import gql from 'graphql-tag'

export default gql(`
query batchGetResolutions($input: [ID]){
    batchGetResolutions(
        input: $input
    ){
        id
        key
        height
        width
    }
}`)
