import React, { Component } from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'

import AppContext from '../../AppContext'
import IngestSideBar from './IngestSideBar'
import IngestList from './IngestList'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryIngestJobs from '../../graphQL/IngestManager/listIngestJobs'
import SubscriptionJobUpdateDetails from '../../graphQL/IngestManager/listJobUpdateSubscription'
import SubscriptionCreateJob from '../../graphQL/IngestManager/createJobSubscription'

let subscribeNewIngnest = null

const subscribeChangeInJob = (subscribeToMore, props, retrying = 50) => {
  return subscribeToMore({
    document: SubscriptionJobUpdateDetails,
    updateQuery: (prev, { subscriptionData: { data: { ingestJobUpdated } } }) => {
      try {
        if (!ingestJobUpdated) return prev
        const index = prev.listIngestJobs.items.findIndex(item => item.batchID === ingestJobUpdated.batchID)
        if (index > -1) {
          const currentAsset = _.cloneDeep(prev.listIngestJobs.items[index])
          if (currentAsset.status !== ingestJobUpdated.status) {
            prev.listIngestJobs.items[index].status = ingestJobUpdated.status
            prev.listIngestJobs.items[index].successful = ingestJobUpdated.successful
            prev.listIngestJobs.items[index].errors = ingestJobUpdated.errors
          }
          if (ingestJobUpdated.retryJobId) {
            prev.listIngestJobs.items[index].retryJobId = ingestJobUpdated.retryJobId
          }
        }
        return prev
      } catch (error) {

      }
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeChangeInJob(subscribeToMore, props, retrying * 2) }, retrying)
      }
    }
  })
}

const subscribeNewIngestJob = (subscribeToMore, props, selectedIngestJobType, searchBatchId, retrying = 50) => {
  return subscribeToMore({
    document: SubscriptionCreateJob,
    updateQuery: (prev, { subscriptionData: { data: { jobCreated } } }) => {
      try {
        if (!searchBatchId && !selectedIngestJobType) {
          const newList = [ ...jobCreated, ...prev.listIngestJobs.items ]
          prev.listIngestJobs.items = newList
          prev.listIngestJobs.totalCount = prev.listIngestJobs.totalCount + 1
        } else if (searchBatchId) {

        } else {
          if (selectedIngestJobType === jobCreated[0].jobType) {
            const newList = [ ...jobCreated, ...prev.listIngestJobs.items ]
            prev.listIngestJobs.items = newList
            prev.listIngestJobs.totalCount = prev.listIngestJobs.totalCount + 1
          }
        }
        return prev
      } catch (error) {

      }
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeNewIngestJob(subscribeToMore, props, retrying * 2) }, retrying)
      }
    }
  })
}

class IngestEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSidebar: false,
      selectedJobId: null,
      ingestTableActiveKey: '1',
      shouldRefreshJobHistory: false,
      selectedIngestedJob: {}
    }
  }

  componentDidMount = () => {
    document.title = 'Ingest Manager - Hyperion CMS'
    this.initiateSubscription()
    this.props.subscribeToChangeInJob()
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if ((!newProps.match.params || !newProps.match.params.id) && this.state.selectedJobId) {
      this.setState({ selectedJobId: '' })
    } else if (this.state.selectedJobId !== newProps.match.params.id) {
      this.setState({ selectedJobId: newProps.match.params.id, showSidebar: true })
    }
    if (this.props.selectedIngestJobType !== newProps.selectedIngestJobType) {
      this.setState({
        showSidebar: false
      })
    }

    if (this.props.searchBatchId !== newProps.searchBatchId) {
      this.setState({
        showSidebar: false
      })
    }

    this.initiateSubscription(newProps.selectedIngestJobType, newProps.searchBatchId)
  }

  initiateSubscription = (selectedIngestJobType, searchBatchId) => {
    if (this.props.subscribeToNewIngestJob) {
      if (subscribeNewIngnest) { subscribeNewIngnest() }
      subscribeNewIngnest = this.props.subscribeToNewIngestJob(selectedIngestJobType, searchBatchId)
    } else {
      setTimeout(() => {
        this.initiateSubscription(selectedIngestJobType, searchBatchId)
      }, 2000)
    }
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar })
  }

  changeSelectedJob = (jobDetails) => {
    this.setState({ showSidebar: true, selectedJobId: jobDetails.batchID, selectedIngestedJob: jobDetails }, () => {
      if (jobDetails) {
        this.props.history.push(`/ingest-jobs/${jobDetails.batchID}`)
      } else {
        this.props.history.push(`/ingest-jobs`)
      }
    })
  }

  onIngestTableTabChange = (ingestTableActiveKey) => {
    if (ingestTableActiveKey === '2') {
      this.setState({
        ingestTableActiveKey,
        showSidebar: false
      })
    } else {
      this.setState({ ingestTableActiveKey, showSidebar: true })
    }
  }

  handleCustomJobTypeName = (jobType) => {
    let customJobTypeName
    switch (jobType) {
      case 'CSV_ASSET_INGEST':
        customJobTypeName = 'CSV Ingest'
        break
      case 'GN_ASSET_INGEST':
        customJobTypeName = 'Gracenote Asset Ingest'
        break
      case 'GN_UPDATE_ASSET_INGEST':
        customJobTypeName = 'Gracenote Update Asset Ingest'
        break
      case 'GN_PARTNER_UPDATE':
        customJobTypeName = 'Gracenote Partner Update'
        break
      case 'GN_SEASON_EPISODE_LINK':
        customJobTypeName = 'Gracenote Season Episode Link'
        break
      case 'GN_SEASON_ASSET_INGEST':
        customJobTypeName = 'Gracenote Season Asset Ingest'
        break
      case 'GN_PARTNER_EXPIRY':
        customJobTypeName = 'Gracenote Partner Expiry'
        break
      case 'GN_SERIES_PARTNER_UPDATE':
        customJobTypeName = 'Gracenote Series Partner Update'
        break
      default:
        customJobTypeName = '-'
    }
    return customJobTypeName
  }

  refetchHistory = () => {
    setTimeout(() => {
      this.setState({ shouldRefreshJobHistory: true }, () => {
        this.setState({ shouldRefreshJobHistory: false })
      })
    }, 20000)
  }

  render () {
    const { showSidebar, selectedJobId, ingestTableActiveKey, shouldRefreshJobHistory } = this.state
    const { project, IngestClient, selectedIngestJobType, onIngestJobTypeSelect, clearBatchIdSearchField, onSearchBatchIdChange, searchBatchId, listIngestJobs,
      isLoading, totalCount, getMoreIngestJob, onChangeBatchIdSearchInput } = this.props
    return (
      <Row className='ingest-manager'>
        <Col xl={{ span: 18 + (showSidebar ? 0 : 6) }} xs={{ span: 18 + (showSidebar ? 0 : 6) }} className={`ingest-tab-container ${showSidebar ? 'sidebar' : ''}`} >
          <AppContext.Consumer>
            {({ permissions }) => {
              const userPermissions = permissions['INGEST_MANAGER']
              const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
              const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
              return <IngestList
                isSidebarVisible={showSidebar}
                toggleSidebar={this.toggleSidebar}
                changeSelectedJob={this.changeSelectedJob}
                project={project}
                IngestClient={IngestClient}
                selectedJobId={selectedJobId}
                onIngestJobTypeSelect={onIngestJobTypeSelect}
                selectedIngestJobType={selectedIngestJobType}
                onIngestTableTabChange={this.onIngestTableTabChange}
                ingestTableActiveKey={ingestTableActiveKey}
                isUpdateDisabled={isUpdateDisabled}
                handleCustomJobTypeName={this.handleCustomJobTypeName}
                refetchHistory={this.refetchHistory}
                shouldRefreshJobHistory={shouldRefreshJobHistory}
                searchBatchId={searchBatchId}
                onSearchBatchIdChange={onSearchBatchIdChange}
                clearBatchIdSearchField={clearBatchIdSearchField}
                isCreateDisabled={isCreateDisabled}
                listIngestJobs={listIngestJobs}
                isLoading={isLoading}
                totalCount={totalCount}
                getMoreIngestJob={getMoreIngestJob}
                onChangeBatchIdSearchInput={onChangeBatchIdSearchInput}
              />
            }}
          </AppContext.Consumer>
        </Col>
        <Col xs={{ span: (showSidebar ? 6 : 0) }} className='ingest-sidebar-container'>
          <AppContext.Consumer>
            {({ permissions }) => {
              const userPermissions = permissions['INGEST_MANAGER']
              const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
              const isCreateDisable = userPermissions.indexOf('CREATE') === -1
              const isPublishDisable = userPermissions.indexOf('PUBLISH') === -1
              return <IngestSideBar
                project={project}
                selectedJobId={selectedJobId}
                IngestClient={IngestClient}
                isUpdateDisabled={isUpdateDisabled}
                isCreateDisable={isCreateDisable}
                handleCustomJobTypeName={this.handleCustomJobTypeName}
                refetchHistory={this.refetchHistory}
                isPublishDisable={isPublishDisable}
                listIngestJobs={listIngestJobs}
              />
            }}
          </AppContext.Consumer>
        </Col>
      </Row>
    )
  }
}

IngestEditor.propTypes = {
  /** current project */
  project: PropTypes.string,
  /** ingest Client configuration */
  IngestClient: PropTypes.object,
  /** search value for api call */
  searchBatchId: PropTypes.string,
  /** function to handle job type change */
  onIngestJobTypeSelect: PropTypes.func,
  /** value of the selected job type for api */
  selectedIngestJobType: PropTypes.string,
  /** function to handle batchId search change */
  onChangeBatchIdSearchInput: PropTypes.func

}

export default withApollo(compose(
  graphql(
    QueryIngestJobs,
    {
      options: ({ project, IngestClient, selectedIngestJobType, searchBatchId }) => {
        return {
          fetchPolicy: 'network-only',
          client: IngestClient,
          variables: { filter: { project, jobType: selectedIngestJobType || null, batchJobId: searchBatchId || null }, offset: 0, limit: 30 }
        }
      },
      props: (props) => {
        const { data } = props
        let listIngestJobs = data.listIngestJobs ? data.listIngestJobs.items.filter(item => item.isArchived !== true) : []
        return {
          listIngestJobs: listIngestJobs,
          isLoading: data.loading || !data.listIngestJobs,
          totalCount: data.listIngestJobs ? data.listIngestJobs.totalCount : 0,
          getMoreIngestJob: (page) => {
            return props.data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listIngestJobs.items, ...fetchMoreResult.listIngestJobs.items]
                prev.listIngestJobs.items = newList
                return prev
              }
            })
          },
          subscribeToNewIngestJob: (selectedIngestJobType, searchBatchId) => subscribeNewIngestJob(props.data.subscribeToMore, props, selectedIngestJobType, searchBatchId),
          subscribeToChangeInJob: () => subscribeChangeInJob(props.data.subscribeToMore, props)
        }
      }
    }
  )
)(IngestEditor))
