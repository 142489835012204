import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import CopyLinkIcon from '../icons/CopyLinkIcon'

const CopyUrlButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button copy-url ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <CopyLinkIcon color='#fff' />
  </div>
)

CopyUrlButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

CopyUrlButton.defaultProps = {
  isDisabled: false
}

export default CopyUrlButton
