import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import { Form, Input, message } from 'antd'
import { utilityService } from './../../../../services/UtilityService'
import userMessages from '../../../../constants/messages'
import LoadingButton from './../../general/buttons/LoadingButton'

class ResetPasswordForm extends React.Component {
    state = {
      password: '',
      newPassword: '',
      currentPassword: '',
      isValidPassword: true,
      isValidNewPassword: true,
      errorMessage: '',
      isLoading: false,
      disabled: true
    }
    UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
      if (newProps.formSuccess) {
        this.setState({ isLoading: false })
      }
    }

    handleSubmit = async (e) => {
      e.preventDefault()
      if (this.state.isLoading) {
        return
      }
      this.setState({ isLoading: true })
      let isValidNewPassword = true; let isValidPassword = true; let errorMessage = ''
      if (!this.state.password) {
        isValidPassword = false
      }
      if (!this.state.newPassword) {
        isValidNewPassword = false
      }
      if (isValidPassword !== isValidNewPassword) {
        errorMessage = userMessages.AUTH_EMPTY_FIELDS
      } else if (!isValidPassword) {
        errorMessage = userMessages.AUTH_EMPTY_FIELD
      } else {
        if (this.state.password !== this.state.newPassword) {
          isValidNewPassword = false
          errorMessage = userMessages.AUTH_PASSWORD_MISMATCH
        } else {
          const isvalid = utilityService.checkPasswordValidation(this.state.password)
          if (!isvalid) {
            isValidNewPassword = false
            isValidPassword = false
            errorMessage = userMessages.AUTH_PASSWORD_VALIDATION
          } else {
            this.props.onSuccess(this.state.password, this.state.currentPassword)
          }
        }
      }
      if (errorMessage) {
        this.setState({ isLoading: false, errorMessage })
        message.warning(errorMessage)
      }
      this.setState({ isValidNewPassword, isValidPassword, errorMessage })
    }

    handleLoginformChange = e => {
      const { name, value } = e.target
      this.setState({ [ name ]: value })
    }
    checkFormStatus = () => {
      if (this.state.disabled) {
        if (this.state.password && this.state.newPassword) {
          this.setState({ disabled: false })
        }
      } else {
        if (!this.state.password || !this.state.newPassword) {
          this.setState({ disabled: true })
        }
      }
    }

    render () {
      const { password, newPassword, isValidPassword, isValidNewPassword, disabled, isLoading, currentPassword } = this.state
      const { isChangePassword, onCancel } = this.props
      this.checkFormStatus()
      const isHyperion = window.location.href.includes('hyperion')
      const isSubhub = window.location.href.includes('subhub')
      return (
        <div className={`login-form ${isChangePassword ? 'change-password' : 'reset-password'} ${isLoading ? 'loading' : ''}`} >
          {isHyperion ? <div className='optus-logo hyperion-logo'><span>HYPERION</span></div>
            : isSubhub ? <div className='optus-logo hyperion-logo'><span>SUBHUB</span></div>
              : <img className='optus-logo' src={require('./../../../../assets/images/optus-logo.png')} />}
          <span>SET NEW PASSWORD</span>
          <div className='border-line' />
          <Form onSubmit={this.handleSubmit} className='reset-form'>
            {isChangePassword && <Form.Item label='Current Password'>
              <Input className={`password current-password`} type='password' name='currentPassword' placeholder='Enter your current password' value={currentPassword} onChange={this.handleLoginformChange} />
            </Form.Item>}
            <Form.Item label='New Password'>
              <Input className={`password new-password ${!isValidPassword ? 'error' : ''}`} type='password' name='password' placeholder='Enter your new password' value={password} onChange={this.handleLoginformChange} />
            </Form.Item>
            <Form.Item label='Confirm Password'>
              <Input className={`password confirm-password ${!isValidNewPassword ? 'error' : ''}`} type='password' name='newPassword' placeholder='Confirm your new password' value={newPassword} onChange={this.handleLoginformChange} />
            </Form.Item>
            <Form.Item>
              <div className='button-container'>
                {isChangePassword && <LoadingButton type='secondary' htmlType={'button'} buttonText='Cancel' buttonClass='login-form-change-button' onClick={onCancel} />}
                <LoadingButton type='primary' htmlType='submit' buttonText='Reset Password' buttonClass={isChangePassword ? 'login-form-change-button' : 'login-form-button'} disabled={disabled} isLoading={isLoading} />
              </div>
            </Form.Item>
          </Form>
        </div>
      )
    }
}

ResetPasswordForm.propTypes = {
  /** Submit action of ResetPasswordForm. */
  onSuccess: PropTypes.func.isRequired,
  /** Flag to indicate for change password */
  isChangePassword: PropTypes.bool
}

ResetPasswordForm.defaultProps = {
  isChangePassword: false
}

export default ResetPasswordForm
