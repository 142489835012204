import gql from 'graphql-tag'

export default gql(`
    query($filter: TableAuthorFilterInput $limit: Int $offset: Int $project: String){
        listAuthors( 
            filter: $filter
            limit: $limit
            offset: $offset
            project: $project
        ) {
        items {
            id
            name
            assetCount
            isDisabled
            isTransferring
            isError
            media {
                id
                name
                fileName
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
        }
        totalCount
    }
}`)
