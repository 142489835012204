const ApiService = (apiType, url, request, header, successCallBack, failureCallBack, progressCallBack) => {
  var xhr = new XMLHttpRequest()

  xhr.onload = function (e) {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        let response = xhr.response
        let responseHeaderEtag = ''
        let responseHeaderContent = ''
        try {
          response = JSON.parse(xhr.response)
        } catch {

        }
        try {
          responseHeaderEtag = xhr.getResponseHeader('ETAG')
        } catch {

        }
        try {
          responseHeaderContent = xhr.getResponseHeader('content-length')
        } catch {

        }
        successCallBack(response, responseHeaderEtag, responseHeaderContent)
      } else {
        const error = typeof (xhr.statusText) === 'string' ? xhr.statusText : JSON.parse(xhr.statusText)
        failureCallBack(error)
      }
    }
  }

  xhr.onerror = function (e) {
    const error = xhr.statusText && typeof (xhr.statusText) !== 'string' ? JSON.parse(xhr.statusText) : 'Network Error'
    failureCallBack(error)
  }

  xhr.upload.onprogress = function (evt) {
    if (evt.lengthComputable) {
      var percentComplete = evt.loaded / evt.total
      var progress = percentComplete * 100
      if (progress > 99) {
        progress = 99
      }
      if (progressCallBack) { progressCallBack(Math.floor(progress)) }
    }
  }

  xhr.open(apiType, url, true)
  if (header['Content-Type']) { xhr.setRequestHeader('Content-type', header['Content-Type']) }
  if (header['x-api-key']) { xhr.setRequestHeader('x-api-key', header['x-api-key']) }
  const requestObj = header['x-api-key'] ? JSON.stringify(request) : request || null
  xhr.send(requestObj)
  return xhr
}

export default ApiService
