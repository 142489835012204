import gql from 'graphql-tag'

export default gql(`
    mutation  adminSwitchFinancialAccount($userId: String! $targetFAId: String $project: String) {
        adminSwitchFinancialAccount(
            userId: $userId
            targetFAId: $targetFAId
            project: $project
        ) {
            success{
                message
            }
            error{
                message
            }
            statusCode
        }
    }`
)
