import gql from 'graphql-tag'

export default gql(`
query listMetaSections($filter: ListMetaSectionsFilterInput){
    listMetaSections( 
        filter: $filter
    ){
        items{
            id
            title
            usedCount
            }
        }
    }
`)
