import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, message } from 'antd'
import moment from 'moment'

import NProgress from 'nprogress'

import OfferSideBar from './OfferSideBar'
import OfferDetails from './OfferDetails'
import CreatePriceModal from './../../components/ui/dataEntry/other/CreatePriceModal'
import userMessages from './../../constants/messages'
import LoggerService from './../../services/LoggerService'
import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationUpdateOffer from './../../graphQL/offer/updateOfferDetails'
import QueryGetOfferDetails from './../../graphQL/offer/getOfferDetails'
import MutationCreatePrice from './../../graphQL/offer/createPrice'
import MutationUpdatePrice from './../../graphQL/offer/updatePrice'
import MutationDeletePrice from './../../graphQL/offer/deletePrice'
import QueryGetPartnerList from './../../graphQL/offer/getPartnerList'
import QueryGetRecurrenceList from './../../graphQL/offer/getRecurrenceList'
import QueryCustomMetaFields from './../../graphQL/admin/customMetaFields/listCustomMetaFields'

class OfferEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      details: undefined,
      isSaving: false,
      isVisible: false,
      editPrice: null,
      isEdited: false,
      isLoading: false,
      activePriceId: '',
      pricesWithPromoId: {},
      activepricesWithPromoId: {},
      refetchHistoryList: false
    }
    this.invalidTitle = false
  }

  componentDidMount=() => {
    if (this.props.details) {
      // this.setState({ details: this.props.details })
      this.mapPricesToPromoId()
      NProgress.done()
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.offerId !== this.props.offerId && newProps.offerId) {
      this.setState({ isLoading: true })
      this.changeEditStatus(false)
    } else if (!newProps.offerId) {
      this.setState({ isLoading: false, details: undefined })
    }

    if (newProps.shouldSave && !this.props.shouldSave) {
      this.submitOfferDetails()
    }

    if (newProps.details && !_.isEqual(newProps.details, this.props.details) && !this.state.isEdited) {
      this.setState({ details: _.cloneDeep(newProps.details), isLoading: false, activepricesWithPromoId: {}, pricesWithPromoId: {} }, () => this.mapPricesToPromoId())
      NProgress.done()
    } else if (newProps.details && (!this.props.details || newProps.offerId !== this.props.offerId)) {
      this.setState({ details: { ...newProps.details }, isLoading: newProps.offerIsLoading, activepricesWithPromoId: {}, pricesWithPromoId: {} }, () => this.mapPricesToPromoId())
      NProgress.done()
    }

    if (!newProps.offerIsLoading && this.props.offerIsLoading && !newProps.details) {
      if (newProps.offerId && !newProps.detailsError) {
        message.error(userMessages.INVALID_ASSET)
      } else {
        const errorMessage = newProps.detailsError ? newProps.detailsError : 'Something went wrong. Please try again later'
        message.error(errorMessage)
      }
      this.setState({ isLoading: false, details: undefined })
      NProgress.done()
    }
  }

  fetchHistory = () => {
    this.setState({ refetchHistoryList: true }, () => {
      this.setState({ refetchHistoryList: false })
    })
  }

  mapPricesToPromoId=() => {
    const { details } = this.state
    let newPricesWithPromoId = {
      nopromoid: []
    }
    const { prices } = details
    if (prices) {
      (prices || []).map(price => {
        if (price && price.promoId) {
          if (_.has(newPricesWithPromoId, price.promoId)) {
            let priceArray = newPricesWithPromoId[`${price.promoId}`]
            const foundValue = (priceArray || []).find(item => {
              if (item) {
                return item === price.id
              }
            })
            if (!foundValue) {
              priceArray.push(price.id)
            }

            newPricesWithPromoId[`${price.promoId}`] = priceArray
          } else if (!_.has(newPricesWithPromoId, price.promoId) && price.promoId !== '') {
            let priceArray = []
            priceArray.push(price.id)
            newPricesWithPromoId[`${price.promoId}`] = priceArray
          }
        } else if (price && (!price.promoId || price.promoId === '')) {
          let priceArray = newPricesWithPromoId['nopromoid']
          priceArray.push(price.id)
          newPricesWithPromoId['nopromoid'] = priceArray
        }
      })
      newPricesWithPromoId = this.checkIfPromoIdExists(newPricesWithPromoId, prices)
      this.setState({ pricesWithPromoId: newPricesWithPromoId }, () => this.mapActivePricesToPromoId())
    }
  }

  mapActivePricesToPromoId=() => {
    const { pricesWithPromoId, details } = this.state
    const { prices } = details
    const currentDate = moment(new Date())
    let newMapActivePricetoPromoId = {}
    if (!_.isEmpty(pricesWithPromoId)) {
      Object.keys(pricesWithPromoId).forEach((promoId) => {
        let finalActivePrice = (pricesWithPromoId[promoId] || []).find((priceId) => {
          if (priceId && prices) {
            let selectedPrice = (prices || []).find(price => {
              if (price) {
                return price.id === priceId
              }
            })
            if (selectedPrice && selectedPrice.startDate && selectedPrice.endDate) {
              if (currentDate.isBetween(moment(selectedPrice.startDate), moment(selectedPrice.endDate))) {
                return selectedPrice
              }
            }
          }
        })
        newMapActivePricetoPromoId[`${promoId}`] = finalActivePrice
      })
      this.setState({ activepricesWithPromoId: newMapActivePricetoPromoId })
    }
  }

  checkIfPromoIdExists=(pricesWithPromoId, prices) => {
    (Object.keys(pricesWithPromoId) || []).map(promoId => {
      const foundValue = (prices || []).find(price => {
        if (price) {
          return promoId === price.promoId || !price.promoId
        }
      })
      if (_.isEmpty(foundValue)) {
        delete pricesWithPromoId[`${promoId}`]
      }
    })
    return pricesWithPromoId
  }

  onDeleteOffer = id => {
    this.changeErrorStatus(false, false)
    this.props.onDeleteOffer(id)
  }

  changeErrorStatus = (editedStatus, errorStatus) => {
    // if (this.state.isEdited !== editedStatus) {
    this.setState({ isEdited: editedStatus })
    const editStatus = { isEdited: editedStatus, isError: errorStatus }
    if (!this.isUpdateDisable) { this.props.changeEditStatus(editStatus) }
    // }
  }

  onInputFieldFocus = () => {
    this.isEditingInput = true
  };

  onInputFieldBlur = (e) => {
    if (!this.isEditingInput) {
      return
    }
    const { details } = this.state
    const defaultSlug = utilityService.slugifyString(`${details.id}-untitled`)
    const isFirstSlug =
      details.slug &&
      (details.slug.indexOf('untitled-') === 0 ||
        details.slug.indexOf(defaultSlug) === 0)
    if (isFirstSlug && details.title !== 'Untitled') {
      const newTitle =
        details.type === 'PARTNER'
          ? details.title.toLowerCase()
          : `${details.id}-${details.title.toLowerCase()}`
      const slug = utilityService.slugifyString(newTitle)
      details.slug = slug
      this.setState({ details })
    }
    this.isEditingInput = false
  };

  handleGeneralChange = (e) => {
    const { details } = this.state
    const { name, value } = e.target
    details[name] = value || ''
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  };

  handleRecurrenceChange = (selectedRecurrence) => {
    if (selectedRecurrence) {
      const { details } = this.state
      details.recurrence = selectedRecurrence
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    } else {
      const { details } = this.state
      details.recurrence = null
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    }
  };

  handlePartnerChange = (selectedPartner) => {
    if (selectedPartner) {
      const { details } = this.state
      details.partner = selectedPartner
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    } else {
      const { details } = this.state
      details.partner = null
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    }
  };

  handleDateChange = (selectedDates) => {
    const { details } = this.state
    let startDate = ''
    let endDate = ''
    // if (details.isActive && selectedDates[1]) { startDate = moment().utc().format() } else { startDate = selectedDates[0] ? moment(selectedDates[0]).utc().format() : null }
    startDate = selectedDates[0] ? moment(selectedDates[0]).utc().format() : null
    endDate = selectedDates[1] ? moment(selectedDates[1]).utc().format() : null
    details.startDate = startDate
    details.endDate = endDate
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  };

  handleActiveChange = (active) => {
    const { details } = this.state
    details.isActive = active
    if (active) {
      let newDate = new Date()
      details.startDate = moment(newDate).utc().format()
    } else if (!active && !details.endDate) {
      details.startDate = null
    }
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  };

  onRemovePriceField=(priceId) => {
    const { details } = this.state
    const { prices } = details
    const { selectedOffer } = this.props
    this.props.deletePrice(priceId, selectedOffer).then(response => {
      const deletedPrice = response.data.deletePrice
      if (deletedPrice) {
        message.success(userMessages.PRICE_DELETE_SUCCESS)
        const modifiedPriceDetails = (prices || []).filter((offerPrice) => {
          if (offerPrice) { return offerPrice.id !== priceId }
        })
        details.prices = modifiedPriceDetails
        this.setState({ details }, () => {
          this.changeEditStatus(true)
          this.mapPricesToPromoId()
        })
      }
    }, error => {
      utilityService.handleError(error)
    })
    this.mapPricesToPromoId()
  }

  handleAttributeDetailsChange = (metaData, value) => {
    const { details } = this.state
    const attributes = (details.partner.attributes || []).map(item => {
      if (item.label === metaData.label && item.type === metaData.type) {
        item.value = item.type === 'STRING' ? (value || '').replace(/,/g, ' ') : value
        item.value = item.type === 'BOOLEAN' ? value : value
        delete item.isError
      } else {
        const tempItem = (details.attributes || []).find(innerItem => innerItem.label === item.label && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
      }
      return item
    })
    details.attributes = attributes
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handlePriceModal = () => {
    this.setState({
      isVisible: true
    })
  }

  handlePriceModalCancel = () => {
    this.setState({
      isVisible: false,
      editPrice: null
    })
  }

  handlePriceSubmit = (data) => {
    const { details, editPrice } = this.state
    const { selectedOffer } = this.props
    let editPriceSubmit = (details.prices || []).find(price => {
      if (price && editPrice) { return price.id === editPrice.id }
    })
    if (!_.isEmpty(editPriceSubmit)) {
      const index = (details.prices || []).findIndex(item => {
        if (item) {
          return item.id === editPrice.id
        }
      })
      data.id = editPriceSubmit.id || ''
      data.offerId = selectedOffer || ''
      this.props.updatePrice(data).then(response => {
        const updatedPrice = response.data.updatePrice
        LoggerService.info('flow', `Updated price ${response.data.updatePrice.id}`)
        this.fetchHistory()
        message.success(userMessages.PRICE_UPDATE_SUCCESS)
        details.prices[index] = updatedPrice
        this.setState({
          details,
          isVisible: false,
          editPrice: null
        }, () => {
          this.changeEditStatus(true)
        })
        this.mapPricesToPromoId()
      }, error => {
        utilityService.handleError(error)
      })
    } else {
      this.props.createPrice(data).then(response => {
        const newPrice = response.data.createPrice
        LoggerService.info('flow', `Created price ${response.data.createPrice.id}`)
        message.success(userMessages.PRICE_CREATE_SUCCESS)
        const { details } = this.state
        if (!details.prices) {
          details.prices = []
        }
        details.prices.push(newPrice)
        this.setState({
          details,
          isVisible: false,
          editPrice: null
        }, () => {
          this.changeEditStatus(true)
        })
        this.mapPricesToPromoId()
      }, (error) => {
        const { graphQLErrors } = error
        if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized') {
          message.warning('User does not have permission to edit')
        } else {
          this.setState({
            isVisible: false
          })
          utilityService.handleError(error)
        }
      })
    }
  }

  handleEditPrice = (fieldId) => {
    const { details } = this.state
    let editPrice = (details.prices || []).filter((item, index) => index === fieldId)[0]
    this.setState({
      isVisible: true,
      editPrice
    })
  }

  handleTermAndConditionChange=(value) => {
    const { details } = this.state
    const { terms } = details
    const isScriptStartTag = value.indexOf('&lt;script')
    const isScriptEndTag = value.indexOf('&lt;/script&gt;')
    if (isScriptStartTag > -1 && isScriptEndTag > -1) {
      value = `${value.substring(0, isScriptStartTag)}${value.substring(isScriptEndTag + 15, value.length)}`
    }
    const tempValue = (value || '').replace(/<br \/>/g, '<br>')
    const tempOldValue = (terms || '').replace(/<br \/>/g, '<br>')
    let isSame
    if (tempValue === tempOldValue) {
      isSame = true
    }
    if (terms !== value) {
      details.terms = value
    } else return
    this.setState({ details }, () => {
      if (!isSame) {
        this.changeEditStatus(true)
      }
    })
  }

  handleActivePriceChange=(id) => {
    this.setState({ activePriceId: id })
  }

  createdBucket = (id) => {
    const { details } = this.state
    details.relatedAssets = { id }
    this.setState({ details })
  }

  checkForModification = (isFromPublish, shouldBlockMessage) => {
    this.submitOfferDetails(isFromPublish, shouldBlockMessage)
  }

  trimSpace = (details) => {
    let { contents } = details
    let item = _.find(contents, (contentItem) => contentItem.type === 'RICHTEXT')
    const itemIndex = _.findIndex(contents, (contentItem) => contentItem.type === 'RICHTEXT')
    if (itemIndex === -1) {
      return details
    }
    let { value } = item
    if (itemIndex === -1 || !value) {
      return details
    }
    let length = value ? value.length : 0
    const checkSpace = '<p><br></p>'
    const spaceLength = checkSpace.length
    while (value.lastIndexOf(checkSpace) !== -1 && value.lastIndexOf(checkSpace) + spaceLength === length) {
      const index = value.lastIndexOf(checkSpace)
      value = value.slice(0, index)
      length = value.length
    }
    item.value = value
    contents[itemIndex] = item
    details.contents = contents
    this.setState({ details })
    return details
  }

  submitOfferDetails = async (isFromPublish, shouldBlockMessage) => {
    const details = this.trimSpace(_.cloneDeep(this.state.details))
    if (!details.name) {
      message.error('Please enter valid Offer name')
      this.setState({ isSaving: false, isLoading: false })
      return
    }
    if (details && details.externalId && details.externalId.includes(' ')) {
      message.error('ExternalId cannot include spaces, Please enter valid externalId')
      this.setState({ isSaving: false, isLoading: false })
      return
    }
    this.invalidTitle = false
    const offerRequest = this.getFormattedRequest(_.cloneDeep(details), isFromPublish)
    this.isEditingInput = false

    this.setState({ isSaving: true }, async () => {
      NProgress.start()
      LoggerService.info('flow', `Updated offer ${details.id}`)
      this.changeEditStatus(false)
      this.props.updateOffer(offerRequest).then(({ data }) => {
        LoggerService.info('offerUpdate', `Updated offer details`, offerRequest)
        this.setState({ isSaving: false, isLoading: false, details: { ...this.state.details, updatedAt: data.updateOffer.updatedAt, updatedBy: data.updateOffer.updatedBy } })
        NProgress.done()
        if (!shouldBlockMessage) {
          if (isFromPublish === true) {
            setTimeout(() => this.props.fetchOfferDetails(), 500)
          } else {
            message.success(userMessages.OFFER_UPDATE_SUCCESS)
            // this.fetchHistory()
          }
        }
        this.fetchHistory()
      }, error => {
        this.setState({ isSaving: false, isLoading: false })
        utilityService.handleError(error)
      })
    })
  }

  getFormattedRequest = (request, isFromPublish) => {
    const formattedRequest = {}
    const { name, externalId, description, shortDescription, mediumDescription, terms, isActive, startDate, endDate, partner, assets, recurrence, customMeta } = request
    formattedRequest.id = request.id
    formattedRequest.name = name
    formattedRequest.externalId = externalId
    formattedRequest.description = description
    formattedRequest.shortDescription = shortDescription
    formattedRequest.mediumDescription = mediumDescription
    formattedRequest.terms = terms
    const priceIdArray = request.prices ? (request.prices || []).filter(item => item).map(price => price.id) : []
    formattedRequest.prices = priceIdArray
    formattedRequest.isActive = isActive
    formattedRequest.startDate = startDate
    formattedRequest.endDate = endDate
    formattedRequest.recurrence = recurrence
    if (this.props.details && !this.props.details.partner) {
      if (partner) {
        formattedRequest.partner = partner.id
      }
    }
    formattedRequest.assets = assets ? assets.id : null
    let attributes = _.map(request.attributes, function (attribute) { return _.omit(attribute, '__typename') })
    if (partner && partner.attributes) {
      attributes = (partner.attributes || []).map(item => {
        const selectedAttribute = (request.attributes || []).find(attribute => attribute.name === item.name)
        return {
          label: item.label,
          type: item.type,
          name: item.name,
          value: _.isEmpty(selectedAttribute) ? (item.type === 'BOOLEAN' ? false : null) : (selectedAttribute.type === 'BOOLEAN' && selectedAttribute.value === null ? false : selectedAttribute.value)
        }
      })
    } else {
      attributes = null
    }
    formattedRequest.attributes = attributes
    const customMetaDetails = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item && item.config) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      } return newItem
    })
    formattedRequest.customMeta = customMetaDetails && customMetaDetails.length ? customMetaDetails : null
    return formattedRequest
  }

  changeEditStatus = (status) => {
    if (this.state.isEdited !== status) {
      this.setState({ isEdited: status }, () => {
        const editStatus = { isEdited: status, isError: this.invalidTitle }
        if (!this.isUpdateDisable) { this.props.changeEditStatus(editStatus) }
      })
    }
    if (status) {
      this.isEditingInput = true
    }
  }

  createdBucket = (id) => {
    const { details } = this.state
    details.assets = { id }
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  onEditRelatedAssets = (status) => {
    this.changeEditStatus(status)
  }

  handleMetaDetailsChange = (metaData, value) => {
    const listCustomMetaFields = _.cloneDeep(this.props.metaFieldList)
    const { details } = this.state
    const { customMeta } = details
    const newDetails = _.cloneDeep(details)
    let finalMetaModified = []
    if (value || value === '' || value === false || value === 'false') {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        let newMetaField = {
          config: item,
          value: item && item.default
        }
        if (item.id === metaData.id && item.type === metaData.type) {
          newMetaField.value = item.type === 'STRING' ? (value || '') : value
          delete item.isError
          newMetaField.config = item
        } else {
          const tempItem = (customMeta || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
          if (_.isEmpty(tempItem)) {
            newMetaField.config = item
            newMetaField.value = item.default
          } else {
            newMetaField = tempItem
            newMetaField.value = tempItem.value
          }
        }

        return newMetaField
      })
      finalMetaModified = customMetaModified
    } else if (!value || value === null || value === undefined || value !== false) {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        const foundMeta = (customMeta || []).find(existingMeta => {
          if (existingMeta && existingMeta.config) {
            if (existingMeta.config.id === item.id) { return existingMeta }
          }
        })
        if (_.isEmpty(foundMeta)) {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          return newMetaField
        } else {
          return foundMeta
        }
      })
      finalMetaModified = customMetaModified
    }
    newDetails.customMeta = finalMetaModified
    this.setState({ details: newDetails }, () => {
      this.changeEditStatus(true)
    })
  }

  render () {
    const { showSidebar, toggleSidebar, project, isArchive, appliedFilter, listPartners, listOptions, selectedOffer, metaFieldList, priceMetaFieldList, offerIsLoading } = this.props
    const { details, isVisible, editPrice, isSaving, activePriceId, isEdited, activepricesWithPromoId, refetchHistoryList } = this.state
    let projectRecurrence = project === 'projectx' ? 'projectx-offer_recurrence' : 'offer_recurrence'
    let listRecurrence = (listOptions || []).find(option => option.id === projectRecurrence)
    let projectPrice = project === 'projectx' ? 'projectx-offer_currency' : 'offer_currency'
    let listCurrency = (listOptions || []).find(option => option.id === projectPrice)
    return (
      <>
        <Row className='offer-editor'>
          <Col
            className={`offer-details ${showSidebar ? 'small' : ''}`}
            xxl={{ span: 24 - (showSidebar ? 8 : 0) }}
            xl={{ span: 24 - (showSidebar ? 8 : 0) }}
            lg={{ span: 24 - (showSidebar ? 6 : 0) }}
            md={{ span: 24 - (showSidebar ? 6 : 0) }}
            xs={{ span: 24 - (showSidebar ? 6 : 0) }}
          >
            {details ? <OfferDetails
              toggleSidebar={toggleSidebar}
              showSidebar={showSidebar}
              details={_.cloneDeep(details)}
              handleGeneralChange={this.handleGeneralChange}
              onInputFieldFocus={this.onInputFieldFocus}
              onInputFieldBlur={this.onInputFieldBlur}
              isInvalidTitle={this.invalidTitle}
              handleRecurrenceChange={this.handleRecurrenceChange}
              handleMetaDetailsChange={this.handleMetaDetailsChange}
              handlePartnerChange={this.handlePartnerChange}
              handleDateChange={this.handleDateChange}
              handleActiveChange={this.handleActiveChange}
              onRemovePriceField={this.onRemovePriceField}
              handleAttributeDetailsChange={this.handleAttributeDetailsChange}
              handlePriceModal={this.handlePriceModal}
              handleEditPrice={this.handleEditPrice}
              handleTermAndConditionChange={this.handleTermAndConditionChange}
              handleActivePriceChange={this.handleActivePriceChange}
              createdBucket={this.createdBucket}
              onEditRelatedAssets={this.onEditRelatedAssets}
              isSaving={isSaving}
              project={project}
              activePriceId={activePriceId}
              submitOfferDetails={this.checkForModification}
              isEdited={isEdited}
              isLoading={offerIsLoading}
              isArchive={isArchive}
              appliedFilter={appliedFilter}
              onDeleteOffer={this.onDeleteOffer}
              listPartners={listPartners}
              listRecurrence={listRecurrence}
              activePricesWithPromoId={activepricesWithPromoId}
              disabled={this.props.details && !!this.props.details.partner}
              metaFieldList={metaFieldList || []}
              priceMetaFieldList={priceMetaFieldList || []}
            /> : null}
          </Col>
          <Col
            className='offer-side-bar'
            xxl={{ span: showSidebar ? 8 : 0 }}
            xl={{ span: showSidebar ? 8 : 0 }}
            lg={{ span: showSidebar ? 6 : 0 }}
            md={{ span: showSidebar ? 6 : 0 }}
            xs={{ span: showSidebar ? 6 : 0 }}
          >{ selectedOffer
              ? <OfferSideBar selectedOffer={selectedOffer} project={project} refetchHistoryList={refetchHistoryList} /> : null
            }
          </Col>
        </Row>
        <CreatePriceModal
          isVisible={isVisible}
          handlePriceModalCancel={this.handlePriceModalCancel}
          handlePriceSubmit={this.handlePriceSubmit}
          editPrice={_.cloneDeep(editPrice)}
          project={project}
          prices={details ? _.cloneDeep(details.prices) : null}
          activePricesWithPromoId={activepricesWithPromoId}
          activePrice={activePriceId}
          listCurrency={listCurrency}
          priceMetaFieldList={priceMetaFieldList || []}
          handlePriceMetaDetailsChange={this.handlePriceMetaDetailsChange}
        />
      </>
    )
  }
}

OfferEditor.propTypes = {
  /** toggle side bar value of OfferManager. */
  showSidebar: PropTypes.bool,
  /** toggle side bar action of OfferManager. */
  toggleSidebar: PropTypes.func,
  /** id of selected Offer of OfferManager. */
  offerId: PropTypes.string,
  /** archived offers of OfferManager. */
  deletedIds: PropTypes.array,
  /** Delete Offer action of OfferManager. */
  onDeleteOffer: PropTypes.func,
  /** change offer details mutation action of OfferManager. */
  changeEditStatus: PropTypes.func,
  /** Archive status of offer of OfferManager. */
  isArchive: PropTypes.bool,
  /** applied filter data of OfferManager. */
  appliedFilter: PropTypes.object
}

export default withApollo(compose(
  graphql(
    MutationUpdateOffer,
    {
      props: (props) => ({
        updateOffer: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    QueryGetOfferDetails,
    {
      skip: ({ offerId }) => {
        return !offerId
      },
      options: ({ offerId, project }) => {
        return {
          variables: { id: offerId, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        sessionStorage.setItem('OfferName', props.data.getOffer ? props.data.getOffer.title : '')
        return {
          details: props.data.getOffer,
          offerIsLoading: props.data.loading,
          detailsError: props.data.error ? props.data.error.message : null,
          fetchOfferDetails: () => {
            return props.data.refetch()
          }
          // subscribeToChangeInOffer: (offerId) => subscribeChangeInOffer(props.data.subscribeToMore, props, offerId)
        }
      }
    }
  ),
  graphql(
    MutationCreatePrice,
    {
      props: (props) => ({
        createPrice: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdatePrice,
    {
      props: (props) => ({
        updatePrice: (data) => {
          let variables = data
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    MutationDeletePrice,
    {
      props: (props) => ({
        deletePrice: (id, offerId) => {
          let variables = { id, offerId }
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    QueryGetPartnerList,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 999, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          listPartners: props.data.listPartners && props.data.listPartners.items && props.data.listPartners.items.length ? props.data.listPartners.items : []
        }
      }
    }
  ),
  graphql(
    QueryGetRecurrenceList,
    {
      options: (props) => {
        const { project } = props
        return {
          variables: { module: project === 'projectx' ? 'projectx-offer' : 'offer', project: project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          listOptions: props.data.listConfig && props.data.listConfig.length ? props.data.listConfig : []
        }
      }
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'OFFER', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let metaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        metaFieldList = metaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          metaFieldList
        }
      }
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'OFFER_PRICE', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let priceMetaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        priceMetaFieldList = priceMetaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          priceMetaFieldList
        }
      }
    }
  )
)(withApollo(OfferEditor)))
