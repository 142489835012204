import gql from 'graphql-tag'

export default gql(`
    mutation createAppPage($title: String! $shortDescription: String! $app: ID! $group: ID $project: String! $tags: [ID] $slug: String $seoKeywords: [String] $seoMetaDescription: String $seoTitle: String $key: String! $customMeta: [MetaFieldInput] $isCritical: Boolean $assignedUsers: [String]) {
        createAppPage(
            input: {
                title: $title
                shortDescription: $shortDescription
                app: $app
                group: $group
                slug: $slug
                seoKeywords: $seoKeywords
                tags: $tags
                seoMetaDescription: $seoMetaDescription
                seoTitle: $seoTitle
                key: $key
                project: $project
                customMeta: $customMeta
                isCritical: $isCritical
                assignedUsers: $assignedUsers
            },
            project: $project
        ) {
            id
            isArchived
            isPublished
            createdBy
            createdAt
            key
            isCritical
            assignedUsers
            project
            publishStartDate
            publishEndDate
            title
            shortTitle
            shortDescription
            slug
            seoKeywords
            seoMetaDescription
            seoTitle
            group{
              key
              name
              type
              assignedUsers
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
        }
    }`
)
