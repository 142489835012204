import React from 'react'
import { ForgotPassword as Forgot } from 'aws-amplify-react'
import { message } from 'antd'
import ForgotForm from './../../components/ui/dataEntry/forms/ForgotForm'
import userMessages from './../../constants/messages'
import './Auth.scss'

const forgotPasswordState = 'forgotPassword'
const signInState = 'signIn'

class ForgotPassword extends Forgot {
  constructor (props) {
    super(props)
    this.state = {
      isSuccess: false
    }
    this._validAuthStates = [ forgotPasswordState ]
  }

    onSubmit = () => {
      message.success(userMessages.AUTH_PASSWORD_SET_SUCCESS)
      this.changeState(signInState)
    }

    loginClicked = () => {
      this.changeState(signInState)
    }

    showComponent () {
      return (
        <div className='forgot'>
          <div className='login-form-align'>
            <ForgotForm onLoginClick={this.loginClicked} onSubmit={this.onSubmit} />
          </div>
        </div>
      )
    }
}

export default ForgotPassword
