import gql from 'graphql-tag'

export default gql(`
    mutation deleteApiKey($id: ID! $project: String) {
        deleteApiKey(
            id: $id
            project: $project
        ) {
            id
        }
    }`
)
