import gql from 'graphql-tag'

export default gql(`
subscription{
    publishRulesUpdated{
        id
        assetId
        group{
          id
        }
        isEnabled
        publishEndDate
        publishStartDate
      }
  }`)
