import React from 'react'
import { Menu, Item, contextMenu } from 'react-contexify'
import moment from 'moment'
import { Spin, Icon, Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import _ from 'lodash'
import 'react-contexify/dist/ReactContexify.css'

import AssetListCell from './AssetListCell'
import BucketEditTitleModal from './../dataEntry/other/BucketEditTitleModal'
import BucketResetTitleModal from './../dataEntry/other/BucketResetTitleModal'
import BucketResetDisplayImageModal from './../dataEntry/other/BucketResetDisplayImageModal'
import BucketEditDisplayImageModal from './../dataEntry/other/BucketEditDisplayImageModal'
import BucketAssetSearchList from './BucketAssetSearchList'
import DragAndDropIcon from '../general/icons/DragAndDropIcon'
import { generateCroppedThumbnail } from './../../../util/util'
import { utilityService } from '../../../services/UtilityService'

import './../ui.style.scss'

// import styled from '@emotion/styled'
// import { colors } from '@atlaskit/theme'

export const grid = 8
export const borderRadius = 2

// const TaskList = styled.div`
//   transition: background-color 0.2s ease;
// `

const primaryButton = 0

// const Container = styled.div`
//   ${props =>
//     props.isSelected ? `border: 2px solid ${colors.N90} !important;` : ''}
//     ${props =>
//     props.isGhosting
//       ? 'opacity: 0.5;'
//       : ''}

//   /* needed for SelectionCount */
//   position: relative;

//   /* avoid default outline which looks lame with the position: absolute; */
//   &:focus {
//     outline: none;
//     border-color: ${colors.G200};
//   }
// `
/* stylelint-enable */
// const size = 30

// const SelectionCount = styled.div`
//   right: -${grid}px;
//   top: -${grid}px;
//   color: ${colors.N0};
//   background: ${colors.N200};
//   border-radius: 50%;
//   height: ${size}px;
//   width: ${size}px;
//   line-height: ${size}px;
//   position: absolute;
//   text-align: center;
//   font-size: 0.8rem;
// `

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9
}

// const getItemStyle = (isDragging, draggableStyle, isSelected, isGhosting) => ({
//   opacity: isGhosting ? '0.5' : '1',
//   userSelect: 'none',
//   margin: '0',
//   border: isDragging ? '1px solid #cfd0d1' : 'none',
//   ...draggableStyle
// })

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

let primaryImageType

class BucketDetailList extends React.Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    this.state = {
      showResetTitle: false,
      showEditTitle: false,
      showResetDisplayImage: false,
      showEditDisplayImage: false,
      selectedId: '',
      isFilterOpen: false,
      uploadingImage: [],
      selectedTaskIds: []
    }
  }

  onKeyDown = (
    event,
    provided,
    snapshot,
    item
  ) => {
    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.keyCode !== keyCodes.enter) {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    this.performAction(event, item)
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  onClick = (event, item) => {
  // to disable the all select message
    if (event.target.className === 'react-contexify__item__content') {
      return
    }
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== primaryButton) {
      return
    }

    // marking the event as used
    event.preventDefault()

    this.performAction(event, item)
  };

  onTouchEnd = (event, item) => {
    if (event.defaultPrevented) {
      return
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault()
    this.props.toggleSelectionInGroup(item.id)
  };

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed = (event) => {
    // const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    // return isUsingWindows ? event.ctrlKey : event.metaKey
    const isUsingMac = navigator.platform.indexOf('Mac') >= 0
    return isUsingMac ? event.metaKey : event.ctrlKey
  };

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = (event) => event.shiftKey;

  performAction = (event, item) => {
    const {
      toggleSelection,
      toggleSelectionInGroup,
      multiSelectTo
    } = this.props

    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(item.id)
      return
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      if (item && item.id) {
        multiSelectTo(item.id)
      }
      return
    }

    toggleSelection(item.id)

    // const { selectedTaskIds } = this.state
  }

  onWindowKeyDown = (event) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.key === 'Escape') {
      this.unselectAll()
    }
  }

  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }
    this.unselectAll()
  }

  onWindowTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return
    }
    this.unselectAll()
  }

  unselect = () => {
    this.unselectAll()
  }

  unselectAll = () => {
    this.setState({
      selectedTaskIds: []
    })
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.isScroll) {
      setTimeout(() => {
        this.bucketScrollContainer.scrollTop = (this.bucketScrollContainer.scrollHeight + 100)
        this.props.onBucketScroll()
      }, 200)
    }
    if (newProps.selectedTaskIds && !_.isEqual(newProps.selectedTaskIds, this.state.selectedTaskIds)) {
      this.setState({
        selectedTaskIds: newProps.selectedTaskIds
      })
    }
  }

  handleFileUpload = (uploadingImage, isImageUpload) => {
    if (isImageUpload) {
      this.setState({ uploadingImage })
    }
  }

  clearFileName = () => this.setState({ uploadingImage: [] })

  showEditTitleModal = (itemId) => {
    this.setState({ showEditTitle: true, selectedId: itemId })
  }

  showResetTitleModal = (itemId) => {
    this.setState({ showResetTitle: true, selectedId: itemId })
  }
  showResetImageModal = (itemId) => {
    this.setState({ showResetDisplayImage: true, selectedId: itemId })
  }

  showEditImageModal = (itemId) => {
    this.setState({ showEditDisplayImage: true, selectedId: itemId })
  }

  hideEditImageModal = () => {
    this.setState({ showEditDisplayImage: false })
  }

  hideEditTitleModal = () => {
    this.setState({ showEditTitle: false })
  }
  hideResetImageModal = () => {
    this.setState({ showResetDisplayImage: false })
  }

  hideResetTitleModal = () => {
    this.setState({ showResetTitle: false })
  }

  removeBucketItem = (item) => {
    if (this.props.removeBucketItem) { this.props.removeBucketItem(item) }
  }

  handleAssetTitle = (title) => {
    const { selectedId } = this.state
    this.props.handleAssetTitle(title, selectedId)
    this.setState({ showEditTitle: false })
  }

  handleAssetMedia = (media, defaultMedia) => {
    const { selectedId } = this.state
    this.props.onAssetDisplayImageChange(media, selectedId, defaultMedia)
    this.setState({ showEditDisplayImage: false })
  }

  handleAssetTitleReset = () => {
    const { selectedId } = this.state
    this.props.handleAssetTitleReset(selectedId)
    this.setState({ showResetTitle: false })
  }

  handleAssetDisplayImageReset = () => {
    const { selectedId } = this.state
    this.props.handleAssetDisplayImageReset(selectedId)
    this.setState({ showResetDisplayImage: false })
  }

  onAddImage = (details) => {
    const { selectedId } = this.state
    this.props.onAddImage(details, selectedId)
  }

  isFilterOpen = (isFilterOpen) => {
    this.setState({ isFilterOpen })
  }

  AfterEdittedMenu = (type, itemId, customMedia, customTitle, defaultMedia, isLoading, menuId) => {
    if (isLoading) {
      return <Menu className='loading-menu' id={menuId} >
        <Item>
          <Spin indicator={<Icon type='loading' style={{ fontSize: 24, color: '#FF015A' }} spin />} />
        </Item>
      </Menu>
    }
    return (
      <Menu id={menuId} >
        <Item onClick={() => this.removeBucketItem(itemId)} >{ type === 'ASSET' ? 'Remove Item' : 'Remove Filter' }</Item>
        { this.props.parentManager !== 'Asset' && type === 'ASSET' ? <Item onClick={() => this.showEditTitleModal(itemId)} >Edit Display Title</Item> : null}
        { this.props.parentManager !== 'Asset' && type === 'ASSET' ? <Item onClick={() => this.showEditImageModal(itemId)} >Edit Display Image</Item> : null}
        { type === 'ASSET' && customMedia && (!defaultMedia || customMedia.id !== defaultMedia.id) ? <Item onClick={() => this.showResetImageModal(itemId)}> Reset Display Image</Item> : null }
        { type === 'ASSET' && customTitle ? <Item onClick={() => this.showResetTitleModal(itemId)}> Reset Display Title</Item> : null }
      </Menu>
    )
  }

  deleteSelected = (menuId) => {
    return (
      <Menu id={menuId} >
        <Item onClick={() => {
          this.state.selectedTaskIds.map((itemId) => this.removeBucketItem(itemId))
        }} >{'Remove Selected Items'}</Item>
      </Menu>
    )
  }

  render () {
    const { listData, bucketId, parent, parentCompoent, isSaveEnable, saveLoading, isUpdateBlocked, isCreateBlocked, draggingTaskId,
      isUpdateDisabledContent, isCreateDisabledContent, project } = this.props
    const { showEditTitle, showResetTitle, showResetDisplayImage, showEditDisplayImage, selectedId, isFilterOpen, uploadingImage, selectedTaskIds } = this.state
    const bucketAssetData = selectedId ? listData.find((item) => item.id === selectedId) : undefined
    return (
      <div className='bucket-detail-list' ref={node => { this.bucketScrollContainer = node }}>
        <div className='bucket-detail'>
          {/* <DragDropContext
            onDragEnd={this.onDragEnd}
          > */}
          <Droppable droppableId={'#Bucket_' + bucketId} isDropDisabled={saveLoading}>
            {(droppableProvided, droppableSnapshot) => (
              <div
                className='bucket-list-container'
                ref={droppableProvided.innerRef}
                // isDraggingOver={droppableSnapshot.isDraggingOver}
                {...droppableProvided.droppableProps}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {(listData || []).map((item, index) => {
                  const isSelected = selectedTaskIds && !_.isEmpty(selectedTaskIds) && Boolean(
                    selectedTaskIds.includes(item.id)
                  )
                  const isGhosting =
                    isSelected &&
                    Boolean(draggingTaskId) &&
                    draggingTaskId !== item.id
                  return (
                    <Draggable key={item.id} draggableId={item.id + `bucket-item` + index + 1} index={index} isDragDisabled={isFilterOpen || isUpdateBlocked}>
                      {(provided, snapshot) => {
                        const shouldShowSelection = snapshot.isDragging && selectedTaskIds.length > 1 && selectedTaskIds.includes(item.id)
                        if (snapshot.isDragging && selectedTaskIds.length > 0 && !selectedTaskIds.includes(item.id)) {
                          this.setState({
                            selectedTaskIds: []
                          })
                        }
                        const menuId = `id-${item.id}-${bucketId}`
                        const defaultMedia = item.type === 'ASSET' ? item.asset.defaultMedia ? item.asset.defaultMedia
                          : (item.asset.images || []).filter(imageItem => imageItem.type.id === primaryImageType).find(imageItem => imageItem.isDefault === 'true') : null
                        return (
                          <div
                            className='asset-list-cell-inner-container'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={(event) => this.onClick(event, item)}
                            onTouchEnd={(event) => this.onTouchEnd(event, item)}
                            onKeyDown={(event) =>
                              this.onKeyDown(event, provided, snapshot, item)
                            }
                            isDragging={snapshot.isDragging}
                            isSelected={isSelected}
                            isGhosting={isGhosting}
                            // style={getItemStyle({
                            //   isDragging: snapshot.isDragging,
                            //   draggableStyle: provided.draggableProps.style,
                            //   isSelected,
                            //   isGhosting
                            // })}
                          >
                            <div

                              onContextMenu={(e) => contextMenu.show({
                                id: menuId,
                                event: e
                              })}

                            >
                              {
                                item.type === 'ASSET'
                                  ? <AssetListCell
                                    project={project}
                                    isSelected={selectedTaskIds.includes(item.id)}
                                    assetId={item.asset.id}
                                    assetName={item.customTitle || item.asset.title}
                                    displayId={item.asset.shortId || item.asset.id}
                                    assetType={item.asset.type}
                                    status={item.asset.status}
                                    date={moment(new Date(item.asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                                    imageUrl={item.asset && defaultMedia ? generateCroppedThumbnail(item.customAssetImage || item.customMedia || defaultMedia, 87, 48, '16:9') : ''}
                                    onSelect={() => { }}
                                    asset={item.asset}
                                    isEditted={item.customTitle || (defaultMedia && item.customAssetImage ? (item.customAssetImage.id !== defaultMedia.id) : (item.customMedia && defaultMedia && (item.customMedia.id !== defaultMedia.id)))}
                                  /> : null }
                              {item.type === 'FILTER' ? <BucketAssetSearchList isSelected={selectedTaskIds.includes(item.id)} id={item.id} searchString={item.filterRule.searchKey} filter={item.filterRule.filters} isFilterOpen={this.isFilterOpen}
                                onChangeFilter={this.props.onChangeFilter} parent={parent} isSaveEnable={isSaveEnable} saveLoading={saveLoading} /> : null}
                              {item.type === 'LOADING' ? <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 2 }} loading /> : ''}
                            </div>
                            {(isFilterOpen || isUpdateBlocked) ? '' : (!_.isEmpty(selectedTaskIds) && selectedTaskIds.length >= 2) ? this.deleteSelected(menuId) : (this.AfterEdittedMenu(item.type, item.id, (item.customAssetImage || item.customMedia), item.customTitle, item.asset ? defaultMedia : undefined, item.isLoading, menuId))}
                            {shouldShowSelection ? (
                              // <SelectionCount>{selectedTaskIds.length}</SelectionCount>
                              <div className='selection-count'>{selectedTaskIds.length}</div>
                            ) : null}
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {!listData.length ? <div className='drag-asset-message'>
                  <DragAndDropIcon />
                  <p>Drag and drop Assets here </p>
                </div> : null}
              </div>
            )}
          </Droppable>
        </div>

        {showResetTitle
          ? <BucketResetTitleModal
            visible={showResetTitle}
            handleSubmit={this.handleAssetTitleReset}
            handleCancel={this.hideResetTitleModal}
            bucketAssetData={bucketAssetData}
            title='RESET DISPLAY TITLE'
          /> : null }
        {showEditTitle
          ? <BucketEditTitleModal
            visible={showEditTitle}
            handleSubmit={this.handleAssetTitle}
            handleCancel={this.hideEditTitleModal}
            title='EDIT DISPLAY TITLE'
            bucketAssetData={bucketAssetData}
          /> : null }
        {showResetDisplayImage
          ? <BucketResetDisplayImageModal
            visible={showResetDisplayImage}
            handleSubmit={this.handleAssetDisplayImageReset}
            handleCancel={this.hideResetImageModal}
            bucketAssetData={bucketAssetData}
            title='RESET DISPLAY IMAGE'
          /> : null }
        {showEditDisplayImage
          ? <BucketEditDisplayImageModal
            files={uploadingImage}
            clearFileName={this.clearFileName}
            handleFileUpload={this.handleFileUpload}
            parentCompoent={parentCompoent}
            visible={showEditDisplayImage}
            handleSubmit={this.handleAssetMedia}
            handleCancel={this.hideEditImageModal}
            title='EDIT DISPLAY IMAGE'
            bucketAssetData={bucketAssetData}
            assetId={bucketAssetData.asset.id}
            onAddImage={this.onAddImage}
            isCreateBlocked={isCreateBlocked}
            project={project}
            isUpdateDisabledContent={isUpdateDisabledContent}
            isCreateDisabledContent={isCreateDisabledContent}
            displayMediaId={bucketAssetData.customAssetImage ? bucketAssetData.customAssetImage.id : bucketAssetData.customMedia
              ? bucketAssetData.customMedia.id : bucketAssetData.asset.defaultMedia
                ? bucketAssetData.asset.defaultMedia.id : undefined
            }
          /> : null }
      </div>
    )
  }
}

BucketDetailList.propTypes = {
  /** isSearch of BucketDetailList. */
  isSearch: PropTypes.bool,
  /** isEditted of BucketDetailList. */
  isEditted: PropTypes.bool,
  /** listData of BucketDetailList. */
  listData: PropTypes.array,
  /** Function to be called on rearrange of buckets */
  onDragEnd: PropTypes.func,
  /** Item Id */
  bucketId: PropTypes.string,
  /** Function to be called on bucket item remove */
  removeBucketItem: PropTypes.func,
  /** display image change action of BucketDetailList. */
  onAssetDisplayImageChange: PropTypes.func
}

export default BucketDetailList
