import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImageContent from './../../components/ui/dataEntry/other/ImageContent'
import LoadingButton from './../../components/ui/general/buttons/LoadingButton'
import { utilityService } from '../../services/UtilityService'
import { Empty } from 'antd'
import _ from 'lodash'
const parentCompoent = 'partner-container'

let primaryImageType
class PartnerImages extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.uploadingFiles, this.props.uploadingFiles)) {
      this.imageContainerRef.scrollTop = this.imageContainerRef.scrollHeight + 500
    }
  }

  getFIlterImage = (images, imageType) => {
    return images.filter(image => {
      return imageType.id === primaryImageType ? (image.type.id === imageType.id || !image.type.id) : imageType.id === image.type.id
    })
  }

  renderImage = (imageType) => {
    const {
      images,
      onChangeDefaultImage,
      onCloseImage,
      showEditImage,
      isUpdateImageDisabled,
      uploadingFiles,
      project,
      defaultMedia
    } = this.props

    let filterdImageList = (images || []).sort((a, b) => {
      const dateA = new Date(a.createdAt)
      const dateB = new Date(b.createdAt)
      return dateA - dateB
    })
    let defaultImage = filterdImageList.filter(item => (defaultMedia && item.id === defaultMedia.id))
    filterdImageList = filterdImageList.filter(item => !(defaultMedia && item.id === defaultMedia.id))
    if (defaultImage.length) { filterdImageList = [...defaultImage, ...filterdImageList] }
    return filterdImageList.length ? _.uniqBy(filterdImageList, 'id').map((image) => image ? (
      <ImageContent
        key={image.id}
        mediaId={image.id}
        imageDetails={image}
        id={image.id}
        isAssetList
        isDefaultImage={!_.isEmpty(defaultMedia) && image.id === defaultMedia.id}
        makeDefaultImage={onChangeDefaultImage}
        name={image.name}
        imageUrl={image.url}
        onCloseContent={() => onCloseImage(image.id)}
        parentCompoent={parentCompoent}
        showEditImage={showEditImage}
        isUpdateImageDisabled={isUpdateImageDisabled}
        aspectRatio={image.aspectRatio}
        project={project}
      />
    ) : null) : ((uploadingFiles.length && uploadingFiles.filter(image => image.imageType === primaryImageType)) ? null : <Empty />)
  }

  renderUploadImage = (imageType) => {
    const { uploadingFiles, project } = this.props
    return (uploadingFiles && uploadingFiles.length) ? uploadingFiles.filter(image => image.imageType === primaryImageType)
      .map((uploadinImage) => {
        return <ImageContent
          isUploading
          key={utilityService.makeRandomString(7)}
          mediaId={uploadinImage.id}
          id={uploadinImage.id}
          name={uploadinImage.fileName}
          isAssetList
          parentCompoent={parentCompoent}
          showEditImage={false}
          isUpdateImageDisabled={false}
          imageDetails={uploadinImage}
          project={project}
        />
      }) : null
  }

  render () {
    const {
      isLoading
    } = this.props

    return (
      <div className='image-details'>
        <div className='image-content-parent' ref={(ref) => { this.imageContainerRef = ref }}>
          {this.renderImage()}
          {this.renderUploadImage()}
        </div>
        {isLoading ? <div className='overlay'>
          <LoadingButton isLoading />
        </div> : ''}
      </div>
    )
  }
}

PartnerImages.propTypes = {
  /** images of PartnerImages. */
  images: PropTypes.array,
  /** default image of PartnerImages. */
  defaultMedia: PropTypes.object,
  /** default image change action callback of PartnerImages. */
  onChangeDefaultImage: PropTypes.func,
  /** close image action callback of PartnerImages. */
  onCloseImage: PropTypes.func,
  /** close image action callback of PartnerImages. */
  showAddImages: PropTypes.func,
  /** edit image action callback of PartnerImages. */
  showEditImage: PropTypes.func,
  /** Boolean to disable button */
  isButtonDisabled: PropTypes.bool
}

export default PartnerImages
