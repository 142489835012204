import React from 'react'
import PropTypes from 'prop-types'

const GroupIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill={color} fillRule='evenodd'>
      <path d='M0 0h19v5H0zM0 7h4v5H0zM5 7h4v5H5zM10 7h4v5h-4zM15 7h4v5h-4zM0 14h19v5H0z' />
    </g>
  </svg>
)

GroupIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

GroupIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default GroupIcon
