import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateGenreModal from '../../../components/ui/dataEntry/other/CreateGenreModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGenre from './../../../graphQL/admin/entertainmentData/listGenre'
import MutationDeleteGenre from '../../../graphQL/admin/entertainmentData/deleteGenre'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

const deleteGenreMessage = {
  title: 'DELETE GENRE',
  firstMessage: 'Are you sure you want to delete this Genre?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class GenreList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedGenre: null,
      isLoading: !(props.genreList && props.genreList.length),
      showAddGenreModal: false,
      showDeleteGenreModal: false,
      selectedGenre: undefined,
      deleteLoading: false,
      isDeleted: false,
      genreSearch: { searchString1: '', searchString2: '' }
    }
    this.columns = [
      {
        title: 'Genre',
        dataIndex: 'name',
        key: 'name',
        width: '37%',
        className: 'entertainment-data'
      },
      {
        title: 'Usage Count',
        dataIndex: 'assetCount',
        width: '50%',
        key: 'assetCount',
        render: (assetCount, { isError }) => this.renderAssetCount(assetCount, isError)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.genreList, this.props.genreList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.genreList, this.props.genreList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEqual(newProps.genreList, this.props.genreList) && this.props.genreFilter && this.props.genreFilter.searchString) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.genreList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedGenre) {
        this.setState({ openedGenre: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedGenre === id) {
        this.setState({ openedGenre: null })
      } else {
        this.setState({ openedGenre: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { genreList } = this.props
      const selectedGenre = genreList.find(item => item.id === id)
      if (!selectedGenre) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddGenreModal: true, selectedGenre })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteGenreModal: true, selectedGenre })
      }
      this.setState({ openedGenre: null })
    }

    onGenreSelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedGenre: selectedValue })
      } else {
        this.setState({ fromSelectedGenre: selectedValue })
      }
    }

    showAddGenre = () => {
      this.setState({ showAddGenreModal: true })
    }

    hideAddGenreModal = () => {
      this.setState({ showAddGenreModal: false, selectedGenre: null })
    }

    handleAddGenre = () => {
      this.setState({ showAddGenreModal: false, selectedGenre: null })
    }

    hideDeleteGenreModal = () => {
      this.setState({ isDeleted: true, selectedGenre: null }, () => {
        this.setState({ showDeleteGenreModal: false, isDeleted: false })
      })
    }

    handleDeleteGenre = () => {
      const { selectedGenre } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedGenre) {
        return
      }
      this.setState({ deleteLoading: true })
      this.props.deleteGenre(selectedGenre.id, selectedGenre.name).then(() => {
        message.success('Genre deleted successfully')
        this.setState({ isDeleted: true, selectedGenre: null }, () => {
          this.setState({ showDeleteGenreModal: false, deleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedGenre: null }, () => {
          this.setState({ showDeleteGenreModal: false, deleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    onSearchOptions = (value, isFirst) => {
      let { genreSearch } = this.state
      if (isFirst) {
        genreSearch.searchString1 = value
      } else {
        genreSearch.searchString2 = value
      }
      this.setState({ genreSearch })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangeGenreFilter({ searchString: value })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.genreList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreGenres(this.props.genreList.length))
    }

    renderAssetCount = (count, isError) => {
      if (isError) {
        return <div>{'Error in Transfer. Retry'}</div>
      } else if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedGenre } = this.state
      const { genreList } = this.props
      const selectedGenre = genreList.find(item => item.id === id)
      if (selectedGenre && (!selectedGenre.assetCount || !parseInt(selectedGenre.assetCount)) && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (!selectedGenre.assetCount || !parseInt(selectedGenre.assetCount)) {
        // availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedGenre === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { genreList, genreFilter, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showAddGenreModal, showDeleteGenreModal, selectedGenre, deleteLoading, isDeleted } = this.state
      let adminItem
      if (selectedGenre && showDeleteGenreModal) {
        adminItem = <div >
          <p>{ selectedGenre.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal entertainment-list'>
              <FilterInput
                searchString={genreFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
                project={project}
              />
              <div className='admin-list genre-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > genreList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={genreList} pagination={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddGenre}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreateGenreModal
                title={'Genre'}
                isVisible={showAddGenreModal}
                handleCancel={this.hideAddGenreModal}
                handleSubmit={this.handleAddGenre}
                selectedGenre={selectedGenre}
                listApi module='DATA_MANAGER'
                isSubmitDisabled={_.isEmpty(selectedGenre) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
                searchString={genreFilter.searchString}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteGenreModal}
                title={deleteGenreMessage.title}
                firstMessage={deleteGenreMessage.firstMessage}
                secondMessage={deleteGenreMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteGenreModal}
                handleSubmit={this.handleDeleteGenre}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

GenreList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryGenre,
    {
      options: ({ genreFilter, project }) => {
        const { searchString } = genreFilter
        const search = searchString ? { keyword: searchString, fields: ['name'] } : null
        const variables = { search, offset: 0, limit: 20, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let genreList = data.listGenre ? data.listGenre.items : []
        genreList = genreList.map(item => {
          if (item.isTransferring) {
            item.assetCount = 'Transferring'
          }
          return item
        })
        return {
          genreList,
          isLoading: data.loading || !data.listGenre,
          totalCount: data.listGenre ? data.listGenre.totalCount : 0,
          getMoreGenres: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listGenre.items, ...fetchMoreResult.listGenre.items]
                prev.listGenre.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteGenre, {
      options: ({ genreFilter, project }) => ({
        update: (cache, { data: { deleteGenre } }) => {
          const { searchString } = genreFilter
          const search = searchString ? { keyword: searchString, fields: ['name'] } : null
          const variables = { search, offset: 0, limit: 20, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGenre, variables }))
          if (cacheData && cacheData.listGenre && cacheData.listGenre.items) {
            const selectedIndex = cacheData.listGenre.items.findIndex(item => item.id === deleteGenre.id)
            if (selectedIndex > -1) {
              cacheData.listGenre.items.splice(selectedIndex, 1)
              cacheData.listGenre.totalCount = cacheData.listGenre.totalCount - 1
            }
          }
          cache.writeQuery({
            query: QueryGenre,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteGenre: (id, name) => {
          return props.mutate({
            variables: { id, name, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(GenreList))
