import React from 'react'
import PropTypes from 'prop-types'
import { Select, Tooltip } from 'antd'

import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

const Option = Select.Option

const filter = (input, option, options) => {
  const currentOption = (options || []).find(item => item.id === option.props.value)
  const returnValue = currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

class SchemaDropDown extends React.Component {
    state = {
      isSearchPresent: false
    }

    onOptionSelect = (id) => {
      const selectedSchema = (this.props.schemas || []).find(item => item.id === id)
      this.props.onSchemaSelect(selectedSchema, this.props.index)
    }

    onSchemaSearch = (value) => {
      this.setState({ isSearchPresent: true })
      if (this.props.onSearch) { this.props.onSearch(value) }
    }

    onDropdownVisibleChange = (isOpen) => {
      if (!isOpen) { this.setState({ isSearchPresent: false }) }
    }

    render () {
      const { schemas, selectedSchema, parentCompoent, isDisable } = this.props
      let optionComponents = (schemas || []).map((option, index) => {
        return (
          <Option key={option.id} value={option.id}>
            <Tooltip overlayClassName='drop-down-tooltip' placement='right' title={<p>{ option.name }  ({utilityService.getSourceDetails(option.source)} ) </p>}>
              <p> { option.name }</p>
            </Tooltip>
          </Option>
        )
      })

      return (

        <div className='schema-drop-down'>

          <Select
            key={JSON.stringify(selectedSchema)}
            allowClear
            dropdownClassName='schema-dropdown-popup'
            suffixIcon={<ExpandedIcon />}
            clearIcon={<CrossIcon />}
            value={selectedSchema || undefined}
            placeholder='Select Schema'
            showSearch
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onChange={this.onOptionSelect}
            onSearch={this.onSchemaSearch}
            optionFilterProp={'name'}
            filterOption={(input, option) => filter(input, option, schemas)}
            onDropdownVisibleChange={this.onDropdownVisibleChange}
            disabled={isDisable}
          >
            { optionComponents }
          </Select>
        </div>
      )
    }
}
SchemaDropDown.propTypes = {
  /** Option selection action of Dropdown. */
  onSchemaSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func,
  /** Selected value of Dropdown. */
  selectedSchema: PropTypes.string,
  /** data for SchemaDropDown. */
  schemas: PropTypes.array,
  /** parentcomponent for position */
  parentCompoent: PropTypes.string,
  /** search string for schemaSearch */
  schemaSearch: PropTypes.string
}

export default SchemaDropDown
