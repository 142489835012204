import gql from 'graphql-tag'

export default gql(`
    mutation createApp($name: String! $key: String! $project: String $appIcon: String $description: String $bundleId: String $customMeta: [MetaFieldInput] $url: String $position: Int $isCritical: Boolean $assignedUsers: [String]
        ) {
        createApp(
            input: {
                name: $name
                key: $key
                appIcon: $appIcon
                description: $description
                bundleId: $bundleId
                customMeta: $customMeta
                url: $url
                position: $position
                isCritical: $isCritical 
                assignedUsers: $assignedUsers
            },
            project: $project
        ) {
            id
            name
            project
            updatedAt
            url
            key
            position
            assignedUsers
            description
            createdAt
            bundleId
            configUsageCount
            pageUsageCount
            widgetUsageCount
            isCritical
            assignedUsers
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
            appIcon{
              id
              mediaId
              project
              name
              url
              fileName
              aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                  }
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2          
              }
            }
        }
               
    }`
)
