import React from 'react'
import PropTypes from 'prop-types'
import { generateCroppedThumbnail } from './../../../util/util'
import './../ui.style.scss'

class MatchExpandedViewDetails extends React.Component {
  render () {
    const { teamA, teamB } = this.props
    return (
      <div className='match-expanded-view-details'>
        <div className='match'>
          <p>{teamA.code}</p>
          <img src={
            teamA.media && teamA.media.length && teamA.media[0]
              ? generateCroppedThumbnail(teamA.media[0], 200, 200, '1:1')
              : require('../../../assets/images/avatar-team-icon.png')
          } />
        </div>
        <p className='vs'>VS</p>
        <div className='match'>
          <img
            src={
              teamB.media && teamB.media.length && teamB.media[0]
                ? generateCroppedThumbnail(teamB.media[0], 200, 200, '1:1')
                : require('../../../assets/images/avatar-team-icon.png')
            }
          />
          <p>{teamB.code}</p>
        </div>
      </div>
    )
  }
}

MatchExpandedViewDetails.propTypes = {
  /** team A details of MatchListViewDetails */
  teamA: PropTypes.object,
  /** team A details of MatchListViewDetails */
  teamB: PropTypes.object
}

MatchExpandedViewDetails.defaultProps = {}

export default MatchExpandedViewDetails
