import React from 'react'
import PropTypes from 'prop-types'

const ViewPageIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <rect width={width} height={height} rx='2' />
      <path d='M9 9h20v20H9z' />
      <g fill={color} fillRule='nonzero' transform='translate(9 12.75)'>
        <circle cx='10' cy='6.25' r='3.75' />
        <path d='M10 0C3.75 0 0 6.211 0 6.211S3.75 12.5 10 12.5s10-6.25 10-6.25S16.25 0 10 0zm0 11.25c-5.469 0-8.438-5-8.438-5s2.97-5 8.438-5c5.469 0 8.438 5 8.438 5s-2.97 5-8.438 5z' />
      </g>
    </g>
  </svg>

)

ViewPageIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string
}

ViewPageIcon.defaultProps = {
  width: 34,
  height: 34,
  color: '#343A40'
}

export default ViewPageIcon
