import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import Input from '../inputs/Input'
import InformationIcon from '../../general/icons/InformationIcon'
import './../../ui.style.scss'

import QueryAllCategory from '../../../../graphQL/asset/getContentCategory'
import MutationAssetCategory from '../../../../graphQL/asset/createCategory'
import MutationUpdateCategory from '../../../../graphQL/admin/asset/updateCategory'
import QueryCategory from '../../../../graphQL/admin/asset/listCategory'
import { utilityService } from '../../../../services/UtilityService'

class CreateCategoryModal extends Component {
    state = {
      categoryName: undefined,
      categorySlug: undefined,
      isLoading: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedCategory && (!_.isEqual(this.props.selectedCategory, nextProps.selectedCategory) || (!this.state.categoryName && !this.state.categorySlug))) {
        this.setState({
          categoryName: nextProps.selectedCategory.name,
          categorySlug: nextProps.selectedCategory.slug
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      let { categorySlug } = this.state
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          // if (!this.props.selectedCategory) {
          //   if ((!categorySlug && !categoryName) || categorySlug === utilityService.slugifyString(categoryName || '').toUpperCase()) {
          //     categorySlug = utilityService.slugifyString(value || '').toUpperCase()
          //   }
          // }
          categorySlug = utilityService.slugifyString(value || '')
          this.setState({ categoryName: value, categorySlug })
        }
      }
    }

    handleAddCategory = () => {
      const { categoryName, categorySlug } = this.state
      const { selectedCategory, module, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })

      if (selectedCategory) {
        const newCategory = {
          id: selectedCategory.id,
          name: categoryName,
          slug: categorySlug
        }
        if (!categorySlug || !categoryName) {
          message.error('Name and slug are mandatory fields')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateAssetCategory(newCategory).then(response => {
          this.props.handleSubmit(response.data.updateAssetCategory)
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newCategory = {
          name: categoryName,
          slug: categorySlug,
          module
        }
        this.props.createAssetCategory(newCategory).then(response => {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          message.success('Category created successfully')
          this.props.handleSubmit(response.data.createAssetCategory)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    resetState = () => {
      const state = {
        categoryName: '',
        categorySlug: '',
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedCategory, isSubmitDisabled } = this.props
      const { categoryName, categorySlug, isLoading } = this.state
      const disabled = selectedCategory ? (selectedCategory.name === categoryName && selectedCategory.slug === categorySlug) : (!categoryName && !categorySlug)
      const slugInput = <div className='type'>
        <span>Slug</span>
        <div className='information-icon'>
          <InformationIcon />
        </div>
      </div>
      return (
        <Modal
          className='general-modal create-category-modal'
          title={selectedCategory ? 'EDIT CATEGORY' : 'NEW CATEGORY'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddCategory}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
          confirmLoading={isLoading}
        >
          <div className='create-category'>
            <Input title='Name' isRequired inputName='name' handleChange={this.handleChange} value={categoryName} placeholder='Enter Category name' />
            <Input title={slugInput} inputName='slug' handleChange={this.handleChange} value={categorySlug} placeholder='Enter slug' readOnly />
          </div>
        </Modal>
      )
    }
}

CreateCategoryModal.propTypes = {
  /** visible file status of CreateCategoryModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateCategoryModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

CreateCategoryModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationAssetCategory,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { createAssetCategory } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllCategory, variables }))
              if (cacheData && cacheData.getContentByField && cacheData.getContentByField.items) {
                cacheData.getContentByField.items.unshift(createAssetCategory)
              }
              cache.writeQuery({
                query: QueryAllCategory,
                data: cacheData,
                variables
              })
            } else {
              try {
                const variables = { search: null, offset: 0, limit: 20, project }
                const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCategory, variables }))
                if (cacheData && cacheData.listAssetCategories && cacheData.listAssetCategories.items) {
                  cacheData.listAssetCategories.items.unshift(createAssetCategory)
                }
                cache.writeQuery({
                  query: QueryCategory,
                  data: cacheData,
                  variables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        createAssetCategory: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateCategory,
    {
      options: ({ listApi }) => {
        return {
          update: (cache, { data: { updateAssetCategory } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0 }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllCategory, variables }))
              const index = cacheData.listAssetCategories.items.findIndex(item => item.id === updateAssetCategory.id)
              if (index > -1 && updateAssetCategory.name) {
                cacheData.listAssetCategories.items[index].name = updateAssetCategory.name
              }
              if (index > -1 && updateAssetCategory.slug) {
                cacheData.listAssetCategories.items[index].slug = updateAssetCategory.slug
              }
              cache.writeQuery({
                query: QueryAllCategory,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { filter: null, offset: 0, limit: 20 }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryCategory, variables: newVariables }))
                const index = newCacheData.listAssetCategories.items.findIndex(item => item.id === updateAssetCategory.id)
                if (index > -1 && updateAssetCategory.name) {
                  newCacheData.listAssetCategories.items[index].name = updateAssetCategory.name
                }
                if (index > -1 && updateAssetCategory.slug) {
                  newCacheData.listAssetCategories.items[index].slug = updateAssetCategory.slug
                }
                cache.writeQuery({
                  query: QueryCategory,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateAssetCategory: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateCategoryModal))
