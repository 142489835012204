import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GeneralFieldComponent from './GeneralFieldComponent'
import LoadingButton from '../general/buttons/LoadingButton'

class ConfigFieldObjects extends Component {
    state = {
    }

    render () {
      const { reorder, onCloseField, streamField, configName, isEnableSave, addFieldObjects, onOptionSelect, onChangeStatus, handleChange } = this.props
      return (
        <div className='config-field-objects'>
          <GeneralFieldComponent
            reorder={reorder}
            onCloseField={onCloseField}
            streamField={streamField}
            isEnableSave={isEnableSave}
            isTitleInvisible
            onOptionSelect={onOptionSelect}
            onChangeStatus={onChangeStatus}
            handleChange={handleChange}
            keyParam='name'
            columnTitle1={'Name'}
            columnTitle2={'Field Type'}
            columnTitle3={'Required'} />
          <LoadingButton
            type='primary'
            onClick={addFieldObjects}
            htmlType='submit'
            buttonText={'Add Field Objects'}
            buttonClass='save-btn'
            isLoading={false}
            isDisabled={!configName}
          />
        </div>
      )
    }
}

ConfigFieldObjects.propTypes = {
  /** visible file status of ConfigFieldObjects. */
  isVisible: PropTypes.bool,
  /** cancel action of ConfigFieldObjects. */
  handleCancel: PropTypes.func
}

ConfigFieldObjects.defaultProps = {
}

export default ConfigFieldObjects
