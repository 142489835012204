import React from 'react'

const NotificationIcon = ({ setNotificationIconRef, ...props }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20' ref={node => { setNotificationIconRef(node) }}>
      <defs>
        <path id='b1b026of6a' d='M0 0H20V20H0z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g transform='translate(-1835 -20) translate(1700 20) translate(135)'>
              <mask id='skajwrnyyb' fill='#fff'>
                <use href='#b1b026of6a' />
              </mask>
              <path fill='#FFF' d='M14.782 17.789c-.747 1.333-1.58 2-2.5 2-.921 0-1.754-.667-2.5-2zm-4.5-18c1.104 0 2 .895 2 2 0 .058-.003.116-.008.173 2.87.673 5.008 3.25 5.008 6.327v5.5c0 .552.447 1 1 1 .552 0 1 .447 1 1v.125c0 .552-.448 1-1 1h-16c-.553 0-1-.448-1-1v-.125c0-.553.447-1 1-1 .552 0 1-.448 1-1v-5.5c0-3.077 2.137-5.654 5.008-6.328-.006-.056-.008-.114-.008-.172 0-1.105.895-2 2-2z' mask='url(#skajwrnyyb)' transform='rotate(15 10.282 9.789)' />
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}

NotificationIcon.propTypes = {

}

export default NotificationIcon
