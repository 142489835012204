import React from 'react'
import PropTypes from 'prop-types'
import RetryIcon from '../icons/RetryIcon'
import { Icon, Spin } from 'antd'
import './../../ui.style.scss'

const antIcon = <Icon type='loading' style={{ fontSize: 19 }} spin />

const RetryButton = ({ isDisabled, isLoading, onClick, width, height, ...props }) => (
  <div className={`save-button ${isDisabled || isLoading ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick}>
    {isLoading ? <Spin indicator={antIcon} /> : <RetryIcon width={width} height={height} />}
  </div>
)

RetryButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

RetryButton.defaultProps = {
  isDisabled: false
}

export default RetryButton
