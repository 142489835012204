import React from 'react'
import PropTypes from 'prop-types'

const ImageThumbnailIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} fillRule='nonzero' d='M20 3.4C20 1.53 18.5 0 16.667 0H3.333C1.5 0 0 1.53 0 3.4v10.2C0 15.47 1.5 17 3.333 17h13.334C18.5 17 20 15.47 20 13.6V3.4zm-15 0c.917 0 1.667.765 1.667 1.7 0 .935-.75 1.7-1.667 1.7s-1.667-.765-1.667-1.7c0-.935.75-1.7 1.667-1.7zm13.333 10.2c0 .935-.75 1.7-1.666 1.7h-13c-.75 0-1.084-.935-.584-1.445l3-3.06a.795.795 0 0 1 1.167 0l.5.51c.333.34.833.34 1.167 0l5.5-5.61a.795.795 0 0 1 1.166 0l2.5 2.55c.167.17.25.34.25.595v4.76z' />
      <path d='M0-2h20v20H0z' />
    </g>
  </svg>
)

ImageThumbnailIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

ImageThumbnailIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#AFAFAF'
}

export default ImageThumbnailIcon
