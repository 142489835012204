import gql from 'graphql-tag'

export default gql(`
mutation($id: ID! $venue: String $tags: [ID] $isLiveOnly: Boolean $isBroadcast: Boolean $status: String $customMeta: [MetaFieldInput] $notLive: Boolean) {
    updateMatch(
        input: {
         id: $id
         customVenueName: $venue
         tags: $tags
         isLiveOnly: $isLiveOnly
         isBroadcast: $isBroadcast
         status: $status
         customMeta: $customMeta
         notLive: $notLive
      }) {
      id
      customVenueName
      tags{
        key
        name
        type
        module
      }
      isBroadcast
      notLive
      isLiveOnly
      asset{
        id
        isArchived
        shortId
        externalId
        isFree
        title
        type
        updatedAt
        publishStartDate
        publishEndDate
        status
        vodStatus{
          id
          status
          site
          updatedAt
          createdAt
          errorDesc
          startTime
          endTime
        }
        vodJobs{
                id
                status
                site
                name
                type
                jobType
                vwmJobId
                updatedAt
                createdAt
                createdBy
                progress
                errorDesc
                startTime
                endTime 
                jobStates{
                    status
                    errorCode
                    updatedAt
                }
                channel{
                    id
                }
                mediaId{
                    id
                    duration
                }  
                }
        program{
          id
          source
          isLiveTelecast
          channel{
              id
              name
              channelCode
              icon{
                  id
                  fileName
                  aspectRatio {
                      aspectRatio
                      ratio
                      title          
                      resolutions {
                      url
                      }
                  }
                  settings {
                      aspectRatio
                      outputFormat
                      fileName
                      x1
                      y1
                      x2
                      y2
                  }
              }
              activeConfigGroup{
                  id
                  name
                  streams{
                      url
                      fields{
                          name
                          value
                          isRequired
                          type
                      }
                      streamSchema{
                          id
                          levels{
                              id
                              name
                          }
                      }
                  }
              }
          }
          broadCastStartTime
          broadCastEndTime
        }
        defaultMedia {
          id
          url
          fileName
          aspectRatio {
            title
            aspectRatio
            resolutions {
              url
            }
          }
          settings {
            aspectRatio
            outputFormat
            fileName
            x1
            y1
            x2 
            y2
          }
        }
      }
      opId
      description
      kickOffTime
      matchDate
      finishTime
      externalId
      homeTeam{
        id
        opId
        code
        officialName  
        media {
            createdAt
            fileName
            url
            aspectRatio {
                aspectRatio
                ratio
                title          
                resolutions {
                  url
                }
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
        }
      }
      awayTeam{
        id
        opId
        code
        officialName  
        media {
            fileName
            createdAt
            aspectRatio {
                aspectRatio
                ratio
                title          
                resolutions {
                  url
                }
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
        }
      }
      competition{
        id
        opId
        title
      }
      stage{
        title
        id
      }
      tournamentCalendar{
        title
        id
      }
      venue{
        title
      }
      programs{
        id
        source
        isLiveTelecast
        channel{
            id
            name
            channelCode
            icon{
              id
              fileName
              aspectRatio {
                aspectRatio
                ratio
                title          
                resolutions {
                  url
                }
              }
              settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
              }
            }
            activeConfigGroup{
              id
              name
              streams{
                url
                fields{
                  name
                  value
                  isRequired
                  type
                }
                streamSchema{
                  id
                  levels{
                    id
                    name
                  }
                }
              }
            }
        }
        broadCastStartTime
        broadCastEndTime
      }
      status
      customMeta {
        config {
          default
          id
          name
          project
          section
          type
          usageCount
        }
        name
        value
      }
    }
}`)
