import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getVodStatus(
        jobId: $id
        ){
            id
            status
            site
            name
            updatedAt
            progress
            errorDesc
            startTime
            endTime 
            vwmJobId
            mediaId{
                id
                duration
            }
            channel{
                id
            }
            jobStates{
                status
                errorCode
                updatedAt
            }
    }
}`)
