import gql from 'graphql-tag'

export default gql(`
    mutation updateModuleConfig( $id: ID!, $value: [String], $key: [String],  $project: String, $module: String) {
        updateModuleConfig(
            input: {
                id: $id
                value: $value
                key: $key
                module: $module
            }
            project: $project
        ) {
            id
            value
            key
        }
    }`
)
