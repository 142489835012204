import gql from 'graphql-tag'

export default gql(`
    mutation gracenoteIngestUpdate($csvS3Key: String!) {
        gracenoteIngestUpdate(
            csvS3Key: $csvS3Key
        ) {
            status
            data {
                programIngestResult {
                    id
                }
                programScheduleIngestResult{
                    id
                }
            }
        }
    }`
)
