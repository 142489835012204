import gql from 'graphql-tag'

export default gql(`
    mutation autoAssociateImages($project: String! $assetId: ID!) {
        autoAssociateImages(
            input: {
                project: $project,
                id: $assetId
            }
        ) {
            id
            status
        }
    }`
)
