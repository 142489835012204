import gql from 'graphql-tag'

export default gql(`
query adminListPaymentTransactions( $subscriptionId: String!) {
    adminListPaymentTransactions( 
        subscriptionId: $subscriptionId
    ) {
        billingItemId
        itChargeId
        subscriptionStartDate
        subscriptionEndDate
        actualChargeDate
        expectedChargeDate
        paymentStatus
        amount
        latestITErrorPayload
    }
}`)
