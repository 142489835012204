import React from 'react'
import PropTypes from 'prop-types'

const DragAndDropIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <defs>
      <path id='a' d='M6.35 15.873h18.963v58.73a6.35 6.35 0 0 0 6.35 6.35H83.85V93.65A6.35 6.35 0 0 1 77.5 100H6.35A6.35 6.35 0 0 1 0 93.65V22.223a6.35 6.35 0 0 1 6.35-6.349z' />
      <mask id='b' width='83.851' height='84.127' x='0' y='0' fill='#fff'>
        <use href='#a' />
      </mask>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <use stroke='#EEE' strokeDasharray='15.87301587301587,6.349206349206349' strokeWidth='6.349' mask='url(#b)' href='#a' />
      <path fill='#EEE' d='M28.498 0h71.153A6.35 6.35 0 0 1 106 6.35v71.428a6.35 6.35 0 0 1-6.35 6.349H28.499a6.35 6.35 0 0 1-6.349-6.35V6.35A6.35 6.35 0 0 1 28.5 0zm.77 16.667v17.46h70.404v-17.46H29.269zm0 21.428v17.46h70.404v-17.46H29.269zm0 21.429v13.492a3.968 3.968 0 0 0 3.969 3.968h62.466a3.968 3.968 0 0 0 3.969-3.968V59.524H29.269z' />
    </g>
  </svg>
)

DragAndDropIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

DragAndDropIcon.defaultProps = {
  width: 106,
  height: 100
}

export default DragAndDropIcon
