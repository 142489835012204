import gql from 'graphql-tag'

export default gql(`
    mutation archiveAppConfig($title: String $key: String! $app: ID! $group: ID $type: AppConfigType $project: String $fields: [ConfigModelFieldInput] $id: ID! $tags: [ID] $isArchived: Boolean) {
        archiveAppConfig(
            input: {
                title: $title
                key: $key
                app: $app
                group: $group
                type: $type
                fields: $fields
                id: $id
                tags: $tags
                isArchived: $isArchived
            },
            project: $project
        ) {
            id
            isArchived
            isPublished
            createdBy
            createdAt
            key
            project
            publishEndDate
            title
            type
            group{
              key
              name
              type
            }
            fields{
                id
                type
                isRequired
                useAsTitle
                name
                position
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
        }
    }`
)
