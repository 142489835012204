import React from 'react'
import bitmovinPlayer from '../bitmovin/bitmovin'
import usePlayerTime from '../bitmovin/hooks/usePlayerTime'
const LiveIndicator = props => {
  const { isPlayingLiveEnd } = usePlayerTime()

  const seekToLive = () => {
    if (!isPlayingLiveEnd) {
      bitmovinPlayer.seekToLiveEnd()
    }
  }

  return (
    <div className='liveIndicator'>
      {!isPlayingLiveEnd ? (
        <div className='notlive' onClick={seekToLive}>
          <div className='liveCircle' />
          NOT LIVE
        </div>
      ) : (
        <div className='live'>LIVE</div>
      )}
    </div>
  )
}
export default LiveIndicator
