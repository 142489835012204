import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import Input from '../inputs/Input'
import { utilityService } from '../../../../services/UtilityService'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateRole from './../../../../graphQL/admin/entertainmentData/createRole'
import QueryRole from './../../../../graphQL/admin/entertainmentData/listRole'
import QueryRoleAsset from './../../../../graphQL/asset/hyperion/listRolesDetails'
import MutationUpdateRole from './../../../../graphQL/admin/entertainmentData/updateRole'

class CreateRoleModal extends Component {
    state = {
      roleName: undefined,
      isLoading: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedRole && (!_.isEqual(this.props.selectedRole, nextProps.selectedRole) || !this.state.roleName)) {
        this.setState({
          roleName: nextProps.selectedRole.name
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ roleName: value })
        }
      }
    }

    handleAddRole = () => {
      const { roleName } = this.state
      const { selectedRole, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })

      if (selectedRole) {
        const newRole = {
          id: selectedRole.id,
          name: roleName
        }
        if (!roleName) {
          message.error('Name is mandatory field')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateRole(newRole).then(response => {
          this.props.handleSubmit(response.data.updateRole)
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newRole = {
          name: roleName
        }
        this.props.createRole(newRole).then(response => {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          message.success('Role created successfully')
          this.props.handleSubmit(response.data.createRole)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    resetState = () => {
      const state = {
        roleName: '',
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedRole, isSubmitDisabled } = this.props
      const { roleName, isLoading } = this.state
      const disabled = selectedRole ? (selectedRole.name === roleName && selectedRole.name) : (!roleName)
      return (
        <Modal
          className='general-modal create-general-modal'
          title={selectedRole ? 'EDIT ROLE' : 'NEW ROLE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddRole}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
          confirmLoading={isLoading}
        >
          <div className='create-role'>
            <Input title='Name' isRequired inputName='name' handleChange={this.handleChange} value={roleName} placeholder='Enter Role name' />
          </div>
        </Modal>
      )
    }
}

CreateRoleModal.propTypes = {
  /** visible file status of CreateRoleModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateRoleModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

CreateRoleModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateRole,
    {
      options: ({ listApi, project, searchString, isFromAsset }) => {
        return {
          update: (cache, { data: { createRole } }) => {
            if (isFromAsset) {
              try {
                let variables = { limit: 999, project: project }
                const cacheData = _.cloneDeep(cache.readQuery({ query: QueryRoleAsset, variables }))
                if (cacheData && cacheData.listRoles && cacheData.listRoles.items) {
                  delete (createRole.usedCount)
                  cacheData.listRoles.items.unshift(createRole)
                }
                cache.writeQuery({
                  query: QueryRoleAsset,
                  data: cacheData,
                  variables
                })
              } catch (e) {}
            } else {
              try {
                let variables = { limit: 20, offset: 0, project: project, search: searchString || null }
                const cacheData = _.cloneDeep(cache.readQuery({ query: QueryRole, variables }))
                if (cacheData && cacheData.listRoles && cacheData.listRoles.items) {
                  cacheData.listRoles.items.unshift(createRole)
                }
                cache.writeQuery({
                  query: QueryRole,
                  data: cacheData,
                  variables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        createRole: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateRole,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateRole } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project: project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryRole, variables }))
              const index = cacheData.listRoles.items.findIndex(item => item.id === updateRole.id)
              if (index > -1 && updateRole.name) {
                cacheData.listRoles.items[index].name = updateRole.name
              }
              cache.writeQuery({
                query: QueryRole,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { filter: null, offset: 0, limit: 20, project: project }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryRole, variables: newVariables }))
                const index = newCacheData.listRoles.items.findIndex(item => item.id === updateRole.id)
                if (index > -1 && updateRole.name) {
                  newCacheData.listRoles.items[index].name = updateRole.name
                }
                cache.writeQuery({
                  query: QueryRole,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateRole: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateRoleModal))
