import gql from 'graphql-tag'

export default gql(`
    mutation updateVodSettingsInput($aws_region: String $callbackUrl: String $cognitoClientId: String $cognitoPoolId: String $encodingTemplate: String
        $mediaVodReqUrl: String $password: String $requestUrl: String $username: String $vodRequestUrl: String $xApiKey: String) {
            updateVodSettings(
            input: {
                aws_region: $aws_region
                callbackUrl: $callbackUrl
                cognitoClientId: $cognitoClientId
                cognitoPoolId: $cognitoPoolId
                encodingTemplate: $encodingTemplate
                mediaVodReqUrl: $mediaVodReqUrl
                password: $password
                requestUrl: $requestUrl
                username: $username
                vodRequestUrl: $vodRequestUrl,
                x_api_key: $xApiKey,
            }
        ) {
            aws_region
            callbackUrl
            cognitoClientId
            cognitoPoolId
            encodingTemplate
            id
            mediaVodReqUrl
            password
            requestUrl
            username
            vodRequestUrl
            x_api_key
        }
    }`
)
