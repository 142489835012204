import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Input, Skeleton, Empty, message } from 'antd'
import { GlobalHotKeys } from 'react-hotkeys'
import InfiniteScroll from 'react-infinite-scroller'
import { withRouter } from 'react-router-dom'
import NProgress from 'nprogress'

import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
import BackArrowButton from './../../components/ui/general/buttons/BackArrowButton'
import AddButton from './../../components/ui/general/buttons/AddButton'
import SortInput from './../../components/ui/dataEntry/inputs/SortInput'
import PartnerListCell from './../../components/ui/dataDisplay/PartnerListCell'
import LoggerService from './../../services/LoggerService'
import { utilityService } from './../../services/UtilityService'
import userMessages from './../../constants/messages'
import AuthService from '../../services/AuthService'
import { generateCroppedThumbnail } from './../../util/util'
import StatusFilterIcon from '../../components/ui/general/icons/StatusFilterIcon'
import IconDropDown from '../../components/ui/dataEntry/inputs/IconDropDown'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { InMemoryCache } from 'apollo-cache-inmemory'
import MutationCreatePartner from './../../graphQL/partner/createPartnerDetails'
import QueryFilterPartners from './../../graphQL/partner/searchPartner'
import AppContext from '../../AppContext'

const keyMap = {
  shortcutNew: 'n',
  shortcutNext: 'j',
  shortcutPrev: 'k'
}

// const archiveFilter = [
//   {
//     name: 'Archived',
//     key: '1',
//     value: true
//   }
// ]

const statusFilter = [
  {
    name: 'Active',
    key: '1',
    value: 1
  },
  {
    name: 'Inactive',
    key: '2',
    value: 2
  },
  {
    name: 'Archived',
    key: '3',
    value: 3
  }
]

const filterList = [
  {
    displayName: 'Status',
    name: 'isActive',
    type: 'BOOLEAN',
    value: null,
    fieldName: 'STATUS'
  }
]

const width = ['calc(100% - 100px)', '50%', '100%']

class PartnerList extends Component {
  constructor (props) {
    super(props)
    const urlFilter = window.location.search
    let filterData = urlFilter && urlFilter.split('?filter=').length > 1 ? JSON.parse(decodeURI((urlFilter.split('?filter=')[1]))) : (localStorage.getItem('filterData'))
    filterData = typeof (filterData) === 'string' ? JSON.parse(filterData) : filterData
    let state = {
      partnerList: [],
      searchString: '',
      isLoading: true,
      isArchival: false,
      isPaginating: false,
      isSearching: false,
      archiveWarning: false,
      selectedIds: [],
      filterData: filterData || [],
      shouldShowStatusFilter: false
    }
    if (filterData) {
      localStorage.removeItem('filterData')
      localStorage.setItem('filterData', JSON.stringify(filterData))
      props.changeFilterValue(filterData)
      props.history.push('?filter=' + encodeURI(JSON.stringify(filterData)))
    }
    this.cache = new InMemoryCache()
    this.lastAppliedSort = {
      value: 'name',
      order: 'desc'
    }
    this.getUserDetails()
    if (this.props.partnerList && this.props.partnerList.length) {
      this.props.changedPartner(this.props.partnerList[0].id)
      if (!this.props.isLoading) {
        state.partnerList = this.props.partnerList
        state.isLoading = false
      }
    } else if (!this.props.isLoading) {
      state.isLoading = false
    }
    this.state = { ...state }
    if (props.match.params.id && !(filterData && filterData.length)) { props.onChangeFilter(props.match.params.id) }
  }

  componentDidMount = () => {
    this.setState({ partnerList: this.props.partnerList })
    if (this.state.searchString !== this.props.searchString) { this.setState({ searchString: this.props.searchString }) }
    this.getUserDetails()
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps=(newProps) => { // eslint-disable-line camelcase
    if (!newProps.isLoading && (!this.lastSearched || this.lastSearched.searchString !== newProps.searchString || this.lastSearched.filter !== newProps.filterVal || this.lastSearched.sort !== newProps.sort)) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
      // this.replaceCacheSearchString(newProps.searchString)
      this.setState({ partnerList: newProps.partnerList, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.partnerList, this.props.partnerList)) {
      this.setState({ partnerList: newProps.partnerList, isSearching: false, isPaginating: false, isLoading: false })
    }
    if (newProps.partnerList && newProps.partnerList.length && !newProps.selectedPartnerId) {
      this.props.changedPartner(newProps.partnerList[0].id)
    }
    if (newProps.searchString !== this.state.searchString) {
      this.setState({ searchString: newProps.searchString, isSearching: true })
    }
    if (newProps.deletedIds !== this.props.deletedIds && newProps.deletedIds.length) {
      const { partnerList } = this.state
      const index = newProps.deletedIds.indexOf(newProps.selectedPartnerId)
      const isDeleteCompleted = partnerList.findIndex(partner => partner.id === newProps.deletedIds[0]) === -1
      if (isDeleteCompleted && partnerList.length) {
        this.props.changedPartner(partnerList[0].id)
      } else if (index > -1) {
        this.setNewSelectedPartner(newProps)
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setNewSelectedPartner = newProps => {
    const { partnerList } = this.state
    if (partnerList.length > newProps.deletedIds.length) {
      let nextSelectedId
      let tempPartnerList = [...partnerList]
      let selectedId = newProps.selectedPartnerId
      while (tempPartnerList.length) {
        const tempIndex = (tempPartnerList || []).findIndex(partner => partner.id === selectedId)
        const id = tempPartnerList[ tempIndex ? tempIndex - 1 : 1 ].id
        const nextIdPositionInDeletedIds = (newProps.deletedIds || []).indexOf(id)
        if (nextIdPositionInDeletedIds === -1) {
          nextSelectedId = id
          break
        }
        tempPartnerList = (tempPartnerList || []).filter(partner => partner.id !== selectedId)
        selectedId = id
      }
      if (tempPartnerList.length && nextSelectedId) {
        setTimeout(() => this.props.changedPartner(nextSelectedId), 500)
      } else {
        setTimeout(() => this.props.history.push('/partners'), 2000)
      }
    } else {
      setTimeout(() => this.props.history.push('/partners'), 2000)
    }
  }

  getUserDetails = async () => {
    const userDetails = await AuthService.getUserDetails()
    if (userDetails) {
      this.username = userDetails.name || userDetails.email
    }
  }

  onItemCheck=(id) => {
    let { partnerList, selectedIds } = this.state
    const index = (partnerList || []).findIndex(asset => asset.id === id)
    if (index > -1) {
      const selectedIndex = (selectedIds || []).indexOf(id)
      if (selectedIndex > -1) {
        selectedIds.splice(selectedIndex, 1)
      } else {
        selectedIds.push(id)
      }
      this.setState({ selectedIds })
    }
  }

  onHandleArchival = () => {
    this.setState({ isArchival: true })
    this.props.archiveButtonHandle(true)
  }

  onHandleBack =() => {
    this.setState({ isArchival: false, selectedIds: [] })
    this.props.archiveButtonHandle(false)
  }

  toggleWarningMessage=(status) => {
    this.setState({ archiveWarning: status })
  }

  onCheckAllChange=(e) => {
    const { partnerList } = this.state
    let selectedIds
    if (this.state.selectedIds.length !== partnerList.length) {
      selectedIds = partnerList.map(offer => offer.id)
    } else {
      selectedIds = []
    }
    this.setState({ selectedIds })
  }
  onHandleArchiveButton = () => {
    this.toggleWarningMessage(true)
  }

  onSortChange = (sortData) => {
    this.setState({ isSearching: true })
    this.lastAppliedSort = sortData
    this.props.onChangeSort(sortData)
  }

  onSearchChange = e => {
    let { value } = e.target
    if (value.startsWith(' ')) { value = value.trim() }
    this.setState({ searchString: value, isSearching: true })
    if (this.delayTime) { clearTimeout(this.delayTime) }
    this.delayTime = setTimeout(() => {
      clearTimeout(this.delayTime)
      this.props.onChangeFilter(value)
    }, 750)
  }

  loadMorePartners = () => {
    if (this.props.totalCount === this.props.partnerList.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getPartnerList(this.state.partnerList.length))
  }

  onPartnerCreateSelection = (selectedType) => {
    const newPartner = {
      name: 'Untitled'
    }
    if (this.state.isLoading) {
      return
    }
    NProgress.start()
    this.forceLoading = true
    this.setState({ isLoading: true, isClearFilter: true }, () => {
      this.props.createPartner(newPartner).then(response => {
        this.setState({ isLoading: false, isClearFilter: false, initialSearch: true })
        this.resetFilters()
        this.containerRef.scrollTop = 0
        LoggerService.info('flow', `Created offer ${response.data.createPartner.id}`)
        this.props.changedPartner(response.data.createPartner.id)
        setTimeout(() => {
          this.forceLoading = false
          this.onChangeFilter(response.data.createPartner.id)
        }, 2000)
        NProgress.done()
        message.success(userMessages.PARTNER_CREATE_SUCCESS)
      }, error => {
        this.setState({ isLoading: false, isClearFilter: false, initialSearch: true })
        this.resetFilters()
        setTimeout(() => {
          this.forceLoading = false
          this.onChangeFilter(this.props.selectedPartnerId)
        }, 2000)
        utilityService.handleError(error)
      })
    })
  }

  onChangeFilter = (e) => {
    this.setState({ isSearching: true })
    const sortData = {
      value: 'name',
      order: 'desc'
    }
    if (e && e.length) {
      this.props.onChangeSort(sortData)
    } else {
      this.props.onChangeSort(this.lastAppliedSort)
    }
    this.props.onChangeFilter(e)
    setTimeout(() => {
      this.setState({
        initialSearch: false
      })
    }, 100)
  }

  resetFilters = () => {
    const sortData = {
      value: 'name',
      order: 'desc'
    }
    this.lastAppliedSort = sortData
    this.setState({ isSearching: true })
    this.props.onChangeFilter('')
    this.props.onChangeSort(sortData)
    this.changeFilterValue([])
  }

  changeFilterValue = (value) => {
    this.props.changeFilterValue(value)
    localStorage.removeItem('filterData')
    // const urlValue = value.filter((item) => item.value || item.value !== null).map((item) => {
    //   delete (item.__typename)
    //   return item
    // })
    // if (urlValue && urlValue.length) {
    //   const filter = this.encodeFilter(urlValue)
    //   localStorage.setItem('filterData', filter)
    //   // this.props.history.push('?filter=' + JSON.stringify(urlValue))
    //   this.props.history.push('?filter=' + encodeURI(filter))
    // } else {
    //   this.props.history.push('?')
    // }
  }

  handleClickOutside = (event) => {
    if (this.state.shouldShowCreate) {
      setTimeout(() => this.setState({ shouldShowCreate: false }), 200)
    }
    if (!(this.filterContainer && this.filterContainer.contains(event.target))) {
      this.setState({ shouldShowStatusFilter: false })
    }
  }

  toggleStatusFilter = () => {
    this.setState({ shouldShowStatusFilter: !this.state.shouldShowStatusFilter })
  }

  onStatusSelect=(selectedStatus) => {
    if (selectedStatus) {
      const selectedItem = (statusFilter || []).find((item) => item.key === selectedStatus)
      this.onChangingQuickFilter(selectedItem.value, 'isActive')
    } else {
      this.onChangingQuickFilter(null, 'isActive')
    }
  }

  onChangingQuickFilter = (value, key) => {
    let filterData = _.cloneDeep(this.state.filterData)
    const offerTypeFilterIndex = (filterData || []).findIndex((item) => item.name === key)
    if (offerTypeFilterIndex < 0) {
      let newfilterList = _.cloneDeep(filterList)
      const index = (newfilterList || []).findIndex((item) => item.name === key)
      newfilterList[index].value = value
      filterData.push(newfilterList[index])
      this.setState({ isSearching: true, filterData }, () => {
        this.changeFilterValue(filterData)
      })
    } else {
      filterData[offerTypeFilterIndex].value = value
      this.setState({ isSearching: true, filterData }, () => {
        this.changeFilterValue(filterData)
      })
    }
  }

  getSelectedStatus=() => {
    const { filterData } = this.state
    let selectedFilter = filterData.find((item) => (item.name === 'isActive'))
    if (_.isEmpty(selectedFilter)) {
      return null
    } else {
      const selectedValue = (statusFilter || []).find((item) => (item.value === selectedFilter.value))
      return selectedValue ? selectedValue.key : null
    }
  }

  render () {
    const { partnerList, isPaginating, isLoading, isArchival, selectedIds, isSearching, searchString, shouldShowStatusFilter } = this.state
    const { sort, project, selectedPartnerId, changedPartner, offerFilter } = this.props
    const selectedStatus = this.getSelectedStatus()
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['PARTNER_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          return <>
            <div className='partner-list' id='partner-list'>
              <GlobalHotKeys keyMap={keyMap} handlers={{}} />
              <div className='filter'>
                <Input
                  placeholder='Search Partner' allowClear onChange={this.onSearchChange} value={searchString} />
              </div>
              <div className='sort-filter-container' id='sort-filter-container'>
                <SortInput type='PartnerManager' selectedOrder={sort.order} selectedSort={sort.value} onSortChange={this.onSortChange} parent='sort-filter-container' manager='partner' project={project} />
                <div className='partner-quick-filter' id='partner-quick-filter' ref={node => { this.filterContainer = node }}>
                  <IconDropDown
                    icon={<StatusFilterIcon onClick={this.toggleStatusFilter} color={selectedStatus ? '#FF015A' : ''} />}
                    displayParam='name'
                    contents={statusFilter || []}
                    onContentClick={this.onStatusSelect}
                    parentCompoent={'partner-quick-filter'}
                    isVisible={shouldShowStatusFilter}
                    selectedOption={selectedStatus}
                    keyParam='key'
                    multiSelect={false}
                  />
                </div>
              </div>

              <div className='table' ref={(node) => { this.containerRef = node }}>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={this.forceLoading || (isLoading && !isSearching && !isPaginating)} />
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isSearching && !this.forceLoading}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMorePartners}
                    hasMore={this.props.totalCount > partnerList.length}
                    initialLoad={false}
                    useWindow={false}
                  >
                    { !this.forceLoading && partnerList && partnerList.length ? partnerList.map(partner => {
                      let imageUrl
                      if (partner.logo) {
                        imageUrl = generateCroppedThumbnail(partner.logo, 23, 23, '1:1')
                      }
                      return (<PartnerListCell
                        key={partner.id || ''}
                        imageUrl={imageUrl}
                        status={partner.isActive}
                        partnerName={partner.name || ''}
                        partnerId={partner.id || ''}
                        partnerExtId={partner.externalId || ''}
                        numOfOffers={partner.offerCount}
                        onSelect={changedPartner}
                        isSelected={selectedPartnerId === partner.id}
                        isArchival={isArchival}
                        isChecked={selectedIds.indexOf(partner.id) > -1}
                        onCheck={this.onItemCheck}
                        offerFilter={offerFilter}
                        project={project}
                        sort={sort}
                      />)
                    }) : (!isLoading && !isPaginating && !this.forceLoading) ? <Empty /> : <div /> }
                  </InfiniteScroll>
                </Skeleton>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={!this.forceLoading && isPaginating && this.props.totalCount > partnerList.length} />

              </div>
              <div className='partner-list-footer' id={'partner-list-footer'}>
                {!isArchival
                  ? <React.Fragment>
                    {/* <ButtonContainer displayTitle='Mutiple Archive' childComponent={<MultipleSelectButton onClick={this.onHandleArchival} isDisabled={!partnerList.length || isArchivalFilterApplied} />} /> */}
                    <ButtonContainer displayTitle='Create partner' childComponent={<AddButton onClick={this.onPartnerCreateSelection} isDisabled={isCreateDisabled} />} />
                  </React.Fragment>
                  : <React.Fragment>
                    <Checkbox onChange={this.onCheckAllChange} checked={selectedIds.length && selectedIds.length === partnerList.length} disabled={false} />
                    <div className='archieve-bottom'>
                      <ButtonContainer displayTitle='Back' childComponent={<BackArrowButton onClick={this.onHandleBack} />} />
                      <div>
                        <ButtonContainer displayTitle='Archive Selected partners' childComponent={<ArchiveButton type='primary' isDisabled={false} onClick={this.onHandleArchiveButton} />} />
                      </div>
                    </div>
                  </React.Fragment>
                }

              </div>
            </div>
        </>
        }}
      </AppContext.Consumer>
    )
  }
}

PartnerList.propTypes = {
  /** sort object for PartnerList */
  sort: PropTypes.object,
  /** partner change action of PartnerManager. */
  changedPartner: PropTypes.func.isRequired,
  /** partner list filter change action of PartnerManager. */
  onChangeFilter: PropTypes.func.isRequired,
  /* list of assets given by apollo or PartnerManager */
  partnerList: PropTypes.array,
  /* partner id of selected PartnerFieldCell */
  selectedPartnerId: PropTypes.string
}

export default withApollo(compose(
  graphql(
    MutationCreatePartner,
    {
      props: (props) => ({
        createPartner: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryFilterPartners,
    {
      options: (props) => {
        const { filterVal, searchString, sort } = props
        const { project } = props
        const filter = utilityService.getFormattedFilterForPartner(filterVal)
        const variables = utilityService.getFormattedPartnerFilter(filter, searchString, sort, project)
        variables.project = props.project
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const partnerList = data.listPartners ? data.listPartners.items : []
        return {
          partnerList,
          isLoading: data.loading || !data.listPartners,
          totalCount: data.listPartners ? data.listPartners.totalCount : 0,
          getPartnerList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listPartners.items, ...fetchMoreResult.listPartners.items ]
                prev.listPartners.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  )
)(withRouter(PartnerList)))
