import React from 'react'
// import PropTypes from 'prop-types'
import './../ui.style.scss'
import DownloadSvgIcon from '../general/icons/DownloadSvgIcon'
// import Papa from 'papaparse'

class SelectedDatatype extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  makeCsvFIle = (field, dataType) => {
    // let config = {
    //   quotes: false, // or array of booleans
    //   quoteChar: '"',
    //   escapeChar: '"',
    //   delimiter: ',',
    //   header: true,
    //   newline: '\r\n',
    //   skipEmptyLines: false, // or 'greedy',
    //   columns: null // or array of strings
    // }
    let fields = []
    field.forEach((element) => {
      fields.push(element.name)
    })
    // let csvContent = Papa.unparse(field.fields, config)
    let csvContent = fields.join(',')
    var exportedFilenmae = dataType ? dataType + '.csv' : 'export.csv'
    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  render () {
    const { field, dataType } = this.props
    return (<div className='selected-item'>
      <div className='selected-header'>
        <div className='selected-fieldName'>
          {dataType}
        </div>
      </div>
      <div className='selected-body'>
        {
          field && field.map((type, j) => {
            return <div key={j} className='selected-type'>{type.displayName}</div>
          })
        }
      </div>
      <div className='selected-footer'>
        <div className='selected-datatype-download' onClick={() => this.makeCsvFIle(field, dataType)}>
          <span className='selected-download'>
            Download CSV
          </span>
          <span className='selected-csvIcon'>
            <DownloadSvgIcon width='16px' height='16px' />
          </span>
        </div>
      </div>
    </div>
    )
  }
}

SelectedDatatype.propTypes = {

}

SelectedDatatype.defaultProps = {
}

export default SelectedDatatype
