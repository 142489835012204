import gql from 'graphql-tag'

export default gql(`
query listApps($limit: Int $project: String $search: SearchInput) {
    listApps(
      limit: $limit,
      project: $project
      search: $search
    ) {
      items {
        id
        name
        project
        updatedAt
        url
        description
        createdAt
        bundleId
        position
        isCritical
        assignedUsers
        appIcon{
          id
          mediaId
          project
          name
          url
          fileName
          aspectRatio {
              title
              aspectRatio
              resolutions {
                url
              }
          }
          settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2          
          }
        }
      }
    }
}`)
