import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { withRouter } from 'react-router-dom'

import CustomerDetails from './CustomerDetails'
import CustomerSidebar from './CustomerSidebar'
import HistoryList from '../auditManager/HistoryList'
import SearchCustomerIcon from './../../components/ui/general/icons/SearchCustomerIcon'
import TabPanel from '../../components/ui/dataDisplay/TabPanel'
import LoggerService from '../../services/LoggerService'
import AppContext from '../../AppContext'

import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import SidebarCloseButton from '../../components/ui/general/buttons/SidebarCloseButton'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import MutationCreateAudit from '../../graphQL/customers/createAudit'
import AuthService from '../../services/AuthService'

import './CustomerServiceManager.scss'

const detailsTabs = [
  {
    displayName: 'History',
    mode: 'history'
  }
]

class CustomerServiceManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedCustomer: undefined,
      mode: 'history',
      refetchHistoryList: false,
      isSideBarVisible: false,
      refetchCustomerList: false
    }
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
    // if (this.props.match && this.props.match.params && this.props.match.params.id) {
    LoggerService.info('flow', 'Customer service Manager Loaded')
    // this.setState({ selectedCustomer: this.props.match.params.id })
    // }
  }

  componentDidMount = () => {
    document.title = 'Customer Service Manager - Optus CMS'
  }

  onSelectCustomer = (id, cogId) => {
    if (this.state.selectedCustomer !== id) {
      this.setState({ selectedCustomer: id, selectedCustomerCogId: cogId })
      // this.props.history.push(id ? `/customers/${id}` : '/customers')
    }
  }

  toggleSidebar = () => {
    this.setState({ isSideBarVisible: !this.state.isSideBarVisible })
  }

  fetchHistory = () => {
    this.setState({ refetchHistoryList: true }, () => {
      this.setState({ refetchHistoryList: false })
    })
  }

  onChangeTab = async (tab) => {
    const { selectedCustomer } = this.state
    const { project } = this.props
    let activeTab = 'Subscriptions'
    if (project === 'hyperion' || project === 'projectx') {
      if (tab === '1') { activeTab = 'Subscriptions' } else if (tab === '2') { activeTab = 'Transactions' } else if (tab === '3') { activeTab = 'Billing Accounts' }
    } else {
      if (tab === '1') { activeTab = 'Subscriptions' } else if (tab === '2') { activeTab = 'Purchase History' } else if (tab === '3') { activeTab = 'Support Tickets' } else if (tab === '5') { activeTab = 'Stream Sessions' } else if (tab === '6') { activeTab = 'Active Devices' } else if (tab === '7') { activeTab = 'View History' } else if (tab === '8') { activeTab = 'Favourites' } else if (tab === '9') { activeTab = 'Admin Attribute' } else if (tab === '10') { activeTab = 'Anti-Piracy' }
    }
    const userDetails = await AuthService.getUserDetails()
    const variables = {
      value: activeTab,
      name: userDetails.email,
      contentId: selectedCustomer,
      project
    }
    // if (project !== 'hyperion') {
    this.props.createAudit(variables).then(() => {
      this.fetchHistory()
    })
    // }
  }

  onChangeEmailChange = (newEmail) => {
    this.setState({
      selectedCustomer: newEmail
    })

    // setTimeout(() => {
    //   this.fetchHistory()
    // }, 1000);

    this.setState({ refetchCustomerList: true }, () => {
      this.setState({ refetchCustomerList: false })
    })
  }

  render () {
    const { selectedCustomer, selectedCustomerCogId, mode, refetchHistoryList, isSideBarVisible, refetchCustomerList } = this.state
    return <AppContext.Consumer>
      {({ project }) => {
        return (
          <Row className='customer-manager'>
            <Col className='customer-list-wrapper' span={6} >
              <CustomerSidebar onSelectCustomer={this.onSelectCustomer} selectedCustomer={selectedCustomer} project={project} refetchCustomerList={refetchCustomerList} />
            </Col>
            <Col className='customer-details-container' span={selectedCustomer && isSideBarVisible ? 12 : 18} >
              { selectedCustomer ? <CustomerDetails selectedCustomer={selectedCustomer} selectedCustomerCogId={selectedCustomerCogId}
                fetchHistory={this.fetchHistory} toggleSidebar={this.toggleSidebar} isSideBarVisible={!isSideBarVisible} onChangeTab={this.onChangeTab}
                project={project} onChangeEmailChange={this.onChangeEmailChange} />
                : <div className='empty-placeholder'>
                  <div>
                    <SearchCustomerIcon />
                    <p>Search and Select Customers First</p>
                  </div>
                </div>
              }
            </Col>
            {selectedCustomer && isSideBarVisible && <Col span={6}>
              <div className='side-panel-header'>
                <ButtonContainer displayTitle='Hide Audit History' childComponent={<SidebarCloseButton onClick={this.toggleSidebar} />} />
                <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
              </div>
              <HistoryList isChanging={refetchHistoryList} showFilter={false} content='CUSTOMER_MANAGER' contentId={selectedCustomer} project={project} />
            </Col>}
          </Row>
        )
      }}
    </AppContext.Consumer>
  }
}

CustomerServiceManager.propTypes = {
}

export default withApollo(compose(
  graphql(
    MutationCreateAudit,
    {
      props: (props) => ({
        createAudit: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(withRouter(CustomerServiceManager)))
