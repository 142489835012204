import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import PopoverConfirm from './../feedback/PopoverConfirm'

const parentCompoent = 'asset-container'

const ContentTitle = ({ title, onCloseContent, ...props }) => (
  <div className='content-box'>
    <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
    <p className='title-text' > { title } </p>
  </div>
)

ContentTitle.propTypes = {
  /** Title of ContentTitle. */
  title: PropTypes.string.isRequired,
  /** close action of ContentTitle. */
  onCloseContent: PropTypes.func
}

ContentTitle.defaultProps = {
}

export default ContentTitle
