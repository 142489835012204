import gql from 'graphql-tag'

export default gql(`
    query{
        listMasterFields{
            id
            displayName
            fieldName
            fields{
              name
              displayName
              type
              isRequired
            }
          }
}`)
