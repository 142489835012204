import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { message, Progress } from 'antd'
import AppContext from '../../AppContext'
import { utilityService } from './../../services/UtilityService'

import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import TextArea from './../../components/ui/dataEntry/inputs/TextArea'
import RetryButton from '../../components/ui/general/buttons/RetryButton'
import CopyLinkIcon from '../../components/ui/general/icons/CopyLinkIcon'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import Player from '../../components/player/Player'
import HistoryList from '../auditManager/HistoryList'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import MutationRetriggerVodValidation from './../../graphQL/jobs/retriggerVodvalidation'
import QueryJobList from './../../graphQL/jobs/listJobs'

const detailsTabs = [
  {
    displayName: 'Info',
    mode: 'info'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

class JobDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mode: 'info',
      isRetiggeringValidation: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    const { jobDetails } = nextProps
    if (jobDetails && jobDetails.id !== this.props.jobDetails.id && jobDetails.jobStatus.status !== 'COMPLETE' && jobDetails.jobStatus.status !== 'ERROR' && jobDetails.jobStatus.status !== 'UNDEFINED') {
      this.liveTimeout = setInterval(this.getUpdatedJobStatus, 5 * 1000)
    }
  }

    handleModeChange = (e) => {
      const mode = e.target.value
      this.setState({ mode })
    }

    checkForRecreate = (job) => {
      if (job.jobType && job.jobType.jobType) {
        if (job.jobType.jobType === 'VOD_MEDIA') {
          return true
        } else {
          return moment().diff(job.endTime, 'seconds') <= 86400
        }
      } else return false
    }

    onCopyDetails = (errorDetails, isId) => {
      const textField = document.createElement('textarea')
      textField.innerText = errorDetails
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      if (!isId) { message.success('Error details copied to clipboard') } else { message.success('Job ID copied to clipboard') }
    }

    getVideoDrm = (video) => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const decodeType = isSafari ? 'HLS' : 'DASH'
      const streamType = isSafari ? 'FAIRPLAY' : 'CENC'
      const selectedStream = (video.streams || []).filter(item => item).find(stream => (stream.drm === (!isSafari ? 'CLEAR' : streamType) || stream.drm === (!isSafari ? 'CENC' : streamType)) && stream.transport === decodeType)

      // let selectedStream = (video.streams || []).find(stream => (stream.drm === streamType) && stream.transport === decodeType)
      // if (!selectedStream) {
      //   selectedStream = (video.streams || []).find(stream => (stream.drm === 'CLEAR') && stream.transport === 'HLS')
      // }

      if (selectedStream) {
        const request = {
          url: selectedStream.Url,
          contentKey: selectedStream.contentKey,
          streamType: decodeType,
          drm: selectedStream.drm,
          drmEntitlementMessage: selectedStream.drmEntitlementMessage
        }
        if (decodeType === 'HLS' && selectedStream.iv) {
          request.iv = selectedStream.iv
        }
        return request
      }
    }

    retriggerVodValidation=() => {
      this.setState({ isRetiggeringValidation: true })
      const { jobDetails } = this.props
      const mediaId = (jobDetails.media && jobDetails.media.id) || ''
      this.props.retryVodValidation(jobDetails.id, mediaId).then(res => {
        if (res.data.triggerVwmVideoValidator) {
          this.setState({ isRetiggeringValidation: false })
          message.success('VOD Validation retriggered successfully')
        }
      }, error => {
        utilityService.handleError(error)
        this.setState({ isRetiggeringValidation: false })
      })
    }

    renderInnerComponent = (project) => {
      const { mode, isRetiggeringValidation } = this.state
      const { jobDetails, isAuditChange } = this.props
      const streamRequest = jobDetails && jobDetails.media ? this.getVideoDrm(jobDetails.media) : null
      const newClass = jobDetails && jobDetails.jobStatus && jobDetails.jobStatus.status === 'ERROR' && !jobDetails.relatedJob && this.checkForRecreate(jobDetails) ? 'job-details-details footer' : 'job-details-details'
      const cuePoints = jobDetails && jobDetails.media && jobDetails.media.cuePoints ? jobDetails.media.cuePoints : ''
      const adPositions = jobDetails && jobDetails.media && jobDetails.media.adPositions ? jobDetails.media.adPositions : ''
      const breakDuration = jobDetails && jobDetails.media && jobDetails.media.breakDuration ? jobDetails.media.breakDuration : ''
      const cuePointsError = jobDetails && jobDetails.media && jobDetails.media.cuePointsError ? jobDetails.media.cuePointsError : ''
      const formattedCuePoints = (cuePoints || '').replace(/[, ]+/g, ',').replace(/[,]+/g, ', ')
      const formattedBreakDuration = (breakDuration || '').replace(/[, ]+/g, ',').replace(/[,]+/g, ', ')
      const formattedAdPositions = (adPositions || '').replace(/[, ]+/g, ',').replace(/[,]+/g, ', ')
      // if(jobDetails)
      //   jobDetails.cuePointsError = 'something went wrong !'
      return mode === 'info' ? <div className={newClass}>
        {jobDetails && jobDetails.media && streamRequest
          ? <Player
            type='VIDEO'
            isLinearChannel='false'
            videoList={[]}
            showPlayer
            streamRequest={streamRequest}
            isPlayerReady={() => {}}
            isCollapsable={false}
            selectedVideoDetails={jobDetails && jobDetails.media ? jobDetails.media : undefined}
            project={project}
          /> : null}
        <div className='job-item-info'>
          <div>
            <div>Last Updated Status:</div>
            <span>{jobDetails.lastUdated ? jobDetails.lastUdated : '-'}</span>
          </div>
          <div>
            <div>Job Status:</div>
            {jobDetails.lastUdated
              ? <div className='job-status-details'>
                {(!jobDetails.jobStatus.status.includes('ERROR') && jobDetails.jobStatus.status !== 'QUEUED' && jobDetails.jobStatus.status !== 'UNDEFINED' && jobDetails.jobStatus.status !== 'UNKNOWN') ? <Progress percent={jobDetails.jobStatus.status === 'COMPLETE' ? 100 : jobDetails.jobStatus.progress}
                  showInfo={false} strokeColor={jobDetails.cuePointsError ? '#fdbd39' : jobDetails.jobStatus.status === 'REGISTERED' ? '#50e3c2' : ''}
                  status={jobDetails.jobStatus.status.includes('ERROR') ? 'exception' : jobDetails.jobStatus.status === 'COMPLETE' ? 'success' : 'active'} /> : ''}
                <span className={`${jobDetails.cuePointsError ? 'cue-error ' : ''}${(jobDetails.jobStatus.status || '').toLowerCase()}`}>{jobDetails.jobStatus.status === 'UNDEFINED' ? 'UNKNOWN' : jobDetails.jobStatus.status}</span>
              </div>
              : '-'}
          </div>
          <div>
            <div>Job Type:</div>
            <span>{jobDetails.jobType && jobDetails.jobType.isLive ? 'LIVE2VOD' : 'VOD'}</span>
          </div>
          <div>
            <div>Job ID:</div>
            <div className='id-container'><span>{jobDetails.jobId ? jobDetails.jobId : '-'}</span>
              <CopyLinkIcon color='#424A52' onClick={() => this.onCopyDetails(jobDetails.jobId, true)} /></div>
          </div>
          <div>
            <div>Job Name:</div>
            <span>{jobDetails.jobName ? jobDetails.jobName : '-'}</span>
          </div>
          <div className='video-info'>
            <div>AD Positions:</div>
            <span>{formattedAdPositions || '-'}</span>
          </div>
          <div className='video-info'>
            <div>Cue Points:</div>
            <span>{formattedCuePoints || '-'}</span>
          </div>
          <div className='video-info'>
            <div>Break Duration:</div>
            <span>{formattedBreakDuration || '-'}</span>
          </div>
          <div className='video-info'>
            <div>Cue Point Error:</div>
            <span>{cuePointsError || '-'}</span>
          </div>
          <div>
            <div>Validation Status:</div>
            <div className='job-status-details-container'>
              {jobDetails.validations
                ? <div className='validation-status-details'>
                  <span className={`${jobDetails.validations.status === 'ERROR' || jobDetails.validations.status === null ? 'cue-error ' : ''}`}>{jobDetails.validations.status === 'UNDEFINED' ? 'UNKNOWN' : (isRetiggeringValidation === false ? (jobDetails.validations.status ? jobDetails.validations.status : (jobDetails.jobStatus.status === 'ERROR' || jobDetails.jobStatus.status === 'UNKNOWN' || isRetiggeringValidation ? '-' : 'Pending')) : 'Pending')}</span>
                </div>
                : '-'}
              {<ButtonContainer displayTitle='Retrigger validation' placement='top' childComponent={<RetryButton isDisabled={(jobDetails.validations && jobDetails.jobStatus) && (jobDetails.validations.streamStatus === 'VALID' || jobDetails.jobStatus.status !== 'COMPLETE')} onClick={this.retriggerVodValidation} isLoading={isRetiggeringValidation} />} width={10} height={10} /> }
            </div>
          </div>
          <div>
            <div>VOD Validation Message:</div>
            {jobDetails.validations
              ? <div className='job-status-details-warning'>
                <span className={''}>{jobDetails.validations.error === 'UNDEFINED' ? 'UNKNOWN' : (jobDetails.validations.error ? jobDetails.validations.error : (jobDetails.jobStatus.status === 'ERROR' ? '-' : '-'))}</span>
              </div>
              : '-'}
          </div>
        </div>
        <div className='job-item-related-info'>
          <div>
            <div>Triggered By:</div>
            <span>{jobDetails.triggeredBy ? jobDetails.triggeredBy : '-'}</span>
          </div>
          <div>
            <div>Triggered Date:</div>
            <span>{jobDetails.triggeredDate ? jobDetails.triggeredDate : '-'}</span>
          </div>
          <div>
            <div>Asset Title:</div>
            <span>{jobDetails.assetTitle ? jobDetails.assetTitle : '-'}</span>
          </div>
          <div>
            <div>Asset ID</div>
            <span className='id'><a href={location.origin + '/assets/' + jobDetails.assetId} target='_blank'>{jobDetails.assetId}</a> </span>
          </div>
          <div>
            <div>Channel Name:</div>
            <span>{jobDetails.channelName ? jobDetails.channelName : '-'}</span>
          </div>
          <div>
            <div>Channel Code:</div>
            <span className='id'>{jobDetails.channelCode ? <a href={location.origin + '/channels/' + jobDetails.channelCode} target='_blank'>{jobDetails.channelCode}</a> : '-'}</span>
          </div>
          <div>
            <div>Start/End Time:</div>
            <span>{jobDetails.startEndTime ? jobDetails.startEndTime : '-'}</span>
          </div>
          <div>
            <div>Site:</div>
            <span>{jobDetails.site ? jobDetails.site : '-'}</span>
          </div>
          <div>
            <div>Match:</div>
            <span>{jobDetails.matchName ? jobDetails.matchName : '-'}</span>
          </div>
          <div>
            <div>Match Date:</div>
            <span>{jobDetails.matchDate ? jobDetails.matchDate : '-'}</span>
          </div>
          <div>
            <div>Match OPID:</div>
            <span>{jobDetails.matchOpid ? jobDetails.matchOpid : '-'}</span>
          </div>
        </div>
        {jobDetails && jobDetails.jobStatus && jobDetails.jobStatus.status === 'ERROR' ? <div className='error-details-container'>
          { jobDetails.jobStatus.errorCode ? <div className='error-details-title'>
            <span>Error Code</span>
            <span>{jobDetails.jobStatus.errorCode}</span>
          </div> : null }
          <div className='error-details-title'>
            <span>Error Details</span>
            <span onClick={() => this.onCopyDetails(jobDetails.jobStatus.errorDetails)}>Copy Error Details</span>
          </div>
          <TextArea title='' inputName='errorDetails' isEditable={false} value={jobDetails.jobStatus.errorDetails} placeholder='Error Details' />
        </div> : ''}
      </div> : <HistoryList contentId={jobDetails.id} content='JOB_MANAGER' showFilter={false} isChanging={isAuditChange} project={project} />
    }

    render () {
      const { mode } = this.state
      const { jobDetails, onRetry } = this.props
      const isFooterPresent = jobDetails && jobDetails.jobStatus && jobDetails.jobStatus.status === 'ERROR' && !jobDetails.relatedJob && this.checkForRecreate(jobDetails)
      return <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['JOB_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          return <div className='job-details-container'>
            <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
            {this.renderInnerComponent(project)}
            {isFooterPresent && mode === 'info' ? <div className='error-footer'>
              <ButtonContainer displayTitle='Recreate job' placement='topRight' childComponent={<RetryButton onClick={onRetry} isDisabled={isCreateDisabled} />} />
            </div> : ''}
          </div>
        }}
      </AppContext.Consumer>
    }
}

JobDetails.propTypes = {
  /** Details of jobs */
  jobDetails: PropTypes.object,
  /** Function for recreate error job */
  onRetry: PropTypes.func
}

export default withApollo(compose(
  graphql(
    MutationRetriggerVodValidation,
    {
      options: (props) => {
        const { filterData, searchString, sort, jobDetails } = props
        const filter = utilityService.getFormattedFilter(filterData)
        const variables = utilityService.getFormattedJobFilter(filter, searchString, sort)
        return {
          // refetchQueries: () => [{ query: QueryJobList, variables, fetchPolicy: 'network-only' }],
          update: (cache, { data: { triggerVwmVideoValidator } }) => {
            const cacheData = cloneDeep(cache.readQuery({ query: QueryJobList, variables }))
            if (cacheData && cacheData.listVwmJobs && cacheData.listVwmJobs.items) {
              const index = cacheData.listVwmJobs.items.findIndex((innerItem) => innerItem.id === jobDetails.id)
              cacheData.listVwmJobs.items[index].validations.error = triggerVwmVideoValidator.error
              cacheData.listVwmJobs.items[index].validations.streamStatus = triggerVwmVideoValidator.streamStatus
              cacheData.listVwmJobs.items[index].validations.status = triggerVwmVideoValidator.status
              // cacheData.listVwmJobs.items = [...cacheData.listVwmJobs.items]
              // cacheData.listVwmJobs.items = [...retryVodJob, ...cacheData.listVwmJobs.items]
            }
            cache.writeQuery({
              query: QueryJobList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        retryVodValidation: (id, mediaId) => {
          return props.mutate({
            variables: { id: id, mediaId: mediaId }
          })
        }
      })
    }
  )
)(JobDetails))
