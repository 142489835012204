import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import PropTypes from 'prop-types'

import RatingFieldCell from './RatingFieldCell'

class RatingFieldComponent extends Component {
    onDragEnd = result => {
      if (!result.destination) {
        return
      }
      this.props.reorder(result.source.index, result.destination.index)
    }

    render () {
      const { ratingData, onCloseField, onSchemaSelect, onSchemaSearch, schemas, schemaSearch, onRatingSelect,
        onRatingSearch, ratings, ratingSearch, onContentRatingSelect, contentRatings, listRatingDetails, isUpdateDisabled } = this.props
      const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle
      })

      const getListStyle = isDraggingOver => ({
        borderTop: isDraggingOver ? '1px solid #cfd0d1' : 'none'
      })

      return (
        <div className='rating-field-objects'>
          <div className='rating-data'>
            {ratingData && ratingData.length
              ? <DragDropContext onDragEnd={this.onDragEnd} is>
                <Droppable droppableId='droppable'>
                  {(droppableProvided, droppableSnapshot) => (
                    <div className='rating-content'
                      ref={droppableProvided.innerRef}
                      style={getListStyle(droppableSnapshot.isDraggingOver)}
                    >
                      {ratingData.map((field, index) => (
                        <Draggable key={field.id} draggableId={`id${index}`} index={index} isDragDisabled={isUpdateDisabled}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <RatingFieldCell
                                field={field}
                                onCloseField={onCloseField}
                                onSchemaSelect={onSchemaSelect}
                                onSchemaSearch={onSchemaSearch}
                                schemaSearch={schemaSearch}
                                schemas={schemas}
                                onRatingSelect={onRatingSelect}
                                onRatingSearch={onRatingSearch}
                                ratingSearch={ratingSearch}
                                ratings={ratings}
                                onContentRatingSelect={onContentRatingSelect}
                                contentRatings={contentRatings}
                                listRatingDetails={listRatingDetails}
                                isUpdateDisabled={isUpdateDisabled}
                                index={index} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              : null }
          </div>
        </div>
      )
    }
}

RatingFieldComponent.propTypes = {
  /** function to handle reorder of ratingField */
  reorder: PropTypes.func,
  /** data for rating field */
  ratingData: PropTypes.array,
  /** function to handle close of ratingField */
  onCloseField: PropTypes.func,
  /** function to hanlde schema change */
  onSchemaSelect: PropTypes.func,
  /** function tp handle schema search */
  onSchemaSearch: PropTypes.func,
  /** schema search value */
  schemaSearch: PropTypes.string,
  /** data for schema field */
  schemas: PropTypes.array,
  /** function to handle ratingSelec */
  onRatingSelect: PropTypes.func,
  /** function to handle rating search */
  onRatingSearch: PropTypes.func,
  /** rating search value */
  ratingSearch: PropTypes.string,
  /** data for rating */
  ratings: PropTypes.array,
  /** data for contentrating */
  contentRatings: PropTypes.array
}

export default RatingFieldComponent
