import gql from 'graphql-tag'

export default gql(`
mutation updateFolder($id: ID!, $name: String, $tags: [String], $position: Int, $trackPosChange: Boolean $project: String){
    updateFolder(input: {
        id: $id
        name: $name
        tags: $tags
        position: $position
        trackPosChange: $trackPosChange
    }
      project: $project
    ){
        id
        name
        tags{
            key
            name
            type
            module
            assetCount
            mediaCount
            isTransferring
        }
        position
        createdAt
    }
}
`)
