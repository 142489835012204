import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Row, Col, message } from 'antd'

import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryFilterListAppconfigs from '../../graphQL/monitor/filterListAppconfigs'

import './MonitorManager.scss'

import AppContext from '../../AppContext'
import MonitorManagerHeader from './MonitorManagerHeader'
import MonitorDataView from './MonitorDataView'
import RangeCalenderModal from '../../components/ui/dataEntry/inputs/RangeCalenderModal'

let monitorDashboardList
let monitorTypeList

const timeList = [
  {
    name: 'Past 5 Minutes',
    id: '5m'
  },
  {
    name: 'Past 15 Minutes',
    id: '15m'
  },
  {
    name: 'Past 30 Minutes',
    id: '30m'
  },
  {
    name: 'Past 1 Hour',
    id: '1h'
  },
  {
    name: 'Past 4 Hours',
    id: '4h'
  },
  {
    name: 'Past 1 Day',
    id: '1D'
  },
  {
    name: 'Past 2 days',
    id: '2D'
  },
  {
    name: 'Past 1 Week',
    id: '1W'
  },
  {
    name: 'Past 1 Month',
    id: '1M'
  },
  {
    name: 'Select from calender',
    id: '10'
  }
]

class MonitorManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedDashboard: '',
      selectedDashboardType: '',
      selectedUrl: '',
      selectedDatadogTitle: '',
      selectedTime: '1D',
      apiKey: '',
      isCalenderVisible: false,
      apiMetaData: null,
      startDate: '',
      endDate: '',
      isCalenderSelected: false,
      rangeTime: '',
      disabled: true,
      lastRefresh: moment().format('MMMM Do, h:mm a'),
      epochStartInSeconds: '',
      epochEndInSeconds: '',
      configItemId: '',
      valueId: '',
      selectedConfigId: '',
      selectedDatadogUrl: '',
      queryParams: '?last=1D',
      isLoading: false
    }
  }

  componentDidMount = () => {
    const { project } = this.props
    document.title = `Monitor Manager - ${project === 'hyperion' ? 'Hyperion' : 'Optus'} CMS`
  }

  getSortedList = (data) => {
    return (cloneDeep(data) || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  handleConfiguration = () => {
    const { configList } = this.props
    let configuration = configList && configList[0] && configList[0].monitorConfigList
    let sortedConfiguration = this.getSortedList(configuration)

    let datadogConfiguration = _.filter(sortedConfiguration, { fieldValues: [{ value: 'datadog' }], isEnabled: true })
    let externalConfiguration = _.filter(sortedConfiguration, { fieldValues: [{ value: 'api' }], isEnabled: true })
    let proxyConfiguration = _.filter(sortedConfiguration, { fieldValues: [{ value: 'proxyGQL' }], isEnabled: true })
    let datadogConfigurationFields = (datadogConfiguration || []).map(item => item.fieldValues)
    let externalConfigurationFields = (externalConfiguration || []).map(item => item.fieldValues)
    let proxyConfigurationFields = (proxyConfiguration || []).map(item => item.fieldValues)

    return {
      datadogConfigurationFields,
      externalConfigurationFields,
      proxyConfigurationFields
    }
  }

  handleTitleConfig = (configurationFields) => {
    let proxyTitles = (configurationFields || []).map(item => {
      let results = item.filter(internalItem => internalItem.configField.name === 'title')[0]
      return results
    })

    return proxyTitles
  }

  handleDasboardTitleConfig = () => {
    const { configList } = this.props
    let configuration = configList && configList[0] && configList[0].monitorConfigList
    let sortedConfiguration = this.getSortedList(configuration)
    let dataConfiguration = _.filter(sortedConfiguration, { isEnabled: true })

    let dataConfigurationFields = (dataConfiguration || []).map(item => item.fieldValues)
    let dataTitles = this.handleTitleConfig(dataConfigurationFields)

    monitorDashboardList = (dataTitles || []).map(item => {
      return {
        id: item.id,
        name: item.value
      }
    })
  }

  onDashboardSelect = (selectedDashboard) => {
    const { datadogConfigurationFields, externalConfigurationFields, proxyConfigurationFields } = this.handleConfiguration()
    const dataDogTitles = this.handleTitleConfig(datadogConfigurationFields)
    const proxyTitles = this.handleTitleConfig(proxyConfigurationFields)
    const selectedDatadogTitle = (dataDogTitles || []).filter(item => item.value === selectedDashboard)[0]
    const selectedProxytitle = (proxyTitles || []).filter(item => item.value === selectedDashboard)[0]

    if (selectedDatadogTitle && selectedDashboard === selectedDatadogTitle.value) {
      let dataDogConfigDetails = (datadogConfigurationFields || []).map(configureData => {
        let tempItem = (configureData || []).find(innerItem => innerItem.value === selectedDashboard && innerItem.configField.name === 'title')
        if (!_.isEmpty(tempItem)) {
          return configureData
        } else {
          return null
        }
      })

      let filterDataDogTitlesnew = _.compact(dataDogConfigDetails)[0]

      let dataDogConfig = (filterDataDogTitlesnew || []).filter(item => item.configField.name === 'config')[0]
      let dataDogValue = dataDogConfig && dataDogConfig.value
      let dataDogParseValue = JSON.parse(dataDogValue)
      monitorTypeList = (dataDogParseValue || []).map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
      this.setState({ selectedDashboard,
        selectedDashboardType: dataDogParseValue[0].name,
        selectedDatadogUrl: dataDogParseValue[0].endpoint,
        selectedUrl: '',
        selectedDatadogTitle: selectedDatadogTitle.value,
        selectedConfigId: ''
      })
    } else if (selectedProxytitle && selectedDashboard === selectedProxytitle.value) {
      this.getConfigurationId(selectedDashboard)
      let proxyTitlesnew = (proxyConfigurationFields || []).map(configureData => {
        let tempItem = (configureData || []).find(innerItem => innerItem.value === selectedDashboard && innerItem.configField.name === 'title')
        if (!_.isEmpty(tempItem)) {
          return configureData
        } else {
          return null
        }
      })

      let filterProxyTitlesnew = _.compact(proxyTitlesnew)[0]
      let proxyConfig = (filterProxyTitlesnew || []).filter(item => item.configField.name === 'config')[0]
      let proxyValue = proxyConfig && proxyConfig.value
      let proxyParseValue = JSON.parse(proxyValue)
      monitorTypeList = (proxyParseValue || []).map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
      this.setState({ selectedDashboard,
        selectedDashboardType: proxyParseValue[0].name,
        configItemId: proxyConfig && proxyConfig.configField && proxyConfig.configField.id,
        selectedDatadogTitle: '',
        selectedUrl: '',
        valueId: proxyParseValue[0].id,
        queryParams: '?last=1D',
        isCalenderSelected: false,
        selectedTime: '1D',
        apiMetaData: []
      })
      this.handleLastRefreshUpdate()
    } else {
      let externalTitlesnew = (externalConfigurationFields || []).map(configureData => {
        let tempItem = (configureData || []).find(innerItem => innerItem.value === selectedDashboard && innerItem.configField.name === 'title')
        if (!_.isEmpty(tempItem)) {
          return configureData
        } else {
          return null
        }
      })

      let filterExternalTitlesnew = _.compact(externalTitlesnew)[0]
      let externalConfig = (filterExternalTitlesnew || []).filter(item => item.configField.name === 'config')[0]
      let externalValue = externalConfig && externalConfig.value
      let externalParseValue = JSON.parse(externalValue)
      monitorTypeList = (externalParseValue || []).map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
      this.setState({ selectedDashboard,
        selectedDashboardType: externalParseValue[0].name,
        selectedUrl: externalParseValue[0].endpoint,
        selectedDatadogTitle: '',
        apiKey: externalParseValue[0].security,
        queryParams: '?last=1D',
        isCalenderSelected: false,
        selectedTime: '1D',
        selectedConfigId: ''
      },
      () => {
        this.handleQueryParams()
      })
    }
  }

  onDashboardTypeSelect = (selectedDashboardType) => {
    const { selectedDashboard } = this.state
    const { datadogConfigurationFields, externalConfigurationFields, proxyConfigurationFields } = this.handleConfiguration()
    const dataDogTitles = this.handleTitleConfig(datadogConfigurationFields)
    const proxyTitles = this.handleTitleConfig(proxyConfigurationFields)
    const filterTitles = (dataDogTitles || []).map(item => item.value)
    const selectedProxytitle = (proxyTitles || []).map(item => item.value)
    if (filterTitles.includes(selectedDashboard)) {
      let dataDogConfigDetails = (datadogConfigurationFields || []).map(configureData => {
        let tempItem = (configureData || []).find(innerItem => innerItem.value === selectedDashboard && innerItem.configField.name === 'title')
        if (!_.isEmpty(tempItem)) {
          return configureData
        } else {
          return null
        }
      })

      let filterDataDogConfigDetails = _.compact(dataDogConfigDetails)[0]
      let dataDogConfig = (filterDataDogConfigDetails || []).filter(item => item.configField.name === 'config')[0]
      let dataDogValue = dataDogConfig && dataDogConfig.value
      let dataDogParseValue = JSON.parse(dataDogValue)
      let selectedDataDogParseValue = (dataDogParseValue || []).filter(item => item.name === selectedDashboardType)[0]
      this.setState({ selectedDashboardType: selectedDashboardType, selectedDatadogUrl: selectedDataDogParseValue.endpoint, selectedUrl: '' })
    } else if (selectedProxytitle.includes(selectedDashboard)) {
      let externalProxyConfigDetails = (proxyConfigurationFields || []).map(configureData => {
        let tempItem = (configureData || []).find(innerItem => innerItem.value === selectedDashboard && innerItem.configField.name === 'title')
        if (!_.isEmpty(tempItem)) {
          return configureData
        } else {
          return null
        }
      })

      let filterExternalProxyConfigDetails = _.compact(externalProxyConfigDetails)[0]
      let proxyConfig = (filterExternalProxyConfigDetails || []).filter(item => item.configField.name === 'config')[0]
      let proxyValue = proxyConfig && proxyConfig.value
      let proxyParseValue = JSON.parse(proxyValue)
      let selectedProxyParseValue = (proxyParseValue || []).filter(item => item.name === selectedDashboardType)[0]
      this.setState({ selectedDashboardType: selectedDashboardType,
        configItemId: proxyConfig && proxyConfig.configField && proxyConfig.configField.id,
        valueId: selectedProxyParseValue.id,
        isCalenderSelected: false,
        selectedUrl: '',
        rangeTime: '',
        selectedTime: '1D',
        queryParams: '?last=1D'
      })
      this.handleLastRefreshUpdate()
    } else {
      let externalApiConfigDetails = (externalConfigurationFields || []).map(configureData => {
        let tempItem = (configureData || []).find(innerItem => innerItem.value === selectedDashboard && innerItem.configField.name === 'title')
        if (!_.isEmpty(tempItem)) {
          return configureData
        } else {
          return null
        }
      })

      let filterExternalApiConfigDetails = _.compact(externalApiConfigDetails)[0]
      let externalConfig = (filterExternalApiConfigDetails || []).filter(item => item.configField.name === 'config')[0]
      let externalValue = externalConfig && externalConfig.value
      let externalParseValue = JSON.parse(externalValue)
      let selectedExternalParseValue = (externalParseValue || []).filter(item => item.name === selectedDashboardType)[0]
      this.setState({ selectedDashboardType: selectedDashboardType,
        selectedUrl: selectedExternalParseValue.endpoint,
        apiKey: selectedExternalParseValue.security,
        isCalenderSelected: false,
        rangeTime: '',
        selectedTime: '1D'
      },
      () => {
        this.handleQueryParams()
      })
    }
  }

  onTimeSelect = (selectedTime) => {
    if (selectedTime === '10') {
      this.setState({
        isCalenderVisible: true,
        isCalenderSelected: true
      })
    } else {
      if (selectedTime) {
        this.setState({ selectedTime, isCalenderSelected: false }, () => {
          this.handleQueryParams()
        })
      }
    }
  }

  handleCalenderCancel = () => {
    const { rangeTime } = this.state
    if (!_.isEmpty(rangeTime)) {
      this.setState({
        isCalenderVisible: false,
        disabled: true
      })
    } else {
      this.setState({
        isCalenderVisible: false,
        isCalenderSelected: false,
        disabled: true
      })
    }
  }

  handleQueryParams = () => {
    const { startDate, endDate, selectedTime, epochEndInSeconds, epochStartInSeconds } = this.state
    let newEpochStartInSeconds = epochStartInSeconds
    let newEochEndInSeconds = epochEndInSeconds

    if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
      newEpochStartInSeconds = Date.parse(startDate) / 1000
      newEochEndInSeconds = Date.parse(endDate) / 1000
      this.setState({
        epochStartInSeconds: newEpochStartInSeconds,
        epochEndInSeconds: newEochEndInSeconds
      })
    }

    let queryParams = `?${!selectedTime ? `startTime=${newEpochStartInSeconds}&endTime=${newEochEndInSeconds}` : ''}${selectedTime ? `last=${selectedTime} ` : ''}`
    this.setState({
      queryParams
    }, () => {
      this.onExternalApiCall()
    })
  }

  onExternalApiCall = () => {
    const { selectedUrl, apiKey, queryParams } = this.state
    if (!_.isEmpty(selectedUrl)) {
      this.setState({
        isLoading: true
      })
      fetch(`${selectedUrl}${queryParams}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'x-api-key': apiKey
        }
      })
        .then(response => response.json())
        .then(apiMetaData => { this.setState({ apiMetaData, lastRefresh: moment().format('MMMM Do, h:mm a'), isLoading: false }) },
          (error) => {
            this.setState({ apiMetaData: [], selectedConfigId: '', isLoading: false })
            console.log(error)
          })
    }
  }

  resetState = () => {
    this.setState({
      startDate: '',
      endDate: ''
    })
  }

  handleDateChange = (selectedDates) => {
    let startDate = ''
    let endDate = ''
    let currentTime = moment()

    if (moment(selectedDates[0]).isAfter(currentTime) || moment(selectedDates[1]).isAfter(currentTime)) {
      message.error('Can not select future date.')
      return
    } else {
      startDate = selectedDates[0] ? moment(selectedDates[0]) : null
      endDate = selectedDates[1] ? moment(selectedDates[1]) : null
    }

    if (_.isEmpty(selectedDates)) {
      this.setState({ startDate, endDate }, () => {
        this.setState({ disabled: true })
      })
    } else {
      this.setState({ startDate, endDate }, () => {
        this.setState({ disabled: false })
      })
    }
  };

  handleDateRangeSubmit = () => {
    const { startDate, endDate } = this.state
    let formatStartDate = moment(startDate).format('MMMM Do, h:mm a')
    let formatEndDate = moment(endDate).format('MMMM Do, h:mm a')
    let rangeTime = `${formatStartDate} - ${formatEndDate}`
    this.setState({
      isCalenderVisible: false,
      disabled: true,
      rangeTime,
      selectedTime: ''
    }, () => {
      this.handleQueryParams()
    })
  }

  getConfigurationId = (selectedDashboard) => {
    const { configList } = this.props
    let configuration = configList && configList[0] && configList[0].monitorConfigList
    let sortedConfiguration = this.getSortedList(configuration)
    let proxyConfiguration = _.filter(sortedConfiguration, { fieldValues: [{ value: 'proxyGQL' }], isEnabled: true })
    let selectedProxyConfiguration = _.filter(proxyConfiguration, { fieldValues: [{ value: selectedDashboard }], isEnabled: true })[0]
    this.setState({
      selectedConfigId: selectedProxyConfiguration.id
    })
  }

  handleLastRefreshUpdate = () => {
    this.setState({
      lastRefresh: moment().format('MMMM Do, h:mm a')
    })
  }

  render () {
    const { selectedDashboard, selectedDashboardType, selectedUrl, selectedDatadogTitle, selectedTime, apiKey, isCalenderVisible,
      apiMetaData, rangeTime, isCalenderSelected, startDate, endDate, disabled, lastRefresh, configItemId, valueId, selectedConfigId, selectedDatadogUrl,
      queryParams, isLoading } = this.state
    const { appClient, configList } = this.props
    this.handleDasboardTitleConfig()
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          // const userPermissions = permissions['MONITOR_MANAGER']
          // const isMonitorAdminDisabled = userPermissions.indexOf('MONITORING_ADMIN') === -1
          return <Row className='monitor-manager' id='monitor-manager' ref={(ref) => { this.scrollParentRef = ref }}>
            <Col xl={{ span: 24 }} md={{ span: 24 }} className='monitor-list-container' >
              <div className={`monitor-header-and-table`}>
                <MonitorManagerHeader
                  appClient={appClient}
                  project={project}
                  monitorDashboardList={monitorDashboardList}
                  onDashboardSelect={this.onDashboardSelect}
                  selectedDashboard={selectedDashboard}
                  monitorTypeList={monitorTypeList}
                  selectedDashboardType={selectedDashboardType}
                  onDashboardTypeSelect={this.onDashboardTypeSelect}
                  onDashboardIntialLoad={this.onDashboardIntialLoad}
                  configList={configList}
                />
                <div className='monitor-view'>
                  <MonitorDataView
                    appClient={appClient}
                    project={project}
                    selectedUrl={selectedUrl}
                    selectedDashboard={selectedDashboard}
                    dataDogTitle={selectedDatadogTitle}
                    timeList={timeList}
                    selectedTime={selectedTime}
                    onTimeSelect={this.onTimeSelect}
                    apiKey={apiKey}
                    apiMetaData={apiMetaData}
                    onExternalApiCall={this.onExternalApiCall}
                    isCalenderSelected={isCalenderSelected}
                    rangeTime={rangeTime}
                    lastRefresh={lastRefresh}
                    configItemId={configItemId}
                    valueId={valueId}
                    selectedConfigId={selectedConfigId}
                    selectedDatadogUrl={selectedDatadogUrl}
                    handleLastRefreshUpdate={this.handleLastRefreshUpdate}
                    queryParams={queryParams}
                    handleQueryParams={this.handleQueryParams}
                    isLoading={isLoading}
                  />
                </div>
                <RangeCalenderModal
                  isVisible={isCalenderVisible}
                  handleCancel={this.handleCalenderCancel}
                  title={'Select Range'}
                  resetState={this.resetState}
                  handleDateChange={this.handleDateChange}
                  handleDateRangeSubmit={this.handleDateRangeSubmit}
                  startDate={startDate}
                  endDate={endDate}
                  disabled={disabled}

                />
              </div>
            </Col>
          </Row>
        }}
      </AppContext.Consumer>
    )
  }
}

MonitorManager.propTypes = {

}

export default withApollo(compose(
  graphql(
    QueryFilterListAppconfigs,
    {
      options: ({ project, appClient }) => {
        let variables = { limit: 30, filter: { key: { exact: 'MONITORS' } }, project }
        return {
          variables: variables,
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const configList = props.data && props.data.listAppConfig ? props.data.listAppConfig.items : []
        return {
          configList
        }
      }
    }
  )
  // graphql(
  //   QueryGetMonitoringResponse,
  //   {
  //     options: ({ project, appClient }) => {
  //       let variables = { limit: 30, filter: { key: { exact: 'MONITORS' } }, project }
  //       return {
  //         variables: variables,
  //         fetchPolicy: 'network-only',
  //         client: appClient
  //       }
  //     },
  //     props: (props) => {
  //       const configList = props.data && props.data.listAppConfig ? props.data.listAppConfig.items : []
  //       return {
  //         configList
  //       }
  //     }
  //   }
  // )
)(withRouter(MonitorManager)))
