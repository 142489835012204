import React, { Component } from 'react'
import { Table, Skeleton, Empty, message } from 'antd'
import { Resizable } from 'react-resizable'
import moment from 'moment'

// import Tooltip from './ToolTip'
import EditSubscriptionModal from '../dataEntry/other/EditSubscriptionModal'
import RefundModal from '../dataEntry/other/RefundModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListSubscription from '../../../graphQL/customers/listSubscriptions'
import MutationCancelSubscription from '../../../graphQL/customers/cancelSubscription'

import ExpandMenuIcon from './../../../components/ui//general/icons/ExpandMenuIcon'
import PopoverButton from './../../../components/ui//general/buttons/PopoverButton'
import ConfirmModal from './../../../components/ui/feedback/ConfirmModal'
import { utilityService } from './../../../services/UtilityService'
import ToolTip from './ToolTip'

const options = [
  {
    type: 'refund',
    name: 'Refund'
  },
  {
    type: 'editSubscription',
    name: 'Edit Subscription'
  },
  {
    type: 'cancelSubscription',
    name: 'Cancel Subscription'
  }
]

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class SubscriptionTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Sub ID',
        dataIndex: 'id',
        key: 'id',
        width: 100
      },
      {
        title: 'Segment tag',
        dataIndex: 'segmentTag',
        key: 'segmentTag',
        width: 100
      },
      {
        title: 'Offer Code',
        dataIndex: 'offerCode',
        key: 'offerCode',
        width: 100
      },
      {
        title: 'Created Date',
        dataIndex: 'id',
        width: 100,
        key: 'createDate',
        render: this.renderCreatedDate
      },
      {
        title: 'Auto Renew',
        dataIndex: 'autorenewStatus',
        key: 'autorenewStatus',
        width: 100
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        width: 100,
        render: (endDate) => moment(new Date(endDate)).format('DD MMM YYYY, HH:mm:ss')
      },
      {
        title: 'Cancellation Reason',
        dataIndex: 'cancellationReason',
        key: 'cancellationReason',
        width: 130,
        render: this.renderCancellationReason
      },
      {
        title: 'Snapshot Offer Price',
        dataIndex: 'snapshotOffer.price',
        key: 'price',
        width: 130
      },
      {
        title: 'Snapshot Offer Code',
        dataIndex: 'snapshotOffer.offerCode',
        key: 'snapshotOfferOfferCode',
        width: 130
      },
      {
        title: 'Snapshot Offer Period',
        dataIndex: 'snapshotOffer.period',
        key: 'period',
        width: 140
      },
      {
        dataIndex: 'id',
        width: 100,
        render: this.renderData
      }
    ]
    this.state = {
      shouldShowPopup: false,
      isCancelSubscriptionVisible: false,
      isEditSubscriptionVisible: false,
      isRefundVisible: false,
      isModalLoading: false,
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
    if (newProps.profileOptionShown === true) {
      this.setState({ shouldShowPopup: false })
    }
  }

  showEditSubscription = id => {
    this.setState({ editSubscription: id })
  }

  removeEditSubscription = () => {
    this.setState({ editSubscription: undefined })
  }

  removeRefund = () => {
    this.setState({ isRefundVisible: false })
  }

  renderCreatedDate = (id) => {
    const { subscription } = this.props
    const SubscriptionData = subscription.find(item => item.id === id)
    return SubscriptionData && SubscriptionData.createDate
      ? moment(new Date(SubscriptionData.createDate)).format('DD MMM YYYY, HH:mm:ss') : ''
  }

  renderData = (id) => {
    const { isUpdateDisabled } = this.props
    const { shouldShowPopup, editSubscription } = this.state
    return (
      <div className='icon'>
        <div onClick={() => this.showEditSubscription(id)} id={`sub-option-list-${id}`} className={`sub-option-list ${isUpdateDisabled ? 'disabled' : ''}`}>
          <PopoverButton
            button={<div onClick={this.toggleShowOptions} > <ExpandMenuIcon /></div>}
            placement='left'
            displayParam='name'
            contents={options}
            onContentClick={this.selectOption}
            parentCompoent={`sub-option-list-${id}`}
            isVisible={editSubscription === id && shouldShowPopup}
          />
        </div>
      </div>
    )
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  toggleShowOptions = () => {
    this.setState({ shouldShowPopup: !this.state.shouldShowPopup })
  }

  toggleisEditSubscriptionVisible = () => {
    this.setState({ isEditSubscriptionVisible: !this.state.isEditSubscriptionVisible })
  }

  toggleisRefundVisible = () => {
    this.setState({ isRefundVisible: !this.state.isRefundVisible })
  }

  selectOption = ({ type }) => {
    if (type === 'cancelSubscription') {
      this.setState({ isCancelSubscriptionVisible: true, editSubscription: undefined, shouldShowPopup: false })
    } else if (type === 'editSubscription') {
      this.setState({ isEditSubscriptionVisible: true, editSubscription: undefined, shouldShowPopup: false })
    } else if (type === 'refund') {
      this.setState({ isRefundVisible: true, editSubscription: undefined, shouldShowPopup: false })
    }
  }

  onCancelSubscriptionConfirmation = () => {
    this.setState({ isCancelSubscriptionVisible: false })
  }

  onConfirmCancelSubscription = () => {
    const { selectedCustomer } = this.props
    const { editSubscription } = this.state
    this.setState({ isModalLoading: true })
    const variables = {
      email: selectedCustomer,
      id: editSubscription
    }
    this.props.cancelSubscription(variables).then(({ data }) => {
      if (data && data.adminCancelSubscription && data.adminCancelSubscription.error && data.adminCancelSubscription.error.message) {
        const errorMessage = data.adminCancelSubscription.error.message
        this.props.fetchHistory()
        this.setState({ isCancelSubscriptionVisible: false, isModalLoading: false })
        utilityService.handleError(errorMessage)
      } else {
        this.setState({ isCancelSubscriptionVisible: false, isModalLoading: false })
        const successMessage = data.admincancelSubscription && data.admincancelSubscription.success && data.admincancelSubscription.success.message ? data.admincancelSubscription.success.message : 'Cancelled Successfully'
        this.props.fetchHistory()
        message.success(successMessage)
      }
    }, error => {
      this.setState({ isCancelSubscriptionVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  renderCancellationReason = (cancellationReason) => {
    if (cancellationReason) {
      const details = cancellationReason || 'None'
      console.log(cancellationReason.length)
      return cancellationReason.length > 70 ? <ToolTip
        childComponent={<div className='general-text-ellipsis'>{cancellationReason}</div>}
        description={details}
        placement='leftTop'
        isList
      /> : <span>{cancellationReason}</span>
    } else {
      return <span className='no-data-text' >None</span>
    }
  }

  render () {
    const { subscription, isLoading, selectedCustomer, fetchHistory } = this.props
    const { editSubscription, width, height, columns, isEditSubscriptionVisible, isCancelSubscriptionVisible, isModalLoading, isRefundVisible } = this.state
    let listSize = _.size(subscription)
    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))
    return (
      <div className={`general-customer-details-table ${listSize === 1 ? 'subscription-table-single' : ''}`} ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(subscription) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={subscription} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
        <RefundModal
          isVisible={isRefundVisible}
          email={selectedCustomer}
          onRefund={this.removeRefund}
          handleCancel={this.toggleisRefundVisible}
          fetchHistory={fetchHistory} />
        <EditSubscriptionModal
          isVisible={isEditSubscriptionVisible}
          email={selectedCustomer}
          details={(subscription || []).find(item => item.id === editSubscription)}
          onEditSubscription={this.removeEditSubscription}
          onCancelEdit={this.toggleisEditSubscriptionVisible}
          fetchHistory={fetchHistory} />
        <ConfirmModal
          isVisible={isCancelSubscriptionVisible}
          title={'Cancel Subscription'}
          message={'Do you want to cancel the subscription?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onCancelSubscriptionConfirmation}
          handleSubmit={this.onConfirmCancelSubscription} />
      </div>
    )
  }
}

SubscriptionTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListSubscription,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const subscription = data && data.listSubscriptions ? data.listSubscriptions : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { subscription, isLoading: data && data.loading }
      }
    }
  ),
  graphql(
    MutationCancelSubscription, {
      props: (props) => ({
        cancelSubscription: (request) => {
          return props.mutate({
            variables: request
          })
        }
      })
    }
  )
)(SubscriptionTable))
