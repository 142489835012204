import gql from 'graphql-tag'

export default gql(`
    mutation reset2FADevice($email: String $project: String) {
        reset2FADevice(
            input: {
                email: $email
            }
            project: $project
        )
    }
`)
