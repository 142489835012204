import React from 'react'
import bitmovinPlayer from '../bitmovin/bitmovin'

const FullscreenButton = (props) => {
  const { showPlayer, onTogglePlayer } = props
  const handleFullscreen = () => {
    if (showPlayer) {
      bitmovinPlayer.enableFullscreen()
    } else {
      onTogglePlayer()
      bitmovinPlayer.enableFullscreen()
    }
  }
  return (
    <>
      <div className={`fullscreen`} onClick={handleFullscreen} />
    </>
  )
}
export default FullscreenButton
