import gql from 'graphql-tag'

export default gql(`
    mutation deleteBucketGroupType($id: ID! $project: String) {
        deleteBucketGroupType(
            input: {
                id: $id
            }
            project: $project
        ) {
            id
        }
    }`
)
