import gql from 'graphql-tag'

export default gql(`
    mutation deleteAccessRoleGroup($userId: ID $project: String) {
        deleteAccessRoleGroup(
            input: {
                id: $userId
            }
            project: $project
        ){
            id
        }
    }
`)
