import React, { Component } from 'react'
import _ from 'lodash'
import BucketAssetSearchListInner from './BucketAssetSearchListInner'
import AppContext from '../../../AppContext'
import './../ui.style.scss'

class BucketAssetSearchList extends Component {
    state={
      searchString: this.props.searchString,
      filter: this.props.filter
    }

    UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
      if (this.state.filter !== newProps.filter) {
        this.setState({ filter: newProps.filter })
      }
      if (this.state.searchString !== newProps.searchString) {
        this.setState({ searchString: newProps.searchString })
      }
    }

    onChangeFilter = value => {
      this.setState({ searchString: value })
      const filterObj = {
        searchKey: value || null,
        filters: this.state.filter
      }
      this.props.onChangeFilter(this.props.id, filterObj)
    }

    changeFilterValue = (filter) => {
      this.setState({ filter })
      const filterObj = {
        searchKey: this.state.searchString ? this.state.searchString : null,
        filters: filter
      }
      this.props.onChangeFilter(this.props.id, filterObj)
    }

    onExpandButtonClick = () => {
      this.setState({ isOpen: !this.state.isOpen })
    }

    render () {
      let { searchString, filter, isOpen } = this.state
      const { parent, isSaveEnable, saveLoading, isFilterOpen, isSelected, readOnly } = this.props
      return (
        <AppContext.Consumer>
          {({ project }) => {
            return <div className={`bucket-asset-search-list${isSelected ? ' active' : ''}`}>
              <BucketAssetSearchListInner
                isOpen={_.cloneDeep(isOpen)}
                searchString={searchString}
                changeFilterValue={this.changeFilterValue}
                filterVal={filter}
                onChangeFilter={this.onChangeFilter}
                parent={parent}
                onExpandButtonClick={this.onExpandButtonClick}
                isSaveEnable={isSaveEnable}
                saveLoading={saveLoading}
                project={project}
                isFilterOpen={isFilterOpen}
                readOnly={readOnly}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

export default BucketAssetSearchList
