import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import './../../ui.style.scss'

const Option = Select.Option

class AppMultipleSelectDropDown extends React.Component {
  componentDidMount=() => {
    window.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount=() => {
    window.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside=() => {
    if (this.props.searchText !== null) {
      this.props.onSearch(null)
    }
  }
  // window.addEventListener('mousedown', handleClickOutside(searchText, onSearch))
  render () {
    const { onOptionSelect, displayParam, options, placeHolder, className, allowClear, parent, selectedValue, valueParam, onSearch } = this.props
    let value
    // let finale = (options || []).filter(item => !(value && value.length && value.includes(item.id)))
    return (
      <Select
        className={className ? 'multi-drop-down ' + className : 'multi-drop-down'}
        mode='multiple'
        allowClear={allowClear}
        value={selectedValue || []}
        style={{ width: '100%' }}
        placeholder={placeHolder}
        getPopupContainer={() => document.getElementById(parent)}
        onChange={onOptionSelect}
        onSearch={onSearch}
        filterOption={false}
        {...this.props}
      >
        {(options || []).filter(item => !(value && value.length && value.includes(item.id))).map((option, index) => <Option key={option.id || index} value={option[valueParam]}>{option[displayParam]}</Option>)}
      </Select>
    )
  }
}

AppMultipleSelectDropDown.propTypes = {
  /** Options of Dropdown. */
  options: PropTypes.array.isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Class name */
  className: PropTypes.string,
  defaultValue: PropTypes.array
}

AppMultipleSelectDropDown.defaultProps = {
  placeHolder: 'Select'
}

export default AppMultipleSelectDropDown
