import React from 'react'
import PropTypes from 'prop-types'
import ViewPageIcon from '../icons/ViewPageIcon'
import './../../ui.style.scss'

const ViewPageButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button page-view ${isDisabled ? 'disabled' : ''}`} onClick={onClick} >
    <ViewPageIcon color='#fff' />
  </div>
)

ViewPageButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

ViewPageButton.defaultProps = {
  isDisabled: false
}

export default ViewPageButton
