import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import { withRouter } from 'react-router-dom'

import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import SidebarButton from '../../components/ui/general/buttons/SidebarButton'
import ArchiveButton from '../../components/ui/general/buttons/ArchiveButton'
import RestoreButton from '../../components/ui/general/buttons/RestoreButton'
import OfferHeading from '../../components/ui/dataDisplay/OfferHeading'
import Accordion from './../../components/ui/dataDisplay/Accordion'
import OfferAttributes from './OfferAttributes'
import OfferGeneralDetails from './OfferGeneralDetails'
import OfferAvailability from './OfferAvailability'
import OfferPrice from './OfferPrice'
import TermsAndConditions from './OfferTermsAndConditions'
import RelatedAssets from '../assetManager/RelatedAssets'
import userMessages from './../../constants/messages'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationArchiveOffer from './../../graphQL/offer/updateOfferDetails'
import QueryAllOffers from './../../graphQL/offer/searchOffer'
import AppContext from '../../AppContext'

const deleteWarningMessage = {
  message: userMessages.OFFER_DELETE_CONFIRMATION,
  title: 'ARCHIVE ?'
}
const restoreWarningMessage = {
  message: userMessages.OFFER_RESTORE_CONFIRMATION,
  title: 'RESTORE ?'
}

const width = ['100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%']

class OfferDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shouldShowWarning: false,
      shouldShowRestoreWarning: false,
      isDeleteLoading: false,
      isRestoreLoading: false,
      isSaveDisabled: false

    }
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  toggleRestoreWarningMessage = status => {
    this.setState({ shouldShowRestoreWarning: status })
  }

  deleteCurrentOffer = () => {
    if (this.state.isDeleteLoading) {
      return
    }
    this.setState({ isDeleteLoading: true })
    this.props.archiveOffer(this.props.details.id).then(response => {
      this.props.onDeleteOffer([this.props.details.id])
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
    }, error => {
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  restoreCurrentOffer = () => {
    if (this.state.isRestoreLoading) {
      return
    }
    this.setState({ isRestoreLoading: true })
    this.props.restoreOffer(this.props.details.id).then(response => {
      this.props.onDeleteOffer([this.props.details.id])
      this.setState({ isRestoreLoading: false })
      this.toggleRestoreWarningMessage(false)
    }, error => {
      this.setState({ isRestoreLoading: false })
      this.toggleRestoreWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  addingSubAsset = () => {
    this.setState({ isSaveDisabled: true })
  }

  onEditRelatedAssets = () => {
    this.setState({ isSaveDisabled: false }, () => {
      this.props.onEditRelatedAssets(true)
    })
  }

  render () {
    const {
      details,
      handleGeneralChange,
      onInputFieldBlur,
      onInputFieldFocus,
      isInvalidTitle,
      handleRecurrenceChange,
      handlePartnerChange,
      handleDateChange,
      handleActiveChange,
      onRemovePriceField,
      handleTermAndConditionChange,
      handleAttributeDetailsChange,
      handlePriceModal,
      handleEditPrice,
      handleActivePriceChange,
      createdBucket,
      isSaving,
      project,
      activePriceId,
      submitOfferDetails,
      isEdited,
      isArchive,
      listPartners,
      listRecurrence,
      activePricesWithPromoId,
      disabled,
      handleMetaDetailsChange,
      metaFieldList,
      priceMetaFieldList
    } = this.props

    const { shouldShowWarning, shouldShowRestoreWarning, isDeleteLoading, isRestoreLoading, isSaveDisabled } = this.state

    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['OFFER_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          return <>
            <div
              className='container'
              id='offer-container'
              ref={(node) => {
                this.containerRef = node
              }}
            >
              <OfferHeading
                active={details && details.isActive}
              />
              <Accordion
                title='General'
                childComponent={
                  <Skeleton
                    active
                    avatar={false}
                    title={false}
                    paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}
                  >
                    {details ? <OfferGeneralDetails
                      details={_.cloneDeep(details)}
                      offerId={_.cloneDeep(details.id)}
                      handleGeneralChange={handleGeneralChange}
                      handleMetaDetailsChange={handleMetaDetailsChange}
                      onInputFieldBlur={onInputFieldBlur}
                      onInputFieldFocus={onInputFieldFocus}
                      isInvalidTitle={isInvalidTitle}
                      handleRecurrenceChange={handleRecurrenceChange}
                      handlePartnerChange={handlePartnerChange}
                      project={project}
                      listPartners={listPartners}
                      listRecurrence={listRecurrence}
                      disabled={disabled}
                      metaFieldList={metaFieldList}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Offer Attributes'
                childComponent={
                  <Skeleton
                    active
                    avatar={false}
                    title={false}
                    paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}
                  >
                    {details ? <OfferAttributes
                      attributes={details.attributes ? _.cloneDeep(details.attributes) : _.cloneDeep(details && details.partner && details.partner.attributes)}
                      handleAttributeDetailsChange={handleAttributeDetailsChange}
                      attributesData={_.cloneDeep(details && details.partner && details.partner.attributes)}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Price'
                childComponent={
                  <Skeleton
                    active
                    avatar={false}
                    title={false}
                    paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}
                  >
                    {details ? <OfferPrice
                      offerPriceDetails={details.prices}
                      onRemovePriceField={onRemovePriceField}
                      handlePriceModal={handlePriceModal}
                      handleEditPrice={handleEditPrice}
                      activePrice={activePriceId}
                      handleActivePriceChange={handleActivePriceChange}
                      activePricesWithPromoId={activePricesWithPromoId}
                      project={project}
                      isUpdateDisabled={isUpdateDisabled}
                      isCreateDisabled={isCreateDisabled}
                      isDeleteDisabled={isDeleteDisabled}
                      priceMetaFieldList={priceMetaFieldList}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Availability'
                childComponent={
                  <Skeleton
                    active
                    avatar={false}
                    title={false}
                    paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}
                  >
                    {details ? <OfferAvailability
                      details={details}
                      handleDateChange={handleDateChange}
                      handleActiveChange={handleActiveChange}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Assets For The Offer'
                childComponent={
                  <Skeleton
                    active
                    avatar={false}
                    title={false}
                    paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}
                  >
                    {details ? <RelatedAssets
                      bucketId={details && details.assets ? details.assets.id : null}
                      assetId={details.id}
                      createdBucket={createdBucket}
                      onEditRelatedAssets={this.onEditRelatedAssets}
                      isSaving={isSaving}
                      isUpdateDisable={isUpdateDisabled}
                      project={project}
                      addingSubAsset={this.addingSubAsset}
                      manager='offer'
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Term and Conditions'
                childComponent={
                  <Skeleton
                    active
                    avatar={false}
                    title={false}
                    paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}
                  >
                    {details ? <TermsAndConditions
                      terms={details.terms}
                      handleTermAndConditionChange={handleTermAndConditionChange}
                      onInputFieldFocus={onInputFieldFocus}
                      onInputFieldBlur={onInputFieldBlur}
                    /> : null}
                  </Skeleton>
                }
              />
            </div>
            <div className='offer-details-footer'>
              {!isArchive && !details.isArchived ? <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={() => this.toggleWarningMessage(true)} isDisabled={isSaving} />} /> : null }
              {!isArchive && details.isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={() => this.toggleRestoreWarningMessage(true)} isDisabled={isSaving} />} /> : null }
              <ConfirmModal isVisible={shouldShowWarning} title={deleteWarningMessage.title} message={deleteWarningMessage.message} isLoading={isDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.toggleWarningMessage(false)} handleSubmit={this.deleteCurrentOffer} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <ConfirmModal isVisible={shouldShowRestoreWarning} title={restoreWarningMessage.title} message={restoreWarningMessage.message} isLoading={isRestoreLoading} rightButtonText={'Confirm'} handleCancel={() => this.toggleRestoreWarningMessage(false)} handleSubmit={this.restoreCurrentOffer} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <div className='right'>
                <div className='side-button'>
                  <ButtonContainer
                    displayTitle='Toggle Sidebar'
                    childComponent={
                      <SidebarButton onClick={this.props.toggleSidebar} />
                    }
                  />
                </div>
                <div style={{ width: '150px' }}>
                  <LoadingButton
                    type='primary'
                    onClick={() => submitOfferDetails()}
                    htmlType='submit'
                    buttonText='Save'
                    buttonClass='save-btn'
                    isLoading={isSaving}
                    isDisabled={!isEdited || false || isSaveDisabled || isUpdateDisabled}
                  />
                </div>
              </div>
            </div>
      </>
        }}
      </AppContext.Consumer>
    )
  }
}

OfferDetails.propTypes = {
  /** function to handle changes in OfferGeneralDetails */
  handleGeneralChange: PropTypes.func,
  /** Offer details of selected Offer. */
  details: PropTypes.object,
  /** function to handle blur changes in OfferGeneralDetails */
  onInputFieldBlur: PropTypes.func,
  /** function to handle focus changes in OfferGeneralDetails */
  onInputFieldFocus: PropTypes.func,
  /** Boolean to validate input error */
  isInvalidTitle: PropTypes.bool,
  /** function to handle partner dropdown changes in OfferGeneralDetails */
  handlePartnerChange: PropTypes.func,
  /** function to handle price edit */
  handleEditPrice: PropTypes.func,
  /** function to handle add price */
  handlePriceModal: PropTypes.func,
  /** function to handle changes in Rich text Editor */
  handleTermAndConditionChange: PropTypes.func,
  /** function to handle active status change in price */
  handleActivePriceChange: PropTypes.func,
  /** function to handle creating Bucket */
  createdBucket: PropTypes.func,
  /** function to edit related assets */
  onEditRelatedAssets: PropTypes.func,
  /** function to handle change in attribute details */
  handleAttributeDetailsChange: PropTypes.func,
  /** function to handle removal of price field */
  onRemovePriceField: PropTypes.func,
  /** function to handle change in status of offer */
  handleActiveChange: PropTypes.func,
  /** function to handle changes in date */
  handleDateChange: PropTypes.func,
  /** function to handle changes to recurrance field */
  handleRecurrenceChange: PropTypes.func,
  /** is edited details status of OfferDetails. */
  isEdited: PropTypes.bool.isRequired,
  /** is Saving details status of OfferDetails. */
  isSaving: PropTypes.bool.isRequired,
  /** toggle side bar value of OfferDetails. */
  showSidebar: PropTypes.bool,
  /** toggle side bar action of OfferDetails. */
  toggleSidebar: PropTypes.func

}

export default withApollo(compose(
  graphql(
    MutationArchiveOffer,
    {
      props: (props) => ({
        archiveOffer: (id) => {
          return props.mutate({
            variables: { id, isArchived: true, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updateOffer } }) => {
          const { searchString, filter, sort } = props.appliedFilter
          const { project } = props
          const variables = utilityService.getFormattedOfferFilter(searchString, filter, sort, project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllOffers, variables }))
          if (variables.filter.isArchived && (variables.filter.isArchived.match === true || variables.filter.isArchived.match === 'true')) {
            if (cacheData && cacheData.listOffers && cacheData.listOffers.items) {
              const index = cacheData.listOffers.items.findIndex(item => item.id === updateOffer.id)
              if (index === -1) {
                cacheData.listOffers.items.splice(0, 0, updateOffer)
                cacheData.listOffers.totalCount = cacheData.listOffers.totalCount + 1
              }
            }
          } else if (variables.filter.isArchived && (variables.filter.isArchived.match === false || variables.filter.isArchived.match === 'false')) {
            if (cacheData && cacheData.listOffers && cacheData.listOffers.items) {
              const index = cacheData.listOffers.items.findIndex(item => item.id === updateOffer.id)
              if (index > -1) {
                cacheData.listOffers.items.splice(index, 1)
                cacheData.listOffers.totalCount = cacheData.listOffers.totalCount - 1
              }
            }
          }

          cache.writeQuery({
            query: QueryAllOffers,
            data: cacheData,
            variables
          })
        }
      })
    }

  ),
  graphql(
    MutationArchiveOffer,
    {
      props: (props) => ({
        restoreOffer: (id) => {
          return props.mutate({
            variables: { id, isArchived: false, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updateOffer } }) => {
          const { searchString, filter, sort } = props.appliedFilter
          const { project } = props
          const variables = utilityService.getFormattedOfferFilter(searchString, filter, sort, project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllOffers, variables }))
          if (!filter.isArchived || (filter.isArchived && (filter.isArchived.match === true || filter.isArchived.match === 'true'))) {
            if (cacheData && cacheData.listOffers && cacheData.listOffers.items) {
              const index = cacheData.listOffers.items.findIndex(item => item.id === updateOffer.id)
              if (index > -1) {
                cacheData.listOffers.items.splice(index, 1)
                cacheData.listOffers.totalCount = cacheData.listOffers.totalCount - 1
              }
            }
          } else if (filter.isArchived && (filter.isArchived.match === false || filter.isArchived.match === 'false')) {
            if (cacheData && cacheData.listOffers && cacheData.listOffers.items) {
              const index = cacheData.listOffers.items.findIndex(item => item.id === updateOffer.id)
              if (index === -1) {
                cacheData.listOffers.items.splice(0, 0, updateOffer)
                cacheData.listOffers.totalCount = cacheData.listOffers.totalCount + 1
              }
            }
          }
          cache.writeQuery({
            query: QueryAllOffers,
            data: cacheData,
            variables
          })
        }
      })
    }
  )
)(withRouter(withApollo(OfferDetails))))
