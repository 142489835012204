import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import moment from 'moment'
import './../ui.style.scss'

import BucketPublishDetailsCell from './../dataEntry/inputs/BucketPublishDetailsCell'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetBucketDetails from './../../../graphQL/bucket/getBucketPublishRules'
import MutationUpdateBucket from './../../../graphQL/bucket/updateBucketPublishRules'
import { utilityService } from '../../../services/UtilityService'

class PublishBucketModal extends React.Component {
  state = {
    bucketDetails: undefined,
    isEdited: false,
    isPublishLoading: false // for publish button disable
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.bucketDetails && (!_.isEqual(newProps.bucketDetails, this.props.bucketDetails) || (!this.state.bucketDetails && _.isEqual(newProps.bucketDetails, this.props.bucketDetails)))) {
      this.setState({ bucketDetails: _.cloneDeep(newProps.bucketDetails) })
    }
  }

  onPublishRuleStartDateChange = (value, index) => {
    let { publishRules } = this.state
    publishRules[index].startDate = value ? moment(value).utc() : null
    this.setState({ publishRules, isEdited: true })
  }

  onPublishRuleEndDateChange = (value, index) => {
    let { publishRules } = this.state
    publishRules[index].endDate = value ? moment(value).utc() : null
    this.setState({ publishRules, isEdited: true })
  }

  onPublishButtonActiveChange = (index) => {
    const { bucketDetails } = this.props
    let { publishRules } = bucketDetails
    publishRules[index].isActive = !publishRules[index].isActive

    this.setState({ publishRules, isEdited: true }, this.updatePublishRules)
  }

  submit = () => {
    this.updatePublishRules()
    this.props.handleCancel()
  }

  updatePublishRules = () => {
    const { bucketDetails } = this.state
    const request = {
      id: bucketDetails.id,
      publishStartDate: bucketDetails.publishStartDate ? new Date(bucketDetails.publishStartDate).toISOString() : new Date().toISOString(),
      publishEndDate: bucketDetails.publishEndDate ? new Date(bucketDetails.publishEndDate).toISOString() : null,
      isPublished: bucketDetails.isPublished
    }
    this.props.updateBucketPublishRules(request).then(() => {
      this.setState({ isPublishLoading: false }, () => {
        if (this.props.updateHistory) { this.props.updateHistory() }
      })
    }, error => {
      bucketDetails.isPublished = !bucketDetails.isPublished
      this.setState({ bucketDetails, isPublishLoading: false }, () => {
        utilityService.handleError(error)
      })
    })
  }

  onPublishAllStartDateChange = (value) => {
    let { bucketDetails } = this.state
    bucketDetails.publishStartDate = value ? moment(value).utc() : bucketDetails.publishStartDate
    this.setState({ bucketDetails, isEdited: true })
  }

  onPublishAllEndDateChange = (value) => {
    let { bucketDetails } = this.state
    bucketDetails.publishEndDate = value ? moment(value).utc() : null
    this.setState({ bucketDetails, isEdited: true })
  }

  onPublishAllButtonActiveChange = (index) => {
    let { bucketDetails } = this.state
    bucketDetails.isPublished = !bucketDetails.isPublished
    if (!bucketDetails.publishStartDate) {
      bucketDetails.publishStartDate = moment().utc()
    }
    this.setState({ bucketDetails, isEdited: true, isPublishLoading: true }, this.updatePublishRules)
  }

  clearState = () => {
    this.setState({
      bucketDetails: _.cloneDeep(this.props.bucketDetails),
      isEdited: false
    })
  }

  render () {
    const { bucketDetails, isEdited, isPublishLoading } = this.state
    const { handleCancel, visible } = this.props
    const isValidDate = bucketDetails && bucketDetails.publishStartDate && bucketDetails.publishEndDate
      ? moment(bucketDetails.publishStartDate).isBefore(moment(bucketDetails.publishEndDate)) : true
    const tempTitle = <div className='title'>
      <p>PUBLISH BUCKET</p>
      <p>{bucketDetails ? bucketDetails.displayName : null}</p>
    </div>
    return (

      <Modal
        className='general-modal publish-bucket-modal'
        visible={visible}
        closable={false}
        title={tempTitle}
        onOk={this.submit}
        onCancel={handleCancel}
        okText='Done'
        cancelText='Back'
        okButtonProps={{ disabled: !(isEdited && isValidDate) }}
        destroyOnClose
        afterClose={this.clearState}
      >
        <div className='publish-heading'>
          <div className='dates'>
            <label className='dates-selector'>Publish Start Date</label>
            <label className='dates-selector'>Publish End Date</label>
          </div>
          <label className='publishing'>Publish</label>
        </div>
        {/* <BucketPublishDetailsCell
          type='QA/Staging'
          // startDate={' '}
          // endDate={' '}
          // isActive={' '}
          onChange={() => ({ })}
          onStartDateChange={() => ({ })}
          onEndDateChange={() => ({ })}
        /> */}
        {bucketDetails ? <BucketPublishDetailsCell
          type='All'
          startDate={bucketDetails.publishStartDate}
          endDate={bucketDetails.publishEndDate}
          isActive={bucketDetails.isPublished}
          onChange={this.onPublishAllButtonActiveChange}
          onStartDateChange={this.onPublishAllStartDateChange}
          onEndDateChange={this.onPublishAllEndDateChange}
          dateFormat='DD MMM YYYY, HH:mm'
          isLoading={isPublishLoading}
        /> : null}
        {/* <div className='publish-rules'>
          <p>Publish Rules</p>
        </div>

        {this.props.publishRules.map((item, index) => (
          <BucketPublishDetailsCell
            startDate={item.startDate}
            endDate={item.endDate}
            type={item.ruleName}
            isActive={item.isActive}
            onChange={() => onPublishButtonActiveChange(index)}
            onStartDateChange={(value) => onPublishRuleStartDateChange(value, index)}
            onEndDateChange={(value) => onPublishRuleEndDateChange(value, index)}
          />
        ))} */}

      </Modal>

    )
  }
}

PublishBucketModal.propTypes = {
  /** visible action of PublishBucketModal. */
  visible: PropTypes.bool,
  /** title  of PublishBucketModal. */
  title: PropTypes.string,
  /** handleCancel action of PublishBucketModal. */
  handleCancel: PropTypes.func
}

PublishBucketModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryGetBucketDetails,
    {
      skip: ({ bucketId }) => {
        return !bucketId
      },
      options: ({ bucketId }) => {
        return {
          variables: { id: bucketId }
        }
      },
      props: (props) => {
        return {
          bucketDetails: props.data.getBucket,
          isLoading: props.data.loading,
          fetchAssetDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateBucket,
    {
      options: ({ bucketId }) => ({
        update: (cache, { data: { updateBucket } }) => {
          const variables = { id: bucketId }
          const cachedData = _.cloneDeep(cache.readQuery({ query: QueryGetBucketDetails, variables }))
          if (cachedData && cachedData.getBucket) {
            cachedData.getBucket = updateBucket
          }
          cache.writeQuery({
            query: QueryGetBucketDetails,
            data: cachedData,
            variables
          })
        }
      }),
      props: (props) => ({
        updateBucketPublishRules: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(PublishBucketModal))
