import React from 'react'
import PropTypes from 'prop-types'

const AddIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill={color} d='M18.75 7.5H12.5V1.25C12.5.562 11.937 0 11.25 0h-2.5C8.062 0 7.5.563 7.5 1.25V7.5H1.25C.562 7.5 0 8.063 0 8.75v2.5c0 .688.563 1.25 1.25 1.25H7.5v6.25c0 .688.563 1.25 1.25 1.25h2.5c.688 0 1.25-.563 1.25-1.25V12.5h6.25c.688 0 1.25-.563 1.25-1.25v-2.5c0-.688-.563-1.25-1.25-1.25z' />
    </g>
  </svg>
)

AddIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

AddIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default AddIcon
