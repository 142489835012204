import gql from 'graphql-tag'

export default gql(`
query getUserEvents($filter: UserAuditFilter $limit: Int $offset: Int $project: String){
    getUserEvents(
        limit: $limit
        offset: $offset
        filter: $filter
        project: $project
    ){
        items{
            id
            user
            userAgent
            userEvent
            ip
            time
            errorMessage 
        }
        totalCount
    }
}`)
