import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GeneralStreamCell from '../../../components/ui/dataDisplay/GeneralStreamCell'
import InformationIcon from '../general/icons/InformationIcon'
import LoadingButton from '../general/buttons/LoadingButton'

// import { utilityService } from '../../../services/UtilityService'

class ConfigGroupStreams extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }
  render () {
    const { streams, onSelect, configName, addStreamLevelObjects, onCloseStreamLevels, channelStreamList } = this.props

    const heading = <div className='type'>
      <div>{'Streams'}<span>*</span></div>
      <div className='information-icon'>
        <InformationIcon />
      </div>
    </div>

    return (
      <div className='config-group-streams'>
        <div className='config-stream-heading'>
          {heading}
        </div>
        <div className='channel-stream-content'>
          {streams && streams.length
            ? streams.map((level, index) => (
              <GeneralStreamCell streamLevels={level} onCloseStreamLevels={onCloseStreamLevels} onSelect={onSelect} channelStreamList={channelStreamList} key={index} />
            )) : null }
        </div>
        {/* <GeneralStreamCell onCloseStreamLevels={onCloseStreamLevels} isEnableSave={isEnableSave} optionList={listType} placeHolder1={placeHolder1} placeHolder2={placeHolder2} /> */}
        <LoadingButton
          type='primary'
          onClick={addStreamLevelObjects}
          htmlType='submit'
          buttonText={'Add Streams'}
          buttonClass='save-btn'
          isLoading={false}
          isDisabled={!configName}
        />
      </div>
    )
  }
}

ConfigGroupStreams.propTypes = {
  /** Title of first column */
  columnTitle1: PropTypes.string,
  /** Title of second column */
  columnTitle2: PropTypes.string,
  /** Title of third column */
  columnTitle3: PropTypes.string,
  /** array of object list for dropdown */
  listType: PropTypes.array,
  /** Placeholder name of column 1 */
  placeHolder1: PropTypes.string,
  /** Placeholder name of column 2 */
  placeHolder2: PropTypes.string
}

ConfigGroupStreams.defaultProps = {
  columnTitle1: 'Field Objects',
  columnTitle2: 'Field Name',
  columnTitle3: 'Required',
  placeHolder1: 'Insert Stream Name',
  placeHolder2: 'Insert Group Type',
  listType: [{
    name: 'Number',
    value: 'number'
  }, {
    name: 'String',
    value: 'string'
  }]
}

export default ConfigGroupStreams
