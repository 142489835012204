import gql from 'graphql-tag'

export default gql(`
    mutation(
        $name: String!    
        $project: String
   ) {
    createPartner(
            input: {
            name: $name         
            }
            project: $project
        ) {
            id
        }
    }`
)
