import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, message } from 'antd'

import NProgress from 'nprogress'

import PartnerSideBar from './PartnerSideBar'
import PartnerDetails from './PartnerDetails'
import LoggerService from './../../services/LoggerService'
import userMessages from './../../constants/messages'

import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryUploadImage from '../../graphQL/asset/getUploadUrl'
import MutationCreateMedia from './../../graphQL/content/createMedia'
import ApiService from './../../services/ApiService'
import QueryGetPartnerDetails from './../../graphQL/partner/getPartnerDetails'
import MutationUpdatePartner from './../../graphQL/partner/updatePartnerDetails'
import MutationUpdateAttributes from '../../graphQL/partner/updateOfferAttribute'
import QueryGetAppsList from './../../graphQL/partner/getAppsList'
import QueryCustomMetaFields from './../../graphQL/admin/customMetaFields/listCustomMetaFields'

let primaryImageType

class PartnerEditor extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    this.state = {
      details: undefined,
      isMetaDescEdited: false,
      isOldSlug: false,
      uploadingFiles: [],
      isAssetImageLoading: false,
      activeImageType: primaryImageType,
      isLoading: false,
      isSaving: false,
      isEdited: false,
      refetchHistoryList: false
    }
    this.invalidTitle = false
  }

  componentDidMount=() => {
    if (this.props.details) {
      // this.setState({ details: this.props.details })
      NProgress.done()
    }
  }

UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
  if (newProps.partnerId !== this.props.partnerId && newProps.partnerId) {
    this.setState({ isLoading: true })
    this.changeEditStatus(false)
  } else if (!newProps.partnerId) {
    this.setState({ isLoading: false, details: undefined })
  }
  if (newProps.shouldSave && !this.props.shouldSave) {
    this.submitPartnerDetails()
  }
  if (newProps.details && !_.isEqual(newProps.details, this.props.details) && !this.state.isEdited) {
    this.setState({ details: _.cloneDeep(newProps.details), isLoading: false })
    NProgress.done()
  } else if (newProps.details && (!this.props.details || newProps.partnerId !== this.props.partnerId)) {
    this.setState({ details: { ...newProps.details }, isLoading: newProps.partnerIsLoading })
    NProgress.done()
  }

  const { uploadData, isUploadError } = newProps
  if (isUploadError && !this.props.isUploadError) {
    this.setState({ uploadingFiles: [] })
  }
  if (uploadData && !_.isEqual(uploadData, this.props.uploadData)) {
    if (uploadData.length > 0) {
      this.uploadImageApi(uploadData[0], uploadData, uploadData.length, 0)
    }
  }
}

fetchHistory = () => {
  this.setState({ refetchHistoryList: true }, () => {
    this.setState({ refetchHistoryList: false })
  })
}

  onInputFieldFocus = () => {
    this.isEditingInput = true
  }

  onInputFieldBlur = (e) => {
    if (!this.isEditingInput) {
      return
    }
    const { details } = this.state
    const defaultSlug = utilityService.slugifyString(`${details.id}-untitled`)
    const isFirstSlug = details.slug && (details.slug.indexOf('untitled-') === 0 || details.slug.indexOf(defaultSlug) === 0)
    if (isFirstSlug && details.title !== 'Untitled') {
      const newTitle = details.type === 'PARTNER' ? details.title.toLowerCase() : `${details.id}-${details.title.toLowerCase()}`
      const slug = utilityService.slugifyString(newTitle)
      details.slug = slug
      this.setState({ details })
    }
    this.isEditingInput = false
  }

  handleGeneralChange = (e) => {
    const { details, isMetaDescEdited } = this.state
    const { name, value } = e.target
    if (name === 'mediumDescription' && !isMetaDescEdited && details.mediumDescription === details.seoMetaDescription) { details.seoMetaDescription = value ? value.substr(0, 155) : null }
    if (name === 'name' && (!details.seoTitle || details.seoTitle === details.name)) {
      details.seoTitle = value
    }
    details[name] = value || ''
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleActiveChanges = (active) => {
    const { details } = this.state
    details.isActive = active
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleAppsChange = value => {
    const { details } = this.state
    details.apps = value
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleTermAndConditionChange=(value) => {
    const { details } = this.state
    const { termsAndConditions } = details
    const isScriptStartTag = value.indexOf('&lt;script')
    const isScriptEndTag = value.indexOf('&lt;/script&gt;')
    if (isScriptStartTag > -1 && isScriptEndTag > -1) {
      value = `${value.substring(0, isScriptStartTag)}${value.substring(isScriptEndTag + 15, value.length)}`
    }
    const tempValue = (value || '').replace(/<br \/>/g, '<br>')
    const tempOldValue = (termsAndConditions || '').replace(/<br \/>/g, '<br>')
    let isSame
    if (tempValue === tempOldValue) {
      isSame = true
    }
    if (termsAndConditions !== value) {
      details.termsAndConditions = value
    } else return
    this.setState({ details }, () => {
      if (!isSame) {
        this.changeEditStatus(true)
      }
    })
  }

  handleSeoDetailsChange = e => {
    // const { details } = this.state
    // const { name, value } = e.target
    // details[name] = value
    // this.setState({ details }, () => {
    //   this.changeEditStatus(true)
    // })

    const { details } = this.state
    let { isMetaDescEdited } = this.state
    const { name, value } = e.target
    details[name] = value
    if (name === 'seoMetaDescription') { isMetaDescEdited = true }
    if (name === 'slug') {
      this.slugEdited = true
      const modified = utilityService.slugifyString(value)
      if (value !== modified) {
        this.invalidSlug = true
      } else {
        this.setState({ isOldSlug: false })
        this.invalidSlug = false
      }
    }
    this.setState({ details, isMetaDescEdited }, () => this.changeEditStatus(true))
  }

  onSeoKeywordChange = (value, isDelete) => {
    const { details } = this.state
    if (isDelete) {
      const index = (details.seoKeywords || []).indexOf(value)
      if (index > -1) {
        details.seoKeywords.splice(index, 1)
      }
    } else {
      if (!details.seoKeywords) {
        details.seoKeywords = [value]
      } else {
        const index = (details.seoKeywords || []).findIndex(item => item === value)
        if (index === -1) {
          details.seoKeywords.push(value)
        }
      }
    }
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  onCloseImage = id => {
    const { details, activeImageType } = this.state
    let { images } = details
    images = (images || []).sort((a, b) => {
      const dateA = new Date(a.createdAt)
      const dateB = new Date(b.createdAt)
      return dateA - dateB
    })
    const index = images.findIndex(item => item.id === id)
    images.splice(index, 1)
    images.filter(item => item.imageType === activeImageType)
    this.setState({ isAssetImageLoading: true })
    details.images = images
    if (details.logo && details.logo.id === id) {
      if (details.images && details.images.length) {
        details.logo = details.images[0]
      } else {
        details.logo = null
      }
    }

    this.setState({
      details,
      isAssetImageLoading: false
    }, () => {
      this.changeEditStatus(true)
    })
  }

  onAddImageFromLocal = (data, isImage, imageType, cropPos) => {
    if (isImage) {
      const { uploadingFiles } = this.state
      const dataList = [...data.files]
      const tagList = [...data.tags]
      for (let i = 0; i < dataList.length; i++) {
        const contentType = dataList[i].type
        const fileName = dataList[i].name
        const fileSize = dataList[i].size
        if (contentType && fileName) {
          const newFile = {
            fileName,
            fileSize,
            aspectRatio: [],
            contentType,
            percentage: 0,
            id: fileName,
            file: dataList[i],
            tagList,
            imageType,
            cropPos
          }

          uploadingFiles.splice(0, 0, newFile)
        }
      }
      this.setState({ uploadingFiles, isEdited: true })
    }
    this.props.handleFileUpload(data, isImage)
  }

  onMediaAdd = (media, selectedImageIndex, category) => {
    this.setState({ isAssetImageLoading: true })
    const { details } = this.state
    // const imageContents = (media || []).map(image => {
    //   const newImage = {
    //     mediaId: image.id,
    //     assetId: details.id,
    //     imageTypeId: activeImageType,
    //     isDefault: 'false',
    //     assetType: details.type
    //   }
    //   if (category) { newImage.categoryId = category }
    //   return newImage
    // })
    if (!details.images || !details.images.length) {
      details.logo = media[0]
    }

    details.images = details.images ? [...details.images, ...media] : [...media]

    if (selectedImageIndex) {
      let { uploadingFiles } = this.state
      const imageDeleteIndex = (uploadingFiles || []).findIndex(image => image.id === selectedImageIndex)
      uploadingFiles.splice(imageDeleteIndex, 1)
      this.setState({ details, isAssetImageLoading: false, uploadingFiles }, () => {
        this.changeEditStatus(true)
      })
    } else {
      this.setState({ details, isAssetImageLoading: false }, () => {
        this.changeEditStatus(true)
      })
    }
  }

  updateMediaDetails = (updatedMedia, isVideo) => {
    let { details } = this.state
    let { images, videos } = details
    if (isVideo) {
      if (videos && videos.length) {
        const index = (videos || []).findIndex(item => item.id === updatedMedia.id)
        if (index > -1) {
          videos[index] = updatedMedia
          details.videos = videos
          this.setState({ details })
        }
      }
    } else {
      if (images && images.length) {
        const index = (images || []).findIndex(item => item.id === updatedMedia.id)
        if (index > -1) {
          images[index] = updatedMedia
          details.images = images
          this.setState({ details })
        }
      }
    }
  }

  onImageTypeChange = (activeImageType) => {
    this.setState({ activeImageType })
  }

  onChangeDefaultImage = imageId => {
    const { details } = this.state
    const partnerLogo = (details.images || []).find(item => item.id === imageId)
    if (!_.isEmpty(partnerLogo)) {
      details.logo = partnerLogo
      this.setState({ details })
      this.changeEditStatus(true)
    }
  }

  uploadImageApi = (uploadData, dataArr, uploadSize, uploadIndex) => {
    const { fileName, contentType, key, uploadUrl, category } = uploadData
    const successCallBack = () => {
      const { uploadingFiles } = this.state
      const index = (uploadingFiles || []).findIndex(image => image.id === key)
      const tags = (uploadingFiles[index].tagList || []).map(tag => tag.key)
      this.props.createMedia(key, fileName, 'IMAGE', tags).then(response => {
        const selectedImageIndex = (uploadingFiles || []).findIndex(image => image.id === key)
        const newMedia = response.data.createMedia
        newMedia.imageType = uploadingFiles[selectedImageIndex].imageType
        newMedia.cropPos = uploadingFiles[selectedImageIndex].cropPos
        this.onMediaAdd([newMedia], key, category)
        if (uploadSize !== (uploadIndex + 1)) {
          setTimeout(() => {
            this.uploadImageApi(dataArr[uploadIndex + 1], dataArr, uploadSize, uploadIndex + 1)
          }, 300)
        }
      }, error => {
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        if (selectedImageIndex > -1) {
          uploadingFiles.splice(selectedImageIndex, 1)
        }
        this.setState({ uploadingFiles })
        if (uploadSize !== (uploadIndex + 1)) {
          this.uploadImageApi(dataArr[uploadIndex + 1], dataArr, uploadSize, uploadIndex + 1)
        }
        utilityService.handleError(error)
      })
    }

    const failureCallBack = () => {
      const { uploadingFiles } = this.state
      message.success(`Failed to create media ${fileName}`)
      const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
      if (selectedImageIndex > -1) {
        uploadingFiles.splice(selectedImageIndex, 1)
      }
      this.setState({ uploadingFiles })
    }

    const progressCallBack = progress => {
      const { uploadingFiles } = this.state
      const selectedImageIndex = (uploadingFiles || []).findIndex(image => image.id === key)
      if (selectedImageIndex > -1) {
        uploadingFiles[selectedImageIndex].percentage = progress
      }
      this.setState({ uploadingFiles })
    }
    const { uploadingFiles } = this.state
    const selectedImageIndex = uploadingFiles.findIndex(image => image.id === fileName)
    if (selectedImageIndex > -1) {
      uploadingFiles[selectedImageIndex].id = key
      uploadingFiles[selectedImageIndex].isUploading = true
      const notUploadedFile = uploadingFiles.find(item => !item.isUploading)
      if (!notUploadedFile) {
        this.props.clearFileName()
      }
      this.setState({ uploadingFiles })
      ApiService('PUT', uploadUrl, uploadingFiles[selectedImageIndex].file, { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
    }
  }

  checkForModification = (isFromPublish, shouldBlockMessage) => {
    const { uploadingFiles } = this.state
    if (uploadingFiles && uploadingFiles.length) {
      message.warning('Media upload in progress, Please try again later')
      return
    }
    this.submitPartnerDetails(isFromPublish, shouldBlockMessage)
  }

  trimSpace = (details) => {
    let { contents } = details
    let item = _.find(contents, (contentItem) => contentItem.type === 'RICHTEXT')
    const itemIndex = _.findIndex(contents, (contentItem) => contentItem.type === 'RICHTEXT')
    if (itemIndex === -1) {
      return details
    }
    let { value } = item
    if (itemIndex === -1 || !value) {
      return details
    }
    let length = value ? value.length : 0
    const checkSpace = '<p><br></p>'
    const spaceLength = checkSpace.length
    while (value.lastIndexOf(checkSpace) !== -1 && value.lastIndexOf(checkSpace) + spaceLength === length) {
      const index = value.lastIndexOf(checkSpace)
      value = value.slice(0, index)
      length = value.length
    }
    item.value = value
    contents[itemIndex] = item
    details.contents = contents
    this.setState({ details })
    return details
  }

  submitPartnerDetails = async (isFromPublish, shouldBlockMessage) => {
    const details = this.trimSpace(_.cloneDeep(this.state.details))
    const websiteUrlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    const validURL = websiteUrlRegex.test(details.website)
    if (details.website && !validURL) {
      message.error('Please enter valid website URL')
      this.setState({ isSaving: false, isLoading: false })
      return
    }
    if (!details.name) {
      message.error('Please enter valid Partner name')
      this.setState({ isSaving: false, isLoading: false })
      return
    }

    if (this.invalidSlug || this.state.isOldSlug) {
      if (!shouldBlockMessage) {
        message.error(this.invalidSlug ? userMessages.INVALID_SLUG : userMessages.DUPLICATE_SLUG)
      }
      this.setState({ isSaving: false })
      this.changeErrorStatus(true, true)
      return
    }

    if (details && details.externalId && details.externalId.includes(' ')) {
      message.error('ExternalId cannot include spaces, Please enter valid externalId')
      this.setState({ isSaving: false, isLoading: false })
      return
    }

    this.invalidTitle = false
    const partnerRequest = this.getFormattedRequest(_.cloneDeep(details), isFromPublish)
    this.isEditingInput = false

    this.setState({ isSaving: true }, async () => {
      this.isSavingFlag = false
      NProgress.start()
      LoggerService.info('flow', `Updated partner ${details.id}`)
      let attributeUpdate = await this.updateModifiedAttributesCallback()
      this.changeEditStatus(false)
      if (attributeUpdate) {
        this.props.updatePartner(partnerRequest).then(({ data }) => {
          LoggerService.info('partnerUpdate', `Updated partner details`, partnerRequest)
          this.slugEdited = false
          this.fetchHistory()
          this.setState({ isSaving: false, isLoading: false, details: { ...this.state.details, updatedAt: data.updatePartner.updatedAt, updatedBy: data.updatePartner.updatedBy } })
          NProgress.done()
          if (!shouldBlockMessage) {
            if (isFromPublish === true) {
              setTimeout(() => this.props.fetchpartnerDetails(), 500)
            } else {
              message.success(userMessages.PARTNER_UPDATE_SUCCESS)
            }
          }
        }, error => {
          this.setState({ isSaving: false, isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        NProgress.done()
        this.setState({ isSaving: false, isLoading: false })
        message.error('Please ensure there are no duplicate attribute names or empty attribute fields before Updation')
      }
    })
  }

  getFormattedRequest = (request, isFromPublish) => {
    const formattedRequest = {}
    const { name, externalId, tagLine, website, address, shortDescription, mediumDescription, description, category, chargeCode,
      refundChargeCode, isActive, termsAndConditions, seoTitle, slug, seoMetaDescription, seoKeywords, logo, apps, customMeta } = request

    formattedRequest.id = request.id
    formattedRequest.name = name
    formattedRequest.externalId = externalId
    formattedRequest.tagLine = tagLine
    formattedRequest.website = website || null
    formattedRequest.address = address
    formattedRequest.shortDescription = shortDescription
    formattedRequest.mediumDescription = mediumDescription
    formattedRequest.description = description
    formattedRequest.category = category
    formattedRequest.chargeCode = chargeCode
    formattedRequest.refundChargeCode = refundChargeCode
    formattedRequest.isActive = isActive
    formattedRequest.termsAndConditions = termsAndConditions
    const imageIdArray = request.images ? request.images.map(image => image.id) : []
    formattedRequest.images = imageIdArray
    formattedRequest.seoTitle = seoTitle
    formattedRequest.slug = slug
    formattedRequest.seoMetaDescription = seoMetaDescription
    formattedRequest.seoKeywords = seoKeywords
    formattedRequest.apps = apps
    formattedRequest.logo = logo && !_.isEmpty(logo) ? logo.id : null
    formattedRequest.customMeta = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item && item.config) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      } return newItem
    })
    return formattedRequest
  }

  changeEditStatus = (status) => {
    if (this.state.isEdited !== status) {
      this.setState({ isEdited: status }, () => {
        const editStatus = { isEdited: status, isError: this.state.isOldSlug || this.invalidSlug || this.invalidTitle }
        if (!this.isUpdateDisable) { this.props.changeEditStatus(editStatus) }
      })
    }
    if (status) {
      this.isEditingInput = true
    }
  }

  onDeletePartner = id => {
    this.changeErrorStatus(false, false)
    this.props.onDeletePartner(id)
  }

  onChangeAttributes = (attributes) => {
    const { details } = this.state
    details.attributes = attributes
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  updateModifiedAttributesCallback = () => {
    return new Promise(resolve => {
      this.updateModifiedAttributes(resolve)
    })
  }

  updateModifiedAttributes = async (resolve) => {
    if (!(this.props.details.attributes === null && this.state.details.attributes === [])) {
      let variables = this.state.details.attributes || []
      let array = []
      let duplicateExists = false
      variables.forEach(attribute => {
        if (attribute) {
          const duplicateData = array.find(item => {
            if (item) {
              return (attribute.name === item.name && attribute.name) || !attribute.name || !attribute.type || !attribute.label
            }
          })
          if (!_.isEmpty(duplicateData)) {
            duplicateExists = true
          } else {
            array.push(attribute)
          }
        }
      })
      if (!duplicateExists) {
        variables = (variables || []).filter(item => !_.isEmpty(item.type) && item.label && item.name).map((item, index) => {
          return {
            id: item.id,
            label: item.label,
            name: item.name,
            type: item.type,
            position: item.position,
            trackPosChange: item.trackPosChange
          }
        })
        if (variables.length) {
          this.props.updateOfferAttribute(variables).then(() => {
            resolve(true)
          }, (errorMessage) => {
            resolve(true)
            utilityService.handleError(errorMessage)
          })
        } else {
          resolve(true)
        }
      } else {
        resolve(false)
      }
    } else {
      resolve(false)
    }
  }

  changeErrorStatus = (editedStatus, errorStatus) => {
    // if (this.state.isEdited !== editedStatus) {
    this.setState({ isEdited: editedStatus })
    const editStatus = { isEdited: editedStatus, isError: errorStatus }
    if (!this.isUpdateDisable) { this.props.changeEditStatus(editStatus) }
    // }
  }

  handleMetaDetailsChange = (metaData, value) => {
    const listCustomMetaFields = _.cloneDeep(this.props.metaFieldList)
    const { details } = this.state
    const { customMeta } = details
    const newDetails = _.cloneDeep(details)
    let finalMetaModified = []
    if (value || value === '' || value === false || value === 'false') {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        let newMetaField = {
          config: item,
          value: item && item.default
        }
        if (item.id === metaData.id && item.type === metaData.type) {
          newMetaField.value = item.type === 'STRING' ? (value || '') : value
          delete item.isError
          newMetaField.config = item
        } else {
          const tempItem = (customMeta || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
          if (_.isEmpty(tempItem)) {
            newMetaField.config = item
            newMetaField.value = item.default
          } else {
            newMetaField = tempItem
            newMetaField.value = tempItem.value
          }
        }

        return newMetaField
      })
      finalMetaModified = customMetaModified
    } else if (!value || value === null || value === undefined || value !== false) {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        const foundMeta = (customMeta || []).find(existingMeta => {
          if (existingMeta && existingMeta.config) {
            if (existingMeta.config.id === item.id) { return existingMeta }
          }
        })
        if (_.isEmpty(foundMeta)) {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          return newMetaField
        } else {
          return foundMeta
        }
      })
      finalMetaModified = customMetaModified
    }
    newDetails.customMeta = finalMetaModified
    this.setState({ details: newDetails }, () => {
      this.changeEditStatus(true)
    })
  }

  render () {
    const { showSidebar, toggleSidebar, project, isArchive, selectedOfferId, filterVal, sort, selectedPartnerId, appliedFilter, listApps, metaFieldList, partnerIsLoading } = this.props
    const { details, uploadingFiles, isAssetImageLoading, activeImageType, isOldSlug, isSaving, isEdited, refetchHistoryList } = this.state
    return (
      <Row className='partner-editor'>
        <Col
          className={`partner-details ${showSidebar ? 'small' : ''}`}
          xxl={{ span: 24 - (showSidebar ? 8 : 0) }}
          xl={{ span: 24 - (showSidebar ? 8 : 0) }}
          lg={{ span: 24 - (showSidebar ? 6 : 0) }}
          md={{ span: 24 - (showSidebar ? 6 : 0) }}
          xs={{ span: 24 - (showSidebar ? 6 : 0) }}
        >
          {details ? <PartnerDetails
            toggleSidebar={toggleSidebar}
            showSidebar={showSidebar}
            details={_.cloneDeep(details)}
            handleGeneralChange={this.handleGeneralChange}
            handleActiveChanges={this.handleActiveChanges}
            handleMetaDetailsChange={this.handleMetaDetailsChange}
            onInputFieldFocus={this.onInputFieldFocus}
            onInputFieldBlur={this.onInputFieldBlur}
            handleAppsChange={this.handleAppsChange}
            handleTermAndConditionChange={this.handleTermAndConditionChange}
            isInvalidTitle={this.invalidTitle}
            handleSeoDetailsChange={this.handleSeoDetailsChange}
            onSeoKeywordChange={this.onSeoKeywordChange}
            isInvalidSlug={this.invalidSlug || isOldSlug}
            project={project}
            onChangeDefaultImage={this.onChangeDefaultImage}
            onCloseImage={this.onCloseImage}
            handleFileUpload={this.onAddImageFromLocal}
            uploadingFiles={uploadingFiles}
            isAssetImageLoading={isAssetImageLoading}
            activeImageType={activeImageType}
            onAddMedia={this.onMediaAdd}
            updateMediaDetails={this.updateMediaDetails}
            isLoading={partnerIsLoading}
            submitPartnerDetails={this.checkForModification}
            isSaving={isSaving}
            isEdited={isEdited}
            onDeletePartner={this.onDeletePartner}
            isArchive={isArchive}
            appliedFilter={appliedFilter}
            listApps={listApps}
            onChangeAttributes={this.onChangeAttributes}
            originalOfferAttributes={this.props.details && this.props.details.attributes ? this.props.details.attributes : []}
            refetchHistory={this.fetchHistory}
            metaFieldList={metaFieldList || []}
          /> : null}
        </Col>
        <Col className='partner-side-bar' xxl={{ span: (showSidebar ? 8 : 0) }} xl={{ span: (showSidebar ? 8 : 0) }} lg={{ span: (showSidebar ? 6 : 0) }} md={{ span: (showSidebar ? 6 : 0) }} xs={{ span: (showSidebar ? 6 : 0) }}>
          {selectedPartnerId
            ? <PartnerSideBar
              selectedOfferId={selectedOfferId}
              filterVal={filterVal}
              sort={sort}
              selectedPartnerId={selectedPartnerId}
              isSaving={isSaving}
              details={_.cloneDeep(details)}
              selectedPartner={selectedPartnerId}
              project={project}
              refetchHistoryList={refetchHistoryList}
            />
            : null}
        </Col>
      </Row>

    )
  }
}

PartnerEditor.propTypes = {
  /** toggle side bar value of PartnerManager. */
  showSidebar: PropTypes.bool,
  /** toggle side bar action of PartnerManager. */
  toggleSidebar: PropTypes.func,
  /** project id */
  project: PropTypes.string,
  /** callback function to handle local file upload */
  handleFileUpload: PropTypes.func,
  /** array of the local files upload */
  files: PropTypes.array,
  /** callback function to clear upload files array */
  clearFileName: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryUploadImage,
    {
      options: ({ files, project }) => {
        const fileList = (files.files || []).map(item => {
          return {
            fileName: item.name,
            contentType: item.type,
            type: 'IMAGE'
          }
        })
        return {
          fetchPolicy: 'network-only',
          variables: { files: fileList, project }
        }
      },
      skip: ({ files }) => {
        return (!files || !files.files || !files.files.length)
      },
      props: (props) => {
        const { data, ownProps } = props
        const uploadData = !data.loading && data.getUploadUrl ? data.getUploadUrl.map((item, index) => {
          const currentItem = ownProps.files.files[index]
          return { ...item,
            fileName: currentItem.name,
            contentType: currentItem.type,
            aspectRatio: [],
            fileSize: currentItem.fileSize,
            category: currentItem.category,
            type: 'IMAGE' }
        }) : []
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          ownProps.clearFileName()
          utilityService.handleError(data.error)
        }
        return { uploadData: _.map(uploadData, _.clone), isUploadError }
      }
    }
  ),
  graphql(
    MutationCreateMedia,
    {
      options: ({ searchString, filter, mediaType }) => {
        return { update: (cache, { data: { createMedia } }) => {
        }
        }
      },
      props: (props) => ({
        createMedia: (key, name, type, tags) => {
          return props.mutate({
            variables: { key, name, type, tags, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    QueryGetPartnerDetails,
    {
      skip: ({ partnerId }) => {
        return !partnerId
      },
      options: ({ partnerId, project }) => {
        return {
          variables: { id: partnerId, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        sessionStorage.setItem('PartnerName', props.data.getPartner ? props.data.getPartner.title : '')
        return {
          details: props.data.getPartner,
          partnerIsLoading: props.data.loading,
          detailsError: props.data.error ? props.data.error.message : null,
          fetchPartnerDetails: () => {
            return props.data.refetch()
          }
          // subscribeToChangeInPartner: (partnerId) => subscribeChangeInPartner(props.data.subscribeToMore, props, partnerId)
        }
      }
    }
  ),
  graphql(
    MutationUpdatePartner,
    {
      // options: (props) => ({
      //   update: (cache, { data: { updatePartner } }) => {
      //     const { partnerId } = props
      //     const cacheOfferData = _.cloneDeep(cache.readQuery({ query: QueryGetPartnerDetails, variables: { id: partnerId } }))
      //     debugger
      //     if (cacheOfferData && cacheOfferData.getPartner) {
      //       cacheOfferData.getPartner = updatePartner
      //     }
      //     cache.writeQuery({
      //       query: QueryGetPartnerDetails,
      //       data: cacheOfferData,
      //       variables: { id: partnerId }
      //     })
      //   }
      // }),
      // options: ({partnerId}) => {
      //   const variables = { id: partnerId }
      //   return {
      //     refetchQueries: () => [{ query: QueryGetPartnerDetails, variables }]
      //   }
      // },
      props: (props) => ({
        updatePartner: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    MutationUpdateAttributes,
    {
      props: (props) => ({

        updateOfferAttribute: (input) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    QueryGetAppsList,
    {
      options: (props) => {
        const { project } = props
        return {
          variables: { module: project === 'projectx' ? 'projectx-partner' : 'partner', project: project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          listApps: props.data.listConfig && props.data.listConfig.length ? props.data.listConfig : []
        }
      }
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'PARTNER', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let metaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        metaFieldList = metaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          metaFieldList
        }
      }
    }
  )
)(withApollo(PartnerEditor)))
