import React from 'react'
import PropTypes from 'prop-types'

import MultilineEllipsis from './../../MultilineEllipse'

function OfferListCellData (props) {
  const { partnerName, offerName, shortDescription } = props
  return (
        <>
          <div className='partner-name'>
            <MultilineEllipsis maxLines={'1'} text={partnerName || ''} />
          </div>
          <div className='offer-name'>
            <MultilineEllipsis maxLines={'1'} text={offerName || ''} />
          </div>
          <div className='short-description'>
            <MultilineEllipsis maxLines={'3'} text={shortDescription || ''} />
          </div>
        </>
  )
}

OfferListCellData.propTypes = {
  /* Name of associated Partner  */
  partnerName: PropTypes.string,
  /** Name of selected Offer */
  offerName: PropTypes.string,
  /** short description of selected Offer */
  shortDescription: PropTypes.string
}

export default OfferListCellData
