import gql from 'graphql-tag'

export default gql(`
    mutation createRole($name: String!, $project: String) {
        createRole(
            input: {
                name: $name
            },
            project: $project
        ) {
            id
            name
            usedCount
            source
        }
    }`
)
