import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListAssetType from '../../graphQL/admin/asset/listAssetTypes'

import NProgress from 'nprogress'
import { Row, Col } from 'antd'

import AssetList from './AssetList'
import AssetEditor from './AssetEditor'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import userMessages from './../../constants/messages'
import LoggerService from './../../services/LoggerService'
import { utilityService } from './../../services/UtilityService'
import './AssetManager.scss'

const assetSaveWarningMessage = {
  messageText: userMessages.UNSAVED_ASSET_WARNING,
  title: 'UNSAVED CHANGES'
}

class AssetManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSidebar: window.innerWidth > 1200,
      searchString: '',
      selectedAssetId: '',
      shouldShowWarning: false,
      shouldSave: false,
      shouldDiscard: false,
      isSavingAsset: false,
      deletedIds: [],
      isArchive: false,
      filter: [],
      uploadingImage: [],
      shortcutFIlter: false,
      urlSlug: '',
      sort: {
        order: 'desc',
        value: 'createdAt'
      },
      personSearch: undefined,
      roleSearch: undefined,
      productionStudioSearchValue: undefined,
      isImageUploading: 0
    }
    this.roleSearchTimeout = 0
    this.personSearchTimeout = 0
    this.productionSearchTimeout = 0
  }

  UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      LoggerService.info('flow', 'Asset Manager Loaded')
      NProgress.start()
      this.setState({ selectedAssetId: this.props.match.params.id })
    }
  }

    componentDidMount = () => {
      const { project } = this.props
      document.title = `Asset Manager - ${project === 'hyperion' ? 'Hyperion' : 'Optus'} CMS`
    }

    UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
      if ((!newProps.match.params || !newProps.match.params.id) && this.state.selectedAssetId) {
        this.setState({ selectedAssetId: '' })
      } else if (this.state.selectedAssetId && this.state.selectedAssetId !== newProps.match.params.id) {
        this.setState({ selectedAssetId: newProps.match.params.id })
      }
      if (newProps.showWarning) {
        this.setState({ shouldShowWarning: true })
      }
    }

    setSelectedAsset = id => {
      if (this.state.selectedAssetId !== id && this.state.isEdited) {
        this.tempSelectedId = id
        LoggerService.info('flow', `Asset ${id} Selected`)
        this.toggleWarningMessage(true)
      } else if (this.state.selectedAssetId !== id) {
        NProgress.start()
        this.setState({ selectedAssetId: id, urlSlug: '' }, () => {
          this.props.history.push(`/assets/${window.location.search ? id + window.location.search : id}`)
        })
      }
    }

    forceSave = () => {
      this.props.notifyParent()
      this.setState({ shouldSave: true, isSavingAsset: true })
    }

    discardChanges = () => {
      this.props.notifyParent()
      this.setState({ isEdited: false, shouldDiscard: true }, () => {
        this.toggleWarningMessage(false)
        this.setSelectedAsset(this.tempSelectedId)
        this.setState({ shouldDiscard: false })
      })
    }

    onCloseConfirmModal = () => {
      this.setState({ shouldShowWarning: false }, this.props.clearModuleChange)
    }

    onChangeFilter = value => {
      this.setState({ searchString: value })
    }

    onDeleteAsset = ids => {
      this.setState({ deletedIds: ids })
    }

    onDuplicateAsset = id => {
      this.setState({ duplicatedAssetId: id })
    }

    clearFilterDetails = () => {
      this.setState({ searchString: null, filter: [], shouldClearFilter: true }, () => {
        setTimeout(() => this.setState({ shouldClearFilter: false }), 200)
      })
    }

    archiveButtonHandle = (status) => {
      this.setState({ isArchive: status })
    }

    toggleSidebar = () => {
      this.setState({ showSidebar: !this.state.showSidebar })
    }

    changeEditStatus = (status) => {
      const { isEdited, isError } = status
      this.props.onChangeEditStatus(isEdited)
      if (this.state.shouldSave) {
        this.setState({ isError, isEdited, shouldSave: false, isSavingAsset: false, shouldShowWarning: false }, () => {
          if (!isError) {
            this.setSelectedAsset(this.tempSelectedId)
          }
        })
      } else {
        this.setState({ isError, isEdited })
      }
    }

    toggleWarningMessage = status => {
      this.setState({ shouldShowWarning: status })
    }

    onChangeSort = sortData => {
      if (!_.isEqual(sortData, this.state.sortData)) {
        this.setState({ sort: sortData })
      }
    }

    slugChanged = slug => {
      this.setState({ urlSlug: slug })
    }

    changeFilterValue = (filter) => {
      this.setState({ filter })
    }

    changedVodId = (id) => {
      if (this.state.selectedVodId !== id) {
        this.setState({ selectedVodId: id })
      }
    }

    handleFileUpload = (uploadingImage, isImageUpload) => {
      if (isImageUpload) {
        this.setState({ uploadingImage })
      } else {
        let files = { ...uploadingImage, timeStamp: new Date().getTime() }
        this.props.handleFileUpload(files)
      }
    }

    clearFileName = () => this.setState({ uploadingImage: [] })

    onPersonSearch = (value) => {
      if (this.personSearchTimeout) clearTimeout(this.personSearchTimeout)
      this.personSearchTimeout = setTimeout(() => {
        this.setState({
          personSearch: value
        })
      }, 300)
    }

    onRoleSearch = (value) => {
      if (this.roleSearchTimeout) clearTimeout(this.roleSearchTimeout)
      this.roleSearchTimeout = setTimeout(() => {
        this.setState({
          roleSearch: value
        })
      }, 300)
    }

    onProductionStudioSearch=(value) => {
      if (this.productionSearchTimeout) clearTimeout(this.productionSearchTimeout)
      this.productionSearchTimeout = setTimeout(() => {
        this.setState({ productionStudioSearchValue: value })
      }, 300)
    }

    onChangeIsImageUploading=(value) => {
      const { isImageUploading } = this.state
      let tempCount = isImageUploading
      if (value === 'ADD') {
        tempCount++
      } else {
        tempCount--
      }
      this.setState({ isImageUploading: tempCount })
    }

    render () {
      const { duplicatedAssetId, showSidebar, selectedAssetId, searchString, shouldShowWarning, shouldSave, isSavingAsset, deletedIds,
        isArchive, filter, sort, urlSlug, isError, shouldClearFilter, selectedVodId, shouldDiscard, uploadingImage, personSearch, roleSearch, productionStudioSearchValue, isImageUploading } = this.state
      const { listAssetTypes, project } = this.props
      const filterData = { searchString, filter: utilityService.getFormattedFilter(filter), sort }
      const assetTitle = sessionStorage.getItem('AssetName')
      return <Row className='asset-manager'>
        <Col className={showSidebar ? 'asset-list-wrapper' : ''} span={6}>
          <AssetList
            sort={sort}
            onChangeFilter={this.onChangeFilter}
            searchString={searchString}
            changedAsset={this.setSelectedAsset}
            selectedAssetId={selectedAssetId}
            deletedIds={deletedIds}
            onDeleteAsset={this.onDeleteAsset}
            duplicatedAssetId={duplicatedAssetId}
            archiveButtonHandle={this.archiveButtonHandle}
            changeFilterValue={this.changeFilterValue}
            onChangeSort={this.onChangeSort}
            filterVal={filter}
            shouldClearFilter={shouldClearFilter}
            listAssetTypes={listAssetTypes}
            uploadingImage={isImageUploading}
            project={project}
          />
        </Col>
        <Col xl={{ span: 18 }} xs={{ span: 18 + (showSidebar ? 6 : 0) }} className='asset-editor-container'>
          <AssetEditor
            assetId={selectedAssetId}
            shouldSave={shouldSave}
            deletedIds={deletedIds}
            appliedFilter={filterData}
            changeEditStatus={this.changeEditStatus}
            onDeleteAsset={this.onDeleteAsset}
            onDuplicateAsset={this.onDuplicateAsset}
            showSidebar={showSidebar}
            toggleSidebar={this.toggleSidebar}
            isArchive={isArchive}
            urlSlug={urlSlug}
            slugChanged={this.slugChanged}
            clearFilterDetails={this.clearFilterDetails}
            vodId={selectedVodId}
            changedVodId={this.changedVodId}
            shouldDiscard={shouldDiscard}
            listAssetTypes={listAssetTypes}
            handleFileUpload={this.handleFileUpload}
            clearFileName={this.clearFileName}
            files={uploadingImage}
            project={project}
            onPersonSearch={this.onPersonSearch}
            personSearch={personSearch}
            onRoleSearch={this.onRoleSearch}
            roleSearch={roleSearch}
            onProductionStudioSearch={this.onProductionStudioSearch}
            productionSearch={productionStudioSearchValue}
            onChangeIsImageUploading={this.onChangeIsImageUploading}
            appleClient={this.props.appleClient}
          />
        </Col>
        <ConfirmModal
          isVisible={shouldShowWarning}
          title={`Asset: ${assetTitle}`}
          message={assetSaveWarningMessage.messageText}
          isLeftPrior={false}
          leftButtonText={'Discard'}
          rightButtonText={'Save'}
          handleSubmit={this.forceSave}
          handleCancel={this.discardChanges}
          handleClose={this.onCloseConfirmModal}
          isSubmitButtonDisabled={isError}
          isCancelButtonDisabled={false}
          isLoading={isSavingAsset}
        />
      </Row>
    }
}

AssetManager.propTypes = {
  /* History parameter of dom */
  history: PropTypes.object,
  /* match parameter of dom */
  match: PropTypes.object,
  /** Function to be called on Change Edit Status */
  onChangeEditStatus: PropTypes.func,
  /** Boolean for showing save article warning */
  showWarning: PropTypes.bool,
  /** Function to be called after save or discard of an article */
  notifyParent: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryListAssetType,
    {
      options: ({ project }) => {
        const variables = { limit: 500, nextToken: null, filter: null, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes
        }
      }
    }
  )
)(withRouter(AssetManager)))
