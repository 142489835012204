import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import CheckIcon from '../general/icons/CheckIcon'

class PublishAccordionTitle extends Component {
  render () {
    const { title, isChecked, onClickExpand, onRemovePublishRule } = this.props
    return (
      <div className='publish-cell' onClick={onClickExpand}>
        <span> {title} </span>
        <CheckIcon color={isChecked ? '#52C41A' : '#CFD0D1'} onClick={onRemovePublishRule} />
      </div>
    )
  }
}

PublishAccordionTitle.propTypes = {}

export default PublishAccordionTitle
