import React, { Component } from 'react'
import { Menu, Item, contextMenu } from 'react-contexify'
import InfiniteScroll from 'react-infinite-scroller'
import { Skeleton, message, Empty } from 'antd'
import PropTypes from 'prop-types'
import 'react-contexify/dist/ReactContexify.css'

import AddButton from './../../components/ui/general/buttons/AddButton'
// import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
// import PopoverButton from './../../components/ui/general/buttons/PopoverButton'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import BucketListCell from './../../components/ui/dataDisplay/BucketListCell'
import AddBucketModal from './../../components/ui/feedback/AddBucketModal'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import PublishBucketModal from './../../components/ui/feedback/PublishBucketModal'
import { utilityService } from './../../services/UtilityService'
import AuthService from './../../services/AuthService'
import userMessages from './../../constants/messages'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryFilterBuckets from './../../graphQL/bucket/searchBuckets'
import SubscriptionCreateBuckets from './../../graphQL/bucket/createBucketSubscription'
import MutationCreateBucket from './../../graphQL/bucket/createBucket'
import MutationUpdateBucket from './../../graphQL/bucket/updateBucket'

const bucketTypes = [
  {
    type: 'competition',
    name: 'Competition Bucket'
  },
  {
    type: 'page',
    name: 'Page Bucket'
  }
]

const width = ['100%', '100%']

class BucketList extends Component {
  state = {
    loading: false,
    visible: false,
    shouldShowCreate: false,
    bucket: '',
    shouldShowWarning: false,
    isModalLoading: false,
    selectedId: '',
    isArchive: false,
    isSearching: false,
    isPaginating: false
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.getUserDetails()
    this.props.subscribeToNewBucket()
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && this.lastSearched !== { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
      this.setState({ bucketList: newProps.bucketList, isSearching: false, isPaginating: false, isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.bucketList, this.props.bucketList)) {
      this.setState({ bucketList: newProps.bucketList, isSearching: false, isPaginating: false, isLoading: false })
    } else if (newProps.isLoading) {
      this.setState({ isLoading: true })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  getUserDetails = async () => {
    const userDetails = await AuthService.getUserDetails()
    if (userDetails) {
      this.username = userDetails.name || userDetails.email
    }
  }

  bucketClickMenu = (id, isArchived, status) => (
    <Menu id={`${id}-bucket-list-menu-item`}>
      {isArchived ? '' : <Item onClick={() => this.showPublish(id)}>{status === 'PUBLISHED' ? 'Unpublish' : `Publish`}</Item>}
      <Item onClick={() => this.showEditModal(id)}>Edit</Item>
      {/* <Submenu label='Bucket Groups'>
        <Item>Home</Item>
        <Item>EPL</Item>
      </Submenu> */}
      <Item onClick={() => this.restoreArchiveBucket(id, !isArchived)}>{isArchived ? `Restore` : `Archive`}</Item>
    </Menu>
  )

  restoreArchiveBucket = (id, isArchived) => {
    this.setState({
      selectedId: id,
      shouldShowWarning: true,
      isArchive: isArchived
    })
  }

  showPublish = (id) => {
    this.setState({ showPublish: true, selectedId: id })
  }

  showEditModal = (id) => {
    this.setState({
      selectedId: id,
      visible: true
    })
  }

  cancelModal = () => {
    this.setState({
      selectedId: '',
      shouldShowWarning: false,
      visible: false,
      isArchive: false,
      showPublish: false
    })
  }

  showModal = (selectedItem) => {
    bucketTypes.map((item, index) => {
      if (item.name === selectedItem.name) {
        this.setState({
          bucket: selectedItem.name
        })
      }
    })
    this.setState({
      visible: true
    })
  }

  onArchiveSubmit = () => {
    if (this.state.isModalLoading) {
      return
    }
    this.setState({ isModalLoading: true })
    const { selectedId, isArchive } = this.state
    const variables = {
      isArchived: isArchive,
      id: selectedId
    }
    this.props.archiveBucket(variables).then(() => {
      this.setState({
        shouldShowWarning: false,
        isModalLoading: false,
        selectedId: '',
        isArchive: false })
    }, error => {
      utilityService.handleError(error)
      this.setState({ shouldShowWarning: false, isModalLoading: false })
    })
  }

  handleClickOutside = () => {
    if (this.state.shouldShowCreate) {
      setTimeout(() => this.setState({ shouldShowCreate: false }), 200)
    }
  }

  toggleCreateBucket = () => {
    this.setState({ shouldShowCreate: !this.state.shouldShowCreate })
  }

  createBucket = (request) => {
    this.setState({ loading: true })
    const { selectedId } = this.state
    if (selectedId) {
      request.id = selectedId
      request.updatedAt = new Date().toISOString()
      request.updatedBy = this.username
      this.props.updateBucket(request).then(() => {
        this.setState({
          selectedId: '',
          loading: false,
          visible: false
        })
      }, error => {
        utilityService.handleError(error)
      })
    } else {
      this.props.startCreateBucket()
      setTimeout(() => {
        this.props.createBucket(request).then(data => {
          message.success(userMessages.SUCCESS_BUCKET_CREATE)
          this.setState({
            loading: false,
            visible: false
          })
        }, (error) => {
          const { graphQLErrors } = error
          if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized') {
            message.warning('User does not have permission to edit')
          } else if (!!graphQLErrors.length && graphQLErrors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
            message.error('Bucket ID already in use')
          } else {
            message.warning(error.message.replace('Error: ', '').replace('GraphQL error: ', ''))
          }
          this.setState({
            loading: false,
            visible: false
          })
        })
      }, 500)
    }
  }

  loadMoreAsset = () => {
    if (this.props.totalCount === this.props.bucketList.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getBucketList(this.state.bucketList.length))
  }

  render () {
    const { visible, isPaginating, shouldShowWarning, isModalLoading, isArchive, selectedId, loading, showPublish } = this.state
    const { onBucketAdd, addedBucket, bucketList, isLoading, displayTypes, isCreateBlocked, isUpdateBlocked, project } = this.props
    const bucketDetails = selectedId ? bucketList.find((item) => item.id === selectedId) : undefined
    return (
      <React.Fragment>
        <div className='bucket-list-container'>
          {/* <Skeleton active title={false} paragraph={{ rows: 2, width: width }} loading={isLoading && !isSearching && !isPaginating} /> */}
          <Skeleton active title={false} paragraph={{ rows: 2, width: width }} loading={isLoading && !isPaginating}>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMoreAsset}
              hasMore={this.props.totalCount > bucketList.length}
              initialLoad={false}
              useWindow={false}
            >
              { bucketList && bucketList.length ? bucketList.map((item, index) => {
                const isEnabled = addedBucket.indexOf(item.id) === -1
                const status = utilityService.getPublishStatus(item.isPublished, item.publishStartDate, item.publishEndDate)
                return <div className='bucket-list' key={item.id}>
                  <div
                    onContextMenu={(e) => contextMenu.show({
                      id: `${item.id}-bucket-list-menu-item`,
                      event: e
                    })}
                  >
                    <BucketListCell
                      bucketName={item.name}
                      bucketType={item.displayType ? item.displayType.name : ''}
                      status={status}
                      shortId={item.key}
                      onBucketAdd={isEnabled ? () => onBucketAdd(item.id) : () => { }}
                      isEnabled={isEnabled && !isUpdateBlocked && !isCreateBlocked}
                      isArchived={item.isArchived}
                    />
                  </div>
                  {isUpdateBlocked ? '' : this.bucketClickMenu(item.id, item.isArchived, status)}
                </div>
              }
              ) : <Empty />}
            </InfiniteScroll>
          </Skeleton>
          <Skeleton active title={false} paragraph={{ rows: 2, width: width }} loading={isPaginating && this.props.totalCount > bucketList.length} />
        </div>
        <div className='bucket-list-footer'>
          {/* <PopoverButton
            button={<AddButton onClick={this.toggleCreateBucket} />}
            displayParam='name'
            contents={bucketTypes}
            onContentClick={this.showModal}
            parentCompoent={parentCompoent}
            isVisible={shouldShowCreate}
          /> */}
          <ButtonContainer displayTitle='Create Bucket' childComponent={<AddButton onClick={this.showModal} isDisabled={isCreateBlocked} />} />
          <AddBucketModal visible={visible}
            handleCancel={this.cancelModal}
            isEdit={!!selectedId}
            createBucket={this.createBucket}
            bucketDetails={bucketDetails}
            loading={loading}
            displayTypes={displayTypes}
          />
        </div>
        <ConfirmModal
          isVisible={shouldShowWarning}
          title={isArchive ? 'Archive Bucket' : 'Restore Bucket'}
          message={`Do you want to ${isArchive ? 'archive' : 'restore'} this bucket ?`}
          leftButtonText={'Cancel'}
          rightButtonText={'Confirm'}
          handleSubmit={this.onArchiveSubmit}
          handleCancel={this.cancelModal}
          isLoading={isModalLoading}
        />
        <PublishBucketModal visible={showPublish}
          handleCancel={this.cancelModal}
          bucketId={selectedId}
          project={project}
        />
      </React.Fragment>

    )
  }
}

BucketList.propTypes = {
  /** Function to be called on bucket add */
  onBucketAdd: PropTypes.func,
  /** Array of ids of added Bucket */
  addedBucket: PropTypes.arrayOf(PropTypes.string),
  /** List of bucket display type */
  displayTypes: PropTypes.array,
  /** Boolean to block create */
  isCreateBlocked: PropTypes.bool,
  /** Boolean to block update */
  isUpdateBlocked: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryFilterBuckets,
    {
      options: (props) => {
        const { searchString, filterVal, sort, project } = props
        const variables = utilityService.getFormattedBucketFilter(searchString, filterVal, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const bucketList = data.listBuckets && data.listBuckets.items && data.listBuckets.items.length ? data.listBuckets.items : []

        return {
          bucketList,
          isLoading: data.loading || !data.listBuckets,
          totalCount: data.listBuckets ? data.listBuckets.totalCount : 0,
          getBucketList: (page) => {
            return data.fetchMore({
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listBuckets.items, ...fetchMoreResult.listBuckets.items]
                prev.listBuckets.items = newList
                return prev
              }
            })
          },
          subscribeToNewBucket: () => {
            return props.data.subscribeToMore({
              document: SubscriptionCreateBuckets,
              updateQuery: (previous, { subscriptionData: { data: { bucketCreated } } }) => {
                const { project } = props.ownProps
                if (!bucketCreated || (project !== bucketCreated.project)) return previous
                const prev = cloneDeep(previous)
                const index = prev.listBuckets.items.findIndex(group => group.id === bucketCreated.id)
                if (index !== -1) {
                  prev.listBuckets.items.splice(index, 1)
                }
                const newList = [ bucketCreated, ...prev.listBuckets.items ]
                prev.listBuckets.items = newList
                return prev
              }
            })
          }
        }
      }

    }
  ),
  graphql(
    MutationCreateBucket,
    {
      options: (props) => ({
        update: (cache, { data: { createBucket } }) => {
          const { searchString, filterVal, sort, project } = props
          const variables = utilityService.getFormattedBucketFilter(searchString, filterVal, sort, project)
          // const variables = { limit: 50 }
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterBuckets, variables }))
          if (listCacheData && listCacheData.listBuckets && listCacheData.listBuckets.items) {
            const index = listCacheData.listBuckets.items.findIndex(group => group.id === createBucket.id)
            if (index !== -1) {
              listCacheData.listBuckets.items.splice(index, 1)
            }
            listCacheData.listBuckets.items.splice(0, 0, createBucket)
            listCacheData.listBuckets.totalCount++
          }
          cache.writeQuery({
            query: QueryFilterBuckets,
            data: listCacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        createBucket: (event) => {
          event.isPublished = false
          event.project = props.ownProps.project
          return props.mutate({
            variables: event
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateBucket,
    {
      options: (props) => ({
        update: (cache, { data: { updateBucket } }) => {
          const { searchString, filterVal, sort, project } = props
          const variables = utilityService.getFormattedBucketFilter(searchString, filterVal, sort, project)
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterBuckets, variables }))
          if (listCacheData && listCacheData.listBuckets && listCacheData.listBuckets.items) {
            const index = listCacheData.listBuckets.items.findIndex((item) => item.id === updateBucket.id)
            listCacheData.listBuckets.items[index] = updateBucket
          }
          cache.writeQuery({
            query: QueryFilterBuckets,
            data: listCacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        updateBucket: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateBucket,
    {
      options: (props) => ({
        update: (cache, { data: { updateBucket } }) => {
          const { searchString, filterVal, sort, project } = props
          const variables = utilityService.getFormattedBucketFilter(searchString, filterVal, sort, project)
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterBuckets, variables }))
          if (listCacheData && listCacheData.listBuckets && listCacheData.listBuckets.items) {
            const index = listCacheData.listBuckets.items.findIndex(item => item.id === updateBucket.id)
            if (index > -1) {
              listCacheData.listBuckets.items.splice(index, 1)
              listCacheData.listBuckets.totalCount--
            }
          }
          cache.writeQuery({
            query: QueryFilterBuckets,
            data: listCacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        archiveBucket: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(BucketList))
