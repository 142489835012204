import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateAuthorModal from '../../../components/ui/dataEntry/other/CreateAuthorModal'
import CropImageModal from '../../../components/ui/dataEntry/other/CropImageModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AddImageModal from '../../../components/ui/dataEntry/other/AddImageModal'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import { generateCroppedThumbnail, getCropImageUrl } from '../../../util/util'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAuthors from '../../../graphQL/asset/getAuthors'
import MutationDeleteAuthor from './../../../graphQL/admin/asset/deleteAuthor'
import MutationTransferAssets from './../../../graphQL/admin/asset/transferAssets'
import AssetTransferModal from '../../../components/ui/dataEntry/other/AssetTransferModal'

const defaultImage = require('../../../assets/images/avatar.jpg')
const options = [
  {
    type: 'edit',
    name: 'Edit'
  }, {
    type: 'transfer',
    name: 'Transfer'
  }
]

const deleteAuthorMessage = {
  title: 'DELETE AUTHOR',
  firstMessage: 'Are you sure you want to delete this author?',
  secondMessage: 'This action cannot be undone.'
}

const transferMessage = {
  title: 'TRANSFER AUTHOR',
  lastMessage: 'Are you sure you want to transfer to another Author ? This action cannot be undone.'
}

class AuthorList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedAuthor: null,
      isLoading: !props.authorList || !props.authorList.length,
      authorSearch: { searchString1: '', searchString2: '' }
    }
    this.columns = [
      {
        title: 'Author',
        dataIndex: 'id',
        key: 'name',
        width: '44%',
        render: this.renderAuthor
      }, {
        title: '# of Articles',
        dataIndex: 'assetCount',
        width: '28%',
        key: 'assetCount',
        render: (assetCount, { isError }) => <div>{isError ? 'Error in Transfer. Retry' : assetCount}</div>
      }, {
        title: 'Active',
        dataIndex: 'isDisabled',
        key: 'isDisabled',
        width: '25%',
        render: isDisabled => (
          <span>
            {isDisabled === true ? <CrossIcon color={'#f5212d'} /> : <DoneIcon /> }
          </span>
        )
      }, {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: this.renderData
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.authorList, this.props.authorList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.authorList, this.props.authorList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.authorList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = [ 'edit', 'transfer', 'delete' ]
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedAuthor) {
        this.setState({ openedAuthor: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedAuthor === id) {
        this.setState({ openedAuthor: null })
      } else {
        this.setState({ openedAuthor: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { authorList } = this.props
      const selectedAuthor = authorList.find(item => item.id === id)
      if (!selectedAuthor) {
        return
      }
      if (selectedOption && selectedOption.type === 'transfer') {
        this.setState({ showTransferAssetModal: true, selectedAuthor, fromSelectedAuthor: selectedAuthor, openedAuthor: null })
      } else if (selectedOption && selectedOption.type === 'edit') {
        this.setState({ showCreateAuthorModal: true, selectedAuthor, selectedImage: selectedAuthor.media, openedAuthor: null })
      } else if (selectedOption && selectedOption.type === 'delete') {
        this.setState({ showDeleteWarning: true, selectedAuthor, openedAuthor: null })
      }
    }

    showAddAuthor = () => {
      this.setState({ showCreateAuthorModal: true, selectedAuthor: null, selectedImage: null })
    }

    hideCreateAuthorModal = () => {
      this.setState({ showCreateAuthorModal: false, croppedUrl: null })
    }

    showCropModal = (images) => {
      let { selectedImage } = _.cloneDeep(this.state)
      if (images) {
        selectedImage = _.cloneDeep(images)
      }
      const aspectRatio = selectedImage[0].aspectRatio.find(item => item.title === '1:1')
      const settings = selectedImage[0].settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
      this.setState({ selectedImage, settings, aspectRatio }, () => this.setState({ showCropModal: true }))
    }

    hideCropModal = () => {
      this.setState({ showCropModal: false })
    }

    onCreateAuthor = () => {
      this.setState({ showCreateAuthorModal: false, selectedImage: null, croppedUrl: null })
    }

    clearSelectedImage = () => {
      this.setState({ selectedImage: null, croppedUrl: null })
    }

    showAddImageModal = () => {
      this.setState({ showAddImageModal: true })
    }

    hideAddImageModal = () => {
      this.setState({ showAddImageModal: false })
    }

    handleDeleteAuthorCancel = () => {
      this.setState({ showDeleteWarning: false, selectedAuthor: null })
    }

    hideTransferAssetModal = () => {
      this.setState({ showTransferAssetModal: false })
    }

    onSearchOptions = (value, isFirst) => {
      let { authorSearch } = this.state
      if (isFirst) {
        authorSearch.searchString1 = value
      } else {
        authorSearch.searchString2 = value
      }
      this.setState({ authorSearch })
    }

    onAuthorSelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedAuthor: selectedValue })
      } else {
        this.setState({ fromSelectedAuthor: selectedValue })
      }
    }

    handleTransferAssets = () => {
      const { fromSelectedAuthor, toSelectedAuthor } = this.state
      const { onHistoryUpdate } = this.props
      const request = {
        type: 'AUTHOR',
        input: {
          oldValue: fromSelectedAuthor.id,
          newValue: toSelectedAuthor.id
        }
      }
      this.props.transferCategoryAssets(request).then(() => {
        onHistoryUpdate()
        this.setState({ showTransferAssetModal: false, fromSelectedAuthor: null, toSelectedAuthor: null })
      }, error => {
        this.setState({ showTransferAssetModal: false, fromSelectedAuthor: null, toSelectedAuthor: null })
        utilityService.handleError(error)
      })
    }

    handleDeleteAuthorSubmit = () => {
      const { selectedAuthor } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedAuthor) {
        return
      }
      this.props.deleteAuthor(selectedAuthor.id).then(() => {
        onHistoryUpdate()
        this.setState({ showDeleteWarning: false, selectedAuthor: null })
      }, error => {
        this.setState({ showDeleteWarning: false, selectedAuthor: null })
        utilityService.handleError(error)
      })
    }

    onChangeFilter = value => {
      if (value.length > 0) {
        this.setState({ isLoading: true, isSearching: true })
        this.props.onChangeAuthorFilter({ searchString: value })
      } else {
        this.setState({ isLoading: false, isSearching: false })
        this.props.onChangeAuthorFilter({ searchString: value })
      }
    }

    saveCurrentSelection = (selectedImage) => {
      this.setState({ selectedImage, showAddImageModal: false, showCreateAuthorModal: true })
    }

    saveCurrentCrop = (settings) => {
      const image = _.cloneDeep(this.state.selectedImage[0])
      const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
      const currentaspectId = currentAspectRatio.aspectRatio
      const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
      // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
      image.settings.map(setting => {
        setting.outputFormat = 'JPG'
        delete setting[ '__typename' ]
      })
      image.settings[changedIndex].x1 = settings.x1
      image.settings[changedIndex].x2 = settings.x2
      image.settings[changedIndex].y1 = settings.y1
      image.settings[changedIndex].y2 = settings.y2
      // this.props.updateImage(image.id, image.name, tags, image.settings)
      const croppedUrl = getCropImageUrl(image, settings)
      this.setState({ showCropModal: false, showCreateAuthorModal: true, croppedUrl, selectedImage: [image] })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.authorList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreAuthors(this.props.authorList.length))
    }

    renderAuthor = id => {
      const { authorList } = this.props
      const selectedAuthor = (authorList || []).find(author => author.id === id)
      if (selectedAuthor) {
        return (
          <div className='author-list-name'>
            <img src={selectedAuthor.media && selectedAuthor.media.length && selectedAuthor.media[ 0 ] ? generateCroppedThumbnail(selectedAuthor.media[0], 200, 200, '1:1') : defaultImage} />
            <p>{ selectedAuthor.name }</p>
          </div>
        )
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedAuthor } = this.state
      const { authorList } = this.props
      const selectedAuthor = authorList.find(item => item.id === id)
      if (selectedAuthor && !selectedAuthor.assetCount && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (selectedAuthor && (!selectedAuthor.assetCount || !parseInt(selectedAuthor.assetCount))) {
        availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 2)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={openedAuthor ? availableOptions : []}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedAuthor === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { authorList, authorFilter, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showCreateAuthorModal, fromSelectedAuthor, toSelectedAuthor, showTransferAssetModal, croppedUrl, selectedImage, showAddImageModal, settings, aspectRatio, showCropModal, selectedAuthor, showDeleteWarning, authorSearch } = this.state
      let adminItem
      if (selectedAuthor && showDeleteWarning) {
        adminItem = <div className='author-list-name'>
          <img src={selectedAuthor.media && selectedAuthor.media.length && selectedAuthor.media[ 0 ] ? generateCroppedThumbnail(selectedAuthor.media[0], 200, 200, '1:1') : defaultImage} />
          <p>{ selectedAuthor.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const userPermissionsContent = permissions['CONTENT_BANK']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
            const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
            return <div className='admin-portal'>
              <FilterInput
                searchString={authorFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
                project={project}
              />
              <div className='admin-list author-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > authorList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={authorList} pagination={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddAuthor}
                  htmlType='submit'
                  buttonText={'Add Author'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CropImageModal
                isVisible={showCropModal}
                aspectRatio={aspectRatio}
                imgUrl={settings && settings.length ? settings[0].fileName : null}
                settings={settings} updateSettings={this.saveCurrentCrop}
                handleCancel={this.hideCropModal}
                isUpdateBlocked={isUpdateDisabledContent} />
              <AddImageModal
                isMultiSelect={false}
                isVisible={showAddImageModal}
                handleSubmit={this.saveCurrentSelection}
                handleCancel={this.hideAddImageModal}
                isSubmitButtonDisabled={isCreateDisabledContent}
                isUpdateBlocked={isUpdateDisabledContent}
                mediaType='IMAGE'
                project={project} />
              <CreateAuthorModal
                selectedAuthor={showCreateAuthorModal ? selectedAuthor : {}}
                clearSelection={this.clearSelectedImage}
                isVisible={showCreateAuthorModal}
                croppedUrl={croppedUrl}
                showCropModal={this.showCropModal}
                selectedImage={selectedImage}
                showImageModal={this.showAddImageModal}
                handleCancel={this.hideCreateAuthorModal}
                handleSubmit={this.onCreateAuthor}
                module='DATA_MANAGER'
                isSubmitDisabled={this.isUpdateDisabled}
                isUpdateBlocked={isUpdateDisabledContent}
                onHistoryUpdate={onHistoryUpdate}
                project={project} />
              <AdminItemConfirmModal
                isVisible={showDeleteWarning}
                title={deleteAuthorMessage.title}
                firstMessage={deleteAuthorMessage.firstMessage}
                secondMessage={deleteAuthorMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.handleDeleteAuthorCancel}
                handleSubmit={this.handleDeleteAuthorSubmit}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
              />
              <AssetTransferModal
                type='author'
                placeholder={'Select Author'}
                authorSearch={authorSearch}
                isVisible={showTransferAssetModal}
                handleCancel={this.hideTransferAssetModal}
                onSubmitTransfer={this.handleTransferAssets}
                title={transferMessage.title}
                onOptionSelect={this.onAuthorSelect}
                selectedToValue={toSelectedAuthor}
                isImage
                selectedFromValue={fromSelectedAuthor}
                lastMessage={transferMessage.lastMessage}
                onSearchOptions={this.onSearchOptions}
                project={project}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

AuthorList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryAuthors,
    {
      options: ({ authorFilter, project }) => {
        const { searchString } = authorFilter
        const filter = searchString ? { name: { match: searchString } } : null
        const variables = { filter, offset: 0, limit: 30, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let authorList = data.listAuthors ? data.listAuthors.items : []
        authorList = authorList.map(item => {
          if (item.isTransferring) {
            item.assetCount = 'Transferring'
          }
          return item
        })
        return {
          authorList,
          isLoading: data.loading || !data.listAuthors,
          totalCount: data.listAuthors ? data.listAuthors.totalCount : 0,
          getMoreAuthors: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAuthors.items, ...fetchMoreResult.listAuthors.items]
                prev.listAuthors.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationTransferAssets, {
      options: ({ authorFilter, project }) => ({
        update: (cache, { data: { transferAssets } }) => {
          const { searchString } = authorFilter
          const filter = searchString ? { name: { match: searchString } } : null
          const variables = { filter, offset: 0, limit: 30, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAuthors, variables }))
          if (cacheData && cacheData.listAuthors && cacheData.listAuthors.items) {
            cacheData.listAuthors.items = cacheData.listAuthors.items.map(item => {
              if (transferAssets.includes(item.id)) {
                item.assetCount = 'Transferring'
              }
              return item
            })
          }
          cache.writeQuery({
            query: QueryAuthors,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        transferCategoryAssets: (request) => {
          return props.mutate({
            variables: { ...request, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteAuthor, {
      options: ({ authorFilter, project }) => ({
        update: (cache, { data: { deleteAuthor } }) => {
          const { searchString } = authorFilter
          const filter = searchString ? { name: { match: searchString } } : null
          const variables = { filter, limit: 30, offset: 0, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAuthors, variables }))
          if (cacheData && cacheData.listAuthors && cacheData.listAuthors.items) {
            const selectedAuthorIndex = cacheData.listAuthors.items.findIndex(item => item.id === deleteAuthor.id)
            if (selectedAuthorIndex > -1) {
              cacheData.listAuthors.items.splice(selectedAuthorIndex, 1)
            }
          }
          cache.writeQuery({
            query: QueryAuthors,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteAuthor: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  )

)(AuthorList))
