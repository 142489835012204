import gql from 'graphql-tag'

export default gql(`
    mutation($programs: [ID]!  $assetId: ID! ) {
        linkAssetProgram(input:{
            assetId: $assetId
            programs: $programs
          }) {
                id
                source
                isLiveTelecast
                channel{
                    id
                    name
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                    masterConfig{
                        id
                    }
                    configGroups{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    activeConfigGroup{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
                broadCastStartTime
                broadCastEndTime
            }
    }`
)
