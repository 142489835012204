import gql from 'graphql-tag'

export default gql(`
    query getDrmToken($url: String $contentKey: String $streamType: String $iv: String $drmEntitlementMessage: String $validity: Int $source: String $channel: String $configGroup: String
        ) {
            getDrmToken(
            input: {
                url: $url
                contentKey: $contentKey
                streamType: $streamType
                iv: $iv
                drmEntitlementMessage: $drmEntitlementMessage
                validity: $validity
                source: $source
                channel: $channel
                configGroup: $configGroup
            }
        ) {
            token
            validTill
        }
               
    }`
)
