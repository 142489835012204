import gql from 'graphql-tag'

export default gql(`
    mutation adminCancelSubscription($email: String!, $id: ID! $userId: String $project: String) {
        adminCancelSubscription(
            email: $email
            id: $id
            project: $project
            userId: $userId
        ) {
            success{
                message
            }
            error{
                message
            }
            data {
                activatedAt
                createdAt
                customerId
                endDate
                id
                planId
                price
                renewalDate
                status
                planData {
                  description
                  id
                  interval
                  name
                  price
                  provider {
                    id
                    name
                  }
                  providerId
                }
            }
        }
    }`
)
