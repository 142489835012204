import * as React from 'react'
import LoadingButton from '../../general/buttons/LoadingButton'
import InputNumber from '../inputs/InputNumber'
import CrossIcon from '../../general/icons/CrossIcon'
import _ from 'lodash'
import { utilityService } from '../../../../services/UtilityService'
import { defaultAspectRatioList } from '../../../../util/util'
import getUuid from 'uuid-by-string'
import { message } from 'antd'

class AspectRatioSettings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      aspectRatios: props.aspectRatios ? props.aspectRatios : [],
      selectedAspectRatioId: props.aspectRatios ? props.aspectRatios[0].aspectRatio : null
    }
    this.isDuplicateAspectRatio = false
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.aspectRatios, this.props.aspectRatios) && !_.isEqual(nextProps.aspectRatios, this.state.aspectRatios)) {
      const { selectedAspectRatioId } = this.state
      this.setState({ aspectRatios: nextProps.aspectRatios ? nextProps.aspectRatios : [],
        selectedAspectRatioId: selectedAspectRatioId || (nextProps.aspectRatios && nextProps.aspectRatios.length ? nextProps.aspectRatios[0].aspectRatio : null)
      })
    }
  }

    addAspectRatio = () => {
      let { aspectRatios } = this.state
      const aspectRatio = getUuid(utilityService.makeRandomString(5))
      const newAspectRatio = {
        aspectRatio,
        resolutions: []
      }
      aspectRatios.unshift(newAspectRatio)
      this.setState({ aspectRatios, selectedAspectRatioId: aspectRatio })
    }

    addResolution = () => {
      const { aspectRatios, selectedAspectRatioId } = this.state
      const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
      const index = aspectRatios.findIndex(item => item.aspectRatio === selectedAspectRatioId)
      const newResolution = {
        id: getUuid(utilityService.makeRandomString(5)),
        width: '',
        height: ''
      }
      selectedAspectRatio.resolutions.unshift(newResolution)
      aspectRatios[index] = selectedAspectRatio
      this.setState({ aspectRatios })
    }

    handleWidthChange = (aspectRatio, value) => {
      let { aspectRatios } = this.state
      const index = (aspectRatios || []).findIndex(aspect => aspect.aspectRatio === aspectRatio)
      aspectRatios[index].title = `${value}:${aspectRatios[index].title ? aspectRatios[index].title.split(':')[1] : ''}`
      this.setState({ aspectRatios })
      this.props.onChangeAspectRatio(aspectRatios)
    }

    handleHeightChange = (aspectRatio, value) => {
      let { aspectRatios } = this.state
      const index = (aspectRatios || []).findIndex(aspect => aspect.aspectRatio === aspectRatio)
      aspectRatios[index].title = `${aspectRatios[index].title ? aspectRatios[index].title.split(':')[0] : ''}:${value}`
      this.setState({ aspectRatios })
      this.props.onChangeAspectRatio(aspectRatios)
    }

    handlePixelWidthChange = (id, value, title) => {
      const { aspectRatios, selectedAspectRatioId } = this.state
      const tempHeight = title.split(':')[1]
      const tempWidth = title.split(':')[0]
      const ratio = tempHeight / tempWidth
      const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
      const selectedAspectRatioIndex = aspectRatios.findIndex(item => item.aspectRatio === selectedAspectRatioId)
      const index = (selectedAspectRatio.resolutions || []).findIndex(resolution => resolution.id === id)
      selectedAspectRatio.resolutions[index].width = value
      selectedAspectRatio.resolutions[index].height = Math.round(value * ratio)
      aspectRatios[selectedAspectRatioIndex] = selectedAspectRatio
      this.setState({ aspectRatios })
      this.props.onChangeAspectRatio(aspectRatios)
    }

    handlePixelHeightChange = (id, value, title) => {
      const { aspectRatios, selectedAspectRatioId } = this.state
      const tempHeight = title.split(':')[1]
      const tempWidth = title.split(':')[0]
      const ratio = tempWidth / tempHeight
      const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
      const selectedAspectRatioIndex = aspectRatios.findIndex(item => item.aspectRatio === selectedAspectRatioId)
      const index = (selectedAspectRatio.resolutions || []).findIndex(resolution => resolution.id === id)
      selectedAspectRatio.resolutions[index].height = value
      selectedAspectRatio.resolutions[index].width = Math.round(value * ratio)
      aspectRatios[selectedAspectRatioIndex] = selectedAspectRatio
      this.setState({ aspectRatios })
      this.props.onChangeAspectRatio(aspectRatios)
    }

    onCloseResolution = (id, event) => {
      const { aspectRatios, selectedAspectRatioId } = this.state
      const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
      const selectedAspectRatioIndex = aspectRatios.findIndex(item => item.aspectRatio === selectedAspectRatioId)
      const index = (selectedAspectRatio.resolutions || []).findIndex(resolution => resolution.id === id)
      selectedAspectRatio.resolutions.splice(index, 1)
      aspectRatios[selectedAspectRatioIndex] = selectedAspectRatio
      this.setState({ aspectRatios })
      this.props.onChangeAspectRatio(aspectRatios)
    }

    onCloseAspectRatio = (aspectRatio, event) => {
      let { aspectRatios, selectedAspectRatioId } = this.state
      const index = (aspectRatios || []).findIndex(aspect => aspect.aspectRatio === aspectRatio)
      aspectRatios.splice(index, 1)
      const selected = selectedAspectRatioId === aspectRatio ? aspectRatios && aspectRatios.length ? aspectRatios[0].aspectRatio : null : selectedAspectRatioId
      this.setState({ aspectRatios, selectedAspectRatioId: selected })
      this.props.onChangeAspectRatio(aspectRatios)
      event.stopPropagation()
    }

    // onCropPositionCHange = (aspectRatio, value) => {
    //   const { aspectRatios, selectedAspectRatioId } = this.state
    //   const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
    //   const selectedAspectRatioIndex = aspectRatios.findIndex(item => item.aspectRatio === selectedAspectRatioId)
    //   const index = (selectedAspectRatio.resolutions || []).findIndex(resolution => resolution.aspectRatio === aspectRatio)
    //   selectedAspectRatio.resolutions[index].cropPosition = value
    //   aspectRatios[selectedAspectRatioIndex] = selectedAspectRatio
    //   this.setState({ aspectRatios })
    // }

    selectAspectRatio = (selectedAspectRatioId) => {
      this.setState({ selectedAspectRatioId })
    }

    checkForDuplicateAspectRatio = (selected, aspectRatios) => {
      const duplicateArr = aspectRatios.map(aspectRatio => {
        const widthHeightArr = (aspectRatio.title || '').split(':').filter(item => item)
        let isDuplicateFlag = false
        if (widthHeightArr.length === 2) {
          const ratio = parseInt(widthHeightArr[0]) / parseInt(widthHeightArr[1])
          const ratioSelected = parseInt(selected[0]) / parseInt(selected[1])
          if (ratio === ratioSelected) { isDuplicateFlag = true }
        }
        return isDuplicateFlag
      })
      this.isDuplicateAspectRatio = (duplicateArr).filter(item => item).length === 2
      if (this.isDuplicateAspectRatio) { message.warn('Duplicate Aspect Ratio') }
    }

    renderAspectRatio () {
      const { aspectRatios, selectedAspectRatioId } = this.state
      const { isPrimary } = this.props

      return (aspectRatios || []).map(aspect => {
        const title = aspect.title ? aspect.title.split(':') : null
        const tempWidth = title ? title[0] : ''
        const tempHeight = title ? title[1] : ''
        const isEditDisabled = isPrimary && (aspect.title === '1:1' || aspect.title === '16:9' || aspect.title === '9:16') && (aspect.resolutions && aspect.resolutions.length)
        const isAlreadyAdded = (this.props.selectedType || []).find(item => item.aspectRatio === aspect.aspectRatio)
        const currentResolution = aspect.resolutions && aspect.resolutions.length
        // const currentResolution = (aspect.resolutions || []).filter(item => item.width && item.height)

        return <div className={`aspect-ratio-item${selectedAspectRatioId === aspect.aspectRatio ? ' selected' : ''}`} key={aspect.aspectRatio} onClick={() => this.selectAspectRatio(aspect.aspectRatio)}>
          <InputNumber
            min={1}
            placeholder='Width'
            title='Width'
            value={tempWidth}
            readOnly={isEditDisabled || !_.isEmpty(isAlreadyAdded) || !!currentResolution}
            handleChange={(value) => this.handleWidthChange(aspect.aspectRatio, value)} />
          <span>:</span>
          <InputNumber
            min={1}
            placeholder='Height'
            title='Height'
            value={tempHeight}
            readOnly={isEditDisabled || !_.isEmpty(isAlreadyAdded) || !!currentResolution}
            handleChange={(value) => this.handleHeightChange(aspect.aspectRatio, value)} />
          { isEditDisabled ? <div className='dummy' /> : <CrossIcon onClick={(event) => this.onCloseAspectRatio(aspect.aspectRatio, event)} />}
        </div>
      })
    }

    renderResolution () {
      const { aspectRatios, selectedAspectRatioId } = this.state
      const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
      const { isPrimary } = this.props

      return ((selectedAspectRatio && selectedAspectRatio.resolutions) || []).map(resolution => {
        let isDisabled = false
        if (isPrimary) {
          const defaultResolutions = defaultAspectRatioList.find(item => item.title === selectedAspectRatio.title)
          const defaultAspectRatio = defaultResolutions ? (defaultResolutions.resolutions || []).find(item => item.key === resolution.key) : null
          if (!_.isEmpty(defaultAspectRatio)) {
            isDisabled = true
          }
        }
        return <div className={`resolution-item`} key={resolution.id}>
          <InputNumber
            min={1}
            placeholder='Width'
            title='Width'
            value={resolution.width}
            readOnly={isDisabled}
            handleChange={(value) => this.handlePixelWidthChange(resolution.id, value, selectedAspectRatio.title)} />
          <InputNumber
            min={1}
            placeholder='Height'
            title='Height'
            value={resolution.height}
            readOnly={isDisabled}
            handleChange={(value) => this.handlePixelHeightChange(resolution.id, value, selectedAspectRatio.title)} />
          {isDisabled ? <div className='dummy' /> : <CrossIcon onClick={() => this.onCloseResolution(resolution.id)} />}
        </div>
      })
    }

    render () {
      const { selectedAspectRatioId, aspectRatios } = this.state
      const selectAspectRatio = aspectRatios.find(item => item.aspectRatio === selectedAspectRatioId)
      let title = []
      if (!_.isEmpty(selectAspectRatio)) {
        if (selectAspectRatio.title) {
          title = (selectAspectRatio.title || '').split(':').filter(item => item)
          if (title.length) { this.checkForDuplicateAspectRatio(title, aspectRatios) }
        }
      }

      return <div className='aspect-ratio-settings-container'>
        <div className='ratio-outer-container'>
          <div className='ratio-container'>
            <div className='ratio-header'><span>{'Ratio (W) : Ratio (H)'}</span></div>
            <div className='ratio-body'>
              {this.renderAspectRatio()}
            </div>
          </div>
          <LoadingButton
            type='primary'
            onClick={this.addAspectRatio}
            htmlType='submit'
            buttonText={'Add Aspect Ratio'}
            buttonClass='save-btn'
            isLoading={false}
            isDisabled={false} />
        </div>
        <div className='resolution-outer-container'>
          <div className='resolution-container'>
            <div className='ratio-header'>
              <span>{'Pixel Width'}</span>
              <span>{'Pixel Height'}</span>
              <div />
            </div>
            <div className='resolution-body'>
              {(selectedAspectRatioId !== null && title.length === 2) ? this.renderResolution() : ''}
            </div>
          </div>
          {(selectedAspectRatioId !== null && title.length === 2 && !this.isDuplicateAspectRatio) ? <LoadingButton
            type='primary'
            onClick={this.addResolution}
            htmlType='submit'
            buttonText={'Add Dimension'}
            buttonClass='save-btn'
            isLoading={false}
            isDisabled={false} /> : ''}
        </div>
      </div>
    }
}

export default AspectRatioSettings
