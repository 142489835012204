import React from 'react'
import PropTypes from 'prop-types'

const EditPageIcon = ({ height, width, color, ...props }) => (
  <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
    <g transform='translate(.039)' fill='none' fillRule='evenodd'>
      <rect fill='#343A40' width='36.961' height='37' rx='2' />
      <path d='M8.99 9h19.98v20H8.99z' />
      <path d='M27.73 13.878a10.553 10.553 0 0 0-1.587-2.026 10.554 10.554 0 0 0-2.06-1.595L25.336 9s1.816 0 2.725.91c.908.908.908 2.726.908 2.726l-1.24 1.242zM17.164 24.455H13.53v-3.637l.436-.436a7.624 7.624 0 0 1 3.633 3.636l-.436.437zm9.517-9.528-8.033 8.041A10.492 10.492 0 0 0 15 19.348l8.049-8.057a7.586 7.586 0 0 1 3.632 3.636zm-14.966-3.2v14.546h14.53v-6.364l2.725-2.762v10.035A1.817 1.817 0 0 1 27.153 29H10.807a1.817 1.817 0 0 1-1.816-1.818V10.818C8.99 9.815 9.804 9 10.807 9h9.999l-2.726 2.727h-6.365z' fill='#FFF' fillRule='nonzero' />
    </g>
  </svg>
)

EditPageIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

EditPageIcon.defaultProps = {
  width: 37,
  height: 37,
  color: '#FFF'
}

export default EditPageIcon
