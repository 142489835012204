import gql from 'graphql-tag'

export default gql(`
    mutation deleteImageTypes($id: [ID]! $project: String) {
        deleteImageTypes(
            input: $id
            project: $project
        )
    }`
)
