import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'
import { Icon, message } from 'antd'
import ToolTip from '../../components/ui/dataDisplay/ToolTip'
//

import Input from './../../components/ui/dataEntry/inputs/Input'
import InputNumber from './../../components/ui/dataEntry/inputs/InputNumber'
import TextAreaInput from './../../components/ui/dataEntry/inputs/TextArea'
import { utilityService } from '../../services/UtilityService'

// const parentCompoent = 'drm-container'
function DrmGeneralDetails ({ state, token, onChangeDuration, isUpdateDisabled }) {
  const copyIcon = (<Icon type='copy' onClick={() => {
    if (token) {
      utilityService.copyToClipBoard(token, '')
    } else {
      message.warn('Please generate Token before copying')
    }
  }
  } />)
  return (
    <div className='general-details'>
      <div className='details-left'>
        {state && state.url ? <ToolTip
          childComponent={<Input
            title='URL'
            placeholder=''
            inputName='url'
            value={(state && state.url) || null}
            handleChange={() => {}}
            readOnly
          />}
          description={(state && state.url) || null}
          placement='right'
          isList
        /> : <Input
          title='URL'
          placeholder=''
          inputName='url'
          value={(state && state.url) || null}
          handleChange={() => {}}
          readOnly
        />}
        {state && state.contentKey && (state.contentKey.length > 50) ? <ToolTip
          childComponent={<Input
            title='Content Key'
            placeholder=''
            inputName='key'
            value={(state && state.contentKey) || null}
            handleChange={() => {}}
            readOnly
          />}
          description={(state && state.contentKey) || null}
          placement='right'
          isList
        /> : <Input
          title='Content Key'
          placeholder=''
          inputName='key'
          value={(state && state.contentKey) || null}
          handleChange={() => {}}
          readOnly
        />}
        {state && state.iV && (state.iV.length > 50) ? <ToolTip
          childComponent={<Input
            title='IV'
            placeholder=''
            inputName='iv'
            value={(state && state.iV) || null}
            handleChange={() => {}}
            readOnly
          />}
          description={(state && state.iV) || null}
          placement='right'
          isList
        /> : <Input
          title='IV'
          placeholder=''
          inputName='iv'
          value={(state && state.iV) || null}
          handleChange={() => {}}
          readOnly
        />}
        <div className='readOnly'>
          <TextAreaInput
            title='DRM Entitlement Message'
            placeholder=''
            inputName='drmEntitlementMessage'
            isEditable={false}
            value={(state && state.drmEntitlementMessage) || null}
          />
        </div>

        <div className='input-field-number'>
          <InputNumber
            limit={16}
            title={'Duration in hours'}
            placeholder={`Enter Duration`}
            inputName='duration'
            isRequired={false}
            value={(state && state.duration) || null}
            handleChange={onChangeDuration}
            errorMessage={''}
            readOnly={isUpdateDisabled}
          />
        </div>

      </div>
      <div className='details-right'>
        <TextAreaInput
          title='Token'
          placeholder=''
          inputName='drmToken'
          value={token}
          icon={copyIcon}
          isEditable={false}
        />
      </div>

    </div>
  )
}

DrmGeneralDetails.propTypes = {
  /** details of ChannelDetails. */
  channelState: PropTypes.object.isRequired
}

export default withApollo(compose(

)(DrmGeneralDetails))
