import gql from 'graphql-tag'

export default gql(`
query listImageTypes($project: String){
    listImageTypes(project: $project){
        id
        name
        isDefault
        position
        isActive
        usageCount
        enabledAssetTypes{
          cropPosition
          assetType
          minCount
        }
        aspectRatios{
          aspectRatio
          name
          ratio
          title
          resolutions{
            id
            width
            height
            key
          }
        }
        updatedAt
        createdAt
      }
}`)
