import React from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
import { Modal, Tabs, Button, Skeleton } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import AppContext from '../../../../AppContext'

import SeasonOriginalData from '../../dataDisplay/SeasonOriginalData'
import SeasonCustomData from '../../dataDisplay/SeasonCustomData'
import LoadingButton from '../../general/buttons/LoadingButton'
import './../../ui.style.scss'

import { utilityService } from '../../../../services/UtilityService'
import MutationUpdateSeason from '../../../../graphQL/admin/updateSeason'

const { TabPane } = Tabs

class EditSeasonModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      customDetails: {},
      isLoading: false
    }
  }

  resetState = () => {
    const state = {
      customDetails: {}
    }
    this.setState({ ...state })
  }

  onEditSeason = (details) => {
    this.setState({ customDetails: details })
  }

  onSeasonChange = () => {
    this.setState({ isLoading: true })
    const { customDetails } = this.state
    const { onHistoryUpdate } = this.props
    const newDetails = {
      id: customDetails.id,
      customTitle: customDetails.customTitle,
      customStartDate: customDetails.customStartDate,
      customEndDate: customDetails.customEndDate,
      customActive: customDetails.customActive,
      tags: customDetails.tags && customDetails.tags.length ? customDetails.tags.map(item => item.key) : []
    }
    this.props.updateSeason(newDetails).then(response => {
      onHistoryUpdate()
      this.props.handleCancel()
      this.setState({ isLoading: false })
    }, error => {
      this.props.handleCancel()
      utilityService.handleError(error)
      this.setState({ isLoading: false })
    })
  }

  render () {
    const { visible, handleCancel, title, okText, selectedSeason, seasonIsLoading } = this.props
    const { customDetails, isLoading } = this.state
    return (

      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <Modal
            className='general-modal edit-season-modal'
            visible={visible}
            closable={false}
            title={title}
            okText={okText}
            cancelText='Back'
            afterClose={this.resetState}
            width='650px'
            centered
            destroyOnClose
            maskClosable={false}
            footer={<div className='modal-footer'>
              <p>
                {/* Last Updated: {moment(new Date(selectedSeason.lastUpdated)).format('DD MMM, YYYY')} */}
                {!_.isEmpty(customDetails) ? 'The changes made here will reflect in APIs and other modules of VCMS only after a re-sync of the respective competition is triggered from Opta Sync Settings page.'
                  : ''}</p>
              <div className='right-btn'>
                <Button key='back' onClick={handleCancel}> Back </Button>
                <LoadingButton type='primary' onClick={this.onSeasonChange} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isLoading} isDisabled={_.isEmpty(customDetails) || isUpdateDisabled} />
              </div>
            </div>}
          >
            <div className='tab-details'>
              <Tabs>
                <TabPane tab='Original Data' key='1'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={seasonIsLoading}>
                    <SeasonOriginalData selectedSeason={selectedSeason} />
                  </Skeleton>
                </TabPane>

                <TabPane tab='Custom Data' key='2'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={seasonIsLoading}>
                    <SeasonCustomData selectedSeason={selectedSeason} onEditSeason={this.onEditSeason} project={project} />
                  </Skeleton>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        }}
      </AppContext.Consumer>
    )
  }
}

EditSeasonModal.propTypes = {
  /** visible action of EditSeasonModal. */
  visible: PropTypes.bool,
  /** handleCancel action of EditSeasonModal. */
  handleCancel: PropTypes.func
}

EditSeasonModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationUpdateSeason,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateSeason: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(EditSeasonModal))
