import gql from 'graphql-tag'

export default gql(`
    mutation updateGenre($id: ID!, $name: String!, $project: String ) {
        updateGenre(
            input: {
                name: $name
            },
            id: $id,
            project: $project
        ) {
            id
            name
            assetCount
        }
    }`
)
