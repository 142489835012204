import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getVodStatus(
        jobId: $id
        ){
            id
            channel{
                id
                name
            }
            name
            asset{
                id
                title
                match{
                    id
                    matchDate
                    opId
                    homeTeam {
                        id
                        opId
                        code
                        officialName  
                        media {
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2          
                            }
                        }
                    }
                    awayTeam {
                        id
                        opId
                        code
                        officialName   
                        media {
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2          
                            }
                        }     
                    }
                }
            }
            createdAt
            vwmJobId
            status
            updatedAt
            errorDesc
            startTime
            endTime
            site
            type
            validations{
                error
                status
                streamStatus
            }
            mediaId{
                id
                fileName
                createdAt
                updatedAt
                type
                name
                fileSize
                isArchived
                duration
                thumbnails
                cuePoints
                adPositions
                breakDuration
                cuePointsError
                tags{
                    name
                    key
                    type
                }
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
            }
            jobType
            progress
            createdBy
            relatedJob{
                id
                vwmJobId
            }
            lastReceivedStatus
            parentJob
            jobStates{
                status
                errorCode
                updatedAt
            }
    }
}`)
