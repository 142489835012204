import gql from 'graphql-tag'

export default gql(`
  query ( $limit: Int $filter: ListCompetitionFilterInput $project: String){
    listCompetitions(
      limit: $limit
      filter: $filter
      project: $project
      ) {
        items{
          title
          id
          opId
          competitionCode
          type
          lastUpdated
          tournamentCalendar{
            id
            externalId
            title
            startDate
            endDate
            active
            lastUpdated
            customTitle
            customStartDate 
            customEndDate
            customActive
            tags{
              name
              key
            }
          }
          customTitle
          customCompetitionCode
          customCountry
          customCompetitionFormat
          customType
          customCompetitionType
          customSource
          tags{
            name
            key
          }
          logo {
            id
            url
            fileName
            aspectRatio {
              title
              aspectRatio
              resolutions {
                url
                id
                key
              }
              name
            }
            settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2
            }
            updatedAt
            type
            thumbnails
            status
            source
            module
            name
            mediaId
            isArchived
            fileSize
            drmContent
            duration
            createdAt
            createdBy
            adPositions
          }

          defaultTeamImage {
            id
            url
            fileName
            aspectRatio {
              title
              aspectRatio
              resolutions {
                url
                id
                key
              }
              name
            }
            settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2
            }
            updatedAt
            type
            thumbnails
            status
            source
            module
            name
            mediaId
            isArchived
            fileSize
            drmContent
            duration
            createdAt
            createdBy
            adPositions
          }       
        }
    }
  }
  `
)
