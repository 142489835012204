import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, message, Switch } from 'antd'
//

import DropDown from '../inputs/DropDown'
import UploadQueueSystemTags from '../../dataEntry/other/UploadQueueSystemTags'
import Input from '../inputs/Input'
import Button from '../../general/buttons/Button'
import RemoteFolderList from './RemoteFolderList'
import LocalVideoUploader from '../../dataDisplay/LocalVideoUploader'
import { utilityService } from '../../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import QueryAllTags from '../../../../graphQL/asset/getTags'
import QueryAccess from '../../../../graphQL/asset/getFileAccess'
import MutationCreateTag from '../../../../graphQL/asset/createTag'
// import QueryTagContent from '../../../../graphQL/asset/getContentTags'
import QueryTagType from './../../../../graphQL/admin/tagType/listTagType'

import './../../ui.style.scss'

const TabPane = Tabs.TabPane

class AddVideoModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      uploadUrl: undefined,
      fname: '',
      ftype: '',
      file: null,
      success: () => {},
      error: () => {},
      isCancelButtonDisabled: false,
      searchString: '',
      selectedMedias: [],
      filePrefix: undefined,
      selectedCategory: '',
      selectedTags: [],
      searchKey: '',
      isSearchLoading: false,
      isTagCreate: false,
      selectedVodCategory: '',
      isDrmEnabled: true,
      isVodDrmEnabled: true,
      folderLimit: 200,
      fileList: [],
      systemTags: []
    }
  }

  UNSAFE_componentWillReceiveProps=(nextProps) => { // eslint-disable-line camelcase
    if (nextProps.systemTags) {
      const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: enabledTags })
    }
  }

    clearState = () => {
      const state = {
        uploadUrl: undefined,
        fname: '',
        ftype: '',
        files: null,
        success: () => {},
        error: () => {},
        isCancelButtonDisabled: false,
        searchString: '',
        selectedMedias: [],
        filePrefix: undefined,
        selectedTags: [],
        searchKey: '',
        isSearchLoading: false,
        isTagCreate: false,
        selectedCategory: '',
        selectedVodCategory: '',
        isDrmEnabled: true,
        isVodDrmEnabled: true,
        folderLimit: 200,
        fileList: []
      }
      this.setState({ ...state })
    }

    disableCancelButton = (status) => {
      if (status === 'success') {
        this.setState({ isCancelButtonDisabled: false })
      } else {
        this.setState({ isCancelButtonDisabled: true })
      }
    }

    handleSubmit = () => {
      const { selectedTags, selectedCategory, selectedMedias, isDrmEnabled, fileList, selectedVodCategory, isVodDrmEnabled } = this.state
      // const fileLimitExceed = (fileList || []).filter(item => item.size > 5000000000)
      // if (fileLimitExceed.length) {
      //   message.warn('The file upload is currently supported only for files under 5GB. Please remove larger files to proceed with Upload')
      //   return
      // }
      if (selectedMedias && selectedMedias.length) {
        this.props.handleSubmit(selectedMedias, selectedTags, selectedCategory, isDrmEnabled)
      }
      if (fileList && fileList.length) {
        const fileData = {
          tags: selectedTags,
          files: fileList,
          category: selectedVodCategory,
          isDrmEnabled: isVodDrmEnabled
        }
        this.props.handleFileUpload(fileData)
      }
    }

    handleCancel = () => {
      this.props.handleCancel()
    }

    updatedImageName = (files) => {
      const newFiles = files.map(file => ({ fileName: file.name, contentType: file.type }))
      this.setState({ files: newFiles })
    }

    hideFromBackgrond = shouldShow => {
      this.setState({ isHidden: !shouldShow })
    }

    clearFileName = () => this.setState({ fname: '', files: undefined })

    onSelectVideo = (selectedFile) => {
      if (selectedFile.isLeaf) {
        let { selectedMedias } = this.state
        if (selectedFile) { selectedMedias[0] = selectedFile } else { selectedMedias = [] }
        // selectedMedias.push(selectedFile)
        this.setState({ selectedMedias })
      } else {
        this.setState({ filePrefix: selectedFile.absolutePath, selectedMedias: [] })
      }
    }

    onOptionSelect = (item) => {
      this.setState({ selectedCategory: item })
    }

    onVodCategoryOptionSelect = (item) => {
      this.setState({ selectedVodCategory: item })
    }

    handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
      const { selectedTags } = this.state
      if (isDelete) {
        const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
        selectedTags.splice(index, 1)
      } else {
        const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
        if (index === -1) {
          if (isCustom) {
            const newTag = { name: value, module: 'CONTENT_BANK', type: 'MANUAL' }
            const id = Math.random().toString(36).substr(2, 9)
            selectedTags.push({ ...newTag, id, type })
            this.setState({ selectedTags, isTagCreate: true })
            this.props.createTag(newTag).then(response => {
              const tag = response.data.createTag
              const index = selectedTags.findIndex(item => item.id === id)
              selectedTags.splice(index, 1, tag)
              this.setState({ selectedTags, isTagCreate: false })
            }, error => {
              const index = (error.message || '').indexOf('ConditionalCheckFailedException')
              if (index > -1) {
                const tagIndex = selectedTags.findIndex(item => item.id === id)
                const newId = `${value}content_bank`
                selectedTags[tagIndex].key = newId
                this.setState({ selectedTags, isTagCreate: false })
                return
              }
              utilityService.handleError(error)
            })
          }
        } else {
          const newTag = (tagsList[index])
          const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
          if (!isPresent) {
            selectedTags.push(newTag)
          }
        }
      }
      this.setState({ selectedTags })
    }

    onSearchEnter = (e) => {
      const searchKey = e.target.value
      this.setState({ searchKey })
    }

    onSelectLimit = (folderLimit) => {
      this.setState({ folderLimit })
    }

    onSearchPath = () => {
      const { searchKey } = this.state
      this.setState({ isSearchLoading: true })
      this.props.checkFileAccess(searchKey).then((response) => {
        const isAvailable = response.data.checkS3FileAccess
        if (isAvailable) {
          let filePrefix = searchKey
          if (searchKey.startsWith('https://') || searchKey.startsWith('http://') || searchKey.startsWith('s3://')) {
            filePrefix = searchKey.split('/').slice(3).join('/')
          }
          this.setState({ filePrefix, searchKey: '', isSearchLoading: false })
        } else {
          message.warn('No access for this file')
          this.setState({ searchKey: '', isSearchLoading: false })
        }
      })
    }

    drmEnableChange = (isDrmEnabled) => {
      this.setState({ isDrmEnabled })
    }

    vodDrmEnableChange = (isVodDrmEnabled) => {
      this.setState({ isVodDrmEnabled })
    }

    onReorder = (startIndex, endIndex) => {
      const { fileList } = this.state
      const result = Array.from(fileList)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      this.setState({ fileList: result })
      return result
    }

    handleDelete = (index, e) => {
      const { fileList } = this.state
      fileList.splice(index, 1)
      this.setState({ fileList })
    }

    beforeUpload = (data, dataList) => {
      let { fileList } = this.state
      if (!data.size) {
        message.error(`${data.name} is an empty image`)
      }
      dataList = dataList.filter(item => item.size)
      fileList = [...fileList, ...dataList]
      this.setState({ fileList })
    }

    renderHeader = () => {
      return <div className='video-header'><span>ADD VIDEO</span><span onClick={() => this.props.onChangeModal('uploadQueue')}>Select from Content bank</span></div>
    }

    render () {
      const { isVisible, handleCancel, videoCategoryList, isLoading, isSubmitButtonDisabled, project } = this.props
      const { isCancelButtonDisabled, selectedMedias, isHidden, filePrefix, selectedCategory, selectedTags, searchKey, isSearchLoading, isDrmEnabled, folderLimit, fileList, selectedVodCategory, isVodDrmEnabled, isTagCreate, systemTags } = this.state
      return (
        <Modal
          className={`confirm-modal add-video ${isHidden ? 'hidden' : ''}`}
          title={this.renderHeader()}
          maskClosable={false}
          visible={isVisible}
          okText='Add Video'
          onOk={this.handleSubmit}
          onCancel={typeof handleCancel === 'function' ? handleCancel : this.handleCancel}
          okButtonProps={{ disabled: (!selectedMedias.length && !fileList.length) || isSubmitButtonDisabled || isTagCreate, loading: isLoading }}
          cancelButtonProps={{ disabled: isCancelButtonDisabled }}
          closable={false}
          centered
          width='907px'
          afterClose={this.clearState}
          destroyOnClose
        >
          <div id='upload-video-container'>
            <Tabs defaultActiveKey='1' >
              <TabPane tab='Remote Folder' key='1'>
                <div className='s3-search-container'>
                  <Input inputName='searchKey' title='' placeholder='Search S3 URL' value={searchKey} handleChange={this.onSearchEnter} />
                  <Button onClick={this.onSearchPath} buttonText={'Search'} isLoading={isSearchLoading} isDisabled={!searchKey} color={searchKey ? 'enable' : ''} />
                </div>
                <RemoteFolderList
                  prefix={filePrefix}
                  onSelect={this.onSelectVideo}
                  maxKeys={folderLimit}
                  selectLimit={this.onSelectLimit}
                  project={project} />
                <div className='video-settings-container'>
                  <DropDown options={videoCategoryList} selectedValue={selectedCategory}
                    placeHolder='Select Video Category' valueParam='id'
                    displayParam='label' onOptionSelect={this.onOptionSelect}
                    className={selectedCategory ? 'selected' : ''} />
                  <div className='drm-container'>
                    <span>DRM Enabled</span>
                    <Switch onChange={this.drmEnableChange} checked={isDrmEnabled} />
                  </div>
                </div>
              </TabPane>
              <TabPane tab='Local Folder' key='2'>
                <LocalVideoUploader
                  beforeUpload={this.beforeUpload}
                  fileList={fileList}
                  onReorder={this.onReorder}
                  handleDelete={this.handleDelete} />
                <div className='video-settings-container'>
                  <DropDown options={videoCategoryList} selectedValue={selectedVodCategory}
                    placeHolder='Select Video Category' valueParam='id'
                    displayParam='label' onOptionSelect={this.onVodCategoryOptionSelect}
                    className={selectedVodCategory ? 'selected' : ''} />
                  <div className='drm-container'>
                    <span>DRM Enabled</span>
                    <Switch onChange={this.vodDrmEnableChange} checked={isVodDrmEnabled} />
                  </div>
                </div>
              </TabPane>
              <TabPane tab='Tags' key='3'>
                <UploadQueueSystemTags
                  parentCompoent={'upload-video-container'}
                  handleTagChange={this.handleTagChange}
                  systemTags={systemTags}
                  selectedTags={selectedTags}
                  project={project}
                />
              </TabPane>
            </Tabs>
          </div>

        </Modal>
      )
    }
}

AddVideoModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool.isRequired,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Submit button disable status of Modal. */
  isSubmitButtonDisabled: PropTypes.bool,
  /**  Submit button disable status of Modal. */
  isCancelButtonDisabled: PropTypes.bool,
  /** List of images returned by the server. */
  imageList: PropTypes.array,
  /** Callback to be executed when image is selected for uploading */
  updatedImageName: PropTypes.func,
  /**  */
  currentSelection: PropTypes.array,
  /** Mutation function to create new media  */
  createMedia: PropTypes.func,
  /** Function to be called on search for file access */
  checkFileAccess: PropTypes.func,
  /** Function to be called for changing video selection */
  onChangeModal: PropTypes.func
}

AddVideoModal.defaultProps = {
  selectedValue: 'Select'
}

export default withApollo(
  compose(
    graphql(MutationCreateTag, {
      // TODO: The code for MutationCreateTag could be consolidated to keep the code DRY
      options: ({ project }) => {
        return {
          update: (cache, { data: { createTag } }) => {
            const filter = { type: { match: 'MANUAL' }, module: { exact: 'CONTENT_BANK' } }
            try {
              const cacheData = cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
              if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
              cache.writeQuery({
                query: QueryAllTags,
                data: cacheData
              })
            } catch (e) {
              console.log('error', encodeURI)
            }
          }
        }
      },
      props: props => ({
        createTag: contentObj => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
    /* graphql(
      QueryTagContent,
      {
        options: ({ project }) => {
          return {
            variables: { project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
          }
        }
      }
    ), */
    graphql(
      QueryTagType,
      {
        options: ({ project }) => {
          return {
            fetchPolicy: 'network-only',
            variables: { filter: null, limit: 500, project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    ),
    graphql(
      QueryAccess,
      {
        options: ({ fileKey }) => {
          return {
            variables: { fileKey: fileKey || '' }
          }
        },
        props: (props) => {
          return {
            isFileAccess: props.data ? props.data.checkS3FileAccess : false,
            checkFileAccess: (fileKey) => {
              return props.data.refetch({ fileKey })
            }
          }
        }
      }
    )
  )(AddVideoModal)
)
