import gql from 'graphql-tag'

export default gql(`
    mutation deleteOfferAttribute($id: ID!, $partnerId: ID, $name: String, $type: OfferInputTypes $project: String) {
        deleteOfferAttribute(
            id: $id 
            partnerId: $partnerId
            name: $name
            type: $type
            project: $project
        ) {
            id
        }
    }`
)
