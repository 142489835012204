import worker from './../serviceWorker/worker'
import WebWorker from './../serviceWorker/workerSetup'
import config from './../constants/config'
import AuthService from './AuthService'

const logWorker = new WebWorker(worker)
const loggerLevels = ['ERROR', 'WARNING', 'INFO', 'DEBUG']

const error = (message, stack, networkRequest) => {
  postMessage('ERROR', message, message, stack, networkRequest)
}

const warning = (message) => {
  postMessage('WARNING', 'event', message)
}

const info = (type, message, networkDetails) => {
  postMessage('INFO', type, message, undefined, networkDetails)
}

const debug = (message) => {
  postMessage('DEBUG', 'event', message)
}

const postMessage = async (level, type, message, errorStack, networkRequest) => {
  const userDetails = await AuthService.getUserDetails()

  const loggerLevel = window.config && window.config.application_log_level ? window.config.application_log_level : 'ERROR'
  const request = {
    log_type: type,
    log_level: level,
    app: {
      version: config.VERSION,
      releaseStage: 'production'
    },
    device: {
      'osName': os,
      'os_version': osVersion,
      'browserName': objbrowserName,
      'browserVersion': objfullVersion,
      'locale': objLanguage,
      time: new Date().toISOString()
    },
    user: {
      id: '',
      roles: [
        'root-admin',
        'editor'
      ]
    },
    networkRequest,

    errorStack
  }
  if (userDetails) {
    request.user.id = userDetails.name
  }
  if (level === 'INFO') {
    request.event = {
      type,
      message,
      module: ''
    }
  }
  const isLocal = location.origin.indexOf('localhost:') > -1
  if (loggerLevels.indexOf(level) <= loggerLevels.indexOf(loggerLevel) && !isLocal) {
    logWorker.postMessage(request)
  }
  if (loggerLevel === 'DEBUG') {
    console.log(level, type, message, errorStack, networkRequest)
  }
}

const LoggerService = {
  info,
  warning,
  debug,
  error
}

export default LoggerService

const unknown = 'unknown'
const objVer = navigator.appVersion
const objAgent = navigator.userAgent
let objbrowserName = navigator.appName
const objLanguage = navigator.language
let objfullVersion = '' + parseFloat(navigator.appVersion)
let objBrMajorVersion = parseInt(navigator.appVersion, 10)
let objOffsetName, objOffsetVersion, ix

if ((objOffsetVersion = objAgent.indexOf('Chrome')) !== -1) {
  // In Chrome
  objbrowserName = 'Chrome'
  objfullVersion = objAgent.substring(objOffsetVersion + 7)
} else if ((objOffsetVersion = objAgent.indexOf('MSIE')) !== -1) {
  // In IE
  objbrowserName = 'Microsoft Internet Explorer'
  objfullVersion = objAgent.substring(objOffsetVersion + 5)
} else if ((objOffsetVersion = objAgent.indexOf('Firefox')) !== -1) {
  // In Firefox
  objbrowserName = 'Firefox'
} else if ((objOffsetVersion = objAgent.indexOf('Safari')) !== -1) {
  // In Safari
  objbrowserName = 'Safari'
  objfullVersion = objAgent.substring(objOffsetVersion + 7)
  if ((objOffsetVersion = objAgent.indexOf('Version')) !== -1) {
    objfullVersion = objAgent.substring(objOffsetVersion + 8)
  }
} else if ((objOffsetName = objAgent.lastIndexOf(' ') + 1) < (objOffsetVersion = objAgent.lastIndexOf('/'))) {
  // For other browser "name/version" is at the end of userAgent
  objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion)
  objfullVersion = objAgent.substring(objOffsetVersion + 1)
  if (objbrowserName.toLowerCase() === objbrowserName.toUpperCase()) {
    objbrowserName = navigator.appName
  }
}
// trimming the fullVersion string at semicolon/space if present
if ((ix = objfullVersion.indexOf(';')) !== -1) {
  objfullVersion = objfullVersion.substring(0, ix)
}
if ((ix = objfullVersion.indexOf(' ')) !== -1) {
  objfullVersion = objfullVersion.substring(0, ix)
}
objBrMajorVersion = parseInt('' + objfullVersion, 10)
if (isNaN(objBrMajorVersion)) {
  objfullVersion = '' + parseFloat(navigator.appVersion)
  objBrMajorVersion = parseInt(navigator.appVersion, 10)
}
// document.write('' +'Browser name = '+objbrowserName+'<br>' +'Full version = '+objfullVersion+'<br>' +'Major version = '+objBrMajorVersion+'<br>' +'navigator.appName = '+navigator.appName+'<br>' +'navigator.userAgent = '+navigator.userAgent+'<br>' )
var os = unknown
var clientStrings = [
  { s: 'Windows 3.11', r: /Win16/ },
  { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
  { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
  { s: 'Windows 98', r: /(Windows 98|Win98)/ },
  { s: 'Windows CE', r: /Windows CE/ },
  { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
  { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
  { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
  { s: 'Windows Vista', r: /Windows NT 6.0/ },
  { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
  { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
  { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
  { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
  { s: 'Windows ME', r: /Windows ME/ },
  { s: 'Android', r: /Android/ },
  { s: 'Open BSD', r: /OpenBSD/ },
  { s: 'Sun OS', r: /SunOS/ },
  { s: 'Linux', r: /(Linux|X11)/ },
  { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
  { s: 'Mac OS X', r: /Mac OS X/ },
  { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
  { s: 'QNX', r: /QNX/ },
  { s: 'UNIX', r: /UNIX/ },
  { s: 'BeOS', r: /BeOS/ },
  { s: 'OS/2', r: /OS\/2/ },
  { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
]
for (var id in clientStrings) {
  var cs = clientStrings[id]
  if (cs.r.test(objAgent)) {
    os = cs.s
    break
  }
}

var osVersion = unknown

if (/Windows/.test(os)) {
  osVersion = /Windows (.*)/.exec(os)[1]
  os = 'Windows'
}

switch (os) {
  case 'Mac OS X':
    osVersion = /Mac OS X (\d[._\d]+)/.exec(objAgent)[1]
    break

  case 'Android':
    osVersion = /Android ([._\d]+)/.exec(objAgent)[1]
    break

  case 'iOS':
    osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(objVer)
    osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
    break
}
