import gql from 'graphql-tag'

export default gql(`
query listTagType($limit: Int, $project: String! $offset: Int) {
    listTagType( 
    project: $project
    limit: $limit
    offset: $offset
    ){
      totalCount
        items {
          isCustom
          id
          isMediaEnabled
          enabledAssetTypes{
            assetType
          }
          key
          name
          tagCount
          position
        }
      }
}`)
