
import gql from 'graphql-tag'

export default gql(`
      mutation($id: ID! $isEnabled: Boolean $startDate: AWSDateTime $endDate: AWSDateTime $parentAssetId: ID $project: String ) {
        updatePublishRule(
            input:{
              id: $id
              isEnabled: $isEnabled
              publishStartDate: $startDate
              publishEndDate: $endDate
            }
            parentAssetId: $parentAssetId
            project: $project
          ){
            id
            assetId
            group{
              id
            }
            isEnabled
            publishEndDate
            publishStartDate
          }
      }`
)
