import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Skeleton } from 'antd'
import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import PopoverButton from '../general/buttons/PopoverButton'
import ExpandMenuIcon from '../general/icons/ExpandMenuIcon'
import DoneIcon from '../general/icons/DoneIcon'
import CrossIcon from '../general/icons/CrossIcon'
import EditOptaSettingModal from '../dataEntry/other/EditOptaSettingModal'
import userMessages from '../../../constants/messages'
import ConfirmModal from '../feedback/ConfirmModal'
import { utilityService } from '../../../services/UtilityService'

import QueryGetOptaSyncState from '../../../graphQL/admin/getOptaSyncState'
import MutationUpdateOptaSyncState from '../../../graphQL/admin/updateOptaSyncState'
import QueryOptaSyncStates from '../../../graphQL/admin/listOptaSyncStates'

let timeOutVar = null

// const resyncMessage = {
//   message: userMessages.RESYNC_COMPETITION,
//   title: 'RE-SYNC ?'
// }

const resyncWarningMessage = {
  message: userMessages.RESYNC_COMPETITION_ERROR,
  title: 'RE-SYNC NOT POSSIBLE !'
}

const editWarningMessage = {
  message: userMessages.EDIT_COMPETITION_ERROR,
  title: 'EDIT NOT POSSIBLE !'
}

const resetWarningMessage = {
  message: userMessages.RESET_COMPETITION,
  title: 'RESET OPTA SETTINGS'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  },
  {
    type: 're-sync',
    name: 'Re-Sync'
  }
]

class OptaSettingTable extends Component {
  constructor (props) {
    super(props)
    this.columns = [
      {
        title: 'Competition',
        dataIndex: 'title',
        key: 'title',
        width: '26%'

      },
      {
        title: 'OPID',
        dataIndex: 'opId',
        key: 'opId',
        width: '9%'
      },
      {
        title: 'Sync Date',
        dataIndex: 'id',
        key: 'lastRunTime',
        width: '18%',
        render: (id) => this.renderSyncDate(id)
      },
      {
        title: 'Sync Frequency',
        dataIndex: 'id',
        key: 'frequency',
        width: '16%',
        render: (id) => this.renderSyncFrequency(id)
      },
      {
        title: 'Active',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '10%',
        render: isActive => (
          <span>
            {isActive === true ? <DoneIcon /> : <CrossIcon color={'#f5212d'} />}
          </span>
        )
      },
      {
        title: 'Last Sync',
        dataIndex: 'id',
        key: 'isError',
        width: '15%',
        render: (id) => this.renderSyncData(id)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '6%',
        render: (id, data) => this.renderData(id, data)
      }
    ]

    this.state = {
      isEditSetting: false,
      selectedSyncState: {},
      isLoading: props.isLoading,
      openedOption: null,
      shouldShowPopup: false,
      height: null,
      lastRunTime: ''
    }
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
      window.addEventListener('resize', this.setHeight)
      this.setHeight()
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
      window.removeEventListener('resize', this.setHeight)
    }

    setHeight = () => {
      if (this.tableSize) {
        clearTimeout(timeOutVar)
        timeOutVar = null
        this.setState({ height: this.tableSize.clientHeight })
      } else {
        clearTimeout(timeOutVar)
        this.timeOutVar = setTimeout(() => {
          this.setHeight()
        }, 2000)
      }
    }

    handleClickOutside = () => {
      if (this.state.shouldShowPopup) {
        setTimeout(() => this.setState({ shouldShowPopup: false }), 200)
      }
    }

    toggleShowOptions = (id, e) => {
      this.setState({ shouldShowPopup: false })
      e.preventDefault()
      e.stopPropagation()
      this.setState({ openedOption: id, shouldShowPopup: true })
    }

    onSelectOption = (id, selectedOption) => {
      if (selectedOption.type === 'edit') {
        this.setState({ isLoading: true })
        if (this.props.getSyncStateDetails) {
          this.props.getSyncStateDetails({ id: id }).then((response) => {
            const selectedSyncState = response.data.getOptaSyncState
            this.setState({ selectedSyncState, isLoading: false }, () => this.onCheckOptaRunningStatus('edit'))
          })
        }
      } else if (selectedOption.type === 're-sync') {
        if (this.props.getSyncStateDetails) {
          this.props.getSyncStateDetails({ id: id }).then((response) => {
            const selectedSyncState = response.data.getOptaSyncState
            this.setState({ selectedSyncState, isLoading: false }, () => this.onCheckOptaRunningStatus('resync'))
          })
        }
      } else if (selectedOption.type === 'reset') {
        if (this.props.getSyncStateDetails) {
          this.props.getSyncStateDetails({ id: id }).then((response) => {
            const selectedSyncState = response.data.getOptaSyncState
            this.setState({ selectedSyncState, isLoading: false }, () => this.onCheckOptaRunningStatus('reset'))
          })
        }
      }
      this.setState({ openedOption: undefined })
    }

    onCheckOptaRunningStatus = (option) => {
      const { selectedSyncState } = this.state
      let seconds = selectedSyncState.frequency * 60 * 60
      if (selectedSyncState.isRunning === true) {
        if (option === 'resync') {
          this.setState({ showResyncWarning: true })
        } else if (option === 'edit') {
          this.setState({ showEditWarning: true })
        } else if (option === 'reset') {
          this.setState({ showResetWarning: true })
        }
      } else {
        if (option === 'resync') {
          this.setState({ shouldResync: true, lastRunTime: moment().subtract(seconds, 'seconds').format('MMMM Do, h:mm a') })
        } else if (option === 'edit') {
          this.setState({ isEditSetting: true })
        }
      }
    }

    renderSyncFrequency = (id) => {
      const { optaSyncStates } = this.props
      let optaSync = {}
      optaSync = optaSyncStates.find(item => item.id === id)
      return optaSync && optaSync.frequency
        ? (optaSync.frequency <= 1 ? `${optaSync.frequency} hour` : `${optaSync.frequency} hours`) : ' - '
    }

    renderSyncDate = (id) => {
      const { optaSyncStates } = this.props
      let optaSync = {}
      optaSync = optaSyncStates.find(item => item.id === id)
      return optaSync && optaSync.lastRunTime
        ? moment(new Date(optaSync.lastRunTime)).format('DD MMM, YYYY,HH:mm') : ' - '
    }

    renderSyncData= (id) => {
      const { optaSyncStates } = this.props
      let optaSync = {}
      optaSync = optaSyncStates.find(item => item.id === id)
      return optaSync && (optaSync.isRunning === false)
        ? optaSync.isError === null ? 'Waiting to Sync'
          : (optaSync.isError === false ? <div> {moment(new Date(optaSync.lastRunTime)).format('HH:mm')}  <span style={{ color: '#87d068', marginLeft: '1px' }}> Success </span> </div>
            : <div> {moment(new Date(optaSync.lastRunTime)).format('HH:mm')}  <span style={{ color: '#f5212d', marginLeft: '1px' }}> Error </span> </div>)
        : optaSync.isRunning === null ? '-' : 'Syncing'
    }

    renderData = (id, data) => {
      const { openedOption, shouldShowPopup } = this.state
      const { isEditDisabled } = this.props
      const availableOptions = _.cloneDeep(options)
      if (data && data.isRunning) {
        const resetOption = {
          type: 'reset',
          name: 'Reset'
        }
        availableOptions.splice(1, 1, resetOption)
      }
      if (!isEditDisabled) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id, e)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedOption === id && shouldShowPopup}
            />
          </div>
        )
      } else {
        return null
      }
    }

    handleResyncData = () => {
      const { selectedSyncState } = this.state
      const { onHistoryUpdate } = this.props
      this.setState({ isSyncing: true })
      let seconds = selectedSyncState.frequency * 60 * 60
      const newDetails = {
        id: selectedSyncState.id,
        title: selectedSyncState.title,
        lastRunTime: moment().subtract(seconds, 'seconds').utc().format(),
        frequency: selectedSyncState.frequency,
        isActive: true,
        opId: selectedSyncState.opId,
        isError: null,
        isRunning: selectedSyncState.isRunning
      }
      this.props.updateOptaSyncState(newDetails).then(response => {
        this.hideResyncModal()
        onHistoryUpdate()
        this.props.refetchData()
        this.setState({ isSyncing: false })
      }, error => {
        this.hideResyncModal()
        utilityService.handleError(error)
        this.setState({ isSyncing: false })
      })
    }

    handleResetData = () => {
      const { selectedSyncState } = this.state
      const newDetails = {
        id: selectedSyncState.id,
        title: selectedSyncState.title,
        lastRunTime: moment('01-01-2016').utc().format(),
        frequency: selectedSyncState.frequency,
        isActive: true,
        opId: selectedSyncState.opId,
        isError: false,
        isRunning: false
      }
      this.props.updateOptaSyncState(newDetails).then(response => {
        this.setState({ showResetWarning: false })
      }, error => {
        utilityService.handleError(error)
        this.setState({ showResetWarning: false })
      })
    }

    hideEditSettingModal = () => {
      this.setState({ isEditSetting: false })
    }

    handleResyncError = () => {
      this.setState({ showResyncWarning: false })
    }

    handleEditSettingError = () => {
      this.setState({ showEditWarning: false })
    }

    hideResyncModal = () => {
      this.setState({ shouldResync: false })
    }

    render () {
      const { selectedSyncState, isEditSetting, isLoading, shouldResync, showResyncWarning, showEditWarning, isSyncing, showResetWarning, height, lastRunTime } = this.state
      const { optaSyncStates, loading, isEditDisabled, onHistoryUpdate } = this.props

      return (
        <div className='opta-setting-table' id='opta-setting-table' ref={(ref) => { this.tableSize = ref }}>
          <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={loading}>
            <Table
              rowKey={record => record.id}
              columns={this.columns}
              dataSource={optaSyncStates}
              scroll={{ x: true, y: (height - 63) }}
              pagination={false} />
            <EditOptaSettingModal visible={isEditSetting} handleCancel={this.hideEditSettingModal} isOptaSyncDataLoading={isLoading}
              title={'EDIT OPTA SYNC SETTINGS'} okText={'Save'} selectedSyncState={selectedSyncState} isSubmitDisabled={isEditDisabled}
              onHistoryUpdate={onHistoryUpdate} />
            <ConfirmModal
              isVisible={shouldResync}
              title={'RE-SYNC ?'}
              message={`This action will re-sync all the matches of this competition from ${lastRunTime}. Do you want to proceed?`}
              isLoading={isSyncing}
              isLeftPrior
              rightButtonText={'Confirm'}
              handleCancel={this.hideResyncModal}
              isSubmitButtonDisabled={isEditDisabled}
              handleSubmit={this.handleResyncData} />
            <ConfirmModal
              isVisible={showResyncWarning}
              title={resyncWarningMessage.title}
              message={resyncWarningMessage.message}
              isLeftPrior
              rightButtonText={'OK'}
              handleSubmit={this.handleResyncError}
              isSubmitButtonDisabled={isEditDisabled}
              isCancelButtonInvisible />
            <ConfirmModal
              isVisible={showEditWarning}
              title={editWarningMessage.title}
              message={editWarningMessage.message}
              isLeftPrior
              rightButtonText={'OK'}
              handleSubmit={this.handleEditSettingError}
              isSubmitButtonDisabled={isEditDisabled}
              isCancelButtonInvisible />
            <ConfirmModal
              isVisible={showResetWarning}
              title={resetWarningMessage.title}
              message={resetWarningMessage.message}
              isLeftPrior={false}
              rightButtonText={'OK'}
              handleSubmit={this.handleResetData}
              isSubmitButtonDisabled={isEditDisabled}
              isCancelButtonInvisible />
          </Skeleton>
        </div>
      )
    }
}

OptaSettingTable.propTypes = {
  /** Boolean to disable edit */
  isEditDisabled: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryGetOptaSyncState,
    {
      options: () => {
        return {
          variables: { id: null },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          selectedSyncState: props.data.getOptaSyncState,
          isLoading: props.data.loading,
          getSyncStateDetails: (variables) => {
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateOptaSyncState,
    {
      options: (props) => ({
        update: (cache, { data: { updateOptaSyncState } }) => {
          const variables = { offset: 0 }
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryOptaSyncStates, variables }))
          if (listCacheData && listCacheData.listAssets && listCacheData.listAssets.items && updateOptaSyncState) {
            const index = listCacheData.listAssets.items.findIndex(item => item.id === updateOptaSyncState)
            if (index > -1) {
              listCacheData.listAssets.items[index] = updateOptaSyncState
            }
          }
          cache.writeQuery({
            query: QueryOptaSyncStates,
            data: listCacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        updateOptaSyncState: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(OptaSettingTable))
