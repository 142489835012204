import * as React from 'react'
import PropTypes from 'prop-types'
import Switch from '../dataEntry/inputs/Switch'
// import { generateCroppedThumbnail } from './../../../util/util'

// const defaultImage = require('./../../../assets/images/avatar.jpg')

class AppsList extends React.Component {
    checkIfAppEnabled=(id, enabledApps) => {
      const index = (enabledApps || []).findIndex(app => app === id)
      if (index > -1) {
        return true
      } else {
        return false
      }
    }

    renderOptionListBody = () => {
      const { onChange, details, appsList } = this.props
      const { enabledApps } = details
      const finalAssetTypeList = (appsList || []).map(item => {
        return (
          <div className='option-item' key={item.id}>
            <div className='option-item-title'>
              {/* {item.icon || item.draftIcon ? <img src={generateCroppedThumbnail((item.icon ? item.icon : item.draftIcon), 23, 23, '1:1')} /> : <img src={null} />} */}
              <span>{item.name}</span>
            </div>
            <div className='option-item-title'>
              {/* {item.icon || item.draftIcon ? <img src={generateCroppedThumbnail((item.icon ? item.icon : item.draftIcon), 23, 23, '1:1')} /> : <img src={null} />} */}
              <span>{item.key}</span>
            </div>
            <Switch isChecked={this.checkIfAppEnabled(item.id, enabledApps)} onChange={(status) => onChange(item.id, status)} />
          </div>
        )
      })
      return finalAssetTypeList
    }

    render () {
      return <div className='optional-api-key-list-container'>
        <div className='optional-list-title'>
          <span>{'App Name'}</span>
          <span>{'App Key'}</span>
          <span>{'Enabled'}</span>
        </div>
        <div className='optional-list-body'>
          <React.Fragment>
            {this.renderOptionListBody()}
          </React.Fragment>
        </div>
      </div>
    }
}

AppsList.propTypes = {
  /** List of options */
  optionList: PropTypes.array,
  /** Function to be called on change of active state */
  onChange: PropTypes.func
}

export default AppsList
