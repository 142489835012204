import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MediaList from '../../../../modules/contentManager/MediaList'
import QueryMediaCategory from '../../../../graphQL/content/listMediaCategories'
import DropDown from '../inputs/DropDown'
import './../../ui.style.scss'

const getFormattedFilter = (filterVal) => {
  const filterData = typeof (filterVal) === 'string' ? filterVal.split(',') : filterVal
  let value = (filterData || []).map((innerItem) => innerItem.key)
  value = value && value.length ? value : null
  return value
}

class AddImageModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      uploadUrl: undefined,
      fname: '',
      ftype: '',
      file: null,
      success: () => {},
      error: () => {},
      isCancelButtonDisabled: false,
      searchString: '',
      selectedMedias: [],
      selectedCategory: ''
    }
  }

    disableCancelButton = (status) => {
      if (status === 'success') {
        this.setState({ isCancelButtonDisabled: false })
      } else {
        this.setState({ isCancelButtonDisabled: true })
      }
    }

    handleSubmit = () => {
      const { selectedCategory } = this.state
      this.props.handleSubmit(this.selectedImages, selectedCategory)
    }

    handleCancel = () => {
      this.props.handleCancel()
    }

    onDoubleSelectImage = (images, id) => {
      const selectedImage = images.find(image => image.id === id)
      if (selectedImage) {
        this.props.handleSubmit([selectedImage])
      }
    }

    onSearchMedia = (value) => {
      this.setState({ searchString: value })
    }

    onSelectImage = (images, imageId, e, isfolder) => {
      const { isLoading } = this.props
      if (isfolder || isLoading) {
        return
      }
      const maxImages = 25
      const imageIndex = images.findIndex(image => image.id === imageId)
      if (imageIndex > -1) {
        let { selectedMedias } = this.state
        if (!this.props.isMultiSelect) {
          selectedMedias = [imageId]
          if (selectedMedias.length > maxImages) {
            message.warn(`Only ${maxImages} images can be selected at a time`)
            selectedMedias = selectedMedias.slice(0, maxImages)
          }
          this.setState({ selectedMedias })
        } else if (!e || e.metaKey || e.ctrlkey) {
          const index = selectedMedias.indexOf(imageId)
          if (index > -1) {
            selectedMedias.splice(index, 1)
          } else {
            selectedMedias.push(imageId)
          }
          if (selectedMedias.length > maxImages) {
            message.warn(`Only ${maxImages} images can be selected at a time`)
            selectedMedias = selectedMedias.slice(0, maxImages)
          }
          this.setState({ selectedMedias })
        } else {
          const selected = images.findIndex(item => item.id === selectedMedias[selectedMedias.length - 1])
          if (e.shiftKey && selected > -1) {
            const higherIndex = imageIndex > selected ? imageIndex : selected
            const lowerIndex = imageIndex < selected ? imageIndex : selected
            for (let i = lowerIndex; i <= higherIndex; i++) {
              const isExist = selectedMedias.indexOf(images[i].id) > -1
              if (!isExist) {
                selectedMedias.push(images[i].id)
              }
            }
            if (selectedMedias.length > maxImages) {
              message.warn(`Only ${maxImages} images can be selected at a time`)
              selectedMedias = selectedMedias.slice(0, maxImages)
            }
            this.setState({ selectedMedias })
          } else {
            const index = selectedMedias.indexOf(imageId)
            if (index > -1) {
              selectedMedias = []
            } else {
              selectedMedias = [ imageId ]
            }
            if (selectedMedias.length > maxImages) {
              message.warn(`Only ${maxImages} images can be selected at a time`)
              selectedMedias = selectedMedias.slice(0, maxImages)
            }
            this.setState({ selectedMedias })
          }
        }
        this.selectedImages = selectedMedias.map(imageId => images.find(image => image.id === imageId))
      }
    }

    clearState = () => {
      const state = {
        uploadUrl: undefined,
        fname: '',
        ftype: '',
        files: null,
        success: () => {},
        error: () => {},
        isCancelButtonDisabled: false,
        searchString: '',
        selectedCategory: '',
        selectedMedias: [],
        filter: undefined
      }
      this.setState({ ...state })
    }

    updatedImageName = (files) => {
      const newFiles = files.map(file => ({ fileName: file.name, contentType: file.type, type: 'IMAGE' }))
      this.setState({ files: newFiles })
    }

    onFilterChange = (filter, folderFilter) => {
      let newFilter = { }
      if (filter && !_.isEmpty(filter)) {
        filter.map((filterItem) => {
          if (filterItem.value !== null) {
            if (filterItem.type === 'KEYWORD') {
              newFilter[filterItem.name] = {
                multiple_exact: getFormattedFilter(filterItem.value)
              }
            } else if (filterItem.type === 'DATE') {
              newFilter[filterItem.name] = {
                multiple_exact: filterItem.value.split(',')
              }
            } else {
              newFilter[filterItem.name] = {
                match: filterItem.value
              }
            }
          }
          // if (filterItem.value === null && filterItem.type === 'BOOLEAN') {
          //   newFilter[filterItem.name] = {
          //     match: false
          //   }
          // }
        })
      }
      if (folderFilter && !_.isEmpty(folderFilter)) {
        folderFilter.map((filterItem) => {
          if (filterItem.value !== null) {
            if (!newFilter[filterItem.name]) {
              newFilter[filterItem.name] = {}
            }
            if (filterItem.type === 'KEYWORD') {
              newFilter[filterItem.name]['multiple_precise'] = getFormattedFilter(filterItem.value)
            } else if (filterItem.type === 'DATE') {
              newFilter[filterItem.name]['multiple_precise'] = filterItem.value.split(',')
            } else {
              newFilter[filterItem.name]['exact'] = filterItem.value
            }
          }
          // if (filterItem.value === null && filterItem.type === 'BOOLEAN') {
          //   newFilter[filterItem.name] = {
          //     match: false
          //   }
          // }
        })
      }
      if (!_.isEmpty(newFilter) && !newFilter.isArchived) { // dont show archived Media inside folder when opening
        newFilter.isArchived = {
          match: false
        }
      }
      newFilter = _.isEmpty(newFilter) ? {
        isArchived: {
          match: false
        }
      } : newFilter
      this.setState({ filter: newFilter })
    }

    hideFromBackgrond = shouldShow => {
      this.setState({ isHidden: !shouldShow })
    }

    onCategorySelect = (selectedCategory) => {
      this.setState({ selectedCategory })
    }

    renderHeader = () => {
      return <div className='video-header'><span>ADD VIDEO</span><span onClick={() => this.props.onChangeModal('remoteFolder')}>Select from Remote folder</span></div>
    }

    clearFileName = () => this.setState({ fname: '', files: undefined })

    clearSearch = () => {
      const searchString = ''
      const filter = {
        isArchived: {
          match: false
        }
      }
      this.setState({ searchString, filter })
    }

    render () {
      const { isVisible, handleCancel, currentSelection, shouldCacheLoad, mediaType, mediaCategoryList, isSubmitButtonDisabled,
        isUpdateDisabled, isLoading, project, module } = this.props
      const { isCancelButtonDisabled, files, searchString, selectedMedias, isHidden, filter, selectedCategory } = this.state
      const isVideo = mediaType === 'VIDEO'
      return (
        <Modal
          className={`confirm-modal add-image ${isHidden ? 'hidden' : ''}`}
          title={isVideo ? this.renderHeader() : 'ADD IMAGE'}
          maskClosable={false}
          visible={isVisible}
          okText={isVideo ? 'Add Video' : 'Add Image'}
          onOk={this.handleSubmit}
          onCancel={typeof handleCancel === 'function' ? handleCancel : this.handleCancel}
          okButtonProps={{ disabled: (!selectedMedias.length || isUpdateDisabled), loading: isLoading }}
          cancelButtonProps={{ disabled: isCancelButtonDisabled }}
          closable={false}
          centered
          width='907px'
          destroyOnClose
          afterClose={this.clearState}
        >
          <div className='media-list-modal-container'>
            <MediaList
              files={files}
              isAddFolder={false}
              isModal
              filter={filter}
              shouldCacheLoad={shouldCacheLoad}
              updatedImageName={this.updatedImageName}
              onSearchMedia={this.onSearchMedia}
              searchString={searchString}
              selectedMedias={selectedMedias}
              onSelectImage={this.onSelectImage}
              onFilterChange={this.onFilterChange}
              displayModal={this.hideFromBackgrond}
              disableCancelButton={this.disableCancelButton}
              clearFileName={this.clearFileName}
              currentSelection={currentSelection}
              onDoubleClick={this.onDoubleSelectImage}
              mediaType={mediaType}
              isUploadDisabled={isSubmitButtonDisabled}
              clearSearch={this.clearSearch}
              module={module}
              project={project}
            />
            {isVideo ? <DropDown
              options={mediaCategoryList.filter((categoryItem) => categoryItem.type === mediaType && categoryItem.isActive)}
              selectedValue={selectedCategory}
              placeHolder={`Select ${_.capitalize(mediaType)} Category`} valueParam='id'
              displayParam='label' onOptionSelect={this.onCategorySelect}
              className={selectedCategory ? 'selected' : ''} /> : ''}
          </div>
        </Modal>
      )
    }
}

AddImageModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Submit button disable status of Modal. */
  isSubmitButtonDisabled: PropTypes.bool,
  /**  Submit button disable status of Modal. */
  isCancelButtonDisabled: PropTypes.bool,
  /** List of images returned by the server. */
  imageList: PropTypes.array,
  /** Callback to be executed when image is selected for uploading */
  updatedImageName: PropTypes.func,
  /**  */
  currentSelection: PropTypes.array,
  /** Mutation function to create new media  */
  createMedia: PropTypes.func,
  /** mediaType for indicate which all media type to show */
  mediaType: PropTypes.string,
  /** Function to be called for changing modal */
  onChangeModal: PropTypes.func

}

AddImageModal.defaultProps = {
  selectedValue: 'Select'
}

export default withApollo(
  compose(
    graphql(
      QueryMediaCategory,
      {
        options: ({ project }) => {
          const variables = { project }
          return {
            variables
          }
        },
        props: (props) => {
          return {
            mediaCategoryList: props.data && props.data.listMediaCategories && props.data.listMediaCategories.length
              ? props.data.listMediaCategories : []
          }
        }
      }
    )
  )(AddImageModal)
)
