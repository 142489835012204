import React, { Component } from 'react'
import { Input, message } from 'antd'

import { GlobalHotKeys } from 'react-hotkeys'

// import FilterIcon from './../../general/icons/FilterIcon'
import CrossIcon from './../../general/icons/CrossIcon'
import FilterContent from './FilterContent'
import userMessages from '../../../../constants/messages'
import { utilityService } from '../../../../services/UtilityService'
import AuthService from '../../../../services/AuthService'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetFilter from '../../../../graphQL/filter/filterDataList'
import QueryGetSavedFilter from '../../../../graphQL/filter/getSavedFilter'
import MutationCreateFilter from '../../../../graphQL/filter/createFilter'
import MutationUpdateFilter from '../../../../graphQL/filter/updateFilter'
import GetSortOptions from './../../../../graphQL/filter/getSort'

const keyMap = {
  shortcutSearch: '/'
}

let username

const widgetFilterData = [
  {
    displayName: 'App',
    fieldName: 'APP',
    isRequired: true,
    name: 'app',
    type: 'KEYWORD',
    value: null
  },
  {
    displayName: 'Active',
    fieldName: 'APP',
    isRequired: true,
    name: 'isActive',
    type: 'BOOLEAN',
    value: null
  }
]

class FilterInput extends Component {
    state = {
      searchString: this.props.searchString,
      isSelected: false,
      savedFilterSearchString: '',
      savedFilterMode: '',
      filterData: (this.props.filterData && this.props.filterData.length ? _.cloneDeep(this.props.filterData) : []),
      isCreate: false
    }

    componentDidMount () {
      if (this.state.searchString !== this.props.searchString) { this.setState({ searchString: this.props.searchString }) }
      this.getUserDetails()
    }

    componentWillUnmount () {
      clearTimeout(this.delayTime)
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      if (!_.isEqual(this.props.filterData, nextProps.filterData)) {
        this.setState({ filterData: _.cloneDeep(nextProps.filterData)
        })
      }
      if (nextProps.isClearFilter) { this.setState({ searchString: '' }) }
      if (nextProps.initialSearch && (nextProps.searchString || nextProps.searchString === null)) {
        this.setState({ searchString: nextProps.searchString })
      }
    }

    savedFiltermodeHandler = (mode) => {
      this.setState({
        savedFilterMode: mode
      })
    }

    getUserDetails = async () => {
      const userDetails = await AuthService.getUserDetails()
      if (userDetails) {
        username = userDetails.name || userDetails.email
        this.setState({ shouldRefresh: true })
      }
    }

    onRemoveFilter = (id) => {
      if (id) {
        this.props.deleteFilter(id).then(() => {}, error => {
          utilityService.handleError(error)
        })
      }
    }

    savedFilterSearch = (searchString) => {
      this.setState({ savedFilterSearchString: searchString })
    }

    onSearchChange = e => {
      let { value } = e.target
      if (value.startsWith(' ')) { value = value.trim() }
      this.setState({ searchString: value })
      if (this.delayTime) { clearTimeout(this.delayTime) }
      this.delayTime = setTimeout(() => {
        clearTimeout(this.delayTime)
        this.props.onChangeSearchInput(value)
      }, 750)
    }

    clearSearchField =() => {
      const { readOnly } = this.props
      if (readOnly) {

      } else {
        this.setState({
          searchString: ''
        })
        this.props.onChangeSearchInput('')
      }
    }

    changeFilterData = (filterVal, key) => {
      let value = (filterVal || []).map((innerItem) => innerItem[key]).join(',')
      value = value && value.length ? value : null
      return value
    }

    onSearchFilter = (searchStr) => {
      this.props.onSearchFilter(searchStr)
    }

    updateFilter = (filterData) => {
      this.setState({ isCreate: true })
      const filter = (filterData.fields || []).map((item) => {
        return {
          isRequired: item.isRequired,
          type: item.type,
          value: (item.type === 'KEYWORD' || item.type === 'SELECT') ? this.changeFilterData(item.value, this.props.filterType !== 'AppManager' ? 'key' : 'id') : item.value,
          displayValue: (item.type === 'KEYWORD' || item.type === 'SELECT') ? this.changeFilterData(item.value, 'name') : item.value,
          fieldName: item.fieldName,
          name: item.name,
          displayName: item.displayName
        }
      })
      this.props.updateFilter(filterData.id, filterData.name, filter).then(() => {
        this.setState({ isCreate: false })
        message.success(userMessages.FILTER_UPDATE_SUCCESS)
      }, error => {
        this.setState({ isCreate: false })
        utilityService.handleError(error)
      })
    }

    getBucketFilter = (bucketAssetFilter) => {
      const filterData = _.cloneDeep(this.props.filterData)
      const data = filterData.map((filterItem) => {
        const bucketItem = bucketAssetFilter.find((item) => item.name === filterItem.name)
        if (!_.isEmpty(bucketItem) && (filterItem.type === 'KEYWORD' || filterItem.type === 'SELECT') && bucketItem.value) {
          if (typeof (bucketItem.value) === 'string') {
            const names = bucketItem.displayValue.split(',')
            let value = bucketItem.value.split(',').map((item, index) => {
              return {
                key: item,
                name: names[index]
              }
            })
            filterItem.value = value
          } else filterItem.value = bucketItem.value
        } else if (!_.isEmpty(bucketItem) && filterItem.type === 'SORT') {
          filterItem.value = bucketItem.value
          filterItem.fieldName = bucketItem.fieldName
        } else if (!_.isEmpty(bucketItem) && bucketItem.value !== null) {
          filterItem.value = bucketItem.value
        }
        return filterItem
      })
      return data
    }

    createFilter = (filterData) => {
      this.setState({ isCreate: true })
      const filter = (filterData.filter || []).map((item) => {
        return {
          isRequired: item.isRequired,
          type: item.type,
          value: (item.type === 'KEYWORD' || item.type === 'SELECT') ? this.changeFilterData(item.value, 'key') : item.value,
          displayValue: (item.type === 'KEYWORD' || item.type === 'SELECT') ? this.changeFilterData(item.value, 'name') : item.value,
          name: item.name,
          displayName: item.displayName,
          fieldName: item.fieldName
        }
      })
      this.props.createFilter(this.props.filterType, filterData.name, filter).then(() => {
        message.success(userMessages.FILTER_ADDED_SUCCESS)
        this.setState({ isCreate: false })
      }, error => {
        this.setState({ isCreate: false })
        utilityService.handleError(error)
      })
    }

    keyHandlers = {
      shortcutSearch: () => {
        setTimeout(() => {
          document.querySelector('.filter-input-field .ant-input').focus()
        }, 10)
      }
    }

    clickInput = (e) => {
      e.stopPropagation()
      e.preventDefault()
    }

    render () {
      const { searchString, isCreate } = this.state
      const { filterType, placement, loading, filterData, bucketAssetFilter, isClearFilter, parentId, changeFilter,
        sortOptionList, isFilterOpen, placeholder, project, isDisabled, disabledField, className, appClient, readOnly } = this.props
      return (
        <div className={`filter-input-field ${!searchString ? 'empty' : ''} ${isCreate ? 'create' : ''}${!filterType ? ' hide' : ''}`}>
          <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
          <Input
            onClick={this.clickInput}
            onChange={this.onSearchChange}
            value={searchString}
            placeholder={placeholder || 'Search'}
            disabled={readOnly}
            addonAfter={<FilterContent
              onChangeFilter={changeFilter}
              isFilterOpen={isFilterOpen}
              placement={placement}
              filterData={_.isEmpty(bucketAssetFilter) ? (filterType === 'AppManager' ? widgetFilterData : filterData) : this.getBucketFilter(bucketAssetFilter)}
              createFilter={this.createFilter}
              updateFilter={this.updateFilter}
              filterType={filterType} isLoading={loading}
              isClearFilter={isClearFilter}
              isCreateFilter={isCreate}
              sortOptionList={sortOptionList}
              parentId={parentId}
              username={username}
              savedFilterMode={this.state.savedFilterMode}
              savedFiltermodeHandler={this.savedFiltermodeHandler}
              savedFilterSearch={this.savedFilterSearch}
              savedFilterSearchString={this.state.savedFilterSearchString}
              project={project}
              isDisabled={isDisabled}
              className={className}
              disabledField={disabledField}
              appClient={appClient}
              readOnly={readOnly}
            />}
            suffix={<CrossIcon onClick={this.clearSearchField} />
            }
          />
        </div>
      )
    }
}

export default withApollo(compose(
  graphql(
    MutationUpdateFilter,
    {
      options: (props) => ({
        update: (cache, { data: { updateFilter } }) => {
          const { filterType } = props
          let filter = {
            type: {
              match: filterType
            }
          }
          if (filter.name) { delete (filter.name) }
          let cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetSavedFilter, variables: { filter } }))
          if (cacheData && cacheData.listFilters && cacheData.listFilters.items) {
            cacheData.listFilters.items = cacheData.listFilters.items.map((item) => {
              if (item.id === updateFilter.id) { return updateFilter } else return item
            })
          }

          filter.createdBy = {
            match: username
          }

          cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetSavedFilter, variables: { filter } }))
          if (cacheData && cacheData.listFilters && cacheData.listFilters.items) {
            cacheData.listFilters.items = cacheData.listFilters.items.map((item) => {
              if (item.id === updateFilter.id) { return updateFilter } else return item
            })
          }

          cache.writeQuery({
            query: QueryGetSavedFilter,
            data: cacheData,
            variables: { filter }
          })
        }
      }),
      props: (props) => ({
        updateFilter: (id, name, fields) => {
          return props.mutate({
            variables: { id, name, fields }
          })
        }
      })
    }),
  graphql(
    QueryGetFilter,
    {
      skip: (props) => {
        return !(props.filterType && props.filterType.length)
      },
      options: ({ filterType, project }) => {
        return {
          variables: { type: filterType === 'AppManager' ? 'Widget' : filterType, project }
        }
      },
      props: (props) => {
        return {
          filterData: props.data && props.data.filterDataList && props.data.filterDataList.length ? props.data.filterDataList[0].fields : []
          // filterData: props.data && props.data.filterDataList && props.data.filterDataList.length ? props.data.filterDataList[0].fields.filter((item) => item.isRequired) : []
        }
      }
    }
  ),
  graphql(
    MutationCreateFilter,
    {
      options: (props) => ({
        update: (cache, { data: { createFilter } }) => {
          const { filterType } = props
          let filter = {
            type: {
              match: filterType
            }
          }
          if (filter.name) { delete (filter.name) }
          let cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetSavedFilter, variables: { filter, project: props.project } }))
          if (cacheData && cacheData.listFilters && cacheData.listFilters.items) {
            cacheData.listFilters.items.unshift(createFilter)
          }
          cache.writeQuery({
            query: QueryGetSavedFilter,
            data: cacheData,
            variables: { filter, project: props.project }
          })
          filter.createdBy = {
            match: username
          }
          cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetSavedFilter, variables: { filter, project: props.project } }))
          if (cacheData && cacheData.listFilters && cacheData.listFilters.items) {
            cacheData.listFilters.items.unshift(createFilter)
          }
          cache.writeQuery({
            query: QueryGetSavedFilter,
            data: cacheData,
            variables: { filter, project: props.project }
          })
        }
      }),
      props: (props) => ({
        createFilter: (type, name, fields) => {
          return props.mutate({
            variables: { type, name, fields, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    GetSortOptions,
    {
      skip: (props) => {
        return !(props.filterType && props.filterType.length)
      },
      options: ({ filterType, project }) => {
        return {
          variables: { type: filterType, project }
        }
      },
      props: (props) => {
        const { data } = props
        const sortOptionList = data.filterDataList && data.filterDataList.length ? data.filterDataList[0].sortableFields : []
        return { sortOptionList }
      }

    }
  )
)(FilterInput))
