import gql from 'graphql-tag'

export default gql(`
mutation editApiKey($label: String!, $app: [ID]!, $project: String, $id: ID!) {
    editApiKey(
        input: {
            id: $id
            label: $label
            app: $app
        },
        project: $project
    ) {
        id
        apiKey
        label
        app{
            id
            key
            name
            project
            updatedAt
            url
            description
            createdAt
            bundleId
        }
        isDisabled
        isArchived
        createdAt
    }
}`
)
