import gql from 'graphql-tag'

export default gql(`
    mutation updateBucketGroup( $updateData: [BucketGroupFieldInput] $id: ID! $name:String $type: String $isArchived: Boolean $key: String $project: String) {
        updateBucketGroup(
            input: {
                bucketGroupItems: $updateData,
                id: $id
                name: $name
                type: $type
                key: $key
                isArchived: $isArchived
            }
            project: $project
        ) {
            id
            name
            type
            key
            isArchived
            bucketGroupItems{
                id
                bucketId
                position
                bucket{
                    displayName
                    name
                    key
                    id
                    isArchived
                }
            }
        }
    }`
)
