import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateCategoryModal from '../../../components/ui/dataEntry/other/CreateCategoryModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AssetTransferModal from '../../../components/ui/dataEntry/other/AssetTransferModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'

import { utilityService } from '../../../services/UtilityService'
import QueryCategory from '../../../graphQL/admin/asset/listCategory'
import MutationDeleteCategory from '../../../graphQL/admin/asset/deleteCategory'
import MutationTransferCategory from '../../../graphQL/admin/asset/transferAssets'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

const deleteCategoryMessage = {
  title: 'DELETE CATEGORY',
  firstMessage: 'Are you sure you want to delete this Category?',
  secondMessage: 'This action cannot be undone.'
}

const transferMessage = {
  title: 'TRANSFER CATEGORY',
  lastMessage: 'Are you sure you want to transfer to another Category ? This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }, {
    type: 'transfer',
    name: 'Transfer'
  }
]

class CategoryList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedCategory: null,
      isLoading: !(props.categoryList && props.categoryList.length),
      showAddCategoryModal: false,
      showTransferCategoryModal: false,
      showDeleteCategoryModal: false,
      selectedCategory: undefined,
      deleteLoading: false,
      isDeleted: false,
      categorySearch: { searchString1: '', searchString2: '' }
    }
    this.columns = [
      {
        title: 'Categories',
        dataIndex: 'name',
        key: 'name',
        width: '37%'
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
        width: '30%'
      },
      {
        title: '# of Assets',
        dataIndex: 'assetCount',
        width: '30%',
        key: 'assetCount',
        render: (assetCount, { isError }) => this.renderAssetCount(assetCount, isError)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.categoryList, this.props.categoryList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.categoryList, this.props.categoryList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.categoryList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'transfer', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedCategory) {
        this.setState({ openedCategory: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedCategory === id) {
        this.setState({ openedCategory: null })
      } else {
        this.setState({ openedCategory: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { categoryList } = this.props
      const selectedCategory = categoryList.find(item => item.id === id)
      if (!selectedCategory) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddCategoryModal: true, selectedCategory })
      } else if (selectedOption.type === 'transfer') {
        this.setState({ showTransferCategoryModal: true, selectedCategory, fromSelectedCategory: selectedCategory })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteCategoryModal: true, selectedCategory })
      }
      this.setState({ openedCategory: null })
    }

    onCategorySelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedCategory: selectedValue })
      } else {
        this.setState({ fromSelectedCategory: selectedValue })
      }
    }

    showAddCategory = () => {
      this.setState({ showAddCategoryModal: true })
    }

    hideAddCategoryModal = () => {
      this.setState({ showAddCategoryModal: false, selectedCategory: null })
    }

    handleAddCategory = () => {
      this.setState({ showAddCategoryModal: false, selectedCategory: null })
    }

    hideTransferCategoryModal = () => {
      this.setState({ isDeleted: true }, () => {
        this.setState({ showTransferCategoryModal: false, selectedCategory: null, isDeleted: false })
      })
    }

    handleTransferCategory = () => {
      const { fromSelectedCategory, toSelectedCategory } = this.state
      const { onHistoryUpdate } = this.props
      const request = {
        type: 'ASSET_CATEGORY',
        input: {
          oldValue: fromSelectedCategory.id,
          newValue: toSelectedCategory.id
        }
      }
      this.props.transferCategoryAssets(request).then(() => {
        onHistoryUpdate()
        this.setState({ isDeleted: true }, () => {
          this.setState({ showTransferCategoryModal: false, fromSelectedCategory: null, toSelectedCategory: null, selectedCategory: null, isDeleted: false })
        })
      }, error => {
        this.setState({ isDeleted: true }, () => {
          this.setState({ showTransferCategoryModal: false, fromSelectedCategory: null, toSelectedCategory: null, selectedCategory: null, isDeleted: false })
        })

        utilityService.handleError(error)
      })
    }

    hideDeleteCategoryModal = () => {
      this.setState({ isDeleted: true, selectedCategory: null }, () => {
        this.setState({ showDeleteCategoryModal: false, isDeleted: false })
      })
    }

    handleDeleteCategory = () => {
      const { selectedCategory } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedCategory) {
        return
      }
      this.setState({ deleteLoading: true })
      this.props.deleteAssetCategory(selectedCategory.id).then(() => {
        message.success('Category deleted successfully')
        this.setState({ isDeleted: true, selectedCategory: null }, () => {
          this.setState({ showDeleteCategoryModal: false, deleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedCategory: null }, () => {
          this.setState({ showDeleteCategoryModal: false, deleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    onSearchOptions = (value, isFirst) => {
      let { categorySearch } = this.state
      if (isFirst) {
        categorySearch.searchString1 = value
      } else {
        categorySearch.searchString2 = value
      }
      this.setState({ categorySearch })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangeCategoryFilter({ searchString: value })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.categoryList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreCategories(this.props.categoryList.length))
    }

    renderAssetCount = (count, isError) => {
      if (isError) {
        return <div>{'Error in Transfer. Retry'}</div>
      } else if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedCategory } = this.state
      const { categoryList } = this.props
      const selectedCategory = categoryList.find(item => item.id === id)
      if (selectedCategory && (_.isEmpty(selectedCategory.assetCount) || !parseInt(selectedCategory.assetCount)) && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (!selectedCategory.assetCount || !parseInt(selectedCategory.assetCount)) {
        availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 2)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedCategory === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { categoryList, categoryFilter, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showAddCategoryModal, showTransferCategoryModal, showDeleteCategoryModal, selectedCategory, fromSelectedCategory, toSelectedCategory, deleteLoading, isDeleted, categorySearch } = this.state
      let adminItem
      if (selectedCategory && showDeleteCategoryModal) {
        adminItem = <div >
          <p>{ selectedCategory.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal category-list'>
              <FilterInput
                searchString={categoryFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
                project={project}
              />
              <div className='admin-list asset-category-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > categoryList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={categoryList} pagination={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddCategory}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreateCategoryModal
                isVisible={showAddCategoryModal}
                handleCancel={this.hideAddCategoryModal}
                handleSubmit={this.handleAddCategory}
                selectedCategory={selectedCategory}
                listApi module='DATA_MANAGER'
                isSubmitDisabled={_.isEmpty(selectedCategory) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
                project={project}
              />
              <AssetTransferModal
                type='category'
                placeholder={'Select Category'}
                categorySearch={categorySearch}
                isVisible={showTransferCategoryModal}
                handleCancel={this.hideTransferCategoryModal}
                onSubmitTransfer={this.handleTransferCategory}
                title={transferMessage.title}
                onOptionSelect={this.onCategorySelect}
                selectedToValue={toSelectedCategory}
                isImage={false}
                selectedFromValue={fromSelectedCategory}
                lastMessage={transferMessage.lastMessage}
                onSearchOptions={this.onSearchOptions}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteCategoryModal}
                title={deleteCategoryMessage.title}
                firstMessage={deleteCategoryMessage.firstMessage}
                secondMessage={deleteCategoryMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteCategoryModal}
                handleSubmit={this.handleDeleteCategory}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

CategoryList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCategory,
    {
      options: ({ categoryFilter, project }) => {
        const { searchString } = categoryFilter
        const search = searchString ? { keyword: searchString, fields: ['name', 'slug'] } : null
        const variables = { search, offset: 0, limit: 20, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let categoryList = data.listAssetCategories ? data.listAssetCategories.items : []
        categoryList = categoryList.map(item => {
          if (item.isTransferring) {
            item.assetCount = 'Transferring'
          }
          return item
        })
        return {
          categoryList,
          isLoading: data.loading || !data.listAssetCategories,
          totalCount: data.listAssetCategories ? data.listAssetCategories.totalCount : 0,
          getMoreCategories: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAssetCategories.items, ...fetchMoreResult.listAssetCategories.items]
                prev.listAssetCategories.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteCategory, {
      options: ({ categoryFilter, project }) => ({
        update: (cache, { data: { deleteAssetCategory } }) => {
          const { searchString } = categoryFilter
          const search = searchString ? { keyword: searchString, fields: ['name', 'slug'] } : null
          const variables = { search, offset: 0, limit: 20, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCategory, variables }))
          if (cacheData && cacheData.listAssetCategories && cacheData.listAssetCategories.items) {
            const selectedIndex = cacheData.listAssetCategories.items.findIndex(item => item.id === deleteAssetCategory.id)
            if (selectedIndex > -1) {
              cacheData.listAssetCategories.items.splice(selectedIndex, 1)
              cacheData.listAssetCategories.totalCount = cacheData.listAssetCategories.totalCount - 1
            }
          }
          cache.writeQuery({
            query: QueryCategory,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteAssetCategory: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationTransferCategory, {
      options: ({ categoryFilter, project }) => ({
        update: (cache, { data: { transferAssets } }) => {
          const { searchString } = categoryFilter
          const search = searchString ? { keyword: searchString, fields: ['name', 'slug'] } : null
          const variables = { search, offset: 0, limit: 20, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCategory, variables }))
          if (cacheData && cacheData.listAssetCategories && cacheData.listAssetCategories.items) {
            cacheData.listAssetCategories.items = cacheData.listAssetCategories.items.map(item => {
              if (transferAssets.includes(item.id)) {
                item.assetCount = 'Transferring'
              }
              return item
            })
          }
          cache.writeQuery({
            query: QueryCategory,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        transferCategoryAssets: (request) => {
          return props.mutate({
            variables: { ...request, project: props.ownProps.project }
          })
        }
      })
    }
  )

)(CategoryList))
