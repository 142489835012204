import React from 'react'
import './../ui.style.scss'

const OfferHeading = ({ title, active }) => {
  return (
    <div className='offer-heading'>
      <p> { title }</p>
      <p className={`status ${active ? 'active' : 'inActive'}`}> { active ? 'Active' : 'InActive' }</p>
    </div>
  )
}

OfferHeading.propTypes = {

}

OfferHeading.defaultProps = {
  title: 'Offer'
}

export default OfferHeading
