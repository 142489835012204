import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $title: String $shortTitle: String $description: String $shortDescription: String $author: ID $contents: [ID] $tags: [ID] $media: [ID] $publishStartDate: AWSDateTime $publishEndDate: AWSDateTime $updatedAt: AWSDateTime $isArchived: Boolean $slug: String $seoTitle: String $seoKeywords: [String] $seoMetaDescription: String $updatedBy: String
        $match: ID $relatedAssets: ID $category: ID $isLive: Boolean $isFree: Boolean $project: String) {
        updateAsset(
            id: $id
            project: $project
            input: {
                id: $id
                title: $title
                shortTitle: $shortTitle
                description: $description
                shortDescription: $shortDescription
                author: $author
                contents: $contents
                tags: $tags
                media: $media
                updatedAt: $updatedAt
                updatedBy: $updatedBy
                publishEndDate: $publishEndDate
                publishStartDate: $publishStartDate
                isArchived: $isArchived
                seoTitle: $seoTitle
                slug: $slug
                seoKeywords: $seoKeywords
                seoMetaDescription: $seoMetaDescription
                match: $match
                category: $category
                relatedAssets: $relatedAssets
                isLive: $isLive
                isFree: $isFree
            }
        ) {
            id
            title
            type
            shortId
            externalId
            updatedAt
            status
            defaultMedia {
                id
                url
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                        url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
            }
            media{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
            }
            images{
                id
                assetId
                mediaId
                isDefault
                fileName
                createdAt
                name
                type{
                    name
                    id
                }
                fileSize
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                
            }
            videos{
                id
                assetId
                mediaId
                fileName
                createdAt
                name
                fileSize
                cuePoints
                adPositions
                breakDuration
                cuePointsError
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                type
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
                drmContent
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                duration
                thumbnails
                isDefault
            }
        }
    }`
)
