import React from 'react'
import ReactCrop from 'react-image-crop'
import PropTypes from 'prop-types'
import 'react-image-crop/dist/ReactCrop.css'

class ImageCropper extends React.Component {
   ratio = this.props.aspectRatio.title.split(':')
   state = {}
    onImageLoaded = (image, pixelCrop) => {
      this.imageRef = image
      const height = ((this.props.settings.y2 - this.props.settings.y1) / this.imageRef.naturalHeight) * 100
      const width = ((this.props.settings.x2 - this.props.settings.x1) / this.imageRef.naturalWidth) * 100
      const x = (this.props.settings.x1 / image.naturalWidth) * 100
      const y = (this.props.settings.y1 / image.naturalHeight) * 100
      this.setState({ crop: { aspect: Number(this.ratio[ 0 ]) / Number(this.ratio[ 1 ]), x, y, width, height } })
    };

    onCropComplete = (crop, pixelCrop) => {
      if (this.imageRef && crop.width && crop.height) {
        // TODO determine why changing the props here is reflecting it everywhere
        var newSettings = { ...this.props.settings }
        newSettings.x1 = pixelCrop.x
        newSettings.y1 = pixelCrop.y
        newSettings.x2 = pixelCrop.x + pixelCrop.width
        newSettings.y2 = pixelCrop.y + pixelCrop.height
        this.props.callback(newSettings)
      }
    };

    onCropChange = crop => {
      this.setState({ crop })
    };

    startCrop = () => {
    }

    render () {
      const { src } = this.props
      const { crop } = this.state
      return (
        src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            onDragStart={this.startCrop}
          />
        )
      )
    }
}

ImageCropper.propTypes = {
  /** url of the image to be cropped */
  src: PropTypes.string.isRequired,
  /** Aspect ratio of the image */
  aspectRatio: PropTypes.object.isRequired,
  /**  */
  callback: PropTypes.func.isRequired,
  /**  */
  settings: PropTypes.object.isRequired
}

ImageCropper.defaultProps = {

}

export default ImageCropper
