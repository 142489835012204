import React from 'react'
import PropTypes from 'prop-types'
import MultipleSelectIcon from './../icons/MultipleSelectIcon'
import './../../ui.style.scss'

const MultipleSelectButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button multiple-select ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <MultipleSelectIcon />
  </div>
)

MultipleSelectButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

MultipleSelectButton.defaultProps = {
  isDisabled: false
}

export default MultipleSelectButton
