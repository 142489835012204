import gql from 'graphql-tag'

export default gql(`
query($id: ID!, $tournamentCalendar: ID!) {
    getTeam(id: $id, tournamentCalendar: $tournamentCalendar) {
        team{
            id
            externalId
            opId
            title
            shortName
            officialName
            code
            countryExternalId
            country
            status
            city
            postalAddress
            addressZip
            founded
            lastUpdated
            media{
                id
                type
                fileName
                createdAt
                updatedAt
                type
                name
                fileSize
                isArchived
                duration
                thumbnails
                tags{
                    name
                    key
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
                aspectRatio {
                    title
                    aspectRatio
                    ratio
                    resolutions {
                        key
                        width
                        height
                        url
                    }
                }
                streams{
                    id
                }
                vodStatus{
                    id
                }
            }
            source
            customName
            trigramme
            customShortName
            customOfficialName
            customCountry
            customStatus
            customCity
            customPostalAddress
            customAddressZip
            customFounded
            tags{
                name
                key
                type
            }  
        }
        awayJersey{
            id
            type
            fileName
            createdAt
            updatedAt
            type
            name
            fileSize
            isArchived
            duration
            thumbnails
            tags{
                name
                key
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
            aspectRatio {
                title
                aspectRatio
                ratio
                resolutions {
                    key
                    width
                    height
                    url
                }
            }
            streams{
                id
            }
            vodStatus{
                id
            }
        }
        homeJersey{
            id
            type
            fileName
            createdAt
            updatedAt
            type
            name
            fileSize
            isArchived
            duration
            thumbnails
            tags{
                name
                key
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
            aspectRatio {
                title
                aspectRatio
                ratio
                resolutions {
                    key
                    width
                    height
                    url
                }
            }
            streams{
                id
            }
            vodStatus{
                id
            }
        }
        logo{
            id
            type
            fileName
            createdAt
            updatedAt
            type
            name
            fileSize
            isArchived
            duration
            thumbnails
            tags{
                name
                key
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
            aspectRatio {
                title
                aspectRatio
                ratio
                resolutions {
                    key
                    width
                    height
                    url
                }
            }
            streams{
                id
            }
            vodStatus{
                id
            }
        }
        customHomeVenue
    }    
}`)
