import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Switch } from 'antd'

import Input from '../../components/ui/dataEntry/inputs/Input'
import TextAreaInput from '../../components/ui/dataEntry/inputs/TextArea'
import InputNumber from './../../components/ui/dataEntry/inputs/InputNumber'
import userMessages from '../../constants/messages'
import RecurrenceDropDown from '../../components/ui/dataEntry/inputs/RecurrenceDropDown'
import PartnerDropDown from './../../components/ui/dataEntry/inputs/PartnerDropDown'

const parentCompoent = 'offer-container'

class OfferGeneralDetails extends Component {
  constructor (props) {
    super(props)
    if (props.metaFieldList && props.metaFieldList.length && props.details && !(props.details.customMeta && props.details.customMeta.length)) {
      props.handleMetaDetailsChange(props.metaFieldList, null)
    } else if (props.metaFieldList && props.metaFieldList.length > 0 && props.details && props.details.customMeta && props.details.customMeta.length) {
      this.checkForNewMeta(props.metaFieldList, props.details.customMeta)
    }
  }

  UNSAFE_componentWillReceiveProps=(newProps) => { // eslint-disable-line camelcase
    if (newProps.metaFieldList && newProps.metaFieldList.length && newProps.details && !(newProps.details.customMeta && newProps.details.customMeta.length)) {
      this.props.handleMetaDetailsChange(newProps.metaFieldList, null)
    } else if (newProps.metaFieldList && newProps.metaFieldList.length > 0 && newProps.details && newProps.details.customMeta && newProps.details.customMeta.length) {
      this.checkForNewMeta(newProps.metaFieldList, newProps.details.customMeta)
    }
  }

  checkForNewMeta=(metaFieldList, customMeta) => {
    let changeExists = false;
    (metaFieldList || []).map(item => {
      const foundMeta = (customMeta || []).find(existingItem => {
        if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
          return existingItem
        }
      })

      if (_.isEmpty(foundMeta)) {
        changeExists = true
      }
    })
    if (changeExists) { this.props.handleMetaDetailsChange(metaFieldList, null) }
  }

  onRecurrenceChange = (id) => {
    const selectedRecurrence = (this.props.listRecurrence.value || []).find(item => item === id)
    this.props.handleRecurrenceChange(selectedRecurrence)
  }

  onPartnerChange = (id) => {
    const selectedPartner = (this.props.listPartners || []).find(item => item.id === id)
    this.props.handlePartnerChange(selectedPartner)
  }

  getOfferMetaFields = (metaData, selectedMeta) => {
    const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
    const { handleMetaDetailsChange } = this.props
    return (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if (customMeta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{customMeta.name}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => handleMetaDetailsChange(customMeta, value)} />
          </div>
        </div>
      } else if (customMeta.type === 'STRING') {
        return <Input
          // limit={50}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
          handleChange={({ target }) => handleMetaDetailsChange(customMeta, target.value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      } else if (customMeta.type === 'NUMBER') {
        return <InputNumber
          limit={16}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
          handleChange={(value) => handleMetaDetailsChange(customMeta, value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      }
    })
  }

  render () {
    const { details, handleGeneralChange, onInputFieldBlur, onInputFieldFocus, isInvalidTitle, listPartners, disabled, listRecurrence, metaFieldList } = this.props
    const generalDetails = _.cloneDeep(details)
    return (

      <div className='general-details'>
        <div className='input-field'>
          <label className='input-title'>Partner</label>
          <PartnerDropDown
            parentCompoent={parentCompoent}
            options={listPartners}
            selected={_.cloneDeep(details.partner)}
            displayParam='name'
            onOptionSelect={this.onPartnerChange}
            disabled={disabled}
          />
        </div>
        <Input
          title='Offer Name'
          placeholder='Insert Offer Name'
          inputName='name'
          value={details.name}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_TITLE}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <Input
          title='ID'
          placeholder='Insert ID'
          inputName='externalId'
          value={details.externalId}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_EXTERNALID}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <TextAreaInput
          title='Short Description'
          placeholder='Insert Short Description'
          inputName='shortDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.shortDescription}
          handleChange={handleGeneralChange}
        />

        <TextAreaInput
          title='Medium Description'
          placeholder='Insert Medium Description'
          inputName='mediumDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.mediumDescription}
          handleChange={handleGeneralChange}
        />

        <TextAreaInput
          title='Description'
          placeholder='Insert Description'
          inputName='description'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.description}
          handleChange={handleGeneralChange}
        />
        <div className='input-field'>
          <label className='input-title'>Recurrence</label>
          <RecurrenceDropDown
            selectedValue={details.recurrence}
            className='recurrence-drop-down'
            options={listRecurrence && listRecurrence.value ? listRecurrence.value : []}
            valueParam='id'
            displayParam='name'
            onOptionSelect={this.onRecurrenceChange}
            placeHolder='Select Recurrence'
            parent={parentCompoent}
            allowClear
          />
        </div>
        <div className='offer-meta-field-container'>
          {this.getOfferMetaFields(generalDetails.customMeta, metaFieldList)}
        </div>
      </div>
    )
  }
}

OfferGeneralDetails.propTypes = {
  /** function to handle changes in OfferGeneralDetails */
  handleGeneralChange: PropTypes.func,
  /** Offer details of selected Offer. */
  details: PropTypes.object,
  /** function to handle active button changes in OfferGeneralDetails */
  onInputFieldBlur: PropTypes.func,
  /** function to handle focus changes in OfferGeneralDetails */
  onInputFieldFocus: PropTypes.func,
  /** function to handle apps changes in OfferGeneralDetails */
  isInvalidTitle: PropTypes.bool,
  /** function to handle recurrance changes in OfferGeneralDetails */
  handleRecurrenceChange: PropTypes.func,
  /** function to handle partner changes in OfferGeneralDetails */
  handlePartnerChange: PropTypes.func,
  /** List of Partners in OfferGeneralDetails */
  listPartners: PropTypes.arrayOf(PropTypes.object)
}

export default OfferGeneralDetails
