import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getOptaSyncState(id: $id) {
        id
        title
        frequency
        lastRunTime
        opId
        isRunning
        isActive
        isError
    }    
}`)
