import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $title: String! $project: String) {
        updateMetaSection(
            input: {
                id: $id
                title:$title
                project:$project
            }
        ) {
            id
            title
            metaFields{
                displayName
                values
                value
                type
            }
        }
    }`
)
