import React from 'react'
import PropTypes from 'prop-types'

const StatusIcon = ({ width, height, onClick, color, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <g fill='#000' fillRule='nonzero'>
        <g >
          <path fill={color} d='M17.071 2.929C15.182 1.04 12.671 0 10 0 7.329 0 4.818 1.04 2.929 2.929 1.04 4.818 0 7.329 0 10c0 2.671 1.04 5.182 2.929 7.071C4.818 18.96 7.329 20 10 20c2.671 0 5.182-1.04 7.071-2.929C18.96 15.182 20 12.671 20 10c0-2.671-1.04-5.182-2.929-7.071zM10 18.829c-4.868 0-8.828-3.961-8.828-8.829S5.132 1.172 10 1.172c4.868 0 8.828 3.96 8.828 8.828 0 4.868-3.96 8.828-8.828 8.828z' transform='translate(-407 -132) translate(407 132)' />
          <path fill={color} d='M12.914 6.885c-.229.229-.229.6 0 .828 1.607 1.607 1.607 4.222 0 5.829-.803.803-1.859 1.205-2.914 1.205-1.055 0-2.11-.402-2.914-1.205-1.607-1.607-1.607-4.222 0-5.828.229-.23.229-.6 0-.83-.229-.228-.6-.228-.829 0-2.063 2.065-2.063 5.423 0 7.486C7.29 15.402 8.645 15.918 10 15.918s2.71-.516 3.743-1.548c2.063-2.063 2.063-5.421 0-7.485-.23-.229-.6-.229-.829 0z' transform='translate(-407 -132) translate(407 132)' />
          <path fill={color} d='M10 8.703c.324 0 .586-.262.586-.586V3.724c0-.324-.262-.586-.586-.586-.324 0-.586.262-.586.586v4.393c0 .324.262.586.586.586z' transform='translate(-407 -132) translate(407 132)' />
        </g>
      </g>
    </g>
  </svg>
)

StatusIcon.propsTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

StatusIcon.defaultProps = {
  width: 20,
  height: 20
}

export default StatusIcon
