import gql from 'graphql-tag'

export default gql(`
    mutation dismissNotification($id: ID!){
        dismissNotification(
            id: $id
        ) {
            id
        }
}`)
