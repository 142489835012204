import React from 'react'
import PropTypes from 'prop-types'

const InformationIcon = ({ width, height, stroke, fill, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='nonzero'>
      <path stroke={stroke} strokeWidth='1.357' d='M9 .679a8.322 8.322 0 1 0 0 16.643A8.322 8.322 0 0 0 9 .68z' />
      <path fill={fill} d='M8.623 7.184c.456 0 .81.124 1.056.366s.37.56.37.948c0 .081-.007.224-.024.426a3.19 3.19 0 0 1-.095.56l-.473 1.848a5.794 5.794 0 0 0-.105.507c-.03.188-.044.334-.044.43 0 .246.049.416.149.505.097.089.27.132.517.132.114 0 .243-.022.39-.065a2.1 2.1 0 0 0 .314-.119l-.124.572c-.38.164-.683.29-.91.377-.226.086-.487.129-.787.129-.458 0-.816-.124-1.072-.372-.254-.245-.38-.56-.38-.943 0-.148.01-.299.029-.452.017-.156.049-.329.09-.526l.475-1.853c.042-.178.078-.345.108-.504a2.41 2.41 0 0 0 .041-.436c0-.235-.044-.402-.132-.493-.09-.094-.258-.14-.507-.14a1.27 1.27 0 0 0-.377.062c-.13.043-.24.083-.332.12l.127-.57c.31-.14.607-.259.892-.359.283-.1.553-.15.804-.15zM9.673 3.8c.31 0 .576.113.795.342.222.23.332.507.332.827 0 .32-.11.596-.332.822-.219.226-.485.34-.794.34-.31 0-.578-.114-.8-.34a1.131 1.131 0 0 1-.331-.822c0-.32.112-.598.331-.827.222-.229.49-.342.8-.342z' />
    </g>  </svg>
)

InformationIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

InformationIcon.defaultProps = {
  width: 18,
  height: 18,
  stroke: '#343A40',
  fill: '#343A40'
}

export default InformationIcon
