import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Divider } from 'antd'

import GroupDragAndDropIcon from '../general/icons/GroupDragAndDropIcon'
import CrossIcon from '../general/icons/CrossIcon'
import PersonDropDown from '../dataEntry/inputs/PersonDropDown'
import RoleDropDown from '../dataEntry/inputs/RoleDropDown'

import './../ui.style.scss'

const parentCompoent = 'asset-container'

class CreditCrewFieldCell extends React.Component {
  onCloseField = (index) => {
    this.props.onCloseField(index)
  }

  onPersonChange = (id, index) => {
    const selectedPerson = this.props.persons.find(person => person.id === id)
    this.props.onPersonChange(selectedPerson, index)
  }

  onPersonSearch = (value) => {
    if (this.props.onPersonSearch) { this.props.onPersonSearch(value) }
  }

  onRoleSearch = (value) => {
    if (this.props.onRoleSearch) { this.props.onRoleSearch(value) }
  }

  render () {
    const { field, isUpdateDisabled, project } = this.props
    const { index, onRoleSelect, roles, roleSearch, persons, isCreateDisabled } = this.props
    const isPerson = true
    return (
      <div className='credit-field-cell credit-field-general'>
        <GroupDragAndDropIcon />
        <div className='group' id='group'>
          <label className='label'>Role</label>
          <RoleDropDown
            parentCompoent={parentCompoent}
            onRoleSelect={onRoleSelect}
            selectedRole={field.role}
            index={index}
            onSearch={this.onRoleSearch}
            roles={roles}
            roleSearch={roleSearch}
            project={project}
          />
        </div>
        {isPerson ? <div className='group'>
          <label className='label'>Person</label>
          <PersonDropDown
            parentCompoent={parentCompoent}
            options={persons || []}
            selected={field.person}
            displayParam='name'
            onSearch={this.onPersonSearch}
            index={index}
            onOptionSelect={this.onPersonChange}
            dropdownRender={menu => (
              <div>
                {isCreateDisabled ? '' : <React.Fragment>
                  <div onMouseDown={(e) => {
                    e.stopPropagation()
                    this.props.showCreatePersonModal(index)
                  }} className='custom-dropdown-container'>
                    <div style={{ position: 'absolute', left: 0, marginLeft: '10px' }}>New Person</div>
                    <div style={{ position: 'absolute', right: 0, marginRight: '10px' }} > <Icon type='plus' /> </div>
                  </div>
                  <Divider dashed style={{ margin: 0 }} />
                </React.Fragment>}
                { menu }
              </div>
            )}
          />
        </div> : null}

        {isUpdateDisabled ? '' : <CrossIcon onClick={() => this.onCloseField(field.id)} />}

      </div>
    )
  }
}
CreditCrewFieldCell.propTypes = {
  /** index number of the creditFieldComponent */
  index: PropTypes.number,
  /** object about specific creditFieldComponent details */
  field: PropTypes.object
}

export default CreditCrewFieldCell
