import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { Skeleton, message } from 'antd'

import NProgress from 'nprogress'
import TabPanel from '../../components/ui/dataDisplay/TabPanel'
import MatchGeneralDetails from './../../components/ui/dataDisplay/MatchGeneralDetails'
import MatchAssets from './MatchAssets'
import MatchInfoTab from './MatchInfoTab'
import MatchSettings from './MatchSettings'
import HistoryList from '../auditManager/HistoryList'
import MutationTriggerFasterSync from '../../graphQL/match/triggerFasterSync'
import QueryGetMatchDetails from '../../graphQL/match/getMatch'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import { wordCapitalize } from '../../util/util'
import { utilityService } from '../../services/UtilityService'

const width = ['0%', '100%', '100%', '100%', '150%', '150%']
const widths = ['150%', '150%']
const detailsTabs = [
  {
    displayName: 'Info',
    mode: 'info'
  }, {
    displayName: 'Assets',
    mode: 'assets'
  },
  {
    displayName: 'History',
    mode: 'history'
  },
  {
    displayName: 'Sync',
    mode: 'settings'
  }
]

const fasterSyncStartWarnning = {
  message: 'Are you sure you want to start faster sync ?',
  title: 'Start Faster Sync'
}

const fasterSyncStopWarnning = {
  message: 'Are you sure you want to stop faster sync ?',
  title: 'Stop Faster Sync'
}

class MatchDetails extends Component {
  state = {
    mode: 'info',
    matchDetails: undefined,
    isLoading: true,
    shouldShowWarning: false,
    fasterSyncAction: null,
    fasterSyncLoading: false
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.selectedMatchId && !_.isEqual(newProps.selectedMatchId, this.props.selectedMatchId)) {
      this.setState({ isLoading: true })
    } else if (!newProps.selectedMatchId) {
      this.setState({ isLoading: false })
    }
    if (newProps.matchDetails && !_.isEqual(newProps.matchDetails, this.props.matchDetails) && this.props.matchDetails && newProps.matchDetails.id === this.props.matchDetails.id) {
      this.setState({ matchDetails: this.state.matchDetails, isLoading: false })
      NProgress.done()
    } else if (newProps.matchDetails && !_.isEqual(newProps.matchDetails, this.props.matchDetails)) {
      this.setState({ matchDetails: newProps.matchDetails, isLoading: false, mode: 'info' })
      NProgress.done()
    }
    if (!newProps.matchIsLoading && this.props.matchIsLoading && !newProps.matchDetails) {
      this.setState({ isLoading: false, matchDetails: undefined })
    }
  }

  matchUpdated = () => {
    const { selectedMatchId } = this.props
    this.props.getMatchDetails({ id: selectedMatchId }).then((response) => {
      const matchDetails = response.data.getMatch
      this.setState({ matchDetails })
    })
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  triggerFasterSync = () => {
    const { matchDetails, fasterSyncAction } = this.state
    const { matchDate, opId, id } = matchDetails
    const match = {
      matchDate, opId, id
    }
    this.setState({ fasterSyncLoading: true })
    this.props.triggerMutation(fasterSyncAction, match).then((res) => {
      const { data: { triggerFasterSync } } = res || {}
      if (triggerFasterSync) {
        message.success(`${wordCapitalize(triggerFasterSync.status)}`)
      }
      this.setState({ shouldShowWarning: false, fasterSyncLoading: false })
    }
    , (error) => {
      this.setState({ fasterSyncLoading: false })
      utilityService.handleError(error)
    })
  }

  onHnadleFasterSync = (fasterSyncAction) => {
    this.setState({ fasterSyncAction, shouldShowWarning: true })
  }

  render () {
    const { mode, matchDetails, isLoading, shouldShowWarning, fasterSyncLoading, fasterSyncAction } = this.state
    const { selectedMatchId, listAssetTypes, project, matchStatusList, metaFieldList, permissions } = this.props
    const gameAssets = matchDetails && matchDetails.asset && matchDetails.asset.length ? matchDetails.asset.filter(item => item.type === 'GAME' && item.program && !item.isArchived) : null
    const program = matchDetails && gameAssets && gameAssets.length ? gameAssets[0].program : matchDetails && matchDetails.programs && matchDetails.programs[0] ? matchDetails.programs[0] : []
    const userPermissions = permissions['MATCH_MANAGER']
    const isSyncDisabled = userPermissions.indexOf('SYNC') === -1
    return (
      <div className='match-details'>
        <ConfirmModal isVisible={shouldShowWarning}
          title={fasterSyncAction === 'start' ? fasterSyncStartWarnning.title : fasterSyncStopWarnning.title}
          message={fasterSyncAction === 'start' ? fasterSyncStartWarnning.message : fasterSyncStopWarnning.message} isLoading={fasterSyncLoading}
          isLeftPrior rightButtonText={'Confirm'} handleCancel={() => { this.setState({ shouldShowWarning: false }) }} handleSubmit={this.triggerFasterSync} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />

        { matchDetails && selectedMatchId && !isLoading ? <MatchGeneralDetails match={matchDetails}
          program={program}
          assets={matchDetails && matchDetails.asset ? matchDetails.asset : []}
        />
          : <div className='match-skeleton'>
            <Skeleton active avatar title={false} paragraph={{ rows: 6, width: width }} loading={isLoading} />
            <Skeleton active avatar title={false} paragraph={{ rows: 2, width: widths }} loading={isLoading} />
          </div>
        }
        { matchDetails && selectedMatchId && !isLoading
          ? <React.Fragment>
            <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
            { mode === 'assets' ? <MatchAssets
              match={matchDetails}
              assets={matchDetails && matchDetails.asset ? matchDetails.asset : []}
              program={program}
              listAssetTypes={listAssetTypes}
            /> : null }
            { mode === 'info' ? <MatchInfoTab
              id={matchDetails.id}
              notLive={matchDetails.notLive}
              venue={matchDetails.customVenueName}
              tagList={(matchDetails.tags || []).filter(item => item)}
              isLiveOnly={matchDetails.isLiveOnly}
              defaultVenue={matchDetails.venue}
              matchUpdated={this.matchUpdated}
              project={project}
              matchStatusList={matchStatusList}
              status={matchDetails.status}
              customMeta={matchDetails.customMeta}
              metaFieldList={metaFieldList}
              handleMetaDetailsChange={this.handleMetaDetailsChange}
            /> : null }
            { mode === 'settings' ? <MatchSettings
              id={matchDetails.id}
              notLive={matchDetails.notLive}
              venue={matchDetails.customVenueName}
              tagList={(matchDetails.tags || []).filter(item => item)}
              isLiveOnly={matchDetails.isLiveOnly}
              defaultVenue={matchDetails.venue}
              matchUpdated={this.matchUpdated}
              project={project}
              matchStatusList={matchStatusList}
              status={matchDetails.status}
              customMeta={matchDetails.customMeta}
              metaFieldList={metaFieldList}
              handleMetaDetailsChange={this.handleMetaDetailsChange}
              onHnadleFasterSync={this.onHnadleFasterSync}
              isSyncDisabled={isSyncDisabled}
            /> : null }
            {mode === 'history' ? <HistoryList contentId={matchDetails.id} content='MATCH_MANAGER' showFilter={false} project={project} /> : null }
            { mode === 'comments' ? <p> Comments</p> : null }
          </React.Fragment>
          : null }
      </div>
    )
  }
}
MatchDetails.propTypes = {
  /** selectedMedia of ContentDetails */
  selectedMedia: PropTypes.string,
  /** delete media  MediaDetails. */
  onDeleteMedia: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryGetMatchDetails,
    {
      skip: ({ selectedMatchId }) => {
        return !selectedMatchId
      },
      options: ({ selectedMatchId }) => {
        return {
          variables: { id: selectedMatchId },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          matchDetails: props.data.getMatch,
          matchIsLoading: props.data.loading,
          getMatchDetails: (variables) => {
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    MutationTriggerFasterSync,
    {
      props: (props) => ({
        triggerMutation: (action, match) => {
          return props.mutate({
            variables: { action, match, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(MatchDetails))
