import * as React from 'react'
import PropTypes from 'prop-types'
import { Skeleton, message } from 'antd'

import NProgress from 'nprogress'
import AppContext from '../../AppContext'

import EditButton from './../../components/ui/general/buttons/EditButton'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import PopoverButton from './../../components/ui/general/buttons/PopoverButton'
import AddButton from './../../components/ui/general/buttons/AddButton'
import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
import RestoreButton from './../../components/ui/general/buttons/RestoreButton'
import { ConfigGroupEditModal } from '../../components/ui/dataEntry/other/ConfigGroupEditModal'
import { ChannelConfigAccordion } from './ChannelConfigAccordion'
import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import MasterSettings from '../../components/ui/general/icons/MasterSettings'
import HistoryList from '../auditManager/HistoryList'
import Player from '../../components/player/Player'
import userMessages from '../../constants/messages'
import { utilityService } from '../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetChannelDetails from '../../graphQL/channel/getChannel'
import MutationUpdateActiveConfig from '../../graphQL/channel/updateActiveConfig'
import MutationDeleteChannelConfigGroup from '../../graphQL/channel/deleteChannelConfigGroup'
import MutationUpdateStream from '../../graphQL/channel/batchUpdateStream'
import MutationCreateChannelConfigGroup from '../../graphQL/channel/createChannelConfigGroup'
import MutationUpdateChannelConfigGroup from '../../graphQL/channel/updateChannelConfigGroup'
import BatchUpdateChannelConfigGroup from '../../graphQL/channel/batchUpdateChannelConfigGroup'
import MutationCreateStream from '../../graphQL/channel/batchCreateStream'
import QueryListConfigGroups from '../../graphQL/admin/channel/listConfigGroup'
import QueryListChannelStreams from '../../graphQL/admin/channel/listStreamLevels'

const defaultImage = require('../../assets/images/default-image-square.jpg')

const detailsTabs = [
  {
    displayName: 'Details',
    mode: 'details'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

class ChannelConfigList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeButton: 'details',
      scrollHeight: 600,
      isListVisible: false,
      isArchived: props.channelDetails ? props.channelDetails.isArchived : false,
      channelConfigGroup: props.channelDetails ? _.sortBy(props.channelDetails.configGroups, (item) => item.position) : [],
      activeConfigId: props.channelDetails && props.channelDetails.activeConfigGroup ? props.channelDetails.activeConfigGroup.id : '',
      isEditClicked: false,
      editConfigData: {},
      configSchema: {},
      fieldData: []
    }
    this.containerRef = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.channelDetails && !_.isEqual(nextProps.channelDetails, this.props.channelDetails)) {
      this.setState({
        isArchived: nextProps.channelDetails ? nextProps.channelDetails.isArchived : false,
        channelConfigGroup: nextProps.channelDetails ? _.sortBy(_.cloneDeep(nextProps.channelDetails.configGroups), (item) => item.position) : [],
        activeConfigId: nextProps.channelDetails && nextProps.channelDetails.activeConfigGroup ? nextProps.channelDetails.activeConfigGroup.id : '',
        isListVisible: false
      })
    }
    if (nextProps.channelDetails && this.props.channelDetails && nextProps.channelDetails.id !== this.props.channelDetails.id) {
      this.setState({ streamRequest: null })
    }
    if (!nextProps.configGroupLoading) { NProgress.done() }
  }

  handleClickOutside = () => {
    if (this.state.isListVisible) {
      setTimeout(() => this.setState({ isListVisible: false }), 200)
    }
  }

  handleModeChange = e => {
    const activeButton = e.target.value
    this.setState({ activeButton })
  }

  updateDimensions = () => {
    const scrollHeight = this.containerRef.current.clientHeight - 98 // 145/ 100
    this.setState({
      scrollHeight
    })
  }

  onReorder = (startIndex, endIndex) => {
    const { channelConfigGroup } = this.state
    let result = _.cloneDeep(channelConfigGroup)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    const configGroup = result.map((item, index) => {
      let trackPosChange = false
      if (index === endIndex) {
        trackPosChange = true
      }
      return {
        channelId: this.props.selectedId,
        schemaId: item.schemaId.id,
        fields: item.fields && item.fields.length ? item.fields.map((fieldItem) => {
          delete (fieldItem.__typename)
          return fieldItem
        }) : null,
        id: item.id,
        name: item.name,
        position: index + 1,
        streams: item.streams && item.streams.length ? item.streams.map((streamItem) => {
          return streamItem.id
        }) : null,
        trackPosChange: trackPosChange,
        version: item.version
      }
    })
    this.setState({ channelConfigGroup: result })
    this.props.batchUpdateChannelConfigGroup(configGroup, this.props.selectedId).then(() => { this.props.onRearrangeConfigGroups() }, error => {
      utilityService.handleError(error)
    })
  }

  handleModeChange = e => {
    const activeButton = e.target.value
    this.setState({ activeButton })
  }

  onArchiveClick = () => {
    this.props.onArchiveClick()
  }

  toggleShowCreate = () => {
    this.setState({ isListVisible: !this.state.isListVisible })
  }

  onEditConfig = (id) => {
    const selectedItem = _.cloneDeep(this.props.channelDetails)
    const editConfigData = _.find(selectedItem.configGroups, (items) => items.id === id)
    const { configGroupList } = this.props
    const selectedConfigGroup = configGroupList.find(item => item.id === editConfigData.schemaId.id)
    let { configData, fieldData } = this.getConfigSteam(_.cloneDeep(selectedConfigGroup))
    fieldData = (fieldData || []).map(item => {
      const selectedField = (editConfigData.fields || []).find(config => config.type === item.type && config.name === item.name)
      return selectedField ? { ...item, value: selectedField.value, schemaId: editConfigData.schemaId.id } : item
    })
    this.setState({ editConfigData, configSchema: configData, isFromAddChannel: false, fieldData }, () => {
      this.setState({ isEditClicked: true })
    })
  }

  onCancelConfig = () => {
    this.setState({ isEditClicked: false })
  }

  onRemoveConfig = (id) => {
    this.props.deleteChannelConfigGroup(id, this.props.selectedId).then(() => {
      const activeConfigId = this.props.channelDetails.activeConfigGroup
      if (this.props.channelDetails.configGroups && this.props.channelDetails.configGroups.length && activeConfigId === null) {
        const configItem = _.sortBy(cloneDeep(this.props.channelDetails.configGroups), [(item) => item.position])
        this.makeConfigActive(configItem[0].id)
      }
    }, error => {
      utilityService.handleError(error)
    })
  }

  onSaveConfig = (editConfigInfo, fieldArr) => {
    const fieldData = fieldArr && fieldArr.length ? fieldArr.map((item) => {
      if (item.__typename) { delete item.__typename }
      return item
    }) : null
    this.setState({ isSaveConfigLoading: true, fieldData })
    const updateStreamInput = editConfigInfo.streams.map((streamItem) => {
      const fields = streamItem.fields.map((item) => {
        return {
          isRequired: item.isRequired,
          type: item.type,
          value: item.value,
          name: item.name
        }
      })
      const configItem = {
        fields: fields,
        streamSchemaId: streamItem.schemaId,
        url: streamItem.url,
        id: streamItem.id,
        levelVersions: streamItem.levelVersions
      }
      if (this.state.isFromAddChannel) { delete configItem.id }
      return configItem
    })
    const version = this.state.configSchema.version
    if (this.state.isFromAddChannel) {
      this.props.createStream(updateStreamInput, this.props.selectedId).then((streamCreateResponse) => {
        const streamData = streamCreateResponse.data.batchCreateStream.map((item) => item.id)
        const configGroups = !_.isEmpty(this.props.channelDetails) && this.props.channelDetails.configGroups && this.props.channelDetails.configGroups.length ? _.sortBy(this.props.channelDetails.configGroups, (item) => item.position) : []
        const position = configGroups.length ? configGroups[configGroups.length - 1].position + 1 : 1
        this.props.createChannelConfigGroup(editConfigInfo.name, editConfigInfo.id, streamData, this.props.selectedId, position, fieldData, version).then((newConfig) => {
          if (!_.isEmpty(this.props.channelDetails) && this.props.channelDetails.configGroups && this.props.channelDetails.configGroups.length === 0) { this.makeConfigActive(newConfig.data.createChannelConfigGroup.id) }
          this.setState({
            isEditClicked: false,
            configSchema: null,
            isSaveConfigLoading: false
          })
        }, error => {
          this.setState({ isSaveConfigLoading: false })
          utilityService.handleError(error)
        })
      }, error => {
        this.setState({ isSaveConfigLoading: false })
        utilityService.handleError(error)
      })
    } else {
      const modifiedfieldData = (fieldData || []).map(item => ({ name: item.name, value: item.value, isRequired: item.isRequired, type: item.type }))
      const updatingStreams = (updateStreamInput || []).filter(item => item.id)
      const newStreams = (updateStreamInput || []).filter(item => !item.id).map(item => { delete item.id; return item })
      if (updatingStreams && updatingStreams.length && newStreams && newStreams.length) {
        this.props.updateStream(updatingStreams, this.props.selectedId, this.state.editConfigData.name).then((updateResponse) => {
          if (!_.isEmpty(updateResponse) && updateResponse.data && updateResponse.data.batchUpdateStream) {
            const updatedStreamData = updateResponse.data.batchUpdateStream.map((item) => item.id)
            this.props.createStream(newStreams, this.props.selectedId, this.state.editConfigData.name).then((streamCreateResponse) => {
              if (!_.isEmpty(streamCreateResponse) && streamCreateResponse.data && streamCreateResponse.data.batchCreateStream) {
                const streams = [...updatedStreamData, ...streamCreateResponse.data.batchCreateStream.map((item) => item.id)]
                const configDataRequest = {
                  id: editConfigInfo.configId,
                  channelId: this.props.selectedId,
                  position: editConfigInfo.position,
                  name: editConfigInfo.name,
                  fields: modifiedfieldData,
                  version,
                  streams,
                  schemaId: editConfigInfo.id
                }
                this.props.updateChannelConfigGroup(configDataRequest).then(() => {
                  this.setState({ isEditClicked: false, isSaveConfigLoading: false, configSchema: null })
                }, error => {
                  this.setState({ isSaveConfigLoading: false })
                  utilityService.handleError(error)
                })
              }
            }, error => {
              this.setState({ isSaveConfigLoading: false })
              utilityService.handleError(error)
            })
          }
        }, error => {
          this.setState({ isSaveConfigLoading: false })
          utilityService.handleError(error)
        })
      } else if (updatingStreams && updatingStreams.length) {
        this.props.updateStream(updatingStreams, this.props.selectedId, this.state.editConfigData.name).then((updateResponse) => {
          if (!_.isEmpty(updateResponse) && updateResponse.data && updateResponse.data.batchUpdateStream) {
            const updatedStreamData = updateResponse.data.batchUpdateStream.map((item) => item.id)
            const configDataRequest = {
              id: editConfigInfo.configId,
              channelId: this.props.selectedId,
              fields: modifiedfieldData,
              version,
              streams: updatedStreamData,
              schemaId: editConfigInfo.id,
              position: editConfigInfo.position,
              name: editConfigInfo.name
            }
            this.props.updateChannelConfigGroup(configDataRequest).then(() => {
              this.setState({ isEditClicked: false, isSaveConfigLoading: false, configSchema: null })
            }, error => {
              this.setState({ isSaveConfigLoading: false })
              utilityService.handleError(error)
            })
          }
        }, error => {
          this.setState({ isSaveConfigLoading: false })
          utilityService.handleError(error)
        })
      } else if (newStreams && newStreams.length) {
        this.props.createStream(newStreams, this.props.selectedId, this.state.editConfigData.name).then((streamCreateResponse) => {
          if (!_.isEmpty(streamCreateResponse) && streamCreateResponse.data && streamCreateResponse.data.batchCreateStream) {
            const updatedStreamData = streamCreateResponse.data.batchCreateStream.map((item) => item.id)
            const configDataRequest = {
              id: editConfigInfo.configId,
              channelId: this.props.selectedId,
              fields: modifiedfieldData,
              version,
              streams: updatedStreamData,
              schemaId: editConfigInfo.id,
              position: editConfigInfo.position,
              name: editConfigInfo.name
            }
            this.props.updateChannelConfigGroup(configDataRequest).then(() => {
              this.setState({ isEditClicked: false, isSaveConfigLoading: false, configSchema: null })
            }, error => {
              this.setState({ isSaveConfigLoading: false })
              utilityService.handleError(error)
            })
          }
        }, error => {
          this.setState({ isSaveConfigLoading: false })
          utilityService.handleError(error)
        })
      }
    }
  }

  getConfigSteam = (configData) => {
    if (configData && configData.streams && configData.streams.length) {
      configData.streams = configData.streams.map((data, index) => {
        let newFields = []
        const tempData = (data.levels || []).filter((item) => {
          return item.fields.length
        })
        tempData.map((item) => {
          newFields = [...newFields, ...item.fields]
        })
        return {
          id: data.id,
          streamSchema: {
            levels: data.levels,
            id: data.id
          },
          version: data.version,
          fields: newFields
        }
      })
    }
    const fieldData = configData.fields && configData.fields.length ? configData.fields : []
    return { fieldData, configData }
  }

  onCreateConfig = (selectedConfigData) => {
    const { fieldData, configData } = this.getConfigSteam(_.cloneDeep(selectedConfigData))
    this.setState({ isListVisible: false, configSchema: configData, isEditClicked: true, isFromAddChannel: true, fieldData, editConfigData: {} })
  }

  getConfigGroupList = (configGroups) => {
    const selectGroup = this.state.channelConfigGroup
    if (configGroups && selectGroup && configGroups.length && selectGroup.length) {
      return configGroups.filter((configItem) => {
        const tempItem = _.find(selectGroup, (item) => {
          return item.schemaId && item.schemaId.id === configItem.id
        })
        return _.isEmpty(tempItem)
      })
    } else {
      return configGroups
    }
  }

  makeConfigActive = (id) => {
    if (id) {
      this.props.updateActiveConfig(this.props.selectedId, id).then(() => { }, error => {
        utilityService.handleError(error)
      })
    }
  }

  getProgramDRM = (stream) => {
    let url, contentKey, streamType, drmEntitlementMessage
    if (stream && stream.fields && stream.streamSchema) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const expectedStreamType = isSafari ? 'HLS' : 'DASH'
      url = stream.url
      const contentKeyField = stream.fields.find(item => item.name === 'Content Key')
      const drmEntitlementMessageField = stream.fields.find(item => item.name === 'DRM Entitlement Message')

      if (contentKeyField) {
        contentKey = contentKeyField.value
      }

      if (drmEntitlementMessageField) {
        drmEntitlementMessage = drmEntitlementMessageField.value
      }
      if (stream.streamSchema.levels && stream.streamSchema.levels.length) {
        const isStreamPresent = stream.streamSchema.levels.findIndex(item => item.name === expectedStreamType)
        if (isStreamPresent > -1) {
          streamType = expectedStreamType
        }
      }
    } else {
      message.error(userMessages.INVALID_CHANNEL_CONFIG)
      return
    }
    if (!url || !contentKey || !streamType) {
      message.error(userMessages.INVALID_CHANNEL_CONFIG)
      return
    }
    const request = {
      url,
      contentKey,
      streamType,
      drmEntitlementMessage
    }
    if (!_.isEqual(request, this.state.streamRequest)) {
      this.setState({ streamRequest: request, isVodApiCalling: true, selectedStream: stream.id, showPlayer: true })
    } else {
      this.setState({ selectedStream: stream.id })
    }
  }

  onPlayerLoad = () => {
    this.setState({ isVodApiCalling: false })
  }

  onChangeShowPlayer = (showPlayer) => {
    this.setState({ showPlayer })
  }

  onEditChannel = () => {
    if (this.props.onEditChannel) { this.props.onEditChannel() }
  }

  isArrayEqual = (x, y) => {
    return _(x).differenceWith(y, _.isEqual).isEmpty()
  }

  getVersionChange = (versions, oldVersions) => {
    const validVersions = versions.findIndex(item => item.version) > -1
    if (validVersions) {
      if (!oldVersions) {
        return true
      } else {
        const returnBool = this.isArrayEqual(versions, oldVersions)
        return !returnBool
      }
    }
    return false
  }

  getVersion = (editConfigData, configGroupList) => {
    let shouldUpdate = false
    for (let i = 0; i < (editConfigData.streams || []).length; i++) {
      const seletectedStream = _.cloneDeep(editConfigData.streams[i])
      const versionArray = seletectedStream.streamSchema && seletectedStream.streamSchema.levels ? seletectedStream.streamSchema.levels.map(item => ({
        id: item.id,
        version: item.version
      })) : null
      const oldVersion = seletectedStream.levelVersions ? seletectedStream.levelVersions.map(item => {
        delete item.__typename
        item.id = item.streamId
        delete item.streamId
        return item
      }) : null
      const status = this.getVersionChange(versionArray, oldVersion)
      if (status) {
        shouldUpdate = true
        break
      }
    }
    const selectedConfigGroup = configGroupList.find(item => item.id === editConfigData.schemaId.id)
    if (selectedConfigGroup && selectedConfigGroup.version !== editConfigData.version) {
      shouldUpdate = true
    }
    return shouldUpdate
  }

  render () {
    const { showPlayer, activeButton, channelConfigGroup, isArchived, scrollHeight, configSchema, editConfigData, activeConfigId, isListVisible, isSaveConfigLoading, fieldData, isEditClicked, streamRequest, selectedStream, isVodApiCalling } = this.state
    const { displayArchivedList, onRestoreChannelClick, channelCode, isChannelHistory, configGroupList } = this.props
    return <AppContext.Consumer>
      {({ permissions, project }) => {
        const userPermissions = permissions['CHANNEL_MANAGER']
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
        return <React.Fragment>
          <div className='channel-config-list' ref={this.containerRef}>
            <TabPanel tabs={detailsTabs} selectedMode={activeButton} handleModeChange={this.handleModeChange} />
            {activeButton === 'details' ? <React.Fragment>
              {streamRequest
                ? <div className='player-container'>
                  <Player
                    type='channel'
                    isLinearChannel
                    assetType='LINEAR-CHANNEL'
                    videoList={[]}
                    showPlayer={showPlayer}
                    streamRequest={streamRequest}
                    isPlayerReady={this.onPlayerLoad}
                    isCollapsable
                    onChangeShowPlayer={this.onChangeShowPlayer}
                    project={project}
                  />
                </div> : null}
              <div className='config-title'>
                <div className='logo-title'><img className='channel-logo' src={this.props.channelLogo ? this.props.channelLogo : defaultImage} />
                  <div className='channel-title'>
                    {this.props.channelDetails && this.props.channelDetails.masterConfig
                      ? <div className='master-enabled'>
                        <MasterSettings bgColor='#FFF' color='#f5212d' />
                        <span>{this.props.channelDetails.masterConfig.name}</span>
                      </div> : ''}
                    {channelCode ? <div className='channel-code-container'>
                      <span>{'ID: '}</span>
                      <div className='channel-code'>
                        {channelCode}
                      </div>
                    </div> : ''}
                    {this.props.channelName}
                  </div>
                </div>
                <ButtonContainer displayTitle='Edit Channel' childComponent={<EditButton onClick={this.onEditChannel} isDisabled={isUpdateDisabled} />} />
              </div>
              <div className='config-list' style={{ height: scrollHeight }}>
                <Skeleton active avatar={false} title={false} paragraph={{ rows: 10 }} loading={this.props.configGroupLoading}>
                  <ChannelConfigAccordion
                    data={_.cloneDeep(channelConfigGroup)}
                    onEditConfig={this.onEditConfig}
                    activeConfigId={activeConfigId}
                    onReorder={this.onReorder}
                    onRemove={this.onRemoveConfig}
                    makeConfigActive={this.makeConfigActive}
                    masterConfig={this.props.channelDetails ? this.props.channelDetails.masterConfig : undefined}
                    configGroupList={configGroupList}
                    onSelectPlayer={this.getProgramDRM}
                    selectedStream={selectedStream}
                    isLoadingPlayer={isVodApiCalling}
                  />
                </Skeleton>
              </div>
            </React.Fragment> : null}
            {activeButton === 'history' ? <HistoryList filter={this.props.channelDetails.id} content='CHANNEL_MANAGER' showFilter={false} selectedBucketGroup={this.props.settingsId} isChanging={isChannelHistory} project={project} /> : null}
          </div>
          <div className={`${activeButton === 'details' ? 'channel-config-list-footer' : 'display-hide'}`} id='channel-config-list-footer'>
            {!isArchived ? <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={this.onArchiveClick} isDisabled={displayArchivedList || isUpdateDisabled} />} /> : null}
            {isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={onRestoreChannelClick} isDisabled={isUpdateDisabled} />} /> : null}
            {this.getConfigGroupList(_.cloneDeep(this.props.configGroupList)).length ? <PopoverButton
              button={<ButtonContainer displayTitle='Add Config' childComponent={<AddButton onClick={this.toggleShowCreate} isDisabled={isCreateDisabled} />} />}
              displayParam='name'
              contents={this.getConfigGroupList(_.cloneDeep(this.props.configGroupList))}
              onContentClick={this.onCreateConfig}
              parentCompoent={'channel-config-list-footer'}
              isVisible={isListVisible}
            /> : <ButtonContainer displayTitle='Add Config' childComponent={<AddButton onClick={() => { }} isDisabled />} />}
            <ConfigGroupEditModal
              isVisible={isEditClicked}
              onSave={this.onSaveConfig}
              onCancel={this.onCancelConfig}
              configSchema={configSchema}
              configEditData={editConfigData}
              isLoading={isSaveConfigLoading}
              isVersionChange={!_.isEmpty(editConfigData) ? this.getVersion(_.cloneDeep(editConfigData), _.cloneDeep(configGroupList)) : false}
              fieldData={fieldData} />
          </div>
        </React.Fragment>
      }}
    </AppContext.Consumer>
  }
}

ChannelConfigList.propTypes = {
  configData: PropTypes.any,
  onEditChannel: PropTypes.func,
  channelName: PropTypes.string,
  channelLogo: PropTypes.string,
  configGroupList: PropTypes.arrayOf(PropTypes.object),
  onArchiveClick: PropTypes.func,
  batchUpdateChannelConfigGroup: PropTypes.func,
  selectedId: PropTypes.string,
  channelDetails: PropTypes.object,
  configGroupLoading: PropTypes.bool,
  displayArchivedList: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryGetChannelDetails,
    {
      skip: (props) => {
        return !(props.selectedId && props.selectedId.length)
      },
      options: ({ selectedId }) => {
        return {
          variables: { id: selectedId }
        }
      },
      props: (props) => {
        return {
          channelDetails: _.cloneDeep(props.data.getChannel),
          configGroupLoading: props.data.loading
        }
      }
    }
  ),
  graphql(
    MutationUpdateActiveConfig,
    {
      props: (props) => ({
        updateActiveConfig: (id, activeConfigGroup) => {
          return props.mutate({
            variables: { id, activeConfigGroup }
          })
        }
      })
    }),
  graphql(
    MutationDeleteChannelConfigGroup,
    {
      options: (props) => ({
        update: (cache, { data: { deleteChannelConfigGroup } }) => {
          const cacheData = cloneDeep(cache.readQuery({
            query: QueryGetChannelDetails,
            variables: {
              id: props.selectedId
            },
            returnPartialData: true
          }))
          if (cacheData && cacheData.getChannel && cacheData.getChannel.configGroups) {
            cacheData.getChannel.configGroups = cacheData.getChannel.configGroups.filter((item) => !(item.id === deleteChannelConfigGroup.id))
            if (!_.isEmpty(cacheData.getChannel.activeConfigGroup) && cacheData.getChannel.activeConfigGroup.id === deleteChannelConfigGroup.id) {
              cacheData.getChannel.activeConfigGroup = null
            }
            if (!_.isEmpty(cacheData.getChannel.masterConfig) && cacheData.getChannel.masterConfig.id === deleteChannelConfigGroup.id) {
              cacheData.getChannel.masterConfig = null
            }
          }
          cache.writeQuery({
            query: QueryGetChannelDetails,
            data: cacheData
          })
        }
      }),
      props: (props) => ({
        deleteChannelConfigGroup: (id, channelId) => {
          return props.mutate({
            variables: {
              id,
              channelId
            }
          })
        }
      })
    }),
  graphql(
    MutationCreateStream,
    {
      props: (props) => ({
        createStream: (contents, channelId) => {
          return props.mutate({
            variables: {
              contents,
              channelId
            }
          })
        }
      })
    }),
  graphql(
    MutationCreateChannelConfigGroup,
    {
      options: (props) => ({
        refetchQueries: () => [{ query: QueryGetChannelDetails, variables: { id: props.selectedId } }]
      }),
      props: (props) => ({
        createChannelConfigGroup: (name, schemaId, streams, channelId, position, fields, version) => {
          return props.mutate({
            variables: { name, schemaId, streams, channelId, position, fields, version }
          })
        }
      })
    }),
  graphql(
    MutationUpdateChannelConfigGroup,
    {
      options: (props) => ({
        update: (cache, { data: { updateChannelConfigGroup } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetChannelDetails, variables: { id: props.selectedId } }))
          if (cacheData && cacheData.getChannel && cacheData.getChannel.id && updateChannelConfigGroup && updateChannelConfigGroup.id) {
            const index = (cacheData.getChannel.configGroups || []).findIndex(item => item.id === updateChannelConfigGroup.id)
            if (index > -1) {
              cacheData.getChannel.configGroups[index] = updateChannelConfigGroup
              if (cacheData.getChannel.activeConfigGroup && cacheData.getChannel.activeConfigGroup.id === updateChannelConfigGroup.id) {
                cacheData.getChannel.activeConfigGroup.streams = updateChannelConfigGroup.streams
              }
            }
          }
          cache.writeQuery({
            query: QueryGetChannelDetails,
            data: cacheData,
            variables: { id: props.selectedId }
          })
        }
      }),
      props: (props) => ({
        updateChannelConfigGroup: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    MutationUpdateStream,
    {
      props: (props) => ({
        updateStream: (contents, channelId, configGroupName) => {
          return props.mutate({
            variables: {
              contents,
              channelId,
              configGroupName
            }
          })
        }
      })
    }),
  graphql(
    BatchUpdateChannelConfigGroup,
    {
      props: (props) => ({
        batchUpdateChannelConfigGroup: (contents, channelId) => {
          return props.mutate({
            variables: {
              contents,
              channelId
            }
          })
        }
      })
    }),
  graphql(
    QueryListConfigGroups,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let configGroupList = data.listConfigGroupSchemas ? [...data.listConfigGroupSchemas.items] : []
        return {
          configGroupList
        }
      }
    }
  ),
  graphql(
    QueryListChannelStreams,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let channelStreamList = data.listStreamLevels ? [...data.listStreamLevels.items] : []
        return {
          channelStreamList
        }
      }
    }
  )
)(ChannelConfigList))
