import React from 'react'
import ReactJwPlayer from 'react-jw-player'
import ReactBitmovinPlayer from './bitmovin/bitmovinComponent'
import { JWPlayerScript, isJWPlayer } from '../config/playerConfig'
import PlayerUtils from './Utils/playerUtils'

/**
 * This chooses the player to play the video according to the flag isJWPlayer
 * @param {*} props contains the playlist and callbacks for the player
 * @returns chosen Player Component [JW/Bitmovin]
 */
const JSPlayer = props => {
  const {
    playerId,
    playlist,
    isAutoPlay,
    customProps,
    isEditable,
    isLive,
    defaultTrimStart,
    broadCastStartTime,
    playTime,
    showPlayer,
    onTogglePlayer,
    onResume,
    playerStartTime
  } = props
  const { getPlayerCallbacks } = PlayerUtils
  const callbacks = getPlayerCallbacks(props)

  return (
    <>
      {isJWPlayer ? (
        <ReactJwPlayer
          playerId={playerId}
          playlist={playlist}
          onError={callbacks.Error}
          onSetupError={callbacks.onSetupError}
          isAutoPlay={isAutoPlay}
          onResume={onResume}
          onPlay={callbacks.Play}
          onPause={callbacks.Paused}
          onAutoStart={callbacks.onAutoStart}
          onReady={callbacks.Ready}
          onVideoLoad={callbacks.SourceLoaded}
          onOneHundredPercent={callbacks.PlaybackFinished}
          playerScript={JWPlayerScript}
          customProps={customProps}
        />
      ) : (
        <ReactBitmovinPlayer
          playerStartTime={playerStartTime}
          playerId={playerId}
          playlist={playlist}
          isAutoPlay={isAutoPlay}
          playerCallbacks={callbacks}
          customProps={customProps}
          isEditable={isEditable}
          isLive={isLive}
          defaultTrimStart={defaultTrimStart}
          broadCastStartTime={broadCastStartTime}
          playTime={playTime}
          showPlayer={showPlayer}
          onTogglePlayer={onTogglePlayer}
        />
      )}
    </>
  )
}

export default JSPlayer
