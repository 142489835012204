import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'

const onChange = (handleChange, event) => {
  const value = event.target.value
  if (!value.startsWith(' ')) { handleChange(event) }
}

const Input = ({ handleChange, value, placeholder, title, inputName, isEditable, isLimited, limit, isError, onBlur, onFocus, errorMessage, isRequired, readOnly, type, isOptional, noLabel, warrningMessage, mode, ...props }) => (
  <div className={`input-field ${isError ? 'error' : ''}${readOnly ? ' readOnly' : ''}`}>
    {noLabel ? null : <label>{ title }</label>}{(isRequired || isOptional) ? <span className={`mandatory${isOptional ? ' optional' : ''}`}>*</span> : ''}
    { isLimited && !mode ? <label className='limit'><span>Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
    { isLimited && mode === 'page' ? <label className='limit'><span>Character Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
    <input name={inputName} value={value || ''} onChange={onChange.bind(this, handleChange)} placeholder={placeholder} maxLength={limit} onBlur={onBlur} onFocus={onFocus} autoComplete='off'
      readOnly={readOnly} type={type} {...props} />
    {isError ? <p>{ errorMessage } </p> : null }
    {(warrningMessage && value && value.length > 0) ? <div className='warrning-message'>{ warrningMessage } </div> : null }

  </div>
)

Input.propTypes = {
  /** Change action of Input. */
  handleChange: PropTypes.func,
  /** on focus action of Input. */
  onFocus: PropTypes.func,
  /** out of focus action of Input. */
  onBlur: PropTypes.func,
  /** display value of Input. */
  value: PropTypes.string,
  /** placeholder of Input. */
  placeholder: PropTypes.string,
  /** title of Input. */
  title: PropTypes.any.isRequired,
  /** inut field name of Input. */
  inputName: PropTypes.string,
  /** editable status of Input. */
  isEditable: PropTypes.bool,
  /** error field status of Input. */
  isError: PropTypes.bool,
  /** limited input field status of Input. */
  isLimited: PropTypes.bool,
  /** string limit of Input. */
  limit: PropTypes.number,
  /** requierd field or not */
  isRequired: PropTypes.bool,
  /** Boolean value for readonly props */
  readOnly: PropTypes.bool,
  /** Type of input  */
  type: PropTypes.string
}

Input.defaultProps = {
  isEditable: true,
  readOnly: false,
  type: 'text'
}

export default Input
