import gql from 'graphql-tag'

export default gql(`
    mutation createAspectRatio( $title: String! $cropPosition: CROP_POSITION $ratio: Float! $name: String ) {
        createAspectRatio(
            input: {
                title: $title
                cropPosition: $cropPosition
                ratio: $ratio
                name: $name
            }
        ) {
            name
            title
            ratio
            aspectRatio
            cropPosition
            resolutions{
                id
                width
                height
            }
        }
    }`
)
