import React from 'react'
import PropTypes from 'prop-types'
import GroupUserIcon from './../icons/GroupUserIcon'
import './../../ui.style.scss'

const GroupUserButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button group-user ${isDisabled ? 'disabled' : ''}`} onClick={onClick} >
    <GroupUserIcon />
  </div>
)

GroupUserButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

GroupUserButton.defaultProps = {
  isDisabled: false
}

export default GroupUserButton
