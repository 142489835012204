import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $channelId: ID $startTime: AWSDateTime! $endTime: AWSDateTime ) {
            updateProgram(input:{
                id: $id
                channel: $channelId,
                broadCastStartTime: $startTime
                broadCastEndTime: $endTime
                source:"MANUAL"
                isFirstRun:false
                isLiveTelecast:false
              }) {
                id
                source
                isLiveTelecast
                channel{
                    id
                    name
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                    masterConfig{
                        id
                    }
                    configGroups{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    activeConfigGroup{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    defaultTrimStart
                    defaultTrimEnd
                }
                broadCastStartTime
                broadCastEndTime
        }
    }`
)
