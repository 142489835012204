import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'

import './../../ui.style.scss'

class ExportCsvHyperionModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {

    }
    this.columns = [
      {
        title: 'Asset Name',
        dataIndex: 'title',
        width: '40%',
        key: 'title'
      },
      {
        title: 'Asset ID',
        dataIndex: 'id',
        width: '40%',
        key: 'id',
        align: 'right'
      }
    ]
  }

  render () {
    const { isVisible, handleCancel, handleSubmit, selectedIds, assetList, exportCsvLoading } = this.props
    const selectedCsvTable = assetList.filter(asset => selectedIds.includes(asset.id))

    return (
      <Modal
        className='general-modal export-csv-modal'
        visible={isVisible}
        closable={false}
        title={'Export CSV'}
        okButtonProps={{ loading: exportCsvLoading }}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText={'Confirm'}
        cancelText='Cancel'
        width='890px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <p>Would u like to export all these assets ?</p>
        <Table rowKey={record => record.id} columns={this.columns} dataSource={selectedCsvTable} pagination={false} showHeader scroll={{ x: 500, y: (360) }} />
      </Modal>
    )
  }
}

ExportCsvHyperionModal.propTypes = {
  /** boolean to handle visiblity of modal */
  isVisible: PropTypes.bool,
  /** function to handle modal cancel */
  handleCancel: PropTypes.func,
  /** function to handle modal submit */
  handleSubmit: PropTypes.func,
  /** array of assetList */
  assetList: PropTypes.array,
  /** array of select asset id */
  selectedIds: PropTypes.array,
  /** boolean to handle submit loading */
  exportCsvLoading: PropTypes.bool
}

export default ExportCsvHyperionModal
