import gql from 'graphql-tag'

export default gql(`
    mutation($imageContent: [AssetImageInput] $project: String) {
        addAssetImages(
            input: $imageContent
            project: $project
        ) {
            id
            assetId
            mediaId
            isDefault
            fileName
            createdAt
            name
            type{
                name
                id
            }
            fileSize
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions{
                x1
                x2
                y1
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
        }
    }`
)
