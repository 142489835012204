import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import './../../ui.style.scss'

const Option = Select.Option

class SearchDropDown extends React.Component {
    state = {
      value: undefined
    }

    onOptionSelect = (id) => {
      const selectedOption = this.props.options.find(item => item.id === id)
      if (this.props.onSelect) { this.props.onSelect(selectedOption) }
    }

    onOptionSearch = (value) => {
      if (this.props.onSearch) { this.props.onSearch(value) }
    }

    render () {
      const { options, selectedOption, parentCompoent, placeHolder, dropdownRender, displayParam, valueParam } = this.props

      const optionComponents = options.map((option, index) => (
        <Option key={option.id || index} value={option[ valueParam || displayParam ]}> { option[ displayParam ] } </Option>
      ))

      return (

        <div className='category-drop-down'>
          <Select dropdownRender={dropdownRender}
            value={selectedOption ? selectedOption[ valueParam || displayParam ] : undefined}
            placeholder={placeHolder}
            showSearch={this.props.hasOwnProperty('showSearch') ? this.props.showSearch : true}
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onChange={this.onOptionSelect}
            onSearch={this.onOptionSearch}
            filterOption={false}
          >
            { optionComponents }
          </Select>
        </div>
      )
    }
}

SearchDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Parent container for the dropdown. */
  parentCompoent: PropTypes.string.isRequired,
  /** Parent container for the dropdown. */
  showSearch: PropTypes.bool
}

SearchDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default SearchDropDown
