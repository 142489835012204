import * as React from 'react'
import DropDown from '../../components/ui/dataEntry/inputs/DropDown'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

class MonitorManagerHeader extends React.Component {
  render () {
    const { monitorDashboardList, onDashboardSelect, selectedDashboard, monitorTypeList, selectedDashboardType, onDashboardTypeSelect } = this.props
    return <div className='monitor-manager-header' id='monitor-manager-header' key={uuidv4()}>
      <div className='monitor-header-left' key={uuidv4()}>
        <DropDown
          options={monitorDashboardList || []}
          selectedValue={selectedDashboard}
          placeHolder='Select Dashboard'
          valueParam='name'
          displayParam='name'
          onOptionSelect={onDashboardSelect}
          className={(selectedDashboard || selectedDashboard === null) ? 'selected' : ''}
        />

        <DropDown
          options={monitorTypeList || []}
          selectedValue={selectedDashboardType}
          placeHolder='Select Type'
          valueParam='name'
          displayParam='name'
          onOptionSelect={onDashboardTypeSelect}
          className={(selectedDashboardType || selectedDashboardType === null) ? 'selected' : ''}
        />

      </div>

    </div>
  }
}

MonitorManagerHeader.propTypes = {
  /** List of groups for monitor categorization */
  groupByList: PropTypes.array
}

MonitorManagerHeader.defaultProps = {
  groupByList: [],
  timeList: []
}

export default withRouter(MonitorManagerHeader)
