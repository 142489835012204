import React from 'react'
import PropTypes from 'prop-types'

const SearchIcon = ({ width, height, isDisabled, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 1h20v20H0z' />
      <g>
        <path fill='#FFF' d='M0 1h7.5v5H0zM0 8h7.5v5H0zM0 16h20v5H0z' />
        <circle cx='11' cy='7' r='7' fill={isDisabled ? '#AFAFAF' : '#343A40'} />
      </g>
      <path fill='#FFF' fillRule='nonzero' d='M13.385 6.846v.462a.222.222 0 0 1-.069.162.222.222 0 0 1-.162.068h-1.616v1.616a.222.222 0 0 1-.068.162.222.222 0 0 1-.162.069h-.462a.222.222 0 0 1-.162-.069.222.222 0 0 1-.069-.162V7.538H9a.222.222 0 0 1-.162-.068.222.222 0 0 1-.069-.162v-.462c0-.062.023-.116.069-.162A.222.222 0 0 1 9 6.615h1.615V5c0-.063.023-.117.069-.162a.222.222 0 0 1 .162-.069h.462c.062 0 .116.023.162.069.046.045.068.1.068.162v1.615h1.616c.062 0 .116.023.162.069.046.046.069.1.069.162zm.923.23c0-.888-.316-1.65-.949-2.282a3.111 3.111 0 0 0-2.282-.948c-.89 0-1.65.316-2.283.948a3.111 3.111 0 0 0-.948 2.283c0 .89.316 1.65.948 2.282a3.111 3.111 0 0 0 2.283.949c.89 0 1.65-.316 2.282-.949a3.111 3.111 0 0 0 .949-2.282zm3.692 6a.89.89 0 0 1-.27.654.89.89 0 0 1-.653.27.857.857 0 0 1-.65-.274l-2.473-2.466c-.86.596-1.82.894-2.877.894a4.989 4.989 0 0 1-1.972-.4 5.076 5.076 0 0 1-1.623-1.082A5.076 5.076 0 0 1 6.4 9.049 4.989 4.989 0 0 1 6 7.077c0-.688.133-1.345.4-1.972a5.076 5.076 0 0 1 1.082-1.623A5.076 5.076 0 0 1 9.105 2.4a4.989 4.989 0 0 1 1.972-.4c.687 0 1.345.133 1.972.4a5.076 5.076 0 0 1 1.623 1.082c.454.454.815.995 1.082 1.623.266.627.4 1.284.4 1.972a4.938 4.938 0 0 1-.894 2.877l2.473 2.474a.883.883 0 0 1 .267.649z' />
    </g>
  </svg>
)

SearchIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  isDisabled: PropTypes.bool
}

SearchIcon.defaultProps = {
  width: 20,
  height: 21
}

export default SearchIcon
