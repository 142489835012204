import gql from 'graphql-tag'

export default gql(`
    mutation createUser($accessRoleGroups: [ID] $id: String $name: String $project: String) {
        createUser(
            input: {
                id: $id
                name: $name
                accessRoleGroups: $accessRoleGroups
            }
            project: $project
        ){
            id
            status
            name
            enabled
            status
            lastLogin
            accessRoleGroups {
              id
              name
              modules{
                name
                permissions
              }
            }
        }
    }
`)
