import gql from 'graphql-tag'

export default gql(`
query searchOffersQuery($filter: ListOfferInput, $offset: Int, $limit: Int, $orderBy: String, $order: String, $search: SearchInput, $project: String ) {
  listOffers(
      filter: $filter,
      limit: $limit,
      offset: $offset,
      orderBy: $orderBy,
      order: $order,
      search: $search,
      project: $project
    ) {
      items {
        id
        externalId
        name
        shortDescription
        partner{
            id,
            name,
            logo{
              id
              fileName
              createdAt
              name
              type
              fileSize
              duration
              thumbnails
              tags{
                  key
                  name
                  type
              }
              category{
                  id
                  label
                  type
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
              }
              dimensions{
                  x1
                  x2
                  y1
                  y2
              }
              aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                  key
                  width
                  height
                  url
                  }
              }
              drmContent
              streams{
                  id
                  drm
                  codec
                  Url
                  iv
                  transport
                  contentKey
              }
              vodStatus{
                  id
                  startTime
                  endTime
                  channel{
                      id
                      icon{
                          id
                          fileName
                          aspectRatio {
                              aspectRatio
                              ratio
                              title          
                              resolutions {
                              url
                              }
                          }
                          settings {
                              aspectRatio
                              outputFormat
                              fileName
                              x1
                              y1
                              x2
                              y2
                          }
                      }
                  }
              }
          }
        },
        }
      totalCount
    }
}`)
