import gql from 'graphql-tag'

export default gql(`
    mutation deleteConfigInstance($id: ID! $project: String $titleField:String $app: ID! $configId:ID!) {
        deleteConfigInstance(
          id: $id
          project: $project
          titleField: $titleField
          app: $app
          configId: $configId
        ) {
          id
          fieldValues {
            configField {
              id
              name
              type
              isRequired
              project
              useAsTitle
            }
            value
            bucket {
              project
              id
              isArchived
              name
              isPublished
              publishStartDate
              publishEndDate
              updatedAt
              updatedBy
              key
              displayName
              displayType{
                name
                id
              }
            }
            image {
              id
              
              fileName
              aspectRatio {
                  aspectRatio
                  ratio
                  title          
                  resolutions {
                    url
                  }
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2          
              }
          }
            project
            position
          }
          isEnabled
          position
          project
          appConfigId
          createdAt
          createdBy
        }
    }`
)
