import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { message } from 'antd'

import LoadingButton from '../../general/buttons/LoadingButton'
import { utilityService } from '../../../../services/UtilityService'
import AttributeFieldComponent from './../../dataDisplay/AttributeFieldComponent'
import InformationIcon from './../../general/icons/InformationIcon'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateAttribute from '../../../../graphQL/partner/addOfferAttribute'
import MutationDeleteAttribute from '../../../../graphQL/partner/deleteOfferAttribute'
import MutationUpdateAttributes from '../../../../graphQL/partner/updateOfferAttribute'

const attributes = [
  {
    id: '1',
    name: 'BOOLEAN'
  },
  {
    id: '2',
    name: 'STRING'
  },
  {
    id: '3',
    name: 'NUMBER'
  }
]

class CreatePartnerAttributes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      attributeData: props.attributeData ? _.sortBy(_.cloneDeep(props.attributeData), 'position') : [],
      attributeSearch: null,
      duplicateAttributes: []
    }
    this.tempIdArr = []
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.attributeData, this.props.attributeData)) {
      this.setState({
        attributeData: _.sortBy(_.cloneDeep(newProps.attributeData), 'position')
      })
    }
  }

  handleAttributeSelect = (selectedAttribute, index) => {
    if (selectedAttribute) {
      const { attributeData } = this.state
      // const { partnerId } = this.props
      attributeData[index].type = selectedAttribute.name
      let array = []
      let duplicateExists = false
      attributeData.forEach(attribute => {
        if (attribute) {
          const duplicateData = array.find(item => {
            if (item) {
              return attribute.name === item.name && attribute.name
            }
          })
          if (!_.isEmpty(duplicateData)) {
            duplicateExists = true
            message.error('Attribute Name already exists. Please Enter a different Attribute Name')
          } else {
            array.push(attribute)
          }
        }
      })
      //   let uniqueAttributeData = _.uniqBy(attributeData, 'name')
      // if (uniqueAttributeData.length !== attributeData.length) {
      //   message.error('Duplicate Name')
      //   return
      // }
      if (!duplicateExists) {
        // if (attributeData[index].label && attributeData[index].name && this.tempIdArr.includes(attributeData[index].id)) {
        //   const variables = {
        //     partnerId,
        //     label: attributeData[index].label,
        //     name: attributeData[index].name,
        //     type: attributeData[index].type
        //   }
        //   this.props.addOfferAttribute(variables).then(({ data }) => {
        //     const { attributeData } = this.state
        //     const id = data && data.addOfferAttribute && data.addOfferAttribute[0].id
        //     attributeData[index].id = id
        //     const tempIndex = this.tempIdArr.findIndex(item => item === id)
        //     if (tempIndex > -1) { this.tempIdArr.splice(tempIndex, 1) }
        //     this.setState({ attributeData })
        //     // this.props.onChangeAttributes(attributeData)
        //   })
        // }
        this.setState({ attributeData })
        // this.props.onChangeAttributes(attributeData)
      } else {
        const { attributeData } = this.state
        attributeData[index].type = ''
        this.setState({ attributeData })
        // this.props.onChangeAttributes(attributeData)
        return null
      }
    }
  }

  handleNameChange = (e, index) => {
    const { attributeData } = this.state
    const { value } = e.target
    attributeData[index].name = value
    this.setState({
      attributeData
    })
    // this.props.onChangeAttributes(attributeData)
  }

  handleLabelChange = (e, index) => {
    const { attributeData } = this.state
    const { value } = e.target
    attributeData[index].label = value
    this.setState({
      attributeData
    })
    // this.props.onChangeAttributes(attributeData)
  }

    reorder = (startIndex, endIndex) => {
      const { attributeData } = this.state
      const result = Array.from(attributeData)
      result[startIndex].position = endIndex
      result[endIndex].position = startIndex
      const [removed] = result.splice(startIndex, 1)
      removed.trackPosChange = true
      result.splice(endIndex, 0, removed)
      result.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({
        attributeData: result
      })
      this.props.onChangeAttributes(result)
    }

    onCloseField = (id) => {
      const { attributeData } = this.state
      const { partnerId } = this.props
      const index = attributeData.findIndex(item => item.id === id)
      const selectedAttributeName = attributeData[index] ? attributeData[index].name : ''
      const selectedAttributeType = attributeData[index] ? attributeData[index].type : ''
      if (index > -1) {
        const tempData = attributeData.splice(index, 1)
        this.setState({ attributeData })
        // this.props.onChangeAttributes(attributeData)
        if (this.tempIdArr.includes(id)) {
          const tempIndex = this.tempIdArr.findIndex(item => item === id)
          this.tempIdArr.splice(tempIndex, 1)
        } else {
          this.props.deleteOfferAttribute(id, partnerId, selectedAttributeName, selectedAttributeType).then(() => {
            this.props.refetchHistory()
          }, (errorMsg) => {
            attributeData.splice(index, 0, tempData[0])
            this.setState({ attributeData })
            utilityService.handleError(errorMsg)
          })
        }
      }
    }

    addFieldObjects = () => {
      const { attributeData } = this.state
      const id = utilityService.makeRandomString(6)
      const newFieldObject = {
        name: '',
        label: '',
        type: '',
        id,
        position: attributeData.length
      }
      attributeData.push(newFieldObject)
      this.tempIdArr.push(id)
      this.setState({
        attributeData
      })
    }

    handleAttributeSearch = (value) => {
      this.setState({
        attributeSearch: value
      })
    }

    handleNameBlur = (e, index) => {
      const { attributeData } = this.state
      // const { partnerId } = this.props
      let array = []
      let duplicateExists = false
      attributeData.forEach(attribute => {
        if (attribute) {
          const duplicateData = array.find(item => {
            if (item) {
              return attribute.name === item.name && attribute.name
            }
          })
          if (!_.isEmpty(duplicateData)) {
            duplicateExists = true
            message.error('Attribute Name already exists. Please Enter a different Attribute Name')
          } else {
            array.push(attribute)
          }
        }
      })
      //   let uniqueAttributeData = _.uniqBy(attributeData, 'name')
      // if (uniqueAttributeData.length !== attributeData.length) {
      //   message.error('Duplicate Name')
      //   return
      // }
      if (!duplicateExists) {
        if (!_.isEmpty(attributeData[index].type) && attributeData[index].label && this.tempIdArr.includes(attributeData[index].id)) {
          // const variables = {
          //   partnerId,
          //   label: attributeData[index].label,
          //   name: attributeData[index].name,
          //   type: attributeData[index].type
          // }
          this.setState({ attributeData, duplicateAttributes: [] })
          // this.props.addOfferAttribute(variables).then(({ data }) => {
          //   const { attributeData } = this.state
          //   const id = data && data.addOfferAttribute && data.addOfferAttribute[0].id
          //   attributeData[index].id = id
          //   const tempIndex = this.tempIdArr.findIndex(item => item === id)
          //   if (tempIndex > -1) { this.tempIdArr.splice(tempIndex, 1) }
          //   this.setState({ attributeData })
          //   this.props.onChangeAttributes(attributeData)
          // })
        }
      } else {
        this.setState({ duplicateAttributes: array.map(item => item.id) })
      }
    }

    handleLabelBlur = (e, index) => {
      const { attributeData } = this.state
      // const { partnerId } = this.props
      let array = []
      let duplicateExists = false
      attributeData.forEach(attribute => {
        if (attribute) {
          const duplicateData = array.find(item => {
            if (item) {
              return attribute.name === item.name && attribute.name
            }
          })
          if (!_.isEmpty(duplicateData)) {
            duplicateExists = true
            message.error('Attribute Name already exists. Please Enter a different Attribute Name')
          } else {
            array.push(attribute)
          }
        }
      })
      //   let uniqueAttributeData = _.uniqBy(attributeData, 'name')
      // if (uniqueAttributeData.length !== attributeData.length) {
      //   message.error('Duplicate Name')
      //   return
      // }
      if (!duplicateExists) {
        if (!_.isEmpty(attributeData[index].type) && attributeData[index].name && this.tempIdArr.includes(attributeData[index].id)) {
          // const variables = {
          //   partnerId,
          //   label: attributeData[index].label,
          //   name: attributeData[index].name,
          //   type: attributeData[index].type
          // }
          this.setState({ attributeData, duplicateAttributes: [] })
          // this.props.addOfferAttribute(variables).then(({ data }) => {
          //   const { attributeData } = this.state
          //   const id = data && data.addOfferAttribute && data.addOfferAttribute[0].id
          //   attributeData[index].id = id
          //   const tempIndex = this.tempIdArr.findIndex(item => item === id)
          //   if (tempIndex > -1) { this.tempIdArr.splice(tempIndex, 1) }
          //   this.setState({ attributeData })
          //   // this.props.onChangeAttributes(attributeData)
          // })
        }
      } else {
        this.setState({ duplicateAttributes: array.map(item => item.id) })
      }
    }

    onSaveAttribute = (selectedAttribute) => {
      const { attributeData } = this.state
      const { partnerId } = this.props
      const index = attributeData.findIndex(item => item.id === selectedAttribute.id)
      let array = []
      let duplicateExists = false
      attributeData.forEach(attribute => {
        if (attribute) {
          const duplicateData = array.find(item => {
            if (item) {
              return attribute.name === item.name && attribute.name
            }
          })
          if (!_.isEmpty(duplicateData)) {
            duplicateExists = true
            message.error('Attribute Name already exists. Please Enter a different Attribute Name')
          } else {
            array.push(attribute)
          }
        }
      })
      //   let uniqueAttributeData = _.uniqBy(attributeData, 'name')
      // if (uniqueAttributeData.length !== attributeData.length) {
      //   message.error('Duplicate Name')
      //   return
      // }
      if (!duplicateExists) {
        if (!_.isEmpty(attributeData[index].type) && attributeData[index].name && this.tempIdArr.includes(attributeData[index].id)) {
          const variables = {
            partnerId,
            label: attributeData[index].label,
            name: attributeData[index].name,
            type: attributeData[index].type,
            position: attributeData[index].position
          }
          this.props.addOfferAttribute(variables).then(({ data }) => {
            const { attributeData } = this.state
            const id = data && data.addOfferAttribute && data.addOfferAttribute[0].id
            attributeData[index].id = id
            const tempIndex = this.tempIdArr.findIndex(item => item === id)
            if (tempIndex > -1) { this.tempIdArr.splice(tempIndex, 1) }
            this.setState({ attributeData, duplicateAttributes: [] })
            this.props.refetchHistory()
            // this.props.onChangeAttributes(attributeData)
          }, (errorMsg) => {
            attributeData.splice(index, 1)
            this.setState({ attributeData })
            utilityService.handleError(errorMsg)
          })
        } else {
          const variables = {
            id: selectedAttribute.id,
            label: selectedAttribute.label,
            name: selectedAttribute.name,
            type: selectedAttribute.type,
            position: selectedAttribute.position
          }
          this.setState({ duplicateAttributes: [] })
          this.props.updateOfferAttribute([variables], partnerId).then(() => {
            this.props.refetchHistory()
          }, (errorMessage) => {
            utilityService.handleError(errorMessage)
          })
        }
      } else {
        this.setState({ duplicateAttributes: array.map(item => item.id) })
      }
    }

    onCancelAttribute = (attribute) => {
      const { originalOfferAttributes, attributeData } = this.props
      const index = originalOfferAttributes.findIndex(item => item.id === attribute.id)
      if (index > -1) {
        attributeData.splice(index, 1, originalOfferAttributes[index])
        this.setState({ attributeData })
      }
    }

    render () {
      const { attributeData, attributeSearch, duplicateAttributes } = this.state
      const { isUpdateDisabled, isCreateDisabled } = this.props

      return (
        <>
          <div className='create-attributes'>
            {attributeData.length > 0 ? <div className='attribute-heading'>
              <div className='group'>
                <label className='attribute-name'>Attribute Name</label>
              </div>
              <div className='group'>
                <label className='display-name'>Display Name</label>
              </div>
              <div className='group'>
                <label className='attribute-type'>Attribute Type</label>
                <InformationIcon />
              </div>

            </div> : ''}
            <AttributeFieldComponent
              attributeData={attributeData}
              onCloseField={this.onCloseField}
              reorder={this.reorder}
              handleNameChange={this.handleNameChange}
              handleLabelChange={this.handleLabelChange}
              handleAttributeSelect={this.handleAttributeSelect}
              handleAttributeSearch={this.handleAttributeSearch}
              attributeSearch={attributeSearch}
              attributes={attributes}
              duplicateAttributes={duplicateAttributes}
              handleNameBlur={this.handleNameBlur}
              handleLabelBlur={this.handleLabelBlur}
              onSaveAttribute={this.onSaveAttribute}
              onCancelAttribute={this.onCancelAttribute}
              isUpdateDisabled={isUpdateDisabled}
            />
            <LoadingButton
              type='primary'
              onClick={this.addFieldObjects}
              htmlType='submit'
              buttonText={'Add Attributes'}
              buttonClass={attributeData.length > 0 ? 'create-attribute-btn' : ''}
              isLoading={false}
              isDisabled={isCreateDisabled}
            />
          </div>
      </>

      )
    }
}

CreatePartnerAttributes.propTypes = {
  /** Partner Id */
  partnerId: PropTypes.string,
  /** attribute data */
  attributeData: PropTypes.array,
  /** function to handle attribute changes */
  onChangeAttributes: PropTypes.func
}

CreatePartnerAttributes.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateAttribute,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        addOfferAttribute: (input) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteAttribute,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        deleteOfferAttribute: (id, partnerId, name, type) => {
          return props.mutate({
            variables: { id, partnerId, name, type, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAttributes,
    {
      props: (props) => ({
        updateOfferAttribute: (input, partnerId) => {
          return props.mutate({
            variables: { input, partnerId, project: props.ownProps.project }
          })
        }
      })
    }
  )

)(CreatePartnerAttributes))
