import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import './../ui.style.scss'
import { generateCroppedThumbnail } from '../../../util/util'
import placeholderImage from './../../../assets/images/default-image.jpg'

class ChannelDetailCell extends React.Component {
    getDuration = () => {
      const startTime = moment(new Date(this.props.startTime))
      const endTime = moment(new Date(this.props.endTime))
      const duration = moment.duration(endTime.diff(startTime))
      const totalSeconds = Math.floor(duration.asSeconds())
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.round(totalSeconds % 3600 / 60)
      const seconds = Math.round(totalSeconds % 3600 % 60)
      return ((hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds))
    }

    getStatus = (match) => {
      if (match && match.asset && match.asset.length) {
        const gameAssets = match.asset.filter(item => item.type === 'GAME' && item.vodStatus && !item.isArchived)
        if (gameAssets && gameAssets.length) {
          const status = gameAssets[0].vodStatus ? gameAssets[0].vodStatus.status : null
          return status
        }
      }
      if (match.asset && match.asset.length) {
        const gameAssets = match.asset.filter(item => item.type === 'GAME' && item.program && !item.isArchived)
        if (gameAssets && gameAssets.length) {
          const currentDate = moment()
          const startDate = moment(gameAssets[0].program.broadCastStartTime)
          const endDate = moment(gameAssets[0].program.broadCastEndTime)
          let isLive = gameAssets[0].program.broadCastEndTime ? (currentDate.isBetween(startDate, endDate) || startDate.format('DD MM YYYY HH:mm') === currentDate.format('DD MM YYYY HH:mm'))
            : moment(currentDate).isSameOrAfter(startDate, 'minute')
          if (isLive) {
            return 'On Now'
          } else {
            const isScheduled = moment().isBefore(moment(gameAssets[0].program.broadCastStartTime))
            if (isScheduled) {
              return 'SCHEDULED'
            } else if (match && match.isLiveOnly === true) {
              return 'AUTO TRIGGER DISABLED'
            } else if (moment().isAfter(moment(gameAssets[0].program.broadCastEndTime)) && gameAssets[0].isLive === true) {
              return 'AWAITING AUTO TRIGGER'
            } else if (moment().isAfter(moment(gameAssets[0].program.broadCastEndTime).add(24, 'hours'))) {
              return ''
            }
          }
        }
      }
      if (match && match.programs[0]) {
        if (match.asset && match.asset.length) {
          const gameAssets = match.asset.filter(item => item.type === 'GAME' && item.program && !item.isArchived)
          if (gameAssets && gameAssets.length) {
            return
          }
        }
        const currentDate = moment()
        const startDate = moment(match.programs[0].broadCastStartTime)
        const endDate = moment(match.programs[0].broadCastEndTime)
        let isLive = match.programs[0].broadCastEndTime ? (currentDate.isBetween(startDate, endDate) || startDate.format('DD MM YYYY HH:mm') === currentDate.format('DD MM YYYY HH:mm'))
          : moment(currentDate).isSameOrAfter(startDate, 'minute')
        if (isLive) {
          return 'On Now'
        } else {
          const isScheduled = moment().isBefore(moment(match.programs[0].broadCastStartTime))
          if (isScheduled) {
            return 'SCHEDULED'
          }
        }
      }
    }

    render () {
      const { imageUrl, channel, startTime, endTime, match } = this.props
      const duration = startTime && endTime ? this.getDuration() : null
      const status = this.getStatus(match)
      // const isScheduled = moment().isBefore(moment(startTime))
      const image = imageUrl ? generateCroppedThumbnail(imageUrl, 80, 80, '1:1') : placeholderImage
      return (
        channel ? <div className='channel-detail-cell'>
          <div className='channel-thumb' style={{ backgroundImage: `url(${image})` }} />
          <div className='details'>
            <span>{channel.name}</span>
            <span>{channel.activeConfigGroup ? (channel.activeConfigGroup.name).toUpperCase() : null }</span>
            <div className='bottom'>
              <label>Duration:</label>
              <span>{` ${duration}`}</span>
            </div>
          </div>
          <div className='right'>
            <div className='status'>
              <p className={status === 'On Now' ? 'ERROR' : status}>
                {status || null}
              </p>
              {status === 'On Now' ? <div><span className=''>LIVE</span></div> : null}
            </div>
            {/* <div className='channel-switch'>
                <span>Switch Channel</span>
            </div> */}
          </div>

        </div> : null
      )
    }
}

ChannelDetailCell.propTypes = {

  /** Asset image url of ChannelDetailCell. */
  imageUrl: PropTypes.string
}

ChannelDetailCell.defaultProps = {
}

export default ChannelDetailCell
