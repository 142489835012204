import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Row, Col, message } from 'antd'
import { DragDropContext } from '@hello-pangea/dnd'

import moment from 'moment'

import AppContext from '../../AppContext'
import AppDetails from './AppDetails'
import AppRightSideBar from './AppRightSideBar'
import { utilityService } from '../../services/UtilityService'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import SchedulePageModal from '../../components/ui/feedback/SchedulePageModal'
import { multiDragAwareReorder } from '../../components/ui/dataDisplay/utils'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetGroupList from '../../graphQL/appManager/listGroups'
import QueryGetAppList from '../../graphQL/appManager/listApps'
import QueryGetAppConfig from '../../graphQL/appManager/getAppConfig'
import QueryGetUpdatedAppConfig from './../../graphQL/appManager/getUpdatedAppConfig'
import QueryGetAppConfigList from '../../graphQL/appManager/listAppConfigs'
import QueryGetAppPageList from '../../graphQL/appManager/listAppPages'
import MutationAddConfigInstance from '../../graphQL/appManager/addConfigInstance'
import MutationEditConfigInstance from '../../graphQL/appManager/editConfigInstance'
import QueryGetAppPage from '../../graphQL/appManager/getAppPage'
import QueryGetUpdatedAppPage from './../../graphQL/appManager/getUpdatedPageDetails'
import QueryGetUpdatedPageGroupDetail from './../../graphQL/appManager/getUpdatedPageGroupDetail'
import MutationDeletePageLayoutItem from '../../graphQL/appManager/deletePageLayoutItem'
import MutationPublishPage from '../../graphQL/appManager/publishAppPage'
import MutationBatchEditPageLayoutItem from '../../graphQL/appManager/batchEditPageLayoutItem'
import MutationUpdatePage from '../../graphQL/appManager/editAppPage'
import MutationAddPageVersion from '../../graphQL/appManager/addPageVersion'
import MutationEditPageVersion from '../../graphQL/appManager/editPageVersion'
import MutationDeletePageVersion from '../../graphQL/appManager/deletePageVersion'
import QueryCustomMetaFields from './../../graphQL/admin/customMetaFields/listCustomMetaFields'

const fieldTypeList = [
  {
    id: 'String',
    name: 'String',
    type: 'STRING',
    title: true,
    value: ''
  },
  {
    id: 'Bucket',
    name: 'Bucket',
    type: 'BUCKET',
    value: ''
  },
  {
    id: 'Json',
    name: 'JSON',
    type: 'JSON',
    value: ''
  },
  {
    id: 'Number',
    name: 'Number',
    type: 'NUMBER',
    value: ''
  },
  {
    id: 'Image',
    name: 'Image',
    type: 'IMAGE',
    value: ''
  },
  {
    id: 'Boolean',
    name: 'Boolean',
    type: 'BOOLEAN',
    value: ''
  },
  {
    id: 'Rich Text',
    name: 'Rich Text',
    type: 'RICHTEXT',
    value: ''
  }
]

const typeList = [
  {
    id: 'CONFIG',
    name: 'Single'
  },
  {
    id: 'CONFIG_LIST',
    name: 'List'
  }]
class AppEditor extends Component {
  constructor (props) {
    super(props)

    this.state = {
      configDetails: null,
      showObjectModal: false,
      details: null,
      pageDetails: null,
      isLoading: true,
      isConfigListInstanceEdited: false,
      editConfigInstance: null,
      isConfigObjectSaveLoading: false,
      isConfigObjectSaveDisable: true,
      singleConfigDetails: null,
      isConfigSingleInstanceEdited: false,
      isConfigSingleInstanctSaveLoading: false,
      shouldRefreshHistory: false,
      isConfigListEmpty: true,
      isJsonValid: true,
      showSwitchWarning: false,
      isPageLoading: false,
      sourceIndex: undefined,
      destinationIndex: undefined,
      droppableId: undefined,
      draggableId: undefined,
      isPagSaveDisable: true,
      selectedWidget: null,
      showWidgetPinWarning: false,
      isWidgetLoading: false,
      isPageSaveLoading: false,
      isPagePublishEdited: false,
      isPublishLoading: false,
      showPagePublishModal: false,
      isBulkWidgetEdit: false,
      rightBarMode: 'widgets',
      isUnPublishLoading: false,
      addPageLayoutItem: false, // to stop the bulksave during add widget,
      widgetSearchValue: undefined,
      showPageScheduleModal: false,
      editPageSchedule: false,
      editedPageVersion: null,
      currentPageVersion: {},
      isPageScheduleEdited: false,
      showPageVersionDeleteWarning: false,
      selectedDeletedPageVersion: null,
      isPageVersionDeleteLoading: false,
      isScheduleLoading: false,
      widgetSettingSearchValue: undefined,
      widgetSettingFilter: {},
      currentPageVersionId: null,
      newAddedLayoutItems: [],
      pageReorderLoading: false,
      isJsonArrayValid: true,
      jsonInvalidEntries: [],
      jsonArrayInvalidEntries: [],
      showPageVersionSwitchWarning: false,
      tempVersion: '',
      isJsonArrayVisible: false,
      jsonConfig: null,
      jsonSelectedMeta: null,
      showPageVersionScheduleWarning: false,
      isPageModified: false,
      lastPageUpdatedUser: '',
      pageModifiedConfirm: false,
      isConfigModified: false,
      lastConfigUpdatedUser: '',
      configModifiedConfirm: false,
      showWidgetDeleteWarning: false,
      selectedIds: [],
      entities: {}
    }
    this.updatedWidgetContents = []
    this.widgetInstancePos = []
  }

  UNSAFE_componentWillReceiveProps=(newProps) => { // eslint-disable-line camelcase
    if (newProps.details && !_.isEqual(this.props.details, newProps.details) && !newProps.configIsLoading) {
      this.handleSingleConfigInstance(newProps.details)
      this.setState({ details: _.cloneDeep(newProps.details), isLoading: false })
    } else if (newProps.details && !_.isEqual(this.props.details, newProps.details)) {
      this.handleSingleConfigInstance(newProps.details)
      this.setState({ details: _.cloneDeep(newProps.details) })
    }
    if (newProps.pageDetails && this.props.pageDetails && newProps.pageDetails.id !== this.props.pageDetails.id) {
      this.setState({ newAddedLayoutItems: [] })
    }
    if (newProps.pageDetails && !_.isEqual(this.props.pageDetails, newProps.pageDetails) && !newProps.pageIsLoading) {
      if (!_.isEmpty(this.state.newAddedLayoutItems) && newProps.pageDetails.id === this.props.pageDetails.id) {
        let details = _.cloneDeep(newProps.pageDetails)
        if (details.layoutItems && details.layoutItems.length > 0) {
          details.layoutItems.push(...this.state.newAddedLayoutItems)
        } else {
          details.layoutItems = []
          details.layoutItems.push(...this.state.newAddedLayoutItems)
        }
        this.setState({ pageDetails: _.cloneDeep(details), isPageLoading: false })
      } else {
        this.setState({ pageDetails: _.cloneDeep(newProps.pageDetails), isPageLoading: false, newAddedLayoutItems: [] })
      }
      this.updatedWidgetContents = []
      this.widgetInstancePos = []
      this.setState({
        currentPageVersionId: 'current'
      })
    } else if (newProps.pageDetails && !_.isEqual(this.props.pageDetails, newProps.pageDetails)) {
      this.setState({ pageDetails: _.cloneDeep(newProps.pageDetails) })
      this.updatedWidgetContents = []
      this.widgetInstancePos = []
    }
    if (newProps.selectedPage !== this.props.selectedPage && newProps.pageIsLoading) {
      this.setState({ isPageLoading: true })
    }
    if (!newProps.configIsLoading && newProps.details && _.isEqual(this.props.details, newProps.details)) {
      this.setState({ isLoading: false })
    }
    if (!newProps.pageIsLoading && newProps.pageDetails && _.isEqual(this.props.pageDetails, newProps.pageDetails)) {
      this.setState({ isPageLoading: false })
    }
    if (!_.isEmpty(newProps.configList)) {
      this.setState({
        isConfigListEmpty: false
      })
    }
    if (newProps.forceSave && this.state.isConfigSingleInstanceEdited) {
      this.handleSingleConfigSubmit()
    }
    if (newProps.forcePageSave && !this.state.isPagSaveDisable && newProps.forcePageSave !== this.props.forcePageSave) {
      this.handlePageSubmit()
    }
    if (!newProps.configEditted && (this.state.isConfigSingleInstanceEdited || this.state.isConfigListInstanceEdited)) {
      this.setState({ isConfigListInstanceEdited: false, isConfigSingleInstanceEdited: false })
    }

    if (newProps.appMode === 'configs' && (!this.state.rightBarMode || this.state.rightBarMode === 'widgets')) {
      this.setState({
        rightBarMode: 'publish'
      })
    } else if (newProps.appMode === 'settings') {
      this.setState({ rightBarMode: 'history' })
    } else if (newProps.appMode === 'pages' && (this.props.appMode !== newProps.appMode)) {
      this.setState({
        rightBarMode: 'widgets'
      })
    }
    // to refetch configDetails if group critical flag is modified
    if (newProps.shouldRefetchConfigDetails) {
      setTimeout(() => {
        this.props.fetchAppDetails()
      }, 1500)
      this.props.handleRefetchConfigDetails(false)
    }

    // to refetch pageDetails if group critical flag is modified
    if (newProps.shouldRefetchPageDetails) {
      setTimeout(() => {
        this.props.fetchUpdatedPageGroupDetails().then((res) => {
          if (res && res.data && res.data.getAppPage) {
            let newPageDetails = _.cloneDeep(this.state.pageDetails)
            newPageDetails.group = res.data.getAppPage.group
            this.setState({ pageDetails: newPageDetails })
          }
        })
      }, 1500)
      this.props.handleRefetchPageDetails(false)
    }
  }

  handleConfigDetailsChange = (configData, value) => {
    const { details, jsonSelectedMeta } = this.state
    const { configDetails } = this.state
    if (configData.type === 'JSON') {
      let isJsonValid = this.handleJsonValidation(configData, value)
      this.setState({
        isJsonValid
      })
    }

    if (configData.type === 'JSONARRAY') {
      let isJsonArrayValid = this.handleJsonArrayValidation(configData, value)
      let duplicateJsonSelectedMeta = _.cloneDeep(jsonSelectedMeta)
      duplicateJsonSelectedMeta.value = value
      this.setState({
        isJsonArrayValid,
        jsonSelectedMeta: duplicateJsonSelectedMeta
      })
    }

    const config = (details.fields || []).map(item => {
      if (item.name === configData.name && item.type === configData.type) {
        item.value = item.type === 'STRING' ? (value || '') : item.type === 'IMAGE' ? value && value[0] : value
        delete item.isError
      } else {
        const tempItem = (configDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
      }
      return item
    })
    this.setState({ configDetails: config, isConfigObjectSaveDisable: false })
  }

  showAddObjectModal = () => {
    this.setState({
      showObjectModal: true
    })
  }

  hideAddObjectModal = () => {
    this.setState({
      showObjectModal: false,
      configDetails: null,
      isJsonValid: true,
      isConfigObjectSaveDisable: true,
      editConfigInstance: null,
      isJsonArrayValid: true,
      jsonInvalidEntries: [],
      handleJsonArrayValidation: []
    })
  }

  changeSelectedConfig=(value) => {
    const { configList } = this.props
    if (!_.isEmpty(configList) && value === 'archive') {
      const config = (configList || []).find(config => {
        if (config && config.id && !config.isArchived) {
          return config.id
        }
      })
      if (config) { this.props.onSelectConfigGroup(config.id) }
    } else if (!_.isEmpty(configList) && value === 'restore') {
      const config = (configList || []).find(config => {
        if (config && config.id && config.isArchived === true) {
          return config.id
        }
      })
      if (config) { this.props.onSelectConfigGroup(config.id) }
    }
  }

  changeSelectedPage=(value) => {
    const { pageList } = this.props
    if (!_.isEmpty(pageList) && value === 'archive') {
      const page = (pageList || []).find(page => {
        if (page && page.id && !page.isArchived) {
          return page.id
        }
      })
      if (page) { this.props.onSelectPageGroup(page.id) }
    } else if (!_.isEmpty(pageList) && value === 'restore') {
      const page = (pageList || []).find(page => {
        if (page && page.id && page.isArchived === true) {
          return page.id
        }
      })
      if (page) { this.props.onSelectPageGroup(page.id) }
    }
  }

  handleConfigObjectSave = async () => {
    const { details, configDetails, editConfigInstance, jsonInvalidEntries, jsonArrayInvalidEntries } = this.state
    const { assignedUsers } = details
    let app = details && details.app && details.app.id
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isGroupCritical = (details && details.group && details.group.isCritical) || false
    const isConfigCritical = isAppCritical || isGroupCritical || (details && details.isCritical)
    const isAssignedUser = ((details && details.assignedUsers) || []).find(user => user === this.props.userId)
    // check at FE to check if user is not APP_ADMIN and if user is not in userassigned list
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }

    if (isCriticalWriteDisabled && isAppAdminDisabled && isConfigCritical && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    if (!_.isEmpty(jsonInvalidEntries)) {
      message.error('Please enter valid JSON Value')
      this.props.updateForceSaveState(false)
      return
    }
    if (!_.isEmpty(jsonArrayInvalidEntries)) {
      message.error('Please enter valid JSON ARRAY Value')
      this.props.updateForceSaveState(false)
      return
    }
    let mandatoryMeta = []
    if ((configDetails && configDetails.length) || (details && details.fields)) {
      mandatoryMeta = (details.fields || []).filter(metaData => {
        const tempItem = (configDetails || []).find(innerItem => innerItem.name === metaData.name && metaData.type === innerItem.type)
        return _.isEmpty(tempItem) ? (metaData.isRequired && metaData.type !== 'BOOLEAN') && (metaData.value === null || metaData.value === '' || metaData.value === undefined) : (tempItem.isRequired && tempItem.type !== 'BOOLEAN') && (tempItem.value === null || tempItem.value === '' || tempItem.value === undefined)
      })
    }
    if (mandatoryMeta && mandatoryMeta.length) {
      message.error('Mandatory fields are missing')
      this.props.updateForceSaveState(false)
      const newConfigDetails = (details.fields || []).map(item => {
        const tempItem = (configDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
        if (item.isRequired && (item.value === null || item.value === '' || item.value === undefined)) {
          item.isError = true
        }
        return item
      })
      this.setState({
        isConfigObjectSaveDisable: true,
        configDetails: newConfigDetails
      })
      return
    }
    this.setState({
      isConfigObjectSaveLoading: true
    })
    const { project } = this.props

    let editConfigSubmit = (details.configList || []).find(config => {
      if (config && editConfigInstance) { return config.id === editConfigInstance.id }
    })
    // let app = (details && details.app && details.app.id) || null

    if (await this.checkForConfigModification()) {
      this.setState({
        isConfigObjectSaveLoading: false,
        editConfigInstance: null,
        isConfigObjectSaveDisable: true,
        isConfigListInstanceEdited: false,
        jsonInvalidEntries: [],
        jsonArrayInvalidEntries: []
      })
      this.hideAddObjectModal()
      return
    }

    if (editConfigSubmit) {
      let useAsTitleFieldId = null
      let fieldValues = configDetails.map(item => {
        let value = ''
        let bucket = ''
        let image = ''
        if (item.type === 'BUCKET') {
          bucket = item.value ? item.value && item.value.id : ''
        } else if (item.type === 'IMAGE') {
          image = item.value ? item.value && item.value && item.value.id : ''
        } else if (item.type === 'BOOLEAN') {
          value = item.value ? item.value : 'false'
        } else {
          value = item.value || item.value === false ? item.value : ''
        }

        if (item.useAsTitle) {
          useAsTitleFieldId = item.id
        }

        return {
          configField: item.id,
          value,
          bucket,
          image,
          project
        }
      })

      let input = {
        appConfigId: details.id,
        id: editConfigInstance.id,
        position: editConfigInstance.position,
        isEnabled: editConfigInstance.isEnabled,
        fieldValues
      }

      this.props.editConfigInstance(input, useAsTitleFieldId, app).then(() => {
        message.success('List Config Instance Updated Successfully')
        if (this.props.fetchAppDetails) {
          this.props.fetchAppDetails()
        }
        this.hideAddObjectModal()
        this.setState({
          isConfigObjectSaveLoading: false,
          editConfigInstance: null,
          isConfigObjectSaveDisable: true,
          isConfigListInstanceEdited: false,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []
        })
        this.refetchHistory()
        this.props.updateForceSaveState(true)
      }, (error) => {
        utilityService.handleError(error)
        this.hideAddObjectModal()
        this.props.updateForceSaveState(true)
        this.setState({
          isConfigObjectSaveLoading: false,
          editConfigInstance: null,
          isConfigObjectSaveDisable: true,
          isConfigListInstanceEdited: false,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []

        })
      })
    } else {
      const customListSize = _.size(details.configList)
      let useAsTitleFieldId = null
      let fieldValues = configDetails.map(item => {
        let value = ''
        let bucket = ''
        let image = ''
        if (item.type === 'BUCKET') {
          bucket = item.value ? item.value && item.value.id : ''
        } else if (item.type === 'IMAGE') {
          image = item.value ? item.value && item.value && item.value.id : ''
        } else if (item.type === 'BOOLEAN') {
          value = item.value ? item.value : 'false'
        } else {
          value = item.value || item.value === false ? item.value : ''
        }

        if (item.useAsTitle) {
          useAsTitleFieldId = item.id
        }
        return {
          configField: item.id,
          value,
          bucket,
          image,
          project
        }
      })

      let input = {
        isEnabled: false,
        position: customListSize + 1,
        fieldValues
      }
      this.props.addConfigInstance(details.id, project, input, useAsTitleFieldId, app).then(() => {
        message.success('List Config Instance Added Successfully')
        if (this.props.fetchAppDetails) {
          this.props.fetchAppDetails()
        }
        this.hideAddObjectModal()
        this.refetchHistory()
        this.setState({
          isConfigObjectSaveLoading: false,
          isConfigObjectSaveDisable: true,
          isConfigListInstanceEdited: false,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []

        })
        this.props.changeConfigEditStatus(false)
      }, (error) => {
        utilityService.handleError(error)
        this.hideAddObjectModal()
        this.setState({
          isConfigObjectSaveLoading: false,
          isConfigObjectSaveDisable: true,
          isConfigListInstanceEdited: false,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []

        })
        this.props.changeConfigEditStatus(false)
      })
    }
  }

  changeConfigListEditStatus = (status) => {
    if (this.state.isConfigListInstanceEdited !== status) {
      this.setState({ isConfigListInstanceEdited: status })
      if (status === true) {
        this.props.changeConfigEditStatus(true)
      }
    }
  }

  handleListConfigInstanceEdit = (editConfigInstance) => {
    let fieldValues = editConfigInstance && editConfigInstance.fieldValues
    // let isJsonArrayValid = false
    let configDetails = (fieldValues || []).map(field => {
      let value
      if (field.configField.type === 'IMAGE') {
        value = field.image ? field.image : ''
      } else if (field.configField.type === 'BUCKET') {
        value = field.bucket ? field.bucket : ''
      } else {
        value = field.value
      }

      // if (field.configField.type === 'JSONARRAY') {
      //   if (!_.isEmpty(field.value)) {
      //     isJsonArrayValid = true
      //   }
      // }

      return {
        id: field.configField.id,
        isRequired: field.configField.isRequired,
        name: field.configField.name,
        type: field.configField.type,
        useAsTitle: field.configField.useAsTitle,
        value: value
      }
    })
    this.setState({
      configDetails,
      editConfigInstance: editConfigInstance,
      showObjectModal: true
      // isJsonArrayValid: isJsonArrayValid
    })
  }

  handleSingleConfigDetailsChange = (configData, value) => {
    const { details, singleConfigDetails, jsonSelectedMeta } = this.state
    if (configData.type === 'JSON') {
      let isJsonValid = this.handleJsonValidation(configData, value)
      this.setState({
        isJsonValid
      })
    }
    if (configData.type === 'JSONARRAY') {
      let isJsonArrayValid = this.handleJsonArrayValidation(configData, value)
      let duplicateJsonSelectedMeta = _.cloneDeep(jsonSelectedMeta)
      duplicateJsonSelectedMeta.value = value
      this.setState({
        isJsonArrayValid,
        jsonSelectedMeta: duplicateJsonSelectedMeta
      })
    }
    const config = (details.fields || []).map(item => {
      if (item.name === configData.name && item.type === configData.type) {
        item.value = item.type === 'STRING' ? (value || '') : item.type === 'IMAGE' ? value && value[0] : value
        delete item.isError
      } else {
        const tempItem = (singleConfigDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
      }
      return item
    })
    this.setState({ singleConfigDetails: config, isConfigSingleInstanceEdited: true })
    this.props.changeConfigEditStatus(true)
  }

  handleSingleConfigSubmit = async () => {
    const { details, singleConfigDetails, jsonInvalidEntries, jsonArrayInvalidEntries } = this.state
    const { assignedUsers } = details
    let app = details && details.app && details.app.id
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isGroupCritical = (details && details.group && details.group.isCritical) || false
    const isConfigCritical = isAppCritical || isGroupCritical || (details && details.isCritical)
    const isAssignedUser = ((details && details.assignedUsers) || []).find(user => user === this.props.userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }

    if (isCriticalWriteDisabled && isAppAdminDisabled && isConfigCritical && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Config')
      this.props.updateForceSaveState(false)
      return
    }
    // const app = (details && details.app && details.app.id) || null
    const { project } = this.props
    if (!_.isEmpty(jsonInvalidEntries)) {
      message.error('Please enter valid JSON Value')
      this.props.updateForceSaveState(false)
      return
    }
    if (!_.isEmpty(jsonArrayInvalidEntries)) {
      message.error('Please enter valid JSON ARRAY Value')
      this.props.updateForceSaveState(false)
      return
    }
    let mandatoryMeta = []
    if ((singleConfigDetails && singleConfigDetails.length) || (details && details.fields)) {
      mandatoryMeta = (details.fields || []).filter(metaData => {
        const tempItem = (singleConfigDetails || []).find(innerItem => innerItem.name === metaData.name && metaData.type === innerItem.type)
        return _.isEmpty(tempItem) ? (metaData.isRequired && metaData.type !== 'BOOLEAN') && (metaData.value === null || metaData.value === '' || metaData.value === undefined) : (tempItem.isRequired && tempItem.type !== 'BOOLEAN') && (tempItem.value === null || tempItem.value === '' || tempItem.value === undefined)
      })
    }

    if (mandatoryMeta && mandatoryMeta.length) {
      message.error('Mandatory fields are missing')
      this.props.updateForceSaveState(false)
      const newConfigDetails = (details.fields || []).map(item => {
        const tempItem = (singleConfigDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
        if (item.isRequired && (item.value === null || item.value === '' || item.value === undefined)) {
          item.isError = true
        }
        return item
      })
      this.setState({
        isConfigSingleInstanceEdited: false,
        singleConfigDetails: newConfigDetails
      })
      return
    }
    this.setState({
      isConfigSingleInstanctSaveLoading: true
    })
    if (await this.checkForConfigModification()) {
      this.setState({ isConfigSingleInstanctSaveLoading: false })
      return
    }
    let useAsTitleFieldId = null
    let fieldValues = singleConfigDetails.map(item => {
      let bucket = ''
      let image = ''
      let value = ''
      if (item.type === 'BUCKET') {
        bucket = item.value ? item.value && item.value.id : ''
      } else if (item.type === 'IMAGE') {
        image = item.value ? item.value && item.value && item.value.id : ''
      } else {
        value = item.value || item.value === false ? item.value : ''
      }
      if (useAsTitleFieldId) {
        useAsTitleFieldId = item.id
      }

      return {
        configField: item.id,
        value,
        bucket,
        image,
        project
      }
    })
    // let app = (details && details.app && details.app.id) || null
    let input = {
      isEnabled: true,
      position: 0,
      fieldValues,
      appConfigType: 'CONFIG',
      appConfigId: details.id,
      id: details && details.config ? details.config.id : ''
    }
    this.props.editConfigInstance(input, useAsTitleFieldId, app).then(() => {
      message.success('Config Updated Successfully')
      if (this.props.fetchAppDetails) {
        this.props.fetchAppDetails()
      }
      this.setState({
        isConfigSingleInstanceEdited: false,
        isConfigSingleInstanctSaveLoading: false,
        jsonInvalidEntries: [],
        jsonArrayInvalidEntries: []
      })
      this.refetchHistory()
      this.props.updateForceSaveState(true)
    }, (error) => {
      utilityService.handleError(error)
      this.setState({
        isConfigSingleInstanceEdited: false,
        isConfigSingleInstanctSaveLoading: false,
        jsonInvalidEntries: [],
        jsonArrayInvalidEntries: []
      })
      this.props.updateForceSaveState(true)
    })
  }

  handleSingleConfigInstance = (updatedDetails) => {
    let fieldValues = updatedDetails && updatedDetails.config && updatedDetails.config.fieldValues
    let singleConfigDetails = (fieldValues || []).map(field => {
      let value
      if (field.configField.type === 'IMAGE') {
        value = field.image ? field.image : ''
      } else if (field.configField.type === 'BUCKET') {
        value = field.bucket ? field.bucket : ''
      } else {
        value = field.value
      }
      return {
        id: field.configField.id,
        isRequired: field.configField.isRequired,
        name: field.configField.name,
        type: field.configField.type,
        useAsTitle: field.configField.useAsTitle,
        value: value
      }
    })
    this.setState({
      singleConfigDetails
      // isJsonArrayValid
    })
  }

  refetchHistory = () => {
    this.setState({ shouldRefreshHistory: true }, () => {
      this.setState({ shouldRefreshHistory: false })
    })
  }

  clearRefreshHistory = () => {
    this.setState({ shouldRefreshHistory: false })
  }

  isValidJson = (json, isJSONArray) => {
    try {
      if (isJSONArray && !isNaN(json)) {
        return false
      }
      JSON.parse(json)
      return true
    } catch (e) {
      return false
    }
  }

  handleListConfigImgCropInstanceEdit = (editConfigInstance) => {
    let fieldValues = editConfigInstance && editConfigInstance.fieldValues
    let configDetails = (fieldValues || []).map(field => {
      let value
      if (field.configField.type === 'IMAGE') {
        value = field.image ? field.image : ''
      } else if (field.configField.type === 'BUCKET') {
        value = field.bucket ? field.bucket : ''
      } else {
        value = field.value
      }
      return {
        id: field.configField.id,
        isRequired: field.configField.isRequired,
        name: field.configField.name,
        type: field.configField.type,
        useAsTitle: field.configField.useAsTitle,
        value: value
      }
    })
    this.setState({
      configDetails,
      editConfigInstance: editConfigInstance
    })
  }

  onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (source.droppableId === 'widget-list' && destination.droppableId.startsWith('#Page_')) {
      const id = result.draggableId.split('#Widget_list_')[0]
      const dropableId = destination.droppableId.split('#Page_')[1]
      let isPinnedWidget = this.widgetAddDeleteReorder(destination.index, true)
      if (isPinnedWidget) {

      } else {
        this.moveWidgetToPage(source.index, destination.index, dropableId, id)
      }
    } else if (source.droppableId.startsWith('#Page_') && destination.droppableId.startsWith('#Page_')) {
      const dragBucketId = source.droppableId.split('#Page_')[1]
      const dropBucketId = destination.droppableId.split('#Page_')[1]
      if (dragBucketId === dropBucketId) {
        if (source.index !== destination.index) {
          let isPinnedWidget = this.widgetReorder(source.index, destination.index, dropBucketId)
          if (isPinnedWidget) {

          } else {
            this.moveWidgetToPage(source.index, destination.index, dropBucketId, undefined)
          }
        }
      }
    }
  }

  moveWidgetToPage = (sourceIndex, destinationIndex, droppableId, draggableId) => {
    this.setState({ sourceIndex, destinationIndex, droppableId, draggableId: `${draggableId}-random${utilityService.makeRandomString(6)}` })
  }

  handlePageLayoutItems = (layoutItem) => {
    let pageListDetails = this.state.pageDetails
    let widgetItems = _.cloneDeep(pageListDetails.layoutItems)
    let newAddedLayoutItems = []
    const isLayoutItemPresent = (widgetItems || []).find((item) => item.widget && item.widget.id === layoutItem.id)
    if (_.isEmpty(isLayoutItemPresent)) {
      widgetItems = widgetItems && widgetItems.length ? widgetItems : []
      widgetItems.push(layoutItem)
      newAddedLayoutItems.push(layoutItem)
      pageListDetails.layoutItems = widgetItems
      this.props.handlePageDetails(_.cloneDeep(pageListDetails))
      this.setState({
        pageDetails: pageListDetails,
        isPagSaveDisable: false,
        addPageLayoutItem: true,
        newAddedLayoutItems: newAddedLayoutItems
      })
      this.props.changePageEditStatus(true)
    }
  }

  handlePageLayoutItemDelete = async () => {
    const { selectedWidget, pageDetails, newAddedLayoutItems } = this.state
    const { pageDetails: pageDetailsProps } = this.props
    let widgetName = selectedWidget && selectedWidget.widget && selectedWidget.widget.title
    let app = (pageDetails && pageDetails.app && pageDetails.app.id) || null
    let pageListDetails = pageDetails
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    if (pageDetails && pageDetails.assignedUsers && pageDetails.assignedUsers.length > 0 && isAppAdminDisabled) {
      const isUserAssignedToApp = ((pageDetails && pageDetails.assignedUsers) || []).find(user => user === this.props.userId)
      if (!isUserAssignedToApp) {
        message.error('You do not have permission to edit this Page, since user is not assigned to App')
        return
      }
    }
    let widgetItems = this.getSortedList(_.cloneDeep(pageDetails.layoutItems))
    widgetItems = widgetItems && widgetItems.length ? widgetItems : []
    let index = widgetItems.findIndex((item) => item.id === selectedWidget.id)
    if (selectedWidget.isPinned) {
      message.error('Please Unpin before deleting the layout Item')
      return
    }
    this.widgetAddDeleteReorder(index, false)
    this.setState({
      isWidgetLoading: true
    })
    if (this.checkForPageModification) {
      if (await this.checkForPageModification()) {
        this.setState({
          showWidgetDeleteWarning: false,
          deleteWidget: null,
          isWidgetDeleteLoading: false,
          isPagSaveDisable: false,
          newAddedLayoutItems: []

        })
        return
      }
    }

    if (!_.isEmpty(pageDetails.layoutItems)) {
      this.updateBatchPositionWidgetItems()
    }

    let pageDetailsIds = pageDetails.layoutItems && pageDetails.layoutItems.map(item => item.id)
    let pagePropsDetailsIds = pageDetailsProps.layoutItems && pageDetailsProps.layoutItems.map(item => item.id)
    let pageDetailsSize = _.size(pageDetailsIds)
    let pageDetailDiff = _.difference(pageDetailsIds, pagePropsDetailsIds)
    let pageId = (pageDetails && pageDetails.id) || null

    this.props.deletePageLayoutItem(selectedWidget.id, widgetName, app, pageId).then(() => {
      if (this.props.fetchAppPageDetails) {
        this.props.fetchAppPageDetails()
      }
      if (this.refetchHistory) {
        this.refetchHistory()
      }
      const index = (this.updatedWidgetContents || []).findIndex(item => item === selectedWidget.id)
      if (index > -1) {
        this.updatedWidgetContents.splice(index, 1)
      }
      const foundIndex = (newAddedLayoutItems || []).findIndex(item => item.id === selectedWidget.id)
      if (foundIndex > -1) {
        newAddedLayoutItems.splice(foundIndex, 1)
      }
      this.props.updatePageForceSaveState(true)
      setTimeout(() => {
        if (pageDetailsSize === 0) {
          this.props.changePageEditStatus(false)
        } else if (!_.isEmpty(pageDetailDiff)) {
          this.props.changePageEditStatus(true)
        }
      }, 500)
      this.setState({
        pageDetails: pageListDetails,
        showWidgetDeleteWarning: false,
        selectedWidget: null,
        isWidgetLoading: false,
        isPagSaveDisable: false,
        newAddedLayoutItems
      })
    }, (error) => {
      utilityService.handleError(error)
      const foundIndex = (newAddedLayoutItems || []).findIndex(item => item.id === selectedWidget.id)
      if (foundIndex > -1) {
        newAddedLayoutItems.splice(foundIndex, 1)
      }
      if (this.props.fetchAppPageDetails) {
        this.props.fetchAppPageDetails()
      }
      this.setState({
        showWidgetDeleteWarning: false,
        selectedWidget: null,
        isWidgetLoading: false,
        isPagSaveDisable: true,
        newAddedLayoutItems
      })
    })
  }

  onPagePinSelection = (item) => {
    if (this.props.pageEditted) {
      message.warn('Please Save before pinning the layoutItem.')
      return
    }
    this.setState({
      showWidgetPinWarning: true,
      selectedWidget: item
    })
  }

  onPageDeleteSelection = (item) => {
    if (this.props.pageEditted) {
      message.warn('Please Save before deleting the layoutItem.')
      return
    }
    this.setState({
      showWidgetDeleteWarning: true,
      selectedWidget: item
    })
  }

  onPageSelectionCancel = (status) => {
    this.setState({
      showWidgetPinWarning: status,
      showWidgetDeleteWarning: status,
      selectedWidget: null,
      isConfigObjectSaveLoading: false
    })
  }

  handlePageSubmit = async () => {
    const { pageDetails, isBulkWidgetEdit, addPageLayoutItem, tempVersion } = this.state
    const { assignedUsers } = pageDetails
    const app = (pageDetails && pageDetails.app && pageDetails.app.id)
    const pageId = (pageDetails && pageDetails.id) || null
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    // const isAppCritical = (pageDetails && pageDetails.app && pageDetails.app.isCritical) || false
    const isAppCritical = (pageDetails && pageDetails.app && pageDetails.app.isCritical) || false
    const isGroupCritical = (pageDetails && pageDetails.group && pageDetails.group.isCritical) || false
    const isPageCritical = isAppCritical || isGroupCritical || (pageDetails && pageDetails.isCritical)
    const isAssignedUser = ((pageDetails && pageDetails.assignedUsers) || []).find(user => user === this.props.userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Page')
      return
    }

    if (isCriticalWriteDisabled && isAppAdminDisabled && isPageCritical && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Page')
      this.props.updatePageForceSaveState(false)
      return
    }
    this.setState({ isPageSaveLoading: true })
    if (await this.checkForPageModification()) {
      this.setState({ isPageSaveLoading: false })
      return
    }
    if (isBulkWidgetEdit && !addPageLayoutItem) { // to stop the bulksave during add widget
      const updateConfigs = this.updateWidgetItems()
      this.setState({ isPageSaveLoading: true })
      this.props.batchEditPageLayoutItem(updateConfigs, app, pageId).then(() => {
        if (this.props.fetchAppPageDetails) {
        //  this.props.fetchAppPageDetails()
        }
        this.props.fetchAppUpdatedPageDetails().then(response => {
          if (response && response.data.getAppPage) {
            let updatedPageDetails = _.cloneDeep(pageDetails)
            updatedPageDetails.updatedAt = response.data.getAppPage.updatedAt
            this.setState({ lastPageUpdatedUser: response.data.getAppPage.updatedBy, pageDetails: updatedPageDetails })
          }
        })
        this.updatedWidgetContents = []
        this.widgetInstancePos = []
        if (this.refetchHistory) {
          this.refetchHistory()
        }
        this.setState({ isPageSaveLoading: false, isPagSaveDisable: true, isBulkWidgetEdit: false, newAddedLayoutItems: [], showPageVersionSwitchWarning: false, currentPageVersionId: 'current' })
        this.props.updatePageForceSaveState(true)
        this.onPageVersionSelection('current')
        message.success('Page layout updated successfully')
      }, error => {
        this.updatedWidgetContents = []
        this.widgetInstancePos = []
        this.setState({ isPageSaveLoading: false, isPagSaveDisable: true, isBulkWidgetEdit: false, newAddedLayoutItems: [], showPageVersionSwitchWarning: false, currentPageVersionId: 'current' })
        this.props.updatePageForceSaveState(false)
        utilityService.handleError(error)
      })
    } else {
      // widget add position update
      this.updateBatchPositionWidgetItems()
      const { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta } = pageDetails
      const appId = app && app.id
      const groupId = group && group.key
      const tagsArray = (tags || []).map(tag => {
        if (tag) {
          return tag.key
        }
      })
      const newLayoutItems = (layoutItems || []).map(layoutItem => {
        if (layoutItem) {
          return layoutItem.id
        }
      })

      const newCustomMeta = (customMeta || []).map(item => {
        const newItem = {
          config: '',
          value: ''
        }
        if (item.config && item.config.id) {
          newItem.config = item.config.id
          newItem.value = item.value
          newItem.name = item.config.name || ''
        }
        return newItem
      })
      this.setState({ isPageSaveLoading: true, newAddedLayoutItems: [] })
      this.props.updateAppPage(id, title, key, appId, groupId, tagsArray, shortDescription, newLayoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, newCustomMeta).then(() => {
        this.setState({ isPagSaveDisable: true, addPageLayoutItem: false, newAddedLayoutItems: [], showPageVersionSwitchWarning: false, currentPageVersionId: 'current' }, () => {
          if (this.refetchHistory) {
            this.refetchHistory()
          }
          this.props.updatePageForceSaveState(true)
          if (tempVersion) { this.onPageVersionSelection(tempVersion) }
        })
        if (this.props.fetchAppPageDetails) {
          this.props.fetchAppPageDetails()
        }

        if (!this.props.pageIsLoading) {
          this.setState({
            isPageSaveLoading: false
          })
          message.success('Page layout updated successfully')
        }
      }, error => {
        // pageDetails.isPublished = !pageDetails.isPublished
        this.setState({ isPageSaveLoading: false, isPagSaveDisable: true, addPageLayoutItem: false, newAddedLayoutItems: [], showPageVersionSwitchWarning: false, currentPageVersionId: 'current' }, () => {
          utilityService.handleError(error)
          this.props.updatePageForceSaveState(false)
        })
      })
    }
  }

  handlePagePublish = async () => {
    const { pageDetails } = this.state
    const { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta, assignedUsers } = pageDetails
    const appId = app && app.id
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAppCritical = (pageDetails && pageDetails.app && pageDetails.app.isCritical) || false
    const isGroupCritical = (pageDetails && pageDetails.group && pageDetails.group.isCritical) || false
    const isPageCritical = isAppCritical || isGroupCritical || (pageDetails && pageDetails.isCritical)
    const isAssignedUser = ((pageDetails && pageDetails.assignedUsers) || []).find(user => user === this.props.userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Page')
      return
    }
    if (isCriticalWriteDisabled && isAppAdminDisabled && isPageCritical && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Page')
      return
    }
    const groupId = group && group.key
    const tagsArray = (tags || []).map(tag => {
      if (tag) {
        return tag.key
      }
    })
    const newLayoutItems = (layoutItems || []).map(layoutItem => {
      if (layoutItem) {
        return layoutItem.id
      }
    })
    const newCustomMeta = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item.config && item.config.id) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      }
      return newItem
    })
    this.setState({ isPageSaveLoading: true, isPublishLoading: true, newAddedLayoutItems: [] })
    if (await this.checkForPageModification()) {
      this.setState({ isPublishLoading: false, isPageSaveLoading: false, newAddedLayoutItems: [], showPagePublishModal: false, isPagSaveDisable: true })
      return
    }
    this.props.publishAppPage(id, title, key, appId, groupId, tagsArray, shortDescription, newLayoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, newCustomMeta).then(() => {
      if (this.props.fetchAppPageDetails) {
        this.props.fetchAppPageDetails()
      }
      this.setState({ isPageSaveLoading: false, isPagSaveDisable: true, isPublishLoading: false, showPagePublishModal: false }, () => {
        message.success('Page Publish Settings updated')
        this.props.updatePageForceSaveState(true)
      })
    }, error => {
      // pageDetails.isPublished = !pageDetails.isPublished
      let newPageDetails = _.cloneDeep(pageDetails)
      newPageDetails.publishStartDate = (this.props.pageDetails && this.props.pageDetails.publishStartDate) || null
      newPageDetails.publishEndDate = (this.props.pageDetails && this.props.pageDetails.publishEndDate) || null
      newPageDetails.isPublished = (this.props.pageDetails && this.props.pageDetails.isPublished) || false
      // this.setState({pageDetails: newPageDetails})
      this.setState({ isPageSaveLoading: false, isPagSaveDisable: true, isPublishLoading: false, showPagePublishModal: false, pageDetails: newPageDetails }, () => {
        utilityService.handleError(error)
        this.props.updatePageForceSaveState(false)
      })
    })
  }

  onPublishAllStartDateChange = (value) => {
    let { pageDetails } = this.state
    pageDetails.publishStartDate = value ? new Date(moment(value).utc()).toISOString() : value
    if (pageDetails && pageDetails.publishStartDate && pageDetails.publishEndDate && pageDetails.isPublished && moment(pageDetails.publishStartDate).isAfter(moment(pageDetails.publishEndDate))) {
      message.error('Please enter a valid startDate')
      return
    }
    if (!value || value === null) {
      if (pageDetails.isPublished === true) {
        pageDetails.isPublished = !pageDetails.isPublished
      }
    }
    if (pageDetails.publishStartDate) {
      this.setState({ isPagePublishEdited: true })
    }

    if (pageDetails.publishStartDate === null) {
      this.setState({ isPagePublishEdited: false })
    }
    this.setState({ pageDetails })
  }

  onPublishAllEndDateChange = (value) => {
    let { pageDetails } = this.state
    pageDetails.publishEndDate = value ? new Date(moment(value).utc()).toISOString() : value
    if (pageDetails && pageDetails.publishStartDate && pageDetails.publishEndDate && pageDetails.isPublished && moment(pageDetails.publishStartDate).isAfter(moment(pageDetails.publishEndDate))) {
      message.error('Please enter a valid endDate')
      return
    }

    this.setState({ pageDetails, isPagePublishEdited: true })
  }

  onPublishAllButtonActiveChange = (value) => {
    const { pageDetails } = this.state
    if (pageDetails && !pageDetails.publishStartDate && !pageDetails.publishEndDate && value === true) {
      pageDetails.publishStartDate = moment().utc()
    }
    if (pageDetails && !pageDetails.publishStartDate && pageDetails.publishEndDate) {
      message.error('Please select a startDate before Publishing')
      return
    }
    if (pageDetails && pageDetails.publishStartDate && pageDetails.publishEndDate && moment(pageDetails.publishStartDate).isAfter(moment(pageDetails.publishEndDate))) {
      message.error('Please select a startDate before endDate')
      return
    }
    pageDetails.isPublished = value
    this.setState({ pageDetails, isPagePublishEdited: true })
  }

  togglePagePublishModal = () => {
    const { pageEditted } = this.props
    if (pageEditted) {
      message.warning('Please save before delete operation.')
      return
    }
    this.setState({ showPagePublishModal: true })
  }

  hidePagePublishModal = () => {
    let newPageDetails = _.cloneDeep(this.state.pageDetails)
    newPageDetails.publishStartDate = (this.props.pageDetails && this.props.pageDetails.publishStartDate) || null
    newPageDetails.publishEndDate = (this.props.pageDetails && this.props.pageDetails.publishEndDate) || null
    newPageDetails.isPublished = (this.props.pageDetails && this.props.pageDetails.isPublished) || false
    this.setState({ showPagePublishModal: false, pageDetails: newPageDetails })
  }

  handleLayoutItemStatus = (WidgetInstance, value) => {
    const { pageDetails } = this.state
    let pageListDetails = pageDetails
    let widgetItems = _.cloneDeep(this.state.pageDetails.layoutItems)
    if (!this.updatedWidgetContents.includes(WidgetInstance.id)) {
      this.updatedWidgetContents.push(WidgetInstance.id)
    }
    const updatedLayoutItems = (widgetItems || []).map(item => {
      if (item.id === WidgetInstance.id) {
        item.isEnabled = value
      }
      return item
    })
    pageListDetails.layoutItems = updatedLayoutItems
    this.setState({ pageDetails: pageListDetails, isBulkWidgetEdit: true, isPagSaveDisable: false }, () => {
      this.updateWidgetItems()
      this.props.changePageEditStatus(true)
    })
  }

  updateWidgetItems = () => {
    const { pageDetails } = this.state
    const { project } = this.props
    // let pageListDetails = pageDetails
    let widgetItems = _.cloneDeep(pageDetails.layoutItems)
    this.updatedWidgetContents = [...new Set(this.updatedWidgetContents)]
    const updatedWidgetIds = this.updatedWidgetContents
    const updatedWidgets = widgetItems.map(item => {
      const selectedWidgetInstanceId = (updatedWidgetIds || []).find(innerItem => innerItem === item.id)
      const selectedWidgetInstance = (widgetItems || []).find(widget => widget.id === selectedWidgetInstanceId)
      const isPosChange = this.widgetInstancePos.includes(selectedWidgetInstance && selectedWidgetInstance.id)
      let trackPosChange = isPosChange
      let filterFieldValues = _.omit(item, '__typename')
      let filerConfigField = (filterFieldValues.fieldValues || []).map(fieldValue => {
        return {
          configField: fieldValue.configField.id,
          bucket: fieldValue.bucket ? fieldValue.bucket.id : '',
          image: fieldValue.image ? fieldValue.image.id : '',
          project: fieldValue.project,
          value: fieldValue.value,
          useAsTitle: fieldValue.useAsTitle
        }
      })
      return {
        id: item.id,
        position: item.position,
        title: item.title,
        page: pageDetails.id,
        type: 'WIDGET',
        widget: item.widget.id,
        fieldValues: filerConfigField,
        project: project,
        isEnabled: item.isEnabled,
        trackPosChange: trackPosChange,
        isPinned: item.isPinned
      }
    })
    return updatedWidgets
  }

  handleModeChange = e => {
    const rightBarMode = e.target.value
    this.setState({ rightBarMode })
  }

  handlePageUnPublish = () => {
    const { pageDetails } = this.state
    const { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isArchived, publishStartDate, publishEndDate, customMeta } = pageDetails
    const appId = app && app.id
    const isPublished = false
    const groupId = group && group.key
    const tagsArray = (tags || []).map(tag => {
      if (tag) {
        return tag.key
      }
    })
    const newLayoutItems = (layoutItems || []).map(layoutItem => {
      if (layoutItem) {
        return layoutItem.id
      }
    })
    this.setState({
      isUnPublishLoading: true,
      newAddedLayoutItems: []
    })
    const newCustomMeta = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item.config && item.config.id) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      }
      return newItem
    })
    this.props.publishAppPage(id, title, key, appId, groupId, tagsArray, shortDescription, newLayoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, newCustomMeta).then(() => {
      if (this.props.fetchAppPageDetails) {
        this.props.fetchAppPageDetails()
      }
      this.setState({
        isUnPublishLoading: false
      })
      message.success('Page Unpublish successfully')
    }, error => {
      this.setState({
        isUnPublishLoading: false
      })
      utilityService.handleError(error)
    })
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  // widgetReorder = (startIndex, endIndex, droppableId) => {
  //   const { pageDetails, selectedIds, entities } = this.state
  //   let pageListDetails = pageDetails
  //   let widgetItems = this.getSortedList(pageDetails.layoutItems)
  //   let size = _.size(widgetItems)

  //   if (endIndex === 0) {
  //     if (widgetItems[0].isPinned) {
  //       message.warn('Can not move layoutItem above the pinned widget.')
  //       return true
  //     }
  //   }

  //   if (endIndex === size) {
  //     if (widgetItems[size - 1].isPinned) {
  //       message.warn('Can not move layoutItem below the pinned widget.')
  //       return true
  //     }
  //   }
  //   const result = Array.from(widgetItems)
  //   let dropItem = result[startIndex]
  //   if (dropItem.isPinned) {
  //     message.warn('Can not move pinned widget.')
  //     return true
  //   }
  //   let itemAtDrop = result[endIndex]
  //   if (itemAtDrop.isPinned) {
  //     message.warn('Can not move layoutItem at pinned widget.')
  //     return true
  //   }
  //   const [removed] = result.splice(startIndex, 1)
  //   let afterIndexNotPinned = result.slice(endIndex)
  //   afterIndexNotPinned = afterIndexNotPinned.filter(item => !item.isPinned)
  //   let afterIndexPinned = result.slice(endIndex)
  //   afterIndexPinned = afterIndexPinned.filter(item => item.isPinned)
  //   const beforeIndex = result.slice(0, endIndex)
  //   const modifiedResult = (afterIndexNotPinned || []).map((item, index, array) => {
  //     if (array[index + 1]) {
  //       if (array[index + 1].position > startIndex + 1) {
  //         item.position = startIndex + 1
  //       } else {
  //         item.position = array[index + 1].position
  //       }
  //     } else {
  //       item.position = startIndex + 1
  //     }
  //     return item
  //   })
  //   if (!widgetItems[widgetItems.length - 1].isPinned) {
  //     removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : (result[endIndex].position - 1)
  //   } else {
  //     removed.position = endIndex + 1
  //   }

  //   let finalModifedResult = [...afterIndexPinned, ...modifiedResult]
  //   finalModifedResult.splice(endIndex, 0, removed);
  //   (widgetItems || []).map(item => {
  //     if (!this.updatedWidgetContents.includes(item.id)) {
  //       this.updatedWidgetContents.push(item.id)
  //     }
  //   })
  //   this.widgetInstancePos.push(widgetItems[startIndex].id)
  //   pageListDetails.layoutItems = [...beforeIndex, ...finalModifedResult]
  //   this.setState({ pageDetails: _.cloneDeep(pageListDetails), isBulkWidgetEdit: true, isPagSaveDisable: false }, this.updateWidgetItems)
  //   this.props.changePageEditStatus(true)
  // }

  widgetReorder = (startIndex, endIndex, droppableId) => {
    const { pageDetails, selectedIds, entities } = this.state
    let pageListDetails = _.cloneDeep(pageDetails)
    let widgetItems = this.getSortedList(_.cloneDeep(pageListDetails.layoutItems))
    const newListData = widgetItems.slice(1, -1)
    const isWidgetPinned = newListData.some(item => item.isPinned)
    let size = _.size(widgetItems)

    if (endIndex === 0) {
      if (widgetItems[0].isPinned) {
        message.warn('Cannot move layoutItem above the pinned widget.')
        return true
      }
    }

    if (endIndex === size) {
      if (widgetItems[size - 1].isPinned) {
        message.warn('Cannot move layoutItem below the pinned widget.')
        return true
      }
    }
    const result = Array.from(widgetItems)
    let dropItem = result[startIndex]
    if (dropItem.isPinned) {
      message.warn('Cannot move pinned widget.')
      return true
    }
    let itemAtDrop = result[endIndex]
    if (itemAtDrop.isPinned) {
      message.warn('Cannot move layoutItem at pinned widget.')
      return true
    }
    // for Multi Drag reorder
    if (selectedIds && selectedIds.length) {
      const selectedIdsWidget = (widgetItems || []).filter(item => {
        const isFind = selectedIds.includes(item.id)
        return isFind
      })

      let isPinnedWidgetSelected = (selectedIdsWidget || []).some(item => item.isPinned)
      if (isPinnedWidgetSelected) {
        message.warn('Cannot move pinned widget.')
        return true
      }
      (selectedIds || []).forEach(id => {
        if (!this.updatedWidgetContents.includes(id)) {
          this.updatedWidgetContents.push(id)
          this.widgetInstancePos.push(id)
        }
      })
      const destination = {
        index: endIndex,
        droppableId: '#Page_' + droppableId
      }
      const source = {
        index: startIndex,
        droppableId: '#Page_' + droppableId
      }

      if (startIndex === endIndex + 1 || endIndex === startIndex + 1) {
        message.warn('MultiSelect is not allowed for this operation.')
        return
      }
      const processed = multiDragAwareReorder({
        entities,
        selectedTaskIds: selectedIds,
        source,
        destination
      })
      const selectedTaskIds = processed.entities.columns['#Page_' + droppableId].taskIds
      let newArray = []

      if (widgetItems[size - 1].isPinned) {
        selectedTaskIds.forEach((selectedId, index) => {
          for (var [key, values] of Object.entries(processed.entities.tasks)) {
            if (key === selectedId) {
              if (values.isPinned) {
                selectedTaskIds.splice(index, 1)
              }
            }
          }
        })
      }

      selectedTaskIds.forEach((selectedId, index) => {
        for (var [key, values] of Object.entries(processed.entities.tasks)) {
          if (key === selectedId) {
            values.position = index
            newArray.push(values)
          }
        }
      })

      if (widgetItems[size - 1].isPinned) {
        for (var [key, values] of Object.entries(processed.entities.tasks)) {
          if (values.isPinned && key) {
            newArray.push(values)
          }
        }
      }

      pageDetails.layoutItems = newArray
      this.setState({ pageDetails: _.cloneDeep(pageListDetails), isBulkWidgetEdit: true, isPagSaveDisable: false, selectedIds: [] }, this.updateWidgetItems)
      this.props.changePageEditStatus(true)
    } else if (isWidgetPinned) {
      const result = _.cloneDeep(widgetItems)
      const [removed] = result.splice(startIndex, 1)
      const pinnedItems = result.filter(item => item.isPinned).map(item => item.position)
      const isDecreasing = endIndex < startIndex
      const largerIndex = isDecreasing ? startIndex : endIndex
      const smallerIndex = isDecreasing ? endIndex : startIndex
      const modifiedResult = result.map((item, index) => {
        if (index >= smallerIndex && index < largerIndex) {
          let count = 1
          let index
          let total = pinnedItems.length
          let position = item.position
          while (total > 0) {
            let check = isDecreasing ? position + 1 : position - 1
            index = pinnedItems.indexOf(check)
            if (index > -1) {
              count++
            }
            position++
            total--
          }
          item.position = item.isPinned ? item.position : item.position + (isDecreasing ? count : -Math.abs(count))
        }
        return item
      })
      let newPosition = endIndex + 1
      removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : newPosition
      modifiedResult.splice(endIndex, 0, removed);
      [...modifiedResult].splice(smallerIndex, largerIndex - smallerIndex + 1).map(item => {
        if (!this.updatedWidgetContents.includes(item.id)) {
          this.updatedWidgetContents.push(item.id)
        }
      })
      this.widgetInstancePos.push(widgetItems[startIndex].id)
      pageListDetails.layoutItems = _.cloneDeep(modifiedResult)
      this.setState({ pageDetails: _.cloneDeep(pageListDetails), isBulkWidgetEdit: true, isPagSaveDisable: false, selectedIds: [] }, this.updateWidgetItems)
      this.props.changePageEditStatus(true)
    } else {
      const result = Array.from(widgetItems)
      const [removed] = result.splice(startIndex, 1)
      const isDecreasing = endIndex < startIndex
      const largerIndex = isDecreasing ? startIndex : endIndex
      const smallerIndex = isDecreasing ? endIndex : startIndex
      const modifiedResult = result.map((item, index) => {
        if (index >= smallerIndex && index < largerIndex) {
          item.position = item.position + (isDecreasing ? 1 : -1)
        }
        return item
      })
      removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : (result[endIndex].position - 1)
      modifiedResult.splice(endIndex, 0, removed);
      [...modifiedResult].splice(smallerIndex, largerIndex - smallerIndex + 1).map(item => {
        if (!this.updatedWidgetContents.includes(item.id)) {
          this.updatedWidgetContents.push(item.id)
        }
      })
      this.widgetInstancePos.push(widgetItems[startIndex].id)
      pageListDetails.layoutItems = modifiedResult
      this.setState({ pageDetails: _.cloneDeep(pageListDetails), isBulkWidgetEdit: true, isPagSaveDisable: false, selectedIds: [] }, this.updateWidgetItems)
      this.props.changePageEditStatus(true)
    }
  }

  // function to handle Widget add and delete reorder
  widgetAddDeleteReorder = (endIndex, addWidget) => {
    const { pageDetails } = this.state
    let pageListDetails = pageDetails
    let widgetItems = this.getSortedList(pageDetails.layoutItems)
    const newListData = widgetItems.slice(1, -1)
    const isWidgetPinned = newListData.some(item => item.isPinned)

    let size = _.size(widgetItems)
    if (endIndex === 0 && addWidget && !_.isEmpty(widgetItems)) {
      if (widgetItems[0].isPinned) {
        message.warn('Cannot add layoutItem above the pinned widget.')
        return true
      }
    }

    if (endIndex === size && addWidget && !_.isEmpty(widgetItems) && size !== 1) {
      if (widgetItems[size - 1].isPinned) {
        message.warn('Cannot add layoutItem below the pinned widget.')
        return true
      }
    }

    const result = Array.from(widgetItems)
    let itemAtDrop = result[endIndex]
    if (!_.isEmpty(itemAtDrop) && addWidget && itemAtDrop.isPinned) {
      if (size - 1 !== endIndex) {
        message.warn('Cannot add layoutItem at pinned widget.')
        return true
      }
    }

    if (!_.isEmpty(itemAtDrop) && addWidget && itemAtDrop.isPinned) {
      message.warn('Cannot add layoutItem at pinned widget.')
      return true
    }
    const beforeIndex = result.slice(0, endIndex)
    let afterIndex = result.slice(endIndex + 1)
    const pinnedItems = afterIndex.filter(item => item.isPinned).map(item => item.position)
    let modifiedResult = afterIndex
    if (addWidget) {
      if (isWidgetPinned && size - 1 !== endIndex) {
        afterIndex = result.slice(endIndex)
        modifiedResult = (afterIndex || []).map((item, index) => {
          let count = 1
          let findIndexd
          let total = pinnedItems.length
          let position = item.position
          while (total > 0) {
            findIndexd = pinnedItems.indexOf(position + 1)
            let currentIndexPosition = pinnedItems[findIndexd]
            if (findIndexd > -1 && currentIndexPosition !== size) {
              count++
            }
            position++
            total--
          }
          item.position = item.isPinned ? item.position : item.position + count
          return item
        })
        pageListDetails.layoutItems = [...beforeIndex, ...modifiedResult]
      } else {
        afterIndex = result.slice(endIndex)
        modifiedResult = (afterIndex || []).map((item, index) => {
          item.position = item.position + 1
          return item
        })
        pageListDetails.layoutItems = [...beforeIndex, ...modifiedResult]
      }
    } else {
      let position = 1
      let layoutItems = [...beforeIndex, ...modifiedResult]
      let widgetItems = this.getSortedList(layoutItems)
      let updatedPositionItems = widgetItems.map(item => {
        item.position = position++
        return item
      })
      pageListDetails.layoutItems = updatedPositionItems
    }

    this.setState({ pageDetails: _.cloneDeep(pageListDetails) })
  }

  // Widget add and delete batchEditPageLayoutItem update
  updatePositionWidgetItems = () => {
    const { pageDetails } = this.state
    const { project } = this.props
    let widgetItems = _.cloneDeep(pageDetails.layoutItems)
    const updatedWidgets = (widgetItems || []).map(item => {
      let filerConfigField = (item.fieldValues || []).map(fieldValue => {
        return {
          configField: (fieldValue.configField && fieldValue.configField.id) || '',
          bucket: fieldValue.bucket ? fieldValue.bucket.id : '',
          image: fieldValue.image ? fieldValue.image.id : '',
          project: fieldValue.project,
          useAsTitle: fieldValue.useAsTitle,
          value: fieldValue.value
        }
      }).filter(item => item && item.configField)
      return {
        id: item.id,
        position: item.position,
        title: item.title,
        page: pageDetails.id,
        type: 'WIDGET',
        widget: item.widget.id,
        fieldValues: filerConfigField,
        project: project,
        isEnabled: item.isEnabled,
        isPinned: item.isPinned
      }
    })
    return updatedWidgets
  }

  updateBatchPositionWidgetItems = () => {
    // for widget position updated
    const { pageDetails } = this.state
    let app = (pageDetails && pageDetails.app && pageDetails.app.id) || null
    let pageId = (pageDetails && pageDetails.id) || null
    this.setState({ pageReorderLoading: true })
    const updateConfigs = this.updatePositionWidgetItems()
    this.props.batchEditPageLayoutItem(updateConfigs, app, pageId).then(() => {
      this.props.fetchAppUpdatedPageDetails().then(response => {
        if (response && response.data.getAppPage) {
          let updatedPageDetails = _.cloneDeep(this.state.pageDetails)
          updatedPageDetails.updatedAt = response.data.getAppPage.updatedAt
          this.setState({ lastPageUpdatedUser: response.data.getAppPage.updatedBy, pageDetails: updatedPageDetails })
        }
      })
      this.props.updatePageForceSaveState(true)
      if (this.refetchHistory) {
        this.refetchHistory()
      }
      setTimeout(() => {
        this.setState({ pageReorderLoading: false })
      }, 900)
    }, error => {
      utilityService.handleError(error)
      this.setState({ pageReorderLoading: false })
    })
  }

  handleEditPageLayoutItems = (layoutItem, pageDetails) => {
    let pageListDetails = pageDetails
    let widgetItems = _.cloneDeep(pageListDetails.layoutItems)
    const index = (widgetItems || []).findIndex((item) => item.id === layoutItem.id)
    widgetItems = widgetItems && widgetItems.length ? widgetItems : []
    widgetItems[index] = layoutItem
    pageListDetails.layoutItems = widgetItems
    this.setState({
      pageDetails: pageListDetails
    })
  }

  // dummy commit

  onChangeWidgetFilter = (search) => {
    this.setState({
      widgetSearchValue: search,
      sourceIndex: undefined,
      destinationIndex: undefined,
      droppableId: undefined,
      draggableId: undefined
    })
  }

  hidePageScheduleModal = () => {
    const { editPageSchedule } = this.state
    if (editPageSchedule) {
      this.setState({ showPageScheduleModal: false, editPageSchedule: false, editedPageVersion: null, isPageScheduleEdited: false })
    } else {
      this.setState({ showPageScheduleModal: false, editPageSchedule: false, currentPageVersion: null, isPageScheduleEdited: false })
    }
  }

  togglePageScheduleModal = (isEdited, version) => {
    if (this.props.pageEditted) {
      message.warn('Please Save changes to pageLayout before Scheduling new Version')
      return
    }
    if (isEdited) {
      this.setState({ showPageScheduleModal: true, editPageSchedule: true, editedPageVersion: version })
    } else {
      let currentPageVersion = {}
      const id = utilityService.makeRandomString(6)
      currentPageVersion.id = id
      currentPageVersion.publishEndDate = null
      currentPageVersion.publishStartDate = null
      this.setState({ showPageScheduleModal: true, editPageSchedule: false, currentPageVersion, editedPageVersion: version })
    }
  }

  checkForInvalidStartDates=() => {
    let { currentPageVersionId, pageDetails, currentPageVersion, editedPageVersion } = this.state
    let { scheduledVersions } = pageDetails
    let scheduledVersionIndex = (scheduledVersions || []).findIndex(version => (version.id === currentPageVersionId) && !currentPageVersion)
    if (scheduledVersionIndex > -1 && scheduledVersionIndex === 0) {
      if (pageDetails.publishEndDate) {
        if (editedPageVersion.publishStartDate && !editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[1] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)))) {
          return true
        } else if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[1] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
          return true
        }
      } else {
        if (editedPageVersion.publishStartDate && !editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[1] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)))) {
          return true
        } else if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[1] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
          return true
        }
      }
    } if (scheduledVersionIndex > -1 && scheduledVersionIndex === 1) {
      if (editedPageVersion.publishStartDate && !editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0] && scheduledVersions[0].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishStartDate)))) {
        return true
      } else if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0] && scheduledVersions[0].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishStartDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
        return true
      }
    } else if (scheduledVersionIndex === -1 && !_.isEmpty(scheduledVersions)) {
      if (pageDetails.publishEndDate) {
        if (currentPageVersion.publishStartDate && !currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(currentPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishEndDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(currentPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishEndDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      } else {
        if (currentPageVersion.publishStartDate && !currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(currentPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishEndDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(currentPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishEndDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      }
    } else if (scheduledVersionIndex === -1 && _.isEmpty(scheduledVersions)) {
      if (pageDetails.publishEndDate) {
        if (currentPageVersion.publishStartDate && !currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      } else {
        if (currentPageVersion.publishStartDate && !currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      }
    }
    return false
  }

  checkForInvalidEndDates=() => {
    let { currentPageVersionId, pageDetails, currentPageVersion, editedPageVersion } = this.state
    let { scheduledVersions } = pageDetails
    let scheduledVersionIndex = (scheduledVersions || []).findIndex(version => (version.id === currentPageVersionId) && _.isEmpty(currentPageVersion))
    if (scheduledVersionIndex > -1 && scheduledVersionIndex === 0) {
      if (pageDetails.publishEndDate) {
        if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[1] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
          return true
        } else if (editedPageVersion.publishEndDate && scheduledVersions[1] && scheduledVersions[1].publishEndDate && moment(editedPageVersion.publishEndDate).isAfter(moment(scheduledVersions[1].publishEndDate))) {
          return true
        }
      } else {
        if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[1] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
          return true
        } else if (editedPageVersion.publishEndDate && scheduledVersions[1] && scheduledVersions[1].publishEndDate && moment(editedPageVersion.publishEndDate).isAfter(moment(scheduledVersions[1].publishEndDate))) {
          return true
        }
      }
    } if (scheduledVersionIndex > -1 && scheduledVersionIndex === 1) {
      if (pageDetails.publishEndDate) {
        if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
          return true
        } else if (editedPageVersion.publishEndDate && scheduledVersions[0] && scheduledVersions[0].publishEndDate && (moment(editedPageVersion.publishEndDate).isBefore(moment(scheduledVersions[0].publishEndDate)) || moment(editedPageVersion.publishEndDate.isBefore(moment(editedPageVersion.publishStartDate))))) {
          return true
        }
      } else {
        if (editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0] && scheduledVersions[1].publishStartDate && (moment(editedPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(editedPageVersion.publishStartDate).isBefore(moment(scheduledVersions[1].publishEndDate)) || moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate)))) {
          return true
        } else if (editedPageVersion.publishEndDate && scheduledVersions[0] && scheduledVersions[0].publishEndDate && (moment(editedPageVersion.publishEndDate).isBefore(moment(scheduledVersions[0].publishEndDate)) || moment(editedPageVersion.publishEndDate.isBefore(moment(editedPageVersion.publishStartDate))))) {
          return true
        }
      }
    } else if (scheduledVersionIndex === -1 && !_.isEmpty(scheduledVersions)) {
      if (pageDetails.publishEndDate) {
        if (currentPageVersion.publishStartDate && !currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(currentPageVersion.publishEndDate).isBefore(moment(scheduledVersions[0].publishEndDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) || moment(currentPageVersion.publishEndDate).isBefore(moment(scheduledVersions[0].publishEndDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      } else {
        if (currentPageVersion.publishStartDate && !currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(currentPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishStartDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && scheduledVersions[0].publishStartDate && (moment(currentPageVersion.publishStartDate).isBefore(moment(pageDetails.publishStartDate)) || moment(currentPageVersion.publishStartDate).isBefore(moment(scheduledVersions[0].publishStartDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      }
    } else if (scheduledVersionIndex === -1 && _.isEmpty(scheduledVersions)) {
      if (pageDetails.publishEndDate) {
        if (!currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishEndDate).isBefore(moment(pageDetails.publishEndDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishEndDate).isBefore(moment(pageDetails.publishEndDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      } else {
        if (!currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishEndDate).isBefore(moment(pageDetails.publishStartDate)))) {
          return true
        } else if (currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && pageDetails.publishStartDate && (moment(currentPageVersion.publishEndDate).isBefore(moment(pageDetails.publishStartDate)) || moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate)))) {
          return true
        }
      }
    }
    return false
  }

  onScheduleStartDateChange = (value) => {
    let { currentPageVersion, editedPageVersion } = this.state
    if (_.isEmpty(editedPageVersion)) {
      currentPageVersion.publishStartDate = value ? new Date(moment(value).utc()).toISOString() : value
      if (currentPageVersion && currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate))) {
        message.error('Please enter a valid startDate')
        return
      }
      if (this.checkForInvalidStartDates(currentPageVersion)) {
        message.error('Please enter dates that do not conflict with previously scheduled versions')
        return
      }

      if (currentPageVersion.publishEndDate) {
        this.setState({ isPageScheduleEdited: true })
      }

      if (currentPageVersion.publishStartDate === null) {
        this.setState({ isPageScheduleEdited: false })
      }
      this.setState({ currentPageVersion })
    } else {
      editedPageVersion.publishStartDate = value ? new Date(moment(value).utc()).toISOString() : value
      if (editedPageVersion && editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate))) {
        message.error('Please enter a valid startDate')
        return
      }
      if (this.checkForInvalidStartDates(editedPageVersion)) {
        message.error('Please enter dates that do not conflict with previously scheduled versions')
        return
      }

      if (editedPageVersion.publishEndDate) {
        this.setState({ isPageScheduleEdited: true })
      }

      if (editedPageVersion.publishStartDate === null) {
        this.setState({ isPageScheduleEdited: false })
      }
      this.setState({ editedPageVersion })
    }
  }

  onScheduleEndDateChange = (value) => {
    let { currentPageVersion, editedPageVersion } = this.state
    if (_.isEmpty(editedPageVersion)) {
      currentPageVersion.publishEndDate = value ? new Date(moment(value).utc()).toISOString() : value
      if (currentPageVersion && currentPageVersion.publishStartDate && currentPageVersion.publishEndDate && moment(currentPageVersion.publishStartDate).isAfter(moment(currentPageVersion.publishEndDate))) {
        message.error('Please enter a valid endDate')
        return
      }
      if (this.checkForInvalidEndDates()) {
        message.error('Please enter dates that do not conflict with previously scheduled versions')
        return
      }

      if (currentPageVersion.publishStartDate) {
        this.setState({ isPageScheduleEdited: true })
      }

      if (currentPageVersion.publishEndDate === null) {
        this.setState({ isPageScheduleEdited: false })
      }

      this.setState({ currentPageVersion })
    } else {
      editedPageVersion.publishEndDate = value ? new Date(moment(value).utc()).toISOString() : value
      if (editedPageVersion && editedPageVersion.publishStartDate && editedPageVersion.publishEndDate && moment(editedPageVersion.publishStartDate).isAfter(moment(editedPageVersion.publishEndDate))) {
        message.error('Please enter a valid endDate')
        return
      }
      if (this.checkForInvalidEndDates()) {
        message.error('Please enter dates that do not conflict with previously scheduled versions')
        return
      }

      if (editedPageVersion.publishEndDate) {
        this.setState({ isPageScheduleEdited: true })
      }

      if (editedPageVersion.publishEndDate === null) {
        this.setState({ isPageScheduleEdited: false })
      }
      this.setState({ editedPageVersion })
    }
  }

  handlePageSchedule = () => {
    const { pageDetails, editPageSchedule, editedPageVersion, currentPageVersion } = this.state
    let layoutItems = _.cloneDeep(pageDetails.layoutItems)
    let layoutItemIds = (layoutItems || []).map(item => item && item.id)

    if (editPageSchedule) {
      const input = {
        id: editedPageVersion.id,
        layoutItems: layoutItemIds,
        isPublished: editedPageVersion.isPublished || false,
        publishStartDate: editedPageVersion.publishStartDate,
        publishEndDate: editedPageVersion.publishEndDate
      }
      const appPageId = pageDetails.id
      this.setState({
        isScheduleLoading: true
      })
      this.props.editPageVersion(input, appPageId).then(() => {
        this.setState({ showPageScheduleModal: false, editPageSchedule: false, editedPageVersion: null, isScheduleLoading: false, newAddedLayoutItems: [] })
        if (this.props.fetchAppPageDetails) {
          this.props.fetchAppPageDetails()
        }
      }, error => {
        this.setState({ showPageScheduleModal: false, editPageSchedule: false, editedPageVersion: null, isScheduleLoading: false, newAddedLayoutItems: [] })
        utilityService.handleError(error)
      })
    } else {
      const input = {
        layoutItems: [],
        isPublished: true,
        publishStartDate: currentPageVersion.publishStartDate,
        publishEndDate: currentPageVersion.publishEndDate
      }
      const appPageId = pageDetails.id
      this.setState({
        isScheduleLoading: true
      })
      this.props.addPageVersion(input, appPageId).then(({ data }) => {
        const { addPageVersion } = data
        let pageDetailsList = _.cloneDeep(pageDetails)
        let editedPageVersion = addPageVersion
        pageDetailsList.layoutItems = addPageVersion.layoutItems
        pageDetailsList.scheduledVersions.push(addPageVersion)
        this.setState({ isPageScheduleEdited: false, showPageScheduleModal: false, editPageSchedule: false, currentPageVersion: {}, isScheduleLoading: false, currentPageVersionId: addPageVersion.id, pageDetails: pageDetailsList, editedPageVersion })
      }, error => {
        this.setState({ isPageScheduleEdited: false, showPageScheduleModal: false, editPageSchedule: false, currentPageVersion: {}, isScheduleLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  onPageDeleteVersion = (status, version) => {
    this.setState({
      showPageVersionDeleteWarning: status,
      selectedDeletedPageVersion: version
    })
  }

  handlePageDeleteVersion = () => {
    const { selectedDeletedPageVersion, pageDetails } = this.state
    this.setState({
      isPageVersionDeleteLoading: true
    })
    this.props.deletePageVersion(selectedDeletedPageVersion.id).then(() => {
      let pageDetailsList = _.cloneDeep(pageDetails)
      let filterPageScheduleVersion = (pageDetailsList.scheduledVersions).filter(item => item.id !== selectedDeletedPageVersion.id)
      pageDetailsList.scheduledVersions = filterPageScheduleVersion
      this.setState({ showPageVersionDeleteWarning: false, selectedDeletedPageVersion: null, isPageVersionDeleteLoading: false, pageDetails: pageDetailsList, currentPageVersionId: 'current' })
      if (this.props.fetchAppPageDetails) {
        this.props.fetchAppPageDetails()
      }
    }, error => {
      utilityService.handleError(error)
      this.setState({ showPageVersionDeleteWarning: false, selectedDeletedPageVersion: null, isPageVersionDeleteLoading: false })
    })
  }

  handlePageDeleteVersionCancel = (status) => {
    this.setState({
      showPageVersionDeleteWarning: status,
      selectedDeletedPageVersion: null
    })
  }

  onChangeWidgetSettingSearchFilter = (search) => {
    this.setState({
      widgetSettingSearchValue: search,
      sourceIndex: undefined,
      destinationIndex: undefined,
      droppableId: undefined,
      draggableId: undefined
    })
  }

  changeWidgetSettingFilter = filter => {
    let { widgetSettingFilter } = this.state
    if (filter.length) {
      widgetSettingFilter.filter = filter
      this.setState({ widgetSettingFilter })
    }
  }

  onPageVersionSelection = (version) => {
    if (version === this.state.currentPageVersionId) {
      return
    }
    this.updatedWidgetContents = []
    if (this.props.pageEditted && version !== this.state.currentPageVersionId) {
      message.warn('Please save changes to selected Version layoutItem before switching versions')
      this.setState({ showPageVersionSwitchWarning: true, tempVersion: version })
      return
    }
    if (version === 'current') {
      const { pageDetails } = this.state
      let pageDetailsList = _.cloneDeep(pageDetails)
      pageDetailsList.layoutItems = this.props.pageDetails.layoutItems
      this.setState({
        currentPageVersionId: 'current',
        pageDetails: pageDetailsList,
        tempVersion: ''
      })
    } else {
      const { pageDetails } = this.state
      let pageDetailsList = _.cloneDeep(pageDetails)
      pageDetailsList.layoutItems = version.layoutItems
      this.setState({
        currentPageVersionId: version.id,
        pageDetails: pageDetailsList,
        editedPageVersion: version,
        tempVersion: ''
      })
    }
  }

  discardPageVersionChanges=() => {
    this.props.updatePageForceSaveState(true)
    this.setState({ showPageVersionSwitchWarning: false }, () => {
      this.onPageVersionSelection(this.state.tempVersion || '')
    })
  }

  onCloseConfirmPageVersionSwitchModal=() => {
    this.setState({ showPageVersionSwitchWarning: false })
  }

  handlePageScheduleSubmit = () => {
    const { editedPageVersion, pageDetails, tempVersion } = this.state
    let layoutItems = _.cloneDeep(pageDetails.layoutItems)
    let layoutItemIds = layoutItems.map(item => item.id)
    const input = {
      id: editedPageVersion.id,
      layoutItems: layoutItemIds,
      isPublished: true,
      publishStartDate: editedPageVersion.publishStartDate,
      publishEndDate: editedPageVersion.publishEndDate
    }
    const appPageId = pageDetails.id
    this.setState({
      isPageScheduleSaveLoading: true
    })
    this.props.editPageVersion(input, appPageId).then(({ data }) => {
      const { editPageVersion } = data
      this.setState({ isPageScheduleSaveLoading: false, showPageVersionSwitchWarning: false })
      this.props.updatePageForceSaveState(true)
      this.onPageVersionSelection(editPageVersion)
    }, error => {
      this.setState({ isPageScheduleSaveLoading: false, showPageVersionSwitchWarning: false })
      utilityService.handleError(error)
      this.props.updatePageForceSaveState(true)
      this.onPageVersionSelection(tempVersion)
    })
  }

  handleJsonValidation = (configData, value) => {
    const { jsonInvalidEntries } = this.state
    let isJsonValid = true
    if (_.isEmpty(value)) {
      isJsonValid = true
      let index = (jsonInvalidEntries || []).indexOf(configData.id)
      if (index !== -1) {
        jsonInvalidEntries.splice(index, 1)
      }
    } else {
      let isValidJson = this.isValidJson(value, false)
      if (!isValidJson) {
        let index = (jsonInvalidEntries || []).indexOf(configData.id)
        if (index === -1) {
          jsonInvalidEntries.push(configData.id)
        }
        isJsonValid = false
      } else {
        let index = (jsonInvalidEntries || []).indexOf(configData.id)
        if (index !== -1) {
          jsonInvalidEntries.splice(index, 1)
        }
        isJsonValid = true
      }
    }
    return isJsonValid
  }

  handleJsonArrayValidation = (configData, value) => {
    const { jsonArrayInvalidEntries } = this.state
    let isJsonArrayValid = true
    if (_.isEmpty(value)) {
      isJsonArrayValid = true
      let index = (jsonArrayInvalidEntries || []).indexOf(configData.id)
      if (index !== -1) {
        jsonArrayInvalidEntries.splice(index, 1)
      }
    } else {
      let isValidJson = this.isValidJson(value, true)
      if (!isValidJson) {
        let index = (jsonArrayInvalidEntries || []).indexOf(configData.id)
        if (index === -1) {
          jsonArrayInvalidEntries.push(configData.id)
        }
        isJsonArrayValid = false
      } else {
        let index = (jsonArrayInvalidEntries || []).indexOf(configData.id)
        if (index !== -1) {
          jsonArrayInvalidEntries.splice(index, 1)
        }
        isJsonArrayValid = true
      }
    }
    return isJsonArrayValid
  }

  handleJsonArrayVisibilty = (jsonConfig, jsonSelectedMeta) => {
    if (jsonSelectedMeta) {
      this.setState({
        isJsonArrayVisible: true,
        jsonConfig,
        jsonSelectedMeta
      })
    } else {
      this.setState({
        isJsonArrayVisible: true,
        jsonConfig,
        jsonSelectedMeta: jsonConfig
      })
    }
  }

  handleJsonArrayHide = () => {
    const { jsonSelectedMeta } = this.state
    if (!_.isEmpty(jsonSelectedMeta.value)) {
      let parseValue = JSON.parse(jsonSelectedMeta.value)
      if (!Array.isArray(parseValue)) {
        message.warn('Please enter valid JSON array')
        return
      }
    }
    this.setState({
      isJsonArrayVisible: false,
      jsonConfig: null,
      jsonSelectedMeta: null
    })
  }

  reloadPage=() => {
    this.setState({ pageModifiedConfirm: true })
    this.props.fetchAppPageDetails().then(response => {
      if (response && response.data && response.data.getAppPage) {
        this.setState({ pageDetails: _.cloneDeep(response.data.getAppPage), isPageModified: false, pageModifiedConfirm: false, isPageSaveLoading: false })
        this.props.updatePageForceSaveState(true)
      }
    })
  }

  reloadConfig=() => {
    this.setState({ configModifiedConfirm: true })
    this.props.fetchAppDetails().then(response => {
      if (response && response.data && response.data.getAppConfig) {
        this.setState({ details: _.cloneDeep(response.data.getAppConfig), isConfigModified: false, configModifiedConfirm: false })
        this.props.updateForceSaveState(true)
      }
    })
  }

  checkForConfigModification= async () => {
    const { details } = this.state
    let isModified = false
    await this.props.fetchUpdatedAppConfigDetails().then(response => {
      if (response && response.data.getAppConfig && moment(response.data.getAppConfig.updatedAt).isAfter(moment(details.updatedAt))) {
        this.setState({ isConfigModified: true, lastConfigUpdatedUser: response.data.getAppConfig.updatedBy })
        isModified = true
      }
    })
    if (isModified) {
      return true
    }
    return false
  }

    updatePinWidgetItems = () => {
      const { pageDetails, selectedWidget } = this.state
      let widget = [selectedWidget]
      const { project } = this.props
      const updatedWidgets = (widget || []).map(item => {
        let filerConfigField = (item.fieldValues || []).map(fieldValue => {
          return {
            configField: (fieldValue.configField && fieldValue.configField.id) || '',
            bucket: fieldValue.bucket ? fieldValue.bucket.id : '',
            image: fieldValue.image ? fieldValue.image.id : '',
            project: fieldValue.project,
            useAsTitle: fieldValue.useAsTitle,
            value: fieldValue.value
          }
        }).filter(item => item && item.configField)
        return {
          id: item.id,
          position: item.position,
          title: item.title,
          page: pageDetails.id,
          type: 'WIDGET',
          widget: item.widget.id,
          fieldValues: filerConfigField,
          project: project,
          isEnabled: item.isEnabled,
          isPinned: !item.isPinned
        }
      })
      return updatedWidgets
    }

  handleWidgetPinChange = () => {
    const { pageDetails, selectedWidget } = this.state
    const app = (pageDetails && pageDetails.app && pageDetails.app.id)
    const pageId = (pageDetails && pageDetails.id) || null
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    if (isAppAdminDisabled) {
      message.error('You do not have access to do this operation on this Page')
      return
    }
    this.setState({ isWidgetLoading: true })
    const updateConfigs = this.updatePinWidgetItems()
    this.props.batchEditPageLayoutItem(updateConfigs, app, pageId).then(() => {
      this.props.fetchAppUpdatedPageDetails().then(response => {
        if (response && response.data.getAppPage) {
          let updatedPageDetails = _.cloneDeep(pageDetails)
          updatedPageDetails.updatedAt = response.data.getAppPage.updatedAt
          let widgetItems = updatedPageDetails.layoutItems
          let index = widgetItems.findIndex(item => item.id === selectedWidget.id)
          widgetItems[index].isPinned = !selectedWidget.isPinned
          this.setState({ lastPageUpdatedUser: response.data.getAppPage.updatedBy, pageDetails: updatedPageDetails })
        }
      })
      if (this.refetchHistory) {
        this.refetchHistory()
        if (!selectedWidget.isPinned) {
          message.success('Widget Pinned Successfully.')
        } else {
          message.success('Widget Unpinned Successfully.')
        }
      }
      this.setState({ isWidgetLoading: false, showWidgetPinWarning: false, selectedWidget: null })
    }, error => {
      utilityService.handleError(error)
      this.setState({ isWidgetLoading: false, showWidgetPinWarning: false, selectedWidget: null })
    })
  }
  checkForPageModification = async () => {
    const { pageDetails } = this.state
    let isModified = false
    await this.props.fetchAppUpdatedPageDetails().then(response => {
      if (response && response.data.getAppPage && moment(response.data.getAppPage.updatedAt).isAfter(moment(pageDetails.updatedAt))) {
        this.setState({ isPageModified: true, lastPageUpdatedUser: response.data.getAppPage.updatedBy })
        isModified = true
      }
    })
    if (isModified) {
      return true
    }
    return false
  }

  fetchUpdatedPageUpdateTime=() => {
    this.props.fetchAppUpdatedPageDetails().then(response => {
      if (response && response.data.getAppPage) {
        let updatedPageDetails = _.cloneDeep(this.state.pageDetails)
        updatedPageDetails.updatedAt = response.data.getAppPage.updatedAt
        this.setState({ lastPageUpdatedUser: response.data.getAppPage.updatedBy, pageDetails: updatedPageDetails })
      }
    })
  }
  // dummy commit //

  setSelectedTaskIds=(ids, entities, isToggle) => {
    const { pageDetails } = this.state
    let clonePageDetails = _.cloneDeep(pageDetails)
    let clonePageDetailsDuplicate = _.cloneDeep(pageDetails)
    var newListData = (clonePageDetailsDuplicate.layoutItems || []).slice(1, -1)
    const isWidgetPinned = newListData.some(item => item.isPinned)
    if (isWidgetPinned && !isToggle) {
      message.error('Multiselect is disabled.')
      return
    }

    let layoutItems = clonePageDetails.layoutItems
    let layoutItemsSize = _.size(layoutItems)
    let idsSize = _.size(ids)
    if (idsSize === layoutItemsSize && layoutItemsSize > 1) {
      message.warn('Cannot select all the items.')
      return
    }

    this.setState({ selectedIds: ids, entities: entities })
  }

  resetPageState = () => {
    const { pageDetails } = this.props
    this.setState({
      pageDetails
    })
  }

  render () {
    const
      {
        configDetails,
        showObjectModal,
        details,
        isLoading,
        isConfigListInstanceEdited,
        isConfigObjectSaveLoading,
        isConfigObjectSaveDisable,
        singleConfigDetails,
        isConfigSingleInstanceEdited,
        isConfigSingleInstanctSaveLoading,
        shouldRefreshHistory,
        editConfigInstance,
        pageDetails,
        isPageLoading,
        sourceIndex,
        destinationIndex,
        droppableId,
        draggableId,
        isPagSaveDisable,
        showWidgetPinWarning,
        isWidgetLoading,
        isPageSaveLoading,
        isPagePublishEdited,
        isPublishLoading,
        showPagePublishModal,
        rightBarMode,
        isUnPublishLoading,
        widgetSearchValue,
        showPageScheduleModal,
        editPageSchedule,
        editedPageVersion,
        currentPageVersion,
        isPageScheduleEdited,
        showPageVersionDeleteWarning,
        isPageVersionDeleteLoading,
        isScheduleLoading,
        widgetSettingSearchValue,
        widgetSettingFilter,
        currentPageVersionId,
        isPageScheduleSaveLoading,
        pageReorderLoading,
        isJsonArrayValid,
        jsonInvalidEntries,
        jsonArrayInvalidEntries,
        showPageVersionSwitchWarning,
        isJsonArrayVisible,
        jsonConfig,
        jsonSelectedMeta,
        isPageModified,
        lastPageUpdatedUser,
        pageModifiedConfirm,
        isConfigModified,
        lastConfigUpdatedUser,
        configModifiedConfirm,
        showWidgetDeleteWarning,
        selectedIds,
        selectedWidget
      } = this.state

    const
      {
        appList,
        groupList,
        selectedConfig,
        appClient,
        project,
        fetchAppDetails,
        fetchConfigList,
        fetchPageList,
        onSelectConfigGroup,
        forceSave,
        updateForceSaveState,
        appMode,
        selectedPage,
        onSelectPageGroup,
        fetchAppPageDetails,
        pageEditted,
        selectedAppSetting,
        refetchPageList,
        configMetaFieldList,
        pageMetaFieldList,
        handleRecreateConfigTree,
        userId
      } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          return <Row className='app-editor'>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Col
                className={`app-details`}
                xs={{ span: 24 - (8) }}
              >
                <AppDetails
                  details={_.cloneDeep(details)}
                  pageDetails={_.cloneDeep(pageDetails)}
                  selectedConfig={selectedConfig}
                  selectedPage={selectedPage}
                  handleConfigDetailsChange={this.handleConfigDetailsChange}
                  configDetails={_.cloneDeep(configDetails)}
                  fieldTypeList={fieldTypeList}
                  showObjectModal={showObjectModal}
                  showAddObjectModal={this.showAddObjectModal}
                  hideAddObjectModal={this.hideAddObjectModal}
                  appList={appList || []}
                  groupList={groupList || []}
                  typeList={typeList || []}
                  loading={isLoading}
                  pageLoading={isPageLoading}
                  appClient={appClient}
                  changeSelectedConfig={this.changeSelectedConfig}
                  changeSelectedPage={this.changeSelectedPage}
                  onSelectConfigGroup={onSelectConfigGroup}
                  project={project}
                  handleConfigObjectSave={this.handleConfigObjectSave}
                  changeConfigListEditStatus={this.changeConfigListEditStatus}
                  isConfigListInstanceEdited={isConfigListInstanceEdited}
                  fetchAppDetails={fetchAppDetails}
                  handleListConfigInstanceEdit={this.handleListConfigInstanceEdit}
                  isConfigObjectSaveLoading={isConfigObjectSaveLoading}
                  isConfigObjectSaveDisable={isConfigObjectSaveDisable}
                  handleSingleConfigDetailsChange={this.handleSingleConfigDetailsChange}
                  singleConfigDetails={_.cloneDeep(singleConfigDetails)}
                  isConfigSingleInstanceEdited={isConfigSingleInstanceEdited}
                  isConfigSingleInstanctSaveLoading={isConfigSingleInstanctSaveLoading}
                  handleSingleConfigSubmit={this.handleSingleConfigSubmit}
                  fetchConfigList={fetchConfigList}
                  refetchHistory={this.refetchHistory}
                  forceSave={forceSave}
                  updateForceSaveState={updateForceSaveState}
                  handleListConfigImgCropInstanceEdit={this.handleListConfigImgCropInstanceEdit}
                  editConfigInstance={editConfigInstance}
                  fetchPageList={fetchPageList}
                  appMode={appMode}
                  sourceIndex={sourceIndex}
                  destinationIndex={destinationIndex}
                  droppableId={droppableId}
                  draggableId={draggableId}
                  onSelectPageGroup={onSelectPageGroup}
                  handlePageLayoutItems={this.handlePageLayoutItems}
                  isPagSaveDisable={isPagSaveDisable}
                  handlePageLayoutItemDelete={this.onPageDeleteSelection}
                  isPageSaveLoading={isPageSaveLoading}
                  handlePageSubmit={this.handlePageSubmit}
                  handleLayoutItemStatus={this.handleLayoutItemStatus}
                  fetchAppPageDetails={fetchAppPageDetails}
                  handleEditPageLayoutItems={this.handleEditPageLayoutItems}
                  widgetSearchValue={widgetSearchValue}
                  pageEditted={pageEditted}
                  selectedAppSetting={selectedAppSetting}
                  refetchPageList={refetchPageList}
                  togglePageScheduleModal={this.togglePageScheduleModal}
                  widgetSettingSearchValue={widgetSettingSearchValue}
                  onChangeWidgetSettingFilter={this.onChangeWidgetSettingSearchFilter}
                  changeWidgetSettingFilter={this.changeWidgetSettingFilter}
                  widgetSettingFilter={widgetSettingFilter}
                  currentPageVersionId={currentPageVersionId}
                  handlePageScheduleSubmit={this.handlePageScheduleSubmit}
                  isPageScheduleSaveLoading={isPageScheduleSaveLoading}
                  pageReorderLoading={pageReorderLoading}
                  configMetaFieldList={configMetaFieldList || []}
                  pageMetaFieldList={pageMetaFieldList || []}
                  isJsonArrayValid={isJsonArrayValid}
                  handleRecreateConfigTree={handleRecreateConfigTree}
                  jsonInvalidEntries={jsonInvalidEntries}
                  jsonArrayInvalidEntries={jsonArrayInvalidEntries}
                  permissions={permissions}
                  isJsonArrayVisible={isJsonArrayVisible}
                  jsonConfig={jsonConfig}
                  jsonSelectedMeta={jsonSelectedMeta}
                  handleJsonArrayVisibilty={this.handleJsonArrayVisibilty}
                  handleJsonArrayHide={this.handleJsonArrayHide}
                  checkForConfigModification={this.checkForConfigModification}
                  handlePageLayoutItemPin={this.onPagePinSelection}
                  checkForPageModification={this.checkForPageModification}
                  fetchUpdatedPageUpdateTime={this.fetchUpdatedPageUpdateTime}
                  userId={userId}
                  setSelectedTaskIds={this.setSelectedTaskIds}
                  selectedTaskIds={selectedIds}
                  resetPageState={this.resetPageState}
                />
              </Col>
              <Col className='app-side-bar' xs={{ span: (8) }}>
                <AppRightSideBar
                  selectedConfig={selectedConfig}
                  appDetails={_.cloneDeep(details)}
                  pageDetails={_.cloneDeep(pageDetails)}
                  appClient={appClient}
                  shouldRefreshHistory={shouldRefreshHistory}
                  appMode={appMode}
                  selectedPage={selectedPage}
                  hidePagePublishModal={this.hidePagePublishModal}
                  onPublishAllStartDateChange={this.onPublishAllStartDateChange}
                  onPublishAllEndDateChange={this.onPublishAllEndDateChange}
                  onPublishAllButtonActiveChange={this.onPublishAllButtonActiveChange}
                  handlePagePublish={this.handlePagePublish}
                  isPagePublishEdited={isPagePublishEdited}
                  isPublishLoading={isPublishLoading}
                  togglePagePublishModal={this.togglePagePublishModal}
                  showPagePublishModal={showPagePublishModal}
                  handleModeChange={this.handleModeChange}
                  mode={rightBarMode}
                  handlePageUnPublish={this.handlePageUnPublish}
                  isUnPublishLoading={isUnPublishLoading}
                  widgetSearchValue={widgetSearchValue}
                  onChangeWidgetFilter={this.onChangeWidgetFilter}
                  togglePageScheduleModal={this.togglePageScheduleModal}
                  onPageDeleteVersion={this.onPageDeleteVersion}
                  selectedAppSetting={selectedAppSetting}
                  currentPageVersionId={currentPageVersionId}
                  onPageVersionSelection={this.onPageVersionSelection}
                  currentPageVersion={currentPageVersion}
                  appList={appList}
                  checkForConfigModification={this.checkForConfigModification}
                  checkForPageModification={this.checkForPageModification}
                  isConfigSingleInstanceEdited={isConfigSingleInstanceEdited}
                  pageReorderLoading={pageReorderLoading}
                  userId={userId}
                />
              </Col>
            </DragDropContext>
            <ConfirmModal isVisible={showWidgetDeleteWarning} title={'Widget Delete ?'} message={'Are you Sure You Want to Delete Widget?'} isLoading={isWidgetLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.onPageSelectionCancel(false)} handleSubmit={() => this.handlePageLayoutItemDelete()} isSubmitButtonDisabled={false} isCancelButtonDisabled={isWidgetLoading} />
            <SchedulePageModal
              pageVersionDetails={editPageSchedule ? editedPageVersion : currentPageVersion}
              visible={showPageScheduleModal}
              handleCancel={this.hidePageScheduleModal}
              appClient={appClient}
              onScheduleStartDateChange={this.onScheduleStartDateChange}
              onScheduleEndDateChange={this.onScheduleEndDateChange}
              handlePageSchedule={this.handlePageSchedule}
              isPageScheduleEdited={isPageScheduleEdited}
              editPageSchedule={editPageSchedule}
              isScheduleLoading={isScheduleLoading}
              currentPageVersionId={currentPageVersionId}
            />
            <ConfirmModal isVisible={showPageVersionDeleteWarning} title={'Page Version Delete ?'} message={'Are you Sure You Want to Delete this Page Version?'} isLoading={isPageVersionDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handlePageDeleteVersionCancel(false)} handleSubmit={() => this.handlePageDeleteVersion()} isSubmitButtonDisabled={false} isCancelButtonDisabled={isPageVersionDeleteLoading} />
            {showPageVersionSwitchWarning ? <ConfirmModal
              isVisible={showPageVersionSwitchWarning}
              title={`Page : ${pageDetails ? pageDetails.title : pageDetails.id}`}
              message={'Changes made by you will be discarded. Do you wish to continue ?'}
              isLeftPrior={false}
              leftButtonText={'Discard'}
              rightButtonText={'Save'}
              handleSubmit={currentPageVersionId === 'current' ? this.handlePageSubmit : this.handlePageScheduleSubmit}
              handleCancel={this.discardPageVersionChanges}
              handleClose={this.onCloseConfirmPageVersionSwitchModal}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false}
              isLoading={currentPageVersionId === 'current' ? isPageSaveLoading : isPageScheduleSaveLoading}
            /> : null}
            <ConfirmModal
              isVisible={isPageModified}
              title={'Warning'}
              message={`This page has been modified by ${lastPageUpdatedUser}. Please refetch page details to get the latest updates`}
              isLoading={pageModifiedConfirm}
              rightButtonText={'Refetch Data'}
              handleSubmit={this.reloadPage}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled
              isCancelButtonInvisible
              zIndex={2000} />

            <ConfirmModal
              isVisible={isConfigModified}
              title={'Warning'}
              message={`This config has been modified by ${lastConfigUpdatedUser}. Please refetch config details to get the latest updates`}
              isLoading={configModifiedConfirm}
              rightButtonText={'Refetch Data'}
              handleSubmit={this.reloadConfig}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled
              isCancelButtonInvisible
              zIndex={2000} />

            <ConfirmModal
              isVisible={showWidgetPinWarning}
              title={`${selectedWidget && selectedWidget.isPinned ? 'UnPin Widget ?' : 'Pin Widget ?'}`}
              message={`Are you Sure You Want to ${selectedWidget && selectedWidget.isPinned ? 'unPin' : 'pin'} this Widget?`}
              isLoading={isWidgetLoading}
              isLeftPrior rightButtonText={'Confirm'}
              handleCancel={() => this.onPageSelectionCancel(false)}
              handleSubmit={() => this.handleWidgetPinChange()}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={isWidgetLoading}
            />

          </Row>
        }}
      </AppContext.Consumer>
    )
  }
}

AppEditor.propTypes = {

}

export default withApollo(compose(
  graphql(
    QueryGetAppConfig,
    {
      skip: ({ selectedConfig, appMode }) => {
        return !(selectedConfig && appMode === 'configs')
      },
      options: ({ project, selectedConfig, appClient }) => {
        return {
          variables: { id: selectedConfig, project: project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const details = props.data && props.data.getAppConfig ? _.cloneDeep(props.data.getAppConfig) : {}
        return {
          details,
          configIsLoading: props.data.loading,
          fetchAppDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetUpdatedAppConfig,
    {
      skip: ({ selectedConfig, appMode }) => {
        return !(selectedConfig && appMode === 'configs')
      },
      options: ({ project, selectedConfig, appClient }) => {
        return {
          variables: { id: selectedConfig, project: project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        // const details = props.data && props.data.getAppConfig ? props.data.getAppConfig : {}
        return {
          // details,
          // configIsLoading: props.data.loading,
          fetchUpdatedAppConfigDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetAppPage,
    {
      skip: ({ selectedPage, appMode }) => {
        return !(selectedPage && appMode === 'pages')
      },
      options: ({ project, selectedPage, appClient }) => {
        return {
          variables: { id: selectedPage, project: project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const pageDetails = props.data && props.data.getAppPage ? props.data.getAppPage : props.ownProps.pageDetails
        return {
          pageDetails,
          pageIsLoading: props.data.loading,
          fetchAppPageDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetUpdatedAppPage,
    {
      skip: ({ selectedPage, appMode }) => {
        return !(selectedPage && appMode === 'pages')
      },
      options: ({ project, selectedPage, appClient }) => {
        return {
          variables: { id: selectedPage, project: project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        // const pageUpdatedDetails = props.data && props.data.getAppPage ? props.data.getAppPage : {}
        return {
          updatedAt: props.data && props.data.getAppPage ? props.data.getAppPage.updatedAt : '',
          // pageIsLoading: props.data.loading,
          fetchAppUpdatedPageDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetUpdatedPageGroupDetail,
    {
      skip: ({ selectedPage, appMode }) => {
        return !(selectedPage && appMode === 'pages')
      },
      options: ({ project, selectedPage, appClient }) => {
        return {
          variables: { id: selectedPage, project: project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        // const pageUpdatedDetails = props.data && props.data.getAppPage ? props.data.getAppPage : {}
        return {
          // updatedAt: props.data && props.data.getAppPage ? props.data.getAppPage.updatedAt : '',
          // pageIsLoading: props.data.loading,
          fetchUpdatedPageGroupDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetAppList,
    {
      options: ({ project, appClient }) => {
        return {
          variables: { limit: 499, project: project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const appList = props.data && props.data.listApps ? props.data.listApps.items : []
        return {
          appList
        }
      }
    }
  ),
  graphql(
    QueryGetGroupList,
    {
      options: ({ project, appClient, appMode }) => {
        let type = appMode === 'pages' ? 'PAGE' : 'CONFIG'
        let filter = {
          type: {
            match: type
          }
        }
        return {
          variables: { limit: 499, project: project, filter },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const groupList = props.data && props.data.listGroups ? props.data.listGroups.items : []
        return {
          groupList
        }
      }
    }
  ),
  graphql(
    QueryGetAppConfigList,
    {
      options: ({ project, appClient }) => {
        return {
          variables: { limit: 499, project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const configList = props.data && props.data.listAppConfig ? props.data.listAppConfig.items : []
        return {
          configList,
          fetchConfigList: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetAppPageList,
    {
      options: ({ project, appClient }) => {
        return {
          variables: { limit: 499, project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const pageList = props.data && props.data.listAppPages ? props.data.listAppPages.items : []
        return {
          pageList,
          fetchPageList: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    MutationAddConfigInstance, {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        addConfigInstance: (appConfigId, project, input, titleField, app) => {
          return props.mutate({
            variables: { appConfigId, project: project, input, titleField, app }
          })
        }
      })
    }
  ),
  graphql(
    MutationEditConfigInstance, {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        editConfigInstance: (input, titleField, app) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project, titleField, app }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeletePageLayoutItem, {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        deletePageLayoutItem: (id, widgetName, app, page) => {
          return props.mutate({
            variables: { id, name: widgetName, project: props.ownProps.project, app, page }
          })
        }
      })
    }
  ),
  graphql(
    MutationPublishPage, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { publishAppPage } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppPage, variables: { id: publishAppPage.id, project } }))
            if (cacheData && cacheData.getAppPage) {
              cacheData.getAppPage.data = publishAppPage
            }
            cache.writeQuery({
              query: QueryGetAppPage,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        publishAppPage: (id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta) => {
          return props.mutate({
            variables: { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationBatchEditPageLayoutItem, {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        batchEditPageLayoutItem: (input, app, page) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project, app, page }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdatePage, {
      options: ({ appClient, project }) => {
        return {
          client: appClient
        }
      },
      props: props => ({
        updateAppPage: (id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta) => {
          return props.mutate({
            variables: { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationAddPageVersion,
    {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        addPageVersion: (input, appPageId) => {
          return props.mutate({
            variables: { input, appPageId, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeletePageVersion,
    {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        deletePageVersion: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationEditPageVersion,
    {
      options: ({ appClient }) => {
        return {
          client: appClient
        }
      },
      props: (props) => ({
        editPageVersion: (input, appPageId) => {
          return props.mutate({
            variables: { input, appPageId, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'APP_CONFIG', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let configMetaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        configMetaFieldList = configMetaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          configMetaFieldList
        }
      }
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'APP_PAGE', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let pageMetaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        pageMetaFieldList = pageMetaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          pageMetaFieldList
        }
      }
    }
  )
)(AppEditor))
