import gql from 'graphql-tag'

export default gql(`
query listRoles($filter: TableRoleFilterInput $limit: Int $offset: Int $project: String) {
    listRoles( 
      filter: $filter
      limit: $limit
      offset: $offset
      project: $project
    ){
        totalCount
        items {
          id
          name
          source
        }
      }
}`)
