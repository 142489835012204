import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import './../../ui.style.scss'

const { TextArea } = Input

const onChange = (handleChange, event) => {
  const value = event.target.value
  if (!value.startsWith(' ')) { handleChange(event) }
}

const TextAreaInput = ({ handleChange, value, placeholder, title, inputName, isEditable, isLimited, limit, onFocus, mode, icon, ...props }) => (
  <div className='input-field text-area'>
    <div><label>{ title }</label>{icon || null}</div>
    { isLimited && !mode ? <label className='limit'><span>Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
    { isLimited && mode === 'page' ? <label className='limit'><span>Character Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
    <TextArea name={inputName} placeholder={placeholder} value={value} onChange={onChange.bind(this, handleChange)} autosize={{ minRows: 2, maxRows: 6 }} maxLength={limit} onClick={onFocus} readOnly={!isEditable} />
  </div>
)

TextAreaInput.propTypes = {
  /** Change action of TextAreaInput. */
  handleChange: PropTypes.func,
  /** display value of TextAreaInput. */
  value: PropTypes.string,
  /** placeholder of TextAreaInput. */
  placeholder: PropTypes.string.isRequired,
  /** title of TextAreaInput. */
  title: PropTypes.string.isRequired,
  /** input name of TextAreaInput. */
  inputName: PropTypes.string.isRequired,
  /** editable status of TextAreaInput. */
  isEditable: PropTypes.bool,
  /** string limited status of TextAreaInput. */
  isLimited: PropTypes.bool,
  /** string limit of TextAreaInput. */
  limit: PropTypes.number
}

TextAreaInput.defaultProps = {
  isEditable: true
}

export default TextAreaInput
