import gql from 'graphql-tag'

export default gql(`
mutation createProductionStudio($name: String!, $project: String) {
    createProductionStudio(
        input: {
            name: $name
        },
        project: $project
    ) {
        id
        name
        assetCount
        source
    }
}`
)
