import gql from 'graphql-tag'

export default gql(`
    mutation($contents: [CreatePublishRuleInput] $project: String) {
        batchCreatePublishRule(
          input: $contents 
          project: $project 
        ) {
            id
            assetId
            group{
              id
            }
            isEnabled
            publishEndDate
            publishStartDate
          }
    }`
)
