import gql from 'graphql-tag'

export default gql(`
    mutation(
        $offerId: ID
        $id: ID!
        $project: String
   ) {
        deletePrice(
            offerId: $offerId
            id: $id
            project: $project
        ) {
            id
            name
            startDate
            endDate
            currency
            displayPrice
            wholeSalePrice
            rrpIncGst
            rrpExcGst
            gstAmount
            promoId
            customMeta{
                config{
                    id
                    name
                    type
                    section
                    default
                }
                value
            }
        }
    }`
)
