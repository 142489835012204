import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '../../components/ui/dataEntry/inputs/Input'
import TagsInput from '../../components/ui/dataEntry/inputs/TagsInput'
import TextAreaInput from '../../components/ui/dataEntry/inputs/TextArea'
import { utilityService } from './../../services/UtilityService'

const parentCompoent = 'asset-container'
const seoTitleMaxLength = 150
const slugMaxLength = 250
const seoDescMaxLength = 155

class SeoSettings extends Component {
  render () {
    const seoDetails = this.props.details
    const { handleSeoDetailsChange } = this.props
    const { onSeoKeywordChange, onInputFieldBlur, onInputFieldFocus, isInvalidSlug } = this.props
    return (
      <div className='seo-container'>
        <Input
          title='SEO Title'
          placeholder='Insert SEO Title'
          inputName='seoTitle'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={seoDetails.title !== null && seoDetails.title !== undefined ? seoDetails.title : null}
          handleChange={handleSeoDetailsChange}
          isLimited
          limit={seoTitleMaxLength}
        />
        <Input
          title='URL Slug'
          placeholder='Insert URL Slug'
          inputName='slug'
          isRequired
          isError={isInvalidSlug}
          value={seoDetails.slug !== null && seoDetails.slug !== undefined ? seoDetails.slug : utilityService.slugifyString(this.props.defaultTitle.toLowerCase())}
          handleChange={handleSeoDetailsChange}
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          errorMessage={'Invalid Slug'}
          isLimited
          limit={slugMaxLength}
        />
        <TextAreaInput
          title='Meta Description'
          placeholder='Write a short description...'
          inputName='seoMetaDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={seoDetails.metaDescription}
          handleChange={handleSeoDetailsChange}
          isLimited
          limit={seoDescMaxLength}
        />
        <div className='input-field'>
          <label className='input-title'>Keywords</label>
          <TagsInput
            selectedItems={seoDetails.keywords || []}
            tagList={[]}
            className={'key-word-dropdown'}
            mode={'tags'}
            onChange={onSeoKeywordChange}
            placeholder='Insert Keywords'
            displayParameter='name'
            parentCompoent={parentCompoent}
          />
        </div>
        <Input
          title='Canonical URL'
          placeholder='Insert Canonical URL'
          inputName='seoCanonicalUrl'
          value={seoDetails.seoCanonicalUrl !== null && seoDetails.seoCanonicalUrl !== undefined ? seoDetails.seoCanonicalUrl : ''}
          handleChange={handleSeoDetailsChange}
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
        />
      </div>
    )
  }
}

SeoSettings.propTypes = {
  /** details of SeoSettings. */
  details: PropTypes.object.isRequired,
  /** seo data change action of SeoSettings. */
  handleSeoDetailsChange: PropTypes.func,
  /** keyword change action of SeoSettings. */
  onSeoKeywordChange: PropTypes.func,
  /** default title of SeoSettings. */
  defaultTitle: PropTypes.string
}

SeoSettings.defaultProps = {}

export default SeoSettings
