import React, { Component } from 'react'
// import moment from 'moment'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import InfiniteScroll from 'react-infinite-scroller'
// import PropTypes from 'prop-types'

import { Empty, Skeleton } from 'antd'

import WidgetListCell from '../../components/ui/dataDisplay/WidgetListCell'
import { utilityService } from './../../services/UtilityService'
import { generateCroppedThumbnail } from '../../util/util'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QuerylistWidgets from '../../graphQL/appManager/listWidgets'

const width = ['calc(100% - 100px)', '50%', '100%']

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class AppWidgetList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      listWidgets: props.listWidgets,
      isLoading: false,
      isSearching: false,
      isPaginating: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && this.lastSearched !== { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
      this.setState({ listWidgets: newProps.listWidgets, isSearching: false, isPaginating: false, isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.listWidgets, this.props.listWidgets)) {
      this.setState({ listWidgets: newProps.listWidgets, isSearching: false, isPaginating: false, isLoading: false })
    }
  }

  componentDidMount () {

  }

  loadMoreAsset = () => {
    if (this.props.totalCount === this.props.listWidgets.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getlistWidgets(this.state.listWidgets.length, this.props.project))
  }

  render () {
    const { isPaginating } = this.state
    const { selectedAssets, isDragDisabled, disableAssetDrag, isLoading } = this.props
    let listWidgets = selectedAssets ? (this.state.listWidgets || []).filter((item) => (selectedAssets).indexOf(item.id) === -1) : this.state.listWidgets
    listWidgets = _.uniqBy(listWidgets, 'id')
    listWidgets = listWidgets.filter(widgets => (widgets.isActive))
    return (
      <React.Fragment>
        <div className='app-widget-list'>
          <Droppable droppableId='widget-list'>
            {(droppableProvided, droppableSnapshot) => (
              <div className='app-widget-list-container'
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isLoading && !isPaginating}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMoreAsset}
                    hasMore={this.props.totalCount > listWidgets.length}
                    initialLoad={false}
                    useWindow={false}
                  >
                    {listWidgets && listWidgets.length ? listWidgets.map((widget, index) => {
                      let imageUrl
                      let popupImageUrl
                      if (widget.widgetPreview) {
                        imageUrl = !_.isEmpty(widget.widgetPreview) ? generateCroppedThumbnail(widget.widgetPreview, 200, 200, '1:1') : undefined
                        popupImageUrl = !_.isEmpty(widget.widgetPreview) ? widget.widgetPreview.fileName : undefined
                      }
                      return (<Draggable key={index} draggableId={widget.id + `#Widget_list_` + index + 1} index={index} isDragDisabled={widget.isArchived || isDragDisabled || disableAssetDrag}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <WidgetListCell
                              key={widget.id}
                              imageUrl={imageUrl}
                              popupImageUrl={popupImageUrl}
                              widget={widget}
                            />
                          </div>
                        )}
                      </Draggable>)
                    }) : <Empty /> }
                  </InfiniteScroll>
                </Skeleton>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isPaginating && this.props.totalCount > listWidgets.length} />

              </div>
            )}
          </Droppable>
        </div>
      </React.Fragment>
    )
  }
}

AppWidgetList.propTypes = {

}
export default withApollo(compose(
  graphql(
    QuerylistWidgets,
    {
      options: (props) => {
        const { project, appClient, pageDetails, widgetSearchValue } = props
        let app = pageDetails && pageDetails.app && pageDetails.app.id
        let variables = utilityService.getFormattedWidgetFilter(app, widgetSearchValue, project)
        return {
          fetchPolicy: 'network-only',
          variables,
          client: appClient
        }
      },
      props: (props) => {
        const { data } = props
        const listWidgets = data.listWidgets ? data.listWidgets.items : []
        return {
          listWidgets,
          isLoading: data.loading || !data.listWidgets,
          totalCount: data.listWidgets ? data.listWidgets.totalCount : 0,
          getlistWidgets: (page, project) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listWidgets.items, ...fetchMoreResult.listWidgets.items]
                prev.listWidgets.items = newList
                return prev
              }
            })
          }
        }
      }

    }
  )
)(AppWidgetList))
