import gql from 'graphql-tag'

export default gql(`
    mutation updateOfferAttribute($input: [UpdateOfferAttributeInput], $partnerId: ID  $project: String) {
        updateOfferAttribute(
            input: $input,
            partnerId: $partnerId
            project: $project
        ){
            id
        }
    }`
)
