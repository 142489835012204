import gql from 'graphql-tag'

export default gql(`
    mutation batchArchiveAssets($batchJobId: ID!) {
        batchArchiveAssets(
            batchJobId: $batchJobId
        ) {
            isArchived
        }
    }`
)
