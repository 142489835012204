import React, { Component } from 'react'
import { Table, Skeleton, message, Checkbox } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import FilterInput from '../dataEntry/inputs/FilterInput'
import DisplayValueModal from '../dataEntry/other/displayValueModal'
import AdminItemConfirmModal from '../feedback/AdminItemConfirmModal'
import { utilityService } from './../../../services/UtilityService'
import { generateCroppedThumbnail } from './../../../util/util'
import CreateWidgetModal from '../dataEntry/other/CreateWidgetModal'
import DoneIcon from '../general/icons/DoneIcon'
import CrossIcon from '../general/icons/CrossIcon'
import AppContext from '../../../AppContext'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationDeleteWidget from '../../../graphQL/appManager/deleteAppWidget'
import QueryListWidgets from './../../../graphQL/appManager/listWidgets'
import QueryGetAppList from './../../../graphQL/appManager/listApps'
import JsonArrayTableViewModal from './JsonArrayTableViewModal'

const deleteTagTypeMessage = {
  title: 'DELETE WIDGET',
  firstMessage: 'Are you sure you want to delete this Widget?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  },
  {
    type: 'duplicate',
    name: 'Duplicate'
  }
]

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class WidgetAppSettingList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedType: null,
      isLoading: !(props.listWidgets && props.listWidgets.length),
      isShowTagTypeModal: false,
      // showWidgetModal: false,
      selectedWidget: undefined,
      deleteLoading: false,
      isDeleteSuccess: false,
      isEdit: false,
      isDragStart: false,
      expandedRowKeys: [],
      widgetTypeList: props.listWidgets && props.listWidgets.length ? this.getFormattedWidgetList(props.listWidgets) : [],
      selectedIds: [],
      showDeleteWidgetModal: false,
      widgetList: [],
      appSearchText: '',
      isDuplicate: false,
      showJsonArrayTable: false,
      selectedJsonArrayTableValue: null

    }
    this.columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: '30%',
        render: (name, id) => this.renderTagTypeName(name, id)
      },

      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: '30%',
        render: (name, id) => this.renderTagTypeName(name, id)
      },
      {
        title: 'Usage',
        dataIndex: 'usageCount',
        width: '20%',
        key: 'usageCount',
        render: (tagCount) => this.renderTagTypeCount(tagCount)
      },
      {
        title: 'Active',
        dataIndex: 'isActive',
        width: '17%',
        key: 'isActive',
        render: (tagCount) => this.renderIsEnabled(tagCount)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.innerColumns = [
      {
        title: 'Property Name',
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        render: (name) => <div className='inner-header'>{name}</div>
      },
      {
        title: 'Field Type',
        dataIndex: 'type',
        key: 'type',
        width: '25%',
        render: (type) => <div className='inner-header'>{type}</div>
      },
      {
        title: 'Default Value',
        dataIndex: 'value',
        key: 'value',
        width: '25%',
        render: (value) => <div className='inner-header'>{value}</div>
      },
      {
        title: 'Mandatory',
        dataIndex: 'isRequired',
        key: 'isRequired',
        render: (isRequired) => <div className='inner-header'>{isRequired}</div>
      }

    ]
    this.metaDataColumns = [
      {
        title: 'Property Name',
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        render: (name) => <div className='inner-header'>{name}</div>
      },
      {
        title: 'Field Type',
        dataIndex: 'type',
        key: 'type',
        width: '25%',
        render: (type) => <div className='inner-header'>{type}</div>
      },
      {
        title: 'Default Value',
        dataIndex: 'value',
        key: 'value',
        width: '25%',
        render: (value, data) => this.renderDefaultData(value, data)
      },
      {
        title: 'Mandatory',
        dataIndex: 'isRequired',
        key: 'isRequired',
        render: (isRequired) => <div className='inner-header'>{isRequired ? <DoneIcon /> : <CrossIcon color={'#f5212d'} /> }</div>
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.listWidgets, this.props.listWidgets)) {
        const updatedwidgetTypeList = (newProps.listWidgets || []).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          if (item.enabledAssetTypes === null) {
            item.enabledAssetTypes = []
          }

          return item
        })
        const newList = this.getFormattedWidgetList(updatedwidgetTypeList)
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, widgetTypeList: _.cloneDeep(newList.sort((a, b) => (a.position - b.position))), widgetList: _.cloneDeep(newProps.listWidgets) })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.listWidgets, this.props.listWidgets)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (newProps.isLoading && !_.isEmpty(newProps.listWidgets) && !_.isEqual(newProps.listWidgets, this.props.listWidgets)) {
        const updatedwidgetTypeList = (newProps.listWidgets || []).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          return item
        })
        const newList = this.getFormattedWidgetList(updatedwidgetTypeList)
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, widgetTypeList: _.cloneDeep(newList.sort((a, b) => (a.position - b.position))), widgetList: _.cloneDeep(newProps.listWidgets) })
      } else if (!_.isEqual(newProps.listWidgets, this.state.widgetList)) {
        const updatedwidgetTypeList = (newProps.listWidgets || []).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          if (item.enabledAssetTypes === null) {
            item.enabledAssetTypes = []
          }

          return item
        })
        const newList = this.getFormattedWidgetList(updatedwidgetTypeList)
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, widgetTypeList: _.cloneDeep(newList.sort((a, b) => (a.position - b.position))), widgetList: _.cloneDeep(newProps.listWidgets) })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    getFormattedWidgetList=(widgetList) => {
      const newList = (widgetList || []).map(item => {
        if (item && item.fieldValues && item.fieldValues.length) {
          (item.fieldValues).map(innerItem => {
            if (innerItem.configField && innerItem.configField.type === 'BUCKET') {
              innerItem.value = innerItem.bucket
            } else if (innerItem.configField && innerItem.configField.type === 'IMAGE') {
              innerItem.value = innerItem.image
            }
          })
          const newFieldValues = (item.fieldValues || []).filter(innerItem => innerItem && innerItem.configField)
          item.fieldValues = newFieldValues
        }
        return item
      })
      return newList
    }

    renderDefaultData=(value, data) => {
      if (data && (data.type === 'string' || data.type === 'number' || data.type === 'boolean')) {
        return (
          <div className='inner-header'>{value + ''}</div>
        )
      } else if (data && data.type === 'bucket') {
        return (
          <div className='inner-header'>{!_.isEmpty(value) ? (value && value.name) : (null + '')}</div>
        )
      } else if (data && data.type === 'jsonarray') {
        return (
          <div className='inner-header-hover' style={{ color: '#f5212d' }} onClick={() => this.handleJsonArrayTableView(value)}>{'View as Table'}</div>
        )
      } else {
        return (
          <div className='inner-header-hover' style={{ color: '#f5212d' }} onClick={() => this.onClickView(value, data)}>{'View'}</div>
        )
      }
    }

    renderAssetTypeList=(title) => {
      return (
        <div className='assetType-list'>
          {this.getLogoForAsset(title)}
          {title}
        </div>
      )
    }

    onClickView=(value, data) => {
      const newObj = {
        value: value,
        data: data
      }
      this.setState({ viewData: newObj, showViewModal: true })
    }

    hideViewModal=() => {
      this.setState({ showViewModal: false, viewData: null })
    }

    getLogoForAsset=(title) => {
      const { listAssetTypes } = this.props
      const foundAsset = (listAssetTypes || []).find(item => title === item.title)
      let src
      if (!_.isEmpty(foundAsset)) {
        src = (foundAsset.icon || foundAsset.draftIcon) ? <img src={generateCroppedThumbnail((foundAsset.icon ? foundAsset.icon : foundAsset.draftIcon), 23, 23, '1:1')} /> : <img src={null} />
      }
      return src
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete', 'duplicate']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedType) {
        this.setState({ openedType: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedType === id) {
        this.setState({ openedType: null })
      } else {
        this.setState({ openedType: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { listWidgets } = this.props
      const selectedWidget = listWidgets.find(item => item.id === id)
      if (!selectedWidget) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ selectedWidget }, () => this.props.handleShowWidgetModal())
      } else if (selectedOption.type === 'duplicate') {
        this.setState({ selectedWidget, isDuplicate: true }, () => this.props.handleShowWidgetModal())
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteWidgetModal: true, selectedWidget })
      }
      this.setState({ openedType: null })
    }

    loadMoreTagTypes = () => {
      if (this.props.totalCount === this.state.widgetTypeList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreTagTypes(this.state.widgetTypeList.length))
    }

    hideDeleteTagTypeModal = () => {
      this.setState({ isDeleteSuccess: true, selectedWidget: null }, () => {
        this.setState({ showDeleteWidgetModal: false, isDeleteSuccess: false })
      })
    }

    renderTagTypeCount = (count) => {
      if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderIsEnabled = (isActive) => {
      if (!isActive) {
        return 'No'
      } else {
        return 'Yes'
      }
    }

    renderTagTypeName=(name, props) => {
      return (
        <div className='tag-type-title'>
          {this.props.isDeleting ? (<Checkbox checked={this.checkIfSeleted(props.id)} onClick={() => this.onCheck(props.id)} />) : null}
          {name}
        </div>
      )
    }

    checkIfSeleted=(id) => {
      let { widgetTypeList } = this.state
      const index = widgetTypeList.findIndex(tagType => tagType.id === id)
      if (index > -1) { return widgetTypeList[index].isSelected } else { return false }
    }

    onCheck=(id) => {
      let { widgetTypeList, selectedIds } = this.state
      const index = widgetTypeList.findIndex(tagType => tagType.id === id)
      if (index > -1) {
        widgetTypeList[index].isSelected = !widgetTypeList[index].isSelected
        const selectedIndex = selectedIds.indexOf(id)
        if (selectedIndex > -1) {
          selectedIds.splice(selectedIndex, 1)
        } else {
          selectedIds.push(id)
        }
        this.setState({ selectedIds })
      }
    }

    onDragEnd = (result) => {
      const { listWidgets, onHistoryUpdate } = this.props
      const startIndex = result.source.index
      const endIndex = result.destination.index
      let { widgetTypeList } = this.state
      const [ removed ] = widgetTypeList.splice(startIndex, 1)
      removed.trackPosChange = true
      widgetTypeList.splice(endIndex, 0, removed)
      widgetTypeList = widgetTypeList.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({ widgetTypeList, isDragStart: false })
      const changedTypes = widgetTypeList.filter(tagType => {
        const selectedItem = listWidgets.find(innerItem => innerItem.id === tagType.id)
        return selectedItem.position !== tagType.position
      })

      const variables = (changedTypes || []).map(item => {
        return {
          id: item.id,
          position: item.position,
          name: item.name,
          isMediaEnabled: item.isMediaEnabled,
          key: item.key,
          isCustom: item.isCustom,
          trackPosChange: item.trackPosChange,
          enabledAssetTypes: item.enabledAssetTypes === null ? null : (item.enabledAssetTypes || []).map(assetType => {
            delete assetType.__typename
            // delete meta.id
            return assetType
          })
        }
      })
      this.props.batchUpdateTagTypes({ updateData: variables }).then(() => {
        onHistoryUpdate()
      })
    }

    onDragStart = () => {
      const { expandedRowKeys } = this.state
      if (expandedRowKeys.length) { this.setState({ expandedRowKeys: [], isDragStart: true }) } else { this.setState({ isDragStart: true }) }
    }

     dragableBodyRow = (item) => {
       const { isDragStart } = this.state
       const { index } = item
       return <Draggable key={item['data-row-key']} draggableId={item['data-row-key']} index={index} isDragDisabled>
         { (provided, snapshot) => {
           return <tr className={`ant-table-row${isDragStart ? ' dragEnable' : ''}`}
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={getItemStyle(
               snapshot.isDragging,
               provided.draggableProps.style
             )}
           >
             {item.children}
           </tr>
         }}</Draggable>
     }

     handleRowExpand = (record) => {
       this.setState(prevState =>
         prevState.expandedRowKeys.includes(record.id)
           ? {
             expandedRowKeys: prevState.expandedRowKeys.filter(
               key => key !== record.id
             )
           }
           : { expandedRowKeys: [...prevState.expandedRowKeys, record.id] }
       )
     }

     onDeleteTagTypeModal = () => {
       const { selectedWidget } = this.state
       const { refetchHistory } = this.props
       if (!selectedWidget) {
         return
       }
       this.setState({ deleteLoading: true })
       this.props.deleteAppWidget(selectedWidget.id).then(() => {
         message.success('Widget deleted successfully')
         this.setState({ isDeleteSuccess: true, selectedWidget: null }, () => {
           this.setState({ showDeleteWidgetModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         refetchHistory()
         setTimeout(() => {
           this.refetchNewWidgetList()
         }, 1000)
       }, error => {
         this.setState({ isDeleteSuccess: true, selectedWidget: null }, () => {
           this.setState({ showDeleteWidgetModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         utilityService.handleError(error)
       })
     }

     handleAddTagType = () => {
       this.setState({ isShowTagTypeModal: false, selectedTagType: null })
     }

    renderData = (id) => {
      let availableOptions = []
      const { openedType } = this.state
      const { listWidgets } = this.props
      const selectedWidget = listWidgets.find(item => item.id === id)
      availableOptions = [ ...options ]
      if (selectedWidget && !(selectedWidget.usageCount)) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      // if (this.isUpdateDisabled) {
      //   availableOptions.splice(1, 1)
      // }
      // if (this.isCreateDisabled) {
      //   availableOptions.splice(0, 1)
      // }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedType === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    changeTitleCase=(str) => {
      return str.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        }
      )
    }

    renderWidgetData = ({ fieldValues, fields }) => {
      let newData = (fieldValues || []).map(function (fieldValue) {
        if (fieldValue && fieldValue.configField) {
          const filteredField = fields.filter((el) => el.id === fieldValue.configField.id)
          if (filteredField && filteredField.length) {
            return {
              name: fieldValue && fieldValue.configField && fieldValue.configField.name,
              type: fieldValue && fieldValue.configField && fieldValue.configField.type ? (fieldValue.configField.type).toLowerCase() : '',
              value: fieldValue && fieldValue.value,
              isRequired: fieldValue && fieldValue.configField && fieldValue.configField.isRequired,
              position: filteredField[0].position
            }
          }
        }
      })
      const newFields = _.sortBy(newData, 'position')
      return <Table
        className={`inner-table inner-table-container`}
        rowKey={record => record.title}
        columns={this.metaDataColumns}
        dataSource={newFields}
        pagination={false}
        showHeader
      />
    }

    renderInnerRows = (data) => {
      return <React.Fragment>
        {this.renderWidgetData({ fieldValues: data.fieldValues, fields: data.fields })}
      </React.Fragment>
    }

    onHandleBack=() => {
      let { parentRatingList } = this.state
      const newList = (parentRatingList || []).map(item => {
        item.isSelected = false
        return item
      })

      this.setState({ parentRatingList: newList })
      this.props.onHandleBackButton()
    }

    handleHideWidgetModal=() => {
      this.setState({ selectedWidget: null, isDuplicate: false })
      this.props.hideWidgetModal()
    }

    refetchNewWidgetList=() => {
      const { widgetSettingFilter } = this.props
      if (_.isEmpty(widgetSettingFilter) || (widgetSettingFilter && widgetSettingFilter.filter && !widgetSettingFilter.filter[0].value)) {
        this.props.refetchWidgetList()
        // setTimeout(()=>{
        this.props.refetchWidgetList()
        // },2000)
      }
      if (_.isEmpty(widgetSettingFilter) || (widgetSettingFilter && widgetSettingFilter.filter && !widgetSettingFilter.filter[1].value)) {
        this.props.refetchWidgetList()
      }
    }

    handleJsonArrayTableView = (value) => {
      this.setState({ selectedJsonArrayTableValue: value, showJsonArrayTable: true })
    }

    hideJsonArrayTableView = () => {
      this.setState({ selectedJsonArrayTableValue: null, showJsonArrayTable: false })
    }

    render () {
      const { isLoading, showWidgetModal, appList, appClient, widgetSearchValue, refetchHistory, onChangeWidgetFilter, changeWidgetSettingFilter, appSearchText,
        handleAppSearchTextChange } = this.props
      const { isPaginating, expandedRowKeys, widgetTypeList, selectedWidget, showDeleteWidgetModal, deleteLoading, viewData, showViewModal, isDuplicate,
        showJsonArrayTable, selectedJsonArrayTableValue } = this.state
      let adminItem
      if (selectedWidget && showDeleteWidgetModal) {
        adminItem = <div >
          <p>{ selectedWidget.title }</p>
        </div>
      }

      const components = {
        body: {
          row: this.dragableBodyRow
        }
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['APP_MANAGER']
            // const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('APP_ADMIN') === -1
            this.isUpdateDisabled = userPermissions.indexOf('APP_ADMIN') === -1
            return <div className='widget-setting-container' id='widget-container'>
              <FilterInput
                searchString={widgetSearchValue}
                onChangeSearchInput={onChangeWidgetFilter}
                filterType={'AppManager'}
                project={project}
                appClient={appClient}
                placeholder='Search Widgets'
                changeFilter={changeWidgetSettingFilter}
                placement='rightTop'
                parentId={'app-details-container'}
              />
              <div className='widget-setting-list'>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreTagTypes}
                  hasMore={this.props.totalCount > widgetTypeList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart} >
                      <Droppable droppableId={'#TagType_123'}>
                        { (droppableProvided, droppableSnapshot) => (

                          <div className='widget-setting-table-container'
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            <Table
                              className={`general-table ${isPaginating ? 'paginating' : ''}`}
                              rowKey={record => record.id}
                              columns={this.columns}
                              dataSource={widgetTypeList}
                              pagination={false}
                              expandedRowRender={(data) => this.renderInnerRows(data)}
                              expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => this.handleRowExpand(record)}
                              components={components}
                              onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow
                              })} />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <CreateWidgetModal
                details={!_.isEmpty(selectedWidget) ? _.cloneDeep(selectedWidget) : null}
                isVisible={showWidgetModal}
                hideWidgetModal={this.handleHideWidgetModal}
                appClient={appClient}
                appList={appList}
                isFromEdit={!!selectedWidget && showWidgetModal}
                selectedWidget={selectedWidget}
                widgetSearchValue={widgetSearchValue}
                handleAppSearchTextChange={handleAppSearchTextChange}
                appSearchText={appSearchText}
                refetchHistory={refetchHistory}
                refetchNewWidgetList={this.refetchNewWidgetList}
                project={project}
                isDuplicate={isDuplicate}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteWidgetModal}
                title={deleteTagTypeMessage.title}
                firstMessage={deleteTagTypeMessage.firstMessage}
                secondMessage={deleteTagTypeMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteTagTypeModal}
                handleSubmit={this.onDeleteTagTypeModal}
                isSubmitButtonDisabled={false}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
              <DisplayValueModal
                isVisible={showViewModal}
                handleCancel={this.hideViewModal}
                title={'Default Value'}
                viewData={viewData}
              />
              <JsonArrayTableViewModal
                handleCancel={this.hideJsonArrayTableView}
                isVisible={showJsonArrayTable}
                selectedJsonArrayTableValue={selectedJsonArrayTableValue ? JSON.parse(selectedJsonArrayTableValue) : []}

              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

WidgetAppSettingList.propTypes = {}

export default withApollo(
  compose(
    graphql(MutationDeleteWidget, {
      options: ({ appClient, project, widgetSearchValue }) => {
        return {
          update: (cache, { data: { deleteAppWidget } }) => {
            let variables = utilityService.getFormattedWidgetSettingFilter('', widgetSearchValue, project)
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListWidgets, variables: variables }))
            if (cacheData && cacheData.listWidgets && cacheData.listWidgets.items) {
              let foundIndex = (cacheData.listWidgets.items).findIndex(item => item.id === deleteAppWidget.id)
              if (foundIndex > -1) {
                cacheData.listWidgets.items.splice(foundIndex, 1)
              }
            }
            cache.writeQuery({
              query: QueryListWidgets,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        deleteAppWidget: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }),
    graphql(QueryGetAppList,
      {
        options: ({ project, appClient, appSearchText }) => {
          let variables = utilityService.getFormattedAppSettingFilter(appSearchText, 499, project)
          return {
            variables: variables,
            fetchPolicy: 'network-only',
            client: appClient
          }
        },
        props: (props) => {
          const appList = props.data && props.data.listApps ? props.data.listApps.items : []
          return {
            appList
          }
        }
      }
    ),
    graphql(
      QueryListWidgets,
      {
        skip: ({ selectedAppSetting }) => {
          return selectedAppSetting === 'settings'
        },
        options: (props) => {
          const { project, appClient, widgetSearchValue, widgetSettingFilter } = props
          let filterValue
          let activeValue
          if (widgetSettingFilter && widgetSettingFilter.filter && widgetSettingFilter.filter[0].value) {
            filterValue = widgetSettingFilter.filter[0].value || []
          }
          if (widgetSettingFilter && widgetSettingFilter.filter && widgetSettingFilter.filter[1].value) {
            activeValue = widgetSettingFilter.filter[1].value || []
          }
          let variables = utilityService.getFormattedWidgetSettingFilter(filterValue, widgetSearchValue, project, activeValue)
          return {
            fetchPolicy: 'network-only',
            variables,
            client: appClient
          }
        },
        props: (props) => {
          const { data } = props
          const listWidgets = data.listWidgets ? data.listWidgets.items : []
          return {
            listWidgets,
            isLoading: data.loading || false,
            refetchWidgetList: () => {
              return props.data.refetch({ limit: 499,
                project: props.ownProps.project
              })
            }
          }
        }

      }
    )
  )(WidgetAppSettingList))
