import gql from 'graphql-tag'

export default gql(`
mutation disableApiKey($id: ID! $project: String) {
    disableApiKey(
        id: $id
        project: $project
    ) {
        id
        apiKey
        label
        app{
            id
            name
            project
            updatedAt
            url
            description
            createdAt
            bundleId
        }
        isDisabled
        isArchived
        createdAt
    }
}`
)
