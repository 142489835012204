import React from 'react'
import { Menu, Item, contextMenu } from 'react-contexify'
import moment from 'moment'
import { Spin, Icon, Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import 'react-contexify/dist/ReactContexify.css'

import AssetListCell from './AssetListCell'
import BucketEditTitleModal from './../dataEntry/other/BucketEditTitleModal'
import BucketResetTitleModal from './../dataEntry/other/BucketResetTitleModal'
import BucketResetDisplayImageModal from './../dataEntry/other/BucketResetDisplayImageModal'
import BucketEditDisplayImageModal from './../dataEntry/other/BucketEditDisplayImageModal'
import BucketAssetSearchList from './BucketAssetSearchList'
import DragAndDropIcon from '../general/icons/DragAndDropIcon'
import { generateCroppedThumbnail } from './../../../util/util'

import './../ui.style.scss'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class BucketDetailListForRelatedAsset extends React.Component {
  state = {
    showResetTitle: false,
    showEditTitle: false,
    showResetDisplayImage: false,
    showEditDisplayImage: false,
    selectedId: '',
    isFilterOpen: false
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.isScroll) {
      setTimeout(() => {
        this.bucketScrollContainer.scrollTop = (this.bucketScrollContainer.scrollHeight + 100)
        this.props.onBucketScroll()
      }, 200)
    }
  }

  showEditTitleModal = (itemId) => {
    this.setState({ showEditTitle: true, selectedId: itemId })
  }

  showResetTitleModal = (itemId) => {
    this.setState({ showResetTitle: true, selectedId: itemId })
  }
  showResetImageModal = (itemId) => {
    this.setState({ showResetDisplayImage: true, selectedId: itemId })
  }

  showEditImageModal = (itemId) => {
    this.setState({ showEditDisplayImage: true, selectedId: itemId })
  }

  hideEditImageModal = () => {
    this.setState({ showEditDisplayImage: false })
  }

  hideEditTitleModal = () => {
    this.setState({ showEditTitle: false })
  }
  hideResetImageModal = () => {
    this.setState({ showResetDisplayImage: false })
  }

  hideResetTitleModal = () => {
    this.setState({ showResetTitle: false })
  }

  onDragEnd = (result) => {
    const startIndex = result.source.index
    const endIndex = result.destination.index
    this.props.onDragEnd(startIndex, endIndex)
  }

  removeBucketItem = (item) => {
    if (this.props.removeBucketItem) { this.props.removeBucketItem(item) }
  }

  handleAssetTitle = (title) => {
    const { selectedId } = this.state
    this.props.handleAssetTitle(title, selectedId)
    this.setState({ showEditTitle: false })
  }

  handleAssetMedia = (media, defaultMedia) => {
    const { selectedId } = this.state
    this.props.onAssetDisplayImageChange(media, selectedId, defaultMedia)
    this.setState({ showEditDisplayImage: false })
  }

  handleAssetTitleReset = () => {
    const { selectedId } = this.state
    this.props.handleAssetTitleReset(selectedId)
    this.setState({ showResetTitle: false })
  }

  handleAssetDisplayImageReset = () => {
    const { selectedId } = this.state
    this.props.handleAssetDisplayImageReset(selectedId)
    this.setState({ showResetDisplayImage: false })
  }

  onAddImage = (details) => {
    const { selectedId } = this.state
    this.props.onAddImage(details, selectedId)
  }

  isFilterOpen = (isFilterOpen) => {
    this.setState({ isFilterOpen })
  }

  AfterEdittedMenu = (type, itemId, customMedia, customTitle, defaultMedia, isLoading, menuId) => {
    if (isLoading) {
      return <Menu className='loading-menu' id={menuId} >
        <Item>
          <Spin indicator={<Icon type='loading' style={{ fontSize: 24, color: '#FF015A' }} spin />} />
        </Item>
      </Menu>
    }
    return (
      <Menu id={menuId} >
        <Item onClick={() => this.removeBucketItem(itemId)} >{ type === 'ASSET' ? 'Remove Item' : 'Remove Filter' }</Item>
        { this.props.parentManager !== 'Asset' && type === 'ASSET' ? <Item onClick={() => this.showEditTitleModal(itemId)} >Edit Display Title</Item> : null}
        { this.props.parentManager !== 'Asset' && type === 'ASSET' ? <Item onClick={() => this.showEditImageModal(itemId)} >Edit Display Image</Item> : null}
        { type === 'ASSET' && customMedia && (!defaultMedia || customMedia.id !== defaultMedia.id) ? <Item onClick={() => this.showResetImageModal(itemId)}> Reset Display Image</Item> : null }
        { type === 'ASSET' && customTitle ? <Item onClick={() => this.showResetTitleModal(itemId)}> Reset Display Title</Item> : null }
      </Menu>
    )
  }

  render () {
    const { listData, bucketId, parent, parentCompoent, isSaveEnable, saveLoading, isUpdateBlocked, isCreateBlocked, project } = this.props
    const { showEditTitle, showResetTitle, showResetDisplayImage, showEditDisplayImage, selectedId, isFilterOpen } = this.state
    const bucketAssetData = selectedId ? listData.find((item) => item.id === selectedId) : undefined
    return (
      <div className='bucket-detail-list' ref={node => { this.bucketScrollContainer = node }}>
        <div className='bucket-detail'>
          {/* <DragDropContext onDragEnd={this.onDragEnd} > */}
          <Droppable droppableId={'#Bucket_' + bucketId} isDropDisabled={saveLoading}>
            { (droppableProvided, droppableSnapshot) => (
              <div
                className='bucket-list-container'
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                { (listData || []).map((item, index) =>
                  <Draggable key={item.id} draggableId={item.id + `bucket-item` + index + 1} index={index} isDragDisabled={isFilterOpen || isUpdateBlocked}>
                    { (provided, snapshot) => {
                      const menuId = `id-${item.id}-${bucketId}`
                      return <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          onContextMenu={(e) => contextMenu.show({
                            id: menuId,
                            event: e
                          })}
                        >
                          {
                            item.type === 'ASSET'
                              ? <AssetListCell
                                project={project}
                                assetId={item.asset.id}
                                assetName={item.customTitle || item.asset.title}
                                displayId={item.asset.shortId || item.asset.id}
                                assetType={item.asset.type}
                                status={item.asset.status}
                                date={moment(new Date(item.asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                                imageUrl={item.asset && item.asset.defaultMedia ? generateCroppedThumbnail(item.customAssetImage || item.customMedia || item.asset.defaultMedia, 87, 48, '16:9') : ''}
                                onSelect={() => { }}
                                isEditted={item.customTitle || (item.customAssetImage && (item.customAssetImage.id !== item.asset.defaultMedia.id)) || (item.customMedia && (item.customMedia.id !== item.asset.defaultMedia.id))}
                                asset={item.asset}
                              /> : null }
                          {item.type === 'FILTER' ? <BucketAssetSearchList id={item.id} searchString={item.filterRule.searchKey} filter={item.filterRule.filters} isFilterOpen={this.isFilterOpen}
                            onChangeFilter={this.props.onChangeFilter} parent={parent} isSaveEnable={isSaveEnable} saveLoading={saveLoading} /> : null}
                          {item.type === 'LOADING' ? <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 2 }} loading /> : ''}
                        </div>
                        {(isFilterOpen || isUpdateBlocked) ? '' : this.AfterEdittedMenu(item.type, item.id, (item.customAssetImage || item.customMedia), item.customTitle, item.asset ? item.asset.defaultMedia : undefined, item.isLoading, menuId)}
                      </div>
                    } }
                  </Draggable>
                )}
                {!this.props.listData.length ? <div className='drag-asset-message'>
                  <DragAndDropIcon />
                  <p>Drag and drop Assets here </p>
                </div> : null}
              </div>
            )}
          </Droppable>
          {/* </DragDropContext> */}
        </div>

        {showResetTitle
          ? <BucketResetTitleModal
            visible={showResetTitle}
            handleSubmit={this.handleAssetTitleReset}
            handleCancel={this.hideResetTitleModal}
            bucketAssetData={bucketAssetData}
            title='RESET DISPLAY TITLE'
          /> : null }
        {showEditTitle
          ? <BucketEditTitleModal
            visible={showEditTitle}
            handleSubmit={this.handleAssetTitle}
            handleCancel={this.hideEditTitleModal}
            title='EDIT DISPLAY TITLE'
            bucketAssetData={bucketAssetData}
          /> : null }
        {showResetDisplayImage
          ? <BucketResetDisplayImageModal
            visible={showResetDisplayImage}
            handleSubmit={this.handleAssetDisplayImageReset}
            handleCancel={this.hideResetImageModal}
            bucketAssetData={bucketAssetData}
            title='RESET DISPLAY IMAGE'
          /> : null }
        {showEditDisplayImage
          ? <BucketEditDisplayImageModal
            parentCompoent={parentCompoent}
            visible={showEditDisplayImage}
            handleSubmit={this.handleAssetMedia}
            handleCancel={this.hideEditImageModal}
            title='EDIT DISPLAY IMAGE'
            bucketAssetData={bucketAssetData}
            assetId={bucketAssetData.asset.id}
            onAddImage={this.onAddImage}
            isCreateBlocked={isCreateBlocked}
            project={project}
            displayMediaId={bucketAssetData.customMedia ? bucketAssetData.customAssetImage : bucketAssetData.customMedia
              ? bucketAssetData.customMedia.id : bucketAssetData.asset.defaultMedia
                ? bucketAssetData.asset.defaultMedia.id : undefined
            }
          /> : null }
      </div>
    )
  }
}

BucketDetailListForRelatedAsset.propTypes = {
  /** isSearch of BucketDetailListForRelatedAsset. */
  isSearch: PropTypes.bool,
  /** isEditted of BucketDetailListForRelatedAsset. */
  isEditted: PropTypes.bool,
  /** listData of BucketDetailListForRelatedAsset. */
  listData: PropTypes.array,
  /** Function to be called on rearrange of buckets */
  onDragEnd: PropTypes.func,
  /** Item Id */
  bucketId: PropTypes.string,
  /** Function to be called on bucket item remove */
  removeBucketItem: PropTypes.func,
  /** display image change action of BucketDetailListForRelatedAsset. */
  onAssetDisplayImageChange: PropTypes.func
}

BucketDetailListForRelatedAsset.defaultProps = {
}

export default BucketDetailListForRelatedAsset
