import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import moment from 'moment'
import './../ui.style.scss'

import AppPublishDetailsCell from './../dataEntry/inputs/AppPublishDetailsCell'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetAppPage from './../../../graphQL/appManager/getAppPage'
import MutationPublishPage from './../../../graphQL/appManager/publishAppPage'

class PublishPageModal extends React.Component {
  submit = () => {
    this.props.handlePagePublish()
  }

  clearState = () => {
    this.setState()
  }

  render () {
    const { handleCancel, visible, onPublishAllButtonActiveChange, onPublishAllEndDateChange, onPublishAllStartDateChange, isPublishLoading,
      isPagePublishEdited, pageDetails } = this.props
    const isValidDate = pageDetails && pageDetails.publishStartDate && pageDetails.publishEndDate
      ? moment(pageDetails.publishStartDate).isBefore(moment(pageDetails.publishEndDate)) : true
    return (
      <Modal
        className='general-modal publish-page-modal'
        visible={visible}
        closable={false}
        title={'PUBLISH PAGE'}
        onOk={this.submit}
        onCancel={handleCancel}
        okText='Save'
        cancelText='Back'
        okButtonProps={{ disabled: !(isPagePublishEdited && isValidDate), loading: isPublishLoading }}
        destroyOnClose
        afterClose={this.clearState}
      >
        <div className='publish-heading'>
          <div className='dates'>
            <label className='dates-selector'>Start Date</label>
            <label className='dates-selector'>End Date</label>
          </div>
          <label className='publishing'>Publish</label>
        </div>
        {pageDetails ? <AppPublishDetailsCell
          type='All'
          startDate={pageDetails.publishStartDate}
          endDate={pageDetails.publishEndDate}
          isActive={pageDetails.isPublished}
          onChange={onPublishAllButtonActiveChange}
          onStartDateChange={onPublishAllStartDateChange}
          onEndDateChange={onPublishAllEndDateChange}
          dateFormat='DD MMM YYYY, HH:mm'
          isLoading={isPublishLoading}
          module='appPage'
        /> : null}
      </Modal>

    )
  }
}

PublishPageModal.propTypes = {
  /** visible action of PublishBucketModal. */
  visible: PropTypes.bool,
  /** title  of PublishBucketModal. */
  title: PropTypes.string,
  /** handleCancel action of PublishBucketModal. */
  handleCancel: PropTypes.func
}

PublishPageModal.defaultProps = {
}

export default withApollo(compose(
  graphql(MutationPublishPage, {
    options: ({ appClient, project }) => {
      return {
        update: (cache, { data: { publishAppPage } }) => {
          const cacheData = cloneDeep(cache.readQuery({ query: QueryGetAppPage, variables: { id: publishAppPage.id, project } }))
          if (cacheData && cacheData.getAppPage) {
            cacheData.getAppPage.data = publishAppPage
          }
          cache.writeQuery({
            query: QueryGetAppPage,
            data: cacheData
          })
        },
        client: appClient
      }
    },
    props: props => ({
      publishAppPage: (id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta) => {
        return props.mutate({
          variables: { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta, project: props.ownProps.project }
        })
      }
    })
  })
)(PublishPageModal))
