import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import _, { cloneDeep, isEmpty } from 'lodash'
import assetDefaultValues from '../../../../constants/asset-default'

import Input from '../inputs/Input'
import InformationIcon from '../../general/icons/InformationIcon'
import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'
import GeneralFieldComponent from '../../dataDisplay/GeneralFieldComponentAssetType'
import LoadingButton from '../../general/buttons/LoadingButton'

// import { utilityService } from '../../../../services/UtilityService'

class CreateContentPlaceholderModal extends Component {
    state = {
      placeholderName: undefined,
      placeholderKey: undefined,
      isEdit: false,
      isError: false,
      isNameError: false,
      streamField: []
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedPlaceholder && (!_.isEqual(this.props.selectedPlaceholder, nextProps.selectedPlaceholder) || (!this.state.placeholderName && !this.state.placeholderKey))) {
        this.setState({
          placeholderName: nextProps.selectedPlaceholder.name,
          placeholderKey: nextProps.selectedPlaceholder.key,
          streamField: nextProps.selectedPlaceholder.metaFields || []
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      let { placeholderName, placeholderKey } = this.state

      if (!value.startsWith(' ')) {
        if (name === 'name') {
          let index = assetDefaultValues.contentTypes.findIndex(defaultType => defaultType.name.toLowerCase() === value.toLowerCase())
          if (index === -1) {
            index = this.props.placeholderList.findIndex(placeholder => placeholder.name.toLowerCase() === value.toLowerCase())
          }
          if (!this.props.selectedPlaceholder) {
            if ((!placeholderKey && !placeholderName) || placeholderKey === utilityService.slugifyString(placeholderName || '').toUpperCase()) {
              placeholderKey = utilityService.slugifyString(value || '').toUpperCase()
            }
          }
          this.setState({ placeholderName: value, isEdit: true, isNameError: index !== -1, placeholderKey })
        }
      }
    }

    checkEmptyValueListField = (streamField) => {
      let name = null
      streamField.forEach((item) => {
        if (item.type === 'LIST' && isEmpty(item.values)) {
          name = item.displayName
        }
      })

      return name
    }

    handleAddPlaceholder = () => {
      const { placeholderName, placeholderKey, streamField } = this.state
      const { selectedPlaceholder, handleSubmit } = this.props
      const newPlaceholder = {
        name: placeholderName,
        key: placeholderKey,
        metaFields: streamField
      }
      const checkEmptyValueListField = this.checkEmptyValueListField(streamField)
      if (checkEmptyValueListField) {
        message.warning(`Please enter default values for ${checkEmptyValueListField}`)
        return null
      }
      handleSubmit(newPlaceholder, _.isEmpty(selectedPlaceholder) ? null : selectedPlaceholder.id)
    }

    resetState = () => {
      const state = {
        placeholderName: '',
        placeholderKey: '',
        isEdit: false,
        isNameError: false,
        isError: false,
        streamField: []

      }
      this.setState({ ...state })
    }

    onChangeStatus = (event, index) => {
      const { streamField } = this.state
      const { checked } = event.target
      streamField[index].isRequired = checked
      this.setState({ streamField, isBoolEnabled: true, isEdit: true })
    }

    onChangeMetaName = (event, index) => {
      const { streamField: streamFieldDetails } = this.state
      const streamField = cloneDeep(streamFieldDetails)
      const { value } = event.target
      const duplicateItem = streamField.find(item => (item.displayName === value))
      streamField[index].displayName = value
      if (!_.isEmpty(duplicateItem) && duplicateItem.type === streamField[index].type) {
        message.error('Duplicate Meta Data')
        this.setState({ streamField, isBoolEnabled: false, isNoDuplicate: true })
      } else {
        this.setState({ streamField, isBoolEnabled: true, isNoDuplicate: false, isEdit: true })
      }
    }

    handleDefaultValues = (items, index) => {
      const { streamField } = this.state
      streamField[index].values = items
      this.setState({ streamField, isBoolEnabled: true, isEdit: true })
    }

    handleDisplayTitle = (event, index) => {
      const { streamField } = this.state
      const { value } = event.target
      streamField[index].displayTitle = value
      this.setState({ streamField, isBoolEnabled: true, isEdit: true })
    }

    handleToolTip = (event, index) => {
      const { streamField } = this.state
      const { value } = event.target
      streamField[index].tooltip = value
      this.setState({ streamField, isBoolEnabled: true, isEdit: true })
    }

    reorder = (startIndex, endIndex) => {
      const { streamField } = this.state
      const result = Array.from(streamField)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      this.setState({ streamField: result, isBoolEnabled: true, isEdit: true })
    }

    onCloseField = (index) => {
      const { streamField } = this.state
      // const index = streamField.findIndex(item => item.id === id)
      streamField.splice(index, 1)
      const duplicateItem = streamField.filter(item => {
        const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
        return tempIndex !== -1
      })
      const isNoDuplicate = !!duplicateItem.length
      this.setState({ streamField, isBoolEnabled: true, isNoDuplicate, isEdit: true })
    }

    onOptionSelect = (value, index, option) => {
      const { streamField } = this.state
      streamField[index][option] = value
      if (option === 'type') {
        streamField[index].values = []
      }

      const duplicateItem = streamField.find(item => item.displayName === streamField[index].displayName && item.id !== streamField[index].id)
      if (!_.isEmpty(duplicateItem) && duplicateItem.type === value) {
        message.error('Duplicate Meta Data')
        this.setState({ streamField, isBoolEnabled: false, isNoDuplicate: true })
      } else {
        this.setState({ streamField, isBoolEnabled: true, isNoDuplicate: false })
      }
    }

    enableButton = () => {
      this.setState({ isBoolEnabled: true })
    }

    addFieldObjects = () => {
      const { streamField } = this.state
      const id = utilityService.makeRandomString(6)
      const newFieldObject = {
        displayName: '',
        type: '',
        isRequired: false,
        id
      }
      streamField.push(newFieldObject)
      this.setState({ streamField, isBoolEnabled: true })
    }

    render () {
      const { isVisible, handleCancel, selectedPlaceholder, isLoading, isSubmitDisabled } = this.props
      const { placeholderName, placeholderKey, isEdit, isError, isNameError, streamField } = this.state
      const keyInput = <div className='type'>
        <span>Key</span>
        <div className='information-icon'>
          <InformationIcon />
        </div>
      </div>
      return (
        <Modal
          className='general-modal create-content-placeholder-modal'
          title={selectedPlaceholder ? 'EDIT CONTENT PLACEHOLDER' : 'NEW CONTENT PLACEHOLDER'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddPlaceholder}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !(placeholderName && placeholderKey && isEdit && !isError && !isSubmitDisabled && !isNameError), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='1050px'
          confirmLoading={isLoading}
        >
          <div className='create-content-placeholder'>
            <Input title='Content Placeholder Name' isRequired inputName='name' handleChange={this.handleChange} value={placeholderName} placeholder='Enter placeholder name' isError={isNameError}
              errorMessage='Name already exist' />
            <Input title={keyInput} inputName='key' handleChange={this.handleChange} value={placeholderKey} readOnly
              isError={isError} errorMessage={'Key already exist'} placeholder='Enter placeholder key' />
          </div>
          <GeneralFieldComponent
            type={'content-meta'}
            reorder={this.reorder}
            onCloseField={this.onCloseField}
            streamField={streamField}
            isEnableSave={this.isEnableSave}
            isTitleInvisible
            columnTitle1={'Meta Data Field'}
            columnTitle3={'Required'}
            columnTitle2={'Field Type'}
            placeHolder1='Enter Name'
            placeHolder2='Select Type'
            placeHolder3='Select Section'
            onChangeStatus={this.onChangeStatus}
            handleChange={this.onChangeMetaName}
            handleToolTip={this.handleToolTip}
            handleDisplayTitle={this.handleDisplayTitle}
            handleDefaultValues={this.handleDefaultValues}
            onOptionSelect={this.onOptionSelect}
            nameParam='displayName'
            // metaSectionLists={metaSectionLists}
            enableButton={this.enableButton}
          />
          <LoadingButton
            type='primary'
            onClick={this.addFieldObjects}
            htmlType='submit'
            buttonText={'Add Meta Data'}
            buttonClass='save-btn'
            isLoading={false}
            isDisabled={false}
          />
        </Modal>
      )
    }
}

CreateContentPlaceholderModal.propTypes = {
  /** visible file status of CreateContentPlaceholderModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateContentPlaceholderModal. */
  handleCancel: PropTypes.func,
  /** Boolean for disabling submit actikon */
  isSubmitDisabled: PropTypes.bool
}

CreateContentPlaceholderModal.defaultProps = {
}

export default CreateContentPlaceholderModal
