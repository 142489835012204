import gql from 'graphql-tag'

export default gql(`
    mutation editConfigInstance($input: editConfigInstanceInput $project: String $titleField:String $app: ID!) {
        editConfigInstance(
          input: $input
          project: $project
          titleField: $titleField
          app: $app
        ) {
          id
          fieldValues {
            configField {
              id
              name
              type
              isRequired
              project
              useAsTitle
              tooltipText
            }
            value
            bucket {
              project
              id
              isArchived
              name
              isPublished
              publishStartDate
              publishEndDate
              updatedAt
              updatedBy
              key
              displayName
              displayType{
                name
                id
              }
              bucketItems {
                id
                position
                type
                asset{
                  id
                  title
                  type
                  shortId
                  externalId
                  updatedAt
                  status
                  seasonNumber
                  episodeNumber
                  series{
                    id
                    assetId
                    title
                  }
                  season{
                      id
                      assetId
                      title
                  }
                  defaultMedia {
                      id
                      url
                      fileName
                      aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                          url
                        }
                      }
                      settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                      }
                  }
              }
              filterRule{
                searchKey
                filters{
                    name
                    fieldName
                    type
                    value
                    displayValue
                    displayName
                    isRequired
                 }
               }
              }
            }
            image {
              id
              
              fileName
              aspectRatio {
                  aspectRatio
                  ratio
                  title          
                  resolutions {
                    url
                  }
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2          
              }
          }
            project
            position
          }
          isEnabled
          position
          project
          appConfigId
          createdAt
          createdBy
        }
    }`
)
