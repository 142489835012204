import gql from 'graphql-tag'

export default gql(`
    mutation createDisplayType($name: String! $key: String! $project: String ) {
        createDisplayType(
            input: {
                name: $name
                key: $key
            },
            project: $project
        ) {
            id
            name
            key
            usedCount
        }
    }`
)
