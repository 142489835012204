import React from 'react'
import PropTypes from 'prop-types'
// import { Avatar } from 'antd'
import Input from '../inputs/Input'
// import CropWhiteIcon from '../../general/icons/CropWhiteIcon'
// import AddIcon from '../../general/icons/AddIcon'
// import CrossIcon from '../../general/icons/CrossIcon'
// import { generateCroppedThumbnail } from '../../../../util/util'
import './../../ui.style.scss'

class CreateBucketGroupTypeContainer extends React.Component {
    state = {
      showCrop: false
    }

    showCrop = (e) => {
      e.stopPropagation()
      this.setState({ showCrop: true })
    }

    showCropModal = (e) => {
      e.stopPropagation()
      if (this.props.selectedImage && this.props.selectedImage.length) {
        this.props.showCropModal(this.props.selectedImage)
      } else {
        this.props.showImageModal()
      }
    }

    hideCrop = () => {
      this.setState({ showCrop: false })
    }

    formatInput = (e) => {
      let checkIfNum
      if (e.key !== undefined) {
        checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'E'
      } else if (e.keyCode !== undefined) {
        checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189
      }
      return checkIfNum && e.preventDefault()
    }

    removeImage = (e) => {
      e.stopPropagation()

      this.props.clearSelection()
    }

    render () {
      // const image = this.props.selectedImage && this.props.selectedImage.length ? generateCroppedThumbnail(this.props.selectedImage[0], 200, 200, '1:1') : undefined
      // const defaultImage = require('../../../../assets/images/default-author.png')
      // const currentImage = image || defaultImage
      // const croppedImage = this.props.croppedUrl
      const { groupTypeName, handleTextChange } = this.props
      return (
        <div id='create-bucket-group-modal'>
          <div className='create-bucket-group-modal-container'>
            {/* <div className='avatar' onClick={() => this.props.showImageModal()} onMouseLeave={() => this.hideCrop()}> */}
            {/* { this.state.showCrop

                ? <div className='avatar-hover' onClick={(e) => this.showCropModal(e)}>
                  <span>{ image ? <CropWhiteIcon /> : <AddIcon /> }</span>
                </div>
                : <React.Fragment>
                  <div onMouseEnter={(e) => this.showCrop(e)} onMouseLeave={() => this.hideCrop()}>
                    <Avatar size={64} icon='user' src={croppedImage || currentImage} />
                  </div>
                  { image ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e)} className='oval'>
                    <CrossIcon color='#FFFFFF' />
                  </div> : null}
                </React.Fragment>
              } */}

            {/* </div> */}
            <div className='input-item'>
              <Input title='Bucket Group Type Name'isRequired placeholder='Insert Bucket Group Type Name' limit={40} inputName='title' value={groupTypeName} handleChange={(event) => handleTextChange(event)} />
              {/* <div className='details'>
                <label> Position </label>
                <input type='number' value={position} onChange={onHandlePosition} onKeyDown={this.formatInput} />
              </div> */}
            </div>
          </div>
        </div>
      )
    }
}

CreateBucketGroupTypeContainer.propTypes = {
  /** Details of selected image */
  selectedImage: PropTypes.array,
  /** Function to save the author */
  createAuthor: PropTypes.func,
  /** Callback when content is edited */
  handleTextChange: PropTypes.func,
  /** Name of the Author */
  groupTypeName: PropTypes.string,
  /** Callback to AddImageModal */
  showImageModal: PropTypes.func
}

CreateBucketGroupTypeContainer.defaultProps = {
}

export default CreateBucketGroupTypeContainer
