import gql from 'graphql-tag'

export default gql(`
query listSubscriptions( $userId: ID! $project: String) {
    listSubscriptions( 
        userId: $userId
        project: $project
    ) {
        id
        accessGroups
        autorenewStatus
        startDate
        endDate
        createDate
        offerCode
        segmentTag
        snapshotOffer{
            id
            offerCode
            title
            description
            type
            segmentTag
            period
            autoRenewPeriod
            subscriptionType
            createdDate
            updatedDate
            price
            freePeriod
            validUntil
            maxUsagePerUser
            additionalAccessGroups{
                id
                name
            }
        }
        planId
        customerId
        activatedAt
        renewalDate
        createdAt
        status
        price
        cancellationReason
        customerServiceAccount{
            userName
            email
            providerId
            mobileNumber
            status
        }    
        planData{
            provider{
                name
            }
        }
    }
}`)
