import React from 'react'
import PropTypes from 'prop-types'

const SettingsIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} fillRule='nonzero' d='M19.1 12l-1.07-.583c-1.16-.667-1.16-2.25 0-2.917l1.07-.583c.893-.5 1.16-1.5.625-2.25l-.892-1.417c-.535-.833-1.606-1.083-2.409-.583l-1.07.583c-1.16.667-2.677-.167-2.677-1.417V1.667C12.677.75 11.874 0 10.892 0H9.108c-.982 0-1.785.75-1.785 1.667V2.75c0 1.25-1.516 2.083-2.676 1.417l-1.071-.5c-.892-.5-1.963-.167-2.41.583L.276 5.667A1.747 1.747 0 0 0 .899 8l1.07.583c1.16.584 1.16 2.25 0 2.834L.9 12c-.892.5-1.16 1.5-.624 2.25l.892 1.417c.535.833 1.606 1.083 2.409.583l1.07-.5c1.16-.667 2.677.167 2.677 1.417v1.166c0 .917.803 1.667 1.785 1.667h1.784c.982 0 1.785-.75 1.785-1.667V17.25c0-1.25 1.516-2.083 2.676-1.417l1.071.584c.892.5 1.963.166 2.41-.584l.891-1.416c.446-.917.179-1.917-.624-2.417zM10 13.333c-1.963 0-3.569-1.5-3.569-3.333 0-1.833 1.606-3.333 3.569-3.333 1.963 0 3.569 1.5 3.569 3.333 0 1.833-1.606 3.333-3.569 3.333z' />
      <path d='M0 0h20v20H0z' />
    </g>
  </svg>
)

SettingsIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string
}

SettingsIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default SettingsIcon
