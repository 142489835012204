import React from 'react'
import PropTypes from 'prop-types'

const UploadIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='#343A40' fillRule='evenodd' d='M21 14h14v7H21v14h-7V21H0v-7h14V0h7v14z' />
  </svg>
)

UploadIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

UploadIcon.defaultProps = {
  width: 35,
  height: 35
}

export default UploadIcon
