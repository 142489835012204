import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '../../components/ui/dataEntry/inputs/Input'
import TagsInput from '../../components/ui/dataEntry/inputs/TagsInput'
import TextAreaInput from '../../components/ui/dataEntry/inputs/TextArea'
import { utilityService } from './../../services/UtilityService'

const parentCompoent = 'partner-container'
const seoTitleMaxLength = 150
const slugMaxLength = 250
const seoDescMaxLength = 155

class PartnerSeoSetting extends Component {
  render () {
    const seoDetails = this.props.details
    const { handleSeoDetailsChange } = this.props
    const { onSeoKeywordChange, onInputFieldBlur, onInputFieldFocus, isInvalidSlug } = this.props
    return (
      <div className='seo-container'>
        <Input
          title='SEO Title'
          placeholder='Insert SEO Title'
          inputName='seoTitle'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={seoDetails.title !== null && seoDetails.title !== undefined ? seoDetails.title : null}
          handleChange={handleSeoDetailsChange}
          isLimited
          limit={seoTitleMaxLength}
        />
        <Input
          title='URL Slug'
          placeholder='Insert URL Slug'
          inputName='slug'
          isRequired
          isError={isInvalidSlug}
          value={seoDetails.slug !== null && seoDetails.slug !== undefined ? seoDetails.slug : utilityService.slugifyString(this.props.defaultTitle.toLowerCase())}
          handleChange={handleSeoDetailsChange}slugMaxLength
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          errorMessage={'Invalid Slug'}
          isLimited
          limit={slugMaxLength}
        />
        <TextAreaInput
          title='Meta Description'
          placeholder='Write a short description...'
          inputName='seoMetaDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={seoDetails.metaDescription}
          handleChange={handleSeoDetailsChange}
          isLimited
          limit={seoDescMaxLength}
        />
        <div className='input-field'>
          <label className='input-title'>Keywords</label>
          <TagsInput
            selectedItems={seoDetails.keywords || []}
            tagList={[]}
            className={'key-word-dropdown'}
            mode={'tags'}
            onChange={onSeoKeywordChange}
            placeholder='Insert Keywords'
            displayParameter='name'
            parentCompoent={parentCompoent}
          />
        </div>
      </div>
    )
  }
}

PartnerSeoSetting.propTypes = {
  /** details of PartnerSeoSetting. */
  details: PropTypes.object.isRequired,
  /** seo data change action of PartnerSeoSetting. */
  handleSeoDetailsChange: PropTypes.func,
  /** keyword change action of PartnerSeoSetting. */
  onSeoKeywordChange: PropTypes.func,
  /** default title of PartnerSeoSetting. */
  defaultTitle: PropTypes.string,
  /** function to handle blur changes in PartnerSeoSetting */
  onInputFieldBlur: PropTypes.func,
  /** function to handle focus changes in PartnerSeoSetting */
  onInputFieldFocus: PropTypes.func
}

PartnerSeoSetting.defaultProps = {}

export default PartnerSeoSetting
