import gql from 'graphql-tag'

export default gql(`
mutation createApiKey($label: String!, $app: [ID]!, $project: String) {
    createApiKey(
        input: {
            label: $label
            app: $app
        },
        project: $project
    ) {
        id
        apiKey
        label
        app{
            id
            key
            name
            project
            updatedAt
            url
            description
            createdAt
            bundleId
        }
        isDisabled
        isArchived
        createdAt
    }
}`
)
