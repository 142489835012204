import gql from 'graphql-tag'

export default gql(`
    mutation disableUser($id: String $project: String) {
        disableUser(
            input: {
                id: $id
            }
            project: $project
        ){
            id
        }
    }
`)
