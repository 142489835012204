import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    getOffer(
        id: $id
        project: $project
        ) {
        id
        externalId
        name
        description
        shortDescription
        mediumDescription
        terms
        partner{
            id
            name
            logo{
                id
                mediaId
                project
                name
                url
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            attributes{
                label
                type
                value
                name
                toggleValue
              }
        }
        prices{
            id
            name
            startDate
            endDate
            currency
            displayPrice
            wholeSalePrice
            rrpIncGst
            rrpExcGst
            gstAmount
            promoId
            customMeta{
                config{
                    id
                    name
                    section
                    type
                    default
                }
                value
            }
        }
        isActive
        startDate
        endDate
        recurrence
        isArchived
        attributes{
            label
            type
            value
            name
            toggleValue
          }
        assets {
            id
        }
        customMeta{
            config{
                id
                name
                type
                section
                default
            }
            value
        }
        }
    }
    `)
