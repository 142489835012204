import React from 'react'
import PropTypes from 'prop-types'

const ExpandIcon = ({ width, height, onClick, className, ...props }) => (
  <svg className='expand-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' d='M16 18.788L14.706 20 4 10 14.706 0 16 1.206 6.594 10z' />
    </g>
  </svg>
)

ExpandIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ExpandIcon.defaultProps = {
  width: 20,
  height: 20
}

export default ExpandIcon
