import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListAssetType from '../../../graphQL/admin/asset/listAssetTypes'
import MutationDeleteAssetType from '../../../graphQL/admin/asset/deleteAssetType'
import MutationBatchUpdateAssetType from '../../../graphQL/admin/asset/batchUpdateAssetTypes'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import { utilityService } from '../../../services/UtilityService'
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
import CheckIcon from '../../../components/ui/general/icons/CheckIcon'
import CreateAssetTypeModal from '../../../components/ui/dataEntry/other/CreateAssetTypeModal'
import CropImageModal from '../../../components/ui/dataEntry/other/CropImageModal'
import AddImageModal from '../../../components/ui/dataEntry/other/AddImageModal'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import { getCropImageUrl } from '../../../util/util'

const options = [
  {
    type: 'duplicate',
    name: 'Duplicate'
  },
  {
    type: 'edit',
    name: 'Edit'
  }
]
const optionalData = [
  {
    title: 'Author Field',
    key: 'authorSection'
  },
  {
    title: 'Content',
    key: 'contentSection'
  },
  {
    title: 'Video',
    key: 'videoSection'
  },
  {
    title: 'Program',
    key: 'programSection'
  },
  {
    title: 'Match Field',
    key: 'matchSection'
  },
  {
    title: 'Credits',
    key: 'creditSection'
  },
  {
    title: 'Parental Rating',
    key: 'parentalRatingSection'
  }
]

const configData = [
  {
    title: 'Match Manager Link',
    key: 'isMatchRelated'
  },
  {
    title: 'Auto Trigger',
    key: 'isLveToVodSupported'
  },
  {
    title: 'Auto Publish',
    key: 'autoPublish'
  }
]

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class AssetType extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedType: null,
      isLoading: !(props.listAssetTypes && props.listAssetTypes.length),
      isImageModified: false,
      isShowCreateAssetTypeModal: false,
      showDeleteAssetTypeModal: false,
      selectedAssetType: undefined,
      deleteLoading: false,
      isShowImageModal: false,
      isModalLoading: false,
      selectedImage: null,
      selectedDraftImage: null,
      isFromDraft: false,
      isEdit: false,
      isDragStart: false,
      expandedRowKeys: [],
      assetTypeList: props.listAssetTypes && props.listAssetTypes.length ? props.listAssetTypes : []
    }
    this.columns = [
      {
        title: 'Asset Type',
        dataIndex: 'title',
        key: 'title',
        width: '37%'
      },
      {
        title: '# of Assets',
        dataIndex: 'usedCount',
        width: '50%',
        key: 'usedCount',
        render: (usedCount) => this.renderAssetTypeCount(usedCount)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.innerColumns = [
      {
        title: 'General Meta Data',
        dataIndex: 'title',
        key: 'title',
        width: '90%',
        render: (title) => <div className='inner-header'>{title}</div>
      }
    ]
    this.metaDataColumns = [
      {
        title: 'Meta Data Field',
        dataIndex: 'displayName',
        key: 'displayName',
        width: '30%'
      },
      {
        title: 'Field Type',
        dataIndex: 'type',
        key: 'type',
        width: '30%'
      },
      {
        title: 'Required',
        dataIndex: 'isRequired',
        key: 'isRequired',
        width: '40%',
        render: (isRequired) => <div className='inner-header'>{isRequired ? <DoneIcon /> : <CrossIcon color={'#f5212d'} /> }</div>
      }
    ]
    this.optionalDataColumns = [
      {
        title: 'Section',
        dataIndex: 'title',
        key: 'title',
        width: '30%'
      },
      {
        title: 'Enable',
        dataIndex: 'isEnabled',
        key: 'isEnabled',
        width: '70%',
        render: (isEnabled) => <div className='inner-header'>{isEnabled ? <CheckIcon /> : <CheckIcon color={'#CFD0D1'} /> }</div>
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.listAssetTypes, this.props.listAssetTypes)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, assetTypeList: _.cloneDeep(newProps.listAssetTypes).sort((a, b) => (a.position - b.position)) })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.listAssetTypes, this.props.listAssetTypes)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.listAssetTypes)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'transfer', 'delete', 'duplicate']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedType) {
        this.setState({ openedType: null })
      }
    }

    showCreateAssetTypeModal = (isShowCreateAssetTypeModal) => {
      // if (isShowCreateAssetTypeModal) {
      this.setState({ croppedUrl: '', croppedDraftUrl: '', selectedImage: null, selectedDraftImage: null, isShowCreateAssetTypeModal, selectedAssetType: null, isEdit: false, isImageModified: false })
      // } else {
      //   this.setState({ croppedUrl: '',croppedDraftUrl:'', selectedImage: null, selectedDraftImage: null, isShowCreateAssetTypeModal, selectedAssetType: null })
      // }
    }

    showImageModal = (isShowImageModal, isFromDraft) => {
      this.setState({ isShowImageModal, isFromDraft })
    }

    showCropModal = (images) => {
      let { selectedImage } = _.cloneDeep(this.state)
      if (images) {
        selectedImage = _.cloneDeep(images)
      }
      const aspectRatio = selectedImage.aspectRatio.find(item => item.title === '1:1')
      const settings = selectedImage.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
      this.setState({ selectedImage, settings, aspectRatio, isFromDraft: false }, () => this.setState({ isShowCropModal: true }))
    }

    showDraftCropModal = (images) => {
      let { selectedDraftImage } = _.cloneDeep(this.state)
      if (images) {
        selectedDraftImage = _.cloneDeep(images)
      }
      const draftAspectRatio = selectedDraftImage.aspectRatio.find(item => item.title === '1:1')
      const draftSettings = selectedDraftImage.settings.filter(item => item.aspectRatio === draftAspectRatio.aspectRatio)
      this.setState({ selectedDraftImage, draftSettings, draftAspectRatio, isFromDraft: true }, () => this.setState({ isShowCropModal: true }))
    }

    saveCurrentCrop = (settings) => {
      const { isFromDraft } = this.state
      const image = isFromDraft ? _.cloneDeep(this.state.selectedDraftImage) : _.cloneDeep(this.state.selectedImage)
      const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
      const currentaspectId = currentAspectRatio.aspectRatio
      const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
      // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
      image.settings.map(setting => {
        setting.outputFormat = 'JPG'
        delete setting[ '__typename' ]
      })
      image.settings[changedIndex].x1 = settings.x1
      image.settings[changedIndex].x2 = settings.x2
      image.settings[changedIndex].y1 = settings.y1
      image.settings[changedIndex].y2 = settings.y2
      // this.props.updateImage(image.id, image.name, tags, image.settings)
      const croppedUrl = getCropImageUrl(image, settings)
      if (isFromDraft) { this.setState({ isShowCropModal: false, isShowCreateAssetTypeModal: true, croppedDraftUrl: croppedUrl, selectedDraftImage: image, isImageModified: true }) } else { this.setState({ isShowCropModal: false, isShowCreateAssetTypeModal: true, croppedUrl, selectedImage: image, isImageModified: true }) }
    }

    toggleCropModal = (isShowCropModal) => {
      this.setState({ isShowCropModal })
    }

    saveCurrentSelection = (images) => {
      const { isFromDraft } = this.state
      if (isFromDraft) { this.setState({ selectedDraftImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true, isFromDraft: false }) } else { this.setState({ selectedImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true }) }
    }

    toggleShowOptions = id => {
      if (this.state.openedType === id) {
        this.setState({ openedType: null })
      } else {
        this.setState({ openedType: id })
      }
    }
    clearSelection = (isFromDraft) => {
      if (isFromDraft) { this.setState({ selectedDraftImage: null, croppedDraftUrl: '' }) } else { this.setState({ selectedImage: null, croppedUrl: '' }) }
    }

    handleAddAssetType = () => {
      this.setState({ isShowCreateAssetTypeModal: false, selectedAssetType: null, selectedImage: null, selectedDraftImage: null, croppedUrl: '', croppedDraftUrl: '', isImageModified: false })
    }

    onSelectOption = (id, selectedOption) => {
      const { listAssetTypes } = this.props
      const selectedAssetType = listAssetTypes.find(item => item.id === id)
      if (!selectedAssetType) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ isShowCreateAssetTypeModal: true, selectedAssetType, isEdit: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteAssetTypeModal: true, selectedAssetType })
      } else if (selectedOption.type === 'duplicate') {
        this.setState({ isShowCreateAssetTypeModal: true, selectedAssetType, isEdit: false })
      }
      this.setState({ openedType: null })
    }

    loadMoreAsset = () => {
      if (!this.props.nextToken || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreTypes(this.props.nextToken))
    }

    toggleDeleteWarningMessage = () => {
      this.setState({ isDeleteSuccess: true, selectedAssetType: null }, () => {
        setTimeout(() => {
          this.setState({ showDeleteAssetTypeModal: false, isDeleteSuccess: false })
        }, 100)
      })
    }

    onDeleteAssetTypeModal = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isModalLoading: true }, () => {
        const { selectedAssetType } = this.state
        const variables = { id: selectedAssetType.id }
        this.props.deleteAssetType(variables).then(() => {
          onHistoryUpdate()
          let { assetTypeList } = this.state
          const index = assetTypeList.findIndex(item => item.id === selectedAssetType.id)
          assetTypeList.splice(index, 1)
          this.setState({ isDeleteSuccess: true, selectedAssetType: null }, () => {
            setTimeout(() => {
              this.setState({ isModalLoading: false, showDeleteAssetTypeModal: false, assetTypeList, isDeleteSuccess: false })
            }, 100)
          })
          message.success('Asset type deleted successfully')
        }, error => {
          this.setState({ isDeleteSuccess: true, selectedAssetType: null }, () => {
            setTimeout(() => {
              this.setState({ isModalLoading: false, showDeleteAssetTypeModal: false, isDeleteSuccess: false })
            }, 100)
          })
          this.setState({ isModalLoading: false, showDeleteAssetTypeModal: false })
          utilityService.handleError(error)
        })
      })
    }

    renderAssetTypeCount = (count) => {
      if (!count) {
        return '0'
      } else {
        return count
      }
    }

    onDragEnd = (result) => {
      const { listAssetTypes, onHistoryUpdate } = this.props
      const startIndex = result.source.index
      const endIndex = result.destination.index
      let { assetTypeList } = this.state
      const [ removed ] = assetTypeList.splice(startIndex, 1)
      removed.trackPosChange = true
      assetTypeList.splice(endIndex, 0, removed)
      assetTypeList = assetTypeList.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({ assetTypeList, isDragStart: false })
      const changedTypes = assetTypeList.filter(assetType => {
        const selectedItem = listAssetTypes.find(innerItem => innerItem.id === assetType.id)
        return selectedItem.position !== assetType.position
      })
      const variables = (changedTypes || []).map(item => {
        return {
          id: item.id,
          position: item.position,
          title: item.title,
          authorSection: item.authorSection,
          contentSection: item.contentSection,
          videoSection: item.videoSection,
          programSection: item.programSection,
          isActive: item.isActive,
          isMatchRelated: item.isMatchRelated,
          isLveToVodSupported: item.isLveToVodSupported,
          isDefault: item.isDefault,
          icon: item.icon ? item.icon.id : null,
          matchSection: item.matchSection,
          draftIcon: item.draftIcon ? item.draftIcon.id : null,
          trackPosChange: item.trackPosChange,
          entertainmentMetadata: item.entertainmentMetadata,
          creditSection: item.creditSection,
          parentalRatingSection: item.parentalRatingSection,
          assetTypeCategory: item.assetTypeCategory,
          metaFields: item.metaFields === null ? null : (item.metaFields || []).map(meta => {
            delete meta.__typename
            delete meta.id
            return meta
          })
        }
      })
      this.props.batchUpdateAssetTypes({ updateData: variables }).then(() => {
        onHistoryUpdate()
      })
    }

    onDragStart = () => {
      const { expandedRowKeys } = this.state
      if (expandedRowKeys.length) { this.setState({ expandedRowKeys: [], isDragStart: true }) } else { this.setState({ isDragStart: true }) }
    }

     dragableBodyRow = (item) => {
       const { isDragStart } = this.state
       const { index } = item
       return <Draggable key={item['data-row-key']} draggableId={item['data-row-key']} index={index}>
         { (provided, snapshot) => {
           return <tr className={`ant-table-row${isDragStart ? ' dragEnable' : ''}`}
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={getItemStyle(
               snapshot.isDragging,
               provided.draggableProps.style
             )}
           >
             {item.children}
           </tr>
         }}</Draggable>
     }

     handleRowExpand = (record) => {
       this.setState(prevState =>
         prevState.expandedRowKeys.includes(record.id)
           ? {
             expandedRowKeys: prevState.expandedRowKeys.filter(
               key => key !== record.id
             )
           }
           : { expandedRowKeys: [...prevState.expandedRowKeys, record.id] }
       )
     }

    renderData = (id) => {
      let availableOptions = []
      const { openedType } = this.state
      const { listAssetTypes } = this.props
      const selectedAssetType = listAssetTypes.find(item => item.id === id)
      availableOptions = [ ...options ]
      if (selectedAssetType && !(selectedAssetType.isDefault || selectedAssetType.usedCount || this.isDeleteDisabled)) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(1, 1)
      }
      if (this.isCreateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedType === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    renderMetaData = (data) => {
      return <Table
        className={`inner-table`}
        rowKey={record => record.type}
        columns={this.metaDataColumns}
        dataSource={data}
        pagination={false}
      />
    }

    renderOptionalData = (data, isConfig) => {
      const sectionData = isConfig ? [...configData] : [...optionalData]
      const optionalDataOption = sectionData.map(option => {
        option.isEnabled = data[option.key]
        return option
      })
      return <Table
        className={`inner-table`}
        rowKey={record => record.key}
        columns={this.optionalDataColumns}
        dataSource={optionalDataOption}
        pagination={false}
      />
    }

    renderInnerRows = (data) => {
      return <React.Fragment>
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'General Meta Data' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderMetaData(data.metaFields)}
        />
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'Optional Sections' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderOptionalData(data)}
        />
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'Config' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderOptionalData(data, true)}
        />
      </React.Fragment>
    }

    render () {
      const { isLoading, nextToken, onHistoryUpdate } = this.props
      const { isPaginating, isShowCreateAssetTypeModal, isShowImageModal, isShowCropModal, settings, aspectRatio, draftAspectRatio, isFromDraft, selectedImage, selectedDraftImage,
        croppedUrl, croppedDraftUrl, showDeleteAssetTypeModal, isModalLoading, selectedAssetType, isEdit, draftSettings, isImageModified, expandedRowKeys, assetTypeList, isDeleteSuccess } = this.state
      const tempAssetType = _.cloneDeep(selectedAssetType)
      if (!_.isEmpty(tempAssetType)) { delete (tempAssetType.title) }

      const components = {
        body: {
          row: this.dragableBodyRow
        }
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const userPermissionsContent = permissions['CONTENT_BANK']
            const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
            const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
            this.isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal asset-type-container'>
              <div className='admin-list'>
                <FixedTableHeader columnCellList={this.columns} isExpandable />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={nextToken}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                      <Droppable droppableId={'#AssetType_123'}>
                        { (droppableProvided, droppableSnapshot) => (

                          <div className='asset-type-table-container'
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            <Table
                              className={`general-table ${isPaginating ? 'paginating' : ''}`}
                              rowKey={record => record.id}
                              columns={this.columns}
                              dataSource={assetTypeList}
                              pagination={false}
                              expandedRowRender={(data) => this.renderInnerRows(data)}
                              expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => this.handleRowExpand(record)}
                              components={components}
                              onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow
                              })} />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={() => this.showCreateAssetTypeModal(true, true)}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={this.isCreateDisabled}
                />
              </div>
              <CreateAssetTypeModal
                isVisible={isShowCreateAssetTypeModal}
                handleCancel={() => this.showCreateAssetTypeModal(false)}
                handleSubmit={this.handleAddAssetType}
                showImageModal={(isFromDraft) => this.showImageModal(true, isFromDraft)}
                icon={selectedImage}
                draftIcon={selectedDraftImage}
                croppedUrl={croppedUrl}
                croppedDraftUrl={croppedDraftUrl}
                clearSelection={this.clearSelection}
                showCropModal={(image, isFromDraftIcon) => isFromDraftIcon ? this.showDraftCropModal(image) : this.showCropModal(image)}
                selectedType={(!isEdit && selectedAssetType) ? tempAssetType : selectedAssetType}
                isEdit={isEdit}
                isSubmitDisabled={isEdit ? this.isUpdateDisabled : this.isCreateDisabled}
                isImageModified={isImageModified}
                isDeleteSuccess={isDeleteSuccess}
                maxPosition={assetTypeList && assetTypeList.length ? assetTypeList[assetTypeList.length - 1].position : 0}
                onHistoryUpdate={onHistoryUpdate}
                project={project}
              />
              <CropImageModal
                isVisible={isShowCropModal}
                aspectRatio={isFromDraft ? draftAspectRatio : aspectRatio}
                imgUrl={isFromDraft ? (draftSettings && draftSettings.length ? draftSettings[0].fileName : null) : (settings && settings.length ? settings[0].fileName : null)}
                settings={isFromDraft ? draftSettings : settings}
                updateSettings={this.saveCurrentCrop}
                handleCancel={() => this.toggleCropModal(false)}
                isUpdateBlocked={isUpdateDisabledContent}
              />
              <AddImageModal
                isMultiSelect={false}
                isVisible={isShowImageModal}
                handleSubmit={this.saveCurrentSelection}
                handleCancel={() => this.showImageModal(false)}
                isSubmitButtonDisabled={isCreateDisabledContent}
                isUpdateBlocked={isUpdateDisabledContent}
                mediaType='IMAGE'
                project={project} />
              <ConfirmModal
                isVisible={showDeleteAssetTypeModal}
                title={'Delete Asset Types'}
                message={'Do you want to delete this asset type ?'}
                isLoading={isModalLoading}
                rightButtonText={'Confirm'}
                handleCancel={this.toggleDeleteWarningMessage}
                handleSubmit={this.onDeleteAssetTypeModal}
                isSubmitButtonDisabled={this.isDeleteDisabled}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

AssetType.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListAssetType,
    {
      options: ({ nextToken, project }) => {
        const filter = null
        const variables = { limit: 20, nextToken: nextToken || null, filter, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes,
          isLoading: data.loading,
          nextToken: data.listAssetTypes ? data.listAssetTypes.nextToken : null,
          previousToken: data.listAssetTypes ? data.listAssetTypes.previousToken : null,
          getMoreTypes: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: page,
                project: ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAssetTypes.items, ...fetchMoreResult.listAssetTypes.items]
                prev.listAssetTypes.items = newList
                prev.listAssetTypes.previousToken = prev.listAssetTypes.nextToken
                prev.listAssetTypes.nextToken = fetchMoreResult.listAssetTypes.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteAssetType,
    {
      options: ({ previousToken, project }) => {
        return {
          update: (cache, { data: { deleteAssetType } }) => {
            const filter = null
            const variables = { limit: 20, nextToken: previousToken || null, filter, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAssetType, variables }))
            if (cacheData && cacheData.listAssetTypes && cacheData.listAssetTypes.items) {
              const index = cacheData.listAssetTypes.items.findIndex((item) => deleteAssetType.id === item.id)
              cacheData.listAssetTypes.items.splice(index, 1)
            }
            cache.writeQuery({
              query: QueryListAssetType,
              data: cacheData,
              variables
            })
          },
          refetchQueries: () => [{ query: QueryListAssetType,
            variables: { limit: 500,
              project,
              nextToken: null,
              filter: {
                isMatchRelated: true
              } } }]
        }
      },
      props: (props) => ({
        deleteAssetType: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchUpdateAssetType,
    {
      props: (props) => ({
        batchUpdateAssetTypes: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(AssetType))
