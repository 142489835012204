import gql from 'graphql-tag'

export default gql(`
    mutation editPageGroup($name: String $key: String! $app: ID! $project: String $isCritical: Boolean $isNameChanged: Boolean $isCriticalChanged: Boolean $assignedUsers: [String] $isAssigneeChanged: Boolean) {
        editPageGroup(
            input: {
                name: $name
                key: $key
                app: $app
                isCritical: $isCritical
                isNameChanged: $isNameChanged
                isCriticalChanged: $isCriticalChanged
                assignedUsers: $assignedUsers
                isAssigneeChanged: $isAssigneeChanged
            },
            project: $project
        ) {
            id
            key
            name
            type
            isCritical
            assignedUsers
            app{
                id
                name
                url
            }
        }
    }`
)
