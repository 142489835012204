import gql from 'graphql-tag'

export default gql(`
query($limit: Int $project: String) {
    listRating( 
    project: $project
    limit: $limit
    ){
        totalCount
        items {
          rating
          id
          scheme
          subRatings
          source
        }
      }
}`)
