import gql from 'graphql-tag'

export default gql(`
  mutation($bucketId: ID! $groupId: ID! $position: Int $project: String ) {
    createBucketGroupItem(input:{
        bucketId: $bucketId
        groupId: $groupId
        position: $position
        project: $project
    }){
      id
      position
      bucketId
      bucket{
        id
        isArchived
      }
    }
  }`
)
