import React from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import { Switch, Table, message } from 'antd'

import './../../ui/ui.style.scss'
import placeholderImage from './../../../assets/images/default-image.jpg'
import { findFileExtension, generateCroppedThumbnail, getFileSize } from './../../../util/util'
import ButtonContainer from './../general/buttons/ButtonContainer'
import PopoverConfirm from './../feedback/PopoverConfirm'
import EditIcon from './../general/icons/EditIcon'
import InputNumberApp from './../dataEntry/inputs/InputNumberApp'
import TextAreaAppInput from './../dataEntry/inputs/TextAreaApp'
import AddBucketConfigAppModal from '../dataEntry/other/AddBucketConfigAppModal'
import AddImageModal from '../dataEntry/other/AddImageModal'
import EditImageModal from '../dataEntry/other/EditImageModal'
import RichText from './../dataEntry/other/RichText'
import { utilityService } from '../../../services/UtilityService'
import InputApp from './../dataEntry/inputs/InputApp'
import AddIcon from './../general/icons/AddIcon'
import Tooltip from './../dataDisplay/ToolTip'
import InformationIcon from '../general/icons/InformationIcon'
import JsonArrayViewModal from './JsonArrayViewModal'
import EditButton from '../general/buttons/EditButton'
import CopyLinkButton from '../general/buttons/CopyLinkButton'
import BucketAppViewModal from './BucketAppViewModal'

const parentCompoent = 'single-app-config-modal'

class SingleAppConfigs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showAddIcon: false,
      selectedImgConfigIcon: null,
      showBucketAddIcon: false,
      selectedBucketconfigIcon: null,
      showBucketDetails: false
    }
  }

  hideImgAddIcon = () => {
    this.setState({
      showAddIcon: false,
      selectedImgConfigIcon: null
    })
  }

  showImgAddIcon = (selectedImgConfigIcon) => {
    this.setState({
      showAddIcon: true,
      selectedImgConfigIcon: selectedImgConfigIcon
    })
  }

  hideBucketAddIcon = () => {
    this.setState({
      showBucketAddIcon: false,
      selectedBucketconfigIcon: null
    })
  }

  showBucketAddIcon = (selectedBucketConfigIcon) => {
    this.setState({
      showBucketAddIcon: true,
      selectedBucketconfigIcon: selectedBucketConfigIcon
    })
  }

  showImageDetails = (config, imageDetails, index) => {
    const { project, handleSingleConfigDetailsChange, showImgEditorIcon, hideImgEditorIcon, showEditIcon, showImgEditor, selectedImgEditConfig, isUpdateDisabled } = this.props
    let imageUrl
    if (imageDetails) {
      imageUrl = generateCroppedThumbnail(imageDetails, 214, 120, '16:9')
    }
    const category = imageDetails && imageDetails.category ? imageDetails.category.label : null
    const imageSize = getFileSize(imageDetails.fileSize)
    return <div className={project !== 'hyperion' || 'projectx' ? 'image-content' : 'image-content-hyperion'} key={index}>
      <div className='img-container' onMouseEnter={() => showImgEditorIcon(config, imageDetails)}
        onMouseLeave={hideImgEditorIcon}>
        {<img src={imageUrl} />}
        { (showEditIcon && !isUpdateDisabled && selectedImgEditConfig.name === config.name) ? <div onClick={showImgEditor} className='img-editor-icon'> <EditIcon /> </div> : null }
      </div>
      <div className='image-content-wrapper' key={index}>
        <div className='image-content-header'>
          {imageDetails.name.length > 15 ? <ButtonContainer displayTitle={imageDetails.name} childComponent={<p>{ imageDetails.name }</p>} /> : <p>{ imageDetails.name }</p> }
          {isUpdateDisabled ? null : <div className='image-content-btns'>
            <PopoverConfirm confirm={() => handleSingleConfigDetailsChange(config, null)} parentCompoent={parentCompoent} />
          </div>}
        </div>
        <div className='image-content-details'>
          <div className='image-info'>
            <p>Output Format:</p>
            <p> { findFileExtension(imageDetails.name) || 'JPG'}</p>
          </div>
          {category ? <div className={`image-info ${category ? '' : 'hidden'}`}>
            <p>Category:</p>
            <p>{category}</p>
          </div> : ''}
          <div className={`image-info ${imageSize ? '' : 'hidden'}`}>
            <p>Original File Size:</p>
            <p>{imageSize}</p>
          </div>
        </div>
      </div>
    </div >
  }

  showBucketDetails = (meta, bucketDetails, index) => {
    const { handleSingleConfigDetailsChange, project, isUpdateDisabled } = this.props
    const { showBucketDetails } = this.state
    let isBucketPublished = bucketDetails && bucketDetails.isPublished
    let bucketId = bucketDetails && bucketDetails.id
    let copyLink = () => {
      var anchor = document.createElement('a')
      anchor.href = `${location.origin}/buckets/preview/${bucketId}`
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    }
    const status = utilityService.getPublishStatus(bucketDetails.isPublished, bucketDetails.publishStartDate, bucketDetails.publishEndDate)
    return <div className={'bucket-content-hyperion'} key={index}>
      <div className='bucket-content-wrapper' key={index}>
        <div className='bucket-content-header'>
          { <a onClick={this.handlBucketDetails}>{ bucketDetails.name }</a> }
          <div className='bucket-content-btns'>
            <div className='preview-button'>
              <ButtonContainer displayTitle='View API Response' childComponent={<CopyLinkButton onClick={copyLink} isDisabled={!isBucketPublished} />} />
            </div>
            {isUpdateDisabled ? null : <PopoverConfirm confirm={() => handleSingleConfigDetailsChange(meta, null)} parentCompoent={parentCompoent} />}          </div>
        </div>
        <div className='bucket-content-details'>
          <div className='bucket-right-info'>
            <p>{ status }</p>
            <p>{bucketDetails.key}</p>
          </div>
          <div className='bucket-left-info'>
            <p>{bucketDetails.displayName }</p>
            <p>{bucketDetails.displayType && bucketDetails.displayType.name}</p>
          </div>
        </div>
      </div>
      <BucketAppViewModal
        isVisible={showBucketDetails}
        handleCancel={this.hideBucketDetails}
        bucketDetails={bucketDetails}
        project={project}

      />
    </div >
  }

  handleJsonArrayChange = (config, value) => {
    this.props.handleSingleConfigDetailsChange(config, value)
  }

  getObjectMetaFields = (configData, selectedAppConfig) => {
    const { handleSingleConfigDetailsChange, project, handleShowAddImageModal, hideAddImageModal, saveCurrentImageSelection, showAddImageModal,
      handleShowBucketModal, showAddBucketModal, hideAddBucketModal, handleBucketSelection, selectedBucket, saveCurrentBucketSelection,
      showImgEditModal, hideImgEditor, selectedBucketConfig, selectedImgConfig, editImageDetails, isJsonArrayValid, jsonInvalidEntries, handleJsonArrayVisibilty, isUpdateDisabled } = this.props
    const { showAddIcon, selectedImgConfigIcon, selectedBucketconfigIcon, showBucketAddIcon } = this.state
    const configFields = selectedAppConfig ? _.cloneDeep(selectedAppConfig) : []
    const image = placeholderImage
    return (configFields || []).map((config, index) => {
      const selectedMeta = (configData || []).find(item => item.name === config.name && item.type === config.type)
      if (config.type === 'BOOLEAN') {
        return <div className='switch-container' key={index}>
          <div className={`switch-inner-container`} >
            <div><span>{config.name}</span>{config.isRequired ? <span className='mandatory'>*</span> : ''}{config && config.tooltipText ? <span id='info-icon'><Tooltip placement='right' childComponent={<div className='info-icon'><InformationIcon /></div>} description={config.tooltipText} parentCompoent='info-icon' /></span> : null }</div>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} disabled={isUpdateDisabled} onChange={(value) => handleSingleConfigDetailsChange(config, value)} />
          </div>
        </div>
      } else if (config.type === 'STRING') {
        return <InputApp
          key={index}
          title={config.name}
          readOnly={isUpdateDisabled}
          placeholder={`Enter ${config.name}`}
          inputName='title'
          isRequired={config.isRequired}
          value={selectedMeta ? selectedMeta.value : ''}
          handleChange={({ target }) => handleSingleConfigDetailsChange(config, target.value)}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          tooltipText={config && config.tooltipText}
        />
      } else if (config.type === 'NUMBER') {
        return <InputNumberApp
          limit={16}
          readOnly={isUpdateDisabled}
          key={index}
          title={config.name}
          placeholder={`Enter ${config.name}`}
          inputName='title'
          isRequired={config.isRequired}
          value={selectedMeta ? parseInt(selectedMeta.value) : null}
          handleChange={(value) => handleSingleConfigDetailsChange(config, value)}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          tooltipText={config && config.tooltipText}
        />
      } else if (config.type === 'RICHTEXT') {
        return <div className={`rich-text-container`} key={index}>
          <div><span>{config.name}</span>{config.isRequired ? <span className='mandatory'>*</span> : ''}
            {config && config.tooltipText ? <span id='info-icon'><Tooltip placement='right' childComponent={<div className='info-icon'><InformationIcon /></div>} description={config.tooltipText} parentCompoent='info-icon' /></span> : null }</div>
          <RichText key={config.id} id={config.id} text={selectedMeta ? selectedMeta.value : ''} readOnly={isUpdateDisabled} onRichTextChange={value => handleSingleConfigDetailsChange(config, value)} showCloseIcon={false} />
        </div>
      } else if (config.type === 'JSON') {
        return <div className='summary-container' key={index}>
          <TextAreaAppInput
            key={index}
            title={config.name}
            placeholder={`Enter ${config.name}`}
            inputName='title'
            isEditable={!isUpdateDisabled}
            value={selectedMeta ? selectedMeta.value : ''}
            handleChange={({ target }) => handleSingleConfigDetailsChange(config, target.value)}
            isRequired={config.isRequired}
            isError={selectedMeta ? selectedMeta.isError : false}
            jsonInvalidEntries={jsonInvalidEntries}
            currentId={config.id}
            tooltipText={config.tooltipText || null}
          />
        </div>
      } else if (config.type === 'JSONARRAY') {
        const handleArrayKeyConversion = (value) => {
          let selectedJsonArrayTableValue = value && isJsonArrayValid ? JSON.parse(value) : []
          let keys = [];
          (selectedJsonArrayTableValue || []).map(item => {
            keys = [...keys, ...Object.keys(item)]
          })
          return [...new Set(keys)]
        }

        const handleArrayColumTable = () => {
          let value = selectedMeta && selectedMeta.value ? selectedMeta.value : ''
          let keys = handleArrayKeyConversion(value)
          let columns = []
          keys.map(key => {
            columns.push(
              {
                title: key.toUpperCase(),
                dataIndex: key,
                key: key,
                width: 100,
                render: (data) => renderData(data)
              }
            )
          })
          return columns
        }

        const renderData = (data) => {
          if (Array.isArray(data)) {
            return null
          } else {
            return _.size(data && data.toString()) > 30
              ? <Tooltip placement='topLeft' description={data && data.toString()} childComponent={<p onClick={() => this.copyOnClick(data && data.toString(), 'Cell')}>{data && data.toString()}</p>}>
                <p onClick={() => this.copyOnClick(data && data.toString(), 'Cell')}>{data && data.toString()}</p>
              </Tooltip>
              : <p onClick={() => this.copyOnClick(data && data.toString(), 'Cell')}>{data && data.toString()}</p>
          }
        }

        let selectedJsonArrayTableValue = selectedMeta && selectedMeta.value && isJsonArrayValid ? JSON.parse(selectedMeta.value) : []
        let columns = handleArrayColumTable()
        return <div className={'json-array-summary-container'} key={index}>
          <div className='json-summary-header'>
            <div><label>{config.name}</label>
              {config.isRequired
                ? <span className='mandatory'>*</span> : ''}
              {config.tooltipText ? <span id='info-icon'>
                <Tooltip
                  placement='rightTop'
                  childComponent={<div className='info-icon'><InformationIcon /></div>}
                  description={config.tooltipText}
                  parentCompoent='info-icon' />
              </span> : null
              }
            </div>
            {isUpdateDisabled ? null : <EditButton onClick={() => handleJsonArrayVisibilty(config, selectedMeta)} />}
          </div>
          <Table className={`json-array-table`} rowKey={record => record.id} columns={columns} dataSource={selectedJsonArrayTableValue} pagination={false} scroll={{ x: 1600, y: 240 }} />
        </div>
      } else if (config.type === 'IMAGE') {
        let selectedImage = selectedMeta && selectedMeta.value
        return <React.Fragment key={index}>
          {!selectedImage
            ? <div className={`image-container ${selectedMeta && selectedMeta.isError ? 'error' : ''}`}>
              <div><span>{config.name}</span>{config.isRequired ? <span className='mandatory'>*</span> : ''}{config && config.tooltipText ? <span id='info-icon'><Tooltip placement='right' childComponent={<div className='info-icon'><InformationIcon /></div>} description={config.tooltipText} parentCompoent='info-icon' /></span> : null }</div>
              <div className='image-container-thumb' style={{ backgroundImage: `url(${image})` }} onMouseEnter={() => this.showImgAddIcon(config)}
                onMouseLeave={this.hideImgAddIcon} >
                { (showAddIcon && !isUpdateDisabled && selectedImgConfigIcon.name === config.name) ? <div onClick={() => handleShowAddImageModal(config)} className='img-add-icon'> <AddIcon /> </div> : null }
              </div>
            </div>
            : <div className='image-details'> <div><span>{config.name}</span>{config.isRequired ? <span className='mandatory'>*</span> : ''}{config && config.tooltipText ? <span id='info-icon'><Tooltip placement='right' childComponent={<div className='info-icon'><InformationIcon /></div>} description={config.tooltipText} parentCompoent='info-icon' /></span> : null }</div>
              {this.showImageDetails(config, selectedMeta.value, index)}
            </div>}
          <AddImageModal
            isMultiSelect={false}
            isVisible={showAddImageModal && !isUpdateDisabled}
            handleSubmit={(selectedImage) => saveCurrentImageSelection(selectedImgConfig, selectedImage, 'CONFIG')}
            handleCancel={hideAddImageModal}
            mediaType='IMAGE'
            isUpdateDisabled={isUpdateDisabled}
            isSubmitButtonDisabled={isUpdateDisabled}
            project={project}
          />
          <EditImageModal
            type={'IMAGE'}
            isVisible={showImgEditModal && !isUpdateDisabled}
            hideImageEditor={hideImgEditor}
            imageId={editImageDetails && editImageDetails.id}
            mediaDetails={editImageDetails && editImageDetails}
            // query={queryParams}
            isUpdateBlocked={isUpdateDisabled}
            isUploadBlocked={isUpdateDisabled}
            project={project}
          />
        </React.Fragment>
      } else if (config.type === 'BUCKET') {
        let selectedBucketValue = selectedMeta && selectedMeta.value
        return <React.Fragment key={index}>
          {!selectedBucketValue ? <div className={`bucket-container  ${selectedMeta && selectedMeta.isError ? 'error' : ''}`} >
            <div><span>{config.name}</span>{config.isRequired ? <span className='mandatory'>*</span> : ''}{config && config.tooltipText ? <span id='info-icon'><Tooltip placement='right' childComponent={<div className='info-icon'><InformationIcon /></div>} description={config.tooltipText} parentCompoent='info-icon' /></span> : null }</div>
            <div className='bucket-container-thumb' style={{ backgroundImage: `url(${image})` }} onMouseEnter={() => this.showBucketAddIcon(config)}
              onMouseLeave={this.hideBucketAddIcon} >
              { (showBucketAddIcon && !isUpdateDisabled && selectedBucketconfigIcon.name === config.name) ? <div onClick={() => handleShowBucketModal(config)} className='bucket-add-icon'> <AddIcon /> </div> : null }
            </div>
          </div>
            : <div className='bucket-details-config'>
              <div className='info-header'>
                <span>{config.name}</span>
                {config.isRequired ? <span className='mandatory'>*</span> : ''}
                {config && config.tooltipText
                  ? <div id='info-icon'>
                    <Tooltip
                      placement='right'
                      childComponent={<div className='info-icon'><InformationIcon /></div>}
                      description={config.tooltipText}
                      parentCompoent='info-icon'
                    />
                  </div> : null }
              </div>
              {this.showBucketDetails(config, selectedMeta.value, index)}
            </div>}
          <AddBucketConfigAppModal
            isVisible={showAddBucketModal}
            handleCancel={hideAddBucketModal}
            handleBucketSelection={handleBucketSelection}
            selectedBucket={selectedBucket}
            handleSave={() => saveCurrentBucketSelection(selectedBucketConfig, 'CONFIG')}
            project={project}
          />
        </React.Fragment>
      }
    })
  }

  copyOnClick = (data, type) => {
    navigator.clipboard.writeText(data, type)
    message.success(`${type} Copied Successfully`)
  }

  handlBucketDetails = () => {
    this.setState({
      showBucketDetails: true
    })
  }

  hideBucketDetails = () => {
    this.setState({
      showBucketDetails: false
    })
  }

  render () {
    const { singleConfigDetails, details, jsonArrayInvalidEntries, handleSingleConfigDetailsChange, isJsonArrayValid, handleJsonArrayHide,
      isJsonArrayVisible, jsonConfig, jsonSelectedMeta } = this.props
    return (
      <div id='single-app-config-modal' className={`single-app-config-modal`}>
        {this.getObjectMetaFields(singleConfigDetails, _.sortBy(details.fields, 'position'))}
        {isJsonArrayVisible ? <JsonArrayViewModal
          key={jsonConfig.name}
          title={jsonConfig.name}
          placeholder={`Enter ${jsonConfig.name}`}
          inputName='title'
          value={jsonSelectedMeta ? jsonSelectedMeta.value : ''}
          isRequired={jsonConfig.isRequired}
          isError={jsonSelectedMeta ? jsonSelectedMeta.isError : false}
          handleChange={({ target }) => handleSingleConfigDetailsChange(jsonConfig, target.value)}
          isJsonArrayValid={isJsonArrayValid}
          jsonArrayInvalidEntries={jsonArrayInvalidEntries}
          currentId={jsonConfig.id}
          isVisible={isJsonArrayVisible}
          handleCancel={handleJsonArrayHide}
          jsonConfig={jsonConfig}
          handleJsonArrayChange={this.handleJsonArrayChange}
        /> : null}
      </div>
    )
  }
}

SingleAppConfigs.propTypes = {

}

SingleAppConfigs.defaultProps = {
}

export default SingleAppConfigs
