import React from 'react'
import PropTypes from 'prop-types'
import PopoverConfirm from './../feedback/PopoverConfirm'
import TwitterIcon from './../general/icons/TwitterIcon'
import FacebookIcon from './../general/icons/FacebookIcon'
import Pinterest from './../general/icons/Pinterest'
import InstagramIcon from './../general/icons/InstagramIcon'
import './../ui.style.scss'

const parentCompoent = 'asset-container'

const SocialMediaShare = ({ onCloseContent, ...props }) => (
  <div className='social-media-default'>
    <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
    <div className='social-media'>
      <TwitterIcon />
      <FacebookIcon />
      <Pinterest />
      <InstagramIcon />
    </div>

  </div>
)

SocialMediaShare.propTypes = {
  /** close action of SocialMediaShare. */
  onCloseContent: PropTypes.func
}

SocialMediaShare.defaultProps = {
}

export default SocialMediaShare
