import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import Input from '../inputs/Input'
import InformationIcon from '../../general/icons/InformationIcon'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryBucketDisplayTypeList from '../../../../graphQL/bucket/getBucketDisplayType'
import MutationCreateBucketDisplayType from '../../../../graphQL/admin/bucket/createBucketDisplayType'
import MutationUpdatBucketDisplayType from '../../../../graphQL/admin/bucket/updateBucketDisplayType'
import { utilityService } from '../../../../services/UtilityService'

// import { utilityService } from '../../../../services/UtilityService'

class CreateBucketDisplayTypesModal extends Component {
    state = {
      displayTypeName: undefined,
      displayTypeKey: undefined,
      isLoading: false,
      isEdited: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedDisplayType && (!_.isEqual(this.props.selectedDisplayType, nextProps.selectedDisplayType) || (!this.state.displayTypeName && !this.state.displayTypeKey))) {
        this.setState({
          displayTypeName: nextProps.selectedDisplayType.name,
          displayTypeKey: nextProps.selectedDisplayType.key
        })
      } else if (!nextProps.selectedDisplayType && this.props.selectedDisplayType && this.state.displayTypeName && this.state.displayTypeKey) {
        this.setState({ displayTypeName: '', displayTypeKey: '' })
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      let { displayTypeKey, displayTypeName } = this.state
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          if ((!displayTypeKey && !displayTypeName) || displayTypeKey === utilityService.slugifyString(displayTypeName || '').toUpperCase()) {
            displayTypeKey = utilityService.slugifyString(value || '').toUpperCase()
          }
          this.setState({ displayTypeName: value, displayTypeKey, isEdited: true })
        }
        if (name === 'key') {
          displayTypeKey = (utilityService.slugifyString(value || '')).toUpperCase()
          this.setState({ displayTypeKey, isEdited: true })
        }
      }
    }

    handleAddbucketDisplayType = () => {
      const { displayTypeName, displayTypeKey } = this.state
      const { selectedDisplayType, handleSubmit, displayTypeList, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })
      const bucketDisplayTypeRequest = {
        name: displayTypeName,
        key: displayTypeKey
      }
      const isSameKeyPresent = displayTypeList.filter(item => item.key === displayTypeKey)
      if (!selectedDisplayType) {
        if (isSameKeyPresent && isSameKeyPresent.length) {
          message.error('Duplicate key found. Please try another key')
          this.setState({ isLoading: false })
          return
        }
        this.props.createBucketDisplayType(bucketDisplayTypeRequest).then(() => {
          handleSubmit()
          onHistoryUpdate()
          this.setState({ isLoading: false })
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false })
        })
      } else {
        if (isSameKeyPresent && isSameKeyPresent.length && isSameKeyPresent[0].id !== selectedDisplayType.id) {
          message.error('Duplicate key found. Please try another key')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateBucketDisplayType({ id: selectedDisplayType.id, ...bucketDisplayTypeRequest }).then(() => {
          handleSubmit()
          onHistoryUpdate()
          this.setState({ isLoading: false })
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false })
        })
        this.setState({ isLoading: false })
      }
    }

    resetState = () => {
      const state = {
        displayTypeName: '',
        displayTypeKey: '',
        isLoading: false,
        isEdited: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedDisplayType, isSubmitDisabled } = this.props
      const { displayTypeName, displayTypeKey, isLoading, isEdited } = this.state
      const keyInput = <div className='type'>
        <span>Key</span>
        <div className='information-icon'>
          <InformationIcon />
        </div>
      </div>
      return (
        <Modal
          className='general-modal create-bucket-display-type-modal'
          title={selectedDisplayType ? 'EDIT DISPLAY TYPE' : 'NEW DISPLAY TYPE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddbucketDisplayType}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (isLoading || !displayTypeName || !displayTypeKey || !isEdited || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-bucket-display-type'>
            <Input title='Display Type Name' isRequired inputName='name' handleChange={this.handleChange} value={displayTypeName} />
            <Input title={keyInput} inputName='key' handleChange={this.handleChange} value={displayTypeKey} />
          </div>
        </Modal>
      )
    }
}

CreateBucketDisplayTypesModal.propTypes = {
  /** visible file status of CreateBucketDisplayTypesModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateBucketDisplayTypesModal. */
  handleCancel: PropTypes.func
}

CreateBucketDisplayTypesModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationCreateBucketDisplayType, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { createDisplayType } }) => {
              const variables = { filter: null, limit: 50, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketDisplayTypeList, variables }))
              if (cacheData && cacheData.listDisplayType && cacheData.listDisplayType.items) { cacheData.listDisplayType.items.push(createDisplayType) }
              cache.writeQuery({
                query: QueryBucketDisplayTypeList,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          createBucketDisplayType: (request) => {
            let variables = request
            variables.project = props.ownProps.project
            return props.mutate({
              variables
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdatBucketDisplayType, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { updateDisplayType } }) => {
              const variables = { filter: null, limit: 50, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketDisplayTypeList, variables }))
              if (cacheData && cacheData.listDisplayType && cacheData.listDisplayType.items) {
                const selectedAuthorIndex = cacheData.listDisplayType.items.findIndex(item => item.id === updateDisplayType.id)
                if (selectedAuthorIndex > -1) {
                  cacheData.listDisplayType.items[selectedAuthorIndex] = updateDisplayType
                }
              }
              cache.writeQuery({
                query: QueryBucketDisplayTypeList,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          updateBucketDisplayType: (variables) => {
            variables.project = props.ownProps.project
            return props.mutate({
              variables
            })
          }
        })
      }
    ),
    graphql(
      QueryBucketDisplayTypeList,
      {
        options: ({ project }) => {
          const variables = { limit: 50, project }
          return {
            variables
          }
        },
        props: ({ data }) => {
          return {
            displayTypeList: data && data.listDisplayType && data.listDisplayType.items ? [ ...data.listDisplayType.items ] : undefined
          }
        }
      }
    )

  )(CreateBucketDisplayTypesModal)
)
