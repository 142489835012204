import React from 'react'
import PropTypes from 'prop-types'
import MultilineEllipsis from './../../MultilineEllipse'
import ButtonContainer from '../general/buttons/ButtonContainer'
import './../ui.style.scss'
import _ from 'lodash'
import folderImage from '../../../assets/images/folder.svg'

const FolderDisplay = ({ selected, fileName, folderIndex, onSelect, onDoubleClick, ...props }) => {
  return (
    <div className={`folder-file-display ${selected ? 'selected' : ''}`} onClick={(e) => onSelect(folderIndex, e)} onDoubleClick={onDoubleClick}>
      <div className='folder-item'>
        <img src={folderImage} />
      </div>
      <div className='folder-name'>
        {fileName && fileName.length > 35 ? <ButtonContainer displayTitle={fileName} childComponent={<MultilineEllipsis maxLines={'2'} text={fileName} />} />
          : <MultilineEllipsis maxLines={'2'} text={!_.isEmpty(fileName) ? fileName : 'folder'} />}
      </div>
    </div>
  )
}

FolderDisplay.propTypes = {
  /** fileName of FolderDisplay. */
  fileName: PropTypes.string.isRequired,
  /** Select action of FolderDisplay. */
  onSelect: PropTypes.func,
  /** selected status of FolderDisplay. */
  selected: PropTypes.bool,
  /** index of the folder item */
  folderIndex: PropTypes.number
}

FolderDisplay.defaultProps = {
  fileName: 'Image File name 01'
}

export default FolderDisplay
