import gql from 'graphql-tag'

export default gql(`
    query($fileName: String! $contentType: String! $type: MediaType!) {
        getMultipartUploadId(input: {
            type: $type
            fileName: $fileName
            contentType: $contentType
        } )
        {
            fileKey
            uploadId
        }
    }
    `
)
