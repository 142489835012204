import gql from 'graphql-tag'

export default gql(`
    mutation createBucketGroupType($name: String! $position: Int! $project: String ) {
        createBucketGroupType(
            input: {
                name: $name
                position: $position
            },
            project: $project
        ) {
            id
            name
            position
            usedCount
        }
    }`
)
