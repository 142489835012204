import React from 'react'
import PropTypes from 'prop-types'

const SaveIcon = ({ width, height, color, onClick, ...props }) => (
  <svg className='save-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill={color} fillRule='nonzero' d='M16.364 0h-1.819v5.455a.91.91 0 0 1-.909.909H5.455a.91.91 0 0 1-.91-.91V0H1.818A1.818 1.818 0 0 0 0 1.818v16.364C0 19.186.814 20 1.818 20h16.364A1.818 1.818 0 0 0 20 18.182V3.636L16.364 0zm0 18.182H3.636v-5.455c0-1.004.814-1.818 1.819-1.818h9.09c1.005 0 1.819.814 1.819 1.818v5.455z' />
      <path fill={color} fillRule='nonzero' d='M10.909 0h1.818v4.545h-1.818z' />
    </g>
  </svg>
)

SaveIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

SaveIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default SaveIcon
