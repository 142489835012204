import React from 'react'
import PropTypes from 'prop-types'

const TrimmerIcon = ({ width, className, height, disabled, color, onClick, style, ...props }) => (
  <svg className={`trimmer-icon ${className}`} width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={disabled ? () => {} : onClick} style={style}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill={color} d='M11.2 17.6h1.6V16h-1.6V6.4h1.6V4.8h-1.6V4h8a.8.8 0 0 1 .8.8v12.8a.8.8 0 0 1-.8.8h-8v-.8zm-1.6-16V0h.8v20h-.8v-4H.8a.8.8 0 0 1-.8-.8V2.4a.8.8 0 0 1 .8-.8h8.8zm-8.8.8V4h2.4V2.4H.8zm3.2 0V4h2.4V2.4H4zm3.2 0V4h2.4V2.4H7.2zM.8 13.6v1.6h2.4v-1.6H.8zm3.2 0v1.6h2.4v-1.6H4zm3.2 0v1.6h2.4v-1.6H7.2zm6.4-8.8v1.6H16V4.8h-2.4zm3.2 0v1.6h2.4V4.8h-2.4zM13.6 16v1.6H16V16h-2.4zm3.2 0v1.6h2.4V16h-2.4z' />
    </g>
  </svg>
)

TrimmerIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

TrimmerIcon.defaultProps = {
  width: 20,
  height: 20
}

export default TrimmerIcon
