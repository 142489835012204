import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import { utilityService } from '../../../services/UtilityService'
import CreateCustomMetaFieldModalVcms from '../../../components/ui/dataEntry/other/CreateCustomMetaFieldModalVcms'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCustomMetaFields from '../../../graphQL/admin/customMetaFields/listCustomMetaFields'
import MutationDeleteCustomMetaField from '../../../graphQL/admin/customMetaFields/deleteCustomMetaField'

const deleteCountryMessage = {
  title: 'DELETE CUSTOM META FIELD',
  firstMessage: 'Are you sure you want to delete this Meta Field?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]
class CustomMetaFieldsListVcms extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedCustomMetaField: null,
      isLoading: !(props.metaFieldList && props.metaFieldList.length),
      showAddCustomMetaFieldModal: false,
      showDeleteCustomMetaFieldModal: false,
      selectedCustomMetaField: undefined,
      deleteLoading: false,
      isDeleted: false
    }
    this.columns = [
      {
        title: 'Field Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        className: 'meta-name'
      },
      {
        title: 'Field Type',
        dataIndex: 'type',
        width: '20%',
        key: 'type'
      },
      {
        title: 'Section',
        dataIndex: 'section',
        width: '20%',
        key: 'section',
        render: (section) => this.renderSection(section)
      },
      {
        title: 'Default Value',
        dataIndex: 'default',
        width: '20%',
        key: 'default',
        className: 'meta-default',
        render: (data) => <div>{data + ''}</div>
      },
      {
        title: 'Usage Count',
        dataIndex: 'usageCount',
        width: '20%',
        key: 'uasgeCount',
        render: (usageCount, { isError }) => this.renderAssetCount(usageCount, isError)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.metaFieldList, this.props.metaFieldList)) {
        this.setState({ isPaginating: false, isLoading: false, metaFieldList: _.cloneDeep(newProps.metaFieldList) })
      } else if (!newProps.isLoading && this.props.isLoading && _.isEqual(newProps.listMetaFieldConfigs, this.props.listMetaFieldConfigs)) {
        this.setState({ isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.metaFieldList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedCustomMetaField) {
        this.setState({ openedCustomMetaField: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedCustomMetaField === id) {
        this.setState({ openedCustomMetaField: null })
      } else {
        this.setState({ openedCustomMetaField: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { metaFieldList } = this.props
      const selectedCustomMetaField = metaFieldList.find(item => item.id === id)
      if (!selectedCustomMetaField) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddCustomMetaFieldModal: true, selectedCustomMetaField, isEdit: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteCustomMetaFieldModal: true, selectedCustomMetaField, isEdit: true })
      }
      this.setState({ openedCustomMetaField: null })
    }

    onCountrySelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedCustomMetaField: selectedValue })
      } else {
        this.setState({ fromSelectedCustomMetaField: selectedValue })
      }
    }

    showAddCustomMetaField = () => {
      this.setState({ showAddCustomMetaFieldModal: true })
    }

    hideAddCustomMetaFieldModal = () => {
      this.setState({ showAddCustomMetaFieldModal: false, selectedCustomMetaField: null })
    }

    handleAddCustomMetaField = () => {
      this.setState({ showAddCustomMetaFieldModal: false, selectedCustomMetaField: null })
    }

    hideDeleteCustomMetaFieldModal = () => {
      this.setState({ isDeleted: true, selectedCustomMetaField: null }, () => {
        this.setState({ showDeleteCustomMetaFieldModal: false, isDeleted: false })
      })
    }

    handleDeleteCustomMetaField = () => {
      const { selectedCustomMetaField } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedCustomMetaField) {
        return
      }
      this.setState({ deleteLoading: true })
      this.props.deleteCustomMetaField(selectedCustomMetaField.id).then(() => {
        message.success('Custom Meta Field deleted successfully')
        this.setState({ isDeleted: true, selectedCustomMetaField: null }, () => {
          this.setState({ showDeleteCustomMetaFieldModal: false, deleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedCustomMetaField: null }, () => {
          this.setState({ showDeleteCustomMetaFieldModal: false, deleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.metaFieldList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreMetaFields(this.props.metaFieldList.length))
    }

    renderAssetCount = (count, isError) => {
      if (isError) {
        return <div>{'Error in Transfer. Retry'}</div>
      } else if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderSection=(section) => {
      if (section === 'OFFER_PRICE') {
        return <div>{'OFFER PRICE'}</div>
      } else {
        return section
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedCustomMetaField } = this.state
      const { metaFieldList } = this.props
      const selectedCustomMetaField = metaFieldList.find(item => item.id === id)
      if (selectedCustomMetaField && (!selectedCustomMetaField.usageCount || !parseInt(selectedCustomMetaField.usageCount)) && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (!selectedCustomMetaField.usageCount || !parseInt(selectedCustomMetaField.usageCount)) {
        // availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedCustomMetaField === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { metaFieldList, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showAddCustomMetaFieldModal, showDeleteCustomMetaFieldModal, selectedCustomMetaField, deleteLoading, isDeleted } = this.state
      let adminItem
      if (selectedCustomMetaField && showDeleteCustomMetaFieldModal) {
        adminItem = <div >
          <p>{ selectedCustomMetaField.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal entertainment-list'>
              <div className='admin-list custom-meta-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={false}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={metaFieldList} pagination={false} showHeader={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddCustomMetaField}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreateCustomMetaFieldModalVcms
                isVisible={showAddCustomMetaFieldModal}
                handleCancel={this.hideAddCustomMetaFieldModal}
                handleSubmit={this.handleAddCustomMetaField}
                selectedCustomField={selectedCustomMetaField}
                listApi module='DATA_MANAGER'
                isSubmitDisabled={_.isEmpty(selectedCustomMetaField) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteCustomMetaFieldModal}
                title={deleteCountryMessage.title}
                firstMessage={deleteCountryMessage.firstMessage}
                secondMessage={deleteCountryMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteCustomMetaFieldModal}
                handleSubmit={this.handleDeleteCustomMetaField}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

CustomMetaFieldsListVcms.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: null, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let metaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        metaFieldList = metaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          metaFieldList,
          isLoading: data.loading || !data.listMetaFieldConfigs,
          totalCount: data.listMetaFieldConfigs ? data.listMetaFieldConfigs.totalCount : 0,
          getMoreMetaFields: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listMetaFieldConfigs, ...fetchMoreResult.listMetaFieldConfigs]
                prev.listMetaFieldConfigs = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteCustomMetaField, {
      options: ({ project }) => ({
        update: (cache, { data: { deleteMetaFieldConfig } }) => {
          const variables = { section: null, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCustomMetaFields, variables }))
          if (cacheData && cacheData.listMetaFieldConfigs) {
            const selectedIndex = cacheData.listMetaFieldConfigs.findIndex(item => item.id === deleteMetaFieldConfig.id)
            if (selectedIndex > -1) {
              cacheData.listMetaFieldConfigs.splice(selectedIndex, 1)
              // cacheData.listMetaFieldConfigs.totalCount = cacheData.listMetaFieldConfigs.totalCount - 1
            }
          }
          cache.writeQuery({
            query: QueryCustomMetaFields,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteCustomMetaField: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(CustomMetaFieldsListVcms))
