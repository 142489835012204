import gql from 'graphql-tag'

export default gql(`
    mutation updateAccessRoleGroup($description: String $id: ID $name: String $modules: [ModulePermissionsInput] $oldUsers: [CreateUserInput] $newUsers: [CreateUserInput] $project: String) {
        updateAccessRoleGroup(
            input: {
                id: $id
                name: $name
                description: $description
                modules: $modules
                oldUsers: $oldUsers
                newUsers: $newUsers
            }
            project: $project
        ){
            id
            name
            description
            createdAt
            modules{
                name
                permissions
            }
            users{
                id
                accessRoleGroups {
                    id
                }
            }
        }
    }
`)
