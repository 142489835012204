import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

import _ from 'lodash'
import GeneralFieldComponent from './../../dataDisplay/GeneralFieldComponent'
import AddImageModal from '../../dataEntry/other/AddImageModal'
import ImageViewModal from './ImageViewModal'
import LoadingButton from '../../general/buttons/LoadingButton'
import Input from './../inputs/Input'
import DropDown from './../inputs/DropDown'
import AddObjectContainer from './AddWidgetDefaultValueContainer'
import './../../ui.style.scss'
import EditAppWidgetDetails from './EditAppWidgetDetails'

const { TabPane } = Tabs

class CreateAppWidgetContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeKey: ''
    }
  }
  onSelected = (item) => {
    this.props.selectAspectRatio(item)
  };

  onTabChange=(activeKey) => {
    this.setState({ activeKey: activeKey })
  }

  handleShowAddImageModal = (selectedImgConfig) => {
    this.setState({ selectedImgConfig: selectedImgConfig, showAddImageModalInner: true })
  }

  hideAddImageModal = () => {
    this.setState({ showAddImageModalInner: false })
  }

  saveCurrentImageSelection = (config, imageDetails) => {
    this.setState({ showAddImageModalInner: false })
    this.props.handleObjectDetailsChange(config, imageDetails)
  }

  showImgEditorIcon = (selectedImgEditConfig, editImageDetails) => {
    this.setState({
      showEditIcon: true,
      selectedImgEditConfig: selectedImgEditConfig,
      editImageDetails: editImageDetails
    })
  }

  hideImgEditorIcon = () => {
    this.setState({
      showEditIcon: false,
      selectedImgEditConfig: null
    })
  }

  handleShowBucketModal = (selectedBucketConfig) => {
    this.setState({ selectedBucketConfig: selectedBucketConfig, showAddBucketModal: true })
  }

  hideAddBucketModal = () => {
    this.setState({ showAddBucketModal: false })
  }

  handleBucketSelection = (selectedBucket) => {
    this.setState({
      selectedBucket
    })
  }

  saveCurrentBucketSelection = (config) => {
    const { selectedBucket } = this.state
    this.setState({ showAddBucketModal: false, selectedBucket: null })
    this.props.handleObjectDetailsChange(config, selectedBucket)
  }

  showImgEditor = () => {
    this.setState({
      showImgEditModal: true
    })
  }

  hideImgEditor = () => {
    // const { editWidgetInstance } = this.props
    this.setState({
      showImgEditModal: false,
      editImageDetails: null
    })
    if (this.props.fetchAppPageDetails) {
      this.props.fetchAppPageDetails()
    }

    setTimeout(() => {
      // this.onConfigImgCropInstanceEdit(editWidgetInstance.id)
    }, 1000)
  }

  onConfigImgCropInstanceEdit = (id) => {
    const { listData } = this.props
    let editWidgetInstance = listData.filter(widget => widget.id === id)[0]
    this.props.handleListWidgetImgCropInstanceEdit(editWidgetInstance)
  }

  render () {
    const { selectedType, saveCurrentSelection, selectedApp, isShowImageModal, mediaDetails, name, isActive, selectedKey, handleTextChange, handleIsEnabledChange,
      handleTagChange, showImageModal, handleTypeChange, handleAppChange, showCropModal, appList, clearSelection, croppedUrl, icon, isUploadBlocked, addFieldObjects, project,
      streamField, handleTempPropertyNameChange, handleTempFieldTypeChange, tempFieldType, tempPropertyName, fieldTypeList, onOptionSelect, onChangePropertyName, onChangeStatus,
      handleIdChange, isFromEdit, onCloseField, onChangeUseAsTitle, reorder, appClient, objectDetails, handleObjectDetailsChange, isWidgetEdit, hideImageModal, isShowCropModal,
      saveCurrentCrop, toggleCropModal, aspectRatio, settings, description, handleShortDescriptionChange, onInputFieldFocus, onInputFieldBlur, handleAppSearchTextChange,
      appSearchText, isDuplicate, isJsonArrayValid, jsonArrayInvalidEntries, jsonInvalidEntries, onChangeUpdatableStatus, isJsonArrayVisible, jsonConfig, jsonSelectedMeta,
      handleJsonArrayVisibilty, handleJsonArrayHide } = this.props
    const { activeKey, showAddImageModalInner, showImgEditModal, editImageDetails, showAddBucketModal, selectedBucket, showEditIcon, selectedImgConfig,
      selectedImgEditConfig, selectedBucketConfig } = this.state
    return (
      <div id='edit-app-widget-modal' className={`edit-app-widget-modal`}>
        <Tabs defaultActiveKey='1' onChange={this.onTabChange}>
          <TabPane tab='General' key='1'>
            <EditAppWidgetDetails
              appClient={appClient}
              isFromEdit={isFromEdit}
              name={name}
              isActive={isActive}
              description={description}
              selectedKey={selectedKey}
              handleTypeChange={handleTypeChange}
              handleTextChange={handleTextChange}
              handleIsEnabledChange={handleIsEnabledChange}
              handleIdChange={handleIdChange}
              handleTagChange={handleTagChange}
              icon={icon}
              clearSelection={clearSelection}
              appList={appList}
              croppedUrl={croppedUrl}
              showImageModal={showImageModal}
              showCropModal={showCropModal}
              selectedApp={selectedApp}
              // selectedGroup={selectedGroup}
              handleAppChange={handleAppChange}
              handleShortDescriptionChange={handleShortDescriptionChange}
              onInputFieldBlur={onInputFieldBlur}
              onInputFieldFocus={onInputFieldFocus}
              handleAppSearchTextChange={handleAppSearchTextChange}
              appSearchText={appSearchText}
              project={project}
              isDuplicate={isDuplicate}
            />
          </TabPane>

          <TabPane tab='Define Properties' key='2' disabled={false}>
            {!_.isEmpty(streamField) ? <GeneralFieldComponent
              type='appWidget'
              reorder={reorder}
              streamField={streamField}
              // isEnableSave={this.isEnableSave}
              isFromEdit={isFromEdit}
              selectedType={selectedType}
              isTitleInvisible
              columnTitle1={'Property Name'}
              columnTitle3={'Mandatory'}
              columnTitle2={'Field Type'}
              placeHolder1='Enter Property Name'
              placeHolder2='Select Field'
              onChangeStatus={onChangeStatus}
              handleChange={onChangePropertyName}
              onOptionSelect={onOptionSelect}
              nameParam='name'
              listType={fieldTypeList}
              onCloseField={onCloseField}
              onChangeUseAsTitle={onChangeUseAsTitle}
              onChangeUpdatableStatus={onChangeUpdatableStatus}
            /> : null}
          </TabPane>
          <TabPane tab='Default Values' key='3' disabled={_.isEmpty(streamField)}>
            <AddObjectContainer
              handleObjectDetailsChange={handleObjectDetailsChange}
              fieldTypeList={fieldTypeList}
              objectDetails={objectDetails}
              project={project}
              handleShowAddImageModal={this.handleShowAddImageModal}
              hideAddImageModal={this.hideAddImageModal}
              saveCurrentImageSelection={this.saveCurrentImageSelection}
              showImgEditorIcon={this.showImgEditorIcon}
              hideImgEditorIcon={this.hideImgEditorIcon}
              showAddImageModal={showAddImageModalInner}
              showEditIcon={showEditIcon}
              handleShowBucketModal={this.handleShowBucketModal}
              showAddBucketModal={showAddBucketModal}
              hideAddBucketModal={this.hideAddBucketModal}
              handleBucketSelection={this.handleBucketSelection}
              selectedBucket={selectedBucket}
              saveCurrentBucketSelection={this.saveCurrentBucketSelection}
              showImgEditor={this.showImgEditor}
              hideImgEditor={this.hideImgEditor}
              showImgEditModal={showImgEditModal}
              details={{ fields: streamField }}
              selectedBucketConfig={selectedBucketConfig}
              selectedImgConfig={selectedImgConfig}
              selectedImgEditConfig={selectedImgEditConfig}
              editImageDetails={editImageDetails}
              isWidget
              isWidgetEdit={isWidgetEdit}
              isJsonArrayValid={isJsonArrayValid}
              jsonInvalidEntries={jsonInvalidEntries}
              jsonArrayInvalidEntries={jsonArrayInvalidEntries}
              isJsonArrayVisible={isJsonArrayVisible}
              jsonConfig={jsonConfig}
              jsonSelectedMeta={jsonSelectedMeta}
              handleJsonArrayVisibilty={handleJsonArrayVisibilty}
              handleJsonArrayHide={handleJsonArrayHide}
            />
          </TabPane>
        </Tabs>
        {activeKey === '2'
          ? <div className={`add-field-stream ${_.isEmpty(streamField) ? ' no-streams' : ''}`}>
            <div className='edit-input-item'>
              <Input title='' placeholder='Property Name' inputName='title' value={tempPropertyName} handleChange={handleTempPropertyNameChange} noLabel />
            </div>
            <div className='edit-input-item'>
              <div className='output-format' id='dropdown-1'>
                <DropDown options={fieldTypeList} onOptionSelect={handleTempFieldTypeChange} selectedValue={tempFieldType || undefined} valueParam='id' displayParam='id' placeHolder='Select Field Type' parent={'dropdown-1'} />
              </div>
            </div>
            <LoadingButton
              type='primary'
              onClick={addFieldObjects}
              htmlType='submit'
              buttonText={'Add Property'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={false}
            />
          </div> : null}

        <AddImageModal
          isVisible={isShowImageModal}
          handleSubmit={saveCurrentSelection}
          handleCancel={hideImageModal}
          currentSelection={!_.isEmpty(mediaDetails) ? [mediaDetails] : []}
          mediaType={'IMAGE'}
          isSubmitButtonDisabled={isUploadBlocked}
          project={project} />

        <ImageViewModal
          isVisible={isShowCropModal}
          aspectRatio={aspectRatio}
          imgUrl={settings && settings.length ? settings[0].fileName : null}
          settings={settings}
          updateSettings={saveCurrentCrop}
          handleCancel={() => toggleCropModal(false)}
          // isUpdateBlocked={isUpdateDisabledContent}
        />
      </div>
    )
  }
}

CreateAppWidgetContainer.propTypes = {
  /** Array of taglist */
  tagsList: PropTypes.array,
  /**  Array of selected tags */
  selectedTags: PropTypes.array,
  /**  Function to handle tag change */
  handleTagChange: PropTypes.func,
  /**  Function to handle video format change */
  handleFormatChange: PropTypes.func,
  /** Function to handle name cahnge */
  handleTextChange: PropTypes.func,
  /** Array of formats  */
  formats: PropTypes.array,
  /**  selected format */
  selectedFormat: PropTypes.string,
  /** Function to be called for updating images for each aspect ratios */
  updateAspectRatioImage: PropTypes.func,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** List of media category */
  categoryList: PropTypes.array,
  /** Selected category */
  selectedCategory: PropTypes.string
}

CreateAppWidgetContainer.defaultProps = {
}

// export default EditImageContainer

export default CreateAppWidgetContainer
