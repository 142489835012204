import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import _ from 'lodash'
import Input from '../inputs/Input'
import DropDown from '../inputs/DropDown'
import { utilityService } from '../../../../services/UtilityService'
// import bucketTypes from './../../../../constants/bucket-group-types'
import './../../ui.style.scss'

class AddBucketGroupModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      newTitle: props.bucketGroupDetails ? props.bucketGroupDetails.name : '',
      selectedType: props.bucketGroupDetails ? props.bucketGroupDetails.type : '',
      key: props.bucketGroupDetails ? props.bucketGroupDetails.key : ''
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.bucketGroupDetails, this.props.bucketGroupDetails)) {
      this.setState({
        newTitle: newProps.bucketGroupDetails ? newProps.bucketGroupDetails.name : '',
        selectedType: newProps.bucketGroupDetails ? newProps.bucketGroupDetails.type : '',
        key: newProps.bucketGroupDetails ? newProps.bucketGroupDetails.key : ''
      })
    }
  }

  handleChange = (e) => {
    const { value } = e.target
    const { isFromEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.newTitle || '')
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isFromEdit) {
      const key = utilityService.slugifyString(value).slice(0, 125).toLocaleUpperCase()
      this.setState({ newTitle: value, key })
    } else {
      this.setState({ newTitle: value })
    }
  }

  handleIdChange =(e) => {
    const { value } = e.target
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(value) ? value.toLocaleUpperCase() : this.state.key
    this.setState({ key })
  }

  onOptionSelect = (id) => {
    this.setState({ selectedType: id })
  }

  handleSubmit = () => {
    const { newTitle, key, selectedType } = this.state
    this.props.handleSubmit(newTitle, key, selectedType)
  }

  resetState = () => {
    const state = {
      newTitle: '',
      selectedType: '',
      key: ''
    }
    this.setState({ ...state })
  }

  render () {
    const { visible, handleCancel, title, loading, isFromEdit, bucketGroupTypes } = this.props
    const { newTitle, selectedType, key } = this.state
    return (

      <Modal
        className='general-modal bucket-group-modal'
        visible={visible}
        closable={false}
        title={title}
        maskClosable={false}
        okButtonProps={{ disabled: !(newTitle && selectedType), loading: loading }}
        onOk={this.handleSubmit || this.handleEdit}
        onCancel={handleCancel}
        okText='Save'
        cancelText='Cancel'
        afterClose={this.resetState}
        centered
        destroyOnClose
      >
        <Input title='Group Name' handleChange={this.handleChange} value={newTitle} limit={100} />
        <Input title='Key' handleChange={this.handleIdChange} value={key} limit={128} readOnly={isFromEdit} />
        <p>Type </p>
        <DropDown options={bucketGroupTypes} selectedValue={selectedType} placeHolder='Select Bucket Type' valueParam='id' displayParam='name' onOptionSelect={this.onOptionSelect} />
      </Modal>

    )
  }
}

AddBucketGroupModal.propTypes = {
  /** visible action of AddBucketGroupModal. */
  visible: PropTypes.bool,
  /** title  of AddBucketGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of AddBucketGroupModal. */
  handleCancel: PropTypes.func,
  /** Boolean for showing loading */
  loading: PropTypes.bool,
  /** Boolean to know source of the modal */
  isFromEdit: PropTypes.bool
}

AddBucketGroupModal.defaultProps = {
}

export default AddBucketGroupModal
