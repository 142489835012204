import gql from 'graphql-tag'

export default gql(`
    mutation editPageVersion($input: editPageVersionInput!, $appPageId: String!,  $project: String ) {
        editPageVersion(
          input: $input
          appPageId: $appPageId
          project: $project
        ) {
            id
            isPublished
            publishStartDate
            publishEndDate
            layoutItems {
                id
                position
                type
                title
                widget {
                  id
                  key
                  title
                  description  
                  widgetPreview {
                    id
                    fileName
                    createdAt
                    name
                    type
                    fileSize
                    duration
                    thumbnails
                    tags{
                        key
                        name
                        type
                    }
                    category{
                        id
                        label
                        type
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2
                    }
                    dimensions{
                        x1
                        x2
                        y1
                        y2
                    }
                    aspectRatio {
                        aspectRatio
                        ratio
                        title
                        resolutions {
                        key
                        width
                        height
                        url
                        }
                    }
                }
                }
                fieldValues {
                  configField {
                    id
                    name
                    type
                    isRequired
                    project
                    useAsTitle
                    createdAt
                    createdBy
                    position
                    
                  }
                  value
                  bucket {
                    project
                    id
                    isArchived
                    name
                    isPublished
                    publishStartDate
                    publishEndDate
                    updatedAt
                    updatedBy
                    key
                    displayName
                    displayType {
                      name
                      id
                    }
                  }
                  image {
                    id
                    fileName
                    createdAt
                    name
                    type
                    fileSize
                    duration
                    thumbnails
                    tags {
                      key
                      name
                      type
                    }
                    category {
                      id
                      label
                      type
                    }
                    settings {
                      aspectRatio
                      outputFormat
                      fileName
                      x1
                      y1
                      x2
                      y2
          
                    }
                    dimensions {
                      x1
                      x2
                      y1
                      y2
                      
                    }
                    aspectRatio {
                      aspectRatio
                      ratio
                      title
                      resolutions {
                        key
                        width
                        height
                        url
                        
                      }
                      
                    }
                    
                  }
                  project
                  position
                  
                }
                project
                isEnabled
                
              }
        }
    }`
)
