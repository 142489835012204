import gql from 'graphql-tag'

export default gql(`
    mutation addOfferAttribute($input: [OfferAttributeInput] $project: String) {
        addOfferAttribute(
            input: $input
            project: $project
        ){
            id
        }
    }`
)
