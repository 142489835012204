import React, { useEffect } from 'react'
import { Row, Col } from 'antd'

import DrmEditor from './DrmEditor'

import { withRouter } from 'react-router-dom'
import { hookUtilityService } from './../../services/HookUtilityService'

import './../drmManager/DrmManager.scss'

const sourceList = [
  {
    name: 'MANUAL',
    id: 'manual'
  },
  {
    name: 'CMS CHANNEL MANAGER',
    id: 'cmsChannelManager'
  }

]

function DrmManager ({ project, appClient }) {
  const [source, setSource] = hookUtilityService.useStateWithLabel({
    name: 'CMS CHANNEL MANAGER',
    id: 'cmsChannelManager'
  }, 'source')
  const [isSideBarVisible, setSideBarVisible] = hookUtilityService.useStateWithLabel(false, 'isSideBarVisible')
  const [channel, setChannel] = hookUtilityService.useStateWithLabel(null, 'channel')

  useEffect(() => {
    document.title = `DRM Token Manager - Optus CMS`
  })

  const onSourceSelect = (value) => {
    const sourceData = (sourceList || []).find(Item => Item.id === value)
    setSource({ ...sourceData })
    setChannel(null)
  }

  const toggleSideBar = () => {
    setSideBarVisible(!isSideBarVisible)
  }

  const onChannelSelect = (channel) => {
    setChannel(channel)
  }

  return (<Row className='drm-manager' id='drm-manager' ref={(ref) => { }}>
    <Col xl={{ span: 24 }} md={{ span: 24 }} className='drm-editor-container' >
      <DrmEditor
        project
        isSideBarVisible={isSideBarVisible}
        toggleSideBar={toggleSideBar}
        onSourceSelect={onSourceSelect}
        sourceList={sourceList}
        source={source}
        channel={channel}
        onChannelSelect={onChannelSelect}
      />
    </Col>
  </Row>)
}

export default withRouter(DrmManager)
