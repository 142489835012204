import React from 'react'
import { Tree, message } from 'antd'

// import GroupCompetitionIcon from '../../components/ui/general/icons/GroupCompetitionIcon'
// import GroupDragAndDropIcon from '../../components/ui/general/icons/GroupDragAndDropIcon'
// import bucketTypes from './../../constants/bucket-group-types'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
// import MutationUpdateBucketGroup from './../../graphQL/bucket/updateBucketGroup'
import QueryGetAppList from '../../graphQL/appManager/listApps'
import MutationDeleteGroup from '../../graphQL/appManager/deleteAppGroup'
import QueryGetGroupList from '../../graphQL/appManager/listGroups'
import QueryGetAppPageList from '../../graphQL/appManager/listAppPages'
import QueryCustomMetaFields from './../../graphQL/admin/customMetaFields/listCustomMetaFields'
// import SubscriptionUpdateBucketGroupList from '../../graphQL/bucket/updateBucketGroupSubscription'
import userMessages from '../../constants/messages'
import { utilityService } from '../../services/UtilityService'

import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import AddButton from '../../components/ui/general/buttons/AddButton'
import ArchiveConfigButton from '../../components/ui/general/buttons/ArchiveConfigButton'
import PopoverButton from '../../components/ui/general/buttons/PopoverButton'
// import CreateConfigModal from '../../components/ui/dataEntry/other/CreateConfigModal'
import CreatePageModal from '../../components/ui/dataEntry/other/CreatePageModal'
import CreateAppGroupModal from '../../components/ui/dataEntry/other/CreateAppGroupModal'
import GroupHistoryModal from '../../components/ui/dataEntry/other/appGroupHistoryModal'
import { generateCroppedThumbnail } from '../../util/util'
import placeholderImage from './../../assets/images/default-image.jpg'
import AdminItemConfirmModal from '../../components/ui/feedback/AdminItemConfirmModal'

const { TreeNode } = Tree
const deleteGroupMessage = {
  title: 'REMOVE GROUP',
  firstMessage: 'Are you sure you want to remove this group?',
  secondMessage: 'This action cannot be undone.'
}

const createOptions = [
  {
    id: 'Group',
    type: 'Group'
  },
  {
    id: 'Page',
    type: 'Page'
  }
]

let groupOptions = [
  {
    id: 'Edit',
    type: 'Edit'
  },
  {
    id: 'Remove',
    type: 'Remove'
  },
  {
    id: 'History',
    type: 'History'
  }
]

let groupOptionsAlt = [
  {
    id: 'Edit',
    type: 'Edit'
  },
  {
    id: 'History',
    type: 'History'
  }
]

class AppPagesList extends React.Component {
  constructor (props) {
    super(props)
    this.defaultPages = _.cloneDeep(props.appList)
    // this.defaultPages = _.cloneDeep(props.bucketGroupTypes)
    let pages = []
    if (props.groupList && props.groupList.length && props.configList) {
      pages = this.setAppGroup(props.groupList, props.configList)
    }
    this.state = {
      defaultExpandAll: true,
      pages,
      showCreateOptions: false,
      showGroupModal: false,
      showPageModal: false,
      showGroupOptions: false,
      showArchivedConfig: false,
      showDeleteGroupModal: false,
      showGroupHistory: false,
      groupDetails: null,
      isDeleteLoading: false,
      typeList: [
        {
          id: 'CONFIG',
          name: 'Single'
        },
        {
          id: 'CONFIG_LIST',
          name: 'List'
        }],
      selectedBucketId: ''
    }
    this.reCreateTreeTimeout = 0
    this.refetchPageTimeOut = 0
    this.stopRecreate = false
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.refetchPageList !== this.props.refetchPageList && newProps.refetchPageList === true) {
      if (this.refetchPageTimeOut) clearTimeout(this.refetchPageTimeOut)
      this.refetchPageTimeOut = setTimeout(() => {
        this.props.refetchAppPageList()
        this.props.handleRefetchPageList(false)
      }, 1500)
    } else if (((newProps.groupList && newProps.groupList.length && !_.isEqual(newProps.groupList, this.props.groupList)) || (newProps.configList && newProps.configList.length && !_.isEqual(newProps.configList, this.props.configList))) && newProps.refetchPageList === false) {
      if (newProps.configList && this.props.configList && newProps.configList.length < this.props.configList.length) {
        this.stopRecreate = true
        const pages = this.setAppGroup(newProps.groupList, this.props.configList)
        this.setState({ pages })
      } else {
        this.stopRecreate = false
        const pages = this.setAppGroup(newProps.groupList, newProps.configList)
        this.setState({ pages })
      }
    }
    if (newProps.appList && newProps.appList.length && !_.isEqual(newProps.appList, this.props.appList)) {
      this.defaultPages = _.cloneDeep(newProps.appList)
      const pages = this.setAppGroup(newProps.groupList, newProps.configList)
      this.setState({ pages })
    }
    if (newProps.selectedConfig !== this.props.selectedConfig) {
      this.setState({ selectedBucketId: newProps.selectedConfig })
    } else if (!newProps.selectedPage && newProps.configList && newProps.configList.length) {
      const configId = (newProps.configList || []).find(item => item.id && !item.isArchived)
      if (!_.isEmpty(configId)) {
        this.props.onSelectPageGroup(configId.id)
      }
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.showCreateOptions) {
      setTimeout(() => this.setState({ showCreateOptions: false }), 200)
    }
    if (this.state.showGroupOptions) {
      setTimeout(() => this.setState({ showGroupOptions: false }), 200)
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setAppGroup = (appGroups, configList) => {
    // const {configList} = this.props
    let pages = cloneDeep((this.defaultPages || []).sort((a, b) => a.position - b.position))
    for (let i = 0; i < this.defaultPages.length; i++) {
      const app = this.defaultPages[i].id
      let pageAppGroups = (cloneDeep(appGroups) || []).filter(item => item.app.id === app)
      // pageAppGroups = _.sortBy(pageAppGroups, 'name')
      pageAppGroups = _.sortBy(pageAppGroups, (a, b) => (a && a.name).localeCompare(b && b.name))
      pages[i].appGroups = pageAppGroups
      let groupIndex = null;
      (pageAppGroups || []).forEach((item, index) => {
        pages[i].appGroups[index].configs = []
      });
      (cloneDeep(configList) || []).map(item => {
        if (item.group) {
          groupIndex = (pageAppGroups || []).findIndex((group) => item.group.key === group.key)
        }
        if (groupIndex > -1 && groupIndex !== null) {
          if (pages[i].appGroups[groupIndex].configs) {
            const existingIndex = (pages[i].appGroups[groupIndex].configs || []).findIndex(config => config.id === item.id)
            if (existingIndex > -1) {
              pages[i].appGroups[groupIndex].configs[existingIndex] = item
              if (!this.props.selectedConfig || (this.state && !this.state.selectedBucketId && !item.isArchived)) {
                this.props.onSelectPageGroup(item.id)
              }
            } else {
              pages[i].appGroups[groupIndex].configs.push(item)
              // pages[i].appGroups[groupIndex].configs = (pages[i].appGroups[groupIndex].configs || []).sort((a, b) => a.title - b.title)
              pages[i].appGroups[groupIndex].configs = _.sortBy(pages[i].appGroups[groupIndex].configs, (a, b) => (a && a.title).localeCompare(b && b.title))
              if (this.state && !this.state.selectedBucketId && !item.isArchived && !this.props.selectedPage) {
                this.props.onSelectPageGroup(item.id)
                this.setState({ selectedBucketId: item.id })
              }
            }
          } else {
            pages[i].appGroups[groupIndex].configs = []
            pages[i].appGroups[groupIndex].configs.push(item)
            // pages[i].appGroups[groupIndex].configs = (pages[i].appGroups[groupIndex].configs || []).sort((a, b) => a.title - b.title)
            pages[i].appGroups[groupIndex].configs = _.sortBy(pages[i].appGroups[groupIndex].configs, (a, b) => (a && a.title).localeCompare(b && b.title))
            if (this.state && !this.state.selectedBucketId && !item.isArchived) {
              this.props.onSelectConfigGroup(item.id)
              this.setState({ selectedBucketId: item.id })
            }
          }
        }
      })
    }
    return pages
  }

  reCreatePageTree = () => {
    // const {configList} = this.props
    if (this.reCreateTreeTimeout) clearTimeout(this.reCreateTreeTimeout)
    if (this.stopRecreate) {
      this.stopRecreate = false
      return
    }
    let tempPages
    const { groupList, configList } = this.props
    tempPages = this.setAppGroup(groupList, configList)
    this.setState({ pages: tempPages })
  }

  onDragEnter = () => {
  }

  onDrop = (info) => {
    const dropPos = info.node.props.pos.split('-')
    const dragPos = info.dragNode.props.pos.split('-')
    if (dropPos.length === dragPos.length) {
      const isSameBucket = _.isEqual([...dropPos].splice(0, 3).join(''), [...dragPos].splice(0, 3).join(''))

      if (!isSameBucket) {
        return
      }
      const { pages } = this.state
      const contents = pages[dropPos[1]].bucketGroups[dropPos[2]].bucketGroupItems
      const endIndex = parseInt([...dropPos].splice(3, 1))
      const startIndex = parseInt([...dragPos].splice(3, 1))

      const result = Array.from(contents)
      const [removed] = result.splice(startIndex, 1)
      const isDecreasing = endIndex < startIndex
      const largerIndex = isDecreasing ? startIndex : endIndex
      const smallerIndex = isDecreasing ? endIndex : startIndex
      const modifiedResult = result.map((item, index) => {
        if (index >= smallerIndex && index < largerIndex) {
          item.position = item.position + (isDecreasing ? 1 : -1)
        }
        return item
      })
      removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : (result[endIndex].position - 1)
      modifiedResult.splice(endIndex, 0, removed)
      pages[dropPos[1]].bucketGroups[dropPos[2]].bucketGroupItems = modifiedResult
      this.setState({ pages }, () => this.updateBucketGroups(pages[dropPos[1]].bucketGroups[dropPos[2]].id, modifiedResult, true))
    } else {

    }
  }

  pageGroupSelected = ([value]) => {
    const { pages } = this.state
    const { selectedGroup } = this.props
    let currentAppGroup
    for (let i = 0; i < pages.length; i++) {
      const appGroups = pages[i].appGroups
      const currentAppGroupIndex = appGroups.findIndex(item => item.id === selectedGroup)
      if (currentAppGroupIndex > -1) {
        currentAppGroup = appGroups[currentAppGroupIndex]
        break
      }
    }
    if (!currentAppGroup && !selectedGroup && !_.isEmpty(pages)) {
      for (let i = 0; i < pages.length; i++) {
        const appGroups = !_.isEmpty(pages[i].appGroups) ? pages[i].appGroups[0] : null
        currentAppGroup = appGroups
      }
    }
    for (let i = 0; i < pages.length; i++) {
      const appGroups = pages[i].appGroups
      const selectedIdIndex = appGroups.findIndex(item => item.key === value)
      if (selectedIdIndex > -1) {
        const selectedId = appGroups[selectedIdIndex].key
        this.setState({ selectedBucketId: '' })
        this.props.onSelectPageGroup(selectedId)
        break
      } else {
        let configId
        const selectedIdIndex = appGroups.findIndex(item => {
          if (item.configs) {
            const index = item.configs.findIndex(configItem => configItem.id === value)
            if (index > -1) {
              configId = item.configs[index].id
              return true
            }
          }
          return false
        })
        if (selectedIdIndex > -1) {
          // const isSameBucketGroup = (currentAppGroup.configs || []).findIndex(config => config.id === configId) > -1
          this.props.onSelectPageGroup(configId)
          this.setState({ selectedBucketId: value })
        }
      }
    }
  }

  updateBucketGroups = (id, bucketGroupItems, shouldShowMessage) => {
    const request = bucketGroupItems.map((item, index) => {
      return { id: item.id, position: index }
    })
    this.props.updateBucketGroup(request, id).then(data => {
      if (shouldShowMessage) {
        message.success(userMessages.SUCCESS_UPDATE_BUCKET_GROUP)
      }
    }, error => {
      utilityService.handleError(error)
    })
  }

  toggleShowCreate=() => {
    this.setState({ showCreateOptions: !this.state.showCreateOptions })
  }

  toggleShowGroupOptions=(id) => {
    this.setState({ showGroupOptions: !this.state.showGroupOptions, selectedGroup: id })
  }

  toggleArchivedConfig=() => {
    this.setState({ showArchivedConfig: !this.state.showArchivedConfig })
  }

  onCreateSelection=(selectedType) => {
    if (selectedType) {
      if (selectedType.id === 'Group') {
        if (this.props.isCreateGroupDisabled) {
          message.error('You Do not have permission to create Group')
          return
        }
        this.setState({ showGroupModal: true, showCreateOptions: false, selectedGroup: '', groupDetails: null })
      } else if (selectedType.id === 'Page') {
        if (this.props.isCreateDisabled) {
          message.error('You Do not have permission to create Page')
          return
        }
        this.setState({ showPageModal: true, showCreateOptions: false })
      }
    }
  }

  onGroupOptionSelection=(option) => {
    if (option) {
      if (option.id === 'Edit') {
        if (this.props.isUpdateGroupDisabled) {
          message.error('You Do not have permission to update group')
          return
        }
        let groupDetails = null
        if (this.state.selectedGroup) {
          groupDetails = (this.props.groupList || []).find(group => group.id === this.state.selectedGroup)
        }
        this.setState({ showGroupModal: true, showGroupOptions: false, groupDetails: groupDetails })
      } else if (option.id === 'Remove') {
        this.setState({ showDeleteGroupModal: true, showGroupOptions: false })
      } else if (option.id === 'History') {
        this.setState({ showGroupHistory: true, showGroupOptions: false })
      }
    }
  }

  hidePageModal=() => {
    this.setState({ showPageModal: false })
  }

  hideGroupModal=() => {
    this.setState({ showGroupModal: false })
  }

  hideGroupHistory=() => {
    this.setState({ showGroupHistory: false })
  }

  handleDeleteGroupCancel=() => {
    this.setState({ showDeleteGroupModal: false })
  }

  handleDeleteGroupSubmit=() => {
    const { selectedGroup } = this.state
    let groupDetails
    this.setState({ isDeleteLoading: true })
    if (this.state.selectedGroup) {
      groupDetails = (this.props.groupList || []).find(group => group.id === selectedGroup)
    }
    this.props.deleteGroup(groupDetails.key, (groupDetails && groupDetails.app && groupDetails.app.id)).then(() => {
      message.success('Group Removed Successfully')
      this.setState({ showDeleteGroupModal: false, selectedGroup: '', isDeleteLoading: false })
      this.reCreatePageTree()
    }, error => {
      utilityService.handleError(error)
      this.setState({ isDeleteLoading: false })
    })
  }

  checkForConfigs=(id) => {
    const { pages } = this.state
    let groupConfigs
    (pages || []).map(page => {
      let groupData = (page.appGroups || []).find(group => group.key === id)
      if (groupData) {
        groupConfigs = groupData.configs
      }
    })
    if ((!_.isEmpty(groupConfigs) && groupConfigs.length) || this.props.isDeleteGroupDisabled) {
      return true
    } else {
      return false
    }
  }

  render () {
    const { pages, defaultExpandAll, showCreateOptions, showGroupModal, showGroupOptions, selectedGroup, showArchivedConfig, showDeleteGroupModal, groupDetails, isDeleteLoading, showGroupHistory, showPageModal } = this.state
    const { selectedPage, appList, groupList, appClient, project, isUpdateDisabled, onSelectPageGroup, pageMetaFieldList, handleRefetchPageList, isCriticalWriteDisabled, isAppAdminDisabled, handleRefetchPageDetails, userId } = this.props
    const selectedGroupObject = (groupList || []).find(group => {
      if (group && (group.id === selectedGroup)) {
        return group.name
      }
    })
    const tree = (
      pages.map(page => {
        let image
        if (page.appIcon) {
          image = generateCroppedThumbnail(page.appIcon, 20, 20, '16:9')
        } else {
          image = placeholderImage
        }

        return <TreeNode icon={<div className='app-thumb' style={{ backgroundImage: `url(${image})` }} />} key={page.id} title={page.name} selectable={false} expanded>
          { (page.appGroups || []).sort((a, b) => (a && a.name).localeCompare(b && b.name)).map(appGroup => {
            return (<TreeNode className='bucket-group' key={appGroup.key} title={<PopoverButton
              button={<div onContextMenu={(e) => { e.preventDefault(); this.toggleShowGroupOptions(appGroup.id); return false }}>{appGroup.name}</div>}
              displayParam='id'
              contents={this.checkForConfigs(appGroup.key) ? groupOptionsAlt : groupOptions}
              onContentClick={this.onGroupOptionSelection}
              parentCompoent={'config-group-list'}
              isVisible={showGroupOptions && (selectedGroup === appGroup.id)}
              placement='topLeft'
            />} id={`${appGroup.key}-bucket-list-menu-item`} selectable={false} >
              {(appGroup.configs || []).sort((a, b) => (a && a.title).localeCompare(b && b.title)).map(config => {
                if (!showArchivedConfig && !config.isArchived) {
                  return <TreeNode className={`bucket ${selectedPage === config.id ? 'selected' : ''}`} key={config.id} title={config.title} />
                } else if (showArchivedConfig && config.isArchived) {
                  return <TreeNode className={`bucket ${selectedPage === config.id ? 'selected' : ''}`} key={config.id} title={config.title} />
                }
              })}
            </TreeNode>)
          })}
        </TreeNode>
      })
    )

    return (
      <div className='config-group-list' id='config-group-list'>
        <Tree
          defaultExpandAll={defaultExpandAll}
          className='draggable-tree'
          selectedKeys={[selectedPage]}
          blockNode
          onSelect={this.pageGroupSelected}
          showIcon
        >
          {tree}
        </Tree>
        <div className='app-list-footer' id='bucket-list-footer'>
          <ArchiveConfigButton onClick={this.toggleArchivedConfig} isDisabled={false} title={showArchivedConfig ? 'Hide Archived Pages' : 'Show Archived Pages'} />
          <PopoverButton
            button={<ButtonContainer displayTitle='Create Group/Page' childComponent={<AddButton onClick={this.toggleShowCreate} isDisabled={false} />} />}
            displayParam='id'
            contents={createOptions || []}
            onContentClick={this.onCreateSelection}
            parentCompoent={'bucket-list-footer'}
            isVisible={showCreateOptions}
          />

        </div>
        <CreatePageModal
          // typeList={typeList || []}
          appList={appList || []}
          groupList={groupList || []}
          isUpdateBlocked={isUpdateDisabled}
          isVisible={showPageModal}
          hidePageModal={this.hidePageModal}
          reCreatePageTree={this.reCreatePageTree}
          refetchPageList={handleRefetchPageList}
          onSelectPageGroup={onSelectPageGroup}
          pageMetaFieldList={pageMetaFieldList}
          isCriticalWriteDisabled={isCriticalWriteDisabled}
          isAppAdminDisabled={isAppAdminDisabled}
          appClient={appClient}
          project={project}
          userId={userId}
        />
        <CreateAppGroupModal
          title={!_.isEmpty(groupDetails) ? 'Edit Group' : 'Add Group'}
          isFromEdit={!_.isEmpty(groupDetails)}
          details={groupDetails}
          appList={appList || []}
          isUpdateBlocked={isUpdateDisabled}
          isVisible={showGroupModal}
          handleCancel={this.hideGroupModal}
          reCreatePageTree={this.reCreatePageTree}
          appClient={appClient}
          project={project}
          type={'PAGE'}
          isCriticalDisabled={isCriticalWriteDisabled}
          isAppAdminDisabled={isAppAdminDisabled}
          handleRefetchPageDetails={handleRefetchPageDetails}
          userId={userId}
        />
        <GroupHistoryModal
          title={'GROUP HISTORY'}
          isVisible={showGroupHistory}
          selectedGroup={(selectedGroupObject && selectedGroupObject.id) || selectedGroup}
          handleCancel={this.hideGroupHistory}
          appClient={appClient}
          project={project}
        />
        <AdminItemConfirmModal
          isVisible={showDeleteGroupModal}
          title={deleteGroupMessage.title}
          firstMessage={deleteGroupMessage.firstMessage}
          secondMessage={deleteGroupMessage.secondMessage}
          adminItem={(selectedGroupObject && selectedGroupObject.name) || selectedGroup}
          rightButtonText={'Remove'}
          handleCancel={this.handleDeleteGroupCancel}
          handleSubmit={this.handleDeleteGroupSubmit}
          isLoading={isDeleteLoading}
          // isSubmitButtonDisabled={this.isDeleteDisabled}
          isCancelButtonDisabled={false}
        />
      </div>
    )
  }
}

export default withApollo(compose(

  graphql(
    QueryGetAppList,
    {
      options: ({ project, appClient }) => {
        return {
          variables: { limit: 499, project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const appList = props.data && props.data.listApps ? props.data.listApps.items : []
        return {
          appList
        }
      }
    }
  ),
  graphql(
    QueryGetGroupList,
    {
      options: ({ project, appClient, appMode }) => {
        let type = appMode === 'pages' ? 'PAGE' : 'CONFIG'
        let filter = {
          type: {
            match: type
          }
        }
        return {
          variables: { limit: 499, project: project, filter },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const groupList = props.data && props.data.listGroups ? props.data.listGroups.items : []
        return {
          groupList
        }
      }
    }
  ),
  graphql(
    QueryGetAppPageList,
    {
      options: ({ project, appClient }) => {
        return {
          variables: { limit: 499, project },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const configList = props.data && props.data.listAppPages ? props.data.listAppPages.items : []
        return {
          configList,
          refetchAppPageList: () => {
            return props.data.refetch({ limit: 499,
              project: props.ownProps.project
            })
          }
        }
      }
    }
  ),
  // graphql(
  //   MutationUpdateBucketGroup,
  //   {
  //     props: (props) => ({
  //       updateBucketGroup: (updateData, id) => {
  //         return props.mutate({
  //           variables: { updateData, id, project: props.ownProps.project }
  //         })
  //       }
  //     })
  //   }),
  graphql(
    MutationDeleteGroup, {
      options: ({ appClient, project, appMode }) => ({
        update: (cache, { data: { deleteGroup } }) => {
          // const { searchString } = authorFilter
          // const filter = searchString ? { name: { match: searchString } } : null
          let type = appMode === 'pages' ? 'PAGE' : 'CONFIG'
          let filter = {
            type: {
              match: type
            }
          }
          const variables = { limit: 499, project, filter }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetGroupList, variables }))
          if (cacheData && cacheData.listGroups && cacheData.listGroups.items) {
            const selectedGroupIndex = cacheData.listGroups.items.findIndex(item => item.key === deleteGroup.key)
            if (selectedGroupIndex > -1) {
              cacheData.listGroups.items.splice(selectedGroupIndex, 1)
            }
          }
          cache.writeQuery({
            query: QueryGetGroupList,
            data: cacheData,
            variables
          })
        },
        client: appClient
      }),
      props: (props) => ({
        deleteGroup: (key, app) => {
          return props.mutate({
            variables: { key, project: props.ownProps.project, app }
          })
        }
      })
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'APP_PAGE', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let pageMetaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        pageMetaFieldList = pageMetaFieldList.map(item => {
          // if (item.isTransferring) {
          //   item.assetCount = 'Transferring'
          // }
          return item
        })
        return {
          pageMetaFieldList
        }
      }
    }
  )
)(AppPagesList))
