import gql from 'graphql-tag'

export default gql(`
mutation editTagType($name: String!,$enabledAssetTypes: [TagAssetTypeInput],$key: String!,$isMediaEnabled: Boolean, $project: String, $id: ID!, $position: Int, $isCustom: Boolean) {
    editTagType(
        input: {
            name: $name
            enabledAssetTypes: $enabledAssetTypes
            isCustom: $isCustom,
            key: $key
            isMediaEnabled: $isMediaEnabled
            position: $position
        },
        id: $id
        project: $project
    ) {
        id
        name
        key
        position
        isCustom
        isMediaEnabled
        enabledAssetTypes{
            assetType
        }
        tagCount
    }
}`
)
