import gql from 'graphql-tag'

export default gql(`
    query listSports($limit: Int $nextToken: String){
        listSports( 
            limit: $limit
            nextToken: $nextToken
            ) {
                items{
                  id
                  externalId
                  title
                  meta{
                    name
                    type
                    value
                    oldValue
                    displayValue
                    isRequired
                    displayName
                    fieldName
                    maxValue
                    isPosChange
                  }
                  competitionFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  tournamentFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  teamFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  matchFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  playerFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  stageFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                }
                nextToken
    }
}`)
