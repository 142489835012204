import gql from 'graphql-tag'

export default gql(`
    mutation updateSeason( $id: ID! $customTitle: String  $customStartDate: AWSDateTime $customEndDate: AWSDateTime $customActive: String $tags: [ID]) {
        updateSeason(
            input: {
                id: $id
                tags: $tags
                customTitle: $customTitle
                customStartDate: $customStartDate
                customEndDate: $customEndDate
                customActive: $customActive
            }
        ) {
            id
            title
            customTitle
            customStartDate 
            customEndDate
            customActive
            tags{
                key
                name
                type
            }
        }
    }`
)
