import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import BucketViewAppDetails from './BucketViewAppDetails'

class BucketAppViewModal extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    const { isVisible, handleCancel, bucketDetails, project } = this.props

    return (
      <Modal
        className='general-modal bucket-app-view-modal'
        title={'Bucket Details'}
        maskClosable={false}
        visible={isVisible}
        closable={false}
        centered
        destroyOnClose
        width={'450px'}
        // zIndex={3000}
        footer={[
          <Button key='back' onClick={handleCancel}>
              Close
          </Button>]}
      >
        <BucketViewAppDetails
          item={bucketDetails}
          project={project}
        />
      </Modal>
    )
  }
}

BucketAppViewModal.propTypes = {
  /** function to handle Back in Modal */
  handleCancel: PropTypes.func,
  /** boolean toggle to show Modal */
  isVisible: PropTypes.bool
}

export default BucketAppViewModal
