import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import AddTeam from '../../dataDisplay/AddTeam'
import './../../ui.style.scss'

class AddTeamModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  resetState = () => {
    const state = {
      newTitle: undefined,
      newMailId: undefined,
      disable: true
    }
    this.setState({ ...state })
  }

  render () {
    const { visible, handleCancel, title, okText, project } = this.props
    return (

      <Modal
        className='general-modal add-team-modal'
        visible={visible}
        closable={false}
        title={title}
        // okButtonProps={{ disabled: !(newTitle), loading: loading }}
        // onOk={this.handleSubmit}
        onCancel={handleCancel}
        okText={okText}
        cancelText='Back'
        // afterClose={this.resetState}
        width='1118px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <AddTeam project={project} />
      </Modal>
    )
  }
}

AddTeamModal.propTypes = {
  /** visible action of AddTeamModal. */
  visible: PropTypes.bool,
  /** handleCancel action of AddTeamModal. */
  handleCancel: PropTypes.func
}

AddTeamModal.defaultProps = {
}

export default AddTeamModal
