import React, { Component } from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'

import TagsInput from '../../../components/ui/dataEntry/inputs/TagsInput'
import { utilityService } from './../../../services/UtilityService'
import LoadingButton from './../../../components/ui/general/buttons/LoadingButton'

import './../../../components/ui/ui.style.scss'

import QueryGetAppsList from '../../../graphQL/partner/getAppsList'
import MutationUpdateApps from '../../../graphQL/admin/entertainmentData/updateModuleConfig'

const parentCompoent = 'partner-management-container'

class PartnerManagement extends Component {
  constructor (props) {
    super(props)
    this.state = {
      apps: props.listApps && props.listApps.length ? props.listApps : [],
      isUpdateDisabled: true,
      isLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps && newProps.listApps && !_.isEqual(this.props.listApps, newProps.listApps)) {
      this.setState({
        apps: newProps.listApps
      })
    }
  }

  handleAppsChange = (value, isDelete) => {
    const { apps } = this.state
    let newApps = [...apps] || []
    if (isDelete) {
      const index = newApps.indexOf(value)
      if (index > -1) {
        newApps.splice(index, 1)
      }
    } else {
      if (!apps) {
        newApps = [value]
      } else {
        const index = newApps.findIndex(item => item === value)
        if (index === -1) {
          newApps.push(value)
        }
      }
    }
    const uniqueApps = _.uniqBy(newApps)
    this.setState({ apps: uniqueApps, isUpdateDisabled: false })
  }

  updateApps = () => {
    const { onHistoryUpdate, project } = this.props
    this.setState({ isLoading: true })
    const { apps } = this.state
    const newApps = utilityService.getUniqueArr(apps)
    const variables = {
      id: project === 'projectx' ? 'projectx-partner_apps' : 'partner_apps',
      value: newApps,
      module: project === 'projectx' ? 'projectx-partner' : 'partner',
      project: this.props.project
    }
    this.props.updatePartnerApps(variables).then(() => {
      if (onHistoryUpdate) {
        onHistoryUpdate()
      }
      this.setState({ isUpdateDisabled: true, apps: newApps, isLoading: false })
    }, errorMsg => {
      this.setState({ isLoading: false })
      utilityService.handleError(errorMsg)
    })
  }

  render () {
    const { apps, isUpdateDisabled, isLoading } = this.state
    return (
      <div className='admin-portal partner-manger-list' id='partner-management-container'>
        <div className='input-field'>
          <label className='input-title'>Partner Manager &gt; Apps</label>
          <TagsInput
            selectedItems={apps || []}
            tagList={[]}
            className={'partner-management-apps'}
            mode={'tags'}
            onChange={this.handleAppsChange}
            placeholder='Insert Tags'
            displayParameter='name'
            parentCompoent={parentCompoent}
          />
        </div>
        <div className='admin-footer' >
          <LoadingButton
            type='primary'
            onClick={this.updateApps}
            htmlType='submit'
            buttonText={'Update'}
            buttonClass='save-btn'
            isLoading={isLoading}
            isDisabled={isUpdateDisabled}
          />
        </div>
      </div>
    )
  }
}

PartnerManagement.propTypes = {
  /** project details */
  project: PropTypes.string
}

PartnerManagement.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryGetAppsList,
    {
      options: (props) => {
        const { project } = props
        return {
          variables: { module: project === 'projectx' ? 'projectx-partner' : 'partner', project: project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        {
          const isListPresent = (props.data && props.data.listConfig && props.data.listConfig.length)
          const listValue = isListPresent ? props.data.listConfig.find(listItem => listItem.value) : null
          return {
            listApps: listValue ? listValue.value : []
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateApps,
    {
      props: (props) => ({
        updatePartnerApps: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(withApollo(PartnerManagement)))
