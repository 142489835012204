import React, { Component } from 'react'
// import PropTypes from 'prop-types'

import CustomerProfile from './CustomerProfile'
import CustomerTableDetails from './CustomerTableDetails'
import AppContext from '../../AppContext'

class CustomerDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isActive: true,
      profileOptionShown: false,
      refetch: false
    }
  }

  onExpandButtonClick = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  changeProfileOptionStatus=(status) => {
    this.setState({ profileOptionShown: status })
  }

  onRefetchData = () => {
    this.setState({ refetch: true }, () => {
      this.setState({ refetch: false })
    })
  }

  render () {
    const { selectedCustomer, selectedCustomerCogId, fetchHistory, toggleSidebar, isSideBarVisible, onChangeTab, project, onChangeEmailChange } = this.props
    const { isActive, profileOptionShown, refetch } = this.state
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['CUSTOMER_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1

          return <React.Fragment >
            <CustomerProfile
              selectedCustomer={selectedCustomer}
              selectedCustomerCogId={selectedCustomerCogId}
              onExpandButtonClick={this.onExpandButtonClick}
              isUpdateDisabled={isUpdateDisabled}
              toggleSidebar={toggleSidebar}
              isSideBarVisible={isSideBarVisible}
              profileUpdated={fetchHistory}
              changeProfileOptionStatus={this.changeProfileOptionStatus}
              project={project}
              onChangeEmailChange={onChangeEmailChange}
              onRefetchData={this.onRefetchData}
              refetch={refetch}
            />
            <CustomerTableDetails
              selectedCustomer={selectedCustomer}
              selectedCustomerCogId={selectedCustomerCogId}
              classStyle={`${isActive ? 'active' : 'not-active'}`}
              isUpdateDisabled={isUpdateDisabled}
              userPermissions={userPermissions}
              fetchHistory={fetchHistory}
              isActive={isActive}
              onChangeTab={onChangeTab}
              profileOptionShown={profileOptionShown}
              refetch={refetch}
              project={project} />
          </React.Fragment >
        }}
      </AppContext.Consumer>
    )
  }
}

CustomerDetails.propTypes = {
}

export default CustomerDetails
