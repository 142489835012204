import React from 'react'
import PropTypes from 'prop-types'

const RestoreIcon = ({ width, height, onClick, ...props }) => (
  <svg width='18' height='23' viewBox='0 0 18 23'>
    <path fill='#FFF' fillRule='nonzero' d='M0 21.53V8.333h18V21.53c0 .81-.66 1.47-1.47 1.47H1.47C.66 23 0 22.34 0 21.53zM17.333 19A3.333 3.333 0 0 0 14 22.333h2.197c.627 0 1.136-.509 1.136-1.136V19zM4 11.833v.748c0 .966.786 1.752 1.752 1.752h6.495c.967 0 1.753-.786 1.753-1.752v-.748a.5.5 0 1 0-1 0v.748a.753.753 0 0 1-.753.752H5.752A.753.753 0 0 1 5 12.581v-.748a.5.5 0 1 0-1 0zM.667 19v2.197c0 .627.509 1.136 1.136 1.136H4A3.333 3.333 0 0 0 .667 19zM9.434.182l3.407 3.525a.555.555 0 0 1-.032.807.606.606 0 0 1-.836-.03l-2.38-2.461V7.43a.58.58 0 0 1-.592.57.58.58 0 0 1-.593-.57V2.02L6.03 4.48a.613.613 0 0 1-.84.031.557.557 0 0 1-.031-.807L8.565.182a.611.611 0 0 1 .869 0z' />
  </svg>

)

RestoreIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onClick: PropTypes.func
}

RestoreIcon.defaultProps = {
  width: 15,
  height: 15
}

export default RestoreIcon
