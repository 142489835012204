import gql from 'graphql-tag'

export default gql(`
    mutation updateDRMLicenseSettings( $apiKey: String $licenseUrl: String ) {
        updateDRMLicenseSettings(
            input: {
                apiKey: $apiKey
                licenseUrl: $licenseUrl
            }
        ) {
            apiKey
            licenseUrl
        }
    }`
)
