import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'

const Option = Select.Option
const moduleMap = {
  ALL: 'All',
  ASSET_MANAGER: 'Asset Manager',
  CONTENT_BANK: 'Content Bank',
  MATCH_MANAGER: 'Match Manager',
  BUCKET_MANAGER: 'Bucket Manager',
  CHANNEL_MANAGER: 'Channel Manager',
  DATA_MANAGER: 'Admin Manager'
}

const TransferDropDown = ({ displayParam, onOptionSelect, selected, parentCompoent, options, isImage, onSearch, placeholder, disabled, type, ...props }) => {
  const optionComponents = options.map((option, index) =>
    (
      <Option className='transfer-drop-down-option' key={`option${index}`} value={option.id || option.key}>
        { isImage
          ? <img src={option.media && option.media.length && option.media[ 0 ] ? generateCroppedThumbnail(option.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
          : null }
        <p>{ option[ displayParam ] }</p>
        { type === 'tag' ? <p className='module'>{`(${moduleMap[option.module]})`}</p> : null }
        { type === 'tag' ? <p>{`(${option.assetCount + option.mediaCount}  ${option.assetCount + option.mediaCount > 1 ? 'Items' : 'item'})`}</p> : <p>{`(${option.assetCount}  ${option.assetCount > 1 ? 'Assets' : 'Asset'})`}</p>}
      </Option>
    ))

  const selectedValue = selected && selected[ displayParam ] ? (
    <React.Fragment>
      { isImage
        ? <img src={selected.media && selected.media.length && selected.media[ 0 ] ? generateCroppedThumbnail(selected.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
        : null }
      <p>{ selected[ displayParam ] }</p>
      { type === 'tag' ? <p className='module'>{`(${moduleMap[selected.module]})`}</p> : null }
      { type === 'tag' ? <p>{`(${selected.assetCount + selected.mediaCount} ${selected.assetCount + selected.mediaCount > 1 ? 'Items' : 'Item'})`}</p> : <p>{`(${selected.assetCount} ${selected.assetCount > 1 ? 'Assets' : 'Asset'})`}</p> }
    </React.Fragment>
  ) : undefined

  const onSelectOption = (id) => {
    const selectedOption = options.find(item => item.id === id || item.key === id)
    onOptionSelect(selectedOption)
  }

  return (
    <Select key={JSON.stringify(selected)} showSearch className='transfer-drop-down'
      placeholder={placeholder} value={selectedValue} onChange={onSelectOption}
      getPopupContainer={() => document.getElementById(parentCompoent)} {...props}
      onSearch={onSearch} onBlur={() => {}}
      filterOption={false}
      disabled={disabled}
    >
      { optionComponents }
    </Select>
  )
}

TransferDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** parent container id name of Dropdown. */
  parentCompoent: PropTypes.string,
  /** Selected value of Dropdown. */
  selected: PropTypes.object,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func
}

TransferDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default TransferDropDown
