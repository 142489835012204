import React, { Component } from 'react'
import { message, Skeleton } from 'antd'
import getUuid from 'uuid-by-string'

import EditPageButton from '../../components/ui/general/buttons/EditPageButton'
import WidgetDetailList from '../../components/ui/dataDisplay/WidgetDetailsList'
import { utilityService } from './../../services/UtilityService'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import CopyLinkButton from '../../components/ui/general/buttons/CopyLinkButton'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationAddPageLayoutItem from '../../graphQL/appManager/addPageLayoutItem'
import MutationEditPageLayoutItem from '../../graphQL/appManager/editPageLayoutItem'

import { multiSelect } from './../../components/ui/dataDisplay/utils'

const width = [ '100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%' ]
class PageGeneralDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // pageDetails: _.cloneDeep(props.pageDetails),
      showWidgetModal: false,
      pageConfigDetails: null,
      selectedImgConfig: null,
      showAddImageModal: false,
      isWidgetObjectSaveLoading: false,
      isWidgetObjectSaveDisable: true,
      isJsonValid: true,
      isWidgetEdit: false,
      editWidgetInstance: null,
      showWidgetEditeWarning: false,
      isWidgetEditSaveLoading: false,
      draggableWidget: null,
      jsonInvalidEntries: [],
      jsonArrayInvalidEntries: [],
      isJsonArrayValid: true,
      isJsonArrayVisible: false,
      jsonConfig: null,
      jsonSelectedMeta: null,
      entities: this.loadPageDetailsTolistData(props.pageDetails) || {}
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.draggable && newProps.droppableId && (!this.props.draggable || newProps.draggable.randomKey !== this.props.draggable.randomKey)) {
      const tempId = getUuid(newProps.droppableId + newProps.draggable.id)
      const tempWidget = {
        id: tempId,
        widget: newProps.draggable,
        type: 'WIDGET',
        position: newProps.destinationIndex + 1,
        isLoading: true
      }

      let isValueExist = (newProps.draggable.fieldValues || []).filter(field => !_.isEmpty(field.value) || (field.configField && field.configField.isRequired === false) || (field.configField && field.configField.isRequired === true && field.configField.isUpdatable === false))
      if (!_.isEmpty(isValueExist)) {
        this.setState({
          isWidgetObjectSaveDisable: false
        })
      }
      let pageConfigDetails = this.handlePageConfigCoversion(newProps.draggable.fieldValues || [])

      this.setState({
        showWidgetModal: true,
        draggableWidget: newProps.draggable,
        tempWidget: tempWidget,
        pageConfigDetails
      })
    }
    if (newProps.pageDetails && newProps.pageDetails.layoutItems && !_.isEqual(newProps.pageDetails.layoutItems, this.props.pageDetails.layoutItems)) {
      this.setState({ entities: this.loadPageDetailsTolistData(newProps.pageDetails) })
    }
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  hideAddWidgetObjectModal = (resetState, isWidgetEdit) => {
    this.setState({
      showWidgetModal: false,
      pageConfigDetails: null,
      isJsonValid: true,
      isWidgetObjectSaveDisable: true,
      isWidgetEdit: false,
      editWidgetInstance: null,
      jsonInvalidEntries: [],
      jsonArrayInvalidEntries: []
    })

    if (resetState && !isWidgetEdit) {
      this.props.resetPageState()
    }
  }

  handleWidgetDetailsChange = (configData, value) => {
    const { draggableWidget, isWidgetEdit, editWidgetInstance, jsonSelectedMeta } = this.state
    const { pageConfigDetails } = this.state
    if (configData.type === 'JSON') {
      let isJsonValid = this.handleJsonValidation(configData, value)
      this.setState({
        isJsonValid
      })
    }
    if (configData.type === 'JSONARRAY') {
      let isJsonArrayValid = this.handleJsonArrayValidation(configData, value)
      let duplicateJsonSelectedMeta = _.cloneDeep(jsonSelectedMeta)
      duplicateJsonSelectedMeta.value = value
      this.setState({
        isJsonArrayValid,
        jsonSelectedMeta: duplicateJsonSelectedMeta
      })
    }
    let fields = isWidgetEdit ? editWidgetInstance.fieldValues : draggableWidget.fields

    const config = (fields || []).map(item => {
      if (item.name === configData.name && item.type === configData.type) {
        item.value = item.type === 'STRING' ? (value || '') : item.type === 'IMAGE' ? value && value[0] : value
        delete item.isError
      } else {
        const tempItem = (pageConfigDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
      }
      return item
    })
    this.setState({ pageConfigDetails: config, isWidgetObjectSaveDisable: false })
  }

  handlePageWidgetSave = async () => {
    const { pageConfigDetails, draggableWidget, tempWidget, editWidgetInstance, isWidgetEdit, jsonInvalidEntries, jsonArrayInvalidEntries } = this.state
    const { pageDetails, project, isCriticalWriteDisabled, isAppAdminDisabled, userId } = this.props
    const { assignedUsers } = pageDetails
    const isAppCritical = (pageDetails && pageDetails.app && pageDetails.app.isCritical) || false
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Page')
      return
    }

    if (isCriticalWriteDisabled && isAppAdminDisabled && ((pageDetails && pageDetails.isCritical) || isAppCritical) && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Page')
      return
    }
    if (!_.isEmpty(jsonInvalidEntries)) {
      message.error('Please enter valid JSON Value')
      this.props.updateForceSaveState(false)
      return
    }
    if (!_.isEmpty(jsonArrayInvalidEntries)) {
      message.error('Please enter valid JSON ARRAY Value')
      this.props.updateForceSaveState(false)
      return
    }
    let mandatoryMeta = []
    let fields = isWidgetEdit ? editWidgetInstance.fieldValues : draggableWidget.fields
    if ((pageConfigDetails && pageConfigDetails.length) || (fields)) {
      mandatoryMeta = (fields || []).filter(metaData => {
        const tempItem = (pageConfigDetails || []).find(innerItem => innerItem.name === metaData.name && metaData.type === innerItem.type)
        return _.isEmpty(tempItem) ? (metaData.isRequired && metaData.type !== 'BOOLEAN' && metaData.isUpdatable === true) && (metaData.value === null || metaData.value === '' || metaData.value === undefined) : (tempItem.isRequired && tempItem.type !== 'BOOLEAN' && tempItem.isUpdatable === true) && (tempItem.value === null || tempItem.value === '' || tempItem.value === undefined)
      })
    }
    if (mandatoryMeta && mandatoryMeta.length) {
      message.error('Mandatory fields are missing')
      const newConfigDetails = (fields || []).map(item => {
        const tempItem = (pageConfigDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
        if (item.isRequired && (item.value === null || item.value === '' || item.value === undefined)) {
          item.isError = true
        }
        return item
      })
      this.setState({
        isWidgetObjectSaveDisable: true,
        pageConfigDetails: newConfigDetails
      })
      return
    }
    this.setState({
      isWidgetObjectSaveLoading: true
    })

    let editWidgetSubmit = (pageDetails.layoutItems || []).find(config => {
      if (config && editWidgetInstance) { return config.id === editWidgetInstance.id }
    })

    const app = pageDetails && pageDetails.app && pageDetails.app.id

    if (editWidgetSubmit) {
      if (this.props.checkForPageModification) {
        if (await this.props.checkForPageModification()) {
          this.setState({
            isWidgetObjectSaveLoading: false,
            editWidgetInstance: null,
            isWidgetObjectSaveDisable: true,
            isWidgetEdit: false,
            showWidgetEditeWarning: false,
            isWidgetEditSaveLoading: false,
            jsonInvalidEntries: [],
            jsonArrayInvalidEntries: []
          })
          this.hideAddWidgetObjectModal()
          return
        }
      }
      let fieldValues = pageConfigDetails.map(item => {
        let value = ''
        let bucket = ''
        let image = ''
        let useAsTitle = false
        let position = ''
        if (item.type === 'BUCKET') {
          bucket = item.value ? item.value && item.value.id : ''
        } else if (item.type === 'IMAGE') {
          image = item.value ? item.value && item.value && item.value.id : ''
        } else {
          value = item.value || item.value === false ? item.value : ''
        }
        if (item.useAsTitle) {
          useAsTitle = item.useAsTitle ? item.useAsTitle : false
        }
        position = item.position
        return {
          configField: item.id,
          value,
          bucket,
          image,
          useAsTitle,
          project,
          position
        }
      })

      const sortedFieldValues = _.sortBy(fieldValues || [], 'position')

      let input = {
        id: editWidgetInstance.id,
        position: editWidgetInstance.position,
        page: pageDetails.id,
        type: 'WIDGET',
        widget: editWidgetInstance.widget.id,
        fieldValues: sortedFieldValues,
        project: project,
        isEnabled: editWidgetInstance.isEnabled,
        app: app
      }

      this.setState({
        isWidgetEditSaveLoading: true
      })

      this.props.editPageLayoutItem(input).then(({ data }) => {
        this.props.handleEditPageLayoutItems(data && data.editPageLayoutItem, pageDetails)
        if (this.props.fetchUpdatedPageUpdateTime) {
          this.props.fetchUpdatedPageUpdateTime()
        }
        message.success('Widget Instance Updated Successfully')
        if (this.props.refetchHistory) {
          this.props.refetchHistory()
        }
        this.hideAddWidgetObjectModal()
        this.setState({
          isWidgetObjectSaveLoading: false,
          editWidgetInstance: null,
          isWidgetObjectSaveDisable: true,
          isWidgetEdit: false,
          showWidgetEditeWarning: false,
          isWidgetEditSaveLoading: false,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []
        })
      }, (error) => {
        utilityService.handleError(error)
        this.hideAddWidgetObjectModal()
        this.setState({
          isWidgetObjectSaveLoading: false,
          editWidgetInstance: null,
          isWidgetObjectSaveDisable: true,
          isWidgetEdit: false,
          showWidgetEditeWarning: false,
          isWidgetEditSaveLoading: false,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []
        })
      })
    } else {
      if (this.props.checkForPageModification) {
        if (await this.props.checkForPageModification()) {
          this.setState({
            isWidgetObjectSaveLoading: false,
            isWidgetObjectSaveDisable: true,
            jsonInvalidEntries: [],
            jsonArrayInvalidEntries: []
          })
          this.hideAddWidgetObjectModal()
          return
        }
      }
      let fieldValues = pageConfigDetails.map(item => {
        let value = ''
        let bucket = ''
        let image = ''
        let useAsTitle = null
        let position = ''
        if (item.type === 'BUCKET') {
          bucket = item.value ? item.value && item.value.id : ''
        } else if (item.type === 'IMAGE') {
          image = item.value ? item.value && item.value && item.value.id : ''
        } else {
          value = item.value ? item.value : ''
        }
        if (item.useAsTitle) {
          useAsTitle = item.useAsTitle ? item.useAsTitle : false
        }

        position = item.position
        return {
          configField: item.id,
          value,
          bucket,
          image,
          useAsTitle,
          project,
          position
        }
      })

      const sortedFieldValues = _.sortBy(fieldValues || [], 'position')
      let input = {
        position: tempWidget.position,
        page: pageDetails.id,
        type: tempWidget.type,
        widget: tempWidget.widget.id,
        fieldValues: sortedFieldValues,
        project: project,
        isEnabled: false,
        app: app
      }
      this.props.addPageLayoutItem(input).then(({ data }) => {
        this.props.handlePageLayoutItems(data && data.addPageLayoutItem)
        if (this.props.fetchUpdatedPageUpdateTime) {
          this.props.fetchUpdatedPageUpdateTime()
        }
        this.hideAddWidgetObjectModal()
        if (this.props.refetchHistory) {
          this.props.refetchHistory()
        }
        this.setState({
          isWidgetObjectSaveLoading: false,
          isWidgetObjectSaveDisable: true,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []
        })
      }, (error) => {
        utilityService.handleError(error)
        this.hideAddWidgetObjectModal()
        this.setState({
          isWidgetObjectSaveLoading: false,
          isWidgetObjectSaveDisable: true,
          jsonInvalidEntries: [],
          jsonArrayInvalidEntries: []
        })
      })
    }
  }

  isValidJson = (json, isJSONArray) => {
    try {
      if (isJSONArray && !isNaN(json)) {
        return false
      }
      JSON.parse(json)
      return true
    } catch (e) {
      return false
    }
  }

  handlePageLayoutItemEdit = (layoutItem) => {
    let copyLayoutItem = _.cloneDeep(layoutItem)
    let fieldValues = copyLayoutItem && copyLayoutItem.fieldValues
    let pageConfigDetails = this.handlePageConfigCoversion(fieldValues || [])
    let widgetFieldValues = this.handlePageConfigCoversion(copyLayoutItem.widget.fieldValues || [])
    let fieldValueToAdd = _.differenceBy(widgetFieldValues, pageConfigDetails, 'id')
    if (fieldValueToAdd.length > 0) {
      pageConfigDetails = pageConfigDetails.concat(fieldValueToAdd)
    }
    pageConfigDetails = _.intersectionBy(pageConfigDetails, widgetFieldValues, 'id')
    copyLayoutItem.fieldValues = pageConfigDetails
    let editWidgetInstance = copyLayoutItem
    this.setState({
      pageConfigDetails,
      editWidgetInstance: editWidgetInstance,
      showWidgetModal: true,
      isWidgetEdit: true
    })
  }

  handleWidgetObjectSave = () => {
    const { isWidgetEdit, jsonInvalidEntries, jsonArrayInvalidEntries } = this.state
    if (isWidgetEdit) {
      if (!_.isEmpty(jsonInvalidEntries)) {
        message.error('Please enter valid JSON Value')
        this.props.updateForceSaveState(false)
        return
      }
      if (!_.isEmpty(jsonArrayInvalidEntries)) {
        message.error('Please enter valid JSON ARRAY Value')
        this.props.updateForceSaveState(false)
        return
      }
      const { pageConfigDetails, draggableWidget, editWidgetInstance, isWidgetEdit } = this.state
      let mandatoryMeta = []
      let fields = isWidgetEdit ? editWidgetInstance.fieldValues : draggableWidget.fields
      if ((pageConfigDetails && pageConfigDetails.length) || (fields)) {
        mandatoryMeta = (fields || []).filter(metaData => {
          const tempItem = (pageConfigDetails || []).find(innerItem => innerItem.name === metaData.name && metaData.type === innerItem.type)
          return _.isEmpty(tempItem) ? (metaData.isRequired && metaData.type !== 'BOOLEAN' && metaData.isUpdatable === true) && (metaData.value === null || metaData.value === '' || metaData.value === undefined) : (tempItem.isRequired && tempItem.type !== 'BOOLEAN' && tempItem.isUpdatable === true) && (tempItem.value === null || tempItem.value === '' || tempItem.value === undefined)
        })
      }
      if (mandatoryMeta && mandatoryMeta.length) {
        message.error('Mandatory fields are missing')
        const newConfigDetails = (fields || []).map(item => {
          const tempItem = (pageConfigDetails || []).find(innerItem => innerItem.name === item.name && item.type === innerItem.type)
          item = _.isEmpty(tempItem) ? item : tempItem
          if (item.isRequired && (item.value === null || item.value === '' || item.value === undefined)) {
            item.isError = true
          }
          return item
        })
        this.setState({
          isWidgetObjectSaveDisable: true,
          pageConfigDetails: newConfigDetails
        })
        return
      }
      this.setState({
        showWidgetEditeWarning: true,
        showWidgetModal: false
      })
    } else {
      this.handlePageWidgetSave()
    }
  }

  handleEditeWarningCancel = (status) => {
    this.setState({
      showWidgetEditeWarning: status
    })
  }

  handleListWidgetImgCropInstanceEdit = (editWidgetInstance) => {
    let fieldValues = editWidgetInstance && editWidgetInstance.fieldValues
    let pageConfigDetails = this.handlePageConfigCoversion(fieldValues || [])
    editWidgetInstance.fieldValues = pageConfigDetails
    this.setState({
      pageConfigDetails,
      editWidgetInstance: editWidgetInstance
    })
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  handlePageConfigCoversion = (fieldValues) => {
    let pageConfigDetails = (fieldValues || []).map(field => {
      let value
      if (field.configField && field.configField.type === 'IMAGE') {
        value = field.image ? field.image : ''
      } else if (field.configField && field.configField.type === 'BUCKET') {
        value = field.bucket ? field.bucket : ''
      } else {
        value = field.value
      }
      return {
        id: (field.configField && field.configField.id) || '',
        isRequired: (field.configField && field.configField.isRequired) || false,
        name: (field.configField && field.configField.name) || '',
        type: (field.configField && field.configField.type) || '',
        useAsTitle: (field.configField && field.configField.useAsTitle) || false,
        value: value,
        position: (field.configField && (field.configField.position)) || null,
        isUpdatable: (field.configField) ? field.configField.isUpdatable : null
      }
    })
    pageConfigDetails = (pageConfigDetails || []).filter(item => item.id)
    return pageConfigDetails
  }

  handleMandatoryCheck = () => {

  }

  copyLink = () => {
    var anchor = document.createElement('a')
    anchor.href = `${location.origin}/apps/pages/preview/${this.props.pageDetails.id}`
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  handleJsonValidation = (configData, value) => {
    const { jsonInvalidEntries } = this.state
    let isJsonValid = true
    if (_.isEmpty(value)) {
      isJsonValid = true
      let index = jsonInvalidEntries.indexOf(configData.id)
      if (index !== -1) {
        jsonInvalidEntries.splice(index, 1)
      }
    } else {
      let isValidJson = this.isValidJson(value)
      if (!isValidJson) {
        let index = jsonInvalidEntries.indexOf(configData.id)
        if (index === -1) {
          jsonInvalidEntries.push(configData.id)
        }
        isJsonValid = false
      } else {
        let index = jsonInvalidEntries.indexOf(configData.id)
        if (index !== -1) {
          jsonInvalidEntries.splice(index, 1)
        }
        isJsonValid = true
      }
    }
    return isJsonValid
  }

  handleJsonArrayValidation = (configData, value) => {
    const { jsonArrayInvalidEntries } = this.state
    let isJsonArrayValid = true
    if (_.isEmpty(value)) {
      isJsonArrayValid = true
      let index = jsonArrayInvalidEntries.indexOf(configData.id)
      if (index !== -1) {
        jsonArrayInvalidEntries.splice(index, 1)
      }
    } else {
      let isValidJson = this.isValidJson(value, true)
      if (!isValidJson) {
        let index = jsonArrayInvalidEntries.indexOf(configData.id)
        if (index === -1) {
          jsonArrayInvalidEntries.push(configData.id)
        }
        isJsonArrayValid = false
      } else {
        let index = jsonArrayInvalidEntries.indexOf(configData.id)
        if (index !== -1) {
          jsonArrayInvalidEntries.splice(index, 1)
        }
        isJsonArrayValid = true
      }
    }
    return isJsonArrayValid
  }

  handleJsonArrayVisibilty = (jsonConfig, jsonSelectedMeta) => {
    this.setState({
      isJsonArrayVisible: true,
      jsonConfig,
      jsonSelectedMeta
    })
  }

  handleJsonArrayHide = () => {
    const { jsonSelectedMeta } = this.state
    if (!_.isEmpty(jsonSelectedMeta.value)) {
      let parseValue = JSON.parse(jsonSelectedMeta.value)
      if (!Array.isArray(parseValue)) {
        message.warn('Please enter valid JSON array')
        return
      }
    }
    this.setState({
      isJsonArrayVisible: false,
      jsonConfig: null,
      jsonSelectedMeta: null
    })
  }

  toggleSelection = (taskId) => {
    const selectedTaskIds = this.props.selectedTaskIds
    const wasSelected = selectedTaskIds.includes(taskId)

    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId]
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId]
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return []
    })()
    this.setState({
      // selectedTaskIds: newTaskIds,
      entities: this.state.entities
    })
    this.props.setSelectedTaskIds(newTaskIds, this.state.entities, true)
  }

  toggleSelectionInGroup = (taskId) => {
    const selectedTaskIds = this.props.selectedTaskIds
    const index = selectedTaskIds.indexOf(taskId)

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        // selectedTaskIds: [...selectedTaskIds, taskId],
        entities: this.state.entities
      })
      this.props.setSelectedTaskIds([...selectedTaskIds, taskId], this.state.entities)
      return
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedTaskIds]
    shallow.splice(index, 1)
    this.setState({
      // selectedTaskIds: shallow,
      entities: this.state.entities
    })
    this.props.setSelectedTaskIds(shallow, this.state.entities)
  }

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = (newTaskId) => {
    const updated = multiSelect(
      this.state.entities,
      this.props.selectedTaskIds,
      newTaskId
    )
    if (updated == null) {
      return
    }
    this.setState({
      // selectedTaskIds: updated,
      entities: this.state.entities
    })
    this.props.setSelectedTaskIds(updated, this.state.entities)
  }

  loadPageDetailsTolistData = (pageDetails) => {
    if (pageDetails && pageDetails.layoutItems && !_.isEmpty(pageDetails.layoutItems)) {
      let tasks = {}
      const listData = this.getSortedList(pageDetails.layoutItems)
      const list = listData.map((task) => {
        tasks = {
          ...tasks,
          [task.id]: task
        }
        return task.id
      })
      const entities = {
        columnOrder: ['#Page_' + pageDetails.id],
        columns: {
          ['#Page_' + pageDetails.id]: {
            id: '#Page_' + pageDetails.id,
            taskIds: list
          }
        },
        tasks
      }
      return entities
      // this.setState({
      //   entities
      // })
    }
  }

  render () {
    const {
      showWidgetModal,
      pageConfigDetails,
      showAddImageModal,
      selectedImgConfig,
      isWidgetObjectSaveLoading,
      isWidgetObjectSaveDisable,
      isWidgetEdit,
      editWidgetInstance,
      showWidgetEditeWarning,
      isWidgetEditSaveLoading,
      jsonArrayInvalidEntries,
      jsonInvalidEntries,
      isJsonArrayValid,
      isJsonArrayVisible,
      jsonConfig,
      jsonSelectedMeta
    } = this.state

    const { pageDetails, handlePageLayoutItemDelete, onEditClick, isUpdateDisabled, hasViewAccessEnabled, draggable, handleLayoutItemStatus, fetchAppPageDetails,
      isCreateDisabled, project, pageReorderLoading, selectedTaskIds, handlePageLayoutItemPin } = this.props

    return (
      <div className='app-page-wrapper'>
        <div className='app-page-general-details'>
          <div className='general-details'>

            <div className='details large'>
              <div className='data'>
                <span> {pageDetails ? pageDetails.title : '-'}</span>
              </div>
              <div className='data-tags'>
                {/* <span>{tagsString || '-'}</span> */}
                <span>{pageDetails ? pageDetails.key : '-'}</span>
              </div>
            </div>

            <div className='details large'>
              <div className='data-app'>
                <span>{pageDetails && pageDetails.app ? pageDetails.app.name : '-'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='app-page-details-footer'>
          <div className='edit-button'>
            <ButtonContainer displayTitle='Edit' childComponent={<EditPageButton onClick={onEditClick} isDisabled={isUpdateDisabled && isCreateDisabled} />} />
          </div>
          <div className='copy-button'>
            <ButtonContainer displayTitle='View API Response' childComponent={<CopyLinkButton onClick={this.copyLink} isDisabled={false} />} />
          </div>
        </div>
        <div className='app-widget-details'>
          <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
            loading={pageReorderLoading}>
            {
              <WidgetDetailList
                listData={this.getSortedList(_.cloneDeep((pageDetails && pageDetails.layoutItems)) || [])}
                pageId={pageDetails.id}
                hideAddWidgetObjectModal={this.hideAddWidgetObjectModal}
                showWidgetModal={showWidgetModal}
                draggable={draggable}
                handleWidgetDetailsChange={this.handleWidgetDetailsChange}
                pageConfigDetails={pageConfigDetails}
                project={project}
                selectedImgConfig={selectedImgConfig}
                showAddImageModal={showAddImageModal}
                handleWidgetObjectSave={this.handleWidgetObjectSave}
                isWidgetObjectSaveLoading={isWidgetObjectSaveLoading}
                isWidgetObjectSaveDisable={isWidgetObjectSaveDisable || isUpdateDisabled}
                handlePageLayoutItemDelete={handlePageLayoutItemDelete}
                handlePageLayoutItemEdit={this.handlePageLayoutItemEdit}
                isWidgetEdit={isWidgetEdit}
                editWidgetInstance={editWidgetInstance}
                handleLayoutItemStatus={handleLayoutItemStatus}
                fetchAppPageDetails={fetchAppPageDetails}
                handleListWidgetImgCropInstanceEdit={this.handleListWidgetImgCropInstanceEdit}
                pageDetails={_.cloneDeep(pageDetails)}
                isUpdateBlocked={isUpdateDisabled}
                hasViewAccessEnabled={hasViewAccessEnabled}
                jsonInvalidEntries={jsonInvalidEntries}
                jsonArrayInvalidEntries={jsonArrayInvalidEntries}
                isJsonArrayValid={isJsonArrayValid}
                isJsonArrayVisible={isJsonArrayVisible}
                jsonConfig={jsonConfig}
                jsonSelectedMeta={jsonSelectedMeta}
                handleJsonArrayVisibilty={this.handleJsonArrayVisibilty}
                handleJsonArrayHide={this.handleJsonArrayHide}
                handlePageLayoutItemPin={handlePageLayoutItemPin}
                toggleSelection={this.toggleSelection}
                toggleSelectionInGroup={this.toggleSelectionInGroup}
                multiSelectTo={this.multiSelectTo}
                selectedTaskIds={selectedTaskIds}
              />
            }
          </Skeleton>
        </div>
        <ConfirmModal isVisible={showWidgetEditeWarning} title={'Widget'} message={'Are you Sure You Want to save widget ? Changes will be updated Instantly ?'} isLoading={isWidgetEditSaveLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handleEditeWarningCancel(false)} handleSubmit={this.handlePageWidgetSave} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
      </div>
    )
  }
}

PageGeneralDetails.propTypes = {
}

export default withApollo(
  compose(
    graphql(
      MutationAddPageLayoutItem, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          addPageLayoutItem: (input) => {
            return props.mutate({
              variables: { input, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationEditPageLayoutItem, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          editPageLayoutItem: (input) => {
            return props.mutate({
              variables: { input, project: props.ownProps.project }
            })
          }
        })
      }
    )
  )(PageGeneralDetails))
