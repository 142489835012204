import React, { Component } from 'react'
import { message } from 'antd'

import EditButton from '../../components/ui/general/buttons/EditButton'
import CopyLinkButton from '../../components/ui/general/buttons/CopyLinkButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'

import { withApollo } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'

class ConfigGeneralDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
    // this.editModalContainer = null
  }

  componentDidMount = () => {
    // document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.error && newProps.error !== this.props.error && !newProps.isLoading) {
      message.error(newProps.error.replace('GraphQL error: ', ''))
    }
  }

  componentWillUnmount () {
    // document.removeEventListener('mousedown', this.handleClickOutside)
  }

  copyLink = () => {
    var anchor = document.createElement('a')
    anchor.href = `${location.origin}/apps/configs/preview/${this.props.details.id}`
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  render () {
    const { onEditClick, details, isUpdateDisabled } = this.props
    const tags = ((details && details.tags) || []).map(tag => {
      if (tag) {
        return tag.name
      }
    })

    return (
      <div className='app-config-wrapper'>
        <div className='app-general-details'>
          <div className='general-details'>

            <div className='details large'>
              <div className='data'>
                <span> Title: </span>
                <span> {details ? details.title : '-'}</span>
              </div>
              <div className='data'>
                <span> Group: </span>
                <span>{details && details.group ? details.group.name : '-'}</span>
              </div>
              <div className='data'>
                <span> App: </span>
                <span>{details && details.app ? details.app.name : '-'}</span>
              </div>
            </div>

            <div className='details large'>
              <div className='data'>
                <span> Key: </span>
                <span>{details ? details.key : '-'}</span>
              </div>
              <div className='data'>
                <span> Type: </span>
                <span>{details ? `${details.type === 'CONFIG' ? 'Single' : 'List'}` : '-'}</span>
              </div>
              <div className='data'>
                <span> Tags: </span>
                <span>{ tags ? tags.toString() : '-'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='app-config-details-footer'>
          <div className='edit-button'>
            <EditButton onClick={onEditClick} isDisabled={isUpdateDisabled} />
          </div>
          <div className='copy-button'>
            <ButtonContainer displayTitle='View API Response' childComponent={<CopyLinkButton onClick={this.copyLink} isDisabled={false} />} />
          </div>
        </div>

      </div>
    )
  }
}

ConfigGeneralDetails.propTypes = {
}

export default withApollo(compose(

)(ConfigGeneralDetails))
