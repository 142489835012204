import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import _ from 'lodash'
import '../../ui.style.scss'

const DeleteFolderModal = ({ isVisible, isLoading, title, message1, message2, isLeftPrior, rightButtonText, leftButtonText, handleCancel, handleSubmit, isSubmitButtonDisabled, handleClose, isCancelButtonDisabled, isCancelButtonInvisible, deleteItems, ...props }) => (
  <Modal
    className={`confirm-modal ${isLeftPrior ? 'left-prior' : ''}${isCancelButtonInvisible ? ' cancel-not-visible' : ''}`}
    title={title}
    visible={isVisible}
    cancelText={leftButtonText}
    okText={rightButtonText}
    onOk={handleSubmit}
    onCancel={handleClose || handleCancel}
    okButtonProps={{ disabled: isSubmitButtonDisabled }}
    cancelButtonProps={{ disabled: isCancelButtonDisabled, onClick: handleCancel }}
    closable={false}
    centered
    confirmLoading={isLoading}
  >
    <p>{message1}</p>
    <p>{!_.isEmpty(deleteItems) && deleteItems.map((item) => item.name ? item.name : item.id).reduce((prev, curr) => [prev, ', ', curr])}</p>
    <p>{message2}</p>
  </Modal>
)

DeleteFolderModal.propTypes = {
  /** loading status of Modal. */
  isLoading: PropTypes.bool,
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Title of Modal. */
  title: PropTypes.string.isRequired,
  /** Description message of Modal. */
  message1: PropTypes.string.isRequired,
  /** Description message of Modal. */
  message2: PropTypes.string.isRequired,
  /** Button priority status of Modal. if 'isleftPrior' is 'true', 'Cancel' button is of higher priority */
  isLeftPrior: PropTypes.bool,
  /** Left button display text of Modal. */
  leftButtonText: PropTypes.string,
  /** Right button display text of Modal. */
  rightButtonText: PropTypes.string,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Submit button disable status of Modal. */
  isSubmitButtonDisabled: PropTypes.bool,
  /**  Submit button disable status of Modal. */
  isCancelButtonDisabled: PropTypes.bool,
  /** Boolean for making cancel button invisible */
  isCancelButtonInvisible: PropTypes.bool,
  /** List of all items to be deleted */
  deleteItems: PropTypes.array
}

DeleteFolderModal.defaultProps = {
  selectedValue: 'Select'
}

export default DeleteFolderModal
