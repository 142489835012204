import gql from 'graphql-tag'

export default gql(`
    mutation createStreamLevel(  $group: String! $name: String! $fields: [CreateFieldInput] $version: String! ) {
        createStreamLevel(input:{
            group: $group
            name:$name
            fields: $fields
            version: $version
        }){
            id
            name
            group
            version
            fields{
                name
                type
                value
                oldValue
                displayName
                displayValue
                isRequired
                fieldName
                maxValue
                isPosChange
            }
        }
    }`
)
