import gql from 'graphql-tag'

export default gql(`
    mutation gracenoteOnDemandSync($runProgramIngest: Boolean!, $runProgramAvailIngest: Boolean!) {
        gracenoteOnDemandSync(
            runProgramIngest: $runProgramIngest
            runProgramAvailIngest:$runProgramAvailIngest
        ) {
            status
            data {
                programIngestResult {
                    id
                }
                programScheduleIngestResult{
                    id
                }
            }
        }
    }`
)
