import gql from 'graphql-tag'

export default gql(`
    mutation duplicateAppPage($id: ID!, $title: String, $key: String!, $app: ID!, $group: ID, $tags: [ID], $shortDescription: String, $layoutItems: [ID], $slug: String, $seoKeywords: [String], $seoMetaDescription: String, $seoTitle: String, $isPublished: Boolean, $isArchived: Boolean, $publishStartDate: AWSDateTime, $publishEndDate: AWSDateTime, $customMeta: [MetaFieldInput], $project: String,  $scheduledVersions: [ID], $isCritical: Boolean, $assignedUsers: [String] ) {
        duplicateAppPage(
          input: {
            id: $id
            title: $title
            key: $key
            app: $app
            group: $group
            tags: $tags
            shortDescription: $shortDescription
            layoutItems: $layoutItems
            slug: $slug
            seoKeywords: $seoKeywords
            seoMetaDescription: $seoMetaDescription
            seoTitle: $seoTitle
            isPublished: $isPublished
            isArchived: $isArchived
            publishStartDate: $publishStartDate
            publishEndDate: $publishEndDate
            customMeta: $customMeta
            scheduledVersions: $scheduledVersions
            isCritical: $isCritical
            assignedUsers: $assignedUsers
          }
          project: $project
        ) {
            id
            key
            title
            shortTitle
            shortDescription
            description
            isCritical
            app{
                id
                name
                url
            }
            group{
                key
                name
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
            project
            createdAt
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
            updatedBy
            createdBy
            isPublished
            isArchived
            publishStartDate
            publishEndDate
            slug
            seoKeywords
            seoMetaDescription
            seoTitle
            scheduledVersions{
                id
                layoutItems{
                    id
                    position
                    type
                    isEnabled
                    page{
                        id
                        title
                    }
                }
                isPublished
                publishStartDate
                publishEndDate
                layoutItems{
                    id
                    position
                    type
                    isEnabled
                    page{
                        id
                        title
                    }
                    widget{
                        id
                        key
                        title
                        widgetPreview{
                            id
                    fileName
                    createdAt
                    name
                    type
                    fileSize
                    duration
                    thumbnails
                    tags{
                        key
                        name
                        type
                    }
                    category{
                        id
                        label
                        type
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2
                    }
                    dimensions{
                        x1
                        x2
                        y1
                        y2
                    }
                    aspectRatio {
                        aspectRatio
                        ratio
                        title
                        resolutions {
                        key
                        width
                        height
                        url
                        }
                    }
                        }  
                    }
                }
            }
        }
            
    }`
)
