import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
// import { message, Button as LoadingButton } from 'antd'
import moment from 'moment'
import _, { isEmpty } from 'lodash'
import { GlobalHotKeys } from 'react-hotkeys'

import DataPicker from './DatePicker'
import Switch from './Switch'
import CopyLinkButton from './../../general/buttons/CopyLinkButton'
import ViewPageButton from './../../general/buttons/ViewPageButton'
import ViewAppleNewsButton from './../../general/buttons/ViewAppleNewsButton'
import Button from './../../general/buttons/Button'
import userMessages from './../../../../constants/messages'
import ButtonContainer from '../../general/buttons/ButtonContainer'
import CollapsedIcon from '../../general/icons/CollapsedIcon'
import Tooltip from '../../dataDisplay/ToolTip'
import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'
import { checkIsItExpired, redirectToLink } from '../../../../util/util'

const keyMap = {
  shortcutPublish: 'c+p'
}

class PublishDetailCell extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      isUpdating: false,
      isAppleUrlLoading: false,
      contentExpired: false
    }
  }

  keyHandlers = {
    shortcutPublish: () => {
      if (this.props.isDefault) {
        this.onPublishAll(true)
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.isActive !== this.props.isActive || nextProps.isError) { this.setState({ isLoading: false }) }
    if (nextProps.startDate && this.state.startDateWarning) { this.setState({ startDateWarning: false }) }
  }

  copyToClipboard = () => {
    var textField = document.createElement('textarea')
    textField.innerText = this.props.shareLink
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    message.success(userMessages.ASSET_LINK_COPIED)
  }

  onChange = (value) => {
    if (this.state.isLoading) {
      return
    }
    // const { endDate } = this.props
    // if (!value && moment().isAfter(moment(endDate), 'seconds')) {
    //   message.error('Please select valid publish dates')
    //   return
    // }
    this.setState({ isUpdating: true, isLoading: true }, () => {
      setTimeout(() => {
        this.setState({ isUpdating: false })
      }, 2500)
    })
    if (this.props.onChange) { this.props.onChange(value) }
  }

  endDateChange = (value) => {
    if (!this.props.startDate) { this.setState({ startDateWarning: true }) }
    if (this.props.onEndDateChange) { this.props.onEndDateChange(value) }
  }

  disableEndDate = (currentDate) => {
    let IsValidDate = moment(currentDate).isBefore(moment(new Date()), 'day')
    if (this.props.startDate) {
      IsValidDate = IsValidDate || moment(currentDate).isBefore(moment(this.props.startDate))
      return moment(currentDate).isBefore(moment(this.props.startDate), 'day')
    }
    return IsValidDate
  }

  disableStartDate = (currentDate) => {
    if (this.props.endDate) {
      return moment(currentDate).isAfter(moment(this.props.endDate), 'day')
    }
    return false
  }

  disabledRangeTime = (type) => {
    let { startDate, endDate } = this.props
    startDate = moment(startDate).toDate()
    endDate = moment(endDate).toDate()
    let minutes = []
    const isDateEqual = moment(startDate).format('DD MMM YYYY') === moment(endDate).format('DD MMM YYYY')
    if (type === 'end' && startDate && (isDateEqual || !endDate)) {
      let hours = this.range(0, 24)
      hours.splice(startDate.getHours())
      if (!endDate || endDate.getHours() === startDate.getHours()) {
        minutes = this.range(0, 60)
        minutes.splice(startDate.getMinutes() + 1)
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      }
    } else if (type === 'start' && endDate && (isDateEqual || !startDate)) {
      let hours = this.range(0, 24).splice(endDate.getHours() + 1)
      if (!startDate || endDate.getHours() === startDate.getHours()) {
        minutes = this.range(0, 60)
        minutes = minutes.splice(endDate.getMinutes())
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => []
    }
  }

  range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  disableTimeComplete = () => {
    const hours = this.range(0, 24)
    const minutes = this.range(0, 60)
    return {
      disabledHours: () => hours,
      disabledMinutes: () => minutes
    }
  }

  makeAccordionVisible = () => {
    if (this.props.makeAccordionVisible) { this.props.makeAccordionVisible() }
  }

  onPublishAll = (isPublish) => {
    const { startDate, endDate } = this.props
    this.props.onPublishAll(
      {
        publishStartDate: startDate || new Date().toISOString(),
        publishEndDate: endDate,
        isPublish
      })
  }

  onLoadingClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  onOpenChange = (value, isStart) => {
    this.props.onOpenChange(value, isStart)
    if (!value) {
      this.setState({ isUpdating: true }, () => {
        setTimeout(() => {
          this.setState({ isUpdating: false })
        }, 2500)
      })
    }
  }

  triggerAppleNewsURL = () => {
    this.setState({ isAppleUrlLoading: true })
    this.props.triggerAppleNewsPreviewURL().then((url) => {
    }).catch(() => {
      message.error(userMessages.APPLE_NEWS_URL_FAILED)
    }).finally(() => {
      this.setState({ isAppleUrlLoading: false })
    })
  }

  // expireCheck = (array, updatedAt) => {
  //   for (let i = 0; i < array.length; i++) {
  //     const { isEnabled, publishEndDate } = array[i]
  //     if (isEnabled && publishEndDate) {
  //       const expire = checkIsItExpired(publishEndDate)
  //       this.setState({ contentExpired: expire })
  //       break
  //     } else if (!isEnabled && !publishEndDate) {
  //       const expire = checkIsItExpired(updatedAt)
  //       this.setState({ contentExpired: expire })
  //       break
  //     }
  //   }
  // }

  expireCheck = (isPublishAllLoading, isPublishDisabled, isLoadingLayerPresent) => {
    let publishButtonExpiry = window.config.publishButtonExpiry
    if (publishButtonExpiry && publishButtonExpiry === 'true') {
      const { publishRules, details } = this.props
      let expired = true
      let hasEndDate = false
      if (publishRules.length === 0) {
        return (isPublishAllLoading || isPublishDisabled || isLoadingLayerPresent)
      }
      for (let i = 0; i < publishRules.length; i++) {
        const { isEnabled, publishEndDate } = publishRules[i]
        if (isEnabled) {
          hasEndDate = true
        }
        if (isEnabled && !publishEndDate) {
          expired = false
        }
        if (publishEndDate) {
          hasEndDate = true
          let _expired = checkIsItExpired(publishEndDate)
          if (_expired === false) {
            expired = false
            break
          }
        }
      }
      if (!hasEndDate) {
        expired = checkIsItExpired(details.updatedAt)
      }
      return (expired || isPublishAllLoading || isPublishDisabled || isLoadingLayerPresent)
    } else {
      return (isPublishAllLoading || isPublishDisabled || isLoadingLayerPresent)
    }
  }

  componentDidUpdate (prevProps) {
    const { publishRules, details } = this.props
    if (publishRules !== prevProps.publishRules) {
      this.expireCheck(publishRules, details.updatedAt)
    }
  }

  componentDidMount () {
    const { publishRules, details } = this.props
    if (!isEmpty(publishRules)) {
      this.expireCheck(publishRules, details.updatedAt)
    }
  }

  render () {
    const { type, dateFormat, startDate, endDate, isActive, isLinkEnabled, onStartDateChange, isAccordionActive, isPublishAllLoading,
      isUnpublishAllLoading, isDefault, publishItems, totalCount, isPublishDisabled, project, canTriggerAppleNewsURL, hidePublishAll = false } = this.props
    const { startDateWarning, isUpdating, isLoading, isAppleUrlLoading } = this.state
    const publishCount = publishItems && publishItems.length ? publishItems.length : 0
    const randomId = utilityService.makeRandomString(8)
    const isExpired = endDate && moment(endDate).isBefore(moment())
    const isHyperion = (project === 'hyperion' || project === 'projectx') && isActive
    const projectId = utilityService.getCurrentProject() // TBR checking for development (DO NOT SHOW)
    const hideAppleNews = (projectId === 'hyperion' || projectId === 'projectx')
    const isLoadingLayerPresent = isPublishAllLoading || isUnpublishAllLoading || isUpdating || isLoading

    return (
      <div className='publish-details-cell' >
        {
          !hidePublishAll && <>
            {isDefault
              ? <div className='publish-all-container' id={randomId}>
                <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
                {(publishCount || isHyperion)
                  ? <Tooltip childComponent={<span className={publishCount === totalCount ? 'published' : `count`}>{publishCount === totalCount ? 'All Published' : `${publishCount} of ${totalCount} Published`}</span>}
                    isList heading='Published' details={isHyperion ? ['ALL'] : publishItems} placement='rightTop' parentCompoent={randomId} /> : <span className='un-published'>{'All Unpublished'}</span>}
                <div className='button-container'>
                  <Button buttonText={isPublishAllLoading ? 'Loading...' : 'Publish All'} onClick={() => this.onPublishAll(true)}
                    isDisabled={this.expireCheck(isPublishAllLoading, isPublishDisabled, isLoadingLayerPresent)}
                  // {contentExpired || isPublishAllLoading || isPublishDisabled || isLoadingLayerPresent}
                  />
                  <Button buttonText={isUnpublishAllLoading ? 'Loading...' : 'Unpublish All'} onClick={() => this.onPublishAll(false)}
                    isDisabled={this.expireCheck(isPublishAllLoading, isPublishDisabled, isLoadingLayerPresent)}
                  // {contentExpired || isUnpublishAllLoading || isPublishDisabled || isLoadingLayerPresent}
                  />
                </div>
              </div> : <div><label className='publish-title'>{type}</label>
                <div className='switch-container'>
                  <ButtonContainer displayTitle={isActive ? 'Unpublish' : 'Publish'} childComponent={<Switch
                  // disabled={contentExpired || isPublishDisabled || isLoadingLayerPresent}
                    disabled={this.expireCheck(isPublishAllLoading, isPublishDisabled, isLoadingLayerPresent)}
                    isChecked={isActive !== undefined ? isActive : false} onChange={_.debounce(() => this.onChange(isActive !== undefined ? isActive : false), 500)} loading={this.state.isLoading} />} />
                </div></div>}
            <div className='date'>
              <div className={`date-selector${startDateWarning ? ' error' : ''}`}>
                <DataPicker
                  date={startDate ? moment(new Date(startDate), dateFormat).format(dateFormat) : null}
                  disabledDate={this.disableStartDate}
                  dateFormat={dateFormat}
                  onChange={onStartDateChange}
                  onOpenChange={(value) => this.onOpenChange(value, true)}
                  placeholder='Select Start Date'
                  allowClear={false}
                  use12Hours={false}
                  disabled={this.expireCheck(isPublishAllLoading, isPublishDisabled, isLoadingLayerPresent)}
                  disabledTime={startDate ? () => this.disabledRangeTime('start') : () => this.disableTimeComplete()} />
              </div>
              <div className={`date-selector${isExpired ? ' expired' : ''}`}>
                <DataPicker
                  use12Hours={false}
                  date={endDate ? moment(new Date(endDate), dateFormat).format(dateFormat) : null}
                  disabledTime={endDate ? () => this.disabledRangeTime('end') : () => this.disableTimeComplete()}
                  disabledDate={this.disableEndDate}
                  dateFormat={dateFormat}
                  onOpenChange={(value) => this.onOpenChange(value, false)}
                  onChange={this.endDateChange}
                  disabled={this.expireCheck(isPublishAllLoading, isPublishDisabled, isLoadingLayerPresent)}
                  placeholder='Select End Date' />
              </div>
            </div>
          </>
        }
        {isLinkEnabled ? <div className='link-buttons'
          style={{ marginTop: !hidePublishAll ? '20px' : '0px' }} >
          <div>
            {isDefault
              ? <div className={`accordion-arrow ${isAccordionActive ? 'active' : ''}`} onClick={this.makeAccordionVisible}>
                {!hidePublishAll && <><div>{isAccordionActive ? 'Less Rules' : 'More Rules'}</div>
                  <CollapsedIcon /></>
                }
              </div> : ''}
          </div>
          <div className='button-container'>
            <ButtonContainer displayTitle='Copy Link' childComponent={<CopyLinkButton onClick={this.copyToClipboard} />} />
            <ButtonContainer displayTitle='Preview' childComponent={<ViewPageButton onClick={() => redirectToLink(this.props.shareLink)} />} />
            {this.props.triggerAppleNewsPreviewURL && !hideAppleNews ? <ButtonContainer displayTitle='Apple News Preview' childComponent={<ViewAppleNewsButton isDisabled={!canTriggerAppleNewsURL} isLoading={isAppleUrlLoading} onClick={this.triggerAppleNewsURL} />} /> : null}
          </div>
          {/* <Button buttonText='Preview' color='gray' onClick={this.redirectToLink} /> */}
        </div> : null}
      </div>
    )
  }
}

PublishDetailCell.propTypes = {
  /** start publish date of PublishDetailCell. */
  type: PropTypes.string,
  /** start publish date of PublishDetailCell. */
  startDate: PropTypes.string,
  /** end publish date of PublishDetailCell. */
  endDate: PropTypes.string,
  /** pubish active status of PublishDetailCell. */
  isActive: PropTypes.bool,
  /** end publish date of PublishDetailCell. */
  dateFormat: PropTypes.string,
  /** publish status change action of PublishDetailCell. */
  onChange: PropTypes.any,
  /** publish start date  change action of PublishDetailCell. */
  onStartDateChange: PropTypes.any,
  /** publish start date change action of PublishDetailCell. */
  onEndDateChange: PropTypes.any,
  /** Boolean indicates accordion open or not */
  isAccordionActive: PropTypes.bool,
  /** Boolean to disable publish */
  isPublishDisabled: PropTypes.bool
}

PublishDetailCell.defaultProps = {
  dateFormat: 'DD MMM YYYY, hh:mm A'
}

export default PublishDetailCell
