import gql from 'graphql-tag'

export default gql(`
    mutation transferAssets($input: TransferAssetInput!, $type: String, $project: String) {
        transferAssets(
            input: $input,
            type: $type
            project: $project
        )
    }`
)
