import gql from 'graphql-tag'

export default gql(`
    mutation ($id: ID!, $activeConfigGroup: ID) {
        updateChannel(input: {id: $id, activeConfigGroup: $activeConfigGroup }) {
            id
            name
            icon{
                id
                url
                fileName
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
            }
            configGroups {
                schemaId{
                    id
                  }
                id
                position
                name
                fields{
                    name
                    value
                    isRequired
                    type
                }
                streams{
                    id
                    fields{
                        name
                        value
                        isRequired
                        type
                    }
                    url
                    streamSchema{
                        id
                        levels{
                            id
                            name
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                        }
                    }
                }
            }
            activeConfigGroup{
                id
                name
                streams{
                    id
                    fields{
                        name
                        isRequired
                        type
                        value
                    }
                }
                schemaId{
                    id
                    streams{
                        id
                        levels{
                            id
                            fields{
                                name
                                isRequired
                                type
                            }
                        }
                    }
                }
            }
        }
   }`
)
