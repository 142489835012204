import gql from 'graphql-tag'

export default gql(`
    mutation addPageLayoutItem($input: addPageLayoutItemInput, $project:String) {
        addPageLayoutItem(
          project: $project
          input: $input
        ) {
            id
            position
            type
            title
            widget {
              id
              key
              title
              description  
              fieldValues {
                configField {
                  id
                  name
                  type
                  isRequired
                  project
                  useAsTitle
                  createdAt
                  createdBy
                  position
                  isUpdatable
                  
                }
                value
                useAsTitle
                bucket {
                  project
                  id
                  isArchived
                  name
                  isPublished
                  publishStartDate
                  publishEndDate
                  updatedAt
                  updatedBy
                  key
                  displayName
                  displayType {
                    name
                    id
                  }
                }
                image {
                  id
                  fileName
                  createdAt
                  name
                  type
                  fileSize
                  duration
                  thumbnails
                  tags {
                    key
                    name
                    type
                  }
                  category {
                    id
                    label
                    type
                  }
                  settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
        
                  }
                  dimensions {
                    x1
                    x2
                    y1
                    y2
                    
                  }
                  aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                      key
                      width
                      height
                      url
                      
                    }
                    
                  }
                  
                }
                project
                position
              }
              widgetPreview {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
              }
           }
            fieldValues {
              configField {
                id
                name
                type
                isRequired
                project
                useAsTitle
                createdAt
                createdBy
                position
                isUpdatable
                
              }
              value
              useAsTitle
              bucket {
                project
                id
                isArchived
                name
                isPublished
                publishStartDate
                publishEndDate
                updatedAt
                updatedBy
                key
                displayName
                displayType {
                  name
                  id
                }
              }
              image {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags {
                  key
                  name
                  type
                }
                category {
                  id
                  label
                  type
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
      
                }
                dimensions {
                  x1
                  x2
                  y1
                  y2
                  
                }
                aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                    key
                    width
                    height
                    url
                    
                  }
                  
                }
                
              }
              project
              position
              
            }
            project
            isEnabled
            
          }
    }`
)
