import React from 'react'
import PropTypes from 'prop-types'

const VideoFileIcon = ({ width, height, selected, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill={selected ? '#FFF' : '#343A40'} fillRule='nonzero' d='M19.548 5.995a.968.968 0 0 0-.926-.04L16.19 7.14v-.354C16.19 5.25 14.91 4 13.333 4H2.857C1.282 4 0 5.25 0 6.786v7.428C0 15.75 1.282 17 2.857 17h10.476c1.576 0 2.857-1.25 2.857-2.786v-.354l2.432 1.184a.965.965 0 0 0 .926-.04.925.925 0 0 0 .452-.79V6.786a.925.925 0 0 0-.452-.79zM4.762 11.893c-.79 0-1.429-.623-1.429-1.393s.64-1.393 1.429-1.393c.79 0 1.428.623 1.428 1.393s-.639 1.393-1.428 1.393z' />
    </g>
  </svg>
)

VideoFileIcon.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

VideoFileIcon.defaultProps = {
  selected: false,
  width: 20,
  height: 20
}

export default VideoFileIcon
