import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from '@hello-pangea/dnd'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'
import BucketSideBar from './BucketSideBar'
import BucketGroupDetails from './BucketGroupDetails'
import './BucketManager.scss'
import { utilityService } from '../../services/UtilityService'
import AppContext from '../../AppContext'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAllBucketDisplayTypes from '../../graphQL/bucket/getBucketDisplayType'
import QueryGetDefaultBucketGroup from './../../graphQL/admin/getModuleConfig'
// import { defaultFieldResolver } from 'graphql'

let loadingBuckets = []
class BucketManager extends Component {
  state= {
    mode: 'buckets',
    sourceIndex: undefined,
    destinationIndex: undefined,
    droppableId: undefined,
    draggableId: undefined,
    appliedBuckets: [],
    unsavedBuckets: [],
    showWarning: false,
    isFetchHistory: false,
    tempSelectedId: '',
    filterData: {
      sort: {
        value: 'createdAt',
        order: 'desc'
      },
      filter: {}
    },
    draggingTaskId: undefined,
    disableAssetDrag: false
  }

  componentDidMount = () => {
    const { project } = this.props
    document.title = `Bucket Manager - ${project === 'hyperion' ? 'Hyperion' : 'Optus'} CMS`
    window.addEventListener('click', this.onWindowClick)
    window.addEventListener('keydown', this.onWindowKeyDown)
    window.addEventListener('touchend', this.onWindowTouchEnd)
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.onWindowClick)
    window.removeEventListener('keydown', this.onWindowKeyDown)
    window.removeEventListener('touchend', this.onWindowTouchEnd)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.showBucketWarning) { this.setState({ showWarning: true }) }
  }

  onChangeLoadingBucket=(id, operation) => {
    let newArray = _.cloneDeep(loadingBuckets)
    if (operation === 'ADD') {
      const index = (newArray || []).findIndex(item => item === id)
      if (index < 0) {
        newArray = [ ...newArray, id ]
        this.setState({ loadingBuckets: newArray })
        loadingBuckets = newArray
      }
    } else if (operation === 'REMOVE') {
      const index = (newArray || []).findIndex(item => item === id)
      if (index > -1) {
        newArray.splice(index, 1)
      }
      this.setState({ loadingBuckets: newArray })
      loadingBuckets = newArray
    }
    if (loadingBuckets.length > 0) {
      this.setState({ disableAssetDrag: true })
    } else {
      this.setState({ disableAssetDrag: false })
    }
  }

  moveBucketToBucketGroup = (sourceIndex, destinationIndex, droppableId, draggableId) => {
    this.setState({ sourceIndex, destinationIndex, droppableId, draggableId: `${draggableId}-random${utilityService.makeRandomString(6)}` })
    // let { bucketList, assetList } = this.state
    // let index = bucketList.findIndex((item) => item.id === droppableId)
    // const assetIndex = assetList.findIndex((item) => item.id === draggableId)
    // bucketList[index].details.splice(destinationIndex, 0, assetList[assetIndex])
    // // assetList.splice(assetIndex, 1)
    // this.setState({ bucketList, assetList })
  }

  onDragStart = (start) => {
    const id = start.draggableId
    const selected = this.state.selectedTaskIds.find(
      (taskId) => taskId === id
    )

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      this.unselectAll()
    }
    this.setState({
      draggingTaskId: start.draggableId
    })
  };

  onDragEnd = (result) => {
    const { source, destination } = result
    // const { totalBucketList, bucketList } = this.state
    // dropped outside the list
    if (!destination) {
      return
    }
    if (source.droppableId === 'asset-list' && destination.droppableId === 'asset-list') {
      // const items = reorder(
      //   this.state.assetList,
      //   source.index,
      //   destination.index
      // )
      // this.setState({
      //   assetList: items
      // })
    } else if (source.droppableId === 'asset-list' && destination.droppableId.startsWith('#Bucket_')) {
      const id = result.draggableId.split('#Asset_list_')[0]
      const dropableId = destination.droppableId.split('#Bucket_')[1]
      this.moveBucketToBucketGroup(source.index, destination.index, dropableId, id)
    } else if (source.droppableId.startsWith('#Bucket_') && destination.droppableId.startsWith('#Bucket_')) {
      const dragBucketId = source.droppableId.split('#Bucket_')[1]
      const dropBucketId = destination.droppableId.split('#Bucket_')[1]
      if (dragBucketId === dropBucketId) {
        if (source.index !== destination.index) {
          this.moveBucketToBucketGroup(source.index, destination.index, dropBucketId, undefined)
        }
      } else {
        // const id = result.draggableId.split('bucket-item')[0]
        // REMOVE ITEM FROM DRAG BUCKET AND DROP IN DESTINATION BUCKET- PRIORITY LESS
      }
    }
  }

  onBucketGroupChange = (id, isSameBucket, bucketId, isSavedAll) => {
    let { unsavedBuckets, tempSelectedId, tempBucketId, selectedgroupId } = this.state
    const isBucketsUnsaved = unsavedBuckets.filter((item) => item.isChanged)
    if (isSameBucket) {
      loadingBuckets = []
      this.setState({ prevSelectedgroupId: selectedgroupId, selectedGroupId: id || tempSelectedId, selectedBucketId: bucketId || tempBucketId, showWarning: false, unsavedBuckets: [], tempSelectedId: '', tempBucketId: '' })
    } else if (isBucketsUnsaved && isBucketsUnsaved.length && !isSavedAll) {
      this.props.onChangeBucketStatus(!!(isBucketsUnsaved && isBucketsUnsaved.length))
      this.setState({ showWarning: true, tempSelectedId: id, tempBucketId: bucketId })
    } else {
      if ((id || tempSelectedId) && !this.props.showBucketWarning) {
        this.props.onChangeBucketStatus(false)
        loadingBuckets = []
        this.setState({ prevSelectedgroupId: selectedgroupId, selectedGroupId: id || tempSelectedId, selectedBucketId: bucketId || tempBucketId, showWarning: false, unsavedBuckets: [], tempSelectedId: '', tempBucketId: '' })
      } else {
        this.props.notifyParent()
      }
    }
  }

  appliedBuckets = appliedBuckets => {
    this.setState({ appliedBuckets })
  }

  onBucketAdd = (bucketId) => {
    let { appliedBuckets } = this.state
    appliedBuckets.push(bucketId)
    this.setState({ newBucket: bucketId, appliedBuckets })
    setTimeout(() => this.setState({ newBucket: undefined }), 1000)
  }

  changeBucket = (id, isChanged) => {
    let { unsavedBuckets } = this.state
    const index = unsavedBuckets.findIndex((item) => item.id === id)
    if (index === -1) {
      unsavedBuckets.push({
        id, isChanged
      })
    } else {
      unsavedBuckets[index].isChanged = isChanged
    }
    this.setState({ unsavedBuckets })
    const isBucketsUnsaved = unsavedBuckets.filter((item) => item.isChanged)
    // if (isBucketsUnsaved && isBucketsUnsaved.length) {
    this.props.onChangeBucketStatus(!!(isBucketsUnsaved && isBucketsUnsaved.length))
  }

  onChangeAssetFilter = (filterData) => {
    this.setState({ filterData })
  }

  clearDragDropId = () => {
    this.setState({ droppableId: undefined, draggableId: undefined })
  }

  clearModuleChange = () => {
    this.setState({ showWarning: false })
    this.props.clearModuleChange()
  }

  updateHistory = () => {
    this.setState({ isFetchHistory: true }, () => {
      this.setState({ isFetchHistory: false })
    })
  }

  render () {
    const { selectedGroupId, sourceIndex, destinationIndex, droppableId, draggableId, newBucket, appliedBuckets, showWarning, filterData, selectedBucketId, isFetchHistory, disableAssetDrag } = this.state
    const { displayTypes, defaultBucketGroup } = this.props
    return (
      <Row className='bucket-manager'>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Col xs={{ span: 6 }} className='bucket-sidebar-container'>
            <BucketSideBar
              onBucketAdd={this.onBucketAdd}
              addedBucket={appliedBuckets}
              selectedBucketGroup={selectedGroupId}
              onSelectBucketGroup={this.onBucketGroupChange}
              onChangeAssetFilter={this.onChangeAssetFilter}
              isFetchHistory={isFetchHistory}
              displayTypes={displayTypes}
              disableAssetDrag={disableAssetDrag}
            />
          </Col>
          <Col xs={{ span: 18 }} className='bucket-details-container' id='bucket-details-container'>
            <AppContext.Consumer>
              {({ project }) => {
                return <BucketGroupDetails
                  bucketGroupId={selectedGroupId}
                  bucketId={selectedBucketId}
                  onBucketGroupChange={this.onBucketGroupChange}
                  sourceIndex={sourceIndex}
                  destinationIndex={destinationIndex}
                  droppableId={droppableId}
                  draggableId={draggableId}
                  newBucket={newBucket}
                  filterData={filterData}
                  appliedBuckets={this.appliedBuckets}
                  clearDragDropId={this.clearDragDropId}
                  changeBucket={this.changeBucket}
                  showWarning={showWarning}
                  notifyParent={this.clearModuleChange}
                  updateHistory={this.updateHistory}
                  displayTypes={displayTypes}
                  draggingTaskId={this.state.draggingTaskId}
                  defaultBucketGroup={defaultBucketGroup}
                  changeLoadingBucket={this.onChangeLoadingBucket}
                  project={project}
                />
              }}
            </AppContext.Consumer>
          </Col>
        </DragDropContext>
      </Row>
    )
  }
}

BucketManager.propTypes = {
  /* History parameter of dom */
  history: PropTypes.object,
  /* match parameter of dom */
  match: PropTypes.object,
  /** Function to be called when changes happens in bucket */
  onChangeBucketStatus: PropTypes.func,
  /** Boolean for showing warning for unsaved buckets */
  showBucketWarning: PropTypes.bool

}

export default withApollo(compose(
  graphql(
    QueryAllBucketDisplayTypes,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 30, project },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listDisplayType } }) => {
        return { displayTypes: listDisplayType && listDisplayType.items ? [ ...listDisplayType.items ] : undefined }
      }
    }
  ),
  graphql(
    QueryGetDefaultBucketGroup,
    {
      options: (props) => {
        return {
          fetchPolicy: 'network-only',
          variables: { id: props.project + '_default_bucket_group' }
        }
      },
      props: ({ data }) => {
        return {
          defaultBucketGroup: data && data.getConfig ? data.getConfig.value && data.getConfig.value[0] : ''
          //   timeout: data && data.getConfig ? data.getConfig.value && data.getConfig.value[1] : ''
        }
      }
    }
  )
)(withRouter(withApollo(BucketManager))))
