import * as React from 'react'
import FolderIcon from '../../general/icons/FolderIcon'
import VideoFileIcon from '../../general/icons/VideoFileIcon'
import PopoverButton from '../../general/buttons/PopoverButton'
import DropDown from '../inputs/DropDown'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryS3FolderList from '../../../../graphQL/content/listS3Folder'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import { Skeleton, message } from 'antd'
import { getFileSize, videoFormat } from '../../../../util/util'

// const parseDatas = (data, prefix) => {
//   const prefixTemp = prefix || '/'
//   const prefixLength = prefix ? (prefixTemp.match(/\//g) || []).length + 1 : 0
//   return data.filter(item => {
//     const tempArr = item.Key.match(/\//g) ? item.Key.split('/') : []
//     const length = tempArr.length
//     const lastItem = tempArr[length - 1]
//     return ((prefixLength - length) === 0 || (lastItem === '' && length - prefixLength < 3)) && item.Key !== prefix
//   })
// }

const limitOption = [
  {
    value: 200,
    displayParam: 'Show 200'
  },
  {
    value: 300,
    displayParam: 'Show 300'
  },
  {
    value: 500,
    displayParam: 'Show 500'
  },
  {
    value: 1000,
    displayParam: 'Show 1000'
  }
]

const parseData = (data, prefix, error) => {
  if (error) {
    // message.warn('Invalid settings, Please update settings from Admin Manager')
    return []
  } else {
    return data.filter(item => {
      return (item.Key !== prefix) || !prefix.includes('/')
    })
  }
}

class RemoteFolderList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedName: '',
      shouldShowPopup: false,
      isPaginating: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.remoteFolderError && !this.props.remoteFolderError) {
      message.warn('Invalid settings, Please update settings from Admin Manager')
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = () => {
    if (this.state.shouldShowPopup) {
      setTimeout(() => this.setState({ shouldShowPopup: false }), 200)
    }
  }

  toggleShowPopup = () => {
    this.setState({ shouldShowPopup: !this.state.shouldShowPopup })
  }

  selectMinimizePath = (option) => {
    this.selectFolderPath(option.index + 1)
  }

  getPath = (pathArr, index) => {
    return pathArr.slice(0, index + 1).join('/')
  }

  onOptionSelect = (limit) => {
    this.props.selectLimit(limit)
  }

  parseFolderPath = (folderList, prefix) => {
    let newTempArr = []; let fileList = prefix ? folderList.filter((item) => item.Key !== prefix || item.Size) : folderList
    fileList.map((file) => {
      const item = file.Key
      const pathArr = item.split('/')
      const level = pathArr.length
      const pathObj = {
        name: pathArr[level - 1] ? pathArr[level - 1] : pathArr[level - 2],
        isLeaf: !(pathArr[level - 1] === ''),
        absolutePath: item,
        lastUpdate: file.LastModified ? moment(file.LastModified).format('DD MMM YYYY') : '',
        size: file.Size
      }
      newTempArr.push(pathObj)
    })
    return _.uniqBy(newTempArr.filter((item) => item.name), 'absolutePath')
  }

  renderFolder = (parsedFilePathArr) => {
    return parsedFilePathArr.map((item, index) => this.getItem(item, index))
  }

    selectItem = (selectedItem) => {
      this.setState({ selectedName: selectedItem.name }, () => {
        if (!selectedItem.isLeaf) {
          setTimeout(() => {
            this.setState({ selectedName: '' })
          }, 500)
        }
      })
      this.props.onSelect(selectedItem)
    }

    getItem = (item, key) => {
      const { selectedName } = this.state
      const isSelected = selectedName === item.name
      const size = getFileSize(item.size)
      const formatCheckArr = item.isLeaf ? item.name.split('.') : []
      const isDisabled = videoFormat.indexOf(formatCheckArr[formatCheckArr.length - 1]) === -1
      return <div className={`file-item folder-heading${isSelected ? ' selected' : ''}${isDisabled && item.isLeaf ? ' disabled' : ''}`} onClick={() => this.selectItem(item)} key={key}>
        <div className='files'>
          {item.isLeaf ? <VideoFileIcon selected={isSelected} /> : <FolderIcon selected={isSelected} />}
          <span className='file-name'>{item.name}</span>
        </div>
        <div className='file-size'>{item.isLeaf ? (size || '-') : '-'}</div>
        <div className='last-update'>{item.lastUpdate}</div>
      </div>
    }

    selectFolderPath = (index) => {
      const { prefix } = this.props
      const pathArr = prefix.split('/')
      const tempPath = index !== undefined ? pathArr.slice(0, index) : undefined
      const absolutePath = tempPath && tempPath.length ? tempPath.join('/') + '/' : undefined
      const tempObj = {
        absolutePath,
        isLeaf: false
      }
      // const index = pathArr.slice(0, index)
      this.props.onSelect(tempObj)
    }

    loadMoreFolder = () => {
      // if (this.props.isTruncated) {
      //   this.setState({ isPaginating: true }, () => {
      //     this.props.getFolderList(this.props.nextToken).then(() => {
      //       this.setState({ isPaginating: false })
      //     })
      //   })
      // }
    }

    renderMinimizeHeader = (folderPath) => {
      const { shouldShowPopup } = this.state
      const length = folderPath.length
      const startingIndex = length - 4
      const completeNameArr = folderPath.slice(startingIndex)
      const minimizeNameArr = folderPath.slice(0, startingIndex)
      let pathComplete = completeNameArr.map((item, index) => <React.Fragment><span key={'key_' + index}>{'>'}</span><div key={index} onClick={() => this.selectFolderPath(index + 1 + startingIndex)}>{item}</div></React.Fragment>)
      const pathMinimize = minimizeNameArr.map((item, index) => { return { name: item, value: item, index: index } })
      const popoverButton = <PopoverButton
        button={<div onClick={this.toggleShowPopup} style={{ minWidth: '10px' }}>{'...'}</div>}
        placement='bottomLeft'
        displayParam='name'
        contents={pathMinimize}
        onContentClick={this.selectMinimizePath}
        parentCompoent={'remote-folder-container'}
        isVisible={shouldShowPopup}
      />
      pathComplete.unshift(popoverButton)
      pathComplete.unshift(<span>{'>'}</span>)
      return pathComplete
    }

    renderHeader = () => {
      const { prefix } = this.props
      if (!prefix) { return <div onClick={() => this.selectFolderPath('')}>Root</div> } else {
        const headerPathArr = prefix.split('/')
        const filterHeader = headerPathArr.filter((item) => item)
        let headerPath = filterHeader.length < 5 ? filterHeader.map((item, index) => <React.Fragment><span key={'key_' + index}>{'>'}</span>
          <div key={index} onClick={() => this.selectFolderPath(index + 1)}>{item}</div></React.Fragment>)
          : this.renderMinimizeHeader(filterHeader)
        headerPath.unshift(<div onClick={() => this.selectFolderPath('')}>{'Root'}</div>)
        return headerPath
      }
    }

    render () {
      const { folderList, folderListLoading, prefix, isTruncated, maxKeys } = this.props
      const { isPaginating } = this.state
      this.parsedFilePathArr = this.parseFolderPath(folderList, prefix)
      const content = this.renderFolder(this.parsedFilePathArr)
      return <div className='remote-folder-container' id='remote-folder-container'>
        <div className='folder-tree-container'>
          <div className='folder-tree'>
            {this.renderHeader()}
          </div>
          <DropDown options={limitOption} selectedValue={maxKeys}
            placeHolder='Select Video Category' valueParam='value'
            displayParam='displayParam' onOptionSelect={this.onOptionSelect}
            className={maxKeys ? 'selected limit' : 'limit'} />
        </div>
        <div className='folder-heading'>
          <div className='files'>File/Folder</div>
          <div className='file-size'>Size</div>
          <div className='last-update'>Last Updated</div>
        </div>
        <div className='folder-container'>
          <Skeleton active avatar={false} title={false} paragraph={{ rows: 5 }} loading={folderListLoading}>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMoreFolder}
              hasMore={isTruncated}
              initialLoad={false}
              useWindow={false}
            >
              {content}
            </InfiniteScroll>
          </Skeleton>
          <Skeleton active avatar={false} title={false} paragraph={{ rows: 2 }} loading={isPaginating} />
        </div>
      </div>
    }
}

RemoteFolderList.propTypes = {
  /**  Array of folder and files */
  folderList: PropTypes.any
}

export default withApollo(compose(
  graphql(
    QueryS3FolderList,
    {
      options: ({ prefix, maxKeys, project }) => {
        let variables = { project }
        if (prefix) { variables.prefix = prefix }
        variables.delimiter = '/'
        variables.maxKeys = maxKeys
        // if (prefix) { variables.prefix = prefix } else { variables.delimiter = '/' }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: ({ data, prefix, project }) => {
        let rootFolderList = []
        if (!prefix) {
          rootFolderList = data && data.listS3Objects && !!data.listS3Objects.CommonPrefixes ? data.listS3Objects.CommonPrefixes.map((item) => {
            return {
              Key: item.Prefix,
              LastModified: undefined
            }
          }) : []
        }
        let folderList = data && data.listS3Objects && !!data.listS3Objects.Contents ? prefix ? data.listS3Objects.Contents : [...rootFolderList, ...data.listS3Objects.Contents] : []
        return {
          // folderList,
          folderList: parseData(folderList, _.isEmpty(data.variables) ? '' : data.variables.prefix, data.error),
          remoteFolderError: data.error,
          // folderList: folderList.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified)),
          nextToken: data && data.listS3Objects ? data.listS3Objects.NextContinuationToken : undefined,
          isTruncated: data && data.listS3Objects ? data.listS3Objects.IsTruncated : false,
          folderListLoading: data.loading,
          getFolderList: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextContinuationToken: nextToken,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newFolderList = fetchMoreResult.listS3Objects ? fetchMoreResult.listS3Objects.CommonPrefixes.map((item) => {
                  return {
                    Key: item,
                    LastModified: null,
                    Size: null,
                    __typename: 'S3Object'
                  }
                }) : []
                const newList = [ ...prev.listS3Objects.Contents, ...newFolderList, ...fetchMoreResult.listS3Objects.Contents ]
                prev.listS3Objects.Contents = newList
                prev.listS3Objects.NextContinuationToken = fetchMoreResult.listS3Objects.NextContinuationToken
                prev.listS3Objects.IsTruncated = fetchMoreResult.listS3Objects.IsTruncated
                // prev.listS3Objects.CommonPrefixes = [ ...prev.listS3Objects.CommonPrefixes, ...fetchMoreResult.listS3Objects.CommonPrefixes ]
                return prev
              }
            })
          }
        }
      }

    }
  )
)(withRouter(withApollo(RemoteFolderList))))
