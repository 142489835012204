import gql from 'graphql-tag'

export default gql(`
    mutation updateEpgConfigSetting( $id: ID! $url: String  $frequency: Int $fileName: String) {
        updateEpgConfigSetting(
            input: {
                id: $id
                url: $url
                frequency: $frequency
                fileName: $fileName
            }
        ) {
            id
            url
            frequency
            status
            fileName
        }
    }`
)
