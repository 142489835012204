import gql from 'graphql-tag'

export default gql(`
query getUserAdminAttributes( $id: String! ) {
    getUserAdminAttributes( 
      id: $id
    ) {
        adminAttributes{
           key
           value
           title
        }
    }
}`)
