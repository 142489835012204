import React, { Component } from 'react'
import { Skeleton } from 'antd'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { contextMenu } from 'react-contexify'
import _ from 'lodash'
import AppContext from '../../../AppContext'
import ListAppConfigsCell from './ListAppConfigsCell'

class ListAppConfigs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      configList: props.configList
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.configList, this.props.configList)) {
      this.setState({
        configList: newProps.configList
      })
    }
  }

  render () {
    const { isLoading, configList } = this.state
    const { onDragEnd, configClickMenu, handleListConfigInstanceStatus, hasViewAccessEnabled, onDoubleClick } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['APP_MANAGER']
          // const isUpdateDisabled = userPermissions.indexOf('UPDATE_CONFIG') === -1
          const isUpdateConfigValueDisabled = userPermissions.indexOf('UPDATE_CONFIG_VALUES') === -1
          return <div className='list-app-config-container'>
            <div className='list-app-config'>
              <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(droppableProvided, droppableSnapshot) => (
                      <div className='config-group-content'
                        ref={droppableProvided.innerRef}
                      >
                        {configList.sort((a, b) => a.position - b.position).map((config, index) => (
                          <Draggable key={config.id} draggableId={`id${config.id}`} index={index} isDragDisabled={isUpdateConfigValueDisabled}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  onContextMenu={(e) => contextMenu.show({
                                    id: `${config.id}-config-list-menu-item`,
                                    event: e
                                  })}
                                >
                                  <ListAppConfigsCell
                                    configInstance={config}
                                    onDoubleClick={onDoubleClick}
                                    isUpdateDisabled={isUpdateConfigValueDisabled}
                                    handleListConfigInstanceStatus={handleListConfigInstanceStatus}
                                  />
                                </div>
                                {(!isUpdateConfigValueDisabled || hasViewAccessEnabled) ? configClickMenu(config.id) : '' }
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Skeleton>
            </div>
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

export default ListAppConfigs
