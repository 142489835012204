import React from 'react'
import PropTypes from 'prop-types'

const PartnerIcon = ({ width, height, onClick, color, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <g fill='#343A40' fillRule='nonzero'>
        <g >
          <path fill={color} d='M4.585 0C2.054 0 0 2.054 0 4.585c0 2.53 2.054 4.584 4.585 4.584 2.53 0 4.584-2.054 4.584-4.584C9.17 2.054 7.116 0 4.585 0zm0 8.308C2.53 8.308.86 6.638.86 4.585.861 2.53 2.531.86 4.585.86c2.054 0 3.723 1.67 3.723 3.724 0 2.054-1.67 3.723-3.723 3.723z' transform='translate(-442 -132) translate(442 132)' />
          <path fill={color} d='M4.585 1.663c-.696 0-1.26.563-1.26 1.259 0 .695.564 1.259 1.26 1.259.695 0 1.258-.564 1.258-1.26 0-.695-.563-1.258-1.258-1.258zm0 1.656c-.219 0-.398-.179-.398-.397 0-.219.179-.398.398-.398.218 0 .397.18.397.398 0 .218-.179.397-.397.397zM4.591 4.565c-1.702 0-3.127 1.272-3.319 2.955l.855.099c.139-1.246 1.199-2.186 2.458-2.186 1.258 0 2.312.94 2.458 2.186l.867-.1c-.198-1.682-1.616-2.954-3.319-2.954zM15.384 5.4c-2.531 0-4.585 2.053-4.585 4.584 0 2.531 2.054 4.585 4.585 4.585 2.53 0 4.585-2.054 4.585-4.585 0-2.53-2.054-4.584-4.585-4.584zm0 8.308c-2.054 0-3.724-1.67-3.724-3.724 0-2.054 1.67-3.723 3.724-3.723 2.054 0 3.723 1.67 3.723 3.723 0 2.054-1.67 3.724-3.723 3.724z' transform='translate(-442 -132) translate(442 132)' />
          <path fill={color} d='M15.384 7.063c-.696 0-1.259.563-1.259 1.258 0 .696.563 1.26 1.259 1.26s1.259-.564 1.259-1.26c0-.695-.563-1.258-1.26-1.258zm0 1.656c-.219 0-.398-.18-.398-.398 0-.218.18-.397.398-.397.218 0 .397.179.397.397 0 .219-.179.398-.397.398zM15.39 9.964c-1.702 0-3.127 1.272-3.319 2.955l.855.1c.139-1.246 1.2-2.187 2.458-2.187 1.259 0 2.312.941 2.458 2.187l.868-.1c-.199-1.683-1.617-2.955-3.32-2.955zM4.585 10.8C2.054 10.8 0 12.852 0 15.383c0 2.53 2.054 4.585 4.585 4.585 2.53 0 4.584-2.054 4.584-4.585s-2.053-4.585-4.584-4.585zm0 8.307c-2.054 0-3.724-1.67-3.724-3.723 0-2.054 1.67-3.724 3.724-3.724 2.054 0 3.723 1.67 3.723 3.724 0 2.054-1.67 3.723-3.723 3.723z' transform='translate(-442 -132) translate(442 132)' />
          <path fill={color} d='M4.585 12.462c-.696 0-1.26.563-1.26 1.259s.564 1.259 1.26 1.259c.695 0 1.258-.563 1.258-1.26 0-.695-.563-1.258-1.258-1.258zm0 1.656c-.219 0-.398-.178-.398-.397 0-.219.179-.398.398-.398.218 0 .397.18.397.398 0 .219-.179.397-.397.397zM4.591 15.364c-1.702 0-3.127 1.272-3.319 2.955l.855.1c.139-1.246 1.199-2.187 2.458-2.187 1.258 0 2.312.94 2.458 2.186l.867-.1c-.198-1.682-1.616-2.954-3.319-2.954z' transform='translate(-442 -132) translate(442 132)' />
          <path fill={color} d='M9.552 5.405L10.413 5.405 10.413 9.168 9.552 9.168z' transform='translate(-442 -132) translate(442 132) rotate(-63.345 9.982 7.287)' />
          <path fill={color} d='M8.106 12.251L11.869 12.251 11.869 13.112 8.106 13.112z' transform='translate(-442 -132) translate(442 132) rotate(-26.655 9.988 12.682)' />
        </g>
      </g>
    </g>
  </svg>
)

PartnerIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

PartnerIcon.defaultProps = {
  width: 20,
  height: 20
}

export default PartnerIcon
