import gql from 'graphql-tag'

export default gql(`
    mutation deleteApp($project: String $id: ID!
        ) {
        deleteApp(
            id: $id
            project: $project
        ) {
                id
               }
               
    }`
)
