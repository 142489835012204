import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from './../../dataDisplay/ToolTip'
import PopoverConfirm from './../../feedback/PopoverConfirm'
import EditIcon from '../../general/icons/EditIcon'
import ButtonContainer from '../../general/buttons/ButtonContainer'
import CircularProgressBar from '../../../CircularProgressBar'
import './../../ui.style.scss'
import { generateCroppedThumbnail, getFileSize } from '../../../../util/util'
import DropDown from '../inputs/DropDown'

class ImageContent extends React.Component {
    state = { showEditIcon: false, showEditModal: false }

    makeDefaultImage = () => {
      const { isDefaultImage, makeDefaultImage, mediaId } = this.props
      if (!isDefaultImage) {
        makeDefaultImage(mediaId)
      }
    }

    makeDisplayImage = () => {
      const { isDisplayImage, imageDetails } = this.props
      if (!isDisplayImage) {
        this.props.makeDisplayImage(imageDetails.id)
      }
    }

    showImgEditor = () => this.setState({ showEditIcon: true });

    hideImgEditor = () => this.setState({ showEditIcon: false });

    onImageCaptionChange = (e) => {
      const { value } = e.target
      if (!value.startsWith(' ')) { this.props.onImageCaptionChange(this.props.id, value) }
    }

    render () {
      const { imageUrl, imageDetails, name, isAssetList, isDefaultImage, onCloseContent, caption, parentCompoent, isUploading,
        aspectRatio, showEditImage, mediaId, onInputFieldFocus, onInputFieldBlur, isDisplayImage, isBucketEdit, isUpdateImageDisabled, project, mediaCategoryList,
        handleAssetImageCategoryChange } = this.props
      const image = isUploading ? null : generateCroppedThumbnail(imageDetails, 214, 120, '16:9')
      // const imageDetailsArray = imageDetails.fileName.split('.')
      // const format = imageDetailsArray[imageDetailsArray.length - 1]
      const aspectRatioComponent = imageDetails.aspectRatio.map((ratio, index) => {
        const childComponent = (<React.Fragment>{ ratio.title }{ (index + 1 !== aspectRatio.length) ? <span className='black-comma'>,</span> : null }</React.Fragment>)
        return (<Tooltip placement='top' key={index} heading={ratio.title} childComponent={childComponent} details={ratio.resolutions} parentCompoent={parentCompoent} />)
      })
      // const imageSizeKb = imageDetails.fileSize ? (imageDetails.fileSize / (1024)).toFixed(2) : null
      // const imageSize = imageSizeKb ? (imageSizeKb > 1024 ? `${(imageSizeKb / 1024).toFixed(2)} Mb` : `${imageSizeKb} Kb`) : null
      const imageSize = getFileSize(imageDetails.fileSize)
      const category = imageDetails && imageDetails.category ? imageDetails.category.id : null
      let imageCategories = (mediaCategoryList || []).filter(item => item.type === 'IMAGE' && item.isActive)
      if (category && imageCategories && imageCategories.length) {
        const selected = mediaCategoryList.find(item => item.id === category)
        if (selected.isActive === false) { imageCategories.push(selected) }
      }
      return (
        <div className={project !== 'hyperion' || 'projectx' ? 'image-content' : 'image-content-hyperion'} >
          <div className='img-container' onMouseEnter={isUploading ? () => {} : this.showImgEditor}
            onMouseLeave={isUploading ? () => {} : this.hideImgEditor}>
            {isUploading ? <div className='loading-item'>
              <CircularProgressBar
                strokeWidth='5'
                sqSize='54'
                percentage={imageDetails.percentage}
              />
            </div> : null}
            {isUploading ? null : <img src={image} />}
            { (this.state.showEditIcon && !isUpdateImageDisabled) ? <div onClick={() => showEditImage(imageUrl, mediaId, imageDetails)} className='img-editor-icon'> <EditIcon /> </div> : null }
          </div>
          <div className='image-content-wrapper'>
            <div className='image-content-header'>
              {name.length > 15 ? <ButtonContainer displayTitle={name} childComponent={<p>{ name }</p>} /> : <p>{ name }</p> }
              <div className='image-content-btns'>
                { (isAssetList && !isUploading && (project === 'hyperion' || project === 'projectx'))
                  ? <div className='default-image-status-container'>
                    <span className={`default-image-status ${isDefaultImage ? 'default' : ''}`} onClick={this.makeDefaultImage}>{ isDefaultImage ? 'Partner Logo' : 'Select as Partner Logo' } </span>
                    <div className='seperator' />
                  </div> : null }
                {isUploading ? null : <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />}
              </div>
            </div>
            <div className='image-content-details'>
              {/* <div className='image-info'>
                <p>Output Format:</p>
                <p> { findFileExtension(name) || 'JPG'}</p>
              </div> */}
              { (imageCategories && imageCategories.length) ? <div className={`image-info-category`}>
                <p>Category:</p>
                <div className='image-category' id={imageDetails.id}>
                  <DropDown
                    options={imageCategories}
                    onOptionSelect={(value) => handleAssetImageCategoryChange(value, imageDetails)}
                    selectedValue={category || 'Select Category'}
                    valueParam='id'
                    displayParam='label'
                    placeHolder={`Select  Category`}
                    parent={imageDetails.id}
                    disabled={isUploading}
                    className={''} />
                </div>
              </div> : null }
              <div className={`image-info ${imageSize ? '' : 'hidden'}`}>
                <p>Original File Size:</p>
                <p>{imageSize}</p>
              </div>
            </div>
            {project !== 'hyperion' || 'projectx' ? <div className='image-content-footer'>
              <div className={`image-resolution-detail ${(isAssetList || isBucketEdit) && (isDefaultImage || isDisplayImage) ? 'default' : ''} ${!isAssetList && !isBucketEdit ? 'full' : ''}`} >
                <p>Aspect Ratio:&nbsp;</p>
                <p>{ aspectRatioComponent }</p>
              </div>
              { (isAssetList && !isUploading) ? <p className={`default-image-status ${isDefaultImage ? 'default' : ''}`} onClick={this.makeDefaultImage}>{ isDefaultImage ? 'Default Image' : 'Make Default Image' } </p> : null }
              { isBucketEdit ? <p className={`default-image-status ${isDisplayImage ? 'default' : ''}`} onClick={this.makeDisplayImage}>{ isDisplayImage ? 'Display Image' : 'Make Display Image' } </p> : null }
            </div> : null}
          </div>

          { !isAssetList && !isDefaultImage && !isDisplayImage && !isBucketEdit ? <input name='caption' value={caption || ''} onChange={this.onImageCaptionChange} onFocus={onInputFieldFocus} onBlur={onInputFieldBlur} placeholder='Caption' /> : null }
        </div >
      )
    }
}

ImageContent.propTypes = {
  /** image identifier of ImageContent. */
  mediaId: PropTypes.string,
  /** image identifier of ImageContent. */
  id: PropTypes.string,
  /** image URL parameter of ImageContent. */
  imageUrl: PropTypes.string,
  /** file name of ImageContent. */
  name: PropTypes.string,
  /** caption of ImageContent. */
  caption: PropTypes.string,
  /** parent component of ImageContent. */
  parentCompoent: PropTypes.string,
  /** caption of ImageContent. */
  imageDetails: PropTypes.object,
  /** Is asset list child status of ImageContent. */
  isAssetList: PropTypes.bool,
  /** Is default image of asset list status of ImageContent. */
  isDefaultImage: PropTypes.bool,
  /** Is asset list child status of ImageContent. */
  makeDefaultImage: PropTypes.func,
  /** close action of ImageContent. */
  onCloseContent: PropTypes.func,
  /** edit image action callback of ImageContent. */
  showEditImage: PropTypes.func,
  /** image caption change of ImageContent. */
  onImageCaptionChange: PropTypes.func,
  /** Is bucket asset image change boolean of ImageContent. */
  isBucketEdit: PropTypes.bool,
  /** Is bucket asset image change status of ImageContent. */
  isDisplayImage: PropTypes.bool
}

ImageContent.defaultProps = {
  imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Football_iu_1996.jpg',
  parentCompoent: 'root'
}

export default ImageContent
