import React from 'react'
import PropTypes from 'prop-types'

const StagesFilterIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick} >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <g stroke={color}>
        <rect width='8' height='7' x='.5' y='.5' rx='1' />
        <rect width='8' height='7' x='11.5' y='6.5' rx='1' />
        <path strokeLinecap='square' d='M4.5 8v4.339M4.5 10h7' />
        <rect width='8' height='7' x='.5' y='12.5' rx='1' />
      </g>
    </g>
  </svg>
)

StagesFilterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

StagesFilterIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default StagesFilterIcon
