import gql from 'graphql-tag'

export default gql(`
query getAppPage($id: ID!, $project: String!) {
    getAppPage(id: $id, project: $project) {
        id
        key
        title
        shortTitle
        shortDescription
        description
        isCritical
        assignedUsers
        app {
          id
          name
          url
          isCritical
        }
        group {
          key
          name
          isCritical 
          assignedUsers
        }
        tags {
          key
          name
          type
          module
          assetCount
          isTransferring
          isError
        }
        project
        createdAt
        updatedAt
        customMeta {
          config {
            id
            name
            type
            section
            default
            project
            usageCount
          }
          value
          name
          
        }
        updatedBy
        createdBy
        isPublished
        isArchived
        publishStartDate
        publishEndDate
        slug
        seoKeywords
        seoMetaDescription
        seoTitle
        layoutItems {
          id
          position
          type
          title
          widget {
            id
            key
            title
            description  
            fieldValues {
              configField {
                id
                name
                type
                isRequired
                project
                useAsTitle
                createdAt
                createdBy
                position
                isUpdatable
                
              }
              value
              useAsTitle
              bucket {
                project
                id
                isArchived
                name
                isPublished
                publishStartDate
                publishEndDate
                updatedAt
                updatedBy
                key
                displayName
                displayType {
                  name
                  id
                }
                bucketItems {
                  id
                  position
                  type
                  asset{
                    id
                    title
                    type
                    shortId
                    externalId
                    updatedAt
                    status
                    seasonNumber
                    episodeNumber
                    series{
                      id
                      assetId
                      title
                    }
                    season{
                        id
                        assetId
                        title
                    }
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                     }
                    }
                    filterRule{
                      searchKey
                      filters{  
                          name
                          fieldName
                          type
                          value
                          displayValue
                          displayName
                          isRequired
                      }
                  }
                  }
                }
              image {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags {
                  key
                  name
                  type
                }
                category {
                  id
                  label
                  type
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
      
                }
                dimensions {
                  x1
                  x2
                  y1
                  y2
                  
                }
                aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                    key
                    width
                    height
                    url
                    
                  }
                  
                }
                
              }
              project
              position
            }
            widgetPreview {
              id
              fileName
              createdAt
              name
              type
              fileSize
              duration
              thumbnails
              tags{
                  key
                  name
                  type
              }
              category{
                  id
                  label
                  type
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
              }
              dimensions{
                  x1
                  x2
                  y1
                  y2
              }
              aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                  key
                  width
                  height
                  url
                  }
              }
          }
          }
          fieldValues {
            configField {
              id
              name
              type
              isRequired
              project
              useAsTitle
              createdAt
              createdBy
              position
              isUpdatable
              
            }
            value
            useAsTitle
            bucket {
              project
              id
              isArchived
              name
              isPublished
              publishStartDate
              publishEndDate
              updatedAt
              updatedBy
              key
              displayName
              displayType {
                name
                id
              }
              bucketItems {
                id
                position
                type
                asset{
                  id
                  title
                  type
                  shortId
                  externalId
                  updatedAt
                  status
                  seasonNumber
                  episodeNumber
                  series{
                    id
                    assetId
                    title
                  }
                  season{
                      id
                      assetId
                      title
                  }
                  defaultMedia {
                      id
                      url
                      fileName
                      aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                          url
                        }
                      }
                      settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                      }
                  }
              }
              filterRule{
                searchKey
                filters{
                    name
                    fieldName
                    type
                    value
                    displayValue
                    displayName
                    isRequired
                 }
               }
              }
            }
            image {
              id
              fileName
              createdAt
              name
              type
              fileSize
              duration
              thumbnails
              tags {
                key
                name
                type
              }
              category {
                id
                label
                type
              }
              settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
    
              }
              dimensions {
                x1
                x2
                y1
                y2
                
              }
              aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                  key
                  width
                  height
                  url
                  
                }
                
              }
              
            }
            project
            position
            
          }
          project
          isEnabled
          isPinned
          
        }
        scheduledVersions {
          id
          isPublished
          publishStartDate
          publishEndDate
          layoutItems {
            id
            position
            type
            title
            widget {
              id
              key
              title
              description  
              widgetPreview {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
            }
            }
            fieldValues {
              configField {
                id
                name
                type
                isRequired
                project
                useAsTitle
                createdAt
                createdBy
                position
                
              }
              value
              bucket {
                project
                id
                isArchived
                name
                isPublished
                publishStartDate
                publishEndDate
                updatedAt
                updatedBy
                key
                displayName
                displayType {
                  name
                  id
                }
              }
              image {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags {
                  key
                  name
                  type
                }
                category {
                  id
                  label
                  type
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
      
                }
                dimensions {
                  x1
                  x2
                  y1
                  y2
                  
                }
                aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                    key
                    width
                    height
                    url
                    
                  }
                  
                }
                
              }
              project
              position
              
            }
            project
            isEnabled
            
          }
        }
        
      }
}`)
