import React from 'react'
// import PropTypes from 'prop-types'
import Switch from '../dataEntry/inputs/Switch'
import PremierLeagueIcon from '../general/icons/PremierLeagueIcon'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import DropDown from '../dataEntry/inputs/DropDown'
import './../ui.style.scss'

const options = [{
  name: 'Option 1'
}, {
  name: 'Option 2'
}]

class AddTeam extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  render () {
    const { project } = this.props
    return (
      <div className='add-team'>
        <div className='top'>
          <div className='team-img'>
            <PremierLeagueIcon />
          </div>
          <div className='top-data'>
            <div className='new-data'>
              <Input title='Official Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='new-data'>
              <Input title='Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='new-data'>
              <Input title='Short Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='new-data'>
              <Input title='Code' placeholder='CODE' inputName='title' value={''} handleChange={() => { }} />
            </div>
          </div>
        </div>
        <div className='team-data'>
          <div className='team-image-vertical'>
            <div className='team'>
              <label> Home </label>
              <div className='image'>
                <img src={require('../../../assets/images/avatar-team-icon.png')} />
              </div>
            </div>
            <div className='team'>
              <label> Away </label>
              <div className='image'>
                <img src={require('../../../assets/images/avatar-team-icon.png')} />
              </div>
            </div>
          </div>
          <div className='data'>
            <div className='custom-data'>
              <div className='details'>
                <Input title='ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='Opta ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='OPTA Legacy ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='Founded' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='Venue Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='Venue ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details' id='details'>
                <label>Country</label>
                <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
              </div>
              <div className='details'>
                <Input title='Country External ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='City' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='Postal Address' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details'>
                <Input title='Address Zip' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
              </div>
              <div className='details' id='details'>
                <label>Source</label>
                <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
              </div>
            </div>
            <div className='custom-tags' id='custom-tags'>
              <label> Tags </label>
              <TagsInputContainer
                selectedItems={[]}
                onChange={() => {}}
                placeholder='Insert Tags'
                parentCompoent={'custom-tags'}
                displayParameter='name'
                onSearch={() => {}}
                project={project}
              />
            </div>
            <div className='type'>
              <span>Active</span>
              <div className='information-icon'>
                <InformationIcon />
              </div>
              <Switch isChecked={false} onChange={() => { }} />
            </div>

          </div>
        </div>
      </div>
    )
  }
}

AddTeam.propTypes = {

}

AddTeam.defaultProps = {
}

export default AddTeam
