import gql from 'graphql-tag'

export default gql(`
    mutation updateBucketGroupType($id: ID! $name: String! $position: Int! $project: String ) {
        updateBucketGroupType(
            input: {
                id: $id
                name: $name
                position: $position
            }
            project: $project
        ) {
            id
            name
            position
            usedCount
        }
    }`
)
