import React from 'react'
import PropTypes from 'prop-types'

const UpdatedIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path fill='#FF015A' d='M0 0h25L0 25z' />
      <path fill='#FFF' fillRule='nonzero' d='M3.258 9.7l2.501 2.312H3.258V9.7zm4.377-4.543l2.502 2.285-3.766 4.024-2.488-2.31 3.752-3.999zM8.94 3.89c.39-.357 1.141-.25 1.676.238l.484.443c.535.488.652 1.174.26 1.53l-.772.706-2.42-2.211.772-.706z' />
    </g>
  </svg>
)

UpdatedIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

UpdatedIcon.defaultProps = {
  width: 25,
  height: 25

}

export default UpdatedIcon
