import React from 'react'
import PropTypes from 'prop-types'
import { generateCroppedThumbnail } from '../../../util/util'
import './../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryGetAssetDetails from '../../../graphQL/asset/getAssetPublishDetails'
import SubscriptionCreatePublishRule from '../../../graphQL/asset/createPublishRuleSubscription'

const getAssetIcon = (assetType, status, listAssetTypes) => {
  const selectedType = (listAssetTypes || []).find(item => item.title === assetType)
  return (selectedType && selectedType.icon) ? <img src={generateCroppedThumbnail((status === 'PUBLISHED' || status === 'PARTIAL') ? selectedType.icon : selectedType.draftIcon, 18, 18, '1:1')} /> : null
}

const AssetHeading = ({ title, status, type, listAssetTypes, assetId, ...props }) => {
  const storedStatusObj = JSON.parse(sessionStorage.getItem('publishDetails'))
  const publishStatus = storedStatusObj && storedStatusObj.id === assetId ? storedStatusObj.status : status
  return (
    <div className='asset-heading'>
      {/* <img src={ assettypeIcon } /> */}
      {getAssetIcon(type, publishStatus, listAssetTypes) }
      <p> { title }</p>
      <p className={`status ${(publishStatus === 'PUBLISHED' || publishStatus === 'PARTIAL') ? 'published' : null}`}> { publishStatus }</p>
    </div>
  )
}

AssetHeading.propTypes = {
  /** Title of AssetHeading. */
  title: PropTypes.string.isRequired,
  /** Component type icon of AssetHeading. */
  assetIcon: PropTypes.string,
  /** status of AssetHeading. */
  status: PropTypes.string
}

AssetHeading.defaultProps = {
  title: 'Article',
  status: 'Published'
}

export default withApollo(compose(
  graphql(
    QueryGetAssetDetails,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project }
        }
      },
      props: (props) => {
        return {
          publishDetails: props.data.getAsset,
          assetIsLoading: props.data.loading,
          subscribeToPublishRule: () => {
            return props.data.subscribeToMore({
              document: SubscriptionCreatePublishRule,
              // variables: {assetId: props.ownProps.assetId},
              updateQuery: (prev, { subscriptionData: { data: { publishRuleUpdated } } }) => {
                const isFromSameAsset = publishRuleUpdated && (publishRuleUpdated.assetId === prev.getAsset.id)
                if (!publishRuleUpdated || !isFromSameAsset) return prev
                const index = (prev.getAsset.publishRules || []).findIndex(rule => rule.id === publishRuleUpdated.id)
                if (index && index !== -1) { prev.getAsset.publishRules[index] = publishRuleUpdated } else { prev.getAsset.publishRules = [...prev.getAsset.publishRules, publishRuleUpdated] }
                return prev
              }
            })
          }
        }
      }
    }
  )
)(AssetHeading))
