import gql from 'graphql-tag'

export default gql(`
query listFavouriteTeams( $id: ID! ) {
    listFavouriteTeams( 
      id: $id
    ) {
        id
        teams{
            id
            optaId
            officialName
            shortName
            shortCode
            country
            city
            postalAddress
            addressZip
            founded
            status
            logoUrl
        }
        updatedAt
    }
}`)
