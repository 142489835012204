import * as React from 'react'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import Input from '../../../components/ui/dataEntry/inputs/Input'
import { utilityService } from '../../../services/UtilityService'
import AppContext from '../../../AppContext'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { Skeleton, message } from 'antd'

import QueryGetVodSettings from '../../../graphQL/admin/media/getVodSettings'
import MutationUpdateVodSettingsInput from '../../../graphQL/admin/media/updateVodSettings'

class EncoderConfig extends React.Component {
  constructor (props) {
    super(props)
    const {
      callbackUrl,
      cognitoClientId,
      cognitoPoolId,
      encodingTemplate,
      mediaVodReqUrl,
      password,
      requestUrl,
      username,
      vodRequestUrl
    } = props.vodSettingsDetails ? props.vodSettingsDetails : {}

    this.state = {
      isEdit: false,
      isCallbackUrlError: false,
      isBaseUrlError: false,
      isVodBaseUrlError: false,
      isVodStatusUrlError: false,
      isSaveLoading: false,
      username,
      password,
      baseUrl: requestUrl,
      vodRequestUrl,
      callbackUrl,
      mediaVodReqUrl,
      clientId: cognitoClientId,
      poolId: cognitoPoolId,
      region: props.vodSettingsDetails ? props.vodSettingsDetails.aws_region : '',
      encodingTemplate,
      isSuccessTest: false,
      isErrorTest: false,
      xApiKey: props.vodSettingsDetails ? props.vodSettingsDetails.x_api_key : ''
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(this.props.vodSettingsDetails, nextProps.vodSettingsDetails)) {
      const {
        callbackUrl,
        cognitoClientId,
        cognitoPoolId,
        encodingTemplate,
        mediaVodReqUrl,
        password,
        requestUrl,
        username,
        vodRequestUrl
      } = nextProps.vodSettingsDetails
      this.setState({
        username,
        password,
        baseUrl: requestUrl,
        vodRequestUrl,
        mediaVodReqUrl,
        callbackUrl,
        clientId: cognitoClientId,
        poolId: cognitoPoolId,
        region: nextProps.vodSettingsDetails.aws_region,
        encodingTemplate,
        xApiKey: nextProps.vodSettingsDetails.x_api_key
      })
    }
  }

    handleUsernameChange = (e) => {
      const { value } = e.target
      this.setState({ username: value, isEdit: true })
    }

    handlePasswordChange = (e) => {
      const { value } = e.target
      this.setState({ password: value, isEdit: true })
    }

    handleBaseUrlChange = (e) => {
      const { value } = e.target
      this.setState({ baseUrl: value, isEdit: true, isBaseUrlError: false })
    }

    handleVodBaseUrlChange = (e) => {
      const { value } = e.target
      this.setState({ vodRequestUrl: value, isEdit: true, isVodBaseUrlError: false })
    }

    handleVodStatusUrlChange = (e) => {
      const { value } = e.target
      this.setState({ mediaVodReqUrl: value, isEdit: true, isVodStatusUrlError: false })
    }

    handleCallbackUrlChange = (e) => {
      const { value } = e.target
      this.setState({ callbackUrl: value, isEdit: true, isCallbackUrlError: false })
    }

    handlePoolIdChange = (e) => {
      const { value } = e.target
      this.setState({ poolId: value, isEdit: true })
    }

    handleClientIdChange = (e) => {
      const { value } = e.target
      this.setState({ clientId: value, isEdit: true })
    }

    handleRegionChange = (e) => {
      const { value } = e.target
      this.setState({ region: value, isEdit: true })
    }

    handleTemplateChange = (e) => {
      const { value } = e.target
      this.setState({ encodingTemplate: value, isEdit: true })
    }

    handleXAPIKeyChange = (e) => {
      const { value } = e.target
      this.setState({ xApiKey: value, isEdit: true })
    }

    checkForValidation = () => {
      const { baseUrl, callbackUrl, vodRequestUrl, mediaVodReqUrl } = this.state
      const isBaseUrlError = utilityService.checkUrlValidation(baseUrl)
      const isCallbackUrlError = utilityService.checkUrlValidation(callbackUrl)
      const isVodBaseUrlError = utilityService.checkUrlValidation(vodRequestUrl)
      const isVodStatusUrlError = utilityService.checkUrlValidation(mediaVodReqUrl)
      this.setState({ isBaseUrlError: !isBaseUrlError,
        isCallbackUrlError: !isCallbackUrlError,
        isVodBaseUrlError: !isVodBaseUrlError,
        isVodStatusUrlError: !isVodStatusUrlError })
      return isBaseUrlError && isCallbackUrlError && isVodStatusUrlError && isVodBaseUrlError
    }

    testConnection = () => {
      const isDetailsClear = this.checkForValidation()
      if (isDetailsClear) {

      } else {

      }
    }

    saveEncoderConfig = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isSaveLoading: true })
      const isDetailsClear = this.checkForValidation()
      if (isDetailsClear) {
        const {
          username,
          password,
          baseUrl,
          vodRequestUrl,
          mediaVodReqUrl,
          callbackUrl,
          clientId,
          poolId,
          region,
          encodingTemplate,
          xApiKey
        } = this.state
        const variables = {
          aws_region: region,
          callbackUrl,
          cognitoClientId: clientId,
          cognitoPoolId: poolId,
          encodingTemplate,
          mediaVodReqUrl,
          password,
          requestUrl: baseUrl,
          username,
          vodRequestUrl,
          xApiKey
        }
        this.props.updateVodSettingsInput(variables).then(() => {
          onHistoryUpdate()
          message.success('VWM Encoder Config saved successfully')
          this.setState({ isSaveLoading: false, isEdit: false })
        }, error => {
          this.setState({ isSaveLoading: false })
          utilityService.handleError(error)
        })
      } else {
        this.setState({ isSaveLoading: false })
      }
    }

    render () {
      const { username, password, baseUrl, callbackUrl, poolId, clientId, isEdit, isCallbackUrlError, isBaseUrlError,
        vodRequestUrl, isVodBaseUrlError, region, mediaVodReqUrl, isVodStatusUrlError, encodingTemplate, isSaveLoading, xApiKey } = this.state
      const { isLoading } = this.props
      const isAllDetailsEnter = username && password && baseUrl && callbackUrl && poolId && clientId && vodRequestUrl && region && mediaVodReqUrl && encodingTemplate
      return <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['DATA_MANAGER']
          //   const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <div className='encoder-config-container admin-portal'>
            <div className='encoder-config-body'>
              <div className='encoder-config'>
                <div className='header'>Cognito Credentials</div>
                <div className='encoder-form'>
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 5 }} loading={isLoading}>
                    <div className='encoder-primary'>
                      <Input title='Username' placeholder='Enter Cognito Username' inputName='username' value={username} handleChange={this.handleUsernameChange}
                        errorMessage='Please enter valid username' />
                      <Input title='Password' placeholder='Enter Cognito Password' inputName='password' value={password} handleChange={this.handlePasswordChange} type={'password'} />
                    </div>
                    <div className='encoder-secondary'>
                      <Input title='Live2VOD Base URL' placeholder='Enter Live2VOD Base URL' inputName='baseUrl' value={baseUrl} handleChange={this.handleBaseUrlChange}
                        isError={isBaseUrlError} errorMessage='Please enter a valid URL' />
                      <Input title='VOD Base URL' placeholder='Enter VOD Base URL' inputName='vodRequestUrl' value={vodRequestUrl} handleChange={this.handleVodBaseUrlChange}
                        isError={isVodBaseUrlError} errorMessage='Please enter a valid URL' />
                      <Input title='VOD Status URL' placeholder='Enter VOD Status URL' inputName='mediaVodReqUrl' value={mediaVodReqUrl} handleChange={this.handleVodStatusUrlChange}
                        isError={isVodStatusUrlError} errorMessage='Please enter a valid URL' />
                      <Input title='Callback URL' placeholder='Enter VCMS Callback URL' inputName='callbackUrl' value={callbackUrl} handleChange={this.handleCallbackUrlChange}
                        isError={isCallbackUrlError} errorMessage='Please enter a valid URL' />
                      <Input title='Cognito Pool ID' placeholder='Enter Cognito Pool ID' inputName='poolId' value={poolId} handleChange={this.handlePoolIdChange} />
                      <Input title='Cognito Client ID' placeholder='Enter Cognito Client ID' inputName='clientId' value={clientId} handleChange={this.handleClientIdChange} />
                      <Input title='Region' placeholder='Enter AWS Region' inputName='region' value={region} handleChange={this.handleRegionChange} />
                      <Input title='Encoding Template' placeholder='Enter Encoding Template' inputName='encodingTemplate' value={encodingTemplate} handleChange={this.handleTemplateChange} />
                      <Input title='X-API-Key' placeholder='Enter API-Key' inputName='encodingTemplate' value={xApiKey} handleChange={this.handleXAPIKeyChange} />
                      {/* <Input title='Threshold' placeholder='Enter Threshold' inputName='Threshold' value={threshold ? threshold.toString() : ''} handleChange={this.handleThresholdChange} /> */}
                      {/* <div className='button-container'>
                        <LoadingButton
                          type='primary'
                          onClick={this.testConnection}
                          htmlType='submit'
                          buttonText={'Test Connection'}
                          buttonClass='save-btn'
                          isLoading={false}
                          isDisabled={!isAllDetailsEnter}
                        />
                        {(isSuccessTest || isErrorTest) ? <div className={`${isSuccessTest ? 'success' : 'error'}`}>{isSuccessTest ? 'Test Successful. You have successfully connected.' : 'Test Failed. Connection is not successful.'}</div> : ''}
                      </div> */}
                    </div>
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className='admin-footer' >
              <LoadingButton
                type='primary'
                onClick={this.saveEncoderConfig}
                htmlType='submit'
                buttonText={'Save'}
                buttonClass='save-btn'
                isLoading={isSaveLoading}
                isDisabled={!(isAllDetailsEnter && isEdit) || isUpdateDisabled}
              />
            </div>
          </div>
        }}
      </AppContext.Consumer>
    }
}

export default withApollo(compose(
  graphql(
    QueryGetVodSettings,
    {
      options: () => {
        return {
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        return {
          vodSettingsDetails: data.getVodSettings,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationUpdateVodSettingsInput,
    {
      options: () => {
        return {
          update: (cache, { data: { updateVodSettings } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetVodSettings }))
            if (cacheData && cacheData.getVodSettings) {
              cacheData.getVodSettings = updateVodSettings
            }
            cache.writeQuery({
              query: QueryGetVodSettings,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        updateVodSettingsInput: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
  // graphql(
  //   QueryS3FolderList,
  //   {
  //     options: () => {
  //       let variables = { delimiter: '/' }
  //       // if (prefix) { variables.prefix = prefix } else { variables.delimiter = '/' }
  //       return {
  //         fetchPolicy: 'network-only',
  //         variables
  //       }
  //     },
  //     props: ({ data }) => {
  //       return {
  //         folderList: data.listS3Objects ? data.listS3Objects.objects : [],
  //         folderListLoading: data.loading,
  //         getFolderList: () => {
  //           return data.refetch()
  //         }
  //       }
  //     }

  //   }
  // )
)(EncoderConfig))
