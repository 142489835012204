import gql from 'graphql-tag'

export default gql(`
    mutation duplicateAppConfig($input: editAppConfigInput $project: String) {
      duplicateAppConfig(
            input: $input
            project: $project
        ) {
            id
            isArchived
            isPublished
            createdBy
            createdAt
            key
            project
            publishEndDate
            title
            type
            isCritical
            assignedUsers
            group{
              key
              name
              type
            }
            fields{
                id
                type
                isRequired
                useAsTitle
                name
                position
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
        }
    }`
)
