import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'
import NProgress from 'nprogress'
import moment from 'moment'

import MatchList from './MatchList'
import AppContext from '../../AppContext'
import MatchDetails from './MatchDetails'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListAssetType from '../../graphQL/admin/asset/listAssetTypes'
import QueryMatchStatusConfig from '../../graphQL/admin/matchManager/getMatchStatusConfigList'
import QueryCustomMetaFields from '../../graphQL/admin/customMetaFields/listCustomMetaFields'

import './MatchManager.scss'

class MatchManager extends Component {
  constructor (props) {
    super(props)
    const lastDate = moment(new Date()).add(1, 'month').format('ddd, DD MMM, YYYY')
    const startDay = moment(new Date()).format('DD MMM, YYYY')
    let dateString = [ startDay, lastDate ]
    let arrayVal = ''
    // dateString.map((item) => {
    //   arrayVal = !arrayVal.length ? (arrayVal + (new Date(item + ' 00:00 UTC')).toISOString()) : (arrayVal + ',' + (new Date(item + ' 00:00 UTC')).toISOString())
    // })
    dateString.filter((item) => item).map((time, index) => {
      const date = moment(time)
      const startTime = _.cloneDeep(date).startOf('day')
      const endTime = _.cloneDeep(date).endOf('day')
      const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
      arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
    })
    const filterDate = arrayVal && arrayVal.length ? arrayVal : null
    this.state = {
      selectedMatchId: '',
      searchString: '',
      showSidebar: false,
      matchFilter: {
        selectedCompetitions: [],
        filterDate: filterDate,
        stages: [],
        seasons: [],
        sport: []
      },
      matchStatusList: props.listMatchStatusConfig && props.listMatchStatusConfig.length ? props.listMatchStatusConfig : []
    }
  }
    componentDidMount = () => {
      document.title = 'Match Manager - Optus CMS'
    }

    UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
      if (this.props.match && this.props.match.params && this.props.match.params.id) {
        NProgress.start()
        this.setState({ selectedMatchId: this.props.match.params.id })
      }
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.match && newProps.match.params && newProps.match.params.id !== this.state.selectedMatchId) {
        NProgress.start()
        this.setState({ selectedMatchId: newProps.match.params.id })
      }
      if (newProps && newProps.listMatchStatusConfig && !_.isEqual(this.props.listMatchStatusConfig, newProps.listMatchStatusConfig)) {
        this.setState({
          matchStatusList: newProps.listMatchStatusConfig
        })
      }
    }

    changeFilterValue = () => {

    }

    changedMatch = (matchId) => {
      if (this.state.selectedMatchId !== matchId) {
        this.setState({ selectedMatchId: matchId })
        if (matchId) {
          NProgress.start()
          this.props.history.push(`/matches/${matchId}`)
        } else {
          this.props.history.push('/matches')
        }
        this.setState({ showSidebar: true })
      } else if (this.state.selectedMatchId === matchId) {
        this.setState({ showSidebar: true })
      }
    }

    onChangeFilter = (value) => {
      this.setState({ searchString: value })
    }

    onChangeMatchFilter = (matchFilter) => {
      this.setState({ matchFilter })
    }

    onMatchSelect = () => {
      this.setState({ showSidebar: false })
    }

    toggleSidebar = () => {
      this.setState({ showSidebar: !this.state.showSidebar })
    }

    render () {
      const { listAssetTypes, metaFieldList } = this.props
      const { filter, selectedMatchId, matchFilter, searchString, showSidebar, matchStatusList } = this.state
      return (
        <Row className='match-manager'>
          <Col xl={{ span: 18 + (showSidebar ? 0 : 6) }} xs={{ span: 18 + (showSidebar ? 0 : 6) }} className={`match-list-container ${showSidebar ? 'sidebar' : ''}`} >
            <MatchList
              filterVal={filter}
              changedMatch={this.changedMatch}
              selectedMatchId={selectedMatchId}
              onChangeCompetition={this.onChangeCompetition}
              onChangeMatchFilter={this.onChangeMatchFilter}
              matchFilter={matchFilter}
              onChangeFilter={this.onChangeFilter}
              searchString={searchString}
              toggleSidebar={this.toggleSidebar}
              onMatchSelect={this.onMatchSelect}
              isSidebarVisible={showSidebar}
              listAssetTypes={listAssetTypes}
            />
          </Col>
          <Col xs={{ span: (showSidebar ? 6 : 0) }} className='match-details-container'>
            <AppContext.Consumer>
              {({ project, permissions }) => {
                return <MatchDetails permissions={permissions} selectedMatchId={selectedMatchId} listAssetTypes={listAssetTypes} project={project} matchStatusList={matchStatusList} metaFieldList={metaFieldList} />
              }}
            </AppContext.Consumer>
          </Col>
        </Row>
      )
    }
}

MatchManager.propTypes = {
  /* History parameter of dom */
  history: PropTypes.object,
  /* match parameter of dom */
  match: PropTypes.object

}

export default withApollo(compose(
  graphql(
    QueryListAssetType,
    {
      options: ({ project }) => {
        const filter = {
          isMatchRelated: true,
          isActive: true
        }
        const variables = { limit: 500, nextToken: null, filter, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes
        }
      }
    }
  ),
  graphql(
    QueryMatchStatusConfig,
    {
      options: (props) => {
        const { project } = props
        return {
          variables: { module: 'Match', project: project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        {
          const isListPresent = (props.data && props.data.listConfig && props.data.listConfig.length)
          const listValue = isListPresent ? props.data.listConfig.find(listItem => listItem.value) : null
          return {
            listMatchStatusConfig: listValue ? listValue.value : []
          }
        }
      }
    }
  ),
  graphql(
    QueryCustomMetaFields,
    {
      options: ({ project }) => {
        const variables = { section: 'MATCH_MANAGER', project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let metaFieldList = data.listMetaFieldConfigs ? data.listMetaFieldConfigs : []
        metaFieldList = metaFieldList.map(item => {
          return item
        })
        return {
          metaFieldList
        }
      }
    }
  )
)(withRouter(MatchManager)))
