import gql from 'graphql-tag'

export default gql(`
subscription{
    bucketGroupCreated{
            id
            name
            type
            isArchived
            key
            project
            bucketGroupItems{
              id
              position
              bucketId
              bucket{
                displayName
                name
                key
                id
                isArchived
              }
            }
        } 
    }
`)
