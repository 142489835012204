import React from 'react'
import PropTypes from 'prop-types'
import CircularProgressBar from './../../CircularProgressBar'
import MultilineEllipsis from './../../MultilineEllipse'
import ButtonContainer from '../general/buttons/ButtonContainer'
import './../ui.style.scss'

class ImageLoadingDisplay extends React.Component {
  render () {
    const { percentage, name } = this.props
    return <div className={`image-loading-display`} >
      <div className='loading-item'>
        <CircularProgressBar
          strokeWidth='5'
          sqSize='54'
          percentage={percentage}
        />
      </div>
      <div className='file-name'>
        {name.length > 35 ? <ButtonContainer displayTitle={name} childComponent={<MultilineEllipsis maxLines={'2'} text={name} />} />
          : <MultilineEllipsis maxLines={'2'} text={name} /> }
      </div>
    </div>
  }
}

ImageLoadingDisplay.propTypes = {
  /** file name of ImageLoadingDisplay. */
  name: PropTypes.string,
  /** percentage upload of file. */
  percentage: PropTypes.number
  // /** on select action of uploading file. */
  // onSelect: PropTypes.func
}

ImageLoadingDisplay.defaultProps = {

}

export default ImageLoadingDisplay
