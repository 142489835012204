import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChannelContent from '../../components/ui/dataEntry/other/ChannelContent'
import Button from '../../components/ui/general/buttons/Button'
import _ from 'lodash'
const parentCompoent = 'asset-container'

class AssetChannel extends Component {
  render () {
    const {
      showAddChannel,
      channel,
      onCloseChannel,
      editProgram,
      assetId,
      // vodStatus,
      onPlayClick,
      isLoadingPlayer,
      isLiveAsset,
      assetType,
      isPlaying,
      disableAutoTrigger,
      jobList,
      isUpdateDisabled,
      project
    } = this.props
    return (
      <div className='asset-channel-details'>
        {!_.isEmpty(channel) ? <ChannelContent
          icon={channel.channel ? channel.channel.icon : null}
          id={channel.id}
          assetType={assetType}
          disableAutoTrigger={disableAutoTrigger}
          name={channel.channel ? channel.channel.name : null}
          onCloseContent={() => onCloseChannel(channel.id)}
          parentCompoent={parentCompoent}
          startTime={channel.broadCastStartTime}
          endTime={channel.broadCastEndTime}
          duration={channel.duration}
          activeConfig={channel.channel ? channel.channel.activeConfigGroup : null}
          editProgram={editProgram}
          source={channel.source}
          isPlaying={isPlaying}
          channelId={channel.channel ? channel.channel.id : null}
          assetId={assetId}
          onPlayClick={onPlayClick}
          // vodId={vodStatus ? vodStatus.id : null}
          // vodStatus={vodStatus ? vodStatus.status : ''}
          isLiveAsset={isLiveAsset}
          isLoadingPlayer={isLoadingPlayer}
          // createdAt={vodStatus ? vodStatus.createdAt : ''}
          // updatedAt={vodStatus ? vodStatus.updatedAt : ''}
          // errorStatus={vodStatus ? vodStatus.errorDesc : ''}
          jobList={jobList}
          isUpdateDisabled={isUpdateDisabled}
          project={project}
        /> : ''}
        {!_.isEmpty(channel) ? null : <div style={{ width: '150px' }}>
          <Button
            buttonText={'Add Channel'}
            color='red'
            onClick={() => showAddChannel()}
            style={{ width: '150px' }}
            isDisabled={isUpdateDisabled}
          />
        </div>}
        <button id='channel-hidden-button' />
      </div>
    )
  }
}

AssetChannel.propTypes = {
  /** show channel action callback of AssetChannel. */
  showAddChannel: PropTypes.func,
  /** close channel action callback of AssetChannel. */
  onCloseChannel: PropTypes.func,
  /** Channels details */
  channel: PropTypes.object,
  /** Function to be called on edit channel date */
  editProgram: PropTypes.func,
  /** Asset ID */
  assetId: PropTypes.string,
  /** List of jobs in current asset */
  jobList: PropTypes.array,
  /** Boolean to disable Add program button */
  isUpdateDisabled: PropTypes.bool
}

export default AssetChannel
