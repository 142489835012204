import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $title: String $shortTitle: String $description: String  $shortDescription: String $author: ID $contents: [ID] $tags: [ID] $publishStartDate: AWSDateTime $publishEndDate: AWSDateTime $updatedAt: AWSDateTime $isArchived: Boolean $slug: String $seoTitle: String $seoKeywords: [String] $seoMetaDescription: String $updatedBy: String
        $match: ID $relatedAssets: ID $category: ID $isLive: Boolean $isFree: Boolean $duration: String $meta: [CreateFieldInput] $ratings: [RatingInput] $originalAudio: String $audioLanguages: [String] $originalTitle: String $subtitleLanguages: [String] $closedCaption: Boolean $releaseDateTime: AWSDateTime $productionDateTime: AWSDateTime $averageRating: Float
        $expectedNumberOfSeasons: Int $productionStudio: ID $genres: [ID] $countryOfOrigin: String $seasonNumber:Int $episodeNumber: Int $mediumDescription: String $videoDuration: String $project: String $productionStudios: [ID] $yearOfRelease: Int $episodeNumberInSeries: Int $colorCode: String $countries: [ID] $seoCanonicalUrl: String $autoPublish: Boolean)  {
        updateAsset(
            id: $id
            project: $project
            input: {
                id: $id
                title: $title
                shortTitle: $shortTitle
                description: $description
                shortDescription: $shortDescription
                author: $author
                contents: $contents
                tags: $tags
                updatedAt: $updatedAt
                updatedBy: $updatedBy
                publishEndDate: $publishEndDate
                publishStartDate: $publishStartDate
                isArchived: $isArchived
                seoTitle: $seoTitle
                slug: $slug
                seoKeywords: $seoKeywords
                seoMetaDescription: $seoMetaDescription
                match: $match
                category: $category
                relatedAssets: $relatedAssets
                isLive: $isLive
                isFree: $isFree
                duration: $duration
                meta: $meta
                ratings: $ratings
                originalAudio: $originalAudio
                audioLanguages: $audioLanguages
                originalTitle: $originalTitle
                subtitleLanguages: $subtitleLanguages
                closedCaption: $closedCaption
                releaseDateTime: $releaseDateTime
                productionDateTime: $productionDateTime
                averageRating: $averageRating
                expectedNumberOfSeasons: $expectedNumberOfSeasons
                productionStudio: $productionStudio
                genres: $genres
                countryOfOrigin: $countryOfOrigin
                seasonNumber: $seasonNumber
                episodeNumber: $episodeNumber
                mediumDescription: $mediumDescription
                videoDuration: $videoDuration
                productionStudios: $productionStudios
                yearOfRelease: $yearOfRelease
                episodeNumberInSeries: $episodeNumberInSeries
                colorCode: $colorCode
                countries: $countries
                seoCanonicalUrl:$seoCanonicalUrl
                autoPublish: $autoPublish
            }
        ) {
            id
            shortId
            externalId
            type
            title
            shortTitle
            description
            shortDescription
            autoPublish
            updatedAt
            updatedBy
            createdBy
            createdAt
            publishStartDate
            publishEndDate
            isArchived
            status
            seoTitle
            slug
            seoKeywords
            seoMetaDescription
            isLive
            isFree
            duration
            isInTransition
            markInTime
            markOutTime
            drmRequired
            site
            source
            relatedAssets{
                id
            }
            vodJobs{
                id
                status
                site
                name
                type
                jobType
                vwmJobId
                updatedAt
                createdAt
                createdBy
                progress
                errorDesc
                startTime
                endTime 
                jobStates{
                status
                errorCode
                updatedAt
            }
                channel{
                    id
                }
                mediaId{
                    id
                    duration
                }  
            }
            category{
                id
                name
                slug
            }
            defaultVideo{
                id
            }
            match{
                id
                kickOffTime
                matchDate
                opId
                finishTime
                externalId
                isLiveOnly
                competition{
                    id
                    title
                }
                homeTeam {
                    id
                    opId
                    code
                    officialName 
                    media {
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2          
                        }
                        vodStatus{
                            id
                        }
                    }
                }
                awayTeam {
                    id
                    opId
                    code
                    officialName   
                    media {
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2          
                        }
                        vodStatus{
                            id
                        }
                    }     
                }
            }
            program{
                id
                source
                isLiveTelecast
                channel{
                    id
                    name
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                    masterConfig{
                        id
                    }
                    configGroups{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    activeConfigGroup{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    defaultTrimStart
                    defaultTrimEnd
                }
                broadCastStartTime
                broadCastEndTime
            }
            author {
                id
                name
                media {
                    id
                    
                    fileName
                    aspectRatio {
                        aspectRatio
                        ratio
                        title          
                        resolutions {
                        url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2          
                    }
                }
            }
            media{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    contentKey
                    Url
                    drm
                    iv 
                    codec
                    transport
                    drmEntitlementMessage
                    channel {
                        id
                    }
                    PackagingConfigurationId
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
                _originalResponseBody 
            }
            images{
                id
                assetId
                mediaId
                isDefault
                fileName
                createdAt
                name
                type{
                    name
                    id
                }
                fileSize
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                
            }
            videos{
                id
                assetId
                mediaId
                fileName
                createdAt
                name
                fileSize
                cuePoints
                adPositions
                breakDuration
                cuePointsError
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                type
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
                drmContent
                streams{
                    id
                    contentKey
                    Url
                    drm
                    iv 
                    codec
                    transport
                    drmEntitlementMessage
                    channel {
                        id
                    }
                    PackagingConfigurationId
                }
                duration
                thumbnails
                isDefault
                _originalResponseBody
            }
            tags {
                key
                type
                name
            }
            defaultMedia {
                id
                fileName
            }
            contents {
                id
                position
                value
                type
                caption
                metaFields{
                    id
                    fieldID
                    tooltip
                    displayName
                    displayTitle
                    type
                    isRequired
                    values
                    value
                    fieldValues
                }
                media{
                    id
                    fileName
                    name
                    type
                    fileSize
                    tags{
                        key
                        name
                        type
                    }
                    settings {
                        fileName
                        aspectRatio
                        fileName
                        x1
                        y1
                        x2
                        y2
                    }
                    dimensions{
                        x1
                        x2
                        y1
                        y2
                    }
                    aspectRatio {
                        aspectRatio
                        title
                        resolutions {
                            key
                            width
                            height
                            url
                        }
                    }
                    vodStatus{
                        id
                    }
                }
            }
            meta{
                displayName
                displayTitle
                tooltip
                type
                value
                isRequired
                fieldValues
                section{
                    id
                    title
                }
            }
            ratings {
                id
                rating
                scheme
                subRatings
                position
                source
            }
            originalAudio
            audioLanguages
            originalTitle
            subtitleLanguages
            closedCaption
            releaseDateTime
            productionDateTime
            averageRating
            expectedNumberOfSeasons
            productionStudio {
                id
                name
            }
            genres {
                id
                name
            }
            countryOfOrigin
            seasonNumber
            episodeNumber
            episodes{
                assetId
                isArchived
                shortId
                externalId
                title
                type
                updatedAt
                publishStartDate
                publishEndDate
                status
                isFree
                seasonNumber
                episodeNumber
                series{
                    id
                    assetId
                    title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                id
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                    url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2 
                    y2
                }
                }
            }
            seasons{
                assetId
                isArchived
                shortId
                externalId
                title
                type
                updatedAt
                publishStartDate
                publishEndDate
                status
                isFree
                seasonNumber
                episodeNumber
                series{
                    id
                    assetId
                    title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                id
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                    url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2 
                    y2
                }
                }
            }
            mediumDescription
            videoDuration
            credits{
                id
                position
                source
                person{
                    id
                    name
                    image {
                        id
                        name
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                              url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2          
                        }
                      }
                }
                role{
                id
                name
                }
                type
                characterName
            }
            series{
                id
                assetId
                title
            }
            season{
                id
                assetId
                title
            }
            productionStudios{
                id
                name
            } 
            yearOfRelease
            episodeNumberInSeries
            colorCode
            countries {
                id 
                name
            }
            seoCanonicalUrl 
        }
    }`
)
