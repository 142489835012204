import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

import TagsInputContainer from '../../components/ui/dataEntry/inputs/TagsInputContainer'
import EditButton from '../../components/ui/general/buttons/EditButton'
import BackArrowButton from '../../components/ui/general/buttons/BackArrowButton'
import SaveButton from '../../components/ui/general/buttons/SaveButton'
import Input from '../../components/ui/dataEntry/inputs/Input'
import { utilityService } from '../../services/UtilityService'
import AppContext from '../../AppContext'
import DropDown from './../../components/ui/dataEntry/inputs/DropDown'
import InputNumber from '../../components/ui/dataEntry/inputs/InputNumber'

import QueryAllTags from '../../graphQL/asset/getTags'
import MutationCreateTag from '../../graphQL/asset/createTag'
import MutationUpdateMatch from '../../graphQL/match/updateMatch'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

class MatchInfoTab extends Component {
  /** Constructor function for initilise state values */
  constructor (props) {
    super(props)
    this.state = {
      isEditEnable: true,
      onDemand: props.isLiveOnly ? _.cloneDeep(props.isLiveOnly) : false,
      venue: props.venue ? props.venue : null,
      selectedTags: props.tagList ? _.cloneDeep(props.tagList) : [],
      isSaveEnable: false,
      notLive: props.notLive,
      status: props.status ? props.status : null,
      customMetaProps: props.customMeta ? _.cloneDeep(props.customMeta) : null
    }

    if (props.metaFieldList && props.metaFieldList.length > 0 && !(props.customMeta && props.customMeta.length)) {
      this.handleMetaDetailsChange(props.metaFieldList, null)
    } else if (props.metaFieldList && props.metaFieldList.length > 0 && props.customMeta && props.customMeta.length) {
      this.checkForNewMeta(props.metaFieldList, props.customMeta)
    }
  }

  componentDidMount = () => {
    if (this.props.metaFieldList && this.props.metaFieldList.length > 0 && this.props.customMeta && this.props.customMeta.length) {
      this.checkForNewMeta(this.props.metaFieldList, this.props.customMeta)
    }
  }

  /** React life cycle method called when props getting change */
  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.id !== this.props.id) {
      this.setState({
        isSaveEnable: false,
        isEditEnable: true
      })
    }
    if (newProps.notLive !== this.props.notLive || newProps.isLiveOnly !== this.props.onDemand || newProps.venue !== this.props.venue || newProps.tagList !== this.props.selectedTags) {
      this.setState({
        onDemand: _.cloneDeep(newProps.isLiveOnly),
        venue: newProps.venue,
        notLive: newProps.notLive,
        selectedTags: newProps.tagList && newProps.tagList.length ? _.cloneDeep(newProps.tagList) : []
      })
    }

    if (newProps.status !== this.props.status) {
      this.setState({
        status: newProps.status
      })
    }

    if (newProps.metaFieldList && newProps.metaFieldList.length > 0 && !(newProps.customMeta && newProps.customMeta.length)) {
      this.handleMetaDetailsChange(newProps.metaFieldList, null)
    } else if (newProps.metaFieldList && newProps.metaFieldList.length > 0 && newProps.customMeta && newProps.customMeta && newProps.customMeta.length) {
      this.checkForNewMeta(newProps.metaFieldList, newProps.customMeta)
    }

    if (!_.isEqual(this.props.customMeta, newProps.customMeta)) {
      this.setState({
        customMetaProps: _.cloneDeep(newProps.customMeta)
      })
    }
  }

    /** Function called when user click on back button after selecting edit mode */
    onBackClick = () => {
      const { venue, defaultVenue, isLiveOnly, tagList, metaFieldList, status, customMeta } = this.props
      const { customMetaProps } = this.state
      const venueDefault = _.isEmpty(defaultVenue) ? '' : defaultVenue.title
      this.getMatchMetaFields(customMetaProps, metaFieldList)
      this.setState({ isEditEnable: true, venue: venue || venueDefault, onDemand: isLiveOnly, selectedTags: tagList, status: status, customMetaProps: customMeta })
    }

    /** Function to be called on save button click for saving match info */
    onSaveClick = () => {
      const { customMetaProps } = this.state
      this.setState({ isLoading: true })
      const { venue, selectedTags, onDemand, notLive, status } = this.state
      const tags = selectedTags.map((item) => item.key)
      const formattedCustomMeta = (customMetaProps || []).map(item => {
        const newItem = {
          config: '',
          value: ''
        }
        if (item && item.config) {
          newItem.config = item.config.id
          newItem.value = item.value
          newItem.name = item.config.name || ''
        } return newItem
      })
      const variables = {
        id: this.props.id,
        venue: venue || null,
        tags: tags.length ? _.uniqBy(tags) : null,
        isLiveOnly: onDemand,
        notLive,
        status,
        customMeta: formattedCustomMeta
      }
      this.props.updateMatches(variables).then((response) => {
        this.props.matchUpdated()
        const data = response.data.updateMatch
        this.setState({
          isLoading: false,
          isEditEnable: true,
          isSaveEnable: false,
          onDemand: _.cloneDeep(data.isLiveOnly),
          venue: data.customVenueName,
          selectedTags: data.tags && data.tags.length ? _.cloneDeep(data.tags) : [] })
      }, error => {
        this.setState({
          isLoading: false,
          isEditEnable: true,
          isSaveEnable: false })
        utilityService.handleError(error)
      })
    }

    /** Function to be called when user click on edit button for editing match info */
    onEditClick = () => {
      // const { customMetaProps } = this.state
      this.setState({ isEditEnable: false })
      // this.props.handleMetaRollback(customMetaProps)
    }

    /** Called when user enter venue name */
    onChangeVenue = (event) => {
      this.setState({ venue: event.target.value, isSaveEnable: true })
    }

    /** Called when user change on demand status */
    onChangeOnDemand = (onDemand) => {
      this.setState({ onDemand: !onDemand, isSaveEnable: true })
    }

    onChangeBroadcastStatus = (notLive) => {
      this.setState({ notLive, isSaveEnable: true })
    }

    /** Called when user change tags */
    onTagChange = (value, tagsList, type, isDelete, isCustom) => {
      let { selectedTags } = this.state
      if (isDelete) {
        const index = selectedTags.findIndex((item) => item.name === value)
        selectedTags.splice(index, 1)
        this.setState({ selectedTags, isSaveEnable: true })
      } else {
        let newTag = tagsList.find((item) => item.name === value)
        if (_.isEmpty(newTag)) {
          const id = Math.random().toString(36).substr(2, 9)
          newTag = { name: value, module: 'MATCH_MANAGER', type: 'MANUAL' }
          this.props.createTag(newTag).then(response => {
            let { selectedTags } = this.state
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.key === id)
            selectedTags[index] = tag
            this.setState({ selectedTags })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}match_manager`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags })
              return
            }
            utilityService.handleError(error)
          })
          newTag.key = id
        }
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
          this.setState({ selectedTags, isSaveEnable: true })
        }
      }
    }

    /** Method to be called when search for a tag */
    onTagSearch = (searchTag) => {
      this.setState({
        searchTag
      })
    }

    onStatusSelect = (status) => {
      if (!status) {
        this.setState({
          status: null,
          isSaveEnable: true
        })
      } else {
        this.setState({
          status,
          isSaveEnable: true
        })
      }
    }

    checkForNewMeta=(metaFieldList, customMeta) => {
      let changeExists = false;
      (metaFieldList || []).map(item => {
        const foundMeta = (customMeta || []).find(existingItem => {
          if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
            return existingItem
          }
        })

        if (_.isEmpty(foundMeta)) {
          changeExists = true
        }
      })
      if (changeExists) { this.handleMetaDetailsChange(metaFieldList, null) }
    }

    getMatchMetaFields = (metaData, selectedMeta) => {
      const { isEditEnable } = this.state
      const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
      // const { handleMetaDetailsChange } = this.props
      return (metaFields || []).map((customMeta, index) => {
        const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
        if (customMeta.type === 'BOOLEAN') {
          return <div className='switch-container input-field' key={index}>
            <div className='switch-inner-container'>
              <span>{customMeta.name}</span>
              <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => this.handleMetaDetailsChange(customMeta, value)} disabled={isEditEnable} />
            </div>
          </div>
        } else if (customMeta.type === 'STRING') {
          return <Input
            key={index}
            title={customMeta.name}
            placeholder={`Enter ${customMeta.name}`}
            inputName='title'
            value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
            handleChange={({ target }) => this.handleMetaDetailsChange(customMeta, target.value)}
            isError={selectedMeta ? selectedMeta.isError : false}
            errorMessage={''}
            disabled={isEditEnable}
          />
        } else if (customMeta.type === 'NUMBER') {
          return <InputNumber
            limit={16}
            key={index}
            title={customMeta.name}
            placeholder={`Enter ${customMeta.name}`}
            inputName='title'
            value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
            handleChange={(value) => this.handleMetaDetailsChange(customMeta, value)}
            isError={selectedMeta ? selectedMeta.isError : false}
            errorMessage={''}
            disabled={isEditEnable}
          />
        }
      })
    }

    handleMetaDetailsChange = (metaData, value) => {
      const listCustomMetaFields = _.cloneDeep(this.props.metaFieldList)
      const { customMetaProps } = this.state
      let finalMetaModified = []
      if (value || value === '' || value === false || value === 'false') {
        const customMetaModified = (listCustomMetaFields || []).map(item => {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          if (item.id === metaData.id && item.type === metaData.type) {
            newMetaField.value = item.type === 'STRING' ? (value || '') : value
            delete item.isError
            newMetaField.config = item
          } else {
            const tempItem = (customMetaProps || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
            if (_.isEmpty(tempItem)) {
              newMetaField.config = item
              newMetaField.value = item.default
            } else {
              newMetaField = tempItem
              newMetaField.value = tempItem.value
            }
          }

          return newMetaField
        })
        finalMetaModified = customMetaModified
      } else if (!value || value === null || value === undefined || value !== false) {
        const customMetaModified = (listCustomMetaFields || []).map(item => {
          const foundMeta = (customMetaProps || []).find(existingMeta => {
            if (existingMeta && existingMeta.config) {
              if (existingMeta.config.id === item.id) { return existingMeta }
            }
          })
          if (_.isEmpty(foundMeta)) {
            let newMetaField = {
              config: item,
              value: item && item.default
            }
            return newMetaField
          } else {
            return foundMeta
          }
        })
        finalMetaModified = customMetaModified
      }
      this.setState({ customMetaProps: finalMetaModified, isSaveEnable: true })
    }

    /** Mandatory render function */
    render () {
      const { defaultVenue, matchStatusList, metaFieldList } = this.props
      const { isEditEnable, venue, onDemand, selectedTags, searchTag, isLoading, isSaveEnable, notLive, status, customMetaProps } = this.state
      const randomId = utilityService.makeRandomString(6)
      const randomParentId = utilityService.makeRandomString(5)
      // const selectedName = (selectedTags || []).map(item => item.name)
      const selectedKey = (selectedTags || []).filter(item => item).map(item => item.name)
      const venueDefault = venue === null ? (_.isEmpty(defaultVenue) ? '' : defaultVenue.title) : venue
      const matchStatusListDropdown = matchStatusList.map((item, index) => { return { id: index, name: item } })
      return <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['MATCH_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <div className='match-info-container'>
            <div className='match-info-inner-container'>
              <Input handleChange={this.onChangeVenue} value={venue || venueDefault} title='Venue' inputName='Venue' readOnly={isEditEnable} />
              <div className='match-tag-container' id={randomId}>
                <span>Tags:</span>
                <TagsInputContainer
                  selectedItems={selectedKey}
                  // selectedDisplayItems={selectedName}
                  onChange={this.onTagChange}
                  placeholder='Insert Tags'
                  parentCompoent={randomId}
                  displayParameter='name'
                  onSearch={this.onTagSearch}
                  searchTag={searchTag}
                  type={'MANUAL'}
                  disabled={isEditEnable}
                  // keyParameter={'key'}
                  isCustom
                  module={'MATCH_MANAGER'}
                  project={project}
                />
              </div>
              <div className='on-demand-container'>
                <Switch disabled={isEditEnable} onChange={this.onChangeOnDemand} checked={!onDemand} />
                <span>Auto Live2Vod</span>

              </div>
              <div className='on-demand-container'>
                <Switch disabled={isEditEnable} onChange={this.onChangeBroadcastStatus} checked={notLive} />
                <span>Not Live</span>
              </div>
              <div className='match-status-container' id={randomParentId}>
                <span>Status</span>
                <DropDown options={matchStatusListDropdown} onOptionSelect={this.onStatusSelect} selectedValue={status} valueParam='name' displayParam='name' placeHolder='Output format' parent={randomParentId} disabled={isEditEnable} allowClear />
              </div>
              <div className='match-meta-field-container'>
                {customMetaProps ? this.getMatchMetaFields(customMetaProps, metaFieldList) : this.handleMetaDetailsChange(metaFieldList, null) }
              </div>
            </div>
            <div className='match-info-footer'>
              {isEditEnable ? '' : <BackArrowButton onClick={this.onBackClick} />}
              {isEditEnable ? <EditButton onClick={this.onEditClick} isDisabled={isUpdateDisabled} /> : <SaveButton onClick={this.onSaveClick} isLoading={isLoading} isDisabled={!isSaveEnable || venue === ''} />}
            </div>
          </div>
        }}
      </AppContext.Consumer>
    }
}

MatchInfoTab.propTypes = {
  /** Match ID */
  id: PropTypes.string,
  /** Match isLiveOnly status */
  isLiveOnly: PropTypes.bool,
  /** List of tags */
  tagList: PropTypes.any,
  /** Custom venue tag */
  venue: PropTypes.string,
  /** Default venue name */
  defaultVenue: PropTypes.object,
  /** Funtion indicates updates completed */
  matchUpdated: PropTypes.func
}

export default withApollo(compose(

  graphql(
    MutationUpdateMatch,
    {
      props: (props) => ({
        updateMatches: (contentObj) => {
          return props.mutate({
            variables: contentObj
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateTag,
    {
      options: ({ project }) => {
        const filter = { type: { match: 'MANUAL' }, module: { exact: 'MATCH_MANAGER' } }
        return {
          update: (cache, { data: { createTag } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        createTag: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(MatchInfoTab))
