import React from 'react'
import PropTypes from 'prop-types'

const DownloadIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} fillRule='nonzero' d='M1 17.333h16.286v-4H20V20H1v-2.667zm0-4h2.714v4H1v-4zM7.786 0h5.428v9.333h2.715L10.5 16 5.071 9.333h2.715V0z' />
      <path d='M0 0h20v20H0z' />
    </g>
  </svg>
)

DownloadIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

DownloadIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default DownloadIcon
