import React from 'react'
import PropTypes from 'prop-types'
import EditIcon from './../icons/EditIcon'
import './../../ui.style.scss'

const EditButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`add-button ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick}>
    <EditIcon />
  </div>
)

EditButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

EditButton.defaultProps = {
  isDisabled: false
}

export default EditButton
