import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Input from '../inputs/Input'

import './../../ui.style.scss'

const errorMessages = [
  `Password doesn't match`,
  `Password must be atleast 12 characters`,
  `Password must have atleast one number`,
  `Password must have atleast one letter`
]

class ChangeCustomerPasswordModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newPassword: '',
      confirmPassword: '',
      isEdited: false,
      isConfirmPasswordError: false,
      isNewPasswordError: false,
      errorMessage: errorMessages[0]
    }
  }

handleChangeNewPassword = (event) => {
  const newPassword = event.target.value
  this.setState({ newPassword, isEdited: true, isNewPasswordError: !newPassword, isConfirmPasswordError: false })
}

handleChangeConfirmPassword = (event) => {
  const confirmPassword = event.target.value
  this.setState({ confirmPassword, isEdited: true, isConfirmPasswordError: false })
}

handleSubmit = () => {
  const { newPassword, confirmPassword } = this.state
  const isEqual = newPassword === confirmPassword
  const lengthCheck = newPassword.length > 7
  let errorIndex = 0
  const atleastOneNumberReg = /[0-9]/
  const atleastOneLetterReg = /[a-zA-Z]/
  const numberCheck = atleastOneNumberReg.test(newPassword)
  const letterCheck = atleastOneLetterReg.test(newPassword)
  if (isEqual && letterCheck && numberCheck && lengthCheck) { this.props.handleSubmit(newPassword) } else {
    errorIndex = !isEqual ? 0 : !lengthCheck ? 1 : !numberCheck ? 2 : !letterCheck ? 3 : 0
    this.setState({ isConfirmPasswordError: true, errorMessage: errorMessages[errorIndex] })
  }
}

    clearState = () => {
      this.setState({
        newPassword: '',
        confirmPassword: '',
        isEdited: false,
        isConfirmPasswordError: false,
        isNewPasswordError: false
      })
    }

    render () {
      const { isVisible, isSubmitDisabled, handleCancel, isLoading } = this.props
      const { isEdited, confirmPassword, newPassword, isNewPasswordError, isConfirmPasswordError, errorMessage } = this.state
      return (
        <Modal
          className='general-modal change-password'
          title={'CHANGE PASSWORD'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Cancel'
          onOk={this.handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (isSubmitDisabled || !isEdited || !confirmPassword || !newPassword || isConfirmPasswordError || isNewPasswordError) }}
          closable={false}
          centered
          destroyOnClose
          confirmLoading={isLoading}
          afterClose={this.clearState}
          width='350'
        >
          { isVisible
            ? <div className='change-password-container'>
              <Input
                title='New Password'
                inputName='newPassword'
                handleChange={this.handleChangeNewPassword}
                value={newPassword}
                placeholder='Enter New Password'
                type={'password'}
                isError={isNewPasswordError}
                errorMessage='Please enter new password' />
              <Input
                title='Confirm New Password'
                inputName='confirmPassword'
                handleChange={this.handleChangeConfirmPassword}
                value={confirmPassword}
                placeholder='Confirm New Password'
                type={'password'}
                isError={isConfirmPasswordError}
                errorMessage={errorMessage} />
            </div> : null }
        </Modal>
      )
    }
}

ChangeCustomerPasswordModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

ChangeCustomerPasswordModal.defaultProps = {
}

export default ChangeCustomerPasswordModal
