import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Spin } from 'antd'
import './../../ui.style.scss'

const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />
const spin = <Spin indicator={antIcon} />

const LoadingButton = ({ buttonText, buttonClass, isLoading, isDisabled, onClick, htmlType, type, ...props }) => (
  <Button
    disabled={isDisabled}
    type={type || 'primary'}
    htmlType={htmlType}
    className={`loading-button ${buttonClass} ${isLoading ? 'loading' : ''} ${isDisabled ? 'disable' : ''}`}
    onClick={onClick}
  >
    { isLoading ? spin : buttonText }
  </Button>
)

LoadingButton.propTypes = {
  /** loading status of LoadingButton. */
  isLoading: PropTypes.bool,
  /** disabled status of LoadingButton. */
  isDisabled: PropTypes.bool,
  /** Button inner text. Defaults to 'Submit' */
  buttonText: PropTypes.string,
  /** Button className. Defaults to 'login-form-button' */
  buttonClass: PropTypes.string,
  /** Button click action. */
  onClick: PropTypes.func
}

LoadingButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  buttonText: 'Submit',
  buttonClass: 'login-form-button',
  htmlType: 'submit'
}

export default LoadingButton
