import React from 'react'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import CollapsedIcon from '../general/icons/CollapsedIcon'

import './../ui.style.scss'

const Panel = Collapse.Panel

const Accordion = ({ title, childComponent, isFirstClose, onExpandButtonClick, hideCollapse, ...props }) => {
  const click = () => {
    if (onExpandButtonClick) {
      onExpandButtonClick()
    }
  }
  const header = (
    <div className='accordion-arrow'>
      <div className='title'>{ title }</div>
      {!hideCollapse ? <CollapsedIcon /> : null }
    </div>
  )
  return (

    <Collapse accordion className='full-accordion' defaultActiveKey={isFirstClose ? [ '0' ] : ['1']} onChange={click}>
      <Panel header={header} key='1'>
        { childComponent }
      </Panel>
    </Collapse>
  )
}

Accordion.propTypes = {
  /** Title of Accordion. */
  title: PropTypes.any.isRequired,
  /** Child Component  of Accordion. */
  childComponent: PropTypes.any
}

Accordion.defaultProps = {
  isFirstClose: false
}

export default Accordion
