import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import CreateAuthorContainer from './CreateAuthorContainer'
import userMessages from './../../../../constants/messages'
import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAllAuthors from '../../../../graphQL/asset/getAuthors'
import MutationCreateAuthor from '../../../../graphQL/asset/createAuthor'
import MutationUpdateAuthor from '../../../../graphQL/admin/asset/updateAuthor'
import MutationUpdateMedia from '../../../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from '../../../../graphQL/content/updateMediaSettings'

class CreateAuthorModal extends Component {
  constructor (props) {
    super(props)
    let authorName = ''
    let image = ''
    let isDisabled = false
    if (props.selectedAuthor) {
      authorName = props.selectedAuthor.name
      isDisabled = props.selectedAuthor.isDisabled
      image = props.selectedAuthor.media && props.selectedAuthor.media.length && props.selectedAuthor.media[0] ? props.selectedAuthor.media[0] : ''
    }
    this.state = {
      authorName,
      isDisabled,
      image,
      notEdited: true
    }
  }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedAuthor && (!_.isEqual(this.props.selectedAuthor, nextProps.selectedAuthor) || (!this.state.authorName && !this.state.image))) {
        this.setState({
          authorName: nextProps.selectedAuthor.name,
          isDisabled: nextProps.selectedAuthor.isDisabled,
          image: nextProps.selectedAuthor.media && nextProps.selectedAuthor.media.length ? nextProps.selectedAuthor.media : '',
          notEdited: true
        })
      } else if (!_.isEqual(this.props.selectedImage, nextProps.selectedImage)) {
        this.setState({ image: nextProps.selectedImage, notEdited: false })
      }
      if (nextProps.croppedUrl && !_.isEqual(this.props.croppedUrl, nextProps.croppedUrl)) {
        this.setState({ notEdited: false })
      }
    }

    handleTextChange = (event) => {
      this.setState({ authorName: event.target.value, notEdited: false })
    }

    handleActiveChange = () => {
      this.setState({ isDisabled: !this.state.isDisabled, notEdited: false })
    }

    submitAuthor = () => {
      const { authorName } = this.state
      this.setState({ isLoading: true })
      this.props.getUserWithName(authorName).then(({ data }) => {
        if (this.props.selectedAuthor) {
          if (data.listAuthors && data.listAuthors.items && (!data.listAuthors.items.length || (data.listAuthors.items.length === 1 && data.listAuthors.items[0].id === this.props.selectedAuthor.id))) {
            this.updateAuthor()
          } else {
            message.error(userMessages.DUPLICATE_AUTHOR_NAME)
            this.setState({ isLoading: false })
          }
        } else {
          if (data.listAuthors && data.listAuthors.items && !data.listAuthors.items.length) {
            this.createAuthor()
          } else if (data.listAuthors && data.listAuthors.items && data.listAuthors.items.length) {
            message.error(userMessages.DUPLICATE_AUTHOR_NAME)
            this.setState({ isLoading: false })
          }
        }
      })
    }

    createAuthor = () => {
      const { authorName, image, isDisabled } = this.state
      const { isUpdateBlocked, module, onHistoryUpdate } = this.props
      this.props.createAuthor(image ? image[ 0 ].id : null, authorName, isDisabled, module).then(response => {
        if (onHistoryUpdate) {
          onHistoryUpdate()
        }
        if (!response.data.createAuthor.media || !response.data.createAuthor.media.length) {
          this.props.handleSubmit(response.data.createAuthor)
          this.setState({ authorName: '', image: '', isLoading: false })
          return
        }
        const mediaId = response.data.createAuthor.media[0].id
        const name = image[ 0 ].name
        const tags = image[ 0 ].tags
        const authorId = response.data.createAuthor.id
        const settings = _.cloneDeep(image[ 0 ].settings).map((item) => {
          if (item.__typename) {
            delete (item.__typename)
            delete item.fileName
          }
          return item
        })
        // this.props.updateImageSettings(mediaId, authorId, settings).then(({ data }) => {
        this.props.updateImageSettings(mediaId, authorId, settings)
        if (isUpdateBlocked) {
          // response.data.createAuthor.media[0] = data.updateAssetMediaLink
          this.props.handleSubmit(response.data.createAuthor)
          // this.props.getUserWithName(authorName)
          this.setState({ authorName: '', image: '', isLoading: false })
        } else {
          this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then(({ data }) => {
            let createdAuthor = response.data?.createAuthor
            if (createdAuthor?.media?.[0]) {
              createdAuthor = data.updateMedia
            }
            this.props.handleSubmit(createdAuthor)
            // this.props.getUserWithName(authorName)
            this.setState({ authorName: '', image: '', isLoading: false })
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }

    updateAuthor = () => {
      const { authorName, image, isDisabled } = this.state
      const { selectedAuthor, isUpdateBlocked, onHistoryUpdate } = this.props
      this.props.updateAuthor(selectedAuthor.id, image ? image[ 0 ].id : null, authorName, isDisabled).then(response => {
        if (!response.data.updateAuthor.media || !response.data.updateAuthor.media.length) {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          this.props.handleSubmit(response.data.updateAuthor)
          this.setState({ authorName: '', image: '', isLoading: false })
          return
        }
        const mediaId = response.data.updateAuthor.media[0].id
        const name = image[ 0 ].name
        const tags = image[ 0 ].tags
        const authorId = response.data.updateAuthor.id
        const settings = _.cloneDeep(image[ 0 ].settings).map((item) => {
          if (item.__typename) {
            delete (item.__typename)
            delete item.fileName
          }
          return item
        })
        // this.props.updateImageSettings(mediaId, authorId, settings).then(({ data }) => {
        this.props.updateImageSettings(mediaId, authorId, settings)
        if (isUpdateBlocked) {
          this.props.handleSubmit(response.data.updateAuthor)
          this.props.getUserWithName(authorName)
          this.setState({ authorName: '', image: '', isLoading: false })
        } else {
          this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then(({ data }) => {
            if (response.data.updateAuthor.media[0]) { response.data.updateAuthor.media[0] = data.updateMedia }
            this.props.handleSubmit(response.data.updateAuthor)
            if (onHistoryUpdate) {
              onHistoryUpdate()
            }
            this.props.getUserWithName(authorName)
            this.setState({ authorName: '', image: '', isLoading: false })
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }

    handleCancel = () => {
      this.setState({
        authorName: '',
        image: '',
        isDisabled: false,
        notEdited: true
      })
      this.props.handleCancel()
    }

    render () {
      const { isVisible, showImageModal, showCropModal, croppedUrl, clearSelection, selectedAuthor, isUpdateBlocked, isSubmitDisabled, module } = this.props
      const { authorName, isLoading, image, isDisabled, notEdited } = this.state
      return (
        <Modal
          className='confirm-modal edit-image'
          title={selectedAuthor ? 'EDIT AUTHOR' : 'NEW AUTHOR'}
          maskClosable={false}
          visible={isVisible}
          okText='Save Author'
          cancelText='Back'
          onOk={this.submitAuthor}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: (!authorName || notEdited || isSubmitDisabled) }}
          closable={false}
          centered
          afterClose={this.resetState}
          width='600px'
          confirmLoading={isLoading}
        >
          { isVisible ? <CreateAuthorContainer
            showCropModal={showCropModal}
            showImageModal={showImageModal}
            selectedImage={image}
            croppedUrl={croppedUrl}
            handleActiveChange={this.handleActiveChange}
            handleTextChange={this.handleTextChange}
            isDisabled={isDisabled}
            authorName={authorName}
            clearSelection={clearSelection}
            isUpdateBlocked={isUpdateBlocked}
            module={module}
          /> : null }
        </Modal>
      )
    }
}

CreateAuthorModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Details of selected image */
  selectedImage: PropTypes.array,
  /** Function to save the author */
  createAuthor: PropTypes.func,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Callback to show the AddImageModal */
  showImageModal: PropTypes.func,
  /** Boolean to indicates update media is disabled */
  isUpdateBlocked: PropTypes.bool,
  /** Module name */
  module: PropTypes.string,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

CreateAuthorModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      QueryAllAuthors,
      {
        options: ({ project }) => {
          return {
            varibales: { filter: null, project }
          }
        },
        props: (props) => {
          const { data: { listAuthors = { items: [] } } } = props
          return {
            authors: [ ...listAuthors.items ],
            getUserWithName: (name) => {
              return props.data.refetch({ filter:
                {
                  name: { exact: name }
                },
              project: props.ownProps.project
              })
            }
          }
        }
      }
    ),
    graphql(
      MutationCreateAuthor, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { createAuthor } }) => {
              const variables = { filter: null, limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllAuthors, variables }))
              if (cacheData && cacheData.listAuthors && cacheData.listAuthors.items) { cacheData.listAuthors.items.push(createAuthor) }
              cache.writeQuery({
                query: QueryAllAuthors,
                data: cacheData,
                variables
              })
            }
          }
        },
        skip: ({ name, id }) => {
          return !!(!id || !name)
        },
        props: (props) => ({
          createAuthor: (id, name, isDisabled, module) => {
            return props.mutate({
              variables: { name, media: id ? [ id ] : [], isDisabled, module, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateAuthor, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { updateAuthor } }) => {
              const variables = { filter: null, limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllAuthors, variables }))
              if (cacheData && cacheData.listAuthors && cacheData.listAuthors.items) {
                const selectedAuthorIndex = cacheData.listAuthors.items.findIndex(item => item.id === updateAuthor.id)
                if (selectedAuthorIndex > -1) {
                  cacheData.listAuthors.items[selectedAuthorIndex] = updateAuthor
                }
              }
              cache.writeQuery({
                query: QueryAllAuthors,
                data: cacheData,
                variables
              })
            }
          }
        },
        skip: ({ name, id }) => {
          return !!(!id || !name)
        },
        props: (props) => ({
          updateAuthor: (id, mediaId, name, isDisabled) => {
            return props.mutate({
              variables: { id, name, media: mediaId ? [ mediaId ] : [], isDisabled, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMedia, {
        props: (props) => ({
          updateImage: (id, name, tags, settings) => {
            return props.mutate({
              variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMediaSettings, {
        props: (props) => ({
          updateImageSettings: (id, assetId, settings) => {
            return props.mutate({
              variables: { id, assetId, settings }
            })
          }
        })
      }
    )
  )(CreateAuthorModal)
)
