import React, { Component } from 'react'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'antd/dist/antd.css'
import './App.scss'
import Amplify, { Auth, I18n } from 'aws-amplify'

// AppSync and Apollo libraries
import ApolloLogger from './components/ApolloLoggerLink'
// import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync'
// import { Rehydrated } from 'aws-appsync-react'
// import { ApolloProvider } from '@apollo/client/react/hoc'
// import { from } from 'apollo-link'
import { CookieStorage } from 'amazon-cognito-identity-js'

// Config
import config from './constants/config'
// import bugsnag from '@bugsnag/js'
// import bugsnagReact from '@bugsnag/plugin-react'

// Components
import Logger from './components/Logger'
import AppWithAuth from './App'
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache, createHttpLink, split } from '@apollo/client'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { createAuthLink } from 'aws-appsync-auth-link'

NProgress.configure({ showSpinner: false })
I18n.setLanguage(config.PROJECT_LANGUAGE)
I18n.putVocabularies(config.AUTH_SCREEN_LABELS)

// Bugsnag settings
// const bugsnagClient = bugsnag({
//   apiKey: config.BUGSNAG_KEY,
//   appVersion: config.VERSION,
//   notifyReleaseStages: ['production'],
//   releaseStage: config.RELEASE_STAGE
// })
// bugsnagClient.use(bugsnagReact, React)
// const ErrorBoundary = bugsnagClient.getPlugin('react')
const getGraphQLUrl = (cmsOnlyUserAccess) => {
  // if (window.config) {
  //   if (window.location.host.startsWith('wrc-dev-g.')) {
  //     return window.config.api_endpoint_g
  //   } else {
  //     return window.config.api_endpoint_b
  //   }
  // }
  let graphQLUrl = `${location.origin.toString()}/graphql`
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
    graphQLUrl = `${config.LOCAL_URL}/graphql`
  }
  if (cmsOnlyUserAccess === false) {
    graphQLUrl = window.config.active_stack === 'G' ? window.config.api_endpoint_g : window.config.api_endpoint_b
  }
  return graphQLUrl
}
class WithProvider extends Component {
    state = {
      csm_only_user_access: null
    }

    updateCMSonly = (status) => {
      this.setState({ csm_only_user_access: status })
    }

    componentDidMount () {
      let datainterval = setInterval(() => {
        if (window.csm_only_user_access) {
          this.setState({ csm_only_user_access: true })
          clearInterval(datainterval)
        } else if (window.csm_only_user_access === false) {
          this.setState({ csm_only_user_access: false })
          clearInterval(datainterval)
        }
      }, 1000)
    }

    render () {
      const graphQLUrl = getGraphQLUrl(this.state.csm_only_user_access)
      const awsConfig = {
        ...window.config,
        aws_appsync_graphqlEndpoint: graphQLUrl,
        storage: new CookieStorage({ domain: location.hostname })
      }
      Amplify.configure(awsConfig)
      const auth = {
        type: awsConfig.aws_appsync_authenticationType,
        jwtToken: async () => {
          try {
            const session = await Auth.currentSession()
            return session ? session.getIdToken().getJwtToken() : null
          } catch (err) {
            return ''
          }
        }
      }
      const config = {
        url: awsConfig.aws_appsync_graphqlEndpoint,
        region: awsConfig.aws_appsync_region,
        auth,
        complexObjectsCredentials: () => Auth.currentCredentials()
      }
      const httpLink = createHttpLink({ uri: config.url })
      const client = new ApolloClient({
        link: ApolloLink.from([
          ApolloLogger,
          createAuthLink(config),
          split(op => {
            const { operation } = op.query.definitions[0]
            if (operation === 'subscription') {
              return false
            }
            return true
          },
          httpLink,
          createSubscriptionHandshakeLink(
            {
              auth,
              region: awsConfig.aws_appsync_region,
              url: awsConfig.aws_appsync_graphqlEndpoint
            },
            httpLink
          )
          )
        ]),
        cache: new InMemoryCache()
        // defaultOptions: {
        //   watchQuery: {
        //     fetchPolicy: 'cache-and-network'
        //   }
        // }
      })

      // const link = from([
      //   ApolloLogger,
      //   appSyncLink
      // ])

      // const client = new AWSAppSyncClient({ disableOffline: true }, { link })

      return (
        // <ErrorBoundary>
        <Logger >
          <ApolloProvider client={client}>
            <AppWithAuth />
          </ApolloProvider>
        </Logger>
        // </ErrorBoundary>
      )
    }
}

export default WithProvider
