import React from 'react'
import PropTypes from 'prop-types'
import ImageCropper from '../../dataDisplay/ImageCropper'
import './../../ui.style.scss'

class CropImageContainer extends React.Component {
  render () {
    const { aspectRatio, imgUrl, callback, settings } = this.props
    return (
      <div className='crop-image-modal image-item' >
        <ImageCropper src={imgUrl} aspectRatio={aspectRatio} callback={callback} settings={settings} />
      </div>
    )
  }
}

CropImageContainer.propTypes = {
  /* Url of the image to be edited  */
  imgUrl: PropTypes.string,
  /** Aspect ratio size */
  aspectRatio: PropTypes.object,
  callback: PropTypes.func,
  /**  */
  settings: PropTypes.object
}

CropImageContainer.defaultProps = {
  imgUrl: ''
}

export default CropImageContainer
