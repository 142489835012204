import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import Input from '../inputs/Input'
import { utilityService } from '../../../../services/UtilityService'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateCountry from './../../../../graphQL/admin/entertainmentData/createCountry'
import QueryCountry from './../../../../graphQL/admin/entertainmentData/listCountry'
import MutationUpdateCountry from './../../../../graphQL/admin/entertainmentData/updateCountry'

class CreateCountryModal extends Component {
    state = {
      countryName: undefined,
      isLoading: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedCountry && (!_.isEqual(this.props.selectedCountry, nextProps.selectedCountry) || (!this.state.countryName))) {
        this.setState({
          countryName: nextProps.selectedCountry.name
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ countryName: value })
        }
      }
    }

    handleAddCountry = () => {
      const { countryName } = this.state
      const { selectedCountry, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })

      if (selectedCountry) {
        const newCountry = {
          id: selectedCountry.id,
          name: countryName
        }
        if (!countryName) {
          message.error('Name is mandatory field')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateCountry(newCountry).then(response => {
          this.props.handleSubmit(response.data.updateCountry)
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newCountry = {
          name: countryName
        }
        this.props.createCountry(newCountry).then(response => {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          message.success('Country created successfully')
          this.props.handleSubmit(response.data.createCountry)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    resetState = () => {
      const state = {
        countryName: '',
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedCountry, isSubmitDisabled } = this.props
      const { countryName, isLoading } = this.state
      const disabled = selectedCountry ? (selectedCountry.name === countryName && selectedCountry.name) : (!countryName)
      return (
        <Modal
          className='general-modal create-general-modal'
          title={selectedCountry ? 'EDIT COUNTRY' : 'NEW COUNTRY'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddCountry}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
          confirmLoading={isLoading}
        >
          <div className='create-role'>
            <Input title='Name' isRequired inputName='name' handleChange={this.handleChange} value={countryName} placeholder='Enter Country name' />
          </div>
        </Modal>
      )
    }
}

CreateCountryModal.propTypes = {
  /** visible file status of CreateRoleModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateRoleModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

CreateCountryModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateCountry,
    {
      options: ({ listApi, project, searchString }) => {
        return {
          update: (cache, { data: { createCountry } }) => {
            try {
              const variables = { limit: 20, offset: 0, project: project, search: searchString || null }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCountry, variables }))
              if (cacheData && cacheData.listCountry && cacheData.listCountry.items) {
                cacheData.listCountry.items.unshift(createCountry)
              }
              cache.writeQuery({
                query: QueryCountry,
                data: cacheData,
                variables
              })
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createCountry: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateCountry,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateCountry } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCountry, variables }))
              const index = cacheData.listCountry.items.findIndex(item => item.id === updateCountry.id)
              if (index > -1 && updateCountry.name) {
                cacheData.listCountry.items[index].name = updateCountry.name
              }
              cache.writeQuery({
                query: QueryCountry,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { filter: null, offset: 0, limit: 20, project }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryCountry, variables: newVariables }))
                const index = newCacheData.listCountry.items.findIndex(item => item.id === updateCountry.id)
                if (index > -1 && updateCountry.name) {
                  newCacheData.listCountry.items[index].name = updateCountry.name
                }
                cache.writeQuery({
                  query: QueryCountry,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateCountry: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateCountryModal))
