import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryListSubscriptionEvents from '../../../graphQL/customers/listSubscriptionsEvents'

import { Modal, Button, Empty } from 'antd'

class EventsModal extends Component {
    renderEventData=(events) => {
      let eventsData = (events || []).map(event => {
        return (
          <div className='event-cell' key={event.id}>
            <span>{event.id}</span>
            <span>{event.eventName}</span>
            <span>{event.eventDate ? moment(new Date(event.eventDate)).format('DD MMM YYYY') : '-'}</span>
          </div>
        )
      })
      if (!(eventsData && eventsData.length)) {
        eventsData = <div className='empty-container'><Empty /></div>
      }
      return eventsData
    }

    renderLoading = () => {
      return <div className='loading-container'>
        <Button loading className='loading-button' />
      </div>
    }

    render () {
      const { isVisible, handleCancel, events, isLoading } = this.props
      return (
        <Modal
          className='general-modal event-details'
          title={'EVENTS'}
          maskClosable={false}
          visible={isVisible}
          closable={false}
          centered
          destroyOnClose
          width={'600px'}
          // height={'699px'}
          footer={[
            <Button key='back' onClick={handleCancel}>
              Close
            </Button>]}
        >

          <div className='event-header'>
            <span>Event ID</span>
            <span>Event Name</span>
            <span>Event Date</span>
          </div>
          <div className='event-modal-content'>
            {isLoading ? this.renderLoading() : this.renderEventData(events)}
          </div>
        </Modal>
      )
    }
}

EventsModal.propTypes = {
  /** function to handle Back in Modal */
  handleCancel: PropTypes.func,
  /** boolean toggle to show Modal */
  isVisible: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryListSubscriptionEvents,
    {
      skip: ({ isVisible, subscriptionId }) => !isVisible || !subscriptionId,
      options: (props) => {
        const { subscriptionId } = props
        const variables = { subscriptionId }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const events = data && data.hyperionListSubscriptionActivities ? data.hyperionListSubscriptionActivities : []
        return { events, isLoading: data && data.loading }
      }
    }
  )
)(EventsModal))
