import React, { Component } from 'react'
//
// import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePicker } from 'antd'
import PublishAccordionTitle from './PublishAccordionTitle'
import InputNumber from '../dataEntry/inputs/InputNumber'
import DropDown from '../dataEntry/inputs/DropDown'
import Accordion from './Accordion'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryListPublishGroups from './../../../graphQL/asset/listpublishgroup'

const relativeTimeOptions = [
  {
    name: 'Before',
    id: 'SUBTRACT'
  }, {
    name: 'After',
    id: 'ADD'
  // }, {
  //   name: 'Equal',
  //   id: 'ADD'
  }, {
    name: 'Specific End Date',
    id: 'SPECIFIC'
  }
]

const availableTimeRelationOptions = [
  {
    name: 'Minute(s)',
    id: 'MINUTES'
  }, {
    name: 'Hour(s)',
    id: 'HOURS'
  }, {
    name: 'Day(s)',
    id: 'DAYS'
  }, {
    name: 'Month(s)',
    id: 'MONTH'
  }, {
    name: 'Year(s)',
    id: 'YEAR'
  }
]

const matchTimeOptions = [
  {
    name: 'Match Start',
    id: '{MATCH_DATE}'
  }, {
    name: 'Season End',
    id: '{SEASON_END}'
  }
]
// let selectedOption = {}
class TemplatePublishDetails extends Component {
  selectOptions = (id, type, value) => {
    let { publishRules } = this.props
    const selectedPublishRule = publishRules.findIndex(item => item.publishGroup === id)
    if (selectedPublishRule > -1) {
      // selectedOption=value
      // console.log(selectedOption)
      if (type === 'operator' && value === 'SPECIFIC' && publishRules[selectedPublishRule].field !== '{SPECIFIC_DATE}') {
        publishRules[selectedPublishRule].field = '{SPECIFIC_DATE}'
      }
      if (type === 'operator' && value !== 'SPECIFIC' && publishRules[selectedPublishRule].field === '{SPECIFIC_DATE}') {
        publishRules[selectedPublishRule].field = '{MATCH_DATE}'
      }
      publishRules[selectedPublishRule][type] = value
      this.props.onUpdatePublishRules(publishRules)
    }
  }

  selectTimeOptions = (id, value) => {
    const type = 'offset'
    const { publishRules } = this.props
    const selectedPublishRule = publishRules.findIndex(item => item.publishGroup === id)
    if (selectedPublishRule > -1) {
      publishRules[selectedPublishRule][type] = value
      this.props.onUpdatePublishRules(publishRules)
    }
  }

  selectSpecificEndDate=(id, type, value) => {
    let { publishRules } = this.props
    const selectedPublishRule = publishRules.findIndex(item => item.publishGroup === id)
    if (selectedPublishRule > -1) {
      // selectedOption=value
      // console.log(selectedOption)
      if (value) {
        publishRules[selectedPublishRule][type] = value ? moment(value).utc().format() : null
        // publishRules[selectedPublishRule].offset = '0'
        publishRules[selectedPublishRule].field = '{SPECIFIC_DATE}'
        if (publishRules[selectedPublishRule].offset < 1) {
          publishRules[selectedPublishRule].offset = 30
        }
        this.props.onUpdatePublishRules(publishRules)
      } else {
        publishRules[selectedPublishRule][type] = value
        // publishRules[selectedPublishRule].offset = '0'
        publishRules[selectedPublishRule].field = '{SPECIFIC_DATE}'
        if (publishRules[selectedPublishRule].offset < 1) {
          publishRules[selectedPublishRule].offset = 30
        }
        this.props.onUpdatePublishRules(publishRules)
      }
    }
  }

  renderChildComponent = (id) => {
    const { publishRules } = this.props
    const selectedPublishGroup = publishRules.find(item => item.publishGroup === id)
    return <div className='template-publishing'>
      <div className='publish-data' id={`details-${id}`}>
        {/* <div className='publish-date'>
          <label>Start Date</label>
          <p className={`${isDisabled ? 'disabled' : ''}`}> Reset Default </p>
        </div>
        <div className='publish-time'>
          <div className='schedule'>
            <DropDown options={relativeTimeOptions} onOptionSelect={(value) => this.selectOptions(id, 'startDate', 'a', value)} selectedValue={selectedPublishRule['startDate'].a} valueParam='name' displayParam='name' placeHolder='Output format' parent={`details-${id}`} />
          </div>
          <div className='schedule'>
            <DropDown options={matchTimeOptions} onOptionSelect={(value) => this.selectOptions(id, 'startDate', 'b', value)} selectedValue={selectedPublishRule['startDate'].b} valueParam='name' displayParam='name' placeHolder='Output format' parent={`details-${id}`} />
          </div>
          <div className='schedule'>
            <Input title='' placeholder='Offset...' inputName='title' value={selectedPublishRule['startDate'].c} handleChange={(value) => this.selectTimeOptions(id, 'startDate', value)} />
          </div>
          <div className='schedule'>
            <DropDown options={availableTimeRelationOptions} onOptionSelect={(value) => this.selectOptions(id, 'startDate', 'd', value)} selectedValue={selectedPublishRule['startDate'].d} valueParam='name' displayParam='name' placeHolder='Output format' parent={`details-${id}`} />
          </div>
        </div> */}
        <div className='publish-date'>
          <label>End Date</label>
        </div>
        <div className='publish-time'>
          <div className='schedule'>
            <DropDown options={relativeTimeOptions} onOptionSelect={(value) => this.selectOptions(id, 'operator', value)} selectedValue={selectedPublishGroup.operator} valueParam='id' displayParam='name' placeHolder='Output format' parent={`details-${id}`} />
          </div>
          {selectedPublishGroup.operator !== 'SPECIFIC'
            ? <div className='schedule'>
              <DropDown options={matchTimeOptions} onOptionSelect={(value) => this.selectOptions(id, 'field', value)} selectedValue={selectedPublishGroup.field} valueParam='id' displayParam='name' placeHolder='Output format' parent={`details-${id}`} />
            </div> : null}
          { (selectedPublishGroup.operator !== 'Equal' && selectedPublishGroup.operator !== 'SPECIFIC')
            ? <React.Fragment >
              <div className='schedule'>
                <InputNumber title='' placeholder='Offset' inputName='title' value={selectedPublishGroup.offset} handleChange={(value) => this.selectTimeOptions(id, value)} />
              </div>
              <div className='schedule'>
                <DropDown options={availableTimeRelationOptions} onOptionSelect={(value) => this.selectOptions(id, 'offsetType', value)} selectedValue={selectedPublishGroup.offsetType} valueParam='id' displayParam='name' placeHolder='Output format' parent={`details-${id}`} />
              </div>
            </React.Fragment>
            : null}
          {selectedPublishGroup.operator === 'SPECIFIC'
            ? <div className='schedule'>
              <DatePicker format='MMM DD YYYY HH:mm:ss' placeholder='Select End Date' value={selectedPublishGroup && selectedPublishGroup.publishEndDate ? moment(selectedPublishGroup.publishEndDate) : null} onChange={(value, endDate) => this.selectSpecificEndDate(id, 'publishEndDate', endDate)} showTime={{ format: 'HH:mm:ss' }} />
            </div> : null
          }

        </div>
      </div>
    </div>
  }

  onRemovePublishRule = (event, id) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onRemovePublishRule(id)
  }

  render () {
    const { listPublishGroups, onAddPublishRule, publishRules } = this.props

    return (
      <div className='template-publish-rules'>
        {listPublishGroups.map(item => {
          const selectedPublishGroup = publishRules.find(publishRule => publishRule.publishGroup === item.id)
          return (<Accordion key={item.id} title={<PublishAccordionTitle title={item.name} isChecked={selectedPublishGroup} onRemovePublishRule={(e) => this.onRemovePublishRule(e, item.id)} onClickExpand={() => onAddPublishRule(item.id)} />} childComponent={selectedPublishGroup ? this.renderChildComponent(item.id) : null} hideCollapse isFirstClose={!selectedPublishGroup} />)
        })}
      </div>
    )
  }
}

TemplatePublishDetails.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListPublishGroups,
    {
      options: ({ project }) => {
        const variables = { limit: 50, project }
        return {
          variables
        }
      },
      props: (props) => {
        return {
          listPublishGroups: props.data && props.data.listPublishingGroups && props.data.listPublishingGroups.items ? props.data.listPublishingGroups.items : []
        }
      }
    }
  )
)(TemplatePublishDetails))
