import gql from 'graphql-tag'

export default gql(`
    mutation media($id: ID! $name: String! $updatedAt: AWSDateTime $tags: [ID] $settings: [UpdateImageSettingInput] $fileName: String, $drmContent: Boolean $adPositions: String $cuePoints:String $breakDuration:String $project: String) {
        updateMedia(input:{
            id: $id
            tags: $tags
            name: $name
            settings: $settings
            updatedAt: $updatedAt
            fileName: $fileName
            drmContent: $drmContent
            adPositions: $adPositions
            cuePoints: $cuePoints
            breakDuration: $breakDuration
        }
        project: $project
        ) {
            id
            fileName
            createdAt
            name
            type
            fileSize
            isArchived
            duration
            thumbnails
            cuePoints
            adPositions
            breakDuration
            cuePointsError
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions {
                x1
                y1
                x2
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
            drmContent
            streams{
                id
                contentKey
                Url
                drm
                iv 
                codec
                transport
                channel {
                    id
                }
                PackagingConfigurationId
            }
            _originalResponseBody
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
        }
    }
`)
