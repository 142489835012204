import * as React from 'react'
import { Modal, Button, message } from 'antd'
import PropTypes from 'prop-types'
import { StreamList } from '../../dataDisplay/StreamList'
import { ConfigEditAccordion } from '../../dataDisplay/ConfigEditAccordion'
import Input from '../inputs/Input'
import InputNumber from '../inputs/InputNumber'
import _ from 'lodash'
import userMessages from '../../../../constants/messages'
import { v4 as uuidv4 } from 'uuid'

let url = []

export class ConfigGroupEditModal extends React.Component {
  constructor (props) {
    super(props)
    let configSchema = []
    if (_.keys(props.configSchema).length) {
      configSchema = props.configSchema.streams
    }

    const configEditData = this.getStreamList(_.keys(props.configEditData).length ? props.configEditData.streams : [], configSchema)
    const fieldData = props.fieldData && props.fieldData.length ? props.fieldData : []
    const isSaveEnable = this.checkSaveable(configEditData, fieldData)
    this.state = {
      newOffset: '',
      isChecked: false,
      configEditData,
      isSaveEnable: props.isVersionChange && isSaveEnable,
      fieldData,
      validJson: true,
      jsonInvalidEntries: []
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.configSchema && _.keys(newProps.configSchema).length && !newProps.isLoading) {
      const configEditData = this.getStreamList(_.keys(newProps.configEditData).length ? newProps.configEditData.streams : [], _.keys(newProps.configSchema).length ? newProps.configSchema.streams : [])
      const fieldData = newProps.fieldData && newProps.fieldData.length ? newProps.fieldData : []
      const isSaveEnable = this.checkSaveable(configEditData, fieldData)
      this.setState({
        configEditData,
        fieldData,
        isSaveEnable: newProps.isVersionChange && isSaveEnable
      })
    }
    // if (_.keys(newProps.configSchema).length && _.keys(newProps.configEditData).length && newProps.configEditData.name !== newProps.configSchema.name) {
    //   this.setState({ isSaveEnable: true })
    // }
  }

  clearState = () => {
    this.setState({
      newOffset: '',
      isChecked: false,
      configEditData: [],
      isSaveEnable: false,
      fieldData: []
    })
  }

  onSave = () => {
    let tempData = _.cloneDeep(this.state.configEditData)
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    let urlValid = []
    let mandatoryMissing = []
    tempData = tempData.map((dataItem) => {
      const fields = _.find(this.props.configEditData ? this.props.configEditData.streams : [], (item) => item.id === dataItem.id)
      urlValid.push(!dataItem.url ? true : (!!((dataItem.url && dataItem.url.match(urlRegex)))))

      const missingMandatoryStreamData = (dataItem.schema || []).filter(item => (item.isRequired && item.type !== 'BOOLEAN' && !item.value))
      if (missingMandatoryStreamData && missingMandatoryStreamData.length) { mandatoryMissing = [...mandatoryMissing, ...missingMandatoryStreamData] }
      return {
        fields: dataItem.schema,
        // version: dataItem.version
        url: (dataItem.url && dataItem.url.match(urlRegex)) ? dataItem.url : undefined,
        id: fields ? fields.id : '',
        schemaId: dataItem.schemaId,
        levelVersions: dataItem.levelVersions
      }
    })
    const configData = _.cloneDeep({ ...this.props.configSchema, position: this.props.configEditData.position, streams: tempData, configId: this.props.configEditData ? this.props.configEditData.id : null })

    if (urlValid.indexOf(false) === -1 && this.props.onSave) {
      const missingMandatoryField = (configData.fields || []).filter(item => item.isRequired && item.type !== 'BOOLEAN' && !item.value)
      if (!!missingMandatoryField.length || !!mandatoryMissing.length) {
        message.warning('Please fill all the mandatory fields')
        return
      }
      this.props.onSave(configData, this.state.fieldData)
    } else message.warning(userMessages.INVALID_URL)
  }

  onCancel = () => {
    if (this.props.onCancel) { this.props.onCancel() }
  }

  onChecked = () => {
    this.setState({ isChecked: !this.state.isChecked, isSaveEnable: true })
  }

  onEditOffset = (e) => {
    const timeRegex = /^\d*/
    const newOffset = e.target.value
    const isValid = timeRegex.test(newOffset)
    if (isValid) {
      this.setState({
        newOffset, isSaveEnable: true
      })
    }
  }

  onUrlEnter = (selectedId, url) => {
    // let isSaveEnable = false
    const { jsonInvalidEntries } = this.state
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    const configEditData = (this.state.configEditData).map((dataItem, index) => {
      if (index === selectedId) {
        // if (!isSaveEnable && url.match(urlRegex)) { isSaveEnable = true }
        return {
          name: dataItem.name,
          isActive: url ? (!!url.match(urlRegex)) && url.length > 1 && this.getSchemaValid(dataItem.schema) : this.getSchemaValid(dataItem.schema),
          url: url,
          schema: dataItem.schema,
          id: dataItem.id,
          schemaId: dataItem.schemaId,
          levelVersions: dataItem.levelVersions
        }
      } else {
        // if (!isSaveEnable && dataItem.isActive) { isSaveEnable = true }
        return {
          name: dataItem.name,
          isActive: dataItem.isActive,
          url: dataItem.url,
          schema: dataItem.schema,
          id: dataItem.id,
          schemaId: dataItem.schemaId,
          levelVersions: dataItem.levelVersions
        }
      }
    })
    const isSaveable = this.checkSaveable(configEditData, this.state.fieldData)
    this.setState({ configEditData, isSaveEnable: isSaveable && _.isEmpty(jsonInvalidEntries) })
  }

  onDataEnter = (selectedId, schemaId, value, name, type, id) => {
    const { jsonInvalidEntries } = this.state
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    // let isSaveable = false
    if (type === 'JSON') {
      let isValid = this.handleJsonValidation(id, value)
      if (!isValid) {
        this.setState({
          validJson: false
        })
      } else {
        this.setState({
          validJson: true
        })
      }
    }
    const configEditData = (this.state.configEditData).map((dataItem) => {
      // let isActive = false
      // if (!isSaveable) { isSaveable = dataItem.isActive }
      const isSelecteItem = selectedId ? dataItem.id === selectedId : dataItem.schemaId === schemaId
      if (isSelecteItem) {
        const schema = dataItem.schema.map((item) => {
          if (item.name === name) {
            // isActive = item.isRequired && value
            // if (!isSaveable) { isSaveable = isActive }
            return {
              isRequired: item.isRequired,
              name: name,
              type: item.type,
              value: value,
              id: item.id
            }
          } else {
            return {
              isRequired: item.isRequired,
              name: item.name,
              type: item.type,
              value: item.value,
              id: item.id
            }
          }
        })
        return {
          name: dataItem.name,
          id: dataItem.id,
          url: dataItem.url,
          schema: schema,
          schemaId: dataItem.schemaId,
          levelVersions: dataItem.levelVersions,
          isActive: dataItem.url ? (!!dataItem.url.match(urlRegex)) && dataItem.url.length > 1 && this.getSchemaValid(schema) : this.getSchemaValid(schema)
        }
      } else return dataItem
    })
    const isSaveable = this.checkSaveable(configEditData, this.state.fieldData)
    this.setState({ configEditData, isSaveEnable: isSaveable && _.isEmpty(jsonInvalidEntries) })
  }

  configItemClicked = (id) => {
    const index = _.findIndex(this.state.configEditData, (item) => { return item.id === id })
    this.scrollContainer.scrollTop = (this.scrollContainer.scrollHeight) / this.state.configEditData.length * index
  }

  getStreamList = (streamList, streamSchema) => {
    url = []
    return streamSchema.map((streamItem) => {
      // const schemaLevels = streamItem.streamSchema && streamItem.streamSchema.levels && streamItem.streamSchema.levels.length ? streamItem.streamSchema.levels : []
      const currespondingStreamDataIndex = (streamList || []).findIndex(item => item.streamSchema.id === streamItem.id)
      const currespondingStreamData = currespondingStreamDataIndex > -1 ? streamList[currespondingStreamDataIndex] : {}
      url.push(currespondingStreamData.url)
      let streamName = ''
      // let isActive = false
      streamItem.streamSchema.levels.map((item) => {
        streamName = streamName ? `${streamName} - ${item.name}` : item.name
      })
      const levelVersions = streamItem.streamSchema.levels.map((item) => ({ id: item.id, version: item.version }))
      const schema = streamItem.fields.map((item) => {
        const selectedField = (currespondingStreamData.fields || []).find(field => field.name === item.name && field.type === item.type)
        // isActive = selectedField && item && (item.isRequired ? selectedField.value : true)
        return selectedField ? { ...item, value: selectedField.value, id: uuidv4() } : { ...item, id: uuidv4() }
      })

      const requiredStream = (streamItem.fields || []).filter(item => item.isRequired).map(item => {
        const selectedField = (currespondingStreamData.fields || []).find(field => field.name === item.name && field.type === item.type)
        return selectedField && selectedField.value
      })
      const isInactive = (requiredStream || []).filter(item => !item)
      return {
        id: currespondingStreamData ? currespondingStreamData.id : undefined,
        schemaId: streamItem.streamSchema.id,
        name: streamName,
        schema,
        isActive: !(isInactive && isInactive.length) && (!!currespondingStreamData.url),
        url: currespondingStreamData.url,
        levelVersions
      }
    })
  }

  checkSaveable = (configEditData, fieldData) => {
    let validUrl = false
    // let itemArr = []
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    const editConfigArr = configEditData.map((configItem) => {
      validUrl = configItem.url ? (!!configItem.url.match(urlRegex)) : true
      // itemArr.push(this.getSchemaValid(configItem.schema))
      return validUrl && this.getSchemaValid(configItem.schema)
      // return validUrl && itemArr.indexOf(false) === -1
    })
    const fieldItemArr = fieldData.filter(item => item.type !== 'BOOLEAN').map(fieldItem => {
      return fieldItem.isRequired ? (!!fieldItem.value) : true
    })
    return fieldItemArr.indexOf(false) === -1 && editConfigArr.indexOf(true) > -1
  }

  getSchemaValid = (schema) => {
    const schemaArr = schema.filter(item => item.type !== 'BOOLEAN').map((item) => {
      return item.isRequired ? (!!item.value) : true
    })
    return schemaArr.indexOf(false) === -1
  }

  onSettingsDataEnter = (event, name, isChecked) => {
    const value = event.target.value ? event.target.value : isChecked
    // let isSaveable = false
    // const flag = this.state.configEditData.filter((configItem) => configItem.url)
    const fieldData = this.state.fieldData.map((item) => {
      // let isActive = false
      if (item.name === name) {
        // isActive = item.isRequired && value
        // if (!isSaveable) { isSaveable = isActive }
        return {
          isRequired: item.isRequired,
          name: name,
          type: item.type,
          value: value,
          schemaId: item.schemaId,
          id: item.id
        }
      } else {
        return {
          isRequired: item.isRequired,
          name: item.name,
          type: item.type,
          value: item.value,
          schemaId: item.schemaId,
          id: item.id
        }
      }
    })
    // this.state.isSaveEnable ? true : (flag.length ? isSaveable : false)
    const isSaveable = this.checkSaveable(this.state.configEditData, fieldData)
    this.setState({ fieldData, isSaveEnable: isSaveable })
  }

  renderSettings = () => {
    return (this.state.fieldData).map((item, index) => {
      if (item.type === 'STRING') {
        return <Input key={index} inputName={item.name} title={item.name} placeholder={`Enter ${_.capitalize(item.name)}`} value={item.value}
          handleChange={(event) => this.onSettingsDataEnter(event, item.name, undefined)} isRequired={item.isRequired} />
      } else if (item.type === 'INTEGER') {
        return <InputNumber key={index} inputName={item.name} title={item.name} placeholder={`Enter ${_.capitalize(item.name)}`} value={item.value}
          handleChange={(event) => this.onSettingsDataEnter({ target: { value: event } }, item.name, undefined)} isRequired={item.isRequired} />
      } else if (item.type === 'BOOLEAN') {
        return <div className='checkbox-container' key={index}>
          {item.isRequired ? <div className='checkbox-name'>{`${item.name} `}<span className='mandatory'>*</span>:</div> : <div className='checkbox-name'>{`${item.name}:`}</div>}
          <div className={`${(item.value === true || item.value === 'true') ? 'checkbox-checked' : 'checkbox'}`} onClick={(event) => this.onSettingsDataEnter(event, item.name, !(item.value === true || item.value === 'true'))}>
            <div className='checked' />
          </div>
        </div>
      }
    })
  }

  isValidJson = (json) => {
    try {
      if (json === '') {
        return true
      }
      JSON.parse(json)
      return true
    } catch (e) {
      return false
    }
  }

  handleJsonValidation = (item, value) => {
    const { jsonInvalidEntries } = this.state
    let isJsonValid = true
    if (_.isEmpty(value)) {
      isJsonValid = true
      let index = (jsonInvalidEntries || []).indexOf(item)
      if (index !== -1) {
        jsonInvalidEntries.splice(index, 1)
      }
    } else {
      let isValidJson = this.isValidJson(value, false)
      if (!isValidJson) {
        let index = (jsonInvalidEntries || []).indexOf(item)
        if (index === -1) {
          jsonInvalidEntries.push(item)
        }
        isJsonValid = false
      } else {
        let index = (jsonInvalidEntries || []).indexOf(item)
        if (index !== -1) {
          jsonInvalidEntries.splice(index, 1)
        }
        isJsonValid = true
      }
    }
    return isJsonValid
  }

  render () {
    const { isLoading, configSchema, isVisible } = this.props
    const { jsonInvalidEntries } = this.state
    return <Modal
      centered
      destroyOnClose
      closable={false}
      maskClosable={false}
      wrapClassName='edit-config-modal'
      afterClose={this.clearState}
      title={<div className='modal-header'>{`EDIT ${(_.keys(configSchema).length ? configSchema.name : '').toLocaleUpperCase()} GROUP CONFIG`}</div>}
      visible={isVisible}
      footer={<div className='modal-footer'>
        <div className='cancel-button' onClick={this.onCancel}><span>Cancel</span></div>
        <div className={`${this.state.isSaveEnable ? 'enable-save' : 'save-button'}`}
          onClick={(this.state.isSaveEnable && !isLoading) ? this.onSave : () => {}}>
          {isLoading ? <Button type='primary' loading block>
          Saving... </Button> : <span>Save Config Group</span>
          }</div>
      </div>}>
      <div className='edit-config-body'>
        <StreamList streamList={this.state.configEditData} itemClicked={this.configItemClicked} />
        <div className='stream-config-container' ref={(node) => { this.scrollContainer = node }}>
          { (this.state.configEditData).map((dataItem, index) => {
            return <ConfigEditAccordion key={dataItem.id} title={`${dataItem.name}`} onUrlEnter={this.onUrlEnter.bind(this, index)} fields={dataItem.schema}
              onDataEnter={this.onDataEnter.bind(this, dataItem.id, dataItem.schemaId)} url={url[index]} jsonInvalidEntries={jsonInvalidEntries} />
          }) }
        </div>
        {this.state.fieldData && this.state.fieldData.length ? <div className='config-settings'>
          <div className='settings-title'>SETTINGS</div>
          {this.renderSettings()}
        </div> : ''}
      </div>
    </Modal>
  }
}

ConfigGroupEditModal.propTypes = {
  isVisible: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  configEditData: PropTypes.object,
  isLoading: PropTypes.bool,
  fieldData: PropTypes.arrayOf(PropTypes.object)
}
