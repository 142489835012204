import React from 'react'
import PropTypes from 'prop-types'
import ArchiveIcon from './../icons/ArchiveIcon'
import './../../ui.style.scss'

const ArchiveButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button archive ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick}>
    <ArchiveIcon />
  </div>
)

ArchiveButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

ArchiveButton.defaultProps = {
  isDisabled: false
}

export default ArchiveButton
