import gql from 'graphql-tag'

export default gql(`
    mutation createAccessRoleGroup($description: String $name: String! $users:[CreateUserInput] $modules:[ModulePermissionsInput] $project: String) {
        createAccessRoleGroup(
            input: {
                name: $name
                description: $description
                users: $users
                modules: $modules
            }
            project: $project
        ){
            id
            name
            description
            createdAt
            modules{
                name
                permissions
            }
            users{
                id
                accessRoleGroups {
                    id
                }
            }
        }
    }
`)
