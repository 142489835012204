import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import './../../ui.style.scss'

class OfferDetailsModal extends Component {
    state = {
      isLoading: false
    }

    resetState = () => {
      const state = {
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, child, title } = this.props
      return (
        <Modal
          className='general-modal offer-details-modal'
          title={title}
          maskClosable={false}
          visible={isVisible}
          cancelText='Close'
          onOk={() => {}}
          onCancel={handleCancel}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='500px'
        >
          {child}
        </Modal>
      )
    }
}

OfferDetailsModal.propTypes = {
  /** visible file status of CreateCategoryModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateCategoryModal. */
  handleCancel: PropTypes.func
}

OfferDetailsModal.defaultProps = {
}

export default OfferDetailsModal
