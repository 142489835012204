import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import CrossIcon from '../../general/icons/CrossIcon'

const Option = Select.Option

const filter = (input, option, options) => {
  const currentOption = options.find(item => item.id === option.props.value)
  const returnValue = currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

const AuthorDropDown = ({ displayParam, onOptionSelect, selected, parentCompoent, options, onSearch, ...props }) => {
  const optionComponents = options.map((option, index) =>
    (
      <Option className='author-drop-down-option' key={`option${index}`} value={option.id}>
        <img src={option.media && option.media.length && option.media[ 0 ] ? generateCroppedThumbnail(option.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
        <p>{ option[ displayParam ] }</p>
      </Option>
    ))

  const selectedValue = selected && selected[ displayParam ] ? (
    <React.Fragment>
      <img src={selected.media && selected.media.length && selected.media[ 0 ] ? generateCroppedThumbnail(selected.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
      <p>{ selected[ displayParam ] }</p>
    </React.Fragment>
  ) : undefined

  const onAuthorSearch = (value) => {
    if (onSearch) { onSearch(value) }
  }

  const onBlur = () => {
    if (onSearch) { onSearch('') }
  }

  const onSelectOption = (id) => {
    if (onSearch) { onSearch('') }
    onOptionSelect(id)
  }

  return (
    <Select key={JSON.stringify(selected)} showSearch className='author-drop-down'
      allowClear
      suffixIcon={<ExpandedIcon />}
      clearIcon={<CrossIcon />}
      placeholder='Select author' value={selectedValue} onChange={onSelectOption}
      optionFilterProp={displayParam} filterOption={(input, option) => filter(input, option, options)}
      getPopupContainer={() => document.getElementById(parentCompoent)} {...props}
      onSearch={onAuthorSearch} onBlur={onBlur}>
      { optionComponents }
    </Select>
  )
}

AuthorDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** parent container id name of Dropdown. */
  parentCompoent: PropTypes.string,
  /** Selected value of Dropdown. */
  selected: PropTypes.object,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func
}

AuthorDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default AuthorDropDown
