import React from 'react'
import PropTypes from 'prop-types'
import { Spin, Icon } from 'antd'
import './../../ui.style.scss'
import { ReactComponent as PlayIcon } from './../../../../assets/images/play.svg'

const PlayButton = ({ isDisabled, isLoading, onClick, isPlaying, ...props }) => (
  <div className={`play-button ${isDisabled ? 'disabled' : ''} ${isPlaying ? 'playing' : ''}`} onClick={onClick} >
    { isLoading ? <Spin indicator={<Icon type='loading' style={{ fontSize: 24, color: '#FF015A' }} spin />} /> : <PlayIcon /> }
  </div>
)

PlayButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

PlayButton.defaultProps = {
  isDisabled: false
}

export default PlayButton
