import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Skeleton, Spin } from 'antd'
//
import AppContext from '../../AppContext'

import DrmManualGeneralDetails from './DrmManualGeneralDetails'
import DrmChannelDetails from './DrmChannelDetails'
import DrmGeneralDetails from './DrmGeneralDetails'
import LoadingButton from './../../components/ui/general/buttons/LoadingButton'
import Accordion from './../../components/ui/dataDisplay/Accordion'

import { withApollo } from '@apollo/client/react/hoc'

import _, { flowRight as compose } from 'lodash'

const width = [ '100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%' ]

function DrmDetails ({ source, onContentKeyChange, onIvChange, onDrmEntitlementChange, onSelectStreamType, onChangeDuration, state, channelState, channelList, onSelectChannel, channel, streamList, onGenerateToken, token, onClearData, isTokenGenerating, tempStreamList, onChannelStreamSelect, isChannelLoading, onConfigGroupSelect }) {
  return (
    <AppContext.Consumer>
      {({ permissions }) => {
        const userPermissions = permissions['DRM_MANAGER']
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        return <React.Fragment>
          <div className='container' id='drm-container' ref={(node) => { }}>
            {source && source.id === 'manual' ? <Accordion
              title='Manual'
              childComponent={
                <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                  loading={false}>
                  {state
                    ? <DrmManualGeneralDetails
                      state={state}
                      onDrmEntitlementChange={onDrmEntitlementChange}
                      onIvChange={onIvChange}
                      onSelectStreamType={onSelectStreamType}
                      onChangeDuration={onChangeDuration}
                      onContentKeyChange={onContentKeyChange}
                      streamList={streamList}
                      token={token}
                      isUpdateDisabled={isUpdateDisabled}
                    /> : null}
                </Skeleton>
              }
            /> : null}
            {source && source.id === 'cmsChannelManager'
              ? <Accordion
                title='CMS'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={false}>
                    {channelState ? <Spin spinning={isChannelLoading === true} delay={500}><DrmChannelDetails
                      channelList={channelList}
                      onSelectChannel={onSelectChannel}
                      channel={channel}
                      channelState={channelState}
                      tempStreamList={tempStreamList}
                      onChannelStreamSelect={onChannelStreamSelect}
                      onConfigGroupSelect={onConfigGroupSelect}
                      isUpdateDisabled={isUpdateDisabled}
                    /></Spin> : null}
                  </Skeleton>
                }
              />
              : null}
            {source && source.id === 'cmsChannelManager'
              ? <Accordion
                title='CMS'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={false}>
                    {channelState ? <DrmGeneralDetails
                      isUpdateDisabled={isUpdateDisabled}
                      state={state}
                      token={token}
                      onChangeDuration={onChangeDuration}
                    /> : null}
                  </Skeleton>
                }
              />
              : null}

          </div>
          {state || channelState ? <div className='drm-details-footer'>
            <div className='right'>
              {source && source.id ? <div style={{ width: '150px', marginLeft: '10px' }}>
                <LoadingButton type='primary' onClick={onGenerateToken} htmlType='submit' buttonText='Generate Token' buttonClass='save-btn' isLoading={isTokenGenerating} isDisabled={isUpdateDisabled || (_.isEmpty(state.streamType) && _.isEmpty(channelState.stream))} />
              </div> : null}
              {source && source.id ? <div style={{ width: '150px' }}>
                <LoadingButton type='primary' onClick={onClearData} htmlType='submit' buttonText='Clear' buttonClass='clr-btn' isLoading={false} isDisabled={!token || isUpdateDisabled} />
              </div> : null}
            </div>
          </div> : null}
        </React.Fragment>
      }}
    </AppContext.Consumer>
  )
}

DrmDetails.propTypes = {
  /** is edited details status of AssetDetails. */
  isEdited: PropTypes.bool

}

// export default AssetDetails
export default withApollo(compose(
)(withRouter(withApollo(DrmDetails))))
