import React from 'react'
import './../ui.style.scss'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'

import { generateCroppedThumbnail } from '../../../util/util'
import OfferListCellData from './OfferListCellData'
import placeholderImage from './../../../assets/images/default-image.jpg'
import UpdatedIcon from './../general/icons/UpdatedIcon'
import MultilineEllipsis from './../../MultilineEllipse'

class OfferListCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

    getAssetIcon = (assetType, status, listAssetTypes) => {
      const selectedType = (listAssetTypes || []).find(item => item.title === assetType)
      return (selectedType && selectedType.icon) ? <img src={generateCroppedThumbnail((status === 'PUBLISHED' || status === 'PARTIAL') ? selectedType.icon : selectedType.draftIcon, 18, 18, '1:1')} /> : null
    }

    onClick=(offerId) => {
      if (this.props.manager === 'offer') { this.props.onSelect(offerId) } else if (this.props.manager === 'partner') {
        window.open(`${location.origin}/offers/${offerId}`, '_blank')
      }
    }

    onCheck = e => {
      e.preventDefault()
      e.stopPropagation()
      this.props.onCheck(this.props.offerId)
    }

    selectedLink = (e) => {
      e.stopPropagation()
    }

    render () {
      const { imageUrl, isSelected, partnerName, offerName, offerId, shortDescription, isArchival, isEditted, isChecked, externalId } = this.props
      const image = imageUrl || placeholderImage
      return (
        <div
          className={`offer-list-cell ${isSelected ? 'active' : ''} 
                  ${isArchival ? 'archive' : ''}`}
          onClick={() => this.onClick(offerId)}
        >
          {isEditted ? <div className='offer-edit'> <UpdatedIcon /> </div> : null }
          { isArchival ? (<Checkbox checked={isChecked} onClick={this.onCheck} />) : null }
          <div className='offer-thumb' style={{ backgroundImage: `url(${image})` }} />
          <div className='details'>
            <OfferListCellData
              partnerName={partnerName}
              offerName={offerName}
              shortDescription={shortDescription}
            />
          </div>
          <a className='offer-id' target='_blank' onClick={this.selectedLink} href={`${location.origin}/offers/${offerId}`}><MultilineEllipsis maxLines={'1'} text={externalId || ''} /></a>
        </div>
      )
    }
}

OfferListCell.propTypes = {
  /** Partner image url of OfferListCell. */
  imageUrl: PropTypes.string,
  /** Name of associated Partner. */
  partnerName: PropTypes.string,
  /** Id of selected Offer. */
  offerId: PropTypes.string,
  /** short description of selected Offer. */
  shortDescription: PropTypes.string,
  /** Name of selected Offer */
  offerName: PropTypes.string,
  /** Partner checkbox status OfferListCell. */
  isChecked: PropTypes.bool,
  /** Partner archival status of OfferListCell */
  isArchival: PropTypes.bool,
  /** Partner selection status of OfferListCell */
  isSelected: PropTypes.bool,
  /** Click selection action of OfferListCell. */
  onSelect: PropTypes.func,
  /** onCheck selection action of OfferListCell. */
  onCheck: PropTypes.func,
  /** Parent component name of OfferListCell. */
  manager: PropTypes.string
}

export default OfferListCell
