import gql from 'graphql-tag'

export default gql(`
query listRating( $limit: Int $offset: Int $project: String) {
    listRating( 
      limit: $limit
      offset: $offset
      project: $project
    ){
        totalCount
        items {
          rating
          id
          scheme
          subRatings
          source
          assetCount
        }
      }
}`)
