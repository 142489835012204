import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Row, Col, message } from 'antd'
import NProgress from 'nprogress'
import moment from 'moment'

import AssetDetails from './AssetDetails'
import AssetSideBar from './AssetSideBar'
import userMessages from './../../constants/messages'
import LoggerService from './../../services/LoggerService'
import AppContext from '../../AppContext'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'

import { utilityService } from './../../services/UtilityService'
import AuthService from '../../services/AuthService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryLanguageDetails from '../../graphQL/asset/hyperion/listLanguageDetails'
import QueryRatingDetails from '../../graphQL/asset/hyperion/listRatingDetails'
import QueryCountryDetails from '../../graphQL/asset/hyperion/listCountryDetails'
import QueryListGenreDetails from '../../graphQL/asset/hyperion/listGenresDetails'
import QueryProductionStudio from '../../graphQL/asset/hyperion/listProductionStudios'
import QueryPersonDetails from '../../graphQL/asset/hyperion/listPersonsDetails'
import QueryRoleDetails from '../../graphQL/asset/hyperion/listRolesDetails'
import QueryGetAssetDetails from './../../graphQL/asset/getAssetDetails'
import QueryMediaCategory from './../../graphQL/content/listMediaCategories'
import QueryGetImageType from './../../graphQL/admin/media/listImageType'
import QueryGetUpdated from './../../graphQL/asset/getUpdatedTime'
import MutationUpdateCredits from '../../graphQL/asset/hyperion/updateAssetCreditLinks'
import MutationUpdateSeries from '../../graphQL/asset/hyperion/updateSeasonSeriesLink'
import MutationUpdateSeason from '../../graphQL/asset/hyperion/updateEpisodeSeasonLink'
import MutationDeleteSeries from '../../graphQL/asset/hyperion/deleteSeriesSeasons'
import MutationDeleteSeason from '../../graphQL/asset/hyperion/deleteSeasonEpisodes'
import MutationUpdateArticle from './../../graphQL/asset/updateAssetDetails'
import MutationAddImageToAsset from './../../graphQL/asset/addImageToAsset'
import MutationUpdateAssetImage from './../../graphQL/asset/updateAssetImage'
import MutationUpdateAssetVideo from './../../graphQL/asset/updateAssetVideo'
import MutationAddVideoToAsset from './../../graphQL/asset/addAssetVideos'
import MutationDeleteAssetVideo from './../../graphQL/asset/deleteAssetVideos'
import MutationDeleteAssetImage from './../../graphQL/asset/deleteAssetImages'
import MutationRearrangeContent from './../../graphQL/asset/batchUpdateContentItem'
import QueryGetSlug from './../../graphQL/asset/getSeoSlugItem'
import MutationLinkAssetProgram from './../../graphQL/asset/linkAssetProgram'
import MutationCreateMedia from './../../graphQL/content/createMedia'
import QueryGetVodStatus from '../../graphQL/asset/getAssetVodDetails'
import QueryUploadImage from '../../graphQL/asset/getUploadUrl'
import SubscriptionAssetDetails from '../../graphQL/asset/updateAssetSubscription'
import MutationTriggerVOD from './../../graphQL/asset/triggerLiveToVod'
import ApiService from './../../services/ApiService'

let subscribeAsset = null

const subscribeChangeInAsset = (subscribeToMore, props, assetId, retrying = 50) => {
  const id = assetId || props.ownProps.assetId
  return subscribeToMore({
    document: SubscriptionAssetDetails,
    variables: { id },
    updateQuery: (previous, { subscriptionData: { data: { assetUpdated } } }) => {
      if (!assetUpdated || assetUpdated.id !== id) return previous
      let prev = cloneDeep(previous)
      if (_.isEmpty(prev)) {
        prev = {
          getAsset: assetUpdated,
          details: assetUpdated
        }
      } else {
        prev.details = assetUpdated
        prev.getAsset = assetUpdated
      }
      return prev
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeChangeInAsset(subscribeToMore, props, id, retrying * 2) }, retrying)
      }
    }
  })
}

let primaryImageType

class AssetEditor extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    this.mediaRefreshCount = 0
    this.state = {
      details: undefined,
      isSaving: false,
      isEdited: false,
      status: '',
      isLoading: false,
      shouldShowWarning: false,
      shouldShowHyperionWarning: false,
      shouldShowSeriesWarning: false,
      isModified: false,
      isModifiedConfirm: false,
      isConfirmLoading: false,
      isMetaDescEdited: false,
      isAssetImageLoading: false,
      isAssetVideoLoading: false,
      activeImageType: primaryImageType,
      isVodTriggerSuccess: false,
      lastUpdatedUser: '',
      addedMediaList: [],
      removedMediaList: [],
      uploadingFiles: [],
      currentMatch: props.details && props.details.match ? props.details.match : {},
      tempProductionList: [], // tempList to keep track of all searched values for produtionStudio
      shouldShowCategoryWarning: false,
      selectedAssetImageCategory: '',
      selectedImageId: '',
      canTriggerAppleNewsURL: true
    }
    this.shouldDisableAutoSave = false
    this.updatedContents = []
    this.updatedContentsDirectly = []
    this.isSavingFlag = true
    this.firstTimeFlag = true
  }

  componentDidMount = () => {
    this.autoSaveInterval = setInterval(this.autoSaveDetails, 20000)
    this.getUserDetails()
    this.initiateSubscription()
    if (this.props.details) {
      let contents = (this.props.details.contents || []).filter(item => item).sort((a, b) => a.position - b.position)
      contents = _.map(contents, _.clone)
      const tempDetailsProps = _.cloneDeep(this.props.details)
      this.setState({ details: { ...tempDetailsProps, contents }, isLoading: false })
      NProgress.done()
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.assetId !== this.props.assetId && newProps.assetId) {
      this.isSavingFlag = true
      this.slugEdited = false
      this.invalidTitle = false
      this.isBlockingUrlValidation = false
      this.invalidSlug = false
      this.changeEditStatus(false)
      this.updatedContents = []
      this.updatedContentsDirectly = []
      this.changedIsLive = false
      this.setState({ isLoading: true, isOldSlug: false, isMetaDescEdited: false })
      this.initiateSubscription(newProps.assetId)
      this.urlSlug = undefined
    } else if (!newProps.assetId) {
      this.setState({ isLoading: false })
    }
    if (newProps.shouldSave && !this.props.shouldSave) {
      this.submitAssetDetails()
      this.isBlockingUrlValidation = false
    }
    if (newProps.shouldDiscard) { this.invalidateCache() }
    if (newProps.details && !_.isEqual(newProps.details, this.props.details) && !this.state.isEdited) {
      let contents = [...(newProps.details.contents || [])].filter(item => item).sort((a, b) => a.position - b.position).map(item => { return { ...item } })
      contents = _.map(contents, _.clone)
      const { tempProductionList } = this.state
      let newProductionList = _.cloneDeep(tempProductionList)
      if (newProps.details.productionStudios && newProps.details.productionStudios.length) {
        if (_.isEmpty(tempProductionList)) {
          newProductionList = newProps.details.productionStudios
        } else if (!_.isEmpty(tempProductionList) || tempProductionList.length > 0) {
          // newProductionList=tempProductionList;
          (newProps.details.productionStudios || []).map(item => {
            const index = (tempProductionList || []).findIndex(innerItem => innerItem && (innerItem.id === item.id))
            if (index < 0) {
              newProductionList.push(item)
            }
          })
        }
      }
      this.setState({ details: _.cloneDeep({ ...newProps.details, contents }), isLoading: false, shouldRefreshHistory: true, tempProductionList: newProductionList }, this.clearRefreshHistory)
      // this.handleVodStatus(newProps.details)
      this.isSavingFlag = false
      NProgress.done()
    } else if (newProps.details && !_.isEqual(newProps.details, this.props.details) && this.props.details && newProps.details.id === this.props.details.id) {
      // if (!_.isEqual(newProps.details.contents, this.props.details.contents) || !_.isEqual(newProps.details.media, this.props.details.media)) {
      //   const isLatest = moment(newProps.details.updatedAt).isAfter(moment(this.state.details.updatedAt), 'seconds')
      //   if (!isLatest) {
      //     return
      //   }
      // }

      // dummy commit
      const isLatest = moment(newProps.details.updatedAt).isAfter(moment(this.state.details.updatedAt), 'seconds')
      if (isLatest) {
        let stateAssetDetails = _.cloneDeep(this.props.details)
        let newAssetDetails = _.cloneDeep(newProps.details)
        delete (stateAssetDetails.program)
        delete (stateAssetDetails.vodJobs)
        delete (stateAssetDetails.media)
        delete (stateAssetDetails.images)
        delete (stateAssetDetails.videos)
        delete (stateAssetDetails.updatedAt)
        delete (stateAssetDetails.updatedBy)
        delete (stateAssetDetails.defaultVideo)
        delete (stateAssetDetails.defaultMedia)
        delete (stateAssetDetails.duration)
        delete (stateAssetDetails.markInTime)
        delete (stateAssetDetails.markOutTime)
        delete (stateAssetDetails.isInTransition)
        delete (stateAssetDetails.isArchived)
        delete (stateAssetDetails.status)
        delete (newAssetDetails.program)
        delete (newAssetDetails.vodJobs)
        delete (newAssetDetails.media)
        delete (newAssetDetails.images)
        delete (newAssetDetails.videos)
        delete (newAssetDetails.updatedAt)
        delete (newAssetDetails.updatedBy)
        delete (newAssetDetails.defaultVideo)
        delete (newAssetDetails.defaultMedia)
        delete (newAssetDetails.duration)
        delete (newAssetDetails.markInTime)
        delete (newAssetDetails.markOutTime)
        delete (newAssetDetails.isInTransition)
        delete (newAssetDetails.isArchived)
        delete (newAssetDetails.status)
        stateAssetDetails.tags = _.sortBy(stateAssetDetails.tags, 'key')
        newAssetDetails.tags = _.sortBy(newAssetDetails.tags, 'key')
        if (_.isEqual(stateAssetDetails, newAssetDetails)) {
          let isUpdated = false
          const tempAssetDetails = _.cloneDeep(this.state.details)
          if (!_.isEqual(newProps.details.program, this.state.details.program)) {
            tempAssetDetails.program = newProps.details.program
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.vodJobs, this.state.details.vodJobs)) {
            tempAssetDetails.vodJobs = newProps.details.vodJobs
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.defaultVideo, this.state.details.defaultVideo)) {
            tempAssetDetails.defaultVideo = newProps.details.defaultVideo
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.defaultMedia, this.state.details.defaultMedia)) {
            tempAssetDetails.defaultMedia = newProps.details.defaultMedia
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.duration, this.state.details.duration)) {
            tempAssetDetails.duration = newProps.details.duration
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.markOutTime, this.state.details.markOutTime)) {
            tempAssetDetails.markOutTime = newProps.details.markOutTime
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.markInTime, this.state.details.markInTime)) {
            tempAssetDetails.markInTime = newProps.details.markInTime
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.isInTransition, this.state.details.isInTransition)) {
            tempAssetDetails.isInTransition = newProps.details.isInTransition
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.isArchived, this.state.details.isArchived)) {
            tempAssetDetails.isArchived = newProps.details.isArchived
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.status, this.state.details.status)) {
            tempAssetDetails.status = newProps.details.status
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.images, this.state.details.images)) {
            tempAssetDetails.images = newProps.details.images
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.videos, this.state.details.videos)) {
            tempAssetDetails.videos = newProps.details.videos
            isUpdated = true
          }
          if (!_.isEqual(newProps.details.media, this.props.details.media)) {
            const newVideoList = (newProps.details.media || []).filter(item => {
              // const tempIndex = (tempAssetDetails.media ||[]).findIndex(innerMedia => innerMedia.id === item.id)
              const isDeleted = this.state.removedMediaList.includes(item.id)
              return !isDeleted
            })
            const newMediaStateList = (tempAssetDetails.media || []).filter(item => {
              // const tempIndex = (newProps.details.media ||[]).findIndex(innerMedia => innerMedia.id === item.id)
              const isAddedMedia = this.state.addedMediaList.includes(item.id)
              return isAddedMedia
            })
            tempAssetDetails.media = [...newVideoList, ...newMediaStateList]
            isUpdated = true
            // this.setState({ addedMediaList: [], removedMediaList: [] })
          }
          if (isUpdated) { tempAssetDetails.updatedAt = newProps.details.updatedAt }
          this.setState({ details: tempAssetDetails })
        } else {
          LoggerService.info('session_id', `Updating user session id`, sessionStorage.getItem('session_id'))
          this.setState({ isModified: true, shouldRefreshHistory: true, tempAssetDetails: newProps.details, lastUpdatedUser: newProps.details.updatedBy }, this.clearRefreshHistory)
        }
      } else {
        if (!_.isEqual(newProps.details.vodJobs, this.state.details.vodJobs) || !_.isEqual(newProps.details.images, this.state.details.images) || !_.isEqual(newProps.details.videos, this.state.details.videos)) {
          const tempAssetDetails = _.cloneDeep(this.state.details)
          tempAssetDetails.vodJobs = !_.isEqual(newProps.details.vodJobs, this.state.details.vodJobs) ? newProps.details.vodJobs : tempAssetDetails.vodJobs
          tempAssetDetails.images = !_.isEqual(newProps.details.images, this.state.details.images) ? newProps.details.images : tempAssetDetails.images
          tempAssetDetails.videos = !_.isEqual(newProps.details.videos, this.state.details.videos) ? newProps.details.videos : tempAssetDetails.videos
          tempAssetDetails.updatedAt = newProps.details.updatedAt
          this.setState({ details: tempAssetDetails })
        }
      }
      // const modifiedcontent = this.getUpdatedDetails(newProps.details, this.state.details)
      // const { status } = newProps.details
      // this.setState({ details: { ...this.state.details, ...modifiedcontent, status }, isLoading: false })
      // this.isSavingFlag = false
      // this.handleVodStatus(newProps)
      // NProgress.done()
    } else if (newProps.details && (!this.props.details || newProps.details.id !== this.props.details.id)) {
      this.setState({ details: { ...newProps.details }, shouldRefreshHistory: true, isLoading: false })
      this.isSavingFlag = false
      // this.handleVodStatus(newProps.details)
      NProgress.done()
    }
    if (!newProps.assetIsLoading && this.props.assetIsLoading && !newProps.details) {
      if (newProps.assetId && !newProps.detailsError) {
        message.error(userMessages.INVALID_ASSET)
      } else {
        const errorMessage = newProps.detailsError ? newProps.detailsError : 'Something went wrong. Please try again later'
        message.error(errorMessage)
      }
      this.setState({ isLoading: false, details: undefined })
      NProgress.done()
    }

    const { uploadData, isUploadError } = newProps
    if (isUploadError && !this.props.isUploadError) {
      this.setState({ uploadingFiles: [] })
    }
    if (uploadData && !_.isEqual(uploadData, this.props.uploadData)) {
      if (uploadData.length > 0) {
        this.uploadImageApi(uploadData[0], uploadData, uploadData.length, 0)
        // uploadData.map((item) => {
        //   this.uploadImageApi(_.cloneDeep(item))
        // })
      }
    }
    // check to add all listProdutionAPI response to tempList
    if (newProps.listProductionStudio && !_.isEqual(newProps.listProductionStudio, this.props.listProductionStudio)) {
      const { tempProductionList } = this.state
      let newProductionList = []
      if (_.isEmpty(tempProductionList)) {
        newProductionList = newProps.listProductionStudio
      } else if (!_.isEmpty(tempProductionList) || tempProductionList.length > 0) {
        newProductionList = tempProductionList;
        (newProps.listProductionStudio || []).map(item => {
          const index = (tempProductionList || []).findIndex(innerItem => innerItem && (innerItem.id === item.id))
          if (index < 0) {
            newProductionList.push(item)
          }
        })
      }
      this.setState({ tempProductionList: newProductionList })
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.autoSaveInterval)
    clearInterval(this.vodInterval)
    clearTimeout(this.mediaRefreshTimeout)
  }

  initiateSubscription = (assetId) => {
    if (this.props.subscribeToChangeInAsset) {
      if (subscribeAsset) { subscribeAsset() }
      subscribeAsset = this.props.subscribeToChangeInAsset(assetId)
    } else {
      setTimeout(() => {
        this.initiateSubscription(assetId)
      }, 1500)
    }
  }

  updateMediaDetails = (updatedMedia, isVideo) => {
    let { details } = this.state
    let { images, videos } = details
    if (isVideo) {
      if (videos && videos.length) {
        const index = videos.findIndex(item => item.id === updatedMedia.id)
        if (index > -1) {
          videos[index] = updatedMedia
          details.videos = videos
          this.setState({ details })
        }
      }
    } else {
      if (images && images.length) {
        const index = images.findIndex(item => item.id === updatedMedia.id)
        if (index > -1) {
          images[index] = updatedMedia
          details.images = images
          this.setState({ details })
        }
      }
    }
  }

  onImageTypeChange = (activeImageType) => {
    this.setState({ activeImageType })
  }

  onAddImageFromLocal = (data, isImage, imageType, cropPos) => {
    if (isImage) {
      const { uploadingFiles } = this.state
      const dataList = [...data.files]
      const tagList = [...data.tags]
      for (let i = 0; i < dataList.length; i++) {
        const contentType = dataList[i].type
        const fileName = dataList[i].name
        const fileSize = dataList[i].size
        if (contentType && fileName) {
          const newFile = {
            fileName,
            fileSize,
            aspectRatio: [],
            contentType,
            percentage: 0,
            id: fileName,
            file: dataList[i],
            tagList,
            imageType,
            cropPos
          }

          uploadingFiles.splice(0, 0, newFile)
        }
      }
      this.props.onChangeIsImageUploading('ADD')
      this.setState({ uploadingFiles })
      // this.setState({ uploadingFiles, isEdited: true })
      // this.changeEditStatus(true)
    }
    this.props.handleFileUpload(data, isImage)
  }

  uploadImageApi = (uploadData, dataArr, uploadSize, uploadIndex) => {
    const { fileName, contentType, key, uploadUrl, category } = uploadData
    const successCallBack = () => {
      const { uploadingFiles } = this.state
      const index = uploadingFiles.findIndex(image => image.id === key)
      const tags = (uploadingFiles[index].tagList || []).map(tag => tag.key)
      this.props.createMedia(key, fileName, 'IMAGE', tags).then(response => {
        // message.success(`Created media ${fileName} successfully`)
        // this.props.onSelectImage(this.state.imageList, response.data.createMedia.id)
        // const { media } = details
        // const updatedMedia = [response.data.createMedia, ...(media || [])]
        // details.media = updatedMedia
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        const newMedia = cloneDeep(response.data.createMedia)
        newMedia.imageType = uploadingFiles[selectedImageIndex].imageType
        newMedia.cropPos = uploadingFiles[selectedImageIndex].cropPos
        this.onMediaAdd([newMedia], key, category)
        if (uploadSize !== (uploadIndex + 1)) {
          setTimeout(() => {
            this.uploadImageApi(dataArr[uploadIndex + 1], dataArr, uploadSize, uploadIndex + 1)
          }, 300)
        }
        // if (selectedImageIndex > -1) {
        //   uploadingFiles.splice(selectedImageIndex, 1)
        // }
        // this.setState({ uploadingFiles })
        // this.updateCache('media', updatedMedia)
      }, error => {
        this.props.onChangeIsImageUploading('REMOVE')
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        if (selectedImageIndex > -1) {
          uploadingFiles.splice(selectedImageIndex, 1)
        }
        this.setState({ uploadingFiles })
        if (uploadSize !== (uploadIndex + 1)) {
          this.uploadImageApi(dataArr[uploadIndex + 1], dataArr, uploadSize, uploadIndex + 1)
        }
        utilityService.handleError(error)
      })
    }

    const failureCallBack = () => {
      const { uploadingFiles } = this.state
      message.success(`Failed to create media ${fileName}`)
      const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
      if (selectedImageIndex > -1) {
        uploadingFiles.splice(selectedImageIndex, 1)
      }
      this.setState({ uploadingFiles })
    }

    const progressCallBack = progress => {
      const { uploadingFiles } = this.state
      const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
      if (selectedImageIndex > -1) {
        uploadingFiles[selectedImageIndex].percentage = progress
      }
      this.setState({ uploadingFiles })
    }
    const { uploadingFiles } = this.state
    const selectedImageIndex = uploadingFiles.findIndex(image => image.id === fileName)
    if (selectedImageIndex > -1) {
      uploadingFiles[selectedImageIndex].id = key
      uploadingFiles[selectedImageIndex].isUploading = true
      const notUploadedFile = uploadingFiles.find(item => !item.isUploading)
      if (!notUploadedFile) {
        this.props.clearFileName()
      }
      this.setState({ uploadingFiles })
      ApiService('PUT', uploadUrl, uploadingFiles[selectedImageIndex].file, { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
    }
  }

  handleVodStatus = details => {
    // if (!props.vodId && props.details && props.details.vodJobs) {
    //   this.props.changedVodId(props.details.vodStatus.id)
    // }

    if ((details.isInTransition && !this.vodInterval && details.vodJobs && details.vodJobs.length) || (this.props.details && details.id !== this.props.details.id)) {
      clearInterval(this.vodInterval)
      this.vodInterval = undefined
      if (details.isInTransition) {
        if ((this.props.details && details.id !== this.props.details.id)) {
          if (this.props.fetchVodStatus) {
            this.props.fetchVodStatus(details.id)
          }
        }
        this.vodInterval = setInterval(() => this.getVodStatus(details.id), 15 * 1000)
      }
    }
  }

  // fetchAssetStatus = () => {
  //   this.props.fetchAssetDetails().then(({ data }) => {
  //     const { vodStatus } = data.getAsset
  //     if (vodStatus.status !== 'COMPLETE') {
  //       setTimeout(this.fetchAssetStatus, 2000)
  //     }
  //   })
  // }

  getVodStatus = id => {
    if (this.props.fetchVodStatus) {
      this.props.fetchVodStatus(id).then(({ data }) => {
        let { details } = this.state
        const { vodJobs } = details
        const isInTransition = (vodJobs || []).findIndex(item => item.status !== 'PUBLISHED' && item.status !== 'ERROR') > -1
        if (!isInTransition || !vodJobs || !vodJobs.length) {
          clearInterval(this.vodInterval)
          this.vodInterval = undefined
          if (!vodJobs || !vodJobs.length) {
            return
          }
          this.mediaRefreshTimeout = setTimeout(() => {
            this.mediaRefreshCount++
            if (this.mediaRefreshCount === 6) {
              this.mediaRefreshCount = 0
              clearTimeout(this.mediaRefreshTimeout)
              clearInterval(this.vodInterval)
              this.vodInterval = undefined
            } else {
              this.getVodStatus(this.props.assetId)
            }
          }, 10 * 1000)
        }
        // if (!vodStatus || !details.vodStatus || vodStatus === 'UNDEFINED') {
        //   return
        // }
        // if (vodStatus && vodStatus.status === 'PUBLISHED' && this.firstTimeFlag) {
        //   this.firstTimeFlag = false
        //   this.props.fetchAssetDetails().then(({ data }) => {
        //     const status = data.getAsset.vodStatus ? data.getAsset.vodStatus.status : ''
        //     if (status && status !== 'COMPLETE') { setTimeout(this.fetchAssetStatus, 2000) } else if (this.props.fetchVodStatus) { this.props.fetchVodStatus(id) }
        //   })
        //   // message.success(userMessages.VOD_COMPLETE_SUCCESS)
        //   clearInterval(this.vodInterval)
        //   details.vodStatus = vodStatus
        //   this.setState({ details })
        // } else if (details.vodStatus.status !== vodStatus.status) {
        //   details.vodStatus = vodStatus
        //   this.setState({ details })
        // }
      })
    }
  }

  onVodTrigger = status => {
    let { details } = this.state
    // this.props.changedVodId(status.id)
    this.firstTimeFlag = true
    details.vodStatus = status
    details.isInTransition = true
    if (this.vodInterval) {
      clearInterval(this.vodInterval)
      this.vodInterval = undefined
    }
    // this.vodInterval = setInterval(() => this.getVodStatus(this.props.assetId), 15 * 1000)
    // this.props.fetchVodStatus(details.id).then(() => {
    //   details.updatedAt = moment()
    //   this.setState({ details })
    // })
  }

  updateCache = (key, value) => {
    const { project } = this.props
    const variables = { id: this.props.assetId, project }

    try {
      const getCacheData = this.props.client.readQuery({ query: QueryGetAssetDetails, variables })
      if (getCacheData && getCacheData.getAsset) {
        getCacheData.getAsset[key] = value
      }
      this.props.client.writeQuery({
        query: QueryGetAssetDetails,
        data: getCacheData,
        variables
      })
    } catch (error) {
      console.warn('cache update did not work', error)
    }
  }

  invalidateCache = () => {
    const variables = { id: this.props.assetId }
    try {
      const cache = this.props.client.cache
      cache.data.delete(`Asset:${variables.id}`)
    } catch (error) {
      console.warn('cache update did not work', error)
    }
  }

  getUserDetails = async () => {
    const userDetails = await AuthService.getUserDetails()
    if (userDetails) {
      this.username = userDetails.name || userDetails.email
    }
  }

  clearRefreshHistory = () => {
    this.setState({ shouldRefreshHistory: false })
  }

  onInputFieldFocus = () => {
    this.isEditingInput = true
  }

  onInputFieldBlur = (e) => {
    if (!this.isEditingInput) {
      return
    }
    const { details } = this.state
    const defaultSlug = utilityService.slugifyString(`${details.id}-untitled`)
    const isFirstSlug = details.slug && (details.slug.indexOf('untitled-') === 0 || details.slug.indexOf(defaultSlug) === 0)
    if (isFirstSlug && details.title !== 'Untitled') {
      const newTitle = details.type === 'ARTICLE' ? details.title.toLowerCase() : `${details.id}-${details.title.toLowerCase()}`
      const slug = utilityService.slugifyString(newTitle)
      details.slug = slug
      this.setState({ details })
    }
    this.isEditingInput = false
  }

  getUpdatedDetails = (updatedData, currentData) => {
    const updatedContents = (updatedData.contents || []).filter(item => item)
    const currentContents = (currentData.contents || []).filter(item => item)
    const updatedMedia = (updatedData.media || []).filter(item => item)
    const currentMedia = (currentData.media || []).filter(item => item)
    const newMedias = currentData.isInTransition && !updatedData.isInTransition ? _.differenceBy(updatedMedia, currentMedia, 'id') : []
    const modifiedCurrentMedia = currentMedia.map(media => {
      const modifiedIndex = updatedMedia.findIndex(item => item.id === media.id)
      return modifiedIndex > -1 ? updatedMedia[modifiedIndex] : media
    })
    const modifiedMedia = [ ...newMedias, ...modifiedCurrentMedia ]

    const modifiedCurrentContents = currentContents.map(content => {
      const modifiedIndex = updatedContents.findIndex(item => item.id === content.id)
      const isModifiedDefaultImageContent = modifiedIndex > -1 && content.type === 'DEFAULT_IMAGE' && updatedContents[modifiedIndex].media && content.media && updatedContents[modifiedIndex].media.id === content.media.id
      return modifiedIndex > -1 && (isModifiedDefaultImageContent || content.type === 'IMAGE') ? updatedContents[modifiedIndex] : content
    })
    let modifiedcontent = { contents: modifiedCurrentContents, media: modifiedMedia }
    if (currentData.isInTransition && !updatedData.isInTransition) {
      modifiedcontent.defaultVideo = updatedData.defaultVideo
      modifiedcontent.isLive = updatedData.isLive
    }
    if (updatedData.isInTransition !== null) {
      modifiedcontent.isInTransition = updatedData.isInTransition
    }
    if (updatedData.vodJobs && updatedData.vodJobs.length) {
      modifiedcontent.vodJobs = updatedData.vodJobs
    }
    return modifiedcontent
  }

  checkUrlValidation = assets => {
    if (assets && assets.length) {
      if (assets.length > 1) {
        if (this.state.isSaving) {
          this.setState({ isSaving: false })
          this.changeErrorStatus(true, true)
          message.error(userMessages.DUPLICATE_SLUG)
        }
        this.setState({ isOldSlug: true })
      } else if (assets[0].id !== this.props.assetId) {
        if (this.state.isSaving) {
          this.setState({ isSaving: false })
          this.changeErrorStatus(true, true)
          message.error(userMessages.DUPLICATE_SLUG)
        }
        this.setState({ isOldSlug: true })
      } else {
        this.setState({ isOldSlug: false }, () => {
          if (this.state.isSaving) {
            this.isBlockingUrlValidation = false
            this.submitAssetDetails()
          }
        })
      }
      this.isBlockingUrlValidation = false
    } else if (assets && !assets.length) {
      if (this.state.isSaving) {
        this.setState({ isOldSlug: false }, () => {
          this.isBlockingUrlValidation = false
          this.submitAssetDetails()
        })
      }
    }
  }

  onSocialShareChange = (id, value) => {
    const { details } = this.state
    const { contents } = details
    const index = contents.findIndex(item => item.id === id)
    if (index > -1) {
      contents[index].value = value
      this.updatedContents.push(contents[index].id)
    }
    details.contents = contents
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onContentPlaceholderChange = (id, value, metaField, deselect = false) => {
    const { details } = this.state
    const { contents } = details
    if (metaField.type === 'STRING' && deselect) {
      metaField.value = null
    } else if (metaField.type === 'LIST') {
      let fieldValues = metaField.fieldValues || []
      if (deselect && fieldValues) {
        const updateList = fieldValues.filter(item => item !== value)
        metaField.fieldValues = updateList
      } else if (fieldValues) {
        metaField.fieldValues = [ ...fieldValues, value ]
      } else {
        metaField.fieldValues = [value]
      }
    } else {
      metaField.value = value
    }
    metaField.fieldID = metaField.fieldID || metaField.id
    delete metaField['__typename']
    delete metaField['isError']
    const index = contents.findIndex(item => item.id === id)
    if (index > -1) {
      let content = contents[index]
      let contentMetas = content.metaFields
      contentMetas = contentMetas.map(contentMeta => {
        delete contentMeta['__typename']
        return contentMeta
      })
      const metaIndex = contentMetas.findIndex(item => item.id === metaField.id)
      if (metaIndex === -1) {
        contentMetas.push(metaField)
      } else {
        contentMetas[metaIndex] = metaField
      }
      content.metaFields = contentMetas
    }
    details.contents = contents
    this.updatedContents.push(id)
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onMatchSelect = (matchData) => {
    let { details } = this.state
    const { listAssetTypes } = this.props
    const assetConfig = listAssetTypes.find(item => item.title === details.type)
    const isProgramEnable = details && (assetConfig.programSection)
    if (matchData) {
      if (isProgramEnable && (matchData && matchData.programs && matchData.programs.length)) {
        this.setState({ shouldShowWarning: true, currentMatch: matchData })
      } else {
        details.match = matchData
        this.setState({ details })
        this.changeEditStatus(true)
      }
    } else {
      details.match = matchData
      this.setState({ details })
      this.changeEditStatus(true)
    }
  }

  changeMatch = () => {
    this.setState({ isConfirmLoading: true })
    let { details, currentMatch } = this.state
    const variables = { id: details.id, match: currentMatch ? currentMatch.id : null }
    this.props.updateAsset(variables).then(({ data }) => {
      if (currentMatch) {
        let program = details.program ? details.program : null
        currentMatch.programs = (currentMatch.programs || []).filter((item) => item.isLiveTelecast)
        program = [...currentMatch.programs]
        details.program = program && program.length ? program[0] : null
        details.vodStatus = null
        details.match = currentMatch
        const programId = program.map((item) => item.id)
        this.props.linkAssetProgram(details.id, programId).then(() => {
          details.updatedAt = new Date().toISOString()
          this.setState({ details, isConfirmLoading: false, shouldShowWarning: false })
        }, error => {
          utilityService.handleError(error)
        })
      } else {
        this.setState({ isConfirmLoading: false, shouldShowWarning: false })
      }
    }, error => {
      this.setState({ isConfirmLoading: false, shouldShowWarning: false })
      utilityService.handleError(error)
    })
  }

  toggleWarningMessage = () => {
    const { details } = this.state
    const { match } = details
    this.setState({ currentMatch: match, shouldShowWarning: false })
  }

  toggleWarningHyperionMessage = () => {
    const { details } = this.state
    const { season } = details
    this.setState({ currentSeason: season, shouldShowHyperionWarning: false })
  }

  toggleWarningSeriesMessage = () => {
    const { details } = this.state
    const { series } = details
    this.setState({ currentSeries: series, shouldShowSeriesWarning: false })
  }

  updateStatus = (isLive) => {
    const { details } = this.state
    this.savedLiveStatus = details.isLive
    details.isLive = isLive
    this.changedIsLive = true
    this.setState({ details })
    this.changeEditStatus(true)
  }

  updateSubscriptionStatus = (isFree) => {
    const { details } = this.state
    details.isFree = isFree
    this.setState({ details })
    this.changeEditStatus(true)
  }

  onEmbedScriptChange = (id, value) => {
    const { details } = this.state
    const { contents } = details
    const index = contents.findIndex(item => item.id === id)
    if (index > -1) {
      contents[index].value = value
      this.updatedContents.push(contents[index].id)
    }
    details.contents = contents
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onEmbedScriptCaptionChange = (id, caption) => {
    const { details } = this.state
    const { contents } = details
    const index = contents.findIndex(item => item.id === id)
    if (index > -1) {
      contents[index].caption = caption
      this.updatedContents.push(contents[index].id)
    }
    details.contents = contents
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onImageCaptionChange = (id, value) => {
    const { details } = this.state
    const { contents } = { ...details }
    const index = contents.findIndex(item => item.id === id)
    if (index > -1) {
      contents[index].value = value
      this.updatedContents.push(contents[index].id)
    }
    details.contents = contents
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onChangeDefaultVideo = videoId => {
    const { details } = this.state
    let { videos } = details
    const index = videos.findIndex(image => image.id === videoId)
    if (index > -1) {
      let duration = 0
      if (videos[index].vodStatus) {
        const from = videos[index].vodStatus ? videos[index].vodStatus.startTime : 0
        const to = videos[index].vodStatus ? videos[index].vodStatus.endTime : 0
        if (to && from) {
          duration = `${moment(to).diff(moment(from), 'seconds')}`
        }
      }
      details.defaultVideo = { id: videoId }
      details.duration = videos[index].duration && videos[index].duration !== '0' ? videos[index].duration : duration
    }
    let defaultVideo = videos[index]
    videos.splice(index, 1)
    videos.splice(0, 0, defaultVideo)
    videos = videos.map(video => {
      if (video.id === defaultVideo.id) { video.isDefault = 'true' } else { video.isDefault = 'false' }
    })
    this.setState({ details })
    const updatedVideo = {
      id: defaultVideo.id,
      assetId: defaultVideo.assetId,
      mediaId: defaultVideo.mediaId,
      isDefault: 'true'
    }
    this.props.updateAssetVideo(updatedVideo).then(() => {
      this.setState({ shouldRefreshHistory: true }, () => {
        this.setState({ shouldRefreshHistory: false })
      })
    }, (error) => {
      utilityService.handleError(error)
    })
  }

  onChangeDefaultImage = imageId => {
    const { details, activeImageType } = this.state
    const { listAssetTypes, listImageTypes } = this.props
    const assetConfig = listAssetTypes.find(item => item.title === details.type)
    const { images } = details
    const index = images.findIndex(image => image.id === imageId)
    let tempDefault = null
    details.images = images.map(image => {
      const isFilter = (activeImageType === primaryImageType ? (image.type.id === activeImageType || !image.type.id) : activeImageType === image.type.id)
      if (isFilter) {
        if (image.id === imageId) {
          image.isDefault = 'true'
        } else {
          if (image.isDefault === 'true') { tempDefault = image }
          image.isDefault = 'false'
        }
      }
      return image
    })
    const cropPos = listImageTypes.find(item => item.id === activeImageType)
    const variables = {
      id: imageId,
      assetId: details.id,
      imageTypeId: activeImageType,
      isDefault: 'true',
      mediaId: images[index].mediaId,
      cropPosition: cropPos.enabledAssetTypes.find(item => item.assetType === details.type).cropPosition
    }
    this.setState({ isAssetImageLoading: true })
    this.props.updateAssetImage(variables).then(() => {
      this.setState({ shouldRefreshHistory: true, isAssetImageLoading: false }, () => {
        this.setState({ shouldRefreshHistory: false })
      })
    }, (error) => {
      details.images = images.map(image => {
        const isFilter = (activeImageType === primaryImageType ? (image.type.id === activeImageType || !image.type.id) : activeImageType === image.type.id)
        if (isFilter) {
          if (tempDefault && image.id === tempDefault.id) {
            image.isDefault = 'true'
          } else {
            image.isDefault = 'false'
          }
        }
        return image
      })
      this.setState({ details, isAssetImageLoading: false })
      utilityService.handleError(error)
    })
    if (!assetConfig.contentSection) {
      // this.updateCache('defaultMedia', media[index])
      // this.setState({ details }, () => {
      //   this.changeEditStatus(true)
      // })
      this.setState({ details })
      return
    }
    const contentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
    let newDefaultImageContent = _.cloneDeep(images[index])
    newDefaultImageContent.id = newDefaultImageContent.mediaId
    if (contentIndex > -1 && activeImageType === primaryImageType) {
      const { contents } = details
      contents[contentIndex].media = newDefaultImageContent
      this.updatedContents.push(contents[contentIndex].id)
      details.contents = contents
      this.setState({ details, isEdited: true }, () => {
        this.changeEditStatus(true)
        this.updateCache('defaultMedia', newDefaultImageContent)
        // this.updateCache('contents', details.contents)
      })
    } else if (activeImageType === primaryImageType) {
      this.updateCache('defaultMedia', newDefaultImageContent)
      this.setState({ details, newDefaultImage: newDefaultImageContent.id }, () => {
        this.changeEditStatus(true)
      })
    } else {
      this.setState({ details })
    }
  }

  handleGeneralDetailsChange = e => {
    const { details, isMetaDescEdited } = this.state
    const { listAssetTypes } = this.props
    const { contents } = details
    const { name, value } = e.target
    if (!value.startsWith(' ')) {
      if (name === 'description' && !isMetaDescEdited && details.description === details.seoMetaDescription) { details.seoMetaDescription = value ? value.substr(0, 155) : null }
      if (name === 'title' && (!details.seoTitle || details.seoTitle === details.title)) {
        details.seoTitle = value
      }
      details[name] = value
      const assetConfig = listAssetTypes.find(item => item.title === details.type)
      if (!(assetConfig && assetConfig.contentSection)) {
        this.setState({ details }, () => {
          this.changeEditStatus(true)
        })
        return
      }
      const typeMapping = {
        title: 'TITLE',
        description: 'DESCRIPTION'
      };
      (contents || []).map((item, index) => {
        if (item.type === typeMapping[name]) {
          contents[index].value = value
          this.updatedContents.push(contents[index].id)
        }
      })
      details.contents = contents
      this.setState({ details }, () => {
        this.changeEditStatus(true)
        this.updateCache('contents', contents)
      })
    }
  }

  handleMetaDetailsChange = (metaData, value, deselect = false) => {
    const listAssetTypes = _.cloneDeep(this.props.listAssetTypes)
    const { details } = this.state
    const currentAssetType = (listAssetTypes || []).find(item => item.title === details.type)
    const meta = (currentAssetType.metaFields || []).map((item, index) => {
      if (item.displayName === metaData.displayName && item.type === metaData.type) {
        const { fieldValues } = details.meta[index] || {}
        if (item.type === 'LIST') {
          if (deselect && fieldValues) {
            const updateList = fieldValues.filter(item => item !== value)
            item.fieldValues = updateList
          } else if (fieldValues) {
            item.fieldValues = [ ...fieldValues, value ]
          } else {
            item.fieldValues = [value]
          }
        } else if (item.type === 'STRING' && deselect) {
          item.value = null
        } else {
          item.value = value
        }
      } else {
        const tempItem = (details.meta || []).find(innerItem => innerItem.displayName === item.displayName && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
      }
      const { section, isError, ...rest } = item
      return rest
    })
    details.meta = meta
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleSeoDetailsChange = e => {
    const { details } = this.state
    let { isMetaDescEdited } = this.state
    const { name, value } = e.target
    details[name] = value
    if (name === 'seoMetaDescription') { isMetaDescEdited = true }
    if (name === 'slug') {
      this.slugEdited = true
      const modified = utilityService.slugifyString(value)
      if (value !== modified) {
        this.invalidSlug = true
      } else {
        this.setState({ isOldSlug: false })
        this.invalidSlug = false
      }
    }
    this.setState({ details, isMetaDescEdited }, () => this.changeEditStatus(true))
  }

  onGeneralTagChange = value => {
    const { details } = this.state
    details.tags = value
    this.setState({ details }, () => this.changeEditStatus(true))
  }

  onSeoKeywordChange = (value, isDelete) => {
    const { details } = this.state
    if (isDelete) {
      const index = details.seoKeywords.indexOf(value)
      if (index > -1) {
        details.seoKeywords.splice(index, 1)
      }
    } else {
      if (!details.seoKeywords) {
        details.seoKeywords = [value]
      } else {
        const index = details.seoKeywords.findIndex(item => item === value)
        if (index === -1) {
          details.seoKeywords.push(value)
        }
      }
    }
    this.setState({ details }, () => this.changeEditStatus(true))
  }

  onAuthorChange = (selectedAuthor) => {
    const { details } = this.state
    const { listAssetTypes } = this.props
    if (!selectedAuthor) {
      details.author = null
    } else {
      if (!details.author) {
        details.author = {}
      }
      details.author.name = selectedAuthor.name
      details.author.id = selectedAuthor.id
      details.author.media = selectedAuthor.media
    }
    const assetConfig = listAssetTypes.find(item => item.title === details.type)
    if (!assetConfig.contentSection) {
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
      return
    }
    const { contents } = details
    contents.map((item, index) => {
      if (item.type === 'AUTHOR') {
        contents[index].value = selectedAuthor ? selectedAuthor.name : undefined
        this.updatedContents.push(contents[index].id)
      }
    })
    details.contents = contents

    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onCategorySelect = (selectedCategory) => {
    let { details } = this.state
    details.category = selectedCategory
    this.setState({ details })
    this.changeEditStatus(true)
  }

  onRichTextChange = (id, value) => {
    const { details } = this.state
    const { contents } = details
    const index = contents.findIndex(item => item.id === id)
    const isScriptStartTag = value.indexOf('&lt;script')
    const isScriptEndTag = value.indexOf('&lt;/script&gt;')
    if (isScriptStartTag > -1 && isScriptEndTag > -1) {
      value = `${value.substring(0, isScriptStartTag)}${value.substring(isScriptEndTag + 15, value.length)}`
    }
    const tempValue = (value || '').replace(/<br \/>/g, '<br>')
    const tempOldValue = (contents[index].value || '').replace(/<br \/>/g, '<br>')
    let isSame
    if (tempValue === tempOldValue) {
      isSame = true
    }
    if (index > -1 && contents[index].value !== value) {
      contents[index].value = value
    } else return
    details.contents = contents
    this.updatedContents.push(id)
    this.setState({ details }, () => {
      if (!isSame) {
        this.changeEditStatus(true)
      }
      this.updateCache('contents', contents)
    })
  }

  onAddContent = content => {
    const { details } = this.state
    const { contents } = details
    contents.push(content)
    details.contents = contents
    this.updatedContents.push(content.id)
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  createdBucket = (id) => {
    const { details } = this.state
    details.relatedAssets = { id }
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  onEditRelatedAssets = (status) => {
    this.changeEditStatus(status)
  }

  onMediaAdd = (media, selectedImageIndex, category) => {
    this.setState({ isAssetImageLoading: true })
    const { listImageTypes, listAssetTypes } = this.props
    const { details, activeImageType } = this.state
    const assetConfig = listAssetTypes.find(item => item.title === details.type)
    const cropPos = listImageTypes.find(item => item.id === activeImageType)
    const imageContents = (media || []).map(image => {
      const newImage = {
        mediaId: image.id,
        assetId: details.id,
        imageTypeId: activeImageType,
        isDefault: 'false',
        assetType: details.type,
        cropPosition: cropPos.enabledAssetTypes.find(item => item.assetType === details.type).cropPosition
      }
      if (category) { newImage.categoryId = category }
      return newImage
    })
    if (!details.images || !((details.images || []).filter(item => item.type.id === activeImageType)).length) {
      imageContents[0].isDefault = 'true'
    }
    this.props.addAssetImages(imageContents).then(({ data }) => {
      const { addAssetImages } = data
      const { details } = this.state
      this.setState({ shouldRefreshHistory: true }, () => {
        this.setState({ shouldRefreshHistory: false })
      })
      if (details.images) { details.images = addAssetImages && addAssetImages.length ? _.uniqBy([...details.images, ...addAssetImages], 'id') : [] } else { details.images = addAssetImages && addAssetImages.length ? [...addAssetImages] : [] }
      if (selectedImageIndex) {
        let { uploadingFiles } = this.state
        const imageDeleteIndex = uploadingFiles.findIndex(image => image.id === selectedImageIndex)
        uploadingFiles.splice(imageDeleteIndex, 1)
        this.setState({ details, isAssetImageLoading: false, uploadingFiles })
      } else { this.setState({ details, isAssetImageLoading: false }) }
      const newDefault = addAssetImages.find(item => item.isDefault === 'true')
      if (activeImageType === primaryImageType && newDefault && assetConfig.contentSection) {
        this.setState({ newDefaultImage: newDefault.mediaId }, () => {
          this.changeEditStatus(true)
        })
      }
      this.props.onChangeIsImageUploading('REMOVE')
    }, error => {
      console.log('error', error)
      this.props.onChangeIsImageUploading('REMOVE')
      let { uploadingFiles } = this.state
      if (selectedImageIndex) {
        const imageDeleteIndex = uploadingFiles.findIndex(image => image.id === selectedImageIndex)
        uploadingFiles.splice(imageDeleteIndex, 1)
      }
      this.setState({ isAssetImageLoading: false, uploadingFiles })
      utilityService.handleError(error)
    })
  }

  onAddMedia = (mediaContent, isVideo, category) => {
    if (isVideo) {
      const { details } = this.state
      let { addedMediaList, removedMediaList } = this.state
      const { videos } = details
      const addedIndexList = mediaContent.map(item => item.id)
      addedMediaList = [...addedMediaList, ...addedIndexList]
      removedMediaList = removedMediaList.filter(item => {
        return !addedIndexList.includes(item)
      })
      // const updatedMedia = [...(videos || []), ...mediaContent]

      const videoContent = (mediaContent || []).map(video => {
        const newVideo = {
          mediaId: video.id,
          assetId: details.id
        }
        if (category) { newVideo.categoryId = category }
        return newVideo
      })
      if (!videos || !videos.length) { videoContent[0].isDefault = 'true' }

      // details.videos = updatedMedia
      this.setState({ isAssetVideoLoading: true })
      this.props.addAssetVideos(videoContent).then(({ data }) => {
        details.videos = [...videos, ...data.addAssetVideos]
        const defaultVideo = (data.addAssetVideos || []).find(item => item.isDefault === 'true')
        if (!_.isEmpty(defaultVideo)) {
          details.duration = defaultVideo.duration
        }
        this.setState({ details, shouldRefreshHistory: true }, () => {
          this.setState({ shouldRefreshHistory: false, isAssetVideoLoading: false })
        })
      }, (error) => {
        this.setState({ isAssetImageLoading: false, isAssetVideoLoading: false })
        utilityService.handleError(error)
      })
      this.setState({ addedMediaList, removedMediaList }, () => {
        // if ((videos.length === 1 || !details.defaultVideo) && isVideo) {
        //   this.onChangeDefaultVideo(mediaContent[0].id)
        // }
        // this.changeEditStatus(true)
        // this.updateCache('media', updatedMedia)
      })
    } else { this.onMediaAdd(mediaContent) }
  }

  onTriggerVod = (video, tags, mediaCategory, drmRequired) => {
    const { details } = this.state
    const variables = {
      assetId: details.id,
      assetType: details.type,
      name: details.title,
      jobType: 'VOD_MEDIA',
      fileKey: video[0].absolutePath,
      tags: tags && tags.length ? tags.map((item) => item.key) : null,
      mediaCategory: mediaCategory || null,
      originalFileSize: video[0].size || null,
      drmRequired,
      timestamp: new Date().getTime()
    }
    this.props.triggerLiveToVod(variables).then((response) => {
      // details.vodJobs.push(response.data.triggerLiveToVod)
      // if (this.vodInterval) {
      //   clearInterval(this.vodInterval)
      //   this.vodInterval = undefined
      // }
      // this.vodInterval = setInterval(() => this.getVodStatus(this.props.assetId), 15 * 1000)
      // setTimeout(() => {
      // if(response.data.triggerLiveToVod.asset.id === this.state.details.id) {
      details.updatedAt = moment().add(1.5, 'seconds')
      this.setState({ details, isVodTriggerSuccess: true }, () => {
        this.setState({ isVodTriggerSuccess: false })
      })
      //   }
      // }, 1500)
    }, (error) => {
      message.error(error.graphQLErrors && error.graphQLErrors.length ? error.graphQLErrors[0].message : error.message)
      this.setState({ isVodTriggerSuccess: true }, () => {
        this.setState({ isVodTriggerSuccess: false })
      })
    })
  }

  onCloseImage = id => {
    const { details, removedMediaList, addedMediaList, activeImageType } = this.state
    let { images } = details
    images = (images || []).sort((a, b) => {
      const dateA = new Date(a.createdAt)
      const dateB = new Date(b.createdAt)
      return dateA - dateB
    })
    const index = images.findIndex(item => item.id === id)
    const deletedMedia = images.splice(index, 1)
    const imageList = images.filter(item => item.type.id === activeImageType)
    this.setState({ isAssetImageLoading: true })
    details.images = images
    if (!((images).filter(image => image.type.id === primaryImageType)).length) {
      const { contents } = details
      details.defaultMedia = null
      const index = (contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
      if (index > -1) {
        this.onCloseContent(contents[index].id)
        this.setState({ isEdited: true }, () => {
          this.changeEditStatus(true)
          this.updateCache('contents', details.contents)
        })
      }
    }
    removedMediaList.push(id)
    const removedIndex = addedMediaList.findIndex(item => item === id)
    if (removedIndex !== -1) {
      addedMediaList.splice(removedIndex, 1)
    }

    this.setState({ details, removedMediaList, addedMediaList }, () => {
      if (imageList.length && deletedMedia[0].isDefault === 'true') {
        this.onChangeDefaultImage(imageList[0].id)
      }
    })
    const variables = {
      deletedId: [id],
      assetId: details.id,
      project: this.props.project
    }

    this.props.deleteAssetImages(variables).then(({ data }) => {
      this.setState({ shouldRefreshHistory: true, isAssetImageLoading: false }, () => {
        this.setState((prevState) => {
          let tempDetails = prevState.details
          tempDetails.images = (tempDetails.images || []).filter(item => !((data.deleteAssetImages || []).includes(item.id)))
          return { shouldRefreshHistory: false, details: tempDetails }
        })
      })
    }, (error) => {
      this.setState({ isSaving: false, isAssetImageLoading: false })
      utilityService.handleError(error)
    })
  }

  refetchHistory = () => {
    this.setState({ shouldRefreshHistory: true }, () => {
      this.setState({ shouldRefreshHistory: false })
    })
  }

  onCloseVideo = id => {
    const { details, removedMediaList, addedMediaList } = this.state
    const { videos } = details
    this.setState({ isAssetVideoLoading: true })
    const index = videos.findIndex(item => item.id === id)
    const variables = {
      deletedId: [id],
      assetId: details.id
    }
    this.props.deleteAssetVideos(variables).then(() => {
      this.setState({ shouldRefreshHistory: true }, () => {
        this.setState({ shouldRefreshHistory: false, isAssetVideoLoading: false })
      })
    }, (error) => {
      this.setState({ isAssetVideoLoading: false })
      utilityService.handleError(error)
    })
    let nextDefaultVideo = null
    if (videos[index].isDefault === 'true') {
      if (videos.length > 1) {
        nextDefaultVideo = videos.filter(item => item.id !== id)[0]
        const from = nextDefaultVideo.vodStatus ? nextDefaultVideo.vodStatus.startTime : null
        const to = nextDefaultVideo.vodStatus ? nextDefaultVideo.vodStatus.endTime : null
        details.duration = moment(to).diff(moment(from), 'seconds')
        details.defaultVideo = { id: nextDefaultVideo.id }
        let duration = 0
        if (nextDefaultVideo.vodStatus) {
          const from = nextDefaultVideo.vodStatus ? nextDefaultVideo.vodStatus.startTime : 0
          const to = nextDefaultVideo.vodStatus ? nextDefaultVideo.vodStatus.endTime : 0
          if (to && from) {
            duration = `${moment(to).diff(moment(from), 'seconds')}`
          }
        }
        details.duration = nextDefaultVideo.duration && nextDefaultVideo.duration !== '0' ? nextDefaultVideo.duration : duration
      } else {
        details.duration = '0'
        details.defaultVideo = null
      }
    }
    removedMediaList.push(id)
    const removedIndex = addedMediaList.findIndex(item => item === id)
    if (removedIndex !== -1) {
      addedMediaList.splice(removedIndex, 1)
    }
    videos.splice(index, 1)
    details.videos = videos
    this.setState({ details, removedMediaList, addedMediaList }, () => {
      // this.changeEditStatus(true)
      if (nextDefaultVideo) {
        setTimeout(() => {
          this.onChangeDefaultVideo(nextDefaultVideo.id)
        }, 500)
      }
    })
  }

  changeEditStatus = (status) => {
    if (this.state.isEdited !== status) {
      this.setState({ isEdited: status })
      const editStatus = { isEdited: status, isError: this.state.isOldSlug || this.invalidSlug || this.invalidTitle }
      this.refetchHistory()
      if (!this.isUpdateDisable) { this.props.changeEditStatus(editStatus) }
    }
    if (status) {
      this.isEditingInput = true
    }
  }

  changeErrorStatus = (editedStatus, errorStatus) => {
    // if (this.state.isEdited !== editedStatus) {
    this.setState({ isEdited: editedStatus })
    const editStatus = { isEdited: editedStatus, isError: errorStatus }
    if (!this.isUpdateDisable) { this.props.changeEditStatus(editStatus) }
    // }
  }

  onOrderChange = (startIndex, endIndex) => {
    const { details } = { ...this.state }
    const { contents } = details
    const result = Array.from(contents)
    const [removed] = result.splice(startIndex, 1)
    const isDecreasing = endIndex < startIndex
    const largerIndex = isDecreasing ? startIndex : endIndex
    const smallerIndex = isDecreasing ? endIndex : startIndex
    const modifiedResult = result.map((item, index) => {
      if (index >= smallerIndex && index < largerIndex) {
        item.position = item.position + (isDecreasing ? 1 : -1)
      }
      return item
    })
    removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : (result[endIndex].position - 1)
    modifiedResult.splice(endIndex, 0, removed)
    details.contents = modifiedResult.map((item, index) => {
      item.position = index + 1
      return item
    })
    this.updatedContentsDirectly.push(removed.id);
    [...modifiedResult].splice(smallerIndex, largerIndex - smallerIndex + 1).map(item => {
      this.updatedContents.push(item.id)
    })
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', modifiedResult)
    })
  }

  onCloseContent = id => {
    const { details } = this.state
    const { contents } = details
    const index = contents.findIndex(item => item.id === id)
    if (index > -1) {
      contents.splice(index, 1)
    }
    details.contents = contents.map((item, index) => {
      item.position = index + 1
      return item
    })
    this.setState({ details }, () => {
      this.changeEditStatus(true)
      this.updateCache('contents', contents)
    })
  }

  onDeleteAsset = id => {
    this.changeErrorStatus(false, false)
    this.props.onDeleteAsset(id)
  }

  onPublishButtonClick = () => {
    setTimeout(this.props.fetchAssetDetails, 1500)
    // this.submitAssetDetails(true)
  }

  trimSpace = (details) => {
    let { contents } = details
    let item = _.find(contents, (contentItem) => contentItem.type === 'RICHTEXT')
    const itemIndex = _.findIndex(contents, (contentItem) => contentItem.type === 'RICHTEXT')
    if (itemIndex === -1) {
      return details
    }
    let { value } = item
    if (itemIndex === -1 || !value) {
      return details
    }
    let length = value ? value.length : 0
    const checkSpace = '<p><br></p>'
    const spaceLength = checkSpace.length
    while (value.lastIndexOf(checkSpace) !== -1 && value.lastIndexOf(checkSpace) + spaceLength === length) {
      const index = value.lastIndexOf(checkSpace)
      value = value.slice(0, index)
      length = value.length
    }
    item.value = value
    contents[itemIndex] = item
    details.contents = contents
    this.setState({ details })
    return details
  }

  onPublishButtonActiveChange = () => {
    // this.submitAssetDetails(true, true)
    setTimeout(this.props.fetchAssetDetails, 1500)
  }

  autoSaveDetails = () => {
    if (this.state.isEdited && this.state.details.status === 'DRAFT' && !this.shouldDisableAutoSave && !this.isEditingInput) {
      // this.submitAssetDetails(false, true)  // Disabled auto save
    }
  }

  checkForModification = (isFromPublish, shouldBlockMessage) => {
    const { uploadingFiles } = this.state
    if (uploadingFiles && uploadingFiles.length) {
      message.warning('Media upload in progress, Please try again later')
      return
    }
    this.setState({ isSaving: true, canTriggerAppleNewsURL: false })
    this.props.fetchUpdatedDetails().then((response) => {
      const { details } = this.state
      const isLatest = moment(response.data.getAsset.updatedAt).isAfter(moment(details.updatedAt), 'seconds')
      if (isLatest) { this.setState({ isModified: true, isSaving: false, lastUpdatedUser: response.data.getAsset.updatedBy }) } else { this.submitAssetDetails(isFromPublish, shouldBlockMessage) }
    })
    // this.submitAssetDetails(isFromPublish, shouldBlockMessage)
  }

  reloadAsset = () => {
    const { tempAssetDetails } = this.state
    this.changedIsLive = false
    this.setState({ isModifiedConfirm: true })
    this.changeEditStatus(false)
    if (tempAssetDetails) {
      this.setState({ details: tempAssetDetails, isLoading: false, isEdited: false, isModified: false, isModifiedConfirm: false, lastUpdatedUser: '' })
      this.isSavingFlag = false
      // this.handleVodStatus(tempAssetDetails)
      NProgress.done()
      this.setState({})
    } else {
      this.props.fetchAssetDetails().then((response) => {
        this.setState({ isModified: false, isModifiedConfirm: false, details: response.data.getAsset, isEdited: false })
      })
    }
  }

  submitAssetDetails = async (isFromPublish, shouldBlockMessage) => {
    const details = this.trimSpace(_.cloneDeep(this.state.details))
    const listAssetTypes = _.cloneDeep(this.props.listAssetTypes)
    const currentAssetType = (listAssetTypes || []).find(item => item.title === details.type)
    const { meta } = details
    let mandatoryMeta = []
    if ((meta && meta.length) || (currentAssetType && currentAssetType.metaFields)) {
      mandatoryMeta = (currentAssetType.metaFields || []).filter(metaData => {
        const tempItem = (meta || []).find(innerItem => innerItem.displayName === metaData.displayName && metaData.type === innerItem.type)
        return _.isEmpty(tempItem) ? (metaData.isRequired && metaData.type !== 'BOOLEAN') &&
        (metaData.value === null || metaData.value === '' || metaData.value === undefined)
          : ((tempItem.isRequired && tempItem.type !== 'BOOLEAN' && tempItem.type !== 'LIST') && (tempItem.value === null || tempItem.value === '' || tempItem.value === undefined)) ||
        ((tempItem.type === 'LIST') && tempItem.isRequired && (tempItem.fieldValues === null || tempItem.fieldValues === '' || tempItem.fieldValues === undefined || _.isEmpty(tempItem.fieldValues)))
      })
    }

    if (mandatoryMeta && mandatoryMeta.length) {
      message.error('Mandatory fields are missing')
      const newMeta = (currentAssetType.metaFields || []).map(item => {
        const tempItem = (meta || []).find(innerItem => innerItem.displayName === item.displayName && item.type === innerItem.type)
        item = _.isEmpty(tempItem) ? item : tempItem
        if (item.isRequired && (item.value === null || item.value === '' || item.value === undefined)) {
          item.isError = true
        }
        return item
      })
      details.meta = _.cloneDeep(newMeta)
      this.setState({ isSaving: false, isLoading: false, details })
      return
    }

    await this.updateModifiedContentsCallback().catch(err => {
      return err
    })

    if (details && details.contents && details.contents.length > 99) {
      message.error('Number of content items should be less than 100')
      this.setState({ isSaving: false, isLoading: false })
      return
    }
    if (details && details.credits && details.credits.length) {
      const incompleteCredit = details.credits.filter(item => _.isEmpty(item.role) || _.isEmpty(item.person))
      if (incompleteCredit.length) {
        message.error('Please remove incomplete credit details')
        this.setState({ isSaving: false, isLoading: false })
        return
      }
    }
    if (details && details.averageRating) {
      if (parseFloat(details.averageRating) < 0 || parseFloat(details.averageRating) > 10) {
        message.error('Please enter a valid averageRating value between 0 and 10')
        this.setState({ isSaving: false, isLoading: false })
        return
      }
      if (details.averageRating.toString().includes('.')) {
        const splitString = details.averageRating.toString().split('.')
        if (splitString[1] && splitString[1].length > 1) {
          message.error('Please enter a average Rating with maximum of one digit after decimal')
          this.setState({ isSaving: false, isLoading: false })
          return
        }
      }
    }

    this.invalidTitle = false
    const assetRequest = this.getFormattedRequest(_.cloneDeep(details), isFromPublish)
    // await utilityService.delay(1000)
    this.isEditingInput = false
    if (!details.title) {
      this.invalidTitle = true
      this.changeErrorStatus(true, true)
      if (!shouldBlockMessage) {
        this.setState({ isSaving: false })
        message.error(userMessages.EMPTY_TITLE)
      }
      return
    }
    if (this.isBlockingUrlValidation) {
      return
    } else if (assetRequest.slug && (this.slugEdited || assetRequest.slug !== details.slug) && this.urlSlug !== assetRequest.slug && !this.isBlockingUrlValidation) {
      this.isBlockingUrlValidation = true
      // this.props.slugChanged(assetRequest.slug)
      this.setState({ isSaving: true })
      this.urlSlug = assetRequest.slug
      this.props.checkUrlValidation(assetRequest.slug).then(response => {
        const data = response.data && response.data.listAssets && response.data.listAssets.items && response.data.listAssets.items.length ? response.data.listAssets.items : []
        // const data = { assets: response.data && response.data.listAssets && response.data.listAssets.items && response.data.listAssets.items.length ? response.data.listAssets.items : [] }
        this.checkUrlValidation(data)
      })
      return
    } else if (this.invalidSlug || this.state.isOldSlug) {
      if (!shouldBlockMessage) {
        message.error(this.invalidSlug ? userMessages.INVALID_SLUG : userMessages.DUPLICATE_SLUG)
      }
      this.setState({ isSaving: false })
      this.changeErrorStatus(true, true)
      return
    }
    if (details.status !== 'DRAFT' && (!assetRequest.slug || assetRequest.slug.length < 3)) {
      this.invalidSlug = true
      this.changeErrorStatus(true, true)
      message.error(userMessages.EMPTY_SLUG)
      return
    }
    this.setState({ isSaving: true }, async () => {
      this.isSavingFlag = false
      NProgress.start()
      LoggerService.info('flow', `Updated assets ${details.id}`)
      if (((this.state.details && this.state.details.credits) || (this.props.details && this.props.details.credits)) && _.isEqual(_.size(this.state.details && this.state.details.credits), _.size(this.props.details && this.props.details.credits))) {
        await this.updateModifiedCreditsCallback()
      }
      this.changeEditStatus(false)
      this.props.updateAsset(assetRequest).then(({ data }) => {
        LoggerService.info('assetUpdate', `Updated asset details`, assetRequest)
        this.slugEdited = false
        this.changedIsLive = false
        this.savedLiveStatus = details.isLive
        this.setState({ isSaving: false, isLoading: false, removedMediaList: [], addedMediaList: [], details: { ...this.state.details, updatedAt: data.updateAsset.updatedAt, updatedBy: data.updateAsset.updatedBy, meta: data.updateAsset.meta } })
        NProgress.done()
        if (!shouldBlockMessage) {
          if (isFromPublish === true) {
            // if (details.status !== 'DRAFT') {
            //   message.success(userMessages.ASSET_PUBLISH_SUCCESS)
            // } else {
            //   message.success(userMessages.ASSET_UNPUBLISH_SUCCESS)
            // }
            setTimeout(() => this.props.fetchAssetDetails(), 500)
          } else {
            message.success(userMessages.ASSET_UPDATE_SUCCESS)
            setTimeout(() => this.setState({ canTriggerAppleNewsURL: true }), 3000)
          }
        }
      }, error => {
        this.setState({ isSaving: false, isLoading: false })
        utilityService.handleError(error)
      })
    })
  }

  getFormattedRequest = (request, isFromPublish) => {
    const formattedRequest = {}
    const { author, category, media, contents, tags, isLive, isFree, match, relatedAssets, defaultVideo, meta, images } = request
    let formattedAuthor = null; let formattedMedia; let formattedContents; let formattedTags; let isEmptyImage; let isEmptyVideo
    if (author && author.id) {
      formattedAuthor = author.id
    }
    if (media && media.length) {
      formattedMedia = media.filter(item => item).map(item => item.id)
      isEmptyImage = media.filter(item => item.type === 'IMAGE')
      isEmptyVideo = media.filter(item => item.type === 'VIDEO')
    }
    if (contents && contents.length) {
      formattedContents = contents.filter(item => item.type !== 'DEFAULT_IMAGE' || item.media).map(item => item.id)
    }
    if (tags && tags.length) {
      formattedTags = tags.filter(item => item).map(item => item.key).filter(item => item)
    }
    if (!formattedMedia || !(isEmptyImage && isEmptyImage.length)) {
      formattedRequest.defaultMedia = null
    } else if (images && images.length) {
      // formattedRequest.defaultMedia = images.filter(item => (item.isDefault === 'true' && item.type.id === primaryImageType))[0].id
    }
    if (!formattedMedia || !(isEmptyVideo && isEmptyVideo.length)) {
      formattedRequest.defaultVideo = null
    } else if (defaultVideo && defaultVideo.id) {
      formattedRequest.defaultVideo = defaultVideo.id
    }
    if (relatedAssets && relatedAssets.id) {
      formattedRequest.relatedAssets = relatedAssets.id
    }

    formattedRequest.id = request.id
    formattedRequest.author = formattedAuthor
    formattedRequest.contents = formattedContents || []
    formattedRequest.media = formattedMedia && formattedMedia.length ? _.uniq(formattedMedia, 'id') : null
    formattedRequest.tags = _.uniq(formattedTags || [])
    formattedRequest.updatedAt = new Date().toISOString()
    formattedRequest.title = (request.title).replace(/\n/g, '')
    formattedRequest.duration = request.duration
    formattedRequest.description = request.description ? request.description : null
    formattedRequest.shortTitle = request.shortTitle ? request.shortTitle : null
    formattedRequest.shortDescription = request.shortDescription ? request.shortDescription : null
    formattedRequest.seoTitle = request.seoTitle ? request.seoTitle.substring(0, 150) : null
    formattedRequest.slug = request.slug ? request.slug : null
    formattedRequest.seoKeywords = request.seoKeywords || null
    formattedRequest.seoMetaDescription = request.seoMetaDescription ? request.seoMetaDescription : null
    formattedRequest.isArchived = request.isArchived
    // formattedRequest.isPublished = request.isPublished
    formattedRequest.publishStartDate = request.publishStartDate ? new Date(request.publishStartDate).toISOString() : null
    formattedRequest.publishEndDate = request.publishEndDate ? new Date(request.publishEndDate).toISOString() : null
    formattedRequest.updatedBy = this.username
    if (this.changedIsLive) {
      formattedRequest.isLive = isLive || false
    }
    formattedRequest.isFree = isFree || false
    formattedRequest.match = match ? match.id : null
    formattedRequest.category = category ? category.id : null
    formattedRequest.autoPublish = request ? request.autoPublish : null
    formattedRequest.meta = meta && meta.length ? meta.map(data => {
      data.value = data.value === '' ? null : data.value
      delete data.__typename
      return data
    }) : []
    // if (!formattedRequest.seoTitle) {
    //   formattedRequest.seoTitle = request.title.substring(0, 60)
    // }
    const ratings = _.map(request.ratings, function (rating) { return _.omit(rating, '__typename') })
    const filterRatings = _.uniqWith(ratings.filter(allRating => allRating.rating !== ''), (rating1, rating2) => {
      return rating1.rating === rating2.rating && rating1.scheme === rating2.scheme && _.isEqual(_.sortBy(rating1.subRatings), _.sortBy(rating2.subRatings))
    })
    formattedRequest.ratings = filterRatings
    formattedRequest.originalAudio = request.originalAudio ? request.originalAudio : null
    formattedRequest.audioLanguages = request.audioLanguages ? request.audioLanguages.filter(item => item) : null
    formattedRequest.originalTitle = request.originalTitle
    formattedRequest.subtitleLanguages = request.subtitleLanguages ? request.subtitleLanguages.filter(item => item) : null
    formattedRequest.closedCaption = request.closedCaption
    formattedRequest.releaseDateTime = request.releaseDateTime
    formattedRequest.productionDateTime = request.productionDateTime
    formattedRequest.averageRating = parseFloat(request.averageRating)
    formattedRequest.expectedNumberOfSeasons = parseInt(request.expectedNumberOfSeasons)
    formattedRequest.productionStudio = !_.isEmpty(request.productionStudios) ? request.productionStudios.map(productionStudio => productionStudio.id)[0] : null
    formattedRequest.genres = !_.isEmpty(request.genres) ? (request.genres || []).map(item => item.id) : null
    formattedRequest.countryOfOrigin = !_.isEmpty(request.countries) ? request.countries.map(country => country.id)[0] : null
    formattedRequest.seasonNumber = request.seasonNumber ? parseInt(request.seasonNumber) : null
    formattedRequest.episodeNumber = request.episodeNumber ? parseInt(request.episodeNumber) : null
    formattedRequest.mediumDescription = request.mediumDescription
    formattedRequest.videoDuration = request.videoDuration
    formattedRequest.productionStudios = !_.isEmpty(request.productionStudios) ? request.productionStudios.map(productionStudio => productionStudio.id) : null
    formattedRequest.yearOfRelease = request.yearOfRelease ? parseInt(request.yearOfRelease) : null
    formattedRequest.episodeNumberInSeries = request.episodeNumberInSeries ? parseInt(request.episodeNumberInSeries) : null
    formattedRequest.colorCode = request.colorCode
    formattedRequest.countries = !_.isEmpty(request.countries) ? request.countries.map(country => country.id) : null
    formattedRequest.seoCanonicalUrl = request.seoCanonicalUrl || null
    const defaultSlug = utilityService.slugifyString(`${request.id}-untitled`)
    const isFirstSlug = formattedRequest.slug && (formattedRequest.slug.indexOf('untitled-') === 0 || formattedRequest.slug.indexOf(defaultSlug) === 0)
    if (isFirstSlug && formattedRequest.title !== 'Untitled') {
      const newTitle = request.type === 'ARTICLE' ? request.title.toLowerCase() : `${request.id}-${request.title.toLowerCase()}`
      formattedRequest.slug = utilityService.slugifyString(newTitle)
      const { details } = this.state
      details.slug = formattedRequest.slug
      this.setState({ details })
    } else if (!formattedRequest.slug) {
      const newTitle = request.type === 'ARTICLE' ? request.title.toLowerCase() : `${request.id}-${request.title.toLowerCase()}`
      formattedRequest.slug = utilityService.slugifyString(newTitle)
      const { details } = this.state
      details.slug = formattedRequest.slug
      this.setState({ details })
    }
    return formattedRequest
  }

  updateModifiedContentsCallback = () => {
    return new Promise(resolve => {
      this.updateModifiedContents(resolve)
    })
  }

  updateModifiedCreditsCallback = () => {
    return new Promise(resolve => {
      this.updateModifiedCredits(resolve)
    })
  }

  updateModifiedCredits = async (resolve) => {
    if (this.props.details && !_.isEqual(this.props.details.credits, this.state.details.credits) && !(this.props.details.credits === null && this.state.details.credits === [])) {
      const cast = (this.state.details.credits || []).filter(credit => credit.type === 'cast')
      const sortedCast = _.sortBy(cast || [], 'position')
      const crew = (this.state.details.credits || []).filter(credit => credit.type === 'crew')
      const sortedCrew = _.sortBy(crew || [], 'position')
      let variables = [...sortedCast, ...sortedCrew]
      variables = variables.filter(item => !_.isEmpty(item.role) && !_.isEmpty(item.person)).map((item, index) => {
        return {
          id: item.id,
          position: item.position,
          personId: item.person.id,
          roleId: item.role.id,
          trackPosChange: item.trackPosChange,
          characterName: item.characterName,
          type: item.type
        }
      })
      if (variables.length) {
        this.props.updateAssetCreditLinks(variables, this.state.details.id, this.props.project).then(() => {
          resolve(true)
        }, () => {
          resolve(true)
        })
      } else {
        resolve(true)
      }
    } else {
      resolve(true)
    }
  }

  updateModifiedContents = async (resolve) => {
    if (!this.updatedContents.length) {
      resolve(true)
      return
    }
    const uniqueContents = [...new Set(this.updatedContents)]
    const uniqueContentsDirectly = [...new Set(this.updatedContentsDirectly)]
    const { contents } = this.state.details
    const modifiedContents = contents.filter(item => {
      const index = uniqueContents.indexOf(item.id)
      return index > -1
    }).map(item => {
      let { metaFields } = item
      let mandatoryMeta = []
      if ((metaFields && metaFields.length)) {
        metaFields = metaFields.map(meta => {
          delete meta.__typename
          return meta
        })
        mandatoryMeta = (metaFields || []).filter(metaData => {
          // const tempItem = (meta || []).find(innerItem => innerItem.displayName === metaData.displayName && metaData.type === innerItem.type)
          return _.isEmpty(metaData) ? (metaData.isRequired && metaData.type !== 'BOOLEAN') &&
        (metaData.value === null || metaData.value === '' || metaData.value === undefined)
            : ((metaData.isRequired && metaData.type !== 'BOOLEAN' && metaData.type !== 'LIST') && (metaData.value === null || metaData.value === '' || metaData.value === undefined)) ||
        ((metaData.type === 'LIST') && metaData.isRequired && (metaData.fieldValues === null || metaData.fieldValues === '' || metaData.fieldValues === undefined || _.isEmpty(metaData.fieldValues)))
        })
      }
      if (mandatoryMeta && mandatoryMeta.length) {
        let errmessage = 'Mandatory fields are missing'
        message.error(errmessage)
        const newMeta = (metaFields || []).map(metaData => {
          // const tempItem = (meta || []).find(innerItem => innerItem.displayName === metaData.displayName && metaData.type === innerItem.type)
          if (metaData.isRequired && (metaData.value === null || metaData.value === '' || metaData.value === undefined)) {
            metaData.isError = true
          }
          return metaData
        })
        item.metaFields = _.cloneDeep(newMeta)
        this.setState({ isSaving: false, isLoading: false })
        throw errmessage
      }

      const returnItem = { ...item }
      if ((item.type === 'IMAGE' || item.type === 'DEFAULT_IMAGE') && item.media && item.media.id) {
        returnItem.media = item.media.id
      }
      if (item.value === '') {
        returnItem.value = null
      }
      if (item.caption === '') {
        returnItem.caption = null
      }
      returnItem.assetId = this.state.details.id
      const modifiedPositionIndex = uniqueContentsDirectly.indexOf(item.id)
      if (modifiedPositionIndex > -1) {
        returnItem.trackPosChange = true
      }
      delete returnItem['__typename']
      return returnItem
    })
    this.props.changeContentPosition(modifiedContents).then(async () => {
      this.updatedContents = []
      this.updatedContentsDirectly = []
      resolve(true)
    }, error => {
      this.setState({ isSaving: false, isLoading: false })
      utilityService.handleError(error)
    })
  }

  updateChannelProgram = (program) => {
    let { details } = this.state
    // const index = details.program.findIndex((item) => item.id === updatedChannel.id)
    // details.program[index] = updatedChannel
    if (program && !program.isLiveTelecast) { program.isLiveTelecast = false }
    details.program = program
    details.vodStatus = null
    if (this.vodInterval) {
      clearInterval(this.vodInterval)
      this.vodInterval = undefined
    }
    details.updatedAt = moment()
    this.setState({ details })
    this.updateCache('program', program)
  }

  handleHyperionGeneralChange = (e) => {
    const { details } = this.state
    const { name, value } = e.target
    details[name] = value || ''
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleChangeProductionDateTime = (selectedDate) => {
    const productionDateTime = selectedDate ? moment(selectedDate).utc().format() : null
    const { details } = this.state
    details.productionDateTime = productionDateTime
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleChangeReleaseDateTime = (selectedDate) => {
    const releaseDateTime = selectedDate ? moment(selectedDate).utc().format() : null
    const { details } = this.state
    details.releaseDateTime = releaseDateTime
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleDurationChange = (time, selectedTime) => {
    const { details } = this.state
    details.videoDuration = selectedTime || null
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleSeasonSelect = (selectedSeason) => {
    this.setState({ currentSeason: selectedSeason, shouldShowHyperionWarning: true }, () => {
      this.changeEditStatus(true)
    })
  }

  handleSeasonSumbit = () => {
    const { currentSeason, details } = this.state
    const selectedSeason = _.isEmpty(currentSeason) ? null : { ...currentSeason }
    this.setState({ isConfirmLoading: true })
    if (selectedSeason) {
      // const { details } = this.state
      details.season = {
        assetId: selectedSeason.id,
        id: selectedSeason.id + details.id,
        title: selectedSeason.title,
        __typename: 'Asset'
      }
      const variables = {
        episodeId: details.id,
        seasonId: selectedSeason.id
      }
      this.props.updateEpisodeSeasonLink(variables).then(() => {
        this.setState({ details, shouldShowHyperionWarning: false, currentSeason: null, isConfirmLoading: false }, () => {
          // this.changeEditStatus(true)
        })
      }, errorMsg => {
        this.setState({ shouldShowHyperionWarning: false, currentSeason: null, isConfirmLoading: false })
        utilityService.handleError(errorMsg)
      })
    } else {
      // const { details } = this.state
      if (details.season) {
        const itemId = details.season.id
        this.props.deleteSeasonEpisodes([itemId]).then(() => {
          details.season = null
          this.setState({ details, shouldShowHyperionWarning: false, currentSeason: null, isConfirmLoading: false }, () => {
            // this.changeEditStatus(true)
          })
        }, errorMsg => {
          this.setState({ shouldShowHyperionWarning: false, currentSeason: null, isConfirmLoading: false })
          utilityService.handleError(errorMsg)
        })
      }
    }
  }

  handleSeriesSelect = (selectedSeries) => {
    this.setState({ currentSeries: selectedSeries, shouldShowSeriesWarning: true }, () => {
      this.changeEditStatus(true)
    })
  }

  handleSeriesSubmit = () => {
    const { currentSeries, details } = this.state
    const selectedSeries = _.isEmpty(currentSeries) ? null : { ...currentSeries }
    this.setState({ isConfirmLoading: true })
    if (selectedSeries) {
      details.series = {
        assetId: selectedSeries.id,
        id: selectedSeries.id + details.id,
        title: selectedSeries.title,
        __typename: 'Asset'
      }
      const variables = {
        seasonId: details.id,
        seriesId: selectedSeries.id
      }
      this.props.updateSeasonSeriesLink(variables).then(() => {
        this.setState({ details, shouldShowSeriesWarning: false, currentSeries: null, isConfirmLoading: false }, () => {
          // this.changeEditStatus(true)
        })
      }, errorMsg => {
        this.setState({ shouldShowSeriesWarning: false, currentSeries: null, isConfirmLoading: false })
        utilityService.handleError(errorMsg)
      })
    } else {
      const { details } = this.state
      if (details.series) {
        const itemId = details.series.id
        this.props.deleteSeriesSeasons([itemId]).then(() => {
          details.series = null
          this.setState({ details, shouldShowSeriesWarning: false, currentSeries: null, isConfirmLoading: false }, () => {
            // this.changeEditStatus(true)
          })
        }, errorMsg => {
          this.setState({ shouldShowSeriesWarning: false, currentSeries: null, isConfirmLoading: false })
          utilityService.handleError(errorMsg)
        })
      }
    }
  }

  handleGenreChange = value => {
    const { details } = this.state
    details.genres = value
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleSubtitleChange = value => {
    const { details } = this.state
    details.subtitleLanguages = value
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleAudioLanguageChange = value => {
    const { details } = this.state
    details.audioLanguages = value
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleCaptionChanges = (caption) => {
    const { details } = this.state
    details.closedCaption = caption
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleAutoPublishChanges = (autoPublish) => {
    const { details } = this.state
    details.autoPublish = autoPublish
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  handleRatingChange = (ratingData) => {
    const { details } = this.state
    details.ratings = ratingData
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  onChangeCredits = (credits, isCrewEmpty) => {
    const { details } = this.state
    const isCrew = credits.findIndex(credit => credit.type === 'crew') >= 0
    if (isCrew || isCrewEmpty) {
      const cast = (details.credits || []).filter(credit => credit.type === 'cast')
      const crew = isCrewEmpty ? [] : [...credits]
      details.credits = [...cast, ...crew]
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    } else {
      const crew = (details.credits || []).filter(credit => credit.type === 'crew')
      const cast = [...credits]
      details.credits = [...crew, ...cast]
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    }
  }

  handleProductionStudiosChange = (value) => {
    const { details, tempProductionList } = this.state
    let uniqueList = _.uniqBy(tempProductionList, 'name')
    const isNotCreateProductioStudio = Array.isArray(value)
    if (isNotCreateProductioStudio) {
      const { details } = this.state
      let productionStudios = uniqueList.filter(item => value.includes(item.name))
      productionStudios.sort(function (a, b) {
        return value.indexOf(a.name) - value.indexOf(b.name)
      })
      details.productionStudios = productionStudios
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    } else {
      const newProductionStudio = _.omit(value, 'assetCount')
      if (details.productionStudios) {
        details.productionStudios.push(newProductionStudio)
      } else {
        details.productionStudios = [newProductionStudio]
      }
      this.setState({ details }, () => {
        this.changeEditStatus(true)
      })
    }
  }

  handleCountriesChange = (value) => {
    const { details } = this.state
    details.countries = value
    this.setState({ details }, () => {
      this.changeEditStatus(true)
    })
  }

  onChangeAssetImageCategory = () => {
    const { details, activeImageType, selectedImageId, selectedAssetImageCategory } = this.state
    const { listImageTypes, mediaCategoryList } = this.props
    let selectedCategory = (mediaCategoryList || []).filter(item => item.type === 'IMAGE' && item.isActive && item.id === selectedAssetImageCategory)
    const { images } = details
    const index = images.findIndex(image => image.id === selectedImageId)
    let tempDefault = null
    details.images = images.map(image => {
      const isFilter = (activeImageType === primaryImageType ? (image.type.id === activeImageType || !image.type.id) : activeImageType === image.type.id)
      if (isFilter) {
        if (image.id === selectedImageId) {
          image.category = selectedCategory
        }
      }
      return image
    })
    const cropPos = listImageTypes.find(item => item.id === activeImageType)
    const variables = {
      id: selectedImageId,
      assetId: details.id,
      imageTypeId: activeImageType,
      mediaId: images[index].mediaId,
      cropPosition: cropPos.enabledAssetTypes.find(item => item.assetType === details.type).cropPosition,
      categoryId: selectedAssetImageCategory
    }
    this.setState({ isAssetImageLoading: true, shouldShowCategoryWarning: false })
    this.props.updateAssetImage(variables).then(() => {
      this.setState({ shouldRefreshHistory: true, isAssetImageLoading: false, selectedAssetImageCategory: '', selectedImageId: '' }, () => {
        this.setState({ shouldRefreshHistory: false })
      })
    }, (error) => {
      details.images = images.map(image => {
        const isFilter = (activeImageType === primaryImageType ? (image.type.id === activeImageType || !image.type.id) : activeImageType === image.type.id)
        if (isFilter) {
          if (tempDefault && image.id === tempDefault.id) {
            // image.category = image.category
          }
        }
        return image
      })
      this.setState({ details, isAssetImageLoading: false, selectedAssetImageCategory: '', selectedImageId: '' })
      utilityService.handleError(error)
    })

    this.setState({ details })
  }

  toggleWarningImageCategoryMessage = () => {
    this.setState({ selectedAssetImageCategory: '', shouldShowCategoryWarning: false, selectedImageId: '' })
  }

  handleAssetImageCategoryChange = (option, selectedImage) => {
    this.setState({ selectedAssetImageCategory: option, shouldShowCategoryWarning: true, selectedImageId: selectedImage.id })
  }

  render () {
    const { showSidebar, toggleSidebar, onDuplicateAsset, assetId, isArchive, appliedFilter, clearFilterDetails, channelList,
      listAssetTypes, mediaCategoryList, listImageTypes, project, listGenreDetails, listProductionStudio, listRolesDetails,
      listPersonsDetails, listCountryDetails, listLanguageDetails, listRatingDetails, onPersonSearch, onRoleSearch, onProductionStudioSearch, productionSearch } = this.props
    const { details, isSaving, isEdited, isLoading, newDefaultImage, isOldSlug, shouldShowWarning, shouldShowHyperionWarning, shouldShowSeriesWarning,
      isConfirmLoading, isModified, isModifiedConfirm, shouldRefreshHistory, lastUpdatedUser, uploadingFiles,
      isAssetImageLoading, isVodTriggerSuccess, isAssetVideoLoading, shouldShowCategoryWarning, canTriggerAppleNewsURL } = this.state

    if (this.isEditingInput) {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout)
      }
      this.blurTimeout = setTimeout(() => {
        if (this.isEditingInput) {
          this.onInputFieldBlur()
        }
      }, 10000)
    }
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['ASSET_MANAGER']
          this.isUpdateDisable = userPermissions.indexOf('UPDATE') === -1
          return <Row className='asset-editor'>
            <Col
              className={`asset-details ${showSidebar ? 'small' : ''}`}
              xxl={{ span: 24 - (showSidebar ? 8 : 0) }}
              xl={{ span: 24 - (showSidebar ? 8 : 0) }}
              lg={{ span: 24 - (showSidebar ? 6 : 0) }}
              md={{ span: 24 - (showSidebar ? 6 : 0) }}
              xs={{ span: 24 - (showSidebar ? 6 : 0) }}
            >
              {(assetId && details) || isLoading ? <AssetDetails isLoading={isLoading}
                details={_.cloneDeep(details)}
                isSaving={isSaving}
                isEdited={isEdited}
                isArchive={isArchive}
                appliedFilter={appliedFilter}
                isInvalidTitle={this.invalidTitle}
                isInvalidSlug={this.invalidSlug || isOldSlug}
                newDefaultImage={newDefaultImage}
                onInputFieldFocus={this.onInputFieldFocus}
                onInputFieldBlur={this.onInputFieldBlur}
                onCloseContent={this.onCloseContent}
                onAuthorChange={this.onAuthorChange}
                onCategorySelect={this.onCategorySelect}
                onOrderChange={this.onOrderChange}
                onRichTextChange={this.onRichTextChange}
                onSocialShareChange={this.onSocialShareChange}
                onContentPlaceholderChange={this.onContentPlaceholderChange}
                toggleSidebar={toggleSidebar}
                handleSeoDetailsChange={this.handleSeoDetailsChange}
                handleGeneralDetailsChange={this.handleGeneralDetailsChange}
                handleMetaDetailsChange={this.handleMetaDetailsChange}
                onGeneralTagChange={this.onGeneralTagChange}
                onSeoKeywordChange={this.onSeoKeywordChange}
                onEmbedScriptChange={this.onEmbedScriptChange}
                onEmbedScriptCaptionChange={this.onEmbedScriptCaptionChange}
                showSidebar={showSidebar}
                onAddMedia={this.onAddMedia}
                onChangeDefaultImage={this.onChangeDefaultImage}
                onAddContent={this.onAddContent}
                onCloseImage={this.onCloseImage}
                onCloseVideo={this.onCloseVideo}
                submitAssetDetails={this.checkForModification}
                onImageCaptionChange={this.onImageCaptionChange}
                onDeleteAsset={this.onDeleteAsset}
                onDuplicateAsset={onDuplicateAsset}
                clearFilterDetails={clearFilterDetails}
                updateChannelProgram={this.updateChannelProgram}
                onMatchSelect={this.onMatchSelect}
                updateStatus={this.updateStatus}
                updateSubscriptionStatus={this.updateSubscriptionStatus}
                createdBucket={this.createdBucket}
                onEditRelatedAssets={this.onEditRelatedAssets}
                channelList={channelList}
                onVodTrigger={this.onVodTrigger}
                assetId={this.props.assetId}
                savedLiveStatus={this.savedLiveStatus}
                onMakeDisplayVideo={this.onChangeDefaultVideo}
                onTriggerVod={this.onTriggerVod}
                listAssetTypes={_.cloneDeep(listAssetTypes)}
                handleFileUpload={this.onAddImageFromLocal}
                updateMediaDetails={this.updateMediaDetails}
                onImageTypeChange={this.onImageTypeChange}
                uploadingFiles={uploadingFiles}
                isAssetImageLoading={isAssetImageLoading}
                mediaCategoryList={mediaCategoryList}
                listImageTypes={listImageTypes}
                isVodTriggerSuccess={isVodTriggerSuccess}
                isAssetVideoLoading={isAssetVideoLoading}
                project={project}
                listGenreDetails={listGenreDetails}
                listProductionStudio={listProductionStudio}
                listRolesDetails={listRolesDetails}
                listPersonsDetails={listPersonsDetails}
                listCountryDetails={listCountryDetails}
                listLanguageDetails={listLanguageDetails}
                listRatingDetails={listRatingDetails}
                handleHyperionGeneralChange={this.handleHyperionGeneralChange}
                handleChangeProductionDateTime={this.handleChangeProductionDateTime}
                handleChangeReleaseDateTime={this.handleChangeReleaseDateTime}
                handleDurationChange={this.handleDurationChange}
                handleSeasonSelect={this.handleSeasonSelect}
                handleSeriesSelect={this.handleSeriesSelect}
                handleGenreChange={this.handleGenreChange}
                handleSubtitleChange={this.handleSubtitleChange}
                handleAudioLanguageChange={this.handleAudioLanguageChange}
                handleCaptionChanges={this.handleCaptionChanges}
                handleRatingChange={this.handleRatingChange}
                onChangeCredits={this.onChangeCredits}
                refetchHistory={this.refetchHistory}
                handleProductionStudiosChange={this.handleProductionStudiosChange}
                handleCountriesChange={this.handleCountriesChange}
                onPersonSearch={onPersonSearch}
                onRoleSearch={onRoleSearch}
                onProductionStudioSearch={onProductionStudioSearch}
                productionSearch={productionSearch}
                handleAssetImageCategoryChange={this.handleAssetImageCategoryChange}
                handleAutoPublishChanges={this.handleAutoPublishChanges}
              /> : null}
            </Col>
            <Col className='asset-side-bar' xxl={{ span: (showSidebar ? 8 : 0) }} xl={{ span: (showSidebar ? 8 : 0) }} lg={{ span: (showSidebar ? 6 : 0) }} md={{ span: (showSidebar ? 6 : 0) }} xs={{ span: (showSidebar ? 6 : 0) }}>
              {assetId ? <AssetSideBar
                onPublishButtonClick={this.onPublishButtonClick}
                onPublishButtonActiveChange={this.onPublishButtonActiveChange}
                assetId={assetId}
                details={details}
                isSaving={isSaving || shouldRefreshHistory}
                canTriggerAppleNewsURL={canTriggerAppleNewsURL}
                listAssetTypes={listAssetTypes}
                mediaCategoryList={mediaCategoryList}
                project={project}
                appleClient={this.props.appleClient}
              /> : null}
            </Col>
            <ConfirmModal
              isVisible={shouldShowWarning}
              title={'Change Match'}
              message={userMessages.ASSET_LINKED_MATCH_WARNING}
              isLoading={isConfirmLoading} rightButtonText={'Confirm'}
              handleCancel={this.toggleWarningMessage}
              handleSubmit={this.changeMatch}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false} />
            <ConfirmModal
              isVisible={shouldShowHyperionWarning}
              title={'Update Season'}
              message={userMessages.ASSET_LINKED_SEAONS_WARNING}
              isLoading={isConfirmLoading} rightButtonText={'Confirm'}
              handleCancel={this.toggleWarningHyperionMessage}
              handleSubmit={this.handleSeasonSumbit}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false} />
            <ConfirmModal
              isVisible={shouldShowSeriesWarning}
              title={'Update Series'}
              message={userMessages.ASSET_LINKED_SERIES_WARNING}
              isLoading={isConfirmLoading} rightButtonText={'Confirm'}
              handleCancel={this.toggleWarningSeriesMessage}
              handleSubmit={this.handleSeriesSubmit}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false} />
            <ConfirmModal
              isVisible={isModified}
              title={'Warning'}
              message={`This asset has been modified by ${lastUpdatedUser}. Please refetch asset details to get the latest updates`}
              isLoading={isModifiedConfirm}
              rightButtonText={'Refetch Data'}
              handleSubmit={this.reloadAsset}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled
              isCancelButtonInvisible />
            <ConfirmModal
              isVisible={shouldShowCategoryWarning}
              title={'Update Asset Image Category'}
              message={userMessages.UPDATE_CATEGORY_ASSET_IMAGE_WARNING}
              isLoading={isConfirmLoading} rightButtonText={'Confirm'}
              handleCancel={this.toggleWarningImageCategoryMessage}
              handleSubmit={this.onChangeAssetImageCategory}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false} />
          </Row>
        }}
      </AppContext.Consumer>
    )
  }
}

AssetEditor.propTypes = {
  /** asset id of selected asset. */
  assetId: PropTypes.string,
  /** asset details of selected asset. */
  details: PropTypes.object,
  /** force save details bool of AssetManager. */
  shouldSave: PropTypes.bool,
  /** toggle side bar value of AssetManager. */
  showSidebar: PropTypes.bool,
  /** toggle side bar action of AssetManager. */
  toggleSidebar: PropTypes.func,
  /** change content positon graphQL mutation action of AssetManager. */
  changeContentPosition: PropTypes.func.isRequired,
  /** change asset details mutation action of AssetManager. */
  updateAsset: PropTypes.func.isRequired,
  /** loading status of asset details api of AssetManager. */
  assetIsLoading: PropTypes.bool,
  /** change asset details mutation action of AssetManager. */
  changeEditStatus: PropTypes.func,
  /** delete asset  ArticleManager. */
  onDeleteAsset: PropTypes.func,
  /** Boolean for discarding cache changes */
  shouldDiscard: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryGetAssetDetails,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        sessionStorage.setItem('AssetName', props.data.getAsset ? props.data.getAsset.title : '')
        return {
          details: props.data.getAsset,
          assetIsLoading: props.data.loading,
          detailsError: props.data.error ? props.data.error.message : null,
          fetchAssetDetails: () => {
            return props.data.refetch()
          },
          subscribeToChangeInAsset: (assetId) => subscribeChangeInAsset(props.data.subscribeToMore, props, assetId)
        }
      }
    }
  ),
  graphql(
    QueryGetUpdated,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project }
        }
      },
      props: (props) => {
        return {
          updatedAt: props.data.getAsset ? props.data.getAsset.updatedAt : '',
          fetchUpdatedDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    QueryGetVodStatus,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project }
        }
      },
      props: (props) => {
        return {
          // vodStatus: props.data.getAsset,
          fetchVodStatus: (id) => {
            return props.data.refetch({ id, project: props.project })
          }
        }
      }
    }
  ),
  graphql(
    MutationRearrangeContent,
    {
      props: (props) => ({
        changeContentPosition: (contentsArray) => {
          return props.mutate({
            variables: { contents: contentsArray, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationUpdateArticle,
    {
      props: (props) => ({
        updateAsset: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    QueryGetSlug,
    {
      options: ({ urlSlug, project }) => {
        return {
          variables: { slug: 'list', project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          checkUrlValidation: (slug) => {
            return props.data.refetch({ slug })
          }
        }
      }
    }
  ),
  graphql(
    MutationLinkAssetProgram,
    {
      options: ({ assetId, project }) => {
        return {
          update: (cache, { data: { linkAssetProgram } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
            if (cacheData.getAsset && cacheData.getAsset) {
              cacheData.getAsset.program = linkAssetProgram && linkAssetProgram.length ? linkAssetProgram[0] : null
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData,
              variables: { id: assetId, project }
            })
          }
        }
      },
      props: (props) => ({
        linkAssetProgram: (assetId, programs) => {
          return props.mutate({
            variables: { assetId, programs }
          })
        }
      })
    }
  ),
  graphql(
    MutationTriggerVOD,
    {
      options: ({ assetId, project }) => {
        return {
          update: (cache, { data: { triggerLiveToVod } }) => {
            if (triggerLiveToVod) {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
              if (cacheData.getAsset && cacheData.getAsset) {
                const index = (cacheData.getAsset.vodJobs || []).findIndex((item) => item.id === triggerLiveToVod.id)
                if (index === -1) { cacheData.getAsset.vodJobs.push(triggerLiveToVod) } else { cacheData.getAsset.vodJobs[index] = triggerLiveToVod }
              }
              cache.writeQuery({
                query: QueryGetAssetDetails,
                data: cacheData,
                variables: { id: assetId, project }
              })
            } else {
              message.error(userMessages.ALREADY_ADDED_VIDEO)
            }
          }
        }
      },
      props: (props) => ({
        triggerLiveToVod: (variables) => {
          return props.mutate({ variables })
        }
      })
    }
  ),
  graphql(
    QueryUploadImage,
    {
      options: ({ files, project }) => {
        const fileList = (files.files || []).map(item => {
          return {
            fileName: item.name,
            contentType: item.type,
            type: 'IMAGE'
          }
        })
        return {
          fetchPolicy: 'network-only',
          variables: { files: fileList, project }
        }
      },
      skip: ({ files }) => {
        return (!files || !files.files || !files.files.length)
      },
      props: (props) => {
        const { data, ownProps } = props
        const uploadData = !data.loading && data.getUploadUrl ? data.getUploadUrl.map((item, index) => {
          const currentItem = ownProps.files.files[index]
          return { ...item,
            fileName: currentItem.name,
            contentType: currentItem.type,
            aspectRatio: [],
            fileSize: currentItem.fileSize,
            category: currentItem.category,
            type: 'IMAGE' }
        }) : []
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          ownProps.clearFileName()
          utilityService.handleError(data.error)
        }
        return { uploadData: _.map(uploadData, _.clone), isUploadError }
      }
    }
  ),
  graphql(
    MutationCreateMedia,
    {
      options: ({ searchString, filter, mediaType }) => {
        return { update: (cache, { data: { createMedia } }) => {
          // const variables = utilityService.getFormattedMediaFilter(searchString, filter, mediaType)
          // const cacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterMedia, variables }))
          // if (cacheData && cacheData.listMedia && cacheData.listMedia.items) { cacheData.listMedia.items.splice(0, 0, createMedia) }
          // cache.writeQuery({
          //   query: QueryFilterMedia,
          //   data: cacheData,
          //   variables
          // })
        }
        }
      },
      props: (props) => ({
        createMedia: (key, name, type, tags) => {
          return props.mutate({
            variables: { key, name, type, tags, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationAddImageToAsset,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { addAssetImages } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({
            query: QueryGetAssetDetails,
            variables: { id: assetId, project },
            returnPartialData: true
          }))
          if (cacheData.getAsset) {
            if (cacheData.getAsset.images) { cacheData.getAsset.images = addAssetImages && addAssetImages.length ? [...cacheData.getAsset.images, ...addAssetImages] : [] } else { cacheData.getAsset.images = addAssetImages && addAssetImages.length ? [...addAssetImages] : [] }
            const tempDefaultImage = addAssetImages.filter(item => item.type.id === primaryImageType && item.isDefault === 'true')
            if (!cacheData.getAsset.defaultMedia && (tempDefaultImage || []).length) {
              cacheData.getAsset.defaultMedia = tempDefaultImage[0]
            }
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        addAssetImages: (imageContent) => {
          return props.mutate({
            variables: { imageContent, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationAddVideoToAsset,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { addAssetVideos } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
          if (cacheData.getAsset) {
            if (cacheData.getAsset.videos) {
              cacheData.getAsset.videos = addAssetVideos && addAssetVideos.length ? [...cacheData.getAsset.videos, ...addAssetVideos] : []
            } else { cacheData.getAsset.videos = addAssetVideos && addAssetVideos.length ? [...addAssetVideos] : [] }
            const defaultVideo = addAssetVideos.filter(item => item.isDefault === 'true')
            if (defaultVideo.length) {
              cacheData.getAsset.duration = defaultVideo[0].duration
            }
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        addAssetVideos: (videoContent) => {
          return props.mutate({
            variables: { videoContent, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAssetImage,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { updateAssetImage } }) => {
          try {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project }, returnPartialData: true }))
            if (cacheData.getAsset) {
              cacheData.getAsset.images = (cacheData.getAsset.images || []).map(image => {
                if (image.id === updateAssetImage.id) { image = updateAssetImage } else if (image.type.id === updateAssetImage.type.id) { image.isDefault = 'false' }
                return image
              })
              if (updateAssetImage.isDefault === 'true' && updateAssetImage.type.id === primaryImageType) {
                cacheData.getAsset.defaultMedia = updateAssetImage
              }
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData,
              variables: { id: assetId, project }
            })
          } catch (error) {
            console.log(error)
          }
        }
        }
      },
      props: (props) => ({
        updateAssetImage: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAssetVideo,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { updateAssetVideo } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project }, returnPartialData: true }))
          if (cacheData.getAsset) {
            cacheData.getAsset.videos = (cacheData.getAsset.videos || []).map(video => {
              if (video.id === updateAssetVideo.id) { video = updateAssetVideo } else { video.isDefault = 'false' }
              return video
            })
            const defaultVideo = (cacheData.getAsset.videos || []).filter(item => item.isDefault === 'true')
            if (defaultVideo.length) {
              cacheData.getAsset.duration = defaultVideo[0].duration
            }
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        updateAssetVideo: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteAssetImage,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { deleteAssetImages } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project }, returnPartialData: true }))

          if (cacheData.getAsset) {
            cacheData.getAsset.images = (cacheData.getAsset.images || []).filter(image => !(deleteAssetImages || []).includes(image.id))
          }
          if ((cacheData.getAsset.images || []).length === 0 && cacheData.getAsset.defaultMedia && (deleteAssetImages || []).includes(cacheData.getAsset.defaultMedia.id)) {
            cacheData.getAsset.defaultMedia = null
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        deleteAssetImages: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteAssetVideo,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { deleteAssetVideos } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project }, returnPartialData: true }))

          if (cacheData.getAsset) {
            cacheData.getAsset.videos = (cacheData.getAsset.videos || []).filter(video => !(deleteAssetVideos || []).includes(video.id))
            if (!cacheData.getAsset.videos.length) {
              cacheData.getAsset.duration = '0'
            }
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        deleteAssetVideos: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateCredits,
    {
      props: (props) => ({
        updateAssetCreditLinks: (input, assetId, project) => {
          return props.mutate({
            variables: { input, assetId, project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateSeries,
    {
      props: (props) => ({
        updateSeasonSeriesLink: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteSeries,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { deleteSeriesSeasons } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))

          if (cacheData.getAsset) {
            cacheData.getAsset.series = null
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        deleteSeriesSeasons: (input) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteSeason,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { deleteSeasonEpisodes } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project }, returnPartialData: true }))
          if (cacheData.getAsset) {
            cacheData.getAsset.season = null
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId, project }
          })
        }
        }
      },
      props: (props) => ({
        deleteSeasonEpisodes: (input) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateSeason,
    {
      props: (props) => ({
        updateEpisodeSeasonLink: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryMediaCategory,
    {
      options: ({ project }) => {
        return {
          variables: { project }
        }
      },
      props: (props) => {
        return {
          mediaCategoryList: props.data && props.data.listMediaCategories && props.data.listMediaCategories.length
            ? props.data.listMediaCategories : []
        }
      }
    }
  ),
  graphql(
    QueryGetImageType,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        return {
          listImageTypes: props.data.listImageTypes && props.data.listImageTypes.length ? props.data.listImageTypes : []
        }
      }
    }
  ),
  graphql(
    QueryListGenreDetails,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        return {
          listGenreDetails: props.data.listGenre && props.data.listGenre.items && props.data.listGenre.items.length ? props.data.listGenre.items : []
        }
      }
    }
  ),
  graphql(
    QueryProductionStudio,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ productionSearch, project }) => {
        const search = productionSearch ? { keyword: productionSearch, fields: ['name'] } : null
        const variables = { search, offset: 0, limit: 30, project: project }
        return {
          variables: variables,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          listProductionStudio: props.data.listProductionStudio && props.data.listProductionStudio.items && props.data.listProductionStudio.items.length ? props.data.listProductionStudio.items : [],
          isLoading: props.data.loading || !props.data.listProductionStudio,
          totalCount: props.data.listProductionStudio ? props.data.listProductionStudio.totalCount : 0
          // getMoreProductions: (page) => {
          //   return data.fetchMore({
          //     fetchPolicy: 'network-only',
          //     variables: {
          //       offset: page
          //     },
          //     updateQuery: (prev, { fetchMoreResult }) => {
          //       if (!fetchMoreResult) return prev
          //       const newList = [...prev.listProductionStudio.items, ...fetchMoreResult.listProductionStudio.items]
          //       prev.listProductionStudio.items = newList
          //       return prev
          //     }
          //   })
          // }
        }
      }
    }
  ),
  graphql(
    QueryRoleDetails,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ roleSearch, project }) => {
        const searchFilter = {
          name: { match: roleSearch }
        }
        const filter = roleSearch ? searchFilter : null
        return {
          variables: { filter, limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          listRolesDetails: props.data.listRoles && props.data.listRoles.items && props.data.listRoles.items.length ? props.data.listRoles.items : []
        }
      }
    }
  ),
  graphql(
    QueryPersonDetails,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ personSearch, project }) => {
        const searchFilter = {
          name: { match: personSearch }
        }
        const filter = personSearch ? searchFilter : null
        return {
          variables: { filter, limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          listPersonsDetails: props.data.listPerson && props.data.listPerson.items && props.data.listPerson.items.length ? props.data.listPerson.items : []
        }
      }
    }
  ),
  graphql(
    QueryCountryDetails,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ project }) => {
        const variables = { limit: 999, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        return {
          listCountryDetails: props.data.listCountry && props.data.listCountry.items && props.data.listCountry.items.length ? props.data.listCountry.items : []
        }
      }
    }
  ),
  graphql(
    QueryLanguageDetails,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ project }) => {
        const variables = { limit: 999, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        return {
          listLanguageDetails: props.data.listLanguage && props.data.listLanguage.items && props.data.listLanguage.items.length ? props.data.listLanguage.items : []
        }
      }
    }
  ),
  graphql(
    QueryRatingDetails,
    {
      skip: ({ project }) => {
        return project === 'vcms'
      },
      options: ({ project }) => {
        const variables = { limit: 999, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        return {
          listRatingDetails: props.data.listRating && props.data.listRating.items && props.data.listRating.items.length ? props.data.listRating.items : []
        }
      }
    }
  )
)(withApollo(AssetEditor)))
