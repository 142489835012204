import gql from 'graphql-tag'

export default gql(`
    mutation deletePageVersion($id: ID!  $project: String ) {
        deletePageVersion(
          id: $id
          project: $project
        ) {
            id
            isPublished
            publishStartDate
            publishEndDate
        }
    }`
)
