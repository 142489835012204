import gql from 'graphql-tag'

export default gql(`
query listChannel($filter: TableChannelFilterInput $search: SearchInput){
    listChannels(
        limit: 100,
        filter: $filter
        search: $search
    ){
        items{
            id
            name
            channelCode            
            isArchived
            site
        }
    }
}`)
