import React from 'react'
import PropTypes from 'prop-types'

const OfferAttributeIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g transform='translate(-887 -702) translate(500 647) translate(387 56)'>
            <ellipse cx='72.5' cy='74' fill='#F5F5F5' rx='72.5' ry='16' />
            <g stroke='#D9D9D9'>
              <path d='M104 28.801L81.061 2.84C79.961 1.07 78.353 0 76.66 0H27.341c-1.694 0-3.301 1.07-4.402 2.837L0 28.803v20.854h104V28.801z' transform='translate(21)' />
              <path fill={color} d='M73.734 35.959c0-3.623 2.247-6.614 5.035-6.616H104V70.28c0 4.792-2.984 8.719-6.67 8.719H6.67C2.984 79 0 75.07 0 70.28V29.344h25.231c2.788 0 5.035 2.986 5.035 6.609v.05c0 3.622 2.272 6.547 5.058 6.547h33.352c2.786 0 5.058-2.952 5.058-6.575v-.015h0z' transform='translate(21)' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

)

OfferAttributeIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

OfferAttributeIcon.defaultProps = {
  width: 15,
  height: 13,
  color: '#343A40'
}

export default OfferAttributeIcon
