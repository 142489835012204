import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker as AntDatePicker } from 'antd'
import moment from 'moment'
import './../../ui.style.scss'

const DatePicker = ({ date, dateFormat, onChange, disabledDate, placeholder, allowClear, use12Hours, disabledTime, disabled, onOpenChange, ...props }) => {
  return (
    <AntDatePicker
      className='date-picker'
      use12Hours={use12Hours}
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      allowClear={allowClear}
      showTime={{ use12Hours: use12Hours, onAmPmChange: () => {}, format: 'HH:mm' }}
      value={date ? moment(date, dateFormat) : null}
      format={dateFormat}
      onChange={onChange}
      onOpenChange={onOpenChange || (() => {})}
      placeholder={placeholder}
      disabled={disabled} />
  )
}

DatePicker.propTypes = {
  /** Date value of Datepicker. */
  date: PropTypes.string,
  /** Date format of Datepicker. */
  dateFormat: PropTypes.string,
  /** Date change action of Datepicker. */
  onChange: PropTypes.func,
  /** Placeholder of Datepicker. */
  placeholder: PropTypes.string,
  /** Date display decision maker function of Datepicker. */
  disabledDate: PropTypes.func,
  /** Boolean for display clear icon */
  allowClear: PropTypes.bool
}

DatePicker.defaultProps = {
  dateFormat: 'YYYY-MM-DD',
  allowClear: true,
  use12Hours: true
}

export default DatePicker
