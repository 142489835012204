import gql from 'graphql-tag'

export default gql(`
    mutation createOrUpdateIpWhitelistingItem( $input: [CreateOrUpdateIpWhitelistingItemInput] ) {
        createOrUpdateIpWhitelistingItem(
            input: $input
        ) {
            ip
        }
    }`
)
