import gql from 'graphql-tag'

export default gql(`
    query getDRMSettings{
        getDRMLicenseSettings{
            apiKey
            licenseUrl
        }
    }`
)
