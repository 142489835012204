import gql from 'graphql-tag'

export default gql(`
    mutation updateAssetCreditLinks($input: [UpdateCreditLinkInput] $assetId: ID $project:String) {
        updateAssetCreditLinks(
            input: $input
            assetId: $assetId
            project: $project
        ){
            id
        }
    }`
)
