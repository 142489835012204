import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import MetaDefaultDropDown from '../inputs/MetaDefaultDropDown'
import InputNumber from '../inputs/InputNumber'
import InputCustom from '../inputs/InputCustom'
import Accordion from '../../dataDisplay/Accordion'
import { Skeleton, Switch, Tooltip } from 'antd'
import _, { isEmpty } from 'lodash'
import InformationIcon from '../../general/icons/InformationIcon'

class CreateMetaComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      metaFieldArray: null
    }
  }

  getAssetMetaFields = (metaData, meta, index, assetId, showInfoIcon) => {
    const { handleMetaDetailsChange } = this.props
    const selectedMeta = (metaData || []).find(item => item.displayName === meta.displayName && item.type === meta.type)
    if (meta.type === 'BOOLEAN') {
      return <div className='switch-container input-field' key={index}>
        <div className='switch-inner-container'>
          <span>{meta.displayTitle || meta.displayName} </span>
          {
            meta.tooltip &&
            <Tooltip placement='right' title={meta.tooltip} >
              <span className='asset-info-icon-boolean'><InformationIcon /></span>
            </Tooltip>
          }
          <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => handleMetaDetailsChange(meta, value)} />
        </div>
      </div>
    } else if (meta.type === 'STRING') {
      if (meta.values && !isEmpty(meta.values)) {
        return <div className='input-field'>
          <label className='input-title'>{meta.displayTitle || meta.displayName} {meta.isRequired ? <span className='mandatory'>*</span> : ''}</label>
          {
            meta.tooltip &&
            <Tooltip placement='right' title={meta.tooltip} >
              <span className='asset-info-icon'><InformationIcon /></span>
            </Tooltip>
          }
          <MetaDefaultDropDown
            key={index}
            title={meta.displayTitle || meta.displayName}
            options={meta.values}
            meta={meta}
            placeholder={`Select ${meta.displayTitle || meta.displayName}`}
            inputName='title'
            isRequired={meta.isRequired}
            selectedValue={selectedMeta && selectedMeta.value ? [selectedMeta.value] : []}
            handleChange={handleMetaDetailsChange}
            isError={selectedMeta ? selectedMeta.isError : false}
            assetId={assetId}
            parentCompoent='asset-container'
            mode={'default'}
            allowClear
          />
        </div>
      } else {
        return <InputCustom
          key={index}
          tooltip={meta.tooltip}
          title={meta.displayTitle || meta.displayName}
          placeholder={`Enter ${meta.displayTitle || meta.displayName}`}
          inputName='title'
          isRequired={meta.isRequired}
          value={selectedMeta ? selectedMeta.value : ''}
          handleChange={({ target }) => handleMetaDetailsChange(meta, target.value)}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={`${meta.displayTitle || meta.displayName} is a mandatory field`}
        />
      }
    } else if (meta.type === 'INTEGER') {
      return <InputNumber
        limit={16}
        key={index}
        tooltip={meta.tooltip}
        title={meta.displayTitle || meta.displayName}
        placeholder={`Enter ${meta.displayTitle || meta.displayName}`}
        inputName='title'
        isRequired={meta.isRequired}
        value={selectedMeta ? parseInt(selectedMeta.value) : 0}
        handleChange={(value) => handleMetaDetailsChange(meta, value)}
        isError={selectedMeta ? selectedMeta.isError : false}
        errorMessage={`${meta.displayTitle || meta.displayName} is a mandatory field`}
      />
    } else if (meta.type === 'LIST') {
      return <div className='input-field' id='input-field'>
        <label className='input-title'>{meta.displayTitle || meta.displayName}
          {meta.isRequired ? <span className='mandatory'>*</span> : ''}</label>
        {
          meta.tooltip &&
          <Tooltip placement='right' title={meta.tooltip} >
            <span className='asset-info-icon'><InformationIcon /></span>
          </Tooltip>
        }
        <MetaDefaultDropDown
          key={index}
          title={meta.displayTitle || meta.displayName}
          options={meta.values}
          meta={meta}
          placeholder={`Select ${meta.displayTitle || meta.displayName}`}
          inputName='title'
          isRequired={meta.isRequired}
          selectedValue={selectedMeta ? selectedMeta.fieldValues : []}
          handleChange={handleMetaDetailsChange}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          assetId={assetId}
          parentCompoent='asset-container'
        />
      </div>
    }
  }

  UNSAFE_componentWillReceiveProps () { // eslint-disable-line camelcase
    if (this.props.assetConfig && this.props.assetConfig.metaFields) {
      const arrayOfArrays = _.groupBy(this.props.assetConfig.metaFields, item => (item.section ? item.section.id : null))
      this.setState({ metaFieldArray: arrayOfArrays })
    }
  }

  render () {
    const { details, width } = this.props
    const { metaFieldArray } = this.state
    return (
      <>
        {
          metaFieldArray ? Object.keys(metaFieldArray).map(key => {
            const value = metaFieldArray[key]
            if (key === 'null') return null
            return (
              <Accordion
                title={value[0].section.title}
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    <div className='general-details' id='general-details'>
                      {
                        value.map((item, index) => {
                          return this.getAssetMetaFields(details.meta, item, index, details.id)
                        })
                      }
                    </div>
                  </Skeleton>
                }
              />
            )
          }) : null
        }
      </>
    )
  }
}

CreateMetaComponent.propTypes = {
  /** Details of list ratings */
  listRatingDetails: PropTypes.array,
  /** Details of asset ratings */
  ratings: PropTypes.array
  /** function to handle rating change */
}

CreateMetaComponent.defaultProps = {
}

export default CreateMetaComponent
