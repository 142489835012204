import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryRatingDetails from '../../../graphQL/admin/entertainmentData/listRatings'
import MutationDeleteRating from '../../../graphQL/admin/entertainmentData/deleteRating'

import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import CreateParentRatingModal from '../../../components/ui/dataEntry/other/CreateParentRatingModal'
import { utilityService } from './../../../services/UtilityService'

const deleteParentalRatingMessage = {
  title: 'DELETE PARENTAL RATING',
  firstMessage: 'Are you sure you want to delete this Parental Rating?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class ParentRatingList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedType: null,
      isLoading: !(props.listRatingTypes && props.listRatingTypes.length),
      isShowParentRatingModal: false,
      showDeleteParentRatingModal: false,
      selectedRatingType: undefined,
      deleteLoading: false,
      isDeleteSuccess: false,
      isEdit: false,
      isDragStart: false,
      expandedRowKeys: [],
      parentRatingList: props.listRatingTypes && props.listRatingTypes.length ? props.listRatingTypes : []
    }
    this.columns = [
      {
        title: 'Parental Rating Name',
        dataIndex: 'scheme',
        key: 'scheme',
        width: '37%'
      },
      {
        title: 'Usage Count',
        dataIndex: 'assetCount',
        width: '50%',
        key: 'assetCount',
        render: (assetCount) => this.renderParentRatingCount(assetCount)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.innerColumns = [
      {
        title: 'Genral Rating Values',
        dataIndex: 'title',
        key: 'title',
        width: '90%',
        render: (title) => <div className='inner-header'>{title}</div>
      }
    ]
    this.metaDataColumns = [
      {
        title: '',
        dataIndex: 'title',
        key: 'title',
        width: '100%'
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && newProps.listRatingTypes) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, parentRatingList: _.cloneDeep(newProps.listRatingTypes) })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.listRatingTypes, this.props.listRatingTypes)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.listRatingTypes)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'transfer', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedType) {
        this.setState({ openedType: null })
      }
    }

    showCreateParentRatingModal = (isShowParentRatingModal) => {
      this.setState({ isShowParentRatingModal, selectedRatingType: null, isEdit: false })
    }

    toggleShowOptions = id => {
      if (this.state.openedType === id) {
        this.setState({ openedType: null })
      } else {
        this.setState({ openedType: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { listRatingTypes } = this.props
      const selectedRatingType = listRatingTypes.find(item => item.id === id)
      if (!selectedRatingType) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ isShowParentRatingModal: true, selectedRatingType, isEdit: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteParentRatingModal: true, selectedRatingType })
      }
      this.setState({ openedType: null })
    }

    loadMoreParentRating = () => {
      if (!this.props.totalCount === this.props.listRatingTypes.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreRatings(this.props.listRatingTypes.length))
    }

    hideDeleteParentRatingModal = () => {
      this.setState({ isDeleteSuccess: true, selectedRatingType: null }, () => {
        this.setState({ showDeleteParentRatingModal: false, isDeleteSuccess: false })
      })
    }

    renderParentRatingCount = (count) => {
      if (!count) {
        return '0'
      } else {
        return count
      }
    }

    onDragEnd = (result) => {
      const startIndex = result.source.index
      const endIndex = result.destination.index
      let { parentRatingList } = this.state
      const [ removed ] = parentRatingList.splice(startIndex, 1)
      removed.trackPosChange = true
      parentRatingList.splice(endIndex, 0, removed)
      parentRatingList = parentRatingList.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({ parentRatingList, isDragStart: false })
    }

    onDragStart = () => {
      const { expandedRowKeys } = this.state
      if (expandedRowKeys.length) { this.setState({ expandedRowKeys: [], isDragStart: true }) } else { this.setState({ isDragStart: true }) }
    }

     dragableBodyRow = (item) => {
       const { isDragStart } = this.state
       const { index } = item
       return <Draggable key={item['data-row-key']} draggableId={item['data-row-key']} index={index} isDragDisabled>
         { (provided, snapshot) => {
           return <tr className={`ant-table-row${isDragStart ? ' dragEnable' : ''}`}
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={getItemStyle(
               snapshot.isDragging,
               provided.draggableProps.style
             )}
           >
             {item.children}
           </tr>
         }}</Draggable>
     }

     handleRowExpand = (record) => {
       this.setState(prevState =>
         prevState.expandedRowKeys.includes(record.id)
           ? {
             expandedRowKeys: prevState.expandedRowKeys.filter(
               key => key !== record.id
             )
           }
           : { expandedRowKeys: [...prevState.expandedRowKeys, record.id] }
       )
     }

     onDeleteParentRatingModal = () => {
       const { selectedRatingType } = this.state
       const { onHistoryUpdate } = this.props
       if (!selectedRatingType) {
         return
       }
       this.setState({ deleteLoading: true })
       this.props.deleteRating(selectedRatingType.id, selectedRatingType.scheme).then(() => {
         message.success('Rating deleted successfully')
         this.setState({ isDeleteSuccess: true, selectedRatingType: null }, () => {
           this.setState({ showDeleteParentRatingModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         onHistoryUpdate()
       }, error => {
         this.setState({ isDeleteSuccess: true, selectedRatingType: null }, () => {
           this.setState({ showDeleteParentRatingModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         utilityService.handleError(error)
       })
     }

     handleAddParentRating = () => {
       this.setState({ isShowParentRatingModal: false, selectedRatingType: null })
     }

    renderData = (id) => {
      let availableOptions = []
      const { openedType } = this.state
      const { listRatingTypes } = this.props
      const selectedRatingType = listRatingTypes.find(item => item.id === id)
      availableOptions = [ ...options ]
      if (selectedRatingType && !(selectedRatingType.isDefault || selectedRatingType.assetCount || this.isDeleteDisabled)) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(1, 1)
      }
      if (this.isCreateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedType === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    renderRatingData = (data) => {
      let newData = data.map(function (data) {
        return {
          title: data
        }
      })
      return <Table
        className={`inner-table`}
        rowKey={record => record.title}
        columns={this.metaDataColumns}
        dataSource={newData}
        pagination={false}
        showHeader={false}
      />
    }

    renderInnerRows = (data) => {
      return <React.Fragment>
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'Rating Values' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderRatingData(data.rating)}
        />
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'Sub Rating Values' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderRatingData(data.subRatings)}
        />
      </React.Fragment>
    }

    render () {
      const { isLoading, listRatingTypes, totalCount, onHistoryUpdate } = this.props
      const { isPaginating, selectedRatingType, expandedRowKeys, parentRatingList, isShowParentRatingModal, showDeleteParentRatingModal, deleteLoading, isDeleteSuccess } = this.state
      let adminItem
      if (selectedRatingType && showDeleteParentRatingModal) {
        adminItem = <div >
          <p>{ selectedRatingType.scheme }</p>
        </div>
      }

      const components = {
        body: {
          row: this.dragableBodyRow
        }
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal parent-rating-container'>
              <div className='admin-list'>
                <FixedTableHeader columnCellList={this.columns} isExpandable />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreParentRating}
                  hasMore={totalCount > listRatingTypes.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                      <Droppable droppableId={'#ParentRatingType_123'}>
                        { (droppableProvided, droppableSnapshot) => (

                          <div className='parent-rating-table-container'
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            <Table
                              className={`general-table ${isPaginating ? 'paginating' : ''}`}
                              rowKey={record => record.id}
                              columns={this.columns}
                              dataSource={parentRatingList}
                              pagination={false}
                              expandedRowRender={(data) => this.renderInnerRows(data)}
                              expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => this.handleRowExpand(record)}
                              components={components}
                              onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow
                              })} />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={() => this.showCreateParentRatingModal(true, true)}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={this.isCreateDisabled}
                />
              </div>

              <CreateParentRatingModal
                isVisible={isShowParentRatingModal}
                handleCancel={() => this.showCreateParentRatingModal(false)}
                handleSubmit={this.handleAddParentRating}
                selectedRatingType={selectedRatingType}
                listApi module='DATA_MANAGER'
                isSubmitDisabled={_.isEmpty(selectedRatingType) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleteSuccess}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteParentRatingModal}
                title={deleteParentalRatingMessage.title}
                firstMessage={deleteParentalRatingMessage.firstMessage}
                secondMessage={deleteParentalRatingMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteParentRatingModal}
                handleSubmit={this.onDeleteParentRatingModal}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

ParentRatingList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryRatingDetails,
    {
      options: ({ project }) => {
        const variables = { limit: 20, offset: 0, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listRatingTypes = data.listRating ? data.listRating.items : []
        return {
          listRatingTypes,
          isLoading: data.loading || !data.listRating,
          totalCount: data.listRating ? data.listRating.totalCount : 0,
          getMoreRatings: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listRating.items, ...fetchMoreResult.listRating.items]
                prev.listRating.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteRating,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deleteRating } }) => {
            const variables = { limit: 20, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryRatingDetails, variables }))
            if (cacheData && cacheData.listRating && cacheData.listRating.items) {
              const index = cacheData.listRating.items.findIndex((item) => deleteRating.id === item.id)
              cacheData.listRating.items.splice(index, 1)
              cacheData.listRating.totalCount = cacheData.listRating.totalCount - 1
            }
            cache.writeQuery({
              query: QueryRatingDetails,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        deleteRating: (id, scheme) => {
          return props.mutate({
            variables: { id, scheme, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(ParentRatingList))
