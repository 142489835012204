import React from 'react'
import PropTypes from 'prop-types'
import { message, Modal } from 'antd'
import Input from './../inputs/Input'
import TagsInput from '../inputs/TagsInput'
import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'
import _ from 'lodash'
class AddUserModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      newTitle: undefined,
      newMailId: undefined,
      disable: true,
      isValidEmail: true,
      selectedGroups: [],
      isSaveEnabled: false,
      searchKey: ''
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (!this.state.isSaveEnabled) {
      if (newProps.userName && !_.isEqual(newProps.userName, this.state.newTitle)) { this.setState({ newTitle: newProps.userName }) }
      if ((newProps.userId && newProps.isFromEdit) && !_.isEqual(newProps.userId, this.state.newMailId)) { this.setState({ newMailId: newProps.userId }) }
      if (newProps.userGroups && !_.isEqual(newProps.userGroups, this.state.selectedGroups)) { this.setState({ selectedGroups: newProps.userGroups }) }
    }
  }

  resetState = () => {
    const state = {
      newTitle: undefined,
      newMailId: undefined,
      disable: true,
      isValidEmail: true,
      isSaveEnabled: false,
      searchKey: '',
      selectedGroups: []
    }
    this.setState({ ...state })
  }

  handleTitleChange =(e) => {
    const { value } = e.target
    this.setState({ newTitle: value, isSaveEnabled: true })
  }

  handleEmailChange =(e) => {
    const { value } = e.target
    const isValidEmail = utilityService.checkEmailValidation(value)
    this.setState({ newMailId: value, isSaveEnabled: true, isValidEmail })
  }

    handleSubmit = () => {
      const { newTitle, newMailId, selectedGroups } = this.state
      this.props.handleSubmit(newTitle, newMailId, selectedGroups)
    }

  onChangeGroup = (changedGroup, isDelete) => {
    let { selectedGroups } = this.state
    if (isDelete) {
      const index = selectedGroups.findIndex(item => item === changedGroup)
      if (index > -1) { selectedGroups.splice(index, 1) }
    } else {
      selectedGroups.push(changedGroup)
    }
    if (selectedGroups.length === 0 && this.props.isFromEdit) {
      message.warn('Removing all user groups would remove the user from this project')
    }
    this.setState({ selectedGroups, isSaveEnabled: true })
  }

  onSearchUserGroup = (searchKey) => {
    this.setState({ searchKey: searchKey.toLowerCase() })
  }

  render () {
    const { visible, handleCancel, title, loading, parentCompoent, okText, isFromEdit, isSubmitDisabled } = this.props
    const { newTitle, newMailId, selectedGroups, isSaveEnabled, isValidEmail, searchKey } = this.state
    const groupList = searchKey ? (_.cloneDeep(this.props.groupList) || []).filter(group => (group.name.toLowerCase()).indexOf(searchKey) > -1) : _.cloneDeep(this.props.groupList)
    const isDisabled = !(newTitle && newMailId && isSaveEnabled && (isFromEdit ? true : selectedGroups.length > 0) && isValidEmail && !isSubmitDisabled)
    return (

      <Modal
        className='general-modal add-user-modal'
        visible={visible}
        closable={false}
        title={title}
        // okButtonProps={{ disabled: disable, loading: loading }} (needed)
        okButtonProps={{ disabled: isDisabled, loading: loading }}
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okText={okText}
        cancelText='Cancel'
        afterClose={this.resetState}
        width='890px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <div className='add-user'>
          <div className='user-input'>
            <Input title='Name' handleChange={this.handleTitleChange} value={newTitle} placeholder='Enter User Name' />
          </div>
          <div className='user-input'>
            <Input title='Email' handleChange={this.handleEmailChange} value={newMailId} placeholder='Enter Email' readOnly={isFromEdit} isError={!isValidEmail} errorMessage={'Invalid email'} />
          </div>
        </div>
        <label className='user-groups'> User Groups </label>
        <TagsInput
          containerClass='tags'
          selectedItems={selectedGroups}
          selectedDisplayItems={selectedGroups}
          keyParameter={'id'}
          tagList={groupList || []}
          onChange={this.onChangeGroup}
          placeholder='Select User Group'
          parentCompoent={parentCompoent}
          displayParameter={'name'}
          mode={'multiple'}
          onSearch={this.onSearchUserGroup}
        />

      </Modal>
    )
  }
}

AddUserModal.propTypes = {
  /** visible action of AddUserModal. */
  visible: PropTypes.bool,
  /** title  of AddUserModal. */
  title: PropTypes.string,
  /** handleCancel action of AddUserModal. */
  handleCancel: PropTypes.func,
  /** List of user groups */
  groupList: PropTypes.array,
  /** Name of the user for edit */
  userName: PropTypes.string,
  /** ID of the user for edit */
  userId: PropTypes.string,
  /** Selected userGroups */
  userGroups: PropTypes.any,
  /** Boolean to indicates from edit or create */
  isFromEdit: PropTypes.bool,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

AddUserModal.defaultProps = {
}

export default AddUserModal
