import gql from 'graphql-tag'

export default gql(`
    query($filter: ListTeamFilterInput $limit: Int){
        listTeams( 
            filter: $filter
            limit: $limit
            ) {
        items {
            id
            externalId
            opId
            title
            code
            city
            country
            officialName
            status
            founded
            shortName
            postalAddress
            addressZip
            tags{
                key
                name
                type
            }
            trigramme
            customOfficialName
            customName
            customFounded
            customCountry
            customShortName
            customStatus
            customCity
            customAddressZip
            customPostalAddress
        }    
    }
}`)
