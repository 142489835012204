import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'

import PublishButton from './../general/buttons/PublishButton'
import DeleteButton from './../general/buttons/DeleteButton'
import EditButton from './../general/buttons/EditButton'
import SearchButton from '../general/buttons/SearchButton'
import CopyLinkButton from './../general/buttons/CopyLinkButton'
import SaveButton from './../general/buttons/SaveButton'
import './../ui.style.scss'
import ButtonContainer from '../general/buttons/ButtonContainer'

const width = ['100%', '100%']

class BucketDetailHeading extends React.Component {
  state = {
    loading: false,
    visible: false
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  render () {
    const { onClick, onAddSearch, deleteBucket, saveBucket, copyLink, editBucket, bucketName, bucketType, status, bucketId, isSaveEnable,
      showPublish, isDisableAddFilter, loading, isUpdateBlocked, isDeleteBlocked, isSelectionBlocking } = this.props
    return (
      <div className='bucket-detail-heading' onClick={onClick} >
        {isSelectionBlocking && <div className='overlay' />}
        {bucketName ? <React.Fragment>
          <div className='cell-left'>
            <p> {bucketName} </p>
            <p> {bucketType} </p>
          </div>
          <div className='cell-right'>
            <p className='bucket-publish '>{ status } </p>
            {bucketId.length > 30 ? <ButtonContainer displayTitle={bucketId} childComponent={<p className='bucket-id'>{ `${bucketId}` } </p>} /> : <p className='bucket-id'>{ `${bucketId}` } </p> }
          </div>
        </React.Fragment> : <Skeleton active title={false} paragraph={{ rows: 2, width: width }} loading />}

        <div className='bottom-icons'>
          <ButtonContainer displayTitle='Save' childComponent={<SaveButton onClick={isSaveEnable ? saveBucket : () => {}} isDisabled={!isSaveEnable || isUpdateBlocked} isLoading={loading} />} />
          <ButtonContainer displayTitle='Add Search Item' childComponent={<SearchButton onClick={onAddSearch} isDisabled={isDisableAddFilter || isUpdateBlocked} />} />
          <ButtonContainer displayTitle='View API Response' childComponent={<CopyLinkButton onClick={copyLink} isDisabled={status === 'DRAFT'} />} />
          <ButtonContainer displayTitle='Publish' childComponent={<PublishButton onClick={showPublish} isDisabled={isUpdateBlocked} />} />
          <ButtonContainer displayTitle='Edit' childComponent={<EditButton onClick={editBucket} isDisabled={isUpdateBlocked || isSaveEnable} />} />
          <ButtonContainer displayTitle='Remove' childComponent={<DeleteButton onClick={deleteBucket} isDisabled={isDeleteBlocked} />} />
        </div>
      </div>
    )
  }
}

BucketDetailHeading.propTypes = {
  /** Check action of BucketDetailHeading. */
  onCheck: PropTypes.func,
  /** Handle Search action of BucketDetailHeading. */
  onHandleSearch: PropTypes.func,
  /** Bucket Name of BucketDetailHeading. */
  bucketName: PropTypes.string,
  /** Bucket Type of BucketDetailHeading. */
  bucketType: PropTypes.string,
  /** Publish Status BucketDetailHeading. */
  status: PropTypes.string,
  /** Bucket Id of BucketDetailHeading. */
  bucketId: PropTypes.string,
  /** Boolean for enable or disable save button */
  isSaveEnable: PropTypes.bool,
  /** Boolean for disable add filter in bucket */
  isDisableAddFilter: PropTypes.bool,
  /** Boolean for disabling delete */
  isDeleteBlocked: PropTypes.bool,
  /** Boolean for disabling update */
  isUpdateBlocked: PropTypes.bool
}

BucketDetailHeading.defaultProps = {
}

export default BucketDetailHeading
