import gql from 'graphql-tag'

export default gql(`
    mutation archiveAppPage($title: String $key: String! $app: ID! $group: ID $project: String $id: ID! $isArchived: Boolean) {
        archiveAppPage(
            input: {
                title: $title
                key: $key
                app: $app
                group: $group
                id: $id
                isArchived: $isArchived
            },
            project: $project
        ) {
            id
            key
            title
            shortTitle
            shortDescription
            description
            app{
                id
                name
                url
            }
            group{
                key
                name
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
            project
            createdAt
            updatedBy
            createdBy
            isPublished
            isArchived
            publishStartDate
            publishEndDate
            slug
            seoKeywords
            seoMetaDescription
            seoTitle
        }
    }`
)
