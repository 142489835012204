import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $title: String! $icon: ID $draftIcon: ID $isActive: Boolean! $isDefault: Boolean! $isLveToVodSupported: Boolean! $matchSection: Boolean!
        $isMatchRelated: Boolean! $videoSection: Boolean! $contentSection: Boolean! $programSection: Boolean! $authorSection: Boolean! $metaFields: [CreateFieldInput] $position: Int $entertainmentMetadata: Boolean! $creditSection: Boolean! $parentalRatingSection: Boolean! $assetTypeCategory: ASSET_TYPE_CATEGORY $project: String $autoPublish: Boolean ) {
        updateAssetType(
            input: {
                id: $id
                title: $title
                icon: $icon
                draftIcon: $draftIcon
                isActive: $isActive
                isDefault: $isDefault
                isLveToVodSupported: $isLveToVodSupported
                isMatchRelated: $isMatchRelated
                videoSection: $videoSection
                contentSection: $contentSection
                programSection: $programSection
                authorSection: $authorSection
                matchSection: $matchSection
                metaFields: $metaFields
                position: $position
                entertainmentMetadata: $entertainmentMetadata
                creditSection: $creditSection
                parentalRatingSection: $parentalRatingSection
                assetTypeCategory: $assetTypeCategory
                autoPublish: $autoPublish
            }
            project: $project
        ) {
            id
            title
            isActive
            position
            isDefault
            isLveToVodSupported
            isMatchRelated
            autoPublish
            videoSection
            contentSection
            programSection
            authorSection
            matchSection
            usedCount
            entertainmentMetadata
            creditSection
            parentalRatingSection
            assetTypeCategory
            metaFields{
                id
                tooltip
                displayTitle
                displayName
                type
                isRequired
                values
                section{
                    id
                    title
                }
            }
            icon{
                id
                fileName
                url
                name
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            draftIcon{
                id
                fileName
                url
                name
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
        }
    }`
)
