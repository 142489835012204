import React from 'react'
import PropTypes from 'prop-types'

const SeekMarkInIcon = ({ width, height, onClick, style, ...props }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' className='seek-icon markin' onClick={onClick} style={style}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M12.69 6c.17 0 .308.188.308.417H13V9h3v2h-3v2.583h-.002c0 .23-.139.417-.309.417-.064 0-.117-.03-.175-.07L8.17 10.48A.723.723 0 0 1 8 10c0-.194.066-.365.169-.481l4.35-3.452a.272.272 0 0 1 .17-.067z' />
      <path stroke='#FFF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 19v-4l-5.078-4.232a1 1 0 0 1 0-1.536L9 5V1' />
    </g>
  </svg>

)

SeekMarkInIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SeekMarkInIcon.defaultProps = {
  width: 20,
  height: 20
}

export default SeekMarkInIcon
