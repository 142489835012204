import gql from 'graphql-tag'

export default gql(`
query {
    listConfigGroupSchemas {
        items {
          id
          name
          usedCount
          version
          streams{
            id
            levels {
              id
              name
              version
              fields{
                name
                value
                isRequired
                type
              }
            }
          }
          fields{
            name
            value
            isRequired
            type
          }
        }
    }
}`)
