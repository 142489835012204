import React, { Component } from 'react'
import { Row, Col } from 'antd'
import './PartnerManager.scss'
import PartnerEditor from './PartnerEditor'
import PartnerList from './PartnerList'
import NProgress from 'nprogress'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import _ from 'lodash'
class PartnerManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSidebar: window.innerWidth > 1200,
      selectedPartnerId: '',
      selectedOfferId: '',
      searchString: '',
      isArchive: false,
      filter: [],
      offerFilter: [],
      sort: {
        order: 'desc',
        value: 'name'
      },
      uploadingImage: [],
      deletedIds: []
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if ((!newProps.match.params || !newProps.match.params.id) && this.state.selectedPartnerId) {
      this.setState({ selectedPartnerId: '' })
    } else if (this.state.selectedPartnerId && this.state.selectedPartnerId !== newProps.match.params.id) {
      this.setState({ selectedPartnerId: newProps.match.params.id })
    }
    if (newProps.showWarning) {
      this.setState({ shouldShowWarning: true })
    }
  }

  componentDidMount () {
    document.title = `Partner Manager - Hyperion CMS`
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  changeFilterValue = (filter) => {
    this.setState({ filter: filter })
  }

  changeOfferFilterValue = (filter) => {
    this.setState({ offerFilter: filter })
  }

  onChangeFilter=value => {
    this.setState({ searchString: value })
  }

  archiveButtonHandle = (status) => {
    this.setState({ isArchive: status })
  }

  setSearchString=(value) => {
    this.setState({ searchString: value })
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar })
  }

  handleFileUpload = (uploadingImage, isImageUpload) => {
    if (isImageUpload) {
      this.setState({ uploadingImage })
    } else {
      let files = { ...uploadingImage, timeStamp: new Date().getTime() }
      this.props.handleFileUpload(files)
    }
  }

  onChangeSort = sortData => {
    if (!_.isEqual(sortData, this.state.sortData)) {
      this.setState({ sort: sortData })
    }
  }

  setSelectedPartner = id => {
    if (this.state.selectedPartnerId !== id && this.state.isEdited) {
      this.tempSelectedId = id
      this.toggleWarningMessage(true)
    } else if (this.state.selectedPartnerId !== id) {
      NProgress.start()
      this.onChangeFilterValues(id)
      this.setState({ selectedPartnerId: id, urlSlug: '' }, () => {
        this.props.history.push(`/partners/${window.location.search ? id + window.location.search : id}`)
      })
    }
  }

  onChangeFilterValues =(id) => {
    let filter = [...this.state.offerFilter]
    const filterIndex = filter.findIndex(filter => filter.name === 'partner')
    if (filterIndex > -1) {
      filter[filterIndex].value = id
    } else {
      const partnerFilter = {
        name: 'partner',
        type: 'KEYWORD',
        value: id
      }
      filter.push(partnerFilter)
    }
    this.changeOfferFilterValue(filter)
  }

  clearFileName = () => this.setState({ uploadingImage: [] })

  onDeletePartner = ids => {
    this.setState({ deletedIds: ids })
  }

  archiveButtonHandle = (status) => {
    this.setState({ isArchive: status })
  }

  changeEditStatus = (status) => {
    const { isEdited, isError } = status
    this.props.onChangeEditStatus(isEdited)
    if (this.state.shouldSave) {
      this.setState({ isError, isEdited, shouldSave: false, isSavingOffer: false, shouldShowWarning: false }, () => {
        if (!isError) {
          this.setSelectedPartner(this.tempSelectedId)
        }
      })
    } else {
      this.setState({ isError, isEdited })
    }
  }

  forceSave = () => {
    this.props.notifyParent()
    this.setState({ shouldSave: true, isSavingAsset: true })
  }

  discardChanges = () => {
    this.props.notifyParent()
    this.setState({ isEdited: false, shouldDiscard: true }, () => {
      this.toggleWarningMessage(false)
      this.selectedPartner(this.tempSelectedId)
      this.setState({ shouldDiscard: false })
    })
  }

  selectedPartner = id => {
    if (this.state.selectedAssetId !== id && this.state.isEdited) {
      this.tempSelectedId = id
      this.toggleWarningMessage(true)
    } else if (this.state.selectedAssetId !== id) {
      NProgress.start()
      this.setState({ selectedAssetId: id, urlSlug: '' }, () => {
        this.props.history.push(`/partners/${window.location.search ? id + window.location.search : id}`)
        this.onChangeFilterValues(this.tempSelectedId)
      })
    }
  }

  onCloseConfirmModal = () => {
    this.setState({ shouldShowWarning: false }, this.props.clearModuleChange)
  }

  render () {
    const { showSidebar, sort, selectedPartnerId, searchString, uploadingImage, isArchive, filter,
      offerFilter, deletedIds, shouldShowWarning, isError, shouldSave } = this.state
    const { project } = this.props
    const partnerFilter = filter
    const appliedFilter = { searchString, partnerFilter, sort }
    return <Row className='partner-manager'>
      <Col className={showSidebar ? 'partner-list-wrapper' : ''} span={6} >
        <PartnerList
          sort={sort}
          searchString={searchString}
          onChangeFilter={this.onChangeFilter}
          changedPartner={this.setSelectedPartner}
          selectedPartnerId={selectedPartnerId}
          archiveButtonHandle={this.archiveButtonHandle}
          changeFilterValue={this.changeFilterValue}
          onChangeSort={this.onChangeSort}
          deletedIds={deletedIds}
          filterVal={filter}
          project={project}
          offerFilter={offerFilter}
        />
      </Col>
      <Col xl={{ span: 18 }} xs={{ span: 18 + (showSidebar ? 6 : 0) }} className='partner-editor-container' >
        <PartnerEditor
          showSidebar={showSidebar}
          toggleSidebar={this.toggleSidebar}
          project={project}
          handleFileUpload={this.handleFileUpload}
          files={uploadingImage}
          clearFileName={this.clearFileName}
          partnerId={selectedPartnerId}
          onDeletePartner={this.onDeletePartner}
          isArchive={isArchive}
          filterVal={offerFilter}
          sort={sort}
          appliedFilter={appliedFilter}
          changeEditStatus={this.changeEditStatus}
          selectedPartnerId={selectedPartnerId}
          shouldSave={shouldSave}
        />
      </Col>
      <ConfirmModal
        isVisible={shouldShowWarning}
        title={`Partner Manager`}
        message={'Changes made by you will be discarded. Do you wish to continue ?'}
        isLeftPrior={false}
        leftButtonText={'Discard'}
        rightButtonText={'Save'}
        handleSubmit={this.forceSave}
        handleCancel={this.discardChanges}
        handleClose={this.onCloseConfirmModal}
        isSubmitButtonDisabled={isError}
        isCancelButtonDisabled={false}
        // isLoading={isSavingAsset}
      />

    </Row>
  }
}

PartnerManager.propTypes = {

}

export default PartnerManager
