import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Row, Col, message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { _, flowRight as compose } from 'lodash'
//

import DrmHistory from './DrmHistory'
import SidebarOpenButton from '../../components/ui/general/buttons/SidebarOpenButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import { hookUtilityService } from './../../services/HookUtilityService'
import { utilityService } from '../../services/UtilityService'
import DropDown from '../../components/ui/dataEntry/inputs/DropDown'
import DrmDetails from './DrmDetails'

import QueryListChannels from './../../graphQL/drmManager/listChannels'
import QueryGetChannel from './../../graphQL/drmManager/getChannel'
import QueryListChannelStreams from './../../graphQL/admin/channel/listStreamLevels'
import QueryGetDrmToken from './../../graphQL/drmManager/getDrmToken'

function DrmEditor ({ project, client, isSideBarVisible, toggleSideBar, onSourceSelect, sourceList, source, channelList, onChannelSelect, channel, streamList, channelDetails, isChannelLoading, ...props }) {
  const initialState = {
    contentKey: null,
    iV: null,
    drmEntitlementMessage: null,
    duration: null,
    streamType: null,
    url: null
  }

  const initialChannelState = {
    channel: null,
    configGroup: null,
    channelGroupList: [],
    stream: null,
    streamFieldData: null,
    streamFieldMap: null
  }

  const [state, setState] = hookUtilityService.mergeStateData(initialState)
  const [channelState, setChannelState] = hookUtilityService.mergeStateData(initialChannelState)
  const [token, setToken] = hookUtilityService.useStateWithLabel(null, 'token')
  const [isTokenGenerating, setTokenGenerating] = hookUtilityService.useStateWithLabel(false, 'tokenGenerating')
  const [tempStreamList, setTempStreamList] = hookUtilityService.useStateWithLabel([], 'tempStreamList')
  const [refetchAudit, setRefetchAudit] = hookUtilityService.useStateWithLabel(false, 'refetchAudit')

  useEffect(() => {
    let configData = {}
    let allGroupList = ((channelDetails && channelDetails.configGroups) || []).filter(group => group && group.id)
    let activeConfigGroup = (channelDetails && channelDetails.activeConfigGroup) || null
    if (activeConfigGroup) {
      configData.name = activeConfigGroup.name
      configData.id = activeConfigGroup.id
      configData.streams = activeConfigGroup.streams || []
      setChannelState({ configGroup: configData, channelGroupList: allGroupList })
      if (activeConfigGroup.streams && activeConfigGroup.streams.length > 0) {
        getFilteredStreams(activeConfigGroup.streams)
      }
    }
  }, [channelDetails])

  useEffect(() => {
    if (refetchAudit === true) {
      setRefetchAudit(false)
    }
  }, [refetchAudit])

  useEffect(() => {
    onClearData()
  }, [source])

  const getFilteredStreams = (streams) => {
    let finalStreamList = []
    let streamFieldMap = {};
    (streams || []).filter(stream => {
      if (stream && !_.isEmpty(stream.streamSchema) && stream.streamSchema.levels && !_.isEmpty(stream.streamSchema.levels)) {
        let tempLevelObj = {
          streamSchemaId: (stream.streamSchema && stream.streamSchema.id) || null,
          streamSchemaUrl: (stream && stream.url) || null
        };
        (stream.streamSchema.levels || []).map(level => {
          if (stream && stream.fields) {
            if (!tempLevelObj.id) {
              tempLevelObj = { ...tempLevelObj, ...level }
            } else {
              tempLevelObj.id = tempLevelObj.id + '_' + level.id
              tempLevelObj.name = tempLevelObj.name + '_' + level.name
            }
          }
        })
        finalStreamList.push(tempLevelObj)
        streamFieldMap[stream.streamSchema.id] = stream.fields
      }
    })
    setTempStreamList(finalStreamList)
    setChannelState({ streamFieldData: finalStreamList, streamFieldMap: streamFieldMap })
    // getFilteredState(finalStreamList)
  }

  const getFilteredState = (channelData) => {
    let url = ''
    let iv = ''
    let contentKey = ''
    let drmEntitlementMessage = ''
    let duration = null
    let fieldData = []
    if (channelData && channelData.streamSchemaUrl) {
      url = (channelData && channelData.streamSchemaUrl) || null
    }
    if (channelState && channelState.streamFieldMap && channelState.streamFieldMap.hasOwnProperty(channelData.streamSchemaId)) {
      fieldData = channelState.streamFieldMap[channelData.streamSchemaId]
    }
    (fieldData || []).map(field => {
      if (field && field.name && field.name.toLowerCase() === 'iv') {
        iv = field.value || null
      } else if (field && field.name && field.name.toLowerCase() === 'drm entitlement message') {
        drmEntitlementMessage = field.value || null
      } else if (field && field.name && field.name.toLowerCase() === 'content key') {
        contentKey = field.value || null
      } else if (field && field.name && (field.name.toLowerCase() === 'duration' || field.name.toLowerCase() === 'validity')) {
        duration = field.value || null
      }
    })
    setState({ url: url, iV: iv, contentKey: contentKey, drmEntitlementMessage: drmEntitlementMessage, duration: duration })
  }

  const onContentKeyChange = (event) => {
    if (event.target) { setState({ contentKey: event.target.value }) }
  }

  const onIvChange = (event) => {
    if (event.target) { setState({ iV: event.target.value }) }
  }

  const onDrmEntitlementChange = (event) => {
    if (event.target) { setState({ drmEntitlementMessage: event.target.value }) }
  }

  const onSelectStreamType = (value) => {
    const streamData = (streamList || []).find(Item => Item.id === value)
    setState({ streamType: streamData })
  }

  const onChangeDuration = (value) => {
    setState({ duration: value })
  }

  const onUrlChange = (event) => {
    if (event.target) { setState({ url: event.target.value }) }
  }

  const onSelectChannel = (value) => {
    const channelData = (channelList || []).find(Item => Item.id === value)
    setState(initialState)
    setChannelState({ channel: channelData, configGroup: null, stream: null })
    setToken(null)
    setTempStreamList([])
    onChannelSelect(channelData)
  }

  const onConfigGroupSelect = (value) => {
    const configGroup = ((channelState && channelState.channelGroupList) || []).find(item => item.id === value)
    setChannelState({ configGroup: configGroup, stream: null })
    setState(initialState)
    if (configGroup && configGroup.streams) {
      getFilteredStreams(configGroup.streams)
    }
  }

  const onChannelStreamSelect = (value) => {
    const channelStreamData = (tempStreamList || []).find(Item => Item.id === value)
    setChannelState({ stream: channelStreamData })
    setToken(null)
    getFilteredState(channelStreamData)
  }

  const onGenerateToken = async () => {
    const { contentKey, iV, drmEntitlementMessage, duration, streamType, url } = state
    if (_.isEmpty(streamType) && source && source.id === 'manual') {
      message.warn('Please Select a Stream Type')
      return
    } else if (_.isEmpty(channelState && channelState.stream) && source && source.id === 'cmsChannelManager') {
      message.warn('Please Select a Stream Type')
      return
    }
    if (!contentKey && !drmEntitlementMessage) {
      message.warn('Please enter Content Key or DRM Entitlement Message ')
      return
    }

    if (duration > 2190) {
      message.error('Duration cannot be more than 3 months, Please enter a valid duration')
      return
    }
    let streamId = null
    let sourceName = null
    let configGroup = channelState && channelState.configGroup ? channelState.configGroup.name : null
    if (source && (source.id === 'manual')) {
      streamId = (streamType && streamType.id) || null
      sourceName = 'MANUAL'
    } else if (source && (source.id === 'cmsChannelManager')) {
      let tempArray = null
      tempArray = channelState && channelState.stream && channelState.stream.id && channelState.stream.id.includes('_') ? channelState.stream.id.split('_') : [channelState.stream.id]
      if (tempArray[0]) {
        streamId = tempArray[0]
      }
      sourceName = 'CHANNEL_MANAGER'
    }
    // let source = source && (source.id === 'manual') ? 'MANUAL' : source && (source.id === 'cmsChannelManager') ? 'CHANNEL_MANAGER' : null
    const variables = {
      contentKey: contentKey || null,
      iv: iV || null,
      drmEntitlementMessage: drmEntitlementMessage || null,
      validity: duration || null,
      streamType: streamId || null,
      url: url || null,
      source: sourceName || null,
      channel: (channel && channel.name) || null,
      configGroup: configGroup
    }

    setTokenGenerating(true)
    client.query({ query: QueryGetDrmToken, variables: variables, fetchPolicy: 'network-only' })
      .then(({ data }) => {
        if (data && data.getDrmToken) {
          console.log('data', data)
          setToken(data.getDrmToken.token)
          setTokenGenerating(false)
          message.success('Token Generated Successfully')
          setRefetchAudit(true)
        } else {
          setTokenGenerating(false)
        }
      })
      .catch(error => {
        if (error) {
          utilityService.handleError(error)
          setTokenGenerating(false)
        }
      })
  }

  const onClearData = () => {
    setToken(null)
    setState(initialState)
    setChannelState(initialChannelState)
    setTempStreamList([])
    onChannelSelect(null)
  }

  return (
    <Row className='drm-editor' >
      <Col xl={{ span: (isSideBarVisible) ? 18 : 24 }} xs={{ span: (isSideBarVisible) ? 18 : 24 }} className='drm-details'>
        <div className={`drm-header-and-table`}>
          <div className='drm-manager-header' id='drm-manager-header' >
            <div className='drm-header-left' >
              <span>Source</span>
              <DropDown
                options={sourceList}
                selectedValue={(source && source.id) || undefined}
                placeHolder='Select Source'
                valueParam='id'
                keyParam='id'
                displayParam='name'
                onOptionSelect={onSourceSelect}
                className={''}
                allowClear />
            </div>
            <div>
              {!isSideBarVisible ? <ButtonContainer displayTitle='Show Audit History' placement='bottomLeft' childComponent={<SidebarOpenButton onClick={toggleSideBar} />} /> : <div className='invisible' />}
            </div>
          </div>
        </div>
        <DrmDetails
          source={source}
          onContentKeyChange={onContentKeyChange}
          onIvChange={onIvChange}
          onDrmEntitlementChange={onDrmEntitlementChange}
          onSelectStreamType={onSelectStreamType}
          onChangeDuration={onChangeDuration}
          onUrlChange={onUrlChange}
          onSelectChannel={onSelectChannel}
          channelList={channelList || []}
          channel={channel}
          streamList={streamList || []}
          onGenerateToken={onGenerateToken}
          onClearData={onClearData}
          state={state}
          isTokenGenerating={isTokenGenerating}
          isChannelLoading={isChannelLoading}
          channelState={channelState}
          tempStreamList={tempStreamList}
          onChannelStreamSelect={onChannelStreamSelect}
          onConfigGroupSelect={onConfigGroupSelect}
          token={token || null} />
      </Col>
      {(isSideBarVisible) ? <Col xl={{ span: toggleSideBar ? 6 : 0 }} xs={{ span: toggleSideBar ? 6 : 0 }} className='data-sidebar-container'>
        <DrmHistory content={'DRM_MANAGER'} isChanging={refetchAudit} toggleSidebar={toggleSideBar} contentId={null} project={project} streamList={streamList} />
      </Col> : ''}
    </Row>
  )
}

DrmEditor.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListChannels,
    {
      options: ({ project, appClient }) => {
        return {
          variables: { project, filter: { isArchived: { match: false } } },
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const channelList = props.data && props.data.listChannels ? props.data.listChannels.items : []
        return {
          channelList
        }
      }
    }
  ),
  graphql(
    QueryListChannelStreams,
    {
      skip: (source) => source === 'cmsChannelManager',
      options: () => {
        return {
          variables: { },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const streamList = props.data && props.data.listStreamLevels ? props.data.listStreamLevels.items : []
        return {
          streamList
        }
      }
    }
  ),
  graphql(
    QueryGetChannel,
    {
      skip: ({ channel }) => !channel,
      options: ({ project, channel }) => {
        return {
          variables: { project, id: channel && channel.id },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const channelDetails = props.data && props.data.getChannel ? props.data.getChannel : null
        const isChannelLoading = (props.data && props.data.loading) || false
        return {
          channelDetails,
          isChannelLoading
        }
      }
    }
  )
)(DrmEditor))
