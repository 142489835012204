import React from 'react'
//

class AppSettingList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {

    }
  }

  render () {
    const { handleAppListSelect, selectedAppSetting } = this.props
    return (
      <div className='setting-list' id='setting-list'>
        <p className={`${selectedAppSetting === 'Apps' ? 'selected' : ''}`} onClick={() => handleAppListSelect('Apps')}>Apps</p>
        <p className={`${selectedAppSetting === 'Widgets' ? 'selected' : ''}`}onClick={() => handleAppListSelect('Widgets')}>Widgets</p>
        <p className={`${selectedAppSetting === 'Api Keys' ? 'selected' : ''}`}onClick={() => handleAppListSelect('Api Keys')}>API Keys</p>
        <div className='app-list-footer' id='setting-list-footer' />
      </div>
    )
  }
}

export default AppSettingList
