import React from 'react'
import PropTypes from 'prop-types'
import DownloadIcon from './../icons/DownloadIcon'
import './../../ui.style.scss'

const DownloadButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button delete ${isDisabled ? 'disabled' : ''}`} onClick={onClick} >
    <DownloadIcon color={'#FFF'} />
  </div>
)

DownloadButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

DownloadButton.defaultProps = {
  isDisabled: false
}

export default DownloadButton
