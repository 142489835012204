import gql from 'graphql-tag'

export default gql(`
query getBatchBucketItems($input: [ID]){
    getBatchBucketItems(
        input: $input
    ){
        id
        type
        asset{
            id
        }
    }
}`)
