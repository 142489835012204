import gql from 'graphql-tag'

export default gql(`
query filterListAppconfigs($filter: AppConfigFilterInput $limit: Int $project: String) {
  listAppConfig(
      limit: $limit,
      project: $project
      filter: $filter
    ) {
      items {
        id
        monitorConfigList {
          id
          isEnabled
          position
          fieldValues {
            configField {
              id
              name
            }
            value
          }
        }
    }
      
    }
}`)
