import gql from 'graphql-tag'

export default gql(`
query getMetricData ($id:ID!, $configItemId:ID!, $valueId:String, $project:String , $queryParams: String) {
    getMetricData (
        id: $id,
        configItemId: $configItemId
        valueId: $valueId
        project: $project
        queryParams: $queryParams
    ) {
        configItemId
        name
        apiResponse
        type
    }
      
}`)
