import gql from 'graphql-tag'

export default gql(`
query filterAppWidgetAudit($filter: AuditFilter $limit: Int $offset: Int $project: String){
    filterAudits(
        limit: $limit
        offset: $offset
        filter: $filter
        project: $project
    ){
        items{
            id
            contentType
            contentName
            action
            contentId
            updatedAt
            updatedBy
            updatedObj{
                name
                type
                value
                oldValue
                displayValue
                isRequired
                displayName
                fieldName
                maxValue
                isPosChange
                position
                default

            }
        }
        totalCount
    }
}`)
