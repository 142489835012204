import React from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import './../ui.style.scss'
import moment from 'moment'
class SeasonOriginalData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  render () {
    const { selectedSeason } = this.props
    return (
      <div className='season-original-data'>
        <div className='data'>
          <p className='key' >Season</p>
          <p className='value' >{selectedSeason.title ? selectedSeason.title : '-'}</p>
        </div>
        <div className='data'>
          <p className='key' >Season Opta Id</p>
          <p className='value' >{selectedSeason.externalId ? selectedSeason.externalId : '-'}</p>
        </div>
        <div className='data'>
          <p className='key' >OCID</p>
          <p className='value' >{selectedSeason.ocId ? selectedSeason.ocId : '-'}</p>
        </div>
        <div className='data'>
          <p className='key' >Start Date</p>
          <p className='value' >{moment(new Date(selectedSeason.startDate ? selectedSeason.startDate : '-')).format('DD MMM, YYYY')}</p>
        </div>
        <div className='data'>
          <p className='key' >End Date</p>
          <p className='value' >{moment(new Date(selectedSeason.endDate ? selectedSeason.endDate : '-')).format('DD MMM, YYYY')}</p>
        </div>
        <div className='data'>
          <p className='key' >Including Venue</p>
          <p className='value' >{selectedSeason.includesVenues ? _.capitalize(selectedSeason.includesVenues) : '-'}</p>
        </div>
        <div className='data'>
          <p className='key' >Including Standing</p>
          <p className='value' >{selectedSeason.includesStandings ? _.capitalize(selectedSeason.includesStandings) : '-'}</p>
        </div>
        <div className='data'>
          <p className='key' >Active</p>
          <p className='value' >{selectedSeason.active ? _.capitalize(selectedSeason.active) : '-'}</p>
        </div>
      </div>
    )
  }
}

export default SeasonOriginalData
