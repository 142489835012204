import gql from 'graphql-tag'

export default gql(`
    mutation batchUpdateTagType($updateData: [batchUpdateTagTypeInput] $project: String ) {
        batchUpdateTagType(
            input: $updateData
            project: $project
        ) {
            id
            name
            isMediaEnabled
            position
            isCustom
            key
            tagCount
            enabledAssetTypes{
                assetType
            }   
        }
    }`
)
