import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'

import EditSquadModal from '../dataEntry/other/EditSquadModal'
import './../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryPlayer from '../../../graphQL/admin/listPlayer'
import QueryGetPlayerDetails from '../../../graphQL/admin/getPlayer'

class OptaPlayerData extends Component {
  constructor (props) {
    super(props)
    this.state = {
      teams: props.team && props.team.length ? props.team : undefined,
      players: props.player && props.player.length ? props.player : [],
      playerIsLoading: true
    }
    this.columns = [
      // {
      //   dataIndex: 'shirtNumber',
      //   key: 'shirtNumber'
      // },
      {
        key: 'firstName',
        dataIndex: 'id',
        render: (id) => this.renderPlayerName(id)
      },
      {
        key: 'position',
        dataIndex: 'id',
        render: (id) => this.renderPlayerPosition(id)
      },
      {
        key: '4',
        dataIndex: 'id',
        render: (id) => this.props.renderData(id, 'squad', props.playerFilter)
      }
    ]
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.team && !_.isEqual(newProps.team, this.props.team)) {
        this.setState({ teams: newProps.team })
      }
      if (newProps.player && !_.isEqual(newProps.player, this.props.player)) {
        this.setState({ players: newProps.player })
      }
      if (newProps.selectedPlayer && this.state.playerIsLoading) {
        this.setState({ playerIsLoading: false })
      }
      if (newProps.selectedPlayer && newProps.selectedPlayer.player && newProps.selectedPlayerRequest && newProps.selectedPlayer.player.id === newProps.selectedPlayerRequest.id && this.state.playerIsLoading) {
        this.setState({ playerIsLoading: false })
      }
      if (newProps.selectedPlayer && newProps.selectedPlayer.player && newProps.selectedPlayerRequest && newProps.selectedPlayer.player.id !== newProps.selectedPlayerRequest.id && !this.state.playerIsLoading) {
        this.setState({ playerIsLoading: true })
      }
    }

    getPlayerTitle = (status) => {
      const { playerFilter, selectedPlayer } = this.props
      if (!status) {
        const playerFirstName = selectedPlayer.player.firstName
        const playerLastName = selectedPlayer.player.lastName
        return `${playerFilter.compName} - ${playerFilter.seasonName} - ${playerFilter.teamCode} - ${playerFirstName} ${playerLastName}`
      } else {
        return <Skeleton active avatar={false} title paragraph={false}
          loading={status} />
      }
    }

    renderPlayerPosition = (id) => {
      const { players } = this.state
      let player = {}
      player = players.find(item => item.id === id)
      return player.customPosition ? player.customPosition : player.position
    }

    renderPlayerName = (id) => {
      const { players } = this.state
      let player = {}
      player = players.find(item => item.id === id)
      return player.customFirstName ? player.customFirstName : player.firstName
    }

    render () {
      const { players, playerIsLoading } = this.state
      const { editSquad, hideEditSquadModal, selectedPlayer, loading, playerFilter, onHistoryUpdate } = this.props
      return (
        <React.Fragment>
          <Skeleton active avatar={false} title={false} paragraph={{ rows: 3 }}
            loading={loading}>
            <Table rowKey={record => record.id} columns={this.columns} dataSource={players} pagination={false} showHeader={false} />
          </Skeleton>
          { editSquad ? <EditSquadModal visible={editSquad} playerFilter={playerFilter} handleCancel={hideEditSquadModal} title={this.getPlayerTitle(playerIsLoading)} okText={'Save'} selectedSquad={selectedPlayer} playerIsLoading={playerIsLoading} onHistoryUpdate={onHistoryUpdate} /> : null }
        </React.Fragment>
      )
    }
}

OptaPlayerData.propTypes = {}

export default withApollo(compose(

  graphql(
    QueryPlayer,
    {
      options: ({ playerFilter }) => {
        let tournamentCalendar = ''; let team = ''
        tournamentCalendar = playerFilter.tournamentCalendar
        team = playerFilter.team

        return {
          variables: { tournamentCalendar: tournamentCalendar, team: team, limit: 250, offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let player = data.getSquad && data.getSquad[0] && data.getSquad[0].squad ? [ ...data.getSquad[0].squad ] : []
        return {
          player,
          loading: data.loading
        }
      }
    }
  ),
  graphql(
    QueryGetPlayerDetails,
    {
      skip: (props) => {
        return _.isEmpty(props.selectedPlayerRequest)
      },
      options: (props) => {
        return {
          variables: props.selectedPlayerRequest,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          selectedPlayer: props.data.getPlayer,
          playerIsLoading: props.data.loading
        }
      }
    }
  )

)(OptaPlayerData))
