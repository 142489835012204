import React, { Component } from 'react'

import { Empty, Skeleton } from 'antd'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'

import HistoryListCell from './historyCell/HistoryListCell'
import AdminHistoryListCell from './historyCell/AdminHistoryListCell'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import AuthService from '../../services/AuthService'
import { utilityService } from './../../services/UtilityService'
import './AuditManager.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryListAudit from './../../graphQL/audit/listAudit'
import QueryListAppleNewsAudit from './../../graphQL/audit/listAuditAppleNews'
import QueryFilterBucketAudit from './../../graphQL/audit/filterBucketAudit'
import QueryFilterAppWidgetAudit from './../../graphQL/audit/filterAppWidgetAudit'

import PropTypes from 'prop-types'

const width = ['100px', '100%', '100%']

const skippedAudit = []

const getFilterAudits = (historyList) => {
  const createAuditIndex = (historyList || []).findIndex(item => item.action === 'CREATE' && item.contentType === 'CHANNEL')
  if (createAuditIndex > -1) {
    return historyList.filter((item, index) => index <= createAuditIndex && item)
  }
  return historyList
}

class HistoryList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      historyList: [],
      isLoading: true
    }
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && (!this.lastSearched || this.lastSearched.searchString !== newProps.searchString || this.lastSearched.filter !== newProps.filter)) {
        this.lastSearched = { searchString: newProps.searchString, filter: newProps.filter }
        this.setState({ historyList: newProps.historyList, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
      } else if (!_.isEqual(newProps.historyList, this.props.historyList)) {
        this.setState({ historyList: newProps.historyList, isSearching: false, isPaginating: false, isLoading: false })
      }
      if (!newProps.isChanging && this.props.isChanging) {
        if (newProps.refetchHistoryList) {
          newProps.refetchHistoryList()
          setTimeout(newProps.refetchHistoryList, 1000)
        }
        if (newProps.refetchCSMHistoryList) {
          newProps.refetchCSMHistoryList()
          setTimeout(newProps.refetchCSMHistoryList, 1000)
        }
      }
    }

    componentDidMount = () => {
      this.setState({ historyList: this.props.historyList })
    }

    getUserDetails = async () => {
      const userDetails = await AuthService.getUserDetails()
      if (userDetails) {
        this.username = userDetails.name || userDetails.email
      }
    }

    onChangeFilter = (value) => {
      this.setState({ isSearching: true })
      if (this.props.changeFilter) {
        this.props.changeFilter(value)
      }
    }

    onChangeFilterString = value => {
      this.setState({ isSearching: true })
      if (this.props.changeFilterString) {
        this.props.changeFilterString(value)
      }
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.historyList.length || this.state.isPaginating) { return }
      if (this.props.content === 'CHANNEL_MANAGER') {
        const createAuditIndex = (this.props.historyList || []).findIndex(item => item.action === 'CREATE' && item.contentType === 'CHANNEL')
        if (createAuditIndex > -1 && this.props.historyList.length - 1 === createAuditIndex) {
          return
        }
      }
      this.setState({ isPaginating: true }, () => {
        if (this.props.content === 'CUSTOMER_MANAGER' && this.props.contentId === 'CSM') { this.props.getCSMhistoryList(this.state.historyList.length) } else { this.props.gethistoryList(this.state.historyList.length) }
      })
    }

    render () {
      const { isLoading, isSearching, isPaginating, historyList } = this.state
      const { searchString, showFilter, type, content, mediaDetails, isClearFilter, displayTypes, assetDetails, mediaCategoryList, project, seachField, historyType, appList, streamList } = this.props
      return (
        <div className={historyType === 'APP_GROUP' ? 'app-group-history-list' : `history-list ${showFilter ? 'show-filter' : ''}`}>
          { showFilter ? <div className='filter'>
            <FilterInput
              searchString={searchString}
              onChangeSearchInput={this.onChangeFilterString}
              filterType={type || 'AuditBucketManager'}
              changeFilter={this.onChangeFilter}
              placement='leftTop'
              username={this.username}
              isClearFilter={isClearFilter}
              project={project}
            />
          </div> : null }
          {(isLoading) && !isPaginating
            ? <div className={`table`}>{[...Array(7)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading key={i} />)}
            </div>
            : <div className='table'>
              {/* { (isLoading || isSearching) && !isPaginating ? [...Array(7)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading />) : null } */}
              { (!isLoading && !isSearching) || isPaginating ? <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMoreAsset}
                hasMore={this.props.totalCount > (historyList || []).length}
                initialLoad={false}
                useWindow={false}
              >
                { historyList && historyList.length ? historyList.filter(history => !(history.action === 'UPDATE' && history.contentName !== 'Retrigger Validataion' && (!history.updatedObj || !history.updatedObj.length))).map((history, index) => {
                  return (content === 'DATA_MANAGER' ? <AdminHistoryListCell content={content} details={history} selectedDataId={this.props.selectedDataId} key={index} project={project} /> : <HistoryListCell content={content} details={history} key={index} mediaDetails={mediaDetails} displayTypes={displayTypes} assetDetails={assetDetails} mediaCategoryList={mediaCategoryList} seachField={seachField} appList={appList} streamList={streamList} />)
                }) : <Empty /> }
              </InfiniteScroll> : null }
              { isPaginating && this.props.totalCount > historyList.length ? [...Array(2)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading key={i} />) : null }
            </div>}
        </div>
      )
    }
}

HistoryList.propTypes = {
  /** Selected media details */
  mediaDetails: PropTypes.object,
  /** Boolean for clearing filter */
  isClearFilter: PropTypes.bool,
  /** List of bucket list type */
  displayTypes: PropTypes.array
}

export default withApollo(compose(
  graphql(
    QueryListAudit,
    {
      skip: ({ content, project, contentId }) => {
        return !content || content === 'BUCKET_MANAGER' || content === 'CHANNEL_MANAGER' || content === 'DATA_MANAGER' || contentId === 'CSM' || (content === 'APP_MANAGER' && !contentId) || content === 'DRM_MANAGER'
      },
      options: (props) => {
        const { contentId, content, project } = props
        const variables = { contentId, limit: 20, module: content, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const project = data.variables && data.variables.project ? data.variables.project : ''
        const historyList = data.listAudits ? data.listAudits.items : []
        return {
          historyList,
          isLoading: data.loading || !data.listAudits,
          totalCount: data.listAudits ? data.listAudits.totalCount : 0,
          gethistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listAudits.items, ...fetchMoreResult.listAudits.items ]
                prev.listAudits.items = newList
                return prev
              }
            })
          },
          refetchHistoryList: () => {
            const { contentId, project } = props.ownProps
            const variables = { contentId, limit: 20, project }
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryFilterBucketAudit,
    {
      skip: ({ content, project, contentId, filter }) => {
        return !content || filter === 'SECTION_MAPPING' || (content !== 'BUCKET_MANAGER' && content !== 'CHANNEL_MANAGER' && content !== 'DATA_MANAGER' && content !== 'DRM_MANAGER') || contentId === 'CSM' || ((project === 'hyperion' || project === 'projectx') && skippedAudit.includes(filter))
      },
      options: (props) => {
        const { searchString, filter, selectedBucketGroup, content, moduleContent, project, contentId } = props
        let variables = utilityService.getAuditFilter(searchString, filter, selectedBucketGroup, content, moduleContent)
        variables.project = project
        if (contentId && content === 'DATA_MANAGER' && filter === 'MODULE_CONFIG') {
          variables.filter.contentId = {
            multiple_exact: contentId
          }
        }
        // if (!contentId && content === 'DRM_MANAGER') {
        //   variables.filter.contentId = {
        //     multiple_exact: contentId
        //   }
        // }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        const historyList = data.filterAudits ? data.filterAudits.items : []
        return {
          historyList: props.ownProps.content === 'CHANNEL_MANAGER' ? getFilterAudits(historyList) : historyList,
          isLoading: data.loading,
          totalCount: data.filterAudits ? data.filterAudits.totalCount : 0,
          gethistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: ownProps.project
              },
              updateQuery: (previous, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previous
                const prev = cloneDeep(previous)
                const newList = [ ...prev.filterAudits.items, ...fetchMoreResult.filterAudits.items ]
                prev.filterAudits.items = props.ownProps.content === 'CHANNEL_MANAGER' ? getFilterAudits(newList) : newList
                return prev
              }
            })
          },
          refetchHistoryList: () => {
            const { searchString, filter, contentId, module } = props.ownProps
            const variables = utilityService.getAuditFilter(searchString, filter, contentId, module)
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryFilterBucketAudit,
    {
      skip: ({ content, contentId }) => {
        return !content || (content !== 'CUSTOMER_MANAGER' || contentId !== 'CSM')
      },
      options: (props) => {
        const { project } = props
        const filter = { contentType: { multiple_exact: 'SEARCH' } }
        const variables = { limit: 20, module: 'CUSTOMER_MANAGER', project, filter }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        const historyList = data.filterAudits ? data.filterAudits.items : []
        const filter = { contentType: { multiple_exact: 'SEARCH' } }
        return {
          historyList: historyList,
          isLoading: data.loading,
          totalCount: data.filterAudits ? data.filterAudits.totalCount : 0,
          getCSMhistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: ownProps.project,
                limit: 20,
                module: 'CUSTOMER_MANAGER',
                filter
              },
              updateQuery: (previous, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previous
                const prev = cloneDeep(previous)
                const newList = [ ...prev.filterAudits.items, ...fetchMoreResult.filterAudits.items ]
                prev.filterAudits.items = newList
                return prev
              }
            })
          },
          refetchCSMHistoryList: () => {
            const { project } = props.ownProps
            const filter = { contentType: { multiple_exact: 'SEARCH' } }
            const variables = { limit: 20, module: 'CUSTOMER_MANAGER', project, filter }
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryFilterAppWidgetAudit,
    {
      skip: ({ content, contentId }) => {
        return !content || (content !== 'APP_MANAGER' || contentId !== null)
      },
      options: (props) => {
        const { project, type } = props
        const settingType = type === 'Widgets' ? 'APP_WIDGET' : type === 'Apps' ? 'Apps' : 'APP_KEY'
        const filter = { contentType: { multiple_exact: settingType } }
        const variables = { limit: 20, module: 'APP_MANAGER', project, filter }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        const historyList = data.filterAudits ? data.filterAudits.items : []
        const filter = { contentType: { multiple_exact: 'APP_WIDGET' } }
        return {
          historyList: historyList,
          isLoading: data.loading,
          totalCount: data.filterAudits ? data.filterAudits.totalCount : 0,
          gethistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: ownProps.project,
                limit: 20,
                module: 'APP_MANAGER',
                filter
              },
              updateQuery: (previous, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previous
                const prev = cloneDeep(previous)
                const newList = [ ...prev.filterAudits.items, ...fetchMoreResult.filterAudits.items ]
                prev.filterAudits.items = newList
                return prev
              }
            })
          },
          refetchHistoryList: () => {
            const { project, type } = props.ownProps
            const filter = { contentType: { multiple_exact: type === 'Widgets' ? 'APP_WIDGET' : type === 'Apps' ? 'Apps' : 'APP_KEY' } }
            const variables = { limit: 20, module: 'APP_MANAGER', project, filter }
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryListAppleNewsAudit,
    {
      skip: ({ content, project, filter }) => {
        return !content || filter !== 'SECTION_MAPPING'
      },
      options: (props) => {
        const { content, project } = props
        const variables = { contentId: 'SECTION_MAPPING', limit: 20, module: content, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const project = data.variables && data.variables.project ? data.variables.project : ''
        const historyList = data.listAudits ? data.listAudits.items : []
        return {
          historyList,
          isLoading: data.loading || !data.listAudits,
          totalCount: data.listAudits ? data.listAudits.totalCount : 0,
          gethistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listAudits.items, ...fetchMoreResult.listAudits.items ]
                prev.listAudits.items = newList
                return prev
              }
            })
          },
          refetchHistoryList: () => {
            const { project } = props.ownProps
            const variables = { contentId: 'SECTION_MAPPING', limit: 20, project }
            return props.data.refetch(variables)
          }
        }
      }
    }
  )

)(withRouter(HistoryList)))
