import gql from 'graphql-tag'

export default gql(`
query listAppConfig($limit: Int $project: String) {
    listAppConfig(
      limit: $limit,
      project: $project
    ) {
      items {
        id
        isArchived
        isPublished
        createdBy
        createdAt
        key
        project
        publishEndDate
        title
        type
        group{
          key
          name
          type
        }
      }
    }
}`)
