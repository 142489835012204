import gql from 'graphql-tag'

export default gql(`
mutation($id: ID! $externalId: String $name: String $description: String $mediumDescription: String $shortDescription: String  $terms: String  $prices: [ID] $isArchived: Boolean
    $isActive: Boolean $startDate: AWSDateTime $endDate: AWSDateTime $partner: ID $assets: ID $attributes: [CreateOfferAttributeField] $recurrence: String $customMeta: [MetaFieldInput]
    $project: String){
        updateOffer(
            id: $id
            input:{
                externalId: $externalId
                name: $name   
                description: $description
                mediumDescription: $mediumDescription
                shortDescription: $shortDescription  
                terms: $terms     
                prices: $prices 
                isArchived: $isArchived
                isActive: $isActive
                startDate: $startDate
                endDate: $endDate 
                partner: $partner
                assets: $assets
                attributes: $attributes
                recurrence: $recurrence
                customMeta: $customMeta
            }
            project: $project
        ) 
        {
        id
        externalId
        name
        description
        shortDescription
        mediumDescription
        terms
        partner{
            id
            name
            logo{
                id
                mediaId
                project
                name
                url
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            attributes{
                label
                type
                value
                name
                toggleValue
              }
        }
        prices{
            id
            name
            startDate
            endDate
            currency
            displayPrice
            wholeSalePrice
            rrpIncGst
            rrpExcGst
            gstAmount
            promoId
            customMeta{
                config{
                    id
                    name
                    type
                    section
                    default
                }
                value
            }
        }
        isActive
        startDate
        endDate
        recurrence
        isArchived
        attributes{
            label
            type
            value
            name
            toggleValue
        }
        assets {
            id
        }
        customMeta{
            config{
                id
                name
                type
                section
                default
            }
            value
        }
        }
    }
`)
