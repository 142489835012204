import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    listAssetsbyBucket(id: $id
        project: $project
        limit: 50) {
        items{
            id
            externalId
            shortId
            title
            shortDescription
            slug
            updatedAt
            updatedBy
            createdAt
            publishRule{
                id
                group{
                    id
                }
                isEnabled
                publishEndDate
                publishStartDate
            }
            tags {
                key
                type
                name
            }
        }
    }
}`)
