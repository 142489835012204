import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
function getPriceMetaFields (pos, metaData, selectedMeta) {
  const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
  if (pos === 'left') {
    const customMeta = (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if ((index % 2) === 0) {
        let finalValue = null
        if (selectedMeta && selectedMeta.value !== null) {
          finalValue = '' + selectedMeta.value
        } else {
          // finalValue = '' + customMeta.default
        }
        return (
          <div className='data-inner-container'>
            <span>{customMeta ? customMeta.name : ''}</span>
            <div>{finalValue + ''}</div>
          </div>
        )
      }
    })
    return customMeta
  } else if (pos === 'right') {
    const customMeta = (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if ((index % 2) !== 0) {
        let finalValue = null
        if (selectedMeta && selectedMeta.value !== null) {
          finalValue = '' + selectedMeta.value
        } else {
          // finalValue = '' + customMeta.default
        }
        return (
          <div className='data-inner-container'>
            <span>{customMeta ? customMeta.name : ''}</span>
            <div>{finalValue + ''}</div>
          </div>
        )
      }
    })
    return customMeta
  }
}
function OfferPriceData (props) {
  const { currency, displayPrice, startDate, endDate, wholeSalePrice, rrpIncGst, rrpExcGst, gstAmount, customMeta } = props.priceData
  const { priceMetaFieldList } = props
  return (
    <div className='price-data-container'>
      <div className='data-container'>
        <div className='data-inner-container'>
          <span>Currency</span>
          <div>{currency}</div>
        </div>
        <div className='data-inner-container'>
          <span>Start Date</span>
          <div className='data-inner-container-date'>{startDate}</div>
        </div>
        <div className='data-inner-container'>
          <span>WholeSale Price</span>
          <div>{wholeSalePrice}</div>
        </div>
        <div className='data-inner-container'>
          <span>RRP inc GST</span>
          <div>{rrpIncGst}</div>
        </div>
        {getPriceMetaFields('left', customMeta, priceMetaFieldList)}
      </div>
      <div className='data-container'>
        <div className='data-inner-container'>
          <span>Display Price</span>
          <div>{displayPrice}</div>
        </div>
        <div className='data-inner-container'>
          <span>End Date</span>
          <div className='data-inner-container-date'>{endDate}</div>
        </div>
        <div className='data-inner-container'>
          <span>GST Amount</span>
          <div>{gstAmount}</div>
        </div>
        <div className='data-inner-container'>
          <span>RRP excel GST</span>
          <div>{rrpExcGst}</div>
        </div>
        {getPriceMetaFields('right', customMeta, priceMetaFieldList)}
      </div>

    </div>
  )
}

OfferPriceData.propTypes = {
  // priceData contains pricedetails
  priceData: PropTypes.object
}
export default OfferPriceData
