import React, { Component } from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
// import PropTypes from 'prop-types'

import Accordion from './Accordion'
import TagsInput from '../dataEntry/inputs/TagsInput'
// import { utilityService } from '../../../services/UtilityService'

// import QueryTagContent from '../../../graphQL/asset/getContentTags'
import QueryTagType from './../../../graphQL/admin/tagType/listTagType'

class TemplateTags extends Component {
  state = {
    searchTag: '',
    selectedIndex: undefined
  }

  onTagSearch = (index, searchTag) => {
    this.setState({
      searchTag,
      selectedIndex: searchTag ? index : undefined
    })
  }

  onTagChange = (value, isDelete, type) => {
    const { details } = this.props
    let { tags } = details
    if (isDelete) {
      const index = tags.findIndex(tag => tag.name === value && tag.type === type)
      if (index > -1) {
        tags.splice(index, 1)
      }
    } else {
      const tag = {
        name: value,
        type: type,
        module: type === 'MANUAL' ? 'ASSET_MANAGER' : 'ALL'
      }
      if (!tags) {
        tags = [tag]
      } else {
        const index = tags.findIndex(item => item.name === value && item.type === type)
        if (index === -1) {
          tags.push(tag)
        }
      }
    }
    this.props.onTagChange(tags)
  }

  getEnabledTagTypes=(systemTags, assetType) => {
    let finalTagTypes = []
    if (assetType === 'DEFAULT') {
      finalTagTypes = systemTags
    } else {
      (systemTags || []).filter(tagType => {
        if (tagType && tagType.enabledAssetTypes) {
          (tagType.enabledAssetTypes || []).map(item => {
            if (item.assetType === assetType) {
              finalTagTypes.splice(0, 0, tagType)
            }
          })
        }
      })
    }
    return finalTagTypes
  }

  render () {
    // const { searchTag } = this.state
    const { systemTags, selectedItems, details } = this.props
    const childComponent = <div className='template-tags' id='template-tags'>
      {cloneDeep(this.getEnabledTagTypes(systemTags, details.assetType)).sort((a, b) => a.position - b.position).map((tag, index) => (
        <div className='input-field' key={index}>
          <label className='input-title'>{tag.name}</label>
          <TagsInput
            selectedItems={selectedItems.filter(item => item && (item.type === tag.name)).map(item => item.name) || []}
            tagList={[]}
            className={'key-word-dropdown'}
            mode={'tags'}
            onChange={(a, b) => this.onTagChange(a, b, tag.name)}
            placeholder='Insert Tag'
            displayParameter='name'
            parentCompoent={'template-tags'}
          />
        </div>
      ))}
    </div>
    return (
      <div className='template-tag-details'>
        <Accordion
          title={'TAGS'}
          childComponent={childComponent}
        />
      </div>
    )
  }
}

TemplateTags.propTypes = {}

export default withApollo(compose(
  /* graphql(
    QueryTagContent,
    {
      ptions: ({ project }) => {
        return {
          variables: { project }
        }
      },
      props: (props) => {
        return {
          systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  ) */
  graphql(
    QueryTagType,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { filter: null, limit: 500, project }
        }
      },
      props: (props) => {
        return {
          systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
        }
      }
    }
  )

)(TemplateTags))
