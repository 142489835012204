import gql from 'graphql-tag'

export default gql(`
query ($input: GenerateImageSettingInput){
    getImageSetting(
      input: $input
      ) {
          aspectRatio
          outputFormat
          fileName
          x1
          y1
          x2
          y2          
    }
  }
`)
