import React from 'react'
import PropTypes from 'prop-types'
import { Switch, DatePicker } from 'antd'
import moment from 'moment'
import _ from 'lodash'
const { RangePicker } = DatePicker

class OfferAvailability extends React.Component {
  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.details.prices && newProps.details.isActive && !newProps.details.prices.length) {
      this.props.handleActiveChange(false)
    }
  }

  render () {
    const { details, handleDateChange, handleActiveChange } = this.props
    const { startDate, endDate, prices } = details

    return (
      <div className='offer-availability'>
        <div className='switch-container'>
          <div className='date-picker-inner-container'>
            <span>Date</span>
            <RangePicker
              onChange={handleDateChange}
              format='DD-MMM-YYYY HH:mm'
              value={details.isActive ? [startDate ? moment(startDate) : null, endDate ? moment(endDate) : null] : [startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
              defaultValue={null}
              showTime
            />
          </div>
          <div className='switch-inner-container'>
            <span>Active</span>
            <Switch checked={details.isActive} onChange={handleActiveChange} disabled={!(!_.isEmpty(prices))} />
          </div>
        </div>
      </div>
    )
  }
}

OfferAvailability.propTypes = {
  /** Offer details of selected Offer. */
  details: PropTypes.object,
  /** function to handle change Offer Date */
  handleDateChange: PropTypes.func,
  /** function to handle change Active restriction on Offer */
  handleActiveChange: PropTypes.func
}

export default OfferAvailability
