import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import PopoverConfirm from './../feedback/PopoverConfirm'

const CustomPlaceholder = ({ placeholder, onCloseContent, ...props }) => (
  <div className='custom-placeholder'>
    <PopoverConfirm confirm={onCloseContent} />
    <p className='placeholder-content' > { placeholder } </p>
  </div>
)

CustomPlaceholder.propTypes = {
  /** placeholder of Custom Placeholder. */
  placeholder: PropTypes.string.isRequired,
  /** close action of CustomPlaceholder. */
  onCloseContent: PropTypes.func
}

CustomPlaceholder.defaultProps = {
}

export default CustomPlaceholder
