import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'
import DataList from './DataList'
import DataDetails from './DataDetails'
import AppContext from '../../AppContext'
import LoggerService from './../../services/LoggerService'
// import DataHistory from './DataHistory'
import { utilityService } from './../../services/UtilityService'

import './DataManager.scss'
const parentArr = [
  {
    displayName: 'Assets',
    id: 'assets'
  },
  {
    displayName: 'Tags',
    id: 'tags'
  },
  {
    displayName: 'Tag Types',
    id: 'tag-types'
  },
  {
    displayName: 'Channels',
    id: 'channels'
  },
  {
    displayName: 'Buckets',
    id: 'buckets'
  },
  {
    displayName: 'Media',
    id: 'media'
  },
  {
    displayName: 'Sports Config',
    id: 'sports-config'
  },
  {
    displayName: 'Publishing Groups',
    id: 'publish-groups'
  },
  {
    displayName: 'Apple News',
    id: 'apple-news'
  },
  {
    displayName: 'Match Template',
    id: 'match-template'
  },
  {
    displayName: 'Entertainment Data',
    id: 'entertainment-data'
  },
  {
    displayName: 'Partner Manager',
    id: 'partner-manager'
  },
  {
    displayName: 'Offer Manager',
    id: 'offer-manager'
  },
  {
    displayName: 'Custom Meta Fields',
    id: 'custom-meta-fields'
  },
  {
    displayName: 'Settings',
    id: 'mfa-settings'
  },
  {
    displayName: 'Match Manager',
    id: 'match-manager'
  }
]

const childArr = [
  {
    displayName: 'Types',
    name: 'Types',
    id: 'asset-types'
  },
  {
    displayName: 'Meta Section',
    name: 'Meta Section',
    id: 'sections'
  },
  {
    displayName: 'Author',
    name: 'Author',
    id: 'authors'
  },
  {
    displayName: 'Categories',
    name: 'Categories',
    id: 'categories'
  },
  {
    displayName: 'Content Placeholder',
    name: 'Content Placeholder',
    id: 'content-placeholder'
  },
  {
    displayName: 'Channel Streams',
    name: 'Channel Streams',
    id: 'channel-streams'
  },
  {
    displayName: 'Channel Config Groups',
    name: 'Channel Config Groups',
    id: 'channel-config-groups'
  },
  {
    displayName: 'EPG Settings',
    name: 'EPG Settings',
    id: 'epg'
  },
  {
    displayName: 'Bucket Group Types',
    name: 'Bucket Group Types',
    id: 'bucket-group-types'
  },
  {
    displayName: 'Display Types',
    name: 'Display Types',
    id: 'display-types'
  },
  {
    displayName: 'VWM Encoder Config',
    name: 'VWM Encoder Config',
    id: 'encoder-config'
  }, {
    displayName: 'Image Types',
    name: 'Image Types',
    id: 'image-types'
  }, {
    displayName: 'Media Category',
    name: 'Media Category',
    id: 'media-category'
  },
  {
    displayName: 'S3 Remote Folder Config',
    name: 'S3 Remote Folder Config',
    id: 'remote-folder'
  },
  {
    displayName: 'DRM Playback Config',
    name: 'DRM Playback Config',
    id: 'drm-playback-settings'
  },
  {
    displayName: 'Sports',
    name: 'Sports',
    id: 'sports'
  },
  {
    displayName: 'Add Sports Data',
    name: 'Add Sports Data',
    id: 'add-sports-data'
  },
  {
    displayName: 'Data',
    name: 'Data',
    id: 'opta-data'
  },
  {
    displayName: 'Settings',
    name: 'Opta Sync Settings',
    id: 'opta-settings'
  },
  {
    displayName: 'Genre',
    name: 'Genre',
    id: 'genre'
  },
  {
    displayName: 'Production Studio',
    name: 'Production Studio',
    id: 'production-studio'
  },
  {
    displayName: 'Roles',
    name: 'Roles',
    id: 'roles'
  },
  {
    displayName: 'Persons',
    name: 'Persons',
    id: 'persons'
  },
  {
    displayName: 'Parental Rating Config',
    name: 'Parental Rating Config',
    id: 'parental-rating-config'
  },
  {
    displayName: 'Countries',
    name: 'Countries',
    id: 'countries'
  },
  {
    displayName: 'Languages',
    name: 'Languages',
    id: 'languages'
  },
  {
    displayName: 'Bucket Settings',
    name: 'Bucket Settings',
    id: 'bucket-settings'
  }
]

// let dataTypesVcms = []

// let dataTypesHyperion = []

const currentProject = utilityService.getCurrentProject()
// let currentProject = 'hyperion'

// let dataTypes = currentProject === 'hyperion' ? dataTypesHyperion : dataTypesVcms
let dataTypes = []

// const availableOptionsVcms = [ 'mfa-settings', 'authors', 'tags', 'categories', 'display-types', 'opta-settings', 'opta-data', 'sports', 'add-sports-data', 'bucket-group-types', 'match-template', 'content-placeholder', 'asset-types', 'publish-groups', 'encoder-config',
//   'remote-folder', 'epg', 'drm-playback-settings', 'channel-config-groups', 'channel-streams', 'media-category', 'image-types']
// const availableOptionsHyperion = [ 'asset-types', 'authors', 'categories', 'content-placeholder', 'media-category', 'image-types', 'tags', 'display-types', 'bucket-group-types', 'genre', 'production-studio', 'roles', 'persons', 'parental-rating-config', 'countries', 'languages', 'partner-manager', 'offer-manager', 'custom-meta-fields' ]
// const availableOptions = currentProject === 'hyperion' ? availableOptionsHyperion : availableOptionsVcms
let availableOptions = []
class DataManager extends Component {
  constructor (props) {
    super(props)
    dataTypes = (window.config.adminManagerConfig || []).map(configItem => {
      const parenntItem = parentArr.find(item => item.displayName === configItem.parent)
      if (configItem.child) {
        parenntItem.child = (configItem.child || []).map(childItem => {
          const child = childArr.find(innerChild => innerChild.name === childItem)
          availableOptions.push(child.id)
          return child
        })
      } else {
        availableOptions.push(parenntItem.id)
      }
      return parenntItem
    })
    let selectedDataId, selectedDisplayId
    let isSelected = false
    if (currentProject === 'hyperion' || currentProject === 'projectx') {
      if (props.match && props.match.params && props.match.params.id) {
        LoggerService.info('flow', 'Data Manager Loaded')
        const isOptionValid = availableOptions.findIndex(item => item === props.match.params.id)
        if (isOptionValid > -1) {
          selectedDataId = props.match.params.id !== 'match-template' ? props.match.params.id : null
          selectedDisplayId = props.match.params.id
          isSelected = true
        } else {
          selectedDataId = 'asset-types'
          selectedDisplayId = 'asset-types'
          props.history.push('/admin/asset-types')
        }
      } else {
        selectedDataId = 'asset-types'
        selectedDisplayId = 'asset-types'
        props.history.push('/admin/asset-types')
      }
    } else {
      if (props.match && props.match.params && props.match.params.id) {
        LoggerService.info('flow', 'Data Manager Loaded')
        const isOptionValid = availableOptions.findIndex(item => item === props.match.params.id)
        if (isOptionValid > -1) {
          selectedDataId = props.match.params.id !== 'match-template' ? props.match.params.id : null
          selectedDisplayId = props.match.params.id
          isSelected = true
        } else {
          selectedDataId = 'asset-types'
          selectedDisplayId = 'asset-types'
          props.history.push('/admin/asset-types')
        }
      } else {
        selectedDataId = 'asset-types'
        selectedDisplayId = 'asset-types'
        props.history.push('/admin/asset-types')
      }
    }
    this.state = {
      selectedDataId,
      selectedDisplayId,
      isSelected
    }
  }

  componentDidMount = () => {
    const { project } = this.props
    document.title = `Admin Bank - ${project === 'hyperion' ? 'Hyperion' : 'Optus'} CMS`
  }

  selectedData = (dataId, type) => {
    const newSelectedItem = type ? `${type}-competiton-${dataId}` : dataId
    if (this.state.selectedDataId !== newSelectedItem) {
      const selectedDisplayId = type ? 'match-template' : newSelectedItem
      this.props.history.push(`/admin/${selectedDisplayId}`)
      this.setState({ selectedDataId: newSelectedItem, selectedDisplayId, isSelected: true })
    }
  }

  render () {
    const { selectedDataId, isSelected, selectedDisplayId } = this.state
    const data = dataTypes && dataTypes.length && selectedDataId
      ? dataTypes.find(type => type.child && type.child.length ? type.child.find(item => item.id === selectedDataId) : type.id === selectedDataId) : null

    return <AppContext.Consumer>
      {({ project }) => {
        return (
          <Row className='data-manager' id='data-manager'>
            <Col xl={{ span: 6 }} xs={{ span: 6 }}className='data-list-container' >
              <DataList selectedDisplayId={selectedDisplayId} onSelect={this.selectedData} project={project}
                isSelected={isSelected} selectedDataId={selectedDataId} dataTypes={dataTypes} />
            </Col>
            {/* <Col xl={{ span: 12 }} xs={{ span: 12 }}className='data-details-container' ></Col> */}
            <Col xl={{ span: 18 }} xs={{ span: 18 }}className='data-details-container' >
              <DataDetails selectedDataId={selectedDataId} appleClient={this.props.appleClient} data={data} project={project} />
            </Col>
            {/* <Col xl={{ span: 6 }} xs={{ span: 6 }}className='data-sidebar-container' >
          <DataHistory />
        </Col> */}
          </Row>
        )
      }}
    </AppContext.Consumer>
  }
}

export default withRouter(DataManager)
