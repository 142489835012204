import React from 'react'
import PropTypes from 'prop-types'

const CheckIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <defs>
      <path id='a' d='M0 0h20v20H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} fillRule='nonzero' d='M10 1c-4.95 0-9 4.05-9 9s4.05 9 9 9 9-4.05 9-9-4.05-9-9-9zM8.2 14.5L3.7 10l1.26-1.26 3.24 3.24 6.84-6.84L16.3 6.4l-8.1 8.1z' />
    </g>
  </svg>
)

CheckIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

CheckIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#52C41A'
}

export default CheckIcon
