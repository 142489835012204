import gql from 'graphql-tag'

export default gql(`
    mutation resendUserInvitation($accessRoleGroups: [ID] $id: String $name: String $project: String) {
        resendUserInvitation(
            input: {
                id: $id
                name: $name
                accessRoleGroups: $accessRoleGroups
            }
            project: $project
        ){
            id
        }
    }
`)
