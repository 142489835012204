import gql from 'graphql-tag'

export default gql(`
query listViewHistory( $userId: ID! ) {
    listViewHistory( 
        userId: $userId
    ) {
        assetId{
            id
            title
        }
        progress{
            position
            updatedAt
        }
    }
}`)
