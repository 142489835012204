import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as AntTooltip } from 'antd'
import './../ui.style.scss'

const getToolTipComponent = (heading, details, isList) => {
  return (
    <React.Fragment>
      {heading ? <span className='heading'>{ heading }</span> : ''}
      { (details || []).map((item, index) => isList ? <span key={index}>{item}</span> : <span key={index}>{ `${item.width} X ${item.height}` }</span>) }
    </React.Fragment>
  )
}

const Tooltip = ({ childComponent, parentCompoent, heading, details, placement, isList, arrowPointAtCenter, className, description, styleClass, ...props }) => (
  <AntTooltip overlayClassName={className} placement={placement} title={description || getToolTipComponent(heading, details, isList)} getPopupContainer={() => document.getElementById(parentCompoent)} arrowPointAtCenter={arrowPointAtCenter} trigger={['hover', 'click']}>
    <span className={styleClass}>{ childComponent }</span>
  </AntTooltip>
)

Tooltip.propTypes = {
  /** display content of Tooltip. */
  childComponent: PropTypes.element,
  /** parent container of Tooltip. */
  parentCompoent: PropTypes.string,
  /** heading of Tooltip. */
  heading: PropTypes.string,
  /** details to show in Tooltip. */
  details: PropTypes.array,
  /** Placement of tooltip */
  placement: PropTypes.string,
  /** boolean to show list details */
  isList: PropTypes.bool,
  /** boolean to show pointer at center */
  arrowPointAtCenter: PropTypes.bool,
  /** Description of the tooltip */
  description: PropTypes.any,
  /** Class name */
  styleClass: PropTypes.string
}

Tooltip.defaultProps = {
  parentCompoent: 'root',
  placement: 'rightBottom',
  styleClass: '',
  arrowPointAtCenter: false
}

export default Tooltip
