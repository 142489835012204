import * as React from 'react'
import PropTypes from 'prop-types'

export default class SavedFilterInput extends React.Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
  }

    listenEnter = (event) => {
      if (event.keyCode === 13 && event.target.contains(this.inputRef.current)) {
        event.preventDefault()
        this.inputRef.current.blur()
      }
    }

    componentDidMount () {
      if (this.props.enableEnter) { document.addEventListener('keypress', this.listenEnter) }
    }

    componentWillUnmount () {
      if (this.props.enableEnter) { document.removeEventListener('keypress', this.listenEnter) }
    }

    render () {
      const { readOnly, onBlur, value, onClick, onChange, autoFocus } = this.props
      return readOnly ? <div onMouseDown={onClick}>{value}</div> : <input type='text' readOnly={readOnly} onBlur={onBlur} value={value}
        onChange={onChange} ref={this.inputRef} autoFocus={autoFocus} />
    }
}

SavedFilterInput.propTypes = {
  /** Boolean to decide read only or not */
  readOnly: PropTypes.bool,
  /** Function triggered on blur */
  onBlur: PropTypes.func.isRequired,
  /** Value of input field */
  value: PropTypes.string,
  /** Function to trigger on cilck */
  onClick: PropTypes.func.isRequired,
  /** Function to trigger on change of value */
  onChange: PropTypes.func.isRequired,
  /** Enable enter */
  enableEnter: PropTypes.bool,
  /** Enable autoFocus */
  autoFocus: PropTypes.bool
}

SavedFilterInput.defaultProps = {
  readonly: false,
  autoFocus: false
}
