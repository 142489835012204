import gql from 'graphql-tag'

export default gql(`
    mutation publishAppConfig( $project: String $isPublished: Boolean $publishStartDate: AWSDateTime $publishEndDate: AWSDateTime $id: ID! $app: ID!) {
        publishAppConfig(
            input: {
                id: $id
                publishStartDate: $publishStartDate
                publishEndDate: $publishEndDate
                isPublished: $isPublished
                app: $app
            },
            project: $project
        ) {
            id
            isArchived
            isPublished
            createdBy
            createdAt
            updatedAt
            key
            project
            publishEndDate
            publishStartDate
            title
            type
            isCritical
            group{
              key
              name
              type
              isCritical
              assignedUsers
            }
            fields{
                id
                type
                isRequired
                useAsTitle
                name
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
        }
    }`
)
