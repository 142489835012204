import gql from 'graphql-tag'

export default gql(`
query batchGetMedia($input: [ID]){
    batchGetMedia(
        input: $input
    ){
        id
        type
        name
    }
}`)
