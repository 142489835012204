import React, { Component } from 'react'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import RoleUserGroupTable from '../../components/ui/dataDisplay/RoleUserGroupTable'
import CreateUserGroupModal from '../../components/ui/dataEntry/other/CreateUserGroupModal'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import AppContext from '../../AppContext'
import QueryGroupList from '../../graphQL/role/listGroups'
import QueryUserList from '../../graphQL/role/listUsers'
import MutationBatchUpdateGroups from '../../graphQL/role/batchUpdateAccessRoleGroups'
import MutationCreateGroups from '../../graphQL/role/createAccessRoleGroup'
import MutationDeleteUserGroups from '../../graphQL/role/deleteAccessRoleGroup'
import MutationUpdateAccessRoleGroup from '../../graphQL/role/updateAccessRoleGroup'
import MutationCreateAudit from '../../graphQL/role/createUserGroupAudit'

import { utilityService } from '../../services/UtilityService'
import { message } from 'antd'
import { convertToCSV, downloadCSV, getFormattedGroupList } from '../../util/util'

class RoleUserGroupDetails extends Component {
  state = {
    toCreateGroup: false,
    isShowEditConfirm: false,
    isModalLoading: false,
    isEditGroup: false,
    isCancelClicked: false,
    isCreateLoading: false,
    isDeleteConfirmVisible: false,
    userId: '',
    userGroupsPermissionList: [],
    userList: this.props.userList
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.userList && newProps.userList.length && !_.isEqual(newProps.userList, this.props.userList)) {
      this.setState({ userList: newProps.userList })
    }
  }

  onCreateUserGroup = (isEditGroup) => {
    if (isEditGroup) { this.setState({ isShowEditConfirm: true }) } else { this.setState({ toCreateGroup: true }) }
  }

  toggleEditWarningMessage = () => {
    this.setState({ isShowEditConfirm: false })
  }

  onEditGroup = () => {
    this.setState({ isEditGroup: true })
  }

  onCancelEdit = () => {
    this.setState({ isEditGroup: false, isCancelClicked: true, userGroupsPermissionList: [] }, () => {
      this.setState({ isCancelClicked: false, userGroupsPermissionList: [] })
    })
  }

  hideAddUserGroupModal = () => {
    this.setState({ toCreateGroup: false })
  }

  createUserGroups = (userGroupTitle, description, selectedUsers, modules) => {
    const { userList } = this.state
    const { project } = this.props
    this.setState({ isCreateLoading: true }, () => {
      const newUsers = (selectedUsers || []).map(item => {
        const curUser = userList.find(user => user.id.toLowerCase() === item)
        return curUser
      })
      const users = (newUsers || []).map(item => {
        return {
          id: item.id,
          accessRoleGroups: (item.accessRoleGroups || []).map(accessItem => accessItem.id)
        }
      })
      let variables = {
        name: userGroupTitle,
        description,
        users,
        project
      }
      if (modules) { variables.modules = modules }
      this.props.createAccessRoleGroup(variables).then(() => {
        this.setState({ isCreateLoading: false, toCreateGroup: false })
      }, error => {
        this.setState({ isCreateLoading: false, toCreateGroup: false })
        utilityService.handleError(error)
      })
    })
  }

  onChangeUserGroups = (userGroupTitle, description, selectedUsers, isFromEdit, moduleList) => {
    if (isFromEdit) {
      const { userId, userList } = this.state
      const { groupList, project } = this.props
      const selected = groupList.find(item => item.id === userId)
      const modules = (selected.modules || []).map(item => {
        return {
          name: item.name,
          permissions: item.permissions && item.permissions.length ? item.permissions : []
        }
      })
      const oldUsers = (selected.users || []).map(item => {
        return {
          id: item.id,
          accessRoleGroups: (item.accessRoleGroups || []).map(accessRole => accessRole.id)
        }
      })
      let newUsers = (selectedUsers || []).map(item => {
        const accessRole = userList.find(user => user.id === item)
        return {
          id: item,
          accessRoleGroups: (accessRole.accessRoleGroups || []).map(accessRole => accessRole.id)
        }
      })

      let userDiff = _.differenceBy(newUsers, oldUsers, 'id')
      if (userDiff.length) {
        userDiff = userDiff.map(user => {
          const userIndex = (user.accessRoleGroups || []).findIndex(item => item === userId)
          if (userIndex > -1) {
            user.accessRoleGroups.splice(userIndex, 1)
          }
          return user
        })
        newUsers = newUsers.map(user => {
          const userIndex = (userDiff || []).findIndex(item => item.id === user.id)
          return userIndex === -1 ? user : userDiff[userIndex]
        })
      }
      const variables = {
        id: userId,
        name: userGroupTitle,
        description,
        modules,
        oldUsers,
        newUsers,
        project
      }
      this.props.updateAccessRoleGroup(variables).then(() => {
        this.setState({ userId: '' })
      }, error => {
        this.setState({ userId: '' })
        utilityService.handleError(error)
      })
    } else {
      const modules = (moduleList || []).map(item => {
        return {
          name: item.name,
          permissions: item.permissions ? item.permissions : []
        }
      })
      this.createUserGroups(userGroupTitle, description, selectedUsers, modules)
    }
  }

  onSaveConfirmUser = () => {
    this.setState({ isModalLoading: true }, () => {
      const { userGroupsPermissionList } = this.state
      const { groupList, project } = this.props
      const changedArr = _.differenceWith(userGroupsPermissionList, groupList, _.isEqual)
      const accessRoleGroups = (changedArr || []).map(item => {
        const modules = (item.modules || []).map(moduleItem => {
          return {
            name: moduleItem.name,
            permissions: moduleItem.permissions && moduleItem.permissions.length ? moduleItem.permissions : null
          }
        })

        const users = (item.users || []).map(user => {
          return {
            id: user.id,
            accessRoleGroups: (user.accessRoleGroups || []).map(accessItem => accessItem.id)
          }
        })

        return {
          description: item.description,
          id: item.id,
          name: item.name,
          modules: modules && modules.length ? modules : null,
          users
        }
      })
      this.props.batchUpdateAccessRoleGroups({ accessRoleGroups, project }).then(() => {
        this.setState({ isModalLoading: false, isShowEditConfirm: false, userGroupsPermissionList: [], isEditGroup: false })
      }, error => {
        this.setState({ isModalLoading: false, isShowEditConfirm: false, isEditGroup: false })
        utilityService.handleError(error)
      })
    })
  }

  onChangePermissions = (userGroupsPermissionList) => {
    this.setState({ userGroupsPermissionList })
  }

  onUserGroupDelete = (userId, isEdit) => {
    if (isEdit) { this.setState({ userId }) } else { this.setState({ isDeleteConfirmVisible: true, userId }) }
  }

  toggleDeleteWarningMessage = () => {
    this.setState({ isDeleteConfirmVisible: false, userId: '' })
  }

  onDeleteConfirmUser = () => {
    this.setState({ isModalLoading: true }, () => {
      const { userId } = this.state
      const { project } = this.props
      this.props.deleteAccessRoleGroup({ userId, project }).then(() => {
        this.setState({ isModalLoading: false, isDeleteConfirmVisible: false, userId: '' })
      }, error => {
        this.setState({ isModalLoading: false, isDeleteConfirmVisible: false })
        utilityService.handleError(error)
      })
    })
  }

  makeCsvFile = () => {
    const { groupList, project } = this.props
    if (!groupList.length) {
      message.warning('Group data not available')
      return
    }
    const groupListFormatted = getFormattedGroupList(groupList, project)
    const csvContent = groupListFormatted && convertToCSV(groupListFormatted)
    downloadCSV(csvContent, 'group_details.csv')
    const auditVariables = {
      name: 'User Group',
      value: 'CSV Downloaded',
      contentId: 'group_csv_download'
    }
    this.props.createAudit(auditVariables)
  }

  render () {
    const { toCreateGroup, isEditGroup, isShowEditConfirm, isModalLoading, userGroupsPermissionList, isCancelClicked, isCreateLoading, isDeleteConfirmVisible, userList } = this.state
    const { groupList, onSearchUser, project, onUserGroupClick } = this.props

    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['ROLE_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          return <div className='role-user-group-details' id='role-user-group-details' >
            <RoleUserGroupTable
              onUserGroupClick={onUserGroupClick}
              isEditGroup={isEditGroup}
              groupList={groupList}
              userList={userList}
              onChangePermissions={this.onChangePermissions}
              isCancelClicked={isCancelClicked}
              onChangeUserGroups={this.onChangeUserGroups}
              onUserGroupDelete={this.onUserGroupDelete}
              onSearchUser={onSearchUser}
              isCreateDisabled={isCreateDisabled}
              project={project}
              isUpdateDisabled={isUpdateDisabled} />
            <div className='create-button'>
              <LoadingButton
                type='primary'
                onClick={this.makeCsvFile}
                htmlType='submit'
                buttonText='Download CSV'
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isUpdateDisabled}
              />
              <LoadingButton
                type='primary'
                onClick={!isEditGroup ? this.onEditGroup : this.onCancelEdit}
                htmlType='submit'
                buttonText={`${!isEditGroup ? 'Edit Permissions' : 'Cancel'}`}
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isUpdateDisabled}
              />
              <LoadingButton
                type='primary'
                onClick={() => this.onCreateUserGroup(isEditGroup)}
                htmlType='submit'
                buttonText={`${!isEditGroup ? 'Create User Group' : 'Save Permissions'}`}
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isEditGroup ? (!(userGroupsPermissionList && userGroupsPermissionList.length) || isUpdateDisabled) : isCreateDisabled}
              />
              <CreateUserGroupModal
                visible={toCreateGroup}
                handleSubmit={this.createUserGroups}
                handleCancel={this.hideAddUserGroupModal}
                loading={isCreateLoading}
                title={'Create User Group'}
                okText={'Create'}
                userList={userList}
                onSearchUser={onSearchUser}
                isSubmitDisabled={isCreateDisabled} />

              <ConfirmModal
                isVisible={isShowEditConfirm}
                title={'Save Permissions'}
                message={'Do you want to continue?'}
                isLoading={isModalLoading}
                rightButtonText={'Confirm'}
                handleCancel={this.toggleEditWarningMessage}
                handleSubmit={this.onSaveConfirmUser}
                isSubmitButtonDisabled={isUpdateDisabled} />

              <ConfirmModal
                isVisible={isDeleteConfirmVisible}
                title={'Delete User groups'}
                message={'Do you want to delete this user group ?'}
                isLoading={isModalLoading}
                rightButtonText={'Confirm'}
                handleCancel={this.toggleDeleteWarningMessage}
                handleSubmit={this.onDeleteConfirmUser}
                isSubmitButtonDisabled={isDeleteDisabled} />
            </div>
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

RoleUserGroupDetails.propTypes = {
}

export default withApollo(compose(

  graphql(
    QueryGroupList,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        const { data } = props
        return {
          groupList: data.getAccessRoleGroups && data.getAccessRoleGroups.length ? _.sortBy(data.getAccessRoleGroups, 'name') : []
        }
      }
    }
  ),
  graphql(
    QueryUserList,
    {
      options: ({ userSearch, project }) => {
        const variables = { search: userSearch, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        return {
          userList: data.listUsers && data.listUsers.items && data.listUsers.items.length ? data.listUsers.items : []
        }
      }
    }
  ),
  graphql(
    MutationBatchUpdateGroups,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { batchUpdateAccessRoleGroups } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGroupList, variables: { project } }))
            if (cacheData && cacheData.getAccessRoleGroups) {
              (batchUpdateAccessRoleGroups || []).map(updateAccessRoleGroup => {
                const index = cacheData.getAccessRoleGroups.findIndex((item) => updateAccessRoleGroup.id === item.id)
                cacheData.getAccessRoleGroups[index] = updateAccessRoleGroup
              })
            }
            cache.writeQuery({
              query: QueryGroupList,
              data: cacheData,
              variables: { project }
            })
          }
        }
      },
      props: (props) => ({
        batchUpdateAccessRoleGroups: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAccessRoleGroup,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { updateAccessRoleGroup } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGroupList, variables: { project } }))
            if (cacheData && cacheData.getAccessRoleGroups) {
              const index = cacheData.getAccessRoleGroups.findIndex((item) => updateAccessRoleGroup.id === item.id)
              cacheData.getAccessRoleGroups[index] = updateAccessRoleGroup
            }
            cache.writeQuery({
              query: QueryGroupList,
              data: cacheData,
              variables: { project }
            })
          }
        }
      },
      props: (props) => ({
        updateAccessRoleGroup: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateGroups,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { createAccessRoleGroup } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGroupList, variables: { project } }))
            if (cacheData && cacheData.getAccessRoleGroups) {
              cacheData.getAccessRoleGroups = [createAccessRoleGroup, ...cacheData.getAccessRoleGroups]
            }
            cache.writeQuery({
              query: QueryGroupList,
              data: cacheData,
              variables: { project }
            })
          }
        }
      },
      // options: (props) => {
      //   return {
      //     refetchQueries: () => [{ query: QueryGroupList, fetchPolicy: 'network-only' }]
      //   }
      // },
      props: (props) => ({
        createAccessRoleGroup: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteUserGroups,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deleteAccessRoleGroup } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGroupList, variables: { project } }))
            if (cacheData && cacheData.getAccessRoleGroups) {
              const index = cacheData.getAccessRoleGroups.findIndex((item) => deleteAccessRoleGroup.id === item.id)
              cacheData.getAccessRoleGroups.splice(index, 1)
            }
            cache.writeQuery({
              query: QueryGroupList,
              data: cacheData,
              variables: { project }
            })
          }
        }
      },
      props: (props) => ({
        deleteAccessRoleGroup: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    MutationCreateAudit,
    {
      props: (props) => ({
        createAudit: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(RoleUserGroupDetails))
