import React, { Component } from 'react'
import { Table, Empty, Skeleton } from 'antd'

import moment from 'moment'
import { Resizable } from 'react-resizable'

import Tooltip from './ToolTip'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListStreamSessions from '../../../graphQL/customers/listStreamSessions'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class StreamSessionTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Session ID',
        dataIndex: 'sessionId',
        key: 'sessionId',
        width: 100
      },
      {
        title: 'Asset',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (id, data) => this.renderAssetDetails(id, data)
      },
      {
        title: 'Platform',
        dataIndex: 'id',
        width: 100,
        key: 'platform',
        render: (id, data) => this.renderAppDetails(id, data)
      },
      {
        title: 'Device',
        dataIndex: 'id',
        key: 'model',
        width: 100,
        render: (id, data) => this.renderDeviceDetails(id, data)
      },
      {
        title: 'isActive',
        dataIndex: 'isActive',
        key: 'isActive',
        width: 100,
        render: (isActive) => isActive === true ? 'true' : 'false'
      },
      {
        title: 'isKicked',
        dataIndex: 'isKicked',
        key: 'isKicked',
        width: 100,
        render: (isKicked) => isKicked === true ? 'true' : 'false'
      },
      {
        title: 'IP Address',
        dataIndex: 'ip',
        key: 'ip',
        width: 100
      },
      {
        title: 'Browser',
        key: 'userAgent',
        dataIndex: 'browser.userAgent',
        width: 100
      },
      {
        title: 'Created At',
        key: 'createdAt',
        dataIndex: 'createdAt',
        width: 100,
        render: (createdAt) => createdAt ? moment(new Date(createdAt)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'HeartBeats',
        key: 'heartBeats',
        dataIndex: 'id',
        width: 100,
        render: (id, data) => this.renderHeartBeats(id, data)
      },
      {
        title: 'Client Heart Beat',
        dataIndex: 'id',
        width: 100,
        key: 'clientHeartBeat',
        render: (id, data) => this.renderClientHeartBeats(id, data)
      },
      {
        title: 'Backend Heart Beat',
        dataIndex: 'id',
        width: 100,
        key: 'backendHeartBeat',
        render: (id, data) => this.renderBackendHeartBeats(id, data)
      }
    ]
    this.state = {
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
  }

  renderAssetDetails = (id, { asset }) => {
    return <div className='stream-session-asset'>
      <span> {asset && asset.title ? asset.title : ''}  </span>
      {asset && asset.id ? <a className='asset-id' target='_blank' href={`${location.origin}/assets/${asset.id}`} >{ ` ${asset.id}` }</a> : '' }
    </div>
  }

  renderAppDetails = (id, { app }) => {
    const description = <div>
      <span className='heading'>{ app && app.platform ? app.platform : '' }</span>
      <p><span className='key'>App Id:</span>{ app && app.appId ? app.appId : '-'}</p>
      <p><span className='key'>App Name:</span>{app && app.appName ? app.appName : '-'}</p>
      <p><span className='key'>Environment:</span>{ app && app.environment ? app.environment : '-'}</p>
      <p><span className='key'>Version:</span>{app && app.version ? app.version : '-'}</p>
    </div>
    const childComponent = (<span style={{ color: '#FF015A' }}>{ app && app.platform ? app.platform : '' }</span>)
    return (
      !_.isEmpty(app) ? <Tooltip placement='rightTop' key={id} heading={app.platform} childComponent={childComponent} description={description} parentCompoent={'root'} /> : '')
  }

  renderDeviceDetails = (id, { device }) => {
    const description = <div>
      <span className='heading'>{ device && device.model ? device.model : '' }</span>
      <p><span className='key'>OS Version:</span>{ device && device.osVersion ? device.osVersion : '-'}</p>
      <p><span className='key'>Make:</span>{device && device.make ? device.make : '-'}</p>
      <p><span className='key'>User Agent:</span>{ device && device.userAgent ? device.userAgent : '-'}</p>
      <p><span className='key'>Device Time:</span>{device && device.deviceTime ? moment(new Date(device.deviceTime)).format('DD MMM YYYY HH:mm:ss') : '-'}</p>
    </div>
    const childComponent = (<span style={{ color: '#FF015A' }}>{ device && device.model ? device.model : '' }</span>)
    return (
      !_.isEmpty(device) ? <Tooltip placement='rightTop' key={id} heading={device.model} childComponent={childComponent} description={description} parentCompoent={'root'} /> : '')
  }

  renderClientHeartBeats = (id, { clientHeartBeats }) => {
    return clientHeartBeats && clientHeartBeats.length ? moment(new Date(clientHeartBeats[0])).format('DD MMM YYYY, HH:mm:ss') : ''
  }

  renderBackendHeartBeats = (id, { backendHeartBeats }) => {
    return backendHeartBeats && backendHeartBeats.length ? moment(new Date(backendHeartBeats[0])).format('DD MMM YYYY, HH:mm:ss') : ''
  }

  renderHeartBeats = (id, data) => {
    const description = <div>
      <span className='heading'>{ 'HeartBeats' }</span>
      <p><span className='key'>Client HeartBeats:</span>{ (data && data.clientHeartBeats && data.clientHeartBeats.length ? (data.clientHeartBeats || []).map(item => <label >{moment(new Date(item)).format('DD MMM YYYY, HH:mm:ss')} </label>) : '') }</p>
      <p><span className='key'>Backend HeartBeats:</span>{ (data && data.backendHeartBeats && data.backendHeartBeats.length ? (data.backendHeartBeats || []).map(item => <label >{moment(new Date(item)).format('DD MMM YYYY, HH:mm:ss')} </label>) : '') }</p>
    </div>
    const childComponent = (<span style={{ color: '#FF015A' }}>{ 'See Dates' }</span>)
    return (
      !_.isEmpty(data && (data.clientHeartBeats || data.backendHeartBeats)) ? <Tooltip placement='rightTop' key={id} heading={'HeartBeats'} childComponent={childComponent} description={description} parentCompoent={'root'} /> : '')
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  render () {
    const { streamSessions, isLoading } = this.props
    const { width, height, columns } = this.state

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))

    return (
      <div className='general-customer-details-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(streamSessions) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={streamSessions} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
      </div>
    )
  }
}

StreamSessionTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListStreamSessions,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const streamSessions = data && data.listStreamSessions ? data.listStreamSessions : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { streamSessions, isLoading: data && data.loading }
      }
    }
  )
)(StreamSessionTable))
