import React from 'react'
import moment from 'moment'
import _ from 'lodash'
const lastReplaceRegex = /, ([^,]*)$/

const actionMap = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
  ADD: 'Updated Job',
  REMOVE: 'Removed',
  CLEARED: 'Cleared'
}
const fieldMap = {
  CSV_FILE_UPLOAD: 'CSV_FILE_UPLOAD'
}

const replaceIdwithItems = (ids, props, contentType) => {
  const modifiedIds = ids.map(id => {
    return id
  })
  return modifiedIds
}

const getFormattedIngestJobManagerDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let content
  let changedValue = audit.value

  const oldValue = audit.oldValue
  const newValue = audit.value
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }

  const arrayChanges = getArrayChanges(newValue, oldValue, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue

  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }

  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
}
const getArrayChanges = (newValue, oldValue, props, isCustomMedia = false) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || (isCustomMedia && (newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = replaceIdwithItems(_.difference(newArray, oldArray), props)
    const removedItems = replaceIdwithItems(_.difference(oldArray, newArray), props)
    if (newItems.length && removedItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} also Removed ${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = removedItems.join(', ').replace(lastReplaceRegex, ' and $1')
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = newItems.join(', ').replace(lastReplaceRegex, ' and $1')
    }
  } else return
  return { changedValue, action }
}

const getFormattedIngestSidebarHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedIngestJobManagerDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [ { action: `${actionMap[action]}` } ]
  }

  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedIngestManagerDetails = (audit, action, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name

  let changedValue = audit.value
  const oldValue = audit.oldValue
  const newValue = audit.value

  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }

  const arrayChanges = getArrayChanges(newValue, oldValue, field, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  action = arrayChanges ? arrayChanges.action : action
  field = arrayChanges ? arrayChanges.field : field

  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue }
  }
  return { action: actionMap[action], field, value: ` ${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}` }
}

const getFormattedIngestHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedIngestManagerDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    let field
    let action
    if (contentType === 'CSV_FILE_UPLOAD') {
      field = 'csv file upload'
      action = 'Initiated'
    } else if (contentType === 'GRACENOTE_PROGRAM_SYNC') {
      field = 'graceNote program sync'
      action = 'Initiated onDemand'
    } else {
      field = 'graceNote program avail sync'
      action = 'Initiated'
    }
    descriptions = [ { action: action, field } ]
  }
  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
    </p>
  ))
}

class IngestHistoryCell extends React.Component {
  render () {
    const { details, content } = this.props
    const { updatedBy, updatedAt } = details
    let historyList
    if (content === 'INGEST_JOB_MANAGER') {
      historyList = getFormattedIngestSidebarHistoryDetails(details, this.props)
    } else {
      historyList = getFormattedIngestHistoryDetails(details, this.props)
    }
    return (
      <React.Fragment>
        {content === 'INGEST_JOB_MANAGER' ? <div className='history-list-cell' >
          <div className='heading'>
            <p className='user-name'>{updatedBy}</p>
            <span className='date'>{moment(updatedAt).format('DD MMM YYYY HH:mm')}</span>
          </div>
          { historyList }
        </div>
          : <div className='ingest-history-cell ingest-history-general' >
            <div className='date'> {moment(updatedAt).format('DD MMM YYYY HH:mm')} </div>
            <div className='date'> {updatedBy} </div>
            <div className='details'> {historyList} </div>
          </div>

        }
      </React.Fragment>

    )
  }
}

IngestHistoryCell.defaultProps = {
  details: {
    user: 'David Nelson',
    date: '28 Nov 2018, 12:00',
    description: 'David Nelson created an Article “Football mourns the tragic ending for a man who created a miracle and so much hope”'
  }
}

export default IngestHistoryCell
