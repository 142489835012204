import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import PopoverConfirm from './../feedback/PopoverConfirm'
import avatar from './../../../assets/images/avatar.jpg'

const parentCompoent = 'asset-container'

const AuthorContent = ({ authorName, authorImage, onCloseContent, ...props }) => (
  <div className='author-content'>
    <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
    <img className='author' src={authorImage || avatar} />
    <p className='author-name' > { authorName || 'Author' } </p>
  </div>
)
AuthorContent.propTypes = {
  /** Author Name of Author AuthorContent. */
  authorName: PropTypes.string.isRequired,
  /** Author Image of Author AuthorContent. */
  authorImage: PropTypes.string.isRequired,
  /** close action of AuthorContent. */
  onCloseContent: PropTypes.func
}

AuthorContent.defaultProps = {
  authorImage: avatar
}

export default AuthorContent
