import React from 'react'
// import PropTypes from 'prop-types'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { utilityService } from '../../../services/UtilityService'

import Input from '../dataEntry/inputs/Input'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import DropDown from '../dataEntry/inputs/DropDown'

import QueryAllTags from '../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../graphQL/asset/createTag'
import QuerySports from '../../../graphQL/admin/listSports'
import './../ui.style.scss'

const compFormat = [{
  name: 'International cup',
  id: 'internationalCup'
}, {
  name: 'International super cup',
  id: 'internationalSuperCup'
}, {
  name: 'Domestic league',
  id: 'domesticLeague'
}]

const compType = [{
  name: 'club',
  type: 'Club',
  id: 'club'
}, {
  name: 'international',
  type: 'International',
  id: 'international'
}]

const country = [{
  name: 'Europe',
  id: 'europe'
}, {
  name: 'World',
  id: 'world'
}, {
  name: 'England',
  id: 'england'
}]

const type = [{
  name: 'men',
  type: 'Men',
  id: 'men'
}, {
  name: 'women',
  type: 'Women',
  id: 'women'
}]

class CompetitionCustomData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {},
      sport: props.sports && props.sports.length ? props.sports : []
    }
  }

  componentDidMount = () => {
    if (this.props.selectedCompetition) {
      this.setState({
        details: _.cloneDeep(this.props.selectedCompetition)
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedCompetition && !_.isEqual(this.props.selectedCompetition, nextProps.selectedCompetition)) {
      this.setState({ details: _.cloneDeep(nextProps.selectedCompetition) })
    }
    if (nextProps.sports && !_.isEqual(nextProps.sports, this.props.sports)) {
      this.setState({ sport: nextProps.sports })
    }
  }

  onOptionSelect = (name, type) => {
    const { details } = this.state
    if (type === 'country') {
      details.customCountry = name
    } else if (type === 'comFormat') {
      details.customCompetitionFormat = name
    } else if (type === 'type') {
      details.customType = name
    } else if (type === 'compType') {
      details.customCompetitionType = name
    } else if (type === 'source') {
      details.customSource = name
    } else if (type === 'sport') {
      details.sport.title = name
    }
    this.setState({ details }, () => this.props.onEditCompetition(details))
  }

  // handleGeneralChange = (e) => {
  //   const { details } = this.state
  //   const { name, value } = e.target
  //   if (!value.startsWith(' ')) {
  //     details[name] = value
  //     this.setState({ details }, () => this.props.onEditCompetition(details))
  //   }
  // }

  handleTitleChange = (e) => {
    this.setState({ isEditTitle: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customTitle = null
      } else {
        details.customTitle = value
      }
      this.setState({ details }, () => this.props.onEditCompetition(details))
    }
  }
  handleCodeChange = (e) => {
    this.setState({ isEditCode: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customCompetitionCode = null
      } else {
        details.customCompetitionCode = value
      }
      this.setState({ details }, () => this.props.onEditCompetition(details))
    }
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { details } = this.state
    const selectedTags = details.tags ? details.tags : []
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'DATA_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            details.tags = selectedTags
            this.setState({ details }, () => this.props.onEditCompetition(details))
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}data_manager`
              selectedTags[tagIndex].key = newId
              details.tags = selectedTags
              this.setState({ details }, () => this.props.onEditCompetition(details))
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
          details.tags = selectedTags
        }
      }
    }
    this.setState({ details }, () => this.props.onEditCompetition(details))
  }

  render () {
    const { details, sport, isEditTitle, isEditCode } = this.state
    const { project } = this.props
    return (
      <div className='competition-data'>
        {/* <div className='competition-img'>
          <PremierLeagueIcon />
        </div> */}
        <div className='data'>
          <div className='custom-data'>
            <div className='details'>
              <Input title='Title' placeholder='Write a title...' inputName='customTitle' value={isEditTitle ? details.customTitle : details.customTitle ? details.customTitle : details.title} handleChange={this.handleTitleChange} />
            </div>
            <div className='details'>
              <Input title='Competition Code' placeholder='Write a Competition Code...' inputName='customCompetitionCode' value={isEditCode ? details.customCompetitionCode : details.customCompetitionCode ? details.customCompetitionCode : details.competitionCode} handleChange={this.handleCodeChange} />
            </div>
            {/* <div className='details'>
              <Input title='Display Order' placeholder='Write a title...' inputName='displayOrder' value={details.displayOrder} handleChange={ () => { } } />
            </div> */}
            <div className='details' id='details'>
              <label>Country</label>
              <DropDown options={country} onOptionSelect={(name) => this.onOptionSelect(name, 'country')} selectedValue={details.customCountry ? details.customCountry : details.country} valueParam='name' displayParam='name' placeHolder='Select Country' parent={'details'} />
            </div>
            <div className='details'>
              <label>Competition Format</label>
              <DropDown options={compFormat} onOptionSelect={(name) => this.onOptionSelect(name, 'comFormat')} selectedValue={details.customCompetitionFormat ? details.customCompetitionFormat : details.competitionFormat} valueParam='name' displayParam='name' placeHolder='Select Competition format' parent={'details'} />
            </div>
            <div className='details'>
              <label>Type</label>
              <DropDown options={type} onOptionSelect={(name) => this.onOptionSelect(name, 'type')} selectedValue={details.customType ? _.capitalize(details.customType) : _.capitalize(details.type)} valueParam='type' displayParam='type' placeHolder='Type' parent={'details'} />
            </div>
            <div className='details'>
              <label>Competition Type</label>
              <DropDown options={compType} onOptionSelect={(name) => this.onOptionSelect(name, 'compType')} selectedValue={details.customCompetitionType ? _.capitalize(details.customCompetitionType) : _.capitalize(details.competitionType)} valueParam='type' displayParam='type' placeHolder='Select Competition Type' parent={'details'} />
            </div>
            {/* <div className='details'>
              <label>Source</label>
              <DropDown options={source} onOptionSelect={(name) => this.onOptionSelect(name, 'source')} selectedValue={details.customSource? details.customSource : details.source} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div> */}
            <div className='details' id='details'>
              <label>Sport</label>
              <DropDown options={sport} onOptionSelect={(title) => this.onOptionSelect(title, 'sport')} selectedValue={details.sport ? details.sport.title : null} valueParam='title' displayParam='title' placeHolder='Select Sport' parent={'details'} />
            </div>
          </div>
          <div className='custom-tags' id='custom-tags'>
            <label> Tags </label>
            <TagsInputContainer
              selectedItems={details && details.tags ? details.tags.filter(item => item).map(item => item.name) : []}
              onChange={this.handleTagChange}
              placeholder='Insert Tags'
              parentCompoent={'custom-tags'}
              displayParameter='name'
              onSearch={() => {}}
              searchTag={''}
              type={'MANUAL'}
              isCustom
              module={'DATA_MANAGER'}
              project={project}
            />
          </div>
          {/* <div className='type'>
            <span>IsFriendly</span>
            <div className='information-icon'>
              <InformationIcon />
            </div>
            <Checkbox checked={details.isFriendly==='yes'? true : false } onClick={() => { }} />
          </div> */}

        </div>
      </div>
    )
  }
}

CompetitionCustomData.propTypes = {

}

CompetitionCustomData.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateTag,
    {
      options: ({ project }) => {
        const filter = { type: { match: 'MANUAL' } }
        return {
          update: (cache, { data: { createTag } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },

      props: (props) => ({
        createTag: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QuerySports,
    {
      options: () => {
        return {
          variables: { limit: 999, offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listSports } }) => {
        let sports = listSports && listSports.items ? [ ...listSports.items ] : []
        return {
          sports
        }
      }
    }
  )

)(CompetitionCustomData))
