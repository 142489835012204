import gql from 'graphql-tag'

export default gql(`
    mutation updateOptaSyncState( $id: ID! $title: String  $lastRunTime: AWSDateTime $frequency: Int $opId: String $isActive: Boolean $isRunning: Boolean $isError: Boolean) {
        updateOptaSyncState(
            input: {
                id: $id
                title: $title
                lastRunTime: $lastRunTime
                frequency: $frequency
                isActive: $isActive
                isError: $isError
                isRunning: $isRunning
                opId: $opId
            }
        ) {
            id
            title
            lastRunTime
            frequency
            isActive
            isRunning
            isError
            opId
        }
    }`
)
