import React, { Component } from 'react'
import { Tabs } from 'antd'
// import PropTypes from 'prop-types'

import SubscriptionTable from '../../components/ui/dataDisplay/SubscriptionTable'
import SubscriptionTableHyperion from '../../components/ui/dataDisplay/SubscriptionTableHyperion'
import TransactionTableHyperion from '../../components/ui/dataDisplay/TransactionTableHyperion'
import BillingAccountsTable from '../../components/ui/dataDisplay/BillingAccountsTable'
import TransactionTable from '../../components/ui/dataDisplay/TransactionTable'
import SupportTicketTable from '../../components/ui/dataDisplay/SupportTicketTable'
// import UserEventTable from '../../components/ui/dataDisplay/UserEventTable'
import StreamSessionTable from '../../components/ui/dataDisplay/StreamSessionTable'
import ViewHistoryTable from '../../components/ui/dataDisplay/ViewHistoryTable'
import FavouriteTeamsTable from '../../components/ui/dataDisplay/FavouriteTeamsTable'
import ActiveDevicesTable from '../../components/ui/dataDisplay/ActiveDevicesTable'
import AdminAttributeTable from '../../components/ui/dataDisplay/AdminAttributeTable'
import AntiPiracyTable from '../../components/ui/dataDisplay/AntiPiracyTable'
// import DeviceTable from '../../components/ui/dataDisplay/DeviceTable'

const { TabPane } = Tabs

class CustomerTableDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mode: 'Subscription',
      EditSubscriptionHyperionId: null,
      targetAccount: null
    }
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  changeEditSubscriptionHyperionId = id => {
    this.setState({
      EditSubscriptionHyperionId: id
    })
  }

  setTargetAccount=(id) => {
    this.setState({ targetAccount: id })
  }

  renderHyperionTable = () => {
    const { selectedCustomer, isUpdateDisabled, fetchHistory, isActive, onChangeTab, project, profileOptionShown, selectedCustomerCogId } = this.props
    const { EditSubscriptionHyperionId, targetAccount } = this.state
    return <Tabs onChange={onChangeTab}>
      <TabPane tab='Subscriptions' key='1'>
        <SubscriptionTableHyperion selectedCustomer={selectedCustomer} isUpdateDisabled={isUpdateDisabled} fetchHistory={fetchHistory} isActive={isActive} project={project} profileOptionShown={profileOptionShown} changeEditSubscriptionHyperionId={this.changeEditSubscriptionHyperionId} EditSubscriptionHyperionId={EditSubscriptionHyperionId} />
      </TabPane>
      <TabPane tab='Transactions' key='2'>
        <TransactionTableHyperion selectedCustomer={selectedCustomer} isUpdateDisabled={isUpdateDisabled} fetchHistory={fetchHistory} isActive={isActive} project={project} profileOptionShown={profileOptionShown} />
      </TabPane>
      <TabPane tab='Billing Accounts' key='3'>
        <BillingAccountsTable selectedCustomer={selectedCustomer} isUpdateDisabled={isUpdateDisabled} fetchHistory={fetchHistory} isActive={isActive} project={project} profileOptionShown={profileOptionShown} selectedCustomerCogId={selectedCustomerCogId} setTargetAccount={this.setTargetAccount} targetAccount={targetAccount} />
      </TabPane>
    </Tabs>
  }

  render () {
    const { selectedCustomer, classStyle, isUpdateDisabled, fetchHistory, isActive, onChangeTab, project, profileOptionShown, userPermissions, refetch } = this.props
    return (
      <div className={`customer-table-details ${classStyle}`}>
        {project === 'hyperion' || project === 'projectx' ? this.renderHyperionTable()
          : <Tabs onChange={onChangeTab}>
            <TabPane tab='Subscription' key='1'>
              <SubscriptionTable selectedCustomer={selectedCustomer} isUpdateDisabled={isUpdateDisabled} fetchHistory={fetchHistory} isActive={isActive} profileOptionShown={profileOptionShown} />
            </TabPane>

            <TabPane tab='Purchase History' key='2'>
              <TransactionTable selectedCustomer={selectedCustomer} isActive={isActive} profileOptionShown={profileOptionShown} />
            </TabPane>

            <TabPane tab='Support Tickets' key='3'>
              <SupportTicketTable selectedCustomer={selectedCustomer} isActive={isActive} />
            </TabPane>

            {/* <TabPane tab='User Event Logs' key='4'>
          <UserEventTable selectedCustomer={selectedCustomer} />
        </TabPane> */}

            <TabPane tab='Stream Sessions' key='5'>
              <StreamSessionTable selectedCustomer={selectedCustomer} isActive={isActive} />
            </TabPane>

            <TabPane tab='Active Devices' key='6'>
              <ActiveDevicesTable selectedCustomer={selectedCustomer} isActive={isActive} />
            </TabPane>

            <TabPane tab='View History' key='7'>
              <ViewHistoryTable selectedCustomer={selectedCustomer} isActive={isActive} />
            </TabPane>

            <TabPane tab='Favourites' key='8'>
              <FavouriteTeamsTable selectedCustomer={selectedCustomer} isActive={isActive} />
            </TabPane>

            <TabPane tab='Admin Attribute' key='9'>
              <AdminAttributeTable selectedCustomer={selectedCustomer} fetchHistory={fetchHistory} userPermissions={userPermissions} isActive={isActive} />
            </TabPane>

            <TabPane tab='Anti-Piracy Status' key='10'>
              <AntiPiracyTable selectedCustomer={selectedCustomer} fetchHistory={fetchHistory} userPermissions={userPermissions} project={project} refetch={refetch} isActive={isActive} />
            </TabPane>

            {/* <TabPane tab='Device' key='8'>
          <DeviceTable selectedCustomer={selectedCustomer} />
        </TabPane> */}
          </Tabs>}
      </div>
    )
  }
}

CustomerTableDetails.propTypes = {
}

export default CustomerTableDetails
