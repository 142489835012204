import gql from 'graphql-tag'

export default gql(`
    mutation editApp($name: String! $key: String! $project: String $appIcon: String $description: String $bundleId: String $url: String $id: ID! $customMeta: [MetaFieldInput] $position: Int $isCritical: Boolean $isCriticalChanged: Boolean $assignedUsers: [String] $isAssigneeChanged: Boolean
        ) {
        editApp(
            input: {
                name: $name
                key: $key
                appIcon: $appIcon
                description: $description
                bundleId: $bundleId
                url: $url
                id: $id
                customMeta: $customMeta
                position: $position
                isCritical: $isCritical
                isCriticalChanged: $isCriticalChanged
                assignedUsers: $assignedUsers
                isAssigneeChanged: $ isAssigneeChanged
            },
            project: $project
        ) {
            id
            name
            project
            updatedAt
            url
            position
            key
            description
            assignedUsers
            createdAt
            bundleId
            configUsageCount
            pageUsageCount
            widgetUsageCount
            isCritical
            assignedUsers
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
            appIcon{
              id
              mediaId
              project
              name
              url
              fileName
              aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                  }
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2          
              }
            }
        }
               
    }`
)
