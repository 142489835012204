import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, DatePicker, message } from 'antd'
import Input from '../inputs/Input'
import moment from 'moment'

import './../../ui.style.scss'

const errorMessages = [
  `Password doesn't match`,
  `Password must be atleast 8 characters`,
  `Password must have atleast one number`,
  `Password must have atleast one letter`
]

class ChangeCustomerPasswordModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      offerCode: '',
      endDate: '',
      isEdited: false,
      isendDateError: false,
      errorMessage: errorMessages[0]
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    const { data } = nextProps
    if (!this.state.isEdited && data && data.email) {
      this.setState({
        email: data.email
      })
    }
  }

  handleChangeOfferCode = (event) => {
    const offerCode = event.target.value
    this.setState({ offerCode, isEdited: true })
  }

  handleSubmit = () => {
    const { email, offerCode, endDate } = this.state
    if (!endDate) {
      message.warn('Empty End Date')
      return
    }
    const data = {
      email,
      offerCode,
      endDate
    }
    this.props.handleSubmit(data)
  }

  onChangeDOB = (endDate) => {
    this.setState({ endDate, isEdited: true })
  }

  clearState = () => {
    this.setState({
      endDate: '',
      offerCode: '',
      isEdited: false
    })
  }

  render () {
    const { isVisible, isSubmitDisabled, handleCancel, isLoading } = this.props
    const { isEdited, offerCode, endDate } = this.state
    return (
      <Modal
        className='general-modal create-subscription-modal'
        title={'CREATE SUBSCRIPTION'}
        maskClosable={false}
        visible={isVisible}
        okText='Create'
        cancelText='Cancel'
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ disabled: (isSubmitDisabled || !isEdited || !endDate) }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoading}
        afterClose={this.clearState}
        width='350'
      >
        { isVisible
          ? <div className='create-subscription-container'>
            <Input
              title='Offer Code'
              inputName='offerCode'
              handleChange={this.handleChangeOfferCode}
              value={offerCode}
              placeholder='Enter Offer Code'
              type={'text'}
              errorMessage='Please enter offer code' />
            <div className='date-picker-container'>
              <label>End Date</label>
              <DatePicker
                value={endDate ? moment(endDate) : null}
                format='DD MMM YYYY'
                onChange={this.onChangeDOB}
                placeholder='Select end date'
                allowClear
                timeDisabled
              />
            </div>
          </div> : null }
      </Modal>
    )
  }
}

ChangeCustomerPasswordModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

ChangeCustomerPasswordModal.defaultProps = {
}

export default ChangeCustomerPasswordModal
