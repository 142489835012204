import React from 'react'
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill' // ES6
import { Icon } from 'antd'
import richTextIcons from './../../../../constants/rich-text-editor-icons'

import './../../ui.style.scss'
import 'react-quill/dist/quill.snow.css'
import PopoverConfirm from '../../feedback/PopoverConfirm'
import { cloneDeep } from 'lodash'

const icons = ReactQuill.Quill.import('ui/icons')

icons[ 'header' ][ '0' ] = ReactDOMServer.renderToString(<Icon type='down' />)
icons[ 'header' ][ '1' ] = ReactDOMServer.renderToString(<Icon type='down' />)
icons[ 'header' ][ 'default' ] = ReactDOMServer.renderToString(<Icon type='down' />)
icons[ 'header' ][ 'active' ] = ReactDOMServer.renderToString(<Icon type='down' />)
icons[ 'header' ][ 'label' ] = ReactDOMServer.renderToString(<Icon type='down' />)
icons[ 'undo' ] = richTextIcons.undoIcon
icons[ 'redo' ] = richTextIcons.redoIcon
icons[ 'bold' ] = richTextIcons.boldIcon
icons[ 'italic' ] = richTextIcons.italicsIcon
icons[ 'underline' ] = richTextIcons.underlineIcon
icons[ 'strike' ] = richTextIcons.strikeIcon
icons[ 'list' ][ 'ordered' ] = richTextIcons.orderedListIcon
icons[ 'list' ][ 'bullet' ] = richTextIcons.unOrderedListIcon
icons[ 'blockquote' ] = richTextIcons.blockQuoteIcon
icons[ 'align' ][ '' ] = richTextIcons.leftAlignIcon
icons[ 'align' ][ 'center' ] = richTextIcons.centerAlignIcon
icons[ 'align' ][ 'right' ] = richTextIcons.rightAlignIcon
icons[ 'align' ][ 'justify' ] = richTextIcons.justifyIcon
icons[ 'link' ] = richTextIcons.linkIcon

function undo () {
  this.quill.history.undo()
}

function redo () {
  this.quill.history.redo()
}

const options = [
  { value: 1, displayValue: 'H1' },
  { value: 2, displayValue: 'H2' },
  { value: 3, displayValue: 'H3' },
  { value: 4, displayValue: 'H4' },
  { value: 5, displayValue: 'H5' },
  { value: 6, displayValue: 'H6' },
  { value: '', displayValue: 'Paragraph' }
]

const dropDown = (
  <select className='ql-header' defaultValue={''} >
    { options.map((option, index) => <option key={`option${index}`} value={option.value}>{ option.displayValue }</option>) }
  </select>
)

const filterMatcher = (node, delta) => {
  const deltaOps = cloneDeep(delta.ops).map(item => {
    if (item.insert && typeof item.insert === 'string') {
      if (item.attributes) {
        delete item.attributes.color
        delete item.attributes.background
      }
      if (typeof item.insert === 'string' && item.insert.length > 16) {
        while (true) {
          const isScriptStartTag = item.insert.indexOf('<script')
          const isScriptEndTag = item.insert.indexOf('</script>')
          if (isScriptStartTag > -1 && isScriptEndTag > -1) {
            item.insert = `${item.insert.substring(0, isScriptStartTag)}`
            item.insert = `${item.insert.substring(isScriptEndTag + 9, item.insert.length)}`
          } else {
            break
          }
        }
      }
      return item
    }
  })
  delta.ops = deltaOps
  return delta
}
const parentCompoent = 'asset-container'
class RichText extends React.Component {
  CustomToolbar = () => (
    <div className='toolbar' id={`toolbar${this.props.id}`}>
      { dropDown }
      <button className='ql-undo'>UN</button>
      <button className='ql-redo'>RE</button>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
      <button className='ql-list' value='bullet' />
      <button className='ql-list' value='ordered' />
      <button className='ql-blockquote' />
      <button className='ql-align' />
      <button className='ql-align' value='right' />
      <button className='ql-align' value='center' />
      <button className='ql-align' value='justify' />
      <button className='ql-link' />
    </div>
  )

    getModule = id => {
      return {
        toolbar: {
          container: `#toolbar${id}`,
          handlers: {
            undo: undo,
            redo: redo
          }
        },
        clipboard: {
          matchers: [[Node.ELEMENT_NODE, filterMatcher]]
        },
        keyboard: {
          bindings: this.bindings
        }
      }
    }

    getBasicModule= id => {
      return {
        toolbar: false,
        clipboard: {
          matchers: [[Node.ELEMENT_NODE, filterMatcher]]
        },
        keyboard: {
          bindings: this.bindings
        }
      }
    }

    bindings = {
      alignLeft: {
        key: 'l',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-align`)
          btnClick.click()
        }
      },
      alignRight: {
        key: 'r',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-align[value='right']`)
          btnClick.click()
        }
      },
      alignCenter: {
        key: 'c',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-align[value='center']`)
          btnClick.click()
        }
      },
      alignJustify: {
        key: 'j',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-align[value='justify']`)
          btnClick.click()
        }
      },
      blockQuote: {
        key: 'q',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-blockquote`)
          btnClick.click()
        }
      },
      unList: {
        key: 'u',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-list`)
          btnClick.click()
        }
      },
      orderList: {
        key: 'o',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-list`)
          btnClick.click()
        }
      },
      strike: {
        key: 'd',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-strike`)
          btnClick.click()
        }
      },
      link: {
        key: 'a',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-link`)
          btnClick.click()
        }
      },
      paragraph: {
        key: '0',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:nth-child(7)`)
          btnClick.click()
        }
      },
      h1: {
        key: '1',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:first-child`)
          btnClick.click()
        }
      },
      h2: {
        key: '2',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:nth-child(2)`)
          btnClick.click()
        }
      },
      h3: {
        key: '3',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:nth-child(3)`)
          btnClick.click()
        }
      },
      h4: {
        key: '4',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:nth-child(4)`)
          btnClick.click()
        }
      },
      h5: {
        key: '5',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:nth-child(5)`)
          btnClick.click()
        }
      },
      h6: {
        key: '6',
        altKey: true,
        shiftKey: true,
        handler: () => {
          let btnClick = document.querySelector(`#toolbar${this.props.id} .ql-picker-options .ql-picker-item:nth-child(6)`)
          btnClick.click()
        }
      }
    }

    onRichTextChange = (data, delta, source) => {
      if (source === 'user') { this.props.onRichTextChange(data) }
    }

    render () {
      const { id, onCloseContent, onInputFieldFocus, onInputFieldBlur, text, showCloseIcon, readOnly, showToolbar } = this.props
      return (
        <pre className='text-editor'>
          {
            showCloseIcon ? <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} /> : null
          }
          {showToolbar === false ? null : <this.CustomToolbar />}
          <ReactQuill
            onChange={this.onRichTextChange}
            onFocus={onInputFieldFocus}
            onBlur={onInputFieldBlur}
            placeholder=''
            modules={showToolbar === undefined || showToolbar === null || showToolbar === true ? this.getModule(id) : (showToolbar === false ? this.getBasicModule(id) : null)}
            value={text}
            bounds={'.text-editor'}
            readOnly={readOnly}
          />
        </pre>
      )
    }
}

RichText.propTypes = {
  /** identifier of RichTextEditor. */
  id: PropTypes.string,
  /** optional settings of RichTextEditor. */
  text: PropTypes.string,
  /** change action of RichTextEditor. */
  onRichTextChange: PropTypes.func,
  /** close action of RichText. */
  onCloseContent: PropTypes.func
}

export default RichText
