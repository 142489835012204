import React, { Component } from 'react'
// import PropTypes from 'prop-types'
//
import AppContext from '../../AppContext'
import { message, Switch } from 'antd'
import moment from 'moment'

import DataPicker from '../../components/ui/dataEntry/inputs/DatePicker'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetAppConfig from '../../graphQL/appManager/getAppConfig'
import MutationUpdateConfig from '../../graphQL/appManager/publishAppConfig'
import { utilityService } from '../../services/UtilityService'
class AppPublishDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      appDetails: this.props.appDetails ? _.cloneDeep(this.props.appDetails) : null,
      isLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.appDetails && (!_.isEqual(newProps.appDetails, this.props.appDetails) || (!this.state.appDetails && _.isEqual(newProps.appDetails, this.props.appDetails)))) {
      this.setState({ appDetails: _.cloneDeep(newProps.appDetails) })
    }
  }

  onStartDateChange = async (value) => {
    let { appDetails } = this.state
    let appDetailsProp = this.props.appDetails
    let isAppCritical = (appDetailsProp && appDetailsProp.app && appDetailsProp.app.isCritical) || false
    const isAssignedUser = ((appDetailsProp && appDetailsProp.assignedUsers) || []).find(user => user === this.props.userId)
    if (this.props.isAppAdminDisabled && appDetailsProp && appDetailsProp.assignedUsers && appDetailsProp.assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }
    if ((isAppCritical || appDetailsProp.isCritical) && this.props.isCriticalWriteDisabled && this.props.isAppAdminDisabled) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    let app = (appDetailsProp && appDetailsProp.app && appDetailsProp.app.id) || null
    const { isConfigSingleInstanceEdited } = this.props
    appDetails.publishStartDate = value ? new Date(moment(value).utc()).toISOString() : value
    if (appDetails && appDetails.type === 'CONFIG') {
      if (isConfigSingleInstanceEdited) {
        message.error('Please save config before making any changes in Publish section.')
        return
      }
    }
    if (appDetails && appDetails.publishStartDate && appDetails.publishEndDate && appDetails.isPublished && moment(appDetails.publishStartDate).isAfter(moment(appDetails.publishEndDate))) {
      message.error('Please enter a valid startDate')
      return
    }
    if (!value || value === null) {
      if (appDetails.isPublished === true) {
        appDetails.isPublished = !appDetails.isPublished
      }
    }
    this.setState({ isLoading: true })

    if (await this.props.checkForConfigModification()) {
      this.setState({ isLoading: false })
      return
    }
    this.props.updateAppConfig(appDetails.id, appDetails.isPublished, appDetails.publishStartDate, appDetails.publishEndDate, app).then((res) => {
      if (res && res.data && res.data.publishAppConfig) {
        this.setState({ appDetails: res.data.publishAppConfig, isLoading: false })
      }
    }, error => {
      utilityService.handleError(error)
      this.setState({ isLoading: false })
    })
  }

  onEndDateChange = async (value) => {
    let { appDetails } = this.state
    let appDetailsProp = this.props.appDetails
    let app = (appDetailsProp && appDetailsProp.app && appDetailsProp.app.id) || null
    let isAppCritical = (appDetailsProp && appDetailsProp.app && appDetailsProp.app.isCritical) || false
    const isAssignedUser = ((appDetailsProp && appDetailsProp.assignedUsers) || []).find(user => user === this.props.userId)
    if (this.props.isAppAdminDisabled && appDetailsProp && appDetailsProp.assignedUsers && appDetailsProp.assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }
    if ((isAppCritical || appDetailsProp.isCritical) && this.props.isCriticalWriteDisabled && this.props.isAppAdminDisabled) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    appDetails.publishEndDate = value ? new Date(moment(value).utc()).toISOString() : value

    const { isConfigSingleInstanceEdited } = this.props
    appDetails.publishEndDate = value ? new Date(moment(value).utc()).toISOString() : value
    if (appDetails && appDetails.type === 'CONFIG') {
      if (isConfigSingleInstanceEdited) {
        message.error('Please save config before making any changes in Publish section.')
        return
      }
    }
    if (appDetails && appDetails.publishStartDate && appDetails.publishEndDate && appDetails.isPublished && moment(appDetails.publishStartDate).isAfter(moment(appDetails.publishEndDate))) {
      message.error('Please enter a valid endDate')
      return
    }
    if (!value || value === null) {
      if (appDetails.isPublished === true) {
        // appDetails.isPublished = !appDetails.isPublished
      }
    }
    this.setState({ isLoading: true })

    if (await this.props.checkForConfigModification()) {
      this.setState({ isLoading: false })
      return
    }
    this.props.updateAppConfig(appDetails.id, appDetails.isPublished, appDetails.publishStartDate, appDetails.publishEndDate, app).then((res) => {
      if (res && res.data && res.data.publishAppConfig) {
        this.setState({ appDetails: res.data.publishAppConfig, isLoading: false })
      }
    }, error => {
      utilityService.handleError(error)
      this.setState({ isLoading: false })
    })
  }

  onPublishChange = async (value) => {
    const { appDetails } = this.state
    const { userId } = this.props
    let appDetailsProp = this.props.appDetails
    let app = (appDetailsProp && appDetailsProp.app && appDetailsProp.app.id) || null
    let isAppCritical = (appDetailsProp && appDetailsProp.app && appDetailsProp.app.isCritical) || false
    const isAssignedUser = ((appDetailsProp && appDetailsProp.assignedUsers) || []).find(user => user === userId)
    if (this.props.isAppAdminDisabled && appDetailsProp && appDetailsProp.assignedUsers && appDetailsProp.assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }
    if ((isAppCritical || appDetailsProp.isCritical) && this.props.isCriticalWriteDisabled && this.props.isAppAdminDisabled) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    const { isConfigSingleInstanceEdited } = this.props
    if (appDetails && !appDetails.publishStartDate && !appDetails.publishEndDate && value === true) {
      appDetails.publishStartDate = moment().utc()
    }
    if (appDetails && appDetails.type === 'CONFIG') {
      if (isConfigSingleInstanceEdited) {
        message.error('Please save config before making any changes in Publish section.')
        return
      }
    }
    if (appDetails && !appDetails.publishStartDate) {
      message.error('Please select a startDate before Publishing')
      return
    }
    if (appDetails && appDetails.publishStartDate && appDetails.publishEndDate && moment(appDetails.publishStartDate).isAfter(moment(appDetails.publishEndDate))) {
      message.error('Please select a startDate before endDate')
      return
    }
    this.setState({ isLoading: true })

    if (await this.props.checkForConfigModification()) {
      this.setState({ isLoading: false })
      return
    }
    appDetails.isPublished = value
    this.props.updateAppConfig(appDetails.id, appDetails.isPublished, appDetails.publishStartDate, appDetails.publishEndDate, app).then((res) => {
      if (res && res.data && res.data.publishAppConfig) {
        this.setState({ appDetails: res.data.publishAppConfig, isLoading: false })
      }
    }, error => {
      utilityService.handleError(error)
      this.setState({ isLoading: false })
    })
  }

  disableStartDate = (currentDate) => {
    const { appDetails } = this.state
    if (appDetails && appDetails.publishEndDate) {
      return moment(currentDate).isAfter(moment(appDetails.publishEndDate), 'day')
    }
    return false
  }

  disableEndDate = (currentDate) => {
    const { appDetails } = this.state
    let IsValidDate = moment(currentDate).isBefore(moment(new Date()), 'day')
    if (appDetails && appDetails.publishStartDate) {
      IsValidDate = IsValidDate || moment(currentDate).isBefore(moment(appDetails.publishStartDate))
      return moment(currentDate).isBefore(moment(appDetails.publishStartDate), 'day')
    }
    return IsValidDate
  }

  render () {
    const { appDetails, isLoading } = this.state
    const { isPublishDisabled } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          return <React.Fragment>
            <div className='info'>
              <div className='title'>
                <span>PUBLISH</span>
              </div>
              <div className='publish-config-container'>
                <div className='publish-config-status'>
                  <span>Publish Now</span>
                  <Switch checked={appDetails && appDetails.isPublished} onChange={this.onPublishChange} loading={isLoading} disabled={isLoading || isPublishDisabled} />
                </div>
                <div className='publish-config-date'>
                  <div className='date'>
                    <div className={`date-selector${' error'}`}>
                      <DataPicker
                        className='date-picker'
                        placeholder='Select Start Date'
                        use12Hours={false}
                        date={appDetails && appDetails.publishStartDate ? moment(new Date(appDetails.publishStartDate), 'DD MMM YYYY, HH:mm').format('DD MMM YYYY, HH:mm') : null}
                        disabledDate={this.disableStartDate}
                        // disabledTime={endDate ? () => this.disabledRangeTime('end') : () => this.disableTimeComplete()}
                        dateFormat={'DD MMM YYYY, HH:mm'}
                        onChange={this.onStartDateChange}
                        disabled={isLoading}
                      />

                    </div>
                    <div className={`date-selector${' expired'}`}>
                      <DataPicker
                        placeholder='Select End Date'
                        className='date-picker'
                        use12Hours={false}
                        date={appDetails && appDetails.publishEndDate ? moment(new Date(appDetails.publishEndDate), 'DD MMM YYYY, HH:mm').format('DD MMM YYYY, HH:mm') : null}
                        disabledDate={this.disableEndDate}
                        // disabledTime={endDate ? () => this.disabledRangeTime('end') : () => this.disableTimeComplete()}
                        dateFormat={'DD MMM YYYY, HH:mm'}
                        onChange={this.onEndDateChange}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AppPublishDetails.defaultProps = {
  dateFormat: 'DD MMM YYYY, hh:mm A'
}

AppPublishDetails.propTypes = {

}

export default withApollo(
  compose(
    graphql(MutationUpdateConfig, {
      options: ({ appClient, project, selectedConfig }) => {
        return {
          update: (cache, { data: { publishAppConfig } }) => {
            const variables = { id: selectedConfig, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppConfig, variables }))
            if (cacheData && cacheData.getAppConfig) {
              // const index = (cacheData.listAppConfig.items || []).findIndex(config => config.id === selectedConfig)
              cacheData.getAppConfig.updatedAt = publishAppConfig.updatedAt
            }
            cache.writeQuery({
              query: QueryGetAppConfig,
              data: cacheData,
              variables
            })
          },
          client: appClient
        }
      },
      props: props => ({
        updateAppConfig: (id, isPublished, publishStartDate, publishEndDate, app) => {
          return props.mutate({
            variables: { id, isPublished, publishStartDate, publishEndDate, project: props.ownProps.project, app }
          })
        }
      })
    })
  )(AppPublishDetails))
