import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import PopoverConfirm from './../feedback/PopoverConfirm'

const parentCompoent = 'asset-container'

const SummaryContent = ({ summary, onCloseContent, isNotSummary, ...props }) => (
  <div className={isNotSummary ? 'description-content content' : 'description-content'}>
    <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
    <p className='summary-text' > { summary } </p>
  </div>
)

SummaryContent.propTypes = {
  /** summary of Summary. */
  summary: PropTypes.string.isRequired,
  /** close action of Summary. */
  onCloseContent: PropTypes.func
}

SummaryContent.defaultProps = {
}
export default SummaryContent
