import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Input from '../inputs/Input'
import './../../ui.style.scss'
// const regex = /^[a-zA-Z0-9_-]*$/

class EditIdModal extends Component {
    state = {
      categoryName: undefined,
      categorySlug: undefined,
      isLoading: false,
      inputData: {}
    }

    handleChange =(e) => {
      const { name, value } = e.target
      if (!value.includes(',') || value === '') {
        this.setState({ inputData: { ...this.state.inputData, [name]: value } })
      }
    }

    handleSubmit = () => {
      this.props.handleSubmit(this.state.inputData)
    }

    resetState = () => {
      this.setState({ inputData: {} })
    }

    render () {
      const { isVisible, handleCancel, urlFieldArray } = this.props
      const { inputData } = this.state
      return (
        <Modal
          className='general-modal replace-params-modal'
          title={'Replace Params'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (Object.keys(inputData).length !== urlFieldArray.length) }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
        >
          <div className='create-category'>
            {
              urlFieldArray.map((item, index) => {
                if (index > 3) return null
                return <Input title={item} isRequired inputName={item} handleChange={this.handleChange} value={inputData[item]} placeholder={`${item}`} />
              })
            }
          </div>
        </Modal>
      )
    }
}

EditIdModal.propTypes = {
  /** visible file status of EditIdModal. */
  isVisible: PropTypes.bool,
  /** cancel action of EditIdModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

EditIdModal.defaultProps = {
}

export default EditIdModal
