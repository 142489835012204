import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import DropDownIcon from './../../general/icons/DropDownIcon'
import './../../ui.style.scss'

const Option = Select.Option

const HeaderDropDown = ({ displayParam, onOptionSelect, selectedValue, options, ...props }) => (
  <Select className='header-dropdown' value={selectedValue} suffixIcon={<DropDownIcon color='#fff' />} getPopupContainer={() => document.getElementById('header')}>
    {options.map((option, index) => <Option key={`option${index}`} value={option[ displayParam ]} onClick={() => onOptionSelect(option.route)}>
      <a href={option.route} onClick={(e) => e.preventDefault()}>{ option[ displayParam ] }</a>
    </Option>)}
  </Select>
)

HeaderDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** Selected value of Dropdown. */
  selectedValue: PropTypes.string,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func
}

HeaderDropDown.defaultProps = {
  selectedValue: 'Select'
}

export default HeaderDropDown
