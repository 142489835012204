import gql from 'graphql-tag'

export default gql(`
    mutation($type: String! $label: String! $isActive: Boolean $project: String! ) {
        createMediaCategory(
            input: {
                type: $type
                label: $label
                isActive: $isActive
            },
            project: $project
        ) {
            id
            label
            type
            usageCount
            isActive
        }
    }`
)
