import gql from 'graphql-tag'

export default gql(`
    mutation batchUpdateAccessRoleGroups($accessRoleGroups: [AccessRoleGroupInput] $project: String) {
        batchUpdateAccessRoleGroups(
            input: $accessRoleGroups
            project: $project
        ){
            id
            name
            description
            createdAt
            modules{
                name
                permissions
            }
            users{
                id
                accessRoleGroups {
                    id
                }
            }
        }
    }
`)
