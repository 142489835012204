import * as React from 'react'
import { Switch, message } from 'antd'
import { Auth } from 'aws-amplify'

import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import AddMfaDevices from '../../components/ui/feedback/AddMfaDevicesModal'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import { utilityService } from '../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryUserSettings from '../../graphQL/mfaSettings/getUserSettings'
import MutationAddUserEvents from '../../graphQL/audit/addUserEvents'

import './Auth.scss'

class DisableMfa extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isEnabled: false,
      isLoading: true,
      user: null,
      isMfaDeviceModalVisible: false,
      isAddDeviceModalLoading: false,
      isResetDeviceModalLoading: false,
      isCancelNotVisible: false,
      qrCode: '',
      secretKey: '',
      isConfirmPopupVisible: false
    }
  }

  componentDidMount () {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
      const isEnabled = user.preferredMFA && user.preferredMFA === 'SOFTWARE_TOKEN_MFA'
      this.setState({ isEnabled, user, isLoading: false })
    }, () => {
      message.warn('Something went wrong please reload the page again')
    })
  }
  componentWillUnmount () {
    window.onbeforeunload = null
  }

  handleUnload = (e) => {
    return 'Your device is removed, please add another device before you navigate to any other page'
  }

  onChangeMfaSettings = (isEnabled) => {
    this.setState({ isLoading: true })
    const { user } = this.state
    const project = utilityService.getCurrentProject()
    const browserAndOsDetails = utilityService.getBrowserDetails()
    const mfaMethod = isEnabled ? 'TOTP' : 'NOMFA'
    Auth.setPreferredMFA(user, mfaMethod).then(() => {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((newUser) => {
        this.setState({ isEnabled, isLoading: false, user: newUser })
        if (mfaMethod === 'TOTP') {
          const variables = { user: user.username, userEvent: 'User Enable the MFA', project, userAgent: browserAndOsDetails }
          this.props.addUserEvents(variables).then(({ data }) => {

          }, error => {
            utilityService.handleError(error)
          })
        } else {
          const variables = { user: user.username, userEvent: 'User Disable the MFA', project, userAgent: browserAndOsDetails }
          this.props.addUserEvents(variables).then(({ data }) => {

          }, error => {
            utilityService.handleError(error)
          })
        }
      }, () => {
        const variables = { user: user.username, userEvent: 'User Enable/Disable failed for the MFA', project, userAgent: browserAndOsDetails }
        this.props.addUserEvents(variables).then(({ data }) => {

        }, error => {
          utilityService.handleError(error)
        })
        message.warn('Something went wrong please reload the page again')
        this.setState({ isEnabled, isLoading: false })
      })
    }, (error) => {
      if (isEnabled && error && error.code === 'InvalidParameterException') {
        Auth.setupTOTP(user).then(code => {
          const domainName = utilityService.getEnvironment()
          const accountName = domainName ? `Optus CMS - ${domainName}` : 'Optus CMS'
          const qrCode = `otpauth://totp/${accountName}:${user.username}?secret=${code}&issuer=${accountName}`
          // const qrCode = 'otpauth://totp/AWSCognito:' + user.username + '?secret=' + code + '&issuer=AWSCognito'
          this.setState({ qrCode, isMfaDeviceModalVisible: true, isLoading: false, secretKey: code })
          message.warn('Please assign a device')
        })
      } else {
        const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
        message.warn(errorMessage)
        this.setState({ isLoading: false })
      }
    })
  }

  onResetDevice = () => {
    this.setState({ isResetDeviceModalLoading: true })
    const { user, isEnabled } = this.state
    const project = utilityService.getCurrentProject()
    const browserAndOsDetails = utilityService.getBrowserDetails()
    Auth.setPreferredMFA(user, 'NOMFA').then(() => {
      Auth.setupTOTP(user).then(code => {
        if (!window.onbeforeunload) {
          window.onbeforeunload = this.handleUnload
        }
        const domainName = utilityService.getEnvironment()
        const accountName = domainName ? `Optus CMS - ${domainName}` : 'Optus CMS'
        const qrCode = `otpauth://totp/${accountName}:${user.username}?secret=${code}&issuer=${accountName}`
        // const qrCode = 'otpauth://totp/AWSCognito:' + user.username + '?secret=' + code + '&issuer=AWSCognito'
        this.setState({ qrCode, isMfaDeviceModalVisible: true, isResetDeviceModalLoading: false, isCancelNotVisible: true, secretKey: code })
        message.warn('Please assign a device')
        if (isEnabled) {
          const variables = { user: user.username, userEvent: 'Reset MFA Device is successful', project, userAgent: browserAndOsDetails }
          this.props.addUserEvents(variables).then(({ data }) => {

          }, error => {
            utilityService.handleError(error)
          })
        }
      })
    })
  }

  onCancelAddDevice = () => {
    this.setState({ isMfaDeviceModalVisible: false, isCancelNotVisible: false })
  }

  addMfaDevice = (code) => {
    const { user } = this.state
    const project = utilityService.getCurrentProject()
    const browserAndOsDetails = utilityService.getBrowserDetails()
    try {
      this.setState({ isAddDeviceModalLoading: true })
      Auth.verifyTotpToken(user, code).then(() => {
        Auth.setPreferredMFA(user, 'TOTP').then(() => {
          window.onbeforeunload = null
          this.setState({ qrCode: '', isMfaDeviceModalVisible: false, isAddDeviceModalLoading: false, secretKey: '' })
          this.props.onBackClick()
        })
        const variables = { user: user.username, userEvent: 'Assign MFA Device is successful', project, userAgent: browserAndOsDetails }
        this.props.addUserEvents(variables).then(({ data }) => {

        }, error => {
          utilityService.handleError(error)
        })
      }, error => {
        const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
        message.error(errorMessage)
        this.setState({ isAddDeviceModalLoading: false })
      })
    } catch (error) {
      const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
      message.error(errorMessage)
    }
  }

  toggleWarning = (isConfirmPopupVisible) => {
    this.setState({ isConfirmPopupVisible })
  }

  render () {
    const { onBackClick, isMfaEnable } = this.props
    const { isEnabled, isLoading, isMfaDeviceModalVisible, isAddDeviceModalLoading, qrCode, secretKey, isResetDeviceModalLoading, isCancelNotVisible, isConfirmPopupVisible, user } = this.state
    const isNoMfa = user && user.preferredMFA === 'NOMFA'
    return <div className='login'>
      <div className='disable-mfa-container'>
        <div className='header'>
          <span>MULTI-FACTOR AUTHENTICATION</span>
          <div />
        </div>
        <span className='body'>Multi-Factor Authentication(MFA) requires users to type in a unique authentication code from an approved device when logging into VCMS</span>
        {isEnabled ? <div className='mfa-switch-container'>
          <span>Enable/Disable MFA</span>
          <Switch checked={isEnabled} loading={isLoading} onChange={this.onChangeMfaSettings} disabled={!isMfaEnable} />
        </div> : null}
        <div className='footer'>
          <LoadingButton type='primary' buttonText={'Back'} buttonClass='login-form-button login-form-submit' onClick={onBackClick} />
          {isNoMfa ? <LoadingButton type='primary' buttonText={'Assign MFA Device'} buttonClass='login-form-button login-form-submit' onClick={this.onResetDevice} isLoading={isResetDeviceModalLoading} />
            : <LoadingButton type='primary' buttonText={'Reset MFA Device'} buttonClass='login-form-button login-form-submit' onClick={() => this.toggleWarning(true)} isLoading={isResetDeviceModalLoading} />}
        </div>
      </div>
      <AddMfaDevices
        isVisible={isMfaDeviceModalVisible}
        handleCancel={this.onCancelAddDevice}
        qrCode={qrCode}
        secretKey={secretKey}
        isLoading={isAddDeviceModalLoading}
        isCancelNotVisible={isCancelNotVisible}
        handleSubmit={this.addMfaDevice} />
      <ConfirmModal
        isVisible={isConfirmPopupVisible}
        title={'Reset MFA Device'}
        message={'Do you want to reset current MFA device ? This action cannot be undone'}
        isLoading={isResetDeviceModalLoading}
        rightButtonText={'Confirm'}
        handleCancel={() => this.toggleWarning(false)}
        handleSubmit={this.onResetDevice} />
    </div>
  }
}

export default withApollo(compose(
  graphql(
    QueryUserSettings,
    {
      props: ({ data }) => {
        return {
          isMfaEnable: data && data.get2FAConfig ? data.get2FAConfig.isEnabled : false
        }
      }
    }
  ),
  graphql(
    MutationAddUserEvents,
    {
      props: (props) => ({
        addUserEvents: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(DisableMfa))
