import React from 'react'
import PropTypes from 'prop-types'

const GroupDragAndDropIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} fillRule='evenodd' d='M1 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm4-8a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z' />
    </g>
  </svg>
)

GroupDragAndDropIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string
}

GroupDragAndDropIcon.defaultProps = {
  width: 6,
  height: 10,
  color: '#AFAFAF'
}

export default GroupDragAndDropIcon
