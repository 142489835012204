import React, { Component } from 'react'
import { Table, Skeleton, Empty, Modal, Button } from 'antd'
import { Resizable } from 'react-resizable'
import moment from 'moment'

import ToolTip from './ToolTip'
import MutilineEllipsis from './../../MultilineEllipse'
import { utilityService } from './../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryBillingAccount from '../../../graphQL/customers/hyperionListBills'
// import QueryListSubscription from '../../../graphQL/customers/listSubscriptions'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class TransactionTableHyperion extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100
      },
      {
        title: 'Subscription ID',
        dataIndex: 'subscriptionId',
        key: 'subscriptionId',
        width: 100
      },
      {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
        width: 100,
        render: this.renderPlan
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        width: 100,
        render: (startDate) => startDate ? moment(new Date(startDate)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        width: 100,
        render: (endDate) => endDate ? moment(new Date(endDate)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Transaction Date',
        dataIndex: 'transactionDate',
        key: 'transactionDate',
        width: 100,
        render: (transactionDate) => transactionDate ? moment(new Date(transactionDate)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Billing Account ID',
        dataIndex: 'billingAccountId',
        width: 100,
        key: 'billingAccountId'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 100
      },
      {
        title: 'Coverage Period',
        dataIndex: 'coveragePeriod',
        width: 100,
        key: 'coveragePeriod'
      },
      {
        title: 'System Logs',
        dataIndex: 'systemLog',
        key: 'systemLog',
        width: 100,
        render: this.renderSystemLog
      }
    ]

    this.state = {
      shouldShowPopup: false,
      width: null,
      height: null,
      plan: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
    if (newProps.profileOptionShown === true) {
      this.setState({ shouldShowPopup: false })
    }
  }

  renderSystemLog = (systemLog) => {
    const details = [`Requested Payload: ${systemLog && systemLog.requestPayload ? systemLog.requestPayload : '-'}`,
      `Error: ${systemLog && systemLog.error ? systemLog.error : '-'}`]
    return <ToolTip
      heading='System Logs'
      className='system-logs'
      childComponent={<div className='general-text'>Logs</div>}
      details={details}
      placement='leftTop'
      isList />
  }

  renderPlan = (plan) => {
    return <div className='general-text' onClick={() => this.toggleShowOptions(plan)}>{plan && plan.id && plan.provider ? `${plan.provider.name}-${plan.id}` : `Plan ID`}</div>
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  toggleShowOptions = (plan) => {
    this.setState({ shouldShowPopup: !this.state.shouldShowPopup, plan })
  }

  renderPlanModal = (plan, shouldShowPopup) => {
    const { id, description, name, price, interval, providerId } = plan
    return <Modal
      className='general-modal plan-details'
      title={'PLAN'}
      maskClosable={false}
      visible={shouldShowPopup}
      closable={false}
      centered
      destroyOnClose
      width={'358px'}
      height={'349px'}
      footer={[
        <Button key='back' onClick={this.toggleShowOptions}>
        Close
        </Button>]}
    >
      {shouldShowPopup
        ? <div className='plan-details-container'>
          <div className='plan-details-inner-container'>
            <span>ID</span>
            <MutilineEllipsis maxLines={'1'} text={id || ''} />
          </div>
          <div className='plan-details-inner-container'>
            <span>Provider ID</span>
            <MutilineEllipsis maxLines={'1'} text={providerId || ''} />
          </div>
          <div className='plan-details-inner-container'>
            <span>Name</span>
            <MutilineEllipsis maxLines={'1'} text={name || ''} />
          </div>
          <div className='plan-details-inner-container'>
            <span>Description</span>
            <div className='plan-description'><MutilineEllipsis maxLines={'3'} text={description || ''} /></div>
          </div>
          <div className='plan-details-inner-container'>
            <span>Price</span>
            <MutilineEllipsis maxLines={'1'} text={price || ''} />
          </div>
          <div className='plan-details-inner-container'>
            <span>Interval</span>
            <MutilineEllipsis maxLines={'1'} text={interval || ''} />
          </div>
        </div> : null
      }
    </Modal>
  }

  render () {
    const { hyperionListBills, isLoading } = this.props
    // const { hyperionListBills, isLoading, selectedCustomer, fetchHistory } = this.props
    const { width, height, columns, shouldShowPopup, plan } = this.state

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))
    return (
      <div className='general-customer-details-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(hyperionListBills) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={hyperionListBills} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
        {!_.isEmpty(plan) ? this.renderPlanModal(plan, shouldShowPopup) : ''}
      </div>
    )
  }
}

TransactionTableHyperion.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryBillingAccount,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const hyperionListBills = data && data.hyperionListBills ? data.hyperionListBills : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { hyperionListBills, isLoading: data && data.loading }
      }
    }
  )
)(TransactionTableHyperion))
