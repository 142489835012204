import gql from 'graphql-tag'

export default gql(`
query searchAssetsQuery($filter: TableAssetFilterInput, $offset: Int, $limit: Int, $orderBy: String, $order: String, $search: SearchInput $project: String) {
  listAssets(
      limit: $limit,
      filter: $filter,
      order: $order,
      offset: $offset,
      orderBy: $orderBy,
      search: $search
      project: $project
    ) {
      items {
        id
        isArchived
        shortId
        externalId
        title
        type
        updatedAt
        publishStartDate
        publishEndDate
        status
        isFree
        seasonNumber
        episodeNumber
        series{
          id
          assetId
          title
        }
        season{
            id
            assetId
            title
        }
        defaultMedia {
          id
          fileName
          aspectRatio {
            title
            aspectRatio
            resolutions {
              url
            }
          }
          settings {
            aspectRatio
            outputFormat
            fileName
            x1
            y1
            x2 
            y2
          }
        }
      }
      totalCount
    }
}`)
