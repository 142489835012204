import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'
import _ from 'lodash'
import Input from '../../components/ui/dataEntry/inputs/Input'
import TextAreaInput from '../../components/ui/dataEntry/inputs/TextArea'
import InputNumber from './../../components/ui/dataEntry/inputs/InputNumber'
import userMessages from '../../constants/messages'
import PartnerAppDropDown from '../../components/ui/dataEntry/inputs/PartnerAppDropDown'

const parentCompoent = 'partner-container'

class PartnerGeneralDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      appsList: []
    }
    if (props.metaFieldList && props.metaFieldList.length > 0 && props.details && !(props.details.customMeta && props.details.customMeta.length)) {
      props.handleMetaDetailsChange(props.metaFieldList, null)
    } else if (props.metaFieldList && props.metaFieldList.length > 0 && props.details && props.details.customMeta && props.details.customMeta.length) {
      this.checkForNewMeta(props.metaFieldList, props.details.customMeta)
    }
  }

  componentDidMount=() => {
    if (this.props.listApps && this.props.listApps[0] && this.props.listApps[0].value) {
      this.formatAppsList(this.props.listApps)
    }
  }

  UNSAFE_componentWillReceiveProps=(newProps) => { // eslint-disable-line camelcase
    if (newProps.metaFieldList && newProps.metaFieldList.length > 0 && newProps.details && !(newProps.details.customMeta && newProps.details.customMeta.length)) {
      this.props.handleMetaDetailsChange(newProps.metaFieldList, null)
    } else if (newProps.metaFieldList && newProps.metaFieldList.length > 0 && newProps.details && newProps.details.customMeta && newProps.details.customMeta.length) {
      this.checkForNewMeta(newProps.metaFieldList, newProps.details.customMeta)
    }
  }

  checkForNewMeta=(metaFieldList, customMeta) => {
    let changeExists = false;
    (metaFieldList || []).map(item => {
      const foundMeta = (customMeta || []).find(existingItem => {
        if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
          return existingItem
        }
      })

      if (_.isEmpty(foundMeta)) {
        changeExists = true
      }
    })
    if (changeExists) { this.props.handleMetaDetailsChange(metaFieldList, null) }
  }

  formatAppsList=(appsList) => {
    const formattedAppsList = (appsList[0].value || []).map((item, index) => {
      let newApp = {}
      newApp.id = index
      newApp.name = item
      return newApp
    })

    this.setState({ appsList: formattedAppsList })
  }

  getPartnerMetaFields = (metaData, selectedMeta) => {
    const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
    const { handleMetaDetailsChange } = this.props
    return (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if (customMeta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{customMeta.name}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => handleMetaDetailsChange(customMeta, value)} />
          </div>
        </div>
      } else if (customMeta.type === 'STRING') {
        return <Input
          // limit={50}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
          handleChange={({ target }) => handleMetaDetailsChange(customMeta, target.value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      } else if (customMeta.type === 'NUMBER') {
        return <InputNumber
          limit={16}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
          handleChange={(value) => handleMetaDetailsChange(customMeta, value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      }
    })
  }

  render () {
    const { details, handleGeneralChange, handleActiveChanges, onInputFieldBlur, onInputFieldFocus, handleAppsChange, isInvalidTitle, metaFieldList } = this.props
    const { appsList } = this.state
    const generalDetails = _.cloneDeep(details)

    return (
      <div className='general-details'>
        <Input
          title='Partner Name'
          placeholder='Insert Partner name...'
          inputName='name'
          isRequired
          value={details.name}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_TITLE}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <Input
          title='ID'
          placeholder='Insert ID'
          inputName='externalId'
          value={details.externalId}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_EXTERNALID}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <Input
          title='Tagline'
          placeholder='Insert Tagline'
          inputName='tagLine'
          value={details.tagLine}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.TAGLINE}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <Input
          title='Websites'
          placeholder='Insert Website'
          inputName='website'
          value={details.website}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_WEBSITE}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <TextAreaInput
          title='Address'
          placeholder='Insert Address'
          inputName='address'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.address}
          handleChange={handleGeneralChange}
        />

        <TextAreaInput
          title='Short Description'
          placeholder='Insert Short Description'
          inputName='shortDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.shortDescription}
          handleChange={handleGeneralChange}
        />

        <TextAreaInput
          title='Medium Description'
          placeholder='Insert Medium Description'
          inputName='mediumDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.mediumDescription}
          handleChange={handleGeneralChange}
        />

        <TextAreaInput
          title='Description'
          placeholder='Insert Description'
          inputName='description'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={details.description}
          handleChange={handleGeneralChange}
        />

        <Input
          title='Category'
          placeholder='Insert Category'
          inputName='category'
          value={details.category}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_CATEGORY}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />
        <div className='input-field'>
          <label className='input-title'>Apps</label>
          <PartnerAppDropDown
            options={appsList || []}
            displayParam='name'
            placeHolder='Select Apps'
            parent={parentCompoent}
            value={details.apps || []}
            onOptionSelect={handleAppsChange}
            allowClear
          />
        </div>
        <Input
          title='Charge Code'
          placeholder='Insert Charge Code'
          inputName='chargeCode'
          value={details.chargeCode}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_CHARGECODE}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <Input
          title='Refund Charge Code'
          placeholder='Insert Refund Charge Code'
          inputName='refundChargeCode'
          value={details.refundChargeCode}
          handleChange={handleGeneralChange}
          onFocus={onInputFieldFocus}
          errorMessage={userMessages.EMPTY_DEBITCODE}
          onBlur={onInputFieldBlur}
          isError={isInvalidTitle}
        />

        <div className='input-field switch-container'>
          <div className='switch-inner-container'>
            <span>Active</span>
            <Switch checked={details.isActive} onChange={handleActiveChanges} />
          </div>
        </div>

        <div className='partner-meta-field-container'>
          {this.getPartnerMetaFields(generalDetails.customMeta, metaFieldList)}
        </div>

      </div>
    )
  }
}

PartnerGeneralDetails.propTypes = {
  /** function to handle changes in PartnerGeneralDetails */
  handleGeneralChange: PropTypes.func,
  /** partner details of selected partner. */
  details: PropTypes.object,
  /** function to handle active button changes in PartnerGeneralDetails */
  handleActiveChanges: PropTypes.func,
  /** function to handle blur changes in PartnerGeneralDetails */
  onInputFieldBlur: PropTypes.func,
  /** function to handle focus changes in PartnerGeneralDetails */
  onInputFieldFocus: PropTypes.func,
  /** function to handle apps changes in PartnerGeneralDetails */
  handleAppsChange: PropTypes.func,
  /** Boolean to validate input error */
  isInvalidTitle: PropTypes.bool
}

export default PartnerGeneralDetails
