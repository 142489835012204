import React from 'react'
import PropTypes from 'prop-types'
import CopyLinkIcon from './../icons/CopyLinkIcon'
import './../../ui.style.scss'

const CopyLinkButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button copy-link ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <CopyLinkIcon color='#fff' />
  </div>
)

CopyLinkButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

CopyLinkButton.defaultProps = {
  isDisabled: false
}

export default CopyLinkButton
