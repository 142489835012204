import gql from 'graphql-tag'

export default gql(`
mutation createMetaFieldConfig($name: String!, $type: InputTypes!, $default: String, $section: String!, $project: String) {
    createMetaFieldConfig(
        input: {
            name: $name
            type: $type
            default: $default
            section: $section
            project: $project
        }
    ) {
        id
        name
        type
        default
        section
        usageCount
    }
}`
)
