import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Skeleton, message } from 'antd'
import AppContext from '../../AppContext'

import PublishDetailCell from './../../components/ui/dataEntry/inputs/PublishDetailCell'
import Accordion from './../../components/ui/dataDisplay/Accordion'
import AssetShare from './../../components/ui/dataDisplay/AssetShare'
import GeneralDisplayDetails from './../../components/ui/dataDisplay/GeneralDisplayDetails'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetPublishDetails from '../../graphQL/asset/listpublishgroup'
import MutationCreatePublishRule from '../../graphQL/asset/createPublishRules'
import MutationBatchUpdatePublishRule from '../../graphQL/asset/batchUpdatePublishRules'
import MutationBatchCreatePublishRule from '../../graphQL/asset/batchCreatePublishRules'
import MutationUpdatePublishRule from '../../graphQL/asset/updatePublishRules'
import QueryGetAssetDetails from '../../graphQL/asset/getAssetPublishDetails'
import QueryListPublishRules from '../../graphQL/asset/listPublishRules'
import QueryAppleNewsPreview from '../../graphQL/asset/getAppleNewsArticlePreview'

import { utilityService } from '../../services/UtilityService'
import { redirectToLink } from '../../util/util'
import userMessages from '../../constants/messages'

const getStatus = (publishRules, assetDetails) => {
  const assetPublished = _.uniqBy(assetDetails.publishRules || [], 'id')
  const enabledRules = (assetPublished).filter(item => {
    const curDate = moment()
    const publishEndDate = item.publishEndDate ? moment(item.publishEndDate) : moment()
    return item.isEnabled && curDate.isSameOrBefore(publishEndDate, 'minutes')
  })
  let status
  if (enabledRules.length) {
    const allIndex = enabledRules.findIndex(item => (item.group.id === 'ALL' || item.group.id === 'HYPERION-ALL' || item.group.id === 'PROJECTX-ALL'))
    if (allIndex > -1) {
      if (enabledRules[allIndex].isEnabled && assetPublished.length <= enabledRules.length) { status = 'PUBLISHED' } else {
        enabledRules.splice(allIndex, 1)
        if (enabledRules.length === ((publishRules && publishRules.items) || []).length - 1) { status = 'PUBLISHED' } else if (enabledRules.length || assetPublished.length !== ((publishRules && publishRules.items) || []).length) { status = 'PARTIAL' } else { status = 'DRAFT' }
      }
    } else {
      if (enabledRules.length === ((publishRules && publishRules.items) || []).length - 1) { status = 'PUBLISHED' } else if (enabledRules.length) { status = 'PARTIAL' } else { status = 'DRAFT' }
    }
  } else {
    status = 'DRAFT'
  }
  return status
}

const assetTypes = {
  ARTICLE: 'articles'
}

class AssetPublishDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      publishGroup: props.publishRuleDetails || [],
      isPublishAll: false,
      publishRule: props.details && props.details.publishRules && props.details.publishRules.length ? props.details.publishRules : [],
      isAllVisible: false,
      isPublishAllLoading: false,
      isUnpublishAllLoading: false,
      errorId: ''
    }
    this.qaBaseUrl = window.config && window.config.client_url_qa ? window.config.client_url_qa : 'https://web-dev.sport-ott.com/'
    this.prodBaseUrl = window.config && window.config.client_url_all ? window.config.client_url_all : 'https://sport.optus.com.au/'
    this.updatedEndDateList = []
    this.updatedStartDateList = []
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    let publishRules = []
    if (nextProps.details.id !== this.props.details.id) {
      publishRules = nextProps.details.publishRules
      this.updatedEndDateList = []
      this.updatedStartDateList = []
      this.setState({ publishRule: publishRules })
    } else if ((nextProps.details && nextProps.details.publishRules && nextProps.details.publishRules.length > (this.state.publishRule).filter(rule => rule.id).length)) {
      this.setState({ publishRule: nextProps.details.publishRules })
    } else if (nextProps.details && nextProps.details.publishRules && nextProps.details.publishRules.length) {
      publishRules = (this.state.publishRule || []).map((item) => {
        const index = nextProps.details.publishRules.findIndex(rule => rule && rule.group && item.group && (rule.group.id === item.group.id))
        return index > -1 ? nextProps.details.publishRules[index] : item
        // const newItem = nextProps.details.publishRule.filter((propItem) => {
        //   return item.id === propItem.id
        // })
        // return newItem.length ? newItem[0] : item
      })
      this.setState({ publishRule: publishRules })
    }
    this.setState({ publishGroup: nextProps.publishRuleDetails })
  }

  onPublishButtonActiveChange = (id, value) => {
    if (this.apiCallingId === id) {
      return
    }
    this.apiCallingId = id
    if (id) {
      const { publishRules } = this.state
      let selectedItem = _.find(publishRules, (item) => item.group.id === id)
      if (_.isEmpty(selectedItem)) {
        const statePublishRule = _.find(this.state.publishRule, (item) => item.group.id === id)
        // const defaultItem = this.state.publishGroup.find((item) => item.isDefault)
        // const active = (publishRules || []).find((item) => item.group.id === defaultItem.id)
        // !_.isEmpty(active) && active.isEnabled !== undefined ? active.isEnabled : true,
        const variables = {
          assetId: this.props.assetId,
          groupId: id,
          isEnabled: !value,
          startDate: statePublishRule && statePublishRule.publishStartDate ? statePublishRule.publishStartDate : moment().utc(),
          endDate: statePublishRule && statePublishRule.publishEndDate ? statePublishRule.publishEndDate : null
        }
        const item = {
          group: {
            id: id
          },
          isEnabled: variables.isEnabled,
          publishStartDate: variables.isEnabled ? moment().utc() : variables.startDate,
          publishEndDate: variables.endDate
        }
        let newPublishRule = _.cloneDeep(this.state.publishRule)
        const oldPublishRule = _.cloneDeep(this.state.publishRule)
        newPublishRule.push(item)
        this.setState({ publishRule: newPublishRule }, () => {
          this.props.getPublishRules(this.props.assetId).then((response) => {
            const presentItem = response.data.listPublishRules.find((listItem) => listItem.group.id === variables.groupId)
            if (_.isEmpty(presentItem)) {
              this.props.onPublishButtonActiveChange()
              this.props.createPublishRule(variables).then(() => {
                this.apiCallingId = null
                this.props.onPublishButtonClick()
              }, error => {
                this.setState({ publishRule: oldPublishRule, errorId: id }, () => {
                  setTimeout(() => {
                    this.setState({ errorId: '' })
                  }, 200)
                })
                this.apiCallingId = null
                utilityService.handleError(error)
              })
            } else {
              variables.id = presentItem.id
              delete variables.assetId
              delete variables.groupId
              if (!variables.endDate && value) {
                variables.endDate = moment().utc()
              }
              if (variables.endDate && !value) {
                let now = moment()
                let endDate = moment(variables.endDate)
                if (now.isAfter(endDate)) {
                  variables.endDate = null
                }
              }
              this.props.onPublishButtonActiveChange()
              this.props.updatePublishRule(variables).then(() => {
                this.apiCallingId = null
                this.props.onPublishButtonClick()
              }, error => {
                this.setState({ publishRule: oldPublishRule, errorId: id }, () => {
                  setTimeout(() => {
                    this.setState({ errorId: '' })
                  }, 200)
                })
                this.apiCallingId = null
                utilityService.handleError(error)
              })
            }
          })
        })
      } else {
        const variables = {
          id: selectedItem.id,
          isEnabled: !selectedItem.isEnabled,
          startDate: !selectedItem.isEnabled ? moment().utc() : selectedItem.publishStartDate ? selectedItem.publishStartDate : moment().utc(),
          endDate: selectedItem.publishEndDate ? selectedItem.publishEndDate : null
        }
        this.props.onPublishButtonActiveChange()
        this.props.updatePublishRule(variables).then(() => {
          this.isApiCalling = false
          this.apiCallingId = null
          this.props.onPublishButtonClick()
        }, error => {
          this.setState({ errorId: id }, () => {
            setTimeout(() => {
              this.setState({ errorId: '' })
            }, 200)
          })
          this.isApiCalling = false
          utilityService.handleError(error)
        })
      }
    } else {
      this.isApiCalling = false
    }
  }

  onPublishRuleStartDateChange = (value, id) => {
    if (id) {
      const { publishRules } = this.props.details
      let selectedItem = _.find(publishRules, (item) => item.group.id === id)
      const defaultItem = this.state.publishGroup.find((item) => item.isDefault)
      const allItem = _.find(this.state.publishRule, (item) => item.group.id === defaultItem.id)
      let newPublishRule = _.cloneDeep(this.state.publishRule)
      const statePublishRule = _.find(this.state.publishRule, (item) => item.group.id === id)
      const statePublishIndex = _.findIndex(this.state.publishRule, (item) => item.group.id === id)
      const item = {
        id: selectedItem ? selectedItem.id : null,
        group: {
          id: id
        },
        isCreate: !selectedItem,
        isEnabled: statePublishRule && statePublishRule.isEnabled !== undefined ? statePublishRule.isEnabled : (allItem ? allItem.isEnabled : false),
        publishStartDate: value ? moment(value).utc() : null,
        publishEndDate: statePublishRule && statePublishRule.publishEndDate ? statePublishRule.publishEndDate : (!_.isEmpty(allItem) && allItem.isEnabled ? allItem.publishEndDate : null)
      }
      _.isEmpty(statePublishRule) ? newPublishRule.push(item) : newPublishRule[statePublishIndex] = item
      this.setState({ publishRule: newPublishRule }, () => {
        if (!value) {
          this.openStatusChange(false, id, true)
        }
      })
      const index = this.updatedStartDateList.indexOf(id)
      if (index === -1) {
        this.updatedStartDateList.push(id)
      }
    }
  }

  onPublishRuleEndDateChange = (value, id) => {
    if (id) {
      const { publishRules } = this.props.details
      let selectedItem = _.find(publishRules, (item) => item.group.id === id)
      let newPublishRule = _.cloneDeep(this.state.publishRule)
      const defaultItem = this.state.publishGroup.find((item) => item.isDefault)
      const allItem = _.find(this.state.publishRule, (item) => item.group.id === defaultItem.id)
      const statePublishRule = _.find(this.state.publishRule, (item) => item.group.id === id)
      const statePublishIndex = _.findIndex(this.state.publishRule, (item) => item.group.id === id)
      const item = {
        group: {
          id: id
        },
        id: selectedItem ? selectedItem.id : null,
        isCreate: !selectedItem,
        isEnabled: statePublishRule && statePublishRule.isEnabled !== undefined ? statePublishRule.isEnabled : (allItem ? allItem.isEnabled : false),
        publishStartDate: statePublishRule && statePublishRule.publishStartDate ? statePublishRule.publishStartDate : (!_.isEmpty(allItem) && allItem.isEnabled ? allItem.publishStartDate : null),
        publishEndDate: value ? moment(value).utc() : null
      }
      _.isEmpty(statePublishRule) ? newPublishRule.push(item) : newPublishRule[statePublishIndex] = item
      this.setState({ publishRule: newPublishRule }, () => {
        if (!value) {
          this.openStatusChange(false, id, false)
        }
      })
      const index = this.updatedEndDateList.indexOf(id)
      if (index === -1) {
        this.updatedEndDateList.push(id)
      }
    }
  }

  openStatusChange = (value, id, isStart) => {
    let { publishRule } = this.state
    let selectedItem = _.find(publishRule, (item) => item.group.id === id)
    const isInvalid = isStart ? this.updatedStartDateList.indexOf(id) === -1 : this.updatedEndDateList.indexOf(id) === -1
    if (value || isInvalid || _.isEmpty(selectedItem)) {
      return
    }
    if (selectedItem && selectedItem.publishStartDate && selectedItem.publishEndDate && moment(selectedItem.publishStartDate).isSameOrAfter(moment(selectedItem.publishEndDate), 'minutes')) {
      message.warn('Please select publish start date less than publish end date')
      return
    }

    if (!selectedItem.isCreate) {
      const variables = {
        id: selectedItem.id,
        isEnabled: selectedItem.isEnabled,
        startDate: selectedItem.publishStartDate ? selectedItem.publishStartDate : null,
        endDate: selectedItem.publishEndDate ? selectedItem.publishEndDate : null
      }
      this.props.onPublishButtonActiveChange()
      this.props.updatePublishRule(variables).then(() => {
        this.updatedList = []
      }, error => {
        this.setState({ errorId: id }, () => {
          setTimeout(() => {
            this.setState({ errorId: '' })
          }, 200)
        })
        utilityService.handleError(error)
      })
    } else {
      const variables = {
        assetId: this.props.assetId,
        groupId: id,
        isEnabled: selectedItem.isEnabled,
        startDate: selectedItem.publishStartDate,
        endDate: selectedItem.publishEndDate
      }
      this.props.getPublishRules(this.props.assetId).then((response) => {
        const presentItem = response.data.listPublishRules.find((listItem) => listItem.group.id === variables.groupId)
        if (_.isEmpty(presentItem)) {
          this.props.createPublishRule(variables).then(() => {
            let selectedItemIndex = publishRule.findIndex((item) => item.group.id === id)
            publishRule[selectedItemIndex].isCreate = false
            this.setState({ publishRule })
            if (isStart) {
              const index = this.updatedStartDateList.indexOf(id)
              if (index > -1) {
                this.updatedStartDateList.splice(index, 1)
              }
            } else {
              const index = this.updatedEndDateList.indexOf(id)
              if (index > -1) {
                this.updatedEndDateList.splice(index, 1)
              }
            }
          }, error => {
            this.setState({ errorId: id }, () => {
              setTimeout(() => {
                this.setState({ errorId: '' })
              }, 200)
            })
            utilityService.handleError(error)
          })
        } else {
          delete variables.assetId
          delete variables.groupId
          variables.id = presentItem.id
          this.props.updatePublishRule(variables).then(() => {
            let selectedItemIndex = publishRule.findIndex((item) => item.group.id === id)
            publishRule[selectedItemIndex].isCreate = false
            this.setState({ publishRule })
            if (isStart) {
              const index = this.updatedStartDateList.indexOf(id)
              if (index > -1) {
                this.updatedStartDateList.splice(index, 1)
              }
            } else {
              const index = this.updatedEndDateList.indexOf(id)
              if (index > -1) {
                this.updatedEndDateList.splice(index, 1)
              }
            }
          }, error => {
            this.setState({ errorId: id }, () => {
              setTimeout(() => {
                this.setState({ errorId: '' })
              }, 200)
            })
            utilityService.handleError(error)
          })
        }
      })
    }
  }

  makeAccordionVisible = () => {
    this.setState({
      isAllVisible: !this.state.isAllVisible
    })
  }

  onPublishAll = (defaultItemId, allPublishRule) => {
    // const tempStartDate = allPublishRule.isPublish ? moment() : allPublishRule.publishStartDate
    // if (moment(tempStartDate).isAfter(moment(allPublishRule.publishEndDate), 'minutes')) {
    //   message.error('Please select valid publish dates')
    //   return
    // }
    const isPublishAllLoading = !!allPublishRule.isPublish
    const isUnpublishAllLoading = !isPublishAllLoading
    this.setState({ isPublishAllLoading, isUnpublishAllLoading })
    const { assetId } = this.props
    this.props.getPublishRules(assetId).then(async (response) => {
      const presentItem = response.data.listPublishRules
      const updatingRules = (presentItem || []).map((item) => {
        let publishEndDate = item.publishEndDate
        if (!allPublishRule.isPublish && !publishEndDate) {
          publishEndDate = moment().utc()
        } else {
          if (publishEndDate && allPublishRule.isPublish) {
            let now = moment()
            let endDate = moment(publishEndDate)
            if (now.isAfter(endDate)) {
              publishEndDate = null
            }
          }
        }
        return {
          id: item.id,
          assetId: assetId,
          isEnabled: allPublishRule.isPublish,
          group: item.group.id,
          publishStartDate: item.publishStartDate || new Date().toISOString(),
          publishEndDate: publishEndDate
        }
      })
      if (updatingRules.length) {
        // const disabledPublishDates = updatingRules.filter(rule => rule.publishEndDate && moment(rule.publishStartDate).isAfter(moment(rule.publishEndDate), 'seconds'))
        // if (disabledPublishDates.length) {
        //   this.setState({ isPublishAllLoading: false, isUnpublishAllLoading: false })
        //   message.error('Please select valid publish dates')
        //   return
        // }
        await this.props.batchUpdatePublishRule(updatingRules).then(() => {}, error => {
          utilityService.handleError(error)
        })
      }
      const defaultItemPresent = (presentItem || []).find((item) => item.group.id === defaultItemId)

      if (_.isEmpty(defaultItemPresent)) {
        const creatingRules = {
          assetId: assetId,
          group: defaultItemId,
          isEnabled: allPublishRule.isPublish,
          publishStartDate: allPublishRule && allPublishRule.publishStartDate ? allPublishRule.publishStartDate : moment().utc(),
          publishEndDate: allPublishRule && allPublishRule.publishEndDate ? allPublishRule.publishEndDate : null
        }
        await this.props.batchCreatePublishRule(creatingRules).then(() => {}, error => {
          utilityService.handleError(error)
        })
      }
      this.props.onPublishButtonActiveChange()
      this.setState({ isPublishAllLoading: false, isUnpublishAllLoading: false })
    })
  }

  onPublishAllDateChange = (value, id, isStart) => {
    const { publishGroup, publishRule } = this.state
    const defaultItemDetails = publishGroup.find((item) => item.isDefault)
    const currentItem = (publishRule || []).find((linkedGroup) => linkedGroup.group.id === defaultItemDetails.id)
    const index = (publishRule || []).findIndex((linkedGroup) => linkedGroup.group.id === defaultItemDetails.id)
    if (isStart) { currentItem.publishStartDate = new Date(value).toISOString() } else { currentItem.publishEndDate = value ? new Date(value).toISOString() : null }
    publishRule[index] = currentItem
    this.setState({ publishRule })
  }

  triggerAppleNewsPreviewURLFuntion = () => {
    const { appleClient } = this.props
    if (!this.props.details || !this.props.details.type || this.props.details.type !== 'ARTICLE') {
      return null
    }
    return async () => {
      const { data } = await appleClient.query({
        query: QueryAppleNewsPreview,
        fetchPolicy: 'network-only',
        variables: { id: this.props.assetId },
        client: this.props.appleClient
      })
      const appleNewsPreviewURL = data && data.getArticlePreview && data.getArticlePreview.previewUrl
      if (appleNewsPreviewURL) {
        message.success(userMessages.APPLE_NEWS_URL_SUCCESS)
        redirectToLink(appleNewsPreviewURL)
      } else {
        message.error(userMessages.APPLE_NEWS_URL_FAILED)
      }
    }
  }

  renderAllPublishGroup = (publishRule, subUrl, defaultItemDetails, isPublishDisabled, isArticleInVCMS) => {
    const { project, canTriggerAppleNewsURL } = this.props
    const { isAllVisible, isPublishAllLoading, isUnpublishAllLoading, publishGroup, errorId } = this.state
    const currentItem = (publishRule || []).find((linkedGroup) => linkedGroup && linkedGroup.group && (linkedGroup.group.id === defaultItemDetails.id))
    const isNotExpire = currentItem && (!currentItem.publishEndDate || moment().isBefore(moment(currentItem.publishEndDate)))
    let publishItems = currentItem && currentItem.isEnabled && isNotExpire ? publishGroup.map((item) => item.id).filter((item) => {
      const isPresent = publishRule.find((innerItem) => (innerItem.group.id === item && !(innerItem.isEnabled && (!innerItem.publishEndDate || moment(innerItem.publishEndDate).isAfter(moment())))))
      return _.isEmpty(isPresent)
    }) : (publishRule || []).filter((item) => item.isEnabled && (!item.publishEndDate || moment(item.publishEndDate).isAfter(moment()))).map((item) => item.group.id)
    publishItems = publishItems.filter((item) => {
      const tempItem = publishGroup.find((innerItem) => innerItem.id === item && innerItem.isEnvironment)
      return _.isEmpty(tempItem)
    })
    return <div className='all-publish-container'>
      <div className='title'>
        <span>PUBLISH RULES</span>
      </div>
      <PublishDetailCell
        publishRules={this.props.details.publishRules}
        details={this.props.details}
        startDate={currentItem ? currentItem.publishStartDate : undefined}
        endDate={currentItem ? currentItem.publishEndDate : undefined}
        type={defaultItemDetails && defaultItemDetails.name}
        assetType={this.props.details.type}
        shareLink={`${this.prodBaseUrl}${subUrl}?allowPreview=true`}
        isLinkEnabled
        isActive={currentItem ? currentItem.isEnabled : false}
        onChange={(value) => this.onPublishButtonActiveChange(defaultItemDetails.id, value)}
        onStartDateChange={(value) => this.onPublishRuleStartDateChange(value, defaultItemDetails.id)}
        onEndDateChange={(value) => this.onPublishRuleEndDateChange(value, defaultItemDetails.id, false)}
        makeAccordionVisible={this.makeAccordionVisible}
        isAccordionActive={isArticleInVCMS || isAllVisible}
        onPublishAll={(rules) => this.onPublishAll(defaultItemDetails.id, rules)}
        isPublishAllLoading={isPublishAllLoading}
        isUnpublishAllLoading={isUnpublishAllLoading}
        isDefault
        publishItems={_.uniq(publishItems)}
        totalCount={(publishGroup.filter((item) => !item.isEnvironment)).length}
        isPublishDisabled={isPublishDisabled}
        isError={errorId === (currentItem ? currentItem.id : undefined)}
        onOpenChange={(value, isCreate) => setTimeout(() => {
          this.openStatusChange(value, defaultItemDetails.id, isCreate)
        }, 200)}
        project={project}
        canTriggerAppleNewsURL={canTriggerAppleNewsURL}
        triggerAppleNewsPreviewURL={this.triggerAppleNewsPreviewURLFuntion()}
        hidePublishAll={isArticleInVCMS}
      />
    </div>
  }

  render () {
    const { details, info, listAssetTypes } = this.props
    const { publishGroup, isAllVisible, errorId } = this.state
    const { publishRules } = details
    const subUrl = details && details.match && details.match.competition && details.match.competition.competitionCode
      ? `${details.match.competition.competitionCode.toLocaleLowerCase()}/${assetTypes[details.type] || 'videos'}/${details.id}/${details.slug}`
      : `${assetTypes[details.type] || 'videos'}/${details.id}/${details.slug}`
    const isvcms = utilityService.getCurrentProject() === 'vcms'
    const isArticleInVCMS = (isvcms && details.type === 'ARTICLE')
    let isShowEnvironmentPublish = (!isArticleInVCMS || !(isArticleInVCMS && utilityService.isProdEnv()))
    const environmentPublish = isShowEnvironmentPublish && publishGroup.filter((item) => item.isEnvironment && !item.isDefault).sort((a, b) => a.priority - b.priority)
    const publishRulesList = publishGroup.filter((item) => !item.isEnvironment).sort((a, b) => a.priority - b.priority)
    const defaultItemDetails = publishGroup.find((item) => item.isDefault)
    const defaultItem = defaultItemDetails ? (publishRules || []).find((item) => item && item.group && item.group.id === defaultItemDetails.id) : undefined
    const assetConfig = details ? listAssetTypes.find(item => item.title === details.type) : ''
    const isMatch = info && assetConfig && assetConfig.matchSection

    const storedStatusObj = JSON.parse(sessionStorage.getItem('publishDetails'))
    const publishStatus = storedStatusObj && storedStatusObj.id === details.id ? storedStatusObj.status : details.status
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['ASSET_MANAGER']
          const isPublishDisabled = userPermissions.indexOf('PUBLISH') === -1
          return <React.Fragment>
            <div className='info'>
              <div className='title'>
                <span>GENERAL INFO</span>
              </div>
              { info ? <GeneralDisplayDetails
                details={info}
                isCompetition={isMatch}
              /> : <Skeleton /> }
            </div>
            <div>
              {defaultItemDetails ? this.renderAllPublishGroup(this.state.publishRule, subUrl, defaultItemDetails, isPublishDisabled, isArticleInVCMS) : ''}
            </div>
            <div className='publish-rules-container' style={{ display: (isArticleInVCMS || isAllVisible) ? 'block' : 'none' }}>
              { publishRulesList.map((item, index) => {
                if (item && item.id === 'APPLE-NEWS' && details.type !== 'ARTICLE') {
                  return null
                }
                if (isArticleInVCMS &&
                 !(item.id === 'WEB' || item.id === 'MOBILE' || item.id === 'APPLE-NEWS')) {
                  return null
                }

                const currentItem = (this.state.publishRule || []).find((linkedGroup) => linkedGroup && linkedGroup.group && (linkedGroup.group.id === item.id))
                const isActive = (currentItem && currentItem.isEnabled) !== undefined ? currentItem.isEnabled : defaultItem && defaultItem.isEnabled
                return <PublishDetailCell key={index}
                  publishRules={this.props.details.publishRules}
                  details={this.props.details}
                  assetType={this.props.details.type}
                  startDate={currentItem ? currentItem.publishStartDate : (defaultItem && defaultItem.isEnabled ? defaultItem.publishStartDate : undefined)}
                  endDate={currentItem ? currentItem.publishEndDate : (defaultItem && defaultItem.isEnabled ? defaultItem.publishEndDate : undefined)}
                  type={item.name}
                  isActive={isActive}
                  onChange={(value) => this.onPublishButtonActiveChange(item.id, value)}
                  onStartDateChange={(value) => this.onPublishRuleStartDateChange(value, item.id)}
                  onEndDateChange={(value) => this.onPublishRuleEndDateChange(value, item.id)}
                  isError={errorId === item.id}
                  onOpenChange={(value, isCreate) => setTimeout(() => {
                    this.openStatusChange(value, item.id, isCreate)
                  }, 200)}
                  isPublishDisabled={isPublishDisabled}
                  isArticleInVCMS={isArticleInVCMS}
                />
              })
              }
            </div>
            <div>
              {(environmentPublish || []).map((item, index) => {
                const currentItem = (this.state.publishRule || []).find((linkedGroup) => linkedGroup.group.id === item.id)
                const startDate = currentItem && currentItem.publishStartDate ? currentItem.publishStartDate : item.publishStartDate || (defaultItem && defaultItem.isEnabled ? defaultItem.publishStartDate : undefined)
                const endDate = currentItem && currentItem.publishEndDate ? currentItem.publishEndDate : item.publishEndDate || (defaultItem && defaultItem.isEnabled ? defaultItem.publishEndDate : undefined)
                const isActive = (currentItem && currentItem.isEnabled) !== undefined ? currentItem.isEnabled : defaultItem && defaultItem.isEnabled
                return <PublishDetailCell key={index}
                  publishRules={this.props.details.publishRules}
                  details={this.props.details}
                  assetType={this.props.details.type}
                  type={item.name}
                  startDate={startDate}
                  endDate={endDate}
                  isActive={isActive}
                  shareLink={`${this.qaBaseUrl}${subUrl}?allowPreview=true`}
                  isLinkEnabled
                  isError={errorId === item.id}
                  onChange={(value) => this.onPublishButtonActiveChange(item.id, value)}
                  onStartDateChange={(value) => this.onPublishRuleStartDateChange(value, item.id)}
                  onEndDateChange={(value) => this.onPublishRuleEndDateChange(value, item.id)}
                  onOpenChange={(value, isCreate) => setTimeout(() => {
                    this.openStatusChange(value, item.id, isCreate)
                  }, 200)}
                  isPublishDisabled={isPublishDisabled}
                />
              }
              )}

            </div>

            { publishStatus === 'PUBLISHED' || publishStatus === 'PARTIAL' ? <Accordion title='SHARE' childComponent={<AssetShare shareUrl={`${this.prodBaseUrl}${subUrl}`} />} /> : null}

          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AssetPublishDetails.propTypes = {
  /** Asset id */
  assetId: PropTypes.string,
  /** Asset details */
  details: PropTypes.object,
  /** Publish group details */
  publishRuleDetails: PropTypes.arrayOf(PropTypes.object)
}

export default withApollo(compose(
  graphql(
    QueryGetPublishDetails,
    {
      skip: ({ details }) => {
        return _.isEmpty(details)
      },
      options: ({ project }) => {
        const variables = { project }
        return {
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        sessionStorage.removeItem('publishDetails')
        const publishStatus = {
          id: ownProps.details.id,
          status: getStatus(data.listPublishingGroups, ownProps.details)
        }
        sessionStorage.setItem('publishDetails', JSON.stringify(publishStatus))
        return {
          publishRuleDetails: props.data && props.data.listPublishingGroups && props.data.listPublishingGroups.items &&
          props.data.listPublishingGroups.items.length ? props.data.listPublishingGroups.items.filter(item => !item.status) : []
        }
      }
    }
  ),
  graphql(
    QueryListPublishRules,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { assetId, project }
        }
      },
      props: (props) => {
        // const { data} =props
        return {
          listPublishRules: props.data && props.data.listPublishRules && props.data.listPublishRules.length ? props.data.listPublishRules : [],
          getPublishRules: (assetId) => {
            const { project } = props.ownProps
            return props.data.refetch({ assetId, project })
          }
        }
      }
    }
  ),
  graphql(
    MutationCreatePublishRule,
    {
      options: ({ assetId, project }) => {
        return {
          update: (cache, { data: { createPublishRule } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
            if (cacheData.getAsset && cacheData.getAsset.publishRules) {
              cacheData.getAsset.publishRules.push(createPublishRule)
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        createPublishRule: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchCreatePublishRule,
    {
      options: ({ assetId, project }) => {
        return {
          update: (cache, { data: { batchCreatePublishRule } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
            if (cacheData.getAsset && cacheData.getAsset.publishRules) {
              cacheData.getAsset.publishRules = [...cacheData.getAsset.publishRules, ...batchCreatePublishRule]
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        batchCreatePublishRule: (contents) => {
          return props.mutate({
            variables: { contents, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchUpdatePublishRule,
    {
      props: (props) => ({
        batchUpdatePublishRule: (contents) => {
          return props.mutate({
            variables: {
              contents,
              parentAssetId: props.ownProps.assetId
            }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdatePublishRule,
    {
      options: ({ assetId, project }) => {
        return {
          update: (cache, { data: { updatePublishRule } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
            if (cacheData.getAsset && cacheData.getAsset.publishRules) {
              const index = cacheData.getAsset.publishRules.findIndex((item) => item.id === updatePublishRule.id)
              cacheData.getAsset.publishRules[index] = updatePublishRule
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData,
              variables: { id: assetId }
            })
          }
        }
      },
      props: (props) => ({
        updatePublishRule: (variables) => {
          return props.mutate({
            variables: { ...variables, parentAssetId: props.ownProps.assetId, project: props.ownProps.project }
          })
        }
      })
    }
  )
  // graphql(
  //   MutationRearrangeContent,
  //   {
  //     props: (props) => ({
  //       changeContentPosition: (contentsArray) => {
  //         return props.mutate({
  //           variables: { contents: contentsArray }
  //         })
  //       }
  //     })
  //   }),
)(AssetPublishDetails))
