import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose, isEmpty } from 'lodash'
import { Icon, Divider, Switch, DatePicker, TimePicker, Tooltip } from 'antd'
import moment from 'moment'

import ProductionStudioDropDown from './../../components/ui/dataEntry/inputs/ProductionStudioDropDown'
import MultipleDropDown from './../../components/ui/dataEntry/inputs/MultipleDropDown'
import GenreDropDown from './../../components/ui/dataEntry/inputs/GenreDropDown'
import Input from './../../components/ui/dataEntry/inputs/Input'
import InputCustom from './../../components/ui/dataEntry/inputs/InputCustom'
import InputNumber from './../../components/ui/dataEntry/inputs/InputNumber'
import TextAreaInput from './../../components/ui/dataEntry/inputs/TextArea'
import AuthorDropDown from './../../components/ui/dataEntry/inputs/AuthorDropDown'
import MatchDropDown from './../../components/ui/dataEntry/inputs/MatchDropDown'
import SeriesDropDown from '../../components/ui/dataEntry/inputs/SeriesDropDown'
import SeasonDropDown from './../../components/ui/dataEntry/inputs/SeasonDropDown'
import CategoryDropDown from './../../components/ui/dataEntry/inputs/CategoryDropDown'
import MetaDefaultDropDown from './../../components/ui/dataEntry/inputs/MetaDefaultDropDown'
import LanguageDropDown from '../../components/ui/dataEntry/inputs/LanguageDropDown'

import QueryAllAuthors from './../../graphQL/asset/getAuthors'
import userMessages from './../../constants/messages'
import InformationIcon from '../../components/ui/general/icons/InformationIcon'

const parentCompoent = 'asset-container'
class AssetGeneralDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      authors: props.authors && props.authors.length ? props.authors : [],
      searchTag: '',
      mode: props.details.isLive,
      subscription: props.details.isFree,
      seriesSearchString: '',
      seasonSearchString: '',
      seriesFilterVal: {
        type: {
          multiple_exact: 'SERIES'
        }
      },
      seasonFilterVal: {
        type: {
          multiple_exact: 'SEASON'
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.details) {
      this.setState({
        mode: newProps.details.isLive,
        subscription: newProps.details.isFree
      })
    }
    if (newProps.authors) {
      this.setState({ authors: newProps.authors })
    }
  }

  onAuthorChange = (id) => {
    const selectedAuthor = this.state.authors.find(author => author.id === id)
    this.props.onAuthorChange(selectedAuthor)
  }

  onSearch = (value) => {
    if (this.props.onSearch) { this.props.onSearch(value) }
  }

  onMatchSearch = (value) => {
    if (this.props.onMatchSearch) { this.props.onMatchSearch(value) }
  }

  onCategorySearch = (value) => {
    if (this.props.onCategorySearch) { this.props.onCategorySearch(value) }
  }

  onTagSearch = (searchTag) => {
    this.setState({ searchTag })
  }

  handleModeChange = (mode) => {
    this.setState({ mode })
    this.props.updateStatus(mode)
  }

  handleEntitlementChange = (subscription) => {
    this.setState({ subscription })
    this.props.updateSubscriptionStatus(!subscription)
  }

  onSeriesSearch = (seriesSearchString) => {
    this.setState({ seriesSearchString })
  }

  onSeasonSearch = (seasonSearchString) => {
    this.setState({ seasonSearchString })
  }

  validateFloatNumber = (e) => {
    const { handleHyperionGeneralChange } = this.props
    const regx = /[+-]?([0-9]*[.])?[0-9]+/
    const { value } = e.target
    const regxValidator = regx.test(value) && !isNaN(value)
    if (regxValidator || !value) {
      return handleHyperionGeneralChange(e)
    }
  }

  validateIntNumber = (e) => {
    const { handleHyperionGeneralChange } = this.props
    const regx = /^\d+$/
    const { value } = e.target
    const regxValidator = regx.test(value) && !isNaN(value)
    if (regxValidator || !value) {
      return handleHyperionGeneralChange(e)
    }
  }

  getAssetMetaFields = (metaData, selectedAssetType, assetId) => {
    const { metaFields } = selectedAssetType || {}
    const { handleMetaDetailsChange } = this.props
    return (metaFields || []).map((meta, index) => {
      if (meta.section && (meta.section !== 'null' || meta.section !== null)) return null
      const selectedMeta = (metaData || []).find(item => item.displayName === meta.displayName && item.type === meta.type)
      if (meta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{meta.displayTitle || meta.displayName}</span>
            {
              meta.tooltip &&
              <Tooltip placement='right' title={meta.tooltip} >
                <span className='asset-info-icon-boolean'><InformationIcon /></span>
              </Tooltip>
            }
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => handleMetaDetailsChange(meta, value)} />
          </div>
        </div>
      } else if (meta.type === 'STRING') {
        if (meta.values && !isEmpty(meta.values)) {
          return <div className='input-field'>
            <label className='input-title'>{meta.displayTitle || meta.displayName} {meta.isRequired ? <span className='mandatory'>*</span> : ''}</label>
            {
              meta.tooltip &&
              <Tooltip placement='right' title={meta.tooltip} >
                <span className='asset-info-icon'><InformationIcon /></span>
              </Tooltip>
            }
            <MetaDefaultDropDown
              key={index}
              title={meta.displayTitle || meta.displayName}
              options={meta.values}
              meta={meta}
              placeholder={`Select ${meta.displayTitle || meta.displayName}`}
              inputName='title'
              isRequired={meta.isRequired}
              selectedValue={selectedMeta && selectedMeta.value ? [selectedMeta.value] : []}
              handleChange={handleMetaDetailsChange}
              isError={selectedMeta ? selectedMeta.isError : false}
              assetId={assetId}
              parentCompoent='asset-container'
              mode={'default'}
              allowClear
            />
          </div>
        } else {
          return <InputCustom
            tooltip={meta.tooltip}
            key={index}
            title={meta.displayTitle || meta.displayName}
            placeholder={`Enter ${meta.displayTitle || meta.displayName}`}
            inputName='title'
            isRequired={meta.isRequired}
            value={selectedMeta ? selectedMeta.value : ''}
            handleChange={({ target }) => handleMetaDetailsChange(meta, target.value)}
            isError={selectedMeta ? selectedMeta.isError : false}
            errorMessage={`${meta.displayTitle || meta.displayName} is a mandatory field`}
          />
        }
      } else if (meta.type === 'INTEGER') {
        return <InputNumber
          tooltip={meta.tooltip}
          limit={16}
          key={index}
          title={meta.displayTitle || meta.displayName}
          placeholder={`Enter ${meta.displayTitle || meta.displayName}`}
          inputName='title'
          isRequired={meta.isRequired}
          value={selectedMeta ? parseInt(selectedMeta.value) : 0}
          handleChange={(value) => handleMetaDetailsChange(meta, value)}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={`${meta.displayTitle || meta.displayName} is a mandatory field`}
        />
      } else if (meta.type === 'LIST') {
        return <div className='input-field'>
          <label className='input-title'>{meta.displayTitle || meta.displayName} {meta.isRequired ? <span className='mandatory'>*</span> : ''}</label>
          {
            meta.tooltip &&
            <Tooltip placement='right' title={meta.tooltip} >
              <span className='asset-info-icon'><InformationIcon /></span>
            </Tooltip>
          }
          <MetaDefaultDropDown
            key={index}
            title={meta.displayTitle || meta.displayName}
            options={meta.values}
            meta={meta}
            placeholder={`Select ${meta.displayTitle || meta.displayName}`}
            inputName='title'
            isRequired={meta.isRequired}
            selectedValue={selectedMeta ? selectedMeta.fieldValues : []}
            handleChange={handleMetaDetailsChange}
            isError={selectedMeta ? selectedMeta.isError : false}
            assetId={assetId}
            parentCompoent='asset-container'
            mode={'multiple'}
          />
        </div>
      }
    })
  }

  render () {
    const generalDetails = _.cloneDeep(this.props.details)
    const { authors, mode, subscription, seriesFilterVal, seriesSearchString, seasonFilterVal, seasonSearchString } = this.state
    const {
      handleGeneralDetailsChange,
      isInvalidTitle,
      onInputFieldFocus,
      onInputFieldBlur,
      matchSearch,
      onCategorySelect,
      categorySearch,
      onMatchSelect,
      onChangeMatchFilter,
      matchFilter,
      isCreateDisabled,
      listAssetTypes,
      project,
      listGenreDetails,
      listProductionStudio,
      listCountryDetails,
      listLanguageDetails,
      handleHyperionGeneralChange,
      handleChangeProductionDateTime,
      handleChangeReleaseDateTime,
      handleDurationChange,
      handleSeasonSelect,
      handleSeriesSelect,
      handleGenreChange,
      handleSubtitleChange,
      handleAudioLanguageChange,
      handleCaptionChanges,
      handleProductionStudiosChange,
      handleCountriesChange,
      onProductionStudioSearch,
      productionSearch,
      handleAutoPublishChanges
    } = this.props

    const assetConfig = listAssetTypes.find(item => item.title === generalDetails.type)
    const isMatch = generalDetails && assetConfig && assetConfig.matchSection
    const isAuthor = generalDetails && assetConfig && assetConfig.authorSection
    const isMode = generalDetails && assetConfig && assetConfig.programSection && assetConfig && assetConfig.videoSection
    const isEntertainment = generalDetails && assetConfig && assetConfig.entertainmentMetadata
    const assetTypeCategory = generalDetails && assetConfig && assetConfig.assetTypeCategory
    const isAutoPublish = generalDetails && assetConfig && assetConfig.autoPublish
    const audioLanguages = generalDetails && generalDetails.audioLanguages ? generalDetails.audioLanguages.filter(item => item != null).map(item => {
      const tempItem = (listLanguageDetails || []).find(innerItem => innerItem.id === item)
      return _.isEmpty(tempItem) ? item : tempItem.id
    }) : []
    const subtitleLanguages = generalDetails && generalDetails.subtitleLanguages ? generalDetails.subtitleLanguages.filter(item => item != null).map(item => {
      const tempItem = (listLanguageDetails || []).find(innerItem => innerItem.id === item)
      return _.isEmpty(tempItem) ? item : tempItem.id
    }) : []
    const productionStudios = generalDetails && generalDetails.productionStudios ? generalDetails.productionStudios.map(productionStudio => productionStudio.name) : []
    // const countries = generalDetails && generalDetails.countries ? generalDetails.countries.map(country => country.name) : []
    return (
      <div className='general-details' id='general-details'>
        <Input
          title='Title'
          placeholder='Write a title...'
          inputName='title'
          isRequired
          value={generalDetails.title}
          handleChange={handleGeneralDetailsChange}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          errorMessage={userMessages.EMPTY_TITLE}
          onBlur={onInputFieldBlur}
        />
        <Input
          title='Short Title'
          placeholder='Write a short title...'
          inputName='shortTitle'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.shortTitle}
          handleChange={handleGeneralDetailsChange}
        />
        {isEntertainment ? <Input
          title='Original Title'
          placeholder='Write a original title...'
          inputName='originalTitle'
          value={generalDetails.originalTitle}
          handleChange={handleHyperionGeneralChange}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          errorMessage={userMessages.EMPTY_ORIGINALTITLE}
          onBlur={onInputFieldBlur}
        /> : ''}
        {isEntertainment ? <Input
          title='Color Code'
          placeholder='Insert Color Code'
          inputName='colorCode'
          value={generalDetails.colorCode}
          handleChange={handleHyperionGeneralChange}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          onBlur={onInputFieldBlur}
        /> : ''}
        {isEntertainment ? <Input
          title='Year Of Release'
          placeholder='Insert Year Of Release'
          inputName='yearOfRelease'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.yearOfRelease ? generalDetails.yearOfRelease.toString() : null}
          handleChange={this.validateIntNumber}
        /> : ''}
        {assetTypeCategory === 'SEASON' ? <div className='input-field'>
          <label className='input-title'>Series</label>
          <SeriesDropDown
            filterVal={seriesFilterVal}
            onSeriesSearch={this.onSeriesSearch}
            searchString={seriesSearchString}
            handleSeriesSelect={handleSeriesSelect}
            selectedSeries={generalDetails.series}
            project={project}
          />
        </div> : ''}
        {assetTypeCategory === 'SEASON' ? <Input
          title='Season Number'
          placeholder='Insert Season Number'
          inputName='seasonNumber'
          value={generalDetails.seasonNumber ? generalDetails.seasonNumber.toString() : null}
          handleChange={this.validateIntNumber}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          errorMessage={userMessages.EMPTY_SEASONNUMBER}
          onBlur={onInputFieldBlur}
        /> : ''}

        {assetTypeCategory === 'EPISODE' ? <div className='input-field'>
          <label className='input-title'>Season</label>
          <SeasonDropDown
            filterVal={seasonFilterVal}
            onSeasonSearch={this.onSeasonSearch}
            searchString={seasonSearchString}
            handleSeasonSelect={handleSeasonSelect}
            selectedSeason={_.cloneDeep(generalDetails.season)}
            project={project}
          />
        </div> : ''}

        {assetTypeCategory === 'EPISODE' ? <Input
          title='Episode Number In Series'
          placeholder='Insert Episode Number In Series'
          inputName='episodeNumberInSeries'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.episodeNumberInSeries ? generalDetails.episodeNumberInSeries.toString() : null}
          handleChange={this.validateIntNumber}
        /> : ''}

        {assetTypeCategory === 'EPISODE' ? <Input
          title='Episode Number'
          placeholder='Insert Episode Number'
          inputName='episodeNumber'
          value={generalDetails.episodeNumber ? generalDetails.episodeNumber.toString() : null}
          handleChange={this.validateIntNumber}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          errorMessage={userMessages.EMPTY_EPISODENUMBER}
          onBlur={onInputFieldBlur}
        /> : ''}

        {isEntertainment ? <TextAreaInput
          title='Short Description'
          placeholder='Write a short description...'
          inputName='shortDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.shortDescription}
          handleChange={handleHyperionGeneralChange}
        /> : ''}

        {isEntertainment ? <TextAreaInput
          title='Medium Description'
          placeholder='Write a medium description...'
          inputName='mediumDescription'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.mediumDescription}
          handleChange={handleHyperionGeneralChange}
        /> : ''}
        <TextAreaInput
          title='Description'
          placeholder='Write a description...'
          inputName='description'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.description}
          handleChange={handleGeneralDetailsChange}
        />

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Genres</label>
          <GenreDropDown
            options={listGenreDetails}
            displayParam='name'
            keyParam='id'
            parent={parentCompoent}
            placeHolder='Select Genres'
            selectedValue={generalDetails.genres}
            onOptionSelect={handleGenreChange}
            allowClear
          />
        </div> : ''}

        {assetTypeCategory === 'SERIES' ? <Input
          title='Expected Number of Seasons'
          placeholder='Insert Expected Number of Seasons'
          inputName='expectedNumberOfSeasons'
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
          value={generalDetails.expectedNumberOfSeasons ? generalDetails.expectedNumberOfSeasons.toString() : null}
          handleChange={this.validateIntNumber}
          errorMessage={userMessages.EMPTY_EXPECTEDSEASON}
        /> : ''}

        {isEntertainment && (assetTypeCategory === 'GENERIC' || assetTypeCategory === 'EPISODE') ? <div className='input-field'>
          <label className='input-title'>Duration</label>
          <TimePicker
            value={generalDetails.videoDuration ? moment(generalDetails.videoDuration, 'HH:mm:ss') : null}
            onChange={handleDurationChange}
            getPopupContainer={() => document.getElementById(parentCompoent)}
            format='HH:mm:ss'
            placeholder='Insert Duration'
            className='time-picker-general'
            popupClassName='time-picker-duration'
          />
        </div> : ''}

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Countries</label>
          <MultipleDropDown
            options={listCountryDetails}
            displayParam='name'
            keyParam='id'
            placeHolder='Select Countries'
            parent={parentCompoent}
            selectedValue={generalDetails.countries}
            onOptionSelect={handleCountriesChange}
            allowClear
          />
        </div> : ''}

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Production Studios</label>
          <ProductionStudioDropDown
            listProductionStudio={listProductionStudio}
            displayParam='name'
            keyParam='id'
            value={productionStudios}
            onOptionSelect={handleProductionStudiosChange}
            onProductionStudioSearch={onProductionStudioSearch}
            productionSearch={productionSearch}
            project={project}
          />
        </div> : ''}

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Release Date</label>
          <DatePicker
            getPopupContainer={() => document.getElementById(parentCompoent)}
            showTime={{ format: 'HH:mm' }}
            format='DD-MMM-YYYY HH:mm'
            placeholder='Select Release Date'
            showToday={false}
            onChange={handleChangeReleaseDateTime}
            value={generalDetails.releaseDateTime ? moment(generalDetails.releaseDateTime) : null}
            className='date-picker-general'
          />
        </div> : ''}

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Production Date</label>
          <DatePicker
            getPopupContainer={() => document.getElementById(parentCompoent)}
            showTime={{ format: 'HH:mm' }}
            format='DD-MMM-YYYY HH:mm'
            placeholder='Select Production Date'
            showToday={false}
            className='date-picker-general'
            onChange={handleChangeProductionDateTime}
            value={generalDetails.productionDateTime ? moment(generalDetails.productionDateTime) : null}
          />
        </div> : ''}

        {isEntertainment ? <Input
          title='Average Rating'
          placeholder='Insert Average Rating'
          inputName='averageRating'
          value={generalDetails.averageRating ? generalDetails.averageRating.toString() : null}
          handleChange={this.validateFloatNumber}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          errorMessage={userMessages.EMPTY_AVERAGE_RATING}
          onBlur={onInputFieldBlur}
        /> : ''}

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Subtitles</label>
          <LanguageDropDown
            options={listLanguageDetails}
            displayParam='name'
            keyParam='id'
            placeHolder='Select Subtitles'
            parent={parentCompoent}
            value={subtitleLanguages}
            onOptionSelect={handleSubtitleChange}
            allowClear
          />
        </div> : ''}

        {isEntertainment ? <div className='input-field'>
          <label className='input-title'>Audio Languages</label>
          <LanguageDropDown
            options={listLanguageDetails}
            displayParam='name'
            keyParam='id'
            placeHolder='Select Audio Languages'
            parent={parentCompoent}
            value={audioLanguages}
            onOptionSelect={handleAudioLanguageChange}
            allowClear
          />
        </div> : ''}

        {isEntertainment ? <Input
          title='Original Audio'
          placeholder='Insert Original Audio'
          inputName='originalAudio'
          value={generalDetails.originalAudio}
          handleChange={handleHyperionGeneralChange}
          onFocus={onInputFieldFocus}
          isError={isInvalidTitle}
          errorMessage={userMessages.EMPTY_AVERAGE_RATING}
          onBlur={onInputFieldBlur}
        /> : ''}

        {isAuthor ? <div className='input-field'>
          <label className='input-title'>Author</label>
          <AuthorDropDown
            parentCompoent={parentCompoent}
            options={authors || []}
            selected={generalDetails.author}
            displayParam='name'
            onSearch={this.onSearch}
            onOptionSelect={this.onAuthorChange}
            dropdownRender={menu => (
              <div>
                {isCreateDisabled ? '' : <React.Fragment>
                  <div onMouseDown={(e) => {
                    e.stopPropagation()
                    this.props.showCreateAuthorModal()
                  }} className='custom-dropdown-container'>
                    <div style={{ position: 'absolute', left: 0, marginLeft: '10px' }}>New Author</div>
                    <div style={{ position: 'absolute', right: 0, marginRight: '10px' }} > <Icon type='plus' /> </div>
                  </div>
                  <Divider dashed style={{ margin: 0 }} />
                </React.Fragment>}
                {menu}
              </div>
            )}
          />
        </div> : null}

        {isMatch ? <div className='input-field'>
          <label className='input-title'>Match</label>
          <MatchDropDown
            parentCompoent={parentCompoent}
            onMatchSelect={onMatchSelect}
            selectedMatch={generalDetails.match}
            onSearch={this.onMatchSearch}
            matchSearch={matchSearch}
            matchFilter={matchFilter}
            onChangeMatchFilter={onChangeMatchFilter}
            project={project}
          />
        </div> : null}

        <div className='input-field'>
          <label className='input-title'>Category</label>
          <CategoryDropDown
            parentCompoent={parentCompoent}
            onCategorySelect={onCategorySelect}
            selectedCategory={generalDetails.category}
            onSearch={this.onCategorySearch}
            categorySearch={categorySearch}
            project={project}
          />
        </div>

        {!isEntertainment ? <div className='input-field switch-container'>
          {isMode ? <div className='switch-inner-container'>
            <span>Live</span>
            <Switch checked={mode} onChange={this.handleModeChange} />
          </div> : ''}
          <div className='switch-inner-container'>
            <span>Premium</span>
            <Switch checked={!subscription} onChange={this.handleEntitlementChange} />
          </div>
        </div> : ''}

        {isEntertainment ? <div className='input-field switch-container'>
          <div className='switch-inner-container'>
            <span>Closed Caption</span>
            <Switch checked={generalDetails.closedCaption} onChange={handleCaptionChanges} />
          </div>
        </div> : ''}

        {this.getAssetMetaFields(generalDetails.meta, assetConfig, this.props.details.id)}
        {isAutoPublish ? <div className='input-field switch-container'>
          <div className='switch-inner-container'>
            <span>Auto Publish</span>
            <Switch checked={generalDetails.autoPublish} onChange={handleAutoPublishChanges} />
          </div>
        </div> : ''}

      </div>
    )
  }
}

AssetGeneralDetails.propTypes = {
  /** details of AssetGeneralDetails. */
  details: PropTypes.object.isRequired,
  /** details of AssetGeneralDetails. */
  authors: PropTypes.array,
  /** general data change action of AssetGeneralDetails. */
  handleGeneralDetailsChange: PropTypes.func.isRequired,
  /** author change action of AssetGeneralDetails. */
  onAuthorChange: PropTypes.func.isRequired,
  /** create new tag of AssetGeneralDetails. */
  /** Callback to display create author modal  */
  showCreateAuthorModal: PropTypes.func,
  /** Callback to search author */
  onSearch: PropTypes.func,
  /** Author text to search in author dropdown */
  authorSearch: PropTypes.string,
  /** match text to search in match dropdown */
  matchSearch: PropTypes.string,
  /** matches of AssetGeneralDetails. */
  matches: PropTypes.array,
  /** Function to be called on match select */
  onMatchSelect: PropTypes.func,
  /** Function to be called on live status change */
  updateStatus: PropTypes.func,
  /** Function to be called on subscription status change */
  updateSubscriptionStatus: PropTypes.func,
  /** category text to search in category dropdown */
  categorySearch: PropTypes.string,
  /** Function to be called on category select */
  onCategorySelect: PropTypes.func,
  /** Function to be called on category search */
  onCategorySearch: PropTypes.func,
  /** Boolean to indicates create access restriction */
  isCreateDisabled: PropTypes.bool,
  /** Details of GenreList */
  listGenreDetails: PropTypes.array,
  /** Details of ProductionStudioList */
  listProductionStudio: PropTypes.array,
  /** Details of CountriesList */
  listCountryDetails: PropTypes.array,
  /** Details of LanguagesList */
  listLanguageDetails: PropTypes.array,
  /** hyperion generalChanges func */
  handleHyperionGeneralChange: PropTypes.func.isRequired,
  /** functioin to handleProductionDateTime */
  handleChangeProductionDateTime: PropTypes.func.isRequired,
  /** functioin to handleDurationTime */
  handleDurationChange: PropTypes.func.isRequired,
  /** function to handleSeasonOption select */
  handleSeasonSelect: PropTypes.func.isRequired,
  /** function to handleSeriesOption select */
  handleSeriesSelect: PropTypes.func.isRequired,
  /** function to handleCountriesChange select */
  handleCountriesChange: PropTypes.func.isRequired,
  /** function to handleGenreOption select */
  handleGenreChange: PropTypes.func.isRequired,
  /** function to handleSubtitleOption select */
  handleSubtitleChange: PropTypes.func.isRequired,
  /** function to handleAudioLanguageOption select */
  handleAudioLanguageChange: PropTypes.func.isRequired,
  /** function to handleCaptionChangesOption select */
  handleCaptionChanges: PropTypes.func.isRequired,
  /** function to handleProductionStudiosChange select */
  handleProductionStudiosChange: PropTypes.func.isRequired
}

export default withApollo(compose(
  graphql(
    QueryAllAuthors,
    {
      options: ({ authorSearch, project }) => {
        const searchFilter = {
          name: { match: authorSearch }
          // isDisabled: { match: false }
        }
        const filter = authorSearch ? searchFilter : null
        return {
          variables: { filter, limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listAuthors } }) => {
        const authors = listAuthors && listAuthors.items ? [...listAuthors.items.filter(item => !item.isDisabled)] : undefined
        return { authors }
      }
    }
  )

)(AssetGeneralDetails))
