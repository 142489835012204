import * as React from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'

export class MatchCard extends React.Component {
  state = {
    blink: false
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.assetId !== this.props.assetId) {
      this.setState({ blink: true }, () => {
        if (this.blinkTimout) {
          clearTimeout(this.blinkTimout)
        }
        this.blinkTimout = setTimeout(() => this.setState({ blink: false }), 2000)
      })
    }
  }

  componentWillUnmount = () => {
    if (this.blinkTimout) {
      clearTimeout(this.blinkTimout)
    }
  }

  render () {
    const { assetId, shortId } = this.props
    const { blink } = this.state
    return !assetId ? <Empty className={`channel-empty ${blink ? 'blink' : ''}`} description='No Data' />
      : <div className={`match-card ${blink ? 'blink' : ''}`}>
        {<img src={this.props.displayImage} className='display-image' />}
        {/* <span className='display-image' style={{ background: `url${this.props.displayImage}` }} /> */}
        <div className='match-details'>
          <span className='match-title'>{this.props.matchTitle}</span>
          {/* <span className='match-date'>{this.props.date}</span> */}
          <div className='match-time-details'>
            <div className='broadcast-details'>

              {shortId ? <div className='match-container'>
                <span className='title-text'>{'Asset ID: '}</span>
                <a className='match-id link' href={location.origin + '/assets/' + assetId} target='_blank'>{shortId}</a>
              </div> : ''}
            </div>
            <div className='kickoff-details'>

              {this.props.matchId ? <div className='match-container'>
                <span className='title-text'>{'Match ID: '}</span>
                <div className='match-id'>{this.props.matchId}</div>
              </div> : ''}
            </div>
          </div>
          {this.props.broadcast ? <div className='details-bottom'>
            <span className='title-text'>{'Broadcast Start: '}</span>
            <span>{this.props.broadcast}</span>
          </div> : ''}
          {this.props.endTime ? <div className='details-bottom'>
            <span className='title-text'>{'Broadcast End: '}</span>
            <span>{this.props.endTime}</span>
          </div> : ''}
        </div>
      </div>
  }
}

MatchCard.propTypes = {
  /** Display image of program */
  displayImage: PropTypes.string,
  /** Program title */
  matchTitle: PropTypes.string,
  /** Match date */
  date: PropTypes.string,
  /** Broadcast start time */
  broadcast: PropTypes.string,
  /** Kickoff time */
  kickoff: PropTypes.string,
  /** Match ID */
  matchId: PropTypes.string,
  /** ID of asset related */
  assetId: PropTypes.string,
  /** Asset short ID */
  shortId: PropTypes.string,
  /** Broadcast end time */
  endTime: PropTypes.string
}
