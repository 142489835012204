import React from 'react'
import { Menu, Icon, message } from 'antd'
import AccountIcon from './../general/icons/AccountIcon'
import LogoutIcon from './../general/icons/LogoutIcon'
import NotificationAlertIcon from './../general/icons/NotificationAlertIcon'
import { Auth, Hub } from 'aws-amplify'
import AuthService from '../../../services/AuthService'
import CircularProgressBar from '../../CircularProgressBar'
import ApiService from '../../../services/ApiService'
import { utilityService } from '../../../services/UtilityService'

import { Greetings } from 'aws-amplify-react'
import NotificationIcon from '../general/icons/NotificationIcon'
import userMessages from '../../../constants/messages'

import './../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
// import QueryUploadImage from '../../../graphQL/asset/getUploadUrl'
import QueryUploadId from '../../../graphQL/asset/getMultipartUploadId'
import QueryBatchGetIntermediateMultipartUploadUrl from '../../../graphQL/asset/batchGetIntermediateMultipartUploadUrl'
import MutationCompletedMultipartUpload from '../../../graphQL/asset/completedMultipartUpload'
import MutationTriggerVOD from '../../../graphQL/asset/triggerLiveToVod'
import MutationTrackLogout from '../../../graphQL/asset/trackLogout'

const SubMenu = Menu.SubMenu

class HeaderMenu extends Greetings {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      uploadQueue: []
    }
    this.getUserDetails()
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      const { fileData, uploadData, chunkUrl } = newProps
      if (fileData && !_.isEqual(fileData, this.props.fileData)) {
        let { uploadQueue = [] } = this.state
        if (uploadQueue && (!uploadQueue.length || this.hasFilesUploaded(uploadQueue))) {
          if (!window.onbeforeunload) {
            window.onbeforeunload = this.handleUnload
          }
          this.props.clearUploadUrl()
          this.props.getUploadUrl(fileData.files[0])
          fileData.files[0].isProgress = true
          fileData.isProcessing = true
        }
        uploadQueue.push(fileData)
        this.setState({ uploadQueue })
      }
      if (uploadData && !_.isEqual(uploadData, this.props.uploadData)) {
        if (!_.isEmpty(uploadData)) {
          this.beforeVidoUpload(_.cloneDeep(uploadData))
        }
      }

      if (chunkUrl && !_.isEqual(chunkUrl, this.props.chunkUrl)) {
        if (!_.isEmpty(chunkUrl)) {
          this.uploadVideoApi(uploadData, chunkUrl[0], 0, chunkUrl)
          // const responseArr = (chunkUrl || []).map((chunk, index) => {
          //   const response = this.uploadVideoApi(uploadData, chunk, index, chunkUrl.length)
          //   return response
          // })
        }
      }
    }

    componentWillUnmount () {
      window.onbeforeunload = null
    }

    handleUnload = (e) => {
      return 'File upload is in progress. Closing the window will lead to upload failure. Are you sure?'
    }

    logout = () => {
      const { name } = this.state
      const browserAndOsDetails = utilityService.getBrowserDetails()
      const variables = { user: name, project: this.props.project, userAgent: browserAndOsDetails }
      this.props.trackLogout(variables).then(({ data }) => {

      }, error => {
        utilityService.handleError(error)
      })
      window.onbeforeunload = null
      Auth.signOut()
        .then(data => {
          AuthService.clearUserDetails()
          this.props.history.push('/')
          Hub.listen('auth', this)
          // TODO :: location.reload() is a work around fix for logout not working after react 18 dom change done in index file js, remove when aws amplify is upgraded
          location.reload()
        })
        .catch(err => console.log(err))
    }

    onMenuClick = (selectedMenu) => {
      if (selectedMenu.key === 'logout') {
        this.logout()
      }
      if (selectedMenu.key === 'changePassword') {
        this.props.changePassword()
      }
      if (selectedMenu.key === 'disableMfa') {
        this.props.disableMfa(true)
      }
    }

    getUserDetails = async () => {
      const user = await Auth.currentUserInfo()
      if (user && user.attributes) {
        const { name, email } = user.attributes
        this.setState({ name, email })
      } else {
        this.logout()
      }
    }

    hasFilesUploaded = uploadQueue => {
      if (!uploadQueue || !uploadQueue.length) {
        return true
      }
      const isnotUploaded = !!uploadQueue.find(queue => {
        const isFinished = queue.files.filter(item => !item.uploaded).length === 0
        return !isFinished
      })
      return !isnotUploaded
    }

    handleJobFinishStatus = uploadQueue => {
      const isFinished = uploadQueue[0].files.filter(item => !item.finished).length === 0
      if (isFinished) {
        const successJobs = uploadQueue[0].files.filter(item => !item.isUploadError).length
        const failureJobs = uploadQueue[0].files.length - successJobs
        if (!successJobs) {
          message.error(`Job creation failed for asset ${uploadQueue[0].assetId}`)
        } else if (failureJobs) {
          message.success(`${successJobs} ${successJobs > 1 ? 'Jobs' : 'Job'} created for asset ${uploadQueue[0].assetId} but failed to create ${failureJobs} ${failureJobs > 1 ? 'Jobs' : 'Job'}`)
        } else {
          message.success(`${successJobs} ${successJobs > 1 ? 'Jobs' : 'Job'} created for asset ${uploadQueue[0].assetId}`)
        }
        uploadQueue.splice(0, 1)
        if (!uploadQueue.length) {
          window.onbeforeunload = null
        }
      }
      this.setState({ uploadQueue })
    }

    createVodJob = (file, queue) => {
      const { tags, assetId, assetType, assetTitle, category, isDrmEnabled } = queue
      const fileNameList = file.url ? file.url.split('/') : [file.id]
      const variables = {
        assetId: assetId,
        assetType: assetType,
        name: assetTitle,
        jobType: 'VOD_MEDIA',
        fileKey: fileNameList[fileNameList.length - 1],
        tags: tags && tags.length ? tags.map((item) => item.key) : null,
        mediaCategory: category || null,
        originalFileSize: file.size || null,
        drmRequired: isDrmEnabled || false,
        timestamp: new Date().getTime()
      }
      this.props.triggerLiveToVod(variables).then((response) => {
        let { uploadQueue } = this.state
        const index = uploadQueue[0].files.findIndex(item => item.uid === file.uid)
        uploadQueue[0].files[index].finished = true
        this.handleJobFinishStatus(uploadQueue)
      }, (error) => {
        let { uploadQueue } = this.state
        const index = uploadQueue[0].files.findIndex(item => item.uid === file.uid)
        uploadQueue[0].files[index].finished = true
        uploadQueue[0].files[index].isUploadError = true
        this.handleJobFinishStatus(uploadQueue)
        utilityService.handleError(error)
      })
    }

    uploadImageApi = (uploadData) => {
      const { fileName, contentType, key, uploadUrl, url } = uploadData

      const successCallBack = () => {
        let { uploadQueue } = this.state
        const index = uploadQueue.findIndex(item => item.isProcessing)
        if (index === -1) {
          return
        }
        const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isUploading)
        if (selectedFileIndex > -1) {
          uploadQueue[index].files[selectedFileIndex].progress = 100
          uploadQueue[index].files[selectedFileIndex].isUploading = false
          uploadQueue[index].files[selectedFileIndex].uploaded = true
        }
        uploadQueue = this.uploadNextFile(uploadQueue, index)
        this.setState({ uploadQueue })
        setTimeout(() => {
          this.createVodJob(uploadQueue[index].files[selectedFileIndex], uploadQueue[index])
          // this.setState({ uploadQueue })
        }, 5 * 1000)
      }

      const failureCallBack = () => {
        let { uploadQueue } = this.state
        const index = uploadQueue.findIndex(item => item.isProcessing)
        if (index === -1) {
          return
        }
        message.error(`Failed to create media ${fileName}`)
        const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isUploading)
        if (selectedFileIndex > -1) {
          uploadQueue[index].files[selectedFileIndex].isUploading = false
          uploadQueue[index].files[selectedFileIndex].uploaded = true
          uploadQueue[index].files[selectedFileIndex].isError = true
          uploadQueue[index].files[selectedFileIndex].finished = true
          uploadQueue[index].files[selectedFileIndex].isUploadError = true
        }
        uploadQueue = this.uploadNextFile(uploadQueue, index)
        this.handleJobFinishStatus(uploadQueue)
      }

      const progressCallBack = progress => {
        let { uploadQueue } = this.state
        const index = uploadQueue.findIndex(item => item.isProcessing)
        if (index === -1) {
          return
        }
        const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isUploading)
        if (selectedFileIndex > -1) {
          uploadQueue[index].files[selectedFileIndex].progress = progress
        }
        this.setState({ uploadQueue })
      }
      let { uploadQueue } = this.state
      const index = uploadQueue.findIndex(item => item.isProcessing)
      if (index === -1) {
        return
      }
      const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isProgress)
      if (selectedFileIndex > -1) {
        uploadQueue[index].files[selectedFileIndex].id = key
        uploadQueue[index].files[selectedFileIndex].isUploading = true
        uploadQueue[index].files[selectedFileIndex].isProgress = false
        uploadQueue[index].files[selectedFileIndex].url = url
        const request = ApiService('PUT', uploadUrl, uploadQueue[index].files[selectedFileIndex], { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
        uploadQueue[index].files[selectedFileIndex].request = request
        this.setState({ uploadQueue })
      }
    }

    beforeVidoUpload = (uploadData) => {
      this.blob = []
      const fileChunkSize = 10000000 // 10MB
      const fileSize = uploadData.size
      const numChunks = Math.floor(fileSize / fileChunkSize) + 1
      let promisesArray = []
      let { uploadQueue } = this.state
      const index = uploadQueue.findIndex(item => item.isProcessing)
      if (index === -1) {
        return
      }
      const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isProgress)
      const selectedFile = uploadQueue[index].files[selectedFileIndex]
      let start, end, blob
      for (let i = 1; i < numChunks + 1; i++) {
        start = (i - 1) * fileChunkSize
        end = (i) * fileChunkSize
        blob = (i < numChunks) ? selectedFile.slice(start, end) : selectedFile.slice(start)
        const param = {
          fileKey: uploadData.fileKey,
          partNumber: i,
          uploadId: uploadData.uploadId
        }
        promisesArray.push(param)
        this.blob.push(blob)
      }
      this.props.updateIntermediateUrl(promisesArray)
    }

    uploadVideoApi = (uploadData, uploadUrl, chunkIndex, chunkArr, errorIndex = 0) => {
      const { contentType, url, fileKey } = uploadData
      const chunkSize = chunkArr.length
      // const { fileName, contentType, key, url } = uploadData

      const successCallBack = (response, etag, partNo) => {
        let { uploadQueue } = this.state
        const index = uploadQueue.findIndex(item => item.isProcessing)
        if (index === -1) {
          return
        }
        const part = chunkIndex + 1
        const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isUploading)
        if (!uploadQueue[index].files[selectedFileIndex].chunkData || uploadQueue[index].files[selectedFileIndex].chunkData.length !== chunkSize) {
          if (!uploadQueue[index].files[selectedFileIndex].chunkData) {
            uploadQueue[index].files[selectedFileIndex].chunkData = []
          }
          (uploadQueue[index].files[selectedFileIndex].chunkData).push({
            ETag: etag,
            PartNumber: part
          })
          this.setState({ uploadQueue }, () => {
            if (chunkSize === part && uploadQueue[index].files[selectedFileIndex].chunkData.length === chunkSize) {
              const variablesForCompleteUpload = {
                uploadId: uploadData.uploadId,
                fileKey: uploadData.fileKey,
                parts: uploadQueue[index].files[selectedFileIndex].chunkData,
                assetId: uploadQueue[index].assetId
              }
              this.props.completedMultipartUpload(variablesForCompleteUpload).then(() => {
                if (selectedFileIndex > -1) {
                  uploadQueue[index].files[selectedFileIndex].progress = 100
                  uploadQueue[index].files[selectedFileIndex].isUploading = false
                  uploadQueue[index].files[selectedFileIndex].uploaded = true
                  delete uploadQueue[index].files[selectedFileIndex].chunkData
                }
                uploadQueue = this.uploadNextFile(uploadQueue, index)
                this.setState({ uploadQueue })
                setTimeout(() => {
                  this.createVodJob(uploadQueue[index].files[selectedFileIndex], uploadQueue[index])
                  // this.setState({ uploadQueue })
                }, 5 * 1000)
              }, (error) => {
                utilityService.handleError(error)
                if (selectedFileIndex > -1) {
                  uploadQueue[index].files[selectedFileIndex].isUploading = false
                  uploadQueue[index].files[selectedFileIndex].uploaded = true
                  uploadQueue[index].files[selectedFileIndex].isError = true
                  uploadQueue[index].files[selectedFileIndex].finished = true
                  uploadQueue[index].files[selectedFileIndex].isUploadError = true
                }
                uploadQueue = this.uploadNextFile(uploadQueue, index)
                this.handleJobFinishStatus(uploadQueue)
              })
            } else {
              uploadQueue[index].files[selectedFileIndex].isProgress = true
              this.setState({ uploadQueue }, () => {
                this.uploadVideoApi(uploadData, chunkArr[chunkIndex + 1], chunkIndex + 1, chunkArr)
              })
            }
          })
        }
      }

      const failureCallBack = () => {
        let { uploadQueue } = this.state
        const index = uploadQueue.findIndex(item => item.isProcessing)
        if (index === -1) {
          return
        }
        const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isUploading)
        if (selectedFileIndex > -1 && errorIndex !== 12) {
          uploadQueue[index].files[selectedFileIndex].isProgress = true
          setTimeout(() => {
            this.uploadVideoApi(uploadData, chunkArr[chunkIndex], chunkIndex, chunkArr, errorIndex + 1)
          }, 20000)
          return
        }
        if (selectedFileIndex > -1) {
          uploadQueue[index].files[selectedFileIndex].isUploading = false
          uploadQueue[index].files[selectedFileIndex].uploaded = true
          uploadQueue[index].files[selectedFileIndex].isError = true
          uploadQueue[index].files[selectedFileIndex].finished = true
          uploadQueue[index].files[selectedFileIndex].isUploadError = true
        }
        uploadQueue = this.uploadNextFile(uploadQueue, index)
        this.handleJobFinishStatus(uploadQueue)
      }

      const progressCallBack = progress => {
        let { uploadQueue } = this.state
        const index = uploadQueue.findIndex(item => item.isProcessing)
        if (index === -1) {
          return
        }
        const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isUploading)
        if (selectedFileIndex > -1) {
          const ratio = 1 / chunkSize
          const currentProgress = (progress * ratio) + (ratio * 100 * chunkIndex)
          uploadQueue[index].files[selectedFileIndex].progress = parseInt(currentProgress)
        }
        this.setState({ uploadQueue })
      }

      let { uploadQueue } = this.state
      const index = uploadQueue.findIndex(item => item.isProcessing)
      if (index === -1) {
        return
      }
      const selectedFileIndex = uploadQueue[index].files.findIndex(image => image.isProgress)
      if (selectedFileIndex > -1) {
        uploadQueue[index].files[selectedFileIndex].id = fileKey
        uploadQueue[index].files[selectedFileIndex].isUploading = true
        uploadQueue[index].files[selectedFileIndex].isProgress = false
        uploadQueue[index].files[selectedFileIndex].url = url
        const request = ApiService('PUT', uploadUrl, this.blob[chunkIndex], { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
        uploadQueue[index].files[selectedFileIndex].request = request
        this.setState({ uploadQueue })
      }
    }

    skipUploadingFile = () => {
      let { uploadQueue } = this.state
      const index = uploadQueue.findIndex(item => item.isProcessing)
      let fileIndex = index > -1 ? uploadQueue[index].files.findIndex(item => item.isUploading) : -1
      if (index > -1 && fileIndex > -1) {
        const fileName = uploadQueue[index].files[fileIndex].name
        uploadQueue[index].files[fileIndex].isUploading = false
        uploadQueue[index].files[fileIndex].uploaded = true
        uploadQueue[index].files[fileIndex].isError = true
        uploadQueue[index].files[fileIndex].finished = true
        uploadQueue[index].files[fileIndex].isUploadError = true
        if (uploadQueue[index].files[fileIndex].request) {
          uploadQueue[index].files[fileIndex].request.abort()
        }
        uploadQueue = this.uploadNextFile(uploadQueue, index)
        message.warning(`Cancelled upload of ${fileName}`)
        setTimeout(() => this.handleJobFinishStatus(uploadQueue), 2000)
      }
    }

    uploadNextFile = (uploadQueue, index) => {
      let nextIndex = uploadQueue[index].files.findIndex(item => !item.uploaded && !item.isUploading && !item.isProgress)
      if (nextIndex > -1) {
        this.props.clearUploadUrl()
        this.props.getUploadUrl(uploadQueue[index].files[nextIndex])
        uploadQueue[index].files[nextIndex].isProgress = true
      } else {
        uploadQueue[index].isProcessing = false
        if (uploadQueue.length - 1 > index) {
          uploadQueue[index + 1].isProcessing = true
          uploadQueue[index + 1].files[0].isProgress = true
          this.props.clearUploadUrl()
          this.props.getUploadUrl(uploadQueue[index + 1].files[0])
        }
      }
      return uploadQueue
    }

    getUpladStatus = (selectedFile, isFinished) => {
      const percentage = isFinished ? 100 : selectedFile && selectedFile.progress ? selectedFile.progress : 0
      return <div className='small-upload-status' >
        <CircularProgressBar strokeWidth='2' sqSize='22' percentage={percentage} />
      </div>
    }

    render () {
      const { uploadQueue } = this.state
      const { handleNotificationDropDown, setNotificationIconRef, showAlertIcon } = this.props
      const index = uploadQueue.findIndex(item => item.isProcessing)
      const fileIndex = index > -1 ? uploadQueue[index].files.findIndex(item => item.isUploading) : -1
      let selectedFile
      if (index > -1 && fileIndex > -1) {
        selectedFile = uploadQueue[index].files[fileIndex]
      }
      return (
        <Menu mode='horizontal' onClick={this.onMenuClick}>
          <Menu.Item key='1'>
            <span className='user-name'>{ this.state.name }</span>
            <span className='menu-separation' />
          </Menu.Item>
          <SubMenu key='sub2' title={<Icon type='question-circle' theme='filled' />} onTitleClick={this.props.handleKeyboardShortcut} />
          { uploadQueue && uploadQueue.length ? <SubMenu key='sub3' title={this.getUpladStatus(selectedFile, index === -1)} >
            <div className='uplaod-status' >
              { uploadQueue.map((item, queueIndex) => <div key={queueIndex} className='asset-container' >
                <div className='single-item'>
                  <label>{item.assetId}</label>{queueIndex === index && selectedFile ? <p>{selectedFile.progress || 0}%</p> : null}
                </div>
                { index > -1 ? <div className='description'>
                  { queueIndex === index ? <p>{`Uploading ${item.files.filter(item => item.uploaded).length + 1} of ${item.files.length}  ${item.files.length > 1 ? 'videos' : 'video'}`}</p> : null}
                  { queueIndex > index ? <p>{`Waiting to upload ${item.files.length} ${item.files.length > 1 ? 'videos' : 'video'}`}</p> : null}
                  { queueIndex === index && selectedFile ? <p>{`Uploading ${selectedFile.name}`}</p> : null}
                </div> : null }
                { index === -1 ? <div className='description'>
                  <p> Waiting for Job Creation</p>
                </div> : null}
                { queueIndex === index && selectedFile ? <div className='skip-upload' >
                  <p className='heading'>Skip </p>
                  <p>{selectedFile.name}</p>
                  <div className='skip-button' onClick={this.skipUploadingFile}>SKIP</div>
                </div> : null }
              </div>) }
            </div>
          </SubMenu> : null }
          <SubMenu key='sub3' title={showAlertIcon ? <NotificationAlertIcon setNotificationIconRef={setNotificationIconRef} /> : <NotificationIcon setNotificationIconRef={setNotificationIconRef} />} onTitleClick={handleNotificationDropDown} />
          <SubMenu key='sub1' title={<AccountIcon />} >
            <Menu.Item key='logout'>
              <LogoutIcon />Log Out
            </Menu.Item>
            <Menu.Item key='changePassword'>
              Change Password
            </Menu.Item>
            <Menu.Item key='disableMfa'>
              MFA Settings
            </Menu.Item>
          </SubMenu>
        </Menu>
      )
    }
}

HeaderMenu.propTypes = {

}

HeaderMenu.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationTriggerVOD,
    {
      options: () => {
        return {
          update: (cache, { data: { triggerLiveToVod } }) => {
            if (!triggerLiveToVod) {
              message.error(userMessages.ALREADY_ADDED_VIDEO)
            }
          }
        }
      },
      props: (props) => ({
        triggerLiveToVod: (variables) => {
          return props.mutate({ variables })
        }
      })
    }
  ),
  graphql(
    MutationCompletedMultipartUpload,
    {
      props: (props) => ({
        completedMultipartUpload: (variables) => {
          return props.mutate({ variables })
        }
      })
    }
  ),
  // graphql(
  //   QueryUploadImage,
  //   {
  //     options: ({ file }) => {
  //       return {
  //         fetchPolicy: 'network-only',
  //         variables: { files: [file], timeStamp: new Date().getTime() }
  //       }
  //     },
  //     skip: ({ file }) => {
  //       return !file
  //     },
  //     props: (props) => {
  //       const { data, ownProps } = props
  //       const uploadData = !data.loading && data.getUploadUrl ? data.getUploadUrl.map((item, index) => {
  //         return { ...item, ...ownProps.file }
  //       }) : []
  //       const isUploadError = !data.loading && data.error
  //       if (isUploadError) {
  //         utilityService.handleError(data.error)
  //       }
  //       return { uploadData: _.map(uploadData, _.clone), isUploadError }
  //     }
  //   }
  // ),
  graphql(
    QueryUploadId,
    {
      options: ({ file }) => {
        return {
          fetchPolicy: 'network-only',
          variables: {
            type: file.type,
            fileName: file.fileName,
            contentType: file.contentType
          }
        }
      },
      skip: ({ file }) => {
        return !file
      },
      props: (props) => {
        const { data, ownProps } = props
        const uploadData = !data.loading && data.getMultipartUploadId ? { ...data.getMultipartUploadId, ...ownProps.file } : []
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          utilityService.handleError(data.error)
        }
        return { uploadData: _.cloneDeep(uploadData), isUploadError }
      }
    }
  ),
  graphql(
    QueryBatchGetIntermediateMultipartUploadUrl,
    {
      options: ({ intermediateUrl }) => {
        return {
          fetchPolicy: 'network-only',
          variables: {
            input: intermediateUrl
          }
        }
      },
      skip: ({ intermediateUrl }) => {
        return !(intermediateUrl && intermediateUrl.length)
      },
      props: (props) => {
        const { data } = props
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          utilityService.handleError(data.error)
        }
        return { chunkUrl: data.batchGetIntermediateMultipartUploadUrl }
      }
    }
  ),
  graphql(
    MutationTrackLogout,
    {
      props: (props) => ({
        trackLogout: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(HeaderMenu))
