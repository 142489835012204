import gql from 'graphql-tag'

export default gql(`
    mutation($updateData: [UpdateAssetTypeInput] $project: String ) {
        batchUpdateAssetTypes(
            input: $updateData
            project: $project
        ) {
            id
            title
            position
            isActive
            isDefault
            isLveToVodSupported
            isMatchRelated
            videoSection
            contentSection
            programSection
            authorSection
            matchSection
            usedCount
            entertainmentMetadata
            creditSection
            parentalRatingSection
            assetTypeCategory
            metaFields{
                displayName
                type
                isRequired
            }
            icon{
                id
                url
                fileName
                name
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            draftIcon{
                id
                url
                fileName
                name
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
        }
    }`
)
