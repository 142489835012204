import gql from 'graphql-tag'

export default gql(`
    mutation adminChangePassword( $email: String! $password: String!) {
        adminChangePassword(
            email: $email
            password: $password
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
