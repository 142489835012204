import gql from 'graphql-tag'

export default gql(`
query getBucketGroup($id: ID!) {
    getBucketGroup(id: $id) {
        id
        name
        type
        key
        bucketGroupItems{
            id
            position
            bucketId
            bucket{
                id
                isArchived
                key
                displayName

            }
        }
    }
}`)
