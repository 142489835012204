import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'
import { Icon, message } from 'antd'
//

import Input from './../../components/ui/dataEntry/inputs/Input'
import InputNumber from './../../components/ui/dataEntry/inputs/InputNumber'
import TextAreaInput from './../../components/ui/dataEntry/inputs/TextArea'
import DropDown from '../../components/ui/dataEntry/inputs/DropDown'
import { utilityService } from '../../services/UtilityService'

const parentCompoent = 'drm-container'
function DrmManualGeneralDetails ({ onContentKeyChange, onIvChange, onDrmEntitlementChange, onSelectStreamType, onChangeDuration, state, streamList, token, isUpdateDisabled }) {
  const copyIcon = (<Icon type='copy' onClick={() => {
    if (token) {
      utilityService.copyToClipBoard(token, '')
    } else {
      message.warn('Please generate Token before copying')
    }
  }} />)
  return (
    <div className='general-details'>
      <div className='details-left'>
        <Input
          title='Content Key'
          placeholder='Enter Content Key'
          inputName='key'
          value={state.contentKey}
          handleChange={onContentKeyChange}
          readOnly={isUpdateDisabled}
        />
        <Input
          title='IV'
          placeholder='Enter IV'
          inputName='iv'
          value={state.iV}
          handleChange={onIvChange}
          readOnly={isUpdateDisabled}
        />

        <TextAreaInput
          title='DRM Entitlement Message'
          placeholder='Enter Entitlement Message'
          inputName='drmEntitlementMessage'
          value={state.drmEntitlementMessage}
          handleChange={onDrmEntitlementChange}
          isEditable={!isUpdateDisabled}
        />

        <div className='input-field'>
          <label className='input-title'>Stream Type</label>
          <DropDown
            options={streamList || []}
            displayParam='name'
            keyParam='id'
            valueParam='id'
            placeHolder='Select Stream Type'
            parent={parentCompoent}
            selectedValue={(state.streamType && state.streamType.id) || undefined}
            onOptionSelect={onSelectStreamType}
            disabled={isUpdateDisabled}
            allowClear
          />
        </div>
        <div className='input-field-number'>
          <InputNumber
            limit={16}
            title={'Duration in hours'}
            placeholder={`Enter Duration`}
            inputName='duration'
            value={state.duration}
            handleChange={onChangeDuration}
            errorMessage={''}
            readOnly={isUpdateDisabled}
          />
        </div>

      </div>
      <div className='details-right'>
        <TextAreaInput
          title='Token'
          placeholder=''
          inputName='drmToken'
          value={token}
          isEditable={false}
          icon={copyIcon}
        />
      </div>

    </div>
  )
}

DrmManualGeneralDetails.propTypes = {
  /** details of Drm. */
  state: PropTypes.object.isRequired
}

export default withApollo(compose(

)(DrmManualGeneralDetails))
