import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getCompetition(id: $id) {
        id
        externalId
        ocId
        opId
        title
        competitionCode
        displayOrder
        country
        countryExternalId
        isFriendly
        competitionFormat
        type
        lastUpdated
        tournamentCalendar{
            title
            id
        }
        competitionType
        source
        sport{
            id
            externalId
            title
        }
        year
        media{
            id
            fileName
            aspectRatio {
                aspectRatio
                ratio
                title          
                resolutions {
                  url
                }
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
        }
        customTitle
        customCompetitionCode
        customCountry
        customCompetitionFormat
        customType
        customCompetitionType
        customSource
        tags{
            name
            key
            type
        }    
        logo {
            id
            url
            fileName
            aspectRatio {
              title
              aspectRatio
              resolutions {
                url
                id
                key
              }
              name
            }
            settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2
            }
            updatedAt
            type
            thumbnails
            status
            source
            module
            name
            mediaId
            isArchived
            fileSize
            drmContent
            duration
            createdAt
            createdBy
            adPositions
        }

          defaultTeamImage {
            id
            url
            fileName
            aspectRatio {
              title
              aspectRatio
              resolutions {
                url
                id
                key
              }
              name
            }
            settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2
            }
            updatedAt
            type
            thumbnails
            status
            source
            module
            name
            mediaId
            isArchived
            fileSize
            drmContent
            duration
            createdAt
            createdBy
            adPositions
        } 
    }    
}`)
