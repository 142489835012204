import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListAssetType from '../../../graphQL/admin/asset/listAssetTypes'
import QueryGetImageType from '../../../graphQL/admin/media/listImageType'
import MutationcreateImageType from '../../../graphQL/admin/media/createImageType'
import MutationDeleteImageType from '../../../graphQL/admin/media/deleteImageTypes'
import MutationUpdateImageType from '../../../graphQL/admin/media/updateImageType'
import MutationBatchUpdateImageType from '../../../graphQL/admin/media/batchUpdateImageTypes'

import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
// import { utilityService } from '../../../services/UtilityService'
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateImageTypeModal from '../../../components/ui/dataEntry/other/CreateImageTypeModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import { utilityService } from '../../../services/UtilityService'

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

let primaryImageType

class ImageTypeList extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    this.state = {
      openedType: null,
      isLoading: !(props.listImageTypes && props.listImageTypes.length),
      isShowCreateImageTypeModal: false,
      showDeleteImageTypeModal: false,
      selectedImageType: undefined,
      isModalLoading: false,
      isEdit: false,
      isDragStart: false,
      expandedRowKeys: [],
      listImageTypes: props.listImageTypes && props.listImageTypes.length ? _.cloneDeep(props.listImageTypes) : []
    }
    this.columns = [
      {
        title: 'Image Type',
        dataIndex: 'name',
        key: 'name',
        width: '37%'
      },
      {
        title: '# of Images',
        dataIndex: 'usageCount',
        width: '50%',
        key: 'usageCount',
        render: (usageCount) => this.renderImageTypeCount(usageCount)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.innerColumns = [
      {
        title: 'Enabled for Asset Type',
        dataIndex: 'title',
        key: 'title',
        width: '90%',
        render: (title) => <div className='inner-header'>{title}</div>
      }
    ]
    this.enabledAssetTypes = [
      {
        title: 'Asset Types',
        dataIndex: 'assetType',
        key: 'assetType',
        width: '40%'
      }, {
        title: 'Minimum Images',
        dataIndex: 'minCount',
        key: 'minCount',
        width: '35%'
      },
      {
        title: 'Crop Position',
        dataIndex: 'cropPosition',
        key: 'cropPosition',
        width: '25%'
      }
    ]
    this.aspectRatioSettings = [
      {
        title: 'Aspect Ratios',
        dataIndex: 'title',
        key: 'title',
        width: '50%'
      },
      {
        title: 'Dimension',
        dataIndex: 'width',
        key: 'width',
        width: '50%',
        render: (width, data) => <div>{`${width}x${data.height}`}</div>
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && (!_.isEqual(newProps.listImageTypes, this.props.listImageTypes) || !_.isEqual(newProps.listImageTypes, this.state.listImageTypes))) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, listImageTypes: _.cloneDeep(newProps.listImageTypes).sort((a, b) => (a.position - b.position)) })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.listImageTypes, this.props.listImageTypes)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && _.isEqual(newProps.listImageTypes, this.props.listImageTypes)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, listImageTypes: _.cloneDeep(newProps.listImageTypes).sort((a, b) => (a.position - b.position)) })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'transfer', 'delete', 'duplicate']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedType) {
        this.setState({ openedType: null })
      }
    }

    showCreateImageTypeModal = (isShowCreateImageTypeModal) => {
      // if (isShowCreateImageTypeModal) {
      this.setState({ croppedUrl: '', croppedDraftUrl: '', isShowCreateImageTypeModal, selectedImageType: null, isEdit: false })
      // } else {
      //   this.setState({ croppedUrl: '',croppedDraftUrl:'', selectedImage: null, selectedDraftImage: null, isShowCreateImageTypeModal, selectedImageType: null })
      // }
    }

    toggleShowOptions = id => {
      if (this.state.openedType === id) {
        this.setState({ openedType: null })
      } else {
        this.setState({ openedType: id })
      }
    }

    handleModifyImageType = (newData) => {
      // debugger
      const { listImageTypes, onHistoryUpdate } = this.props
      const { selectedImageType } = this.state
      this.setState({ isModalLoading: true })
      if (selectedImageType) {
        this.props.updateImageType(newData).then(() => {
          onHistoryUpdate()
          this.setState({ isShowCreateImageTypeModal: false, selectedImageType: null, isModalLoading: false })
        }, (error) => {
          this.setState({ isModalLoading: false })
          utilityService.handleError(error)
        })
      } else {
        newData.position = listImageTypes.length + 1
        this.props.createImageType(newData).then(() => {
          onHistoryUpdate()
          this.setState({ isShowCreateImageTypeModal: false, selectedImageType: null, isModalLoading: false })
        }, (error) => {
          this.setState({ isModalLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { listImageTypes } = this.props
      const selectedImageType = listImageTypes.find(item => item.id === id)
      if (!selectedImageType) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ isShowCreateImageTypeModal: true, selectedImageType, isEdit: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteImageTypeModal: true, selectedImageType })
      } else if (selectedOption.type === 'duplicate') {
        this.setState({ isShowCreateImageTypeModal: true, selectedImageType, isEdit: false })
      }
      this.setState({ openedType: null })
    }

    loadMoreAsset = () => {
      if (!this.props.nextToken || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreTypes(this.props.nextToken))
    }

    toggleDeleteWarningMessage = () => {
      this.setState({ isDeleteSuccess: true }, () => {
        setTimeout(() => {
          this.setState({ showDeleteImageTypeModal: false, isDeleteSuccess: false })
        }, 100)
      })
    }

    onDeleteImageTypeModal = () => {
      this.setState({ isModalLoading: true }, () => {
        let { selectedImageType } = this.state
        const variable = [ selectedImageType.id ]
        this.props.deleteImageTypes(variable).then(() => {
          this.props.onHistoryUpdate()
          this.setState({ isDeleteSuccess: true }, () => {
            this.setState({ isModalLoading: false, showDeleteImageTypeModal: false, isDeleteSuccess: false })
          })
        }, (error) => {
          this.setState({ isModalLoading: false, showDeleteImageTypeModal: false, isDeleteSuccess: false })
          utilityService.handleError(error)
        })
      })
    }

    renderImageTypeCount = (count) => {
      if (!count) {
        return '0'
      } else {
        return count
      }
    }

    onDragEnd = (result) => {
      // const { listImageTypes, onHistoryUpdate } = this.props
      const startIndex = result.source.index
      const endIndex = result.destination.index
      if (endIndex === 0) {
        message.warn('Image type cannot be moved on top of primary image type')
        return
      }
      let { listImageTypes } = this.state
      const [ removed ] = listImageTypes.splice(startIndex, 1)
      removed.trackPosChange = true
      listImageTypes.splice(endIndex, 0, removed)
      listImageTypes = listImageTypes.map((item, index) => {
        item.position = index + 1
        return item
      })
      const changedTypes = listImageTypes.filter(imageType => {
        const selectedItem = this.props.listImageTypes.find(innerItem => innerItem.id === imageType.id)
        return selectedItem.position !== imageType.position
      })

      const variables = changedTypes.map(details => {
        delete details.__typename

        details.enabledAssetTypes = (details.enabledAssetTypes || []).map(item => {
          delete item.__typename
          delete item.isEnable
          return item
        })

        details.aspectRatios = (details.aspectRatios || []).map(item => {
          delete item.__typename
          item.resolutions = (item.resolutions || []).map(innerItem => {
            delete innerItem.__typename
            return innerItem
          })
          return item
        })
        return details
      })

      this.props.batchUpdateImageTypes({ input: variables }).then(() => {
        this.props.onHistoryUpdate()
      })

      this.setState({ listImageTypes, isDragStart: false })
      // const changedTypes = listImageTypes.filter(assetType => {
      //   const selectedItem = listImageTypes.find(innerItem => innerItem.id === assetType.id)
      //   return selectedItem.position !== assetType.position
      // })
      // const variables = (changedTypes || []).map(item => {
      //   return {
      //     id: item.id,
      //     position: item.position,
      //     title: item.title,
      //     authorSection: item.authorSection,
      //     contentSection: item.contentSection,
      //     videoSection: item.videoSection,
      //     programSection: item.programSection,
      //     isActive: item.isActive,
      //     isMatchRelated: item.isMatchRelated,
      //     isLveToVodSupported: item.isLveToVodSupported,
      //     isDefault: item.isDefault,
      //     icon: item.icon ? item.icon.id : null,
      //     matchSection: item.matchSection,
      //     draftIcon: item.draftIcon ? item.draftIcon.id : null,
      //     trackPosChange: item.trackPosChange,
      //     metaFields: item.metaFields === null ? null : (item.metaFields || []).map(meta => {
      //       delete meta.__typename
      //       delete meta.id
      //       return meta
      //     })
      //   }
      // })
      // this.props.batchUpdateAssetTypes({ updateData: variables }).then(() => {
      //   onHistoryUpdate()
      // })
    }

    onDragStart = () => {
      const { expandedRowKeys } = this.state
      if (expandedRowKeys.length) { this.setState({ expandedRowKeys: [], isDragStart: true }) } else { this.setState({ isDragStart: true }) }
    }

     dragableBodyRow = (item) => {
       const { isDragStart } = this.state
       const { index } = item
       return <Draggable key={item['data-row-key']} draggableId={item['data-row-key']} index={index} isDragDisabled={item['data-row-key'] === primaryImageType}>
         { (provided, snapshot) => {
           return <tr className={`ant-table-row${isDragStart ? ' dragEnable' : ''}`}
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={getItemStyle(
               snapshot.isDragging,
               provided.draggableProps.style
             )}
           >
             {item.children}
           </tr>
         }}</Draggable>
     }

     handleRowExpand = (record) => {
       this.setState(prevState =>
         prevState.expandedRowKeys.includes(record.id)
           ? {
             expandedRowKeys: prevState.expandedRowKeys.filter(
               key => key !== record.id
             )
           }
           : { expandedRowKeys: [...prevState.expandedRowKeys, record.id] }
       )
     }

    renderData = (id) => {
      let availableOptions = []
      const { openedType } = this.state
      const { listImageTypes } = this.props
      const selectedImageType = listImageTypes.find(item => item.id === id)
      availableOptions = [ ...options ]
      if (selectedImageType && !(selectedImageType.isDefault || selectedImageType.usageCount || this.isDeleteDisabled)) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(1, 1)
      }
      if (this.isCreateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedType === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    renderEnabledAssetTypes = (data) => {
      return <Table
        className={`inner-table`}
        rowKey={record => record.id}
        columns={this.enabledAssetTypes}
        dataSource={data}
        pagination={false}
      />
    }

    renderAspectRatioSettings = (data) => {
      let newAspectData = []
      for (let i = 0; i < (data || []).length; i++) {
        for (let j = 0; j < (data[i].resolutions || []).length; j++) {
          const newData = Object.assign({}, data[i].resolutions[j])
          newData.title = data[i].title
          newAspectData.push(newData)
        }
      }

      return <Table
        className={`inner-table`}
        rowKey={record => record.id}
        columns={this.aspectRatioSettings}
        dataSource={newAspectData}
        pagination={false}
      />
    }

    renderInnerRows = (data) => {
      return <React.Fragment>
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'Enabled for Asset Type' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderEnabledAssetTypes(data.enabledAssetTypes)}
        />
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: 'Aspect Ratio Settings' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderAspectRatioSettings(data.aspectRatios)}
        />
      </React.Fragment>
    }

    render () {
      const { isLoading, nextToken, onHistoryUpdate, listAssetTypes, project } = this.props
      const { isPaginating, isShowCreateImageTypeModal, showDeleteImageTypeModal, isModalLoading, selectedImageType,
        isEdit, expandedRowKeys, listImageTypes, isDeleteSuccess } = this.state

      const components = {
        body: {
          row: this.dragableBodyRow
        }
      }
      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['DATA_MANAGER']
            this.isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal asset-type-container'>
              <div className='admin-list'>
                <FixedTableHeader columnCellList={this.columns} isExpandable />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={nextToken}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                      <Droppable droppableId={'#AssetType_123'}>
                        { (droppableProvided, droppableSnapshot) => (

                          <div className='asset-type-table-container'
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            <Table
                              className={`general-table ${isPaginating ? 'paginating' : ''}`}
                              rowKey={record => record.id}
                              columns={this.columns}
                              dataSource={listImageTypes}
                              pagination={false}
                              expandedRowRender={(data) => this.renderInnerRows(data)}
                              expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => this.handleRowExpand(record)}
                              components={components}
                              onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow
                              })} />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={() => this.showCreateImageTypeModal(true, true)}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={this.isCreateDisabled}
                />
              </div>
              <CreateImageTypeModal
                isVisible={isShowCreateImageTypeModal}
                handleCancel={() => this.showCreateImageTypeModal(false)}
                handleSubmit={this.handleModifyImageType}
                selectedType={selectedImageType || null}
                isEdit={isEdit}
                isSubmitDisabled={isEdit ? this.isUpdateDisabled : this.isCreateDisabled}
                isDeleteSuccess={isDeleteSuccess}
                onHistoryUpdate={onHistoryUpdate}
                listAssetTypes={listAssetTypes}
                isLoading={isModalLoading}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteImageTypeModal}
                title={'DELETE IMAGE TYPE'}
                firstMessage={'Are you sure you want to delete this Image Type?'}
                secondMessage={'This action cannot be undone.'}
                itemName={selectedImageType ? selectedImageType.name : ''}
                rightButtonText={'Delete'}
                handleCancel={this.toggleDeleteWarningMessage}
                handleSubmit={this.onDeleteImageTypeModal}
                isSubmitButtonDisabled={false}
                isCancelButtonDisabled={false}
                isLoading={isModalLoading}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

ImageTypeList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListAssetType,
    {
      options: ({ project }) => {
        const variables = { limit: 500, nextToken: null, filter: null, project }
        return {
          fetchPolicy: 'cache-first',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes
        }
      }
    }
  ),
  graphql(
    QueryGetImageType,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        return {
          listImageTypes: props.data.listImageTypes && props.data.listImageTypes.length ? props.data.listImageTypes : [],
          isLoading: props.data.loading
        }
      }
    }
  ),
  graphql(
    MutationcreateImageType,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { createImageType } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetImageType, variables: { project } }))
              if (cacheData && cacheData.listImageTypes && cacheData.listImageTypes.length) {
                cacheData.listImageTypes.splice(0, 0, createImageType)
              } else {
                cacheData.listImageTypes = [createImageType]
              }
              cache.writeQuery({
                query: QueryGetImageType,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        createImageType: (input) => {
          const variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteImageType,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deleteImageTypes } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetImageType, variables: { project } }))
              if (cacheData && cacheData.listImageTypes && cacheData.listImageTypes.length) {
                cacheData.listImageTypes = cacheData.listImageTypes.filter(imageType => {
                  const index = deleteImageTypes.findIndex(deletedContent => deletedContent === imageType.id)
                  return index === -1
                })
              }
              cache.writeQuery({
                query: QueryGetImageType,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        deleteImageTypes: (variables) => {
          return props.mutate({
            variables: { id: variables, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateImageType,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { updateImageType } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetImageType, variables: { project } }))
              if (cacheData && cacheData.listImageTypes && cacheData.listImageTypes.length) {
                const index = cacheData.listImageTypes.findIndex((item) => item.id === updateImageType.id)
                cacheData.listImageTypes[index] = updateImageType
              }
              cache.writeQuery({
                query: QueryGetImageType,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        updateImageType: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchUpdateImageType,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { batchUpdateImageTypes } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetImageType, variables: { project } }))
              if (cacheData && cacheData.listImageTypes && cacheData.listImageTypes.length) {
                cacheData.listImageTypes = cacheData.listImageTypes.map(imageType => {
                  const index = batchUpdateImageTypes.findIndex((item) => item.id === imageType.id)
                  return index > -1 ? batchUpdateImageTypes[index] : imageType
                })
              }
              cache.writeQuery({
                query: QueryGetImageType,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        batchUpdateImageTypes: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(ImageTypeList))
