import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, message } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateWidget from './../../../../graphQL/appManager/createAppWidget'
import MutationDuplicateWidget from './../../../../graphQL/appManager/duplicateAppWidget'
import MutationUpdateWidget from './../../../../graphQL/appManager/editAppWidget'
import QueryWidgetList from './../../../../graphQL/appManager/listWidgets'
import MutationUpdateMedia from './../../../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from './../../../../graphQL/content/updateMediaSettings'

import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'
import { getCropImageUrl } from '../../../../util/util'
import CreateAppWidgetContainer from './CreateAppWidgetContainer'

const fieldTypeList = [
  {
    id: 'STRING',
    name: 'String',
    value: 'STRING'
  },
  {
    id: 'BUCKET',
    name: 'Bucket',
    value: 'BUCKET'
  },
  {
    id: 'JSON',
    name: 'JSON',
    value: 'JSON'
  },
  {
    id: 'NUMBER',
    name: 'Number',
    value: 'NUMBER'
  },
  {
    id: 'IMAGE',
    name: 'Image',
    value: 'IMAGE'
  },
  {
    id: 'BOOLEAN',
    name: 'Boolean',
    value: 'BOOLEAN'
  },
  {
    id: 'RICHTEXT',
    name: 'Rich Text',
    value: 'RICHTEXT'
  },
  {
    id: 'JSONARRAY',
    name: 'JSON Array',
    value: 'JSONARRAY'
  }
]

class CreateWidgetModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSaving: false,
      // settings: [],
      isEditVisible: false,
      isCropVisible: false,
      selectedTags: [],
      filteredConfigName: '',
      filteredConfigIsEnabled: true,
      selectedType: '',
      app: [],
      group: '',
      key: '',
      itemSelected: {},
      isEnable: false,
      isTagCreating: false,
      systemTags: [],
      showWarning: false,
      shouldSave: false,
      streamField: [],
      streamFieldValue: [],
      tempPropertyName: '',
      tempFieldType: null,
      isJsonValid: true,
      shortDescription: '',
      isJsonArrayValid: true,
      jsonInvalidEntries: [],
      jsonArrayInvalidEntries: [],
      isJsonArrayVisible: false,
      jsonConfig: null,
      jsonSelectedMeta: null
    }
  }

  resetState = () => {
    this.setState({
      settings: [],
      isSaving: false,
      isEditVisible: false,
      isCropVisible: false,
      isShowImageModal: false,
      streamField: [],
      filteredConfigName: '',
      filteredConfigIsEnabled: false,
      selectedImage: null,
      app: [],
      group: '',
      key: '',
      isEnable: false,
      showWarning: false,
      shouldSave: false,
      ignoreWarning: false,
      tempFieldType: null,
      tempPropertyName: '',
      isJsonValid: true,
      shortDescription: '',
      isImageModified: false,
      isJsonArrayValid: true,
      selectedJsonArrayTableValue: null,
      jsonInvalidEntries: [],
      jsonArrayInvalidEntries: []
    })
  }

  componentDidMount = () => {
    if (this.props.details && this.props.isFromEdit) {
      const selectedApps = this.Props.details.app ? this.props.details.app.map(item => item && item.id) : []
      // const selectedTags = this.props.details.tags ? this.props.details.tags.filter(item => item) : []
      let name = _.get(this.props.details, 'title')
      const widgetName = this.props.isDuplicate ? `Copy of ${name}` : name
      this.setState({
        isEditVisible: this.props.isVisible,
        filteredConfigName: widgetName,
        filteredConfigIsEnabled: this.props.details.isActive,
        key: this.props.isDuplicate ? utilityService.slugifyString('Copy Of ' + _.get(this.props.details, 'key')).slice(0, 125).toLocaleUpperCase() : this.props.details ? _.get(this.props.details, 'key') : '',
        // key: _.get(this.props.details, 'key'),
        app: this.props.details && selectedApps ? selectedApps : [],
        streamField: this.props.details && this.props.details.fields && this.props.details.fields.length > 0 ? _.sortBy(this.props.details.fields, 'position') : [],
        streamFieldValue: this.props.details && this.props.details.fieldValues && this.props.details.fieldValues.length > 0 ? this.handleFieldsValueCoversion(this.props.details.fieldValues || []) : [],
        selectedImage: this.props.details && this.props.details.widgetPreview ? this.props.details.widgetPreview : null,
        shortDescription: this.props.details && this.props.details.description ? this.props.details.description : ''
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.details && !this.state.isSaving && !this.state.isEnable && nextProps.isFromEdit) {
      const selectedApps = nextProps.details.app ? nextProps.details.app.map(item => item && item.id) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      let name = _.get(this.props.details, 'title')
      const widgetName = this.props.isDuplicate ? `Copy of ${name}` : name
      this.setState({
        isEditVisible: nextProps.isVisible,
        filteredConfigName: widgetName,
        filteredConfigIsEnabled: nextProps.details.isActive,
        key: nextProps.isDuplicate ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'key')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? _.get(nextProps.details, 'key') : '',
        // key: nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && selectedApps ? selectedApps : [],
        streamField: nextProps.details && nextProps.details.fields && nextProps.details.fields.length > 0 ? _.sortBy(nextProps.details.fields, 'position') : [],
        streamFieldValue: nextProps.details && nextProps.details.fieldValues && nextProps.details.fieldValues.length > 0 ? this.handleFieldsValueCoversion(nextProps.details.fieldValues || []) : [],
        selectedImage: nextProps.details && nextProps.details.widgetPreview ? nextProps.details.widgetPreview : null,
        shortDescription: nextProps.details && nextProps.details.description ? nextProps.details.description : ''
      })
    }
    if (nextProps.systemTags) {
      // const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: nextProps.systemTags })
    }
    this.setState({ isEditVisible: nextProps.isVisible })
  };

  resetState=() => {
    this.setState({
      isEditVisible: false,
      filteredConfigName: '',
      filteredConfigIsEnabled: false,
      key: '',
      app: [],
      streamFieldValue: [],
      streamField: [],
      tempFieldType: null,
      tempPropertyName: '',
      isJsonValid: true,
      selectedImage: null,
      shortDescription: '',
      isEnable: false,
      isJsonArrayValid: true
    })
  }

  handleFieldsValueCoversion = (fieldValues) => {
    let pageConfigDetails = (fieldValues || []).map(field => {
      let value
      if (field && field.configField && field.configField.type === 'IMAGE') {
        value = field.image ? field.image : ''
      } else if (field && field.configField && field.configField.type === 'BUCKET') {
        value = field.bucket ? field.bucket : ''
      } else {
        value = field.value
      }
      return {
        id: (field && field.configField && field.configField.id) || '',
        isRequired: (field && field.configField && field.configField.isRequired) || false,
        name: (field && field.configField && field.configField.name) || '',
        type: (field && field.configField && field.configField.type) || '',
        useAsTitle: (field && field.configField && field.configField.useAsTitle),
        value: value
      }
    })

    pageConfigDetails = (pageConfigDetails || []).filter(item => item.id)
    return pageConfigDetails
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { selectedTags } = this.state
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'APP_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags, isTagCreating: true })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            this.setState({ selectedTags, isEnable: true, isTagCreating: false })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}content_bank`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags, isEnable: true, isTagCreating: false })
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
        }
      }
    }
    this.setState({ selectedTags, isEnable: true })
  }

  handleTextChange = (event) => {
    const { value } = event.target
    const { isFromEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.filteredConfigName || '')
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isFromEdit) {
      const key = utilityService.slugifyString(value).slice(0, 125).toLocaleUpperCase()
      this.setState({ filteredConfigName: value, key })
    } else {
      this.setState({ filteredConfigName: value, isEnable: true })
    }
    // this.setState({ filteredConfigName: event.target.value, isEnable: true})
  }

  handleIsEnabledChange = (value) => {
    this.setState({ filteredConfigIsEnabled: value, isEnable: true })
  }

  handleIdChange =(e) => {
    const { isDuplicate } = this.props
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(e.target.value) ? e.target.value.toLocaleUpperCase() : this.state.key
    if (isDuplicate) {
      this.setState({ key, isEnable: true })
    } else {
      this.setState({ key })
    }
  }

  handleAppChange = (option) => {
    let apps = this.props.appList.filter(item => option.includes(item.id)).map(item => item.id)
    // apps.sort(function (a, b) {
    //   return option.indexOf(a.name) - option.indexOf(b.name)
    // })
    // details.countries = countries

    this.setState({ app: apps, isEnable: true })
  }

  handleTypeChange = (option) => {
    this.setState({ selectedType: option, isEnable: true })
  }

  handleGroupChange = (option) => {
    this.setState({ group: option, isEnable: true })
  }

  onItemSelected = (itemSelected) => {
    this.setState({ itemSelected })
  }

  handleSave = () => {
    const { isFromEdit, isDuplicate } = this.props
    const { filteredConfigName, filteredConfigIsEnabled, key, app, streamField, selectedImage, streamFieldValue, shortDescription, isImageModified,
      jsonInvalidEntries, jsonArrayInvalidEntries } = this.state
    if (!_.isEmpty(jsonInvalidEntries)) {
      message.error('Please enter valid JSON Value')
      // this.props.updateForceSaveState(false)
      return
    }
    if (!_.isEmpty(jsonArrayInvalidEntries)) {
      message.error('Please enter valid JSON ARRAY Value')
      // this.props.updateForceSaveState(false)
      return
    }
    const notValidField = ((streamField || []).find(item => !item.name || item.name === '' || item.name === null))
    if (!_.isEmpty(notValidField)) {
      message.warn('Please enter valid Property Name for all properties before saving')
      return
    }

    let duplicateItem;
    (streamField || []).some(field => {
      duplicateItem = (streamField || []).find(innerField => ((innerField.name === field.name && innerField.type === field.type) && field.id !== innerField.id))
      if (!_.isEmpty(duplicateItem)) {
        return true
      }
    })
    if (!_.isEmpty(duplicateItem)) {
      message.error('Please remove duplicate Properties before saving')
      return
    }

    const stringField = (streamField || []).find(item => item.type === 'STRING')
    if (_.isEmpty(stringField)) {
      message.warn('Please add atleast one string type property')
      return
    } else {
      const useAsTitleSet = (streamField || []).find(item => {
        if (item && item.type === 'STRING' && item.useAsTitle) {
          return item
        }
      })
      if (_.isEmpty(useAsTitleSet)) {
        message.warn('Please set a useAsTitle for String field')
        return
      } else {
        if (!useAsTitleSet.isRequired) {
          message.warn('Please set a useAsTitle for String field as mandatory')
          return
        }
      }
    }

    this.setState({ isLoading: true })
    // this.props.handleSubmit(newTitle, key, selectedType)

    // const apps = (app || []).map(item => item.id)
    const newFieldValues = (streamFieldValue || []).map(fieldValue => {
      const newObject = {
        configField: fieldValue.id,
        image: fieldValue.type === 'IMAGE' && fieldValue.value ? fieldValue.value.id : '',
        bucket: fieldValue.type === 'BUCKET' && fieldValue.value ? fieldValue.value.id : '',
        value: fieldValue.type !== 'BUCKET' && fieldValue.type !== 'IMAGE' ? fieldValue.value : '',
        project: this.props.project
      }
      return newObject
    })
    const newStreamFields = (streamField || []).map(field => {
      const newField = { ...field }
      newField.name = newField.name ? (newField.name).trim() : ''
      newField.isUpdatable = !!(newField && newField.isUpdatable)
      delete newField['__typename']
      if (newField.hasOwnProperty('value')) {
        delete newField['value']
      }
      return newField
    })

    if (isFromEdit && !isDuplicate) {
      if (isImageModified) {
        this.props.updateImage(selectedImage.id, selectedImage.name, selectedImage.tags ? selectedImage.tags.map(item => item.key || item.id) : [], selectedImage.settings).then((response) => {
          this.props.updateAppWidget(filteredConfigName, key, app, newStreamFields, this.props.selectedWidget.id, newFieldValues, selectedImage.id, shortDescription, filteredConfigIsEnabled).then(() => {
            message.success('Widget updated successfully')
            this.setState({ isLoading: false, isEnable: false, isImageModified: false, croppedUrl: '', selectedImage: null })
            this.props.refetchHistory()
            this.handleCancel()
          }, error => {
            utilityService.handleError(error)
            this.setState({ isLoading: false, isEnable: false })
            this.handleCancel()
          })
        })
      } else {
        this.props.updateAppWidget(filteredConfigName, key, app, newStreamFields, this.props.selectedWidget.id, newFieldValues, selectedImage.id, shortDescription, filteredConfigIsEnabled).then(() => {
          message.success('Widget updated successfully')
          this.setState({ isLoading: false, isEnable: false })
          this.props.refetchHistory()
          this.handleCancel()
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false, isEnable: false })
          this.handleCancel()
        })
      }
    } else if (isDuplicate) {
      if (isImageModified) {
        this.props.updateImage(selectedImage.id, selectedImage.name, selectedImage.tags ? selectedImage.tags.map(item => item.key || item.id) : [], selectedImage.settings).then((response) => {
          this.props.duplicateAppWidget(filteredConfigName, key, app, newStreamFields, this.props.selectedWidget.id, newFieldValues, selectedImage.id, shortDescription).then(() => {
            message.success('Widget Created successfully')
            this.setState({ isLoading: false, isEnable: false, isImageModified: false, croppedUrl: '', selectedImage: null })
            this.props.refetchHistory()
            setTimeout(() => {
              this.props.refetchNewWidgetList()
            }, 1000)
            this.handleCancel()
          }, error => {
            utilityService.handleError(error)
            if (error.message.includes('Widget with the KEY already exists')) {
              this.setState({ isLoading: false, isEnable: false })
              return
            }
            this.setState({ isLoading: false, isEnable: false })
            this.handleCancel()
          })
        })
      } else {
        this.props.duplicateAppWidget(filteredConfigName, key, app, newStreamFields, this.props.selectedWidget.id, newFieldValues, selectedImage.id, shortDescription).then(() => {
          message.success('Widget Created successfully')
          this.setState({ isLoading: false, isEnable: false })
          this.props.refetchHistory()
          setTimeout(() => {
            this.props.refetchNewWidgetList()
          }, 1000)
          this.handleCancel()
        }, error => {
          utilityService.handleError(error)
          if (error.message.includes('Widget with the KEY already exists')) {
            this.setState({ isLoading: false, isEnable: false })
            return
          }
          this.setState({ isLoading: false, isEnable: false })
          this.handleCancel()
        })
      }
    } else {
      this.props.createAppWidget(filteredConfigName, key, app, newStreamFields, newFieldValues, selectedImage.id, shortDescription, filteredConfigIsEnabled).then((res) => {
        if (res && res.data && res.data.createAppWidget) {
          const mediaId = res.data.createAppWidget.widgetPreview.id
          const widgetId = res.data.createAppWidget.id
          const name = res.data.createAppWidget.widgetPreview.name
          const tags = res.data.createAppWidget.widgetPreview.tags
          const settings = (selectedImage.settings).map((item) => {
            if (item.__typename) {
              delete (item.__typename)
            }
            return item
          })
          if (isImageModified) {
            this.props.updateImageSettings(mediaId, widgetId)
            this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then((response) => {
              // this.props.handleSubmit(data.createAssetType)
              // onHistoryUpdate()
              this.setState({ selectedImage: null, croppedUrl: '', isImageModified: false })
            }, error => {
              this.setState({ isLoading: false })
              utilityService.handleError(error)
            })
          }
          message.success('Widget created successfully')
          this.setState({ isLoading: false, isEnable: false })
          this.props.refetchHistory()
          setTimeout(() => {
            this.props.refetchNewWidgetList()
          }, 1000)
          // this.props.onSelectConfigGroup(null, null, res.data.createAppConfig.id)
          this.handleCancel()
        }
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    }
  }

  handleCancel = () => {
    this.resetState()
    this.props.hideWidgetModal()
  }

  forceSave=() => {
    this.setState({ shouldSave: true, ignoreWarning: true, showWarning: false }, () => this.handleSave())
  }

  discardChanges=() => {
    this.setState({ showWarning: false })
  }

  // reorder = (startIndex, endIndex) => {
  //   const { streamField } = this.state
  //   const result = Array.from(streamField)
  //   const [removed] = result.splice(startIndex, 1)
  //   result.splice(endIndex, 0, removed)
  //   this.setState({ streamField: result, isEnable: true })
  // }

  reorder = (startIndex, endIndex) => {
    const { streamField } = this.state
    const result = Array.from(streamField)
    result[startIndex].position = endIndex
    result[endIndex].position = startIndex
    const [removed] = result.splice(startIndex, 1)
    removed.trackPosChange = true
    result.splice(endIndex, 0, removed)
    result.map((item, index) => {
      item.position = index + 1
      return item
    })
    // this.props.handleRatingChange(result)
    this.setState({ streamField: result, isEnable: true })
  }

  onCloseField = (index) => {
    const { streamField } = this.state
    // const index = streamField.findIndex(item => item.id === id)
    streamField.splice(index, 1)
    const duplicateItem = streamField.filter(item => {
      const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
      return tempIndex !== -1
    })
    const isNoDuplicate = !!duplicateItem.length
    this.setState({ streamField, isEnable: true, isNoDuplicate })
  }

  addFieldObjects = () => {
    const { streamField, streamFieldValue, tempFieldType, tempPropertyName } = this.state
    const tempTrimmed = tempPropertyName.trim()
    const id = uuidv4()
    if (!tempFieldType || !tempTrimmed) {
      message.error('Please enter valid property name and type')
      return
    }
    const duplicateItem = streamField.find(item => item.name === tempTrimmed && item.type === tempFieldType)
    const newFieldObject = {
      name: tempTrimmed,
      type: tempFieldType,
      isRequired: false,
      id,
      useAsTitle: false,
      isUpdatable: false,
      position: streamField && streamField.length ? streamField[streamField.length - 1].position + 1 : 1
    }
    // const newFieldObjectValue = {
    //   configField: newFieldObject,
    //   value: null,
    //   bucket: '',
    //   image: ''
    // }
    if (!_.isEmpty(duplicateItem)) {
      message.error('Duplicate Property')
      return
    }
    streamField.push(newFieldObject)
    newFieldObject.value = null
    streamFieldValue.push(newFieldObject)
    // fieldValues.push(newFieldObjectValue)
    this.setState({ streamField, streamFieldValue, isEnable: true, tempFieldType: null, tempPropertyName: '' })
  }

  onChangePropertyName = (event, index) => {
    const { streamField } = this.state
    const { value } = event.target
    const duplicateItem = streamField.find((item, innerIndex) => ((item.name.trim() === value.trim() || item.name === value) && item.type === streamField[index].type && index !== innerIndex))
    streamField[index].name = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.type === streamField[index].type) {
      message.error('Duplicate Property')
      this.setState({ streamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField, isEnable: true, isNoDuplicate: false })
    }
  }

  onOptionSelect = (value, index) => {
    const { streamField } = this.state
    const duplicateItem = streamField.find(item => item.name === streamField[index].name && item.type === value)
    streamField[index].type = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.type === value) {
      message.error('Duplicate Property')
      this.setState({ streamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField, isEnable: true, isNoDuplicate: false })
    }
  }

  handleTempPropertyNameChange=(event) => {
    this.setState({ tempPropertyName: event.target.value })
  }

  handleTempFieldTypeChange=(option) => {
    this.setState({ tempFieldType: option })
  }

onChangeStatus = (event, index) => {
  const { streamField } = this.state
  const { checked } = event.target
  streamField[index].isRequired = checked
  this.setState({ streamField, isEnable: this.checkDuplicate(streamField[index].id) })
}

onChangeUpdatableStatus = (event, index) => {
  const { streamField } = this.state
  const { checked } = event.target
  streamField[index].isUpdatable = checked
  this.setState({ streamField, isEnable: this.checkDuplicate(streamField[index].id) })
}

onChangeUseAsTitle=(event, index) => {
  const { streamField } = this.state
  const { value } = event.target
  for (let i = 0; i < streamField.length; i++) {
    // streamField[i].useAsTitle = false
    if (streamField[i].id === value) {
      streamField[i].useAsTitle = true
    } else {
      streamField[i].useAsTitle = false
    }
  }
  this.setState({ streamField, isEnable: this.checkDuplicate(value) })
}

checkDuplicate = (value) => {
  const { streamField } = this.state
  const addedField = streamField.filter((el) => el.id === value)
  if (addedField.length) {
    const duplicateItem = streamField.find(item => item.name === addedField[0].name.trim() && item.type === addedField[0].type && item.id !== value)
    if (!_.isEmpty(duplicateItem)) {
      return false
    }
    return true
  }
}

showImageModal = (isShowImageModal, isFromDraft) => {
  this.setState({ isShowImageModal, isFromDraft })
}

hideImageModal=() => {
  this.setState({ isShowImageModal: false })
}

showCropModal = (images) => {
  let { selectedImage } = _.cloneDeep(this.state)
  if (images) {
    selectedImage = _.cloneDeep(images)
  }
  const aspectRatio = selectedImage.aspectRatio.find(item => item.title === '1:1')
  const settings = selectedImage.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
  this.setState({ selectedImage, settings, aspectRatio, isFromDraft: false }, () => this.setState({ isShowCropModal: true }))
}

saveCurrentCrop = (settings) => {
  const image = _.cloneDeep(this.state.selectedImage)
  const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
  const currentaspectId = currentAspectRatio.aspectRatio
  const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
  // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
  image.settings.map(setting => {
    setting.outputFormat = 'JPG'
    delete setting[ '__typename' ]
  })
  image.settings[changedIndex].x1 = settings.x1
  image.settings[changedIndex].x2 = settings.x2
  image.settings[changedIndex].y1 = settings.y1
  image.settings[changedIndex].y2 = settings.y2
  // this.props.updateImage(image.id, image.name, tags, image.settings)
  const croppedUrl = getCropImageUrl(image, settings)
  this.setState({ isShowCropModal: false, isShowCreateAssetTypeModal: true, croppedUrl, selectedImage: image, isImageModified: true, isEnable: true })
}

toggleCropModal = (isShowCropModal) => {
  this.setState({ isShowCropModal })
}

saveCurrentSelection = (images) => {
  const { isFromDraft } = this.state
  if (isFromDraft) { this.setState({ selectedDraftImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true, isFromDraft: false }) } else { this.setState({ selectedImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true, isEnable: true }) }
}

clearSelection = (isFromDraft) => {
  if (isFromDraft) { this.setState({ selectedDraftImage: null, croppedDraftUrl: '' }) } else { this.setState({ selectedImage: null, croppedUrl: '' }) }
}

// saveCurrentSelection = (images) => {
//   const { isFromDraft } = this.state
//   if (isFromDraft) { this.setState({ selectedDraftImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true, isFromDraft: false }) } else { this.setState({ selectedImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true }) }
// }

handleWidgetDetailsChange = (configData, value) => {
  // const { draggableWidget, isWidgetEdit, editWidgetInstance } = this.state
  const { streamFieldValue, jsonSelectedMeta } = this.state
  if (configData.type === 'JSON') {
    let isJsonValid = this.handleJsonValidation(configData, value)
    this.setState({
      isJsonValid
    })
  }
  if (configData.type === 'JSONARRAY') {
    let isJsonArrayValid = this.handleJsonArrayValidation(configData, value)
    let duplicateJsonSelectedMeta = _.cloneDeep(jsonSelectedMeta)
    duplicateJsonSelectedMeta.value = value
    this.setState({
      isJsonArrayValid,
      jsonSelectedMeta: duplicateJsonSelectedMeta
    })
  }

  // let fields = isWidgetEdit ? editWidgetInstance.fieldValues : draggableWidget.fields
  let fields = streamFieldValue || []
  const config = (fields || []).map(item => {
    if (item.id === configData.id && item.type === configData.type) {
      item.value = item.type === 'STRING' ? (value || '') : item.type === 'IMAGE' ? value && value[0] : value
      delete item.isError
    } else {
      const tempItem = (streamFieldValue || []).find(innerItem => innerItem.id === item.id && item.type === innerItem.type)
      item = _.isEmpty(tempItem) ? item : tempItem
    }
    return item
  })
  this.setState({ streamFieldValue: config, isWidgetObjectSaveDisable: false, isEnable: true })
}

isValidJson = (json, isJSONArray) => {
  try {
    if (isJSONArray && !isNaN(json)) {
      return false
    }
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}

handleShortDescriptionChange = (e) => {
  // const { isFromEdit } = this.props
  const { value } = e.target
  // const expectedSeoMetaDescription = this.state.shortDescription || ''
  // if (!isFromEdit && expectedSeoMetaDescription === this.state.seoMetaDescription) {
  //   this.setState({ shortDescription: value, seoMetaDescription: value })
  // } else {
  this.setState({ shortDescription: value, isEnable: true })
  // }
  // this.setState({ shortDescription: value || '', isEnable: true })
}

onInputFieldFocus = () => {
  this.isEditingInput = true
}

onInputFieldBlur = (e) => {
  if (!this.isEditingInput) {
    return
  }
  const { filteredConfigName, slug } = this.state
  const defaultSlug = utilityService.slugifyString(`untitled`)
  const isFirstSlug = slug && (slug.indexOf('untitled-') === 0 || slug.indexOf(defaultSlug) === 0)
  if (isFirstSlug && filteredConfigName !== '') {
    const newTitle = `${filteredConfigName.toLowerCase()}`
    const slug = utilityService.slugifyString(newTitle)
    // details.slug = slug
    this.setState({ slug: slug })
  }
  this.isEditingInput = false
}

handleJsonValidation = (configData, value) => {
  // debugger
  const { jsonInvalidEntries } = this.state
  let isJsonValid = true
  if (_.isEmpty(value)) {
    isJsonValid = true
    let index = (jsonInvalidEntries || []).indexOf(configData.id)
    if (index !== -1) {
      jsonInvalidEntries.splice(index, 1)
    }
  } else {
    let isValidJson = this.isValidJson(value)
    if (!isValidJson) {
      let index = (jsonInvalidEntries || []).indexOf(configData.id)
      if (index === -1) {
        jsonInvalidEntries.push(configData.id)
      }
      isJsonValid = false
    } else {
      let index = (jsonInvalidEntries || []).indexOf(configData.id)
      if (index !== -1) {
        jsonInvalidEntries.splice(index, 1)
      }
      isJsonValid = true
    }
  }
  return isJsonValid
}

handleJsonArrayValidation = (configData, value) => {
  const { jsonArrayInvalidEntries } = this.state
  let isJsonArrayValid = true
  if (_.isEmpty(value)) {
    isJsonArrayValid = true
    let index = (jsonArrayInvalidEntries || []).indexOf(configData.id)
    if (index !== -1) {
      jsonArrayInvalidEntries.splice(index, 1)
    }
  } else {
    let isValidJson = this.isValidJson(value, true)
    if (!isValidJson) {
      let index = (jsonArrayInvalidEntries || []).indexOf(configData.id)
      if (index === -1) {
        jsonArrayInvalidEntries.push(configData.id)
      }
      isJsonArrayValid = false
    } else {
      let index = (jsonArrayInvalidEntries || []).indexOf(configData.id)
      if (index !== -1) {
        jsonArrayInvalidEntries.splice(index, 1)
      }
      isJsonArrayValid = true
    }
  }
  return isJsonArrayValid
}

handleJsonArrayVisibilty = (jsonConfig, jsonSelectedMeta) => {
  this.setState({
    isJsonArrayVisible: true,
    jsonConfig,
    jsonSelectedMeta
  })
}

handleJsonArrayHide = () => {
  const { jsonSelectedMeta } = this.state
  if (!_.isEmpty(jsonSelectedMeta.value)) {
    let parseValue = JSON.parse(jsonSelectedMeta.value)
    if (!Array.isArray(parseValue)) {
      message.warn('Please enter valid JSON array')
      return
    }
  }
  this.setState({
    isJsonArrayVisible: false,
    jsonConfig: null,
    jsonSelectedMeta: null
  })
}

render () {
  const { details, typeList, appList, groupList, isUploadBlocked, isCreateDisabled, isUpdateBlocked, project, isFromEdit, appClient,
    appSearchText, handleAppSearchTextChange, isDuplicate } = this.props
  const { selectedTags, filteredConfigName, filteredConfigIsEnabled, key, isSaving, app, selectedType, systemTags, group, streamField, tempFieldType, tempPropertyName, isEnable,
    isLoading, croppedUrl, selectedImage, isShowImageModal, streamFieldValue, isShowCropModal, aspectRatio, settings, shortDescription, isJsonArrayValid,
    jsonArrayInvalidEntries, jsonInvalidEntries, isJsonArrayVisible, jsonConfig, jsonSelectedMeta } = this.state
  const finalIsEnable = !isFromEdit ? !!(filteredConfigName && key && !_.isEmpty(app) && !_.isEmpty(streamField) && !_.isEmpty(selectedImage))
    : isDuplicate ? !!(filteredConfigName && !_.isEmpty(app) && !_.isEmpty(streamField) && !_.isEmpty(selectedImage))
      : !!(isEnable && filteredConfigName && !_.isEmpty(app) && !_.isEmpty(streamField) && !_.isEmpty(selectedImage))

  const container = this.props.isVisible ? (
    <CreateAppWidgetContainer
      details={_.cloneDeep(details)}
      objectDetails={streamFieldValue || []}
      streamField={streamField}
      selectedTags={selectedTags || []}
      name={filteredConfigName}
      isActive={filteredConfigIsEnabled}
      description={shortDescription}
      selectedKey={key}
      selectedType={selectedType}
      handleTagChange={this.handleTagChange}
      handleTypeChange={this.handleTypeChange}
      handleAppChange={this.handleAppChange}
      handleTextChange={this.handleTextChange}
      handleIsEnabledChange={this.handleIsEnabledChange}
      systemTags={systemTags}
      typeList={typeList}
      appList={appList}
      groupList={groupList}
      selectedApp={app}
      isUploadBlocked={isUploadBlocked}
      selectedGroup={group}
      handleGroupChange={this.handleGroupChange}
      addFieldObjects={this.addFieldObjects}
      handleTempPropertyNameChange={this.handleTempPropertyNameChange}
      handleTempFieldTypeChange={this.handleTempFieldTypeChange}
      tempFieldType={tempFieldType}
      tempPropertyName={tempPropertyName}
      fieldTypeList={fieldTypeList}
      onChangePropertyName={this.onChangePropertyName}
      onOptionSelect={this.onOptionSelect}
      onChangeStatus={this.onChangeStatus}
      onChangeUpdatableStatus={this.onChangeUpdatableStatus}
      handleIdChange={this.handleIdChange}
      onCloseField={this.onCloseField}
      onChangeUseAsTitle={this.onChangeUseAsTitle}
      reorder={this.reorder}
      isFromEdit={isFromEdit}
      appClient={appClient}
      project={project}
      handleObjectDetailsChange={this.handleWidgetDetailsChange}
      showImageModal={(isFromDraft) => this.showImageModal(true, isFromDraft)}
      hideImageModal={this.hideImageModal}
      icon={selectedImage}
      isShowImageModal={isShowImageModal}
      croppedUrl={croppedUrl}
      saveCurrentSelection={this.saveCurrentSelection}
      clearSelection={this.clearSelection}
      showCropModal={(image, isFromDraftIcon) => isFromDraftIcon ? this.showDraftCropModal(image) : this.showCropModal(image)}
      isShowCropModal={isShowCropModal}
      saveCurrentCrop={this.saveCurrentCrop}
      toggleCropModal={this.toggleCropModal}
      aspectRatio={aspectRatio}
      settings={settings}
      handleShortDescriptionChange={this.handleShortDescriptionChange}
      onInputFieldBlur={this.onInputFieldBlur}
      onInputFieldFocus={this.onInputFieldFocus}
      handleAppSearchTextChange={handleAppSearchTextChange}
      appSearchText={appSearchText}
      isDuplicate={isDuplicate}
      isJsonArrayValid={isJsonArrayValid}
      jsonInvalidEntries={jsonInvalidEntries}
      jsonArrayInvalidEntries={jsonArrayInvalidEntries}
      isJsonArrayVisible={isJsonArrayVisible}
      jsonConfig={jsonConfig}
      jsonSelectedMeta={jsonSelectedMeta}
      handleJsonArrayVisibilty={this.handleJsonArrayVisibilty}
      handleJsonArrayHide={this.handleJsonArrayHide}
    />
  ) : <div />
  return (
    <div>
      <Modal
        className='confirm-modal edit-image'
        title={details ? isDuplicate ? 'DUPLICATE WIDGET' : 'EDIT WIDGET' : 'CREATE WIDGET'}
        visible={this.props.isVisible}
        okText='Save'
        okButtonProps={{ disabled: (!finalIsEnable || (isUpdateBlocked && isCreateDisabled)), loading: isLoading }}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        closable={false}
        confirmLoading={isSaving}
        destroyOnClose
        maskClosable={false}
        centered
        width='890px'
      >
        {container}
      </Modal>
    </div>
  )
}
}

CreateWidgetModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** id of the selected image */
  imageId: PropTypes.string,
  /**  */
  createTag: PropTypes.func,
  /**  */
  tagsList: PropTypes.array,
  /** Callback for save content */
  updateImage: PropTypes.func,
  /**  */
  handleSuccess: PropTypes.func,
  /**  */
  mediaDetails: PropTypes.object,
  /** Callback to hide image editor */
  hideImageEditor: PropTypes.func
}

CreateWidgetModal.defaultProps = {}

export default withApollo(
  compose(
    graphql(MutationCreateWidget, {
      options: ({ appClient, project, widgetSearchValue }) => {
        return {
          update: (cache, { data: { createAppWidget } }) => {
            let variables = utilityService.getFormattedWidgetSettingFilter('', widgetSearchValue, project)
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryWidgetList, variables: variables }))
            if (cacheData && cacheData.listWidgets && cacheData.listWidgets.items) { cacheData.listWidgets.items.push(createAppWidget) }
            cache.writeQuery({
              query: QueryWidgetList,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        createAppWidget: (title, key, app, fields, fieldValues, widgetPreview, description, isActive) => {
          return props.mutate({
            variables: { title, key, app, fields, fieldValues, widgetPreview, description, project: props.ownProps.project, isActive }
          })
        }
      })
    }),
    graphql(MutationUpdateWidget, {
      options: ({ appClient, project, widgetSearchValue }) => {
        return {
          update: (cache, { data: { editAppWidget } }) => {
            let variables = utilityService.getFormattedWidgetSettingFilter('', widgetSearchValue, project)
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryWidgetList, variables: variables }))
            if (cacheData && cacheData.listWidgets && cacheData.listWidgets.items) {
              let foundIndex = (cacheData.listWidgets.items).findIndex(item => item.id === editAppWidget.id)
              if (foundIndex > -1) {
                // let temp= cacheData.listWidgets.items[foundIndex]
                cacheData.listWidgets.items[foundIndex] = editAppWidget
                // cacheData.listWidgets.items[foundIndex].totalCount=temp.totalCount
              }
            }
            cache.writeQuery({
              query: QueryWidgetList,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        updateAppWidget: (title, key, app, fields, id, fieldValues, widgetPreview, description, isActive) => {
          return props.mutate({
            variables: { title, key, app, fields, project: props.ownProps.project, id, fieldValues, widgetPreview, description, isActive }
          })
        }
      })
    }),
    graphql(
      MutationUpdateMedia, {
        props: (props) => ({
          updateImage: (id, name, tags, settings) => {
            return props.mutate({
              variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMediaSettings, {
        props: (props) => ({
          updateImageSettings: (id, assetId, settings) => {
            return props.mutate({
              variables: { id, assetId, settings }
            })
          }
        })
      }
    ),
    graphql(MutationDuplicateWidget, {
      options: ({ appClient, project, widgetSearchValue }) => {
        return {
          update: (cache, { data: { duplicateAppWidget } }) => {
            let variables = utilityService.getFormattedWidgetSettingFilter('', widgetSearchValue, project)
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryWidgetList, variables: variables }))
            if (cacheData && cacheData.listWidgets && cacheData.listWidgets.items) { cacheData.listWidgets.items.push(duplicateAppWidget) }
            cache.writeQuery({
              query: QueryWidgetList,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        duplicateAppWidget: (title, key, app, fields, id, fieldValues, widgetPreview, description, isActive) => {
          return props.mutate({
            variables: { title, key, app, fields, project: props.ownProps.project, id, fieldValues, widgetPreview, description, isActive }
          })
        }
      })
    })
  )(CreateWidgetModal)
)
