import React, { Component } from 'react'
import ReactJson from 'react-json-view'
import { withRouter } from 'react-router-dom'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryGetBucketDetails from './../../graphQL/bucket/getBucketJsonDetails'

import './AppManager.scss'

class AppManagerBucketJsonPreview extends Component {
  render () {
    const { details } = this.props
    return (
      <div className='bucket-json-preview'>
        <ReactJson id='json-pretty' src={details} displayDataTypes={false} iconStyle={'triangle'} name={false} />
      </div>
    )
  }
}

export default withApollo(withRouter(compose(
  graphql(
    QueryGetBucketDetails,
    {
      options: (props) => {
        const { project, bucketId } = props
        return {
          variables: { id: bucketId, project }
        }
      },
      props: (props) => {
        return {
          details: props.data.listAssetsbyBucket ? props.data.listAssetsbyBucket : { items: [] }
        }
      }
    }
  )
)(withApollo(AppManagerBucketJsonPreview))))
