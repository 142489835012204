import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'

class BucketAppConfigListCell extends React.Component {
  render () {
    const { status, shortId, bucketName, bucketType, handleBucketSelection, bucketDetails, selectedBucket } = this.props
    const selectedBucketName = selectedBucket && selectedBucket.key
    return (
      <div className={`bucket-config-list-cell ${status ? status.toLowerCase() : ''} ${selectedBucketName === shortId ? 'active' : ''}`} onClick={() => handleBucketSelection(bucketDetails)}>
        <input type='radio' name='test' value={bucketName} checked={selectedBucketName === shortId} />
        <div className='cell'>
          <div className='cell-left'>
            <p> {bucketName} </p>
            <p> {bucketType} </p>
          </div>
          <div className='cell-right'>
            <p className='bucket-publish '>{ status } </p>
            <p className='bucket-id'>{ `${shortId}` } </p>
          </div>
        </div>
      </div>
    )
  }
}

BucketAppConfigListCell.propTypes = {
  /** Bucket status type of BucketAppConfigListCell. */
  status: PropTypes.string,
  /** Bucket id of BucketAppConfigListCell. */
  bucketId: PropTypes.string,
  /** Bucket Name type of BucketAppConfigListCell. */
  bucketName: PropTypes.string,
  /** Bucket type of BucketAppConfigListCell. */
  bucketType: PropTypes.string,
  /** Function to be called on bucket add */
  onBucketAdd: PropTypes.func,
  /** Boolean for enable adding to bucket */
  isEnabled: PropTypes.bool,
  /** Boolean to indicate bucket is archived or not */
  isArchived: PropTypes.bool
}

BucketAppConfigListCell.defaultProps = {
  isSelected: false
}

export default BucketAppConfigListCell
