import gql from 'graphql-tag'

export default gql(`
    mutation enableUser($id: String $project: String) {
        enableUser(
            input: {
                id: $id
            }
            project: $project
        ){
            id
        }
    }
`)
