import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

const Option = Select.Option

const filterDropDown = (input, option, options) => {
  const currentOption = options.find(item => item.id === option.props.value)
  const returnValue = currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

const MultipleDropDown = ({ onOptionSelect, displayParam, keyParam, options, placeHolder, className, allowClear, defaultValue, parent, selectedValue, ...props }) => {
  const value = selectedValue && selectedValue.length ? (selectedValue || []).map(value => value.id) : []
  const onMultiOptionSelect = (selectedValue) => {
    const selectedGenres = options.filter(item => selectedValue.includes(item.id))
    onOptionSelect(selectedGenres)
  }

  return (
    <Select
      className={className ? 'multi-drop-down ' + className : 'multi-drop-down'}
      mode='multiple'
      allowClear={allowClear}
      value={value}
      style={{ width: '100%' }}
      placeholder={placeHolder}
      getPopupContainer={() => document.getElementById(parent)}
      onChange={onMultiOptionSelect}
      filterOption={(input, option) => filterDropDown(input, option, options)}
      optionLabelProp='label'
      {...props}
    >
      {(options || []).map((option, index) => <Option label={option[displayParam]} key={option.id} value={option[keyParam]}>{option[displayParam]} <span>{utilityService.getSourceDetails(option['source'])}</span> </Option>)}
    </Select>
  )
}

MultipleDropDown.propTypes = {
  /** Options of Dropdown. */
  options: PropTypes.array.isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Class name */
  className: PropTypes.string,
  defaultValue: PropTypes.array
}

MultipleDropDown.defaultProps = {
  placeHolder: 'Select'
}

export default MultipleDropDown
