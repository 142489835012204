import React, { Component } from 'react'
import ReactJson from 'react-json-view'
import { withRouter } from 'react-router-dom'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryGetBucketDetails from './../../graphQL/bucket/getBucketJsonDetails'

import './BucketManager.scss'

class BucketManagerJsonPreview extends Component {
  render () {
    const { details } = this.props
    return (
      <div className='bucket-json-preview'>
        <ReactJson id='json-pretty' src={details} displayDataTypes={false} iconStyle={'triangle'} name={false} />
      </div>
    )
  }
}

export default withApollo(withRouter(compose(
  graphql(
    QueryGetBucketDetails,
    {
      options: (props) => {
        let id
        if (props.match && props.match.params && props.match.params.id) {
          id = props.match.params.id
        }
        const { project } = props
        return {
          variables: { id, project }
        }
      },
      props: (props) => {
        return {
          details: props.data.listAssetsbyBucket ? props.data.listAssetsbyBucket : { items: [] }
        }
      }
    }
  )
)(withApollo(BucketManagerJsonPreview))))
