import gql from 'graphql-tag'

export default gql(`
    mutation updateStreamLevel( $id: ID!  $group: String! $name: String! $fields: [CreateFieldInput]! $version: String! ) {
        updateStreamLevel(input:{
            id: $id
            group: $group
            name: $name
            fields: $fields 
            version: $version
        }){
            id
            name
            group
            version
            fields{
                name
                type
                value
                oldValue
                displayName
                displayValue
                isRequired
                fieldName
                maxValue
                isPosChange
            }
        }
    }`
)
