import React from 'react'
import PropTypes from 'prop-types'
import CrossIcon from '../../general/icons/CrossIcon'
import './../../ui.style.scss'

class CustomerPreviewImageModal extends React.Component {
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.previewContainerRef && this.previewContainerRef.contains(event.target)) {

    } else {
      this.props.onClose()
    }
  }

  render () {
    const { onClose, previewImageSrc, visible } = this.props
    return <div className='customer-preview-image-modal'>
      <div className='image-container' ref={(node) => { this.previewContainerRef = node }}>
        <CrossIcon onClick={onClose} visible={visible} />
        <div className='preview-image'>
          <img src={previewImageSrc} alt='Attachment Image' />
        </div>
      </div>
    </div>
  }
}

CustomerPreviewImageModal.propTypes = {
  /** close action of PreviewImageModal. */
  onClose: PropTypes.func
}

CustomerPreviewImageModal.defaultProps = {
  previewImageSrc: 'https://2bhkkyl1q5.execute-api.us-east-1.amazonaws.com/image/0x0:299x168/1920x1080/http://vcms-appsync-stack-dev-mediafilestorage-7ixtofy7q6ep.s3-website-ap-southeast-2.amazonaws.com/a4dfec64-f1cf-44dd-be1f-8ce1b1a6cc4b_images-1.jpeg'
}

export default CustomerPreviewImageModal
