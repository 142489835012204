import gql from 'graphql-tag'

export default gql(`
    mutation createBucket($name:String $displayName:String $displayType:ID $key: String! $isPublished: Boolean $publishStartDate: AWSDateTime $module: MODULE_TYPE $project: String) {
        createBucket(
            input: {
                name: $name
                displayName: $displayName
                displayType: $displayType
                key: $key
                isArchived: false
                isPublished: $isPublished
                publishStartDate: $publishStartDate
                project: $project
            }
            module: $module
        ) {
            project
            id
            name
            isArchived
            displayName
            key
            isPublished
            status
            publishStartDate
            publishEndDate
            updatedAt
            updatedBy
            displayType{
                name
                id
            }
            bucketItems {
                id
                position
                type
                asset{
                  id
                  title
                  type
                  shortId
                  externalId
                  updatedAt
                  status
                  seasonNumber
                  episodeNumber
                  series{
                    id
                    assetId
                    title
                  }
                  season{
                      id
                      assetId
                      title
                  }
                  defaultMedia {
                      id
                      url
                      fileName
                      aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                          url
                        }
                      }
                      settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                      }
                  }
                }  
                filterRule{
                  searchKey
                  filters{
                      name
                      fieldName
                      type
                      value
                      displayValue
                      displayName
                      isRequired
                  }
              }
            }
        }
    }`
)
