import React from 'react'
import PropTypes from 'prop-types'
import ImageThumbnailIcon from './../general/icons/ImageThumbnailIcon'
import FileThumbnailIcon from './../general/icons/FileThumbnailIcon'
import CrossIcon from './../general/icons/CrossIcon'
import DropDown from '../dataEntry/inputs/DropDown'
import './../ui.style.scss'
import { utilityService } from '../../../services/UtilityService'

class UploadQueueCell extends React.Component {
  render () {
    const { onClose, name, listMediaCategories, selectedCategory, onCategorySelect, thumbnailIcon } = this.props
    const parentId = utilityService.makeRandomString(5)
    let imageCategories = (listMediaCategories || []).filter(item => item.type === 'IMAGE' && item.isActive)
    if (selectedCategory) {
      const selected = listMediaCategories.find(item => item.id === selectedCategory)
      if (selected.isActive === false) { imageCategories.push(selected) }
    }
    return (
      <React.Fragment>
        <div className='upload-queue-cell' id={parentId}>
          <div className='cell-content'>
            { (thumbnailIcon && thumbnailIcon === 'file') ? <FileThumbnailIcon color='#343a40' width='23' height='30' /> : <ImageThumbnailIcon color='#343a40' />}
            <p>{name}</p>
            {listMediaCategories && listMediaCategories.length ? <div className='image-category'>
              <span>Image Category:</span>
              <DropDown
                parent={parentId}
                options={imageCategories}
                selectedValue={selectedCategory}
                placeHolder={`Select Image Category`}
                valueParam='id'
                displayParam='label'
                onOptionSelect={onCategorySelect}
                className={selectedCategory ? 'selected' : ''} />
            </div> : ''}
          </div>
          <CrossIcon onClick={(e) => onClose(this.props.index, e)} />
        </div>
      </React.Fragment>
    )
  }
}
UploadQueueCell.propTypes = {
  /** file name of UploadQueueCell. */
  name: PropTypes.string,
  /** close action of UploadQueueCell. */
  onClose: PropTypes.func,
  /** File index of UploadQueueCell. */
  index: PropTypes.number
}

UploadQueueCell.defaultProps = {
}

export default UploadQueueCell
