import React, { Component } from 'react'
import { message, Row, Col } from 'antd'

import AppContext from '../../../AppContext'

import TemplateAssetDetails from '../../../components/ui/dataDisplay/TemplateAssetDetails'
import TemplateTags from '../../../components/ui/dataDisplay/TemplateTags'
import TemplatePublishRules from '../../../components/ui/dataDisplay/TemplatePublishRules'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import DataHistory from './../DataHistory'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAssetTemplate from '../../../graphQL/admin/asset/getAssetTemplate'
import MutationUpdateAssetTemplate from '../../../graphQL/admin/asset/updateAssetTemplate'
import SidebarOpenButton from '../../../components/ui/general/buttons/SidebarOpenButton'
import ButtonContainer from '../../../components/ui/general/buttons/ButtonContainer'

const initialDetails = {
  isLive: false,
  isFree: false,
  isadminHistory: false,
  title: '',
  maxAssets: 4,
  minAssets: 1
}

class MatchTemplate extends Component {
  constructor (props) {
    super(props)
    const variableArray = props.selectedId.split('-competiton-')
    this.state = {
      details: _.cloneDeep(props.details) || { ...initialDetails, competition: variableArray[0], assetType: variableArray[1] }
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.details && !_.isEqual(this.props.details, newProps.details)) {
      this.setState({ details: _.cloneDeep(newProps.details), isEdited: false })
    }
    if (newProps.selectedId && newProps.selectedId !== this.props.selectedId) {
      const variableArray = newProps.selectedId.split('-competiton-')
      if (variableArray && variableArray.length === 2) {
        let details = { ...initialDetails, competition: variableArray[0], assetType: variableArray[1] }
        if (newProps.details && newProps.details.assetType === variableArray[1] && newProps.details.competition === variableArray[0]) {
          details = _.cloneDeep(newProps.details)
        }
        this.setState({ details, isEdited: false })
      }
    }
  }

  onTagChange = value => {
    const { details } = this.state
    details.tags = value
    this.setState({ details, isEdited: true })
  }

  handleGeneralChange = (e) => {
    const { details } = this.state
    const { name, value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details[name] = null
      } else {
        details[name] = value
      }
      this.setState({ details, isEdited: true })
    }
  }

  handleSeoKeywordChange = (value) => {
    const { details } = this.state
    details.seoKeywords = value
    this.setState({ details, isEdited: true })
  }

  onRangeChange = (type, value) => {
    const { details } = this.state
    details[type] = value
    this.setState({ details, isEdited: true })
  }

  onCategorySelect = selectedCategory => {
    const { details } = this.state
    details.category = selectedCategory
    this.setState({ details, isEdited: true })
  }

  onLiveStatusChange = value => {
    const { details } = this.state
    details.isLive = value
    this.setState({ details, isEdited: true })
  }

  onPremiumStatusChange = value => {
    const { details } = this.state
    details.isFree = !value
    this.setState({ details, isEdited: true })
  }

  getUpdateRequest = () => {
    const { details } = this.state
    const mapItems = {
      category: details.category ? details.category.id : null,
      isFree: !!(details.isFree),
      tags: (details.tags || []).map(item => {
        delete (item.__typename)
        return item
      }),
      publishEndDate: (details.publishEndDate || []).map(item => {
        delete (item.__typename)
        return item
      })
    }
    const request = { ...details, ...mapItems, action: this.props.details ? 'UPDATE' : 'CREATE' }
    delete (request.__typename)
    return request
  }

  onUpdatePublishRules = (publishEndDate) => {
    let { details } = this.state
    details.publishEndDate = publishEndDate
    this.setState({ details, isEdited: true })
  }

  onAddPublishRule = id => {
    let { details } = this.state
    let { publishEndDate } = details
    const index = (publishEndDate || []).findIndex(item => item.publishGroup === id)
    if (index === -1) {
      const defaultPublishRule = {
        publishGroup: id,
        operator: 'ADD',
        field: '{MATCH_DATE}',
        offset: 30,
        offsetType: 'DAYS'
      }
      if (!publishEndDate) {
        publishEndDate = []
      }
      publishEndDate.push(defaultPublishRule)
      details.publishEndDate = publishEndDate
      this.setState({ details, isEdited: true })
    }
  }

  onRemovePublishRule = id => {
    let { details } = this.state
    let { publishEndDate } = details
    const index = (publishEndDate || []).findIndex(item => item.publishGroup === id)
    if (index > -1) {
      publishEndDate.splice(index, 1)
      details.publishEndDate = publishEndDate
      this.setState({ details, isEdited: true })
    }
  }

  onUpdateMatchTemplate = () => {
    this.setState({ isLoading: true })
    const request = this.getUpdateRequest()
    let inValidField = false
    const isInvalidOffset = (request.publishEndDate || []).findIndex(item => !item.offset) > -1
    const isInvalidSpecificEndDate = (request.publishEndDate || []).findIndex(item => {
      if (item && item.operator === 'SPECIFIC' && item.field !== '{SPECIFIC_DATE}') {
        item.field = '{SPECIFIC_DATE}'
      }
      if (item && item.operator !== 'SPECIFIC' && item.field === '{SPECIFIC_DATE}') {
        inValidField = true
      }
      if (item && item.operator === 'SPECIFIC' && (!item.publishEndDate || item.publishEndDate === '')) {
        return item
      }
    })
    if (!request.title) {
      message.error('Title is empty. Please add a title before saving')
      this.setState({ isLoading: false })
      return
    } else if (isInvalidOffset) {
      message.error('Offset is empty. Please add offset for all the publish rules before saving')
    } else if (!request.minAssets || !request.minAssets) {
      message.error('Match asset counts are required')
      this.setState({ isLoading: false })
      return
    } else if (isInvalidSpecificEndDate > -1) {
      message.warn('Enter a valid endDate')
      this.setState({ isLoading: false })
      return
    } else if (inValidField) {
      message.warn('Enter a valid field MATCH_DATE/SEASON_END')
      this.setState({ isLoading: false })
      return
    }
    this.props.updateAssetTemplate(request).then(response => {
      this.setState({ isLoading: false, isEdited: false, isadminHistory: true }, () => {
        this.setState({ isadminHistory: false })
      })
      message.success('Updated Match template')
    }, error => {
      this.setState({ isLoading: false, isEdited: false })
      utilityService.handleError(error)
    })
  }

  render () {
    const { toggleSidebar, isSideBarVisible } = this.props
    const { details, isLoading, isEdited, isadminHistory } = this.state

    // (details && details.id && isSideBarVisible)

    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isEditDisabled = userPermissions.indexOf('UPDATE') === -1
          return (
            <Row className='data-match-template' id=''>
              <Col xl={{ span: (isSideBarVisible) ? 16 : 24 }} xs={{ span: (isSideBarVisible) ? 16 : 24 }} className='data-template'>
                <div className='match-template'>
                  <div className='data-heading'>
                    <label>Match Template</label>
                    {!isSideBarVisible ? <ButtonContainer displayTitle='Show Audit History' placement='bottomLeft' childComponent={<SidebarOpenButton onClick={toggleSidebar} />} /> : <div className='invisible' />}
                  </div>
                  <TemplateAssetDetails
                    details={details}
                    handleGeneralChange={this.handleGeneralChange}
                    handleSeoKeywordChange={this.handleSeoKeywordChange}
                    onLiveStatusChange={this.onLiveStatusChange}
                    onPremiumStatusChange={this.onPremiumStatusChange}
                    onRangeChange={this.onRangeChange}
                    onCategorySelect={this.onCategorySelect}
                  />
                  <TemplateTags
                    details={details}
                    onTagChange={this.onTagChange}
                    selectedItems={details && details.tags ? details.tags.filter(item => item) : []}
                    project={project} />
                  <TemplatePublishRules
                    publishRules={details.publishEndDate || []}
                    onUpdatePublishRules={this.onUpdatePublishRules}
                    onAddPublishRule={this.onAddPublishRule}
                    onRemovePublishRule={this.onRemovePublishRule}
                    project={project}
                  />
                </div>
                <div className='data-bottom'>
                  <LoadingButton
                    type='primary'
                    onClick={this.onUpdateMatchTemplate}
                    htmlType='submit'
                    buttonText={'Save'}
                    buttonClass='save-btn'
                    isLoading={isLoading}
                    isDisabled={!isEdited || isLoading || isEditDisabled}
                  />
                </div>
              </Col>
              {/* <Col xl={{ span: 8 }} xs={{ span: 8 }} className='data-sidebar-container'>
                <DataHistory selectedDataId={'MATCH_TEMPLATE'} isChanging={isadminHistory} templateId={details.id || ''} />
              </Col> */}
              { (isSideBarVisible) ? <Col xl={{ span: 8 }} xs={{ span: 8 }} className='data-sidebar-container'>
                <DataHistory selectedDataId={'MATCH_TEMPLATE'} isChanging={isadminHistory} templateId={details.id} toggleSidebar={toggleSidebar} />
              </Col> : ''}
            </Row>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

MatchTemplate.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryAssetTemplate,
    {
      skip: ({ selectedId }) => !selectedId,
      options: ({ selectedId }) => {
        const variableArray = selectedId.split('-competiton-')
        return {
          variables: { competition: variableArray[0], assetType: variableArray[1] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let details = data.getAssetTemplate
        return {
          details,
          loading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationUpdateAssetTemplate, {
      options: ({ selectedId }) => {
        const variableArray = selectedId.split('-competiton-')
        const variables = { competition: variableArray[0], assetType: variableArray[1] }
        return {
          update: (cache, { data: { createOrUpdateAssetTemplate } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAssetTemplate, variables }))
            if (cacheData) {
              cacheData.getAssetTemplate = createOrUpdateAssetTemplate
            }
            cache.writeQuery({
              query: QueryAssetTemplate,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        updateAssetTemplate: (request) => {
          return props.mutate({
            variables: { ...request }
          })
        }
      })
    }
  )

)(MatchTemplate))
