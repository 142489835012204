import gql from 'graphql-tag'

export default gql(`
    mutation($ids : [ID!] $project: String) {
        batchArchiveAssets(
            assets: $ids
            project: $project
        )
    }`
)
