import React from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import AdminOriginalData from './AdminOriginalData'
// import { generateCroppedThumbnail } from '../../../util/util'
import './../ui.style.scss'

class SquadOriginalData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  getSelectedData = (selectedSquad, shirtNumber) => {
    const data = [
      {
        key: 'Player Name',
        value: selectedSquad.matchName ? selectedSquad.matchName : '-',
        isLeft: true
      },
      {
        key: 'First Name',
        value: selectedSquad.firstName ? selectedSquad.firstName : '-',
        isLeft: false
      },
      {
        key: 'Last Name',
        value: selectedSquad.lastName ? selectedSquad.lastName : '-',
        isLeft: true
      },
      {
        key: 'Opta Id',
        value: selectedSquad.externalId ? selectedSquad.externalId : '-',
        isLeft: false
      },
      {
        key: 'Nationality',
        value: selectedSquad.nationality ? selectedSquad.nationality : '-',
        isLeft: true
      },
      {
        key: 'Nationality Id',
        value: selectedSquad.nationalityId ? selectedSquad.nationalityId : '-',
        isLeft: false
      },
      {
        key: 'Position',
        value: selectedSquad.position ? selectedSquad.position : '-',
        isLeft: true
      },
      {
        key: 'Type',
        value: selectedSquad.type ? _.capitalize(selectedSquad.type) : '-',
        isLeft: false
      },
      // {
      //   key: 'Jersey Number',
      //   value: shirtNumber || '-',
      //   isLeft: true
      // },
      {
        key: 'Active',
        value: selectedSquad.active ? _.capitalize(selectedSquad.active) : '-',
        isLeft: false
      }
    ]
    return data
  }

  render () {
    const { selectedSquad, shirtNumber } = this.props
    const selectedData = this.getSelectedData(selectedSquad, shirtNumber)
    return (
      <div className='squad-data'>
        <AdminOriginalData selectedData={selectedData} />
      </div>
    )
  }
}

SquadOriginalData.propTypes = {

}

SquadOriginalData.defaultProps = {
}

export default SquadOriginalData
