import gql from 'graphql-tag'

export default gql(`
    mutation($name: String! $module: String $type: String $project: String
        ) {
            createTag(
            input: {
                name: $name
                module: $module
                type: $type
            }
            project: $project
        ) {
            key
            type
            name
            module
            isTransferring
            assetCount
            mediaCount
            isError
        }
    }`
)
