import gql from 'graphql-tag'

export default gql(`
    query($filter: BucketGroupTypeFilterInput $limit: Int $project: String){
        listBucketGroupType( 
            filter: $filter
            limit: $limit
            project: $project
        ) {
        items {
            id
            name
            position
            usedCount
        }
    }
}`)
