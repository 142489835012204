import React from 'react'
import PropTypes from 'prop-types'

const GroupUserIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='#FFF' fillRule='nonzero' d='M16.391 4.084c-1.245 0-2.256.918-2.256 2.046 0 1.127 1.01 2.042 2.256 2.042 1.246 0 2.26-.915 2.26-2.042 0-1.127-1.014-2.046-2.26-2.046zm-12.784 0c-1.246 0-2.26.918-2.26 2.046 0 1.127 1.014 2.042 2.26 2.042 1.243 0 2.256-.915 2.256-2.042 0-1.127-1.013-2.046-2.256-2.046zM9.997 0C8.158 0 6.659 1.356 6.659 3.022c0 1.667 1.498 3.024 3.338 3.024s3.34-1.357 3.34-3.024C13.339 1.356 11.837 0 9.997 0zM20 18.112h-3.94v-6.523c0-.897-.262-1.73-.685-2.479a3.92 3.92 0 0 1 1.017-.148C18.38 8.962 20 10.426 20 12.23v5.882zM3.936 11.589v6.523H0V12.23c0-1.802 1.618-3.268 3.61-3.268.354 0 .69.062 1.012.148-.423.748-.686 1.582-.686 2.48zM4.762 20h10.47v-8.41c0-2.615-2.349-4.74-5.235-4.74-2.889 0-5.235 2.125-5.235 4.74V20z' />
  </svg>
)

GroupUserIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

GroupUserIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default GroupUserIcon
