import React from 'react'
import PropTypes from 'prop-types'
import CropWhiteIcon from './../icons/CropWhiteIcon'
import './../../ui.style.scss'

const CropWhiteButton = ({ onClick, ...props }) => (
  <div className='black-button crop-white' onClick={onClick} >
    <CropWhiteIcon />
  </div>
)

CropWhiteButton.propTypes = {

  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

CropWhiteButton.defaultProps = {

}

export default CropWhiteButton
