import React, { Component } from 'react'
import { Row, Col } from 'antd'
import './OfferManager.scss'
import NProgress from 'nprogress'
import _ from 'lodash'
import OfferEditor from './OfferEditor'
import OfferList from './OfferList'
import { utilityService } from './../../services/UtilityService'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'

class OfferManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSidebar: window.innerWidth > 1200,
      selectedOfferId: '',
      searchString: '',
      isArchive: false,
      deletedIds: [],
      filter: [],
      shouldShowWarning: false,
      shouldSave: false,
      shouldDiscard: false,
      isSavingOffer: false,
      sort: {
        order: 'desc',
        value: 'createdAt'
      }
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if ((!newProps.match.params || !newProps.match.params.id) && this.state.selectedOfferId) {
      this.setState({ selectedOfferId: '' })
    } else if (this.state.selectedOfferId && this.state.selectedOfferId !== newProps.match.params.id) {
      this.setState({ selectedOfferId: newProps.match.params.id })
    }
    if (newProps.showWarning) {
      this.setState({ shouldShowWarning: true })
    }
  }

  componentDidMount () {
    document.title = `Offer Manager - Hyperion CMS`
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar })
  }

  changeFilterValue = (filter) => {
    this.setState({ filter })
  }

  onChangeFilter=value => {
    this.setState({ searchString: value })
  }

  onChangeSort = sortData => {
    if (!_.isEqual(sortData, this.state.sortData)) {
      this.setState({ sort: sortData })
    }
  }

  // selectedOffer = id => {
  //   if (this.state.selectedOfferId !== id) {
  //     NProgress.start()
  //     this.setState({ selectedOfferId: id, urlSlug: '' }, () => {
  //       this.props.history.push(`/offers/${window.location.search ? id + window.location.search : id}`)
  //     })
  //   }
  // }

  onDeleteOffer = ids => {
    this.setState({ deletedIds: ids })
  }

  changeEditStatus = (status) => {
    const { isEdited, isError } = status
    this.props.onChangeEditStatus(isEdited)
    if (this.state.shouldSave) {
      this.setState({ isError, isEdited, shouldSave: false, isSavingOffer: false, shouldShowWarning: false }, () => {
        if (!isError) {
          this.selectedOffer(this.tempSelectedId)
        }
      })
    } else {
      this.setState({ isError, isEdited })
    }
  }

  archiveButtonHandle = (status) => {
    this.setState({ isArchive: status })
  }

  forceSave = () => {
    this.props.notifyParent()
    this.setState({ shouldSave: true, isSavingAsset: true })
  }

  discardChanges = () => {
    this.props.notifyParent()
    this.setState({ isEdited: false, shouldDiscard: true }, () => {
      this.toggleWarningMessage(false)
      this.selectedOffer(this.tempSelectedId)
      this.setState({ shouldDiscard: false })
    })
  }

  selectedOffer = id => {
    if (this.state.selectedOfferId !== id && this.state.isEdited) {
      this.tempSelectedId = id
      this.toggleWarningMessage(true)
    } else if (this.state.selectedOfferId !== id) {
      NProgress.start()
      this.setState({ selectedOfferId: id, urlSlug: '' }, () => {
        this.props.history.push(`/offers/${window.location.search ? id + window.location.search : id}`)
      })
    }
  }

  onCloseConfirmModal = () => {
    this.setState({ shouldShowWarning: false }, this.props.clearModuleChange)
  }

  render () {
    const { showSidebar, sort, selectedOfferId, searchString, filter, isArchive, deletedIds
      , shouldShowWarning, isError, shouldSave } = this.state
    const { project } = this.props
    const filterData = { searchString, filter: utilityService.getFormattedFilterForOffer(filter), sort }
    return <Row className='offer-manager'>
      <Col className={showSidebar ? 'offer-list-wrapper' : ''} span={6} >
        <OfferList
          sort={sort}
          searchString={searchString}
          onChangeFilter={this.onChangeFilter}
          selectedOfferId={selectedOfferId}
          deletedIds={deletedIds}
          changedOffer={this.selectedOffer}
          archiveButtonHandle={this.archiveButtonHandle}
          changeFilterValue={this.changeFilterValue}
          filterVal={filter}
          onChangeSort={this.onChangeSort}
          project={project}
        />
      </Col>
      <Col xl={{ span: 18 }} xs={{ span: 18 + (showSidebar ? 6 : 0) }} className='offer-editor-container' >
        <OfferEditor
          showSidebar={showSidebar}
          toggleSidebar={this.toggleSidebar}
          offerId={selectedOfferId}
          deletedIds={deletedIds}
          onDeleteOffer={this.onDeleteOffer}
          changeEditStatus={this.changeEditStatus}
          project={project}
          isArchive={isArchive}
          appliedFilter={filterData}
          shouldSave={shouldSave}
          selectedOffer={selectedOfferId}
        />
      </Col>
      <ConfirmModal
        isVisible={shouldShowWarning}
        title={`Offer Manager`}
        message={'Changes made by you will be discarded. Do you wish to continue ?'}
        isLeftPrior={false}
        leftButtonText={'Discard'}
        rightButtonText={'Save'}
        handleSubmit={this.forceSave}
        handleCancel={this.discardChanges}
        handleClose={this.onCloseConfirmModal}
        isSubmitButtonDisabled={isError}
        isCancelButtonDisabled={false}
        // isLoading={isSavingAsset}
      />
    </Row>
  }
}

OfferManager.propTypes = {

}

export default OfferManager
