import gql from 'graphql-tag'

export default gql(`
    mutation updateBucket($buckets:[ID] $id: ID! $name:String $displayName:String $displayType:ID $isArchived: Boolean $key: String $updatedBy: String $updatedAt: AWSDateTime $parentAssetId: ID $module: MODULE_TYPE $project: String) {
        updateBucket (
            input: {
                id: $id
                bucketItems: $buckets
                name: $name
                displayName: $displayName
                displayType: $displayType
                isArchived: $isArchived
                key: $key
                updatedAt: $updatedAt
                updatedBy: $updatedBy
            }
            parentAssetId: $parentAssetId
            module: $module
            project: $project
          ) {
            id
            name
            isArchived
            displayName
            key
            isPublished
            status
            publishStartDate
            publishEndDate
            updatedAt
            updatedBy
            displayType{
                name
                id
            }
            bucketItems{
                id
                position
                type
                customTitle
                customMedia{
                    id
                    url
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                            url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
                customAssetImage{
                    id
                    mediaId
                    url
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                            url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
                asset{
                    id
                    title
                    type
                    shortId
                    externalId
                    updatedAt
                    status
                    seasonNumber
                    episodeNumber
                    series{
                      id
                      assetId
                      title
                    }
                    season{
                        id
                        assetId
                        title
                    }
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                }
                filterRule{
                    searchKey
                    filters{
                        name
                        fieldName
                        type
                        value
                        displayValue
                        displayName
                        isRequired
                    }
                }
            }
        }
    }`
)
