import gql from 'graphql-tag'

export default gql(`
    query listAspectRatios($limit: Int $project: String){
        listAspectRatios( 
            limit: $limit
            project: $project
            ) {
        items {
            name
            title
            ratio
            aspectRatio
            cropPosition
            resolutions{
                id
                width
                height
            }
        }
    }
}`)
