import React from 'react'

import { Empty, Button } from 'antd'
//

import './../../ui.style.scss'
import PopoverConfirm from './../../feedback/PopoverConfirm'
// import DeleteIcon from '../../general/icons/DeleteIcon'
// import SaveIcon from '../../general/icons/SaveIcon'
// import EditIcon from './../../general/icons/EditIcon'

const getNewAdPositionItem = (ipItem, onDeleteAdPosition, onSaveAdPosition, onChangeAdPosition) => {
  return <div className='ip-item' key={'temp-ip-key'}>
    <input onChange={(e) => onChangeAdPosition(e, 0)} />
    {/* {ipItem.isLoading ? <Button loading className='save-loading' />
      : <SaveIcon color={ipItem.position ? '#343a40' : '#AFAFAF'} onClick={ipItem.position ? () => onSaveAdPosition(ipItem) : () => {}} />}
    <div className='separator' />
    <DeleteIcon color={ipItem.isLoading ? '#AFAFAF' : '#343a40'} onClick={ipItem.isLoading ? () => {} : () => onDeleteAdPosition(ipItem.position, 0)} /> */}
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteAdPosition(ipItem.position, 0)} />
  </div>
}

const getAdPositionItem = (ipItem, onDeleteAdPosition, index, onChangeAdPosition) => {
  return <div className={`ip-item ${ipItem.isError === true ? 'error' : ''}`} key={ipItem.ip}>
    <input value={ipItem.position} onChange={(e) => onChangeAdPosition(e, index)} />
    {/* <EditIcon color={'#343a40'} onClick={(index)=>onClickEditAdPosition(index)} />
    <div className='separator' /> */}
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteAdPosition(ipItem.position, index)} />
  </div>
}

const getAdPositionList = (adPositions, onDeleteAdPosition, onSaveAdPosition, onChangeAdPosition, onClickEditAdPosition) => {
  return (adPositions || []).map((item, index) => {
    return item.isNew ? getNewAdPositionItem(item, onDeleteAdPosition, onSaveAdPosition, onChangeAdPosition) : getAdPositionItem(item, onDeleteAdPosition, index, onChangeAdPosition)
  })
}

const getNewCuePointItem = (ipItem, onDeleteCuePoint, onSaveCuePoint, onChangeCuePoint) => {
  return <div className='ip-item new' key={'temp-ip-key'}>
    <input onChange={(e) => onChangeCuePoint(e, 0)} />
    {/* {ipItem.isLoading ? <Button loading className='save-loading' />
      : <SaveIcon color={ipItem.position ? '#343a40' : '#AFAFAF'} onClick={ipItem.position ? () => onSaveCuePoint(ipItem) : () => {}} />}
    <div className='separator' />
    <DeleteIcon color={ipItem.isLoading ? '#AFAFAF' : '#343a40'} onClick={ipItem.isLoading ? () => {} : () => onDeleteCuePoint(ipItem.position, 0)} /> */}
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteCuePoint(ipItem.position, 0)} />
  </div>
}

const getCuePointItem = (ipItem, onDeleteCuePoint, index, onChangeCuePoint) => {
  return <div className={`ip-item ${ipItem.isError === true ? 'error' : ''}`} key={ipItem.ip}>
    <input value={ipItem.position} onChange={(e) => onChangeCuePoint(e, index)} />
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteCuePoint(ipItem.position, index)} />
  </div>
}

const getCuePointList = (cuePoints, onDeleteCuePoint, onSaveCuePoint, onChangeCuePoint) => {
  return cuePoints.map((item, index) => {
    return item.isNew ? getNewCuePointItem(item, onDeleteCuePoint, onSaveCuePoint, onChangeCuePoint) : getCuePointItem(item, onDeleteCuePoint, index, onChangeCuePoint)
  })
}

const getNewBreakDurationItem = (ipItem, onDeleteBreakDuration, onSaveBreakDuration, onChangeBreakDuration) => {
  return <div className='ip-item new' key={'temp-ip-key'}>
    <input onChange={(e) => onChangeBreakDuration(e, 0)} />
    {/* {ipItem.isLoading ? <Button loading className='save-loading' />
      : <SaveIcon color={ipItem.position ? '#343a40' : '#AFAFAF'} onClick={ipItem.position ? () => onSaveBreakDuration(ipItem) : () => {}} />}
    <div className='separator' />
    <DeleteIcon color={ipItem.isLoading ? '#AFAFAF' : '#343a40'} onClick={ipItem.isLoading ? () => {} : () => onDeleteBreakDuration(ipItem.position, 0)} /> */}
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteBreakDuration(ipItem.position, 0)} />
  </div>
}

const getBreakDurationItem = (ipItem, onDeleteBreakDuration, index, onChangeBreakDuration) => {
  return <div className={`ip-item ${ipItem.isError === true ? 'error' : ''}`} key={ipItem.ip}>
    <input value={ipItem.position} onChange={(e) => onChangeBreakDuration(e, index)} />
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteBreakDuration(ipItem.position, index)} />
  </div>
}

const getBreakDurationList = (breakDurations, onDeleteBreakDuration, onSaveBreakDuration, onChangeBreakDuration) => {
  return breakDurations.map((item, index) => {
    return item.isNew ? getNewBreakDurationItem(item, onDeleteBreakDuration, onSaveBreakDuration, onChangeBreakDuration) : getBreakDurationItem(item, onDeleteBreakDuration, index, onChangeBreakDuration)
  })
}

const EditAdManagementDetails = ({ onAddNewAdPosition, adPositions, onDeleteAdPosition, onSaveAdPosition, onChangeAdPosition, cuePoints, onAddNewCuePoint, onChangeCuePoint, onSaveCuePoint, onDeleteCuepoint, breakDurations, onAddNewBreakDuration, onChangeBreakDuration, onSaveBreakDuration, onDeleteBreakDuration, ...props }) => (
  <div className='ad-container'>
    <div className='ip-whitelist-container'>
      <div className='whitelist-header'>
        <span>AD Positions</span>
      </div>
      <div className='whitelist-body'>
        {adPositions && adPositions.length ? <div className='ip-whiteList' id='ip-whitelist-body'>
          {getAdPositionList(adPositions, onDeleteAdPosition, onSaveAdPosition, onChangeAdPosition)}
        </div> : <Empty />}
      </div>
      <div className='whitelist-footer'>
        <Button onClick={onAddNewAdPosition} disabled={(adPositions.filter(item => item.isNew).length)}>Add AD Position</Button>
      </div>
    </div>
    <div className='ip-whitelist-container'>
      <div className='whitelist-header'>
        <span>Cue Points</span>
      </div>
      <div className='whitelist-body'>
        {cuePoints && cuePoints.length ? <div className='ip-whiteList' id='ip-whitelist-body'>
          {getCuePointList(cuePoints, onDeleteCuepoint, onSaveCuePoint, onChangeCuePoint)}
        </div> : <Empty />}
      </div>
      <div className='whitelist-footer'>
        <Button onClick={onAddNewCuePoint} disabled={(cuePoints.filter(item => item.isNew).length)}>Add Cue Points</Button>
      </div>
    </div>
    <div className='ip-whitelist-container'>
      <div className='whitelist-header'>
        <span>Break Durations </span>
      </div>
      <div className='whitelist-body'>
        {breakDurations && breakDurations.length ? <div className='ip-whiteList' id='ip-whitelist-body'>
          {getBreakDurationList(breakDurations, onDeleteBreakDuration, onSaveBreakDuration, onChangeBreakDuration)}
        </div> : <Empty />}
      </div>
      <div className='whitelist-footer'>
        <Button onClick={onAddNewBreakDuration} disabled={(breakDurations.filter(item => item.isNew).length)}>Add Break Durations</Button>
      </div>
    </div>
  </div>
)

export default EditAdManagementDetails
