import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    getAsset(
        id: $id
        project: $project
    ) {
        id
        shortId
        externalId
        type
        title
        shortTitle
        description
        duration
        shortDescription
        updatedAt
        updatedBy   
        duration
        externalId
        type
        title
    }
}`)
