import * as React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, DatePicker } from 'antd'
import moment from 'moment'
import DropDown from '../inputs/DropDown'

// const { RangePicker } = DatePicker

const timezoneList = [
  {
    id: 'AEDT',
    name: 'AEDT'
  },
  {
    id: 'AEST',
    name: 'AEST'
  }
]

class AddAssetChannelModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedChannel: 'Select channel',
      startDate: '',
      endDate: '',
      startZone: '',
      endZone: '',
      selectedId: '',
      isEditable: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.isFromEdit !== this.props.isFromEdit) {
      const { details } = nextProps
      this.setState({
        startDate: moment(details.broadCastStartTime),
        endDate: details.broadCastEndTime ? moment(details.broadCastEndTime) : '',
        selectedId: details && details.channel ? details.channel.id : '',
        selectedChannel: details && details.channel ? details.channel.name : 'Select channel'
      }, () => {
        this.setTimezone()
      })
    }
  }

  setTimezone = () => {
    const { startDate, endDate } = this.state
    const isStartDST = moment(startDate).isDST()
    const isStartNextHourDST = moment(startDate).add(1, 'hours').isDST()
    // const isStartBeforeHourDST = moment(startDate).subtract(1, 'hours').isDST()
    const isEndDST = moment(endDate).isDST()
    const isEndNextHourDST = moment(endDate).add(1, 'hours').isDST()
    // const isEndBeforeHourDST = moment(endDate).subtract(1, 'hours').isDST()
    if ((isStartDST && !isStartNextHourDST)) {
      this.setState({ startZone: isStartDST ? 'AEDT' : 'AEST' })
    }
    if ((isEndDST && !isEndNextHourDST)) {
      this.setState({ endZone: isEndDST ? 'AEDT' : 'AEST' })
    }
  }

    handleCancel = () => {
      if (this.props.handleCancel) {
        this.props.handleCancel()
        this.setState({ isEditable: false })
      }
    }

    handleSubmit = () => {
      const { selectedId, startDate, endDate } = this.state
      const programId = this.props.isFromEdit ? this.props.details.id : undefined
      if (this.props.handleSubmit) {
        this.props.handleSubmit(selectedId, startDate, endDate, programId)
        this.setState({ isEditable: false })
      }
    }

    onOptionSelect = (selectedChannel, option) => {
      this.setState({ selectedChannel, selectedId: option.key, isEditable: true })
    }

    onZoneSelect = (selectedZone, isStart) => {
      const { startDate, endDate } = this.state

      if (isStart) {
        const isDST = moment(startDate).isDST()
        const isNextHourDST = moment(startDate).add(1, 'hours').isDST()
        const isBeforeHourDST = moment(startDate).subtract(1, 'hours').isDST()
        if ((isDST && !isNextHourDST) || (isDST && !isBeforeHourDST)) {
          this.setState({ startDate: moment(startDate).add(1, 'hours') })
        }
        if ((!isDST && isNextHourDST) || (!isDST && isBeforeHourDST)) {
          this.setState({ startDate: moment(startDate).subtract(1, 'hours') })
        }
        this.setState({ startZone: selectedZone, isEditable: true })
      } else {
        const isDST = moment(endDate).isDST()
        const isNextHourDST = moment(endDate).add(1, 'hours').isDST()
        const isBeforeHourDST = moment(endDate).subtract(1, 'hours').isDST()
        if ((isDST && !isNextHourDST) || (isDST && !isBeforeHourDST)) {
          this.setState({ endDate: moment(endDate).add(1, 'hours') })
        }
        if ((!isDST && isNextHourDST) || (!isDST && isBeforeHourDST)) {
          this.setState({ endDate: moment(endDate).subtract(1, 'hours') })
        }
        this.setState({ endZone: selectedZone, isEditable: true })
      }
    }

    calculateDuration = () => {
      const { startDate, endDate } = this.state
      if (startDate && endDate) {
        const duration = moment.duration(endDate.diff(startDate))
        const totalMinutes = duration.asMinutes()
        const hours = Math.floor(totalMinutes / 60)
        const minutes = Math.round(totalMinutes % 60)
        return ((hours < 10 ? '0' + hours : hours) + 'h ' + (minutes < 10 ? '0' + minutes : minutes) + 'm ')
      } else return null
    }

    onChangeDate = (selectedDate, flag) => {
      const date = selectedDate ? selectedDate.set({ second: 0, millisecond: 0 }) : undefined
      const isDST = moment(date).isDST()
      const isNextDst = moment(moment(date).add(1, 'hours')).isDST()
      if (flag) {
        if (isDST && !isNextDst) {
          this.setState({ startDate: date, isEditable: true, startZone: isDST ? 'AEDT' : 'AEST' })
        } else {
          this.setState({ startDate: date, isEditable: true, startZone: '' })
        }
      } else {
        if (isDST && !isNextDst) {
          this.setState({ endDate: date, isEditable: true, endZone: isDST ? 'AEDT' : 'AEST' })
        } else {
          this.setState({ endDate: date, isEditable: true, endZone: '' })
        }
      }
    }

    range = (start, end) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }

    checkSaveable = () => {
      const { type } = this.props
      const { selectedChannel, startDate, endDate, isEditable } = this.state
      let isValidDate = !_.isEmpty(startDate) && !_.isEmpty(endDate)
      if (type === 'LINEAR-CHANNEL') {
        isValidDate = !_.isEmpty(startDate)
      }
      return selectedChannel && selectedChannel !== 'Select channel' && isEditable && isValidDate
    }

    clearState = () => {
      this.setState({
        selectedChannel: 'Select channel',
        startDate: '',
        endDate: '',
        startZone: '',
        endZone: ''
      })
    }

    render () {
      const { isVisible, channelList, isFromEdit, isLoading, details } = this.props
      const { startDate, endDate, selectedChannel, startZone, endZone } = this.state
      const duration = this.calculateDuration()
      const invalidDate = duration && (duration.indexOf('-') !== -1 || duration === '00h 00m ')
      return <Modal
        className={`add-channel${!this.checkSaveable() ? ' disabled-button' : ''}`}
        title={isFromEdit ? 'EDIT CHANNEL' : 'ADD CHANNEL'}
        maskClosable={false}
        visible={isVisible}
        okText={isFromEdit ? 'Save Channel' : 'Add Channel'}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        closable={false}
        centered
        width='500px'
        destroyOnClose
        okButtonProps={{ disabled: !(this.checkSaveable() && !invalidDate), loading: isLoading }}
        afterClose={this.clearState}
      >
        <div className='add-channel-container' id='add-channel-container'>
          <span>Channel</span>
          <DropDown displayParam='name'
            valueParam='name'
            onOptionSelect={this.onOptionSelect}
            selectedValue={isFromEdit ? ((selectedChannel !== 'Select channel' || !details.channel) ? selectedChannel : details.channel.name) : selectedChannel}
            options={channelList}
            placeHolder='Select Channel'
            parent='add-channel-container' />
          <span>Broadcast Date and Time</span>
          <div className='range-picker-container'>
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format='DD-MMM-YYYY HH:mm'
              placeholder={'Start Time'}
              showToday={false}
              onChange={(value) => this.onChangeDate(value, true)}
              // onOk={(value) => this.onChangeDate(value, true)}
              defaultValue={(isFromEdit && details) ? moment(details.broadCastStartTime) : undefined}
            />
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format='DD-MMM-YYYY HH:mm'
              placeholder={'End Time'}
              showToday={false}
              onChange={(value) => this.onChangeDate(value, false)}
              // onOk={(value) => this.onChangeDate(value, false)}
              defaultValue={isFromEdit && details.broadCastEndTime ? moment(details.broadCastEndTime) : undefined}
            />
          </div>
          <div className='time-zone-picker-container'>
            {startZone ? <DropDown displayParam='name'
              valueParam='name'
              onOptionSelect={(value) => this.onZoneSelect(value, true)}
              selectedValue={startZone}
              options={timezoneList}
              placeHolder='Select Timezone'
              parent='add-channel-container' /> : ''}
            {(endZone && !startZone) ? <div className='drop-down' /> : ''}
            {endZone ? <DropDown displayParam='name'
              valueParam='name'
              onOptionSelect={(value) => this.onZoneSelect(value, false)}
              selectedValue={endZone}
              options={timezoneList}
              placeHolder='Select Timezone'
              parent='add-channel-container' /> : ''}
            {(startZone !== '' && endZone === '') ? <div className='drop-down' /> : ''}
          </div>
          {startDate && endDate && !invalidDate ? <div className='time-duration'>
            <span>{'Duration'}</span>
            <span className={`${duration ? 'duration' : 'placeholder'}`}>{duration || 'HH:MM'}</span>
          </div> : null}
          {invalidDate ? <div className='invalid-date'> Please select a valid start/end time</div> : null}
        </div>
      </Modal>
    }
}

export default AddAssetChannelModal

AddAssetChannelModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool.isRequired,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Selected value  */
  selectedValue: PropTypes.string,
  /** n List of channels to be shown in dropdown */
  channelList: PropTypes.arrayOf(PropTypes.object),
  /** Boolean to know channel details add or edit */
  isFromEdit: PropTypes.bool,
  /** Saved channl details */
  details: PropTypes.object,
  /** Loading boolean */
  isLoading: PropTypes.bool
}
