import gql from 'graphql-tag'

export default gql(`
query listPublishingGroups($project: String){
    listPublishingGroups(project: $project){
        items{
          id
          name
          status
          isEnvironment
          isDefault
          priority
        }
      }
}`)
