import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Radio, Popconfirm } from 'antd'

import GroupDragAndDropIcon from './../general/icons/GroupDragAndDropIcon'
import Input from '../dataEntry/inputs/Input'
import DropDown from '../dataEntry/inputs/DropDown'
import Switch from '../dataEntry/inputs/Switch'
import CrossIcon from './../general/icons/CrossIcon'
import MetaDefaultValueInput from './../dataEntry/inputs/MetaDefaultValuesInput'
import _ from 'lodash'
import './../ui.style.scss'
import { utilityService } from '../../../services/UtilityService'

const listType = [
  {
    name: 'BOOLEAN',
    value: 'BOOLEAN'
  }, {
    name: 'STRING',
    value: 'STRING'
  }, {
    name: 'INTEGER',
    value: 'INTEGER'
  }, {
    name: 'LIST',
    value: 'LIST'
  }
]
class GeneralFieldCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      details: props.field,
      showWarning: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.field, this.props.field)) {
      this.setState({ details: nextProps.field })
    }
  }

  onCloseField = (index) => {
    this.props.onCloseField(index)
  }

  onRemoveProperty = () => {
    this.setState({ showWarning: true })
  }

  closeWarningMessage=() => {
    this.setState({ showWarning: false })
  }

  render () {
    const { details, showWarning } = this.state
    const { metaSectionLists, placeHolder1, placeHolder2, placeHolder3, index, onOptionSelect, handleChange, handleDefaultValues, handleToolTip, onChangeStatus, nameParam, isSports, isDisabled, type, onChangeEnabled, optionList, selectedType, isFromEdit, onChangeUseAsTitle, onChangeUpdatableStatus, onChangePropertyTooltipText, enableButton } = this.props
    const groupId = isSports ? utilityService.makeRandomString(6) : 'group'

    return (
      <div className='channel-stream-cell channel-stream-general'>
        <div className='stream-name'>
          {isDisabled ? <div className='disable-drag' /> : <GroupDragAndDropIcon />}
          <Input title='Title' placeholder={placeHolder1} inputName='title' value={details[nameParam]} handleChange={(e) => handleChange(e, index)} readOnly={isDisabled} />
        </div>
        {type !== 'app' ? <div className='group' id={groupId}>
          <DropDown options={type === 'appConfig' || type === 'appWidget' || type === 'channel-steam' ? optionList : listType}
            onOptionSelect={(value) => onOptionSelect(value, index, 'type')} selectedValue={details.type} valueParam='value' displayParam='name' placeHolder={placeHolder2} parent={groupId} disabled={type === 'channel-steam' ? !details.isEnable : isDisabled || isFromEdit} />
        </div> : null}
        {type === 'meta' ? <div className='group' id={groupId}>
          <DropDown options={metaSectionLists} allowClear onOptionSelect={(value) => onOptionSelect(value, index, 'section')} selectedValue={details.section} valueParam='id' displayParam='title' placeHolder={placeHolder3} parent={groupId} disabled={type === 'channel-steam' ? !details.isEnable : isDisabled || isFromEdit} />
        </div> : null}
        {type === 'app' ? <div className='stream-name'>
          <Input title='Key' placeholder={placeHolder2} inputName='title' value={details.key} handleChange={(e) => handleChange(e, index)} readOnly={isDisabled} />
        </div> : null}
        {type === 'appConfig' ? <div className='stream-name'>
          <Input title='Text' placeholder={'Enter Tooltip text'} inputName='title' value={details.tooltipText} handleChange={(e) => onChangePropertyTooltipText(e, index)} readOnly={false} />
        </div> : null}
        {type === 'app' ? <div className='stream-name'>

          <Switch isChecked={details.isEnable} onChange={(status) => onChangeEnabled(status, index)} />
        </div> : null}
        {type === 'appWidget' || (type === 'appConfig' && selectedType === 'CONFIG_LIST') ? (<div className='useAsTitle'>
          {details.type === 'STRING' ? <Radio value={details.id} onChange={(e) => onChangeUseAsTitle(e, index)} checked={details.useAsTitle} /> : null }
        </div>) : null}

        {type === 'meta'
          ? <div className='stream-name' >
            <Input placeholder={'Tooltip'} inputName='tooltip' value={details.tooltip} handleChange={(e) => handleToolTip(e, index)} readOnly={isDisabled} />
          </div> : null }

        {type === 'meta' || type === 'content-meta' ? <div className='default-values' >
          <MetaDefaultValueInput enableButton={enableButton} title='Default Values' type={details.type} placeholder={'Enter Default Values'} inputName='defaultValues' value={details.values ? details.values : []} handleChange={handleDefaultValues} index={index}
            disabled={details.type === 'BOOLEAN' || details.type === 'INTEGER'}
            readOnly
          />
        </div> : null }

        {type === 'appWidget' ? <div className='useAsTitle'><Checkbox checked={details.isUpdatable} onChange={(e) => onChangeUpdatableStatus(e, index)} /></div> : null}

        <div className={'required'}>
          {type !== 'app' ? <Checkbox checked={details.isRequired} onChange={(e) => onChangeStatus(e, index)} disabled={details.type === 'BOOLEAN'} /> : null}

          {isDisabled ? <div className='disable-close' /> : (type !== 'appConfig' && type !== 'app' ? <CrossIcon onClick={() => this.onCloseField(index)} /> : null)}
          {isDisabled ? <div className='disable-close' /> : (type === 'appConfig' || type === 'app' ? <Popconfirm
            visible={showWarning}
            placement='top'
            title={'Are you sure you want to delete this?'}
            okText='Yes'
            cancelText='No'
            onConfirm={() => {
              this.props.onCloseField(index)
              this.setState({ showWarning: false })
            }}
            onCancel={this.closeWarningMessage}>
            <CrossIcon onClick={this.onRemoveProperty} color={type === 'appConfig' ? '#424A52' : null} module={'appConfig'} />
          </Popconfirm> : null)}
        </div>
      </div>
    )
  }
}
GeneralFieldCell.propTypes = {
  /** file name of GeneralFieldCell. */
  name: PropTypes.string,
  /** close action of GeneralFieldCell. */
  onClose: PropTypes.func,
  /** File index of GeneralFieldCell. */
  index: PropTypes.number,
  /** List of options */
  optionList: PropTypes.array,
  /** Placeholder name of column 1 */
  placeHolder1: PropTypes.string,
  /** Placeholder name of column 2 */
  placeHolder2: PropTypes.string,
  /** Parameter name */
  nameParam: PropTypes.string
}

GeneralFieldCell.defaultProps = {
  nameParam: 'name'
}

export default GeneralFieldCell
