import gql from 'graphql-tag'

export default gql(`
mutation deleteFilter($id: ID!, $project: String){
    deleteFilter(input:{
      id: $id
    }
    project: $project
    ){
        id
    }
  }
`)
