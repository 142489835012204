import React from 'react'
import LoggerService from './../services/LoggerService'

class Logger extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error }
  }

  componentDidCatch (error, info) {
    // You can also log the error to an error reporting service
    LoggerService.error(error.message, error.stack)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{this.state.hasError}</h1>
    }

    return this.props.children
  }
}

export default Logger
