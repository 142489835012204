import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import './../../ui.style.scss'
import Input from './../inputs/Input'
import { utilityService } from '../../../../services/UtilityService'

class CreateMetaSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sectionName: null
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.activeSelection) {
      const selectedSection = this.props.metaSectionLists.find((item) => item.id === newProps.activeSelection)
      this.setState({ sectionName: selectedSection && selectedSection.title })
    }
  }

    handleTextChange = (event) => {
      this.setState({ sectionName: event.target.value })
    }

    handleCancel = () => {
      this.setState({ isLoading: false, sectionName: null })
      this.props.handleCancel()
    }

    submitSection = () => {
      const duplicateItem = this.props.metaSectionLists.find(item => item.title.toLowerCase().trim() === this.state.sectionName.toLowerCase().trim())
      if (duplicateItem) {
        message.warning('Meta Section name already exist')
        return null
      }
      this.setState({ isLoading: true })
      if (this.props.activeSelection) {
        this.props.updateSection(this.props.activeSelection, this.state.sectionName).then(() => {
          this.props.onHistoryUpdate()
          this.handleCancel()
        }, (errorMessage) => {
          utilityService.handleError(errorMessage)
        })
      } else {
        this.props.createNewSection(this.state.sectionName).then(() => {
          this.props.onHistoryUpdate()
          this.handleCancel()
        }, (errorMessage) => {
          utilityService.handleError(errorMessage)
        })
      }
    }

    onChangeSectionName = (e) => {
      const { value } = e.target
      this.setState({ sectionName: value })
    }

    render () {
      const { isVisible, activeSelection } = this.props
      const { isLoading } = this.state
      return (
        <Modal
          className='confirm-modal edit-image'
          title={activeSelection ? 'EDIT META SECTION' : 'NEW META SECTION'}
          maskClosable={false}
          visible={isVisible}
          okText={activeSelection ? 'Update' : 'Save'}
          cancelText='Back'
          onOk={this.submitSection}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: (!this.state.sectionName) }}
          closable={false}
          centered
          afterClose={this.resetState}
          width='600px'
          confirmLoading={isLoading}
        >
          <div id='create-author-modal'>
            <div className={`create-author-modal-container ${this.props.module === 'ASSET_MANAGER' ? 'asset-create' : ''}`} />
            <div className='input-item'>
              <Input title='Section Name' isRequired placeholder='Insert Section Name' limit={40} inputName='title' value={this.state.sectionName} handleChange={this.onChangeSectionName} />
            </div>
          </div>
        </Modal>
      )
    }
}

CreateMetaSection.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Details of selected image */
  selectedImage: PropTypes.array,
  /** Function to save the author */
  createAuthor: PropTypes.func,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to indicates update media is disabled */
  isUpdateBlocked: PropTypes.bool,
  /** Module name */
  module: PropTypes.string,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

CreateMetaSection.defaultProps = {
}

export default CreateMetaSection
