import React from 'react'
import PropTypes from 'prop-types'

const GroupCompetitionIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M-2 0h20v20H-2z' />
      <path fill='#343A40' fillRule='nonzero' d='M13.544 0H3.452v1.81H0v2.685c0 1.93 1.549 3.498 3.452 3.498h.06c.32 2.097 1.897 3.768 3.921 4.21V15.1H6.195a.694.694 0 0 0-.69.699v.92H4.45V20h8.102v-3.282h-1.056v-.92a.694.694 0 0 0-.69-.698H9.567v-2.897c2.028-.442 3.601-2.117 3.92-4.21h.06C15.452 7.993 17 6.424 17 4.495V1.81h-3.452V0h-.004zM3.452 6.224c-.94 0-1.706-.777-1.706-1.729V3.58h1.706v2.644zm7.337-1.25l-1.037.886.323 1.337c.06.249-.21.45-.428.314l-1.149-.727-1.154.723a.286.286 0 0 1-.427-.315l.323-1.336-1.033-.883c-.193-.167-.093-.49.161-.506l1.352-.098.516-1.275a.283.283 0 0 1 .528 0l.512 1.27 1.352.099c.254.02.354.343.16.51zm4.457-1.394v.915c0 .952-.763 1.729-1.702 1.729V3.58h1.702z' />
    </g>
  </svg>
)

GroupCompetitionIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

GroupCompetitionIcon.defaultProps = {
  width: 17,
  height: 20
}

export default GroupCompetitionIcon
