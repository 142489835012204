import gql from 'graphql-tag'

export default gql(`
    mutation trackLogout($user: String! $project: String $userAgent: String) {
        trackLogout(
            user: $user
            project: $project
            userAgent: $userAgent
        ) {
            id
        }
    }
`)
