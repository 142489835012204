import gql from 'graphql-tag'

export default gql(`
query getEpg {
    getEpgConfigSetting {
        id
        status
        url
        frequency
        fileName
        lastRunTime
    }
}`)
