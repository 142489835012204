import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateLanguageModal from '../../../components/ui/dataEntry/other/CreateLanguageModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryLanguage from './../../../graphQL/admin/entertainmentData/listLanguage'
import MutationDeleteLanguage from '../../../graphQL/admin/entertainmentData/deleteLanguage'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

const deleteLanguageMessage = {
  title: 'DELETE LANGUAGE',
  firstMessage: 'Are you sure you want to delete this Language?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class LanguageList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedLanguage: null,
      isLoading: !(props.languageList && props.languageList.length),
      showAddLanguageModal: false,
      showDeleteLanguageModal: false,
      selectedLanguage: undefined,
      deleteLoading: false,
      isDeleted: false,
      languageSearch: { searchString1: '', searchString2: '' }
    }
    this.columns = [
      {
        title: 'Language',
        dataIndex: 'name',
        key: 'name',
        width: '37%',
        className: 'entertainment-data'
      },
      {
        title: 'Usage Count',
        dataIndex: 'assetCount',
        width: '50%',
        key: 'assetCount',
        render: (assetCount, { isError }) => this.renderAssetCount(assetCount, isError)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.languageList, this.props.languageList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.languageList, this.props.languageList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEqual(newProps.languageList, this.props.languageList) && this.props.languageFilter && this.props.languageFilter.searchString) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.languageList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedLanguage) {
        this.setState({ openedLanguage: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedLanguage === id) {
        this.setState({ openedLanguage: null })
      } else {
        this.setState({ openedLanguage: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { languageList } = this.props
      const selectedLanguage = languageList.find(item => item.id === id)
      if (!selectedLanguage) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddLanguageModal: true, selectedLanguage })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteLanguageModal: true, selectedLanguage })
      }
      this.setState({ openedLanguage: null })
    }

    onLanguageSelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedLanguage: selectedValue })
      } else {
        this.setState({ fromSelectedLanguage: selectedValue })
      }
    }

    showAddLanguage = () => {
      this.setState({ showAddLanguageModal: true })
    }

    hideAddLanguageModal = () => {
      this.setState({ showAddLanguageModal: false, selectedLanguage: null })
    }

    handleAddLanguage = () => {
      this.setState({ showAddLanguageModal: false, selectedLanguage: null })
    }

    hideDeleteLanguageModal = () => {
      this.setState({ isDeleted: true, selectedLanguage: null }, () => {
        this.setState({ showDeleteLanguageModal: false, isDeleted: false })
      })
    }

    handleDeleteLanguage = () => {
      const { selectedLanguage } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedLanguage) {
        return
      }
      this.setState({ deleteLoading: true })
      this.props.deleteLanguage(selectedLanguage.id, selectedLanguage.name).then(() => {
        message.success('Language deleted successfully')
        this.setState({ isDeleted: true, selectedLanguage: null }, () => {
          this.setState({ showDeleteLanguageModal: false, deleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedLanguage: null }, () => {
          this.setState({ showDeleteLanguageModal: false, deleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    onSearchOptions = (value, isFirst) => {
      let { languageSearch } = this.state
      if (isFirst) {
        languageSearch.searchString1 = value
      } else {
        languageSearch.searchString2 = value
      }
      this.setState({ languageSearch })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangeLanguageFilter({ searchString: value })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.languageList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreLanguages(this.props.languageList.length))
    }

    renderAssetCount = (count, isError) => {
      if (isError) {
        return <div>{'Error in Transfer. Retry'}</div>
      } else if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedLanguage } = this.state
      const { languageList } = this.props
      const selectedLanguage = languageList.find(item => item.id === id)
      if (selectedLanguage && (!selectedLanguage.assetCount || !parseInt(selectedLanguage.assetCount)) && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (!selectedLanguage.assetCount || !parseInt(selectedLanguage.assetCount)) {
        // availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedLanguage === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { languageList, languageFilter, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showAddLanguageModal, showDeleteLanguageModal, selectedLanguage, deleteLoading, isDeleted } = this.state
      let adminItem
      if (selectedLanguage && showDeleteLanguageModal) {
        adminItem = <div >
          <p>{ selectedLanguage.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal entertainment-list'>
              <FilterInput
                searchString={languageFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
                project={project}
              />
              <div className='admin-list language-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > languageList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={languageList} pagination={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddLanguage}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreateLanguageModal
                isVisible={showAddLanguageModal}
                handleCancel={this.hideAddLanguageModal}
                handleSubmit={this.handleAddLanguage}
                selectedLanguage={selectedLanguage}
                listApi module='DATA_MANAGER'
                isSubmitDisabled={_.isEmpty(selectedLanguage) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
                searchString={languageFilter.searchString}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteLanguageModal}
                title={deleteLanguageMessage.title}
                firstMessage={deleteLanguageMessage.firstMessage}
                secondMessage={deleteLanguageMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteLanguageModal}
                handleSubmit={this.handleDeleteLanguage}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

LanguageList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryLanguage,
    {
      options: ({ languageFilter, project }) => {
        const { searchString } = languageFilter
        const search = searchString ? { keyword: searchString, fields: ['name'] } : null
        const variables = { search, offset: 0, limit: 20, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let languageList = data.listLanguage ? data.listLanguage.items : []
        languageList = languageList.map(item => {
          if (item.isTransferring) {
            item.assetCount = 'Transferring'
          }
          return item
        })
        return {
          languageList,
          isLoading: data.loading || !data.listLanguage,
          totalCount: data.listLanguage ? data.listLanguage.totalCount : 0,
          getMoreLanguages: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listLanguage.items, ...fetchMoreResult.listLanguage.items]
                prev.listLanguage.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteLanguage, {
      options: ({ languageFilter, project }) => ({
        update: (cache, { data: { deleteLanguage } }) => {
          const { searchString } = languageFilter
          const search = searchString ? { keyword: searchString, fields: ['name'] } : null
          const variables = { search, offset: 0, limit: 20, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryLanguage, variables }))
          if (cacheData && cacheData.listLanguage && cacheData.listLanguage.items) {
            const selectedIndex = cacheData.listLanguage.items.findIndex(item => item.id === deleteLanguage.id)
            if (selectedIndex > -1) {
              cacheData.listLanguage.items.splice(selectedIndex, 1)
              cacheData.listLanguage.totalCount = cacheData.listLanguage.totalCount - 1
            }
          }
          cache.writeQuery({
            query: QueryLanguage,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteLanguage: (id, name) => {
          return props.mutate({
            variables: { id, name, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(LanguageList))
