import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import DataPicker from './DatePicker'
import Switch from './Switch'
import moment from 'moment'

class BucketPublishDetailsCell extends Component {
    disableEndDate = (currentDate) => {
      let IsValidDate = moment(currentDate).isBefore(moment(new Date()), 'day')
      if (this.props.startDate) {
        IsValidDate = IsValidDate || moment(currentDate).isBefore(moment(this.props.startDate))
        return moment(currentDate).isBefore(moment(this.props.startDate), 'day')
      }
      return IsValidDate
    }

    disableStartDate = (currentDate) => {
      if (this.props.endDate) {
        return moment(currentDate).isAfter(moment(this.props.endDate), 'day')
      }
      return false
    }

    disabledRangeTime = (type) => {
      let { startDate, endDate } = this.props
      startDate = moment(startDate)
      endDate = moment(endDate)
      let minutes = []
      const isDateEqual = moment(startDate).format('DD MMM YYYY') === moment(endDate).format('DD MMM YYYY')
      if (type === 'end' && startDate && (isDateEqual || !endDate)) {
        let hours = this.range(0, 24)
        hours.splice(startDate.hours())
        if (!endDate || endDate.hours() === startDate.hours()) {
          minutes = this.range(0, 60)
          minutes.splice(startDate.minutes() + 1)
        }
        return {
          disabledHours: () => hours,
          disabledMinutes: () => minutes
        }
      } else if (type === 'start' && endDate && (isDateEqual || !startDate)) {
        let hours = this.range(0, 24).splice(endDate.hours() + 1)
        if (!startDate || endDate.hours() === startDate.hours()) {
          minutes = this.range(0, 60)
          minutes = minutes.splice(endDate.minutes())
        }
        return {
          disabledHours: () => hours,
          disabledMinutes: () => minutes
        }
      }
      return {
        disabledHours: () => [],
        disabledMinutes: () => []
      }
    }

    range = (start, end) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }

    disableTimeComplete = () => {
      const hours = this.range(0, 24)
      const minutes = this.range(0, 60)
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      }
    }

    render () {
      const { type, dateFormat, startDate, endDate, isActive, onChange, onStartDateChange, onEndDateChange, isLoading, module } = this.props
      return (
        <div className={module === 'appPage' ? 'page-publish-details-cell' : 'bucket-publish-details-cell'} >
          {module !== 'appPage' ? <label className='publish-title'>{ type }</label> : null}
          <div className='date'>
            <div className='date-selector'>
              <DataPicker
                use12Hours={false}
                date={startDate ? moment(new Date(startDate), dateFormat).format(dateFormat) : null}
                disabledDate={this.disableStartDate}
                disabledTime={startDate ? () => this.disabledRangeTime('start') : () => this.disableTimeComplete()}
                dateFormat={dateFormat}
                onChange={onStartDateChange}
                placeholder='Select Start Date'
                allowClear />
            </div>
            <span>&#x2015;</span>
            <div className='date-selector'>

              <DataPicker
                use12Hours={false}
                date={endDate ? moment(new Date(endDate), dateFormat).format(dateFormat) : null}
                disabledDate={this.disableEndDate}
                disabledTime={endDate ? () => this.disabledRangeTime('end') : () => this.disableTimeComplete()}
                dateFormat={dateFormat}
                onChange={onEndDateChange}
                placeholder='Select End Date' />
            </div>
          </div>
          <div className='publish'>

            <div className='switch-container'>
              <Switch isChecked={isActive} loading={isLoading} onChange={onChange} />
            </div>
          </div>
        </div>
      )
    }
}

BucketPublishDetailsCell.propTypes = {
  /** start publish date of BucketPublishDetailsCell. */
  type: PropTypes.string,
  /** start publish date of BucketPublishDetailsCell. */
  startDate: PropTypes.string,
  /** end publish date of BucketPublishDetailsCell. */
  endDate: PropTypes.string,
  /** pubish active status of BucketPublishDetailsCell. */
  isActive: PropTypes.bool,
  /** end publish date of BucketPublishDetailsCell. */
  dateFormat: PropTypes.string,
  /** publish status change action of BucketPublishDetailsCell. */
  onChange: PropTypes.any,
  /** publish start date  change action of BucketPublishDetailsCell. */
  onStartDateChange: PropTypes.any,
  /** publish start date change action of BucketPublishDetailsCell. */
  onEndDateChange: PropTypes.any
}

BucketPublishDetailsCell.defaultProps = {
  dateFormat: 'DD MMM YYYY, hh:mm A'
}

export default BucketPublishDetailsCell
