import * as React from 'react'
import { message } from 'antd'

import Accordion from '../../../components/ui/dataDisplay/Accordion'
import AppContext from '../../../AppContext'
import Input from './../../../components/ui/dataEntry/inputs/Input'
import LoadingButton from './../../../components/ui/general/buttons/LoadingButton'
import { utilityService } from './../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import MutationUpdateAuthConfig from '../../../graphQL/admin/updateModuleConfig'
import QueryUpdateAuthConfig from '../../../graphQL/admin/getModuleConfig'

class AuthConfigSetting extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loginAttempts: '',
      timeout: '',
      isLoading: false,
      enableUpdate: true
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.loginAttempts || newProps.timeout) {
      this.setState({ loginAttempts: newProps.loginAttempts, timeout: newProps.timeout })
    }
  }

  validateIntNumber = (e) => {
    const { handleHyperionGeneralChange } = this.props
    const regx = /^\d+$/
    const { value } = e.target
    const regxValidator = regx.test(value) && !isNaN(value)
    if (regxValidator || !value) {
      return handleHyperionGeneralChange(e)
    }
  }

  handleLoginAttempt = (e) => {
    const regx = /^\d+$/
    const { value } = e.target
    let loginAttempts = value
    const regxValidator = regx.test(loginAttempts) && !isNaN(loginAttempts)
    if (regxValidator || !value) {
      this.setState({
        loginAttempts,
        enableUpdate: false
      })
    } else {
      message.error('Enter Numeric Value')
    }
  }

  handleTimeout = (e) => {
    const regx = /^\d+$/
    const { value } = e.target
    let timeout = value
    const regxValidator = regx.test(timeout) && !isNaN(timeout)
    if (regxValidator || !value) {
      this.setState({
        timeout,
        enableUpdate: false
      })
    } else {
      message.error('Enter Numeric Value')
    }
  }

  handlConfigUpdate = () => {
    this.setState({ isLoading: true })
    const { loginAttempts, timeout } = this.state
    const value = [loginAttempts, timeout]
    this.props.updateModuleConfig(value).then(() => {
      this.props.onHistoryUpdate()
      message.success('Auth Config Setting Updated')
      this.setState({ isLoading: false, enableUpdate: true })
    }, (error) => {
      utilityService.handleError(error)
      this.setState({ isLoading: false, enableUpdate: true })
    })
  }

  render () {
    return <AppContext.Consumer>
      {({ permissions }) => {
        const userPermissions = permissions['DATA_MANAGER']
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        const { loginAttempts, timeout, isLoading, enableUpdate } = this.state
        const isAUthUpdateDisable = enableUpdate || isUpdateDisabled
        return <Accordion
          title='Auth Config Setting'
          childComponent={
            <div className='auth-config-admin-container'>
              <Input
                title='Maximum Number of Failed Login Attempts'
                placeholder='Enter Maximum Number of Failed Login Attempts'
                inputName='loginAttempts'
                handleChange={this.handleLoginAttempt}
                value={loginAttempts}
              />
              <Input
                title='Maximum Duration For Failed Attempts'
                placeholder='Enter Maximum Duration For Failed Attempts'
                inputName='timeout'
                handleChange={this.handleTimeout}
                value={timeout}
              />
              <LoadingButton
                buttonText='Update'
                isDisabled={isAUthUpdateDisable}
                isLoading={isLoading}
                onClick={this.handlConfigUpdate}
                buttonClass='auth-config-button'
              />
            </div>
          }
        />
      }}
    </AppContext.Consumer>
  }
}

export default withApollo(compose(
  graphql(
    QueryUpdateAuthConfig,
    {
      options: () => {
        return {
          fetchPolicy: 'network-only',
          variables: { id: 'user-login-config' }
        }
      },
      props: ({ data }) => {
        return {
          loginAttempts: data && data.getConfig ? data.getConfig.value && data.getConfig.value[0] : '',
          timeout: data && data.getConfig ? data.getConfig.value && data.getConfig.value[1] : ''
        }
      }
    }
  ),
  graphql(
    MutationUpdateAuthConfig,
    {
      props: (props) => ({
        updateModuleConfig: (value) => {
          return props.mutate({
            variables: {
              id: 'user-login-config',
              value,
              key: ['loginAttempts', 'timeout'],
              module: 'user-login-config',
              project: props.ownProps.project
            }
          })
        }
      })
    }
  )
)(AuthConfigSetting))
