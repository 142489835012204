import React from 'react'
import PropTypes from 'prop-types'

const DefaultImageIcon = ({ width, height, onClick, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M12.414 1.645c2.323.233 4.186 1.63 4.584 4.027h-1.344l2.17 3.228 2.17-3.228h-1.497c-.398-3.228-2.842-5.326-5.93-5.625-.427-.034-.823.332-.823.798.028.4.303.765.67.8zM7.586 18.32c-2.597-.233-4.584-1.963-4.675-4.826h1.436l-2.168-3.228-2.17 3.228h1.436c.06 3.695 2.657 6.09 5.99 6.424.427.033.825-.333.825-.8a.797.797 0 0 0-.674-.798zM20 12.8c0-.99-.75-1.8-1.667-1.8h-6.666C10.75 11 10 11.81 10 12.8v5.4c0 .99.75 1.8 1.667 1.8h6.666C19.25 20 20 19.19 20 18.2v-5.4zm-7.5 0c.458 0 .833.405.833.9s-.375.9-.833.9c-.458 0-.833-.405-.833-.9s.375-.9.833-.9zm6.667 5.4c0 .495-.375.9-.834.9h-6.5c-.375 0-.541-.495-.291-.765l1.5-1.62a.383.383 0 0 1 .583 0l.25.27c.167.18.417.18.583 0l2.75-2.97a.383.383 0 0 1 .584 0l1.25 1.35c.083.09.125.18.125.315v2.52zM10 1.8C10 .81 9.25 0 8.333 0H1.667C.75 0 0 .81 0 1.8v5.4C0 8.19.75 9 1.667 9h6.666C9.25 9 10 8.19 10 7.2V1.8zm-7.5 0c.458 0 .833.405.833.9s-.375.9-.833.9c-.458 0-.833-.405-.833-.9s.375-.9.833-.9zm6.667 5.4c0 .495-.375.9-.834.9h-6.5c-.375 0-.541-.495-.291-.765l1.5-1.62a.383.383 0 0 1 .583 0l.25.27c.167.18.417.18.583 0l2.75-2.97a.383.383 0 0 1 .584 0l1.25 1.35c.083.09.125.18.125.315V7.2z' />
    </g>
  </svg>
)

DefaultImageIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onClick: PropTypes.func,
  color: PropTypes.string
}

DefaultImageIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default DefaultImageIcon
