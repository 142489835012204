import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    getAsset(
        id: $id
        project: $project
    ) {
        id
        status
        type
        slug
        updatedAt
        publishRules{
            id
            group{
                id
            }
            isEnabled
            publishEndDate
            publishStartDate
        }
        match{
            id
            opId
            competition{
                id
                competitionCode
            }
        }
    }
}`)
