import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import './../../ui.style.scss'
import { generateCroppedThumbnail } from '../../../../util/util'

class BucketResetDisplayImageModal extends React.Component {
  render () {
    const { visible, handleCancel, title, bucketAssetData, handleSubmit } = this.props
    const customImage = bucketAssetData.customAssetImage ? generateCroppedThumbnail(bucketAssetData.customAssetImage, 150, 84, '16:9') : generateCroppedThumbnail(bucketAssetData.customMedia, 150, 84, '16:9')
    const defaultImage = generateCroppedThumbnail(bucketAssetData.asset.defaultMedia, 150, 84, '16:9')

    return (

      <Modal
        className='general-modal bucket-reset-display-image-modal'
        visible={visible}
        closable={false}
        title={title}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText='Reset'
        cancelText='Cancel'
        maskClosable={false}
        centered
        destroyOnClose
      >
        <p>Are you sure you want to reset this Display Image? </p>
        <div className='content-details'>
          <div className='image-details'>
            <p>Current Display Image:</p>
            <img src={customImage} />
          </div>
          <div className='image-details'>
            <p>Original Display Image:</p>
            <img src={defaultImage} />
          </div>
        </div>
      </Modal>
    )
  }
}

BucketResetDisplayImageModal.propTypes = {
  /** visible action of BucketResetDisplayImageModal. */
  visible: PropTypes.bool,
  /** title  of BucketResetDisplayImageModal. */
  title: PropTypes.string,
  /** handleCancel action of BucketResetDisplayImageModal. */
  handleCancel: PropTypes.func
}

BucketResetDisplayImageModal.defaultProps = {
}

export default BucketResetDisplayImageModal
