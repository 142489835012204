import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, Switch } from 'antd'
import Input from '../inputs/Input'
import _ from 'lodash'
import './../../ui.style.scss'
import moment from 'moment'

class EditCustomerProfileModalHyperion extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      dateOfBirth: '',
      receiveServiceUpdateSms: null,
      receiveServiceUpdateEmail: null,
      receiveBillReminderSms: null,
      receiveBillReminderEmail: null,
      isNameError: false,
      isEdited: false
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    const { data } = nextProps
    if (data && !(_.isEqual(data, this.props.data)) && !this.state.isEdited) {
      this.setState({
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
        dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).format('DD MMM YYYY') : '',
        email: data.email,
        receiveServiceUpdateSms: data.receiveServiceUpdateSms,
        receiveServiceUpdateEmail: data.receiveServiceUpdateEmail,
        receiveBillReminderSms: data.receiveBillReminderSms,
        receiveBillReminderEmail: data.receiveBillReminderEmail
      })
    }
  }

handleChangeFname = (event) => {
  const firstName = event.target.value
  this.setState({ firstName, isEdited: true, isNameError: !firstName })
}

handleChangeLname = (event) => {
  const lastName = event.target.value
  this.setState({ lastName, isEdited: true })
}

handleChangeMobileNumber = (event) => {
  const mobile = event.target.value
  this.setState({ mobile, isEdited: true })
}

// handleGeneralChange = (e) => {
//   const { details } = this.state
//   const { name, value } = e.target
//   if (!value.startsWith(' ')) {
//     if (name === 'opId') {
//       const regexExp = /^[0-9]*$/
//       const isNum = regexExp.test(value)
//       if (isNum) {
//         details[name] = value
//       }
//     } else {
//       details[name] = value
//     }
//     this.setState({ details }, () => this.props.onEditSyncState(details))
//   }
// }

handleChangeServiceSMS=(value) => {
  this.setState({ receiveServiceUpdateSms: value, isEdited: true })
}

handleChangeServiceEmail=(value) => {
  this.setState({ receiveServiceUpdateEmail: value, isEdited: true })
}

handleChangeBillingSMS=(value) => {
  this.setState({ receiveBillReminderSms: value, isEdited: true })
}

handleChangeBillingEmail=(value) => {
  this.setState({ receiveBillReminderEmail: value, isEdited: true })
}

onChangeDOB = (dateOfBirth) => {
  this.setState({ dateOfBirth, isEdited: true })
}

handleSubmit = () => {
  const { email, mobile, receiveServiceUpdateSms, receiveServiceUpdateEmail, receiveBillReminderSms, receiveBillReminderEmail } = this.state
  const { id } = this.props.data
  const regExp = /^[+]*[0-9]*$/g
  if (mobile && !regExp.test(mobile)) {
    message.warn('Invalid Mobile Number')
    return
  }
  const update = {
    receiveServiceUpdateSms: receiveServiceUpdateSms,
    receiveServiceUpdateEmail: receiveServiceUpdateEmail,
    receiveBillReminderSms: receiveBillReminderSms,
    receiveBillReminderEmail: receiveBillReminderEmail
  }
  // if (!update.postcode) { delete (update.postcode) }
  const data = {
    userId: id,
    email,
    update
  }
  this.props.handleSubmit(data)
}

    clearState = () => {
      const { data } = this.props
      if (data) {
        this.setState({
          firstName: data.firstName,
          lastName: data.lastName,
          mobile: data.mobile,
          dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).format('DD MMM YYYY') : '',
          email: data.email,
          isNameError: false,
          isEdited: false,
          receiveServiceUpdateSms: data.receiveServiceUpdateSms,
          receiveServiceUpdateEmail: data.receiveServiceUpdateEmail,
          receiveBillReminderSms: data.receiveBillReminderSms,
          receiveBillReminderEmail: data.receiveBillReminderEmail
        })
      }
    }

    render () {
      const { isVisible, isSubmitDisabled, handleCancel, isLoading } = this.props
      const { firstName, isNameError, lastName, mobile, email, isEdited, receiveServiceUpdateSms, receiveServiceUpdateEmail, receiveBillReminderSms, receiveBillReminderEmail } = this.state
      const isEnable = isEdited
      return (
        <Modal
          className='general-modal edit-customer'
          title={'EDIT CUSTOMER PROFILE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (isSubmitDisabled || !isEnable) }}
          closable={false}
          centered
          destroyOnClose
          confirmLoading={isLoading}
          afterClose={this.clearState}
        >
          { isVisible
            ? <div className='edit-cutomer-container'>
              <Input
                title='First Name'
                inputName='fName'
                handleChange={() => {}}
                value={firstName}
                placeholder='Enter first name'
                isError={isNameError}
                errorMessage='Please enter first name'
                readOnly />
              <Input
                title='Last Name'
                inputName='lName'
                handleChange={() => {}}
                value={lastName}
                placeholder='Enter last name'
                readOnly />
              {/* <div className='date-picker-container'>
                <label>Date of Birth</label>
                <DatePicker
                  value={dateOfBirth ? moment(dateOfBirth) : ''}
                  format='DD MMM YYYY'
                  onChange={() => {}}
                  placeholder='Please select date of birth'
                  allowClear
                  timeDisabled
                  disabled
                />
              </div> */}
              <Input
                title='Email'
                inputName='email'
                handleChange={() => {}}
                value={email}
                readOnly />
              <Input
                title='Mobile Number'
                inputName='mobile'
                handleChange={() => {}}
                value={mobile}
                placeholder='Enter mobile number'
                readOnly />
              <div className='switch-container'>
                <div className='switch-inner-container'>
                  <span>Service SMS</span>
                  <Switch
                    checked={receiveServiceUpdateSms}
                    onChange={this.handleChangeServiceSMS}
                  />
                </div>
                <div className='switch-inner-container'>
                  <span>Service Email</span>
                  <Switch
                    checked={receiveServiceUpdateEmail}
                    onChange={this.handleChangeServiceEmail}
                  />
                </div>
                <div className='switch-inner-container'>
                  <span>Billing Email</span>
                  <Switch
                    checked={receiveBillReminderEmail}
                    onChange={this.handleChangeBillingEmail}
                  />
                </div>
                <div className='switch-inner-container'>
                  <span>Billing SMS</span>
                  <Switch
                    checked={receiveBillReminderSms}
                    onChange={this.handleChangeBillingSMS}
                  />
                </div>
              </div>
            </div> : '' }
        </Modal>
      )
    }
}

EditCustomerProfileModalHyperion.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

export default EditCustomerProfileModalHyperion
