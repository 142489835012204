import React from 'react'
import PropTypes from 'prop-types'

const SeasonFilterIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <g stroke={color} strokeLinecap='round' strokeLinejoin='round' transform='translate(1 1)'>
        <rect width='18' height='16.2' y='1.8' rx='2' />
        <path d='M13 0v3.6M5 0v3.6M0 7.2h18' />
      </g>
    </g>
  </svg>
)

SeasonFilterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

SeasonFilterIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default SeasonFilterIcon
