import React from 'react'
import PropTypes from 'prop-types'
import SocialMedia from './SocialMedia'
import Input from './../inputs/Input'
import PopoverConfirm from './../../feedback/PopoverConfirm'
import './../../ui.style.scss'

const parentCompoent = 'asset-container'

class SocialMediaContent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isValidUrl: props.content && props.content.value,
      isSocialMediaUrl: false
    }
  }

  componentDidMount () {
    if (this.props.content && this.props.content.value) {
      let isSocialMediaUrl = this.onUrlValidate(this.props.content.value)
      this.setState({ isSocialMediaUrl })
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    let isSocialMediaUrl = this.onUrlValidate(newProps.content.value)
    this.setState({ isSocialMediaUrl })
  }

  setValidUrl = isValidUrl => {
    this.setState({ isValidUrl })
  }

  onSocialShareChange = (e) => {
    const { value } = e.target
    const { onSocialShareChange, id } = this.props
    onSocialShareChange(id, value)
  }

  onUrlValidate=(url) => {
    const twitterRegex = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/
    const twitterxRegex = /(?:http:\/\/)?(?:www\.)?x\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/
    const facebookRegex = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/
    const instagramRegex = /(https?:\/\/www\.)?instagram\.com(\/(tv|p|reel|stories|reels)\/\w+\/?)/
    const pinterestRegex = /(https?:\/\/in\.)?pinterest\.com(\/pin\/\w+\/?)/
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/

    if (twitterxRegex.test(url) || twitterRegex.test(url) || facebookRegex.test(url) || instagramRegex.test(url) || youtubeRegex.test(url) || pinterestRegex.test(url)) {
      return true
    }
    return false
  }

  manipulateURL = (url) => {
    // Changing the URL to fix the issue with the twitter link not working with x.com. [PSFV-247]
    if (url === null) return null
    const twitterxRegex = /(?:http:\/\/)?(?:www\.)?x\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/
    if (twitterxRegex.test(url)) {
      return url.replace('x.com', 'twitter.com')
    }
    return url
  }

  render () {
    const { isSocialMediaUrl, isValidUrl } = this.state
    const { content, onCloseContent, onInputFieldFocus, onInputFieldBlur } = this.props
    return (
      <div className={`social-media-content ${!content.value || !isValidUrl || !isSocialMediaUrl ? 'empty' : ''}`}>
        <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
        <div className='social-media-display' >
          { isSocialMediaUrl
            ? <SocialMedia href={this.manipulateURL(content.value)} name={this.manipulateURL(content.value) || ''} dataWidth={'313'} onUrlValidation={this.setValidUrl} /> : null
          }
          { !content.value || !this.state.isValidUrl || !this.state.isSocialMediaUrl ? <p> Preview</p> : null }
        </div>
        <Input
          isError={!!((!isSocialMediaUrl || !isValidUrl) && content.value)}
          title='Social Media Embed'
          placeholder='Enter URL'
          inputName='socialUrl'
          value={content.value}
          handleChange={this.onSocialShareChange}
          errorMessage={' Invalid URL'}
          onFocus={onInputFieldFocus}
          onBlur={onInputFieldBlur}
        />
      </div>
    )
  }
}

SocialMediaContent.propTypes = {
  /** identifier of SocialMediaContent. */
  id: PropTypes.string,
  /** content details of SocialMediaContent. */
  content: PropTypes.object,
  /** social media url change action of SocialMediaContent. */
  onSocialShareChange: PropTypes.func.isRequired,
  /** close action of SocialMediaContent. */
  onCloseContent: PropTypes.func.isRequired
}

SocialMediaContent.defaultProps = {
}

export default SocialMediaContent
