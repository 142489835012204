import React from 'react'
import PropTypes from 'prop-types'
import { Switch as AntSwitch } from 'antd'
import './../../ui.style.scss'

const Switch = ({ onChange, isChecked, loading, disabled, className, ...props }) => (
  <AntSwitch className={`${className || ''} switch`} defaultChecked onChange={onChange} checked={isChecked} loading={loading} disabled={disabled} />
)

Switch.propTypes = {
  /** Change action of Switch. */
  onChange: PropTypes.func,
  /** Checked status of Switch. Defaults to 'true' */
  isChecked: PropTypes.bool,
  /** Checked loading of Switch. Defaults to 'false' */
  loading: PropTypes.bool,
  /** Checked disable prop of Switch. Defaults to 'false' */
  disabled: PropTypes.bool,
  /** Class name for test case */
  className: PropTypes.string
}

Switch.defaultProps = {
  isChecked: true
}

export default Switch
