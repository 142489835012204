import gql from 'graphql-tag'

export default gql(`
    mutation($contents: [UpdateChannelConfigGroupInput], $channelId: ID) {
        batchUpdateChannelConfigGroup(input: $contents, channelId: $channelId ) {
                id
                schemaId{
                    id
                  }
                position
                version
                name
                fields{
                    name
                    value
                    isRequired
                    type
                }
                streams{
                    id
                    levelVersions{
                        streamId
                        version
                    }
                    fields{
                        name
                        value
                        isRequired
                        type
                    }
                    url
                    streamSchema{
                        id
                        levels{
                            id
                            name
                            version
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                        }
                    }
                }
        }
    }`
)
