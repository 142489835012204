import React from 'react'
import PropTypes from 'prop-types'

const DeleteIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill={color} fillRule='nonzero' d='M4 17c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5H4v12zM17 2h-3.5l-1-1h-5l-1 1H3v2h14V2z' />
    </g>
  </svg>
)

DeleteIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

DeleteIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default DeleteIcon
