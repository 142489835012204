import gql from 'graphql-tag'

export default gql(`
    mutation($resolutions: [CreateResolutionsInput]!) {
        createResolutions(input: $resolutions ) {
            width
            height
            id
          }
    }`
)
