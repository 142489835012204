import * as React from 'react'
import DropDown from '../../components/ui/dataEntry/inputs/DropDown'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import SortInput from '../../components/ui/dataEntry/inputs/SortInput'
import RetryButton from '../../components/ui/general/buttons/RetryButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import SidebarCloseButton from '../../components/ui/general/buttons/SidebarCloseButton'
import SidebarOpenButton from '../../components/ui/general/buttons/SidebarOpenButton'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const jobTypeList = [
  {
    name: 'Manual',
    id: 'MANUAL'
  },
  {
    name: 'Auto',
    id: 'AUTO'
  }
]

const jobStatusList = [
  {
    name: 'QUEUED',
    id: 'QUEUED'
  },
  {
    name: 'TRIGGERED',
    id: 'TRIGGERED'
  },
  {
    name: 'INGEST',
    id: 'INGEST'
  },
  {
    name: 'PROCESSING',
    id: 'PROCESSING'
  },
  {
    name: 'REGISTERED',
    id: 'REGISTERED'
  },
  {
    name: 'MP-PROCESSING',
    id: 'MP-PROCESSING'
  },
  {
    name: 'MC-PROCESSING',
    id: 'MC-PROCESSING'
  },
  {
    name: 'COMPLETE',
    id: 'COMPLETE'
  },
  {
    name: 'PUBLISHED',
    id: 'PUBLISHED'
  },
  {
    name: 'ERROR',
    id: 'ERROR'
  },
  {
    name: 'UNKNOWN',
    id: 'UNKNOWN'
  }
]
class JobManagerHeader extends React.Component {
  constructor (props) {
    super(props)
    this.isInitalSearchEnable = props.match && props.match.params && props.match.params.id
    this.state = {
      selectedGroup: '',
      selectedTime: this.isInitalSearchEnable ? '' : '1',
      selectedType: '',
      selectedStatus: '',
      jobSearchText: props.match && props.match.params && props.match.params.id ? props.match.params.id : null,
      initialSearch: this.isInitalSearchEnable
    }
    if (this.isInitalSearchEnable) {
      props.onSearchFilter(props.match.params.id)
    }
  }
  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.searchString && this.isInitalSearchEnable) {
      this.setState({ selectedTime: '' })
    } else if (!this.state.selectedTime) {
      this.setState({ selectedTime: '1' })
    }
  }

    onGroupSelect = (selectedGroup) => {
      this.setState({ selectedGroup }, () => {
        this.props.onGroupSelect(selectedGroup)
      })
    }

    onTimeSelect = (selectedTime) => {
      this.setState({ selectedTime }, () => {
        this.isInitalSearchEnable = false
        this.props.onTimeSelect(selectedTime)
      })
    }

    onTypeSelect = (selectedType) => {
      this.setState({ selectedType }, () => {
        this.props.onTypeSelect(selectedType)
      })
    }

    onStatusSelect = (selectedStatus) => {
      this.setState({ selectedStatus }, () => {
        this.props.onStatusSelect(selectedStatus)
      })
    }

    onSearchFilter = (jobSearchText) => {
      this.isInitalSearchEnable = true
      this.props.onSearchFilter(jobSearchText)
      this.setState({ jobSearchText, initialSearch: false })
    }

    render () {
      const { groupByList, timeList, parent, sort, onSortChange, isSidebarVisible, toggleSidebar,
        isRetryEnabled, onRetry, isRecreateLoading, changeFilterValue, downloadAll, project } = this.props
      const { selectedGroup, selectedTime, jobSearchText, initialSearch, selectedType, selectedStatus } = this.state
      return <div className='job-manager-header' id='job-manager-header'>
        <div className='job-header-left'>
          <DropDown
            options={groupByList}
            selectedValue={selectedGroup}
            placeHolder='Select Group'
            valueParam='id'
            displayParam='name'
            onOptionSelect={this.onGroupSelect}
            className={(selectedGroup || selectedGroup === null) ? 'selected' : ''}
            allowClear />
          <DropDown
            options={timeList}
            selectedValue={selectedTime}
            placeHolder='Select Time'
            valueParam='id'
            displayParam='name'
            onOptionSelect={this.onTimeSelect}
            className={(selectedTime || selectedTime === null) ? 'selected' : ''} />
          <DropDown
            options={jobTypeList}
            selectedValue={selectedType}
            placeHolder='Select Job Type'
            valueParam='id'
            displayParam='name'
            onOptionSelect={this.onTypeSelect}
            className={(selectedType || selectedType === null) ? 'selected' : ''}
            allowClear />
          <DropDown
            options={jobStatusList}
            selectedValue={selectedStatus}
            placeHolder='Select Job Status'
            valueParam='id'
            displayParam='name'
            onOptionSelect={this.onStatusSelect}
            className={(selectedStatus || selectedStatus === null) ? 'selected' : ''}
            allowClear />
          <div className='seperator' />
          <FilterInput
            searchString={jobSearchText}
            initialSearch={initialSearch}
            onChangeSearchInput={this.onSearchFilter}
            filterType={'JobManager'}
            parentId={parent}
            changeFilter={changeFilterValue}
            placement='bottomRight'
            project={project}
          />
          <div className='seperator' />
          <SortInput type='JobManager' selectedOrder={sort.order} selectedSort={sort.value} onSortChange={onSortChange} parent='job-manager-header' />
          <div className='download-all-button' onClick={downloadAll}><span>Download All(CSV)</span>
          </div>
        </div>
        <div className='job-header-right'>
          {isSidebarVisible ? <ButtonContainer displayTitle='Hide job details' childComponent={<SidebarCloseButton onClick={toggleSidebar} />} /> : <ButtonContainer displayTitle='Show job details' childComponent={<SidebarOpenButton onClick={toggleSidebar} />} />}
          <ButtonContainer displayTitle='Recreate selected job' placement='topRight' childComponent={<RetryButton isDisabled={!isRetryEnabled} onClick={onRetry} isLoading={isRecreateLoading} />} />
        </div>
      </div>
    }
}

JobManagerHeader.propTypes = {
  /** List of groups for job categorization */
  groupByList: PropTypes.array,
  /** Function to be called on selection of groups */
  onGroupSelect: PropTypes.func,
  /** List of times for job categorization */
  timeList: PropTypes.array,
  /** Function to be called on selection of time */
  onTimeSelect: PropTypes.func,
  /** ID of parent for filter component */
  parent: PropTypes.string,
  /** Sort order  */
  sort: PropTypes.object,
  /** Function to be called on Sort Change */
  onSortChange: PropTypes.func,
  /** Boolean for indicatinsg sidebar visible or not */
  isSidebarVisible: PropTypes.bool,
  /** Function to be called on side bar visibility change */
  toggleSidebar: PropTypes.func,
  /** Boolean to indicate retry buttonn enabled or not */
  isRetryEnabled: PropTypes.bool,
  /** Function to be called on click of retry button */
  onRetry: PropTypes.func,
  /** Boolean for loading on recreate job */
  isRecreateLoading: PropTypes.bool,
  /** Function to be called on search for jobs */
  onSearchFilter: PropTypes.func,
  /** Function to be called on change of filters */
  changeFilterValue: PropTypes.func,
  /** Function to be called on downloading job list */
  downloadAll: PropTypes.func
}

JobManagerHeader.defaultProps = {
  groupByList: [],
  timeList: []
}

export default withRouter(JobManagerHeader)
