import React from 'react'
import PropTypes from 'prop-types'

const freeGradientFirst = '#A6A6A6'
const freeGradientSecond = '#5C5C5C'
const premiumGradientFirst = '#E7B21E'
const premiumGradientSecond = '#D57E01'

const CustomerAccountIcon = ({ width, height, isPremium, ...props }) => {
  const id = `gradient-${parseInt(Math.random() * 1000)}`
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id={id} x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor={isPremium ? premiumGradientFirst : freeGradientFirst} />
          <stop offset='100%' stopColor={isPremium ? premiumGradientSecond : freeGradientSecond} />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <rect width='18' height='18' fill='#FFF' rx='2' />
        <path fill={`url(#${id})`} d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM6.71 11.577l-.196.005A3.715 3.715 0 0 0 3 15.288c0 .096.078.174.175.174h12.456c.099 0 .177-.078.177-.174a3.715 3.715 0 0 0-3.71-3.71zM9.405 3a3.811 3.811 0 0 0-3.807 3.807c0 2.099 1.708 3.806 3.807 3.806 2.1 0 3.807-1.707 3.807-3.806C13.21 4.707 11.503 3 9.404 3zm-2.49 3.4c.096.011.165.098.155.193-.014.13-.042.504-.004.666a.175.175 0 0 1-.34.08c-.06-.25-.01-.73-.004-.783.01-.096.098-.171.193-.155zm-.12-.585A2.417 2.417 0 0 1 9.635 3.92a.175.175 0 1 1-.068.343 2.07 2.07 0 0 0-2.431 1.622.175.175 0 0 1-.206.137.175.175 0 0 1-.138-.206z' />
      </g>
    </svg>
  )
}

CustomerAccountIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

CustomerAccountIcon.defaultProps = {
  width: 18,
  height: 18,
  isPremium: false
}

export default CustomerAccountIcon
