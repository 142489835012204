import gql from 'graphql-tag'

export default gql(`
    mutation deleteUsers($id: [String] $project: String) {
        deleteUsers(
            input: $id
            project: $project
        ){
            id
        }
    }
`)
