import gql from 'graphql-tag'

export default gql(`
  query ( $limit: Int ){
    listSports(
        limit: $limit
      ) {
        items{
          id
          title
          externalId
        }
    }
  }`
)
