import React, { Component } from 'react'
import { Tabs } from 'antd'

import CreateCastCredit from '../../components/ui/dataEntry/other/CreateCastCredit'
import CreateCrewCredit from '../../components/ui/dataEntry/other/CreateCrewCredit'

const { TabPane } = Tabs

class AssetCredits extends Component {
    state = {
      activeKey: '1'
    }
    onTabChange = (activeKey) => {
      this.setState({ activeKey })
    }
    render () {
      const { isCreateDisabled, isUpdateDisabled, isCreateDisabledContent, isUpdateDisabledContent, listRolesDetails,
        listPersonsDetails, details, onChangeCredits, project, onPersonSearch, onRoleSearch, onChangeAddingNewCredits } = this.props
      const crew = details && details.credits ? details && (details.credits || []).filter(credit => credit.type === 'crew') : []
      const cast = details && details.credits ? details && (details.credits || []).filter(credit => credit.type === 'cast') : []
      return (
        <div className='credit-tab-details'>
          <Tabs onChange={this.onTabChange}>
            <TabPane tab='Cast' key='1'>
              <CreateCastCredit
                isCreateDisabled={isCreateDisabled}
                isUpdateDisabled={isUpdateDisabled}
                isCreateDisabledContent={isCreateDisabledContent}
                isUpdateDisabledContent={isUpdateDisabledContent}
                listRolesDetails={listRolesDetails}
                listPersonsDetails={listPersonsDetails}
                credits={cast}
                assetId={details.id}
                onChangeCredits={onChangeCredits}
                project={project}
                onPersonSearch={onPersonSearch}
                onRoleSearch={onRoleSearch}
                onChangeAddingNewCredits={onChangeAddingNewCredits}
              />
            </TabPane>
            <TabPane tab='Crew' key='2' >
              <CreateCrewCredit
                isCreateDisabled={isCreateDisabled}
                isUpdateDisabled={isUpdateDisabled}
                isCreateDisabledContent={isCreateDisabledContent}
                isUpdateDisabledContent={isUpdateDisabledContent}
                listRolesDetails={listRolesDetails}
                listPersonsDetails={listPersonsDetails}
                credits={crew}
                assetId={details.id}
                onChangeCredits={onChangeCredits}
                project={project}
                onPersonSearch={onPersonSearch}
                onRoleSearch={onRoleSearch}
              />
            </TabPane>
          </Tabs>
        </div>
      )
    }
}

AssetCredits.propTypes = {

}

export default AssetCredits
