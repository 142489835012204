import React from 'react'
import PropTypes from 'prop-types'
import { Popover, DatePicker } from 'antd'
import moment from 'moment'

import CrossIcon from '../../general/icons/CrossIcon'
import FilterIcon from '../../general/icons/FilterIcon'
import SearchDropDown from './SearchDropDown'
import Tooltip from '../../dataDisplay/ToolTip'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCompetitionFilter from '../../../../graphQL/asset/getCompetitonFilter'
import QueryListSports from '../../../../graphQL/admin/sports/listSports'

const { RangePicker } = DatePicker

const newData = {
  id: '',
  name: 'None'
}

class MatchFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

   componentDidMount = () => {
     document.addEventListener('mousedown', this.handleClickOutside)
   }

   componentWillUnmount () {
     document.removeEventListener('mousedown', this.handleClickOutside)
   }

    handleClickOutside = (event) => {
      if ((this.filterContainer && this.filterContainer.contains(event.target)) || (this.filterHeaderContainer && this.filterHeaderContainer.contains(event.target))) {
        return
      }
      if (this.state.isVisible) {
        setTimeout(() => this.setState({ isVisible: false }), 200)
      }
    }

    handleDateChange = (date, dateString) => {
      let arrayVal = ''
      dateString.filter((item) => item).map((time, index) => {
        const date = moment(time)
        const startTime = _.cloneDeep(date).startOf('day')
        const endTime = _.cloneDeep(date).endOf('day')
        const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
        arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
      })
      const filterDate = arrayVal && arrayVal.length ? arrayVal : null
      if (this.props.onCompetitionDateSelect) { this.props.onCompetitionDateSelect(filterDate) }
    }

    handleVisibleChange = (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.setState({
        isVisible: !this.state.isVisible
      })
    }

    onCompetitionSelect = (value) => {
      if (this.props.onCompetitionSelect) { this.props.onCompetitionSelect(value) }
    }

    onCompetitionSearch = (value) => {
      if (this.props.onCompetitionSearch) { this.props.onCompetitionSearch(value) }
    }

    onSportSelect = (value) => {
      if (this.props.onSportSelect) { this.props.onSportSelect(value) }
    }

    renderContent = () => {
      const { selectedCompetition, filterDate, competition, listSports, selectedSport } = this.props
      const randomId = '-' + Math.random().toString(36).substr(2, 9)
      return <div className='match-filter-body' ref={node => { this.filterContainer = node }} onClick={e => e.stopPropagation()}>
        <div className='match-filter-data' id={'filter-competition' + randomId}>
          <span>Competition</span>
          <SearchDropDown
            options={competition}
            placeHolder='Select Competition'
            selectedOption={selectedCompetition}
            onSelect={this.onCompetitionSelect}
            onSearch={this.onCompetitionSearch}
            valueParam='id'
            displayParam='name'
            parentCompoent={'filter-competition' + randomId}
          />
        </div>
        <div className='match-filter-data' id={'filter-sport' + randomId}>
          <span>Sport</span>
          <div className='category-drop-down'>
            <SearchDropDown
              options={listSports}
              placeHolder='Select Sport'
              selectedOption={selectedSport}
              onSelect={this.onSportSelect}
              showSearch={false}
              valueParam='id'
              displayParam='title'
              parentCompoent={'filter-competition' + randomId}
            />
          </div>
        </div>
        <div className='match-filter-data' id={'filter-date' + randomId}>
          <span>Match Date</span>
          <RangePicker
            value={filterDate ? filterDate.split(',').map((dateItem) => moment(dateItem)) : filterDate}
            format='DD MMM YYYY'
            onChange={this.handleDateChange}
            getCalendarContainer={() => document.getElementById('filter-date' + randomId)}
          />
        </div>
      </div>
    }

    renderTitle = () => {
      return <div className='match-filter-header' ref={node => { this.filterHeaderContainer = node }}>
        <span className='title'>FILTER</span>
        <span className='clear' onClick={this.props.clearFilter}>Clear Filter</span>
      </div>
    }

    getDetails = (matchFilter) => {
      let value; let details = []
      if (matchFilter && matchFilter.selectedCompetition) {
        value = `Competition: ${matchFilter.selectedCompetition.name}`
        details.push(value)
      }
      if (matchFilter && matchFilter.filterDate) {
        value = this.formattingDate(matchFilter.filterDate)
        details.push(value)
      }
      if (matchFilter && matchFilter.selectedSport) {
        value = `Sport: ${matchFilter.selectedSport.title}`
        details.push(value)
      }
      return details
    }

    formattingDate = (value) => {
      let dates = []
      value.split(',').map((item, index) => {
        dates[index] = item.substr(0, 10)
      })
      var day = new Date(dates[0])
      var aDay = 1440 * 60 * 1000
      var d2 = new Date(Math.trunc((day.getTime() + aDay) / aDay) * aDay)
      var currDate = d2.getDate().toString()
      if (currDate.length === 1) {
        currDate = '0' + currDate
      }
      var currMonth = (d2.getMonth() + 1).toString()
      if (currMonth.length === 1) {
        currMonth = '0' + currMonth
      }
      var currYear = d2.getFullYear()
      var startDate = currYear + '-' + currMonth + '-' + currDate
      return (`Match Date: ${startDate} to ${dates[1]}`)
    }

    render () {
      const { isVisible } = this.state
      const { parent, selectedMatch, isSelected, matchFilter } = this.props
      const content = this.renderContent()
      const title = this.renderTitle()
      const details = this.getDetails(matchFilter)
      return (
        <React.Fragment>
          {selectedMatch ? <CrossIcon onClick={this.props.clearSearchField} /> : undefined}
          <Popover overlayClassName='match-filter' placement='rightTop' content={content} visible={isVisible} title={title} trigger='click'
            arrowPointAtCenter getPopupContainer={() => document.getElementById(parent)} >
            {!isVisible && details.length ? <Tooltip parentCompoent={parent} placement='rightTop' details={details} isList arrowPointAtCenter
              childComponent={<FilterIcon onClick={this.handleVisibleChange} selected={isSelected} />} />
              : <FilterIcon onClick={this.handleVisibleChange} selected={isSelected && details.length} />}

          </Popover>
        </React.Fragment>
      )
    }
}
MatchFilter.propTypes = {
  /** onCompetitionSelect of MatchFilter. */
  onCompetitionSelect: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryCompetitionFilter,
    {
      options: ({ competitionSearch, project }) => {
        return {
          variables: { searchKey: competitionSearch, limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        let competition = props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? _.cloneDeep(props.data.getContentByField.items) : []
        competition.unshift(newData)
        return {
          competition: competition
        }
      }
    }
  ),
  graphql(
    QueryListSports,
    {
      options: (props) => {
        const nextToken = props.nextToken ? props.nextToken : null
        return {
          variables: { limit: 999, nextToken },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let listSports = data.listSports ? [ ...data.listSports.items ] : []
        const nextToken = data.listSports ? data.listSports.nextToken : null
        const previousToken = data.listSports ? data.listSports.previousToken : null

        return {
          listSports,
          isLoading: data.loading,
          nextToken,
          previousToken,
          getMoreSports: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: nextToken
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newSportsList = fetchMoreResult.listSports ? [ ...fetchMoreResult.listSports.items ] : []
                let newList = [ ...prev.listSports.items, ...newSportsList ]
                newList = _.uniqBy(newList, 'id')
                if (_.isEqual(newList, prev.listSports.items)) { return prev }
                prev.listSports.items = newList
                prev.listSports.previousToken = prev.listSports.nextToken
                prev.listSports.nextToken = fetchMoreResult.listSports.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  )
)(MatchFilter))
