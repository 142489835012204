import React, { Component } from 'react'
import AppContext from '../../AppContext'
import TabPanel from '../../components/ui/dataDisplay/TabPanel'
// import PopoverButton from './../../components/ui/general/buttons/PopoverButton'
// import AddButton from './../../components/ui/general/buttons/AddButton'
// import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import AppConfigList from './../appManager/AppConfigList'
import AppPagesList from './../appManager/AppPagesList'
import AppSettingList from './AppSettingList'
// import Button from './../../components/ui/general/buttons/Button'

const detailsTabs = (mode) => [
  {
    displayName: 'Pages',
    mode: 'pages'
  }, {
    displayName: 'Configs',
    mode: 'configs'
  }, {
    displayName: 'Settings',
    mode: 'settings'
  }
]

class AppLeftSideBar extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    const { selectedConfig, selectedPage, onSelectConfigGroup, onSelectPageGroup, appClient, handleAppModeChange, appMode, selectedAppSetting, handleAppListSelect, refetchPageList, handleRefetchPageList, handleRecreateConfigTree, shouldRecreateConfigTree, handleRefetchConfigDetails, handleRefetchPageDetails, userId } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['APP_MANAGER']
          const isViewConfigDisabled = userPermissions.indexOf('VIEW_CONFIG') === -1
          const isViewPageDisabled = userPermissions.indexOf('VIEW_PAGE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE_CONFIG') === -1
          const isCreateDisabled = userPermissions.indexOf('CREATE_CONFIG') === -1
          const isCreateGroupDisabled = userPermissions.indexOf('CREATE_GROUP') === -1
          const isUpdateGroupDisabled = userPermissions.indexOf('UPDATE_GROUP') === -1
          const isUpdatePageDisabled = userPermissions.indexOf('UPDATE_PAGE') === -1
          const isCreatePageDisabled = userPermissions.indexOf('CREATE_PAGE') === -1
          const isDeleteGroupDisabled = userPermissions.indexOf('DELETE_GROUP') === -1
          const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
          const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
          return <div className='app-list' id='app-list'>
            <TabPanel tabs={detailsTabs(appMode)} selectedMode={appMode} handleModeChange={handleAppModeChange} />
            {appMode === 'pages' && !isViewPageDisabled
              ? <AppPagesList onSelectPageGroup={onSelectPageGroup} selectedPage={selectedPage} appClient={appClient} project={project} isCreateDisabled={isCreatePageDisabled} isUpdateDisabled={isUpdatePageDisabled} defaultExpandAll appMode={appMode} isCreateGroupDisabled={isCreateGroupDisabled} isUpdateGroupDisabled={isUpdateGroupDisabled} refetchPageList={refetchPageList} handleRefetchPageList={handleRefetchPageList} isCriticalWriteDisabled={isCriticalWriteDisabled} isAppAdminDisabled={isAppAdminDisabled} handleRefetchPageDetails={handleRefetchPageDetails} userId={userId} /> : null }
            {appMode === 'configs' && !isViewConfigDisabled
              ? <AppConfigList onSelectConfigGroup={onSelectConfigGroup} selectedConfig={selectedConfig} appClient={appClient} project={project} isCreateDisabled={isCreateDisabled} isUpdateDisabled={isUpdateDisabled} isDeleteGroupDisabled={isDeleteGroupDisabled} defaultExpandAll appMode={appMode} isCreateGroupDisabled={isCreateGroupDisabled} isUpdateGroupDisabled={isUpdateGroupDisabled} handleRecreateConfigTree={handleRecreateConfigTree} shouldRecreateConfigTree={shouldRecreateConfigTree} isCriticalWriteDisabled={isCriticalWriteDisabled} isAppAdminDisabled={isAppAdminDisabled} handleRefetchConfigDetails={handleRefetchConfigDetails} userId={userId} /> : null }
            {appMode === 'settings' ? <AppSettingList handleAppListSelect={handleAppListSelect} selectedAppSetting={selectedAppSetting} /> : null }
          </div>
        }}
      </AppContext.Consumer>

    )
  }
}

AppLeftSideBar.propTypes = {

}

export default AppLeftSideBar
