import gql from 'graphql-tag'

export default gql(`
query listCustomers($offset: Int, $limit: Int, $searchTerm: String $project: String) {
    listCustomers(
      limit: $limit,
      offset: $offset,
      searchTerm: $searchTerm
      project: $project
    ) {
      users {
        id
        firstName
        lastName
        email
        mobile
        externalId
        cognitoUserId
        eligibleForHyperion
        activeSubscriptions {
          id
        }
      }
      numberOfHits
    }
}`)
