const bugsnagClientKeys = {
  dev: '5623a20ddf1007cbd35b3a598d525e42',
  qa: '73986f9ad21abbe158c346d6e4c3fce7',
  uat: '0f030a217e1c6e0dfdf65e4c85885a4c',
  pp: '4db111f21899321684bfb79e6ac55018',
  prod: 'da543458a7e59d943d83381de43b408d'
}

const localURl = {
  dev: 'https://cms-dev-g.create.diagnal.com',
  staging: 'https://cms-staging-b.create.diagnal.com',
  qa: 'https://cms-qa.optussport.tv', // v1
  qag: 'https://cms-qa-g.optussport.tv', // v1
  pp: 'https://cms-preprod.optussport.tv',
  uat: 'https://cms-uat.optussport.tv'
  // staging: 'https://dw5imlimytpgj.cloudfront.net',
  // qa: 'https://d32qlhptltmu47.cloudfront.net/', // v2
  // uat: 'https://cms-uat.optussport.tv',
}

const config = {
  VERSION: 'v0.1.0',
  PROJECT_LANGUAGE: 'en',
  LOCAL_URL: localURl.qag,
  BUGSNAG_KEY: bugsnagClientKeys.dev,
  RELEASE_STAGE: 'production',
  AUTH_SCREEN_LABELS: {
    en: {
      'Username': 'Email',
      'Enter your username': 'Enter your email'
    }
  }
}
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
  config.RELEASE_STAGE = 'staging'
}
export default config
