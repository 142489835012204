import React from 'react'
import _ from 'lodash'
import AppContext from '../../../AppContext'

import AddImageModal from '../dataEntry/other/AddImageModal'
import EditImageModal from '../dataEntry/other/EditImageModal'
import AddIcon from '../general/icons/AddIcon'
import EditIcon from '../general/icons/EditIcon'
import CrossIcon from '../general/icons/CrossIcon'
import { generateCroppedThumbnail } from '../../../util/util'
import './../ui.style.scss'

const defaultImage = require('../../../assets/images/avatar-large-team-icon.png')

class SquadSeasonData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {}
    }
  }

  componentDidMount = () => {
    if (this.props.selectedSquad) {
      this.setState({
        details: _.cloneDeep(this.props.selectedSquad),
        awayImage: this.props.awayImage,
        homeImage: this.props.homeImage,
        shirtNumber: this.props.shirtNumber
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedSquad && !_.isEqual(this.props.selectedSquad, nextProps.selectedSquad)) {
      this.setState({
        details: _.cloneDeep(nextProps.selectedSquad)
      })
    }
    if (nextProps.awayImage && !_.isEqual(this.props.awayImage, nextProps.awayImage)) {
      this.setState({
        awayImage: nextProps.awayImage
      })
    }
    if (nextProps.homeImage && !_.isEqual(this.props.homeImage, nextProps.homeImage)) {
      this.setState({
        awayImage: nextProps.awayImage
      })
    }
    if (nextProps.shirtNumber && this.props.shirtNumber !== nextProps.shirtNumber) {
      this.setState({
        shirtNumber: nextProps.shirtNumber
      })
    }
  }

  formatInput = (e) => {
    let checkIfNum
    if (e.key !== undefined) {
      checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'E'
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189
    }
    return checkIfNum && e.preventDefault()
  }

  onHandleShirtNumber = (e) => {
    let { homeImage, awayImage, shirtNumber } = this.state
    const { value } = e.target
    const regexExp = /^\d*[0-9]\d*$/
    const isNum = regexExp.test(value)
    const jerseyNo = isNum ? value : undefined
    if (jerseyNo === '' || jerseyNo === undefined) {
      shirtNumber = null
    } else {
      shirtNumber = jerseyNo
    }
    this.setState({ shirtNumber }, () => this.props.onEditSquadMedia({ homeImage, awayImage, shirtNumber }))
  }

  editProgram = type => {
    this.setState({ showAddImage: type })
  }

  hideAddImage = () => {
    this.setState({ showAddImage: undefined })
  }

  onAddMedia = (newMedia) => {
    let { showAddImage, homeImage, awayImage, shirtNumber } = this.state
    if (showAddImage === 'home') {
      homeImage = newMedia[0]
    } else if (showAddImage === 'away') {
      awayImage = newMedia[0]
    }
    this.setState({ homeImage, awayImage, showAddImage: undefined }, () => this.props.onEditSquadMedia({ homeImage, awayImage, shirtNumber }))
  }

  showCrop = (e, type) => {
    e.stopPropagation()
    this.setState({ showCrop: type })
  }

  hideCrop = () => {
    this.setState({ showCrop: undefined })
  }

  showCropModal = (e, type) => {
    e.stopPropagation()
    this.setState({ showCrop: undefined, showEditImage: type })
  }

  hideImageEditor = () => {
    this.setState({ showEditImage: undefined })
  }

  removeImage = (e, type) => {
    e.stopPropagation()
    let { homeImage, awayImage } = this.state
    if (type === 'home') {
      homeImage = null
    } else if (type === 'away') {
      awayImage = null
    }
    this.setState({ homeImage, awayImage }, () => this.props.onEditSquadMedia({ homeImage, awayImage }))
  }

  render () {
    const { showAddImage, homeImage, awayImage, showEditImage, shirtNumber, query } = this.state
    const formattedAwayImage = awayImage ? generateCroppedThumbnail(awayImage, 150, 150, '1:1') : defaultImage
    const formattedhomeImage = homeImage ? generateCroppedThumbnail(homeImage, 150, 150, '1:1') : defaultImage
    const queryParams = { ...query, imageKeyName: showEditImage === 'home' ? 'homeImage' : showEditImage === 'away' ? 'awayImage' : '' }
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissionsContent = permissions['CONTENT_BANK']
          const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
          const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
          return <div className='squad-season-data'>
            <div className='jersey-no' id='jersey-no'>
              <label>Jersey Number</label>
              <input type='number' value={shirtNumber !== null ? parseInt(shirtNumber) : null} onChange={this.onHandleShirtNumber} onKeyDown={this.formatInput} />
            </div>
            <div className='team-image-horizontal'>
              <div className='team'>
                <label> Home </label>
                <div className='jersey'>
                  {this.state.showCrop === 'home' ? <div className='avatar-hover' onClick={(e) => homeImage ? this.showCropModal(e, 'home') : this.editProgram('home')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ homeImage ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={homeImage ? formattedhomeImage : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'home')} onMouseLeave={() => this.hideCrop()} />}
                  { homeImage ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'home')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
              <div className='team'>
                <label> Away </label>
                <div className='jersey'>
                  {this.state.showCrop === 'away' ? <div className='avatar-hover' onClick={(e) => awayImage ? this.showCropModal(e, 'away') : this.editProgram('away')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ awayImage ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={awayImage ? formattedAwayImage : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'away')} onMouseLeave={() => this.hideCrop()} />}
                  { awayImage ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'away')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
            </div>
            <AddImageModal
              isVisible={showAddImage}
              handleCancel={this.hideAddImage}
              handleSubmit={this.onAddMedia} isSubmitButtonDisabled={isCreateDisabledContent} isCancelButtonDisabled={false}
              mediaType={'IMAGE'}
              project={project}
            />
            <EditImageModal
              type={'IMAGE'}
              isVisible={showEditImage}
              hideImageEditor={this.hideImageEditor}
              imageId={showEditImage === 'home' ? homeImage && homeImage.id : awayImage && awayImage.id}
              mediaDetails={showEditImage === 'home' ? homeImage : awayImage}
              query={queryParams}
              isUpdateBlocked={isUpdateDisabledContent}
              isUploadBlocked={isCreateDisabledContent}
              project={project}
            />
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

SquadSeasonData.propTypes = {

}

SquadSeasonData.defaultProps = {
}

export default SquadSeasonData
