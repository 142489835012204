import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $isArchived: Boolean $updatedAt: AWSDateTime $project: String) {
        updateMedia(
            input:{
                id: $id
                isArchived: $isArchived
                updatedAt: $updatedAt
            }
            project: $project
        ) {
            id
            fileName
            createdAt
            name
            type
            fileSize
            isArchived
            duration
            thumbnails
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
            drmContent
            streams{
                id
                drm
                codec
                Url
                iv
                transport
                contentKey
            }
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
        }
    }`
)
