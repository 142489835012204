import * as React from 'react'
import CollapsedIcon from '../../ui/general/icons/CollapsedIcon'
import GroupDragAndDropIcon from '../../ui/general/icons/GroupDragAndDropIcon'
import EditIcon from '../../ui/general/icons/EditIcon'
import DeleteIcon from '../../ui/general/icons/DeleteIcon'
import PropTypes from 'prop-types'
import { Popconfirm } from 'antd'
import MasterSettings from '../general/icons/MasterSettings'
import Switch from '../dataEntry/inputs/Switch'
import './../ui.style.scss'
import ButtonContainer from '../general/buttons/ButtonContainer'
import AppContext from '../../../AppContext'

export class ChannelConfigAccordionHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }
  render () {
    const { isActive, isMasterEnabled, isClicked, isNotAllowed, title, isPrevent, shouldUpdate } = this.props
    return <AppContext.Consumer>
      {({ permissions }) => {
        const userPermissions = permissions['CHANNEL_MANAGER']
        const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        return <div className={`channel-accordion ant-collapse${isMasterEnabled ? ' master-config-enabled' : ''}`}>
          <div className={`channel-title ${isMasterEnabled ? ' master-enabled' : ''}`} >
            <div className='config-title-container'>
              <div className={`button-container ${isActive ? 'active' : ''}${isPrevent ? ' transition-not-allowed' : ''}`} onClick={this.preventClick}>
                <GroupDragAndDropIcon color={isMasterEnabled ? '#FFF' : undefined} />
                <Switch isChecked={isActive} onChange={this.onMakeConfigActive} loading={isClicked} disabled={isNotAllowed || isUpdateDisabled} className={!isActive ? 'inActive' : undefined} />
              </div>
              {isMasterEnabled ? <div className='master-config-title'><MasterSettings /></div> : ''}
              <span className='master-config'>{title}</span>
            </div>
            <div className='config-active' onClick={this.preventClick}>
              <div className={`edit-button${isUpdateDisabled ? ' disabled' : ''}${shouldUpdate ? ' notification' : ''}`} onClick={this.onEdit}>
                <ButtonContainer displayTitle={shouldUpdate ? 'A change has been made to the config group schema. Click here to review the details as per the new schema and update the config' : 'Edit Config'}
                  childComponent={<EditIcon color={isMasterEnabled ? '#FFF' : '#343a40'} isDisabled={isUpdateDisabled} />} placement='topLeft' />
              </div>
              <span className={`${isMasterEnabled ? 'enabled-master-config ' : ''}active-button`}>|</span>
              <Popconfirm placement='left' title='Do you want to delete this item?' onConfirm={this.onRemove} okText='Yes' cancelText='No'>
                <span className={`delete-button${(isActive || isDeleteDisabled) ? ' disabled' : ''}`} >
                  <ButtonContainer displayTitle='Delete Config' childComponent={<DeleteIcon color={isMasterEnabled ? '#FFF' : '#343a40'} />} />
                </span>
              </Popconfirm>
            </div>
          </div>
          <div className='accordion-arrow'><CollapsedIcon color={isMasterEnabled ? '#FFF' : undefined} /></div>
        </div>
      }}
    </AppContext.Consumer>
  }

    preventClick = (e) => {
      e.stopPropagation()
      e.preventDefault()
    }

    onEdit = () => {
      if (this.props.onEdit) { this.props.onEdit(this.props.id) }
    }

    onRemove = () => {
      if (this.props.onRemove) { this.props.onRemove(this.props.id) }
    }

    onMakeConfigActive = () => {
      if (!this.props.isActive && this.props.onMakeConfigActive) {
        this.setState({ isLoading: true })
        this.props.onMakeConfigActive(this.props.id)
      }
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      this.setState({ isLoading: this.state.isLoading ? !nextProps.isActive : false })
    }
}

ChannelConfigAccordionHeader.propTypes = {
  /** Title string */
  title: PropTypes.string,
  /** Boolean for active  */
  isActive: PropTypes.bool,
  /** Function called on edit config */
  onEdit: PropTypes.func.isRequired,
  /** Function called on remove config group */
  onRemove: PropTypes.func.isRequired,
  /** Function called on change a config group to active */
  onMakeConfigActive: PropTypes.func.isRequired,
  /** ID of a config group */
  id: PropTypes.string.isRequired,
  /** Boolean for knwoing master config is enabled or not */
  isMasterEnabled: PropTypes.bool,
  /** Boolean to show loading after click on active button */
  isClicked: PropTypes.bool,
  /** Boolean for disallowed the click */
  isNotAllowed: PropTypes.bool,
  /** Boolean for transition prevent for drag and drop */
  isPrevent: PropTypes.bool
}
