import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select } from 'antd'
import './../../ui.style.scss'

const Option = Select.Option

const getChildren = (items, displayParameter, keyParameter, selectedItems) => {
  if (keyParameter) { items = items.filter((newItem) => selectedItems.indexOf(newItem.key) === -1) }
  const keyVal = keyParameter || displayParameter
  return items.map((item) => <Option key={item[ keyVal ]}>{item[ displayParameter ]}</Option>)
}

class GroupInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedItems: props.selectedItems && props.selectedItems.length ? props.selectedItems : [],
      autoFocus: false
      // tagList: props.tagList && props.tagList.length ? props.tagList : []
    }
  }

  // componentDidMount = () => {
  //   if (!_.isEqual(this.props.selectedItems, this.state.selectedItems)) {
  //     this.setState({ selectedItems: this.props.selectedItems })
  //   }
  // }

    componentDidUpdate = () => {
      if (!_.isEqual(this.props.selectedItems, this.state.selectedItems)) {
        this.setState({ selectedItems: this.props.selectedItems })
      }
    }

    onChangeTag = (value) => {
      if (value.trim() === '') {
        return
      }
      this.lastSearchedValue = undefined
      let { selectedItems } = _.cloneDeep(this.state)
      selectedItems.push(this.props.containerClass === 'tags' ? value.toLowerCase() : value)
      const values = selectedItems
      this.setState({ selectedItems: values, autoFocus: !!(values && values.length) }, () => {
        this.props.onChange(this.props.containerClass === 'tags' ? value.toLowerCase() : value)
        if (this.props.onSearch) { this.props.onSearch('') }
      })
    }

    onSearch = (value) => {
      this.lastSearchedValue = value
      if (this.props.onSearch) { this.props.onSearch(this.props.containerClass === 'tags' ? value.toLowerCase() : value) }
    }

    onBlur = () => {
      this.setState({ autoFocus: false })
      if (this.props.onSearch) { this.props.onSearch('') }
    }

    onDeselect = (value) => {
      if (this.lastSearchedValue === value) {
        return
      }
      let { selectedItems } = _.cloneDeep(this.state)
      const { keyParameter } = this.props
      let deletedKey
      if (keyParameter) {
        const index = selectedItems.indexOf(value)
        deletedKey = selectedItems[index]
        selectedItems.splice(index, 1)

        selectedItems.splice(index, 1)
      } else { selectedItems.splice(selectedItems.indexOf(value), 1) }
      this.setState({ selectedItems, autoFocus: !!(selectedItems && selectedItems.length) }, () => {
        if (keyParameter) { this.props.onChange(deletedKey, true) } else { this.props.onChange(value, true) }
        if (this.props.onSearch) { this.props.onSearch('') }
      })
    }

    render () {
      const {
        tagList,
        placeholder,
        parentCompoent,
        displayParameter,
        keyParameter,
        mode,
        // className,
        // containerClass,
        isEditGroup
      } = this.props
      const { autoFocus, selectedItems } = this.state
      const children = getChildren(tagList, displayParameter, keyParameter, selectedItems)
      return (
        <Select
          key={JSON.stringify(selectedItems)}
          className={`group-input-tags ${!isEditGroup ? 'disabled' : ''}`}
          mode={mode}
          dropdownClassName={'group-drop-down'}
          defaultValue={selectedItems}
          placeholder={placeholder}
          onSelect={this.onChangeTag}
          getPopupContainer={() => document.getElementById(parentCompoent)}
          onSearch={this.onSearch}
          onBlur={this.onBlur}
          autoFocus={autoFocus}
          onDeselect={this.onDeselect}
          disabled={!isEditGroup}
          filterOption={false}
        >
          {children}
        </Select>
      )
    }

  // componentWillReceiveProps(nextProps){
  //   this.setState({
  //     tagList: nextProps.tagList
  //   })
  // }
}

GroupInput.propTypes = {
  /** Placeholder text of GroupInput. */
  placeholder: PropTypes.string,
  /** Parameter for display value */
  displayParameter: PropTypes.string,
  /**  Parameter for mapping key */
  keyParameter: PropTypes.string,
  /** parent container id name of GroupInput. */
  parentCompoent: PropTypes.string,
  /** Selected Items of GroupInput. */
  selectedItems: PropTypes.array,
  /** Option data of GroupInput. */
  tagList: PropTypes.array,
  /** Change action of GroupInput. */
  onChange: PropTypes.func,
  /** Mode of input */
  mode: PropTypes.string,
  /** Function invokes when user wants to search tag */
  onSearch: PropTypes.func,
  /** Selected Display values */
  selectedDisplayItems: PropTypes.array
}

GroupInput.defaultProps = {
  selectedItems: [],
  placeholder: 'Select',
  parentCompoent: 'root',
  mode: 'multiple'
}

export default GroupInput
