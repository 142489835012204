import gql from 'graphql-tag'

export default gql(`
    mutation($assetType: ID! $matchId: ID! $programId: ID $competition: ID!) {
        createAssetFromTemplate(
            input: {
                assetType: $assetType
                matchId: $matchId
                programId: $programId
                competition: $competition
            }
        ) {
            id
            isArchived
            shortId
            externalId
            title
            isLive
            isFree
            type
            updatedAt
            publishStartDate
            publishEndDate
            status
            vodStatus{
              id
              status
              site
              updatedAt
              createdAt
              errorDesc
              startTime
              endTime
            }
            vodJobs{
                id
                status
                site
                name
                type
                jobType
                vwmJobId
                updatedAt
                createdAt
                createdBy
                progress
                errorDesc
                startTime
                endTime 
                jobStates{
                    status
                    errorCode
                    updatedAt
                }
                channel{
                    id
                }
                mediaId{
                    id
                    duration
                }  
            }
            program{
              id
              source
              isLiveTelecast
              channel{
                  id
                  name
                  channelCode
                  icon{
                      id
                      fileName
                      aspectRatio {
                          aspectRatio
                          ratio
                          title          
                          resolutions {
                          url
                          }
                      }
                      settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2
                          y2
                      }
                  }
                  activeConfigGroup{
                      id
                      name
                      streams{
                          url
                          fields{
                              name
                              value
                              isRequired
                              type
                          }
                          streamSchema{
                              id
                              levels{
                                  id
                                  name
                              }
                          }
                      }
                  }
              }
              broadCastStartTime
              broadCastEndTime
            }
            defaultMedia {
              id
              url
              fileName
              aspectRatio {
                title
                aspectRatio
                resolutions {
                  url
                }
              }
              settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2 
                y2
              }
            }
        }
    }`
)
