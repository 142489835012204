import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import './../../ui.style.scss'

class ImageViewModal extends Component {
    state = {
      settings: null
    }

    UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
      if (this.props.aspectRatio && this.props.settings) {
        const filteredSetting = { ...this.props.settings.find(setting => setting.aspectRatio === this.props.aspectRatio.aspectRatio) }
        this.setState({ settings: filteredSetting })
      }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.aspectRatio && nextProps.settings) {
        const filteredSetting = { ...nextProps.settings.find(setting => setting.aspectRatio === nextProps.aspectRatio.aspectRatio) }
        this.setState({ settings: filteredSetting })
      }
    }

    handleSubmit = () => {
      this.props.updateSettings(this.state.settings)
    }

    handleCancel = () => {
      this.setState({ settings: null })
      this.props.handleCancel()
    }

    getCropArea = (newSettings) => {
      this.setState({ settings: newSettings })
    }

    render () {
      const { imgUrl, isVisible, isUpdateBlocked } = this.props
      return (
        <Modal
          className='confirm-modal edit-image'
          title='Image'
          maskClosable={false}
          visible={isVisible}
          cancelText='Back'
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: isUpdateBlocked }}
          closable={false}
          centered
          width='890px'
          destroyOnClose
        >
          <div className='crop-image-modal image-item' >
            <img style={{ 'max-width': '100%', 'max-height': '100%' }} src={imgUrl} />
          </div>
        </Modal>
      )
    }
}

ImageViewModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /* Url  of the image */
  imgUrl: PropTypes.string,
  /** close image action callback. */
  handleCancel: PropTypes.func,
  /** Aspect ratio of the image */
  aspectRatio: PropTypes.object,
  /**  */
  updateImage: PropTypes.func,
  /**  */
  settings: PropTypes.arrayOf(PropTypes.object),
  /** Update block boolean */
  isUpdateBlocked: PropTypes.bool
}

ImageViewModal.defaultProps = {
}

export default ImageViewModal
