import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import './../../ui.style.scss'

const Option = Select.Option

const customFilter = (input, option, options) => {
  const currentOption = (options || []).find(item => item.id === option.props.value)
  const returnValue = currentOption.name ? currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
  return returnValue
}

const BucketGroupDropDown = ({ displayParam, valueParam, onOptionSelect, selectedValue, options, placeHolder, parent, className, allowClear, disabled, onSearch, onBucketGroupSearch, ...props }) => {
  const filteredOptions = (options || []).filter(item => item[valueParam || displayParam] !== selectedValue)
  let value = (options || []).find(item => item[valueParam || displayParam] === selectedValue)
  const index = (options || []).findIndex(item => item[valueParam || displayParam] === selectedValue)
  if (index > -1) {
    value = (options || [])[index][displayParam || valueParam]
  }
  return (
    <Select
      className={className ? 'drop-down ' + className : 'drop-down'}
      value={value || null}
      placeholder={placeHolder}
      getPopupContainer={() => document.getElementById(parent)}
      onChange={onOptionSelect ? onOptionSelect.bind(this) : () => { }}
      optionFilterProp={displayParam}
      filterOption={(input, option) => customFilter(input, option, options)}
      allowClear={value ? allowClear : false}
      suffixIcon={<ExpandedIcon />}
      clearIcon={<CrossIcon />}
      onSearch={onBucketGroupSearch}
      disabled={disabled}
      showSearch
    >
      {filteredOptions.map((option, index) => <Option key={option.id || index} value={option[valueParam || displayParam]}>{option[displayParam]}</Option>)}
    </Select>
  )
}

BucketGroupDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** Selected value of Dropdown. */
  selectedValue: PropTypes.string,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Parent container for the dropdown. */
  parent: PropTypes.string.isRequired,
  /** Class name */
  className: PropTypes.string
}

BucketGroupDropDown.defaultProps = {
  selectedValue: 'Select',
  placeHolder: 'Select',
  parent: 'root',
  disabled: false
}

export default BucketGroupDropDown
