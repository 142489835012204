import * as React from 'react'
import { Table, Skeleton } from 'antd'
import { TableHeader } from '../../components/ui/dataDisplay/TableHeader'
import SettingsIcon from '../../components/ui/general/icons/SettingsIcon'
import moment from 'moment'
import { MatchCard } from '../../components/ui/dataDisplay/MatchCard'
import PropTypes from 'prop-types'
import placeholderImage from '../../assets/images/default-image.jpg'
import { generateCroppedThumbnail } from './../../util/util'

let selectedId
const defaultImage = require('../../assets/images/default-image-square.jpg')

const getImageUrl = (imageData) => {
  const aspectRatio = imageData.aspectRatio.find(item => item.title === '1:1')
  return aspectRatio.resolutions.length ? aspectRatio.resolutions[0].url : ''
}

const columns = [
  {
    title: <TableHeader title={'CHANNEL'} classStyle='channel-header-cell' />,
    dataIndex: 'name',
    fixed: 'left',
    width: 149.5,
    render: (text, data) => (
      <div className={`${'chanel-list-cell'}${data.id === selectedId ? ' selected-channel-indicator' : ''} `}>
        <img src={data.icon ? getImageUrl(data.icon) : defaultImage} className='channel-icon' />
        {data.channelCode ? <div className='code-container channel-name'>ID:
          <span className='code'>{ data.channelCode }</span></div> : null}
        <div className='channel-name'>{ text }</div>
      </div>
    ) },
  {
    title: <TableHeader title={'ACTIVE CONFIG'} classStyle='channel-config-cell' />,
    dataIndex: 'activeConfigGroup',
    fixed: 'left',
    width: 149.5,
    render: (text, data) => (
      <div className={`${'chanel-list-cell'}${data.id === selectedId ? ' selected-channel' : ''} `}>
        {text && data.masterConfig && data.masterConfig.name ? <div className='enable-master-container'><SettingsIcon color='#f5212d' />
          <div className='active active-config'>{ data.masterConfig ? data.masterConfig.name : (data.activeConfigGroup ? text.name : '') }</div></div>
          : <span className='active-config'>{ data.activeConfigGroup ? text.name : ''}</span> }
      </div>
    )
  },
  {
    title: <TableHeader title={'PREVIOUS'} classStyle='channel-active-cell' />,
    dataIndex: 'previous',
    render: (text, data) => {
      const previous = data.programs && data.programs.previous ? data.programs.previous : undefined
      const match = previous && previous.match ? previous.match : undefined
      const broadCastStartTime = previous && previous.program && previous.program.broadCastStartTime ? moment(previous.program.broadCastStartTime).format('DD MMM YYYY HH:mm') : undefined
      const broadCastEndTime = previous && previous.program && previous.program.broadCastEndTime ? moment(previous.program.broadCastEndTime).format('DD MMM YYYY HH:mm') : undefined
      const shortId = previous ? previous.shortId || previous.id : undefined
      const assetId = previous && previous.id ? previous.id : undefined
      const title = previous && previous.title ? (previous.title).toLocaleUpperCase() : ''
      const startTime = previous && previous.program && previous.program.broadCastStartTime
      const date = match && match.matchDate ? moment(match.matchDate).format('DD MMM YYYY HH:mm') : startTime ? moment(startTime).format('DD MMM YYYY HH:mm') : ''
      const matchId = match ? match.opId : undefined
      let imageUrl = placeholderImage
      if (previous && previous.defaultMedia) {
        imageUrl = generateCroppedThumbnail(previous.defaultMedia, 188, 106, '16:9')
      }
      return <div className={`${data.id === selectedId ? ' selected-channel' : 'channel-inactive-cell'} `}>
        <MatchCard key={`${data.id}-pre`} matchTitle={title} displayImage={imageUrl} date={date} assetId={assetId} shortId={shortId}
          broadcast={broadCastStartTime} matchId={matchId} endTime={broadCastEndTime}
          kickoff={match ? moment(match.kickOffTime).format('HH:mm:ss') : ''} />
      </div>
    }
  },
  {
    title: <TableHeader title={'CURRENT'} classStyle='channel-active-cell' />,
    dataIndex: 'current',
    render: (text, data) => {
      const current = data.programs && data.programs.current ? data.programs.current : undefined
      const match = current && current.match ? current.match : undefined
      const broadCastStartTime = current && current.program && current.program.broadCastStartTime ? moment(current.program.broadCastStartTime).format('DD MMM YYYY HH:mm') : undefined
      const broadCastEndTime = current && current.program && current.program.broadCastEndTime ? moment(current.program.broadCastEndTime).format('DD MMM YYYY HH:mm') : undefined
      const assetId = current && current.id ? current.id : undefined
      const shortId = current ? current.shortId || current.id : undefined
      const title = current && current.title ? (current.title).toLocaleUpperCase() : ''
      const matchId = match ? match.opId : undefined
      const startTime = current && current.program && current.program.broadCastStartTime
      const date = match && match.matchDate ? moment(match.matchDate).format('DD MMM YYYY HH:mm') : startTime ? moment(startTime).format('DD MMM YYYY HH:mm') : ''
      let imageUrl = placeholderImage
      if (current && current.defaultMedia) {
        imageUrl = generateCroppedThumbnail(current.defaultMedia, 188, 106, '16:9')
      }
      return <div className={`${data.id === selectedId ? ' selected-channel' : 'channel-inactive-cell'} `}>
        <MatchCard key={`${data.id}-current`} matchTitle={title} displayImage={imageUrl} date={date} assetId={assetId} shortId={shortId}
          broadcast={broadCastStartTime} matchId={matchId} endTime={broadCastEndTime}
          kickoff={match ? moment(match.kickOffTime).format('HH:mm:ss') : ''} />
      </div>
    }
  },
  {
    title: <TableHeader title={'UPCOMING'} classStyle='channel-active-cell' />,
    dataIndex: 'upcoming',
    render: (text, data) => {
      const next = data.programs && data.programs.next ? data.programs.next : undefined
      const match = next && next.match ? next.match : undefined
      const broadCastStartTime = next && next.program && next.program.broadCastStartTime ? moment(next.program.broadCastStartTime).format('DD MMM YYYY HH:mm') : undefined
      const broadCastEndTime = next && next.program && next.program.broadCastEndTime ? moment(next.program.broadCastEndTime).format('DD MMM YYYY HH:mm') : undefined
      const shortId = next ? next.shortId || next.id : undefined
      const assetId = next && next.id ? next.id : undefined
      const title = next && next.title ? (next.title).toLocaleUpperCase() : ''
      const matchId = match ? match.opId : undefined
      const startTime = next && next.program && next.program.broadCastStartTime
      const date = match && match.matchDate ? moment(match.matchDate).format('DD MMM YYYY HH:mm') : startTime ? moment(startTime).format('DD MMM YYYY HH:mm') : ''
      let imageUrl = placeholderImage
      if (next && next.defaultMedia) {
        imageUrl = generateCroppedThumbnail(next.defaultMedia, 188, 106, '16:9')
      }
      return <div className={`${data.id === selectedId ? ' selected-channel' : 'channel-inactive-cell'} `}>
        <MatchCard key={`${data.id}-next`} matchTitle={title} displayImage={imageUrl} date={date} assetId={assetId} shortId={shortId}
          broadcast={broadCastStartTime} matchId={matchId} endTime={broadCastEndTime}
          kickoff={match ? moment(match.kickOffTime).format('HH:mm:ss') : ''} />
      </div>
    }
  }
]

export class ChannelListTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedId: props.selectedId ? props.selectedId : 0,
      selectedChannel: props.data[ 0 ]
    }
    selectedId = props.selectedId ? props.selectedId : 0
  }
  render () {
    return <Skeleton active avatar={false} title={false} paragraph={{ rows: 15 }}
      loading={this.props.isLoading}>
      <Table columns={columns} dataSource={this.props.data} scroll={{ y: this.props.tableHeight, x: true }} pagination={false} rowClassName='table-row'
        onRow={(record) => {
          return {
            onClick: this.onRowClick.bind(this, record.id)
          }
        }}
        rowKey={(record) => record.id}
      />
    </Skeleton>
  }

    onRowClick = (selectedID) => {
      if (this.state.selectedId !== selectedID) {
        this.setState({ selectedId: selectedID })
        selectedId = selectedID
        this.props.onSelectChannel(selectedID)
      }
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      if (nextProps.selectedId !== this.state.selectedId) {
        this.setState({ selectedId: nextProps.selectedId })
        selectedId = nextProps.selectedId
      }
    }
}

ChannelListTable.propTypes = {
  data: PropTypes.any,
  selectedIndex: PropTypes.string,
  tableHeight: PropTypes.number,
  selectedChannel: PropTypes.func,
  onSelectChannel: PropTypes.func,
  selectedId: PropTypes.string,
  isLoading: PropTypes.bool
}

ChannelListTable.defaultProps = {
  tableHeight: 833
}
