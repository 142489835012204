import * as React from 'react'
import { Modal, Button, message } from 'antd'
import PropTypes from 'prop-types'

import _ from 'lodash'
import Input from '../inputs/Input'
import AddIcon from '../../general/icons/AddIcon'
import CropWhiteIcon from '../../general/icons/CropWhiteIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import DropDown from '../inputs/DropDown'
import { getCropImageUrl } from '../../../../util/util'

import './../../ui.style.scss'

const defaultImage = require('../../../../assets/images/default-image-square.jpg')
const siteOption = [
  {
    value: 'PRI',
    displayValue: 'Primary'
  },
  {
    value: 'SEC',
    displayValue: 'Secondary'
  },
  {
    value: 'XBTSS',
    displayValue: 'Multi'
  }
]
export class CreateChannelModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      channelName: props.channelName ? props.channelName.name : '',
      channelCode: props.channelCode ? props.channelCode : '',
      imageUrl: (!_.isEmpty(props.channelLogo)) ? props.channelLogo.url : '',
      showCrop: false,
      isEditStarted: false,
      warningEnable: false,
      codeWarning: false,
      selectedSite: 'PRI',
      isEdited: false,
      defaultTrimStart: props.defaultTrimStart || props.defaultTrimStart === 0 ? props.defaultTrimStart : '',
      defaultTrimEnd: props.defaultTrimEnd || props.defaultTrimEnd === 0 ? props.defaultTrimEnd : ''
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.channelLogo && this.props && !_.isEmpty(this.props.channelLogo) && !_.isEqual(this.props.channelLogo, newProps.channelLogo)) {
      this.setState({ isEdited: true })
    }
    if (!newProps.isLoading && newProps.isVisible && !_.isEqual(this.props, newProps)) {
      this.setState({
        channelName: (newProps.channelName && !this.state.isEditStarted) ? newProps.channelName : this.state.channelName,
        channelCode: (newProps.channelCode && !this.state.isEditStarted) ? newProps.channelCode : this.state.channelCode,
        imageUrl: !_.isEmpty(newProps.channelLogo) ? this.getImageUrl(newProps.channelLogo) : '',
        selectedSite: newProps.isFromAddChannel ? 'PRI' : newProps.selectedSite ? newProps.selectedSite : this.state.selectedSite,
        defaultTrimStart: ((newProps.defaultTrimStart || newProps.defaultTrimStart === 0) && !this.state.isEditStarted) ? newProps.defaultTrimStart : this.state.defaultTrimStart,
        defaultTrimEnd: ((newProps.defaultTrimEnd || newProps.defaultTrimEnd === 0) && !this.state.isEditStarted) ? newProps.defaultTrimEnd : this.state.defaultTrimEnd
      })
    } else {
      this.setState({
        imageUrl: !_.isEmpty(newProps.channelLogo) ? this.getImageUrl(newProps.channelLogo) : ''
      })
    }
  }

  onSave = () => {
    const { channelName, imageUrl, channelCode, selectedSite, defaultTrimStart, defaultTrimEnd } = this.state
    const channelInfo = {
      title: channelName,
      logo: imageUrl,
      code: channelCode,
      site: selectedSite,
      defaultTrimStart: parseInt(defaultTrimStart),
      defaultTrimEnd: parseInt(defaultTrimEnd)
    }

    if (this.props.onSave && !_.isEmpty(defaultTrimStart.toString()) && !_.isEmpty(defaultTrimEnd.toString())) {
      this.props.onSave(channelInfo)
    } else {
      message.warning('Please enter value for startOffset and endOffset')
    }
  }

  onCancel = () => {
    if (this.props.onCancel) { this.props.onCancel() }
  }

  onUploadImage = () => {
    this.setState({
      isEdited: true
    })
    if (this.props.onUploadImage) { this.props.onUploadImage() }
  }

  onChannelNameEnter = (e) => {
    let channelName = e.target.value
    const { channelList, isFromAddChannel, channelId } = this.props
    channelName = channelName.startsWith(' ') ? '' : channelName
    const isChannelExist = channelList.filter((channel) => {
      return !isFromAddChannel ? channel.name.toLowerCase() === channelName.toLowerCase() && channel.id !== channelId : channel.name.toLowerCase() === channelName.toLowerCase()
    })
    this.setState({
      channelName,
      isEditStarted: true,
      isEdited: true,
      warningEnable: !!isChannelExist.length
    })
  }

  onChannelCodeEnter = (e) => {
    let channelCode = e.target.value
    const codeReg = /^[a-zA-Z0-9_]*$/
    const { channelList } = this.props
    channelCode = codeReg.test(channelCode) ? channelCode.toLocaleUpperCase() : this.state.channelCode
    const isChannelCodeExist = channelList.filter((channel) => {
      return channel.channelCode === channelCode
    })
    this.setState({
      channelCode,
      isEditStarted: true,
      isEdited: true,
      codeWarning: !!isChannelCodeExist.length
    })
  }

  showCrop = (e) => {
    // if (this.state.imageUrl) {
    e.stopPropagation()
    this.setState({ showCrop: true })
    // }
  }

  hideCrop = () => {
    this.setState({ showCrop: false })
  }

  showCropModal = (e) => {
    e.stopPropagation()
    this.setState({ showCrop: false })
    this.props.showCropModal()
  }

  removeImage = (e) => {
    e.stopPropagation()
    this.setState({ imageUrl: '' })
    this.props.removeImage()
  }

  getImageUrl = (imageData) => {
    const aspectRatio = imageData.aspectRatio.find(item => item.title === '1:1')
    const settings = imageData.settings.find((item) => item.aspectRatio === aspectRatio.aspectRatio)
    const url = getCropImageUrl(imageData, settings, 70, 70)
    // return aspectRatio.resolutions.length ? aspectRatio.resolutions[0].url : ''
    return url
  }

  onOptionSelect = (selectedSite) => {
    this.setState({ selectedSite, isEdited: true })
  }

  clearState = () => {
    this.setState({
      isEditStarted: false,
      showCrop: false,
      imageUrl: '',
      channelName: '',
      channelCode: '',
      selectedSite: 'PRI',
      warningEnable: false,
      codeWarning: false,
      isEdited: false,
      defaultTrimStart: '',
      defaultTrimEnd: ''
    })
  }

  getSaveStatus = () => {
    const { codeWarning, warningEnable, channelName, channelCode, isEdited } = this.state
    return channelName && channelCode && !warningEnable && !codeWarning && isEdited
  }

  onStartOffsetChange = (e) => {
    const regx = /^\d+$/
    const { value } = e.target
    let defaultTrimStart = value
    const regxValidator = regx.test(defaultTrimStart) && !isNaN(defaultTrimStart)
    if (regxValidator || !value) {
      this.setState({
        isEditStarted: true,
        isEdited: true,
        defaultTrimStart
      })
    } else {
      message.error('Enter Numeric Value')
    }
  }

  onEndOffsetChange = (e) => {
    const regx = /^\d+$/
    const { value } = e.target
    let defaultTrimEnd = value
    const regxValidator = regx.test(defaultTrimEnd) && !isNaN(defaultTrimEnd)
    if (regxValidator || !value) {
      this.setState({
        isEditStarted: true,
        isEdited: true,
        defaultTrimEnd
      })
    } else {
      message.error('Enter Numeric Value')
    }
  }

  render () {
    const { isFromAddChannel } = this.props
    const { codeWarning, warningEnable, selectedSite, channelName, channelCode, defaultTrimStart, defaultTrimEnd } = this.state
    return <Modal
      centered
      maskClosable={false}
      afterClose={this.clearState}
      title={<div className='modal-title'>{`${this.props.isFromAddChannel ? 'CREATE CHANNEL' : 'EDIT CHANNEL'}`}</div>}
      closable={false}
      wrapClassName='create-channel-modal'
      destroyOnClose
      footer={<div className='modal-footer'>
        <div className='cancel-button' onClick={this.onCancel}><span>Cancel</span></div>
        <div className={`${this.getSaveStatus() ? 'enable-save' : 'save-button'}`}
          onClick={(channelName && !this.props.isLoading && !warningEnable && !codeWarning) ? this.onSave : () => {}}>
          {this.props.isLoading ? <Button type='primary' loading block>
          Saving... </Button> : <span>Save Channel</span>
          }
        </div>
      </div>}
      visible={this.props.isVisible} >
      <div className={`${'channel-name'}`}>
        <div className='channel-logo'>
          {this.state.showCrop ? <div className='avatar-hover' onClick={(e) => this.state.imageUrl ? this.showCropModal(e) : this.onUploadImage()} onMouseLeave={() => this.hideCrop()}>
            <div className='crop-icon'>{ this.state.imageUrl ? <CropWhiteIcon /> : <AddIcon /> }</div>
          </div> : <img src={this.state.imageUrl ? this.state.imageUrl : defaultImage} onClick={this.onUploadImage}
            onMouseEnter={(e) => this.showCrop(e)} onMouseLeave={() => this.hideCrop()} />}
          { this.state.imageUrl && <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e)} className='oval'>
            <CrossIcon color='#fff' height={14} width={14} />
          </div> }
        </div>
        <div className={`input-container`}>
          <div className='name-code-container'>

            <div className='name'>
              <Input title='Name' inputName='Name' value={channelName} placeholder='Channel Name' handleChange={this.onChannelNameEnter} limit={50}
                isError={warningEnable} errorMessage={codeWarning ? '' : 'Channel Name Already in Use'} />
            </div>
          </div>
          <div className='site-dropdown input-field' id={'channel-site-parent'}>
            <div className={`code${!isFromAddChannel ? ' readOnly' : ''}`}>
              <Input title='Code' inputName='Code' value={channelCode} placeholder='Channel Code' handleChange={this.onChannelCodeEnter} limit={10}
                readOnly={!isFromAddChannel} isError={codeWarning} errorMessage={'Channel Code Already in Use'} />
            </div>
            <div className='code-site-container'>
              <label>Live2Vod SITE</label>
              <DropDown options={siteOption} selectedValue={selectedSite} placeHolder='Select site' valueParam='value' displayParam='displayValue' onOptionSelect={this.onOptionSelect} parent={'channel-site-parent'} />
            </div>
          </div>
          <div className='channel-offset input-field' id={'channel-site-parent'}>
            <div className='start-offset'>
              <Input title='Start Offset (Seconds)' inputName='defaultTrimStart' value={defaultTrimStart === 0 ? '0' : defaultTrimStart} placeholder='Start Offset' handleChange={this.onStartOffsetChange} />
            </div>
            <div className='end-offset'>
              <Input title='End Offset (Seconds)' inputName='defaultTrimEnd' value={defaultTrimEnd === 0 ? '0' : defaultTrimEnd} placeholder='End Offset' handleChange={this.onEndOffsetChange} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  }
}

CreateChannelModal.propTypes = {
  /** Boolean value to hide and show create modal */
  isVisible: PropTypes.bool,
  /** Channel name  */
  channelName: PropTypes.string,
  /** Channel logo */
  channelLogo: PropTypes.object,
  /** Function to be called on channel save */
  onSave: PropTypes.func,
  /** Function to be called on create channel modal cancel */
  onCancel: PropTypes.func,
  /** Function to be caled on upload image */
  onUploadImage: PropTypes.func,
  /** Boolean for showing crop modal */
  showCropModal: PropTypes.func,
  /** Function to be called when channel removed icon */
  removeImage: PropTypes.func,
  /** Boolean for laoding */
  isLoading: PropTypes.bool,
  /** Boolean indicates  */
  isFromAddChannel: PropTypes.bool,
  /** Channel List */
  channelList: PropTypes.arrayOf(PropTypes.object),
  /** Channel id  */
  channelId: PropTypes.string,
  /** Channel code */
  channelCode: PropTypes.string,
  /** selected site */
  selectedSite: PropTypes.string
}
