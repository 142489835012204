import gql from 'graphql-tag'

export default gql(`
  query($id: ID! $project: String){
    getMedia(id:$id project: $project){
      id
      fileName
      createdAt
      name
      type
      fileSize
      isArchived
      duration
      thumbnails
      adPositions
      cuePoints
      breakDuration
      tags{
          key
          name
          type
      }
      category{
          id
          label
          type
      }
      settings {
          aspectRatio
          outputFormat
          fileName
          x1
          y1
          x2
          y2
      }
      dimensions{
          x1
          x2
          y1
          y2
      }
      aspectRatio {
          aspectRatio
          ratio
          title
          resolutions {
          key
          width
          height
          url
          }
      }
      drmContent
      streams{
          id
          drm
          codec
          Url
          iv
          transport
          contentKey
      }
      vodStatus{
          id
          startTime
          endTime
          channel{
              id
              icon{
                  id
                  fileName
                  aspectRatio {
                      aspectRatio
                      ratio
                      title          
                      resolutions {
                      url
                      }
                  }
                  settings {
                      aspectRatio
                      outputFormat
                      fileName
                      x1
                      y1
                      x2
                      y2
                  }
              }
          }
      }
       _originalResponseBody
    }
  }
  `
)
