import React, { Component } from 'react'
import moment from 'moment'
import { message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import AppContext from '../../AppContext'

import AddButton from './../../components/ui/general/buttons/AddButton'
import PopoverButton from './../../components/ui/general/buttons/PopoverButton'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import MatchAssetAccordion from '../../components/ui/dataDisplay/MatchAssetAccordion'
import AssetListCell from '../../components/ui/dataDisplay/AssetListCell'
import './MatchManager.scss'

import { generateCroppedThumbnail } from '../../util/util'

// import { ReactComponent as ConferencePublishIcon } from '../../assets/images/conference-published.svg'
// import { ReactComponent as HighlightsPublishIcon } from '../../assets/images/highlights-published.svg'
// import { ReactComponent as ShortPublishIcon } from '../../assets/images/short-published.svg'
// import { ReactComponent as MatchPublishIcon } from '../../assets/images/match-published.svg'
// import { ReactComponent as InterviewPublishIcon } from '../../assets/images/interview-published.svg'
// import { ReactComponent as SummaryPublishIcon } from '../../assets/images/summary-published.svg'

import MutationCreateAsset from '../../graphQL/match/createAssetFromTemplate'
import QueryGetMatchDetails from '../../graphQL/match/getMatch'
import { utilityService } from '../../services/UtilityService'

// const types = [
//   {
//     displayName: 'Game',
//     type: 'GAME',
//     icon: <MatchPublishIcon />,
//     neededCount: 1
//   }, {
//     displayName: 'Mini Match',
//     type: 'MINIMATCH',
//     icon: <SummaryPublishIcon />,
//     neededCount: 1
//   }, {
//     displayName: 'Highlights',
//     type: 'HIGHLIGHTS',
//     icon: <HighlightsPublishIcon />,
//     neededCount: 1
//   }, {
//     displayName: 'Interview',
//     type: 'INTERVIEW',
//     icon: <InterviewPublishIcon />,
//     neededCount: 1
//   }, {
//     displayName: 'Press Conferences',
//     type: 'PRESSCONFERENCE',
//     icon: <ConferencePublishIcon />,
//     neededCount: 1
//   }, {
//     displayName: 'Short',
//     type: 'SHORT',
//     icon: <ShortPublishIcon />,
//     neededCount: 1
//   }
// ]

// const assetTypes = [
//   {
//     displayName: 'GAME',
//     type: 'GAME'
//   }, {
//     displayName: 'MINIMATCH',
//     type: 'MINIMATCH'
//   }, {
//     displayName: 'HIGHLIGHTS',
//     type: 'HIGHLIGHTS'
//   }, {
//     displayName: 'INTERVIEW',
//     type: 'INTERVIEW'
//   }, {
//     displayName: 'PRESSCONFERENCE',
//     type: 'PRESSCONFERENCE'
//   }, {
//     displayName: 'SHORT',
//     type: 'SHORT'
//   }
// ]

class MatchAssets extends Component {
    state = {
      shouldShowCreate: false
    }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    toggleShowCreateAsset = () => {
      this.setState({ shouldShowCreate: !this.state.shouldShowCreate })
    }

    handleClickOutside = () => {
      if (this.state.shouldShowCreate) {
        setTimeout(() => this.setState({ shouldShowCreate: false }), 200)
      }
    }

    onCreateAsset = (selectedType) => {
      const { match, program } = this.props
      const newAsset = {
        assetType: selectedType.title,
        matchId: match.id,
        programId: selectedType.programSection && program ? program.id : undefined,
        competition: match.competition ? match.competition.id : null
      }
      this.props.createAssetFromTemplate(newAsset).then(response => {
        if (!response.data.createAssetFromTemplate) {
          message.error('Template/default template not defined for this competition')
          this.setState({ isLoading: false, createAsset: false })
          return
        }
        window.open(location.origin + '/assets/' + response.data.createAssetFromTemplate.id, '_blank')
      }, error => {
        utilityService.handleError(error)
      })
    }

    render () {
      const { shouldShowCreate } = this.state
      const { assets, program, listAssetTypes } = this.props
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['MATCH_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            return <div className={`match-assets ${program.channel ? 'with-channel' : ''}`}>
              <div className='match-assets-details'>
                {listAssetTypes ? listAssetTypes.map(assetType => {
                  const filteredAssets = assets ? assets.filter(item => item.type === assetType.title) : []

                  return <MatchAssetAccordion
                    key={assetType.title}
                    title={`${assetType.title} (${filteredAssets.length})`}
                    icon={assetType.icon && assetType.icon ? <img src={generateCroppedThumbnail(assetType.icon, 18, 18, '1:1')} /> : null}
                    childComponent={assets && assets.length ? assets.filter(item => item.type === assetType.title).map((item, index) => {
                      let imageUrl
                      if (item.defaultMedia) {
                        imageUrl = generateCroppedThumbnail(item.defaultMedia, 87, 48, '16:9')
                      } return (
                        <AssetListCell key={index}
                          imageUrl={imageUrl}
                          status={item.status}
                          assetName={item.title}
                          displayId={item.shortId || item.id}
                          assetType={item.type}
                          assetId={item.id}
                          date={moment(new Date(item.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                          onSelect={() => { }}
                          project={project}
                        />)
                    }) : null}
                  />
                }) : null }
              </div>
              <div className='match-asset-footer' id={'match-asset-footer'}>
                <PopoverButton
                  button={<ButtonContainer displayTitle='Create Asset' childComponent={<AddButton onClick={this.toggleShowCreateAsset} isDisabled={isCreateDisabled} />} />}
                  displayParam='title'
                  contents={listAssetTypes}
                  onContentClick={this.onCreateAsset}
                  parentCompoent={'match-asset-footer'}
                  isVisible={shouldShowCreate}
                />
              </div>
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

export default withApollo(compose(
  graphql(
    MutationCreateAsset,
    {
      options: ({ match }) => {
        return {
          update: (cache, { data: { createAssetFromTemplate } }) => {
            try {
              const cacheData = cloneDeep(cache.readQuery({ query: QueryGetMatchDetails, variables: { id: match.id } }))
              if (cacheData.getMatch && createAssetFromTemplate) {
                cacheData.getMatch.asset.push(createAssetFromTemplate)
              }
              cache.writeQuery({
                query: QueryGetMatchDetails,
                data: cacheData,
                variables: { id: match.id }
              })
            } catch (e) {}
          }
        }
      },
      props: (props) => ({
        createAssetFromTemplate: (variables) => {
          return props.mutate({ variables })
        }
      })
    }
  )

)(MatchAssets))
