import React from 'react'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import { generateCroppedThumbnail } from '../../../util/util'
import EditIcon from '../general/icons/EditIcon'
import AddImageModal from '../dataEntry/other/AddImageModal'
import AppContext from '../../../AppContext'
import './../ui.style.scss'
import CrossIcon from '../general/icons/CrossIcon'
import AddIcon from '../general/icons/AddIcon'
import EditImageModal from '../dataEntry/other/EditImageModal'

const defaultImage = require('../../../assets/images/avatar-large-team-icon.png')

class CompetitionImages extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {},
      logo: null,
      defaultTeamImage: null
    }
  }

  componentDidMount = () => {
    if (this.props.selectedCompetition) {
      this.setState({
        details: _.cloneDeep(this.props.selectedCompetition),
        logo: this.props.logo,
        defaultTeamImage: this.props.defaultTeamImage
        // customHomeVenue: this.props.venue
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedCompetition && !_.isEqual(this.state.details, nextProps.selectedCompetition)) {
      this.setState({
        details: _.cloneDeep(nextProps.selectedCompetition)
      })
    }
    if (nextProps.logo && !_.isEqual(this.props.logo, nextProps.logo)) {
      this.setState({
        logo: nextProps.logo
      })
    }
    if (nextProps.defaultTeamImage && !_.isEqual(this.props.defaultTeamImage, nextProps.defaultTeamImage)) {
      this.setState({
        defaultTeamImage: nextProps.defaultTeamImage
      })
    }
  }

  editProgram = type => {
    this.setState({ showAddImage: type })
  }

  hideAddImage = () => {
    this.setState({ showAddImage: undefined })
  }

  onAddMedia = (newMedia) => {
    let { showAddImage, defaultTeamImage, logo } = this.state
    if (showAddImage === 'logo') {
      logo = newMedia[0]
    } else if (showAddImage === 'default') {
      defaultTeamImage = newMedia[0]
    }
    this.setState({ defaultTeamImage, logo, showAddImage: undefined }, () => this.props.onEditCompetitionMedia({ defaultTeamImage, logo }))
  }

  showCrop = (e, type) => {
    e.stopPropagation()
    this.setState({ showCrop: type })
  }

  hideCrop = () => {
    this.setState({ showCrop: undefined })
  }

  showCropModal = (e, type) => {
    e.stopPropagation()
    this.setState({ showCrop: undefined, showEditImage: type })
  }

  hideImageEditor = () => {
    this.setState({ showEditImage: undefined })
  }

  removeImage = (e, type) => {
    e.stopPropagation()
    let { defaultTeamImage, logo } = this.state
    if (type === 'logo') {
      logo = null
    } else {
      defaultTeamImage = null
    }
    this.setState({ logo, defaultTeamImage }, () => this.props.onEditCompetitionMedia({ defaultTeamImage, logo }))
  }

  render () {
    const { query } = this.props
    const { showAddImage, showEditImage, defaultTeamImage, logo } = this.state
    const logoImage = logo ? generateCroppedThumbnail(logo, 100, 100, '1:1') : defaultImage
    const defaultTeamImageThumb = defaultTeamImage ? generateCroppedThumbnail(defaultTeamImage, 100, 100, '1:1') : defaultImage
    const queryParams = { ...query, imageKeyName: showEditImage === 'logo' ? 'logo' : 'defaultTeamImage' }
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissionsContent = permissions['CONTENT_BANK']
          const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
          const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
          return <div className='competition-data'>
            <div className='competition-image-horizontal'>
              <div className='competition'>
                <label> Competition Logo </label>
                <div className='logo'>
                  {this.state.showCrop === 'logo' ? <div className='avatar-hover' onClick={(e) => logo ? this.showCropModal(e, 'logo') : this.editProgram('logo')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ logo ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={logo ? logoImage : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'logo')} onMouseLeave={() => this.hideCrop()} />}
                  { logo ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'logo')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
              <div className='competition'>
                <label> Default Image </label>
                <div className='default-team-image'>
                  {this.state.showCrop === 'default' ? <div className='avatar-hover' onClick={(e) => defaultTeamImage ? this.showCropModal(e, 'default') : this.editProgram('default')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ defaultTeamImage ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={defaultTeamImage ? defaultTeamImageThumb : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'default')} onMouseLeave={() => this.hideCrop()} />}
                  { defaultTeamImage ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'default')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
            </div>
            <AddImageModal
              isVisible={showAddImage}
              handleCancel={this.hideAddImage}
              handleSubmit={this.onAddMedia}
              isSubmitButtonDisabled={isCreateDisabledContent}
              isCancelButtonDisabled={false}
              mediaType={'IMAGE'}
              project={project}
            />
            <EditImageModal
              type={'IMAGE'}
              isVisible={showEditImage}
              hideImageEditor={this.hideImageEditor}
              imageId={showEditImage === 'logo' ? logo && logo.id : defaultTeamImage && defaultTeamImage.id}
              mediaDetails={showEditImage === 'logo' ? logo : defaultTeamImage}
              query={queryParams}
              isUpdateBlocked={isUpdateDisabledContent}
              isUploadBlocked={isCreateDisabledContent}
              project={project}
            />
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

CompetitionImages.propTypes = {

}

CompetitionImages.defaultProps = {
}

export default CompetitionImages
