import gql from 'graphql-tag'

export default gql(`
    mutation updatePlayer( $id: ID! $customFirstName: String $customLastName: String $customMatchName: String 
    $customNationality: String $customPosition: String $customType: String $customActive: String $tags: [ID]) {
        updatePlayer(
            input: {
                id: $id
                tags: $tags
                customFirstName: $customFirstName
                customLastName: $customLastName
                customMatchName: $customMatchName
                customNationality: $customNationality
                customPosition: $customPosition
                customType: $customType
                customActive: $customActive
            }
        ) {
          id
          externalId
          opId
          firstName
          lastName
          matchName
          lastUpdated
          nationality
          nationalityId
          position
          type
          placeOfBirth
          active
          customFirstName
          customLastName
          customMatchName
          customNationality
          customPosition
          customType
          customActive
          tags{
            key
            name
            type
          }  
        }
    }`
)
