import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import TransferDropDown from '../inputs/TransferDropDown'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryCategory from '../../../../graphQL/admin/asset/listCategory'
import QueryAuthors from '../../../../graphQL/asset/getAuthors'
import QueryTags from '../../../../graphQL/asset/getTags'

class AssetTransferModal extends Component {
    state = {
      isLoading: false
    }

    resetState = () => {
      const state = {
        isLoading: false
      }
      this.setState({ ...state })
    }

    submitTransfer = () => {
      const { selectedToValue, selectedFromValue } = this.props
      if (selectedToValue === selectedFromValue) {
        message.error('Please select different items for transfer')
      } else {
        this.setState({ isLoading: true })
        this.props.onSubmitTransfer()
      }
    }

    render () {
      const { isVisible, handleCancel, title, onOptionSelect, selectedToValue, selectedFromValue, isImage, lastMessage, optionList1, optionList2, onSearchOptions, placeholder, type } = this.props
      const { isLoading } = this.state
      let options
      if (type !== 'tag') {
        options = (optionList2 || []).filter(item => !selectedFromValue || item.id !== selectedFromValue.id)
      } else {
        options = (optionList2 || []).filter(item => !selectedFromValue || item.key !== selectedFromValue.key)
      }

      return (
        <Modal
          className='general-modal asset-transfer-modal'
          title={title}
          maskClosable={false}
          visible={isVisible}
          okText='Transfer'
          cancelText='Cancel'
          onOk={this.submitTransfer}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !(selectedToValue), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='650px'
          confirmLoading={isLoading}
        >
          <div className='asset-transfer'>
            <div className='details'>
              <label>From</label>
              <TransferDropDown
                options={optionList1 || []}
                selected={selectedFromValue}
                displayParam='name'
                onSearch={(value) => onSearchOptions(value, true)}
                onOptionSelect={(value) => onOptionSelect(value, 'from')}
                placeholder={placeholder}
                isImage={isImage}
                type={type}
              />
            </div>
            <div className='details'>
              <label>To</label>
              <TransferDropDown
                options={options}
                selected={selectedToValue}
                displayParam='name'
                onSearch={(value) => onSearchOptions(value, false)}
                onOptionSelect={(value) => onOptionSelect(value, 'to')}
                isImage={isImage}
                placeholder={placeholder}
                type={type}
              />
            </div>
          </div>
          <span className='last-message'> {lastMessage}  </span>
        </Modal>
      )
    }
}

AssetTransferModal.propTypes = {
  /** visible file status of CreateCategoryModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateCategoryModal. */
  handleCancel: PropTypes.func
}

AssetTransferModal.defaultProps = {
}

export default withApollo(compose(
  // graphql(
  //   QueryCategory,
  //   {
  //     skip: ({ type, categorySearch }) => type !== 'category' || !categorySearch,
  //     options: ({ categorySearch }) => {
  //       const { searchString1 } = categorySearch
  //       const search = searchString1 ? { keyword: searchString1, fields: ['name', 'slug'] } : null
  //       const variables = { search, offset: 0, limit: 20 }
  //       return {
  //         variables
  //       }
  //     },
  //     props: (props) => {
  //       const { data } = props
  //       const optionList1 = data.listAssetCategories ? data.listAssetCategories.items.filter(item => !_.isEmpty(item.assetCount) && parseInt(item.assetCount)) : []
  //       return {
  //         optionList1
  //       }
  //     }
  //   }
  // ),
  graphql(
    QueryCategory,
    {
      skip: ({ type, categorySearch }) => type !== 'category' || !categorySearch,
      options: ({ categorySearch, project }) => {
        const { searchString2 } = categorySearch
        const search = searchString2 ? { keyword: searchString2, fields: ['name', 'slug'] } : null
        const variables = { search, offset: 0, limit: 20, project }
        return {
          variables,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        const optionList2 = data.listAssetCategories ? data.listAssetCategories.items : []
        return {
          optionList2
        }
      }
    }
  ),
  // graphql(
  //   QueryAuthors,
  //   {
  //     skip: ({ type, authorSearch }) => type !== 'author' || !authorSearch,
  //     options: ({ authorSearch }) => {
  //       const { searchString1 } = authorSearch
  //       const search = searchString1 ? { keyword: searchString1, fields: ['name', 'slug'] } : null
  //       const variables = { search, offset: 0, limit: 30 }
  //       return {
  //         variables
  //       }
  //     },
  //     props: (props) => {
  //       const { data } = props
  //       const optionList1 = data.listAuthors ? data.listAuthors.items.filter(item => item.assetCount && parseInt(item.assetCount)) : []
  //       return {
  //         optionList1
  //       }
  //     }
  //   }
  // ),
  graphql(
    QueryAuthors,
    {
      skip: ({ type, authorSearch }) => type !== 'author' || !authorSearch,
      options: ({ authorSearch, project }) => {
        const { searchString2 } = authorSearch
        const searchFilter = {
          name: { match: searchString2 }
        }
        const filter = searchString2 ? searchFilter : null
        const variables = { filter, offset: 0, limit: 25, project }
        return {
          variables,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        const optionList2 = data.listAuthors ? data.listAuthors.items : []
        return {
          optionList2
        }
      }
    }
  ),
  graphql(
    QueryTags,
    {
      skip: ({ type, tagSearch }) => type !== 'tag' || !tagSearch,
      options: ({ tagSearch, project }) => {
        const { searchString2 } = tagSearch
        let filter = { }
        if (searchString2) {
          filter.name = { match: searchString2 }
        }
        const variables = {
          filter,
          limit: 40,
          offset: 0,
          project
        }
        return {
          variables,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        const optionList2 = data.listTags ? data.listTags.items : []
        return {
          optionList2
        }
      }
    }
  )
)(AssetTransferModal))
