import { Empty, Skeleton } from 'antd'

import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import Accordion from '../../components/ui/dataDisplay/Accordion'
import Tooltip from '../../components/ui/dataDisplay/ToolTip'
import DropDown from '../../components/ui/dataEntry/inputs/DropDown'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import InformationIcon from '../../components/ui/general/icons/InformationIcon'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetMetricData from '../../graphQL/monitor/getMetricData'

// import PropTypes from 'prop-types'
const width = [ '100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%' ]

class MonitorDataView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      apiMetaData: null,
      selectedDatadogUrl: ''
    }

    this.setInterval = setInterval(this.props.handleQueryParams, 300000)
    this.setInterval2 = setInterval(this.handleCallReftech, 300000)
  }

  handleCallReftech = () => {
    if (this.props.fetchApiResonse) {
      this.props.fetchApiResonse()
    }
    this.props.handleLastRefreshUpdate()
  }

  UNSAFE_componentWillReceiveProps = (newprops) => { // eslint-disable-line camelcase
    if (!_.isEmpty(newprops.apiResponse) && newprops.apiResponseType !== 'iframe') {
      let apiMetaDataParse = JSON.parse(newprops.apiResponse)
      this.setState({
        apiMetaData: apiMetaDataParse && apiMetaDataParse.data
      })
    }

    if (!_.isEmpty(newprops.apiResponse) && newprops.apiResponseType === 'iframe') {
      this.setState({
        selectedDatadogUrl: newprops.apiResponse
      })
    }

    if (_.isEmpty(newprops.apiResponse) && !_.isEqual(this.props.apiResponse, newprops.apiResponse)) {
      this.setState({
        apiMetaData: []
      })
    }
    if (!_.isEmpty(newprops.apiMetaData) && !_.isEqual(this.props.apiMetaData, newprops.apiMetaData)) {
      this.setState({
        apiMetaData: newprops.apiMetaData && newprops.apiMetaData.data
      })
    }

    if (!_.isEmpty(newprops.selectedDatadogUrl) && !_.isEqual(this.props.selectedDatadogUrl, newprops.selectedDatadogUrl)) {
      this.setState({
        selectedDatadogUrl: newprops.selectedDatadogUrl
      })
    }
  }

  componentWillUnmount = () => {
    if (this.setInterval) {
      clearInterval(this.setInterval)
    }

    if (this.setInterval2) {
      clearInterval(this.setInterval2)
    }
  }

  render () {
    const { selectedDashboard, dataDogTitle, timeList, selectedTime, onTimeSelect, isCalenderSelected, rangeTime, lastRefresh, isLoading, apiResponseType } = this.props
    const { apiMetaData, selectedDatadogUrl } = this.state
    let metrics = apiMetaData && apiMetaData.metrics
    return <React.Fragment>
      {!_.isEmpty(selectedDashboard)
        ? dataDogTitle === selectedDashboard || apiResponseType === 'iframe'
          ? <React.Fragment>
            { <iframe
              src={selectedDatadogUrl}
              width='100%'
              height='100%'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full'
              title='DataDog Dashboard'
              loading='lazy'
            /> }

          </React.Fragment>
          : <React.Fragment>
            <div className='external-dashboard-header'>
              <React.Fragment>
                <div className='dashboard-header-meta'>
                  {apiMetaData && apiMetaData.name ? <p><span className='dashboard-header-label'>Name : </span> {_.size(apiMetaData && apiMetaData.name) > 40
                    ? <Tooltip placement='topLeft' description={apiMetaData && apiMetaData.name} childComponent={apiMetaData && apiMetaData.name} /> : apiMetaData && apiMetaData.name} </p> : ''}
                  {apiMetaData && apiMetaData.description ? <p><span className='dashboard-header-label'>Description : </span> {_.size(apiMetaData && apiMetaData.description) > 40
                    ? <Tooltip placement='topLeft' description={apiMetaData && apiMetaData.description} childComponent={apiMetaData && apiMetaData.description} /> : apiMetaData && apiMetaData.description}</p> : ''}
                </div>
                <div className='dashboard-header-right'>
                  <p><span>Last Refresh : </span> {lastRefresh}</p>
                  <DropDown
                    options={timeList}
                    selectedValue={isCalenderSelected ? rangeTime : selectedTime}
                    placeHolder='Select Time'
                    valueParam='id'
                    displayParam='name'
                    onOptionSelect={onTimeSelect}
                    className={(selectedTime || selectedTime === null) ? 'selected' : ''} />
                </div>
              </React.Fragment>
            </div>
            { !_.isEmpty(apiMetaData)
              ? <Skeleton active avatar={false} title={false} paragraph={{ rows: 5, width: width }} loading={isLoading}>
                <div className='external-dashboard'>
                  <Accordion
                    title='Overview'
                    childComponent={
                      <div className='external-dashboard-overview'>
                        <div className='overview-details'>
                          {(metrics || []).map(item => {
                            let backgroudColour = (!_.isEmpty(item && item.backgroudColour) && (item && item.backgroudColour !== '#FFFFFF')) ? _.isEmpty(item && item.backgroudColour) ? '#41c464' : item.backgroudColour : '#41c464'
                            let textColour = (!_.isEmpty(item && item.textColour) && (item && item.textColour !== '#000000')) ? _.isEmpty(item && item.textColour) ? '#fff' : item.textColour : '#fff'
                            let style = { color: textColour }
                            return (
                              <div className='overview-details-cell' style={{ backgroundColor: backgroudColour, borderBottom: `1px solid ${textColour}` }} key={uuidv4()}>
                                <div className='details-cell-header' style={{ borderBottom: `1px solid ${textColour}` }}>
                                  <div className='details-cell-left'>
                                    <Tooltip placement='rightTop' childComponent={<p style={style}>{item && item.name}</p>} description={<p>{item && item.name}</p>} parentCompoent={'root'} />
                                  </div>
                                  <div className='details-cell'>
                                    <ButtonContainer style={style} displayTitle={item && item.description} childComponent={<InformationIcon stroke={textColour} fill={textColour} />} />
                                  </div>
                                </div>
                                <div className='details-cell-info'>
                                  <p style={style}>{item && item.value}</p>
                                  <span style={style}>{item && item.unit}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                      </div>
                    }
                  />
                </div>
              </Skeleton>
              : <Empty description={'API data not available.'} />}
          </React.Fragment>

        : <Empty description='Please select the dashboard.' />}
    </React.Fragment>
  }
}

MonitorDataView.propTypes = {

}

export default withApollo(compose(
  graphql(
    QueryGetMetricData,
    {
      skip: ({ configItemId, selectedConfigId, valueId }) => {
        return !configItemId || !selectedConfigId || !valueId
      },
      options: ({ project, appClient, selectedConfigId, configItemId, valueId, queryParams }) => {
        let variables = { id: selectedConfigId, configItemId, valueId, project, queryParams }
        return {
          variables: variables,
          fetchPolicy: 'network-only',
          client: appClient
        }
      },
      props: (props) => {
        const apiResponse = props.data && props.data.getMetricData ? props.data.getMetricData.apiResponse : []
        const apiResponseType = props.data && props.data.getMetricData ? props.data.getMetricData.type : ''
        const result = !(props.data && props.data.error) ? apiResponse : []
        return {
          apiResponse: result,
          isLoading: props.data.loading || !props.data.getMetricData,
          apiResponseType,
          fetchApiResonse: () => {
            return props.data.refetch()
          }
        }
      }
    }
  )
)(withRouter(MonitorDataView)))
