import gql from 'graphql-tag'

export default gql(`
query ($limit: Int, $offset: Int, $order: String , $orderBy: String $project: String){
    listFolders(
        limit: $limit
        offset: $offset
        order: $order
        orderBy: $orderBy
        project: $project
        ){
            items{
                id
                name
                tags{
                    key
                    name
                    type
                    module
                    assetCount
                    mediaCount
                    isTransferring
                }
                position
                createdAt
            }
            totalCount
        }
  }
`)
