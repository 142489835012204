import gql from 'graphql-tag'

export default gql(`
query hyperionListBills( $userId: ID! $project: String ) {
    hyperionListBills( 
        userId: $userId
        project: $project
    ) {
        id
        startDate
        endDate
        billId
        transactionDate
        subscriptionId
        billingAccountId
        plan{
            name
            providerId
            id
            description
            price 
            interval
            name
            provider{
                name
            }
        }
        amount
        status
        coveragePeriod
        systemLog{
            requestPayload
            error
        }
    }
}`)
