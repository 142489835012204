import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $assetId: ID! $isDefault: String $imageTypeId: ID! $categoryId: ID $mediaId: ID! 
        $settings: [CreateImageSettingInput] $cropPosition: CROP_POSITION $project: String) {
        updateAssetImage(
            input:  {
                id: $id
                mediaId: $mediaId
                assetId: $assetId
                isDefault: $isDefault
                imageTypeId: $imageTypeId
                categoryId: $categoryId
                settings: $settings
                cropPosition: $cropPosition
              }
              project: $project
        ) {
            id
            assetId
            mediaId
            isDefault
            fileName
            createdAt
            name
            type{
                name
                id
            }
            fileSize
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions{
                x1
                x2
                y1
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
        }
    }`
)
