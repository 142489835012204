import gql from 'graphql-tag'

export default gql(`
query listMediaCategories($project: String){
    listMediaCategories(project: $project){
            id
            type
            label
            isActive
    }
}`)
