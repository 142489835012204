import gql from 'graphql-tag'

export default gql(`
    query listUsers($search: String $project: String $status: USER_STATUS, $accessRoleGroups: [ID] $enabled: Boolean  ){
        listUsers(input:{
          id: $search
          status: $status
          accessRoleGroups: $accessRoleGroups
          enabled: $enabled
        }, project: $project){
          items{
            id
            status
            name
            enabled
            status
            lastLogin
            accessRoleGroups {
              id
              name
              modules{
                name
                permissions
              }
            }
          }
        }
    }`
)
