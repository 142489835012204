import React from 'react'
import PropTypes from 'prop-types'
import { generateCroppedThumbnail } from './../../../util/util'

import './../ui.style.scss'

class MatchListViewDetails extends React.Component {
  render () {
    const { teamA, teamB } = this.props
    return (
      <div className='match-listview-details'>
        <p>{teamA.code}</p>
        <img src={teamA.media && teamA.media.length && teamA.media[ 0 ] ? generateCroppedThumbnail(teamA.media[0], 200, 200, '1:1') : require('../../../assets/images/avatar-team-icon.png')} />
        <p className='vs'>VS</p>
        <img src={teamB.media && teamB.media.length && teamB.media[ 0 ] ? generateCroppedThumbnail(teamB.media[0], 200, 200, '1:1') : require('../../../assets/images/avatar-team-icon.png')} />
        <p>{teamB.code}</p>
      </div>
    )
  }
}

MatchListViewDetails.propTypes = {
  /** team A details of MatchListViewDetails */
  teamA: PropTypes.object,
  /** team A details of MatchListViewDetails */
  teamB: PropTypes.object

}

MatchListViewDetails.defaultProps = {
}

export default MatchListViewDetails
