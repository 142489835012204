import React from 'react'
import PropTypes from 'prop-types'

import TagsInputContainer from './../inputs/TagsInputContainer'
import './../../ui.style.scss'

const skippedTagsName = []

class EditTagDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTag: '',
      selectedIndex: undefined
    }
  }

    onTagSearch = (index, searchTag) => {
      this.setState({
        searchTag,
        selectedIndex: searchTag ? index : undefined
      })
    }

    render () {
      const { selectedTags, type, categoryList, selectedCategory, systemTags, project } = this.props
      const { searchTag } = this.state
      let categories = (categoryList || []).filter(item => item.type === type && item.isActive)
      if (selectedCategory) {
        const selected = categoryList.find(item => item.id === selectedCategory)
        if (selected.isActive === false) { categories.push(selected) }
      }
      return (
        <div className='edit-image-details'>
          <div className='system-tags'>
            {systemTags.filter((tag) => !skippedTagsName.includes(tag.name)).sort((a, b) => a.position - b.position).map((tag, index) => (
              <div className='input-field' key={index}>
                <label className='input-title'>{tag.name}</label>
                <TagsInputContainer
                  selectedItems={selectedTags.filter(item => item && (item.type === tag.name)).map(item => item.name)}
                  onChange={this.props.handleTagChange}
                  displayParameter='name'
                  placeholder='Insert Tags'
                  parentCompoent='edit-image-modal'
                  onSearch={this.onTagSearch.bind(this, index)}
                  searchTag={this.state.selectedIndex === index ? searchTag : undefined}
                  type={tag.name}
                  isCustom={tag.isCustom}
                  module={tag.isCustom ? 'CONTENT_BANK' : 'ALL'}
                  isDropdownInvisible
                  project={project}
                />
              </div>
            ))}
          </div>
        </div>
      )
    }
}

EditTagDetails.propTypes = {
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func

}

EditTagDetails.defaultProps = {
}

export default EditTagDetails
