import gql from 'graphql-tag'

export default gql(`
query($filter: TableTagFilterInput, $limit: Int, $offset: Int $project: String) {
    listTags(
        filter: $filter
        limit: $limit
        offset: $offset
        project: $project
        ){
        items {
            key
            type
            name
            module
            assetCount
            mediaCount
            isTransferring
            isError
        }
        totalCount
    }
}`)
