import React, { Component } from 'react'
import AssetListCell from '../../components/ui/dataDisplay/AssetListCell'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import GetAssetList from '../../graphQL/content/getRelatedAssetsByMedia'
import { Skeleton, Empty } from 'antd'
import moment from 'moment'
import { generateCroppedThumbnail } from './../../util/util'

const width = ['calc(100% - 100px)', '50%', '100%']
export class AssetList extends Component {
  render () {
    const { selectedMedia, isLoading, project } = this.props
    return (
      <div className='media-assetlist'>
        { selectedMedia && !isLoading
          ? (this.props.assetList && this.props.assetList.length)
            ? (this.props.assetList || []).map((asset) => {
              let imageUrl
              if (asset.defaultMedia) {
                imageUrl = generateCroppedThumbnail(asset.defaultMedia, 87, 48, '16:9')
              }
              return <AssetListCell
                key={asset.id}
                assetType={asset.type}
                imageUrl={imageUrl}
                status={asset.status}
                date={moment(new Date(asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                assetName={asset.title}
                assetId={asset.id}
                displayId={asset.shortId || asset.id}
                onSelect={() => {}}
                isSelected={false}
                isArchival={false}
                isChecked={false}
                onCheck={() => {}}
                project={project}
                asset={asset}
              />
            }) : <Empty description={'No Asset Found'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          : <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} />
        }
      </div>
    )
  }
}

export default withApollo(
  compose(
    graphql(
      GetAssetList,
      {
        options: (props) => {
          const variables = {
            id: props.selectedMedia,
            offset: 0,
            size: 100,
            project: props.project
          }
          return {
            variables
          }
        },
        props: (props) => {
          return {
            assetList: props.data && props.data.getRelatedAssetsByMedia && props.data.getRelatedAssetsByMedia.items && props.data.getRelatedAssetsByMedia.items.length ? props.data.getRelatedAssetsByMedia.items : [],
            isLoading: props.data.loading
          }
        }
      }
    )
  )(AssetList)
)
