import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'

const ellipsis = (ComposedComponent, ellipsisText = '...') => {
  class ReactMultilineEllipsis extends Component {
    constructor (props) {
      super(props)
      this.checkEllipsis = this.checkEllipsis.bind(this)
      this.getDOMNodeProperty = this.getDOMNodeProperty.bind(this)
      this.startsWith = this.startsWith.bind(this)

      this.state = {
        wrappedText: `${props.text}`,
        originalText: `${props.text}`
      }
    }

    componentDidMount () {
      window.addEventListener('resize', this.updateDimensions)
      this.checkEllipsis()
    }

    componentDidUpdate () {
      this.checkEllipsis()
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions = () => {
      this.checkEllipsis(true)
    }

    getDOMNodeProperty (node, property) {
      return document.defaultView.getComputedStyle(node, null)
        .getPropertyValue(property)
    }

    checkEllipsis (isResize) {
      const node = findDOMNode(this.multiLineRef)
      const lineHeight = this.getDOMNodeProperty(node, 'line-height').replace('px', '')
      const height = this.getDOMNodeProperty(node, 'height').replace('px', '')

      const numberOfLines = height / lineHeight
      if (isResize) {
        const currentText = this.state.originalText
        this.setState({ wrappedText: currentText })
      } else if (numberOfLines > this.props.maxLines) {
        let currentText = this.state.wrappedText
        const lengthFactor = numberOfLines / parseInt(this.props.maxLines, 10)
        let ellipsedText = currentText
        if (lengthFactor > 2) {
          ellipsedText = ellipsedText = currentText.substring(0, currentText.length / lengthFactor + 15)
        } else {
          ellipsedText = currentText.substring(0, currentText.length - 10)
        }
        const wrappedText = `${ellipsedText}${ellipsisText}`
        if (wrappedText !== this.state.wrappedText) { this.setState({ wrappedText }) }
      } else if (this.hasTextChanges()) {
        this.setState({ originalText: `${this.props.text}`, wrappedText: `${this.props.text}` })
      }
    }

    hasTextChanges () {
      return this.props.text !== this.state.originalText
    }

    startsWith (str, textToSearch) {
      return str.startsWith
        ? str.startsWith(textToSearch)
        : str.indexOf(textToSearch, 0) === 0
    }

    render () {
      return <ComposedComponent ref={(node) => { this.multiLineRef = node }} {...this.props} {...this.state} />
    }
  }

  ReactMultilineEllipsis.propTypes = {
    text: PropTypes.string.isRequired,
    maxLines: PropTypes.string
  }

  return ReactMultilineEllipsis
}

class MultilineEllipsis extends Component {
  render () {
    return <div>{this.props.wrappedText}</div>
  }
}

MultilineEllipsis.propTypes = {
  wrappedText: PropTypes.string.isRequired
}

export default ellipsis(MultilineEllipsis, ' ...')
