import gql from 'graphql-tag'

export default gql(`
    mutation forceLogout($user: String! $project: String) {
        forceLogout(
            user: $user
            project: $project
        ) {
            status
            error 
            message 
        }
    }
`)
