import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import Input from '../../components/ui/dataEntry/inputs//Input'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import './Auth.scss'

class ConfirmOtp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      code1: '',
      code2: ''
    }
  }

    handleMfaChange = ({ target }) => {
      const { name, value } = target
      if (name === 'code1') { this.setState({ code1: value }) } else { this.setState({ code2: value }) }
    }

    render () {
      const { isLoading, handleCancel, handleSubmit } = this.props
      const { code2, code1 } = this.state
      // return <Modal
      //   className={`confirm-modal mfa-modal`}
      //   title={'Confirm OTP'}
      //   visible={isVisible}
      //   cancelText={'Cancel'}
      //   okText={'Confirm'}
      //   onOk={() => handleSubmit(code1, code2)}
      //   onCancel={handleCancel}
      //   okButtonProps={{ disabled: !(code1) }}
      //   closable={false}
      //   centered
      //   confirmLoading={isLoading}
      //   destroyOnClose
      //   // width={760}
      // >
      // </Modal>
      return <div className='login'>
        <div className='mfa-confirm-container'>
          <div className='header'>
            <span>Confirm OTP</span>
            <div />
          </div>
          <Form onSubmit={(event) => {
            event.preventDefault()
            return handleSubmit(code1, code2)
          }
          }>
            <Form.Item >
              <Input
                title={'MFA code'}
                placeholder={`Enter MFA code`}
                inputName='code1'
                isRequired
                value={code1}
                handleChange={this.handleMfaChange}
              />
            </Form.Item>
            <Form.Item className='footer'>
              <LoadingButton
                type='secondary'
                buttonText={'Cancel'}
                buttonClass='login-form-button login-form-submit'
                isDisabled={isLoading}
                htmlType='button'
                onClick={handleCancel} />
              <LoadingButton
                type='primary'
                buttonText={'Confirm'}
                buttonClass='login-form-button login-form-submit'
                onClick={(event) => {
                  event.preventDefault()
                  return handleSubmit(code1, code2)
                }}
                isDisabled={isLoading || !code1}
                htmlType='submit'
                isLoading={isLoading} />
            </Form.Item>
          </Form>
        </div>
      </div>
    //   <div className='mfa-confirm-container'>
    //   <div className='two-fa-container'>
    //     <Input
    //       title={'MFA code'}
    //       placeholder={`Enter MFA code`}
    //       inputName='code1'
    //       isRequired
    //       value={code1}
    //       handleChange={this.handleMfaChange}
    //     />
    //     {/* <Input
    //             title={'MFA code 2'}
    //             placeholder={`Enter MFA code 2`}
    //             inputName='code2'
    //             isRequired={true}
    //             value={code2}
    //             handleChange={this.handleMfaChange}
    //         /> */}
    //   </div>
    // </div>
    }
}

ConfirmOtp.propTypes = {
  /** loading status of Modal. */
  isLoading: PropTypes.bool,
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func
}

export default ConfirmOtp
