/* global Image */
/** Function takes an image and crop with specific x,y values */
import { Base64 } from 'js-base64'
import config from '../constants/config'
// const domainName = 'http://cms-dev-micro.create.diagnal.com/resources/images'

export const getCropImageUrl = (image, settings, width, height) => {
  let domainName = `${location.origin.toString()}/resources/images`
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
    domainName = `${config.LOCAL_URL}/resources/images`
  }
  const imageFileName = settings.fileName
  let toEncode = null
  let encoded = null
  if (settings && settings.x1 !== null) {
    const x1 = settings.x1
    const y1 = settings.y1
    const x2 = settings.x2
    const y2 = settings.y2
    const cropWidth = x2 - x1
    const cropHeight = y2 - y1
    if (width) {
      toEncode = JSON.stringify({
        key: imageFileName,
        edits: {
          extract: {
            left: x1,
            top: y1,
            width: cropWidth,
            height: cropHeight
          },
          resize: {
            width: width,
            height: height
          },
          jpeg: {
            quality: 80
          }
        }
      })
    } else {
      toEncode = JSON.stringify({
        key: imageFileName,
        edits: {
          extract: {
            left: x1,
            top: y1,
            width: cropWidth,
            height: cropHeight
          },
          jpeg: {
            quality: 80
          }
        }
      })
    }
  } else {

  }
  encoded = Base64.encode(toEncode)
  return `${domainName}/${encoded}`
}

// Generate image thumbnails

export const generateThumbnail = (fileName, width, height) => {
  let domainName = `${location.origin.toString()}/resources/images`
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
    domainName = `${config.LOCAL_URL}/resources/images`
  }
  const imageFileName = fileName
  const toEncode = JSON.stringify({
    key: imageFileName,
    edits: {
      resize: {
        width: width,
        height: height
      }
    }
  })
  const encoded = Base64.encode(toEncode)
  return `${domainName}/${encoded}`
}

// Generate cropped thumbnails

export const generateCroppedThumbnail = (image, width, height, aspectRatio) => {
  let domainName = `${location.origin.toString()}/resources/images`
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
    domainName = `${config.LOCAL_URL}/resources/images`
  }
  const settings = getAspectRatioSettings(image.aspectRatio, image.settings, aspectRatio)
  if (!settings) { return null }
  const imageFileName = settings.fileName
  const x1 = settings.x1
  const y1 = settings.y1
  const x2 = settings.x2
  const y2 = settings.y2
  const cropWidth = x2 - x1
  const cropHeight = y2 - y1
  let toEncode = null

  if (width) {
    toEncode = JSON.stringify({
      key: imageFileName,
      timestamp: image.createdAt,
      edits: {
        extract: {
          left: x1,
          top: y1,
          width: cropWidth,
          height: cropHeight
        },
        resize: {
          width: width,
          height: height
        },
        jpeg: {
          quality: 80
        }
      }
    })
  } else {
    toEncode = JSON.stringify({
      key: imageFileName,
      timestamp: image.createdAt,
      edits: {
        extract: {
          left: x1,
          top: y1,
          width: cropWidth,
          height: cropHeight
        },
        jpeg: {
          quality: 80
        }
      }
    })
  }
  const encoded = Base64.encode(toEncode)
  return `${domainName}/${encoded}`
}

const getAspectRatioSettings = (aspectRatios, settings, ratio) => {
  let selectedRatio = (aspectRatios || []).find(item => item.title === ratio)
  if (!selectedRatio) {
    selectedRatio = aspectRatios.filter(item => item.aspectRatio)[0]
  }
  const selectedSettings = selectedRatio ? settings.find(item => item.aspectRatio === selectedRatio.aspectRatio) : settings[0]
  return selectedSettings || settings[0]
}

export const getFormattedDuration = (time) => {
  const duration = parseInt(time) / (60)
  const seconds = parseInt(time) % (60)
  const totalMinutes = duration
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.floor(totalMinutes % 60)
  return ((hours < 10 ? '0' + hours : hours) + 'h ' + (minutes < 10 ? '0' + minutes : minutes) + 'm ' + (seconds < 10 ? '0' + seconds : seconds) + 's')
}

export const getFileSize = (bytes) => {
  // const imageSizeKb = fileSize ? (fileSize / (1024)).toFixed(2) : null
  // return imageSizeKb ? (imageSizeKb > 1024 ? `${(imageSizeKb / 1024).toFixed(2)} Mb` : `${imageSizeKb} Kb`) : null
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (parseInt(bytes) === 0) return '0 Byte'
    const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, index), 2) + ' ' + sizes[index]
  } else return null
}

export const videoFormat = ['mkv', 'mp4', 'avi', 'mpeg4', 'webm', 'wmv', 'asf', 'm4v', 'mov']
export const imageFormat = ['jpg', 'png', 'jpeg', 'webp', 'gif']
export const vodStatusList = ['QUEUED', 'COPIED', 'TRIGGERED', 'INGEST', 'MC-PROCESSING', 'PROCESSING', 'COMPLETE']

const validateImage = (imageDataUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve()
    }
    img.onerror = (err) => {
      reject(err)
    }
    img.src = imageDataUrl
  })
}

export const validateUploadedFile = (file, list) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const imageDataUrl = reader.result
      validateImage(imageDataUrl).then(() => {
        resolve(file)
      }).catch((error) => {
        reject(error)
      })
    }
  })
}

export const defaultAspectRatioList = [
  {
    'resolutions': [{
      'height': 900,
      'key': 'Square - 900',
      'width': 900
    }],
    'title': '1:1'
  },
  {
    'resolutions': [
      {
        'height': 768,
        'key': 'HD Ready',
        'width': 1366
      },
      {
        'height': 1080,
        'key': 'Full HD',
        'width': 1920
      },
      {
        'height': 1440,
        'key': '2560 * 1440',
        'width': 2560
      },
      {
        'height': 720,
        'key': '1280 * 720',
        'width': 1280
      },
      {
        'height': 360,
        'key': '640 * 360',
        'width': 640
      },
      {
        'height': 270,
        'key': '480 * 270',
        'width': 480
      },
      {
        'height': 180,
        'key': '320 * 180',
        'width': 320
      },
      {
        'height': 461,
        'key': '820 Width',
        'width': 820
      }
    ],
    'title': '16:9'
  },
  {
    'resolutions': [
      {
        'height': 1920,
        'key': 'Portrait Full HD',
        'width': 1080
      }
    ],
    'title': '9:16'
  }
]

export const validateIPaddress = (ipaddress) => {
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipRegex.test(ipaddress)
}

export const timeSince = (time) => {
  let timeStamp = new Date(time).getTime()
  var now = new Date()

  var secondsPast = (now.getTime() - timeStamp) / 1000
  if (secondsPast < 60) {
    return parseInt(secondsPast) + 's'
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + 'm'
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + 'h'
  }
  if (secondsPast > 86400) {
    let day = new Date(timeStamp).getDate()
    let month = new Date(timeStamp).toDateString().match(/ [a-zA-Z]*/)[0].replace(' ', '')
    let year = new Date(timeStamp).getFullYear() === now.getFullYear() ? '' : ' ' + new Date(timeStamp).getFullYear()
    return day + ' ' + month + year
  }
}

export const redirectToLink = (link) => {
  var anchor = document.createElement('a')
  anchor.href = link
  anchor.target = '_blank'
  document.body.appendChild(anchor)
  anchor.click()
}

export const findFileExtension = (filename) => {
  if (filename && filename.includes('.')) {
    return filename.split('.').pop().toUpperCase()
  }
  return null
}

export const checkIsItExpired = (data) => {
  const date1 = new Date()
  const date2 = new Date(data)
  const timeDifference = date1 - date2
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
  return daysDifference > window.config.publishButtonExpiryDays
}

export const wordCapitalize = (sentence) => {
  return sentence.replace(/\b\w/g, function (l) { return l.toUpperCase() })
}

export const getFormattedGroupList = (dataList, project) => {
  let modulesToAdd = ['ASSET_MANAGER', 'CONTENT_BANK', 'CHANNEL_MANAGER', 'BUCKET_MANAGER', 'MATCH_MANAGER', 'ROLE_MANAGER', 'JOB_MANAGER', 'CUSTOMER_MANAGER', 'APP_MANAGER', 'MONITOR_MANAGER', 'DRM_MANAGER', 'DATA_MANAGER']
  if (project === 'hyperion') {
    modulesToAdd = ['ASSET_MANAGER', 'CONTENT_BANK', 'BUCKET_MANAGER', 'OFFER_MANAGER', 'PARTNER_MANAGER', 'ROLE_MANAGER', 'DATA_MANAGER', 'CUSTOMER_MANAGER', 'APP_MANAGER', 'MONITOR_MANAGER', 'INGEST_MANAGER']
  }

  // Function to check if a module already exists
  function moduleExists (moduleName, modules) {
    return modules.some(module => module.name === moduleName)
  }

  // Process the dataList
  dataList.map(item => {
    // Remove modules not in modulesToAdd
    item.modules = item.modules.filter(module => modulesToAdd.includes(module.name))

    // Add missing modules
    modulesToAdd.forEach(moduleName => {
      if (!moduleExists(moduleName, item.modules)) {
        item.modules.push({ name: moduleName, permissions: [] })
      }
    })
  })

  const groupData = dataList.map(({ name, modules, users
  }) => {
    const moduleColmn = {}
    modules.forEach(({ name: moduleName, permissions }) => {
      moduleColmn[moduleName === 'DATA_MANAGER' ? ' ADMIN_MANAGER' : moduleName] = (permissions && permissions.length && `"${permissions.join(', ')}"`) || 'No Access'
    })
    const userList = users && users.length && users.map(({ id }) => id)
    return ({ 'User Group': `"${name}"`, Users: (userList && userList.length && `"${userList.join(', ')}"`) || 'No Users', ...moduleColmn })
  })

  return groupData
}

export const convertToCSV = (arr) => {
  let maxKeysObject = arr.reduce((maxObj, currentObj) =>
    Object.keys(currentObj).length > Object.keys(maxObj).length ? currentObj : maxObj,
  {}
  )
  const header = Object.keys(maxKeysObject).join(',')
  const rows = arr.map(obj => Object.values(obj).join(','))
  return header + '\n' + rows.join('\n')
}

export const downloadCSV = (csvContent, fileName) => {
  const blob = new Blob([csvContent], { type: 'text/csv' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.click()
}
export const getSortedUserList = (userList) => {
  const sortedItems = userList.sort((a, b) => {
    if (a.enabled !== b.enabled) {
      return a.enabled - b.enabled
    }

    if (a.enabled && b.enabled) {
      if (a.status === 'FORCE_CHANGE_PASSWORD' && b.status !== 'FORCE_CHANGE_PASSWORD') {
        return -1
      }
      if (a.status !== 'FORCE_CHANGE_PASSWORD' && b.status === 'FORCE_CHANGE_PASSWORD') {
        return 1
      }
    }

    return 0
  })

  return sortedItems
}

export const truncate = (str, n) => {
  if (str) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str
  }
  return null
}
