import React from 'react'
import { Popover, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import NotificationListCell from '../../dataDisplay/NotificationListCell'

// const data = 'Yo this is the new notification dropdown'
const PopOverListContainer = ({ listData, parentCompoent, onSelectOption, openedNotification, toggleShowOptions, setNotificationRef, handleDismissNotification, loadMoreNotification, isLoading, isPaginating, hasMore, notificationContainerRef, handleDismissAllNotification, isNotificationAvailable, ...props }) => {
  const title = (<div className='popover-container-header'>
    <span>{'NOTIFICATIONS'}</span>
    <span className={isNotificationAvailable ? 'popover-container-header-button' : 'popover-container-header-button-disabled'} onClick={handleDismissAllNotification}>{'Mark All As Read'}</span>
  </div>)

  const content = (<div>
    {(listData || []).map(item => <NotificationListCell key={item.id} id={item.id} title={item.type} content={item.message} time={item.createdAt} onSelectOption={onSelectOption} openedNotification={openedNotification} toggleShowOptions={toggleShowOptions} read={item.dismissed} handleDismissNotification={handleDismissNotification} />)}
  </div>)

  const emptyNotification = (<div>
    <div className={`notification-cell-empty`}>
      No Notifications
    </div>
  </div>)

  const finalContent = (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMoreNotification}
      hasMore={hasMore}
      initialLoad={false}
      useWindow={false}
    >
      <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
        {_.isEmpty(listData) ? emptyNotification : content}
      </Skeleton>
      <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
    </InfiniteScroll>
  )

  return (
    <div className='popover-notification-container' id='notification' ref={node => { setNotificationRef(node) }}>
      <Popover className='popover-btn' title={title} placement={'bottom'} content={finalContent} trigger='click' visible overlayClassName={'popover-notification-container'} getPopupContainer={() => parentCompoent ? document.getElementById(parentCompoent) : ''} />
    </div>
  )
}

PopOverListContainer.propTypes = {

}

export default PopOverListContainer
