import React, { Component } from 'react'
import AppContext from '../../AppContext'

class MatchSettings extends Component {
  /** Constructor function for initilise state values */
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return <AppContext.Consumer>
      {() => {
        return <div className='match-info-container'>
          <div className='match-info-inner-container'>
            <div className='on-demand-settings'>
              <span>Faster Sync</span>
              <div className='settings-button-container'>
                <button type='primary'
                  onClick={() => this.props.onHnadleFasterSync('start')}
                  buttonClass='save-btn'
                  isLoading={false}
                  disabled={this.props.isSyncDisabled}
                >Start Sync</button>
                <button type='primary'
                  onClick={() => this.props.onHnadleFasterSync('stop')}
                  buttonClass='save-btn'
                  isLoading={false}
                  disabled={this.props.isSyncDisabled}
                >Stop Sync</button>
              </div>
            </div>
          </div>

        </div>
      }}
    </AppContext.Consumer>
  }
}

export default MatchSettings
