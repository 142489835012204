import gql from 'graphql-tag'

export default gql(`
    mutation editAppConfig($title: String $key: String! $app: ID! $group: ID $type: AppConfigType $project: String $fields: [ConfigModelFieldInput] $id: ID! $tags: [ID] $isArchived: Boolean $customMeta: [MetaFieldInput] $isCritical: Boolean $isCriticalChanged: Boolean $assignedUsers: [String] $isAssigneeChanged: Boolean) {
        editAppConfig(
            input: {
                title: $title
                key: $key
                app: $app
                group: $group
                type: $type
                fields: $fields
                id: $id
                tags: $tags
                isArchived: $isArchived
                customMeta: $customMeta
                isCritical: $isCritical
                isCriticalChanged: $isCriticalChanged
                assignedUsers: $assignedUsers
                isAssigneeChanged: $ isAssigneeChanged
            },
            project: $project
        ) {
            id
            isArchived
            isPublished
            createdBy
            createdAt
            updatedAt
            updatedBy
            assignedUsers
            key
            project
            publishEndDate
            title
            type
            isCritical
            app{
                id
                name
                url
                isCritical
            }
            group{
              key
              name
              type
              isCritical
              assignedUsers
            }
            fields{
                id
                type
                isRequired
                useAsTitle
                name
                position
                tooltipText
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
        }
    }`
)
