import React from 'react'
import PropTypes from 'prop-types'

const TickIcon = ({ width, height, color, onClick, className, ...props }) => (
  <svg className='tick-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path stroke={color} strokeWidth='3' d='M14.09 3l-9 9L1 7.91' />
      <path d='M0 0h15v15H0z' />
    </g>
  </svg>
)

TickIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TickIcon.defaultProps = {
  width: 18,
  height: 18,
  color: '#1890FF'
}

export default TickIcon
