import gql from 'graphql-tag'

export default gql(`
query searchAssetsQuery($filters: [CreateFieldInput], $query: String $limit: Int $offset: Int $project: String) {
  listAssetsbyQuery(
        query: $query,
        filters: $filters
        limit: $limit
        offset: $offset
        project: $project
    ) {
      items {
        id
        isArchived
        shortId
        externalId
        title
        type
        updatedAt
        publishStartDate
        publishEndDate
        status
        isFree
        seasonNumber
        episodeNumber
        series{
          id
          assetId
          title
        }
        season{
            id
            assetId
            title
        }
        defaultMedia {
          id
          url
          fileName
          aspectRatio {
            title
            aspectRatio
            resolutions {
              url
            }
          }
          settings {
            aspectRatio
            outputFormat
            fileName
            x1
            y1
            x2 
            y2
          }
        }
      }
      totalCount
    }
}`)
