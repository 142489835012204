import React from 'react'
// import PropTypes from 'prop-types'
import Switch from '../dataEntry/inputs/Switch'
import PremierLeagueIcon from '../general/icons/PremierLeagueIcon'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import DropDown from '../dataEntry/inputs/DropDown'
import './../ui.style.scss'

const options = [{
  name: 'Option 1'
}, {
  name: 'Option 2'
}]

class AddCompetition extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  render () {
    const { project } = this.props
    const competitionCode = <div className='type'>
      <span>Competition Code</span>
      <div className='information-icon'>
        <InformationIcon />
      </div>
    </div>
    return (
      <div className='add-competition'>
        <div className='top'>
          <div className='team-img'>
            <PremierLeagueIcon />
          </div>
          <div className='top-data'>
            <div className='new-data'>
              <Input title='Title' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='new-data'>
              <Input title={competitionCode} placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
          </div>
        </div>
        <div className='data'>
          <div className='custom-data'>
            <div className='details'>
              <Input title='Opta ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='OCID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='OPTA Legacy ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details' id='details'>
              <label>Country</label>
              <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details'>
              <Input title='Country External ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details' id='details'>
              <label>Sport</label>
              <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details' id='details'>
              <label>Competition Format</label>
              <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details' id='details'>
              <label>Competition Type</label>
              <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details' id='details'>
              <label>Type</label>
              <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details' id='details'>
              <label>Source</label>
              <DropDown options={options} onOptionSelect={() => {}} selectedValue={''} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details'>
              <Input title='Display Order' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <div className='type'>
                <span>IsFriendly</span>
                <div className='information-icon'>
                  <InformationIcon />
                </div>
                <Switch isChecked={false} onChange={() => { }} />
              </div>
            </div>
          </div>
          <div className='custom-tags' id='custom-tags'>
            <label> Tags </label>
            <TagsInputContainer
              selectedItems={[]}
              onChange={() => {}}
              placeholder='Insert Tags'
              parentCompoent={'custom-tags'}
              displayParameter='name'
              onSearch={() => {}}
              project={project}
            />
          </div>
        </div>
      </div>
    )
  }
}

AddCompetition.propTypes = {

}

AddCompetition.defaultProps = {
}

export default AddCompetition
