import gql from 'graphql-tag'

export default gql(`
    query listAudits($contentId: ID, $project: String, $limit: Int, $offset: Int, $order: String, $module: MODULE_TYPE){
        listAudits(
            contentId : $contentId 
            project: $project 
            limit: $limit 
            offset: $offset 
            order: $order 
            module: $module 
            ) {
                items{
                    id
                    contentType
                    contentName
                    action
                    updatedAt
                    updatedBy
                    updatedObj{
                        isPosChange
                        name
                        value
                        oldValue
                    }
                }
                totalCount
            }
    }`
)
