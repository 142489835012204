import gql from 'graphql-tag'

export default gql(`
query($channelId: ID!) {
    getChannelConfigGroup(id: $channelId) {
        id
        name
        schemaId {
          id
        }
        fields{
          name
          value
          isRequired
          type
        }
        streams {
          id
          fields {
            value
            name
            isRequired
            type
          }
          url
        }
      }
}`)
