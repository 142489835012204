import React from 'react'
import PropTypes from 'prop-types'

import TagsInputContainer from '../inputs/TagsInputContainer'
import './../../ui.style.scss'

const skippedTagsName = []

class UploadQueueSystemTags extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTag: '',
      selectedIndex: undefined
    }
  }

    onTagSearch = (index, searchTag) => {
      this.setState({
        searchTag,
        selectedIndex: searchTag ? index : undefined
      })
    }

    render () {
      const { selectedTags, systemTags, parentCompoent, project } = this.props
      const { searchTag } = this.state
      return (
        <div className='upload-image-tags'>
          <div className='system-tags'>
            {(systemTags || []).filter((tag) => !skippedTagsName.includes(tag.name)).sort((a, b) => a.position - b.position).map((tag, index) => (
              <div className='input-field' key={index}>
                <label className='input-title'>{tag.name}</label>
                <TagsInputContainer
                  selectedItems={selectedTags.filter(item => item && (item.type === tag.name)).map(item => item.name)}
                  onChange={this.props.handleTagChange}
                  displayParameter='name'
                  placeholder='Insert Tags'
                  parentCompoent={parentCompoent || 'upload-modal'}
                  onSearch={this.onTagSearch.bind(this, index)}
                  searchTag={this.state.selectedIndex === index ? searchTag : undefined}
                  type={tag.name}
                  isCustom={tag.isCustom}
                  module={tag.isCustom ? 'CONTENT_BANK' : 'ALL'}
                  isDropdownInvisible
                  project={project}
                />
              </div>
            ))}
          </div>
        </div>
      )
    }
}

UploadQueueSystemTags.propTypes = {
  /**  */
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /** Parent component id */
  parentCompoent: PropTypes.string,
  /** Module name fo filtering tags */
  module: PropTypes.string

}

UploadQueueSystemTags.defaultProps = {
}

export default UploadQueueSystemTags
