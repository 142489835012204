import gql from 'graphql-tag'

export default gql(`
    mutation deleteConfigGroupSchema( $id: ID!) {
        deleteConfigGroupSchema(
            input: {
                id: $id
            }
        ) {
            id
        }
    }`
)
