import gql from 'graphql-tag'

export default gql(`
mutation updateFilter($id:ID! $name: String! $fields: [CreateFieldInput]){
    updateFilter(input:{
      id:$id
      name:$name
      fields: $fields
    }){
        id
        name
        fields{
            displayName
            name
            type
            value
            fieldName
            displayValue
        }
    }
  }
`)
