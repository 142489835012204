import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import './../ui/ui.style.scss'

import Range3Icon from './icons/Range3Icon'
import Range4Icon from './icons/Range4Icon'
import Range6Icon from './icons/Range6Icon'
import TickIcon from './icons/TickIcon'

// const Option = Select.Option

const availableRanges = [
  {
    name: 'Default',
    icon: <Range3Icon color={'#343A40'} onClick={() => {}} />,
    id: 0
  },
  {
    name: '3 Hours Stream',
    icon: <Range3Icon color={'#343A40'} onClick={() => {}} />,
    id: 1
  },
  {
    name: '4 Hours Stream',
    icon: <Range4Icon color={'#343A40'} onClick={() => {}} />,
    id: 2
  },
  {
    name: '5 Hours Stream',
    icon: <Range4Icon color={'#343A40'} onClick={() => {}} />,
    id: 3
  },
  {
    name: '6 Hours Stream',
    icon: <Range6Icon color={'#343A40'} onClick={() => {}} />,
    id: 4
  }
]

class PlayerRangeDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  componentDidMount = () => {
    this.setState({ assetList: this.props.assetList })
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

    handleClickOutside = () => {
      if (this.state.isVisible) {
        setTimeout(() => this.setState({ isVisible: false }), 200)
      }
    }

    showRangeOptions = () => {
      this.setState({ isVisible: true })
    }

    onChangeRange = (value) => {
      if (this.props.selectedRange !== value) {
        this.props.onChangeRange(value)
      }
    }

    render () {
      const { selectedRange, parentCompoent } = this.props
      const { isVisible } = this.state

      const content = (
        <div>
          { availableRanges.map((item, index) => <div key={index} className='range-option' onClick={() => this.onChangeRange(item.id)}>
            { item.icon }
            { item.name }
            { item.id === selectedRange ? <TickIcon /> : null }
          </div>) }
        </div>
      )
      let selectedRangeIcon
      if (selectedRange === 1) {
        selectedRangeIcon = <Range3Icon color={isVisible ? '#FF015A' : '#FFF'} onClick={this.showRangeOptions} />
      } else if (selectedRange === 2) {
        selectedRangeIcon = <Range4Icon color={isVisible ? '#FF015A' : '#FFF'} onClick={this.showRangeOptions} />
      } else if (selectedRange === 3) {
        selectedRangeIcon = <Range4Icon color={isVisible ? '#FF015A' : '#FFF'} onClick={this.showRangeOptions} />
      } else if (selectedRange === 4) {
        selectedRangeIcon = <Range6Icon color={isVisible ? '#FF015A' : '#FFF'} onClick={this.showRangeOptions} />
      } else {
        selectedRangeIcon = <Range3Icon color={isVisible ? '#FF015A' : '#FFF'} onClick={this.showRangeOptions} />
      }

      return (
        <Popover className='popover-btn' placement='topLeft' content={content} trigger='click' visible={isVisible} getPopupContainer={() => parentCompoent ? document.getElementById(parentCompoent) : ''} >
          { selectedRangeIcon }
        </Popover>
      )
    }
}

PlayerRangeDropDown.propTypes = {
  /** Placeholder text of TagsInput. */
  selectedRange: PropTypes.number,
  /** Function invokes when user wants to search tag */
  onChangeRange: PropTypes.func
}

PlayerRangeDropDown.defaultProps = {
  parentCompoent: 'player'
}

export default PlayerRangeDropDown
