import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Button, Skeleton } from 'antd'
// import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import AppContext from '../../../../AppContext'

import TeamOriginalData from '../../dataDisplay/TeamOriginalData'
import TeamCustomData from '../../dataDisplay/TeamCustomData'
import TeamSeasonData from '../../dataDisplay/TeamSeasonData'
import LoadingButton from '../../general/buttons/LoadingButton'
import { utilityService } from '../../../../services/UtilityService'

import MutationUpdateTeam from '../../../../graphQL/admin/updateTeam'
import MutationUpdateTeamMedia from '../../../../graphQL/admin/updateTeamMedia'
import QueryTeam from '../../../../graphQL/admin/listTeam'
import QueryGetTeam from '../../../../graphQL/admin/getTeam'
import QueryGetPlayerDetails from '../../../../graphQL/admin/getPlayer'

import './../../ui.style.scss'

const { TabPane } = Tabs

class EditTeamModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      customDetails: {}
    }
  }

  resetState = () => {
    const state = {
      customDetails: {}
    }
    this.setState({ ...state })
  }

  onEditTeam = (details) => {
    this.setState({ customDetails: details })
  }

  onEditTeamMedia = (mediaDetails) => {
    this.setState({ mediaDetails })
  }

  updateTeamMediaCallBack = (details) => {
    return new Promise(resolve => {
      this.updateTeamMedia(details, resolve)
    })
  }

  updateTeamMedia = async (details, resolve) => {
    const { onHistoryUpdate } = this.props
    this.props.updateTeamMedia(details).then(async () => {
      onHistoryUpdate()
      resolve(true)
    })
  }

  onTeamChange = async () => {
    const { customDetails, mediaDetails } = this.state
    const { selectedTeam, teamFilter, onHistoryUpdate } = this.props
    this.setState({ isLoading: true })
    if (mediaDetails) {
      const newDetails = {
        team: selectedTeam.team.externalId,
        tournamentCalendar: teamFilter.tournamentCalendarExternalId,
        logo: mediaDetails.logo ? mediaDetails.logo.id : null,
        awayJersey: mediaDetails.awayJersey ? mediaDetails.awayJersey.id : null,
        homeJersey: mediaDetails.homeJersey ? mediaDetails.homeJersey.id : null,
        customHomeVenue: mediaDetails.customHomeVenue ? mediaDetails.customHomeVenue : null
      }
      await this.updateTeamMediaCallBack(newDetails)
    }
    if (!_.isEmpty(customDetails)) {
      const newDetails = {
        id: customDetails.id,
        customName: customDetails.customName,
        trigramme: customDetails.trigramme,
        customOfficialName: customDetails.customOfficialName,
        customFounded: customDetails.customFounded,
        customCountry: customDetails.customCountry,
        customStatus: customDetails.customStatus,
        customShortName: customDetails.customShortName,
        customCity: customDetails.customCity,
        customAddressZip: customDetails.customAddressZip,
        customPostalAddress: customDetails.customPostalAddress,
        tags: customDetails.tags && customDetails.tags.length ? customDetails.tags.map(item => item.key) : []
      }
      this.props.updateTeam(newDetails).then(response => {
        this.props.handleCancel()
        onHistoryUpdate()
        this.setState({ isLoading: false })
      }, error => {
        this.props.handleCancel()
        utilityService.handleError(error)
        this.setState({ isLoading: false })
      })
    } else {
      this.props.handleCancel()
      this.setState({ isLoading: false })
    }
  }

  render () {
    const { visible, handleCancel, title, okText, selectedTeam, teamIsLoading, teamFilter } = this.props
    const { customDetails, isLoading, mediaDetails } = this.state
    let variables
    if (teamFilter && selectedTeam && selectedTeam.team) {
      variables = {
        id: selectedTeam.team.id,
        tournamentCalendar: teamFilter.tournamentCalendar
      }
    }
    const query = {
      readQuery: { query: QueryGetPlayerDetails, variables },
      queryName: 'getTeam'
    }
    return (

      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <Modal
            className='general-modal edit-team-modal'
            visible={visible}
            closable={false}
            title={title}
            onCancel={handleCancel}
            okText={okText}
            cancelText='Back'
            afterClose={this.resetState}
            width='1024px'
            centered
            destroyOnClose
            maskClosable={false}
            footer={<div className='modal-footer'>
              <p>
                {/* Last Updated:  {moment(new Date(selectedTeam.lastUpdated)).format('DD MMM, YYYY')} */}
                {!_.isEmpty(customDetails) || !_.isEmpty(mediaDetails) ? 'The changes made here will reflect in APIs and other modules of VCMS only after a re-sync of the respective competition is triggered from Opta Sync Settings page.'
                  : ''}</p>
              <div className='right-btn'>
                <Button key='back' onClick={handleCancel}> Back </Button>
                <LoadingButton type='primary' onClick={this.onTeamChange} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isLoading} isDisabled={(_.isEmpty(customDetails) && _.isEmpty(mediaDetails)) || isUpdateDisabled} />
              </div>
            </div>}
          >
            <div className='tab-details'>
              <Tabs>
                <TabPane tab='Original Data' key='1'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={teamIsLoading}>
                    {!teamIsLoading ? <TeamOriginalData selectedTeam={selectedTeam.team} logo={selectedTeam.logo} homeJersey={selectedTeam.homeJersey} awayJersey={selectedTeam.awayJersey} /> : null}
                  </Skeleton>
                </TabPane>

                <TabPane tab='Custom Data' key='2'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={teamIsLoading}>
                    {!teamIsLoading ? <TeamCustomData selectedTeam={selectedTeam.team} logo={selectedTeam.logo} homeJersey={selectedTeam.homeJersey} awayJersey={selectedTeam.awayJersey} onEditTeamMedia={this.onEditTeamMedia} onEditTeam={this.onEditTeam} project={project} /> : null}
                  </Skeleton>
                </TabPane>

                <TabPane tab='Season Data' key='3'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={teamIsLoading}>
                    {!teamIsLoading ? <TeamSeasonData selectedTeam={selectedTeam.team} venue={selectedTeam.customHomeVenue} logo={selectedTeam.logo} homeJersey={selectedTeam.homeJersey} awayJersey={selectedTeam.awayJersey} onEditTeamMedia={this.onEditTeamMedia} onEditTeam={this.onEditTeam} query={query} /> : null}
                  </Skeleton>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        }}
      </AppContext.Consumer>
    )
  }
}

EditTeamModal.propTypes = {
  /** visible action of EditTeamModal. */
  visible: PropTypes.bool,
  /** handleCancel action of EditTeamModal. */
  handleCancel: PropTypes.func
}

EditTeamModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationUpdateTeam,
    {
      options: (props) => ({
        update: (cache, { data: { updateTeam } }) => {
          if (props.teamFilterRequest) {
            const variables = props.teamFilterRequest
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryTeam, variables }))
            if (cacheData && cacheData.listTeams && cacheData.listTeams.items) {
              const index = cacheData.listTeams.items.findIndex(item => item.id === updateTeam.id)
              if (index > -1 && updateTeam.customName) {
                cacheData.listTeams.items[index].title = updateTeam.customName
              }
              if (index > -1 && updateTeam.trigramme) {
                cacheData.listTeams.items[index].code = updateTeam.trigramme
              }
            }
            cache.writeQuery({
              query: QueryTeam,
              data: cacheData,
              variables
            })
          }
          const { selectedTeam, teamFilter } = props
          if (teamFilter) {
            let variables
            if (teamFilter && selectedTeam && selectedTeam.team) {
              variables = {
                id: selectedTeam.team.id,
                tournamentCalendar: teamFilter.tournamentCalendar
              }
            }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetTeam, variables }))
            if (cacheData && cacheData.getTeam) {
              cacheData.getTeam.tags = updateTeam.tags
              cacheData.getTeam.customName = updateTeam.customName
              cacheData.getTeam.trigramme = updateTeam.trigramme
              cacheData.getTeam.customOfficialName = updateTeam.customOfficialName
              cacheData.getTeam.customFounded = updateTeam.customFounded
              cacheData.getTeam.customCountry = updateTeam.customCountry
              cacheData.getTeam.customShortName = updateTeam.customShortName
              cacheData.getTeam.customStatus = updateTeam.customStatus
              cacheData.getTeam.customCity = updateTeam.customCity
              cacheData.getTeam.customAddressZip = updateTeam.customAddressZip
              cacheData.getTeam.customPostalAddress = updateTeam.customPostalAddress
            }
            cache.writeQuery({
              query: QueryGetTeam,
              data: cacheData,
              variables
            })
          }
        }
      }),
      props: (props) => ({
        updateTeam: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateTeamMedia,
    {
      options: (props) => ({
        update: (cache, { data: { updateTeamTournament } }) => {
          const { selectedTeam, teamFilter } = props
          if (teamFilter) {
            let variables
            if (teamFilter && selectedTeam && selectedTeam.team) {
              variables = {
                id: selectedTeam.team.id,
                tournamentCalendar: teamFilter.tournamentCalendar
              }
            }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetTeam, variables }))
            if (cacheData && cacheData.getTeam) {
              cacheData.getTeam.logo = updateTeamTournament.logo
              cacheData.getTeam.awayJersey = updateTeamTournament.awayJersey
              cacheData.getTeam.homeJersey = updateTeamTournament.homeJersey
              cacheData.getTeam.customHomeVenue = updateTeamTournament.customHomeVenue
            }
            cache.writeQuery({
              query: QueryGetTeam,
              data: cacheData,
              variables
            })
          }
        }
      }),
      props: (props) => ({
        updateTeamMedia: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(EditTeamModal))
