import gql from 'graphql-tag'

export default gql(`
    mutation deleteSeasonEpisodes($input: [ID] $assetId: ID $project: String) {
        deleteSeasonEpisodes(
            input: $input
            assetId: $assetId
            project: $project
        )
    }`
)
