import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select } from 'antd'
import moment from 'moment'
import './../../ui.style.scss'

const Option = Select.Option

const getChildren = (items, displayParameter, keyParameter, selectedItems) => {
  if (keyParameter) { items = items.filter((newItem) => selectedItems.indexOf(newItem.key) === -1) }
  const keyVal = keyParameter || displayParameter
  return items.map((item) => <Option key={item[ keyVal ]}>{item[ displayParameter ]}{ item.labelType ? <span>{item.labelType === 'Date' ? moment(item.label).format('DD MMM, YYYY') : item.label}</span> : null}</Option>)
}

const getChildrenForApps = (items, displayParameter, keyParameter, selectedItems, searchText) => {
  if (keyParameter) { items = items.filter((newItem) => selectedItems.indexOf(newItem.id) === -1) }
  const keyVal = keyParameter || displayParameter
  if (searchText) {
    let options = items.filter((item) => item && item.name && ((item.name).toLowerCase()).includes(searchText.toLowerCase()))
    return (options || []).map((item) => <Option key={item[ keyVal ]}>{item[ displayParameter ]}{ item.labelType ? <span>{item.labelType === 'Date' ? moment(item.label).format('DD MMM, YYYY') : item.label}</span> : null}</Option>)
  } else {
    return items.map((item) => <Option key={item[ keyVal ]}>{item[ displayParameter ]}{ item.labelType ? <span>{item.labelType === 'Date' ? moment(item.label).format('DD MMM, YYYY') : item.label}</span> : null}</Option>)
  }
}

class TagsInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedItems: props.selectedItems && props.selectedItems.length ? props.selectedItems : [],
      autoFocus: false,
      searchVal: ''
      // tagList: props.tagList && props.tagList.length ? props.tagList : []
    }
  }

    componentDidMount = () => {
      if (!_.isEqual(this.props.selectedItems, this.state.selectedItems)) {
        this.setState({ selectedItems: this.props.selectedItems })
      }
    }

    componentDidUpdate = () => {
      if (!_.isEqual(this.props.selectedItems, this.state.selectedItems)) {
        this.setState({ selectedItems: this.props.selectedItems })
      }
    }

    // shouldComponentUpdate = (newProps) => {
    //   if ((newProps.tagList.length && !this.props.tagList.length) || !_.isEqual(this.state.selectedItems, newProps.selectedItems)) {
    //     return true
    //   }
    //   return false
    // }

    onChangeTag = (value) => {
      if (value.trim() === '') {
        return
      }
      this.lastSearchedValue = undefined
      let { selectedItems } = _.cloneDeep(this.state)
      selectedItems.push(this.props.containerClass === 'tags' ? value.toLowerCase() : value)
      const values = selectedItems
      this.setState({ selectedItems: values, autoFocus: !!(values && values.length), searchVal: '' }, () => {
        this.props.onChange(this.props.containerClass === 'tags' ? value.toLowerCase() : value)
        if (this.props.onSearch) { this.props.onSearch('') }
      })
    }

    onSearch = (value) => {
      this.lastSearchedValue = value
      if (this.props.onSearch) { this.props.onSearch(this.props.containerClass === 'tags' ? value.toLowerCase() : value) }
      this.setState({ searchVal: value })
    }

    onBlur = () => {
      this.setState({ autoFocus: false, searchVal: '' })
      if (this.props.onSearch) { this.props.onSearch('') }
    }

    onDeselect = (value) => {
      if (this.lastSearchedValue === value) {
        return
      }
      let { selectedItems } = _.cloneDeep(this.state)
      const { selectedDisplayItems, keyParameter } = this.props
      let deletedKey
      if (keyParameter) {
        let index = selectedDisplayItems.indexOf(value)
        if (index === -1) {
          index = selectedItems.indexOf(value)
        }
        selectedDisplayItems.splice(index, 1)
        deletedKey = selectedItems[index]
        selectedItems.splice(index, 1)
      } else { selectedItems.splice(selectedItems.indexOf(value), 1) }
      this.setState({ selectedItems, autoFocus: !!(selectedItems && selectedItems.length), searchVal: '' }, () => {
        if (keyParameter) { this.props.onChange(deletedKey, true) } else { this.props.onChange(value, true) }
        if (this.props.onSearch) { this.props.onSearch('') }
      })
    }

    render () {
      const {
        tagList,
        placeholder,
        parentCompoent,
        displayParameter,
        keyParameter,
        selectedDisplayItems,
        mode,
        className,
        containerClass,
        disabled,
        isDropdownInvisible,
        filterType,
        searchText
      } = this.props
      const { autoFocus, selectedItems, searchVal } = this.state
      const selectedDisplay = keyParameter ? selectedDisplayItems : selectedItems
      // const filteredTagLists = tagList.filter(item => !(selectedItems || []).includes(item[keyParameter || displayParameter]))
      const children = filterType === 'AppManager' ? getChildrenForApps(tagList, displayParameter, keyParameter, selectedItems, searchText) : getChildren(tagList, displayParameter, keyParameter, selectedItems)
      const isNotPresent = _.isEmpty((tagList || []).find(tag => tag[displayParameter] === searchVal))
      return (
        <Select
          key={JSON.stringify(selectedItems)}
          className={`input-tags ${containerClass}`}
          mode={isNotPresent ? mode : 'multiple'}
          dropdownClassName={`${className || ''}${(isDropdownInvisible && searchVal && isNotPresent && mode === 'tags') ? ' hide-manual-tag-first' : ''}`}
          defaultValue={selectedDisplay}
          placeholder={placeholder}
          onSelect={this.onChangeTag}
          getPopupContainer={() => document.getElementById(parentCompoent)}
          onSearch={this.onSearch}
          onBlur={this.onBlur}
          autoFocus={autoFocus}
          onDeselect={this.onDeselect}
          filterOption={false}
          disabled={disabled}
        >
          {children}
        </Select>
      )
    }

  // componentWillReceiveProps(nextProps){
  //   this.setState({
  //     tagList: nextProps.tagList
  //   })
  // }
}

TagsInput.propTypes = {
  /** Placeholder text of TagsInput. */
  placeholder: PropTypes.string,
  /** Parameter for display value */
  displayParameter: PropTypes.string,
  /**  Parameter for mapping key */
  keyParameter: PropTypes.string,
  /** parent container id name of TagsInput. */
  parentCompoent: PropTypes.string,
  /** Selected Items of TagsInput. */
  selectedItems: PropTypes.array,
  /** Option data of TagsInput. */
  tagList: PropTypes.array,
  /** Change action of TagsInput. */
  onChange: PropTypes.func,
  /** Mode of input */
  mode: PropTypes.string,
  /** Function invokes when user wants to search tag */
  onSearch: PropTypes.func,
  /** Selected Display values */
  selectedDisplayItems: PropTypes.array,
  /** Boolean for disable tag */
  disabled: PropTypes.bool
}

TagsInput.defaultProps = {
  selectedItems: [],
  placeholder: 'Select',
  parentCompoent: 'root',
  mode: 'tags'
}

export default TagsInput
