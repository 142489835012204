import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import TextAreaJsonArrayApp from '../dataEntry/inputs/TextAreaJsonArrayApp'

class JsonArrayViewModal extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    const { isVisible, key, title, placeholder, inputName, value, isRequired, isError, handleChange,
      isJsonArrayValid, jsonArrayInvalidEntries, currentId, isEditable, tooltipText, handleCancel, jsonConfig, handleJsonArrayChange } = this.props
    return (
      <Modal
        className='general-modal json-edit-modal'
        title={'JSON ARRAY'}
        maskClosable={false}
        visible={isVisible}
        closable={false}
        centered
        destroyOnClose
        width={'600px'}
        // zIndex={3000}
        footer={[
          <Button key='back' onClick={handleCancel} disabled={!isJsonArrayValid}>
              Close
          </Button>]}
      >
        <TextAreaJsonArrayApp
          key={key}
          title={title}
          placeholder={placeholder}
          inputName={inputName}
          value={value}
          isRequired={isRequired}
          isError={isError}
          handleChange={handleChange}
          isJsonArrayValid={isJsonArrayValid}
          jsonArrayInvalidEntries={jsonArrayInvalidEntries}
          currentId={currentId}
          isEditable={isEditable}
          tooltipText={tooltipText}
          handleJsonArrayChange={handleJsonArrayChange}
          jsonConfig={jsonConfig}
        />
      </Modal>
    )
  }
}

JsonArrayViewModal.propTypes = {
  /** function to handle Back in Modal */
  handleCancel: PropTypes.func,
  /** boolean toggle to show Modal */
  isVisible: PropTypes.bool
}

export default JsonArrayViewModal
