import React, { Component } from 'react'

import { Empty, Skeleton, Select } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AuthService from '../../services/AuthService'
import RoleAuthLogCell from './RoleAuthLogCell'

import { utilityService } from './../../services/UtilityService'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryFilterAuthLogAudits from './../../graphQL/audit/filterRoleAuthLogAudits'
import QueryAuthUserList from './../../graphQL/role/listRoleAuthUsers'

import PropTypes from 'prop-types'

const width = ['100px', '100%', '100%']

const { Option } = Select
class RoleAuthLogs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      historyList: [],
      isLoading: true
    }
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && (!this.lastSearched || this.lastSearched.searchString !== newProps.searchString || this.lastSearched.filter !== newProps.filterVal || this.lastSearched.sort !== newProps.sort)) {
        this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
        // this.replaceCacheSearchString(newProps.searchString)
        this.setState({ historyList: newProps.historyList, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
      } else if (!_.isEqual(newProps.historyList, this.props.historyList)) {
        this.setState({ historyList: newProps.historyList, isSearching: false, isPaginating: false, isLoading: false })
      }
      if (!newProps.isChanging && this.props.isChanging) {
        if (newProps.refetchHistoryList) {
          newProps.refetchHistoryList()
          setTimeout(newProps.refetchHistoryList, 1000)
        }
      }
    }

    componentDidMount = () => {
      this.setState({ historyList: this.props.historyList })
    }

    getUserDetails = async () => {
      const userDetails = await AuthService.getUserDetails()
      if (userDetails) {
        this.username = userDetails.name || userDetails.email
      }
    }

    onChangeFilter = (value) => {
      this.setState({ isSearching: true })
      if (this.props.changeFilter) {
        this.props.changeFilter(value)
      }
    }

    onChangeFilterString = value => {
      this.setState({ isSearching: true })
      if (this.props.changeFilterString) {
        this.props.changeFilterString(value)
      }
    }

    onSelectUser = (id) => {
      this.props.onSelectUser(id)
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.historyList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.gethistoryList(this.state.historyList.length))
    }

    render () {
      const { isLoading, isSearching, isPaginating, historyList } = this.state
      const { showFilter, groupList, userList } = this.props
      return (
        <div className={`history-list role-auth-log ${showFilter ? 'show-filter' : ''}`}>
          { showFilter ? <div className='filter'>
            <Select
              className='role-dropdown'
              showSearch
              style={{ width: 200 }}
              placeholder='Select user'
              onChange={this.onSelectUser}
              optionFilterProp='children'
              filterOption
              allowClear
            >
              {userList.filter(item => item).map((option, index) => <Option key={option.user || index} value={option.user}>{ option.user }</Option>)}
            </Select>
          </div> : null }

          <div className='role-auth-log-heading role-auth-log-general'>
            <div className='date'> Date </div>
            <div className='date'> User </div>
            <div className='date'> UserEvent </div>
            <div className='date'> IP </div>
            <div className='date'> User Agent / OS </div>
            <div className='details'> Error </div>

          </div>
          {(isLoading || isSearching || this.props.isLoading) && !isPaginating
            ? <div className={`table`}>{[...Array(7)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading key={i} />)}
            </div>
            : <div className='table'>
              { (!isLoading && !isSearching) || isPaginating ? <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMoreAsset}
                hasMore={this.props.totalCount > historyList.length}
                initialLoad={false}
                useWindow={false}
              >
                { historyList && historyList.length ? historyList.map((history, index) => {
                  return (<RoleAuthLogCell details={history} key={index} groupList={groupList} />)
                }) : (!isLoading && !isPaginating) ? <Empty /> : <div /> }
              </InfiniteScroll> : null }
              { isPaginating && this.props.totalCount > historyList.length ? [...Array(2)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading key={i} />) : null }
            </div>}
        </div>
      )
    }
}

RoleAuthLogs.propTypes = {
  /** Selected media details */
  mediaDetails: PropTypes.object
}

export default withApollo(compose(
  graphql(
    QueryFilterAuthLogAudits,
    {

      options: (props) => {
        const { searchString, selectedUser, project } = props
        let variables = utilityService.getRoleAuthLogFilter(searchString, selectedUser)
        variables.project = project
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        const historyList = data.getUserEvents ? data.getUserEvents.items : []
        return {
          historyList,
          isLoading: data.loading || !data.getUserEvents,
          totalCount: data.getUserEvents ? data.getUserEvents.totalCount : 0,
          gethistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.getUserEvents.items, ...fetchMoreResult.getUserEvents.items ]
                prev.getUserEvents.items = newList
                return prev
              }
            })
          },
          refetchHistoryList: () => {
            const { searchString, selectedUser, project } = props
            let variables = utilityService.getRoleAuthLogFilter(searchString, selectedUser)
            variables.project = project
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryAuthUserList,
    {
      options: ({ userSearch, project }) => {
        const variables = { search: userSearch, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        return {
          userList: data.listAuthLogUsers && data.listAuthLogUsers && data.listAuthLogUsers.length ? data.listAuthLogUsers : []
        }
      }
    }
  )

)(RoleAuthLogs))
