import React from 'react'
import Input from './../dataEntry/inputs/Input'
import PropTypes from 'prop-types'
import { Popconfirm } from 'antd'

import GroupDragAndDropIcon from './../general/icons/GroupDragAndDropIcon'
import CrossIcon from './../general/icons/CrossIcon'
import AttributeTypeDropDown from './../dataEntry/inputs/AttributeTypeDropDown'
import SaveButton from '../general/buttons/SaveButton'
import EditButton from '../general/buttons/EditButton'
import ConfirmModal from './../feedback/ConfirmModal'

import './../ui.style.scss'

const parentCompoent = 'partner-container'

class AttributeFieldCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // details: props.field,
      showWarning: false,
      isSaveVisible: false,
      isSaveLoading: false,
      confirmVisible: false,
      isEdited: false
    }
  }

  onCloseField = () => {
    this.showWarningMessage()
  }

  onLabelChange = (e) => {
    const { index } = this.props
    this.setState({ isEdited: true })
    this.props.handleLabelChange(e, index)
  }

  onNameChange = (e) => {
    const { index } = this.props
    this.setState({ isEdited: true })
    this.props.handleNameChange(e, index)
  }

  handleAttributeSelect = (attribute, index) => {
    this.setState({ isEdited: true })
    this.props.handleAttributeSelect(attribute, index)
  }

  showWarningMessage=() => {
    this.setState({ showWarning: true })
  }

  closeWarningMessage=() => {
    this.setState({ showWarning: false })
  }
  onNameBlur = (e) => {
    const { index } = this.props
    this.props.handleNameBlur(e, index)
  }

  onLabelBlur = (e) => {
    const { index } = this.props
    this.props.handleLabelBlur(e, index)
  }

  onSave = () => {
    this.setState({ confirmVisible: true })
  }

  confirmWarning = () => {
    const { field } = this.props
    this.props.onSaveAttribute(field)
    this.setState({ isSaveLoading: true, confirmVisible: false }, () => {
      setTimeout(() => {
        this.setState({ isSaveLoading: false, isSaveVisible: false, isEdited: false })
      }, 1200)
    })
  }

  cancelWarning = () => {
    const { field } = this.props
    this.props.onCancelAttribute(field)
    this.setState({ confirmVisible: false, isSaveVisible: false })
  }

  render () {
    const { index, attributes, handleAttributeSearch, attributeSearch, field, attributeData, isUpdateDisabled } = this.props
    const { showWarning, isSaveVisible, isSaveLoading, confirmVisible, isEdited } = this.state
    const duplicateArr = attributeData.filter(item => item.name === field.name && item.id !== field.id)
    const isDisabled = (field.id && field.id.length > 8 && !isSaveVisible) || isUpdateDisabled
    return (
<>
  <div className='attribute-field-cell attribute-field-general'>
    <GroupDragAndDropIcon />
    <div className='group' id='group'>
      <Input
        title='Details Name'
        placeholder='Insert Details Name'
        inputName='name'
        value={field.name}
        handleChange={this.onNameChange}
        onBlur={this.onNameBlur}
        readOnly={isDisabled}
      />
    </div>

    <div className='group' id='group'>
      <Input
        title='Display Name'
        placeholder='Insert Display Name'
        inputName='label'
        value={field.label}
        handleChange={this.onLabelChange}
        onBlur={this.onLabelBlur}
        readOnly={isDisabled}
      />
    </div>

    <div className='group' id='group'>
      <AttributeTypeDropDown
        parentCompoent={parentCompoent}
        onAttributeSelect={this.handleAttributeSelect}
        selectedAttribute={field.type}
        index={index}
        onSearch={handleAttributeSearch}
        attributes={attributes}
        attributeSearch={attributeSearch}
        isDisabled={isDisabled}
      />
    </div>
    {isDisabled ? <EditButton onClick={() => this.setState({ isSaveVisible: true })} /> : <SaveButton isDisabled={!(field.name && field.label && field.type && isEdited && !(duplicateArr && duplicateArr.length))} onClick={this.onSave} isLoading={isSaveLoading} />}
    <CrossIcon onClick={() => this.onCloseField()} />
    {showWarning ? <Popconfirm
      visible={showWarning}
      placement='left'
      title='The deleted attributes will be removed from the Client API only after editing those offers manually and saving with new offer attributes and values'
      okText='Yes'
      cancelText='No'
      onConfirm={() => {
        this.closeWarningMessage()
        this.props.onCloseField(field.id)
      }}
      onCancel={this.closeWarningMessage}
    /> : null}
    <ConfirmModal
      isVisible={confirmVisible}
      title={'Confirm'}
      message={'The updated attributes will be available along with linked offers in Client API only after editing those offers manually and saving with new offer attributes and values'}
      rightButtonText={'Confirm'}
      handleCancel={this.cancelWarning}
      handleSubmit={this.confirmWarning}
      isSubmitButtonDisabled={false} />

  </div>
      </>
    )
  }
}
AttributeFieldCell.propTypes = {
  /** data of particular field attributes */
  field: PropTypes.object,
  /** Index number of the field */
  index: PropTypes.number,
  /** function to handle attribute filed close */
  onCloseField: PropTypes.func,
  /** function to handleNameChange in attribueFieldCell */
  handleNameChange: PropTypes.func,
  /** function to handleLabelChange in attribueFieldCell */
  handleLabelChange: PropTypes.func,
  /** function to handle attributeType change in attribueFieldCell */
  handleAttributeSelect: PropTypes.func,
  /** data for attributeType dropdown */
  attributes: PropTypes.array,
  /** function to handle attributeName apicall in attribueFieldCell */
  handleNameBlur: PropTypes.func,
  /** function to handle attributeLabel apicall in attribueFieldCell */
  handleLabelBlur: PropTypes.func

}

export default AttributeFieldCell
