import React from 'react'
import moment from 'moment'
import { Empty } from 'antd'
import _, { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'

import { utilityService } from '../../../services/UtilityService'
import { generateCroppedThumbnail } from '../../../util/util'
import AssetListCell from './AssetListCell'
import BucketAssetSearchList from './BucketAssetSearchList'

const getSortedList = (data) => {
  return (cloneDeep(data) || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
}

const onChangeFilter = () => {

}

const BucketViewAppDetails = ({ item, project, ...props }) => {
  const status = utilityService.getPublishStatus(item.isPublished, item.publishStartDate, item.publishEndDate)
  let bucketItems = getSortedList(item.bucketItems || [])
  return (
    <div className='bucket-content-wrapper' key={item.key}>
      <div className='bucket-content-details'>
        <div className='bucket-right-info'>
          <p>{ status }</p>
          <p>{item.key}</p>
        </div>
        <div className='bucket-left-info'>
          <p>{item.name}</p>
          <p>{item.displayType && item.displayType.name}</p>

        </div>
      </div>
      {!_.isEmpty(bucketItems) ? (bucketItems || []).map(bucketItem => {
        let defaultMedia = ''
        if (bucketItem.type === 'ASSET' && bucketItem.asset) {
          defaultMedia = bucketItem.asset.defaultMedia
        }
        if (bucketItem.type === 'ASSET') {
          return (
            <AssetListCell
              project={project}
              assetId={bucketItem.asset.id}
              assetName={bucketItem.customTitle || bucketItem.asset.title}
              displayId={bucketItem.asset.shortId || bucketItem.asset.id}
              assetType={bucketItem.asset.type}
              status={bucketItem.asset.status}
              date={moment(new Date(bucketItem.asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
              imageUrl={bucketItem.asset && defaultMedia ? generateCroppedThumbnail(bucketItem.customAssetImage || bucketItem.customMedia || defaultMedia, 87, 48, '16:9') : ''}
              onSelect={() => { }}
              asset={bucketItem.asset} />
          )
        }

        if (bucketItem.type === 'FILTER') {
          return (
            <BucketAssetSearchList
              id={bucketItem.id}
              searchString={bucketItem.filterRule.searchKey}
              filter={bucketItem.filterRule.filters}
              onChangeFilter={onChangeFilter}
              readOnly
              isFilterOpen={onChangeFilter}
            />
          )
        }
      }) : <Empty /> }
    </div>
  )
}

BucketViewAppDetails.propTypes = {
  item: PropTypes.object,
  project: PropTypes.string
}

export default BucketViewAppDetails
