import gql from 'graphql-tag'

export default gql(`
    query listLanguage($search: SearchInput $limit: Int $offset: Int $project: String){
        listLanguage( 
            search: $search
            limit: $limit
            offset: $offset
            project: $project
        ) {
        items {
            id
            name
            source
            assetCount
        }
        totalCount
    }
}`)
