import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Upload, message } from 'antd'
//

import UploadQueue from '../dataDisplay/UploadQueue'
import DragDropUploadIcon from '../general/icons/DragDropUploadIcon'
import UploadQueueSystemTags from '../dataEntry/other/UploadQueueSystemTags'
import { utilityService } from '../../../services/UtilityService'
import './../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import QueryAllTags from './../../../graphQL/asset/getTags'
import MutationCreateTag from './../../../graphQL/asset/createTag'
// import QueryTagContent from './../../../graphQL/asset/getContentTags'
import QueryTagType from './../../../graphQL/admin/tagType/listTagType'
import { validateUploadedFile } from '../../../util/util'

const TabPane = Tabs.TabPane
const Dragger = Upload.Dragger

class UploadQueueModal extends React.Component {
  state = {
    fileList: [],
    selectedTags: [],
    tagList: [],
    isTagCreate: false,
    systemTags: []
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.isLocalFolderOpen) {
      this.uploadRef.click()
    }
    if (nextProps.systemTags) {
      const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: enabledTags })
    }
  }

  onReorder = (startIndex, endIndex) => {
    const { fileList } = this.state
    const result = Array.from(fileList)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    this.setState({ fileList: result })
    return result
  }

  handleDelete = (index, e) => {
    const { fileList } = this.state
    fileList.splice(index, 1)
    this.setState({ fileList })
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { selectedTags } = this.state
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'CONTENT_BANK', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags, isTagCreate: true })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            this.setState({ selectedTags, isTagCreate: false })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}content_bank`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags, isTagCreate: false })
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
        }
      }
    }
    this.setState({ selectedTags })
  }

  beforeUpload = (data, dataList) => {
    if (!data.size) {
      message.error(`${data.name} is an empty image`)
      return Upload.LIST_IGNORE
    }
    return validateUploadedFile(data).then((file) => {
      let { fileList } = this.state
      fileList = [ ...fileList, file ]
      this.setState({ fileList })
      return true
    }
    ).catch((file) => {
      message.error(`${data.name} is an invalid image`)
      return Upload.LIST_IGNORE
    })
  }

  handleOk = () => {
    const { fileList, selectedTags } = this.state
    this.props.uploadList(fileList, selectedTags.filter(item => item).map(tag => tag.key))
    this.setState({ fileList: [], selectedTags: [] })
  }

  handleCancel = () => {
    this.setState({ fileList: [], selectedTags: [] })
    this.props.handleCancel()
  }

  render () {
    const uploadProps = {
      multiple: true,
      beforeUpload: this.beforeUpload,
      customRequest: () => {}
    }
    const { fileList, selectedTags, isTagCreate, systemTags } = this.state
    const { visible, project } = this.props
    return (

      <Modal
        className='general-modal upload-modal'
        visible={visible}
        closable={false}
        title='UPLOAD CONTENT'
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okText='Upload Content'
        cancelText='Back'
        centered
        destroyOnClose
        okButtonProps={{ disabled: !fileList || !fileList.length || isTagCreate }}
      >
        <div id='upload-modal'>
          <Tabs defaultActiveKey='1' >
            <TabPane tab='Upload Queue' key='1'>
              <Dragger className={!fileList.length ? 'full' : ''} {...uploadProps} accept='.jpg,.png,.jpeg,.webp,.gif' listType='picture-card'>
                <DragDropUploadIcon />
                <p className='ant-upload-text' ref={(ref) => { this.uploadRef = ref }}>Click or drag file to this area to upload</p>
              </Dragger>
              { fileList.length ? <UploadQueue uploadingFiles={fileList}
                onReorder={this.onReorder}
                handleDelete={this.handleDelete}
              /> : null}

            </TabPane>
            <TabPane tab='Tags' key='2'>
              <UploadQueueSystemTags
                handleTagChange={this.handleTagChange}
                systemTags={systemTags}
                selectedTags={selectedTags}
                project={project}
              />
            </TabPane>
            {/* <TabPane tab='Remote Folder' key='2'>Content of Tab Pane 2</TabPane>  */}
          </Tabs>
        </div>
      </Modal>

    )
  }
}

UploadQueueModal.propTypes = {
  /** uploadList upload function. */
  uploadList: PropTypes.func,
  /**  */
  createTag: PropTypes.func,
  /** Upload mutliple file status of UploadQueueModal. */
  multiple: PropTypes.bool,
  /** upload file status of UploadQueueModal. */
  upload: PropTypes.bool,
  /** visible file status of UploadQueueModal. */
  visible: PropTypes.bool,
  /** cancel action of UploadQueueCell. */
  handleCancel: PropTypes.func,
  /**  */
  tagsList: PropTypes.array
}

UploadQueueModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(MutationCreateTag, {
      // TODO: The code for MutationCreateTag could be consolidated to keep the code DRY
      options: ({ project }) => {
        return {
          update: (cache, { data: { createTag } }) => {
            const filter = { type: { match: 'MANUAL' }, module: { exact: 'CONTENT_BANK' } }
            const cacheData = cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },
      props: props => ({
        createTag: contentObj => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
    /* graphql(
      QueryTagContent,
      {
        options: ({ project }) => {
          return {
            variables: { project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
          }
        }
      }
    ) */
    graphql(
      QueryTagType,
      {
        options: ({ project }) => {
          return {
            variables: { filter: null, limit: 500, project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    )
  )(UploadQueueModal)
)
