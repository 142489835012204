import React, { useState, useEffect, useRef } from 'react'
import bitmovinPlayer from '../bitmovin/bitmovin'
const VolumeSlider = props => {
  const { autohideSlider, isMuted } = props
  const [volume, setVolume] = useState(() => bitmovinPlayer.getVolume())
  const volumeSliderElem = useRef()

  useEffect(
    () => {
      if (bitmovinPlayer.isMuted()) {
        setVolume(0)
        updateSliderColor(0)
      } else {
        setVolume(bitmovinPlayer.getVolume())
        updateSliderColor(bitmovinPlayer.getVolume())
      }
    },
    [isMuted, bitmovinPlayer.isMuted()]
  )

  const updateSliderColor = (vl) => {
    if (volumeSliderElem.current) { volumeSliderElem.current.style.background = `linear-gradient(90deg, white ${vl}%,${vl}%, #828d98)` }
  }

  const handleOnSliderSeek = event => {
    autohideSlider(false)
    setVolume(setBitmovinVolume(event.target.valueAsNumber))
    updateSliderColor(event.target.valueAsNumber)
  }

  const setBitmovinVolume = num => {
    if (num <= 1) {
      bitmovinPlayer.mutePlayer()
    } else {
      if (bitmovinPlayer.isMuted) {
        bitmovinPlayer.unMutePlayer()
      }
    }
    bitmovinPlayer.setVolume(num)
    return bitmovinPlayer.getVolume()
  }

  return (
    <input
      onMouseEnter={() => autohideSlider(false)}
      onMouseLeave={() => autohideSlider(true)}
      type='range'
      min={0}
      max={100}
      step={1}
      value={volume}
      orient='vertical'
      ref={volumeSliderElem}
      className='volumeSlider'
      onChange={handleOnSliderSeek}
    />
  )
}
export default VolumeSlider
