import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Skeleton, message } from 'antd'

import ImageContent from './ImageContent'
import Button from './../../general/buttons/Button'
import EditImageModal from './EditImageModal'
import AddImageModal from './AddImageModal'
import AddImageFromLocalFolderModal from './AddImageFromLocalFolderModal'
import { utilityService } from '../../../../services/UtilityService'
import assetDefaultValues from '../../../../constants/asset-default'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetAssetDetails from './../../../../graphQL/asset/getAssetMediaDetails'
import MutationUpdateArticle from './../../../../graphQL/asset/updateAssetMediaDetails'
import MutationDeleteAssetImage from './../../../../graphQL/asset/deleteAssetImages'
import MutationUpdateAssetImage from './../../../../graphQL/asset/updateAssetImage'
import MutationAddImageToAsset from './../../../../graphQL/asset/addImageToAsset'
import MutationCreateMediaLinks from './../../../../graphQL/content/batchLinkMedia'
import MutationRearrangeContent from './../../../../graphQL/asset/batchUpdateContentItem'
import MutationCreateContent from './../../../../graphQL/asset/createContentItem'
import MutationCreateMedia from './../../../../graphQL/content/createMedia'
import QueryUploadImage from './../../../../graphQL/asset/getUploadUrl'
import ApiService from '../../../../services/ApiService'

import './../../ui.style.scss'

let primaryImageType

class BucketEditDisplayImageModal extends React.Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    let details
    if (this.props.details) {
      let media = [...(this.props.details.media || [])].filter(item => item)
      details = { ...props.details, media }
    }
    this.addedMedia = []
    this.state = {
      details,
      showEditImage: false,
      currentSelectedImageId: '',
      showAddImage: false,
      imageId: this.props.displayMediaId,
      isImageEditted: false,
      isImageDeleted: false,
      addImageLoading: false,
      isLoading: !details,
      currentSelectedImageDetails: undefined,
      loading: false,
      changedDisplay: false,
      isLocalFolderOpen: false,
      uploadingFiles: [],
      showAddImageFromLocal: false
    }
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.displayMediaId !== this.state.imageId && newProps.displayMediaId !== this.props.displayMediaId) {
        this.setState({
          imageId: newProps.displayMediaId
        })
      }
      if (!this.state.showAddImage && newProps.details && !_.isEqual(newProps.details, this.props.details)) {
        const media = [...(newProps.details.media || [])].filter(item => item)
        this.setState({ details: { ...newProps.details, media }, isLoading: false })
      }
      const { uploadData, isUploadError } = newProps
      if (isUploadError && !this.props.isUploadError) {
        this.setState({ uploadingFiles: [] })
      }
      if (uploadData && !_.isEqual(uploadData, this.props.uploadData)) {
        if (uploadData.length > -1) {
          uploadData.map((item) => {
            this.uploadImageApi(_.cloneDeep(item))
          })
        }
      }
    }

    onImageAdd = async (newImages, isClearLocal, category) => {
      if (this.state.addImageLoading && this.state.addImageLoading === true) {
        message.warn('Please wait for seleted image to be uploaded')
      } else {
        this.setState({ addImageLoading: true })
        let { details, imageId } = this.state
        const { images } = details
        // const imageLinkArray = [...newImages].map(item => {
        //   return {
        //     mediaId: item.id,
        //     assetId: this.props.details.id
        //   }
        // })
        // this.props.addMediaLinks(imageLinkArray).then(async ({ data }) => {
        //   const newAddedImages = [...newImages].map(image => {
        //     image.id = data.batchCreateAssetMediaLink.find(item => item.id === image.id).id
        //     return image
        //   })
        const media = (images || []).filter(item => item.type.id === primaryImageType)
        newImages = newImages.filter(item => {
          const tempIndex = media.findIndex(innerItem => innerItem.mediaId === item.id)
          return tempIndex === -1
        })

        const imageContents = (newImages || []).map(image => {
          const newImage = {
            mediaId: image.id,
            assetId: this.props.details.id,
            imageTypeId: primaryImageType,
            isDefault: media.length ? 'false' : 'true',
            cropPosition: image.cropPos,
            assetType: this.props.details.type
          }
          if (category) { newImage.categoryId = category }
          return newImage
        })
        this.props.addAssetImages(imageContents).then(async ({ data }) => {
        // const newAddedImages = [...newImages].map(image => {
        //   image.id = data.batchCreateAssetMediaLink.find(item => item.id === image.id).id
        //   return image
        // })
          const { addAssetImages } = data
          const updatedMedia = [...(images || []), ...addAssetImages]

          if (!media.length) {
            details.defaultMedia = addAssetImages[0]
            imageId = addAssetImages[0].id
            if (details.type === 'ARTICLE') {
              const defaultImageContentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
              if (defaultImageContentIndex > -1) {
                details.contents[defaultImageContentIndex].media = addAssetImages[0]
                this.updatedContent = details.contents[defaultImageContentIndex].id
              } else {
                const defaultImageContent = await this.onAddDefaultImageContentCallback(details)
                details.contents.splice(0, 0, defaultImageContent)
              }
            }
          }
          details.images = updatedMedia
          if (isClearLocal) { this.updateLocalUploadImage() }
          this.setState({ details, imageId, showAddImage: false, addImageLoading: false }, () => {
            this.imageContainerRef.scrollTop = this.imageContainerRef.scrollHeight + 126
          })
        }, error => {
          this.setState({ addImageLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    onAddImage = async (newImages, isClearLocal) => {
      let { details, imageId } = this.state
      const { media } = details
      // const imageLinkArray = [...newImages].map(item => {
      //   return {
      //     mediaId: item.id,
      //     assetId: this.props.details.id
      //   }
      // })
      // this.props.addMediaLinks(imageLinkArray).then(async ({ data }) => {

      // }, error => {
      //   utilityService.handleError(error)
      // })
      // const newAddedImages = [...newImages].map(image => {
      //   image.id = data.batchCreateAssetMediaLink.find(item => item.id === image.id).id
      //   return image
      // })
      const updatedMedia = [...(media || []), ...newImages]
      const images = media.filter(item => item.type === 'IMAGE')
      if (!images.length) {
        details.defaultMedia = newImages[0]
        imageId = newImages[0].id
        if (details.type === 'ARTICLE') {
          const defaultImageContentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
          if (defaultImageContentIndex > -1) {
            details.contents[defaultImageContentIndex].media = newImages[0]
            this.updatedContent = details.contents[defaultImageContentIndex].id
          } else {
            const defaultImageContent = await this.onAddDefaultImageContentCallback(details)
            details.contents.splice(0, 0, defaultImageContent)
          }
        }
      }
      details.media = updatedMedia
      if (isClearLocal) { this.updateLocalUploadImage() }
      this.setState({ details, imageId, showAddImage: false, isImageEditted: true }, () => {
        this.imageContainerRef.scrollTop = this.imageContainerRef.scrollHeight + 126
      })
    }

    showAddImageLocal = (showAddImageFromLocal) => {
      if (showAddImageFromLocal) {
        this.setState({ isLocalFolderOpen: false, showAddImageFromLocal }, () => {
          setTimeout(() => {
            this.setState({ isLocalFolderOpen: true }, () => {
              this.setState({ isLocalFolderOpen: false })
            })
          }, 100)
        })
      } else {
        this.setState({ showAddImageFromLocal })
      }
    }

    handleImageUpload = (fileData) => {
      this.setState({ showAddImageFromLocal: false })
      this.onAddImageFromLocal(fileData, true)
    }

    onAddImageFromLocal = (data, isImage) => {
      if (isImage) {
        const { uploadingFiles } = this.state
        const dataList = [...data.files]
        const tagList = [...data.tags]
        for (let i = 0; i < dataList.length; i++) {
          const contentType = dataList[i].type
          const fileName = dataList[i].name
          const fileSize = dataList[i].size
          if (contentType && fileName) {
            const newFile = {
              fileName,
              fileSize,
              aspectRatio: [],
              contentType,
              percentage: 0,
              id: fileName,
              file: dataList[i],
              tagList
            }

            uploadingFiles.splice(0, 0, newFile)
          }
        }
        this.setState({ uploadingFiles }, () => {
          this.imageContainerRef.scrollTop = this.imageContainerRef.scrollHeight + 126
        })
        // this.setState({ uploadingFiles, isEdited: true })
        // this.changeEditStatus(true)
      }
      this.props.handleFileUpload(data, isImage)
    }

    uploadImageApi = (uploadData) => {
      const { fileName, contentType, key, uploadUrl } = uploadData
      const successCallBack = () => {
        const { uploadingFiles } = this.state
        const index = uploadingFiles.findIndex(image => image.id === key)
        const tags = (uploadingFiles[index].tagList || []).map(tag => tag.key)
        this.props.createMedia(key, fileName, 'IMAGE', tags).then(response => {
          // message.success(`Created media ${fileName} successfully`)
          // this.props.onSelectImage(this.state.imageList, response.data.createMedia.id)
          // const { media } = details
          // const updatedMedia = [response.data.createMedia, ...(media || [])]
          // details.media = updatedMedia
          this.addedMedia.push(response.data.createMedia)
          if (this.addedMedia.length === uploadingFiles.length) {
            this.onImageAdd(this.addedMedia, true, null, key)
            this.addedMedia = []
          }

          // this.updateCache('media', updatedMedia)
        }, error => {
          utilityService.handleError(error)
          const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
          if (selectedImageIndex > -1) {
            uploadingFiles.splice(selectedImageIndex, 1)
          }
          this.setState({ uploadingFiles })
        })
      }

      const failureCallBack = () => {
        const { uploadingFiles } = this.state
        message.warning(`Failed to create media ${fileName}`)
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        if (selectedImageIndex > -1) {
          uploadingFiles.splice(selectedImageIndex, 1)
        }
        this.setState({ uploadingFiles })
      }

      const progressCallBack = progress => {
        const { uploadingFiles } = this.state
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        if (selectedImageIndex > -1) {
          uploadingFiles[selectedImageIndex].percentage = progress
        }
        this.setState({ uploadingFiles })
      }
      const { uploadingFiles } = this.state
      const selectedImageIndex = uploadingFiles.findIndex(image => image.id === fileName)
      if (selectedImageIndex > -1) {
        uploadingFiles[selectedImageIndex].id = key
        uploadingFiles[selectedImageIndex].isUploading = true
        const notUploadedFile = uploadingFiles.find(item => !item.isUploading)
        if (!notUploadedFile) {
          this.props.clearFileName()
        }
        this.setState({ uploadingFiles })
        ApiService('PUT', uploadUrl, uploadingFiles[selectedImageIndex].file, { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
      }
    }

    updateLocalUploadImage = () => {
      // const { uploadingFiles } = this.state
      // const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
      //   if (selectedImageIndex > -1) {
      //     uploadingFiles.splice(selectedImageIndex, 1)
      //   }
      this.setState({ uploadingFiles: [] })
    }

    updateModifiedContentsCallback = () => {
      return new Promise(resolve => {
        this.updateModifiedContents(resolve)
      })
    }

    updateModifiedContents = async (resolve) => {
      if (!this.updatedContent) {
        resolve(true)
        return
      }
      const { contents } = this.state.details
      const modifiedContents = contents.filter(item => this.updatedContent === item.id).map(item => {
        const returnItem = { ...item }
        if ((item.type === 'IMAGE' || item.type === 'DEFAULT_IMAGE') && item.media && item.media.id) {
          returnItem.media = item.media.id
        }
        returnItem.assetId = this.state.details.id
        delete returnItem['__typename']
        return returnItem
      })
      this.props.updateAssetContents(modifiedContents).then(async () => {
        this.updatedContents = null
        resolve(true)
      })
    }

    onAddDefaultImageContentCallback = (details) => {
      return new Promise(resolve => {
        this.onAddDefaultImageContent(details, resolve)
      })
    }

    onAddDefaultImageContent = async (details, resolve) => {
      const currentDefaultMedia = details.defaultMedia
      const { contents } = details
      const newContent = { ...assetDefaultValues.defaultContentData['DEFAULT_IMAGE'] }
      newContent.media = currentDefaultMedia ? currentDefaultMedia.id : null
      newContent.position = contents.length ? contents[ 0 ].position - 1 : 1
      const newContentRequest = {
        position: newContent.position,
        media: newContent.media || null,
        value: newContent.value ? newContent.value : null,
        type: 'DEFAULT_IMAGE',
        assetId: details.id
      }
      this.props.createContentItem(newContentRequest).then(async ({ data }) => {
        resolve(data.createContentItem)
      }, async (error) => {
        resolve(error)
        utilityService.handleError(error)
      })
    }

    saveAsset = async (mediaDetails) => {
      const { details } = this.state
      this.setState({ loading: true })
      const media = details.media.map(item => item.id)
      const defaultMedia = details.defaultMedia ? details.defaultMedia.mediaId : null
      const contents = (details.contents || []).map(item => item.id)
      const request = { id: details.id, media, defaultMedia, contents }
      if (this.updatedContent) {
        await this.updateModifiedContentsCallback()
      }
      if (mediaDetails) {
        const variables = {
          id: mediaDetails.id,
          assetId: mediaDetails.assetId,
          imageTypeId: primaryImageType,
          isDefault: 'true',
          mediaId: mediaDetails.mediaId
        }
        this.props.updateAssetImage(variables).then(() => {
          this.props.updateAsset(request).then(() => {
            this.setState({ loading: false })
            if (mediaDetails && (mediaDetails.id === details.defaultMedia.id)) {
              this.props.handleSubmit(undefined, mediaDetails)
            } else if (!mediaDetails) {
              this.props.handleSubmit(undefined, mediaDetails)
            } else {
              this.props.handleSubmit(mediaDetails, mediaDetails)
            }
          }, error => {
            utilityService.handleError(error)
          })
        }, error => {
          utilityService.handleError(error)
        })
      } else {
        this.props.updateAsset(request).then(() => {
          this.setState({ loading: false })
          if (mediaDetails && (mediaDetails.id === details.defaultMedia.id)) {
            this.props.handleSubmit(undefined, mediaDetails)
          } else if (!mediaDetails) {
            this.props.handleSubmit(undefined, mediaDetails)
          } else {
            this.props.handleSubmit(mediaDetails, mediaDetails)
          }
        }, error => {
          utilityService.handleError(error)
        })
      }
    }

    showImageEditor = (imageUrl, imageId, imageDetails) => {
      this.setState({ showEditImage: true, currentSelectedImageId: imageId, currentSelectedImageDetails: imageDetails })
    }

    hideImageEditor = (updatedMedia) => {
      let { details } = this.state
      let { images } = details
      if (images && images.length && updatedMedia) {
        const index = images.findIndex(item => item.id === updatedMedia.id)
        if (index > -1) {
          images[index] = updatedMedia
          details.images = images
          this.setState({ showEditImage: false, currentSelectedImageId: '', currentSelectedImageDetails: undefined, details })
        }
      } else {
        this.setState({ showEditImage: false, currentSelectedImageId: '', currentSelectedImageDetails: undefined })
      }
    }

    showAddImages = popupId => {
      this.addImageType = popupId
      this.setState({ showAddImage: true })
    }

    hideAddImage = () => {
      this.setState({ showAddImage: false })
    }

    onCloseImage = id => {
      const { details } = this.state
      let { imageId } = this.state
      let { media } = details
      const index = media.findIndex(item => item.id === id)
      const deletedMedia = media.splice(index, 1)
      const tempMedia = media.filter(item => item.type === 'IMAGE')
      if (tempMedia.length && details.defaultMedia.id === deletedMedia[0].id) {
        details.defaultMedia = tempMedia[0]
        imageId = tempMedia[0].id
        if (details.type === 'ARTICLE') {
          // modify the default image content
          const defaultImageContentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
          if (defaultImageContentIndex > -1) {
            details.contents[defaultImageContentIndex].media = tempMedia[0]
            this.updatedContent = details.contents[defaultImageContentIndex].id
          }
        }
      } else if (!tempMedia.length) {
        details.defaultMedia = null
        imageId = null
        if (details.type === 'ARTICLE') {
          // remove the default image content
          const defaultImageContentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
          if (defaultImageContentIndex > -1) {
            details.contents.splice(defaultImageContentIndex, 1)
          }
        }
      } else {
        imageId = tempMedia[0].id
      }
      this.setState({ details, isImageEditted: true, imageId })
    }

    onDeleteImage = deletedImageId => {
      const { details } = this.state
      let { imageId, isImageEditted, changedDisplay } = this.state
      const { images } = details
      const index = (images || []).findIndex(item => item.id === deletedImageId)
      const deletedMedia = images.splice(index, 1)
      const variables = {
        deletedId: [deletedMedia[0].id],
        assetId: details.id
      }
      this.props.deleteAssetImages(variables).then(() => {}, (error) => {
        utilityService.handleError(error)
      })
      details.images = images
      const tempImages = (images || []).filter(item => item.type && item.type.id === primaryImageType)
      if (tempImages.length && details.defaultMedia.id === deletedMedia[0].id) {
        details.defaultMedia = tempImages[0]
        imageId = tempImages[0].id
        isImageEditted = true
        this.setState({ details }, () => {
          this.makeDisplayImage(imageId)
        })
        if (details.type === 'ARTICLE') {
          // modify the default image content
          const defaultImageContentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
          if (defaultImageContentIndex > -1) {
            details.contents[defaultImageContentIndex].media = tempImages[0]
            this.updatedContent = details.contents[defaultImageContentIndex].id
          }
        }
      } else if (!tempImages.length) {
        details.defaultMedia = null
        imageId = null
        isImageEditted = true
        if (details.type === 'ARTICLE') {
          // remove the default image content
          const defaultImageContentIndex = (details.contents || []).findIndex(item => item.type === 'DEFAULT_IMAGE')
          if (defaultImageContentIndex > -1) {
            details.contents.splice(defaultImageContentIndex, 1)
          }
        }
      } else {
        imageId = tempImages[0].id
        changedDisplay = true
        // isImageEditted = true
      }
      this.setState({ details, imageId, isImageEditted, changedDisplay })
    }

    makeDisplayImage = (displayMediaId) => {
      const imageDetails = cloneDeep(this.state.details)
      let images = cloneDeep(imageDetails && imageDetails.images ? imageDetails.images : [])
      // let images = details && details.images ? details.images.filter(item => item && item.type.id === 'primary') : []
      const displayImageIndex = images.findIndex(item => item.id === displayMediaId)
      images[displayImageIndex].isDefault = 'true'
      if (displayImageIndex > 0) {
        const displayImage = images[displayImageIndex]
        images.splice(displayImageIndex, 1)
        images.splice(0, 0, displayImage)
      }
      imageDetails.images = images
      this.setState({ imageId: displayMediaId, changedDisplay: true, details: imageDetails })
    }

    handleCancel = () => {
      const { uploadingFiles } = this.state
      if (uploadingFiles && uploadingFiles.length) {
        message.error('Uploading media')
        return
      }
      this.props.handleCancel()
    }

    handleSubmit = () => {
      const { details, isImageEditted, uploadingFiles } = this.state
      const { images } = details
      const { imageId } = this.state
      const defaultMedia = (images || []).filter(item => item.isDefault === 'true' && item.type.id === primaryImageType)
      if (uploadingFiles && uploadingFiles.length) {
        message.error('Uploading media')
        return
      }
      const index = images.findIndex(image => image.id === imageId)
      let mediaDetails = images[index]
      if (isImageEditted) {
        this.saveAsset(mediaDetails)
      } else {
        if (defaultMedia.id === mediaDetails.id) {
          mediaDetails = undefined
        }
        this.props.handleSubmit(mediaDetails)
      }
    }

    render () {
      const { visible, title, isUpdateDisabledContent, isCreateDisabledContent, project } = this.props
      const { showEditImage, currentSelectedImageId, showAddImage, imageId, details, isLoading, currentSelectedImageDetails, loading, isImageDeleted,
        changedDisplay, isImageEditted, showAddImageFromLocal, isLocalFolderOpen, uploadingFiles, addImageLoading } = this.state
      const images = details && details.images ? details.images.filter(item => item && item.type.id === primaryImageType) : []
      // const displayImageIndex = images.findIndex(item => item.id === imageId)
      // if (displayImageIndex > 0) {
      //   const displayImage = images[displayImageIndex]
      //   images.splice(displayImageIndex, 1)
      //   images.splice(0, 0, displayImage)
      // }
      return (

        <Modal
          className='general-modal bucket-edit-display-image-modal'
          visible={visible}
          closable={false}
          title={title}
          okButtonProps={{ disabled: !(changedDisplay || isImageDeleted || isImageEditted), loading: loading }}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          okText='Save'
          cancelText='Cancel'
          width='900px'
          maskClosable={false}
          centered
          destroyOnClose
        >
          <div className='image-details' id='bucket-edit-display-image-modal' ref={(node) => { this.imageContainerRef = node }}>
            <div className='image-content-skeleton'>
              <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 4, width: ['100%', '100%', '120px', '120px'] }} loading={isLoading} />
              <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 4, width: ['100%', '100%', '120px', '120px'] }} loading={isLoading} />
            </div>
            {images && images.length ? images.map((image, index) => (
              <ImageContent
                key={index}
                isBucketEdit
                makeDisplayImage={this.makeDisplayImage}
                mediaId={image.id}
                imageDetails={image}
                id={image.id}
                isDisplayImage={imageId ? (imageId === image.id || imageId === image.mediaId) : image.isDefault === 'true'}
                name={image.name}
                imageUrl={image.url}
                onCloseContent={() => this.onDeleteImage(image.id)}
                parentCompoent='bucket-edit-display-image-modal'
                showEditImage={this.showImageEditor}
                aspectRatio={image.aspectRatio}
                isUpdateImageDisabled={isUpdateDisabledContent}
              />
            )) : undefined }
            {(uploadingFiles && uploadingFiles.length) ? uploadingFiles.map((uploadinImage) => {
              return <ImageContent
                isUploading
                key={utilityService.makeRandomString(7)}
                mediaId={uploadinImage.id}
                id={uploadinImage.id}
                name={uploadinImage.fileName}
                isAssetList
                parentCompoent={'bucket-edit-display-image-modal'}
                showEditImage={false}
                isUpdateImageDisabled={false}
                imageDetails={uploadinImage}
              />
            }) : null}

          </div>
          <div className='button-container'>
            <span>Add Images from:</span>
            <div style={{ width: '150px' }}>
              <Button
                buttonText={'Content Bank'}
                color='red'
                onClick={() => this.showAddImages('images')}
                style={{ width: '150px' }}
                isDisabled={(uploadingFiles && uploadingFiles.length)}
              />
            </div>
            <div style={{ width: '150px' }}>
              <Button
                buttonText={'Local Drive'}
                color='red'
                onClick={() => this.showAddImageLocal(true)}
                style={{ width: '150px' }}
                isDisabled={(uploadingFiles && uploadingFiles.length) || isCreateDisabledContent}
              />
            </div>
          </div>

          { details
            ? <EditImageModal
              assetId={details.id}
              isVisible={showEditImage}
              hideImageEditor={this.hideImageEditor}
              imageId={currentSelectedImageId}
              mediaDetails={currentSelectedImageDetails}
              type='IMAGE' isFromAsset
              project={project} /> : null}

          <AddImageModal
            isMultiSelect
            isVisible={showAddImage}
            type={this.addImageType}
            handleCancel={this.hideAddImage}
            handleSubmit={this.onImageAdd}
            isSubmitButtonDisabled
            isCancelButtonDisabled={false}
            isLoading={addImageLoading}
            currentSelection={this.state.details ? [...(this.state.details.images || []).filter(image => image.type.id === primaryImageType)] : []} mediaType='IMAGE'
            project={project} />
          <AddImageFromLocalFolderModal
            isVisible={showAddImageFromLocal}
            handleCancel={() => this.showAddImageLocal(false)}
            handleSubmit={this.handleImageUpload}
            isSubmitButtonDisabled={isCreateDisabledContent}
            isCancelButtonDisabled={false}
            isLoading={false}
            isLocalFolderOpen={isLocalFolderOpen}
            onChangeModal={this.showAddImageFromLocal}
            project={project} />
        </Modal>
      )
    }
}

BucketEditDisplayImageModal.propTypes = {
  /** visible action of BucketEditDisplayImageModal. */
  visible: PropTypes.bool,
  /** title  of BucketEditDisplayImageModal. */
  title: PropTypes.string,
  /** handleCancel action of BucketEditDisplayImageModal. */
  handleCancel: PropTypes.func,
  /** display image change action callback of BucketEditDisplayImageModal. */
  handleSubmit: PropTypes.func
}

BucketEditDisplayImageModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryGetAssetDetails,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {

          details: props.data.getAsset,
          assetIsLoading: props.data.loading
        }
      }
    }),
  graphql(
    MutationCreateMediaLinks,
    {
      props: (props) => ({
        addMediaLinks: (imageObjArray) => {
          return props.mutate({
            variables: { imageObjArray }
          })
        }
      })
    }),
  graphql(
    MutationUpdateArticle,
    {
      props: (props) => ({
        updateAsset: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    MutationRearrangeContent,
    {
      props: (props) => ({
        updateAssetContents: (contentsArray) => {
          return props.mutate({
            variables: { contents: contentsArray, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationCreateContent,
    {
      props: (props) => ({
        createContentItem: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryUploadImage,
    {
      options: ({ files, project }) => {
        const fileList = (files.files || []).map(item => {
          return {
            fileName: item.name,
            contentType: item.type,
            type: 'IMAGE'
          }
        })
        return {
          fetchPolicy: 'network-only',
          variables: { files: fileList, project }
        }
      },
      skip: ({ files }) => {
        return (!files || !files.files || !files.files.length)
      },
      props: (props) => {
        const { data, ownProps } = props
        const uploadData = !data.loading && data.getUploadUrl ? data.getUploadUrl.map((item, index) => {
          const currentItem = ownProps.files.files[index]
          return { ...item,
            fileName: currentItem.name,
            contentType: currentItem.type,
            aspectRatio: [],
            fileSize: currentItem.fileSize,
            type: 'IMAGE' }
        }) : []
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          ownProps.clearFileName()
          utilityService.handleError(data.error)
        }
        return { uploadData: _.map(uploadData, _.clone), isUploadError }
      }
    }
  ),
  graphql(
    MutationCreateMedia,
    {
      options: ({ searchString, filter, mediaType, project }) => {
        return { update: (cache, { data: { createMedia } }) => {
          // const variables = utilityService.getFormattedMediaFilter(searchString, filter, mediaType)
          // const cacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterMedia, variables }))
          // if (cacheData && cacheData.listMedia && cacheData.listMedia.items) { cacheData.listMedia.items.splice(0, 0, createMedia) }
          // cache.writeQuery({
          //   query: QueryFilterMedia,
          //   data: cacheData,
          //   variables
          // })
        }
        }
      },
      props: (props) => ({
        createMedia: (key, name, type, tags) => {
          return props.mutate({
            variables: { key, name, type, tags, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAssetImage,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { updateAssetImage } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
          if (cacheData.getAsset) {
            cacheData.getAsset.images = (cacheData.getAsset.images || []).map(image => {
              if (image.id === updateAssetImage.id) { image = updateAssetImage } else { image.isDefault = 'false' }
              return image
            })
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId }
          })
        }
        }
      },
      props: (props) => ({
        updateAssetImage: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteAssetImage,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { deleteAssetImages } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))

          if (cacheData.getAsset) {
            cacheData.getAsset.images = (cacheData.getAsset.images || []).filter(image => !(deleteAssetImages || []).includes(image.id))
            if (cacheData.getAsset.defaultMedia && (deleteAssetImages || []).includes(cacheData.getAsset.defaultMedia.id)) {
              const tempImages = (cacheData.getAsset.images || []).filter(item => item.type && item.type.id === primaryImageType)
              if (tempImages.length) {
                const defaultMedia = tempImages[0]
                cacheData.getAsset.defaultMedia = {
                  id: defaultMedia.id,
                  fileName: defaultMedia.fileName,
                  url: defaultMedia.url ? defaultMedia.url : defaultMedia.fileName,
                  settings: defaultMedia.settings,
                  aspectRatio: defaultMedia.aspectRatio,
                  __typename: defaultMedia.__typename
                }
              } else { cacheData.getAsset.defaultMedia = null }
            }
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId }
          })
        }
        }
      },
      props: (props) => ({
        deleteAssetImages: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationAddImageToAsset,
    {
      options: ({ assetId, project }) => {
        return { update: (cache, { data: { addAssetImages } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables: { id: assetId, project } }))
          if (cacheData.getAsset) {
            if (cacheData.getAsset.images) {
              cacheData.getAsset.images = addAssetImages && addAssetImages.length ? [...cacheData.getAsset.images, ...addAssetImages] : []
            } else { cacheData.getAsset.images = addAssetImages && addAssetImages.length ? [...addAssetImages] : [] }
            const defaultMedia = (addAssetImages || []).find(item => item.isDefault === 'true')
            if (defaultMedia) {
              cacheData.getAsset.defaultMedia = {
                id: defaultMedia.id,
                fileName: defaultMedia.fileName,
                url: defaultMedia.url ? defaultMedia.url : defaultMedia.fileName,
                settings: defaultMedia.settings,
                aspectRatio: defaultMedia.aspectRatio,
                __typename: defaultMedia.__typename
              }
            }
          }
          cache.writeQuery({
            query: QueryGetAssetDetails,
            data: cacheData,
            variables: { id: assetId }
          })
        }
        }
      },
      props: (props) => ({
        addAssetImages: (imageContent) => {
          return props.mutate({
            variables: { imageContent, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(withApollo(BucketEditDisplayImageModal)))
