import gql from 'graphql-tag'

export default gql(`
    mutation updateMatchStatusModuleConfig($id: ID!, $value: [String], $module: String $project: String ) {
        updateModuleConfig(
            input: {
                id: $id
                value: $value
                module: $module
                project: $project
            },
        ) {
            id
            value
            module
        }
    }`
)
