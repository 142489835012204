import gql from 'graphql-tag'

export default gql(`
    mutation adminUpdateEmail ( $oldEmail: String! ,$newEmail: String!) {
        adminUpdateEmail (
            oldEmail: $oldEmail
            newEmail: $newEmail
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
