import gql from 'graphql-tag'

export default gql(`
query listGroups($filter: GroupFilterInput $limit: Int $project: String) {
    listGroups(
      limit: $limit,
      project: $project,
      filter: $filter
    ) {
      items {
        id
        key
        name
        type
        isCritical
        assignedUsers
        app{
          id
          name
          url
          isCritical
        }
      }
    }
}`)
