import * as React from 'react'
import PropTypes from 'prop-types'
import Switch from '../dataEntry/inputs/Switch'

class AssetTypeOptionalList extends React.Component {
    renderOptionListBody = () => {
      const { onChange, isConfig, details } = this.props
      if (isConfig) {
        return <React.Fragment>
          <div className='option-item'>
            <span>Auto Trigger</span>
            <Switch isChecked={details.isLveToVodSupported} onChange={(status) => onChange('isLveToVodSupported', status)} />
          </div>
          <div className='option-item'>
            <span>Match Manager Link</span>
            <Switch isChecked={details.isMatchRelated} onChange={(status) => onChange('isMatchRelated', status)} />
          </div>
          <div className='option-item'>
            <span>Auto Publish</span>
            <Switch isChecked={details.autoPublish} onChange={(status) => onChange('autoPublish', status)} />
          </div>
        </React.Fragment>
      } else {
        return <React.Fragment>
          <div className='option-item'>
            <span>Author Field</span>
            <Switch isChecked={details.authorSection} onChange={(status) => onChange('authorSection', status)} />
          </div>
          <div className='option-item'>
            <span>Content Section</span>
            <Switch isChecked={details.contentSection} onChange={(status) => onChange('contentSection', status)} />
          </div>
          <div className='option-item'>
            <span>Video Section</span>
            <Switch isChecked={details.videoSection} onChange={(status) => onChange('videoSection', status)} />
          </div>
          <div className='option-item'>
            <span>Program Section</span>
            <Switch isChecked={details.programSection} onChange={(status) => onChange('programSection', status)} />
          </div>
          <div className='option-item'>
            <span>Match Field</span>
            <Switch isChecked={details.matchSection} onChange={(status) => onChange('matchSection', status)} />
          </div>
          <div className='option-item'>
            <span>Credits Section</span>
            <Switch isChecked={details.creditSection} onChange={(status) => onChange('creditSection', status)} />
          </div>
          <div className='option-item'>
            <span>Parental Rating Section</span>
            <Switch isChecked={details.parentalRatingSection} onChange={(status) => onChange('parentalRatingSection', status)} />
          </div>
        </React.Fragment>
      }
    }

    render () {
      return <div className='optional-list-container'>
        <div className='optional-list-title'>
          <span>{'Field & Section'}</span>
          <span>{'Enabled'}</span>
        </div>
        <div className='optional-list-body'>
          {this.renderOptionListBody()}
        </div>
      </div>
    }
}

AssetTypeOptionalList.propTypes = {
  /** List of options */
  optionList: PropTypes.array,
  /** Function to be called on change of active state */
  onChange: PropTypes.func
}

export default AssetTypeOptionalList
