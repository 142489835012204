import gql from 'graphql-tag'

export default gql(`
mutation updateMetaFieldConfig($id : ID!, $name: String!, $default: String,, $project: String!) {
    updateMetaFieldConfig(
        input: {
            id: $id
            name: $name
            default: $default
        }
        project : $project
    ) {
        id
        name
        type
        default
        section
    }
}`
)
