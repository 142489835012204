import gql from 'graphql-tag'

export default gql(`
    mutation($title: String! $project: String) {
        createMetaSection(
            input: {
                title:$title
                project:$project
            }
        ) {
            id
            title
            usedCount
        }
    }`
)
