import React from 'react'
import { Empty, Modal, Button } from 'antd'
//

import '../../ui.style.scss'
import DeleteIcon from '../../general/icons/DeleteIcon'
import SaveIcon from '../../general/icons/SaveIcon'
import PopoverConfirm from '../../feedback/PopoverConfirm'

const getNewIPsItem = (ipItem, onDeleteIp, onSaveIp, onChangeNewIp) => {
  return <div className='ip-item new' key={'temp-ip-key'}>
    <input onChange={onChangeNewIp} />
    {ipItem.isLoading ? <Button loading className='save-loading' />
      : <SaveIcon color={ipItem.ip ? '#343a40' : '#AFAFAF'} onClick={ipItem.ip ? () => onSaveIp(ipItem) : () => {}} />}
    <div className='separator' />
    <DeleteIcon color={ipItem.isLoading ? '#AFAFAF' : '#343a40'} onClick={ipItem.isLoading ? () => {} : () => onDeleteIp(ipItem.ip)} />
  </div>
}

const getIPsItem = (ipItem, onDeleteIp) => {
  return <div className='ip-item' key={ipItem.ip}>
    <span>{ipItem.ip}</span>
    <PopoverConfirm isThinIcon parentCompoent={'ip-whitelist-body'} confirm={() => onDeleteIp(ipItem.ip)} />
  </div>
}

const getIPsList = (ipList, onDeleteIp, onSaveIp, onChangeNewIp) => {
  return ipList.map(item => {
    return item.isNew ? getNewIPsItem(item, onDeleteIp, onSaveIp, onChangeNewIp) : getIPsItem(item, onDeleteIp)
  })
}

const IpWhitelistModal = ({ isVisible, onCancel, ipList, onAddNewIP, onDeleteIp, onSaveIp, onChangeNewIp, isUpdateDisabled, ...props }) => (
  <Modal
    className={`confirm-modal ip-whitelist-modal`}
    visible={isVisible}
    cancelText={'Close'}
    onCancel={onCancel}
    closable={false}
    centered
    title={'IPs WHITELIST'}
    width={540}
    maskClosable={false}
    destroyOnClose
  >
    <div className='ip-whitelist-container'>
      <div className='whitelist-header'>
        <span>IP Address</span>
      </div>
      <div className='whitelist-body'>
        {ipList && ipList.length ? <div className='ip-whiteList' id='ip-whitelist-body'>
          {getIPsList(ipList, onDeleteIp, onSaveIp, onChangeNewIp)}
        </div>
          : <Empty />}
      </div>
      <div className='whitelist-footer'>
        <Button onClick={onAddNewIP} disabled={(ipList.filter(item => item.isNew).length) || isUpdateDisabled}>Add IP Address</Button>
      </div>
    </div>
  </Modal>
)

export default IpWhitelistModal
