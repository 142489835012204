import gql from 'graphql-tag'

export default gql(`
    mutation createSeasonFromSeries($id: ID! $project: String) {
        createSeasonFromSeries(
            id: $id
            project: $project
        ) {
            id
            assetId
            isArchived
            shortId
            externalId
            title
            type
            updatedAt
            publishStartDate
            publishEndDate
            status
            isFree
            seasonNumber
            episodeNumber
            series{
                id
                assetId
                title
            }
            season{
                id
                 assetId
                title
            }
            defaultMedia {
                id
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                    url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2 
                    y2
                }
            }
        }
    }`
)
