import React from 'react'
import PropTypes from 'prop-types'

const RemoveItemIcon = ({ width, height, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h15v15H0z' />
      <path fill='#343A40' d='M7.488 7.936l-6.96 6.972A.307.307 0 0 1 .31 15a.31.31 0 0 1-.22-.527l6.962-6.973L.093.527A.308.308 0 0 1 .529.09l6.958 6.972L14.447.09a.308.308 0 0 1 .435.437L7.923 7.499l6.96 6.973a.31.31 0 0 1-.217.527.307.307 0 0 1-.22-.09L7.489 7.935z' />
    </g>
  </svg>
)

RemoveItemIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onClick: PropTypes.func
}

RemoveItemIcon.defaultProps = {
  width: 15,
  height: 15
}

export default RemoveItemIcon
