import gql from 'graphql-tag'

export default gql(`
query getBatchBucketGroupItems($input: [ID]){
    getBatchBucketGroupItems(
        input: $input
    ){
        id
        bucket{
            key
        }
    }
}`)
