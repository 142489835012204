import gql from 'graphql-tag'

export default gql(`
    query getCsvUploadUrl($input: [UploadCsvInput], $project:String,  $gracenoteSync: Boolean,){
        getCsvUploadUrl(input : $input project: $project gracenoteSync: $gracenoteSync) {
            bucket
            key
            region
            url
            uploadUrl
        }
    }`
)
