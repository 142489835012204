import gql from 'graphql-tag'

export default gql`
  query  get2FAConfig{
    get2FAConfig{
        id
        isEnabled
        name
        value
    }
  }
`
