import gql from 'graphql-tag'

export default gql(`
query listWidgets ($filter: AppWidgetFilterInput, $project: String, $limit: Int, $offset: Int, $order: String , $orderBy: String, $search: SearchInput) {
    listWidgets(
      filter: $filter,
      limit: $limit,
      project: $project
      offset: $offset
      order: $order
      orderBy: $orderBy
      search: $search
    ) {
      items {
        id
        key
        title
        description
        isActive
        app{
            id
            name
            url
        }
        fields{
            id
            name
            type
            isRequired
            useAsTitle
            position
            isUpdatable
        }
        fieldValues {
            configField {
              id
              type
              isRequired
              useAsTitle
              name
              position
              isUpdatable
            }
            value
            project
            bucket {
              project
              id
              isArchived
              name
              isPublished
              publishStartDate
              publishEndDate
              updatedAt
              updatedBy
              key
              displayName
              displayType{
                name
                id
              }
              bucketItems {
                id
                position
                type
                asset{
                  id
                  title
                  type
                  shortId
                  externalId
                  updatedAt
                  status
                  seasonNumber
                  episodeNumber
                  series{
                    id
                    assetId
                    title
                  }
                  season{
                      id
                      assetId
                      title
                  }
                  defaultMedia {
                      id
                      url
                      fileName
                      aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                          url
                        }
                      }
                      settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                      }
                  }
              }
              filterRule{
                searchKey
                filters{
                    name
                    fieldName
                    type
                    value
                    displayValue
                    displayName
                    isRequired
                 }
               }
              }
            }
            image {
              id
              fileName
              createdAt
              name
              type
              fileSize
              duration
              thumbnails
              tags{
                  key
                  name
                  type
              }
              category{
                  id
                  label
                  type
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
              }
              dimensions{
                  x1
                  x2
                  y1
                  y2
              }
              aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                  key
                  width
                  height
                  url
                  }
              }
            }
        }
        widgetPreview {
            id
            fileName
            createdAt
            name
            type
            fileSize
            duration
            thumbnails
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions{
                x1
                x2
                y1
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
        }
        project
        createdAt
        updatedAt
        updatedBy
        createdBy
        usageCount
       }
       totalCount
    }
}`)
