import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Divider, Select } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'

import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import './../../ui.style.scss'

import QueryAllCategory from '../../../../graphQL/asset/getContentCategory'
import CreateCategoryModal from '../other/CreateCategoryModal'

const Option = Select.Option

class CategoryDropDown extends React.Component {
    state = {
      value: undefined,
      selectedType: undefined,
      showAddCategoryModal: false,
      isSearchPresent: false
    }

    onOptionSelect = (id) => {
      const selectedCategory = this.props.assetCategory.find(item => item.id === id)
      this.props.onCategorySelect(selectedCategory)
    }

    showAddCategoryModal = () => {
      this.setState({ showAddCategoryModal: true })
    }

    hideAddCategoryModal = () => {
      this.setState({ showAddCategoryModal: false })
    }

    handleAddCategory = (newCategory) => {
      this.setState({ showAddCategoryModal: false })
      this.props.onCategorySelect(newCategory)
    }

    onCategorySearch = (value) => {
      this.setState({ isSearchPresent: true })
      if (this.props.onSearch) { this.props.onSearch(value) }
    }

    onDropdownVisibleChange = (isOpen) => {
      if (!isOpen) { this.setState({ isSearchPresent: false }) }
    }

    render () {
      const { showAddCategoryModal, isSearchPresent } = this.state
      const { assetCategory, selectedCategory, parentCompoent, project } = this.props
      let optionComponents = assetCategory.map((option, index) => (
        <Option key={option.id} value={option.id}> { option.name }
        </Option>
      ))
      if (selectedCategory && optionComponents && optionComponents.length && !isSearchPresent) {
        const selectedOption = optionComponents.find((item) => item.key === selectedCategory.id)
        optionComponents = optionComponents.filter((item) => item.key !== selectedCategory.id)
        optionComponents.splice(0, 0, selectedOption)
      }

      const dropdownRender = menu => (
        <div>
          <div onMouseDown={(e) => {
            e.stopPropagation()
            this.showAddCategoryModal()
          }} className='custom-dropdown-container'>
            <div style={{ position: 'absolute', left: 0, marginLeft: '10px' }}>Add Category</div>
            <div style={{ position: 'absolute', right: 0, marginRight: '10px' }} > <Icon type='plus' /> </div>
          </div>
          <Divider dashed style={{ margin: 0 }} />
          { menu }
        </div>
      )
      // const tree = (
      //   assetCategory.map(assetType => {
      //     return <TreeNode className='category-drop-down-option' value={assetType.category} title={assetType.category} key={assetType.key} selectable={false} >
      //       {assetType.type.map(assets => {
      //         return <TreeNode value={assets.name} title={assets.name} key={assets.key} />
      //       })}
      //     </TreeNode>
      //   })
      // )

      return (

        <div className='category-drop-down'>

          {/* <TreeSelect
            showSearch
            // style={{ width: 300 }}
            value={this.state.value}
            getPopupContainer={() => document.getElementById(parentCompoent)}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder='Please select'
            allowClear
            treeDefaultExpandAll
            onChange={this.onChange}
          > {tree} </TreeSelect> */}

          <Select dropdownRender={dropdownRender}
            allowClear
            dropdownClassName='category-dropdown-popup'

            suffixIcon={<ExpandedIcon />}
            clearIcon={<CrossIcon />}
            value={selectedCategory ? selectedCategory.name : undefined}
            placeholder='Select Category' showSearch
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onChange={this.onOptionSelect} onSearch={this.onCategorySearch}
            filterOption={false}
            onDropdownVisibleChange={this.onDropdownVisibleChange}
          >
            { optionComponents }
          </Select>

          <CreateCategoryModal
            isVisible={showAddCategoryModal}
            handleCancel={this.hideAddCategoryModal}
            handleSubmit={this.handleAddCategory}
            title='NEW CATEGORY'
            module='ASSET_MANAGER'
            project={project} />
        </div>
      )
    }
}
CategoryDropDown.propTypes = {
  /** Option selection action of Dropdown. */
  onCategorySelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func,
  /** Selected value of Dropdown. */
  selectedCategory: PropTypes.object,
  /** assetCategory of CategoryDropDown. */
  assetCategory: PropTypes.array
}

CategoryDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default withApollo(compose(
  graphql(
    QueryAllCategory,
    {
      options: ({ categorySearch, project }) => {
        // const filter = categorySearch ? { name: { match: categorySearch } } : null
        return {
          variables: { searchKey: categorySearch, limit: 30, offset: 0, project }
        }
      },
      props: (props) => {
        return {
          assetCategory: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  )
)(CategoryDropDown))
