import gql from 'graphql-tag'

export default gql(`
mutation createGenre($name: String!, $project: String) {
    createGenre(
        input: {
            name: $name
        },
        project: $project
    ) {
        id
        name
        source
        assetCount
    }
}`
)
