import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import './../../ui.style.scss'

const Option = Select.Option

const customFilter = (input, option, options) => {
  const currentOption = options.find(item => item === option.props.value)
  const returnValue = currentOption.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

const AssetCategoryTypeDropDown = ({ displayParam, valueParam, onOptionSelect, selectedValue, options, placeHolder, parent, className, allowClear, disabled, ...props }) => {
  const filteredOptions = (options || []).filter(item => item !== selectedValue)
  return (
    <Select
      className={className ? 'drop-down ' + className : 'drop-down'}
      value={selectedValue || undefined}
      placeholder={placeHolder}
      getPopupContainer={() => document.getElementById(parent)}
      onChange={onOptionSelect ? onOptionSelect.bind(this) : () => { }}
      filterOption={(input, option) => customFilter(input, option, options)}
      allowClear={selectedValue ? allowClear : false}
      suffixIcon={<ExpandedIcon />}
      clearIcon={<CrossIcon />}
      disabled={disabled}
      showSearch
    >
      {filteredOptions.map((option, index) => <Option key={option || index} value={option}>{option}</Option>)}
    </Select>
  )
}

AssetCategoryTypeDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** Selected value of Dropdown. */
  selectedValue: PropTypes.string,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Parent container for the dropdown. */
  parent: PropTypes.string.isRequired,
  /** Class name */
  className: PropTypes.string
}

AssetCategoryTypeDropDown.defaultProps = {
  parent: 'root',
  disabled: false
}

export default AssetCategoryTypeDropDown
