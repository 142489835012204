import React from 'react'
import PropTypes from 'prop-types'
import SaveIcon from './../icons/SaveIcon'
import { Icon, Spin } from 'antd'
import './../../ui.style.scss'

const antIcon = <Icon type='loading' style={{ fontSize: 19 }} spin />

const SaveButton = ({ isDisabled, isLoading, onClick, ...props }) => (
  <div className={`save-button ${isDisabled || isLoading ? 'disabled' : ''}`} onClick={onClick}>
    {isLoading ? <Spin indicator={antIcon} /> : <SaveIcon />}
  </div>
)

SaveButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

SaveButton.defaultProps = {
  isDisabled: false
}

export default SaveButton
