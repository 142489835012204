import gql from 'graphql-tag'

export default gql(`
query {
    listStreamLevels {
        items{
            id
            name
            version
            fields{
              name
              type
              value
              oldValue
              displayName
              displayValue
              isRequired
              fieldName
              maxValue
              isPosChange
            }
            group
        }
    }
}`)
