import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import ContentTitle from '../../../components/ui/dataDisplay/ContentTitle'
import CustomPlaceholder from '../../../components/ui/dataDisplay/CustomPlaceholder'
import DescriptionContent from '../../../components/ui/dataDisplay/DescriptionContent'
import SummaryContent from '../../../components/ui/dataDisplay/SummaryContent'
import SocialMediaShare from '../../../components/ui/dataDisplay/SocialMediaShare'
import AuthorContent from '../../../components/ui/dataDisplay/AuthorContent'
import SocialMediaContent from '../../../components/ui/dataEntry/other/SocialMediaContent'
import EmbedContent from '../../../components/ui/dataEntry/other/EmbedContent'
import ImageContent from '../../../components/ui/dataEntry/other/ImageContent'
import Button from '../../../components/ui/general/buttons/Button'
import RichText from '../../../components/ui/dataEntry/other/RichText'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import { generateCroppedThumbnail } from './../../../util/util'
import assetDefaultValues from '../../../constants/asset-default'

import { GlobalHotKeys } from 'react-hotkeys'

const keyMap = {
  shortcutContentImage: 'c+i',
  shortcutContentText: 'c+t',
  shortcutContentSocial: 'c+s'
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'white' : 'white',
  margin: '0 0 20px 0',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

const parentCompoent = 'asset-container'

class ArticleContents extends Component {
  constructor (props) {
    super(props)
    this.state = {
      placeholderList: this.props.placeholderList || [],
      items: [],
      shouldShowAddContentPopup: false
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

    UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
      this.setState({ items: this.getContentComponents([ ...newProps.content ], newProps.author, newProps.defaultMedia, newProps.isUpdateImageDisabled) })
      if (newProps.placeholderList) {
        this.setState({
          placeholderList: newProps.placeholderList
        })
      }
    }

    handleClickOutside = (e) => {
      if (this.state.shouldShowAddContentPopup && !(e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight)) {
        setTimeout(() => this.setState({ shouldShowAddContentPopup: false }), 200)
      }
    }

    getContentComponents = (content, author, defaultMedia, isUpdateImageDisabled) => {
      const { onSocialShareChange, onRichTextChange, onEmbedScriptChange, onEmbedScriptCaptionChange, onCloseContent, showEditImage, onImageCaptionChange, onInputFieldFocus, onInputFieldBlur, onContentPlaceholderChange } = this.props
      const { placeholderList } = this.state
      return content.map((item, index) => {
        if (item.type === 'TITLE') {
          return <ContentTitle key={item.id} onCloseContent={() => onCloseContent(item.id)} title={item.value || 'Placeholder Title'} />
        } else if (item.type === 'placeholder') {
          return <CustomPlaceholder key={item.id} onCloseContent={() => onCloseContent(item.id)} placeholder={item.value} />
        } else if (item.type === 'SHARE') {
          return <SocialMediaShare key={item.id} onCloseContent={() => onCloseContent(item.id)} />
        } else if (item.type === 'DESCRIPTION') {
          return <DescriptionContent key={item.id} onCloseContent={() => onCloseContent(item.id)} summary={item.value || 'Short Description'} />
        } else if (item.type === 'AUTHOR') {
          return <AuthorContent key={item.id} onCloseContent={() => onCloseContent(item.id)} authorImage={author && author.media && author.media.length && author.media[ 0 ] ? generateCroppedThumbnail(author.media[0], 200, 200, '1:1') : undefined} authorName={author ? author.name : 'Author'} />
        } else if (item.type === 'SOCIAL') {
          return <SocialMediaContent onInputFieldFocus={onInputFieldFocus} onInputFieldBlur={onInputFieldBlur} key={item.id} id={item.id} onCloseContent={() => onCloseContent(item.id)} content={item} onSocialShareChange={onSocialShareChange} />
        } else if (item.type === 'EMBED') {
          return <EmbedContent key={item.id} id={item.id} onInputFieldFocus={onInputFieldFocus} onInputFieldBlur={onInputFieldBlur} onCloseContent={() => onCloseContent(item.id)} content={item} onEmbedScriptChange={onEmbedScriptChange} onEmbedScriptCaptionChange={onEmbedScriptCaptionChange} caption={item.caption} />
        } else if (item.type === 'IMAGE') {
          const aspectRatio = item.media ? item.media.aspectRatio : []
          return item.media ? <ImageContent key={item.id} imageDetails={item.media} id={item.id} mediaId={item.media.id} onFocus={onInputFieldFocus} onBlur={onInputFieldBlur} showEditImage={(a, b, c) => showEditImage(a, b, c, item.id, true)} aspectRatio={aspectRatio} onImageCaptionChange={onImageCaptionChange} parentCompoent={parentCompoent} name={item.media.name} caption={item.value} imageUrl={item.media ? item.media.url : undefined} onCloseContent={() => onCloseContent(item.id)} /> : null
        } else if (item.type === 'DEFAULT_IMAGE') {
          const aspectRatio = item.media ? item.media.aspectRatio : []
          return item.media ? <ImageContent key={item.id} isUpdateImageDisabled={isUpdateImageDisabled} imageDetails={item.media} id={item.id} mediaId={item.media.id} isDefaultImage showEditImage={(a, b, c, d) => showEditImage(a, b, c, d, true)} aspectRatio={aspectRatio} onImageCaptionChange={onImageCaptionChange} parentCompoent={parentCompoent} name={item.media.name} caption={item.value} imageUrl={item.media ? item.media.url : undefined} onCloseContent={() => onCloseContent(item.id)} /> : null
        } else if (item.type === 'RICHTEXT') {
          return <RichText key={item.id} id={item.id} text={item.value} onInputFieldFocus={onInputFieldFocus} onInputFieldBlur={onInputFieldBlur} onRichTextChange={value => onRichTextChange(item.id, value)} onCloseContent={() => onCloseContent(item.id)} showCloseIcon />
        } else {
          return <SummaryContent key={item.id} onCloseContent={() => onCloseContent(item.id)} summary={item.value || 'Placeholder content'} isNotSummary item={item} assetId={this.props.assetId} placeholderList={placeholderList} onContentPlaceholderChange={onContentPlaceholderChange} />
        }
      })
    }

    onDragEnd = (result) => {
      if (result.destination) {
        this.props.onOrderChange(result.source.index, result.destination.index)
      }
    }

    changeAddContentPopup = (value) => {
      this.setState({ shouldShowAddContentPopup: value })
    }

    onContentTypeSelection = (item) => {
      const { onAddContent } = this.props
      this.setState({ shouldShowAddContentPopup: false })
      onAddContent(item)
    }

    renderPopOverButton = (isUpdateDisable) => {
      const { placeholderList, content, defaultMedia } = this.props
      const tempPlaceHolder = (placeholderList || []).map(placeHolder => ({ type: placeHolder.id, name: placeHolder.name, metaFields: placeHolder.metaFields }))
      let contentTypes = [ ...assetDefaultValues.contentTypes, ...tempPlaceHolder ];
      // if (this.props.content.findIndex(content => content.type === 'TITLE') > -1) {
      //   const index = contentTypes.findIndex(content => content.type === 'TITLE')
      //   contentTypes.splice(index, 1)
      // }
      // if (this.props.content.findIndex(content => content.type === 'DESCRIPTION') > -1) {
      //   const index = contentTypes.findIndex(content => content.type === 'DESCRIPTION')
      //   contentTypes.splice(index, 1)
      // }
      // if (this.props.content.findIndex(content => content.type === 'AUTHOR') > -1) {
      //   const index = contentTypes.findIndex(content => content.type === 'AUTHOR')
      //   contentTypes.splice(index, 1)
      // }
      // if (this.props.content.findIndex(content => content.type === 'DEFAULT_IMAGE') > -1 || !this.props.defaultMedia) {
      //   const index = contentTypes.findIndex(content => content.type === 'DEFAULT_IMAGE')
      //   contentTypes.splice(index, 1)
      // }
      // if (this.props.content.findIndex(content => content.type === 'SHARE') > -1) {
      //   const index = contentTypes.findIndex(content => content.type === 'SHARE')ß
      //   contentTypes.splice(index, 1)
      // }
      (content || []).forEach(contentItem => {
        const index = contentTypes.findIndex(content => (content.type === contentItem.type) && (content.type === 'DEFAULT_IMAGE' || content.type === 'AUTHOR' || content.type === 'TITLE' || content.type === 'DESCRIPTION'))
        if (index > -1) {
          contentTypes.splice(index, 1)
        }
      })
      const index = contentTypes.findIndex(contentItem => contentItem.type === 'DEFAULT_IMAGE')
      if (index > -1 && !defaultMedia) {
        contentTypes.splice(index, 1)
      }
      const { shouldShowAddContentPopup } = this.state
      const button = <Button buttonText='Add Content' color='red' onClick={() => this.changeAddContentPopup(!shouldShowAddContentPopup)} isDisabled={isUpdateDisable} />
      return (
        <PopoverButton button={button} displayParam='name' parentCompoent={parentCompoent} contents={contentTypes} onContentClick={this.onContentTypeSelection} isVisible={shouldShowAddContentPopup} />
      )
    }

    keyHandlers = {
      shortcutContentImage: () => {
        this.props.onAddContent({ type: 'IMAGE' })
      },
      shortcutContentText: () => {
        this.props.onAddContent({ type: 'RICHTEXT' })
      },
      shortcutContentSocial: () => {
        this.props.onAddContent({ type: 'SOCIAL' })
      }
    }

    render () {
      const { isUpdateDisable } = this.props
      return (
        <React.Fragment>
          <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
          <DragDropContext onDragEnd={this.onDragEnd} >
            <Droppable droppableId='droppable'>
              { (droppableProvided, droppableSnapshot) => (
                <div className='article-contents'
                  ref={droppableProvided.innerRef}
                  style={getListStyle(droppableSnapshot.isDraggingOver)}
                >
                  { this.state.items.map((item, index) => (
                    <Draggable key={index} draggableId={index + 1} index={index} isDragDisabled={isUpdateDisable}>
                      { (provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          { item }
                        </div>
                      ) }
                    </Draggable>
                  )) }
                </div>
              ) }
            </Droppable>
          </DragDropContext>
          <div className='add-content' style={{ width: '150px', marginBottom: '20px', marginLeft: '20px' }}>
            { this.renderPopOverButton(isUpdateDisable) }
          </div>
        </React.Fragment>
      )
    }
}
ArticleContents.propTypes = {
  /** default media details for author component. */
  defaultMedia: PropTypes.object,
  /** author details for author component. */
  author: PropTypes.object,
  /** content details array of ArticleContents. */
  content: PropTypes.array,
  /** content add action of ArticleContents. */
  onAddContent: PropTypes.func,
  /** SocialShare Content change action of ArticleContents. */
  onSocialShareChange: PropTypes.func,
  /** Embed Script Content change action of ArticleContents. */
  onEmbedScriptChange: PropTypes.func,
  /** Embed Script Caption change action of ArticleContents. */
  onEmbedScriptCaptionChange: PropTypes.func,
  /** RichText Content change action of ArticleContents. */
  onRichTextChange: PropTypes.func,
  /** content order change action of ArticleContents. */
  onOrderChange: PropTypes.func,
  /** on content close action of ArticleContents. */
  onCloseContent: PropTypes.func,
  /** image caption change of ArticleContents. */
  onImageCaptionChange: PropTypes.func,
  /** image edit option of ArticleContents. */
  showEditImage: PropTypes.func
}

export default ArticleContents
