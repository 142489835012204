import gql from 'graphql-tag'

export default gql(`
  query($sport: ID! $type: String!){
    generateCSVHeaders( 
    sport: $sport
    type: $type
    ){
      name
      type
      value
      oldValue
      displayValue
      isRequired
      displayName
      fieldName
      maxValue
      isPosChange
      position
      default
    }
  }`
)
