import React, { Component } from 'react'
import { Table, Skeleton, Empty } from 'antd'
import { Resizable } from 'react-resizable'
import moment from 'moment'

// import Tooltip from './ToolTip'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListUserDevices from './../../../graphQL/customers/listCustomerUserDevices'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class ActiveDevicesTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 150
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100
      },
      {
        title: 'Platform Type',
        dataIndex: 'platformType',
        key: 'platformType',
        width: 130
      },
      {
        title: 'Model',
        dataIndex: 'model',
        width: 130,
        key: 'model'
        // render: this.renderCreatedDate
      },
      {
        title: 'OS version',
        dataIndex: 'osVersion',
        key: 'osVersion',
        width: 100
      },
      {
        title: 'App Version',
        dataIndex: 'appVersion',
        key: 'appVersion',
        width: 100
      },
      {
        title: 'Browser Name',
        dataIndex: 'browserName',
        key: 'browserName',
        width: 130
      },
      {
        title: 'Browser Version',
        dataIndex: 'browserVersion',
        key: 'browserVersion',
        width: 130
      },
      {
        title: 'Last IP Address',
        dataIndex: 'lastIPAddress',
        key: 'lastIPAddress',
        width: 130
      },
      {
        title: 'Last IP Location',
        dataIndex: 'lastIPLocation',
        key: 'lastIPLocation',
        width: 130
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 130,
        render: (createdAt) => createdAt ? moment(new Date(createdAt)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 130,
        render: (updatedAt) => updatedAt ? moment(new Date(updatedAt)).format('DD MMM YYYY, HH:mm:ss') : ''
      }
    ]
    this.state = {
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  render () {
    const { listDevices, isLoading } = this.props
    const { width, height, columns } = this.state
    let listSize = _.size(listDevices)
    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))
    return (
      <div className={`general-customer-devices-table ${listSize === 1 ? 'subscription-table-single' : ''}`} ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(listDevices) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={listDevices} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
      </div>
    )
  }
}

ActiveDevicesTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListUserDevices,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listDevices = data && data.listUserDevices ? data.listUserDevices : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { listDevices, isLoading: data && data.loading }
      }
    }
  )
)(ActiveDevicesTable))
