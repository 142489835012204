import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MatchFilter from './MatchFilter'
import { utilityService } from '../../../../services/UtilityService'
import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'

import QueryAllMatches from '../../../../graphQL/match/listMatches'

const Option = Select.Option
class MatchDropDown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      match: props.matches && props.matches.length ? props.matches : undefined,
      isDeletingMatch: false,
      selectedCompetition: undefined,
      selectedSport: undefined,
      competitionSearch: undefined,
      filterDate: undefined,
      isSelected: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.matches && !_.isEqual(newProps.matches, this.props.matches)) {
      this.setState({ match: newProps.matches })
    }
  }

  componentDidMount () {
    // const startDate = moment(new Date()).startOf('day').utc().format()
    const startDate = moment(new Date()).subtract(8, 'day').startOf('day').utc().format()
    const endDate = moment(new Date(startDate)).add(1, 'year').endOf('day').utc().format()
    const date = `${startDate},${endDate}`
    this.onCompetitionDateSelect(date)
  }

  onMatchSearch = (value) => {
    if (this.props.onSearch) { this.props.onSearch(value) }
  }

  onBlur = () => {
    if (this.props.onSearch) { this.props.onSearch('') }
  }

  onSelectMatch = (id) => {
    if (this.props.onSearch) { this.props.onSearch('') }
    this.onMatchSelect(id)
  }

  onChangeMatch = (id) => {
    this.onMatchSelect(id, true)
  }

  onChangeMatchFilter = () => {
    const { selectedCompetition, selectedSport, filterDate } = this.state
    const matchFilter = {
      selectedCompetition: selectedCompetition && selectedCompetition.name === 'None' ? null : selectedCompetition,
      selectedSport: selectedSport && selectedSport.title === 'None' ? null : selectedSport,
      filterDate: filterDate
    }
    this.props.onChangeMatchFilter(matchFilter)
  }

  onMatchSelect = (id, isDeletingMatch) => {
    const { match } = this.state
    const selectedMatch = (match || []).find(match => match.id === id)
    if (selectedMatch) {
      this.props.onMatchSelect(selectedMatch)
    } else if (isDeletingMatch) {
      this.props.onMatchSelect(undefined)
    }
  }

  clearFilter = (e) => {
    e.stopPropagation()
    this.setState({ filterDate: undefined, selectedCompetition: undefined, isSelected: false, selectedSport: undefined }, this.onChangeMatchFilter)
  }

  onCompetitionSelect = (selectedValue) => {
    const { filterDate, selectedSport } = this.state
    if (selectedValue.name === 'None' && (filterDate ? filterDate === null : null) && (selectedSport ? selectedSport.name === 'None' : null)) {
      this.setState({ selectedCompetition: selectedValue, isSelected: false }, this.onChangeMatchFilter)
    } else {
      this.setState({ selectedCompetition: selectedValue, isSelected: true }, this.onChangeMatchFilter)
    }
  }

  onCompetitionSearch = (value) => {
    this.setState({
      competitionSearch: value
    })
  }

  onSportSelect = (selectedSport) => {
    const { selectedCompetition, filterDate } = this.state
    if (selectedSport.title === 'None' && (selectedCompetition ? selectedCompetition.name === 'None' : null) && (filterDate ? filterDate === null : null)) {
      this.setState({ selectedSport, isSelected: false }, this.onChangeMatchFilter)
    } else {
      this.setState({ selectedSport, isSelected: true }, this.onChangeMatchFilter)
    }
  }

  onCompetitionDateSelect = (selectedValue) => {
    const { selectedCompetition, selectedSport } = this.state
    if (selectedValue === null && (selectedCompetition ? selectedCompetition.name === 'None' : null) && (selectedSport ? selectedSport.name === 'None' : null)) {
      this.setState({ filterDate: selectedValue, isSelected: false }, this.onChangeMatchFilter)
    } else {
      this.setState({ filterDate: selectedValue, isSelected: true }, this.onChangeMatchFilter)
    }
  }

  render () {
    const { match, competitionSearch, selectedCompetition, filterDate, isSelected, selectedSport } = this.state
    const { parentCompoent, selectedMatch, matchFilter, project } = this.props
    const matchComponents = match ? match.map((match, index) => {
      return <Option className='match-drop-down-option' key={`option${index}`} value={match.id}>
        <div className='match-details-top'>
          <p>{ match.competition ? match.competition.title : '' }</p>
          <div className='date'>
            {match.matchDate ? <p>{moment(new Date(match.matchDate)).format('DD MMM YYYY, HH:mm')}</p> : null}
          </div>
        </div>

        <div className='match-details-middle'>
          <div className='matches'>
            <img src={match.homeTeam && match.homeTeam.media && match.homeTeam.media.length && match.homeTeam.media[ 0 ] ? generateCroppedThumbnail(match.homeTeam.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar-team-icon.png')} />
            {match.homeTeam ? <p>{ match.homeTeam.officialName }</p> : ''}
          </div>
          <div className='matches'>
            <p>vs</p>
          </div>
          <div className='matches'>
            <img src={match.awayTeam && match.awayTeam.media && match.awayTeam.media.length && match.awayTeam.media[ 0 ] ? generateCroppedThumbnail(match.awayTeam.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar-team-icon.png')} />
            {match.awayTeam ? <p>{ match.awayTeam.officialName }</p> : null}
          </div>
        </div>

        <div className='match-details-bottom'>
          {match.opId ? <div className='match-details'>
            <p>Match ID:</p>
            <p>{`#${match.opId}`}</p>
          </div> : ''}
          {match.kickOffTime ? <div className='match-details'>
            <p>Kickoff:</p>
            {match.kickOffTime ? <p>{moment(new Date(match.kickOffTime)).format(' HH:mm:ss')}</p> : null}
          </div> : ''}
          {match.finishTime ? <div className='match-details'>
            <p>Finish Time:</p>
            {match.finishTime ? <p>{moment(new Date(match.finishTime)).format(' HH:mm:ss')}</p> : null}
          </div> : ''}
        </div>
      </Option>
    }) : null

    const matchFilters = <MatchFilter
      parent={parentCompoent}
      selectedMatch={selectedMatch || undefined}
      onCompetitionDateSelect={this.onCompetitionDateSelect}
      onCompetitionSelect={this.onCompetitionSelect}
      onCompetitionSearch={this.onCompetitionSearch}
      onSportSelect={this.onSportSelect}
      selectedSport={selectedSport}
      competitionSearch={competitionSearch}
      isSelected={isSelected}
      selectedCompetition={selectedCompetition}
      clearFilter={this.clearFilter}
      filterDate={filterDate}
      clearSearchField={this.onChangeMatch}
      matchFilter={matchFilter}
      project={project}
    />

    const selectedValue = selectedMatch ? (

      <React.Fragment>
        <div className='selected-match-details'>
          <div className='match-data'>
            <div className='selected-match'>
              <img src={selectedMatch.homeTeam && selectedMatch.homeTeam.media && selectedMatch.homeTeam.media.length && selectedMatch.homeTeam.media[ 0 ] ? generateCroppedThumbnail(selectedMatch.homeTeam.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar-team-icon.png')} />
              {selectedMatch.homeTeam ? <p>{ selectedMatch.homeTeam.officialName }</p> : null}
            </div>
            <div className='selected-match'>
              <p>vs</p>
            </div>
            <div className='selected-match'>
              <img src={selectedMatch.awayTeam && selectedMatch.awayTeam.media && selectedMatch.awayTeam.media.length && selectedMatch.awayTeam.media[ 0 ] ? generateCroppedThumbnail(selectedMatch.awayTeam.media[0], 200, 200, '1:1') : require('../../../../assets/images/avatar-team-icon.png')} />
              {selectedMatch.awayTeam ? <p>{ selectedMatch.awayTeam.officialName }</p> : null}
            </div>
          </div>
          <div className='left-icon'>{matchFilters}</div>
        </div>
      </React.Fragment>
    ) : <div className='left-icon' style={{ float: 'right', marginTop: '2px' }}>{ matchFilters }</div>

    return (
      <Select key={JSON.stringify(selectedMatch)}
        className='match-drop-down'
        placeholder='Select Match'
        showSearch
        value={selectedValue}
        onSelect={this.onSelectMatch}
        mode='default'
        getPopupContainer={() => document.getElementById(parentCompoent)}
        filterOption={false}
        onSearch={this.onMatchSearch}
        onBlur={this.onBlur}
      >
        { matchComponents }
      </Select>
    )
  }
}

MatchDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string,
  /** parent container id name of Dropdown. */
  parentCompoent: PropTypes.string,
  /** Selected value of Dropdown. */
  selected: PropTypes.object,
  /** Options of Dropdown. */
  match: PropTypes.arrayOf(PropTypes.object),
  /** match selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func
}

MatchDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default withApollo(compose(
  graphql(
    QueryAllMatches,
    {
      options: ({ matchSearch, matchFilter }) => {
        const count = 60
        if (matchFilter && matchFilter.selectedCompetition) {
          matchFilter.selectedCompetitions = [ matchFilter.selectedCompetition ]
        }
        // if (matchFilter && matchFilter.selectedSport) {
        //   matchFilter.sport = [ matchFilter.sport ]
        // }
        const variables = utilityService.getMatchManagerFilter(matchFilter, matchSearch, count)
        return {
          variables,
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listMatches } }) => {
        let matches
        if (listMatches && listMatches.items) {
          matches = listMatches.items.map(item => {
            if (item.competition && item.competition.customTitle) {
              item.competition.title = item.competition.customTitle
            }
            return item
          })
        }
        return { matches: matches ? [ ...matches ] : undefined }
      }
    }
  )

)(MatchDropDown))
