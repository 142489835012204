import gql from 'graphql-tag'

export default gql(`
query listBucketItem($filter: BucketFilterInput, $offset: Int, $limit: Int, $orderBy: String, $order: String, $search: SearchInput $project: String) {
  listBuckets(
      limit: $limit,
      filter: $filter,
      order: $order,
      offset: $offset,
      orderBy: $orderBy,
      search: $search
      project: $project
    ) {
      items{
        project
        id
        isArchived
        name
        isPublished
        publishStartDate
        publishEndDate
        updatedAt
        updatedBy
        key
        displayName
        displayType{
          name
          id
        }
        bucketItems {
          id
          position
          type
          asset{
            id
            title
            type
            shortId
            externalId
            updatedAt
            status
            seasonNumber
            episodeNumber
            series{
              id
              assetId
              title
            }
            season{
                id
                assetId
                title
            }
            defaultMedia {
                id
                url
                fileName
                aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                  }
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2 
                  y2
                }
            }
          }  
          filterRule{
            searchKey
            filters{
                name
                fieldName
                type
                value
                displayValue
                displayName
                isRequired
            }
        }
        }
      }
      totalCount
    }
}`)
