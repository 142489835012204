import gql from 'graphql-tag'

export default gql(`
    mutation adminDisableUser( $email: String! $userId: String $project: String) {
        adminDisableUser(
            email: $email
            project: $project
            userId: $userId
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
