import gql from 'graphql-tag'

export default gql(`
    mutation discardPageLayoutItem($layoutItems:[ID] $page: String $project: String $app: ID!) {
        discardPageLayoutItem(
          layoutItems: $layoutItems
          project: $project
          page: $page
          app: $app
        ) {
          id
        }
    }`
)
