import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MatchListViewDetails from './MatchListViewDetails'
import MatchAssetDetails from './MatchAssetDetails'
// import { generateCroppedThumbnail } from './../../../util/util'
import './../ui.style.scss'

class MatchListCell extends React.Component {
  render () {
    const { matchId, details, isSelected, onSelect, status, program, types } = this.props
    const matchTime = details.matchDate ? details.matchDate : details.kickOffTime
    return (
      <div
        className={`match-list-cell ${isSelected ? 'active' : ''}`}
        onClick={() => onSelect(matchId)}
      >
        <div className='list-view'>
          <div className='match-data'>
            <div className='league-name'>
              {/* <img src={details.competition.media && details.competition.media.length && details.competition.media[ 0 ] ? generateCroppedThumbnail(details.competition.media[0], 200, 200, '1:1') : require('../../../assets/images/avatar-team-icon.png')} /> */}
              {/* <p>{details.competition ? details.competition.competitionCode : '-'}</p> */}
            </div>
            <div className='match-teams'>
              <MatchListViewDetails teamA={details.homeTeam} teamB={details.awayTeam} />
            </div>
            {/* <p className='match-id'>{details.opId ? details.opId : null }</p> */}
          </div>
          <div className='second'>
            <div className='broadcast-time'>
              <p> {program && program.broadCastStartTime
                ? `${moment(new Date(program.broadCastStartTime)).format('HH:mm')} - ${program.broadCastEndTime
                  ? moment(new Date(program.broadCastEndTime)).format('HH:mm') : ' - '} `
                : <span className='empty'>{' - '}</span>}
              </p>  </div>
            <div className='match-time'>
              <p>{details.kickOffTime || details.matchDate
                ? `${moment(new Date(matchTime)).format('HH:mm')}  - ${details.finishTime
                  ? moment(new Date(details.finishTime)).format('HH:mm') : ''}`
                : <span className='empty'>{' - '}</span>}
              </p> </div>
          </div>
          <div className='third'>
            <div className='channel'>{program && program.channel
              ? <a className='pink-color' href={location.origin + '/channels/' + program.channel.id} target='_blank'> {program.channel.channelCode} </a>
              : <span className='empty'>{' - '}</span>}
            </div>
            <div className='status'>
              <p className={status === 'On Now' ? 'ERROR' : status}> {status || <span className='empty'>{' - '}</span> } </p>
            </div>
          </div>
          <MatchAssetDetails assets={details.asset ? details.asset : []} isExpanded={false} details={details} types={types} />
          {/* <div className='assignee'> - </div> */}
        </div>
      </div>
    )
  }
}

MatchListCell.propTypes = {
  /** Match id of MatchListCell */
  matchId: PropTypes.string,
  /** Match details expanded status of MatchListCell */
  isExpanded: PropTypes.bool,
  /** Match selected status of MatchListCell */
  isSelected: PropTypes.bool,
  /** Match details of MatchListCell */
  details: PropTypes.object,
  /** Click selection action of MatchListCell. */
  onSelect: PropTypes.func

}

MatchListCell.defaultProps = {
  isSelected: false
}

export default MatchListCell
