import React, { Component } from 'react'
import { Table, Skeleton, message, Checkbox } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import MutationDeleteTagType from '../../../graphQL/admin/tagType/deleteTagType'
import QueryTagType from './../../../graphQL/admin/tagType/listTagType'
import QueryListAssetType from './../../../graphQL/admin/asset/listAssetTypes'
import MutationBatchUpdateTagType from './../../../graphQL/admin/tagType/batchUpdateTagType'

import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import CreateTagTypeModal from '../../../components/ui/dataEntry/other/CreateTagTypeModal'
import { utilityService } from './../../../services/UtilityService'
// import ButtonContainer from './../../../components/ui/general/buttons/ButtonContainer'
// import MultipleSelectButton from './../../../components/ui/general/buttons/MultipleSelectButton'
// import BackArrowButton from './../../../components/ui/general/buttons/BackArrowButton'
// import ArchiveButton from './../../../components/ui/general/buttons/ArchiveButton'
import { generateCroppedThumbnail } from './../../../util/util'
// import ParentRatingList from '../entertainmentData/ParentRatingList'

const deleteTagTypeMessage = {
  title: 'DELETE TAG TYPE',
  firstMessage: 'Are you sure you want to delete this Tag Type?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class TagTypeList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedType: null,
      isLoading: !(props.listTagTypes && props.listTagTypes.length),
      isShowTagTypeModal: false,
      showDeleteTagTypeModal: false,
      selectedTagType: undefined,
      deleteLoading: false,
      isDeleteSuccess: false,
      isEdit: false,
      isDragStart: false,
      expandedRowKeys: [],
      // parentRatingList: props.listRatingTypes && props.listRatingTypes.length ? props.listRatingTypes : [],
      tagTypeList: props.listTagTypes && props.listTagTypes.length ? props.listTagTypes : [],
      selectedIds: []
    }
    this.columns = [
      {
        title: 'Tag Type Name',
        dataIndex: 'name',
        key: 'name',
        width: '37%',
        render: (name, id) => this.renderTagTypeName(name, id)
      },
      {
        title: '# of tags',
        dataIndex: 'tagCount',
        width: '50%',
        key: 'tagCount',
        render: (tagCount) => this.renderTagTypeCount(tagCount)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.innerColumns = [
      {
        title: 'Asset Types',
        dataIndex: 'title',
        key: 'title',
        width: '90%',
        render: (title) => <div className='inner-header'>{title}</div>
      }
    ]
    this.metaDataColumns = [
      {
        title: 'Asset Type',
        dataIndex: 'title',
        key: 'title',
        width: '100%',
        render: (title) => this.renderAssetTypeList(title)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.listTagTypes, this.props.listTagTypes)) {
        const updatedTagTypeList = (cloneDeep(newProps.listTagTypes || [])).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          if (item.enabledAssetTypes === null) {
            item.enabledAssetTypes = []
          }

          return item
        })
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, tagTypeList: _.cloneDeep(updatedTagTypeList.sort((a, b) => (a.position - b.position))) })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.listTagTypes, this.props.listTagTypes)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (newProps.isLoading && !_.isEmpty(newProps.listTagTypes) && !_.isEqual(newProps.listTagTypes, this.props.listTagTypes)) {
        const updatedTagTypeList = (cloneDeep(newProps.listTagTypes) || []).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          return item
        })
        this.setState({ isSearching: false, isPaginating: false, isLoading: false, tagTypeList: _.cloneDeep(updatedTagTypeList.sort((a, b) => (a.position - b.position))) })
      } else if (!newProps.isLoading && _.isEmpty(newProps.listTagTypes)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    renderAssetTypeList=(title) => {
      return (
        <div className='assetType-list'>
          {this.getLogoForAsset(title)}
          {title}
        </div>
      )
    }

    getLogoForAsset=(title) => {
      const { listAssetTypes } = this.props
      const foundAsset = (listAssetTypes || []).find(item => title === item.title)
      let src
      if (!_.isEmpty(foundAsset)) {
        src = (foundAsset.icon || foundAsset.draftIcon) ? <img src={generateCroppedThumbnail((foundAsset.icon ? foundAsset.icon : foundAsset.draftIcon), 23, 23, '1:1')} /> : <img src={null} />
      }
      return src
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'transfer', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedType) {
        this.setState({ openedType: null })
      }
    }

    showCreateTagTypeModal = (isShowTagTypeModal) => {
      this.setState({ isShowTagTypeModal, selectedTagType: null, isEdit: false })
    }

    toggleShowOptions = id => {
      if (this.state.openedType === id) {
        this.setState({ openedType: null })
      } else {
        this.setState({ openedType: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { listTagTypes } = this.props
      const selectedTagType = listTagTypes.find(item => item.id === id)
      if (!selectedTagType) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ isShowTagTypeModal: true, selectedTagType, isEdit: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteTagTypeModal: true, selectedTagType })
      }
      this.setState({ openedType: null })
    }

    loadMoreTagTypes = () => {
      if (this.props.totalCount === this.state.tagTypeList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreTagTypes(this.state.tagTypeList.length))
    }

    hideDeleteTagTypeModal = () => {
      this.setState({ isDeleteSuccess: true, selectedTagType: null }, () => {
        this.setState({ showDeleteTagTypeModal: false, isDeleteSuccess: false })
      })
    }

    renderTagTypeCount = (count) => {
      if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderTagTypeName=(name, props) => {
      return (
        <div className='tag-type-title'>
          {this.props.isDeleting ? (<Checkbox checked={this.checkIfSeleted(props.id)} onClick={() => this.onCheck(props.id)} />) : null}
          {name}
        </div>
      )
    }

    checkIfSeleted=(id) => {
      let { tagTypeList } = this.state
      const index = tagTypeList.findIndex(tagType => tagType.id === id)
      // if(index > -1)
      // return true
      // else
      // return false
      if (index > -1) { return tagTypeList[index].isSelected } else { return false }
    }

    onCheck=(id) => {
      let { tagTypeList, selectedIds } = this.state
      const index = tagTypeList.findIndex(tagType => tagType.id === id)
      if (index > -1) {
        tagTypeList[index].isSelected = !tagTypeList[index].isSelected
        const selectedIndex = selectedIds.indexOf(id)
        if (selectedIndex > -1) {
          selectedIds.splice(selectedIndex, 1)
        } else {
          selectedIds.push(id)
        }
        this.setState({ selectedIds })
      }
    }

    onDragEnd = (result) => {
      const { listTagTypes, onHistoryUpdate } = this.props
      const startIndex = result.source.index
      const endIndex = result.destination.index
      let { tagTypeList } = this.state
      const [ removed ] = tagTypeList.splice(startIndex, 1)
      removed.trackPosChange = true
      tagTypeList.splice(endIndex, 0, removed)
      tagTypeList = tagTypeList.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({ tagTypeList, isDragStart: false })
      const changedTypes = tagTypeList.filter(tagType => {
        const selectedItem = listTagTypes.find(innerItem => innerItem.id === tagType.id)
        return selectedItem.position !== tagType.position
      })

      const variables = (changedTypes || []).map(item => {
        return {
          id: item.id,
          position: item.position,
          name: item.name,
          isMediaEnabled: item.isMediaEnabled,
          key: item.key,
          isCustom: item.isCustom,
          trackPosChange: item.trackPosChange,
          enabledAssetTypes: item.enabledAssetTypes === null ? null : (item.enabledAssetTypes || []).map(assetType => {
            delete assetType.__typename
            // delete meta.id
            return assetType
          })
        }
      })
      this.props.batchUpdateTagTypes({ updateData: variables }).then(() => {
        onHistoryUpdate()
      })
    }

    onDragStart = () => {
      const { expandedRowKeys } = this.state
      if (expandedRowKeys.length) { this.setState({ expandedRowKeys: [], isDragStart: true }) } else { this.setState({ isDragStart: true }) }
    }

     dragableBodyRow = (item) => {
       const { isDragStart } = this.state
       const { index } = item
       return <Draggable key={item['data-row-key']} draggableId={item['data-row-key']} index={index}>
         { (provided, snapshot) => {
           return <tr className={`ant-table-row${isDragStart ? ' dragEnable' : ''}`}
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={getItemStyle(
               snapshot.isDragging,
               provided.draggableProps.style
             )}
           >
             {item.children}
           </tr>
         }}</Draggable>
     }

     handleRowExpand = (record) => {
       this.setState(prevState =>
         prevState.expandedRowKeys.includes(record.id)
           ? {
             expandedRowKeys: prevState.expandedRowKeys.filter(
               key => key !== record.id
             )
           }
           : { expandedRowKeys: [...prevState.expandedRowKeys, record.id] }
       )
     }

     onDeleteTagTypeModal = () => {
       const { selectedTagType } = this.state
       const { onHistoryUpdate } = this.props
       if (!selectedTagType) {
         return
       }
       this.setState({ deleteLoading: true })
       this.props.deleteTagType(selectedTagType.id, selectedTagType.name).then(() => {
         message.success('Tag deleted successfully')
         this.setState({ isDeleteSuccess: true, selectedTagType: null }, () => {
           this.setState({ showDeleteTagTypeModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         onHistoryUpdate()
       }, error => {
         this.setState({ isDeleteSuccess: true, selectedTagType: null }, () => {
           this.setState({ showDeleteTagTypeModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         utilityService.handleError(error)
       })
     }

     handleAddTagType = () => {
       this.setState({ isShowTagTypeModal: false, selectedTagType: null })
     }

    renderData = (id) => {
      let availableOptions = []
      const { openedType } = this.state
      const { listTagTypes } = this.props
      const selectedTagType = listTagTypes.find(item => item.id === id)
      availableOptions = [ ...options ]
      if (selectedTagType && !(selectedTagType.tagCount || this.isDeleteDisabled)) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(1, 1)
      }
      if (this.isCreateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedType === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    renderRatingData = (data) => {
      let newData = (data || []).map(function (data) {
        return {
          title: data.assetType
        }
      })
      return <Table
        className={`inner-table inner-table-container`}
        rowKey={record => record.title}
        columns={this.metaDataColumns}
        dataSource={newData}
        pagination={false}
        showHeader
      />
    }

    renderInnerRows = (data) => {
      return <React.Fragment>
        {this.renderRatingData(data.enabledAssetTypes)}
      </React.Fragment>
    }

    onHandleBack=() => {
      let { parentRatingList } = this.state
      const newList = (parentRatingList || []).map(item => {
        item.isSelected = false
        return item
      })

      this.setState({ parentRatingList: newList })
      this.props.onHandleBackButton()
    }

    render () {
      const { isLoading, listAssetTypes, onHistoryUpdate } = this.props
      const { isPaginating, selectedTagType, expandedRowKeys, tagTypeList, isShowTagTypeModal, showDeleteTagTypeModal, deleteLoading, isDeleteSuccess } = this.state
      let adminItem
      if (selectedTagType && showDeleteTagTypeModal) {
        adminItem = <div >
          <p>{ selectedTagType.name }</p>
        </div>
      }

      const components = {
        body: {
          row: this.dragableBodyRow
        }
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal tag-type-container'>
              <div className='admin-list'>
                <FixedTableHeader columnCellList={this.columns} isExpandable />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreTagTypes}
                  hasMore={this.props.totalCount > tagTypeList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart} >
                      <Droppable droppableId={'#TagType_123'}>
                        { (droppableProvided, droppableSnapshot) => (

                          <div className='tag-type-table-container'
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            <Table
                              className={`general-table ${isPaginating ? 'paginating' : ''}`}
                              rowKey={record => record.id}
                              columns={this.columns}
                              dataSource={tagTypeList}
                              pagination={false}
                              expandedRowRender={(data) => this.renderInnerRows(data)}
                              expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => this.handleRowExpand(record)}
                              components={components}
                              onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow
                              })} />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-tag-type-footer' >
                {/*! isDeleting
                  ? <ButtonContainer displayTitle=' Selecte Tag Types' childComponent={<MultipleSelectButton type='primary' isDisabled={false} onClick={onHandleMultipleSelect} />} />
                  : <div className='button-delete-container'>
                    <ButtonContainer displayTitle='Back' childComponent={<BackArrowButton onClick={this.onHandleBack} />} />
                    <ButtonContainer displayTitle='Delete Selected Tags' childComponent={<ArchiveButton type='primary' isDisabled={!selectedIds.length || userPermissions.indexOf('UPDATE') === -1} onClick={() => console.log('hey!!')} />} />
                  </div>

                */}
                <LoadingButton
                  type='primary'
                  onClick={() => this.showCreateTagTypeModal(true, true)}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreateTagTypeModal
                isVisible={isShowTagTypeModal}
                handleCancel={() => this.showCreateTagTypeModal(false)}
                handleSubmit={this.handleAddTagType}
                selectedTagType={selectedTagType}
                listAssetTypes={listAssetTypes}
                isEdit={!!selectedTagType}
                isSubmitDisabled={!!this.isUpdateDisabled}
                // isImageModified={isImageModified}
                isDeleteSuccess={isDeleteSuccess}
                maxPosition={tagTypeList && tagTypeList.length ? tagTypeList[tagTypeList.length - 1].position : 0}
                onHistoryUpdate={onHistoryUpdate}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteTagTypeModal}
                title={deleteTagTypeMessage.title}
                firstMessage={deleteTagTypeMessage.firstMessage}
                secondMessage={deleteTagTypeMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteTagTypeModal}
                handleSubmit={this.onDeleteTagTypeModal}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

TagTypeList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryTagType,
    {
      options: ({ nextToken, project }) => {
        const filter = null
        const variables = { limit: 500, offset: 0, filter, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listTagTypes = data.listTagType ? data.listTagType.items : []
        return {
          listTagTypes,
          isLoading: data.loading,
          totalCount: data.listTagType ? data.listTagType.totalCount : 0,
          // previousToken: data.listTagType ? data.listTagType.previousToken : null,
          getMoreTagTypes: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listTagType.items, ...fetchMoreResult.listTagType.items]
                prev.listTagType.items = newList
                // prev.listTagType.previousToken = prev.listTagType.nextToken
                prev.listTagType.nextToken = fetchMoreResult.listTagType.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteTagType,
    {
      options: ({ previousToken, project }) => {
        return {
          update: (cache, { data: { deleteTagType } }) => {
            // const filter = null
            const variables = { filter: null, limit: 500, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryTagType, variables }))
            if (cacheData && cacheData.listTagType && cacheData.listTagType.items) {
              const index = cacheData.listTagType.items.findIndex((item) => deleteTagType.id === item.id)
              cacheData.listTagType.items.splice(index, 1)
              cacheData.listTagType.totalCount = cacheData.listTagType.totalCount - 1
            }
            cache.writeQuery({
              query: QueryTagType,
              data: cacheData,
              variables
            })
          }
          // refetchQueries: () => [{ query: QueryTagType,
          //   variables: { limit: 500,
          //     project,
          //     nextToken: null,
          //     filter: {
          //       isMatchRelated: true
          //     } } }]
        }
      },
      props: (props) => ({
        deleteTagType: (id, name) => {
          return props.mutate({
            variables: { id, name, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    QueryListAssetType,
    {
      options: ({ nextToken, project }) => {
        const filter = null
        const variables = { limit: 20, nextToken: nextToken || null, filter, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data, ownProps } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes,
          isLoading: data.loading,
          nextToken: data.listAssetTypes ? data.listAssetTypes.nextToken : null,
          previousToken: data.listAssetTypes ? data.listAssetTypes.previousToken : null,
          getMoreTypes: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: page,
                project: ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAssetTypes.items, ...fetchMoreResult.listAssetTypes.items]
                prev.listAssetTypes.items = newList
                prev.listAssetTypes.previousToken = prev.listAssetTypes.nextToken
                prev.listAssetTypes.nextToken = fetchMoreResult.listAssetTypes.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationBatchUpdateTagType,
    {
      props: (props) => ({
        batchUpdateTagTypes: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(TagTypeList))
