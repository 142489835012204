import React, { Component } from 'react'
import moment from 'moment'
import { DatePicker, Empty, Skeleton } from 'antd'

import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'

// import TagsInputContainer from './../../components/ui/dataEntry/inputs/TagsInputContainer'
import GroupIcon from './../../components/ui/general/icons/GroupIcon'
import MatchListIcon from './../../components/ui/general/icons/MatchListIcon'
// import FilterInput from './../../components/ui/dataEntry/inputs/FilterInput'
// import GroupInput from './../../components/ui/dataEntry/inputs/GroupInput'
import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import MatchListTable from '../../components/ui/dataDisplay/MatchListTable'
import SidebarOpenButton from '../../components/ui/general/buttons/SidebarOpenButton'
import SidebarCloseButton from '../../components/ui/general/buttons/SidebarCloseButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import SportFilterIcon from '../../components/ui/general/icons/SportFilterIcon'
import CompetitionFilterIcon from '../../components/ui/general/icons/CompetitionFilterIcon'
import SeasonFilterIcon from '../../components/ui/general/icons/SeasonFilterIcon'
import TimeFilterIcon from '../../components/ui/general/icons/TimeFilterIcon'
import StagesFilterIcon from '../../components/ui/general/icons/StagesFilterIcon'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import IconDropDown from '../../components/ui/dataEntry/inputs/IconDropDown'
import AuthService from '../../services/AuthService'
import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCompetition from '../../graphQL/asset/listCompetition'
import QuerySeasonFilter from './../../graphQL/match/getSeasonFilter'
import QueryStageFilter from './../../graphQL/match/getStageFilter'
import QueryAllMatches from './../../graphQL/match/listMatches'
import QueryListSports from './../../graphQL/admin/sports/listSports'

const { RangePicker } = DatePicker
const parentCompoent = 'root'
const width = ['100%', '100%', '100%']
const detailsTabs = (mode) => [
  {
    displayName: 'short',
    mode: 'short',
    icon: <MatchListIcon color={mode === 'short' ? '#FF015A' : '#343A40'} />
  }, {
    displayName: 'expand',
    mode: 'expand',
    icon: <GroupIcon color={mode === 'expand' ? '#FF015A' : '#343A40'} />
  }
]

const timeFilter = [
  {
    displayName: 'Yesterday',
    id: '1',
    day: '1'
  }, {
    displayName: 'Last 2 days',
    id: '2',
    day: '2'
  }, {
    displayName: 'Last 3 days',
    id: '3',
    day: '3'
  }, {
    displayName: 'Last 8 days',
    id: '4',
    day: '8'
  }
]

const allCompetitions = {
  id: 'allCompetitions',
  title: 'All Competitions',
  isDefault: true
}

const allStages = {
  id: 'allStages',
  name: 'All Stages',
  isDefault: true
}

const allSeasons = {
  id: 'allSeasons',
  name: 'All Seasons',
  isDefault: true
}

class MatchList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedIds: [],
      isPaginating: false,
      isSearching: false,
      filterSearch: '',
      isClearFilter: false,
      mode: 'expand',
      matchDates: [],
      competitions: props.competition && props.competition.length ? props.competition : [],
      endDate: undefined,
      filterDate: props.matchFilter ? props.matchFilter.filterDate : undefined,
      filter: {},
      searchString: '',
      shouldShowSportFilter: false,
      shouldShowTimeFilter: false,
      shouldShowCompetitionFilter: false,
      shouldShowStageFilter: false,
      shouldShowSeasonFilter: false,
      selectedTime: undefined,
      selectedSport: [],
      selectedCompetition: [],
      selectedSeason: [],
      selectedStage: []
    }
    this.getUserDetails()
    this.selectedIds = []
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.competition) {
      this.setState({ competitions: newProps.competition })
    }
    if (!newProps.isLoading && (!this.lastSearched || this.lastSearched.searchString !== newProps.searchString || this.lastSearched.filter !== newProps.matchFilter)) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.matchFilter }
      const matchDates = this.getMatchesByDate(_.cloneDeep(newProps.matches || []))
      this.setState({ matchDates, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
      if (!this.props.selectedMatchId && newProps.matches && newProps.matches.length) {
        this.props.changedMatch(newProps.matches[0].id)
        this.props.onMatchSelect()
      }
    } else if (!newProps.isLoading && !_.isEqual(newProps.matches, this.props.matches)) {
      const matchDates = this.getMatchesByDate(_.cloneDeep(newProps.matches || []))
      this.setState({ matchDates, isSearching: false, isPaginating: false, isLoading: false })
      if (!this.props.selectedMatchId && newProps.matches && newProps.matches.length) {
        this.props.changedMatch(newProps.matches[0].id)
        this.props.onMatchSelect()
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    // const { shouldShowTimeFilter, shouldShowCompetitionFilter, shouldShowSeasonFilter, shouldShowStageFilter } = this.state

    // if ((shouldShowTimeFilter || shouldShowCompetitionFilter || shouldShowSeasonFilter || shouldShowStageFilter) && !(e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight)) {
    if (!(this.filterContainer && this.filterContainer.contains(event.target))) {
      setTimeout(() => this.setState({ shouldShowTimeFilter: false, shouldShowCompetitionFilter: false, shouldShowStageFilter: false, shouldShowSeasonFilter: false, shouldShowSportFilter: false }), 200)
    }
  }

    getUserDetails = async () => {
      const userDetails = await AuthService.getUserDetails()
      if (userDetails) {
        this.username = userDetails.name || userDetails.email
      }
    }

    getMatchesByDate = matches => {
      let sortedMatches = (matches || []).sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate)))
      let matchDates = []
      while (sortedMatches.length) {
        const firstDate = sortedMatches[0].matchDate
        const date = moment(firstDate).format('ddd, DD MMM, YYYY')
        const matchList = sortedMatches.filter(match => moment(firstDate).isSame(match.matchDate, 'day'))
        matchDates.push({ date, matchList })
        sortedMatches.splice(0, matchList.length)
      }
      return matchDates
    }

    onChangeDate = (date, dateArr) => {
      let dateString = dateArr.filter((item) => item)
      const defLastDate = moment(new Date()).add(1, 'month').format('ddd, DD MMM, YYYY')
      const defStartDay = moment(new Date()).format('DD MMM, YYYY')
      if (!dateString.length) {
        dateString = [defStartDay, defLastDate]
        this.setState({ selectedTime: undefined })
      }
      if (date !== '') {
        this.setState({ selectedTime: undefined })
        const lastDate = moment(new Date()).add(1, 'year').format('ddd, DD MMM, YYYY')
        const startDay = moment(new Date()).format('DD MMM, YYYY')
        let dateString = [ startDay, lastDate ]
        let arrayVal = ''
        dateString.filter((item) => item).map((time, index) => {
          const date = moment(time)
          const startTime = _.cloneDeep(date).startOf('day')
          const endTime = _.cloneDeep(date).endOf('day')
          const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
          arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
        })
        const filterDate = arrayVal && arrayVal.length ? arrayVal : null
        if (this.onDateSelect) { this.onDateSelect(filterDate) }
      } else {
        if (date !== '') {
          this.setState({ selectedTime: undefined })
        }
        let arrayVal = ''
        dateString.filter((item) => item).map((time, index) => {
          const date = moment(time)
          const startTime = _.cloneDeep(date).startOf('day')
          const endTime = _.cloneDeep(date).endOf('day')
          const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
          arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
        })
        const filterDate = arrayVal && arrayVal.length ? arrayVal : null
        if (this.onDateSelect) { this.onDateSelect(filterDate) }
      }
      let arrayVal = ''
      dateString.map((time, index) => {
        const date = moment(time)
        const startTime = _.cloneDeep(date).startOf('day')
        const endTime = _.cloneDeep(date).endOf('day')
        const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
        arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
      })
      const filterDate = arrayVal && arrayVal.length ? arrayVal : null
      if (this.onDateSelect) { this.onDateSelect(filterDate) }

      this.selectedIds = []
    }

    onDateSelect = (selectedValue) => {
      this.setState({ filterDate: selectedValue }, this.onChangeMatchFilter)
    }

    onChangeMatchFilter = () => {
      const { selectedSport, selectedCompetition, filterDate, selectedStage, selectedSeason } = this.state
      const matchFilter = {
        selectedCompetitions: selectedCompetition,
        filterDate: filterDate,
        stages: selectedStage,
        seasons: selectedSeason,
        sport: selectedSport
      }
      this.props.onChangeMatchFilter(matchFilter)
    }

    onSearchChange = () => {
      this.setState({ isSearching: false })
    }

    onAssigneeSearch = () => {

    }

    handleModeChange = (e) => {
      const mode = e.target.value
      this.setState({ mode })
    }

    resetFilters = () => {
      this.setState({ isSearching: true })
      this.props.onChangeFilter('')
    }

    onChangeFilter = (value) => {
      this.setState({ isSearching: true })
      if (this.props.onChangeFilter) { this.props.onChangeFilter(value) }
    }

    loadMore = () => {
      if (!this.props.matches || (this.props.matches && this.props.totalCount === this.props.matches.length) || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMatchList(this.props.matches.length))
    }

    changeFilterValue = () => {
    }

    toggleSportFilter = () => {
      this.setState({ shouldShowSportFilter: !this.state.shouldShowSportFilter,
        shouldShowCompetitionFilter: false,
        shouldShowTimeFilter: false,
        shouldShowStageFilter: false,
        shouldShowSeasonFilter: false
      })
    }

    toggleTimeFilter = () => {
      this.setState({ shouldShowTimeFilter: !this.state.shouldShowTimeFilter,
        shouldShowCompetitionFilter: false,
        shouldShowStageFilter: false,
        shouldShowSeasonFilter: false,
        shouldShowSportFilter: false
      })
    }
    toggleCompetitionFilter = () => {
      this.setState({ shouldShowCompetitionFilter: !this.state.shouldShowCompetitionFilter,
        shouldShowTimeFilter: false,
        shouldShowStageFilter: false,
        shouldShowSeasonFilter: false,
        shouldShowSportFilter: false
      })
    }
    toggleStageFilter = () => {
      this.setState({ shouldShowStageFilter: !this.state.shouldShowStageFilter,
        shouldShowCompetitionFilter: false,
        shouldShowTimeFilter: false,
        shouldShowSeasonFilter: false,
        shouldShowSportFilter: false
      })
    }
    toggleSeasonFilter = () => {
      this.setState({ shouldShowSeasonFilter: !this.state.shouldShowSeasonFilter,
        shouldShowCompetitionFilter: false,
        shouldShowTimeFilter: false,
        shouldShowStageFilter: false,
        shouldShowSportFilter: false
      })
    }
    onSportSelect = (value) => {
      this.setState({ selectedSport: value }, this.onChangeMatchFilter)
      this.selectedIds = []
    }

    onTimeSelect = (value) => {
      if (value === undefined) {
        let dateString = [ '', '' ]
        if (this.onChangeDate) { this.onChangeDate('', dateString) }
      } else {
        this.setState({ selectedTime: value }, this.onTimeFilterSelect)
        this.selectedIds = []
      }
    }
    onCompetitionSelect = (value) => {
      this.setState({ selectedCompetition: value }, this.onChangeMatchFilter)
      this.selectedIds = []
    }
    onSeasonSelect = (value) => {
      this.setState({ selectedSeason: value }, this.onChangeMatchFilter)
      this.selectedIds = []
    }
    onStageSelect = (value) => {
      if (!_.isEmpty(value)) {
        this.setState({ selectedStage: value }, this.onChangeMatchFilter)
        this.selectedIds = []
      }
    }

    onTimeFilterSelect = () => {
      const { selectedTime } = this.state
      if (selectedTime) {
        const selectedItem = timeFilter.find((item) => item.id === selectedTime)
        const lastDay = selectedItem.day
        const endDay = moment(new Date()).subtract(1, 'day').format('DD MMM, YYYY')
        const startDay = moment(new Date()).subtract(lastDay, 'day').format('DD MMM, YYYY')
        let dateString = [ startDay, endDay ]
        if (this.onChangeDate) { this.onChangeDate('', dateString) }
      } else {
        let dateString = [ '', '' ]
        if (this.onChangeDate) { this.onChangeDate('', dateString) }
      }
    }

    render () {
      const { changedMatch, selectedMatchId, searchString, season, stage, isSidebarVisible, matchFilter, isLoading, listAssetTypes, sport, project } = this.props
      const { isSearching, mode, matchDates, isClearFilter, filterDate, competitions, isPaginating,
        shouldShowTimeFilter, shouldShowCompetitionFilter, shouldShowSeasonFilter, shouldShowStageFilter, selectedTime, selectedCompetition, selectedSeason, selectedStage, shouldShowSportFilter, selectedSport } = this.state
      return (
        <div className='match-list'>
          <div className='filter'>
            <div className='second-row'>
              <FilterInput
                searchString={searchString}
                onChangeSearchInput={this.onChangeFilter}
                filterType={'MatchManager'}
                changeFilter={(value) => {
                  this.setState({ isSearching: true })
                  this.changeFilterValue(value)
                }}
                placement='rightTop'
                isClearFilter={isClearFilter}
                placeholder='Search Match'
                project={project}
              />
              <div className='left'>
                <RangePicker onChange={this.onChangeDate} format='DD MMM YYYY' getCalendarContainer={() => document.getElementById(parentCompoent)}
                  defaultValue={filterDate ? filterDate.split(',').map((dateItem) => moment(dateItem)) : filterDate} />
              </div>
            </div>
            <div className='right'>
              <div className='quick-filters' id={'quick-filters'} ref={node => { this.filterContainer = node }}>

                <IconDropDown
                  icon={<CompetitionFilterIcon onClick={this.toggleCompetitionFilter} color={selectedCompetition && selectedCompetition.length ? '#FF015A' : '#343A40'} />}
                  displayParam={'title'}
                  contents={_.cloneDeep(competitions || []).map(item => { if (item.customTitle) { item.title = item.customTitle } return item })}
                  onContentClick={this.onCompetitionSelect}
                  parentCompoent={'quick-filters'}
                  isVisible={shouldShowCompetitionFilter}
                  selectedOption={selectedCompetition}
                  multiSelect
                />
                <IconDropDown
                  icon={<SportFilterIcon onClick={this.toggleSportFilter} color={selectedSport && selectedSport.length ? '#FF015A' : '#343A40'} />}
                  displayParam='title'
                  contents={sport || []}
                  onContentClick={this.onSportSelect}
                  parentCompoent={'quick-filters'}
                  isVisible={shouldShowSportFilter}
                  selectedOption={selectedSport}
                  multiSelect
                />
                <IconDropDown
                  icon={<TimeFilterIcon onClick={this.toggleTimeFilter} color={selectedTime ? '#FF015A' : '#343A40'} />}
                  displayParam='displayName'
                  contents={timeFilter}
                  onContentClick={this.onTimeSelect}
                  parentCompoent={'quick-filters'}
                  isVisible={shouldShowTimeFilter}
                  selectedOption={selectedTime}
                  multiSelect={false}
                />
                <IconDropDown
                  icon={<SeasonFilterIcon onClick={this.toggleSeasonFilter} color={selectedSeason && selectedSeason.length ? '#FF015A' : '#343A40'} />}
                  displayParam='name'
                  contents={season || []}
                  onContentClick={this.onSeasonSelect}
                  parentCompoent={'quick-filters'}
                  isVisible={shouldShowSeasonFilter}
                  selectedOption={selectedSeason}
                  multiSelect
                />
                <IconDropDown
                  icon={<StagesFilterIcon onClick={this.toggleStageFilter} color={selectedStage && selectedStage.length ? '#FF015A' : '#343A40'} />}
                  displayParam='name'
                  contents={stage || []}
                  onContentClick={this.onStageSelect}
                  parentCompoent={'quick-filters'}
                  isVisible={shouldShowStageFilter}
                  selectedOption={selectedStage}
                  multiSelect
                />
              </div>
              <div className='sidebar-button-container'>
                <TabPanel tabs={detailsTabs(mode)} selectedMode={mode} handleModeChange={this.handleModeChange} />
                <ButtonContainer displayTitle='Toggle Sidebar' childComponent={isSidebarVisible ? <SidebarCloseButton onClick={this.props.toggleSidebar} /> : <SidebarOpenButton onClick={this.props.toggleSidebar} />} />
              </div>
            </div>
          </div>
          { !isLoading && !isSearching && !isPaginating && (!matchDates || !matchDates.length) ? <Empty description={'No Match Found For This Filter'} /> : null }

          <div className='tables' ref={(node) => { this.containerRef = node }}>
            { isLoading || isSearching ? [...Array(10)].map((e, i) => <div className={`loading ${mode}`} key={i} >
              <span ><span /></span>
              <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading={isLoading || isSearching} />
            </div>) : null}
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMore}
              hasMore
              initialLoad={false}
              useWindow={false}
            >
              { !isLoading && !isSearching && matchDates && matchDates.length ? <div className='match-list-outer-container'>
                {matchDates.map((matchday, index) => {
                  return (<MatchListTable
                    key={index}
                    matchDate={matchday.date}
                    matches={matchday.matchList}
                    onSearchChange={this.onSearchChange}
                    onSelect={changedMatch}
                    matchSearch={searchString}
                    selectedMatchId={selectedMatchId}
                    matchFilter={matchFilter}
                    listAssetTypes={listAssetTypes}
                    isExpanded={mode === 'expand'}
                  />)
                })}
                { isPaginating ? [...Array(1)].map((e, i) => <div className={`loading ${mode}`} >
                  <span ><span /></span>
                  <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading={isPaginating} />
                </div>) : null}
              </div> : null }
            </InfiniteScroll>

          </div>

        </div>
      )
    }
}

MatchList.propTypes = {

}

export default withApollo(compose(
  graphql(
    QueryAllMatches,
    {
      options: ({ searchString, matchFilter }) => {
        if (!matchFilter) {
          matchFilter = {}
        }
        const variables = utilityService.getMatchManagerFilter(matchFilter, searchString)
        return {
          variables,
          fetchPolicy: 'network-only',
          returnPartialData: true
        }
      },
      props: (props) => {
        const { data } = props
        const matches = data && data.listMatches ? data.listMatches.items : null
        return {
          matches,
          isLoading: data.loading,
          totalCount: data.listMatches ? data.listMatches.totalCount : 0,
          getMatchList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.listMatches) return prev
                const newList = [ ...prev.listMatches.items, ...fetchMoreResult.listMatches.items ]
                prev.listMatches.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),

  graphql(
    QueryCompetition,
    {
      options: ({ competitionSearch, project }) => {
        return {
          variables: { limit: 999, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listCompetitions } }) => {
        let competition = listCompetitions && listCompetitions.items ? [ ...listCompetitions.items ] : []
        competition.unshift(allCompetitions)
        return {
          competition
        }
      }
    }
  ),
  graphql(
    QuerySeasonFilter,
    {
      options: ({ seasonSearch, project }) => {
        const variables = { limit: 999, offset: 0, project }
        if (seasonSearch) {
          variables.searchKey = seasonSearch
        }
        return {
          variables,
          fetchPolicy: 'network-only'

        }
      },
      props: (props) => {
        let season = props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? _.cloneDeep(props.data.getContentByField.items) : []
        season.unshift(allSeasons)
        return {
          season
        }
      }
    }
  ),
  graphql(
    QueryStageFilter,
    {
      options: ({ stageSearch }) => {
        const variables = { limit: 999, offset: 0 }
        if (stageSearch) {
          variables.searchKey = stageSearch
        }
        return {
          variables,
          fetchPolicy: 'network-only'

        }
      },
      props: (props) => {
        let stage = props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? _.cloneDeep(props.data.getContentByField.items) : []
        stage.unshift(allStages)
        return {
          stage
        }
      }
    }
  ),
  graphql(
    QueryListSports,
    {
      options: (props) => {
        const nextToken = props.nextToken ? props.nextToken : null
        return {
          variables: { limit: 999, nextToken },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let sport = data.listSports ? [ ...data.listSports.items ] : []
        const nextToken = data.listSports ? data.listSports.nextToken : null
        const previousToken = data.listSports ? data.listSports.previousToken : null

        return {
          sport,
          isLoading: data.loading,
          nextToken,
          previousToken,
          getMoreSports: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: nextToken
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newSportsList = fetchMoreResult.listSports ? [ ...fetchMoreResult.listSports.items ] : []
                let newList = [ ...prev.listSports.items, ...newSportsList ]
                newList = _.uniqBy(newList, 'id')
                if (_.isEqual(newList, prev.listSports.items)) { return prev }
                prev.listSports.items = newList
                prev.listSports.previousToken = prev.listSports.nextToken
                prev.listSports.nextToken = fetchMoreResult.listSports.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  )

)(withRouter(MatchList)))
