import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImageContent from './../../components/ui/dataEntry/other/ImageContent'
import LoadingButton from './../../components/ui/general/buttons/LoadingButton'
import { utilityService } from '../../services/UtilityService'
import { Tabs, Empty } from 'antd'
import _ from 'lodash'

const parentCompoent = 'asset-container'
const { TabPane } = Tabs

let primaryImageType
class AssetImages extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.uploadingFiles, this.props.uploadingFiles)) {
      this.imageContainerRef.scrollTop = this.imageContainerRef.scrollHeight + 500
    }
  }

  getFIlterImage = (images, imageType) => {
    return images.filter(image => {
      return imageType.id === primaryImageType ? (image.type.id === imageType.id || !image.type.id) : imageType.id === image.type.id
    })
  }

  renderImage = (imageType) => {
    const {
      images,
      onChangeDefaultImage,
      onCloseImage,
      showEditImage,
      // defaultMedia,
      isUpdateImageDisabled,
      uploadingFiles,
      mediaCategoryList,
      handleAssetImageCategoryChange
    } = this.props
    let imegeList = this.getFIlterImage(images, imageType, uploadingFiles)
    let filterdImageList = (imegeList || []).sort((a, b) => {
      const dateA = new Date(a.createdAt)
      const dateB = new Date(b.createdAt)
      return dateA - dateB
    })
    let defaultImage = filterdImageList.filter(item => item.isDefault === 'true')
    filterdImageList = filterdImageList.filter(item => item.isDefault !== 'true')
    if (defaultImage.length) { filterdImageList = [...defaultImage, ...filterdImageList] }
    return filterdImageList.length ? _.uniqBy(filterdImageList, 'id').map((image, index) => image ? (
      <ImageContent
        key={index}
        mediaId={image.id}
        imageDetails={image}
        id={image.id}
        isAssetList
        isDefaultImage={image.isDefault === 'true'}
        makeDefaultImage={onChangeDefaultImage}
        name={image.name}
        imageUrl={image.url}
        onCloseContent={() => onCloseImage(image.id)}
        parentCompoent={parentCompoent}
        showEditImage={showEditImage}
        isUpdateImageDisabled={isUpdateImageDisabled}
        aspectRatio={image.aspectRatio}
        mediaCategoryList={mediaCategoryList}
        handleAssetImageCategoryChange={handleAssetImageCategoryChange}
      />
    ) : null) : ((uploadingFiles.length && uploadingFiles.filter(image => (imageType.id === primaryImageType ? (image.imageType === imageType.id || !image.imageType) : imageType.id === image.imageType)).length) ? null : <Empty />)
  }

  renderUploadImage = (imageType) => {
    const { uploadingFiles, mediaCategoryList, handleAssetImageCategoryChange } = this.props
    return (uploadingFiles && uploadingFiles.length) ? uploadingFiles.filter(image => (imageType.id === primaryImageType ? (image.imageType === imageType.id || !image.imageType) : imageType.id === image.imageType))
      .map((uploadinImage) => {
        return <ImageContent
          isUploading
          key={utilityService.makeRandomString(7)}
          mediaId={uploadinImage.id}
          id={uploadinImage.id}
          name={uploadinImage.fileName}
          isAssetList
          parentCompoent={parentCompoent}
          showEditImage={false}
          isUpdateImageDisabled={false}
          imageDetails={uploadinImage}
          mediaCategoryList={mediaCategoryList}
          handleAssetImageCategoryChange={handleAssetImageCategoryChange}
        />
      }) : null
  }

  render () {
    const {
      onImageTypeChange,
      listImageTypes,
      isLoading,
      activeImageType,
      uploadingFiles
    } = this.props

    const sortedImageType = _.sortBy(listImageTypes, 'position')
    const imageUploadStatus = !_.isEmpty(uploadingFiles)
    return (
      <div className='image-details'>
        <Tabs onChange={onImageTypeChange} activeKey={activeImageType}>
          {(sortedImageType || []).map(imageType =>
            <TabPane tab={imageType.name} key={imageType.id} disabled={imageUploadStatus} >
              <div className='image-content-parent' ref={(ref) => { this.imageContainerRef = ref }}>
                {this.renderImage(imageType)}
                {this.renderUploadImage(imageType)}
              </div>
            </TabPane>
          )}
        </Tabs>
        {isLoading ? <div className='overlay'>
          <LoadingButton isLoading />
        </div> : ''}
        {/* <div style={{ width: '150px' }}>
          <Button
            buttonText={'Add Image'}
            color='red'
            onClick={() => showAddImages('images')}
            style={{ width: '150px' }}
            isDisabled={isButtonDisabled}
          />
        </div>
        <div style={{ width: '150px' }}>
          <Button
            buttonText={'Local Drive'}
            color='red'
            onClick={showAddImageFromLocal}
            style={{ width: '150px' }}
            isDisabled={isButtonDisabled}
          />
        </div> */}
      </div>
    )
  }
}

AssetImages.propTypes = {
  /** images of AssetImages. */
  images: PropTypes.array,
  /** default image of AssetImages. */
  defaultMedia: PropTypes.object,
  /** default image change action callback of AssetImages. */
  onChangeDefaultImage: PropTypes.func,
  /** close image action callback of AssetImages. */
  onCloseImage: PropTypes.func,
  /** close image action callback of AssetImages. */
  showAddImages: PropTypes.func,
  /** edit image action callback of AssetImages. */
  showEditImage: PropTypes.func,
  /** Boolean to disable button */
  isButtonDisabled: PropTypes.bool
}

export default AssetImages
