import gql from 'graphql-tag'

export default gql(`
query listAllAntiPiracyActions( $client: String! $project: String ) {
  listAllAntiPiracyActions( 
      client: $client,
      project: $project
    ) {
      statusCode
      error {
        code
        message
      }
      data {
        title
        key
        note
      
      }
    }
}`)
