import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Upload, message } from 'antd'
import LoadingButton from '../../general/buttons/LoadingButton'
import DropDown from '../inputs/DropDown'

import UploadQueueSystemTags from '../../dataEntry/other/UploadQueueSystemTags'
import LocalVideoUploader from '../../dataDisplay/LocalVideoUploader'
import { utilityService } from '../../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAllTags from '../../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../../graphQL/asset/createTag'
// import QueryTagContent from '../../../../graphQL/asset/getContentTags'
import QueryTagType from './../../../../graphQL/admin/tagType/listTagType'
import QueryListFolders from '../../../../graphQL/content/listFolders'

import './../../ui.style.scss'
import { validateUploadedFile } from '../../../../util/util'

const TabPane = Tabs.TabPane

class AddImageFromLocalFolderModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      uploadUrl: undefined,
      fname: '',
      ftype: '',
      file: null,
      selectedFolder: null,
      success: () => {},
      error: () => {},
      isCancelButtonDisabled: false,
      isTagCreate: false,
      searchString: '',
      selectedMedias: [],
      selectedTags: [],
      fileList: [],
      systemTags: []
    }
  }

  UNSAFE_componentWillReceiveProps=(nextProps) => { // eslint-disable-line camelcase
    if (nextProps.systemTags) {
      const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: enabledTags })
    }
  }

    clearState = () => {
      const state = {
        uploadUrl: undefined,
        fname: '',
        ftype: '',
        files: null,
        selectedFolder: null,
        success: () => {},
        error: () => {},
        isCancelButtonDisabled: false,
        isTagCreate: false,
        searchString: '',
        selectedMedias: [],
        selectedTags: [],
        fileList: []
      }
      this.setState({ ...state })
    }

    handleSubmit = () => {
      const { selectedTags, fileList, selectedFolder } = this.state
      let selectedTagsArr = _.cloneDeep(selectedTags)
      if (selectedFolder) {
        const folder = (this.props.folderList || []).find(item => item.id === selectedFolder)
        selectedTagsArr = _.uniqBy([...selectedTagsArr, ...folder.tags], 'key')
      }
      if (fileList && fileList.length) {
        const fileData = {
          tags: selectedTagsArr,
          files: fileList
        }
        this.props.handleSubmit(fileData)
      }
    }

    handleCancel = () => {
      this.props.handleCancel()
    }

    updatedImageName = (files) => {
      const newFiles = files.map(file => ({ fileName: file.name, contentType: file.type }))
      this.setState({ files: newFiles })
    }

    handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
      const { selectedTags } = this.state
      if (isDelete) {
        const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
        selectedTags.splice(index, 1)
      } else {
        const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
        if (index === -1) {
          if (isCustom) {
            const newTag = { name: value, module: 'CONTENT_BANK', type: 'MANUAL' }
            const id = Math.random().toString(36).substr(2, 9)
            selectedTags.push({ ...newTag, id, type })
            this.setState({ selectedTags, isTagCreate: true })
            this.props.createTag(newTag).then(response => {
              const tag = response.data.createTag
              const index = selectedTags.findIndex(item => item.id === id)
              selectedTags.splice(index, 1, tag)
              this.setState({ selectedTags, isTagCreate: false })
            }, error => {
              const index = (error.message || '').indexOf('ConditionalCheckFailedException')
              if (index > -1) {
                const tagIndex = selectedTags.findIndex(item => item.id === id)
                const newId = `${value}content_bank`
                selectedTags[tagIndex].key = newId
                this.setState({ selectedTags, isTagCreate: false })
                return
              }
              utilityService.handleError(error)
            })
          }
        } else {
          const newTag = (tagsList[index])
          const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
          if (!isPresent) {
            selectedTags.push(newTag)
          }
        }
      }
      this.setState({ selectedTags })
    }

    onReorder = (startIndex, endIndex) => {
      const { fileList } = this.state
      const result = Array.from(fileList)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      this.setState({ fileList: result })
      return result
    }

    handleDelete = (index, e) => {
      const { fileList } = this.state
      fileList.splice(index, 1)
      this.setState({ fileList })
    }

    onCategorySelect = (index, category) => {
      const { fileList } = this.state
      fileList[index].category = category
      this.setState({ fileList })
    }

    beforeUpload = (data, dataList) => {
      if (!data.size) {
        message.error(`${data.name} is an empty image`)
      }
      return validateUploadedFile(data).then((file) => {
        let { fileList } = this.state
        fileList = [...fileList, file]
        this.setState({ fileList })
        return true
      }).catch(() => {
        message.error(`${data.name} is an invalid image`)
        return Upload.LIST_IGNORE
      })
    }

    onSelectFolder = (selectedFolder) => {
      this.setState({ selectedFolder })
    }

    renderFooter = () => {
      const { handleCancel, isLoading, isSubmitButtonDisabled, folderList } = this.props
      const { isCancelButtonDisabled, selectedMedias, fileList, selectedFolder, isTagCreate } = this.state
      const isOkButtonDisabled = (!selectedMedias.length && !fileList.length) || isSubmitButtonDisabled || isTagCreate
      return <div className='footer-container'>
        <div className='folder-container'>
          <span>{'Upload to Folder:'}</span>
          <DropDown
            options={folderList}
            selectedValue={selectedFolder}
            placeHolder={`Select Folder`}
            valueParam='id'
            displayParam='name'
            allowClear={!!selectedFolder}
            onOptionSelect={this.onSelectFolder}
            className={selectedFolder ? 'selected' : ''} />
        </div>
        <div>
          <LoadingButton className='ant-btn'
            type={'button'}
            buttonText={'Cancel'} isDisabled={isCancelButtonDisabled}
            onClick={typeof handleCancel === 'function' ? handleCancel : this.handleCancel} />
          <LoadingButton className='ant-btn ant-btn-primary'
            buttonText={'Add Image'}
            isLoading={isLoading}
            onClick={this.handleSubmit}
            isDisabled={isOkButtonDisabled} />
        </div>
      </div>
    }

    render () {
      const { isVisible, handleCancel, isLoading, isSubmitButtonDisabled, isLocalFolderOpen, listMediaCategories, project } = this.props
      const { isCancelButtonDisabled, selectedMedias, isHidden, selectedTags, fileList, isTagCreate, systemTags } = this.state
      return (
        <Modal
          className={`confirm-modal add-video ${isHidden ? 'hidden' : ''}`}
          title={'ADD IMAGE - LOCAL DRIVE'}
          maskClosable={false}
          visible={isVisible}
          okText='Add Image'
          onOk={this.handleSubmit}
          onCancel={typeof handleCancel === 'function' ? handleCancel : this.handleCancel}
          okButtonProps={{ disabled: (!selectedMedias.length && !fileList.length) || isSubmitButtonDisabled || isTagCreate, loading: isLoading }}
          cancelButtonProps={{ disabled: isCancelButtonDisabled }}
          closable={false}
          centered
          width='907px'
          afterClose={this.clearState}
          destroyOnClose
          footer={this.renderFooter()}
        >
          <div id='upload-video-container' className='upload-image-from-local'>
            <Tabs defaultActiveKey='1' >
              <TabPane tab='Local Folder' key='1'>
                <LocalVideoUploader
                  beforeUpload={this.beforeUpload}
                  fileList={fileList}
                  onReorder={this.onReorder}
                  handleDelete={this.handleDelete}
                  isLocalFolderOpen={isLocalFolderOpen}
                  listMediaCategories={listMediaCategories}
                  onCategorySelect={this.onCategorySelect}
                  isImageType />
              </TabPane>
              <TabPane tab='Tags' key='2'>
                <UploadQueueSystemTags
                  parentCompoent={'upload-video-container'}
                  handleTagChange={this.handleTagChange}
                  systemTags={systemTags}
                  selectedTags={selectedTags}
                  project={project}
                />
              </TabPane>
            </Tabs>
          </div>

        </Modal>
      )
    }
}

AddImageFromLocalFolderModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool.isRequired,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Submit button disable status of Modal. */
  isSubmitButtonDisabled: PropTypes.bool,
  /**  Submit button disable status of Modal. */
  isCancelButtonDisabled: PropTypes.bool,
  /** List of images returned by the server. */
  imageList: PropTypes.array,
  /** Callback to be executed when image is selected for uploading */
  updatedImageName: PropTypes.func,
  /**  */
  currentSelection: PropTypes.array,
  /** Mutation function to create new media  */
  createMedia: PropTypes.func,
  /** Function to be called on search for file access */
  checkFileAccess: PropTypes.func,
  /** Function to be called for changing video selection */
  onChangeModal: PropTypes.func
}

AddImageFromLocalFolderModal.defaultProps = {
  selectedValue: 'Select'
}

export default withApollo(
  compose(
    graphql(MutationCreateTag, {
      // TODO: The code for MutationCreateTag could be consolidated to keep the code DRY
      options: ({ project }) => {
        return {
          update: (cache, { data: { createTag } }) => {
            const filter = { type: { match: 'MANUAL' }, module: { exact: 'CONTENT_BANK' } }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },
      props: props => ({
        createTag: contentObj => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
    /* graphql(
      QueryTagContent,
      {
        options: ({ project }) => {
          return {
            variables: { project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
          }
        }
      }
    ), */
    graphql(
      QueryTagType,
      {
        options: ({ project }) => {
          return {
            fetchPolicy: 'network-only',
            variables: { filter: null, limit: 500, project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    ),
    graphql(
      QueryListFolders,
      {
        options: ({ project }) => {
          const variables = { limit: 200, nextToken: null, project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: ({ data }) => {
          return {
            folderList: data && data.listFolders && data.listFolders.items ? _.map(data.listFolders.items, _.clone) : []
          }
        }
      }
    )
  )(AddImageFromLocalFolderModal)
)
