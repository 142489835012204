import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import InformationIcon from '../../general/icons/InformationIcon'
import { Tooltip } from 'antd'

const formatInputChange = (e, handleChange) => {
  const { value } = e.target
  const regexExp = /^\d*[0-9]\d*$/
  const isNum = regexExp.test(value)
  if (isNum || !value) { handleChange(value) }
}

const preventKey = (e) => {
  let checkIfNum
  if (e.key !== undefined) {
    checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'E'
  } else if (e.keyCode !== undefined) {
    checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189
  }
  return checkIfNum && e.preventDefault()
}

const preventPaste = (e) => {
  const value = e.clipboardData.getData('text/plain')
  const regexExp = /^\d*[0-9]\d*$/
  const isNum = regexExp.test(value)
  if (!isNum) { e.preventDefault() }
}

const Input = ({ handleChange, tooltip, value, placeholder, title, inputName, isEditable, isLimited, limit, isError, onBlur, onFocus, errorMessage, isRequired, readOnly, ...props }) => (<div className={`input-field ${isError ? 'error' : ''}${readOnly ? ' readOnly' : ''}`}>
  <label>{ title } </label>{isRequired ? <span className='mandatory'>*</span> : ''}
  { tooltip &&
  <Tooltip placement='right' title={tooltip} >
    <span className='asset-info-icon'><InformationIcon /></span>
  </Tooltip>
  }
  { isLimited ? <label className='limit'><span>Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
  <input name={inputName} value={(value === undefined || value === null) ? '' : value} onChange={(event) => formatInputChange(event, handleChange)} placeholder={placeholder} maxLength={limit} onBlur={onBlur} onFocus={onFocus} autoComplete='off'
    readOnly={readOnly} type={'number'} onKeyDown={preventKey} onPaste={preventPaste} className='number' {...props} />
  {isError ? <p>{ errorMessage } </p> : null }
</div>
)

Input.propTypes = {
  /** Change action of Input. */
  handleChange: PropTypes.func,
  /** on focus action of Input. */
  onFocus: PropTypes.func,
  /** out of focus action of Input. */
  onBlur: PropTypes.func,
  /** display value of Input. */
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  /** placeholder of Input. */
  placeholder: PropTypes.string,
  /** title of Input. */
  title: PropTypes.any,
  /** inut field name of Input. */
  inputName: PropTypes.string,
  /** editable status of Input. */
  isEditable: PropTypes.bool,
  /** error field status of Input. */
  isError: PropTypes.bool,
  /** limited input field status of Input. */
  isLimited: PropTypes.bool,
  /** string limit of Input. */
  limit: PropTypes.number,
  /** requierd field or not */
  isRequired: PropTypes.bool,
  /** Boolean value for readonly props */
  readOnly: PropTypes.bool,
  /** Type of input  */
  type: PropTypes.string
}

Input.defaultProps = {
  isEditable: true,
  readOnly: false,
  type: 'number',
  placeholder: 'Enter value',
  value: ''
}

export default Input
