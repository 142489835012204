import React, { Component } from 'react'

import { generateCroppedThumbnail } from '../../util/util'
import Accordion from '../../components/ui/dataDisplay/Accordion'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCompetition from '../../graphQL/asset/listCompetition'
import QueryListAssetType from '../../graphQL/admin/asset/listAssetTypes'

class DataList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      competitions: []
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (!_.isEmpty(newProps.competitions)) {
      this.setState({
        competitions: newProps.competitions ? _.cloneDeep(newProps.competitions) : []
      })
    }
  }

  getSeasonTemplates = type => {
    const { onSelect, selectedDataId, listAssetTypes } = this.props

    const types = [{
      title: 'DEFAULT'
    }, ...listAssetTypes]
    return (types || []).map(asset => <div key={asset.title} className={`data-type ${selectedDataId === `${type}-competiton-${asset.title}` ? 'active' : ''}`} onClick={() => onSelect(asset.title, type)}>
      { asset.icon ? <img src={generateCroppedThumbnail(asset.icon, 18, 18, '1:1')} /> : ''}
      <p>{ asset.title }</p>
    </div>)
  }

  render () {
    const { onSelect, selectedDataId, dataTypes, selectedDisplayId } = this.props
    const { competitions } = this.state
    return (
      <div className='data-list'>
        {dataTypes
          ? dataTypes.map((type, outerIndex) => {
            if (type.id === 'match-template') {
              return (
                <Accordion
                  key={outerIndex}
                  isFirstClose={selectedDisplayId !== 'match-template'}
                  title={type.displayName} hideCollapse={!(competitions && competitions.length)}
                  childComponent={competitions && competitions.length ? competitions.map((item, index) => {
                    return <Accordion title={item.title} isFirstClose childComponent={this.getSeasonTemplates(item.id)} key={index} />
                  }) : null
                  }
                />
              )
            }
            if (type.child && type.child.length) {
              const isClosed = (type.child || []).findIndex(item => item.id === selectedDisplayId) === -1
              return (
                <Accordion
                  key={outerIndex}
                  isFirstClose={isClosed}
                  title={type.displayName} hideCollapse={!(type.child && type.child.length)}
                  childComponent={type.child && type.child.length ? type.child.map((item, index) => {
                    return <div className={`data-type ${selectedDataId === item.id ? 'active' : ''}`} onClick={() => onSelect(item.id)} key={index}> {item.name} </div>
                  }) : null
                  }
                />
              )
            } else {
              return <div key={outerIndex} className={`admin-title ${selectedDataId === type.id ? 'active' : ''}`} onClick={() => onSelect(type.id)} >
                {type.displayName}
              </div>
            }
          })
          : null}
      </div>
    )
  }
}

DataList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCompetition,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 999, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listCompetitions } }) => {
        let competitions = listCompetitions && listCompetitions.items ? [ ...listCompetitions.items ] : []
        return {
          competitions
        }
      }
    }
  ),
  graphql(
    QueryListAssetType,
    {
      options: ({ project }) => {
        const filter = {
          isMatchRelated: true
        }
        const variables = { limit: 500, nextToken: null, filter, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes
        }
      }
    }
  )

)(DataList))
