import React from 'react'
// import PropTypes from 'prop-types'
import { InputNumber } from 'antd'

import Switch from '../dataEntry/inputs/Switch'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import './../ui.style.scss'

class AddSquad extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {}
    }
  }

  render () {
    const { details } = this.state
    const { project } = this.props
    return (
      <div className='add-squad'>
        <div className='team-image-vertical'>
          <div className='team'>
            <label> Home </label>
            <div className='image'>
              <img src={require('../../../assets/images/avatar-team-icon.png')} />
            </div>
          </div>
          <div className='team'>
            <label> Away </label>
            <div className='image'>
              <img src={require('../../../assets/images/avatar-team-icon.png')} />
            </div>
          </div>
        </div>
        <div className='data'>
          <div className='custom-data'>
            <div className='details'>
              <Input title='Opta ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='First Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title=' Last Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='Match Name' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='Nationality' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='Nationality ID' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='Position' placeholder='Write a title...' inputName='title' value={details.position} handleChange={() => { }} />
            </div>
            <div className='details'>
              <Input title='Type' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
            </div>
            <div className='details' id='details'>
              <label>Jersey Number</label>
              <InputNumber min={1} value={details.jerseyNo} onChange={() => { }} />
            </div>
          </div>
          <div className='custom-tags' id='custom-tags'>
            <label> Tags </label>
            <TagsInputContainer
              selectedItems={[]}
              onChange={() => {}}
              placeholder='Insert Tags'
              parentCompoent={'custom-tags'}
              displayParameter='name'
              onSearch={() => {}}
              project={project}
            />
          </div>
          <div className='type'>
            <span>Active</span>
            <div className='information-icon'>
              <InformationIcon />
            </div>
            <Switch isChecked={false} onChange={() => { }} />
          </div>

        </div>
      </div>
    )
  }
}

AddSquad.propTypes = {

}

AddSquad.defaultProps = {
}

export default AddSquad
