import gql from 'graphql-tag'

export default gql(`
query listChannel($filter: TableChannelFilterInput $search: SearchInput){
    listChannels(
        limit: 100,
        filter: $filter
        search: $search
    ){
        items{
            id
            name
            channelCode            
            isArchived
            site
            masterConfig {
                id
                name
            }
            icon{
                id
                url
                tags{
                    id : key
                    name
                }
                name
                fileName
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
            }
            activeConfigGroup{
                id
                name
            }
            programs{
                previous{
                    id
                    shortId
                    title
                    externalId
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                    program{
                        id
                        broadCastStartTime
                        broadCastEndTime
                    }
                        match{
                            id
                            kickOffTime
                            finishTime
                            matchDate
                            opId
                            externalId
                            homeTeam {
                                id
                                officialName
                            }
                            awayTeam {
                                id
                                officialName     
                            }
                        }
                }
                current{
                    id
                    shortId
                    title
                    externalId
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                    program{
                        id
                        broadCastStartTime
                        broadCastEndTime
                    }
                        match{
                            id
                            kickOffTime
                            finishTime
                            matchDate
                            opId
                            externalId
                            homeTeam {
                                id
                                officialName
                            }
                            awayTeam {
                                id
                                officialName     
                            }
                        }
                }
                next{
                    id
                    shortId
                    title
                    externalId
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                    program{
                        id
                        broadCastStartTime
                        broadCastEndTime
                    }
                    match{
                        id
                        kickOffTime
                        finishTime
                        matchDate
                        opId
                        externalId
                        homeTeam {
                            id
                            officialName
                        }
                        awayTeam {
                            id
                            officialName     
                        }
                    }
                }
            }
            defaultTrimStart
            defaultTrimEnd
        }
    }
}`)
