import React, { Component } from 'react'
import { Table, Empty, Skeleton } from 'antd'
import { Resizable } from 'react-resizable'

import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListViewHistory from '../../../graphQL/customers/listViewHistory'
import { getFormattedDuration } from '../../../util/util'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class ViewHistoryTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [

      {
        title: 'Asset ID',
        dataIndex: 'id',
        width: 100,
        key: 'assetId',
        className: 'id',
        render: (id, data) => this.renderAssetId(id, data)
      },
      {
        title: 'Asset Title',
        dataIndex: 'assetId.title',
        key: 'title',
        className: 'name',
        width: 200
      },
      {
        title: 'Position',
        dataIndex: 'progress.position',
        key: 'position',
        className: 'position',
        render: (id, data) => this.renderPosition(id, data),
        width: 100
      },
      {
        title: 'Updated At',
        dataIndex: 'id',
        width: 100,
        key: 'updatedAt',
        className: 'update-date',
        render: (id, data) => this.renderUpdatedDate(id, data)
      }
    ]
    this.state = {
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
  }

  renderUpdatedDate = (id, { progress }) => {
    return progress && progress.updatedAt
      ? moment(new Date(progress.updatedAt)).format('DD MMM YYYY, HH:mm:ss') : ''
  }

  renderAssetId = (id, { assetId }) => {
    return <div className='stream-session-asset'>
      {assetId && assetId.id ? <a className='asset-id' target='_blank' href={`${location.origin}/assets/${assetId.id}`} >{ ` ${assetId.id}` }</a> : '' }
    </div>
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  renderPosition = (id, data) => {
    return data && data.progress && data.progress.position ? getFormattedDuration(parseInt(data.progress.position / 1000)) : '-'
  }

  render () {
    const { viewHistory, isLoading } = this.props
    const { height, columns } = this.state

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))

    return (
      <div className='customer-details-table view-history-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(viewHistory) ? <Table components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={viewHistory} pagination={false} scroll={{ y: (height - 45) }} />
            : <Empty /> }
        </Skeleton>
      </div>
    )
  }
}

ViewHistoryTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListViewHistory,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer } = props
        const variables = { userId: selectedCustomer }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const viewHistory = data && data.listViewHistory ? data.listViewHistory : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { viewHistory, isLoading: data && data.loading }
      }
    }
  )
)(ViewHistoryTable))
