import { useEffect, useRef, useState } from 'react'
import bitmovinPlayer from '../bitmovin'

const usePlayerTime = () => {
  const timeWatcher = useRef(null)
  const [pRate, setpRate] = useState(0)
  const [bRate, setbRate] = useState(0)
  const [isPlayingLiveEnd, setIsPlayingLiveEnd] = useState(true)
  const [timeElapsed, setTimeElapsed] = useState(0)

  const updateBufferRate = () => {
    setbRate(() => {
      return (bitmovinPlayer.getBuffer() * 100) / bitmovinPlayer.getDuration()
    })
  }

  const updatePlayRate = () => {
    setpRate(() => {
      if (bitmovinPlayer.isLive()) {
        return (1 - bitmovinPlayer.getTimeShift() / bitmovinPlayer.getMaxTimeShift()) * 100
      } else {
        setTimeElapsed(bitmovinPlayer.getPosition())
        return (bitmovinPlayer.getPosition() * 100) / bitmovinPlayer.getDuration()
      }
    })
  }

  const checkIsPlayingLiveEnd = () => {
    setIsPlayingLiveEnd(() => {
      return bitmovinPlayer.getTimeShift() === 0
    })
  }

  useEffect(() => {
    if (timeWatcher.current) clearInterval(timeWatcher.current)
    timeWatcher.current = setInterval(() => {
      if (bitmovinPlayer.isLive()) {
        checkIsPlayingLiveEnd()
      }
      updateBufferRate()
      updatePlayRate()
    }, 100)
    return () => clearInterval(timeWatcher.current)
  }, [])

  const getRelativePlayackTime = seekPercentage => {
    if (bitmovinPlayer.isLive()) {
      const timeShift = (1 - seekPercentage) * bitmovinPlayer.getMaxTimeShift()
      return timeShift
    } else {
      const seekTime = seekPercentage * bitmovinPlayer.getDuration()
      return seekTime
    }
  }

  const seek = seekPercentage => {
    if (bitmovinPlayer.isLive()) {
      bitmovinPlayer.timeShift(getRelativePlayackTime(seekPercentage))
    } else {
      bitmovinPlayer.seek(getRelativePlayackTime(seekPercentage))
    }
  }

  return { pRate, bRate, seek, isPlayingLiveEnd, timeElapsed, getRelativePlayackTime }
}

export default usePlayerTime
