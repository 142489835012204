import React, { Component } from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import PropTypes from 'prop-types'

import AppContext from '../../../AppContext'

import BucketGroupDropDown from './../../../components/ui/dataEntry/inputs/BucketGroupDropDown'
import { utilityService } from '../../../services/UtilityService'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'

import './../../../components/ui/ui.style.scss'

import QueryGetBucketGroupList from './../../../graphQL/bucket/getBucketGroupList'
import QueryGetDefaultBucketGroup from './../../../graphQL/admin/getModuleConfig'
import MutationUpdateDefaultBucketGroup from './../../../graphQL/admin/entertainmentData/updateModuleConfig'
import { message } from 'antd'

const parentCompoent = 'match-status-management-container'

class BucketSettings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchValue: '',
      listBucketGroup: props.bucketGroupList && props.bucketGroupList.length ? props.bucketGroupList : [],
      isEdit: false,
      isLoading: false,
      defaultBucketGroup: null
    }
  }

  componentDidMount=() => {
    if (this.props.defaultBucketGroup) {
      this.setState({
        defaultBucketGroup: {
          id: this.props.defaultBucketGroup
        }
      })
    }
    this.props.setContentId(this.props.project + '_default_bucket_group')
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps && newProps.bucketGroupList && !_.isEqual(this.props.bucketGroupList, newProps.bucketGroupList)) {
      this.setState({
        listBucketGroup: newProps.bucketGroupList
      })
    }
    if (newProps && newProps.defaultBucketGroup && !_.isEqual(this.props.defaultBucketGroup, newProps.defaultBucketGroup)) {
      this.setState({
        defaultBucketGroup: {
          id: newProps.defaultBucketGroup
        }
      })
    }
  }

  onDefaultBucketGroupChange = (id) => {
    const { bucketGroupList } = this.props
    const selectedBucketGroup = (bucketGroupList || []).find(item => item.id === id)
    if (selectedBucketGroup) {
      this.setState({ defaultBucketGroup: selectedBucketGroup, isEdit: true })
    } else {
      this.setState({ defaultBucketGroup: null, isEdit: true })
    }
  }

  updateStatus = () => {
    const { onHistoryUpdate, project } = this.props
    this.setState({ isLoading: true })
    const { defaultBucketGroup } = this.state
    // const newStatus = utilityService.getUniqueArr(status)
    const variables = {
      id: project + '_default_bucket_group',
      value: defaultBucketGroup ? [defaultBucketGroup.id] : [],
      module: project + '_default_bucket_group',
      project: project
    }
    this.props.updateDefaultBucketGroup(variables).then(() => {
      if (onHistoryUpdate) {
        onHistoryUpdate()
      }
      this.setState({ isEdit: false, isLoading: false })
      message.success('Bucket Settings Updated Successfully')
    }, errorMsg => {
      this.setState({ isLoading: false })
      utilityService.handleError(errorMsg)
    })
  }

  onBucketGroupSearch=(value) => {
    this.setState({ searchValue: value })
  }

  render () {
    const { defaultBucketGroup, isEdit, isLoading } = this.state
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return (
            <div className='admin-portal bucket-setting-status-list' id='match-status-management-container'>
              <div className='input-field'>
                <label className='input-title'>Bucket Settings &gt; Default Bucket Group</label>
                <div className='input-dropdown'>
                  <BucketGroupDropDown
                    selectedValue={defaultBucketGroup ? defaultBucketGroup.id : ''}
                    className='recurrence-drop-down'
                    options={this.props.bucketGroupList || []}
                    valueParam='id'
                    displayParam='name'
                    onOptionSelect={this.onDefaultBucketGroupChange}
                    placeHolder='Select Default Bucket Group'
                    parent={parentCompoent}
                    onBucketGroupSearch={this.onBucketGroupSearch}
                    allowClear
                  />
                </div>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.updateStatus}
                  htmlType='submit'
                  buttonText={'Update'}
                  buttonClass='save-btn'
                  isLoading={isLoading}
                  isDisabled={isUpdateDisabled || !isEdit}
                />
              </div>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

BucketSettings.propTypes = {
  /** project details */
  project: PropTypes.string
}

BucketSettings.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryGetBucketGroupList,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { limit: 300, project }
        }
      },
      props: (props) => {
        const bucketGroupList = props.data && props.data.listBucketGroups ? props.data.listBucketGroups.items : []
        return {
          bucketGroupList
        //   subscribeToNewBucketGroup: () => {
        //     return props.data.subscribeToMore({
        //       document: SubscriptionBucketGroupList,
        //       updateQuery: (prev, { subscriptionData: { data: { bucketGroupCreated } } }) => {
        //         const { project } = props.ownProps
        //         if (!bucketGroupCreated || (project !== bucketGroupCreated.project)) return prev
        //         const index = prev.listBucketGroups.items.findIndex(group => group.id === bucketGroupCreated.id)
        //         if (index !== -1) {
        //           prev.listBucketGroups.items.splice(index, 1)
        //         }
        //         const newList = [ bucketGroupCreated, ...prev.listBucketGroups.items ]
        //         prev.listBucketGroups.items = newList
        //         // prev.listBucketGroups.totalCount = prev.listBucketGroups.totalCount + 1
        //         return prev
        //       }
        //     })
        //   }
          // subscribeToChangeBucketGroup: () => {
          //   return props.data.subscribeToMore({
          //     document: SubscriptionUpdateBucketGroupList,
          //     updateQuery: (prev, { subscriptionData: { data: { bucketGroupUpdated } } }) => {
          //       if (!bucketGroupUpdated || !bucketGroupUpdated.isArchived) return prev
          //         const index = prev.listBucketGroups.items.findIndex(group => group.id === bucketGroupUpdated.id)
          //         debugger
          //         if (index !== -1) {
          //           prev.listBucketGroups.items.splice(index, 1)
          //         }
          //       return prev
          //     }
          //   })
          // }
        }
      }
    }
  ),
  graphql(
    MutationUpdateDefaultBucketGroup,
    {
      props: (props) => ({
        updateDefaultBucketGroup: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryGetDefaultBucketGroup,
    {
      options: (props) => {
        return {
          fetchPolicy: 'network-only',
          variables: { id: props.project + '_default_bucket_group' }
        }
      },
      props: ({ data }) => {
        return {
          defaultBucketGroup: data && data.getConfig ? data.getConfig.value && data.getConfig.value[0] : ''
          //   timeout: data && data.getConfig ? data.getConfig.value && data.getConfig.value[1] : ''
        }
      }
    }
  )
)(withApollo(BucketSettings)))
