import gql from 'graphql-tag'

export default gql(`
    mutation media($id: ID! $assetId: ID! $settings: [CreateImageSettingInput] $mediaCategory: ID) {
        updateAssetMediaLink(input:{
            assetId: $assetId
            mediaId: $id
            settings: $settings
            mediaCategory: $mediaCategory
        }) {
            id
            fileName
            createdAt
            name
            type
            fileSize
            duration
            thumbnails
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions{
                x1
                x2
                y1
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
            drmContent
            streams{
                id
                contentKey
                Url
                drm
                iv 
                codec
                transport
                channel {
                    id
                }
                PackagingConfigurationId
            }
            _originalResponseBody
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
        }
    }
`)
