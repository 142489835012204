import React from 'react'
import PropTypes from 'prop-types'

const SidebarIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M19 17.778V2.222C19 .994 17.993 0 16.75 0H3.25C2.007 0 1 .994 1 2.222v13.445h2.25V2.222H13.5v15.556H3.25v-4.445H1v4.445C1 19.006 2.007 20 3.25 20h13.5c1.243 0 2.25-.994 2.25-2.222z' />
    </g>
  </svg>
)

SidebarIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string
}

SidebarIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default SidebarIcon
