import gql from 'graphql-tag'

export default gql(`
subscription{
    bucketGroupUpdated{
            id
            name
            type
            key
            isArchived
            bucketGroupItems{
                id
                bucketId
                position
                bucket{
                    displayName
                    name
                    key
                    id
                    isArchived
                }
            }
        } 
    }
`)
