import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'
import _ from 'lodash'
class RoleAuthLogCell extends React.Component {
  render () {
    const { details } = this.props
    const {
      user,
      userAgent,
      userEvent,
      ip,
      time,
      errorMessage
    } = details

    return (
      <div className='role-auth-log-cell role-auth-log-general' >
        <div className='date'> {moment(time).format('DD MMM YYYY HH:mm')} </div>
        <div className='date'> {user} </div>
        {_.size(userEvent) > 22
          ? <div className='date'>
            <Tooltip placement='topLeft' title={userEvent}>
              {userEvent}
            </Tooltip></div>
          : <div className='date'>
            {userEvent}
          </div>
        }
        {_.size(ip) > 15
          ? <div className='date'>
            <Tooltip placement='topLeft' title={ip}>
              {ip}
            </Tooltip></div>
          : <div className='date'>
            {ip}
          </div>
        }
        {_.size(userAgent) > 22
          ? <div className='date'>
            <Tooltip placement='topLeft' title={userAgent}>
              {userAgent}
            </Tooltip></div>
          : <div className='date'>
            {userAgent}
          </div>
        }
        <div className='details'> {errorMessage || '-' } </div>
      </div>
    )
  }
}

RoleAuthLogCell.defaultProps = {
  details: {
    user: 'David Nelson',
    date: '28 Nov 2018, 12:00',
    description: 'David Nelson created an Article “Football mourns the tragic ending for a man who created a miracle and so much hope”'
  }
}

export default RoleAuthLogCell
