import React from 'react'
import PropTypes from 'prop-types'

const AlertIcon = ({ width, height, isLive, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill={isLive ? '#f5212d' : '#fdbd39'} fillRule='nonzero' d='M10.273 14.047v-1.94a.33.33 0 0 0-.095-.24.305.305 0 0 0-.226-.098H8.028a.305.305 0 0 0-.226.097.33.33 0 0 0-.095.24v1.941a.33.33 0 0 0 .095.24.305.305 0 0 0 .226.098h1.924a.305.305 0 0 0 .226-.097.33.33 0 0 0 .095-.24zm-.02-3.82l.18-4.69a.226.226 0 0 0-.1-.194c-.087-.075-.167-.112-.24-.112H7.888c-.074 0-.154.037-.24.112-.068.048-.101.12-.101.215l.17 4.669c0 .068.034.124.1.168a.426.426 0 0 0 .241.067h1.854a.402.402 0 0 0 .236-.067.228.228 0 0 0 .105-.168zm-.14-9.543L17.81 15.07c.234.43.227.858-.02 1.287a1.271 1.271 0 0 1-1.103.644H1.293c-.227 0-.44-.058-.636-.174a1.271 1.271 0 0 1-.466-.47c-.248-.429-.254-.858-.02-1.287L7.868.684c.113-.21.27-.378.47-.5C8.54.06 8.757 0 8.99 0c.234 0 .451.061.652.184.2.122.357.29.47.5z' />
  </svg>
)

AlertIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

AlertIcon.defaultProps = {
  width: 18,
  height: 17
}

export default AlertIcon
