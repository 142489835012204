import React from 'react'
import { Menu, Item, contextMenu } from 'react-contexify'
import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import 'react-contexify/dist/ReactContexify.css'
import _ from 'lodash'
import DragAndDropIcon from '../general/icons/DragAndDropIcon'

import './../ui.style.scss'

// import styled from '@emotion/styled'
// import { colors } from '@atlaskit/theme'

import WidgetListDetailsCell from './WidgetListDetailsCell'
import AddObjectModal from '../dataEntry/other/AddObjectModal'

export const grid = 8
export const borderRadius = 2

// const TaskList = styled.div`
//   transition: background-color 0.2s ease;
// `

// const primaryButton = 0

// const Container = styled.div`

//   ${props =>
//     props.isSelected ? `` : ''}
//     ${props =>
//     props.isGhosting
//       ? 'opacity: 0.5;'
//       : ''}

//   /* needed for SelectionCount */
//   position: relative;

//   /* avoid default outline which looks lame with the position: absolute; */
//   &:focus {
//     outline: none;
//     border-color: ${colors.G200};
//   }
// `
/* stylelint-enable */
// const size = 30

// const SelectionCount = styled.div`
//   right: -${grid}px;
//   top: -${grid}px;
//   color: ${colors.N0};
//   background: ${colors.N200};
//   border-radius: 50%;
//   height: ${size}px;
//   width: ${size}px;
//   line-height: ${size}px;
//   position: absolute;
//   text-align: center;
//   font-size: 0.8rem;
// `

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  border: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

const primaryButton = 0

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9
}
// let primaryImageType

class WidgetDetailList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedId: '',
      isFilterOpen: false,
      uploadingImage: [],
      selectedTaskIds: [],
      showAddImageModal: false,
      showEditIcon: false,
      showAddBucketModal: false,
      selectedBucket: null,
      showImgEditModal: false,
      selectedBucketConfig: null,
      selectedImgConfig: null,
      selectedImgEditConfig: null,
      editImageDetails: null
    }
  }

  isFilterOpen = (isFilterOpen) => {
    this.setState({ isFilterOpen })
  }

  menuWidget = (menuId, item) => {
    return (
      <Menu id={menuId}>
        <Item onClick={() => this.props.handlePageLayoutItemEdit(item)}> {'Edit'}</Item>
        <Item onClick={() => this.props.handlePageLayoutItemDelete(item)}> {'Remove Item'}</Item>
        <Item onClick={() => this.props.handlePageLayoutItemPin(item)}> {`${item.isPinned ? 'UnPin Widget' : 'Pin Widget'}`}</Item>
      </Menu>
    )
  }

  saveCurrentImageSelection = (config, imageDetails) => {
    this.setState({ showAddImageModal: false })
    this.props.handleWidgetDetailsChange(config, imageDetails)
  }

  showImgEditorIcon = (selectedImgEditConfig, editImageDetails) => {
    this.setState({
      showEditIcon: true,
      selectedImgEditConfig: selectedImgEditConfig,
      editImageDetails: editImageDetails
    })
  }

  hideImgEditorIcon = () => {
    this.setState({
      showEditIcon: false,
      selectedImgEditConfig: null
    })
  }

  hideAddImageModal = () => {
    this.setState({ showAddImageModal: false })
  }

  handleShowAddImageModal = (selectedImgConfig) => {
    this.setState({ selectedImgConfig: selectedImgConfig, showAddImageModal: true })
  }

  handleShowBucketModal = (selectedBucketConfig) => {
    this.setState({ selectedBucketConfig: selectedBucketConfig, showAddBucketModal: true })
  }

  hideAddBucketModal = () => {
    this.setState({ showAddBucketModal: false })
  }

  handleBucketSelection = (selectedBucket) => {
    this.setState({
      selectedBucket
    })
  }

  saveCurrentBucketSelection = (config) => {
    const { selectedBucket } = this.state
    this.setState({ showAddBucketModal: false, selectedBucket: null })
    this.props.handleWidgetDetailsChange(config, selectedBucket)
  }

  showImgEditor = () => {
    this.setState({
      showImgEditModal: true
    })
  }

  hideImgEditor = () => {
    const { editWidgetInstance } = this.props
    this.setState({
      showImgEditModal: false,
      editImageDetails: null
    })
    if (this.props.fetchAppPageDetails) {
      this.props.fetchAppPageDetails()
    }

    setTimeout(() => {
      this.onConfigImgCropInstanceEdit(editWidgetInstance.id)
    }, 1000)
  }

  onConfigImgCropInstanceEdit = (id) => {
    const { listData } = this.props
    let editWidgetInstance = listData.filter(widget => widget.id === id)[0]
    this.props.handleListWidgetImgCropInstanceEdit(editWidgetInstance)
  }

  onKeyDown = (
    event,
    provided,
    snapshot,
    item
  ) => {
    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.keyCode !== keyCodes.enter) {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    this.performAction(event, item)
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  onClick = (event, item) => {
    if (event.target.className === 'react-contexify__item__content' || event.target.className === 'ant-switch' || event.target.className.includes('ant-switch-checked')) {
      return
    }

    if (event.button !== primaryButton) {
      return
    }

    // marking the event as used
    event.preventDefault()

    this.performAction(event, item)
  };

  onTouchEnd = (event, item) => {
    if (event.defaultPrevented) {
      return
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault()
    this.props.toggleSelectionInGroup(item.id)
  };

   // Determines if the platform specific toggle selection in group key was used
   wasToggleInSelectionGroupKeyUsed = (event) => {
     // const isUsingWindows = navigator.platform.indexOf('Win') >= 0
     // return isUsingWindows ? event.ctrlKey : event.metaKey
     const isUsingMac = navigator.platform.indexOf('Mac') >= 0
     return isUsingMac ? event.metaKey : event.ctrlKey
   };

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = (event) => event.shiftKey;

  performAction = (event, item) => {
    const {
      toggleSelection,
      toggleSelectionInGroup,
      multiSelectTo
    } = this.props

    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(item.id)
      return
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      multiSelectTo(item.id)
      return
    }

    toggleSelection(item.id)
  }

  onWindowKeyDown = (event) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.key === 'Escape') {
      this.unselectAll()
    }
  }

  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }
    this.unselectAll()
  }

  onWindowTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return
    }
    this.unselectAll()
  }

  unselect = () => {
    this.unselectAll()
  }

  unselectAll = () => {
    this.setState({
      selectedTaskIds: []
    })
  }

  UNSAFE_componentWillReceiveProps=(newProps) => { // eslint-disable-line camelcase
    if (newProps.selectedTaskIds && !_.isEqual(newProps.selectedTaskIds, this.state.selectedTaskIds)) {
      this.setState({
        selectedTaskIds: newProps.selectedTaskIds
      })
    }
  }

  render () {
    const {
      listData,
      pageId,
      saveLoading,
      isUpdateBlocked,
      draggingTaskId,
      showWidgetModal,
      hideAddWidgetObjectModal,
      draggable,
      handleWidgetDetailsChange,
      pageConfigDetails,
      handleWidgetObjectSave,
      isWidgetObjectSaveLoading,
      isWidgetObjectSaveDisable,
      project,
      isWidgetEdit,
      editWidgetInstance,
      handleLayoutItemStatus,
      jsonArrayInvalidEntries,
      jsonInvalidEntries,
      isJsonArrayValid,
      isJsonArrayVisible,
      jsonConfig,
      jsonSelectedMeta,
      handleJsonArrayVisibilty,
      handleJsonArrayHide
    } = this.props
    const {
      isFilterOpen,
      selectedTaskIds,
      showAddImageModal,
      showEditIcon,
      showAddBucketModal,
      selectedBucket,
      showImgEditModal,
      selectedImgConfig,
      selectedImgEditConfig,
      selectedBucketConfig,
      editImageDetails
    } = this.state
    return (
      <div className='widget-detail-list' ref={node => { this.bucketScrollContainer = node }}>
        <div className='widget-detail'>
          <Droppable droppableId={'#Page_' + pageId} isDropDisabled={saveLoading}>
            {(droppableProvided, droppableSnapshot) => (
              <div className={`widget-list-container ${!_.isEmpty(listData) ? 'list-data' : ''}`}
                ref={droppableProvided.innerRef}
                isDraggingOver={droppableSnapshot.isDraggingOver}
                {...droppableProvided.droppableProps}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {(listData || []).map((item, index) => {
                  const isSelected = selectedTaskIds && !_.isEmpty(selectedTaskIds) && Boolean(
                    selectedTaskIds.includes(item.id)
                  )
                  const isGhosting =
                    isSelected &&
                    Boolean(draggingTaskId) &&
                    draggingTaskId !== item.id
                  return (
                    <Draggable key={item.id} draggableId={item.id + `widget-item` + index + 1} index={index} isDragDisabled={isFilterOpen || isUpdateBlocked}>
                      {(provided, snapshot) => {
                        const menuId = `id-${item.id}-${pageId}`
                        const shouldShowSelection = snapshot.isDragging && selectedTaskIds.length > 1
                        return (
                          <div className='widget-list-cell-inner-container'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            onClick={(event) => this.onClick(event, item)}
                            onTouchEnd={(event) => this.onTouchEnd(event, item)}
                            onKeyDown={(event) =>
                              this.onKeyDown(event, provided, snapshot, item)
                            }
                            isSelected={isSelected}
                            isGhosting={isGhosting}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              onContextMenu={(e) => contextMenu.show({
                                id: menuId,
                                event: e
                              })}
                            >
                              <WidgetListDetailsCell
                                key={item.id}
                                onDoubleClick={() => this.props.handlePageLayoutItemEdit(item)}
                                item={item}
                                handleLayoutItemStatus={handleLayoutItemStatus}
                                isPinned={item.isPinned}
                                isSelected={selectedTaskIds.includes(item.id)}

                              />
                              {item.type === 'LOADING' ? <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 2 }} loading /> : ''}
                            </div>
                            {isUpdateBlocked ? () => {} : this.menuWidget(menuId, item)}
                            {shouldShowSelection ? (
                              <div className='selection-count'>{selectedTaskIds.length}</div>
                              // <SelectionCount>{selectedTaskIds.length}</SelectionCount>
                            ) : null}
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {!listData.length ? <div className='drag-asset-message'>
                  <DragAndDropIcon />
                  <p>Drag and drop widgets here </p>
                </div> : null}
                {listData.length && listData.length > 0 ? <div className='drag-asset-message-holder'>
                  <DragAndDropIcon />
                  <p>Drag and drop widgets here </p>
                </div> : null}
              </div>
            )}
          </Droppable>
        </div>
        <AddObjectModal
          handleCancel={hideAddWidgetObjectModal}
          isVisible={showWidgetModal}
          handleObjectDetailsChange={handleWidgetDetailsChange}
          objectDetails={pageConfigDetails}
          project={project}
          handleShowAddImageModal={this.handleShowAddImageModal}
          hideAddImageModal={this.hideAddImageModal}
          saveCurrentImageSelection={this.saveCurrentImageSelection}
          showImgEditorIcon={this.showImgEditorIcon}
          hideImgEditorIcon={this.hideImgEditorIcon}
          showAddImageModal={showAddImageModal && !isUpdateBlocked}
          showEditIcon={showEditIcon && !isUpdateBlocked}
          handleShowBucketModal={this.handleShowBucketModal}
          showAddBucketModal={showAddBucketModal}
          hideAddBucketModal={this.hideAddBucketModal}
          handleBucketSelection={this.handleBucketSelection}
          selectedBucket={selectedBucket}
          saveCurrentBucketSelection={this.saveCurrentBucketSelection}
          showImgEditor={this.showImgEditor}
          hideImgEditor={this.hideImgEditor}
          showImgEditModal={showImgEditModal && !isUpdateBlocked}
          details={isWidgetEdit ? editWidgetInstance : draggable}
          handleConfigObjectSave={handleWidgetObjectSave}
          isConfigObjectSaveLoading={isWidgetObjectSaveLoading}
          isConfigObjectSaveDisable={isWidgetObjectSaveDisable}
          selectedBucketConfig={selectedBucketConfig}
          selectedImgConfig={selectedImgConfig}
          selectedImgEditConfig={selectedImgEditConfig}
          editImageDetails={editImageDetails}
          isWidget
          isWidgetEdit={isWidgetEdit}
          jsonInvalidEntries={jsonInvalidEntries}
          jsonArrayInvalidEntries={jsonArrayInvalidEntries}
          isJsonArrayValid={isJsonArrayValid}
          isJsonArrayVisible={isJsonArrayVisible}
          jsonConfig={jsonConfig}
          jsonSelectedMeta={jsonSelectedMeta}
          handleJsonArrayVisibilty={handleJsonArrayVisibilty}
          handleJsonArrayHide={handleJsonArrayHide}
          isUpdateBlocked={isUpdateBlocked}
        />
      </div>
    )
  }
}

WidgetDetailList.propTypes = {
  /** isSearch of WidgetDetailList. */
  isSearch: PropTypes.bool,
  /** isEditted of WidgetDetailList. */
  isEditted: PropTypes.bool,
  /** listData of WidgetDetailList. */
  listData: PropTypes.array,
  /** Function to be called on rearrange of buckets */
  onDragEnd: PropTypes.func,
  /** Item Id */
  pageId: PropTypes.string,
  /** Function to be called on bucket item remove */
  removeBucketItem: PropTypes.func,
  /** display image change action of WidgetDetailList. */
  onAssetDisplayImageChange: PropTypes.func
}

WidgetDetailList.defaultProps = {
}

export default WidgetDetailList
