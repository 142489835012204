import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
// import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
// import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
// import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
// import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateAspectRatioModal from '../../../components/ui/dataEntry/other/CreateAspectRatioModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
// import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
// import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
// import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
// import userMessages from '../../../constants/messages'
// import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAspectRatioList from '../../../graphQL/admin/media/listAspectRatios'
import MutationDeleteAspectRatio from '../../../graphQL/admin/media/deleteAspectRatio'

let timeOutVar = null

const deleteAspectRatioMessage = {
  title: 'DELETE ASPECT RATIO',
  firstMessage: 'Are you sure you want to delete this Aspect Ratio?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
  // {
  //   type: 'delete',
  //   name: 'Delete'
  // }
]

class AspectRatios extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedAspectRatio: null,
      isLoading: !(this.props.aspectRatios && this.props.aspectRatios.length),
      //   isDuplicate: false,
      showAddAspectRatioModal: false,
      showTransferContentPlaceholderModal: false,
      showDeleteAspectRatioModal: false,
      selectedAspectRatio: undefined,
      height: null
      //   selectedRowKeys: [],
      //   isSelected: false,
      //   isMultipleDelete: false,
    }
    this.columns = [
      {
        title: 'Config Group',
        dataIndex: 'name',
        key: 'name',
        width: '45%'
      },
      {
        title: 'Aspect Ratio',
        dataIndex: 'title',
        width: '52%',
        key: 'title'
      },
      {
        key: 'aspectRatio',
        dataIndex: 'aspectRatio',
        width: '3%',
        render: (aspectRatio) => this.renderData(aspectRatio)
      }
    ]
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    window.addEventListener('resize', this.setHeight)
    this.setHeight()
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && !_.isEqual(newProps.aspectRatios, this.props.aspectRatios)) {
      this.setState({ isSearching: false, isPaginating: false, isLoading: false })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight = () => {
    if (this.tableSize) {
      clearTimeout(timeOutVar)
      timeOutVar = null
      this.setState({ height: this.tableSize.clientHeight })
    } else {
      clearTimeout(timeOutVar)
      this.timeOutVar = setTimeout(() => {
        this.setHeight()
      }, 2000)
    }
  }

  handleClickOutside = (event) => {
    const { target } = event
    const { className } = target
    const availableOptions = ['edit', 'delete']
    const isOptionClicked = availableOptions.findIndex(item => className === item)
    if (isOptionClicked > -1) {
      return
    }
    if (this.state.openedAspectRatio) {
      this.setState({ openedAspectRatio: null })
    }
  }

  toggleShowOptions = id => {
    this.props.aspectRatioId(id)
    if (this.state.openedAspectRatio === id) {
      this.setState({ openedAspectRatio: null })
    } else {
      this.setState({ openedAspectRatio: id })
    }
  }

  onSelectOption = (id, selectedOption) => {
    const { aspectRatios } = this.props
    const selectedAspectRatio = aspectRatios.find(item => item.aspectRatio === id)
    if (!selectedAspectRatio) {
      return
    }
    if (selectedOption.type === 'edit') {
      this.setState({ showAddAspectRatioModal: true, selectedAspectRatio })
    } else if (selectedOption.type === 'delete') {
      this.setState({ showDeleteAspectRatioModal: true, selectedAspectRatio })
    }
    this.setState({ openedAspectRatio: null })
  }

  showAddAspectRatio = () => {
    this.setState({ showAddAspectRatioModal: true, selectedAspectRatio: null })
  }

  hideAddAspectRatioModal = () => {
    this.setState({ showAddAspectRatioModal: false, selectedAspectRatio: null, isDuplicate: false })
  }

  handleAddAspectRatio = () => {
    this.setState({ showAddAspectRatioModal: false, selectedAspectRatio: null, isDuplicate: false })
  }

  hideDeleteAspectRatioModal = () => {
    this.setState({ showDeleteAspectRatioModal: false })
  }

  handleDeleteAspectRatio = () => {
    this.setState({ showDeleteAspectRatioModal: false })
  }

  // onSelectChange = selectedRowKeys => {
  //   this.setState({ selectedRowKeys })
  // }

  // onMultipleSelect = () => {
  //   this.setState({ isSelected: true })
  // }

  // onMultipleDSelect = () => {
  //   this.setState({ isSelected: false, selectedRowKeys: [] })
  // }

  // onMultipleDelete = () => {
  //   this.setState({ isMultipleDelete: true })
  // }

  // handleMultipleDeleteAspectRatio = () => {
  //   this.setState({ isMultipleDelete: false })
  // }

  // hideMultipleDeleteAspectRatio = () => {
  //   this.setState({ isMultipleDelete: false })
  // }

  // loadMoreAsset = () => {
  //   if (this.props.totalCount === this.props.placeholderList.length || this.state.isPaginating) { return }
  //   this.setState({ isPaginating: true }, () => this.props.getMoreCategories(this.props.placeholderList.length))
  // }

  handleDeleteAspectRatio = () => {
    const { onHistoryUpdate } = this.props
    this.props.deleteAspectRatio([this.state.selectedAspectRatio.aspectRatio]).then(() => {
      onHistoryUpdate()
      this.setState({ showDeleteAspectRatioModal: false, selectedAspectRatio: null, isDuplicate: false, isLoading: false })
      message.success('Deleted Aspect Ratio Successfully')
    }, error => {
      utilityService.handleError(error)
    })
  }

  getRenderedColumn = data => {
    // this.props.aspectRatioId(data.aspectRatio)
    const columns = [
      {
        title: 'Width',
        dataIndex: 'width',
        key: 'width',
        width: '45%'
      },

      {
        title: 'Height',
        dataIndex: 'height',
        key: 'height',
        width: '55%'
      }
    ]

    const field = _.cloneDeep(data.resolutions)
    return (
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={field}
        pagination={false}
      />
    )
  }

  renderData = (id) => {
    let availableOptions = _.cloneDeep(options)
    const { openedAspectRatio } = this.state
    if (this.isDeleteDisabled && this.isUpdateDisabled) {
      return <div />
    // } else if (this.isDeleteDisabled) {
    //   availableOptions.splice(1, 1)
    } else if (this.isUpdateDisabled) {
      availableOptions.splice(0, 1)
    }
    if (availableOptions && availableOptions.length) {
      return (
        <div className='option-list' id='option-list'>
          <PopoverButton
            button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
            displayParam='name'
            contents={availableOptions}
            onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
            parentCompoent={'option-list'}
            isVisible={openedAspectRatio === id}
            placement={'leftBottom'}
          />
        </div>
      )
    } else {
      return null
    }
  }

  render () {
    const { isPaginating, showAddAspectRatioModal, showDeleteAspectRatioModal, selectedAspectRatio, selectedRowKeys, isSelected, isLoading, height } = this.state
    const { aspectRatios, onHistoryUpdate } = this.props
    const columnData = this.getRenderedColumn
    let adminItem
    if (selectedAspectRatio && showDeleteAspectRatioModal) {
      adminItem = <div >
        <p>{selectedAspectRatio.name} </p>
      </div>
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: false
    }

    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <div className='admin-portal aspect-ratio-list'>
            <div className='admin-list' ref={(ref) => { this.tableSize = ref }}>
              {/* <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMoreAsset}
                    hasMore={this.props.totalCount > placeholderList.length}
                    initialLoad={false}
                    useWindow={false}
                    > */}
              <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                <Table className={`general-expand-table ${isPaginating ? 'paginating' : ''}`}
                  rowKey={record => record.id}
                  columns={this.columns}
                  dataSource={aspectRatios}
                  pagination={false}
                  expandedRowRender={columnData}
                  scroll={{ x: true, y: (height - 37) }}
                  rowSelection={isSelected ? rowSelection : undefined}
                />
              </Skeleton>
              {/* </InfiniteScroll> */}
            </div>
            <div className='admin-footer' >
              {/* <div className='left-button'>
                    {!isSelected
                        ? <MultipleSelectButton onClick={this.onMultipleSelect} />
                        : <React.Fragment>
                        <BackArrowButton onClick={this.onMultipleDSelect} />
                        <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                        </React.Fragment>
                    }
                    </div> */}
              {!isSelected ? <LoadingButton
                type='primary'
                onClick={this.showAddAspectRatio}
                htmlType='submit'
                buttonText={'New'}
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isCreateDisabled}
              /> : null}
            </div>
            <CreateAspectRatioModal
              isVisible={showAddAspectRatioModal}
              handleCancel={this.hideAddAspectRatioModal}
              handleSubmit={this.handleAddAspectRatio}
              selectedAspectRatio={selectedAspectRatio}
              isCreateDisabled={isCreateDisabled}
              onHistoryUpdate={onHistoryUpdate}
              project={project}
            />
            <AdminItemConfirmModal
              isVisible={showDeleteAspectRatioModal}
              title={deleteAspectRatioMessage.title}
              firstMessage={deleteAspectRatioMessage.firstMessage}
              secondMessage={deleteAspectRatioMessage.secondMessage}
              adminItem={adminItem}
              rightButtonText={'Delete'}
              handleCancel={this.hideDeleteAspectRatioModal}
              handleSubmit={this.handleDeleteAspectRatio}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false}
            />
            {/* <ConfirmModal
                    isVisible={isMultipleDelete}
                    title={'DELETE MULTIPLE CHANNEL STREAMS'}
                    message={userMessages.DELETE_MULTIPLE_CHANNEL_STREAMS}
                    isLoading={false}
                    rightButtonText={'Delete'}
                    handleSubmit={this.handleMultipleDeleteAspectRatio}
                    handleCancel={this.hideMultipleDeleteAspectRatio}
                /> */}
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

AspectRatios.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryAspectRatioList,
    {
      options: ({ project }) => {
        const variables = { limit: 100, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: ({ data }) => {
        return {
          aspectRatios: data && data.listAspectRatios && data.listAspectRatios.items ? [...data.listAspectRatios.items] : undefined,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationDeleteAspectRatio, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deleteAspectRatios } }) => {
            const variables = { filter: null, limit: 50, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAspectRatioList, variables }))
            if (cacheData && cacheData.listAspectRatios && cacheData.listAspectRatios.items) {
              cacheData.listAspectRatios.items = cacheData.listAspectRatios.items.filter(item => deleteAspectRatios.findIndex(deleteItem => deleteItem === item.aspectRatio) === -1)
            }
            cache.writeQuery({
              query: QueryAspectRatioList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        deleteAspectRatio: (id) => {
          return props.mutate({
            variables: { id }
          })
        }
      })
    }
  )

)(AspectRatios))
