import gql from 'graphql-tag'

export default gql(`
mutation deleteMetaFieldConfig($id : ID! $project: String) {
    deleteMetaFieldConfig(
            id: $id
            project: $project
    ) {
        id
    }
}`
)
