const undoIcon = `<svg width="15" height="15" viewBox="0 0 15 15">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#343A40" d="M6.519 5.333S14.872 5.281 15 13c-4.223-7.115-8.482-4.667-8.482-4.667v3.334L0 6.833 6.519 2v3.333z"/>
                        <path d="M0 0h15v15H0z"/>
                    </g>
                </svg>`

const redoIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path fill="#343A40" d="M8.481 5.333S.128 5.281 0 13c4.223-7.115 8.482-4.667 8.482-4.667v3.334L15 6.833 8.481 2v3.333z"/>
    <path d="M15 0H0v15h15z"/>
</g>
</svg>`

const boldIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M10.738 7.286c1.028-.75 1.748-1.929 1.748-3C12.486 1.82 10.738 0 8.374 0H2v15h7.196C11.356 15 13 13.179 13 10.929c0-1.608-.925-3-2.262-3.643zM4.981 2.679h3.084c.823 0 1.542.75 1.542 1.607s-.72 1.607-1.542 1.607H4.981V2.679zM8.58 12.32H4.981V9.107H8.58c.823 0 1.542.75 1.542 1.607s-.616 1.607-1.542 1.607z"/>
</g>
</svg>`

const italicsIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M5.333 0v3.214h2.384l-3.792 8.572H1V15h8.667v-3.214H7.175l3.792-8.572H14V0z"/>
</g>
</svg>`

const underlineIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M8 11.667c2.829 0 5.143-2.25 5.143-5V0H11v6.667c0 1.583-1.371 2.916-3 2.916S5 8.25 5 6.667V0H2.857v6.667c0 2.75 2.314 5 5.143 5zm-6 1.666V15h12v-1.667H2z"/>
</g>
</svg>`

const strikeIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" fill-rule="nonzero" d="M14.732 7.5c.078 0 .142.03.193.088.05.058.075.133.075.224v.625a.333.333 0 0 1-.075.225.242.242 0 0 1-.193.088H.268a.242.242 0 0 1-.193-.088A.333.333 0 0 1 0 8.437v-.624c0-.092.025-.167.075-.225A.242.242 0 0 1 .268 7.5h14.464zm-10.88-.625a4.575 4.575 0 0 1-.513-.781c-.321-.632-.482-1.244-.482-1.836 0-1.179.449-2.184 1.346-3.018C5.093.413 6.41 0 8.151 0c.334 0 .894.062 1.677.186.442.078 1.035.234 1.778.468.067.248.137.632.211 1.153.094.8.14 1.396.14 1.787 0 .117-.016.263-.05.44l-.12.029-.844-.06-.14-.019c-.335-.97-.68-1.637-1.035-2.002-.59-.592-1.293-.888-2.11-.888-.763 0-1.372.192-1.828.576-.448.378-.673.853-.673 1.426 0 .475.221.93.663 1.367.442.436 1.376.856 2.803 1.26.462.13 1.041.345 1.737.644.389.182.707.352.955.508H3.852zm5.092 2.5h4.129c.046.254.07.553.07.898a5.32 5.32 0 0 1-.412 2.07 3.245 3.245 0 0 1-.713 1.016c-.248.228-.613.492-1.095.791-.536.313-1.048.528-1.537.645-.536.137-1.215.205-2.039.205-.763 0-1.416-.075-1.959-.225l-1.406-.39c-.382-.104-.623-.196-.723-.274a.286.286 0 0 1-.08-.215v-.126c0-.704-.007-1.211-.02-1.524a10.94 10.94 0 0 1 0-.664l.02-.361v-.43l1.024-.02c.1.222.201.453.301.694.1.24.176.423.226.547.05.123.093.211.126.263.234.371.502.677.804.918.288.235.64.42 1.054.557a3.87 3.87 0 0 0 1.326.215c.429 0 .894-.088 1.396-.264.516-.17.924-.449 1.226-.84.315-.397.472-.817.472-1.26 0-.546-.271-1.057-.814-1.533-.227-.188-.686-.42-1.376-.693z"/>
</g>
</svg>
`
const linkIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M6.4 10.402c1.07 0 2.075-.416 2.83-1.172a.885.885 0 0 0-1.25-1.252 2.219 2.219 0 0 1-1.58.654 2.218 2.218 0 0 1-1.58-.654L2.465 5.622a2.236 2.236 0 0 1 0-3.159c.87-.87 2.287-.87 3.158 0l1.009 1.009A.885.885 0 0 0 7.884 2.22L6.875 1.212a4.009 4.009 0 0 0-5.662 0 4.008 4.008 0 0 0 0 5.662L3.568 9.23A3.979 3.979 0 0 0 6.4 10.402zm4.556 4.557c1.07 0 2.075-.416 2.832-1.173a4.008 4.008 0 0 0 0-5.662l-2.356-2.356a4.009 4.009 0 0 0-5.663 0A.885.885 0 0 0 7.021 7.02c.871-.87 2.288-.87 3.159 0l2.356 2.356a2.236 2.236 0 0 1-1.58 3.813 2.219 2.219 0 0 1-1.58-.654l-1.008-1.009a.885.885 0 0 0-1.251 1.252l1.008 1.009a3.979 3.979 0 0 0 2.831 1.172z"/>
</g>
</svg>
`
const justifyIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path fill="#343A40" d="M0 15h15v-1.667H0V15zm0-3.333h15V10H0v1.667zm0-3.334h15V6.667H0v1.666zM0 5h15V3.333H0V5zm0-5v1.667h15V0H0z"/>
    <path d="M0 0h15v15H0z"/>
</g>
</svg>
`
const rightAlignIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
<g fill="none" fill-rule="evenodd">
    <path fill="#343A40" d="M.667 15h15v-1.667h-15V15zm5-3.333h10V10h-10v1.667zm-5-3.334h15V6.667h-15v1.666zm5-3.333h10V3.333h-10V5zm-5-5v1.667h15V0h-15z"/>
    <path d="M.667 0h15v15h-15z"/>
</g>
</svg>`

const centerAlignIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
<g fill="none" fill-rule="evenodd">
    <path fill="#343A40" d="M3.667 10v1.667H12V10H3.667zM.333 15h15v-1.667h-15V15zm0-6.667h15V6.667h-15v1.666zm3.334-5V5H12V3.333H3.667zM.333 0v1.667h15V0h-15z"/>
    <path d="M.333 0h15v15h-15z"/>
</g>
</svg>`

const leftAlignIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path fill="#343A40" d="M10 10H0v1.667h10V10zm0-6.667H0V5h10V3.333zm-10 5h15V6.667H0v1.666zM0 15h15v-1.667H0V15zM0 0v1.667h15V0H0z"/>
    <path d="M0 0h15v15H0z"/>
</g>
</svg>`

const blockQuoteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M15 6.135V13H8.125V6.135h.023a4.135 4.135 0 0 1 4.118-4.056v-.001H15v.713h-1.095a3.545 3.545 0 0 0-.901.128c-.28.093-.605.254-.9.532a4.1 4.1 0 0 0-1.063 2.684H15zM6.875 13H0V6.135A4.138 4.138 0 0 1 4.14 2h2.735v.713h-.924s-.75.01-1.224.184a2.425 2.425 0 0 0-.128.054l-.007.004c-.291.138-.6.357-.863.697a4.097 4.097 0 0 0-.835 2.483h3.981V13z"/>
</g>
</svg>`

const orderedListIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M3 7h11.991v1.3H3V7zm0-6h11.991v1.3H3V1zm0 12h11.991v1.3H3V13zm-1.75.4c.499.06.71.284.71.673 0 .265-.073.473-.274.625-.202.152-.486.228-.844.228-.147 0-.292-.011-.417-.033-.125-.022-.175-.062-.425-.12v-.64c.25.064.26.113.383.144.125.03.257.048.362.048.162 0 .28-.02.354-.067.074-.047.111-.113.111-.215 0-.077-.019-.121-.058-.164-.039-.041-.1-.04-.187-.062-.086-.021-.198.034-.336.034h-.13v-.78h.134c.375 0 .562-.065.562-.265 0-.075-.027-.095-.083-.132a.402.402 0 0 0-.223-.054.994.994 0 0 0-.543.182l-.307-.514c.144-.104.292-.177.443-.222A1.85 1.85 0 0 1 1 12c.283 0 .501.059.662.175.16.117.234.278.234.483a.708.708 0 0 1-.17.455c-.103.126-.228.218-.478.275v.012zm-.24-5.167v.012h1v.52h-2v-.384l.678-.703c.19-.207.325-.35.383-.425a.943.943 0 0 0 .123-.196.434.434 0 0 0 .035-.171.227.227 0 0 0-.07-.175.276.276 0 0 0-.194-.065.517.517 0 0 0-.261.077 1.89 1.89 0 0 0-.298.227L0 6.456c.14-.132.26-.225.355-.282.096-.056.2-.1.314-.13.112-.03.24-.044.38-.044.177 0 .336.032.474.097a.738.738 0 0 1 .44.681 1.104 1.104 0 0 1-.159.589 1.816 1.816 0 0 1-.215.29c-.093.101-.305.293-.579.576zM.694 1.022l-.31.266L0 .797.939 0h.498v2.861h-.5v-1.52c0-.183-.037-.35-.03-.502-.046.058-.145.12-.213.183z"/>
</g>
</svg>`

const unOrderedListIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h15v15H0z"/>
    <path fill="#343A40" d="M3 7h11.803v1.277H3V7zM1.107 8.698C.495 8.698 0 8.183 0 7.548 0 6.915.495 6.4 1.107 6.4c.61 0 1.106.514 1.106 1.149 0 .634-.495 1.149-1.106 1.149zM3.197.51H15v1.276H3.197V.511zm-2.09 1.787C.495 2.298 0 1.783 0 1.148 0 .515.495 0 1.107 0c.61 0 1.106.514 1.106 1.149 0 .634-.495 1.149-1.106 1.149zm2.09 10.915H15v1.276H3.197v-1.276zM1.107 15C.495 15 0 14.486 0 13.851c0-.634.495-1.149 1.107-1.149.61 0 1.106.515 1.106 1.15 0 .634-.495 1.148-1.106 1.148z"/>
</g>
</svg>`

const richTextIcons = {
  undoIcon,
  redoIcon,
  boldIcon,
  italicsIcon,
  underlineIcon,
  orderedListIcon,
  unOrderedListIcon,
  strikeIcon,
  blockQuoteIcon,
  leftAlignIcon,
  centerAlignIcon,
  rightAlignIcon,
  justifyIcon,
  linkIcon
}

export default richTextIcons
