import gql from 'graphql-tag'

export default gql(`
    mutation createOrUpdateAssetTemplate(
            $assetType: String!
            $competition: ID! 
            $title: String! 
            $seoTitle: String
            $seoKeywords: [String]
            $shortTitle: String
            $description: String
            $seoMetaDescription: String
            $isLive: Boolean!
            $isFree: Boolean!
            $maxAssets: Int!
            $minAssets: Int!
            $category: ID
            $publishEndDate: [ CreatePublishRuleTemplateInput ]
            $tags: [ CreateTagInput ]
            $action: ACTION_TYPE
        ) {
        createOrUpdateAssetTemplate(
            input: {
                assetType: $assetType
                competition: $competition
                title: $title
                shortTitle: $shortTitle
                seoTitle: $seoTitle
                seoKeywords: $seoKeywords
                description: $description
                seoMetaDescription: $seoMetaDescription
                isLive: $isLive
                isFree: $isFree
                maxAssets: $maxAssets
                minAssets: $minAssets
                category: $category
                publishEndDate: $publishEndDate
                tags: $tags
            }
            action: $action
        ) {
            id
            assetType
            competition
            title
            shortTitle
            description
            seoTitle
            seoKeywords
            seoMetaDescription
            isLive
            isFree
            maxAssets
            minAssets
            publishEndDate{
                field
                operator
                offset
                offsetType
                publishGroup
                publishEndDate
            }
            category{
                id
                name
            }
            tags{
                name,
                type,
                module
            }
        }
    }`
)
