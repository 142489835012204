import gql from 'graphql-tag'

export default gql(`
    mutation editPageLayoutItem($input: editPageLayoutItemInput, $project:String) {
        editPageLayoutItem(
          project: $project
          input: $input
        ) {
            id
            position
            type
            widget {
              id
              key
              title
              description  
              fieldValues {
                configField {
                  id
                  name
                  type
                  isRequired
                  project
                  useAsTitle
                  createdAt
                  createdBy
                  position
                  isUpdatable
                }
                value
                useAsTitle
                bucket {
                  project
                  id
                  isArchived
                  name
                  isPublished
                  publishStartDate
                  publishEndDate
                  updatedAt
                  updatedBy
                  key
                  displayName
                  displayType {
                    name
                    id
                  }
                }
                image {
                  id
                  fileName
                  createdAt
                  name
                  type
                  fileSize
                  duration
                  thumbnails
                  tags {
                    key
                    name
                    type
                  }
                  category {
                    id
                    label
                    type
                  }
                  settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
        
                  }
                  dimensions {
                    x1
                    x2
                    y1
                    y2
                    
                  }
                  aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                      key
                      width
                      height
                      url
                      
                    }
                    
                  }
                  
                }
                project
                position
              }
              widgetPreview {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
              }
           }
            fieldValues {
              configField {
                id
                name
                type
                isRequired
                project
                useAsTitle
                createdAt
                createdBy
                position
                isUpdatable
              }
              useAsTitle
              value
              bucket {
                project
                id
                isArchived
                name
                isPublished
                publishStartDate
                publishEndDate
                updatedAt
                updatedBy
                key
                displayName
                displayType {
                  name
                  id
                }
                bucketItems {
                  id
                  position
                  type
                  asset{
                    id
                    title
                    type
                    shortId
                    externalId
                    updatedAt
                    status
                    seasonNumber
                    episodeNumber
                    series{
                      id
                      assetId
                      title
                    }
                    season{
                        id
                        assetId
                        title
                    }
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                }
                filterRule{
                  searchKey
                  filters{
                      name
                      fieldName
                      type
                      value
                      displayValue
                      displayName
                      isRequired
                  }
               }
                }
              }
              image {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags {
                  key
                  name
                  type
                }
                category {
                  id
                  label
                  type
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
      
                }
                dimensions {
                  x1
                  x2
                  y1
                  y2
                  
                }
                aspectRatio {
                  aspectRatio
                  ratio
                  title
                  resolutions {
                    key
                    width
                    height
                    url
                    
                  }
                  
                }
                
              }
              project
              position
              
            }
            project
            isEnabled
            
          }
    }`
)
