import React, { Component } from 'react'
import { message, Skeleton, Empty } from 'antd'
import { Menu, Item } from 'react-contexify'

import AppContext from '../../AppContext'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import DuplicateButton from '../../components/ui/general/buttons/DuplicateButton'
import ArchiveButton from '../../components/ui/general/buttons/ArchiveButton'
import RestoreButton from '../../components/ui/general/buttons/RestoreButton'
import AppHeading from '../../components/ui/dataDisplay/AppHeading'
import Accordion from '../../components/ui/dataDisplay/Accordion'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import AddObjectModal from '../../components/ui/dataEntry/other/AddObjectModal'
import ConfigGeneralDetails from './ConfigGeneralDetails'
import PageGeneralDetails from './PageGeneralDetails'
import CreateAppConfigModal from '../../components/ui/dataEntry/other/CreateConfigModal'
import CreateAppPageModal from '../../components/ui/dataEntry/other/CreatePageModal'
import SingleAppConfigs from '../../components/ui/dataDisplay/SingleAppconfigs'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import ListAppConfigs from '../../components/ui/dataDisplay/ListAppConfigs'
import { utilityService } from '../../services/UtilityService'
import WidgetAppSettingList from '../../components/ui/dataDisplay/WidgetAppSettingList'
import AppSettingList from '../../components/ui/dataDisplay/AppSettingList'
import AppAPIKeyList from '../../components/ui/dataDisplay/AppAPIKeyList'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationArchiveAppConfig from './../../graphQL/appManager/archiveAppConfig'
import MutationArchiveAppPage from './../../graphQL/appManager/archiveAppPage'
import QueryGetAppConfigList from '../../graphQL/appManager/listAppConfigs'
import QueryGetAppPageList from '../../graphQL/appManager/listAppPages'
import MutationBatchEditConfigInstance from '../../graphQL/appManager/batchEditConfigInstance'
import MutationDeleteConfigInstance from '../../graphQL/appManager/deleteConfigInstance'
import QueryListWidgets from '../../graphQL/appManager/listWidgets'

const width = [ '100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%' ]

// const typeList=[]
class AppDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showConfigModal: false,
      showPageModal: false,
      showAddImageModal: false,
      showEditIcon: false,
      showAddBucketModal: false,
      selectedBucket: null,
      showImgEditModal: false,
      showArchiveWarning: false,
      showRestoreWarning: false,
      isDeleteLoading: false,
      configList: _.cloneDeep(props.configList),
      isConfigListSaveLoading: false,
      showConfigDeleteWarning: false,
      selectedDeleteConfigInstance: null,
      isConfigDeleteLoading: false,
      selectedBucketConfig: null,
      selectedImgConfig: null,
      selectedImgEditConfig: null,
      showSwitchWarning: false,
      isForceSaving: false,
      editImageDetails: null,
      showPageArchiveWarning: false,
      showPageRestoreWarning: false,
      isPageDeleteLoading: false,
      layoutItems: _.cloneDeep(props.pageDetails && props.pageDetails.layoutItems),
      showWidgetModal: false,
      appSearchText: '',
      showWidgetDuplicateWarning: false,
      showAppModal: false,
      isConfigDuplicating: false,
      showApiKeyModal: false,
      isPageDuplicating: false
    }
    this.updatedContents = []
    this.configInstancePos = []
    this.isBulkSubmitting = false // to prevent mutiple listBulkSubmit Calls when saving changes to config list
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.details && newProps.details.configList, this.props.details && this.props.details.configList) && newProps.appMode === 'configs') {
      this.updatedContents = []
      this.configInstancePos = []
      this.setState({ isPaginating: false, configList: _.cloneDeep(newProps.details.configList) })
    }
    if (newProps.details && this.props.details && this.state && !_.isEqual(newProps.details.configList, this.state.configList) && newProps.appMode && newProps.appMode === 'configs' && newProps.appMode !== this.props.appMode) {
      this.setState({ configList: _.cloneDeep(newProps.details.configList) })
    }

    if (newProps.selectedConfig !== this.props.selectedConfig && (newProps.isConfigListInstanceEdited || newProps.isConfigSingleInstanceEdited)) {
      this.setState({ showSwitchWarning: true })
    }
    if (newProps.forceSave && newProps.isConfigListInstanceEdited && !this.isBulkSubmitting) {
      this.handleListBulkSubmit()
    }

    if (!_.isEqual(newProps.pageDetails, this.props.pageDetails)) {
      this.setState({
        layoutItems: _.cloneDeep(newProps.pageDetails && newProps.pageDetails.layoutItems)
      })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { target } = event
    const { className } = target
    const availableOptions = [ 'edit', 'delete' ]
    const isOptionClicked = availableOptions.findIndex(item => className === item)
    if (isOptionClicked > -1) {
      return
    }
    if (this.state.openedGroupType) {
      this.setState({ openedGroupType: null })
    }
  }

  hideConfigModal=() => {
    this.setState({ showConfigModal: false, isConfigDuplicating: false })
  }

  hidePageModal=() => {
    this.setState({ showPageModal: false, isPageDuplicating: false })
  }

  toggleConfigModal=() => {
    const { isConfigListInstanceEdited, isConfigSingleInstanceEdited } = this.props
    if (isConfigListInstanceEdited || isConfigSingleInstanceEdited) {
      message.warning('Please save before editing the config.')
      return
    }
    this.setState({ showConfigModal: true })
  }

  toggleApiKeyModal=() => {
    this.setState({ showApiKeyModal: true })
  }

  hideApiKeyModal=() => {
    this.setState({ showApiKeyModal: false })
  }

  togglePageModal=() => {
    const { pageEditted } = this.props
    if (pageEditted) {
      message.warning('Please save before editing the page.')
      return
    }
    this.setState({ showPageModal: true })
  }

  handleShowAddImageModal = (selectedImgConfig) => {
    this.setState({ selectedImgConfig: selectedImgConfig, showAddImageModal: true })
  }

  hideAddImageModal = () => {
    this.setState({ showAddImageModal: false })
  }

  saveCurrentImageSelection = (config, imageDetails, type) => {
    this.setState({ showAddImageModal: false })
    if (type === 'CONFIG') {
      this.props.handleSingleConfigDetailsChange(config, imageDetails)
    } else {
      this.props.handleConfigDetailsChange(config, imageDetails)
    }
  }

  handleShowBucketModal = (selectedBucketConfig) => {
    this.setState({ selectedBucketConfig: selectedBucketConfig, showAddBucketModal: true })
  }

  hideAddBucketModal = () => {
    this.setState({ showAddBucketModal: false })
  }

  handleBucketSelection = (selectedBucket) => {
    this.setState({
      selectedBucket
    })
  }

  saveCurrentBucketSelection = (config, type) => {
    const { selectedBucket } = this.state
    this.setState({ showAddBucketModal: false, selectedBucket: null })
    if (type === 'CONFIG') {
      this.props.handleSingleConfigDetailsChange(config, selectedBucket)
    } else {
      this.props.handleConfigDetailsChange(config, selectedBucket)
    }
  }

  showImgEditorIcon = (selectedImgEditConfig, editImageDetails) => {
    this.setState({
      showEditIcon: true,
      selectedImgEditConfig: selectedImgEditConfig,
      editImageDetails: editImageDetails
    })
  }

  hideImgEditorIcon = () => {
    this.setState({
      showEditIcon: false,
      selectedImgEditConfig: null
    })
  }

  showImgEditor = () => {
    this.setState({
      showImgEditModal: true
    })
  }

  hideImgEditor = () => {
    const { editConfigInstance } = this.props
    this.setState({
      showImgEditModal: false,
      editImageDetails: null
    })
    if (this.props.fetchAppDetails) {
      this.props.fetchAppDetails()
    }

    setTimeout(() => {
      this.onConfigImgCropInstanceEdit(editConfigInstance.id)
    }, 1000)
  }

  handleArchiveAppConfig=() => {
    const { details, userId } = this.props
    const { title, key, app, group, type, fields, id, tags, assignedUsers } = details
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)

    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }
    if (isCriticalWriteDisabled && isAppAdminDisabled && ((details && details.isCritical) || (details && details.app && details.app.isCritical)) && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    const newStreamFields = (fields || []).map(field => {
      delete field['__typename']
      return field
    })
    const tagsArray = (tags || []).map(tag => {
      if (tag) {
        return tag.key
      }
    })
    this.setState({ isDeleteLoading: true })
    this.props.updateAppConfig(title, key, app.id, group.key, type, newStreamFields, id, tagsArray, true).then(() => {
      message.success('Config archived successfully')
      this.setState({ isDeleteLoading: false, showArchiveWarning: false })
      this.props.changeSelectedConfig('archive')
    }, (error) => {
      utilityService.handleError(error)
      this.setState({ isDeleteLoading: false, showArchiveWarning: false })
    })
  }

  handleArchiveAppPage=() => {
    const { pageDetails, userId } = this.props
    const { title, key, app, group, id, assignedUsers } = pageDetails
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Page')
      return
    }
    if (isCriticalWriteDisabled && isAppAdminDisabled && ((pageDetails && pageDetails.isCritical) || (pageDetails && pageDetails.app && pageDetails.app.isCritical)) && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Page')
      return
    }
    this.setState({ isPageDeleteLoading: true })
    this.props.archiveAppPage(title, key, app.id, group.key, id, true).then(() => {
      message.success('Page archived successfully')
      this.setState({ isPageDeleteLoading: false, showPageArchiveWarning: false })
      this.props.changeSelectedPage('archive')
    }, (error) => {
      utilityService.handleError(error)
      this.setState({ isPageDeleteLoading: false, showPageArchiveWarning: false })
    })
  }

  handleRestoreAppConfig=() => {
    const { details, userId } = this.props
    const { title, key, app, group, type, fields, id, tags, assignedUsers } = details
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }
    if (isCriticalWriteDisabled && isAppAdminDisabled && ((details && details.isCritical) || (details && details.app && details.app.isCritical)) && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    const newStreamFields = (fields || []).map(field => {
      delete field['__typename']
      return field
    })
    const tagsArray = (tags || []).map(tag => {
      if (tag) {
        return tag.key
      }
    })
    this.setState({ isDeleteLoading: true })
    this.props.updateAppConfig(title, key, app.id, group.key, type, newStreamFields, id, tagsArray, false).then(() => {
      message.success('Config Restored successfully')
      this.setState({ isDeleteLoading: false, showRestoreWarning: false })
      this.props.changeSelectedConfig('restore')
    }, (error) => {
      utilityService.handleError(error)
      this.setState({ isDeleteLoading: false, showRestoreWarning: false })
    })
  }

  handleRestoreAppPage=() => {
    const { pageDetails, userId } = this.props
    const { title, key, app, group, id, assignedUsers } = pageDetails
    const userPermissions = this.props.permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Page')
      return
    }
    if (isCriticalWriteDisabled && isAppAdminDisabled && ((pageDetails && pageDetails.isCritical) || (pageDetails && pageDetails.app && pageDetails.app.isCritical)) && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Page')
      return
    }
    this.setState({ isPageDeleteLoading: true })
    this.props.archiveAppPage(title, key, app.id, group.key, id, false).then(() => {
      message.success('Page Restored successfully')
      this.setState({ isPageDeleteLoading: false, showPageRestoreWarning: false })
      this.props.changeSelectedPage('restore')
    }, (error) => {
      utilityService.handleError(error)
      this.setState({ isPageDeleteLoading: false, showPageRestoreWarning: false })
    })
  }

  handleArchiveClick=(value) => {
    this.setState({ showArchiveWarning: value })
  }

  handlePageArchiveClick=(value) => {
    this.setState({ showPageArchiveWarning: value })
  }

  handleRestoreClick=(value) => {
    this.setState({ showRestoreWarning: value })
  }

  handlePageRestoreClick=(value) => {
    this.setState({ showPageRestoreWarning: value })
  }

  reorder = (startIndex, endIndex) => {
    const { configList } = this.state
    let sortedList = this.getSortedList(_.cloneDeep(configList))
    const result = Array.from(sortedList)
    result[startIndex].position = endIndex
    result[endIndex].position = startIndex
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    result.map((item, index) => {
      if (!this.updatedContents.includes(item.id)) {
        this.updatedContents.push(item.id)
      }
      item.position = index + 1
      return item
    })
    this.configInstancePos.push(sortedList[startIndex].id)
    this.setState({ configList: result }, this.updateConfigInstance)
  }

  onDragEnd = result => {
    if (!result.destination || result.source.index === result.destination.index) {
      return
    }
    this.reorder(result.source.index, result.destination.index)
  }

  updateConfigInstance = () => {
    this.props.changeConfigListEditStatus(true)
    const { configList } = this.state
    this.updatedContents = [...new Set(this.updatedContents)]
    const updatedConfigs = this.updatedContents.map(item => {
      const selectedConfigInstance = configList.find(config => config.id === item)
      const isPosChange = this.configInstancePos.includes(selectedConfigInstance.id)
      let trackPosChange = isPosChange
      let filterFieldValues = _.omit(selectedConfigInstance, '__typename')
      let filerConfigField = (filterFieldValues.fieldValues || []).map(fieldValue => {
        return {
          configField: fieldValue.configField.id,
          bucket: fieldValue.bucket ? fieldValue.bucket.id : '',
          image: fieldValue.image ? fieldValue.image.id : '',
          project: fieldValue.project,
          value: fieldValue.value
        }
      })
      return { id: selectedConfigInstance.id,
        position: selectedConfigInstance.position,
        appConfigId: selectedConfigInstance.appConfigId,
        trackPosChange: trackPosChange,
        isEnabled: selectedConfigInstance.isEnabled,
        fieldValues: filerConfigField,
        createdAt: selectedConfigInstance.createdAt,
        createdBy: selectedConfigInstance.createdBy }
    })
    return updatedConfigs
  }

  configEditClickMenu = (id) => (
    <Menu id={`${id}-config-list-menu-item`}>
      <Item onClick={() => this.onConfigInstanceEdit(id)}>Edit</Item>
      <Item onClick={() => this.handleConfigDeleteClick(true, id)}>Remove Item</Item>
    </Menu>
  )
  configViewMenu = (id) => (
    <Menu id={`${id}-config-list-menu-item`}>
      <Item onClick={() => this.onConfigInstanceEdit(id)}>View</Item>
    </Menu>
  )

  handleListConfigInstanceStatus = (configInstance, value) => {
    const { configList } = this.state
    let tempConfigList = _.cloneDeep(configList)
    if (!this.updatedContents.includes(configInstance.id)) {
      this.updatedContents.push(configInstance.id)
    }
    const updatedConfigList = (tempConfigList || []).map(item => {
      if (item.id === configInstance.id) {
        item.isEnabled = value
      }
      return item
    })
    this.setState({ configList: updatedConfigList }, this.updateConfigInstance)
  }

  handleListBulkSubmit = async () => {
    const updateConfigs = this.updateConfigInstance()
    this.setState({ isConfigListSaveLoading: true })
    this.isBulkSubmitting = true
    const { details, permissions, userId } = this.props
    const { assignedUsers } = details
    const userPermissions = permissions['APP_MANAGER']
    const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
    const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    const app = (details && details.app && details.app.id) || null
    const configId = (details && details.id) || null
    let isAppCritical = (details && details.app && details.app.isCritical) || false
    const isAssignedUser = ((details && details.assignedUsers) || []).find(user => user === userId)
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      if (this.props.fetchAppDetails) {
        this.props.fetchAppDetails().then(res => {
          if (res && res.data && res.data.getAppConfig && res.data.getAppConfig.configList) {
            this.setState({ configList: res.data.getAppConfig.configList })
          }
        })
      }
      this.setState({ isConfigListSaveLoading: false })
      this.isBulkSubmitting = false
      this.props.updateForceSaveState(true)
      return
    }
    if ((isAppCritical || details.isCritical) && isCriticalWriteDisabled && isAppAdminDisabled && !isAssignedUser) {
      this.setState({ isConfigListSaveLoading: false })
      message.error('You do not have access to do this operation on this Config')
      if (this.props.fetchAppDetails) {
        this.props.fetchAppDetails().then(res => {
          if (res && res.data && res.data.getAppConfig && res.data.getAppConfig.configList) {
            this.setState({ configList: res.data.getAppConfig.configList })
          }
        })
      }
      this.isBulkSubmitting = false
      this.props.updateForceSaveState(true)
      return
    }
    if (await this.props.checkForConfigModification()) {
      this.setState({ isConfigListSaveLoading: false })
      return
    }
    this.props.batchEditConfigInstance(updateConfigs, app, configId).then(() => {
      if (this.props.fetchAppDetails) {
        this.props.fetchAppDetails()
      }
      this.configInstancePos = []
      this.props.changeConfigListEditStatus(false)
      this.setState({ isConfigListSaveLoading: false, isForceSaving: false })
      this.props.refetchHistory()
      this.props.updateForceSaveState(true)
      this.isBulkSubmitting = false
      message.success('Config List Updated Successfully')
    }, error => {
      this.props.changeConfigListEditStatus(false)
      this.setState({ isConfigListSaveLoading: false })
      utilityService.handleError(error)
      this.props.updateForceSaveState(true)
      this.isBulkSubmitting = false
    })
  }

  onConfigInstanceEdit = (id) => {
    const { configList } = this.state
    let editConfigInstance = configList.filter(config => config.id === id)[0]
    this.props.handleListConfigInstanceEdit(editConfigInstance)
  }

  handleConfigDeleteClick=(value, id) => {
    this.setState({ showConfigDeleteWarning: value, selectedDeleteConfigInstance: id })
  }

  handleConfigDelete = () => {
    const { selectedDeleteConfigInstance, configList } = this.state
    const { details } = this.props
    let selectedConfig = configList.filter(config => config.id === selectedDeleteConfigInstance)[0].fieldValues
    let filterConfigList = configList.filter(config => config.id !== selectedDeleteConfigInstance)
    let useAsTitleFieldId = null
    selectedConfig.map(item => {
      let field = item.configField
      if (field.useAsTitle) {
        useAsTitleFieldId = field.id
      }
    })

    this.setState({
      isConfigDeleteLoading: true
    })
    const app = (details && details.app && details.app.id) || null
    const configId = (details && details.id) || ''
    this.props.deleteConfigInstance(selectedDeleteConfigInstance, useAsTitleFieldId, app, configId).then(() => {
      if (this.props.fetchAppDetails) {
        this.props.fetchAppDetails()
      }
      const isDeletedConfigPresent = this.updatedContents.findIndex(item => item === selectedDeleteConfigInstance)
      if (isDeletedConfigPresent > -1) {
        this.updatedContents.splice(isDeletedConfigPresent, 1)
      }
      this.setState({
        isConfigDeleteLoading: false,
        configList: filterConfigList,
        selectedDeleteConfigInstance: null,
        showConfigDeleteWarning: false
      })
      this.props.refetchHistory()
    }, error => {
      this.setState({
        isConfigDeleteLoading: false,
        selectedDeleteConfigInstance: null,
        showConfigDeleteWarning: false
      })
      utilityService.handleError(error)
    })
  }

  handleConfigDuplicateClick=(value) => {
    this.setState({ showConfigModal: value, isConfigDuplicating: true })
  }

  handlePageDuplicateClick = (value) => {
    this.setState({ showPageModal: value, isPageDuplicating: true })
  }

  onCloseConfirmModal=() => {
    this.setState({ showSwitchWarning: false })
  }

  forceSave=() => {
    if (this.props.isConfigListInstanceEdited) {
      this.handleListBulkSubmit()
    } else if (this.props.isConfigSingleInstanceEdited) {
      this.props.handleSingleConfigSubmit()
    }
  }

  discardChanges=() => {
    this.setState({ showSwitchWarning: false })
  }

  onConfigImgCropInstanceEdit = (id) => {
    const { configList } = this.state
    let editConfigInstance = configList.filter(config => config.id === id)[0]
    this.props.handleListConfigImgCropInstanceEdit(editConfigInstance)
  }

  handleShowWidgetModal = () => {
    this.setState({
      showWidgetModal: true
    })
  }

  handleShowAppModal = () => {
    this.setState({
      showAppModal: true
    })
  }

  hideWidgetModal = () => {
    this.setState({
      showWidgetModal: false
    })
  }

  hideAppModal = () => {
    this.setState({
      showAppModal: false
    })
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  handleAppSearchTextChange=(value) => {
    this.setState({ appSearchText: value })
  }

  render () {
    const {
      handleConfigDetailsChange,
      configDetails,
      fieldTypeList,
      showObjectModal,
      hideAddObjectModal,
      showAddObjectModal,
      details,
      appList,
      groupList,
      typeList,
      selectedConfig,
      appClient,
      loading,
      handleConfigObjectSave,
      isConfigListInstanceEdited,
      isConfigObjectSaveLoading,
      isConfigObjectSaveDisable,
      handleSingleConfigDetailsChange,
      singleConfigDetails,
      isConfigSingleInstanceEdited,
      isConfigSingleInstanctSaveLoading,
      handleSingleConfigSubmit,
      appMode,
      selectedPage,
      pageDetails,
      pageLoading,
      sourceIndex,
      destinationIndex,
      droppableId,
      draggableId,
      listWidgets,
      handlePageLayoutItems,
      isPagSaveDisable,
      handlePageLayoutItemDelete,
      isPageSaveLoading,
      handleLayoutItemStatus,
      fetchAppPageDetails,
      handleEditPageLayoutItems,
      handlePageSubmit,
      pageEditted,
      selectedAppSetting,
      togglePageScheduleModal,
      refetchPageList,
      onChangeWidgetSettingFilter,
      widgetSettingSearchValue,
      changeWidgetSettingFilter,
      widgetSettingFilter,
      currentPageVersionId,
      isPageScheduleSaveLoading,
      handlePageScheduleSubmit,
      pageReorderLoading,
      configMetaFieldList,
      pageMetaFieldList,
      isJsonArrayValid,
      handleRecreateConfigTree,
      jsonInvalidEntries,
      jsonArrayInvalidEntries,
      onSelectPageGroup,
      onSelectConfigGroup,
      isJsonArrayVisible,
      jsonConfig,
      jsonSelectedMeta,
      handleJsonArrayVisibilty,
      handleJsonArrayHide,
      handlePageLayoutItemPin,
      checkForConfigModification,
      checkForPageModification,
      fetchUpdatedPageUpdateTime,
      setSelectedTaskIds,
      selectedTaskIds,
      resetPageState
    } = this.props

    const {
      showAddImageModal,
      showEditIcon,
      showAddBucketModal,
      selectedBucket,
      showImgEditModal,
      showConfigModal,
      showArchiveWarning,
      showRestoreWarning,
      isDeleteLoading,
      configList,
      isConfigListSaveLoading,
      showConfigDeleteWarning,
      isConfigDeleteLoading,
      selectedBucketConfig,
      selectedImgConfig,
      selectedImgEditConfig,
      showSwitchWarning,
      editImageDetails,
      showPageModal,
      showPageArchiveWarning,
      showPageRestoreWarning,
      isPageDeleteLoading,
      showWidgetModal,
      appSearchText,
      showAppModal,
      isConfigDuplicating,
      showApiKeyModal,
      isPageDuplicating
    } = this.state

    const isListSaveDisable = _.size(details && details.configList) >= 1
    if (draggableId !== this.lastDraggable) {
      this.lastDraggable = draggableId
      this.randomKey = utilityService.makeRandomString(6)
    }
    let draggableWidget
    if (pageDetails && pageDetails.id === droppableId && draggableId) {
      draggableWidget = (listWidgets || []).find(item => item.id === draggableId.split('-random')[0])
    }
    if (draggableWidget) {
      draggableWidget = { ...draggableWidget, randomKey: this.randomKey }
    }
    return (
      <AppContext.Consumer>
        {({ permissions, project, userId }) => {
          const userPermissions = permissions['APP_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE_CONFIG') === -1
          const isUpdateConfigValueDisabled = userPermissions.indexOf('UPDATE_CONFIG_VALUES') === -1
          // const isCreateDisabled = userPermissions.indexOf('CREATE_CONFIG') === -1
          const isArchiveDisabled = userPermissions.indexOf('ARCHIVE_CONFIG') === -1
          const isUpdatePageDisabled = userPermissions.indexOf('UPDATE_PAGE') === -1
          const hasViewAccessEnabled = userPermissions.indexOf('VIEW_PAGE') !== -1
          const hasViewConfigEnabled = userPermissions.indexOf('VIEW_CONFIG') !== -1
          const isCreatePageDisabled = userPermissions.indexOf('CREATE_PAGE') === -1
          const isArchivePageDisabled = userPermissions.indexOf('ARCHIVE_PAGE') === -1
          const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
          const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
          return <React.Fragment>
            {(appMode === 'configs' && !_.isEmpty(details)) || (appMode === 'pages' && !_.isEmpty(pageDetails)) || (appMode === 'settings') ? <div className='container' id='app-container' ref={(node) => { this.containerRef = node }}>
              {appMode === 'configs' ? <AppHeading title={'Config'} /> : null}
              {appMode === 'settings' ? <AppHeading title={selectedAppSetting === 'Widgets' ? 'Widgets' : selectedAppSetting} /> : null}
              {appMode === 'configs' ? <Accordion
                title='General'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={loading}>
                    { <ConfigGeneralDetails details={details} onEditClick={this.toggleConfigModal} hasViewAccessEnabled={hasViewAccessEnabled} isUpdateDisabled={isUpdateDisabled} configMetaFieldList={configMetaFieldList} />}
                  </Skeleton>
                }
              /> : null}
              {appMode === 'pages'
                ? <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                  loading={pageLoading}>
                  {
                    <PageGeneralDetails
                      pageDetails={_.cloneDeep(pageDetails)}
                      onEditClick={this.togglePageModal}
                      isUpdateDisabled={isUpdatePageDisabled}
                      hasViewAccessEnabled={hasViewAccessEnabled}
                      isCreateDisabled={isCreatePageDisabled}
                      sourceIndex={sourceIndex}
                      destinationIndex={destinationIndex}
                      droppableId={pageDetails.id === droppableId ? droppableId : undefined}
                      draggable={pageDetails.id === droppableId ? draggableWidget : null}
                      appClient={appClient}
                      project={project}
                      handlePageLayoutItems={handlePageLayoutItems}
                      handlePageLayoutItemDelete={handlePageLayoutItemDelete}
                      handleLayoutItemStatus={handleLayoutItemStatus}
                      fetchAppPageDetails={fetchAppPageDetails}
                      handleEditPageLayoutItems={handleEditPageLayoutItems}
                      pageReorderLoading={pageReorderLoading}
                      pageMetaFieldList={pageMetaFieldList}
                      refetchHistory={this.props.refetchHistory}
                      handlePageLayoutItemPin={handlePageLayoutItemPin}
                      isCriticalWriteDisabled={isCriticalWriteDisabled}
                      isAppAdminDisabled={isAppAdminDisabled}
                      checkForPageModification={checkForPageModification}
                      fetchUpdatedPageUpdateTime={fetchUpdatedPageUpdateTime}
                      userId={userId}
                      setSelectedTaskIds={setSelectedTaskIds}
                      selectedTaskIds={selectedTaskIds}
                      resetPageState={resetPageState}
                    />
                  }
                </Skeleton>
                : null}
              {appMode === 'configs' && details && details.type === 'CONFIG_LIST' ? <Accordion
                title='List Items'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={loading}>
                    {!_.isEmpty(configList) ? <ListAppConfigs
                      hasViewAccessEnabled={hasViewConfigEnabled}
                      onDragEnd={this.onDragEnd}
                      onDoubleClick={this.onConfigInstanceEdit}
                      configClickMenu={isUpdateConfigValueDisabled ? () => {} : this.configEditClickMenu}
                      handleListConfigInstanceStatus={this.handleListConfigInstanceStatus}
                      configList={this.getSortedList(_.cloneDeep(configList))}
                    /> : <div className='list-app-config-container-empty'><Empty description='No items added' /></div>}
                  </Skeleton>
                }
              /> : null}
              {appMode === 'configs' && details && details.type === 'CONFIG' ? <Accordion
                title='Parameters'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={loading} >
                    <SingleAppConfigs
                      handleSingleConfigDetailsChange={handleSingleConfigDetailsChange}
                      fieldTypeList={fieldTypeList}
                      isUpdateDisabled={isUpdateConfigValueDisabled}
                      hasViewAccessEnabled={hasViewAccessEnabled}
                      singleConfigDetails={singleConfigDetails}
                      project={project}
                      handleShowAddImageModal={this.handleShowAddImageModal}
                      hideAddImageModal={this.hideAddImageModal}
                      saveCurrentImageSelection={this.saveCurrentImageSelection}
                      showImgEditorIcon={this.showImgEditorIcon}
                      hideImgEditorIcon={this.hideImgEditorIcon}
                      showAddImageModal={showAddImageModal}
                      showEditIcon={showEditIcon}
                      handleShowBucketModal={this.handleShowBucketModal}
                      showAddBucketModal={showAddBucketModal}
                      hideAddBucketModal={this.hideAddBucketModal}
                      handleBucketSelection={this.handleBucketSelection}
                      selectedBucket={selectedBucket}
                      saveCurrentBucketSelection={this.saveCurrentBucketSelection}
                      showImgEditor={this.showImgEditor}
                      hideImgEditor={this.hideImgEditor}
                      showImgEditModal={showImgEditModal}
                      details={details}
                      selectedBucketConfig={selectedBucketConfig}
                      selectedImgConfig={selectedImgConfig}
                      selectedImgEditConfig={selectedImgEditConfig}
                      editImageDetails={editImageDetails}
                      isJsonArrayValid={isJsonArrayValid}
                      jsonInvalidEntries={jsonInvalidEntries}
                      jsonArrayInvalidEntries={jsonArrayInvalidEntries}
                      isJsonArrayVisible={isJsonArrayVisible}
                      jsonConfig={jsonConfig}
                      jsonSelectedMeta={jsonSelectedMeta}
                      handleJsonArrayVisibilty={handleJsonArrayVisibilty}
                      handleJsonArrayHide={handleJsonArrayHide}
                    />
                  </Skeleton>
                }
              /> : null}
              {appMode === 'settings' && selectedAppSetting === 'Widgets'
                ? <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                  loading={false}>
                  <WidgetAppSettingList
                    showWidgetModal={showWidgetModal}
                    hideWidgetModal={this.hideWidgetModal}
                    handleShowWidgetModal={this.handleShowWidgetModal}
                    widgetSearchValue={widgetSettingSearchValue}
                    refetchHistory={this.props.refetchHistory}
                    onChangeWidgetFilter={onChangeWidgetSettingFilter}
                    changeWidgetSettingFilter={changeWidgetSettingFilter}
                    widgetSettingFilter={widgetSettingFilter}
                    appSearchText={appSearchText}
                    handleAppSearchTextChange={this.handleAppSearchTextChange}
                    appClient={appClient}
                    project={project}
                  />
                  {/* : <div className='list-app-config-container-empty'><Empty description='No items added' /></div>} */}
                </Skeleton> : null }
              {appMode === 'settings' && selectedAppSetting === 'Apps'
                ? <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                  loading={false}>
                  <AppSettingList
                    showAppModal={showAppModal}
                    hideAppModal={this.hideAppModal}
                    appClient={appClient}
                    project={project}
                    handleShowAppModal={this.handleShowAppModal}
                    onHistoryUpdate={this.props.refetchHistory}
                    isAppAdminDisabled={isAppAdminDisabled}
                  />
                </Skeleton> : null }
              {appMode === 'settings' && selectedAppSetting === 'Api Keys'
                ? <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                  loading={false}>
                  <AppAPIKeyList
                    showApiKeyModal={showApiKeyModal}
                    hideApiKeyModal={this.hideApiKeyModal}
                    appClient={appClient}
                    project={project}
                    handleShowApiKeyModal={this.toggleApiKeyModal}
                    onHistoryUpdate={this.props.refetchHistory}
                  />
                </Skeleton> : null }
              {appMode === 'configs' && details && details.type === 'CONFIG_LIST' ? <LoadingButton
                type='primary'
                onClick={showAddObjectModal}
                htmlType='submit'
                buttonText={'Add Object'}
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isUpdateConfigValueDisabled}
              /> : null}
            </div> : <Empty />}
            {appMode === 'configs' ? <div className='app-details-footer'>
              {details && !details.isArchived ? <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={() => this.handleArchiveClick(true)} isDisabled={!this.props.selectedConfig || (details && details.isArchived) || isArchiveDisabled} />} /> : null}
              {details && details.isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={() => this.handleRestoreClick(true)} isDisabled={!this.props.selectedConfig || isArchiveDisabled} />} /> : null }
              <ConfirmModal isVisible={showArchiveWarning} title={'ARCHIVE CONFIG'} message={'Are you Sure You Want to Archive Config?'} isLoading={isDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handleArchiveClick(false)} handleSubmit={this.handleArchiveAppConfig} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <ConfirmModal isVisible={showConfigDeleteWarning} title={'DELETE CONFIG LIST INSTANCE'} message={'Are you Sure You Want to Delete Config List Instance?'} isLoading={isConfigDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handleConfigDeleteClick(false)} handleSubmit={this.handleConfigDelete} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <ConfirmModal isVisible={showRestoreWarning} title={'RESTORE CONFIG'} message={'Are you Sure You Want to Restore Config?'} isLoading={isDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handleRestoreClick(false)} handleSubmit={this.handleRestoreAppConfig} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <div className='right'>
                <div className='side-button'>
                  <ButtonContainer displayTitle='Duplicate' isDisabled childComponent={<DuplicateButton onClick={() => this.handleConfigDuplicateClick(true)} isDisabled={isUpdateDisabled} />} />
                </div>
                {details && details.type === 'CONFIG_LIST' ? <div style={{ width: '150px' }}>

                  <LoadingButton type='primary' onClick={this.handleListBulkSubmit} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isConfigListSaveLoading} isDisabled={!isConfigListInstanceEdited || isUpdateConfigValueDisabled || !isListSaveDisable} />
                </div> : null}
                {details && details.type === 'CONFIG' ? <div style={{ width: '150px' }}>
                  <LoadingButton type='primary' onClick={handleSingleConfigSubmit} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isConfigSingleInstanctSaveLoading} isDisabled={!isConfigSingleInstanceEdited || isUpdateConfigValueDisabled} />
                </div> : null}
              </div>
            </div> : null}
            {appMode === 'pages' ? <div className='app-details-footer'>
              {pageDetails && !pageDetails.isArchived ? <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={() => this.handlePageArchiveClick(true)} isDisabled={!this.props.selectedPage || (pageDetails && pageDetails.isArchived) || isArchivePageDisabled} />} /> : null}
              {pageDetails && pageDetails.isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={() => this.handlePageRestoreClick(true)} isDisabled={!this.props.selectedPage || isArchivePageDisabled} />} /> : null }
              <ConfirmModal isVisible={showPageArchiveWarning} title={'ARCHIVE PAGE'} message={'Are you Sure You Want to Archive Page?'} isLoading={isPageDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handlePageArchiveClick(false)} handleSubmit={this.handleArchiveAppPage} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              {/* <ConfirmModal isVisible={showPageDuplicateWarning} title={'DUPLICATE PAGE'} message={'Are you Sure You Want to Duplicate Page?'} isLoading={isPageDuplicateLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handlePageDuplicateClick(false)} handleSubmit={this.handlePageDuplicate} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} /> */}
              <ConfirmModal isVisible={showPageRestoreWarning} title={'RESTORE PAGE'} message={'Are you Sure You Want to Restore Page?'} isLoading={isPageDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.handlePageRestoreClick(false)} handleSubmit={this.handleRestoreAppPage} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <div className='right'>
                <div className='side-button'>
                  <ButtonContainer displayTitle='Duplicate' isDisabled childComponent={<DuplicateButton onClick={() => this.handlePageDuplicateClick(true)} isDisabled={isUpdateDisabled} />} />
                </div>
                {pageDetails && appMode === 'pages' ? <div className='side-button-left'>
                  {currentPageVersionId !== 'current'
                    ? <LoadingButton type='primary' onClick={handlePageScheduleSubmit} htmlType='submit' buttonText={'Save'} buttonClass='save-btn' isLoading={isPageScheduleSaveLoading} isDisabled={isPagSaveDisable || isUpdatePageDisabled || !pageEditted || isPageScheduleSaveLoading} />
                    : <LoadingButton type='primary' onClick={handlePageSubmit} htmlType='submit' buttonText={pageDetails.isPublished ? `Save & Publish` : 'Save'} buttonClass='save-btn' isLoading={isPageSaveLoading} isDisabled={isPagSaveDisable || isUpdatePageDisabled || !pageEditted || isPageSaveLoading} />
                  }                  <LoadingButton type='secondary' onClick={() => togglePageScheduleModal(false, null)} htmlType='submit' buttonText={'Schedule'} buttonClass='schedule-btn' isLoading={false} isDisabled={isUpdatePageDisabled || !pageDetails.isPublished || _.size(pageDetails.scheduledVersions) === 2} />
                </div> : null}
              </div>
            </div> : null}
            {appMode === 'settings' ? <div className='app-details-footer'>
              <div className='right'>
                {selectedAppSetting === 'Widgets' ? <div style={{ width: '150px' }}>
                  <LoadingButton type='primary' onClick={this.handleShowWidgetModal} htmlType='submit' buttonText='New' buttonClass='save-btn' isLoading={false} isDisabled={false} />
                </div> : null}
                {selectedAppSetting === 'Apps' ? <div style={{ width: '150px' }}>
                  <LoadingButton type='primary' onClick={this.handleShowAppModal} htmlType='submit' buttonText='New' buttonClass='save-btn' isLoading={false} isDisabled={false} />
                </div> : null}
                {
                  selectedAppSetting === 'Api Keys' ? <div style={{ width: '150px' }}>
                    <LoadingButton type='primary' onClick={this.toggleApiKeyModal} htmlType='submit' buttonText='New' buttonClass='save-btn' isLoading={false} isDisabled={false} />
                  </div>
                    : null
                }
              </div>
            </div> : null}
            {appMode === 'configs' && details && details.type !== 'CONFIG' ? <AddObjectModal
              isUpdateBlocked={isUpdateConfigValueDisabled}
              hasViewAccessEnabled={hasViewAccessEnabled}
              handleCancel={hideAddObjectModal}
              isVisible={showObjectModal}
              fieldTypeList={fieldTypeList}
              handleObjectDetailsChange={handleConfigDetailsChange}
              objectDetails={configDetails}
              project={project}
              handleShowAddImageModal={this.handleShowAddImageModal}
              hideAddImageModal={this.hideAddImageModal}
              saveCurrentImageSelection={this.saveCurrentImageSelection}
              showImgEditorIcon={this.showImgEditorIcon}
              hideImgEditorIcon={this.hideImgEditorIcon}
              showAddImageModal={showAddImageModal}
              showEditIcon={showEditIcon}
              handleShowBucketModal={this.handleShowBucketModal}
              showAddBucketModal={showAddBucketModal}
              hideAddBucketModal={this.hideAddBucketModal}
              handleBucketSelection={this.handleBucketSelection}
              selectedBucket={selectedBucket}
              saveCurrentBucketSelection={this.saveCurrentBucketSelection}
              showImgEditor={this.showImgEditor}
              hideImgEditor={this.hideImgEditor}
              showImgEditModal={showImgEditModal}
              details={details}
              handleConfigObjectSave={handleConfigObjectSave}
              isConfigObjectSaveLoading={isConfigObjectSaveLoading}
              isConfigObjectSaveDisable={isConfigObjectSaveDisable}
              selectedBucketConfig={selectedBucketConfig}
              selectedImgConfig={selectedImgConfig}
              selectedImgEditConfig={selectedImgEditConfig}
              editImageDetails={editImageDetails}
              isJsonArrayValid={isJsonArrayValid}
              jsonInvalidEntries={jsonInvalidEntries}
              jsonArrayInvalidEntries={jsonArrayInvalidEntries}
              isJsonArrayVisible={isJsonArrayVisible}
              jsonConfig={jsonConfig}
              jsonSelectedMeta={jsonSelectedMeta}
              handleJsonArrayVisibilty={handleJsonArrayVisibilty}
              handleJsonArrayHide={handleJsonArrayHide}
            /> : null}
            {appMode === 'configs'
              ? <CreateAppConfigModal
                details={details}
                selectedConfig={selectedConfig}
                isFromEdit={!_.isEmpty(details)}
                typeList={typeList || []}
                appList={appList || []}
                groupList={groupList || []}
                isUpdateBlocked={isUpdateDisabled}
                isVisible={showConfigModal}
                hideConfigModal={this.hideConfigModal}
                // reCreatePageTree={this.reCreatePageTree}
                configMetaFieldList={configMetaFieldList}
                refetchHistory={this.props.refetchHistory}
                appClient={appClient}
                project={project}
                isDuplicating={isConfigDuplicating}
                handleRecreateConfigTree={handleRecreateConfigTree}
                onSelectConfigGroup={onSelectConfigGroup}
                isCriticalDisabled={isCriticalWriteDisabled}
                isAppAdminDisabled={isAppAdminDisabled}
                checkForConfigModification={checkForConfigModification}
                userId={userId}
              /> : null}
            {appMode === 'pages'
              ? <CreateAppPageModal
                details={_.cloneDeep(pageDetails)}
                selectedPage={selectedPage}
                isFromEdit={!_.isEmpty(pageDetails)}
                appList={appList || []}
                groupList={groupList || []}
                isUpdateBlocked={isUpdatePageDisabled}
                isVisible={showPageModal}
                hidePageModal={this.hidePageModal}
                // reCreatePageTree={this.reCreatePageTree}
                pageMetaFieldList={pageMetaFieldList}
                refetchHistory={this.props.refetchHistory}
                refetchPageList={refetchPageList}
                appClient={appClient}
                project={project}
                isDuplicating={isPageDuplicating}
                onSelectPageGroup={onSelectPageGroup}
                isCriticalWriteDisabled={isCriticalWriteDisabled}
                isAppAdminDisabled={isAppAdminDisabled}
                checkForPageModification={checkForPageModification}
                userId={userId}
              /> : null}
            {appMode === 'configs' ? <ConfirmModal
              isVisible={showSwitchWarning}
              title={`Config: ${selectedConfig}`}
              message={'Changes made by you will be discarded. Do you wish to continue ?'}
              isLeftPrior={false}
              leftButtonText={'Discard'}
              rightButtonText={'Save'}
              handleSubmit={this.forceSave}
              handleCancel={this.discardChanges}
              handleClose={this.onCloseConfirmModal}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false}
              isLoading={isConfigListSaveLoading || isConfigSingleInstanctSaveLoading}
            /> : null}
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AppDetails.propTypes = {

}

// export default AppDetails
export default withApollo(
  compose(
    graphql(MutationArchiveAppConfig, {
      options: ({ appClient, project, selectedConfig }) => {
        return {
          update: (cache, { data: { archiveAppConfig } }) => {
            const variables = { limit: 499, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppConfigList, variables }))
            if (cacheData && cacheData.listAppConfig && cacheData.listAppConfig.items) {
              const index = (cacheData.listAppConfig.items || []).findIndex(config => config.id === selectedConfig)
              cacheData.listAppConfig.items[index].isArchived = archiveAppConfig.isArchived
            }
            cache.writeQuery({
              query: QueryGetAppConfigList,
              data: cacheData,
              variables
            })
          },
          client: appClient
        }
      },
      props: props => ({
        updateAppConfig: (title, key, app, group, type, fields, id, tags, isArchived) => {
          return props.mutate({
            variables: { title, key, app, group, type, fields, project: props.ownProps.project, id, tags, isArchived }
          })
        }
      })
    }),
    graphql(MutationArchiveAppPage, {
      options: ({ appClient, project, selectedPage }) => {
        return {
          update: (cache, { data: { archiveAppPage } }) => {
            const variables = { limit: 499, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppPageList, variables }))
            if (cacheData && cacheData.listAppPages && cacheData.listAppPages.items) {
              const index = (cacheData.listAppPages.items || []).findIndex(config => config.id === selectedPage)
              cacheData.listAppPages.items[index].isArchived = archiveAppPage.isArchived
            }
            cache.writeQuery({
              query: QueryGetAppPageList,
              data: cacheData,
              variables
            })
          },
          client: appClient
        }
      },
      props: props => ({
        archiveAppPage: (title, key, app, group, id, isArchived) => {
          return props.mutate({
            variables: { title, key, app, group, project: props.ownProps.project, id, isArchived }
          })
        }
      })
    }),
    graphql(
      MutationBatchEditConfigInstance, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          batchEditConfigInstance: (input, app, appConfigId) => {
            return props.mutate({
              variables: { input, project: props.ownProps.project, app, appConfigId }
            })
          }
        })
      }
    ),
    graphql(
      MutationDeleteConfigInstance, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          deleteConfigInstance: (id, titleField, app, configId) => {
            return props.mutate({
              variables: { id, titleField, project: props.ownProps.project, app, configId }
            })
          }
        })
      }
    ),
    graphql(
      QueryListWidgets,
      {
        options: (props) => {
          const { project, appClient, pageDetails, widgetSearchValue } = props
          let app = pageDetails && pageDetails.app && pageDetails.app.id
          let variables = utilityService.getFormattedWidgetFilter(app, widgetSearchValue, project)
          return {
            fetchPolicy: 'network-only',
            variables,
            client: appClient
          }
        },
        props: (props) => {
          const { data } = props
          const listWidgets = data.listWidgets ? data.listWidgets.items : []
          return {
            listWidgets
          }
        }

      }
    )
    // graphql(
    //   QueryListWidgets,
    //   {
    //     skip: ({ selectedAppSetting }) => {
    //       return selectedAppSetting === 'settings'
    //     },
    //     options: (props) => {
    //       const { project, appClient, widgetSearchValue } = props
    //       let variables = utilityService.getFormattedWidgetSettingFilter('', widgetSearchValue, project)
    //       return {
    //         fetchPolicy: 'network-only',
    //         variables,
    //         client: appClient
    //       }
    //     },
    //     props: (props) => {
    //       const { data } = props
    //       const listWidgets = data.listWidgets ? data.listWidgets.items : []
    //       return {
    //         listWidgets
    //       }
    //     }

    //   }
    // )
  )(AppDetails))
