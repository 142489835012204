import React, { Component } from 'react'
import { Skeleton } from 'antd'

import moment from 'moment'

import Input from '../../../components/ui/dataEntry/inputs/Input'
import InformationIcon from '../../../components/ui/general/icons/InformationIcon'
import ButtonContainer from '../../../components/ui/general/buttons/ButtonContainer'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import { utilityService } from '../../../services/UtilityService'
import AppContext from '../../../AppContext'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetEpgConfigSetting from '../../../graphQL/admin/channel/getEpgConfigSetting'
import MutationUpdateEpgConfigSetting from '../../../graphQL/admin/channel/updateEpgConfigSetting'
import MutationTriggerEpgWorker from '../../../graphQL/admin/channel/triggerEpgWorker'

class Epg extends Component {
  constructor (props) {
    super(props)
    this.state = {
      epgDetails: props.selectedEpgConfig,
      isSave: false,
      isEditted: false,
      isSyncLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.selectedEpgConfig && !_.isEqual(newProps.selectedEpgConfig, this.props.selectedEpgConfig)) {
      this.setState({ epgDetails: newProps.selectedEpgConfig })
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.getEpgDetails)
  }

    onSaveEpg = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isSave: true })
      const { epgDetails } = this.state
      const newDetails = {
        id: epgDetails.id,
        url: epgDetails.url,
        frequency: epgDetails.frequency,
        fileName: epgDetails.fileName
      }
      this.props.updateEpgConfigSetting(newDetails).then(response => {
        onHistoryUpdate()
        this.setState({ isSave: false, isEditted: false })
      }, error => {
        utilityService.handleError(error)
        this.setState({ isSave: false, isEditted: false })
      })
    }

    onSyncEpg = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isSyncLoading: true })
      this.props.triggerEpgWorker().then(response => {
        onHistoryUpdate()
        if (!this.getEpgDetails || response.data.triggerEpgWorker === 'syncing') {
          this.getEpgDetails = setInterval(this.getEpgConfigDetails, 2 * 1000)
        }
        this.setState({ isSyncLoading: false })
      }, error => {
        utilityService.handleError(error)
        this.setState({ isSyncLoading: false })
      })
    }

    getEpgConfigDetails = () => {
      this.props.fetchGetEpgDetails().then(({ data }) => {
        if (data.getEpgConfigSetting && (data.getEpgConfigSetting.status === 'done' || data.getEpgConfigSetting.status === 'error')) {
          clearInterval(this.getEpgDetails)
        }
      })
    }

    handleGeneralChange = (e) => {
      this.setState({ isEdit: true })
      const { epgDetails } = this.state
      const { name, value } = e.target
      epgDetails[name] = value
      this.setState({ epgDetails, isEditted: true })
    }

    onHandleFrequency = (e) => {
      const { epgDetails } = this.state
      const { value } = e.target
      const regexExp = /^\d*[1-9]\d*$/
      const isNum = regexExp.test(value)
      epgDetails.frequency = isNum ? value : 1
      this.setState({ epgDetails, isEditted: true })
    }

    formatInput = (e) => {
      let checkIfNum
      if (e.key !== undefined) {
        checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'E'
      } else if (e.keyCode !== undefined) {
        checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189
      }
      return checkIfNum && e.preventDefault()
    }

    render () {
      const { epgDetails, isSave, isEditted, isSyncLoading } = this.state
      const { loading } = this.props
      const isSync = (epgDetails.status === 'done' || epgDetails.status === 'error') && !isEditted
      const frequency = <div className='type'>
        <span>Sync Frequency (Hours)</span>
        <div className='information-icon'>
          <ButtonContainer displayTitle={'Sync Frequency in Hours'} childComponent={<InformationIcon />} />
        </div>
      </div>
      return <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['DATA_MANAGER']
          //   const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <div className='epg'>
            <p className='epg-status'>{epgDetails.status === 'done' ? `Last Sync : ${moment(epgDetails.lastRunTime).format('DD MMM YYYY HH:mm:ss')}` : epgDetails.status }</p>
            <div className='epg-list'>
              <div className='epg-data-container'>
                <div className='epg-data'>
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 2 }} loading={loading}>
                    <Input title='EPG URL' isRequired placeholder='Insert URL' inputName='url' value={epgDetails.url} handleChange={this.handleGeneralChange} />
                    <Input title='File Name' isRequired placeholder='Enter File Name' inputName='fileName' value={epgDetails.fileName} handleChange={this.handleGeneralChange} />
                    <div className='input-field' id='input-field'>
                      <label> {frequency} </label>
                      <input type='number' value={epgDetails && epgDetails.frequency !== null ? parseInt(epgDetails.frequency) : null} onChange={this.onHandleFrequency} onKeyDown={this.formatInput} />
                    </div>
                  </Skeleton>
                </div>
                <div className='sync-button'>
                  <LoadingButton
                    type='primary'
                    onClick={this.onSyncEpg}
                    htmlType='submit'
                    buttonText={'Sync Now'}
                    buttonClass='save-btn'
                    isLoading={isSyncLoading}
                    isDisabled={!isSync || isUpdateDisabled || loading}
                  />
                </div>
              </div>
            </div>
            <div className='epg-footer' >
              <LoadingButton
                type='primary'
                onClick={this.onSaveEpg}
                htmlType='submit'
                buttonText={'Save'}
                buttonClass='save-btn'
                isLoading={isSave}
                isDisabled={(!isEditted || epgDetails.url === '') || epgDetails.status === 'syncing' || isUpdateDisabled || epgDetails.fileName === '' || !epgDetails.fileName}
              />
            </div>
          </div>
        }}
      </AppContext.Consumer>
    }
}
export default withApollo(compose(
  graphql(
    QueryGetEpgConfigSetting,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let selectedEpgConfig = data.getEpgConfigSetting ? { ...data.getEpgConfigSetting } : {}
        return {
          selectedEpgConfig,
          loading: data.loading,
          fetchGetEpgDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateEpgConfigSetting,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateEpgConfigSetting: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationTriggerEpgWorker,
    {
      options: (props) => ({
        update: (cache, { data: { triggerEpgWorker } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetEpgConfigSetting }))
          if (cacheData && cacheData.getEpgConfigSetting) {
            cacheData.getEpgConfigSetting.status = triggerEpgWorker
          }
          cache.writeQuery({
            query: QueryGetEpgConfigSetting,
            data: cacheData
          })
        }
      }),
      props: (props) => ({
        triggerEpgWorker: () => {
          return props.mutate({

          })
        }
      })
    }
  )

)(Epg))
