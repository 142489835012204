function pad (num, size = 2) {
  let numS = num.toString()
  while (numS.length < size) numS = '0' + numS
  return numS
}

export const formatDuration = (duration) => {
  const hrs = ~~(duration / (60 * 60)) | 0
  const minutes = ~~((duration % 3600) / 60)
  const seconds = ~~duration % 60
  return duration
    ? `${hrs ? pad(hrs) : ''}${hrs ? ':' : ''}${pad(minutes)}:${pad(seconds)}`
    : '00:00'
}
