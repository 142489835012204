import React from 'react'
import PropTypes from 'prop-types'

import MultilineEllipsis from './../../MultilineEllipse'

function PartnerListCellData (props) {
  const { partnerName, status, numOfOffers, partnerExtId } = props
  return (
    <div className='details-container'>
      <div className='details'>
        <span><MultilineEllipsis maxLines={'1'} text='Partner Name' /></span>
        <div className='partner-name'><MultilineEllipsis maxLines={'1'} text={partnerName || ''} /></div>
      </div>
      <div className='details'>
        <span><MultilineEllipsis maxLines={'1'} text='Status' /></span>
        <div className={status ? 'partner-status-value' : 'partner-status-novalue'}>{ status ? 'Active' : 'Inactive'}</div>
      </div>
      <div className='details'>
        <span><MultilineEllipsis maxLines={'1'} text='No Of Offers' /></span>
        <div className='partner-offers'>{numOfOffers || '00'}</div>
      </div>
      <div className='details'>
        <span><MultilineEllipsis maxLines={'1'} text='ID' /></span>
        <div className='partner-externalId'><MultilineEllipsis maxLines={'1'} text={partnerExtId || ''} /></div>
      </div>
    </div>
  )
}

PartnerListCellData.propTypes = {
  /** Name of selected Partner. */
  partnerName: PropTypes.string,
  /** external Id of selected Partner. */
  partnerExtId: PropTypes.string,
  /** status of Partner. */
  status: PropTypes.bool,
  /** number of Offers of partner. */
  numOfOffers: PropTypes.number
}

export default PartnerListCellData
