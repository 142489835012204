import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import './../../ui.style.scss'

const Option = Select.Option

const PartnerAppDropDown = ({ onOptionSelect, displayParam, options, placeHolder, className, allowClear, defaultValue, parent, value, ...props }) => {
  return (
    <Select
      className={className ? 'multi-drop-down ' + className : 'multi-drop-down'}
      mode='multiple'
      allowClear={allowClear}
      value={value}
      style={{ width: '100%' }}
      placeholder={placeHolder}
      getPopupContainer={() => document.getElementById(parent)}
      onChange={onOptionSelect}
      {...props}
    >
      {(options || []).filter(item => !(value && value.length && value.includes(item.name))).map((option, index) => <Option key={option.id || index} value={option[displayParam]}>{option[displayParam]}</Option>)}
    </Select>
  )
}

PartnerAppDropDown.propTypes = {
  /** Options of Dropdown. */
  options: PropTypes.array.isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Class name */
  className: PropTypes.string,
  defaultValue: PropTypes.array
}

PartnerAppDropDown.defaultProps = {
  placeHolder: 'Select'
}

export default PartnerAppDropDown
