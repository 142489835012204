import React from 'react'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import { generateCroppedThumbnail } from '../../../util/util'
import EditIcon from '../general/icons/EditIcon'
import AddImageModal from '../dataEntry/other/AddImageModal'
import AppContext from '../../../AppContext'
import Input from '../dataEntry/inputs/Input'
import './../ui.style.scss'
import CrossIcon from '../general/icons/CrossIcon'
import AddIcon from '../general/icons/AddIcon'
import EditImageModal from '../dataEntry/other/EditImageModal'

const defaultImage = require('../../../assets/images/avatar-large-team-icon.png')

class TeamSeasonData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {}
    }
  }

  componentDidMount = () => {
    if (this.props.selectedTeam) {
      this.setState({
        details: _.cloneDeep(this.props.selectedTeam),
        logo: this.props.logo,
        awayJersey: this.props.awayJersey,
        homeJersey: this.props.homeJersey,
        customHomeVenue: this.props.venue
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedTeam && !_.isEqual(this.state.details, nextProps.selectedTeam)) {
      this.setState({
        details: _.cloneDeep(nextProps.selectedTeam)
      })
    }
    if (nextProps.logo && !_.isEqual(this.props.logo, nextProps.logo)) {
      this.setState({
        logo: nextProps.logo
      })
    }
    if (nextProps.awayJersey && !_.isEqual(this.props.awayJersey, nextProps.awayJersey)) {
      this.setState({
        awayJersey: nextProps.awayJersey
      })
    }
    if (nextProps.homeJersey && !_.isEqual(this.props.homeJersey, nextProps.homeJersey)) {
      this.setState({
        homeJersey: nextProps.homeJersey
      })
    }
    if (nextProps.venue && this.props.venue !== nextProps.venue) {
      this.setState({
        customHomeVenue: nextProps.venue
      })
    }
  }

  editProgram = type => {
    this.setState({ showAddImage: type })
  }

  hideAddImage = () => {
    this.setState({ showAddImage: undefined })
  }

  onAddMedia = (newMedia) => {
    let { showAddImage, homeJersey, awayJersey, logo, customHomeVenue } = this.state
    if (showAddImage === 'logo') {
      logo = newMedia[0]
    } else if (showAddImage === 'home') {
      homeJersey = newMedia[0]
    } else if (showAddImage === 'away') {
      awayJersey = newMedia[0]
    }
    this.setState({ homeJersey, awayJersey, logo, showAddImage: undefined }, () => this.props.onEditTeamMedia({ homeJersey, awayJersey, logo, customHomeVenue }))
  }

  showCrop = (e, type) => {
    e.stopPropagation()
    this.setState({ showCrop: type })
  }

  hideCrop = () => {
    this.setState({ showCrop: undefined })
  }

  showCropModal = (e, type) => {
    e.stopPropagation()
    this.setState({ showCrop: undefined, showEditImage: type })
  }

  hideImageEditor = () => {
    this.setState({ showEditImage: undefined })
  }

  removeImage = (e, type) => {
    e.stopPropagation()
    let { homeJersey, awayJersey, logo, customHomeVenue } = this.state
    if (type === 'logo') {
      logo = null
    } else if (type === 'home') {
      homeJersey = null
    } else {
      awayJersey = null
    }
    this.setState({ homeJersey, logo, awayJersey }, () => this.props.onEditTeamMedia({ homeJersey, awayJersey, logo, customHomeVenue }))
  }

  handleVenueChange = (e) => {
    let { homeJersey, awayJersey, logo, customHomeVenue } = this.state
    const { value } = e.target
    customHomeVenue = value
    this.setState({ customHomeVenue }, () => this.props.onEditTeamMedia({ homeJersey, awayJersey, logo, customHomeVenue }))
  }

  render () {
    const { query } = this.props
    const { showAddImage, showEditImage, awayJersey, homeJersey, logo, customHomeVenue } = this.state
    const logoImage = logo ? generateCroppedThumbnail(logo, 100, 100, '1:1') : defaultImage
    const awayJerseyImage = awayJersey ? generateCroppedThumbnail(awayJersey, 100, 100, '1:1') : defaultImage
    const homeJerseyImage = homeJersey ? generateCroppedThumbnail(homeJersey, 100, 100, '1:1') : defaultImage
    const queryParams = { ...query, imageKeyName: showEditImage === 'home' ? 'homeJersey' : showEditImage === 'away' ? 'awayJersey' : 'logo' }
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissionsContent = permissions['CONTENT_BANK']
          const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
          const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
          return <div className='team-season-data'>
            <div className='venue'>
              <Input title='Venue Name' placeholder='Write a title...' inputName='venueName' value={customHomeVenue} handleChange={this.handleVenueChange} />
            </div>

            <div className='team-image-horizontal'>
              <div className='team'>
                <label> Logo </label>
                <div className='jersey'>
                  {this.state.showCrop === 'logo' ? <div className='avatar-hover' onClick={(e) => logo ? this.showCropModal(e, 'logo') : this.editProgram('logo')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ logo ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={logo ? logoImage : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'logo')} onMouseLeave={() => this.hideCrop()} />}
                  { logo ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'logo')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
              <div className='team'>
                <label> Home </label>
                <div className='jersey'>
                  {this.state.showCrop === 'home' ? <div className='avatar-hover' onClick={(e) => homeJersey ? this.showCropModal(e, 'home') : this.editProgram('home')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ homeJersey ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={homeJersey ? homeJerseyImage : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'home')} onMouseLeave={() => this.hideCrop()} />}
                  { homeJersey ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'home')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
              <div className='team'>
                <label> Away </label>
                <div className='jersey'>
                  {this.state.showCrop === 'away' ? <div className='avatar-hover' onClick={(e) => awayJersey ? this.showCropModal(e, 'away') : this.editProgram('away')} onMouseLeave={() => this.hideCrop()}>
                    <div className='crop-icon'>{ awayJersey ? <EditIcon /> : <AddIcon /> }</div>
                  </div> : <img src={awayJersey ? awayJerseyImage : defaultImage} onClick={this.editProgram}
                    onMouseEnter={(e) => this.showCrop(e, 'away')} onMouseLeave={() => this.hideCrop()} />}
                  { awayJersey ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e, 'away')} className='oval'>
                    <CrossIcon color='#fff' height={14} width={14} />
                  </div> : null}
                </div>
              </div>
            </div>
            <AddImageModal
              isVisible={showAddImage}
              handleCancel={this.hideAddImage}
              handleSubmit={this.onAddMedia} isSubmitButtonDisabled={isCreateDisabledContent} isCancelButtonDisabled={false}
              mediaType={'IMAGE'}
              project={project}
            />
            <EditImageModal
              type={'IMAGE'}
              isVisible={showEditImage}
              hideImageEditor={this.hideImageEditor}
              imageId={showEditImage === 'home' ? homeJersey.id : showEditImage === 'away' ? awayJersey.id : showEditImage === 'logo' ? logo.id : null}
              mediaDetails={showEditImage === 'home' ? homeJersey : showEditImage === 'away' ? awayJersey : logo}
              query={queryParams}
              isUpdateBlocked={isUpdateDisabledContent}
              isUploadBlocked={isCreateDisabledContent}
              project={project}
            />
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

TeamSeasonData.propTypes = {

}

TeamSeasonData.defaultProps = {
}

export default TeamSeasonData
