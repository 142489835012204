import gql from 'graphql-tag'

export default gql(`
query listBucketGroups($limit: Int $project: String) {
    listBucketGroups(
      limit: $limit,
      project: $project
      filter: {
        isArchived: {
          ne: true
        }
      }
    ) {
      items {
        id
        name
        type
        key
        project
        isArchived
        bucketGroupItems{
          id
          position
          bucketId
          bucket{
            displayName
            name
            key
            id
            isArchived
          }
        }
      }
    }
}`)
