import React from 'react'
import { RequireNewPassword as ResetPassword } from 'aws-amplify-react'
import { Auth } from 'aws-amplify'
import { message } from 'antd'
import ResetPasswordForm from '../../components/ui/dataEntry/forms/ResetPasswordForm'
import AddMfaDevices from '../../components/ui/feedback/AddMfaDevicesModal'
import userMessages from './../../constants/messages'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryUserSettings from '../../graphQL/mfaSettings/checkUser2faStatus'
import { utilityService } from '../../services/UtilityService'
import './Auth.scss'

const signedInState = 'signedIn'
const requirePasswordState = 'requireNewPassword'

// let forceMFA = null

class RequireNewPassword extends ResetPassword {
  constructor (props) {
    super(props)
    this.state = {
      isSuccess: false
    }
    this._validAuthStates = [ requirePasswordState ]
    // forceMFA = window.config.forceMFA
  }

    onSuccess = async (password) => {
      const user = this.props.authData
      const attributes = {
        email: user.username,
        name: user.username,
        'phone_number': ''
      }
      Auth.completeNewPassword(user, password, attributes)
        .then(data => {
          // this.changeState(signedInState)
          const project = utilityService.getCurrentProject()
          this.props.getUserMfaStatus({ user: user.username, project }).then((response) => {
            message.success(userMessages.AUTH_PASSWORD_SET_SUCCESS)
            if (!response.data.checkUserAccess.projectAccess) {
              message.error(`You don't have access to this to project`)
              // this.onLogout()
              this.setState({ isSuccess: true })
              return
            }
            if (response.data.checkUserAccess.mfaStatus) {
              this.changeState(signedInState)
            } else {
              this.setState({ isEnableMfaLoading: true, user: data })
              Auth.setupTOTP(data).then(code => {
                const domainName = utilityService.getEnvironment()
                const accountName = domainName ? `Optus CMS - ${domainName}` : 'Optus CMS'
                const authCode = `otpauth://totp/${accountName}:${data.username}?secret=${code}&issuer=${accountName}`
                // const authCode = 'otpauth://totp/AWSCognito:' + data.username + '?secret=' + code + '&issuer=AWSCognito'
                this.setState({ qrCode: authCode, isMfaDeviceModalVisible: true, isEnableMfaLoading: false, isSuccess: true, secretKey: code })
              })
            }
          })
        })
        .catch(err => {
          message.error(err.message)
          this.setState({ isSuccess: true })
        })
    }

    onContinueMfa = async (isMfaDeviceModalVisible) => {
      this.setState({ isMfaDeviceModalVisible })
    }

    confirmLogin = async (code, code2) => {
      const { user } = this.state
      try {
        this.setState({ isAddDeviceModalLoading: true })
        Auth.verifyTotpToken(user, code).then(() => {
          Auth.setPreferredMFA(user, 'TOTP').then(() => {
            this.setState({ qrCode: '', isMfaDeviceModalVisible: false, isAddDeviceModalLoading: false, secretKey: '' })
            this.changeState(signedInState, user)
          })
        }, error => {
          const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
          message.error(errorMessage)
          this.setState({ isAddDeviceModalLoading: false })
        })
      } catch (error) {
        const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
        message.error(errorMessage)
      }
    }

    showComponent () {
      const { isMfaDeviceModalVisible, isAddDeviceModalLoading, qrCode, secretKey } = this.state
      return (
        <div className='forgot'>
          <div className='login-form-align'>
            <ResetPasswordForm onSuccess={this.onSuccess} formSuccess={this.state.isSuccess} />
          </div>
          <AddMfaDevices
            isVisible={isMfaDeviceModalVisible}
            handleCancel={() => this.onContinueMfa(false)}
            qrCode={qrCode}
            secretKey={secretKey}
            isLoading={isAddDeviceModalLoading}
            isCancelNotVisible
            handleSubmit={this.confirmLogin} />
        </div>
      )
    }
}

// export default RequireNewPassword
export default withApollo(compose(
  graphql(
    QueryUserSettings,
    {
      options: () => {
        const project = utilityService.getCurrentProject()
        return {
          fetchPolicy: 'network-only',
          variables: { user: '', project }
        }
      },
      props: (props) => {
        return {
          getUserMfaStatus: (user) => {
            return props.data.refetch(user)
          }
        }
      }
    }
  )

)(RequireNewPassword))
