import React from 'react'
import PropTypes from 'prop-types'

const DropIcon = ({ width, height, isEnabled, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={isEnabled ? onClick : () => {}}>
    <path fill={isEnabled ? '#ff015a' : '#afafaf'} fillRule='nonzero' d='M19.079 12.563h-6.517a.563.563 0 0 1 0-1.126h6.518l-2.04-2.04a.563.563 0 0 1 .795-.796l3 3a.563.563 0 0 1-.013.809l-2.987 2.987a.558.558 0 0 1-.795 0 .563.563 0 0 1 0-.795l2.039-2.04zM8.437 21H5.062A2.064 2.064 0 0 1 3 18.937V5.063C3 3.925 3.925 3 5.062 3h3.375c1.138 0 2.063.925 2.063 2.063v13.874A2.064 2.064 0 0 1 8.437 21zM5.062 4.125a.939.939 0 0 0-.937.938v13.874c0 .517.42.938.937.938h3.375c.517 0 .938-.42.938-.938V5.063a.939.939 0 0 0-.938-.938H5.062z' />
  </svg>
)

DropIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  isEnabled: PropTypes.bool
}

DropIcon.defaultProps = {
  width: 24,
  height: 24
}

export default DropIcon
