import gql from 'graphql-tag'

export default gql(`
mutation($id: ID! $externalId: String $name: String $address: String $website: String $termsAndConditions: String $tagLine: String $apps: [String] $category: String $logo: String $images: [String]
    $shortDescription: String $mediumDescription: String $description: String $isActive: Boolean $chargeCode: String $refundChargeCode: String $customMeta: [MetaFieldInput] $slug: String $seoKeywords: [String] $seoMetaDescription: String
    $seoTitle: String $isArchived: Boolean $apps: [String] $project: String) {
    updatePartner(
        id: $id
        project: $project
        input: {
            externalId: $externalId
            name: $name
            address: $address
            website: $website
            termsAndConditions: $termsAndConditions
            tagLine: $tagLine
            apps: $apps
            category: $category
            logo: $logo
            images: $images
            shortDescription: $shortDescription
            mediumDescription: $mediumDescription 
            description: $description 
            isActive: $isActive 
            chargeCode: $chargeCode 
            refundChargeCode: $refundChargeCode
            customMeta: $customMeta 
            slug: $slug
            seoKeywords: $seoKeywords 
            seoMetaDescription: $seoMetaDescription
            seoTitle: $seoTitle  
            isArchived: $isArchived
            apps: $apps
        }
        ) {
            id
            externalId
            name
            address
            website
            termsAndConditions
            tagLine
            apps
            category
            offerCount
            logo{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
            }
            images{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
            }
            shortDescription
            mediumDescription
            description
            isActive
            chargeCode
            refundChargeCode
            slug
            seoKeywords
            seoMetaDescription
            seoTitle
            isArchived
            attributes{
                key
                id
                type
                limit
                value
                toggleValue 
                partnerId
                name
                label
                position
            }
            customMeta{
                config{
                    id
                    name
                    type
                    section
                    default
                }
                value
            }
        }
    }
    `)
