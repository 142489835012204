import gql from 'graphql-tag'

export default gql(`
    query Query($user: String! $limit: Int $offset: Int $project: String){
        getUserNotifications( 
            user: $user
            limit: $limit
            offset: $offset
            project: $project
        ) {
        items {
            id
            type
            priority
            message
            dismissed
            createdAt
            updatedAt
        }
        totalCount
    }
}`)
