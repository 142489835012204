import gql from 'graphql-tag'

export default gql(`
query searchOffersQuery($filter: ListOfferInput, $offset: Int, $limit: Int, $orderBy: String, $order: String, $search: SearchInput, $project: String) {
  listOffers(
      filter: $filter,
      limit: $limit,
      offset: $offset,
      orderBy: $orderBy,
      order: $order,
      search: $search,
      project: $project
    ) {
      items {
        id
        externalId
        name
        shortDescription
        isArchived
        partner{
            id,
            name,
            logo{
              id
              mediaId
              project
              name
              url
              fileName
              aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                  }
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2          
              }
          }
        },
        }
      totalCount
    }
}`)
