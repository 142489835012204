import gql from 'graphql-tag'

export default gql(`
    mutation batchEditAppInstance($input: [editAppInput] $project: String ) {
        batchEditAppInstance(
            input: $input
            project: $project
        ) {
            id
            name
            project
            updatedAt
            url
            key
            position
            description
            createdAt
            bundleId
            configUsageCount
            pageUsageCount
            widgetUsageCount
            isCritical
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
            appIcon{
              id
              mediaId
              project
              name
              url
              fileName
              aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                  }
              }
              settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2          
              }
            }
        }
    }`
)
