import gql from 'graphql-tag'

export default gql(`
mutation updateRating($scheme: String!, $rating: [String!], $subRatings: [String!], $project: String, $id: ID!, $project: String) {
    updateRating(
        input: {
            scheme: $scheme,
            rating: $rating,
            subRatings: $subRatings
        },
        id: $id,
        project: $project
    ) {
        id
        scheme
        rating
        subRatings
        assetCount
    }
}`
)
