import gql from 'graphql-tag'

export default gql(`
query listAudit($contentId: ID $limit: Int $offset: Int $module: MODULE_TYPE $project: String){
    listAudits(
        limit: $limit
        offset: $offset
        contentId: $contentId,
        module: $module
        project: $project
    ){
        items{
            id
            contentName
            action
            updatedAt
            updatedBy
            updatedObj{
                isPosChange
                name
                value
                oldValue
                fieldName
            }
        }
        totalCount
    }
}`)
