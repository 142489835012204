import gql from 'graphql-tag'

export default gql(`
    mutation createAppConfig($title: String $key: String! $app: ID! $group: ID $type: AppConfigType $project: String $fields: [ConfigModelFieldInput] $tags: [ID] $customMeta: [MetaFieldInput] $isCritical: Boolean $assignedUsers: [String]) {
        createAppConfig(
            input: {
                title: $title
                key: $key
                app: $app
                group: $group
                type: $type
                fields: $fields
                tags: $tags
                customMeta: $customMeta
                isCritical: $isCritical
                assignedUsers: $assignedUsers
            },
            project: $project
        ) {
            id
            isArchived
            isPublished
            createdBy
            createdAt
            updatedAt
            updatedBy
            assignedUsers
            key
            project
            publishEndDate
            title
            type
            isCritical
            group{
              key
              name
              type
              assignedUsers
            }
            fields{
                id
                type
                isRequired
                useAsTitle
                name
                position
            }
            tags{
                key
                name
                type
                module
                assetCount
                isTransferring
                isError
            }
            customMeta{
                config{
                    id
                    name
                      type
                      section
                    default
                      project
                      usageCount
                    }
                value
                name
            }
        }
    }`
)
