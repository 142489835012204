import gql from 'graphql-tag'

export default gql(`
    mutation deleteGroup($key: ID! $project: String $app: ID!) {
        deleteGroup(
            key: $key
            project: $project
            app: $app
        ) {
            id
            key
            name
            type
            app{
                id
                name
                url
            }
        }
    }`
)
