import gql from 'graphql-tag'

export default gql(`
  query getPermissions($id: String, $project: String){
    getUserPermissions(input: {
        id: $id
        project: $project
      }){
        userId
        superAdmin
        permissions{
            CHANNEL_MANAGER
            ALL
            ASSET_MANAGER
            BUCKET_MANAGER
            ROLE_MANAGER
            CONTENT_BANK
            MATCH_MANAGER
            DATA_MANAGER
            CUSTOMER_MANAGER
            JOB_MANAGER
            PARTNER_MANAGER
            OFFER_MANAGER
            INGEST_MANAGER
            APP_MANAGER
            MONITOR_MANAGER
            DRM_MANAGER
        }
      }
  }`
)
