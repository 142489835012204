import gql from 'graphql-tag'

export default gql(`
      mutation($assetId: ID $groupId: ID! $isEnabled: Boolean $startDate: AWSDateTime $endDate: AWSDateTime $project: String) {
        createPublishRule(
            input:{
              assetId: $assetId
              group: $groupId
              isEnabled: $isEnabled
              publishStartDate: $startDate
              publishEndDate: $endDate
              project: $project
            }            
          )
          {
            id
            assetId
            group{
              id
            }
            isEnabled
            publishEndDate
            publishStartDate
          }
      }`
)
