import gql from 'graphql-tag'

export default gql(`
    mutation($name:String! $channel:ID $assetId:ID! $programId:ID $startTime:AWSDateTime $endTime:AWSDateTime $site: VOD_SITE $isLive: Boolean $jobType: String $fileKey: String $tags: [String] $mediaCategory: ID $jobType: String $originalFileSize: String $assetType: String $drmRequired: Boolean $timestamp: String) {
        triggerLiveToVod(
            input: {
                channel: $channel
                site: $site
                assetId: $assetId
                assetType: $assetType
                programId: $programId
                name: $name
                startTime: $startTime
                endTime: $endTime
                isLive: $isLive
                jobType: $jobType
                fileKey: $fileKey
                tags: $tags
                mediaCategory: $mediaCategory
                jobType: $jobType
                originalFileSize: $originalFileSize
                drmRequired: $drmRequired,
                timeStamp: $timestamp
            }
        ) {
            id
            channel{
                id
                name
            }
            name
            asset{
                id
                title
                match{
                    id
                    matchDate
                    opId
                    homeTeam {
                        id
                        opId
                        code
                        officialName  
                        media {
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                  url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2          
                            }
                        }
                    }
                    awayTeam {
                        id
                        opId
                        code
                        officialName   
                        media {
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                  url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2          
                            }
                        }     
                    }
                }
            }
            createdAt
            vwmJobId
            status
            updatedAt
            errorDesc
            startTime
            endTime
            site
            type
            validations{
                error
                status
                streamStatus
            }
            mediaId{
                id
            }
            jobType
            progress
            createdBy
            relatedJob{
                id
                vwmJobId
            }
            lastReceivedStatus
            parentJob
            jobStates{
                status
                errorCode
                updatedAt
            } 
        }
    }`
)
