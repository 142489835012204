import gql from 'graphql-tag'

export default gql(`
    mutation($contents: [CreateStreamInput], $channelId: ID) {
        batchCreateStream(input: $contents, channelId: $channelId ) {
            id
            fields {
                name
                type
                isRequired
                value
            }
            url
            levelVersions{
                streamId
                version
            }
            streamSchema{
                id
                levels{
                    id
                    name
                    
                    fields{
                        name
                        value
                        isRequired
                        type
                    }
                }
            }
        }
    }`
)
