import gql from 'graphql-tag'

export default gql(`
    mutation deleteCountry($id: ID!, $name: String, $project: String) {
        deleteCountry(
                id: $id,
                name: $name,
                project: $project
        ) {
            id
            name
        }
    }`
)
