import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    getAsset(
        id: $id
        project: $project
    ) {
        id
        markInTime
        markOutTime
        drmRequired
        site
    }
}`)
