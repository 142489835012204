import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'
import _ from 'lodash'
import Input from '../../components/ui/dataEntry/inputs/Input'
import InputNumber from './../../components/ui/dataEntry/inputs/InputNumber'
import OfferAttributeIcon from './../../components/ui/general/icons/OfferAttributeIcon'

class OfferAttributes extends React.Component {
  getAssetMetaFields = (metaData, selectedAssetType) => {
    const metaFields = selectedAssetType
    const { handleAttributeDetailsChange } = this.props
    return (metaFields || []).map((meta, index) => {
      const selectedMeta = (metaData || []).find(item => item.name === meta.name && item.type === meta.type)
      if (meta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{meta.label}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value === 'true' ? true : selectedMeta.value) : false} onChange={(value) => handleAttributeDetailsChange(meta, value)} />
          </div>
        </div>
      } else if (meta.type === 'STRING') {
        return (<div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <Input
              limit={50}
              key={index}
              title={meta.label}
              placeholder={`Enter ${meta.label}`}
              inputName='title'
              isRequired={meta.isRequired}
              value={selectedMeta ? selectedMeta.value : ''}
              handleChange={({ target }) => handleAttributeDetailsChange(meta, target.value)}
              isError={selectedMeta ? selectedMeta.isError : false}
              errorMessage={''}
            />
          </div>
        </div>)
      } else if (meta.type === 'NUMBER') {
        return (
          <div className='switch-container input-field' key={index}>
            <div className='switch-inner-container'>
              <InputNumber
                limit={16}
                key={index}
                title={meta.label}
                placeholder={`Enter ${meta.label}`}
                inputName='title'
                isRequired={meta.isRequired}
                value={selectedMeta ? parseInt(selectedMeta.value) : null}
                handleChange={(value) => handleAttributeDetailsChange(meta, value)}
                isError={selectedMeta ? selectedMeta.isError : false}
                errorMessage={''}
              />
            </div>
          </div>
        )
      }
    })
  }
  render () {
    const {
      attributes,
      attributesData
    } = this.props
    return (
      <div className='offer-attributes'>
        {_.isEmpty(attributesData) ? <div className='offer-attribute-icon'>
          <OfferAttributeIcon width={145} height={90} color={'#FAFAFA'} />
          {attributesData ? <p>Selected partner does not have offer attributes</p> : <p>Choose a partner to show offer attributes</p>}
        </div> : null}
        { attributesData ? this.getAssetMetaFields(attributes, attributesData) : null}
      </div>
    )
  }
}

OfferAttributes.propTypes = {
  /** Offer details of selected Offer. */
  attributes: PropTypes.array,
  /** function to handle attribute change */
  handleAttributeDetailsChange: PropTypes.func,
  /** sample data */
  attributesData: PropTypes.array
}
export default OfferAttributes
