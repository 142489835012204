import gql from 'graphql-tag'

export default gql(`
    mutation updateContentBankSettings( $accessKey: String $region: String $s3BucketName: String $secretAccessKey: String) {
        updateContentBankSettings(
            input: {
                accessKey: $accessKey
                region: $region
                s3BucketName: $s3BucketName
                secretAccessKey: $secretAccessKey
            }
        ) {
            id
            accessKey
            region
            s3BucketName
            secretAccessKey
        }
    }`
)
