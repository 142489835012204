import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, message } from 'antd'
import CropImageModal from './CropImageModal'
import EditImageContainer from './EditImageContainer'
import ConfirmModal from './../../feedback/ConfirmModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryAllTags from '../../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../../graphQL/asset/createTag'
// import QueryTagContent from '../../../../graphQL/asset/getContentTags'
import QueryTagType from './../../../../graphQL/admin/tagType/listTagType'
import QueryGetAssetDetails from '../../../../graphQL/asset/getAssetDetails'
import MutationUpdateAssetImage from '../../../../graphQL/asset/updateAssetImage'
import MutationUpdateAssetVideo from '../../../../graphQL/asset/updateAssetVideo'
import MutationUpdateMedia from '../../../../graphQL/content/updateMedia'
import MutationUpdateMediaLink from '../../../../graphQL/content/updateMediaSettings'
import MutationCreateAudit from './../../../../graphQL/asset/createAudit'
// import QueryAllRelatedMedia from '../../../../graphQL/content/getAllRelatedMedia'

import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

class EditImageModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSaving: false,
      settings: [],
      isEditVisible: false,
      isCropVisible: false,
      selectedFormat: 'JPG',
      selectedTags: [],
      filteredImageName: '',
      selectedCategory: '',
      selectedAspectRatio: null,
      itemSelected: {},
      isEnable: false,
      isDrmEnabled: false,
      isTagCreating: false,
      systemTags: [],
      adPositions: [],
      cuePoints: [],
      breakDurations: [],
      showWarning: false,
      shouldSave: false,
      ignoreWarning: false
      // editingAdPositionIndex: null,
      // editingCuePointIndex: null,
      // editingbreakDurationIndex: null
    }
  }

  resetState = () => {
    this.setState({
      settings: [],
      isSaving: false,
      isEditVisible: false,
      isCropVisible: false,
      selectedFormat: 'JPG',
      selectedTags: [],
      filteredImageName: '',
      selectedCategory: '',
      selectedAspectRatio: null,
      isEnable: false,
      isDrmEnabled: false,
      isTagCreating: false,
      adPositions: [],
      cuePoints: [],
      breakDurations: [],
      showWarning: false,
      shouldSave: false,
      ignoreWarning: false
      // editingAdPositionIndex: null,
      // editingCuePointIndex: null,
      // editingbreakDurationIndex: null
    })
  }

  formatAdManagementDetails=(details) => {
    if (details && details.length) {
      const formattedData = (details.split(',')).map(item => {
        const obj = {
          position: item,
          isError: false
        }
        return obj
      })
      return formattedData
    } else {
      return []
    }
  }

  componentDidMount = () => {
    if (this.props.mediaDetails) {
      const selectedTags = this.props.mediaDetails.tags ? this.props.mediaDetails.tags.filter(item => item) : []
      this.setState({
        isEditVisible: this.props.isVisible,
        filteredImageName: _.get(this.props.mediaDetails, 'name'),
        settings: _.get(this.props.mediaDetails, 'settings', []),
        selectedTags: selectedTags,
        adPositions: this.props.mediaDetails.adPositions ? this.formatAdManagementDetails(this.props.mediaDetails.adPositions) : [],
        cuePoints: this.props.mediaDetails.cuePoints ? this.formatAdManagementDetails(this.props.mediaDetails.cuePoints) : [],
        breakDurations: this.props.mediaDetails.breakDurations ? this.formatAdManagementDetails(this.props.mediaDetails.breakDuration) : []
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.mediaDetails && nextProps.mediaDetails.id === nextProps.imageId && !this.state.isSaving && !this.state.isEnable) {
      const selectedTags = nextProps.mediaDetails.tags ? nextProps.mediaDetails.tags.filter(item => item) : []
      const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        filteredImageName: _.get(nextProps.mediaDetails, 'name'),
        settings: _.get(nextProps.mediaDetails, 'settings', []),
        selectedTags: selectedTags,
        selectedCategory: nextProps.mediaDetails.category ? nextProps.mediaDetails.category.id : '',
        isDrmEnabled: nextProps.mediaDetails.drmContent,
        selectedAspectRatio,
        adPositions: this.formatAdManagementDetails(nextProps.mediaDetails.adPositions),
        cuePoints: this.formatAdManagementDetails(nextProps.mediaDetails.cuePoints),
        breakDurations: this.formatAdManagementDetails(nextProps.mediaDetails.breakDuration)
      })
    }
    if (nextProps.systemTags) {
      const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: enabledTags })
    }
    this.setState({ isEditVisible: nextProps.isVisible })
  };

  showCrop = (aspectRatio) => {
    this.setState({ selectedAspectRatio: aspectRatio, isCropVisible: true, isEditVisible: false })
  };

  selectAspectRatio = (aspectRatio) => {
    this.setState({ selectedAspectRatio: aspectRatio })
  };

  hideCrop = () => {
    this.setState({ isCropVisible: false, isEditVisible: true })
  };

  updateSettings = (item) => {
    var settings = [...this.state.settings]
    settings = settings.map(function (setting) {
      return setting.aspectRatio === item.aspectRatio ? item : setting
    })
    this.setState({ settings: settings, isCropVisible: false, isEditVisible: true, isEnable: true })
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { selectedTags } = this.state
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'CONTENT_BANK', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags, isTagCreating: true })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            this.setState({ selectedTags, isEnable: true, isTagCreating: false })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}content_bank`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags, isEnable: true, isTagCreating: false })
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
        }
      }
    }
    this.setState({ selectedTags, isEnable: true })
  }

  handleDrmChange = (isDrmEnabled) => {
    this.setState({ isDrmEnabled, isEnable: true })
  }

  handleTextChange = (event) => {
    this.setState({ filteredImageName: event.target.value, isEnable: true })
  }

  handleFormatChange = (option) => {
    this.setState({ selectedFormat: option, isEnable: true })
  }

  handleCategoryChange = (option) => {
    this.setState({ selectedCategory: option, isEnable: true })
  }

  onItemSelected = (itemSelected) => {
    this.setState({ itemSelected })
  }

  updateAspectRatioImage = (itemDetails) => {
    const item = cloneDeep(itemDetails)
    // TODO :: set isModified in graphql cache aswell
    item.isModified = true
    var settings = [...this.state.settings]
    settings = settings.map(function (setting) {
      return setting.aspectRatio === item.aspectRatio ? item : setting
    })
    this.setState({ settings: settings, isEditVisible: true, isEnable: true })
  }

  validateCuepointsandAdPositions=(adPositionArray, cuePointArray) => {
    let length = adPositionArray.length
    let inValidValue = false
    for (let i = 0; i < length; i++) {
      let tempArray = (adPositionArray[i].position).split(':')
      let convertedAdPosition = (parseInt(tempArray[0] * 60 * 60) + parseInt(tempArray[1] * 60) + parseInt(tempArray[2]))
      if (convertedAdPosition !== parseInt(cuePointArray[i].position)) {
        inValidValue = true
        this.setState({ showWarning: true })
        return inValidValue
      }
    }
    this.setState({ shouldSave: true })
    return inValidValue
  }

  handleSave = () => {
    var settings = this.state.settings ? _.cloneDeep(this.state.settings) : []
    const { mediaDetails, isFromAsset, type, selectedImageType } = this.props
    let { isDrmEnabled, shouldSave, ignoreWarning } = this.state
    const { selectedCategory, adPositions, cuePoints, breakDurations } = this.state
    isDrmEnabled = mediaDetails.type === 'VIDEO' ? isDrmEnabled : undefined
    // TODO: Need a better appraoch for handling typename
    settings.map(setting => {
      setting.outputFormat = 'JPG'
      delete setting['__typename']
      delete setting.isModified
    })
    if (mediaDetails.name === this.state.filteredImageName &&
      (mediaDetails.category && mediaDetails.category.id === this.state.selectedCategory) &&
      _.isEqual((mediaDetails.tags ? mediaDetails.tags.filter(item => item) : []), this.state.selectedTags) &&
      _.isEqual(mediaDetails.settings, this.state.settings) && _.isEqual(mediaDetails.adPositions, this.state.adPositions) && _.isEqual(mediaDetails.cuePoints, this.state.cuePoints) && _.isEqual(mediaDetails.breakDuration, this.state.breakDuration)) {
      this.resetState()
      this.props.hideImageEditor()
      return
    }

    const formattedAdPosition = (adPositions || []).map(item => {
      let value = ''
      if (item) {
        const splitArray = (item.position.trim()).split(':');
        (splitArray || []).forEach((innerItem, index) => {
          if (innerItem && innerItem.length > 0 && innerItem.length < 2) {
            value += '0' + innerItem + `${(index === splitArray.length - 1) ? '' : ':'}`
          } else {
            value += innerItem + `${(index === splitArray.length - 1) ? '' : ':'}`
          }
        })
        item.position = value
        let obj = {
          position: value,
          isError: item.isError
        }
        return obj
      }
    })
    this.setState({ adPositions: formattedAdPosition })

    const adPositionRegex = /([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]:[0-9][0-9]$/
    const cuePointRegex = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/

    let emptyAdValue = (adPositions || []).find(item => item.position === '' || item.isNew === true)
    let emptyCueValue = (cuePoints || []).find(item => item.position === '' || item.isNew === true)
    let emptyBreakValue = (breakDurations || []).find(item => item.position === '' || item.isNew === true)
    const updatedAdPositions = (adPositions || []).map(item => {
      if (item && ((adPositionRegex.test(item.position)) === false)) {
        item.isError = true
        return item
      } else if (item && item.position && ((adPositionRegex.test(item.position)) === true)) {
        item.isError = false
        return item
      }
    })
    const updatedCuePoints = (cuePoints || []).map(item => {
      if (item && ((cuePointRegex.test(item.position)) === false)) {
        item.isError = true
        return item
      } else if (item && item.position && ((cuePointRegex.test(item.position)) === true)) {
        item.isError = false
        return item
      }
    })
    const updatedBreakDurations = (breakDurations || []).map(item => {
      if (item && ((cuePointRegex.test(item.position)) === false)) {
        item.isError = true
        return item
      } else if (item && item.position && ((cuePointRegex.test(item.position)) === true)) {
        item.isError = false
        return item
      }
    })
    this.setState({ adPositions: updatedAdPositions, cuePoints: updatedCuePoints, breakDurations: updatedBreakDurations })

    let invalidAdPosition = (adPositions || []).find((item, index) => {
      if (item && item.isError && item.isError === true) {
        // item.isError=true
        let newItem = item
        newItem.index = index
        return newItem
      }
    })
    if (!_.isEmpty(invalidAdPosition)) {
      message.warn('AdPosition should be in format HH:MM:SS:FF at position ' + (invalidAdPosition.index + 1))
      // adPositions[invalidAdPosition.index].isError=true
      // this.setState({adPositions})
      return
    }
    let invalidCuePoint = (cuePoints || []).find((item, index) => {
      if (item && item.isError && item.isError === true) {
        // item.isError=true
        let newItem = item
        newItem.index = index
        return newItem
      }
    })
    if (!_.isEmpty(invalidCuePoint)) {
      message.warn('cuePoint should be in number format upto 3 decimals at position ' + (invalidCuePoint.index + 1))
      // cuePoints[invalidCuePoint.index].isError=true
      // this.setState({cuePoints})
      return
    }
    let invalidBreakDuration = (breakDurations || []).find((item, index) => {
      if (item && item.isError && item.isError === true) {
        // item.isError=true
        let newItem = item
        newItem.index = index
        return newItem
      }
    })
    if (!_.isEmpty(invalidBreakDuration)) {
      message.warn('breakDuration should be in number format upto 3 decimals for position ' + (invalidBreakDuration.index + 1))
      // breakDurations[invalidBreakDuration.index].isError=true
      // this.setState({breakDurations})
      return
    }
    // if (!_.isEmpty(invalidAdPosition)) {
    //   message.warn('AdPosition should be in format HH:MM:SS:FF')
    //   return
    // } else if (!_.isEmpty(invalidCuePoint)) {
    //   message.warn('cuePoint should be in number format upto 3 decimals')
    //   return
    // } else if (!_.isEmpty(invalidBreakDuration)) {
    //   message.warn('breakDuration should be in number format upto 3 decimals')
    //   return
    // }
    if (!_.isEmpty(emptyAdValue) || !_.isEmpty(emptyBreakValue) || !_.isEmpty(emptyCueValue)) {
      message.warn('Please remove empty or unsaved fields')
      return
    } else if (adPositions.length !== cuePoints.length) {
      message.warn('The number of adPositions and cuePoints must be equal')
      return
    } else if (cuePoints.length < breakDurations.length) {
      message.warn('The number of breakDurations must be less than or  equal to cuePoints')
      return
    }
    let warning
    if (ignoreWarning === false) {
      warning = this.validateCuepointsandAdPositions(adPositions, cuePoints)
    }

    let finalAdPositions = null
    let finalCuePoints = null
    let finalBreakDuration = null
    if (type === 'VIDEO') {
      finalAdPositions = ''
      finalCuePoints = ''
      finalBreakDuration = ''
    }
    (adPositions || []).forEach((item, index) => {
      if (index === adPositions.length - 1) {
        finalAdPositions += item.position.trim()
      } else {
        finalAdPositions += item.position.trim() + ','
      }
    });
    (cuePoints || []).forEach((item, index) => {
      if (index === cuePoints.length - 1) {
        finalCuePoints += item.position
      } else {
        finalCuePoints += item.position + ','
      }
    });
    (breakDurations || []).forEach((item, index) => {
      if (index === breakDurations.length - 1) {
        finalBreakDuration += item.position
      } else {
        finalBreakDuration += item.position + ','
      }
    })
    if (shouldSave || type === 'IMAGE' || !warning) {
      this.setState({ isSaving: true })
      const imageName = this.state.filteredImageName ? this.state.filteredImageName : null
      const newSettings = isFromAsset ? (_.cloneDeep(mediaDetails).settings || []).map(setting => {
        delete setting.__typename
        return setting
      }) : settings

      const mediaId = isFromAsset && mediaDetails.mediaId ? mediaDetails.mediaId : mediaDetails.id
      const assetId = mediaDetails.assetId ? mediaDetails.assetId : this.props.assetId

      let imageUpdateVariable = {
        id: mediaDetails.id,
        assetId,
        isDefault: mediaDetails.isDefault,
        mediaId,
        settings
      }
      if (mediaDetails.type) { imageUpdateVariable.imageTypeId = mediaDetails.type.id ? mediaDetails.type.id : selectedImageType ? selectedImageType.id : null }
      if (selectedCategory) { imageUpdateVariable.categoryId = selectedCategory }
      if (((mediaDetails.category && mediaDetails.category.id !== this.state.selectedCategory) || this.state.selectedCategory || !_.isEqual(mediaDetails.settings, this.state.settings)) && this.props.assetId) {
        this.props.updateImage(mediaId, imageName, this.state.selectedTags.map(tag => tag.key), newSettings, isDrmEnabled, finalAdPositions, finalCuePoints, finalBreakDuration).then(responseUpdateDetails => {
        // this.props.updateMediaLink(mediaId, this.props.assetId, newSettings, this.state.selectedCategory).then(response => {
          const responseUpdate = cloneDeep(responseUpdateDetails)
          if (isFromAsset) {
            if (type === 'VIDEO') {
              delete (imageUpdateVariable.settings)
              delete (imageUpdateVariable.imageTypeId)
              if (imageUpdateVariable.categoryId) {
                this.props.updateAssetVideo(imageUpdateVariable).then(({ data }) => {
                  this.setState({ isSaving: false })
                  this.resetState()
                  responseUpdate.data.updateMedia.assetId = data.updateAssetVideo.assetId
                  responseUpdate.data.updateMedia.mediaId = data.updateAssetVideo.mediaId
                  responseUpdate.data.updateMedia.id = data.updateAssetVideo.id
                  responseUpdate.data.updateMedia.isDefault = data.updateAssetVideo.isDefault
                  responseUpdate.data.updateMedia.type = data.updateAssetVideo.type
                  responseUpdate.data.updateMedia.category = data.updateAssetVideo.category
                  responseUpdate.data.updateMedia.cuePoints = data.updateAssetVideo.cuePoints
                  responseUpdate.data.updateMedia.adPositions = data.updateAssetVideo.adPositions
                  responseUpdate.data.updateMedia.breakDuration = data.updateAssetVideo.breakDuration
                  responseUpdate.data.updateMedia.cuePointsError = data.updateAssetVideo.cuePointsError
                  let updatedObj = []
                  if (this.props.mediaDetails.adPositions !== responseUpdate.data.updateMedia.adPositions) {
                    const newAuditItem = {
                      name: 'adPositions',
                      oldValue: this.props.mediaDetails.adPositions,
                      value: responseUpdate.data.updateMedia.adPositions,
                      fieldName: 'adManagement'
                    }
                    updatedObj.push(newAuditItem)
                  }
                  if (this.props.mediaDetails.cuePoints !== responseUpdate.data.updateMedia.cuePoints) {
                    const newAuditItem = {
                      name: 'cuePoints',
                      oldValue: this.props.mediaDetails.cuePoints,
                      value: responseUpdate.data.updateMedia.cuePoints,
                      fieldName: 'adManagement'
                    }
                    updatedObj.push(newAuditItem)
                  }
                  if (this.props.mediaDetails.breakDuration !== responseUpdate.data.updateMedia.breakDuration) {
                    const newAuditItem = {
                      name: 'breakDuration',
                      oldValue: this.props.mediaDetails.breakDuration,
                      value: responseUpdate.data.updateMedia.breakDuration,
                      fieldName: 'adManagement'
                    }
                    updatedObj.push(newAuditItem)
                  }
                  const id = this.props.assetId
                  const contentName = this.props.mediaDetails.mediaId
                  const variables = {
                    updatedObj: updatedObj,
                    contentId: id,
                    contentName: contentName,
                    project: this.props.project
                  }
                  if (!_.isEmpty(updatedObj)) {
                    this.props.createAudit(variables).then(() => { this.props.refetchHistory(true) }, error => {
                      utilityService.handleError(error)
                    })
                  }
                  this.props.hideImageEditor(responseUpdate.data.updateMedia, true)
                }, (error) => {
                  this.setState({ isSaving: false })
                  utilityService.handleError(error)
                })
              } else {
                this.setState({ isSaving: false })
                let updatedObj = []
                if (this.props.mediaDetails.adPositions !== responseUpdate.data.updateMedia.adPositions) {
                  const newAuditItem = {
                    name: 'adPositions',
                    oldValue: this.props.mediaDetails.adPositions,
                    value: responseUpdate.data.updateMedia.adPositions,
                    fieldName: 'adManagement'
                  }
                  updatedObj.push(newAuditItem)
                }
                if (this.props.mediaDetails.cuePoints !== responseUpdate.data.updateMedia.cuePoints) {
                  const newAuditItem = {
                    name: 'cuePoints',
                    oldValue: this.props.mediaDetails.cuePoints,
                    value: responseUpdate.data.updateMedia.cuePoints,
                    fieldName: 'adManagement'
                  }
                  updatedObj.push(newAuditItem)
                }
                if (this.props.mediaDetails.breakDuration !== responseUpdate.data.updateMedia.breakDuration) {
                  const newAuditItem = {
                    name: 'breakDuration',
                    oldValue: this.props.mediaDetails.breakDuration,
                    value: responseUpdate.data.updateMedia.breakDuration,
                    fieldName: 'adManagement'
                  }
                  updatedObj.push(newAuditItem)
                }
                const id = this.props.assetId
                const contentName = this.props.mediaDetails.mediaId
                const variables = {
                  updatedObj: updatedObj,
                  contentId: id,
                  contentName: contentName,
                  project: this.props.project
                }
                if (!_.isEmpty(updatedObj)) {
                  this.props.createAudit(variables).then(() => { this.props.refetchHistory(true) }, error => {
                    utilityService.handleError(error)
                  })
                }
                this.resetState()
                this.props.hideImageEditor(responseUpdate.data.updateMedia)
              }
            } else {
              this.props.updateAssetImage(imageUpdateVariable).then(({ data }) => {
                this.setState({ isSaving: false })
                this.resetState()
                responseUpdate.data.updateMedia.settings = data.updateAssetImage.settings
                responseUpdate.data.updateMedia.aspectRatio = data.updateAssetImage.aspectRatio
                responseUpdate.data.updateMedia.assetId = data.updateAssetImage.assetId
                responseUpdate.data.updateMedia.mediaId = data.updateAssetImage.mediaId
                responseUpdate.data.updateMedia.id = data.updateAssetImage.id
                responseUpdate.data.updateMedia.isDefault = data.updateAssetImage.isDefault
                responseUpdate.data.updateMedia.type = data.updateAssetImage.type
                responseUpdate.data.updateMedia.category = data.updateAssetImage.category
                this.props.hideImageEditor(responseUpdate.data.updateMedia)
              }, (error) => {
                console.log('error', error)
                this.setState({ isSaving: false })
                utilityService.handleError(error)
              })
            }
          } else {
            this.setState({ isSaving: false })
            this.resetState()
            this.props.hideImageEditor(responseUpdate.data.updateMedia)
          }
        // }, error => {
        //   this.setState({ isSaving: false })
        //   utilityService.handleError(error)
        // })
        }, error => {
          this.setState({ isSaving: false })
          utilityService.handleError(error)
        })
      } else {
        this.props.updateImage(mediaId, imageName, this.state.selectedTags.map(tag => tag.key), newSettings, isDrmEnabled, finalAdPositions, finalCuePoints, finalBreakDuration).then(responseData => {
          const responseUpdate = cloneDeep(responseData)
          // this.props.getAllRelatedMedia(mediaDetails.id).then(response => {
          if (isFromAsset) {
            if (type === 'VIDEO') {
              delete (imageUpdateVariable.settings)
              delete (imageUpdateVariable.imageTypeId)
              if (imageUpdateVariable.categoryId) {
                this.props.updateAssetVideo(imageUpdateVariable).then(({ data }) => {
                  this.setState({ isSaving: false })
                  this.resetState()
                  responseUpdate.data.updateMedia.assetId = data.updateAssetVideo.assetId
                  responseUpdate.data.updateMedia.mediaId = data.updateAssetVideo.mediaId
                  responseUpdate.data.updateMedia.id = data.updateAssetVideo.id
                  responseUpdate.data.updateMedia.isDefault = data.updateAssetVideo.isDefault
                  responseUpdate.data.updateMedia.type = data.updateAssetVideo.type
                  responseUpdate.data.updateMedia.category = data.updateAssetVideo.category
                  responseUpdate.data.updateMedia.cuePoints = data.updateAssetVideo.cuePoints
                  responseUpdate.data.updateMedia.adPositions = data.updateAssetVideo.adPositions
                  responseUpdate.data.updateMedia.breakDuration = data.updateAssetVideo.breakDuration
                  responseUpdate.data.updateMedia.cuePointsError = data.updateAssetVideo.cuePointsError
                  this.props.hideImageEditor(responseUpdate.data.updateMedia, true)
                }, (error) => {
                  this.setState({ isSaving: false })
                  utilityService.handleError(error)
                })
              } else {
                this.setState({ isSaving: false })
                this.resetState()
                this.props.hideImageEditor(responseUpdate.data.updateMedia)
              }
            } else {
              this.props.updateAssetImage(imageUpdateVariable).then(({ data }) => {
                this.setState({ isSaving: false })
                this.resetState()
                responseUpdate.data.updateMedia.settings = data.updateAssetImage.settings
                responseUpdate.data.updateMedia.aspectRatio = data.updateAssetImage.aspectRatio
                responseUpdate.data.updateMedia.assetId = data.updateAssetImage.assetId
                responseUpdate.data.updateMedia.mediaId = data.updateAssetImage.mediaId
                responseUpdate.data.updateMedia.id = data.updateAssetImage.id
                responseUpdate.data.updateMedia.dimensions = data.updateAssetImage.dimensions
                responseUpdate.data.updateMedia.isDefault = data.updateAssetImage.isDefault
                responseUpdate.data.updateMedia.type = data.updateAssetImage.type
                this.props.hideImageEditor(responseUpdate.data.updateMedia)
              }, (error) => {
                console.log('error', error)
                this.setState({ isSaving: false })
                utilityService.handleError(error)
              })
            }
          } else {
            this.setState({ isSaving: false })
            this.resetState()
            this.props.hideImageEditor(responseUpdate.data.updateMedia)
          }
        // }, error => {
        //   this.setState({ isSaving: false })
        //   utilityService.handleError(error)
        // })
        }, error => {
          this.setState({ isSaving: false })
          utilityService.handleError(error)
        })
      }
    } else {

    }
  }

  handleCancel = () => {
    this.resetState()
    this.props.hideImageEditor()
  }

  onAddNewAdPosition = () => {
    const { adPositions } = this.state
    const newAdPosition = {
      position: '',
      isError: false
    }
    adPositions.splice(0, 0, newAdPosition)
    this.setState({ adPositions })
  }

  onChangeAdPosition=(event, index) => {
    const { adPositions } = this.state
    adPositions[index].position = event.target.value
    // let enable
    // if (adPositions[index].isNew === true) {
    //   enable = adPositions[index]
    // }
    // if (!_.isEmpty(enable)) {
    //   this.setState({ adPositions })
    // } else {
    this.setState({ adPositions, isEnable: true })
    // }
  }

  // onSaveAdPosition=(adPosition) => {
  //   const { adPositions } = this.state
  //   const duplicateArr = adPositions.filter(item => item.position === adPosition.position)
  //   const adPositionRegex = /([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]:[0-9][0-9]$/g
  //   const validFormat = adPositionRegex.test(adPosition.position)
  //   if (!!duplicateArr && duplicateArr.length > 1) {
  //     message.warn('Please remove duplicate adPosition')
  //     return
  //   } else if (!validFormat) {
  //     message.warn('AdPosition should be in format HH:MM:SS:FF')
  //     return
  //   }
  //   adPositions[0].isLoading = true
  //   this.setState({ adPositions })
  //   setTimeout(() => {
  //     delete adPositions[0].isLoading
  //     delete adPositions[0].isNew
  //     this.setState({ adPositions, isEnable: true })
  //   }, 2000)
  // }

  onDeleteAdPosition=(adPosition, index) => {
    const { adPositions } = this.state
    if (adPositions[index].position === adPosition) {
      adPositions.splice(index, 1)
      this.setState({ adPositions, isEnable: true })
    }
  }

  // onClickEditAdPosition=(index)=>{
  //   this.setState({editingAdPositionIndex: index})
  // }

  onAddNewCuePoint = () => {
    const { cuePoints } = this.state
    const newCuePoint = {
      position: '',
      // isNew: true
      isError: false
    }
    cuePoints.splice(0, 0, newCuePoint)
    this.setState({ cuePoints })
  }

  onChangeCuePoint=(event, index) => {
    const { cuePoints } = this.state
    cuePoints[index].position = event.target.value
    // let enable
    // if (cuePoints[index].isNew === true) {
    //   enable = cuePoints[index]
    // }
    // if (!_.isEmpty(enable)) {
    //   this.setState({ cuePoints })
    // } else {
    this.setState({ cuePoints, isEnable: true })
    // }
  }

  // onSaveCuePoint=(cuePoint) => {
  //   const { cuePoints } = this.state
  //   const duplicateArr = cuePoints.filter(item => item.position === cuePoint.position)
  //   const cuePointRegex = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/g
  //   const validFormat = cuePointRegex.test(cuePoint.position)
  //   if (!!duplicateArr && duplicateArr.length > 1) {
  //     message.warn('Please remove duplicate cuePoint')
  //     return
  //   } else if (!validFormat) {
  //     message.warn('Please enter valid cuePoint in number format upto 3 decimals')
  //     return
  //   }
  //   cuePoints[0].isLoading = true
  //   this.setState({ cuePoints })
  //   setTimeout(() => {
  //     delete cuePoints[0].isLoading
  //     delete cuePoints[0].isNew
  //     this.setState({ cuePoints, isEnable: true })
  //   }, 2000)
  // }

  onDeleteCuePoint=(cuePoint, index) => {
    const { cuePoints } = this.state
    if (cuePoints[index].position === cuePoint) {
      cuePoints.splice(index, 1)
      this.setState({ cuePoints, isEnable: true })
    }
  }

  onAddNewBreakDuration = () => {
    const { breakDurations } = this.state
    const newBreakDuration = {
      position: '',
      // isNew: true,
      isError: false
    }
    breakDurations.splice(0, 0, newBreakDuration)
    this.setState({ breakDurations })
  }

  onChangeBreakDuration=(event, index) => {
    const { breakDurations } = this.state
    breakDurations[index].position = event.target.value
    // let enable
    // if (breakDurations[index].isNew === true) {
    //   enable = breakDurations[index]
    // }
    // if (!_.isEmpty(enable)) {
    //   this.setState({ breakDurations })
    // } else {
    this.setState({ breakDurations, isEnable: true })
    // }
  }

  // onSaveBreakDuration=(breakDuration) => {
  //   const { breakDurations } = this.state
  //   const duplicateArr = breakDurations.filter(item => item.position === breakDuration.position)
  //   const breakDurationRegex = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/g
  //   const validFormat = breakDurationRegex.test(breakDuration.position)
  //   if (!!duplicateArr && duplicateArr.length > 1) {
  //     message.warn('Please remove duplicate Break Duration')
  //     return
  //   } else if (!validFormat) {
  //     message.warn('Please enter valid breakDuration in number format upto 3 decimals')
  //     return
  //   }
  //   breakDurations[0].isLoading = true
  //   this.setState({ breakDurations })
  //   setTimeout(() => {
  //     delete breakDurations[0].isLoading
  //     delete breakDurations[0].isNew
  //     this.setState({ breakDurations, isEnable: true })
  //   }, 2000)
  // }

  onDeleteBreakDuration=(breakDuration, index) => {
    const { breakDurations } = this.state
    if (breakDurations[index].position === breakDuration) {
      breakDurations.splice(index, 1)
      this.setState({ breakDurations, isEnable: true })
    }
  }

  forceSave=() => {
    this.setState({ shouldSave: true, ignoreWarning: true, showWarning: false }, () => this.handleSave())
  }

  discardChanges=() => {
    this.setState({ showWarning: false })
  }

  render () {
    const { mediaDetails, imageId, type, categoryList, isUploadBlocked, isUpdateBlocked, selectedImageType, project } = this.props
    const { selectedTags, filteredImageName, isEditVisible, isCropVisible, selectedAspectRatio, isSaving, settings, isEnable, selectedCategory, isDrmEnabled, isTagCreating, systemTags, adPositions, cuePoints, breakDurations, showWarning } = this.state
    // const imageDetailsArray = mediaDetails && mediaDetails.fileName ? mediaDetails.fileName.split('.') : null
    // const format = imageDetailsArray ? imageDetailsArray[imageDetailsArray.length - 1] : ''
    const format = 'JPG'
    const container = isEditVisible && mediaDetails && mediaDetails.id === imageId ? (
      <EditImageContainer
        showCrop={this.showCrop}
        mediaDetails={_.cloneDeep(mediaDetails)}
        settings={_.map(settings, _.clone)}
        selectedTags={selectedTags || []}
        selectedFormat={format.toUpperCase()}
        name={filteredImageName}
        formats={[{ name: format.toUpperCase() }]}
        handleTagChange={this.handleTagChange}
        handleFormatChange={this.handleFormatChange}
        handleCategoryChange={this.handleCategoryChange}
        handleTextChange={this.handleTextChange}
        selectAspectRatio={this.selectAspectRatio}
        selectedAspectRatio={selectedAspectRatio}
        systemTags={systemTags}
        categoryList={categoryList}
        selectedCategory={selectedCategory}
        updateAspectRatioImage={this.updateAspectRatioImage}
        isUploadBlocked={isUploadBlocked}
        isDrmEnabled={isDrmEnabled}
        type={type}
        selectedImageType={selectedImageType}
        handleDrmChange={this.handleDrmChange}
        onAddNewAdPosition={this.onAddNewAdPosition}
        adPositions={adPositions}
        onChangeAdPosition={this.onChangeAdPosition}
        // onSaveAdPosition={this.onSaveAdPosition}
        onDeleteAdPosition={this.onDeleteAdPosition}
        cuePoints={cuePoints}
        onAddNewCuePoint={this.onAddNewCuePoint}
        onChangeCuePoint={this.onChangeCuePoint}
        // onSaveCuePoint={this.onSaveCuePoint}
        onDeleteCuePoint={this.onDeleteCuePoint}
        breakDurations={breakDurations}
        onAddNewBreakDuration={this.onAddNewBreakDuration}
        onChangeBreakDuration={this.onChangeBreakDuration}
        // onSaveBreakDuration={this.onSaveBreakDuration}
        onDeleteBreakDuration={this.onDeleteBreakDuration}
        project={project}
      />
    ) : <div />
    return (
      <div>
        <Modal
          className='confirm-modal edit-image'
          title={type === 'IMAGE' ? 'EDIT IMAGE' : 'EDIT VIDEO'}
          visible={isEditVisible}
          okText='Save Content'
          okButtonProps={{ disabled: (!isEnable || isUpdateBlocked || isTagCreating), loading: isSaving }}
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          closable={false}
          confirmLoading={isSaving}
          destroyOnClose
          maskClosable={false}
          centered
          width='890px'
        >
          {container}
        </Modal>
        {isCropVisible ? (
          <CropImageModal
            isVisible={isCropVisible}
            handleCancel={this.hideCrop}
            imgUrl={settings.find((item) => item.aspectRatio === selectedAspectRatio.aspectRatio).fileName} aspectRatio={selectedAspectRatio}
            settings={settings}
            updateSettings={this.updateSettings} />
        ) : null
        }
        {
          showWarning
            ? <ConfirmModal
              isVisible={showWarning}
              title={`CONFIRMATION MESSAGE`}
              message={'Adpositions and CuePoint Value do not match. Do you wish to continue ?'}
              isLeftPrior={false}
              leftButtonText={'Discard'}
              rightButtonText={'Save'}
              handleSubmit={this.forceSave}
              handleCancel={this.discardChanges}
              // handleClose={this.onCloseConfirmModal}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false}
              // isLoading={isSavingAsset}
            />
            : null }
      </div>
    )
  }
}

EditImageModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** id of the selected image */
  imageId: PropTypes.string,
  /**  */
  createTag: PropTypes.func,
  /**  */
  tagsList: PropTypes.array,
  /** Callback for save content */
  updateImage: PropTypes.func,
  /**  */
  handleSuccess: PropTypes.func,
  /**  */
  mediaDetails: PropTypes.object,
  /** Callback to hide image editor */
  hideImageEditor: PropTypes.func
}

EditImageModal.defaultProps = {}

export default withApollo(
  compose(
    // graphql(
    //   QueryAllTags,
    //   {
    //     options: ({ tagSearchString }) => {
    //       return {
    //         variables: { name: '' }
    //       }
    //     },
    //     props: ({ data: { listTags = { items: [] } } }) => {
    //       return { tagsList: [ ...listTags.items ] }
    //     }
    //   }
    // ),
    // graphql(
    //   QueryMediaDetails,
    //   {
    //     options: ({ imageId }) => {
    //       return {
    //         variables: { id: imageId }
    //       }
    //     },
    //     skip: ({ imageId }) => !(imageId && imageId.length),
    //     props: ({ data }) => {
    //       return { mediaDetails: _.cloneDeep(data.getMedia) }
    //     }
    //   }
    // ),
    graphql(MutationCreateTag, {
      // TODO: The code for MutationCreateTag could be consolidated to keep the code DRY
      options: ({ project }) => {
        return {
          update: (cache, { data: { createTag } }) => {
            const filter = { type: { match: 'MANUAL' }, module: { exact: 'CONTENT_BANK' } }
            try {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
              if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
              cache.writeQuery({
                query: QueryAllTags,
                data: cacheData
              })
            } catch (e) {
              console.log('error', encodeURI)
            }
          }
        }
      },
      props: props => ({
        createTag: contentObj => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
    /* graphql(
      QueryTagContent,
      {
        options: ({ project }) => {
          return {
            variables: { project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
          }
        }
      }
    ), */
    graphql(
      QueryTagType,
      {
        options: ({ project }) => {
          return {
            fetchPolicy: 'network-only',
            variables: { filter: null, limit: 500, project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    ),
    graphql(
      MutationUpdateMedia, {
        options: (props) => ({
          update: (cache, { data: { updateMedia } }) => {
            if (props.assetId) {
              const variables = { id: props.assetId, project: props.project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables }))
              if (cacheData && cacheData.getAsset) {
                if (props.type === 'VIDEO') {
                  const index = cacheData.getAsset.videos.findIndex((item) => item.mediaId === updateMedia.id)
                  const selectedVideo = cacheData.getAsset.videos[index]
                  cacheData.getAsset.videos[index] = { ...updateMedia,
                    createdAt: selectedVideo.createdAt,
                    category: selectedVideo.category,
                    assetId: selectedVideo.assetId,
                    id: selectedVideo.id,
                    mediaId: selectedVideo.mediaId,
                    isDefault: selectedVideo.isDefault,
                    cuePoints: updateMedia.cuePoints,
                    adPositions: updateMedia.adPositions,
                    breakDuration: updateMedia.breakDuration,
                    type: selectedVideo.type }
                } else {
                  const index = cacheData.getAsset.images.findIndex((item) => item.mediaId === updateMedia.id)
                  const selectedImages = cacheData.getAsset.images[index]
                  cacheData.getAsset.images[index] = { ...updateMedia,
                    category: selectedImages.category,
                    assetId: selectedImages.assetId,
                    id: selectedImages.id,
                    mediaId: selectedImages.mediaId,
                    isDefault: selectedImages.isDefault,
                    type: selectedImages.type }
                }
              }
              cache.writeQuery({
                query: QueryGetAssetDetails,
                data: cacheData,
                variables
              })
            }
            if (props.query) {
              try {
                const { readQuery, queryName, imageKeyName } = props.query
                const cacheData = _.cloneDeep(cache.readQuery({ query: readQuery.query, variables: readQuery.variables }))
                if (cacheData && cacheData[queryName] && cacheData[queryName][imageKeyName]) {
                  if (cacheData[queryName][imageKeyName].id === updateMedia.id) {
                    cacheData[queryName][imageKeyName].settings = updateMedia.settings
                    cacheData[queryName][imageKeyName].tags = updateMedia.tags
                    cacheData[queryName][imageKeyName].name = updateMedia.name
                    cacheData[queryName][imageKeyName].updatedAt = updateMedia.updatedAt
                    cacheData[queryName][imageKeyName].fileName = updateMedia.fileName
                  }
                }
                cache.writeQuery({ query: readQuery.query, variables: readQuery.variables, data: cacheData })
              } catch (e) {
                // console.log('query error')
              }
            }
          }
        }),
        skip: ({ id, name, tags, aspectRatio, settings }) => {
          return !!(!id || !name || !tags)
        },
        props: (props) => ({
          updateImage: (id, name, tags, settings, drmContent, adPositions, cuePoints, breakDuration) => {
            return props.mutate({
              variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), drmContent, adPositions, cuePoints, breakDuration, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMediaLink, {
        options: (props) => ({
          update: (cache, { data: { updateAssetMediaLink } }) => {
            if (props.type === 'VIDEO') {
              const variables = { id: props.assetId, project: props.project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables }))
              if (cacheData && cacheData.getAsset && cacheData.getAsset.media && updateAssetMediaLink) {
                const index = cacheData.getAsset.media.findIndex((item) => item.id === updateAssetMediaLink.id)
                if (cacheData.getAsset.media[index].type === 'VIDEO') {
                  cacheData.getAsset.media[index] = updateAssetMediaLink
                }
              }
              cache.writeQuery({
                query: QueryGetAssetDetails,
                data: cacheData,
                variables
              })
            }
          }
        }),
        props: (props) => ({
          updateMediaLink: (id, assetId, settings, mediaCategory) => {
            return props.mutate({
              variables: { id, assetId, settings, mediaCategory }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateAssetImage,
      {
        options: ({ assetId, project }) => {
          return { update: (cache, { data: { updateAssetImage } }) => {
            const variables = { id: assetId, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables }))
            if (cacheData && cacheData.getAsset && cacheData.getAsset.images) {
              const index = cacheData.getAsset.images.findIndex((item) => item.id === updateAssetImage.id)
              // const selectedImages =  cacheData.getAsset.images[index]
              cacheData.getAsset.images[index].aspectRatio = updateAssetImage.aspectRatio
              cacheData.getAsset.images[index].settings = updateAssetImage.settings
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData,
              variables
            })
          }
          }
        },
        props: (props) => ({
          updateAssetImage: (input) => {
            let variables = input
            variables.project = props.ownProps.project
            return props.mutate({
              variables
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateAssetVideo,
      {
        // options: ({ assetId }) => {
        // return { update: (cache, { data: { updateAssetVideo } }) => {
        // const variables = { id: assetId }
        // const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAssetDetails, variables }))
        // if (cacheData && cacheData.getAsset && cacheData.getAsset.images) {
        //   const index = cacheData.getAsset.images.findIndex((item) => item.id === updateAssetVideo.id)
        //   // const selectedImages =  cacheData.getAsset.images[index]
        //   cacheData.getAsset.images[index].settings = updateAssetVideo.settings
        // }
        // cache.writeQuery({
        //   query: QueryGetAssetDetails,
        //   data: cacheData,
        //   variables
        // })
        // }
        // }
        // },
        props: (props) => ({
          updateAssetVideo: (variables) => {
            return props.mutate({
              variables
            })
          }
        })
      }
    ),
    graphql(
      MutationCreateAudit,
      {
        props: (props) => ({
          createAudit: (variables) => {
            return props.mutate({
              variables
            })
          }
        })
      }
    )
    // graphql(
    //   QueryAllRelatedMedia,
    //   {
    //     skip: (props) => {
    //       return !props.mediaDetails || !props.mediaDetails.id
    //     },
    //     options: ({ mediaDetails }) => {
    //       return {
    //         variables: { mediaId: mediaDetails.id }
    //       }
    //     },
    //     props: (props) => {
    //       return {
    //         getAllRelatedMedia: (mediaId) => {
    //           return props.data.refetch({ mediaId })
    //         }
    //       }
    //     }
    //   }
    // )
  )(EditImageModal)
)
