import React, { Component } from 'react'
import AppContext from '../../AppContext'
import moment from 'moment'

import EditPageButton from '../../components/ui/general/buttons/EditPageButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import DeleteButton from '../../components/ui/general/buttons/DeleteButton'
import { utilityService } from '../../services/UtilityService'
// import LoadingButton from '../../components/ui/general/buttons/LoadingButton'

class AppPageScheduleDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { isPublishDisabled, togglePageScheduleModal, appDetails, onPageDeleteVersion, currentPageVersionId, onPageVersionSelection } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          return (appDetails.scheduledVersions || []).map(version => {
            // return <p>test</p>
            let status = utilityService.getPageSheduleStatus(version.publishStartDate, version.publishEndDate)
            return <div className={`page-schedule-section ${currentPageVersionId === version.id ? 'selected' : ''}`} key={version.id} onClick={() => onPageVersionSelection(version)}>
              <div className='schedule-page-details'>
                <div className='details-row1'>
                  <span className='status'>{status}</span>
                  <span className='time'>{version && version.publishStartDate ? moment(version.publishStartDate).format('DD MMM YYYY HH:mm') : ''}</span>
                </div>
                <div className='details-row2'>
                  <span className='status'>Untill</span>
                  <span className='time'>{version && version.publishEndDate ? moment(version.publishEndDate).format('DD MMM YYYY HH:mm') : ''}</span>
                </div>
              </div>
              <div className='schedule-page-button-container'>
                <div className='delete-button'>
                  <ButtonContainer displayTitle='Delete' childComponent={<DeleteButton onClick={() => onPageDeleteVersion(true, version)} isDisabled={isPublishDisabled} />} />
                </div>
                <div className='edit-button'>
                  <ButtonContainer displayTitle='Edit' childComponent={<EditPageButton onClick={() => togglePageScheduleModal(true, version)} isDisabled={isPublishDisabled} />} />
                </div>

              </div>
            </div>
          })
        }}
      </AppContext.Consumer>
    )
  }
}

AppPageScheduleDetails.defaultProps = {
}

AppPageScheduleDetails.propTypes = {

}

export default AppPageScheduleDetails
