import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'

import MultilineEllipse from '../../../MultilineEllipse'
import placeholderImage from './../../../../assets/images/default-image.jpg'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryFilterAssets from '../../../../graphQL/asset/searchAssets'
// import SubscriptionAssetList from '../../../../graphQL/asset/createAssetSubscription'
import { utilityService } from '../../../../services/UtilityService'
import { generateCroppedThumbnail } from '../../../../util/util'

import './../../ui.style.scss'

const Option = Select.Option

class SeriesDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedSeries: {}
    }
  }

    UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
      const selectedSeries = newProps.selectedSeries && newProps.selectedSeries.id ? (newProps.assetList || []).find(item => item.id === newProps.selectedSeries.assetId) : null
      this.setState({
        selectedSeries
      })
    }

    onOptionSelect = (id) => {
      const selectedSeries = (this.props.assetList || []).find(item => item.id === id)

      this.setState({
        selectedSeries
      })
      this.props.handleSeriesSelect(selectedSeries)
      this.props.onSeriesSearch('')
    }

    onSeriesSearch = (value) => {
      this.props.onSeriesSearch(value)
    }

    render () {
      const { selectedSeries } = this.props
      const { parentComponent, assetList } = this.props
      const image = placeholderImage
      const seriesComponents = assetList ? (assetList || []).map((series, index) => {
        const imageUrl = series.defaultMedia ? generateCroppedThumbnail(series.defaultMedia, 87, 48, '16:9') : null
        return <Option className='series-drop-down-option' key={`option${index}`} value={series.id}>
          <div className='series-list-cell'>
            <div className='details'>
              <MultilineEllipse maxLines={'2'} text={series.title || 'Untitled'} />
            </div>
            <div className='series-thumb' style={{ backgroundImage: `url(${imageUrl || image})` }} />
            <a className='series-id' target='#' href={`${location.origin}/assets/${series.id}`} >{series.id}</a>
          </div>
        </Option>
      }) : null

      return (

        <div className='series-drop-down'>
          <Select
            allowClear
            dropdownClassName='series-dropdown-popup'
            suffixIcon={<ExpandedIcon />}
            clearIcon={<CrossIcon />}
            value={selectedSeries ? selectedSeries.title : undefined}
            placeholder='Select Series'
            showSearch
            getPopupContainer={() => document.getElementById(parentComponent)}
            onChange={this.onOptionSelect}
            onSearch={this.onSeriesSearch}
            filterOption={false}
          >
            { seriesComponents }
          </Select>

        </div>
      )
    }
}

SeriesDropDown.propTypes = {
  /** object to filter the service call */
  filterVal: PropTypes.object,
  /** function for series search */
  onSeriesSearch: PropTypes.func,
  /** series search value */
  searchString: PropTypes.string,
  /** function for handle series select option */
  handleSeriesSelect: PropTypes.func
}

SeriesDropDown.defaultProps = {
  parentComponent: 'asset-container'
}

// export default SeriesDropDown

export default withApollo(compose(
  graphql(
    QueryFilterAssets,
    {
      options: (props) => {
        const { searchString, filterVal, project } = props
        const sort = {
          value: 'updatedAt',
          order: 'desc'
        }
        const variables = utilityService.getFormattedAssetFilter(searchString, filterVal, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const assetList = data.listAssets ? data.listAssets.items : []
        return {
          assetList,
          isLoading: data.loading || !data.listAssets,
          totalCount: data.listAssets ? data.listAssets.totalCount : 0,
          getAssetList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAssets.items, ...fetchMoreResult.listAssets.items]
                prev.listAssets.items = newList
                return prev
              }
            })
          }
          // subscribeToNewAsset: () => {
          //   return props.data.subscribeToMore({
          //     document: SubscriptionAssetList,
          //     updateQuery: (prev, { subscriptionData: { data: { assetCreated } } }) => {
          //       if (!assetCreated) return prev
          //       const newList = [ assetCreated, ...prev.listAssets.items ]
          //       prev.listAssets.items = newList
          //       prev.listAssets.totalCount = prev.listAssets.totalCount + 1
          //       return prev
          //     }
          //   })
          // }
        }
      }

    }
  )
)(SeriesDropDown))
