import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateMetaSectionModal from '../../../components/ui/dataEntry/other/CreateMetaSectionModal'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import ListMetaSections from '../../../graphQL/asset/listMetaSections'
import MutationCreateSection from './../../../graphQL/admin/asset/createSection'
import MutationUpdateSection from './../../../graphQL/admin/asset/editSection'
import deleteMetaSection from './../../../graphQL/admin/asset/deleteMetaSection'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import { utilityService } from '../../../services/UtilityService'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
class MetaSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentSelection: null,
      activeSelection: null,
      isLoading: !props.metaSectionLists || !props.metaSectionLists.length,
      showDeleteMetaSectionModal: false,
      isDeleteModalLoading: false,
      showedit: true
    }
    this.columns = [
      {
        title: 'Section Title',
        dataIndex: 'title',
        key: 'title',
        width: '60%'
      },
      {
        title: '# of Asset Types',
        dataIndex: 'usedCount',
        width: '37%',
        key: 'usedCount',
        render: (usedCount) => this.renderAssetTypeCount(usedCount)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && !_.isEqual(newProps.metaSectionLists, this.props.metaSectionLists)) {
      this.setState({ isSearching: false, isPaginating: false, isLoading: false })
    } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.metaSectionLists, this.props.metaSectionLists)) {
      this.setState({ isSearching: false, isLoading: false })
    } else if (!newProps.isLoading && _.isEmpty(newProps.metaSectionLists)) {
      this.setState({ isLoading: false })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { target } = event
    const { className } = target
    const availableOptions = ['edit', 'transfer', 'delete']
    const isOptionClicked = availableOptions.findIndex(item => className === item)
    if (isOptionClicked > -1) {
      return
    }
    if (this.state.currentSelection) {
      this.setState({ currentSelection: null })
    }
  }

  toggleShowOptions = id => {
    if (this.state.currentSelection === id) {
      this.setState({ currentSelection: null })
    } else {
      this.setState({ currentSelection: id })
    }
  }

  onDeleteMetaSection = () => {
    this.setState({ isDeleteModalLoading: true }, () => {
      const { activeSelection } = this.state
      this.props.deleteMetaSection(activeSelection, this.props.project).then(() => {
        this.setState({ isDeleteModalLoading: false })
        this.hideMetaSectionDelete()
        message.success('Meta Section deleted successfully')
        this.props.onHistoryUpdate()
      }, error => {
        this.setState({ isDeleteModalLoading: false })
        utilityService.handleError(error)
      })
    })
  }

  hideMetaSectionDelete = () => {
    this.setState({ showDeleteMetaSectionModal: false, showedit: true })
  }

  showMetaSectionDelete = () => {
    this.setState({ showDeleteMetaSectionModal: true, showedit: false })
  }

  onSelectOption = (id, selectedOption) => {
    this.setState({ activeSelection: id })
    if (selectedOption.type === 'delete') {
      this.showMetaSectionDelete()
    } else {
      this.onShowModal()
    }
  }

  creareNewMetaSection = () => {
    this.onShowModal()
    this.setState({ activeSelection: null })
  }

  onShowModal = () => {
    this.setState({ showSectionCreateModal: true, showedit: false })
  }

  onHideModal = () => {
    this.setState({ showSectionCreateModal: false, activeSelection: null, showedit: true })
  }

  loadMoreAsset = () => {
    if (this.props.totalCount === this.props.metaSectionLists.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getMoreAuthors(this.props.metaSectionLists.length))
  }

  renderAssetTypeCount = (count) => {
    if (!count) {
      return '0'
    } else {
      return count
    }
  }

  renderData = (id) => {
    let availableOptions = []
    const { currentSelection, showedit } = this.state
    const { metaSectionLists } = this.props
    const selectedMeta = metaSectionLists.find(item => item.id === id)
    if (!this.isUpdateDisabled) {
      availableOptions = [{ type: 'edit', name: 'Edit' }]
    }
    if (!this.isDeleteDisabled && selectedMeta && (!selectedMeta.usedCount || (selectedMeta.usedCount === 0))) {
      const deleteOption = {
        type: 'delete',
        name: 'Delete'
      }
      availableOptions.push(deleteOption)
    }
    if (availableOptions && availableOptions.length) {
      return (
        <div className='option-list' id='option-list'>
          <PopoverButton
            button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
            displayParam='name'
            contents={availableOptions}
            onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
            parentCompoent={'option-list'}
            isVisible={showedit && currentSelection === id}
            placement={'leftBottom'}
          />
        </div>
      )
    } else {
      return null
    }
  }

  render () {
    const { metaSectionLists, onHistoryUpdate } = this.props
    const { isLoading, isPaginating, showSectionCreateModal, activeSelection, showDeleteMetaSectionModal, isDeleteModalLoading } = this.state
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const userPermissionsContent = permissions['CONTENT_BANK']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
          return <div className='admin-portal asset-type-container'>
            <div className='admin-list asset-category-list'>
              <FixedTableHeader columnCellList={this.columns} />
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMoreAsset}
                hasMore={this.props.totalCount > metaSectionLists.length}
                initialLoad={false}
                useWindow={false}
              >
                <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                  <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={metaSectionLists} pagination={false} />
                </Skeleton>
                <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
              </InfiniteScroll>
            </div>
            <div className='admin-footer' >
              <LoadingButton
                type='primary'
                onClick={this.creareNewMetaSection}
                htmlType='submit'
                buttonText={'Add Section'}
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isCreateDisabled}
              />
            </div>
            <CreateMetaSectionModal
              activeSelection={activeSelection}
              isVisible={showSectionCreateModal}
              handleCancel={this.onHideModal}
              handleSubmit={this.onCreate}
              module='DATA_MANAGER'
              isSubmitDisabled={this.isUpdateDisabled}
              isUpdateBlocked={isUpdateDisabledContent}
              createNewSection={this.props.createNewSection}
              onHistoryUpdate={onHistoryUpdate}
              updateSection={this.props.updateSection}
              metaSectionLists={this.props.metaSectionLists}
              project={project} />
            <ConfirmModal
              isVisible={showDeleteMetaSectionModal}
              title={'Delete Meta Section'}
              message={'Do you want to delete this Meta Section ?'}
              isLoading={isDeleteModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={this.hideMetaSectionDelete}
              handleSubmit={this.onDeleteMetaSection}
              isSubmitButtonDisabled={false}
            />
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

MetaSection.propTypes = {}

export default withApollo(compose(
  graphql(
    ListMetaSections,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { filter: {
            project: {
              eq: project
            }
          } }
        }
      },
      props: (props) => {
        const { data } = props
        let metaSectionLists = data.listMetaSections ? data.listMetaSections.items : []
        return {
          metaSectionLists,
          isLoading: data.loading || !data.listMetaSections,
          totalCount: data.listMetaSections ? data.listMetaSections.totalCount : 0
        }
      }
    }
  ),
  graphql(
    MutationCreateSection, {
      options: ({ project }) => ({
        update: (cache, { data: { createMetaSection } }) => {
          const cacheData = cloneDeep(
            cache.readQuery({
              query: ListMetaSections,
              variables: { filter: {
                project: {
                  eq: project
                }
              } }
            })
          )
          if (cacheData && cacheData.listMetaSections && cacheData.listMetaSections.items) {
            cacheData.listMetaSections.items.push(createMetaSection)
          }
          cache.writeQuery({
            query: ListMetaSections,
            data: cacheData,
            variables: { filter: {
              project: {
                eq: project
              }
            } }
          })
        }
      }),
      props: (props) => ({
        createNewSection: (title) => {
          return props.mutate({
            variables: { title, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateSection, {
      options: () => ({
      }),
      props: (props) => ({
        updateSection: (id, title) => {
          return props.mutate({
            variables: { id, title, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    deleteMetaSection,
    {
      options: ({ project }) => ({
        update: (cache, { data: { deleteMetaSection } }) => {
          const cacheData = cache.readQuery({ query: ListMetaSections,
            variables: { filter: {
              project: {
                eq: project
              }
            } } })
          if (cacheData && cacheData.listMetaSections && cacheData.listMetaSections.items) {
            const index = cacheData.listMetaSections.items.findIndex((item) => deleteMetaSection.id === item.id)
            cacheData.listMetaSections.items.splice(index, 1)
          }
          cache.writeQuery({
            query: ListMetaSections,
            data: cacheData,
            variables: { filter: {
              project: {
                eq: project
              }
            } }
          })
        }
      }),
      props: (props) => ({
        deleteMetaSection: (activeSelection, project) => {
          const variables = { id: activeSelection, project }
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(MetaSection))
