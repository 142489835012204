import React from 'react'
// import PropTypes from 'prop-types'
import { withApollo } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'
// import { Icon, Divider, Switch, DatePicker, TimePicker } from 'antd'
//

import DropDown from '../../components/ui/dataEntry/inputs/DropDown'

const parentCompoent = 'drm-container'
function DrmChannelDetails ({ channelList, onSelectChannel, channelState, tempStreamList, onChannelStreamSelect, isUpdateDisabled, onConfigGroupSelect }) {
  const { configGroup, stream } = channelState
  return (
    <div className='general-channel-details'>
      <div className='input-field'>
        <label className='input-title'>Channels</label>
        <DropDown
          options={channelList || []}
          displayParam='name'
          keyParam='id'
          valueParam='id'
          placeHolder='Select Channel'
          parent={parentCompoent}
          selectedValue={(channelState && channelState.channel && channelState.channel.id) || undefined}
          onOptionSelect={onSelectChannel}
          disabled={isUpdateDisabled}
        />
      </div>
      <div className='input-field'>
        <label className='input-title'>Config Groups</label>
        <DropDown
          options={(channelState && channelState.channelGroupList) || []}
          displayParam='name'
          keyParam='id'
          valueParam='id'
          placeHolder='Select Config Group'
          parent={parentCompoent}
          selectedValue={(configGroup && configGroup.id) || undefined}
          onOptionSelect={onConfigGroupSelect}
          disabled={isUpdateDisabled}
        />
      </div>

      <div className='input-field'>
        <label className='input-title'>Stream Type</label>
        <DropDown
          options={tempStreamList || []}
          displayParam='name'
          keyParam='id'
          valueParam='id'
          placeHolder='Select Stream Type'
          parent={parentCompoent}
          selectedValue={(stream && stream.id) || undefined}
          onOptionSelect={onChannelStreamSelect}
          disabled={isUpdateDisabled}
        />
      </div>

    </div>
  )
}

DrmChannelDetails.propTypes = {
}

export default withApollo(compose(

)(DrmChannelDetails))
