import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox } from 'antd'

import _ from 'lodash'
import Input from '../inputs/Input'
import DropDown from '../inputs/DropDown'
import './../../ui.style.scss'
// import { utilityService } from '../../../../services/UtilityService'

const mediaCategoryType = [
  {
    label: 'Video',
    id: 'VIDEO'
  },
  {
    label: 'Image',
    id: 'IMAGE'
  }
]

class CreateImageCategoryModal extends Component {
    state = {
      imageCategoryName: undefined,
      isActive: true,
      isEdit: false,
      isNameError: false,
      selectedCategoryType: 'IMAGE'
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedCategory && (!_.isEqual(this.props.selectedCategory, nextProps.selectedCategory) || (!this.state.imageCategoryName))) {
        this.setState({
          imageCategoryName: nextProps.selectedCategory.label,
          isActive: nextProps.selectedCategory.isActive,
          selectedCategoryType: nextProps.selectedCategory.type
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value } = e.target
      const index = -1
      const regex = /^[a-zA-Z0-9 ]*$/
      if (!value.startsWith(' ') && regex.test(value)) {
        this.setState({ isEdit: true, isNameError: index !== -1, imageCategoryName: value })
      }
    }

    handleActiveChange =() => {
      const { isActive } = this.state
      this.setState({ isActive: !isActive, isEdit: true })
    }

    handleAddImageCategory = () => {
      const { imageCategoryName, selectedCategoryType, isActive } = this.state
      const { selectedCategory, handleSubmit } = this.props
      const newImageCategory = {
        label: imageCategoryName,
        type: (selectedCategoryType || '').toLocaleUpperCase(),
        isActive
      }
      handleSubmit(newImageCategory, _.isEmpty(selectedCategory) ? null : selectedCategory.id)
    }

    onCategoryTypeSelect = (selectedCategoryType) => {
      this.setState({ selectedCategoryType, isEdit: true })
    }

    resetState = () => {
      const state = {
        imageCategoryName: undefined,
        isActive: true,
        isEdit: false,
        isNameError: false,
        selectedCategoryType: 'IMAGE'
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedCategory, isLoading, isSubmitDisabled } = this.props
      const { imageCategoryName, isEdit, isNameError, selectedCategoryType, isActive } = this.state
      return (
        <Modal
          className='general-modal create-image-category-modal'
          title={selectedCategory ? 'EDIT MEDIA CATEGORY' : 'NEW MEDIA CATEGORY'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddImageCategory}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !(imageCategoryName && isEdit && !isSubmitDisabled && !isNameError), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='455px'
          confirmLoading={isLoading}
        >
          <div className='create-image-category' id='media-category-type-parent'>
            <Input
              title={'Media Category Name'}
              inputName='name'
              handleChange={this.handleChange}
              value={imageCategoryName}
              isError={isNameError}
              errorMessage={'Media category already exist'}
              placeholder='Insert Media Category Name'
              limit={50} />
            <div className='category-type'>
              <span>Media Category Type</span>
              <DropDown
                disabled={!_.isEmpty(selectedCategory)}
                parent={'media-category-type-parent'}
                options={mediaCategoryType}
                selectedValue={selectedCategoryType}
                placeHolder={`Select Category Type`}
                valueParam='id'
                displayParam='label'
                onOptionSelect={this.onCategoryTypeSelect}
                className={selectedCategoryType ? 'selected' : ''} />
            </div>
            <div className='active-checkbox'>
              <label >Active</label>
              <Checkbox checked={isActive} onClick={this.handleActiveChange} />
            </div>
          </div>
        </Modal>
      )
    }
}

CreateImageCategoryModal.propTypes = {
  /** visible file status of CreateImageCategoryModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateImageCategoryModal. */
  handleCancel: PropTypes.func,
  /** Boolean for disabling submit actikon */
  isSubmitDisabled: PropTypes.bool
}

export default CreateImageCategoryModal
