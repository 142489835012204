import React from 'react'
import PropTypes from 'prop-types'

const DoneIcon = ({ width, height, ...props }) => (
  <svg className='done-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='none' fillRule='evenodd' stroke='#52C41A' strokeWidth='3' d='M17 1L6 12 1 7' />
  </svg>
)

DoneIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

DoneIcon.defaultProps = {
  width: 18,
  height: 15
}

export default DoneIcon
