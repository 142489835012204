import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Skeleton, Empty } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import _, { cloneDeep } from 'lodash'
import PopoverButton from '../general/buttons/PopoverButton'
import ExpandMenuIcon from '../general/icons/ExpandMenuIcon'
import AddUserModal from '../dataEntry/other/AddUserModal'
import Tag from './Tag'

import { utilityService } from '../../../services/UtilityService'
import moment from 'moment'
import { getSortedUserList } from '../../../util/util'

const options = [
  {
    type: 'editUser',
    name: 'Edit User'
  },
  {
    type: 'duplicateUser',
    name: 'Duplicate'
  },
  {
    type: 'resendInvitation',
    name: 'Resend Invitation'
  },
  {
    type: 'suspendUser',
    name: 'Suspend User'
  },
  {
    type: 'deleteUser',
    name: 'Delete'
  },
  {
    type: 'resetMfa',
    name: 'Reset MFA Device'
  },
  {
    type: 'forceLogout',
    name: 'Force Logout'
  }
]
const optionsEnabled = [
  {
    type: 'editUser',
    name: 'Edit User'
  },
  {
    type: 'duplicateUser',
    name: 'Duplicate'
  },
  {
    type: 'resendInvitation',
    name: 'Resend Invitation'
  },
  {
    type: 'enableUser',
    name: 'Enable User'
  },
  {
    type: 'deleteUser',
    name: 'Delete'
  },
  {
    type: 'resetMfa',
    name: 'Reset MFA Device'
  },
  {
    type: 'forceLogout',
    name: 'Force Logout'
  }
]

class RoleUserTable extends Component {
  constructor (props) {
    super(props)
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        className: 'user-name'

      },
      {
        title: 'Email ID',
        dataIndex: 'email',
        key: 'email',
        className: 'user-mail'
      },
      {
        title: 'User Group',
        key: 'accessRoleGroups',
        dataIndex: 'accessRoleGroups',
        className: 'user-groups',
        render: groups => (
          <span>
            {(groups || []).filter(item => item).map((group, index) => {
              return (
                <Tag key={index} tagText={group.name} />
              )
            })}
          </span>
        )
      },
      {
        title: 'Last Logged In',
        dataIndex: 'lastLogin',
        className: 'last-login',
        key: 'lastLogin',
        render: lastLogin => lastLogin ? <span>{moment(lastLogin).format('DD MMM YYYY HH:mm:ss')}</span> : ''
      },
      {
        title: 'Status',
        key: 'status',
        className: 'status',
        dataIndex: 'id',
        render: this.renderStatus
      }
    ]

    this.id = {
      key: 'id',
      dataIndex: 'id',
      render: this.renderData
    }

    this.state = {
      showOptions: false,
      toEditUser: false,
      toDuplicateUser: false,
      selectedRowKeys: [],
      isUpdateLoading: false,
      isPaginating: false
    }
  }

  UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
    if (this.props.isExpand) {
      this.columns.push(this.id)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.isBackButtonClicked) { this.onSelectChange([]) }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (!(this.tableContainer && this.tableContainer.contains(event.target))) {
      this.setState({ openedOption: '' })
    }
  }

  getOptions = (selectedUser, isSuperAdmin) => {
    let optionList = selectedUser.enabled ? options : optionsEnabled
    if (selectedUser.status !== 'FORCE_CHANGE_PASSWORD') {
      optionList = optionList.filter(item => item.type !== 'resendInvitation')
    }
    if (!isSuperAdmin) {
      optionList = optionList.filter(item => !(item.type === 'suspendUser' || item.type === 'deleteUser' || item.type === 'enableUser'))
    }
    return optionList
  }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys }, () => {
        this.props.onChangeSelectUser(selectedRowKeys)
      })
    }

    toggleShowOptions = (id) => {
      const { userList } = this.props
      const selectedUser = userList.find(item => item.id === id)
      this.setState({ openedOption: id, selectedUser })
    }

    renderData = id => {
      const { openedOption } = this.state
      const { userList, isSuperAdmin } = this.props
      const refId = utilityService.makeRandomString(5)
      const selectedUser = (userList || []).find(item => item.id === id)
      return (
        <div >
          <PopoverButton
            button={<div onClick={() => this.toggleShowOptions(id)} id={refId}> <ExpandMenuIcon /> </div>}
            displayParam='name'
            contents={this.getOptions(selectedUser, isSuperAdmin)}
            onContentClick={this.onSelectOption}
            parentCompoent={refId}
            isVisible={openedOption === id}
          />
        </div>
      )
    }

    renderStatus = id => {
      const { userList } = this.props
      const selectedUser = (userList || []).find(item => item.id === id)
      if (selectedUser.enabled === false) {
        return 'SUSPENDED'
      } else {
        return selectedUser.status
      }
    }

    onSelectOption = (selectedOption) => {
      if (selectedOption.type === 'editUser') {
        this.setState({ toEditUser: true })
      } else if (selectedOption.type === 'duplicateUser') {
        this.setState({ toDuplicateUser: true })
      } else if (selectedOption.type === 'deleteUser') {
        this.props.onDeleteUser([this.state.openedOption])
      } else if (selectedOption.type === 'suspendUser') {
        this.props.onSuspendUser(this.state.openedOption)
      } else if (selectedOption.type === 'enableUser') {
        this.props.onEnableUser(this.state.openedOption)
      } else if (selectedOption.type === 'resendInvitation') {
        this.props.onResendInvitation(this.state.openedOption)
      } else if (selectedOption.type === 'resetMfa') {
        this.props.onResetMfaDevice(this.state.openedOption)
      } else if (selectedOption.type === 'forceLogout') {
        this.props.onForceLogout(this.state.openedOption)
      }
      this.setState({ openedOption: undefined })
    }

    hideEditUserModal = () => {
      this.setState({ toEditUser: false, toDuplicateUser: false })
    }

    onEditUser = (userName, userId, userGroups) => {
      this.setState({ isUpdateLoading: true })
      if (this.state.toEditUser) { this.props.onEditUser(userName, userId, userGroups) } else { this.props.onDuplicateUser(userName, userId, userGroups) }
      setTimeout(() => {
        this.setState({ isUpdateLoading: false, toEditUser: false, toDuplicateUser: false })
      }, 2000)
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.userList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreUsers(this.props.authorList.length))
    }

    render () {
      const { toEditUser, toDuplicateUser, selectedRowKeys, selectedUser, isUpdateLoading } = this.state
      const { isSelected, isLoading, totalCount, groupList, isUpdateDisabled, isCreateDisabled } = this.props
      let { userList: userListData } = this.props
      const userList = (cloneDeep(userListData) || []).map((item) => {
        item.email = item.id
        return item
      })
      const sortedItems = getSortedUserList(userList)
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
      }
      return (
        <div className='role-user-table' ref={node => { this.tableContainer = node }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreAsset}
            hasMore={userList && totalCount > userList.length}
            initialLoad={false}
            useWindow={false}
          >
            <Skeleton active title={false} paragraph={{ rows: 12 }} loading={isLoading && !(userList && userList.length)}>
              {userList && userList.length ? <Table columns={this.columns} dataSource={sortedItems} pagination={false} rowSelection={isSelected ? rowSelection : undefined} rowKey={record => record.id} /> : ''}
              {
                !isLoading && !(userList && userList.length) && <Empty description={'No Match Found'} style={{ marginTop: 30 }} />
              }
            </Skeleton>
          </InfiniteScroll>
          <AddUserModal
            visible={toEditUser || toDuplicateUser}
            handleSubmit={this.onEditUser}
            handleCancel={this.hideEditUserModal}
            loading={isUpdateLoading}
            title={toEditUser ? 'EDIT USER' : 'DUPLICATE USER'}
            okText={toEditUser ? 'Save User' : 'Send Invite'}
            groupList={groupList}
            isFromEdit={toEditUser}
            userName={_.isEmpty(selectedUser) ? '' : selectedUser.name}
            userId={_.isEmpty(selectedUser) ? '' : selectedUser.id}
            userGroups={_.isEmpty(selectedUser) ? '' : (selectedUser.accessRoleGroups || []).map(item => item.id)}
            isSubmitDisabled={toEditUser ? isUpdateDisabled : isCreateDisabled} />
        </div>
      )
    }
}

RoleUserTable.propTypes = {
  /** Boolean for showing loading while fetching user data */
  isLoading: PropTypes.bool,
  /** List of users */
  userList: PropTypes.array,
  /** Function to be called on edit user */
  onEditUser: PropTypes.func,
  /** Function to be called on delete of user */
  onDeleteUser: PropTypes.func,
  /** Function to be called on disabling user */
  onSuspendUser: PropTypes.func,
  /** Function to be called on enabling user */
  onEnableUser: PropTypes.func,
  /** Function to be called on select multiple user for delete */
  onChangeSelectUser: PropTypes.func,
  /** Boolean for getting back button click status */
  isBackButtonClicked: PropTypes.bool,
  /** Function to be called on resend invitation */
  onResendInvitation: PropTypes.func
}

export default RoleUserTable
