import React from 'react'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import AdminOriginalData from './AdminOriginalData'
import './../ui.style.scss'

class CompetitionOriginalData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  getSelectedData = (selectedCompetition) => {
    const data = [
      {
        key: 'Competition',
        value: selectedCompetition.title ? selectedCompetition.title : '-',
        isLeft: true
      },
      {
        key: 'Opta Id',
        value: selectedCompetition.externalId ? selectedCompetition.externalId : '-',
        isLeft: true
      },
      {
        key: 'Competition Code',
        value: selectedCompetition.competitionCode ? selectedCompetition.competitionCode : '-',
        isLeft: false
      },
      {
        key: 'OPTA Legacy Id',
        value: selectedCompetition.opId ? selectedCompetition.opId : '-',
        isLeft: true
      },
      {
        key: 'Country External Id',
        value: selectedCompetition.countryExternalId ? selectedCompetition.countryExternalId : '-',
        isLeft: true
      },
      {
        key: 'Competition Format',
        value: selectedCompetition.competitionFormat ? selectedCompetition.competitionFormat : '-',
        isLeft: true
      },
      {
        key: 'Type',
        value: selectedCompetition.type ? _.capitalize(selectedCompetition.type) : '-',
        isLeft: true
      },
      {
        key: 'Display Order',
        value: selectedCompetition.displayOrder ? selectedCompetition.displayOrder : '-',
        isLeft: true
      },
      {
        key: 'OCID',
        value: selectedCompetition.ocId ? selectedCompetition.ocId : '-',
        isLeft: false
      },
      {
        key: 'Country',
        value: selectedCompetition.country ? selectedCompetition.country : '-',
        isLeft: false
      },
      {
        key: 'Sport',
        value: selectedCompetition.sport ? selectedCompetition.sport.title : '-',
        isLeft: false
      },
      {
        key: 'Competition Type',
        value: selectedCompetition.competitionType ? _.capitalize(selectedCompetition.competitionType) : '-',
        isLeft: false
      },
      {
        key: 'Source',
        value: selectedCompetition.source ? _.capitalize(selectedCompetition.source) : '-',
        isLeft: false
      },
      {
        key: 'Friendly',
        value: selectedCompetition.isFriendly ? _.capitalize(selectedCompetition.isFriendly) : '-',
        isLeft: false
      }
    ]
    return data
  }

  render () {
    const { selectedCompetition } = this.props
    const selectedData = this.getSelectedData(selectedCompetition)
    return (
      <div className='competition-data'>
        {/* <div className='competition-img'>
          <PremierLeagueIcon />
        </div> */}
        <div className='data'>
          <AdminOriginalData selectedData={selectedData} />
        </div>
      </div>
    )
  }
}

CompetitionOriginalData.propTypes = {

}

CompetitionOriginalData.defaultProps = {
}

export default CompetitionOriginalData
