import React, { Component } from 'react'
import { Table, Skeleton, Checkbox, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

// import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import Switch from '../dataEntry/inputs/Switch'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import CreateAPIKeyModal from '../../../components/ui/dataEntry/other/CreateAPIKeyModal'
// import { generateCroppedThumbnail } from './../../../util/util'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryListAPIKeys from '../../../graphQL/appManager/listAPIKeys'
import QueryListSettingApps from '../../../graphQL/appManager/listSettingApps'
import MutationEnableApiKey from './../../../graphQL/appManager/enableApiKey'
import MutationDisableApiKey from './../../../graphQL/appManager/disableApiKey'
import MutationDeleteApiKey from './../../../graphQL/appManager/deleteApiKey'
import { utilityService } from '../../../services/UtilityService'

const deleteTagTypeMessage = {
  title: 'DELETE API Key',
  firstMessage: 'Are you sure you want to delete this Api Key?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  borderBottom: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class AppAPIKeyList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedType: null,
      loading: !(props.apiKeyList && props.apiKeyList.length),
      isShowTagTypeModal: false,
      showDeleteTagTypeModal: false,
      selectedTagType: undefined,
      deleteLoading: false,
      isDeleteSuccess: false,
      isEdit: false,
      isDragStart: false,
      expandedRowKeys: [],
      tagTypeList: props.apiKeyList && props.apiKeyList.length ? (props.apiKeyList || []).filter(item => !item.isArchived) : [],
      selectedIds: []
    }
    this.columns = [
      {
        title: 'API Key Label',
        dataIndex: 'label',
        key: 'label',
        width: '30%',
        render: (name, id) => this.renderTagTypeName(name, id)
      },
      {
        title: 'API Key',
        dataIndex: 'apiKey',
        key: 'apiKey',
        width: '40%',
        render: (name, id) => this.renderTagTypeName(name, id)
      },
      {
        title: 'Status',
        dataIndex: 'isDisabled',
        key: 'isDisabled',
        width: '27%',
        render: (isDisabled, id) => this.renderApiKeyButton(isDisabled, id)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.innerColumns = [
      {
        title: 'App Name',
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (title) => <div className='inner-header'>{title}</div>
      },
      {
        title: 'App Key',
        dataIndex: 'key',
        key: 'key',
        width: '50%',
        render: (title) => <div className='inner-header'>{title}</div>
      }
    ]
    this.metaDataColumns = [
      {
        title: 'App Name',
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (title) => this.renderAppList(title)
      },
      {
        title: 'App Key',
        dataIndex: 'key',
        key: 'key',
        width: '50%',
        render: (title) => this.renderAppList(title)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.apiKeyList, this.props.apiKeyList)) {
        const updatedAppList = (cloneDeep(newProps.apiKeyList || [])).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          if (item.enabledAssetTypes === null) {
            item.enabledAssetTypes = []
          }

          return item
        })
        this.setState({ isSearching: false, isPaginating: false, loading: false, tagTypeList: _.cloneDeep(updatedAppList.filter(item => !item.isArchived)) })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.apiKeyList, this.props.apiKeyList)) {
        this.setState({ isSearching: false, loading: false })
      } else if (newProps.isLoading && !_.isEmpty(newProps.apiKeyList) && !_.isEqual(newProps.apiKeyList, this.props.apiKeyList)) {
        const updatedAppList = (newProps.apiKeyList || []).map(item => {
          const index = (this.state.selectedIds || []).findIndex(selectedId => selectedId === item.id)
          if (index > -1) {
            item.isSelected = true
          } else {
            item.isSelected = false
          }

          return item
        })
        this.setState({ isSearching: false, isPaginating: false, loading: false, tagTypeList: _.cloneDeep(updatedAppList.filter(item => !item.isArchived)) })
      }

      if (!newProps.isLoading && _.isEmpty(newProps.apiKeyList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    renderAppList=(title) => {
      return (
        <div className='assetType-list'>
          {/* {this.getLogoForAsset(title)} */}
          {title}
        </div>
      )
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedType) {
        this.setState({ openedType: null })
      }
    }

    showCreateTagTypeModal = (isShowTagTypeModal) => {
      this.setState({ isShowTagTypeModal, selectedTagType: null, isEdit: false })
    }

    toggleShowOptions = id => {
      if (this.state.openedType === id) {
        this.setState({ openedType: null })
      } else {
        this.setState({ openedType: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { handleShowApiKeyModal } = this.props
      const { tagTypeList } = this.state
      const selectedTagType = tagTypeList.find(item => item.id === id)
      if (!selectedTagType) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ selectedTagType, isEdit: true }, () => handleShowApiKeyModal(true))
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteTagTypeModal: true, selectedTagType })
      }
      this.setState({ openedType: null })
    }

    loadMoreTagTypes = () => {
      if (this.props.totalCount === this.state.tagTypeList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreTagTypes(this.state.tagTypeList.length))
    }

    hideDeleteTagTypeModal = () => {
      this.setState({ isDeleteSuccess: true, selectedTagType: null }, () => {
        this.setState({ showDeleteTagTypeModal: false, isDeleteSuccess: false })
      })
    }

    renderTagTypeName=(name, props) => {
      return (
        <div className='tag-type-title'>
          {this.props.isDeleting ? (<Checkbox checked={this.checkIfSeleted(props.id)} onClick={() => this.onCheck(props.id)} />) : null}
          {name}
        </div>
      )
    }

    renderApiKeyButton=(checked, props) => {
      const { isEnableLoading } = this.state
      return (
        <div className='tag-type-title'>
          <Switch isChecked={!checked} onChange={(status) => this.onChangeApiKeyStatus(props.id, status)} loading={isEnableLoading} />
        </div>
      )
    }

    onChangeApiKeyStatus=(id, status) => {
      const { tagTypeList } = this.state
      const { project } = this.props
      const index = (tagTypeList || []).findIndex(item => item.id === id)
      if (index > -1) {
        this.setState({ isEnableLoading: true })
        // tagTypeList[index].isDisabled=!status
        if (status === true) {
          this.props.enableApiKey(id, project).then(() => {
            message.success('API Key successfully enabled')
            // this.setState({tagTypeList})
            this.setState({ isEnableLoading: false })
            this.props.onHistoryUpdate()
          }, error => {
            utilityService.handleError(error)
            this.setState({ isEnableLoading: false })
          })
        } else if (status === false) {
          this.props.disableApiKey(id, project).then(() => {
            message.success('API Key successfully disabled')
            // this.setState({tagTypeList})
            this.setState({ isEnableLoading: false })
            this.props.onHistoryUpdate()
          }, error => {
            utilityService.handleError(error)
            this.setState({ isEnableLoading: false })
          })
        }
      }
      this.setState({ tagTypeList })
    }

    checkIfSeleted=(id) => {
      let { tagTypeList } = this.state
      const index = tagTypeList.findIndex(tagType => tagType.id === id)
      if (index > -1) { return tagTypeList[index].isSelected } else { return false }
    }

    onCheck=(id) => {
      let { tagTypeList, selectedIds } = this.state
      const index = tagTypeList.findIndex(tagType => tagType.id === id)
      if (index > -1) {
        tagTypeList[index].isSelected = !tagTypeList[index].isSelected
        const selectedIndex = selectedIds.indexOf(id)
        if (selectedIndex > -1) {
          selectedIds.splice(selectedIndex, 1)
        } else {
          selectedIds.push(id)
        }
        this.setState({ selectedIds })
      }
    }

    onDragEnd = (result) => {
      const { appList, onHistoryUpdate } = this.props
      const startIndex = result.source.index
      const endIndex = result.destination.index
      let { tagTypeList } = this.state
      const [ removed ] = tagTypeList.splice(startIndex, 1)
      removed.trackPosChange = true
      tagTypeList.splice(endIndex, 0, removed)
      tagTypeList = tagTypeList.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({ tagTypeList, isDragStart: false })
      const changedTypes = tagTypeList.filter(tagType => {
        const selectedItem = appList.find(innerItem => innerItem.id === tagType.id)
        return selectedItem.position !== tagType.position
      })

      const variables = (changedTypes || []).map(item => {
        return {
          id: item.id,
          position: item.position,
          name: item.name,
          isMediaEnabled: item.isMediaEnabled,
          key: item.key,
          isCustom: item.isCustom,
          trackPosChange: item.trackPosChange,
          enabledAssetTypes: item.enabledAssetTypes === null ? null : (item.enabledAssetTypes || []).map(assetType => {
            delete assetType.__typename
            // delete meta.id
            return assetType
          })
        }
      })
      this.props.batchUpdateTagTypes({ updateData: variables }).then(() => {
        onHistoryUpdate()
      })
    }

    onDragStart = () => {
      const { expandedRowKeys } = this.state
      if (expandedRowKeys.length) { this.setState({ expandedRowKeys: [], isDragStart: true }) } else { this.setState({ isDragStart: true }) }
    }

     dragableBodyRow = (item) => {
       const { isDragStart } = this.state
       const { index } = item
       return <Draggable key={item['data-row-key']} draggableId={item['data-row-key']} index={index} isDragDisabled>
         { (provided, snapshot) => {
           return <tr className={`ant-table-row${isDragStart ? ' dragEnable' : ''}`}
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={getItemStyle(
               snapshot.isDragging,
               provided.draggableProps.style
             )}
           >
             {item.children}
           </tr>
         }}</Draggable>
     }

     handleRowExpand = (record) => {
       this.setState(prevState =>
         prevState.expandedRowKeys.includes(record.id)
           ? {
             expandedRowKeys: prevState.expandedRowKeys.filter(
               key => key !== record.id
             )
           }
           : { expandedRowKeys: [...prevState.expandedRowKeys, record.id] }
       )
     }

     onDeleteApiKeyModal = () => {
       const { selectedTagType } = this.state
       const { onHistoryUpdate, project } = this.props

       if (!selectedTagType) {
         return
       }
       this.setState({ deleteLoading: true })
       this.props.deleteApiKey(selectedTagType.id, project).then(() => {
         message.success('Api Key deleted successfully')
         this.setState({ isDeleteSuccess: true, selectedTagType: null }, () => {
           this.setState({ showDeleteTagTypeModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         onHistoryUpdate()
       }, error => {
         this.setState({ isDeleteSuccess: true, selectedTagType: null }, () => {
           this.setState({ showDeleteTagTypeModal: false, deleteLoading: false, isDeleteSuccess: false })
         })
         utilityService.handleError(error)
       })
     }

     handleAddTagType = () => {
       this.setState({ isShowTagTypeModal: false, selectedTagType: null })
     }

    renderData = (id) => {
      let availableOptions = []
      const { openedType, tagTypeList } = this.state
      // const { tagTypeList } = this.props
      const selectedTagType = (tagTypeList || []).find(item => item.id === id)
      availableOptions = [ ...options ]
      if (selectedTagType) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedType === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    renderAppData = (data) => {
      let newData = (data || []).map(function (data) {
        return {
          name: data && data.name,
          key: data && data.key
        }
      })
      return <Table
        className={`inner-table inner-table-container`}
        rowKey={record => record.title}
        columns={this.metaDataColumns}
        dataSource={newData}
        pagination={false}
        showHeader
      />
    }

    renderInnerRows = (data) => {
      return <React.Fragment>
        {this.renderAppData(data.app)}
      </React.Fragment>
    }

    onHandleBack=() => {
      let { parentRatingList } = this.state
      const newList = (parentRatingList || []).map(item => {
        item.isSelected = false
        return item
      })

      this.setState({ parentRatingList: newList })
      this.props.onHandleBackButton()
    }

    hideApiKeyModal=() => {
      this.setState({ selectedTagType: null })
      this.props.hideApiKeyModal()
    }

    refetchNewApiKeyList=() => {
      this.setState({ loading: true }, () => {
        setTimeout(() => this.props.refetchApiList(), 1000)
      })
    }

    render () {
      const { isLoading, listAssetTypes, onHistoryUpdate, showApiKeyModal, appClient, appList } = this.props
      const { isPaginating, selectedTagType, tagTypeList, showDeleteTagTypeModal, deleteLoading, isDeleteSuccess, expandedRowKeys, loading } = this.state
      let adminItem
      if (selectedTagType && showDeleteTagTypeModal) {
        adminItem = <div >
          <p>{ selectedTagType.label }</p>
        </div>
      }

      const components = {
        body: {
          row: this.dragableBodyRow
        }
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['APP_MANAGER']
            // const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('APP_ADMIN') === -1
            return <div className='admin-portal app-list-container'>
              <div className='admin-list'>
                {/* <FixedTableHeader columnCellList={this.columns} /> */}
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreTagTypes}
                  hasMore={this.props.totalCount > tagTypeList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading || loading}>
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                      <Droppable droppableId={'#TagType_123'}>
                        { (droppableProvided, droppableSnapshot) => (

                          <div className='tag-type-table-container'
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            <Table
                              className={`general-table ${isPaginating ? 'paginating' : ''}`}
                              rowKey={record => record.id}
                              columns={this.columns}
                              dataSource={tagTypeList}
                              pagination={false}
                              expandedRowRender={(data) => this.renderInnerRows(data)}
                              expandedRowKeys={expandedRowKeys}
                              onExpand={(expanded, record) => this.handleRowExpand(record)}
                              components={components}
                              onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow
                              })} />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              {/* <div className='admin-tag-type-footer' >
                <LoadingButton
                  type='primary'
                  onClick={() => this.showCreateTagTypeModal(true, true)}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div> */}
              <CreateAPIKeyModal
                appList={appList}
                isVisible={showApiKeyModal}
                hideApiKeyModal={this.hideApiKeyModal}
                handleSubmit={this.handleAddTagType}
                details={selectedTagType}
                listAssetTypes={listAssetTypes}
                isEdit={!!selectedTagType && showApiKeyModal}
                selectedApp={selectedTagType}
                isSubmitDisabled={!!this.isUpdateDisabled}
                isDeleteSuccess={isDeleteSuccess}
                // maxPosition={tagTypeList && tagTypeList.length ? tagTypeList[tagTypeList.length - 1].position : 0}
                onHistoryUpdate={onHistoryUpdate}
                refetchNewAppList={this.refetchNewApiKeyList}
                project={project}
                appClient={appClient}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteTagTypeModal}
                title={deleteTagTypeMessage.title}
                firstMessage={deleteTagTypeMessage.firstMessage}
                secondMessage={deleteTagTypeMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteTagTypeModal}
                handleSubmit={this.onDeleteApiKeyModal}
                isSubmitButtonDisabled={false}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

AppAPIKeyList.propTypes = {}

export default withApollo(
  compose(
    graphql(
      QueryListAPIKeys,
      {
        options: ({ project, appClient }) => {
          return {
            variables: { limit: 499, project: project },
            fetchPolicy: 'network-only',
            client: appClient
          }
        },
        props: (props) => {
          const apiKeyList = props.data && props.data.listApiKeys ? props.data.listApiKeys.items : []
          return {
            apiKeyList,
            isLoading: props.data.loading || false,
            refetchApiList: () => {
              return props.data.refetch({ limit: 499,
                project: props.ownProps.project
              })
            }
          }
        }
      }
    ),
    graphql(
      QueryListSettingApps,
      {
        options: ({ project, appClient }) => {
          return {
            variables: { limit: 499, project: project },
            fetchPolicy: 'network-only',
            client: appClient
          }
        },
        props: (props) => {
          const appList = props.data && props.data.listApps ? props.data.listApps.items : []
          return {
            appList,
            // isLoading: props.data.loading || false,
            refetchAppList: () => {
              return props.data.refetch({ limit: 499,
                project: props.ownProps.project
              })
            }
          }
        }
      }
    ),
    graphql(MutationEnableApiKey, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { enableApiKey } }) => {
            let variables = { limit: 499, project: project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAPIKeys, variables: variables }))
            if (cacheData && cacheData.listApiKeys && cacheData.listApiKeys.items) {
              let foundIndex = (cacheData.listApiKeys.items).findIndex(item => item.id === enableApiKey.id)
              if (foundIndex > -1) {
                cacheData.listApiKeys.items[foundIndex] = enableApiKey
              }
            }
            cache.writeQuery({
              query: QueryListAPIKeys,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        enableApiKey: (id, project) => {
          return props.mutate({
            variables: { id, project }
          })
        }
      })
    }),
    graphql(MutationDisableApiKey, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { disableApiKey } }) => {
            let variables = { limit: 499, project: project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAPIKeys, variables: variables }))
            if (cacheData && cacheData.listApiKeys && cacheData.listApiKeys.items) {
              let foundIndex = (cacheData.listApiKeys.items).findIndex(item => item.id === disableApiKey.id)
              if (foundIndex > -1) {
                cacheData.listApiKeys.items[foundIndex] = disableApiKey
              }
            }
            cache.writeQuery({
              query: QueryListAPIKeys,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        disableApiKey: (id, project) => {
          return props.mutate({
            variables: { id, project }
          })
        }
      })
    }),
    graphql(MutationDeleteApiKey, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { deleteApiKey } }) => {
            let variables = { limit: 499, project: project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAPIKeys, variables: variables }))
            if (cacheData && cacheData.listApiKeys && cacheData.listApiKeys.items) {
              let foundIndex = (cacheData.listApiKeys.items).findIndex(item => item.id === deleteApiKey.id)
              if (foundIndex > -1) {
                cacheData.listApiKeys.items[foundIndex].isArchived = true
              }
            }
            cache.writeQuery({
              query: QueryListSettingApps,
              data: cacheData
            })
          },
          client: appClient,
          refetchQueries: () => [{ query: QueryListAPIKeys,
            variables: { limit: 499, project } }]
        }
      },
      props: props => ({
        deleteApiKey: (id, project) => {
          return props.mutate({
            variables: { id, project }
          })
        }
      })
    })
  )(AppAPIKeyList))
