import React from 'react'
import PropTypes from 'prop-types'
import { message, Modal, Switch } from 'antd'

import Input from '../inputs/Input'
import DropDown from '../inputs/DropDown'
import MultipleDropDown from './../inputs/AppMutiDorpDown'
import { utilityService } from '../../../../services/UtilityService'
// import bucketTypes from './../../../../constants/bucket-group-types'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateAppGroup from './../../../../graphQL/appManager/createAppGroup'
import MutationEditAppGroup from './../../../../graphQL/appManager/editAppGroup'
import MutationEditAppPageGroup from './../../../../graphQL/appManager/editAppGroupPage'
import QueryGetGroupList from './../../../../graphQL/appManager/listGroups'
import QueryUserList from './../../../../graphQL/role/listUsers'
import QueryCriticalUserList from './../../../../graphQL/appManager/listCriticalUsers'
// import QueryGetAppConfig from './../../../../graphQL/appManager/getAppConfig'
class CreateAppGroupModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      newTitle: props.details ? props.details.name : '',
      selectedApp: props.details ? props.details.app.id : '',
      key: props.details ? props.details.key : '',
      isLoading: false,
      isEdited: false,
      isCritical: false,
      isNameChanged: false,
      isCriticalChanged: false,
      assignedUsers: [],
      isAssigneeChanged: false,
      userSearch: ''
    }
  }

  componentDidMount=() => {
    if (this.props.details) {
      this.setState({
        newTitle: this.props.details ? this.props.details.name : '',
        selectedApp: this.props.details ? this.props.details.app.id : '',
        key: this.props.details ? this.props.details.key : '',
        isCritical: this.props.details && this.props.details.isCritical ? this.props.details.isCritical : false,
        assignedUsers: (this.props.details && this.props.details.assignedUsers) || []
      })
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.details, this.props.details)) {
      this.setState({
        newTitle: newProps.details ? newProps.details.name : '',
        selectedApp: newProps.details ? newProps.details.app.id : '',
        key: newProps.details ? newProps.details.key : '',
        isCritical: newProps.details && newProps.details.isCritical ? newProps.details.isCritical : false,
        assignedUsers: newProps.details && newProps.details.assignedUsers && newProps.details.assignedUsers.length > 0 ? newProps.details.assignedUsers : []
      })
    } else if (newProps.details && !_.isEqual(newProps.details, this.state.details)) {
      this.setState({
        newTitle: newProps.details ? newProps.details.name : '',
        selectedApp: newProps.details ? newProps.details.app.id : '',
        key: newProps.details ? newProps.details.key : '',
        isCritical: newProps.details && newProps.details.isCritical ? newProps.details.isCritical : false,
        assignedUsers: newProps.details && newProps.details.assignedUsers && newProps.details.assignedUsers.length > 0 ? newProps.details.assignedUsers : []
      })
    }
  }

  handleChange = (e) => {
    const { value } = e.target
    const { isFromEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.newTitle || '')
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isFromEdit) {
      const key = utilityService.slugifyString(value).slice(0, 125).toLocaleUpperCase()
      this.setState({ newTitle: value, key })
    } else {
      this.setState({ newTitle: value, isEdited: true, isNameChanged: true })
    }
  }

  handleIdChange =(e) => {
    const { value } = e.target
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(value) ? value.toLocaleUpperCase() : this.state.key
    this.setState({ key, isEdited: true })
  }

  onOptionSelect = (id) => {
    const appData = (this.props.appList || []).find(appItem => appItem.id === id)
    if (appData && !this.props.isFromEdit) {
      let users = this.state.assignedUsers || []
      // let isUserExist = false
      // ((appData && appData.assignedUsers) || []).map(user => {
      //   if (!users.includes(user)) {
      //     users.push(user)
      //   }
      // })
      this.setState({ selectedApp: id, isEdited: true, appData: appData, assignedUsers: users })
    } else {
      this.setState({ selectedApp: id, isEdited: true, appData: appData })
    }
  }

  handleSubmit = () => {
    const { isFromEdit, type, details, isCriticalDisabled, isAppAdminDisabled, userId } = this.props
    const { newTitle, key, selectedApp, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged, appData } = this.state
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)

    if (!isFromEdit && appData && appData.assignedUsers && appData.assignedUsers.length > 0 && isAppAdminDisabled) {
      const isUserAssignedToApp = ((appData && appData.assignedUsers) || []).find(user => user === userId)
      if (!isUserAssignedToApp) {
        message.error('You do not have permission to create this Group, since user is not assigned to App')
        return
      }
    }
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Group')
      return
    }
    if (isFromEdit && ((isCriticalDisabled && isAppAdminDisabled && (isCritical || isAppCritical)) || (!isAssignedUser && assignedUsers && assignedUsers.length > 0 && isAppAdminDisabled))) {
      message.error('You do not have access to do this operation on this Group')
      return
    }

    if (assignedUsers && assignedUsers.length) {
      const finalUserList = isCritical ? this.props.criticalUserList : this.props.userList
      let inValidUsers = []
      for (let i = 0; i < assignedUsers.length; i++) {
        const isValidUser = (finalUserList || []).find(user => user.id === assignedUsers[i])
        if (_.isEmpty(isValidUser)) {
          inValidUsers.push(assignedUsers[i])
        }
      }
      if (isCritical && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Please Remove Users that are not Critical Users before saving Group. Please find list of invalid users: ' + inValidUsersString)
        return
      } else if (!isCritical && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Please Remove Users that do not have required permissions before saving Group. Please find list of invalid users: ' + inValidUsersString)
        return
      }
    }
    this.setState({ isLoading: true })
    // this.props.handleSubmit(newTitle, key, selectedType)
    if (isFromEdit) {
      if (type === 'PAGE') {
        this.props.editAppPageGroup(newTitle, key, selectedApp, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged).then(() => {
          message.success('Group updated successfully')
          this.setState({ isLoading: false, isEdited: false })
          if (this.props.handleRefetchPageDetails) {
            this.props.handleRefetchPageDetails(true)
          }
          this.props.reCreatePageTree()
          this.props.handleCancel()
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false, isEdited: false })
          this.props.handleCancel()
        })
      } else {
        this.props.editAppGroup(newTitle, key, selectedApp, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged).then(() => {
          message.success('Group updated successfully')
          this.setState({ isLoading: false, isEdited: false })
          if (this.props.handleRefetchConfigDetails) {
            this.props.handleRefetchConfigDetails(true)
          }
          this.props.reCreatePageTree()
          this.props.handleCancel()
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false, isEdited: false })
          this.props.handleCancel()
        })
      }
    } else {
      this.props.createAppGroup(newTitle, key, selectedApp, type, isCritical, assignedUsers).then(() => {
        message.success('Group created successfully')
        this.setState({ isLoading: false, isEdited: false })
        this.props.reCreatePageTree()
        this.props.handleCancel()
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEdited: false })
        this.props.handleCancel()
      })
    }
  }

  resetState = () => {
    const state = {
      newTitle: '',
      selectedApp: '',
      key: '',
      isEdited: false,
      isLoading: false,
      isCritical: false,
      isNameChanged: false,
      isCriticalChanged: false,
      assignedUsers: [],
      isAssigneeChanged: false,
      userSearch: '',
      appData: null
    }
    this.setState({ ...state })
  }

  onChangeGroupStatus = (value) => {
    const { assignedUsers } = this.state
    let tempAssignedUsers = []
    if (this.props.isAppAdminDisabled) {
      message.warn('You do not have permission to update critical flag')
      return
    }

    if (assignedUsers && assignedUsers.length) {
      const finalUserList = value ? this.props.criticalUserList : this.props.userList
      let inValidUsers = []
      for (let i = 0; i < assignedUsers.length; i++) {
        const foundIndex = (finalUserList || []).findIndex(user => user.id === assignedUsers[i])
        if (foundIndex > -1) {
          tempAssignedUsers.push(assignedUsers[i])
        } else {
          inValidUsers.push(assignedUsers[i])
        }
      }
      this.setState({ assignedUsers: tempAssignedUsers })
      if (value && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Removing Users that are not Critical Users. Please find list of invalid users removed: ' + inValidUsersString)
      } else if (!value && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Removing Users that do not have required permissions. Please find list of invalid users removed: ' + inValidUsersString)
      }
    }
    this.setState({ isCritical: value, isEdited: true, isCriticalChanged: true })
  }

  handleUserChange = (option) => {
    const { details, userList, criticalUserList } = this.props
    const { isCritical } = this.state
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isGroupCritical = !!((isCritical || isAppCritical))
    const finalUserList = isGroupCritical ? criticalUserList : userList

    // if (!isFromEdit && appData && appData.assignedUsers && appData.assignedUsers.length > 0) {
    //   for (let i = 0; i < appData.assignedUsers.length; i++) {
    //     const foundUser = (option || []).find(user => user.id === appData.assignedUsers[i])
    //     if (!foundUser) {
    //       message.error('You cannot remove user that is already assigned to App/Group')
    //       return
    //     }
    //   }
    // }
    let finalOptions = (option || []).map(item => item && item.id)
    let assignedUsers = (finalUserList || []).filter(item => finalOptions.includes(item.id)).map(item => item.id)
    // apps.sort(function (a, b) {
    //   return option.indexOf(a.name) - option.indexOf(b.name)
    // })
    // details.countries = countries

    this.setState({ assignedUsers, isEdited: true, isAssigneeChanged: true, userSearch: '' })
  }

  handleUserSearch=(value) => {
    this.setState({ userSearch: value })
  }

  render () {
    const { isVisible, handleCancel, title, isFromEdit, appList, details, userList, criticalUserList, isAppAdminDisabled } = this.props
    const { newTitle, selectedApp, key, isLoading, isEdited, isCritical, assignedUsers, userSearch } = this.state
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isGroupCritical = !!((isCritical || isAppCritical))
    return (

      <Modal
        className='general-modal app-group-modal'
        visible={isVisible}
        closable={false}
        title={title}
        maskClosable={false}
        okButtonProps={{ disabled: !(newTitle && selectedApp && key) || !isEdited, loading: isLoading }}
        onOk={this.handleSubmit || this.handleEdit}
        onCancel={handleCancel}
        okText='Save'
        cancelText='Cancel'
        afterClose={this.resetState}
        centered
        destroyOnClose
      >
        <Input title='Name' handleChange={this.handleChange} value={newTitle} limit={100} placeholder='Enter Name' isRequired />
        <Input title='Key' handleChange={this.handleIdChange} value={key} limit={128} readOnly={isFromEdit} placeholder='Enter Key' isRequired />
        <p className='bold'>App <span className='mandatory'>*</span></p>
        <DropDown options={appList} selectedValue={selectedApp} placeholder='Select App' valueParam='id' displayParam='name' onOptionSelect={this.onOptionSelect} disabled={isFromEdit} />
        <div id='dropdown-user' className='dropdown-user'>
          <label className='title-text'>Users</label>
          <MultipleDropDown options={isGroupCritical ? criticalUserList : userList} onOptionSelect={this.handleUserChange} selectedValue={assignedUsers} valueParam='id' displayParam='id' placeHolder='Select Users' parent={'dropdown-user'} disabled={isAppAdminDisabled} onSearch={this.handleUserSearch} searchText={userSearch} allowClear />
        </div>
        <div className='switch-inner-container'>
          <span>{'Critical'}</span>
          <Switch checked={(isAppCritical ? true : isCritical)} onChange={this.onChangeGroupStatus} disabled={isAppCritical} />
        </div>
      </Modal>

    )
  }
}

CreateAppGroupModal.propTypes = {
  /** visible action of AddBucketGroupModal. */
  visible: PropTypes.bool,
  /** title  of AddBucketGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of AddBucketGroupModal. */
  handleCancel: PropTypes.func,
  /** Boolean for showing loading */
  loading: PropTypes.bool,
  /** Boolean to know source of the modal */
  isFromEdit: PropTypes.bool
}

CreateAppGroupModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationCreateAppGroup, {
        options: ({ project, appClient, type }) => {
          return {
            update: (cache, { data: { createGroup } }) => {
              // let type = appMode === 'pages' ? 'PAGE' : 'CONFIG'
              let filter = {
                type: {
                  match: type
                }
              }
              const variables = { limit: 499, project, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetGroupList, variables }))
              if (cacheData && cacheData.listGroups && cacheData.listGroups.items) { cacheData.listGroups.items.push(createGroup) }
              cache.writeQuery({
                query: QueryGetGroupList,
                data: cacheData,
                variables
              })
            },
            client: appClient
          }
        },
        props: (props) => ({
          createAppGroup: (name, key, app, type, isCritical, assignedUsers) => {
            return props.mutate({
              variables: { name, key, app, project: props.ownProps.project, type, isCritical, assignedUsers }
            })
          }
        })
      }
    ),
    graphql(
      MutationEditAppGroup, {
        options: ({ project, appClient, type, selectedConfig }) => {
          return {
            update: (cache, { data: { editGroup } }) => {
              let filter = {
                type: {
                  match: type
                }
              }
              const variables = { limit: 499, project, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetGroupList, variables }))
              if (cacheData && cacheData.listGroups && cacheData.listGroups.items) {
                const foundIndex = (cacheData.listGroups.items || []).findIndex(item => item.key === editGroup.key)
                if (foundIndex > -1) {
                  cacheData.listGroups.items[foundIndex] = editGroup
                }
              }
              // let configVariables = {id: selectedConfig, project: project}
              // const getConfigCacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppConfig, configVariables }))
              // if (getConfigCacheData && getConfigCacheData.getAppConfig && getConfigCacheData.getAppConfig.data && editGroup) {
              //   if(getConfigCacheData.getAppConfig.data.group){
              //     getConfigCacheData.getAppConfig.data.group.isCritical = editGroup.isCritical
              //   }
              // }
              cache.writeQuery({
                query: QueryGetGroupList,
                data: cacheData,
                variables
              })
              // cache.writeQuery({
              //   query: QueryGetAppConfig,
              //   data: getConfigCacheData,
              //   configVariables
              // })
            },
            client: appClient
          }
        },
        props: (props) => ({
          editAppGroup: (name, key, app, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged) => {
            return props.mutate({
              variables: { name, key, app, project: props.ownProps.project, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged }
            })
          }
        })
      }
    ),
    graphql(
      MutationEditAppPageGroup, {
        options: ({ project, appClient, type }) => {
          return {
            update: (cache, { data: { editPageGroup } }) => {
              let filter = {
                type: {
                  match: type
                }
              }
              const variables = { limit: 499, project, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetGroupList, variables }))
              if (cacheData && cacheData.listGroups && cacheData.listGroups.items) {
                const foundIndex = (cacheData.listGroups.items || []).findIndex(item => item.key === editPageGroup.key)
                if (foundIndex > -1) {
                  cacheData.listGroups.items[foundIndex] = editPageGroup
                }
              }
              cache.writeQuery({
                query: QueryGetGroupList,
                data: cacheData,
                variables
              })
            },
            client: appClient
          }
        },
        props: (props) => ({
          editAppPageGroup: (name, key, app, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged) => {
            return props.mutate({
              variables: { name, key, app, project: props.ownProps.project, isCritical, isNameChanged, isCriticalChanged, assignedUsers, isAssigneeChanged }
            })
          }
        })
      }
    ),
    graphql(
      QueryUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            userList: data.listUsers && data.listUsers.items && data.listUsers.items.length ? data.listUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listUsers ? data.listUsers.totalCount : 0,
            getMoreUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listUsers.items, ...fetchMoreResult.listUsers.items]
                  prev.listUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    ),
    graphql(
      QueryCriticalUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            criticalUserList: data.listCriticalUsers && data.listCriticalUsers.items && data.listCriticalUsers.items.length ? data.listCriticalUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listCriticalUsers ? data.listCriticalUsers.totalCount : 0,
            getMoreCriticalUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listCriticalUsers.items, ...fetchMoreResult.listCriticalUsers.items]
                  prev.listCriticalUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    )
  )(CreateAppGroupModal))
