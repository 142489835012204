import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Checkbox } from 'antd'
import Input from './../inputs/Input'
import CropWhiteIcon from '../../general/icons/CropWhiteIcon'
import AddIcon from '../../general/icons/AddIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'

class CreateAuthorContainer extends React.Component {
    state = {
      showCrop: false
    }

    showCrop = (e) => {
      e.stopPropagation()
      this.setState({ showCrop: true })
    }

    showCropModal = (e) => {
      e.stopPropagation()
      if (this.props.selectedImage && this.props.selectedImage.length) {
        this.props.showCropModal(this.props.selectedImage)
      } else {
        this.props.showImageModal()
      }
    }

    hideCrop = () => {
      this.setState({ showCrop: false })
    }

    removeImage = (e) => {
      e.stopPropagation()

      this.props.clearSelection()
    }

    render () {
      const image = this.props.selectedImage && this.props.selectedImage.length ? generateCroppedThumbnail(this.props.selectedImage[0], 200, 200, '1:1') : undefined
      const defaultImage = require('../../../../assets/images/default-author.png')
      const currentImage = image || defaultImage
      const croppedImage = this.props.croppedUrl
      return (
        <div id='create-author-modal'>
          <div className={`create-author-modal-container ${this.props.module === 'ASSET_MANAGER' ? 'asset-create' : ''}`}>
            <div className='avatar' onClick={() => this.props.showImageModal()} onMouseLeave={() => this.hideCrop()}>
              { this.state.showCrop

                ? <div className='avatar-hover' onClick={(e) => this.showCropModal(e)}>
                  <span>{ image ? <CropWhiteIcon /> : <AddIcon /> }</span>
                </div>
                : <React.Fragment>
                  <div onMouseEnter={(e) => this.showCrop(e)} onMouseLeave={() => this.hideCrop()}>
                    <Avatar size={64} icon='user' src={croppedImage || currentImage} />
                  </div>
                  { image ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e)} className='oval'>
                    <CrossIcon color='#FFFFFF' />
                  </div> : null}
                </React.Fragment>
              }

            </div>
            <div className='input-item'>
              <Input title='Author Name' isRequired placeholder='Insert Author Name' limit={40} inputName='title' value={this.props.authorName} handleChange={(event) => this.props.handleTextChange(event)} />
              <div className='author-check-container'>
                <label >Active</label>
                <Checkbox checked={!this.props.isDisabled} onClick={this.props.handleActiveChange} />
              </div>
            </div>
          </div>
        </div>
      )
    }
}

CreateAuthorContainer.propTypes = {
  /** Details of selected image */
  selectedImage: PropTypes.array,
  /** Function to save the author */
  createAuthor: PropTypes.func,
  /** Callback when content is edited */
  handleTextChange: PropTypes.func,
  /** Name of the Author */
  authorName: PropTypes.string,
  /** Callback to AddImageModal */
  showImageModal: PropTypes.func
}

CreateAuthorContainer.defaultProps = {
}

export default CreateAuthorContainer
