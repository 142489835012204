import React, { Component } from 'react'
import { Table, Empty, Skeleton } from 'antd'
import { Resizable } from 'react-resizable'

import moment from 'moment'

import SupportTicketCommentsModal from '../dataEntry/other/SupportTicketCommentsModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListTickets from '../../../graphQL/customers/listTickets'
import CustomerPreviewImageModal from '../dataEntry/other/CustomerPreviewImageModal'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class SupportTicketTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [

      {
        title: 'Ticket ID',
        dataIndex: 'id',
        width: 100,
        key: 'ticketID'
        // className: 'ticket-id'
      },
      {
        title: 'Case Number',
        dataIndex: 'caseNumber',
        key: 'caseNumber',
        width: 100
        // className: 'case-no'
      },
      {
        title: 'Case Category',
        dataIndex: 'caseCategory',
        key: 'caseCategory',
        width: 100
        // className: 'case-category'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        key: 'status'
        // className: 'status'
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        width: 100,
        key: 'subject'
        // className: 'subject'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 100
        // className: 'description'
      },
      {
        title: 'Comments',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        // className: 'comments',
        render: (id, data) => this.renderComments(id, data)
      },
      {
        title: 'Attachments',
        dataIndex: 'id',
        width: 80,
        // className: 'comments',
        render: (id, data) => this.renderAttachments(id, data)
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        width: 100,
        key: 'createdDate',
        // className: 'created-date',
        render: (createdDate) => moment(new Date(createdDate)).format('DD MMM YYYY, HH:mm:ss')
      },
      {
        title: 'Last Updated',
        dataIndex: 'lastModifiedDate',
        key: 'lastModifiedDate',
        width: 100,
        // className: 'modified-date',
        render: (lastModifiedDate) => moment(new Date(lastModifiedDate)).format('DD MMM YYYY, HH:mm:ss')
      }
    ]

    this.state = {
      ticketData: [],
      showComments: false,
      width: null,
      height: null,
      columns: this.columns
    }

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }

    this.tableSize = React.createRef()
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
  }

  showViewCommentsModal = (data) => {
    this.setState({ showComments: true, ticketData: data })
  }

  hideViewCommentsModal = () => {
    this.setState({ showComments: false })
  }

  showViewAttachmentsModal = (data) => {
    this.setState({ showAttachments: true, attachmentData: data })
  }

  hideViewAttachmentsModal = () => {
    this.setState({ showAttachments: false, attachmentData: null })
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  renderAttachments = (id, data) => {
    return data && data.attachment && data.attachment.fileName ? <div className='comments' onClick={() => this.showViewAttachmentsModal(data)}>{data.attachment.fileName}</div> : ''
  }

  renderComments = (id, data) => {
    return data && data.comments && data.comments.length ? <div className='comments' onClick={() => this.showViewCommentsModal(data)}> View Comments </div> : ''
  }

  render () {
    const { ticketData, showComments, width, height, columns, showAttachments, attachmentData } = this.state
    const { supportTickets, isLoading } = this.props

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))
    const previewImageSrc = attachmentData ? `data:${attachmentData.attachment.contentType};base64,${attachmentData.attachment.body}` : ''
    return (
      <div className='general-customer-details-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(supportTickets) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={supportTickets} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
        <SupportTicketCommentsModal isVisible={showComments} handleCancel={this.hideViewCommentsModal} ticketData={ticketData} />
        { showAttachments ? <CustomerPreviewImageModal previewImageSrc={previewImageSrc} visible={showAttachments} onClose={this.hideViewAttachmentsModal} /> : null }
      </div>
    )
  }
}

SupportTicketTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListTickets,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer } = props
        const variables = { userId: selectedCustomer }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const supportTickets = data && data.listTickets ? data.listTickets : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { supportTickets, isLoading: data && data.loading }
      }
    }
  )
)(SupportTicketTable))
