import React, { useEffect, useRef, useState, useCallback } from 'react'
import bitmovinPlayer from './bitmovin'
import PlayerUtils, { getStreamURL } from '../Utils/playerUtils'
import 'bitmovin-player/bitmovinplayer-ui.css'
import PlayerControllerStrip from '../playbackControllerStrip/playerControllerStrip'

/**
 * This is the bitmovin component where the player is initialised and loaded
 * @param {*} props contains the playlist and callbacks for the player
 * @returns Bitmovin player component
 */
const ReactBitmovinPlayer = props => {
  const {
    playerId,
    playlist,
    playerCallbacks,
    isEditable,
    isLive,
    defaultTrimStart,
    broadCastStartTime,
    playTime,
    showPlayer,
    onTogglePlayer,
    playerStartTime
  } = props
  const playerDiv = useRef()
  const [player, setPlayer] = useState(null)
  const memoizedPlayList = useCallback(playlist, [playlist])

  useEffect(
    () => {
      if (!player) {
        let playerInstance = bitmovinPlayer.getInstance(playerDiv.current, playerCallbacks)
        setPlayer(playerInstance)
        bitmovinPlayer.bindPlayerEventListeners(playerCallbacks)
      }
    },
    [playerDiv.current]
  )

  useEffect(
    () => {
      if (player && getStreamURL(playlist)) {
        bitmovinPlayer.load(PlayerUtils.getBitmovinPlayerConfig(playlist))
      }
      return () => {
        if (player && bitmovinPlayer && getStreamURL(playlist)) {
          bitmovinPlayer.unload()
        }
      }
    },
    [player, playerStartTime, memoizedPlayList]
  )

  return (
    <>
      <div id={playerId} ref={playerDiv} />
      {player ? (
        <PlayerControllerStrip
          onClickProgressBar={playerCallbacks.onClickProgressBar}
          isEditable={isEditable}
          isLive={isLive}
          defaultTrimStart={defaultTrimStart}
          broadCastStartTime={broadCastStartTime}
          playTime={playTime}
          showPlayer={showPlayer}
          onTogglePlayer={onTogglePlayer}
        />
      ) : null}
    </>
  )
}
export default ReactBitmovinPlayer
