import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import Input from '../inputs/Input'
import InputNumber from '../inputs/InputNumber'

import './../../ui.style.scss'
import GenralDropDown from '../inputs/GenralDropDown'
import { utilityService } from '../../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateCustomMetaField from '../../../../graphQL/admin/customMetaFields/createCustomMetaField'
import QueryCustomMetaFields from '../../../../graphQL/admin/customMetaFields/listCustomMetaFields'
import MutationUpdateCustomMetaField from '../../../../graphQL/admin/customMetaFields/updateCustomMetaField'

const parentCompoent = 'new-custom-meta-field-container'

const fieldTypeData = [
  {
    id: '1',
    name: 'Boolean'
  },
  {
    id: '2',
    name: 'String'
  },
  {
    id: '3',
    name: 'Number'
  }
]

const sectionData = [
  {
    id: '1',
    name: 'MATCH_MANAGER'
  },
  {
    id: '2',
    name: 'APP'
  },
  {
    id: '3',
    name: 'APP_PAGE'
  },
  {
    id: '4',
    name: 'APP_CONFIG'
  }
]

const defaultData = [
  {
    id: '1',
    name: 'false'
  },
  {
    id: '2',
    name: 'true'
  }
]

class CreateCustomMetaFieldModalVcms extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      defaultValue: null,
      // isEnable: false,
      isLoading: false,
      type: '',
      section: ''
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    if (nextProps.selectedCustomField && (!_.isEqual(this.props.selectedCustomField, nextProps.selectedCustomField) || (!this.state.parentalRatingName))) {
      this.setState({
        name: nextProps.selectedCustomField.name,
        defaultValue: nextProps.selectedCustomField.default,
        type: nextProps.selectedCustomField.type,
        section: nextProps.selectedCustomField.section
      })
    }
    if (nextProps.isDeleted) {
      this.resetState()
    }
  }

  handleSubmit = () => {
    const { name, section, type, defaultValue } = this.state
    const { selectedCustomField, onHistoryUpdate } = this.props
    this.setState({ isLoading: true })
    if (selectedCustomField) {
      const newCustomField = {
        id: selectedCustomField.id,
        name: name ? name.trim() : '',
        type: type.toUpperCase(),
        section: section,
        default: defaultValue === '' || defaultValue === undefined ? null : defaultValue
      }
      if (!name) {
        message.error('Name is mandatory field')
        this.setState({ isLoading: false })
        return
      }
      if (!type) {
        message.error('Type is mandatory field')
        this.setState({ isLoading: false })
        return
      }
      if (!section) {
        message.error('Section is mandatory field')
        this.setState({ isLoading: false })
        return
      }
      // if (!defaultValue) {
      //   message.error('Default is mandatory field')
      //   this.setState({ isLoading: false })
      //   return
      // }
      this.props.updateCustomMetaField(newCustomField).then(response => {
        this.props.handleSubmit(response.data.updateMetaFieldConfig)
        if (onHistoryUpdate) {
          onHistoryUpdate()
        }
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else {
      const newCustomField = {
        name: name ? name.trim() : '',
        type: type.toUpperCase(),
        section: section,
        default: defaultValue === '' ? null : defaultValue
      }
      this.props.createCustomMetaField(newCustomField).then(response => {
        if (onHistoryUpdate) {
          onHistoryUpdate()
        }
        message.success('Custom Field created successfully')
        this.props.handleSubmit(response.data.createMetaFieldConfig)
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  resetState = () => {
    const state = {
      isLoading: false,
      name: '',
      section: '',
      defaultValue: null,
      type: ''
    }
    this.setState({ ...state })
  }

  handleFieldNameChange = (e) => {
    const { value } = e.target
    this.setState({
      name: value
    })
  }

  handleDefaultValueChange = (e) => {
    const { value } = e.target
    this.setState({
      defaultValue: value
    })
  }

  handleDefaultValueNumberChange = (value) => {
    // const { value } = e.target
    this.setState({
      defaultValue: value
    })
  }

  handleDefaultValueBooleanChange = (value) => {
    // const { value } = e.target
    this.setState({
      defaultValue: value
    })
  }

  handleFieldTypeChange = (fieldType) => {
    const { type } = this.state
    if (type && type !== fieldType && (fieldType === 'BOOLEAN' || fieldType === 'Boolean')) {
      this.setState({
        type: fieldType,
        defaultValue: null
      })
    } else {
      this.setState({
        type: fieldType,
        defaultValue: null
      })
    }
  }

  handleSectionChange = (section) => {
    if (section === 'OFFER PRICE') {
      section = 'OFFER_PRICE'
    }
    this.setState({
      section
    })
  }

  render () {
    const { name, defaultValue, isLoading, type, section } = this.state
    const { isVisible, handleCancel, selectedCustomField, isSubmitDisabled } = this.props
    const disabled = selectedCustomField ? (selectedCustomField.name === name && selectedCustomField.default === defaultValue && selectedCustomField.type && selectedCustomField.section) : !(name && section && type)
    return (
      <Modal
        className='general-modal new-custom-meta-field'
        title={name ? 'EDIT CUSTOM META FIELD' : 'NEW CUSTOM META FIELD'}
        maskClosable={false}
        visible={isVisible}
        okText='Save'
        cancelText='Back'
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoading}
        afterClose={this.resetState}
      >
        <div className='new-custom-meta-field-container' id='new-custom-meta-field-container'>
          <Input
            title='Field Name'
            placeholder='Insert Field Name'
            inputName='fieldName'
            value={name}
            handleChange={this.handleFieldNameChange}
          />
          <div className='input-field'>
            <label className='input-title'>Field Type</label>
            <GenralDropDown
              options={fieldTypeData}
              className='custom-meta-field-dropdown'
              valueParam='name'
              displayParam='name'
              onOptionSelect={this.handleFieldTypeChange}
              placeHolder='Select Field Type'
              parent={parentCompoent}
              selectedValue={type || undefined}
              allowClear
              disabled={selectedCustomField ? selectedCustomField.type : false}
            />
          </div>
          <div className='input-field'>
            <label className='input-title'>Section</label>
            <GenralDropDown
              options={sectionData}
              className='custom-meta-field-dropdown'
              valueParam='name'
              displayParam='name'
              onOptionSelect={this.handleSectionChange}
              placeHolder='Select Section'
              parent={parentCompoent}
              selectedValue={section || undefined}
              allowClear
              disabled={selectedCustomField ? selectedCustomField.section : false}
            />
          </div>
          {type === 'BOOLEAN' || type === 'Boolean'
            ? <div className='input-field'>
              <label className='input-title'>Default Value</label>
              <GenralDropDown
                options={defaultData}
                className='custom-meta-field-dropdown'
                valueParam='name'
                displayParam='name'
                onOptionSelect={this.handleDefaultValueBooleanChange}
                placeHolder='Select Default Value'
                parent={parentCompoent}
                selectedValue={defaultValue || undefined}
                allowClear
              // disabled={selectedCustomField ? selectedCustomField.section : false}
              />
            </div>
            : type === 'NUMBER' || type === 'Number'
              ? <InputNumber
                title='Default Value'
                placeholder='Insert Default Value'
                inputName='defaultValue'
                value={defaultValue}
                handleChange={this.handleDefaultValueNumberChange}
              />

              : <Input
                title='Default Value'
                placeholder='Insert Default Value'
                inputName='defaultValue'
                value={defaultValue}
                handleChange={this.handleDefaultValueChange}
              />}

        </div>

      </Modal>
    )
  }
}

CreateCustomMetaFieldModalVcms.propTypes = {

}

CreateCustomMetaFieldModalVcms.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateCustomMetaField,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { createMetaFieldConfig } }) => {
            try {
              const variables = { section: null, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCustomMetaFields, variables }))
              if (cacheData && cacheData.listMetaFieldConfigs) {
                cacheData.listMetaFieldConfigs.unshift(createMetaFieldConfig)
              }
              cache.writeQuery({
                query: QueryCustomMetaFields,
                data: cacheData,
                variables
              })
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createCustomMetaField: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateCustomMetaField,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateMetaFieldConfig } }) => {
            try {
              const newVariables = { section: null, project }
              const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryCustomMetaFields, variables: newVariables }))
              const index = newCacheData.listMetaFieldConfigs.findIndex(item => item.id === updateMetaFieldConfig.id)
              if (index > -1 && updateMetaFieldConfig.name && updateMetaFieldConfig.default) {
                newCacheData.listMetaFieldConfigs[index].name = updateMetaFieldConfig.name
                newCacheData.listMetaFieldConfigs[index].default = updateMetaFieldConfig.default
              }
              // if (index > -1 && updateAssetCategory.slug) {
              //   newCacheData.listAssetCategories.items[index].slug = updateAssetCategory.slug
              // }
              cache.writeQuery({
                query: QueryCustomMetaFields,
                data: newCacheData,
                variables: newVariables
              })
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        updateCustomMetaField: (variables) => {
          variables.project = props.ownProps.project
          console.log(variables)
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateCustomMetaFieldModalVcms))
