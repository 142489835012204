import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Input from './../inputs/Input'
import TagsInput from '../inputs/TagsInput'
import './../../ui.style.scss'

// import QueryTags from '../../../../graphQL/asset/getTags'
import QueryGetContent from '../../../../graphQL/filter/getContent'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { withRouter } from 'react-router-dom'

class EditFolderModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: undefined,
      folderName: undefined,
      disable: true,
      selectedGroups: [],
      isSaveEnabled: false
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.editFolderInfo && newProps.editFolderInfo[0] && !_.isEqual(newProps.editFolderInfo, this.props.editFolderInfo)) {
      let tags = []
      if (newProps.editFolderInfo[0].tags) {
        newProps.editFolderInfo[0].tags.map((tag) => {
          tags.push(tag.key)
        })
      }
      this.setState({
        id: _.cloneDeep(newProps.editFolderInfo[0].id),
        folderName: _.cloneDeep(newProps.editFolderInfo[0].name),
        selectedGroups: _.cloneDeep(newProps.editFolderInfo[0].tags)
      })
    }
  }

  resetState = () => {
    const state = {
      id: undefined,
      folderName: undefined,
      disable: true,
      selectedGroups: [],
      isSaveEnabled: false
    }
    this.setState({ ...state })
  }

  handleNameChange =(e) => {
    const { value } = e.target
    this.setState({ folderName: value, isSaveEnabled: true })
  }

  handleSubmit = () => {
    const { id, folderName, selectedGroups } = this.state
    const tags = selectedGroups.map((tag) => tag.key)
    if (id && !_.isEmpty(id)) {
      const input = {
        id,
        name: folderName,
        tags: tags
      }
      this.props.handleSubmit(input)
    } else {
      const input = {
        name: folderName,
        tags: tags
      }
      this.props.handleSubmit(input)
    }
  }

  onChangeTags = (changedGroup, isDelete) => {
    let { selectedGroups } = this.state
    if (isDelete) {
      const index = selectedGroups.findIndex(item => item.key === changedGroup)
      if (index > -1) { selectedGroups.splice(index, 1) }
    } else {
      const index = selectedGroups.findIndex(item => item.key === changedGroup)
      if (index < 0) {
        const tag = this.props.groupList.filter((tag) => (tag.key === changedGroup))
        selectedGroups.push(...tag)
      }
    }
    this.setState({ selectedGroups, isSaveEnabled: true })
  }

  render () {
    const { visible, handleCancel, title, loading, parentCompoent, okText, isSubmitDisabled, onSearchTags, groupList } = this.props
    const { folderName, selectedGroups, isSaveEnabled } = this.state
    // const groupList = searchKey ? (_.cloneDeep(this.props.groupList) || []).filter(group => (group.name.toLowerCase()).indexOf(searchKey) > -1) : _.cloneDeep(this.props.groupList)
    // const selectedDisplayItems = (groupList||[]).filter(item => (selectedGroups||[]).includes(item.key)).map(item => item.name)
    return (

      <Modal
        className='general-modal add-folder-modal'
        visible={visible}
        closable={false}
        title={title}
        // okButtonProps={{ disabled: disable, loading: loading }} (needed)
        okButtonProps={{ disabled: !(folderName && isSaveEnabled && selectedGroups.length > 0 && !isSubmitDisabled), loading: loading }}
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okText={okText}
        cancelText='Cancel'
        afterClose={this.resetState}
        width='890px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <Input title='Folder Name' handleChange={this.handleNameChange} value={folderName} placeholder='Insert Folder Name' />
        <label className='folder-tags'> Tags </label>
        <TagsInput
          selectedItems={selectedGroups ? selectedGroups.map((item) => item.key) : []}
          selectedDisplayItems={selectedGroups ? selectedGroups.map((item) => item.name) : []}
          keyParameter={'key'}
          tagList={groupList || []}
          onChange={this.onChangeTags}
          placeholder='Insert Tags'
          parentCompoent={parentCompoent}
          displayParameter={'name'}
          mode={'multiple'}
          onSearch={onSearchTags}
          isDropdownInvisible
        />

      </Modal>
    )
  }
}

EditFolderModal.propTypes = {
  /** visible action of EditFolderModal. */
  visible: PropTypes.bool,
  /** title  of EditFolderModal. */
  title: PropTypes.string,
  /** handleCancel action of EditFolderModal. */
  handleCancel: PropTypes.func,
  /** List of user groups */
  groupList: PropTypes.array,
  /** Name of the user for edit */
  userName: PropTypes.string,
  /** ID of the user for edit */
  userId: PropTypes.string,
  /** Selected userGroups */
  userGroups: PropTypes.any,
  /** Boolean to indicates from edit or create */
  isFromEdit: PropTypes.bool,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

EditFolderModal.defaultProps = {
}

export default withApollo(compose(
  // graphql(
  //   QueryTags,
  //   {
  //     options: ({ searchTag }) => {
  //       let filter = { module: { exact: 'CONTENT_BANK' } }
  //       if (searchTag) {
  //         filter.name = { match: searchTag }
  //       }
  //       const variables = {
  //         offset: 0,
  //         limit: 200,
  //         filter
  //       }

  //       return {
  //         fetchPolicy: 'network-only',
  //         variables
  //       }
  //     },
  //     props: ({ data }) => {
  //       const groupList = data.listTags ? data.listTags.items : []
  //       return {
  //         groupList,
  //         isLoading: data.loading || !data.listTags
  //       }
  //     }
  //   }
  // )
  graphql(
    QueryGetContent,
    {
      skip: (props) => {
        return !(props.filterType && props.filterType.length && props.contentName && props.contentName.length && props.visible)
      },
      options: (props) => {
        let variables = { searchKey: props.searchTag, field: props.contentName, limit: 30, offset: 0, project: props.project }
        if (props.contentName === `TAGS`) { variables.module = props.module }
        return {
          variables,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          groupList: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : [],
          isLoading: props.data.loading || !props.data.getContentByField
        }
      }
    }
  )

)(withRouter(EditFolderModal)))
