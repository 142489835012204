import gql from 'graphql-tag'

export default gql(`
    mutation updatePublishingGroup($id: ID! $name: String! $project: String ) {
        updatePublishingGroup(
            input: {
                id: $id
                name: $name
            }
            project: $project
        ) {
            id
            name 
            status
            isDefault
            isEnvironment
            countries
        }
    }`
)
