import gql from 'graphql-tag'

export default gql(`
    mutation batchUpdateBucketItem($bucketItems:[UpdateBucketItemInput] $parentAssetId: ID $module: MODULE_TYPE $project: String) {
        batchUpdateBucketItem (
            input: $bucketItems
            parentAssetId: $parentAssetId
            module: $module
            project: $project
          ) {
                    id
                    position
                    type
                    customTitle
                    customMedia{
                        id
                        url
                        fileName
                        aspectRatio {
                            title
                            aspectRatio
                            resolutions {
                                url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2 
                            y2
                        }
                    }
                    customAssetImage{
                        id
                        mediaId
                        url
                        fileName
                        aspectRatio {
                            title
                            aspectRatio
                            resolutions {
                                url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2 
                            y2
                        }
                    }
                    asset{
                        id
                        title
                        shortId
                        externalId
                        updatedAt
                        status
                        seasonNumber
                        episodeNumber
                        series{
                          id
                          assetId
                          title
                        }
                        season{
                            id
                            assetId
                            title
                        }
                        defaultMedia {
                            id
                            url
                            fileName
                            aspectRatio {
                              title
                              aspectRatio
                              resolutions {
                                url
                              }
                            }
                            settings {
                              aspectRatio
                              outputFormat
                              fileName
                              x1
                              y1
                              x2 
                              y2
                            }
                          }
                    }
                    filterRule{
                        searchKey
                        filters{
                            name
                            fieldName
                            type
                            value
                            displayValue
                            displayName
                            isRequired
                        }
                    }
        }
    }`
)
