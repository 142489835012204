import * as React from 'react'
import PropTypes from 'prop-types'

export class StreamList extends React.Component {
  render () {
    return <div className='stream-list'>
      <div className='stream-list-header'>STREAMS</div>
      <div className='stream-list-container'>
        {this.props.streamList && this.props.streamList.map((dataItem, index) => {
          return <div className='stream-list-item' key={index} onClick={this.onItemClick.bind(this, dataItem.id)}>
            <div className='stream-name'>{`${dataItem.name}`}</div>
            <div className={`${dataItem.isActive ? 'stream-list-active' : 'stream-list-checkbox'}`}><span className='checked' /></div>
          </div>
        })}
      </div>
    </div>
  }

    onItemClick = (id) => {
      if (this.props.itemClicked) { this.props.itemClicked(id) }
    }
}

StreamList.propTypes = {
  streamList: PropTypes.arrayOf(PropTypes.object),
  itemClicked: PropTypes.func
}

StreamList.defaultProps = {
  streamList: [ {
    preview: [ 'HEVC', 'LIVE', 'DASH' ],
    link: [ 'HEVC', 'LIVE', 'HLS' ]
  } ]
}
