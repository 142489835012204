import gql from 'graphql-tag'

export default gql(`
query($id: ID!, $team: ID!, $tournamentCalendar: ID!) {
    getPlayer(id: $id, team: $team, tournamentCalendar: $tournamentCalendar) {
        player{
            id
            externalId
            opId
            firstName
            lastName
            matchName
            lastUpdated
            nationality
            nationalityId
            position
            type
            placeOfBirth
            active
            customFirstName
            customLastName
            customMatchName
            customNationality
            customPosition
            customType
            customActive
            tags{
            key
            name
            type
            }
        }
        awayImage{
            id
            type
            fileName
            createdAt
            updatedAt
            type
            name
            fileSize
            isArchived
            duration
            thumbnails
            tags{
                name
                key
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
            aspectRatio {
                title
                aspectRatio
                ratio
                resolutions {
                    key
                    width
                    height
                    url
                }
            }
            streams{
                id
            }
            vodStatus{
                id
            }
        }
        homeImage{
            id
            type
            fileName
            createdAt
            updatedAt
            type
            name
            fileSize
            isArchived
            duration
            thumbnails
            tags{
                name
                key
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2          
            }
            aspectRatio {
                title
                aspectRatio
                ratio
                resolutions {
                    key
                    width
                    height
                    url
                }
            }
            streams{
                id
            }
            vodStatus{
                id
            }
        }
        shirtNumber
    }    
}`)
