import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import CreatePersonContainer from './CreatePersonContainer'
import { utilityService } from '../../../../services/UtilityService'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryPerson from './../../../../graphQL/admin/entertainmentData/listPerson'
import QueryPersonAsset from './../../../../graphQL/asset/hyperion/listPersonsDetails'
import MutationCreatePerson from './../../../../graphQL/admin/entertainmentData/createPerson'
import MutationUpdatePerson from './../../../../graphQL/admin/entertainmentData/updatePerson'
import MutationUpdateMedia from '../../../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from '../../../../graphQL/content/updateMediaSettings'

class CreatePersonModal extends Component {
  state = {
    personName: '',
    isDisabled: false,
    image: null,
    notEdited: true
  }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedPerson && (!_.isEqual(this.props.selectedPerson, nextProps.selectedPerson) || (!this.state.personName && !this.state.image))) {
        this.setState({
          personName: nextProps.selectedPerson.name,
          isDisabled: nextProps.selectedPerson.isDisabled,
          image: nextProps.selectedPerson && nextProps.selectedPerson.image ? [nextProps.selectedPerson.image] : null,
          notEdited: true
        })
      } else if (!_.isEqual(this.props.selectedImage, nextProps.selectedImage)) {
        this.setState({ image: nextProps.selectedImage, notEdited: false })
      }
      if (nextProps.croppedUrl && !_.isEqual(this.props.croppedUrl, nextProps.croppedUrl)) {
        this.setState({ notEdited: false })
      }

      if (nextProps.isDeleted) {
        const state = {
          personName: '',
          isDisabled: false,
          image: null,
          notEdited: true
        }
        this.setState({ ...state })
      }
    }

    handleTextChange = (event) => {
      this.setState({ personName: event.target.value, notEdited: false })
    }

    handleActiveChange = () => {
      this.setState({ isDisabled: !this.state.isDisabled, notEdited: false })
    }

    submitPerson = () => {
      const { personName } = this.state
      this.setState({ isLoading: true })
      this.props.getUserWithName(personName).then(({ data }) => {
        if (this.props.selectedPerson) {
          // if (data.listPerson && data.listPerson.items && (!data.listPerson.items.length || (data.listPerson.items.length === 1 && data.listPerson.items[0].id === this.props.selectedPerson.id))) {
          this.updatePerson()
          // } else {
          //   message.error('Duplicate Person')
          //   this.setState({ isLoading: false })
          // }
        } else {
          // if (data.listPerson && data.listPerson.items && !data.listPerson.items.length) {
          this.createPerson()
          // } else if (data.listPerson && data.listPerson.items && data.listPerson.items.length) {
          //   message.error('Duplicate Person')
          //   this.setState({ isLoading: false })
          // }
        }
      })
    }

    createPerson = () => {
      const { personName, image } = this.state
      const { isUpdateBlocked, onHistoryUpdate } = this.props
      this.props.createPerson(image ? image[ 0 ].id : null, personName).then(response => {
        if (onHistoryUpdate) {
          onHistoryUpdate()
        }
        if (!response.data.createPerson.image) {
          this.props.handleSubmit(response.data.createPerson)
          this.setState({ personName: '', image: '', isLoading: false })
          return
        }
        const mediaId = response.data.createPerson.image.id
        const name = image[ 0 ].name
        const tags = image[ 0 ].tags
        const personId = response.data.createPerson.id
        const settings = _.cloneDeep(image[ 0 ].settings).map((item) => {
          if (item.__typename) {
            delete (item.__typename)
            delete item.fileName
          }
          return item
        })
        // this.props.updateImageSettings(mediaId, authorId, settings).then(({ data }) => {
        this.props.updateImageSettings(mediaId, personId, settings)
        if (isUpdateBlocked) {
          // response.data.createAuthor.media[0] = data.updateAssetMediaLink
          this.props.handleSubmit(response.data.createPerson)
          // this.props.getUserWithName(authorName)
          this.setState({ personName: '', image: '', isLoading: false })
        } else {
          this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then(({ data }) => {
            if (response.data.createPerson.image) { response.data.createPerson.image = data.updateMedia }
            this.props.handleSubmit(response.data.createPerson)
            // this.props.getUserWithName(authorName)
            this.setState({ personName: '', image: '', isLoading: false })
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }

    updatePerson = () => {
      const { personName, image } = this.state
      const { selectedPerson, isUpdateBlocked, onHistoryUpdate } = this.props
      this.props.updatePerson(selectedPerson.id, image ? image[ 0 ].id : null, personName).then(response => {
        if (!response.data.updatePerson.image) {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          this.props.handleSubmit(response.data.updatePerson)
          this.setState({ personName: '', image: '', isLoading: false })
          return
        }
        const mediaId = response.data.updatePerson.image.id
        const name = image[ 0 ].name
        const tags = image[ 0 ].tags
        const authorId = response.data.updatePerson.id
        const settings = _.cloneDeep(image[ 0 ].settings).map((item) => {
          if (item.__typename) {
            delete (item.__typename)
            delete item.fileName
          }
          return item
        })
        // this.props.updateImageSettings(mediaId, authorId, settings).then(({ data }) => {
        this.props.updateImageSettings(mediaId, authorId, settings)
        if (isUpdateBlocked) {
          this.props.handleSubmit(response.data.updatePerson)
          this.props.getUserWithName(personName)
          this.setState({ authorName: '', image: '', isLoading: false })
        } else {
          this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then(({ data }) => {
            if (response.data.updatePerson.image) { response.data.updatePerson.image = data.updateMedia }
            this.props.handleSubmit(response.data.updatePerson)
            if (onHistoryUpdate) {
              onHistoryUpdate()
            }
            this.props.getUserWithName(personName)
            this.setState({ personName: '', image: '', isLoading: false })
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }

    handleCancel = () => {
      this.props.handleCancel()
      this.setState({
        personName: '',
        image: null,
        isDisabled: false,
        notEdited: true
      })
    }

    render () {
      const { isVisible, showImageModal, showCropModal, croppedUrl, clearSelection, selectedPerson, isUpdateBlocked, isSubmitDisabled, module } = this.props
      const { personName, isLoading, isDisabled, notEdited, image } = this.state

      return (
        <Modal
          className='confirm-person-modal edit-image'
          title={selectedPerson ? 'EDIT PERSON' : 'NEW PERSON'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.submitPerson}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: (!personName || notEdited || isSubmitDisabled) }}
          closable={false}
          centered
          afterClose={this.resetState}
          width='600px'
          confirmLoading={isLoading}
          destroyOnClose
        >
          <CreatePersonContainer
            showCropModal={showCropModal}
            showImageModal={showImageModal}
            selectedImage={image}
            croppedUrl={croppedUrl}
            handleActiveChange={this.handleActiveChange}
            handleTextChange={this.handleTextChange}
            isDisabled={isDisabled}
            personName={personName}
            clearSelection={clearSelection}
            isUpdateBlocked={isUpdateBlocked}
            module={module}
          />
        </Modal>
      )
    }
}

CreatePersonModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Details of selected image */
  selectedImage: PropTypes.array,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Callback to show the AddImageModal */
  showImageModal: PropTypes.func,
  /** Boolean to indicates update media is disabled */
  isUpdateBlocked: PropTypes.bool,
  /** Module name */
  module: PropTypes.string,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool,
  // person: PropTypes.array,
  /** function to clear selection of modal */
  clearSelection: PropTypes.func,
  /** crop url of the image */
  croppedUrl: PropTypes.string

}

CreatePersonModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      QueryPerson,
      {
        options: ({ project }) => {
          return {
            varibales: { filter: null, project }
          }
        },
        props: (props) => {
          const { data: { listPerson = { items: [] } } } = props
          return {
            authors: [ ...listPerson.items ],
            getUserWithName: (name) => {
              return props.data.refetch({ filter:
                {
                  name: { exact: name }
                },
              project: props.ownProps.project
              })
            }
          }
        }
      }
    ),
    graphql(
      MutationCreatePerson, {
        options: ({ project, isFromAsset }) => {
          return {
            update: (cache, { data: { createPerson } }) => {
              try {
                if (isFromAsset) {
                  const variables = { project }
                  const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPersonAsset, variables }))
                  if (cacheData && cacheData.listPerson && cacheData.listPerson.items) { cacheData.listPerson.items.push(createPerson) }
                  cache.writeQuery({
                    query: QueryPersonAsset,
                    data: cacheData,
                    variables
                  })
                } else {
                  const variables = { filter: null, limit: 30, offset: 0, project }
                  const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPerson, variables }))
                  if (cacheData && cacheData.listPerson && cacheData.listPerson.items) { cacheData.listPerson.items.push(createPerson) }
                  cache.writeQuery({
                    query: QueryPerson,
                    data: cacheData,
                    variables
                  })
                }
              } catch (e) {}
            }
          }
        },
        skip: ({ name, id }) => {
          return !!(!id || !name)
        },
        props: (props) => ({
          createPerson: (id, name) => {
            return props.mutate({
              variables: { name, image: id || null, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdatePerson, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { updatePerson } }) => {
              const variables = { filter: null, limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPerson, variables }))
              if (cacheData && cacheData.listPerson && cacheData.listPerson.items) {
                const selectedPersonIndex = cacheData.listPerson.items.findIndex(item => item.id === updatePerson.id)
                if (selectedPersonIndex > -1) {
                  cacheData.listPerson.items[selectedPersonIndex] = updatePerson
                }
              }
              cache.writeQuery({
                query: QueryPerson,
                data: cacheData,
                variables
              })
            }
          }
        },
        skip: ({ name, id }) => {
          return !!(!id || !name)
        },
        props: (props) => ({
          updatePerson: (id, imageId, name) => {
            return props.mutate({
              variables: { id, name, image: imageId || null, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMedia, {
        props: (props) => ({
          updateImage: (id, name, tags, settings) => {
            return props.mutate({
              variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMediaSettings, {
        props: (props) => ({
          updateImageSettings: (id, assetId, settings) => {
            return props.mutate({
              variables: { id, assetId, settings }
            })
          }
        })
      }
    )
  )(CreatePersonModal))
