import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateSportsModal from '../../../components/ui/dataEntry/other/CreateSportsModal'
import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListSports from '../../../graphQL/admin/sports/listSports'
import QueryListMasterFields from '../../../graphQL/admin/sports/listMasterFields'
import MutationCreateSports from '../../../graphQL/admin/sports/createSport'
import MutationUpdateSports from '../../../graphQL/admin/sports/updateSport'

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

const sportsDetails = [
  {
    label: 'Competition',
    id: 'competitionFields'
  },
  {
    label: 'Tournament',
    id: 'tournamentFields'
  },
  {
    label: 'Team',
    id: 'teamFields'
  },
  {
    label: 'Match',
    id: 'matchFields'
  },
  {
    label: 'Player',
    id: 'playerFields'
  },
  {
    label: 'Stage',
    id: 'stageFields'
  }
]
class Sports extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedSports: null,
      showAddSportsModal: false,
      selectedSport: undefined,
      selectedRowKeys: [],
      isSelected: false,
      isSportsLoading: false
    }

    this.sportsColumns = [
      {
        title: 'Sports',
        dataIndex: 'title',
        key: 'title',
        width: '97%'
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
    this.metaDataColumns = [
      {
        title: 'Meta Data Field',
        dataIndex: 'displayName',
        key: 'displayName',
        width: '30%'
      },
      {
        title: 'Field Type',
        dataIndex: 'type',
        key: 'type',
        width: '30%'
      },
      {
        title: 'Required',
        dataIndex: 'isRequired',
        key: 'isRequired',
        width: '40%',
        render: (isRequired) => <div className='inner-header'>{isRequired ? <DoneIcon /> : <CrossIcon color={'#f5212d'} /> }</div>
      }
    ]
    this.innerColumns = [
      {
        title: 'General Meta Data',
        dataIndex: 'title',
        key: 'title',
        width: '90%',
        render: (title) => <div className='inner-header'>{title}</div>
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedSports) {
        this.setState({ openedSports: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedSports === id) {
        this.setState({ openedSports: null })
      } else {
        this.setState({ openedSports: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { listSports } = this.props
      const selectedSport = listSports.find(item => item.id === id)
      if (!selectedSport) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddSportsModal: true, selectedSport })
      }
      this.setState({ openedSports: null })
    }

    showAddSportsModal = () => {
      this.setState({ showAddSportsModal: true })
    }

    hideAddSportsModal = () => {
      this.setState({ showAddSportsModal: false, selectedSport: null })
    }

    handleAddSports = (updatedSport) => {
      const { selectedSport } = this.state
      const { onHistoryUpdate } = this.props
      this.setState({ isSportsLoading: true })
      if (selectedSport) {
        this.props.updateSport(updatedSport).then(() => {
          onHistoryUpdate()
          this.setState({ showAddSportsModal: false, selectedSport: null, isSportsLoading: false })
          message.success('Sport updated successfully')
        }, (error) => {
          utilityService.handleError(error)
          this.setState({ showAddSportsModal: false, selectedSport: null, isSportsLoading: false })
        })
      } else {
        this.props.createSports(updatedSport).then(() => {
          onHistoryUpdate()
          this.setState({ showAddSportsModal: false, selectedSport: null, isSportsLoading: false })
          message.success('New sport added successfully')
        }, (error) => {
          utilityService.handleError(error)
          this.setState({ showAddSportsModal: false, selectedSport: null, isSportsLoading: false })
        })
      }
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    loadMoreSports = () => {
      if (!this.props.nextToken || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreSports(this.props.nextToken).then(() => {
        setTimeout(() => {
          this.setState({ isPaginating: false })
        }, 200)
      }))
    }

    renderInnerRows = (data) => {
      return sportsDetails.map(sportsItem => {
        return <Table
          key={sportsItem.id}
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={this.innerColumns}
          dataSource={[{ title: sportsItem.label }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.renderMetaData(data[sportsItem.id])}
        />
      })
    }

      renderMetaData = (data) => {
        return <Table
          className={`inner-table`}
          rowKey={record => record.id}
          columns={this.metaDataColumns}
          dataSource={data}
          pagination={false}
        />
      }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedSports } = this.state
      return (
        <div className='option-list' id='option-list'>
          {availableOptions && availableOptions.length
            ? <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedSports === id}
              placement={'leftBottom'}
            />
            : ''}
        </div>
      )
    }

    render () {
      const { isPaginating, showAddSportsModal, selectedSport, selectedRowKeys, isSelected, isSportsLoading } = this.state
      const { listSports, nextToken, isLoading, listMasterFields } = this.props
      const columnData = this.renderInnerRows

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
      }

      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal sports asset-type-container'>
              <div className='admin-list'>
                <FixedTableHeader columnCellList={this.sportsColumns} isExpandable />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreSports}
                  hasMore={!!nextToken && !isPaginating}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`}
                      rowKey={record => record.id}
                      columns={this.sportsColumns}
                      dataSource={listSports}
                      pagination={false}
                      expandedRowRender={columnData}
                      rowSelection={isSelected ? rowSelection : undefined}
                    />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddSportsModal}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                /> : null}
              </div>
              <CreateSportsModal
                isVisible={showAddSportsModal}
                handleCancel={this.hideAddSportsModal}
                handleSubmit={this.handleAddSports}
                selectedSport={_.cloneDeep(selectedSport)}
                reorder={this.reorder}
                isSubmitDisabled={_.isEmpty(selectedSport) ? isCreateDisabled : isUpdateDisabled}
                sportsDetails={sportsDetails}
                isLoading={isSportsLoading}
                listMasterFields={listMasterFields}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

Sports.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListSports,
    {
      options: (props) => {
        const nextToken = props.nextToken ? props.nextToken : null
        return {
          variables: { limit: 999, nextToken },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let listSports = data.listSports ? [ ...data.listSports.items ] : []
        const nextToken = data.listSports ? data.listSports.nextToken : null
        const previousToken = data.listSports ? data.listSports.previousToken : null

        return {
          listSports,
          isLoading: data.loading,
          nextToken,
          previousToken,
          getMoreSports: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: nextToken
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newSportsList = fetchMoreResult.listSports ? [ ...fetchMoreResult.listSports.items ] : []
                let newList = [ ...prev.listSports.items, ...newSportsList ]
                newList = _.uniqBy(newList, 'id')
                if (_.isEqual(newList, prev.listSports.items)) { return prev }
                prev.listSports.items = newList
                prev.listSports.previousToken = prev.listSports.nextToken
                prev.listSports.nextToken = fetchMoreResult.listSports.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    QueryListMasterFields,
    {
      props: (props) => {
        const { data } = props
        let listMasterFields = data.listMasterFields ? [ ...data.listMasterFields ] : []

        return {
          listMasterFields
        }
      }
    }
  ),
  graphql(
    MutationCreateSports,
    {
      options: ({ previousToken }) => {
        return {
          update: (cache, { data: { createSport } }) => {
            const variables = { limit: 20, nextToken: previousToken || null }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListSports, variables }))
            if (cacheData && cacheData.listSports && cacheData.listSports.items) {
              cacheData.listSports.items.unshift(createSport)
            }
            cache.writeQuery({
              query: QueryListSports,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        createSports: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateSports, {
      options: ({ previousToken }) => {
        return {
          update: (cache, { data: { updateSport } }) => {
            const variables = { limit: 20, nextToken: previousToken || null }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListSports, variables }))
            if (cacheData && cacheData.listSports && cacheData.listSports.items) {
              const tempIndex = cacheData.listSports.items.findIndex(item => item.id === updateSport.id)
              cacheData.listSports.items[tempIndex] = updateSport
            }
            cache.writeQuery({
              query: QueryListSports,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        updateSport: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(Sports))
