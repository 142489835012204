import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'
// import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateChannelStreamModal from '../../../components/ui/dataEntry/other/CreateChannelStreamModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import userMessages from '../../../constants/messages'
// import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListChannelStreams from '../../../graphQL/admin/channel/listStreamLevels'
import MutationBatchDeleteChannelStream from '../../../graphQL/admin/channel/batchDeleteStreamLevel'

const deleteChannelStreamMessage = {
  title: 'DELETE CHANNEL STREAM',
  firstMessage: 'Are you sure you want to delete this Channel Stream?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  },
  {
    type: 'duplicate',
    name: 'Duplicate'
  },
  {
    type: 'delete',
    name: 'Delete'
  }
]
class ChannelStreams extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedStream: null,
      isLoading: !(props.channelStreamList && props.channelStreamList.length),
      isDuplicate: false,
      showAddChannelStreamModal: false,
      showTransferContentPlaceholderModal: false,
      showDeleteChannelStreamModal: false,
      selectedStream: undefined,
      selectedRowKeys: [],
      isSelected: false,
      isMultipleDelete: false,
      isDeleteLoading: false
    }
    this.columns = [
      {
        title: 'Channel Streams',
        dataIndex: 'name',
        key: 'name',
        width: '37%'
      },
      {
        title: 'Group',
        dataIndex: 'group',
        width: '60%',
        key: 'used'
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.channelStreamList, this.props.channelStreamList)) {
        this.setState({ isLoading: false })
      }
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'duplicate', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedStream) {
        this.setState({ openedStream: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedStream === id) {
        this.setState({ openedStream: null })
      } else {
        this.setState({ openedStream: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { channelStreamList } = this.props
      const selectedStream = channelStreamList.find(item => item.id === id)
      if (!selectedStream) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddChannelStreamModal: true, selectedStream })
      } else if (selectedOption.type === 'duplicate') {
        this.setState({ showAddChannelStreamModal: true, selectedStream, isDuplicate: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteChannelStreamModal: true, selectedStream })
      }
      this.setState({ openedStream: null })
    }

    showAddChannelStream = () => {
      this.setState({ showAddChannelStreamModal: true })
    }

    hideAddChannelStreamModal = () => {
      this.setState({ showAddChannelStreamModal: false, selectedStream: null, isDuplicate: false })
    }

    handleAddChannelStream = () => {
      this.setState({ showAddChannelStreamModal: false, selectedStream: null, isDuplicate: false })
    }

    hideDeleteChannelStreamModal = () => {
      this.setState({ showDeleteChannelStreamModal: false, selectedStream: null })
    }

    handleDeleteChannelStream = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const { selectedStream } = this.state
      const variable = [{ id: selectedStream.id }]
      this.props.deleteChannelStream(variable).then(() => {
        onHistoryUpdate()
        this.setState({ showDeleteChannelStreamModal: false, isDeleteLoading: false })
      }, error => {
        this.setState({ showDeleteChannelStreamModal: false, isDeleteLoading: false })
        utilityService.handleError(error)
      })
      this.setState({ showDeleteChannelStreamModal: false })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    handleMultipleDeleteChannelStream = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const { selectedRowKeys } = this.state
      const variable = (selectedRowKeys || []).map(id => ({ id }))
      this.props.deleteChannelStream(variable).then(() => {
        onHistoryUpdate()
        this.setState({ isDeleteLoading: false, isMultipleDelete: false, selectedRowKeys: [], isSelected: false })
      }, error => {
        this.setState({ isDeleteLoading: false, isMultipleDelete: false, selectedRowKeys: [], isSelected: false })
        utilityService.handleError(error)
      })
    }

    hideMultipleDeleteChannelStream = () => {
      this.setState({ isMultipleDelete: false })
    }

    // loadMoreAsset = () => {
    //   if (this.props.totalCount === this.props.placeholderList.length || this.state.isPaginating) { return }
    //   this.setState({ isPaginating: true }, () => this.props.getMoreCategories(this.props.placeholderList.length))
    // }

    getRenderedColumn = data => {
      const columns = [
        {
          title: 'Field Object',
          dataIndex: 'name',
          key: 'name',
          width: '20%'
        },

        {
          title: 'Field Type',
          dataIndex: 'type',
          key: 'type',
          width: '20%'
        },
        {
          title: 'Required',
          key: 'required',
          dataIndex: 'isRequired',
          render: isRequired => (
            <span>
              {isRequired === true ? <DoneIcon /> : <CrossIcon color={'#f5212d'} />}
            </span>
          )
        }
      ]

      const field = _.cloneDeep(data.fields)
      return (
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={field}
          pagination={false}
        />
      )
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      if (this.isDeleteDisabled) {
        availableOptions.splice(2, 1)
      }
      if (this.isCreateDisabled) {
        availableOptions.splice(1, 1)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      const { openedStream } = this.state
      return (
        <div className='option-list' id='option-list'>
          {availableOptions && availableOptions.length
            ? <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedStream === id}
              placement={'leftBottom'}
            />
            : ''}
        </div>
      )
    }

    render () {
      const { isPaginating, showAddChannelStreamModal, showDeleteChannelStreamModal, selectedStream, isMultipleDelete, selectedRowKeys, isSelected, isDuplicate, isLoading, isDeleteLoading } = this.state
      const { channelStreamList, onHistoryUpdate } = this.props
      const tempSelectedStream = _.cloneDeep(selectedStream)
      if (!_.isEmpty(tempSelectedStream)) { delete (tempSelectedStream.name) }
      const columnData = this.getRenderedColumn
      let adminItem
      if (selectedStream && showDeleteChannelStreamModal) {
        adminItem = <div >
          <p>{ selectedStream.name } </p>
        </div>
      }

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
      }

      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['DATA_MANAGER']
            this.isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal channel-stream'>
              {/* <FilterInput
              searchString={''}
              onChangeSearchInput={() => {}}
              // filterType={'AssetManager'}
              // changeFilter={(value) => {
              //   this.setState({ isSearching: true, filterData: value }, () => {
              //     this.changeFilterValue(value)
              //   })
              // }}
              placement='rightTop'
              // isClearFilter={isClearFilter}
              // initialSearch={initialSearch}
              // username={this.username}
              // bucketAssetFilter={filterData}
            /> */}
              <div className='admin-list'>
                {/* <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > placeholderList.length}
                  initialLoad={false}
                  useWindow={false}
                > */}
                <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                  <Table className={`general-expand-table ${isPaginating ? 'paginating' : ''}`}
                    rowKey={record => record.id}
                    columns={this.columns}
                    dataSource={channelStreamList}
                    pagination={false}
                    expandedRowRender={columnData}
                    rowSelection={isSelected ? rowSelection : undefined}
                  />
                </Skeleton>
                <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                {/* </InfiniteScroll> */}
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {!isSelected
                    ? <MultipleSelectButton onClick={this.onMultipleSelect} isDisabled={this.isDeleteDisabled} />
                    : <React.Fragment>
                      <BackArrowButton onClick={this.onMultipleDSelect} />
                      <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                    </React.Fragment>
                  }
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddChannelStream}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={this.isCreateDisabled}
                /> : null}
              </div>
              <CreateChannelStreamModal
                isVisible={showAddChannelStreamModal}
                handleCancel={this.hideAddChannelStreamModal}
                handleSubmit={this.handleAddChannelStream}
                selectedStream={selectedStream && isDuplicate ? tempSelectedStream : selectedStream}
                reorder={this.reorder}
                isDuplicate={isDuplicate}
                isSubmitDisabled={_.isEmpty(selectedStream) ? this.isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteChannelStreamModal}
                title={deleteChannelStreamMessage.title}
                firstMessage={deleteChannelStreamMessage.firstMessage}
                secondMessage={deleteChannelStreamMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteChannelStreamModal}
                handleSubmit={this.handleDeleteChannelStream}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={isDeleteLoading}
              />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'DELETE MULTIPLE CHANNEL STREAMS'}
                message={userMessages.DELETE_MULTIPLE_CHANNEL_STREAMS}
                isLoading={isDeleteLoading}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeleteChannelStream}
                handleCancel={this.hideMultipleDeleteChannelStream}
                isSubmitButtonDisabled={this.isDeleteDisabled}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

ChannelStreams.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListChannelStreams,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let channelStreamList = data.listStreamLevels ? [ ...data.listStreamLevels.items ] : []
        return {
          channelStreamList,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationBatchDeleteChannelStream,
    {
      options: {
        update: (cache, { data: { batchDeleteStreamLevel } }) => {
          try {
            const variables = { filter: null, limit: 20 }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListChannelStreams, variables }))
            if (cacheData && cacheData.listStreamLevels && cacheData.listStreamLevels.items) {
              cacheData.listStreamLevels.items = cacheData.listStreamLevels.items.filter(contentItem => {
                const index = batchDeleteStreamLevel.findIndex(deletedContent => deletedContent.id === contentItem.id)
                return index === -1
              })
            }
            cache.writeQuery({
              query: QueryListChannelStreams,
              data: cacheData,
              variables
            })
          } catch (e) {
            console.log(e)
          }
        }
      },
      props: (props) => ({
        deleteChannelStream: (variables) => {
          return props.mutate({
            variables: { deleteInput: variables }
          })
        }
      })
    }
  )

)(ChannelStreams))
