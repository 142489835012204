import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import PropTypes from 'prop-types'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateImageCategoryModal from '../../../components/ui/dataEntry/other/CreateImageCategoryModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import userMessages from '../../../constants/messages'
import { utilityService } from '../../../services/UtilityService'
import QueryListImageCategory from '../../../graphQL/admin/media/listMediaCategories'
import MutationcreateImageCategory from '../../../graphQL/admin/media/createMediaCategory'
import MutationBatchdeleteImageCategory from '../../../graphQL/admin/media/deleteMediaCategories'
import MutationupdateImageCategory from '../../../graphQL/admin/media/updateMediaCategory'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

const deleteImageCategoryMessage = {
  title: 'DELETE Media Category',
  firstMessage: 'Are you sure you want to delete this Media Category?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]
class ImageCategoryList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedCategory: null,
      isLoading: true,
      showAddImageCategoryModal: false,
      showDeleteImageCategoryModal: false,
      selectedCategory: undefined,
      selectedRowKeys: [],
      isCreateLoading: false,
      isSelected: false,
      isMultipleDelete: false,
      isDeleteLoading: false,
      isDeleted: false
    }
    this.columns = [
      {
        title: 'Media Category',
        dataIndex: 'label',
        key: 'label',
        width: '44%'
      },
      {
        title: 'Media Type',
        dataIndex: 'type',
        key: 'type',
        width: '28%'
      }, {
        title: 'Active',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '25%',
        render: isActive => (
          <span>
            {isActive === true ? <DoneIcon /> : <CrossIcon color={'#f5212d'} /> }
          </span>
        )
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id, data) => this.renderData(data)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.imageCategoryList, this.props.imageCategoryList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedCategory) {
        this.setState({ openedCategory: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedCategory === id) {
        this.setState({ openedCategory: null })
      } else {
        this.setState({ openedCategory: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { imageCategoryList } = this.props
      const selectedCategory = imageCategoryList.find(item => item.id === id)
      if (!selectedCategory) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddImageCategoryModal: true, selectedCategory })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteImageCategoryModal: true, selectedCategory })
      }
      this.setState({ openedCategory: null })
    }

    showAddImageCategory = () => {
      this.setState({ showAddImageCategoryModal: true })
    }

    hideAddImageCategoryModal = () => {
      this.setState({ showAddImageCategoryModal: false, selectedCategory: null })
    }

    handleAddImageCategory = (imageCategoryDetails, id) => {
      const { onHistoryUpdate, project } = this.props
      this.setState({ isCreateLoading: true })
      if (!id) {
        imageCategoryDetails.project = project
        this.props.createMediaCategory(imageCategoryDetails).then(() => {
          onHistoryUpdate()
          message.success('Media category created successfully')
          this.setState({ showAddImageCategoryModal: false, selectedCategory: null, isCreateLoading: false })
        }, error => {
          this.setState({ showAddImageCategoryModal: false, selectedCategory: null, isCreateLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const variables = {
          id,
          label: imageCategoryDetails.label,
          type: imageCategoryDetails.type,
          isActive: imageCategoryDetails.isActive,
          project: project
        }
        this.props.updateMediaCategory(variables).then(() => {
          onHistoryUpdate()
          message.success('Media category updated successfully')
          this.setState({ showAddImageCategoryModal: false, selectedCategory: null, isCreateLoading: false })
        }, error => {
          this.setState({ showAddImageCategoryModal: false, selectedCategory: null, isCreateLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    hideDeleteImageCategoryModal = () => {
      this.setState({ isDeleted: true, selectedCategory: null }, () => {
        this.setState({ showDeleteImageCategoryModal: false, isDeleted: false })
      })
    }

    handleDeleteImageCategory = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const { selectedCategory } = this.state
      const variable = [ selectedCategory.id ]
      this.props.deleteMediaCategories(variable).then(() => {
        this.setState({ isDeleted: true, selectedCategory: null }, () => {
          this.setState({ showDeleteImageCategoryModal: false, isDeleteLoading: false, isDeleted: false })
        })
        message.success('Media category deleted successfully')
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedCategory: null }, () => {
          this.setState({ showDeleteImageCategoryModal: false, isDeleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    handleMultipleDeleteImageCategory = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const { selectedRowKeys } = this.state
      const variable = (selectedRowKeys || []).map(id => ({ id }))
      this.props.deleteMediaCategories(variable).then(() => {
        message.success('Media category deleted successfully')
        onHistoryUpdate()
        this.setState({ showDeleteImageCategoryModal: false, isDeleteLoading: false, isMultipleDelete: false, selectedRowKeys: [], isSelected: false })
      }, error => {
        this.setState({ showDeleteImageCategoryModal: false, isDeleteLoading: false, isMultipleDelete: false, selectedRowKeys: [], isSelected: false })
        utilityService.handleError(error)
      })
    }

    hideMultipleDeleteImageCategory = () => {
      this.setState({ isMultipleDelete: false })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true, openedCategory: null })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangeContentFilter(value)
    }

    loadMoreAsset = () => {
      if (!this.props.nextToken || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreCategories(this.props.nextToken))
    }

    renderData = ({ id }) => {
      let availableOptions = _.cloneDeep(options)
      const { openedCategory, isSelected } = this.state
      const tempId = utilityService.makeRandomString(6)
      // if (!usageCount && !this.isDeleteDisabled) {
      //   const deleteOption = {
      //     type: 'delete',
      //     name: 'Delete'
      //   }
      //   availableOptions.push(deleteOption)
      // }
      if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className={`option-list${isSelected ? ' hide' : ''}`} id={tempId}>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={tempId}
              isVisible={openedCategory === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { imageCategoryList, nextToken, isLoading, project } = this.props
      const { isPaginating, showAddImageCategoryModal, showDeleteImageCategoryModal, selectedCategory, isMultipleDelete, selectedRowKeys,
        isSelected, isCreateLoading, isDeleteLoading, isDeleted } = this.state
      // let adminItem
      // if (selectedCategory && showDeleteImageCategoryModal) {
      //   adminItem = <div >
      //     <p>{ selectedCategory.name } is being used {selectedCategory.used || '0'} times.</p>
      //   </div>
      // }
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false,
        getCheckboxProps: record => ({
          disabled: record.usageCount !== 0
        })
      }

      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal placeholder-list'>
              <div className='admin-list placeholder-data'>
                <FixedTableHeader columnCellList={this.columns} isExpandable={isSelected} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={nextToken}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading && imageCategoryList && !imageCategoryList.length}>
                    <Table
                      className={`general-table ${isPaginating ? 'paginating' : ''}`}
                      rowKey={record => record.id}
                      columns={this.columns}
                      dataSource={imageCategoryList}
                      pagination={false}
                      rowSelection={isSelected ? rowSelection : undefined} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {/* {!isSelected
                    ? <MultipleSelectButton onClick={this.onMultipleSelect} isDisabled={this.isDeleteDisabled} />
                    : <React.Fragment>
                      <BackArrowButton onClick={this.onMultipleDSelect} />
                      <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                    </React.Fragment>
                  } */}
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddImageCategory}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                /> : null}
              </div>
              <CreateImageCategoryModal
                isVisible={showAddImageCategoryModal}
                handleCancel={this.hideAddImageCategoryModal}
                handleSubmit={this.handleAddImageCategory}
                selectedCategory={selectedCategory}
                isLoading={isCreateLoading}
                isSubmitDisabled={_.isEmpty(selectedCategory) ? isCreateDisabled : this.isUpdateDisabled}
                imageCategoryList={imageCategoryList}
                isDeleted={isDeleted}
                project={project}
              />
              {/* <CreateImageCategoryModal isVisible={showEditContentPlaceholderModal} handleCancel={this.hideEditContentPlaceholderModal} handleSubmit={this.handleEditContentPlaceholder} title='EDIT CONTENT PLACEHOLDER' /> */}
              <AdminItemConfirmModal
                isVisible={showDeleteImageCategoryModal}
                title={deleteImageCategoryMessage.title}
                firstMessage={deleteImageCategoryMessage.firstMessage}
                secondMessage={deleteImageCategoryMessage.secondMessage}
                itemName={selectedCategory ? selectedCategory.label : ''}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteImageCategoryModal}
                handleSubmit={this.handleDeleteImageCategory}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={isDeleteLoading}
              />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'MULTIPLE DELETE Media Category'}
                message={userMessages.DELETE_MULTIPLE_CONTENT_PLACEHOLDER}
                isLoading={isDeleteLoading}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeleteImageCategory}
                handleCancel={this.hideMultipleDeleteImageCategory}
                isSubmitButtonDisabled={this.isDeleteDisabled}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

ImageCategoryList.propTypes = {
  /** Function to be called to refresh audit history */
  onHistoryUpdate: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryListImageCategory,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          project
        }
      },
      props: (props) => {
        return {
          imageCategoryList: props.data.listMediaCategories && props.data.listMediaCategories.length ? props.data.listMediaCategories : [],
          isLoading: props.data.loading
        }
      }
    }
  ),
  graphql(
    MutationcreateImageCategory,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { createMediaCategory } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListImageCategory, variables: { project } }))
              if (cacheData && cacheData.listMediaCategories && cacheData.listMediaCategories) {
                cacheData.listMediaCategories.splice(0, 0, createMediaCategory)
              }
              cache.writeQuery({
                query: QueryListImageCategory,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        createMediaCategory: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationupdateImageCategory,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { updateMediaCategory } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListImageCategory, variables: { project } }))
              if (cacheData && cacheData.listMediaCategories && cacheData.listMediaCategories) {
                const index = cacheData.listMediaCategories.findIndex((item) => item.id === updateMediaCategory.id)
                cacheData.listMediaCategories[index] = updateMediaCategory
              }
              cache.writeQuery({
                query: QueryListImageCategory,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        updateMediaCategory: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchdeleteImageCategory,
    {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deleteMediaCategories } }) => {
            try {
              // const filter = searchString ? { name: { contains: searchString } } : null
              // const variables = { limit: 20, nextToken, filter }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListImageCategory, variables: { project } }))
              if (cacheData && cacheData.listMediaCategories && cacheData.listMediaCategories) {
                cacheData.listMediaCategories = cacheData.listMediaCategories.filter(categoryItem => {
                  const index = deleteMediaCategories.findIndex(deletedContent => deletedContent === categoryItem.id)
                  return index === -1
                })
              }
              cache.writeQuery({
                query: QueryListImageCategory,
                data: cacheData,
                variables: { project }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        deleteMediaCategories: (variables) => {
          return props.mutate({
            variables: { id: variables, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(ImageCategoryList))
