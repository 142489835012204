import gql from 'graphql-tag'

export default gql(`
    mutation createAudit( $contentId: ID $project: String! $updatedObj: [CreateFieldInput] $contentName: String) {
        createAudit(
            input: {
                action: UPDATE,
                contentId: $contentId,
                module: ASSET_MANAGER,
                project: $project,
                contentName: $contentName,
                contentType: VIDEO,
                updatedObj: $updatedObj
            }
        ) {
            id
            contentType
            contentName
            action
            updatedAt
            updatedBy
            updatedObj{
                isPosChange
                name
                value
                oldValue
                fieldName
            }
        }
    }`
)
