import React from 'react'
import PropTypes from 'prop-types'

const RestoreIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 18 23'>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M12.296 0l.042 2.434H2.422c-.35 0-.659.142-.904.356-.025.022-.056.035-.08.057-.023.023-.035.054-.056.079-.218.24-.363.539-.364.88L1 8.592c-.003.76.643 1.372 1.45 1.372.807.001 1.461-.612 1.464-1.372l.01-3.193h8.47l.046 2.395 3.278-2.01L19 3.77l-3.352-1.885L12.296 0zm5.254 10.036c-.807-.001-1.461.612-1.464 1.372l-.01 3.193h-8.47l-.046-2.395-3.278 2.01L1 16.23l3.352 1.885L7.704 20l-.042-2.434h9.916c.35 0 .659-.142.904-.356.025-.022.056-.035.08-.057.023-.023.035-.054.056-.079.218-.24.363-.539.364-.88L19 11.408c.003-.76-.643-1.372-1.45-1.372z' />
    </g>
  </svg>

)

RestoreIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

RestoreIcon.defaultProps = {
  width: 20,
  height: 20
}

export default RestoreIcon
