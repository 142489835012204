import React from 'react'
import PropTypes from 'prop-types'

const TrimIcon = ({ width, height, color, onClick, className, ...props }) => (
  <svg className='trim-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M11.028 7.779L7.97 5.477v9.046l3.058-2.302L13.985 10l-2.957-2.221zm0 0L7.97 5.477v9.046l3.058-2.302L13.985 10l-2.957-2.221zm0 0L7.97 5.477v9.046l3.058-2.302L13.985 10l-2.957-2.221zM8.972 2.03V0A9.96 9.96 0 0 0 3.64 2.221l1.424 1.437a7.95 7.95 0 0 1 3.91-1.628zM3.65 5.075L2.216 3.648A10.012 10.012 0 0 0 0 8.995h2.025a7.992 7.992 0 0 1 1.624-3.92zm-1.624 5.93H0c.2 2.02 1.003 3.86 2.216 5.347l1.433-1.437a7.918 7.918 0 0 1-1.624-3.91zm1.614 6.774A9.993 9.993 0 0 0 8.972 20v-2.03a7.95 7.95 0 0 1-3.91-1.628L3.64 17.779zM20 10c0 5.186-3.93 9.467-8.972 10v-2.03A8.049 8.049 0 0 0 17.995 10a8.049 8.049 0 0 0-6.967-7.97V0C16.07.533 20 4.814 20 10z' />
    </g>
  </svg>
)

TrimIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TrimIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default TrimIcon
