import gql from 'graphql-tag'

export default gql(`
mutation createFolder($name: String!, $tags: [String] $project: String){
    createFolder(input: {
        name: $name
        tags: $tags
        project: $project
    }){
        id
        name
        tags{
            key
            name
            type
            module
            assetCount
            mediaCount
            isTransferring
        }
        position
        createdAt
    }
}
`)
