import gql from 'graphql-tag'

export default gql(`
    mutation($type:String! $title: String! $createdBy: String $project: String) {
        createAsset(
            input: {
                type: $type
                title: $title
                createdBy: $createdBy
                project: $project
            }
        ) {
            id
            isArchived
            shortId
            externalId
            title
            type
            updatedAt
            publishStartDate
            publishEndDate
            autoPublish
            status
            isFree
            seasonNumber
            episodeNumber
            createdAt
            series{
                id
                assetId
                title
              }
              season{
                  id
                  assetId
                  title
              }
            defaultMedia {
                id
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                    url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
            }
        }
    }`
)
