import React from 'react'

class CircularProgressBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      position: -90
    }
    this.setInterval = setInterval(this.updateLoadingPostion, 1)
  }

    updateLoadingPostion = () => {
      let { position } = this.state
      if (position === 180) {
        position = -180
      } else {
        position++
      }
      this.setState({ position })
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.percentage) {
        clearInterval(this.setInterval)
      }
    }

    componentWillUnmount = () => {
      if (this.setInterval) {
        clearInterval(this.setInterval)
      }
    }

    render () {
      // Size of the enclosing square
      const sqSize = this.props.sqSize
      // SVG centers the stroke width on the radius, subtract out so circle fits in square
      const radius = (this.props.sqSize - this.props.strokeWidth) / 2
      // Enclose cicle in a circumscribing square
      const viewBox = `0 0 ${sqSize} ${sqSize}`
      // Arc length at 100% coverage is the circle circumference
      const dashArray = radius * Math.PI * 2
      // Scale 100% coverage overlay with the actual percent
      const dashOffset = dashArray - dashArray * this.props.percentage / 100
      const dashOffsetProcess = dashArray - dashArray * 20 / 100

      return (
        <svg
          className='circular-progress-bar'
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}>
          <circle
            className='circle-background'
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`} />
          {this.props.percentage ? <circle
            className='circle-progress'
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }} /> : null}
          {!this.props.percentage ? <circle
            className='circle-process'
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            transform={`rotate(${this.state.position} ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffsetProcess
            }} /> : null }
          {!this.props.percentage ? <circle
            className='circle-process'
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            transform={`rotate(${this.state.position + 180} ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffsetProcess
            }} /> : null }
          <text
            className='circle-text'
            x='50%'
            y='53%'
            dy='.3em'
            textAnchor='middle'>
            {`${this.props.percentage}%`}
          </text>
        </svg>
      )
    }
}

CircularProgressBar.defaultProps = {
  sqSize: 200,
  percentage: 25,
  strokeWidth: 10
}

export default CircularProgressBar
