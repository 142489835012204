import { useState, useDebugValue, useReducer } from 'react'

function useStateWithLabel (initialValue, name) {
  const [value, setValue] = useState(initialValue)
  useDebugValue(`${name}: ${JSON.stringify(value)}`)
  return [value, setValue]
}

function mergeStateData (initialState) {
  return useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  )
}

export const hookUtilityService = {
  useStateWithLabel,
  mergeStateData
}
