import React from 'react'
import ResetPasswordForm from '../../components/ui/dataEntry/forms/ResetPasswordForm'
import './Auth.scss'

class ChangePassword extends React.Component {
  render () {
    const { onSuccess, onCancel, isChangePassword, isFormSuccess } = this.props
    return (
      <div className='forgot'>
        <div className='login-form-align'>
          <ResetPasswordForm onSuccess={onSuccess} onCancel={onCancel} isChangePassword={isChangePassword} formSuccess={isFormSuccess} />
        </div>
      </div>
    )
  }
}

export default ChangePassword
