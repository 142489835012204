import gql from 'graphql-tag'

export default gql(`
query($contentKey: String $url: String! $streamType: String! $iv: String $drmEntitlementMessage: String) {
    getDrmContentLicense(input:{
        contentKey: $contentKey
        url: $url
        streamType: $streamType
        iv: $iv
        drmEntitlementMessage: $drmEntitlementMessage
    }) {
        url
        license{
            uri
            name 
            drmData
        }
    }
}`)
