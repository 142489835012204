import gql from 'graphql-tag'

export default gql(`
  query ($filter: TableFilterInput! $project: String){
    listFilters(
        filter: $filter
        limit: 100
        project: $project
      ) {
        items{
            id
            name
            fields{
                displayName
                name
                type
                value
                fieldName
                displayValue
            }
        }
    }
  }
  `
)
