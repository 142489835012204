import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import PopoverConfirm from './../feedback/PopoverConfirm'
import { Switch, Tooltip } from 'antd'

import { isEmpty } from 'lodash'
import InputCustom from './../../../components/ui/dataEntry/inputs/InputCustom'
import InputNumber from './../../../components/ui/dataEntry/inputs/InputNumber'
import MetaDefaultDropDown from './../../../components/ui/dataEntry/inputs/MetaContentPlaceholderStringInput'
import InformationIcon from '../../../components/ui/general/icons/InformationIcon'

// import InformationIcon from '../../../components/ui/general/icons/InformationIcon'

const parentCompoent = 'asset-container'
class SummaryContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      details: undefined,
      searchTag: '',
      seriesSearchString: '',
      seasonSearchString: ''
    }
  }

  getAssetMetaFields = (metaData, currentPlaceholder, contentId) => {
    const { onContentPlaceholderChange: handleMetaDetailsChange } = this.props
    let metaFields = metaData.metaFields
    return (metaFields || []).map((meta, index) => {
      if (meta.section && (meta.section !== 'null' || meta.section !== null)) return null
      const addedContenePlaceholderMeta = (currentPlaceholder.metaFields || []).find(item => (item.fieldID === meta.id || item.id === meta.fieldID || item.id === meta.id || item.fieldID === meta.fieldID) && item.type === meta.type)
      if (addedContenePlaceholderMeta) {
        meta.displayTitle = addedContenePlaceholderMeta.displayTitle
        meta.tooltip = addedContenePlaceholderMeta.tooltip
      }
      if (meta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{meta.displayTitle || meta.displayName}</span>
            {
              meta.tooltip &&
              <Tooltip placement='right' title={meta.tooltip} >
                <span className='asset-info-icon-boolean'><InformationIcon /></span>
              </Tooltip>
            }
            <Switch checked={meta ? (meta.value === 'false' ? false : meta.value) : false} onChange={(value) => handleMetaDetailsChange(contentId, value, meta)} />
          </div>
        </div>
      } else if (meta.type === 'STRING') {
        if (meta.values && !isEmpty(meta.values)) {
          return <div className='input-field'>
            <label className='input-title'>{meta.displayTitle || meta.displayName} {meta.isRequired ? <span className='mandatory'>*</span> : ''}</label>
            {
              meta.tooltip &&
              <Tooltip placement='right' title={meta.tooltip} >
                <span className='asset-info-icon'><InformationIcon /></span>
              </Tooltip>
            }
            <MetaDefaultDropDown
              key={index}
              title={meta.displayTitle || meta.displayName}
              options={meta.values}
              meta={meta}
              placeholder={`Select ${meta.displayTitle || meta.displayName}`}
              inputName='title'
              isRequired={meta.isRequired}
              selectedValue={meta && meta.value ? [meta.value] : []}
              handleChange={handleMetaDetailsChange}
              isError={meta ? meta.isError : false}
              contentId={contentId}
              parentCompoent='asset-container'
              mode={'default'}
              allowClear
            />
          </div>
        } else {
          return <InputCustom
            tooltip={meta.tooltip}
            key={index}
            title={meta.displayTitle || meta.displayName}
            placeholder={`Enter ${meta.displayTitle || meta.displayName}`}
            inputName='title'
            isRequired={meta.isRequired}
            value={meta ? meta.value : ''}
            handleChange={({ target }) => handleMetaDetailsChange(contentId, target.value, meta)}
            isError={meta ? meta.isError : false}
            errorMessage={`${meta.displayTitle || meta.displayName} is a mandatory field`}
          />
        }
      } else if (meta.type === 'INTEGER') {
        return <InputNumber
          tooltip={meta.tooltip}

          limit={16}
          key={index}
          title={meta.displayTitle || meta.displayName}
          placeholder={`Enter ${meta.displayTitle || meta.displayName}`}
          inputName='title'
          isRequired={meta.isRequired}
          value={meta ? parseInt(meta.value) : 0}
          handleChange={(value) => handleMetaDetailsChange(contentId, value, meta)}
          isError={meta ? meta.isError : false}
          errorMessage={`${meta.displayTitle || meta.displayName} is a mandatory field`}
        />
      } else if (meta.type === 'LIST') {
        return <div className='input-field'>
          <label className='input-title'>{meta.displayTitle || meta.displayName} {meta.isRequired ? <span className='mandatory'>*</span> : ''}</label>
          {
            meta.tooltip &&
            <Tooltip placement='right' title={meta.tooltip} >
              <span className='asset-info-icon'><InformationIcon /></span>
            </Tooltip>
          }
          <MetaDefaultDropDown
            key={index}
            title={meta.displayTitle || meta.displayName}
            options={meta.values}
            meta={meta}
            placeholder={`Select ${meta.displayTitle || meta.displayName}`}
            inputName='title'
            isRequired={meta.isRequired}
            selectedValue={meta ? meta.fieldValues : []}
            handleChange={handleMetaDetailsChange}
            isError={meta ? meta.isError : false}
            contentId={contentId}
            parentCompoent='asset-container'
            mode={'multiple'}
          />
        </div>
      }
    })
  }
  render () {
    const { summary, onCloseContent, isNotSummary, item, placeholderList } = this.props
    let currentPlaceholder = (placeholderList || []).filter(placeholder => placeholder.id.toUpperCase() === item.type.toUpperCase())

    return (
      <div className={isNotSummary ? 'summary-content content' : 'summary-content'}>
        <div className='summary-content-header' >
          <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
          <p className='summary-text' > { summary } </p>
        </div>
        <div className='summary-content-body' >
          {
            item && item.metaFields && currentPlaceholder && currentPlaceholder[0] && placeholderList && this.getAssetMetaFields(item, currentPlaceholder[0], item.id)
          }

        </div>
      </div>
    )
  }
}

SummaryContent.propTypes = {
  /** summary of Summary. */
  summary: PropTypes.string.isRequired,
  /** close action of Summary. */
  onCloseContent: PropTypes.func
}

SummaryContent.defaultProps = {
}
export default SummaryContent
