import React from 'react'
// import PropTypes from 'prop-types'

import moment from 'moment'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { utilityService } from '../../../services/UtilityService'

import { DatePicker } from 'antd'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import Switch from '../dataEntry/inputs/Switch'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import ButtonContainer from '../general/buttons/ButtonContainer'

import QueryAllTags from '../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../graphQL/asset/createTag'
import './../ui.style.scss'

const { RangePicker } = DatePicker
const parentCompoent = 'root'

class SeasonCustomData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {}
    }
  }

  componentDidMount = () => {
    if (this.props.selectedSeason) {
      this.setState({
        details: _.cloneDeep(this.props.selectedSeason)
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedSeason && !_.isEqual(this.props.selectedSeason, nextProps.selectedSeason)) {
      this.setState({ details: _.cloneDeep(nextProps.selectedSeason) })
    }
  }

  onChangeDate = (date, dateString) => {
    const { details } = this.state
    details.customStartDate = moment(dateString[0]).startOf('day').utc().format()
    details.customEndDate = moment(dateString[1]).endOf('day').utc().format()
    this.setState({ details }, () => this.props.onEditSeason(details))
  }

  // handleGeneralChange = (e) => {
  //   const { details } = this.state
  //   const { name, value } = e.target
  //   if (!value.startsWith(' ')) {
  //     details[name] = value
  //     this.setState({ details }, () => this.props.onEditSeason(details))
  //   }
  // }

  handleTitleChange = (e) => {
    this.setState({ isEditTitle: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customTitle = null
      } else {
        details.customTitle = value
      }
      this.setState({ details }, () => this.props.onEditSeason(details))
    }
  }

  onHandleActive = (status) => {
    const { details } = this.state
    if (status === true) {
      details.customActive = 'yes'
    } else {
      details.customActive = 'no'
    }
    this.setState({ details }, () => this.props.onEditSeason(details))
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { details } = this.state
    const selectedTags = details.tags ? details.tags : []
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'DATA_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            details.tags = selectedTags
            this.setState({ details }, () => this.props.onEditSeason(details))
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}data_manager`
              selectedTags[tagIndex].key = newId
              details.tags = selectedTags
              this.setState({ details }, () => this.props.onEditSeason(details))
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
          details.tags = selectedTags
        }
      }
    }
    this.setState({ details }, () => this.props.onEditSeason(details))
  }

  render () {
    const { project } = this.props
    const { details, isEditTitle } = this.state
    const isChecked = details.customActive ? details.customActive : details.active
    const seasonDate = `${moment(details.customStartDate ? details.customStartDate : details.startDate)},${moment(details.customEndDate ? details.customEndDate : details.endDate)}`
    return (
      <div className='season-custom-data'>
        <div className='season-data'>
          <Input title='Title' placeholder='Write a title...' inputName='customTitle' value={isEditTitle ? details.customTitle : details.customTitle ? details.customTitle : details.title} handleChange={this.handleTitleChange} />
        </div>
        <div className='season-data'>
          <label> Start/End Date </label>
          <RangePicker onChange={this.onChangeDate} format='DD MMM YYYY' getCalendarContainer={() => document.getElementById(parentCompoent)}
            value={seasonDate ? seasonDate.split(',').map((dateItem) => moment(dateItem)) : seasonDate} />
        </div>
        <div className='season-data' id='season-data'>
          <label> Tags </label>
          <TagsInputContainer
            selectedItems={details && details.tags ? details.tags.filter(item => item).map(item => item.name) : []}
            onChange={this.handleTagChange}
            placeholder='Insert Tags'
            parentCompoent={'season-data'}
            displayParameter='name'
            onSearch={() => {}}
            searchTag={''}
            type={'MANUAL'}
            isCustom
            module={'DATA_MANAGER'}
            project={project}
          />
        </div>
        <div className='season-data'>
          <div className='bottom'>
            {/* <div className='type'>
              <span>Including Venues</span>
              <div className='information-icon'>
                <InformationIcon />
              </div>
              <Checkbox checked={details.includesVenues==='yes'? true : false} onClick={() => { }} />
            </div>
            <div className='type'>
              <span>Including Standings</span>
              <div className='information-icon'>
                <InformationIcon />
              </div>
              <Checkbox checked={details.includesStandings==='yes'? true : false} onClick={() => { }} />
            </div> */}
            <div className='type'>
              <span>Active</span>
              <div className='information-icon'>
                <ButtonContainer displayTitle={'Enabled only for current season'} childComponent={<InformationIcon />} />
              </div>
              <Switch isChecked={isChecked === 'yes'} onChange={(status) => this.onHandleActive(status)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SeasonCustomData.propTypes = {

}

SeasonCustomData.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateTag,
    {
      options: ({ project }) => {
        const filter = { type: { match: 'MANUAL' } }
        return {
          update: (cache, { data: { createTag } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },

      props: (props) => ({
        createTag: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(SeasonCustomData))
