import gql from 'graphql-tag'

export default gql(`
  query filterDataList($type: String! $project: String){
    filterDataList(
      type:$type
      project:$project
      ) {
        fields{
            displayName
            name
            type
            value
            isRequired
            fieldName
        }
    }
  }
  `
)
