import gql from 'graphql-tag'

export default gql(`
query listApps($limit: Int $project: String) {
    listApps(
      limit: $limit,
      project: $project
    ) {
      items {
        id
        name
        key
        project
        position
        updatedAt
        url
        description
        createdAt
        bundleId
        configUsageCount
        pageUsageCount
        widgetUsageCount
        isCritical
        assignedUsers
        customMeta{
          config{
              id
              name
                type
                section
              default
                project
                usageCount
              }
          value
          name
      }
        appIcon{
          id
          mediaId
          project
          name
          url
          fileName
          aspectRatio {
              title
              aspectRatio
              resolutions {
                url
              }
          }
          settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2          
          }
        }
      }
    }
}`)
