import React from 'react'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import './../ui.style.scss'

import CrossIcon from './../general/icons/CrossIcon'
import OfferPriceData from './OfferPriceData'

class OfferPriceFieldCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showWarning: false,
      isLoading: false
    }
  }

  showWarningMessage=() => {
    this.setState({ showWarning: true })
  }

  closeWarningMessage=() => {
    this.setState({ showWarning: false, showActiveWarning: false })
  }

  onRemovePrice=() => {
    this.showWarningMessage()
  }

  render () {
    const { priceData, fieldId, active, handleEditPrice, isDisabled, isDeleteDisabled, priceMetaFieldList } = this.props
    const { name, id, promoId } = priceData
    const { showWarning, isLoading } = this.state
    const activeWarningMessage = 'This offer will not have active price at the moment on deletion. Are you sure you want to delete this'
    const warningMessage = 'Are you sure you want to delete this?'
    return (
      <div className='price-details-container'>
        <div className='price-details-header'>
          <div className='price-header-container-left'>
            <h4>{name}</h4>
          </div>
          <div className='price-header-container-right'>
            {promoId ? (<><span className='price-header-promo'>PromoID : {promoId}</span><span>|</span></>) : null}
            {active ? (<><span className='price-header-active'>Active</span><span>|</span></>) : null}
            {isDisabled ? '' : <span className='price-header-edit' onClick={() => handleEditPrice(fieldId)}>Edit Price</span>}
            {(isDeleteDisabled || isDisabled) ? '' : <span>|</span>}
            {isDeleteDisabled ? '' : <Popconfirm
              visible={showWarning}
              placement='left'
              title={active ? activeWarningMessage : warningMessage}
              okText='Yes'
              cancelText='No'
              okButtonProps={{ loading: isLoading }}
              onConfirm={() => {
                this.setState({ isLoading: true })
                this.props.onRemovePriceField(id, () => {
                  this.setState({ isLoading: false })
                  this.closeWarningMessage()
                })
              }}
              onCancel={this.closeWarningMessage}>
              <CrossIcon onClick={() => this.onRemovePrice(active, id)} />
            </Popconfirm>}
          </div>
        </div>
        <div className='line-divider' />
        <OfferPriceData priceData={priceData} priceMetaFieldList={priceMetaFieldList} />
      </div>
    )
  }
}

OfferPriceFieldCell.propTypes = {
  /* Price details of selected Offer */
  priceData: PropTypes.object,
  /* index value of PriceFieldCell */
  fieldId: PropTypes.number,
  /* function to remove a price fieldcell passed from OfferPrice */
  onRemovePrice: PropTypes.func,
  /** function to handle price edit */
  handleEditPrice: PropTypes.func,
  /** active boolean to denote if price is active */
  active: PropTypes.bool
}

export default OfferPriceFieldCell
