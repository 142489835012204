import gql from 'graphql-tag'

export default gql(`
    mutation deleteRole($id: ID!, $name: String, $project: String) {
        deleteRole(
                id: $id,
                name: $name,
                project: $project
        ) {
            id
            name
        }
    }`
)
