import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Input, Skeleton, Empty, message } from 'antd'
import { GlobalHotKeys } from 'react-hotkeys'
import InfiniteScroll from 'react-infinite-scroller'
import { withRouter } from 'react-router-dom'
import NProgress from 'nprogress'

import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
import BackArrowButton from './../../components/ui/general/buttons/BackArrowButton'
import AddButton from './../../components/ui/general/buttons/AddButton'
import SortInput from './../../components/ui/dataEntry/inputs/SortInput'
import OfferListCell from './../../components/ui/dataDisplay/OfferListCell'
import IconDropDown from '../../components/ui/dataEntry/inputs/IconDropDown'
import PartnerFilterIcon from '../../components/ui/general/icons/PartnerFilterIcon'
import StatusFilterIcon from '../../components/ui/general/icons/StatusFilterIcon'
import LoggerService from './../../services/LoggerService'
import { utilityService } from './../../services/UtilityService'
import userMessages from './../../constants/messages'
import AuthService from '../../services/AuthService'
import { generateCroppedThumbnail } from './../../util/util'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { InMemoryCache } from 'apollo-cache-inmemory'
import MutationCreateOffer from './../../graphQL/offer/createOfferDetails'
import QueryFilterOffers from './../../graphQL/offer/searchOffer'
import QueryGetPartnerListFilter from './../../graphQL/offer/getPartnerListFilter'
import AppContext from '../../AppContext'
// import SubscriptionOfferList from './../../graphQL/offer/createOfferSubscription'

const keyMap = {
  shortcutNew: 'n',
  shortcutNext: 'j',
  shortcutPrev: 'k'
}

const statusFilter = [
  {
    name: 'Active',
    key: '1',
    value: 1
  },
  {
    name: 'Inactive',
    key: '2',
    value: 2
  },
  {
    name: 'Archived',
    key: '3',
    value: 3
  }
]

const filterList = [
  {
    displayName: 'Status',
    name: 'isActive',
    type: 'BOOLEAN',
    value: null,
    fieldName: 'STATUS'
  },
  {
    displayName: 'Partner',
    name: 'partner',
    type: 'KEYWORD',
    value: null,
    fieldName: 'PARTNER'
  }
]

const width = ['calc(100% - 100px)', '50%', '100%']

class OfferList extends Component {
  constructor (props) {
    super(props)
    // const urlFilter = window.location.search
    // let filterData = urlFilter && urlFilter.split('?filter=').length > 1 ? JSON.parse(decodeURI((urlFilter.split('?filter=')[1]))) : (localStorage.getItem('filterData'))
    // filterData = typeof (filterData) === 'string' ? JSON.parse(filterData) : filterData
    let filterData = ''
    let state = {
      offerList: [],
      searchString: '',
      isLoading: true,
      isArchival: false,
      isPaginating: false,
      archiveWarning: false,
      selectedIds: [],
      partnerFilter: [],
      shouldShowPartnerFilter: false,
      shouldShowStatusFilter: false,
      isClearFilter: false,
      isSearching: false,
      initialSearch: (!!props.match.params.id && !(filterData && filterData.length)),
      filterData: filterData || []
    }
    if (filterData) {
      localStorage.removeItem('filterData')
      localStorage.setItem('filterData', JSON.stringify(filterData))
      // props.history.push('?filter=' + encodeURI(JSON.stringify(filterData)))
    }
    this.cache = new InMemoryCache()
    this.lastAppliedSort = {
      value: 'name',
      order: 'desc'
    }
    this.getUserDetails()
    if (this.props.offerList && this.props.offerList.length) {
      this.props.changedOffer(this.props.offerList[0].id)
      if (!this.props.isLoading) {
        state.offerList = this.props.offerList
        state.isLoading = false
      }
    } else if (!this.props.isLoading) {
      state.isLoading = false
    }
    this.state = { ...state }
    if (props.match.params.id && !(filterData && filterData.length)) { props.onChangeFilter(props.match.params.id) }
  }

  componentDidMount () {
    // this.props.subscribeToNewOffer()
    this.setState({ offerList: this.props.offerList })
    if (this.state.searchString !== this.props.searchString) {
      this.setState({ searchString: this.props.searchString })
    }
    if (!_.isEqual(this.state.partnerFilter, this.props.partnerList)) {
      this.setState({ partnerFilter: this.props.partnerList })
    }
    this.getUserDetails()
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (!newProps.isLoading && (!this.lastSearched || this.lastSearched.searchString !== newProps.searchString || this.lastSearched.filter !== newProps.filterVal || this.lastSearched.sort !== newProps.sort)) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
      // this.replaceCacheSearchString(newProps.searchString)
      this.setState({ offerList: newProps.offerList, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.offerList, this.props.offerList)) {
      this.setState({ offerList: newProps.offerList, isSearching: false, isPaginating: false, isLoading: false })
    }

    if (newProps.offerList && newProps.offerList.length && !newProps.selectedOfferId) {
      this.props.changedOffer(newProps.offerList[0].id)
    }
    if (newProps.searchString !== this.state.searchString) {
      this.setState({ searchString: newProps.searchString, isSearching: true })
    }
    if (newProps.deletedIds !== this.props.deletedIds && newProps.deletedIds.length) {
      const { offerList } = this.state
      const index = newProps.deletedIds.indexOf(newProps.selectedOfferId)
      const isDeleteCompleted = offerList.findIndex(offer => offer.id === newProps.deletedIds[0]) === -1
      if (isDeleteCompleted && offerList.length) {
        this.props.changedOffer(offerList[0].id)
      } else if (index > -1) {
        this.setNewSelectedOffer(newProps)
      }
    }
    if (!_.isEqual(newProps.partnerList, this.props.partnerList)) {
      this.setState({ partnerFilter: newProps.partnerList })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setNewSelectedOffer = newProps => {
    const { offerList } = this.state
    if (offerList.length > newProps.deletedIds.length) {
      let nextSelectedId
      let tempOfferList = [...offerList]
      let selectedId = newProps.selectedOfferId
      while (tempOfferList.length) {
        const tempIndex = (tempOfferList || []).findIndex(offer => offer.id === selectedId)
        const id = tempOfferList[ tempIndex ? tempIndex - 1 : 1 ].id
        const nextIdPositionInDeletedIds = (newProps.deletedIds || []).indexOf(id)
        if (nextIdPositionInDeletedIds === -1) {
          nextSelectedId = id
          break
        }
        tempOfferList = (tempOfferList || []).filter(offer => offer.id !== selectedId)
        selectedId = id
      }
      if (tempOfferList.length && nextSelectedId) {
        setTimeout(() => this.props.changedOffer(nextSelectedId), 500)
      } else {
        setTimeout(() => this.props.history.push('/offers'), 2000)
      }
    } else {
      setTimeout(() => this.props.history.push('/offers'), 2000)
    }
  }

  getUserDetails = async () => {
    const userDetails = await AuthService.getUserDetails()
    if (userDetails) {
      this.username = userDetails.name || userDetails.email
    }
  }

  onSortChange = (sortData) => {
    this.setState({ isSearching: true })
    this.lastAppliedSort = sortData
    this.props.onChangeSort(sortData)
  }

  loadMoreOffers = () => {
    if (this.props.totalCount === this.props.offerList.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getOfferList(this.state.offerList.length))
  }

  onItemCheck=(id) => {
    let { offerList, selectedIds } = this.state
    const index = (offerList || []).findIndex(offer => offer.id === id)
    if (index > -1) {
      const selectedIndex = (selectedIds || []).indexOf(id)
      if (selectedIndex > -1) {
        selectedIds.splice(selectedIndex, 1)
      } else {
        selectedIds.push(id)
      }
      this.setState({ selectedIds })
    }
  }

  onCheckAllChange=(e) => {
    const { offerList } = this.state
    let selectedIds
    if (this.state.selectedIds.length !== offerList.length) {
      selectedIds = (offerList || []).map(offer => offer.id)
    } else {
      selectedIds = []
    }
    this.setState({ selectedIds })
  }

  onHandleArchival = () => {
    this.setState({ isArchival: true })
    this.props.archiveButtonHandle(true)
  }

  onHandleBack =() => {
    this.setState({ isArchival: false, selectedIds: [] })
    this.props.archiveButtonHandle(false)
  }

  togglePartnerFilter = () => {
    this.setState({ shouldShowPartnerFilter: !this.state.shouldShowPartnerFilter,
      shouldShowStatusFilter: false })
  }

  toggleStatusFilter = () => {
    this.setState({ shouldShowStatusFilter: !this.state.shouldShowStatusFilter,
      shouldShowPartnerFilter: false })
  }

  onOfferCreateSelection = (selectedType) => {
    const newOffer = {
      name: 'Untitled'
    }
    if (this.state.isLoading) {
      return
    }
    NProgress.start()
    this.forceLoading = true
    this.setState({ isLoading: true, isClearFilter: true }, () => {
      this.props.createOffer(newOffer).then(response => {
        this.setState({ isLoading: false, isClearFilter: false, initialSearch: true })
        this.resetFilters()
        this.containerRef.scrollTop = 0
        LoggerService.info('flow', `Created offer ${response.data.createOffer.id}`)
        this.props.changedOffer(response.data.createOffer.id)
        setTimeout(() => {
          this.forceLoading = false
          this.onChangeFilter(response.data.createOffer.id)
        }, 2000)
        NProgress.done()
        message.success(userMessages.OFFER_CREATE_SUCCESS)
      }, error => {
        this.setState({ isLoading: false, isClearFilter: false, initialSearch: true })
        this.resetFilters()
        this.containerRef.scrollTop = 0
        this.props.changedOffer(this.props.selectedOfferId)
        setTimeout(() => {
          this.forceLoading = false
          this.onChangeFilter(this.props.selectedOfferId)
        }, 2000)
        utilityService.handleError(error)
      })
    })
  }

  onChangeFilter = (e) => {
    this.setState({ isSearching: true })
    const sortData = {
      value: 'createdAt',
      order: 'desc'
    }
    if (e && e.length) {
      this.props.onChangeSort(sortData)
    } else {
      this.props.onChangeSort(this.lastAppliedSort)
    }
    this.props.onChangeFilter(e)
    setTimeout(() => {
      this.setState({
        initialSearch: false
      })
    }, 100)
  }

  resetFilters = () => {
    const sortData = {
      value: 'createdAt',
      order: 'desc'
    }
    this.lastAppliedSort = sortData
    this.setState({ isSearching: true })
    this.props.onChangeFilter('')
    this.props.onChangeSort(sortData)
    this.changeFilterValue([])
  }

  handleClickOutside = (event) => {
    if (this.state.shouldShowCreate) {
      setTimeout(() => this.setState({ shouldShowCreate: false }), 200)
    }
    if (!(this.filterContainer && this.filterContainer.contains(event.target))) {
      this.setState({ shouldShowStatusFilter: false,
        shouldShowPartnerFilter: false })
    }
  }

  onStatusSelect=(selectedStatus) => {
    if (selectedStatus) {
      const selectedItem = (statusFilter || []).find((item) => item.key === selectedStatus)
      this.onChangingQuickFilter(selectedItem.value, 'isActive')
    } else {
      this.onChangingQuickFilter(null, 'isActive')
    }
  }

  onPartnerSelect=(selectedPartner) => {
    const { partnerFilter } = this.state
    if (selectedPartner) {
      const selectedItem = (partnerFilter || []).find((item) => item.id === selectedPartner)
      this.onChangingQuickFilter(selectedItem.id, 'partner')
    } else {
      this.onChangingQuickFilter(null, 'partner')
    }
  }

  onChangingQuickFilter = (value, key) => {
    let filterData = _.cloneDeep(this.state.filterData)
    const offerTypeFilterIndex = (filterData || []).findIndex((item) => item.name === key)
    if (offerTypeFilterIndex < 0) {
      let newfilterList = _.cloneDeep(filterList)
      const index = (newfilterList || []).findIndex((item) => item.name === key)
      newfilterList[index].value = value
      filterData.push(newfilterList[index])
      this.setState({ isSearching: true, filterData }, () => {
        this.changeFilterValue(filterData)
      })
    } else {
      filterData[offerTypeFilterIndex].value = value
      this.setState({ isSearching: true, filterData }, () => {
        this.changeFilterValue(filterData)
      })
    }
  }

  getSelectedStatus=() => {
    const { filterData } = this.state
    let selectedFilter = (filterData || []).find((item) => (item.name === 'isActive'))
    if (_.isEmpty(selectedFilter)) {
      return null
    } else {
      const selectedValue = (statusFilter || []).find((item) => (item.value === selectedFilter.value))
      return selectedValue ? selectedValue.key : null
    }
  }

  getSelectedPartner=() => {
    const { filterData, partnerFilter } = this.state
    let selectedFilter = (filterData || []).find((item) => (item.name === 'partner'))
    if (_.isEmpty(selectedFilter)) {
      return null
    } else {
      const selectedValue = (partnerFilter || []).find((item) => (item.id === selectedFilter.value))
      return selectedValue ? selectedValue.id : null
    }
  }

  getQuickFilterData = (key) => {
    const { filterData } = this.state
    const selectedFilter = (filterData || []).find((item) => item.name === key)
    return _.isEmpty(selectedFilter) ? [] : selectedFilter.value
  }

  changeFilterValue = (value) => {
    this.props.changeFilterValue(value)
    localStorage.removeItem('filterData')
    // const urlValue = value.filter((item) => item.value || item.value !== null).map((item) => {
    //   delete (item.__typename)
    //   return item
    // })
    // if (urlValue && urlValue.length) {
    //   const filter = this.encodeFilter(urlValue)
    //   localStorage.setItem('filterData', filter)
    //   // this.props.history.push('?filter=' + JSON.stringify(urlValue))
    //   this.props.history.push('?filter=' + encodeURI(filter))
    // } else {
    //   this.props.history.push('?')
    // }
  }

  // encodeFilter = (filter) => {
  //   let value = (filter || []).map(item => {
  //     let itemValue = item.value
  //     if (typeof (item.value) === 'object') {
  //       itemValue = (item.value).map((innerItem) => {
  //         delete (innerItem.__typename)
  //         return innerItem
  //       })
  //     }
  //     return {
  //       name: item.name,
  //       type: item.type,
  //       value: itemValue
  //     }
  //   })
  //   return JSON.stringify(value)
  // }

  onSearchChange = e => {
    let { value } = e.target
    if (value.startsWith(' ')) { value = value.trim() }
    this.setState({ searchString: value, isSearching: true })
    if (this.delayTime) { clearTimeout(this.delayTime) }
    this.delayTime = setTimeout(() => {
      clearTimeout(this.delayTime)
      this.props.onChangeFilter(value)
    }, 750)
  }

  render () {
    const { offerList, isPaginating, isLoading, isArchival, selectedIds, shouldShowPartnerFilter, shouldShowStatusFilter, searchString, isSearching, partnerFilter } = this.state
    const { sort, selectedOfferId, changedOffer, project } = this.props
    const selectedStatus = this.getSelectedStatus()
    const selectedPartner = this.getSelectedPartner()
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['OFFER_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          return <>
            <div className='offer-list' id='offer-list'>
              <GlobalHotKeys keyMap={keyMap} handlers={{}} />
              <div className='filter'>
                <Input placeholder='Search Products' allowClear onChange={this.onSearchChange} value={searchString} />
              </div>
              <div className='sort-filter-container' id='sort-filter-container'>
                <SortInput type='OfferManager' selectedOrder={sort.order} selectedSort={sort.value} onSortChange={this.onSortChange} parent='sort-filter-container' manager='offer' project={project} />
                <div className='offer-quick-filter' id='offer-quick-filter' ref={node => { this.filterContainer = node }}>
                  <IconDropDown
                    icon={<StatusFilterIcon onClick={this.toggleStatusFilter} color={selectedStatus ? '#FF015A' : ''} />}
                    displayParam='name'
                    contents={statusFilter || []}
                    onContentClick={this.onStatusSelect}
                    parentCompoent={'offer-quick-filter'}
                    isVisible={shouldShowStatusFilter}
                    selectedOption={selectedStatus}
                    keyParam='key'
                    multiSelect={false}
                  />
                  <IconDropDown
                    icon={<PartnerFilterIcon onClick={this.togglePartnerFilter} color={selectedPartner ? '#FF015A' : ''} />}
                    displayParam='name'
                    contents={partnerFilter || []}
                    onContentClick={this.onPartnerSelect}
                    parentCompoent={'offer-quick-filter'}
                    isVisible={shouldShowPartnerFilter}
                    selectedOption={selectedPartner || []}
                    multiSelect={false}
                    keyParam='id'

                  />
                </div>
              </div>
              <div className='table' ref={(node) => { this.containerRef = node }}>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={this.forceLoading || (isLoading && !isSearching && !isPaginating)} />
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={(isSearching && !this.forceLoading)}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMoreOffers}
                    hasMore={this.props.totalCount > offerList.length}
                    initialLoad={false}
                    useWindow={false}
                  >
                    { !this.forceLoading && offerList && offerList.length ? offerList.map(offer => {
                      let imageUrl
                      if (offer.partner && offer.partner.logo) {
                        imageUrl = generateCroppedThumbnail(offer.partner.logo, 55, 55, '1:1')
                      }
                      return (<OfferListCell
                        key={offer.id}
                        imageUrl={imageUrl}
                        shortDescription={offer.shortDescription}
                        offerName={offer.name}
                        partnerName={offer.partner ? offer.partner.name : ''}
                        offerId={offer.id}
                        onSelect={changedOffer}
                        isSelected={selectedOfferId === offer.id}
                        isArchival={isArchival}
                        isChecked={selectedIds.indexOf(offer.id) > -1}
                        onCheck={this.onItemCheck}
                        manager='offer'
                        externalId={offer.externalId}
                      />)
                    }) : (!isLoading && !isPaginating && !this.forceLoading) ? <Empty /> : <div /> }
                  </InfiniteScroll>
                </Skeleton>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={!this.forceLoading && isPaginating && this.props.totalCount > offerList.length} />

              </div>
              <div className='offer-list-footer' id={'offer-list-footer'}>
                { !isArchival
                  ? <React.Fragment>
                    {/* <ButtonContainer displayTitle='Mutiple Archive' childComponent={<MultipleSelectButton onClick={this.onHandleArchival} isDisabled={!offerList.length || isArchivalFilterApplied} />} /> */}
                    <ButtonContainer displayTitle='Create offer' childComponent={<AddButton onClick={this.onOfferCreateSelection} isDisabled={isCreateDisabled} />} />
                  </React.Fragment>
                  : <React.Fragment>
                    <Checkbox onChange={this.onCheckAllChange} checked={selectedIds.length && selectedIds.length === offerList.length} disabled={false} />
                    <div className='archieve-bottom'>
                      <ButtonContainer displayTitle='Back' childComponent={<BackArrowButton onClick={this.onHandleBack} />} />
                      <div>
                        <ButtonContainer displayTitle='Archive Selected offers' childComponent={<ArchiveButton type='primary' isDisabled={false} onClick={() => console.log('click')} />} />
                      </div>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
    </>
        }}
      </AppContext.Consumer>
    )
  }
}

OfferList.propTypes = {
  /** sort object for OfferList */
  sort: PropTypes.object,
  /** partner change action of OfferManager. */
  changedOffer: PropTypes.func.isRequired,
  /** offer list filter change action of OfferManager. */
  onChangeFilter: PropTypes.func.isRequired,
  /* list of assets given by apollo or PartnerManager */
  offerList: PropTypes.array,
  /* partner id of selected OfferFieldCell */
  selectedOfferId: PropTypes.string,
  /** array of applied filters for OfferList */
  filterVal: PropTypes.array,
  /** array of archived filters for OfferList */
  deletedIds: PropTypes.array,
  /** function to handle Archive Button of OfferList */
  archiveButtonHandle: PropTypes.func,
  /** function to handle sort change of OfferList */
  onChangeSort: PropTypes.func

}

export default withApollo(compose(
  graphql(
    MutationCreateOffer,
    {
      props: (props) => ({
        createOffer: (event) => {
          let variables = event
          variables.project = props && props.ownProps && props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryFilterOffers,
    {
      options: (props) => {
        const { searchString, filterVal, sort, project } = props
        const filter = utilityService.getFormattedFilterForOffer(filterVal)
        const variables = utilityService.getFormattedOfferFilter(searchString, filter, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const offerList = data.listOffers ? data.listOffers.items : []
        return {
          offerList,
          isLoading: data.loading || !data.listOffers,
          totalCount: data.listOffers ? data.listOffers.totalCount : 0,
          getOfferList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listOffers.items, ...fetchMoreResult.listOffers.items ]
                prev.listOffers.items = newList
                return prev
              }
            })
          }
          // subscribeToNewOffer: () => {
          //   return props.data.subscribeToMore({
          //     document: SubscriptionOfferList,
          //     updateQuery: (prev, { subscriptionData: { data: { offerCreated } } }) => {
          //       try {
          //         console.log(offerCreated)
          //         // const { project } = props.ownProps
          //         if (!offerCreated) return prev
          //         const index = prev.listOffers.items.findIndex(offer => offer.id === offerCreated.id)
          //         if (index !== -1) {
          //           prev.listOffers.items.splice(index, 1)
          //         }
          //         const newList = [ offerCreated, ...prev.listOffers.items ]
          //         prev.listOffers.items = newList
          //         prev.listOffers.totalCount = prev.listOffers.totalCount + 1
          //         return prev
          //       } catch (error) {
          //       }
          //     }
          //   })
          // }
        }
      }
    }
  ),
  graphql(
    QueryGetPartnerListFilter,
    {
      options: (props) => {
        const variables = {
          limit: 999,
          offset: 0,
          project: props.project

        }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const partnerList = data.listPartners ? data.listPartners.items : []
        return {
          partnerList
        }
      }
    }
  )

)(withRouter(OfferList)))
