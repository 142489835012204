import gql from 'graphql-tag'

export default gql(`
query listPartnersQuery($offset: Int, $limit: Int, $order: String, $search: SearchInput, $project: String) {
  listPartners(
      limit: $limit,
      offset: $offset,
      order: $order,
      search: $search
      project: $project
    ) {
      items {
        id
        externalId
        name
        isActive
        logo{
          id
          mediaId
          project
          name
          url
          fileName
          aspectRatio {
              title
              aspectRatio
              resolutions {
                url
              }
          }
          settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2          
          }
      }
      attributes{
        label
        type
        value
        toggleValue
      }
        }
      totalCount
    }
}`)
