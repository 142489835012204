import gql from 'graphql-tag'

export default gql(`
query listApiKeys( $project: String ) {
    listApiKeys(
      project: $project
    ) {
      items {
        id
        label
        createdAt
        createdBy
        apiKey
        app{
            id
            key
            name
            project
            updatedAt
            url
            description
            createdAt
            bundleId
        }
        isArchived
        isDisabled
      }
    }
}`)
