import React from 'react'
import PropTypes from 'prop-types'
import { Spin, Icon, message } from 'antd'
import AppContext from '../../../AppContext'

import AlertIcon from './../general/icons/AlertIcon'
import ButtonContainer from './../general/buttons/ButtonContainer'
import ToolTip from '../dataDisplay/ToolTip'
import FreeIcon from '../general/icons/FreeIcon'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import MutationCreateAsset from '../../../graphQL/match/createAssetFromTemplate'
import QueryGetMatchDetails from '../../../graphQL/match/getMatch'
import { utilityService } from '../../../services/UtilityService'
import QueryAllMatches from '../../../graphQL/match/listMatches'

class MatchAssetDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false

    }
  }
  onCreateAsset = (e, selectedType) => {
    e.stopPropagation()
    if (this.state.isLoading) {
      return
    }
    this.setState({ isLoading: true, selectedAssetType: selectedType }, () => {
      const { details } = this.props
      const newAsset = {
        assetType: selectedType.type,
        programId: selectedType.programSection && details.programs && details.programs.length ? details.programs[0].id : undefined,
        matchId: details.id,
        competition: details.competition ? details.competition.id : null
      }
      this.props.createAssetFromTemplate(newAsset).then(response => {
        if (!response.data.createAssetFromTemplate) {
          message.error('Template/default template not defined for this competition')
          this.setState({ isLoading: false, createAsset: false })
          return
        }
        setTimeout(() => {
          window.open(location.origin + '/assets/' + response.data.createAssetFromTemplate.id, '_blank')
          this.setState({ isLoading: false, createAsset: false })
        }, 2000)
      }, error => {
        this.setState({ isLoading: false, createAsset: false })
        utilityService.handleError(error)
      })
    })
  }

  renderAddIcon = (assetType, isLoading, isCreateDisabled) => {
    return <div className={`add-asset${isCreateDisabled ? ' disabled' : ''}`}>
      {/* <AddIcon onClick={() => this.onCreateAsset(assetType.type)} isDisabled={isLoading && selectedAssetType === assetType.type} /> */}
      { isLoading ? <Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} /> : <span onClick={(e) => this.onCreateAsset(e, assetType)}> {`+ Add`}</span>}
    </div>
  }

  onAddAsset = (e, assetType) => {
    e.stopPropagation()
    this.setState({ createAsset: true, selectedType: assetType })
  }

  onCancelAddAsset = (e) => {
    e.stopPropagation()
    this.setState({ createAsset: false })
  }

  render () {
    const { assets, isExpanded, types } = this.props
    const { isLoading, selectedAssetType } = this.state
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['MATCH_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          return <div className='list-match-assets'>
            {types ? types.map(assetType => {
              const filteredAssets = assets ? assets.filter(item => item.type === assetType.type) : []
              // const doneAssets = filteredAssets ? filteredAssets.filter(item => item.status === 'PUBLISHED') : []
              const assetId = filteredAssets && filteredAssets.length ? filteredAssets.map(item => item.id) : []
              const status = filteredAssets && filteredAssets.length ? filteredAssets.map(item => `${item.id} - ${item.isArchived ? 'ARCHIVED' : item.status}`) : null
              const displayTitle = isCreateDisabled ? 'Access denied' : `Create ${assetType.displayName} Asset`
              return isExpanded ? <div className='match-asset-cell-expanded' key={assetType.type}>

                {filteredAssets.length
                  ? <div className='asset-column'>
                    <ButtonContainer displayTitle={displayTitle} childComponent={this.renderAddIcon(assetType, isLoading && selectedAssetType.type === assetType.type, isCreateDisabled)} />
                    <div>
                      {assetId ? filteredAssets.map(item => <div className='asset-details' key={item.id}>
                        {(item.status !== 'PUBLISHED' || item.isArchived) ? <ButtonContainer displayTitle={item.isArchived ? 'ARCHIVED' : item.status} childComponent={<AlertIcon />} /> : <span style={{ width: '15px' }} />}
                        {<div className='asset-container'><a className='asset-id' href={location.origin + '/assets/' + item.id} target='_blank'> {item.id} </a>{item.isFree ? <FreeIcon /> : ''}</div>}
                      </div>
                      ) : null }
                    </div>
                  </div> : <div className='asset-empty'>
                    <span>No Asset</span>
                    <ButtonContainer displayTitle={displayTitle} childComponent={this.renderAddIcon(assetType, isLoading && selectedAssetType.type === assetType.type, isCreateDisabled)} />
                  </div>
                }
              </div> : <div className='match-asset-cell' key={assetType.type}>
                {filteredAssets.length
                  ? <ToolTip className='btn-tooltip' placement='top' isList childComponent={`(${filteredAssets.length})`} details={status} />
                  : <ButtonContainer displayTitle={`No ${assetType.displayName} Assets Created`} childComponent={`(${filteredAssets.length})`} /> }
              </div>
            }) : null}
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

MatchAssetDetails.propTypes = {
  /** Match list of MatchListTable */
  matchList: PropTypes.array,
  /** Match details expanded status of MatchListTable */
  isExpanded: PropTypes.bool,
  /** Match selected status of MatchListTable */
  selectedMatchId: PropTypes.string,
  /** Click selection action of MatchListTable. */
  onSelect: PropTypes.func

}

MatchAssetDetails.defaultProps = {
  matchList: [{

  }]
}

export default withApollo(compose(
  graphql(
    MutationCreateAsset,
    {
      options: ({ details, matchSearch, matchFilter, selectedMatchId }) => {
        return {
          update: (cache, { data: { createAssetFromTemplate } }) => {
            if (!matchFilter) {
              matchFilter = {}
            }
            const variables = utilityService.getMatchManagerFilter(matchFilter, matchSearch)
            try {
              if (details.id === selectedMatchId) {
                const cacheData = cloneDeep(cache.readQuery({ query: QueryGetMatchDetails, variables: { id: details.id } }))
                if (cacheData.getMatch && createAssetFromTemplate) {
                  cacheData.getMatch.asset.push(createAssetFromTemplate)
                }
                cache.writeQuery({
                  query: QueryGetMatchDetails,
                  data: cacheData,
                  variables: { id: details.id }
                })
              } else {
                const cacheData = cloneDeep(cache.readQuery({ query: QueryAllMatches, variables }))
                if (cacheData.listMatches && cacheData.listMatches.items) {
                  const index = cacheData.listMatches.items.findIndex(item => item.id === details.id)
                  if (index > -1 && createAssetFromTemplate) {
                    cacheData.listMatches.items[index].asset.push(createAssetFromTemplate)
                  }
                }
                cache.writeQuery({
                  query: QueryAllMatches,
                  data: cacheData,
                  variables
                })
              }
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createAssetFromTemplate: (variables) => {
          return props.mutate({ variables })
        }
      })
    }
  )

)(MatchAssetDetails))
