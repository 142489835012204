import React from 'react'
import PropTypes from 'prop-types'

import RichText from './../../components/ui/dataEntry/other/RichText'

function TermsAndConditions (props) {
  const { terms, handleTermAndConditionChange, onInputFieldFocus, onInputFieldBlur } = props

  return (
    <div className='terms-conditions'>
      <RichText
        text={terms}
        onInputFieldFocus={onInputFieldFocus}
        onInputFieldBlur={onInputFieldBlur}
        onRichTextChange={value => handleTermAndConditionChange(value)}
        showCloseIcon={false}
      />
    </div>
  )
}

TermsAndConditions.propTypes = {
  /** content for TermsandCondition */
  terms: PropTypes.string,
  /** function to handle text changes in RichTextEditor */
  handleTermAndConditionChange: PropTypes.func,
  /** function to handle blur changes in RichTextEditor */
  onInputFieldBlur: PropTypes.func,
  /** function to handle focus changes in RichTextEditor */
  onInputFieldFocus: PropTypes.func
}

export default TermsAndConditions
