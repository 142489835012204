import gql from 'graphql-tag'

export default gql(`
    mutation sendAntiPiracyAction ( $userId: String!  $actionKey: String!  $operatorNote: String!  ) {
        sendAntiPiracyAction (
            userId: $userId
            actionKey: $actionKey
            operatorNote: $operatorNote
        ) {
            statusCode
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
