import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Tabs, Table, message } from 'antd'

import EditImageDetails from './EditImageDetails'
import EditAdManagementDetails from './EditAdManagementDetails'
import EditTagDetails from './EditTagDetails'
import CropImageList from '../../dataDisplay/CropImageList'
import CropWhiteButton from './../../general/buttons/CropWhiteButton'
import ButtonContainer from './../../general/buttons/ButtonContainer'
import DefaultImageButton from './../../general/buttons/DefaultImageButton'
import AddImageModal from '../../dataEntry/other/AddImageModal'
import './../../ui.style.scss'
import { getCropImageUrl } from '../../../../util/util'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryImageSettings from '../../../../graphQL/content/getImageSetting'

const { TabPane } = Tabs

class EditImageContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showImageModal: false
    }

    this.columns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: 200
      },
      {
        title: 'Content Key',
        key: 'contentKey',
        dataIndex: 'contentKey',
        width: 200,
        render: (contentKey) => this.renderStreamContentKey(contentKey)
      },
      {
        title: 'URL',
        key: 'Url',
        dataIndex: 'Url',
        width: 100,
        render: (Url) => this.renderStreamUrl(Url)
      },
      {
        title: 'Drm Entitlement Message',
        key: 'drmEntitlementMessage',
        dataIndex: 'drmEntitlementMessage',
        width: 100,
        render: (drmEntitlementMessage) => this.renderDrmEntitlementMessage(drmEntitlementMessage)
      },
      {
        title: 'DRM',
        key: 'drm',
        dataIndex: 'drm',
        width: 100
      },
      {
        title: 'IV',
        key: 'iv',
        dataIndex: 'iv',
        width: 200,
        render: (iv) => this.renderStreamIv(iv)
      },
      {
        title: 'Codec',
        key: 'codec',
        dataIndex: 'codec',
        width: 100
      },
      {
        title: 'Transport',
        key: 'transport',
        dataIndex: 'transport',
        width: 100
      },
      {
        title: 'Package Config ID',
        key: 'PackagingConfigurationId',
        dataIndex: 'PackagingConfigurationId',
        width: 150,
        render: (PackagingConfigurationId) => this.renderStreamConfig(PackagingConfigurationId)
      }
    ]
  }
  onSelected = (item) => {
    this.props.selectAspectRatio(item)
  };

  ratioSizes = (x, y) => {
    let w = 300
    let h = 168
    if (x > y) {
      w = 300
      h = (y * w) / x
    }
    if (x < y) {
      h = 168
      w = (h * x) / y
    }
    if (x === y) {
      h = 168
      w = 168
    }
    return { width: parseInt(w), height: parseInt(h) }
  }

  getImageUrl = (mediaDetails, settings) => {
    let imageUrl
    if (this.props.selectedAspectRatio) {
      const filteredSetting = settings.find(setting => this.props.selectedAspectRatio.aspectRatio === setting.aspectRatio)
      imageUrl = getCropImageUrl(mediaDetails, filteredSetting)
    } else if (mediaDetails.aspectRatio) {
      const tempAspectRatio = mediaDetails.aspectRatio.filter(item => item.aspectRatio)[ 0 ]
      let tempSettings = settings[0]
      if (tempAspectRatio) {
        tempSettings = settings.find(aspectRatio => tempAspectRatio.aspectRatio === aspectRatio.aspectRatio)
      }
      imageUrl = getCropImageUrl(mediaDetails, tempSettings)
    }
    return imageUrl
  }

  addImage = (selectedImage) => {
    const { selectedAspectRatio, selectedImageType, mediaDetails } = this.props
    let updateSettings
    if (selectedAspectRatio) {
      updateSettings = selectedImage[0].settings.find(aspectRatio => selectedAspectRatio.aspectRatio === aspectRatio.aspectRatio)
    } else {
      const tempAspectRatio = this.props.mediaDetails.aspectRatio[ 0 ]
      updateSettings = selectedImage[0].settings.find(aspectRatio => tempAspectRatio.aspectRatio === aspectRatio.aspectRatio)
    }
    if (!updateSettings) {
      const currentSetting = mediaDetails.settings.find(item => selectedAspectRatio.aspectRatio === item.aspectRatio)
      const ratio = (currentSetting.x2 - currentSetting.x1) / (currentSetting.y2 - currentSetting.y1)
      const variables = {
        aspectRatio: selectedAspectRatio.aspectRatio,
        fileName: selectedImage[0].settings[0].fileName,
        ratio,
        cropPosition: !selectedImageType ? 'TOP_LEFT' : selectedImageType.cropPosition
      }
      this.props.getImageSetting(variables).then(({ data }) => {
        this.props.updateAspectRatioImage(data.getImageSetting)
        this.setState({ showImageModal: false })
      })
    } else {
      this.props.updateAspectRatioImage(updateSettings)
      this.setState({ showImageModal: false })
    }
  }

  handleCancel = () => {
    this.setState({ showImageModal: false })
  }

  renderAspectRatioDetails = (mediaDetails, selectedAspectRatio, settings) => {
    if (mediaDetails.aspectRatio.length) {
      return mediaDetails.aspectRatio.filter(item => item.aspectRatio).map((item, index) => {
        const filteredSetting = settings.find(setting => setting.aspectRatio === item.aspectRatio)
        const { width, height } = this.ratioSizes(filteredSetting.x2 - filteredSetting.x1, filteredSetting.y2 - filteredSetting.y1)
        const imageUrl = getCropImageUrl(mediaDetails, filteredSetting, width, height)
        const { dimensions } = mediaDetails
        let position = ''
        if (dimensions && filteredSetting.x1 === 0 && dimensions.x2 !== filteredSetting.x2) {
          position = 'left'
        } else if (dimensions && filteredSetting.x1 !== 0 && dimensions.x2 <= filteredSetting.x2) {
          position = 'right'
        }
        return <CropImageList
          key={item.title}
          imageUrl={imageUrl}
          onSelected={this.onSelected}
          isSelected={selectedAspectRatio.title === item.title}
          aspectRatio={item}
          position={position}
        />
      })
    }
  }

  renderStreamUrl = (Url) => {
    return <p className='stream-url' onClick={() => this.copyOnClick(Url, 'URL')}>Copy URL</p>
  }

  renderDrmEntitlementMessage = (drmEntitlementMessage) => {
    return <p className='stream-url' onClick={() => this.copyOnClick(drmEntitlementMessage, 'Drm Entitlement Message')}>Copy Message</p>
  }

  renderStreamIv = (iv) => {
    return <p className='stream-iv'>{iv !== 'none' && iv !== null ? iv : '-'}</p>
  }

  renderStreamConfig = (PackagingConfigurationId) => {
    return <p className='package-configuration-id'>{PackagingConfigurationId || '-'}</p>
  }

  renderStreamChannel = (channel) => {
    return <p>{channel ? channel.id : '-'}</p>
  }

  copyOnClick = (data, type) => {
    navigator.clipboard.writeText(data, type)
    message.success(`${type} Copied Successfully`)
  }

  renderStreamContentKey = (contentKey) => {
    return <p>{contentKey || '-'}</p>
  }

  render () {
    const { selectedFormat, selectedTags, formats, mediaDetails, name, settings, handleFormatChange, handleTextChange, type,
      handleTagChange, systemTags, handleCategoryChange, categoryList, selectedCategory, isUploadBlocked, handleDrmChange, isDrmEnabled, onAddNewAdPosition, adPositions, onChangeAdPosition, onSaveAdPosition, onDeleteAdPosition, cuePoints, onAddNewCuePoint, onChangeCuePoint, onSaveCuePoint, onDeleteCuePoint, breakDurations, onAddNewBreakDuration, onChangeBreakDuration, onSaveBreakDuration, onDeleteBreakDuration, project } = this.props
    const { showImageModal } = this.state
    const drmStream = (mediaDetails.streams || []).filter((item) => !item.drm.includes('CLEAR'))
    const noDrmStream = (mediaDetails.streams || []).filter((item) => item.drm.includes('CLEAR'))
    const isAspectRatioNull = (mediaDetails.aspectRatio || []).filter(item => item.aspectRatio)
    let streams
    let prettierOriginalResponse
    if (type === 'VIDEO') {
      streams = mediaDetails.streams ? mediaDetails.streams : []
      const originalResponsebody = mediaDetails && mediaDetails._originalResponseBody ? mediaDetails && mediaDetails._originalResponseBody : ''
      const originalResponse = JSON.parse(originalResponsebody)
      let filterOriginalResponse = originalResponse && originalResponse[0] && originalResponse[0]['_originalResponseBody']
      prettierOriginalResponse = JSON.stringify(filterOriginalResponse, undefined, 4)
    }
    return (
      <div id='edit-image-modal' className={`edit-image-modal${type === 'VIDEO' ? ' video' : ''}`}>
        <Tabs defaultActiveKey={type === 'IMAGE' ? '1' : '2'}>
          { type === 'IMAGE' ? <TabPane tab='Aspect Ratio' key='1'>
            <Row>
              <Col span={12}>
                <div className='edit-img-preview' id='image-item'>
                  <img className='preview-image' src={this.getImageUrl(mediaDetails, settings)} />
                  {isAspectRatioNull && isAspectRatioNull.length ? <React.Fragment>
                    <div className='crop-white-container'>
                      <ButtonContainer displayTitle='Crop Image' childComponent={<CropWhiteButton onClick={() => this.props.showCrop(this.props.selectedAspectRatio || mediaDetails.aspectRatio[ 0 ])} />} />
                    </div>
                    <div className='replace-image-container'>
                      <ButtonContainer displayTitle='Replace Image' childComponent={<DefaultImageButton onClick={() => this.setState({ showImageModal: true })} />} />
                    </div>
                  </React.Fragment> : ''}
                </div>
              </Col>
              <Col span={12}>
                <div className='edit-img-preview' id='crop-list'>
                  <div className='crop-list-container'>
                    {this.renderAspectRatioDetails(mediaDetails, this.props.selectedAspectRatio || mediaDetails.aspectRatio[ 0 ], settings)}
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane> : null }

          <TabPane tab='Details' key='2'>
            <EditImageDetails
              type={type}
              selectedFormat={selectedFormat}
              formats={formats}
              name={name}
              handleFormatChange={handleFormatChange}
              handleTextChange={handleTextChange}
              handleTagChange={handleTagChange}
              systemTags={systemTags}
              selectedTags={selectedTags}
              categoryList={categoryList}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              isDrmEnabled={isDrmEnabled}
              handleDrmChange={handleDrmChange}
              isDrmEditEnabled={type === 'VIDEO' && drmStream.length && noDrmStream.length}
              project={project}
            />
          </TabPane>
          {type === 'VIDEO'
            ? <TabPane tab='Tags' key='3'>
              <EditTagDetails
                handleTagChange={handleTagChange}
                systemTags={systemTags}
                selectedTags={selectedTags}
                project={project}
              />
            </TabPane> : null
          }
          {type === 'VIDEO'
            ? <TabPane tab='AD Management' key='4'>
              <EditAdManagementDetails
                adPositions={adPositions}
                onAddNewAdPosition={onAddNewAdPosition}
                onChangeAdPosition={onChangeAdPosition}
                onSaveAdPosition={onSaveAdPosition}
                onDeleteAdPosition={onDeleteAdPosition}
                cuePoints={cuePoints}
                onAddNewCuePoint={onAddNewCuePoint}
                onChangeCuePoint={onChangeCuePoint}
                onSaveCuePoint={onSaveCuePoint}
                onDeleteCuepoint={onDeleteCuePoint}
                breakDurations={breakDurations}
                onAddNewBreakDuration={onAddNewBreakDuration}
                onChangeBreakDuration={onChangeBreakDuration}
                onSaveBreakDuration={onSaveBreakDuration}
                onDeleteBreakDuration={onDeleteBreakDuration}
              />
            </TabPane> : null
          }
          {type === 'VIDEO'
            ? <TabPane tab='Streams' key='6'>
              <div className='video-stream-details'>
                <Table rowKey={record => record.id} columns={this.columns} dataSource={streams} pagination={false} scroll={{ x: 800, y: (400) }} />
              </div>
            </TabPane> : null
          }
          {type === 'VIDEO'
            ? <TabPane tab='VWM Response' key='7'>
              <div className='video-json-details'>
                <pre onClick={() => this.copyOnClick(prettierOriginalResponse, 'JSON')}>{prettierOriginalResponse}</pre>
              </div>
            </TabPane> : null
          }

        </Tabs>
        <AddImageModal
          isVisible={showImageModal}
          handleSubmit={this.addImage}
          handleCancel={this.handleCancel}
          currentSelection={[mediaDetails]}
          mediaType={'IMAGE'}
          isSubmitButtonDisabled={isUploadBlocked}
          project={project} />
      </div>
    )
  }
}

EditImageContainer.propTypes = {
  /** Function to be executed when crop is clicked */
  showCrop: PropTypes.func,
  /** Array of taglist */
  tagsList: PropTypes.array,
  /**  Array of selected tags */
  selectedTags: PropTypes.array,
  /**  Function to handle tag change */
  handleTagChange: PropTypes.func,
  /**  Function to handle video format change */
  handleFormatChange: PropTypes.func,
  /** Function to handle name cahnge */
  handleTextChange: PropTypes.func,
  /** Array of formats  */
  formats: PropTypes.array,
  /**  selected format */
  selectedFormat: PropTypes.string,
  /** Function to be called for updating images for each aspect ratios */
  updateAspectRatioImage: PropTypes.func,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** List of media category */
  categoryList: PropTypes.array,
  /** Selected category */
  selectedCategory: PropTypes.string
}

EditImageContainer.defaultProps = {
  imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Football_iu_1996.jpg'
}

// export default EditImageContainer

export default withApollo(
  compose(
    graphql(
      QueryImageSettings,
      {
        options: ({ project }) => {
          const variables = {
            aspectRatio: '',
            fileName: '',
            ratio: null,
            cropPosition: 'TOP_LEFT',
            project
          }
          return {
            variables,
            fetchPolicy: 'cache-first'
          }
        },
        props: (props) => {
          return {
            // vodStatus: props.data.getAsset,
            getImageSetting: (input) => {
              return props.data.refetch({ input, project: props.ownProps.project })
            }
          }
        }
      }
    )
  )(EditImageContainer)
)
