import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, message, Switch } from 'antd'
import { v4 as uuidv4 } from 'uuid'

// import ConfirmModal from './../../feedback/ConfirmModal'
import Input from './../inputs/Input'
import InputNumber from '../inputs/InputNumber'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
// import QueryTagContent from '../../../../graphQL/asset/getContentTags'
import MutationCreateApp from './../../../../graphQL/appManager/createApp'
import MutationUpdateApp from './../../../../graphQL/appManager/editApp'
import QueryListSettingApps from './../../../../graphQL/appManager/listSettingApps'
import MutationUpdateMedia from './../../../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from './../../../../graphQL/content/updateMediaSettings'
import QueryUserList from '../../../../graphQL/role/listUsers'
import QueryCriticalUserList from './../../../../graphQL/appManager/listCriticalUsers'

import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'
import { getCropImageUrl } from '../../../../util/util'
import CreateAppContainer from './CreateAppContainer'
// dummy commit
class CreateAppModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSaving: false,
      // settings: [],
      isEditVisible: false,
      isCropVisible: false,
      selectedTags: [],
      filteredConfigName: '',
      selectedType: '',
      app: [],
      group: '',
      bundleId: '',
      itemSelected: {},
      isEnable: false,
      isTagCreating: false,
      systemTags: [],
      showWarning: false,
      shouldSave: false,
      streamField: [],
      streamFieldValue: [],
      tempPropertyName: '',
      tempFieldKey: '',
      isValidJson: true,
      shortDescription: '',
      url: '',
      key: '',
      customMeta: [],
      isCritical: false,
      isCriticalChanged: false,
      userSearch: ''
    }
  }

  resetState = () => {
    this.setState({
      settings: [],
      isSaving: false,
      isEditVisible: false,
      isCropVisible: false,
      isShowImageModal: false,
      streamField: [],
      filteredConfigName: '',
      selectedImage: null,
      app: [],
      group: '',
      key: '',
      isEnable: false,
      showWarning: false,
      shouldSave: false,
      ignoreWarning: false,
      tempFieldKey: '',
      tempPropertyName: '',
      isJsonValid: true,
      shortDescription: '',
      isImageModified: false,
      url: '',
      customMeta: [],
      croppedUrl: '',
      isCritical: false,
      isCriticalChanged: false,
      isAssigneeChanged: false,
      assignedUsers: [],
      userSearch: ''
    })
  }

  componentDidMount = () => {
    if (this.props.details && this.props.isFromEdit) {
      const selectedApps = this.props.details.app ? this.props.details.app.map(item => item && item.id) : []
      // const selectedTags = this.props.details.tags ? this.props.details.tags.filter(item => item) : []
      this.setState({
        isEditVisible: this.props.isVisible,
        filteredConfigName: _.get(this.props.details, 'title'),
        key: _.get(this.props.details, 'key'),
        app: this.props.details && selectedApps ? selectedApps : [],
        streamField: this.props.details && this.props.details.fields && this.props.details.fields.length > 0 ? _.sortBy(this.props.details.fields, 'position') : [],
        streamFieldValue: this.props.details && this.props.details.fieldValues && this.props.details.fieldValues.length > 0 ? this.handleFieldsValueCoversion(this.props.details.fieldValues || []) : [],
        selectedImage: this.props.details && this.props.details.widgetPreview ? this.props.details.widgetPreview : null,
        shortDescription: this.props.details && this.props.details.description ? this.props.details.description : '',
        isEnable: false,
        isCritical: this.props.details && this.props.details.isCritical ? this.props.details.isCritical : false
        // customMeta: this.props.details && this.props.details.customMeta && this.props.details.customMeta.length > 0 ? this.props.details.customMeta : []
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.details && !this.state.isSaving && nextProps.isFromEdit) {
      const selectedApps = nextProps.details.app ? nextProps.details.app.map(item => item && item.id) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        filteredConfigName: _.get(nextProps.details, 'name'),
        key: nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && selectedApps ? selectedApps : [],
        streamField: nextProps.details && nextProps.details.fields && nextProps.details.fields.length > 0 ? _.sortBy(nextProps.details.fields, 'position') : [],
        streamFieldValue: nextProps.details && nextProps.details.fieldValues && nextProps.details.fieldValues.length > 0 ? this.handleFieldsValueCoversion(nextProps.details.fieldValues || []) : [],
        selectedImage: nextProps.details && nextProps.details.appIcon ? nextProps.details.appIcon : null,
        shortDescription: nextProps.details && nextProps.details.description ? nextProps.details.description : '',
        url: nextProps.details && nextProps.details.url ? nextProps.details.url : '',
        bundleId: nextProps.details && nextProps.details.bundleId ? nextProps.details.bundleId : '',
        isEnable: false,
        position: (nextProps.details && nextProps.details.position) || null,
        isCritical: nextProps.details && nextProps.details.isCritical ? nextProps.details.isCritical : false,
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : [],
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || []
      }, () => {
        if (nextProps.appMetaFieldList && nextProps.appMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.appMetaFieldList, this.state.customMeta)
        }
      })
    }
    if (nextProps.systemTags) {
      // const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: nextProps.systemTags })
    }
    if (nextProps.appMetaFieldList && nextProps.appMetaFieldList.length > 0 && !nextProps.isFromEdit && !(this.state.customMeta && this.state.customMeta.length)) {
      this.checkForNewMeta(this.props.appMetaFieldList, this.state.customMeta)
    }

    if (nextProps.details && this.props.details && !_.isEqual(nextProps.details.customMeta, this.props.details.customMeta) && !nextProps.isVisible) {
      this.setState({ customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : [] }, () => {
      })
    }

    this.setState({ isEditVisible: nextProps.isVisible })
  };

  resetState=() => {
    this.setState({
      isEditVisible: false,
      filteredConfigName: '',
      key: '',
      app: [],
      streamFieldValue: [],
      streamField: [],
      tempFieldKey: '',
      tempPropertyName: '',
      isJsonValid: true,
      selectedImage: null,
      shortDescription: '',
      isEnable: false,
      url: '',
      customMeta: [],
      bundleId: '',
      position: null,
      croppedUrl: '',
      isCritical: false,
      isCriticalChanged: false,
      isAssigneeChanged: false,
      assignedUsers: [],
      userSearch: ''
    })
  }

  checkForNewMeta=(metaFieldList, customMeta) => {
    let changeExists = false;
    (metaFieldList || []).map(item => {
      const foundMeta = (customMeta || []).find(existingItem => {
        if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
          return existingItem
        }
      })
      if (_.isEmpty(foundMeta)) {
        changeExists = true
      }
    })
    if (changeExists) {
      this.handleAppMetaDetailsChange(metaFieldList, null)
    }
  }

  handleAppMetaDetailsChange = (metaData, value) => {
    const listCustomMetaFields = _.cloneDeep(this.props.appMetaFieldList)
    // const { details } = this.state
    const { customMeta } = this.state
    // const newDetails = _.cloneDeep(details)
    let finalMetaModified = []
    if (value || value === '' || value === false || value === 'false') {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        let newMetaField = {
          config: item,
          value: item && item.default
        }
        if (item.id === metaData.id && item.type === metaData.type) {
          newMetaField.value = item.type === 'STRING' ? (value || '') : value
          delete item.isError
          newMetaField.config = item
        } else {
          const tempItem = (customMeta || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
          if (_.isEmpty(tempItem)) {
            newMetaField.config = item
            newMetaField.value = item.default
          } else {
            newMetaField = tempItem
            newMetaField.value = tempItem.value
          }
        }

        return newMetaField
      })
      finalMetaModified = customMetaModified
    } else if (!value || value === null || value === undefined || value !== false) {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        const foundMeta = (customMeta || []).find(existingMeta => {
          if (existingMeta && existingMeta.config) {
            if (existingMeta.config.id === item.id) { return existingMeta }
          }
        })
        if (_.isEmpty(foundMeta)) {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          return newMetaField
        } else {
          return foundMeta
        }
      })
      finalMetaModified = customMetaModified
    }
    const newCustomMeta = finalMetaModified
    this.setState({ customMeta: newCustomMeta }, () => {
      this.setState({ isEnable: true })
    })
  }

  getAppMetaFields = (metaData, selectedMeta) => {
    const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
    return (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if (customMeta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{customMeta.name}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => this.handleAppMetaDetailsChange(customMeta, value)} />
          </div>
        </div>
      } else if (customMeta.type === 'STRING') {
        return <Input
          // limit={50}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
          handleChange={({ target }) => this.handleAppMetaDetailsChange(customMeta, target.value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      } else if (customMeta.type === 'NUMBER') {
        return <InputNumber
          limit={16}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
          handleChange={(value) => this.handleAppMetaDetailsChange(customMeta, value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      }
    })
  }

  handleFieldsValueCoversion = (fieldValues) => {
    let pageConfigDetails = (fieldValues || []).map(field => {
      let value
      if (field && field.configField && field.configField.type === 'IMAGE') {
        value = field.image ? field.image : ''
      } else if (field && field.configField && field.configField.type === 'BUCKET') {
        value = field.bucket ? field.bucket : ''
      } else {
        value = field.value
      }
      return {
        id: (field && field.configField && field.configField.id) || '',
        isRequired: (field && field.configField && field.configField.isRequired) || false,
        name: (field && field.configField && field.configField.name) || '',
        type: (field && field.configField && field.configField.type) || '',
        useAsTitle: (field && field.configField && field.configField.useAsTitle),
        value: value
      }
    })

    pageConfigDetails = (pageConfigDetails || []).filter(item => item.id)
    return pageConfigDetails
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { selectedTags } = this.state
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'APP_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags, isTagCreating: true })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            this.setState({ selectedTags, isEnable: true, isTagCreating: false })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}content_bank`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags, isEnable: true, isTagCreating: false })
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
        }
      }
    }
    this.setState({ selectedTags, isEnable: true })
  }

  handleTextChange = (event) => {
    const { value } = event.target
    const { isFromEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.filteredConfigName || '')
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isFromEdit) {
      const key = utilityService.slugifyString(value).slice(0, 125).toLocaleUpperCase()
      this.setState({ filteredConfigName: value, key })
    } else {
      this.setState({ filteredConfigName: value, isEnable: true })
    }
    // this.setState({ filteredConfigName: event.target.value, isEnable: true})
  }

  handleURLChange = (event) => {
    const { value } = event.target
    this.setState({ url: value, isEnable: true })
    // this.setState({ filteredConfigName: event.target.value, isEnable: true})
  }

  handleIdChange =(e) => {
    const codeReg = /^[a-zA-Z0-9-.]*$/
    const key = codeReg.test(e.target.value) ? e.target.value.toLocaleUpperCase() : this.state.bundleId
    this.setState({ bundleId: key, isEnable: true })
  }

  handleKeyChange =(e) => {
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(e.target.value) ? e.target.value.toLocaleUpperCase() : this.state.key
    this.setState({ key: key, isEnable: true })
  }

  handleAppChange = (option) => {
    let apps = this.props.appList.filter(item => option.includes(item.id)).map(item => item.id)
    // apps.sort(function (a, b) {
    //   return option.indexOf(a.name) - option.indexOf(b.name)
    // })
    // details.countries = countries

    this.setState({ app: apps, isEnable: true })
  }

  handleUserChange = (option) => {
    let finalOption = (option || []).map(item => item && item.id)
    let assignedUsers = (this.props.userList || []).filter(item => finalOption.includes(item.id)).map(item => item.id)
    // apps.sort(function (a, b) {
    //   return option.indexOf(a.name) - option.indexOf(b.name)
    // })
    // details.countries = countries

    this.setState({ assignedUsers, isEnable: true, isAssigneeChanged: true })
  }

  handleUserSearch=(value) => {
    this.setState({ userSearch: value })
  }

  onItemSelected = (itemSelected) => {
    this.setState({ itemSelected })
  }

  handleSave = () => {
    const { isFromEdit, maxPosition } = this.props
    const { filteredConfigName, key, streamField, selectedImage, isValidJson, shortDescription, isImageModified, url, bundleId, customMeta, position, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged } = this.state
    if (!isValidJson) {
      message.warn('Please enter valid JSON Value')
      return
    }
    const notValidField = ((streamField || []).find(item => !item.name || item.name === '' || item.name === null))
    if (!_.isEmpty(notValidField)) {
      message.warn('Please enter valid Property Name for all properties before saving')
      return
    }

    // let duplicateItem;
    // (streamField || []).some(field => {
    //   duplicateItem = (streamField || []).find(innerField => ((innerField.name === field.name && innerField.type === field.type) && field.id !== innerField.id))
    //   if (!_.isEmpty(duplicateItem)) {
    //     return true
    //   }
    // })
    // if (!_.isEmpty(duplicateItem)) {
    //   message.error('Please remove duplicate Properties before saving')
    //   return
    // }

    // const stringField = (streamField || []).find(item => item.type === 'STRING')
    // if (_.isEmpty(stringField)) {
    //   message.warn('Please add atleast one string type property')
    //   return
    // } else {
    //   const useAsTitleSet = (streamField || []).find(item => {
    //     if (item && item.type === 'STRING' && item.useAsTitle) {
    //       return item
    //     }
    //   })
    //   if (_.isEmpty(useAsTitleSet)) {
    //     message.warn('Please set a useAsTitle for String field')
    //     return
    //   } else {
    //     if (!useAsTitleSet.isRequired) {
    //       message.warn('Please set a useAsTitle for String field as mandatory')
    //       return
    //     }
    //   }
    // }

    const newCustomMeta = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item.config && item.config.id) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      }
      return newItem
    })

    const newPosition = maxPosition + 1

    this.setState({ isLoading: true })
    // this.props.handleSubmit(newTitle, key, selectedType)

    // const apps = (app || []).map(item => item.id)
    // const newFieldValues = (streamFieldValue || []).map(fieldValue => {
    //   const newObject = {
    //     configField: fieldValue.id,
    //     image: fieldValue.type === 'IMAGE' && fieldValue.value ? fieldValue.value.id : '',
    //     bucket: fieldValue.type === 'BUCKET' && fieldValue.value ? fieldValue.value.id : '',
    //     value: fieldValue.type !== 'BUCKET' && fieldValue.type !== 'IMAGE' ? fieldValue.value : '',
    //     project: this.props.project
    //   }
    //   return newObject
    // })
    // const newStreamFields = (streamField || []).map(field => {
    //   const newField = { ...field }
    //   delete newField['__typename']
    //   if (newField.hasOwnProperty('value')) {
    //     delete newField['value']
    //   }
    //   return newField
    // })

    if (isFromEdit) {
      if (isImageModified) {
        this.props.updateImage(selectedImage.id, selectedImage.name, selectedImage.tags ? selectedImage.tags.map(item => item.key || item.id) : [], selectedImage.settings).then((response) => {
          this.props.updateApp(filteredConfigName, key, this.props.selectedApp.id, selectedImage.id, shortDescription, url, bundleId, newCustomMeta, position, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged).then(() => {
            message.success('App updated successfully')
            this.setState({ isLoading: false, isEnable: false, isImageModified: false, croppedUrl: '', selectedImage: null })
            // this.props.reCreatePageTree()
            this.props.onHistoryUpdate()
            this.handleCancel()
          }, error => {
            utilityService.handleError(error)
            this.setState({ isLoading: false, isEnable: false })
            this.handleCancel()
          })
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false, isEnable: false })
          this.handleCancel()
        })
      } else {
        this.props.updateApp(filteredConfigName, key, this.props.selectedApp.id, selectedImage && selectedImage.id, shortDescription, url, bundleId, newCustomMeta, position, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged).then(() => {
          message.success('App updated successfully')
          this.setState({ isLoading: false, isEnable: false })
          // this.props.reCreatePageTree()
          this.props.onHistoryUpdate()
          this.handleCancel()
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false, isEnable: false })
          this.handleCancel()
        })
      }
    } else {
      this.props.createApp(filteredConfigName, key, selectedImage && selectedImage.id, shortDescription, url, bundleId, newCustomMeta, newPosition, isCritical, assignedUsers).then((res) => {
        if (res && res.data && res.data.createApp) {
          if (isImageModified) {
            const mediaId = res.data.createApp.appIcon ? res.data.createApp.appIcon.id : null
            const widgetId = res.data.createApp.id
            const name = res.data.createApp.appIcon ? res.data.createApp.appIcon.name : null
            const tags = res.data.createApp.appIcon ? res.data.createApp.appIcon.tags : null
            const settings = (selectedImage.settings).map((item) => {
              if (item.__typename) {
                delete (item.__typename)
              }
              return item
            })
            this.props.updateImageSettings(mediaId, widgetId)
            this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then((response) => {
              // this.props.handleSubmit(data.createAssetType)
              this.props.onHistoryUpdate()
              this.setState({ selectedImage: null, croppedUrl: '', isImageModified: false })
            }, error => {
              this.setState({ isLoading: false })
              utilityService.handleError(error)
            })
          }
          message.success('App created successfully')
          this.setState({ isLoading: false, isEnable: false })
          this.props.onHistoryUpdate()
          setTimeout(() => {
            this.props.refetchNewAppList()
          }, 1500)
          // this.props.onSelectConfigGroup(null, null, res.data.createAppConfig.id)
          this.handleCancel()
        }
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    }
  }

  handleCancel = () => {
    this.resetState()
    this.props.hideAppModal()
  }

  forceSave=() => {
    this.setState({ shouldSave: true, ignoreWarning: true, showWarning: false }, () => this.handleSave())
  }

  discardChanges=() => {
    this.setState({ showWarning: false })
  }

  // reorder = (startIndex, endIndex) => {
  //   const { streamField } = this.state
  //   const result = Array.from(streamField)
  //   const [removed] = result.splice(startIndex, 1)
  //   result.splice(endIndex, 0, removed)
  //   this.setState({ streamField: result, isEnable: true })
  // }

  reorder = (startIndex, endIndex) => {
    const { streamField } = this.state
    const result = Array.from(streamField)
    result[startIndex].position = endIndex
    result[endIndex].position = startIndex
    const [removed] = result.splice(startIndex, 1)
    removed.trackPosChange = true
    result.splice(endIndex, 0, removed)
    result.map((item, index) => {
      item.position = index + 1
      return item
    })
    // this.props.handleRatingChange(result)
    this.setState({ streamField: result, isEnable: true })
  }

  onCloseField = (index) => {
    const { streamField } = this.state
    // const index = streamField.findIndex(item => item.id === id)
    streamField.splice(index, 1)
    const duplicateItem = streamField.filter(item => {
      const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
      return tempIndex !== -1
    })
    const isNoDuplicate = !!duplicateItem.length
    this.setState({ streamField, isEnable: true, isNoDuplicate })
  }

  addFieldObjects = () => {
    const { streamField, streamFieldValue, tempFieldKey, tempPropertyName } = this.state
    const tempTrimmed = tempPropertyName.trim()
    const id = uuidv4()
    if (!tempFieldKey || !tempTrimmed) {
      message.error('Please enter valid property name and type')
      return
    }
    const duplicateItem = streamField.find(item => item.key === tempFieldKey)
    const newFieldObject = {
      name: tempTrimmed,
      key: tempFieldKey,
      isEnable: true,
      id
      // useAsTitle: false,
      // position: streamField && streamField.length ? streamField[streamField.length - 1].position + 1 : 1
    }
    // const newFieldObjectValue = {
    //   configField: newFieldObject,
    //   value: null,
    //   bucket: '',
    //   image: ''
    // }
    if (!_.isEmpty(duplicateItem)) {
      message.error('Duplicate Key')
      return
    }
    streamField.push(newFieldObject)
    newFieldObject.value = null
    streamFieldValue.push(newFieldObject)
    // fieldValues.push(newFieldObjectValue)
    this.setState({ streamField, streamFieldValue, isEnable: true, tempFieldKey: null, tempPropertyName: '' })
  }

  onChangePropertyName = (event, index) => {
    const { streamField } = this.state
    const { value } = event.target
    const duplicateItem = streamField.find(item => (item.name === value.trim() && item.key === streamField[index].key))
    streamField[index].name = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.key === streamField[index].key) {
      message.error('Duplicate Key')
      this.setState({ streamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField, isEnable: true, isNoDuplicate: false })
    }
  }

  onKeySelect = (event, index) => {
    const { streamField } = this.state
    const { value } = event.target
    const duplicateItem = streamField.find(item => item.name === streamField[index].name && item.key === value)
    streamField[index].key = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.key === value) {
      message.error('Duplicate Key')
      this.setState({ streamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField, isEnable: true, isNoDuplicate: false })
    }
  }

  handleTempPropertyNameChange=(event) => {
    this.setState({ tempPropertyName: event.target.value })
  }

  handleTempFieldKeyChange=(event) => {
    this.setState({ tempFieldKey: event.target.value })
  }

onChangeAppStatus = (value) => {
  // if (this.props.isCriticalDisabled) {
  //   message.warn('You do not have permission to update critical flag')
  //   return
  // }
  this.setState({ isCritical: value, isEnable: true, isCriticalChanged: true })
}

onChangeIsEnabled=(status, index) => {
  const { streamField } = this.state
  // const { value } = event.target
  //   for (let i = 0; i < streamField.length; i++) {
  //     // streamField[i].useAsTitle = false
  //     if (streamField[i].id === value) {
  //       streamField[i].useAsTitle = true
  //     } else {
  //       streamField[i].useAsTitle = false
  //     }
  //   }
  streamField[index].isEnable = status
  this.setState({ streamField, isEnable: true })
}

showImageModal = (isShowImageModal, isFromDraft) => {
  this.setState({ isShowImageModal, isFromDraft })
}

hideImageModal=() => {
  this.setState({ isShowImageModal: false })
}

showCropModal = (images) => {
  let { selectedImage } = _.cloneDeep(this.state)
  if (images) {
    selectedImage = _.cloneDeep(images)
  }
  const aspectRatio = selectedImage.aspectRatio.find(item => item.title === '1:1')
  const settings = selectedImage.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
  this.setState({ selectedImage, settings, aspectRatio, isFromDraft: false }, () => this.setState({ isShowCropModal: true }))
}

saveCurrentCrop = (settings) => {
  const image = _.cloneDeep(this.state.selectedImage)
  const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
  const currentaspectId = currentAspectRatio.aspectRatio
  const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
  // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
  image.settings.map(setting => {
    setting.outputFormat = 'JPG'
    delete setting[ '__typename' ]
  })
  image.settings[changedIndex].x1 = settings.x1
  image.settings[changedIndex].x2 = settings.x2
  image.settings[changedIndex].y1 = settings.y1
  image.settings[changedIndex].y2 = settings.y2
  // this.props.updateImage(image.id, image.name, tags, image.settings)
  const croppedUrl = getCropImageUrl(image, settings)
  this.setState({ isShowCropModal: false, isShowCreateAssetTypeModal: true, croppedUrl, selectedImage: image, isImageModified: true, isEnable: true })
}

toggleCropModal = (isShowCropModal) => {
  this.setState({ isShowCropModal })
}

saveCurrentSelection = (images) => {
  const { isFromDraft } = this.state
  if (isFromDraft) { this.setState({ selectedDraftImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true, isFromDraft: false }) } else { this.setState({ selectedImage: images[0], isShowImageModal: false, isShowCreateAssetTypeModal: true, isEnable: true }) }
}

clearSelection = (isFromDraft) => {
  if (isFromDraft) { this.setState({ selectedDraftImage: null, croppedDraftUrl: '' }) } else { this.setState({ selectedImage: null, croppedUrl: '', isEnable: true }) }
}

isValidJson = (json) => {
  try {
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}

handleShortDescriptionChange = (e) => {
  const { value } = e.target
  this.setState({ shortDescription: value, isEnable: true })
}

onInputFieldFocus = () => {
  this.isEditingInput = true
}

onInputFieldBlur = (e) => {
  if (!this.isEditingInput) {
    return
  }
  const { filteredConfigName, slug } = this.state
  const defaultSlug = utilityService.slugifyString(`untitled`)
  const isFirstSlug = slug && (slug.indexOf('untitled-') === 0 || slug.indexOf(defaultSlug) === 0)
  if (isFirstSlug && filteredConfigName !== '') {
    const newTitle = `${filteredConfigName.toLowerCase()}`
    const slug = utilityService.slugifyString(newTitle)
    // details.slug = slug
    this.setState({ slug: slug })
  }
  this.isEditingInput = false
}

render () {
  const { details, typeList, appList, groupList, isUploadBlocked, isCreateDisabled, isUpdateBlocked, project, isFromEdit, appClient, appSearchText, handleAppSearchTextChange, appMetaFieldList, userList, criticalUserList, isAppAdminDisabled } = this.props
  const { selectedTags, filteredConfigName, bundleId, isSaving, app, key, selectedType, url, group, streamField, tempFieldKey, tempPropertyName, isEnable, isLoading, croppedUrl, selectedImage, isShowImageModal, streamFieldValue, isShowCropModal, aspectRatio, settings, shortDescription, assignedUsers, isCritical, userSearch } = this.state
  const finalIsEnable = !isFromEdit ? !!(filteredConfigName && key) : !!(isEnable && filteredConfigName && key)
  const finalUserList = isCritical ? criticalUserList : userList
  const container = this.props.isVisible ? (
    <CreateAppContainer
      details={_.cloneDeep(details)}
      objectDetails={streamFieldValue || []}
      streamField={streamField}
      selectedTags={selectedTags || []}
      name={filteredConfigName}
      description={shortDescription}
      selectedKey={bundleId}
      isCritical={isCritical}
      appKey={key}
      selectedType={selectedType}
      handleURLChange={this.handleURLChange}
      handleAppChange={this.handleAppChange}
      handleTextChange={this.handleTextChange}
      handleKeyChange={this.handleKeyChange}
      url={url}
      typeList={typeList}
      appList={appList}
      groupList={groupList}
      selectedApp={app}
      isUploadBlocked={isUploadBlocked}
      selectedGroup={group}
      addFieldObjects={this.addFieldObjects}
      handleTempPropertyNameChange={this.handleTempPropertyNameChange}
      handleTempFieldTypeChange={this.handleTempFieldKeyChange}
      tempFieldKey={tempFieldKey}
      tempPropertyName={tempPropertyName}
      onChangePropertyName={this.onChangePropertyName}
      onOptionSelect={this.onKeySelect}
      onChangeAppStatus={this.onChangeAppStatus}
      handleIdChange={this.handleIdChange}
      onCloseField={this.onCloseField}
      onChangeIsEnabled={this.onChangeIsEnabled}
      reorder={this.reorder}
      isFromEdit={isFromEdit}
      appClient={appClient}
      project={project}
      showImageModal={(isFromDraft) => this.showImageModal(true, isFromDraft)}
      hideImageModal={this.hideImageModal}
      icon={selectedImage}
      isShowImageModal={isShowImageModal}
      croppedUrl={croppedUrl}
      saveCurrentSelection={this.saveCurrentSelection}
      clearSelection={this.clearSelection}
      showCropModal={(image, isFromDraftIcon) => isFromDraftIcon ? this.showDraftCropModal(image) : this.showCropModal(image)}
      isShowCropModal={isShowCropModal}
      saveCurrentCrop={this.saveCurrentCrop}
      toggleCropModal={this.toggleCropModal}
      aspectRatio={aspectRatio}
      settings={settings}
      handleShortDescriptionChange={this.handleShortDescriptionChange}
      onInputFieldBlur={this.onInputFieldBlur}
      onInputFieldFocus={this.onInputFieldFocus}
      handleAppSearchTextChange={handleAppSearchTextChange}
      appSearchText={appSearchText}
      appMetaFields={this.getAppMetaFields(this.state.customMeta, appMetaFieldList)}
      userList={finalUserList}
      handleUserChange={this.handleUserChange}
      userSearch={userSearch}
      assignedUsers={assignedUsers}
      isAppAdminDisabled={isAppAdminDisabled}
      handleUserSearch={this.handleUserSearch}
    />
  ) : <div />
  return (
    <div>
      <Modal
        className='confirm-modal edit-image'
        title={details ? 'EDIT APP' : 'CREATE NEW APP'}
        visible={this.props.isVisible}
        okText='Save'
        okButtonProps={{ disabled: (!finalIsEnable || (isUpdateBlocked && isCreateDisabled)), loading: isLoading }}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        closable={false}
        confirmLoading={isSaving}
        destroyOnClose
        maskClosable={false}
        centered
        width='890px'
      >
        {container}
      </Modal>
    </div>
  )
}
}

CreateAppModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** id of the selected image */
  imageId: PropTypes.string,
  /**  */
  createTag: PropTypes.func,
  /**  */
  tagsList: PropTypes.array,
  /** Callback for save content */
  updateImage: PropTypes.func,
  /**  */
  handleSuccess: PropTypes.func,
  /**  */
  mediaDetails: PropTypes.object,
  /** Callback to hide image editor */
  hideImageEditor: PropTypes.func
}

CreateAppModal.defaultProps = {}

export default withApollo(
  compose(
    // graphql(
    //   QueryAllTags,
    //   {
    //     options: ({ tagSearchString }) => {
    //       return {
    //         variables: { name: '' }
    //       }
    //     },
    //     props: ({ data: { listTags = { items: [] } } }) => {
    //       return { tagsList: [ ...listTags.items ] }
    //     }
    //   }
    // ),
    // graphql(
    //   QueryMediaDetails,
    //   {
    //     options: ({ imageId }) => {
    //       return {
    //         variables: { id: imageId }
    //       }
    //     },
    //     skip: ({ imageId }) => !(imageId && imageId.length),
    //     props: ({ data }) => {
    //       return { mediaDetails: _.cloneDeep(data.getMedia) }
    //     }
    //   }
    // ),
    graphql(MutationCreateApp, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { createApp } }) => {
            // let variables = utilityService.getFormattedWidgetSettingFilter('', widgetSearchValue, project)
            let variables = { limit: 499, project: project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListSettingApps, variables: variables }))
            if (cacheData && cacheData.listApps && cacheData.listApps.items) { cacheData.listApps.items.push(createApp) }
            cache.writeQuery({
              query: QueryListSettingApps,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        createApp: (name, key, appIcon, description, url, bundleId, customMeta, position, isCritical, assignedUsers) => {
          return props.mutate({
            variables: { name, key, appIcon, description, url, bundleId, project: props.ownProps.project, customMeta, position, isCritical, assignedUsers }
          })
        }
      })
    }),
    graphql(MutationUpdateApp, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { editApp } }) => {
            let variables = { limit: 499, project: project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListSettingApps, variables: variables }))
            if (cacheData && cacheData.listApps && cacheData.listApps.items) {
              let foundIndex = (cacheData.listApps.items).findIndex(item => item.id === editApp.id)
              if (foundIndex > -1) {
                // let temp= cacheData.listWidgets.items[foundIndex]
                cacheData.listApps.items[foundIndex] = editApp
                // cacheData.listWidgets.items[foundIndex].totalCount=temp.totalCount
              }
            }
            cache.writeQuery({
              query: QueryListSettingApps,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        updateApp: (name, key, id, appIcon, description, url, bundleId, customMeta, position, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged) => {
          return props.mutate({
            variables: { name, key, project: props.ownProps.project, id, appIcon, description, url, bundleId, customMeta, position, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged }
          })
        }
      })
    }),
    graphql(
      MutationUpdateMedia, {
        props: (props) => ({
          updateImage: (id, name, tags, settings) => {
            return props.mutate({
              variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMediaSettings, {
        props: (props) => ({
          updateImageSettings: (id, assetId, settings) => {
            return props.mutate({
              variables: { id, assetId, settings }
            })
          }
        })
      }
    ),
    graphql(
      QueryUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            userList: data.listUsers && data.listUsers.items && data.listUsers.items.length ? data.listUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listUsers ? data.listUsers.totalCount : 0,
            getMoreUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listUsers.items, ...fetchMoreResult.listUsers.items]
                  prev.listUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    ),
    graphql(
      QueryCriticalUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            criticalUserList: data.listCriticalUsers && data.listCriticalUsers.items && data.listCriticalUsers.items.length ? data.listCriticalUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listCriticalUsers ? data.listCriticalUsers.totalCount : 0,
            getMoreCriticalUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listCriticalUsers.items, ...fetchMoreResult.listCriticalUsers.items]
                  prev.listCriticalUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    )
  )(CreateAppModal)
)
