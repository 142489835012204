import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getPlayerById(id: $id) {
        id
        opId
        firstName
        lastName
    }    
}`)
