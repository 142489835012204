import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, Switch } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import Input from '../inputs/Input'
import AssetTypeList from '../../dataDisplay/AssetTypeList'
import MutationCreateTagType from '../../../../graphQL/admin/tagType/createTagType'
import MutationUpdateTagType from '../../../../graphQL/admin/tagType/updateTagType'
import QueryTagType from './../../../../graphQL/admin/tagType/listTagType'
// import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'

import { utilityService } from '../../../../services/UtilityService'
// const { TabPane } = Tabs

let optionalInitialData = []

// const parentCompoent = 'create-input'

class CreateTagTypeModal extends Component {
    state = {
      isLoading: false,
      enable: false,
      activeKey: '1',
      optionalData: _.cloneDeep(optionalInitialData),
      isNoDuplicate: false,
      details: {
        name: '',
        enabledAssetTypes: [],
        assetTypeList: []
      }
    }

    componentDidMount = () => {
    //   if (this.props.selectedType) {
    //     this.setState({
    //       details: _.cloneDeep(this.props.selectedType)
    //     })
    //   }
    }

    UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
      if (nextProps.listAssetTypes && _.isEqual(nextProps.listAssetTypes, this.props.listAssetTypes)) {
        this.getFormattedAssetTypeList(nextProps.listAssetTypes)
        // this.setState({ listAssetTypes: _.cloneDeep(nextProps.listAssetTypes) })
      }
      if (nextProps.selectedTagType && !_.isEqual(nextProps.selectedTagType, this.props.selectedTagType)) {
        let details1 = _.cloneDeep(this.state.details)
        details1.id = nextProps.selectedTagType.id
        details1.enabledAssetTypes = nextProps.selectedTagType.enabledAssetTypes
        details1.name = nextProps.selectedTagType.name
        details1.key = nextProps.selectedTagType.key
        details1.isCustom = nextProps.selectedTagType.isCustom
        details1.createdAt = nextProps.selectedTagType.createdAt
        details1.updatedAt = nextProps.selectedTagType.updatedAt
        details1.isMediaEnabled = nextProps.selectedTagType.isMediaEnabled
        details1.tagCount = nextProps.selectedTagType.tagCount
        details1.position = nextProps.selectedTagType.position
        this.setState({ details: details1 })
      }
      if (nextProps.isDeleteSuccess) {
        this.resetState()
      }
    }

    getFormattedAssetTypeList=(listAssetTypes) => {
      this.optionalInitialData = (listAssetTypes || []).map(item => {
        const obj = {
          id: item.id,
          title: item.title,
          isEnabled: false,
          icon: item.icon,
          draftIcon: item.draftIcon
        }
        return obj
      })
      let details = _.cloneDeep(this.state.details)
      // details.enabledAssetTypes = this.optionalInitialData
      details.assetTypeList = this.optionalInitialData
      this.setState({ details })
    }

    handleChange =(e) => {
      const { details } = this.state
      const { value, name } = e.target

      if (name === 'name') {
        const regexExp = /^[A-Za-z0-9_-]+$/
        const isCheck = regexExp.test(value)
        if (isCheck || value === '') {
          details.name = value
          this.setState({ details, isBoolEnabled: true })
        }
        // const title = isCheck? value.toUpperCase() : ''
      }
    }

    handleAddTagType = () => {
      const { isEdit, onHistoryUpdate } = this.props
      const { details } = this.state
      this.setState({ isLoading: true })

      if (isEdit) {
        // edit
        const updatedEnabledAssetTypes = (details.enabledAssetTypes || []).map(item => {
          if (item.hasOwnProperty('__typename')) {
            delete item.__typename
          }
          return item
        })
        const newTagType = {
          //  position: details.position,
          id: details.id,
          name: details.name,
          isCustom: details.isCustom,
          enabledAssetTypes: updatedEnabledAssetTypes,
          key: details.key,
          isMediaEnabled: details.isMediaEnabled,
          position: details.position
        }
        this.props.updateTagType(newTagType).then(response => {
          this.props.handleSubmit(response.data.updateTagType)
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        // create
        const newTagType = {
          name: details.name,
          id: details.id,
          enabledAssetTypes: details.enabledAssetTypes,
          key: details.name.toLowerCase(),
          isMediaEnabled: details.isMediaEnabled,
          position: this.props.maxPosition + 1
        }
        this.props.createTagType(newTagType).then(({ data }) => {
          message.success('Tagtype created successfully')
          this.props.handleSubmit(data.createTagType)
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    onCloseField = (index) => {
      const { streamField } = this.state
      // const index = streamField.findIndex(item => item.id === id)
      streamField.splice(index, 1)
      const duplicateItem = streamField.filter(item => {
        const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
        return tempIndex !== -1
      })
      const isNoDuplicate = !!duplicateItem.length
      this.setState({ streamField, isBoolEnabled: true, isNoDuplicate })
    }

    isEnableSave = () => {
      this.setState({ enable: true })
    }

      onChangeData = (assetTypeName, status) => {
        const { details } = this.state
        // const { enabledAssetTypes } = details
        // let modifiedAssetTypeList = (enabledAssetTypes || []).map(item => {
        //  if (item.id && assetTypeId && item.id === assetTypeId) {
        //    item.isEnabled = status
        //  }
        //  return item
        // })
        // details.enabledAssetTypes = modifiedAssetTypeList
        let enabledAssetTypes = _.cloneDeep(details.enabledAssetTypes)
        const index = (enabledAssetTypes || []).findIndex(assetTypeItem => assetTypeItem.assetType === assetTypeName)
        if (index > -1) {
          enabledAssetTypes.splice(index, 1)
        } else {
          let newAssetType = {
            // _typename: 'TagAssetType',
            assetType: assetTypeName
          }
          if (!enabledAssetTypes) {
            enabledAssetTypes = []
            enabledAssetTypes.push(newAssetType)
          } else {
            enabledAssetTypes.push(newAssetType)
          }
        }
        details.enabledAssetTypes = enabledAssetTypes
        this.setState({ details, isBoolEnabled: true })
      }

      onOptionSelect = (value, index) => {
        const { streamField } = this.state
        streamField[index].type = value
        const duplicateItem = streamField.find(item => item.displayName === streamField[index].displayName && item.id !== streamField[index].id)
        if (!_.isEmpty(duplicateItem) && duplicateItem.type === value) {
          message.error('Duplicate Meta Data')
          this.setState({ streamField, isBoolEnabled: false, isNoDuplicate: true })
        } else {
          this.setState({ streamField, isBoolEnabled: true, isNoDuplicate: false })
        }
      }

      onChangeEnableMedia=(status) => {
        const { details } = this.state
        details.isMediaEnabled = status
        this.setState({ details, isBoolEnabled: true })
      }

    resetState = () => {
      const state = {
        isLoading: false,
        enable: false,
        activeKey: '1',
        isBoolEnabled: false,
        isNoDuplicate: false,
        details: {
          name: '',
          enabledAssetTypes: [],
          assetTypeList: []

        }
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, isEdit, isSubmitDisabled } = this.props
      const { isLoading, optionalData, details, isBoolEnabled } = this.state
      const disabled = !details.name || (!isBoolEnabled)
      const title = <div className='title'>
        <span>{isEdit ? 'EDIT TAG TYPE' : 'NEW TAG TYPE'}</span>
      </div>
      // const uniqDataArr = _.uniqBy((streamField || []), asset => [asset.type, asset.displayName].join())
      // const isDuplicate = uniqDataArr.length !== (streamField || []).length
      return (
        <Modal
          className='general-modal create-tag-type-container'
          title={title}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddTagType}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled) || isSubmitDisabled, loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-tag-type'>
            <div className='create-input' id='create-input'>

              <div className='tag-type-config-container'>
                <div className='tag-type-input-container'>
                  <Input title='Tag Type Name' inputName='name' isRequired handleChange={this.handleChange} value={details.name} placeholder='Enter new tag type' limit={50} readOnly={details.tagCount} />
                  <div className='meta-data-switch'>
                    <span>{'Enable for Media'}</span>
                    <Switch checked={details.isMediaEnabled} onChange={this.onChangeEnableMedia} />
                  </div>
                </div>

              </div>
            </div>
            <AssetTypeList optionList={optionalData} onChange={this.onChangeData} details={details} />
          </div>
        </Modal>
      )
    }
}

CreateTagTypeModal.propTypes = {
  /** visible file status of CreateAssetTypeModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateAssetTypeModal. */
  handleCancel: PropTypes.func
}

CreateTagTypeModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateTagType, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { createTagType } }) => {
            const variables = { filter: null, limit: 500, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryTagType, variables }))
            if (cacheData && cacheData.listTagType && cacheData.listTagType.items) {
              cacheData.listTagType.items.push(createTagType)
            }
            cache.writeQuery({
              query: QueryTagType,
              data: cacheData,
              variables
            })
          },
          refetchQueries: () => [{ query: QueryTagType,
            variables: { limit: 500,
              project,
              offset: null,
              filter: null } }]
        }
      },
      props: (props) => ({
        createTagType: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateTagType, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { editTagType } }) => {
            const variables = { filter: null, limit: 500, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryTagType, variables }))
            if (cacheData && cacheData.listTagType && cacheData.listTagType.items) {
              const selectedIndex = cacheData.listTagType.items.findIndex(item => item.id === editTagType.id)
              if (selectedIndex > -1) {
                cacheData.listTagType.items[selectedIndex] = editTagType
              }
            }
            cache.writeQuery({
              query: QueryTagType,
              data: cacheData,
              variables
            })
          }
          // refetchQueries: () => [{ query: QueryTagType,
          //   variables: { limit: 500,
          //     offset: null,
          //     filter: null,
          //     project
          //     } }]
        }
      },
      props: (props) => ({
        updateTagType: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateTagTypeModal))
