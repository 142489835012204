import React from 'react'
import PropTypes from 'prop-types'

const FacebookIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='#AFAFAF' fillRule='evenodd' d='M27.273 0H2.727A2.727 2.727 0 0 0 0 2.727v24.546A2.727 2.727 0 0 0 2.727 30h13.637V17.727h-4.091v-4.09h4.09V11.44c0-4.16 2.027-5.985 5.484-5.985 1.655 0 2.53.122 2.945.178v3.912h-2.357c-1.468 0-1.98.775-1.98 2.343v1.748h4.3l-.583 4.091h-3.717V30h6.818A2.727 2.727 0 0 0 30 27.273V2.727A2.727 2.727 0 0 0 27.273 0z' />
  </svg>
)

FacebookIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

FacebookIcon.defaultProps = {
  width: 30,
  height: 30
}

export default FacebookIcon
