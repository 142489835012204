import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share'

import './../ui.style.scss'

const AssetShare = ({ shareUrl, ...props }) => {
  return (
    <div className='asset-share'>
      <FacebookShareButton url={shareUrl}>
        <FacebookIcon size={40} round />
      </FacebookShareButton>
      <LinkedinShareButton url={shareUrl} >
        <LinkedinIcon size={40} round />
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl}>
        <TwitterIcon size={40} round />
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl} >
        <WhatsappIcon size={40} round />
      </WhatsappShareButton>
      <EmailShareButton url={shareUrl} >
        <EmailIcon size={40} round />
      </EmailShareButton>
    </div>
  )
}

AssetShare.propTypes = {
  /** Title of AssetShare. */
  shareUrl: PropTypes.string
}

export default AssetShare
