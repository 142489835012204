import gql from 'graphql-tag'

export default gql(`
    query listDisplayType($limit: Int $project: String){
        listDisplayType( 
            limit: $limit
            project: $project
            ) {
        items {
            id
            name
            key
            usedCount
        }
    }
}`)
