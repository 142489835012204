import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Skeleton, Tabs, message } from 'antd'

// import { graphql, withApollo } from '@apollo/client/react/hoc'
import _ from 'lodash'

import Input from '../inputs/Input'
import ConfigFieldObjects from '../../dataDisplay/ConfigFieldObjects'
import ConfigGroupStreams from '../../dataDisplay/ConfigGroupStreams'
import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

const { TabPane } = Tabs
class CreateConfigGroupModal extends Component {
    state = {
      configName: undefined,
      streamField: [],
      enable: false,
      streams: []
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedConfigGroup && (!_.isEqual(this.props.selectedConfigGroup, nextProps.selectedConfigGroup) || (!this.state.configName))) {
        const streamField = nextProps.selectedConfigGroup && nextProps.selectedConfigGroup.fields && nextProps.selectedConfigGroup.fields.length ? nextProps.selectedConfigGroup.fields : []
        this.setState({
          configName: nextProps.selectedConfigGroup.name,
          streamField: streamField.map(item => {
            if (!item.id) { item.id = utilityService.makeRandomString(6) }
            return item
          }),
          streams: nextProps.selectedConfigGroup && nextProps.selectedConfigGroup.streams && nextProps.selectedConfigGroup.streams.length ? nextProps.selectedConfigGroup.streams : []
        })
      } else if (!nextProps.selectedConfigGroup && (!_.isEqual(this.props.selectedConfigGroup, nextProps.selectedConfigGroup))) {
        this.setState({
          configName: '',
          streamField: [],
          streams: []
        })
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        // const regex = /^[a-zA-Z0-9 ]*$/
        // const newVal = value.replace(/-/g, ' ')
        if (name === 'name') {
          this.setState({ configName: value, isNameError: false }, this.isEnableSave)
        }
      }
    }

    handleAddConfigGroup = () => {
      const { configName, streamField, streams } = this.state
      const { selectedConfigGroup, isDuplicate } = this.props
      const regex = /^[a-zA-Z0-9 ]*$/
      if (!regex.test(configName)) {
        this.setState({ isNameError: true })
        message.warn('Please remove special characters from config name')
        return
      }
      let newStream = {}
      const emptyStreamField = (streams || []).filter(item => (!item.levels || !item.levels.length))
      if (emptyStreamField.length) {
        message.error('Please fill the incomplete streams')
        return
      }
      const isInvalidFields = (streamField || []).filter(item => !item.name || !item.type)
      if (isInvalidFields.length) {
        message.error('Please fill the incomplete fields')
        return
      }
      if (selectedConfigGroup && isDuplicate) {
        // Duplicate
        newStream = {
          id: selectedConfigGroup.id,
          name: configName,
          fields: streamField,
          streams
        }
      } else if (selectedConfigGroup && !isDuplicate) {
        // edit
        newStream = {
          id: selectedConfigGroup.id,
          name: configName,
          fields: streamField,
          streams
        }
      } else {
        // create
        newStream = {
          name: configName,
          fields: streamField,
          streams
        }
      }
      this.props.handleSubmit(newStream, !isDuplicate)
    }

    reorder = (startIndex, endIndex) => {
      const { streamField } = this.state
      const result = Array.from(streamField)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      this.setState({ streamField: result }, this.isEnableSave)
    }

    onCloseField = (index) => {
      const { streamField } = this.state
      // const index = streamField.findIndex(item => item.id === id)
      streamField.splice(index, 1)
      this.setState({ streamField }, this.isEnableSave)
    }

    onCloseStreamLevels = (id) => {
      const { streams } = this.state
      const index = streams.findIndex(item => item.id === id)
      streams.splice(index, 1)
      this.setState({ streams }, this.isEnableSave)
    }

    addFieldObjects = () => {
      const { streamField } = this.state
      const randomId = utilityService.makeRandomString(6)
      const newFieldObject = {
        name: '',
        type: '',
        isRequired: false,
        id: `${randomId}`
      }
      streamField.push(newFieldObject)
      this.setState({ streamField }, this.isEnableSave)
    }

    addStreamLevelObjects = () => {
      const { streams } = this.state
      const randomId = utilityService.makeRandomString(6)
      const newStreamLevelObject = {
        name: '',
        group: '',
        isRequired: false,
        id: `${randomId}`,
        levels: []
      }
      streams.push(newStreamLevelObject)
      this.setState({ streams }, this.isEnableSave)
    }

    onOptionSelect = (value, index) => {
      const { streamField } = this.state
      // details = streamField.find(item => item.index === index)
      streamField[index].type = value
      // return details.type = value
      // details.type = value
      this.setState({ streamField }, this.isEnableSave)
    }

    onChangeStatus = (e, index) => {
      const { streamField } = this.state
      const { checked } = e.target
      streamField[index].isRequired = checked
      this.setState({ streamField }, this.isEnableSave)
      // this.setState({ details },  this.props.onSelect(details))
    }

    handleFieldChange =(e, index) => {
      const { streamField } = this.state
      const { value } = e.target
      // if (!value.startsWith(' ')) {
      streamField[index].name = value
      this.setState({ streamField }, this.isEnableSave)
      // }
    }

    onSelect = (selectedOption, id) => {
      const { streams } = this.state
      const stream = streams.find(item => item.id === id)
      const index = (stream.levels || []).findIndex(item => item.name === selectedOption.name)
      if (index > -1) {
        stream.levels.splice(index, 1)
      } else {
        const newLevel = {
          name: selectedOption.name,
          field: [],
          id: selectedOption.id
        }
        stream.levels.push(newLevel)
      }
      this.setState({ streams }, this.isEnableSave)
    }

    isEnableSave = () => {
      this.setState({ enable: true })
    }

    resetState = () => {
      const state = {
        configName: '',
        streamField: [],
        streams: [],
        enable: false,
        isNameError: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, isDuplicate, selectedConfigGroup, channelStreamList, isLoading, isSubmitDisabled } = this.props
      const { configName, streamField, enable, streams, isNameError } = this.state
      const isStreamPresent = streams && streams.length && streams.filter(item => item.levels.length).length
      const disabled = enable && configName && isStreamPresent
      return (
        <Modal
          className='general-modal create-config-group-modal'
          title={selectedConfigGroup ? isDuplicate ? 'DUPLICATE CONFIG GROUP' : 'EDIT CONFIG GROUP' : 'NEW CONFIG GROUP'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddConfigGroup}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !disabled || isSubmitDisabled, loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-config'>
            <Input title='Config Group Name' isRequired inputName='name' handleChange={this.handleChange} value={configName} placeholder='Insert Config Group Name' isError={isNameError} />
            <div className='tab-details'>
              <Tabs>
                <TabPane tab='Streams' key='1'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 4 }} loading={false}>
                    <ConfigGroupStreams streams={streams} onCloseStreamLevels={this.onCloseStreamLevels} addStreamLevelObjects={this.addStreamLevelObjects}
                      configName={configName} onSelect={this.onSelect} channelStreamList={channelStreamList} />
                  </Skeleton>
                </TabPane>

                <TabPane tab='Field Objects' key='2'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 4 }} loading={false}>
                    <ConfigFieldObjects reorder={this.reorder} onCloseField={this.onCloseField} streamField={streamField} isEnableSave={this.isEnableSave}
                      addFieldObjects={this.addFieldObjects} configName={configName} onOptionSelect={this.onOptionSelect} onChangeStatus={this.onChangeStatus} handleChange={this.handleFieldChange} />
                  </Skeleton>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </Modal>
      )
    }
}

CreateConfigGroupModal.propTypes = {
  /** visible file status of CreateConfigGroupModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateConfigGroupModal. */
  handleCancel: PropTypes.func
}

CreateConfigGroupModal.defaultProps = {
}

export default CreateConfigGroupModal
