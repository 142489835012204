import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import DropDownIcon from './../../general/icons/DropDownIcon'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import GetSortOptions from './../../../../graphQL/filter/getSort'

const Option = Select.Option

const optionListForJobManager = [
  {
    displayName: 'Relevance',
    name: 'relevance'
  },
  {
    displayName: 'Created at',
    name: 'createdAt'
  },
  {
    displayName: 'Updated At',
    name: 'updatedAt'
  },
  {
    displayName: 'Date of Match',
    name: 'matchDate'
  }
]

const optionListForOfferManager = [
  {
    displayName: 'CreatedAt',
    name: 'createdAt'
  },
  {
    displayName: 'Sort By Name',
    name: 'name'
  },
  {
    displayName: 'Sort By Start Date',
    name: 'startDate'
  },
  {
    displayName: 'Sort By End Date',
    name: 'endDate'
  }
]

const optionListForPartnerManager = [
  {
    displayName: 'Sort By Name',
    name: 'name'
  }
]

class SortInput extends React.Component {
    state = {
      order: this.props.selectedOrder,
      value: this.props.selectedSort
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.selectedSort !== this.state.value) {
        this.setState({ value: newProps.selectedSort })
      }
      if (newProps.selectedOrder !== this.state.order) {
        this.setState({ order: newProps.selectedOrder })
      }
    }

    onChangeSortValue = value => {
      this.setState({ value }, () => this.props.onSortChange(this.state))
    }

    onChangeSortOrder = () => {
      this.setState({ order: this.state.order === 'asc' ? 'desc' : 'asc' }, () => this.props.onSortChange(this.state))
    }

    renderDropDown = () => {
      const { parent, manager } = this.props
      const { value } = this.state
      let optionList
      if (!manager) {
        optionList = this.props.optionList && this.props.optionList.length ? this.props.optionList : optionListForJobManager
      } else if (manager && manager === 'offer' && manager !== 'partner') {
        optionList = optionListForOfferManager
      } else if (manager && manager === 'partner' && manager !== 'offer') {
        optionList = optionListForPartnerManager
      }
      const selected = optionList && optionList.length && value ? (optionList || []).find((item) => item.name === value).displayName : ''
      return optionList && optionList.length ? optionList.length === 1 ? <span className='drop-down sort-single'>{selected}</span> : <Select className='drop-down sort-drop-down' suffixIcon={<DropDownIcon color='#000' />} value={selected} getPopupContainer={() => document.getElementById(parent)}
        onChange={this.onChangeSortValue}>
        {(optionList || optionListForJobManager).filter(item => !(value && item.name === value)).map((option, index) => <Option key={index} value={option.name}>{option.displayName}</Option>)}
      </Select> : null
    }
    render () {
      const { order, value } = this.state
      return (
        <div className='sort-input' >
          <div className='drop-down-container'>
            {this.renderDropDown()}
          </div>
          { (value !== 'relevance') && <div className='order-selector' onClick={this.onChangeSortOrder} >
            <DropDownIcon color={order === 'asc' ? '#ff015a' : '#cfd0d1'} />
            <DropDownIcon color={order === 'desc' ? '#ff015a' : '#cfd0d1'} />
          </div>}
        </div>
      )
    }
}

SortInput.propTypes = {

  /** Sort type of SortInput. */
  type: PropTypes.string,
  /** Parent container for the SortInput. */
  parent: PropTypes.string.isRequired
}

SortInput.defaultProps = {

  parent: 'root'
}

export default withApollo(compose(
  graphql(
    GetSortOptions,
    {
      skip: (props) => {
        return !(props.type && props.type.length)
      },
      options: ({ type, project }) => {
        return {
          variables: { type, project }
        }
      },
      props: (props) => {
        const { data } = props
        const optionList = data.filterDataList && data.filterDataList.length ? data.filterDataList[0].sortableFields : []
        return { optionList }
      }

    }
  )
)(SortInput))
