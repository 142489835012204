import gql from 'graphql-tag'

export default gql(`
    mutation($id: String! $project: String) {
        deleteTag(
            input: {
                key: $id
            }
            project: $project
        ) {
            key
            name
        }
    }`
)
