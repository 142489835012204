import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

import EditAppPageDetails from './EditAppPageDetails'
import SeoSettings from './../../../../modules/appManager/PageSeoSetting'
import './../../ui.style.scss'

import { withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
//
// import QueryImageSettings from '../../../../graphQL/content/getImageSetting'

const { TabPane } = Tabs

class CreateAppPageContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showImageModal: false,
      activeKey: ''
    }
  }
  onSelected = (item) => {
    this.props.selectAspectRatio(item)
  };

  onTabChange=(activeKey) => {
    this.setState({ activeKey: activeKey })
  }

  render () {
    const { selectedTags, selectedApp, selectedGroup, name, handleTextChange,
      handleTagChange, systemTags, handleTypeChange, handleAppChange, handleGroupChange, appList, groupList, typeList, project, onInputFieldBlur, onInputFieldFocus,
      handleSeoDetailsChange, onSeoKeywordChange, handleIdChange, isInvalidSlug, isFromEdit, appClient, handleShortDescriptionChange, shortDescription, slug, seoTitle,
      seoMetaDescription, seoKeywords, selectedKey, pageMetaFields, isDuplicating, isCritical, onChangePageStatus, details, appData, groupData, assignedUsers, handleUserChange, userList, isAppAdminDisabled, handleUserSearch, userSearch } = this.props
    // const { showImageModal, activeKey } = this.state
    return (
      <div id='edit-app-page-modal' className={`edit-app-page-modal`}>
        <Tabs defaultActiveKey='1' onChange={this.onTabChange}>
          <TabPane tab='General' key='1'>
            <EditAppPageDetails
              appClient={appClient}
              isFromEdit={isFromEdit}
              name={name}
              selectedKey={selectedKey}
              shortDescription={shortDescription}
              handleTypeChange={handleTypeChange}
              handleTextChange={handleTextChange}
              handleIdChange={handleIdChange}
              handleTagChange={handleTagChange}
              systemTags={systemTags}
              selectedTags={selectedTags}
              appList={appList}
              groupList={groupList}
              typeList={typeList}
              selectedApp={selectedApp}
              selectedGroup={selectedGroup}
              handleAppChange={handleAppChange}
              isCritical={isCritical}
              onChangePageStatus={onChangePageStatus}
              handleGroupChange={handleGroupChange}
              onInputFieldFocus={onInputFieldFocus}
              onInputFieldBlur={onInputFieldBlur}
              handleShortDescriptionChange={handleShortDescriptionChange}
              pageMetaFields={pageMetaFields}
              project={project}
              isDuplicating={isDuplicating}
              details={details}
              appData={appData}
              groupData={groupData}
              assignedUsers={assignedUsers}
              handleUserChange={handleUserChange}
              userList={userList}
              isAppAdminDisabled={isAppAdminDisabled}
              handleUserSearch={handleUserSearch}
              userSearch={userSearch}
            />
          </TabPane>
          <TabPane tab='SEO' key='2'>
            <SeoSettings
              // assetId={details.id}
              isInvalidSlug={isInvalidSlug}
              details={{ slug: slug, title: seoTitle, metaDescription: seoMetaDescription, keywords: seoKeywords }}
              onInputFieldFocus={onInputFieldFocus}
              onInputFieldBlur={onInputFieldBlur}
              handleSeoDetailsChange={handleSeoDetailsChange}
              onSeoKeywordChange={onSeoKeywordChange}
              defaultTitle={name || ''}
              mode='page'
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

CreateAppPageContainer.propTypes = {
  /** Array of taglist */
  tagsList: PropTypes.array,
  /**  Array of selected tags */
  selectedTags: PropTypes.array,
  /**  Function to handle tag change */
  handleTagChange: PropTypes.func,
  /**  Function to handle video format change */
  handleFormatChange: PropTypes.func,
  /** Function to handle name cahnge */
  handleTextChange: PropTypes.func,
  /** Array of formats  */
  formats: PropTypes.array,
  /**  selected format */
  selectedFormat: PropTypes.string,
  /** Function to be called for updating images for each aspect ratios */
  updateAspectRatioImage: PropTypes.func,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** List of media category */
  categoryList: PropTypes.array,
  /** Selected category */
  selectedCategory: PropTypes.string
}

CreateAppPageContainer.defaultProps = {
}

// export default EditImageContainer

export default withApollo(
  compose(
    // graphql(
    //   QueryImageSettings,
    //   {
    //     options: ({ project }) => {
    //       const variables = {
    //         aspectRatio: '',
    //         fileName: '',
    //         ratio: null,
    //         cropPosition: 'TOP_LEFT',
    //         project
    //       }
    //       return {
    //         variables,
    //         fetchPolicy: 'cache-first'
    //       }
    //     },
    //     props: (props) => {
    //       return {
    //         // vodStatus: props.data.getAsset,
    //         getImageSetting: (input) => {
    //           return props.data.refetch({ input, project: props.ownProps.project })
    //         }
    //       }
    //     }
    //   }
    // )
  )(CreateAppPageContainer)
)
