import React, { Component } from 'react'
import JobStatusContent from '../../components/ui/dataDisplay/JobStatusContent'
class AssetVideoJobStatus extends Component {
  render () {
    const { jobStatus } = this.props
    return (
      <div className='video-job-status'>
        {jobStatus && jobStatus.length
          ? <div className='job-status'>
            <div className='job-status-heading job-status-general'>
              <div className='type'> Job Type </div>
              <div className='name'> Job Name </div>
              <div className='duration'> Duration </div>
              <div className='progress'> Progress </div>
              <div className='status'> Status </div>
              <div className='last-updated'> Last Updated </div>
              <div className='more-details' />

            </div>
            {jobStatus.map((job, index) => <JobStatusContent key={job.id} job={job} />)}
          </div> : <span>No Jobs Created </span>
        }
      </div>
    )
  }
}

export default AssetVideoJobStatus
