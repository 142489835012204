import gql from 'graphql-tag'

export default gql(`
    mutation addAssetCreditLinks($input: [AssetCreditLinkInput] $project: String) {
        addAssetCreditLinks(
            input: $input
            project: $project
        ){
            id
        }
    }`
)
