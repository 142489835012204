import gql from 'graphql-tag'

export default gql(`
  query ( $limit: Int $offset: Int $filter: ListCompetitionFilterInputES $project: String){
    listCompetitionsES(
      limit: $limit
      offset: $offset
      filter: $filter
      project: $project
      ) {
        items{
          title
          id
          opId
          competitionCode
          type
          lastUpdated
          tournamentCalendar{
            id
            externalId
            title
            startDate
            endDate
            active
            lastUpdated
            customTitle
            customStartDate 
            customEndDate
            customActive
            tags{
              name
              key
            }
          }
          customTitle
          customCompetitionCode
          customCountry
          customCompetitionFormat
          customType
          customCompetitionType
          customSource
          tags{
            name
            key
          }
          
        }
    }
  }
  `
)
