import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getChannel(id: $id) {
        id
        name
        isArchived
        channelCode
        site
        masterConfig {
            id
            name
        }
        icon{
            id
            url
            tags{
                id: key
                name
            }
            name
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            fileName
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
        }
        configGroups {
            id
            schemaId{
                id
              }
            position
            version
            name
            fields{
                name
                value
                isRequired
                type
            }
            streams{
                id
                levelVersions{
                    streamId
                    version
                }
                fields{
                    name
                    value
                    isRequired
                    type
                }
                url
                streamSchema{
                    id
                    levels{
                        id
                        name
                        version
                        fields{
                            name
                            value
                            isRequired
                            type
                        }
                    }
                }
            }
        }
        activeConfigGroup{
            id
            name
            version
            streams{
                id
                fields{
                    name
                    isRequired
                    type
                    value
                }
            }
            schemaId{
                id
                streams{
                    id
                    levels{
                        id
                        fields{
                            name
                            isRequired
                            type
                        }
                    }
                }
            }
        }
        defaultTrimStart
        defaultTrimEnd
    }
}`)
