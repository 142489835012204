import gql from 'graphql-tag'

export default gql(`
    mutation createAssetCategory($name: String!, $slug: String $module: MODULE_TYPE $project: String) {
        createAssetCategory(
            input: {
                name: $name
                slug: $slug
            }
            module: $module
            project: $project
        ) {
            id
            name
            slug
            isTransferring
            assetCount
            isError
        }
    }`
)
