import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import _ from 'lodash'
import EditAppConfigDetails from './EditAppConfigDetails'
import GeneralFieldComponent from './../../dataDisplay/GeneralFieldComponent'
import AddImageModal from '../../dataEntry/other/AddImageModal'
import LoadingButton from '../../general/buttons/LoadingButton'
import Input from './../inputs/Input'
import DropDown from './../inputs/DropDown'
import './../../ui.style.scss'

const { TabPane } = Tabs

class CreateAppConfigContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showImageModal: false,
      activeKey: ''
    }
  }
  onSelected = (item) => {
    this.props.selectAspectRatio(item)
  };

  onTabChange=(activeKey) => {
    this.setState({ activeKey: activeKey })
  }

  render () {
    const { selectedType, selectedTags, selectedApp, selectedGroup, mediaDetails, name, selectedKey, handleTextChange,
      handleTagChange, systemTags, handleTypeChange, handleAppChange, handleGroupChange, appList, groupList, typeList, isUploadBlocked, addFieldObjects, project, streamField, handleTempPropertyNameChange, handleTempFieldTypeChange, tempFieldType, tempPropertyName, fieldTypeList, onOptionSelect, onChangePropertyName, onChangeStatus, handleIdChange, isFromEdit, onCloseField, onChangeUseAsTitle, reorder, appClient, configMetaFields, isDuplicating, onChangePropertyTooltipText, onChangeConfigStatus, isCritical, assignedUsers, handleUserChange, userList, details, appData, groupData, isAppAdminDisabled, userSearch, handleUserSearch } = this.props
    const { showImageModal, activeKey } = this.state
    return (
      <div id='edit-app-config-modal' className={`edit-app-config-modal`}>
        <Tabs defaultActiveKey='1' onChange={this.onTabChange}>
          <TabPane tab='General' key='1'>
            <EditAppConfigDetails
              appClient={appClient}
              isFromEdit={isFromEdit}
              details={details}
              name={name}
              selectedKey={selectedKey}
              handleTypeChange={handleTypeChange}
              handleTextChange={handleTextChange}
              handleIdChange={handleIdChange}
              handleTagChange={handleTagChange}
              systemTags={systemTags}
              selectedTags={selectedTags}
              appList={appList}
              groupList={groupList}
              typeList={typeList}
              selectedType={selectedType}
              selectedApp={selectedApp}
              selectedGroup={selectedGroup}
              handleAppChange={handleAppChange}
              handleGroupChange={handleGroupChange}
              configMetaFields={configMetaFields}
              onChangeConfigStatus={onChangeConfigStatus}
              isCritical={isCritical}
              isDuplicating={isDuplicating}
              assignedUsers={assignedUsers}
              handleUserChange={handleUserChange}
              userList={userList}
              appData={appData}
              groupData={groupData}
              isAppAdminDisabled={isAppAdminDisabled}
              handleUserSearch={handleUserSearch}
              userSearch={userSearch}
              project={project}
            />
          </TabPane>

          <TabPane tab='Define Properties' key='2' disabled={!selectedType}>
            {!_.isEmpty(streamField) ? <GeneralFieldComponent
              type='appConfig'
              reorder={reorder}
              streamField={streamField}
              // isEnableSave={this.isEnableSave}
              isFromEdit={isFromEdit}
              selectedType={selectedType}
              isTitleInvisible
              columnTitle1={'Property Name'}
              columnTitle3={'Mandatory'}
              columnTitle2={'Field Type'}
              columnTitle4={'Tooltip Text'}
              placeHolder1='Enter Property Name'
              placeHolder2='Select Field'
              onChangeStatus={onChangeStatus}
              handleChange={onChangePropertyName}
              onOptionSelect={onOptionSelect}
              nameParam='name'
              listType={fieldTypeList}
              onCloseField={onCloseField}
              onChangeUseAsTitle={onChangeUseAsTitle}
              onChangePropertyTooltipText={onChangePropertyTooltipText}
            /> : null}
          </TabPane>
        </Tabs>
        {activeKey === '2'
          ? <div className={`add-field-stream ${_.isEmpty(streamField) ? ' no-streams' : ''}`}>
            <div className='edit-input-item'>
              <Input title='' placeholder='Property Name' inputName='title' value={tempPropertyName} handleChange={handleTempPropertyNameChange} noLabel />
            </div>
            <div className='edit-input-item'>
              <div className='output-format' id='dropdown-1'>
                <DropDown options={fieldTypeList} onOptionSelect={handleTempFieldTypeChange} selectedValue={tempFieldType || undefined} valueParam='id' displayParam='id' placeHolder='Select Field Type' parent={'dropdown-1'} />
              </div>
            </div>
            <LoadingButton
              type='primary'
              onClick={addFieldObjects}
              htmlType='submit'
              buttonText={'Add Property'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={false}
            />
          </div> : null}
        <AddImageModal
          isVisible={showImageModal}
          handleSubmit={this.addImage}
          handleCancel={this.handleCancel}
          currentSelection={[mediaDetails]}
          mediaType={'IMAGE'}
          isSubmitButtonDisabled={isUploadBlocked}
          project={project} />
      </div>
    )
  }
}

CreateAppConfigContainer.propTypes = {
  /** Array of taglist */
  tagsList: PropTypes.array,
  /**  Array of selected tags */
  selectedTags: PropTypes.array,
  /**  Function to handle tag change */
  handleTagChange: PropTypes.func,
  /**  Function to handle video format change */
  handleFormatChange: PropTypes.func,
  /** Function to handle name cahnge */
  handleTextChange: PropTypes.func,
  /** Array of formats  */
  formats: PropTypes.array,
  /**  selected format */
  selectedFormat: PropTypes.string,
  /** Function to be called for updating images for each aspect ratios */
  updateAspectRatioImage: PropTypes.func,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** List of media category */
  categoryList: PropTypes.array,
  /** Selected category */
  selectedCategory: PropTypes.string
}

CreateAppConfigContainer.defaultProps = {
}

// export default EditImageContainer

export default CreateAppConfigContainer
