import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VideoContent from './../../components/ui/dataEntry/other/VideoContent'
// import { Empty } from 'antd'

import Button from './../../components/ui/general/buttons/Button'
import LoadingButton from './../../components/ui/general/buttons/LoadingButton'

const parentCompoent = 'asset-container'

class AssetVideos extends Component {
  toggleShowPopup = () => {
    this.props.showAddVideo('remoteFolder')
  }

  uploadItemSelect = (option) => {
    if (this.props.showAddVideo) { this.props.showAddVideo(option.type) }
  }

  render () {
    const {
      videos,
      onCloseVideo,
      showEditVideo,
      onPlayClick,
      isLoadingPlayer,
      loadingVideo,
      makeDisplayVideo,
      selectedVideo,
      isDeleteDisabled,
      isAssetVideoLoading
    } = this.props

    const defaultVideoIndex = (videos || []).findIndex((item) => item.isDefault === 'true')
    let videoList = [...videos]
    if (defaultVideoIndex > -1) {
      const defaultVideoItem = videoList.splice(defaultVideoIndex, 1)
      videoList.unshift(defaultVideoItem[0])
    }
    return (
      <div className='video-details'>
        <div className='video-content-container'>
          {videoList.map((media, index) => (
            <VideoContent
              key={index}
              isPlaying={selectedVideo === media.id}
              isLoadingPlayer={isLoadingPlayer && loadingVideo === media.id}
              videoDetails={media}
              onCloseContent={() => onCloseVideo(media.id)}
              parentCompoent={parentCompoent}
              showEditVideo={showEditVideo}
              makeDisplayVideo={makeDisplayVideo}
              onPlayClick={onPlayClick}
              isDefaultVideo={media.isDefault === 'true'}
              isDeleteDisabled={isDeleteDisabled}
              cuePoints={media.cuePoints}
              adPositions={media.adPositions}
              breakDuration={media.breakDuration}
            />
          ))}
        </div>

        <div style={{ width: '150px' }} id={'add-video-button-popup-container'}>
          <Button buttonText={'Add Video'} color='red' onClick={this.toggleShowPopup} style={{ width: '150px' }} isDisabled={isDeleteDisabled} />
        </div>
        {isAssetVideoLoading ? <div className='overlay'>
          <LoadingButton isLoading />
        </div> : ''}
      </div>
    )
  }
}

AssetVideos.propTypes = {
  /** close image action callback of AssetVideos. */
  onCloseVideo: PropTypes.func,
  /** close image action callback of AssetVideos. */
  showEditVideo: PropTypes.func,
  /** edit image action callback of AssetVideos. */
  showAddVideo: PropTypes.func,
  /** Boolean to disable add video button */
  isDeleteDisabled: PropTypes.bool
}

export default AssetVideos
