import React from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'

import './../ui.style.scss'

class SupportTicketComments extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  render () {
    const { comments } = this.props
    return (
      <div className='support-ticket-comments'>
        <div className='name'>
          <p> {` ${comments.createdBy ? comments.createdBy : ''}  ${comments.id ? `( ID: ${comments.id} )` : ''} `} </p>
          <p> { comments.createdDate ? moment(new Date(comments.createdDate)).format('DD MMM YYYY, HH:mm') : ''}</p>
        </div>
        {/* <div className='attachment' >
          {comments.attachment && comments.attachment.fileName
            ? <p> Attachment : <a style={{ color: '#FF015A' }} target='_blank' href={comments.attachment.fileName} > {comments.attachment.fileName} </a> </p>
            : ''}
        </div> */}
        { comments.attachment && comments.attachment.fileName
          ? <div className='attachment' >
            <p>Attachment :</p>
            <img src={`data:${comments.attachment.contentType};base64,${comments.attachment.body}`} />
          </div>
          : null }
        { comments.comment
          ? <div className='comment' >
            <p> Comment : </p>
            <p> {comments.comment} </p>
          </div>
          : '' }
      </div>
    )
  }
}

SupportTicketComments.propTypes = {

}

SupportTicketComments.defaultProps = {
}

export default SupportTicketComments
