import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
// import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
// import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
// import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateBucketDisplayTypesModal from '../../../components/ui/dataEntry/other/CreateBucketDisplayTypesModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import userMessages from '../../../constants/messages'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryBucketDisplayTypeList from '../../../graphQL/bucket/getBucketDisplayType'
import MutationDeleteBucketDisplayType from '../../../graphQL/admin/bucket/deleteBucketDisplayType'
import { utilityService } from '../../../services/UtilityService'

const deleteBucketDisplayTypeMessage = {
  title: 'DELETE DISPLAY TYPE',
  firstMessage: 'Are you sure you want to delete this Display Type?',
  secondMessage: 'This action cannot be undone.'
}

let timeOutVar = null

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class BucketDisplayTypes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedDisplayType: null,
      isLoading: !props.displayTypeList,
      showAddDisplayTypeModal: false,
      showTransferDisplayTypeModal: false,
      showDeleteDisplayTypeModal: false,
      selectedDisplayType: undefined,
      height: null
    }
    this.columns = [
      {
        title: 'Display Type',
        dataIndex: 'name',
        key: 'name',
        width: '37%'
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: '30%'
      },
      {
        title: 'Used',
        dataIndex: 'usedCount',
        width: '30%',
        key: 'usedCount'
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: this.renderData
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
      window.addEventListener('resize', this.setHeight)
      this.setHeight()
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.displayTypeList, this.props.displayTypeList)) {
        this.setState({ isSearching: false, isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
      window.removeEventListener('resize', this.setHeight)
    }

    setHeight = () => {
      if (this.tableSize) {
        clearTimeout(timeOutVar)
        timeOutVar = null
        this.setState({ height: this.tableSize.clientHeight })
      } else {
        clearTimeout(timeOutVar)
        this.timeOutVar = setTimeout(() => {
          this.setHeight()
        }, 2000)
      }
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedDisplayType) {
        this.setState({ openedDisplayType: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedDisplayType === id) {
        this.setState({ openedDisplayType: null })
      } else {
        this.setState({ openedDisplayType: id })
      }
    }

    onChangeFilter = value => {
      this.setState({ isSearching: true })
      this.props.onChangeDisplayTypeFilter({ searchString: value })
    }

    onSelectOption = (id, selectedOption) => {
      const { displayTypeList } = this.props
      const selectedDisplayType = displayTypeList.find(item => item.id === id)
      if (!selectedDisplayType) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddDisplayTypeModal: true, selectedDisplayType })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteDisplayTypeModal: true, selectedDisplayType })
      }
      this.setState({ openedDisplayType: null })
    }

    showAddDisplayType = () => {
      this.setState({ showAddDisplayTypeModal: true })
    }

    hideAddDisplayTypeModal = () => {
      this.setState({ showAddDisplayTypeModal: false, selectedDisplayType: null })
    }

    handleAddDisplayType = () => {
      this.setState({ showAddDisplayTypeModal: false, selectedDisplayType: null })
    }

    hideDeleteDisplayTypeModal = () => {
      this.setState({ showDeleteDisplayTypeModal: false })
    }

    handleDeleteDisplayType = () => {
      const { selectedDisplayType } = this.state
      const { onHistoryUpdate } = this.props
      this.props.deleteBucketDisplayType(selectedDisplayType.id).then(() => {
        onHistoryUpdate()
        this.setState({ showDeleteDisplayTypeModal: false, selectedDisplayType: null })
      }, error => {
        this.setState({ showDeleteDisplayTypeModal: false, selectedDisplayType: null })
        utilityService.handleError(error)
      })
    }

    handleMultipleDeleteDisplayTypes = () => {
      this.setState({ isMultipleDelete: false })
    }

    hideMultipleDeleteDisplayTypes = () => {
      this.setState({ isMultipleDelete: false })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedDisplayType, isSelected } = this.state
      const { displayTypeList } = this.props
      const selectedType = displayTypeList.find(item => item.id === id)
      if (selectedType && !selectedType.usedCount && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            {!isSelected ? <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedDisplayType === id}
              placement={'leftBottom'}
            /> : '' }
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { displayTypeList, displayTypeFilter, onHistoryUpdate } = this.props
      const { showAddDisplayTypeModal, showDeleteDisplayTypeModal, selectedDisplayType, isMultipleDelete, selectedRowKeys, isSelected, isSearching, isLoading, height } = this.state
      let adminItem
      if (selectedDisplayType && showDeleteDisplayTypeModal) {
        adminItem = <div >
          <p>{ selectedDisplayType.name } is being used {selectedDisplayType.usedCount || '0'} times.</p>
        </div>
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal bucket-display-list'>
              <FilterInput
                searchString={displayTypeFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                project={project}
                // filterType={'AssetManager'}
                // changeFilter={(value) => {
                //   this.setState({ isSearching: true, filterData: value }, () => {
                //     this.changeFilterValue(value)
                //   })
                // }}
                placement='rightTop'
                // isClearFilter={isClearFilter}
                // initialSearch={initialSearch}
                // username={this.username}
                // bucketAssetFilter={filterData}
              />
              <div className='admin-list display-list' ref={(ref) => { this.tableSize = ref }}>
                <InfiniteScroll
                  pageStart={0}
                  hasMore={false}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading || isSearching}>
                    <Table
                      className={`general-table`}
                      rowKey={record => record.id}
                      columns={this.columns}
                      dataSource={displayTypeList}
                      scroll={{ x: true, y: (height - 42) }}
                      pagination={false} rowSelection={isSelected ? rowSelection : undefined} />
                  </Skeleton>
                  {/* <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} /> */}
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {/* {!isSelected
                ? <MultipleSelectButton onClick={this.onMultipleSelect} />
                : <React.Fragment>
                  <BackArrowButton onClick={this.onMultipleDSelect} />
                  <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                </React.Fragment>
              } */}
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddDisplayType}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                /> : null}
              </div>
              <CreateBucketDisplayTypesModal
                isVisible={showAddDisplayTypeModal}
                handleCancel={this.hideAddDisplayTypeModal}
                handleSubmit={this.handleAddDisplayType}
                selectedDisplayType={selectedDisplayType}
                onHistoryUpdate={onHistoryUpdate}
                isSubmitDisabled={_.isEmpty(selectedDisplayType) ? isCreateDisabled : this.isUpdateDisabled}
                project={project} />
              <AdminItemConfirmModal
                isVisible={showDeleteDisplayTypeModal}
                title={deleteBucketDisplayTypeMessage.title}
                firstMessage={deleteBucketDisplayTypeMessage.firstMessage}
                secondMessage={deleteBucketDisplayTypeMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteDisplayTypeModal}
                handleSubmit={this.handleDeleteDisplayType}
                isSubmitButtonDisabled={false}
                isCancelButtonDisabled={this.isDeleteDisabled} />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'DELETE MULTIPLE DISPLAY TYPE'}
                message={userMessages.DELETE_MULTIPLE_DISPLAY_TYPE}
                isLoading={false}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeleteDisplayTypes}
                handleCancel={this.hideMultipleDeleteDisplayTypes}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

BucketDisplayTypes.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryBucketDisplayTypeList,
    {
      options: ({ displayTypeFilter, project }) => {
        const { searchString } = displayTypeFilter
        let filter = { }
        if (searchString) {
          filter.name = { match: searchString }
        }
        const variables = { filter, limit: 50, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: ({ data }) => {
        return {
          displayTypeList: data && data.listDisplayType && data.listDisplayType.items ? [ ...data.listDisplayType.items ] : undefined,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationDeleteBucketDisplayType, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deleteDisplayType } }) => {
            const variables = { filter: null, limit: 50, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketDisplayTypeList, variables }))
            if (cacheData && cacheData.listDisplayType && cacheData.listDisplayType.items) {
              const selectedAuthorIndex = cacheData.listDisplayType.items.findIndex(item => item.id === deleteDisplayType.id)
              if (selectedAuthorIndex > -1) {
                cacheData.listDisplayType.items.splice(selectedAuthorIndex, 1)
              }
            }
            cache.writeQuery({
              query: QueryBucketDisplayTypeList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        deleteBucketDisplayType: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  )

)(BucketDisplayTypes))
