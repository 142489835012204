import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import Downloadcon from './../general/icons/DownloadIcon'
import CopyLinkIcon from './../general/icons/CopyLinkIcon'
import userMessages from './../../../constants/messages'
import { generateCroppedThumbnail, getCropImageUrl } from './../../../util/util'
import './../ui.style.scss'
import ButtonContainer from '../general/buttons/ButtonContainer'

class ResolutionDisplayCell extends Component {
  state = {
    urlCopyMessage: ''
  }
  copyToClipboard = () => {
    let urlCopyMessage = ''
    let textField = document.createElement('textarea')
    // const { selectedAspect, details } = this.props
    // const filterSettings = details.settings.find((item) => item.aspectRatio === selectedAspect)
    const url = this.props.aspects.url
    textField.innerText = url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    urlCopyMessage = userMessages.IMAGE_LINK_COPIED
    if (urlCopyMessage) {
      message.success(urlCopyMessage)
    }
    this.setState({ urlCopyMessage })
  }

  forceDownload= (aspectData) => {
    // const { selectedAspect, details } = this.props
    // const filterSettings = details.settings.find((item) => item.aspectRatio === selectedAspect)
    const imageUrl = this.props.aspects.url
    const href = imageUrl
    // const temp = imageUrl.split('/')
    // const imageName = temp[temp.length - 1]
    // const downloadName = `${imageName.split('.')[0]}-${aspectData.width}X${aspectData.height}.${imageName.split('.')[1]}`
    var anchor = document.createElement('a')
    anchor.href = href
    // anchor.download = downloadName
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  ratioSizes = (x, y) => {
    let w = 300
    let h = 168
    if (x > y) {
      w = 300
      h = (y * w) / x
    }
    if (x < y) {
      h = 168
      w = (h * x) / y
    }
    if (x === y) {
      h = 168
      w = 168
    }
    return { width: parseInt(w), height: parseInt(h) }
  }

  render () {
    const { aspects, details, selectedAspect, aspectRatio } = this.props
    const { width, height } = this.ratioSizes(aspects.width, aspects.height)
    const filterSettings = details.settings.find((item) => item.aspectRatio === selectedAspect)
    const imageUrl = getCropImageUrl(details, filterSettings)
    return (
      <div className='resolution-cell'>
        <div className='asset-thumbnail' onClick={() => this.props.onResolutionSelect({ url: imageUrl, width: aspects.width, height: aspects.height, aspectRatio: aspectRatio })}>
          <img src={generateCroppedThumbnail(details, width, height, aspectRatio)} />
        </div>
        <span onClick={() => this.props.onResolutionSelect({ url: aspects.url, width: aspects.width, height: aspects.height, aspectRatio: aspectRatio })}> { `${aspects.width}x${aspects.height}` } </span>
        <div className='asset-icons'>
          <span onClick={this.copyToClipboard}>
            <ButtonContainer displayTitle='Copy URL' childComponent={<CopyLinkIcon />} />
          </span>
          <span className='icon-separation' />
          <span onClick={() => { this.forceDownload(aspects) }}>
            <ButtonContainer displayTitle='Download' childComponent={<Downloadcon />} />
          </span>
        </div>

      </div>
    )
  }
}

ResolutionDisplayCell.propTypes = {
  /** aspects of ResolutionDisplayCell. */
  aspects: PropTypes.object,
  /** aspectRatio of ResolutionDisplayCell. */
  aspectRatio: PropTypes.string,
  /** Selected aspect ratio ID */
  selectedAspect: PropTypes.string
}

export default ResolutionDisplayCell
