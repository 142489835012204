import React from 'react'
// import PropTypes from 'prop-types'
import { DatePicker } from 'antd'

import moment from 'moment'
import _ from 'lodash'
import Switch from '../dataEntry/inputs/Switch'
import InformationIcon from '../general/icons/InformationIcon'
import ButtonContainer from '../general/buttons/ButtonContainer'
import Input from '../dataEntry/inputs/Input'
import './../ui.style.scss'

class EditOptaSetting extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {
        frequency: 1,
        lastRunTime: moment('01-01-2016').startOf('day').utc().format(),
        isActive: true,
        isRunning: false,
        isError: null
      }
    }
  }

  componentDidMount = () => {
    if (this.props.selectedSyncState) {
      this.setState({
        details: _.cloneDeep(this.props.selectedSyncState)
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedSyncState && !_.isEqual(this.props.selectedSyncState, nextProps.selectedSyncState)) {
      this.setState({ details: _.cloneDeep(nextProps.selectedSyncState) })
    }
  }

  handleGeneralChange = (e) => {
    const { details } = this.state
    const { name, value } = e.target
    if (!value.startsWith(' ')) {
      if (name === 'opId') {
        const regexExp = /^[0-9]*$/
        const isNum = regexExp.test(value)
        if (isNum) {
          details[name] = value
        }
      } else {
        details[name] = value
      }
      this.setState({ details }, () => this.props.onEditSyncState(details))
    }
  }

  onHandleActive = (status) => {
    const { details } = this.state
    if (status === true) {
      details.isActive = true
    } else {
      details.isActive = false
    }
    this.setState({ details }, () => this.props.onEditSyncState(details))
  }

  onHandleFrequency = (e) => {
    const { details } = this.state
    const { value } = e.target
    const regexExp = /^\d*[1-9]\d*$/
    const isNum = regexExp.test(value)
    details.frequency = isNum ? value : 1
    this.setState({ details }, () => this.props.onEditSyncState(details))
  }

  formatInput = (e) => {
    let checkIfNum
    if (e.key !== undefined) {
      checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'E'
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189
    }
    return checkIfNum && e.preventDefault()
  }

  onChangeDate = (selectedDate) => {
    const { details } = this.state
    const runTime = selectedDate ? moment(selectedDate).utc().format() : null
    if (runTime) {
      details.lastRunTime = runTime
    }
    this.setState({ details }, () => this.props.onEditSyncState(details))
  }

  disabledDate = (currentDate) => {
    return moment(currentDate).isBefore(moment('01-01-2016', 'DD-MMM-YYYY').startOf('day')) || moment(currentDate).isAfter(moment(new Date()), 'day')
  }

  disabledRangeTime = (date) => {
    const currentDate = moment(date)
    const now = moment()
    let minutes = []
    const isDateEqual = currentDate.format('DD MMM YYYY') === moment(now).format('DD MMM YYYY')
    if (currentDate && (isDateEqual)) {
      let hours = this.range(0, 24)
      hours = hours.splice(currentDate.hours())
      if (now.hours() === currentDate.hours()) {
        minutes = this.range(0, 60)
        minutes = minutes.splice(currentDate.minutes() + 1)
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => []
    }
  }

  range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  disableTimeComplete = () => {
    const hours = this.range(0, 24)
    const minutes = this.range(0, 60)
    return {
      disabledHours: () => hours,
      disabledMinutes: () => minutes
    }
  }

  render () {
    const { details } = this.state
    const { isAdd } = this.props
    const opid = <div className='type'>
      <span>OPID</span>
      <div className='information-icon'>
        <ButtonContainer displayTitle={'Opta Data Legacy ID'} childComponent={<InformationIcon />} />
      </div>
    </div>

    const frequency = <div className='type'>
      <span>Sync Frequency (Hours)</span>
      <div className='information-icon'>
        <ButtonContainer displayTitle={'Sync Frequency in Hours'} childComponent={<InformationIcon />} />
      </div>
    </div>
    return (
      <div className='opta-sync-setting'>
        <div className='opta-data'>
          <div className='details'>
            <Input title='Competition' placeholder='Write a title...' inputName='title' value={details ? details.title : ''} handleChange={this.handleGeneralChange} isRequired />
          </div>
          <div className='details'>
            <Input title={opid} placeholder='Write a title...' inputName='opId' value={details ? details.opId : ''} isEditable={isAdd} handleChange={this.handleGeneralChange} readOnly={!isAdd} />
          </div>
          <div className='details'>
            <label> Sync Date<span className='mandatory'>*</span> </label>
            <DatePicker showTime={{ format: 'HH:mm' }} format='DD-MMM-YYYY, HH:mm' placeholder='Select Time' onChange={this.onChangeDate}
              value={details ? moment(details.lastRunTime) : null} disabledDate={this.disabledDate} disabledTime={details.lastRunTime ? () => this.disabledRangeTime(details.lastRunTime) : () => this.disableTimeComplete()}
            />
          </div>
          <div className='details' id='details'>
            <label> {frequency} </label>
            <input type='number' value={details && details.frequency !== null ? parseInt(details.frequency) : null} onChange={this.onHandleFrequency} onKeyDown={this.formatInput} />
          </div>
          <div className='details'>
            <div className='type'>
              <span>Active</span>
              <Switch isChecked={details.isActive} onChange={(status) => this.onHandleActive(status)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditOptaSetting.propTypes = {

}

EditOptaSetting.defaultProps = {
}

export default EditOptaSetting
