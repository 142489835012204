import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'

import _ from 'lodash'
import CustomerAccountIcon from '../general/icons/CustomerAccountIcon'

class CustomerListCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isFreeLoading: false
    }
  }

  render () {
    const { onSelect, details, isSelected, project } = this.props
    const name = details.firstName && details.lastName ? `${details.firstName} ${details.lastName}` : details.firstName || details.lastName || '-'
    const { activeSubscriptions } = details
    const isPremium = (activeSubscriptions && !_.isEmpty(activeSubscriptions))
    const isEligibleForHyperion = details.eligibleForHyperion
    return (
      <div className={`customer-list-cell ${isSelected ? 'active' : ''}`} onClick={() => onSelect(details.id, details.cognitoUserId)} >
        <div className='detail-cell' >
          <p>Name</p>
          <p>{name}</p>
        </div>
        {/* <div className='detail-cell' >
          <p>User ID</p>
          <p>{details.externalId}</p>
        </div> */}
        <div className='detail-cell' >
          <p>Email</p>
          <p>{details.email || '-'}</p>
        </div>
        <div className='detail-cell' >
          <p>{project === 'hyperion' || project === 'projectx' ? 'Mobile No.' : 'Phone Number'}</p>
          <p>{details.mobile || '-'}</p>
        </div>
        <CustomerAccountIcon isPremium={project === 'hyperion' || project === 'projectx' ? isEligibleForHyperion : isPremium} />
      </div>
    )
  }
}

CustomerListCell.propTypes = {
  /** Asset type of CustomerListCell. */
  details: PropTypes.object.isRequired,
  /** Click selection action of CustomerListCell. */
  onSelect: PropTypes.func.isRequired
}

CustomerListCell.defaultProps = {
  isSelected: false
}

export default CustomerListCell
