import { bPlayerConfig } from '../../config/playerConfig'
import PlayerUtils from '../Utils/playerUtils'
import { Player as BitmovinPlayer, PlayerEvent, ViewMode } from 'bitmovin-player'
import { UIFactory } from 'bitmovin-player-ui'
import { isFunction } from 'lodash'

const { playerDebugger } = PlayerUtils
/**
 * bitmovinPlayer :
 * this closure initialises bitmovin and all the callbacks are handled here
 */
let playerTimeout = null

const bitmovinPlayer = (() => {
  var playerInstance
  var uiManager
  var playerCallbacks
  var loaded = false

  const init = playerElm => {
    let bitmovin = null
    if (playerElm) {
      bitmovin = new BitmovinPlayer(playerElm, bPlayerConfig)
    }
    return bitmovin
  }
  const releasePlayer = () => {
    if (uiManager) {
      uiManager.release()
    }
  }

  const startPlayer = (source) => {
    if (playerInstance) {
      playerInstance.unload().then(() => {
        const pl = playerInstance.load(source)
        playerCallbacks.onReady()
        pl.then(() => {
          if (!uiManager) {
            uiManager = UIFactory.buildDefaultUI(playerInstance)
          }
          loaded = true
        }).catch(reason => {
          playerDebugger.log('Error while loading source:', reason)
          if (playerCallbacks && playerCallbacks && isFunction(playerCallbacks.Error)) {
            playerCallbacks.Error(reason)
          }
        })
      })
    }
  }

  return {
    getInstance: playerElm => {
      if (!playerInstance) {
        playerInstance = init(playerElm)
      }
      return playerInstance
    },
    bindPlayerEventListeners: function (callbacks) {
      playerCallbacks = callbacks
      Object.keys(callbacks).forEach(event => {
        if (PlayerEvent[event]) {
          playerInstance.on(PlayerEvent[event], callbacks[event])
        }
      })
    },
    unBindPlayerEventListeners: function (callbacks) {
      Object.keys(callbacks).forEach(event => {
        if (PlayerEvent[event]) {
          playerInstance.off(PlayerEvent[event], callbacks[event])
        }
      })
    },
    load: config => {
      if (playerTimeout) clearTimeout(playerTimeout)
      playerTimeout = setTimeout(() => startPlayer(config), 500)
    },
    getPosition: () => {
      if (playerInstance && loaded) {
        if (playerInstance.isLive()) {
          return playerInstance.getTimeShift()
        }
        return playerInstance.getCurrentTime()
      }
      return 0
    },
    getDuration: () => {
      if (playerInstance) {
        return playerInstance.getDuration()
      }
      return 0
    },
    isLive: () => {
      if (playerInstance) {
        return playerInstance.isLive()
      }
      return -1
    },
    getTimeShift: () => {
      if (playerInstance && playerInstance.isLive()) {
        return playerInstance.getTimeShift()
      }
      return -1
    },
    getMaxTimeShift: () => {
      if (playerInstance) {
        return playerInstance.getMaxTimeShift()
      }
    },
    seekToLiveEnd: () => {
      if (playerInstance && playerInstance.isLive()) {
        return playerInstance.timeShift(0)
      }
    },
    timeShift: shift => {
      if (playerInstance && playerInstance.isLive()) {
        return playerInstance.timeShift(shift)
      }
    },
    getBuffer: () => {
      if (
        playerInstance &&
        playerInstance.getBufferedRanges()[0] &&
        playerInstance.getBufferedRanges()[0].end
      ) {
        return playerInstance.getBufferedRanges()[0].end
      }
      return 0
    },
    play: () => {
      if (!loaded) return
      playerCallbacks.onResume()
      playerInstance.play()
    },
    pause: () => {
      if (!loaded) return
      playerInstance.pause()
    },
    isPlaying: () => {
      if (!loaded) return
      return playerInstance.isPlaying()
    },
    getState: () => {
      if (!loaded) return
      return playerInstance.isPaused() ? 'paused' : 'playing'
    },
    seek: time => {
      if (!loaded) return
      if (playerInstance && playerInstance.isLive()) {
        if (time >= 0) {
          return playerInstance.timeShift(0)
        }
        playerInstance.timeShift(time)
      } else {
        playerInstance.seek(time)
      }
    },
    unload: () => {
      if (playerInstance) {
        playerInstance.pause()
        releasePlayer()
        playerInstance.unload()
        uiManager = null
        loaded = false
      }
    },
    remove: () => {
      if (playerInstance) {
        playerInstance.pause()
        releasePlayer()
        playerInstance.unload()
        playerInstance = null
        loaded = false
        uiManager = null
      }
    },
    isMuted: () => {
      return playerInstance.isMuted()
    },
    mutePlayer: () => {
      if (!loaded) return
      playerInstance.mute()
    },
    unMutePlayer: () => {
      if (!loaded) return
      playerInstance.unmute()
    },
    enableFullscreen: () => {
      if (!loaded) return
      playerInstance.setViewMode(ViewMode.Fullscreen)
      if (!uiManager) {
        uiManager = UIFactory.buildDefaultUI(playerInstance)
      }
    },
    isFullscreen: () => {
      if (!loaded) return
      return playerInstance.getViewMode() === ViewMode.Fullscreen
    },
    disableFullscreenUI: () => {
      releasePlayer()
    },
    setVolume: num => {
      if (!loaded) return
      playerInstance.setVolume(num)
    },
    getVolume: () => {
      if (!loaded) return
      return playerInstance.getVolume()
    },
    resize: (width, height) => {
      const playerElem = document.getElementById('player-container')
      playerElem.style.height = height + 'px'
    }
  }
})()
export default bitmovinPlayer
