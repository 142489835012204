import React from 'react'
import PropTypes from 'prop-types'
import { Popconfirm } from 'antd'
import CrossIcon from '../general/icons/CrossIcon'
import RemoveItemIcon from '../general/icons/RemoveItemIcon'
import './../ui.style.scss'
import userMessages from './../../../constants/messages'

const PopoverConfirm = ({ warningMessage, confirm, cancel, parentCompoent, isDisabled, ...props }) => {
  return (
    <Popconfirm className='pop-over-confirm' placement='topRight' title={warningMessage} onConfirm={confirm} onCancel={cancel} okText='Yes' cancelText='No' getPopupContainer={() => document.getElementById(parentCompoent)} disabled={isDisabled}>
      { props.isThinIcon ? <RemoveItemIcon /> : <CrossIcon />}
    </Popconfirm>
  )
}

PopoverConfirm.propTypes = {
  /** Message of PopoverConfirm. */
  warningMessage: PropTypes.string,
  /** Confirm action of PopoverConfirm. */
  confirm: PropTypes.func.isRequired,
  /** Cancel action of PopoverConfirm. */
  cancel: PropTypes.func,
  /** Delete icon thinner or not */
  isThinIcon: PropTypes.bool

}

PopoverConfirm.defaultProps = {
  parentCompoent: 'root',
  warningMessage: userMessages.ASSET_CONTENT_DELETE_CONFIRMATION
}

export default PopoverConfirm
