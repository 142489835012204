import gql from 'graphql-tag'

export default gql(`
    mutation createImageType( $enabledAssetTypes: [ImageAssetTypeInput] $aspectRatios: [ImageAspectRatioInput] $name: String! $position: Int $project: String) {
        createImageType(
            input: {
                enabledAssetTypes: $enabledAssetTypes
                aspectRatios: $aspectRatios
                name: $name
                position: $position
            }
            project: $project
        ) {
            id
            name
            isDefault
            position
            isActive
            usageCount
            enabledAssetTypes{
                cropPosition
                assetType
                minCount
            }
            aspectRatios{
                aspectRatio
                name
                ratio
                title
                resolutions{
                    id
                    width
                    height
                    key
                }
            }
            updatedAt
            createdAt
        }
    }`
)
