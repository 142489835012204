import React from 'react'
import './../ui.style.scss'
import PropTypes from 'prop-types'

import { generateCroppedThumbnail } from '../../../util/util'
import PartnerListCellData from './PartnerListCellData'
import placeholderImage from './../../../assets/images/default-image.jpg'
import UpdatedIcon from './../general/icons/UpdatedIcon'
import { Checkbox } from 'antd'

class PartnerListCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isFreeLoading: false
    }
  }

      getAssetIcon = (assetType, status, listAssetTypes) => {
        const selectedType = (listAssetTypes || []).find(item => item.title === assetType)
        return (selectedType && selectedType.icon) ? <img src={generateCroppedThumbnail((status === 'PUBLISHED' || status === 'PARTIAL') ? selectedType.icon : selectedType.draftIcon, 18, 18, '1:1')} /> : null
      }

      onCheck = e => {
        e.preventDefault()
        e.stopPropagation()
        this.props.onCheck(this.props.partnerId)
      }

      selectedLink = (e) => {
        e.stopPropagation()
      }

      render () {
        const { imageUrl, isSelected, partnerName, partnerId, partnerExtId, status, onSelect, isArchival, isChecked, isEditted, numOfOffers } = this.props
        const image = imageUrl || placeholderImage
        return (
          <div
            className={`partner-list-cell ${isSelected ? 'active' : ''} 
                  ${isArchival ? 'archive' : ''}`}
            onClick={() => onSelect(partnerId)}
          >
            {isEditted ? <div className='partner-edit'> <UpdatedIcon /> </div> : null }
            { isArchival ? (<Checkbox checked={isChecked} onClick={this.onCheck} />) : null }
            <div className='partner-thumb' style={{ backgroundImage: `url(${image})` }} />
            <PartnerListCellData
              partnerName={partnerName}
              partnerExtId={partnerExtId}
              status={status}
              numOfOffers={numOfOffers}
            />
          </div>
        )
      }
}

PartnerListCell.propTypes = {
  /** Partner image url of PartnerListCell. */
  imageUrl: PropTypes.string,
  /** Name of selected Partner. */
  partnerName: PropTypes.string,
  /** Id of selected Partner. */
  partnerId: PropTypes.string,
  /** external Id of selected Partner. */
  partnerExtId: PropTypes.string,
  /** number of Offers of partner. */
  numOfOffers: PropTypes.number,
  /** status of Partner. */
  status: PropTypes.bool,
  /** Partner checkbox status PartnerListCell. */
  isChecked: PropTypes.bool,
  /** Partner archival status of PartnerListCell */
  isArchival: PropTypes.bool,
  /** Partner selection status of PartnerListCell */
  isSelected: PropTypes.bool,
  /** Click selection action of PartnerListCell. */
  onSelect: PropTypes.func

}

export default PartnerListCell
