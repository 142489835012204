import React from 'react'
import PropTypes from 'prop-types'

const CompetitionFilterIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <g stroke={color}>
        <path d='M3.7 3.7H2a1 1 0 0 0-1 1v1.7c0 2.4.9 3.6 2.7 3.6V3.7zM16.3 3.7H18a1 1 0 0 1 1 1v1.7c0 2.4-.9 3.6-2.7 3.6V3.7z' />
        <path d='M5.7 1h8.6a2 2 0 0 1 2 2v5.2c0 3.6-1.2 5.4-3.6 5.4l1.8 3.6h.8a1 1 0 0 1 1 1v.8H3.7v-.8a1 1 0 0 1 1-1h.8l1.8-3.6c-2.4 0-3.6-1.8-3.6-5.4V3a2 2 0 0 1 2-2z' />
        <path fillRule='nonzero' d='M12.24 6.496a.345.345 0 0 0-.171-.098l-1.147-.173-.511-1.073a.403.403 0 0 0-.204-.173.343.343 0 0 0-.281.027.41.41 0 0 0-.146.14l-.511 1.079-1.121.168-.021.005c-.12.027-.183.109-.204.13l-.02.027a.394.394 0 0 0 .041.483l.814.823-.193 1.182c-.006.06 0 .157.062.254l.021.028a.365.365 0 0 0 .22.13c.02.005.04.005.056.005.053 0 .105-.01.162-.043l1.001-.548 1.017.558a.336.336 0 0 0 .26.011.384.384 0 0 0 .22-.2.463.463 0 0 0 .031-.217l-.193-1.16.824-.834a.383.383 0 0 0 .094-.255v-.027a.33.33 0 0 0-.1-.25z' />
      </g>
    </g>
  </svg>
)

CompetitionFilterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

CompetitionFilterIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default CompetitionFilterIcon
