import gql from 'graphql-tag'

export default gql(`
mutation uploadSport($key:String! $name: String $sport: String $dataType: String ){
    uploadSport(input:{
      key:$key
      type:CSV
      name:$name
      sport:$sport
      dataType:$dataType
    }){
        createdAt
        createdBy
        name
        type
        sport
        dataType
    }
    }
`)
