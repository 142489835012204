import gql from 'graphql-tag'

export default gql(`
    query($filter: ListMatchFilterInput $search: SearchInput $limit: Int $offset: Int $orderBy: String){
        listMatches( 
            filter: $filter
            search: $search
            limit: $limit
            offset: $offset
            orderBy: $orderBy
            ) {
        items {
            id
            kickOffTime
            matchDate
            opId
            finishTime
            externalId
            isLiveOnly
            asset{
                id
                type
                isLive
                isFree
                status
                isArchived
                vodStatus{
                    id
                    status
                    site
                    updatedAt
                    createdAt
                    errorDesc
                    startTime
                    endTime
                }
                vodJobs{
                id
                status
                site
                name
                type
                jobType
                vwmJobId
                updatedAt
                createdAt
                createdBy
                progress
                errorDesc
                startTime
                endTime 
                jobStates{
                    status
                    errorCode
                    updatedAt
                }
                channel{
                    id
                }
                mediaId{
                    id
                    duration
                }  
                }
                program{
                    id
                    source
                    isLiveTelecast
                    channel{
                        id
                        name
                        channelCode
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                        activeConfigGroup{
                            id
                            name
                            streams{
                                url
                                fields{
                                    name
                                    value
                                    isRequired
                                    type
                                }
                                streamSchema{
                                    id
                                    levels{
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                    broadCastStartTime
                    broadCastEndTime
                }
            }
            stage{
                title
                id
            }
            tournamentCalendar{
                title
                id
            }
            competition{
                title
                id
                opId
                customTitle
                competitionCode
                media {
                    fileName
                    aspectRatio {
                        aspectRatio
                        ratio
                        title          
                        resolutions {
                          url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2          
                    }
                }
            }
            homeTeam {
                id
                opId
                code
                officialName  
                media {
                    createdAt
                    fileName
                    aspectRatio {
                        aspectRatio
                        ratio
                        title          
                        resolutions {
                          url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2          
                    }
                }
            }
            awayTeam {
                id
                opId
                code
                officialName   
                media {
                    createdAt
                    fileName
                    aspectRatio {
                        aspectRatio
                        ratio
                        title          
                        resolutions {
                          url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2          
                    }
                }     
            }
            programs{
                id
                source
                isLiveTelecast
                channel{
                    id
                    name
                    channelCode
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                    activeConfigGroup{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
                broadCastStartTime
                broadCastEndTime
            }
            status
            customMeta {
                config {
                default
                id
                name
                project
                section
                type
                usageCount
                }
                name
                value
            }
        }
        totalCount
    }
}`)
