import React from 'react'
import PropTypes from 'prop-types'

const CropWhiteIcon = ({ width, height, color, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20'>
    <g fill='none' fillRule='evenodd'>
      <path d='M1 1h17.375v17.375H1z' />
      <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.58' d='M1 5.051l11.846-.102c.873 0 1.58.707 1.58 1.58v11.846' />
      <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.58' d='M5.051 1L4.95 12.846c0 .873.707 1.58 1.58 1.58h11.846' />
    </g>
  </svg>
)

CropWhiteIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

CropWhiteIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#FFF'
}

export default CropWhiteIcon
