import gql from 'graphql-tag'

export default gql(`
    mutation batchUpdateImageTypes( $input: [UpdateImageTypeInput] $project: String) {
        batchUpdateImageTypes(
            input: $input
            project: $project
        ) {
            id
            name
            isDefault
            position
            isActive
            usageCount
            enabledAssetTypes{
                cropPosition
                assetType
                minCount
            }
            aspectRatios{
                aspectRatio
                name
                ratio
                title
                resolutions{
                    id
                    width
                    height
                    key
                }
            }
            updatedAt
            createdAt
        }
    }`
)
