import React from 'react'
import PropTypes from 'prop-types'

const ClearFilterIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0-1h20v20H0z' />
      <g fill='#FFF'>
        <path d='M.956 0h15.17a.432.432 0 0 1 .343.694L11.05 7.773v10.32a.432.432 0 0 1-.704.335l-3.387-2.753a.432.432 0 0 1-.159-.335V9.5l3.307-1.727H6.848L.632.717A.432.432 0 0 1 .956 0z' />
        <path d='M16.685 10.497L17.86 9.32a.455.455 0 0 1 .643 0l1.176 1.176a.455.455 0 0 1 0 .643l-1.176 1.175a.455.455 0 0 0 0 .643l1.176 1.175a.455.455 0 0 1 0 .643l-1.176 1.175a.455.455 0 0 1-.643 0l-1.175-1.175a.455.455 0 0 0-.643 0l-1.175 1.175a.455.455 0 0 1-.643 0l-1.175-1.175a.455.455 0 0 1 0-.643l1.175-1.175a.455.455 0 0 0 0-.643l-1.175-1.175a.455.455 0 0 1 0-.643l1.175-1.176a.455.455 0 0 1 .643 0l1.175 1.176a.455.455 0 0 0 .643 0z' />
      </g>
    </g>
  </svg>
)

ClearFilterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

ClearFilterIcon.defaultProps = {
  width: 20,
  height: 20
}

export default ClearFilterIcon
