import React, { Component } from 'react'
import AppContext from '../../AppContext'
import moment from 'moment'

import EditPageButton from '../../components/ui/general/buttons/EditPageButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
// import DeleteButton from '../../components/ui/general/buttons/DeleteButton'
import PublishPageModal from '../../components/ui/feedback/PublishPageModal'
import { utilityService } from '../../services/UtilityService'
// import LoadingButton from '../../components/ui/general/buttons/LoadingButton'

class AppPagePublishDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { isPublishDisabled, appClient, hidePagePublishModal, onPublishAllStartDateChange, handlePagePublish, onPublishAllButtonActiveChange,
      onPublishAllEndDateChange, isPagePublishEdited, isPublishLoading, appDetails, togglePagePublishModal, showPagePublishModal, onPageVersionSelection, currentPageVersionId } = this.props
    const { isPublished } = appDetails
    let status = utilityService.getPagePublishStatus(isPublished, appDetails.publishStartDate, appDetails.publishEndDate)
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          return <React.Fragment>
            <div className={`page-publish-section ${currentPageVersionId === 'current' ? 'selected' : ''}`} onClick={() => onPageVersionSelection('current')}>
              <div className='publish-page-details'>
                <div className='details-row1'>
                  <span className='status'>{isPublished ? status : 'Unpublished'}</span>
                  {appDetails && appDetails.isPublished
                    ? <div className={appDetails && appDetails.publishEndDate ? 'details-row2' : 'details-row2-single'}>
                      <span className='time'>{appDetails && appDetails.publishStartDate ? moment(appDetails.publishStartDate).format('DD MMM YYYY HH:mm') : ''}</span>
                      <span className='time'>{appDetails && appDetails.publishEndDate ? moment(appDetails.publishEndDate).format('DD MMM YYYY HH:mm') : ''}</span>
                    </div> : null}
                  <span className='version'>{isPublished && status === 'Published' ? 'Current Version' : ''}</span>
                </div>
              </div>
              <div className='publish-page-button-container'>
                {/* <div className='unpublish-button'>
                  <LoadingButton type='primary' onClick={handlePageUnPublish} htmlType='submit' buttonText={`Unpublish`} buttonClass='save-btn' isLoading={isUnPublishLoading} isDisabled={!isPublished} />
                </div>
                <div className='edit-button'>
                  <ButtonContainer displayTitle='Remove' childComponent={<DeleteButton onClick={() => console.log('delete')} isDisabled />} />
                </div> */}
                <div className='edit-button'>
                  <ButtonContainer displayTitle='Edit' childComponent={<EditPageButton onClick={() => togglePagePublishModal()} isDisabled={isPublishDisabled} />} />
                </div>

              </div>
            </div>
            <PublishPageModal
              pageDetails={appDetails}
              visible={showPagePublishModal}
              handleCancel={hidePagePublishModal}
              appClient={appClient}
              onPublishAllStartDateChange={onPublishAllStartDateChange}
              onPublishAllEndDateChange={onPublishAllEndDateChange}
              onPublishAllButtonActiveChange={onPublishAllButtonActiveChange}
              handlePagePublish={handlePagePublish}
              isPagePublishEdited={isPagePublishEdited}
              isPublishLoading={isPublishLoading}
            />
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AppPagePublishDetails.defaultProps = {
}

AppPagePublishDetails.propTypes = {

}

export default AppPagePublishDetails
