import React from 'react'
import PropTypes from 'prop-types'

const FileThumbnailIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill={color} d='M22.848 8.188c-.072-.172-.16-.336-.28-.477L15.877.547c-.151-.164-.335-.281-.535-.383C15.11.047 14.854 0 14.583 0H1.813C.823 0 0 .828 0 1.867v26.188C0 29.102.823 30 1.813 30h19.414c.99 0 1.773-.898 1.773-1.945V8.985c0-.282-.04-.555-.152-.797zm-19.015.734c0-.117.144-.164.272-.164h5.654c.128 0 .224.047.224.164v.844c0 .109-.088.242-.224.242H4.105c-.128 0-.272-.133-.272-.242v-.844zm0 10c0-.117.144-.164.272-.164h9.759c.128 0 .192.047.192.164v.844c0 .109-.056.242-.192.242h-9.76c-.127 0-.27-.133-.27-.242v-.844zm12.778 5.844c0 .109-.056.242-.192.242H4.105c-.128 0-.272-.133-.272-.242v-.844c0-.117.144-.164.272-.164h12.314c.128 0 .192.047.192.164v.844zm2.556-10c0 .109-.056.242-.192.242H4.105c-.128 0-.272-.133-.272-.242v-.844c0-.117.144-.164.272-.164h14.87c.128 0 .192.047.192.164v.844zm-3.746-5.93c-.447 0-.886-.406-.886-.883V2.797l5.686 6.039h-4.8z' />
  </svg>

)

FileThumbnailIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

FileThumbnailIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#AFAFAF'
}

export default FileThumbnailIcon
