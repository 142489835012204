import React from 'react'
// import PropTypes from 'prop-types'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import MultipleSelectButton from '../../components/ui/general/buttons/MultipleSelectButton'
import DeleteButton from '../../components/ui/general/buttons/DeleteButton'
import BackArrowButton from '../../components/ui/general/buttons/BackArrowButton'
import GroupUserButton from '../../components/ui/general/buttons/GroupUserButton'
import RoleUserTable from '../../components/ui/dataDisplay/RoleUserTable'
import AddUserModal from '../../components/ui/dataEntry/other/AddUserModal'
import AddUserGroupModal from '../../components/ui/dataEntry/other/AddUserGroupModal'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'

import AppContext from '../../AppContext'

import { utilityService } from './../../services/UtilityService'

import QueryGroupList from '../../graphQL/role/listGroups'
import QueryUserList from '../../graphQL/role/listUsers'
import MutationCreateUser from '../../graphQL/role/createNewUser'
import MutationUpdateUser from '../../graphQL/role/updateUser'
import MutationDeleteUsers from '../../graphQL/role/deleteUsers'
import MutationSuspendUser from '../../graphQL/role/suspendUsers'
import MutationEnableUser from '../../graphQL/role/enableUser'
import MutationCreateAudit from '../../graphQL/role/createUserAudit'
import MutationBatchUpdateUserGroups from '../../graphQL/role/batchUpdateUserGroups'
import MutationResendInvitaionUsers from '../../graphQL/role/resendUserInvitation'
import MutationResetMfaDevice from '../../graphQL/role/reset2FADevice'
import MutationForceLogout from '../../graphQL/role/forceLogout'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import { message } from 'antd'

import { convertToCSV, downloadCSV, getSortedUserList } from '../../util/util'

class RoleUserDetails extends React.Component {
  state = {
    toAddUser: false,
    isSelected: false,
    toAddUserGroup: false,
    isCreateLoading: false,
    isConfirmDeleteVisible: false,
    isModalLoading: false,
    isConfirmDisableVisible: false,
    isConfirmEnableVisible: false,
    isAllDeleteVisible: false,
    isBackButtonClicked: false,
    isAddUserGroupLoading: false,
    isConfirmResetMfaVisible: false,
    userIdArr: [],
    isConfirmForceLogoutVisible: false
  }

  onAddUser = () => {
    this.setState({ toAddUser: true })
  }

  hideAddUserModal = () => {
    this.setState({ toAddUser: false })
  }

  onAddUserGroup = () => {
    this.setState({ toAddUserGroup: true })
  }

  hideAddUserGroupModal = () => {
    this.setState({ toAddUserGroup: false })
  }

  onMultipleSelect = () => {
    this.setState({ isSelected: true })
  }

  onMultipleDSelect = () => {
    this.setState({ isSelected: false, isBackButtonClicked: true }, () => {
      this.setState({ isBackButtonClicked: false })
    })
  }

  createNewUser = (userName, userId, userGroups) => {
    const { project } = this.props
    this.setState({ isCreateLoading: true })
    const variables = {
      id: userId,
      name: userName,
      accessRoleGroups: userGroups,
      project

    }
    this.props.createNewUser(variables).then(() => {
      this.setState({ isCreateLoading: false, toAddUser: false })
    }, error => {
      this.setState({ isCreateLoading: false })
      utilityService.handleError(error)
    })
  }

  onEditUser = (userName, userId, userGroups) => {
    const { project } = this.props
    const variables = {
      id: userId,
      name: userName,
      accessRoleGroups: userGroups,
      project

    }
    this.props.updateUser(variables).then(() => {
      this.setState({ isCreateLoading: false })
    }, error => {
      this.setState({ isCreateLoading: false })
      utilityService.handleError(error)
    })
  }

  onDeleteUser = (id) => {
    this.setState({ isConfirmDeleteVisible: true, userIdArr: id })
  }

  toggleDeleteWarningMessage = (isConfirmDeleteVisible) => {
    this.setState({ isConfirmDeleteVisible, isAllDeleteVisible: false })
  }

  onDeleteConfirmUser = () => {
    const { userIdArr } = this.state
    this.setState({ isModalLoading: true })
    const variables = { id: userIdArr }
    this.props.deleteUsers(variables).then(() => {
      this.setState({ isConfirmDeleteVisible: false, userIdArr: [], isModalLoading: false, isAllDeleteVisible: false })
    }, error => {
      this.setState({ isConfirmDeleteVisible: false, userIdArr: [], isModalLoading: false, isAllDeleteVisible: false })
      utilityService.handleError(error)
    })
  }

  onSuspendUser = (id) => {
    this.setState({ isConfirmDisableVisible: true, userIdArr: id })
  }

  toggleSuspendWarningMessage = (isConfirmDisableVisible) => {
    this.setState({ isConfirmDisableVisible })
  }

  onSuspendConfirmUser = () => {
    const { userIdArr } = this.state
    const { project } = this.props
    this.setState({ isModalLoading: true })
    const variables = { id: userIdArr, project }
    this.props.suspendUsers(variables).then(() => {
      this.setState({ isConfirmDisableVisible: false, userIdArr: [], isModalLoading: false })
    }, error => {
      this.setState({ isConfirmDisableVisible: false, userIdArr: [], isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onEnableUser = (id) => {
    this.setState({ isConfirmEnableVisible: true, userIdArr: id })
  }

  toggleEnableWarningMessage = (isConfirmEnableVisible) => {
    this.setState({ isConfirmEnableVisible })
  }

  onEnableConfirmUser = () => {
    const { userIdArr } = this.state
    const { project } = this.props
    this.setState({ isModalLoading: true })
    const variables = { id: userIdArr, project }
    this.props.enableUser(variables).then(() => {
      this.setState({ isConfirmEnableVisible: false, userIdArr: [], isModalLoading: false })
    }, error => {
      this.setState({ isConfirmEnableVisible: false, userIdArr: [], isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onResendInvitation = (id) => {
    this.setState({ isConfirmResendVisible: true, userIdArr: id })
  }

  onResetMfaDevice = (id) => {
    this.setState({ isConfirmResetMfaVisible: true, userIdArr: id })
  }

  toggleResendWarningMessage = (isConfirmResendVisible) => {
    this.setState({ isConfirmResendVisible })
  }

  toggleResetWarningMessage = (isConfirmResetMfaVisible) => {
    this.setState({ isConfirmResetMfaVisible })
  }
  onResetConfirmUser = () => {
    const { userIdArr } = this.state
    const { project } = this.props
    this.setState({ isModalLoading: true })
    const variables = { email: userIdArr, project }
    this.props.reset2FADevice(variables).then(({ data }) => {
      if (!data.reset2FADevice) { utilityService.handleError('2 Factor Authentication for this user hasn’t been enabled yet') } else {
        message.success('Reset MFA Device is successful')
      }
      this.setState({ isConfirmResetMfaVisible: false, isModalLoading: false })
    }, error => {
      this.setState({ isConfirmResetMfaVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onResendConfirmUser = () => {
    const { userIdArr } = this.state
    const { project } = this.props
    this.setState({ isModalLoading: true })
    const variables = { id: userIdArr, project }
    this.props.resendUserInvitation(variables).then(() => {
      this.setState({ isConfirmResendVisible: false, userIdArr: [], isModalLoading: false })
    }, error => {
      this.setState({ isConfirmResendVisible: false, userIdArr: [], isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onChangeSelectUser = (userIdArr) => {
    this.setState({ userIdArr })
  }

  onDeleteAllSelectedUser = () => {
    this.setState({ isAllDeleteVisible: true })
  }

  addGroupToMultipleUsers = (selectedUsers, newGroups) => {
    const { project } = this.props
    this.setState({ isAddUserGroupLoading: true }, () => {
      const variables = selectedUsers.map(item => {
        let accessRoleGroups = (item.accessRoleGroups || []).map(innerItem => innerItem.id)
        accessRoleGroups = [...accessRoleGroups, ...newGroups]
        accessRoleGroups = _.uniq(accessRoleGroups)
        return {
          id: item.id,
          accessRoleGroups
        }
      })
      this.props.batchUpdateUserGroups({ userInput: variables, project }).then(() => {
        this.onMultipleDSelect()
        this.setState({ isAddUserGroupLoading: false, userIdArr: [], toAddUserGroup: false })
      }, error => {
        this.setState({ isAddUserGroupLoading: false, toAddUserGroup: false })
        utilityService.handleError(error)
      })
    })
  }

  onForceLogout = (id) => {
    this.setState({ isConfirmForceLogoutVisible: true, userIdArr: id })
  }

  toggleForceLogoutessage = (isConfirmForceLogoutVisible) => {
    this.setState({ isConfirmForceLogoutVisible })
  }

  onForceLogoutConfirm = () => {
    const { userIdArr } = this.state
    const currentProject = utilityService.getCurrentProject()
    this.setState({ isModalLoading: true })
    const variables = { user: userIdArr, project: currentProject }
    this.props.forceLogout(variables).then(({ data }) => {
      if (data.error) {
        utilityService.handleError('Force logout failed')
      } else {
        message.success('Force Logout is successful')
      }
      this.setState({ isConfirmForceLogoutVisible: false, isModalLoading: false })
    }, error => {
      this.setState({ isConfirmForceLogoutVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  // updateFilter = (filter) => {
  //     this.props.onFilterChange(filter)
  // }
  makeCsvFile = () => {
    const { userList, createAudit } = this.props
    if (!userList.length) {
      message.warning('User data not available')
      return
    }

    const sortedItems = getSortedUserList(cloneDeep(userList))

    const userData = sortedItems.map(({ name, email, status, lastLogin, accessRoleGroups, enabled }) => {
      const userGroups = accessRoleGroups.map((item) => item.name)
      const userStatus = enabled === false ? 'SUSPENDED' : status
      const userGroupColumns = {}
      userGroups.forEach((group, index) => {
        userGroupColumns[`userGroup-${index + 1}`] = group
      })

      // Determine the maximum number of userGroup columns
      const maxUserGroupColumns = Math.max(1, userGroups.length)

      // Fill missing userGroup columns with empty strings
      for (let i = maxUserGroupColumns + 1; i <= userGroups.length; i++) { // Assuming maximum 10 userGroup columns
        userGroupColumns[`userGroup-${i}`] = ''
      }

      return ({ name, email, userStatus, lastLogin, ...userGroupColumns })
    })

    const csvContent = userData && convertToCSV(userData)

    // Download the CSV file
    downloadCSV(csvContent, 'user_details.csv')
    createAudit({ name: 'User List',
      value: 'CSV Downloaded',
      contentId: 'csv_download' })
  }

  render () {
    const { toAddUser, isSelected, toAddUserGroup, isCreateLoading, isConfirmDeleteVisible, isModalLoading, isConfirmDisableVisible,
      isConfirmEnableVisible, isAllDeleteVisible, userIdArr, isBackButtonClicked, isAddUserGroupLoading, isConfirmResendVisible, isConfirmResetMfaVisible, isConfirmForceLogoutVisible } = this.state
    const { groupList, isLoading, userList, onFilterChange, searchString, filterData } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions, project, superAdmin }) => {
          const userPermissions = permissions['ROLE_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          return <div className='role-user-details '>
            <div className='top filter-content-container' id='role-details'>
              <div className='role-manager-filter '>
                <div className='filter'>
                  <FilterInput
                    filterType={'RoleManager'}
                    onChangeSearchInput={this.props.onSearchChange}
                    searchString={searchString}
                    placement={'bottomRight'}
                    changeFilter={onFilterChange}
                    parentId={'role-details'}
                    project={this.props.project}
                    showFilter
                    bucketAssetFilter={filterData}
                  />
                </div>
              </div>
              <div className='role-manager-buttons'>
                {!isSelected
                  ? <MultipleSelectButton onClick={this.onMultipleSelect} isDisabled={isUpdateDisabled} />
                  : <React.Fragment>
                    <GroupUserButton onClick={this.onAddUserGroup} isDisabled={!(userIdArr && userIdArr.length)} />
                    <DeleteButton onClick={this.onDeleteAllSelectedUser} isDisabled={!(userIdArr && userIdArr.length)} />
                    <BackArrowButton onClick={this.onMultipleDSelect} />
                  </React.Fragment>
                }
                <LoadingButton
                  type='primary'
                  onClick={this.makeCsvFile}
                  htmlType='submit'
                  buttonText='Download CSV'
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
                <LoadingButton
                  type='primary'
                  onClick={this.onAddUser}
                  htmlType='submit'
                  buttonText='Invite New User'
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <AddUserModal
                visible={toAddUser}
                handleSubmit={this.createNewUser}
                handleCancel={this.hideAddUserModal}
                loading={isCreateLoading}
                title={'Invite New User'}
                okText={'Send Invite'}
                isSubmitDisabled={isCreateDisabled}
                groupList={groupList} />

              <AddUserGroupModal
                visible={toAddUserGroup}
                handleSubmit={this.addGroupToMultipleUsers}
                handleCancel={this.hideAddUserGroupModal}
                loading={isAddUserGroupLoading}
                selectedUsers={userList.filter(item => userIdArr.indexOf(item.id) > -1)}
                groupList={groupList} />

            </div>
            <div ref={ref => { this.userListRef = ref }}>
              <RoleUserTable
                isSelected={isSelected}
                isExpand
                groupList={groupList}
                userList={userList}
                isLoading={isLoading}
                onEditUser={this.onEditUser}
                onDeleteUser={this.onDeleteUser}
                onSuspendUser={this.onSuspendUser}
                onEnableUser={this.onEnableUser}
                onChangeSelectUser={this.onChangeSelectUser}
                onDuplicateUser={this.createNewUser}
                onResendInvitation={this.onResendInvitation}
                onResetMfaDevice={this.onResetMfaDevice}
                isBackButtonClicked={isBackButtonClicked}
                isCreateDisabled={isCreateDisabled}
                isUpdateDisabled={isUpdateDisabled}
                isSuperAdmin={superAdmin}
                onForceLogout={this.onForceLogout}
              />
            </div>
            <ConfirmModal
              isVisible={isConfirmDeleteVisible || isAllDeleteVisible}
              title={isConfirmDeleteVisible ? 'Delete user' : 'Delete users'}
              message={isConfirmDeleteVisible ? 'Do you want to delete this user ?' : 'Do you want to delete selected users ?'}
              isLoading={isModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={() => this.toggleDeleteWarningMessage(false)}
              handleSubmit={this.onDeleteConfirmUser}
              isSubmitButtonDisabled={isDeleteDisabled} />
            <ConfirmModal
              isVisible={isConfirmDisableVisible}
              title={'Suspend User'}
              message={'Do you want to suspend this user ?'}
              isLoading={isModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={() => this.toggleSuspendWarningMessage(false)}
              handleSubmit={this.onSuspendConfirmUser}
              isSubmitButtonDisabled={isUpdateDisabled} />
            <ConfirmModal
              isVisible={isConfirmEnableVisible}
              title={'Enable User'}
              message={'Do you want to enable this user ?'}
              isLoading={isModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={() => this.toggleEnableWarningMessage(false)}
              handleSubmit={this.onEnableConfirmUser}
              isSubmitButtonDisabled={isUpdateDisabled} />
            <ConfirmModal
              isVisible={isConfirmResendVisible}
              title={'Resend Invitation'}
              message={'Do you want to resend invitation ?'}
              isLoading={isModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={() => this.toggleResendWarningMessage(false)}
              handleSubmit={this.onResendConfirmUser}
              isSubmitButtonDisabled={isCreateDisabled} />
            <ConfirmModal
              isVisible={isConfirmResetMfaVisible}
              title={'Reset MFA Device'}
              message={'Do you want to reset current MFA device ?'}
              isLoading={isModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={() => this.toggleResetWarningMessage(false)}
              handleSubmit={this.onResetConfirmUser}
              isSubmitButtonDisabled={isCreateDisabled} />
            <ConfirmModal
              isVisible={isConfirmForceLogoutVisible}
              title={'Force Logout User '}
              message={'Do you want to Force Logout User ?'}
              isLoading={isModalLoading}
              rightButtonText={'Confirm'}
              handleCancel={() => this.toggleForceLogoutessage(false)}
              handleSubmit={this.onForceLogoutConfirm}
              isSubmitButtonDisabled={isCreateDisabled} />
          </div>
        }}
      </AppContext.Consumer>
    )
  }
}

RoleUserDetails.propTypes = {}

RoleUserDetails.defaultProps = {}

export default withApollo(compose(

  graphql(
    QueryUserList,
    {
      options: (props) => {
        const variables = { search: props.searchString, project: props.project }
        if (props.enabled !== null) {
          variables.enabled = props.enabled
        }
        if (props.status) {
          variables.status = props.status
        }
        if (props.accessRoleGroups) {
          variables.accessRoleGroups = props.accessRoleGroups
        }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        return {
          userList: data.listUsers && data.listUsers.items && data.listUsers.items.length ? data.listUsers.items : [],
          isLoading: data.loading,
          totalCount: data.listUsers ? data.listUsers.totalCount : 0,
          getMoreUsers: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listUsers.items, ...fetchMoreResult.listUsers.items]
                prev.listUsers.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    QueryGroupList,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        const { data } = props
        return {
          groupList: data.getAccessRoleGroups && data.getAccessRoleGroups.length ? _.sortBy(data.getAccessRoleGroups, 'name') : []
        }
      }
    }
  ),
  graphql(
    MutationCreateUser,
    {
      options: ({ userSearch, project }) => {
        const variables = { search: userSearch, project }
        return {
          update: (cache, { data: { createUser } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserList, variables }))
            if (cacheData && cacheData.listUsers && cacheData.listUsers.items) {
              cacheData.listUsers.items = [createUser, ...cacheData.listUsers.items]
            }
            cache.writeQuery({
              query: QueryUserList,
              data: cacheData,
              variables
            })
          }
        }
      },
      // options: (props) => {
      //   const variables = { search: props.userSearch }
      //   return {
      //     refetchQueries: () => [{ query: QueryUserList, variables, fetchPolicy: 'network-only' }]
      //   }
      // },
      props: (props) => ({
        createNewUser: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateUser,
    {
      options: ({ userSearch, project }) => {
        const variables = { search: userSearch, project }
        return {
          update: (cache, { data: { updateUser } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserList, variables }))
            if (cacheData && cacheData.listUsers && cacheData.listUsers.items) {
              const index = cacheData.listUsers.items.findIndex(item => item.id === updateUser.id)
              cacheData.listUsers.items[index] = updateUser
            }
            cache.writeQuery({
              query: QueryUserList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        updateUser: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationSuspendUser,
    {
      options: ({ userSearch, project }) => {
        const variables = { search: userSearch, project }
        return {
          update: (cache, { data: { disableUser } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserList, variables }))
            if (cacheData && cacheData.listUsers && cacheData.listUsers.items) {
              const index = cacheData.listUsers.items.findIndex(item => item.id === disableUser.id)
              cacheData.listUsers.items[index].enabled = false
            }
            cache.writeQuery({
              query: QueryUserList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        suspendUsers: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationEnableUser,
    {
      options: ({ userSearch, project }) => {
        const variables = { search: userSearch, project }
        return {
          update: (cache, { data: { enableUser } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserList, variables }))
            if (cacheData && cacheData.listUsers && cacheData.listUsers.items) {
              const index = cacheData.listUsers.items.findIndex(item => item.id === enableUser.id)
              cacheData.listUsers.items[index].enabled = true
            }
            cache.writeQuery({
              query: QueryUserList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        enableUser: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchUpdateUserGroups,
    {
      options: (props) => {
        const variables = { search: props.userSearch, project: props.project }
        return {
          update: (cache, { data: { batchUpdateUserGroups } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserList, variables }))
            if (cacheData && cacheData.listUsers && cacheData.listUsers.items) {
              cacheData.listUsers.items = cacheData.listUsers.items.map(item => {
                const newUserDetails = batchUpdateUserGroups.find(innerItem => innerItem.id === item.id)
                if (!_.isEmpty(newUserDetails)) {
                  item.accessRoleGroups = newUserDetails.accessRoleGroups
                }
                return item
              })
            }
            cache.writeQuery({
              query: QueryUserList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        batchUpdateUserGroups: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteUsers,
    {
      options: ({ project, userSearch }) => {
        const variables = { search: userSearch, project }
        return {
          update: (cache, { data: { deleteUsers } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserList, variables }))
            if (cacheData && cacheData.listUsers && cacheData.listUsers.items) {
              const newList = cacheData.listUsers.items.filter(item => {
                const index = deleteUsers.findIndex((innerItem) => innerItem.id === item.id)
                return index === -1
              })
              cacheData.listUsers.items = [...newList]
            }
            cache.writeQuery({
              query: QueryUserList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        deleteUsers: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationResendInvitaionUsers,
    {
      props: (props) => ({
        resendUserInvitation: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationResetMfaDevice,
    {
      props: (props) => ({
        reset2FADevice: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationForceLogout,
    {
      props: (props) => ({
        forceLogout: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateAudit,
    {
      props: (props) => ({
        createAudit: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(RoleUserDetails))
