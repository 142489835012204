import React, { Component } from 'react'
import TabPanel from '../../components/ui/dataDisplay/TabPanel'
import MediaDetails from './MediaDetails'
import PropTypes from 'prop-types'
import ListAsset from './AssetList'
import HistoryList from '../auditManager/HistoryList'
import _ from 'lodash'
const detailsTabs = [
  {
    displayName: 'Details',
    mode: 'details'
  }, {
    displayName: 'Assets',
    mode: 'assets'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

const detailTab = [
  {
    displayName: 'Details',
    mode: 'details'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

class ContentDetails extends Component {
  state = {
    mode: 'details',
    mediaDetails: {}
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.selectedMedia, this.props.selectedMedia)) {
      this.setState({ mode: 'details' })
    }
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  setMediaDetails = ({ details }) => {
    this.setState({ mediaDetails: details })
  }

  render () {
    const { mode, mediaDetails } = this.state
    const { onDeleteMedia, appliedFilter, selectedMedia, isFolder, folderData, project, isSaving } = this.props
    return (
      <div className='content-details'>
        <TabPanel tabs={isFolder ? detailTab : detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
        {mode === 'details' ? <MediaDetails selectedMedia={selectedMedia} onDeleteMedia={onDeleteMedia} appliedFilter={appliedFilter} setMediaDetails={this.setMediaDetails} isFolder={isFolder} folderData={folderData} project={project} /> : null }
        {mode === 'assets' ? <ListAsset selectedMedia={selectedMedia} project={project} /> : null }
        {mode === 'history' ? <HistoryList contentId={selectedMedia} showFilter={false} content='CONTENT_BANK' mediaDetails={mediaDetails} project={project} isChanging={isSaving} /> : null }
      </div>
    )
  }
}
ContentDetails.propTypes = {
  /** selectedMedia of ContentDetails */
  selectedMedia: PropTypes.string,
  /** delete media  MediaDetails. */
  onDeleteMedia: PropTypes.func
}

export default ContentDetails
