import { isFunction } from 'lodash'
import { isJWPlayer, playerId } from '../../config/playerConfig'
import bitmovinPlayer from '../bitmovin/bitmovin'
const disableLogs = true
const playerDebugger = {
  log: msg => {
    if (!disableLogs) {
      console.log('Player', msg)
    }
  }
}
const WIDEVINE = 'widevine'
const PLAYREADY = 'playready'
const FAIRPLAY = 'fairplay'

export const getActivePlayer = () => {
  const activePlayer = isJWPlayer ? window.jwplayer(playerId) : bitmovinPlayer
  return {
    getPosition: () => activePlayer.getPosition(),
    getBuffer: () => activePlayer.getBuffer(),
    play: () => activePlayer.play(),
    pause: () => activePlayer.pause(),
    getState: () => activePlayer.getState(),
    seek: time => {
      activePlayer.seek(time)
    },
    remove: () => activePlayer.remove(),
    resize: (width, height) => {
      activePlayer.resize(width, height)
    },
    getVolume: () => activePlayer.getVolume(),
    isMuted: () => {
      if (isJWPlayer) {
        return activePlayer.getMute()
      } else {
        return activePlayer.isMuted()
      }
    },
    unmute: () => {
      if (!isJWPlayer) {
        activePlayer.unMutePlayer()
      }
    },
    mute: () => {
      if (!isJWPlayer) {
        activePlayer.mutePlayer()
      }
    },
    setVolume: (volume) => {
      if (!isJWPlayer) {
        activePlayer.setVolume(volume)
      }
    },
    togglePlayPause: (showPlayer) => {
      if (showPlayer) {
        activePlayer.play()
      } else {
        activePlayer.pause()
      }
    }

  }
}

export const getStreamURL = playlist => {
  return playlist && Array.isArray(playlist) && playlist.length > 0
    ? playlist[0] && playlist[0].sources
      ? playlist[0].sources[0].file
      : playlist[0] && playlist[0].file
        ? playlist[0].file
        : ''
    : ''
}

const getLicenseURL = (playlist, drm) => {
  if (
    playlist &&
    playlist[0] &&
    playlist[0].sources &&
    playlist[0].sources[0].drm &&
    playlist[0].sources[0].drm[drm]
  ) {
    if (drm === FAIRPLAY) {
      return playlist[0].sources[0].drm[drm].processSpcUrl
    }
    return playlist[0].sources[0].drm[drm].url
  }
  return ''
}
const getCertificateURL = (playlist, drm) => {
  if (
    playlist &&
    playlist[0] &&
    playlist[0].sources &&
    playlist[0].sources[0].drm &&
    playlist[0].sources[0].drm[drm]
  ) {
    return playlist[0].sources[0].drm[drm].certificateUrl
  }
  return ''
}

const getDrmData = (playlist, drm) => {
  if (
    playlist &&
    playlist[0] &&
    playlist[0].sources &&
    playlist[0].sources[0].drm &&
    playlist[0].sources[0].drm[drm] &&
    playlist[0].sources[0].drm[drm].licenseRequestHeaders[0]
  ) {
    return playlist[0].sources[0].drm[drm].licenseRequestHeaders[0].value
  }
  return ''
}
const getFairPlayConfig = playlist => {
  return {
    LA_URL: getLicenseURL(playlist, FAIRPLAY),
    headers: {
      'X-AxDRM-Message': getDrmData(playlist, FAIRPLAY)
    },
    certificateURL: getCertificateURL(playlist, FAIRPLAY),
    withCredentials: false,
    prepareContentId: rawContentId => {
      const tmp = rawContentId.split('/')
      return tmp[tmp.length - 1]
    },
    prepareMessage: (event, session) => {
      return new Blob([event.message], {
        type: 'application/octet-binary'
      })
    },
    prepareLicenseAsync: ckc => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', () => {
          const array = new Uint8Array(reader.result)
          resolve(array)
        })
        reader.addEventListener('error', () => {
          reject(reader.error)
        })
        reader.readAsArrayBuffer(ckc)
      })
    },
    useUint16InitData: true,
    licenseResponseType: 'blob'
  }
}
const getWidevineConfig = playlist => {
  return {
    LA_URL: getLicenseURL(playlist, WIDEVINE),
    headers: {
      'X-AxDRM-Message': getDrmData(playlist, WIDEVINE)
    }
  }
}
const getPlayReadyConfig = playlist => {
  return {
    LA_URL: getLicenseURL(playlist, PLAYREADY),
    headers: {
      'X-AxDRM-Message': getDrmData(playlist, PLAYREADY)
    }
  }
}
/**
 * Simple utility functions to help player and playback ; enclosed under an object PlayerUtils
 */
const PlayerUtils = {
  playerDebugger,
  getActivePlayer,
  getPlayerCallbacks: props => {
    return {
      PlaybackFinished: err => {
        playerDebugger.log(err)
        if (isFunction(props.onOneHundredPercent)) {
          props.onOneHundredPercent(err)
        }
      },

      SourceLoaded: err => {
        playerDebugger.log(err)
        if (isFunction(props.onVideoLoad)) {
          props.onVideoLoad(err)
        }
      },

      onReady: err => {
        playerDebugger.log(err)
        if (isFunction(props.onReady)) {
          props.onReady(err)
        }
      },
      onAutoStart: err => {
        playerDebugger.log(err)
        if (isFunction(props.onAutoStart)) {
          props.onAutoStart(err)
        }
      },
      Paused: err => {
        playerDebugger.log(err)
        if (isFunction(props.onPause)) {
          props.onPause(err)
        }
      },
      Play: err => {
        playerDebugger.log(err)
        if (isFunction(props.onPlay)) {
          props.onPlay(err)
        }
      },
      onResume: (err) => {
        if (isFunction(props.onResume)) {
          props.onResume(err)
        }
      },
      Playing: val => {
        playerDebugger.log(val)
        if (isFunction(props.onAutoStart)) {
          props.onAutoStart(val)
        }
      },
      Error: err => {
        playerDebugger.log(err)
        if (isFunction(props.onError)) {
          props.onError(err)
        }
      },
      onSetupError: err => {
        playerDebugger.log(err)
        if (isFunction(props.onSetupError)) {
          props.onSetupError(err)
        }
      },
      onClickProgressBar: err => {
        playerDebugger.log(err)
        if (isFunction(props.onClickProgressBar)) {
          props.onClickProgressBar(err)
        }
      }
    }
  },
  getBitmovinPlayerConfig: function (playlist) {
    if (!playlist || !Array.isArray(playlist)) return
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const playerConfig = {}
    if (isSafari) {
      playerConfig.hls = getStreamURL(playlist)
      playerConfig.drm = {
        fairplay: getFairPlayConfig(playlist)
      }
    } else {
      playerConfig.dash = getStreamURL(playlist)
      playerConfig.drm = {
        widevine: getWidevineConfig(playlist),
        playready: getPlayReadyConfig(playlist)
      }
    }
    return playerConfig
  }
}

export default PlayerUtils
