import gql from 'graphql-tag'

export default gql(`
    mutation deleteAssetCreditLinks($input: [ID] $assetId: ID $project:String) {
        deleteAssetCreditLinks(
            input: $input
            assetId: $assetId
            project: $project
        )
    }`
)
