import gql from 'graphql-tag'

export default gql(`
    mutation batchUnpublishAssets($batchJobId: ID!) {
        batchUnpublishAssets(
            batchJobId: $batchJobId
        ) {
            isUnpublished
        }
    }`
)
