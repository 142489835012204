import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { TimePicker, message, Checkbox } from 'antd'
import moment from 'moment'

import { GlobalHotKeys } from 'react-hotkeys'
import './Player.scss'
import _ from 'lodash'
import CollapseIcon from './icons/CollapseIcon'
import ExpandIcon from './icons/ExpandIcon'
import SaveButton from './SaveButton'
import CreateVodButton from './CreateVodButton'
import TrimInIcon from './icons/TrimInIcon'
import TrimOutIcon from './icons/TrimOutIcon'
import SeekMarkInIcon from './icons/SeekMarkInIcon'
import SeekMarkOutIcon from './icons/SeekMarkOutIcon'
import TrimmerIcon from '../ui/general/icons/TrimmerIcon'
import userMessages from './../../constants/messages'
import DropDown from '../ui/dataEntry/inputs/DropDown'
import ButtonContainer from '../ui/general/buttons/ButtonContainer'
import PlayerRangeDropDown from './PlayerRangeDropDown'
import ConfirmModal from '../ui/feedback/ConfirmModal'
import { getActivePlayer } from './jsPlayers/Utils/playerUtils'

// const bufferTime = 15
const encodingOptions = [
  {
    id: 'DEFAULT',
    name: 'Default'
  },
  {
    id: 'PRI',
    name: 'Primary'
  },
  {
    id: 'SEC',
    name: 'Secondary'
  },
  {
    id: 'XBTSS',
    name: 'Multi'
  }
]

const keyMap = {
  shortcutMarkInInc: 'w',
  shortcutMarkInDec: 'q',
  shortcutMarkOutInc: 'r',
  shortcutMarkOutDec: 'e',
  shortcutSeekInc: 'x',
  shortcutSeekDec: 'z',
  stepSeekInc: 'right',
  stepSeekDec: 'left',
  volumeChangeInc: 'up',
  volumeChangeDec: 'down'
}

const duplicateRunningMessage = {
  message:
    'Currently there is an active job. Re-triggering a new job will create a new video and the latest video will be set as default. Do you want to proceed?',
  okButtonText: 'Proceed',
  cancelButtonText: 'Wait'
}

class PlayerMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      markIn: '00:00:00',
      markOut: '03:55:00',
      isEdited: false,
      selectedEncoder: 'DEFAULT',
      drm: props.drm === null ? true : props.drm
    }
    this.isDateOpen = { markIn: false, markOut: false }
  }

  componentDidMount = () => {
    this.originalDuration = this.props.duration
    const { markIn, markOut } = this.getMarkInMarkOut(_.cloneDeep(this.props))
    this.defaultMarkInMarkout = { markIn, markOut }
    this.setState({
      markIn,
      markOut,
      selectedEncoder: this.props.savedSite
        ? this.props.savedSite
        : this.props.site
          ? this.props.site
          : 'DEFAULT'
    })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (
      newProps.markIn &&
      newProps.markOut &&
      (!this.props.markIn ||
        !this.props.markOut ||
        newProps.markIn.format() !== this.props.markIn.format() ||
        newProps.markOut.format() !== this.props.markOut.format())
    ) {
      this.originalDuration = newProps.duration
      const { markIn, markOut } = this.getMarkInMarkOut(_.cloneDeep(newProps))
      this.defaultMarkInMarkout = { markIn, markOut }
      this.setState({ markIn, markOut })
    } else if (!_.isEqual(newProps.savedTrim, this.props.savedTrim)) {
      const { markIn, markOut } = this.getMarkInMarkOut(_.cloneDeep(newProps))
      this.defaultMarkInMarkout = { markIn, markOut }
      this.setState({ markIn, markOut })
    }
    if (!newProps.isSavingTrim && this.props.isSavingTrim) {
      this.setState({ isEdited: false })
    }
    if (newProps.isSaved !== this.props.isSaved) {
      if (!newProps.isSaved) {
        this.setState({ isLoading: true })
      } else if (this.state.isLoading) {
        this.setState({ isLoading: false }, () => {
          if (!newProps.isError) {
            this.toggleTrimOption()
          }
        })
      }
    }
    if (newProps.drm !== this.props.drm) {
      this.setState({ drm: newProps.drm })
    }
  }

  componentWillUnmount = () => {
    // clearInterval(this.setLiveStatusInterval)
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  keyHandlers = {
    shortcutMarkInInc: () => {
      this.shortKeyMarkChange(true, true)
    },
    shortcutMarkInDec: () => {
      this.shortKeyMarkChange(true, false)
    },
    shortcutMarkOutInc: () => {
      this.shortKeyMarkChange(false, true)
    },
    shortcutMarkOutDec: () => {
      this.shortKeyMarkChange(false, false)
    },
    shortcutSeekDec: () => {
      this.shortKeySeekChange(false, 1)
    },
    shortcutSeekInc: () => {
      this.shortKeySeekChange(true, 1)
    },
    stepSeekInc: () => {
      this.shortKeySeekChange(true, 5)
    },
    stepSeekDec: () => {
      this.shortKeySeekChange(false, 5)
    },
    volumeChangeInc: () => {
      this.shortKeyVolumeChange(true)
    },
    volumeChangeDec: () => {
      this.shortKeyVolumeChange(false)
    }
  }

  shortKeyVolumeChange = (isInc) => {
    const player = getActivePlayer()
    if (player) {
      let volume = player.getVolume()
      if (isInc) {
        if (player.isMuted()) {
          player.unmute()
        }
        volume += 5
      } else {
        if (volume <= 0) {
          player.mute()
        }
        volume -= 5
      }
      player.setVolume(volume <= 0 ? 0 : volume >= 100 ? 100 : volume)
    }
  }

  shortKeySeekChange = (isInc, step) => {
    const player = getActivePlayer()
    let { startTime, playTime, defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    let start = moment(startTime).add(-bufferTime, 'minute')
    const end = _.cloneDeep(start).add(playTime, 'seconds')
    const isLive = moment().isBetween(start, end)
    if (player) {
      let position = player.getPosition()
      if (isLive && isInc) {
        position += step
      } else if (isLive && !isInc) {
        position -= step
      } else if (!isLive && isInc) {
        position += step
      } else {
        position -= step
      }
      player.seek(position)
    }
  }

  shortKeyMarkChange = (isMarkIn, isInc) => {
    const { markOut, markIn } = this.state
    if (markIn === '00:00:00' && !isInc) {
      return
    }
    const time = _.cloneDeep(isMarkIn ? markIn : markOut)
    let startSeconds = time.split(':')
    startSeconds =
      parseInt(startSeconds[0] * 3600) + parseInt(startSeconds[1] * 60) + parseInt(startSeconds[2])
    isInc ? startSeconds++ : startSeconds--
    const changedTime = this.getFormattedDurationTime(startSeconds)
    if (isMarkIn) {
      this.onChangeMarkIn(undefined, changedTime)
    } else {
      this.onChangeMarkOut(undefined, changedTime)
    }
  }

  getMarkInMarkOut = timeDetails => {
    let markIn, markOut
    const start = moment(new Date(timeDetails.startTime))
    const end = moment(new Date(timeDetails.endTime))
    let { defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    const startTime = start.add(-bufferTime, 'minute')
    let isValidSavedTrim = false
    if (timeDetails.savedTrim) {
      markIn = this.getFormattedDurationTime(
        moment(timeDetails.savedTrim.markIn).diff(startTime, 'seconds')
      )
      markOut = this.getFormattedDurationTime(
        moment(timeDetails.savedTrim.markOut).diff(startTime, 'seconds')
      )
      if (markIn !== 'Invalid' && markOut !== 'Invalid') {
        isValidSavedTrim = true
        this.currentMarker = {
          markIn: moment(timeDetails.savedTrim.markIn),
          markOut: moment(timeDetails.savedTrim.markOut)
        }
      }
    }
    if (isValidSavedTrim) {
      this.isDateOpen = { markIn: false, markOut: false }
      return { markIn, markOut }
    } else if (timeDetails.markIn && startTime.isSameOrBefore(moment(timeDetails.markIn))) {
      markIn = this.getFormattedDurationTime(moment(timeDetails.markIn).diff(startTime, 'seconds'))
      markOut = this.getFormattedDurationTime(
        moment(timeDetails.markOut).diff(startTime, 'seconds')
      )
      this.currentMarker = {
        markIn: moment(timeDetails.markIn),
        markOut: moment(timeDetails.markOut)
      }
    } else {
      markIn = `00:${bufferTime}:00`
      markOut = this.getFormattedDurationTime(end.diff(startTime, 'seconds'))
      this.currentMarker = {
        markIn: moment(timeDetails.startTime),
        markOut: moment(timeDetails.endTime)
      }
    }
    this.isDateOpen = { markIn: false, markOut: false }

    return { markIn, markOut }
  }

  getFormattedDurationTime = seconds => {
    if (seconds < 0) {
      return 'Invalid'
    }
    const second = seconds % 60
    const minutes = parseInt(seconds / 60)
    const minute = minutes % 60
    const hour = parseInt(minutes / 60)
    return `${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}:${
      second > 9 ? second : `0${second}`
    }`
  }

  toggleWarningMessage = isOpen => {
    if (!isOpen) {
      this.setState({ showTriggerWarning: isOpen, isRunningVod: false })
      return
    }
    const { vodJobs } = this.props
    const isLiveToVodJobRunning = (vodJobs || []).filter(
      job =>
        job.jobType === 'LIVE_TO_VOD' &&
        job.status !== 'COMPLETE' &&
        job.status !== 'PUBLISHED' &&
        job.status !== 'ERROR'
    )
    this.setState({
      showTriggerWarning: isOpen,
      isRunningVod: isLiveToVodJobRunning && isLiveToVodJobRunning.length
    })
  }

  changedDateOpenStatus = type => {
    this.isDateOpen[type] = !this.isDateOpen[type]
  }

  secondsToHms = d => {
    d = parseInt(d)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)
    return `${h}:${m}:${s}`
  }

  onChangeMarkIn = (time, markIn) => {
    if (markIn) {
      const { startTime, playTime, defaultTrimStart } = this.props
      let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
      let tempMarkin = _.cloneDeep(markIn)
      let startSeconds = markIn.split(':')
      startSeconds =
        parseInt(startSeconds[0] * 3600) +
        parseInt(startSeconds[1] * 60) +
        parseInt(startSeconds[2])
      if (startSeconds > playTime) {
        startSeconds = playTime
        tempMarkin = this.secondsToHms(playTime)
      }
      let start = moment(startTime).add(-bufferTime, 'minute')
      start = start.add(startSeconds, 'seconds')
      this.currentMarker.markIn = start
      this.setState({ markIn: tempMarkin, isEdited: true }, () => this.seekToMarkPosition(true))
    }
  }

  onChangeMarkOut = (time, markOut) => {
    if (markOut) {
      const { startTime, playTime, defaultTrimStart } = this.props
      let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
      let tempMarkout = _.cloneDeep(markOut)
      let endSeconds = markOut.split(':')
      endSeconds =
        parseInt(endSeconds[0] * 3600) + parseInt(endSeconds[1] * 60) + parseInt(endSeconds[2])
      if (endSeconds > playTime) {
        endSeconds = playTime
        tempMarkout = this.secondsToHms(playTime)
      }
      let end = moment(startTime).add(-bufferTime, 'minute')
      end = end.add(endSeconds, 'seconds')
      this.currentMarker.markOut = end
      this.setState({ markOut: tempMarkout, isEdited: true }, () => this.seekToMarkPosition(false))
    }
  }

  onChangeEncoder = value => {
    this.setState({ selectedEncoder: value, isEdited: true })
  }

  range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  disabledHours = type => {
    let { playTime } = this.props
    let hours = this.range(0, 24)
    if (playTime) {
      playTime = playTime / 60
      const hourLimit = parseInt(playTime / 60)
      if (hourLimit <= 24) {
        hours.splice(0, hourLimit + 1)
      } else hours.splice(0, 24)
    } else hours.splice(0, 6)
    return hours
  }

  disabledMinutes = (type, selectedHour) => {
    let { playTime } = this.props
    if (playTime) {
      playTime = playTime / 60
      const hourLimit = parseInt(playTime / 60)
      const minuteLimit = parseInt(playTime % 60)
      let minutes = this.range(0, 60)
      if (hourLimit === selectedHour) {
        minutes.splice(0, minuteLimit)
        return minutes
      } else return []
    } else return []
  }

  getPlayTimePosition = time => {
    const { defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    const start = moment(new Date(this.props.startTime)).add(-bufferTime, 'minute')
    // const totalSeconds = isLive ? moment().diff(start, 'second') : this.props.playTime
    const totalSeconds = this.props.playTime
    const currentTime = moment(time).diff(start, 'second')
    const percentage = (currentTime * 100) / totalSeconds
    return percentage.toFixed(2)
  }

  getMarkPosition = time => {
    // const totalHours = this.originalDuration.split('h')[0]
    // const totalMinutes = this.originalDuration.split('h ')[1].split('m')[0]
    // const totalSeconds = (totalHours * 60 + parseInt(totalMinutes)) * 60 + buffer * 2
    const totalSeconds = this.props.playTime
    const currentTimeArray = time.split(':')
    const currentSecond =
      (parseInt(currentTimeArray[0]) * 60 + parseInt(currentTimeArray[1])) * 60 +
      parseInt(currentTimeArray[2])
    const percentage = (currentSecond * 100) / totalSeconds
    return percentage.toFixed(2)
  }

  getAdMarkPosition = (time, duration) => {
    const totalSeconds = duration ? parseFloat(duration) : this.props.playTime
    const percentage = (parseFloat(time) * 100) / totalSeconds
    return percentage.toFixed(2)
  }

  // getLiveMarkPosition = isStart => {
  //   const { startTime } = this.props
  //   const { markOut, markIn } = this.state
  //   let offset = 0
  //   if (isStart) {
  //     if (markIn !== '00:00:00') {
  //       const currentTimeArray = markIn.split(':')
  //       offset = (currentTimeArray[0] * 60 + parseInt(currentTimeArray[1])) * 60 + parseInt(currentTimeArray[2])
  //     }
  //   } else {
  //     const durationOffset = '05:55:00'
  //     if (markOut !== durationOffset) {
  //       const currentTimeArray = markOut.split(':')
  //       offset = (currentTimeArray[0] * 60 + parseInt(currentTimeArray[1])) * 60 + parseInt(currentTimeArray[2])
  //       // const durationTimeArray = durationOffset.split(':')
  //       // offset = (durationTimeArray[0] * 60 + parseInt(durationTimeArray[1])) * 60 + parseInt(durationTimeArray[2]) - (currentTimeArray[0] * 60 + parseInt(currentTimeArray[1])) * 60 + parseInt(currentTimeArray[2])
  //       // offset = -offset
  //     }
  //   }
  //   const startTimeObj = moment(new Date(startTime)).add(-bufferTime, 'minute')
  //   const now = moment(new Date())
  //   // const time = startTime
  //   const totalDiff = now.diff(startTimeObj)
  //   const currentDiff = now.diff(startTimeObj.add(offset, 'seconds'))
  //   const percentage = (currentDiff * 100) / totalDiff
  //   return 100 - percentage.toFixed(2)
  // }

  // checkSaveEnable = (markIn, markOut) => {
  //   if (markIn && markOut) {
  //     let startSeconds = markIn.split(':')
  //     startSeconds = parseInt(startSeconds[0] * 3600) + parseInt(startSeconds[1] * 60) + parseInt(startSeconds[2])
  //     let endSeconds = markOut.split(':')
  //     endSeconds = parseInt(endSeconds[0] * 3600) + parseInt(endSeconds[1] * 60) + parseInt(endSeconds[2])
  //     return startSeconds < endSeconds
  //   } else return false
  // }

  toggleTrimOption = () => {
    this.props.onEditChange(!this.props.allowTrim)
    if (this.props.allowTrim) {
      const { markIn, markOut } = this.defaultMarkInMarkout
      this.setState({ markIn, markOut })
    }
  }

  // getLiveStatus = () => {
  //   const { startTime, playTime } = this.props
  //   const endTimeObj = moment(new Date(startTime)).add(-bufferTime, 'minute').add(playTime, 'second')
  //   const now = moment(new Date())
  //   return now.isBefore(endTimeObj)
  // }

  // changeTimer = () => {
  //   const time = this.state.time || 0
  //   this.setState({ time: time + 1 })
  // }

  onMouseDown = (type, e) => {
    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('mouseup', this.handleMouseUp)
    this.setState({ type, isDragging: true })
  }

  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
    this.setState({ type: undefined, isDragging: false })
  }

  seekToMarkPosition = isMarkIn => {
    const { startTime, playTime, defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    const { markIn, markOut } = this.state
    const time = isMarkIn ? markIn : markOut
    const currentTimeArray = time.split(':')
    let start = moment(startTime).add(-bufferTime, 'minute')
    const currentSecond =
      (parseInt(currentTimeArray[0]) * 60 + parseInt(currentTimeArray[1])) * 60 +
      parseInt(currentTimeArray[2])
    const selectedTime = _.cloneDeep(start).add(currentSecond, 'seconds')
    const end = _.cloneDeep(start).add(playTime, 'seconds')
    const isLive = moment().isBetween(start, end)
    const player = getActivePlayer()
    if (player) {
      if (isLive) {
        let seekTime = selectedTime.diff(moment(), 'seconds')
        if (seekTime <= 0) {
          player.seek(seekTime)
        }
      } else {
        const seekTime = selectedTime.diff(start, 'seconds')
        player.seek(seekTime)
      }
    }
  }

  handleMouseMove = e => {
    const { isDragging, type } = this.state
    const { playTime, startTime } = this.props
    if (!isDragging) {
      return
    }
    const progressBar = document.getElementById('player-progress')
    const rect = progressBar.getBoundingClientRect()
    const x = e.clientX - rect.left // x position within the element.
    const width = progressBar.clientWidth
    const seekPercentage = x / width
    const seekTime = Math.round(seekPercentage * playTime)
    const { defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    const startTimeFormatted = moment(startTime).add(-bufferTime, 'minute')
    const movedTime = _.cloneDeep(startTimeFormatted).add(seekTime, 'seconds')
    this.currentMarker[type] = movedTime
    const diff = movedTime.diff(startTimeFormatted, 'seconds')
    if (diff < 0 || diff > playTime) {
      return
    }
    const markInTime = this.getFormattedDurationTime(diff)
    this.props.seekPlayer(width, x)
    this.setState({ [type]: markInTime, isEdited: true })
  }

  updateSavedTrim = () => {
    const { startTime, defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    const { markOut, markIn, selectedEncoder, drm } = this.state
    if (!markOut || !markIn) {
      message.error(userMessages.INVALID_MARK_TIME)
      return
    }
    let startSeconds = markIn.split(':')
    startSeconds =
      parseInt(startSeconds[0] * 3600) + parseInt(startSeconds[1] * 60) + parseInt(startSeconds[2])
    let endSeconds = markOut.split(':')
    endSeconds =
      parseInt(endSeconds[0] * 3600) + parseInt(endSeconds[1] * 60) + parseInt(endSeconds[2])
    let startObj = moment(startTime).add(-bufferTime, 'minute')
    const start = _.cloneDeep(startObj).add(startSeconds, 'seconds')
    const end = _.cloneDeep(startObj).add(endSeconds, 'seconds')
    // const now = moment(new Date())
    // if (now.isBefore(end) || now.isBefore(start)) {
    //   message.error(userMessages.INCOMPLETE_MARKOUT)
    //   return
    // } else if (end.isSameOrBefore(start)) {
    //   message.error(userMessages.INVALID_MARK_TIME)
    //   return
    // }
    if (end.isSameOrBefore(start)) {
      message.error(userMessages.INVALID_MARK_TIME)
      return
    }
    this.props.onSaveTrim(start, end, selectedEncoder, drm)
  }

  updateVideoEncoding = () => {
    const { startTime, playTime, defaultTrimStart } = this.props
    let bufferTime = defaultTrimStart !== null ? defaultTrimStart / 60 : 15
    const { markOut, markIn, selectedEncoder, drm } = this.state
    if (!markOut || !markIn) {
      message.error(userMessages.INVALID_MARK_TIME)
      return
    }
    let startSeconds = markIn.split(':')
    startSeconds =
      parseInt(startSeconds[0] * 3600) + parseInt(startSeconds[1] * 60) + parseInt(startSeconds[2])
    let endSeconds = markOut.split(':')
    endSeconds =
      parseInt(endSeconds[0] * 3600) + parseInt(endSeconds[1] * 60) + parseInt(endSeconds[2])
    let startObj = moment(startTime).add(-bufferTime, 'minute')
    let endObj = _.cloneDeep(startObj).add(playTime, 'seconds')
    const start = _.cloneDeep(startObj).add(startSeconds, 'seconds')
    const end = _.cloneDeep(startObj).add(endSeconds, 'seconds')
    const now = moment(new Date())
    const isLive = now.isBefore(endObj)
    if (now.isBefore(end) || now.isBefore(start)) {
      message.error(userMessages.INCOMPLETE_MARKOUT)
      return
    } else if (end.isSameOrBefore(start)) {
      message.error(userMessages.INVALID_MARK_TIME)
      return
    }
    this.setState({ showTriggerWarning: false })
    this.props.updateVideoEncoding(start, end, selectedEncoder, isLive, drm)
  }

  onChangeDrm = e => {
    this.setState({ drm: e.target.checked, isEdited: true })
  }

  getAdMarkers = selectedVideo => {
    const { adPositions, cuePoints, duration } = selectedVideo
    let adMarkers =
      typeof adPositions === 'string'
        ? adPositions
          .replace(/[, ]+/g, ',')
          .trim()
          .split(',')
        : adPositions
    let cuePointList =
      typeof cuePoints === 'string'
        ? cuePoints
          .replace(/[, ]+/g, ',')
          .trim()
          .split(',')
        : cuePoints

    return (adMarkers || []).map((position, index) => {
      const markPosition = this.getAdMarkPosition(cuePointList[index], duration)
      return (
        <div className='ad-marker-container' key={index}>
          <div className='marker-value' style={{ left: `calc( ${markPosition}% - 30px)` }}>
            <div className='overlay'>
              <div />
              <span>{position}</span>
            </div>
          </div>
          <div className='marker' style={{ left: `${markPosition}%` }} />
        </div>
      )
    })
  }

  getRangeDetails = () => {
    const { isSavingTrim, savedTrim, isEditable, allowTrim } = this.props
    const {
      markOut,
      markIn,
      selectedEncoder,
      isLoading,
      isEdited,
      showTriggerWarning,
      isRunningVod,
      drm
    } = this.state
    let markInTime
    let markInMinute = markIn.split(':')[1]
    if (markInMinute > 59) {
      markInTime = moment
        .utc()
        .startOf('day')
        .add({ minutes: markIn.split(':')[1] })
        .format('HH:mm:ss')
    } else {
      markInTime = markIn
    }
    const diff = moment(markOut, 'HH:mm:ss').diff(moment(markInTime, 'HH:mm:ss'), 'seconds')
    const duration = this.getFormattedDurationTime(diff)

    const title = 'Create On Demand Video'
    const messageText = 'Confirm triggering of a new Live2VOD Job ?'

    if (isEditable && allowTrim) {
      this.markInDatePicker = (
        <TimePicker
          value={moment(markInTime, 'HH:mm:ss')}
          onOpenChange={() => this.changedDateOpenStatus('markIn')}
          onChange={this.onChangeMarkIn}
          defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
          disabledHours={() => this.disabledHours('start')}
          hideDisabledOptions
          disabledMinutes={selectedHour => this.disabledMinutes('start', selectedHour)}
        />
      )
    }
    if (isEditable && allowTrim) {
      this.markOutDatePicker = (
        <TimePicker
          value={moment(markOut, 'HH:mm:ss')}
          onOpenChange={() => this.changedDateOpenStatus('markOut')}
          onChange={this.onChangeMarkOut}
          defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
          disabledHours={() => this.disabledHours('end')}
          hideDisabledOptions
          disabledMinutes={selectedHour => this.disabledMinutes('end', selectedHour)}
        />
      )
    }
    // const isSaveEnabled = markIn && markOut ? this.checkSaveEnable(markIn, markOut) : undefined
    return (
      <div className='stop-encoding-body'>
        <div className='encoder'>
          <DropDown
            selectedValue={selectedEncoder}
            options={encodingOptions}
            valueParam='id'
            displayParam='name'
            onOptionSelect={this.onChangeEncoder}
          />
        </div>
        <div className='mark-in-container'>
          <span>Mark In</span>
          {this.markInDatePicker}
        </div>
        <div className='mark-out-container'>
          <span>Mark Out</span>
          {this.markOutDatePicker}
        </div>
        <span className='duration'> Duration: {duration} </span>
        <div className='drm-protected'>
          <span>DRM</span>
          <Checkbox checked={drm} onChange={this.onChangeDrm} />
        </div>
        <SaveButton
          isLoading={isSavingTrim}
          isDisabled={!isEdited}
          onClick={this.updateSavedTrim}
        />
        <CreateVodButton
          isLoading={isLoading}
          isDisabled={!isEdited && !savedTrim}
          onClick={() => this.toggleWarningMessage(true)}
        />
        <ConfirmModal
          isVisible={showTriggerWarning && !isRunningVod}
          title={title}
          message={messageText}
          rightButtonText={'Trigger'}
          handleCancel={e => this.toggleWarningMessage(false)}
          handleSubmit={this.updateVideoEncoding}
          isSubmitButtonDisabled={false}
          isDrmCheckEnabled
          drmEnableChange={this.onChangeDrm}
          drmValue={drm}
          isCancelButtonDisabled={false}
        />
        <ConfirmModal
          isVisible={showTriggerWarning && isRunningVod}
          isLeftPrior
          title={title}
          message={duplicateRunningMessage.message}
          leftButtonText={duplicateRunningMessage.cancelButtonText}
          rightButtonText={duplicateRunningMessage.okButtonText}
          handleCancel={e => this.toggleWarningMessage(false)}
          handleSubmit={this.updateVideoEncoding}
          isSubmitButtonDisabled={false}
          isDrmCheckEnabled
          drmEnableChange={this.onChangeDrm}
          drmValue={drm}
          isCancelButtonDisabled={false}
        />
      </div>
    )
  }

  render () {
    const {
      onPlayerClose,
      isEditable,
      allowTrim,
      showPlayer,
      playerRange,
      onChangeRange,
      startTime,
      endTime,
      selectedVideoDetails
    } = this.props
    const { markOut, markIn, type, isDragging } = this.state
    // const isLive = this.getLiveStatus()
    // if (isLive) {
    //   if (!this.setLiveStatusInterval) {
    //     this.setLiveStatusInterval = setInterval(this.changeTimer, 1000)
    //   }
    // } else if (this.setLiveStatusInterval) {
    //   clearInterval(this.setLiveStatusInterval)
    // }
    // const markInPosition = this.originalDuration && isEditable ? (isLive ? this.getLiveMarkPosition(true) : this.getMarkPosition(markIn)) : null
    // const markOutPosition = this.originalDuration && isEditable ? (isLive ? this.getLiveMarkPosition(false) : this.getMarkPosition(markOut)) : null
    // const startPosition = this.getPlayTimePosition(startTime, isLive)
    // const endPosition = endTime ? this.getPlayTimePosition(endTime, isLive) : null
    const markInPosition = this.originalDuration && isEditable ? this.getMarkPosition(markIn) : null
    const markOutPosition =
      this.originalDuration && isEditable ? this.getMarkPosition(markOut) : null
    const startPosition = this.getPlayTimePosition(startTime)
    const endPosition = endTime ? this.getPlayTimePosition(endTime) : null
    const trimDetails = this.getRangeDetails()
    const adMarkers = selectedVideoDetails ? this.getAdMarkers(selectedVideoDetails) : []

    const markInTrimmer =
      isEditable && markInPosition <= 100 && markInPosition >= 0 && allowTrim ? (
        <div
          className='mark-seek'
          style={{
            left: `calc( ${markInPosition}% - 4px )`,
            cursor: type === 'markIn' && isDragging ? 'grabbing' : 'grab'
          }}>
          <ButtonContainer
            displayTitle={this.currentMarker['markIn'].format('hh:mm:ss')}
            childComponent={
              <TrimInIcon className='trimmer' onMouseDown={e => this.onMouseDown('markIn', e)} />
            }
          />
        </div>
      ) : null

    const markOutTrimmer =
      isEditable && markOutPosition <= 100 && markOutPosition >= 0 && allowTrim ? (
        <div
          className='mark-seek'
          style={{
            right: `calc( ${100 - markOutPosition}% - 4px )`,
            cursor: type === 'markOut' && isDragging ? 'grabbing' : 'grab'
          }}>
          <ButtonContainer
            displayTitle={this.currentMarker['markOut'].format('hh:mm:ss')}
            childComponent={
              <TrimOutIcon className='trimmer' onMouseDown={e => this.onMouseDown('markOut', e)} />
            }
          />
        </div>
      ) : null
    return (
      <React.Fragment>
        {isEditable ? <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} /> : null}
        {isEditable && allowTrim ? trimDetails : null}
        {isEditable ? <SeekMarkInIcon onClick={() => this.seekToMarkPosition(true)} /> : null}
        {isEditable ? <SeekMarkOutIcon onClick={() => this.seekToMarkPosition(false)} /> : null}
        {isEditable ? (
          <TrimmerIcon
            style={{ cursor: 'pointer' }}
            color={allowTrim ? '#FF015A' : '#FFF'}
            onClick={this.toggleTrimOption}
          />
        ) : null}
        {isEditable ? (
          <PlayerRangeDropDown selectedRange={playerRange} onChangeRange={onChangeRange} />
        ) : null}
        {isEditable && startPosition && startPosition < 100 ? (
          <div className='triangle-up start' style={{ left: `calc( ${startPosition}% - 5px)` }} />
        ) : null}
        {isEditable && endPosition && endPosition < 100 ? (
          <div className='triangle-up end' style={{ left: `calc( ${endPosition}% - 5px)` }} />
        ) : null}
        {showPlayer ? (
          <CollapseIcon onClick={onPlayerClose} />
        ) : (
          <ExpandIcon onClick={onPlayerClose} />
        )}
        {isEditable && markInPosition <= 100 && markInPosition >= 0 ? (
          !allowTrim ? (
            <div className='marker' style={{ left: `${markInPosition}%` }} />
          ) : (
            markInTrimmer
          )
        ) : null}
        {isEditable && markOutPosition <= 100 && markOutPosition >= 0 ? (
          !allowTrim ? (
            <div className='marker' style={{ right: `${100 - markOutPosition}%` }} />
          ) : (
            markOutTrimmer
          )
        ) : null}
        {adMarkers && adMarkers.length ? adMarkers : null}
      </React.Fragment>
    )
  }
}

PlayerMenu.propTypes = {}
export default PlayerMenu
