import gql from 'graphql-tag'

export default gql(`
    mutation exportAssetsCsv($assets: [ID!], $project: String) {
        exportAssetsCsv(
            assets: $assets
            project: $project
        ) {
            status
            assets
        }
    }`
)
