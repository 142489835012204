import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import { Popover } from 'antd'

const PopoverButton = ({ button, displayParam, isVisible, contents, parentCompoent, onContentClick, placement, ...props }) => {
  const content = (
    <div>
      { contents.map((item, index) => <p key={index} className={item[ displayParam ].toLowerCase().replace(' ', '_')} onClick={() => onContentClick(item)}>{ item[ displayParam ] }</p>) }
    </div>
  )

  return (
    <Popover className='popover-btn' placement={placement} content={content} trigger='click' visible={isVisible} getPopupContainer={() => parentCompoent ? document.getElementById(parentCompoent) : ''} >
      { button }
    </Popover>
  )
}

PopoverButton.propTypes = {
  /** parent container id of PopoverButton. */
  parentCompoent: PropTypes.string,
  /** display parameter of items of PopoverButton. */
  displayParam: PropTypes.string,
  /** visibility status of PopoverButton. */
  isVisible: PropTypes.bool,
  /** Button component of PopoverButton. */
  button: PropTypes.element.isRequired,
  /** contents data of PopoverButton. */
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** content click action of PopoverButton. */
  onContentClick: PropTypes.func,
  /** placement of popover */
  placement: PropTypes.string
}

PopoverButton.defaultProps = {
  isVisible: true,
  parentCompoent: 'root',
  placement: 'rightBottom'
}

export default PopoverButton
