import React from 'react'
import PropTypes from 'prop-types'

import CrossIcon from './../general/icons/CrossIcon'
import StreamDropDown from '../dataEntry/inputs/StreamDropDown'

import './../ui.style.scss'

class GeneralStreamCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { streamLevels, onSelect, onCloseStreamLevels, channelStreamList } = this.props
    return (
      <div className='general-stream-cell'>
        <StreamDropDown options={channelStreamList} valueParam='id' displayParam='name' selectedOption={streamLevels.levels || []} onSelect={(selectedOption) => onSelect(selectedOption, streamLevels.id)} />
        <CrossIcon onClick={() => onCloseStreamLevels(streamLevels.id)} />
      </div>
    )
  }
}
GeneralStreamCell.propTypes = {
  /** file name of GeneralStreamCell. */
  name: PropTypes.string,
  /** close action of GeneralStreamCell. */
  onClose: PropTypes.func,
  /** File index of GeneralStreamCell. */
  index: PropTypes.number,
  /** List of options */
  optionList: PropTypes.array,
  /** Placeholder name of column 1 */
  placeHolder1: PropTypes.string,
  /** Placeholder name of column 2 */
  placeHolder2: PropTypes.string
}

GeneralStreamCell.defaultProps = {
}

export default GeneralStreamCell
