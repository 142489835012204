import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Switch } from 'antd'
import CrossIcon from './../general/icons/CrossIcon'

import './../ui.style.scss'

const ConfirmModal = ({ isVisible, isLoading, title, message, isLeftPrior, rightButtonText, leftButtonText, handleCancel, handleSubmit, isSubmitButtonDisabled, handleClose, isCancelButtonDisabled, isCancelButtonInvisible, isDrmCheckEnabled, drmEnableChange, drmValue, zIndex, ...props }) => (
  <Modal
    className={`confirm-modal ${isLeftPrior ? 'left-prior' : ''}${isCancelButtonInvisible ? ' cancel-not-visible' : ''}`}
    title={title}
    visible={isVisible}
    cancelText={leftButtonText}
    okText={rightButtonText}
    onOk={handleSubmit}
    onCancel={handleClose || handleCancel}
    okButtonProps={{ disabled: isSubmitButtonDisabled }}
    cancelButtonProps={{ disabled: isCancelButtonDisabled, onClick: handleCancel }}
    closable={false}
    centered
    confirmLoading={isLoading}
    zIndex={zIndex || 1000}
  >
    {isCancelButtonInvisible ? '' : <div className='cross-icon'>
      <CrossIcon onClick={handleClose || handleCancel} />
    </div>}
    <p>{message}</p>
    {isDrmCheckEnabled && <div className='drm-warning-container'>
      <span>DRM Enabled</span>
      <Switch onChange={(value) => drmEnableChange({ target: { checked: value } })} checked={drmValue} />
    </div>}
  </Modal>
)

ConfirmModal.propTypes = {
  /** loading status of Modal. */
  isLoading: PropTypes.bool,
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Title of Modal. */
  title: PropTypes.string.isRequired,
  /** Description message of Modal. */
  message: PropTypes.string.isRequired,
  /** Button priority status of Modal. if 'isleftPrior' is 'true', 'Cancel' button is of higher priority */
  isLeftPrior: PropTypes.bool,
  /** Left button display text of Modal. */
  leftButtonText: PropTypes.string,
  /** Right button display text of Modal. */
  rightButtonText: PropTypes.string,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Submit button disable status of Modal. */
  isSubmitButtonDisabled: PropTypes.bool,
  /**  Submit button disable status of Modal. */
  isCancelButtonDisabled: PropTypes.bool,
  /** Boolean for making cancel button invisible */
  isCancelButtonInvisible: PropTypes.bool,
  /** Flag to indicate DRM confirmation enabled or not */
  isDrmCheckEnabled: PropTypes.bool,
  /** Value of drm protected */
  drmValue: PropTypes.bool,
  /** Function to be called on drm value change */
  drmEnableChange: PropTypes.func
}

ConfirmModal.defaultProps = {
  selectedValue: 'Select'
}

export default ConfirmModal
