import React from 'react'
import PropTypes from 'prop-types'

const AssetIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0-2h15v15H0z' />
      <path fill={color} d='M2 11h11V7h2v6H0V7h2v4zM4 0h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm5 0h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zM4 5h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zm5 0h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1z' />
    </g>
  </svg>
)

AssetIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

AssetIcon.defaultProps = {
  width: 15,
  height: 13,
  color: '#343A40'
}

export default AssetIcon
