import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import Input from '../inputs/Input'
import AppsList from '../../dataDisplay/AppsList'
import MutationCreateAPIKey from '../../../../graphQL/appManager/createAPIKey'
import MutationUpdateAPIKey from '../../../../graphQL/appManager/editApiKey'
import QueryListAPIKeys from './../../../../graphQL/appManager/listAPIKeys'
// import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'

import { utilityService } from '../../../../services/UtilityService'
// const { TabPane } = Tabs

let optionalInitialData = []

// const parentCompoent = 'create-input'

class CreateAPIKeyModal extends Component {
    state = {
      isLoading: false,
      enable: false,
      activeKey: '1',
      optionalData: _.cloneDeep(optionalInitialData),
      isNoDuplicate: false,
      details: {
        name: '',
        enabledApps: []
      },
      appsList: []
    }

    componentDidMount = () => {
    //   if (this.props.selectedType) {
    //     this.setState({
    //       details: _.cloneDeep(this.props.selectedType)
    //     })
    //   }
    }

    UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
      // if (nextProps.appList && _.isEqual(nextProps.appList, this.props.appList)) {
      //  // let details = _.cloneDeep(this.state.details)
      //   let appsList = _.cloneDeep(nextProps.appList)
      //   this.setState({ appsList })
      //   // this.setState({ listAssetTypes: _.cloneDeep(nextProps.listAssetTypes) })
      // }
      if (nextProps.details && nextProps.isEdit) {
        let details1 = _.cloneDeep(this.state.details)
        details1.id = nextProps.details.id
        details1.enabledApps = (nextProps.details.app || []).map(app => app && app.id)
        details1.name = nextProps.details.label
        details1.apiKey = nextProps.details.apiKey
        details1.isDisabled = nextProps.details.isDisabled
        details1.createdAt = nextProps.details.createdAt
        details1.updatedAt = nextProps.details.updatedAt
        this.setState({ details: details1 })
      }
    }

    // getFormattedAssetTypeList=(listAssetTypes) => {
    //   this.optionalInitialData = (listAssetTypes || []).map(item => {
    //     const obj = {
    //       id: item.id,
    //       title: item.title,
    //       isEnabled: false,
    //       icon: item.icon,
    //       draftIcon: item.draftIcon
    //     }
    //     return obj
    //   })
    //   let details = _.cloneDeep(this.state.details)
    //   // details.enabledAssetTypes = this.optionalInitialData
    //   details.assetTypeList = this.optionalInitialData
    //   this.setState({ details })
    // }

    handleChange =(e) => {
      const { details } = this.state
      const { value, name } = e.target
      if (name === 'name') {
        details.name = value
        this.setState({ details, isBoolEnabled: true })
      }
    }

    handleAddApiKey = () => {
      const { isEdit, onHistoryUpdate } = this.props
      const { details } = this.state
      if (details && details.enabledApps && _.isEmpty(details.enabledApps)) {
        message.warn('Please enable atleast one App before saving')
        return
      }
      this.setState({ isLoading: true })

      if (isEdit) {
        // edit
        const updatedEnabledApps = (details.enabledApps || []).map(item => {
          return item
        })
        const newApiKey = {
          //  position: details.position,
          id: details.id,
          label: details.name,
          app: updatedEnabledApps,
          apiKey: details.apiKey
        }
        this.props.updateApiKey(newApiKey).then(response => {
          // this.props.handleSubmit(response.data.updateTagType)
          message.success('API Key updated successfully')
          this.props.hideApiKeyModal()
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        // create
        const newApiKey = {
          label: details.name,
          app: details.enabledApps
        }
        this.props.createApiKey(newApiKey).then(({ data }) => {
          message.success('ApiKey created successfully')
          // this.props.handleSubmit(data.createApiKey)
          this.props.hideApiKeyModal()
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    onCloseField = (index) => {
      const { streamField } = this.state
      // const index = streamField.findIndex(item => item.id === id)
      streamField.splice(index, 1)
      const duplicateItem = streamField.filter(item => {
        const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
        return tempIndex !== -1
      })
      const isNoDuplicate = !!duplicateItem.length
      this.setState({ streamField, isBoolEnabled: true, isNoDuplicate })
    }

    isEnableSave = () => {
      this.setState({ enable: true })
    }

      onChangeData = (appId, status) => {
        const { details } = this.state
        let enabledApps = _.cloneDeep(details.enabledApps)
        const index = (enabledApps || []).findIndex(appItem => appItem === appId)
        if (index > -1) {
          enabledApps.splice(index, 1)
        } else {
          if (!enabledApps) {
            enabledApps = []
            enabledApps.push(appId)
          } else {
            enabledApps.push(appId)
          }
        }
        details.enabledApps = enabledApps
        this.setState({ details, isBoolEnabled: true })
      }

      onOptionSelect = (value, index) => {
        const { streamField } = this.state
        streamField[index].type = value
        const duplicateItem = streamField.find(item => item.displayName === streamField[index].displayName && item.id !== streamField[index].id)
        if (!_.isEmpty(duplicateItem) && duplicateItem.type === value) {
          message.error('Duplicate Meta Data')
          this.setState({ streamField, isBoolEnabled: false, isNoDuplicate: true })
        } else {
          this.setState({ streamField, isBoolEnabled: true, isNoDuplicate: false })
        }
      }

      onChangeEnableMedia=(status) => {
        const { details } = this.state
        details.isMediaEnabled = status
        this.setState({ details, isBoolEnabled: true })
      }

    resetState = () => {
      const state = {
        isLoading: false,
        enable: false,
        activeKey: '1',
        isBoolEnabled: false,
        isNoDuplicate: false,
        details: {
          name: '',
          enabledApps: []

        },
        appList: []
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, hideApiKeyModal, isEdit, isSubmitDisabled, appList } = this.props
      const { isLoading, optionalData, details, isBoolEnabled } = this.state
      const disabled = !details.name || (!isBoolEnabled)
      const title = <div className='title'>
        <span>{isEdit ? 'EDIT API KEY' : 'CREATE API KEY'}</span>
      </div>
      // const uniqDataArr = _.uniqBy((streamField || []), asset => [asset.type, asset.displayName].join())
      // const isDuplicate = uniqDataArr.length !== (streamField || []).length
      return (
        <Modal
          className='general-modal create-api-key-container'
          title={title}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddApiKey}
          onCancel={hideApiKeyModal}
          okButtonProps={{ disabled: (disabled) || isSubmitDisabled, loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-api-key'>
            <div className='create-input' id='create-input'>

              <div className='api-key-config-container'>
                <Input title='API Key Label' inputName='name' isRequired handleChange={this.handleChange} value={details.name} placeholder='Enter API Key Label' limit={50} />
              </div>
              {isEdit ? <div className='api-key-config-container'>
                <Input title='API Key' inputName='key' isRequired handleChange={this.handleChange} value={details.apiKey} placeholder='Enter API Key' limit={50} readOnly />
              </div> : null}
            </div>
            <AppsList optionList={optionalData} onChange={this.onChangeData} details={details} appsList={appList || []} />
          </div>
        </Modal>
      )
    }
}

CreateAPIKeyModal.propTypes = {
  /** visible file status of CreateAssetTypeModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateAssetTypeModal. */
  handleCancel: PropTypes.func
}

CreateAPIKeyModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateAPIKey, {
      options: ({ project, appClient }) => {
        return {
          update: (cache, { data: { createApiKey } }) => {
            const variables = { limit: 499, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAPIKeys, variables }))
            if (cacheData && cacheData.listApiKeys && cacheData.listApiKeys.items) {
              cacheData.listApiKeys.items.push(createApiKey)
            }
            cache.writeQuery({
              query: QueryListAPIKeys,
              data: cacheData,
              variables
            })
          },
          client: appClient,
          refetchQueries: () => [{ query: QueryListAPIKeys,
            variables: { limit: 499, project } }]
        }
      },
      props: (props) => ({
        createApiKey: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAPIKey, {
      options: ({ project, appClient }) => {
        return {
          update: (cache, { data: { editApiKey } }) => {
            const variables = { limit: 499, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAPIKeys, variables }))
            if (cacheData && cacheData.listApiKeys && cacheData.listApiKeys.items) {
              const index = (cacheData.listApiKeys.items || []).findIndex(item => item.id === editApiKey.id)
              if (index > -1) {
                cacheData.listApiKeys.items[index] = editApiKey
              }
            }
            cache.writeQuery({
              query: QueryListAPIKeys,
              data: cacheData,
              variables
            })
          },
          client: appClient
        //   refetchQueries: () => [{ query: QueryListAPIKeys,
        //     variables: { project } }]
        }
      },
      props: (props) => ({
        updateApiKey: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateAPIKeyModal))
