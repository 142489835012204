import * as React from 'react'
import TagsInput from './TagsInput'
import PropTypes from 'prop-types'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryAllTags from './../../../../graphQL/asset/getTags'

class TagsInputContainer extends React.Component {
  render () {
    const { selectedItems, tagList, parentCompoent, displayParameter, placeholder, type, onSearch, isCustom, disabled, keyParameter, selectedDisplayItems, isDropdownInvisible } = this.props
    return <TagsInput
      containerClass='tags'
      selectedItems={selectedItems}
      tagList={tagList || []}
      onChange={this.onChange}
      placeholder={placeholder}
      parentCompoent={parentCompoent}
      displayParameter={displayParameter}
      mode={isCustom ? 'tags' : 'multiple'}
      onSearch={onSearch}
      type={type}
      disabled={disabled}
      keyParameter={keyParameter}
      selectedDisplayItems={selectedDisplayItems}
      isDropdownInvisible={isDropdownInvisible}
    />
  }

    onChange = (values, isDelete) => {
      const { tagList, type, isCustom } = this.props
      if (this.props.onChange) { this.props.onChange(values, tagList, type, isDelete, isCustom) }
    }
}

TagsInputContainer.propTypes = {
  /** Placeholder text of TagsInputContainer. */
  placeholder: PropTypes.string,
  /** Placeholder text of TagsInputContainer. */
  displayParameter: PropTypes.string,
  /** parent container id name of TagsInputContainer. */
  parentCompoent: PropTypes.string,
  /** Selected Items of TagsInputContainer. */
  selectedItems: PropTypes.array,
  /** Option data of TagsInputContainer. */
  tagList: PropTypes.array,
  /** Change action of TagsInputContainer. */
  onChange: PropTypes.func,
  /** Mode of input */
  mode: PropTypes.string,
  /** Tag search string */
  searchTag: PropTypes.string,
  /** Function invokes when user wants to search tag */
  onSearch: PropTypes.func,
  /** Filter module */
  module: PropTypes.string
}

export default withApollo(compose(
  graphql(
    QueryAllTags,
    {
      skip: (props) => {
        return !(props.type)
      },
      options: ({ searchTag, type, module, project }) => {
        let filter = { type: { match: type === 'all' ? null : type } }
        if (searchTag) {
          filter.name = { match: searchTag }
        }
        if (module) {
          filter.module = { exact: module }
        }
        return {
          variables: { filter, limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listTags = { items: [] } } }) => ({
        tagList: [ ...listTags.items ]
      })
    }
  )
)(TagsInputContainer))
