import { Auth } from 'aws-amplify'
let userDetails

const clearUserDetails = async () => {
  userDetails = undefined
}

const setUserDetails = async () => {
  const user = await Auth.currentUserInfo()
  if (user && user.attributes) {
    const { name, email } = user.attributes
    userDetails = { name, email }
  }
}

const getUserDetails = async () => {
  if (userDetails) {
    return userDetails
  } else {
    const user = await Auth.currentUserInfo()
    if (user && user.attributes) {
      const { name, email } = user.attributes
      userDetails = { name, email }
    }
    return userDetails
  }
}

const AuthService = {
  getUserDetails,
  setUserDetails,
  clearUserDetails
}

export default AuthService
