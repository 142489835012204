import React, { Component } from 'react'
import { Tabs, message } from 'antd'

import PropTypes from 'prop-types'

import SidebarOpenButton from '../../components/ui/general/buttons/SidebarOpenButton'
import SidebarCloseButton from '../../components/ui/general/buttons/SidebarCloseButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import Button from '../../components/ui/general/buttons/Button'
import IngestJobList from './IngestJobList'
import AddJobAssetCsvModal from '../../components/ui/dataEntry/other/AddJobAssetCsvModal'
import IngestHistoryList from '../auditManager/IngestHistoryList'
import PopoverButton from '../../components/ui/general/buttons/PopoverButton'
import { utilityService } from '../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryExportCsv from '../../graphQL/IngestManager/exportCsv'
import MuatationOnDemandSync from '../../graphQL/IngestManager/gracenoteOnDemandSync'
import MuatationIngestUpdate from '../../graphQL/IngestManager/gracenoteIngestUpdate'

const { TabPane } = Tabs

const options = [
  {
    type: 'program',
    name: 'Program Updates'
  },
  {
    type: 'partner',
    name: 'Partner Updates'
  }
]

class IngestList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      importAssetVisible: false,
      csvFile: [],
      fileList: [],
      isUploadBtnLoading: false,
      isCancelButtonDisabled: false,
      isUploadButtonDisable: true,
      shouldShowSync: false,
      onDemandSyncLoading: false,
      lastUploadedCsvUrl: null,
      graceNoteSync: false,
      lastUploadedCsvKey: null
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.shouldShowSync) {
      setTimeout(() => this.setState({ shouldShowSync: false }), 200)
    }
  }

  showImportAssetModal = () => {
    this.setState({
      importAssetVisible: true
    })
  }

  getUploadCsvUrl = (files) => {
    const newFiles = files.map(file => ({ fileName: file.name.replace(/\s/g, ''), contentType: 'CSV' }))
    this.setState({ csvFile: newFiles })
    this.props.refetchHistory()
  }

  beforeUpload = (data, dataList) => {
    let { fileList } = this.state
    if (!data.size) {
      message.error(`${data.name} is an empty file`)
    }
    dataList = dataList.filter(item => item.size)
    fileList = [ ...fileList, ...dataList ]
    this.setState({ fileList, isUploadButtonDisable: false })
  }

  handleDelete = (index, e) => {
    const { fileList, isUploadBtnLoading } = this.state
    if (isUploadBtnLoading) {
      message.error('Can not remove while uploading the csv')
    } else {
      fileList.splice(index, 1)
      this.setState({ fileList })
    }
  }

  handleCsvUploadSubmit = () => {
    const { fileList } = this.state
    this.setState({
      isUploadBtnLoading: true,
      isCancelButtonDisabled: true
    })
    this.getUploadCsvUrl(fileList)
  }

  handleCancel = () => {
    this.setState({ fileList: [], importAssetVisible: false, isUploadButtonDisable: true, csvFile: [], graceNoteSync: false })
  }

  handleCsvUpload = () => {
    const { lastUploadedCsvKey, graceNoteSync } = this.state
    if (graceNoteSync) {
      this.props.gracenoteIngestUpdate(lastUploadedCsvKey).then(({ data }) => {
        let gracenoteIngestUpdateData = data && data.gracenoteIngestUpdate && data.gracenoteIngestUpdate.data
        const { programIngestResult, programScheduleIngestResult } = gracenoteIngestUpdateData
        message.success(`Programs Found: ${[_.size(programIngestResult)]}, Program Availability Found : ${[_.size(programScheduleIngestResult)]}`)
      }, errorMsg => {
        utilityService.handleError(errorMsg)
      })
    }
    this.setState({ fileList: [], isUploadBtnLoading: false, importAssetVisible: false, isCancelButtonDisabled: false, csvFile: [], isUploadButtonDisable: true, graceNoteSync: false })
  }

  toggleShowDemandSync = () => {
    this.setState({ shouldShowSync: !this.state.shouldShowSync })
  }

  onDemandSync = (selectedType) => {
    if (selectedType.type === 'partner') {
      this.setState({
        shouldShowSync: false,
        onDemandSyncLoading: true
      })
      this.props.gracenoteOnDemandSync(true, false).then(({ data }) => {
        this.props.refetchHistory()
        const { data: onDemandData } = data && data.gracenoteOnDemandSync
        const { programScheduleIngestResult } = onDemandData
        if (!_.isEmpty(programScheduleIngestResult)) {
          message.success('Fetching Partner Availability updates initiated')
        } else {
          message.warning('No Partner Update Available')
        }
        this.setState({
          onDemandSyncLoading: false
        })
      }, errorMsg => {
        this.setState({
          onDemandSyncLoading: false
        })
        utilityService.handleError(errorMsg)
      })
    } else {
      this.setState({
        shouldShowSync: false,
        onDemandSyncLoading: true
      })
      this.props.gracenoteOnDemandSync(false, true).then(({ data }) => {
        this.props.refetchHistory()
        const { data: onDemandData } = data && data.gracenoteOnDemandSync
        const { programIngestResult } = onDemandData
        if (!_.isEmpty(programIngestResult)) {
          message.success('Fetching Program updates initiated')
        } else {
          message.warning('No Program Update Available')
        }
        this.setState({
          onDemandSyncLoading: false
        })
      }, errorMsg => {
        this.setState({
          onDemandSyncLoading: false
        })
        utilityService.handleError(errorMsg)
      })
    }
  }

  updateLastUploadedUrl=(url) => {
    this.setState({ lastUploadedCsvUrl: url })
  }

  updateLastUploadedKey=(key) => {
    this.setState({ lastUploadedCsvKey: key })
  }

  onChangeGraceNoteSync = (status) => {
    this.setState({ graceNoteSync: status })
  }

  render () {
    const { isSidebarVisible, listIngestJobs, totalCount, getMoreIngestJob, isLoading, changeSelectedJob, project, exportCsv, IngestClient, selectedJobId, onIngestJobTypeSelect,
      selectedIngestJobType, ingestTableActiveKey, onIngestTableTabChange, handleCustomJobTypeName, shouldRefreshJobHistory
      , searchBatchId, isCreateDisabled, onChangeBatchIdSearchInput, exportGraceNoteCsv } = this.props
    const { importAssetVisible, csvFile, fileList, isUploadBtnLoading, isCancelButtonDisabled, isUploadButtonDisable, shouldShowSync,
      onDemandSyncLoading, lastUploadedCsvUrl, graceNoteSync } = this.state
    const operations =
      <div className='ingeston-tab-right'>
        <div className='option-list' id='option-list'>
          <PopoverButton
            button={<Button buttonText='On-Demand Sync' isDisabled={isCreateDisabled} isLoading={onDemandSyncLoading} key='sync' onClick={this.toggleShowDemandSync} />}
            displayParam='name'
            contents={options}
            onContentClick={this.onDemandSync}
            parentCompoent={'option-list'}
            isVisible={shouldShowSync}
            placement={'leftBottom'}
          />
        </div>
        <Button buttonText='Import Assets' loading key='import' onClick={this.showImportAssetModal} />
        {ingestTableActiveKey === '1' ? <ButtonContainer displayTitle='Toggle Sidebar' childComponent={isSidebarVisible ? <SidebarCloseButton onClick={this.props.toggleSidebar} /> : <SidebarOpenButton onClick={this.props.toggleSidebar} />} /> : null}

      </div>

    return (
      <React.Fragment>
        <div className='ingest-tabs'>
          <div className='ingest-tab-details'>
            <Tabs destroyInactiveTabPane tabBarExtraContent={operations} onChange={onIngestTableTabChange}>
              <TabPane tab='Ingest Jobs' key='1' >
                <IngestJobList
                  listIngestJobs={listIngestJobs}
                  totalCount={totalCount}
                  getMoreIngestJob={getMoreIngestJob}
                  isLoading={isLoading}
                  changeSelectedJob={changeSelectedJob}
                  project={project}
                  IngestClient={IngestClient}
                  selectedJobId={selectedJobId}
                  onIngestJobTypeSelect={onIngestJobTypeSelect}
                  selectedIngestJobType={selectedIngestJobType}
                  handleCustomJobTypeName={handleCustomJobTypeName}
                  searchBatchId={searchBatchId}
                  onChangeBatchIdSearchInput={onChangeBatchIdSearchInput}
                />
              </TabPane>
              <TabPane tab='History' key='2' >
                <IngestHistoryList
                  project={project}
                  IngestClient={IngestClient}
                  content='INGEST_MANAGER'
                  isChanging={shouldRefreshJobHistory}
                />

              </TabPane>
            </Tabs>
          </div>
        </div>
        <AddJobAssetCsvModal
          isVisible={importAssetVisible}
          handleCancel={this.handleCancel}
          exportCsv={exportCsv}
          IngestClient={IngestClient}
          files={csvFile}
          project={project}
          beforeUpload={this.beforeUpload}
          handleSubmit={this.handleCsvUploadSubmit}
          handleDelete={this.handleDelete}
          fileList={fileList}
          handleCsvUpload={this.handleCsvUpload}
          isUploadBtnLoading={isUploadBtnLoading}
          isCancelButtonDisabled={isCancelButtonDisabled}
          isUploadButtonDisable={isUploadButtonDisable}
          updateLastUploadedUrl={this.updateLastUploadedUrl}
          lastUploadedCsvUrl={lastUploadedCsvUrl}
          isCreateDisabled={isCreateDisabled}
          graceNoteSync={graceNoteSync}
          onChangeGraceNoteSync={this.onChangeGraceNoteSync}
          updateLastUploadedKey={this.updateLastUploadedKey}
          exportGraceNoteCsv={exportGraceNoteCsv}
        />
      </React.Fragment>

    )
  }
}

IngestList.propTypes = {
  /** current project */
  project: PropTypes.string,
  /** sidebar visibility flag */
  isSidebarVisible: PropTypes.bool,
  /** function to handle sidebar toggle */
  toggleSidebar: PropTypes.func,
  /** function to handle jobchange */
  changeSelectedJob: PropTypes.func,
  /** ingest Client configuration */
  IngestClient: PropTypes.object,
  /** selected JobID */
  selectedJobId: PropTypes.string,
  /** function to refetch history */
  refetchHistory: PropTypes.func,
  /** boolean to check the history refetch status */
  shouldRefreshJobHistory: PropTypes.bool,
  /** boolean to check the update access */
  isUpdateDisabled: PropTypes.bool,
  /** boolean to check the create access */
  isCreateDisable: PropTypes.bool,
  /** function to handle batchId search change */
  onChangeBatchIdSearchInput: PropTypes.func

}

export default withApollo(compose(
  graphql(
    QueryExportCsv,
    {
      options: (props) => {
        const { project, IngestClient } = props
        return {
          fetchPolicy: 'network-only',
          client: IngestClient,
          variables: { key: 'template101.csv', project: project }
        }
      },
      props: (props) => {
        return {
          exportCsv: props.data && props.data.exportCsv && props.data.exportCsv[0] ? props.data && props.data.exportCsv && props.data.exportCsv[0] && props.data.exportCsv[0].url : ''
        }
      }
    }
  ),
  graphql(
    QueryExportCsv,
    {
      options: (props) => {
        const { project, IngestClient } = props
        return {
          fetchPolicy: 'network-only',
          client: IngestClient,
          variables: { key: 'template101-gracenote.csv', project: project }
        }
      },
      props: (props) => {
        return {
          exportGraceNoteCsv: props.data && props.data.exportCsv && props.data.exportCsv[0] ? props.data && props.data.exportCsv && props.data.exportCsv[0] && props.data.exportCsv[0].url : ''
        }
      }
    }
  ),
  graphql(
    MuatationOnDemandSync,
    {
      options: ({ IngestClient }) => {
        return {
          client: IngestClient
        }
      },
      props: (props) => ({
        gracenoteOnDemandSync: (runProgramAvailIngest, runProgramIngest) => {
          return props.mutate({
            variables: { runProgramAvailIngest, runProgramIngest }
          })
        }
      })
    }
  ),
  graphql(
    MuatationIngestUpdate,
    {
      options: ({ IngestClient }) => {
        return {
          client: IngestClient
        }
      },
      props: (props) => ({
        gracenoteIngestUpdate: (csvS3Key) => {
          return props.mutate({
            variables: { csvS3Key }
          })
        }
      })
    }
  )
)(IngestList))
