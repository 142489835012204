import gql from 'graphql-tag'

export default gql(`
query{
    listModuleSettings {
        items {
          id
          channelManagerSetting {
            masterConfigEnabled
            masterConfigSchemaId
          }
        }
      }
}`)
