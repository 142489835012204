import React from 'react'
import PropTypes from 'prop-types'

const ExpandMenuIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <circle cx='10' cy='3' r='2' fill='#343A40' />
      <circle cx='10' cy='17' r='2' fill='#343A40' />
      <circle cx='10' cy='10' r='2' fill='#343A40' />
      <path d='M0 0h20v20H0z' />
    </g>
  </svg>
)

ExpandMenuIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

ExpandMenuIcon.defaultProps = {
  width: 20,
  height: 20
}

export default ExpandMenuIcon
