import React, { Component } from 'react'
import moment from 'moment'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import InfiniteScroll from 'react-infinite-scroller'
import PropTypes from 'prop-types'

import { Empty, Skeleton } from 'antd'
import AssetListCell from './../../components/ui/dataDisplay/AssetListCell'
import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryFilterAssets from './../../graphQL/asset/searchAssets'
import SubscriptionAssetList from './../../graphQL/asset/createAssetSubscription'
import { generateCroppedThumbnail } from './../../util/util'

const width = ['calc(100% - 100px)', '50%', '100%']

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class BucketAssetList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      assetList: props.assetList,
      isLoading: false,
      isSearching: false,
      isPaginating: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && this.lastSearched !== { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
      this.setState({ assetList: newProps.assetList, isSearching: false, isPaginating: false, isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.assetList, this.props.assetList)) {
      this.setState({ assetList: newProps.assetList, isSearching: false, isPaginating: false, isLoading: false })
    }
  }

  componentDidMount () {
    this.props.subscribeToNewAsset()
  }

  loadMoreAsset = () => {
    if (this.props.totalCount === this.props.assetList.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getAssetList(this.state.assetList.length, this.props.project))
  }

  render () {
    const { isPaginating } = this.state
    const { isLoading, selectedAssets, isDragDisabled, project, disableAssetDrag } = this.props
    let assetList = selectedAssets ? (this.state.assetList || []).filter((item) => (selectedAssets).indexOf(item.id) === -1) : this.state.assetList
    assetList = _.uniqBy(assetList, 'id')
    return (
      <React.Fragment>
        <div className='bucket-asset-list'>
          <Droppable droppableId='asset-list'>
            {(droppableProvided, droppableSnapshot) => (
              <div className='bucket-asset-list-container'
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {/* <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isLoading && !isPaginating} /> */}
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isLoading && !isPaginating}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMoreAsset}
                    hasMore={this.props.totalCount > assetList.length}
                    initialLoad={false}
                    useWindow={false}
                  >
                    {assetList && assetList.length ? assetList.map((asset, index) => {
                      let imageUrl
                      if (asset.defaultMedia) {
                        imageUrl = generateCroppedThumbnail(asset.defaultMedia, 87, 48, '16:9')
                      }
                      return (<Draggable key={index} draggableId={asset.id + `#Asset_list_` + index + 1} index={index} isDragDisabled={asset.isArchived || isDragDisabled || disableAssetDrag}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <AssetListCell
                              key={asset.id}
                              assetType={asset.type}
                              imageUrl={imageUrl}
                              status={asset.status}
                              date={moment(new Date(asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                              assetName={asset.title}
                              assetId={asset.id}
                              displayId={asset.shortId || asset.id}
                              onSelect={() => {}}
                              onCheck={this.onItemCheck}
                              project={project}
                              asset={asset}
                            />
                          </div>
                        )}
                      </Draggable>)
                    }) : <Empty /> }
                  </InfiniteScroll>
                </Skeleton>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isPaginating && this.props.totalCount > assetList.length} />

              </div>
            )}
          </Droppable>
        </div>
      </React.Fragment>
    )
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.listData, this.state.listData)) {
      this.setState({ listData: nextProps.listData })
    }
  }
}

BucketAssetList.propTypes = {
  /** Asset List */
  listData: PropTypes.arrayOf(PropTypes.object),
  /** List of selected assets id to hide from asset list */
  selectedAssets: PropTypes.arrayOf(PropTypes.string),
  /** Boolean for disabling drag */
  isDragDisabled: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryFilterAssets,
    {
      options: (props) => {
        const { searchString, filterVal, sort, project } = props
        const variables = utilityService.getFormattedAssetFilter(searchString, filterVal, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const assetList = data.listAssets ? data.listAssets.items : []
        return {
          assetList,
          isLoading: data.loading || !data.listAssets,
          totalCount: data.listAssets ? data.listAssets.totalCount : 0,
          getAssetList: (page, project) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project
              },
              updateQuery: (previous, { fetchMoreResult }) => {
                const prev = cloneDeep(previous)
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAssets.items, ...fetchMoreResult.listAssets.items]
                prev.listAssets.items = newList
                return prev
              }
            })
          },
          subscribeToNewAsset: () => {
            return props.data.subscribeToMore({
              document: SubscriptionAssetList,
              updateQuery: (previous, { subscriptionData: { data: { assetCreated } } }) => {
                const prev = cloneDeep(previous)
                const { project } = props.ownProps
                if (!assetCreated || ((project === 'hyperion' || project === 'projectx') && assetCreated.id.includes('os')) || (project === 'vcms' && assetCreated.id.includes('h'))) return prev
                const newList = [ assetCreated, ...prev.listAssets.items ]
                prev.listAssets.items = newList
                prev.listAssets.totalCount = prev.listAssets.totalCount + 1
                return prev
              }
            })
          }
        }
      }

    }
  )
)(BucketAssetList))
