import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'

import OptaPlayerData from './OptaPlayerData'
import EditTeamModal from '../dataEntry/other/EditTeamModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetTeamDetails from '../../../graphQL/admin/getTeam'
import QueryTeam from '../../../graphQL/admin/listTeam'
import './../ui.style.scss'

class OptaTeamData extends Component {
  constructor (props) {
    super(props)
    this.state = {
      teams: props.team && props.team.length ? props.team : undefined,
      players: props.player && props.player.length ? props.player : undefined,
      teamIsLoading: true
    }
    this.columns = [
      {
        dataIndex: 'id',
        key: 'title',
        render: (id) => this.renderTeamTitle(id)
      },
      {
        dataIndex: 'id',
        key: 'code',
        render: (id) => this.renderTeamCode(id)
      },
      {
        key: '3',
        dataIndex: 'id',
        render: (id) => this.props.renderData(id, 'team', props.teamFilter)
      }
    ]
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.team && !_.isEqual(newProps.team, this.props.team)) {
        this.setState({ teams: newProps.team })
      }
      if (newProps.player && !_.isEqual(newProps.player, this.props.player)) {
        this.setState({ players: newProps.player })
      }
      if (newProps.selectedTeam && newProps.selectedTeam.team && newProps.selectedTeamRequest && newProps.selectedTeam.team.id === newProps.selectedTeamRequest.id && this.state.teamIsLoading) {
        this.setState({ teamIsLoading: false })
      }
      if (newProps.selectedTeam && newProps.selectedTeam.team && newProps.selectedTeamRequest && newProps.selectedTeam.team.id !== newProps.selectedTeamRequest.id && !this.state.teamIsLoading) {
        this.setState({ teamIsLoading: true })
      }
    }

    getPlayerData = (clickedTeam) => {
      let selectedIds = []
      const { teamFilter } = this.props
      teamFilter.team = clickedTeam.id
      teamFilter.teamExternalId = clickedTeam.externalId
      teamFilter.teamCode = clickedTeam.trigramme ? clickedTeam.trigramme : clickedTeam.code
      selectedIds = [teamFilter.team]
      this.props.getSelectedOptaIds(selectedIds)
      return teamFilter
    }

    getTeamTitle = (status) => {
      const { teamFilter, selectedTeam } = this.props
      if (!status) {
        const teamName = selectedTeam.team.shortName
        return `${teamFilter.compName} - ${teamFilter.seasonName} - ${teamName}`
      } else {
        return <Skeleton active avatar={false} title paragraph={false}
          loading={status} />
      }
    }

    renderTeamTitle = (id) => {
      const { teams } = this.state
      let team = {}
      team = teams.find(item => item.id === id)
      return team.customName ? team.customName : team.title
    }

    renderTeamCode = (id) => {
      const { teams } = this.state
      let team = {}
      team = teams.find(item => item.id === id)
      return team.trigramme ? team.trigramme : team.code
    }

    getRenderedThirdColumn =(clickedTeam) => {
      const { editSquad, hideEditSquadModal, selectedPlayerRequest, playerIsLoading, onHistoryUpdate } = this.props
      const playerFilter = this.getPlayerData(clickedTeam)
      return (
        <OptaPlayerData playerFilter={playerFilter} renderData={this.props.renderData} selectedPlayerRequest={selectedPlayerRequest}
          hideEditSquadModal={hideEditSquadModal} editSquad={editSquad} playerIsLoading={playerIsLoading} onHistoryUpdate={onHistoryUpdate} />
      )
    }

    render () {
      const { teams, teamIsLoading } = this.state
      const { editTeam, selectedTeam, hideEditTeamModal, teamFilter, loading, onHistoryUpdate } = this.props
      let teamFilterRequest
      if (teamFilter) {
        let filter = {}
        if (teamFilter.competitionCode) {
          filter.competitionCode = { multiple_exact: teamFilter.competitionCode }
        }
        if (teamFilter.seasonName) {
          filter.seasonName = { multiple_exact: teamFilter.seasonName }
        }
        teamFilterRequest = { filter: filter, limit: 250, offset: 0 }
      }
      return (
        <React.Fragment>
          <Skeleton active avatar={false} title={false} paragraph={{ rows: 3 }}
            loading={loading}>
            <Table rowKey={record => record.id} columns={this.columns} dataSource={teams} pagination={false} showHeader={false} expandedRowRender={(clickedTeam) => this.getRenderedThirdColumn(clickedTeam)} />
          </Skeleton>
          { editTeam ? <EditTeamModal key={editTeam} visible={_.isEmpty(editTeam)} handleCancel={hideEditTeamModal} title={this.getTeamTitle(teamIsLoading)} okText={'Save'} selectedTeam={selectedTeam}
            teamFilter={teamFilter} teamFilterRequest={teamFilterRequest} teamIsLoading={teamIsLoading} onHistoryUpdate={onHistoryUpdate} /> : null }
        </React.Fragment>
      )
    }
}

OptaTeamData.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryGetTeamDetails,
    {
      skip: (props) => {
        return _.isEmpty(props.selectedTeamRequest)
      },
      options: (props) => {
        return {
          variables: props.selectedTeamRequest,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          selectedTeam: props.data.getTeam,
          teamIsLoading: props.data.loading
        }
      }
    }
  ),
  graphql(
    QueryTeam,
    {
      options: ({ teamFilter }) => {
        let filter = null
        if (teamFilter) {
          filter = {}
          if (teamFilter.competitionCode) {
            filter.competitionCode = { multiple_exact: teamFilter.competitionCode }
          }
          if (teamFilter.seasonName) {
            filter.seasonName = { multiple_exact: teamFilter.seasonName }
          }
          if (teamFilter.sport) {
            filter.sport = { exact: teamFilter.sport }
          }
        }
        return {
          variables: { filter: filter, limit: 250, offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let team = data.listTeams ? [ ...data.listTeams.items ] : []
        return {
          team,
          loading: data.loading
        }
      }
    }
  )

)(OptaTeamData))
