import gql from 'graphql-tag'

export default gql(`
    mutation attachResolutions( $title: String  $cropPosition: CROP_POSITION $ratio: Float $name: String $aspectRatio: ID! $resolutions: [ID] ) {
        attachResolutions(
            input: {
                title: $title
                cropPosition: $cropPosition
                ratio: $ratio
                name: $name
                aspectRatio: $aspectRatio
                resolutions: $resolutions
            }
        ) {
            name
            title
            ratio
            aspectRatio
            cropPosition
            resolutions{
                id
                width
                height
            }
        }
    }`
)
