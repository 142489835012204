import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import './../../ui.style.scss'

import Input from '../inputs/Input'
import { utilityService } from './../../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateGenre from './../../../../graphQL/admin/entertainmentData/createGenre'
import QueryGenre from './../../../../graphQL/admin/entertainmentData/listGenre'
import MutationUpdateGenre from './../../../../graphQL/admin/entertainmentData/updateGenre'

class CreateGenreModal extends Component {
    state = {
      genreName: undefined,
      isLoading: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedGenre && (!_.isEqual(this.props.selectedGenre, nextProps.selectedGenre) || (!this.state.genreName))) {
        this.setState({
          genreName: nextProps.selectedGenre.name
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ genreName: value })
        }
      }
    }

    handleAddGenre = () => {
      const { genreName } = this.state
      const { selectedGenre, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })

      if (selectedGenre) {
        const newGenre = {
          id: selectedGenre.id,
          name: genreName
        }
        if (!genreName) {
          message.error('Name is mandatory field')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateGenre(newGenre).then(response => {
          this.props.handleSubmit(response.data.updateGenre)
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newGenre = {
          name: genreName
        }
        this.props.createGenre(newGenre).then(response => {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          message.success('Genre created successfully')
          this.props.handleSubmit(response.data.createGenre)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    resetState = () => {
      const state = {
        genreName: '',
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedGenre, isSubmitDisabled } = this.props
      const { genreName, isLoading } = this.state
      const disabled = selectedGenre ? (selectedGenre.name === genreName && selectedGenre.name) : (!genreName)
      return (
        <Modal
          className='general-modal create-general-modal'
          title={selectedGenre ? 'EDIT GENRE' : 'NEW GENRE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddGenre}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
          confirmLoading={isLoading}
        >
          <div className='create-role'>
            <Input title='Name' isRequired inputName='name' handleChange={this.handleChange} value={genreName} placeholder='Enter Genre name' />
          </div>
        </Modal>
      )
    }
}

CreateGenreModal.propTypes = {
  /** visible file status of CreateRoleModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateRoleModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

CreateGenreModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateGenre,
    {
      options: ({ listApi, project, searchString }) => {
        return {
          update: (cache, { data: { createGenre } }) => {
            try {
              const variables = { limit: 20, offset: 0, project: project, search: searchString || null }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGenre, variables }))
              if (cacheData && cacheData.listGenre && cacheData.listGenre.items) {
                cacheData.listGenre.items.unshift(createGenre)
              }
              cache.writeQuery({
                query: QueryGenre,
                data: cacheData,
                variables
              })
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createGenre: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateGenre,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateGenre } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project: project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGenre, variables }))
              const index = cacheData.listGenre.items.findIndex(item => item.id === updateGenre.id)
              if (index > -1 && updateGenre.name) {
                cacheData.listGenre.items[index].name = updateGenre.name
              }
              cache.writeQuery({
                query: QueryGenre,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { filter: null, offset: 0, limit: 20, project: project }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryGenre, variables: newVariables }))
                const index = newCacheData.listGenre.items.findIndex(item => item.id === updateGenre.id)
                if (index > -1 && updateGenre.name) {
                  newCacheData.listGenre.items[index].name = updateGenre.name
                }
                cache.writeQuery({
                  query: QueryGenre,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateGenre: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateGenreModal))
