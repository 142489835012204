import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import './../../ui.style.scss'
import EditIdModal from '../other/EditIdModal'

const Option = Select.Option

class MetaDefaultDropDown extends React.Component {
    state = {
      value: undefined,
      isSearchPresent: false,
      showModal: false,
      urlFieldArray: [],
      selectedString: ''
    }

    onOptionSelect = (value) => {
      let urlField = this.extractValues(value)
      if (urlField.length > 0) {
        this.setState({ showModal: true, urlFieldArray: urlField, selectedString: value })
      } else {
        this.props.handleChange(this.props.meta, value)
      }
    }

    extractValues = (inputString) => {
      // Define the global regular expression pattern
      var pattern = /\{\{(.*?)\}\}/g

      // Use the match method to get an array of all matched values
      var matchResults = inputString.match(pattern)

      // Check if matches are found and extract the values
      if (matchResults) {
        return matchResults.map(match => match.slice(2, -2).trim())
      } else {
        return []
      }
    }

    handleEditId = (data) => {
      const replacedString = this.state.selectedString.replace(/\{\{([^}]+)\}\}/g, (match, p1) => {
        // p1 contains the captured content inside {{ }}
        return data[p1] || '' // Replace with the corresponding object property or an empty string if not found
      })

      this.props.handleChange(this.props.meta, replacedString)
      this.hideModal()
    }

    onDeselect = (value) => {
      const deselect = true
      this.props.handleChange(this.props.meta, value, deselect)
    }

    onRemove = (value) => {
      if (this.props.allowClear && value === undefined) {
        const deselect = true
        this.props.handleChange(this.props.meta, value, deselect)
      }
    }

    onDropdownVisibleChange = (isOpen) => {
      if (!isOpen) { this.setState({ isSearchPresent: false }) }
    }

    onSearch = (value) => {
      this.setState({ isSearchPresent: true })
      if (this.props.onSearch) { this.props.onSearch(value) }
    }

    hideModal = () => {
      this.setState({ showModal: false,
        urlFieldArray: [],
        selectedString: '' })
    }

    render () {
      const { options, selectedValue, parentCompoent, placeholder, isError, title, mode, allowClear } = this.props
      const { showModal, urlFieldArray } = this.state
      let optionComponents = options && options.map((option, index) => (
        <Option key={index} value={option}> { option }
        </Option>
      ))

      return (
        <div className={`meta-field-drop-down ${isError ? 'meta-field-drop-down-error' : ''}`}>
          <Select
            dropdownClassName='category-dropdown-popup'
            suffixIcon={<ExpandedIcon />}
            clearIcon={<CrossIcon />}
            value={selectedValue || []}
            placeholder={placeholder} showSearch
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onSelect={this.onOptionSelect}
            filterOption={(input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onDropdownVisibleChange={this.onDropdownVisibleChange}
            mode={mode || 'multiple'}
            onDeselect={this.onDeselect}
            allowClear={allowClear}
            onChange={this.onRemove}
            className={`input-tags`}
          >
            { optionComponents }
          </Select>
          {
            isError &&
            <p>{`${title} is a mandatory field`}</p>
          }
          <EditIdModal
            isVisible={showModal}
            handleCancel={this.hideModal}
            handleSubmit={this.handleEditId}
            urlFieldArray={urlFieldArray}
            title='edit url'
            module='ASSET_MANAGER'
          />
        </div>
      )
    }
}
MetaDefaultDropDown.propTypes = {
  /** assetCategory of MetaDefaultDropDown. */
  options: PropTypes.array
}

MetaDefaultDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default MetaDefaultDropDown
