import gql from 'graphql-tag'

export default gql(`
    mutation updateDisplayType($id: ID! $name: String! $key: String! $project: String) {
        updateDisplayType(
            input: {
                id: $id
                name: $name
                key: $key
            }
            project: $project
        ) {
            id
            name
            key
            usedCount
        }
    }`
)
