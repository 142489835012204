import gql from 'graphql-tag'

export default gql(`
query getArticlePreview($id:ID!){
    getArticlePreview(
        id:  $id
    ){
        previewUrl
        identifier
        isPreview
        revision
        selfUrl
        id
    }
}`)
