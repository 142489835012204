import gql from 'graphql-tag'

export default gql(`
mutation createTagType($name: String!,$enabledAssetTypes: [TagAssetTypeInput],$key: String!,$isMediaEnabled: Boolean, $project: String, $position: Int) {
    createTagType(
        input: {
            name: $name
            enabledAssetTypes: $enabledAssetTypes
            isCustom: false,
            key: $key
            isMediaEnabled: $isMediaEnabled
            position: $position
        },
        project: $project
    ) {
        id
        name
        key
        position
        isCustom
        isMediaEnabled
        enabledAssetTypes{
            assetType
        }
        tagCount
    }
}`
)
