import * as React from 'react'
import CollapsedIcon from '../general/icons/CollapsedIcon'
import { Collapse, Input as antdInput, Icon, message } from 'antd'
import Input from '../dataEntry/inputs/Input'
import InputNumber from '../dataEntry/inputs/InputNumber'
import PropTypes from 'prop-types'
import _ from 'lodash'
const { TextArea } = antdInput

const Panel = Collapse.Panel

export class ConfigEditAccordion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      url: props.url,
      urlWarning: false
    }
  }

  copyOnClick = (data, type) => {
    let value = ''
    if (data) {
      value = data
    }
    navigator.clipboard.writeText(value, type)
    message.success(`${type} Copied Successfully`)
  }

  render () {
    const { jsonInvalidEntries } = this.props
    return <Collapse defaultActiveKey={[ '1' ]} className='config-accordion-container'>
      <Panel key={'1'}header={
        <div className='config-accordion-header'>
          <div className='title'>{this.props.title}</div>
          <div className='accordion-arrow'><CollapsedIcon /></div>
        </div>
      }>
        <div className={`config-url-container`}>
          <Input inputName='URL' title='URL' placeholder='Enter URL' value={this.state.url} handleChange={this.onUrlEnter} isError={this.state.urlWarning} />
          {this.props.fields ? this.props.fields.map((item, index) => {
            if (item.type === 'STRING') {
              return <Input key={index} inputName={item.name} title={item.name} placeholder={`Enter ${_.capitalize(item.name)}`} value={item.value}
                handleChange={(event) => this.onDataEnter(item.name, undefined, event)} isRequired={item.isRequired} />
            } else if (item.type === 'INTEGER') {
              return <InputNumber key={index} inputName={item.name} title={item.name} placeholder={`Enter ${_.capitalize(item.name)}`} value={item.value}
                handleChange={(event) => this.onDataEnter(item.name, undefined, { target: { value: event } })} isRequired={item.isRequired} />
            } else if (item.type === 'JSON') {
              let index = (jsonInvalidEntries || []).indexOf(item.id)
              let jsonErrorMessage = false
              if (index !== -1) {
                jsonErrorMessage = true
              }
              return (
                <div className={`json-field`}>
                  <div>
                    <label>{item.name}</label>
                    {item.isRequired ? <span className='mandatory'>*</span> : ''}
                    <Icon type='copy' onClick={() => this.copyOnClick(item.value, 'JSON ARRAY')} />
                    <p className='json-error'>{jsonErrorMessage ? 'Please Enter Valid JSON' : ''}</p>
                  </div>
                  <TextArea name={item.name} placeholder={`Enter ${_.capitalize(item.name)}`} value={item.value} onChange={(event) => this.onDataEnter(item.name, undefined, event, item.type, item.id)} autosize={{ minRows: 2, maxRows: 6 }} />
                </div>

              )
            } else if (item.type === 'BOOLEAN') {
              return <div className='checkbox-container' key={index}>
                {item.isRequired ? <div className='checkbox-name'>{`${item.name} `}<span className='mandatory'>*</span>:</div> : <div className='checkbox-name'>{`${item.name}:`}</div>}
                <div className={`${item.value ? 'checkbox-checked' : 'checkbox'}`} onClick={(event) => this.onDataEnter(item.name, !item.value, event)}>
                  <div className='checked' />
                </div>
              </div>
            }
          }
          ) : ''}
        </div>
      </Panel>
    </Collapse>
  }
    onUrlEnter = (e) => {
      const url = e.target.value
      const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      const urlWarning = url === '' ? false : !url.match(urlRegex)
      this.setState({ url, urlWarning })
      if (this.props.onUrlEnter) { this.props.onUrlEnter(url) }
    }

    onDataEnter = (name, isChecked, event, type, id) => {
      const value = event.target.value ? event.target.value : isChecked
      this.props.onDataEnter(value, name, type, id)
    }

  // isValidJson = (json) => {
  //   try {
  //     if (json === '') {
  //       return true
  //     }
  //     JSON.parse(json)
  //     return true
  //   } catch (e) {
  //     return false
  //   }
  // }

  // handleJsonValidation = (item, value) => {
  //   const { jsonInvalidEntries } = this.state
  //   let isJsonValid = true
  //   if (_.isEmpty(value)) {
  //     isJsonValid = true
  //     let index = (jsonInvalidEntries || []).indexOf(item)
  //     if (index !== -1) {
  //       jsonInvalidEntries.splice(index, 1)
  //     }
  //   } else {
  //     let isValidJson = this.isValidJson(value, false)
  //     if (!isValidJson) {
  //       let index = (jsonInvalidEntries || []).indexOf(item)
  //       if (index === -1) {
  //         jsonInvalidEntries.push(item)
  //       }
  //       isJsonValid = false
  //     } else {
  //       let index = (jsonInvalidEntries || []).indexOf(item)
  //       if (index !== -1) {
  //         jsonInvalidEntries.splice(index, 1)
  //       }
  //       isJsonValid = true
  //     }
  //   }
  //   return isJsonValid
  // }
}

ConfigEditAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  onUrlEnter: PropTypes.func,
  onDataEnter: PropTypes.func,
  url: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object)
}

ConfigEditAccordion.defaultProps = {
  title: 'Title'
}
