import gql from 'graphql-tag'

export default gql(`
    mutation deleteBucketDisplayType($id: ID! $project: String) {
        deleteDisplayType(
            input: {
                id: $id
            }
            project: $project
        ) {
            id
            key
            name
        }
    }`
)
