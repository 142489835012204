import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getChannel(id: $id) {
        id
        name
        isArchived
        channelCode
        site
        masterConfig {
            id
            name
        }
        configGroups {
            id
            name
            schemaId{
                id
              }
            position
            version
            name
            fields{
                name
                value
                isRequired
                type
            }
            streams{
                id
                url
                levelVersions{
                    streamId
                    version
                }
                fields{
                    name
                    value
                    isRequired
                    type
                }
                url
                streamSchema{
                    id
                    levels{
                        id
                        name
                        version
                        fields{
                            name
                            value
                            isRequired
                            type
                        }
                    }
                }
            }
        }
        activeConfigGroup{
            id
            name
            version
            streams{
id
url
streamSchemaId
streamSchema {
id
levels {
name
id
}
}
                    fields{
                        name
                        isRequired
                        type
                        value
                    }
            }

        }
    }
}`)
