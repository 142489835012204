import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import './../../ui.style.scss'
import Tooltip from './../../dataDisplay/ToolTip'
import InformationIcon from '../../general/icons/InformationIcon'

const { TextArea } = Input

const onChange = (handleChange, event) => {
  const value = event.target.value
  if (!value.startsWith(' ')) { handleChange(event) }
}

const TextAreaInputApp = ({ handleChange, value, placeholder, title, inputName, isEditable, isLimited, limit, onFocus, isRequired, jsonInvalidEntries, currentId, isError, tooltipText, ...props }) => {
  let index = (jsonInvalidEntries || []).indexOf(currentId)
  let jsonError = isError
  let jsonErrorMessage = false
  if (index !== -1) {
    jsonError = true
    jsonErrorMessage = true
  }
  return (
    <div className={`input-field text-area ${jsonError ? 'error' : ''}`}>
      <div><label>{title}</label>{isRequired ? <span className='mandatory'>*</span> : ''} {tooltipText ? <span id='info-icon'><Tooltip placement='rightTop' childComponent={<div className='info-icon'><InformationIcon /></div>} description={tooltipText} parentCompoent='info-icon' /></span> : null }</div>
      { isLimited ? <label className='limit'><span>Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
      <TextArea name={inputName} placeholder={placeholder} value={value} onChange={onChange.bind(this, handleChange)} autosize={{ minRows: 2, maxRows: 6 }} maxLength={limit} onClick={onFocus} readOnly={!isEditable} />
      <p className='json-error'>{jsonErrorMessage ? 'Please Enter Valid JSON' : ''}</p>
    </div>
  )
}

TextAreaInputApp.propTypes = {
  /** Change action of TextAreaInputApp. */
  handleChange: PropTypes.func,
  /** display value of TextAreaInputApp. */
  value: PropTypes.string,
  /** placeholder of TextAreaInputApp. */
  placeholder: PropTypes.string.isRequired,
  /** title of TextAreaInputApp. */
  title: PropTypes.string.isRequired,
  /** input name of TextAreaInputApp. */
  inputName: PropTypes.string.isRequired,
  /** editable status of TextAreaInputApp. */
  isEditable: PropTypes.bool,
  /** string limited status of TextAreaInputApp. */
  isLimited: PropTypes.bool,
  /** string limit of TextAreaInputApp. */
  limit: PropTypes.number
}

TextAreaInputApp.defaultProps = {
  isEditable: true
}

export default TextAreaInputApp
