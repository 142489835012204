import gql from 'graphql-tag'

export default gql(`
query getAppConfig($id: ID!, $project: String!) {
    getAppConfig(id: $id, project: $project) {
        id
        key
        title
        project
        createdAt
        updatedAt
        updatedBy
        createdBy
    }
}`)
