import React from 'react'
import PropTypes from 'prop-types'

import GroupDragAndDropIcon from './../general/icons/GroupDragAndDropIcon'
import CrossIcon from './../general/icons/CrossIcon'
import SchemaDropDown from './../dataEntry/inputs/SchemaDropDown'
import RatingDropDown from './../dataEntry/inputs/RatingDropDown'
import ContentRatingDropDown from './../dataEntry/inputs/ContentRatingDropDown'

import './../ui.style.scss'

const parentCompoent = 'asset-container'

class RatingFieldCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      details: props.field
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (this.props.field !== newProps.field) {
      this.setState({
        details: newProps.field
      })
    }
  }

  onCloseField = (index) => {
    this.props.onCloseField(index)
  }

  onSchemaSearch = (value) => {
    if (this.props.onSchemaSearch) { this.props.onSchemaSearch(value) }
  }

  onRatingSearch = (value) => {
    if (this.props.onRatingSearch) { this.props.onRatingSearch(value) }
  }

  render () {
    const { details } = this.state
    const { index, onSchemaSelect, schemas, schemaSearch, onRatingSelect, ratingSearch, onContentRatingSelect,
      listRatingDetails, isUpdateDisabled } = this.props
    const data = details.scheme ? listRatingDetails.filter(item => item.scheme === details.scheme) : []
    const ratings = data.length ? data[0].rating : []
    const contentRatings = data.length ? data[0].subRatings : []
    const schemaIndex = details.scheme ? (listRatingDetails || []).findIndex(item => item.scheme === details.scheme) : -1
    const ratingIndex = details.scheme && details.rating && schemaIndex > -1 ? (listRatingDetails[schemaIndex].rating || []).findIndex(item => item === details.rating) : -1
    const subRatingsIndex = details.scheme && details.subRatings && schemaIndex > -1 ? (listRatingDetails[schemaIndex].subRatings || []).findIndex(item => (details.subRatings || []).includes(item)) : -1
    const isPresent = schemaIndex > -1 && ratingIndex > -1 && subRatingsIndex > -1
    const isComplete = (details.scheme && details.rating && details.subRatings && details.subRatings.length)
    const isValid = isPresent || !isComplete

    return (
      <div className={`rating-field-cell rating-field-general${(isValid) ? '' : ' invalid'}`}>
        <GroupDragAndDropIcon />
        <div className='group' id='group'>
          <label className='label'>Schema</label>
          <SchemaDropDown
            parentCompoent={parentCompoent}
            onSchemaSelect={onSchemaSelect}
            selectedSchema={details.scheme}
            index={index}
            onSearch={this.onSchemaSearch}
            schemas={schemas}
            schemaSearch={schemaSearch}
            isDisable={!isValid}
            listRatingDetails={listRatingDetails}
          />
        </div>

        <div className='group' id='group'>
          <label className='label'>Rating</label>
          <RatingDropDown
            parentCompoent={parentCompoent}
            onRatingSelect={onRatingSelect}
            selectedRating={details.rating}
            index={index}
            onSearch={this.onRatingSearch}
            ratings={ratings}
            ratingSearch={ratingSearch}
            isDisable={!isValid}
          />
        </div>

        <div className='group1' id='group1'>
          <label className='label'>Content Rating</label>
          <ContentRatingDropDown
            onContentRatingSelect={onContentRatingSelect}
            index={index}
            contentRatings={contentRatings}
            selectedContentRating={details.subRatings}
            parentCompoent={parentCompoent}
            isDisable={!isValid}
          />
        </div>

        {isUpdateDisabled ? '' : <CrossIcon onClick={() => this.onCloseField(index)} />}

      </div>
    )
  }
}
RatingFieldCell.propTypes = {
  /** number of ratingField drag and drop */
  index: PropTypes.number,
  /** data for ratingField */
  field: PropTypes.object
}

export default RatingFieldCell
