import * as React from 'react'
import PropTypes from 'prop-types'
import CollapsedIcon from '../../components/ui/general/icons/CollapsedIcon'
import JobTableRow from './JobTableRow'
import moment from 'moment'

class JobTableGroup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: true
    }
  }

    checkForRecreate = (job) => {
      if (job.jobType && job.jobType.jobType) {
        if (job.jobType.jobType === 'LIVE_TO_VOD') {
          return moment().diff(job.endTime, 'seconds') <= 86400
        } else return true
      } else return false
    }

    renderTableGroupTitle = (title, isOpen) => {
      return <div className='job-table-header group-title'>
        <div className='job-type'><span>{title && title !== 'UNDEFINED' && title !== 'null' && title !== null ? title : 'UNKNOWN'}</span></div>
        <div className='job-id'><span /></div>
        <div className='job-name'><span /></div>
        <div className='job-triggered-person'><span /></div>
        <div className='job-triggered-date'><span /></div>
        <div className='job-asset'><span /></div>
        <div className='job-asset-id'><span /></div>
        <div className='job-channel'><span /></div>
        <div className='job-channel-code'><span /></div>
        <div className='job-program'><span /></div>
        <div className='job-site'><span /></div>
        <div className='job-match'><span /></div>
        <div className='job-match-date'><span /></div>
        <div className='job-match-opid'><span /></div>
        <div className='job-last-update'><span /></div>
        <div className='jobs-status'><span /></div>
        <div className='jobs-duration'><span /></div>
        <div className={`job-check${isOpen ? ' open' : ''}`} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
          <CollapsedIcon />
        </div>
      </div>
    }

    renderTableContent = (jobList, selectedId, selectedErrors) => {
      const { onSelectJob, onRecreateJob, addErrorToSelected } = this.props
      return (jobList || []).map((item) =>
        <JobTableRow
          job={item}
          selectedId={selectedId}
          selectedErrors={selectedErrors}
          onSelectJob={onSelectJob}
          onRecreateJob={onRecreateJob}
          addErrorToSelected={addErrorToSelected}
          key={item.id} />
      )
    }

    render () {
      const { groupHeader, jobList, selectedId, selectedErrors } = this.props
      const { isOpen } = this.state
      return <React.Fragment>
        {this.renderTableGroupTitle(groupHeader, isOpen)}
        {isOpen ? this.renderTableContent(jobList, selectedId, selectedErrors) : ''}
      </React.Fragment>
    }
}

JobTableGroup.propTypes = {
  /** List of jobs */
  jobList: PropTypes.any,
  /** Group Header */
  groupHeader: PropTypes.string,
  /** ID of selected job */
  selectedId: PropTypes.string,
  /** List of selected error jobs for recreate */
  selectedErrors: PropTypes.array,
  /** Function to be called on selection of a job */
  onSelectJob: PropTypes.func,
  /** Function to be called on recreate job */
  onRecreateJob: PropTypes.func,
  /** Function to be called on adding a job for recreation */
  addErrorToSelected: PropTypes.func
}

export default JobTableGroup
