import * as React from 'react'
import PropTypes from 'prop-types'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryJobStatus from '../../graphQL/jobs/getJobDetails'
// import QueryJobStatusSubscription from '../../graphQL/jobs/getVodDetailsSubscription'

import { Checkbox, Progress } from 'antd'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import AlertIcon from '../../components/ui/general/icons/AlertIcon'
import ToolTip from '../../components/ui/dataDisplay/ToolTip'
import moment from 'moment'

import { generateCroppedThumbnail } from '../../util/util'
import { utilityService } from '../../services/UtilityService'
import { formatDuration } from '../../components/player/jsPlayers/Utils/time'

class JobTableRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isUpdated: false
    }
  }

    componentDidMount = () => {
      const { job } = this.props
      if (job && job.lastReceivedStatus !== 'COMPLETE' && !job.lastReceivedStatus.includes('ERROR') && job.lastReceivedStatus !== 'UNDEFINED' && job.lastReceivedStatus !== 'PUBLISHED') {
      // if (job && job.jobStatus.status !== 'COMPLETE' && job.jobStatus.status !== 'ERROR' && job.jobStatus.status !== 'UNDEFINED' && job.jobStatus.status !== 'PUBLISHED') {
        this.liveTimeout = setInterval(this.getUpdatedJobStatus, 5 * 1000)
        // this.props.subscribeToJobStatus()
      }
    }

    componentWillUnmount () {
      clearInterval(this.liveTimeout)
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      if (nextProps.job && !_.isEqual(nextProps.job.jobStatus, this.props.job.jobStatus) && nextProps.job.lastReceivedStatus !== 'COMPLETE') {
        this.setState({ isUpdated: true }, () => {
          setTimeout(() => {
            this.setState({ isUpdated: false })
          }, 500)
        })
      }
    }

    getToolTipDescription=(cuePointsError, vodValidationError) => {
      return <div className='state-errors'>{cuePointsError ? <><span>Cue Point Error</span> <hr /><span className='description'>{cuePointsError || '-'}</span> </> : null}</div>
    }

    checkForRecreate = (job) => {
      if (job.jobType && job.jobType.jobType) {
        if (job.jobType.jobType === 'VOD_MEDIA') {
          return true
        } else {
          return moment().diff(job.endTime, 'seconds') <= 86400
        }
      } else return false
    }

    getUpdatedJobStatus = () => {
      if (this.props.fetchDirectVodStatus && this.props.job) {
        this.props.fetchDirectVodStatus(this.props.job.id)
      }
    }

    getVodState = (states) => {
      if (states && states.length) { return (states || []).map((item) => <div className='state-list'><span>{item.status}</span><span>{moment(item.updatedAt).format('DD MMM YYYY HH:mm')}</span></div>) } else return [<div className='state-list'>{'Job status not available'}</div>]
    }

    renderJobStatus = (job, parent, description) => {
      // this.props.selectedId === job.id
      // 'something went wrong!'
      console.log('job', job)
      const cuePointsError = job && job.media && job.media.cuePointsError ? job.media.cuePointsError : ''
      const vodValidationError = job && job.validations && job.validations.error ? job.validations.error : ''
      const errorDescription = this.getToolTipDescription(cuePointsError, vodValidationError)
      return ((cuePointsError) && !job.jobStatus.status.includes('ERROR')) ? <React.Fragment>
        <ToolTip isList details={description} parentCompoent={'status_' + parent} placement={'leftTop'} heading={job.jobStates && job.jobStates.length ? job.jobName : ''}
          childComponent={<span className={`${job.jobType.isLive ? 'cue-error-live' : 'cue-error'} ${(job.jobStatus.status || '').toLowerCase()}`}>{job.jobStatus.status === 'UNDEFINED' ? 'UNKNOWN' : job.jobStatus.status}</span>} />
        <ToolTip isList details={[errorDescription]} parentCompoent={'status_' + parent} placement={'leftTop'}
          childComponent={<AlertIcon height={13} isLive={job.jobType.isLive} />} />
      </React.Fragment>
        : <ToolTip isList details={description} parentCompoent={'status_' + parent} placement={'leftTop'} heading={job.jobStates && job.jobStates.length ? job.jobName : ''}
          childComponent={<span className={(job.jobStatus.status || '').toLowerCase()}>{job.jobStatus.status === 'UNDEFINED' ? 'UNKNOWN' : job.jobStatus.status}</span>} />
    }

    renderMatch (teamA, teamB) {
      const defaultIcon = require('../../assets/images/avatar-team-icon.png')
      return (
        <div className='match-view-details'>
          <div className='match'>
            <p>{teamA.code}</p>
            <img src={
              teamA.media && teamA.media.length && teamA.media[0]
                ? generateCroppedThumbnail(teamA.media[0], 200, 200, '1:1')
                : defaultIcon
            } />
          </div>
          <p className='vs'>VS</p>
          <div className='match'>
            <img
              src={
                teamB.media && teamB.media.length && teamB.media[0]
                  ? generateCroppedThumbnail(teamB.media[0], 200, 200, '1:1')
                  : defaultIcon
              }
            />
            <p>{teamB.code}</p>
          </div>
        </div>
      )
    }

    render () {
      const { onSelectJob, onRecreateJob, addErrorToSelected, job, selectedId, selectedErrors } = this.props
      const { isUpdated } = this.state
      const description = this.getVodState(job.jobStates)
      const parent = utilityService.makeRandomString(7)
      return <div className={`job-table-row${selectedId === job.id ? ' selected' : ''}${isUpdated ? ' updated' : ''}`} onClick={(event) => onSelectJob(job.id, event)}>
        <div className='job-type'>
          <div className='indicator' />
          <div className={job.jobType.isLive ? 'live' : 'vod'}>{job.jobType.isLive ? <span>LIVE</span> : <span>VOD</span>}</div>
          {job.jobType.isLive ? <span>{job.jobType.type}</span> : ''}
        </div>
        <div className='job-id'><a href={location.origin + '/assets/' + job.assetId} target='_blank'>{job.assetId}</a></div>
        <div className='job-name'><span>{job.assetTitle}</span></div>
        {/* <div className='job-asset-id'><ButtonContainer displayTitle={job.jobId} childComponent={<span>{job.jobId}</span>} /></div>
        <div className='job-asset'><span>{job.jobName}</span></div> */}
        <div className='job-triggered-person'><span>{job.triggeredBy ? job.triggeredBy : '-'}</span></div>
        <div className='job-triggered-date'><span>{job.triggeredDate ? moment(job.triggeredDate, 'DD MMM YYYY HH:mm').format('DD MMM HH:mm') : '-'}</span></div>
        {/* <div className='job-channel'><span>{job.channelName ? job.channelName : '-'}</span></div> */}
        <div className='job-channel-code'>{job.channelCode ? <a href={location.origin + '/channels/' + job.channelCode} target='_blank'>{job.channelCode}</a> : <span>{'-'}</span>}</div>
        <div className='job-program'><span>{job.startEndTime ? job.startEndTime : '-'}</span></div>
        <div className='job-site'><span>{job.site}</span></div>
        <div className='job-match'><span>{job.matchOpid ? this.renderMatch(job.homeTeam, job.awayTeam) : '-'}</span></div>
        <div className='job-match-date'><span>{job.matchDate ? moment(job.matchDate, 'DD MMM YYYY HH:mm').format('DD MMM HH:mm') : '-'}</span></div>
        <div className='job-match-opid'><span>{job.matchOpid ? job.matchOpid : '-'}</span></div>
        <div className='job-last-update'><span>{moment(job.lastUdated, 'DD MMM YYYY HH:mm').format('DD MMM HH:mm')}</span></div>
        <div className='jobs-status' id={'status_' + parent}>
          {(!job.jobStatus.status.includes('ERROR') && job.jobStatus.status !== 'QUEUED' && job.jobStatus.status !== 'UNDEFINED' && job.jobStatus.status !== 'UNKNOWN') || job.jobStatus.status === 'COMPLETE'
            ? <ToolTip isList details={description} parentCompoent={'status_' + parent} placement={'leftTop'} heading={job.jobStates && job.jobStates.length ? job.jobName : ''}
              childComponent={<Progress percent={job.jobStatus.status === 'COMPLETE' ? 100 : job.jobStatus.progress} showInfo={false}
                status={job.jobStatus.status.includes('ERROR') ? 'exception' : job.jobStatus.status === 'COMPLETE' ? 'success' : 'active'} strokeColor={job.media && job.media.cuePointsError ? (job.jobType.isLive ? '#f5212d' : '#fdbd39') : job.jobStatus.status === 'REGISTERED' ? '#50e3c2' : ''} />} /> : ''}
          {this.renderJobStatus(job, parent, description)}
          {(job.jobStatus.status.includes('ERROR') && !job.relatedJob && this.checkForRecreate(job)) ? <span className='recreate-job' onClick={(event) => onRecreateJob(event, job.id)}>Recreate</span>
            : job.relatedJob ? <ButtonContainer displayTitle={job.relatedJob} styleClass='error' childComponent={<a className='recreate-job recreate-id' href={location.origin + '/jobs/' + job.relatedJob} target='_blank'>{job.relatedJob}</a>} /> : ''}
        </div>
        <div className='jobs-duration'><span>{job.duration === '' ? '-' : formatDuration(job.duration)}</span></div>
        <div className='job-check'>
          <Checkbox onChange={(event) => addErrorToSelected(job.id, event)} checked={selectedErrors.indexOf(job.id) > -1} disabled={job.jobStatus.status.includes('ERROR') || job.relatedJob || !this.checkForRecreate(job)} />
        </div>
      </div>
    }
}

JobTableRow.propTypes = {
  /** List of jobs */
  job: PropTypes.any,
  /** ID of selected job */
  selectedId: PropTypes.any,
  /** List of selected error jobs for recreate */
  selectedErrors: PropTypes.array,
  /** Function to be called on selection of a job */
  onSelectJob: PropTypes.func,
  /** Function to be called on recreate job */
  onRecreateJob: PropTypes.func,
  /** Function to be called on adding a job for recreation */
  addErrorToSelected: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryJobStatus,
    {
      skip: ({ job }) => {
        let refetchForValidation = false
        if (job.lastReceivedStatus === 'COMPLETE' && (!job.validations || job.validations.status !== 'COMPLETE')) {
          const hoursSinceJobtriggered = moment().diff(moment(job.triggeredDate), 'hours')
          refetchForValidation = !((hoursSinceJobtriggered > 24))
        }
        const isMedia = (job.lastReceivedStatus === 'COMPLETE' && (job.validations.status === 'COMPLETE' || refetchForValidation === false)) ? !_.isEmpty(job.media) : (job.lastReceivedStatus.includes('ERROR') || job.lastReceivedStatus === 'UNDEFINED' || job.lastReceivedStatus === 'PUBLISHED')
        return !job || isMedia
      },
      options: ({ job }) => {
        return {
          variables: { id: job.id }
        }
      },
      props: (props) => {
        return {
          // subscribeToJobStatus: () => {
          //   return props.data.subscribeToMore({
          //     document: QueryJobStatusSubscription,
          //     updateQuery: (prev, { subscriptionData: { data: { vwmJobStatusUpdate } } }) => ({
          //       ...prev,
          //       jobs: { __typename: 'LiveToVodJobStatusConnection', job: parseData(vwmJobStatusUpdate) }
          //     })
          //   })
          // },
          fetchDirectVodStatus: (id) => {
            return props.data.refetch({ id })
          }
        }
      }
    }
  )

)(JobTableRow))
