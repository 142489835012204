import gql from 'graphql-tag'

export default gql(`
query listChannelAssetManager($filter: TableChannelFilterInput $project: String){
    listChannels(
        limit: 99,
        filter: $filter
        project: $project
    ){
        items{
            id
            name
            channelCode
            isArchived
            activeConfigGroup{
                id
                name
            }
        }
    }
}`)
