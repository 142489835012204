import * as React from 'react'
import PropTypes from 'prop-types'
import Switch from '../dataEntry/inputs/Switch'
import InputNumber from '../dataEntry/inputs/InputNumber'
import DropDown from '../dataEntry/inputs/DropDown'
import _ from 'lodash'

const cropPositionList = [
  {
    label: 'Top Left',
    id: 'TOP_LEFT'
  },
  {
    label: 'Top Right',
    id: 'TOP_RIGHT'
  },
  {
    label: 'Bottom Left',
    id: 'BOTTOM_LEFT'
  },
  {
    label: 'Bottom Right',
    id: 'BOTTOM_RIGHT'
  },
  {
    label: 'Middle',
    id: 'CENTRE'
  }
]

class AssetTypeRequiredFieldList extends React.Component {
    renderOptionListBody = () => {
      const { onChange, details, listAssetTypes, handleNumberChange, onCropPositionCHange } = this.props

      return (listAssetTypes || []).map((assetType) => {
        const selectedAsset = (details || []).find(item => item.assetType === assetType.title)
        return <div className='option-item' key={assetType.title}>
          <span>{assetType.title}</span>
          {selectedAsset ? <InputNumber min={0} value={selectedAsset.minCount} handleChange={(value) => handleNumberChange(assetType.title, value)} /> : ''}
          {selectedAsset
            ? <DropDown
              options={cropPositionList}
              selectedValue={selectedAsset.cropPosition}
              placeHolder='Select Crop Position'
              valueParam='id'
              displayParam='label'
              onOptionSelect={(value) => onCropPositionCHange(assetType.title, value)}
              className={selectedAsset.cropPosition ? 'selected' : ''} /> : ''}
          <div className='button-container'>
            <Switch isChecked={!!selectedAsset} onChange={(status) => onChange(assetType.title, status)} />
          </div>
        </div>
      })
    }

    render () {
      const { details } = this.props
      return <div className={`optional-list-container image-type`}>
        <div className='optional-list-title'>
          <span>{'Asset Types'}</span>
          {_.isEmpty(details) ? '' : <span>{'Minimum Images'}</span>}
          {_.isEmpty(details) ? '' : <span>{'Crop Position'}</span>}
          <span>{'Enabled'}</span>
        </div>
        <div className='optional-list-body'>
          {this.renderOptionListBody()}
        </div>
      </div>
    }
}

AssetTypeRequiredFieldList.propTypes = {
  /** List of options */
  optionList: PropTypes.array,
  /** Function to be called on change of active state */
  onChange: PropTypes.func
}

export default AssetTypeRequiredFieldList
