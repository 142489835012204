import React from 'react'
import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryBucketDisplayTypeList from '../../../graphQL/bucket/getBucketDisplayType'
import QueryBucketGroupType from '../../../graphQL/admin/bucket/listBucketGroupType'
import QueryPublishGroupList from '../../../graphQL/admin/getPublishingGroups'
import QueryGetOptaSyncState from '../../../graphQL/admin/getOptaSyncState'
import QueryMediaDetails from '../../../graphQL/audit/getBatchMedia'
import QueryAuthorDetails from '../../../graphQL/audit/batchGetAuthor'
import QueryCategoryDetails from '../../../graphQL/audit/batchGetCategory'
import QueryListAssetType from '../../../graphQL/admin/asset/listAssetTypes'
import QueryListImageCategory from '../../../graphQL/admin/media/listMediaCategories'
import QueryGetImageType from '../../../graphQL/admin/media/listImageType'
import QueryGetCompetition from '../../../graphQL/admin/getCompetition'
import QueryAspectRatioDetails from '../../../graphQL/admin/media/listAspectRatios'
import QueryGetSeason from '../../../graphQL/admin/getSeason'
import QueryBatchGetResolutions from '../../../graphQL/audit/getBatchResolutions'
import QueryTeamDetails from '../../../graphQL/audit/listTeamsFilter'
import QueryPlayerDetails from '../../../graphQL/audit/listPlayerFilter'
import QueryListConfigGroups from '../../../graphQL/admin/channel/listConfigGroup'
import QueryListChannelStreams from '../../../graphQL/admin/channel/listStreamLevels'
import QueryListSports from '../../../graphQL/admin/sports/listSports'
import ListMetaSections from '../../../graphQL/asset/listMetaSections'

const lastReplaceRegex = /, ([^,]*)$/

const actionMap = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
  ADD: 'Added',
  REMOVE: 'Removed',
  SYNC: 'Sync'
}
const fieldMap = {
  TITLE: 'title content',
  IMAGE: 'image content',
  DEFAULT_IMAGE: 'default image content',
  DESCRIPTION: 'description content',
  PROGRAM: 'channel program',
  FILTER: 'filter',
  ASSET: 'asset',
  shortTitle: 'short title',
  createdBy: 'created by',
  isLveToVodSupported: 'Auto Trigger',
  isMatchRelated: 'Match Manager Link',
  videoSection: 'Video Section',
  contentSection: 'Content Section',
  programSection: 'Program Section',
  authorSection: 'Author Field',
  matchSection: 'Match Field',
  draftIcon: 'draft icon',
  isActive: 'active',
  isDisabled: 'active',
  trigramme: 'custom code',
  customPostalAddress: 'custom postal address',
  customAddressZip: 'custom address zip',
  customCity: 'custom city',
  customStatus: 'custom status',
  customShortName: 'custom short name',
  customCountry: 'custom country',
  customFounded: 'custom founded',
  customOfficialName: 'custom official name',
  customName: 'custom name',
  customActive: 'custom active',
  customEndDate: 'custom end date',
  customStartDate: 'custom start date',
  customTitle: 'custom title',
  customType: 'custom type',
  customSource: 'custom source',
  customCompetitionCode: 'custom competition code',
  customCompetitionType: 'custom competition type',
  customCompetitionFormat: 'custom competition format',
  customPosition: 'custom position',
  customNationality: 'custom nationality',
  customMatchName: 'custom match name',
  customLastName: 'custom last name',
  customFirstName: 'custom first name',
  awayJersey: 'away jersey logo',
  awayImage: 'away team image',
  homeImage: 'home team image',
  homeJersey: 'home jersey logo',
  customHomeVenue: 'custom home venue',
  shirtNumber: 'shirt number',
  lastRunTime: 'last run time',
  apiKey: 'api key',
  licenseUrl: 'license url',
  DRM_PLAYBACK_SETTINGS: 'DRM playback settings',
  accessKey: 'access key ID',
  secretAccessKey: 'secret access key',
  s3BucketName: 's3 bucket name',
  aws_region: 'region',
  callbackUrl: 'callback URL',
  cognitoClientId: 'cognito client Id',
  cognitoPoolId: 'cognito pool Id',
  encodingTemplate: 'encoding template',
  mediaVodReqUrl: 'VOD status URL',
  requestUrl: 'Live2VOD base URL',
  vodRequestUrl: 'VOD base URL',
  maxAssets: 'max assets',
  minAssets: 'min assets',
  seoMetaDescription: 'SEO description',
  seoTitle: 'SEO title',
  isFree: 'premium',
  publishEndDate: 'publish end date'
}

const publishDuration = {
  HOURS: 'HOUR',
  MINUTES: 'MINUTE',
  DAYS: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
}
const publishDurations = {
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  DAYS: 'DAYS',
  MONTH: 'MONTHS',
  YEAR: 'YEARS'
}
const getFormattedAdminDetails = (audit, action, contentType, contentName, contentId, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let iskey = false
  let changedValue = audit.value
  let oldValue = audit.oldValue
  let newValue = audit.value
  let contentKey = contentId

  if (contentType === 'ASSET_TYPE') {
    console.log('audit', audit)
    if (audit.name === 'Asset Type Meta Field') {
      if (newValue !== null) {
        return { action: actionMap[action], field: `Meta Fields ${audit.displayName}`, value: `${newValue}`, content: contentKey.toUpperCase() }
      } else {
        return null
      }
    }
    if (audit.name === 'Create Asset Type Meta Field') {
      if (newValue !== null) {
        const contentString = 'Meta Fields'
        return { action: 'Created', field: contentString, value: `${newValue}`, content: contentKey.toUpperCase() }
      } else {
        return null
      }
    }
    if (audit.name === 'Remove Asset Type Meta Field') {
      const contentString = 'Meta Fields'
      return { action: 'Removed', field: contentString, value: `${newValue.replace('Removed fields', '')}`, content: contentKey.toUpperCase() }
    }
    if (audit.name === 'Meta Fields' || audit.name === 'Fields') {
      let auditAction = 'ADD'
      let newValue = audit.value
      if (!newValue || newValue === '[]' || newValue === 'null' || newValue.length === 0) {
        auditAction = 'REMOVE'
      } else if (audit.oldValue) {
        auditAction = 'UPDATE'
      }

      if (contentType === 'APP_WIDGET' && !audit.fieldName) {
        let content = contentName
        return { action: audit.value, field: '', value: '', content }
      }

      if (auditAction === 'REMOVE') {
        return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: audit.oldValue, content: contentName || props.contentId }
      } else {
        return { action: audit.value, field: '', value: '', content: '' }
      }
    }
  }

  if (contentName === 'TAG-TRANSFER') {
    oldValue = getTagsArray(oldValue)
    newValue = getTagsArray(newValue)
    return { action: 'Transferred', field: 'Assets', value: `of ${oldValue} to ${newValue}` }
  }
  if (contentName === 'AUTHOR-TRANSFER') {
    oldValue = replaceIdwithItems([oldValue], props)
    newValue = replaceIdwithItems([newValue], props)
    return { action: 'Transferred', field: 'Assets', value: `of ${oldValue} to ${newValue}` }
  }
  if (contentName === 'ASSET_CATEGORY-TRANSFER') {
    oldValue = replaceIdwithItems([oldValue], props)
    newValue = replaceIdwithItems([newValue], props)
    return { action: 'Transferred', field: 'Assets', value: `of ${oldValue} to ${newValue}` }
  }
  if (contentType === 'BUCKET_GROUP_TYPE' || contentType === 'DISPLAY_TYPE' || contentType === 'PUBLISH_GROUP' || contentType === 'ASSET_CATEGORY' || contentType === 'CONFIG_GROUP' || contentType === 'STREAM_LEVEL' || (contentType === 'AUTHOR' && audit.name === 'name')) {
    iskey = true
  }
  if ((contentType === 'BUCKET_GROUP_TYPE' || contentType === 'ASSET_TYPE') && audit.isPosChange) {
    field = contentId
    field = replaceIdwithItems([field], props)
    return { action: 'Changed postion of', field, value: `from ${parseInt(audit.oldValue)} to ${parseInt(audit.value)}` }
  }
  if (contentType === 'OPTA_SYNC' && audit.name === 'lastRunTime') {
    newValue = moment(new Date(newValue)).format('DD MMM, YYYY,HH:mm')
  }

  if (contentType === 'ASSET_TYPE' || contentType === 'AUTHOR' || contentType === 'COMPETITION' || contentType === 'SEASON' || contentType === 'TEAM' || contentType === 'PLAYER' || contentType === 'ASPECT_RATIO' || contentType === 'RESOLUTION' || contentType === 'MEDIA_CATEGORY' || contentType === 'IMAGE_TYPE') {
    contentKey = replaceIdwithItems([contentKey], props)
  }

  if (contentType === 'COMPETITION' && (audit.name === 'logo' || audit.name === 'defaultTeamImage')) {
    newValue = replaceIdwithItems([newValue], props)
    let content = replaceOptaIdwithItems([contentName], props)
    return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue[0] !== null ? `to ${newValue}` : 'to null'}`, content: content }
  }
  if (contentType === 'TEAM' && (audit.name === 'customHomeVenue' || audit.name === 'logo' || audit.name === 'awayJersey' || audit.name === 'homeJersey')) {
    newValue = replaceIdwithItems([newValue], props)
    let content = replaceOptaIdwithItems([contentName], props)
    return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue !== null ? `to ${newValue}` : ''}`, content: content }
  }
  if (contentType === 'PLAYER' && (audit.name === 'shirtNumber' || audit.name === 'awayImage' || audit.name === 'homeImage')) {
    newValue = replaceIdwithItems([newValue], props)
    const splitContent = contentName.split(',')
    const seasonContent = replaceOptaIdwithItems([splitContent[0]], props)
    const teamContent = replaceOptaIdwithItems([splitContent[1]], props)
    return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue !== null ? `to ${newValue}` : ''}`, content: `${teamContent} of ${seasonContent}` }
  }
  if ((contentType === 'ASSET_TYPE' || contentType === 'AUTHOR' || contentType === 'PERSON') && (audit.name === 'icon' || audit.name === 'draftIcon' || audit.name === 'media' || audit.name === 'image')) {
    newValue = replaceIdwithItems([newValue], props)
  }

  if (contentType === 'MATCH_TEMPLATE' && (audit.name === 'category')) {
    newValue = replaceIdwithItems([newValue], props)
    newValue = newValue[0]
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${newValue}` }
  }

  if (contentType === 'AUTHOR' && audit.name === 'isDisabled') {
    return { action: `Marked ${contentKey} as`, field: audit.value === 'true' ? 'inactive' : 'active' }
  }
  if (audit.name === 'isActive') {
    // return handleBooleanChanges(field, audit)
    return { action: `Marked ${contentKey} as`, field: audit.value === 'false' ? 'inactive' : 'active' }
  }
  if (contentType === 'MATCH_TEMPLATE' && audit.name === 'isFree') {
    return { action: `Marked asset as`, field: audit.value === 'false' ? 'Premium' : 'Free' }
  }
  if (contentType === 'ASSET_TYPE' && (audit.name === 'matchSection' || audit.name === 'authorSection' || audit.name === 'programSection' || audit.name === 'contentSection' || audit.name === 'videoSection' || audit.name === 'isMatchRelated' || audit.name === 'isLveToVodSupported')) {
    return { action: audit.value === 'true' ? 'Enabled ' : 'Disabled ', field, value: `of ${contentKey}` }
  }
  if (contentType === 'MATCH_TEMPLATE' && (audit.name === 'tags')) {
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getTemplateArrayChanges(newValue, oldValue, props, 'template-tags')
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}` }
  }
  if (contentType === 'MATCH_TEMPLATE' && (audit.name === 'publishEndDate')) {
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getTemplateArrayChanges(newValue, oldValue, props, 'publish-end-date')
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}` }
  }
  if (contentType === 'MATCH_TEMPLATE') {
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}` }
  }
  if (((contentType === 'STREAM_LEVEL' || contentType === 'CONFIG_GROUP') && (audit.name === 'fields')) || (contentType === 'ASSET_TYPE' && audit.name === 'metaFields')) {
    let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    let arrayChanges = getTemplateArrayChangesFields(newValue, oldValue, props)

    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }

  if (contentType === 'CONTENT_PLACE_HOLDER') {
    if (audit.name === 'Content Meta Field') {
      if (newValue !== null) {
        const contentString = 'Meta Fields'
        return { action: actionMap[action], field: contentString, value: ` ${audit.displayName} ${audit.fieldName} ${oldValue ? 'from ' + oldValue : ''}   to ${newValue}`, content: contentKey.toUpperCase() }
      } else {
        return null
      }
    }
    if (audit.name === 'Create Content Meta Field') {
      if (newValue !== null) {
        const contentString = 'Meta Fields'
        return { action: 'Created', field: contentString, value: `${newValue}`, content: contentKey.toUpperCase() }
      } else {
        return null
      }
    }
    if (audit.name === 'Remove Content Meta Field') {
      const contentString = 'Meta Fields'
      return { action: 'Removed', field: contentString, value: `${oldValue}`, content: contentKey.toUpperCase() }
    }
  }

  if (contentType === 'DRM_PLAYBACK_SETTINGS' || contentType === 'S3_CONFIG_SETTINGS' || contentType === 'VWM_CONFIG_SETTINGS' || contentType === 'EPG_CONFIG') {
    return { action: actionMap[action], field, value: `${newValue !== null ? `to ${newValue}` : ''}` }
  }
  if ((contentType === 'COMPETITION' || contentType === 'SEASON' || contentType === 'TEAM' || contentType === 'PLAYER') && audit.name === 'tags') {
    contentId = replaceIdwithItems([contentId], props)
    oldValue = getFormattedTagsArray(audit.oldValue || '')
    newValue = getFormattedTagsArray(audit.value || '')
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getArrayChanges(newValue, oldValue, props)
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue} ${contentId}` }
  }
  if (contentType === 'SEASON' && (audit.name === 'customStartDate' || audit.name === 'customEndDate')) {
    newValue = moment(new Date(newValue)).format('DD MMM, YYYY')
  }
  if (contentType === 'ASPECT_RATIO' && audit.name === 'resolutions') {
    contentId = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getArrayChanges(newValue, oldValue, props)
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue} ${contentId}` }
  }
  if (contentType === 'CONFIG_GROUP' && audit.name === 'streams') {
    contentId = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getArrayChanges(newValue, oldValue, props)
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue} ${contentId}` }
  }

  if ((field === 'media' || field === 'image') && (!newValue || !newValue.toString())) {
    return { action: actionMap['REMOVE'], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}` }
  }

  if (contentType === 'MEDIA_CATEGORY') {
    return { action: actionMap[action], field, value: `${audit.name === 'label' ? `from ${oldValue}` : `of ${contentKey} from ${oldValue}`}  ${newValue && newValue ? `to ${newValue}` : ''}` }
  }

  if (contentType === 'IMAGE_TYPE') {
    if (field === 'assetType') {
      // if ((newValue || '').indexOf('cropPosition') > -1 || (oldValue || '').indexOf('cropPosition') > -1) {
      if (!newValue) {
        return { action: 'DISABLED', field: contentKey, value: `image type for ${audit.displayName}` }
      } else {
        const result = getimageTypeAssetDetails(oldValue, newValue)
        return { action: !oldValue ? 'ENABLED' : actionMap[action], field: contentKey, value: `image type for ${audit.displayName} ${result}` }
      }
    } else if (field === 'aspectRatio') {
      if (!newValue) {
        return { action: 'REMOVED', field, value: `${audit.displayName} from ${contentKey}` }
      } else if (!oldValue) {
        return { action: 'ADDED', field, value: `${audit.displayName} to ${contentKey}` }
      } else {
        return { action: 'UPDATED', field, value: `${audit.displayName} of ${contentKey}` }
      }
    } else if (field === 'position') {
      return { action: actionMap[action], field, value: `${`of ${contentKey} from ${oldValue}`} ${newValue && newValue ? `to ${newValue}` : ''}` }
    }
  }
  if (contentType === 'SPORT') {
    contentId = replaceIdwithItems([contentId], props)
    if (action === 'UPDATE') {
      if (audit.name === 'searchField') {
        return {}
      } else if (!oldValue || oldValue === '[]' || oldValue === 'null') {
        action = 'ADD'
        return { action: actionMap[action], field: audit.value, value: `to ${audit.name} on sport ${contentId}` }
      } else if (!newValue || newValue === '[]' || newValue === 'null') {
        action = 'REMOVE'
        return { action: actionMap[action], field: audit.oldValue, value: `from ${audit.name} on sport ${contentId}` }
      } else {
        return { action: actionMap[action], field: audit.name, value: `from ${audit.oldValue} to ${audit.value} on sport ${contentId}` }
      }
    }
  }
  if (contentType === 'SPORT_DATA') {
    contentName = replaceIdwithItems([contentName], props)
    if (action === 'UPDATE' && audit.name === 'dataType') {
      return { action: `Uploaded CSV for`, field: audit.value, value: `${audit.name} to sport ${contentName}` }
    }
    if (action === 'UPDATE' && audit.name === 'type') {
      return { action: `Finished processing`, field: audit.value, value: `datatype to sport ${contentName}` }
    }
    if (action === 'UPDATE' && audit.name === 'fileName') {
      return { action: `file name: `, field: audit.value, value: `` }
    }
    if (action === 'UPDATE' && audit.name === 'status') {
      return { action: `status changed to`, field: audit.value, value: `of sport ${contentName}` }
    }
  }

  if (contentType === 'GENRE' || contentType === 'PRODUCTION_STUDIO' || contentType === 'ROLE' || contentType === 'PERSON' || contentType === 'RATING' || contentType === 'COUNTRY' || contentType === 'LANGUAGE') {
    if (audit.name === 'image') {
      return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue && newValue ? `to ${newValue}` : ''}` }
    }

    if (contentType === 'RATING' && action === 'CREATE') {
      let string = ''
      let returnString = ''
      let array = [];
      (newValue.split(',')).map((item, index) => {
        if (item.includes('[') && item.includes(']')) {
          string += item
          returnString = string
          string = ''
          array.push(returnString)
        } else if (item.includes(']')) {
          string += ',' + item
          returnString = string
          string = ''
          array.push(returnString)
        } else if (item.includes('[')) {
          string += item
        } else if (index > 0) {
          string += ',' + item
        }
      })
      const rating = newValue.split(',')
      const ratingCreateAudit = `${rating[0]}  with rating ${array[0]} and subRating ${array[1]} `
      return { action: actionMap[action], field, value: ratingCreateAudit }
    }

    if (audit.name === 'rating' || audit.name === 'subRatings') {
      return { action: actionMap[action], field, value: `of ${oldValue} to ${newValue} for schema ${contentId}` }
    }
    if (audit.name === 'scheme') {
      field = 'schema'
    }

    return { action: actionMap[action], field, value: `of ${oldValue} to ${newValue}` }
  }

  if (contentType === 'PARTNER_CONFIG' || contentType === 'OFFER_CONFIG') {
    const { changedValue, action } = getConfigArrayChanges(newValue, oldValue)
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue} ${contentId}` }
  }

  if (contentType === 'META_FIELD_CONFIG') {
    if (contentName && audit.name === 'default') {
      return { action: actionMap[action], field, value: `from ${oldValue} to ${newValue} in  ${contentName}` }
    }
    return { action: actionMap[action], field, value: `from ${oldValue} to ${newValue}` }
  }

  if (action === 'UPDATE' && contentType === 'TAG_TYPE' && audit) {
    contentKey = contentName || contentId
    const arrayChanges = getArrayChanges(audit.value, audit.oldValue, props)
    let changedValue = arrayChanges ? arrayChanges.changedValue : ''
    changedValue = (changedValue || '').replace('of', 'from')
    const curAction = arrayChanges ? arrayChanges.action : action
    // if (changedValue === updatedObj[0].value && curAction === 'REMOVE') {
    //   return { action: actionMap[curAction], field:'', value: oldValue }
    // }
    if (field === 'position') {
      return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`} ${oldValue && oldValue ? `from ${oldValue} ` : ''} ${newValue && newValue ? `to ${newValue}` : ''}` }
    }
    if (audit.name === 'enabledAssetTypes') {
      return { action: `${actionMap[action]} `, field: 'enabledAssetTypes ', value: `${actionMap[curAction]} ${changedValue} TagType ${contentKey}` }
    }
    if (audit.name === 'name') {
      return { action: actionMap[action], field, value: `from ${oldValue}  ${newValue && newValue ? `to ${newValue}` : ''}` }
    }
    return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue && newValue ? `to ${newValue}` : ''}` }
  }

  if (action === 'CREATE' && contentType === 'TAG_TYPE' && audit) {
    // debugger
    let arrayChanges = getArrayChanges(audit.value, audit.oldValue, props)
    let changedValue = arrayChanges ? arrayChanges.changedValue : ''
    changedValue = (changedValue || '').replace('of', 'from')
    const curAction = arrayChanges ? arrayChanges.action : action
    // if (changedValue === updatedObj[0].value && curAction === 'REMOVE') {
    //   return { action: actionMap[curAction], field:'', value: oldValue }
    // }
    if (audit.name === 'enabledAssetTypes') {
      return { action: `${actionMap[action]} `, field: 'enabledAssetTypes ', value: `${actionMap[curAction]} ${changedValue} ` }
    }
    return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : ` `}  ${newValue && newValue ? `to ${newValue}` : ''}` }
  }

  if (contentType === 'MATCH_CONFIG') {
    const { changedValue, action } = getConfigArrayChanges(newValue, oldValue)
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue} ${contentId}` }
  }

  if (contentType === 'MODULE_CONFIG') {
    const { changedValue, action } = getBucketSettingArrayChanges(newValue, oldValue)
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue} ${contentId}` }
  }

  if (action === 'UPDATE' && props.selectedDataId === 'SECTION_MAPPING') {
    let dataAction = contentName.split('::')
    return { action: 'Updated bucket of Section', field: `${dataAction[0] || 'APPLE News Section'}`, value: `from ${oldValue} to ${newValue && newValue ? newValue : ''}` }
  }

  if (contentType === 'META_SECTION' && action === 'UPDATE') {
    return { action: 'Updated', field: `Title`, value: `from ${oldValue} to ${newValue && newValue ? newValue : ''}` }
  }

  return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue && newValue ? `to ${newValue}` : ''}` }
  // return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${mediaName}` }
}

const replaceIdwithItems = (ids, props) => {
  const { displayTypeList, bucketGroupTypeList, publishList, optaSyncList, mediaItemDetails, assetTypeList, configGroupList, channelStreamList,
    authorDetails, categoryDetails, seasonDetails, competitionDetails, aspectRatioList, resolutionItemDetails, teamDetails, playerDetails, transferAuthor, listMediaCategories, listImageTypes, listSports } = props
  const modifiedIds = ids.map(id => {
    const displayType = (displayTypeList || []).find(item => item.id === id)
    if (displayType) {
      return `${displayType.name}`
    }
    const bucketGroupType = (bucketGroupTypeList || []).find(item => item.id === id)
    if (bucketGroupType) {
      return `${bucketGroupType.name}`
    }
    const publishType = (publishList || []).find(item => item.id === id)
    if (publishType) {
      return `${publishType.name}`
    }
    // const categoryType = (categoryList || []).find(item => item.id === id)
    // if (categoryType) {
    //   return `${categoryType.name}`
    // }
    const optaSync = (optaSyncList && optaSyncList.id ? optaSyncList : {})
    if (optaSync && optaSync.id === id) {
      return `${optaSync.title}`
    }
    const media = (mediaItemDetails || []).find(item => item.id === id)
    if (media) {
      return `${media.name} (${media.type ? media.type.toLowerCase() : ''})`
    }
    const author = (authorDetails || []).find(item => item.id === id)
    if (author) {
      return `${author.name}`
    }
    const authorTransfer = (transferAuthor && transferAuthor.id ? transferAuthor : {})
    if (authorTransfer && authorTransfer.id === id) {
      return `${authorTransfer.name}`
    }
    const assetType = (assetTypeList || []).find(item => item.id === id)
    if (assetType) {
      return `${assetType.title}`
    }
    const mediaCategory = (listMediaCategories || []).find(item => item.id === id)
    if (mediaCategory) {
      return `${mediaCategory.label}`
    }
    const imageTypes = (listImageTypes || []).find(item => item.id === id)
    if (imageTypes) {
      return `${imageTypes.name}`
    }
    const category = (categoryDetails || []).find(item => item.id === id)
    if (category) {
      return `${category.name}`
    }
    const configGroup = (configGroupList || []).find(item => item.id === id)
    if (configGroup) {
      return `${configGroup.name}`
    }
    const channelStream = (channelStreamList || []).find(item => item.id === id)
    if (channelStream) {
      return `${channelStream.name}`
    }
    const competition = (competitionDetails && competitionDetails.id ? competitionDetails : {})

    if (competition && competition.id === id) {
      return `${competition.title}`
    }

    if (competition && competition.defaultTeamImage && competition.defaultTeamImage.id === id) {
      return `${competition.defaultTeamImage.name}`
    }

    if (competition && competition.logo && competition.logo.id === id) {
      return `${competition.logo.name}`
    }

    const season = (seasonDetails && seasonDetails.id ? seasonDetails : {})
    if (season && season.id === id) {
      return `${season.title}`
    }
    const player = (playerDetails && playerDetails.id ? playerDetails : {})
    if (player && player.id === id) {
      return `${player.firstName}`
    }
    const team = (teamDetails && teamDetails.id ? teamDetails : {})
    if (team && team.id === id) {
      return `${team.title}`
    }
    const aspectRatio = (aspectRatioList || []).find(item => item.aspectRatio === id)
    if (aspectRatio) {
      return `${aspectRatio.title}`
    }
    const resolution = (resolutionItemDetails || []).find(item => item.id === id)
    if (resolution) {
      return `${resolution.width} X ${resolution.height}`
    }
    const Sport = (listSports || []).find(item => item.id === id)
    if (Sport) {
      return `${Sport.title}`
    }

    return id
  })
  return modifiedIds
}

const replaceOptaIdwithItems = (ids, props) => {
  const { seasonDetails, teamDetails } = props
  const modifiedIds = ids.map(id => {
    const season = (seasonDetails && seasonDetails.id ? seasonDetails : {})
    if (season && season.id === id) {
      return `${season.title}(${season.competition.title})`
    }
    const team = (teamDetails && teamDetails.id ? teamDetails : {})
    if (team && team.id === id) {
      return `${team.title}(team)`
    }

    return id
  })
  return modifiedIds
}

const getTagsArray = tagsString => {
  const tagTypes = ['vimond', 'sport', 'team', 'season', 'competition', 'bucket', 'product']
  const manualTags = ['asset_manager', 'channel_manager', 'content_bank', 'match_manager', 'manual']
  _.forEach(tagTypes, (item) => {
    const n = tagsString.lastIndexOf(item)
    if (n > -1) {
      tagsString = `${tagsString.substring(0, n)}(${item})`
    }
  })
  _.forEach(manualTags, (item) => {
    const n = tagsString.lastIndexOf(item)
    if (n > -1) {
      tagsString = `${tagsString.substring(0, n)}(manual)`
    }
  })
  return `${tagsString}`
}

const getFormattedTagsArray = tagsString => {
  const tagTypes = ['vimond', 'sport', 'team', 'season', 'competition', 'bucket', 'product']
  const manualTags = ['asset_manager', 'channel_manager', 'content_bank', 'match_manager', 'data_manager']
  let tags = tagsString.slice(1, tagsString.length - 1).split(',')
  tags = tags.map((tag) => {
    _.forEach(tagTypes, (item) => {
      const n = tag.lastIndexOf(item)
      if (n > -1) {
        tag = `${tag.substring(0, n)}(${item}) `
      }
    })
    _.forEach(manualTags, (item) => {
      const n = tag.lastIndexOf(item)
      if (n > -1) {
        tag = `${tag.substring(0, n)}(manual)`
      }
    })
    return tag
  }).join(',')
  return `[${tags}]`
}

const getSectionName = (id, props) => {
  const sl = props.metaSectionLists.find(item => item.id === id)
  return sl ? sl.title : 'empty'
}

const getUpdatedArray = (add, remove, type, props) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(',')
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(',')
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] === removedArray[i][0]) {
            if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2])) {
              updatedString = `${addedArray[j][0]} with${addedArray[j][1] === 'true' ? ' mandatory ' : ' '} field of type ${addedArray[j][2]}`
            } else if (addedArray[j][1] !== removedArray[i][1]) {
              updatedString = `${addedArray[j][0]} to ${addedArray[j][1] === 'true' ? ' mandatory ' : ' non mandatory'} field`
            } else if (addedArray[j][3] !== removedArray[i][3]) {
              updatedString = `section from ${getSectionName(removedArray[j][3], props)} to ${getSectionName(addedArray[j][3], props)}`
            } else {
              updatedString = `${addedArray[j][0]} to type ${addedArray[j][2]}`
            }
            removeIndex.push(i)
            addedIndex.push(j)
          }
          updatedArray.push(updatedString)
        }
      }
    }
    if (type === 'publish-end-date') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][4] === removedArray[i][4]) {
            let duration = `${addedArray[j][2] === '1' ? publishDuration[addedArray[j][3]] : publishDurations[addedArray[j][3]]}`
            if (addedArray[j][1] === 'SPECIFIC') {
              updatedString = `${addedArray[j][4]} to  Specific Date ${addedArray[j][2] ? moment(addedArray[j][2]) : addedArray[j][2]}`
            } else {
              updatedString = `${addedArray[j][4]} to ${addedArray[j][1] === 'ADD' ? 'after' : 'before'} ${addedArray[j][2]} ${duration} from ${addedArray[j][0]}`
            }

            removeIndex.push(i)
            addedIndex.push(j)
          }
          updatedArray.push(updatedString)
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  return { updatedArray, addedArray, removedArray }
}

const createArrayFromString = (inputString, n) => {
  if (inputString) {
    const stringWithoutBrackets = inputString.slice(1, -1)
    const stringWithoutInnerCommas = stringWithoutBrackets.replace(/\[.*?\]/g, match => match.replace(/,/g, '|'))
    const arrayOfElements = stringWithoutInnerCommas.split(',')
    const finalArray = arrayOfElements.map(element => element.replace(/\|/g, ','))
    return _.chunk(finalArray, n)
  } return null
}

const compareArrays = (a, b) => {
  if (!a || !b) return null
  const differences = []
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    const arrayA = a[i]
    const arrayB = b[i]

    if (!arrayB) {
      differences.push({ index: i, element: arrayA, status: 'Only in A' })
    } else if (!arrayA) {
      differences.push({ index: i, element: arrayB, status: 'Only in B' })
    } else if (!arraysEqual(arrayA, arrayB)) {
      differences.push({ index: i, elementA: arrayA, elementB: arrayB, status: 'Different' })
    }
  }
  return differences
}

const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

const getTemplateArrayChangesFields = (newValue, oldValue, props) => {
  let changedValue = ''; let action
  const fieldNewArray = createArrayFromString(newValue, 5)
  const fieldOldArray = createArrayFromString(oldValue, 5)
  if (!_.isEmpty(fieldNewArray) && !_.isEmpty(fieldOldArray) && !_.isEmpty(fieldNewArray[0]) && fieldNewArray[0].length === 1) {
    action = 'REMOVE'
    fieldOldArray.forEach(item => {
      changedValue += `${item[0]} with Type of ${item[2]} ,`
    }
    )
  } else if (!_.isEmpty(fieldOldArray) && !_.isEmpty(fieldNewArray) && !_.isEmpty(fieldOldArray[0]) && fieldOldArray[0].length === 1) {
    action = 'ADD'
    fieldNewArray.forEach(item => {
      changedValue += `${item[0]} with Type of ${item[2]} ,`
    }
    )
  } else {
    const differences = compareArrays(fieldNewArray, fieldOldArray)
    if (differences && differences.length) {
      differences.forEach((item) => {
        if (item.status === 'Only in A') {
          action = 'ADD'
          changedValue += `${item.element[0]} with Type of ${item.element[2]} ,`
        } else if (item.status === 'Only in B') {
          action = 'REMOVE'
          changedValue += `${item.element[0]} with Type of ${item.element[2]} ,`
        } else if (item.status === 'Different') {
          action = 'UPDATE'
          const values = ['Title', 'Required', 'Type', 'Section', 'Default Values']
          const innerArrayDifferences = compareArrays(item.elementA, item.elementB)
          if (innerArrayDifferences && innerArrayDifferences.length) {
            innerArrayDifferences.forEach((inneritem) => {
              if (inneritem.index === 3) {
                changedValue += `${item.elementA[0]} ${values[inneritem.index]} from ${getSectionName(inneritem.elementB, props)} to ${getSectionName(inneritem.elementA, props)}, `
              } else {
                changedValue += `${item.elementA[0]} ${values[inneritem.index]} from ${inneritem.elementB} to ${inneritem.elementA}, `
              }
            })
          }
        }
      })
    }
  }
  if (action === 'REMOVE') {
    changedValue = changedValue.split(' ')[0]
  }
  if (changedValue.endsWith(',')) {
    changedValue = changedValue.slice(0, -1)
  }

  if (changedValue.endsWith(', ')) {
    changedValue = changedValue.slice(0, -2)
  }

  console.log('changedValue', changedValue)
  return { changedValue, action }
}

const getTemplateArrayChanges = (newValue, oldValue, props, type) => {
  let changedValue; let action; let arrayItems = {}; let ele = []; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    let newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    oldArrayString = (oldArrayString || '').replace(/, /g, ',')
    newArrayString = (newArrayString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(',') : []
    const oldArray = oldArrayString ? oldArrayString.split(',') : []
    if (type === 'template-tags') {
      n = 3
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      addedArray = (addedArrayItem || []).map(item => {
        ele = item.split(',')
        return `${ele[0]}(${ele[2].toLowerCase()})`
      })
      removedArray = (removedArrayItem || []).map(item => {
        ele = item.split(',')
        return `${ele[0]}(${ele[2].toLowerCase()})`
      })
    }
    if (type === 'publish-end-date') {
      // debugger
      n = 5
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      arrayItems = getUpdatedArray(addedArrayItem, removedArrayItem, type, props)
      addedArray = (arrayItems.addedArray || []).map(item => {
        // ele = item.split(',')
        if (item[1] === 'SPECIFIC') {
          return `of ${item[4]} to Specific Date ${item[2] ? moment(item[2]) : item[2]}`
        } else {
          return `of ${item[4]} to ${item[1] === 'ADD' ? 'after' : 'before'} ${item[2]} ${item[3]} from ${item[0]}`
        }
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        // ele = item.split(',')
        if (item[1] === 'SPECIFIC') {
          return `of ${item[4]} to Specific Date ${item[2] ? moment(item[2]) : item[2]}`
        } else {
          return `${item[4]} to ${item[1] === 'ADD' ? 'after' : 'before'} ${item[2]} ${item[3]} from ${item[0]}`
        }
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }

    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action }
}

const getArrayChanges = (newValue, oldValue, props) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = replaceIdwithItems(_.difference(newArray, oldArray), props)
    const removedItems = replaceIdwithItems(_.difference(oldArray, newArray), props)
    if (newItems.length && removedItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')} from`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = `${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')} of`
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} to`
    }
  } else return
  return { changedValue, action }
}

const getFormattedAdminHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName, contentId } = details
  let descriptions = (updatedObj || []).map(item => getFormattedAdminDetails(_.cloneDeep(item), action, contentType, contentName, contentId, props))
  if ((action === 'DELETE' || action === 'CREATE') && contentType !== 'TAG' && contentType !== 'MATCH_TEMPLATE' && contentType !== 'TAG_TYPE') {
    const isValuePresent = (descriptions && descriptions.length)
    const ratingField = isValuePresent ? descriptions.find(item => item.value) : null
    let field = contentType === 'RATING' && action === 'CREATE' ? ratingField && ratingField.value : contentId
    field = replaceIdwithItems([field], props)
    descriptions = [{ action: `${actionMap[action]}`, field: `${field}` }]
  }
  if ((action === 'CREATE') && props.selectedDataId === 'SECTION_MAPPING') {
    let dataAction = contentName ? contentName.split('::') : []
    if (dataAction.length > 1) {
      let dataAction = contentName.split('::')
      let createMessage = `- Mapped Bucket ${dataAction[1]} to ${dataAction[0]} section`
      descriptions = [{ action: `Created Section`, field: `${dataAction[0] || 'APPLE News Section'}`, value: createMessage }]
    } else {
      descriptions = [{ action: `${actionMap[action]}`, field: `${contentName || 'APPLE News Section'}` }]
    }
  }
  if ((action === 'DELETE') && props.selectedDataId === 'SECTION_MAPPING') {
    let dataAction = contentName ? contentName.split('::') : []
    if (dataAction.length > 1) {
      let dataAction = contentName.split('::')
      let createMessage = `- Removed Bucket ${dataAction[1]} from ${dataAction[0]} section`
      descriptions = [{ action: `Updated Section`, field: `${dataAction[0] || 'APPLE News Section'}`, value: createMessage }]

      // return { action: actionMap[action], field, value: `of ${contentName} from ${oldValue} to ${newValue && newValue ? newValue : ''}` }
    } else {
      descriptions = [{ action: `${actionMap[action]}`, field: `${contentName || 'APPLE News Section'}` }]
    }
  }

  if ((action === 'DELETE' || action === 'CREATE') && contentType === 'TAG_TYPE') {
    const isValuePresent1 = (descriptions && descriptions.length)
    const newField = isValuePresent1 ? descriptions.filter(item => item.value) : null
    let fieldTagType = action === 'DELETE' ? (contentName || contentId) : ''
    // if (newField && newField.length) {
    //   (newField || []).map(item => {
    //     if (item.value) {
    //       if (fieldTagType !== '') {
    //         fieldTagType += ' also ' + `${item.field === 'isMediaEnabled' ? 'MediaEnabled set' : ''}` + item.value + `${item.field === 'enabledAssetTypes ' ? 'enabledAssetTypes' : ''}`
    //       } else {
    //         fieldTagType = `${contentId} -` + `${item.field === 'isMediaEnabled' ? 'MediaEnabled set' : ''}` + item.value + `${item.field === 'enabledAssetTypes ' ? 'enabledAssetTypes' : ''}`
    //       }
    //     }
    //   })
    // }
    let field1 = ''
    let field2 = ''
    let field3 = ''
    let content1 = ''
    let content2 = ''

    if (newField && newField.length) {
      (newField || []).map(item => {
        // debugger
        if (item.value) {
          if (field1 !== '') {
            field2 = ' also set'
            field3 = item.value
            if (item.field === 'isMediaEnabled') {
              content2 = 'Media'
            } else if (item.field === 'enabledAssetTypes ') {
              content2 = 'enabledAssetTypes'
            }
          } else {
            field1 = '- ' + `${item.field === 'isMediaEnabled' ? 'set ' : ''}` + item.value
            if (item.field === 'isMediaEnabled') {
              content1 = 'Media'
            } else if (item.field === 'enabledAssetTypes ') {
              content1 = 'enabledAssetTypes'
            }
          }
        }
      })
    }

    if (action === 'CREATE') {
      descriptions = [{ action: `${actionMap[action]}`, field: `${contentId}`, field1: `${field1}`, field2: `${field2}`, field3: `${field3}`, content1: `${content1}`, content2: `${content2}` }]
    }

    if (action === 'DELETE') {
      fieldTagType = replaceIdwithItems([fieldTagType], props)
      descriptions = [{ action: `${actionMap[action]}`, field: `${fieldTagType}` }]
    }
  }
  if ((action === 'DELETE' || action === 'CREATE') && (contentType === 'IMAGE_TYPE' || contentType === 'MEDIA_CATEGORY')) {
    let field = contentName || contentId
    field = replaceIdwithItems([field], props)
    descriptions = [{ action: `${actionMap[action]}`, field: `${field}` }]
  }
  if ((action === 'DELETE' || action === 'CREATE') && contentType === 'TAG') {
    let field = contentId
    field = getTagsArray(field)
    descriptions = [{ action: `${actionMap[action]}`, field: `${field}` }]
  }
  if ((action === 'DELETE' || action === 'CREATE' || action === 'UPDATE') && contentType === 'BUCKET_GROUP_TYPE' && props.project === 'hyperion') {
    let keyField = contentId
    keyField = replaceIdwithItems([keyField], props)
    const tempIndex = keyField[0].indexOf('hyperion' || 'projectx')
    if (tempIndex !== -1) { keyField = keyField[0].substring(8) }
    descriptions = [{ action: `${actionMap[action]}`, field: `${keyField}` }]
  }
  if ((action === 'DELETE' || action === 'CREATE') && contentType === 'MATCH_TEMPLATE') {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (action === 'UPDATE' && contentType === 'CONFIG' && contentId === 'IP_List' && updatedObj && updatedObj.length) {
    const arrayChanges = getArrayChanges(updatedObj[0].value, updatedObj[0].oldValue, props)

    let changedValue = arrayChanges ? arrayChanges.changedValue : ''
    changedValue = (changedValue || '').replace('of', 'from')
    const curAction = arrayChanges ? arrayChanges.action : action
    // if (changedValue === updatedObj[0].value && curAction === 'REMOVE') {
    //   return { action: actionMap[curAction], field:'', value: oldValue }
    // }
    descriptions = [{ action: `${actionMap[curAction]} ${changedValue}`, field: 'IP List', value: `` }]
  } else if (action === 'UPDATE' && contentType === 'CONFIG' && contentId !== 'user-login-config' && updatedObj && updatedObj.length) {
    descriptions = [{ action: `Marked Allow user to disable MFA as`, field: `${updatedObj[0].value === 'true' ? 'active' : 'inactive'}` }]
  }

  if (action === 'UPDATE' && contentType === 'CONFIG' && contentId === 'user-login-config' && updatedObj && updatedObj.length) {
    const arrayChanges = getAdminAuthSettingArrayChanges(updatedObj[0].value, updatedObj[0].oldValue, contentId)
    let changedValue = arrayChanges ? arrayChanges.changedValue : ''
    descriptions = [{ action: `${changedValue}`, field: 'Auth Config Setting', value: `` }]
  }

  if (action === 'CREATE' && contentType === 'TAG_TYPE') {
    return descriptions.map((description, i) => (
      !_.isEmpty(description) && <p className='description admin' key={i}>
        {description.action}
        <span>{description.field}</span>
        {description.field1}
        <span>{description.content1}</span>
        {description.field2 ? description.field2 : null}
        {description.field3 ? description.field3 : null}
        <span>{description.content2 ? description.content2 : null}</span>
      </p>
    ))
  } else if (contentType === 'COMPETITION') {
    return descriptions.map((description, i) => (
      !_.isEmpty(description) && <p className='description admin' key={i}>
        {description.action}
        <span>{description.field}</span>
        {description.value}
        {description.content ? <span>{description.content}</span> : null}
      </p>
    ))
  } else {
    return descriptions.map((description, i) => (
      !_.isEmpty(description) && <p className='description admin' key={i}>
        {description.action}
        <span>{description.field}</span>
        {description.value}
        {description.content ? ' in' : null}
        {description.content ? <span>{description.content}</span> : null}
      </p>
    ))
  }
}

const getConfigArrayChanges = (newValue, oldValue) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = _.difference(newArray, oldArray)
    const removedItems = _.difference(oldArray, newArray)
    if (newItems.length && removedItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ')} and also Removed ${removedItems.join(', ')} from`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = `${removedItems.join(', ')} from`
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ')} to`
    }
  } else return
  return { changedValue, action }
}

const getBucketSettingArrayChanges = (newValue, oldValue) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = _.difference(newArray, oldArray)
    const removedItems = _.difference(oldArray, newArray)
    if (newItems.length && removedItems.length) {
      action = 'UPDATE'
      changedValue = `${newItems.join(', ')} from ${removedItems.join(', ')} of`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = `${removedItems.join(', ')} from`
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ')} to`
    }
  } else return
  return { changedValue, action }
}

const getAdminAuthSettingArrayChanges = (newValue, oldValue) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = _.difference(newArray, oldArray)
    const removedItems = _.difference(oldArray, newArray)
    if (newItems.length && removedItems.length) {
      action = 'ADD'
      if (newItems.length > 1) {
        if (newItems[0] === '' && newItems[1] === '') {
          changedValue = `Updated Maximum Number of Failed Login Attempts ${removedItems[0]} to 0 and 
                           Updated Maximum duration of failed Attempts ${removedItems[1]} to 0 `
        } else if (newItems[0] === '') {
          changedValue = `Updated Maximum Number of Failed Login Attempts ${removedItems[0]} to 0 and 
                           Updated Maximum duration of failed Attempts ${removedItems[1]} to ${newItems[1]} `
        } else if (newItems[1] === '') {
          changedValue = `Updated Maximum Number of Failed Login Attempts ${removedItems[0]} to ${newItems[0]} and 
                           Updated Maximum duration of failed Attempts ${removedItems[1]} to 0 `
        } else {
          let removedItem1 = removedItems[0] === '' ? 0 : removedItems[0]
          let removedItem2 = removedItems[1] === '' ? 0 : removedItems[1]

          changedValue = `Updated Maximum Number of Failed Login Attempts ${removedItem1} to ${newItems[0]} and 
          Updated Maximum duration of failed Attempts ${removedItem2} to ${newItems[1]} `
        }
      } else {
        let value = parseInt(newItems.join(', '))
        let newItem = newItems[0] === '' ? 0 : newItems.join(', ')
        let removedItem = removedItems[0] === '' ? 0 : removedItems.join(', ')
        if (value >= 15) {
          changedValue = `Updated Maximum duration of failed Attempts ${removedItem} to ${newItem}`
        } else {
          changedValue = `Updated Maximum Number of Failed Login Attempts ${removedItem} to ${newItem}`
        }
      }
    } else if (removedItems.length) {
      action = 'REMOVE'
      let value = parseInt(removedItems.join(', '))
      if (value >= 15) {
        changedValue = `Updated Maximum duration of failed Attempts ${removedItems.join(', ')} to 0`
      } else {
        changedValue = `Updated Maximum Number of Failed Login Attempts ${removedItems.join(', ')} to 0`
      }
    } else if (newItems.length) {
      action = 'ADD'
      let value = parseInt(newItems.join(', '))
      if (value >= 15) {
        changedValue = `Updated Maximum duration of failed Attempts  0 to ${newItems.join(', ')} `
      } else {
        changedValue = `Updated Maximum Number of Failed Login Attempts 0 to ${newItems.join(', ')}`
      }
    }
  } else return
  return { changedValue, action }
}

const getimageTypeAssetDetails = (oldValue, newValue) => {
  const old = oldValue ? JSON.parse(oldValue) : ''
  const newVal = newValue ? JSON.parse(newValue) : ''
  if (!old) { return `with value Crop Position: ${newVal.cropPosition} and Minimum Count: ${newVal.minCount}` } else if (old && newVal) {
    if (old.minCount === newVal.minCount && old.cropPosition !== newVal.cropPosition) { return `Crop Position from ${old.cropPosition} to ${newVal.cropPosition}` } else if (old.cropPosition === newVal.cropPosition && old.minCount !== newVal.minCount) { return `Minimum Count from ${old.minCount} to ${newVal.minCount}` } else { return `from Crop Position: ${old.cropPosition} and Minimum Count: ${old.minCount} to Crop Position: ${newVal.cropPosition} and Minimum Count: ${newVal.minCount}` }
  }
}

class AdminHistoryListCell extends React.Component {
  render () {
    const { details } = this.props
    const { updatedBy, updatedAt } = details
    let historyList
    historyList = getFormattedAdminHistoryDetails(details, this.props)
    return (
      <div className='history-list-cell' >
        <div className='heading'>
          <p className='user-name'>{updatedBy}</p>
          <span className='date'>{moment(updatedAt).format('DD MMM YYYY HH:mm')}</span>
        </div>
        {historyList}
      </div>
    )
  }
}

AdminHistoryListCell.defaultProps = {
  details: {
    user: 'David Nelson',
    date: '28 Nov 2018, 12:00',
    description: 'David Nelson created an Article “Football mourns the tragic ending for a man who created a miracle and so much hope”'
  }
}

export default withApollo(compose(

  graphql(
    QueryBucketDisplayTypeList,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'DISPLAY_TYPE' && (details.action === 'DELETE' || details.action === 'CREATE')) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if (details.contentType === 'DISPLAY_TYPE' && (details.action === 'DELETE' || details.action === 'CREATE')) {
          let filter = null
          const variables = { filter, limit: 50, project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          displayTypeList: data && data.listDisplayType && data.listDisplayType.items ? [...data.listDisplayType.items] : undefined
        }
      }
    }
  ),
  graphql(
    QueryBucketGroupType,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'BUCKET_GROUP_TYPE' && (details.action === 'DELETE' || details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if (details.contentType === 'BUCKET_GROUP_TYPE' && (details.action === 'DELETE' || details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          let filter = null
          const variables = { filter, limit: 50, project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          bucketGroupTypeList: data && data.listBucketGroupType && data.listBucketGroupType.items ? [...data.listBucketGroupType.items] : undefined
        }
      }
    }
  ),
  graphql(
    QueryPublishGroupList,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'PUBLISH_GROUP' && (details.action === 'DELETE' || details.action === 'CREATE')) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if (details.contentType === 'PUBLISH_GROUP' && (details.action === 'DELETE' || details.action === 'CREATE')) {
          let filter = {}
          const variables = { filter, limit: 50, project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          publishList: data && data.listPublishingGroups && data.listPublishingGroups.items ? [...data.listPublishingGroups.items] : undefined
        }
      }
    }
  ),
  graphql(
    QueryListAssetType,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'ASSET_TYPE' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if (details.contentType === 'ASSET_TYPE' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const variables = { limit: 20, nextToken: null, filter: null, project }
          return {
            fetchPolicy: 'cache-first',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          assetTypeList: data && data.listAssetTypes && data.listAssetTypes.items ? [...data.listAssetTypes.items] : []
        }
      }
    }
  ),
  graphql(
    QueryListImageCategory,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'MEDIA_CATEGORY' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if (details.contentType === 'MEDIA_CATEGORY' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const variables = { limit: 20, nextToken: null, filter: null, project }
          return {
            fetchPolicy: 'cache-first',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          listMediaCategories: data && data.listMediaCategories && data.listMediaCategories.length ? [...data.listMediaCategories] : []
        }
      }
    }
  ),
  graphql(
    QueryGetImageType,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'IMAGE_TYPE' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if (details.contentType === 'IMAGE_TYPE' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const variables = { limit: 20, nextToken: null, filter: null, project }
          return {
            fetchPolicy: 'cache-first',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          listImageTypes: data && data.listImageTypes && data.listImageTypes.length ? [...data.listImageTypes] : []
        }
      }
    }
  ),
  graphql(
    QueryGetOptaSyncState,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'OPTA_SYNC' && (details.action === 'CREATE')) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'OPTA_SYNC' && (details.action === 'CREATE')) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          optaSyncList: props.data.getOptaSyncState
        }
      }
    }
  ),
  graphql(
    QueryAuthorDetails,
    {
      skip: ({ details }) => {
        let contentIds = []
        if (details.contentType === 'AUTHOR' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          contentIds.push(details.contentId)
        }
        if (details.contentType === 'AUTHOR' && details.contentName === 'AUTHOR-TRANSFER') {
          contentIds.push(details.updatedObj[0].oldValue)
        }
        return !contentIds.length
      },
      options: ({ details }) => {
        let contentIds = []
        if (details.contentType === 'AUTHOR' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          contentIds.push(details.contentId)
        }
        if (details.contentType === 'AUTHOR' && details.contentName === 'AUTHOR-TRANSFER') {
          contentIds.push(details.updatedObj[0].oldValue)
        }
        return {
          variables: { input: [...new Set(contentIds)] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          authorDetails: (props.data.batchGetAuthors || []).filter(item => item)
        }
      }
    }
  ),
  graphql(
    QueryCategoryDetails,
    {
      skip: ({ details }) => {
        let contentIds = []
        if ((details.contentType === 'MATCH_TEMPLATE') && (details.updatedObj && details.updatedObj.length)) {
          const newItems = (details.updatedObj || []).filter(item => item.name === 'category').map(updates => {
            return updates.value
          })
          const items = newItems
          contentIds = [...contentIds, ..._.cloneDeep(items.filter((item) => item))]
        }
        if (details.contentType === 'ASSET_CATEGORY' && (details.action === 'DELETE' || details.action === 'CREATE')) {
          contentIds.push(details.contentId)
        }
        if (details.contentType === 'ASSET_CATEGORY' && details.contentName === 'ASSET_CATEGORY-TRANSFER') {
          contentIds.push(details.updatedObj[0].oldValue)
          contentIds.push(details.updatedObj[0].value)
        }
        return !contentIds.length
      },
      options: ({ details }) => {
        let contentIds = []
        if ((details.contentType === 'MATCH_TEMPLATE') && (details.updatedObj && details.updatedObj.length)) {
          const newItems = (details.updatedObj || []).filter(item => item.name === 'category').map(updates => {
            return updates.value
          })
          const items = newItems
          contentIds = [...contentIds, ..._.cloneDeep(items.filter((item) => item))]
        }
        if (details.contentType === 'ASSET_CATEGORY' && (details.action === 'DELETE' || details.action === 'CREATE')) {
          contentIds.push(details.contentId)
        }
        if (details.contentType === 'ASSET_CATEGORY' && details.contentName === 'ASSET_CATEGORY-TRANSFER') {
          contentIds.push(details.updatedObj[0].oldValue)
          contentIds.push(details.updatedObj[0].value)
        }
        return {
          variables: { input: [...new Set(contentIds)] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          categoryDetails: (props.data.batchGetAssetCategories || []).filter(item => item)
        }
      }
    }
  ),
  graphql(
    QueryMediaDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if ((details.contentType === 'ASSET_TYPE' || details.contentType === 'AUTHOR' || details.contentType === 'TEAM' || details.contentType === 'PLAYER' || details.contentType === 'PERSON') && details.action === 'UPDATE') {
          const newItems = (details.updatedObj || []).filter(item => item.name === 'icon' || item.name === 'draftIcon' || item.name === 'media' || item.name === 'logo' || item.name === 'homeJersey' || item.name === 'homeImage' || item.name === 'awayImage' || item.name === 'awayJersey' || item.name === 'image').map(updates => {
            return (updates.value || '').replace('[', '').replace(']', '').split(', ')
          })
          const oldItems = (details.updatedObj || []).filter(item => item.name === 'icon' || item.name === 'draftIcon' || item.name === 'media' || item.name === 'logo' || item.name === 'homeJersey' || item.name === 'homeImage' || item.name === 'awayImage' || item.name === 'awayJersey' || item.name === 'image').map(updates => {
            return (updates.oldValue || '').replace('[', '').replace(']', '').split(', ')
          })
          const items = [...newItems, ...oldItems]
          contentIds = items.reduce((total, currentItem) => {
            return [...total, ...currentItem]
          }, []).filter(item => item.length)
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if ((details.contentType === 'ASSET_TYPE' || details.contentType === 'AUTHOR' || details.contentType === 'TEAM' || details.contentType === 'PLAYER' || details.contentType === 'PERSON') && details.action === 'UPDATE') {
          const newItems = (details.updatedObj || []).filter(item => item.name === 'icon' || item.name === 'draftIcon' || item.name === 'media' || item.name === 'logo' || item.name === 'homeJersey' || item.name === 'homeImage' || item.name === 'awayImage' || item.name === 'awayJersey' || item.name === 'image').map(updates => {
            return (updates.value || '').replace('[', '').replace(']', '').split(', ')
          })
          const oldItems = (details.updatedObj || []).filter(item => item.name === 'icon' || item.name === 'draftIcon' || item.name === 'media' || item.name === 'logo' || item.name === 'homeJersey' || item.name === 'homeImage' || item.name === 'awayImage' || item.name === 'awayJersey' || item.name === 'image').map(updates => {
            return (updates.oldValue || '').replace('[', '').replace(']', '').split(', ')
          })
          const items = [...newItems, ...oldItems]
          contentIds = items.reduce((total, currentItem) => {
            return [...total, ...currentItem]
          }, []).filter(item => item.length)
        }
        return {
          variables: { input: _.uniq(contentIds) }
        }
      },
      props: (props) => {
        return {
          mediaItemDetails: props.data.batchGetMedia
        }
      }
    }
  ),
  graphql(
    QueryGetCompetition,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'COMPETITION' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'COMPETITION' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'cache-first'
        }
      },
      props: (props) => {
        return {
          competitionDetails: props.data.getCompetition
        }
      }
    }
  ),
  graphql(
    QueryGetSeason,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'SEASON' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'TEAM' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentName]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'PLAYER' && (details.updatedObj && details.updatedObj.length && !_.isEmpty(details.contentName))) {
          const splitContent = details.contentName.split(',')
          const items = [splitContent[0]]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'SEASON' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'TEAM' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentName]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'PLAYER' && (details.updatedObj && details.updatedObj.length && !_.isEmpty(details.contentName))) {
          const splitContent = details.contentName.split(',')
          const items = [splitContent[0]]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'cache-first'
        }
      },
      props: (props) => {
        return {
          seasonDetails: props.data.getSeason
        }
      }
    }
  ),
  graphql(
    QueryAspectRatioDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if ((details.contentType === 'ASPECT_RATIO' || details.contentType === 'RESOLUTION') && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details, project }) => {
        if ((details.contentType === 'ASPECT_RATIO' || details.contentType === 'RESOLUTION') && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const variables = { limit: 100, project }
          return {
            fetchPolicy: 'cache-first',
            variables
          }
        }
      },
      props: ({ data }) => {
        return {
          aspectRatioList: data && data.listAspectRatios && data.listAspectRatios.items ? [...data.listAspectRatios.items] : undefined
        }
      }
    }
  ),
  graphql(
    QueryBatchGetResolutions,
    {
      skip: ({ details }) => {
        let contentIds
        if ((details.contentType === 'ASPECT_RATIO' && (details.updatedObj && details.updatedObj.length))) {
          let items = []
          let i = 0
          for (i = 0; i < details.updatedObj.length; i++) {
            if (details.updatedObj[i].name === 'resolutions') {
              const newItems = (details.updatedObj[i].value || '').replace('[', '').replace(']', '').split(', ')
              const oldItems = (details.updatedObj[i].oldValue || '').replace('[', '').replace(']', '').split(', ')
              items = _.uniq([...newItems, ...oldItems])
              contentIds = items.filter(item => item.length)
            }
          }
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if ((details.contentType === 'ASPECT_RATIO' && (details.updatedObj && details.updatedObj.length))) {
          let items = []
          let i = 0
          for (i = 0; i < details.updatedObj.length; i++) {
            if (details.updatedObj[i].name === 'resolutions') {
              const newItems = (details.updatedObj[i].value || '').replace('[', '').replace(']', '').split(', ')
              const oldItems = (details.updatedObj[i].oldValue || '').replace('[', '').replace(']', '').split(', ')
              items = _.uniq([...newItems, ...oldItems])
              contentIds = items.filter(item => item.length)
            }
          }
        }
        return {
          variables: { input: contentIds }
        }
      },
      props: (props) => {
        return {
          resolutionItemDetails: props.data.batchGetResolutions
        }
      }
    }
  ),
  graphql(
    QueryTeamDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'TEAM' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'PLAYER' && (details.updatedObj && details.updatedObj.length && !_.isEmpty(details.contentName))) {
          const splitContent = details.contentName.split(',')
          const items = [splitContent[1]]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }

        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'TEAM' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'PLAYER' && (details.updatedObj && details.updatedObj.length && !_.isEmpty(details.contentName))) {
          const splitContent = details.contentName.split(',')
          const items = [splitContent[1]]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'cache-first'
        }
      },
      props: (props) => {
        return {
          teamDetails: props.data.getTeamById
        }
      }
    }
  ),
  graphql(
    QueryPlayerDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'PLAYER' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'PLAYER' && (details.updatedObj && details.updatedObj.length)) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'cache-first'
        }
      },
      props: (props) => {
        return {
          playerDetails: props.data.getPlayerById
        }
      }
    }
  ),
  graphql(
    QueryListConfigGroups,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'CONFIG_GROUP' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        if (details.contentType === 'CONFIG_GROUP' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          return {
            fetchPolicy: 'cache-first',
            variables: { offset: 0 }
          }
        }
      },
      props: ({ data }) => {
        return {
          configGroupList: data && data.listConfigGroupSchemas && data.listConfigGroupSchemas.items ? [...data.listConfigGroupSchemas.items] : undefined
        }
      }
    }
  ),
  graphql(
    QueryListChannelStreams,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'STREAM_LEVEL' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          const items = [details.contentId]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        if (details.contentType === 'STREAM_LEVEL' && (details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))) {
          return {
            fetchPolicy: 'cache-first',
            variables: { offset: 0 }
          }
        }
      },
      props: ({ data }) => {
        return {
          channelStreamList: data && data.listStreamLevels && data.listStreamLevels.items ? [...data.listStreamLevels.items] : undefined
        }
      }
    }
  ),
  graphql(
    QueryListSports,
    {
      skip: ({ details }) => {
        let contentName
        if (details.contentType === 'SPORT_DATA' && (details.action === 'UPDATE')) {
          const items = [details.contentName]
          contentName = _.cloneDeep(items.filter((item) => item))
        }
        if (details.contentType === 'SPORT') {
          const items = [details.contentId]
          contentName = _.cloneDeep(items.filter((item) => item))
        }
        return !contentName || !contentName.length
      },
      options: (props) => {
        const nextToken = props.nextToken ? props.nextToken : null
        return {
          variables: { limit: 999, nextToken },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let listSports = data.listSports ? [...data.listSports.items] : []
        const nextToken = data.listSports ? data.listSports.nextToken : null
        const previousToken = data.listSports ? data.listSports.previousToken : null

        return {
          listSports,
          isLoading: data.loading,
          nextToken,
          previousToken,
          getMoreSports: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: nextToken
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newSportsList = fetchMoreResult.listSports ? [...fetchMoreResult.listSports.items] : []
                let newList = [...prev.listSports.items, ...newSportsList]
                newList = _.uniqBy(newList, 'id')
                if (_.isEqual(newList, prev.listSports.items)) { return prev }
                prev.listSports.items = newList
                prev.listSports.previousToken = prev.listSports.nextToken
                prev.listSports.nextToken = fetchMoreResult.listSports.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    ListMetaSections,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { filter: {
            project: {
              eq: project
            }
          } }
        }
      },
      props: (props) => {
        const { data } = props
        let metaSectionLists = data.listMetaSections ? data.listMetaSections.items : []
        return {
          metaSectionLists
        }
      }
    }
  )
)(withApollo(AdminHistoryListCell)))
