import React from 'react'
import PropTypes from 'prop-types'

const CopyLinkIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} fillRule='nonzero' d='M4.132 7.324L3.127 6.312a2.265 2.265 0 0 1 0-3.18 2.189 2.189 0 0 1 1.564-.653c.591 0 1.147.231 1.565.652l3.696 3.726c.765.772 1.892 2.224.843 3.282a1.24 1.24 0 1 0 1.758 1.747c1.786-1.8 1.456-4.459-.842-6.775L8.015 1.386A4.651 4.651 0 0 0 4.69 0a4.65 4.65 0 0 0-3.323 1.386A4.721 4.721 0 0 0 0 4.722C0 5.93.456 7.138 1.369 8.059L2.374 9.07a1.239 1.239 0 1 0 1.758-1.747zm14.357 11.29c1.917-1.933 2.021-4.635.246-6.424l-1.252-1.262a1.239 1.239 0 1 0-1.758 1.747l1.251 1.261c.92.927.539 2.14-.246 2.932a2.188 2.188 0 0 1-1.565.654 2.19 2.19 0 0 1-1.565-.654l-3.942-3.974c-1.803-1.817-.957-2.67-.596-3.034a1.24 1.24 0 0 0-1.758-1.746c-.828.834-1.239 1.787-1.239 2.785 0 1.222.616 2.513 1.834 3.74l3.943 3.975A4.653 4.653 0 0 0 15.165 20a4.653 4.653 0 0 0 3.324-1.387z' />
      <path d='M0 0h20v20H0z' />
    </g>
  </svg>

)

CopyLinkIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string
}

CopyLinkIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default CopyLinkIcon
