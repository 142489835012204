import React, { Component } from 'react'
import moment from 'moment'
import { Progress } from 'antd'
import Tooltip from './ToolTip'
import { truncate, vodStatusList } from '../../../util/util'
import ButtonContainer from '../general/buttons/ButtonContainer'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryJobStatus from './../../../graphQL/asset/getJobStatus'

const jobTypeList = {
  LIVE_TO_VOD: 'Live2VOD',
  VOD_MEDIA: 'VOD'
}

class JobStatusContent extends Component {
    componentDidMount = () => {
      const { job } = this.props
      if (job && (job.status !== 'COMPLETE' || !job.mediaId) && (job.status !== 'PUBLISHED' || !job.mediaId) && !job.status.includes('ERROR')) {
        this.liveTimeout = setInterval(this.getUpdatedJobStatus, 5 * 1000)
      }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
      if (newProps && newProps.job && ((newProps.job.status === 'COMPLETE' && newProps.job.mediaId) || (newProps.job.status === 'PUBLISHED' && newProps.job.mediaId) || newProps.job.status === 'ERROR') && this.liveTimeout) {
        clearTimeout(this.liveTimeout)
      }
    }

    componentWillUnmount = () => {
      if (this.liveTimeout) {
        clearTimeout(this.liveTimeout)
      }
    }

    getUpdatedJobStatus = () => {
      if (this.props.fetchDirectVodStatus && this.props.job) {
        this.props.fetchDirectVodStatus(this.props.job.id)
      }
    }

    getDuration = (duratonSec) => {
      let duration = duratonSec
      if (!duration) {
        const startTime = moment(new Date(this.props.job.startTime))
        const endTime = moment(new Date(this.props.job.endTime))
        duration = moment.duration(endTime.diff(startTime))
        duration = duration.asSeconds()
      }
      const totalSeconds = Math.floor(duration)
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor(totalSeconds % 3600 / 60)
      const seconds = Math.round(totalSeconds % 3600 % 60)

      return ((hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds))
    }

    getVodState = (states) => {
      if (states && states.length) {
        return (states || []).map((item) =>
          <div className='details'>
            <div className='detail-data'>
              <span>{item.status}</span>
              <span>{moment(item.updatedAt).format('DD MMM YYYY HH:mm')}</span>
            </div>
          </div>

        )
      } else return [<div className='detail-data'>{'Job status not available'}</div>]
    }

    getMoreDetails = (duration) => {
      const { job } = this.props
      const lastUpdated = job.updatedAt ? moment(job.updatedAt).format('DD MMM YYYY,HH:mm') : ''
      return (
        <div className='details'>
          {job.vwmJobId ? <div className='detail-data'>
            <span>Job Id</span>
            <span>{job.vwmJobId}</span>
          </div> : null }
          {job.type ? <div className='detail-data'>
            <span>Trigger Type</span>
            <span>{job.type}</span>
          </div> : null}
          {job.createdBy ? <div className='detail-data'>
            <span>Triggered By</span>
            <span>{job.createdBy}</span>
          </div> : null}
          {job.createdAt ? <div className='detail-data'>
            <span>Job Start</span>
            <span>{moment(job.createdAt).format('DD MMM YYYY,HH:mm')}</span>
          </div> : null }
          {job.site ? <div className='detail-data'>
            <span>Stream</span>
            <span>{job.site}</span>
          </div> : null }
          <div className='detail-data details-last-updated' id='last-updated'>
            <span>Last Updated</span>
            <span>{lastUpdated}</span>
          </div>
          <div className='detail-data details-duration'>
            <span>Duration</span>
            <span>{duration || '-'}</span>
          </div>
          <div className='detail-data details-type'>
            <span>Job Type</span>
            <span>{job.jobType ? jobTypeList[job.jobType] : 'Live2VOD'}</span>
          </div>
          {job.errorDesc && job.status === 'ERROR' ? <div className='detail-data'>
            <span>Error Details</span>
            <span>{job.errorDesc}</span>
          </div> : ''}
        </div>
      )
    }

    render () {
      const { job } = this.props
      let duration
      if (job.startTime && job.endTime) {
        duration = this.getDuration()
      } else if (job.mediaId) {
        duration = this.getDuration(job.mediaId.duration)
      }
      const moreDetailsData = this.getMoreDetails(duration)
      const vodStates = this.getVodState(job.jobStates)
      const lastUpdated = job.updatedAt ? moment(job.updatedAt).format('DD MMM YYYY,HH:mm') : ''
      return (
        <React.Fragment>
          <div className='job-status-content job-status-general'>
            <div className='type'> {job.jobType ? jobTypeList[job.jobType] : 'Live2VOD'} </div>
            <div className='name'> {job.name} </div>
            <div className='duration'> {duration || '-'} </div>
            <div className='progress'>
              <Progress percent={job.status === 'COMPLETE' || job.status.includes('ERROR') || job.status === 'PUBLISHED' ? 100 : job.progress ? job.progress : vodStatusList.indexOf(job.status) !== -1 ? vodStatusList.indexOf(job.status) + 1 * 14.29 : 0}
                showInfo={false} status={job.status.includes('ERROR') ? 'exception' : (job.status === 'COMPLETE' || job.status === 'PUBLISHED') ? 'success' : 'active'}
                strokeColor={job.status === 'REGISTERED' ? '#50e3c2' : ''} />
            </div>
            <div className='status asset-vod-detail-data' id='asset-vod-status'>
              {/* <p className={job.status === 'PUBLISHED' ? 'COMPLETE' : job.status}>{job.status === 'PUBLISHED' ? 'COMPLETE' : job.status === 'UNDEFINED' ? 'QUEUED' : job.status}</p> */}
              <Tooltip parentCompoent={'asset-vod-status'} placement={'rightTop'} details={vodStates} heading={'JOB STATUS'} isList arrowPointAtCenter
                childComponent={<p className='pink-color'> {job.status === 'PUBLISHED' ? 'COMPLETE' : job.status === 'UNDEFINED' ? 'QUEUED' : truncate(job.status, 8)} </p>} />
            </div>
            <div className='last-updated'>
              <ButtonContainer displayTitle={lastUpdated} childComponent={<span>{ lastUpdated }</span>} />
            </div>
            <div className='more-details' id='more-details'>
              <Tooltip parentCompoent={'more-details'} placement={'rightTop'} description={moreDetailsData} isList arrowPointAtCenter
                childComponent={<p className='pink-color'>More Details</p>} />
            </div>
          </div>
        </React.Fragment>
      )
    }
}

export default withApollo(compose(
  graphql(
    QueryJobStatus,
    {
      skip: ({ job }) => {
        return !job || (job.status === 'COMPLETE' && job.mediaId) || (job.status === 'PUBLISHED' && job.mediaId) || job.status === 'ERROR'
      },
      options: ({ job }) => {
        return {
          variables: { id: job.id }
        }
      },
      props: (props) => {
        return {
          fetchDirectVodStatus: (id) => {
            return props.data.refetch({ id })
          }
        }
      }
    }
  )

)(JobStatusContent))
