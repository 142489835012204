import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import Input from '../inputs/Input'
import { utilityService } from './../../../../services/UtilityService'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateLanguage from './../../../../graphQL/admin/entertainmentData/createLanguage'
import QueryLanguage from './../../../../graphQL/admin/entertainmentData/listLanguage'
import MutationUpdateLanguage from './../../../../graphQL/admin/entertainmentData/updateLanguage'

class CreateLanguageModal extends Component {
    state = {
      languageName: undefined,
      isLoading: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedLanguage && (!_.isEqual(this.props.selectedLanguage, nextProps.selectedLanguage) || (!this.state.languageName))) {
        this.setState({
          languageName: nextProps.selectedLanguage.name
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ languageName: value })
        }
      }
    }

    handleAddLanguage = () => {
      const { languageName } = this.state
      const { selectedLanguage, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })

      if (selectedLanguage) {
        const newLanguage = {
          id: selectedLanguage.id,
          name: languageName
        }
        if (!languageName) {
          message.error('Name is mandatory field')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateLanguage(newLanguage).then(response => {
          this.props.handleSubmit(response.data.updateLanguage)
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newLanguage = {
          name: languageName
        }
        this.props.createLanguage(newLanguage).then(response => {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          message.success('Language created successfully')
          this.props.handleSubmit(response.data.createLanguage)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    resetState = () => {
      const state = {
        languageName: '',
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedLanguage, isSubmitDisabled } = this.props
      const { languageName, isLoading } = this.state
      const disabled = selectedLanguage ? (selectedLanguage.name === languageName && selectedLanguage.name) : (!languageName)
      return (
        <Modal
          className='general-modal create-general-modal'
          title={selectedLanguage ? 'EDIT LANGUAGE' : 'NEW LANGUAGE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddLanguage}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
          confirmLoading={isLoading}
        >
          <div className='create-role'>
            <Input title='Name' isRequired inputName='name' handleChange={this.handleChange} value={languageName} placeholder='Enter Language name' />
          </div>
        </Modal>
      )
    }
}

CreateLanguageModal.propTypes = {
  /** visible file status of CreateRoleModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateRoleModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

CreateLanguageModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateLanguage,
    {
      options: ({ listApi, project, searchString }) => {
        return {
          update: (cache, { data: { createLanguage } }) => {
            try {
              const variables = { limit: 20, offset: 0, project: project, search: searchString || null }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryLanguage, variables }))
              if (cacheData && cacheData.listLanguage && cacheData.listLanguage.items) {
                cacheData.listLanguage.items.unshift(createLanguage)
              }
              cache.writeQuery({
                query: QueryLanguage,
                data: cacheData,
                variables
              })
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createLanguage: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateLanguage,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateLanguage } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryLanguage, variables }))
              const index = cacheData.listLanguage.items.findIndex(item => item.id === updateLanguage.id)
              if (index > -1 && updateLanguage.name) {
                cacheData.listLanguage.items[index].name = updateLanguage.name
              }
              cache.writeQuery({
                query: QueryLanguage,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { filter: null, offset: 0, limit: 20, project }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryLanguage, variables: newVariables }))
                const index = newCacheData.listLanguage.items.findIndex(item => item.id === updateLanguage.id)
                if (index > -1 && updateLanguage.name) {
                  newCacheData.listLanguage.items[index].name = updateLanguage.name
                }
                cache.writeQuery({
                  query: QueryLanguage,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateLanguage: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateLanguageModal))
