import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import './../../ui.style.scss'
import TickIcon from '../../../../components/player/icons/TickIcon'

const Option = Select.Option

class StreamDropDown extends React.Component {
    state = {
      value: undefined
    }

    onOptionSelect = (id) => {
      const selectedItem = this.props.options.find(item => item.id === id)
      // let isSelected = false
      // const index = selectedOption.findIndex(item => item.name === selectedItem.name)
      // if(index > -1){
      //   isSelected=true
      // }
      if (this.props.onSelect) { this.props.onSelect(selectedItem) }
    }

    onChangeSelected = (value) => {
      const selectedItem = this.props.options.find(item => item.name === value)
      if (this.props.onSelect) { this.props.onSelect(selectedItem) }
    }

    render () {
      const { options, selectedOption, parentCompoent, placeHolder, displayParam, valueParam } = this.props
      const optionComponents = options.map((option, index) => (
        <Option key={option.id || index} value={option[ valueParam || displayParam ]}>
          <div className='stream-drop-down-item'>
            <p>{ option.name }</p>
            <p>{ option.group }</p>
            <span>{(selectedOption || []).map((item, index) => item.name === option.name ? <TickIcon key={index} /> : null)} </span>
          </div>
        </Option>
      ))

      return (

        <div className='stream-drop-down'>

          <Select
            mode='multiple'
            value={selectedOption && selectedOption.length ? selectedOption.map(item => item.name) : undefined}
            placeholder={placeHolder}
            dropdownClassName={'stream-drop-down-options'}
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onDeselect={this.onChangeSelected}
            onSelect={this.onOptionSelect}
            filterOption={false}
          >
            { optionComponents }
          </Select>
        </div>
      )
    }
}

StreamDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Parent container for the dropdown. */
  parentCompoent: PropTypes.string.isRequired
}

StreamDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default StreamDropDown
