import React, { Component } from 'react'

import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import OfferList from './PartnerOfferList'
import HistoryList from '../auditManager/HistoryList'

const detailsTabs = [
  {
    displayName: 'Offers',
    mode: 'offers'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

class PartnerSideBar extends Component {
  state = {
    mode: 'offers'
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  render () {
    const { mode } = this.state
    const { filterVal, sort, selectedPartnerId, isSaving, details, refetchHistoryList, selectedPartner, project } = this.props
    return (
      <div>
        <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
        {mode === 'offers'
          ? <OfferList filterVal={filterVal} sort={sort} selectedPartnerId={selectedPartnerId} isSaving={isSaving} details={details} project={project} /> : null}

        {mode === 'history' ? <HistoryList isChanging={refetchHistoryList} showFilter={false} content='PARTNER_MANAGER' contentId={selectedPartner} project={project} /> : null }
        {mode === 'comments' ? null : null}
      </div>
    )
  }
}
export default PartnerSideBar
