import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Input from '../inputs/Input'
import TextAreaInput from '../inputs/TextArea'
import TagsInput from '../inputs/TagsInput'
import _ from 'lodash'
import './../../ui.style.scss'

class CreateUserGroupModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      userGroupTitle: undefined,
      disable: true,
      newDescription: undefined,
      selectedUsers: []
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (this.state.disable) {
      if (newProps.userGroupTitle && !_.isEqual(newProps.userGroupTitle, this.state.userGroupTitle)) { this.setState({ userGroupTitle: newProps.userGroupTitle }) }
      if (newProps.description && !_.isEqual(newProps.description, this.state.newDescription)) { this.setState({ newDescription: newProps.description }) }
      if (newProps.selectedUsers && !_.isEqual(newProps.selectedUsers, this.state.selectedUsers)) { this.setState({ selectedUsers: newProps.selectedUsers }) }
    }
  }

  resetState = () => {
    const state = {
      userGroupTitle: undefined,
      disable: true,
      newDescription: undefined,
      selectedUsers: []
    }
    this.setState({ ...state })
  }

  handleTitleChange =(e) => {
    const { value } = e.target
    this.setState({ userGroupTitle: value, disable: false })
  }

  onDescriptionChange =(e) => {
    const { value } = e.target
    this.setState({ newDescription: value, disable: false })
  }

  onChangeUser = (changedUser, isDelete) => {
    let { selectedUsers } = this.state
    if (isDelete) {
      const index = selectedUsers.findIndex(item => item === changedUser)
      if (index > -1) { selectedUsers.splice(index, 1) }
    } else {
      selectedUsers.push(changedUser)
    }
    setTimeout(() => {
      this.setState({ selectedUsers, disable: false })
    }, 100)
  }

  handleSubmit = () => {
    const { userGroupTitle, newDescription, selectedUsers } = this.state
    this.props.handleSubmit(userGroupTitle, newDescription, selectedUsers)
  }

  render () {
    const { visible, handleCancel, title, loading, parentCompoent, okText, userList, onSearchUser, isSubmitDisabled } = this.props
    const { userGroupTitle, disable, newDescription, selectedUsers } = this.state
    return (

      <Modal
        className='general-modal create-user-group-modal'
        visible={visible}
        closable={false}
        title={title}
        okButtonProps={{ disabled: !(!disable && userGroupTitle) || isSubmitDisabled, loading: loading }}
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okText={okText}
        cancelText='Cancel'
        afterClose={this.resetState}
        width='620px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <React.Fragment>
          <Input title='User Group' handleChange={this.handleTitleChange} value={userGroupTitle} placeholder='Enter User Group Name' />
          <TextAreaInput
            title='Description'
            placeholder='Enter description'
            inputName='description'
            onFocus={() => { }}
            onBlur={() => { }}
            value={newDescription}
            handleChange={this.onDescriptionChange}
          />
          <label className='user'> Users </label>
          <TagsInput
            containerClass='tags'
            selectedItems={selectedUsers}
            selectedDisplayItems={selectedUsers}
            keyParameter={'id'}
            tagList={userList || []}
            onChange={this.onChangeUser}
            placeholder='Select Users'
            parentCompoent={parentCompoent}
            displayParameter={'id'}
            mode={'multiple'}
            onSearch={onSearchUser}
          />
        </React.Fragment>

      </Modal>
    )
  }
}

CreateUserGroupModal.propTypes = {
  /** visible action of CreateUserGroupModal. */
  visible: PropTypes.bool,
  /** title  of CreateUserGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of CreateUserGroupModal. */
  handleCancel: PropTypes.func,
  /** Function to be called on search user */
  onSearchUser: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

export default CreateUserGroupModal
