import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import MultilineEllipsis from './../../MultilineEllipse'
import { utilityService } from '../../../services/UtilityService'
import { generateCroppedThumbnail } from '../../../util/util'

import placeholderImage from './../../../assets/images/default-image.jpg'
// import { ReactComponent as ArticlePublishIcon } from './../../../assets/images/article-published-icon.svg'
// import { ReactComponent as ArticleUnpublishIcon } from './../../../assets/images/article-unpublished-icon.svg'
// import { ReactComponent as ConferencePublishIcon } from './../../../assets/images/conference-published.svg'
// import { ReactComponent as ConferenceUnpublishIcon } from './../../../assets/images/conference-unpublished.svg'
// import { ReactComponent as HighlightsPublishIcon } from './../../../assets/images/highlights-published.svg'
// import { ReactComponent as HighlightsUnpublishIcon } from './../../../assets/images/highlights-unpublished.svg'
// import { ReactComponent as NewsPublishIcon } from './../../../assets/images/news-published.svg'
// import { ReactComponent as NewsUnpublishIcon } from './../../../assets/images/news-unpublished.svg'
// import { ReactComponent as ShortPublishIcon } from './../../../assets/images/short-published.svg'
// import { ReactComponent as ShortUnpublishIcon } from './../../../assets/images/short-unpublished.svg'
// import { ReactComponent as ShowPublishIcon } from './../../../assets/images/show-published.svg'
// import { ReactComponent as ShowUnpublishIcon } from './../../../assets/images/show-unpublished.svg'
// import { ReactComponent as MatchPublishIcon } from './../../../assets/images/match-published.svg'
// import { ReactComponent as MatchUnpublishIcon } from './../../../assets/images/match-unpublished.svg'
// import { ReactComponent as InterviewPublishIcon } from './../../../assets/images/interview-published.svg'
// import { ReactComponent as InterviewUnpublishIcon } from './../../../assets/images/interview-unpublished.svg'
// import { ReactComponent as FeaturePublishIcon } from './../../../assets/images/feature-published.svg'
// import { ReactComponent as FeatureUnpublishIcon } from './../../../assets/images/feature-unpublished.svg'
// import { ReactComponent as ClassicsPublishIcon } from './../../../assets/images/classics-published.svg'
// import { ReactComponent as ClassicsUnpublishIcon } from './../../../assets/images/classics-unpublished.svg'
// import { ReactComponent as LinearChannelPublishIcon } from './../../../assets/images/linear-channel-published.svg'
// import { ReactComponent as LinearChannelUnpublishIcon } from './../../../assets/images/linear-channel-unpublished.svg'
// import { ReactComponent as EgamePublishIcon } from './../../../assets/images/egame-published.svg'
// import { ReactComponent as EgameUnpublishIcon } from './../../../assets/images/egame-unpublished.svg'
// import { ReactComponent as SummaryPublishIcon } from './../../../assets/images/summary-published.svg'
// import { ReactComponent as SummaryUnpublishIcon } from './../../../assets/images/summary-unpublished.svg'

import UpdatedIcon from './../general/icons/UpdatedIcon'
import MutationUpdateAssets from '../../../graphQL/asset/updateAssetDetails'
import QueryListAssetType from '../../../graphQL/admin/asset/listAssetTypes'
import FreeIcon from '../../ui/general/icons/FreeIcon'
import { Checkbox, Button, message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'

class AssetListCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isFreeLoading: false
    }
  }

    getAssetIcon = (assetType, status, listAssetTypes) => {
      const selectedType = (listAssetTypes || []).find(item => item.title === assetType)
      return (selectedType && selectedType.icon) ? <img src={generateCroppedThumbnail((status === 'PUBLISHED' || status === 'PARTIAL') ? selectedType.icon : selectedType.draftIcon, 18, 18, '1:1')} /> : null
    }

    onCheck = e => {
      e.preventDefault()
      e.stopPropagation()
      this.props.onCheck(this.props.assetId)
    }

    onChangeIsFree = (value, event) => {
      const { assetId } = this.props
      event.preventDefault()
      event.stopPropagation()
      this.setState({ isFreeLoading: true }, () => {
        const variables = { id: assetId, isFree: !value }
        this.props.updateAsset(variables).then(() => {
          this.setState({ isFreeLoading: false })
        }, error => {
          this.setState({ isFreeLoading: false })
          utilityService.handleError(error)
        })
      })
    }

    selectedLink = (e) => {
      e.stopPropagation()
    }

    render () {
      const { imageUrl, isSelected, date, assetName, assetId, status, onSelect, isArchival, isChecked, displayId, isEditted, assetType, isFree,
        listAssetTypes, isFreeButtonEnable, project, asset, disableOnSelect } = this.props
      const assetNumber = (asset && assetType === 'EPISODE') ? asset.episodeNumber : (asset && assetType === 'SEASON') ? asset.seasonNumber : ''
      const parentAsset = (asset && assetType === 'EPISODE' && asset.season) ? asset.season.title : (asset && assetType === 'SEASON' && asset.series) ? asset.series.title : ''
      // isFreeButtonEnable, isUpdateDisabled, listAssetTypes } = this.props
      const { isFreeLoading } = this.state
      const image = imageUrl || placeholderImage
      return (
        <div
          className={`asset-list-cell ${isSelected ? 'active' : ''} 
          ${isArchival ? 'archive' : ''} ${status === 'DRAFT' ? 'expired' : ''}`}
          onClick={() => {
            if (!disableOnSelect) {
              onSelect(assetId)
            } else {
              message.warn('Please wait for image to be uploaded before switching assets')
            }
          }}
        >
          {isEditted ? <div className='asset-edit'> <UpdatedIcon /> </div> : null }
          { isArchival ? (<Checkbox checked={isChecked} onClick={this.onCheck} />) : null }
          <div className='asset-thumb' style={{ backgroundImage: `url(${image})` }} />
          <div className='details'>
            <MultilineEllipsis maxLines={'2'} text={assetName || 'Untitled'} />
          </div>
          {asset && (assetNumber || parentAsset) && (assetType === 'EPISODE' || assetType === 'SEASON')
            ? <div className='asset-number'>{`${(assetType === 'EPISODE' && assetNumber) ? 'E' : assetNumber ? 'S' : ''}${assetNumber || ''}${(parentAsset && assetNumber) ? ': ' : ''}${parentAsset || ''}`}</div> : ''}
          <div className='status-icon'>
            {/* <img src={ assetIcon } /> */}
            {this.getAssetIcon(assetType, status, listAssetTypes)}
            <p>{ date }</p>
          </div>
          {(isFreeButtonEnable && !isFreeLoading) && (project !== 'hyperion' || project !== 'projectx') && <FreeIcon color={isFree ? '#FF015A' : '#AFAFAF'} onClick={(e) => this.onChangeIsFree(isFree, e)} />}
          {(isFreeLoading) && <Button type='primary' loading />}
          {/* {isFreeButtonEnable ? <Switch checkedChildren='$' unCheckedChildren='0' size='small' checked={!isFree} onChange={this.onChangeIsFree} loading={isFreeLoading} disabled={isUpdateDisabled} /> : ''} */}
          {displayId ? <a className='asset-id' target='_blank' onClick={this.selectedLink} href={`${location.origin}/assets/${assetId}`} >{ `${displayId}` }</a> : null}
        </div>
      )
    }
}

AssetListCell.propTypes = {
  /** Asset type of AssetListCell. */
  assetType: PropTypes.string.isRequired,
  /** Asset image url of AssetListCell. */
  imageUrl: PropTypes.string,
  /** Asset status type of AssetListCell. */
  status: PropTypes.string,
  /** Asset type icon url of AssetListCell. */
  assetIcon: PropTypes.string,
  /** Asset publish date of AssetListCell. */
  date: PropTypes.string.isRequired,
  /** Asset name of AssetListCell. */
  assetName: PropTypes.string.isRequired,
  /** Asset Id of AssetListCell. AssetId is passed on selection action */
  assetId: PropTypes.string.isRequired,
  /** Asset selection status of AssetListCell */
  isSelected: PropTypes.bool,
  /** Asset archival status of AssetListCell */
  isArchival: PropTypes.bool,
  /** Asset checkbox status AssetListCell. */
  isChecked: PropTypes.bool,
  /** Click selection action of AssetListCell. */
  onSelect: PropTypes.func.isRequired,
  /** Check action of AssetListCell. */
  onCheck: PropTypes.func,
  /** Boolean to indicate isFree or not */
  isFree: PropTypes.bool,
  /** Boolean for enable/ disable isFree in asset */
  isFreeButtonEnable: PropTypes.bool
}

AssetListCell.defaultProps = {
  isSelected: false,
  isFreeButtonEnable: false
}

export default withApollo(compose(
  graphql(
    MutationUpdateAssets,
    {
      props: (props) => ({
        updateAsset: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryListAssetType,
    {
      options: ({ project }) => {
        const variables = { limit: 500, nextToken: null, filter: null, project }
        return {
          fetchPolicy: 'cache-first',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const listAssetTypes = data.listAssetTypes ? data.listAssetTypes.items : []
        return {
          listAssetTypes
        }
      }
    }
  )

)(AssetListCell))
