import React from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from './../icons/DeleteIcon'
import './../../ui.style.scss'

const DeleteButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button delete ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <DeleteIcon />
  </div>
)

DeleteButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

DeleteButton.defaultProps = {
  isDisabled: false
}

export default DeleteButton
