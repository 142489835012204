import React from 'react'

import Input from '../inputs/Input'
import InputNumber from '../inputs/InputNumber'
import DropDown from '../inputs/DropDown'
import Button from '../../general/buttons/Button'
import PopoverConfirm from '../../feedback/PopoverConfirm'

import './../../ui.style.scss'

const options = [
  {
    id: 'Top_Left',
    name: 'Top Left'
  // }, {
  //   id: 'Top_Centre',
  //   name: 'Top Centre'
  // }, {
  //   id: 'Top_Right',
  //   name: 'Top Right'
  // }, {
  //   id: 'Bottom_Left',
  //   name: 'Bottom Left'
  // }, {
  //   id: 'Bottom_Centre',
  //   name: 'Bottom Centre'
  // }, {
  //   id: 'Bottom_Right',
  //   name: 'Bottom Right'
  // }, {
  //   id: 'Centre_Left',
  //   name: 'Centre Left'
  // }, {
  //   id: 'Centre',
  //   name: 'Centre'
  // }, {
  //   id: 'Centre_Right',
  //   name: 'Centre Right'
  }
]

class CreateAspectRatioContainer extends React.Component {
  getCropPosition = (title, position) => {
    if (!position || !title) {
      return { width: 405, height: 230 }
    }
    const ratio = title.split(':')
    const aspectHeight = ratio[1]
    const aspectWidth = ratio[0]
    if (!aspectHeight || !aspectWidth) {
      return { width: 405, height: 230 }
    }
    const currentRatio = (aspectWidth / aspectHeight).toFixed(2)
    const dispayImageRatio = (405 / 230).toFixed(2)
    if (currentRatio > dispayImageRatio) {
      return { width: 405, height: 405 / currentRatio }
    } else {
      return { height: 230, width: 230 * currentRatio }
    }
  }

  render () {
    const { details, handleTextChange, handleCropPositionChange, addNewResolution, handleResolutionChange, handleAspectChange, onCloseResolution, isCreateDisabled } = this.props
    const { height, width } = this.getCropPosition(details.title, details.cropPosition)
    let aspectHeight, aspectWidth, ratio
    if (details && details.title) {
      ratio = details.title.split(':')
      aspectHeight = ratio[1]
      aspectWidth = ratio[0]
    }
    return (
      <div className='create-aspect-ratio-modal-container' id='create-aspect-ratio-modal'>
        <div className='field-top'>
          <div className='input-fields'>
            <div className='input-item'>
              <Input title='Aspect Ratio Name' placeholder='Insert Aspect Ratio Name' limit={40} inputName='name' value={details.name} handleChange={handleTextChange} isRequired />
            </div>
            <div className='aspect-ratio' >
              <div className='input-item'>
                <InputNumber readOnly={!!details.aspectRatio} title='Ratio Width' placeholder='Insert Aspect Ratio Width' inputName='width' value={aspectWidth} handleChange={(value) => handleAspectChange(value, 'width')} isRequired />
              </div>
              <div className='input-item'>
                <InputNumber readOnly={!!details.aspectRatio} title='Ratio Height' placeholder='Insert Aspect Ratio Height' inputName='height' value={aspectHeight} handleChange={(value) => handleAspectChange(value, 'height')} isRequired />
              </div>
            </div>
            <div className='input-item' id='crop-position-container'>
              <label>Crop Position</label>
              <DropDown options={options} onOptionSelect={handleCropPositionChange} selectedValue={details.cropPosition} valueParam='id' displayParam='name' placeHolder='Crop Position' parent={'crop-position-container'} />
            </div>
          </div>
          <div className='aspect-ratio-preview'>
            <p>Preview</p>
            <div className='preview-container'>
              <div className='preview' style={{ width, height }}>
                <p>Crop position</p>
              </div>
            </div>
          </div>
        </div>
        <div className='field-objects'>
          <p className='title' >Field Objects</p>
          {(details.resolutions || []).length ? <div className='field-table' >
            <div className='heading'>
              <p>Pixels Width</p>
              <p>Pixels Height</p>
            </div>
            {(details.resolutions || []).map((resolution, index) => <div key={resolution.id || index} className='field-item' >
              <div className='input-item'>
                <InputNumber title='' placeholder='Insert Aspect Ratio Width' inputName='width' value={resolution.width} handleChange={(value) => handleResolutionChange(value, 'width', index)} />
              </div>
              <div className='input-item'>
                <InputNumber title='' placeholder='Insert Aspect Ratio Width' inputName='height' value={resolution.height} handleChange={(value) => handleResolutionChange(value, 'height', index)} />
              </div>
              <div className='close-button'>
                <PopoverConfirm confirm={() => onCloseResolution(index)} parentCompoent={'create-aspect-ratio-modal'} />
              </div>
            </div>)}
          </div> : null}
          <div className='add-aspect-button' >
            <Button
              buttonText={'Add Resolution'}
              color='red'
              onClick={addNewResolution}
              isDisabled={isCreateDisabled}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default CreateAspectRatioContainer
