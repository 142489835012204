import React from 'react'
import PropTypes from 'prop-types'

const HistoryIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M.732-1h15v15h-15z' />
      <path fill={color} fillRule='nonzero' d='M8.607 0c-2.625 0-4.95 1.4-6.15 3.537L.732 1.842v4.79h4.875l-2.1-2.064c.975-1.842 2.85-3.094 5.1-3.094 3.075 0 5.625 2.505 5.625 5.526s-2.55 5.526-5.625 5.526c-2.475 0-4.5-1.547-5.325-3.684H1.707C2.532 11.79 5.307 14 8.607 14c3.975 0 7.125-3.168 7.125-7s-3.225-7-7.125-7zM7.482 3.684v3.758l3.525 2.063.6-.958-3-1.768V3.684H7.482z' />
    </g>
  </svg>
)

HistoryIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

HistoryIcon.defaultProps = {
  width: 16,
  height: 14,
  color: '#343A40'
}

export default HistoryIcon
