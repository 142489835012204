import gql from 'graphql-tag'

export default gql(`
  query($project: String){
    getAccessRoleGroups(project: $project){
      id
      name
    }
  }`
)
