import React from 'react'
import { Popover } from 'antd'
// import PropTypes from 'prop-types'
import './../ui.style.scss'

import placeholderImage from './../../../assets/images/default-image.jpg'
import MultilineEllipsis from './../../MultilineEllipse'
// import GroupDragAndDropIcon from '../general/icons/GroupDragAndDropIcon'

class WidgetListCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { widget, popupImageUrl } = this.props
    // const image = imageUrl || placeholderImage
    const popupUrl = popupImageUrl || placeholderImage
    const content = <div className='widget-thumb' >
      <img className='widget-thumb-preview' src={popupUrl} />
    </div>
    return (
      <div className='widget-list-cell'>
        {/* <div className='widget-cell-drag' >
          <GroupDragAndDropIcon width={10} height={11} />
        </div> */}
        <div className='widget-cell-left'>
          <div className='top'>
            <p className='widget-cell-title'>{widget.title}</p>
            <p className='widget-cell-key'>{widget.key}</p>
          </div>
          <div className='bottom'>
            <MultilineEllipsis maxLines={'2'} text={widget.description || '...'} />
          </div>
        </div>
        <Popover overlayClassName='widget-image' placement='left' content={content}>
          <div className='widget-thumb' style={{ backgroundImage: `url(${popupUrl})` }} />
        </Popover>
      </div>
    )
  }
}

WidgetListCell.propTypes = {

}

WidgetListCell.defaultProps = {

}

export default WidgetListCell
