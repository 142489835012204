import React from 'react'
import PropTypes from 'prop-types'
import MultilineEllipsis from './../../MultilineEllipse'
import ButtonContainer from '../general/buttons/ButtonContainer'
import './../ui.style.scss'

const ImageFileDisplay = ({ imageSrc, selected, fileName, imageIndex, onSelect, onDoubleClick, imageId, ...props }) => {
  return (
    <div className={`image-file-display ${selected ? 'selected' : ''}`} onClick={(e) => onSelect(imageIndex, e, imageId)} onDoubleClick={onDoubleClick}>
      <div className='image-item'>
        <img src={imageSrc} />
      </div>
      <div className='image-name'>
        {fileName.length > 35 ? <ButtonContainer displayTitle={fileName} childComponent={<MultilineEllipsis maxLines={'2'} text={fileName} />} />
          : <MultilineEllipsis maxLines={'2'} text={fileName} />}
      </div>
    </div>
  )
}

ImageFileDisplay.propTypes = {
  /** fileName of ImageFileDisplay. */
  fileName: PropTypes.string.isRequired,
  /** image url of ImageFileDisplay. */
  imageSrc: PropTypes.string.isRequired,
  /** Select action of ImageFileDisplay. */
  onSelect: PropTypes.func,
  /** selected status of ImageFileDisplay. */
  selected: PropTypes.bool,
  /** index of the image item */
  imageIndex: PropTypes.number
}

ImageFileDisplay.defaultProps = {
  imageSrc: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Football_iu_1996.jpg',
  fileName: 'Image File name 01'
}

export default ImageFileDisplay
