import gql from 'graphql-tag'

export default gql(`
    mutation deleteMetaSection($id: ID! $project: String) {
        deleteMetaSection(
            input: {
                id: $id
                project: $project
            }
        ) {
            id
            title
            usedCount
        }
    }`
)
