import gql from 'graphql-tag'

export default gql(`
query listProductionStudio($search: SearchInput $limit: Int $offset: Int $project: String) {
    listProductionStudio( 
      project: $project
      limit: $limit
      search: $search
      offset: $offset
    ){
        items {
          name
          id
          source
        }
      }
}`)
