import React from 'react'
import PropTypes from 'prop-types'

const ExpandedIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h15v15H0z' />
      <path fill='#343A40' d='M14.09 3l.91.97L7.5 12 0 3.97.905 3 7.5 10.055z' />
    </g>
  </svg>
)

ExpandedIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

ExpandedIcon.defaultProps = {
  width: 15,
  height: 15
}

export default ExpandedIcon
