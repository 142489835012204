import gql from 'graphql-tag'

export default gql(`
query($project: String) {
    listGenre( 
    project: $project
    limit: 999
    ){
        totalCount
        items {
          id
          name
          source
        }
      }
}`)
