import gql from 'graphql-tag'

export default gql(`
query listStreamSessions( $userId: ID! $project: String ) {
    listStreamSessions( 
        userId: $userId
        project: $project
    ) {
        sessionId
        app{
            appId
            appName
            environment
            platform
            version
        }
        asset{
            id
            externalId
            source
            type
            title
        }
        updatedAt
        createdAt
        isActive
        isKicked
        ip
        device{
            model
            osVersion
            make
            userAgent
            deviceTime
        }
        backendHeartBeats
        clientHeartBeats
        clientCreatedAt
        browser{
            userAgent
        }
    }
}`)
