import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import PropTypes from 'prop-types'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateContentPlaceholderModal from '../../../components/ui/dataEntry/other/CreateContentPlaceholderModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import userMessages from '../../../constants/messages'
// import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose, isEmpty } from 'lodash'
import QueryContentPlaceholder from '../../../graphQL/asset/listContetPlaceHolder'
import MutationCreateContentPlaceholder from '../../../graphQL/asset/createContentPlaceholder'
import MutationUpdateContentPlaceholder from '../../../graphQL/asset/updateContentPlaceholder'
import MutationBatchDeleteContentPlaceholder from '../../../graphQL/asset/batchDeleteContentPlaceholder'

const deleteContentPlaceholderMessage = {
  title: 'DELETE CONTENT PLACEHOLDER',
  firstMessage: 'Are you sure you want to delete this Content Placeholder?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]
class ContentPlaceHolderList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedPlaceholder: null,
      isLoading: true,
      showAddContentPlaceholderModal: false,
      showTransferContentPlaceholderModal: false,
      showDeleteContentPlaceholderModal: false,
      selectedPlaceholder: undefined,
      selectedRowKeys: [],
      isCreateLoading: false,
      isSelected: false,
      isMultipleDelete: false,
      isDeleteLoading: false,
      isDeleted: false
    }
    this.columns = [
      {
        title: 'Content Placeholder',
        dataIndex: 'name',
        key: 'name',
        width: '37%',
        className: 'placehodler'
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: '30%',
        className: 'placehodler'
      },
      {
        title: '# of Assets',
        dataIndex: 'usedCount',
        width: '30%',
        key: 'usedCount'
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id, data) => this.renderData(data)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.placeholderList, this.props.placeholderList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedPlaceholder) {
        this.setState({ openedPlaceholder: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedPlaceholder === id) {
        this.setState({ openedPlaceholder: null })
      } else {
        this.setState({ openedPlaceholder: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { placeholderList } = this.props
      const selectedPlaceholder = placeholderList.find(item => item.id === id)
      if (!selectedPlaceholder) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddContentPlaceholderModal: true, selectedPlaceholder })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteContentPlaceholderModal: true, selectedPlaceholder })
      }
      this.setState({ openedPlaceholder: null })
    }

    showAddContentPlaceholder = () => {
      this.setState({ showAddContentPlaceholderModal: true })
    }

    hideAddContentPlaceholderModal = () => {
      this.setState({ showAddContentPlaceholderModal: false, selectedPlaceholder: null })
    }

    handleAddContentPlaceholder = (placeholderDetails, id) => {
      const { onHistoryUpdate } = this.props
      this.setState({ isCreateLoading: true })
      if (!id) {
        this.props.createContentPlaceHolder(placeholderDetails).then(() => {
          onHistoryUpdate()
          this.setState({ showAddContentPlaceholderModal: false, selectedPlaceholder: null, isCreateLoading: false })
        }, error => {
          this.setState({ showAddContentPlaceholderModal: false, selectedPlaceholder: null, isCreateLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const variables = {
          id,
          name: placeholderDetails.name,
          key: placeholderDetails.key,
          metaFields: (placeholderDetails.metaFields || []).map(metaField => ({ displayName: metaField.displayName, type: metaField.type, isRequired: metaField.isRequired, id: metaField.id, values: metaField.values, tooltip: metaField.tooltip, displayTitle: metaField.displayTitle }))
        }
        this.props.updateContentPlaceHolder(variables).then(() => {
          onHistoryUpdate()
          this.setState({ showAddContentPlaceholderModal: false, selectedPlaceholder: null, isCreateLoading: false })
        }, error => {
          this.setState({ showAddContentPlaceholderModal: false, selectedPlaceholder: null, isCreateLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    hideDeleteContentPlaceholderModal = () => {
      this.setState({ isDeleted: true, selectedPlaceholder: null }, () => {
        this.setState({ showDeleteContentPlaceholderModal: false, isDeleted: false })
      })
    }

    handleDeleteContentPlaceholder = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const { selectedPlaceholder } = this.state
      const variable = [{ id: selectedPlaceholder.id }]
      this.props.deleteContentPlaceHolder(variable).then(() => {
        this.setState({ isDeleted: true, selectedPlaceholder: null }, () => {
          this.setState({ showDeleteContentPlaceholderModal: false, isDeleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedPlaceholder: null }, () => {
          this.setState({ showDeleteContentPlaceholderModal: false, isDeleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    handleMultipleDeleteContentPlaceHolder = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const { selectedRowKeys } = this.state
      const variable = (selectedRowKeys || []).map(id => ({ id }))
      this.props.deleteContentPlaceHolder(variable).then(() => {
        onHistoryUpdate()
        this.setState({ showDeleteContentPlaceholderModal: false, isDeleteLoading: false, isMultipleDelete: false, selectedRowKeys: [], isSelected: false })
      }, error => {
        this.setState({ showDeleteContentPlaceholderModal: false, isDeleteLoading: false, isMultipleDelete: false, selectedRowKeys: [], isSelected: false })
        utilityService.handleError(error)
      })
    }

    hideMultipleDeleteContentPlaceHolder = () => {
      this.setState({ isMultipleDelete: false })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true, openedPlaceholder: null })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangeContentFilter(value)
    }

    loadMoreAsset = () => {
      if (!this.props.nextToken || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreCategories(this.props.nextToken))
    }

    renderData = ({ id, usedCount }) => {
      let availableOptions = _.cloneDeep(options)
      const { openedPlaceholder, isSelected } = this.state
      const tempId = utilityService.makeRandomString(6)
      if (!usedCount && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className={`option-list${isSelected ? ' hide' : ''}`} id={tempId}>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={tempId}
              isVisible={openedPlaceholder === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { placeholderList, nextToken, isLoading, onHistoryUpdate } = this.props
      const { isPaginating, showAddContentPlaceholderModal, showDeleteContentPlaceholderModal, selectedPlaceholder, isMultipleDelete, selectedRowKeys,
        isSelected, isCreateLoading, isDeleteLoading, isDeleted } = this.state
      // let adminItem
      // if (selectedPlaceholder && showDeleteContentPlaceholderModal) {
      //   adminItem = <div >
      //     <p>{ selectedPlaceholder.name } is being used {selectedPlaceholder.used || '0'} times.</p>
      //   </div>
      // }
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false,
        getCheckboxProps: record => ({
          disabled: record.usedCount !== 0
        })
      }

      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal placeholder-list'>
              {/* <FilterInput
                searchString={searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
              /> */}
              <div className='admin-list placeholder-data'>
                <FixedTableHeader columnCellList={this.columns} isExpandable={isSelected} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={nextToken}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading && placeholderList && !placeholderList.length}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={placeholderList} pagination={false} rowSelection={isSelected ? rowSelection : undefined} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {!isSelected
                    ? <MultipleSelectButton onClick={this.onMultipleSelect} isDisabled={this.isDeleteDisabled} />
                    : <React.Fragment>
                      <BackArrowButton onClick={this.onMultipleDSelect} />
                      <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                    </React.Fragment>
                  }
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddContentPlaceholder}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                /> : null}
              </div>
              <CreateContentPlaceholderModal
                isVisible={showAddContentPlaceholderModal}
                handleCancel={this.hideAddContentPlaceholderModal}
                handleSubmit={this.handleAddContentPlaceholder}
                selectedPlaceholder={selectedPlaceholder}
                isLoading={isCreateLoading}
                isSubmitDisabled={_.isEmpty(selectedPlaceholder) ? isCreateDisabled : this.isUpdateDisabled}
                placeholderList={placeholderList}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
              />
              {/* <CreateContentPlaceholderModal isVisible={showEditContentPlaceholderModal} handleCancel={this.hideEditContentPlaceholderModal} handleSubmit={this.handleEditContentPlaceholder} title='EDIT CONTENT PLACEHOLDER' /> */}
              <AdminItemConfirmModal
                isVisible={showDeleteContentPlaceholderModal}
                title={deleteContentPlaceholderMessage.title}
                firstMessage={deleteContentPlaceholderMessage.firstMessage}
                secondMessage={deleteContentPlaceholderMessage.secondMessage}
                // adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteContentPlaceholderModal}
                handleSubmit={this.handleDeleteContentPlaceholder}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={isDeleteLoading}
              />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'MULTIPLE DELETE CONTENT PLACEHOLDER'}
                message={userMessages.DELETE_MULTIPLE_CONTENT_PLACEHOLDER}
                isLoading={isDeleteLoading}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeleteContentPlaceHolder}
                handleCancel={this.hideMultipleDeleteContentPlaceHolder}
                isSubmitButtonDisabled={this.isDeleteDisabled}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

ContentPlaceHolderList.propTypes = {
  /** Function to be called on search for content placeholder */
  onChangeContentFilter: PropTypes.func,
  /** Search string for search */
  searchString: PropTypes.string
}

export default withApollo(compose(
  graphql(
    QueryContentPlaceholder,
    {
      options: ({ nextToken, searchString, project }) => {
        const filter = searchString ? { name: { contains: searchString } } : null
        const variables = { limit: 50, nextToken: nextToken || null, filter, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const { project } = props.ownProps
        const placeholderList = data.listContentPlaceHolder ? data.listContentPlaceHolder.items : []
        return {
          placeholderList,
          isLoading: data.loading,
          prevToken: props.ownProps.nextToken ? props.ownProps.nextToken : null,
          nextToken: data.listContentPlaceHolder ? data.listContentPlaceHolder.nextToken : null,
          getMoreCategories: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: page,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listContentPlaceHolder.items, ...fetchMoreResult.listContentPlaceHolder.items]
                prev.listContentPlaceHolder.items = newList
                prev.listContentPlaceHolder.prevToken = prev.listContentPlaceHolder.nextToken
                prev.listContentPlaceHolder.nextToken = fetchMoreResult.listContentPlaceHolder.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationCreateContentPlaceholder,
    {
      options: ({ prevToken, searchString, project }) => {
        return {
          update: (cache, { data: { createContentPlaceHolder } }) => {
            try {
              const filter = searchString ? { name: { contains: searchString } } : null
              const variables = { limit: 50, nextToken: prevToken, filter, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryContentPlaceholder, variables }))
              if (cacheData && cacheData.listContentPlaceHolder && cacheData.listContentPlaceHolder.items) {
                cacheData.listContentPlaceHolder.items.splice(0, 0, createContentPlaceHolder)
              }
              cache.writeQuery({
                query: QueryContentPlaceholder,
                data: cacheData,
                variables
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        createContentPlaceHolder: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateContentPlaceholder,
    {
      options: ({ prevToken, searchString, project }) => {
        return {
          update: (cache, { data: { updateContentPlaceHolder } }) => {
            try {
              if (!isEmpty(updateContentPlaceHolder)) {
                const filter = searchString ? { name: { contains: searchString } } : null
                const variables = { limit: 50, nextToken: prevToken, filter, project }
                const cacheData = _.cloneDeep(cache.readQuery({ query: QueryContentPlaceholder, variables, returnPartialData: true }))
                if (cacheData && cacheData.listContentPlaceHolder && cacheData.listContentPlaceHolder.items) {
                  const index = cacheData.listContentPlaceHolder.items.findIndex((item) => {
                    return item.id === updateContentPlaceHolder.id
                  })
                  cacheData.listContentPlaceHolder.items[index] = updateContentPlaceHolder
                }
                cache.writeQuery({
                  query: QueryContentPlaceholder,
                  data: cacheData,
                  variables
                })
              }
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        updateContentPlaceHolder: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchDeleteContentPlaceholder,
    {
      options: ({ prevToken, searchString, project }) => {
        return {
          update: (cache, { data: { batchDeleteContentPlaceHolder } }) => {
            try {
              const filter = searchString ? { name: { contains: searchString } } : null
              const variables = { limit: 50, nextToken: prevToken, filter, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryContentPlaceholder, variables }))
              if (cacheData && cacheData.listContentPlaceHolder && cacheData.listContentPlaceHolder.items) {
                cacheData.listContentPlaceHolder.items = cacheData.listContentPlaceHolder.items.filter(contentItem => {
                  const index = batchDeleteContentPlaceHolder.findIndex(deletedContent => deletedContent.id === contentItem.id)
                  return index === -1
                })
              }
              cache.writeQuery({
                query: QueryContentPlaceholder,
                data: cacheData,
                variables
              })
            } catch (e) {
              console.log(e)
            }
          }
        }
      },
      props: (props) => ({
        deleteContentPlaceHolder: (variables) => {
          return props.mutate({
            variables: { deleteInput: variables, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(ContentPlaceHolderList))
