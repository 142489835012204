import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MatchExpandedViewDetails from './MatchExpandedViewDetails'
import MatchAssetDetails from './MatchAssetDetails'
// import { generateCroppedThumbnail } from './../../../util/util'
import './../ui.style.scss'
import Tooltip from './ToolTip'

// const isLive = true

class MatchListExpandedCell extends React.Component {
  getVodState = (states) => {
    if (states && states.length) {
      return (states || []).map((item) =>
        <div className='details'>
          <div className='detail-data'>
            <span>{item.status}</span>
            <span>{moment(item.updatedAt).format('DD MMM YYYY HH:mm')}</span>
          </div>
        </div>

      )
    } else return [<div className='detail-data'>{'Job status not available'}</div>]
  }

  render () {
    const { matchId, details, isSelected, onSelect, status, program, matchSearch, matchFilter, selectedMatchId, types, vodStatusAssets } = this.props
    const matchTime = details.matchDate ? details.matchDate : details.kickOffTime
    let vodStatusID = null
    let activeJobItem = []
    if (vodStatusAssets && vodStatusAssets.length) {
      vodStatusID = vodStatusAssets[0].vodStatus && vodStatusAssets[0].vodStatus.id
      activeJobItem = vodStatusAssets[0].vodJobs && vodStatusAssets[0].vodJobs.length && vodStatusAssets[0].vodJobs.find(({ id }) => id === vodStatusID)
    }
    const vodStates = this.getVodState(activeJobItem && activeJobItem.jobStates)
    return (
      <div
        className={`match-list-cell expanded ${isSelected ? 'active' : ''}`} id='match-list-cell'
        onClick={() => onSelect(matchId)}
      >
        <div className='expanded-view'>
          <div className='match-data'>
            <div className='league-name'>
              <p>{details.competition ? details.competition.customTitle || details.competition.title : '-'}</p>
            </div>
            <div className='match-teams'>
              <MatchExpandedViewDetails teamA={details.homeTeam} teamB={details.awayTeam} />
            </div>
            <p className='match-id'>{details.opId ? details.opId : null}</p>
          </div>

          <div className='match-time'>
            <div className='time'>
              <div className='schedule'>
                <label>Broadcast Time </label>
                <p>{program && program.broadCastStartTime
                  ? `${moment(new Date(program.broadCastStartTime)).format('HH:mm')} - ${program.broadCastEndTime
                    ? moment(new Date(program.broadCastEndTime)).format('HH:mm') : ' - '} `
                  : ' - '}</p>
              </div>
              <div className='schedule'>
                <label>Match Time </label>
                <p>{details.kickOffTime || details.matchDate
                  ? `${moment(new Date(matchTime)).format('HH:mm')}  - ${details.finishTime
                    ? moment(new Date(details.finishTime)).format('HH:mm') : ''}`
                  : ' - '}</p>
              </div>
            </div>

            <div className='details'>
              <div className='channel'>
                <label>Channel: </label>
                <p>{program && program.channel
                  ? <a className='pink-color' href={location.origin + '/channels/' + program.channel.id} target='_blank'> {program.channel.channelCode} </a> : ' - '}
                </p>
              </div>
              <div className='status'><label>Live2Vod Status: </label>
                {
                  status
                    ? <Tooltip parentCompoent={'match-list-cell'} placement={'bottom'} details={vodStates} heading={'JOB STATUS'} isList arrowPointAtCenter
                      childComponent={<p className={status === 'On Now' ? 'ERROR' : status === 'AWAITING AUTO TRIGGER' || status === 'AUTO TRIGGER DISABLED' ? 'SCHEDULED' : status}>
                        {status}
                      </p>}
                    />
                    : <p style={{ color: '#343a40' }}> - </p>
                }
              </div>
            </div>
          </div>
          <MatchAssetDetails assets={details.asset ? details.asset : []} isExpanded details={details} matchSearch={matchSearch} matchFilter={matchFilter} selectedMatchId={selectedMatchId} types={types} />
          {/* <div className='assignee'>
              <label>Assignee</label>
              <p> - </p>
            </div> */}
        </div>
      </div>
    )
  }
}

MatchListExpandedCell.propTypes = {
  /** Match id of MatchListCell */
  matchId: PropTypes.string,
  /** Match details expanded status of MatchListCell */
  isExpanded: PropTypes.bool,
  /** Match selected status of MatchListCell */
  isSelected: PropTypes.bool,
  /** Match details of MatchListCell */
  details: PropTypes.object,
  /** Click selection action of MatchListCell. */
  onSelect: PropTypes.func

}

MatchListExpandedCell.defaultProps = {
  isSelected: false
}

export default MatchListExpandedCell
