import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'

const placeholderImage = 'http://vcms-static.s3-website-ap-southeast-2.amazonaws.com/default_image.jpg'

const CropImageList = ({ imageUrl, isSelected, onSelected, aspectRatio, onClick, position, ...props }) => (
  <div className={`crop-image-list${isSelected ? ' active' : ''}`} onClick={() => onSelected(aspectRatio, imageUrl)}>
    { isSelected && <div className='pink-border' /> }
    <div className={`img-container ${position}`}>
      <img src={imageUrl || placeholderImage} alt='card' />
    </div>
    <label className='dimension-text'>{aspectRatio.title}</label>
  </div>
)

CropImageList.propTypes = {
  /* Url of the thumbnail image */
  imageUrl: PropTypes.string,
  /* Whether the current list is selected or not */
  isSelected: PropTypes.bool,
  /* Image dimension to be displayed as text */
  dimension: PropTypes.string,
  /* Function to be executed when crop label is clicked */
  onClick: PropTypes.func,
  /**  */
  onSelected: PropTypes.func,
  /**  */
  ratioId: PropTypes.string
}

CropImageList.defaultProps = {
  imageUrl: placeholderImage,
  isSelected: false,
  dimension: '1920x1080',
  onClick: () => {}
}

export default CropImageList
