import gql from 'graphql-tag'

export default gql(`
    mutation(
        $name: String!   
        $project: String 
   ) {
        createOffer(
            input: {
            name: $name         
            }
            project: $project
        ) {
            id
            externalId
            name    
            description
            mediumDescription
            shortDescription   
            terms
            isActive
            startDate
            endDate
            recurrence
            isArchived
        }
    }`
)
