import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import _ from 'lodash'
// import HistoryList from '../../../../modules/auditManager/HistoryList'
import { generateCroppedThumbnail } from '../../../../util/util'
// import { utilityService } from '../../../../services/UtilityService'
import TextAreaInputApp from '../inputs/TextAreaApp'
import RichText from './RichText'
import './../../ui.style.scss'

class DisplayValueModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { isVisible, handleCancel, viewData, title } = this.props
    // const { value, data } = viewData
    let currentImage
    if (viewData && viewData.data && viewData.data.type === 'image') {
      const image = !_.isEmpty(viewData.value) ? generateCroppedThumbnail(viewData.value, 180, 180, '1:1') : undefined
      const defaultImage = require('../../../../assets/images/default-image-square.jpg')
      currentImage = image || defaultImage
    }

    return (

      <Modal
        className='general-modal app-display-value-modal'
        visible={isVisible}
        title={title}
        maskClosable={false}
        closable
        onCancel={handleCancel}
        // afterClose={this.resetState}
        centered
        destroyOnClose
      >
        <div>
          {viewData && viewData.data && viewData.data.type === 'image'
            ? <div className='default-image'>
              <img size={64} className='user' src={currentImage} />
            </div>
            : null}
          {viewData && viewData.data && (viewData.data.type === 'json')
            ? <div className='default-richtext'>
              <TextAreaInputApp
                key={viewData.data.id}
                // title={config.name}
                inputName='title'
                value={viewData ? viewData.value : ''}
                // isRequired={config.isRequired}
                // isError={selectedMeta ? selectedMeta.isError : false}
                isEditable={false}
                // handleChange={({ target }) => handleObjectDetailsChange(config, target.value)}
              />
            </div> : null }
          {viewData && viewData.data && (viewData.data.type === 'richtext')
            ? <div className='default-richtext'>
              <RichText key={viewData.data.id} id={viewData.data.id} text={viewData ? viewData.value : ''} showCloseIcon={false} readOnly showToolbar={false} />
            </div> : null }
        </div>
      </Modal>

    )
  }
}

DisplayValueModal.propTypes = {
  /** visible action of AddBucketGroupModal. */
  visible: PropTypes.bool,
  /** title  of AddBucketGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of AddBucketGroupModal. */
  handleCancel: PropTypes.func,
  /** Boolean for showing loading */
  loading: PropTypes.bool,
  /** Boolean to know source of the modal */
  isFromEdit: PropTypes.bool
}

DisplayValueModal.defaultProps = {
}

export default DisplayValueModal
