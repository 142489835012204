import * as React from 'react'
import CopyLinkIcon from '../general/icons/CopyLinkIcon'
import PropTypes from 'prop-types'
import { message } from 'antd'
import userMessages from '../../../constants/messages'
import ButtonContainer from '../general/buttons/ButtonContainer'
import PlayButton from '../general/buttons/PlayButton'

class EncoderGroupTile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isHoverOnLink: false
    }
  }

  onCopyUrl = (url) => {
    const dummy = document.createElement('input')
    document.body.appendChild(dummy)
    dummy.setAttribute('id', 'dummy_id')
    document.getElementById('dummy_id').value = url
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    message.success(userMessages.LINK_COPIED)
  }

  isConfigValid = () => {
    const { stream } = this.props
    let url, contentKey, streamType
    if (stream && stream.fields && stream.streamSchema) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const expectedStreamType = isSafari ? 'HLS' : 'DASH'
      url = stream.url
      const contentKeyField = stream.fields.find(item => item.name === 'Content Key')
      if (contentKeyField) {
        contentKey = contentKeyField.value
      }
      if (stream.streamSchema.levels && stream.streamSchema.levels.length) {
        const isStreamPresent = stream.streamSchema.levels.findIndex(item => item.name === expectedStreamType)
        if (isStreamPresent > -1) {
          streamType = expectedStreamType
        }
        if (streamType && contentKey && url) {
          return true
        }
      }
    }
    return false
  }

  renderInnerComp = (data) => {
    const { isLoadingPlayer, onSelectPlayer, url, isPlayerSelected } = this.props
    const innerItem = (data.levels || []).map((dataItem, index) => {
      return <div key={index} className={`${index === 0 ? 'encoder-item-code' : (index === 1 ? 'encoder-item-status' : 'encoder-item-protocol')}`}><span>{ dataItem.name }</span></div>
    })
    const isValidConfig = this.isConfigValid()
    return <React.Fragment >
      <div className='encoder-group'>
        <div className='encoder-group-items'>
          {innerItem}
        </div>
        <div className='copy-link'>
          <ButtonContainer displayTitle='Copy Stream URL' childComponent={<CopyLinkIcon onClick={() => this.onCopyUrl(url)} />} />
          { isValidConfig ? <ButtonContainer displayTitle='Play Stream' childComponent={<PlayButton isPlaying={isPlayerSelected} isLoading={isLoadingPlayer} onClick={onSelectPlayer}>Play</PlayButton>} /> : null }
        </div>
      </div>
    </React.Fragment>
  }

  render () {
    return <React.Fragment>
      {this.renderInnerComp(this.props.details)}
    </React.Fragment>
  }
}

EncoderGroupTile.propTypes = {
  details: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired
}

export default EncoderGroupTile
