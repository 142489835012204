
const defaultContentData = {
  TITLE: {
    type: 'TITLE',
    value: ''
  },
  DESCRIPTION: {
    type: 'DESCRIPTION',
    value: ''
  },
  AUTHOR: {
    type: 'AUTHOR',
    name: ''
  },
  SHARE: {
    type: 'SHARE'
  },
  placeholder: {
    type: 'placeholder',
    placeholder: 'Custom Placeholder'
  },
  SOCIAL: {
    type: 'SOCIAL',
    value: ''
  },
  RICHTEXT: {
    type: 'RICHTEXT',
    value: ''
  },
  EMBED: {
    type: 'EMBED',
    value: ''
  },
  DEFAULT_IMAGE: {
    type: 'DEFAULT_IMAGE',
    value: ''
  }
}

const contentTypes = [
  {
    type: 'AUTHOR',
    name: 'Author'
  }, {
    type: 'DESCRIPTION',
    name: 'Description'
  }, {
    type: 'DEFAULT_IMAGE',
    name: 'Default Image'
  }, {
    type: 'IMAGE',
    name: 'Image'
  }, {
    type: 'RICHTEXT',
    name: 'Rich Text'
  }, {
    type: 'SHARE',
    name: 'Share'
  }, {
    type: 'SOCIAL',
    name: 'Social Media'
  }, {
    type: 'TITLE',
    name: 'Title'
  }, {
    type: 'EMBED',
    name: 'Embed Script'
  }
]

const assetDefaultValues = {
  defaultContentData,
  contentTypes
}

export default assetDefaultValues
