import React, { Component } from 'react'

// import PropTypes from 'prop-types'
import AppContext from '../../../AppContext'
import DataExpandTable from '../../../components/ui/dataDisplay/DataExpandTable'
// import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
// import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import AddCompetitionModal from '../../../components/ui/dataEntry/other/AddCompetitionModal'
import DropDown from '../../../components/ui/dataEntry/inputs/DropDown'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCompetitionsES from '../../../graphQL/asset/listCompetitionsES'
import QueryListSports from '../../../graphQL/admin/sports/listSports'

class OptaDataDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isAddCompetition: false,
      competitions: props.competition && props.competition.length ? props.competition : [],
      loading: true,
      selectedSport: undefined
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.competition) {
      this.setState({ loading: newProps.loading })
    }
    if (newProps.competition && !_.isEqual(newProps.competition, this.props.competition)) {
      this.setState({ competitions: newProps.competition, loading: newProps.loading })
    }
    if (newProps.listSports && !_.isEqual(newProps.listSports, this.props.listSports)) {
      newProps.listSports.forEach(sport => {
        if (sport.title === 'Soccer') {
          this.setState({ selectedSport: sport.id }, this.onChangeDataFilter)
        }
      })
    } else if (_.isEmpty(this.state.selectedSport) && !_.isEmpty(this.props.listSports)) {
      newProps.listSports.forEach(sport => {
        if (sport.title === 'Soccer') {
          this.setState({ selectedSport: sport.id }, this.onChangeDataFilter)
        }
      })
    }
  }

  onAddCompetition = () => {
    this.setState({ isAddCompetition: true })
  }
  hideCompetitionModal = () => {
    this.setState({ isAddCompetition: false })
  }

  onSportSelect = (value) => {
    this.setState({ selectedSport: value }, this.onChangeDataFilter)
  }

  onChangeDataFilter = () => {
    const { selectedSport } = this.state
    const dataFilter = {
      sport: selectedSport
    }
    this.props.onChangeDataFilter(dataFilter)
  }

  render () {
    const { isAddCompetition, competitions, loading, selectedSport } = this.state
    const { onHistoryUpdate, optaHistoryId, listSports } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <React.Fragment>
            <div className='opta-data-details'>
              <div className='opta-data-sport'>
                <div className='opta-data-sport-label'>Filter:</div>
                <div className='opta-data-sport-filter'>
                  <DropDown
                    options={listSports}
                    selectedValue={selectedSport}
                    placeHolder={'Select sport'}
                    valueParam={'id'}
                    displayParam={'title'}
                    onOptionSelect={this.onSportSelect}
                    parent={'data-manager'}
                  />
                </div>
              </div>
              {/* <FilterInput
                  searchString={''}
                  onChangeSearchInput={() => { }}
                  filterType={'DataManager'}
                  changeFilter={() => { }}
                  placement='bottomLeft'
                  parentId={'data-manager'}
                /> */}
              <DataExpandTable competitions={competitions} loading={loading} isUpdateDisabled={isUpdateDisabled} onHistoryUpdate={onHistoryUpdate} optaHistoryId={optaHistoryId} selectedSport={selectedSport} project={project} />
            </div>
            <div className='data-bottom'>
              {/* <LoadingButton
                  type='primary'
                  onClick={this.onAddCompetition}
                  htmlType='submit'
                  buttonText={'Add Competition'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={false}
                /> */}
              <AddCompetitionModal visible={isAddCompetition} handleSubmit={() => { }} handleCancel={this.hideCompetitionModal} loading={false} project={project}
                title={'New Competition'} okText={'Save'} />
            </div>
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

OptaDataDetails.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCompetitionsES,
    {
      options: ({ dataFilter, project }) => {
        if (!dataFilter) {
          dataFilter = {}
        }
        let filter = {}
        if (dataFilter.sport) {
          filter.sport = { multiple_exact: [dataFilter.sport] }
        } else {
          filter = null
        }
        return {
          variables: { filter: !_.isEmpty(filter) ? filter : null, limit: 50, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let competition = data.listCompetitionsES ? [ ...data.listCompetitionsES.items ] : []
        return {
          competition,
          loading: data.loading
        }
      }
    }
  ),
  graphql(
    QueryListSports,
    {
      options: (props) => {
        const nextToken = props.nextToken ? props.nextToken : null
        return {
          variables: { limit: 999, nextToken },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let listSports = data.listSports ? [ ...data.listSports.items ] : []
        const nextToken = data.listSports ? data.listSports.nextToken : null
        const previousToken = data.listSports ? data.listSports.previousToken : null

        return {
          listSports,
          isLoading: data.loading,
          nextToken,
          previousToken,
          getMoreSports: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: nextToken
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newSportsList = fetchMoreResult.listSports ? [ ...fetchMoreResult.listSports.items ] : []
                let newList = [ ...prev.listSports.items, ...newSportsList ]
                newList = _.uniqBy(newList, 'id')
                if (_.isEqual(newList, prev.listSports.items)) { return prev }
                prev.listSports.items = newList
                prev.listSports.previousToken = prev.listSports.nextToken
                prev.listSports.nextToken = fetchMoreResult.listSports.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  )

)(OptaDataDetails))
