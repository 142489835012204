import React from 'react'
import PropTypes from 'prop-types'
import { Select, Tooltip } from 'antd'

import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import { utilityService } from '../../../../services/UtilityService'

const Option = Select.Option

const customFilter = (input, option, options) => {
  const currentOption = (options || []).find(item => item.id === option.props.value)
  const returnValue = currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

const PersonDropDown = ({ displayParam, onOptionSelect, selected, parentCompoent, options, onSearch, field, index, ...props }) => {
  const optionComponents = (options || []).map((option, index) =>
    (

      <Option className='people-drop-down-option' key={`option${index}`} value={option.id}>
        <img src={option.image ? generateCroppedThumbnail(option.image, 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
        <Tooltip overlayClassName='drop-down-tooltip' placement='right' title={<p>{ option[ displayParam ] } ({utilityService.getSourceDetails(option['source'])} )</p>}>
          <p>{ option[ displayParam ] }</p>
        </Tooltip>
      </Option>
    ))

  const selectedValue = selected && selected.name ? (
    <React.Fragment>
      <img src={selected.image ? generateCroppedThumbnail(selected.image, 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
      <p>{ `${selected.name}` }</p>
    </React.Fragment>
  ) : undefined

  const onPersonSearch = (value) => {
    if (onSearch) { onSearch(value) }
  }

  const onBlur = () => {
    if (onSearch) { onSearch('') }
  }

  const onSelectOption = (id) => {
    if (onSearch) { onSearch('') }
    onOptionSelect(id, index)
  }

  return (

    <Select key={JSON.stringify(selected)}
      showSearch
      className='people-drop-down'
      allowClear
      suffixIcon={<ExpandedIcon />}
      clearIcon={<CrossIcon />}
      placeholder='Select person'
      value={selectedValue || undefined}
      onChange={onSelectOption}
      optionFilterProp={displayParam}
      filterOption={(input, option) => customFilter(input, option, options)}
      getPopupContainer={() => document.getElementById(parentCompoent)} {...props}
      onSearch={onPersonSearch} onBlur={onBlur}>
      { optionComponents }
    </Select>

  )
}

PersonDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** parent container id name of Dropdown. */
  parentCompoent: PropTypes.string,
  /** Selected value of Dropdown. */
  selected: PropTypes.object,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func
}

PersonDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default PersonDropDown
