import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'antd/dist/antd.css'
import './App.scss'
import AppRouterWrapper from './AppRouterWrapper'
import { Auth, I18n, Hub } from 'aws-amplify'
import { ConfirmSignIn, Greetings, withAuthenticator } from 'aws-amplify-react'
import { message } from 'antd'

// AppSync and Apollo libraries
import ApolloLogger from './components/ApolloLoggerLink'
// import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync'
// import { from } from 'apollo-link'
import QueryUserSettings from './graphQL/mfaSettings/checkUser2faStatus'
import MutationAddUserEvents from './graphQL/audit/addUserEvents'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'

// Config
import config from './constants/config'
import AuthService from './services/AuthService'
import LoggerService from './services/LoggerService'

// import bugsnag from '@bugsnag/js'
// import bugsnagReact from '@bugsnag/plugin-react'

// Components
import Login from './modules/authManager/Login'
import ForgotPassword from './modules/authManager/ForgotPassword'
import RequireNewPassword from './modules/authManager/RequireNewPassword'
import ChangePassword from './modules/authManager/ChangePassword'
import DisableMfa from './modules/authManager/DisableMfa'
import userMessages from './constants/messages'
import ConfirmModal from './components/ui/feedback/ConfirmModal'
import { utilityService } from './services/UtilityService'
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

NProgress.configure({ showSpinner: false })

const history = createBrowserHistory()

I18n.setLanguage(config.PROJECT_LANGUAGE)
I18n.putVocabularies(config.AUTH_SCREEN_LABELS)

/// / dummy commit - UAT 16-01-2023
// Bugsnag settings
// const bugsnagClient = bugsnag({
//   apiKey: config.BUGSNAG_KEY,
//   appVersion: config.VERSION,
//   notifyReleaseStages: ['production'],
//   releaseStage: config.RELEASE_STAGE
// })
// bugsnagClient.use(bugsnagReact, React)

// client intialization for ingest manager
const IngestClient = () => {
  const awsConfig = {
    ...window.config,
    aws_appsync_graphqlEndpoint: window.config.active_stack === 'G'
      ? window.config.api_endpoint_g
      : window.config.api_endpoint_b
  }
  const auth = {
    type: window.config.aws_appsync_authenticationType,
    jwtToken: async () => {
      try {
        const session = await Auth.currentSession()
        return session ? session.getIdToken().getJwtToken() : undefined
      } catch (error) {
        Auth.signOut()
          .then(data => {
            AuthService.clearUserDetails()
          })
          .catch(err => console.log(err))
      }
    }
  }
  const config = {
    url: awsConfig.ingest_endpoint,
    region: awsConfig.aws_appsync_region,
    auth,
    complexObjectsCredentials: () => Auth.currentCredentials()
  }
  const httpLink = createHttpLink({ uri: config.url })
  const ingestManagerClient = new ApolloClient({
    link: ApolloLink.from([
      ApolloLogger,
      createAuthLink(config),
      split(op => {
        const { operation } = op.query.definitions[0]
        if (operation === 'subscription') {
          return false
        }
        return true
      },
      httpLink,
      createSubscriptionHandshakeLink(
        {
          auth,
          region: awsConfig.aws_appsync_region,
          url: awsConfig.aws_appsync_graphqlEndpoint
        },
        httpLink
      )
      )
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true
    // defaultOptions: {
    //   watchQuery: {
    //     fetchPolicy: 'cache-and-network'
    //   }
    // }
  })
  return ingestManagerClient
}
// dummy commit for uat rollback
// client intialization for app manager
const appClient = () => {
  const awsConfig = {
    ...window.config,
    aws_appsync_graphqlEndpoint: window.config.active_stack === 'G'
      ? window.config.api_endpoint_g
      : window.config.api_endpoint_b
  }
  const auth = {
    type: awsConfig.aws_appsync_authenticationType,
    jwtToken: async () => {
      try {
        const session = await Auth.currentSession()
        return session ? session.getIdToken().getJwtToken() : undefined
      } catch (error) {
        Auth.signOut()
          .then(data => {
            AuthService.clearUserDetails()
          })
          .catch(err => console.log(err))
      }
    }
  }
  const config = {
    url: awsConfig.app_manager_endpoint,
    region: awsConfig.aws_appsync_region,
    auth,
    complexObjectsCredentials: () => Auth.currentCredentials()
  }
  const httpLink = createHttpLink({ uri: config.url })
  const appClientManager = new ApolloClient({
    link: ApolloLink.from([
      ApolloLogger,
      createAuthLink(config),
      split(op => {
        const { operation } = op.query.definitions[0]
        if (operation === 'subscription') {
          return false
        }
        return true
      },
      httpLink,
      createSubscriptionHandshakeLink(
        {
          auth,
          region: awsConfig.aws_appsync_region,
          url: awsConfig.aws_appsync_graphqlEndpoint
        },
        httpLink
      )
      )
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true
    // defaultOptions: {
    //   watchQuery: {
    //     fetchPolicy: 'cache-and-network'
    //   }
    // }
  })
  return appClientManager
}

// client initialization for apple news
const appleClient = () => {
  const awsConfig = {
    ...window.config,
    aws_appsync_graphqlEndpoint: window.config.active_stack === 'G'
      ? window.config.apple_news_endpoint_g
      : window.config.apple_news_endpoint_b
  }
  const auth = {
    url: awsConfig.url,
    region: window.config.aws_appsync_region,
    auth: {
      type: window.config.aws_appsync_authenticationType,
      jwtToken: async () => {
        try {
          const session = await Auth.currentSession()
          return session ? session.getIdToken().getJwtToken() : undefined
        } catch (error) {
          Auth.signOut()
            .then(data => {
              AuthService.clearUserDetails()
            })
            .catch(err => console.log(err))
        }
      }
    },
    complexObjectsCredentials: () => Auth.currentCredentials()
  }
  const config = {
    url: awsConfig.app_manager_endpoint,
    region: awsConfig.aws_appsync_region,
    auth,
    complexObjectsCredentials: () => Auth.currentCredentials()
  }
  const httpLink = createHttpLink({ uri: config.url })
  const appleClientManager = new ApolloClient({
    link: ApolloLink.from([
      ApolloLogger,
      createAuthLink(config),
      split(op => {
        const { operation } = op.query.definitions[0]
        if (operation === 'subscription') {
          return false
        }
        return true
      },
      httpLink,
      createSubscriptionHandshakeLink(
        {
          auth,
          region: awsConfig.aws_appsync_region,
          url: awsConfig.aws_appsync_graphqlEndpoint
        },
        httpLink
      )
      )
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true
    // defaultOptions: {
    //   watchQuery: {
    //     fetchPolicy: 'cache-and-network'
    //   }
    // }
  })
  return appleClientManager
}
class App extends Component {
  constructor (props) {
    super(props)
    let ifVersionChange = config.VERSION && window.config.application_version && window.config.application_version !== config.VERSION
    ifVersionChange = window.config.application_version && window.config.application_version.includes(config.LOCAL_URL) ? false : ifVersionChange // TBR checking for development (DO NOT SHOW)
    const projectId = utilityService.getCurrentProject() // TBR checking for development (DO NOT SHOW)
    // forceMFA = window.config.forceMFA

    this.state = {
      isEdit: false,
      showWarning: false,
      forceSwitch: false,
      isChangePassword: false,
      isDisableMfaVisible: false,
      isFormSuccess: false,
      isConfirmReloadVisible: ifVersionChange,
      projectId,
      isPartnerEdit: false,
      isOfferEdit: false
    }
    AuthService.setUserDetails()
    LoggerService.info('flow', 'App initialized')
    this.getUserDetails(props)
  }

  componentDidMount () {
    const favicon = document.getElementById('favicon')
    const url = window.location.href
    if (url.includes('hyperion')) {
      favicon.href = '/hyperion_favicon.ico'
    } else if (url.includes('subhub')) {
      favicon.href = '/subhub_favicon.ico'
    } else if (url.includes('uat') || url.includes('qa')) {
      favicon.href = '/favicon_gray.ico'
    }
  }
  getUserDetails = async ({ isEnabled }) => {
    // const userDetails = await AuthService.getUserDetails()
    const projectId = utilityService.getCurrentProject()
    const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
    if (authUser && authUser.attributes) {
      const { name, email } = authUser.attributes
      this.props.getUserMfaStatus({ user: email, project: projectId }).then(({ data }) => {
        if (!data.checkUserAccess.projectAccess) {
          message.error(`You don't have access to this to project`)
          this.onLogout()
        }
        // if (data.checkUserAccess.mfaStatus) {
        if ((name || email) && !(authUser.preferredMFA === 'NOMFA' && !data.checkUserAccess.mfaStatus)) {
          // if (userDetails && authUser.preferredMFA !== 'NOMFA') {
          // bugsnagClient.user = { name, email }
          console.log('email', email)
          this.setState({ email })
        } else {
          this.onLogout()
        }
        // }
      })
    }
  }

  onLogout = () => {
    Auth.signOut()
      .then(data => {
        AuthService.clearUserDetails()
        Hub.listen('auth', this)
      })
      .catch(err => console.log(err))
  }

  onChangeBucketStatus = (status) => {
    this.setState({ isEdit: status })
  }

  changeEditStatus = (status) => {
    const { isEdit } = this.state
    if (isEdit !== status) { this.setState({ isEdit: status }) }
    if (status === false) { this.setState({ showWarning: false }) }
  }

  moduleSwitch = () => {
    this.setState({ showWarning: true })
  }

  notifyParent = () => {
    if (this.state.isEdit) {
      this.setState({ forceSwitch: true, showWarning: false, isEdit: false }, () => {
        this.setState({ forceSwitch: false })
      })
    }
  }

  clearModuleChange = () => {
    this.setState({ forceClear: true, showWarning: false, isEdit: false }, () => {
      this.setState({ forceClear: false })
    })
  }

  changePassword = () => {
    this.setState({ isChangePassword: true })
  }

  disableMfa = (isDisableMfaVisible) => {
    this.setState({ isDisableMfaVisible })
  }

  onSuccess = async (newPassword, password) => {
    const user = await Auth.currentUserPoolUser()
    const project = utilityService.getCurrentProject()
    const browserAndOsDetails = utilityService.getBrowserDetails()
    Auth.changePassword(user, password, newPassword)
      .then(data => {
        const variables = { user: user.username, userEvent: 'User Password Changed', project, userAgent: browserAndOsDetails }
        this.props.addUserEvents(variables).then(({ data }) => {
        }, error => {
          utilityService.handleError(error)
        })
        message.success(userMessages.AUTH_PASSWORD_SET_SUCCESS)
        this.setState({ isChangePassword: false, isFormSuccess: true })
      })
      .catch(err => {
        const variables = { user: user.username, userEvent: 'User Password Change Failed', project, userAgent: browserAndOsDetails, errorMessage: err.message }
        this.props.addUserEvents(variables).then(({ data }) => {
        }, error => {
          utilityService.handleError(error)
        })
        this.setState({ isFormSuccess: true })
        message.error(err.message)
      })
  }

  onCancel = () => {
    this.setState({ isChangePassword: false, isFormSuccess: true })
  }

  onConfirmReload = () => {
    window.location.reload(false)
  }

  changePartnerEditStatus = (status) => {
    const { isPartnerEdit } = this.state
    if (isPartnerEdit !== status) { this.setState({ isPartnerEdit: status }) }
    if (status === false) { this.setState({ showWarning: false }) }
  }

  changeOfferEditStatus = (status) => {
    const { isOfferEdit } = this.state
    if (isOfferEdit !== status) { this.setState({ isOfferEdit: status }) }
    if (status === false) { this.setState({ showWarning: false }) }
  }

  render () {
    const { isEdit, forceSwitch, forceClear, email, showWarning, isChangePassword, isFormSuccess, isConfirmReloadVisible, isDisableMfaVisible, projectId } = this.state
    return (
      <>
        {isChangePassword ? <ChangePassword isChangePassword onSuccess={this.onSuccess} onCancel={this.onCancel} isFormSuccess={isFormSuccess} />
          : isDisableMfaVisible ? <DisableMfa onBackClick={() => this.disableMfa(false)} />
            : (
              <Router history={history}>
                <AppRouterWrapper
                  userId={email}
                  showWarning={showWarning}
                  forceClear={forceClear}
                  forceSwitch={forceSwitch}
                  isEdit={isEdit}
                  history={history}
                  moduleSwitch={this.moduleSwitch}
                  notifyParent={this.notifyParent}
                  clearModuleChange={this.clearModuleChange}
                  onChangeBucketStatus={this.onChangeBucketStatus}
                  changeEditStatus={this.changeEditStatus}
                  disableMfa={this.disableMfa}
                  changePassword={this.changePassword}
                  changePartnerEditStatus={this.changePartnerEditStatus}
                  changeOfferEditStatus={this.changeOfferEditStatus}
                  IngestClient={IngestClient()}
                  appClient={appClient()}
                  appleClient={appleClient()}
                  project={projectId} />
                {/* project={'hyperion'} /> */}
              </Router>
            )
        }
        <ConfirmModal
          isVisible={isConfirmReloadVisible}
          title={'Version Mismatch'}
          message={'You`re running an old version of vcms. Please reload to continue'}
          isCancelButtonInvisible
          rightButtonText={'Reload'}
          handleSubmit={this.onConfirmReload} />
        </>
    )
  }
}

const AppWithApollo = withApollo(compose(
  graphql(
    QueryUserSettings,
    {
      options: () => {
        const project = utilityService.getCurrentProject()
        return {
          fetchPolicy: 'network-only',
          variables: { user: '', project }
        }
      },
      props: (props) => {
        return {
          getUserMfaStatus: (user) => {
            return props.data.refetch(user)
          }
        }
      }
    }
  ),
  graphql(
    MutationAddUserEvents,
    {
      props: (props) => ({
        addUserEvents: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(App))

const AppWithAuth = withAuthenticator(AppWithApollo, false, [
  <Greetings />,
  <Login />,
  <ConfirmSignIn />,
  <ForgotPassword />,
  <RequireNewPassword />
], null)

export default AppWithAuth
