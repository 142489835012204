import React from 'react'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import { generateCroppedThumbnail } from '../../../util/util'
import AdminOriginalData from './AdminOriginalData'
import './../ui.style.scss'

class TeamOriginalData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  getSelectedData = (selectedTeam) => {
    const data = [
      {
        key: 'Official Name',
        value: selectedTeam.officialName ? selectedTeam.officialName : '-',
        isLeft: true
      },
      {
        key: 'Name',
        value: selectedTeam.title ? selectedTeam.title : '-',
        isLeft: false
      },
      {
        key: 'Short Name',
        value: selectedTeam.shortName ? selectedTeam.shortName : '-',
        isLeft: true
      },
      {
        key: 'ID',
        value: selectedTeam.id ? selectedTeam.id : '-',
        isLeft: true
      },
      {
        key: 'Code',
        value: selectedTeam.code ? selectedTeam.code : '-',
        isLeft: false
      },
      {
        key: 'Opta Id',
        value: selectedTeam.externalId ? selectedTeam.externalId : '-',
        isLeft: false
      },
      {
        key: 'Opta Legacy Id',
        value: selectedTeam.opId ? selectedTeam.opId : '-',
        isLeft: true
      },
      // {
      //   key: 'Venue Name',
      //   value: selectedTeam.venueName,
      //   isLeft: true
      // },
      {
        key: 'Founded',
        value: selectedTeam.founded ? selectedTeam.founded : '-',
        isLeft: false
      },
      {
        key: 'Country',
        value: selectedTeam.country ? selectedTeam.country : '-',
        isLeft: true
      },
      // {
      //   key: 'Venue Id',
      //   value: selectedTeam.venueId,
      //   isLeft: false
      // },
      {
        key: 'Country External Id',
        value: selectedTeam.countryExternalId ? selectedTeam.countryExternalId : '-',
        isLeft: false
      },
      {
        key: 'City',
        value: selectedTeam.city ? selectedTeam.city : '-',
        isLeft: true
      },
      {
        key: 'Postal Address',
        value: selectedTeam.postalAddress ? selectedTeam.postalAddress : '-',
        isLeft: false
      },
      {
        key: 'Address Zip',
        value: selectedTeam.addressZip ? selectedTeam.addressZip : '-',
        isLeft: true
      },
      {
        key: 'Source',
        value: selectedTeam.source ? _.capitalize(selectedTeam.source) : '-',
        isLeft: false
      },
      {
        key: 'Active',
        value: selectedTeam.status ? (selectedTeam.status === 'active' ? 'Yes' : 'No') : '-',
        isLeft: true
      }
    ]
    return data
  }

  render () {
    // const { selectedTeam, homeJersey, awayJersey } = this.props
    const { selectedTeam } = this.props
    const selectedData = this.getSelectedData(selectedTeam)
    return (
      <div className='team-data'>
        <div className='team-img'>
          <img src={selectedTeam.media && selectedTeam.media.length && selectedTeam.media[ 0 ] ? generateCroppedThumbnail(selectedTeam.media[0], 70, 70, '1:1') : require('../../../assets/images/avatar-team-icon.png')} />
        </div>
        <div className='data'>
          <AdminOriginalData selectedData={selectedData} />
          {/* <div className='team-image-horizontal'>
            { homeJersey ? <div className='team'>
              <label> Home </label>
              <div className='image'>
                <img src={homeJersey ? generateCroppedThumbnail(homeJersey, 150, 150, '1:1') : require('../../../assets/images/avatar-team-icon.png')} />
              </div>
            </div> : null}
            { awayJersey ? <div className='team'>
              <label> Away </label>
              <div className='image'>
                <img src={awayJersey ? generateCroppedThumbnail(awayJersey, 150, 150, '1:1') : require('../../../assets/images/avatar-team-icon.png')} />
              </div>
            </div> : null}
          </div> */}
        </div>
      </div>
    )
  }
}

TeamOriginalData.propTypes = {

}

TeamOriginalData.defaultProps = {
}

export default TeamOriginalData
