import gql from 'graphql-tag'

export default gql(`
    mutation($contents: [UpdatePublishRuleInput],  $parentAssetId: ID $project: String) {
        batchUpdatePublishRule(
          input: $contents, 
          parentAssetId: $parentAssetId
          project: $project
        ) {
            id
            assetId
            group{
              id
            }
            isEnabled
            publishEndDate
            publishStartDate
          }
    }`
)
