import React from 'react'
// import PropTypes from 'prop-types'

import FilterInput from '../inputs/FilterInput'
import SortInput from '../inputs/SortInput'
import { utilityService } from './../../../../services/UtilityService'
import BucketAppConfigList from './BucketAppConfigList'

class AddBucketContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bucketSearchString: '',
      isClearHistoryFilter: false,
      bucketSort: {
        value: 'createdAt',
        order: 'desc'
      }
    }
  }
  onChangeFilter = (value) => {
    const sortData = {
      value: 'relevance',
      order: 'desc'
    }
    if (value) {
      this.onSortChange(sortData, true)
    }
    this.setState({ bucketSearchString: value })
  }

  onChangeFilterSearch = value => {
    this.setState({ bucketSearchString: value })
  }

  changeFilterValue = (filter) => {
    let newFilter = { }
    newFilter = utilityService.getFormattedFilter(filter)
    this.bucketFilterObj = filter
    this.setState({ isSearching: true, bucketFilter: newFilter })
  }

    onSortChange = (sortData, isForced) => {
      this.setState({ bucketSort: sortData })
    }

    render () {
      const { isClearBucketFilter, bucketSearchString, bucketSort, bucketFilter } = this.state
      const { project, handleBucketSelection, selectedBucket } = this.props
      return <div className='add-bucket-container' id='bucket-sidebar'>
        <div className={`add-bucket-container-top`}>
          <div className='filter'>
            <FilterInput
              searchString={bucketSearchString}
              onChangeSearchInput={this.onChangeFilter}
              isClearFilter={isClearBucketFilter}
              filterType={'BucketManager'}
              changeFilter={this.changeFilterValue}
              placement='rightTop'
              bucketAssetFilter={this.bucketFilterObj}
              project={project}
            />
          </div>

          <div className='sort'>
            <SortInput type='BucketManager' selectedOrder={bucketSort.order} selectedSort={bucketSort.value} onSortChange={this.onSortChange} project={project} />
          </div>
          <BucketAppConfigList searchString={bucketSearchString} sort={bucketSort} filterVal={bucketFilter} project={project} handleBucketSelection={handleBucketSelection} selectedBucket={selectedBucket} />
        </div>
      </div>
    }
}

AddBucketContainer.propTypes = {

}

AddBucketContainer.defaultProps = {
}

export default AddBucketContainer
