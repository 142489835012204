import gql from 'graphql-tag'

export default gql(`
    mutation(
        $offerId: ID
        $name: String
        $startDate: AWSDateTime
        $endDate: AWSDateTime
        $currency: String
        $displayPrice: String
        $wholeSalePrice: String
        $rrpIncGst: String
        $rrpExcGst: String
        $gstAmount: String
        $promoId: String
        $customMeta: [MetaFieldInput]
        $project: String
   ) {
        createPrice(
            offerId: $offerId,
            project: $project
            input: {
            name: $name
            startDate: $startDate
            endDate: $endDate
            currency: $currency
            displayPrice: $displayPrice
            wholeSalePrice: $wholeSalePrice
            rrpIncGst: $rrpIncGst
            rrpExcGst: $rrpExcGst
            gstAmount: $gstAmount   
            promoId: $promoId   
            customMeta: $customMeta  
        }

        ) {
            id
            name
            startDate
            endDate
            currency
            displayPrice
            wholeSalePrice
            rrpIncGst
            rrpExcGst
            gstAmount
            promoId
            customMeta{
                config{
                    id
                    name
                    type
                    section
                    default
                }
                value
            }
        }
    }`
)
