import gql from 'graphql-tag'

export default gql(`
    mutation triggerFasterSync($action: String $project: String $match: MatchInput ) {
        triggerFasterSync(
            input: {
                action: $action
                match: $match
            }
            project: $project
        ) {
            id
            status
        }
    }`
)
