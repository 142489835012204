import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose, isEmpty } from 'lodash'

import Input from '../inputs/Input'
import GeneralFieldComponent from '../../dataDisplay/GeneralFieldComponentAssetType'
import AssetTypeOptionalList from '../../dataDisplay/AssetTypeOptionalList'
import LoadingButton from '../../general/buttons/LoadingButton'
import CropWhiteIcon from '../../general/icons/CropWhiteIcon'
import AddIcon from '../../general/icons/AddIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import Switch from '../../dataEntry/inputs/Switch'
import MutationCreateAssetType from '../../../../graphQL/admin/asset/createAssetType'
import MutationUpdateAssetType from '../../../../graphQL/admin/asset/updateAssetType'
import QueryListAssetType from '../../../../graphQL/admin/asset/listAssetTypes'
import MutationUpdateMedia from '../../../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from '../../../../graphQL/content/updateMediaSettings'
import ListMetaSections from '../../../../graphQL/asset/listMetaSections'

import { generateCroppedThumbnail } from './../../../../util/util'
import AssetCategoryTypeDropDown from './../inputs/AssetCategoryTypeDropDown'
import './../../ui.style.scss'

import { utilityService } from '../../../../services/UtilityService'
const { TabPane } = Tabs

const optionalInitialData = [
  {
    id: 1,
    title: 'Author Field',
    isEnabled: false,
    key: 'authorSection'
  },
  {
    id: 2,
    title: 'Content Section',
    isEnabled: false,
    key: 'contentSection'
  },
  {
    id: 3,
    title: 'Video Section',
    isEnabled: false,
    key: 'videoSection'
  },
  {
    id: 4,
    title: 'Program Section',
    isEnabled: false,
    key: 'programSection'
  },
  {
    id: 5,
    title: 'Match Field',
    isEnabled: false,
    key: 'matchSection'
  }
]

const configInitialData = [
  {
    id: 1,
    title: 'Auto Trigger',
    isEnabled: false,
    key: 'isLveToVodSupported'
  },
  {
    id: 2,
    title: 'Match Link',
    isEnabled: false,
    key: 'isMatchRelated'
  },
  {
    id: 3,
    title: 'Auto Publish',
    isEnabled: false,
    key: 'autoPublish'
  }
]

const assetCategoryType = ['GENERIC', 'SERIES', 'SEASON', 'EPISODE']

const parentCompoent = 'create-input'

class CreateAssetTypeModal extends Component {
    state = {
      isLoading: false,
      showDraftCrop: false,
      enable: false,
      activeKey: '1',
      optionalData: _.cloneDeep(optionalInitialData),
      configData: _.cloneDeep(configInitialData),
      icon: null,
      draftIcon: null,
      isNoDuplicate: false,
      streamField: [],
      details: {
        title: '',
        assetTypeCategory: 'GENERIC',
        authorSection: false,
        contentSection: false,
        videoSection: false,
        programSection: false,
        matchSection: false,
        creditSection: false,
        parentalRatingSection: false,
        isLveToVodSupported: false,
        isMatchRelated: false,
        autoPublish: false,
        isActive: true,
        isDefault: false,
        isBoolEnabled: false,
        entertainmentMetadata: false
      }
    }

    componentDidMount = () => {
      if (this.props.selectedType) {
        this.setState({
          details: _.cloneDeep(this.props.selectedType)
        })
      }
    }

    UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
      if (nextProps.selectedType && !_.isEqual(this.props.selectedType, nextProps.selectedType)) {
        this.setState({ details: _.cloneDeep(nextProps.selectedType) }, () => {
          let icon = nextProps.selectedType.icon
          let draftIcon = nextProps.selectedType.draftIcon
          const streamField = (cloneDeep(nextProps.selectedType.metaFields || [])).map((item) => {
            if (!nextProps.isEdit) {
              item.id = utilityService.makeRandomString(6)
            }
            item.section = item.section && (item.section.id || item.section)
            return item
          })
          if (!nextProps.isEdit && nextProps.selectedType) {
            let tempDetails = _.cloneDeep(nextProps.selectedType)
            tempDetails.autoPublish = false
            this.setState({ details: tempDetails, icon, draftIcon, streamField })
          } else {
            this.setState({ icon, draftIcon, streamField })
          }
        })
      } else if (!_.isEqual(this.props.icon, nextProps.icon)) {
        let icon = nextProps.icon
        this.setState({ icon })
      } else if (!_.isEqual(this.props.draftIcon, nextProps.draftIcon)) {
        let draftIcon = nextProps.draftIcon
        this.setState({ draftIcon, isBoolEnabled: true })
      }

      if (this.props.croppedUrl !== nextProps.croppedUrl) {
        this.setState({ isBoolEnabled: true })
      }

      if (this.props.croppedDraftUrl !== nextProps.croppedDraftUrl) {
        this.setState({ isBoolEnabled: true })
      }
      if (nextProps.isDeleteSuccess) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { details } = this.state
      const { value, name } = e.target

      if (name === 'name') {
        const regexExp = /^[A-Za-z0-9_-]+$/
        const isCheck = regexExp.test(value)
        if (isCheck || value === '') {
          details.title = value.toUpperCase()
          this.setState({ details, isBoolEnabled: true })
        }
        // const title = isCheck? value.toUpperCase() : ''
      }
    }

    handleCategoryTypeChange=(id) => {
      const { details } = this.state
      if (id) {
        details.assetTypeCategory = id
        this.setState({ details, isBoolEnabled: true })
      } else {
        details.assetTypeCategory = null
        this.setState({ details })
      }
    }

    checkEmptyValueListField = (streamField) => {
      let name = null
      streamField.forEach((item) => {
        if (item.type === 'LIST' && isEmpty(item.values)) {
          name = item.displayName
        }
      })

      return name
    }

    handleAddAssetType = () => {
      const { isEdit, isImageModified, onHistoryUpdate } = this.props
      const { details, icon, draftIcon, streamField } = this.state
      const checkEmptyValueListField = this.checkEmptyValueListField(streamField)
      if (checkEmptyValueListField) {
        message.warning(`Please enter default values for ${checkEmptyValueListField}`)
        return null
      }

      this.setState({ isLoading: true })
      const settings = (cloneDeep(icon.settings)).map((item) => {
        if (item.__typename) {
          delete (item.__typename)
        }
        return item
      })
      const draftSettings = cloneDeep(draftIcon.settings).map((item) => {
        if (item.__typename) {
          delete (item.__typename)
        }
        return item
      })
      const metaFields = _.cloneDeep(streamField).filter(item => item.displayName && item.type).map(item => {
        // item.displayName = item.name
        delete (item.name)
        // delete (item.id)
        item.id = item.id || utilityService.makeRandomString(6)
        delete (item.__typename)
        return item
      })

      if (metaFields.length !== streamField.length) {
        message.error('Incomplete meta fields')
        this.setState({ isLoading: false })
        return
      }

      if (isEdit) {
        // edit
        const newAssetType = {
          position: details.position,
          id: details.id,
          title: details.title,
          icon: icon.id,
          draftIcon: draftIcon.id,
          authorSection: details.authorSection,
          isLveToVodSupported: details.isLveToVodSupported,
          isMatchRelated: details.isMatchRelated,
          videoSection: details.videoSection,
          contentSection: details.contentSection,
          programSection: details.programSection,
          matchSection: details.matchSection,
          isActive: details.isActive,
          isDefault: details.isDefault,
          autoPublish: details.autoPublish,
          metaFields,
          entertainmentMetadata: !!details.entertainmentMetadata,
          creditSection: !!details.creditSection,
          parentalRatingSection: !!details.parentalRatingSection,
          assetTypeCategory: details.assetTypeCategory ? details.assetTypeCategory : 'GENERIC'
        }
        if (isImageModified) {
          this.props.updateImage(draftIcon.id, draftIcon.name, draftIcon.tags ? draftIcon.tags.map(item => item.key || item.id) : [], draftSettings)
          this.props.updateImage(icon.id, icon.name, icon.tags ? icon.tags.map(item => item.key || item.id) : [], settings).then((response) => {
            this.props.updateAssetType(newAssetType).then(response => {
              this.props.handleSubmit(response.data.updateAssetType)
              onHistoryUpdate()
            }, error => {
              this.setState({ isLoading: false })
              utilityService.handleError(error)
            })
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        } else {
          this.props.updateAssetType(newAssetType).then(response => {
            this.props.handleSubmit(response.data.updateAssetType)
            onHistoryUpdate()
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }
      } else {
        // create
        const newAssetType = {
          title: details.title,
          icon: icon.id,
          draftIcon: draftIcon.id,
          authorSection: details.authorSection,
          isLveToVodSupported: details.isLveToVodSupported,
          isMatchRelated: details.isMatchRelated,
          videoSection: details.videoSection,
          contentSection: details.contentSection,
          programSection: details.programSection,
          matchSection: details.matchSection,
          autoPublish: details.autoPublish,
          isActive: details.isActive,
          isDefault: false,
          position: this.props.maxPosition + 1,
          metaFields,
          entertainmentMetadata: !!details.entertainmentMetadata,
          creditSection: !!details.creditSection,
          parentalRatingSection: !!details.parentalRatingSection,
          assetTypeCategory: details.assetTypeCategory ? details.assetTypeCategory : 'GENERIC'
        }
        this.props.createAssetType(newAssetType).then(({ data }) => {
          const mediaId = data.createAssetType.icon.id
          const authorId = data.createAssetType.id
          const name = data.createAssetType.icon.name
          const tags = data.createAssetType.icon.tags

          const unPublishedIcon = data.createAssetType.draftIcon
          if (isImageModified) {
            this.props.updateImageSettings(mediaId, authorId)
            this.props.updateImageSettings(unPublishedIcon.id, authorId)
            this.props.updateImage(unPublishedIcon.id, unPublishedIcon.name, unPublishedIcon.tags ? unPublishedIcon.tags.map(item => item.key || item.id) : [], draftSettings)
            this.props.updateImage(mediaId, name, tags ? tags.map(item => item.key || item.id) : [], settings).then((response) => {
              this.props.handleSubmit(data.createAssetType)
              onHistoryUpdate()
            }, error => {
              this.setState({ isLoading: false })
              utilityService.handleError(error)
            })
          } else {
            this.props.handleSubmit(data.createAssetType)
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    reorder = (startIndex, endIndex) => {
      const { streamField } = this.state
      const result = Array.from(streamField)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      this.setState({ streamField: result, isBoolEnabled: true })
    }

    onCloseField = (index) => {
      const { streamField } = this.state
      // const index = streamField.findIndex(item => item.id === id)
      streamField.splice(index, 1)
      const duplicateItem = streamField.filter(item => {
        const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
        return tempIndex !== -1
      })
      const isNoDuplicate = !!duplicateItem.length
      this.setState({ streamField, isBoolEnabled: true, isNoDuplicate })
    }

    addFieldObjects = () => {
      const { streamField } = this.state
      const id = utilityService.makeRandomString(6)
      const newFieldObject = {
        displayName: '',
        type: '',
        isRequired: false,
        id
      }
      streamField.push(newFieldObject)
      this.setState({ streamField, isBoolEnabled: true })
    }

    isEnableSave = () => {
      this.setState({ enable: true })
    }

    showCrop = (e) => {
      e.stopPropagation()
      this.setState({ showCrop: true })
    }

    showDraftCrop = (e) => {
      e.stopPropagation()
      this.setState({ showDraftCrop: true })
    }

      showCropModal = (e) => {
        const { icon } = this.state
        e.stopPropagation()
        if (!_.isEmpty(icon)) {
          this.props.showCropModal(icon, false)
        } else {
          this.props.showImageModal(false)
        }
      }

      showDraftCropModal = (e) => {
        const { draftIcon } = this.state
        e.stopPropagation()
        if (!_.isEmpty(draftIcon)) {
          this.props.showCropModal(draftIcon, true)
        } else {
          this.props.showImageModal(true)
        }
      }

      hideCrop = () => {
        this.setState({ showCrop: false })
      }

      hideDraftCrop = () => {
        this.setState({ showDraftCrop: false })
      }

      removeImage = (e) => {
        e.stopPropagation()
        this.setState({ icon: null, isBoolEnabled: true })
        this.props.clearSelection()
      }

      removeDraftImage = (e) => {
        e.stopPropagation()
        this.setState({ draftIcon: null, isBoolEnabled: true })
        this.props.clearSelection(true)
      }
      onTabChange = (activeKey) => {
        this.setState({ activeKey })
      }

      onChangeData = (optionalData, status) => {
        const { details } = this.state
        details[optionalData] = status
        this.setState({ details, isBoolEnabled: true })
      }

      onChangeEnable = (isActive) => {
        const { details } = this.state
        details.isActive = isActive
        this.setState({ details, isBoolEnabled: true })
      }

      onChangeEntertainmentMetaData = (isActive) => {
        const { details } = this.state
        details.entertainmentMetadata = isActive
        this.setState({ details, isBoolEnabled: true })
      }

      onChangeStatus = (event, index) => {
        const { streamField } = this.state
        const { checked } = event.target
        streamField[index].isRequired = checked
        this.setState({ streamField, isBoolEnabled: true })
      }

      onChangeMetaName = (event, index) => {
        const { streamField } = this.state
        const { value } = event.target
        const duplicateItem = streamField.find(item => (item.displayName === value))
        streamField[index].displayName = value
        if (!_.isEmpty(duplicateItem) && duplicateItem.type === streamField[index].type) {
          message.error('Duplicate Meta Data')
          this.setState({ streamField, isBoolEnabled: false, isNoDuplicate: true })
        } else {
          this.setState({ streamField, isBoolEnabled: true, isNoDuplicate: false })
        }
      }

      handleDefaultValues = (items, index) => {
        const { streamField } = this.state
        streamField[index].values = items
        this.setState({ streamField, isBoolEnabled: true })
      }

      handleToolTip = (event, index) => {
        const { streamField } = this.state
        const { value } = event.target
        streamField[index].tooltip = value
        this.setState({ streamField, isBoolEnabled: true })
      }

      handleDisplayTitle = (event, index) => {
        const { streamField } = this.state
        const { value } = event.target
        streamField[index].displayTitle = value
        this.setState({ streamField, isBoolEnabled: true })
      }

      onOptionSelect = (value, index, option) => {
        const { streamField } = this.state
        streamField[index][option] = value || ''
        if (option === 'type') {
          streamField[index].values = []
        }

        const duplicateItem = streamField.find(item => item.displayName === streamField[index].displayName && item.id !== streamField[index].id)
        if (!_.isEmpty(duplicateItem) && duplicateItem.type === value) {
          message.error('Duplicate Meta Data')
          this.setState({ streamField, isBoolEnabled: false, isNoDuplicate: true })
        } else {
          this.setState({ streamField, isBoolEnabled: true, isNoDuplicate: false })
        }
      }

      enableButton = () => {
        this.setState({ isBoolEnabled: true })
      }

    resetState = () => {
      const state = {
        isLoading: false,
        enable: false,
        showCrop: false,
        showDraftCrop: false,
        activeKey: '1',
        isBoolEnabled: false,
        icon: null,
        draftIcon: null,
        isNoDuplicate: false,
        streamField: [],
        details: {
          title: '',
          assetTypeCategory: 'GENERIC',
          authorSection: false,
          contentSection: false,
          videoSection: false,
          programSection: false,
          matchSection: false,
          isLveToVodSupported: false,
          isMatchRelated: false,
          isActive: true,
          isDefault: false,
          creditSection: false,
          parentalRatingSection: false,
          entertainmentMetadata: false,
          autoPublish: false
        }
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedType, showImageModal, croppedUrl, croppedDraftUrl, isEdit, isSubmitDisabled, metaSectionLists } = this.props
      const { isLoading, showCrop, activeKey, optionalData, configData, details, isBoolEnabled, icon, showDraftCrop, draftIcon, streamField, isNoDuplicate } = this.state
      const disabled = isBoolEnabled && icon && draftIcon && details.title && details.assetTypeCategory
      const image = !_.isEmpty(icon) ? generateCroppedThumbnail(icon, 70, 70, '1:1') : undefined
      const draftImage = !_.isEmpty(draftIcon) ? generateCroppedThumbnail(draftIcon, 70, 70, '1:1') : undefined
      const defaultImage = require('../../../../assets/images/default-image-square.jpg')
      const currentImage = image || defaultImage
      const currentDraftImage = draftImage || defaultImage

      const title = <div className='title'>
        <span>{isEdit ? 'EDIT ASSET TYPE' : selectedType ? 'DUPLICATE ASSET TYPE' : 'NEW ASSET TYPE'}</span>
        <div className='enable-contaienr'>
          <span>{`Active: `}</span>
          <Switch isChecked={details.isActive} onChange={this.onChangeEnable} />
        </div>
      </div>
      const uniqDataArr = _.uniqBy((streamField || []), asset => [asset.type, asset.displayName].join())
      const isDuplicate = uniqDataArr.length !== (streamField || []).length
      return (
        <Modal
          className='general-modal create-channel-stream-modal create-asset-type-container'
          title={title}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddAssetType}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (!disabled || isDuplicate) || isSubmitDisabled || isNoDuplicate, loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='1050px'
          confirmLoading={isLoading}
        >
          <div className='create-asset-type'>
            <div className='create-input' id='create-input'>
              <div className='asset-icon'>
                <span>Published Icon<span className='mandatory'>*</span></span>
                <div className='avatar' onClick={showImageModal} onMouseLeave={() => this.hideCrop()}>
                  { showCrop

                    ? <div className='avatar-hover' onClick={(e) => this.showCropModal(e)}>
                      <span>{ image ? <CropWhiteIcon /> : <AddIcon /> }</span>
                    </div>
                    : <React.Fragment>
                      <div onMouseEnter={(e) => this.showCrop(e)} onMouseLeave={() => this.hideCrop()}>
                        <img size={64} className='user' src={croppedUrl || currentImage} />
                      </div>
                      { image ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e)} className='oval'>
                        <CrossIcon color='#FFFFFF' />
                      </div> : null}
                    </React.Fragment>
                  }
                </div>
              </div>

              <div className='asset-icon'>
                <span>Draft Icon<span className='mandatory'>*</span></span>
                <div className='avatar' onClick={() => showImageModal(true)} onMouseLeave={() => this.hideDraftCrop()}>
                  { showDraftCrop

                    ? <div className='avatar-hover' onClick={(e) => this.showDraftCropModal(e)}>
                      <span>{ draftImage ? <CropWhiteIcon /> : <AddIcon /> }</span>
                    </div>
                    : <React.Fragment>
                      <div onMouseEnter={(e) => this.showDraftCrop(e)} onMouseLeave={() => this.hideDraftCrop()}>
                        <img size={64} className='user' src={croppedDraftUrl || currentDraftImage} />
                      </div>
                      { draftImage ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeDraftImage(e)} className='oval'>
                        <CrossIcon color='#FFFFFF' />
                      </div> : null}
                    </React.Fragment>
                  }
                </div>
              </div>
              <div className='asset-config-container'>
                <div className='asset-input-container'>
                  <Input title='Asset Type Name' inputName='name' isRequired handleChange={this.handleChange} value={details.title} placeholder='Enter new asset type' limit={15} readOnly={!!selectedType && !!isEdit} />
                  <div className='input-field'>
                    <span>Asset Category Type<span className={`mandatory`}>*</span></span>
                    <AssetCategoryTypeDropDown
                      selectedValue={details && details.assetTypeCategory ? details.assetTypeCategory : (details.assetTypeCategory === null ? '' : assetCategoryType[0])}
                      className='recurrence-drop-down'
                      options={assetCategoryType || []}
                      valueParam='id'
                      displayParam='name'
                      onOptionSelect={this.handleCategoryTypeChange}
                      placeHolder='Select Category Type'
                      parent={parentCompoent}
                      disabled={false}
                      allowClear
                    />
                  </div>
                </div>
                <div className='meta-data-switch'>
                  <span>{'Entertainment Meta Data'}</span>
                  <Switch isChecked={details.entertainmentMetadata} onChange={this.onChangeEntertainmentMetaData} />
                </div>
              </div>
            </div>
            <Tabs onChange={this.onTabChange}>
              <TabPane tab='General Meta Data' key='1'>
                <GeneralFieldComponent
                  type={'meta'}
                  reorder={this.reorder}
                  onCloseField={this.onCloseField}
                  streamField={streamField}
                  isEnableSave={this.isEnableSave}
                  isTitleInvisible
                  columnTitle1={'Meta Data Field'}
                  columnTitle3={'Required'}
                  columnTitle2={'Field Type'}
                  placeHolder1='Enter Name'
                  placeHolder2='Select Type'
                  placeHolder3='Select Section'
                  onChangeStatus={this.onChangeStatus}
                  handleChange={this.onChangeMetaName}
                  handleToolTip={this.handleToolTip}
                  handleDisplayTitle={this.handleDisplayTitle}
                  handleDefaultValues={this.handleDefaultValues}
                  onOptionSelect={this.onOptionSelect}
                  nameParam='displayName'
                  metaSectionLists={metaSectionLists}
                  enableButton={this.enableButton}
                />
              </TabPane>
              <TabPane tab='Optional Section' key='2'>
                <AssetTypeOptionalList optionList={optionalData} onChange={this.onChangeData} details={details} isConfig={false} />
              </TabPane>

              <TabPane tab='Config Section' key='3'>
                <AssetTypeOptionalList optionList={configData} onChange={this.onChangeData} details={details} isConfig />
              </TabPane>
            </Tabs>
            {activeKey === '1' ? <LoadingButton
              type='primary'
              onClick={this.addFieldObjects}
              htmlType='submit'
              buttonText={'Add Meta Data'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={false}
            /> : ''}
          </div>
        </Modal>
      )
    }
}

CreateAssetTypeModal.propTypes = {
  /** visible file status of CreateAssetTypeModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateAssetTypeModal. */
  handleCancel: PropTypes.func
}

CreateAssetTypeModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateAssetType, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { createAssetType } }) => {
            const variables = { filter: null, limit: 20, nextToken: null, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAssetType, variables }))
            if (cacheData && cacheData.listAssetTypes && cacheData.listAssetTypes.items) {
              cacheData.listAssetTypes.items.push(createAssetType)
            }
            cache.writeQuery({
              query: QueryListAssetType,
              data: cacheData,
              variables
            })
          },
          refetchQueries: () => [{ query: QueryListAssetType,
            variables: { limit: 500,
              nextToken: null,
              project,
              filter: {
                isMatchRelated: true
              } } }]
        }
      },
      props: (props) => ({
        createAssetType: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateAssetType, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { updateAssetType } }) => {
            const variables = { filter: null, limit: 20, nextToken: null, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAssetType, variables }))
            if (cacheData && cacheData.listAssetTypes && cacheData.listAssetTypes.items) {
              const selectedIndex = cacheData.listAssetTypes.items.findIndex(item => item.id === updateAssetType.id)
              if (selectedIndex > -1) {
                cacheData.listAssetTypes.items[selectedIndex] = updateAssetType
              }
            }
            cache.writeQuery({
              query: QueryListAssetType,
              data: cacheData,
              variables
            })
          },
          refetchQueries: () => [{ query: QueryListAssetType,
            variables: { limit: 500,
              nextToken: null,
              project,
              filter: {
                isMatchRelated: true
              } } }]
        }
      },
      props: (props) => ({
        updateAssetType: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateMedia, {
      props: (props) => ({
        updateImage: (id, name, tags, settings) => {
          return props.mutate({
            variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateMediaSettings, {
      props: (props) => ({
        updateImageSettings: (id, assetId, settings) => {
          return props.mutate({
            variables: { id, assetId, settings }
          })
        }
      })
    }
  ), graphql(
    ListMetaSections,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { filter: {
            project: {
              eq: project
            }
          } }
        }
      },
      props: (props) => {
        const { data } = props
        let metaSectionLists = data.listMetaSections ? data.listMetaSections.items : []
        return {
          metaSectionLists,
          isLoading: data.loading || !data.listMetaSections,
          totalCount: data.listMetaSections ? data.listMetaSections.totalCount : 0
        }
      }
    }
  )
)(CreateAssetTypeModal))
