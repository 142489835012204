import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Divider, Select } from 'antd'
import _ from 'lodash'

import CreateProductionModal from './../other/CreateProductionModal'
import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

const Option = Select.Option
class ProductionStudioDropDown extends React.Component {
    state = {
      value: undefined,
      showAddProductionStudioModal: false,
      isSearchPresent: false,
      productionStudioSearch: ''
    }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside=() => {
      if (this.props.productionSearch !== null) {
        this.props.onProductionStudioSearch(null)
      }
    }

    showAddProductionStudioModal = () => {
      this.setState({ showAddProductionStudioModal: true })
    }

    hideAddProductionStudioModal = () => {
      this.setState({ showAddProductionStudioModal: false })
    }

    handleAddProductionStudio = (newProductionStudio) => {
      this.setState({ showAddProductionStudioModal: false })
      this.props.onOptionSelect(newProductionStudio)
    }

    onDropdownVisibleChange = (isOpen) => {
      if (!isOpen) { this.setState({ isSearchPresent: false }) }
    }

    // onProductionStudioSearch = (value) => {
    //   const { onSearch } = this.props
    //   if (onSearch) { onSearch(value) }
    // }

    // onBlur = () => {
    //   const { onSearch } = this.props
    //   if (onSearch) { onSearch('') }
    // }

    render () {
      const { showAddProductionStudioModal } = this.state
      const { parentComponent, listProductionStudio, value, project, onOptionSelect, displayParam, className, onProductionStudioSearch, productionSearch } = this.props
      let uniqueList = _.uniqBy(listProductionStudio, 'name')
      let optionComponents = (uniqueList || []).filter(item => !(value && value.length && value.includes(item.name))).map((option, index) => <Option label={option[displayParam]} key={option.id || index} value={option[displayParam]}>{option[displayParam]}<span>{utilityService.getSourceDetails(option['source'])}</span></Option>)
      const dropdownRender = menu => (
        <div>
          <div onMouseDown={(e) => {
            e.stopPropagation()
            this.showAddProductionStudioModal()
          }} className='custom-dropdown-container'>
            <div style={{ position: 'absolute', left: 0, marginLeft: '10px' }}>New Production Studio</div>
            <div style={{ position: 'absolute', right: 0, marginRight: '10px' }} > <Icon type='plus' /> </div>
          </div>
          <Divider dashed style={{ margin: 0 }} />
          { menu }
        </div>
      )

      return (

        <div className='productionstudio-multi-drop-down '>
          <Select
            dropdownRender={dropdownRender}
            className={className ? 'multi-drop-down ' + className : 'multi-drop-down'}
            allowClear
            mode='multiple'
            value={value}
            placeholder='Select Production Studios'
            getPopupContainer={() => document.getElementById(parentComponent)}
            onChange={onOptionSelect}
            showSearch
            onSearch={onProductionStudioSearch}
            optionLabelProp='label'
            // onBlur={this.onBlur}
          >
            { optionComponents }
          </Select>

          <CreateProductionModal
            isVisible={showAddProductionStudioModal}
            handleCancel={this.hideAddProductionStudioModal}
            handleSubmit={this.handleAddProductionStudio}
            title='NEW PRODUCTION STUDIO'
            module='ASSET_MANAGER'
            project={project}
            isFromAsset
            productionSearch={productionSearch}
          />
        </div>
      )
    }
}

ProductionStudioDropDown.propTypes = {
  /** Details of ProductionStudioList */
  listProductionStudio: PropTypes.array
}

ProductionStudioDropDown.defaultProps = {
  parentComponent: 'asset-container'
}

export default ProductionStudioDropDown
