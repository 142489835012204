import * as React from 'react'
import PropTypes from 'prop-types'
import AppContext from '../../AppContext'

import RecreateJobModal from '../../components/ui/feedback/RecreateJobModal'
import JobTableRow from './JobTableRow'
import JobTableGroup from './JobTableGroup'
import { utilityService } from './../../services/UtilityService'

import QueryJobList from './../../graphQL/jobs/listJobs'
import SubscriptionJobList from './../../graphQL/jobs/createJobSubscription'
import SubscriptionJobRecreate from './../../graphQL/jobs/recreateJobSubscription'
import MutationRecreateJob from './../../graphQL/jobs/recreateJob'
import { withRouter } from 'react-router-dom'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import InfiniteScroll from 'react-infinite-scroller'
import { Checkbox, Skeleton, Empty, message } from 'antd'
import moment from 'moment'

const ERROR_WARNING = 'Cannot select more than 10 jobs'

const JOB_STATES = [
  {
    vcmsState: 'QUEUED'
  },
  {
    vcmsState: 'TRIGGERED'
  },
  {
    vcmsState: 'PROCESSING'
  },
  {
    vcmsState: 'REGISTERED'
  },
  {
    vcmsState: 'INGEST'
  },
  {
    vcmsState: 'MC-PROCESSING'
  },
  {
    vcmsState: 'MP-PROCESSING'
  },
  {
    vcmsState: 'COMPLETE'
  },
  {
    vcmsState: 'PUBLISHED'
  },
  {
    vcmsState: 'ERROR'
  },
  {
    vcmsState: 'UNKNOWN'
  }
]

const parseData = (data, selectedGroup) => {
  const parsedData = data.map((item) => {
    return {
      jobType: {
        type: _.capitalize(item.type),
        isLive: item.jobType !== 'VOD_MEDIA',
        jobType: item.jobType,
        title: item.jobType === 'VOD_MEDIA' ? 'VOD' : 'Live2VOD'
      },
      jobId: item.vwmJobId,
      jobName: item.name,
      triggeredBy: item.createdBy,
      triggeredDate: item.createdAt ? moment(item.createdAt).format('DD MMM YYYY HH:mm') : '',
      assetTitle: item.asset ? item.asset.title : '',
      assetId: item.asset ? item.asset.id : '',
      channelName: item.channel ? item.channel.name : '',
      channelCode: item.channel ? item.channel.id : '',
      startEndTime: item.startTime && item.endTime ? `${moment(item.startTime).format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}` : '',
      endTime: moment(item.endTime),
      site: item.site ? `${item.site === 'PRI' ? 'Primary' : item.site === 'SEC' ? 'Secondary' : 'Multi'}` : '',
      matchName: item.asset && item.asset.match ? `${item.asset.match.homeTeam.officialName} v ${item.asset.match.awayTeam.officialName}` : '',
      matchDate: item.asset && item.asset.match && item.asset.match.matchDate ? moment(item.asset.match.matchDate).format('DD MMM YYYY, HH:mm') : '',
      matchOpid: item.asset && item.asset.match ? item.asset.match.opId : '',
      lastUdated: item.updatedAt ? moment(item.updatedAt).format('DD MMM YYYY HH:mm') : '',
      jobStatus: {
        status: item.status === 'PUBLISHED' ? 'COMPLETE' : item.status === 'UNDEFINED' ? 'UNKNOWN' : item.status,
        progress: item.progress,
        errorDetails: item.errorDesc,
        errorCode: item.jobStates && item.jobStates.length ? item.jobStates[item.jobStates.length - 1].errorCode : null
      },
      relatedJob: item.relatedJob ? item.relatedJob.vwmJobId : '',
      media: item.mediaId,
      priority: JOB_STATES.findIndex((state) => state.vcmsState === item.status) > -1 ? JOB_STATES.findIndex((state) => state.vcmsState === item.status) : 999,
      jobStates: item.jobStates,
      lastReceivedStatus: item.lastReceivedStatus ? item.lastReceivedStatus : item.status,
      homeTeam: item.asset && item.asset.match ? item.asset.match.homeTeam : '',
      awayTeam: item.asset && item.asset.match ? item.asset.match.awayTeam : '',
      id: item.id,
      duration: item.mediaId ? item.mediaId.duration : '',
      validations: item.validations || []
    }
  })
  const newData = _.uniqBy(parsedData, 'id')
  return selectedGroup ? _.sortBy(newData, [(o) => o.priority]) : newData
}

class JobTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isAllErrorJobSelected: false,
      selectedId: props.jobList && props.jobList.length ? props.jobList[0].id : '',
      selectedErrors: [],
      isVisibleRecreateModal: false,
      isRecreteLoading: false,
      isPaginating: false,
      selectedJobForRecreate: ''
    }
    this.scrollRef = undefined
    this.scrollRefParent = undefined
    this.count = 0
    if (props.jobList && props.jobList.length) { props.changeSelectedJob(props.jobList[0]) }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.selectedErrors, this.state.selectedErrors)) { this.setState({ selectedErrors: nextProps.selectedErrors, isAllErrorJobSelected: (!!(this.state.isAllErrorJobSelected && nextProps.selectedErrors && nextProps.selectedErrors.length)) }) }
    if (nextProps.jobList && nextProps.jobList.length && (!this.state.selectedId || _.isEmpty(nextProps.jobList.filter((item) => item.id === this.state.selectedId)))) {
      this.setState({ selectedId: nextProps.jobList[0].id }, this.props.changeSelectedJob(nextProps.jobList[0]))
    }
    if (nextProps.isAllErrorSelect) {
      const errorIdList = (nextProps.selectedErrors || []).map((item) => {
        return {
          jobId: item
        }
      })
      this.recreateJob(errorIdList)
    }
    if (nextProps.isRecreateModalVisible) {
      this.setState({ isVisibleRecreateModal: true, selectedJobForRecreate: this.state.selectedId })
    }
    const newJobDetails = nextProps.jobList.find((item) => item.id === this.state.selectedId)
    const odlJobDetails = this.props.jobList.find((item) => item.id === this.state.selectedId)
    if (newJobDetails && odlJobDetails && newJobDetails.lastReceivedStatus === 'COMPLETE' && odlJobDetails.lastReceivedStatus === 'MC-PROCESSING') {
      const { filterData, searchString, sort } = this.props
      const filter = utilityService.getFormattedFilter(filterData)
      const variables = utilityService.getFormattedJobFilter(filter, searchString, sort)
      this.props.refetchJobList(variables)
    }
    if (!_.isEqual(newJobDetails, odlJobDetails) && !_.isEmpty(newJobDetails)) {
      this.props.onChangeJobDetails(newJobDetails)
    }

    if (nextProps.jobList && !_.isEqual(nextProps.jobList, this.props.jobList)) {
      this.props.sendJobList(nextProps.jobList)
    }
  }

  componentDidMount () {
    this.props.subscribeToNewJob()
    this.props.subscribeToNewRecreatedJob()
  }

  checkForRecreate = (job) => {
    if (job.jobType && job.jobType.jobType) {
      if (job.jobType.jobType === 'VOD_MEDIA') {
        return true
      } else {
        return moment().diff(job.endTime, 'seconds') <= 86400
      }
    } else return false
  }

  markAllErrorJob = (event) => {
    const isChecked = event.target.checked
    const { jobList, onSelectErrors } = this.props
    let selectedErrors = []
    if (isChecked) {
      selectedErrors = jobList.filter((item) => item.jobStatus.status === 'ERROR' && this.checkForRecreate(item) && !item.relatedJob).map((item) => item.id)
      if (selectedErrors.length > 10) {
        message.warn(ERROR_WARNING)
        return
      }
    }
    this.setState({ isAllErrorJobSelected: isChecked, selectedErrors }, () => {
      onSelectErrors(selectedErrors)
    })
  }

  selectJob = (selectedId, event) => {
    const selectedDetails = (this.props.jobList || []).find((item) => item.id === selectedId)
    if (event.target.className !== 'ant-checkbox-input') { this.setState({ selectedId }, this.props.changeSelectedJob(selectedDetails)) }
  }

  addErrorToSelected = (id, event) => {
    let { selectedErrors } = this.state
    const index = selectedErrors.findIndex((item) => item === id)
    if (index >= 0) { selectedErrors.splice(index, 1) } else { selectedErrors.push(id) }
    event.stopPropagation()
    event.preventDefault()
    if (selectedErrors.length > 10) {
      message.warn(ERROR_WARNING)
      return
    }
    this.setState({ selectedErrors }, () => {
      this.props.onSelectErrors(selectedErrors)
    })
  }

  onRecreateJob = (event, selectedJobForRecreate) => {
    event.stopPropagation()
    event.preventDefault()
    this.setState({ isVisibleRecreateModal: true, selectedJobForRecreate })
  }

  getAttribute = (attribute, secondAttribute) => {
    const { jobList } = this.props
    const { selectedJobForRecreate } = this.state
    if (selectedJobForRecreate) {
      const selectedJob = jobList.find((item) => item.id === selectedJobForRecreate)
      return secondAttribute ? selectedJob[attribute][secondAttribute] : selectedJob[attribute]
    } else return null
  }

  handleCancel = () => {
    this.setState({ selectedJobForRecreate: '', isVisibleRecreateModal: false })
  }

  recreateJob = (jobs) => {
    this.setState({ isRecreteLoading: true })
    const jobForRecreate = jobs.map((item) => {
      return item.site ? {
        jobId: item.jobId,
        site: item.site
      } : { jobId: item.jobId }
    })
    this.props.retryVodJob(jobForRecreate).then(() => {
      this.markAllErrorJob({ target: { checked: false } })
      this.setState({ selectedJobForRecreate: '', isVisibleRecreateModal: false, isRecreteLoading: false })
      if (this.props.onAuditChange) { this.props.onAuditChange() }
    }, error => {
      this.markAllErrorJob({ target: { checked: false } })
      this.setState({ selectedJobForRecreate: '', isVisibleRecreateModal: false, isRecreteLoading: false })
      utilityService.handleError(error)
    })
  }

  renderGroupedTable = (jobList, selectedId, selectedGroup) => {
    const groupedItem = _.groupBy(jobList, (item) => {
      if (selectedGroup === 'jobStatus') {
        return item.jobStatus.status
      } else if (selectedGroup === 'jobType') {
        return item.jobType.title
      } else return item[selectedGroup]
    })
    let groupKeys = Object.keys(groupedItem)
    // groupKeys = groupKeys.filter((item) => item)
    if (selectedGroup !== 'jobStatus') {
      groupKeys = groupKeys.sort((a, b) => {
        if (a > b) {
          return 1
        }
        if (b > a) {
          return -1
        }
        return 0
      })
    }
    const index = groupKeys.findIndex(item => !item)
    if (index > -1) {
      groupKeys.splice(0, 1)
      groupKeys.push('')
    }
    let returnArr = []
    groupKeys.map((item, index) => {
      returnArr.push(<JobTableGroup
        groupHeader={item || ((selectedGroup === 'channelCode') ? 'UPLOAD' : '')}
        jobList={groupedItem[item]}
        selectedId={selectedId}
        selectedErrors={this.state.selectedErrors}
        onSelectJob={this.selectJob}
        onRecreateJob={this.onRecreateJob}
        addErrorToSelected={this.addErrorToSelected}
        key={index} />)
    })
    return returnArr
  }

  scrollHorizontally = (isNotParent, event) => {
    const scrollLeft = isNotParent ? this.scrollRef.scrollLeft : this.scrollRefParent.scrollLeft
    const ulta = `${!isNotParent ? 'true' : 'false'}${this.scrollRefParent.scrollLeft}${this.scrollRef.scrollLeft}`
    if (ulta === this.previousData) {
      this.count++
      if (this.count === 4) {
        this.count = 0
        return
      }
    }
    this.previousData = `${isNotParent ? 'true' : 'false'}${this.scrollRef.scrollLeft}${this.scrollRefParent.scrollLeft}`
    this.scrollRefParent.scrollLeft = scrollLeft
    this.scrollRef.scrollLeft = scrollLeft
    event.preventDefault()
    event.stopPropagation()
  }

  loadMoreList = () => {
    const { jobList, totalCount } = this.props
    if (totalCount === jobList.length) { return }
    this.setState({ isPaginating: true }, () => {
      this.props.getJobList(jobList.length).then(() => {
        this.setState({ isPaginating: false })
      })
    })
  }

  renderLoading = (isLoading, isPaginating) => {
    return <React.Fragment>
      <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isLoading && !isPaginating} />
      <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isLoading && !isPaginating} />
      <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isLoading && !isPaginating} />
      <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isLoading && !isPaginating} />
      <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isLoading && !isPaginating} />
      <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isLoading && !isPaginating} />
    </React.Fragment>
  }

  renderTableHeader = () => {
    const { isAllErrorJobSelected } = this.state
    return <div className='job-table-header'>
      <div className='job-type'><span>Job Type</span></div>
      <div className='job-id'><span>Asset ID</span></div>
      <div className='job-name'><span>Asset Title</span></div>
      {/* <div className='job-asset-id'><span>Job ID</span></div>
      <div className='job-asset'><span>Job Name</span></div> */}
      <div className='job-triggered-person'><span>Triggered By</span></div>
      <div className='job-triggered-date'><span>Triggered Date</span></div>
      {/* <div className='job-channel'><span>Channel Name</span></div> */}
      <div className='job-channel-code'><span>Channel</span></div>
      <div className='job-program'><span>Start/End Time</span></div>
      <div className='job-site'><span>Site</span></div>
      <div className='job-match'><span>Match</span></div>
      <div className='job-match-date'><span>Match Date</span></div>
      <div className='job-match-opid'><span>OPID</span></div>
      <div className='job-last-update'><span>Last Updated</span></div>
      <div className='jobs-status'><span>Job Status</span></div>
      <div className='jobs-duration'><span>Video Duration</span></div>
      <div className='job-check'>
        <Checkbox onChange={this.markAllErrorJob} checked={isAllErrorJobSelected} />
      </div>
    </div>
  }

  renderTableContent = (jobList, selectedId) => {
    const { selectedErrors } = this.state
    return (jobList || []).map((item) =>
      <JobTableRow
        job={item}
        selectedId={selectedId}
        selectedErrors={selectedErrors}
        onSelectJob={this.selectJob}
        onRecreateJob={this.onRecreateJob}
        addErrorToSelected={this.addErrorToSelected}
        key={item.id} />
    )
  }

  render () {
    const { jobList, selectedGroup, isLoading, totalCount } = this.props
    const { selectedId, isVisibleRecreateModal, isRecreteLoading, isPaginating } = this.state

    return <AppContext.Consumer>
      {({ permissions }) => {
        const userPermissions = permissions['JOB_MANAGER']
        const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
        return <div className='job-table-container'>
          {isLoading && !jobList.length ? this.renderLoading(isLoading, isPaginating) : <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreList}
            hasMore={totalCount > jobList.length}
            initialLoad={false}
            useWindow={false}
          >
            <div className='scroller-overlay job-table-scroller' onScroll={(event) => this.scrollHorizontally(true, event)} ref={(ref) => { this.scrollRef = ref }}>
              <div className='job-table-row' />
            </div>
            <div className='job-table-scroller' onScroll={(event) => this.scrollHorizontally(false, event)} ref={(ref) => { this.scrollRefParent = ref }}>
              {this.renderTableHeader()}
              {selectedGroup ? this.renderGroupedTable(jobList, selectedId, selectedGroup) : this.renderTableContent(jobList, selectedId)}
              {jobList && jobList.length ? <RecreateJobModal
                isVisible={isVisibleRecreateModal}
                title={this.getAttribute('jobName')}
                handleCancel={this.handleCancel}
                handleSubmit={this.recreateJob}
                isLoading={isRecreteLoading}
                isLive2VOD={isVisibleRecreateModal ? this.getAttribute('jobType', 'isLive') : true}
                isSubmitDisabled={isCreateDisabled}
                jobId={this.getAttribute('id')} /> : ''}
            </div>
          </InfiniteScroll>}
          {isLoading || (jobList && jobList.length) ? '' : <Empty description={'No Jobs'} />}
          <Skeleton active title={false} paragraph={{ rows: 1 }} loading={isPaginating} />
        </div>
      }}
    </AppContext.Consumer>
  }
}

JobTable.propTypes = {
  /** List of jobs */
  jobList: PropTypes.any,
  /** List of error jobs selected for recreate */
  selectedErrors: PropTypes.array,
  /** Function to be called on selection of jobs */
  changeSelectedJob: PropTypes.func,
  /** Selected group name */
  selectedGroup: PropTypes.string,
  /** Filter for job table */
  filterData: PropTypes.array,
  /** Boolean for triggering batch recreate job */
  isAllErrorSelect: PropTypes.bool,
  /** Boolean for showing recreate error job modal */
  isRecreateModalVisible: PropTypes.bool,
  /** Job search text */
  searchString: PropTypes.string,
  /** Sort order */
  sort: PropTypes.object
}

export default withApollo(compose(
  graphql(
    QueryJobList,
    {
      options: (props) => {
        const { filterData, searchString, sort } = props
        const filter = utilityService.getFormattedFilter(filterData)
        const variables = utilityService.getFormattedJobFilter(filter, searchString, sort)
        return {
          variables,
          fetchPolicy: 'cache-and-network'
        }
      },
      props: (props) => {
        const { data } = props
        return {
          jobList: parseData(data && data.listVwmJobs && data.listVwmJobs.items && data.listVwmJobs.items.length ? data.listVwmJobs.items : [], props.ownProps.selectedGroup),
          isLoading: data.loading,
          totalCount: data && data.listVwmJobs ? data.listVwmJobs.totalCount : 0,
          getJobList: (page) => {
            return data.fetchMore({
              variables: {
                offset: page
              },
              fetchPolicy: 'cache-and-network',
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listVwmJobs.items, ...fetchMoreResult.listVwmJobs.items ]
                if (prev && prev.listVwmJobs) {
                  prev.listVwmJobs.items = newList
                  prev.listVwmJobs.totalCount = fetchMoreResult.listVwmJobs.totalCount
                }
                return prev
              }
            })
          },
          refetchJobList: (variables) => {
            return props.data.refetch(variables)
          },
          subscribeToNewJob: () => {
            return props.data.subscribeToMore({
              document: SubscriptionJobList,
              updateQuery: (prev, { subscriptionData: { data: { newVwmJobCreated } } }) => {
                if (!newVwmJobCreated) return prev
                if (prev && prev.listVwmJobs) {
                  const newList = [newVwmJobCreated, ...(prev.listVwmJobs.items || []).filter(job => job.id !== newVwmJobCreated.id)]
                  prev.listVwmJobs.items = newList
                  prev.listVwmJobs.totalCount = prev.listVwmJobs.totalCount + 1
                }
                return prev
              }
            })
          },
          subscribeToNewRecreatedJob: () => {
            return props.data.subscribeToMore({
              document: SubscriptionJobRecreate,
              updateQuery: (prev, { subscriptionData: { data: { vwmJobRetried } } }) => {
                if (!vwmJobRetried) return prev
                if (prev && prev.listVwmJobs) {
                  const newList = [...vwmJobRetried, ...prev.listVwmJobs.items]
                  prev.listVwmJobs.items = newList
                  prev.listVwmJobs.totalCount = prev.listVwmJobs.totalCount + 1
                }
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationRecreateJob,
    {
      options: (props) => {
        const { filterData, searchString, sort } = props
        const filter = utilityService.getFormattedFilter(filterData)
        const variables = utilityService.getFormattedJobFilter(filter, searchString, sort)
        return {
          // refetchQueries: () => [{ query: QueryJobList, variables, fetchPolicy: 'network-only' }],
          update: (cache, { data: { retryVodJob } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryJobList, variables }))
            if (cacheData && cacheData.listVwmJobs && cacheData.listVwmJobs.items) {
              retryVodJob.map((item) => {
                const index = cacheData.listVwmJobs.items.findIndex((innerItem) => innerItem.id === item.parentJob)
                cacheData.listVwmJobs.items[index].relatedJob = {
                  id: item.id,
                  vwmJobId: item.vwmJobId,
                  __typename: 'LiveToVodJobStatus' }
                return item
              })
              // cacheData.listVwmJobs.items = [...cacheData.listVwmJobs.items]
              // cacheData.listVwmJobs.items = [...retryVodJob, ...cacheData.listVwmJobs.items]
            }
            cache.writeQuery({
              query: QueryJobList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        retryVodJob: (jobIdList) => {
          return props.mutate({
            variables: { jobIdList }
          })
        }
      })
    }
  )
)(withRouter(JobTable)))
