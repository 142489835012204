import gql from 'graphql-tag'

export default gql(`
    query listMetaFieldConfigs($section: String $project: String){
        listMetaFieldConfigs(
            section: $section 
            project: $project
        ) {
            id
            name
            type
            section
            default
            usageCount
        }
}`)
