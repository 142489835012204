import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { withRouter } from 'react-router-dom'
import { Skeleton, Empty, message } from 'antd'
import NProgress from 'nprogress'
import PropTypes from 'prop-types'

import OfferListCell from './../../components/ui/dataDisplay/OfferListCell'
import { generateCroppedThumbnail } from './../../util/util'
import { utilityService } from './../../services/UtilityService'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import OfferButton from '../../components/ui/general/buttons/offerCreateButton'
import LoggerService from './../../services/LoggerService'
import userMessages from './../../constants/messages'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import GetOffersList from './../../graphQL/partner/getOfferList'
import MutationCreateOffer from './../../graphQL/partner/createOfferDetails'
import QueryGetPartnerDetails from './../../graphQL/partner/getPartnerDetails'
import AppContext from '../../AppContext'

const width = ['calc(100% - 100px)', '50%', '100%']

class OfferList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      offerList: [],
      isLoading: true,
      isPaginating: false
    }
  }

    componentDidMount=() => {
      if (!_.isEqual(this.state.offerList, this.props.offerList)) {
        this.setState({ offerList: this.props.offerList, isLoading: false })
      }
      if (!this.props.isLoading) {
        this.setState({ isLoading: false })
      }
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps=(newProps) => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.offerList, this.props.offerList)) {
        this.setState({ offerList: _.cloneDeep(newProps.offerList), isPaginating: false, isLoading: false })
      } else if (!newProps.isSaving && !_.isEqual(newProps.offerList, this.props.offerList)) {
        this.setState({ offerList: _.cloneDeep(newProps.offerList), isLoading: false, isPaginating: false })
      } else if (!newProps.isLoading && newProps.offerList && newProps.offerList.length < 1) {
        this.setState({ isLoading: false })
      }
      // else if (newProps.isLoading && newProps.selectedPartnerId !== this.props.selectedPartnerId ) {
      //   console.log('3')
      //   this.setState({ isLoading: true })
      // }

      if (!newProps.isLoading && this.props.selectedPartnerId !== newProps.selectedPartnerId) {
        this.setState({ isLoading: false, offerList: _.cloneDeep(newProps.offerList) })
      }
    }

    componentWillUnmount=() => {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      if (this.state.showCreateOffer) {
        setTimeout(() => this.setState({ showCreateOffer: false }), 200)
      }
    }

    loadMoreOffers = () => {
      if (this.props.totalCount === this.props.offerList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getOfferList(this.state.offerList.length))
    }

      onOfferCreateSelection=() => {
        const { selectedPartnerId, details } = this.props
        const newOffer = {
          name: 'Untitled',
          partner: selectedPartnerId
        }
        if (this.state.isLoading) {
          return
        }
        NProgress.start()
        this.forceLoading = true
        if (details.termsAndConditions) {
          newOffer.terms = details.termsAndConditions
        }
        this.setState({ isLoading: true, showCreateOffer: false }, () => {
          this.props.createOffer(newOffer).then(response => {
            this.forceLoading = false
            this.setState({ isLoading: false })
            this.containerRef.scrollTop = 0
            LoggerService.info('flow', `Created offer ${response.data.createOffer.id}`)

            NProgress.done()
            message.success(userMessages.OFFER_CREATE_SUCCESS)
            window.open(`${location.origin}/offers/${response.data.createOffer.id}`, '_blank')
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        })
      }

      render () {
        const { offerList, isPaginating, isLoading } = this.state
        const { selectedPartnerId } = this.props
        return (
          <AppContext.Consumer>
            {({ permissions }) => {
              const userPermissions = permissions['OFFER_MANAGER']
              const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
              return <div className='offer-list' id='offer-list'>
                {isCreateDisabled ? '' : <div className='create-offer-container' id='create-offer-container'>
                  <ButtonContainer displayTitle='Create Offer' childComponent={<OfferButton onClick={this.onOfferCreateSelection} isDisabled={false} />} />
                </div>}
                <div className='table' ref={(node) => { this.containerRef = node }}>
                  {/* <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isLoading && !isPaginating} /> */}
                  {selectedPartnerId
                    ? <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isLoading}>
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMoreOffers}
                        hasMore={this.props.totalCount > offerList.length}
                        initialLoad={false}
                        useWindow={false}
                      >
                        { !this.forceLoading && offerList && offerList.length ? offerList.map(offer => {
                          let imageUrl
                          if (offer.partner && offer.partner.logo) {
                            imageUrl = generateCroppedThumbnail(offer.partner.logo, 55, 55, '1:1')
                          }
                          return (<OfferListCell
                            key={offer.id}
                            imageUrl={imageUrl}
                            shortDescription={offer.shortDescription}
                            offerName={offer.name}
                            partnerName={offer.partner ? offer.partner.name : ''}
                            offerId={offer.id}
                            externalId={offer.externalId}
                            manager='partner'
                          />)
                        }) : (!isLoading && !isPaginating) ? <Empty /> : <div /> }
                      </InfiniteScroll>
                    </Skeleton> : null }
                  <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={!this.forceLoading && isPaginating && this.props.totalCount > offerList.length} />

                </div>
                <div className='offer-list-footer-partner' id={'offer-list-footer-partner'} />
              </div>
            }}
          </AppContext.Consumer>
        )
      }
}

OfferList.propType = {
  /** array of applied filters for offerlist */
  filterVal: PropTypes.array,
  /** applied sort for offerlist */
  sort: PropTypes.Object,
  /** selected Partner id */
  selectedPartnerId: PropTypes.string
}

export default withApollo(compose(
  graphql(
    GetOffersList,
    {
      options: (props) => {
        const { filterVal, sort, project } = props
        const filter = utilityService.getFormattedFilterForOffer(filterVal)
        const variables = utilityService.getFormattedOfferFilter(null, filter, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const offerList = data.listOffers ? data.listOffers.items : []
        return {
          offerList,
          isLoading: data.loading || !data.listOffers,
          totalCount: data.listOffers ? data.listOffers.totalCount : 0,
          getOfferList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listOffers.items, ...fetchMoreResult.listOffers.items ]
                prev.listOffers.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationCreateOffer,
    {
      props: (props) => ({
        createOffer: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { createOffer } }) => {
          const { searchString, filterVal, sort, selectedPartnerId, project } = props
          const filter = utilityService.getFormattedFilterForOffer(filterVal)
          const variables = utilityService.getFormattedOfferFilter(searchString, filter, sort, project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: GetOffersList, variables }))
          if (cacheData && cacheData.listOffers && cacheData.listOffers.items) {
            cacheData.listOffers.items.splice(0, 0, createOffer)
          }
          cache.writeQuery({
            query: GetOffersList,
            data: cacheData,
            variables
          })
          const cacheOfferData = _.cloneDeep(cache.readQuery({ query: QueryGetPartnerDetails, variables: { id: selectedPartnerId } }))
          if (cacheOfferData && cacheOfferData.getPartner) {
            cacheOfferData.getPartner.offerCount = cacheOfferData.getPartner.offerCount ? cacheOfferData.getPartner.offerCount + 1 : 1
          }
          cache.writeQuery({
            query: QueryGetPartnerDetails,
            data: cacheOfferData,
            variables: { id: selectedPartnerId }
          })
        }
      })
    }
  )
)(withRouter(OfferList)))
