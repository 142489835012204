import React, { Component } from 'react'

import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import AssetPublishDetails from './AssetPublishDetails'
import HistoryList from '../auditManager/HistoryList'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetAssetDetails from './../../graphQL/asset/getAssetPublishDetails'
import SubscriptionCreatePublishRule from '../../graphQL/asset/createPublishRuleSubscription'
import SubscriptionUpdatePublishRules from '../../graphQL/asset/updatePublishRulesSubscription'

const detailsTabs = [
  {
    displayName: 'Info',
    mode: 'info'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

class AssetSideBar extends Component {
  state = {
    mode: 'info',
    publishDetails: this.props.publishDetails
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.publishDetails && !_.isEqual(newProps.publishDetails, this.props.publishDetails)) {
      this.setState({ publishDetails: _.cloneDeep(newProps.publishDetails) })
    }
  }

  componentDidMount () {
    this.props.subscribeToPublishRule()
    this.props.subscribeToPublishAll()
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  render () {
    const { mode, publishDetails } = this.state
    const { onPublishButtonClick, onPublishButtonActiveChange, details, canTriggerAppleNewsURL, assetId, isSaving, listAssetTypes, mediaCategoryList, project } = this.props
    this.publishDetails = publishDetails ? <AssetPublishDetails
      canTriggerAppleNewsURL={canTriggerAppleNewsURL}
      onPublishButtonClick={onPublishButtonClick}
      onPublishButtonActiveChange={onPublishButtonActiveChange}
      assetId={publishDetails.id}
      details={publishDetails}
      info={details}
      listAssetTypes={listAssetTypes}
      project={project}
      appleClient={this.props.appleClient}
    /> : null

    return (
      <div>
        <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
        {mode === 'info' && publishDetails && publishDetails.id
          ? this.publishDetails : null}
        {mode === 'history' ? <HistoryList isChanging={isSaving} showFilter={false} content='ASSET_MANAGER' contentId={assetId} assetDetails={details} mediaCategoryList={mediaCategoryList} project={project} /> : null }
        {mode === 'comments' ? null : null}
      </div>
    )
  }
}
export default withApollo(compose(
  graphql(
    QueryGetAssetDetails,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project }
        }
      },
      props: (props) => {
        return {
          publishDetails: props.data.getAsset,
          assetIsLoading: props.data.loading,
          subscribeToPublishRule: () => {
            return props.data.subscribeToMore({
              document: SubscriptionCreatePublishRule,
              // variables: {assetId: props.ownProps.assetId},
              updateQuery: (prev, { subscriptionData: { data: { publishRuleUpdated } } }) => {
                const isFromSameAsset = publishRuleUpdated && (publishRuleUpdated.assetId === prev.getAsset.id)
                if (!publishRuleUpdated || !isFromSameAsset) return prev
                const index = (prev.getAsset.publishRules || []).findIndex(rule => rule.id === publishRuleUpdated.id)
                if (index && index !== -1) { prev.getAsset.publishRules[index] = publishRuleUpdated } else { prev.getAsset.publishRules = [...prev.getAsset.publishRules, publishRuleUpdated] }
                return prev
              }
            })
          },
          subscribeToPublishAll: () => {
            return props.data.subscribeToMore({
              document: SubscriptionUpdatePublishRules,
              updateQuery: (prev, { subscriptionData: { data: { publishRulesUpdated } } }) => {
                const updatedPublishRules = publishRulesUpdated.filter(rule => rule.assetId === prev.getAsset.id)
                if (!updatedPublishRules || !updatedPublishRules.length) return prev
                prev.getAsset.publishRules = [...updatedPublishRules]
                return prev
              }
            })
          }
        }
      }
    }
  )
)(AssetSideBar))
