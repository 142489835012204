import gql from 'graphql-tag'

export default gql(`
    mutation createBucket($name:String $type: String $key: String! $project: String) {
        createBucketGroup(
            input:{
              name: $name
              type: $type
              key: $key
              project: $project
            }
        ) {
            id
            name
            type
            isArchived
            key
            project
            bucketGroupItems{
              id
              position
              bucketId
              bucket{
                displayName
                name
                key
                id
                isArchived
              }
            }
        } 
    }
`)
