import React, { Component } from 'react'
import ReactJson from 'react-json-view'
import { withRouter } from 'react-router-dom'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryGetAppConfig from '../../graphQL/appManager/getAppConfig'

import './../appManager/AppManager.scss'

class AppConfigJsonPreview extends Component {
  render () {
    const { details } = this.props
    return (
      <div className='app-json-preview'>
        <ReactJson id='json-pretty' src={details} displayDataTypes={false} iconStyle={'triangle'} name={false} />
      </div>
    )
  }
}

export default withApollo(withRouter(compose(
  graphql(
    QueryGetAppConfig,
    {
      options: (props) => {
        let id
        if (props.match && props.match.params && props.match.params.id) {
          id = props.match.params.id
        }
        const { project } = props
        return {
          variables: { id, project },
          client: props.appClient
        }
      },
      props: (props) => {
        return {
          details: props.data.getAppConfig ? props.data.getAppConfig : { }
        }
      }
    }
  )
)(withApollo(AppConfigJsonPreview))))
