import gql from 'graphql-tag'

export default gql(`
query listAppsQuery($module :String!, $project: String!){
    listConfig(
        module: $module,
        project: $project
        ){
            id,
            module,
            project,
            value
        }
}
`)
