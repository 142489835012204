import * as React from 'react'
import PropTypes from 'prop-types'
import TagsInput from './TagsInput'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryGetContent from '../../../../graphQL/filter/getContent'
import QueryListApps from './../../../../graphQL/appManager/listApps'

class FilterTagInput extends React.Component {
  render () {
    const { placeholder, displayParameter, parentCompoent, selectedItems, tagList, isDisabled, filterType, searchText, mode } = this.props
    return <React.Fragment>
      <TagsInput
        placeholder={`Select ${placeholder}`}
        tagList={tagList || []}
        displayParameter={displayParameter}
        selectedItems={filterType === 'AppManager' ? selectedItems.map((item) => item.id) : selectedItems.map((item) => item.key)}
        onChange={filterType === 'AppManager' ? this.onChangeApps : this.onChange}
        parentCompoent={parentCompoent}
        mode={mode || 'multiple'} onSearch={this.onSearch}
        keyParameter={filterType === 'AppManager' ? 'id' : 'key'}
        selectedDisplayItems={selectedItems.map((item) => item.name)}
        disabled={isDisabled}
        searchText={searchText}
        filterType={filterType}
      />
    </React.Fragment>
  }

  onSearch = (value) => {
    if (this.props.onSearch) { this.props.onSearch(value) }
  }

  onChange = (value, isDelete) => {
    if (isDelete) {
      const { selectedItems } = this.props
      const selectedItem = selectedItems.find((item) => item.key === value)
      this.props.onChange(selectedItem, isDelete)
    } else {
      const { tagList } = this.props
      const selectedItem = tagList.find((item) => item.key === value)
      this.props.onChange(selectedItem, isDelete)
    }
  }

  onChangeApps = (value, isDelete) => {
    if (isDelete) {
      const { selectedItems } = this.props
      const selectedItem = selectedItems.find((item) => item.id === value)
      this.props.onChange(selectedItem, isDelete)
    } else {
      const { tagList } = this.props
      const selectedItem = tagList.find((item) => item.id === value)
      this.props.onChange(selectedItem, isDelete)
    }
  }
}

FilterTagInput.propTypes = {
  /** Placeholder text of TagsInput. */
  placeholder: PropTypes.string,
  /** Placeholder text of TagsInput. */
  displayParameter: PropTypes.string,
  /** parent container id name of TagsInput. */
  parentCompoent: PropTypes.string,
  /** Selected Items of TagsInput. */
  selectedItems: PropTypes.array,
  /** Option data of TagsInput. */
  tagList: PropTypes.array,
  /** Change action of TagsInput. */
  onChange: PropTypes.func,
  /** type of filter  */
  filterType: PropTypes.string,
  /** type of content to get */
  contentName: PropTypes.string,
  /** Filter visible boolean */
  isVisible: PropTypes.bool,
  /** Function for searching a filter tag */
  onSearch: PropTypes.func,
  /** Search string */
  searchText: PropTypes.string,
  /** Module name */
  module: PropTypes.string
}

export default withApollo(compose(
  graphql(
    QueryGetContent,
    {
      skip: (props) => {
        return !(props.filterType && props.filterType.length && props.contentName && props.contentName.length && props.isVisible && props.filterType !== 'AppManager')
      },
      options: (props) => {
        let variables = { searchKey: props.searchText, field: props.contentName, limit: 30, offset: 0, project: props.project }
        if (props.contentName === `TAGS`) { variables.module = props.module }
        return {
          variables,
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          tagList: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  ),
  graphql(
    QueryListApps,
    {
      skip: (props) => {
        return !(props.filterType && props.filterType.length && props.contentName && props.contentName.length && props.isVisible && props.filterType === 'AppManager')
      },
      options: (props) => {
        let variables = { limit: 499, project: props.project }
        // if (props.contentName === `TAGS`) { variables.module = props.module }
        return {
          variables,
          fetchPolicy: 'network-only',
          client: props.appClient
        }
      },
      props: (props) => {
        return {
          tagList: props.data && props.data.listApps && props.data.listApps.items && props.data.listApps.items.length ? props.data.listApps.items : []
        }
      }
    }
  )
)(FilterTagInput))
