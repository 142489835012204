import React, { Component } from 'react'
import { Skeleton, Empty } from 'antd'

import InfiniteScroll from 'react-infinite-scroller'

// import Accordion from '../../components/ui/dataDisplay/Accordion'
import CustomerListCell from '../../components/ui/dataDisplay/CustomerListCell'
// import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
// import BackArrowButton from '../../components/ui/general/buttons/BackArrowButton'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListCustomers from './../../graphQL/customers/listCustomers'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import { utilityService } from '../../services/UtilityService'

const width = ['100%', '100%', '100%']

class CustomerSearchResult extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && !_.isEqual(newProps.customerList, this.props.customerList)) {
      this.setState({ isPaginating: false, isLoading: false })
    } else if (!newProps.isLoading && this.props.isLoading && _.isEqual(newProps.customerList, this.props.customerList)) {
      this.setState({ isPaginating: false, isLoading: false })
    }
    if (newProps.error && newProps.error !== this.props.error && !newProps.isLoading) {
      this.setState({ isPaginating: false, isLoading: false })
      // message.error(newProps.error.replace('GraphQL error: ', ''))
      utilityService.handleCustomerManagerError(newProps.error)
    }
    if (!newProps.isChanging && this.props.isChanging) {
      if (newProps.refetchCustomerList) {
        newProps.refetchCustomerList()
        setTimeout(newProps.refetchCustomerList, 1000)
      }
    }
  }

  loadMoreCustomers = () => {
    if (this.props.totalCount === this.props.customerList.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getMoreCustomerList(this.props.customerList.length))
  }

  renderSearchDetails = () => {
    const { searchString } = this.props
    return <div className='search-details'>
      <div className='search-details-item' >
        <p>Search Term</p>
        <p>{searchString}</p>
      </div>
      {/* <div className='search-details-item' >
        <p>Status</p>
        <p>Active</p>
      </div>
      <div className='search-details-item' >
        <p>Country</p>
        <p>Australia</p>
      </div> */}
    </div>
  }

  render () {
    const { onSelectCustomer, customerList, totalCount, selectedCustomer, searchString, onSearchCustomer, isLoading, project } = this.props
    const { isPaginating } = this.state
    return (
      <div className='customer-search-result' >
        {/* <Accordion title='SEARCH DETAILS' childComponent={this.renderSearchDetails()} />
        <div className='search-details-footer'>
          <label>SEARCH RESULTS</label>
          <ButtonContainer displayTitle='Back to search' childComponent={<BackArrowButton onClick={onHandleBack} />} />
        </div> */}
        <FilterInput
          searchString={searchString}
          onChangeSearchInput={onSearchCustomer}
          project={project}
          initialSearch
        />
        <div className='search-description' >
          {project === 'hyperion' || project === 'projectx' ? <label>Search with ID, FirstName, LastName, Email or Mobile.</label>
            : <label>Search on id, firstName, lastName, email, externalId, analyticUserId, phone number, and cognitoUserId support in this field.</label>
          }
        </div>
        { searchString ? <div className='customer-list'>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreCustomers}
            hasMore={customerList && (totalCount > customerList.length)}
            initialLoad={false}
            useWindow={false}
          >
            <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading={isLoading} >
              { customerList && customerList.length ? customerList.map(customer => <CustomerListCell key={customer.id} details={customer} onSelect={onSelectCustomer} isSelected={selectedCustomer === customer.id} project={project} />) : <Empty />}
            </Skeleton>
            <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading={isPaginating} />
          </InfiniteScroll>
        </div> : null }
      </div>
    )
  }
}

CustomerSearchResult.propTypes = {
}

export default withApollo(compose(
  graphql(
    QueryListCustomers,
    {
      options: (props) => {
        const { searchString, project } = props
        const variables = { offset: 0, limit: 20, searchTerm: searchString, project }
        return {
          fetchPolicy: 'network-only',
          variables,
          notifyOnNetworkStatusChange: true
        }
      },
      skip: ({ searchString }) => !searchString,
      props: (props) => {
        const { data, ownProps } = props
        const error = data.error ? data.error.message : null
        const customerList = (data.listCustomers && !error) ? data.listCustomers.users : []
        return {
          customerList,
          isLoading: data.loading,
          totalCount: data.listCustomers ? data.listCustomers.numberOfHits : 0,
          error,
          getMoreCustomerList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              notifyOnNetworkStatusChange: true,
              variables: {
                offset: page,
                project: ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listCustomers.users, ...fetchMoreResult.listCustomers.users ]
                prev.listCustomers.users = newList
                return prev
              }
            })
          },
          refetchCustomerList: () => {
            const { searchString, project } = props.ownProps
            const variables = { offset: 0, limit: 20, searchTerm: searchString, project }
            return props.data.refetch(variables)
          }
        }
      }
    }
  )
)(CustomerSearchResult))
