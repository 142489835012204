import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'

const Button = ({ buttonText, color, onClick, isDisabled, isLoading, ...props }) => (
  <button className={`general-button ${color} ${isDisabled || isLoading ? 'disable' : ''}`} onClick={isDisabled ? () => {} : onClick}>{isLoading ? 'Loading...' : buttonText}</button>
)

Button.propTypes = {
  /** Button color. Defaults to 'light-gray */
  color: PropTypes.string,
  /** Button inner text. Defaults to 'Submit' */
  buttonText: PropTypes.string,
  /** Button click action. */
  onClick: PropTypes.func,
  /** Boolean for button enable/disable */
  isDisabled: PropTypes.bool,
  /** Boolean for button loading */
  isLoading: PropTypes.bool
}

Button.defaultProps = {
  color: 'brown-gray',
  buttonText: 'Submit'
}

export default Button
