import React from 'react'
import PropTypes from 'prop-types'
import '../../../ui/ui.style.scss'
import { Popover } from 'antd'
import TickIcon from '../../../../components/player/icons/TickIcon'
import _ from 'lodash'

class IconDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedItems: []
    }
  }

    onChangeOption = (value) => {
      const { multiSelect, contents, keyParam } = this.props
      const key = keyParam || 'id'
      const selectedItem = contents.find((item) => item[key] === value)
      if (multiSelect) {
        let items = [ ...this.props.selectedOption ]
        const index = items.findIndex(item => (item[key]) === value)
        if (selectedItem.isDefault) {
          items = []
        } else {
          if (index > -1) {
            items.splice(index, 1)
          } else {
            const index = contents.findIndex(item => (item[key]) === value)
            const newItem = (contents[index])
            items.push(newItem)
          }
        }

        this.props.onContentClick(items)
      } else {
        if (this.props.selectedOption !== value) {
          this.props.onContentClick(value)
        } else {
          this.props.onContentClick(undefined)
        }
      }
    }

    render () {
      const { icon, displayParam, isVisible, contents, selectedOption, parentCompoent, multiSelect, keyParam } = this.props
      const key = keyParam || 'id'
      const content = (
        <div>
          { (contents || []).map((item, index) => <div key={index} className='icon-dropdown' onClick={() => this.onChangeOption(item[key])}> <p>{ !_.isEmpty(item[ displayParam ]) ? item[ displayParam ] : item[ key ] }</p>
            {multiSelect ? (selectedOption || []).map(option => item[key] === option[key] ? <TickIcon key={index} /> : null) : item[key] === selectedOption ? <TickIcon /> : null }
            {selectedOption && selectedOption.length ? '' : item.isDefault ? <TickIcon /> : null }
          </div>)}
        </div>
      )

      return (
        <Popover className='popover-btn' placement='rightTop' content={content} trigger='click' visible={isVisible} getPopupContainer={() => parentCompoent ? document.getElementById(parentCompoent) : ''} >
          { icon }
        </Popover>
      )
    }
}

IconDropDown.propTypes = {
  /** parent container id of IconDropDown. */
  parentCompoent: PropTypes.string,
  /** display parameter of items of IconDropDown. */
  displayParam: PropTypes.string,
  /** visibility status of IconDropDown. */
  isVisible: PropTypes.bool,
  /** Button component of IconDropDown. */
  button: PropTypes.element,
  /** contents data of IconDropDown. */
  contents: PropTypes.arrayOf(PropTypes.object),
  /** content click action of IconDropDown. */
  onContentClick: PropTypes.func
}

IconDropDown.defaultProps = {
  isVisible: true,
  parentCompoent: 'root'
}

export default IconDropDown
