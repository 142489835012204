import gql from 'graphql-tag'

export default gql(`
    query($team: ID! $tournamentCalendar: ID! ){
        getSquad( 
            tournamentCalendar: $tournamentCalendar
            team: $team
        ) {
        squad{
          id
          externalId
          opId
          firstName
          lastName
          matchName
          nationality
          nationalityId
          position
          type
          lastUpdated
          placeOfBirth
          active
          customFirstName
          customLastName
          customMatchName
          customNationality
          customPosition
          customType
          customActive
          tags{
            key
            name
            type
          }
        }
    }
}`)
