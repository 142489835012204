import React from 'react'
import PropTypes from 'prop-types'
import UploadIcon from './../icons/UploadIcon'
import './../../ui.style.scss'

const AddUploadButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`add-upload-button ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
    <div className='upload-button'>
      <UploadIcon />
      <p>Upload Content </p>
    </div>
  </div>
)

AddUploadButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

AddUploadButton.defaultProps = {
  isDisabled: false
}

export default AddUploadButton
