import gql from 'graphql-tag'

export default gql(`
    mutation deleteMediaCategories($id: [ID]! $project: String) {
        deleteMediaCategories(
            input: $id
            project: $project
        )
    }`
)
