import gql from 'graphql-tag'

export default gql(`
query getAppConfig($id: ID!, $project: String!) {
    getAppConfig(id: $id, project: $project) {
        id
        key
        title
        app{
            id
            name
            url
            isCritical
        }
        group{
            key
            name
            isCritical
            assignedUsers
        }
        type
        isCritical
        assignedUsers
        tags{
            key
            name
            type
            module
            assetCount
            isTransferring
            isError
        }
        project
        createdAt
        updatedAt
        customMeta{
            config{
                id
                name
                  type
                  section
                default
                  project
                  usageCount
                }
            value
            name
        }
        updatedBy
        createdBy
        isPublished
        isArchived
        publishStartDate
        publishEndDate
        config {
            id
            project
            position
            appConfigId
            isEnabled
            fieldValues {
              configField {
                id
                type
                isRequired
                useAsTitle
                name
                tooltipText
              }
              value
              project
              bucket {
                project
                id
                isArchived
                name
                isPublished
                publishStartDate
                publishEndDate
                updatedAt
                updatedBy
                key
                displayName
                displayType {
                  name
                  id
                }
                bucketItems {
                  id
                  position
                  type
                  asset{
                    id
                    title
                    type
                    shortId
                    externalId
                    updatedAt
                    status
                    seasonNumber
                    episodeNumber
                    series{
                      id
                      assetId
                      title
                    }
                    season{
                        id
                        assetId
                        title
                    }
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                }
                filterRule{
                  searchKey
                  filters{
                      name
                      fieldName
                      type
                      value
                      displayValue
                      displayName
                      isRequired
                   }
                 }
                }
              }
              image {
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
              }
            }
          }
          configList {
             id
             project
             position
             appConfigId
             isEnabled
             createdAt
             createdBy
             fieldValues {
               configField {
                 id
                 type
                 isRequired
                 useAsTitle
                 name
                 tooltipText
               }
               value
               project
               bucket {
                project
                id
                isArchived
                name
                isPublished
                publishStartDate
                publishEndDate
                updatedAt
                updatedBy
                key
                displayName
                displayType {
                  name
                  id
                }
                bucketItems {
                  id
                  position
                  type
                  asset{
                    id
                    title
                    type
                    shortId
                    externalId
                    updatedAt
                    status
                    seasonNumber
                    episodeNumber
                    series{
                      id
                      assetId
                      title
                    }
                    season{
                        id
                        assetId
                        title
                    }
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                }
                filterRule{
                  searchKey
                  filters{
                      name
                      fieldName
                      type
                      value
                      displayValue
                      displayName
                      isRequired
                   }
                 }
                }
              }
               image {
                  id
                  fileName
                  createdAt
                  name
                  type
                  fileSize
                  duration
                  thumbnails
                  tags{
                      key
                      name
                      type
                  }
                  category{
                      id
                      label
                      type
                  }
                  settings {
                      aspectRatio
                      outputFormat
                      fileName
                      x1
                      y1
                      x2
                      y2
                  }
                  dimensions{
                      x1
                      x2
                      y1
                      y2
                  }
                  aspectRatio {
                      aspectRatio
                      ratio
                      title
                      resolutions {
                      key
                      width
                      height
                      url
                      }
                   }

                }
          }
        }
        fields{
            id
            name
            type
            isRequired
            useAsTitle
            position
            tooltipText
        }
    }
}`)
