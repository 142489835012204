import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'

import CustomerSearchResult from './CustomerSearchResult'
import CreateCustomerModal from './../../components/ui/dataEntry/other/CreateCustomerModal'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import AddButton from './../../components/ui/general/buttons/AddButton'
import { utilityService } from '../../services/UtilityService'
import MutationCreateCustomer from './../../graphQL/customers/createCustomer'
import HistoryList from '../auditManager/HistoryList'

class CustomerSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchString: '',
      isCreateCustomerVisible: false,
      isModalLoading: false,
      isSearchHistoryVisible: false
    }
  }

  UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
    // if (this.props.match && this.props.match.params && this.props.match.params.id) {
    //   this.setState({ searchString: this.props.match.params.id })
    // }
  }

  onSearchCustomer = searchString => {
    this.setState({ searchString, isSearchHistoryVisible: false })
  }

  onCreateCustomer = (data) => {
    this.setState({ isModalLoading: true })
    this.props.createCustomer(data).then(() => {
      this.setState({ isCreateCustomerVisible: false, isModalLoading: false })
      message.success('Customer created successfully')
    }, error => {
      this.setState({ isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onCloseCustomerCreate = () => {
    this.setState({
      isCreateCustomerVisible: false
    })
  }

  callCreatePopup = () => {
    this.setState({
      isCreateCustomerVisible: true
    })
  }

  toggleSearchHistory = () => {
    const { isSearchHistoryVisible } = this.state
    this.setState({ isSearchHistoryVisible: !isSearchHistoryVisible })
  }

  render () {
    const { onSelectCustomer, selectedCustomer, project, refetchCustomerList } = this.props
    const { searchString, isCreateCustomerVisible, isModalLoading, isSearchHistoryVisible } = this.state
    return (
      <div className={`customer-sidebar`} >
        <CustomerSearchResult searchString={searchString} onSearchCustomer={this.onSearchCustomer} selectedCustomer={selectedCustomer} onSelectCustomer={onSelectCustomer}
          onHandleBack={this.showSearchOptions} project={project} isChanging={refetchCustomerList} />
        {isSearchHistoryVisible ? <HistoryList showFilter={false} content='CUSTOMER_MANAGER' contentId={'CSM'} project={project} refetchCSMHistoryList={() => {}} seachField={this.onSearchCustomer} /> : ''}
        <div className='customer-list-footer' id={'customer-list-footer'}>
          <span className='search-history' onClick={this.toggleSearchHistory}>{isSearchHistoryVisible ? 'Hide recent searches' : 'Show recent searches'}</span>
          {project === 'hyperion' || project === 'projectx' ? '' : <ButtonContainer displayTitle='Create Customer' childComponent={<AddButton onClick={this.callCreatePopup} />} />}
          <CreateCustomerModal
            isVisible={isCreateCustomerVisible}
            handleSubmit={this.onCreateCustomer}
            handleCancel={this.onCloseCustomerCreate}
            isSubmitDisabled={isModalLoading}
            isLoading={isModalLoading} />
        </div>
      </div>
    )
  }
}

CustomerSidebar.propTypes = {
}

// export default withRouter(CustomerSidebar)
export default withApollo(compose(
  graphql(
    MutationCreateCustomer,
    {
      props: (props) => ({
        createCustomer: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CustomerSidebar))
