import gql from 'graphql-tag'

export default gql(`
query searchPartnersQuery($filter: ListPartnerFilterInput, $offset: Int, $limit: Int, $order: String, $search: SearchInput,  $project: String) {
  listPartners(
      filter: $filter,
      limit: $limit,
      offset: $offset,
      order: $order,
      search: $search
      project: $project
    ) {
      items {
        id
        externalId
        name
        isActive
        offerCount
        logo{
          id
          fileName
          createdAt
          name
          type
          fileSize
          duration
          thumbnails
          tags{
              key
              name
              type
          }
          category{
              id
              label
              type
          }
          settings {
              aspectRatio
              outputFormat
              fileName
              x1
              y1
              x2
              y2
          }
          dimensions{
              x1
              x2
              y1
              y2
          }
          aspectRatio {
              aspectRatio
              ratio
              title
              resolutions {
              key
              width
              height
              url
              }
          }
          drmContent
          streams{
              id
              drm
              codec
              Url
              iv
              transport
              contentKey
          }
          vodStatus{
              id
              startTime
              endTime
              channel{
                  id
                  icon{
                      id
                      fileName
                      aspectRatio {
                          aspectRatio
                          ratio
                          title          
                          resolutions {
                          url
                          }
                      }
                      settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2
                          y2
                      }
                  }
              }
          }
      }
        }
      totalCount
    }
}`)
