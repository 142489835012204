import gql from 'graphql-tag'

export default gql(`
query filterBucketAudit($filter: AuditFilter $limit: Int $offset: Int $project: String){
    filterAudits(
        limit: $limit
        offset: $offset
        filter: $filter
        project: $project
    ){
        items{
            id
            contentType
            contentName
            action
            contentId
            updatedAt
            updatedBy
            updatedObj{
                isPosChange
                name
                value
                oldValue
                displayName
                fieldName
                fieldValues
                oldFieldValues
            }
        }
        totalCount
    }
}`)
