import React, { Component } from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import AppContext from '../../../AppContext'
import { Skeleton, Upload, message } from 'antd'

import QueryUploadCsv from '../../../graphQL/asset/getUploadUrl'
import QueryListSports from '../../../graphQL/admin/sports/listSports'
import MutationUploadSport from '../../../graphQL/admin/sports/uploadSport'
import { utilityService } from '../../../services/UtilityService'
import DropDown from '../../../components/ui/dataEntry/inputs/DropDown'
import SelectedDatatype from '../../../components/ui/dataDisplay/SelectedDatatype'
import DragDropUploadIcon from '../../../components/ui/general/icons/DragDropUploadIcon'
import UploadQueueCell from '../../../components/ui/dataDisplay/UploadQueueCell'
import Button from './../../../components/ui/general/buttons/Button'
import ApiService from './../../../services/ApiService'
import QueryListMasterFields from '../../../graphQL/admin/sports/listMasterFields'
import generateCSVHeaders from '../../../graphQL/admin/sports/generateCSVHeaders'

const Dragger = Upload.Dragger

class AddSportsData extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      selectedValue: null,
      selectedDatatype: null,
      isUploadBtnLoading: false,
      fileList: []
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    const { uploadData, isUploadError } = newProps
    if (isUploadError && !this.props.isUploadError) {
      this.setState({ fileList: [] })
    }
    if (uploadData && !_.isEqual(uploadData, this.props.uploadData)) {
      if (uploadData.length > -1) {
        uploadData.map((item) => {
          this.uploadCsvApi(_.cloneDeep(item))
        })
      }
    }
    if (newProps.hasOwnProperty('isLoading')) {
      this.setState({ loading: newProps.isLoading })
    }
  }

  uploadCsvApi = (uploadData) => {
    const { fileName, contentType, key, uploadUrl } = uploadData
    const successCallBack = () => {
      this.props.uploadSport(key, fileName, 'CSV', this.state.selectedValue, this.state.selectedDatatype).then(response => {
        message.success(`Uploaded ${fileName} successfully`)
        let fileList = []
        this.setState({
          fileList,
          isUploadBtnLoading: false,
          selectedValue: null,
          selectedDatatype: null
        })
        setTimeout(() => {
          window.location.reload()
        }, 200)
      }, error => {
        let fileList = []
        this.setState({ fileList, isUploadBtnLoading: false })
        utilityService.handleError(error)
      })
    }

    const failureCallBack = () => {
      let { fileList } = this.state
      message.success(`Failed to create media ${fileName}`)
      fileList = []
      this.setState({ fileList })
    }

    const progressCallBack = progress => {
    }
    const { fileList } = this.state
    const selectedFileIndex = fileList.findIndex(file => file.name === fileName)
    ApiService('PUT', uploadUrl, fileList[selectedFileIndex], { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
  }
  onOptionSelect = (value) => {
    this.setState({ selectedValue: value })
    this.props.setSelectedSport(value)
  }

  onDatatypeSelect = (value) => {
    this.setState({ selectedDatatype: value })
    this.props.setSelectedSportType(value)
  }

  beforeUpload = (data, dataList) => {
    let { fileList } = this.state
    if (!data.size) {
      message.error(`${data.name} is an empty image`)
    }
    dataList = dataList.filter(item => item.size)
    fileList = [ ...fileList, ...dataList ]
    this.setState({ fileList })
  }

  uploadCsv = () => {
    const { fileList } = this.state
    this.setState({
      isUploadBtnLoading: true
    })
    this.props.uploadList(fileList)
  }

  handleCancel = () => {
    this.setState({ fileList: [], selectedTags: [] })
    this.props.handleCancel()
  }

  onReorder = () => {

  }

  handleDelete = (index, e) => {
    const { fileList } = this.state
    fileList.splice(index, 1)
    this.setState({ fileList })
  }

  render () {
    const { loading, selectedValue, selectedDatatype, fileList, isUploadBtnLoading } = this.state
    const { listSports, listMasterFields, csvHeaders } = this.props
    const uploadProps = {
      multiple: false,
      beforeUpload: this.beforeUpload,
      customRequest: () => {}
    }
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          // const userPermissions = permissions['DATA_MANAGER']
          // const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          // const isEditDisabled = userPermissions.indexOf('UPDATE') === -1

          return <React.Fragment>
            <div className='add-sports-data'>
              <div className='upload-data'>
                <Skeleton active avatar={false} title={false} paragraph={{ rows: 2 }} loading={loading}>
                  <div className='upload-sport' style={selectedDatatype ? { width: '70%' } : { width: '100%' }}>
                    <div className='list-sport'>
                      <div className='select-sport'>
                        <label>Sport</label>
                        <DropDown
                          selectedValue={selectedValue}
                          className={selectedValue ? 'selected' : ''}
                          options={listSports}
                          valueParam='id'
                          onOptionSelect={(value) => this.onOptionSelect(value)}
                          displayParam='title'
                          placeHolder={'Select Sport'}
                        />
                      </div>
                      <div className='select-sport-datatype'>
                        <label>Data Type</label>
                        <DropDown
                          selectedValue={selectedDatatype}
                          className={selectedDatatype ? 'selected' : ''}
                          options={listMasterFields}
                          valueParam='id'
                          onOptionSelect={(value) => this.onDatatypeSelect(value)}
                          displayParam='displayName'
                          placeHolder={'Select Data type'}
                          disabled={!selectedValue}
                        />
                      </div>
                    </div>
                    <div className='upload-sport-file'>
                      { fileList.length ? fileList.map((file, index) => (<div key={index}><UploadQueueCell
                        thumbnailIcon={'file'}
                        name={file.name}
                        onClose={this.handleDelete}
                        index={index} />
                      </div>)
                      ) : <Dragger className={!fileList.length ? 'full' : ''} {...uploadProps} accept='.csv' listType='picture-card' onClick={() => { this.uploadRef.click() }}>
                        <DragDropUploadIcon />
                        <p className='ant-upload-text' ref={(ref) => { this.uploadRef = ref }}>Click or drag file to this area to upload</p>
                      </Dragger>
                      }
                    </div>
                    <div className='upload-btn' style={{ width: '150px' }}>
                      <Button
                        isLoading={isUploadBtnLoading}
                        buttonText={'Upload'}
                        color='red'
                        onClick={() => this.uploadCsv()}
                        isDisabled={!fileList.length || !selectedValue || !selectedDatatype}
                      />
                    </div>
                  </div>
                  <div className='sports-datatype' style={selectedDatatype ? { width: 'calc( 30% - 20px )' } : { display: 'none' }}>
                    <div className='datatype-header'>
                      Metadata Fields
                    </div>
                    <div className='datatype-body'>
                      {
                        selectedDatatype && csvHeaders ? <SelectedDatatype field={csvHeaders} dataType={selectedDatatype} /> : ''
                      }
                    </div>
                  </div>
                </Skeleton>
              </div>
            </div>
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AddSportsData.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListSports,
    {
      options: (props) => {
        const nextToken = props.nextToken ? props.nextToken : null
        return {
          variables: { limit: 999, nextToken },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let listSports = data.listSports ? [ ...data.listSports.items ] : []
        const nextToken = data.listSports ? data.listSports.nextToken : null
        const previousToken = data.listSports ? data.listSports.previousToken : null

        return {
          listSports,
          isLoading: data.loading,
          nextToken,
          previousToken,
          getMoreSports: (nextToken) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                nextToken: nextToken
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newSportsList = fetchMoreResult.listSports ? [ ...fetchMoreResult.listSports.items ] : []
                let newList = [ ...prev.listSports.items, ...newSportsList ]
                newList = _.uniqBy(newList, 'id')
                if (_.isEqual(newList, prev.listSports.items)) { return prev }
                prev.listSports.items = newList
                prev.listSports.previousToken = prev.listSports.nextToken
                prev.listSports.nextToken = fetchMoreResult.listSports.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    QueryUploadCsv,
    {
      options: ({ files, project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { files, project }
        }
      },
      skip: ({ files }) => {
        return (!files || !files.length)
      },
      props: (props) => {
        const { data, ownProps } = props
        const uploadData = !data.loading && data.getUploadUrl ? data.getUploadUrl.map((item, index) => {
          return { ...item, ...ownProps.files[index] }
        }) : []
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          // ownProps.clearFileName()
          utilityService.handleError(data.error)
        }
        return { uploadData: _.map(uploadData, _.clone), isUploadError }
      }
    }
  ),
  graphql(
    MutationUploadSport,
    {
      options: ({ key, name, type, sport, dataType }) => {
        return { update: (cache, { data: { uploadSport } }) => {
        }
        }
      },
      props: (props) => ({
        uploadSport: (key, name, type, sport, dataType) => {
          return props.mutate({
            variables: { key, name, type, sport, dataType }
          })
        }
      })
    }
  ),
  graphql(
    QueryListMasterFields,
    {
      props: (props) => {
        const { data } = props
        let listMasterFields = data.listMasterFields ? [ ...data.listMasterFields ] : []

        return {
          listMasterFields
        }
      }
    }
  ),
  graphql(
    generateCSVHeaders,
    {
      options: ({ selectedSportFields }) => {
        const sport = selectedSportFields.selectedSport ? selectedSportFields.selectedSport : null
        const type = selectedSportFields.selectedSportType ? selectedSportFields.selectedSportType : null
        return {
          variables: { sport, type },
          fetchPolicy: 'network-only'
        }
      },
      skip: ({ selectedSportFields }) => !selectedSportFields.selectedSport || !selectedSportFields.selectedSportType,
      props: (props) => {
        const { data } = props
        let csvHeaders = data.generateCSVHeaders ? [ ...data.generateCSVHeaders ] : []

        return {
          csvHeaders
        }
      }
    }
  )
)(AddSportsData))
