import React, { Component } from 'react'
import { Table, Skeleton, Empty, message } from 'antd'
import { Resizable } from 'react-resizable'
import moment from 'moment'

import ToolTip from './ToolTip'
import EditSubscriptionModal from '../dataEntry/other/EditSubscriptionModal'
import RefundModal from '../dataEntry/other/RefundModal'
import EventsModal from './../dataDisplay/EventsModal'
import TransactionTableModal from './TransactionTableModal'
import { utilityService } from './../../../services/UtilityService'
import ConfirmModal from './../../../components/ui/feedback/ConfirmModal'
import ExpandMenuIcon from './../../../components/ui//general/icons/ExpandMenuIcon'
import PopoverButton from './../../../components/ui//general/buttons/PopoverButton'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListSubscription from '../../../graphQL/customers/listSubscriptions'
import MutationCancelSubscription from '../../../graphQL/customers/cancelSubscription'
import MutationAdminAccountDisconnect from '../../../graphQL/customers/adminAccountDisconnect'

const options = [
  {
    type: 'cancelSubscription',
    name: 'Cancel Subscription'
  }
]

const optionsPartnerDisconnect = [
  {
    type: 'cancelSubscription',
    name: 'Cancel Subscription'
  },
  {
    type: 'disconnectPartnerAccount',
    name: 'Disconnect Partner Account'
  }
]

const optionsPartnerDisconnectHyperion = [
  {
    type: 'cancelSubscription',
    name: 'Cancel Subscription'
  },
  {
    type: 'disconnectPartnerAccount',
    name: 'Disconnect Partner Account (Only for Netflix)'
  }
]

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class SubscriptionTableHyperion extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Subscription ID',
        dataIndex: 'id',
        key: 'id',
        width: 100
      },
      {
        title: 'Plan',
        dataIndex: 'planId',
        key: 'planId',
        width: 100,
        render: (planId, subscription) => subscription && subscription.planData && subscription.planData.provider ? `${subscription.planData.provider.name}-${planId}` : ''
      },
      {
        title: 'Activated At',
        dataIndex: 'activatedAt',
        key: 'activatedAt',
        width: 100,
        render: (activatedAt) => activatedAt ? moment(new Date(activatedAt)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Renewal Date',
        dataIndex: 'renewalDate',
        width: 100,
        key: 'renewalDate',
        render: (renewalDate) => renewalDate ? moment(new Date(renewalDate)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        render: (createdAt) => createdAt ? moment(new Date(createdAt)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        width: 100,
        render: (endDate) => endDate ? moment(new Date(endDate)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100
      },
      {
        title: 'Cancellation Reason',
        dataIndex: 'cancellationReason',
        key: 'cancellationReason',
        width: 140,
        render: this.renderCancellationReason
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width: 100
      },
      {
        title: 'Service Account',
        dataIndex: 'customerServiceAccount',
        key: 'customerServiceAccount',
        width: 140,
        render: this.renderServiceAccount
      },
      {
        title: 'List Payment Transaction',
        dataIndex: 'id',
        width: 180,
        key: 'id',
        render: this.renderTransactions
      },
      {
        title: 'Events',
        dataIndex: 'id',
        width: 120,
        key: 'id',
        render: this.renderEvents
      }
    ]

    this.state = {
      shouldShowPopup: false,
      isCancelSubscriptionVisible: false,
      isEditSubscriptionVisible: false,
      isRefundVisible: false,
      isEventsVisible: false,
      isModalLoading: false,
      width: null,
      height: null,
      subscriptionId: null,
      columns: this.columns,
      isDisconnectPartnerVisible: false
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
    if (newProps.profileOptionShown === true) {
      this.setState({ shouldShowPopup: false })
    }
  }

  renderServiceAccount = (customerServiceAccount) => {
    const details = [`Name: ${customerServiceAccount ? customerServiceAccount.userName : '-'}`,
      `Email: ${customerServiceAccount ? customerServiceAccount.email : '-'}`,
      `Mobile: ${customerServiceAccount ? customerServiceAccount.mobileNumber : '-'}`,
      `Provider ID: ${customerServiceAccount ? customerServiceAccount.providerId : '-'}`,
      `Status: ${customerServiceAccount ? customerServiceAccount.status : '-'}`]
    return <ToolTip
      childComponent={<div className='general-text'>View</div>}
      details={details}
      placement='leftTop'
      isList />
  }

  renderCancellationReason = (cancellationReason) => {
    if (cancellationReason) {
      const details = cancellationReason || 'None'
      return <ToolTip
        childComponent={<div className='general-text'>View</div>}
        description={details}
        placement='leftTop'
        isList
      />
    } else {
      return <span className='no-data-text' >None</span>
    }
  }

  renderEvents = (id) => {
    return <div className='show-event-container'>
      <span className='general-text' onClick={() => this.showEventsModal(id)}>Show Events</span>
      {this.renderData(id)}
    </div>
  }

  renderTransactions = (id) => {
    return <div className='show-event-container'>
      <span className='general-text' onClick={() => this.showTransactionsModal(id)}>Show Transactions</span>
    </div>
  }

  showEventsModal=(subscriptionId) => {
    this.setState({ isEventsVisible: !this.state.isEventsVisible, subscriptionId })
  }

  showTransactionsModal=(subscriptionId) => {
    this.setState({ isTransactionsVisible: !this.state.isTransactionsVisible, subscriptionId })
  }

  showEditSubscription = id => {
    this.setState({ editSubscription: id })
    this.props.changeEditSubscriptionHyperionId(id)
  }

  removeEditSubscription = () => {
    this.setState({ editSubscription: undefined })
    this.props.changeEditSubscriptionHyperionId(null)
  }

  removeRefund = () => {
    this.setState({ isRefundVisible: false })
  }

  renderData = (id) => {
    const { isUpdateDisabled, subscription, project } = this.props
    let renderSubData = subscription.filter(sub => sub.id === id)
    let subStatus = renderSubData[0].status
    const { shouldShowPopup, editSubscription } = this.state
    return (
      <div className='hyperion-icon'>
        <div onClick={() => this.showEditSubscription(id)} id={`sub-option-list-${id}`} className={`sub-option-list hyperion-sub-option-list ${isUpdateDisabled ? 'disabled' : ''}`}>
          <PopoverButton
            button={<div onClick={this.toggleShowOptions} > <ExpandMenuIcon /></div>}
            placement='left'
            displayParam='name'
            contents={subStatus === 'active' || subStatus === 'pendig_cancelation' ? project === 'projectx' ? optionsPartnerDisconnect : optionsPartnerDisconnectHyperion : options}
            onContentClick={this.selectOption}
            parentCompoent={`sub-option-list-${id}`}
            isVisible={editSubscription === id && subStatus !== 'cancelled' && shouldShowPopup}
          />
        </div>
      </div>
    )
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  toggleShowOptions = () => {
    this.setState({ shouldShowPopup: !this.state.shouldShowPopup })
  }

  toggleisEditSubscriptionVisible = () => {
    this.setState({ isEditSubscriptionVisible: !this.state.isEditSubscriptionVisible })
  }

  toggleisRefundVisible = () => {
    this.setState({ isRefundVisible: !this.state.isRefundVisible })
  }

  selectOption = ({ type }) => {
    if (type === 'cancelSubscription') {
      this.setState({ isCancelSubscriptionVisible: true, editSubscription: undefined, shouldShowPopup: false })
    } else if (type === 'disconnectPartnerAccount') {
      this.setState({ isDisconnectPartnerVisible: true, editSubscription: undefined, shouldShowPopup: false })
    } else if (type === 'editSubscription') {
      this.setState({ isEditSubscriptionVisible: true, editSubscription: undefined, shouldShowPopup: false })
    } else if (type === 'refund') {
      this.setState({ isRefundVisible: true, editSubscription: undefined, shouldShowPopup: false })
    }
  }

  onCancelSubscriptionConfirmation = () => {
    this.setState({ isCancelSubscriptionVisible: false })
  }

  onConfirmCancelSubscription = () => {
    const { selectedCustomer, project } = this.props
    const { editSubscription } = this.state
    this.setState({ isModalLoading: true })
    const variables = {
      email: selectedCustomer,
      userId: selectedCustomer,
      id: editSubscription,
      project
    }
    this.props.cancelSubscription(variables).then(({ data }) => {
      if (data && data.adminCancelSubscription && data.adminCancelSubscription.error && data.adminCancelSubscription.error.message) {
        const errorMessage = data.adminCancelSubscription.error.message
        this.props.fetchHistory()
        this.setState({ isCancelSubscriptionVisible: false, isModalLoading: false })
        utilityService.handleError(errorMessage)
      } else {
        this.setState({ isCancelSubscriptionVisible: false, isModalLoading: false })
        const successMessage = data.admincancelSubscription && data.admincancelSubscription.success && data.admincancelSubscription.success.message ? data.admincancelSubscription.success.message : 'Cancelled Successfully'
        this.props.fetchHistory()
        message.success(successMessage)
      }
    }, error => {
      this.setState({ isCancelSubscriptionVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onCancelDisconnectPartnerConfirmation = () => {
    this.setState({ isDisconnectPartnerVisible: false })
  }

  onConfirmDisconnectPartnerSubscription = () => {
    const { selectedCustomer, project } = this.props
    const { editSubscription } = this.state
    this.setState({ isModalLoading: true })
    const variables = {
      userId: selectedCustomer,
      subscriptionId: editSubscription,
      project
    }
    this.props.adminAccountDisconnect(variables).then(({ data }) => {
      if (data && data.adminAccountDisconnect && data.adminAccountDisconnect.error && data.adminAccountDisconnect.error.message) {
        const errorMessage = data.adminAccountDisconnect.error.message
        this.props.fetchHistory()
        this.setState({ isDisconnectPartnerVisible: false, isModalLoading: false })
        utilityService.handleError(errorMessage)
      } else {
        this.setState({ isDisconnectPartnerVisible: false, isModalLoading: false })
        const successMessage = data.adminAccountDisconnect && data.adminAccountDisconnect.success && data.adminAccountDisconnect.success.message ? data.adminAccountDisconnect.success.message : 'Disconnect Partner Account Successfully'
        this.props.fetchHistory()
        message.success(successMessage)
      }
    }, error => {
      this.setState({ isDisconnectPartnerVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  render () {
    const { subscription, isLoading, selectedCustomer, fetchHistory, project } = this.props
    const { editSubscription, width, height, columns, isEditSubscriptionVisible,
      isCancelSubscriptionVisible, isModalLoading, isRefundVisible, isEventsVisible, subscriptionId, isDisconnectPartnerVisible, isTransactionsVisible } = this.state
    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))
    return (
      <div className='general-customer-details-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(subscription) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={subscription} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
        <RefundModal
          isVisible={isRefundVisible}
          email={selectedCustomer}
          onRefund={this.removeRefund}
          handleCancel={this.toggleisRefundVisible}
          fetchHistory={fetchHistory} />
        <EditSubscriptionModal
          isVisible={isEditSubscriptionVisible}
          email={selectedCustomer}
          details={(subscription || []).find(item => item.id === editSubscription)}
          onEditSubscription={this.removeEditSubscription}
          onCancelEdit={this.toggleisEditSubscriptionVisible}
          fetchHistory={fetchHistory} />
        <ConfirmModal
          isVisible={isCancelSubscriptionVisible}
          title={'Cancel Subscription'}
          message={'Do you want to cancel the subscription?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onCancelSubscriptionConfirmation}
          handleSubmit={this.onConfirmCancelSubscription} />

        <ConfirmModal
          isVisible={isDisconnectPartnerVisible}
          title={'Disconnect Partner Account'}
          message={'Are you sure you want to Disconnect Partner Account?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onCancelDisconnectPartnerConfirmation}
          handleSubmit={this.onConfirmDisconnectPartnerSubscription} />
        <EventsModal
          isVisible={isEventsVisible}
          handleCancel={this.showEventsModal}
          subscriptionId={subscriptionId}
        />
        {isTransactionsVisible ? <TransactionTableModal
          isVisible={isTransactionsVisible}
          handleCancel={this.showTransactionsModal}
          subscriptionId={subscriptionId}
          project={project}
        /> : null}
      </div>
    )
  }
}

SubscriptionTableHyperion.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListSubscription,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const subscription = data && data.listSubscriptions ? data.listSubscriptions : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { subscription, isLoading: data && data.loading }
      }
    }
  ),
  graphql(
    MutationCancelSubscription, {
      options: ({ selectedCustomer, project, EditSubscriptionHyperionId }) => {
        return {
          update: (cache, { data: { adminCancelSubscription } }) => {
            try {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListSubscription, variables: { userId: selectedCustomer, project } }))
              if (adminCancelSubscription && adminCancelSubscription.success && adminCancelSubscription.success !== null) {
                if (adminCancelSubscription.data !== null) {
                  const index = (cacheData.listSubscriptions || []).findIndex((item) => item.id === adminCancelSubscription.data.id)
                  if (index !== -1) {
                    cacheData.listSubscriptions[index].status = 'cancelled'
                  }
                } else {
                  const index = (cacheData.listSubscriptions || []).findIndex((item) => item.id === EditSubscriptionHyperionId)
                  if (index !== -1) {
                    // cacheData.listSubscriptions.splice(index, 1)
                    cacheData.listSubscriptions[index].status = 'cancelled'
                  }
                }
              }
              cache.writeQuery({
                query: QueryListSubscription,
                data: cacheData,
                variables: { userId: selectedCustomer, project }
              })
            } catch (error) {

            }
          }

        }
      },
      props: (props) => ({
        cancelSubscription: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationAdminAccountDisconnect,
    {
      options: ({ selectedCustomer, project, EditSubscriptionHyperionId }) => {
        return {
          update: (cache, { data: { adminAccountDisconnect } }) => {
            try {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListSubscription, variables: { userId: selectedCustomer, project } }))
              if (adminAccountDisconnect && adminAccountDisconnect.success && adminAccountDisconnect.success !== null) {
                if (adminAccountDisconnect.data !== null) {
                  const index = (cacheData.listSubscriptions || []).findIndex((item) => item.id === adminAccountDisconnect.data.id)
                  if (index !== -1) {
                    cacheData.listSubscriptions[index].status = 'disconnected'
                  }
                } else {
                  const index = (cacheData.listSubscriptions || []).findIndex((item) => item.id === EditSubscriptionHyperionId)
                  if (index !== -1) {
                    cacheData.listSubscriptions.splice(index, 1)
                  }
                }
              }
              cache.writeQuery({
                query: QueryListSubscription,
                data: cacheData,
                variables: { userId: selectedCustomer, project }
              })
            } catch (error) {

            }
          }

        }
      },
      props: (props) => ({
        adminAccountDisconnect: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(SubscriptionTableHyperion))
