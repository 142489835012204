import React, { Component } from 'react'
import { Table, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
// import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
// import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
// import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import Tag from '../../../components/ui/dataDisplay/Tag'
import CreateTagModal from '../../../components/ui/dataEntry/other/CreateTagModal'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import AssetTransferModal from '../../../components/ui/dataEntry/other/AssetTransferModal'
import userMessages from '../../../constants/messages'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryTags from '../../../graphQL/asset/getTags'
import MutationTransferAssets from './../../../graphQL/admin/asset/transferAssets'
import MutationDeleteTag from './../../../graphQL/admin/asset/deleteTag'
// import QueryTagContent from '../../../graphQL/asset/getContentTags'
import QueryTagType from './../../../graphQL/admin/tagType/listTagType'

const options = [
  {
    type: 'delete',
    name: 'Delete'
  }
]
const deleteTagMessage = {
  title: 'DELETE TAG',
  firstMessage: 'Are you sure you want to delete this tag?',
  secondMessage: 'This action cannot be undone.'
}
const transferMessage = {
  title: 'TRANSFER TAG',
  lastMessage: 'Are you sure you want to transfer to another tag ? This action cannot be undone.'
}

class TagsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedTag: null,
      showTagCreation: false,
      isLoading: !props.tagList || !props.tagList.length,
      tagSearch: { searchString2: '' }
    }
    this.columns = [
      {
        title: 'Tag Name',
        dataIndex: 'key',
        key: 'name',
        width: '20%',
        render: this.renderTags,
        className: 'no-wrap'
      }, {
        title: 'Tag Type',
        dataIndex: 'type',
        width: '27%',
        key: 'type',
        render: this.renderTagType,
        className: 'no-wrap'
      }, {
        title: 'System Type',
        dataIndex: 'type',
        width: '20%',
        key: 'number',
        render: this.renderTagSystemType,
        className: 'no-wrap'
      }, {
        title: 'Created in',
        dataIndex: 'module',
        width: '20%',
        key: 'module',
        className: 'no-wrap'
      }, {
        title: 'Asset used',
        dataIndex: 'assetCount',
        width: '10%',
        key: 'assetCount',
        render: (assetCount, { isError }) => <div>{isError ? 'Error in Transfer. Retry' : assetCount}</div>,
        className: 'no-wrap'
      }, {
        title: 'Media used',
        dataIndex: 'mediaCount',
        width: '10%',
        key: 'mediaCount',
        render: (mediaCount, { isError }) => <div>{isError ? 'Error in Transfer. Retry' : mediaCount}</div>,
        className: 'no-wrap'
      }, {
        key: 'key',
        dataIndex: 'key',
        width: '3%',
        render: this.renderData,
        className: 'no-wrap'
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.tagList, this.props.tagList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.tagList, this.props.tagList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.tagList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = [ 'edit', 'transfer', 'delete' ]
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedTag) {
        this.setState({ openedTag: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedTag === id) {
        this.setState({ openedTag: null })
      } else {
        this.setState({ openedTag: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { tagList } = this.props
      const selectedTag = tagList.find(item => item.key === id)
      if (!selectedTag) {
        return
      }
      if (selectedOption && selectedOption.type === 'transfer') {
        this.setState({ showTransferAssetModal: true, selectedTag, fromSelectedTag: selectedTag, openedTag: null })
      } else if (selectedOption && selectedOption.type === 'delete') {
        this.setState({ showDeleteWarning: true, selectedTag, openedTag: null })
      }
    }

    showAddTag = () => {
      this.setState({ showCreateTagModal: true })
    }

    hideCreateTagModal = () => {
      this.setState({ showCreateTagModal: false })
    }

    handleDeleteTagCancel = () => {
      this.setState({ showDeleteWarning: false, selectedTag: null })
    }

    handleDeleteTagSubmit = () => {
      const { selectedTag } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedTag) {
        return
      }
      this.props.deleteTag(selectedTag.key).then(() => {
        this.setState({ showDeleteWarning: false, selectedTag: null })
        onHistoryUpdate()
      }, error => {
        this.setState({ showDeleteWarning: false, selectedTag: null })
        utilityService.handleError(error)
      })
    }

    showAddSystemTag = () => {
      this.setState({ showTagCreation: true })
    }

    onTagCreation = () => {
      this.setState({ showTagCreation: false })
    }

    handleCancelTagCreation = () => {
      this.setState({ showTagCreation: false })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: false }, () => {
        setTimeout(() => {
          this.setState({ isLoading: false })
        }, 1000)
      })
      this.props.onChangeTagFilter({ searchString: value })
    }

    handleMultipleDeleteTag = () => {
      this.setState({ isMultipleDelete: false })
    }

    hideMultipleDeleteTag = () => {
      this.setState({ isMultipleDelete: false })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    hideTransferAssetModal = () => {
      this.setState({ showTransferAssetModal: false, tagSearch: { searchString2: '' }, toSelectedTag: null })
    }

    onSearchOptions = (value, isFirst) => {
      let { tagSearch } = this.state
      if (isFirst) {
        tagSearch.searchString1 = value
      } else {
        tagSearch.searchString2 = value
      }
      this.setState({ tagSearch })
    }

    onTagSelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedTag: selectedValue })
      } else {
        this.setState({ fromSelectedTAg: selectedValue })
      }
    }

    handleTransferAssets = () => {
      const { fromSelectedTag, toSelectedTag } = this.state
      const { onHistoryUpdate } = this.props
      const request = {
        type: 'TAG',
        input: {
          oldValue: fromSelectedTag.key,
          newValue: toSelectedTag.key
        }
      }
      this.props.transferCategoryAssets(request).then(() => {
        onHistoryUpdate()
        this.setState({ showTransferAssetModal: false, fromSelectedTag: null, toSelectedTag: null, tagSearch: { searchString2: '' } })
      }, error => {
        this.setState({ showTransferAssetModal: false, fromSelectedTag: null, toSelectedTag: null })
        utilityService.handleError(error)
      })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.tagList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreTags(this.props.tagList.length))
    }

    renderTagType = (type) => {
      const typeMap = {
        MANUAL: 'Manual Tag'
        // VIMOND: 'System Tag',
        // BUCKET: 'System Tag',
        // TEAM: 'System Tag',
        // SPORT: 'System Tag',
        // COMPETITION: 'System Tag',
        // PRODUCT: 'System Tag',
        // SEARCH_FILTER: 'System Tag',
        // NOTIFICATION_ACTION_TYPE: 'System Tag',
        // NOTIFICATION_TYPE: 'System Tag',
        // PARTNER: 'System Tag',
        // WORKOUT_CLASS: 'System Tag',
        // WORKOUT_TYPE: 'System Tag',
        // TRAINER: 'System Tag',
        // LEVEL: 'System Tag',
        // INTENSITY: 'System Tag',
        // EQUIPMENT: 'System Tag',
        // PROVIDER: 'System Tag',
        // BODY_AREA: 'System Tag',
        // TARGET_AREA: 'System Tag'

      }
      return type && typeMap[type] ? typeMap[type] : 'System Tag'
    }

    renderTagSystemType = (type) => {
      const typeMap = {
        MANUAL: '-'
        // VIMOND: 'Vimond',
        // BUCKET: 'Bucket',
        // TEAM: 'Team',
        // SPORT: 'Sport',
        // COMPETITION: 'Competition',
        // PRODUCT: 'Product',
        // SEARCH_FILTER: 'Search Filter',
        // NOTIFICATION_ACTION_TYPE: 'Notification Action Type',
        // NOTIFICATION_TYPE: 'Notification Type',
        // PARTNER: 'Partner',
        // WORKOUT_CLASS: 'Workout Class',
        // WORKOUT_TYPE: 'Workout Type',
        // TRAINER: 'Trainer',
        // LEVEL: 'Level',
        // INTENSITY: 'Intensity',
        // EQUIPMENT: 'Equipment',
        // PROVIDER: 'Provider',
        // BODY_AREA: 'Body Area',
        // TARGET_AREA: 'Target Area'
      }
      return type && typeMap[type] ? typeMap[type] : `${type}`
    }

    renderTagModuleType = (module) => {
      const moduleMap = {
        ASSET_MANAGER: 'Asset Manager',
        CONTENT_BANK: 'Content Bank',
        MATCH_MANAGER: 'Match Manager',
        BUCKET_MANAGER: 'Bucket Manager',
        CHANNEL_MANAGER: 'Channel Manager',
        ADMIN_MANAGER: 'Admin Manager'
      }
      return module && moduleMap[module] ? moduleMap[module] : '-'
    }

    renderTags = id => {
      const { tagList } = this.props
      const selectedTag = (tagList || []).find(tag => tag.key === id)
      if (selectedTag) {
        return <Tag tagText={selectedTag.name} />
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedTag, isSelected } = this.state
      const { tagList } = this.props
      const selectedTag = (tagList || []).find(tag => tag.key === id)
      if (selectedTag && (selectedTag.assetCount || selectedTag.mediaCount)) {
        availableOptions.splice(0, 1)
      }
      if (selectedTag && (selectedTag.assetCount || selectedTag.mediaCount) && (parseInt(selectedTag.assetCount) || parseInt(selectedTag.mediaCount))) {
        const transferOption = {
          type: 'transfer',
          name: 'Transfer'
        }
        availableOptions.push(transferOption)
      }
      if (!availableOptions.length) {
        return null
      }
      return (
        <div className='option-list' id='option-list'>
          {!isSelected ? <PopoverButton
            button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
            displayParam='name'
            contents={availableOptions}
            onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
            parentCompoent={'option-list'}
            isVisible={openedTag === id}
            placement={'leftBottom'}
          /> : ''}
        </div>
      )
    }

    render () {
      const { tagList, tagFilter, onHistoryUpdate, changeTagFilter, systemTags } = this.props
      const { isLoading, isPaginating, selectedTag, showDeleteWarning, showTagCreation, isMultipleDelete, selectedRowKeys, isSelected, tagSearch, showTransferAssetModal, toSelectedTag, fromSelectedTag } = this.state
      let adminItem
      if (selectedTag && showDeleteWarning) {
        adminItem = <Tag tagText={selectedTag.name} />
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
        // getCheckboxProps: record => ({
        //   disabled: (record.assetCount !== 0 || record.mediaCount !== 0)
        // })
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            return <div className='admin-portal asset-tag-list'>
              <FilterInput
                searchString={tagFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                filterType={'Tag'}
                project={project}
                // changeFilter={(value) => {
                //   this.setState({ isSearching: true, filterData: value }, () => {
                //     this.changeFilterValue(value)
                //   })
                // }}
                changeFilter={changeTagFilter}
                placement='rightTop'
                // isClearFilter={isClearFilter}
                // initialSearch={initialSearch}
                // username={this.username}
                // bucketAssetFilter={filterData}
              />
              <div className='admin-list tag-list'>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > tagList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table
                      className={`general-table ${isPaginating ? 'paginating' : ''}`}
                      rowKey={record => record.key}
                      columns={this.columns}
                      dataSource={tagList}
                      pagination={false}
                      rowSelection={isSelected ? rowSelection : undefined} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {/* {!isSelected
                ? <MultipleSelectButton onClick={this.onMultipleSelect} />
                : <React.Fragment>
                  <BackArrowButton onClick={this.onMultipleDSelect} />
                  <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                </React.Fragment>
              } */}
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddSystemTag}
                  htmlType='submit'
                  buttonText={'Add System Tag'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                /> : null}
              </div>
              <CreateTagModal
                visible={showTagCreation}
                onTagCreation={this.onTagCreation}
                handleCancelTagCreation={this.handleCancelTagCreation}
                onHistoryUpdate={onHistoryUpdate}
                tagTypes={systemTags || []}
                project={project}
                tagFilter={tagFilter} />
              <AdminItemConfirmModal
                isVisible={showDeleteWarning}
                title={deleteTagMessage.title}
                firstMessage={deleteTagMessage.firstMessage}
                secondMessage={deleteTagMessage.secondMessage}
                adminItem={adminItem} rightButtonText={'Delete'}
                handleCancel={this.handleDeleteTagCancel}
                handleSubmit={this.handleDeleteTagSubmit}
                isSubmitButtonDisabled={isDeleteDisabled}
                isCancelButtonDisabled={false} />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'DELETE MULTIPLE TAGS'}
                message={userMessages.DELETE_MULTIPLE_TAGS}
                isLoading={false}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeleteTag}
                handleCancel={this.hideMultipleDeleteTag}
              />
              <AssetTransferModal
                type='tag'
                placeholder={'Select Tag'}
                tagSearch={tagSearch}
                isVisible={showTransferAssetModal}
                handleCancel={this.hideTransferAssetModal}
                onSubmitTransfer={this.handleTransferAssets}
                title={transferMessage.title}
                onOptionSelect={this.onTagSelect}
                selectedToValue={toSelectedTag}
                isImage={false}
                selectedFromValue={fromSelectedTag}
                lastMessage={transferMessage.lastMessage}
                onSearchOptions={this.onSearchOptions}
                project={project}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

TagsList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryTags,
    {
      options: ({ tagFilter, project }) => {
        // const { searchString, module } = tagFilter
        const tempFilter = utilityService.getFormattedTagFilter(tagFilter)
        // let filter = { }
        // if (searchString) {
        //   filter.name = { match: searchString }
        // }
        // if (module) {
        //   filter.module = { exact: module }
        // }
        const variables = {
          filter: tempFilter,
          limit: 30,
          offset: 0,
          project
        }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: ({ data, ownProps }) => {
        let tagList = data.listTags ? cloneDeep(data.listTags.items) : []
        tagList = tagList.map(item => {
          if (item.isTransferring) {
            item.assetCount = 'Transferring'
            item.mediaCount = 'Transferring'
          }
          return item
        })
        return {
          tagList,
          isLoading: data.loading || !data.listTags,
          totalCount: data.listTags ? data.listTags.totalCount : 0,
          getMoreTags: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: ownProps.project
              },
              updateQuery: (previous, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previous
                const prev = cloneDeep(previous)
                const newList = [...prev.listTags.items, ...fetchMoreResult.listTags.items]
                prev.listTags.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    QueryTagType,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { filter: null, limit: 500, offset: 0, project }
        }
      },
      props: (props) => {
        return {
          systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
        }
      }
    }
  ),
  graphql(
    MutationTransferAssets, {
      options: ({ tagFilter, project }) => ({
        update: (cache, { data: { transferAssets } }) => {
          const { searchString } = tagFilter
          let filter = utilityService.getFormattedTagFilter(tagFilter)
          if (searchString) {
            filter.name = { match: searchString }
          }
          const variables = { filter, limit: 30, offset: 0, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryTags, variables }))
          if (cacheData && cacheData.listTags && cacheData.listTags.items) {
            cacheData.listTags.items = cacheData.listTags.items.map(item => {
              if (transferAssets.includes(item.key)) {
                item.assetCount = 'Transferring'
                item.mediaCount = 'Transferring'
              }
              return item
            })
          }
          cache.writeQuery({
            query: QueryTags,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        transferCategoryAssets: (request) => {
          return props.mutate({
            variables: { ...request, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteTag, {
      options: ({ tagFilter, project }) => ({
        update: (cache, { data: { deleteTag } }) => {
          const { searchString } = tagFilter
          let filter = utilityService.getFormattedTagFilter(tagFilter)
          if (searchString) {
            filter.name = { match: searchString }
          }
          const variables = { filter, limit: 30, offset: 0, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryTags, variables }))
          if (cacheData && cacheData.listTags && cacheData.listTags.items) {
            const selectedTagIndex = cacheData.listTags.items.findIndex(item => item.key === deleteTag.key)
            if (selectedTagIndex > -1) {
              cacheData.listTags.items.splice(selectedTagIndex, 1)
              cacheData.listTags.totalCount = cacheData.listTags.totalCount - 1
            }
          }
          cache.writeQuery({
            query: QueryTags,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteTag: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  )

  //  graphql(
  //    QueryTagContent,
  //    {
  //      options: ({ project }) => {
  //        return {
  //          variables: { project }
  //        }
  //      },
  //      props: (props) => {
  //        return {
  //          systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
  //        }
  //      }
  //    }
  //  ),
)(TagsList))
