import gql from 'graphql-tag'

export default gql(`
    query listContentPlaceHolder($limit: Int $nextToken: String $filter: ContentPlaceHolderFilterInput $project: String){
        listContentPlaceHolder(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            project: $project
        ) {
        items{
            id
            name
            key
            usedCount
            metaFields{
                id
                tooltip
                displayName
                displayTitle
                type
                isRequired
                values
            }
        }
        nextToken
    }
}`)
