import React from 'react'
import PropTypes from 'prop-types'

const ArchiveConfigIcon = ({ width, height, color, onClick, title, isDisabled, ...props }) => (
  <svg width={width} height='37' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <rect fill={isDisabled ? '#AFAFAF' : '#343A40'} width='150' height='37' rx='2' />
      <text fontFamily='Lato-Bold, Lato' fontSize='12' fontWeight='bold' fill='#FFF'>
        <tspan x='14' y='23'>{title}</tspan>
      </text>
    </g>
  </svg>

)

ArchiveConfigIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

ArchiveConfigIcon.defaultProps = {
  width: 150,
  height: 37
  // color: '#FFF'
}

export default ArchiveConfigIcon
