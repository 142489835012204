import React, { Component } from 'react'
import { Table, Modal, Empty, Button } from 'antd'
import { Resizable } from 'react-resizable'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

class ServicesModal extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Service ID',
        dataIndex: 'serviceId',
        key: 'serviceId',
        width: 100
      },
      {
        title: 'Service Type',
        dataIndex: 'serviceType',
        key: 'serviceType',
        width: 100
      },
      {
        title: 'Service Status',
        dataIndex: 'serviceStatus',
        key: 'serviceStatus',
        width: 100
      },
      {
        title: 'Shared',
        dataIndex: 'shared',
        key: 'shared',
        width: 100,
        render: this.renderShared
      },
      {
        title: 'Status Change Date',
        dataIndex: 'statusChangeDate',
        width: 100,
        key: 'statusChangeDate',
        render: (statusChangeDate) => <div>{statusChangeDate ? moment(statusChangeDate).format('DD MMM YYYY') : '-'}</div>
      },
      {
        title: 'Subscriber ID',
        dataIndex: 'subscriberId',
        key: 'subscriberId',
        width: 100
      },
      {
        title: 'Recontraction Date',
        dataIndex: 'recontractionDate',
        key: 'recontractionDate',
        width: 100,
        render: (recontractionDate) => <div>{recontractionDate ? moment(recontractionDate).format('DD MMM YYYY') : '-'}</div>
      },
      {
        title: 'Product ID',
        dataIndex: 'productId',
        width: 100,
        key: 'productId'
      },
      {
        title: 'Plan Name',
        dataIndex: 'planName',
        key: 'planName',
        width: 100
      },
      {
        title: 'Plan ID',
        dataIndex: 'planId',
        key: 'planId',
        width: 100
      },
      {
        title: 'Plan Description',
        dataIndex: 'planDescription',
        key: 'planDescription',
        width: 100
      },
      {
        title: 'Line of Business',
        dataIndex: 'lineOfBusiness',
        key: 'lineOfBusiness',
        width: 100
      },
      {
        title: 'Activation Date',
        dataIndex: 'activationDate',
        key: 'activationDate',
        width: 100,
        render: (activationDate) => <div>{activationDate ? moment(activationDate).format('DD MMM YYYY') : '-'}</div>
      }
    ]

    this.state = {
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

      componentDidMount = () => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }

      UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
        if (newProps.isActive !== this.props.isActive) {
          setTimeout(() => {
            this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
          }, 100)
        }
      }

      renderShared=(shared) => {
        return <span>{shared ? 'True' : 'False'}</span>
      }

      handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
          const nextColumns = [...columns]
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width
          }
          return { columns: nextColumns }
        })
      }

      render () {
        const { services, isVisible, onCancel } = this.props
        const { width, height, columns } = this.state

        const column = columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: this.handleResize(index)
          })
        }))
        return (
          <div ref={this.tableSize}>
            <Modal
              className='general-modal services-details'
              title={'SERVICES'}
              maskClosable={false}
              visible={isVisible}
              closable={false}
              centered
              destroyOnClose
              width={'1000px'}
              footer={[
                <Button key='back' onClick={onCancel}>
                  Close
                </Button>]}
            >
              <div className='general-customer-details-table' ref={this.tableSize}>
                { !_.isEmpty(services) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={services} pagination={false} scroll={{ x: width, y: (height) }} />
                  : <div className='empty-container'> <Empty /> </div>}
              </div>
            </Modal>
          </div>
        )
      }
}

ServicesModal.propTypes = {
  /** visibility toggle for Modal */
  isVisible: PropTypes.bool,
  /** function to handle Back in Modal */
  onCancel: PropTypes.func

}

export default ServicesModal
