import gql from 'graphql-tag'

export default gql(`
    mutation createContentItem($media: ID $position: Int! $value: String $type: String! $assetId: ID! $project: String $metaFields: [CreateFieldInput]
        ) {
        createContentItem(
            input: {
                position: $position
                value: $value
                type: $type
                media: $media
                assetId: $assetId
                project: $project
                metaFields: $metaFields
            }
        ) {
            id
            position
            value
            type
            caption
            metaFields{
                id
                fieldID
                tooltip
                displayName
                displayTitle
                type
                isRequired
                values
            }
            media{
                id
                fileName
                createdAt
                name
                fileSize
                url
                tags{
                    name
                    type
                    key
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
            }
        }
    }`
)
