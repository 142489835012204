import * as React from 'react'
import { message, Switch } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryUserSettings from '../../../graphQL/mfaSettings/getUserSettings'
import QueryWitelistIPs from '../../../graphQL/mfaSettings/listWhitelistedIps'
import MutationUpdateConfig from '../../../graphQL/mfaSettings/updateConfig'
import MutationUpdateIpList from '../../../graphQL/mfaSettings/createOrUpdateIpWhitelistingItem'
import { utilityService } from '../../../services/UtilityService'
// import { validateIPaddress } from '../../../util/util'
import Accordion from '../../../components/ui/dataDisplay/Accordion'
import IpWhitelistModal from '../../../components/ui/dataEntry/other/IpWhitelistModal'
import AppContext from '../../../AppContext'
import { isIP } from 'is-ip'

class TwoFactorAuthenticationSettings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      isIPsWhitelistVisible: false,
      ipList: props.ipList && props.ipList.length ? _.cloneDeep(props.ipList) : []
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.ipList, this.props.ipList)) {
      this.setState({ ipList: _.cloneDeep(newProps.ipList) })
    }
  }

    onChange = (isEnabled) => {
      this.setState({ isLoading: true })
      this.props.updateConfig(isEnabled).then(() => {
        window.config.forceMFA = isEnabled
        this.props.onHistoryUpdate()
        this.setState({ isLoading: false })
        isEnabled ? message.success('Enabling MFA is now not mandatory for users.') : message.success('Enabling MFA is now mandatory for all users.')
      }, (error) => {
        utilityService.handleError(error)
        this.setState({ isLoading: false })
      })
    }

    showIPsWhitelist = () => {
      const { isIPsWhitelistVisible } = this.state
      if (isIPsWhitelistVisible) {
        this.setState({ isIPsWhitelistVisible: !isIPsWhitelistVisible, ipList: _.cloneDeep(this.props.ipList) })
        return
      }
      this.setState({ isIPsWhitelistVisible: !isIPsWhitelistVisible })
    }

    onAddNewIP = () => {
      const { ipList } = this.state
      const newIp = {
        ip: '',
        isNew: true
      }
      ipList.splice(0, 0, newIp)
      this.setState({ ipList })
    }

    onDeleteIp = (ip, isDeleteDisabled) => {
      if (isDeleteDisabled) {
        utilityService.handleError('Access denied')
        return
      }
      const { ipList } = this.state
      const { project } = this.props
      const index = ipList.findIndex(item => item.ip === ip)
      if (index > -1) {
        const tempItem = ipList.splice(index, 1)
        const variables = ipList.map(item => ({
          ip: item.ip,
          project
        }))
        this.props.createOrUpdateIpWhitelistingItem(variables).then(() => {
          this.setState({ ipList })
          this.props.onHistoryUpdate()
          message.success('IP has been removed successfully')
        }, (errorMessage) => {
          ipList.splice(0, 0, tempItem[0])
          this.setState({ ipList })
          utilityService.handleError(errorMessage)
        })
      }
    }

    onSaveIp = (newIp) => {
      const { ipList } = this.state
      const { project } = this.props
      const duplicateArr = ipList.filter(item => item.ip === newIp.ip)
      if (!!duplicateArr && duplicateArr.length >= 2) {
        message.warn('Please remove duplicate IP')
        return
      }
      const splitIp = (newIp.ip).split('/')
      const isIpValid = isIP(splitIp[0])
      let isValidMask = true
      if (splitIp.length > 2) {
        const maskVal = newIp.ip.slice(newIp.ip.indexOf('/'))
        isValidMask = /^\/{1}[0-9]{1,2}$/.test(maskVal)
      }
      if (isIpValid && isValidMask) {
        ipList[0].isLoading = true
        this.setState({ ipList })
        const variables = ipList.map(item => ({
          ip: item.ip,
          project
        }))
        this.props.createOrUpdateIpWhitelistingItem(variables).then(() => {
          delete ipList[0].isLoading
          delete ipList[0].isNew
          this.setState({ ipList })
          this.props.onHistoryUpdate()
          message.success('New IP has been added successfully')
        }, (errorMessage) => {
          ipList[0].isNew = true
          delete ipList[0].isLoading
          this.setState({ ipList })
          utilityService.handleError(errorMessage)
        })
      } else message.warn('Please enter a valid IP')
    }

    onChangeNewIp = ({ target }) => {
      const { ipList } = this.state
      const index = ipList.findIndex(item => item.isNew)
      if (index > -1) {
        ipList[index].ip = target.value
        this.setState({ ipList })
      }
    }

    render () {
      const { isEnabled } = this.props
      const { isLoading, isIPsWhitelistVisible, ipList } = this.state

      return <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          return <Accordion
            title='2FA Settings'
            childComponent={
              <div className='mfa-admin-container'>
                <div className='mfa-allow-button'>
                  <span>Allow User to Disable MFA</span>
                  <Switch checked={isEnabled} loading={isLoading} onChange={this.onChange} disabled={isUpdateDisabled} />
                </div>
                <div className='whitelist'>
                  <span>{`{${ipList.length}}IPs Whitelisted`}</span>
                  <span className='pink-text' onClick={this.showIPsWhitelist}>{`Edit IP Whitelist`}</span>
                </div>
                <IpWhitelistModal
                  isVisible={isIPsWhitelistVisible}
                  ipList={_.cloneDeep(ipList)}
                  onAddNewIP={this.onAddNewIP}
                  onDeleteIp={(ip) => this.onDeleteIp(ip, isDeleteDisabled)}
                  onSaveIp={this.onSaveIp}
                  onChangeNewIp={this.onChangeNewIp}
                  onCancel={this.showIPsWhitelist}
                  isUpdateDisabled={isUpdateDisabled} />
              </div>
            }
          />
        }}
      </AppContext.Consumer>
    }
}

export default withApollo(compose(
  graphql(
    QueryUserSettings,
    {
      options: () => {
        return {
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data }) => {
        return {
          isEnabled: data && data.get2FAConfig ? data.get2FAConfig.isEnabled : false
        }
      }
    }
  ),
  graphql(
    QueryWitelistIPs,
    {
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          project
        }
      },
      props: ({ data }) => {
        return {
          ipList: data && data.listWhitelistedIps && data.listWhitelistedIps.length ? data.listWhitelistedIps : []
        }
      }
    }
  ),
  graphql(
    MutationUpdateConfig, {
      options: {
        update: (cache, { data: { updateConfig } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserSettings }))
          if (cacheData && cacheData.get2FAConfig) {
            cacheData.get2FAConfig.isEnabled = updateConfig.isEnabled
          }
          cache.writeQuery({
            query: QueryUserSettings,
            data: cacheData
          })
        }
      },
      // options: () => {
      //   return {
      //     refetchQueries: () => [{ query: QueryUserSettings }]
      //   }
      // },
      props: (props) => ({
        updateConfig: (isEnabled) => {
          return props.mutate({
            variables: { id: 'auth',
              isEnabled,
              project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateIpList, {
      options: ({ project }) => ({
        update: (cache, { data: { createOrUpdateIpWhitelistingItem } }) => {
          const variables = { project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryWitelistIPs, variables }))
          if (cacheData && cacheData.listWhitelistedIps) {
            cacheData.listWhitelistedIps = createOrUpdateIpWhitelistingItem
          }
          cache.writeQuery({
            query: QueryWitelistIPs,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        createOrUpdateIpWhitelistingItem: (input) => {
          return props.mutate({
            variables: { input }
          })
        }
      })
    }
  )

)(TwoFactorAuthenticationSettings))
