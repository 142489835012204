/* eslint-disable  no-unused-vars */

import React, { useState, useRef } from 'react'
import bitmovinPlayer from '../bitmovin/bitmovin'
import VolumeSlider from './volumeSlider'

const VolumeButton = props => {
  const { showPlayer } = props
  const [isMuted, setMuted] = useState(false)
  const [showSlider, setShowSlider] = useState(false)
  const timeout = useRef()
  const handleMuteButton = () => {
    setMuted(current => {
      if (!bitmovinPlayer.isMuted()) {
        bitmovinPlayer.mutePlayer()
      } else {
        bitmovinPlayer.unMutePlayer()
      }
      return bitmovinPlayer.isMuted()
    })
  }

  const autohideSlider = hide => {
    if (timeout.current) clearTimeout(timeout.current)
    if (hide) {
      timeout.current = setTimeout(() => {
        setShowSlider(false)
      }, 800)
    } else {
      setShowSlider(true)
    }
  }

  return (
    <>
      <div
        className={`volume ${bitmovinPlayer.isMuted() ? 'off' : 'on'}`}
        onClick={handleMuteButton}
        onMouseEnter={() => autohideSlider(false)}
        onMouseLeave={() => autohideSlider(true)}
      />
      {showSlider && showPlayer ? (
        <VolumeSlider isMuted={isMuted} autohideSlider={autohideSlider} />
      ) : null}
    </>
  )
}
export default VolumeButton
