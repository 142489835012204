import React from 'react'
import { Icon, Tooltip } from 'antd'
// import PropTypes from 'prop-types'

import GroupDragAndDropIcon from './../general/icons/GroupDragAndDropIcon'

import './../ui.style.scss'

class ListAppConfigsCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }
  render () {
    const { configInstance, handleListConfigInstanceStatus, isUpdateDisabled, onDoubleClick } = this.props
    const filterConfig = (configInstance.fieldValues || []).filter(item => item.configField.useAsTitle === true)[0]

    return (
      <div className='config-group-cell config-group-general'>
        <div onDoubleClick={() => onDoubleClick(configInstance.id)} className='type'>
          <div className='icons'>
            <GroupDragAndDropIcon />
          </div>
          <p>{ filterConfig && filterConfig.value }</p>
        </div>
        <div className={isUpdateDisabled ? 'disable' : ''}>
          {configInstance.isEnabled ? <Tooltip title={configInstance.isEnabled ? 'Click to disable in Client API' : 'Click to enable in Client API'}><Icon style={{ fontSize: '24px', color: '#08c' }} type='eye' onClick={() => handleListConfigInstanceStatus(configInstance, false)} /></Tooltip >
            : <Tooltip title={configInstance.isEnabled ? 'Click to disable in Client API' : 'Click to enable in Client API'}><Icon style={{ fontSize: '24px', color: '#cfd0d1' }} type='eye-invisible' onClick={() => handleListConfigInstanceStatus(configInstance, true)} /></Tooltip >}
        </div>
      </div>
    )
  }
}
ListAppConfigsCell.propTypes = {

}

ListAppConfigsCell.defaultProps = {
}

export default ListAppConfigsCell
