import React from 'react'
import PropTypes from 'prop-types'

const TrimInIcon = ({ width, height, className, onMouseDown, style, ...props }) => (
  <svg width='9' height='19' viewBox='0 0 9 19' className={className} onMouseDown={onMouseDown} style={style}>
    <path fill='#FFF' fillRule='evenodd' d='M0 0h9v5H4v10h5v4H0V5z' />
  </svg>
)

TrimInIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TrimInIcon.defaultProps = {
  width: 20,
  height: 20
}

export default TrimInIcon
