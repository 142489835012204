import React from 'react'
import PropTypes from 'prop-types'

const SearchCustomerIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd' stroke='#CFD0D1' strokeLinejoin='round'>
      <path strokeLinecap='round' strokeWidth='4.312' d='M77.255 71.994C73.8 65.556 66.9 59.974 58.355 57.45c4.771-3.203 7.919-8.652 7.919-14.835 0-9.859-7.985-17.848-17.835-17.848-9.846 0-17.829 7.99-17.829 17.848 0 6.185 3.144 11.636 7.918 14.838-8.65 2.555-15.614 8.245-19.022 14.783' />
      <path strokeWidth='4.312' d='M94.61 48.5c0 25.557-20.698 46.275-46.23 46.275-25.531 0-46.23-20.718-46.23-46.275S22.85 2.225 48.38 2.225c25.532 0 46.23 20.718 46.23 46.275z' />
      <path strokeLinecap='round' strokeWidth='6.228' d='M85.303 77.99l33.06 29.928' />
    </g>
  </svg>
)

SearchCustomerIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

SearchCustomerIcon.defaultProps = {
  width: 122,
  height: 112
}

export default SearchCustomerIcon
