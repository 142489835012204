import gql from 'graphql-tag'

export default gql(`
    mutation createAudit($value: String $name: String $contentId: ID $project: String!) {
        createAudit(
            input: {
                action: NAVIGATE,
                contentId: $contentId,
                module: CUSTOMER_MANAGER,
                project: $project,
                contentName: "NAVIGATE",
                contentType: NAVIGATE,
                updatedObj: [
                {
                    name: $name,
                    value: $value,
                    oldValue: ""
                }
                ]
            }
        ) {
            id
            contentType
            contentName
            action
            updatedAt
            updatedBy
            updatedObj{
                isPosChange
                name
                value
                oldValue
            }
        }
    }`
)
