import gql from 'graphql-tag'

export default gql(`
    mutation ($id: ID! $fields: [CreateFieldInput], $channelId: ID, $version: String $streams: [ID] $schemaId: ID $name: String $position: Int) {
        updateChannelConfigGroup(input: {
            id: $id
            fields: $fields,
            version: $version
            streams: $streams
            schemaId: $schemaId
            name: $name
            position: $position
            }, 
            channelId: $channelId 
        ) {
            id
            schemaId{
                id
              }
            position
            version
            name
            fields{
                name
                value
                isRequired
                type
            }
            streams{
                id
                levelVersions{
                    streamId
                    version
                }
                fields{
                    name
                    value
                    isRequired
                    type
                }
                url
                streamSchema{
                    id
                    levels{
                        id
                        name
                        version
                        fields{
                            name
                            value
                            isRequired
                            type
                        }
                    }
                }
            }
        }
   }`
)
