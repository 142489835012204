import gql from 'graphql-tag'

export default gql(`
query($assetId: ID! $project: String) {
  listPublishRules( 
    id: $assetId
    project: $project
    ){
      id
      assetId
      group{
        id
      }
      isEnabled
      publishEndDate
      publishStartDate
      }
}`)
