import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import GroupDragAndDropIcon from './../general/icons/GroupDragAndDropIcon'
import ExpandMenuIcon from './../general/icons/ExpandMenuIcon'
import PopoverButton from './../general/buttons/PopoverButton'
// import { generateCroppedThumbnail } from '../../../util/util'

import './../ui.style.scss'
import GroupCompetitionIcon from '../general/icons/GroupCompetitionIcon'

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

// const defaultImage = require('../../../assets/images/avatar.jpg')

class BucketGroupTypeCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showOptions: false
    }
  }

    onSelectOption = (selectedOption) => {
      const { bucketGroup } = this.props
      this.props.toggleShowOptions(bucketGroup.id)
      if (selectedOption && selectedOption.type === 'edit') {
        this.props.handleBucketGroupType(bucketGroup, 'edit')
      } else if (selectedOption && selectedOption.type === 'delete') {
        this.props.handleBucketGroupType(bucketGroup, 'delete')
      }
    }
    hideEditGroupTypeModal = () => {
      this.setState({ showEditGroupTypeModal: false })
    }

    handleEditGroupType = () => {
      this.setState({ showEditGroupTypeModal: false })
    }

    hideDeleteGroupTypeModal = () => {
      this.setState({ showDeleteGroupTypeModal: false })
    }

    handleDeleteGroupType = () => {
      this.setState({ showDeleteGroupTypeModal: false })
    }

    render () {
      const { bucketGroup, isOpened, isUpdateDisabled, isDeleteDisabled } = this.props
      let availableOptions = _.cloneDeep(options)
      if (bucketGroup && !bucketGroup.usedCount && !isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      return (
        <div className='bucket-group-cell bucket-group-general'>
          <div className='type'>
            <div className='icons'>
              <GroupDragAndDropIcon />
              <GroupCompetitionIcon />
              {/* <img src={bucketGroup.media && bucketGroup.media.length && bucketGroup.media[ 0 ] ? generateCroppedThumbnail(bucketGroup.media[0], 25, 25, '1:1') : defaultImage} /> */}
            </div>
            <p>{ bucketGroup.name }</p>
          </div>
          <div className='name'> {bucketGroup.usedCount } </div>
          <div className='expand'>
            <div className='option-list' id='option-list'>
              { availableOptions && availableOptions.length ? <PopoverButton
                button={<div onClick={() => this.props.toggleShowOptions(bucketGroup.id)} > <ExpandMenuIcon /> </div>}
                displayParam='name'
                contents={availableOptions}
                onContentClick={this.onSelectOption}
                parentCompoent={'option-list'}
                isVisible={isOpened}
                placement={'leftBottom'}
              /> : null }
            </div>
          </div>
        </div>
      )
    }
}
BucketGroupTypeCell.propTypes = {
  /** file name of BucketGroupTypeCell. */
  name: PropTypes.string,
  /** close action of BucketGroupTypeCell. */
  onClose: PropTypes.func,
  /** File index of BucketGroupTypeCell. */
  index: PropTypes.number
}

export default BucketGroupTypeCell
