import gql from 'graphql-tag'

export default gql(`
    query listAssetTypes($filter: ListAssetTypesFilterInput $limit: Int $nextToken: String $project: String){
        listAssetTypes( 
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            project: $project
        ) {
        items {
            id
            title
            position
            isActive
            isDefault
            isLveToVodSupported
            isMatchRelated
            autoPublish
            videoSection
            contentSection
            programSection
            authorSection
            matchSection
            usedCount
            entertainmentMetadata
            creditSection
            parentalRatingSection
            assetTypeCategory
            metaFields{
                id
                tooltip
                displayTitle
                displayName
                type
                isRequired
                values
                section{
                    id
                    title
                }
            }
            icon{
                id
                url
                fileName
                name
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            draftIcon{
                id
                url
                fileName
                name
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
        }
        nextToken
    }
}`)
