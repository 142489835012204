import gql from 'graphql-tag'

export default gql(`
query($id: ID!, $project:String) {
    getPartner(
        id: $id
        project: $project
        ) {
            id
            externalId
            name
            address
            website
            termsAndConditions
            tagLine
            apps
            category
            offerCount
            logo{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
            }
            images{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    drm
                    codec
                    Url
                    iv
                    transport
                    contentKey
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
            }
            shortDescription
            mediumDescription
            description
            isActive
            chargeCode
            refundChargeCode
            slug
            seoKeywords
            seoMetaDescription
            seoTitle
            isArchived
            attributes{
                key
                id
                type
                limit
                value
                toggleValue 
                partnerId
                name
                label
                position
            }
            customMeta{
                config{
                    id
                    name
                    type
                    section
                    default
                }
                value
            }
        }
    }
    `)
