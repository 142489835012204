import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import OfferPriceFieldCell from './../../components/ui/dataDisplay/OfferPriceFieldCell'
import Button from '../../components/ui/general/buttons/Button'

class OfferPrice extends React.Component {
  constructor (props) {
    super(props)
    const price = props.offerPriceDetails
    this.state = {
      priceDetails: price
    }
  }

  componentDidMount () {
    if (this.props.offerPriceDetails) {
      this.setFormattedDates(this.props.offerPriceDetails)
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (newProps.offerPriceDetails && !_.isEqual(this.props.priceDetails, newProps.offerPriceDetails)) {
      this.setFormattedDates(newProps.offerPriceDetails)
    } else if (!newProps.offerPriceDetails && !_.isEqual(newProps.offerPriceDetails, this.props.offerPriceDetails)) {
      this.setState({ priceDetails: null })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.offerPriceDetails && !_.isEqual(prevProps.offerPriceDetails, this.props.offerPriceDetails) && this.props.offerPriceDetails.startDate && this.props.offerPriceDetails.endDate) {
      this.setFormattedDates(this.props.offerPriceDetails)
    }
  }

  getMonthasString=(month) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const monthString = months[month]
    return monthString
  }

  setFormattedDates=(offerPriceDetails) => {
    if (offerPriceDetails) {
      const formattedDatesArray = (offerPriceDetails || []).map((offerPrice, index) => {
        if (offerPrice && offerPrice.startDate && offerPrice.endDate) {
          const formattedDate = this.convertDateString([offerPrice.startDate, offerPrice.endDate], index)
          const priceData = { ...offerPrice }
          priceData.startDate = formattedDate[0] || ''
          priceData.endDate = formattedDate[1] || ''
          return priceData
        } else if (offerPrice && !offerPrice.startDate && !offerPrice.endDate) {
          const priceData = { ...offerPrice }
          return priceData
        }
      })
      this.setState({ priceDetails: formattedDatesArray }, () => this.checkIfPriceActive())
    }
  }

    getFormattedDateString=(date) => {
      const day = date.getDate()
      const month = this.getMonthasString(date.getMonth())
      const year = date.getFullYear()
      const hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
      const mins = (date.getMinutes() + '').length > 1 ? date.getMinutes() : '0' + date.getMinutes()
      const timeNotation = (date.getHours() > 11 ? 'PM' : 'AM')
      const dateString = day + ' ' + month + ' ' + year + ', ' + hour + ':' + mins + ' ' + timeNotation
      return dateString
    }

    convertDateString=(dates) => {
      if (dates) {
        const formattedDate = (dates || []).map(date => {
          let formattedDate
          if (date) {
            let newDate = new Date(date)
            formattedDate = this.getFormattedDateString(newDate)
          } else {
            formattedDate = ''
          }
          return formattedDate
        })
        return formattedDate
      } else {
        return []
      }
    }

    checkIfPriceActive=() => {
      const offerPriceDetails = this.state.priceDetails
      if (offerPriceDetails) {
        const newOfferPriceDetails = (offerPriceDetails || []).map(price => {
          let tempPrice = {}
          if (price) {
            if (price.promoId && this.props.activePricesWithPromoId.hasOwnProperty(`${price.promoId}`) && this.props.activePricesWithPromoId[`${price.promoId}`] === price.id) {
              tempPrice = { ...price, active: true }
            } else if (!price.promoId && this.props.activePricesWithPromoId.hasOwnProperty('nopromoid') && this.props.activePricesWithPromoId['nopromoid'] === price.id) {
              tempPrice = { ...price, active: true }
            } else {
              tempPrice = { ...price, active: false }
            }
            return tempPrice
          }
        })
        this.setState({ priceDetails: newOfferPriceDetails })
      }
    }

    render () {
      const { onRemovePriceField, handlePriceModal, handleEditPrice, isUpdateDisabled, isCreateDisabled, isDeleteDisabled, priceMetaFieldList } = this.props
      const { priceDetails } = this.state
      const offerPriceFieldCells = (priceDetails || []).map((offerPrice, index) =>
        offerPrice ? <OfferPriceFieldCell priceData={offerPrice} onRemovePriceField={onRemovePriceField} fieldId={index} key={offerPrice.id} handleEditPrice={handleEditPrice} active={offerPrice.active ? offerPrice.active : false} isDisabled={isUpdateDisabled} isDeleteDisabled={isDeleteDisabled} priceMetaFieldList={priceMetaFieldList} /> : null
      )

      return (
            <>
              <div className='offer-price'>
                {offerPriceFieldCells}
              </div>
              <div style={{ width: '150px', margin: '10px 20px 20px 10px' }}>
                <Button
                  buttonText={'Add Price'}
                  color='red'
                  style={{ width: '150px' }}
                  onClick={handlePriceModal}
                  isDisabled={isCreateDisabled}
                />
              </div>
            </>
      )
    }
}

OfferPrice.propTypes = {
  // array of Price Details
  offerPriceFieldCells: PropTypes.array,
  // function to removePriceFieldCell
  onRemovePriceField: PropTypes.func,
  /** function to handle price edit */
  handleEditPrice: PropTypes.func,
  /** function to handle add price */
  handlePriceModal: PropTypes.func,
  /** current active Price */
  activePrice: PropTypes.string,
  /** Handle change of Active Price */
  handleActivePriceChange: PropTypes.func
}

export default OfferPrice
