import * as React from 'react'
import { Row, Col, Skeleton, Empty, message } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import AppContext from '../../AppContext'

import ChannelList from './ChannelList'
import ChannelConfigList from './ChannelConfigList'
import { CreateChannelModal } from '../../components/ui/dataEntry/other/CreateChannelModal'
import { MasterConfigModal } from '../../components/ui/dataEntry/other/MasterConfigModal'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import AddImageModal from './../../components/ui/dataEntry/other/AddImageModal'
import CropImageModal from './../../components/ui/dataEntry/other/CropImageModal'

import { getCropImageUrl } from '../../util/util'
import { utilityService } from '../../services/UtilityService'
import './ChannelManager.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetSettings from '../../graphQL/channel/getSettings'
import QueryListChannel from '../../graphQL/channel/listChannel'
import QueryListConfigGroupSchema from '../../graphQL/channel/listConfigGroup'
import MutationCreateChannel from '../../graphQL/channel/createChannel'
import MutationUpdateChannel from '../../graphQL/channel/updateChanel'
import MutationUpdateMasterConfig from '../../graphQL/channel/updateMasterConfig'
import MutationUpdateMedia from '../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from '../../graphQL/content/updateMediaSettings'

let channelSearchStr = null
let initialSearchFilter = false
let channelFilter = {}
const buffer = 30000

const getSortedList = (data) => {
  return (cloneDeep(data) || []).sort((a, b) => {
    const regxAlpha = /[^a-zA-Z]/g
    const regxNumber = /[^0-9]/g
    const firstString = (a.id).toLowerCase().replace(regxAlpha, '')
    const secondString = b.id.toLowerCase().replace(regxAlpha, '')
    if (firstString === secondString) {
      const firstNumber = parseInt(a.id.replace(regxNumber, ''), 10)
      const secondNumber = parseInt(b.id.replace(regxNumber, ''), 10)
      return firstNumber === secondNumber ? 0 : firstNumber > secondNumber ? 1 : -1
    } else {
      return firstString > secondString ? 1 : -1
    }
  })
}

class ChannelManager extends React.Component {
  constructor (props) {
    super(props)
    const selectedId = props.channelList && props.channelList.length ? props.channelList[0].id : ''
    if (!props.match.params.id) { this.props.history.push(`/channels/${selectedId}`) }
    const aspectRatio = selectedId && props.channelList[0].icon ? props.channelList[0].icon.aspectRatio.find(item => item.title === '1:1') : {}
    this.state = {
      isCreateClicked: false,
      isEditClicked: false,
      editConfigData: { },
      channelName: '',
      channelCode: '',
      isMasterConfigVisible: false,
      channelConfigGroup: selectedId ? props.channelList[0].configGroups : [ ],
      configGroupList: props.configGroupList ? props.configGroupList : [ ],
      selectedId: selectedId,
      selectChannelName: selectedId ? props.channelList[0].name : '',
      selectChannelCode: selectedId ? props.channelList[0].channelCode : '',
      activeConfig: '',
      channelLogo: selectedId && props.channelList[0].icon ? this.getImageUrl(props.channelList[0].icon) : '',
      isFromAddChannel: true,
      createChannelLoading: false,
      isChannelLoading: props.channelLoading ? props.channelLoading : false,
      isSaveConfigLoading: false,
      showAddImageModal: false,
      showCropModal: false,
      shouldShowWarning: false,
      isSaveLoading: false,
      isMasterLoading: false,
      isChannelHistory: false,
      channelList: selectedId ? props.channelList : [],
      selectedImage: selectedId && props.channelList[0].icon ? props.channelList[0].icon : {},
      tempSelectedImage: {},
      settings: !_.isEmpty(aspectRatio) && props.channelList[0].icon ? props.channelList[0].icon.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio) : [],
      aspectRatio: !_.isEmpty(aspectRatio) ? aspectRatio : {},
      isClearFilter: false,
      searchString: props.match.params.id ? props.match.params.id : '',
      isImageModified: false,
      defaultTrimStart: '',
      defaultTrimEnd: ''
    }
    if (props.match.params.id) {
      this.initialFilter = props.match.params.id
    }
    if (props.channelList && props.channelList.length) {
      const timeout = this.getNextUpdatingProgramDelay(props.channelList)
      this.programRefreshTimeout = setTimeout(this.refetchChannelList, timeout * 1000 + buffer)
    }
  }

  componentDidMount = () => {
    document.title = 'Channel Manager - Optus CMS'
  }

  componentWillUnmount () {
    channelSearchStr = null
    channelFilter = {}
    if (this.programRefreshTimeout) {
      clearTimeout(this.programRefreshTimeout)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.channelList && nextProps.channelList.length && !_.isEqual(nextProps.channelList, this.props.channelList)) {
      const newId = _.find(nextProps.channelList, (item) => item.id === this.props.match.params.id)
      const selectedId = this.state.selectedId && !_.isEmpty(_.find(nextProps.channelList, (item) => item.id === this.state.selectedId))
        ? this.state.selectedId : (!_.isEmpty(newId) ? this.props.match.params.id : nextProps.channelList[ 0 ].id)
      const selectedItem = _.find(nextProps.channelList, (items) => items.id === selectedId)
      const aspectRatio = selectedItem && selectedItem.icon ? selectedItem.icon.aspectRatio.find(item => item.title === '1:1') : {}
      if (!this.props.match.params.id || this.props.match.params.id !== selectedId) { this.props.history.push(`/channels/${selectedId}`) }
      this.setState({
        channelList: _.cloneDeep(nextProps.channelList),
        selectedId,
        selectChannelName: selectedItem ? selectedItem.name : '',
        selectChannelCode: selectedItem ? selectedItem.channelCode : '',
        // channelCode: selectedItem ? selectedItem.channelCode : '',
        channelLogo: selectedItem && selectedItem.icon ? this.getImageUrl(selectedItem.icon) : '',
        channelConfigGroup: selectedItem ? selectedItem.configGroups : [],
        isChannelLoading: nextProps.channelLoading,
        selectedImage: (this.state.showAddImageModal || this.state.isFromAddChannel) ? {} : (selectedItem ? selectedItem.icon : {}),
        aspectRatio,
        settings: selectedItem && selectedItem.icon ? selectedItem.icon.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio) : []
      })
      const timeout = this.getNextUpdatingProgramDelay(nextProps.channelList)
      if (timeout && timeout > 0) {
        if (this.programRefreshTimeout) {
          clearTimeout(this.programRefreshTimeout)
        }
        this.programRefreshTimeout = setTimeout(this.refetchChannelList, timeout * 1000 + buffer)
      }
    } else if (nextProps.channelList && !_.isEqual(nextProps.channelList, this.props.channelList)) {
      // this.props.history.push(`/channels/}`)
      this.setState({
        selectedId: '',
        selectChannelName: '',
        selectChannelCode: '',
        channelCode: '',
        channelList: [],
        isChannelLoading: nextProps.channelLoading
      })
    }
    if (this.state.isChannelLoading && !nextProps.channelLoading) {
      this.setState({ isChannelLoading: false })
    }
    if (!_.isEqual(nextProps.configGroupList, this.props.configGroupList)) {
      this.setState({ configGroupList: nextProps.configGroupList })
    }
    if (this.initialFilter) {
      const archive = {
        isArchived: {
          match: null
        }
      }
      this.onSearchChannel(this.initialFilter, archive)
      this.initialFilter = null
    }
  }

  refetchChannelList = () => {
    const { searchString, appliedFilter } = this.state
    const variables = utilityService.getFormattedChannelFilter(searchString, appliedFilter)
    clearTimeout(this.programRefreshTimeout)
    this.props.getChannelList(variables).then(() => {
      this.setState({ channelLoading: false })
    })
  }

  onSearchChannel = (searchStr, initialFilter) => {
    this.setState({ searchString: searchStr, appliedFilter: initialFilter })
    channelSearchStr = searchStr
    if (initialSearchFilter) {
      initialSearchFilter = false
      if (channelFilter && channelFilter.isArchived && channelFilter.isArchived.match === null) {
        delete channelFilter.isArchived
      }
    }
    const variables = utilityService.getFormattedChannelFilter(channelSearchStr, initialFilter || channelFilter)
    this.setState({ selectedId: '', channelLoading: true }, () => {
      this.props.getChannelList(variables).then(() => {
        this.setState({ channelLoading: false })
      })
    })
  }

  getNextUpdatingProgramDelay = channelList => {
    let smallestDelay
    channelList.map(channel => {
      const channelCurrentProgram = channel.programs.current
      const channelNextProgram = channel.programs.next
      if (channelCurrentProgram && channelCurrentProgram.program) {
        const channelCurrentProgramDelay = moment(moment(channelCurrentProgram.program.broadCastEndTime)).diff(moment(new Date()), 'second') + 60
        if (smallestDelay && smallestDelay > channelCurrentProgramDelay) {
          smallestDelay = channelCurrentProgramDelay
        } else if (!smallestDelay) {
          smallestDelay = channelCurrentProgramDelay
        }
      }
      if (channelNextProgram && channelNextProgram.program) {
        const channelNextProgramDelay = moment(moment(channelNextProgram.program.broadCastStartTime)).diff(moment(new Date()), 'second')
        if (smallestDelay && smallestDelay > channelNextProgramDelay) {
          smallestDelay = channelNextProgramDelay
        } else if (!smallestDelay) {
          smallestDelay = channelNextProgramDelay
        }
      }
    })
    return smallestDelay
  }

  cancelArchive = () => {
    this.setState({ shouldShowWarning: false })
  }

  onArchiveSubmit = () => {
    if (this.state.isSaveLoading) {
      return
    }
    const selectedId = this.state.selectedId
    const selectedChannel = _.find(this.state.channelList, (item) => selectedId === item.id)
    this.setState({ selectedId: '', isSaveLoading: true }, () => {
      this.props.updateChannel(selectedId, selectedChannel.name, (selectedChannel.icon ? selectedChannel.icon.id : null), true, selectedChannel.channelCode, selectedChannel.site, selectedChannel.defaultTrimStart, selectedChannel.defaultTrimEnd).then(() => {
        this.setState({ shouldShowWarning: false, isSaveLoading: false })
      }, error => {
        utilityService.handleError(error)
        this.setState({ shouldShowWarning: false, isSaveLoading: false })
      })
    })
  }

  onArchiveClick = () => {
    this.setState({ shouldShowWarning: true })
  }

  cancelRestore = () => {
    this.setState({ shouldShowRestoreWarning: false })
  }

  onRestoreSubmit = () => {
    if (this.state.isSaveLoading) {
      return
    }
    const selectedId = this.state.selectedId
    const selectedChannel = _.find(this.state.channelList, (item) => selectedId === item.id)
    this.setState({ selectedId: '', isSaveLoading: true }, () => {
      this.props.updateChannel(selectedId, selectedChannel.name, (selectedChannel.icon ? selectedChannel.icon.id : null), false, selectedChannel.channelCode, selectedChannel.site, selectedChannel.defaultTrimStart, selectedChannel.defaultTrimEnd).then(() => {
        this.setState({ shouldShowRestoreWarning: false, isSaveLoading: false })
      }, error => {
        this.setState({ shouldShowRestoreWarning: false, isSaveLoading: false })
        utilityService.handleError(error)
      })
    })
  }

  onRestoreChannelClick = () => {
    this.setState({ shouldShowRestoreWarning: true })
  }

  hideAddImageModal = () => {
    this.setState({ showAddImageModal: false })
  }

  saveCurrentSelection = (selectedImage) => {
    const newImage = selectedImage[0]
    this.setState({ selectedImage: newImage, showAddImageModal: false })
  }

  onUploadImage = () => {
    this.setState({ showAddImageModal: true })
  }

  hideCropModal = () => {
    this.setState({ showCropModal: false })
  }

  showCropModal = () => {
    const { selectedImage } = this.state
    const aspectRatio = selectedImage.aspectRatio.find(item => item.title === '1:1')
    const settings = selectedImage.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
    this.setState({ settings, aspectRatio }, () => this.setState({ showCropModal: true }))
  }

  removeImage = () => {
    this.setState({ selectedImage: {} })
  }

  getImageUrl = (imageData) => {
    const aspectRatio = imageData.aspectRatio.find(item => item.title === '1:1')
    return aspectRatio.resolutions.length ? aspectRatio.resolutions[0].url : ''
  }

  saveCurrentCrop = (settings) => {
    const image = { ...this.state.selectedImage }
    const tempSelectedImage = _.cloneDeep(this.state.selectedImage)
    const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
    const currentAspectRatioIndex = image.aspectRatio.findIndex(item => item.title === '1:1')
    const currentaspectId = currentAspectRatio.aspectRatio
    const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
    image.settings.map(setting => {
      setting.outputFormat = 'JPG'
      delete setting[ '__typename' ]
    })
    image.settings[changedIndex].x1 = settings.x1
    image.settings[changedIndex].x2 = settings.x2
    image.settings[changedIndex].y1 = settings.y1
    image.settings[changedIndex].y2 = settings.y2
    const croppedUrl = getCropImageUrl(image, settings)
    currentAspectRatio.resolutions[0].url = croppedUrl
    image.aspectRatio[currentAspectRatioIndex] = currentAspectRatio
    this.setState({ showCropModal: false, selectedImage: image, tempSelectedImage, isImageModified: true })
  }

  selectChannel = (selectedData, selectedIndex) => {
    this.setState({
      selectedData,
      selectedIndex
    })
  }

  addChannel = () => {
    if (channelSearchStr) {
      this.setState({ isClearFilter: true, searchString: '', channelCode: '' }, () => {
        channelSearchStr = null
        const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
        this.props.getChannelList(variables).then(() => {
          this.setState({ isClearFilter: false })
        })
      })
    }
    const selectedImage = this.state.selectedImage
    const aspectRatio = !_.isEmpty(selectedImage) ? selectedImage.aspectRatio.find(item => item.title === '1:1') : {}
    const settings = !_.isEmpty(selectedImage) ? selectedImage.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio) : []
    this.setState({
      isFromAddChannel: true,
      channelName: '',
      channelCode: '',
      selectedImage: {},
      defaultTrimStart: '',
      defaultTrimEnd: ''
    }, () => {
      this.setState({ isCreateClicked: true, aspectRatio, settings })
    })
  }

  onSaveChannel = (channelInfo) => {
    const { isImageModified } = this.state
    const imageId = !_.isEmpty(this.state.selectedImage) ? this.state.selectedImage.id : null
    const image = cloneDeep(!_.isEmpty(this.state.selectedImage) ? this.state.selectedImage : null)
    // const tags = image && image.tags && image.tags.length ? image.tags.map(item => item.id) : null
    if (!this.state.isFromAddChannel) {
      const selectedIndex = _.findIndex(this.state.channelList, (item) => item.id === this.state.selectedId)
      const channelList = _.cloneDeep(this.state.channelList)
      if (this.state.createChannelLoading) {
        return
      }
      this.setState({ createChannelLoading: true }, () => {
        if (image && image.id) {
          image.settings.map(setting => {
            setting.outputFormat = 'JPG'
            delete setting[ '__typename' ]
          })
          // this.props.updateImageSettings(image.id, this.state.selectedId, image.settings).then((response) => {
          if (isImageModified) {
            this.props.updateImage(image.id, image.name, image.tags ? image.tags.map(item => item.key || item.id) : [], image.settings).then((response) => {
              this.props.updateChannel(this.state.selectedId, channelInfo.title, imageId, channelInfo.isArchived, channelInfo.code, channelInfo.site, channelInfo.defaultTrimStart, channelInfo.defaultTrimEnd).then((response) => {
                channelList[selectedIndex] = response.data.updateChannel
                this.setState({
                  isCreateClicked: false,
                  createChannelLoading: false,
                  channelList,
                  channelLogo: !_.isEmpty(this.state.selectedImage) ? this.getImageUrl(this.state.selectedImage) : '',
                  isChannelHistory: true,
                  isImageModified: false
                }, () => {
                  this.setState({ isChannelHistory: false })
                })
              }, error => {
                this.setState({ createChannelLoading: false })
                utilityService.handleError(error)
              })
            }, error => {
              this.setState({ createChannelLoading: false })
              utilityService.handleError(error)
            })
          } else {
            this.props.updateChannel(this.state.selectedId, channelInfo.title, imageId, channelInfo.isArchived, channelInfo.code, channelInfo.site, channelInfo.defaultTrimStart, channelInfo.defaultTrimEnd).then((response) => {
              channelList[selectedIndex] = response.data.updateChannel
              this.setState({
                isCreateClicked: false,
                createChannelLoading: false,
                channelList,
                channelLogo: !_.isEmpty(this.state.selectedImage) ? this.getImageUrl(this.state.selectedImage) : '',
                isChannelHistory: true,
                isImageModified: false
              }, () => {
                this.setState({ isChannelHistory: false })
              })
            }, error => {
              this.setState({ createChannelLoading: false })
              utilityService.handleError(error)
            })
          }
        } else {
          this.props.updateChannel(this.state.selectedId, channelInfo.title, imageId, channelInfo.isArchived, channelInfo.code, channelInfo.site, channelInfo.defaultTrimStart, channelInfo.defaultTrimEnd).then((response) => {
            channelList[selectedIndex] = response.data.updateChannel
            this.setState({
              isCreateClicked: false,
              createChannelLoading: false,
              channelList,
              channelLogo: !_.isEmpty(this.state.selectedImage) ? this.getImageUrl(this.state.selectedImage) : ''
            })
          }, error => {
            this.setState({ createChannelLoading: false })
            utilityService.handleError(error)
          })
        }
      })
    } else {
      this.setState({ isClearFilter: true, searchString: '' }, () => {
        if (this.state.createChannelLoading) {
          return
        }
        this.setState({ createChannelLoading: true }, () => {
          if (image && image.id) {
            image.settings.map(setting => {
              setting.outputFormat = 'JPG'
              delete setting[ '__typename' ]
            })
            this.props.createChannel(channelInfo.title, imageId, channelInfo.code, channelInfo.site, channelInfo.defaultTrimStart, channelInfo.defaultTrimEnd).then((response) => {
              const newChannelId = response.data.createChannel.id
              // this.props.updateImageSettings(image.id, newChannelId, image.settings).then(() => {
              if (isImageModified) {
                this.props.updateImage(image.id, image.name, image.tags ? image.tags.map(item => item.key || item.id) : [], image.settings)
                this.props.updateImageSettings(image.id, newChannelId, null).then(() => {
                  const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
                  this.props.getChannelList(variables).then(() => {
                    // setTimeout(() => this.onSelectChannel(newChannelId), 10000)
                    this.setState({
                      isCreateClicked: false,
                      createChannelLoading: false,
                      isClearFilter: false,
                      isImageModified: false
                    }, () => {
                      this.onSelectChannel(newChannelId)
                    })
                  })
                }, error => {
                  utilityService.handleError(error)
                })
              } else {
                const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
                this.props.getChannelList(variables).then(() => {
                  // setTimeout(() => this.onSelectChannel(newChannelId), 10000)
                  this.setState({
                    isCreateClicked: false,
                    createChannelLoading: false,
                    isClearFilter: false,
                    isImageModified: false
                  }, () => {
                    this.onSelectChannel(newChannelId)
                  })
                })
              }
            }, (error) => {
              const { graphQLErrors } = error
              if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized') {
                message.warning('User does not have permission to edit')
              } else if (!!graphQLErrors.length && graphQLErrors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
                message.error('Channel code already in use')
              } else {
                message.warning(error.message.replace('Error: ', '').replace('GraphQL error: ', ''))
              }
              this.setState({
                isCreateClicked: false,
                createChannelLoading: false,
                isClearFilter: false
              })
            })
          } else {
            this.props.createChannel(channelInfo.title, imageId, channelInfo.code, channelInfo.site, channelInfo.defaultTrimStart, channelInfo.defaultTrimEnd).then((response) => {
              const newChannelId = response.data.createChannel.id
              const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
              this.props.getChannelList(variables).then(() => {
                // setTimeout(() => this.onSelectChannel(newChannelId), 10000)
                this.setState({
                  isCreateClicked: false,
                  createChannelLoading: false,
                  isClearFilter: false
                }, () => {
                  this.onSelectChannel(newChannelId)
                })
              })
            }, (error) => {
              const { graphQLErrors } = error
              if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized') {
                message.warning('User does not have permission to edit')
              } else if (!!graphQLErrors.length && graphQLErrors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
                message.error('Channel code already in use')
              } else {
                message.warning(error.message.replace('Error: ', '').replace('GraphQL error: ', ''))
              }
              this.setState({
                isCreateClicked: false,
                createChannelLoading: false,
                isClearFilter: false
              })
            })
          }
        })
      })
    }
  }

  onCancelChannel = () => {
    const tempImage = this.state.tempSelectedImage
    const aspectRatio = !_.isEmpty(tempImage) && tempImage.aspectRatio.find(item => item.title === '1:1')
    const settings = !_.isEmpty(tempImage) && tempImage.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
    this.setState({ isCreateClicked: false, selectedImage: this.state.tempSelectedImage, aspectRatio, settings, channelName: '', defaultTrimStart: '', defaultTrimEnd: '' })
  }

  onEditChannel = () => {
    const selectedChannel = _.find(_.cloneDeep(this.state.channelList), (channel) => channel.id === this.state.selectedId)
    const aspectRatio = selectedChannel.icon ? selectedChannel.icon.aspectRatio.find(item => item.title === '1:1') : {}
    this.setState({
      isFromAddChannel: false,
      channelName: this.state.selectChannelName,
      selectedImage: selectedChannel.icon,
      aspectRatio,
      channelLogo: selectedChannel.icon ? this.getImageUrl(selectedChannel.icon) : '',
      channelCode: selectedChannel.channelCode ? selectedChannel.channelCode : '',
      settings: selectedChannel.icon ? selectedChannel.icon.settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio) : [],
      defaultTrimStart: selectedChannel.defaultTrimStart || selectedChannel.defaultTrimStart === 0 ? selectedChannel.defaultTrimStart : '',
      defaultTrimEnd: selectedChannel.defaultTrimEnd || selectedChannel.defaultTrimEnd === 0 ? selectedChannel.defaultTrimEnd : ''
    }, () => {
      this.setState({ isCreateClicked: true })
    })
  }

  onEnableMasterConfig = () => {
    this.setState({ isMasterConfigVisible: !this.state.isMasterConfigVisible })
  }

  onDisableMasterConfig = () => {
    let props = this.props
    this.props.updateModuleSettings(props.settingsId, false, props.masterConfigId).then(() => {
      this.setState({ isChannelHistory: true }, () => {
        this.setState({ isChannelHistory: false })
      })
    }, error => {
      utilityService.handleError(error)
    })
  }

  cancelMasterConfigModal = () => {
    this.setState({ isMasterConfigVisible: false })
  }

  onConfirmMasterConfigModal = (id) => {
    if (this.state.isMasterLoading) {
      return
    }
    this.setState({ isMasterLoading: true })
    this.props.updateModuleSettings(this.props.settingsId, true, id).then(() => {
      this.setState({ isMasterConfigVisible: false, isMasterLoading: false, isChannelHistory: true }, () => {
        this.setState({ isChannelHistory: false })
      })
    }, error => {
      this.setState({ isMasterLoading: false })
      utilityService.handleError(error)
    })
  }

  onChangeFilter= (filter, id) => {
    let newFilter = utilityService.getFormattedFilter(filter)
    newFilter = utilityService.getFormattedChannelFilter(channelSearchStr, newFilter)
    channelFilter = newFilter.filter
    if (initialSearchFilter) {
      initialSearchFilter = false
      if (channelFilter && channelFilter.isArchived && channelFilter.isArchived.match === null) {
        delete channelFilter.isArchived
      }
    }
    if (this.state.channelLoading) {
      return
    }
    this.setState({ channelLoading: true }, () => {
      this.props.getChannelList(newFilter).then(() => {
        this.setState({ channelLoading: false })
      })
    })
  }

  onSelectChannel = (selectedId) => {
    const selectedItem = _.find(this.props.channelList, (items) => items.id === selectedId)
    const selectChannelName = selectedItem ? selectedItem.name : ''
    const selectChannelCode = selectedItem ? selectedItem.channelCode : ''
    // channelCode: selectedItem ? selectedItem.channelCode : '',
    const channelLogo = selectedItem && selectedItem.icon ? this.getImageUrl(selectedItem.icon) : ''
    const channelConfigGroup = selectedItem ? selectedItem.configGroups : []
    this.setState({ selectedId, selectChannelName, selectChannelCode, channelLogo, channelConfigGroup }, () => {
      this.props.history.push(`/channels/${selectedId}`)
      if (selectedItem && (selectedItem.isArchived || selectedItem.isArchived === 'true')) { initialSearchFilter = true }
    })
  }

  onRearrangeConfigGroups = () => {
    this.setState({ isChannelHistory: true }, () => {
      this.setState({ isChannelHistory: false })
    })
  }

  getSelectedImageUrl = (imageData) => {
    const aspectRatio = imageData.aspectRatio.find(item => item.title === '1:1')
    const settings = imageData.settings.find((item) => item.aspectRatio === aspectRatio.aspectRatio)
    return settings.fileName
  }

  render () {
    const { showCropModal, selectedImage, aspectRatio, settings, isClearFilter, channelLoading, channelCode, searchString, channelList, selectedId, isChannelLoading,
      selectChannelName, channelLogo, selectChannelCode, isCreateClicked, channelName, createChannelLoading, isFromAddChannel, isMasterConfigVisible, isMasterLoading,
      showAddImageModal, shouldShowWarning, isChannelHistory, configGroupList, defaultTrimStart, defaultTrimEnd } = this.state
    const { isMasterEnabled } = this.props
    const selectedChannelItem = (channelList || []).find((item) => item.id === selectedId)
    const selectedSite = !_.isEmpty(selectedChannelItem) ? selectedChannelItem.site : ''
    return <AppContext.Consumer>
      {({ permissions, project }) => {
        const userPermissions = permissions['CONTENT_BANK']
        const userChannelPermissions = permissions['CHANNEL_MANAGER']
        const isUpdateDisabled = userChannelPermissions.indexOf('UPDATE') === -1
        const isIconUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        const isIconCreateDisabled = userPermissions.indexOf('CREATE') === -1
        return <Row className='channel-manager' id={'channel-manager'}>
          <Col span={18}>
            <ChannelList
              selectedChannelData={this.selectChannel}
              searchString={searchString}
              addChannel={this.addChannel}
              onEnableMasterConfig={this.onEnableMasterConfig}
              channelList={_.cloneDeep(channelList)}
              selectedId={selectedId}
              isMasterEnabled={isMasterEnabled}
              onSelectChannel={this.onSelectChannel}
              isLoading={isChannelLoading || channelLoading}
              onDisableMasterConfig={this.onDisableMasterConfig}
              onSearchChannel={this.onSearchChannel}
              onChangeFilter={this.onChangeFilter}
              isClearFilter={isClearFilter} />
          </Col>
          <Col span={6} className='channel-config'>
            <Skeleton
              active avatar={false}
              title={false} paragraph={{ rows: 10 }}
              loading={isChannelLoading || channelLoading}>
              {selectChannelName
                ? <ChannelConfigList
                  onEditChannel={this.onEditChannel}
                  channelName={selectChannelName}
                  channelLogo={channelLogo}
                  channelCode={selectChannelCode}
                  configGroupList={_.cloneDeep(configGroupList)}
                  displayArchivedList={!_.isEmpty(channelFilter) && channelFilter.isArchived && (channelFilter.isArchived.match === 'false' ? false : channelFilter.isArchived.match)}
                  onArchiveClick={this.onArchiveClick}
                  selectedId={selectedId}
                  onRestoreChannelClick={this.onRestoreChannelClick}
                  settingsId={this.props.settingsId}
                  isChannelHistory={isChannelHistory}
                  onRearrangeConfigGroups={this.onRearrangeConfigGroups}
                /> : <Empty />}
            </Skeleton>
          </Col>
          <CreateChannelModal
            isVisible={isCreateClicked}
            onSave={this.onSaveChannel}
            onCancel={this.onCancelChannel}
            onUploadImage={this.onUploadImage}
            channelName={channelName}
            isLoading={createChannelLoading}
            channelList={_.cloneDeep(channelList)}
            channelCode={channelCode}
            channelLogo={_.cloneDeep(selectedImage)}
            showCropModal={this.showCropModal}
            removeImage={this.removeImage}
            isFromAddChannel={isFromAddChannel}
            channelId={isFromAddChannel ? '' : selectedId}
            selectedSite={selectedSite}
            defaultTrimStart={defaultTrimStart}
            defaultTrimEnd={defaultTrimEnd}
          />
          <MasterConfigModal
            isVisible={isMasterConfigVisible}
            onCancel={this.cancelMasterConfigModal}
            onOk={this.onConfirmMasterConfigModal}
            channelConfigList={_.cloneDeep(configGroupList)}
            isLoading={isMasterLoading} />
          <AddImageModal
            isVisible={showAddImageModal}
            mediaType='IMAGE'
            handleSubmit={this.saveCurrentSelection}
            handleCancel={this.hideAddImageModal}
            isUpdateDisabled={isIconUpdateDisabled}
            isSubmitButtonDisabled={isIconCreateDisabled}
            project={project} />
          <CropImageModal
            isVisible={showCropModal}
            aspectRatio={aspectRatio}
            imgUrl={_.isEmpty(selectedImage) ? '' : this.getSelectedImageUrl(selectedImage)}
            settings={settings}
            updateSettings={this.saveCurrentCrop}
            isUpdateBlocked={isIconUpdateDisabled}
            handleCancel={this.hideCropModal} />
          <ConfirmModal
            isVisible={shouldShowWarning}
            title={'Archive Channel'}
            message={'Do you want to archive this channel?'}
            leftButtonText={'Cancel'}
            rightButtonText={'Confirm'}
            handleSubmit={this.onArchiveSubmit}
            handleCancel={this.cancelArchive}
            isLoading={this.state.isSaveLoading}
            isSubmitButtonDisabled={isUpdateDisabled}
          />
          <ConfirmModal
            isVisible={this.state.shouldShowRestoreWarning}
            title={'Restore Channel'}
            message={'Do you want to restore this channel?'}
            leftButtonText={'Cancel'}
            rightButtonText={'Confirm'}
            handleSubmit={this.onRestoreSubmit}
            handleCancel={this.cancelRestore}
            isLoading={this.state.isSaveLoading}
            isSubmitButtonDisabled={isUpdateDisabled}
          />
        </Row>
      }}
    </AppContext.Consumer>
  }
}

ChannelManager.propTypes = {
  channelList: PropTypes.any,
  channelLoading: PropTypes.bool,
  isMasterEnabled: PropTypes.bool,
  masterConfigId: PropTypes.string,
  settingsId: PropTypes.string,
  configGroupList: PropTypes.arrayOf(PropTypes.object),
  getChannelList: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryGetSettings,
    {
      props: (props) => {
        {
          const isSettingsPresent = (props.data && props.data.listModuleSettings && props.data.listModuleSettings.items && props.data.listModuleSettings.items.length)
          const masterConfigItem = isSettingsPresent ? props.data.listModuleSettings.items.find(listItem => listItem.channelManagerSetting) : null
          return {
            isMasterEnabled: isSettingsPresent ? masterConfigItem.channelManagerSetting.masterConfigEnabled : false,
            masterConfigId: isSettingsPresent ? masterConfigItem.channelManagerSetting.masterConfigSchemaId : '',
            settingsId: isSettingsPresent ? masterConfigItem.id : ''
          }
        }
      }
    }
  ),
  graphql(
    QueryListChannel,
    {
      options: () => {
        // const filter = {
        //   isArchived: {
        //     match: false
        //   }
        // }
        // channelFilter = _.isEmpty(channelFilter) ? filter : channelFilter
        // if (channelSearchStr) {
        //   channelFilter.name = {
        //     match: channelSearchStr
        //   }
        // } else if (channelFilter.name) delete (channelFilter.name)
        if (initialSearchFilter) {
          if (!channelFilter || _.isEmpty(channelFilter)) {
            channelFilter = {
              isArchived: {
                match: null
              }
            }
          }
        }
        return {
          variables: utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter),
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          channelList: (props.data && props.data.listChannels) ? getSortedList(props.data.listChannels.items) : [],
          channelLoading: props.data.loading,
          getChannelList: (variables) => {
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryListConfigGroupSchema,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: ({ data: { listConfigGroupSchemas = { items: [] } } }) => ({
        configGroupList: [ ...listConfigGroupSchemas.items ]
      })
    }
  ),
  graphql(
    MutationUpdateChannel,
    {
      options: {
        update: (cache, { data: { updateChannel } }) => {
          const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListChannel, variables }))
          if (cacheData && cacheData.listChannels && cacheData.listChannels.items) {
            cacheData.listChannels.items = cacheData.listChannels.items.filter((item) => (item.isArchived + '') === (variables.filter.isArchived.match + ''))
          }
          cache.writeQuery({
            query: QueryListChannel,
            data: cacheData,
            variables
          })
        }
      },
      props: (props) => ({
        updateChannel: (id, name, icon, isArchived, channelCode, site, defaultTrimStart, defaultTrimEnd) => {
          return props.mutate({
            variables: { id, name, icon, isArchived, channelCode, site, defaultTrimStart, defaultTrimEnd }
          })
        }
      })
    }),
  graphql(
    MutationCreateChannel,
    {
      options: {
        update: (cache, { data: { createChannel } }) => {
          channelSearchStr = null
          channelFilter = {}
          const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListChannel, variables }))
          if (cacheData && cacheData.listChannels && cacheData.listChannels.items) {
            cacheData.listChannels.items.unshift(createChannel)
          }
          cache.writeQuery({
            query: QueryListChannel,
            data: cacheData,
            variables
          })
        }

      },
      props: (props) => ({
        createChannel: (name, channelLogo, channelCode, site, defaultTrimStart, defaultTrimEnd) => {
          return props.mutate({
            variables: { name, channelLogo, isArchived: 'false', channelCode, site, defaultTrimStart, defaultTrimEnd }
          })
        }
      })
    }),
  graphql(
    MutationUpdateMedia, {
      props: (props) => ({
        updateImage: (id, name, tags, settings) => {
          return props.mutate({
            variables: { id, name, tags, settings, updatedAt: new Date().toISOString() }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateMasterConfig,
    {
      options: () => {
        const variables = utilityService.getFormattedChannelFilter(channelSearchStr, channelFilter)
        return {
          refetchQueries: () => [{ query: QueryListChannel, variables }]
        }
      },
      props: (props) => ({
        updateModuleSettings: (id, masterConfigEnabled, masterConfigSchemaId) => {
          return props.mutate({
            variables: { id, masterConfigEnabled, masterConfigSchemaId }
          })
        }
      })
    }),
  graphql(
    MutationUpdateMediaSettings, {
      props: (props) => ({
        updateImageSettings: (id, assetId, settings) => {
          return props.mutate({
            variables: { id, assetId, settings }
          })
        }
      })
    }
  )
)(ChannelManager))
