import * as React from 'react'
import QueryGetPermission from './graphQL/app/getPermissions'
import AppContext from './AppContext'

import AssetManager from './modules/assetManager/AssetManager'
import ContentManager from './modules/contentManager/ContentManager'
import ChannelManager from './modules/channelManager/ChannelManager'
import BucketManager from './modules/bucketManager/BucketManager'
import MatchManager from './modules/matchManager/MatchManager'
import RoleManager from './modules/roleManager/RoleManager'
import DataManager from './modules/dataManager/DataManager'
import JobManager from './modules/jobManager/JobManager'
import CustomerServiceManager from './modules/customerServiceManager/CustomerServiceManager'
import OfferServiceManager from './modules/offerManager/OfferManager'
import PartnerServiceManager from './modules/partnerManager/PartnerManager'
import HeaderComponent from './components/ui/layout/Header'
import BucketManagerJsonPreview from './modules/bucketManager/BucketManagerJsonPreview'
import { utilityService } from './services/UtilityService'
import LoggerService from './services/LoggerService'
import IngestManager from './modules/ingestManager/IngestManager'
import AppManager from './modules/appManager/AppManager'
import AppPageJsonPreview from './modules/appManager/AppPageJsonPreview'
import AppConfigJsonPreview from './modules/appManager/AppConfigJsonPreview'
import AppManagerBucketJsonPreview from './modules/appManager/AppManagerBucketJsonPreview'
import MonitorManger from './modules/monitorManager/MonitorManager'
import DrmManager from './modules/drmManager/DrmManager'

import { Route, Redirect, Switch } from 'react-router-dom'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { Layout } from 'antd'

const { Content } = Layout

class AppRouterWrapper extends React.Component {
    state = {
      fileData: null
    }

    componentDidMount () {
      const sessionId = utilityService.makeRandomString(6)
      sessionStorage.setItem('session_id', sessionId)
      LoggerService.info('session_id', `Current session id`, sessionId)
    }

    handleFileUpload = (fileData) => {
      this.setState({ fileData })
    }

    getRoutes = (project) => {
      const { showWarning, userPermission, changeEditStatus, notifyParent, clearModuleChange, onChangeBucketStatus, changePartnerEditStatus, changeOfferEditStatus, IngestClient } = this.props
      const assetRouter = <Route key='1' exact path='/assets' render={() => <AssetManager handleFileUpload={this.handleFileUpload} onChangeEditStatus={changeEditStatus} showWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} appleClient={this.props.appleClient} project={project} />} />
      const assetDetailsRouter = <Route key='1' path='/assets/:id' render={() => <AssetManager handleFileUpload={this.handleFileUpload} onChangeEditStatus={changeEditStatus} showWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} appleClient={this.props.appleClient} project={project} />} />
      const contentRouter = <Route key='3' exact path='/contents' render={() => <ContentManager project={project} />} />
      const contentDetailsRouter = <Route key='3' path='/contents/:id' render={() => <ContentManager project={project} />} />
      const channelRouter = <Route key='5' exact path='/channels' component={ChannelManager} />
      const channelDetailsRouter = <Route key='5' exact path='/channels/:id' component={ChannelManager} />
      const bucketRouter = <Route key='7' exact path='/buckets' render={() => <BucketManager onChangeBucketStatus={onChangeBucketStatus} showBucketWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} project={project} />} />
      const bucketDetailsRouter = <Route key='8' path='/buckets/preview/:id' render={() => <BucketManagerJsonPreview project={project} />} />
      const matchRouter = <Route key='9' exact path='/matches' render={() => <MatchManager project={project} />} />
      const matchDetailsRouter = <Route key='9' path='/matches/:id' render={() => <MatchManager project={project} />} />
      const jobRouter = <Route key='11' exact path='/jobs' component={JobManager} />
      const jobDetailsRouter = <Route key='11' exact path='/jobs/:id' component={JobManager} />
      const roleRouter = <Route key='13' exact path='/users' render={() => <RoleManager project={project} />} />
      const adminRouter = <Route key='14' exact path='/admin' render={() => <DataManager project={project} appleClient={this.props.appleClient} />} />
      const adminDetailsRouter = <Route key='14' path='/admin/:id' render={() => <DataManager project={project} appleClient={this.props.appleClient} />} />
      const customerRouter = <Route key='15' exact path='/customers' render={() => <CustomerServiceManager project={project} />} />
      const customerDetailsRouter = <Route key='15' path='/customers/:id' render={() => <CustomerServiceManager project={project} />} />
      const offerRouter = <Route key='16' exact path='/offers' render={(props) => <OfferServiceManager onChangeEditStatus={changeOfferEditStatus} showWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} project={project} {...props} />} />
      const offerDetailsRouter = <Route key='16' path='/offers/:id' render={(props) => <OfferServiceManager onChangeEditStatus={changeOfferEditStatus} showWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} project={project} {...props} />} />
      const partnerRouter = <Route key='17' exact path='/partners' render={(props) => <PartnerServiceManager onChangeEditStatus={changePartnerEditStatus} showWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} project={project} {...props} />} />
      const partnerDetailsRouter = <Route key='17' path='/partners/:id' render={(props) => <PartnerServiceManager onChangeEditStatus={changePartnerEditStatus} showWarning={showWarning} notifyParent={notifyParent} clearModuleChange={clearModuleChange} project={project} {...props} />} />
      const ingestRouter = <Route key='18' exact path='/ingest-jobs' render={(props) => <IngestManager project={project} {...props} IngestClient={IngestClient} />} />
      const ingestDetailsRouter = <Route key='18' path='/ingest-jobs/:id' render={(props) => <IngestManager project={project} {...props} IngestClient={IngestClient} />} />
      const appRouter = <Route key='19' exact path='/apps' render={() => <AppManager project={project} appClient={this.props.appClient} permissions={userPermission && userPermission.permissions} userId={userPermission && userPermission.userId} />} />
      const appPageDetailsRouter = <Route key='19' exact path='/apps/pages/:id' render={() => <AppManager project={project} appClient={this.props.appClient} permissions={userPermission && userPermission.permissions} userId={userPermission && userPermission.userId} />} />
      const appHomePageDetailsRouter = <Route key='19' exact path='/apps/pages' render={() => <AppManager project={project} appClient={this.props.appClient} permissions={userPermission && userPermission.permissions} userId={userPermission && userPermission.userId} />} />
      const appSettingDetailsRouter = <Route key='19' exact path='/apps/settings' render={() => <AppManager project={project} appClient={this.props.appClient} permissions={userPermission && userPermission.permissions} userId={userPermission && userPermission.userId} />} />
      const appConfigDetailsRouter = <Route key='19' exact path='/apps/configs/:id' render={() => <AppManager project={project} appClient={this.props.appClient} permissions={userPermission && userPermission.permissions} userId={userPermission && userPermission.userId} />} />
      const appHomeConfigDetailsRouter = <Route key='19' exact path='/apps/configs' render={() => <AppManager project={project} appClient={this.props.appClient} permissions={userPermission && userPermission.permissions} userId={userPermission && userPermission.userId} />} />
      const appPageJsonDetailsRouter = <Route key='19' exact path='/apps/pages/preview/:id' render={() => <AppPageJsonPreview project={project} appClient={this.props.appClient} />} />
      const appConfigJsonDetailsRouter = <Route key='19' exact path='/apps/configs/preview/:id' render={() => <AppConfigJsonPreview project={project} appClient={this.props.appClient} />} />
      const appBucketJsonDetailsRouter = <Route key='19' path='/buckets/preview/:id' render={() => <AppManagerBucketJsonPreview project={project} />} />
      const monitorRouter = <Route key='20' exact path='/monitor' render={() => <MonitorManger project={project} appClient={this.props.appClient} />} />
      const drmRouter = <Route key='21' exact path='/drm' render={() => <DrmManager project={project} />} />

      const userDefaultRouterMap = {
        ASSET_MANAGER: '/assets',
        CONTENT_BANK: '/contents',
        CHANNEL_MANAGER: '/channels',
        BUCKET_MANAGER: '/buckets',
        MATCH_MANAGER: '/matches',
        JOB_MANAGER: '/jobs',
        ROLE_MANAGER: '/users',
        DATA_MANAGER: '/admin',
        CUSTOMER_MANAGER: '/customers',
        PARTNER_MANAGER: '/partners',
        OFFER_MANAGER: '/offers',
        INGEST_MANAGER: '/ingest-jobs',
        APP_MANAGER: '/apps',
        MONITOR_MANAGER: '/monitor',
        DRM_MANAGER: '/drm'
      }
      let routes = []
      let moduleKeys = []
      // const permittedModules = userPermission ? (userPermission.permissions || []).filter(item => (item.permissions || []).indexOf('GET') > -1) : []
      const permittedModules = userPermission && userPermission.permissions ? userPermission.permissions : {}
      // permittedModules.map((role) => {
      if (permittedModules['ASSET_MANAGER'] && permittedModules['ASSET_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('ASSET_MANAGER')
        routes.push(assetRouter)
        routes.push(assetDetailsRouter)
      }
      if (permittedModules['CONTENT_BANK'] && permittedModules['CONTENT_BANK'].indexOf('GET') > -1) {
        moduleKeys.push('CONTENT_BANK')
        routes.push(contentRouter)
        routes.push(contentDetailsRouter)
      }
      if (permittedModules['CHANNEL_MANAGER'] && permittedModules['CHANNEL_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('CHANNEL_MANAGER')
        routes.push(channelRouter)
        routes.push(channelDetailsRouter)
      }
      if (permittedModules['BUCKET_MANAGER'] && permittedModules['BUCKET_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('BUCKET_MANAGER')
        routes.push(bucketRouter)
        routes.push(bucketDetailsRouter)
      }
      if (permittedModules['MATCH_MANAGER'] && permittedModules['MATCH_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('MATCH_MANAGER')
        routes.push(matchRouter)
        routes.push(matchDetailsRouter)
      }
      if (permittedModules['JOB_MANAGER'] && permittedModules['JOB_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('JOB_MANAGER')
        routes.push(jobRouter)
        routes.push(jobDetailsRouter)
      }
      if (permittedModules['ROLE_MANAGER'] && permittedModules['ROLE_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('ROLE_MANAGER')
        routes.push(roleRouter)
      }
      if (permittedModules['DATA_MANAGER'] && permittedModules['DATA_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('DATA_MANAGER')
        routes.push(adminRouter)
        routes.push(adminDetailsRouter)
      }
      if (permittedModules['CUSTOMER_MANAGER'] && permittedModules['CUSTOMER_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('CUSTOMER_MANAGER')
        routes.push(customerRouter)
        routes.push(customerDetailsRouter)
      }
      if (permittedModules['OFFER_MANAGER'] && permittedModules['OFFER_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('OFFER_MANAGER')
        routes.push(offerRouter)
        routes.push(offerDetailsRouter)
      }
      if (permittedModules['PARTNER_MANAGER'] && permittedModules['PARTNER_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('PARTNER_MANAGER')
        routes.push(partnerRouter)
        routes.push(partnerDetailsRouter)
      }
      if (permittedModules['INGEST_MANAGER'] && permittedModules['INGEST_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('INGEST_MANAGER')
        routes.push(ingestRouter)
        routes.push(ingestDetailsRouter)
      }
      if (permittedModules['APP_MANAGER'] && ((permittedModules['APP_MANAGER'].indexOf('VIEW_CONFIG') > -1) || (permittedModules['APP_MANAGER'].indexOf('VIEW_PAGE') > -1) || (permittedModules['APP_MANAGER'].indexOf('APP_ADMIN') > -1))) {
        moduleKeys.push('APP_MANAGER')
        routes.push(appRouter)
        routes.push(appPageDetailsRouter)
        routes.push(appConfigDetailsRouter)
        routes.push(appPageJsonDetailsRouter)
        routes.push(appSettingDetailsRouter)
        routes.push(appConfigJsonDetailsRouter)
        routes.push(appBucketJsonDetailsRouter)
        routes.push(appHomePageDetailsRouter)
        routes.push(appHomeConfigDetailsRouter)
      }

      if (permittedModules['MONITOR_MANAGER'] && permittedModules['MONITOR_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('MONITOR_MANAGER')
        routes.push(monitorRouter)
      }
      if (permittedModules['DRM_MANAGER'] && permittedModules['DRM_MANAGER'].indexOf('GET') > -1) {
        moduleKeys.push('DRM_MANAGER')
        routes.push(drmRouter)
      }

      if (!_.isEmpty(permittedModules)) {
        const defaultRoute = <Route key='15' render={() => (<Redirect to={userDefaultRouterMap[moduleKeys[0]]} />)} />
        routes.push(defaultRoute)
      }

      return routes
    }

    render () {
      const { forceClear, forceSwitch, isEdit, history, moduleSwitch, userPermission, changePassword, disableMfa, project, userId } = this.props
      const { fileData } = this.state
      const routes = _.isEmpty(userPermission) ? '' : this.getRoutes(project)
      let permissionObj = _.cloneDeep(userPermission)
      if (!_.isEmpty(permissionObj)) { permissionObj.project = project }
      return <AppContext.Provider value={permissionObj}>
        <Layout>
          <HeaderComponent
            fileData={fileData}
            history={history}
            userRoles={permissionObj}
            editStatus={isEdit}
            moduleSwitch={moduleSwitch}
            forceSwitch={forceSwitch}
            forceClear={forceClear}
            disableMfa={disableMfa}
            changePassword={changePassword}
            userId={userId}
            project={project} />

          <Content>
            <Switch>
              {routes}
            </Switch>
          </Content>
        </Layout>
      </AppContext.Provider>
    }
}

export default withApollo(compose(

  graphql(
    QueryGetPermission,
    {
      options: (props) => {
        const variables = { id: props.userId, project: props.project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      skip: ({ userId }) => !(userId && userId.length),
      props: (props) => {
        const { data } = props
        let getUserPermissions = data.getUserPermissions

        if (getUserPermissions) {
          let permissions = getUserPermissions.permissions
          if (props.ownProps.project === 'vcms' && permissions.ASSET_MANAGER.length === 0 &&
              permissions.BUCKET_MANAGER.length === 0 &&
              permissions.CHANNEL_MANAGER.length === 0 &&
              permissions.CONTENT_BANK.length === 0 &&
              permissions.DATA_MANAGER.length === 0 &&
              permissions.JOB_MANAGER.length === 0 &&
              permissions.MATCH_MANAGER.length === 0 &&
              permissions.ROLE_MANAGER.length === 0) {
            window.csm_only_user_access = true
          } else if ((props.ownProps.project === 'hyperion' || props.ownProps.project === 'projectx') && permissions.ASSET_MANAGER.length === 0 &&
          permissions.BUCKET_MANAGER.length === 0 &&
          permissions.CONTENT_BANK.length === 0 &&
          permissions.DATA_MANAGER.length === 0 &&
          permissions.PARTNER_MANAGER.length === 0 &&
          permissions.OFFER_MANAGER.length === 0 &&
          permissions.ROLE_MANAGER.length === 0) {
            window.csm_only_user_access = true
          } else {
            window.csm_only_user_access = false
          }
        }
        return {
          userPermission: getUserPermissions
        }
      }
    }
  )
)(AppRouterWrapper))
