import gql from 'graphql-tag'

export default gql(`
    mutation adminAccountDisconnect($userId: String! $subscriptionId: String! $project: String!) {
        adminAccountDisconnect(
            subscriptionId: $subscriptionId
            project: $project
            userId: $userId
        ) {
            success{
                message
            }
            error{
                message
            }
            data {
                activatedAt
                createdAt
                customerId
                endDate
                id
                planId
                price
                renewalDate
                status
                planData {
                  description
                  id
                  interval
                  name
                  price
                  provider {
                    id
                    name
                  }
                  providerId
                }
            }
        }
    }`
)
