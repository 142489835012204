import React, { Component } from 'react'

import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import HistoryList from '../auditManager/HistoryList'

const detailsTabs = [
  {
    displayName: 'History',
    mode: 'history'
  }
]

class OfferSideBar extends Component {
  state = {
    mode: 'history'
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  render () {
    const { mode } = this.state
    const { selectedOffer, project, refetchHistoryList } = this.props
    return (
      <div>
        <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
        {mode === 'history' ? <HistoryList isChanging={refetchHistoryList} showFilter={false} content='OFFER_MANAGER' contentId={selectedOffer} project={project} /> : null }
        {mode === 'comments' ? null : null}
      </div>
    )
  }
}
export default OfferSideBar
