import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import PropTypes from 'prop-types'

import GeneralFieldCell from '../../../components/ui/dataDisplay/GeneralFieldCellAssetType'
import InformationIcon from '../general/icons/InformationIcon'

class GeneralFieldComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedGroupType: null,
      isLoading: true,
      selectedGroupType: undefined,
      showCreateBucketGroupModal: false,
      showDeleteGroupTypeModal: false
    }
  }

    onDragEnd = result => {
      if (!result.destination) {
        return
      }
      this.props.reorder(result.source.index, result.destination.index)
    }

    render () {
      const { streamField, isEnableSave, columnTitle1, columnTitle2, columnTitle3, isTitleInvisible, isSports, disabledStreams, metaSectionLists,
        onCloseField, listType, placeHolder1, placeHolder2, placeHolder3, isDragDisabled, onOptionSelect, handleToolTip, handleChange, handleDefaultValues, onChangeStatus, nameParam, type, onChangeUseAsTitle, selectedType, isFromEdit, onChangeIsEnabled, onChangeUpdatableStatus, onChangePropertyTooltipText, handleDisplayTitle } = this.props
      const fieldType = <div className='type'>
        <div>{columnTitle2}<span>*</span></div>
        {type !== 'appConfig' ? <div className='information-icon'>
          <InformationIcon />
        </div> : null}
      </div>
      const getItemStyle = (isDragging, draggableStyle) => ({
        border: isDragging ? '1px solid #cfd0d1' : 'none',
        ...draggableStyle
      })

      const getListStyle = isDraggingOver => ({
        borderTop: isDraggingOver ? '1px solid #cfd0d1' : 'none'
      })

      return (
        <div className='channel-stream-field-objects asset-type-meta-tab'>
          {isTitleInvisible ? '' : <div className='field-heading'>
            <p> Field Objects </p>
          </div>}
          <div className='field-data'>
            <div className={`asset-type-heading ${type === 'content-meta' ? 'content-placeholder-general' : 'asset-type-general'} `}>
              <div className='stream-name'> {columnTitle1}<span>*</span></div>
              <div className='display-name'> {'Display Name'} </div>  {/* This is mapped to displayTitle in Schema baes on Requirement */}
              <div className='group'> {fieldType} </div>
              {type === 'meta' &&
              <>
                <div className='meta-section'> {'Section'} </div>
                <div className='meta-tooltip' style={{ marginLeft: '18px' }}> {'Tooltip'} </div>
                <div className='meta-default-values'> {'Default Values'} </div>
              </>
              }
              {type === 'content-meta' &&
              <>
                <div className='meta-tooltip' style={{ marginLeft: '18px' }}> {'Tooltip'} </div>
                <div className='default-values'> {'Default Values'} </div>
              </>
              }
              <div className='required'> {columnTitle3} </div>
            </div>
            <div className='channel-stream-content'>
              {disabledStreams && disabledStreams.length ? disabledStreams.map((field, index) => {
                return <GeneralFieldCell
                  isDisabled={isDragDisabled}
                  field={field}
                  onCloseField={onCloseField}
                  isEnableSave={isEnableSave}
                  optionList={listType}
                  onChangeStatus={onChangeStatus}
                  placeHolder1={placeHolder1}
                  placeHolder2={placeHolder2}
                  placeHolder3={placeHolder3}
                  onOptionSelect={onOptionSelect}
                  handleChange={handleChange}
                  handleToolTip={handleToolTip}
                  handleDisplayTitle={handleDisplayTitle}
                  handleDefaultValues={handleDefaultValues}
                  onChangeUseAsTitle={onChangeUseAsTitle}
                  selectedType={selectedType}
                  onChangeEnabled={onChangeIsEnabled}
                  onChangeUpdatableStatus={onChangeUpdatableStatus}
                  nameParam={nameParam}
                  isSports={isSports}
                  index={index} />
              }) : ''}
              {streamField && streamField.length
                ? <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(droppableProvided, droppableSnapshot) => (
                      <div
                        ref={droppableProvided.innerRef}
                        style={getListStyle(droppableSnapshot.isDraggingOver)}
                      >
                        {streamField.map((field, index) => (
                          <Draggable key={field.id} draggableId={`id${index}`} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <GeneralFieldCell
                                  type={type}
                                  field={field}
                                  onCloseField={onCloseField}
                                  isEnableSave={isEnableSave}
                                  optionList={listType}
                                  onChangeStatus={onChangeStatus}
                                  placeHolder1={placeHolder1}
                                  placeHolder2={placeHolder2}
                                  onOptionSelect={onOptionSelect}
                                  handleChange={handleChange}
                                  handleToolTip={handleToolTip}
                                  handleDisplayTitle={handleDisplayTitle}
                                  handleDefaultValues={handleDefaultValues}
                                  onChangeUseAsTitle={onChangeUseAsTitle}
                                  nameParam={nameParam}
                                  isSports={isSports}
                                  selectedType={selectedType}
                                  isFromEdit={isFromEdit}
                                  onChangeEnabled={onChangeIsEnabled}
                                  onChangeUpdatableStatus={onChangeUpdatableStatus}
                                  onChangePropertyTooltipText={onChangePropertyTooltipText}
                                  index={index}
                                  metaSectionLists={metaSectionLists}
                                  enableButton={this.props.enableButton}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                : null }
            </div>
          </div>
        </div>
      )
    }
}

GeneralFieldComponent.propTypes = {
  /** Title of first column */
  columnTitle1: PropTypes.string,
  /** Title of second column */
  columnTitle2: PropTypes.string,
  /** Title of third column */
  columnTitle3: PropTypes.string,
  /** array of object list for dropdown */
  listType: PropTypes.array,
  /** Placeholder name of column 1 */
  placeHolder1: PropTypes.string,
  /** Placeholder name of column 2 */
  placeHolder2: PropTypes.string
}

GeneralFieldComponent.defaultProps = {
  columnTitle1: 'Field Objects',
  columnTitle2: 'Field Name',
  columnTitle3: 'Required',
  placeHolder1: 'Insert Stream Name',
  placeHolder2: 'Insert Group Type',
  listType: [{
    name: 'Number',
    value: 'number'
  }, {
    name: 'String',
    value: 'string'
  }],
  nameParam: 'name'
}

export default GeneralFieldComponent
