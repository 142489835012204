import React from 'react'
import PropTypes from 'prop-types'

const TwitterIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='#AFAFAF' fillRule='evenodd' d='M35.883 3.706a14.299 14.299 0 0 1-4.18 1.164 7.389 7.389 0 0 0 3.2-4.091 14.427 14.427 0 0 1-4.621 1.795A7.214 7.214 0 0 0 24.97.24c-4.018 0-7.276 3.31-7.276 7.395 0 .579.064 1.143.189 1.685-6.048-.309-11.41-3.252-15-7.727a7.45 7.45 0 0 0-.985 3.719 7.423 7.423 0 0 0 3.237 6.155 7.168 7.168 0 0 1-3.296-.926v.094c0 3.582 2.508 6.57 5.837 7.251a7.207 7.207 0 0 1-3.287.126c.927 2.938 3.614 5.077 6.798 5.136A14.45 14.45 0 0 1 .413 26.209a20.354 20.354 0 0 0 11.155 3.323c13.386 0 20.705-11.269 20.705-21.041 0-.32-.007-.64-.02-.958a14.874 14.874 0 0 0 3.63-3.827z' />
  </svg>
)

TwitterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

TwitterIcon.defaultProps = {
  width: 36,
  height: 30
}

export default TwitterIcon
