
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from '@hello-pangea/dnd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import BucketDetailListForRelatedSeason from '../../components/ui/dataDisplay/BucketDetailListForRelatedSeason'
import BucketAssetList from '../bucketManager/BucketAssetList'
import { utilityService } from '../../services/UtilityService'

import MutationAddSeasonToSeries from '../../graphQL/asset/hyperion/addSeriesSeasons'
import MutationRemoveSeason from '../../graphQL/asset/hyperion/deleteSeriesSeasons'
import MutationAddEpisodeToSeason from '../../graphQL/asset/hyperion/addSeasonEpisodes'
import MutationRemoveEpisode from '../../graphQL/asset/hyperion/deleteSeasonEpisodes'
import MutationUpdateSeasonEpisode from '../../graphQL/asset/hyperion/updateSeasonEpisodes'
import MutationUpdateSeriesSeason from '../../graphQL/asset/hyperion/updateSeriesSeasons'
import MutationCreateEpisodeFromSeason from '../../graphQL/asset/hyperion/createEpisodeFromSeason'
import MutationCreateSeasonFromSeries from '../../graphQL/asset/hyperion/createSeasonFromSeries'
import QueryFilterAssets from '../../graphQL/asset/searchAssets'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import { message, Button } from 'antd'

const parent = 'related-season-episode'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

let isUpdatedFlag = false

class RelatedSeasons extends Component {
  constructor (props) {
    super(props)
    const isSeason = props.isSeason
    this.state = {
      bucketDetails: _.cloneDeep(props.bucketDetails),
      assetList: props.assetList ? _.cloneDeep(_.sortBy(props.assetList, 'position')) : [],
      assetSearchString: '',
      assetSort: {
        value: 'updatedAt',
        order: 'desc'
      },
      assetFilter: { type: {
        multiple_exact: isSeason ? 'EPISODE' : 'SEASON'
      } },
      assetFilterSearch: '',
      isClearFilter: false,
      updatedBucketItem: [],
      saveLoading: false,
      isScroll: false,
      isLoading: false,
      newData: [],
      isEditted: false
    }
    this.updateCount = 0
    this.changedBucket = []
    this.assetFilterObj = [
      {
        displayName: 'Asset Type',
        fieldName: 'TYPES',
        isRequired: true,
        name: 'type',
        type: 'KEYWORD',
        value: [
          {
            key: isSeason ? 'EPISODE' : 'SEASON',
            label: null,
            labelType: null,
            name: isSeason ? 'EPISODE' : 'SEASON'
          }
        ]
      }
    ]
  }
  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.parentAssetId !== this.props.parentAssetId || (newProps.bucketDetails && !this.props.bucketDetails && !this.state.bucketDetails)) {
      this.setState({ bucketDetails: _.cloneDeep(newProps.bucketDetails), assetList: _.cloneDeep(_.uniqBy(_.sortBy(newProps.assetList, 'position'), 'id')) })
      this.clearFilterDetails()
    }
    if (newProps.isSaving && !this.props.isSaving && newProps.parentAssetId && this.state.isEditted) {
      // this.updateSeasonOrEpisode(newProps.bucketId)
    }
    if (isUpdatedFlag && !_.isEqual(newProps.bucketDetails, this.state.bucketDetails)) {
      isUpdatedFlag = false
      this.setState({ bucketDetails: _.cloneDeep(newProps.bucketDetails) })
    }
    if (!_.isEqual(newProps.assetList, this.props.assetList)) {
      this.setState({ assetList: _.cloneDeep(_.uniqBy(_.sortBy(newProps.assetList, 'position'), 'id')) })
    }
  }

  componentDidUpdate () {
    let { assetFilter } = this.state
    const { isSeason } = this.props
    if (isSeason && assetFilter && assetFilter.type && assetFilter.type.multiple_exact === 'SEASON') {
      assetFilter = { type: {
        multiple_exact: isSeason ? 'EPISODE' : 'SEASON'
      } }
      this.setState({ assetFilter })
    } else if (!isSeason && assetFilter && assetFilter.type && assetFilter.type.multiple_exact === 'EPISODE') {
      assetFilter = { type: {
        multiple_exact: isSeason ? 'EPISODE' : 'SEASON'
      } }
      this.setState({ assetFilter })
    }
  }

  componentDidMount () {
    this.initiateSubscription()
  }

  getAssetFilter = (isSeason, filter) => {
    this.assetFilterObj = _.isEmpty(filter) ? [
      {
        displayName: 'Asset Type',
        fieldName: 'TYPES',
        isRequired: true,
        name: 'type',
        type: 'KEYWORD',
        value: [
          {
            key: isSeason ? 'EPISODE' : 'SEASON',
            label: null,
            labelType: null,
            name: isSeason ? 'EPISODE' : 'SEASON'
          }
        ]
      }
    ] : filter
    return this.assetFilterObj
  }

  initiateSubscription = () => {
    if (this.props.subscribeToChangeBucket) {
      this.props.subscribeToChangeBucket()
    } else {
      setTimeout(() => {
        this.initiateSubscription()
      }, 1500)
    }
  }

  onDragEnd = async (result) => {
    const { project, parentAssetId, isSeason } = this.props
    const { assetSearchString, assetSort, assetFilter } = this.state
    let { assetList } = this.state
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (source.droppableId === 'asset-list' && destination.droppableId.startsWith('#Bucket_')) {
      const id = result.draggableId.split('#Asset_list_')[0]
      const isPresent = !_.isEmpty((assetList || []).find(item => item.assetId === id))
      if (isPresent) {
        message.warn('Duplicate asset')
        return
      }
      let assetData = { id }
      try {
        const variables = utilityService.getFormattedAssetFilter(assetSearchString, assetFilter, assetSort, project)
        const getCacheData = this.props.client.readQuery({ query: QueryFilterAssets, variables })
        if (getCacheData && getCacheData.listAssets) {
          assetData = getCacheData.listAssets.items.find(item => item.id === id)
          const data = {
            ...assetData,
            id: parentAssetId + id,
            assetId: id,
            position: destination.index
          }
          this.props.addingSubAsset()
          if (isSeason) {
            const addEpisodeVariables = {
              season: parentAssetId,
              episodes: [{ episode: id,
                position: destination.index }]
            }
            this.props.addSeasonEpisodes(addEpisodeVariables).then((response) => {
              // data.id = response.data.addSeasonEpisodes.id
              assetList = _.sortBy([...(assetList || []), data], 'position')
              this.setState({
                assetList
              })
              this.props.onEditRelatedSeaonOrEpisode(true)
            }, (errorMessage) => {
              utilityService.handleError(errorMessage)
            })
          } else {
            const addSeasonVariables = {
              series: parentAssetId,
              seasons: [{ season: id,
                position: destination.index }]
            }
            this.props.addSeriesSeasons(addSeasonVariables).then(response => {
              // data.id = response.data.addSeriesSeasons.id
              assetList = _.sortBy([...(assetList || []), data], 'position')
              this.setState({
                assetList
              })
              this.props.onEditRelatedSeaonOrEpisode(true)
            }, (errorMessage) => {
              utilityService.handleError(errorMessage)
            })
          }
          // this.setState({
          //   assetList: [...assetList, data]
          // })
        }
      } catch (error) {
        console.error('cache update did not work', error)
      }
    } else if (source.droppableId.startsWith('#Bucket_') && destination.droppableId.startsWith('#Bucket_')) {
      if (source.index !== destination.index) {
        this.reorderBucketItems(source.index, destination.index)
      }
    }
  }

  reorderBucketItems =(sourceIndex, destinationIndex) => {
    let { assetList, updatedBucketItem } = this.state
    let buckets = assetList ? [...assetList] : []
    const smallIndex = sourceIndex <= destinationIndex ? sourceIndex : destinationIndex
    const largeIndex = sourceIndex === smallIndex ? destinationIndex : sourceIndex
    buckets = this.getSortedList(buckets)
    this.changedBucket.push(buckets[sourceIndex].id)
    buckets = reorder(buckets, sourceIndex, destinationIndex)
    buckets = buckets.map((item, index) => {
      if (index >= smallIndex && index <= largeIndex && updatedBucketItem.indexOf(item.id) === -1) {
        updatedBucketItem.push(item.id)
      }
      item.position = (index >= smallIndex && index <= largeIndex) ? index : item.position
      return item
    })
    this.props.onEditRelatedSeaonOrEpisode(true)
    this.setState({ assetList: buckets, updatedBucketItem, isEditted: true })
  }

  updateSeasonOrEpisode = () => {
    const { isSeason, parentAssetId } = this.props
    this.setState({ saveLoading: true })
    const { assetList } = this.state
    let assetIdList = assetList.map((item, index) => ({ id: item.id, position: index }))
    const tempIdIndex = (assetIdList || []).indexOf('temp-id-123')
    if (tempIdIndex > -1) {
      this.updateCount++
      if (this.updateCount < 3) {
        setTimeout(() => this.updateSeasonOrEpisode(), 800)
        return
      }
      assetIdList.splice(tempIdIndex, 1)
    }
    this.updateCount = 0
    assetIdList = _.sortBy(assetIdList, 'position').map((item, index) => {
      item.position = index
      return item
    })
    const variables = {
      assetId: parentAssetId,
      input: assetIdList
    }
    if (isSeason) {
      this.props.updateSeasonEpisodes(variables).then(() => {
        this.setState({ saveLoading: false, isEditted: false })
      }, error => {
        this.setState({ saveLoading: false })
        utilityService.handleError(error)
      })
    } else {
      this.props.updateSeriesSeasons(variables).then(() => {
        this.setState({ saveLoading: false, isEditted: false })
      }, error => {
        this.setState({ saveLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  removeBucketItem = (itemId) => {
    let { assetList, updatedBucketItem } = this.state
    let { isSeason, parentAssetId } = this.props
    let bucketItems = assetList
    const deletedIndex = bucketItems.findIndex((item) => item.id === itemId)

    if (isSeason) {
      this.props.deleteSeasonEpisodes([itemId], parentAssetId).then(() => {
        this.props.onEditRelatedSeaonOrEpisode(true)
      })
    } else {
      this.props.deleteSeriesSeasons([itemId], parentAssetId).then(() => {
        this.props.onEditRelatedSeaonOrEpisode(true)
      })
    }
    bucketItems = bucketItems.filter((item) => item.id !== itemId).map((item, index) => {
      if (deletedIndex <= index && updatedBucketItem.indexOf(item.id) === -1) {
        updatedBucketItem.push(item.id)
      }
      item.position = index
      return item
    })
    assetList = bucketItems && bucketItems.length ? bucketItems : []
    this.setState({ assetList: assetList, updatedBucketItem, isEditted: true })
    if (assetList && assetList.length) { this.props.onEditRelatedSeaonOrEpisode(true) }
  }

  onChangeAssetFilter = (id, value) => {
    let { bucketDetails, updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const modifiedIndex = bucketItems.findIndex(item => item.id === id)
    bucketItems[modifiedIndex].filterRule = value
    bucketDetails.bucketItems = bucketItems
    if (updatedBucketItem.indexOf(id) === -1) { updatedBucketItem.push(id) }
    this.setState({ bucketDetails, updatedBucketItem, isEditted: true })
    this.props.onEditRelatedSeaonOrEpisode(true)
  }

  onChangeFilter = value => {
    this.setState({ assetSearchString: value })
  }

  onSearchFilter = (filterSearch) => {
    this.setState({ assetFilterSearch: filterSearch })
  }

  clearFilterDetails = () => {
    this.setState({ isClearFilter: true }, () => {
      this.setState({ isClearFilter: false })
      // setTimeout(() => this.setState({ isClearFilter: false }), 200)
    })
  }

  changeFilterValue = (filter) => {
    let newFilter = utilityService.getFormattedFilter(filter)
    this.assetFilterObj = this.getAssetFilter(this.props.isSeason, filter)
    this.setState({ isSearching: true, assetFilter: newFilter })
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  onBucketScroll= () => {
    this.setState({ isScroll: false })
  }

  createNewAsset = () => {
    const { isSeason, parentAssetId, project } = this.props
    let { assetList } = this.state
    const variables = { id: parentAssetId, project }
    this.setState({ isLoading: true })
    if (isSeason) {
      this.props.createEpisodeFromSeason(variables).then(({ data }) => {
        const newAsset = _.cloneDeep(data.createEpisodeFromSeason)
        newAsset.id = `${parentAssetId}${newAsset.id}`
        newAsset.assetId = `${parentAssetId}`
        assetList.splice(0, 0, newAsset)
        this.setState({ assetList, isLoading: false })
        window.open(location.origin + '/assets/' + data.createEpisodeFromSeason.id, '_blank')
        this.props.onEditRelatedSeaonOrEpisode(true)
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else {
      this.props.createSeasonFromSeries(variables).then(({ data }) => {
        const newAsset = _.cloneDeep(data.createSeasonFromSeries)
        newAsset.id = `${parentAssetId}${newAsset.id}`
        newAsset.assetId = `${parentAssetId}`
        assetList.splice(0, 0, newAsset)
        this.setState({ assetList, isLoading: false })
        window.open(location.origin + '/assets/' + data.createSeasonFromSeries.id, '_blank')
        this.props.onEditRelatedSeaonOrEpisode(true)
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  render () {
    const { isUpdateDisable, project, isSeason } = this.props
    const { assetFilter, assetSearchString, assetSort, isClearFilter, bucketDetails, saveLoading, isScroll, assetList, isLoading } = this.state
    let selectedAssets = bucketDetails && bucketDetails.bucketItems ? (bucketDetails.bucketItems || []).filter((item) => item.type === 'ASSET').map((item) => item.asset.id) : []
    selectedAssets.push(this.props.parentAssetId)
    const assetFilterObj = this.getAssetFilter(isSeason)
    return (
      <div className='related-assets related-season-episode' id='related-season-episode'>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className='inner-assets'>
            <p>{`Available ${isSeason ? 'Episodes' : 'Seasons'}`}</p>
            <div className='related-asset-container'>
              <div className='filter'>
                <FilterInput
                  searchString={assetSearchString}
                  onChangeSearchInput={this.onChangeFilter}
                  bucketAssetFilter={assetFilterObj}
                  onSearchFilter={this.onSearchFilter}
                  filterType={'AssetManager'}
                  parentId={parent}
                  clearFilterDetails={this.clearFilterDetails}
                  isClearFilter={isClearFilter}
                  changeFilter={this.changeFilterValue}
                  project={project}
                  disabledField={['type']}
                  className='related-season-episode-filter'
                  // isDisabled
                />
              </div>
              <BucketAssetList
                searchString={assetSearchString}
                sort={assetSort}
                filterVal={assetFilter}
                selectedAssets={selectedAssets}
                isDragDisabled={isUpdateDisable}
                project={project}
              />
            </div>
          </div>

          <div className='inner-assets'>
            <p>{`Selected ${isSeason ? 'Episodes' : 'Seasons'}`}</p>
            <div className='related-season-container'>
              <div className='filter'>
                {isLoading ? <Button loading className='loading-button' /> : ''}
                {!isLoading ? <p onClick={this.createNewAsset}>{`New ${isSeason ? 'Episode' : 'Season'} Asset`}</p> : ''}
              </div>
              <BucketDetailListForRelatedSeason
                // listData={assetList || []}
                listData={assetList || []}
                onChangeFilter={this.onChangeAssetFilter}
                saveLoading={saveLoading}
                removeBucketItem={this.removeBucketItem}
                parentManager='Asset'
                parent={parent}
                isScroll={isScroll}
                onBucketScroll={this.onBucketScroll}
                project={project}
                isSeason={isSeason}
              />
            </div>
          </div>
        </DragDropContext>
      </div>
    )
  }
}

RelatedSeasons.propTypes = {
  /** asset id of selected asset. */
  parentAssetId: PropTypes.string,
  /** bucket id of related asset. */
  bucketId: PropTypes.string,
  /** is Saving details status of RelatedAssets. */
  isSaving: PropTypes.bool.isRequired,
  /** Function to be called to create bucket in related assets */
  createdBucket: PropTypes.func,
  /** Function to be called on related assets update */
  onEditRelatedSeaonOrEpisode: PropTypes.func
}

RelatedSeasons.defaultProps = {}

export default withApollo(compose(

  graphql(
    MutationAddSeasonToSeries,
    {
      props: (props) => ({
        addSeriesSeasons: (input) => {
          let variables = { input }
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  // graphql(
  //   QueryGetBucketDetails,
  //   {
  //     skip: ({ bucketId }) => {
  //       return !bucketId
  //     },
  //     options: ({ bucketId }) => {
  //       return {
  //         variables: { id: bucketId }
  //       }
  //     },
  //     props: (props) => {
  //       return {
  //         bucketDetails: props.data.getBucket,
  //         isLoading: props.data.loading,
  //         fetchAssetDetails: () => {
  //           return props.data.refetch()
  //         }
  //       }
  //     }
  //   }
  // ),
  graphql(
    MutationRemoveSeason,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        deleteSeriesSeasons: (input, assetId) => {
          return props.mutate({
            variables: { input, assetId, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationAddEpisodeToSeason,
    {
      props: (props) => ({
        addSeasonEpisodes: (input) => {
          let variables = { input }
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  // graphql(
  //   QueryGetBucketDetails,
  //   {
  //     skip: ({ bucketId }) => {
  //       return !bucketId
  //     },
  //     options: ({ bucketId }) => {
  //       return {
  //         variables: { id: bucketId }
  //       }
  //     },
  //     props: (props) => {
  //       return {
  //         bucketDetails: props.data.getBucket,
  //         isLoading: props.data.loading,
  //         fetchAssetDetails: () => {
  //           return props.data.refetch()
  //         }
  //       }
  //     }
  //   }
  // ),
  graphql(
    MutationRemoveEpisode,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        deleteSeasonEpisodes: (input, assetId) => {
          return props.mutate({
            variables: { input, assetId, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateSeasonEpisode,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateSeasonEpisodes: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateSeriesSeason,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateSeriesSeasons: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateEpisodeFromSeason,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        createEpisodeFromSeason: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateSeasonFromSeries,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        createSeasonFromSeries: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(RelatedSeasons))
