import gql from 'graphql-tag'

export default gql(`
    mutation ($id: ID! $mediaId: String!) {
        triggerVwmVideoValidator(
            input: {
                id: $id
                mediaId: $mediaId
            }
        ){
            error
            streamStatus
            status
               
        }
   }`
)
