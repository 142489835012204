import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

// import GeneralFieldComponent from './../../dataDisplay/GeneralFieldComponent'
// import EditTagDetails from './EditTagDetails'
// import ButtonContainer from './../../general/buttons/ButtonContainer'
import AddImageModal from '../../dataEntry/other/AddImageModal'
import CropImageModal from './CropImageModal'
// import LoadingButton from '../../general/buttons/LoadingButton'
// import Input from './../inputs/Input'
// import DropDown from './../inputs/DropDown'
import './../../ui.style.scss'

import { withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import EditAppDetails from './EditAppDetails'
// import QueryImageSettings from '../../../../graphQL/content/getImageSetting'

const { TabPane } = Tabs

class CreateAppContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // showImageModal: false,
      activeKey: ''
    }
  }
  onSelected = (item) => {
    this.props.selectAspectRatio(item)
  };

  onTabChange=(activeKey) => {
    this.setState({ activeKey: activeKey })
  }

  handleShowAddImageModal = (selectedImgConfig) => {
    this.setState({ selectedImgConfig: selectedImgConfig, showAddImageModalInner: true })
  }

  hideAddImageModal = () => {
    this.setState({ showAddImageModalInner: false })
  }

  saveCurrentImageSelection = (config, imageDetails) => {
    this.setState({ showAddImageModalInner: false })
    this.props.handleObjectDetailsChange(config, imageDetails)
  }

  showImgEditorIcon = (selectedImgEditConfig, editImageDetails) => {
    this.setState({
      showEditIcon: true,
      selectedImgEditConfig: selectedImgEditConfig,
      editImageDetails: editImageDetails
    })
  }

  hideImgEditorIcon = () => {
    this.setState({
      showEditIcon: false,
      selectedImgEditConfig: null
    })
  }

  handleShowBucketModal = (selectedBucketConfig) => {
    this.setState({ selectedBucketConfig: selectedBucketConfig, showAddBucketModal: true })
  }

  hideAddBucketModal = () => {
    this.setState({ showAddBucketModal: false })
  }

  handleBucketSelection = (selectedBucket) => {
    this.setState({
      selectedBucket
    })
  }

  saveCurrentBucketSelection = (config) => {
    const { selectedBucket } = this.state
    this.setState({ showAddBucketModal: false, selectedBucket: null })
    this.props.handleObjectDetailsChange(config, selectedBucket)
  }

  showImgEditor = () => {
    this.setState({
      showImgEditModal: true
    })
  }

  hideImgEditor = () => {
    // const { editWidgetInstance } = this.props
    this.setState({
      showImgEditModal: false,
      editImageDetails: null
    })
    if (this.props.fetchAppPageDetails) {
      this.props.fetchAppPageDetails()
    }

    setTimeout(() => {
      // this.onConfigImgCropInstanceEdit(editWidgetInstance.id)
    }, 1000)
  }

  onConfigImgCropInstanceEdit = (id) => {
    const { listData } = this.props
    let editWidgetInstance = listData.filter(widget => widget.id === id)[0]
    this.props.handleListWidgetImgCropInstanceEdit(editWidgetInstance)
  }

  render () {
    const { saveCurrentSelection, selectedApp, isShowImageModal, mediaDetails, name, selectedKey, handleTextChange,
      handleURLChange, showImageModal, handleTypeChange, handleAppChange, handleKeyChange, showCropModal, appList, clearSelection, croppedUrl, icon, isUploadBlocked, project, handleIdChange, isFromEdit, appClient, url, hideImageModal, isShowCropModal, saveCurrentCrop, toggleCropModal, aspectRatio, settings, description, handleShortDescriptionChange, onInputFieldFocus, onInputFieldBlur, handleAppSearchTextChange, appSearchText, appMetaFields, appKey, onChangeAppStatus, isCritical, handleUserChange, handleUserSearch, assignedUsers, userList, isAppAdminDisabled, userSearch } = this.props
    // const { activeKey } = this.state
    return (
      <div id='edit-app-modal' className={`edit-app-modal`}>
        <Tabs defaultActiveKey='1' onChange={this.onTabChange}>
          <TabPane tab='General' key='1'>
            <EditAppDetails
              appClient={appClient}
              isFromEdit={isFromEdit}
              name={name}
              url={url}
              appKey={appKey}
              description={description}
              selectedKey={selectedKey}
              handleTypeChange={handleTypeChange}
              handleTextChange={handleTextChange}
              handleIdChange={handleIdChange}
              handleURLChange={handleURLChange}
              handleKeyChange={handleKeyChange}
              icon={icon}
              isCritical={isCritical}
              clearSelection={clearSelection}
              appList={appList}
              croppedUrl={croppedUrl}
              showImageModal={showImageModal}
              showCropModal={showCropModal}
              selectedApp={selectedApp}
              onChangeAppStatus={onChangeAppStatus}
              handleAppChange={handleAppChange}
              handleShortDescriptionChange={handleShortDescriptionChange}
              onInputFieldBlur={onInputFieldBlur}
              onInputFieldFocus={onInputFieldFocus}
              handleAppSearchTextChange={handleAppSearchTextChange}
              appSearchText={appSearchText}
              appMetaFields={appMetaFields}
              project={project}
              userList={userList}
              handleUserChange={handleUserChange}
              handleUserSearch={handleUserSearch}
              userSearch={userSearch}
              assignedUsers={assignedUsers}
              isAppAdminDisabled={isAppAdminDisabled}
            />
          </TabPane>
          {/* {type === 'VIDEO'
            ? <TabPane tab='Tags' key='3'>
              <EditTagDetails
                handleTagChange={handleTagChange}
                systemTags={systemTags}
                selectedTags={selectedTags}
                project={project}
              />
            </TabPane> : null
          } */}
        </Tabs>
        {/* {activeKey === '2'
          ? <div className={`add-field-stream ${_.isEmpty(streamField) ? ' no-streams' : ''}`}>
            <div className='edit-input-item'>
              <Input title='' placeholder='Label' inputName='title' value={tempPropertyName} handleChange={handleTempPropertyNameChange} noLabel />
            </div>
            <div className='edit-input-item'>
              <div className='output-format' id='dropdown-1'>
                <Input title='' placeholder='Key' inputName='title' value={tempFieldKey || undefined} handleChange={handleTempFieldTypeChange} noLabel />
              </div>
            </div>
            <LoadingButton
              type='primary'
              onClick={addFieldObjects}
              htmlType='submit'
              buttonText={'Add Property'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={false}
            />
          </div> : null} */}

        <AddImageModal
          isVisible={isShowImageModal}
          handleSubmit={saveCurrentSelection}
          handleCancel={hideImageModal}
          currentSelection={!_.isEmpty(mediaDetails) ? [mediaDetails] : []}
          mediaType={'IMAGE'}
          isSubmitButtonDisabled={isUploadBlocked}
          project={project} />

        <CropImageModal
          isVisible={isShowCropModal}
          aspectRatio={aspectRatio}
          imgUrl={settings && settings.length ? settings[0].fileName : null}
          settings={settings}
          updateSettings={saveCurrentCrop}
          handleCancel={() => toggleCropModal(false)}
          // isUpdateBlocked={isUpdateDisabledContent}
        />
      </div>
    )
  }
}

CreateAppContainer.propTypes = {
  /** Array of taglist */
  tagsList: PropTypes.array,
  /**  Array of selected tags */
  selectedTags: PropTypes.array,
  /**  Function to handle tag change */
  handleTagChange: PropTypes.func,
  /**  Function to handle video format change */
  handleFormatChange: PropTypes.func,
  /** Function to handle name cahnge */
  handleTextChange: PropTypes.func,
  /** Array of formats  */
  formats: PropTypes.array,
  /**  selected format */
  selectedFormat: PropTypes.string,
  /** Function to be called for updating images for each aspect ratios */
  updateAspectRatioImage: PropTypes.func,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** List of media category */
  categoryList: PropTypes.array,
  /** Selected category */
  selectedCategory: PropTypes.string
}

CreateAppContainer.defaultProps = {
}

// export default EditImageContainer

export default withApollo(
  compose(
    // graphql(
    //   QueryImageSettings,
    //   {
    //     options: ({ project }) => {
    //       const variables = {
    //         aspectRatio: '',
    //         fileName: '',
    //         ratio: null,
    //         cropPosition: 'TOP_LEFT',
    //         project
    //       }
    //       return {
    //         variables,
    //         fetchPolicy: 'cache-first'
    //       }
    //     },
    //     props: (props) => {
    //       return {
    //         // vodStatus: props.data.getAsset,
    //         getImageSetting: (input) => {
    //           return props.data.refetch({ input, project: props.ownProps.project })
    //         }
    //       }
    //     }
    //   }
    // )
  )(CreateAppContainer)
)
