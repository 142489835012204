import React from 'react'
import PropTypes from 'prop-types'

const CollapseIcon = ({ width, height, onClick, className, ...props }) => {
  const onCollapseClicked = () => {
    const playerElem = document.getElementById('player-container')
    if (playerElem) {
      playerElem.style.height = 'unset'
    }
    onClick()
  }
  return (
    <svg
      className='expand-icon'
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      onClick={onCollapseClicked}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h20v20H0z' />
        <path fill='#FFF' d='M18.788 4L20 5.294 10 16 0 5.294 1.206 4 10 13.406z' />
      </g>
    </svg>
  )
}

CollapseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

CollapseIcon.defaultProps = {
  width: 20,
  height: 20
}

export default CollapseIcon
