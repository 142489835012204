import React from 'react'
import PropTypes from 'prop-types'

const ArchiveIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M19.444 2.444L17.89.556C17.667.222 17.222 0 16.667 0H3.333c-.555 0-1 .222-1.333.556L.556 2.444C.222 2.89 0 3.334 0 3.89v13.889C0 19 1 20 2.222 20h15.556C19 20 20 19 20 17.778V3.888c0-.555-.222-1-.556-1.444zM10 16.111L3.889 10h3.889V7.778h4.444V10h3.89L10 16.111zM2.333 2.222l.89-1.11h13.333l1 1.11H2.333z' />
    </g>
  </svg>
)

ArchiveIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

ArchiveIcon.defaultProps = {
  width: 20,
  height: 20
}

export default ArchiveIcon
