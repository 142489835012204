import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AppContext from '../../AppContext'
import TabPanel from './../../components/ui/dataDisplay/TabPanel'
import AssetIcon from './../../components/ui/general/icons/AssetIcon'
import BucketIcon from './../../components/ui/general/icons/BucketIcon'
import GroupIcon from './../../components/ui/general/icons/GroupIcon'
import HistoryIcon from './../../components/ui/general/icons/HistoryIcon'
// import FilterInput from './../../components/ui/dataEntry/inputs/FilterInput'
import BucketList from './BucketList'
import BucketAssetList from './BucketAssetList'
import BucketGroupList from './BucketGroupList'
import HistoryList from '../auditManager/HistoryList'
import SortInput from './../../components/ui/dataEntry/inputs/SortInput'
import { utilityService } from '../../services/UtilityService'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'

const detailsTabs = (mode) => [
  {
    displayName: 'Groups',
    mode: 'groups',
    icon: <GroupIcon color={mode === 'groups' ? '#FF015A' : '#343A40'} />
  }, {
    displayName: 'Buckets',
    mode: 'buckets',
    icon: <BucketIcon color={mode === 'buckets' ? '#FF015A' : '#343A40'} />
  }, {
    displayName: 'Assets',
    mode: 'assets',
    icon: <AssetIcon color={mode === 'assets' ? '#FF015A' : '#343A40'} />
  }, {
    displayName: 'History',
    mode: 'history',
    icon: <HistoryIcon color={mode === 'history' ? '#FF015A' : '#343A40'} />
  }
]

// const bucketId = 'HU5NKRV3X' //b-s-6
const bucketId = 'b-s-6' //
// const bucketGroupId = 'bg-s-4'

class BucketSideBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mode: 'groups',
      historyFilter: [],
      historySearchString: null,
      isClearHistoryFilter: false,
      assetSort: {
        value: 'createdAt',
        order: 'desc'
      },
      bucketSort: {
        value: 'createdAt',
        order: 'desc'
      }
    }
    this.lastAppliedSort = {
      assets: {
        value: 'createdAt',
        order: 'desc'
      },
      buckets: {
        value: 'createdAt',
        order: 'desc'
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.selectedBucketGroup && (nextProps.selectedBucketGroup !== this.props.selectedBucketGroup || !_.isEqual(nextProps.addedBucket, this.props.addedBucket) || !_.isEqual(this.props, nextProps))) {
      const bucketList = (nextProps.addedBucket).map((item) => {
        return {
          key: item,
          name: item
        }
      })
      const bucket = { displayName: 'Bucket',
        fieldName: 'BUCKET',
        isRequired: true,
        name: 'contentId',
        type: 'KEYWORD',
        value: bucketList }
      const bucketGroup = { displayName: 'BucketGroup',
        fieldName: 'BUCKET_GROUP',
        isRequired: true,
        name: 'contentId',
        type: 'KEYWORD',
        value: [{
          key: nextProps.selectedBucketGroup,
          name: nextProps.selectedBucketGroup
        }] }
      this.setState({ historyFilter: [bucket, bucketGroup] })
    }
    if (nextProps.selectedBucketGroup !== this.props.selectedBucketGroup) {
      this.setState({ isClearHistoryFilter: true }, () => {
        this.setState({ isClearHistoryFilter: false })
      })
    }
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  onChangeFilterSearch = value => {
    if (this.state.mode === 'assets') {
      this.setState({ assetSearchString: value })
    } else {
      this.setState({ bucketSearchString: value })
    }
  }

  changeFilterValue = (filter) => {
    let newFilter = { }
    newFilter = utilityService.getFormattedFilter(filter)
    if (this.state.mode === 'assets') {
      this.assetFilterObj = filter
      this.setState({ isSearching: true, assetFilter: newFilter }, this.onChangeAssetFilter)
    } else {
      this.bucketFilterObj = filter
      this.setState({ isSearching: true, bucketFilter: newFilter })
    }
  }

  onSortChange = (sortData, isForced) => {
    if (_.isEqual(sortData, this.state.mode === 'assets' ? this.state.assetSort : this.state.bucketSort)) {
      return
    }
    if (!isForced) {
      this.lastAppliedSort[this.state.mode] = sortData
    }
    if (this.state.mode === 'assets') {
      this.setState({ assetSort: sortData }, this.onChangeAssetFilter)
    } else {
      this.setState({ bucketSort: sortData })
    }
  }

  onSearchFilter = (filterSearch) => {
    if (this.state.mode === 'assets') {
      this.setState({ assetFilterSearch: filterSearch })
    } else {
      this.setState({ bucketFilterSearch: filterSearch })
    }
  }

  onChangeFilter = (value) => {
    const sortData = {
      value: 'relevance',
      order: 'desc'
    }
    if (value) {
      this.onSortChange(sortData, true)
    } else {
      this.onSortChange(this.lastAppliedSort[this.state.mode], true)
    }
    if (this.state.mode === 'assets') {
      this.setState({ assetSearchString: value }, this.onChangeAssetFilter)
    } else {
      this.setState({ bucketSearchString: value })
    }
  }

  startCreateBucket = () => {
    this.bucketFilterObj = (this.bucketFilterObj || []).map((item) => {
      if (item.name === 'isArchived') { item.value = false } else { item.value = null }
      return item
    })
    this.changeFilterValue(this.bucketFilterObj)
  }

  onChangeAssetFilter = () => {
    this.props.onChangeAssetFilter({ filter: this.state.assetFilter, searchString: this.state.assetSearchString, sort: this.state.assetSort })
  }

  changeHistoryFilter = value => {
    const dataWithValue = (value || []).filter((item) => item.value)
    let newFilter = [...value]
    if (!dataWithValue.length) {
      const { addedBucket, selectedBucketGroup } = this.props
      const bucketList = (addedBucket).map((item) => {
        return {
          key: item,
          name: item
        }
      })
      const bucket = { displayName: 'Bucket',
        fieldName: 'BUCKET',
        isRequired: true,
        name: 'contentId',
        type: 'KEYWORD',
        value: bucketList }
      const bucketGroup = { displayName: 'BucketGroup',
        fieldName: 'BUCKET_GROUP',
        isRequired: true,
        name: 'contentId',
        type: 'KEYWORD',
        value: [{
          key: selectedBucketGroup,
          name: selectedBucketGroup
        }] }
      newFilter = _.cloneDeep([bucket, bucketGroup])
    }
    this.setState({ historyFilter: newFilter })
  }

  changeHistoryFilterString = value => {
    this.setState({ historySearchString: value })
  }

  render () {
    const { onBucketAdd, addedBucket, onSelectBucketGroup, selectedBucketGroup, isFetchHistory, displayTypes, disableAssetDrag } = this.props
    const { mode, bucketSearchString, assetSearchString, isClearAssetFilter, isClearBucketFilter, assetFilter, bucketFilter, assetSort, bucketSort,
      historyFilter, historySearchString, isClearHistoryFilter } = this.state
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['BUCKET_MANAGER']
          const isCreateBlocked = userPermissions.indexOf('CREATE') === -1
          const isUpdateBlocked = userPermissions.indexOf('UPDATE') === -1
          return <div className='bucket-sidebar' id='bucket-sidebar'>
            <div className={`bucket-sidebar-top ${mode}`}>
              <TabPanel tabs={detailsTabs(mode)} selectedMode={mode} handleModeChange={this.handleModeChange} />

              {mode === 'buckets'
                ? <div className='filter'>
                  <FilterInput
                    searchString={bucketSearchString}
                    onChangeSearchInput={this.onChangeFilter}
                    isClearFilter={isClearBucketFilter}
                    filterType={'BucketManager'}
                    changeFilter={this.changeFilterValue}
                    placement='rightTop'
                    bucketAssetFilter={this.bucketFilterObj}
                    project={project}
                  />
                </div> : null }
              {mode === 'assets'
                ? <div className='filter'>
                  <FilterInput
                    searchString={assetSearchString}
                    onChangeSearchInput={this.onChangeFilter}
                    isClearFilter={isClearAssetFilter}
                    filterType={'AssetManager'}
                    changeFilter={this.changeFilterValue}
                    placement='rightTop'
                    bucketAssetFilter={this.assetFilterObj}
                    project={project}
                  />
                </div> : null }
              {mode === 'buckets' ? <div className='sort'>
                <SortInput type='BucketManager' selectedOrder={bucketSort.order} selectedSort={bucketSort.value} onSortChange={this.onSortChange} project={project} />
              </div> : null }
              {mode === 'assets' ? <div className='sort'>
                <SortInput type='AssetManager' selectedOrder={assetSort.order} selectedSort={assetSort.value} onSortChange={this.onSortChange} project={project} />
              </div> : null }
            </div>
            {mode === 'buckets' ? <BucketList searchString={bucketSearchString} sort={bucketSort} filterVal={bucketFilter} onBucketAdd={onBucketAdd} project={project}
              addedBucket={addedBucket} startCreateBucket={this.startCreateBucket} displayTypes={displayTypes} isCreateBlocked={isCreateBlocked} isUpdateBlocked={isUpdateBlocked} /> : null }
            {mode === 'assets' ? <BucketAssetList searchString={assetSearchString} sort={assetSort} filterVal={assetFilter} isDragDisabled={isUpdateBlocked} project={project} disableAssetDrag={disableAssetDrag} /> : null }
            {mode === 'groups' ? <BucketGroupList onSelectBucketGroup={onSelectBucketGroup} selectedBucketGroup={selectedBucketGroup} project={project} /> : null }
            {mode === 'history'
              ? <HistoryList showFilter
                filter={historyFilter}
                searchString={historySearchString}
                changeFilter={this.changeHistoryFilter}
                changeFilterString={this.changeHistoryFilterString}
                content='BUCKET_MANAGER'
                contentId={bucketId}
                isClearFilter={isClearHistoryFilter}
                selectedBucketGroup={selectedBucketGroup}
                isChanging={isFetchHistory}
                project={project}
                displayTypes={displayTypes} /> : null }
          </div>
        }}
      </AppContext.Consumer>

    )
  }
}

BucketSideBar.propTypes = {
  /** Function to be called on bucket add */
  onBucketAdd: PropTypes.func,
  /** Array of ids of added Bucket */
  addedBucket: PropTypes.arrayOf(PropTypes.string),
  /** List of assets */
  assetList: PropTypes.arrayOf(PropTypes.object)
}

export default BucketSideBar
