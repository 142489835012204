import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import './../../ui.style.scss'

const Option = Select.Option

const filter = (input, option, options) => {
  const currentOption = (options || []).find(item => item === option.props.value)
  const returnValue = currentOption.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

class RatingDropDown extends React.Component {
    state = {
      isSearchPresent: false
    }

    onOptionSelect = (id) => {
      const selectedRating = (this.props.ratings || []).find(item => item === id)
      this.props.onRatingSelect(selectedRating, this.props.index)
    }

    onRatingSearch = (value) => {
      this.setState({ isSearchPresent: true })
      if (this.props.onSearch) { this.props.onSearch(value) }
    }

    onDropdownVisibleChange = (isOpen) => {
      if (!isOpen) { this.setState({ isSearchPresent: false }) }
    }

    render () {
      const { ratings, selectedRating, parentCompoent, isDisable } = this.props
      let optionComponents = (ratings || []).map((option, index) => (
        <Option key={option} value={option}> { option }
        </Option>
      ))

      return (

        <div className='rating-drop-down'>

          <Select
            allowClear
            dropdownClassName='rating-dropdown-popup'
            suffixIcon={<ExpandedIcon />}
            clearIcon={<CrossIcon />}
            value={selectedRating || undefined}
            placeholder='Select Rating'
            showSearch
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onChange={this.onOptionSelect}
            onSearch={this.onRatingSearch}
            optionFilterProp={'name'} filterOption={(input, option) => filter(input, option, ratings)}
            onDropdownVisibleChange={this.onDropdownVisibleChange}
            disabled={isDisable}
          >
            { optionComponents }
          </Select>
        </div>
      )
    }
}
RatingDropDown.propTypes = {
  /** Option selection action of Dropdown. */
  onRatingSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func,
  /** Selected value of Dropdown. */
  selectedRating: PropTypes.string,
  /** data for RatingDropDown. */
  ratings: PropTypes.array,
  /** parentcomponent for position */
  parentCompoent: PropTypes.string,
  /** search string for ratingSearch */
  ratingSearch: PropTypes.string
}

export default RatingDropDown
