import React from 'react'

// import { InputNumber } from 'antd'

import Switch from '../dataEntry/inputs/Switch'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import DropDown from '../dataEntry/inputs/DropDown'
import ButtonContainer from '../general/buttons/ButtonContainer'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAllTags from '../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../graphQL/asset/createTag'
import './../ui.style.scss'

const type = [{
  name: 'player',
  type: 'Player',
  id: 'player'
}, {
  name: 'coach',
  type: 'Coach',
  id: 'coach'
}, {
  name: 'assistant coach',
  type: 'Assistant coach',
  id: 'assistantCoach'
}]

class SquadCustomData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {}
    }
  }

  componentDidMount = () => {
    if (this.props.selectedSquad) {
      this.setState({
        details: _.cloneDeep(this.props.selectedSquad)
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedSquad && !_.isEqual(this.props.selectedSquad, nextProps.selectedSquad)) {
      this.setState({
        details: _.cloneDeep(nextProps.selectedSquad)
      })
    }
  }

  onOptionSelect = (name, type) => {
    const { details } = this.state
    if (type === 'nationality') {
      details.customNationality = name
    } else if (type === 'type') {
      details.customType = name
    }
    this.setState({ details }, () => this.props.onEditPlayer(details))
  }

  // handleGeneralChange = (e) => {
  //   this.setState({ isEdit: true })
  //   const { details } = this.state
  //   const { name, value } = e.target
  //   debugger
  //   if (!value.startsWith(' ')) {
  //     details[name] = value
  //     if (value === '') {
  //       this.setState({ details }, () => this.props.onEditPlayer(details, false))
  //     } else {
  //       this.setState({ details }, () => this.props.onEditPlayer(details))
  //     }
  //   }
  // }

  handleFirstNameChange = (e) => {
    this.setState({ isEditFirstName: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customFirstName = null
      } else {
        details.customFirstName = value
      }
      this.setState({ details }, () => this.props.onEditPlayer(details))
    }
  }

  handleLastNameChange = (e) => {
    this.setState({ isEditLastName: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customLastName = null
      } else {
        details.customLastName = value
      }
      this.setState({ details }, () => this.props.onEditPlayer(details))
    }
  }

  handleMatchNameChange = (e) => {
    this.setState({ isEditMatchName: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customMatchName = null
      } else {
        details.customMatchName = value
      }
      this.setState({ details }, () => this.props.onEditPlayer(details))
    }
  }

  handleNationalityChange = (e) => {
    this.setState({ isEditNationality: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customNationality = null
      } else {
        details.customNationality = value
      }
      this.setState({ details }, () => this.props.onEditPlayer(details))
    }
  }

  handlePositionChange = (e) => {
    this.setState({ isEditPosition: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customPosition = null
      } else {
        details.customPosition = value
      }
      this.setState({ details }, () => this.props.onEditPlayer(details))
    }
  }

  onHandleActive = (status) => {
    const { details } = this.state
    if (status === true) {
      details.customActive = 'yes'
    } else {
      details.customActive = 'no'
    }
    this.setState({ details }, () => this.props.onEditPlayer(details))
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { details } = this.state
    const selectedTags = details.tags ? details.tags : []
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'DATA_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            details.tags = selectedTags
            this.setState({ details }, () => this.props.onEditPlayer(details))
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}data_manager`
              selectedTags[tagIndex].key = newId
              details.tags = selectedTags
              this.setState({ details }, () => this.props.onEditPlayer(details))
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
          details.tags = selectedTags
        }
      }
    }
    this.setState({ details }, () => this.props.onEditPlayer(details))
  }

  render () {
    const { project } = this.props
    const { details, isEditPosition, isEditNationality, isEditMatchName, isEditLastName, isEditFirstName } = this.state
    const isChecked = details.customActive ? details.customActive : details.active
    return (
      <div className='squad-custom-data'>
        <div className='data'>
          <div className='squad-data'>
            <div className='details'>
              <Input title='First Name' placeholder='First Name' inputName='customFirstName' value={isEditFirstName ? details.customFirstName : details.customFirstName ? details.customFirstName : details.firstName} handleChange={this.handleFirstNameChange} />
            </div>
            <div className='details'>
              <Input title=' Last Name' placeholder='Last Name' inputName='customLastName' value={isEditLastName ? details.customLastName : details.customLastName ? details.customLastName : details.lastName} handleChange={this.handleLastNameChange} />
            </div>
            <div className='details'>
              <Input title='Match Name' placeholder='Match Name' inputName='customMatchName' value={isEditMatchName ? details.customMatchName : details.customMatchName ? details.customMatchName : details.matchName} handleChange={this.handleMatchNameChange} />
            </div>
            <div className='details'>
              <Input title='Nationality' placeholder='Nationality' inputName='customNationality' value={isEditNationality ? details.customNationality : details.customNationality ? details.customNationality : details.nationality} handleChange={this.handleNationalityChange} />
            </div>
            <div className='details'>
              <Input title='Position' placeholder='Position' inputName='customPosition' value={isEditPosition ? details.customPosition : details.customPosition ? details.customPosition : details.position} handleChange={this.handlePositionChange} />
            </div>
            <div className='details' id='details'>
              <label>Type</label>
              <DropDown options={type} onOptionSelect={(name) => this.onOptionSelect(name, 'type')} selectedValue={details.customType ? _.capitalize(details.customType) : _.capitalize(details.type)} valueParam='type' displayParam='type' placeHolder='Output format' parent={'details'} />
            </div>
            <div className='details'>
              <div className='type'>
                <span>Active</span>
                <div className='information-icon'>
                  <ButtonContainer displayTitle={'Enabled for Active Players'} childComponent={<InformationIcon />} />
                </div>
                <Switch isChecked={isChecked === 'yes'} onChange={(status) => this.onHandleActive(status)} />
              </div>
            </div>
          </div>
          <div className='custom-tags' id='custom-tags'>
            <label> Tags </label>
            <TagsInputContainer
              selectedItems={details && details.tags ? details.tags.filter(item => item).map(item => item.name) : []}
              onChange={this.handleTagChange}
              placeholder='Insert Tags'
              parentCompoent={'custom-tags'}
              displayParameter='name'
              onSearch={() => {}}
              searchTag={''}
              type={'MANUAL'}
              isCustom
              module={'DATA_MANAGER'}
              project={project}
            />
          </div>
        </div>
      </div>
    )
  }
}

SquadCustomData.propTypes = {

}

SquadCustomData.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateTag,
    {
      options: ({ project }) => {
        const filter = { type: { match: 'MANUAL' } }
        return {
          update: (cache, { data: { createTag } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },

      props: (props) => ({
        createTag: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(SquadCustomData))
