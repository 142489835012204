import gql from 'graphql-tag'

export default gql(`
    mutation saveAllBuckets($bucketItems:[UpdateBucketItemInput] $bucket:UpdateBucketInput $id: ID $project: String) {
        saveAllBuckets (
            input: {
                bucketItems: $bucketItems
                bucket: $bucket
            }
            id: $id
            project: $project
          ) {
            id
            isArchived
            name
            displayName
            key
            isPublished
            status
            publishStartDate
            publishEndDate
            updatedAt
            updatedBy
            displayType{
                name
                id
            }
            bucketItems{
                id
                position
                type
                customTitle
                customMedia{
                    id
                    url
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                            url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
                customAssetImage{
                    id
                    mediaId
                    url
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                            url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
                asset{
                    id
                    title
                    type
                    shortId
                    externalId
                    updatedAt
                    status
                    seasonNumber
                    episodeNumber
                    series{
                      id
                      assetId
                      title
                    }
                    season{
                        id
                        assetId
                        title
                    }
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                            url
                        }
                        }
                        settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                        }
                    }
                }
                filterRule{
                    searchKey
                    filters{
                        name
                        fieldName
                        type
                        value
                        displayValue
                        displayName
                        isRequired
                    }
                }
            }
        }
    }`
)
