import bitmovinPlayer from '../bitmovin/bitmovin'
import React, { useEffect, useState } from 'react'

const PlayPauseButton = () => {
  const [playing, setIsPlaying] = useState(() => {
    return bitmovinPlayer.isPlaying()
  })

  useEffect(
    () => {
      const ss = setInterval(() => {
        setIsPlaying(bitmovinPlayer.isPlaying())
      }, 200)
      return () => clearInterval(ss)
    },
    [bitmovinPlayer.isPlaying()]
  )

  const handlePlayPause = () => {
    if (!bitmovinPlayer.isPlaying()) {
      bitmovinPlayer.play()
    } else {
      bitmovinPlayer.pause()
    }
    setIsPlaying(curr => !curr)
  }

  return <div className={`playbutton ${playing ? 'on' : 'off'}`} onClick={handlePlayPause} />
}
export default PlayPauseButton
