import gql from 'graphql-tag'

export default gql(`
query($id:ID! $offset:Int $size:Int $project: String){
    getRelatedAssetsByMedia(
        id: $id
        offset: $offset
        size: $size
        project: $project
    )
    {
        items{
            id
            isArchived
            shortId
            externalId
            title
            type
            updatedAt
            publishStartDate
            publishEndDate
            status
            defaultMedia {
                id
                url
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                    url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2 
                    y2
                }
            }
            series{
                id
                assetId
                title
            }
            season{
                id
                assetId
                title
            }
            seasonNumber
            episodeNumber
            seasons{
                assetId
                isArchived
                shortId
                externalId
                title
                type
                updatedAt
                publishStartDate
                publishEndDate
                status
                isFree
                seasonNumber
                episodeNumber
                series{
                    id
                    assetId
                    title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                id
                fileName
                aspectRatio {
                    title
                    aspectRatio
                    resolutions {
                    url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2 
                    y2
                }
                }
            }
            episodes{
                assetId
                isArchived
                shortId
                externalId
                title
                type
                updatedAt
                publishStartDate
                publishEndDate
                status
                isFree
                seasonNumber
                episodeNumber
                series{
                    id
                    assetId
                    title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                    id
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                        url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
            }
        }
    }    
   }
`
)
