import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Skeleton } from 'antd'

import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import LoadingButton from './../../components/ui/general/buttons/LoadingButton'
import SidebarButton from './../../components/ui/general/buttons/SidebarButton'
import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
import RestoreButton from './../../components/ui/general/buttons/RestoreButton'
import PartnerHeading from '../../components/ui/dataDisplay/PartnerHeading'
import Accordion from './../../components/ui/dataDisplay/Accordion'
import PartnerGeneralDetails from './PartnerGeneralDetails'
import CreatePartnerAttributes from './../../components/ui/dataEntry/other/CreatePartnerAttributes'
import TermsAndConditions from './PartnerTermsAndConditions'
import PartnerSeoSetting from './PartnerSeoSetting'
import Button from './../../components/ui/general/buttons/Button'
import AddImageModal from './../../components/ui/dataEntry/other/AddImageModal'
import AddImageFromLocalFolderModal from './../../components/ui/dataEntry/other/AddImageFromLocalFolderModal'
import EditImageModal from './../../components/ui/dataEntry/other/EditImageModal'
import PartnerImages from './PartnerImages'
import userMessages from './../../constants/messages'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'

import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryMediaCategory from './../../graphQL/content/listMediaCategories'
import MutationArchivePartner from './../../graphQL/partner/updatePartnerDetails'
// import MutationArchivePartner from './../../graphQL/partner/updatePartnerDetails'
import QueryAllPartners from './../../graphQL/partner/searchPartner'
import AppContext from '../../AppContext'

let primaryImageType
const width = [ '100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%' ]

const deleteWarningMessage = {
  message: userMessages.OFFER_DELETE_CONFIRMATION,
  title: 'ARCHIVE ?'
}
const restoreWarningMessage = {
  message: userMessages.OFFER_RESTORE_CONFIRMATION,
  title: 'RESTORE ?'
}

class PartnerDetails extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    this.state = {
      showAddImage: false,
      isLocalFolderOpen: false,
      showAddImageFromLocal: false,
      selectedImage: null,
      activeImageType: primaryImageType,
      currentSelectedMediaType: '',
      showEditMedia: false,
      currentSelectedMediaId: '',
      currentSelectedMediaDetails: null,
      isFromContent: false,
      shouldShowWarning: false,
      shouldShowRestoreWarning: false,
      isDeleteLoading: false,
      isRestoreLoading: false
    }
  }

  showAddImages = popupId => {
    this.addImageType = popupId
    this.setState({ showAddImage: true })
  }

  showAddImageLocal = (showAddImageFromLocal) => {
    if (showAddImageFromLocal) {
      this.setState({ isLocalFolderOpen: false, showAddImageFromLocal }, () => {
        setTimeout(() => {
          this.setState({ isLocalFolderOpen: true }, () => {
            this.setState({ isLocalFolderOpen: false })
          })
        }, 100)
      })
    } else {
      this.setState({ showAddImageFromLocal })
    }
  }

  handleImageUpload = (fileData) => {
    const { activeImageType } = this.state
    this.setState({ showAddImageFromLocal: false })
    this.props.handleFileUpload(fileData, true, activeImageType)
  }

  onAddMedia = (media, mediaCategory) => {
    const { details } = this.props
    const { activeImageType } = this.state
    let newMedia = (media || []).filter(item => {
      const tempIndex = (details.media || []).findIndex(innerItem => innerItem.id === item.id)
      return tempIndex === -1
    })
    newMedia = newMedia.filter(item => {
      const tempIndex = (details.images || []).findIndex(innerItem => innerItem.type.id === activeImageType && innerItem.mediaId === item.id)
      return tempIndex === -1
    })
    if (this.state.showAddVideoList) {
      this.setState({ showAddVideoList: false })
      const newAddedVideo = ([...newMedia] || []).map(video => {
        video.category = mediaCategory ? { id: mediaCategory } : null
        return video
      })
      this.props.onAddMedia(newAddedVideo, true, mediaCategory)
    } else {
      this.setState({ showAddImage: false })
      if (this.addImageType === 'contents' && newMedia && newMedia.length) {
        this.createImageContents(newMedia)
      } else if (this.addImageType === 'images') {
        const newAddedImages = ([...newMedia] || []).map(image => {
          image.type = 'IMAGE'
          image.imageType = activeImageType
          return image
        })
        this.props.onAddMedia(newAddedImages, false)
      }
    }
  }

  showAddVideo = uploadType => {
    if (uploadType === 'uploadQueue') { this.setState({ showAddVideoList: true, showAddVideo: false }) } else { this.setState({ showAddVideo: true, showAddVideoList: false }) }
  }

  hideAddImage = () => {
    this.setState({ showAddImage: false, showAddVideoList: false })
  }

  hideImageEditor = (responseData, isVideo) => {
    this.currentId = undefined
    this.setState({ showEditMedia: false,
      currentSelectedMediaId: '',
      currentSelectedMediaDetails: undefined,
      currentSelectedMediaType: null,
      isFromContent: false })
    if (responseData) {
      this.props.updateMediaDetails(responseData, isVideo)
    }
  }

  showImageEditor = (imageUrl, imageId, details, currentId, isFromContent) => {
    this.currentId = currentId
    this.setState({ showEditMedia: true,
      currentSelectedMediaId: imageId,
      currentSelectedMediaDetails: details,
      currentSelectedMediaType: 'IMAGE',
      isFromContent })
  }

  onImageTypeChange = activeImageType => {
    this.setState({ activeImageType })
    this.props.onImageTypeChange(activeImageType)
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  toggleRestoreWarningMessage = status => {
    this.setState({ shouldShowRestoreWarning: status })
  }

  deleteCurrentPartner = () => {
    if (this.state.isDeleteLoading) {
      return
    }
    this.setState({ isDeleteLoading: true })
    this.props.archivePartner(this.props.details.id).then(response => {
      this.props.onDeletePartner([this.props.details.id])
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
    }, error => {
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  restoreCurrentPartner = () => {
    if (this.state.isRestoreLoading) {
      return
    }
    this.setState({ isRestoreLoading: true })
    this.props.restorePartner(this.props.details.id).then(response => {
      this.props.onDeletePartner([this.props.details.id])
      this.setState({ isRestoreLoading: false })
      this.toggleRestoreWarningMessage(false)
    }, error => {
      this.setState({ isRestoreLoading: false })
      this.toggleRestoreWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  render () {
    const {
      details,
      handleGeneralChange,
      handleActiveChanges,
      handleMetaDetailsChange,
      onInputFieldBlur,
      onInputFieldFocus,
      handleAppsChange,
      handleTermAndConditionChange,
      isInvalidTitle,
      handleSeoDetailsChange,
      onSeoKeywordChange,
      isInvalidSlug,
      listMediaCategories,
      project,
      onChangeDefaultImage,
      onCloseImage,
      uploadingFiles,
      isAssetImageLoading,
      activeImageType,
      submitPartnerDetails,
      isEdited,
      isSaving,
      isArchive,
      onChangeAttributes,
      listApps,
      originalOfferAttributes,
      refetchHistory,
      metaFieldList
    } = this.props
    const {
      showAddImageFromLocal,
      isLocalFolderOpen,
      showAddImage,
      showEditMedia,
      currentSelectedMediaType,
      currentSelectedMediaId,
      currentSelectedMediaDetails,
      shouldShowWarning,
      shouldShowRestoreWarning,
      isDeleteLoading,
      isRestoreLoading
    } = this.state

    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['PARTNER_MANAGER']
          const userMediaPermissions = permissions['CONTENT_BANK']
          const isCreateMediaDisabled = userMediaPermissions.indexOf('CREATE') === -1
          const isUpdateMediaDisabled = userMediaPermissions.indexOf('UPDATE') === -1
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <>
            <div className='container' id='partner-container' ref={(node) => { this.containerRef = node }}>
              <PartnerHeading
                active={details && details.isActive}
              />
              <Accordion
                title='General'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading} >
                    {details ? <PartnerGeneralDetails
                      details={_.cloneDeep(details)}
                      handleGeneralChange={handleGeneralChange}
                      handleActiveChanges={handleActiveChanges}
                      handleMetaDetailsChange={handleMetaDetailsChange}
                      onInputFieldBlur={onInputFieldBlur}
                      onInputFieldFocus={onInputFieldFocus}
                      handleAppsChange={handleAppsChange}
                      isInvalidTitle={isInvalidTitle}
                      listApps={listApps}
                      metaFieldList={_.cloneDeep(metaFieldList) || []}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Image'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }} loading={this.props.isLoading}>
                    <div className='image-type-container'>
                      {details ? <PartnerImages
                        images={(details.images || [])}
                        defaultMedia={details.logo}
                        onAddImage={this.onAddMedia}
                        onChangeDefaultImage={onChangeDefaultImage}
                        onCloseImage={onCloseImage}
                        showEditImage={this.showImageEditor}
                        onImageTypeChange={this.onImageTypeChange}
                        isButtonDisabled={false}
                        isUpdateImageDisabled={isUpdateMediaDisabled}
                        uploadingFiles={_.cloneDeep(uploadingFiles)}
                        isLoading={isAssetImageLoading}
                        activeImageType={activeImageType}
                        project={project}
                      /> : null }
                      <div className='button-container'>
                        <span>Add Images from:</span>
                        <div style={{ width: '150px' }}>
                          <Button
                            buttonText={'Content Bank'}
                            color='red'
                            onClick={() => this.showAddImages('images')}
                            style={{ width: '150px' }}
                            isDisabled={isUpdateDisabled}
                          />
                        </div>
                        <div style={{ width: '150px' }}>
                          <Button
                            buttonText={'Local Drive'}
                            color='red'
                            onClick={() => this.showAddImageLocal(true)}
                            style={{ width: '150px' }}
                            isDisabled={isCreateMediaDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </Skeleton>
                }
              />
              <Accordion
                title='Offer Attributes'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading} >
                    {details ? <CreatePartnerAttributes
                      project={project}
                      partnerId={details.id}
                      attributeData={_.cloneDeep(details.attributes)}
                      onChangeAttributes={onChangeAttributes}
                      originalOfferAttributes={originalOfferAttributes}
                      refetchHistory={refetchHistory}
                      isUpdateDisabled={isUpdateDisabled}
                      isCreateDisabled={isCreateDisabled}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='SEO'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading} >
                    {details ? <PartnerSeoSetting
                      assetId={details.id}
                      isInvalidSlug={isInvalidSlug}
                      details={{ slug: details.slug, title: details.seoTitle, metaDescription: details.seoMetaDescription, keywords: details.seoKeywords }}
                      onInputFieldFocus={onInputFieldFocus}
                      onInputFieldBlur={onInputFieldBlur}
                      handleSeoDetailsChange={handleSeoDetailsChange}
                      onSeoKeywordChange={onSeoKeywordChange}
                      defaultTitle={details.name}
                    /> : null}
                  </Skeleton>
                }
              />
              <Accordion
                title='Term and Conditions'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading} >
                    {details ? <TermsAndConditions
                      terms={details.termsAndConditions}
                      handleTermAndConditionChange={handleTermAndConditionChange}
                      onInputFieldFocus={onInputFieldFocus}
                      onInputFieldBlur={onInputFieldBlur}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              />
            </div>
            <div className='partner-details-footer'>
              {!isArchive && !details.isArchived ? <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={() => this.toggleWarningMessage(true)} isDisabled={isSaving || isUpdateDisabled} />} /> : null }
              {!isArchive && details.isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={() => this.toggleRestoreWarningMessage(true)} isDisabled={isSaving || isUpdateDisabled} />} /> : null }
              <ConfirmModal isVisible={shouldShowWarning} title={deleteWarningMessage.title} message={deleteWarningMessage.message} isLoading={isDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.toggleWarningMessage(false)} handleSubmit={this.deleteCurrentPartner} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <ConfirmModal isVisible={shouldShowRestoreWarning} title={restoreWarningMessage.title} message={restoreWarningMessage.message} isLoading={isRestoreLoading} rightButtonText={'Confirm'} handleCancel={() => this.toggleRestoreWarningMessage(false)} handleSubmit={this.restoreCurrentPartner} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />

              <div className='right'>
                <div className='side-button'>
                  <ButtonContainer displayTitle='Toggle Sidebar' childComponent={<SidebarButton onClick={this.props.toggleSidebar} />} />
                </div>
                <div style={{ width: '150px' }}>
                  <LoadingButton type='primary' onClick={() => submitPartnerDetails()} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isSaving} isDisabled={!isEdited || isUpdateDisabled} />
                </div>
              </div>
            </div>
            {details ? <EditImageModal
              // assetId={this.currentId || details.id}
              type={currentSelectedMediaType}
              isVisible={showEditMedia}
              hideImageEditor={this.hideImageEditor}
              imageId={currentSelectedMediaId}
              mediaDetails={currentSelectedMediaDetails}
              settings={currentSelectedMediaDetails ? currentSelectedMediaDetails.settings : null}
              categoryList={listMediaCategories}
              isUpdateBlocked={false}
              isUploadBlocked={false}
              selectedImageType={activeImageType}
              project={project} /> : null}
            <AddImageModal
              onChangeModal={this.showAddVideo}
              isMultiSelect isVisible={showAddImage}
              type={this.addImageType} handleCancel={this.hideAddImage}
              isUpdateDisabled={false}
              handleSubmit={this.onAddMedia}
              isSubmitButtonDisabled={showAddImage && this.addImageType !== 'contents'}
              isCancelButtonDisabled={false}
              currentSelection={this.props.details && this.addImageType !== 'contents'
                ? ([...(this.props.details.images || [])]).filter(item => item.imageType === activeImageType) : []}
              mediaType={'IMAGE'}
              project={project} />
            <AddImageFromLocalFolderModal
              isVisible={showAddImageFromLocal}
              handleCancel={() => this.showAddImageLocal(false)}
              handleSubmit={this.handleImageUpload}
              isSubmitButtonDisabled={false}
              isCancelButtonDisabled={false}
              isLoading={false}
              isLocalFolderOpen={isLocalFolderOpen}
              listMediaCategories={listMediaCategories}
              onChangeModal={this.showAddImageFromLocal}
              project={project} />
              </>
        }}
      </AppContext.Consumer>
    )
  }
}

PartnerDetails.propTypes = {
  /** toggle side bar value of PartnerManager. */
  showSidebar: PropTypes.bool,
  /** toggle side bar action of PartnerManager. */
  toggleSidebar: PropTypes.func,
  /** function to handle changes in PartnerGeneralDetails */
  handleGeneralChange: PropTypes.func,
  /** partner details of selected partner. */
  details: PropTypes.object,
  /** function to handle active button changes in PartnerGeneralDetails */
  handleActiveChanges: PropTypes.func,
  /** function to handle blur changes in PartnerGeneralDetails */
  onInputFieldBlur: PropTypes.func,
  /** function to handle focus changes in PartnerGeneralDetails */
  onInputFieldFocus: PropTypes.func,
  /** function to handle apps changes in PartnerGeneralDetails */
  handleAppsChange: PropTypes.func,
  /** Boolean to validate input error */
  isInvalidTitle: PropTypes.bool,
  /** content for TermsandCondition */
  terms: PropTypes.string,
  /** function to handle text changes in RichTextEditor */
  handleTermAndConditionChange: PropTypes.func,
  /** Boolean to validate url slug */
  isInvalidSlug: PropTypes.bool,
  /** function to handle change to SEO data */
  handleSeoDetailsChange: PropTypes.func,
  /** function to handle change to SEO keywords */
  onSeoKeywordChange: PropTypes.func,
  /** project id */
  project: PropTypes.string,
  /** function to handle default images in image component */
  onChangeDefaultImage: PropTypes.func,
  /** function to handle close images in image component */
  onCloseImage: PropTypes.func,
  /** function to handle local images upload in image component */
  handleFileUpload: PropTypes.func,
  /** array of images from local in image component */
  uploadingFiles: PropTypes.array,
  /** boolean to check image uploading */
  isAssetImageLoading: PropTypes.bool,
  /** string for active image type */
  activeImageType: PropTypes.string,
  /** function to handle add images in image component */
  onAddMedia: PropTypes.func,
  /** function to handle add images in image component */
  updateMediaDetails: PropTypes.func,
  /** function to handle attributes change */
  onChangeAttributes: PropTypes.func,
  /** list of applied filters for PartnerManager */
  appliedFilter: PropTypes.object,
  /** function to submit changes in Partner Details */
  submitPartnerDetails: PropTypes.func
}

export default withApollo(compose(
  graphql(
    QueryMediaCategory,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        return {
          listMediaCategories: props.data.listMediaCategories && props.data.listMediaCategories && props.data.listMediaCategories.length ? props.data.listMediaCategories : []
        }
      }
    }
  ),
  graphql(
    MutationArchivePartner,
    {
      props: (props) => ({
        archivePartner: (id) => {
          return props.mutate({
            variables: { id, isArchived: true, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updatePartner } }) => {
          const { searchString, partnerFilter, sort } = props.appliedFilter
          const { project } = props
          const filter = utilityService.getFormattedFilterForPartner(partnerFilter)
          const variables = utilityService.getFormattedPartnerFilter(filter, searchString, sort, project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllPartners, variables }))
          if (variables.filter.isArchived && (variables.filter.isArchived.match === true || variables.filter.isArchived.match === 'true')) {
            if (cacheData && cacheData.listPartners && cacheData.listPartners.items) {
              const index = cacheData.listPartners.items.findIndex(item => item.id === updatePartner.id)
              if (index === -1) {
                cacheData.listPartners.items.splice(0, 0, updatePartner)
                cacheData.listPartners.totalCount = cacheData.listPartners.totalCount + 1
              }
            }
          } else if (variables.filter.isArchived && (variables.filter.isArchived.match === false || variables.filter.isArchived.match === 'false')) {
            if (cacheData && cacheData.listPartners && cacheData.listPartners.items) {
              const index = cacheData.listPartners.items.findIndex(item => item.id === updatePartner.id)
              if (index > -1) {
                cacheData.listPartners.items.splice(index, 1)
                cacheData.listPartners.totalCount = cacheData.listPartners.totalCount - 1
              }
            }
          }

          cache.writeQuery({
            query: QueryAllPartners,
            data: cacheData,
            variables
          })
        }
      })
    }

  ),
  graphql(
    MutationArchivePartner,
    {
      props: (props) => ({
        restorePartner: (id) => {
          return props.mutate({
            variables: { id, isArchived: false, updatedAt: new Date().toISOString(), project: props.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updatePartner } }) => {
          const { searchString, partnerFilter, sort } = props.appliedFilter
          const { project } = props
          const filter = utilityService.getFormattedFilterForPartner(partnerFilter)
          const variables = utilityService.getFormattedPartnerFilter(filter, searchString, sort, project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllPartners, variables }))
          if (!filter.isArchived || (filter.isArchived && (filter.isArchived.match === true || filter.isArchived.match === 'true'))) {
            if (cacheData && cacheData.listPartners && cacheData.listPartners.items) {
              const index = cacheData.listPartners.items.findIndex(item => item.id === updatePartner.id)
              if (index > -1) {
                cacheData.listPartners.items.splice(index, 1)
                cacheData.listPartners.totalCount = cacheData.listPartners.totalCount - 1
              }
            }
          } else if (filter.isArchived && (filter.isArchived.match === false || filter.isArchived.match === 'false')) {
            if (cacheData && cacheData.listPartners && cacheData.listPartners.items) {
              const index = cacheData.listPartners.items.findIndex(item => item.id === updatePartner.id)
              if (index === -1) {
                cacheData.listPartners.items.splice(0, 0, updatePartner)
                cacheData.listPartners.totalCount = cacheData.listPartners.totalCount + 1
              }
            }
          }
          cache.writeQuery({
            query: QueryAllPartners,
            data: cacheData,
            variables
          })
        }
      })
    }
  )
)(withRouter(withApollo(PartnerDetails))))
