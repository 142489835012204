import React from 'react'
import PropTypes from 'prop-types'
import SaveIcon from '../ui/general/icons/SaveIcon'
import { Spin, Icon } from 'antd'

import './Player.scss'

const SaveButton = ({ isDisabled, onClick, isLoading, ...props }) => (
  <div className={`save-trim-button ${isDisabled ? 'disabled' : ''}`} onClick={!isLoading && !isDisabled ? onClick : () => {}} >
    {isLoading ? <Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} /> : <SaveIcon /> }
  </div>
)

SaveButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

SaveButton.defaultProps = {
  isDisabled: false
}

export default SaveButton
