import * as React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'

export class TableHeader extends React.Component {
  render () {
    return <div className={`${'table-header '} ${this.props.classStyle}`}>
      <span>{this.props.title}</span>
    </div>
  }
}

TableHeader.propTypes = {
  title: PropTypes.string,
  classStyle: PropTypes.string
}
