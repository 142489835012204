import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import { generateCroppedThumbnail, getCropImageUrl } from '../../../util/util'
import CropImageModal from '../../../components/ui/dataEntry/other/CropImageModal'
import AddImageModal from '../../../components/ui/dataEntry/other/AddImageModal'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryPerson from '../../../graphQL/admin/entertainmentData/listPerson'
import MutationDeletePerson from './../../../graphQL/admin/entertainmentData/deletePerson'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import CreatePersonModal from './../../../components/ui/dataEntry/other/CreatePersonModal'

const defaultImage = require('../../../assets/images/avatar.jpg')

const deletePersonMessage = {
  title: 'DELETE PERSON',
  firstMessage: 'Are you sure you want to delete this Person?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class PersonsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedPerson: null,
      isLoading: !(props.personsList && props.personsList.length),
      showAddPersonModal: false,
      showDeletePersonModal: false,
      selectedPerson: undefined,
      deleteLoading: false,
      isDeleted: false,
      selectedImage: null,
      personSearch: { searchString1: '', searchString2: '' }
    }
    this.columns = [
      {
        title: 'Persons',
        dataIndex: 'id',
        key: 'name',
        width: '37%',
        render: this.renderPerson
      },
      {
        title: 'Usage Count',
        dataIndex: 'usedCount',
        width: '50%',
        key: 'usedCount',
        render: (usedCount, { isError }) => this.renderUsedCount(usedCount, isError)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.personsList, this.props.personsList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.personsList, this.props.personsList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEqual(newProps.personsList, this.props.personsList) && this.props.personFilter && this.props.personFilter.searchString) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.personsList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedPerson) {
        this.setState({ openedPerson: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedPerson === id) {
        this.setState({ openedPerson: null })
      } else {
        this.setState({ openedPerson: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { personsList } = this.props
      const selectedPerson = personsList.find(item => item.id === id)
      if (!selectedPerson) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddPersonModal: true, selectedPerson, selectedImage: selectedPerson.image })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeletePersonModal: true, selectedPerson })
      }
      this.setState({ openedPerson: null })
    }

    onPersonSelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedPerson: selectedValue })
      } else {
        this.setState({ fromSelectedPerson: selectedValue })
      }
    }

    showAddPerson = () => {
      this.setState({ showAddPersonModal: true, selectedPerson: null, selectedImage: null })
    }

    hideAddPersonModal = () => {
      this.setState({ showAddPersonModal: false, selectedPerson: null, croppedUrl: null })
    }

    handleAddPerson = () => {
      this.setState({ showAddPersonModal: false, selectedPerson: null, croppedUrl: null })
    }

    clearSelectedImage = () => {
      this.setState({ selectedImage: null, croppedUrl: null })
    }

      showAddImageModal = () => {
        this.setState({ showAddImageModal: true })
      }

      hideAddImageModal = () => {
        this.setState({ showAddImageModal: false })
      }

      showCropModal = (images) => {
        let { selectedImage } = _.cloneDeep(this.state)
        if (images) {
          selectedImage = _.cloneDeep(images)
        }
        const aspectRatio = selectedImage[0].aspectRatio.find(item => item.title === '1:1')
        const settings = selectedImage[0].settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
        this.setState({ selectedImage, settings, aspectRatio }, () => this.setState({ showCropModal: true }))
      }

      hideCropModal = () => {
        this.setState({ showCropModal: false })
      }

    hideDeletePersonModal = () => {
      this.setState({ isDeleted: true, selectedPerson: null }, () => {
        this.setState({ showDeletePersonModal: false, isDeleted: false })
      })
    }

    handleDeletePerson = () => {
      const { selectedPerson } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedPerson) {
        return
      }
      this.setState({ deleteLoading: true })
      this.props.deletePerson(selectedPerson.id, selectedPerson.name).then(() => {
        message.success('Person deleted successfully')
        this.setState({ isDeleted: true, selectedPerson: null }, () => {
          this.setState({ showDeletePersonModal: false, deleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedPerson: null }, () => {
          this.setState({ showDeletePersonModal: false, deleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    onSearchOptions = (value, isFirst) => {
      let { personSearch } = this.state
      if (isFirst) {
        personSearch.searchString1 = value
      } else {
        personSearch.searchString2 = value
      }
      this.setState({ personSearch })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangePersonFilter({ searchString: value })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.personsList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMorePersons(this.props.personsList.length))
    }

    saveCurrentSelection = (selectedImage) => {
      this.setState({ selectedImage, showAddImageModal: false, showCreateAuthorModal: true })
    }

      saveCurrentCrop = (settings) => {
        const image = _.cloneDeep(this.state.selectedImage[0])
        const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
        const currentaspectId = currentAspectRatio.aspectRatio
        const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
        // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
        image.settings.map(setting => {
          setting.outputFormat = 'JPG'
          delete setting[ '__typename' ]
        })
        image.settings[changedIndex].x1 = settings.x1
        image.settings[changedIndex].x2 = settings.x2
        image.settings[changedIndex].y1 = settings.y1
        image.settings[changedIndex].y2 = settings.y2
        // this.props.updateImage(image.id, image.name, tags, image.settings)
        const croppedUrl = getCropImageUrl(image, settings)
        this.setState({ showCropModal: false, showCreateAuthorModal: true, croppedUrl, selectedImage: [image] })
      }

    renderUsedCount = (count, isError) => {
      if (isError) {
        return <div>{'Error in Transfer. Retry'}</div>
      } else if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderPerson = (id) => {
      const { personsList } = this.props
      const selectedPerson = (personsList || []).find(person => person.id === id)
      if (selectedPerson) {
        return (
          <div className='author-list-name'>
            <img src={selectedPerson.image ? generateCroppedThumbnail(selectedPerson.image, 200, 200, '1:1') : defaultImage} />
            <p>{ selectedPerson.name }</p>
          </div>
        )
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedPerson } = this.state
      const { personsList } = this.props
      const selectedPerson = personsList.find(item => item.id === id)
      if (selectedPerson && !selectedPerson.usedCount && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (!selectedPerson.usedCount || !parseInt(selectedPerson.usedCount)) {
        // availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedPerson === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { personsList, personFilter, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showAddPersonModal, showDeletePersonModal, selectedPerson, deleteLoading, showAddImageModal, settings, aspectRatio, showCropModal, croppedUrl, selectedImage, isDeleted } = this.state
      let adminItem
      if (selectedPerson && showDeletePersonModal) {
        adminItem = <div >
          <p>{ selectedPerson.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const userPermissionsContent = permissions['CONTENT_BANK']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
            const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
            return <div className='admin-portal entertainment-list'>
              <FilterInput
                searchString={personFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
                project={project}
              />
              <div className='admin-list persons-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > personsList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={personsList} pagination={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddPerson}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreatePersonModal
                clearSelection={this.clearSelectedImage}
                isVisible={showAddPersonModal}
                croppedUrl={croppedUrl}
                showCropModal={this.showCropModal}
                selectedImage={selectedImage}
                showImageModal={this.showAddImageModal}
                handleCancel={this.hideAddPersonModal}
                handleSubmit={this.handleAddPerson}
                isUpdateBlocked={isUpdateDisabledContent}
                // person={personsList}
                module='ADMIN_MANAGER'
                selectedPerson={selectedPerson}
                project={project}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
              />
              <AdminItemConfirmModal
                isVisible={showDeletePersonModal}
                title={deletePersonMessage.title}
                firstMessage={deletePersonMessage.firstMessage}
                secondMessage={deletePersonMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeletePersonModal}
                handleSubmit={this.handleDeletePerson}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
              <CropImageModal
                isVisible={showCropModal}
                aspectRatio={aspectRatio}
                imgUrl={settings && settings.length ? settings[0].fileName : null}
                settings={settings} updateSettings={this.saveCurrentCrop}
                handleCancel={this.hideCropModal}
                isUpdateBlocked={isUpdateDisabledContent} />
              <AddImageModal
                isMultiSelect={false}
                isVisible={showAddImageModal}
                handleSubmit={this.saveCurrentSelection}
                handleCancel={this.hideAddImageModal}
                isSubmitButtonDisabled={isCreateDisabledContent}
                isUpdateBlocked={isUpdateDisabledContent}
                mediaType='IMAGE'
                project={project} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

PersonsList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryPerson,
    {
      options: ({ personFilter, project }) => {
        const { searchString } = personFilter
        const filter = searchString ? { name: { match: searchString } } : null
        const variables = { filter, offset: 0, limit: 30, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let personList = data.listPerson ? data.listPerson.items : []
        personList = personList.map(item => {
          if (item.isTransferring) {
            item.usedCount = 'Transferring'
          }
          return item
        })
        return {
          personsList: personList,
          isLoading: data.loading || !data.listPerson,
          totalCount: data.listPerson ? data.listPerson.totalCount : 0,
          getMorePersons: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listPerson.items, ...fetchMoreResult.listPerson.items]
                prev.listPerson.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeletePerson, {
      options: ({ personFilter, project }) => ({
        update: (cache, { data: { deletePerson } }) => {
          const { searchString } = personFilter
          const filter = searchString ? { name: { match: searchString } } : null
          const variables = { filter, limit: 30, offset: 0, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPerson, variables }))
          if (cacheData && cacheData.listPerson && cacheData.listPerson.items) {
            const selectedPersonIndex = cacheData.listPerson.items.findIndex(item => item.id === deletePerson.id)
            if (selectedPersonIndex > -1) {
              cacheData.listPerson.items.splice(selectedPersonIndex, 1)
            }
          }
          cache.writeQuery({
            query: QueryPerson,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deletePerson: (id, name) => {
          return props.mutate({
            variables: { id, name, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(PersonsList))
