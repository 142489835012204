import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import DropDown from '../dataEntry/inputs/DropDown'

import './../ui.style.scss'

const streamList = [
  {
    name: 'Primary',
    id: 'PRI'
  },
  {
    name: 'Secondary',
    id: 'SEC'
  },
  {
    name: 'Multi',
    id: 'XBTSS'

  }
]

class RecreateJobModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedStream: 'PRI'
    }
  }

    onStreamSelect = (selectedStream) => {
      this.setState({ selectedStream })
    }

    render () {
      const { isVisible, isLoading, isLive2VOD, title, handleCancel, handleSubmit, jobId, isSubmitDisabled } = this.props
      const { selectedStream } = this.state
      const jobs = isLive2VOD ? [{ site: selectedStream, jobId: jobId }] : [{ jobId: jobId }]
      return <Modal
        className={`confirm-modal recreate-job-modal`}
        title={`RECREATE ${isLive2VOD ? 'LIVE2' : ''}VOD JOB`}
        visible={isVisible}
        cancelText={'Cancel'}
        okText={'Recreate'}
        onOk={() => handleSubmit(jobs)}
        okButtonProps={{ disabled: isSubmitDisabled }}
        onCancel={handleCancel}
        closable={false}
        centered
        confirmLoading={isLoading}
      >
        <div className='recreate-job-modal-content'>
          <span>{`Are you sure you want to recreate this ${isLive2VOD ? 'LIVE2' : ''}VOD Job`}</span>
          <div className='title-container'>
            <div className={isLive2VOD ? 'title' : 'title non-live'}>{'Title:'}</div>
            <span>{title}</span>
          </div>
          {isLive2VOD ? <div className='stream-container'>
            <div className='title'>Select SITE</div>
            <DropDown
              options={streamList}
              selectedValue={selectedStream}
              placeHolder='Select SITE'
              valueParam='id'
              displayParam='name'
              onOptionSelect={this.onStreamSelect}
              className={'selected'} />
          </div> : ''}
        </div>
      </Modal>
    }
}

RecreateJobModal.propTypes = {
  /** loading status of Modal. */
  isLoading: PropTypes.bool,
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Title of Modal. */
  title: PropTypes.string,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Boolean for indicating live2VOD or not */
  isLive2VOD: PropTypes.bool,
  /** ID of job for recreate */
  jobId: PropTypes.string,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

export default RecreateJobModal
