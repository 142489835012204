import * as React from 'react'
import { Collapse } from 'antd'
import _ from 'lodash'
// import CollapsedIcon from '../../components/ui/general/icons/CollapsedIcon'
import { ChannelConfigAccordionHeader } from '../../components/ui/dataDisplay/ChannelConfigAccordionHeader'
import EncoderGroupTile from '../../components/ui/dataDisplay/EncoderGroupTile'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

const Panel = Collapse.Panel

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'white' : 'white',
  margin: '0px 0 20px 0',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

export class ChannelConfigAccordion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      clickedId: '',
      isPrevent: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (this.state.clickedId && nextProps.data && nextProps.data.length) {
      const clickedId = this.state.clickedId
      nextProps.data.map((dataItem) => {
        if (dataItem.id === clickedId && dataItem.id === nextProps.activeConfigId) { this.setState({ clickedId: '', isPrevent: false }) }
      })
    }
  }

  onDragEnd = (result) => {
    this.setState({ isPrevent: true })
    if (result.destination) {
      this.props.onReorder(result.source.index, result.destination.index)
    }
  }
  onEditConfig = (id) => {
    if (this.props.onEditConfig) { this.props.onEditConfig(id) }
  }

  onRemove = (id) => {
    if (this.props.onRemove) { this.props.onRemove(id) }
  }

  getVersionChange = (versions, oldVersions) => {
    const validVersions = versions.findIndex(item => item.version) > -1
    if (validVersions) {
      if (!oldVersions) {
        return true
      } else {
        const returnBool = this.isArrayEqual(versions, oldVersions)
        return !returnBool
      }
    }
    return false
  }

  isArrayEqual = (x, y) => {
    return _(x).differenceWith(y, _.isEqual).isEmpty()
  }

  onMakeConfigActive = (id) => {
    this.setState({ clickedId: id })
    if (this.props.makeConfigActive) { this.props.makeConfigActive(id) }
  }

  sortData = (data) => {
    let tempdta = data.sort((a, b) => {
      if (a.position < b.position) { return -1 } else { return 1 }
    })
    return tempdta
  }

  render () {
    const { data, activeConfigId, masterConfig, configGroupList, onSelectPlayer, selectedStream, isLoadingPlayer } = this.props
    const { clickedId, isPrevent } = this.state
    return <DragDropContext onDragEnd={this.onDragEnd} >
      <Droppable droppableId='droppable'>
        { (droppableProvided, droppableSnapshot) => (

          <div className='config-group-container'
            ref={droppableProvided.innerRef}
            style={getListStyle(droppableSnapshot.isDraggingOver)}
          >
            { (data || []).map((dataItem, index) =>
              <Draggable key={index} draggableId={index + 1} index={index} >
                { (provided, snapshot) => {
                  let shouldUpdate = false
                  for (let i = 0; i < (dataItem.streams || []).length; i++) {
                    const seletectedStream = dataItem.streams[i]
                    const versionArray = seletectedStream.streamSchema && seletectedStream.streamSchema.levels ? seletectedStream.streamSchema.levels.map(item => ({ id: item.id, version: item.version })) : null
                    const oldVersion = seletectedStream.levelVersions ? seletectedStream.levelVersions.map(item => {
                      delete item.__typename
                      item.id = item.streamId
                      delete item.streamId
                      return item
                    }) : null
                    const status = this.getVersionChange(versionArray, oldVersion)
                    if (status) {
                      shouldUpdate = true
                      break
                    }
                  }
                  const selectedConfigGroup = configGroupList.find(item => item.id === dataItem.schemaId.id)
                  if (selectedConfigGroup && selectedConfigGroup.version !== dataItem.version) {
                    shouldUpdate = true
                  }

                  return (<div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Collapse key={`${index}`} defaultActiveKey={[ '1' ]} className='channel-config-accordion'>
                      <Panel header={<ChannelConfigAccordionHeader shouldUpdate={shouldUpdate} isActive={dataItem.id === activeConfigId} title={dataItem.name} id={dataItem.id} onEdit={this.onEditConfig}
                        onRemove={this.onRemove} onMakeConfigActive={this.onMakeConfigActive} isClicked={clickedId === dataItem.id}
                        isNotAllowed={clickedId ? dataItem.id !== clickedId : false} isPrevent={isPrevent}
                        isMasterEnabled={masterConfig && masterConfig.name === dataItem.name} />} key={`${index + 1}`}>
                        { dataItem.streams.filter((item) => item && item.url).map((item, itemIndex) => {
                          if (item) { return <EncoderGroupTile isLoadingPlayer={item.id === selectedStream && isLoadingPlayer} isPlayerSelected={item.id === selectedStream} details={item.streamSchema} stream={item} key={itemIndex} url={item.url} onSelectPlayer={() => onSelectPlayer(item)} /> }
                        })
                        }
                      </Panel>
                    </Collapse>
                  </div>
                  )
                }
                }
              </Draggable>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  }
}

ChannelConfigAccordion.propTypes = {
  /** Data for rendering config */
  data: PropTypes.any,
  /** Function to be called on edit config */
  onEditConfig: PropTypes.func.isRequired,
  /** Function to be called on remove config item */
  onRemove: PropTypes.func.isRequired,
  /** Function to be called on making a config active */
  makeConfigActive: PropTypes.func.isRequired,
  /** ID of currently active config group  */
  activeConfigId: PropTypes.string,
  /** Master config object */
  masterConfig: PropTypes.object
}
