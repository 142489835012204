import React, { Component } from 'react'
import { Modal, message } from 'antd'
import CreateAspectRatioContainer from './CreateAspectRatioContainer'
// import userMessages from './../../../../constants/messages'
// import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListAspectRatios from '../../../../graphQL/admin/media/listAspectRatios'
import MutationCreateAspectRatio from '../../../../graphQL/admin/media/createAspectRatio'
import MutationUpdateAspectRatio from '../../../../graphQL/admin/media/updateAspectRatio'
import MutationAttachAspectRatio from '../../../../graphQL/admin/media/attachResolutions'
import MutationBatchCreateResolutions from '../../../../graphQL/admin/media/batchCreateResolutions'
import MutationBatchUpdateResolutions from '../../../../graphQL/admin/media/batchUpdateResolutions'
import { utilityService } from '../../../../services/UtilityService'

const defaultDetails = {
  cropPosition: 'Top_Left'
}

class CreateAspectRatioModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isEdited: false,
      details: _.cloneDeep(this.props.selectedAspectRatio || defaultDetails)
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    if (nextProps.selectedAspectRatio && !_.isEqual(nextProps.selectedAspectRatio, this.props.selectedAspectRatio)) {
      this.setState({ details: _.cloneDeep(nextProps.selectedAspectRatio), isEdited: false })
    } else if (nextProps.selectedAspectRatio && this.state.details && this.state.details.id) {
      this.setState({ details: _.cloneDeep(defaultDetails), isEdited: false })
    }
  }

  handleTextChange = (event) => {
    const { details } = this.state
    const { value, name } = event.target
    details[name] = value
    this.setState({ details, isEdited: true })
  }

  handleAspectChange = (value, name) => {
    const { details } = this.state
    details[name] = value
    let aspectHeight = name === 'height' ? value : ''; let aspectWidth = name === 'width' ? value : ''; let ratio
    if (details.title) {
      ratio = details.title.split(':')
      aspectHeight = name === 'height' ? value : ratio[1]
      aspectWidth = name === 'width' ? value : ratio[0]
    }
    if (name === 'height' || name === 'width') {
      if (aspectWidth && aspectHeight) {
        details.ratio = (aspectWidth / aspectHeight).toFixed(2)
      }
      details.title = `${aspectWidth}:${aspectHeight}`
      if (name === 'height') {
        details.resolutions = (details.resolutions || []).map(item => ({ width: item.width, height: Math.round(item.width / aspectWidth * aspectHeight) }))
      } else {
        details.resolutions = (details.resolutions || []).map(item => ({ height: item.height, width: Math.round(item.height * aspectWidth / aspectHeight) }))
      }
    }
    this.setState({ details, isEdited: true })
  }

  handleCropPositionChange = (value) => {
    const { details } = this.state
    details.cropPosition = value
    this.setState({ details, isEdited: true })
  }

  addNewResolution = () => {
    const { details } = this.state
    const resolutions = details.resolutions || []
    resolutions.push({ height: '', width: '' })
    details.resolutions = resolutions
    this.setState({ details, isEdited: true })
  }

  onCloseResolution = index => {
    const { details } = this.state
    if (details.resolutions) {
      details.resolutions.splice(index, 1)
      this.setState({ details, isEdited: true })
    }
  }

  handleResolutionChange = (value, name, index) => {
    const { details } = this.state
    details.resolutions[index][name] = value
    details.resolutions[index].isEdited = true
    let aspectHeight, aspectWidth
    if (details && details.title) {
      const ratio = details.title.split(':')
      aspectHeight = ratio[1]
      aspectWidth = ratio[0]
    }
    if (name === 'height') {
      details.resolutions[index].width = Math.round(value * aspectWidth / aspectHeight)
    } else {
      details.resolutions[index].height = Math.round(value / aspectWidth * aspectHeight)
    }
    this.setState({ details, isEdited: true })
  }

  submitAspectRatio = () => {
    const { selectedAspectRatio } = this.props
    if (selectedAspectRatio) {
      this.updateAspectRatio()
    } else {
      this.createAspectRatio()
    }
  }

  createAspectRatio = () => {
    const { onHistoryUpdate } = this.props
    const { details } = this.state
    const invalidResolutions = (details.resolutions || []).filter(item => !item.width || !item.height)
    if (invalidResolutions && invalidResolutions.length) {
      message.error('Resolutions data should be complete')
      return
    }
    const newResolutions = (details.resolutions || []).filter(item => !item.id).map(item => ({ width: item.width, height: item.height }))
    if (newResolutions.length) {
      const ratio = details.title ? details.title.split(':') : []
      let aspectHeight, aspectWidth
      if (ratio && ratio.length === 2) {
        aspectHeight = ratio[1]
        aspectWidth = ratio[0]
      }
      if (!aspectHeight || !aspectWidth || !details.name) {
        if (!details.name) {
          message.error('Aspect ratio name should not be empty')
        } else if (!aspectHeight || !aspectWidth) {
          message.error('Aspect ratio width and height should not be empty')
        }
        return
      }
      this.setState({ isLoading: true })
      this.props.createAspectRatio(details).then(({ data }) => {
        const aspectRatio = data.createAspectRatio.aspectRatio
        this.props.createResolutions(newResolutions.map(item => ({ ...item, aspectRatio: aspectRatio }))).then(({ data }) => {
          const resolutions = (data.createResolutions || []).map(item => item.id)
          const request = { ...details, resolutions, aspectRatio: aspectRatio }
          this.props.attachResolutions(request).then(({ data }) => {
            this.setState({ isLoading: false })
            this.props.handleSubmit()
            // onHistoryUpdate()
            setTimeout(() => onHistoryUpdate(), 1000)
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else {
      message.error('Aspect Ratio require atleast one resolution')
    }
  }

  updateAspectRatio = () => {
    const { details } = this.state
    const { onHistoryUpdate } = this.props
    const invalidResolutions = (details.resolutions || []).filter(item => !item.width || !item.height)
    if (invalidResolutions && invalidResolutions.length) {
      message.error('Resolutions data should be complete')
      return
    } else if (!(details.resolutions || []).length) {
      message.error('Aspect Ratio require atleast one resolution')
      return
    }
    const newResolutions = (details.resolutions || []).filter(item => !item.id).map(item => ({ width: item.width, height: item.height }))
    const editedResolutions = (details.resolutions || []).filter(item => item.id && item.isEdited).map(item => ({ width: item.width, height: item.height, id: item.id }))
    this.setState({ isLoading: true })
    if (newResolutions.length && editedResolutions.length) {
      this.props.createResolutions(newResolutions.map(item => ({ ...item, aspectRatio: details.aspectRatio }))).then(({ data }) => {
        const createdResolutions = (data.createResolutions || []).map(item => item.id)
        this.props.updateResolutions(editedResolutions, details.aspectRatio).then(({ data }) => {
          const resolutions = [...(details.resolutions || []).filter(item => item.id).map(item => item.id), ...createdResolutions]
          const request = { ...details, resolutions }
          this.props.updateAspectRatio(request).then(({ data }) => {
            this.setState({ isLoading: false })
            this.props.handleSubmit()
            onHistoryUpdate()
          }, error => {
            this.setState({ isLoading: false })
            utilityService.handleError(error)
          })
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else if (newResolutions.length) {
      this.props.createResolutions(newResolutions.map(item => ({ ...item, aspectRatio: details.aspectRatio }))).then(({ data }) => {
        const createdResolutions = (data.createResolutions || []).map(item => item.id)
        const resolutions = [...(details.resolutions || []).filter(item => item.id).map(item => item.id), ...createdResolutions]
        const request = { ...details, resolutions }
        this.props.updateAspectRatio(request).then(({ data }) => {
          this.setState({ isLoading: false })
          this.props.handleSubmit()
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else if (editedResolutions.length) {
      this.props.updateResolutions(editedResolutions, details.aspectRatio).then(({ data }) => {
        const resolutions = (details.resolutions || []).filter(item => item.id).map(item => item.id)
        const request = { ...details, resolutions }
        this.props.updateAspectRatio(request).then(({ data }) => {
          this.setState({ isLoading: false })
          this.props.handleSubmit()
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else {
      const resolutions = (details.resolutions || []).filter(item => item.id).map(item => item.id)
      const request = { ...details, resolutions }
      this.props.updateAspectRatio(request).then(({ data }) => {
        this.setState({ isLoading: false })
        this.props.handleSubmit()
        onHistoryUpdate()
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  handleCancel = () => {
    this.setState({
      details: _.cloneDeep(defaultDetails),
      isEdited: false
    })
    this.props.handleCancel()
  }

  resetState = () => {
    const state = {
      details: _.cloneDeep(defaultDetails),
      isEdited: false
    }
    this.setState({ ...state })
  }

  render () {
    const { isVisible, selectedAspectRatio } = this.props
    const { isLoading, details, isEdited } = this.state
    return (
      <Modal
        className='confirm-modal edit-aspect-ratio'
        title={selectedAspectRatio ? 'EDIT ASPECT RATIO' : 'NEW ASPECT RATIO'}
        maskClosable={false}
        visible={isVisible}
        okText='Save'
        cancelText='Back'
        onOk={this.submitAspectRatio}
        onCancel={this.handleCancel}
        okButtonProps={{ disabled: isLoading || !isEdited || !details.name || !details.resolutions || !(details.resolutions || []).length, loading: isLoading }}
        closable={false}
        centered
        width='890px'
        afterClose={this.resetState}
        confirmLoading={isLoading}
      >
        {isVisible ? <CreateAspectRatioContainer
          details={details}
          handleTextChange={this.handleTextChange}
          handleCropPositionChange={this.handleCropPositionChange}
          addNewResolution={this.addNewResolution}
          handleResolutionChange={this.handleResolutionChange}
          handleAspectChange={this.handleAspectChange}
          onCloseResolution={this.onCloseResolution}
          // isCreateDisabled={isCreateDisabled}
        /> : null}
      </Modal>
    )
  }
}

export default withApollo(
  compose(
    graphql(
      MutationCreateAspectRatio, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { createAspectRatio } }) => {
              const variables = { limit: 100, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAspectRatios, variables }))
              if (cacheData && cacheData.listAspectRatios && cacheData.listAspectRatios.items) {
                const selectedAuthorIndex = cacheData.listAspectRatios.items.findIndex(item => item.aspectRatio === createAspectRatio.aspectRatio)
                if (selectedAuthorIndex > -1) {
                  cacheData.listAspectRatios.items[selectedAuthorIndex] = createAspectRatio
                } else {
                  cacheData.listAspectRatios.items.push(createAspectRatio)
                }
              }
              cache.writeQuery({
                query: QueryListAspectRatios,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          createAspectRatio: (request) => {
            return props.mutate({
              variables: { ...request }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateAspectRatio, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { updateAspectRatio } }) => {
              const variables = { limit: 100, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAspectRatios, variables }))
              if (cacheData && cacheData.listAspectRatios && cacheData.listAspectRatios.items) {
                const selectedAuthorIndex = cacheData.listAspectRatios.items.findIndex(item => item.aspectRatio === updateAspectRatio.aspectRatio)
                if (selectedAuthorIndex > -1) {
                  cacheData.listAspectRatios.items[selectedAuthorIndex] = updateAspectRatio
                }
              }
              cache.writeQuery({
                query: QueryListAspectRatios,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          updateAspectRatio: (request) => {
            return props.mutate({
              variables: { ...request }
            })
          }
        })
      }
    ),
    graphql(
      MutationAttachAspectRatio, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { attachResolutions } }) => {
              const variables = { limit: 100, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListAspectRatios, variables }))
              if (cacheData && cacheData.listAspectRatios && cacheData.listAspectRatios.items) {
                const selectedAuthorIndex = cacheData.listAspectRatios.items.findIndex(item => item.aspectRatio === attachResolutions.aspectRatio)
                if (selectedAuthorIndex > -1) {
                  cacheData.listAspectRatios.items[selectedAuthorIndex] = attachResolutions
                }
              }
              cache.writeQuery({
                query: QueryListAspectRatios,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          attachResolutions: (request) => {
            return props.mutate({
              variables: { ...request }
            })
          }
        })
      }
    ),
    graphql(
      MutationBatchCreateResolutions, {
        props: (props) => ({
          createResolutions: (resolutions) => {
            return props.mutate({
              variables: { resolutions }
            })
          }
        })
      }
    ),
    graphql(
      MutationBatchUpdateResolutions, {
        props: (props) => ({
          updateResolutions: (resolutions, aspectRatioId) => {
            return props.mutate({
              variables: { resolutions, aspectRatioId }
            })
          }
        })
      }
    )
  )(CreateAspectRatioModal)
)
