import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import _ from 'lodash'
import OptaDataDetails from './sportsConfig/OptaDataDetails'
import OptaSetting from './sportsConfig/OptaSetting'
import MatchTemplate from './others/MatchTemplate'
import AuthorList from './assets/AuthorList'
import MetaSections from './assets/MetaSections'
import CategoryList from './assets/CategoryList'
import AssetType from './assets/AssetType'
import ContentPlaceHolderList from './assets/ContentPlaceHolderList'
import BucketDisplayTypes from './buckets/BucketDisplayTypes'
import BucketGroupType from './buckets/BucketGroupType'
import TagsList from './assets/TagsList'
import RemoteFolder from './media/RemoteFolder'
import EncoderConfig from './media/EncoderConfig'
import ChannelStreams from './channel/ChannelStreams'
import Sports from './sportsConfig/Sports'
import AddSportsData from './sportsConfig/AddSportsData'
import Epg from './channel/Epg'
import PublishingGroups from './publish/PublishingGroups'
import ChannelConfigGroups from './channel/ChannelConfigGroups'
import AspectRatios from './media/AspectRatios'
import ImageCategoryList from './media/ImageCategoryList'
import ImageTypeList from './media/ImageTypeList'
import DrmPlaybackConfig from './media/DrmPlaybackConfig'
import DataHistory from './DataHistory'
import SidebarOpenButton from '../../components/ui/general/buttons/SidebarOpenButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import TwoFactorAuthenticationSettings from './settings/TwoFactorAuthenticationSettings'
import ParentRatingList from './entertainmentData/ParentRatingList'
import ProductionList from './entertainmentData/ProductionList'
import RoleList from './entertainmentData/RoleList'
import CountryList from './entertainmentData/CountryList'
import LanguageList from './entertainmentData/LanguageList'
import PersonsList from './entertainmentData/PersonsList'
import GenreList from './entertainmentData/GenreList'
import OfferManagement from './entertainmentData/OfferManagement'
import PartnerManagement from './entertainmentData/PartnerManagement'
import CustomMetaFieldsList from './customeMetaFields/customeMetaFieldsList'
import TagTypeList from './tagTypes/tagTypeList'
import { utilityService } from './../../services/UtilityService'
import AuthConfigSetting from './settings/AuthConfigSetting'
import CustomMetaFieldsListVcms from './customeMetaFields/CustomMetaFieldsListVcms'
import MatchMangerStatusManagement from './matchManager/MatchManagerStatusManagement'
import BucketSettings from './buckets/bucketSettings'

const imageTypeList = [
  {
    id: 1,
    name: 'Primary',
    usedCount: 8,
    assetTypes: [
      {
        id: 11,
        assetType: 'Classics',
        minimumImages: 2
      },
      {
        id: 12,
        assetType: 'Article',
        minimumImages: 1
      },
      {
        id: 13,
        assetType: 'Game',
        minimumImages: 2
      }
    ],
    aspectRatios: [
      {
        id: 21,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '512x512'
      },
      {
        id: 22,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1024x1024'
      },
      {
        id: 23,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1920x1920'
      },
      {
        id: 24,
        title: '16:9',
        cropPosition: 'Middle Center',
        dimension: '1280x720'
      }
    ]
  },
  {
    id: 2,
    name: 'Secondary',
    usedCount: 9,
    assetTypes: [
      {
        id: 11,
        assetType: 'Classics',
        minimumImages: 2
      },
      {
        id: 12,
        assetType: 'Article',
        minimumImages: 1
      },
      {
        id: 13,
        assetType: 'Game',
        minimumImages: 2
      }
    ],
    aspectRatios: [
      {
        id: 21,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '512x512'
      },
      {
        id: 22,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1024x1024'
      },
      {
        id: 23,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1920x1920'
      },
      {
        id: 24,
        title: '16:9',
        cropPosition: 'Middle Center',
        dimension: '1280x720'
      }
    ]
  },
  {
    id: 3,
    name: 'Apple TV',
    usedCount: 0,
    assetTypes: [
      {
        id: 11,
        assetType: 'Classics',
        minimumImages: 2
      },
      {
        id: 12,
        assetType: 'Article',
        minimumImages: 1
      },
      {
        id: 13,
        assetType: 'Game',
        minimumImages: 2
      }
    ],
    aspectRatios: [
      {
        id: 21,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '512x512'
      },
      {
        id: 22,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1024x1024'
      },
      {
        id: 23,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1920x1920'
      },
      {
        id: 24,
        title: '16:9',
        cropPosition: 'Middle Center',
        dimension: '1280x720'
      }
    ]
  },
  {
    id: 4,
    name: 'Web',
    usedCount: 12,
    assetTypes: [
      {
        id: 11,
        assetType: 'Classics',
        minimumImages: 2
      },
      {
        id: 12,
        assetType: 'Article',
        minimumImages: 1
      },
      {
        id: 13,
        assetType: 'Game',
        minimumImages: 2
      }
    ],
    aspectRatios: [
      {
        id: 21,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '512x512'
      },
      {
        id: 22,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1024x1024'
      },
      {
        id: 23,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1920x1920'
      },
      {
        id: 24,
        title: '16:9',
        cropPosition: 'Middle Center',
        dimension: '1280x720'
      }
    ]
  },
  {
    id: 5,
    name: 'Image Type 1',
    usedCount: 1,
    assetTypes: [
      {
        id: 11,
        assetType: 'Classics',
        minimumImages: 2
      },
      {
        id: 12,
        assetType: 'Article',
        minimumImages: 1
      },
      {
        id: 13,
        assetType: 'Game',
        minimumImages: 2
      }
    ],
    aspectRatios: [
      {
        id: 21,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '512x512'
      },
      {
        id: 22,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1024x1024'
      },
      {
        id: 23,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1920x1920'
      },
      {
        id: 24,
        title: '16:9',
        cropPosition: 'Middle Center',
        dimension: '1280x720'
      }
    ]
  },
  {
    id: 6,
    name: 'Image Type 2',
    usedCount: 0,
    assetTypes: [
      {
        id: 11,
        assetType: 'Classics',
        minimumImages: 2
      },
      {
        id: 12,
        assetType: 'Article',
        minimumImages: 1
      },
      {
        id: 13,
        assetType: 'Game',
        minimumImages: 2
      }
    ],
    aspectRatios: [
      {
        id: 21,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '512x512'
      },
      {
        id: 22,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1024x1024'
      },
      {
        id: 23,
        title: '1:1',
        cropPosition: 'Middle Center',
        dimension: '1920x1920'
      },
      {
        id: 24,
        title: '16:9',
        cropPosition: 'Middle Center',
        dimension: '1280x720'
      }
    ]
  }
]

let moduleList = {
  'asset-types': 'ASSET_TYPE',
  'authors': 'AUTHOR',
  'sections': 'META_SECTION',
  'categories': 'ASSET_CATEGORY',
  'content-placeholder': 'CONTENT_PLACE_HOLDER',
  'tags': 'TAG',
  'tag-types': 'TAG_TYPE',
  'channel-streams': 'STREAM_LEVEL',
  'channel-config-groups': 'CONFIG_GROUP',
  'epg': 'EPG_CONFIG',
  'bucket-group-types': 'BUCKET_GROUP_TYPE',
  'display-types': 'DISPLAY_TYPE',
  'aspect-ratio': ['ASPECT_RATIO', 'RESOLUTION'],
  'encoder-config': 'VWM_CONFIG_SETTINGS',
  'image-types': 'IMAGE_TYPE',
  'media-category': 'MEDIA_CATEGORY',
  'remote-folder': 'S3_CONFIG_SETTINGS',
  'drm-playback-settings': 'DRM_PLAYBACK_SETTINGS',
  'opta-data': ['COMPETITION', 'SEASON', 'TEAM', 'PLAYER'],
  'opta-settings': 'OPTA_SYNC',
  'publish-groups': 'PUBLISH_GROUP',
  'apple-news': 'SECTION_MAPPING',
  'match-template': 'MATCH_TEMPLATE',
  'sports': 'SPORT',
  'add-sports-data': 'SPORT_DATA',
  'mfa-settings': 'CONFIG',
  'genre': 'GENRE',
  'production-studio': 'PRODUCTION_STUDIO',
  'roles': 'ROLE',
  'persons': 'PERSON',
  'parental-rating-config': 'RATING',
  'countries': 'COUNTRY',
  'languages': 'LANGUAGE',
  'partner-manager': 'PARTNER_CONFIG',
  'offer-manager': 'OFFER_CONFIG',
  'custom-meta-fields': 'META_FIELD_CONFIG',
  'match-manager': 'MATCH_CONFIG',
  'bucket-settings': 'MODULE_CONFIG'

}

let currentProject = utilityService.getCurrentProject()
// let currentProject = 'hyperion'

const availableOptionsHyperion = _.pick(moduleList, ['asset-types', 'authors', 'sections', 'categories', 'content-placeholder', 'media-category', 'image-types', 'tags', 'tag-types', 'display-types', 'bucket-group-types', 'genre', 'production-studio', 'roles', 'persons', 'parental-rating-config', 'countries', 'languages', 'partner-manager', 'offer-manager', 'publish-groups', 'apple-news', 'mfa-settings', 'custom-meta-fields', 'bucket-settings'])

const availableOptionsVcms = _.omit(moduleList, ['genre', 'production-studio', 'roles', 'persons', 'parental-rating-config', 'countries', 'languages', 'offer-manager', 'partner-manager'])
moduleList = currentProject === 'hyperion' || currentProject === 'projectx' ? availableOptionsHyperion : availableOptionsVcms

class DataDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isAddCompetition: false,
      authorFilter: {},
      tagFilter: {},
      categoryFilter: {},
      personFilter: {},
      genreFilter: {},
      roleFilter: {},
      countryFilter: {},
      languageFilter: {},
      productionFilter: {},
      contentFilter: '',
      displayTypeFilter: {},
      bucketGroupFilter: {},
      publishingGroupsFilter: {},
      isadminHistory: false,
      isSideBarVisible: false,
      optaIds: [],
      aspectRatioId: [],
      csvFile: [],
      selectedSportFields: {},
      dataFilter: {
        sport: ''
      },
      isDeleting: false,
      contentId: null
    }
    this.selectedOptaIds = []
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.selectedDataId !== this.props.selectedDataId) { this.setState({ tagFilter: {}, contentId: null }) }
  }

  onChangeAuthorFilter = filter => {
    this.setState({ authorFilter: filter })
  }

  onChangeTagFilter = filter => {
    let { tagFilter } = this.state
    tagFilter.searchString = filter.searchString
    this.setState({ tagFilter })
  }

  changeTagFilter = filter => {
    let { tagFilter } = this.state
    if (filter.length) {
      tagFilter.filter = filter
      this.setState({ tagFilter })
    }
  }

  onChangeCategoryFilter = filter => {
    this.setState({ categoryFilter: filter })
  }

  onChangeGenreFilter = filter => {
    this.setState({ genreFilter: filter })
  }

  onChangePersonFilter = filter => {
    this.setState({ personFilter: filter })
  }

  onChangeProductionFilter = filter => {
    this.setState({ productionFilter: filter })
  }

  onChangeRoleFilter = filter => {
    this.setState({ roleFilter: filter })
  }

  onChangeCountryFilter = filter => {
    this.setState({ countryFilter: filter })
  }

  onChangeLanguageFilter = filter => {
    this.setState({ languageFilter: filter })
  }

  onChangeContentFilter = contentFilter => {
    this.setState({ contentFilter })
  }

  onChangeDisplayTypeFilter = filter => {
    this.setState({ displayTypeFilter: filter })
  }

  onChangeBucketGroupFilter = filter => {
    this.setState({ bucketGroupFilter: filter })
  }

  onChangePublishingGroupsFilter = filter => {
    this.setState({ publishingGroupsFilter: filter })
  }

  onHistoryUpdate = () => {
    this.setState({ isadminHistory: true }, () => {
      this.setState({ isadminHistory: false })
    })
  }

  optaHistoryId = (listIds) => {
    const { optaIds } = this.state
    if (!_.isEqual(listIds, optaIds)) {
      this.setState({ optaIds: _.cloneDeep(listIds) })
    }
  }

  aspectRatioId = id => {
    this.setState({ aspectRatioId: id })
  }

  toggleSidebar = () => {
    this.setState({ isSideBarVisible: !this.state.isSideBarVisible })
  }

  uploadCsv = (files) => {
    const newFiles = files.map(file => ({ fileName: file.name, contentType: 'text/csv', type: 'CSV' }))
    this.setState({ csvFile: newFiles })
  }

  onChangeDataFilter = (dataFilter) => {
    this.setState({ dataFilter })
  }
  clearFileName = () => this.setState({ csvFile: [] })

  setSelectedSport = (value) => {
    this.setState({
      selectedSportFields: {
        ...this.state.selectedSportFields,
        selectedSport: value
      }
    })
  }

  setSelectedSportType = (value) => {
    this.setState({
      selectedSportFields: {
        ...this.state.selectedSportFields,
        selectedSportType: value
      }
    })
  }

  onHandleMultipleSelect=() => {
    this.setState({ isDeleting: true })
  }

  onHandleBackButton=() => {
    this.setState({ isDeleting: false })
  }

  setContentId=(id) => {
    this.setState({ contentId: id })
  }

  render () {
    const {
      authorFilter,
      tagFilter,
      categoryFilter,
      displayTypeFilter,
      bucketGroupFilter,
      contentFilter,
      genreFilter,
      productionFilter,
      personFilter,
      roleFilter,
      languageFilter,
      countryFilter,
      publishingGroupsFilter,
      isadminHistory,
      isSideBarVisible,
      csvFile,
      selectedSportFields,
      dataFilter,
      contentId
    } = this.state
    const { selectedDataId, data, project } = this.props
    let historyId = moduleList[selectedDataId]
    return (
      <Row className='' id=''>
        <Col xl={{ span: (historyId && isSideBarVisible) ? 16 : 24 }} xs={{ span: (historyId && isSideBarVisible) ? 16 : 24 }} className='data-details'>
          {data ? (
            <div className='data-heading'>
              <label>
                {data.displayName}
                {data && data.child && data.child.length
                  ? data.child
                    .filter(item => item.id === selectedDataId)
                    .map(item => {
                      return ` > ${item.displayName}`
                    })
                  : ''}
              </label>{' '}
              {!isSideBarVisible ? <ButtonContainer displayTitle='Show Audit History' placement='bottomLeft' childComponent={<SidebarOpenButton onClick={this.toggleSidebar} />} /> : <div className='invisible' />}
            </div>
          ) : (
            ''
          )}
          {selectedDataId === 'asset-types' ? <AssetType onHistoryUpdate={this.onHistoryUpdate} project={project} /> : null}
          {selectedDataId === 'authors' ? (
            <AuthorList
              authorFilter={authorFilter}
              onChangeAuthorFilter={this.onChangeAuthorFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'sections' ? (
            <MetaSections
              authorFilter={authorFilter}
              onChangeAuthorFilter={this.onChangeAuthorFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'categories' ? (
            <CategoryList
              categoryFilter={categoryFilter}
              onChangeCategoryFilter={this.onChangeCategoryFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'tags' ? (
            <TagsList
              tagFilter={tagFilter}
              onChangeTagFilter={this.onChangeTagFilter}
              changeTagFilter={this.changeTagFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'tag-types' ? (
            <TagTypeList
              onHistoryUpdate={this.onHistoryUpdate}
              onHandleMultipleSelect={this.onHandleMultipleSelect}
              onHandleBackButton={this.onHandleBackButton}
              isDeleting={this.state.isDeleting}
              project={project}
            />
          ) : null}

          {selectedDataId === 'sports' ? <Sports onHistoryUpdate={this.onHistoryUpdate} /> : null}
          {selectedDataId === 'add-sports-data' ? <AddSportsData onHistoryUpdate={this.onHistoryUpdate} uploadList={this.uploadCsv} files={csvFile} clearFileName={this.clearFileName} setSelectedSport={this.setSelectedSport} setSelectedSportType={this.setSelectedSportType} selectedSportFields={selectedSportFields} project={project} /> : null}
          {selectedDataId === 'opta-data' ? <OptaDataDetails onHistoryUpdate={this.onHistoryUpdate} optaHistoryId={this.optaHistoryId} onChangeDataFilter={this.onChangeDataFilter} dataFilter={dataFilter} project={project} /> : null}
          {selectedDataId === 'mfa-settings' ? (
            <TwoFactorAuthenticationSettings onHistoryUpdate={this.onHistoryUpdate} project={project} />
          ) : null}
          {selectedDataId === 'mfa-settings' ? (
            <AuthConfigSetting onHistoryUpdate={this.onHistoryUpdate} project={project} />
          ) : null}
          {selectedDataId === 'opta-settings' ? <OptaSetting onHistoryUpdate={this.onHistoryUpdate} /> : null}
          {selectedDataId === 'content-placeholder' ? (
            <ContentPlaceHolderList
              onChangeContentFilter={this.onChangeContentFilter}
              searchString={contentFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'display-types' ? (
            <BucketDisplayTypes
              displayTypeFilter={displayTypeFilter}
              onChangeDisplayTypeFilter={this.onChangeDisplayTypeFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'bucket-group-types' ? (
            <BucketGroupType
              bucketGroupFilter={bucketGroupFilter}
              onChangeBucketGroupFilter={this.onChangeBucketGroupFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
            // <BucketSettings
            //   onHistoryUpdate={this.onHistoryUpdate}
            //   setContentId={this.setContentId}
            //   project={project}
            // />
          ) : null}
          {selectedDataId === 'bucket-settings' ? (
            <BucketSettings
              onHistoryUpdate={this.onHistoryUpdate}
              setContentId={this.setContentId}
              project={project}
            />
          ) : null}
          {selectedDataId === 'remote-folder' ? <RemoteFolder onHistoryUpdate={this.onHistoryUpdate} project={project} /> : null}
          {selectedDataId === 'aspect-ratio' ? <AspectRatios onHistoryUpdate={this.onHistoryUpdate} aspectRatioId={this.aspectRatioId} project={project} /> : null}
          {selectedDataId === 'channel-streams' ? <ChannelStreams onHistoryUpdate={this.onHistoryUpdate} /> : null}
          {selectedDataId === 'channel-config-groups' ? (
            <ChannelConfigGroups onHistoryUpdate={this.onHistoryUpdate} />
          ) : null}
          {selectedDataId === 'encoder-config' ? <EncoderConfig onHistoryUpdate={this.onHistoryUpdate} /> : null}
          {selectedDataId === 'image-types' ? <ImageTypeList onHistoryUpdate={this.onHistoryUpdate} ImageTypeList={imageTypeList} project={project} /> : null}
          {selectedDataId === 'media-category' ? <ImageCategoryList onHistoryUpdate={this.onHistoryUpdate} project={project} /> : null}
          {selectedDataId === 'publish-groups' ? (
            <PublishingGroups
              publishingGroupsFilter={publishingGroupsFilter}
              onChangePublishingGroupsFilter={this.onChangePublishingGroupsFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {/* {selectedDataId === 'apple-news' ? (
            <AppleNews
              publishingGroupsFilter={publishingGroupsFilter}
              onChangePublishingGroupsFilter={this.onChangePublishingGroupsFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
              appleClient={this.props.appleClient}
            />
          ) : null} */}
          {selectedDataId === 'epg' ? <Epg onHistoryUpdate={this.onHistoryUpdate} /> : null}
          {selectedDataId === 'drm-playback-settings' ? (
            <DrmPlaybackConfig onHistoryUpdate={this.onHistoryUpdate} />
          ) : null}
          {selectedDataId && selectedDataId.indexOf('-competiton-') > -1 ? (
            <MatchTemplate selectedId={selectedDataId} onHistoryUpdate={this.onHistoryUpdate} toggleSidebar={this.toggleSidebar} isSideBarVisible={isSideBarVisible} />
          ) : null}
          {selectedDataId === 'genre' ? (
            <GenreList
              genreFilter={genreFilter}
              onChangeGenreFilter={this.onChangeGenreFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'production-studio' ? (
            <ProductionList
              productionFilter={productionFilter}
              onChangeProductionFilter={this.onChangeProductionFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'roles' ? (
            <RoleList
              roleFilter={roleFilter}
              onChangeRoleFilter={this.onChangeRoleFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'persons' ? (
            <PersonsList
              personFilter={personFilter}
              onChangePersonFilter={this.onChangePersonFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'parental-rating-config' ? (
            <ParentRatingList
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'countries' ? (
            <CountryList
              countryFilter={countryFilter}
              onChangeCountryFilter={this.onChangeCountryFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}

          {selectedDataId === 'languages' ? (
            <LanguageList
              languageFilter={languageFilter}
              onChangeLanguageFilter={this.onChangeLanguageFilter}
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'offer-manager' ? (
            <OfferManagement
              project={project}
              onHistoryUpdate={this.onHistoryUpdate}
            />
          ) : null}
          {selectedDataId === 'partner-manager' ? (
            <PartnerManagement
              project={project}
              onHistoryUpdate={this.onHistoryUpdate}
            />
          ) : null}
          {selectedDataId === 'custom-meta-fields' && (project === 'hyperion' || project === 'projectx') ? (
            <CustomMetaFieldsList
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'custom-meta-fields' && project === 'vcms' ? (
            <CustomMetaFieldsListVcms
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
          {selectedDataId === 'match-manager' ? (
            <MatchMangerStatusManagement
              onHistoryUpdate={this.onHistoryUpdate}
              project={project}
            />
          ) : null}
        </Col>
        {(historyId && isSideBarVisible) ? <Col xl={{ span: 8 }} xs={{ span: 8 }} className='data-sidebar-container'>
          <DataHistory selectedDataId={historyId} isChanging={isadminHistory} toggleSidebar={this.toggleSidebar} contentId={contentId} />
        </Col> : ''}
      </Row>
    )
  }
}

DataDetails.propTypes = {}

export default DataDetails
