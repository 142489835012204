import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Select, message } from 'antd'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationUpdateSubscription from '../../../../graphQL/customers/updateSubsciption'
import { utilityService } from '../../../../services/UtilityService'

// import { utilityService } from '../../../../services/UtilityService'
const Option = Select.Option

const options = [ { name: 'Yes', id: true }, { name: 'No', id: false } ]
const parentCompoent = 'edit-subscription-container'

class EditSubscriptionModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isAuto: props.details ? props.details.isAuto : false,
      isLoading: false,
      isEdited: false
    }
  }

    UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.details && !_.isEqual(this.props.details, nextProps.details)) {
        this.setState({
          isAuto: nextProps.details.autoRenew
        })
      }
    }

    onOptionSelect = (value) => {
      this.setState({ isAuto: value, isEdited: true })
    }

    handleEditSubscriptions = () => {
      const { isAuto } = this.state
      const { onEditSubscription, email, details } = this.props
      this.setState({ isLoading: true })
      const subscriptionRequest = { email, update: { autoRenew: isAuto }, id: details.id }

      this.props.updateSubscription(subscriptionRequest).then(({ data }) => {
        if (!data.adminUpdateSubscription) {
          this.setState({ isLoading: false })
          onEditSubscription()
          message.error('Something went wrong, Please try again later')
          return
        }
        const { success, error } = data.adminUpdateSubscription
        if (success) {
          onEditSubscription()
          message.success(success.message)
          this.setState({ isLoading: false })
          this.props.fetchHistory()
        } else {
          message.error(error.message)
          this.setState({ isLoading: false })
        }
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false })
      })
      // this.setState({ isLoading: false })
    }

    resetState = () => {
      const state = {
        isAuto: false,
        isLoading: false,
        isEdited: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, onCancelEdit, details } = this.props
      const { isAuto, isLoading, isEdited } = this.state

      const optionComponents = options.map(option => (
        <Option key={option.id} value={option.id}> { option.name }
        </Option>
      ))
      const title = <div className='modal-title'>
        <p>EDIT SUBSCRIPTION </p>
        <p>{details ? details.id : null}</p>
      </div>
      return (
        <Modal
          className='general-modal edit-subscription-modal'
          title={title}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleEditSubscriptions}
          onCancel={onCancelEdit}
          okButtonProps={{ disabled: !isEdited || isLoading, loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='400px'
          confirmLoading={isLoading}
        >
          <div className='edit-subscription-container' id='edit-subscription-container'>
            <div className='input-field'>
              <label className='input-title'>Auto Renew Status</label>
              <Select
                value={isAuto ? 'Yes' : 'No'}
                getPopupContainer={() => document.getElementById(parentCompoent)}
                onChange={this.onOptionSelect}
                filterOption={false}
              >
                { optionComponents }
              </Select>
            </div>
          </div>
        </Modal>
      )
    }
}

EditSubscriptionModal.propTypes = {
  /** visible file status of CreateBucketDisplayTypesModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateBucketDisplayTypesModal. */
  handleCancel: PropTypes.func
}

EditSubscriptionModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationUpdateSubscription, {
        // options: {
        //     update: (cache, { data: { updateDisplayType } }) => {
        //         const variables = { filter: null, limit: 50 }
        //         const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketDisplayTypeList, variables }))
        //         if (cacheData && cacheData.listDisplayType && cacheData.listDisplayType.items) {
        //             const selectedAuthorIndex = cacheData.listDisplayType.items.findIndex(item => item.id === updateDisplayType.id)
        //             if (selectedAuthorIndex > -1) {
        //                 cacheData.listDisplayType.items[selectedAuthorIndex] = updateDisplayType
        //             }
        //         }
        //         cache.writeQuery({
        //             query: QueryBucketDisplayTypeList,
        //             data: cacheData,
        //             variables
        //         })
        //     }
        // },
        props: (props) => ({
          updateSubscription: (request) => {
            return props.mutate({
              variables: request
            })
          }
        })
      }
    )
  )(EditSubscriptionModal)
)
