import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import Input from '../inputs/Input'
import GeneralFieldComponent from '../../dataDisplay/GeneralFieldComponent'
import LoadingButton from '../../general/buttons/LoadingButton'
import './../../ui.style.scss'

import QueryListChannelStreams from '../../../../graphQL/admin/channel/listStreamLevels'
import MutationCreateChannelStream from '../../../../graphQL/admin/channel/createStreamLevel'
import MutationUpdateChannelStream from '../../../../graphQL/admin/channel/updateStreamLevel'
import { utilityService } from '../../../../services/UtilityService'

const listType = [
  {
    name: 'BOOLEAN',
    value: 'BOOLEAN'
  }, {
    name: 'STRING',
    value: 'STRING'
  }, {
    name: 'INTEGER',
    value: 'INTEGER'
  },
  {
    name: 'JSON',
    value: 'JSON'
  }
]

class CreateChannelStreamModal extends Component {
    state = {
      streamName: undefined,
      streamGroup: undefined,
      streamField: [],
      isLoading: false,
      enable: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedStream && (!_.isEqual(this.props.selectedStream, nextProps.selectedStream) || (!this.state.streamName && !this.state.streamGroup))) {
        this.setState({
          streamName: nextProps.selectedStream.name,
          streamGroup: nextProps.selectedStream.group,
          streamField: nextProps.selectedStream && nextProps.selectedStream.fields && nextProps.selectedStream.fields.length ? nextProps.selectedStream.fields.map((item, index) => ({ ...item, id: index })) : []
        })
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ streamName: value }, this.isEnableSave)
        }
        if (name === 'group') {
          this.setState({ streamGroup: value }, this.isEnableSave)
        }
      }
    }

    onOptionSelect = (value, index) => {
      const { streamField } = this.state
      streamField[index].type = value
      this.setState({ streamField }, this.isEnableSave)
    }

    handleFieldChange =(e, index) => {
      const { streamField } = this.state
      const { value } = e.target
      if (!value.startsWith(' ')) {
        streamField[index].name = value
        this.setState({ streamField }, this.isEnableSave)
      }
    }

    onChangeStatus = (e, index) => {
      const { streamField } = this.state
      const { checked } = e.target
      streamField[index].isRequired = checked
      this.setState({ streamField }, this.isEnableSave)
    }

    handleAddChannelStream = () => {
      const { streamName, streamGroup, streamField } = this.state
      const { selectedStream, isDuplicate, onHistoryUpdate } = this.props
      const invalidStreams = (streamField || []).filter(item => !item.name || !item.type)
      if (invalidStreams && invalidStreams.length) {
        message.error('Field objects should be filled with valid values')
        return
      }
      this.setState({ isLoading: true })
      if (selectedStream && !isDuplicate) {
        const newStream = {
          id: selectedStream.id,
          version: selectedStream.version ? parseInt(selectedStream.version) + 1 : 1,
          name: streamName,
          group: streamGroup,
          fields: (streamField || []).map(item => ({ name: item.name, isRequired: item.isRequired, type: item.type }))
        }
        this.props.updateStreamLevel(newStream).then(response => {
          this.props.handleSubmit(response.data.updateStreamLevel)
          onHistoryUpdate()
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newStream = {
          name: streamName,
          version: 1,
          group: streamGroup,
          fields: (streamField || []).map(item => ({ name: item.name, isRequired: item.isRequired, type: item.type }))
        }
        this.props.createStreamLevel(newStream).then(response => {
          this.props.handleSubmit(response.data.createStreamLevel)
          onHistoryUpdate()
        }, (error) => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
          const { graphQLErrors } = error
          if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].message === 'Key Already Exists') {
            message.warning('Stream Already Exist')
          }
        })
      }
    }

    reorder = (startIndex, endIndex) => {
      const { streamField } = this.state
      const result = Array.from(streamField)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      this.setState({ streamField: result }, this.isEnableSave)
    }

    onCloseField = (index) => {
      const { streamField } = this.state
      // const index = streamField.findIndex(item => item.id === id)
      streamField.splice(index, 1)
      this.setState({ streamField }, this.isEnableSave)
    }

    addFieldObjects = () => {
      const { streamField } = this.state
      const newFieldObject = {
        name: '',
        type: '',
        isRequired: false,
        isEnable: true
      }
      streamField.push(newFieldObject)
      this.setState({ streamField })
    }

    isEnableSave = () => {
      this.setState({ enable: true })
    }

    resetState = () => {
      const state = {
        streamName: '',
        streamGroup: '',
        streamField: [],
        isLoading: false,
        enable: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, isDuplicate, selectedStream } = this.props
      const { streamName, streamGroup, streamField, isLoading, enable } = this.state
      const disabled = enable && streamName && streamGroup

      return (
        <Modal
          className='general-modal create-channel-stream-modal'
          title={selectedStream ? isDuplicate ? 'DUPLICATE CHANNEL STREAM' : 'EDIT CHANNEL STREAM' : 'NEW CHANNEL STREAM'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddChannelStream}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !disabled, loading: isLoading }}
          // okButtonProps={{ loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-stream'>
            <div className='create-input'>
              <Input title='Channel Stream Name' isRequired inputName='name' handleChange={this.handleChange} value={streamName} placeholder='Insert Channel Stream Name' />
              <Input title='Group' inputName='group' isRequired handleChange={this.handleChange} value={streamGroup} placeholder='Insert Group' />
            </div>
            <GeneralFieldComponent
              reorder={this.reorder}
              onCloseField={this.onCloseField}
              onOptionSelect={this.onOptionSelect}
              onChangeStatus={this.onChangeStatus}
              handleChange={this.handleFieldChange}
              streamField={streamField}
              isEnableSave={this.isEnableSave}
              columnTitle1={'Field Name'}
              columnTitle2={'Field Type'}
              columnTitle3={'Required'}
              type={'channel-steam'}
              listType={listType}
              // isFromEdit={selectedStream}
            />
            <LoadingButton
              type='primary'
              onClick={this.addFieldObjects}
              htmlType='submit'
              buttonText={'Add Field Objects'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={!streamName && !streamGroup}
            />
          </div>
        </Modal>
      )
    }
}

CreateChannelStreamModal.propTypes = {
  /** visible file status of CreateChannelStreamModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateChannelStreamModal. */
  handleCancel: PropTypes.func
}

CreateChannelStreamModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateChannelStream,
    {
      options: {
        update: (cache, { data: { createStreamLevel } }) => {
          const variables = { filter: null, limit: 20 }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListChannelStreams, variables }))
          if (cacheData && cacheData.listStreamLevels && cacheData.listStreamLevels.items) {
            cacheData.listStreamLevels.items.unshift(createStreamLevel)
          }
          cache.writeQuery({
            query: QueryListChannelStreams,
            data: cacheData,
            variables
          })
        }
      },
      props: (props) => ({
        createStreamLevel: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateChannelStream, {
      options: {
        // update: (cache, { data: { updateStreamLevel } }) => {
        //   const variables = { filter: null, limit: 20 }
        //   const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListChannelStreams, variables }))
        //   if (cacheData && cacheData.listStreamLevels && cacheData.listStreamLevels.items) {
        //     const selectedIndex = cacheData.listStreamLevels.items.findIndex(item => item.id === updateStreamLevel.id)
        //     if (selectedIndex > -1) {
        //       cacheData.listStreamLevels.items[selectedIndex] = updateStreamLevel
        //     }
        //   }
        //   cache.writeQuery({
        //     query: QueryListChannelStreams,
        //     data: cacheData,
        //     variables
        //   })
        // }
      },
      props: (props) => ({
        updateStreamLevel: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateChannelStreamModal))
