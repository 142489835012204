import gql from 'graphql-tag'

export default gql(`
    mutation updateTeam( $id: ID! $customName: String  $trigramme: String $customOfficialName: String $customFounded: String $customCountry: String 
        $customShortName: String $customStatus: String $customCity: String $customAddressZip: String $customPostalAddress: String $tags: [ID]) {
        updateTeam(
            input: {
                id: $id
                tags: $tags
                customName: $customName
                trigramme: $trigramme
                customOfficialName: $customOfficialName
                customFounded: $customFounded
                customCountry: $customCountry
                customShortName: $customShortName
                customStatus: $customStatus
                customCity: $customCity
                customAddressZip: $customAddressZip
                customPostalAddress: $customPostalAddress
            }
        ) {
            id
            externalId
            opId
            title
            code
            city
            country
            officialName
            status
            founded
            shortName
            postalAddress
            addressZip
            customName
            trigramme
            customOfficialName
            customFounded
            customCountry
            customShortName
            customStatus
            customCity
            customAddressZip
            customPostalAddress
            tags{
                key
                name
                type
            }
        }
    }`
)
