import gql from 'graphql-tag'

export default gql(`
query {
    listOptaSyncStates{
        items{
            id
            opId
            title
            lastRunTime
            frequency
            isRunning
            isActive
            isError
        }
    }
}`)
