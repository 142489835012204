import React, { Component } from 'react'
import AppContext from '../../../../AppContext'
import { Popover, DatePicker, Popconfirm, Skeleton, InputNumber, Empty, message } from 'antd'
import { GlobalHotKeys } from 'react-hotkeys'

import moment from 'moment'
import PropTypes from 'prop-types'
import FilterIcon from '../../general/icons/FilterIcon'
import FilterTagInput from './FilterTagInput'
import DropDown from './DropDown'
import Input from './Input'
import TabPanel from '../../dataDisplay/TabPanel'
import CrossIcon from '../../general/icons/CrossIcon'
import SavedFilterInput from './SavedFilterInput'
import Tooltip from '../../dataDisplay/ToolTip'
import AddButton from '../../general/buttons/AddButton'
import SaveButton from '../../general/buttons/SaveButton'
import QueryGetSavedFilter from '../../../../graphQL/filter/getSavedFilter'
import MutationDeleteFilter from '../../../../graphQL/filter/deleteFilter'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { utilityService } from '../../../../services/UtilityService'
import MultiInput from './MultiInput'

const keyMap = {
  shortcutFIlter: 'f'
}

const modulePermission = {
  AssetManager: 'ASSET_MANAGER',
  ChannelManager: 'CHANNEL_MANAGER',
  ContentBank: 'CONTENT_BANK',
  BucketAssetManager: 'BUCKET_MANAGER',
  BucketManager: 'BUCKET_MANAGER',
  AuditBucketManager: 'BUCKET_MANAGER',
  MatchManager: 'MATCH_MANAGER',
  JobManager: 'JOB_MANAGER',
  Tag: 'DATA_MANAGER',
  AppManager: 'APP_MANAGER',
  RoleManager: 'ROLE_MANAGER'
}

const detailsTabs = [
  {
    displayName: 'All Filters',
    mode: 'allFilter'
  }, {
    displayName: 'My Filters',
    mode: 'myFilter'
  }
]

const { RangePicker } = DatePicker

const booleanOption = [
  {
    name: 'True',
    value: 'true'
  },
  {
    name: 'False',
    value: 'false'
  },
  {
    name: 'Both',
    value: 'both'
  }
]
const archivedBooleanOption = [
  {
    name: 'True',
    value: 'true'
  },
  {
    name: 'False',
    value: 'false'
  }
]
const sourceBooleanOption = [
  {
    name: 'Yes',
    value: 'true'
  },
  {
    name: 'No',
    value: 'false'
  }
]

const sortOrderOptions = [
  {
    name: 'Desc',
    id: 'desc'
  }, {
    name: 'Asc',
    id: 'asc'
  }
]

let tempRangePickerDate = null

export class FilterContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisible: false,
      mode: 'allFilter',
      savedFilter: this.props.savedFilter && this.props.savedFilter.length ? _.cloneDeep(this.props.savedFilter) : [],
      filterData: (this.props.filterData && this.props.filterData.length ? _.cloneDeep(this.props.filterData).filter((item) => (item.isRequired)) : []),
      selectedId: '',
      selectedIndex: undefined,
      searchText: '',
      tagSearchText: '',
      isCreated: false,
      isCreateFilter: false,
      editingFilterId: '',
      newFilter: undefined,
      deletedId: '',
      fullFilterData: [],
      optionalFilterData: (this.props.filterData && this.props.filterData.length ? _.cloneDeep(this.props.filterData).filter((item) => (!item.isRequired && item.value)) : []),
      optionalFilterDataTemp: (this.props.filterData && this.props.filterData.length ? _.cloneDeep(this.props.filterData).filter((item) => (!item.isRequired && item.value)) : []),
      isVisibleAddFilter: false,
      addFilterFields: (this.props.filterData && this.props.filterData.length ? _.cloneDeep(this.props.filterData).filter((item) => (!item.isRequired && !item.value)) : []),
      addFilterFieldsTemp: (this.props.filterData && this.props.filterData.length ? _.cloneDeep(this.props.filterData).filter((item) => (!item.isRequired && !item.value)) : []),
      isFilterSaveBtn: true,
      isNewFilterDisabled: false,
      isAddFilterbtn: false,
      filterModeClear: [],
      savedFilterSearchString: this.props.savedFilterSearchString
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(this.props.filterData, nextProps.filterData)) {
      let optionalFilterData = _.cloneDeep(nextProps.filterData).filter((item) => (!item.isRequired && item.value))
      if (this.state.optionalFilterDataTemp.length) {
        optionalFilterData = this.state.optionalFilterDataTemp.map((item) => {
          const tempIndex = optionalFilterData.findIndex((innerItem) => innerItem.name === item.name)
          if (tempIndex > -1) {
            item.value = optionalFilterData[tempIndex].value
          } else {
            item.value = null
          }
          return item
        })
      }
      this.setState({
        filterData: _.cloneDeep(nextProps.filterData).filter((item) => (item.isRequired)),
        addFilterFields: _.cloneDeep(nextProps.filterData).filter((item) => {
          const tempIndex = optionalFilterData.findIndex(option => option.name === item.name)
          return (!item.isRequired && !item.value && tempIndex === -1)
        }),
        fullFilterData: _.cloneDeep(nextProps.filterData),
        optionalFilterData
      })
      if (!this.state.addFilterFieldsTemp.length) { this.setState({ addFilterFieldsTemp: _.cloneDeep(nextProps.filterData).filter((item) => (!item.isRequired && !item.value)) }) }
      if (!this.state.optionalFilterDataTemp.length) { this.setState({ optionalFilterDataTemp: _.cloneDeep(nextProps.filterData).filter((item) => (!item.isRequired && item.value)) }) }
    }
    if (this.state.isCreated && !_.isEqual(nextProps.savedFilter, this.state.savedFilter)) {
      this.setState({ selectedId: nextProps.savedFilter[0].id, isCreated: false, newFilter: undefined, isCreateFilter: false })
    }
    if (this.state.isCreated && this.props.isCreateFilter && !nextProps.isCreateFilter) {
      this.setState({ isCreateFilter: false, newFilter: undefined, isCreated: false })
    }
    if (!_.isEqual(nextProps.savedFilter, this.props.savedFilter)) { this.setState({ savedFilter: _.cloneDeep(nextProps.savedFilter) }) }

    if (nextProps.isClearFilter) { this.replaceFilterData(nextProps.filterData.filter((item) => (item.isRequired))) }

    this.setState({
      savedFilterSearchString: nextProps.savedFilterSearchString
    })
  }

  handleModeChange = mode => {
    // let mode = e.target.value
    const { savedFilter, selectedId } = this.state
    this.props.savedFiltermodeHandler(mode)

    let filterModeClear = savedFilter.filter(item => {
      if (selectedId === item.id) {
        return item
      }
    })
    this.setState({ mode, filterModeClear })

    // if (selectedId) {
    //   this.clearFilter()
    // }
  }

  handleClickOutside = (event) => {
    if (!(this.newFilterContainer && this.newFilterContainer.contains(event.target))) {
      this.setState({ isVisibleAddFilter: false })
    }

    if ((this.filterContainer && this.filterContainer.contains(event.target)) || (this.filterHeaderContainer && this.filterHeaderContainer.contains(event.target)) || (this.newFilterContainer && this.newFilterContainer.contains(event.target))) {
      return
    }

    if (this.state.isVisibleAddFilter) {
      this.setState({ isVisibleAddFilter: false })
    }

    if (this.state.isVisible) {
      setTimeout(() => this.setState({ isVisible: false }), 200)
    }
    this.setState({ isVisibleAddFilter: false })
  }

  addFilterBtnHandler = () => {
    this.setState({
      isVisibleAddFilter: !this.state.isVisibleAddFilter
    })
  }

  replaceFilterData = (filterData) => {
    this.setState({ filterData, selectedId: '', optionalFilterData: [] })
    this.clearFilter()
  }

  handleChange = (value, index) => {
    let filterData = _.cloneDeep(this.state.filterData)
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    if (value && value.length > 0) {
      filterData[index].value = value
      this.setState({ filterData })
      this.props.onChangeFilter(filterData, this.state.selectedId)
    } else {
      filterData[index].value = null
      this.setState({ filterData })
      this.props.onChangeFilter(filterData, this.state.selectedId)
    }
  }

  onChangeText = (e, index, optional) => {
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    let filterData = _.cloneDeep(this.state.filterData)
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    if (optional) {
      optionalFilterData[index].value = e.target.value
      this.setState({ optionalFilterData })
    } else {
      filterData[index].value = e.target.value
      this.setState({ filterData })
    }

    if (e.target.value === '') {
      filterData[index].value = null
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    } else {
      let combained = [...filterData, ...optionalFilterData]
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    }
  }

  // onChangeText = (e, index) => {
  //   let filterData = _.cloneDeep(this.state.filterData)
  //   filterData[index].value = e.target.value
  //   this.setState({ filterData })
  //   if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
  // }

  onChangeTag = (index, data, isDelete) => {
    let filterData = _.cloneDeep(this.state.filterData)
    let value = _.cloneDeep(filterData[index].value)
    if (isDelete) {
      const deleteIndex = value.findIndex((item) => item.key === data.key)
      value.splice(deleteIndex, 1)
    } else {
      if (value) { value.push(data) } else { value = [data] }
    }
    filterData[index].value = value && value.length ? value : null
    this.setState({ filterData })

    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }

    let combained = [...filterData, ...this.state.optionalFilterData]
    if (this.state.optionalFilterData.length) {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    } else {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    }
  }

  onSelectChange = (index, data, isDelete) => {
    let filterData = _.cloneDeep(this.state.filterData)
    let value = _.cloneDeep(filterData[index].value)
    if (isDelete) {
      const deleteIndex = value.findIndex((item) => item.key === data.key)
      value.splice(deleteIndex, 1)
    } else {
      value = [data]
    }
    filterData[index].value = value && value.length ? value : null
    this.setState({ filterData })
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    let combained = [...filterData, ...this.state.optionalFilterData]
    if (this.state.optionalFilterData.length) {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    } else {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    }
  }

  onChangeAppTag = (index, data, isDelete) => {
    let filterData = _.cloneDeep(this.state.filterData)
    let value = _.cloneDeep(filterData[index].value)
    if (isDelete) {
      const deleteIndex = value.findIndex((item) => item.id === data.id)
      value.splice(deleteIndex, 1)
    } else {
      if (value) { value.push(data) } else { value = [data] }
    }
    filterData[index].value = value && value.length ? value : null
    this.setState({ filterData })

    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }

    let combained = [...filterData, ...this.state.optionalFilterData]
    if (this.state.optionalFilterData.length) {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    } else {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    }
  }

  onChangeTagOptional = (index, data, isDelete) => {
    let filterData = _.cloneDeep(this.state.filterData)
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    let value = _.cloneDeep(optionalFilterData[index].value)
    if (isDelete) {
      const deleteIndex = value.findIndex((item) => item.key === data.key)
      value.splice(deleteIndex, 1)
    } else {
      if (value) { value.push(data) } else { value = [data] }
    }
    optionalFilterData[index].value = value && value.length ? value : null
    this.setState({ optionalFilterData })

    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }

    let combained = [...filterData, ...optionalFilterData]
    if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }

    // if(this.state.optionalFilterData.length) {
    //  if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    // } else {
    //  if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    // }
  }

  updateFilter = () => {
    if (this.state.selectedId) {
      this.setState({ isFilterSaveBtn: true })
      const { savedFilter, filterData, optionalFilterData } = this.state
      const combain = [...filterData, ...optionalFilterData]
      let updateFilter = savedFilter.find(filter => filter.id === this.state.selectedId)
      updateFilter.fields = combain
      if (this.props.updateFilter) { this.props.updateFilter(updateFilter) }
    }
  }

  onClickSavedFilterHandler = (id) => {
    if (id !== this.state.selectedId) {
      const savedFilter = _.cloneDeep(this.props.savedFilter)
      const selectedFilter = savedFilter.find(filter => filter.id === id)
      const RequiredFields = _.cloneDeep(this.props.filterData).filter((item) => (item.isRequired))
      const fieldWithData = []
      const addFilterFields = _.cloneDeep(this.props.filterData).filter((item) => !(item.isRequired))

      if (selectedFilter) {
        const filterData = (selectedFilter.fields || []).map((item) => {
          if (item.type === 'KEYWORD' || item.type === 'SELECT') {
            let value = typeof (item.value) === 'string' ? item.value.split(',') : []
            let nameArr = typeof (item.displayValue) === 'string' ? item.displayValue.split(',') : []
            value = value.map((valueItem, index) => {
              return {
                key: valueItem,
                name: nameArr[index]
              }
            })
            item.value = value && value.length ? value : null
            return item
          } else return item
        })

        this.setState({ filterData, selectedId: id, isFilterSaveBtn: true })
        this.props.onChangeFilter(filterData, id)
      }

      (selectedFilter.fields || []).map(item => {
        RequiredFields.map(item2 => {
          if (item.name === item2.name) {
            fieldWithData.push(item)
          }
        })
      })

      const extraFields = (selectedFilter.fields).splice((fieldWithData.length), (selectedFilter.fields).length - fieldWithData.length)
      const remaingingFields = addFilterFields.filter((addFilterField) => extraFields.findIndex(extraField => extraField.name === addFilterField.name) === -1)

      this.setState({
        filterData: fieldWithData,
        addFilterFields: remaingingFields,
        optionalFilterData: extraFields
      })
    }
  }

  onRemoveFilter = (selectedId) => {
    if (selectedId) {
      this.setState({ selectedId: '' })
      this.props.deleteFilter(selectedId).then(data => {
        this.setState({ deletedId: selectedId })
        this.clearFilter()
      }, error => {
        utilityService.handleError(error)
      })
    }
  }

  clickFilter = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (this.props.readOnly) { return }
    if (this.props.isDisabled) { return }
    if (this.props.filterType) {
      this.setState({
        isVisible: !this.state.isVisible
      })
    }
    if (this.props.isFilterOpen) { this.props.isFilterOpen(!this.state.isVisible) }
  }

  onVisibleChange = (value) => {
    if (this.props.isFilterOpen) { this.props.isFilterOpen(value) }
  }

  clearFilter = () => {
    const { disabledField } = this.props
    let { filterData, optionalFilterData } = this.state
    filterData = filterData.map((item) => {
      if (item.type === 'LIMIT') {
        item.value = 10
        return item
      }
      if (item.type === 'SORT') {
        return item
      }
      if (disabledField && disabledField.length && disabledField.includes(item.name)) {
        return item
      }
      item.value = null
      return item
    })
    optionalFilterData = optionalFilterData.map((item) => {
      if (item.type === 'SORT') { item.fieldName = null }
      item.value = null
      return item
    })
    this.state.optionalFilterDataTemp = []
    this.setState({ optionalFilterData })
    this.setState({ filterData, optionalFilterData: [], optionalFilterDataTemp: [], selectedId: '', isFilterSaveBtn: true, addFilterFields: _.cloneDeep(this.props.filterData).filter((item) => (!item.isRequired)) }, this.props.onChangeFilter(this.state.filterData, this.state.selectedId))
  }

  onTagSearch = (index, value) => {
    this.setState({
      tagSearchText: value,
      selectedIndex: value ? index : undefined
    })
  }

  createFilterInit = () => {
    const newFilter = {
      name: undefined,
      filter: this.state.filterData
    }
    this.setState({ isCreateFilter: true, newFilter })
  }

  changeFilterName = (e, id, isCreatingFilter) => {
    let filterName = e.target.value
    filterName = filterName.startsWith(' ') ? '' : filterName
    if (isCreatingFilter) {
      let { newFilter } = this.state
      newFilter.name = filterName
      this.setState({ newFilter })
    } else {
      const index = this.state.savedFilter.findIndex(filter => filter.id === id)

      if (index > -1) {
        this.state.savedFilter[index].name = filterName
        this.setState({ savedFilter: this.state.savedFilter })
      }
    }
  }

  optionalDeleteHandler = (e, index) => {
    const { addFilterFieldsTemp, optionalFilterDataTemp } = this.state
    let optionalFilterData = this.state.optionalFilterData
    let addFilterFields = this.state.addFilterFields
    addFilterFields.push(optionalFilterData[index])
    // addFilterFields.splice(tempIndex, 0, optionalFilterData[index])
    const tempIndex = optionalFilterDataTemp.findIndex((item) => item.name === optionalFilterData[index].name)
    optionalFilterData[index].value = null
    optionalFilterData.splice(index, 1)
    optionalFilterDataTemp.splice(tempIndex, 1)
    const tempAddArr = addFilterFieldsTemp.filter((item) => addFilterFields.findIndex((innerItem) => innerItem.name === item.name) > -1)
    this.setState({ optionalFilterData, addFilterFields: tempAddArr, optionalFilterDataTemp })

    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }

    let combained = [...this.state.filterData, ...this.state.optionalFilterData]
    if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
  }

  getFilterDetails = (filterData) => {
    let optionalFilterData = this.state.optionalFilterData
    filterData = [...filterData, ...optionalFilterData]
    let filter = (filterData || []).filter((item) => (item.type === 'SORT' && item.fieldName) || (item.value !== null))
    return filter.map((filterItem) => {
      let value
      if (filterItem.type === 'KEYWORD' || filterItem.type === 'SELECT') { value = this.changeFilterData(filterItem.value) }
      if (filterItem.type === 'STRING') { value = this.getChangeTextData(filterItem) }
      if (filterItem.type === 'SORT') { value = this.getSortedData(filterItem) }
      if (filterItem.type === 'BOOLEAN' || filterItem.type === 'LIMIT') { value = filterItem.value }
      if (filterItem.type === 'INPUT') { value = filterItem.value }
      if (filterItem.type === 'MULTIPLE') { value = filterItem.value.length && filterItem.value.join(',') }
      return `${filterItem.displayName}: ${typeof (filterItem.value) === 'string' && filterItem.value && filterItem.value.includes(',') && filterItem.type !== 'INPUT'
        ? (filterItem.type === 'DATE' ? this.formattingDate(filterItem.value) : (filterItem.value.length && filterItem.value.join(','))) : value}`
    })
  }

  formattingDate = (value) => {
    let dates = []
    value.split(',').map((item, index) => {
      dates[index] = item.substr(0, 10)
    })
    var day = new Date(dates[0])
    var aDay = 1440 * 60 * 1000
    var d2 = new Date(Math.trunc((day.getTime() + aDay) / aDay) * aDay)
    var currDate = d2.getDate().toString()
    if (currDate.length === 1) {
      currDate = '0' + currDate
    }
    var currMonth = (d2.getMonth() + 1).toString()
    if (currMonth.length === 1) {
      currMonth = '0' + currMonth
    }
    var currYear = d2.getFullYear()
    var startDate = currYear + '-' + currMonth + '-' + currDate
    return (`${startDate} to ${dates[1]}`)
  }

  changeFilterData = (filterVal) => {
    let value = (filterVal || []).map((innerItem) => innerItem.name).join(', ')
    value = value && value.length ? value : null
    return value
  }

  getChangeTextData = (filterItem) => {
    let value = filterItem.value
    return value
  }

  getSortedData = (sortData) => {
    const { sortOptionList } = this.props
    let sortOption = sortData.fieldName
    if (sortOptionList) {
      const temp = sortOptionList.find(item => item.name === sortData.fieldName)
      if (temp) { sortOption = temp.displayName }
    }
    return sortData.value ? `${sortOption}(${sortData.value})` : sortOption
  }

  createFilter = () => {
    const { newFilter } = this.state
    if (newFilter.name) {
      this.onChangeTextSavedFilter({ target: { value: '' } })
      this.setState({ isCreated: true })
      if (this.props.createFilter) { this.props.createFilter(this.state.newFilter) }
    } else {
      this.setState({ newFilter: undefined, isCreateFilter: false })
    }
  }

  onChangeCheckData = (index, checked) => {
    let filterData = _.cloneDeep(this.state.filterData)
    filterData[index].value = checked
    this.setState({ filterData })

    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }

    let combained = [...filterData, ...this.state.optionalFilterData]

    if (this.state.optionalFilterData.length) {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    } else {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    }
  }

  onChangeCheckDataOptional = (index, checked) => {
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    optionalFilterData[index].value = checked
    this.setState({ optionalFilterData })

    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }

    let combained = [...this.state.filterData, ...optionalFilterData]

    if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
  }

  enableFilterNameEdit = id => {
    this.setState({ editingFilterId: id })
  }

  onChangeSortOrder = (index, value) => {
    let filterData = _.cloneDeep(this.state.filterData)
    filterData[index].value = value || null
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    filterData = [...filterData, ...optionalFilterData]
    this.setState({ filterData })
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
  }

  onChangeSort = (index, value) => {
    let filterData = _.cloneDeep(this.state.filterData)
    filterData[index].fieldName = value || null
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    filterData = [...filterData, ...optionalFilterData]
    this.setState({ filterData })
    if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
  }

  onChangeLimit = (index, value) => {
    let filterData = _.cloneDeep(this.state.filterData)
    const numberRegex = /^[0-9]*$/
    filterData[index].value = numberRegex.test(value) ? value : null
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    filterData = [...filterData, ...optionalFilterData]
    this.setState({ filterData })
    if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
  }

  disableFilterNameEdit = () => {
    if (this.state.editingFilterId) {
      const { savedFilter, editingFilterId } = this.state
      const currentFilter = _.find(savedFilter, (item) => item.id === editingFilterId)
      if (currentFilter.name) {
        const nameUniqArr = (savedFilter || []).filter(item => item.name === currentFilter.name)
        if (nameUniqArr.length > 1) {
          const newSavedFilter = _.cloneDeep(this.props.savedFilter)
          const item = _.find(newSavedFilter, filter => filter.id === editingFilterId)
          const index = _.findIndex(savedFilter, filter => filter.id === editingFilterId)
          savedFilter[index].name = item.name
          this.setState({ savedFilter, editingFilterId: undefined })
          message.warn('Duplicate filter name')
          return
        } else {
          this.updateFilter()
        }
      } else {
        const newSavedFilter = _.cloneDeep(this.props.savedFilter)
        const item = _.find(newSavedFilter, filter => filter.id === editingFilterId)
        const index = _.findIndex(newSavedFilter, filter => filter.id === editingFilterId)

        if (!_.isEmpty(item)) {
          newSavedFilter[index] = item
          this.setState({ savedFilter: newSavedFilter })
        }
      }
    }
    this.setState({ editingFilterId: undefined })
  }

  onChangeDate = (index, date, dateString) => {
    let filterData = _.cloneDeep(this.state.filterData)
    let arrayVal = ''
    dateString.filter((item) => item).map((time, index) => {
      const date = moment(time + ' 00:00:00')
      const startTime = _.cloneDeep(date).startOf('day')
      const endTime = _.cloneDeep(date).endOf('day')
      const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
      arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
    })
    filterData[index].value = arrayVal && arrayVal.length ? arrayVal : null

    this.setState({ filterData })
    if (this.state.selectedId) { this.setState({ isFilterSaveBtn: false }) }
    let combained = [...filterData, ...this.state.optionalFilterData]
    if (this.state.optionalFilterData.length) {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    } else {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    }
  }

  onOpenChange = (index, isOpen) => {
    let filterData = _.cloneDeep(this.state.filterData)
    const tempValue = filterData[index].value
    if (isOpen && tempValue) {
      filterData[index].value = null
      this.setState({ filterData })
      tempRangePickerDate = {
        index,
        value: tempValue
      }
    }
    if (!isOpen && !tempValue && tempRangePickerDate && tempRangePickerDate) {
      filterData[index].value = tempRangePickerDate.value
      tempRangePickerDate = null
      this.setState({ filterData })
    }
  }

  onOpenChangeOptional = (index, isOpen) => {
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    const tempValue = optionalFilterData[index].value
    if (isOpen && tempValue) {
      optionalFilterData[index].value = null
      this.setState({ optionalFilterData })
      tempRangePickerDate = {
        index,
        value: tempValue
      }
    }
    if (!isOpen && !tempValue && tempRangePickerDate && tempRangePickerDate) {
      optionalFilterData[index].value = tempRangePickerDate.value
      tempRangePickerDate = null
      this.setState({ optionalFilterData })
    }
  }

  onChangeDateOptional = (index, date, dateString) => {
    let optionalFilterData = _.cloneDeep(this.state.optionalFilterData)
    let filterData = _.cloneDeep(this.state.filterData)
    let arrayVal = ''
    dateString.filter((item) => item).map((time, index) => {
      const date = moment(time + ' 00:00:00')
      const startTime = _.cloneDeep(date).startOf('day')
      const endTime = _.cloneDeep(date).endOf('day')
      const formattedTime = index ? endTime.utc().format() : startTime.utc().format()
      arrayVal = !index ? formattedTime : arrayVal + ',' + formattedTime
    })
    optionalFilterData[index].value = arrayVal && arrayVal.length ? arrayVal : null
    this.setState({ optionalFilterData })

    let combained = [...filterData, ...optionalFilterData]
    if (this.state.optionalFilterData[index].value !== '') {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(combained, this.state.selectedId) }
    } else {
      if (this.props.onChangeFilter) { this.props.onChangeFilter(filterData, this.state.selectedId) }
    }
  }

  closeFilter = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.onVisibleChange(false)
    this.setState({ isVisible: false })
  }

  renderTitle = () => {
    return <div className='filter-header' ref={node => { this.filterHeaderContainer = node }}>
      <span className='header-text'>FILTER</span>
      <CrossIcon onClick={this.closeFilter} />
    </div>
  }

  addFilterHandler = (e, index) => {
    let optionalFilterData = this.state.optionalFilterData
    let optionalFilterDataTemp = this.state.optionalFilterDataTemp
    let addFilterFields = _.cloneDeep(this.state.addFilterFields)
    optionalFilterData.push(addFilterFields[index])
    optionalFilterDataTemp.push(addFilterFields[index])
    addFilterFields.splice(index, 1)

    this.setState({
      optionalFilterData,
      addFilterFields,
      isVisibleAddFilter: !this.state.isVisibleAddFilter,
      optionalFilterDataTemp
    })

    var FilterFields = document.getElementById('filter-side-panel-fields')
    setTimeout(() => {
      FilterFields.scrollTop = FilterFields.scrollHeight
    }, 50)
  }

  renderNewFilterContent = () => {
    const addFilterFields = this.state.addFilterFields
    return <div className='filter-side-add-list' ref={node => { this.newFilterContainer = node }}>
      {
        (addFilterFields || []).map((item, index) => (
          <p key={index} onClick={(e) => this.addFilterHandler(e, index)}>{item.displayName}</p>
        ))
      }
    </div>
  }

  onChangeTextSavedFilter = (e) => {
    let searchValue = e.target.value
    this.setState({ selectedId: '' }, () => {
      this.props.savedFilterSearch(searchValue)
    })
  }

  renderContent = () => {
    const { isLoading, sortOptionList, filterType, disabledField, className, appClient } = this.props
    const randomId = '-' + Math.random().toString(36).substr(2, 9)
    const randomIdOptional = '-' + Math.random().toString(36).substr(9)
    const { isCreateFilter, mode } = this.state
    const newFilterContent = this.renderNewFilterContent()
    let { savedFilter } = this.state
    savedFilter = _.uniqBy(savedFilter, 'id')

    let isDeleteDisabled = false; let isUpdateDisabled = false; let isCreateDisabled = false
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          if (filterType) {
            const userPermissions = permissions[modulePermission[filterType]]
            isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            isUpdateDisabled = filterType !== 'AppManager' ? userPermissions.indexOf('UPDATE') === -1 : false
            isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          }
          return <div className={`filter-content ${className || ''}`} ref={node => { this.filterContainer = node }}>
            <div className='filter-content__left'>
              <div className='saved-filter-container'>
                <div className='saved-filter-header'>
                  <TabPanel tabs={detailsTabs} selectedMode={mode} onModeChange={this.handleModeChange} />
                </div>
                <div className='saved-filter'>
                  <div className='saved-filter-body'>
                    <Skeleton active loading={isLoading}>
                      <div className='saved-filter-name-container' id='saved-filter-name'>
                        <div className='saved-filter-search'>
                          <Input
                            placeholder='Search'
                            handleChange={(e) => this.onChangeTextSavedFilter(e)}
                            inputName='filterInputItem'
                            value={this.props.savedFilterSearchString}
                            title={''} />
                        </div>
                        {
                          this.state.isCreateFilter ? <div className='filter-name is-edit'>
                            <SavedFilterInput
                              value={this.state.newFilter.name}
                              onChange={(e) => this.changeFilterName(e, this.state.newFilter.id, true)}
                              onBlur={() => this.createFilter(this.state.newFilter.name)}
                              autoFocus
                              enableEnter
                              onClick={() => { }}
                            />
                          </div> : null
                        }
                        {(mode === 'allFilter' || mode === 'myFilter')
                          ? ((savedFilter && savedFilter.length) || isCreateFilter) ? (savedFilter || []).filter((savedItem) => savedItem.id !== this.state.deletedId).map((singleSavedItem, index) => {
                            return <div
                              key={index}
                              className={`filter-name${singleSavedItem.id !== this.state.editingFilterId ? '' : ' is-edit'}${this.state.selectedId === singleSavedItem.id ? ' selected' : ''}`}
                              onDoubleClick={isUpdateDisabled ? () => { } : () => this.enableFilterNameEdit(singleSavedItem.id)}
                            >
                              <SavedFilterInput
                                onBlur={this.disableFilterNameEdit}
                                value={singleSavedItem.name}
                                onClick={() => this.onClickSavedFilterHandler(singleSavedItem.id)}
                                enableEnter
                                onChange={(e) => this.changeFilterName(e, singleSavedItem.id)}
                                readOnly={(singleSavedItem.id !== this.state.editingFilterId) || isUpdateDisabled}
                              />
                              <Popconfirm
                                placement='left' title='Do you want to delete this filter?'
                                getPopupContainer={() => document.getElementById('saved-filter-name')}
                                onConfirm={() => this.onRemoveFilter(singleSavedItem.id)}
                                okText='Yes'
                                cancelText='No'>
                                {!isDeleteDisabled && <CrossIcon color={'#FFF'} />}
                              </Popconfirm>
                            </div>
                          }) : <Empty description={'No saved filter'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          : null}
                      </div>
                    </Skeleton>
                  </div>
                </div>
              </div>
            </div>
            <div className='filter-side-panel' id={'filter-side-panel' + randomId}>
              <div className='filter-side-panel-fields' id='filter-side-panel-fields'>
                {
                  this.state.filterData.map((singleFilter, index) => {
                    const randomNewId = '-' + Math.random().toString(36).substr(2)
                    if (singleFilter.type === 'KEYWORD' || singleFilter === 'SELECT') {
                      return <div key={index} className='side-panel-item' id={'side-panel' + randomId + randomNewId}>
                        <div className='panel-item-title'>{singleFilter.displayName}</div>
                        <FilterTagInput
                          placeholder={`${singleFilter.displayName}`}
                          displayParameter={'name'}
                          onSearch={this.onTagSearch.bind(this, index)}
                          searchText={this.state.selectedIndex === index ? this.state.tagSearchText : undefined}
                          contentName={singleFilter.fieldName}
                          selectedItems={singleFilter.value && typeof (singleFilter.value) === 'string' ? singleFilter.value.split(',') : (singleFilter && singleFilter.value ? singleFilter.value : [])}
                          filterType={this.props.filterType}
                          isVisible={this.state.isVisible}
                          parentCompoent={'side-panel' + randomId + randomNewId}
                          onChange={filterType === 'AppManager' ? this.onChangeAppTag.bind(this, index) : this.onChangeTag.bind(this, index)}
                          module={filterType ? modulePermission[filterType] === 'BUCKET_MANAGER' ? 'ASSET_MANAGER' : null : null}
                          project={project}
                          isDisabled={disabledField && (disabledField || []).includes(singleFilter.name)}
                          // filterType={filterType}
                          appClient={appClient}
                        />
                      </div>
                    } else if (singleFilter.type === 'SELECT') {
                      return <div key={index} className='side-panel-item' id={'side-panel' + randomId + randomNewId}>
                        <div className='panel-item-title'>{singleFilter.displayName}</div>
                        <FilterTagInput
                          placeholder={`${singleFilter.displayName}`}
                          displayParameter={'name'}
                          onSearch={this.onTagSearch.bind(this, index)}
                          searchText={this.state.selectedIndex === index ? this.state.tagSearchText : undefined}
                          contentName={singleFilter.fieldName}
                          selectedItems={singleFilter.value && typeof (singleFilter.value) === 'string' ? singleFilter.value.split(',') : (singleFilter && singleFilter.value ? singleFilter.value : [])}
                          filterType={this.props.filterType}
                          isVisible={this.state.isVisible}
                          parentCompoent={'side-panel' + randomId + randomNewId}
                          onChange={this.onSelectChange.bind(this, index)}
                          module={filterType ? modulePermission[filterType] === 'BUCKET_MANAGER' ? 'ASSET_MANAGER' : null : null}
                          project={project}
                          isDisabled={disabledField && (disabledField || []).includes(singleFilter.name)}
                          // filterType={filterType}
                          appClient={appClient}
                        />
                      </div>
                    } else if (singleFilter.type === 'DATE') {
                      return <div key={index} className='side-panel-item' id={`rangePicker-filter_${index}`}>
                        <div className='panel-item-title'>{singleFilter.displayName}</div>
                        <RangePicker
                          onChange={this.onChangeDate.bind(this, index)}
                          format='DD MMM YYYY'
                          onOpenChange={(open) => this.onOpenChange(index, open)}
                          getCalendarContainer={() => document.getElementById('filter-side-panel' + randomId)}
                          value={singleFilter.value ? singleFilter.value.split(',').map((dateItem) => moment(dateItem)) : singleFilter.value}
                        />
                      </div>
                    } else if (singleFilter.type === 'BOOLEAN') {
                      const randomNewId = '-' + Math.random().toString(36).substr(2, 9)
                      return <div key={index} className='side-panel-item' id={`side-panel-item${randomNewId}`}>
                        <span className='panel-item-title'>{singleFilter.displayName}</span>
                        <DropDown
                          selectedValue={singleFilter.value}
                          className={singleFilter.value ? 'selected' : ''}
                          options={singleFilter.name === 'isArchived' ? archivedBooleanOption : ((singleFilter.name === 'source' || singleFilter.name === 'isActive') ? sourceBooleanOption : booleanOption)}
                          valueParam='value'
                          onOptionSelect={(e) => this.onChangeCheckData(index, e)}
                          displayParam='name'
                          placeHolder={singleFilter.displayName}
                          parent={`side-panel-item${randomNewId}`}
                        />
                      </div>
                    } else if (singleFilter.type === 'LIMIT') {
                      return <div key={index} className='side-panel-item' >
                        <span className='panel-item-title'>{singleFilter.displayName}</span>
                        <InputNumber
                          min={1}
                          max={100}
                          defaultValue={singleFilter.value}
                          onChange={(value) => this.onChangeLimit(index, value)}
                          value={typeof (singleFilter.value) === 'string' ? Math.round(singleFilter.value) : singleFilter.value} />
                      </div>
                    } else if (singleFilter.type === 'SORT') {
                      return <div key={index} className='side-panel-item sort' >
                        <div className='sort-field'>
                          <div className='panel-item-title'>{singleFilter.displayName}</div>
                          <DropDown
                            className={singleFilter.fieldName ? 'selected' : ''}
                            selectedValue={singleFilter.fieldName}
                            options={sortOptionList || []}
                            onOptionSelect={(e) => this.onChangeSort(index, e)}
                            valueParam='name'
                            displayParam='displayName'
                            placeHolder={`Select ${singleFilter.displayName}`}
                            parent={'filter-side-panel' + randomId}
                            allowClear={singleFilter.name === 'SecondarySort'}
                          />
                        </div>
                        <div className='sort-field'>
                          <div className='panel-item-title'>Order</div>
                          <DropDown
                            className={singleFilter.value ? 'selected' : ''}
                            selectedValue={singleFilter.value}
                            options={sortOrderOptions}
                            valueParam='id'
                            onOptionSelect={(e) => this.onChangeSortOrder(index, e)}
                            displayParam='name'
                            placeHolder='Select Order'
                            allowClear={singleFilter.name === 'SecondarySort'}
                            parent={'filter-side-panel' + randomId}
                          />
                        </div>
                      </div>
                    } else if (singleFilter.type === 'MULTIPLE') {
                      return <div key={index} className='side-panel-item'>
                        <div className='panel-item-title'>{singleFilter.displayName}</div>
                        <MultiInput
                          title='Default Values'
                          placeholder={'Enter Values'}
                          inputName='filterInputItem'
                          value={singleFilter.value || []}
                          handleChange={this.handleChange} index={index}
                          disabled={false}
                          readOnly={false}
                          parentCompoent='filter-side-optional'
                        />
                      </div>
                    } else {
                      return <div key={index} className='side-panel-item'>
                        <Input
                          placeholder={`Enter ${singleFilter.displayName}`}
                          handleChange={(e) => this.onChangeText(e, index)}
                          inputName='filterInputItem'
                          value={singleFilter.value}
                          title={singleFilter.displayName}
                          warrningMessage={'* Only exact matching values will be returned'}
                        />
                      </div>
                    }
                  }
                  )
                }
                <div className='filter-side-optional' id='filter-side-optional' >
                  {
                    (this.state.optionalFilterData || []).map((singleFilter, index) => {
                      const randomNewIdOptional = '-' + Math.random().toString(36).substr(9)
                      if (singleFilter.type === 'KEYWORD' || singleFilter.type === 'SELECT') {
                        return <div key={index} className='side-panel-item' id={'side-panel' + randomIdOptional + randomNewIdOptional}>
                          <div className='panel-item-title'>{singleFilter.displayName}</div>
                          <div className='filter-side-optional-single'>
                            <FilterTagInput
                              placeholder={`${singleFilter.displayName}`}
                              displayParameter={'name'}
                              onSearch={this.onTagSearch.bind(this, (this.state.filterData.length + index))}
                              searchText={this.state.selectedIndex - this.state.filterData.length === index ? this.state.tagSearchText : undefined}
                              contentName={singleFilter.fieldName}
                              selectedItems={singleFilter.value && typeof (singleFilter.value) === 'string' ? singleFilter.value.split(',') : (singleFilter && singleFilter.value ? singleFilter.value : [])}
                              filterType={this.props.filterType}
                              isVisible={this.state.isVisible}
                              parentCompoent={'side-panel' + randomIdOptional + randomNewIdOptional}
                              onChange={this.onChangeTagOptional.bind(this, index)}
                              module={filterType ? modulePermission[filterType] === 'BUCKET_MANAGER' ? 'ASSET_MANAGER' : null : null}
                              project={project}
                            />
                            <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                          </div>
                        </div>
                      } else if (singleFilter.type === 'SELECT') {
                        return <div key={index} className='side-panel-item' id={'side-panel' + randomIdOptional + randomNewIdOptional}>
                          <div className='panel-item-title'>{singleFilter.displayName}</div>
                          <div className='filter-side-optional-single'>
                            <FilterTagInput
                              placeholder={`${singleFilter.displayName}`}
                              displayParameter={'name'}
                              onSearch={this.onTagSearch.bind(this, (this.state.filterData.length + index))}
                              searchText={this.state.selectedIndex - this.state.filterData.length === index ? this.state.tagSearchText : undefined}
                              contentName={singleFilter.fieldName}
                              selectedItems={singleFilter.value && typeof (singleFilter.value) === 'string' ? singleFilter.value.split(',') : (singleFilter && singleFilter.value ? singleFilter.value : [])}
                              filterType={this.props.filterType}
                              isVisible={this.state.isVisible}
                              parentCompoent={'side-panel' + randomIdOptional + randomNewIdOptional}
                              onChange={this.onSelectChange.bind(this, index)}
                              module={filterType ? modulePermission[filterType] === 'BUCKET_MANAGER' ? 'ASSET_MANAGER' : null : null}
                              project={project}
                            />
                            <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                          </div>
                        </div>
                      } else if (singleFilter.type === 'DATE') {
                        return <div key={index} className='side-panel-item' id={`rangePicker-filter_${randomIdOptional}`}>
                          <div className='panel-item-title'>{singleFilter.displayName}</div>
                          <div className='filter-side-optional-single'>
                            <RangePicker
                              onChange={this.onChangeDateOptional.bind(this, index)}
                              format='DD MMM YYYY'
                              onOpenChange={(open) => this.onOpenChangeOptional(index, open)}
                              getCalendarContainer={() => document.getElementById('filter-side-panel' + randomId)}
                              value={singleFilter.value ? singleFilter.value.split(',').map((dateItem) => moment(dateItem)) : singleFilter.value}
                            />
                            <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                          </div>

                        </div>
                      } else if (singleFilter.type === 'BOOLEAN') {
                        // const randomNewId = '-' + Math.random().toString(36).substr(9)
                        return <div key={index} className='side-panel-item' id={`side-panel-item${randomNewIdOptional}`}>
                          <span className='panel-item-title'>{singleFilter.displayName}</span>
                          <div className='filter-side-optional-single'>
                            <DropDown
                              selectedValue={singleFilter.value}
                              className={singleFilter.value ? 'selected' : ''}
                              options={singleFilter.name === 'isArchived' ? archivedBooleanOption : booleanOption}
                              valueParam='value'
                              onOptionSelect={(e) => this.onChangeCheckDataOptional(index, e)}
                              displayParam='name'
                              placeHolder={singleFilter.displayName}
                              parent={`side-panel-item${randomNewIdOptional}`}
                            />
                            <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                          </div>

                        </div>
                      } else if (singleFilter.type === 'LIMIT') {
                        return <div key={index} className='side-panel-item' >
                          <span className='panel-item-title'>{singleFilter.displayName}</span>
                          <div className='filter-side-optional-single'>
                            <InputNumber
                              min={1}
                              max={100}
                              defaultValue={singleFilter.value}
                              onChange={(value) => this.onChangeLimit(index, value)}
                              value={typeof (singleFilter.value) === 'string' ? Math.round(singleFilter.value) : singleFilter.value} />
                            <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                          </div>

                        </div>
                      } else if (singleFilter.type === 'SORT') {
                        return <div key={index} className='side-panel-item sort' id={'filter-side-panel' + randomIdOptional}>
                          <div className='sort-field'>
                            <div className='panel-item-title'>{singleFilter.displayName}</div>
                            <div className='filter-side-optional-single'>
                              <DropDown
                                selectedValue={singleFilter.fieldName}
                                className={singleFilter.fieldName ? 'selected' : ''}
                                options={sortOptionList || []}
                                onOptionSelect={(e) => this.onChangeSort(index, e)}
                                valueParam='name'
                                displayParam='displayName'
                                placeHolder={`Select ${singleFilter.displayName}`}
                                parent={'filter-side-panel' + randomIdOptional}
                                allowClear={singleFilter.name === 'SecondarySort'}
                              />
                              <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                            </div>

                          </div>
                          <div className='sort-field'>
                            <div className='panel-item-title'>Order</div>
                            <div className='filter-side-optional-single'>
                              <DropDown
                                selectedValue={singleFilter.value}
                                className={singleFilter.value ? 'selected' : ''}
                                options={sortOrderOptions}
                                valueParam='id'
                                onOptionSelect={(e) => this.onChangeSortOrder(index, e)}
                                displayParam='name'
                                placeHolder='Select Order'
                                parent={'filter-side-panel' + randomIdOptional}
                                allowClear={singleFilter.name === 'SecondarySort'}
                              />
                              <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                            </div>
                          </div>
                        </div>
                      } else if (singleFilter.type === 'MULTIPLE') {
                        return <div key={index} className='side-panel-item'>
                          <div className='panel-item-title'>{singleFilter.displayName}</div>
                          <MultiInput
                            title='Default Values'
                            placeholder={'Enter Values'}
                            inputName='filterInputItem'
                            value={singleFilter.value || []}
                            handleChange={this.handleChange} index={index}
                            disabled={false}
                            readOnly={false}
                            parentCompoent='filter-side-optional'
                          />
                        </div>
                      } else {
                        return <div className='side-panel-item' key={index} >
                          <div className='filter-side-optional-single'>
                            <Input
                              placeholder={`Select ${singleFilter.displayName}`}
                              handleChange={(e) => this.onChangeText(e, index, 'optional')}
                              inputName='filterInputItem'
                              value={singleFilter.value}
                              title={''}
                              warrningMessage={'* Only exact matching values will be returned'}
                            />
                            <CrossIcon onClick={(e) => this.optionalDeleteHandler(e, index)} />
                          </div>
                        </div>
                      }
                    }
                    )
                  }
                </div>
              </div>
              <div className='filter-side-bottom-bar'>
                <div className='filter-side-bottom-btns'>
                  <AddButton onClick={this.createFilterInit} isDisabled={isCreateDisabled} />
                  {(!this.state.isFilterSaveBtn && !isUpdateDisabled) ? <SaveButton isDisabled={this.state.isFilterSaveBtn} onClick={this.updateFilter} /> : ''}
                </div>
                <div className='filter-side-new-filter'>
                  <span className='clear-text' onClick={this.clearFilter}>Clear Filter</span>
                  <Popover placement='rightBottom'
                    content={newFilterContent}
                    visible={this.state.isVisibleAddFilter}
                    trigger='click'
                  >
                    <button onClick={this.addFilterBtnHandler} disabled={!(this.state.addFilterFields.length)} className={`ant-btn ant-btn-primary ${!(this.state.addFilterFields.length) ? 'disabled' : ''}`}>Add Filter</button>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        }}
      </AppContext.Consumer>
    )
  }

  keyHandlers = {
    shortcutFIlter: (e) => {
      this.setState({
        isVisible: !this.state.isVisible
      })
    }
  }

  fullClick = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  render () {
    const { placement, parentId, savedFilter, isFilterOpen } = this.props
    const { isVisible, selectedId } = this.state
    const content = isVisible ? this.renderContent() : ''
    const id = parentId || 'filter-container'
    const title = isVisible ? this.renderTitle() : ''
    const details = this.getFilterDetails(this.state.filterData)
    const isSelected = !!details.length
    const selectedFilter = selectedId ? _.find(savedFilter, (item) => item.id === selectedId) : undefined
    const filterHeader = _.isEmpty(selectedFilter) ? undefined : selectedFilter.name
    return (
      <div className='filter-content-container' id={'filter-container'} onClick={this.fullClick}>
        <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
        <Popover
          placement={placement}
          title={title}
          content={content}
          visible={isVisible}
          trigger='click'
          onVisibleChange={isFilterOpen ? this.onVisibleChange : () => { }}
          arrowPointAtCenter
          getPopupContainer={() => document.getElementById(id)}>
          {!isVisible && details.length ? <Tooltip
            parentComponent={id}
            placement={placement}
            details={details}
            isList
            arrowPointAtCenter
            heading={filterHeader}
            childComponent={<FilterIcon
              selected={isSelected}
              onClick={this.clickFilter} />}
          />
            : <FilterIcon
              selected={isSelected}
              onClick={this.clickFilter}
            />
          }
        </Popover>
      </div>
    )
  }
}

FilterContent.propTypes = {
  createFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  filterType: PropTypes.string
}

export default withApollo(compose(
  graphql(
    QueryGetSavedFilter,
    {
      skip: (props) => {
        return !(props.filterType && props.filterType.length)
      },
      options: (props) => {
        let filter = {
          type: {
            match: props.filterType
          }
        }

        if (props.savedFilterMode === 'myFilter') {
          filter.createdBy = {
            match: props.username
          }
        }
        if (props.savedFilterSearchString) {
          filter.name = {
            match: props.savedFilterSearchString
          }
        } else if (filter.name) { delete (filter.name) }
        return {
          variables: { filter, project: props.project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          savedFilter: props.data && props.data.listFilters && props.data.listFilters.items && props.data.listFilters.items.length ? _.cloneDeep(props.data.listFilters.items) : []
        }
      }
    }
  ),
  graphql(
    MutationDeleteFilter,
    {
      options: (props) => ({
        update: (cache, { data: { deleteFilter } }) => {
          const { filterType } = props
          let filter = {
            type: {
              match: filterType
            }
          }
          if (props.savedFilterMode === 'myFilter') {
            filter.createdBy = {
              match: props.username
            }
          }
          if (props.savedFilterSearchString) {
            filter.name = {
              match: props.savedFilterSearchString
            }
          } else if (filter.name) { delete (filter.name) }
          // if (filter.name) { delete (filter.name) }
          let cacheData = _.cloneDeep(cache.readQuery({
            query: QueryGetSavedFilter,
            returnPartialData: true,
            variables: {
              filter,
              project: props.project
            }
          }))
          if (cacheData && cacheData.listFilters && cacheData.listFilters.items) {
            cacheData.listFilters.items = cacheData.listFilters.items.filter((item) => item.id !== deleteFilter.id
            )
          }
          cache.writeQuery({
            query: QueryGetSavedFilter,
            data: cacheData,
            variables: { filter, project: props.project }
          })
        }
      }),
      props: (props) => ({
        deleteFilter: (id) => {
          return props.mutate({
            variables: { id, project: props.project }
          })
        }
      })
    }
  )
)(FilterContent))
