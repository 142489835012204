import gql from 'graphql-tag'

export default gql(`
    mutation retryIngestJob($csvS3Key: String! $batchJobId: ID! $project: String) {
        retryIngestJob(
            csvS3Key: $csvS3Key
            batchJobId: $batchJobId
            project: $project
        ) {
            batchID
        }
    }`
)
