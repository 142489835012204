import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { Empty, Skeleton } from 'antd'

// import FilterInput from './../dataEntry/inputs/FilterInput'
import AssetListCell from './AssetListCell'
import BucketFilterAccordion from './BucketFilterAccordion'
// import { utilityService } from './../../../services/UtilityService'
import { generateCroppedThumbnail } from './../../../util/util'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryFilterAssets from './../../../graphQL/asset/searchFilterAsset'
import FilterInput from '../dataEntry/inputs/FilterInput'

const width = ['calc(100% - 100px)', '50%', '100%']

class BucketAssetSearchListInner extends Component {
    state={
      isClearFilter: false,
      filterSearch: '',
      isPaginating: false,
      isLoading: true,
      isSearching: false,
      initialSearch: false,
      assetList: []
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && this.lastSearched !== { searchString: newProps.searchString, filter: newProps.filterVal }) {
        this.lastSearched = { searchString: newProps.searchString, filter: _.cloneDeep(newProps.filterVal) }
        // this.replaceCacheSearchString(newProps.searchString)
        this.setState({ assetList: newProps.assetList, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
      } else if (!newProps.isLoading && !_.isEqual(newProps.assetList, this.props.assetList)) {
        this.setState({ assetList: newProps.assetList, isSearching: false, isPaginating: false, isLoading: false })
      }
      if (this.props.searchString !== newProps.searchString) {
        this.setState({ initialSearch: true }, () => {
          setTimeout(() => {
            this.setState({ initialSearch: false })
          }, 500)
        })
      }
    }

    onChangeFilter = (e) => {
      this.setState({ isSearching: true })
      this.props.onChangeFilter(e)
    }

    onSearchFilter = (filterSearch) => {
      this.setState({ filterSearch })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.assetList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getAssetList(this.state.assetList.length, this.props.project))
    }

    clearFilterDetails = () => {
      this.setState({ isClearFilter: true }, () => {
        setTimeout(() => this.setState({ isClearFilter: false }), 200)
      })
    }

    render () {
      const { isOpen, searchString, changeFilterValue, filterVal, parent, onExpandButtonClick, isFilterOpen, project, readOnly } = this.props
      const { isClearFilter, assetList, isSearching, isLoading, isPaginating, initialSearch } = this.state
      const filterLimitItem = filterVal && filterVal.length ? filterVal.find((item) => item.name === 'limit') : null
      const maxVal = !_.isEmpty(filterLimitItem) && filterLimitItem.value ? filterLimitItem.value : 100
      const list = isOpen && assetList ? <React.Fragment>
        <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isLoading && !isSearching && !isPaginating} />
        <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isSearching}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreAsset}
            hasMore={this.props.totalCount > assetList.length && assetList.length < maxVal}
            initialLoad={false}
            useWindow={false}
          >
            {assetList && assetList.length ? assetList.map((item, index) => {
              let imageUrl
              if (item.defaultMedia) {
                imageUrl = generateCroppedThumbnail(item.defaultMedia, 87, 48, '16:9')
              }
              return (<AssetListCell key={index}
                imageUrl={imageUrl}
                status={item.status}
                assetName={item.title}
                displayId={item.shortId || item.id}
                assetType={item.type}
                assetId={item.id}
                date={moment(new Date(item.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                onSelect={() => { }}
                project={project}
                asset={item}
              />)
            }) : (!isLoading && !isPaginating) ? <Empty /> : null }
          </InfiniteScroll>
        </Skeleton>
        <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isPaginating && this.props.totalCount > assetList.length} />
      </React.Fragment> : <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading />
      const filters = <FilterInput
        onChangeSearchInput={this.onChangeFilter}
        searchString={searchString}
        isFilterOpen={isFilterOpen}
        isClearFilter={isClearFilter}
        changeFilter={(value) => { this.setState({ isSearching: true }); changeFilterValue(value) }}
        onSearchFilter={this.onSearchFilter}
        filterType={'BucketAssetManager'}
        bucketAssetFilter={_.cloneDeep(filterVal)}
        clearFilterDetails={this.clearFilterDetails}
        parentId={parent}
        initialSearch={initialSearch}
        project={project}
        readOnly={readOnly}
      />
      return (
        <BucketFilterAccordion isFirstClose title={filters} childComponent={list} onExpandButtonClick={onExpandButtonClick}
        />
      )
    }
}

export default withApollo(compose(
  graphql(
    QueryFilterAssets,
    {
      skip: ({ isOpen }) => {
        return !isOpen
      },
      options: (props) => {
        const filters = (_.cloneDeep(props.filterVal) || []).filter((item) => item.value).map((item) => {
          delete (item.__typename)
          if (typeof (item.value) !== 'string' && item.value.length) {
            item.value = item.value.map((innerItem) => innerItem.key)
            item.value = item.value.join()
          }
          if (item.displayName === 'Secondary Sort' && item.fieldName === null && item.value) {
            item.fieldName = 'createdAt'
          }
          if (item.displayName === 'Sport') {
            item.name = 'matchDetails.' + item.name
          }
          // if (item.type === 'LIMIT') {
          //   item.value = item.value > 20 ? 20 : item.value
          // }
          return item
        })
        let variables = {
          filters,
          limit: 10,
          project: props.project
        }
        if (props.searchString) { variables.query = props.searchString }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const assetList = data.listAssetsbyQuery ? data.listAssetsbyQuery.items : []
        return {
          assetList,
          isLoading: data.loading || !data.listAssetsbyQuery,
          totalCount: data.listAssetsbyQuery ? data.listAssetsbyQuery.totalCount : 0,
          getAssetList: (page, project) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                limit: 10,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listAssetsbyQuery.items, ...fetchMoreResult.listAssetsbyQuery.items ]
                prev.listAssetsbyQuery.items = newList
                return prev
              }
            })
          }
        }
      }

    }
  )
)(withRouter(BucketAssetSearchListInner)))
