import gql from 'graphql-tag'

export default gql(`
    query{
        getVodSettings{
            aws_region
            callbackUrl
            cognitoClientId
            cognitoPoolId
            encodingTemplate
            id
            mediaVodReqUrl
            password
            requestUrl
            username
            vodRequestUrl
            x_api_key
        }
    }`
)
