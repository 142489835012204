import gql from 'graphql-tag'

export default gql(`
    query listUsers($search: String $project: String){
        listUsers(input:{
          id: $search
        }, project: $project){
          items{
            id
            name
          }
        }
    }`
)
