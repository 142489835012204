import gql from 'graphql-tag'

export default gql(`
    query listRole($search: SearchInput $limit: Int $offset: Int $project: String){
        listRoles( 
            search: $search
            limit: $limit
            offset: $offset
            project: $project
        ) {
        items {
            id
            name
            usedCount
            source
        }
        totalCount
    }
}`)
