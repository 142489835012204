import React from 'react'
import PropTypes from 'prop-types'
import { Upload } from 'antd'

import UploadQueue from './UploadQueue'
import DragDropUploadIcon from '../general/icons/DragDropUploadIcon'
import { videoFormat, imageFormat } from './../../../util/util'

import './../ui.style.scss'

const Dragger = Upload.Dragger

class LocalVideoUploader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isModalInvisible: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.isLocalFolderOpen) {
      this.uploadRef.click()
      this.setState({ isModalInvisible: true })
    }
  }

  render () {
    const { fileList, beforeUpload, onReorder, handleDelete, isImageType, listMediaCategories, onCategorySelect } = this.props
    const uploadProps = {
      multiple: true,
      beforeUpload: beforeUpload,
      customRequest: () => { }
    }

    return (
      <div className={`upload-container`} >
        <Dragger
          className={!fileList.length ? 'full' : ''}
          {...uploadProps}
          accept={(isImageType ? imageFormat : videoFormat).map(item => `.${item}`).join(',')}
          listType='picture-card'>
          <DragDropUploadIcon />
          <p className='ant-upload-text' ref={(ref) => { this.uploadRef = ref }}>Click or drag file to this area to upload</p>
        </Dragger>
        {fileList.length ? <UploadQueue uploadingFiles={fileList}
          onReorder={onReorder}
          handleDelete={handleDelete}
          listMediaCategories={listMediaCategories}
          onCategorySelect={onCategorySelect}
        /> : null}
      </div>
    )
  }
}

LocalVideoUploader.propTypes = {
  /**  */
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /** Parent component id */
  parentCompoent: PropTypes.string,
  /** Module name fo filtering tags */
  module: PropTypes.string

}

LocalVideoUploader.defaultProps = {
}

export default LocalVideoUploader
