import React from 'react'
import PropTypes from 'prop-types'

const TrimOutIcon = ({ width, height, onClick, className, onMouseDown, style, ...props }) => (
  <svg width='9' height='19' className={className} onMouseDown={onMouseDown} style={style}viewBox='0 0 9 19'>
    <path fill='#FFF' fillRule='evenodd' d='M9 0H0v5h5v10H0v4h9V5z' />
  </svg>

)

TrimOutIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TrimOutIcon.defaultProps = {
  width: 20,
  height: 20
}

export default TrimOutIcon
