import React from 'react'
import PropTypes from 'prop-types'
import { Input, Icon, message } from 'antd'

import Tooltip from './../../dataDisplay/ToolTip'
import InformationIcon from '../../general/icons/InformationIcon'
import './../../ui.style.scss'
import PopoverConfirm from '../../feedback/PopoverConfirm'

const { TextArea } = Input

let parentCompoent = 'input-field-json'

const onChange = (handleChange, event) => {
  const value = event.target.value
  if (!value.startsWith(' ')) { handleChange(event) }
}

const copyOnClick = (data, type) => {
  navigator.clipboard.writeText(data, type)
  message.success(`${type} Copied Successfully`)
}

const TextAreaJsonArrayApp = ({ handleChange, value, placeholder, title, inputName, isEditable, isLimited, limit, onFocus, isRequired, isError, isJsonArrayValid,
  currentId, jsonArrayInvalidEntries, tooltipText, jsonConfig, handleJsonArrayChange, ...props }) => {
  let index = (jsonArrayInvalidEntries || []).indexOf(currentId)
  let jsonError = isError
  let jsonErrorMessage = false
  if (index !== -1) {
    jsonError = true
    jsonErrorMessage = true
  }

  return (
    <div className={`input-field text-area ${jsonError ? 'error' : ''}`} id='input-field-json'>
      <div className='text-area-header'>
        <div><label>{title}</label>{isRequired ? <span className='mandatory'>*</span> : ''} {tooltipText ? <span id='info-icon'><Tooltip placement='rightTop' childComponent={<div className='info-icon'><InformationIcon /></div>} description={tooltipText} parentCompoent='info-icon' /></span> : null }</div>
        <div className='text-area-icons'>
          <PopoverConfirm warningMessage={'Are you sure you want to clear the field ?'} confirm={() => handleJsonArrayChange(jsonConfig, '')} parentCompoent={parentCompoent} />
          <Icon type='copy' onClick={() => copyOnClick(value, 'JSON ARRAY')} />
        </div>

      </div>
      <p className='json-array-error'>{jsonErrorMessage ? 'Please Enter Valid JSON Array' : ''}</p>
      <TextArea name={inputName} placeholder={placeholder} value={value} onChange={onChange.bind(this, handleChange)} autosize={{ minRows: 2, maxRows: 6 }} maxLength={limit} onClick={onFocus} readOnly={!isEditable} />
    </div>
  )
}

TextAreaJsonArrayApp.propTypes = {
  /** Change action of TextAreaJsonArrayApp. */
  handleChange: PropTypes.func,
  /** display value of TextAreaJsonArrayApp. */
  value: PropTypes.string,
  /** placeholder of TextAreaJsonArrayApp. */
  placeholder: PropTypes.string.isRequired,
  /** title of TextAreaJsonArrayApp. */
  title: PropTypes.string.isRequired,
  /** input name of TextAreaJsonArrayApp. */
  inputName: PropTypes.string.isRequired,
  /** editable status of TextAreaJsonArrayApp. */
  isEditable: PropTypes.bool,
  /** string limited status of TextAreaJsonArrayApp. */
  isLimited: PropTypes.bool,
  /** string limit of TextAreaJsonArrayApp. */
  limit: PropTypes.number
}

TextAreaJsonArrayApp.defaultProps = {
  isEditable: true
}

export default TextAreaJsonArrayApp
