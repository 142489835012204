import gql from 'graphql-tag'

export default gql(`
    query($filter: TablePersonFilterInput $limit: Int $offset: Int $project: String){
        listPerson( 
            filter: $filter
            limit: $limit
            offset: $offset
            project: $project
        ) {
        items {
            id
            name
            source
            image {
                id
                name
                fileName
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            usedCount
        }
        totalCount
    }
}`)
