import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '../icons/SearchIcon'
import './../../ui.style.scss'

const SearchButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button search ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <SearchIcon isDisabled={isDisabled} />
  </div>
)

SearchButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

SearchButton.defaultProps = {
  isDisabled: false
}

export default SearchButton
