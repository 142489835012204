import React, { Component } from 'react'

import { Skeleton, Upload, message, Switch } from 'antd'

import AppContext from '../../../../AppContext'
import UploadQueueCell from '../../dataDisplay/UploadQueueCell'
import DragDropUploadIcon from '../../general/icons/DragDropUploadIcon'
import ApiService from '../../../../services/ApiService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCsvUploadUrl from '../../../../graphQL/IngestManager/getCsvUploadUrl'

const Dragger = Upload.Dragger

class AddJobAssetData extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    const { csvUploadUrl, lastUploadedCsvUrl } = newProps
    if (csvUploadUrl && !_.isEqual(csvUploadUrl, this.props.csvUploadUrl) && !_.isEqual(lastUploadedCsvUrl, csvUploadUrl)) {
      this.uploadCsvApi(_.cloneDeep(csvUploadUrl))
    }
  }

  uploadCsvApi = (uploadUrl) => {
    const successCallBack = () => {
      message.success(`CSV upload Successful`)
      this.props.handleCsvUpload()
    }

    const failureCallBack = () => {
      message.error(`Failed to upload csv`)
      this.props.handleCsvUpload()
    }

    const progressCallBack = progress => {
    }

    const { fileList } = this.props
    ApiService('PUT', uploadUrl, fileList[0], { 'Content-Type': 'text/csv' }, successCallBack, failureCallBack, progressCallBack)
  }

  render () {
    const { loading } = this.state
    const { fileList, uploadProps, handleDelete, onChangeGraceNoteSync, graceNoteSync } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          // const userPermissions = permissions['DATA_MANAGER']
          // const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          // const isEditDisabled = userPermissions.indexOf('UPDATE') === -1

          return <React.Fragment>
            <div className='add-assets-data'>
              <div className='upload-data'>
                <Skeleton active avatar={false} title={false} paragraph={{ rows: 2 }} loading={loading}>
                  <div className='upload-assets' style={{ width: '100%' }}>
                    <div className='upload-assets-file'>
                      { fileList.length ? fileList.map((file, index) => (<div key={index}><UploadQueueCell
                        thumbnailIcon={'file'}
                        name={file.name}
                        onClose={handleDelete}
                        index={index} />
                      </div>)
                      ) : <Dragger className={!fileList.length ? 'full' : ''} {...uploadProps} accept='.csv' listType='picture-card' onClick={() => { this.uploadRef.click() }}>
                        <DragDropUploadIcon />
                        <p className='ant-upload-text' ref={(ref) => { this.uploadRef = ref }}>Click or drag file to this area to upload</p>
                      </Dragger>
                      }
                    </div>
                    {/* <Checkbox checked={graceNoteSync} onChange={onChangeGraceNoteSync}>Gracenote Sync</Checkbox> */}
                    <div className='gracenote-sync-switch'>
                      <Switch checked={graceNoteSync} onChange={(status) => onChangeGraceNoteSync(status)} />
                      <span>Gracenote Sync</span>
                    </div>
                  </div>
                </Skeleton>
              </div>
            </div>
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AddJobAssetData.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCsvUploadUrl,
    {
      options: ({ files, project, IngestClient, graceNoteSync }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { input: files, project, gracenoteSync: graceNoteSync },
          client: IngestClient
        }
      },
      skip: ({ files }) => {
        return (!files || !files.length)
      },
      props: (props) => {
        if (props.data && props.data.getCsvUploadUrl && props.data.getCsvUploadUrl[0] && props.data.getCsvUploadUrl[0].uploadUrl !== props.ownProps.lastUploadedCsvUrl) {
          props.ownProps.updateLastUploadedUrl(props.data.getCsvUploadUrl[0].uploadUrl)
          props.ownProps.updateLastUploadedKey(props.data.getCsvUploadUrl[0].key)
        }
        return {
          isLoading: props.data && props.data.loading,
          csvUploadUrl: props.data && props.data.getCsvUploadUrl && props.data.getCsvUploadUrl[0] ? props.data && props.data.getCsvUploadUrl && props.data.getCsvUploadUrl[0] && props.data.getCsvUploadUrl[0].uploadUrl : ''
        }
      }
    }
  )
)(AddJobAssetData))
