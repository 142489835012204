import gql from 'graphql-tag'

export default gql(`
    mutation createPublishingGroup( $name: String! $project: String) {
        createPublishingGroup(
            input: {
                name: $name
            }
            project: $project
        ) {
            id
            name
            status
            isDefault
            isEnvironment
            countries
        }
    }`
)
