import React from 'react'
// import PropTypes from 'prop-types'
import './../ui.style.scss'

class AdminOriginalData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  render () {
    const { selectedData } = this.props
    const leftSelectedData = selectedData.filter((item) => item.isLeft === true)
    const rightSelectedData = selectedData.filter((item) => item.isLeft !== true)
    return (
      <div className='original-data'>
        <div className='data-left'>
          {leftSelectedData.map(item => {
            return <div>
              <p className='key' >{item.key}</p>
              <p className='value' >{item.value}</p>
            </div>
          })}
        </div>
        <div className='data-right'>
          {rightSelectedData.map(item => {
            return <div>
              <p className='key' >{item.key}</p>
              <p className='value' >{item.value}</p>
            </div>
          })}
        </div>
      </div>
    )
  }
}

AdminOriginalData.propTypes = {

}

AdminOriginalData.defaultProps = {
}

export default AdminOriginalData
