import React from 'react'
import PropTypes from 'prop-types'

const TimeFilterIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill={color} fillRule='nonzero' d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 19c-4.95 0-9-4.05-9-9s4.05-9 9-9 9 4.05 9 9-4.05 9-9 9zm.5-14H9v6l5.2 3.2.8-1.3-4.5-2.7V5z' />
    </g>
  </svg>
)

TimeFilterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

TimeFilterIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default TimeFilterIcon
