import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import HistoryList from '../../../../modules/auditManager/HistoryList'
// import { utilityService } from '../../../../services/UtilityService'
// import bucketTypes from './../../../../constants/bucket-group-types'
import './../../ui.style.scss'

class AppGroupHistoryModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { isVisible, handleCancel, selectedGroup, project, title } = this.props
    // const { newTitle, selectedApp, key, isLoading, isEdited } = this.state
    return (

      <Modal
        className='general-modal app-group-history-modal'
        visible={isVisible}
        title={title}
        maskClosable={false}
        closable
        onCancel={handleCancel}
        // afterClose={this.resetState}
        centered
        destroyOnClose
      >
        <HistoryList isChanging={false} showFilter={false} content='APP_MANAGER' contentId={selectedGroup} project={project} historyType='APP_GROUP' />
      </Modal>

    )
  }
}

AppGroupHistoryModal.propTypes = {
  /** visible action of AddBucketGroupModal. */
  visible: PropTypes.bool,
  /** title  of AddBucketGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of AddBucketGroupModal. */
  handleCancel: PropTypes.func,
  /** Boolean for showing loading */
  loading: PropTypes.bool,
  /** Boolean to know source of the modal */
  isFromEdit: PropTypes.bool
}

AppGroupHistoryModal.defaultProps = {
}

export default AppGroupHistoryModal
