import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Accordion from './Accordion'

import TemplatePublishDetails from './TemplatePublishDetails'
class TemplatePublishRules extends Component {
  render () {
    const { publishRules, onUpdatePublishRules, onAddPublishRule, onRemovePublishRule, project } = this.props
    return (
      <div className='template-publish-rule-details'>
        <Accordion
          title={'PUBLISHING'}
          childComponent={<TemplatePublishDetails publishRules={publishRules} onUpdatePublishRules={onUpdatePublishRules} onRemovePublishRule={onRemovePublishRule} onAddPublishRule={onAddPublishRule} project={project} />}
        />
      </div>
    )
  }
}

TemplatePublishRules.propTypes = {}
export default TemplatePublishRules
