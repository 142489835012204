import React from 'react'
import PropTypes from 'prop-types'
import TrimIcon from './icons/TrimIcon'
import { Spin, Icon } from 'antd'

import './Player.scss'

const CreateVodButton = ({ isDisabled, onClick, isLoading, ...props }) => (
  <div className={`trigger-button ${isDisabled ? 'disabled' : ''}`} onClick={!isLoading && !isDisabled ? onClick : () => {}} >
    {isLoading ? <Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} /> : <TrimIcon /> }
  </div>
)

CreateVodButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

CreateVodButton.defaultProps = {
  isDisabled: false
}

export default CreateVodButton
