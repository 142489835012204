import React from 'react'
import { timeSince } from '../../../util/util'
const NotificationListCell = ({ id, title, content, time, onSelectOption, openedNotification, toggleShowOptions, read, handleDismissNotification, ...props }) => {
  let timeInDay = timeSince(time)
  return (
    <div className={`notification-cell ${read ? '' : 'read'}`} onClick={() => handleDismissNotification(id, read)}>
      <div className='notification-cell-title'>
        <div>{title}</div>
      </div>
      <div className='notification-cell-content'>
        {content}
      </div>
      <div className='notification-cell-time'>
        {timeInDay}
      </div>
    </div>
  )
}

NotificationListCell.propTypes = {

}

export default NotificationListCell
