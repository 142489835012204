import gql from 'graphql-tag'

export default gql(`
  query($type: String! $project: String){
    filterDataList(
      type:$type
      project:$project
      ) {
        sortableFields{
            name
            displayName
        }
    }
  }
  `
)
