import React from 'react'
import PropTypes from 'prop-types'

const BackArrowIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M18.75 8H10V4.25C10 3.645 9.531 3 8.75 3c-.576 0-.869.359-1.25.675L.781 9.25C.302 9.641 0 9.944 0 10.5s.302.86.781 1.25L7.5 17.325c.381.316.674.675 1.25.675.781 0 1.25-.645 1.25-1.25V13h8.75c.688 0 1.25-.563 1.25-1.25v-2.5C20 8.562 19.437 8 18.75 8z' />
    </g>
  </svg>
)

BackArrowIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

BackArrowIcon.defaultProps = {
  width: 20,
  height: 20
}

export default BackArrowIcon
