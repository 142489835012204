import gql from 'graphql-tag'

export default gql(`
query listUserAntiPiracyActions( $userId: String $project: String  ) {
    listUserAntiPiracyActions( 
      userId: $userId
      project: $project
    ) {
      statusCode
      data {
        email
        operatorNote
        createdAt
        actionDetails {
          action
          subActions {
            subAction
          }
        }
        email
        
      }
    }
}`)
