import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'

import TabPanel from '../../components/ui/dataDisplay/TabPanel'
import IngestJobDetails from './IngestJobDetails'
import IngestHistoryList from '../auditManager/IngestHistoryList'
import { utilityService } from '../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationDeleteAllAssets from '../../graphQL/IngestManager/batchArchiveAssets'
import MutationUnPublishAllAssets from '../../graphQL/IngestManager/batchUnpublishAssets'
import QueryGetInstedJob from '../../graphQL/IngestManager/getIngestedJob'
import SubscriptionJobDetailsUpdate from '../../graphQL/IngestManager/jobDetailsUpdateSubcription'

const detailsTabs = [
  {
    displayName: 'Job Info',
    mode: 'info'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

let subscribeJobDetails = null

const subscribeChangeInJobDetails = (subscribeToMore, props, selectedJobId, retrying = 50) => {
  const id = selectedJobId || props.ownProps.selectedJobId
  return subscribeToMore({
    document: SubscriptionJobDetailsUpdate,
    variables: { batchID: id },
    updateQuery: (prev, { subscriptionData: { data: { ingestJobUpdated } } }) => {
      try {
        if (!ingestJobUpdated) return prev
        prev.getIngestJob = ingestJobUpdated
        return prev
      } catch (error) {
        // console.log(error, 'sub3')

      }
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeChangeInJobDetails(subscribeToMore, props, id, retrying * 2) }, retrying)
      }
    }
  })
}

class IngestSideBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mode: 'info',
      selectedIngestedJob: {},
      isArchiveLoading: false,
      isArchiveDisable: false,
      isUnpublishLoading: false,
      isUnpublishDisable: false,
      archiveWarning: false,
      unpublisheWarning: false
    }
  }

  componentDidMount = () => {
    this.initiateSubscription()
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.selectedJobId) {
      let selectedIngestedJob = (newProps.listIngestJobs || []).filter(item => item.batchID === newProps.selectedJobId)[0]
      this.setState({
        selectedIngestedJob
      })
    }
    this.initiateSubscription(newProps.selectedJobId)
    this.checkDeleteAllStatus(newProps)
    this.checkUnPublishAllStatus(newProps)
  }

  initiateSubscription = (batchID) => {
    if (this.props.subscribeToChangeInJobDetails) {
      if (subscribeJobDetails) { subscribeJobDetails() }
      subscribeJobDetails = this.props.subscribeToChangeInJobDetails(batchID)
    } else {
      setTimeout(() => {
        this.initiateSubscription(batchID)
      }, 1500)
    }
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  checkDeleteAllStatus = (nextProps) => {
    const ingestedAssets = nextProps && nextProps.selectedIngestedJobDetails && nextProps.selectedIngestedJobDetails.successful ? nextProps.selectedIngestedJobDetails.successful : []
    const isArchived = (ingestedAssets || []).every(asset => asset.isArchived === true) // Check for archive asset
    if (_.isEmpty(ingestedAssets)) { // check for not completed job
      this.setState({
        isArchiveDisable: true
      })
    } else {
      this.setState({
        isArchiveDisable: isArchived
      })
    }
  }

  checkUnPublishAllStatus = (nextProps) => {
    const ingestedAssets = nextProps && nextProps.selectedIngestedJobDetails && nextProps.selectedIngestedJobDetails.successful ? nextProps.selectedIngestedJobDetails.successful : []
    const isPublished = (ingestedAssets || []).every(asset => asset.status === 'DRAFT') // Check for unPublish asset
    if (_.isEmpty(ingestedAssets)) { // check for not completed job
      this.setState({
        isUnpublishDisable: true
      })
    } else {
      this.setState({
        isUnpublishDisable: isPublished
      })
    }
  }

  handleArchiveAllSubmit = () => {
    this.setState({
      isArchiveLoading: true
    })
    const { selectedIngestedJob } = this.state
    this.props.batchArchiveAssets(selectedIngestedJob.batchID).then(({ data }) => {
      message.success('Archive All is Successful')
      const isArchived = data && data.batchArchiveAssets && data.batchArchiveAssets.isArchived
      if (isArchived) {
        this.setState({
          isArchiveLoading: false,
          isArchiveDisable: isArchived,
          archiveWarning: false
        })
      }
    }, errorMsg => {
      this.setState({ isArchiveLoading: false, archiveWarning: false })
      utilityService.handleError(errorMsg)
    })
  }

  handleUnpublishAllSubmit = () => {
    this.setState({
      isUnpublishLoading: true
    })
    const { selectedIngestedJob } = this.state
    this.props.batchUnpublishAssets(selectedIngestedJob.batchID).then(({ data }) => {
      message.success('Unpublish All is Successful')
      setTimeout(this.props.fetchJobDetails, 1500)
      const isUnpublished = data && data.batchUnpublishAssets && data.batchUnpublishAssets.isUnpublished
      if (isUnpublished) {
        this.setState({
          isUnpublishLoading: false,
          isUnpublishDisable: isUnpublished,
          unpublisheWarning: false
        })
      }
    }, errorMsg => {
      this.setState({ isArchiveLoading: false, unpublisheWarning: false })
      utilityService.handleError(errorMsg)
    })
  }

  toggleArchiveWarning = (status) => {
    this.setState({
      archiveWarning: status
    })
  }

  toggleUnpublisheWarning = (status) => {
    this.setState({
      unpublisheWarning: status
    })
  }

  render () {
    const { mode } = this.state
    const { batchArchiveAssets, batchUnpublishAssets, project, IngestClient, selectedJobId, isUpdateDisabled,
      isCreateDisable, handleCustomJobTypeName, refetchHistory, isPublishDisable, isLoading, selectedIngestedJobDetails, fetchJobDetails } = this.props
    const { selectedIngestedJob, isArchiveDisable, isUnpublishDisable, isUnpublishLoading, unpublisheWarning, archiveWarning, isArchiveLoading } = this.state
    return (
      <div>
        <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
        {mode === 'info' ? <IngestJobDetails
          selectedIngestedJob={selectedIngestedJob} // for job details
          batchArchiveAssets={batchArchiveAssets}
          batchUnpublishAssets={batchUnpublishAssets}
          project={project}
          IngestClient={IngestClient}
          isUpdateDisabled={isUpdateDisabled}
          isCreateDisable={isCreateDisable}
          handleCustomJobTypeName={handleCustomJobTypeName}
          refetchHistory={refetchHistory}
          isPublishDisable={isPublishDisable}
          isLoading={isLoading}
          selectedIngestedJobDetails={selectedIngestedJobDetails} // for job assetList
          isArchiveLoading={isArchiveLoading}
          isArchiveDisable={isArchiveDisable}
          isUnpublishLoading={isUnpublishLoading}
          isUnpublishDisable={isUnpublishDisable}
          archiveWarning={archiveWarning}
          unpublisheWarning={unpublisheWarning}
          toggleUnpublisheWarning={this.toggleUnpublisheWarning}
          toggleArchiveWarning={this.toggleArchiveWarning}
          handleUnpublishAllSubmit={this.handleUnpublishAllSubmit}
          handleArchiveAllSubmit={this.handleArchiveAllSubmit}
          fetchJobDetails={fetchJobDetails}
        /> : null}
        {mode === 'history' ? <IngestHistoryList
          project={project}
          IngestClient={IngestClient}
          selectedJobId={selectedJobId}
          content='INGEST_JOB_MANAGER'
        /> : null }
      </div>
    )
  }
}

IngestSideBar.propTypes = {
  /** current project */
  project: PropTypes.string,
  /** ingest Client configuration */
  IngestClient: PropTypes.object,
  /** selected job id */
  selectedJobId: PropTypes.string,
  /** boolean to check the update access */
  isUpdateDisabled: PropTypes.bool,
  /** boolean to check the create access */
  isCreateDisable: PropTypes.bool,
  /** function to handle jobType custom name */
  handleCustomJobTypeName: PropTypes.func,
  /** function to refetch history */
  refetchHistory: PropTypes.func,
  /** boolean to check the pubilsh access */
  isPublishDisable: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryGetInstedJob,
    {
      skip: ({ selectedJobId }) => {
        return selectedJobId === null
      },
      options: ({ IngestClient, selectedJobId }) => {
        return {
          fetchPolicy: 'network-only',
          client: IngestClient,
          variables: { batchJobId: selectedJobId },
          pollInterval: 5000
        }
      },
      props: (props) => {
        return {
          isLoading: props.data && props.data.loading,
          selectedIngestedJobDetails: props.data && props.data.getIngestJob ? props.data && props.data.getIngestJob : {},
          fetchJobDetails: () => {
            return props.data.refetch()
          },
          subscribeToChangeInJobDetails: (selectedJobId) => subscribeChangeInJobDetails(props.data.subscribeToMore, props, selectedJobId)
        }
      }
    }
  ),
  graphql(
    MutationDeleteAllAssets,
    {
      options: ({ IngestClient }) => {
        return {
          client: IngestClient
        }
      },
      props: (props) => ({
        batchArchiveAssets: (batchJobId) => {
          return props.mutate({
            variables: { batchJobId }
          })
        }
      })
    }
  ),
  graphql(
    MutationUnPublishAllAssets,
    {
      options: ({ IngestClient }) => {
        return {
          client: IngestClient
        }
      },
      props: (props) => ({
        batchUnpublishAssets: (batchJobId) => {
          return props.mutate({
            variables: { batchJobId }
          })
        }
      })
    }
  )

)(IngestSideBar))
