import gql from 'graphql-tag'

export default gql(`
    query listAssetCategories($search: SearchInput $limit: Int $offset: Int $project: String){
        listAssetCategories( 
            search: $search
            limit: $limit
            offset: $offset
            project: $project
        ) {
        items {
            id
            slug
            name
            assetCount
            isTransferring
            isError
        }
        totalCount
    }
}`)
