import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import MatchListCell from './MatchListCell'
import MatchListExpandedCell from './MatchListExpandedCell'
import { generateCroppedThumbnail } from '../../../util/util'

const todayDate = moment(new Date()).format('ddd, DD MMM, YYYY')

class MatchListTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      match: props.matches && props.matches.length ? props.matches : null
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.searching !== this.props.searching) {
      this.setState({ isSearching: newProps.searching })
    }
    if (newProps.matches && !_.isEqual(newProps.matches, this.props.matches)) {
      this.setState({ match: newProps.matches }, this.onSearchChange)
    }
  }

  onSearchChange = () => {
    this.props.onSearchChange()
  }

  getStatus = (match) => {
    if (match.asset && match.asset.length) {
      const gameAssets = match.asset.filter(item => item.type === 'GAME' && item.vodStatus && !item.isArchived)
      if (gameAssets && gameAssets.length) {
        const status = gameAssets[0].vodStatus ? gameAssets[0].vodStatus.status : null
        return status
      }
    }
    if (match.asset && match.asset.length) {
      const gameAssets = match.asset.filter(item => item.type === 'GAME' && item.program && !item.isArchived)
      if (gameAssets && gameAssets.length) {
        const currentDate = moment()
        const startDate = moment(gameAssets[0].program.broadCastStartTime)
        const endDate = moment(gameAssets[0].program.broadCastEndTime)
        let isLive = gameAssets[0].program.broadCastEndTime ? (currentDate.isBetween(startDate, endDate) || startDate.format('DD MM YYYY HH:mm') === currentDate.format('DD MM YYYY HH:mm'))
          : moment(currentDate).isSameOrAfter(startDate, 'minute')
        if (isLive) {
          return 'On Now'
        } else {
          const isScheduled = moment().isBefore(moment(gameAssets[0].program.broadCastStartTime))
          if (isScheduled) {
            return 'SCHEDULED'
          } else if (match && match.isLiveOnly === true) {
            return 'AUTO TRIGGER DISABLED'
          } else if (moment().isAfter(moment(gameAssets[0].program.broadCastEndTime)) && gameAssets[0].isLive === true) {
            return 'AWAITING AUTO TRIGGER'
          } else if (moment().isAfter(moment(gameAssets[0].program.broadCastEndTime).add(24, 'hours'))) {
            return ''
          }
        }
      }
    }
    if (match.programs[0]) {
      if (match.asset && match.asset.length) {
        const gameAssets = match.asset.filter(item => item.type === 'GAME' && item.program && !item.isArchived)
        if (gameAssets && gameAssets.length) {
          return
        }
      }
      const currentDate = moment()
      const startDate = moment(match.programs[0].broadCastStartTime)
      const endDate = moment(match.programs[0].broadCastEndTime)
      let isLive = match.programs[0].broadCastEndTime ? (currentDate.isBetween(startDate, endDate) || startDate.format('DD MM YYYY HH:mm') === currentDate.format('DD MM YYYY HH:mm'))
        : moment(currentDate).isSameOrAfter(startDate, 'minute')
      if (isLive) {
        return 'On Now'
      } else {
        const isScheduled = moment().isBefore(moment(match.programs[0].broadCastStartTime))
        if (isScheduled) {
          return 'SCHEDULED'
        }
      }
    }
  }

  render () {
    const { match } = this.state
    const { selectedMatchId, isExpanded, onSelect, matchDate, matchSearch, matchFilter, listAssetTypes } = this.props
    const types = (listAssetTypes || []).map(item => {
      return {
        displayName: item.title,
        type: item.title,
        icon: item.icon ? generateCroppedThumbnail(item.icon, 18, 18, '1:1') : '',
        programSection: item.programSection,
        neededCount: 1
      }
    })
    return (
      (match && match.length) ? <div className={`match-table ${isExpanded ? 'expanded' : ''}`}>
        <div className='match-table-header'>
          <div className='date'>
            <span>{ matchDate === todayDate ? `(Today) ${matchDate}` : matchDate }</span>
          </div>
          {isExpanded ? <div className='details'>
            <span>Match Details</span>
          </div> : ''}
          {!isExpanded ? <React.Fragment>
            <div className='second'>
              <div className='broadcast-time'> Broadcast Time</div>
              <div className='match-time'>Match Time</div>
            </div>
            <div className='third'>
              <div className='channel'>Channel</div>
              <div className='status'>Live2Vod Status</div>
            </div>
            <div className='list-match-assets'>
              {types.map(assetType => {
                return <div className='match-asset-cell' key={assetType.type}>
                  {(assetType && assetType.icon) ? <img src={assetType.icon} /> : null}
                </div>
              })}
            </div>
          </React.Fragment> : <React.Fragment>
            <div className='list-match-assets'>
              {types.map((assetType, index) => {
                return <div className='match-asset-cell-expanded' key={assetType.type + index}>
                  {(assetType && assetType.icon) ? <img src={assetType.icon} /> : null}
                  <div>{assetType.displayName}</div>
                </div>
              })}
            </div>
          </React.Fragment> }
        </div>
        { match && match.length ? match.filter(items => items).map(match => {
          const status = this.getStatus(match)
          const gameAssets = match && match.asset && match.asset.length ? match.asset.filter(item => item.type === 'GAME' && item.program && !item.isArchived) : null
          const vodStatusAssets = match.asset.filter(item => item.type === 'GAME' && item.vodStatus && !item.isArchived)
          const program = match && gameAssets && gameAssets.length ? gameAssets[0].program : match && match.programs && match.programs[0] ? match.programs[0] : []
          return !isExpanded ? <MatchListCell key={match.id} matchId={match.id} isSelected={match.id === selectedMatchId}
            isExpanded={isExpanded} details={match} onSelect={onSelect} status={status} program={program} types={types} />
            : <MatchListExpandedCell key={match.id} matchId={match.id} isSelected={match.id === selectedMatchId} isExpanded={isExpanded} details={match} vodStatusAssets={vodStatusAssets}
              onSelect={onSelect} status={status} program={program} matchSearch={matchSearch} matchFilter={matchFilter} selectedMatchId={selectedMatchId} types={types} />
        }) : null
        }
      </div> : null
    )
  }
}

MatchListTable.propTypes = {
  /** Match date of MatchListTable */
  matchDate: PropTypes.string,
  /** Match list of MatchListTable */
  matchList: PropTypes.array,
  /** Match details expanded status of MatchListTable */
  isExpanded: PropTypes.bool,
  /** Match selected status of MatchListTable */
  selectedMatchId: PropTypes.string,
  /** Click selection action of MatchListTable. */
  onSelect: PropTypes.func
}

export default MatchListTable
