import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import TagsInput from '../../../components/ui/dataEntry/inputs/TagsInput'
import LoadingButton from './../../../components/ui/general/buttons/LoadingButton'
import { utilityService } from './../../../services/UtilityService'

import './../../../components/ui/ui.style.scss'

import QueryGetRecurrenceList from '../../../graphQL/offer/getRecurrenceList'
import MutationUpdateOffer from '../../../graphQL/admin/entertainmentData/updateModuleConfig'

const parentCompoent = 'offer-management-container'

class OfferManagement extends Component {
  constructor (props) {
    super(props)
    this.state = {
      recurrence: props.listRecurrence && props.listRecurrence.value && props.listRecurrence.value.length ? props.listRecurrence.value : [],
      currency: props.listCurrency && props.listCurrency.value && props.listCurrency.value.length ? props.listCurrency.value : [],
      isUpdateDisabled: true,
      isLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps && newProps.listCurrency && newProps.listRecurrence && (!_.isEqual(this.props.listCurrency, newProps.listCurrency) || !_.isEqual(this.props.listRecurrence, newProps.listRecurrence))) {
      this.setState({
        recurrence: newProps.listRecurrence.value,
        currency: newProps.listCurrency.value
      })
    }
  }

  handleRecurrenceChange = (value, isDelete) => {
    const { recurrence } = this.state
    let newRecurrence = [...recurrence] || []
    if (isDelete) {
      const index = newRecurrence.indexOf(value)
      if (index > -1) {
        newRecurrence.splice(index, 1)
      }
    } else {
      if (!recurrence) {
        newRecurrence = [value]
      } else {
        const index = newRecurrence.findIndex(item => item === value)
        if (index === -1) {
          newRecurrence.push(value)
        }
      }
    }
    const uniqueRecurrence = _.uniqBy(newRecurrence)
    this.setState({ recurrence: uniqueRecurrence, isUpdateDisabled: false })
  }

  handleCurrencyChange = (value, isDelete) => {
    const { currency } = this.state
    let newCurrency = [...currency] || []
    if (isDelete) {
      const index = newCurrency.indexOf(value)
      if (index > -1) {
        newCurrency.splice(index, 1)
      }
    } else {
      if (!currency) {
        newCurrency = [value]
      } else {
        const index = newCurrency.findIndex(item => item === value)
        if (index === -1) {
          newCurrency.push(value)
        }
      }
    }
    const uniqueCurrency = _.uniqBy(newCurrency)
    this.setState({ currency: uniqueCurrency, isUpdateDisabled: false })
  }

  updateOffer = () => {
    const { onHistoryUpdate, project } = this.props
    this.setState({ isLoading: true })
    const { recurrence, currency } = this.state
    const newRecurrence = utilityService.getUniqueArr(recurrence)
    const newCurrency = utilityService.getUniqueArr(currency)
    const currencyVariables = {
      id: project === 'projectx' ? 'projectx-offer_currency' : 'offer_currency',
      value: newCurrency,
      module: project === 'projectx' ? 'projectx-offer' : 'offer',
      project: this.props.project
    }

    const recurrenceVariables = {
      id: project === 'projectx' ? 'projectx-offer_recurrence' : 'offer_recurrence',
      value: newRecurrence,
      module: project === 'projectx' ? 'projectx-offer' : 'offer',
      project: this.props.project
    }

    this.props.updatePartnerApps(currencyVariables).then(() => {
      if (onHistoryUpdate) {
        onHistoryUpdate()
      }
      this.setState({ isUpdateDisabled: true, currency: newCurrency, isLoading: false })
    }, errorMsg => {
      this.setState({ isLoading: false })
      utilityService.handleError(errorMsg)
    })

    this.props.updatePartnerApps(recurrenceVariables).then(() => {
      if (onHistoryUpdate) {
        onHistoryUpdate()
      }
      this.setState({ isUpdateDisabled: true, recurrence: newRecurrence, isLoading: false })
    }, errorMsg => {
      this.setState({ isLoading: false })
      utilityService.handleError(errorMsg)
    })
  }

  render () {
    const { recurrence, currency, isUpdateDisabled, isLoading } = this.state
    return (
      <div className='admin-portal offer-manger-list' id='offer-management-container'>
        <div className='container-div'>
          <div className='input-field'>
            <label className='input-title'>Offer Manager &gt; Recurrence</label>
            <TagsInput
              selectedItems={recurrence || []}
              tagList={[]}
              className={'offer-management-recurrence'}
              mode={'tags'}
              onChange={this.handleRecurrenceChange}
              placeholder='Insert Tags'
              displayParameter='name'
              parentCompoent={parentCompoent}
            />
          </div>
          <div className='input-field'>
            <label className='input-title'>Offer Manager &gt; Price &gt; Currency</label>
            <TagsInput
              selectedItems={currency || []}
              tagList={[]}
              className={'offer-management-currency'}
              mode={'tags'}
              onChange={this.handleCurrencyChange}
              placeholder='Insert Tags'
              displayParameter='name'
              parentCompoent={parentCompoent}
            />
          </div>
        </div>
        <div className='admin-footer' >
          <LoadingButton
            type='primary'
            onClick={this.updateOffer}
            htmlType='submit'
            buttonText={'Update'}
            buttonClass='save-btn'
            isLoading={isLoading}
            isDisabled={isUpdateDisabled}
          />
        </div>
      </div>
    )
  }
}

OfferManagement.propTypes = {
  /** project details */
  project: PropTypes.string
}

OfferManagement.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryGetRecurrenceList,
    {
      options: (props) => {
        const { project } = props
        return {
          variables: { module: project === 'projectx' ? 'projectx-offer' : 'offer', project: project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        let project = props.ownProps.project
        {
          const isListPresent = (props.data && props.data.listConfig && props.data.listConfig.length)
          let listCurrency
          let listRecurrence
          if (project === 'projectx') {
            listCurrency = isListPresent ? (props.data.listConfig || []).find(option => option.id === 'projectx-offer_currency') : null
            listRecurrence = isListPresent ? (props.data.listConfig || []).find(option => option.id === 'projectx-offer_recurrence') : null
          } else {
            listCurrency = isListPresent ? (props.data.listConfig || []).find(option => option.id === 'offer_currency') : null
            listRecurrence = isListPresent ? (props.data.listConfig || []).find(option => option.id === 'offer_recurrence') : null
          }

          return {
            listCurrency,
            listRecurrence
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateOffer,
    {
      props: (props) => ({
        updatePartnerApps: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(withApollo(OfferManagement)))
