import React, { Component } from 'react'
import CollapsedIcon from './../general/icons/CollapsedIcon'
import PropTypes from 'prop-types'
const defaultImage = require('../../../assets/images/default-image-square.jpg')

class MatchAssetAccordionHeader extends Component {
  render () {
    const { icon, title, hasAsset } = this.props
    return (
      <div className='match-accordion'>
        <div className='left'>
          {icon || <img src={defaultImage} />}
          <p>{title}</p>
        </div>
        {hasAsset ? <div className='accordion-arrow'>
          <CollapsedIcon />
        </div> : null}
      </div>
    )
  }
}

MatchAssetAccordionHeader.propTypes = {
  /** icon of MatchAssetAccordionHeader. */
  icon: PropTypes.element,
  /** is Done  status of MatchAssetAccordionHeader. */
  isDone: PropTypes.bool,
  /** title of MatchAssetAccordionHeader. */
  title: PropTypes.string,
  /** to show Alert status of MatchAssetAccordionHeader. */
  showAlert: PropTypes.bool
}
export default MatchAssetAccordionHeader
