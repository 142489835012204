import gql from 'graphql-tag'

export default gql(`
    query exportCsv($key: String, $project:String){
        exportCsv(key : $key project: $project) {
            bucket
            key
            region
            url
            uploadUrl
        }
    }`
)
