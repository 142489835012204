import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Input from '../inputs/Input'
import { utilityService } from '../../../../services/UtilityService'

import './../../ui.style.scss'

class ChangeCustomerEmailModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmEmail: '',
      isConfirmEmailError: false,
      isEdited: false
    }
  }

handleChangeConfirmEmail = (event) => {
  const confirmEmail = event.target.value
  this.setState({ confirmEmail, isEdited: true, isConfirmEmailError: false })
}

handleSubmit = () => {
  const { confirmEmail } = this.state
  let isValidEmail = utilityService.checkEmailValidation(confirmEmail)
  if (!isValidEmail) {
    this.setState({ isConfirmEmailError: true })
  } else {
    this.props.handleSubmit(confirmEmail)
  }
}

    clearState = () => {
      this.setState({
        confirmEmail: '',
        isEdited: false,
        isConfirmEmailError: false
      })
    }

    render () {
      const { isVisible, handleCancel, isLoading, isSubmitDisabled } = this.props
      const { isEdited, confirmEmail, isConfirmEmailError } = this.state
      return (
        <Modal
          className='general-modal change-email'
          title={'UPDATE EMAIL'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Cancel'
          onOk={this.handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (isSubmitDisabled || !isEdited || !confirmEmail || isConfirmEmailError) }}
          closable={false}
          centered
          destroyOnClose
          confirmLoading={isLoading}
          afterClose={this.clearState}
          width='350'
        >
          { isVisible
            ? <div className='change-email-container'>
              <Input
                title='Confirm New Email'
                inputName='confirmEmail'
                handleChange={this.handleChangeConfirmEmail}
                value={confirmEmail}
                placeholder='Confirm New Email'
                type={'email'}
                isError={isConfirmEmailError}
                errorMessage={`Enter Valid Email Address`} />
            </div> : null }
        </Modal>
      )
    }
}

ChangeCustomerEmailModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

ChangeCustomerEmailModal.defaultProps = {
}

export default ChangeCustomerEmailModal
