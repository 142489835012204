import gql from 'graphql-tag'

export default gql(`
    mutation c($user: String! $project: String){
        dismissAllNotification (
            user: $user
            project: $project
        ) {
             status
             error
        }
}`)
