import React, { Component } from 'react'

import { Empty, Skeleton } from 'antd'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import PropTypes from 'prop-types'

import AuthService from '../../services/AuthService'
import IngestHistoryCell from './historyCell/IngestHistoryCell'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListAudit from '../../graphQL/IngestManager/listAudits'

import '../auditManager/AuditManager.scss'

const width = ['100px', '100%', '100%']

class IngestHistoryList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      historyList: [],
      isLoading: true
    }
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading) {
        this.setState({ historyList: newProps.historyList, isPaginating: false, isLoading: false })
      } else if (!_.isEqual(newProps.historyList, this.props.historyList)) {
        this.setState({ historyList: newProps.historyList, isPaginating: false, isLoading: false })
      }

      if (!newProps.isChanging && this.props.isChanging) {
        if (newProps.refetchHistoryList) {
          newProps.refetchHistoryList()
          setTimeout(newProps.refetchHistoryList, 1000)
        }
      }
    }

    componentDidMount = () => {
      this.setState({ historyList: this.props.historyList })
    }

    getUserDetails = async () => {
      const userDetails = await AuthService.getUserDetails()
      if (userDetails) {
        this.username = userDetails.name || userDetails.email
      }
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.historyList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => {
        this.props.gethistoryList(this.state.historyList.length)
      })
    }

    render () {
      const { isLoading, isPaginating, historyList } = this.state
      const { content } = this.props
      return (
        <div className={`history-list`}>
          {content === 'INGEST_MANAGER' ? <div className='ingest-history-heading ingest-history-general'>
            <div className='date'> Date </div>
            <div className='date'> User </div>
            <div className='date'> Category </div>
          </div> : null}
          {(isLoading) && !isPaginating
            ? <div className={`table`}>{[...Array(7)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading key={i} />)}
            </div>
            : <div className='table'>
              { (!isLoading) || isPaginating ? <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMoreAsset}
                hasMore={this.props.totalCount > (historyList || []).length}
                initialLoad={false}
                useWindow={false}
              >
                { historyList && historyList.length ? historyList.filter(history => !(history.action === 'UPDATE' && (!history.updatedObj || !history.updatedObj.length))).map((history, index) => {
                  return (<IngestHistoryCell content={content} details={history} key={index} />)
                }) : <Empty /> }
              </InfiniteScroll> : null }
              { isPaginating && this.props.totalCount > historyList.length ? [...Array(2)].map((e, i) => <Skeleton active title={false} paragraph={{ rows: 3, width: width }} loading key={i} />) : null }
            </div>}
        </div>
      )
    }
}

IngestHistoryList.propTypes = {
/** ingest Client configuration */
  IngestClient: PropTypes.object,
  /** current project */
  project: PropTypes.string,
  /** module name to get list of audits */
  content: PropTypes.string

}
export default withApollo(compose(
  graphql(
    QueryListAudit,
    {
      options: (props) => {
        const { selectedJobId, content, project, IngestClient } = props
        const variables = { contentId: selectedJobId, limit: 20, module: content, project }
        return {
          fetchPolicy: 'network-only',
          client: IngestClient,
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const project = data.variables && data.variables.project ? data.variables.project : ''
        const historyList = data.listAudits ? data.listAudits.items : []
        return {
          historyList,
          isLoading: data.loading || !data.listAudits,
          totalCount: data.listAudits ? data.listAudits.totalCount : 0,
          gethistoryList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [ ...prev.listAudits.items, ...fetchMoreResult.listAudits.items ]
                prev.listAudits.items = newList
                return prev
              }
            })
          },
          refetchHistoryList: () => {
            const { contentId, project } = props.ownProps
            const variables = { contentId, limit: 20, project }
            return props.data.refetch(variables)
          }
        }
      }
    }))(withRouter(IngestHistoryList)))
