import React, { Component } from 'react'
import { Empty, message, Skeleton } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import RetryButton from '../../components/ui/general/buttons/RetryButton'
import AssetListCell from '../../components/ui/dataDisplay/AssetListCell'
import { generateCroppedThumbnail } from '../../util/util'
import Button from '../../components/ui/general/buttons/Button'
import { utilityService } from '../../services/UtilityService'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryPresignedCsvUrl from '../../graphQL/IngestManager/getPresignedCsvUrl'
import MutationRetryIngestJob from '../../graphQL/IngestManager/retryIngestJob'
import MutationGracenoteIngestJob from '../../graphQL/IngestManager/retryGracenoteIngestJob'

const width = ['100px', '100%', '100%']

class IngestJobDetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isRetryJobDisable: false,
      isRetryJobLoading: false,
      retcreateWarning: false,
      assetList: props.selectedIngestedJobDetails && props.selectedIngestedJobDetails.successful ? _.sortBy(props.selectedIngestedJobDetails && props.selectedIngestedJobDetails.successful, ['id']) : []
    }
  }

  componentDidMount = () => {
    this.checkRetriggerStatus(this.props)
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    const status = nextProps && nextProps.selectedIngestedJob && nextProps.selectedIngestedJob.status
    if (status === 'COMPLETE') {
      if (this.props.fetchJobDetails) {
        this.props.fetchJobDetails()
      }
    }
    this.checkRetriggerStatus(nextProps)
    if (!_.isEqual(_.sortBy(nextProps.selectedIngestedJobDetails && nextProps.selectedIngestedJobDetails.successful, ['id']), _.sortBy(this.props.selectedIngestedJobDetails && this.props.selectedIngestedJobDetails.successful, ['id']))) {
      this.setState({
        assetList: _.sortBy(nextProps.selectedIngestedJobDetails && nextProps.selectedIngestedJobDetails.successful, ['id'])
      })
    }
  }

  checkRetriggerStatus = (nextProps) => {
    const status = nextProps && nextProps.selectedIngestedJob && nextProps.selectedIngestedJob.status
    const retryJobId = nextProps && nextProps.selectedIngestedJob && nextProps.selectedIngestedJob.retryJobId
    if (status === 'PROCESSING' || retryJobId) {
      this.setState({
        isRetryJobDisable: true
      })
    } else {
      this.setState({
        isRetryJobDisable: false
      })
    }
  }

  makeCsvFIle = () => {
    const { selectedIngestedJob } = this.props
    const status = selectedIngestedJob && selectedIngestedJob.status
    const assetIds = selectedIngestedJob && selectedIngestedJob.successful && selectedIngestedJob.successful.map(asset => asset.id)
    const jobType = this.props.handleCustomJobTypeName(selectedIngestedJob['jobType'])
    const errorMessage = selectedIngestedJob && selectedIngestedJob.errors && selectedIngestedJob.errors.map(error => {
      return {
        errorMessage: error.errorMessage,
        rowId: error.rowId
      }
    })
    let field = ['rowId', 'assetId', 'status', 'message']
    let csvContent = 'BatchID,' + selectedIngestedJob['batchID'] + '\n' + 'Source,' + selectedIngestedJob['source'] +
                      '\n' + 'Start Time,' + selectedIngestedJob['createdAt'] + '\n' + 'Status,' + selectedIngestedJob['status'] + '\n' +
                      'Job Type,' + jobType + '\n' + '\n'

    if (status !== 'FAILED' && status !== 'PARTIAL') {
      csvContent += field.toString() + '\n'
      for (const id of assetIds) {
        csvContent += ',' + id + ',success,' + '\n'
      }
    } else if (status === 'PARTIAL') {
      csvContent += field.toString() + '\n'
      if (errorMessage) {
        for (const error of errorMessage) {
          csvContent += error.rowId + ',' + ',' + ',' + error.errorMessage + '\n'
        }
      }
      for (const id of assetIds) {
        csvContent += ',' + id + ',success,' + '\n'
      }
    } else {
      csvContent += field.toString() + '\n'
      for (const error of errorMessage) {
        csvContent += error.rowId + ',' + ',' + ',' + error.errorMessage + '\n'
      }
    }

    var exportedFilenmae = 'report.csv'
    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  handleJobRecreateSubmit = () => {
    this.setState({
      isRetryJobLoading: true
    })
    const { selectedIngestedJob, project } = this.props
    const csvS3Key = selectedIngestedJob && selectedIngestedJob.csvPayload
    const batchID = selectedIngestedJob && selectedIngestedJob.batchID
    if (selectedIngestedJob.source === 'gracenote') {
      this.props.retryGracenoteIngestJob(batchID, project).then(({ data }) => {
        this.props.refetchHistory()
        message.success('Retry Job Done Successful')
        this.setState({
          isRetryJobLoading: false,
          retcreateWarning: false,
          isRetryJobDisable: true
        })
      }, errorMsg => {
        this.setState({ isRetryJobLoading: false, retcreateWarning: false, isRetryJobDisable: true })
        utilityService.handleError(errorMsg)
      })
    } else {
      this.props.retryIngestJob(csvS3Key, batchID, project).then(({ data }) => {
        this.props.refetchHistory()
        message.success('Retry Job Done Successful')
        this.setState({
          isRetryJobLoading: false,
          retcreateWarning: false,
          isRetryJobDisable: true
        })
      }, errorMsg => {
        this.setState({ isRetryJobLoading: false, retcreateWarning: false, isRetryJobDisable: true })
        utilityService.handleError(errorMsg)
      })
    }
  }

  toggleRecreateWarning = (status) => {
    this.setState({
      retcreateWarning: status
    })
  }

  render () {
    const { selectedIngestedJob, project, getPresignedCsvUrl, isCreateDisable, isUpdateDisabled, isPublishDisable, isLoading, isArchiveDisable, isUnpublishDisable,
      isUnpublishLoading, unpublisheWarning, archiveWarning, isArchiveLoading, handleArchiveAllSubmit, handleUnpublishAllSubmit, toggleArchiveWarning, toggleUnpublisheWarning } = this.props
    const { isRetryJobDisable, isRetryJobLoading, retcreateWarning, assetList } = this.state
    const createAt = moment(selectedIngestedJob && selectedIngestedJob.createdAt).format('DD MMM YYYY, HH:mm')
    const status = selectedIngestedJob && selectedIngestedJob.status
    const isDownloadSummayDisable = status === 'PROCESSING' || status === 'QUEUED'
    const retryJobId = !!(selectedIngestedJob && selectedIngestedJob.retryJobId)
    const isArchiveButtonDisable = !!(isArchiveDisable === true || isUpdateDisabled === true)
    const isUnPublishButtonDisable = !!(isUnpublishDisable === true || isPublishDisable === true)
    const isRetryButtonDisable = !!((isRetryJobDisable || retryJobId) === true || isCreateDisable === true)
    const jobType = this.props.handleCustomJobTypeName(selectedIngestedJob && selectedIngestedJob.jobType ? selectedIngestedJob.jobType : ' ')
    return (
      <div className='ingest-item-info'>
        {!isLoading
          ? <React.Fragment>
            <div className='ingest-item-job-info'>
              <div>
                <div>Batch ID:</div>
                <span>{selectedIngestedJob && selectedIngestedJob.batchID ? selectedIngestedJob.batchID : '-'}</span>
              </div>
              <div>
                <div>Source:</div>
                <span>{selectedIngestedJob && selectedIngestedJob.source ? selectedIngestedJob.source : '-'}</span>
              </div>
              <div>
                <div>Start Time:</div>
                <span>{createAt || '-'}</span>
              </div>
              <div className='ingest-item-status'>
                <div>Status:</div>
                <span className={status === 'COMPLETE' ? 'complete' : status === 'PARTIAL' ? 'partial' : status === 'PROCESSING' ? 'processing' : status === 'FAILED' ? 'failed' : 'queued'}>{selectedIngestedJob && selectedIngestedJob.status ? selectedIngestedJob.status : '-'}</span>
              </div>
              <div>
                <div>Job Type:</div>
                <span>{jobType}</span>
              </div>
              <div className='ingest-item-buttons'>
                <p><span className={`download-summary ${isDownloadSummayDisable ? 'disable' : ''}`} onClick={() => this.makeCsvFIle()}>Download Summary </span> { selectedIngestedJob && selectedIngestedJob.csvPayload !== null ? <span className={`${isUpdateDisabled ? 'disable' : 'active'}`}> |  <a className={`download-payload`} href={getPresignedCsvUrl} download> Download Payload</a></span> : ''}</p>
                <ButtonContainer displayTitle='Recreate selected job' placement='topRight' childComponent={<RetryButton isDisabled={isRetryButtonDisable} onClick={() => this.toggleRecreateWarning(true)} />} />
              </div>
            </div>
            <div className='ingest-item-assets-container'>
              <div className='ingest-item-assets'>
                <div className='title'>
                  <span>ASSETS UPDATED</span>
                </div>
                {assetList ? (assetList || []).map(asset => {
                  let imageUrl
                  if (asset.defaultMedia) {
                    imageUrl = generateCroppedThumbnail(asset.defaultMedia, 87, 48, '16:9')
                  }
                  return (<AssetListCell
                    key={asset.id}
                    assetType={asset.type}
                    imageUrl={imageUrl}
                    status={asset.status}
                    date={moment(new Date(asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                    assetName={asset.title}
                    assetId={asset.id}
                    displayId={asset.shortId || asset.id}
                    onSelect={() => console.log('test')}
                    isFree={asset.isFree}
                    // isUpdateDisabled={isUpdateDisabled}
                    project={project}
                    asset={asset}
                  />)
                }
                ) : <Empty /> }
              </div>
              <div className='ingest-info-footer'>
                <Button buttonText='Archive All' isDisabled={isArchiveButtonDisable} key='archive' onClick={() => toggleArchiveWarning(true)} />
                <Button buttonText='Unpublish All' isDisabled={isUnPublishButtonDisable} key='unpublish' onClick={() => toggleUnpublisheWarning(true)} />
              </div>
            </div>
          </React.Fragment>
          : <Skeleton active title={false} paragraph={{ rows: 10, width: width }} />}
        <ConfirmModal isVisible={archiveWarning} title={'Archive All'} message={'Are you sure you want to archive all the assets of selected job?'} rightButtonText={'Confirm'} handleCancel={(e) => toggleArchiveWarning(false, e)} handleSubmit={handleArchiveAllSubmit} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} isLoading={isArchiveLoading} />
        <ConfirmModal isVisible={unpublisheWarning} title={'Unpublish All'} message={'Are you sure you want to Unpublish all the assets of selected job?'} rightButtonText={'Confirm'} handleCancel={(e) => toggleUnpublisheWarning(false, e)} handleSubmit={handleUnpublishAllSubmit} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} isLoading={isUnpublishLoading} />
        <ConfirmModal isVisible={retcreateWarning} title={'Recreate Job'} message={'Are you sure you want to recreate the job?'} rightButtonText={'Confirm'} handleCancel={(e) => this.toggleRecreateWarning(false, e)} handleSubmit={this.handleJobRecreateSubmit} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} isLoading={isRetryJobLoading} />
      </div>

    )
  }
}

IngestJobDetails.propTypes = {
  /** selected job details */
  selectedIngestedJob: PropTypes.object,
  /** mutation function to archive asset */
  batchArchiveAssets: PropTypes.func,
  /** mutation function to unpublish asset */
  batchUnpublishAssets: PropTypes.func,
  /** selected project field */
  project: PropTypes.string,
  /** ingest client */
  IngestClient: PropTypes.object,
  /** boolean to check the update access */
  isUpdateDisabled: PropTypes.bool,
  /** boolean to check the create access */
  isCreateDisable: PropTypes.bool,
  /** function to handle jobType custom name */
  handleCustomJobTypeName: PropTypes.func,
  /** function to refetch history */
  refetchHistory: PropTypes.func,
  /** boolean to check the pubilsh access */
  isPublishDisable: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryPresignedCsvUrl,
    {

      skip: ({ selectedIngestedJob }) => {
        const csvPayloadKey = selectedIngestedJob && selectedIngestedJob.csvPayload
        const jobType = selectedIngestedJob && selectedIngestedJob.jobType
        return csvPayloadKey === null || jobType !== 'CSV_ASSET_INGEST'
      },
      options: (props) => {
        const { project, IngestClient, selectedIngestedJob } = props
        const csvPayloadKey = selectedIngestedJob && selectedIngestedJob.csvPayload
        return {
          fetchPolicy: 'network-only',
          client: IngestClient,
          variables: { key: csvPayloadKey, project: project }
        }
      },
      props: (props) => {
        return {
          getPresignedCsvUrl: props.data && props.data.getPresignedCsvUrl && props.data.getPresignedCsvUrl[0] ? props.data && props.data.getPresignedCsvUrl && props.data.getPresignedCsvUrl[0] && props.data.getPresignedCsvUrl[0].url : []
        }
      }
    }
  ),
  graphql(
    MutationRetryIngestJob,
    {
      options: ({ IngestClient }) => {
        return {
          client: IngestClient
        }
      },
      props: (props) => ({
        retryIngestJob: (csvS3Key, batchJobId, project) => {
          return props.mutate({
            variables: { csvS3Key, batchJobId, project }
          })
        }
      })
    }
  ),
  graphql(
    MutationGracenoteIngestJob,
    {
      options: ({ IngestClient }) => {
        return {
          client: IngestClient
        }
      },
      props: (props) => ({
        retryGracenoteIngestJob: (batchJobId, project) => {
          return props.mutate({
            variables: { batchJobId, project }
          })
        }
      })
    }
  )
)(IngestJobDetails))
