import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import moment from 'moment'

import { Skeleton, message } from 'antd'
import AppContext from '../../AppContext'

import EditButton from './../../components/ui/general/buttons/EditButton'
import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
import RestoreButton from './../../components/ui/general/buttons/RestoreButton'
import Accordion from './../../components/ui/dataDisplay/Accordion'
import AspectRatioList from './../../components/ui/dataDisplay/AspectRatioList'
import Tag from './../../components/ui/dataDisplay/Tag'
import PreviewImageModal from './../../components/ui/dataEntry/other/PreviewImageModal'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import AddImageModal from './../../components/ui/dataEntry/other/AddImageModal'
import EditImageModal from './../../components/ui/dataEntry/other/EditImageModal'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import DownloadButton from '../../components/ui/general/buttons/DownloadButton'
import DefaultImageButton from '../../components/ui/general/buttons/DefaultImageButton'
import EncoderGroupTile from '../../components/ui/dataDisplay/EncoderGroupTile'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryMediaDetails from './../../graphQL/content/getMedia'
import MutationDeleteMedia from './../../graphQL/content/deleteMedia'
import MutationUpdateMedia from './../../graphQL/content/updateMedia'
import QueryFilterMedia from '../../graphQL/content/filterMedia'
import QueryTagType from './../../graphQL/admin/tagType/listTagType'

import { generateCroppedThumbnail } from './../../util/util'
import { utilityService } from '../../services/UtilityService'
import userMessages from './../../constants/messages'
import './ContentManager.scss'
import CopyUrlButton from '../../components/ui/general/buttons/CopyUrlButton'

const folderImage = require('../../assets/images/folder.svg')

const deleteWarningMessage = {
  message: userMessages.CONTENT_DELETE_CONFIRMATION,
  title: 'ARCHIVE ?'
}

const restoreWarningMessage = {
  message: userMessages.CONTENT_RESTORE_CONFIRMATION,
  title: 'RESTORE ?'
}

class MediaDetails extends Component {
  state = {
    visible: false,
    url: '',
    imageHeight: '',
    imageWidth: '',
    showEditMedia: false,
    selectedPreviewImage: {},
    showCropModal: false,
    shouldShowWarning: false
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.selectedMedia && newProps.mediaDetails.id === newProps.selectedMedia) {
      NProgress.done()
    } else if (!newProps.selectedMedia) {
      NProgress.done()
    }
    if (this.props.mediaDetails && newProps.mediaDetails && this.props.mediaDetails.id !== newProps.mediaDetails.id) {
      this.props.setMediaDetails({ details: newProps.mediaDetails, isLoading: newProps.isLoading })
    }
  }

ratioSizes = (x, y) => {
  const w = (68 / 100) * window.innerWidth
  const h = (68 / 100) * window.innerHeight
  let width, height

  if (x <= w && y <= h) {
    return { width: parseInt(x), height: parseInt(y) }
  }
  if (x > y) {
    width = w
    height = (y * width) / x
  }
  if (x < y) {
    height = h
    width = (height * x) / y
  }
  if (x === y) {
    height = h
    width = h
  }
  return { width: parseInt(width), height: parseInt(height) }
}

  handleDelete = () => {
  }

  showImageEditor = () => {
    this.setState({ showEditMedia: true })
  }

  hideImageEditor = () => {
    this.setState({ showEditMedia: false })
  }

  handleCancelPreview = (e) => {
    this.setState({
      visible: false
    })
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  toggleRestoreWarningMessage = status => {
    this.setState({ shouldShowRestoreWarning: status })
  }

  deleteCurrentContent = () => {
    if (this.state.isDeleting) {
      return
    }
    this.setState({ isDeleting: true })
    this.props.onDeleteMedia(this.props.mediaDetails.id)
    this.props.deleteMedia(this.props.mediaDetails.id).then(() => {
      this.setState({ isDeleting: false })
      this.toggleWarningMessage(false)
    }, error => {
      this.setState({ isDeleting: false })
      this.toggleWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  restoreCurrentContent = () => {
    if (this.state.isRestoring) {
      return
    }
    this.props.onDeleteMedia(this.props.mediaDetails.id)
    this.setState({ isRestoring: true })
    this.props.restoreMedia(this.props.mediaDetails.id).then(() => {
      this.toggleRestoreWarningMessage(false)
      this.setState({ isRestoring: false })
    }, error => {
      this.setState({ isRestoring: false })
      this.toggleRestoreWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  handleCancel = () => {
    this.setState({ showImageModal: false })
  }

  addImage = ([selectedImage]) => {
    const { mediaDetails } = this.props
    let settings = _.cloneDeep(selectedImage.settings)
    settings.map(setting => {
      setting.outputFormat = 'JPG'
      delete setting['__typename']
    })
    this.props.updateImage(mediaDetails.id, mediaDetails.name, mediaDetails.tags ? mediaDetails.tags.filter(item => item).map(tag => tag.key) : [], settings, selectedImage.fileName).then(data => {

    }, error => {
      utilityService.handleError(error)
    })
    this.setState({ showImageModal: false })
  }

  onResolutionSelect = (selectedPreviewImage) => {
    this.setState({
      visible: true,
      selectedPreviewImage
    })
  }

  downloadOriginalImage = () => {
    const { mediaDetails } = this.props
    const href = mediaDetails.fileName
    var anchor = document.createElement('a')
    anchor.href = href
    anchor.download = mediaDetails.name
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  getFormattedStreamDetails = stream => {
    const streamData = {
      levels: [
        { name: stream.drm },
        { name: stream.transport },
        { name: stream.codec }
      ]
    }
    return streamData
  }

  showImgEditor = (shouldShow) => {
    this.setState({ shouldShow })
  }

  updateImageStaticUrl = (str) => {
    let url = str
    if (url.includes('https://cms-uat.optussport.tv/resources/static')) {
      url = url.replace('https://cms-uat.optussport.tv/resources/static', 'https://static.sport-pp.optus.com.au/resources/static')
    }

    if (url.includes('https://static.cms-uat.optussport.tv/resources/static')) {
      url = url.replace('https://static.cms-uat.optussport.tv/resources/static', 'https://static.sport-pp.optus.com.au/resources/static')
    }

    if (url.includes('https://cms.optussport.tv/resources/static')) {
      url = url.replace('https://cms.optussport.tv/resources/static', 'https://static.sport.optus.com.au/resources/static')
    }

    if (url.includes('https://static.cms.optussport.tv/resources/static')) {
      url = url.replace('https://static.cms.optussport.tv/resources/static', 'https://static.sport.optus.com.au/resources/static')
    }

    return url
  }

  copyToClipboard = () => {
    let textField = document.createElement('textarea')
    const url = (this.props.mediaDetails && this.props.mediaDetails.fileName) || ''
    const enhanceURL = this.updateImageStaticUrl(url)
    textField.innerText = enhanceURL
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    message.success('Image Static URL copied to clipboard')
  }

  render () {
    const { mediaDetails, isLoading, selectedMedia, isFolder, folderData, systemTags } = this.props
    const { selectedPreviewImage, showEditMedia, shouldShowWarning, shouldShowRestoreWarning, isDeleting, isRestoring, showImageModal, shouldShow } = this.state
    const imageUrl = mediaDetails && mediaDetails.aspectRatio && mediaDetails.aspectRatio.length ? generateCroppedThumbnail(mediaDetails, 500, 281, '16:9') : undefined
    return <AppContext.Consumer>
      {({ permissions, project }) => {
        const userPermissions = permissions['CONTENT_BANK']
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
        return <React.Fragment>
          <div className='media-details'>
            { isFolder && !_.isEmpty(folderData)
              ? <React.Fragment>
                <div className={'view folder-display-image'} >
                  <img className='media-image' src={isFolder ? folderImage : ''} />
                </div>
                <div className='contents'>
                  <div className='content-data'>
                    <p>Content ID:</p>
                    <p>{folderData.id }</p>
                  </div>
                  <div className='content-data'>
                    <p>Name:</p>
                    <p>{folderData.name }</p>
                  </div>
                  { folderData.tags && folderData.tags.length
                    ? <div className='content-data'>
                      <p>Tags:</p>
                      <div>
                        {folderData.tags.filter(item => item).map((tag, index) => <Tag key={index} tagText={tag.name} />) }
                      </div>
                    </div>
                    : null }
                  <div className='content-data last-child'>
                    <p>Created At:</p>
                    <p>{ moment(new Date(folderData.createdAt)).format('DD MMM YYYY, hh:mm A') }</p>
                  </div>
                </div>
              </React.Fragment>
              : selectedMedia && mediaDetails && mediaDetails.id && !isLoading ? (
                <React.Fragment>
                  { mediaDetails.type === 'IMAGE' ? <div className={`${(shouldShow && !isUpdateDisabled) ? 'edit' : 'view'}`} onMouseEnter={() => this.showImgEditor(true)} onMouseLeave={() => this.showImgEditor(false)}>
                    <img className='media-image' src={imageUrl} />
                    { (shouldShow && !isUpdateDisabled) ? <div ><ButtonContainer displayTitle='Replace Image' childComponent={<DefaultImageButton onClick={() => this.setState({ showImageModal: true })} />} /> </div> : null }
                  </div> : null }

                  <div className='contents'>
                    <div className='content-data'>
                      <p>Content ID:</p>
                      <p>{mediaDetails.id }</p>
                    </div>
                    <div className='content-data'>
                      <p>Name:</p>
                      <p>{mediaDetails.name }</p>
                    </div>
                    <div className='content-data'>
                      <p>Content Type:</p>
                      <p>{mediaDetails.type}</p>
                    </div>
                    {mediaDetails.type === 'VIDEO'
                      ? <div>
                        <div className='content-data'>
                          <p>AdPositions:</p>
                          <p>{mediaDetails.adPositions && mediaDetails.adPositions.length ? mediaDetails.adPositions : '-'}</p>
                        </div>
                        <div className='content-data'>
                          <p>CuePoints:</p>
                          <p>{mediaDetails.cuePoints && mediaDetails.cuePoints.length ? mediaDetails.cuePoints : '-'}</p>
                        </div>
                        <div className='content-data'>
                          <p>BreakDuration:</p>
                          <p>{mediaDetails.breakDuration && mediaDetails.breakDuration.length ? mediaDetails.breakDuration : '-'}</p>
                        </div>
                      </div>
                      : null }
                    {mediaDetails.type === 'IMAGE' ? <div className='content-data'>
                      <p>Output Format:</p>
                      <p>JPG</p>
                    </div> : null}
                    { mediaDetails.tags && mediaDetails.tags.length
                      ? <div className='content-data'>
                        <p>Tags:</p>
                        <div>
                          {(mediaDetails.tags || []).filter(item => {
                            if (item && item.type) {
                              const foundValue = (systemTags || []).find(tagType => {
                                if (tagType && tagType.name && tagType.name === item.type && tagType.isMediaEnabled === true) {
                                  return item
                                }
                              })
                              return foundValue
                            }
                          }).map((tag, index) => <Tag key={index} tagText={tag.name} />) }
                        </div>
                      </div>
                      : null }
                    <div className='content-data'>
                      <p>Created At:</p>
                      <p>{ moment(new Date(mediaDetails.createdAt)).format('DD MMM YYYY, hh:mm A') }</p>
                    </div>
                    <div className='content-data last-child'>
                      <p>Updated At:</p>
                      { mediaDetails.updatedAt ? <p> { moment(new Date(mediaDetails.updatedAt)).format('DD MMM YYYY, hh:mm A') } </p> : <p> { moment(new Date(mediaDetails.createdAt)).format('DD MMM YYYY, hh:mm A') }</p> }
                    </div>
                  </div>
                  { mediaDetails.type === 'IMAGE' ? (mediaDetails.aspectRatio || []).filter(item => item.aspectRatio).sort((a, b) => a.ratio - b.ratio).map((aspectRatio) => (
                    <Accordion key={aspectRatio.title} title={aspectRatio.title} childComponent={<AspectRatioList details={mediaDetails} aspects={aspectRatio.resolutions} title={aspectRatio.title} onResolutionSelect={this.onResolutionSelect}
                      selectedAspect={aspectRatio.aspectRatio} />} />
                  )) : null}
                  { mediaDetails.type === 'VIDEO' ? (mediaDetails.streams || []).filter(item => item).map((stream) => {
                    const streamData = this.getFormattedStreamDetails(stream)
                    return <EncoderGroupTile url={stream.Url} details={streamData} />
                  }) : null }
                </React.Fragment>)
                : (isLoading && selectedMedia ? <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 4, width: '100%' }} /> : null)
            }
            { this.state.visible ? <PreviewImageModal
              previewImage={selectedPreviewImage.url}
              visible={this.state.visible}
              imageName={mediaDetails.name}
              imageRatio={selectedPreviewImage.aspectRatio}
              onClose={this.handleCancelPreview}
              imageSize={`${selectedPreviewImage.width}x${selectedPreviewImage.height}`}
              size={this.ratioSizes(selectedPreviewImage.width, selectedPreviewImage.height)}

            /> : null }
            <AddImageModal isVisible={showImageModal} handleSubmit={this.addImage} handleCancel={this.handleCancel} currentSelection={[mediaDetails]} shouldCacheLoad mediaType='IMAGE' isSubmitButtonDisabled={isCreateDisabled} project={project} />
            <EditImageModal type={mediaDetails ? mediaDetails.type : undefined} isVisible={showEditMedia} hideImageEditor={this.hideImageEditor} imageId={showEditMedia ? selectedMedia : ''} mediaDetails={mediaDetails} isUploadBlocked={isCreateDisabled} project={project} />
          </div>
          { selectedMedia && mediaDetails && mediaDetails.id && !isLoading ? (<div className='media-footer'>
            { mediaDetails.isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={() => this.toggleRestoreWarningMessage(true)} isDisabled={isUpdateDisabled} />} /> : <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={() => this.toggleWarningMessage(true)} isDisabled={isUpdateDisabled} />} /> }
            { mediaDetails.type === 'IMAGE' ? <ButtonContainer displayTitle='Download' childComponent={<DownloadButton onClick={this.downloadOriginalImage} />} /> : null }
            { mediaDetails.type === 'IMAGE' ? <ButtonContainer displayTitle='Copy Static URL' childComponent={<CopyUrlButton onClick={this.copyToClipboard} />} /> : null }
            {/* </span> */}
            <ConfirmModal isVisible={shouldShowRestoreWarning} title={restoreWarningMessage.title} message={restoreWarningMessage.message} isLoading={isRestoring} rightButtonText={'Confirm'} handleCancel={() => this.toggleRestoreWarningMessage(false)} handleSubmit={this.restoreCurrentContent} />
            <ConfirmModal isVisible={shouldShowWarning} title={deleteWarningMessage.title} message={deleteWarningMessage.message} isLoading={isDeleting} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.toggleWarningMessage(false)} handleSubmit={this.deleteCurrentContent} />
            <ButtonContainer displayTitle='Edit' childComponent={<EditButton onClick={this.showImageEditor} isDisabled={isUpdateDisabled} />} />

          </div>) : null }
        </React.Fragment>
      }}
    </AppContext.Consumer>
  }
}
MediaDetails.propTypes = {
  /** media details of MediaDetails. */
  mediaDetails: PropTypes.object,
  /** loading status of MediaDetails. */
  isLoading: PropTypes.bool,
  /** delete media  MediaDetails. */
  onDeleteMedia: PropTypes.func
}

export default withApollo(
  compose(
    graphql(
      QueryMediaDetails,
      {
        skip: ({ selectedMedia }) => !selectedMedia,
        options: ({ selectedMedia, project }) => {
          return {
            variables: { id: selectedMedia, project }
          }
        },
        props: ({ data }) => {
          return { mediaDetails: data.getMedia ? { ...data.getMedia } : {}, isLoading: data.loading }
        }
      }
    ),
    graphql(
      MutationUpdateMedia, {
        props: (props) => ({
          updateImage: (id, name, tags, settings, fileName) => {
            return props.mutate({
              variables: { id, name, tags, settings, fileName, updatedAt: new Date().toISOString(), project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationDeleteMedia,
      {
        props: (props) => ({
          deleteMedia: (id) => {
            return props.mutate({
              variables: { id,
                isArchived: true,
                type: { match: 'IMAGE' },
                updatedAt: new Date().toISOString(),
                project: props.ownProps.project
              }
            })
          }
        }),
        options: (props) => ({
          update: (cache, { data: { updateMedia } }) => {
            const variables = utilityService.getFormattedMediaFilter(props.appliedFilter.searchString, props.appliedFilter.filter, null, props.project)
            const { filter } = variables
            const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterMedia, variables }))
            if (filter && filter.isArchived && filter.isArchived.match === true) {
              if (listCacheData && listCacheData.listMedia && listCacheData.listMedia.items) {
                listCacheData.listMedia.items.splice(0, 0, updateMedia)
                listCacheData.listMedia.totalCount = listCacheData.listMedia.totalCount + 1
              }
              cache.writeQuery({
                query: QueryFilterMedia,
                data: listCacheData,
                variables
              })
            } else {
              if (listCacheData && listCacheData.listMedia && listCacheData.listMedia.items) {
                const list = listCacheData.listMedia.items.filter((item) => item.id !== updateMedia.id)
                if (list.length !== listCacheData.listMedia.items.length) {
                  listCacheData.listMedia.items = list
                  listCacheData.listMedia.totalCount = listCacheData.listMedia.totalCount - 1
                }
              }
              cache.writeQuery({
                query: QueryFilterMedia,
                data: listCacheData,
                variables
              })
            }
          }
        })
      }
    ),
    graphql(
      MutationDeleteMedia,
      {
        props: (props) => ({
          restoreMedia: (id) => {
            return props.mutate({
              variables: { id,
                isArchived: false,
                type: { match: 'IMAGE' },
                updatedAt: new Date().toISOString(),
                project: props.ownProps.project
              }
            })
          }
        }),
        options: (props) => ({
          update: (cache, { data: { updateMedia } }) => {
            const variables = utilityService.getFormattedMediaFilter(props.appliedFilter.searchString, props.appliedFilter.filter, null, props.project)
            const { filter } = variables
            const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterMedia, variables }))
            if (filter && filter.isArchived && (filter.isArchived.match === true || filter.isArchived.match === 'true')) {
              if (listCacheData && listCacheData.listMedia && listCacheData.listMedia.items) {
                const list = listCacheData.listMedia.items.filter((item) => item.id !== updateMedia.id)
                if (list.length !== listCacheData.listMedia.items.length) {
                  listCacheData.listMedia.items = list
                  listCacheData.listMedia.totalCount = listCacheData.listMedia.totalCount - 1
                }
              }
              cache.writeQuery({
                query: QueryFilterMedia,
                data: listCacheData,
                variables
              })
            } else {
              if (listCacheData && listCacheData.listMedia && listCacheData.listMedia.items) {
                listCacheData.listMedia.items.splice(0, 0, updateMedia)
                listCacheData.listMedia.totalCount = listCacheData.listMedia.totalCount + 1
              }
              cache.writeQuery({
                query: QueryFilterMedia,
                data: listCacheData,
                variables
              })
            }
          }
        })
      }
    ),
    graphql(
      QueryTagType,
      {
        options: ({ project }) => {
          return {
            variables: { filter: null, limit: 500, project }
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    )
  )(MediaDetails)
)
