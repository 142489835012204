import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getStreamSchema(id: $id) {
        id
        levels {
            id
            group
            name
        }
    }
}`)
