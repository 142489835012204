import gql from 'graphql-tag'

export default gql(`
query ($nextContinuationToken: String $delimiter: String $prefix: String $maxKeys: Int $project: String){
    listS3Objects(
        input:{
            nextContinuationToken: $nextContinuationToken,
            delimiter: $delimiter,
            prefix: $prefix
            maxKeys: $maxKeys
        }
        project: $project
    ) {
        IsTruncated
        Name
        NextContinuationToken
        CommonPrefixes{
            Prefix
        }
        Contents {
            Key
            LastModified,
            Size
        }
      }
}`)
