import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Modal } from 'antd'
import AddBucketContainer from './AddBucketContainer'

class AddBucketConfigAppModal
  extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  resetState = () => {
    this.setState({

    })
  }

  render () {
    const { handleCancel, isVisible, handleBucketSelection, selectedBucket, handleSave, project } = this.props
    return (
      <div>
        <Modal
          className='confirm-modal add-bucket-config'
          title={'Select Bucket'}
          visible={isVisible}
          okText='Save'
          okButtonProps={false}
          onOk={handleSave}
          onCancel={handleCancel}
          closable={false}
          confirmLoading={false}
          destroyOnClose
          maskClosable={false}
          centered
          width='890px'
        >
          <AddBucketContainer handleBucketSelection={handleBucketSelection} selectedBucket={selectedBucket} project={project} />
        </Modal>

      </div>
    )
  }
}

AddBucketConfigAppModal.propTypes = {

}

AddBucketConfigAppModal.defaultProps = {}

export default AddBucketConfigAppModal
