import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import './../../ui.style.scss'

const Option = Select.Option

const DropDown = ({ displayParam, valueParam, onOptionSelect, selectedValue, options, placeHolder, parent, className, allowClear, disabled, ...props }) => {
  const filteredOptions = (options || []).filter(item => item[valueParam || displayParam] !== selectedValue)
  let value = selectedValue
  const index = (options || []).findIndex(item => item[valueParam || displayParam] === selectedValue)
  if (index > -1) {
    value = (options || [])[index][displayParam || valueParam]
  }
  return (
    <Select
      className={className ? 'drop-down ' + className : 'drop-down'}
      value={value}
      placeholder={placeHolder}
      getPopupContainer={() => document.getElementById(parent)}
      onChange={onOptionSelect ? onOptionSelect.bind(this) : () => { }}
      filterOption={false}
      allowClear={value ? allowClear : false}
      suffixIcon={<ExpandedIcon />}
      clearIcon={<CrossIcon />}
      disabled={disabled}
    >
      {filteredOptions.map((option, index) => <Option key={option.id || index} value={option[valueParam || displayParam]}>{option[displayParam]}</Option>)}
    </Select>
  )
}

DropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** Selected value of Dropdown. */
  selectedValue: PropTypes.string,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Parent container for the dropdown. */
  parent: PropTypes.string.isRequired,
  /** Class name */
  className: PropTypes.string
}

DropDown.defaultProps = {
  selectedValue: 'Select',
  placeHolder: 'Select',
  parent: 'root',
  disabled: false
}

export default DropDown
