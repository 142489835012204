import gql from 'graphql-tag'

export default gql(`
    query listCriticalUsers($search: String $project: String){
        listCriticalUsers(input:{
          id: $search
        }, project: $project){
          items{
            id
            status
            name
            enabled
            status
            lastLogin
            accessRoleGroups {
              id
              name
              modules{
                name
                permissions
              }
            }
          }
        }
    }`
)
