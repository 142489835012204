import gql from 'graphql-tag'

export default gql(`
query searchPartnersQuery($offset: Int, $limit: Int,  $project: String) {
  listPartners(
      limit: $limit,
      offset: $offset,
      project: $project
    ) {
      items {
        id
        name 
        }
      totalCount
    }
}`)
