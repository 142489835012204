import React from 'react'
import PropTypes from 'prop-types'

import Input from './../inputs/Input'
import TagsInputContainer from './../inputs/TagsInputContainer'
import DropDown from './../inputs/DropDown'
import { Switch } from 'antd'
import './../../ui.style.scss'

const skippedTagsName = []

class EditImageDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTag: '',
      selectedIndex: undefined
    }
  }

    onTagSearch = (index, searchTag) => {
      this.setState({
        searchTag,
        selectedIndex: searchTag ? index : undefined
      })
    }

    render () {
      const { selectedFormat, formats, name, handleFormatChange, handleTextChange, selectedTags, type, categoryList, handleCategoryChange, selectedCategory, systemTags, isDrmEnabled, handleDrmChange, isDrmEditEnabled, project } = this.props
      const { searchTag } = this.state
      let categories = (categoryList || []).filter(item => item.type === type && item.isActive)
      if (selectedCategory && categories && categories.length) {
        const selected = categoryList.find(item => item.id === selectedCategory)
        if (selected.isActive === false) { categories.push(selected) }
      }
      return (
        <div className='edit-image-details'>
          <div className='edit-input-container'>
            <div className='edit-input-item'>
              <Input title='Name' placeholder='Image Name' inputName='title' value={name} handleChange={handleTextChange} isError={!name} isRequired />
            </div>
            { type === 'IMAGE' ? <div className='edit-input-item'>
              <div className='output-format' id='dropdown-format'>
                <label className='title-text'>Output Format</label>
                <DropDown options={formats} onOptionSelect={handleFormatChange} selectedValue={selectedFormat} valueParam='name' displayParam='name' placeHolder='Output format' parent={'dropdown-format'} />
              </div>
            </div> : null }
            { (categories && categories.length) ? <div className='edit-input-item'>
              <div className='output-format' id='dropdown-category'>
                <label className='title-text'>Category</label>
                <DropDown
                  options={categories}
                  onOptionSelect={handleCategoryChange}
                  selectedValue={selectedCategory}
                  valueParam='id'
                  displayParam='label'
                  placeHolder={`Select ${type === 'IMAGE' ? 'Image' : 'Video'} Category`}
                  parent={'dropdown-category'}
                  className={selectedCategory ? 'selected' : ''} />
              </div>
            </div> : null }
          </div>
          {type === 'IMAGE'
            ? <div className='system-tags'>
              {systemTags.filter((tag) => !skippedTagsName.includes(tag.name)).sort((a, b) => a.position - b.position).map((tag, index) => (
                <div className='input-field' key={index}>
                  <label className='input-title'>{tag.name}</label>
                  <TagsInputContainer
                    selectedItems={selectedTags.filter(item => item && (item.type === tag.name)).map(item => item.name)}
                    onChange={this.props.handleTagChange}
                    displayParameter='name'
                    placeholder='Insert Tags'
                    parentCompoent='edit-image-modal'
                    onSearch={this.onTagSearch.bind(this, index)}
                    searchTag={this.state.selectedIndex === index ? searchTag : undefined}
                    type={tag.name}
                    isCustom={tag.isCustom}
                    module={tag.isCustom ? 'CONTENT_BANK' : 'ALL'}
                    isDropdownInvisible
                    project={project}
                  />
                </div>
              ))}
            </div> : null}
          {type === 'VIDEO' && <div className='drm-container'>
            <span>DRM Enabled</span>
            <Switch onChange={handleDrmChange} checked={isDrmEnabled} disabled={!isDrmEditEnabled} />
          </div>}
        </div>
      )
    }
}

EditImageDetails.propTypes = {
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /**  */
  handleFormatChange: PropTypes.func,
  /**  */
  handleTextChange: PropTypes.func,
  /**  */
  formats: PropTypes.array,
  /**  */
  selectedFormat: PropTypes.string,
  /** List of category */
  categoryList: PropTypes.array,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** Selected category valu */
  selectedCategory: PropTypes.string
}

EditImageDetails.defaultProps = {
}

export default EditImageDetails
