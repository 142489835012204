import gql from 'graphql-tag'

export default gql(`
    mutation($resolutions: [UpdateResolutionsInput]!, $aspectRatioId: ID!) {
        batchUpdateResolutions(
            input: $resolutions 
            aspectRatioId: $aspectRatioId
            ) {
            width
            height
            id
          }
    }`
)
