import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './IngestManager.scss'
import IngestEditor from './IngestEditor'

class IngestManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchBatchId: props.match && props.match.params && props.match.params.id ? props.match.params.id : null,
      selectedIngestJobType: null
    }
  }

  componentDidMount = () => {
    document.title = 'Ingest Manager - Hyperion CMS'
  }

  onChangeBatchIdSearchInput = (searchString) => {
    this.setState({
      searchBatchId: searchString
    })
  }

  onIngestJobTypeSelect = (selectedJobType) => {
    this.setState({
      selectedIngestJobType: selectedJobType
    })
  }

  render () {
    const { searchBatchId, selectedIngestJobType } = this.state
    const { project, IngestClient, history, match } = this.props
    return (
      <IngestEditor
        searchBatchId={searchBatchId}
        onIngestJobTypeSelect={this.onIngestJobTypeSelect}
        selectedIngestJobType={selectedIngestJobType}
        project={project}
        IngestClient={IngestClient}
        history={history} // route history
        match={match} // route match
        onChangeBatchIdSearchInput={this.onChangeBatchIdSearchInput}
      />
    )
  }
}

IngestManager.propTypes = {
  /** current project */
  project: PropTypes.string,
  /** ingest Client configuration */
  IngestClient: PropTypes.object
}

export default IngestManager
