import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Tabs, message } from 'antd'
import HeaderDropDown from '../dataEntry/inputs/HeaderDropDown'
import PopOverListContainer from './../../ui/general/lists/PopOverListContainer'
import { utilityService } from '../../../services/UtilityService'

import HeaderMenu from './HeaderMenu'
import './../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryUserNotifications from './../../../graphQL/notifications/getUserNotifications'
import MutationDismissNotification from './../../../graphQL/notifications/dismissNotification'
import MutationDismissAllNotification from './../../../graphQL/notifications/dismissAllNotification'

import { GlobalHotKeys } from 'react-hotkeys'

const keyMap = {
  shortcutShortcut: 'shift+/'
}

const { TabPane } = Tabs

const { Header } = Layout

const options = {
  ASSET_MANAGER: {
    name: 'Asset Manager',
    address: 'Address1',
    route: '/assets'
  },
  CHANNEL_MANAGER: {
    name: 'Channel Manager',
    address: 'Address2',
    route: '/channels'
  },
  CONTENT_BANK: {
    name: 'Content Bank',
    address: 'Address3',
    route: '/contents'
  },
  BUCKET_MANAGER: {
    name: 'Bucket Manager',
    address: 'Address4',
    route: '/buckets'
  },
  MATCH_MANAGER: {
    name: 'Match Manager',
    address: 'Address',
    route: '/matches'
  },
  JOB_MANAGER: {
    name: 'Job Manager',
    address: 'Address5',
    route: '/jobs'
  },
  APP_MANAGER: {
    name: 'App Manager',
    address: 'Address10',
    route: '/apps'
  },
  DATA_MANAGER: {
    name: 'Admin Manager',
    address: 'Address6',
    route: '/admin'
  },
  ROLE_MANAGER: {
    name: 'Role Manager',
    address: 'Address7',
    route: '/users'
  },
  CUSTOMER_MANAGER: {
    name: 'Customer Manager',
    address: 'Address7',
    route: '/customers'
  },
  PARTNER_MANAGER: {
    name: 'Partner Manager',
    address: 'Address8',
    route: '/partners'
  },
  OFFER_MANAGER: {
    name: 'Offer Manager',
    address: 'Address9',
    route: '/offers'
  },
  INGEST_MANAGER: {
    name: 'Ingest Manager',
    address: 'Address10',
    route: '/ingest-jobs'
  },
  MONITOR_MANAGER: {
    name: 'Monitor Manager',
    address: 'Address11',
    route: '/monitor'
  },
  DRM_MANAGER: {
    name: 'DRM Token Manager',
    address: 'Address12',
    route: '/drm'
  }
}

class HeaderComponent extends React.Component {
  constructor (props) {
    super(props)
    const defaultValue = this.getDefaultValue()
    this.state = {
      selectedMenuItem: defaultValue,
      tempSelected: '/',
      isKeyboardShotcut: false,
      file: null,
      intermediateUrl: [],
      showNotificationDropDown: false,
      openedNotification: null,
      isNotificationAvailable: false,
      isLoading: !(props.listUserNotifications && props.listUserNotifications.length),
      isPaginating: false
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

    handleClickOutside = (e) => {
      if ((this.keyboardShortcutsContent && this.keyboardShortcutsContent.contains(e.target))) {
        return
      }
      if ((this.notificationContainerRef && this.notificationContainerRef.contains(e.target)) || (this.notificationIconRef && this.notificationIconRef.contains(e.target))) {
        return
      }
      if (this.state.isKeyboardShotcut) {
        this.setState({ isKeyboardShotcut: false })
      }
      if (this.state.showNotificationDropDown) {
        this.setState({ showNotificationDropDown: false })
      }
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      if (nextProps.forceSwitch) {
        if (this.state.tempSelected !== '/') {
          this.props.history.push(this.state.tempSelected)
          const defaultValue = this.getDefaultValue()
          this.setState({ selectedMenuItem: defaultValue })
        }
      }
      if (nextProps.forceClear) {
        this.setState({ tempSelected: '/' })
      }
      if (!nextProps.isLoading && nextProps.listUserNotifications && !_.isEqual(nextProps.listUserNotifications, this.props.listUserNotifications)) {
        const allNotificationDismissed = (nextProps.listUserNotifications || []).every(item => item.dismissed === true)
        if (allNotificationDismissed) {
          this.setState({ isNotificationAvailable: false, isLoading: false, isPaginating: false })
        } else {
          this.setState({ isNotificationAvailable: true, isLoading: false, isPaginating: false })
        }
      } else if (!nextProps.isLoading && _.isEmpty(nextProps.listUserNotifications)) {
        this.setState({ isLoading: false, isPaginating: false, isNotificationAvailable: false })
      }
    }

    optionSelected = (option) => {
      if (this.props.editStatus) {
        this.props.moduleSwitch()
        this.setState({ tempSelected: option })
      } else {
        this.props.history.push(option)
        const defaultValue = this.getDefaultValue()
        this.setState({ selectedMenuItem: defaultValue })
      }
    }

    getAvailableModules = () => {
      const { userRoles } = this.props
      const permissions = userRoles ? userRoles.permissions : {}
      const modules = permissions ? _.keys(permissions) : []
      if (modules && modules.length) {
        const headerArr = modules.filter(item => (permissions[item] || []).indexOf('GET') > -1 || (permissions[item] || []).indexOf('VIEW_PAGE') > -1 || (permissions[item] || []).indexOf('VIEW_CONFIG') > -1)
        let optionList = []
        for (let headerOption in options) {
          if (headerArr.indexOf(headerOption) > -1) { optionList.push(options[headerOption]) }
        }
        return optionList
      } else return []
    }

    getUploadUrl = file => {
      const newFile = { fileName: file.name, contentType: file.type, type: 'VIDEO', size: file.size }
      let isFileEqual = false
      if (_.isEqual(this.state.file, newFile)) {
        isFileEqual = true
      }
      this.setState({ file: newFile }, () => {
        if (isFileEqual) { this.forceUpdate() }
      })
    }

    updateIntermediateUrl = (intermediateUrl) => {
      this.setState({ intermediateUrl })
    }

    clearUploadUrl = () => {
      this.setState({ file: null })
    }

    getDefaultValue = () => {
      let defaultValue = 'Asset Manager'
      const { history } = this.props
      if (history && history.location && history.location.pathname) {
        const path = history.location.pathname.split('/')
        if (path.indexOf('assets') > 0) {
          defaultValue = 'Asset Manager'
        } else if (path.indexOf('channels') > 0) {
          defaultValue = 'Channel Manager'
        } else if (path.indexOf('contents') > 0) {
          defaultValue = 'Content Bank'
        } else if (path.indexOf('buckets') > 0) {
          defaultValue = 'Bucket Manager'
        } else if (path.indexOf('matches') > 0) {
          defaultValue = 'Match Manager'
        } else if (path.indexOf('jobs') > 0) {
          defaultValue = 'Job Manager'
        } else if (path.indexOf('admin') > 0) {
          defaultValue = 'Admin Manager'
        } else if (path.indexOf('users') > 0) {
          defaultValue = 'Role Manager'
        } else if (path.indexOf('customers') > 0) {
          defaultValue = 'Customer Manager'
        } else if (path.indexOf('partners') > 0) {
          defaultValue = 'Partner Manager'
        } else if (path.indexOf('offers') > 0) {
          defaultValue = 'Offer Manager'
        } else if (path.indexOf('ingest-jobs') > 0) {
          defaultValue = 'Ingest Manager'
        } else if (path.indexOf('monitor') > 0) {
          defaultValue = 'Monitor Manager'
        } else if (path.indexOf('apps') > 0) {
          defaultValue = 'App Manager'
        } else if (path.indexOf('drm') > 0) {
          defaultValue = 'DRM Token Manager'
        } else {
          defaultValue = ''
        }
      }
      return defaultValue
    }

    keyHandlers = {
      shortcutShortcut: () => {
        this.setState({
          isKeyboardShotcut: !this.state.isKeyboardShotcut
        })
      }
    }

    handleKeyboardShortcut = () => {
      this.setState({ isKeyboardShotcut: true })
    }

    handleNotificationDropDown=() => {
      this.setState({ showNotificationDropDown: !this.state.showNotificationDropDown })
    }

    onNotificationSelectOption = (id, selectedOption) => {
      const { listUserNotifications } = this.props
      const selectedNotification = listUserNotifications.find(item => item.id === id)
      if (!selectedNotification) {
        return
      }
      if (selectedOption.type === 'mark as read') {
        this.setState({ isShowNotificationModal: true, selectedNotification, isEdit: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteNotificationModal: true, selectedNotification })
      }
      this.setState({ openedNotification: null })
    }

    toggleShowOptions = id => {
      if (this.state.openedNotification === id) {
        this.setState({ openedNotification: null })
      } else {
        this.setState({ openedNotification: id })
      }
    }

    setNotificationContainerRef=(ref) => {
      this.notificationContainerRef = ref
    }

    setNotificationIconRef=(ref) => {
      this.notificationIconRef = ref
    }

    loadMoreNotification=() => {
      if (this.props.totalCount === this.props.listUserNotifications.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreUserNotifications(this.props.listUserNotifications.length))
    }

    handleDismissNotification=(id, read) => {
      if (!id) {
        return
      }
      if (!read) {
        this.props.dismissNotification(id).then(() => {

        }, (error) => {
          utilityService.handleError(error)
        })
      }
    }

    handleDismissAllNotification = () => {
      const { userId } = this.props
      const { isNotificationAvailable } = this.state
      const variables = {
        user: userId,
        project: this.props.project
      }

      if (isNotificationAvailable) {
        this.setState({ isNotificationAvailable: false })
        this.props.dismissAllNotification(variables).then((res) => {
          if (!res.error) {
            message.success('All Notification Marked as Read')
          } else {
            this.setState({ isNotificationAvailable: true })
          }
        }, (error) => {
          utilityService.handleError(error)
        })
      }
    }

    render () {
      const { fileData, history, changePassword, disableMfa, listUserNotifications, project } = this.props
      const { file, intermediateUrl, showNotificationDropDown, openedNotification, isNotificationAvailable, isLoading, isPaginating } = this.state
      const currentSelection = this.getDefaultValue()
      // const temOption = [...options]
      const temOption = this.getAvailableModules()
      const index = temOption.findIndex((item) => item.name === currentSelection)
      const selectedItem = temOption[index]
      if (selectedItem) {
        temOption.splice(index, 1)
        temOption.unshift(selectedItem)
      }
      const isHyperion = window.location.href.includes('hyperion')
      const isSubhub = window.location.href.includes('subhub')
      return (
        <Header>
          <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
          <div className='header-dropdown-container' id='header'>
            <HeaderDropDown options={temOption} displayParam='name' selectedValue={currentSelection} onOptionSelect={this.optionSelected} />
          </div>
          {isHyperion ? <span className='optus-logo hyperion-logo'>HYPERION</span>
            : isSubhub ? <span className='optus-logo hyperion-logo'>SUBHUB</span>
              : <img className='optus-logo' src={require('./../../../assets/images/optus-logo.png')} />}
          <HeaderMenu
            history={history}
            fileData={fileData}
            handleKeyboardShortcut={this.handleKeyboardShortcut}
            handleNotificationDropDown={this.handleNotificationDropDown}
            file={file}
            getUploadUrl={this.getUploadUrl}
            clearUploadUrl={this.clearUploadUrl}
            intermediateUrl={intermediateUrl}
            changePassword={changePassword}
            updateIntermediateUrl={this.updateIntermediateUrl}
            showNotification={showNotificationDropDown}
            disableMfa={disableMfa}
            setNotificationIconRef={this.setNotificationIconRef}
            showAlertIcon={isNotificationAvailable}
            project={project}
          />
          {showNotificationDropDown
            ? <PopOverListContainer
              listData={listUserNotifications}
              parentCompoent='notification'
              onSelectOption={this.onNotificationSelectOption}
              toggleShowOptions={this.toggleShowOptions}
              openedNotification={openedNotification}
              setNotificationRef={this.setNotificationContainerRef}
              handleDismissNotification={this.handleDismissNotification}
              loadMoreNotification={this.loadMoreNotification}
              hasMore={this.props.totalCount > _.size(listUserNotifications)}
              isLoading={isLoading}
              isPaginating={isPaginating}
              notificationContainerRef={this.notificationContainerRef}
              handleDismissAllNotification={this.handleDismissAllNotification}
              isNotificationAvailable={isNotificationAvailable}
            />
            : null}
          <div className={`keyboard-shortcuts-wrapper ${this.state.isKeyboardShotcut ? '' : 'keyboard-shortcuts-none'}`}>
            <div className='keyboard-shortcuts-content' ref={node => { this.keyboardShortcutsContent = node }}>
              <h2>Keyboard shortcuts</h2>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Asset Manager' key='1'>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                                New asset
                        <span>n</span>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Add Image to Images Section
                        <span>i</span>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Add video to Videos Section
                        <span>v</span>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Duplicate Asset
                        <span>d</span>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Add image to Content Section
                        <div>
                          <span>c</span> + <span>i</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                            Filter popup
                        <span>f</span>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Add Rich text editor to Content Section
                        <div>
                          <span>c</span> + <span>t</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Add Social Feed to Content Section
                        <div>
                          <span>c</span> + <span>s</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Save Asset
                        <span>s</span>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Publish Asset
                        <div>
                          <span>c</span> + <span>p</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                            Navigate to Next Asset in Listing
                        <span>j</span>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                            Navigate to Previous Asset in Listing
                        <span>k</span>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                            Focus on Asset Search input for Quick Search
                        <span>/</span>
                      </div>
                    </div>
                    <div className='tab-content' />
                  </div>
                </TabPane>
                <TabPane tab='Rich Text Editor' key='2'>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                                Bold
                        <div>
                          <span>Ctrl/⌘</span> + <span>b</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Italic
                        <div>
                          <span>Ctrl/⌘</span> + <span>i</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                                Copy
                        <div>
                          <span>Ctrl/⌘</span> + <span>c</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Paste
                        <div>
                          <span>Ctrl/⌘</span> + <span>v</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Cut
                        <div>
                          <span>Ctrl/⌘</span> + <span>x</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Select All
                        <div>
                          <span>Ctrl/⌘</span> + <span>a</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Undo
                        <div>
                          <span>Ctrl/⌘</span> + <span>z</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Redo
                        <div>
                          <span>Ctrl/⌘</span> + <span>shift</span> + <span>z</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              BlockQuote
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>q</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Numeric List
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>o</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Align Left
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>l</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Justify Text
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>j</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Align Center
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>c</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Strikethrough
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>d</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Align Right
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>r</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Unordered List
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>u</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              Insert/Remove link
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>a</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              Paragraph
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>0</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              H1
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>1</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              H2
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              H3
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>3</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              H4
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>4</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                              H5
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>5</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                              H6
                        <div>
                          <span>Alt</span> + <span>Shift</span> + <span>6</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='Player' key='7'>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                        MarkIn -1s
                        <div>
                          <span>q</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                        MarkIn +1s
                        <div>
                          <span>w</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                        MarkOut -1s
                        <div>
                          <span>e</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                        MarkOut +1s
                        <div>
                          <span>r</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-content-wrapper'>
                    <div className='tab-content'>
                      <div className='row'>
                        Seek -1s
                        <div>
                          <span>z</span>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='row'>
                        Seek +1s
                        <div>
                          <span>x</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
              <button className='ant-btn ant-btn-primary' onClick={() => { this.setState({ isKeyboardShotcut: false }) }}>Close</button>
            </div>
          </div>
        </Header>
      )
    }
}

HeaderComponent.propTypes = {
  history: PropTypes.any,
  /** Boolean for edit status */
  editStatus: PropTypes.bool,
  /** Function to be called for showing module switch warning */
  moduleSwitch: PropTypes.func,
  /** Boolean to switch modules */
  forceSwitch: PropTypes.bool
}

HeaderComponent.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryUserNotifications,
    {
      options: ({ userId, project }) => {
        const variables = { user: userId, limit: 20, offset: 0, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      skip: ({ userId }) => !(userId && userId.length),
      props: (props) => {
        const { data } = props
        const listUserNotifications = data.getUserNotifications ? data.getUserNotifications.items : []
        return {
          listUserNotifications,
          isLoading: data.loading,
          totalCount: data.getUserNotifications ? data.getUserNotifications.totalCount : 0,
          // previousToken: data.listTagType ? data.listTagType.previousToken : null,
          getMoreUserNotifications: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.getUserNotifications.items, ...fetchMoreResult.getUserNotifications.items]
                prev.getUserNotifications.items = newList
                // prev.listTagType.previousToken = prev.listTagType.nextToken
                prev.getUserNotifications.nextToken = fetchMoreResult.getUserNotifications.nextToken
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDismissNotification,
    {
      options: ({ userId, project }) => ({
        update: (cache, { data: { dismissNotification } }) => {
          const variables = { user: userId, offset: 0, limit: 20, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserNotifications, variables }))
          if (cacheData && cacheData.getUserNotifications && cacheData.getUserNotifications.items) {
            const selectedIndex = cacheData.getUserNotifications.items.findIndex(item => item.id === dismissNotification.id)
            if (selectedIndex > -1) {
              // cacheData.listCountry.items.splice(selectedIndex, 1)
              cacheData.getUserNotifications.items[selectedIndex].dismissed = true
            }
          }
          cache.writeQuery({
            query: QueryUserNotifications,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        dismissNotification: (id) => {
          return props.mutate({
            variables: { id }
          })
        }
      })
    }
  ),
  graphql(
    MutationDismissAllNotification,
    {
      options: ({ userId, project }) => ({
        update: (cache) => {
          const variables = { user: userId, offset: 0, limit: 20, project }
          let cacheData = _.cloneDeep(cache.readQuery({ query: QueryUserNotifications, variables }))
          if (cacheData && cacheData.getUserNotifications && cacheData.getUserNotifications.items) {
            cacheData.getUserNotifications.items = (cacheData.getUserNotifications.items || []).map(item => {
              if (item.dismissed === false) {
                return { ...item, dismissed: true }
              }
              return { ...item }
            })
          }
          cache.writeQuery({
            query: QueryUserNotifications,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        dismissAllNotification: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(HeaderComponent))
