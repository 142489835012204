import gql from 'graphql-tag'

export default gql(`
    mutation($name: String! $media: [ID] $isDisabled: Boolean $module:MODULE_TYPE $project: String) {
            createAuthor(
            input: {
                name: $name
                media: $media
                isDisabled: $isDisabled
            }
            project: $project
            module: $module
        ) {
            id
            name
            isDisabled
            assetCount
            isTransferring
            isError
            media {
                id
                name
                fileName
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
        }
    }`
)
