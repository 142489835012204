import * as React from 'react'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
import './Auth.scss'

class EnableMfa extends React.Component {
  render () {
    const { onLogout, onSuccess, isEnableMfaLoading } = this.props

    return <div className='login'>
      <div className='enable-mfa-container'>
        <div className='header'>
          <span>ENABLE MULTI-FACTOR AUTHENTICATION</span>
          <div />
        </div>
        <span className='body'>You are required to enable additional security features for your user account. Proceed to enable Multi-Factor Authentication for your account</span>
        <div className='footer'>
          <LoadingButton type='primary' buttonText={'Logout'} buttonClass='login-form-button login-form-submit' onClick={onLogout} />
          <LoadingButton type='primary' buttonText={'Continue'} buttonClass='login-form-button login-form-submit' onClick={onSuccess} isLoading={isEnableMfaLoading} />
        </div>
      </div>
    </div>
  }
}

export default EnableMfa
