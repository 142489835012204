import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import './../../ui.style.scss'

class BucketResetTitleModal extends React.Component {
  render () {
    const { visible, handleCancel, title, bucketAssetData } = this.props

    return (

      <Modal
        className='general-modal bucket-reset-title-modal'
        visible={visible}
        closable={false}
        title={title}
        onOk={this.props.handleSubmit}
        onCancel={handleCancel}
        okText='Reset'
        cancelText='Cancel'
        maskClosable={false}
        centered
        destroyOnClose
      >
        <p> {`Display Title "${bucketAssetData.customTitle}" will reset to "${bucketAssetData.asset.title}"`}</p>
        <p>Are you sure you want to reset this Display Title?</p>
      </Modal>
    )
  }
}

BucketResetTitleModal.propTypes = {
  /** visible action of BucketResetTitleModal. */
  visible: PropTypes.bool,
  /** title  of BucketResetTitleModal. */
  title: PropTypes.string,
  /** handleCancel action of BucketResetTitleModal. */
  handleCancel: PropTypes.func
}

BucketResetTitleModal.defaultProps = {
}

export default BucketResetTitleModal
