import gql from 'graphql-tag'

export default gql(`
  query ($searchKey: String, $field: FIELD_TYPE $limit: Int $offset: Int $module: MODULE_TYPE $project: String){
    getContentByField(
        searchKey: $searchKey,
        field: $field,
        offset: $offset,
        limit: $limit
        module: $module
        project: $project
      ) {
        items{
          name
          label
          labelType
          key
        }
    }
  }
  `
)
