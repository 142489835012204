import gql from 'graphql-tag'

export default gql(`
query getUserAccountStatus ( $userId: String $project: String  ) {
    getUserAccountStatus ( 
    userId: $userId,
    project: $project
    ) {
     accountStatus {
        userId
        enabled
     }
    }
}`)
