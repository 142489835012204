import React from 'react'
import PropTypes from 'prop-types'

const AssetTypeIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <g stroke={color}>
        <rect width='8.333' height='8.333' x='.5' y='.5' rx='1' />
        <rect width='8.333' height='8.333' x='11.167' y='.5' rx='1' />
        <rect width='8.333' height='8.333' x='.5' y='11.167' rx='1' />
        <rect width='8.333' height='8.333' x='11.167' y='11.167' rx='1' />
      </g>
    </g>
  </svg>
)

AssetTypeIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

AssetTypeIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default AssetTypeIcon
