/**
 * Config file contains all the configs for the player
 */

import { utilityService } from '../../../services/UtilityService'
const isProd = utilityService.getEnvironment() === ''
const BITMOVIN_LICENSE = {
  PROD: 'cac53da6-a2c5-4900-ae4e-3a2e5694220f',
  STAGING: '056deb65-1f54-4f29-a784-79d3dae9f275'
}
export const playerId = 'player'
export const JWPlayerScript = '//content.jwplatform.com/libraries/o4mGrB1s.js'
export const isJWPlayer = false
export const bPlayerConfig = {
  key: isProd ? BITMOVIN_LICENSE.PROD : BITMOVIN_LICENSE.STAGING,
  playback: {
    muted: false,
    autoplay: true
  },
  ui: false,
  tweaks: {
    enable_seek_for_live: true
  }
}
