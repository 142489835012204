import gql from 'graphql-tag'

export default gql(`
  query ($searchKey: String $limit: Int $offset: Int $project: String){
    getContentByField(
        searchKey: $searchKey,
        field: STAGE,
        offset: $offset,
        limit: $limit
        project: $project
      ) {
        items{
          name
          id: key
        }
    }
  }
  `
)
