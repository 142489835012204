import React from 'react'
import moment from 'moment'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose, isEmpty } from 'lodash'
import QueryBucketItemDetails from '../../../graphQL/audit/getBatchBucketItems'
import QueryBucketGroupItemDetails from '../../../graphQL/audit/getBatchBucketGroupItems'
import QueryMediaDetails from '../../../graphQL/audit/getBatchMedia'
import QueryChannelConfigDetails from '../../../graphQL/channel/getChannelConfigGroup'
import QueryConfigGroupSchema from '../../../graphQL/channel/getConfigGroupSchema'
import QueryStreamSchema from '../../../graphQL/channel/getStreamSchema'

const lastReplaceRegex = /, ([^,]*)$/

const actionMap = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
  ADD: 'Added',
  REMOVE: 'Removed',
  CLEARED: 'Cleared',
  SYNC: 'Sync'
}
const fieldMap = {
  RICHTEXT: 'richtext content',
  TITLE: 'title content',
  IMAGE: 'image content',
  DEFAULT_IMAGE: 'default image content',
  AUTHOR: 'author content',
  DESCRIPTION: 'description content',
  SHARE: 'social media share content',
  SOCIAL: 'social media content',
  EMBED: 'embeded content',
  PROGRAM: 'channel program',
  FILTER: 'filter',
  ASSET: 'asset',
  relatedAssets: 'related asset list',
  shortTitle: 'short title',
  bucketItems: 'related asset item',
  gBucketItems: 'related asset item',
  createdBy: 'created by',
  publishEndDate: 'publish end date',
  publishStartDate: 'publish start date',
  gPublishStartDate: 'publish start date',
  gPublishEndDate: 'publish end date'
}

const getFormattedTagsArray = tagsString => {
  const tagTypes = ['vimond', 'sport', 'team', 'season', 'competition', 'bucket', 'product']
  const manualTags = ['asset_manager', 'channel_manager', 'content_bank', 'match_manager', 'data_manager']
  let tags = tagsString.slice(1, tagsString.length - 1).split(',')
  tags = tags.map((tag) => {
    _.forEach(tagTypes, (item) => {
      const n = tag.lastIndexOf(item)
      if (n > -1) {
        tag = `${tag.substring(0, n)}(${item}) `
      }
    })
    _.forEach(manualTags, (item) => {
      const n = tag.lastIndexOf(item)
      if (n > -1) {
        tag = `${tag.substring(0, n)}(manual)`
      }
    })
    return tag
  }).join(',')
  return `[${tags}]`
}

const handleBooleanChanges = (field, audit) => {
  if (field === 'isArchived') {
    return { action: audit.value === 'true' ? 'Archived Asset' : 'Restored Asset' }
  } else if (field === 'isFree') {
    return { action: audit.value === 'true' ? 'Marked asset as' : 'Marked asset as', field: audit.value === 'true' ? 'Free' : 'Premium' }
  } else if (field === 'isLive') {
    return { action: audit.value === 'true' ? 'Marked asset as' : 'Marked asset as', field: audit.value === 'true' ? 'Live' : ' On Demand' }
  }
}

const compareArrays = (a, b) => {
  const changes = []
  // Iterate through the elements of both arrays
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    const elementA = a[i]
    const elementB = b[i]

    // Compare the elements
    if (elementA !== elementB) {
      // If they are different, add the change to the result
      changes.push({
        index: i,
        oldValue: elementA,
        newValue: elementB
      })
    }
  }
  return changes
}

const handleBooleanChangesConfig = (field, audit) => {
  if (field === 'isArchived') {
    return { action: audit.value === 'true' ? 'Archived Config' : 'Restored Config' }
  }
}

const handleBooleanChangesPage = (field, audit) => {
  if (field === 'isArchived') {
    return { action: audit.value === 'true' ? 'Archived Page' : 'Restored Page' }
  }
}

const getFormattedAssetDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let content
  if (audit.name === 'isLive' || audit.name === 'isFree' || audit.name === 'isArchived') {
    return handleBooleanChanges(field, audit)
  }
  if (contentType === 'CONTENT_ITEM' && audit.name === 'media') {
    field = 'media content'
  }
  if (contentType === 'RELATED_ASSET') {
    content = 'related assets'
    if (contentName === 'FILTER') {
      let value = audit.value
      if (audit.name === 'tags') {
        value = getFormattedTagsArray(value || '')
      } else if (audit.name === 'type' && (value === 'null' || !value)) {
        return { action: actionMap['REMOVE'], field: `${field} of ${contentName}`, value: ``, content }
      }
      if (audit.isPosChange && audit.name === 'FILTER') {
        return { action: 'Changed position of', field, value: `from ${parseInt(audit.oldValue)} to ${parseInt(audit.value)}`, content }
      }
      return { action: actionMap[action], field: `${field} of ${contentName}`, value: `to ${value}`, content }
    }
  }
  if (contentType === 'PUBLISH_RULE') {
    content = 'publish rule'
    if (field === 'isEnabled') {
      return { action: audit.value === 'true' ? 'Published to' : 'Unpublished to', field: contentName }
    }
    let value
    if (!audit.oldValue || audit.oldValue === 'null') {
      value = `to ${moment(audit.value).format('DD MMM YYYY HH:mm')}`
    } else {
      let newValue = !audit.value || audit.value === 'null' ? `to null` : ` to ${moment(audit.value).format('DD MMM YYYY HH:mm')}`
      value = `from ${moment(audit.oldValue).format('DD MMM YYYY HH:mm')} ${newValue}`
    }

    return { action: 'Updated', field: `${field} of ${contentName}`, value, content }
  }
  if (audit.isPosChange) {
    contentType === 'CREDIT' ? action = 'Changed credit position of' : (audit.fieldName === 'rating' ? action = 'Changed rating position of' : action = 'Changed position of')
    return { action, field, value: `from ${audit.oldValue} to ${audit.value}`, content }
  }

  if (audit.name === 'meta' && audit.fieldValues !== null) {
    let changedOldValue = audit.oldFieldValues
    let changedNewValue = audit.fieldValues
    let changedString = ''
    let contentString = 'Meta Field'
    if (isEmpty(changedOldValue) && !isEmpty(changedNewValue)) {
      const newArrayItems = _.chunk(changedNewValue, 2)
      newArrayItems.forEach((item) => {
        if (item[1] === null || isEmpty(item[1])) return
        changedString += ` Added  ${item[1].replace(/\[|\]/g, '')} to the ${item[0]},`
      })
    } else {
      const changes = compareArrays(changedOldValue, changedNewValue)
      changes.forEach((change) => {
        if (change.oldValue === undefined || change.oldValue === null || change.oldValue === 'null' || change.oldValue === '[]') {
          changedString += ` Added  ${change.newValue} to the ${changedNewValue[change.index - 1]},`
        } else if (change.newValue === undefined || change.newValue === null || change.newValue === 'null' || change.newValue === '[]') {
          changedString += ` Removed ${change.oldValue} from ${changedNewValue[change.index - 1]},`
        } else {
          changedString += ` Updated ${changedNewValue[change.index - 1]} from  ${change.oldValue} to ${change.newValue},`
        }
      })
    }

    console.log('changedString 2', changedString)

    return { action: changedString.replace(/\[|\]/g, '').slice(0, -1), field: '', value: '', content: contentString }
  }

  if (audit.name === 'meta' && audit.value !== null) {
    let changedOldValue = audit.oldValue
    let changedNewValue = audit.value
    changedNewValue = changedNewValue.split(',')
    changedNewValue[0] = changedNewValue[0].substring(1)
    const newValLength = changedNewValue.length - 1
    changedNewValue[newValLength] = changedNewValue[newValLength].substring(0, changedNewValue[newValLength].length - 1)

    changedOldValue = changedOldValue.split(',')
    changedOldValue[0] = changedOldValue[0].substring(1)
    const oldValLength = changedOldValue.length - 1
    changedOldValue[oldValLength] = changedOldValue[oldValLength].substring(0, changedOldValue[oldValLength].length - 1)

    let changedString = ''

    let index = 0
    for (index; index < changedNewValue.length; index += 4) {
      const tempItem = changedNewValue[index]
      const oldIndex = changedOldValue.indexOf(tempItem)
      if (oldIndex > -1) {
        if (changedOldValue[oldIndex + 3] !== changedNewValue[index + 3]) {
          if (changedNewValue[index + 3] === 'undefined' || changedNewValue[index + 3] === undefined) {
            continue
          }
          if (changedOldValue[oldIndex + 3] === 'undefined' || changedOldValue[oldIndex + 3] === '' || changedOldValue[oldIndex + 3] === 'null' || changedOldValue[oldIndex + 3] === null || changedOldValue[oldIndex + 3] === undefined) {
            if (changedNewValue[index + 3] === null || changedNewValue[index + 3] === 'null' || changedNewValue[index + 3] === 'undefined' || changedNewValue[index + 3] === undefined) {
              continue
            }
            changedString += `Added ${tempItem} - ${changedNewValue[index + 3]}, `
          } else if (changedNewValue[index + 3] === '' || changedNewValue[index + 3] === null || changedNewValue[index + 3] === 'null') {
            changedString += `Removed ${tempItem}, `
          } else {
            if (changedNewValue[index + 3] === null || changedNewValue[index + 3] === 'null' || changedNewValue[index + 3] === 'undefined' || changedNewValue[index + 3] === undefined) {
              continue
            }
            changedString += `Updated ${tempItem} from ${changedOldValue[oldIndex + 3]} to ${changedNewValue[index + 3]}, `
          }
        }
      } else {
        if (changedNewValue[index + 3] === null || changedNewValue[index + 3] === 'null' || changedNewValue[index + 3] === 'undefined' || changedNewValue[index + 3] === undefined) {
          continue
        }

        changedString += `Updated ${tempItem} to ${changedNewValue[index + 3]}, `
      }
    }

    changedString = changedString.substr(0, changedString.length - 2)
    let contentString = changedString !== '' ? 'Meta Field' : ''
    console.log('changedString 1', changedString)
    return { action: changedString, field: '', value: '', content: contentString }
  }

  if (audit.name === 'PROGRAM') {
    return { action: actionMap[action], field, value: `${audit.value}`, content }
  }

  let changedValue = audit.name === 'defaultVideo' && props.details.contentName ? props.details.contentName : audit.value
  if (audit.name === 'productionDateTime' || audit.name === 'releaseDateTime') {
    if (audit.value) {
      changedValue = moment(audit.value).format('DD MMM YYYY HH:mm')
    } else if (audit.oldValue) {
      changedValue = moment(audit.oldValue).format('DD MMM YYYY HH:mm')
    }
  }
  const oldValue = audit.name === 'tags' ? getFormattedTagsArray(audit.oldValue || '') : audit.oldValue
  const newValue = audit.name === 'tags' ? getFormattedTagsArray(audit.value || '') : (audit.name === 'bucketItems' && !audit.value ? '[]' : (audit.name === 'defaultVideo' && props.details.contentName ? props.details.contentName : audit.value))
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  if (contentType === 'ASSET' && audit.name === 'contents') {
    const contentArrayChanges = getArrayChangesForContent(newValue, oldValue, props, audit)
    action = contentArrayChanges ? contentArrayChanges.action : action
    changedValue = contentArrayChanges ? contentArrayChanges.changedValue : changedValue

    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  }
  const arrayChanges = getArrayChanges(newValue, oldValue, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  if (audit.name === 'defaultMedia' || audit.name === 'defaultImage' || audit.name === 'defaultVideo') {
    const mediaNames = replaceIdwithItems([changedValue], props)
    changedValue = mediaNames[0]
  }
  if (audit.name === 'categoryId') {
    const categoryId = replaceIdwithItems([changedValue], props)
    changedValue = categoryId[0]
    return { action: actionMap[action], field: 'category', value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  }
  if (contentType === 'IMAGE' && audit.name === 'settings') {
    const mediaNames = replaceIdwithItems([contentName], props)
    // return getFormattedContentDetails(audit, action, contentType, contentName, props)
    const aspectRatio = JSON.parse(audit.value).aspectRatio
    const assetMedia = ((props.assetDetails && props.assetDetails.images) || []).find(item => item.id === contentName)
    if (assetMedia) {
      changedValue = (assetMedia.aspectRatio || []).find(item => item.aspectRatio === aspectRatio).title
    }
    return { action: actionMap[action], field: 'crop settings', value: `under ${changedValue} of image ${mediaNames[0]}`, content }
  }
  if (audit.name === 'Meta Field') {
    if (action === 'REMOVE') {
      return { action: `${actionMap[action]} ${field}`, field: audit.fieldName, value: audit.oldValue, content }
    } else {
      return { action: `${actionMap[action]} ${field}`, field: audit.fieldName, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : `from ${audit.oldValue} to`} ${changedValue}`, content }
    }
  }

  if (audit.name === 'Content Meta Field') {
    const contentString = 'Content Meta Fields'
    if (newValue !== null) {
      return { action: 'Updated', field: contentString, value: ` ${audit.fieldName} ${oldValue ? 'from ' + oldValue : ''}  to ${newValue || 'null'}`, content: contentName ? contentName.toUpperCase() : '' }
    } else {
      return null
    }
  }
  if (audit.name === 'Remove Content Meta Field') {
    const contentString = 'Meta Fields'
    return { action: 'Removed', field: contentString, value: `${audit.fieldName}`, content: contentName ? contentName.toUpperCase() : '' }
  }
  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }

  if (((contentType === 'CREDIT') && (audit.name === 'crew' || audit.name === 'cast'))) {
    let content = ''
    // let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getTemplateCreditArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name)
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }

  if (audit.name === 'ratings') {
    let content = ''
    // let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getTemplateRatingArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name)
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    field = arrayChanges ? arrayChanges.field : field
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }
  if (audit.fieldName === 'adManagement' && contentType === 'VIDEO') {
    content = replaceIdwithItems([contentName], props)
  }
  if (field === 'site') {
    content = audit.value === 'PRI' ? 'PRIMARY' : audit.value === 'SEC' ? 'SECONDARY' : 'MULTI'
    return { action: 'Updated', field: `${field} to `, value: content }
  }
  if (contentName === 'Webhook') {
    return { action: '', field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  }
  if (audit && audit.name && audit.name.includes('APPLE')) {
    return { action: '', field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  }
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
}

const getFormattedBucketDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let content; let isBucket = false
  let changedValue = audit.value
  let oldValue = audit.oldValue
  let newValue = audit.value
  field = field === 'id' ? 'bucket' : field === 'related asset item' ? 'bucket item' : field
  if (contentType === 'BUCKET' || contentType === 'BUCKET_ITEM') {
    isBucket = true
  }
  if (contentType === 'BUCKET') {
    isBucket = true
    content = 'bucket'
    if (contentName === 'FILTER') {
      let value = audit.value
      if (audit.name === 'tags') {
        value = getFormattedTagsArray(value || '')
      }
      return { action: actionMap[action], field: `${field} of ${contentName}`, value: `to ${value}`, content, isBucket: true }
    }
    if (audit.name === 'displayType') {
      const selectedNewType = props.displayTypes.find((item) => item.id === changedValue)
      changedValue = selectedNewType.name
      newValue = selectedNewType.name
      const selectedOldType = oldValue ? props.displayTypes.find((item) => item.id === oldValue) : { name: null }
      oldValue = selectedOldType.name
    }
  }
  if (contentType === 'BUCKET_ITEM' && audit.name === 'customMedia' && action === 'UPDATE' && audit.oldValue && audit.value) {
    return { action: actionMap[action], field, value: ` from ${replaceIdwithItems([audit.oldValue], props)} to ${replaceIdwithItems([audit.value], props)}`, content, isBucket }
  }
  if (audit.isPosChange) {
    if (contentType === 'BUCKET_GROUP') {
      field = replaceIdwithItems([field], props)
      return { action: 'Changed position of bucket', field, value: `from ${parseInt(audit.oldValue) + 1} to ${parseInt(audit.value) + 1}`, content, isBucket: false }
    } else if (contentType === 'BUCKET_ITEM') {
      return { action: 'Changed position of bucket item', field, value: `from ${parseInt(audit.oldValue) + 1} to ${parseInt(audit.value) + 1}`, content, isBucket: true }
    }
  }
  if (field === 'isArchived') {
    return { action: (newValue === 'true' || newValue === true) ? 'Bucket archived' : 'Bucket restored', field: '', value: '', content: '', isBucket, isBoolean: true }
  }
  if (field === 'isPublished') {
    return { action: (newValue === 'true' || newValue === true) ? 'Bucket published' : 'Bucket unpublished', field: '', value: '', content: '', isBucket, isBoolean: true }
  }

  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  const arrayChanges = getArrayChanges(newValue, oldValue, props, (field === 'customMedia' || field === 'bucket' || field === 'bucket item'))
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content, isBucket }
  }
  changedValue = audit.name === 'publishStartDate' || audit.name === 'publishEndDate' ? moment(changedValue).format('DD MMM YYYY') : changedValue
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content, isBucket }
}

const getFormattedContentDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let changedValue = audit.value
  let oldValue = audit.name === 'tags' ? getFormattedTagsArray(audit.oldValue || '') : audit.oldValue
  let newValue = audit.name === 'tags' ? getFormattedTagsArray(audit.value || '') : audit.value
  let content
  if (audit.name === 'settings' && !_.isEmpty(props.mediaDetails)) {
    oldValue = JSON.parse(audit.oldValue)
    newValue = JSON.parse(audit.value)
    const { mediaDetails } = props
    const selectedAspectRatio = ((!_.isEmpty(mediaDetails.aspectRatio) && mediaDetails.aspectRatio) || []).find((item) => item.aspectRatio === contentName)
    const filedName = oldValue.fileName === newValue.fileName ? 'crop' : 'image'
    const currentAction = oldValue.fileName === newValue.fileName ? actionMap[action] : 'Replaced'
    return { action: currentAction, field: filedName, value: `in aspect ratio ${selectedAspectRatio && selectedAspectRatio.title ? selectedAspectRatio.title : contentName}` }
  }
  if (audit.name === 'fileName') {
    return { action: 'Replaced', field: 'image', value: `with another image` }
  }
  if (field === 'isArchived') {
    return { action: (newValue === 'true' || newValue === true) ? 'Media archived' : 'Media restored', field: '', value: '', content: '' }
  }
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  const arrayChanges = getArrayChanges(newValue, oldValue, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}` }
}

const getFormattedChannelDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let content
  if (audit.name === 'isArchived') {
    return { action: audit.value === 'true' ? 'Archived Channel' : 'Restored Channel' }
  }
  if (contentType === 'CHANNEL') {
    if (field === 'name') {
      return { action: 'Updated', field: `${field} to `, value: audit.value }
    }
    if (field === 'site') {
      content = audit.value === 'PRI' ? 'PRIMARY' : audit.value === 'SEC' ? 'SECONDARY' : 'MULTI'
      return { action: 'Updated', field: `${field} to `, value: content }
    }
  }

  if (contentType === 'MODULE_SETTING') {
    field = replaceIdwithItems([field], props)
    content = audit.value === 'true' ? 'Enabled' : 'Disabled'
    return { action: `${content} ${contentName} -`, field }
  }

  if (contentType === 'CHANNEL' && audit.name === 'icon') {
    field = 'icon'
  }

  if (contentType === 'CHANNEL_CONFIG_GROUP' && audit.isPosChange) {
    field = contentName
    field = replaceIdwithItems([field], props)
    return { action: 'Changed position of Config Group', field, value: `from ${parseInt(audit.oldValue)} to ${parseInt(audit.value)}` }
  }

  if (contentType === 'STREAM') {
    field = contentName
    field = replaceIdwithItems([field], props)
    const value = audit.value
    const content = audit.displayName
    return { action: `${actionMap[action]}`, field: `${field} - ${audit.name}`, value: `to ${value}`, content: content }
  }

  if (contentType === 'CHANNEL' && audit.name === 'activeConfigGroup') {
    field = 'activeConfigGroup'
  }

  let changedValue = audit.value
  const oldValue = audit.oldValue
  const newValue = audit.value
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }

  let mediaName = replaceIdwithItems([changedValue], props)
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${mediaName}` }
}

const getFormattedMatchDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let changedValue = audit.value
  let oldValue = audit.name === 'tags' ? getFormattedTagsArray(audit.oldValue || '') : audit.oldValue
  let newValue = audit.name === 'tags' ? getFormattedTagsArray(audit.value || '') : audit.value
  let content
  if (action === 'UPDATE' && !oldValue && !newValue) {
    return { action: 'Removed', field, value: `from the match` }
  }
  if (audit.name === 'Meta Field') {
    let auditAction = 'ADD'
    if (!newValue || newValue === '[]' || newValue === 'null' || newValue.length === 0) {
      auditAction = 'REMOVE'
    } else if (audit.oldValue) {
      auditAction = 'UPDATE'
    }
    if (auditAction === 'REMOVE') {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: audit.oldValue, content }
    } else {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: `${['REMOVE', 'ADD'].indexOf(auditAction) > -1 ? '-' : `from ${audit.oldValue} to`} ${audit.value || null}`, content }
    }
  }
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  const arrayChanges = getArrayChanges(newValue, oldValue, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }

  if (audit.name === 'customMeta' && audit.value !== null) {
    let changedOldValue = audit.oldValue; let changedNewValue = audit.value
    changedNewValue = changedNewValue.split(',')
    changedNewValue[0] = changedNewValue[0].substring(1)
    const newValLength = changedNewValue.length - 1
    changedNewValue[newValLength] = changedNewValue[newValLength].substring(0, changedNewValue[newValLength].length - 1)

    changedOldValue = changedOldValue.split(',')
    changedOldValue[0] = changedOldValue[0].substring(1)
    const oldValLength = changedOldValue.length - 1
    changedOldValue[oldValLength] = changedOldValue[oldValLength].substring(0, changedOldValue[oldValLength].length - 1)
    let changedString = ''
    let index = 0
    for (index; index < changedNewValue.length; index += 3) {
      const tempItem = changedNewValue[index]
      const tempItemName = changedNewValue[index + 1]
      const oldIndex = changedOldValue.indexOf(tempItem)
      if (oldIndex > -1) {
        if (changedOldValue[oldIndex + 2] !== changedNewValue[index + 2]) {
          if (changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
            continue
          }
          if (changedOldValue[oldIndex + 2] === 'undefined' || changedOldValue[oldIndex + 2] === '' || changedOldValue[oldIndex + 2] === 'null' || changedOldValue[oldIndex + 2] === null || changedOldValue[oldIndex + 2] === undefined) {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Added ${tempItemName} - ${changedNewValue[index + 2]}, `
          } else if (changedNewValue[index + 2] === '' || changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null') {
            changedString += `Removed ${tempItemName}, `
          } else {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Updated ${tempItemName} from ${changedOldValue[oldIndex + 2]} to ${changedNewValue[index + 2]}, `
          }
        }
      } else {
        changedString += `Added ${tempItemName} - ${changedNewValue[index + 2] === '' ? null : changedNewValue[index + 2]}, `
        if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined || changedNewValue[index + 2] === '') {
          continue
        }
      }
    }
    changedString = changedString.substr(0, changedString.length - 2)
    return { action: changedString, field: '', value: '', content: '' }
  }
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}` }
}

const getFormattedJobDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let changedValue = audit.value
  return { action: 'Recreated', field: `${field === 'VOD_JOB' ? 'Vod Job' : ''}`, value: ` - ${changedValue}` }
}

const getFormattedCustomerHistoryDetails = (audit, action, contentType, contentName, props) => {
  let changedValue = ''
  let value = ''
  if (audit.name === 'UpdateProfile') {
    changedValue = 'Customer Profile'
  } else if (audit.name === 'changePassword') {
    changedValue = 'Customer Password'
  } else if (audit.name === 'updateSubscription') {
    changedValue = 'Subscription'
    value = `of ${audit.value}`
  } else if (audit.name === 'forgotPassword') {
    return { action: 'Sent', field: `Reset Password Email`, value: `` }
  } else if (audit.name === 'logout') {
    return { action: 'Customer', field: `Logged out from all devices`, value: `` }
  } else if (audit.name === 'disableUser') {
    return { action: 'Customer', field: `disabled`, value: `` }
  } else if (audit.name === 'enableUser') {
    return { action: 'Customer', field: `enabled`, value: `` }
  } else if (audit.name === 'Cancel Subscription') {
    return { action: 'Cancelled', field: `Subscription`, value: `of ${audit.value}` }
  } else if (audit.name === 'createSubscription') {
    return { action: 'Created', field: `Subscription`, value: `of ${audit.value}` }
  } else if (audit.name === 'status' && contentName !== 'Disconnect User' && contentName !== 'Switch Financial Account User') {
    return { action: 'Status:', field: `${audit.value}` }
  } else if (contentName === 'Subscription' && audit.name === 'offerCode') {
    return { action: 'Create', field: `Subscription`, value: `with offerCode: ${audit.value}` }
  } else if (contentName === 'Change Password') {
    return { action: 'Change', field: `Password` }
  } else if (contentName === 'Forgot Password') {
    return { action: 'Forgot', field: `Password` }
  } else if (contentName === 'Logout') {
    return { action: 'User', field: `Logged Out` }
  } else if (contentName === 'Logout Device' && audit.name === 'device') {
    return { action: 'User', field: `Logged Out`, value: `of device: ${audit.value}` }
  } else if (contentName === 'Disable User') {
    return { action: 'User', field: `Disabled` }
  } else if (contentName === 'Enable User') {
    return { action: 'User', field: `Enabled` }
  } else if (contentName === 'Cancel Subscription' && audit.name === 'cancelSubscription') {
    return { action: 'Cancelled', field: `Subscription`, value: `with id: ${audit.value}` }
  } else if (contentName === 'Update Subscription' && audit.name === 'id') {
    return { action: 'Updated', field: `Subscription`, value: `with id: ${audit.value}` }
  } else if (contentName === 'Create User') {
    return { action: 'User', field: `Created` }
  } else if (contentName === 'NAVIGATE') {
    return { action: `${audit.name} visited`, field: `${audit.value} table` }
  } else if (contentName === 'Update Profile') {
    if (!audit.oldValue) { return { action: `Updated`, field: audit.name, value: `to ${audit.name === 'dateOfBirth' ? moment(audit.value).format('DD MMM YYYY') : audit.value}` } }
    return { action: `Updated`, field: audit.name, value: `from ${audit.name === 'dateOfBirth' ? moment(audit.oldValue).format('DD MMM YYYY') : audit.oldValue} to ${audit.name === 'dateOfBirth' ? moment(audit.value).format('DD MMM YYYY') : audit.value}` }
  } else if (contentName === 'Update User Attributes') {
    if (!audit.oldValue) { return { action: `Updated`, field: 'Admin Attribute', value: ` ${audit.name} to ${audit.value}` } }
    return { action: `Updated`, field: 'Admin Attribute', value: `${audit.name} from ${audit.oldValue} to ${audit.value}` }
  } else if (contentName === 'Refund' && audit.name === 'offerId') {
    return { action: 'Initiated', field: `Refund`, value: `for offerId: ${audit.value}` }
  } else if (contentName === 'Refund' && audit.name === 'status') {
    return { action: 'Status:', field: `${audit.value}` }
  } else if (contentType === 'SEARCH' && action === 'NAVIGATE') {
    return { action: `Search for`, field: `${audit.value}` }
  } else if (audit.name === 'status' && contentName === 'Disconnect User') {
    return { action: `Disconnect Partner account`, field: `${audit.value}` }
  } else if (audit.name === 'Account ID' && contentName === 'Switch Financial Account User') {
    return { action: `Successfully switched  ${audit.value} as `, field: ` Default FA ` }
  } else if (contentName === 'Send User Anti Piracy Action') {
    return { action: `Added Anti Piracy Action`, field: audit.name, value: `with internal notes ${audit.fieldName}` }
  }

  return { action: actionMap[action], field: `${changedValue}`, value }
}

const replaceIdwithItems = (ids, props, contentType) => {
  const { bucketItemDetails, mediaItemDetails, bucketGroupItemDetails,
    channelConfigItemDetails, ConfigGroupSchemaDetails, streamDetails, displayTypeList, assetDetails, mediaCategoryList, appList, streamList } = props
  const modifiedIds = ids.map(id => {
    const bucket = (bucketItemDetails || []).find(item => item.id === id)
    if (bucket) {
      return bucket.type === 'ASSET' ? `Asset (${bucket.asset ? bucket.asset.id : null})` : 'Filter'
    }
    const assetMedia = ((assetDetails && !_.isEmpty(assetDetails.images) && assetDetails.images) || []).find(item => item.id === id)
    if (assetMedia) {
      const name = assetMedia.name.length > 25 ? assetMedia.name.substring(0, 25) + '...' : assetMedia.name
      return `${name} (${assetMedia.type.name})`
    }
    const media = (mediaItemDetails || []).filter(item => item).find(item => item.id === id)
    if (media) {
      if (props.details && props.details.contentType && props.details.updatedObj && props.details.contentType === 'PARTNER' && props.details.updatedObj[0] && (props.details.updatedObj[0].name === 'logo' || props.details.updatedObj[0].name === 'images')) {
        return `${media.name}`
      }
      return `${media.name} (${media.type ? media.type.toLowerCase() : ''})`
    }
    const bucketGroupItems = (bucketGroupItemDetails || []).find(item => item.id === id)
    if (bucketGroupItems && bucketGroupItems.bucket) {
      return `${bucketGroupItems.bucket.key.toLowerCase()}`
    }
    const configGroup = (channelConfigItemDetails && channelConfigItemDetails.id ? channelConfigItemDetails : {})
    if (configGroup && configGroup.id === id) {
      return `${channelConfigItemDetails.name}`
    }
    const configGroupScheme = (ConfigGroupSchemaDetails && ConfigGroupSchemaDetails.id ? ConfigGroupSchemaDetails : {})
    if (configGroupScheme && configGroupScheme.id === id) {
      return `${ConfigGroupSchemaDetails.name}`
    }

    const streamScheme = (streamDetails && streamDetails.id ? streamDetails : {})
    if (streamScheme && streamScheme.id === id) {
      return `${streamScheme.levels && streamScheme.levels.length ? streamScheme.levels[0].name : ''}`
    }
    const displayType = (displayTypeList || []).find(item => item.id === id)
    if (displayType) {
      return `${displayType.name}`
    }
    const categoryList = (mediaCategoryList || []).find(item => item.id === id)
    if (categoryList) {
      return `${categoryList.label}`
    }
    const apps = (appList || []).find(item => item.id === id)
    if (apps) {
      return `${apps.name}`
    }
    const streamId = (streamList || []).find(item => item.id === id)
    if (streamId) {
      return `${streamId.name}`
    }
    return id
  })

  return modifiedIds
}

const getArrayChanges = (newValue, oldValue, props, isCustomMedia = false) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || (isCustomMedia && (newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = replaceIdwithItems(_.difference(newArray, oldArray), props)
    const removedItems = replaceIdwithItems(_.difference(oldArray, newArray), props)
    if (newItems.length && removedItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} also Removed ${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = removedItems.join(', ').replace(lastReplaceRegex, ' and $1')
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = newItems.join(', ').replace(lastReplaceRegex, ' and $1')
    }
  } else return
  return { changedValue, action }
}

const getArrayChangesForContent = (newValue, oldValue, props, audit, isCustomMedia = false) => {
  let changedValue = ''; let action
  if ((newValue && (newValue.indexOf('[')) === 0) || (isCustomMedia && (newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = replaceIdwithItems(_.difference(newArray, oldArray), props)
    let newItemsIds = []
    let newItemsTypes = [];
    (newItems || []).map(item => {
      let tempArray = item.split(',')
      if (tempArray[0]) { newItemsIds.push(tempArray[0]) }
      if (tempArray[1]) { newItemsTypes.push(tempArray[1]) }
    })
    const removedItems = replaceIdwithItems(_.difference(oldArray, newArray), props)
    let removedItemsIds = []
    let removedItemsTypes = [];
    (removedItems || []).map(item => {
      let tempArray = item.split(',')
      if (tempArray[0]) { removedItemsIds.push(tempArray[0]) }
      if (tempArray[1]) { removedItemsTypes.push(tempArray[1]) }
    })

    if (newItems.length && removedItems.length) {
      action = 'ADD'
      newItemsIds.map((id, index) => {
        // const contentName = replaceIdwithItems([id], props)
        // changedValue += ` ${changedValue ? (index === (newItemsIds.length - 1) ? 'and' : ',') : ''} ${contentName[0].replace(lastReplaceRegex, ' and $1')} of type ${newItemsTypes[index]} `
        changedValue += ` ${changedValue ? (index === (newItemsIds.length - 1) ? 'and' : ',') : ''} ${newItemsTypes[index]} `
      })
      removedItemsIds.map((id, index) => {
        // const contentName = replaceIdwithItems([id], props)
        //
        // changedValue += ` ${index !== 0 ? (index === (removedItemsIds.length - 1) ? 'and' : ',') : 'also Removed'} ${contentName[0].replace(lastReplaceRegex, ' and $1')} of type ${removedItemsTypes[index]} `
        changedValue += ` ${index !== 0 ? (index === (removedItemsIds.length - 1) ? 'and' : ',') : 'also Removed'}  ${removedItemsTypes[index]} `
      })
      // changedValue += `${newItemsIds.join(', ').replace(lastReplaceRegex, ' and $1')} also Removed ${removedItemsIds.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedItems.length) {
      action = 'REMOVE'
      removedItemsIds.map((id, index) => {
        // const contentName = replaceIdwithItems([id], props)
        // changedValue += ` ${changedValue ? (index === (removedItemsIds.length - 1) ? 'and' : ',') : ''} ${contentName[0].replace(lastReplaceRegex, ' and $1')} of type ${removedItemsTypes[index]} `
        changedValue += ` ${changedValue ? (index === (removedItemsIds.length - 1) ? 'and' : ',') : ''}  ${removedItemsTypes[index]} `
      })
      // changedValue = removedItems.join(', ').replace(lastReplaceRegex, ' and $1')
    } else if (newItems.length) {
      action = 'ADD'
      newItemsIds.map((id, index) => {
        // const contentName = replaceIdwithItems([id], props)
        // changedValue += ` ${changedValue ? (index === (newItemsIds.length - 1) ? 'and' : ',') : ''} ${contentName[0].replace(lastReplaceRegex, ' and $1')} of type ${newItemsTypes[index]} `
        changedValue += ` ${changedValue ? (index === (newItemsIds.length - 1) ? 'and' : ',') : ''}  ${newItemsTypes[index]} `
      })
      // changedValue += newItems.join(', ').replace(lastReplaceRegex, ' and $1')
    }
  } else return
  return { changedValue, action }
}

const getArrayChangesForLogo = (newValue, oldValue, props, isCustomMedia = false) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || (isCustomMedia && (newValue || oldValue))) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = replaceIdwithItems(_.difference(newArray, oldArray), props)
    const removedItems = replaceIdwithItems(_.difference(oldArray, newArray), props)
    if (newItems.length && removedItems.length) {
      action = 'UPDATE'
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} from ${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = removedItems.join(', ').replace(lastReplaceRegex, ' and $1')
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = newItems.join(', ').replace(lastReplaceRegex, ' and $1')
    }
  } else return
  return { changedValue, action }
}

const getFormattedAssetHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details

  let descriptions = (updatedObj || []).map(item => getFormattedAssetDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (contentName === 'AUTOASSOCIATION') {
    descriptions = [{ action: `${updatedObj && updatedObj[0].value}` }]
  }
  if (action === 'DELETE' && contentType === 'LINK_ASSET_PROGRAM') {
    descriptions = [{ action: `${actionMap[action]}`, field: 'channel program' }]
  }
  return descriptions.map((description, i) => {
    if (!description || (description && description.action === '' && description.content === '')) return null
    return (
      <p className='description' key={i}>
        {description.action && description.action !== '' ? description.action : null}
        {
          description.field !== '' && <span style={!description.action ? { marginLeft: '0px' } : {}}>{description.field}</span>
        }
        {description.value}
        {description.content ? ' in ' : null}
        {description.content ? <span style={{ marginLeft: '0px' }}>{description.content}</span> : null}
      </p>
    )
  })
}

const getFormattedBucketHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName, contentId } = details
  let descriptions = (updatedObj || []).map(item => getFormattedBucketDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}`, isBucket: details.contentType === 'BUCKET' }]
  }
  return descriptions.map((description, i) => {
    const temp = (description.action === 'Added' && description.field !== 'publish start date') ? 'to' : description.action === 'Removed' ? 'from' : 'of'
    const isBothActionPresent = description.field === 'bucket item' && description.value.indexOf('also Removed') > -1
    if (isBothActionPresent) {
      description.value = description.value.split('also Removed')
    }
    return isBothActionPresent ? <p className='description' key={i}>
      {description.action}
      {description.field ? <span>{description.field}</span> : ' '}
      {description.value[0] + ' to bucket'}
      {contentId ? <span>{contentId}</span> : null}
      {'also Removed' + description.value[1] + ' from bucket'}
      {contentId ? <span>{contentId}</span> : null}
    </p>
      : <p className='description' key={i}>
        {description.action}
        {description.field ? <span>{description.field}</span> : ' '}
        {description.value}
        {!description.isBoolean ? description.isBucket ? (description.action === 'Created' ? `bucket` : ` ${temp} bucket`) : (description.action === 'Created' ? 'bucket group' : ` ${temp} bucket group`) : ''}
        {contentId ? <span>{contentId}</span> : null}
      </p>
  })
}

const getFormattedContentHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedContentDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedMatchHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedMatchDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (contentType === 'ASSET' && action === 'CREATE') {
    const field = contentName
    descriptions = [{ action: `${actionMap[action]} Asset -`, field: field }]
  }

  if (action === 'SYNC') {
    descriptions = [{ action: updatedObj[0].value }]
  }

  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedChannelHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedChannelDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length && contentType === 'CHANNEL') {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (!descriptions.length && contentType === 'CHANNEL_CONFIG_GROUP' && (action === 'DELETE' || action === 'CREATE')) {
    let field = contentName
    field = replaceIdwithItems([field], props)
    descriptions = [{ action: `${actionMap[action]}`, field: `${field}` }]
  }
  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedJobHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedJobDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length && action === 'CREATE') {
    descriptions = [{ action: `${actionMap[action]}` }]
  } else if (!descriptions.length && action === 'UPDATE' && contentName === 'Retrigger Validataion') {
    descriptions = [{ action: actionMap[action], field: 'VOD_JOB', value: `Retriggered Validation` }]
  }

  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedCustomerDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedCustomerHistoryDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }

  if (contentType === 'CSM' && action === 'CREATE') {
    const field = contentName
    descriptions = [{ action: `${actionMap[action]} `, field: field }]
  }

  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span onClick={props.seachField ? () => props.seachField(description.field) : () => { }}>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedOfferDetails = (audit, action, contentType, contentName, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let content
  let priceName = ''
  if (contentType === 'PRICE') {
    const priceNameArray = contentName ? contentName.split(',') : []
    priceName = (priceNameArray && priceNameArray[0] && priceNameArray[1]) ? (priceNameArray[1] || priceNameArray[0]) : ''
    // changedValue += ` in ${priceName}`
  }
  if (audit.name === 'isLive' || audit.name === 'isFree' || audit.name === 'isArchived') {
    return handleBooleanChanges(field, audit)
  }
  if (contentType === 'CONTENT_ITEM' && audit.name === 'media') {
    field = 'media content'
  }
  if (audit.name === 'Meta Field') {
    let auditAction = 'ADD'
    let newValue = audit.value
    if (!newValue || newValue === '[]' || newValue === 'null' || newValue.length === 0) {
      auditAction = 'REMOVE'
    } else if (audit.oldValue) {
      auditAction = 'UPDATE'
    }
    if (auditAction === 'REMOVE') {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: audit.oldValue, content }
    } else {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: `${['REMOVE', 'ADD'].indexOf(auditAction) > -1 ? '-' : `from ${audit.oldValue} to`} ${audit.value}`, content }
    }
  }
  if (contentType === 'RELATED_ASSET') {
    field = ''
    content = 'Selected assets for Offer'
    if (contentName === 'FILTER') {
      let value = audit.value
      if (audit.name === 'tags') {
        value = getFormattedTagsArray(value || '')
      } else if (audit.name === 'type' && (value === 'null' || !value)) {
        return { action: actionMap['REMOVE'], field: `${field} of ${contentName}`, value: ``, content }
      }
      return { action: actionMap[action], field: `${field} of ${contentName}`, value: `to ${value}`, content }
    }
  }
  if (audit.isPosChange) {
    return { action: 'Changed position of', field, value: `from ${parseInt(audit.oldValue)} to ${parseInt(audit.value)}`, content }
  }
  if (audit.name === 'attributes') {
    let changedValueAttributes = audit.value
    let oldValueAttributes = audit.oldValue
    let newValueAttributes = audit.value
    if (((contentType === 'OFFER' || contentType === 'CONFIG_GROUP') && (audit.name === 'attributes' && audit.value !== null))) {
      let content = ''
      // let content = replaceIdwithItems([contentId], props)
      if (!oldValueAttributes || oldValueAttributes === '[]' || oldValueAttributes === 'null') {
        action = 'ADD'
      } else if (!newValueAttributes || newValueAttributes === '[]' || newValueAttributes === 'null') {
        action = 'REMOVE'
      }
      const arrayChangesAttributes = getTemplateArrayChanges(newValueAttributes, oldValueAttributes, props, 'stream-level-fields', audit.name)
      changedValueAttributes = arrayChangesAttributes ? arrayChangesAttributes.changedValue : changedValueAttributes
      action = arrayChangesAttributes ? arrayChangesAttributes.action : action
      if (changedValueAttributes === audit.value && action === 'REMOVE') {
        return { action: actionMap[action], field, value: oldValueAttributes }
      }
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValueAttributes}`, content: content }
    }
  }

  if (audit.name === 'customMeta' && audit.value !== null) {
    let changedOldValue = audit.oldValue; let changedNewValue = audit.value
    changedNewValue = changedNewValue.split(',')
    changedNewValue[0] = changedNewValue[0].substring(1)
    const newValLength = changedNewValue.length - 1
    changedNewValue[newValLength] = changedNewValue[newValLength].substring(0, changedNewValue[newValLength].length - 1)

    changedOldValue = changedOldValue.split(',')
    changedOldValue[0] = changedOldValue[0].substring(1)
    const oldValLength = changedOldValue.length - 1
    changedOldValue[oldValLength] = changedOldValue[oldValLength].substring(0, changedOldValue[oldValLength].length - 1)
    let changedString = ''
    let index = 0
    for (index; index < changedNewValue.length; index += 3) {
      const tempItem = changedNewValue[index]
      const tempItemName = changedNewValue[index + 1]
      const oldIndex = changedOldValue.indexOf(tempItem)
      if (oldIndex > -1) {
        if (changedOldValue[oldIndex + 2] !== changedNewValue[index + 2]) {
          if (changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
            continue
          }
          if (changedOldValue[oldIndex + 2] === 'undefined' || changedOldValue[oldIndex + 2] === '' || changedOldValue[oldIndex + 2] === 'null' || changedOldValue[oldIndex + 2] === null || changedOldValue[oldIndex + 2] === undefined) {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Added ${tempItemName} - ${changedNewValue[index + 2]}, `
          } else if (changedNewValue[index + 2] === '' || changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null') {
            changedString += `Removed ${tempItemName}, `
          } else {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Updated ${tempItemName} from ${changedOldValue[oldIndex + 2]} to ${changedNewValue[index + 2]}, `
          }
        }
      } else {
        changedString += `Added ${tempItemName} - ${changedNewValue[index + 2] === '' ? null : changedNewValue[index + 2]}, `
        if (!(changedNewValue && changedNewValue.length && changedNewValue.filter(item => item).length) && changedOldValue && changedOldValue.length) {
          changedString = ''
          for (let i = 0; i < changedOldValue.length; i += 3) {
            changedString += `Removed ${changedOldValue[i + 1]}, `
          }
        } else if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined || changedNewValue[index + 2] === '') {
          continue
        }
      }
    }
    changedString = changedString.substr(0, changedString.length - 2)
    return { action: changedString, field: '', mainField: 'prices', value: '', content: '', priceName }
  }
  let changedValue = audit.value
  if (audit.name === 'startDate' || audit.name === 'endDate') {
    if (audit.value) {
      changedValue = moment(audit.value).format('DD MMM YYYY HH:mm')
    } else if (audit.oldValue) {
      changedValue = moment(audit.oldValue).format('DD MMM YYYY HH:mm')
    }
  }
  const oldValue = audit.name === 'tags' ? getFormattedTagsArray(audit.oldValue || '') : audit.oldValue
  const newValue = audit.name === 'tags' ? getFormattedTagsArray(audit.value || '') : (audit.name === 'bucketItems' && !audit.value ? '[]' : audit.value)
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  const arrayChanges = getArrayChanges(newValue, oldValue, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue

  // if (audit.name === 'defaultMedia' || audit.name === 'defaultImage') {
  //   const mediaNames = replaceIdwithItems([changedValue], props)
  //   changedValue = mediaNames[0]
  // }
  // if (audit.name === 'categoryId') {
  //   const categoryId = replaceIdwithItems([changedValue], props)
  //   changedValue = categoryId[0]
  //   return { action: actionMap[action], field: 'category', value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  // }
  // if (contentType === 'IMAGE' && audit.name === 'settings') {
  //   const mediaNames = replaceIdwithItems([contentName], props)
  //   // return getFormattedContentDetails(audit, action, contentType, contentName, props)
  //   const aspectRatio = JSON.parse(audit.value).aspectRatio
  //   const assetMedia = (props.assetDetails.images || []).find(item => item.id === contentName)
  //   if (assetMedia) {
  //     changedValue = (assetMedia.aspectRatio || []).find(item => item.aspectRatio === aspectRatio).title
  //   }
  //   return { action: actionMap[action], field: 'crop settings', value: `under ${changedValue} of image ${mediaNames[0]}`, content }
  // }
  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }
  if (contentType === 'PRICE') {
    return { action: actionMap[action], field, mainField: 'prices', value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content, priceName }
  }
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
}

const getFormattedPartnerDetails = (audit, action, contentType, contentName, contentId, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let iskey = false
  let changedValue = audit.name === 'logo' && !audit.value ? '[]' : audit.value
  let oldValue = audit.name === 'logo' && audit.oldValue ? '[' + audit.oldValue + ']' : audit.oldValue
  let newValue = audit.name === 'logo' && audit.value ? '[' + audit.value + ']' : audit.value
  let contentKey = contentId
  if (contentType === 'OFFER_ATTRIBUTE' || contentType === 'AUTHOR' || contentType === 'COMPETITION' || contentType === 'SEASON' || contentType === 'TEAM' || contentType === 'PLAYER' || contentType === 'ASPECT_RATIO' || contentType === 'RESOLUTION' || contentType === 'MEDIA_CATEGORY' || contentType === 'IMAGE_TYPE') {
    contentKey = replaceIdwithItems([contentKey], props)
  }
  if ((contentType === 'ASSET_TYPE' || contentType === 'AUTHOR') && (audit.name === 'icon' || audit.name === 'draftIcon' || audit.name === 'media')) {
    newValue = replaceIdwithItems([newValue], props)
  }
  if (audit.name === 'Meta Field') {
    let content
    let auditAction = 'ADD'
    let newValue = audit.value
    if (!newValue || newValue === '[]' || newValue === 'null' || newValue.length === 0) {
      auditAction = 'REMOVE'
    } else if (audit.oldValue) {
      auditAction = 'UPDATE'
    }
    if (auditAction === 'REMOVE') {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: audit.oldValue, content }
    } else {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: `${['REMOVE', 'ADD'].indexOf(auditAction) > -1 ? '-' : `from ${audit.oldValue} to`} ${audit.value}`, content }
    }
  }
  if (contentType === 'ASSET_TYPE' && (audit.name === 'matchSection' || audit.name === 'authorSection' || audit.name === 'programSection' || audit.name === 'contentSection' || audit.name === 'videoSection' || audit.name === 'isMatchRelated' || audit.name === 'isLveToVodSupported')) {
    return { action: audit.value === 'true' ? 'Enabled ' : 'Disabled ', field, value: `of ${contentKey}` }
  }
  if (((contentType === 'OFFER_ATTRIBUTE' || contentType === 'CONFIG_GROUP') && (audit.name === 'Offer Attribute'))) {
    let content = ''
    // let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    let arrayChanges
    if (audit.isPosChange === true) {
      arrayChanges = getPartnerTemplateArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name)
    } else {
      arrayChanges = getTemplateArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name)
    }

    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }

  if (contentType === 'PARTNER' && audit.name !== 'Offer Attribute' && audit.name !== 'customMeta') {
    let content
    let changedValue = audit.value
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    if ((audit.name === 'logo') && !audit.value) {
      newValue = '[]'
    }
    let arrayChanges
    if (audit.name === 'logo') {
      arrayChanges = getArrayChangesForLogo(newValue, oldValue, props)
    } else {
      arrayChanges = getArrayChanges(newValue, oldValue, props)
    }
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    // if (audit.name === 'images' || audit.name === 'logo') {
    //   // if(!changedValue || changedValue === null || changedValue === 'null'){
    //   //   changedValue=''
    //   // }
    //   const mediaNames = replaceIdwithItems([changedValue], props)
    //   changedValue = mediaNames[0]
    // }
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: audit.oldValue, content }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  }

  if (audit.name === 'customMeta' && audit.value !== null) {
    let changedOldValue = audit.oldValue; let changedNewValue = audit.value
    changedNewValue = changedNewValue.split(',')
    changedNewValue[0] = changedNewValue[0].substring(1)
    const newValLength = changedNewValue.length - 1
    changedNewValue[newValLength] = changedNewValue[newValLength].substring(0, changedNewValue[newValLength].length - 1)

    changedOldValue = changedOldValue.split(',')
    changedOldValue[0] = changedOldValue[0].substring(1)
    const oldValLength = changedOldValue.length - 1
    changedOldValue[oldValLength] = changedOldValue[oldValLength].substring(0, changedOldValue[oldValLength].length - 1)
    let changedString = ''
    let index = 0
    for (index; index < changedNewValue.length; index += 3) {
      const tempItem = changedNewValue[index]
      const tempItemName = changedNewValue[index + 1]
      const oldIndex = changedOldValue.indexOf(tempItem)
      if (oldIndex > -1) {
        if (changedOldValue[oldIndex + 2] !== changedNewValue[index + 2]) {
          if (changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
            continue
          }
          if (changedOldValue[oldIndex + 2] === 'undefined' || changedOldValue[oldIndex + 2] === '' || changedOldValue[oldIndex + 2] === 'null' || changedOldValue[oldIndex + 2] === null || changedOldValue[oldIndex + 2] === undefined) {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Added ${tempItemName} - ${changedNewValue[index + 2]}, `
          } else if (changedNewValue[index + 2] === '' || changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null') {
            changedString += `Removed ${tempItemName}, `
          } else {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Updated ${tempItemName} from ${changedOldValue[oldIndex + 2]} to ${changedNewValue[index + 2]}, `
          }
        }
      } else {
        changedString += `Added ${tempItemName} - ${changedNewValue[index + 2] === '' ? null : changedNewValue[index + 2]}, `
        if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined || changedNewValue[index + 2] === '') {
          continue
        }
      }
    }
    changedString = changedString.substr(0, changedString.length - 2)
    return { action: changedString, field: '', value: '', content: '' }
  }

  return { action: actionMap[action], field, value: `${iskey === true ? `from ${oldValue}` : `of ${contentKey}`}  ${newValue && newValue ? `to ${newValue}` : ''}` }
  // return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${mediaName}` }
}

const getUpdatedArray = (add, remove, type, auditName) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(',')
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(',')
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] === removedArray[i][0]) {
            if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2])) {
              updatedString = `${addedArray[j][0]}- displayName to ${addedArray[j][1]} and field to type ${addedArray[j][2]}`
            } else if (addedArray[j][1] !== removedArray[i][1] && auditName === 'attributes') {
              if (addedArray[j][1]) {
                updatedString = `${addedArray[j][0]} value to ${addedArray[j][1]} `
              } else {
                updatedString = `Cleared value of ${addedArray[j][0]} `
              }
            } else if (addedArray[j][1] !== removedArray[i][1]) {
              updatedString = ` ${addedArray[j][0]} ${auditName === 'Offer Attribute' ? '- displayName' : ''} ${auditName === 'attributes' ? '- Value' : ''} to ${addedArray[j][1]} `
            } else {
              updatedString = `${addedArray[j][0]} to type ${addedArray[j][2]}`
            }
            removeIndex.push(i)
            addedIndex.push(j)
          }
          updatedArray.push(updatedString)
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  return { updatedArray, addedArray, removedArray }
}

const getUpdatedArrayPartnerAttributes = (add, remove, type, auditName) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(',')
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(',')
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] === removedArray[i][0]) {
            if ((addedArray[j][3] !== removedArray[i][3])) {
              updatedString = `Changed ${addedArray[j][0]} position from ${removedArray[i][3]} to ${addedArray[j][3]}`
            }
            removeIndex.push(i)
            addedIndex.push(j)
          }
          updatedArray.push(updatedString)
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  addedArray = []
  removedArray = []
  return { updatedArray, addedArray, removedArray }
}

const getUpdatedArrayFieldAttributes = (add, remove, type, auditName, contentType) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(',')
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(',')
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] === removedArray[i][0]) {
            if ((addedArray[j][1] !== removedArray[i][1])) {
              updatedString = ` Property Name from ${removedArray[i][1]} to ${addedArray[j][1]}`
            }
            if ((addedArray[j][2] !== removedArray[i][2])) {
              updatedString = ` ${addedArray[j][1]} Property Type from ${removedArray[i][2]} to ${addedArray[j][2]}`
            }
            if ((addedArray[j][3] !== removedArray[i][3])) {
              updatedString += `${updatedString ? ' and updated ' : ''} ${addedArray[j][1]} Mandatory field from ${removedArray[i][3]} to ${addedArray[j][3]}`
            }
            if ((addedArray[j][4] !== removedArray[i][4])) {
              updatedString += `${updatedString ? ' also updated ' : ''} ${addedArray[j][1]} useAsTitle from ${removedArray[i][4]} to ${addedArray[j][4]}`
            }
            if ((addedArray[j][5] !== removedArray[i][5]) && contentType === 'APP_CONFIG') {
              updatedString += `${updatedString ? ' also updated ' : ''} ${addedArray[j][1]} toolTip text from ${removedArray[i][5]} to ${addedArray[j][5]}`
            }
            if ((addedArray[j][5] !== removedArray[i][5]) && contentType === 'APP_WIDGET') {
              updatedString += `${updatedString ? ' also updated ' : ''} ${addedArray[j][1]} is Editable from ${removedArray[i][5]} to ${addedArray[j][5]}`
            }
            removeIndex.push(i)
            addedIndex.push(j)
          }
          if (updatedString) {
            updatedArray.push(updatedString)
            updatedString = ''
          }
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  addedIndex = (addedIndex || []).sort()
  removeIndex = (removeIndex || []).sort()
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  // addedArray = []
  // removedArray = []
  return { updatedArray, addedArray, removedArray }
}

const getUpdatedArrayFieldValueAttributes = (add, remove, type, auditName, props, contentType) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(',')
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(',')
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] === removedArray[i][0]) {
            if ((addedArray[j][1] !== removedArray[i][1]) && addedArray[j][1]) {
              const value = replaceIdwithItems([addedArray[j][1]], props)
              updatedString = `updated ${addedArray[j][0]} value from ${removedArray[i][1]}  to ${value || addedArray[j][1]}  (${addedArray[j][2]})`
            } else {
              updatedString = `Cleared value of ${addedArray[j][0]} from ${removedArray[i][0]}`
            }
            // if ((addedArray[j][2] !== removedArray[i][2])) {
            //   updatedString = `${updatedString ? updatedString + ',' : ''}Changed ${addedArray[j][0]} Property Type from ${removedArray[i][2]} to ${addedArray[j][2]}`
            // }
            // if ((addedArray[j][3] !== removedArray[i][3])) {
            //   updatedString = `${updatedString ? updatedString + ',' : ''}Changed ${addedArray[j][0]} useAsTitle field from ${removedArray[i][3]} to ${addedArray[j][3]}`
            // }
            // if ((addedArray[j][4] !== removedArray[i][4])) {
            //   updatedString = `${updatedString ? updatedString + ',' : ''}Changed ${addedArray[j][0]} Mandatory from ${removedArray[i][4]} to ${addedArray[j][4]}`
            // }
            removeIndex.push(i)
            addedIndex.push(j)
          }
          updatedArray.push(updatedString)
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  // addedArray = []
  // removedArray = []
  return { updatedArray, addedArray, removedArray }
}

const getTemplateArrayChanges = (newValue, oldValue, props, type, auditName) => {
  let changedValue; let action; let arrayItems = {}; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    let newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    oldArrayString = (oldArrayString || '').replace(/, /g, ',')
    newArrayString = (newArrayString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(',') : []
    const oldArray = oldArrayString ? oldArrayString.split(',') : []

    if (type === 'stream-level-fields') {
      n = 3
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      arrayItems = getUpdatedArray(addedArrayItem, removedArrayItem, type, auditName)
      addedArray = (arrayItems.addedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return `${item[0]} with${item[1] === 'true' ? ' mandatory ' : ' '} field of type ${item[2]}`
        }
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return `${item[0]} with${item[1] === 'true' ? ' mandatory ' : ' '} field of type ${item[2]}`
        }
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }
    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action }
}

const getPartnerTemplateArrayChanges = (newValue, oldValue, props, type, auditName) => {
  let changedValue; let action; let arrayItems = {}; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '').split(' ')
    let newArrayString = (newValue || '[]').replace('[', '').replace(']', '').split(' ')
    let tempOldArrayString = []
    let tempNewArrayString = [];
    (oldArrayString || []).forEach(item => {
      if ((item.split(',').length === 4) && item.split(',')[3] !== '') {
        tempOldArrayString.push(item)
      } else if ((item.split(',').length === 4) && item.split(',')[3] === '') {
        tempOldArrayString.push(item + 'null')
      } else if ((item.split(',').length > 4) && item.split(',')[3] !== '') {
        const newItem = item.slice(0, item.length - 1)
        tempOldArrayString.push(newItem)
      } else if ((item.split(',').length < 4)) {
        tempOldArrayString.push(item + ',null')
      }
    });
    (newArrayString || []).forEach(item => {
      if ((item.split(',').length === 4) && item.split(',')[3] !== '') {
        tempNewArrayString.push(item)
      } else if ((item.split(',').length === 4) && item.split(',')[3] === '') {
        tempNewArrayString.push(item + 'null')
      } else if ((item.split(',').length > 4) && item.split(',')[3] !== '') {
        const newItem = item.slice(0, item.length - 1)
        tempNewArrayString.push(newItem)
      } else if ((item.split(',').length < 4)) {
        tempNewArrayString.push(item + ',null')
      }
    })
    const modifiedOldString = tempOldArrayString.toString()
    const modifiedNewString = tempNewArrayString.toString()
    oldArrayString = (modifiedOldString || '').replace(/, /g, ',')
    newArrayString = (modifiedNewString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(',') : []
    const oldArray = oldArrayString ? oldArrayString.split(',') : []

    if (type === 'stream-level-fields') {
      n = 4
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      arrayItems = getUpdatedArrayPartnerAttributes(addedArrayItem, removedArrayItem, type, auditName)
      addedArray = (arrayItems.addedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return `${item[0]} with${item[1] === 'true' ? ' mandatory ' : ' '} field of type ${item[2]}`
        }
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return `${item[0]} with${item[1] === 'true' ? ' mandatory ' : ' '} field of type ${item[2]}`
        }
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }
    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action }
}

const getFormattedAppsArray = tagsString => {
  const tagTypes = ['vimond', 'sport', 'team', 'season', 'competition', 'bucket', 'product']
  // const manualTags = ['asset_manager', 'channel_manager', 'content_bank', 'match_manager', 'data_manager']
  let tags = tagsString.slice(1, tagsString.length - 1).split(',')
  tags = tags.map((tag) => {
    _.forEach(tagTypes, (item) => {
      const n = tag.lastIndexOf(item)
      if (n > -1) {
        tag = `${tag.substring(0, n)}(${item}) `
      }
    })
    // _.forEach(manualTags, (item) => {
    //   const n = tag.lastIndexOf(item)
    //   if (n > -1) {
    //     tag = `${tag.substring(0, n)}(manual)`
    //   }
    // })
    return tag
  }).join(',')
  return `[${tags}]`
}

const getAppConfigFieldValuesTemplateArrayChanges = (newValue, oldValue, props, type, auditName, contentType) => {
  let changedValue; let action; let arrayItems = {}; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '').split(' ')
    let newArrayString = (newValue || '[]').replace('[', '').replace(']', '').split(' ')
    let tempOldArrayString = []
    let tempNewArrayString = [];
    (oldArrayString || []).forEach(item => {
      if ((item.split(',').length === 4) && item.split(',')[3] !== '') {
        tempOldArrayString.push(item)
      } else if ((item.split(',').length === 4) && item.split(',')[3] === '') {
        tempOldArrayString.push(item + 'null')
      } else if ((item.split(',').length > 4) && item.split(',')[3] !== '') {
        const newItem = item.slice(0, item.length - 1)
        tempOldArrayString.push(newItem)
      } else if ((item.split(',').length === 3)) {
        tempOldArrayString.push(item + ',null')
      }
    });
    (newArrayString || []).forEach(item => {
      if ((item.split(',').length === 4) && item.split(',')[3] !== '') {
        tempNewArrayString.push(item)
      } else if ((item.split(',').length === 4) && item.split(',')[3] === '') {
        tempNewArrayString.push(item + 'null')
      } else if ((item.split(',').length > 4) && item.split(',')[3] !== '') {
        const newItem = item.slice(0, item.length - 1)
        tempNewArrayString.push(newItem)
      } else if ((item.split(',').length === 3)) {
        tempNewArrayString.push(item + ',null')
      }
    })
    const modifiedOldString = tempOldArrayString.toString()
    const modifiedNewString = tempNewArrayString.toString()
    oldArrayString = (modifiedOldString || '').replace(/, /g, ',')
    newArrayString = (modifiedNewString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(',') : []
    const oldArray = oldArrayString ? oldArrayString.split(',') : []

    if (type === 'stream-level-fields') {
      n = 4
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      arrayItems = getUpdatedArrayFieldValueAttributes(addedArrayItem, removedArrayItem, type, auditName, contentType)
      addedArray = (arrayItems.addedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return `${item[0]} with value ${item[1] ? item[1] : ''} of type ${item[2]}`
        }
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return ` of ${item[0]} of type ${item[2]}`
        }
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }
    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action, field: auditName }
}

const getAppConfigTemplateArrayChanges = (newValue, oldValue, props, type, auditName, contentType) => {
  let changedValue; let action; let arrayItems = {}; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '').split(', ')
    let newArrayString = (newValue || '[]').replace('[', '').replace(']', '').split(', ')
    let tempOldArrayString = []
    let tempNewArrayString = [];
    (oldArrayString || []).forEach(item => {
      if ((item.split(',').length === 6) && item.split(',')[5] !== '') {
        tempOldArrayString.push(item)
      } else if ((item.split(',').length === 6) && item.split(',')[5] === '') {
        tempOldArrayString.push(item + 'null')
      } else if ((item.split(',').length > 6) && item.split(',')[5] !== '') {
        const newItem = item.slice(0, item.length - 1)
        tempOldArrayString.push(newItem)
      } else if ((item.split(',').length < 6)) {
        tempOldArrayString.push(item + ',null')
      }
    });
    (newArrayString || []).forEach(item => {
      if ((item.split(',').length === 6) && item.split(',')[5] !== '') {
        tempNewArrayString.push(item)
      } else if ((item.split(',').length === 6) && item.split(',')[5] === '') {
        tempNewArrayString.push(item + 'null')
      } else if ((item.split(',').length > 6) && item.split(',')[5] !== '') {
        const newItem = item.slice(0, item.length - 1)
        tempNewArrayString.push(newItem)
      } else if ((item.split(',').length < 6)) {
        tempNewArrayString.push(item + ',null')
      }
    })
    const modifiedOldString = tempOldArrayString.toString()
    const modifiedNewString = tempNewArrayString.toString()
    oldArrayString = (modifiedOldString || '').replace(/, /g, ',')
    newArrayString = (modifiedNewString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(',') : []
    const oldArray = oldArrayString ? oldArrayString.split(',') : []

    if (type === 'stream-level-fields') {
      n = 6
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      arrayItems = getUpdatedArrayFieldAttributes(addedArrayItem, removedArrayItem, type, auditName, contentType)
      addedArray = (arrayItems.addedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return ` ${item[1]} ${item[3] === 'true' ? ' mandatory ' : ' '} field of type ${item[2]} ${item[4] === 'true' ? ' useAsTitle set to true ' : ' '} ${item[5] === 'true' && contentType === 'APP_WIDGET' ? ' is Editable set to true ' : ' '} ${item[5] && contentType === 'APP_CONFIG' ? ' tooltip text ' + item[5] : ' '}`
        }
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        if (auditName === 'attributes') {
          return `${item[0]} field of type ${item[2]}`
        } else {
          return ` ${item[1]} ${item[3] === 'true' ? ' mandatory ' : ' '} field of type ${item[2]} `
        }
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }
    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action, field: auditName }
}

const getFormattedOfferHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedOfferDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (action === 'DELETE' && contentType === 'LINK_ASSET_PROGRAM') {
    descriptions = [{ action: `${actionMap[action]}`, field: 'channel program' }]
  }
  return descriptions.map((description, i) => {
    if (contentType === 'PRICE') {
      return (<p className='description' key={i}>
        {description.action}
        <span>{description.field}</span>
        {description.value}
        {description.priceName ? ' in' : null}
        {description.priceName ? <span>{description.mainField}</span> : null}
        {description.priceName ? '- ' + description.priceName : null}
      </p>)
    } else {
      return (<p className='description' key={i}>
        {description.action}
        <span>{description.field}</span>
        {description.value}
        {description.content ? ' in' : null}
        {description.content ? <span>{description.content}</span> : null}
      </p>)
    }
  })
}

const getTemplateRatingArrayChanges = (newValue, oldValue, props, type, auditName) => {
  let changedValue; let field; let action; let arrayItems = {}; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString
    let newArrayString
    if (oldValue === '[]') {
      oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    } else {
      oldArrayString = (oldValue || '[]').replace(/^\[(.+)\]$/, '$1')
    }

    if (newValue === '[]') {
      newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    } else {
      newArrayString = (newValue || '[]').replace(/^\[(.+)\]$/, '$1')
    }

    oldArrayString = (oldArrayString || '').replace(/, /g, ',')
    newArrayString = (newArrayString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(/,(?=[^\]]*(?:\[|$))/) : []
    const oldArray = oldArrayString ? oldArrayString.split(/,(?=[^\]]*(?:\[|$))/) : []

    if (newArray[0] === oldArray[0] && newArray[1] !== oldArray[1]) {
      field = 'rating'
    } else if (newArray[0] === oldArray[0] && !_.isEqual(newArray[2], oldArray[2])) {
      field = 'content ratings'
    } else {
      field = auditName
    }

    if (type === 'stream-level-fields') {
      n = 3
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(','))
      removedArrayItem = (removedItems || []).map(item => item.join(','))
      arrayItems = getRatingUpdatedArray(addedArrayItem, removedArrayItem, type, auditName)
      addedArray = (arrayItems.addedArray || []).map(item => {
        return `${item[0]}, ${item[1]}, ${item[2]} `
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        return `${item[0]}`
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }
    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action, field }
}

const getTemplateCreditArrayChanges = (newValue, oldValue, props, type, auditName) => {
  let changedValue; let action; let arrayItems = {}; let addedArrayItem = []; let removedArrayItem = []; let addedArray = []; let removedArray = []; let updatedArray = []; let newArrayItems = []; let oldArrayItems = []; let newItems = []; let removedItems = []
  let n
  if ((newValue && (newValue.indexOf('[')) === 0) || ((newValue || oldValue))) {
    let oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    let newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    oldArrayString = (oldArrayString || '').replace(/, /g, ',')
    newArrayString = (newArrayString || '').replace(/, /g, ',')
    const newArray = newArrayString ? newArrayString.split(',') : []
    const oldArray = oldArrayString ? oldArrayString.split(',') : []

    if (type === 'stream-level-fields') {
      n = 3
      if (auditName === 'cast') {
        n = 4
      }
      newArrayItems = _.chunk(newArray, n)
      oldArrayItems = _.chunk(oldArray, n)
      newItems = _.differenceWith(newArrayItems, oldArrayItems, _.isEqual)
      removedItems = _.differenceWith(oldArrayItems, newArrayItems, _.isEqual)
      addedArrayItem = (newItems || []).map(item => item.join(',')).sort()
      removedArrayItem = (removedItems || []).map(item => item.join(',')).sort()
      arrayItems = getCreditUpdatedArray(addedArrayItem, removedArrayItem, type, auditName)
      addedArray = (arrayItems.addedArray || []).map(item => {
        if (auditName === 'cast') {
          return `${item[0]} (Person), ${item[1]} (Role), ${item[2]} (CharacterName)`
        } else {
          return `${item[0]} (Person), ${item[1]} (Role)`
        }
      })
      removedArray = (arrayItems.removedArray || []).map(item => {
        if (auditName === 'cast') {
          return `${item[0]} (Person), ${item[1]} (Role), ${item[2]} (CharacterName)`
        } else {
          return `${item[0]} (Person), ${item[1]} (Role)`
        }
      })
      updatedArray = (arrayItems.updatedArray || []).filter(updatedVal => updatedVal)
    }
    if (addedArray.length && removedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}  and  Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && updatedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Updated ${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length && removedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length && removedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')} and also Removed ${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (updatedArray.length) {
      action = 'UPDATE'
      changedValue = `${updatedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (removedArray.length) {
      action = 'REMOVE'
      changedValue = `${removedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    } else if (addedArray.length) {
      action = 'ADD'
      changedValue = `${addedArray.join(', ').replace(lastReplaceRegex, ' and $1')}`
    }
  } else return
  return { changedValue, action }
}

const getCreditUpdatedArray = (add, remove, type, auditName) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(',')
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(',')
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] === removedArray[i][0]) {
            if (auditName === 'crew') {
              if ((addedArray[j][1] === removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2])) {
                updatedString = `person ${removedArray[j][2]} to ${addedArray[j][2]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] === removedArray[i][2])) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2])) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]} and person ${removedArray[j][2]} to ${addedArray[j][2]} for id  ${removedArray[j][0]} `
              } else if (addedArray[j][1] !== removedArray[i][1]) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]}  for id  ${removedArray[j][0]}`
              } else {
                updatedString = `person ${removedArray[j][2]} to  ${addedArray[j][2]} for id  ${removedArray[j][0]}`
              }
              removeIndex.push(i)
              addedIndex.push(j)
            } else {
              if ((addedArray[j][1] === removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2]) && (addedArray[j][3] === removedArray[i][3])) {
                updatedString = `person ${removedArray[j][2]} to ${addedArray[j][2]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] === removedArray[i][2]) && (addedArray[j][3] === removedArray[i][3])) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][3] !== removedArray[i][3]) && (addedArray[j][2] === removedArray[i][2]) && (addedArray[j][1] === removedArray[i][1])) {
                updatedString = `characterName ${removedArray[j][3]} to ${addedArray[j][3]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] === removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2]) && (addedArray[j][3] !== removedArray[i][3])) {
                updatedString = `person ${removedArray[j][2]} to ${addedArray[j][2]} and characterName ${removedArray[j][3]} to ${addedArray[j][3]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2]) && (addedArray[j][3] === removedArray[i][3])) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]} and person ${removedArray[j][2]} to ${addedArray[j][2]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] === removedArray[i][2]) && (addedArray[j][3] !== removedArray[i][3])) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]} and characterName ${removedArray[j][3]} to ${addedArray[j][3]} for id  ${removedArray[j][0]} `
              } else if ((addedArray[j][1] !== removedArray[i][1]) && (addedArray[j][2] !== removedArray[i][2]) && (addedArray[j][3] !== removedArray[i][3])) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]} and person ${removedArray[j][2]} to ${addedArray[j][2]} and characterName ${removedArray[j][3]} to ${addedArray[j][3]} for id  ${removedArray[j][0]} `
              } else if (addedArray[j][1] !== removedArray[i][1]) {
                updatedString = `role ${removedArray[j][1]} to ${addedArray[j][1]}  for id  ${removedArray[j][0]}`
              } else {
                updatedString = `person ${removedArray[j][2]} to  ${addedArray[j][2]} for id  ${removedArray[j][0]}`
              }
              removeIndex.push(i)
              addedIndex.push(j)
            }
          }
          updatedArray.push(updatedString)
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  return { updatedArray, addedArray, removedArray }
}

const getRatingUpdatedArray = (add, remove, type, auditName) => {
  let ele = []; let i = 0; let j = 0; let updatedArray = []; let updatedString = ''; let removeIndex = []; let addedIndex = []
  let addedArray = (add || []).map(item => {
    ele = item.split(/,(?=[^\]]*(?:\[|$))/)
    return ele
  })
  let removedArray = (remove || []).map(item => {
    ele = item.split(/,(?=[^\]]*(?:\[|$))/)
    return ele
  })
  if (removedArray && removedArray.length && addedArray && addedArray.length) {
    if (type === 'stream-level-fields') {
      for (i = 0; i < removedArray.length; i++) {
        for (j = 0; j < addedArray.length; j++) {
          if (addedArray[j][0] !== removedArray[i][0] && addedArray[j][1] === removedArray[i][1] && _.isEqual(addedArray[j][2], removedArray[i][2]) && auditName === 'ratings') {
            updatedString = `Schema ${removedArray[i][0]} to ${addedArray[j][0]}`
            removeIndex.push(i)
            addedIndex.push(j)
          } else if (addedArray[j][0] === removedArray[i][0] && addedArray[j][1] !== removedArray[i][1] && !_.isEqual(addedArray[j][2], removedArray[i][2]) && auditName === 'ratings') {
            updatedString = `${removedArray[i][1]} to ${addedArray[j][1]} and content rating ${removedArray[i][2]} to ${addedArray[j][2]} of Schema ${addedArray[j][0]}`
            removeIndex.push(i)
            addedIndex.push(j)
          } else if (addedArray[j][0] === removedArray[i][0] && addedArray[j][1] !== removedArray[i][1] && auditName === 'ratings') {
            updatedString = ` ${removedArray[i][1]} to ${addedArray[j][1]} of Schema ${addedArray[j][0]}`
            removeIndex.push(i)
            addedIndex.push(j)
          } else if (addedArray[j][0] === removedArray[i][0] && !_.isEqual(addedArray[j][2], removedArray[i][2]) && auditName === 'ratings') {
            updatedString = ` ${removedArray[i][2]} to ${addedArray[j][2]} of Schema ${addedArray[j][0]}`
            removeIndex.push(i)
            addedIndex.push(j)
          }
          updatedArray.push(updatedString)
        }
      }
    }
    updatedArray = _.uniq(updatedArray)
  }
  for (i = addedIndex.length - 1; i >= 0; i--) {
    addedArray.splice(addedIndex[i], 1)
  }
  for (j = removeIndex.length - 1; j >= 0; j--) {
    removedArray.splice(removeIndex[j], 1)
  }
  return { updatedArray, addedArray, removedArray }
}

const getFormattedAppDetails = (audit, action, contentType, contentName, props) => {
  let field = audit.name
  let content
  if ((audit.name === 'isLive' || audit.name === 'isFree' || audit.name === 'isArchived') && contentType === 'APP_CONFIG') {
    return handleBooleanChangesConfig(field, audit)
  }
  if (audit.name === 'isArchived' && contentType === 'APP_PAGE') {
    return handleBooleanChangesPage(field, audit)
  }
  if (contentType === 'PUBLISH_RULE') {
    content = 'publish rule'
    if (field === 'isEnabled') {
      return { action: audit.value === 'true' ? 'Published to' : 'Unpublished to', field: contentName }
    }
    let value
    if (!audit.oldValue || audit.oldValue === 'null') {
      value = `to ${moment(audit.value).format('DD MMM YYYY HH:mm')}`
    } else {
      let newValue = !audit.value || audit.value === 'null' ? `to null` : ` to ${moment(audit.value).format('DD MMM YYYY HH:mm')}`
      value = `from ${moment(audit.oldValue).format('DD MMM YYYY HH:mm')} ${newValue}`
    }

    return { action: 'Updated', field: `${field} of ${contentName}`, value, content }
  }
  if (audit.isPosChange && contentType !== 'APP_PAGE_LAYOUT' && contentType !== 'APP_WIDGET' && contentType !== 'APPS') {
    contentType === 'APP_CONFIG' ? action = 'Changed config field position of' : action = 'Changed position of configInstance'

    return { action, field, value: `from ${audit.oldValue} to ${audit.value}`, content }
  }
  if (audit.isPosChange && contentType !== 'APP_PAGE_LAYOUT' && contentType !== 'APP_WIDGET' && contentType !== 'APP_CONFIG') {
    action = 'Changed position of App '

    return { action, field, value: `from ${audit.oldValue} to ${audit.value}`, content }
  }
  if (audit.isPosChange && contentType === 'APP_PAGE_LAYOUT') {
    action = 'Changed position of pageLayoutItem ' + (contentName || '')

    return { action, field, value: `from ${audit.oldValue} to ${audit.value}`, content }
  }

  if (audit.isPosChange && contentType === 'APP_WIDGET') {
    action = 'Changed position of field '
    content = contentName || content
    return { action, field, value: `from ${audit.oldValue} to ${audit.value}`, content }
  }

  if (audit.name === 'Meta Field') {
    let auditAction = 'ADD'
    let newValue = audit.value
    if (!newValue || newValue === '[]' || newValue === 'null' || newValue.length === 0) {
      auditAction = 'REMOVE'
    } else if (audit.oldValue) {
      auditAction = 'UPDATE'
    }
    if (auditAction === 'REMOVE') {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: audit.oldValue, content: contentName || props.contentId }
    } else {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: `${['REMOVE', 'ADD'].indexOf(auditAction) > -1 ? '-' : `from ${audit.oldValue} to`} ${audit.value}`, content: contentName || props.contentId }
    }
  }

  if (audit.name === 'Content Fields' || audit.name === 'Fields') {
    let auditAction = 'ADD'
    let newValue = audit.value
    if (!newValue || newValue === '[]' || newValue === 'null' || newValue.length === 0) {
      auditAction = 'REMOVE'
    } else if (audit.oldValue) {
      auditAction = 'UPDATE'
    }

    if (contentType === 'APP_WIDGET' && !audit.fieldName) {
      let content = contentName
      return { action: audit.value, field: '', value: '', content }
    }

    if (auditAction === 'REMOVE') {
      return { action: `${actionMap[auditAction]} ${field}`, field: audit.fieldName, value: audit.oldValue, content: contentName || props.contentId }
    } else {
      return { action: audit.value, field: '', value: '', content: '' }
    }
  }

  if (audit.name === 'customMeta' && audit.value !== null) {
    let changedOldValue = audit.oldValue; let changedNewValue = audit.value
    changedNewValue = changedNewValue.split(',')
    changedNewValue[0] = changedNewValue[0].substring(1)
    const newValLength = changedNewValue.length - 1
    changedNewValue[newValLength] = changedNewValue[newValLength].substring(0, changedNewValue[newValLength].length - 1)

    changedOldValue = changedOldValue.split(',')
    changedOldValue[0] = changedOldValue[0].substring(1)
    const oldValLength = changedOldValue.length - 1
    changedOldValue[oldValLength] = changedOldValue[oldValLength].substring(0, changedOldValue[oldValLength].length - 1)
    let changedString = ''
    let index = 0
    for (index; index < changedNewValue.length; index += 3) {
      const tempItem = changedNewValue[index]
      const tempItemName = changedNewValue[index + 1]
      const oldIndex = changedOldValue.indexOf(tempItem)
      if (oldIndex > -1) {
        if (changedOldValue[oldIndex + 2] !== changedNewValue[index + 2]) {
          if (changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
            continue
          }
          if (changedOldValue[oldIndex + 2] === 'undefined' || changedOldValue[oldIndex + 2] === '' || changedOldValue[oldIndex + 2] === 'null' || changedOldValue[oldIndex + 2] === null || changedOldValue[oldIndex + 2] === undefined) {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Added ${tempItemName} - ${changedNewValue[index + 2]}, `
          } else if (changedNewValue[index + 2] === '' || changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null') {
            changedString += `Removed ${tempItemName} with value ${changedOldValue[index + 2]} `
          } else {
            if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined) {
              continue
            }
            changedString += `Updated ${tempItemName} from ${changedOldValue[oldIndex + 2]} to ${changedNewValue[index + 2]}, `
          }
        }
      } else {
        changedString += `Added ${tempItemName} - ${changedNewValue[index + 2] === '' ? null : changedNewValue[index + 2]}, `
        if (changedNewValue[index + 2] === null || changedNewValue[index + 2] === 'null' || changedNewValue[index + 2] === 'undefined' || changedNewValue[index + 2] === undefined || changedNewValue[index + 2] === '') {
          continue
        }
      }
    }
    changedString = changedString.substr(0, changedString.length - 1)
    return { action: changedString, field: '', value: '', content: contentName || props.contentId }
  }

  let changedValue = audit.name === 'defaultVideo' && props.details.contentName ? props.details.contentName : audit.value
  if (audit.name === 'productionDateTime' || audit.name === 'releaseDateTime') {
    if (audit.value) {
      changedValue = moment(audit.value).format('DD MMM YYYY HH:mm')
    } else if (audit.oldValue) {
      changedValue = moment(audit.oldValue).format('DD MMM YYYY HH:mm')
    }
  }
  const oldValue = audit.name === 'tags' ? getFormattedTagsArray(audit.oldValue || '') : audit.oldValue
  const newValue = audit.name === 'tags' ? getFormattedTagsArray(audit.value || '') : (audit.name === 'bucketItems' && !audit.value ? '[]' : (audit.name === 'defaultVideo' && props.details.contentName ? props.details.contentName : audit.value))
  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  // if (contentType === 'ASSET' && audit.name === 'contents') {
  //   const contentArrayChanges = getArrayChangesForContent(newValue, oldValue, props, audit)
  //   action = contentArrayChanges ? contentArrayChanges.action : action
  //   changedValue = contentArrayChanges ? contentArrayChanges.changedValue : changedValue

  //   return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  // }
  const arrayChanges = contentType !== 'APP_PAGE_LAYOUT' ? getArrayChanges(newValue, oldValue, props) : null
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  // if (audit.name === 'defaultMedia' || audit.name === 'defaultImage' || audit.name === 'defaultVideo') {
  //   const mediaNames = replaceIdwithItems([changedValue], props)
  //   changedValue = mediaNames[0]
  // }
  // if (audit.name === 'categoryId') {
  //   const categoryId = replaceIdwithItems([changedValue], props)
  //   changedValue = categoryId[0]
  //   return { action: actionMap[action], field: 'category', value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  // }
  // if (contentType === 'IMAGE' && audit.name === 'settings') {
  //   const mediaNames = replaceIdwithItems([contentName], props)
  //   // return getFormattedContentDetails(audit, action, contentType, contentName, props)
  //   const aspectRatio = JSON.parse(audit.value).aspectRatio
  //   const assetMedia = ((props.assetDetails && props.assetDetails.images) || []).find(item => item.id === contentName)
  //   if (assetMedia) {
  //     changedValue = (assetMedia.aspectRatio || []).find(item => item.aspectRatio === aspectRatio).title
  //   }
  //   return { action: actionMap[action], field: 'crop settings', value: `under ${changedValue} of image ${mediaNames[0]}`, content }
  // }
  action = arrayChanges ? arrayChanges.action : action
  if (changedValue === audit.value && action === 'REMOVE') {
    let tempVal
    // let content = contentName ? 'configInstance(' + contentName + ')' : ''
    if (contentType === 'APP_CONFIG_INSTANCE' && field !== 'id' && field !== 'isEnabled') {
      let content = contentName ? 'configInstance(' + contentName + ')' : ''
      if (audit.fieldName === 'image') {
        tempVal = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
        return { action: actionMap[action], field, value: 'value ' + tempVal, content }
      }
      return { action: actionMap[action], field, value: 'value ' + audit.oldValue, content }
    }
    if (contentType === 'APP_PAGE_LAYOUT' && field !== 'id' && field !== 'isEnabled') {
      let content = contentName ? 'layoutitem(' + contentName + ')' : ''
      if (field === 'AppPageLayout') {
        return { action: actionMap[action], field: 'Widget -', value: (audit.oldValue || '[]').replace('[', '').replace(']', ''), content }
      }
      return { action: actionMap[action], field, value: 'value -' + audit.oldValue, content }
    }
    if (contentType === 'APP_WIDGET' && (audit.fieldName === 'value' || audit.fieldName === 'image' || audit.fieldName === 'bucket')) {
      let content = contentName
      if (audit.fieldName === 'image') {
        let oldDefaultValue = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
        // let newDefaultValue = replaceIdwithItems([audit.value],props) || audit.value
        oldDefaultValue = oldDefaultValue && oldDefaultValue.length && oldDefaultValue[0] ? oldDefaultValue : 'null'
        // newDefaultValue= newDefaultValue && newDefaultValue.length && newDefaultValue[0] ? newDefaultValue : 'null'
        return { action: actionMap[action], field, value: 'default value -' + oldDefaultValue, content }
      }
      return { action: actionMap[action], field, value: 'default value -' + audit.oldValue, content }
    }
    if (contentType === 'APP_WIDGET' && !audit.fieldName) {
      let content = contentName
      return { action: actionMap[action], field, value: audit.oldValue, content }
    }
    if (contentType === 'APPS') {
      let content = contentName
      if (audit.name === 'appIcon') {
        let oldDefaultValue = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
        // let newDefaultValue = replaceIdwithItems([audit.value],props) || audit.value
        oldDefaultValue = oldDefaultValue && oldDefaultValue.length && oldDefaultValue[0] ? oldDefaultValue : 'null'
        // newDefaultValue= newDefaultValue && newDefaultValue.length && newDefaultValue[0] ? newDefaultValue : 'null'
        return { action: actionMap[action], field, value: '' + oldDefaultValue, content }
      }
      return { action: actionMap[action], field, value: '' + audit.oldValue, content }
    }
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }
  if ((changedValue === (audit.oldValue || '[]').replace('[', '').replace(']', '')) && action === 'REMOVE' && contentType === 'APP_CONFIG_INSTANCE') {
    field = 'configInstance'
    return { action: actionMap[action], field, value: changedValue || audit.oldValue, content }
  }

  if (((contentType === 'CREDIT') && (audit.name === 'crew' || audit.name === 'cast'))) {
    let content = ''
    // let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    const arrayChanges = getTemplateCreditArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name)
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }

  if (audit.name === 'fields') {
    let content = ''
    // let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    let arrayChanges
    if (contentType === 'APP_CONFIG' || contentType === 'APP_WIDGET') {
      arrayChanges = getAppConfigTemplateArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name, contentType)
    } else {
      arrayChanges = getTemplateRatingArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name)
    }
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    field = arrayChanges ? arrayChanges.field : field
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    if (contentName) {
      content = contentName || content
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }

  if (audit.name === 'fieldValues') {
    let content = contentName ? 'configInstance(' + contentName + ')' : ''
    // let content = replaceIdwithItems([contentId], props)
    if (!oldValue || oldValue === '[]' || oldValue === 'null') {
      action = 'ADD'
    } else if (!newValue || newValue === '[]' || newValue === 'null') {
      action = 'REMOVE'
    }
    let arrayChanges
    if (contentType === 'APP_CONFIG_INSTANCE') {
      arrayChanges = getAppConfigFieldValuesTemplateArrayChanges(newValue, oldValue, props, 'stream-level-fields', audit.name, contentType)
    }
    changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
    action = arrayChanges ? arrayChanges.action : action
    field = arrayChanges ? arrayChanges.field : field
    if (changedValue === audit.value && action === 'REMOVE') {
      return { action: actionMap[action], field, value: oldValue }
    }
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD', 'UPDATE'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: content }
  }

  // if (audit.fieldName === 'adManagement' && contentType === 'VIDEO') {
  //   content = replaceIdwithItems([contentName], props)
  // }

  if (field === 'id' && contentType === 'APP_CONFIG_INSTANCE') {
    field = 'configInstance'
  } else if (field === 'isEnabled' && contentType === 'APP_CONFIG_INSTANCE' && contentName) {
    let content = contentName ? 'configInstance(' + contentName + ')' : ''
    return { action: 'Updated', field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : 'to'} ${changedValue}`, content }
  } else if (field !== 'id' && contentType === 'APP_CONFIG_INSTANCE' && field !== 'isEnabled') {
    field = audit.fieldName !== 'isEnabled' ? 'fieldValues - ' + field : 'isEnabled - ' + field
    if (action === 'UPDATE') {
      if (audit.fieldName === 'image') {
        const oldVal = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
        const newVal = replaceIdwithItems([changedValue], props) || changedValue
        changedValue = `from ${oldVal} to ${newVal}`
      } else {
        changedValue = `from ${audit.oldValue} to ${changedValue}`
      }
    } else if (action === 'ADD') {
      const newVal = replaceIdwithItems([changedValue], props) || changedValue
      changedValue = ` ${newVal}`
    }
    changedValue += audit && audit.fieldName && (audit.fieldName !== 'value' && audit.fieldName !== 'image' && audit.fieldName !== 'isEnabled') ? '(' + audit.fieldName + ')' : ''
    content = contentName ? 'configInstance(' + contentName + ')' : ''
  }
  if (contentType === 'APP_CONFIG' && audit.name !== 'tags' && audit.name !== 'assignedUsers') {
    changedValue = `from ${audit.oldValue} to ${audit.value}`
  }

  if (contentType === 'APP_PAGE_LAYOUT') {
    if (action === 'ADD') {
      let tempVal = audit.value
      if (audit.fieldName === 'image') {
        tempVal = replaceIdwithItems([audit.value], props) || audit.value
      }
      let content = contentName ? 'layoutitem(' + contentName + ')' : ''
      return { action: actionMap[action], field, value: 'value -' + tempVal, content }
    }
    if (action === 'UPDATE') {
      let content = contentName ? 'layoutitem(' + contentName + ')' : ''
      if (audit.fieldName === 'image') {
        const oldVal = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
        const newVal = replaceIdwithItems([changedValue], props) || changedValue
        changedValue = `from ${oldVal} to ${newVal}`
      } else if (audit.fieldName === 'isEnabled') {
        changedValue = `- isEnabled to ${changedValue}`
      } else if (audit.fieldName === 'isPinned') {
        changedValue = `- isPinned to ${changedValue}`
      } else {
        changedValue = `from ${audit.oldValue} to ${changedValue}`
      }

      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
  }

  if (contentType === 'APP_PAGE') {
    if (action === 'ADD') {
      return { action: audit.fieldName === 'Linked' ? 'Linked' : actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
    if (action === 'UPDATE') {
      if (audit.fieldName === 'isEnabled') {
        changedValue = `- isEnabled to ${changedValue}`
      } else {
        changedValue = `from ${audit.oldValue} to ${changedValue}`
      }
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
  }
  if (contentType === 'APP_WIDGET' && (audit.fieldName === 'value' || audit.fieldName === 'image' || audit.fieldName === 'bucket')) {
    if (!audit.oldValue && audit.value) {
      action = 'ADD'
    }
    content = contentName || content
    if (audit.fieldName === 'image') {
      let oldDefaultValue = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
      let newDefaultValue = replaceIdwithItems([audit.value], props) || audit.value
      changedValue = 'default value from ' + (oldDefaultValue && oldDefaultValue.length && oldDefaultValue[0] ? oldDefaultValue : 'null') + ' to ' + newDefaultValue
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
    changedValue = 'default value from ' + audit.oldValue + ' to ' + audit.value
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
  }

  if (contentType === 'APP_WIDGET' && !audit.fieldName) {
    if (audit.name === 'app') {
      const oldAppValue = getFormattedAppsArray(audit.oldValue || '')
      const newAppValue = getFormattedAppsArray(audit.value || '')
      const appArrayChanges = getArrayChanges(newAppValue, oldAppValue, props)
      changedValue = appArrayChanges ? appArrayChanges.changedValue : changedValue
      content = contentName || content
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
    if (audit.name === 'widgetPreview') {
      let oldImageValue = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
      let newImageValue = replaceIdwithItems([audit.value], props) || audit.value
      oldImageValue = oldImageValue && oldImageValue.length && oldImageValue[0] ? oldImageValue : 'null'
      newImageValue = newImageValue && newImageValue.length && newImageValue[0] ? newImageValue : 'null'
      changedValue = `from ${oldImageValue || 'null'} to ${newImageValue}`
      content = contentName || content
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
    changedValue = `from ${audit.oldValue ? audit.oldValue : 'null'} to ${changedValue}`
    content = contentName || content
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
  }
  if (contentType === 'APP_KEY') {
    if (audit.name === 'app') {
      content = contentName || content
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
    changedValue = `from ${audit.oldValue ? audit.oldValue : 'null'} to ${changedValue}`
    content = contentName || content
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
  }
  if (contentType === 'APP_GROUP' && audit.name !== 'assignedUsers') {
    changedValue = `from ${audit.oldValue ? audit.oldValue : 'null'} to ${changedValue}`
  }
  if (contentType === 'APPS') {
    if (audit.name === 'appIcon') {
      let oldImageValue = replaceIdwithItems([audit.oldValue], props) || audit.oldValue
      let newImageValue = replaceIdwithItems([audit.value], props) || audit.value
      oldImageValue = oldImageValue && oldImageValue.length && oldImageValue[0] ? oldImageValue : 'null'
      newImageValue = newImageValue && newImageValue.length && newImageValue[0] ? newImageValue : 'null'
      changedValue = `from ${oldImageValue || 'null'} to ${newImageValue}`
      content = contentName || content
      return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
    }
    if (audit.name !== 'assignedUsers') {
      changedValue = `from ${audit.oldValue ? audit.oldValue : 'null'} to ${changedValue}`
    }

    content = contentName || content
    return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
  }
  if (contentType !== 'APP_GROUP') {
    content = contentName || content
  }
  return { action: actionMap[action], field, value: `${['REMOVE', 'ADD'].indexOf(action) > -1 ? '-' : ''} ${changedValue}`, content }
}

const getFormattedDrmDetails = (updatedObj, action, contentType, contentName, props) => {
  let field = 'DRM Token'
  let content
  if (contentType && contentType === 'DRM') {
    // finalIndex= null
    // let finalIndex = !_.isEmpty(updatedObj) ? updatedObj.length - 1 : null
    let changedValue = contentName ? ' - ' + contentName : ' - '
    let durationValue = '';
    (updatedObj || []).map(item => {
      if (item && item.name && item.name === 'Source') {
        changedValue += ' using Source: ' + item.value
      } else if (item && item.name && item.name === 'Channel' && item.value) {
        changedValue += ', Channel: ' + item.value
      } else if (item && item.name && item.name === 'Config Group' && item.value) {
        changedValue += ', Channel Group: ' + item.value
      } else if (item && item.name && item.name === 'Stream Type' && item.value) {
        changedValue += ', Stream Type: ' + replaceIdwithItems([item.value], props)
      } else if (item && item.name && item.name === 'Fields') {
        let splitArray = (item.value || '').split(',')
        let tempString = '';
        (splitArray || []).map((field) => {
          if (field && !tempString) {
            tempString += field
          } else if (field && tempString) {
            tempString += ', ' + field
          }
        })
        changedValue += ' along with ' + tempString
      } else if (item && item.name && item.name === 'Validity' && item.value) {
        durationValue = ' with Duration of ' + item.value
      }
    })
    changedValue += durationValue
    return { action: 'Created', field, value: changedValue, content }
  }
}

const getFormattedPartnerHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName, id } = details
  let descriptions = (updatedObj || []).map(item => getFormattedPartnerDetails(_.cloneDeep(item), action, contentType, contentName, id, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (action === 'DELETE' && contentType === 'LINK_ASSET_PROGRAM') {
    descriptions = [{ action: `${actionMap[action]}`, field: 'channel program' }]
  }
  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedAppHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = (updatedObj || []).map(item => getFormattedAppDetails(_.cloneDeep(item), action, contentType, contentName, props))
  if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (action === 'DELETE' && contentType === 'LINK_ASSET_PROGRAM') {
    descriptions = [{ action: `${actionMap[action]}`, field: 'channel program' }]
  }
  if (action === 'CREATE' && (contentType === 'APP_WIDGET' || contentType === 'APPS' || contentType === 'APP_KEY' || contentType === 'APP_GROUP') && contentName) {
    descriptions = [{ action: `${actionMap[action]}`, field: contentName }]
  }
  if (action === 'DELETE' && (contentType === 'APP_WIDGET' || contentType === 'APPS' || contentType === 'APP_KEY') && contentName) {
    descriptions = [{ action: `${actionMap[action]}`, field: contentName }]
  }
  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

const getFormattedDrmHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName } = details
  let descriptions = getFormattedDrmDetails(_.cloneDeep(updatedObj), action, contentType, contentName, props)
  if (!descriptions.length && !_.isEmpty(descriptions)) {
    descriptions = [{ ...descriptions }]
  } else if (!descriptions.length) {
    descriptions = [{ action: `${actionMap[action]}` }]
  }
  if (action === 'CREATE' && (contentType === 'APP_WIDGET' || contentType === 'APPS' || contentType === 'APP_KEY' || contentType === 'APP_GROUP') && contentName) {
    descriptions = [{ action: `${actionMap[action]}`, field: contentName }]
  }
  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? ' in' : null}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

class HistoryListCell extends React.Component {
  render () {
    const { details, content } = this.props
    const { updatedBy, updatedAt } = details
    let historyList
    if (content === 'ASSET_MANAGER') {
      historyList = getFormattedAssetHistoryDetails(details, this.props)
    } else if (content === 'BUCKET_MANAGER') {
      historyList = getFormattedBucketHistoryDetails(details, this.props)
    } else if (content === 'CONTENT_BANK') {
      historyList = getFormattedContentHistoryDetails(details, this.props)
    } else if (content === 'CHANNEL_MANAGER') {
      historyList = getFormattedChannelHistoryDetails(details, this.props)
    } else if (content === 'MATCH_MANAGER') {
      historyList = getFormattedMatchHistoryDetails(details, this.props)
    } else if (content === 'JOB_MANAGER') {
      historyList = getFormattedJobHistoryDetails(details, this.props)
    } else if (content === 'CUSTOMER_MANAGER') {
      historyList = getFormattedCustomerDetails(details, this.props)
    } else if (content === 'OFFER_MANAGER') {
      historyList = getFormattedOfferHistoryDetails(details, this.props)
    } else if (content === 'PARTNER_MANAGER') {
      historyList = getFormattedPartnerHistoryDetails(details, this.props)
    } else if (content === 'APP_MANAGER') {
      historyList = getFormattedAppHistoryDetails(details, this.props)
    } else if (content === 'DRM_MANAGER') {
      historyList = getFormattedDrmHistoryDetails(details, this.props)
    }
    return (
      <div className='history-list-cell' >
        <div className='heading'>
          <p className='user-name'>{updatedBy}</p>
          <span className='date'>{moment(updatedAt).format('DD MMM YYYY HH:mm')}</span>
        </div>
        {historyList}
      </div>
    )
  }
}

HistoryListCell.defaultProps = {
  details: {
    user: 'David Nelson',
    date: '28 Nov 2018, 12:00',
    description: 'David Nelson created an Article “Football mourns the tragic ending for a man who created a miracle and so much hope”'
  }
}

export default withApollo(compose(
  graphql(
    QueryBucketItemDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if ((details.contentType === 'BUCKET' || details.contentType === 'RELATED_ASSET') && !details.contentName && details.updatedObj && details.updatedObj.length) {
          const newItems = (details.updatedObj[0].value || '').replace('[', '').replace(']', '').split(', ')
          const oldItems = (details.updatedObj[0].oldValue || '').replace('[', '').replace(']', '').split(', ')
          const items = _.uniq([...newItems, ...oldItems])
          contentIds = items.filter(item => item.length)
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if ((details.contentType === 'BUCKET' || details.contentType === 'RELATED_ASSET') && !details.contentName) {
          const newItems = (details.updatedObj[0].value || '').replace('[', '').replace(']', '').split(', ')
          const oldItems = (details.updatedObj[0].oldValue || '').replace('[', '').replace(']', '').split(', ')
          const items = _.uniq([...newItems, ...oldItems])
          contentIds = items.filter(item => item.length)
        }
        return {
          variables: { input: contentIds }
        }
      },
      props: (props) => {
        return {
          bucketItemDetails: props.data.getBatchBucketItems
        }
      }
    }
  ),
  graphql(
    QueryBucketGroupItemDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if ((details.contentType === 'BUCKET_GROUP') && !details.contentName && details.updatedObj && details.updatedObj.length) {
          const items = _.uniq(details.updatedObj.map((item) => {
            return item.isPosChange ? item.name : item.value ? item.value : item.oldValue
          }))
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if ((details.contentType === 'BUCKET_GROUP') && !details.contentName && details.updatedObj && details.updatedObj.length) {
          const items = _.uniq(details.updatedObj.map((item) => {
            return item.isPosChange ? item.name : item.value ? item.value : item.oldValue
          }))
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { input: contentIds }
        }
      },
      props: (props) => {
        return {
          bucketGroupItemDetails: props.data.getBatchBucketGroupItems
        }
      }
    }
  ),
  graphql(
    QueryMediaDetails,
    {
      skip: ({ details }) => {
        let contentIds
        if (((details.contentType === 'BUCKET_ITEM' || details.contentType === 'ASSET' || details.contentType === 'CHANNEL' || details.contentType === 'PARTNER' || details.contentType === 'VIDEO' || details.contentType === 'APP_CONFIG_INSTANCE' || details.contentType === 'APP_PAGE_LAYOUT' || details.contentType === 'APP_WIDGET' || details.contentType === 'APPS') && details.action === 'UPDATE') || (details.action === 'CREATE' && details.contentType === 'APP_CONFIG_INSTANCE') || (details.action === 'CREATE' && details.contentType === 'APP_PAGE_LAYOUT')) {
          const newItems = (details.updatedObj || []).filter(item => item.name === 'media' || item.name === 'customMedia' || item.name === 'icon' || item.name === 'images' || item.name === 'logo' || item.name === 'breakDuration' || item.name === 'adPositions' || item.name === 'cuePoints' || item.fieldName === 'image' || item.name === 'widgetPreview' || item.name === 'appIcon').map(updates => {
            return (updates.value || '').replace('[', '').replace(']', '').split(', ')
          })
          const oldItems = (details.updatedObj || []).filter(item => item.name === 'media' || item.name === 'customMedia' || item.name === 'icon' || item.name === 'images' || item.name === 'logo' || item.name === 'breakDuration' || item.name === 'adPositions' || item.name === 'cuePoints' || item.fieldName === 'image' || item.name === 'widgetPreview' || item.name === 'appIcon').map(updates => {
            return (updates.oldValue || '').replace('[', '').replace(']', '').split(', ')
          })
          const defaultMedia = (details.updatedObj || []).filter(item => item.name === 'defaultMedia').map(item => item.value)
          const defaultVideo = details.contentType === 'VIDEO' && details.contentName ? details.contentName : []
          const items = [...newItems, ...oldItems, defaultMedia, defaultVideo]
          contentIds = items.reduce((total, currentItem) => {
            return [...total, ...currentItem]
          }, []).filter(item => item && item.length)
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (((details.contentType === 'BUCKET_ITEM' || details.contentType === 'ASSET' || details.contentType === 'CHANNEL' || details.contentType === 'PARTNER' || details.contentType === 'VIDEO' || details.contentType === 'APP_CONFIG_INSTANCE' || details.contentType === 'APP_PAGE_LAYOUT' || details.contentType === 'APP_WIDGET' || details.contentType === 'APPS') && details.action === 'UPDATE') || (details.action === 'CREATE' && details.contentType === 'APP_CONFIG_INSTANCE') || (details.action === 'CREATE' && details.contentType === 'APP_PAGE_LAYOUT')) {
          let newItems = (details.updatedObj || []).filter(item => item.name === 'media' || item.name === 'customMedia' || item.name === 'icon' || item.name === 'images' || item.name === 'logo' || item.name === 'breakDuration' || item.name === 'adPositions' || item.name === 'cuePoints' || item.fieldName === 'image' || item.name === 'widgetPreview' || item.name === 'appIcon').map(updates => {
            return (updates.value || '').replace('[', '').replace(']', '').split(', ')
          })
          let oldItems = (details.updatedObj || []).filter(item => item.name === 'media' || item.name === 'customMedia' || item.name === 'icon' || item.name === 'images' || item.name === 'logo' || item.name === 'breakDuration' || item.name === 'adPositions' || item.name === 'cuePoints' || item.fieldName === 'image' || item.name === 'widgetPreview' || item.name === 'appIcon').map(updates => {
            return (updates.oldValue || '').replace('[', '').replace(']', '').split(', ')
          })
          if (details.updatedObj && (details.updatedObj[0].name === 'adPositions' || details.updatedObj[0].name === 'cuePoints' || details.updatedObj[0].name === 'breakDuration')) {
            newItems = []
            oldItems = []
          }
          const defaultMedia = (details.updatedObj || []).filter(item => item.name === 'defaultMedia').map(item => item.value)
          const defaultVideo = details.contentType === 'VIDEO' && details.contentName ? [details.contentName] : []
          const items = [...newItems, ...oldItems, defaultMedia, defaultVideo]
          contentIds = items.reduce((total, currentItem) => {
            return [...total, ...currentItem]
          }, []).filter(item => item && item.length)
        }
        return {
          variables: { input: _.uniq(contentIds) }
        }
      },
      props: (props) => {
        return {
          mediaItemDetails: (props.data.batchGetMedia || []).filter(item => item)
        }
      }
    }
  ),
  graphql(
    QueryChannelConfigDetails,
    {
      skip: ({ details }) => {
        let contentIds; let items = []
        if ((details.contentType === 'CHANNEL' || (details.contentType === 'CHANNEL_CONFIG_GROUP' && (details.action === 'DELETE' || details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))))) {
          if (details.updatedObj && details.updatedObj.length) {
            items = _.uniq(details.updatedObj.map((item) => {
              return item.isPosChange ? details.contentName : item.value ? item.value : item.oldValue
            }))
          } else if (details.action === 'DELETE' || details.action === 'CREATE') {
            items = [details.contentName]
          }

          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds; let items = []
        if ((details.contentType === 'CHANNEL' || (details.contentType === 'CHANNEL_CONFIG_GROUP' && (details.action === 'DELETE' || details.action === 'CREATE' || (details.updatedObj && details.updatedObj.length))))) {
          if (details.updatedObj && details.updatedObj.length) {
            items = _.uniq(details.updatedObj.map((item) => {
              return item.isPosChange ? details.contentName : item.value ? item.value : item.oldValue
            }))
          } else if (details.action === 'DELETE' || details.action === 'CREATE') {
            items = [details.contentName]
          }

          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { channelId: contentIds[0] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          channelConfigItemDetails: props.data.getChannelConfigGroup
        }
      }
    }
  ),
  graphql(
    QueryConfigGroupSchema,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'MODULE_SETTING' && details.updatedObj && details.updatedObj.length) {
          const items = _.uniq(details.updatedObj.map((item) => {
            return item.name
          }))
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'MODULE_SETTING' && details.updatedObj && details.updatedObj.length) {
          const items = _.uniq(details.updatedObj.map((item) => {
            return item.name
          }))
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          ConfigGroupSchemaDetails: props.data.getConfigGroupSchema
        }
      }
    }
  ),
  graphql(
    QueryStreamSchema,
    {
      skip: ({ details }) => {
        let contentIds
        if (details.contentType === 'STREAM' && details.updatedObj && details.updatedObj.length) {
          const items = [details.contentName]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return !contentIds || !contentIds.length
      },
      options: ({ details }) => {
        let contentIds
        if (details.contentType === 'STREAM' && details.updatedObj && details.updatedObj.length) {
          const items = [details.contentName]
          contentIds = _.cloneDeep(items.filter((item) => item))
        }
        return {
          variables: { id: contentIds[0] },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          streamDetails: props.data.getStreamSchema
        }
      }
    }
  )
)(withApollo(HistoryListCell)))
