import React, { Component } from 'react'
import { Table, Skeleton, Empty, message } from 'antd'
import { Resizable } from 'react-resizable'

import ServicesModal from './../dataDisplay/ServicesModal'
import ConfirmModal from '../feedback/ConfirmModal'
import PopoverButton from '../general/buttons/PopoverButton'
import ExpandMenuIcon from '../general/icons/ExpandMenuIcon'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCustomerProfile from '../../../graphQL/customers/getCustomerProfile'
import MutationSwitchFA from './../../../graphQL/customers/updateDefaultFA'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

const options = [
  {
    type: 'makeDefault',
    name: 'Make Default FA'
  }
]
class BillingAccountsTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Cycle Close Day',
        dataIndex: 'billCycleCloseDay',
        key: 'billCycleCloseDay',
        width: 100
      },
      {
        title: 'Account ID',
        dataIndex: 'billingAccountId',
        key: 'billingAccountId',
        width: 100
      },
      {
        title: 'Account Number',
        dataIndex: 'billingAccountNumber',
        key: 'billingAccountNumber',
        width: 100
      },
      {
        title: 'Account Status',
        dataIndex: 'billingAccountStatus',
        width: 100,
        key: 'billingAccountStatus'
      },
      {
        title: 'Collection Status',
        dataIndex: 'collectionStatus',
        key: 'collectionStatus',
        width: 100
      },
      {
        title: 'Contact Role',
        dataIndex: 'contactRole',
        key: 'contactRole',
        width: 100
      },
      {
        title: 'Services',
        dataIndex: 'services',
        width: 100,
        key: 'services',
        render: this.renderServices
      }
    ]

    this.state = {
      shouldShowPopup: false,
      shouldShowOptionsPopup: false,
      shouldShowActiveAccount: false,
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
    if (newProps.profileOptionShown === true) {
      this.setState({ shouldShowPopup: false, shouldShowOptionsPopup: false })
    }
  }

  renderServices = (services, data) => {
    if (_.isEmpty(services)) {
      return <div className='popup-container'>
        <div className='no-data-text' >None</div>
        {this.renderData(data && data.billingAccountId)}
      </div>
    } else {
      return <div className='popup-container'>
        <div className='general-text' onClick={() => this.toggleShowOptions(services)}>View Services</div>
        {this.renderData(data && data.billingAccountId)}
      </div>
    }
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  renderData = (id) => {
    const { isUpdateDisabled } = this.props
    const { shouldShowOptionsPopup, account } = this.state
    return (
      <div className='icon'>
        <div onClick={() => this.toggleShowActiveAccount(id)} id={`sub-option-list-${id}`} className={`sub-option-list ${isUpdateDisabled ? 'disabled' : ''}`}>
          <PopoverButton
            button={<div onClick={this.toggleShowSideOptions} > <ExpandMenuIcon /></div>}
            placement='left'
            displayParam='name'
            contents={isUpdateDisabled ? [] : options}
            onContentClick={this.selectOption}
            parentCompoent={`sub-option-list-${id}`}
            isVisible={account === id && shouldShowOptionsPopup}
          />
        </div>
      </div>
    )
  }

  selectOption=({ type }) => {
    if (type === 'makeDefault') {
      this.setState({ shouldShowActiveAccount: true, editSubscription: undefined, shouldShowOptionsPopup: false })
    }
  }

  toggleShowOptions = (services) => {
    this.setState({ shouldShowPopup: !this.state.shouldShowPopup, services, shouldShowOptionsPopup: false })
  }

  toggleShowSideOptions=() => {
    this.setState({ shouldShowOptionsPopup: !this.state.shouldShowOptionsPopup, shouldShowPopup: false })
  }

  toggleShowActiveAccount = (accountId) => {
    this.setState({ account: accountId })
    this.props.setTargetAccount(accountId)
  }

  onCancelActiveAccount =() => {
    this.setState({ shouldShowActiveAccount: false, account: null }, () => this.props.setTargetAccount(null))
  }

  onConfirmActiveAccount=() => {
    const { selectedCustomer, project } = this.props
    const { account } = this.state
    this.setState({ isModalLoading: true })
    const variables = {
      userId: selectedCustomer || null,
      targetFAId: account || null,
      project: project
    }

    this.props.adminSwitchFA(variables).then(res => {
      if (res && res.data && res.data.adminSwitchFinancialAccount) {
        message.success(res.data.adminSwitchFinancialAccount.success.message || ' Financial Account switched successfully')
        this.setState({ isModalLoading: false, shouldShowActiveAccount: false, account: null })
        if (this.props.fetchHistory) {
          this.props.fetchHistory()
        }
      }
    }, error => {
      utilityService.handleError(error)
      this.setState({ isModalLoading: false, shouldShowActiveAccount: false })
    })
  }

  renderActiveAccount = (id, data) => {
    if (id) {
      return <div className='general-text' onClick={() => this.toggleShowActiveAccount(id)}>Make Default FA</div>
    } else {
      return <span className='no-data-text' > - </span>
    }
  }

  render () {
    const { billingAccounts, isLoading } = this.props
    // const { billingAccounts, isLoading, selectedCustomer, fetchHistory } = this.props
    const { width, height, columns, services, shouldShowPopup, isModalLoading, shouldShowActiveAccount, account } = this.state

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))
    return (
      <div className='general-customer-details-table billing-accounts-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(billingAccounts) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={billingAccounts} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
        {!_.isEmpty(services) ? <ServicesModal services={services} isVisible={shouldShowPopup} onCancel={this.toggleShowOptions} /> : null}
        <ConfirmModal
          isVisible={shouldShowActiveAccount}
          title={'Make Default FA'}
          message={`Do you want to make ` + account + ` the Default Financial Account?`}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onCancelActiveAccount}
          handleSubmit={this.onConfirmActiveAccount} />
      </div>
    )
  }
}

BillingAccountsTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCustomerProfile,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { id: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const billingAccounts = data && data.getCustomer && data.getCustomer.billingAccounts ? data.getCustomer.billingAccounts : []
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { billingAccounts, isLoading: data && data.loading }
      }
    }
  ),
  graphql(
    MutationSwitchFA,
    {
      options: ({ selectedCustomer, project, selectedCustomerCogId, targetAccount }) => {
        return {
          update: (cache, { data: { adminSwitchFinancialAccount } }) => {
            try {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCustomerProfile, variables: { id: selectedCustomer, cognitoUserId: selectedCustomerCogId, project } }))
              if (adminSwitchFinancialAccount && adminSwitchFinancialAccount.success) {
                if (cacheData && cacheData.getCustomer && targetAccount) {
                  cacheData.getCustomer.selectedBillingAccountId = targetAccount
                }
              }
              cache.writeQuery({
                query: QueryCustomerProfile,
                data: cacheData,
                variables: { userId: selectedCustomer, cognitoUserId: selectedCustomerCogId, project }
              })
            } catch (error) {

            }
          }

        }
      },
      props: (props) => ({
        adminSwitchFA: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(BillingAccountsTable))
