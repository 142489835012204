import gql from 'graphql-tag'

export default gql(`
mutation createRating($scheme: String!, $rating: [String!], $subRatings: [String!], $project: String) {
    createRating(
        input: {
            scheme: $scheme,
            rating: $rating,
            subRatings: $subRatings
        },
        project: $project
    ) {
        id
        scheme
        rating
        subRatings
        assetCount
        source
    }
}`
)
