import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import CrossIcon from './../general/icons/CrossIcon'

import './../ui.style.scss'

const AdminItemConfirmModal = ({ isVisible, isLoading, title, firstMessage, secondMessage, adminItem, isLeftPrior, rightButtonText, leftButtonText, handleCancel, handleSubmit, isSubmitButtonDisabled, handleClose, isCancelButtonDisabled, isCancelButtonInvisible, itemName, ...props }) => (
  <Modal
    className={`confirm-modal admin-confirm ${isLeftPrior ? 'left-prior' : ''}${isCancelButtonInvisible ? ' cancel-not-visible' : ''}`}
    title={title}
    visible={isVisible}
    cancelText={leftButtonText}
    okText={rightButtonText}
    onOk={handleSubmit}
    onCancel={handleClose || handleCancel}
    okButtonProps={{ disabled: isSubmitButtonDisabled, loading: isLoading }}
    cancelButtonProps={{ disabled: isCancelButtonDisabled, onClick: handleCancel }}
    closable={false}
    centered
    confirmLoading={isLoading}
  >
    {isCancelButtonInvisible ? '' : <div className='cross-icon'>
      <CrossIcon onClick={handleClose || handleCancel} />
    </div>}
    <p>{firstMessage}</p>
    {adminItem}
    {itemName && <p>{itemName}</p>}
    <p>{secondMessage}</p>
  </Modal>
)

AdminItemConfirmModal.propTypes = {
  /** loading status of Modal. */
  isLoading: PropTypes.bool,
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Title of Modal. */
  title: PropTypes.string,
  /** Description message of Modal. */
  message: PropTypes.string,
  /** Button priority status of Modal. if 'isleftPrior' is 'true', 'Cancel' button is of higher priority */
  isLeftPrior: PropTypes.bool,
  /** Left button display text of Modal. */
  leftButtonText: PropTypes.string,
  /** Right button display text of Modal. */
  rightButtonText: PropTypes.string,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func,
  /** Submit button disable status of Modal. */
  isSubmitButtonDisabled: PropTypes.bool,
  /**  Submit button disable status of Modal. */
  isCancelButtonDisabled: PropTypes.bool,
  /** Boolean for making cancel button invisible */
  isCancelButtonInvisible: PropTypes.bool
}

AdminItemConfirmModal.defaultProps = {
  selectedValue: 'Select'
}

export default AdminItemConfirmModal
