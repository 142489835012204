import gql from 'graphql-tag'

export default gql(`
    mutation($contents: [UpdateStreamInput], $channelId: ID, $configGroupName: String) {
        batchUpdateStream(input: $contents, channelId: $channelId, configGroupName: $configGroupName) {
            id
            fields {
                name
                type
                isRequired
                value
            }
            url
            levelVersions{
                streamId
                version
            }
            streamSchema{
                id
                levels{
                    id
                    name
                    fields{
                        name
                        value
                        isRequired
                        type
                    }
                }
            }
        }
    }`
)
