import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import ResolutionDisplayCell from './ResolutionDisplayCell'
import _ from 'lodash'

class AspectRatioList extends Component {
  render () {
    const { selectedAspect, details, title, onResolutionSelect } = this.props
    const sortedList = _.sortBy(this.props.aspects, 'width')
    return (
      sortedList.map((items, index) => (
        <ResolutionDisplayCell key={index} details={details} aspects={items} aspectRatio={title} onResolutionSelect={onResolutionSelect} selectedAspect={selectedAspect} />
      ))
    )
  }
}

AspectRatioList.propTypes = {
  /** aspects of AspectRatioList. */
  aspects: PropTypes.array,
  /** ratio title of AspectRatioList. */
  title: PropTypes.string,
  /** Selected aspect ratio ID */
  selectedAspect: PropTypes.string
}
export default AspectRatioList
