import React from 'react'
// import PropTypes from 'prop-types'
import './../ui.style.scss'

import placeholderImage from './../../../assets/images/default-image.jpg'
import { Switch, Popover } from 'antd'
import GroupDragAndDropIcon from '../general/icons/GroupDragAndDropIcon'
// import { generateCroppedThumbnail } from '../../../util/util'
import _ from 'lodash'

class WidgetListDetailsCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { item, handleLayoutItemStatus, isSelected, isPinned, onDoubleClick } = this.props
    const { widget } = item
    let useAsTitleField = null
    item.fieldValues.map(layoutItem => {
      let field = layoutItem.configField
      if (field && field.useAsTitle) {
        useAsTitleField = layoutItem.value
      }
      //  else if (field.useAsTitle) {
      //   useAsTitleField = layoutItem.value
      // }
    })
    // let imageUrl
    let popupImageUrl
    if (widget.widgetPreview) {
      // imageUrl = !_.isEmpty(widget.widgetPreview) ? generateCroppedThumbnail(widget.widgetPreview, 200, 200, '1:1') : undefined
      popupImageUrl = !_.isEmpty(widget.widgetPreview) ? widget.widgetPreview.fileName : undefined
    }
    // const image = imageUrl || placeholderImage
    const popupImage = popupImageUrl || placeholderImage

    const content = <div className='widget-thumb' >
      <img className='widget-thumb-preview' src={popupImage} />
    </div>
    return (
      <div onDoubleClick={onDoubleClick} className={`widget-details-cell ${isSelected || isPinned ? 'active' : ''} `}>
        <div className='widget-drag' >
          <GroupDragAndDropIcon width={10} height={11} />
        </div>
        <Popover overlayClassName='widget-image-details' placement='right' content={content}>
          <div className='widget-thumb' >
            <img className='widget-thumb-preview' src={popupImage} />
          </div>
        </Popover>

        <div className='widget-details'>
          <p className='widget-name'>{useAsTitleField || widget.title}</p>
          <div className='widget-title'>
            <p className='key'>Widget Title:</p>
            <p className='value'> {widget && widget.title}</p>
          </div>
          <div className='widget-title'>
            <p className='key'>Widget Key:</p>
            <p className='value'> {widget && widget.key}</p>
          </div>
        </div>
        <div className='widget-enable'>
          <Switch checked={item.isEnabled} disabled={false} onChange={(value) => handleLayoutItemStatus(item, value)} />
        </div>
      </div>
    )
  }
}

WidgetListDetailsCell.propTypes = {

}

WidgetListDetailsCell.defaultProps = {

}

export default WidgetListDetailsCell
