import gql from 'graphql-tag'

export default gql(`
    mutation duplicateAppWidget($title: String $key: String! $app: [ID] $project: String $fields: [ConfigModelFieldInput] $fieldValues:[ConfigMetaInput] $widgetPreview: ID $description: String $isActive: Boolean
        ) {
            duplicateAppWidget(
            input: {
                title: $title
                key: $key
                app: $app
                fields: $fields
                fieldValues: $fieldValues
                widgetPreview: $widgetPreview
                description: $description
                isActive: $isActive
            },
            project: $project
        ) {
                id
                key
                title
                description
                isActive
                app{
                    id
                    name
                    url
                }
                fields{
                    id
                    name
                    type
                    isRequired
                    useAsTitle
                    position
                    isUpdatable
                }
                fieldValues {
                    configField {
                      id
                      type
                      isRequired
                      useAsTitle
                      name
                      isUpdatable
                    }
                    value
                    project
                    bucket {
                      project
                      id
                      isArchived
                      name
                      isPublished
                      publishStartDate
                      publishEndDate
                      updatedAt
                      updatedBy
                      key
                      displayName
                      displayType{
                        name
                        id
                      }
                    }
                    image {
                      id
                      fileName
                      createdAt
                      name
                      type
                      fileSize
                      duration
                      thumbnails
                      tags{
                          key
                          name
                          type
                      }
                      category{
                          id
                          label
                          type
                      }
                      settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2
                          y2
                      }
                      dimensions{
                          x1
                          x2
                          y1
                          y2
                      }
                      aspectRatio {
                          aspectRatio
                          ratio
                          title
                          resolutions {
                          key
                          width
                          height
                          url
                          }
                      }
                    }
                }
                widgetPreview {
                    id
                    fileName
                    createdAt
                    name
                    type
                    fileSize
                    duration
                    thumbnails
                    tags{
                        key
                        name
                        type
                    }
                    category{
                        id
                        label
                        type
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2
                    }
                    dimensions{
                        x1
                        x2
                        y1
                        y2
                    }
                    aspectRatio {
                        aspectRatio
                        ratio
                        title
                        resolutions {
                        key
                        width
                        height
                        url
                        }
                    }
                }
                createdAt
                updatedAt
                updatedBy
                createdBy
                usageCount
               }
               
    }`
)
