import gql from 'graphql-tag'

export default gql(`
mutation createSport( $title: String! $meta: [CreateFieldInput] $competitionFields: [SportFieldInput] 
    $tournamentFields: [SportFieldInput] $teamFields: [SportFieldInput] $matchFields: [SportFieldInput] 
    $playerFields: [SportFieldInput] $stageFields: [SportFieldInput]) {
    createSport(
        input: {
            title: $title
            meta: $meta
            competitionFields: $competitionFields
            tournamentFields: $tournamentFields
            teamFields: $teamFields
            matchFields: $matchFields
            playerFields: $playerFields
            stageFields: $stageFields
        }
            ) {
                  id
                  externalId
                  title
                  meta{
                    name
                    type
                    value
                    oldValue
                    displayValue
                    isRequired
                    displayName
                    fieldName
                    maxValue
                    isPosChange
                  }
                  competitionFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  tournamentFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  teamFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  matchFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  playerFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
                  stageFields{
                    id
                    name
                    type
                    isRequired
                    default
                    position
                    displayName
                    isPosChange
                  }
        }
    }`
)
