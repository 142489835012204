import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TagsInputContainer from '../../components/ui/dataEntry/inputs/TagsInputContainer'
import MutationCreateTag from '../../graphQL/asset/createTag'
import QueryAllTags from '../../graphQL/asset/getTags'
// import QueryTagContent from '../../graphQL/asset/getContentTags'
import QueryTagType from './../../graphQL/admin/tagType/listTagType'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { cloneDeep, flowRight as compose } from 'lodash'
import { utilityService } from '../../services/UtilityService'

const parentCompoent = 'tags-dummy-container'
// const parentCompoent = 'asset-container'

const skippedTagsName = []

class AssetTags extends Component {
    state = {
      searchTag: '',
      selectedIndex: undefined
    }

    onTagSearch = (index, searchTag) => {
      this.setState({
        searchTag,
        selectedIndex: searchTag ? index : undefined
      })
    }

    generalDetailsTagChange = (value, tagsList, type, isDelete, isCustom) => {
      const tags = [ ...this.props.selectedItems ]
      if (isDelete) {
        // const index = tags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
        const index = tags.findIndex(item => item.key === value && item.type === type)
        tags.splice(index, 1)
      } else {
        let index = tagsList.findIndex(item => item.key === value)
        if (index === -1) { index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase()) }
        // const index = tagsList.findIndex(item => item.key === value)
        if (index === -1) {
          if (isCustom) {
            const id = Math.random().toString(36).substr(2, 9)
            const newTag = { name: value, module: 'ASSET_MANAGER', type: 'MANUAL' }
            tags.push({ ...newTag, id, type })
            this.props.onGeneralTagChange(tags)
            this.props.createTag(newTag).then(response => {
              const tag = response.data.createTag
              // tags.push(tag)
              const index = tags.findIndex(item => item.id === id)
              tags.splice(index, 1, tag)
              this.props.onGeneralTagChange(tags)
            }, error => {
              const index = (error.message || '').indexOf('ConditionalCheckFailedException')
              if (index > -1) {
                const tagIndex = tags.findIndex(item => item.id === id)
                const newId = `${value}asset_manager`
                tags[tagIndex].key = newId
                this.props.onGeneralTagChange(tags)
                return
              }
              utilityService.handleError(error)
            })
          }
          return
        } else {
          const newTag = (tagsList[index])
          const isPresent = tags.findIndex(item => item.key === newTag.key) > -1
          if (!isPresent) {
            tags.push(newTag)
          }
        }
      }
      this.props.onGeneralTagChange(tags)
    }

    getEnabledTagTypes=(systemTags, assetType) => {
      let finalTagTypes = [];
      (systemTags || []).filter(tagType => {
        if (tagType && tagType.enabledAssetTypes) {
          (tagType.enabledAssetTypes || []).map(item => {
            if (item.assetType === assetType) {
              finalTagTypes.splice(0, 0, tagType)
            }
          })
        }
      })
      return finalTagTypes
    }

    render () {
      const { searchTag, selectedIndex } = this.state
      const { systemTags, selectedItems, project, assetType } = this.props
      return (
        <div className='system-tags'>
          {this.getEnabledTagTypes(systemTags, assetType).filter((tag) => !skippedTagsName.includes(tag.name)).sort((a, b) => a.position - b.position).map((tag, index) => (
            <div className='input-field' key={index}>
              <label className='input-title'>{tag.name}</label>
              <TagsInputContainer
                selectedItems={selectedItems.filter(item => item && (item.type === tag.name)).map(item => item.key)}
                selectedDisplayItems={selectedItems.filter(item => item && (item.type === tag.name)).map(item => item.name)}
                onChange={this.generalDetailsTagChange}
                placeholder='Insert Tags'
                parentCompoent={parentCompoent}
                displayParameter='name'
                keyParameter='key'
                onSearch={this.onTagSearch.bind(this, index)}
                searchTag={selectedIndex === index ? searchTag : undefined}
                type={tag.name}
                isCustom={tag.isCustom}
                module={tag.isCustom ? 'ASSET_MANAGER' : 'ALL'}
                isDropdownInvisible
                project={project}
              />
            </div>
          ))}
        </div>
      )
    }
}

AssetTags.propTypes = {
  /** tag change action of AssetTags. */
  onGeneralTagChange: PropTypes.func
}

export default withApollo(compose(
  graphql(
    MutationCreateTag,
    {
      options: ({ project }) => {
        const filter = { type: { match: 'MANUAL' }, module: { exact: 'ASSET_MANAGER' } }
        return {
          update: (cache, { data: { createTag } }) => {
            const cacheData = cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },

      props: (props) => ({
        createTag: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  /* graphql(
    QueryTagContent,
    {
      options: ({ project }) => {
        return {
          variables: { project }
        }
      },
      props: (props) => {
        return {
          systemTags: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  ) */
  graphql(
    QueryTagType,
    {
      options: ({ project }) => {
        return {
          variables: { filter: null, limit: 500, project }
        }
      },
      props: (props) => {
        return {
          systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
        }
      }
    }
  )
)(AssetTags))
