/* eslint-disable  no-unused-vars */
import React from 'react'
import './controllerStrip.scss'
import TimeInfo from './durationInfo'
import FullscreenButton from './fullScreenController'
import PlayPauseButton from './playpauseController'
import ScrubBar from './scrubBar'
import VolumeButton from './volumeController'
const PlayerControllerStrip = props => {
  const { isEditable, showPlayer, onTogglePlayer } = props
  return (
    <>
      {!isEditable ? <ScrubBar showPlayer={showPlayer} /> : null}
      <div className='controllerStrip'>
        <PlayPauseButton />
        <VolumeButton showPlayer={showPlayer} />
        <TimeInfo />
        <FullscreenButton showPlayer={showPlayer} onTogglePlayer={onTogglePlayer} />
      </div>
    </>
  )
}

export default PlayerControllerStrip
