import React from 'react'
// import PropTypes from 'prop-types'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import { utilityService } from '../../../services/UtilityService'

import Switch from '../dataEntry/inputs/Switch'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import ButtonContainer from '../general/buttons/ButtonContainer'

import QueryAllTags from '../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../graphQL/asset/createTag'

import './../ui.style.scss'

// const source = [{
//   name: 'opta',
// }]

class TeamCustomData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      details: {}
    }
  }

  componentDidMount = () => {
    if (this.props.selectedTeam) {
      this.setState({
        details: _.cloneDeep(this.props.selectedTeam)
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (nextProps.selectedTeam && !_.isEqual(this.props.selectedTeam, nextProps.selectedTeam)) {
      this.setState({
        details: _.cloneDeep(nextProps.selectedTeam)
      })
    }
  }

  onOptionSelect = (name, type) => {
    const { details } = this.state
    if (type === 'country') {
      details.customCountry = name
    } else if (type === 'source') {
      details.customSource = name
    }
    this.setState({ details }, () => this.props.onEditTeam(details))
  }

  // handleGeneralChange = (e) => {
  //   const { details } = this.state
  //   const { name, value } = e.target
  //   if (!value.startsWith(' ')) {
  //     details[name] = value
  //     this.setState({ details }, () => this.props.onEditTeam(details))
  //   }
  // }

  handleOfficialNameChange = (e) => {
    this.setState({ isEditOfficialName: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customOfficialName = null
      } else {
        details.customOfficialName = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleNameChange = (e) => {
    this.setState({ isEditName: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customName = null
      } else {
        details.customName = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleShortNameChange = (e) => {
    this.setState({ isEditShortName: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customShortName = null
      } else {
        details.customShortName = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleCodeChange = (e) => {
    this.setState({ isEditCode: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.trigramme = null
      } else {
        details.trigramme = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleFoundedChange = (e) => {
    this.setState({ isEditFounded: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customFounded = null
      } else {
        details.customFounded = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleCountryChange = (e) => {
    this.setState({ isEditCountry: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customCountry = null
      } else {
        details.customCountry = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleCityChange = (e) => {
    this.setState({ isEditCity: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customCity = null
      } else {
        details.customCity = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleAddressChange = (e) => {
    this.setState({ isEditAddress: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customPostalAddress = null
      } else {
        details.customPostalAddress = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  handleAddressZipChange = (e) => {
    this.setState({ isEditAddressZip: true })
    const { details } = this.state
    const { value } = e.target
    if (!value.startsWith(' ')) {
      if (value === '') {
        details.customAddressZip = null
      } else {
        details.customAddressZip = value
      }
      this.setState({ details }, () => this.props.onEditTeam(details))
    }
  }

  onHandleActive = (status) => {
    const { details } = this.state
    if (status === true) {
      details.customStatus = 'active'
    } else {
      details.customStatus = 'inactive'
    }
    this.setState({ details }, () => this.props.onEditTeam(details))
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { details } = this.state
    const selectedTags = details.tags ? details.tags : []
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'DATA_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            details.tags = selectedTags
            this.setState({ details }, () => this.props.onEditTeam(details))
          }, error => {
            utilityService.handleError(error)
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}data_manager`
              selectedTags[tagIndex].key = newId
              details.tags = selectedTags
              this.setState({ details }, () => this.props.onEditTeam(details))
            }
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
          details.tags = selectedTags
        }
      }
    }
    this.setState({ details }, () => this.props.onEditTeam(details))
  }

  render () {
    const { details, isEditCode, isEditFounded, isEditOfficialName, isEditName, isEditShortName, isEditCountry, isEditCity, isEditAddress, isEditAddressZip } = this.state
    const isChecked = details.customStatus ? details.customStatus : details.status
    const { project } = this.props
    return (
      <div className='team-data'>
        <div className='data'>
          <div className='custom-data'>
            <div className='details'>
              <Input title='Official Name' placeholder='Official Name' inputName='customOfficialName' value={isEditOfficialName ? details.customOfficialName : details.customOfficialName ? details.customOfficialName : details.officialName} handleChange={this.handleOfficialNameChange} />
            </div>
            <div className='details'>
              <Input title='Name' placeholder='Name' inputName='customName' value={isEditName ? details.customName : details.customName ? details.customName : details.title} handleChange={this.handleNameChange} />
            </div>
            <div className='details'>
              <Input title='Short Name' placeholder='Short Name' inputName='customShortName' value={isEditShortName ? details.customShortName : details.customShortName ? details.customShortName : details.shortName} handleChange={this.handleShortNameChange} />
            </div>
            <div className='details'>
              <Input title='Code' placeholder='Code' inputName='trigramme' value={isEditCode ? details.trigramme : details.trigramme ? details.trigramme : details.code} handleChange={this.handleCodeChange} />
            </div>
            <div className='details'>
              <Input title='Founded' placeholder='Founded' inputName='customFounded' value={isEditFounded ? details.customFounded : details.customFounded ? details.customFounded : details.founded} handleChange={this.handleFoundedChange} />
            </div>
            <div className='details'>
              <Input title='Country' placeholder='Country' inputName='customCountry' value={isEditCountry ? details.customCountry : details.customCountry ? details.customCountry : details.country} handleChange={this.handleCountryChange} />
            </div>
            <div className='details'>
              <Input title='City' placeholder='City' inputName='customCity' value={isEditCity ? details.customCity : details.customCity ? details.customCity : details.city} handleChange={this.handleCityChange} />
            </div>
            <div className='details'>
              <Input title='Postal Address' placeholder='Postal Address' inputName='customPostalAddress' value={isEditAddress ? details.customPostalAddress : details.customPostalAddress ? details.customPostalAddress : details.postalAddress} handleChange={this.handleAddressChange} />
            </div>
            <div className='details'>
              <Input title='Address Zip' placeholder='Address Zip' inputName='customAddressZip' value={isEditAddressZip ? details.customAddressZip : details.customAddressZip ? details.customAddressZip : details.addressZip} handleChange={this.handleAddressZipChange} />
            </div>
            {/* <div className='details' id='details'>
              <label>Source</label>
              <DropDown options={options} onOptionSelect={(name) => this.onOptionSelect(name, 'source')} selectedValue={details.source} valueParam='name' displayParam='name' placeHolder='Output format' parent={'details'} />
            </div> */}
            <div className='details'>
              <div className='type'>
                <span>Active</span>
                <div className='information-icon'>
                  <ButtonContainer displayTitle={'Enabled for Active Teams'} childComponent={<InformationIcon />} />
                </div>
                <Switch isChecked={isChecked === 'active'} onChange={(status) => this.onHandleActive(status)} />
              </div>
            </div>
          </div>
          <div className='custom-tags' id='custom-tags'>
            <label> Tags </label>
            <TagsInputContainer
              selectedItems={details && details.tags ? details.tags.filter(item => item).map(item => item.name) : []}
              onChange={this.handleTagChange}
              placeholder='Insert Tags'
              parentCompoent={'custom-tags'}
              displayParameter='name'
              onSearch={() => {}}
              searchTag={''}
              type={'MANUAL'}
              isCustom
              module={'DATA_MANAGER'}
              project={project}
            />
          </div>
        </div>
      </div>
    )
  }
}

TeamCustomData.propTypes = {

}

TeamCustomData.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateTag,
    {
      options: ({ project }) => {
        const filter = { type: { match: 'MANUAL' } }
        return {
          update: (cache, { data: { createTag } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
            if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
            cache.writeQuery({
              query: QueryAllTags,
              data: cacheData
            })
          }
        }
      },

      props: (props) => ({
        createTag: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(TeamCustomData))
