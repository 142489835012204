import React from 'react'
import PropTypes from 'prop-types'

const AccountIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path fill='#FFF' d='M10 10a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm10 9.957c0 .086-6.667.043-20-.129V18.36C0 15.388 4.477 12 10 12s10 3.388 10 6.36v1.597z' />
      <path d='M0 0h20v20H0z' />
    </g>
  </svg>
)

AccountIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

AccountIcon.defaultProps = {
  width: 20,
  height: 20
}

export default AccountIcon
