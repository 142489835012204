import gql from 'graphql-tag'

export default gql(`
    mutation createAudit($value: String $name: String $project: String! $contentId: ID) {
        createAudit(
            input: {
                action: CREATE,
                module: ROLE_MANAGER,
                project: $project,
                contentId: $contentId,
                contentType: USER,
                updatedObj: [
                {
                    name: $name,
                    value: $value,
                    oldValue: ""
                }
                ]
            }
        ) {
            id
            action
            updatedAt
            updatedBy
            contentId
            contentType
            updatedObj{
                isPosChange
                name
                value
                oldValue
            }
        }
    }`
)
