import React from 'react'
import PropTypes from 'prop-types'
import ArchiveConfigIcon from './../icons/ArchiveConfigIcon'
import './../../ui.style.scss'

const ArchiveConfigButton = ({ isDisabled, onClick, title, type, ...props }) => (
  <div className={`config-archive-button ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick}>
    <ArchiveConfigIcon title={title} width={type === 'unpublish' ? 80 : 150} isDisabled={isDisabled} />
  </div>
)

ArchiveConfigButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

ArchiveConfigButton.defaultProps = {
  isDisabled: false
}

export default ArchiveConfigButton
