import gql from 'graphql-tag'

export default gql(`
    mutation($videoContent: [CreateAssetVideoInput] $project: String) {
        addAssetVideos(
            input: $videoContent
            project: $project
        ) {
            id
            assetId
            mediaId
            fileName
            createdAt
            name
            fileSize
            cuePoints
            adPositions
            breakDuration
            cuePointsError
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            type
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
            drmContent
            streams{
                id
                drm
                codec
                Url
                iv
                transport
                contentKey
                PackagingConfigurationId
            }
            _originalResponseBody 
            duration
            thumbnails
            isDefault
        }
    }`
)
