import React from 'react'
import PropTypes from 'prop-types'
import { Tag as AntTag } from 'antd'
import './../ui.style.scss'

const Tag = ({ onClose, tagText, ...props }) => (
  <AntTag className='tag' onClose={onClose}>{ tagText }</AntTag>
)

Tag.propTypes = {
  /** Text inside Tag. */
  tagText: PropTypes.string,
  /** Close action of Tag. */
  onClose: PropTypes.func
}

Tag.defaultProps = {
  tagText: 'Submit'
}

export default Tag
