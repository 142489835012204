import gql from 'graphql-tag'

export default gql(`
mutation addUserEvents($user: String! $userEvent: String $project: String $userAgent: String $errorMessage: String){
    addUserEvents(
        input: {
            user: $user
            userEvent: $userEvent
        }
        userAgent: $userAgent
        errorMessage: $errorMessage
        project: $project
    ){
        id
    }
}`)
