import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import TagsInput from '../inputs/TagsInput'
import RoleUserTable from '../../dataDisplay/RoleUserTable'
import './../../ui.style.scss'

class AddUserGroupModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      newTitle: undefined,
      newMailId: undefined,
      disable: true,
      isSaveEnabled: false,
      selectedGroups: []
    }
  }

  resetState = () => {
    const state = {
      newTitle: undefined,
      newMailId: undefined,
      disable: true,
      isSaveEnabled: false,
      selectedGroups: []
    }
    this.setState({ ...state })
  }

  handleTitleChange =(e) => {
    const { value } = e.target
    this.setState({ newTitle: value })
  }

  handleEmailChange =(e) => {
    const { value } = e.target
    this.setState({ newMailId: value })
  }

  onChangeGroup = (changedGroup, isDelete) => {
    let { selectedGroups } = this.state
    if (isDelete) {
      const index = selectedGroups.findIndex(item => item === changedGroup)
      if (index > -1) { selectedGroups.splice(index, 1) }
    } else {
      selectedGroups.push(changedGroup)
    }
    this.setState({ selectedGroups, isSaveEnabled: true })
  }

  handleSubmit = () => {
    const { selectedUsers } = this.props
    const { selectedGroups } = this.state
    this.props.handleSubmit(selectedUsers, selectedGroups)
  }

  render () {
    const { visible, handleCancel, loading, parentCompoent, groupList, selectedUsers } = this.props
    const { isSaveEnabled, selectedGroups } = this.state
    return (

      <Modal
        className='general-modal add-user-group-modal'
        visible={visible}
        closable={false}
        title={'Add User Group'}
        okButtonProps={{ disabled: !(isSaveEnabled), loading: loading }}
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okText={'Add User Group'}
        cancelText='Cancel'
        afterClose={this.resetState}
        width='1100px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <RoleUserTable isSelected={false} isExpand={false} userList={selectedUsers} />
        <label className='user-groups'> User Groups </label>
        <TagsInput
          containerClass='tags'
          selectedItems={selectedGroups}
          selectedDisplayItems={selectedGroups}
          keyParameter={'id'}
          tagList={groupList || []}
          onChange={this.onChangeGroup}
          placeholder='Select User Group'
          parentCompoent={parentCompoent}
          displayParameter={'name'}
          mode={'multiple'}
        />

      </Modal>
    )
  }
}

AddUserGroupModal.propTypes = {
  /** visible action of AddUserGroupModal. */
  visible: PropTypes.bool,
  /** title  of AddUserGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of AddUserGroupModal. */
  handleCancel: PropTypes.func
}

AddUserGroupModal.defaultProps = {
}

export default AddUserGroupModal
