import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import Input from '../inputs/Input'
import _ from 'lodash'
import './../../ui.style.scss'
import moment from 'moment'

class EditCustomerProfileModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      address: '',
      mobile: '',
      postcode: '',
      city: '',
      state: '',
      country: '',
      dateOfBirth: '',
      isNameError: false,
      isEdited: false
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    const { data } = nextProps
    if (data && !(_.isEqual(data, this.props.data)) && !this.state.isEdited) {
      this.setState({
        firstName: data && data.firstName,
        lastName: data && data.lastName,
        mobile: data && data.mobile,
        address: data && data.address,
        postcode: data && data.postcode,
        city: data && data.city,
        state: data && data.state,
        country: data && data.country,
        dateOfBirth: data && data.dateOfBirth ? moment(data.dateOfBirth).format('DD MMM YYYY') : '',
        email: data && data.email
      })
    }
  }

handleChangeFname = (event) => {
  const firstName = event.target.value
  this.setState({ firstName, isEdited: true, isNameError: !firstName })
}

handleChangeLname = (event) => {
  const lastName = event.target.value
  this.setState({ lastName, isEdited: true })
}

handleChangeMobileNumber = (event) => {
  const mobile = event.target.value
  this.setState({ mobile, isEdited: true })
}

handleChangeAddress = (event) => {
  const address = event.target.value
  this.setState({ address, isEdited: true })
}

handleChangePostCode = (event) => {
  const postcode = event.target.value
  const regExp = /^\d*$/g
  if (regExp.test(postcode) || postcode === '') { this.setState({ postcode, isEdited: true }) }
}

// handleGeneralChange = (e) => {
//   const { details } = this.state
//   const { name, value } = e.target
//   if (!value.startsWith(' ')) {
//     if (name === 'opId') {
//       const regexExp = /^[0-9]*$/
//       const isNum = regexExp.test(value)
//       if (isNum) {
//         details[name] = value
//       }
//     } else {
//       details[name] = value
//     }
//     this.setState({ details }, () => this.props.onEditSyncState(details))
//   }
// }

handleChangeCity = (event) => {
  const city = event.target.value
  this.setState({ city, isEdited: true })
}

handleChangeState = (event) => {
  const state = event.target.value
  this.setState({ state, isEdited: true })
}

handleChangeCountry = (event) => {
  const country = event.target.value
  this.setState({ country, isEdited: true })
}

onChangeDOB = (dateOfBirth) => {
  this.setState({ dateOfBirth, isEdited: true })
}

handleSubmit = () => {
  const { email, firstName, lastName, mobile, address, postcode, city, state, country } = this.state
  const regExp = /^[+]*[0-9]*$/g
  if (mobile && !regExp.test(mobile)) {
    message.warn('Invalid Mobile Number')
    return
  }
  const update = {
    firstName: firstName || '',
    lastName: lastName || '',
    mobile: mobile || '',
    address: address || '',
    postcode: postcode || '',
    city: city || '',
    state: state || '',
    country: country || ''
    // dateOfBirth: dateOfBirth ? new Date(dateOfBirth).toISOString() : ''
  }
  // if (!update.postcode) { delete (update.postcode) }
  const data = {
    email,
    update
  }
  this.props.handleSubmit(data)
}

    clearState = () => {
      const { data } = this.props
      this.setState({
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
        address: data.address,
        postcode: data.postcode,
        city: data.city,
        state: data.state,
        country: data.country,
        dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).format('DD MMM YYYY') : '',
        email: data.email,
        isNameError: false,
        isEdited: false
      })
    }

    render () {
      const { isVisible, isSubmitDisabled, handleCancel, isLoading } = this.props
      const { firstName, isNameError, lastName, mobile, email, address, postcode, city, state, country, isEdited } = this.state
      const isEnable = isEdited
      return (
        <Modal
          className='general-modal edit-customer'
          title={'EDIT CUSTOMER PROFILE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (isSubmitDisabled || !isEnable) }}
          closable={false}
          centered
          destroyOnClose
          confirmLoading={isLoading}
          afterClose={this.clearState}
        >
          { isVisible
            ? <div className='edit-cutomer-container'>
              <Input
                title='First Name'
                inputName='fName'
                handleChange={this.handleChangeFname}
                value={firstName}
                placeholder='Enter first name'
                isError={isNameError}
                errorMessage='Please enter first name' />
              <Input
                title='Last Name'
                inputName='lName'
                handleChange={this.handleChangeLname}
                value={lastName}
                placeholder='Enter last name' />
              {/* <div className='date-picker-container'>
                <label>Date of Birth</label>
                <DatePicker
                  value={dateOfBirth ? moment(dateOfBirth) : ''}
                  format='DD MMM YYYY'
                  onChange={this.onChangeDOB}
                  placeholder='Please select date of birth'
                  allowClear
                  timeDisabled
                  disabled
                />
              </div> */}
              <Input
                title='Mobile Number'
                inputName='mobile'
                handleChange={this.handleChangeMobileNumber}
                value={mobile}
                placeholder='Enter mobile number' />
              <Input
                title='Email'
                inputName='email'
                handleChange={() => {}}
                value={email}
                readOnly />
              <Input
                title='Address'
                inputName='address'
                handleChange={this.handleChangeAddress}
                value={address}
                placeholder='Enter Address' />
              <Input
                title='ZIP'
                inputName='postcode'
                handleChange={this.handleChangePostCode}
                value={postcode}
                placeholder='Enter Post Code' />
              <Input
                title='City'
                inputName='city'
                handleChange={this.handleChangeCity}
                value={city}
                placeholder='Enter city name' />
              <Input
                title='State'
                inputName='state'
                handleChange={this.handleChangeState}
                value={state}
                placeholder='Enter state name' />
              <Input
                title='Country'
                inputName='country'
                handleChange={this.handleChangeCountry}
                value={country}
                placeholder='Enter country name' />
            </div> : '' }
        </Modal>
      )
    }
}

EditCustomerProfileModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

EditCustomerProfileModal.defaultProps = {
}

export default EditCustomerProfileModal
