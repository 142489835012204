import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton, message } from 'antd'

import BucketDetailHeading from './BucketDetailHeading'
import BucketDetailList from './BucketDetailList'
import AddBucketModal from './../feedback/AddBucketModal'
import ConfirmModal from './../feedback/ConfirmModal'
import PublishBucketModal from './../feedback/PublishBucketModal'
import AuthService from './../../../services/AuthService'

import userMessages from '../../../constants/messages'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetBucketDetails from './../../../graphQL/bucket/getBucketDetails'
import QueryGetBucketUpdatedDetails from './../../../graphQL/bucket/getBucketUpdatedDetails'
import MutationCreateBucketItem from './../../../graphQL/bucket/createBucketItem'
import MutationUpdateBucket from './../../../graphQL/bucket/updateBucket'
import MutationSaveAllBuckets from './../../../graphQL/bucket/saveAllBuckets'
import SubscriptionSaveAllBucket from './../../../graphQL/bucket/saveAllBucketsSubscription'
import MutationUpdateBucketItem from '../../../graphQL/bucket/batchUpdateBucketItem'
import { utilityService } from '../../../services/UtilityService'
import getUuid from 'uuid-by-string'
import moment from 'moment'
import { multiDragAwareReorder, multiSelect } from './utils'

const parent = 'bucket-details-container'
let userSessionId = null

// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list)
//   const [removed] = result.splice(startIndex, 1)
//   result.splice(endIndex, 0, removed)
//   return result
// }

const subscribeChangeInBucket = (subscribeToMore, props, retrying = 50) => {
  return subscribeToMore({
    document: SubscriptionSaveAllBucket,
    variables: { id: props.ownProps.bucketId },
    updateQuery: (prev, { subscriptionData: { data: { bucketSaved } } }) => {
      // if (bucketSaved && bucketSaved.isArchived) {
      //   const variables = { id: data.ownProps.bucketId }
      //   const listCacheData = props.ownProps.client.readQuery({ query: QueryGetBucketDetails, variables })
      //   const index = listCacheData.listBucketGroups.items.findIndex(group => group.id === bucketSaved.id)
      //   if (index !== -1) {
      //     props.ownProps.client.writeQuery({
      //       query: QueryGetBucketGroupList,
      //       data: listCacheData,
      //       variables
      //     })
      //   }
      // }
      if (!bucketSaved || prev.getBucket.id !== bucketSaved.id) return prev
      prev.getBucket = bucketSaved
      return prev
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeChangeInBucket(subscribeToMore, props, retrying * 2) }, retrying)
      }
    }
  })
}

const deleteWarningMessage = {
  title: 'Delete Bucket?',
  message: 'Do you want to delete this bucket from bucket group'
}

class BucketDetailCell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bucketDetails: _.cloneDeep(props.bucketDetails),
      isSearch: false,
      isEditted: false,
      isSaveEnable: false,
      saveLoading: false,
      addFilterLoading: false,
      isScroll: false,
      isWarningShow: false,
      isModifiedLoading: false,
      updatedBucketItem: [],
      draggingTaskId: null,
      entities: this.loadBucketDetailsTolistData(_.cloneDeep(props.bucketDetails)),
      listData: [],
      selectedTaskIds: []
    }
    this.changedBucketItem = []
    if (props.bucketId && props.isLoading) {
      props.changeLoadingBucket(props.bucketId, 'ADD')
    }
  }

  componentDidMount = () => {
    this.getUserDetails()
    if (this.props.bucketDetails) {
      if (this.props.isLoading) {
        this.props.changeLoadingBucket(this.props.bucketId, 'ADD')
      }
    }
    // this.initiateSubscription()
  }

  // shouldComponentUpdate = (newProps, newState) => {
  //   const isStateSame = _.isEqual(newState, this.state)
  //   const isPropsSame = this.isPropsSame(newProps, this.props)
  //   if (isStateSame && isPropsSame) {
  //     return false
  //   }
  //   return true
  // }

  loadBucketDetailsTolistData = (bucketDetails) => {
    if (bucketDetails && bucketDetails.bucketItems && !_.isEmpty(bucketDetails.bucketItems)) {
      let tasks = {}
      const listData = this.getSortedList(bucketDetails.bucketItems)
      const list = listData.map((task) => {
        tasks = {
          ...tasks,
          [task.id]: task
        }
        return task.id
      })
      const entities = {
        columnOrder: ['#Bucket_' + bucketDetails.id],
        columns: {
          ['#Bucket_' + bucketDetails.id]: {
            id: '#Bucket_' + bucketDetails.id,
            taskIds: list
          }
        },
        tasks
      }
      return entities
      // this.setState({
      //   entities
      // })
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isSaveAllClicked) {
      this.saveBucket(true)
    }
    if (newProps.isForceSave) {
      this.saveBucket(true)
    }
    if (!_.isEqual(newProps.bucketListData, this.state.listData) && !this.state.saveLoading && !this.state.addFilterLoading) {
      this.setState({ listData: newProps.bucketListData })
    }
    if (!_.isEqual(newProps.bucketDetails, this.props.bucketDetails) && !this.state.saveLoading && !this.state.isSaveEnable) {
      // if (newProps.bucketId !== this.props.bucketId) {
      this.props.changeLoadingBucket(newProps.bucketDetails.id, 'REMOVE')
      // }

      if (newProps.bucketId === this.props.id) {
        let bucketDetails = _.cloneDeep(newProps.bucketDetails)
        let bucketItems = this.state.bucketDetails.bucketItems
        bucketDetails.bucketItems = bucketItems
        this.setState({
          bucketDetails,
          entities: this.loadBucketDetailsTolistData(bucketDetails)
        })
      } else {
        let bucketDetails = _.cloneDeep(newProps.bucketDetails)
        this.setState({
          bucketDetails,
          entities: this.loadBucketDetailsTolistData(bucketDetails)
        })
      }
    } else if (newProps.bucketDetails && newProps.bucketDetails.status !== this.props.bucketDetails.status) {
      let bucketDetails = _.cloneDeep(this.state.bucketDetails)
      bucketDetails.status = newProps.bucketDetails.status
      this.setState({ bucketDetails })
    } else if (newProps.bucketDetails && !_.isEqual(newProps.bucketDetails, this.state.bucketDetails)) {
      let bucketDetails = _.cloneDeep(this.state.bucketDetails)
      if (this.state.isSaveEnable && !this.state.saveLoading) {
        const isLatest = moment(newProps.bucketDetails.updatedAt).isAfter(moment(bucketDetails.updatedAt), 'seconds')
        // const tempSessionId = sessionStorage.getItem('user_session_id')
        if (isLatest) {
          this.setState({ isWarningShow: true, saveLoading: false, tempBucketDetails: newProps.bucketDetails })
        }
      } else if (bucketDetails && bucketDetails.bucketItems) {
        bucketDetails.bucketItems = (bucketDetails.bucketItems || []).map((item) => {
          const currentItem = (newProps.bucketDetails.bucketItems).find((bucket) => bucket.id === item.id)
          if (!_.isEmpty(currentItem)) { item.asset = item.asset ? currentItem.asset : null }
          return item
        })
      }
      this.setState({
        bucketDetails,
        entities: this.loadBucketDetailsTolistData(bucketDetails)
      })
    }
    if (!_.isEqual(newProps.bucketDetails, this.props.bucketDetails) && !this.state.saveLoading && !this.state.isSaveEnable) {
      if (newProps.bucketDetails && !newProps.isLoading) {
        this.props.changeLoadingBucket(newProps.bucketDetails.id, 'REMOVE')
      }
    }
    if (newProps.draggable && newProps.droppableId && (!this.props.draggable || newProps.draggable.randomKey !== this.props.draggable.randomKey)) {
      const buckets = {
        type: 'ASSET',
        assetId: newProps.draggable.id,
        bucket: newProps.droppableId,
        position: newProps.destinationIndex
      }
      const tempId = getUuid(newProps.droppableId + newProps.draggable.id)
      const tempAsset = {
        id: tempId,
        asset: newProps.draggable,
        type: 'ASSET',
        position: newProps.destinationIndex,
        isLoading: true
      }
      let bucketListDetails = this.state.bucketDetails
      let bucketItems = _.cloneDeep(this.state.bucketDetails.bucketItems)
      const isAssetPresent = (bucketItems || []).find((item) => item.asset && item.asset.id === newProps.draggable.id)
      if (_.isEmpty(isAssetPresent)) {
        bucketItems = bucketItems && bucketItems.length ? bucketItems : []
        bucketItems.splice(newProps.destinationIndex, 0, tempAsset)
        bucketListDetails.bucketItems = bucketItems
        this.setState({
          bucketDetails: bucketListDetails,
          entities: this.loadBucketDetailsTolistData(bucketListDetails)
        })
        this.props.createBucketItem({ bucketItems: [buckets] }).then((response) => {
          this.props.updateHistory()
          const index = bucketItems.findIndex((item) => item.id === tempAsset.id)
          bucketItems[index] = response.data.batchCreateBucketItem[0]
          bucketItems = bucketItems.map((item, index) => {
            item.position = index >= newProps.destinationIndex ? index : item.position
            delete (item.__typename)
            return item
          })
          bucketListDetails.bucketItems = bucketItems
          this.setState({
            bucketDetails: bucketListDetails,
            entities: this.loadBucketDetailsTolistData(bucketListDetails)
          }, () => {
            // /* DISABLED AUTO SAVE */
            // if (bucketListDetails.status === 'DRAFT') { this.saveBucket(true) } else {
            //   this.props.changeBucket(bucketListDetails.id, true)
            //   this.setState({ isSaveEnable: true })
            // }
            this.props.changeBucket(bucketListDetails.id, true)
            this.setState({ isSaveEnable: true })
          })
          this.props.clearDragDropId()
        }, error => {
          // this.setState({ isSaveEnable: true })
          // utilityService.handleError(error)
          this.getBucket(error)
        })
      } else {
        message.warning(userMessages.ASSET_ADDED_ALREADY)
      }
    }
    if (newProps.droppableId && !newProps.draggable && !this.state.saveLoading) {
      let { bucketDetails, entities, selectedTaskIds } = this.state
      // let buckets = bucketDetails.bucketItems && bucketDetails.bucketItems.length ? [...bucketDetails.bucketItems] : []
      // const smallIndex = newProps.sourceIndex <= newProps.destinationIndex ? newProps.sourceIndex : newProps.destinationIndex
      // const largeIndex = newProps.sourceIndex === smallIndex ? newProps.destinationIndex : newProps.sourceIndex
      // buckets = this.getSortedList(buckets)
      // this.changedBucketItem.push(buckets[newProps.sourceIndex].id)
      // buckets = reorder(buckets, newProps.sourceIndex, newProps.destinationIndex)
      // buckets = buckets.map((item, index) => {
      //   item.position = (index >= smallIndex && index <= largeIndex) ? index : item.position
      //   return item
      // })
      // bucketDetails.bucketItems = buckets
      if (bucketDetails && bucketDetails.bucketItems && bucketDetails.bucketItems[newProps.sourceIndex] && selectedTaskIds && selectedTaskIds.length && selectedTaskIds.length > 0 && bucketDetails && !selectedTaskIds.includes(bucketDetails.bucketItems[newProps.sourceIndex].id)) {
        this.setState({ selectedTaskIds: [] })
        selectedTaskIds = []
      }
      if (bucketDetails && bucketDetails.bucketItems && bucketDetails.bucketItems[newProps.sourceIndex] && !this.changedBucketItem.includes(bucketDetails.bucketItems[newProps.sourceIndex].id)) {
        this.changedBucketItem.push(bucketDetails.bucketItems[newProps.sourceIndex].id) // for audit of single drag
      }
      selectedTaskIds.forEach((id) => {
        if (!this.changedBucketItem.includes(id)) {
          this.changedBucketItem.push(id)// for audit of multiple drag
        }
      })
      const destination = {
        index: newProps.destinationIndex,
        droppableId: '#Bucket_' + newProps.droppableId
      }
      const source = {
        index: newProps.sourceIndex,
        droppableId: '#Bucket_' + newProps.droppableId
      }
      let invalidOperation = false
      if (selectedTaskIds && selectedTaskIds.length > 1 && entities) {
        (selectedTaskIds || []).map(taskId => {
          const columnTemp = this.getHomeColumn(entities, taskId)
          if (columnTemp && columnTemp.taskIds) {
            const index = columnTemp.taskIds.indexOf(taskId)
            if (destination.index === index) {
              message.warn('Operation is not allowed')
              invalidOperation = true
            }
          }
        })
      }
      if (!invalidOperation || invalidOperation === false) {
        let tempEntities = _.cloneDeep(entities)
        const processed = multiDragAwareReorder({
          entities: tempEntities,
          selectedTaskIds: selectedTaskIds,
          source,
          destination
        })
        if (processed && processed.entities) {
          const selectedIds = processed.entities.columns['#Bucket_' + newProps.droppableId].taskIds
          let newArray = []
          selectedIds.forEach((selectedId, index) => {
            for (var [key, values] of Object.entries(processed.entities.tasks)) {
              if (key === selectedId) {
                values.position = index
                newArray.push(values)
              }
            }
          })
          const listData = [
            ...newArray
          ]
          if (!_.isEqual(listData, bucketDetails.bucketItems)) {
            bucketDetails.bucketItems = listData
            this.setState({ bucketDetails }, () => {
            // /* DISABLED AUTO SAVE */
            // if (bucketDetails.status === 'DRAFT') { this.saveBucket(true) } else {
            //   this.props.changeBucket(bucketDetails.id, true)
            //   this.setState({ isSaveEnable: true })
            // }
              this.props.changeBucket(bucketDetails.id, true)
              this.setState({
                entities: processed.entities,
                draggingTaskId: null,
                selectedTaskIds: [],
                isSaveEnable: true
              })
            })
            this.props.clearDragDropId()
          }
        }
      } else {
        this.setState(
          { draggingTaskId: null,
            selectedTaskIds: [],
            isSaveEnable: true }
        )
      }
    }
    if (newProps.forceFetchSubscription && !_.isEqual(newProps.forceFetchSubscription, this.props.forceFetchSubscription)) {
      this.props.fetchMoreBucketDetails().then(({ data }) => {
        this.props.completedSubscription(newProps.bucketId)
      })
    }
  }

  getHomeColumn = (entities, taskId) => {
    if (entities) {
      const columnId = entities.columnOrder.find((id) => {
        const column = entities.columns[id]
        return column.taskIds.includes(taskId)
      })

      // invariant(columnId, 'Count not find column for task')

      return entities.columns[columnId]
    }
  }

  initiateSubscription = () => {
    if (this.props.subscribeToChangeBucket) {
      // this.props.subscribeToChangeBucket()
    } else {
      setTimeout(() => {
        this.initiateSubscription()
      }, 1500)
    }
  }

  getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key)
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key)
        result.splice(resultKeyIndex, 1)
      }
      return result
    }, Object.keys(obj2))

    return diff
  }

  isPropsSame = (newProps, oldProps) => {
    if (_.isEqual(newProps, oldProps)) {
      return true
    }
    const isPropDetailsSame = _.isEqual(newProps.isForceSave, oldProps.isForceSave) &&
                              _.isEqual(newProps.bucketListData, oldProps.bucketListData) &&
                              _.isEqual(newProps.bucketDetails, oldProps.bucketDetails) &&
                              _.isEqual(newProps.bucketId, oldProps.bucketId) &&
                              _.isEqual(newProps.id, oldProps.id) &&
                              _.isEqual(newProps.draggable, oldProps.draggable) &&
                              _.isEqual(newProps.droppableId, oldProps.droppableId) &&
                              _.isEqual(newProps.destinationIndex, oldProps.destinationIndex) &&
                              _.isEqual(newProps.sourceIndex, oldProps.sourceIndex) &&
                              _.isEqual(newProps.displayTypes, oldProps.displayTypes) &&
                              _.isEqual(newProps.isCreateBlocked, oldProps.isCreateBlocked) &&
                              _.isEqual(newProps.isUpdateBlocked, oldProps.isUpdateBlocked) &&
                              _.isEqual(newProps.isDeleteBlocked, oldProps.isDeleteBlocked)
    return isPropDetailsSame
  }

  getUserDetails = async () => {
    const userDetails = await AuthService.getUserDetails()
    if (userDetails) {
      this.username = userDetails.name || userDetails.email
    }
  }

  getAddFilterStatus = () => {
    let { bucketDetails } = this.state
    let bucketItems = bucketDetails ? _.cloneDeep(bucketDetails.bucketItems) : []
    const filterCount = (bucketItems || []).filter((item) => item.type === 'FILTER').length
    return filterCount >= 3
  }

  onAddSearch = () => {
    this.setState({ addFilterLoading: true, isSaveEnable: false })
    const { bucketId } = this.props
    let { bucketDetails, updatedBucketItem } = this.state
    let { bucketItems } = bucketDetails
    bucketItems = bucketItems && bucketItems.length ? bucketItems : []
    const tempId = getUuid(bucketId + 'tempId123')
    const newData = {
      id: tempId,
      type: 'FILTER',
      bucket: bucketId,
      position: bucketItems.length,
      filterRule: {
        searchKey: null,
        filters: [
          {
            displayName: null,
            displayValue: 'isArchived',
            fieldName: 'isArchived',
            isRequired: null,
            name: 'isArchived',
            type: 'BOOLEAN',
            value: 'false'
          }, {
            displayName: null,
            displayValue: null,
            fieldName: 'updatedAt',
            isRequired: null,
            name: 'Sort',
            type: 'SORT',
            value: 'desc'
          }, {
            displayName: 'Limit',
            displayValue: null,
            fieldName: null,
            isRequired: true,
            name: 'limit',
            type: 'LIMIT',
            value: '10'
          }
        ]
      }
    }
    bucketItems.push(newData)
    bucketDetails.bucketItems = bucketItems
    this.setState({ bucketDetails, isScroll: true })
    delete (newData.id)
    this.props.changeAddSearchStatusStatus(false)
    this.props.createBucketItem({ bucketItems: [newData] }).then((response) => {
      this.props.updateHistory()
      this.props.changeAddSearchStatusStatus(true)
      updatedBucketItem.push(response.data.batchCreateBucketItem[0].id)
      bucketItems[bucketItems.length - 1].id = response.data.batchCreateBucketItem[0].id
      bucketDetails.bucketItems = bucketItems
      this.setState({
        bucketDetails,
        entities: this.loadBucketDetailsTolistData(bucketDetails),
        addFilterLoading: false,
        updatedBucketItem
      }, () => {
        // /* DISABLED AUTO SAVE */
        // if (bucketDetails.status === 'DRAFT') {
        //   this.saveBucket(true)
        // } else {
        //   this.props.changeBucket(bucketDetails.id, true)
        //   this.setState({ isSaveEnable: true })
        // }
        this.props.changeBucket(bucketDetails.id, true)
        this.setState({ isSaveEnable: true })
      })
    }, error => {
      // this.setState({ isSaveEnable: true })
      this.getBucket(error)
      // utilityService.handleError(error)
    })
  }

  checkForFilterChange = (id, value) => {
    const { bucketItems } = this.props.bucketDetails
    const currentItem = bucketItems.find(item => item.id === id)
    if (currentItem) {
      let filterRule = currentItem.filterRule
      delete (filterRule.__typename)
      filterRule.filters = (filterRule.filters || []).map((item) => {
        if (item.__typename) { delete (item.__typename) }
        return item
      })
      value.filters = value.filters.map((item) => {
        if (item.__typename) { delete (item.__typename) }
        return item
      })
      return !_.isEqual(filterRule, value)
    } else return true
  }

  onChangeFilter = (id, value) => {
    const isFilterChanged = this.checkForFilterChange(id, value)
    let { bucketDetails, updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const modifiedIndex = bucketItems.findIndex(item => item.id === id)
    bucketItems[modifiedIndex].filterRule = value
    bucketDetails.bucketItems = bucketItems
    if (updatedBucketItem.indexOf(id) === -1) { updatedBucketItem.push(id) }
    this.setState({ bucketDetails, updatedBucketItem, isSaveEnable: this.state.isSaveEnable || isFilterChanged }, () => {
      // /* DISABLED AUTO SAVE */
      // if (bucketDetails.status === 'DRAFT') {
      //   this.saveBucket(true)
      // } else {
      //   this.props.changeBucket(bucketDetails.id, true)
      // }
      this.props.changeBucket(bucketDetails.id, true)
    })
    if (!isFilterChanged) {
      updatedBucketItem = updatedBucketItem.filter((item) => item !== id)
      const isSaveEnable = updatedBucketItem.length !== 0
      this.setState({ updatedBucketItem, isSaveEnable })
    }
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  onBucketEditClick = () => {
    this.setState({ visible: true })
  }

  editBucket = editRequest => {
    this.setState({ loading: true })
    editRequest.id = this.props.bucketId
    editRequest.updatedAt = new Date().toISOString()
    editRequest.updatedBy = this.username
    userSessionId = utilityService.makeRandomString(6)
    sessionStorage.setItem('user_session_id', userSessionId)
    this.props.updateBucket(editRequest).then(response => {
      this.props.updateHistory()
      let { bucketDetails } = this.state
      const responseData = response.data.updateBucket
      bucketDetails.name = responseData.name
      bucketDetails.displayType = responseData.displayType
      bucketDetails.displayName = responseData.displayName
      this.setState({
        loading: false,
        visible: false,
        bucketDetails
      })
    }, error => {
      // this.setState({ loading: false })
      // utilityService.handleError(error)
      this.getBucket(error)
    })
  }

  deleteBucket = () => {

  }

  getBucket = (error) => {
    this.setState({ isModifiedLoading: true }, () => {
      this.props.fetchMoreBucketDetails().then(({ data }) => {
        if (error) { utilityService.handleError(error) }
        this.setState({
          bucketDetails: _.cloneDeep(data.getBucket),
          entities: this.loadBucketDetailsTolistData(_.cloneDeep(data.getBucket)),
          isModifiedLoading: false,
          isWarningShow: false,
          isSaveEnable: false,
          loading: false,
          saveLoading: false
        })
      })
    })
  }

  refetchBucketData = () => {
    this.setState({ isModifiedLoading: true }, () => {
      // this.props.fetchMoreBucketDetails().then(({ data }) => {
      // if (error) { utilityService.handleError(error) }
      this.props.forceSaveCompleted(this.state.tempBucketDetails.id)
      setTimeout(() => {
        this.setState({
          bucketDetails: _.cloneDeep(this.state.tempBucketDetails),
          entities: this.loadBucketDetailsTolistData(_.cloneDeep(this.state.tempBucketDetails)),
          isModifiedLoading: false,
          isWarningShow: false,
          isSaveEnable: false,
          loading: false,
          saveLoading: false,
          tempBucketDetails: null })
      }, 1000)
      // })
    })
  }

  saveBucket = async (isAutoSave, isFromDelete) => {
    if (this.state.isSaveEnable) {
      this.props.onSave(this.props.bucketId)
      const { isUpdateBlocked } = this.props
      if (isUpdateBlocked) { return }
      this.setState({ saveLoading: true })
      // let isLatest = false
      // // await this.props.fetchBucketDetails().then(({ data }) => {
      // //   const { bucketDetails } = this.state
      // //   isLatest = moment(data.getBucket.updatedAt).isAfter(moment(bucketDetails.updatedAt), 'seconds')
      // // })
      // if (isLatest) {
      //   this.setState({ isWarningShow: true, saveLoading: false })
      // } else {
      const { bucketId } = this.props
      let { bucketItems } = this.state.bucketDetails
      userSessionId = utilityService.makeRandomString(6)
      sessionStorage.setItem('user_session_id', userSessionId)
      const assetList = (bucketItems || []).filter((item) => item.type === 'ASSET').map((item) => {
        const isImagePresent = (!item.customAssetImage && item.customMedia) ? (item.images || []).find(imageItem => imageItem.mediaId === item.customMedia.id) : null
        return {
          id: item.id,
          position: item.position,
          assetId: item.asset ? item.asset.id : null,
          type: item.type,
          customMedia: item.customAssetImage ? item.customAssetImage.mediaId : (item.customMedia && isImagePresent) ? item.customMedia.id : null,
          customAssetImage: item.customAssetImage ? item.customAssetImage.id : null,
          customTitle: item.customTitle,
          bucket: bucketId
        }
      })
      const filterList = (bucketItems || []).filter((item) => item.type === 'FILTER').map((item) => {
        return {
          bucket: bucketId,
          position: item.position,
          type: item.type,
          id: item.id,
          filterRule: {
            searchKey: item.filterRule.searchKey,
            filters: (item.filterRule && item.filterRule.filters && item.filterRule.filters.length ? item.filterRule.filters : []).map((filterItem) => {
              if (filterItem.type === 'KEYWORD' || filterItem.type === 'SELECT') {
                if (typeof (filterItem.value) !== 'string') {
                  let value = (filterItem.value || []).map((filterInner) => filterInner.key)
                  let key = (filterItem.value || []).map((filterInner) => filterInner.name)
                  filterItem.value = value && value.length ? value.join() : null
                  filterItem.displayValue = key && key.length ? key.join() : null
                }
              }
              delete (filterItem.__typename)
              return filterItem
            })
          }
        }
      })
      let bucketListItems = [...assetList, ...filterList]
      bucketListItems = bucketListItems && bucketListItems.length ? bucketListItems : null
      let bucketIdList = bucketListItems && bucketListItems.length ? bucketListItems.map((item) => item.id) : null
      if (isFromDelete || !bucketListItems) {
        const variables = {
          id: bucketId,
          buckets: bucketIdList,
          updatedAt: new Date().toISOString(),
          updatedBy: this.username
        }
        const updatedBucketItems = this.getSortedList(_.cloneDeep(this.state.bucketDetails.bucketItems || []))
        bucketListItems = (bucketListItems || []).map(bucketItem => {
          const itemIndex = updatedBucketItems.findIndex(item => item.id === bucketItem.id)
          if (itemIndex > -1) {
            bucketItem.position = itemIndex
          }
          return bucketItem
        })
        let updatedBucketListItems = _.cloneDeep(this.props.bucketDetails.bucketItems || [])
        bucketListItems = (bucketListItems || []).filter(item => {
          const tempIndex = updatedBucketListItems.findIndex(innerItem => innerItem.id === item.id)
          const isEdited = this.state.updatedBucketItem.indexOf(item.id) > -1
          return isEdited || tempIndex === -1 || updatedBucketListItems[tempIndex].position !== item.position
        })
        if (bucketListItems.length) {
          this.updatedBucketItemAndUpdateBucket(bucketListItems, variables, isAutoSave)
        } else {
          this.updateBucket(variables, isAutoSave)
        }
      } else {
        bucketListItems = bucketListItems && bucketListItems.length ? bucketListItems : null
        const variables = {
          id: bucketId,
          buckets: bucketIdList,
          updatedAt: new Date().toISOString(),
          updatedBy: this.username
        }
        bucketListItems = (bucketListItems || []).map(item => {
          const isAuditChange = this.changedBucketItem.includes(item.id)
          item.trackPosChange = isAuditChange
          return item
        })
        const updatedBucketItems = this.getSortedList(_.cloneDeep(this.state.bucketDetails.bucketItems))
        bucketListItems = bucketListItems.map(bucketItem => {
          const itemIndex = updatedBucketItems.findIndex(item => item.id === bucketItem.id)
          if (itemIndex > -1) {
            bucketItem.position = itemIndex
          }
          return bucketItem
        })
        let updatedBucketListItems = _.cloneDeep(this.props.bucketDetails.bucketItems || [])
        bucketListItems = (bucketListItems || []).filter(item => {
          const tempIndex = updatedBucketListItems.findIndex(innerItem => innerItem.id === item.id)
          const isEdited = this.state.updatedBucketItem.indexOf(item.id) > -1
          return isEdited || tempIndex === -1 || updatedBucketListItems[tempIndex].position !== item.position
        })

        if (bucketListItems) {
          this.updatedBucketItemAndUpdateBucket(bucketListItems, variables, isAutoSave)
        } else {
          this.updateBucket(variables, isAutoSave)
        }
      }
      if (this.props.isForceSave) { this.props.forceSaveCompleted(this.props.bucketId) }
    }
  }

  updatedBucketItemAndUpdateBucket = (bucketListItems, variables, isAutoSave) => {
    const { bucketId } = this.props
    variables.bucketItems = variables.buckets
    delete variables.buckets
    // this.props.updateBucketItem(bucketListItems).then(() => {
    //   this.props.updateBucket(variables).then(({ data }) => {
    this.props.saveAllBuckets({ bucketItems: bucketListItems, bucket: variables, id: bucketId }).then(({ data }) => {
      this.props.changeBucket(bucketId, false)
      this.changedBucketItem = []
      this.setState({ isSaveEnable: false, saveLoading: false, bucketDetails: data.saveAllBuckets })
      if (!isAutoSave) { message.success(userMessages.BUCKET_UPDATED_SUCCESS) }
      this.props.updateHistory()
    }, error => {
      // this.setState({ saveLoading: false })
      // utilityService.handleError(error)
      this.getBucket(error)
    })
    // }, error => {
    //   // utilityService.handleError(error)
    //   this.getBucket(error)
    // })
  }

  updateBucket = (variables, isAutoSave) => {
    const { bucketId } = this.props
    this.props.updateBucket(variables).then(({ data }) => {
      this.props.changeBucket(bucketId, false)
      this.setState({ isSaveEnable: false, saveLoading: false, updatedBucketItem: [], bucketDetails: data.updateBucket })
      if (!isAutoSave) { message.success(userMessages.BUCKET_UPDATED_SUCCESS) }
      this.props.updateHistory()
    }, error => {
      // this.setState({ saveLoading: false })
      // utilityService.handleError(error)
      this.getBucket(error)
    })
  }

  copyLink = () => {
    var anchor = document.createElement('a')
    anchor.href = `${location.origin}/buckets/preview/${this.props.bucketId}`
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  toggleDeleteeWarningMessage = (status) => {
    this.setState({ showWarning: status })
  }

  removeBucketItem = (itemId) => {
    let { bucketDetails, updatedBucketItem, selectedTaskIds } = this.state
    let { bucketItems } = bucketDetails
    const deletedIndex = bucketItems.findIndex((item) => item.id === itemId)
    bucketItems = bucketItems.filter((item) => item.id !== itemId).map((item, index) => {
      if (deletedIndex <= index && updatedBucketItem.indexOf(item.id) === -1) {
        updatedBucketItem.push(item.id)
      }
      item.position = index
      return item
    })
    bucketDetails.bucketItems = bucketItems && bucketItems.length ? bucketItems : null
    let filterSelectedTaskIds = (selectedTaskIds || []).filter(item => item !== itemId)
    this.setState({
      bucketItems,
      entities: this.loadBucketDetailsTolistData(_.cloneDeep(bucketDetails)),
      updatedBucketItem,
      selectedTaskIds: filterSelectedTaskIds
    }, () => {
      // /* DISABLED AUTO SAVE */
      // if (bucketDetails.status === 'DRAFT') { this.saveBucket(true, true) } else {
      //   this.props.changeBucket(bucketDetails.id, true)
      //   this.setState({ isSaveEnable: true })
      // }
      this.props.changeBucket(bucketDetails.id, true)
      this.setState({ isSaveEnable: true })
    })
  }

  onAssetTitleChange = (title, id) => {
    let { updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const bucketItemIndex = bucketItems.findIndex((item) => item.id === id)
    bucketItems[bucketItemIndex].customTitle = title
    if (updatedBucketItem.indexOf(id) === -1) {
      updatedBucketItem.push(id)
    }
    this.setState({ bucketItems, updatedBucketItem }, () => {
      // /* DISABLED AUTO SAVE */
      // if (this.state.bucketDetails.status === 'DRAFT') { this.saveBucket(true, false) } else {
      //   this.props.changeBucket(this.state.bucketDetails.id, true)
      //   this.setState({ isSaveEnable: true })
      // }
      this.props.changeBucket(this.state.bucketDetails.id, true)
      this.setState({ isSaveEnable: true })
    })
  }

  onAssetDisplayImageChange = (media, id, defaultMedia) => {
    let { updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const bucketItemIndex = bucketItems.findIndex((item) => item.id === id)
    bucketItems[bucketItemIndex].customAssetImage = media
    if (defaultMedia) {
      bucketItems[bucketItemIndex].asset.defaultMedia = defaultMedia
    } else if (!media && !defaultMedia) {
      bucketItems[bucketItemIndex].asset.defaultMedia = null
    }
    if (updatedBucketItem.indexOf(id) === -1) {
      updatedBucketItem.push(id)
    }
    this.setState({ bucketItems, updatedBucketItem }, () => {
      // /* DISABLED AUTO SAVE */
      // if (this.state.bucketDetails.status === 'DRAFT') { this.saveBucket(true, false) } else {
      //   this.props.changeBucket(this.state.bucketDetails.id, true)
      //   this.setState({ isSaveEnable: true })
      // }
      this.props.changeBucket(this.state.bucketDetails.id, true)
      this.setState({ isSaveEnable: true })
    })
  }

  onAssetTitleReset = (id) => {
    let { updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const bucketItemIndex = bucketItems.findIndex((item) => item.id === id)
    bucketItems[bucketItemIndex].customTitle = null
    if (updatedBucketItem.indexOf(id) === -1) {
      updatedBucketItem.push(id)
    }
    this.setState({ bucketItems, updatedBucketItem }, () => {
      // /* DISABLED AUTO SAVE */
      // if (this.state.bucketDetails.status === 'DRAFT') { this.saveBucket(true, false) } else {
      //   this.props.changeBucket(this.state.bucketDetails.id, true)
      //   this.setState({ isSaveEnable: true })
      // }
      this.props.changeBucket(this.state.bucketDetails.id, true)
      this.setState({ isSaveEnable: true })
    })
  }

  onAssetDisplayImageReset = (id) => {
    let { updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const bucketItemIndex = bucketItems.findIndex((item) => item.id === id)
    bucketItems[bucketItemIndex].customMedia = null
    bucketItems[bucketItemIndex].customAssetImage = null
    if (updatedBucketItem.indexOf(id) === -1) {
      updatedBucketItem.push(id)
    }
    this.setState({ bucketItems, updatedBucketItem }, () => {
      // /* DISABLED AUTO SAVE */
      // if (this.state.bucketDetails.status === 'DRAFT') { this.saveBucket(true, false) } else {
      //   this.props.changeBucket(this.state.bucketDetails.id, true)
      //   this.setState({ isSaveEnable: true })
      // }
      this.props.changeBucket(this.state.bucketDetails.id, true)
      this.setState({ isSaveEnable: true })
    })
  }

  onDragEnd = (startIndex, endIndex) => {
    let { listData } = this.state
    const [ removed ] = listData.splice(startIndex, 1)
    listData.splice(endIndex, 0, removed)
    this.setState({ listData })
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  showPublish = () => {
    this.setState({ showPublish: true })
  }

  handleCancel = () => {
    this.setState({ showPublish: false, visible: false })
  }

  onBucketScroll= () => {
    this.setState({ isScroll: false })
  }

  onMultiDrop = (data) => {
    let { bucketDetails } = this.state
    bucketDetails.bucketItems = data
    this.props.changeBucket(bucketDetails.id, true)
    this.setState({
      bucketDetails,
      isSaveEnable: true
    })
  }

  toggleSelection = (taskId) => {
    const selectedTaskIds = this.state.selectedTaskIds
    const wasSelected = selectedTaskIds.includes(taskId)

    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId]
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId]
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return []
    })()
    this.setState({
      selectedTaskIds: newTaskIds,
      entities: this.state.entities
    })
  }

  toggleSelectionInGroup = (taskId) => {
    const selectedTaskIds = this.state.selectedTaskIds
    const index = selectedTaskIds.indexOf(taskId)

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        selectedTaskIds: [...selectedTaskIds, taskId],
        entities: this.state.entities
      })
      return
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedTaskIds]
    shallow.splice(index, 1)
    this.setState({
      selectedTaskIds: shallow,
      entities: this.state.entities
    })
  }

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = (newTaskId) => {
    const updated = multiSelect(
      this.state.entities,
      this.state.selectedTaskIds,
      newTaskId
    )
    if (updated == null) {
      return
    }
    this.setState({
      selectedTaskIds: updated,
      entities: this.state.entities
    })
  }

  render () {
    const { isEditted, bucketDetails, visible, isSaveEnable, loading, showWarning, showPublish, saveLoading, addFilterLoading, isScroll, isWarningShow,
      isModifiedLoading, tempBucketDetails, selectedTaskIds } = this.state
    const { bucketId, deleteBucketGroupItem, selected, updateHistory, displayTypes, isCreateBlocked, isUpdateBlocked,
      isDeleteBlocked, draggingTaskId, isUpdateDisabledContent, isCreateDisabledContent, isDeleteDisabledContent, project } = this.props
    return (
      <div className={`bucket-detail-cell${selected ? ' selected' : ''}`} id={`bucket-detail-cell-${bucketId}`}>
        {saveLoading ? <div className='overlay' /> : ''}
        <BucketDetailHeading onAddSearch={this.onAddSearch}
          bucketName={bucketDetails ? bucketDetails.name : undefined}
          bucketType={bucketDetails && bucketDetails.displayType ? bucketDetails.displayType.name : undefined}
          status={bucketDetails ? bucketDetails.status : undefined}
          bucketId={bucketDetails ? bucketDetails.key : undefined}
          id={bucketId}
          onBucketEditClick={this.onBucketEditClick}
          deleteBucket={() => this.toggleDeleteeWarningMessage(true)}
          saveBucket={this.saveBucket}
          showPublish={this.showPublish}
          copyLink={this.copyLink}
          editBucket={this.onBucketEditClick}true
          isSaveEnable={isSaveEnable}
          isDisableAddFilter={this.getAddFilterStatus() || saveLoading || addFilterLoading}
          loading={saveLoading}
          // isCreateBlocked={isCreateBlocked}
          isUpdateBlocked={isUpdateBlocked}
          isDeleteBlocked={isDeleteBlocked}
          isSelectionBlocking={!bucketDetails}
          project={project}
        />
        { bucketDetails ? <BucketDetailList
          listData={this.getSortedList(bucketDetails.bucketItems) || []}
          isEditted={isEditted}
          onAddImage={this.onAddImage}
          onChangeFilter={this.onChangeFilter}
          onDragEnd={this.onDragEnd}
          handleAssetTitle={this.onAssetTitleChange}
          handleAssetTitleReset={this.onAssetTitleReset}
          handleAssetDisplayImageReset={this.onAssetDisplayImageReset}
          onAssetDisplayImageChange={this.onAssetDisplayImageChange}
          bucketId={bucketId}
          removeBucketItem={this.removeBucketItem}
          parent={parent}
          isSaveEnable={isSaveEnable}
          saveLoading={saveLoading}
          parentCompoent={`bucket-detail-cell-${bucketId}`}
          isScroll={isScroll}
          onBucketScroll={this.onBucketScroll}
          isUpdateBlocked={isUpdateBlocked}
          isCreateBlocked={isCreateBlocked}
          selectedTaskIds={selectedTaskIds}
          toggleSelection={this.toggleSelection}
          toggleSelectionInGroup={this.toggleSelectionInGroup}
          multiSelectTo={this.multiSelectTo}
          draggingTaskId={draggingTaskId}
          project={project}
          isUpdateDisabledContent={isUpdateDisabledContent}
          isCreateDisabledContent={isCreateDisabledContent}
          isDeleteDisabledContent={isDeleteDisabledContent}
        /> : <React.Fragment>
          <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: ['calc(100% - 100px)', '50%', '100%'] }} loading />
          <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: ['calc(100% - 100px)', '50%', '100%'] }} loading />
        </React.Fragment> }

        <AddBucketModal visible={visible}
          handleCancel={this.handleCancel}
          createBucket={this.editBucket}
          bucketDetails={bucketDetails}
          isEdit
          loading={loading}
          displayTypes={displayTypes}
        />

        <ConfirmModal
          isVisible={showWarning}
          title={deleteWarningMessage.title}
          message={deleteWarningMessage.message}
          isLoading={false}
          rightButtonText={'Confirm'}
          handleCancel={() => this.toggleDeleteeWarningMessage(false)}
          handleSubmit={() => { deleteBucketGroupItem(); this.toggleDeleteeWarningMessage(false) }}
        />

        <PublishBucketModal visible={showPublish && bucketDetails}
          handleCancel={this.handleCancel}
          bucketId={bucketId}
          updateHistory={updateHistory}
          project={project}
        />

        <ConfirmModal
          isVisible={isWarningShow}
          title={'Warning'}
          message={tempBucketDetails ? `${tempBucketDetails.name} has been modified by ${tempBucketDetails.updatedBy}. Please refetch bucket details to get the latest updates` : ''}
          isLoading={isModifiedLoading}
          rightButtonText={'Refetch data'}
          handleSubmit={this.refetchBucketData}
          isSubmitButtonDisabled={false}
          isCancelButtonDisabled
          isCancelButtonInvisible />

      </div>
    )
  }
}

BucketDetailCell.propTypes = {
  /** id of selected bucket. */
  id: PropTypes.string,
  /** Source index for bucket item drag and rearrange */
  sourceIndex: PropTypes.number,
  /** Destination index for bucket item drag and rearrange */
  destinationIndex: PropTypes.number,
  /** ID of newly added asset in buckets */
  droppableId: PropTypes.string,
  /** Id of buckets inwhich new asset added */
  draggable: PropTypes.object,
  /** Function to be called to clear drag and drop id */
  clearDragDropId: PropTypes.func,
  /** Function to be called to notify unsaved changes */
  changeBucket: PropTypes.func,
  /** Boolean for force save bucket */
  isForceSave: PropTypes.bool,
  /** Function to be called on complete of forced save of bucket */
  forceSaveCompleted: PropTypes.func,
  /** Boolean for disabling delete */
  isDeleteBlocked: PropTypes.bool,
  /** Boolean for disabling update */
  isUpdateBlocked: PropTypes.bool,
  /** Boolean for disabling create */
  isCreateBlocked: PropTypes.bool
}

BucketDetailCell.defaultProps = {}

export default withApollo(compose(
  graphql(
    QueryGetBucketDetails,
    {
      skip: ({ bucketId }) => {
        return !bucketId
      },
      options: ({ bucketId }) => {
        return {
          variables: { id: bucketId }
        }
      },
      props: (properties) => {
        let bucketItems
        const props = cloneDeep(properties)
        if (props.data.getBucket) {
          bucketItems = props.data.getBucket.bucketItems
          bucketItems = (bucketItems || []).filter(item => item && (item.type !== 'ASSET' || item.asset)).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
          props.data.getBucket.bucketItems = bucketItems
          props.data.getBucket.status = utilityService.getPublishStatus(props.data.getBucket.isPublished, props.data.getBucket.publishStartDate, props.data.getBucket.publishEndDate)
        }
        return {
          bucketDetails: _.cloneDeep(props.data.getBucket),
          isLoading: props.data.loading,
          fetchMoreBucketDetails: () => {
            return props.data.refetch()
          }
          // subscribeToChangeBucket: () => subscribeChangeInBucket(props.data.subscribeToMore, props)
        }
      }
    }
  ),
  graphql(
    MutationCreateBucketItem,
    {
      options: (props) => ({
        // update: (cache, { data: { createBucket } }) => {
        //   const variables = { limit: 50 }
        //   const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterBuckets, variables }))
        //   if (listCacheData && listCacheData.listBuckets && listCacheData.listBuckets.items) {
        //     listCacheData.listBuckets.items.splice(0, 0, createBucket)
        //     listCacheData.listBuckets.totalCount++
        //   }
        //   cache.writeQuery({
        //     query: QueryFilterBuckets,
        //     data: listCacheData,
        //     variables
        //   })
        // }
      }),
      props: (props) => ({
        createBucketItem: (bucketItemList) => {
          let variables = bucketItemList
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateBucket,
    {
      // options: ({ bucketId }) => ({
      //   update: (cache, { data: { updateBucket } }) => {
      //     const variables = { id: bucketId }
      //     const cachedData = _.cloneDeep(cache.readQuery({ query: QueryGetBucketDetails, variables }))
      //     if (cachedData && cachedData.getBucket) {
      //       cachedData.getBucket = updateBucket
      //     }
      //     cache.writeQuery({
      //       query: QueryGetBucketDetails,
      //       data: cachedData,
      //       variables
      //     })
      //   }
      // }),
      props: (props) => ({
        updateBucket: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationSaveAllBuckets,
    {
      props: (props) => ({
        saveAllBuckets: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateBucketItem,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateBucketItem: (bucketItems) => {
          let variables = bucketItems
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryGetBucketUpdatedDetails,
    {
      skip: ({ bucketId }) => {
        return !bucketId
      },
      options: ({ bucketId }) => {
        return {
          variables: { id: bucketId }
        }
      },
      props: (props) => {
        return {
          bucketUpdatedDetails: _.cloneDeep(props.data.getBucket),
          isLoading: props.data.loading,
          fetchBucketDetails: () => {
            return props.data.refetch()
          }
        }
      }
    }
  )
)(BucketDetailCell))
