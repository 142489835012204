import { ApolloLink } from 'apollo-link'
import LoggerService from './../services/LoggerService'

const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime()
  return forward(operation).map(result => {
    const ellapsed = new Date().getTime() - startTime
    const networkData = {
      request: operation,
      response: result,
      timeTaken: ellapsed
    }
    if (result && result.errors) {
      LoggerService.error('Network Error', 'Network Error', networkData)
    } else {
      LoggerService.info('Network Log', 'Network Log', networkData)
    }
    return result
  })
})

export default loggerLink
