import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import LoadingButton from '../../general/buttons/LoadingButton'
import AddJobAssetData from './AddJobAssetData'

class AddJobAssetCsvModal extends Component {
    renderFooter = () => {
      const { isUploadBtnLoading, isCancelButtonDisabled, isUploadButtonDisable, isCreateDisabled } = this.props
      return <div className='footer-container'>
        <div>
          <LoadingButton className='ant-btn'
            type={'button'}
            buttonText={'Cancel'}
            isDisabled={isCancelButtonDisabled}
            onClick={this.props.handleCancel} />
          <LoadingButton className='ant-btn ant-btn-primary'
            buttonText={'Upload'}
            isLoading={isUploadBtnLoading}
            onClick={this.props.handleSubmit}
            isDisabled={isUploadButtonDisable || isCreateDisabled} />
        </div>
      </div>
    }

    renderHeader = () => {
      const { exportCsv, isUploadBtnLoading, exportGraceNoteCsv } = this.props
      return <div className='header-container'>
        <p>IMPORT ASSETS</p>
        <div className='header-inner-container'>
          <a className={`download-template ${isUploadBtnLoading ? 'disable' : ''}`} href={exportCsv} download> Download Template</a>
          <a className={`download-template ${isUploadBtnLoading ? 'disable' : ''}`} href={exportGraceNoteCsv} download> Download GraceNote Template</a>
        </div>
      </div>
    }

    render () {
      const { isVisible, IngestClient, files, project, beforeUpload, handleDelete, fileList, handleCsvUpload, isCancelButtonDisabled, updateLastUploadedUrl,
        lastUploadedCsvUrl, onChangeGraceNoteSync, graceNoteSync, updateLastUploadedKey } = this.props
      const uploadProps = {
        multiple: false,
        beforeUpload: beforeUpload,
        customRequest: () => {}
      }
      return (
        <Modal
          className={`confirm-modal add-assets-csv`}
          title={this.renderHeader()}
          maskClosable={false}
          visible={isVisible}
          okButtonProps={false}
          cancelButtonProps={{ disabled: isCancelButtonDisabled }}
          closable={false}
          centered
          width='540px'
          afterClose={this.clearState}
          destroyOnClose
          footer={this.renderFooter()}
        >
          <AddJobAssetData
            files={files}
            IngestClient={IngestClient}
            fileList={fileList}
            uploadProps={uploadProps}
            project={project}
            handleDelete={handleDelete}
            handleCsvUpload={handleCsvUpload}
            updateLastUploadedUrl={updateLastUploadedUrl}
            lastUploadedCsvUrl={lastUploadedCsvUrl}
            graceNoteSync={graceNoteSync}
            onChangeGraceNoteSync={onChangeGraceNoteSync}
            updateLastUploadedKey={updateLastUploadedKey}
          />
        </Modal>
      )
    }
}

AddJobAssetCsvModal.propTypes = {
  /** boolean to handle modal visibility */
  isVisible: PropTypes.bool,
  /** function to handle modal cancel */
  handleCancel: PropTypes.func,
  /** url for csv upload */
  exportCsv: PropTypes.string,
  /** ingest Client configuration */
  IngestClient: PropTypes.object,
  /** array of uploaded files */
  files: PropTypes.array,
  /** current project */
  project: PropTypes.string,
  /** function to handle processing of file */
  beforeUpload: PropTypes.func,
  /** function to handle csvUpload modal */
  handleSubmit: PropTypes.func,
  /** function to handle file delete */
  handleDelete: PropTypes.func,
  /** array of file list */
  fileList: PropTypes.array,
  /** function to handle csv upload */
  handleCsvUpload: PropTypes.func,
  /** boolean for upload button loading */
  isUploadBtnLoading: PropTypes.bool,
  /** boolean for cancel button disable */
  isCancelButtonDisabled: PropTypes.bool,
  /** boolean for upload button disable */
  isUploadButtonDisable: PropTypes.bool,
  /** function to handle last upload url */
  updateLastUploadedUrl: PropTypes.func,
  /** last upload url */
  lastUploadedCsvUrl: PropTypes.string
}

AddJobAssetCsvModal.defaultProps = {
  selectedValue: 'Select'
}

export default AddJobAssetCsvModal
