import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import _ from 'lodash'
import Input from './../inputs/Input'
import './../../ui.style.scss'

class BucketEditTitleModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      newTitle: props.bucketAssetData ? props.bucketAssetData.customTitle || props.bucketAssetData.asset.title : '',
      disable: true,
      title: props.bucketAssetData ? props.bucketAssetData.customTitle || props.bucketAssetData.asset.title : ''
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.bucketAssetData, this.props.bucketAssetData)) {
      this.setState({
        newTitle: newProps.bucketAssetData ? newProps.bucketAssetData.customTitle || this.props.bucketAssetData.asset.title : '',
        disable: true,
        title: newProps.bucketAssetData ? newProps.bucketAssetData.customTitle || this.props.bucketAssetData.asset.title : ''

      })
    }
  }

  resetState = () => {
    const state = {
      newTitle: '',
      disable: true
    }
    this.setState({ ...state })
  }

  handleChange =(e) => {
    const { value } = e.target
    this.setState({ newTitle: value }, () => this.onTitleChange())
  }

  handleSubmit = () => {
    const { newTitle } = this.state
    this.props.handleSubmit(newTitle)
  }

  onTitleChange = () => {
    const { newTitle, title } = this.state
    if (newTitle === title || newTitle === '') {
      this.setState({ disable: true })
    } else {
      this.setState({ disable: false })
    }
  }

  render () {
    const { visible, handleCancel, title, loading } = this.props
    const { newTitle, disable } = this.state
    return (

      <Modal
        className='general-modal bucket-edit-title-modal'
        visible={visible}
        closable={false}
        title={title}
        okButtonProps={{ disabled: disable, loading: loading }}
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okText='Save'
        cancelText='Cancel'
        afterClose={this.resetState}
        centered
        destroyOnClose
        maskClosable={false}
      >
        <Input title='Asset Title' handleChange={this.handleChange} value={newTitle} />
      </Modal>

    )
  }
}

BucketEditTitleModal.propTypes = {
  /** visible action of BucketEditTitleModal. */
  visible: PropTypes.bool,
  /** title  of BucketEditTitleModal. */
  title: PropTypes.string,
  /** handleCancel action of BucketEditTitleModal. */
  handleCancel: PropTypes.func
}

export default BucketEditTitleModal
