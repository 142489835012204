import React from 'react'
import PropTypes from 'prop-types'

const LogoutIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill={color} fillRule='nonzero' d='M0 0v15h10.227v-4.09H8.864v2.726h-7.5V1.364h7.5V4.09h1.363V0H0zm10.66 5.09l.965-.965L15 7.5l-3.375 3.375-.964-.964 1.728-1.728H4.773V6.82h7.619L10.66 5.09z' />
  </svg>
)

LogoutIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

LogoutIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#343A40'
}

export default LogoutIcon
