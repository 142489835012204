import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import Input from '../inputs/Input'
import { utilityService } from '../../../../services/UtilityService'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateProductionStudio from './../../../../graphQL/admin/entertainmentData/createProductionStudio'
import QueryProductionStudio from './../../../../graphQL/admin/entertainmentData/listProduction'
import QueryProductionStudioList from './../../../../graphQL/asset/hyperion/listProductionStudios'
import MutationUpdateProductionStudio from './../../../../graphQL/admin/entertainmentData/updateProductionStudio'

class CreateProductionModal extends Component {
    state = {
      productionName: undefined,
      isLoading: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedProduction && (!_.isEqual(this.props.selectedProduction, nextProps.selectedProduction) || (!this.state.productionName))) {
        this.setState({
          productionName: nextProps.selectedProduction.name
        })
      }
      if (nextProps.isDeleted) {
        this.resetState()
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ productionName: value })
        }
      }
    }

    handleAddProduction = () => {
      const { productionName } = this.state
      const { selectedProduction, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })

      if (selectedProduction) {
        const newProduction = {
          id: selectedProduction.id,
          name: productionName
        }
        if (!productionName) {
          message.error('Name is mandatory field')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateProduction(newProduction).then(response => {
          this.props.handleSubmit(response.data.updateProductionStudio)
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        const newProduction = {
          name: productionName
        }
        this.props.createProduction(newProduction).then(response => {
          if (onHistoryUpdate) {
            onHistoryUpdate()
          }
          message.success('Production studio created successfully')
          this.props.handleSubmit(response.data.createProductionStudio)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    resetState = () => {
      const state = {
        productionName: '',
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedProduction, isSubmitDisabled } = this.props
      const { productionName, isLoading } = this.state
      const disabled = selectedProduction ? (selectedProduction.name === productionName && selectedProduction.name) : (!productionName)
      return (
        <Modal
          className='general-modal create-general-modal'
          title={selectedProduction ? 'EDIT PRODUCTION' : 'NEW PRODUCTION'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddProduction}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='539px'
          confirmLoading={isLoading}
        >
          <div className='create-role'>
            <Input title='Name' isRequired inputName='name' handleChange={this.handleChange} value={productionName} placeholder='Enter Production name' />
          </div>
        </Modal>
      )
    }
}

CreateProductionModal.propTypes = {
  /** visible file status of CreateRoleModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateRoleModal. */
  handleCancel: PropTypes.func,
  /** Module name */
  module: PropTypes.string
}

CreateProductionModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateProductionStudio,
    {
      options: ({ listApi, project, searchString, isFromAsset, productionSearch }) => {
        return {
          update: (cache, { data: { createProductionStudio } }) => {
            try {
              const variables = { limit: 20, offset: 0, project: project, search: searchString || null }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryProductionStudio, variables }))
              if (cacheData && cacheData.listProductionStudio && cacheData.listProductionStudio.items) {
                cacheData.listProductionStudio.items.unshift(createProductionStudio)
              }
              cache.writeQuery({
                query: QueryProductionStudio,
                data: cacheData,
                variables
              })
            } catch (e) { }
            try {
              if (isFromAsset) {
                const search = productionSearch ? { keyword: productionSearch, fields: ['name'] } : null

                const cacheData = _.cloneDeep(cache.readQuery({ query: QueryProductionStudioList, variables: { search, offset: 0, limit: 30, project: project } }))
                if (cacheData && cacheData.listProductionStudio && cacheData.listProductionStudio.items) {
                  cacheData.listProductionStudio.items.unshift(createProductionStudio)
                }
                cache.writeQuery({
                  query: QueryProductionStudio,
                  data: cacheData,
                  variables: { project: project }
                })
              }
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createProduction: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateProductionStudio,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateProductionStudio } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project: project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryProductionStudio, variables }))
              const index = cacheData.listProductionStudio.items.findIndex(item => item.id === updateProductionStudio.id)
              if (index > -1 && updateProductionStudio.name) {
                cacheData.listProductionStudio.items[index].name = updateProductionStudio.name
              }
              cache.writeQuery({
                query: QueryProductionStudio,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { filter: null, offset: 0, limit: 20, project: project }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryProductionStudio, variables: newVariables }))
                const index = newCacheData.listProductionStudio.items.findIndex(item => item.id === updateProductionStudio.id)
                if (index > -1 && updateProductionStudio.name) {
                  newCacheData.listProductionStudio.items[index].name = updateProductionStudio.name
                }
                cache.writeQuery({
                  query: QueryProductionStudio,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateProduction: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateProductionModal))
