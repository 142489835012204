import React from 'react'
import PropTypes from 'prop-types'
import CrossIcon from './../../../ui/general/icons/CrossIcon'
import ButtonContainer from '../../general/buttons/ButtonContainer'
import './../../ui.style.scss'

class PreviewImageModal extends React.Component {
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.previewContainerRef && this.previewContainerRef.contains(event.target)) {

    } else {
      this.props.onClose()
    }
  }

  render () {
    const { onClose, imageName, previewImage, visible, imageRatio, imageSize, size } = this.props
    return <div className='preview-image-modal'>
      <div className='image-container' style={{ width: size.width + 2, height: size.height + 2 }} ref={(node) => { this.previewContainerRef = node }}>
        <div className='image-details'>
          {/* <p>{imageName}</p>
          <p>  {imageRatio}  </p>
          <p>{imageSize}</p> */}
          <ButtonContainer displayTitle={imageName} childComponent={<p>{imageName}</p>} />
          <ButtonContainer displayTitle={imageRatio} childComponent={<p>{imageRatio}</p>} />
          <ButtonContainer displayTitle={imageSize} childComponent={<p>{imageSize}</p>} />
        </div>
        <CrossIcon onClick={onClose} visible={visible} />
        <div className='preview-image'>
          <img src={previewImage} alt='AspectRatio Image' width={size.width} height={size.height} />
        </div>
      </div>
    </div>
  }
}

PreviewImageModal.propTypes = {
  /** Image Name of PreviewImageModal. */
  imageName: PropTypes.string,
  /** Image AspectRatio of PreviewImageModal. */
  imageRatio: PropTypes.string,
  /** Image Resolution Size of PreviewImageModal. */
  imageSize: PropTypes.string,
  /** close action of PreviewImageModal. */
  onClose: PropTypes.func,
  /** Image Url of PreviewImageModal. */
  previewImage: PropTypes.string
}

PreviewImageModal.defaultProps = {
  previewImage: 'https://2bhkkyl1q5.execute-api.us-east-1.amazonaws.com/image/0x0:299x168/1920x1080/http://vcms-appsync-stack-dev-mediafilestorage-7ixtofy7q6ep.s3-website-ap-southeast-2.amazonaws.com/a4dfec64-f1cf-44dd-be1f-8ce1b1a6cc4b_images-1.jpeg'
}

export default PreviewImageModal
