import * as React from 'react'
import PropTypes from 'prop-types'
import Switch from '../dataEntry/inputs/Switch'
import { generateCroppedThumbnail } from './../../../util/util'

// const defaultImage = require('./../../../assets/images/avatar.jpg')

class AssetTypeList extends React.Component {
    checkIfAssetEnabled=(name, enabledAssetTypes) => {
      const index = (enabledAssetTypes || []).findIndex(asset => asset.assetType === name)
      if (index > -1) {
        return true
      } else {
        return false
      }
    }

    renderOptionListBody = () => {
      const { onChange, details } = this.props
      const { assetTypeList, enabledAssetTypes } = details
      const finalAssetTypeList = (assetTypeList || []).map(item => {
        return (
          <div className='option-item' key={item.id}>
            <div className='option-item-title'>
              {item.icon || item.draftIcon ? <img src={generateCroppedThumbnail((item.icon ? item.icon : item.draftIcon), 23, 23, '1:1')} /> : <img src={null} />}
              <span>{item.title}</span>
            </div>
            <Switch isChecked={this.checkIfAssetEnabled(item.title, enabledAssetTypes)} onChange={(status) => onChange(item.title, status)} />
          </div>
        )
      })
      return finalAssetTypeList
    }

    render () {
      return <div className='optional-tag-type-list-container'>
        <div className='optional-list-title'>
          <span>{'Asset Types'}</span>
          <span>{'Enabled'}</span>
        </div>
        <div className='optional-list-body'>
          <React.Fragment>
            {this.renderOptionListBody()}
          </React.Fragment>
        </div>
      </div>
    }
}

AssetTypeList.propTypes = {
  /** List of options */
  optionList: PropTypes.array,
  /** Function to be called on change of active state */
  onChange: PropTypes.func
}

export default AssetTypeList
