import React from 'react'
import PropTypes from 'prop-types'

const PublishIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' fillRule='nonzero' d='M10 0C4.477.001 0 4.478 0 10c.001 5.523 4.478 10 10 10 5.523-.001 10-4.477 10-10C20 4.477 15.523 0 10 0zM1.282 10.626H4.4c.044 1.162.204 2.204.45 3.124H2.108a8.717 8.717 0 0 1-.826-3.124zM10.625 5V1.382c.588.441 1.87 1.564 2.77 3.62h-2.77zm3.23 1.25c.268.898.447 1.94.497 3.125h-3.727V6.25h3.23zM9.092 1.603c.109-.09.202-.161.284-.222V5H6.601c.33-.752.71-1.38 1.09-1.895a8.596 8.596 0 0 1 1.4-1.503zm.284 4.648v3.125H5.65c.05-1.19.234-2.227.503-3.125h3.222zM4.4 9.376H1.282a8.71 8.71 0 0 1 .825-3.125H4.85c-.246.92-.406 1.963-.45 3.125zm1.247 1.25h3.728v3.124H6.144c-.268-.898-.447-1.94-.497-3.124zM9.375 15v3.618c-.588-.44-1.87-1.562-2.77-3.618h2.77zm1.533 3.397c-.108.09-.201.16-.283.222v-3.619h2.773a9.925 9.925 0 0 1-1.09 1.895 8.587 8.587 0 0 1-1.4 1.502zm-.283-4.648v-3.124h3.724a12.764 12.764 0 0 1-.502 3.124h-3.222zm4.975-3.124h3.116a8.707 8.707 0 0 1-.825 3.124H15.15c.246-.92.405-1.963.45-3.124zm0-1.25a14.195 14.195 0 0 0-.45-3.125h2.742c.457.958.744 2.011.824 3.125H15.6zM17.17 5h-2.43a11.1 11.1 0 0 0-1.947-3.284A8.764 8.764 0 0 1 17.17 5zM7.208 1.717A11.116 11.116 0 0 0 5.26 5H2.83a8.77 8.77 0 0 1 4.377-3.284zM2.83 15h2.43a11.098 11.098 0 0 0 1.945 3.28 8.763 8.763 0 0 1-4.375-3.28zm9.963 3.28a11.111 11.111 0 0 0 1.945-3.28h2.43a8.762 8.762 0 0 1-4.375 3.28z' />
    </g>
  </svg>
)

PublishIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

PublishIcon.defaultProps = {
  width: 20,
  height: 20
}

export default PublishIcon
