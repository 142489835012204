import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'
import NProgress from 'nprogress'
import MediaList from './MediaList'
import ContentDetails from './ContentDetails'
import AppContext from '../../AppContext'
import _ from 'lodash'
import './ContentManager.scss'

const getFormattedFilter = (filterVal) => {
  const filterData = typeof (filterVal) === 'string' ? filterVal.split(',') : filterVal
  let value = (filterData || []).map((innerItem) => innerItem.key)
  value = value && value.length ? value : null
  return value
}

class ContentManager extends Component {
    state = {
      filter: null,
      uploadUrl: undefined,
      fname: '',
      ftype: '',
      files: null,
      success: () => {},
      error: () => {},
      selectedMedia: '',
      searchString: '',
      deletedId: '',
      isClearFilter: false,
      isFolderSelected: false,
      folderData: {},
      shouldRefreshHistory: false
    }

    componentDidMount = () => {
      const { project } = this.props
      document.title = `Content Bank - ${project === 'hyperion' ? 'Hyperion' : 'Optus'} CMS`
    }

    UNSAFE_componentWillMount (newProps) { // eslint-disable-line camelcase
      if (this.props.match && this.props.match.params && this.props.match.params.id) {
        NProgress.start()
        this.setState({ selectedMedia: this.props.match.params.id })
      }
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (newProps.match && newProps.match.params && newProps.match.params.id !== this.state.selectedMedia) {
        NProgress.start()
        this.setState({ selectedMedia: newProps.match.params.id })
      }
    }

    onSelectImage = (images, selectedMedia, e, isFolder, folderData) => {
      if (isFolder) {
        this.setState({
          isFolderSelected: true,
          selectedMedia,
          folderData
        })
      } else {
        this.setState({
          isFolderSelected: false,
          selectedMedia
        }, () => {
          if (selectedMedia) {
            NProgress.start()
            this.props.history.push(`/contents/${selectedMedia}`)
          } else {
            this.props.history.push('/contents')
          }
        })
      }
    }

    onSearchMedia = (value) => {
      this.setState({ searchString: value })
    }

    updatedImageName = (files) => {
      const newFiles = files.map(file => ({ fileName: file.name, contentType: file.type, type: 'IMAGE' }))
      this.setState({ files: newFiles })
    }

    onDeleteMedia = id => {
      this.setState({ deletedId: id }, () => {
        setTimeout(this.setState({ deletedId: undefined }), 1000)
      })
    }

    clearFileName = () => this.setState({ files: [] })

    onFilterChange = (filter, folderFilter) => {
      let newFilter = { }
      if (filter && !_.isEmpty(filter)) {
        filter.map((filterItem) => {
          if (filterItem.value !== null) {
            if (filterItem.type === 'KEYWORD') {
              newFilter[filterItem.name] = {
                multiple_exact: getFormattedFilter(filterItem.value)
              }
            } else if (filterItem.type === 'DATE') {
              newFilter[filterItem.name] = {
                multiple_exact: filterItem.value.split(',')
              }
            } else {
              newFilter[filterItem.name] = {
                match: filterItem.value
              }
            }
          }
          // if (filterItem.value === null && filterItem.type === 'BOOLEAN') {
          //   newFilter[filterItem.name] = {
          //     match: false
          //   }
          // }
        })
      }
      if (folderFilter && !_.isEmpty(folderFilter)) {
        folderFilter.map((filterItem) => {
          if (filterItem.value !== null) {
            if (!newFilter[filterItem.name]) {
              newFilter[filterItem.name] = {}
            }
            if (filterItem.type === 'KEYWORD') {
              newFilter[filterItem.name]['multiple_precise'] = getFormattedFilter(filterItem.value)
            } else if (filterItem.type === 'DATE') {
              newFilter[filterItem.name]['multiple_precise'] = filterItem.value.split(',')
            } else {
              newFilter[filterItem.name]['exact'] = filterItem.value
            }
          }
          // if (filterItem.value === null && filterItem.type === 'BOOLEAN') {
          //   newFilter[filterItem.name] = {
          //     match: false
          //   }
          // }
        })
      }
      if (!_.isEmpty(newFilter) && !newFilter.isArchived) { // dont show archived Media inside folder when opening
        newFilter.isArchived = {
          match: false
        }
      }
      newFilter = _.isEmpty(newFilter) ? {
        isArchived: {
          match: false
        }
      } : newFilter
      this.setState({ filter: newFilter })
    }

    clearSearch = () => {
      const searchString = ''
      const filter = {
        isArchived: {
          match: false
        }
      }
      this.setState({ isClearFilter: true }, () => {
        this.setState({ searchString, filter }, () => {
          this.setState({ isClearFilter: false })
        })
      })
    }

    refetchHistory = () => {
      this.setState({ shouldRefreshHistory: true }, () => {
        this.setState({ shouldRefreshHistory: false })
      })
    }

    render () {
      const { files, selectedMedia, searchString, deletedId, filter, isClearFilter, isFolderSelected, folderData, shouldRefreshHistory } = this.state
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['CONTENT_BANK']
            // const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            return <Row className='content-manager' id='content-manager' ref={(ref) => { this.scrollParentRef = ref }}>
              <Col xl={{ span: 18 }} md={{ span: 18 }} className='content-list-container' >
                <MediaList
                  files={files}
                  isAddFolder
                  deletedId={deletedId}
                  selectedMedias={selectedMedia ? [selectedMedia] : []}
                  progress={this.progress}
                  currentSelection={[]}
                  searchString={searchString}
                  onFilterChange={this.onFilterChange}
                  onSearchMedia={this.onSearchMedia}
                  onSelectImage={this.onSelectImage}
                  updatedImageName={this.updatedImageName}
                  clearFileName={this.clearFileName}
                  parent={this.scrollParentRef}
                  parentId={'content-manager'}
                  filter={filter}
                  clearSearch={this.clearSearch}
                  isClearFilter={isClearFilter}
                  isUploadDisabled={isCreateDisabled}
                  project={project}
                  onUploadFolderUpdate={this.refetchHistory}
                />
              </Col>
              <Col xl={{ span: 6 }} md={{ span: 6 }} className='content-details-container'>
                <ContentDetails
                  appliedFilter={{ searchString, filter }}
                  selectedMedia={selectedMedia}
                  onDeleteMedia={this.onDeleteMedia}
                  isFolder={isFolderSelected}
                  folderData={folderData}
                  project={project}
                  isSaving={shouldRefreshHistory}
                />
              </Col>
            </Row>
          }}
        </AppContext.Consumer>
      )
    }
}

ContentManager.propTypes = {
  /* History parameter of dom */
  history: PropTypes.object,
  /* match parameter of dom */
  match: PropTypes.object

}

export default withRouter(ContentManager)
