import gql from 'graphql-tag'

export default gql(`
    mutation deleteProductionStudio($id: ID!, $name: String, $project: String) {
        deleteProductionStudio(
                id: $id,
                name: $name,
                project: $project
        ) {
            id
            name
            assetCount
        }
    }`
)
