import React from 'react'
import moment from 'moment'
import _ from 'lodash'
const lastReplaceRegex = /, ([^,]*)$/

const actionMap = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
  ADD: 'Added',
  REMOVE: 'Removed',
  RESEND_INVITATION: 'Invitation Resent to'
}
const fieldMap = {
  RICHTEXT: 'richtext content',
  TITLE: 'title content',
  IMAGE: 'image content',
  DEFAULT_IMAGE: 'default image content',
  AUTHOR: 'author content',
  DESCRIPTION: 'description content',
  SHARE: 'social media share content',
  SOCIAL: 'social media content',
  EMBED: 'embeded content',
  PROGRAM: 'channel program',
  FILTER: 'filter',
  ASSET: 'asset',
  relatedAssets: 'related asset list',
  shortTitle: 'short title',
  bucketItems: 'related asset item',
  createdBy: 'created by',
  publishEndDate: 'publish end date',
  publishStartDate: 'publish start date',
  ASSET_MANAGER: 'Asset Manager',
  CONTENT_BANK: 'Content Bank',
  BUCKET_MANAGER: 'Bucket Manager',
  CHANNEL_MANAGER: 'Channel Manager',
  DATA_MANAGER: 'Admin Manager',
  MATCH_MANAGER: 'Match Manager',
  JOB_MANAGER: 'Job Manager',
  ROLE_MANAGER: 'Role Manager',
  CUSTOMER_MANAGER: 'Customer Manager',
  ACCESS_ROLE_GROUP: 'User Groups',
  USER: 'User',
  RESET_MFA: 'User',
  USER_FORCE_LOGOUT: 'User'

}

const replaceIdwithItems = (ids, props) => {
  const { groupList } = props
  const modifiedIds = ids.map(id => {
    const groups = (groupList || []).find(item => item.id === id)
    if (groups) {
      return groups.name
    }
    return id
  })
  return modifiedIds
}

const getArrayChanges = (newValue, oldValue, field, props) => {
  let changedValue, action
  if ((newValue && (newValue.indexOf('[')) === 0) || (newValue || oldValue)) {
    const oldArrayString = (oldValue || '[]').replace('[', '').replace(']', '')
    const oldArray = oldArrayString !== '' ? oldArrayString.split(', ') : []
    const newArrayString = (newValue || '[]').replace('[', '').replace(']', '')
    const newArray = newArrayString !== '' ? newArrayString.split(', ') : []
    const newItems = replaceIdwithItems(_.difference(newArray, oldArray), props)
    const removedItems = replaceIdwithItems(_.difference(oldArray, newArray), props)
    if (newItems.length && removedItems.length) {
      action = 'ADD'
      if (field === 'users' && removedItems.length < 2) {
        field = 'user'
      }
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} and removed ${field} ${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')} from`
    } else if (removedItems.length) {
      action = 'REMOVE'
      changedValue = `${removedItems.join(', ').replace(lastReplaceRegex, ' and $1')} from`
    } else if (newItems.length) {
      action = 'ADD'
      changedValue = `${newItems.join(', ').replace(lastReplaceRegex, ' and $1')} to`
    }
    if (field === 'users' && newItems.length < 2) {
      field = 'user'
    }
  } else return
  return { changedValue, action, field }
}

const getFormattedRoleDetails = (audit, action, contentType, contentName, contentId, props) => {
  let field = fieldMap[audit.name] ? fieldMap[audit.name] : audit.name
  let content = contentId

  let changedValue = audit.value
  const oldValue = audit.oldValue
  const newValue = audit.value

  if (!oldValue || oldValue === '[]' || oldValue === 'null') {
    action = 'ADD'
  } else if (!newValue || newValue === '[]' || newValue === 'null') {
    action = 'REMOVE'
  }
  if (contentType === 'USER') {
    if (field === 'name') {
      return { action: actionMap[action], field, value: ` of ${content} to `, content: changedValue }
    }
    if (field === 'enabled') {
      return { action: newValue === 'false' ? 'Suspended' : 'Enabled', field: ` user ${content}` }
    }
  }
  if (contentType === 'ACCESS_ROLE_GROUP') {
    content = replaceIdwithItems([contentId], props)
    if (field === 'name') {
      return { action: actionMap[action], field, value: `${oldValue ? `of User Group - ${oldValue}` : ''} to `, content: changedValue }
    }
    if (field === 'description') {
      return { action: actionMap[action], field, value: ` of ${content} to `, content: changedValue }
    }
  }

  if (contentType === 'RESET_MFA' && action === 'RESET') {
    return { action: `Reset MFA device of`, field: contentId, value: '', content: '' }
  }

  if (contentType === 'USER_FORCE_LOGOUT' && action === 'UPDATE') {
    return { action: `Initiated Force Logout for`, field: contentId, value: '', content: '' }
  }

  const arrayChanges = getArrayChanges(newValue, oldValue, field, props)
  changedValue = arrayChanges ? arrayChanges.changedValue : changedValue
  action = arrayChanges ? arrayChanges.action : action
  field = arrayChanges ? arrayChanges.field : field

  if (changedValue === audit.value && action === 'REMOVE') {
    return { action: actionMap[action], field, value: audit.oldValue, content }
  }
  return { action: actionMap[action], field, value: ` ${['REMOVE', 'ADD'].indexOf(action) > -1 ? '' : 'to'} ${changedValue}`, content: `${fieldMap[contentType]} ${content}` }
}

const getFormattedRoleHistoryDetails = (details, props) => {
  const { updatedObj, action, contentType, contentName, contentId, updatedBy } = details
  let descriptions = (updatedObj || []).map(item => getFormattedRoleDetails(_.cloneDeep(item), action, contentType, contentName, contentId, props))
  if (!descriptions.length) {
    let field = contentType === 'USER' ? 'User' : 'Access Role Group'
    let content = contentId
    if (contentType === 'ACCESS_ROLE_GROUP') {
      content = replaceIdwithItems([contentId], props)
    }
    descriptions = [ { action: `${actionMap[action]}`, field, value: content } ]
  }

  if (contentId === 'csv_download') {
    descriptions = [ { action: `User CSV Downloaded` } ]
  }

  if (contentId === 'group_csv_download') {
    descriptions = [ { action: `User Group CSV Downloaded` } ]
  }

  if (action === 'SUSPEND') {
    descriptions = [ { action: `User ${contentName} suspended by ${updatedBy}` } ]
  }

  return descriptions.map((description, i) => (
    <p className='description' key={i}>
      {description.action}
      <span>{description.field}</span>
      {description.value}
      {description.content ? <span>{description.content}</span> : null}
    </p>
  ))
}

class RoleHistoryCell extends React.Component {
  render () {
    const { details } = this.props
    const { updatedBy, updatedAt, contentType } = details
    let historyList
    historyList = getFormattedRoleHistoryDetails(details, this.props)
    return (
      <div className='role-history-cell role-history-general' >
        <div className='date'> {moment(updatedAt).format('DD MMM YYYY HH:mm')} </div>
        <div className='date'> {updatedBy} </div>
        <div className='date'> {fieldMap[contentType]} </div>
        <div className='details'> {historyList} </div>
      </div>
    )
  }
}

RoleHistoryCell.defaultProps = {
  details: {
    user: 'David Nelson',
    date: '28 Nov 2018, 12:00',
    description: 'David Nelson created an Article “Football mourns the tragic ending for a man who created a miracle and so much hope”'
  }
}

export default RoleHistoryCell
