import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import Input from '../inputs/Input'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import './../../ui.style.scss'
import TagsInput from '../inputs/TagsInput'
import { utilityService } from './../../../../services/UtilityService'

import MutationCreateRating from './../../../../graphQL/admin/entertainmentData/createRating'
import QueryRating from './../../../../graphQL/admin/entertainmentData/listRatings'
import MutationUpdateRating from './../../../../graphQL/admin/entertainmentData/updateRating'

const parentCompoent = 'new-parental-rating-container'

class CreateParentRatingModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isEnable: false,
      isLoading: false,
      parentalRatingName: '',
      rating: [],
      subRatings: []

    }
  }

  UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
    if (nextProps.selectedRatingType && (!_.isEqual(this.props.selectedRatingType, nextProps.selectedRatingType) || (!this.state.parentalRatingName))) {
      this.setState({
        parentalRatingName: nextProps.selectedRatingType.scheme,
        rating: _.cloneDeep(nextProps.selectedRatingType.rating),
        subRatings: _.cloneDeep(nextProps.selectedRatingType.subRatings)
      })
    }
    if (nextProps.isDeleted) {
      this.resetState()
    }
  }

  resetState = () => {
    const state = {
      isLoading: false,
      parentalRatingName: '',
      rating: [],
      subRatings: []

    }
    this.setState({ ...state })
  }

  handleAddParentalRating = () => {
    const { parentalRatingName, rating, subRatings } = this.state
    const { selectedRatingType, onHistoryUpdate } = this.props
    this.setState({ isLoading: true })
    if (selectedRatingType) {
      const newRating = {
        id: selectedRatingType.id,
        scheme: parentalRatingName,
        rating: rating,
        subRatings: subRatings
      }
      if (!parentalRatingName) {
        message.error('Name is mandatory field')
        this.setState({ isLoading: false })
        return
      }
      if (_.isEmpty(rating)) {
        message.error('Rating is mandatory field')
        this.setState({ isLoading: false })
        return
      }
      if (_.isEmpty(subRatings)) {
        message.error('SubRatings is mandatory field')
        this.setState({ isLoading: false })
        return
      }
      this.props.updateRating(newRating).then(response => {
        this.props.handleSubmit(response.data.updateRating)
        if (onHistoryUpdate) {
          onHistoryUpdate()
        }
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    } else {
      const newRating = {
        scheme: parentalRatingName,
        rating: rating,
        subRatings: subRatings
      }
      this.props.createRating(newRating).then(response => {
        if (onHistoryUpdate) {
          onHistoryUpdate()
        }
        message.success('Rating created successfully')
        this.props.handleSubmit(response.data.createRating)
      }, error => {
        this.setState({ isLoading: false })
        utilityService.handleError(error)
      })
    }

    // const newParentRating = {
    //   scheme: parentalRatingName,
    //   rating: rating,
    //   subRating: subRating
    // }

    // if (!parentalRatingName && _.isEmpty(rating) && _.isEmpty(subRating)) {
    //   message.error('Fiel is mandatory')
    //   this.setState({ isLoading: false })
    // } else {
    //   message.success('ParentRating created successfully')
    //   this.props.handleSubmit(newParentRating)
    // }
  }

  handleParentalRatingName = (event) => {
    const parentalRatingName = event.target.value
    this.setState({ parentalRatingName })
  }

  handleRatingChange = (value, isDelete) => {
    const { rating } = this.state
    let newRating = [...rating] || []
    if (isDelete) {
      const index = newRating.indexOf(value)
      if (index > -1) {
        newRating.splice(index, 1)
      }
    } else {
      if (!rating) {
        newRating = [value]
      } else {
        const index = newRating.findIndex(item => item === value)
        if (index === -1) {
          newRating.push(value)
        }
      }
    }
    this.setState({ rating: newRating })
  }

  handleSubRatingChange = (value, isDelete) => {
    const { subRatings } = this.state
    let newSubRating = [...subRatings] || []
    if (isDelete) {
      const index = newSubRating.indexOf(value)
      if (index > -1) {
        newSubRating.splice(index, 1)
      }
    } else {
      if (!subRatings) {
        newSubRating = [value]
      } else {
        const index = newSubRating.findIndex(item => item === value)
        if (index === -1) {
          newSubRating.push(value)
        }
      }
    }
    this.setState({ subRatings: newSubRating })
  }

  render () {
    const { isLoading, parentalRatingName, rating, subRatings } = this.state
    const { isVisible, handleCancel, selectedRatingType, isSubmitDisabled } = this.props
    const disabled = selectedRatingType ? (selectedRatingType.scheme === parentalRatingName && _.isEqual(selectedRatingType.rating, rating) && _.isEqual(selectedRatingType.subRatings, subRatings) && (selectedRatingType.scheme && !_.isEmpty(rating) && !_.isEmpty(subRatings))) : !(parentalRatingName && !_.isEmpty(subRatings) && !_.isEmpty(rating))
    return (
      <Modal
        className='general-modal new-parental-rating'
        title={selectedRatingType ? 'EDIT PARENTAL RATING' : 'NEW PARENTAL RATING'}
        maskClosable={false}
        visible={isVisible}
        okText='Save'
        cancelText='Back'
        onOk={this.handleAddParentalRating}
        onCancel={handleCancel}
        okButtonProps={{ disabled: (disabled || isSubmitDisabled), loading: isLoading }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoading}
        afterClose={this.resetState}
      >
        <div className='new-parental-rating-container' id='new-parental-rating-container'>
          <Input
            title='Parental Rating Name'
            placeholder='Insert Parental Rating Name'
            inputName='parentalRatingName'
            value={parentalRatingName}
            handleChange={this.handleParentalRatingName}
            isRequired
          />
          <div className='input-field'>
            <label className='input-title'>Rating Values <span className={`mandatory`}>*</span></label>
            <TagsInput
              selectedItems={rating || []}
              tagList={[]}
              className={'rating-dropdown'}
              mode={'tags'}
              onChange={this.handleRatingChange}
              placeholder='Insert Rating Values'
              displayParameter='name'
              parentCompoent={parentCompoent}
            />
          </div>
          <div className='input-field'>
            <label className='input-title'>Sub Rating Values <span className={`mandatory`}>*</span></label>
            <TagsInput
              selectedItems={subRatings || []}
              tagList={[]}
              className={'sub-rating-dropdown'}
              mode={'tags'}
              onChange={this.handleSubRatingChange}
              placeholder='Insert Sub Rating Values'
              displayParameter='name'
              parentCompoent={parentCompoent}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

CreateParentRatingModal.propTypes = {

}

CreateParentRatingModal.defaultProps = {
}

export default withApollo(compose(

  graphql(
    MutationCreateRating,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { createRating } }) => {
            try {
              const variables = { limit: 20, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryRating, variables }))
              if (cacheData && cacheData.listRating && cacheData.listRating.items) {
                cacheData.listRating.items.unshift(createRating)
              }
              cache.writeQuery({
                query: QueryRating,
                data: cacheData,
                variables
              })
            } catch (e) { }
          }
        }
      },
      props: (props) => ({
        createRating: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateRating,
    {
      options: ({ listApi, project }) => {
        return {
          update: (cache, { data: { updateRating } }) => {
            if (!listApi) {
              const variables = { limit: 30, offset: 0, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryRating, variables }))
              const index = cacheData.listRating.items.findIndex(item => item.id === updateRating.id)
              if (index > -1 && updateRating.name) {
                cacheData.listRating.items[index].name = updateRating.name
              }
              // if (index > -1 && updateAssetCategory.slug) {
              //   cacheData.listAssetCategories.items[index].slug = updateAssetCategory.slug
              // }
              cache.writeQuery({
                query: QueryRating,
                data: cacheData,
                variables
              })
            } else {
              try {
                const newVariables = { offset: 0, limit: 20, project }
                const newCacheData = _.cloneDeep(cache.readQuery({ query: QueryRating, variables: newVariables }))
                const index = newCacheData.listRating.items.findIndex(item => item.id === updateRating.id)
                if (index > -1 && updateRating.name) {
                  newCacheData.listRating.items[index].name = updateRating.name
                }
                // if (index > -1 && updateAssetCategory.slug) {
                //   newCacheData.listAssetCategories.items[index].slug = updateAssetCategory.slug
                // }
                cache.writeQuery({
                  query: QueryRating,
                  data: newCacheData,
                  variables: newVariables
                })
              } catch (e) { }
            }
          }
        }
      },
      props: (props) => ({
        updateRating: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CreateParentRatingModal))
