import gql from 'graphql-tag'

export default gql(`
    subscription{
        bucketCreated{
            id
            name
            isArchived
            displayName
            key
            isPublished
            status
            publishStartDate
            publishEndDate
            updatedAt
            updatedBy
            project
            displayType{
                name
                id
            } 
        }
    }
`)
