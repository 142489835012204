import gql from 'graphql-tag'

export default gql(`
query listTickets( $userId: ID! ) {
    listTickets( 
        userId: $userId
    ) {
        id
        caseNumber
        caseCategory
        createdDate
        lastModifiedDate
        status
        subject
        description
        attachment {
            fileName
            contentType
            body
        }
        comments{
            id
            isCustomerComments
            createdBy
            createdDate
            comment
            attachment{
                fileName
                contentType
                body
            }
        }
    }
}`)
