import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

import Input from './../inputs/Input'
import TagsInputContainer from './../inputs/TagsInputContainer'
import DropDown from './../inputs/DropDown'
import TextAreaInput from './../../dataEntry/inputs/TextArea'
import MultipleDropDown from '../inputs/AppMutiDorpDown'
import _ from 'lodash'
import './../../ui.style.scss'

// const skippedTagsName = []

class EditAppPageDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTag: '',
      selectedIndex: undefined
    }
  }

    onTagSearch = (searchTag) => {
      this.setState({
        searchTag
        // selectedIndex: searchTag ? index : undefined
      })
    }

    render () {
      const { name, shortDescription, handleTextChange, selectedTags, appList, groupList, selectedApp, selectedGroup, handleAppChange, handleGroupChange, project,
        isFromEdit, appClient, onInputFieldBlur, onInputFieldFocus, handleShortDescriptionChange, pageMetaFields, selectedKey, handleIdChange, isDuplicating, isCritical, onChangePageStatus, details, appData, groupData, assignedUsers, userList, handleUserChange, isAppAdminDisabled, userSearch, handleUserSearch } = this.props
      const { searchTag } = this.state
      const isAppCritical = !_.isEmpty(appData) ? ((appData && appData.isCritical) || false) : ((details && details.app && details.app.isCritical) || false)
      const isGroupCritical = !_.isEmpty(groupData) ? ((groupData && groupData.isCritical) || false) : ((details && details.group && details.group.isCritical) || false)
      const isCreateAppCritical = (appData && appData.isCritical) || false
      const isCreateGroupCritical = (groupData && groupData.isCritical) || false
      return (
        <div className='edit-app-page-details'>
          <div className='edit-input-container'>

            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <Input title='Name' placeholder='Page Name' inputName='title' value={name} handleChange={handleTextChange} isRequired />
              </div>
              <div className='edit-input-item'>
                <Input title='Key' placeholder='Key' inputName='title' value={selectedKey} handleChange={handleIdChange} disabled={isFromEdit && !isDuplicating} isRequired />
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <div className='output-format' id='dropdown-app'>
                  <label className='title-text'>App<span className='mandatory'>*</span></label>
                  <DropDown options={appList} onOptionSelect={handleAppChange} selectedValue={selectedApp} valueParam='id' displayParam='name' placeHolder='Output format' parent={'dropdown-app'} disabled={isFromEdit && isDuplicating} />
                </div>
              </div>
              <div className='edit-input-item'>
                <TextAreaInput
                  title='Short Description'
                  placeholder='Write a short description...'
                  inputName='shortDescription'
                  onFocus={onInputFieldFocus}
                  onBlur={onInputFieldBlur}
                  value={shortDescription}
                  handleChange={handleShortDescriptionChange}
                />
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <div className='output-format' id='dropdown-group'>
                  <label className='title-text'>Group<span className='mandatory'>*</span></label>
                  <DropDown options={selectedApp ? groupList.filter(group => group.app.id === selectedApp) : []} onOptionSelect={handleGroupChange} selectedValue={selectedGroup} valueParam='key' displayParam='name' placeHolder='Output format' parent={'dropdown-group'} disabled={!selectedApp && !isDuplicating} />
                </div>
              </div>
              <div className='system-tags'>
                <div className='input-field'>
                  <label className='input-title'>{'Tags'}</label>
                  <TagsInputContainer
                    selectedItems={selectedTags.map(item => item.name)}
                    onChange={this.props.handleTagChange}
                    displayParameter='name'
                    placeholder='Insert Tags'
                    parentCompoent='edit-app-page-modal'
                    onSearch={this.onTagSearch}
                    searchTag={searchTag}
                    type={'MANUAL'}
                    isCustom
                    module={'APP_MANAGER'}
                    isDropdownInvisible
                    appClient={appClient}
                    project={project}
                  />
                </div>
              </div>
            </div>
            <div className='edit-input-inner-container-noflex'>
              <div className='edit-input-item-noflex'>
                <div className='output-format' id='dropdown-app'>
                  <label className='title-text'>Users</label>
                  <MultipleDropDown options={userList} onOptionSelect={handleUserChange} selectedValue={assignedUsers} valueParam='id' displayParam='id' placeHolder='Select Users' parent={'dropdown-app'} disabled={isAppAdminDisabled} onSearch={handleUserSearch} searchText={userSearch} />
                </div>
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='switch-inner-container'>
                <span>{'Critical'}</span>
                <Switch checked={((isAppCritical || isGroupCritical) ? true : isCritical)} onChange={onChangePageStatus} disabled={isAppCritical || isGroupCritical || isCreateAppCritical || isCreateGroupCritical} />
              </div>
            </div>
          </div>
          {pageMetaFields}
        </div>
      )
    }
}

EditAppPageDetails.propTypes = {
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /**  */
  handleFormatChange: PropTypes.func,
  /**  */
  handleTextChange: PropTypes.func,
  /**  */
  formats: PropTypes.array,
  /**  */
  selectedFormat: PropTypes.string,
  /** List of category */
  categoryList: PropTypes.array,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** Selected category valu */
  selectedCategory: PropTypes.string
}

export default EditAppPageDetails
