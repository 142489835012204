import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import './../../ui.style.scss'

const Option = Select.Option

class ContentRatingDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedContentRating: props.selectedContentRating
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (this.props.selectedContentRating !== nextProps.selectedContentRating) {
      this.setState({
        selectedContentRating: nextProps.selectedContentRating
      })
    }
  }

      handleChange = (value) => {
        this.props.onContentRatingSelect(value, this.props.index)
      }

      render () {
        const { contentRatings, parentCompoent, isDisable } = this.props
        const { selectedContentRating } = this.state
        let optionComponents = (contentRatings || []).map((option, index) => (
          <Option key={option} value={option}> { option }
          </Option>
        ))
        return (

          <div className='content-rating-drop-down'>
            <Select
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              placeholder='Content Rating'
              onChange={this.handleChange}
              value={selectedContentRating}
              getPopupContainer={() => document.getElementById(parentCompoent)}
              disabled={isDisable}
            >

              { optionComponents }
            </Select>
          </div>
        )
      }
}
ContentRatingDropDown.propTypes = {
  /** Option selection action of Dropdown. */
  onContentRatingSelect: PropTypes.func,
  /** parentcomponent for position */
  parentCompoent: PropTypes.string
}

export default ContentRatingDropDown
