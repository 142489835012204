import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { message, Empty, Skeleton, Checkbox, Popconfirm } from 'antd'
import NProgress from 'nprogress'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { GlobalHotKeys } from 'react-hotkeys'
import AppContext from '../../AppContext'
import AssetListCell from './../../components/ui/dataDisplay/AssetListCell'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import MultipleSelectButton from './../../components/ui/general/buttons/MultipleSelectButton'
import PopoverButton from './../../components/ui/general/buttons/PopoverButton'
import BackArrowButton from './../../components/ui/general/buttons/BackArrowButton'
import AddButton from './../../components/ui/general/buttons/AddButton'
import ClearFilterButton from '../../components/ui/general/buttons/ClearFilterButton'
import SortInput from './../../components/ui/dataEntry/inputs/SortInput'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import IconDropDown from '../../components/ui/dataEntry/inputs/IconDropDown'
import userMessages from './../../constants/messages'
import AuthService from '../../services/AuthService'
import LoggerService from './../../services/LoggerService'
import { utilityService } from './../../services/UtilityService'
import { generateCroppedThumbnail } from './../../util/util'
import ExpandMenuIcon from './../../components/ui/general/icons/ExpandMenuIcon'
import CompetitionFilterIcon from '../../components/ui/general/icons/CompetitionFilterIcon'
import SeasonFilterIcon from '../../components/ui/general/icons/SeasonFilterIcon'
import TimeFilterIcon from '../../components/ui/general/icons/TimeFilterIcon'
import ExportCsvHyperionModal from '../../components/ui/dataEntry/other/ExportCsvHyperionModal'
import AssetTypeIcon from '../../components/ui/general/icons/AssetTypeIcon'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose, cloneDeep } from 'lodash'
import { InMemoryCache } from 'apollo-cache-inmemory'
import QueryFilterAssets from './../../graphQL/asset/searchAssets'
import MutationCreateArticle from './../../graphQL/asset/createAssetDetails'
import MutationBatchArchiveAssets from './../../graphQL/asset/batchArchiveAssets'
import QueryStoreData from './../../graphQL/getStore'
import QueryGetContent from './../../graphQL/filter/getContent'
import QueryGetFilter from './../../graphQL/filter/filterDataList'
import SubscriptionAssetList from './../../graphQL/asset/createAssetSubscription'
import SubscriptionAssetDetails from '../../graphQL/asset/listAssetUpdateSubscription'
import MutationExportAssetsCsv from '../../graphQL/asset/hyperion/exportAssetsCsv'

const keyMap = {
  shortcutNew: 'n',
  shortcutNext: 'j',
  shortcutPrev: 'k'
}

const archiveWarningMessage = {
  messageText: userMessages.ARCHIVE_ASSET_WARNING,
  title: 'ARCHIVE'
}

const width = ['calc(100% - 100px)', '50%', '100%']

const timeFilter = [
  {
    name: 'Yesterday',
    key: '1',
    day: '1'
  }, {
    name: 'Last 2 days',
    key: '2',
    day: '2'
  }, {
    name: 'Last 3 days',
    key: '3',
    day: '3'
  }, {
    name: 'Last 8 days',
    key: '4',
    day: '8'
  }
]

const subscribeChangeInAsset = (subscribeToMore, props, retrying = 50) => {
  return subscribeToMore({
    document: SubscriptionAssetDetails,
    updateQuery: (prev, { subscriptionData: { data: { assetUpdated } } }) => {
      try {
        if (!assetUpdated) return prev
        const index = prev.listAssets.items.findIndex(item => item.id === assetUpdated.id)
        if (index > -1) {
          const currentAsset = _.cloneDeep(prev.listAssets.items[index])
          if (currentAsset.status !== assetUpdated.status) {
            prev.listAssets.items[index].status = assetUpdated.status
          }
        }
        return prev
      } catch (error) {
        console.log(error)
      }
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeChangeInAsset(subscribeToMore, props, retrying * 2) }, retrying)
      }
    }
  })
}

const bulkAssetOptions = [
  {
    type: 'archive',
    name: 'Archive Assets'
  },
  {
    type: 'exportAsset',
    name: 'Export Assets CSV'
  }
]

class AssetList extends Component {
  constructor (props) {
    super(props)
    const urlFilter = window.location.search
    let filterData = urlFilter && urlFilter.split('?filter=').length > 1 ? JSON.parse(decodeURI((urlFilter.split('?filter=')[1]))) : (localStorage.getItem('filterData'))
    filterData = typeof (filterData) === 'string' ? JSON.parse(filterData) : filterData
    let state = {
      shouldShowCreate: false,
      assetList: [],
      isLoading: true,
      isArchival: false,
      isSelectAll: true,
      archiveWarning: false,
      isPaginating: false,
      selectedIds: [],
      filterSearch: '',
      isClearFilter: false,
      initialSearch: (!!props.match.params.id && !(filterData && filterData.length)),
      shouldShowTimeFilter: false,
      shouldShowCompetitionFilter: false,
      shouldShowAssetTypeFilter: false,
      shouldShowSeasonFilter: false,
      competitionList: [],
      filterData: filterData || [],
      isBulkAssetOptionVisible: false,
      exportCsvWarning: false,
      exportCsvLoading: false,
      archiveLoading: false
    }
    if (filterData) {
      localStorage.removeItem('filterData')
      localStorage.setItem('filterData', JSON.stringify(filterData))
      props.changeFilterValue(filterData)
      props.history.push('?filter=' + encodeURI(JSON.stringify(filterData)))
    }
    this.cache = new InMemoryCache({ addTypename: false })
    this.lastAppliedSort = {
      value: 'createdAt',
      order: 'desc'
    }
    this.getUserDetails()
    if (this.props.assetList && this.props.assetList.length) {
      this.props.changedAsset(this.props.assetList[0].id)
      if (!this.props.isLoading) {
        state.assetList = this.props.assetList
        state.isLoading = false
      }
    } else if (!this.props.isLoading) {
      state.isLoading = false
    }
    this.state = { ...state }
    if (props.match.params.id && !(filterData && filterData.length)) { props.onChangeFilter(props.match.params.id) }
  }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      let isArchivalFilterApplied = false
      if (!newProps.isLoading && (!this.lastSearched || this.lastSearched.searchString !== newProps.searchString || this.lastSearched.filter !== newProps.filterVal || this.lastSearched.sort !== newProps.sort)) {
        this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
        // this.replaceCacheSearchString(newProps.searchString)
        this.setState({ assetList: newProps.assetList, isSearching: false, isPaginating: false, selectedIds: [], isLoading: false })
      } else if (!newProps.isLoading && !_.isEqual(newProps.assetList, this.props.assetList)) {
        this.setState({ assetList: newProps.assetList, isSearching: false, isPaginating: false, isLoading: false })
      }
      if (newProps.assetList && newProps.assetList.length && !newProps.selectedAssetId) {
        this.props.changedAsset(newProps.assetList[0].id)
      }
      if (newProps.duplicatedAssetId !== this.props.duplicatedAssetId) {
        setTimeout(() => {
          this.props.changedAsset(newProps.duplicatedAssetId)
          this.setState({ isClearFilter: true }, () => {
            this.resetFilters()
            this.setState({ initialSearch: true, isClearFilter: false })
            this.onChangeFilter(newProps.duplicatedAssetId)
          })
        }, 500)
      }
      if (newProps.deletedIds !== this.props.deletedIds && newProps.deletedIds.length) {
        const { assetList } = this.state
        const index = newProps.deletedIds.indexOf(newProps.selectedAssetId)
        const isDeleteCompleted = assetList.findIndex(asset => asset.id === newProps.deletedIds[0]) === -1
        if (isDeleteCompleted && assetList.length) {
          this.props.changedAsset(assetList[0].id)
        } else if (index > -1) {
          this.setNewSelectedAsset(newProps)
        }
      }
      if (newProps.filterVal) {
        const filter = utilityService.getFormattedFilter(newProps.filterVal)
        if (filter && filter.isArchived && (filter.isArchived.match === true || filter.isArchived.match === 'true')) {
          isArchivalFilterApplied = true
        }
      }
      if (this.state.isArchivalFilterApplied !== isArchivalFilterApplied) {
        this.setState({ isArchivalFilterApplied })
      }
      if (this.state.isClearFilter !== newProps.shouldClearFilter) {
        this.setState({ isClearFilter: newProps.shouldClearFilter })
      }
      if (!_.isEqual(newProps.competitionList, this.props.competitionList) && newProps.competitionList.length) {
        this.setState({ competitionList: newProps.competitionList })
      }
    }

    componentDidMount = () => {
      this.props.subscribeToNewAsset()
      this.props.subscribeToChangeInAsset()
      this.setState({ assetList: this.props.assetList })
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    toggleTimeFilter = () => {
      this.setState({ shouldShowTimeFilter: !this.state.shouldShowTimeFilter,
        shouldShowCompetitionFilter: false,
        shouldShowAssetTypeFilter: false,
        shouldShowSeasonFilter: false })
    }

    toggleCompetitionFilter = () => {
      this.setState({ shouldShowCompetitionFilter: !this.state.shouldShowCompetitionFilter,
        shouldShowTimeFilter: false,
        shouldShowAssetTypeFilter: false,
        shouldShowSeasonFilter: false })
    }

    toggleAssetTypeFilter = () => {
      this.setState({ shouldShowAssetTypeFilter: !this.state.shouldShowAssetTypeFilter,
        shouldShowTimeFilter: false,
        shouldShowCompetitionFilter: false,
        shouldShowSeasonFilter: false })
    }

    toggleSeasonFilter = () => {
      this.setState({ shouldShowSeasonFilter: !this.state.shouldShowSeasonFilter,
        shouldShowTimeFilter: false,
        shouldShowCompetitionFilter: false,
        shouldShowAssetTypeFilter: false })
    }

    onTimeSelect = (selectedTime) => {
      if (selectedTime) {
        const selectedItem = timeFilter.find((item) => item.key === selectedTime)
        const lastDay = selectedItem.day
        const endDay = moment(new Date()).subtract(1, 'day').endOf('day').utc().format()
        const startDay = moment(new Date()).subtract(lastDay, 'day').startOf('day').utc().format()
        let dateString = startDay + ',' + endDay
        this.onChangingQuickFilter(dateString, 'matchDate')
      } else {
        this.onChangingQuickFilter(null, 'matchDate')
      }
    }

    onChangingQuickFilter = (value, key) => {
      let filterData = _.cloneDeep(this.state.filterData)
      const assetTypeFilterIndex = filterData.findIndex((item) => item.name === key)
      if (assetTypeFilterIndex < 0) {
        let filterList = _.cloneDeep(this.props.filterList)
        const index = filterList.findIndex((item) => item.name === key)
        filterList[index].value = value
        filterData.push(filterList[index])
        // const newFilter = filterList.filter((item) => item.isRequired || item.value)
        this.setState({ isSearching: true, filterData }, () => {
          this.changeFilterValue(filterData)
        })
      } else {
        filterData[assetTypeFilterIndex].value = value
        this.setState({ isSearching: true, filterData }, () => {
          this.changeFilterValue(filterData)
        })
      }
    }

    getQuickFilterData = (key) => {
      const { filterData } = this.state
      const selectedFilter = filterData.find((item) => item.name === key)
      return _.isEmpty(selectedFilter) ? [] : selectedFilter.value
    }

    getSelectedTime = () => {
      const { filterData } = this.state
      const selectedFilter = filterData.find((item) => item.name === 'matchDate')
      if (_.isEmpty(selectedFilter) || !selectedFilter.value) {
        return null
      } else {
        const currentStartDate = moment().startOf('day').utc()
        const currentEndDate = moment().endOf('day').utc()
        const dateArr = selectedFilter.value.split(',')
        const startDate = moment(dateArr[0])
        const endDate = moment(dateArr[1])
        const endDiff = currentEndDate.diff(endDate, 'day')
        const startDiff = currentStartDate.diff(startDate, 'day')
        const selectedValue = timeFilter.find((item) => parseInt(item.day) === startDiff)
        return _.isEmpty(selectedValue) ? null : endDiff === 1 ? selectedValue.key : null
      }
    }

    clearFilter = () => {
      let filterList = _.cloneDeep(this.props.filterList)
      const filterData = filterList.filter((item) => item.isRequired)
      this.setState({ isClearFilter: true, filterData }, () => {
        setTimeout(() => {
          this.changeFilterValue(filterData)
          this.setState({ isClearFilter: false })
        }, 200)
      })
    }

    newAssetKeyPressHandler = (e) => {
      if (e.keyCode === 78) {
        this.setState({ shouldShowCreate: true })
      }
    }

    replaceCacheSearchString = searchString => {
      let store = cloneDeep(this.cache.readQuery({ query: QueryStoreData, variables: {} }))
      store.assetSearchString = searchString
      this.cache.writeData({ data: store })
    }

    setNewSelectedAsset = newProps => {
      const { assetList } = this.state
      if (assetList.length > newProps.deletedIds.length) {
        let nextSelectedId
        let tempAssetList = [...assetList]
        let selectedId = newProps.selectedAssetId
        while (tempAssetList.length) {
          const tempIndex = tempAssetList.findIndex(asset => asset.id === selectedId)
          const id = tempAssetList[ tempIndex ? tempIndex - 1 : 1 ].id
          const nextIdPositionInDeletedIds = newProps.deletedIds.indexOf(id)
          if (nextIdPositionInDeletedIds === -1) {
            nextSelectedId = id
            break
          }
          tempAssetList = tempAssetList.filter(asset => asset.id !== selectedId)
          selectedId = id
        }
        if (tempAssetList.length && nextSelectedId) {
          setTimeout(() => this.props.changedAsset(nextSelectedId), 500)
        } else {
          setTimeout(() => this.props.history.push('/assets'), 2000)
        }
      } else {
        setTimeout(() => this.props.history.push('/assets'), 2000)
      }
    }

    getUserDetails = async () => {
      const userDetails = await AuthService.getUserDetails()
      if (userDetails) {
        this.username = userDetails.name || userDetails.email
      }
    }

    handleClickOutside = (event) => {
      if (this.state.shouldShowCreate) {
        setTimeout(() => this.setState({ shouldShowCreate: false }), 200)
      }
      if (!(this.filterContainer && this.filterContainer.contains(event.target))) {
        this.setState({ shouldShowTimeFilter: false,
          shouldShowCompetitionFilter: false,
          shouldShowAssetTypeFilter: false,
          shouldShowSeasonFilter: false })
      }
      if (this.state.isBulkAssetOptionVisible) {
        setTimeout(() => this.setState({ isBulkAssetOptionVisible: false }), 200)
      }
    }

    toggleShowCreate = () => {
      this.setState({ shouldShowCreate: !this.state.shouldShowCreate })
    }

    onAssetCreateSelection = (selectedType) => {
      const newAsset = {
        title: 'Untitled',
        type: selectedType.title,
        createdBy: this.username
      }
      if (this.state.isLoading) {
        return
      }
      NProgress.start()
      this.forceLoading = true
      this.setState({ isLoading: true, isClearFilter: true }, () => {
        this.props.createAsset(newAsset).then(response => {
          this.setState({ isLoading: false, isClearFilter: false, initialSearch: true })
          this.resetFilters()
          this.containerRef.scrollTop = 0
          LoggerService.info('flow', `Created asset ${response.data.createAsset.id}`)
          this.props.changedAsset(response.data.createAsset.id)
          setTimeout(() => {
            this.forceLoading = false
            this.onChangeFilter(response.data.createAsset.id)
          }, 2000)
          NProgress.done()
          message.success(userMessages.ASSET_CREATE_SUCCESS)
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      })
    }

    resetFilters = () => {
      const sortData = {
        value: 'createdAt',
        order: 'desc'
      }
      this.lastAppliedSort = sortData
      this.setState({ isSearching: true })
      this.props.onChangeFilter('')
      this.props.onChangeSort(sortData)
      this.changeFilterValue([])
    }

    onChangeFilter = (e) => {
      this.setState({ isSearching: true })
      const sortData = {
        value: 'createdAt',
        order: 'desc'
      }
      if (e && e.length) {
        this.props.onChangeSort(sortData)
      } else {
        this.props.onChangeSort(this.lastAppliedSort)
      }
      this.props.onChangeFilter(e)
      setTimeout(() => {
        this.setState({
          initialSearch: false
        })
      }, 100)
    }

    onSortChange = (sortData) => {
      this.setState({ isSearching: true })
      this.lastAppliedSort = sortData
      this.props.onChangeSort(sortData)
    }

    onHandleArchival = () => {
      this.setState({ isArchival: true })
      this.props.archiveButtonHandle(true)
    }

    toggleWarningMessage=(status) => {
      this.setState({ archiveWarning: status })
    }

    onHandleArchiveButton = () => {
      this.toggleWarningMessage(true)
    }

    onArchiveSubmit = () => {
      NProgress.start()
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      this.setState({
        archiveLoading: true
      })
      const { selectedIds } = this.state
      LoggerService.info('flow', `Archived assets ${selectedIds.join(', ')}`)
      this.props.batchArchiveAssets(selectedIds).then(({ data }) => {
        NProgress.done()
        this.isLoading = false
        this.props.onDeleteAsset(selectedIds)
        this.toggleWarningMessage(false)
        this.setState({
          archiveLoading: false
        })
        if (this.state.assetList.length < 10) {
          this.loadMoreAsset()
        }
        this.setState({ selectedIds: [] })
      }, error => {
        this.isLoading = false
        this.toggleWarningMessage(false)
        utilityService.handleError(error)
      })
    }

    onHandleBack =() => {
      this.setState({ isArchival: false, selectedIds: [] })
      this.props.archiveButtonHandle(false)
    }

    onCheckAllChange=(e) => {
      const { assetList } = this.state
      let selectedIds
      if (this.state.selectedIds.length !== assetList.length) {
        selectedIds = assetList.map(asset => asset.id)
      } else {
        selectedIds = []
      }
      this.setState({ selectedIds })
    }

    onItemCheck=(id) => {
      let { assetList, selectedIds } = this.state
      const index = assetList.findIndex(asset => asset.id === id)
      if (index > -1) {
        const selectedIndex = selectedIds.indexOf(id)
        if (selectedIndex > -1) {
          selectedIds.splice(selectedIndex, 1)
        } else {
          selectedIds.push(id)
        }
        this.setState({ selectedIds })
      }
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.assetList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getAssetList(this.state.assetList.length))
    }

    keyHandlers = {
      shortcutNew: () => {
        this.setState({
          shouldShowCreate: !this.state.shouldShowCreate
        })
      },
      shortcutNext: () => {
        let { assetList } = this.state
        const index = assetList.findIndex(asset => asset.id === this.props.selectedAssetId)
        if (assetList.length > index + 1) {
          this.props.changedAsset(assetList[index + 1].id)
        }
      },
      shortcutPrev: () => {
        let { assetList } = this.state
        const index = assetList.findIndex(asset => asset.id === this.props.selectedAssetId)
        if ((index > 0)) {
          this.props.changedAsset(assetList[index - 1].id)
        }
      }
    }

    changeFilterValue = (value) => {
      this.props.changeFilterValue(value)
      localStorage.removeItem('filterData')
      const urlValue = value.filter((item) => item.value || item.value !== null).map((item) => {
        delete (item.__typename)
        return item
      })
      if (urlValue && urlValue.length) {
        const filter = this.encodeFilter(urlValue)
        localStorage.setItem('filterData', filter)
        // this.props.history.push('?filter=' + JSON.stringify(urlValue))
        this.props.history.push('?filter=' + encodeURI(filter))
      } else {
        this.props.history.push('?')
      }
    }

    encodeFilter = (filter) => {
      let value = cloneDeep(filter).map(item => {
        let itemValue = item.value
        if (typeof (item.value) === 'object') {
          itemValue = (item.value).map((innerItem) => {
            delete (innerItem.__typename)
            return innerItem
          })
        }
        return {
          name: item.name,
          type: item.type,
          value: itemValue
        }
      })
      return JSON.stringify(value)
    }

    toggleBulkAssetShowOptions = () => {
      const { selectedIds } = this.state
      if (!selectedIds.length) return
      this.setState({ isBulkAssetOptionVisible: !this.state.isBulkAssetOptionVisible })
    }

    onSelectBulkAssetOption = (selectedOption) => {
      if (selectedOption.type === 'archive') {
        this.onHandleArchiveButton()
        this.setState({
          isBulkAssetOptionVisible: false
        })
      } else {
        this.onHandleExportCsv()
        this.setState({
          isBulkAssetOptionVisible: false
        })
      }
    }

    onHandleExportCsv = () => {
      this.toggleExportCsvWarningMessage(true)
    }

    toggleExportCsvWarningMessage=(status) => {
      this.setState({ exportCsvWarning: status })
    }

    onExportCsvSubmit = () => {
      NProgress.start()
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      this.setState({
        exportCsvLoading: true
      })
      const { selectedIds } = this.state
      LoggerService.info('flow', `Export CSV assets ${selectedIds.join(', ')}`)
      this.props.exportAssetsCsv(selectedIds).then(({ data }) => {
        NProgress.done()
        this.isLoading = false
        this.setState({
          exportCsvLoading: false
        })
        this.toggleExportCsvWarningMessage(false)
        message.success('CSV file with requested data will be emailed to you once generated.')
        if (this.state.assetList.length < 10) {
          this.loadMoreAsset()
        }
        this.setState({ selectedIds: [] })
      }, error => {
        this.isLoading = false
        this.setState({
          exportCsvLoading: false,
          selectedIds: []
        })
        this.toggleExportCsvWarningMessage(false)
        utilityService.handleError(error)
      })
    }

    render () {
      const { shouldShowCreate, isLoading, assetList, isSearching, isArchival, archiveWarning, isPaginating, selectedIds,
        isArchivalFilterApplied, isClearFilter, initialSearch, shouldShowTimeFilter, shouldShowCompetitionFilter, shouldShowSeasonFilter,
        shouldShowAssetTypeFilter, competitionList, filterData, isBulkAssetOptionVisible, exportCsvWarning, exportCsvLoading, archiveLoading } = this.state
      const { changedAsset, selectedAssetId, sort, searchString, assetType, season, listAssetTypes, project, uploadingImage } = this.props
      const { messageText, title } = archiveWarningMessage

      const selectedTime = this.getSelectedTime()
      const selectedCompetition = this.getQuickFilterData('competition')
      const selectedSeason = this.getQuickFilterData('seasonName')
      const selectedAssetType = this.getQuickFilterData('type')
      const isFilterEnabled = (filterData || []).filter((item) => item.value).length > 0
      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['ASSET_MANAGER']
            const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='asset-list' id='asset-list'>
              <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
              <div className='filter'>
                <FilterInput
                  searchString={searchString}
                  onChangeSearchInput={this.onChangeFilter}
                  filterType={'AssetManager'}
                  changeFilter={(value) => {
                    this.setState({ isSearching: true, filterData: value }, () => {
                      this.changeFilterValue(value)
                    })
                  }}
                  placement='rightTop'
                  isClearFilter={isClearFilter}
                  initialSearch={initialSearch}
                  username={this.username}
                  bucketAssetFilter={filterData}
                  project={project}
                />
                <Popconfirm
                  disabled={!isFilterEnabled}
                  className='pop-over-confirm'
                  placement='rightTop'
                  title={'Do you want to clear filter ?'}
                  onConfirm={this.clearFilter}
                  okText='Yes'
                  cancelText='No'
                  getPopupContainer={() => document.getElementById('asset-list')}>
                  { <ClearFilterButton className={`${isFilterEnabled ? ' active' : ''}`} onClick={() => {}} />}
                </Popconfirm>
              </div>
              <div className='sort-filter-container' id='sort-filter-container'>
                <SortInput type='AssetManager' selectedOrder={sort.order} selectedSort={sort.value} onSortChange={this.onSortChange} parent='sort-filter-container' project={project} />
                <div className='asset-quick-filter' id='asset-quick-filter' ref={node => { this.filterContainer = node }}>
                  <IconDropDown
                    icon={<AssetTypeIcon onClick={this.toggleAssetTypeFilter} color={selectedAssetType && selectedAssetType.length ? '#FF015A' : '#343A40'} />}
                    displayParam='name'
                    contents={assetType || []}
                    onContentClick={(value) => this.onChangingQuickFilter(value, 'type')}
                    parentCompoent={'asset-quick-filter'}
                    isVisible={shouldShowAssetTypeFilter}
                    selectedOption={selectedAssetType || []}
                    keyParam='key'
                    multiSelect
                  />
                  {project === 'hyperion' || 'projectx' ? '' : <IconDropDown
                    icon={<TimeFilterIcon onClick={this.toggleTimeFilter} color={selectedTime ? '#FF015A' : '#343A40'} />}
                    displayParam='name'
                    contents={timeFilter}
                    onContentClick={this.onTimeSelect}
                    parentCompoent={'asset-quick-filter'}
                    isVisible={shouldShowTimeFilter}
                    selectedOption={selectedTime || []}
                    multiSelect={false}
                    keyParam='key'
                  />}
                  {project === 'hyperion' || 'projectx' ? '' : <IconDropDown
                    icon={<CompetitionFilterIcon onClick={this.toggleCompetitionFilter} color={selectedCompetition && selectedCompetition.length ? '#FF015A' : '#343A40'} />}
                    displayParam='name'
                    contents={competitionList || []}
                    onContentClick={(value) => this.onChangingQuickFilter(value, 'competition')}
                    parentCompoent={'asset-quick-filter'}
                    isVisible={shouldShowCompetitionFilter}
                    selectedOption={selectedCompetition || []}
                    multiSelect
                    keyParam='key'
                  />}
                  {project === 'hyperion' || 'projectx' ? '' : <IconDropDown
                    icon={<SeasonFilterIcon onClick={this.toggleSeasonFilter} color={selectedSeason && selectedSeason.length ? '#FF015A' : '#343A40'} />}
                    displayParam='name'
                    contents={season || []}
                    onContentClick={(value) => this.onChangingQuickFilter(value, 'seasonName')}
                    parentCompoent={'asset-quick-filter'}
                    isVisible={shouldShowSeasonFilter}
                    selectedOption={selectedSeason || []}
                    multiSelect
                    keyParam='key'
                  />}
                </div>
              </div>
              <div className='table' ref={(node) => { this.containerRef = node }}>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={this.forceLoading || (isLoading && !isSearching && !isPaginating)} />
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={isSearching && !this.forceLoading}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMoreAsset}
                    hasMore={this.props.totalCount > assetList.length}
                    initialLoad={false}
                    useWindow={false}
                  >
                    { !this.forceLoading && assetList && assetList.length ? assetList.map(asset => {
                      let imageUrl
                      if (asset.defaultMedia) {
                        imageUrl = generateCroppedThumbnail(asset.defaultMedia, 87, 48, '16:9')
                      }
                      return (<AssetListCell
                        key={asset.id}
                        assetType={asset.type}
                        imageUrl={imageUrl}
                        status={asset.status}
                        date={moment(new Date(asset.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                        assetName={asset.title}
                        assetId={asset.id}
                        displayId={asset.shortId || asset.id}
                        onSelect={changedAsset}
                        isSelected={selectedAssetId === asset.id}
                        isArchival={isArchival}
                        isChecked={selectedIds.indexOf(asset.id) > -1}
                        onCheck={this.onItemCheck}
                        isFree={asset.isFree}
                        isFreeButtonEnable
                        isUpdateDisabled={isUpdateDisabled}
                        project={project}
                        disableOnSelect={uploadingImage > 0}
                        asset={asset}
                      />)
                    }) : (!isLoading && !isPaginating && !this.forceLoading) ? <Empty /> : <div /> }
                  </InfiniteScroll>
                </Skeleton>
                <Skeleton active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ rows: 3, width: width }} loading={!this.forceLoading && isPaginating && this.props.totalCount > assetList.length} />

              </div>

              <div className='asset-list-footer' id={'asset-list-footer'}>
                { !isArchival
                  ? <React.Fragment>
                    <ButtonContainer displayTitle='Mutiple Archive' childComponent={<MultipleSelectButton onClick={this.onHandleArchival} isDisabled={!assetList.length || isArchivalFilterApplied || userPermissions.indexOf('UPDATE') === -1} />} />
                    <PopoverButton
                      button={<ButtonContainer displayTitle='Create Asset' childComponent={<AddButton onClick={this.toggleShowCreate} isDisabled={userPermissions.indexOf('CREATE') === -1} />} />}
                      displayParam='title'
                      contents={(listAssetTypes || []).filter(item => item.isActive)}
                      onContentClick={this.onAssetCreateSelection}
                      parentCompoent={'asset-list-footer'}
                      isVisible={shouldShowCreate}
                    />
                  </React.Fragment>
                  : <React.Fragment>
                    <Checkbox onChange={this.onCheckAllChange} checked={selectedIds.length && selectedIds.length === assetList.length} disabled={!assetList.length || isArchivalFilterApplied} />
                    <div className='archieve-bottom'>
                      <ButtonContainer displayTitle='Back' childComponent={<BackArrowButton onClick={this.onHandleBack} />} />
                      <div className={!selectedIds.length || isArchivalFilterApplied || userPermissions.indexOf('UPDATE') === -1 ? 'asset-bulk-option-list-disable' : 'asset-bulk-option-list'} id='asset-bulk-option-list'>
                        <PopoverButton
                          button={<div onClick={(e) => this.toggleBulkAssetShowOptions()} > <ExpandMenuIcon /> </div>}
                          displayParam='name'
                          contents={bulkAssetOptions}
                          onContentClick={(selectedOption) => this.onSelectBulkAssetOption(selectedOption)}
                          parentCompoent={'asset-bulk-option-list'}
                          isVisible={isBulkAssetOptionVisible}
                          placement={'leftBottom'}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
                <ConfirmModal isVisible={archiveWarning} title={title} message={messageText} rightButtonText={'Archive'} handleCancel={(e) => this.toggleWarningMessage(false, e)} handleSubmit={this.onArchiveSubmit} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} isLoading={archiveLoading} />
                <ExportCsvHyperionModal isVisible={exportCsvWarning} handleCancel={(e) => this.toggleExportCsvWarningMessage(false, e)} handleSubmit={this.onExportCsvSubmit} assetList={assetList} selectedIds={selectedIds} exportCsvLoading={exportCsvLoading} />
              </div>

            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

AssetList.propTypes = {
  /** selected asset id of AssetManager. */
  isLoading: PropTypes.bool,
  /** selected asset id of AssetManager. */
  selectedAssetId: PropTypes.string,
  /** deleted asset id of AssetManager. */
  deletedId: PropTypes.string,
  /** asset change action of AssetManager. */
  changedAsset: PropTypes.func.isRequired,
  /** asset list filter change action of AssetManager. */
  onChangeFilter: PropTypes.func.isRequired,
  /** asset creation mutation of AssetManager. */
  createAsset: PropTypes.func.isRequired,
  /* list of assets given by apollo or AssetManager */
  assetList: PropTypes.array
}

export default withApollo(compose(
  graphql(
    QueryFilterAssets,
    {
      options: (props) => {
        const { searchString, filterVal, sort, project } = props
        const filter = utilityService.getFormattedFilter(filterVal)
        const variables = utilityService.getFormattedAssetFilter(searchString, filter, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const assetList = data.listAssets ? data.listAssets.items : []
        return {
          assetList,
          isLoading: data.loading || !data.listAssets,
          totalCount: data.listAssets ? data.listAssets.totalCount : 0,
          getAssetList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const previous = cloneDeep(prev)
                const newList = [ ...previous.listAssets.items, ...fetchMoreResult.listAssets.items ]
                previous.listAssets.items = newList
                return previous
              }
            })
          },
          subscribeToNewAsset: () => {
            return props.data.subscribeToMore({
              document: SubscriptionAssetList,
              updateQuery: (prev, { subscriptionData: { data: { assetCreated } } }) => {
                try {
                  const { project } = props.ownProps
                  if (!assetCreated ||
                        (project === 'hyperion' && (assetCreated.id.startsWith('os') || assetCreated.id.startsWith('s'))) ||
                        (project === 'vcms' && (assetCreated.id.startsWith('h') || assetCreated.id.startsWith('s'))) ||
                        (project === 'projectx' && (assetCreated.id.startsWith('h') || assetCreated.id.startsWith('os')))
                  ) return prev
                  const index = prev.listAssets.items.findIndex(asset => asset.id === assetCreated.id)
                  if (index !== -1) {
                    prev.listAssets.items.splice(index, 1)
                  }
                  const newList = [ assetCreated, ...prev.listAssets.items ]
                  prev.listAssets.items = newList
                  prev.listAssets.totalCount = prev.listAssets.totalCount + 1
                  return prev
                } catch (error) {
                }
              }
            })
          },
          subscribeToChangeInAsset: () => subscribeChangeInAsset(props.data.subscribeToMore, props)
        }
      }
    }
  ),
  graphql(
    MutationCreateArticle,
    {
      props: (props) => ({
        createAsset: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationBatchArchiveAssets,
    {
      options: (props) => ({
        update: (cache, { data: { batchArchiveAssets } }) => {
          const { searchString, filterVal, sort, project } = props
          const filter = utilityService.getFormattedFilter(filterVal)
          const variables = utilityService.getFormattedAssetFilter(searchString, filter, sort, project)
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterAssets, variables }))
          if (listCacheData && listCacheData.listAssets && listCacheData.listAssets.items) {
            const totalCount = listCacheData.listAssets.items.length
            listCacheData.listAssets.items = listCacheData.listAssets.items.filter(asset => batchArchiveAssets.indexOf(asset.id) === -1)
            listCacheData.listAssets.totalCount = listCacheData.listAssets.totalCount - (totalCount - listCacheData.listAssets.items.length)
          }
          cache.writeQuery({
            query: QueryFilterAssets,
            data: listCacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        batchArchiveAssets: (ids) => {
          return props.mutate({
            variables: { ids, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    QueryGetContent,
    {
      options: ({ project }) => {
        return {
          variables: { field: 'SEASON', limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          season: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  ),
  graphql(
    QueryGetContent,
    {
      options: ({ project }) => {
        return {
          variables: { field: 'TYPES', limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          assetType: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  ),

  graphql(
    QueryGetContent,
    {
      options: ({ project }) => {
        return {
          variables: { field: 'COMPETITION', limit: 30, offset: 0, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          competitionList: props.data && props.data.getContentByField && props.data.getContentByField.items && props.data.getContentByField.items.length ? props.data.getContentByField.items : []
        }
      }
    }
  ),
  graphql(
    QueryGetFilter,
    {
      options: ({ project }) => {
        return {
          variables: { type: 'AssetManager', project }
        }
      },
      props: (props) => {
        return {
          filterList: props.data && props.data.filterDataList && props.data.filterDataList.length ? props.data.filterDataList[0].fields : []
        }
      }
    }
  ),
  graphql(
    MutationExportAssetsCsv,
    {
      props: (props) => ({
        exportAssetsCsv: (assets) => {
          return props.mutate({
            variables: { assets, project: props.ownProps.project }
          })
        }
      })
    }
  )

)(withRouter(AssetList)))
