import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select } from 'antd'
import './../../ui.style.scss'

class MultiInput extends React.Component {
  constructor (props) {
    super(props)
    const { value } = this.props
    this.state = {
      selectedItems: props.selectedItems && props.selectedItems.length ? props.selectedItems : [],
      autoFocus: false,
      searchVal: '',
      metaOptions: value || []
    }
  }
    onSearch = (value) => {
      this.lastSearchedValue = value
      this.setState({ searchVal: value })
    }

    onChangeTag = (value) => {
      console.log('value', value)
      this.setState({ metaOptions: value, searchVal: '' })
      this.props.handleChange(value, this.props.index)
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      if (!_.isEqual(nextProps.type, this.props.type)) {
        this.setState({ metaOptions: [] })
      }
      if (nextProps.value.length === 0) {
        this.setState({ metaOptions: [] })
      }
    }

    render () {
      const {
        placeholder,
        parentCompoent,
        className,
        disabled,
        mode
      } = this.props
      const { selectedItems, searchVal, metaOptions } = this.state
      const isNotPresent = _.isEmpty((metaOptions || []).find(tag => tag === searchVal))

      return (
        <Select
          key={JSON.stringify(selectedItems)}
          className={`input-tags`}
          mode={isNotPresent ? mode : 'multiple'}
          dropdownClassName={`${className || ''}${(searchVal && isNotPresent) ? ' hide-manual-tag-first' : ''}`}
          value={metaOptions || undefined}
          placeholder={placeholder}
          onSearch={this.onSearch}
          onChange={this.onChangeTag}
          getPopupContainer={() => document.getElementById(parentCompoent)}
          filterOption={false}
          disabled={disabled}
        />
      )
    }
}

MultiInput.propTypes = {
  placeholder: PropTypes.string
}

MultiInput.defaultProps = {
  selectedItems: [],
  placeholder: 'Select',
  parentCompoent: 'root',
  mode: 'tags'
}

export default MultiInput
