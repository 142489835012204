import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Skeleton, message } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import EditOptaSetting from '../../dataDisplay/EditOptaSetting'
import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

import MutationUpdateOptaSyncState from '../../../../graphQL/admin/updateOptaSyncState'
import MutationCreateOptaSyncState from '../../../../graphQL/admin/createOptaSyncState'
import QueryOptaSyncStates from '../../../../graphQL/admin/listOptaSyncStates'

class EditOptaSettingModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true,
      customDetails: {}
    }
  }

  resetState = () => {
    const state = {
      customDetails: {},
      disable: true
    }
    this.setState({ ...state })
  }

  onEditSyncState = (details) => {
    this.setState({ customDetails: details })
  }

  onSyncStateChange = () => {
    const { isAdd, onHistoryUpdate } = this.props
    const { customDetails } = this.state
    this.setState({ isLoading: true })
    const newDetails = {
      id: customDetails.id,
      title: customDetails.title,
      lastRunTime: customDetails.lastRunTime,
      frequency: customDetails.frequency,
      isActive: customDetails.isActive,
      opId: customDetails.opId,
      isError: customDetails.isError,
      isRunning: customDetails.isRunning
    }
    if (isAdd) {
      this.props.createOptaSyncState(newDetails).then(response => {
        this.props.handleCancel()
        onHistoryUpdate()
        this.setState({ isLoading: false })
      // }, error => {
      //   utilityService.handleError(error)
      }, (error) => {
        const { graphQLErrors } = error
        if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
          message.error('Competition with same OpId already exist')
        } else {
          utilityService.handleError(error)
        }
        this.props.handleCancel()
        this.setState({ isLoading: false })
      })
    } else {
      this.props.updateOptaSyncState(newDetails).then(response => {
        this.props.handleCancel()
        onHistoryUpdate()
        this.setState({ isLoading: false })
      }, error => {
        utilityService.handleError(error)
        this.props.handleCancel()
        this.setState({ isLoading: false })
      })
    }
  }

  render () {
    const { visible, handleCancel, title, okText, selectedSyncState, isOptaSyncDataLoading, isAdd, isSubmitDisabled } = this.props
    const { isLoading, customDetails } = this.state
    return (

      <Modal
        className='general-modal edit-opta-setting-modal'
        visible={visible}
        closable={false}
        title={title}
        okButtonProps={{ disabled: _.isEmpty(customDetails.title && customDetails.opId) || isSubmitDisabled, loading: isLoading }}
        onOk={this.onSyncStateChange}
        onCancel={handleCancel}
        okText={okText}
        cancelText='Cancel'
        afterClose={this.resetState}
        width='890px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <Skeleton active avatar={false} paragraph={{ rows: 6 }}
          loading={isOptaSyncDataLoading}>
          <EditOptaSetting selectedSyncState={selectedSyncState} onEditSyncState={this.onEditSyncState} isAdd={isAdd} />
        </Skeleton>
      </Modal>
    )
  }
}

EditOptaSettingModal.propTypes = {
  /** visible action of EditOptaSettingModal. */
  visible: PropTypes.bool,
  /** handleCancel action of EditOptaSettingModal. */
  handleCancel: PropTypes.func
}

EditOptaSettingModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationUpdateOptaSyncState,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateOptaSyncState: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateOptaSyncState,
    {
      options: () => {
        return {
          update: (cache, { data: { createOptaSyncState } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryOptaSyncStates, variables: { offset: 0 } }))
            if (cacheData && cacheData.listOptaSyncStates && cacheData.listOptaSyncStates.items) {
              cacheData.listOptaSyncStates.items.push(createOptaSyncState)
            }
            cache.writeQuery({
              query: QueryOptaSyncStates,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        createOptaSyncState: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(EditOptaSettingModal))
