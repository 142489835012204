import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Tabs } from 'antd'

import RoleUserDetails from './RoleUserDetails'
import RoleUserGroupDetails from './RoleUserGroupDetails'
import RoleHistory from './RoleHistory'
import RoleAuthLogs from './RoleAuthLogs'

import './RoleManager.scss'
import { utilityService } from '../../services/UtilityService'

const { TabPane } = Tabs
class RoleManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userSearch: '',
      userSearchGroup: '',
      selectedUserGroup: undefined,
      selectedUser: undefined,
      status: '',
      enabled: null,
      accessRoleGroups: null,
      searchString: null,
      filterData: null,
      activeKey: '2'
    }
  }
  componentDidMount = () => {
    document.title = 'Role Manager - Optus CMS'
  }

  onChangeSearchInput = (userSearch) => {
    this.setState({ userSearch })
  }

  onChangeUserSearch = (userSearchGroup) => {
    this.setState({ userSearchGroup })
  }

  onSelectUserGroup = (selectedUserGroup) => {
    this.setState({ selectedUserGroup })
  }

  onSelectUser = (selectedUser) => {
    this.setState({ selectedUser })
  }

  clearState = () => {
    this.setState({
      userSearch: '',
      userSearchGroup: '',
      selectedUserGroup: undefined,
      selectedUser: undefined
    })
  }

  onTabClick = (e) => {
    this.setState({ activeKey: e })
  }

  onFilterChange = (filter) => {
    const filterData = utilityService.getFormattedIngestFilter(filter)
    const { accessRoleGroup = null, userStatus = null } = filterData
    this.setState({ accessRoleGroups: accessRoleGroup })
    if (userStatus) {
      if (userStatus.includes('CONFIRMED')) {
        this.setState({ status: 'CONFIRMED', enabled: true })
      } else if (userStatus.includes('FORCE_CHANGE_PASSWORD')) {
        this.setState({ status: 'FORCE_CHANGE_PASSWORD', enabled: null })
      } else if (userStatus.includes('SUSPENDED')) {
        this.setState({ enabled: false, status: null })
      } else {
        this.setState({ enabled: null, status: null })
      }
    } else {
      this.setState({ enabled: null, status: null })
    }
    this.setState({ filterData: filter })
  };

  onSearchChange = value => {
    this.setState({ searchString: value })
  }

  onUserGroupClick = (group, id) => {
    const filter = [
      {
        displayName: 'User Group',
        fieldName: 'ACCESS_ROLE_GROUP',
        isRequired: true,
        name: 'accessRoleGroup',
        type: 'KEYWORD',
        value: [
          {
            key: id,
            label: null,
            labelType: null,
            name: group
          }
        ]
      }
    ]
    this.setState({ filterData: filter, accessRoleGroups: [id], activeKey: '2' })
  }

  render () {
    const { userSearch, userSearchGroup, selectedUserGroup, selectedUser, status, accessRoleGroups, enabled, searchString, filterData, activeKey } = this.state
    const { project } = this.props

    return (
      <div className='role-manager' >
        <div className='role-details' >
          <Tabs destroyInactiveTabPane onChange={this.clearState} onTabClick={this.onTabClick} activeKey={activeKey}>
            <TabPane tab='Users' key='2'>
              <RoleUserDetails userSearch={userSearch} onChangeSearchInput={this.onChangeSearchInput}
                onSearchChange={this.onSearchChange} onFilterChange={this.onFilterChange} project={project} filterData={filterData}
                status={status} accessRoleGroups={accessRoleGroups} enabled={enabled} searchString={searchString} />
            </TabPane>

            <TabPane tab='User Groups' key='1'>
              <RoleUserGroupDetails userSearch={userSearchGroup} onUserGroupClick={this.onUserGroupClick} onSearchUser={this.onChangeUserSearch} project={project} />
            </TabPane>

            <TabPane tab='History' key='3'>
              <RoleHistory content='ROLE_MANAGER' showFilter selectedModule={'ROLE_MANAGER'} isChanging={false} project={project}
                selectedUserGroup={selectedUserGroup} selectedUser={selectedUser} onSelectUserGroup={this.onSelectUserGroup} onSelectUser={this.onSelectUser} />
            </TabPane>
            <TabPane tab='Auth Logs' key='4'>
              <RoleAuthLogs content='ROLE_MANAGER' showFilter selectedModule={'ROLE_MANAGER'} isChanging={false} project={project}
                selectedUserGroup={selectedUserGroup} selectedUser={selectedUser} onSelectUserGroup={this.onSelectUserGroup} onSelectUser={this.onSelectUser} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}

RoleManager.propTypes = {
}

export default withRouter(RoleManager)
