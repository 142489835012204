import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import RatingFieldComponent from '../../dataDisplay/RatingFieldComponent'
import LoadingButton from '../../general/buttons/LoadingButton'
import { utilityService } from '../../../../services/UtilityService'

import './../../ui.style.scss'

class CreateRatingComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ratingData: props.ratings,
      schemas: [],
      ratings: [],
      contentRatings: [],
      schemaSearch: undefined,
      ratingSearch: undefined
    }
  }

    UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
      if (this.props.ratings !== newProps.ratings) {
        this.setState({
          ratingData: newProps.ratings ? _.sortBy(newProps.ratings, 'position') : []
        })
      }
    }

    componentDidMount = () => {
      const { ratingData } = this.state
      const { listRatingDetails } = this.props
      const schemas = (listRatingDetails || []).filter(item => {
        const index = (ratingData || []).findIndex(inner => inner.id === item.id)
        return index === -1
      }).map(item => {
        return {
          id: item.id,
          name: item.scheme,
          source: item.source
        }
      })
      this.setState({
        schemas
      })
    }

    onSchemaSearch = (value) => {
      this.setState({
        schemaSearch: value
      })
    }

    onRatingSearch = (value) => {
      this.setState({
        ratingSearch: value
      })
    }

    getRatingData = (selectedSchema, index) => {
      if (!selectedSchema) {
        const { ratingData } = this.state
        ratingData[index].scheme = ''
        ratingData[index].rating = ''
        ratingData[index].subRatings = []
        this.props.handleRatingChange(ratingData)
        this.setState({ ratings: [], contentRatings: [] })
      } else {
        const { ratingData } = this.state
        ratingData[index].scheme = ''
        ratingData[index].rating = ''
        ratingData[index].subRatings = []
        this.props.handleRatingChange(ratingData)
        const data = this.props.listRatingDetails.filter(item => item.id === selectedSchema.id)

        this.setState({
          ratings: data && data[0].rating,
          contentRatings: data && data[0].subRatings
        })
      }
    }

    onSchemaSelect = (selectedSchema, index) => {
      this.getRatingData(selectedSchema, index)
      if (selectedSchema) {
        const { ratingData } = this.state
        if (!ratingData[index].id || ratingData[index].id.length === 6) {
          const { listRatingDetails } = this.props
          const curItem = (listRatingDetails || []).find(inner => inner.id === selectedSchema.id)
          if (!_.isEmpty(curItem)) {
            ratingData[index].id = curItem.id
          }
        }
        ratingData[index].scheme = selectedSchema.name
        this.props.handleRatingChange(ratingData)
        const { listRatingDetails } = this.props
        const schemas = (listRatingDetails || []).map(item => {
          return {
            id: item.id,
            name: item.scheme,
            source: item.source
          }
        })
        const currentSchemaList = (ratingData || []).map(item => {
          return {
            id: item.id,
            name: item.scheme,
            source: item.source
          }
        })
        const uniqueSchemasList = (schemas || []).filter(item => {
          const index = (currentSchemaList || []).findIndex(inner => inner.id === item.id)
          return index === -1
        })
        this.setState({
          schemas: uniqueSchemasList
        })
      } else {
        const { ratingData } = this.state
        ratingData[index].scheme = ''
        ratingData[index].id = utilityService.makeRandomString(6)
        this.props.handleRatingChange(ratingData)
        const { listRatingDetails } = this.props
        const schemas = (listRatingDetails || []).map(item => {
          return {
            id: item.id,
            name: item.scheme,
            source: item.source
          }
        })
        const currentSchemaList = (ratingData || []).map(item => {
          return {
            id: item.id,
            name: item.scheme,
            source: item.source
          }
        })
        const uniqueSchemasList = (schemas || []).filter(item => {
          const index = (currentSchemaList || []).findIndex(inner => inner.id === item.id)
          return index === -1
        })
        this.setState({
          schemas: uniqueSchemasList
        })
        return null
      }
    }

    onRatingSelect = (selectedRating, index) => {
      if (selectedRating) {
        const { ratingData } = this.state
        ratingData[index].rating = selectedRating
        this.props.handleRatingChange(ratingData)
      } else {
        const { ratingData } = this.state
        ratingData[index].rating = ''
        this.props.handleRatingChange(ratingData)
        return null
      }
    }

    onContentRatingSelect = (value, index) => {
      const { ratingData } = this.state
      ratingData[index].subRatings = value
      this.props.handleRatingChange(ratingData)
    }

    reorder = (startIndex, endIndex) => {
      const { ratingData } = this.state
      const result = Array.from(ratingData)
      result[startIndex].position = endIndex
      result[endIndex].position = startIndex
      const [removed] = result.splice(startIndex, 1)
      removed.trackPosChange = true
      result.splice(endIndex, 0, removed)
      result.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.props.handleRatingChange(result)
    }

    onCloseField = (index) => {
      const { ratingData } = this.state
      const { listRatingDetails } = this.props
      ratingData.splice(index, 1)
      const schemas = (listRatingDetails || []).map(item => {
        return {
          id: item.id,
          name: item.scheme,
          source: item.source
        }
      })
      const currentSchemaList = (ratingData || []).map(item => {
        return {
          id: item.id,
          name: item.scheme,
          source: item.source
        }
      })
      const uniqueSchemasList = _.difference(schemas, currentSchemaList)
      this.setState({
        schemas: uniqueSchemasList
      })
      this.props.handleRatingChange(ratingData)
    }

    addFieldObjects = () => {
      const { ratingData } = this.state
      const id = utilityService.makeRandomString(6)
      const newFieldObject = {
        scheme: '',
        rating: '',
        subRatings: [],
        id,
        position: ratingData && ratingData.length ? ratingData[ratingData.length - 1].position + 1 : 0
      }
      ratingData.push(newFieldObject)
      this.props.handleRatingChange(ratingData)
    }

    render () {
      const { ratingData, schemaSearch, ratingSearch, schemas, ratings, contentRatings } = this.state
      const { listRatingDetails, isUpdateDisabled } = this.props
      return (
        <>
          <div className='create-rating'>
            <RatingFieldComponent
              reorder={this.reorder}
              onCloseField={this.onCloseField}
              onSchemaSelect={this.onSchemaSelect}
              onSchemaSearch={this.onSchemaSearch}
              schemaSearch={schemaSearch}
              schemas={schemas}
              onRatingSelect={this.onRatingSelect}
              onRatingSearch={this.onRatingSearch}
              ratingSearch={ratingSearch}
              ratings={ratings}
              ratingData={ratingData}
              onContentRatingSelect={this.onContentRatingSelect}
              contentRatings={contentRatings}
              listRatingDetails={listRatingDetails}
              isUpdateDisabled={isUpdateDisabled}
            />
            <LoadingButton
              type='primary'
              onClick={this.addFieldObjects}
              htmlType='submit'
              buttonText={'Add Ratings'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={isUpdateDisabled}
            />
          </div>
      </>

      )
    }
}

CreateRatingComponent.propTypes = {
  /** Details of list ratings */
  listRatingDetails: PropTypes.array,
  /** Details of asset ratings */
  ratings: PropTypes.array,
  /** function to handle rating change */
  handleRatingChange: PropTypes.func.isRequired
}

CreateRatingComponent.defaultProps = {
}

export default CreateRatingComponent
