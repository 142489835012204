import * as React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

export class MasterConfigModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedIndex: props.channelConfigList && props.channelConfigList.length ? props.channelConfigList[0].id : ''
    }
  }
  render () {
    return <Modal
      className='master-config-modal'
      title='CHOOSE MASTER CONFIGURATION'
      maskClosable={false}
      visible={this.props.isVisible}
      okText='Confirm'
      onOk={this.onOk}
      okButtonDisabled={this.props.isLoading}
      okButtonProps={{ loading: this.props.isLoading }}
      onCancel={this.onCancel}
      closable={false}
      centered
      destroyOnClose
      width='620px'
      afterClose={this.clearState}
    >
      <div className='config-items-container'>
        { this.props.channelConfigList ? this.props.channelConfigList.map((dataItem, index) => {
          return <div className={`config-items ${dataItem.id === this.state.selectedIndex ? 'active' : ''}`} key={index}
            onClick={this.onConfigSelect.bind(this, dataItem.id)}><span>{dataItem.name}</span></div>
        }) : ''}
      </div>
    </Modal>
  }

    onConfigSelect = (id) => {
      this.setState({ selectedIndex: id })
    }

    onCancel = () => {
      if (this.props.onCancel) { this.props.onCancel() }
    }

    onOk = () => {
      if (this.props.onOk) { this.props.onOk(this.state.selectedIndex) }
    }

    clearState = () => {
      this.setState({ selectedIndex: '' })
    }

    UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
      if (!this.state.selectedIndex && nextProps.channelConfigList && nextProps.channelConfigList.length) { this.setState({ selectedIndex: nextProps.channelConfigList[0].id }) }
    }
}

MasterConfigModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  channelConfigList: PropTypes.any,
  isLoading: PropTypes.bool
}
