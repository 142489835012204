import React, { Component } from 'react'
import { contextMenu } from 'react-contexify'
import InfiniteScroll from 'react-infinite-scroller'
import { Skeleton, Empty } from 'antd'
// import PropTypes from 'prop-types'
import 'react-contexify/dist/ReactContexify.css'

import AuthService from './../../../../services/AuthService'
import { utilityService } from './../../../../services/UtilityService'
import BucketAppConfigListCell from '../../dataDisplay/BucketAppConfigListCell'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import SubscriptionCreateBuckets from '../../../../graphQL/bucket/createBucketSubscription'
import QueryFilterBuckets from '../../../../graphQL/bucket/searchBuckets'

const width = ['100%', '100%']

class BucketAppConfigList extends Component {
  state = {
    loading: false,
    visible: false,
    shouldShowCreate: false,
    bucket: '',
    shouldShowWarning: false,
    isModalLoading: false,
    selectedId: '',
    isArchive: false,
    isSearching: false,
    isPaginating: false
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.getUserDetails()
    this.props.subscribeToNewBucket()
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!newProps.isLoading && this.lastSearched !== { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }) {
      this.lastSearched = { searchString: newProps.searchString, filter: newProps.filterVal, sort: newProps.sort }
      this.setState({ bucketList: newProps.bucketList, isSearching: false, isPaginating: false, isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.bucketList, this.props.bucketList)) {
      this.setState({ bucketList: newProps.bucketList, isSearching: false, isPaginating: false, isLoading: false })
    } else if (newProps.isLoading) {
      this.setState({ isLoading: true })
    } else if (!newProps.isLoading && _.isEmpty(newProps.bucketList)) {
      this.setState({ isLoading: false })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  getUserDetails = async () => {
    const userDetails = await AuthService.getUserDetails()
    if (userDetails) {
      this.username = userDetails.name || userDetails.email
    }
  }

  handleClickOutside = () => {
    if (this.state.shouldShowCreate) {
      setTimeout(() => this.setState({ shouldShowCreate: false }), 200)
    }
  }

  loadMoreAsset = () => {
    if (this.props.totalCount === this.props.bucketList.length || this.state.isPaginating) { return }
    this.setState({ isPaginating: true }, () => this.props.getBucketList(this.state.bucketList.length))
  }

  render () {
    const { isPaginating } = this.state
    const { bucketList, isLoading, handleBucketSelection, selectedBucket } = this.props
    return (
      <React.Fragment>
        <div className='bucket-list-container'>
          <Skeleton active title={false} paragraph={{ rows: 2, width: width }} loading={isLoading && !isPaginating}>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMoreAsset}
              hasMore={this.props.totalCount > bucketList.length}
              initialLoad={false}
              useWindow={false}
            >
              { bucketList && bucketList.length ? bucketList.map((item, index) => {
                const status = utilityService.getPublishStatus(item.isPublished, item.publishStartDate, item.publishEndDate)
                return <div className='bucket-list' key={item.id}>
                  <div
                    onContextMenu={(e) => contextMenu.show({
                      id: `${item.id}-bucket-list-menu-item`,
                      event: e
                    })}
                  >
                    <BucketAppConfigListCell
                      bucketName={item.name}
                      bucketType={item.displayType ? item.displayType.name : ''}
                      status={status}
                      shortId={item.key}
                      onBucketAdd={() => { }}
                      isEnabled={false}
                      isArchived={item.isArchived}
                      handleBucketSelection={handleBucketSelection}
                      bucketDetails={item}
                      selectedBucket={selectedBucket}
                    />
                  </div>
                </div>
              }
              ) : <Empty />}
            </InfiniteScroll>
          </Skeleton>
          <Skeleton active title={false} paragraph={{ rows: 2, width: width }} loading={isPaginating && this.props.totalCount > bucketList.length} />
        </div>
      </React.Fragment>

    )
  }
}

BucketAppConfigList.propTypes = {

}

export default withApollo(compose(
  graphql(
    QueryFilterBuckets,
    {
      options: (props) => {
        const { searchString, filterVal, sort, project } = props
        const variables = utilityService.getFormattedBucketFilter(searchString, filterVal, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const bucketList = data.listBuckets && data.listBuckets.items && data.listBuckets.items.length ? data.listBuckets.items : []

        return {
          bucketList,
          isLoading: data.loading || !data.listBuckets,
          totalCount: data.listBuckets ? data.listBuckets.totalCount : 0,
          getBucketList: (page) => {
            return data.fetchMore({
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (previous, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previous
                const prev = cloneDeep(previous)
                const newList = [...prev.listBuckets.items, ...fetchMoreResult.listBuckets.items]
                prev.listBuckets.items = newList
                return prev
              }
            })
          },
          subscribeToNewBucket: () => {
            return props.data.subscribeToMore({
              document: SubscriptionCreateBuckets,
              updateQuery: (previous, { subscriptionData: { data: { bucketCreated } } }) => {
                const { project } = props.ownProps
                if (!bucketCreated || (project !== bucketCreated.project)) return previous
                const prev = cloneDeep(previous)
                const index = prev.listBuckets.items.findIndex(group => group.id === bucketCreated.id)
                if (index !== -1) {
                  prev.listBuckets.items.splice(index, 1)
                }
                const newList = [ bucketCreated, ...prev.listBuckets.items ]
                prev.listBuckets.items = newList
                return prev
              }
            })
          }
        }
      }

    }
  )
)(BucketAppConfigList))
