import gql from 'graphql-tag'

export default gql(`
mutation adminUpdateAttributes($email: String, $project: String, $adminAttributes:[AdminAttributesInput]){
    adminUpdateAttributes(input:{
      email: $email
      adminAttributes: $adminAttributes
    }
    project: $project
    ){
      statusCode
      success {
        message
      }
      error {
        message
      }
      }
    }
`)
