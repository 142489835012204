import React from 'react'
import PropTypes from 'prop-types'

const FreeIcon = ({ width, height, selected, onClick, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
      <rect width='27.857' height='15' stroke={color} strokeWidth='.714' rx='3.571' />
      <text fill={color} fontFamily='Lato-Bold, Lato' fontSize='8.571' fontWeight='bold'>
        <tspan x='3.571' y='10.143'>FREE</tspan>
      </text>
    </g>
  </svg>
)

FreeIcon.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

FreeIcon.defaultProps = {
  selected: false,
  width: 30,
  height: 17,
  color: '#FF015A'
}

export default FreeIcon
