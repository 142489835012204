import gql from 'graphql-tag'

export default gql(`
    query listPublishingGroups($limit: Int $project: String){
        listPublishingGroups( 
            limit: $limit
            project: $project
            ) {
        items {
            id
            name
            status
            isDefault
            isEnvironment
            countries
        }
    }
}`)
