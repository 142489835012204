import React from 'react'
import PropTypes from 'prop-types'

const MatchListIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd' stroke={color} strokeLinecap='square'>
      <path d='M1 .5h20M1 5.5h20M1 11.5h20M1 16.5h20' />
    </g>
  </svg>

)

MatchListIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

MatchListIcon.defaultProps = {
  width: 22,
  height: 17
}

export default MatchListIcon
