import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, message } from 'antd'

import _ from 'lodash'
import Input from '../inputs/Input'
import AssetTypeRequiredFieldList from '../../dataDisplay/AssetTypeRequiredFieldList'
import AspectRatioSettings from '../../dataEntry/inputs/AspectRatioSettings'
import { defaultAspectRatioList } from '../../../../util/util'
import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'

// import { utilityService } from '../../../../services/UtilityService'
const { TabPane } = Tabs
let primaryImageType

class CreateImageTypeModal extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    this.state = {
      isLoading: false,
      enable: false,
      activeKey: '1',
      isNoDuplicate: false,
      streamField: [],
      details: props.selectedType ? _.cloneDeep(props.selectedType) : {
        name: ''
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.selectedType, this.props.selectedType)) {
      this.setState({ details: nextProps.selectedType ? _.cloneDeep(nextProps.selectedType) : { name: '' }
      })
    }
  }

      onTabChange = (activeKey) => {
        this.setState({ activeKey })
      }

      onChangeData = (assetType, status) => {
        const { details } = this.state
        const enabledAssetTypes = details ? (_.cloneDeep(details.enabledAssetTypes) || []) : []
        const index = enabledAssetTypes.findIndex(item => item.assetType === assetType)
        if (index > -1) {
          if (status) { enabledAssetTypes.isEnable = status } else {
            enabledAssetTypes.splice(index, 1)
          }
        } else {
          enabledAssetTypes.push({
            assetType,
            minCount: '0',
            cropPosition: 'TOP_LEFT',
            isEnable: true })
        }
        details.enabledAssetTypes = enabledAssetTypes
        this.setState({ details, isBoolEnabled: true })
      }

      handleNumberChange = (assetType, minCount) => {
        const { details } = this.state
        details.enabledAssetTypes = (details.enabledAssetTypes).map(type => {
          if (type.assetType === assetType) {
            if (minCount && minCount.startsWith('0')) { minCount = minCount.substring(1) }
            type.minCount = minCount || '0'
          }
          return type
        })
        this.setState({ details, isBoolEnabled: true })
      }

      onCropPositionCHange = (assetType, cropPosition) => {
        const { details } = this.state
        details.enabledAssetTypes = (details.enabledAssetTypes).map(type => {
          if (type.assetType === assetType) { type.cropPosition = cropPosition }
          return type
        })
        this.setState({ details, isBoolEnabled: true })
      }

      onChangeAspectRatio = (aspectRatios) => {
        const { details } = this.state
        details.aspectRatios = aspectRatios
        this.setState({ details, isBoolEnabled: true })
      }

      handleChange =(e) => {
        const { details } = this.state
        const { value, name } = e.target

        if (name === 'name') {
          const regexExp = /^[A-Za-z0-9 _-]+$/
          const isCheck = regexExp.test(value)
          if (isCheck || value === '') {
            details.name = value
            this.setState({ details, isBoolEnabled: true })
          }
          // const name = isCheck? value.toUpperCase() : ''
        }
      }

      handleImageType = () => {
        const { details } = this.state
        const { isEdit } = this.props
        let isValid = true
        let isValidAspect = true
        const isPrimary = details && details.id === primaryImageType

        if (!details.aspectRatios || !details.aspectRatios.length) {
          message.warn('Please add aspect ratio')
          return
        }

        details.enabledAssetTypes = (details.enabledAssetTypes || []).map(item => {
          delete item.__typename
          delete item.isEnable
          return item
        })

        details.aspectRatios = (details.aspectRatios || []).map(item => {
          delete item.__typename
          delete item.name
          // if (!isEdit && item.aspectRatio && item.aspectRatio.length === 5) { delete item.aspectRatio }
          const titleArr = (item.title || '').split(':').filter(title => title)
          if (!item.title || titleArr.length !== 2) {
            isValidAspect = false
          } else {
            item.ratio = titleArr[0] / titleArr[1]
          }

          if (!item.resolutions || !item.resolutions.length) {
            isValid = false
          }
          item.resolutions = (item.resolutions || []).map(innerItem => {
            delete innerItem.__typename
            // if (!isEdit && innerItem.id && innerItem.id.length === 5) { delete innerItem.id }
            let isDisabled = false
            if (isPrimary) {
              const defaultResolutions = defaultAspectRatioList.find(itemRes => itemRes.title === item.title)
              const defaultAspectRatio = defaultResolutions ? (defaultResolutions.resolutions || []).find(itemRes => itemRes.key === innerItem.key) : null
              if (!_.isEmpty(defaultAspectRatio)) {
                isDisabled = true
              }
            }
            if (!innerItem.width || !innerItem.height) {
              isValid = false
            }
            if (innerItem.width && innerItem.height && !isDisabled) {
              innerItem.key = `${innerItem.height}x${innerItem.width}`
            }
            return innerItem
          })
          return item
        })

        if (!isValidAspect) {
          message.warn('Please provide proper aspect ratio name')
          return
        }

        if (!isValid) {
          message.warn('Please provide proper dimensions for all aspect ratios')
          return
        }

        if (isEdit) {
          const variables = {
            id: details.id,
            usageCount: details.usageCount,
            name: details.name,
            aspectRatios: details.aspectRatios,
            enabledAssetTypes: details.enabledAssetTypes
          }
          this.props.handleSubmit(variables)
        } else this.props.handleSubmit(details)
      }

    resetState = () => {
      const state = {
        isLoading: false,
        enable: false,
        activeKey: '1',
        isBoolEnabled: false,
        draftIcon: null,
        isNoDuplicate: false,
        streamField: [],
        details: { name: '' }
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedType, isEdit, isSubmitDisabled, listAssetTypes, isLoading } = this.props
      const { details, isBoolEnabled, streamField, isNoDuplicate } = this.state
      const disabled = isBoolEnabled && details.name
      const uniqDataArr = _.uniqBy((streamField || []), asset => [asset.type, asset.name].join())
      const isDuplicate = uniqDataArr.length !== (streamField || []).length
      return (
        <Modal
          className='general-modal create-image-type-container'
          title={isEdit ? 'EDIT IMAGE TYPE' : 'NEW IMAGE TYPE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleImageType}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (!disabled || isDuplicate) || isSubmitDisabled || isNoDuplicate, loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-image-type'>
            <Input
              title='Image Type Name'
              inputName='name'
              isRequired
              handleChange={this.handleChange}
              value={details ? details.name : ''}
              placeholder='Enter Image Type Name'
              limit={25}
              readOnly={!!selectedType && !!isEdit} />
            <Tabs onChange={this.onTabChange}>
              <TabPane tab='Enabled for Asset Types' key='1'>
                <AssetTypeRequiredFieldList
                  onChange={this.onChangeData}
                  handleNumberChange={this.handleNumberChange}
                  onCropPositionCHange={this.onCropPositionCHange}
                  details={details ? details.enabledAssetTypes : undefined}
                  listAssetTypes={listAssetTypes}
                />
              </TabPane>
              <TabPane tab='Aspect Ratio Settings' key='2'>
                <AspectRatioSettings
                  aspectRatios={details ? details.aspectRatios : []}
                  onChangeAspectRatio={this.onChangeAspectRatio}
                  isPrimary={details && details.id === primaryImageType}
                  selectedType={selectedType ? selectedType.aspectRatios : []}
                />
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      )
    }
}

CreateImageTypeModal.propTypes = {
  /** visible file status of CreateImageTypeModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateImageTypeModal. */
  handleCancel: PropTypes.func
}

CreateImageTypeModal.defaultProps = {
}

export default CreateImageTypeModal
