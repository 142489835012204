import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import Input from '../inputs/Input'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryPublishGroupList from '../../../../graphQL/admin/getPublishingGroups'
import MutationCreatePublishGroup from '../../../../graphQL/admin/createPublishGroup'
import MutationUpdatePublishGroup from '../../../../graphQL/admin/updatePublishGroup'
import { utilityService } from '../../../../services/UtilityService'

// import { utilityService } from '../../../../services/UtilityService'

class CreatePublishingGroupModal extends Component {
    state = {
      name: undefined,
      isLoading: false,
      isEdited: false
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedPublishGroup && (!_.isEqual(this.props.selectedPublishGroup, nextProps.selectedPublishGroup) || !this.state.name)) {
        this.setState({
          name: nextProps.selectedPublishGroup.name
        })
      } else if (!nextProps.selectedPublishGroup && this.props.selectedPublishGroup && this.state.name) {
        this.setState({ name: '' })
      }
    }

    handleChange =(e) => {
      const { value } = e.target
      this.setState({ name: value, isEdited: true })
    }

    handleSubmitPublishGroup = () => {
      const { name } = this.state
      const { onHistoryUpdate } = this.props
      const { selectedPublishGroup, handleSubmit, publishGroups } = this.props
      this.setState({ isLoading: true })
      const publishGroupRequset = { name }
      const isSameKeyPresent = publishGroups.filter(item => item.name === name)
      if (!selectedPublishGroup) {
        if (isSameKeyPresent && isSameKeyPresent.length) {
          message.error('Duplicate name found. Please try another name')
          this.setState({ isLoading: false })
          return
        }
        this.props.createPublishGroup(publishGroupRequset).then(() => {
          handleSubmit()
          onHistoryUpdate()
          this.setState({ isLoading: false })
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false })
        })
      } else {
        if (isSameKeyPresent && isSameKeyPresent.length && isSameKeyPresent[0].id !== selectedPublishGroup.id) {
          message.error('Duplicate name found. Please try another name')
          this.setState({ isLoading: false })
          return
        }
        this.props.updatePublishGroup({ id: selectedPublishGroup.id, ...publishGroupRequset }).then(() => {
          handleSubmit()
          onHistoryUpdate()
          this.setState({ isLoading: false })
        }, error => {
          utilityService.handleError(error)
          this.setState({ isLoading: false })
        })
      }
    }

    resetState = () => {
      const state = {
        name: '',
        isLoading: false,
        isEdited: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedPublishGroup, isSubmitDisabled } = this.props
      const { name, isLoading, isEdited } = this.state
      return (
        <Modal
          className='general-modal create-publish-groups-modal'
          title={selectedPublishGroup ? 'EDIT PUBLISHING GROUP' : 'NEW PUBLISHING GROUP'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleSubmitPublishGroup}
          onCancel={handleCancel}
          okButtonProps={{ disabled: (isLoading || !name || !isEdited || isSubmitDisabled), loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='600px'
          confirmLoading={isLoading}
        >
          <div className='create-publish-groups'>
            <Input title='Publish Group Name' inputName='name' handleChange={this.handleChange} value={name} isRequired />
          </div>
        </Modal>
      )
    }
}

CreatePublishingGroupModal.propTypes = {
  /** visible file status of CreateBucketDisplayTypesModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateBucketDisplayTypesModal. */
  handleCancel: PropTypes.func
}

CreatePublishingGroupModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationCreatePublishGroup, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { createPublishingGroup } }) => {
              const variables = { filter: null, limit: 50, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPublishGroupList, variables }))
              if (cacheData && cacheData.listPublishingGroups && cacheData.listPublishingGroups.items) { cacheData.listPublishingGroups.items.push(createPublishingGroup) }
              cache.writeQuery({
                query: QueryPublishGroupList,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          createPublishGroup: (request) => {
            request.project = props.ownProps.project
            return props.mutate({
              variables: request
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdatePublishGroup, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { updatePublishingGroup } }) => {
              const variables = { filter: null, limit: 50, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPublishGroupList, variables }))
              if (cacheData && cacheData.listPublishingGroups && cacheData.listPublishingGroups.items) {
                const selectedAuthorIndex = cacheData.listPublishingGroups.items.findIndex(item => item.id === updatePublishingGroup.id)
                if (selectedAuthorIndex > -1) {
                  cacheData.listPublishingGroups.items[selectedAuthorIndex] = updatePublishingGroup
                }
              }
              cache.writeQuery({
                query: QueryPublishGroupList,
                data: cacheData,
                variables
              })
            }
          }
        },
        props: (props) => ({
          updatePublishGroup: (request) => {
            request.project = props.ownProps.project
            return props.mutate({
              variables: request
            })
          }
        })
      }
    ),
    graphql(
      QueryPublishGroupList,
      {
        options: ({ project }) => {
          const variables = { limit: 50, project }
          return {
            variables
          }
        },
        props: ({ data }) => {
          return {
            publishGroups: data && data.listPublishingGroups && data.listPublishingGroups.items ? [ ...data.listPublishingGroups.items ] : undefined
          }
        }
      }
    )

  )(CreatePublishingGroupModal)
)
