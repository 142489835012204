import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationCreateCredit from '../../../../graphQL/asset/hyperion/addAssetCreditLinks'
import MutationDeleteCredit from '../../../../graphQL/asset/hyperion/deleteAssetCreditLinks'

import LoadingButton from '../../general/buttons/LoadingButton'
import AddImageModal from './AddImageModal'
import CreatePersonModal from './CreatePersonModal'
import { utilityService } from '../../../../services/UtilityService'
import { getCropImageUrl } from '../../../../util/util'
import CropImageModal from './CropImageModal'
import CreditCrewFieldComponent from './../../dataDisplay/CreditCrewFieldComponent'

import './../../ui.style.scss'

class CreateCrewCredit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      credits: props.credits ? _.sortBy(props.credits, 'position') : [],
      showCreatePersonModal: false,
      selectedImage: null,
      croppedUrl: '',
      showAddImageModal: false,
      showCropModal: false,
      aspectRatio: {},
      settings: [],
      currentIndex: null
    }
    this.tempIdArr = []
  }

    UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
      if (this.props.credits !== newProps.credits) {
        this.setState({
          credits: newProps.credits ? _.sortBy(newProps.credits, 'position') : []
        })
      }
    }

    clearSelectedImage=() => {
      this.setState({ selectedImage: null, croppedUrl: null })
    }

    showCropModal = () => {
      const { selectedImage } = this.state
      const aspectRatio = selectedImage[0].aspectRatio.find(item => item.title === '1:1')
      const settings = selectedImage[0].settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
      this.setState({ showCreatePersonModal: false, settings, aspectRatio }, () => this.setState({ showCropModal: true }))
    }

    showAddImageModal = () => {
      this.setState({ showAddImageModal: true, showCreatePersonModal: false })
    }

    hideCreatePersonModal = () => {
      this.setState({ showCreatePersonModal: false, selectedImage: null, croppedUrl: '' })
    }

    onCreatePerson = (person, image) => {
      this.onPersonChange(person, this.state.currentIndex)
      this.setState({ showCreatePersonModal: false, selectedImage: null, croppedUrl: '', currentIndex: null })
    }

    saveCurrentSelection = (selectedImage) => {
      this.setState({ selectedImage, showAddImageModal: false, showCreatePersonModal: true })
    }

    hideAddImageModal = () => {
      this.setState({ showAddImageModal: false, showCreatePersonModal: true })
    }

    hideCropModal=() => {
      this.setState({ showCropModal: false, showCreatePersonModal: true })
    }

    saveCurrentCrop = (settings) => {
      const image = { ...this.state.selectedImage[0] }
      const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
      const currentaspectId = currentAspectRatio.aspectRatio
      const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
      image.settings.map(setting => {
        setting.outputFormat = 'JPG'
        delete setting[ '__typename' ]
      })
      image.settings[changedIndex].x1 = settings.x1
      image.settings[changedIndex].x2 = settings.x2
      image.settings[changedIndex].y1 = settings.y1
      image.settings[changedIndex].y2 = settings.y2
      const croppedUrl = getCropImageUrl(image, settings)
      this.setState({ showCropModal: false, showCreatePersonModal: true, croppedUrl })
    }

    onPersonChange = (selectedPerson, index) => {
      if (selectedPerson) {
        const { credits } = this.state
        const { assetId } = this.props
        credits[index].person = selectedPerson
        if (!_.isEmpty(credits[index].role) && this.tempIdArr.includes(credits[index].id)) {
          const variables = {
            assetId,
            roleId: credits[index].role.id,
            personId: selectedPerson.id,
            position: credits[index].position,
            type: 'crew',
            characterName: ''
          }
          this.props.addAssetCreditLinks(variables).then(({ data }) => {
            const { credits } = this.state
            const id = data.addAssetCreditLinks[0].id
            credits[index].id = id
            const tempIndex = this.tempIdArr.findIndex(item => item === id)
            if (tempIndex > -1) { this.tempIdArr.splice(tempIndex, 1) }
            this.setState({ credits })
            this.props.onChangeCredits(credits)
          })
        }
        this.setState({ credits })
        this.props.onChangeCredits(credits)
      } else {
        const { credits } = this.state
        credits[index].person = {}
        this.setState({ credits })
        this.props.onChangeCredits(credits)
        return null
      }
    }

    onPersonSearch = (value) => {
      this.props.onPersonSearch(value)
    }

      onRoleSearch = (value) => {
        this.props.onRoleSearch(value)
      }

    showCreatePersonModal = (currentIndex) => {
      this.setState({ showCreatePersonModal: true, currentIndex })
    }

    onRoleSelect = (selectedRole, index) => {
      if (selectedRole) {
        const { credits } = this.state
        const { assetId } = this.props
        credits[index].role = selectedRole
        if (!_.isEmpty(credits[index].person) && this.tempIdArr.includes(credits[index].id)) {
          const variables = {
            assetId,
            roleId: selectedRole.id,
            personId: credits[index].person.id,
            position: credits[index].position,
            type: 'crew',
            characterName: ''
          }
          this.props.addAssetCreditLinks(variables).then(({ data }) => {
            const { credits } = this.state
            const id = data.addAssetCreditLinks[0].id
            credits[index].id = id
            const tempIndex = this.tempIdArr.findIndex(item => item === id)
            if (tempIndex > -1) { this.tempIdArr.splice(tempIndex, 1) }
            this.setState({ credits })
            this.props.onChangeCredits(credits)
          }, (errorMessage) => {
            credits.splice(index, 1)
            this.setState({ credits })
            utilityService.handleError(errorMessage)
          })
        }
        this.setState({ credits })
        this.props.onChangeCredits(credits)
      } else {
        const { credits } = this.state
        credits[index].role = {}
        this.setState({ credits })
        this.props.onChangeCredits(credits)
        return null
      }
    }

    reorder = (startIndex, endIndex) => {
      const { credits } = this.state
      const result = Array.from(credits)
      result[startIndex].position = endIndex
      result[endIndex].position = startIndex
      const [removed] = result.splice(startIndex, 1)
      removed.trackPosChange = true
      result.splice(endIndex, 0, removed)
      result.map((item, index) => {
        item.position = index + 1
        return item
      })
      this.setState({ credits: result })
      this.props.onChangeCredits(result)
    }

    onCloseField = (id) => {
      const { credits } = this.state
      const { assetId, project } = this.props
      const index = credits.findIndex(item => item.id === id)
      if (index > -1) {
        credits.splice(index, 1)
        this.setState({ credits })
        const crewSize = _.size(credits)
        const isCrewEmpty = crewSize === 0
        this.props.onChangeCredits(credits, isCrewEmpty)
        if (this.tempIdArr.includes(id)) {
          const tempIndex = this.tempIdArr.findIndex(item => item === id)
          this.tempIdArr.splice(tempIndex, 1)
        } else {
          this.props.deleteAssetCreditLinks([id], project, assetId).then(() => {
            // debugger
          })
        }
      }
    }

    addFieldObjects = () => {
      const { credits } = this.state
      const id = utilityService.makeRandomString(6)
      const newFieldObject = {
        role: {},
        person: {},
        id,
        position: credits.length,
        type: 'crew',
        characterName: ''
      }

      credits.push(newFieldObject)
      this.tempIdArr.push(id)
      this.setState({ credits })
      this.props.onChangeCredits(credits)
    }

    render () {
      const { credits, roleSearch, settings, showCropModal, aspectRatio, showAddImageModal, showCreatePersonModal, croppedUrl, selectedImage } = this.state
      const { isCreateDisabled, isCreateDisabledContent, isUpdateDisabled, isUpdateDisabledContent, listRolesDetails, listPersonsDetails, project } = this.props
      return (
        <>
          <div className='create-credit'>
            <CreditCrewFieldComponent
              reorder={this.reorder}
              onCloseField={this.onCloseField}
              onRoleSelect={this.onRoleSelect}
              onPersonChange={this.onPersonChange}
              onPersonSearch={this.onPersonSearch}
              onRoleSearch={this.onRoleSearch}
              roleSearch={roleSearch}
              credits={credits}
              roles={listRolesDetails}
              persons={listPersonsDetails}
              isCreateDisabled={isCreateDisabled}
              isUpdateDisabled={isUpdateDisabled}
              showCreatePersonModal={this.showCreatePersonModal}
              project={project}
            />
            <LoadingButton
              type='primary'
              onClick={this.addFieldObjects}
              htmlType='submit'
              buttonText={'Add Crew'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={isUpdateDisabled}
            />
          </div>
          <CropImageModal
            isVisible={showCropModal}
            aspectRatio={aspectRatio}
            imgUrl={settings && settings.length ? settings[0].fileName : null}
            settings={settings}
            updateSettings={this.saveCurrentCrop}
            handleCancel={this.hideCropModal}
            project={project}
            isUpdateBlocked={isUpdateDisabledContent} />
          <AddImageModal
            isMultiSelect={false}
            isVisible={showAddImageModal}
            handleSubmit={this.saveCurrentSelection}
            handleCancel={this.hideAddImageModal}
            mediaType='IMAGE'
            isUpdateDisabled={isUpdateDisabled}
            project={project}
            isSubmitButtonDisabled={isCreateDisabledContent} />
          <CreatePersonModal
            clearSelection={this.clearSelectedImage}
            isVisible={showCreatePersonModal}
            croppedUrl={croppedUrl}
            showCropModal={this.showCropModal}
            selectedImage={selectedImage}
            showImageModal={this.showAddImageModal}
            handleCancel={this.hideCreatePersonModal}
            handleSubmit={this.onCreatePerson}
            isUpdateBlocked={isUpdateDisabledContent}
            person={listPersonsDetails}
            project={project}
            isFromAsset
            module='ASSET_MANAGER' />
      </>

      )
    }
}

CreateCrewCredit.propTypes = {
  /** Details of RolesList */
  listRolesDetails: PropTypes.array,
  /** Details of PersonsList */
  listPersonsDetails: PropTypes.array

}

CreateCrewCredit.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationCreateCredit,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        addAssetCreditLinks: (input) => {
          return props.mutate({
            variables: { input, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteCredit,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        deleteAssetCreditLinks: (input, project, assetId) => {
          return props.mutate({
            variables: { input, project, assetId }
          })
        }
      })
    }
  )

)(CreateCrewCredit))
