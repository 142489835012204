import React from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { Form, Input, message } from 'antd'

import { utilityService } from './../../../../services/UtilityService'
import userMessages from '../../../../constants/messages'
import LoadingButton from './../../general/buttons/LoadingButton'

import './../../ui.style.scss'

class ForgotForm extends React.Component {
    state = {
      username: '',
      newPassword: '',
      confirmPassword: '',
      isValidUserName: true,
      isValidPassword: true,
      isValidConfirmPassword: true,
      isValidCode: true,
      isSendCode: true,
      isLoading: false,
      disabled: true
    }

    handleSubmit = (e) => {
      e.preventDefault()
      if (this.state.isLoading) {
        return
      }
      this.setState({ isLoading: true })
      let isValidUserName = true; let isValidPassword = true; let isValidConfirmPassword = true; let isValidCode = true
      const { username } = this.state
      const email = username.toLowerCase()
      if (this.state.isSendCode) {
        if (!email) {
          message.warning(userMessages.FORGOT_PASSWORD_EMAIL_EMPTY)
          isValidUserName = false
          this.setState({ isLoading: false })
        } else {
          isValidUserName = utilityService.checkEmailValidation(email)
          if (!isValidUserName) {
            message.warning(userMessages.FORGOT_PASSWORD_EMAIL_VALIDATAION)
            this.setState({ isLoading: false })
          }
        }
        if (!isValidUserName) {
          this.setState({ isValidUserName })
          return
        }
        const attributes = {
          meta: window.config.meta,
          sessionId: new Date().getTime().toString(),
          project: utilityService.getCurrentProject(),
          userAgent: utilityService.getBrowserDetails()
        }
        Auth.forgotPassword(email, attributes)
          .then(data => {
            this.setState({ isSendCode: false })
            message.success(userMessages.FORGOT_VERIFICATION_CODE_SUCCESS)
            this.setState({ isLoading: false })
          })
          .catch(() => {
            this.setState({ isSendCode: false })
            message.success(userMessages.FORGOT_VERIFICATION_CODE_SUCCESS)
            this.setState({ isLoading: false })
          })
      } else {
        const { code, newPassword, confirmPassword } = this.state
        let errorCount = 0
        if (!code) {
          isValidCode = false
          errorCount++
        }
        if (!newPassword) {
          isValidPassword = false
          errorCount++
        }
        if (!confirmPassword) {
          isValidConfirmPassword = false
          errorCount++
        }
        if (errorCount === 1) {
          message.warning(userMessages.AUTH_EMPTY_FIELDS)
        } else if (errorCount > 1) {
          message.warning(userMessages.AUTH_EMPTY_FIELDS)
        } else {
          if (newPassword !== confirmPassword) {
            isValidConfirmPassword = false
            message.warning(userMessages.AUTH_PASSWORD_MISMATCH)
            this.setState({ isLoading: false })
          } else {
            const isvalid = utilityService.checkPasswordValidation(newPassword)
            if (!isvalid) {
              isValidConfirmPassword = false
              isValidConfirmPassword = false
              message.warning(userMessages.AUTH_PASSWORD_VALIDATION, 10)
              this.setState({ isLoading: false })
            } else {
              const attributes = {
                meta: window.config.meta,
                sessionId: new Date().getTime().toString(),
                project: utilityService.getCurrentProject(),
                userAgent: utilityService.getBrowserDetails()
              }
              Auth.forgotPasswordSubmit(email, code, newPassword, attributes)
                .then(data => {
                  this.props.onSubmit()
                  this.setState({ isLoading: false })
                })
                .catch(err => {
                  message.error(err.message)
                  this.setState({ isLoading: false })
                })
            }
          }
        }
        this.setState({ isValidConfirmPassword, isValidPassword, isValidCode })
      }
    }

    handleLoginformChange = e => {
      const { name, value } = e.target
      this.setState({ [ name ]: value })
    }

    checkFormStatus = () => {
      const {
        username,
        code,
        newPassword,
        confirmPassword,
        isSendCode
      } = this.state
      if (isSendCode) {
        if (this.state.disabled) {
          if (username) {
            this.setState({ disabled: false })
          }
        } else {
          if (!username) {
            this.setState({ disabled: true })
          }
        }
      } else if (this.state.disabled) {
        if (code && newPassword && confirmPassword) {
          this.setState({ disabled: false })
        }
      } else {
        if (!code || !newPassword || !confirmPassword) {
          this.setState({ disabled: true })
        }
      }
    }

    render () {
      const { username, code, newPassword, confirmPassword, isSendCode, isValidPassword, isValidConfirmPassword, isValidUserName, isValidCode, disabled, isLoading } = this.state
      this.checkFormStatus()
      const isHyperion = window.location.href.includes('hyperion')
      const isSubhub = window.location.href.includes('subhub')
      return (
        <div className={`login-form forgot-form ${isLoading ? 'loading' : ''} ${!isSendCode ? 'reset' : ''}`}>

          {isHyperion ? <div className='optus-logo hyperion-logo'><span>HYPERION</span></div>
            : isSubhub ? <div className='optus-logo hyperion-logo'><span>SUBHUB</span></div>
              : <img className='optus-logo' src={require('./../../../../assets/images/optus-logo.png')} />}
          <span> { isSendCode ? 'FORGOT  PASSWORD' : 'RESET  PASSWORD' }</span>
          <div className='border-line' />
          <Form onSubmit={this.handleSubmit}>
            { isSendCode ? <Form.Item label='Email' >
              <Input className={`email ${!isValidUserName ? 'error' : ''}`} placeholder='Enter your email' name='username' onChange={this.handleLoginformChange} value={username} />
            </Form.Item> : null }
            { !isSendCode
              ? <React.Fragment>
                <Form.Item label='Verification Code' >
                  <Input className={`code ${!isValidCode ? 'error' : ''}`} placeholder='Enter your verification code' name='code' onChange={this.handleLoginformChange} value={code} autoComplete='new-password' />
                </Form.Item>
                <Form.Item label='New Password'>
                  <Input className={`password new-password ${!isValidPassword ? 'error' : ''}`} type='password' name='newPassword' placeholder='Enter your new password' value={newPassword} onChange={this.handleLoginformChange} autoComplete='new-password' />
                </Form.Item>
                <Form.Item label='Confirm Password'>
                  <Input className={`password confirm-password ${!isValidConfirmPassword ? 'error' : ''}`} type='password' name='confirmPassword' placeholder='Confirm your new password' value={confirmPassword} onChange={this.handleLoginformChange} />
                </Form.Item>
              </React.Fragment> : null}
            <Form.Item>
              <LoadingButton type='primary' htmlType='submit' buttonText={`${isSendCode ? 'Send Code' : 'Reset'}`} buttonClass='login-form-button login-form-submit' isLoading={isLoading} isDisabled={disabled} />
              <a className='login-form-signin' onClick={this.props.onLoginClick} > { isSendCode ? 'Return to Sign In' : '' }</a>
            </Form.Item>
          </Form>
        </div>
      )
    }
}

ForgotForm.propTypes = {
  /** Submit action of ForgotForm. */
  onSubmit: PropTypes.func.isRequired,
  /** forgot email link click action of ForgotForm. */
  onLoginClick: PropTypes.func.isRequired
}

ForgotForm.defaultProps = {
}

export default ForgotForm
