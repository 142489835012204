import React from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import Input from '../dataEntry/inputs/Input'
import { QRCodeSVG } from 'qrcode.react'
import './../ui.style.scss'
import ToolTip from '../dataDisplay/ToolTip'

const authenticatorList = [
  'Google Authenticator',
  'Forti Token'
]

class AddMfaDevices extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      code1: '',
      code2: '',
      isSecretKeyVisible: false
    }
  }

    handleMfaChange = ({ target }) => {
      const { name, value } = target
      if (name === 'code1') { this.setState({ code1: value }) } else { this.setState({ code2: value }) }
    }

    toggleSecretKey = () => {
      const { isSecretKeyVisible } = this.state
      this.setState({ isSecretKeyVisible: !isSecretKeyVisible })
    }

    onCopyDetails = (secretKey) => {
      const textField = document.createElement('textarea')
      textField.innerText = secretKey
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      message.success('Secret key copied to clipboard')
    }

    render () {
      const { isVisible, isLoading, handleCancel, handleSubmit, qrCode, isCancelNotVisible, secretKey } = this.props
      const { code2, code1, isSecretKeyVisible } = this.state
      return <Modal
        className={`confirm-modal mfa-modal`}
        title={'SET UP VIRTUAL MFA DEVICE'}
        visible={isVisible}
        cancelText={'Cancel'}
        okText={'Assign MFA'}
        onOk={() => handleSubmit(code1, code2)}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !(code1) }}
        cancelButtonProps={{ disabled: isCancelNotVisible }}
        closable={false}
        centered
        confirmLoading={isLoading}
        width={760}
        destroyOnClose
      >
        <div className='mfa-setup-container'>
          <div className='device-list'>
            <span>{'1. Install a compatible app on your mobile device or computer.'}</span>
            <ToolTip
              childComponent={<span>{'List of compatible applications'}</span>}
              details={authenticatorList}
              placement='bottom'
              isList />
          </div>
          <div className='qr-code-container'>
            <span>{'2. Use your virtual MFA app and your device’s camera to scan the QR code'}</span>
            <div className='qr-code'>
              <QRCodeSVG value={qrCode} size={198} bgColor='#eee' />
            </div>
          </div>
          <div className='secret-key-container'>
            <span>Alternatively, you can type the secret key.</span>
            <span className='secretkey-dialog' onClick={this.toggleSecretKey}>{`${isSecretKeyVisible ? 'Hide' : `Show`} secret key`}</span>
            {isSecretKeyVisible ? <div className='secretkey' onClick={() => this.onCopyDetails(secretKey)}>{secretKey}</div> : ''}
          </div>
          <div className='two-fa-header'>{'3. Type MFA code below'}</div>
          <div className='two-fa-container'>
            <Input
              title={'MFA code'}
              placeholder={`Enter MFA code`}
              inputName='code1'
              isRequired
              value={code1}
              handleChange={this.handleMfaChange}
            />
            {/* <Input
                title={'MFA code 2'}
                placeholder={`Enter MFA code 2`}
                inputName='code2'
                isRequired={true}
                value={code2}
                handleChange={this.handleMfaChange}
              /> */}
          </div>
        </div>
      </Modal>
    }
}

AddMfaDevices.propTypes = {
  /** loading status of Modal. */
  isLoading: PropTypes.bool,
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Cancel action of Modal. */
  handleCancel: PropTypes.func,
  /** Submit action of Modal. */
  handleSubmit: PropTypes.func
}

export default AddMfaDevices
