import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import RestoreIcon from '../icons/RestoreIcon'

const RestoreButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button restore ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <RestoreIcon />
  </div>
)

RestoreButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

RestoreButton.defaultProps = {
  isDisabled: false
}

export default RestoreButton
