import React from 'react'
import bitmovinPlayer from '../bitmovin/bitmovin'
import usePlayerTime from '../bitmovin/hooks/usePlayerTime'
import { formatDuration } from '../Utils/time'
import LiveIndicator from './liveIndicator'
const TimeInfo = props => {
  const isLive = bitmovinPlayer.isLive()
  const { timeElapsed } = usePlayerTime()
  const duration = formatDuration(bitmovinPlayer.getDuration())

  return (
    <>
      {isLive ? (
        <LiveIndicator />
      ) : duration !== '00:00' ? (
        <div className='timeInfo'>{`${formatDuration(timeElapsed)}/${duration}`}</div>
      ) : null}
    </>
  )
}
export default TimeInfo
