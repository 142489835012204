import gql from 'graphql-tag'

export default gql(`
    mutation adminForgotPassword( $email: String!) {
        adminForgotPassword(
            email: $email
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
