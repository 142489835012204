import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'

import ToolTip from '../../dataDisplay/ToolTip'

const ButtonContainer = ({ displayTitle, childComponent, parentCompoent, placement, styleClass, ...props }) => (
  <ToolTip className='btn-tooltip' placement={placement} isList childComponent={childComponent} details={[displayTitle]} parentCompoent={parentCompoent} styleClass={styleClass} />
)

ButtonContainer.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Placement of tooltip */
  placement: PropTypes.string,
  /** Class name */
  styleClass: PropTypes.string
}

ButtonContainer.defaultProps = {
  isDisabled: false,
  placement: 'top'
}

export default ButtonContainer
