import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { generateCroppedThumbnail } from './../../../../util/util'
import './../../ui.style.scss'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import CrossIcon from '../../general/icons/CrossIcon'

const Option = Select.Option

const customFilter = (input, option, options) => {
  const currentOption = options.find(item => item.id === option.props.value)
  const returnValue = currentOption && currentOption.name && currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

const PartnerDropDown = ({ displayParam, onOptionSelect, selected, parentCompoent, options, onSearch, index, ...props }) => {
  const optionComponents = options.map((option, index) =>
    (
      <Option className='people-drop-down-option' key={`option${index}`} value={option.id}>
        <img src={option.logo ? generateCroppedThumbnail(option.logo, 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
        <p>{ option[ displayParam ] }</p>
      </Option>
    ))

  const selectedValue = selected ? (
    <React.Fragment>
      <img src={selected.logo ? generateCroppedThumbnail(selected.logo, 200, 200, '1:1') : require('../../../../assets/images/avatar.jpg')} />
      <p>{ selected.name }</p>
    </React.Fragment>
  ) : undefined

  const onPersonSearch = (value) => {
    if (onSearch) { onSearch(value) }
  }

  const onBlur = () => {
    if (onSearch) { onSearch('') }
  }

  const onSelectOption = (id) => {
    if (onSearch) { onSearch('') }
    onOptionSelect(id)
  }

  return (

    <Select key={JSON.stringify(selected)}
      showSearch
      className='people-drop-down partner-drop-down'
      allowClear
      suffixIcon={<ExpandedIcon />}
      clearIcon={<CrossIcon />}
      placeholder='Select Partner'
      value={selectedValue || undefined}
      onChange={onSelectOption}
      optionFilterProp={displayParam}
      filterOption={(input, option) => customFilter(input, option, options)}
      getPopupContainer={() => document.getElementById(parentCompoent)} {...props}
      onSearch={onPersonSearch} onBlur={onBlur}>
      { optionComponents }
    </Select>

  )
}

PartnerDropDown.propTypes = {
  /** Display paramter value of Dropdown. */
  displayParam: PropTypes.string.isRequired,
  /** parent container id name of Dropdown. */
  parentCompoent: PropTypes.string,
  /** Selected value of Dropdown. */
  selected: PropTypes.object,
  /** Options of Dropdown. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func
}

PartnerDropDown.defaultProps = {
  parentCompoent: 'root'
}

export default PartnerDropDown
