import React from 'react'
import PropTypes from 'prop-types'

const SeekMarkOutIcon = ({ width, height, onClick, style, ...props }) => (
  <svg className='seek-icon markout' onClick={onClick} style={style} width='20' height='20' viewBox='0 0 20 20'>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 0H-4v20h20z' />
      <path fill='#FFF' fillRule='nonzero' d='M3.31 6c-.17 0-.308.188-.308.417H3V9H0v2h3v2.583h.002c0 .23.139.417.309.417.064 0 .117-.03.175-.07L7.83 10.48A.723.723 0 0 0 8 10a.727.727 0 0 0-.169-.481l-4.35-3.452A.272.272 0 0 0 3.311 6z' />
      <path stroke='#FFF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M7 19v-4l5.078-4.232a1 1 0 0 0 0-1.536L7 5V1' />
    </g>
  </svg>
)

SeekMarkOutIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SeekMarkOutIcon.defaultProps = {
  width: 20,
  height: 20
}

export default SeekMarkOutIcon
