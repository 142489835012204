import gql from 'graphql-tag'

export default gql(`
    mutation createOptaSyncState( $title: String  $lastRunTime: AWSDateTime $frequency: Int $opId: String $isActive: Boolean $isError: Boolean $isRunning: Boolean) {
        createOptaSyncState(
            input: {
                title: $title
                lastRunTime: $lastRunTime
                frequency: $frequency
                isActive: $isActive
                isError: $isError
                isRunning: $isRunning
                opId: $opId
            }
        ) {
            id
            title
            lastRunTime
            frequency
            opId
            isRunning
            isActive
            isError
        }
    }`
)
