import React from 'react'
import PropTypes from 'prop-types'

const InstagramIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='#AFAFAF' fillRule='nonzero' d='M26.602 25.352V12.695h-2.637c.26.82.39 1.673.39 2.559 0 1.64-.416 3.154-1.25 4.54a9.113 9.113 0 0 1-3.398 3.292c-1.432.807-2.995 1.21-4.687 1.21-2.566 0-4.76-.881-6.582-2.646-1.823-1.764-2.735-3.896-2.735-6.396 0-.886.13-1.738.39-2.559H3.34v12.657c0 .338.114.621.342.85.228.227.51.341.85.341H25.41c.326 0 .606-.114.84-.342.234-.228.352-.51.352-.85zm-5.547-10.41c0-1.615-.59-2.992-1.768-4.131-1.178-1.14-2.6-1.71-4.267-1.71-1.654 0-3.07.57-4.249 1.71-1.178 1.139-1.767 2.516-1.767 4.13 0 1.615.59 2.992 1.767 4.131 1.179 1.14 2.595 1.71 4.249 1.71 1.666 0 3.089-.57 4.267-1.71 1.178-1.14 1.768-2.516 1.768-4.13zm5.547-7.032V4.687c0-.364-.13-.68-.391-.947-.26-.267-.58-.4-.957-.4h-3.399c-.377 0-.696.133-.957.4-.26.267-.39.583-.39.947V7.91c0 .378.13.697.39.957s.58.39.957.39h3.399c.378 0 .697-.13.957-.39s.39-.58.39-.957zM30 3.848v22.304c0 1.055-.378 1.96-1.133 2.715-.755.755-1.66 1.133-2.715 1.133H3.848c-1.055 0-1.96-.378-2.715-1.133C.378 28.112 0 27.207 0 26.152V3.848c0-1.055.378-1.96 1.133-2.715C1.888.378 2.793 0 3.848 0h22.304c1.055 0 1.96.378 2.715 1.133.755.755 1.133 1.66 1.133 2.715z' />
  </svg>
)

InstagramIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

InstagramIcon.defaultProps = {
  width: 30,
  height: 30
}

export default InstagramIcon
