import gql from 'graphql-tag'

export default gql(`
    mutation adminSyncUser ( $userId: String! $project: String ) {
        adminSyncUser (
            userId: $userId
            project: $project
        ) {
            statusCode
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
