import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

// import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import './../../ui.style.scss'

const Option = Select.Option

const customFilter = (input, option, options) => {
  const currentOption = options.find(item => item.id === option.props.value)
  const returnValue = currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

class AttributeTypeDropDown extends React.Component {
    state = {
      isSearchPresent: false
    }

    onOptionSelect = (id) => {
      const selectedAttribute = this.props.attributes.find(item => item.id === id)
      this.props.onAttributeSelect(selectedAttribute, this.props.index)
    }

    // onAttributeSearch = (value) => {
    //   this.setState({ isSearchPresent: true })
    //   if (this.props.onSearch) { this.props.onSearch(value) }
    // }

    // onDropdownVisibleChange = (isOpen) => {
    //   if (!isOpen) { this.setState({ isSearchPresent: false }) }
    // }

    render () {
      const { attributes, selectedAttribute, parentCompoent, isDisabled } = this.props
      let optionComponents = attributes.map((option, index) => (
        <Option key={option.id} value={option.id}> { option.name}
        </Option>
      ))

      return (

        <div className='attribute-drop-down'>

          <Select
            // allowClear
            dropdownClassName='attribute-dropdown-popup'
            suffixIcon={<ExpandedIcon />}
            // clearIcon={<CrossIcon />}
            value={selectedAttribute || undefined}
            placeholder='Select Attribute Type'
            // showSearch
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onChange={this.onOptionSelect}
            // onSearch={this.onAttributeSearch}
            optionFilterProp={'name'} filterOption={(input, option) => customFilter(input, option, attributes)}
            // onDropdownVisibleChange={this.onDropdownVisibleChange}
            disabled={isDisabled}
          >
            { optionComponents }
          </Select>
        </div>
      )
    }
}
AttributeTypeDropDown.propTypes = {
  /** Option selection action of Dropdown. */
  onAttributeSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func,
  /** Selected value of Dropdown. */
  selectedAttribute: PropTypes.string,
  /** data for AttributeTypeDropDown. */
  attributes: PropTypes.array,
  /** parentcomponent for position */
  parentCompoent: PropTypes.string,
  /** search string for schemaSearch */
  attributeSearch: PropTypes.string
}

export default AttributeTypeDropDown
