import gql from 'graphql-tag'

export default gql(`
    query($competition: ID $assetType: String){
        getAssetTemplate( 
            competition: $competition
            assetType: $assetType
        ) {
            id
            assetType
            competition
            title
            shortTitle
            description
            seoTitle
            seoKeywords
            seoMetaDescription
            isLive
            isFree
            maxAssets
            minAssets
            publishEndDate{
                field
                operator
                offset
                offsetType
                publishGroup
                publishEndDate
            }
            category{
                id
                name
                slug
            }
            tags{
                name
                type
                module
            }
        }
}`)
