import React, { Component } from 'react'

import moment from 'moment'
import { Table, Skeleton } from 'antd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import PopoverButton from '../general/buttons/PopoverButton'
import ExpandMenuIcon from '../general/icons/ExpandMenuIcon'
import EditCompetitionModal from '../dataEntry/other/EditCompetitionModal'
import EditSeasonModal from '../dataEntry/other/EditSeasonModal'
import AddSeasonModal from '../dataEntry/other/AddSeasonModal'
import AddTeamModal from '../dataEntry/other/AddTeamModal'
import AddSquadModal from '../dataEntry/other/AddSquadModal'
import OptaTeamData from './OptaTeamData'
import QueryGetCompetitionDetails from '../../../graphQL/admin/getCompetition'
import QueryGetSeasonDetails from '../../../graphQL/admin/getSeason'

let timeOutVar = null

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class DataExpandTable extends Component {
  constructor (props) {
    super(props)
    this.columns = [
      {
        title: 'Competition',
        key: 'title',
        dataIndex: 'id',
        render: (id) => this.renderCompetitionTitle(id)
      },
      {
        key: 'competitionCode',
        dataIndex: 'id',
        render: (id) => this.renderCompetitionCode(id)
      },
      {
        key: 'id',
        dataIndex: 'id',
        render: (id) => this.renderData(id, 'competition')
      }
    ]
    this.state = {
      showOptions: false,
      openedOption: '',
      type: '',
      selectedCompetition: {},
      selectedSeason: {},
      selectedTeamRequest: {},
      selectedPlayerRequest: {},
      seasonIsLoading: props.seasonIsLoading,
      teamIsLoading: props.teamIsLoading,
      playerIsLoading: props.playerIsLoading,
      competitionIsLoading: props.competitionIsLoading,
      shouldShowPopup: false,
      height: null
    }
    this.selectedIds = []
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    window.addEventListener('resize', this.setHeight)
    this.setHeight()
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight = () => {
    if (this.tableSize) {
      clearTimeout(timeOutVar)
      timeOutVar = null
      this.setState({ height: this.tableSize.clientHeight })
    } else {
      clearTimeout(timeOutVar)
      this.timeOutVar = setTimeout(() => {
        this.setHeight()
      }, 2000)
    }
  }

  handleClickOutside = () => {
    if (this.state.shouldShowPopup) {
      setTimeout(() => this.setState({ shouldShowPopup: false }), 200)
    }
  }

  hideEditCompetitionModal = () => {
    this.setState({ editCompetition: false })
  }
  hideEditSeasonModal = () => {
    this.setState({ editSeason: false })
  }
  hideEditTeamModal = () => {
    this.setState({ editTeam: false })
  }

  hideEditSquadModal = () => {
    this.setState({ editSquad: false })
  }
  hideAddSeasonModal = () => {
    this.setState({ addSeason: false })
  }
  hideAddTeamModal = () => {
    this.setState({ addTeam: false })
  }
  hideAddSquadModal = () => {
    this.setState({ addSquad: false })
  }

  toggleShowOptions = (id, type, e) => {
    this.setState({ shouldShowPopup: false })
    e.preventDefault()
    e.stopPropagation()
    this.setState({ openedOption: id, type, shouldShowPopup: true })
  }

  onSelectOption = (id, selectedOption, filter) => {
    const { type } = this.state
    if (selectedOption.type === 'edit') {
      if (type === 'competition') {
        this.setState({ competitionIsLoading: true })
        if (this.props.getCompetitionDetails) {
          this.props.getCompetitionDetails({ id: id }).then((response) => {
            const selectedCompetition = response.data.getCompetition
            this.setState({ selectedCompetition, competitionIsLoading: false })
          })
        }
        this.setState({ editCompetition: true })
      } else if (type === 'season') {
        this.setState({ seasonIsLoading: true })
        if (this.props.getSeasonDetails) {
          this.props.getSeasonDetails({ id: id }).then((response) => {
            const selectedSeason = response.data.getSeason
            this.setState({ selectedSeason, seasonIsLoading: false })
          })
        }
        this.setState({ editSeason: true })
      } else if (type === 'team') {
        const selectedTeamRequest = { id: id, tournamentCalendar: filter.tournamentCalendar }
        this.setState({ editTeam: true, selectedTeamRequest })
      } else if (type === 'squad') {
        this.setState({ playerIsLoading: true })
        const selectedPlayerRequest = { id: id, team: filter.team, tournamentCalendar: filter.tournamentCalendar }
        this.setState({ selectedPlayerRequest, editSquad: true })
      }
    } else if (selectedOption.type === 'addSeason') {
      this.setState({ addSeason: true })
    } else if (selectedOption.type === 'addTeam') {
      this.setState({ addTeam: true })
    } else if (selectedOption.type === 'addSquad') {
      this.setState({ addSquad: true })
    }
    this.setState({ openedOption: undefined })
  }

  getSelectedOptaIds = (listIds) => {
    listIds.map(id => {
      const isSelected = (this.selectedIds || []).find(item => item === id)
      if (isSelected) {

      } else {
        this.selectedIds.push(id)
      }
    })
    this.props.optaHistoryId(this.selectedIds)
  }

  getSelectedCompetitionTitle =(status) => {
    const { selectedCompetition } = this.state
    if (!status) {
      return selectedCompetition.title
    } else {
      return <Skeleton active avatar={false} title paragraph={false}
        loading={status} />
    }
  }

  getTeamData = (competition, clickedSeason) => {
    const selectedList = competition.find(item => item.tournamentCalendar && item.tournamentCalendar.length ? item.tournamentCalendar.find(season => season.id === clickedSeason.id) : null)
    const teamFilterData = {
      compName: selectedList.title,
      compId: selectedList.id,
      competitionCode: selectedList.competitionCode,
      seasonName: clickedSeason.title,
      tournamentCalendar: clickedSeason.id,
      tournamentCalendarExternalId: clickedSeason.externalId,
      sport: this.props.selectedSport
    }
    let selectedIds = [teamFilterData.compId, teamFilterData.tournamentCalendar]
    this.getSelectedOptaIds(selectedIds)
    return teamFilterData
  }

  getSeasonTitle = (status) => {
    const { selectedSeason } = this.state
    const { competitions } = this.props
    if (!status) {
      const competition = competitions.find(item => item.tournamentCalendar && item.tournamentCalendar.length ? item.tournamentCalendar.find(season => season.id === selectedSeason.id) : null)
      const seasonTitle = {
        compName: competition ? competition.title : '',
        seasonName: selectedSeason ? selectedSeason.title : ''
      }
      return `${seasonTitle.compName} - ${seasonTitle.seasonName}`
    } else {
      return <Skeleton active avatar={false} title paragraph={false}
        loading={status} />
    }
  }

  renderData = (id, type, filter) => {
    let availableOptions = _.cloneDeep(options)
    const { isUpdateDisabled } = this.props
    const { openedOption, shouldShowPopup } = this.state
    return (
      <div className={`${!isUpdateDisabled ? 'option-list' : 'option-list remove'}`} id='option-list'>
        <PopoverButton
          button={<div onClick={(e) => this.toggleShowOptions(id, type, e)} > <ExpandMenuIcon /> </div>}
          displayParam='name'
          contents={availableOptions}
          onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption, filter)}
          parentCompoent={'option-list'}
          isVisible={openedOption === id && shouldShowPopup}
          placement={'leftBottom'}
        />
      </div>
    )
  }

  renderCompetitionTitle = (id) => {
    const { competitions } = this.props
    let competition = {}
    competition = competitions.find(item => item.id === id)
    return competition.customTitle ? competition.customTitle : competition.title
  }

  renderCompetitionCode = (id) => {
    const { competitions } = this.props
    let competition = {}
    competition = competitions.find(item => item.id === id)
    return competition.customCompetitionCode ? competition.customCompetitionCode : competition.competitionCode
  }

  renderSeasonDate = (id) => {
    const { competitions } = this.props
    let season = {}
    for (let i = 0; i < competitions.length; i++) {
      if (competitions[i].tournamentCalendar) {
        season = competitions[i].tournamentCalendar.find(item => item.id === id)
      }
      if (!_.isEmpty(season)) {
        break
      }
    }
    return season && season.startDate
      ? `${moment(new Date(season.customStartDate ? season.customStartDate : season.startDate)).format('DD MMM, YYYY')} - ${season.startDate
        ? moment(new Date(season.customEndDate ? season.customEndDate : season.endDate)).format('DD MMM, YYYY') : null} `
      : ' - '
  }

  renderSeasonTitle = (id) => {
    const { competitions } = this.props
    let season = {}
    for (let i = 0; i < competitions.length; i++) {
      if (competitions[i].tournamentCalendar) {
        season = competitions[i].tournamentCalendar.find(item => item.id === id)
      }
      if (!_.isEmpty(season)) {
        break
      }
    }
    return season.customTitle ? season.customTitle : season.title
  }

  getRenderedColumn = data => {
    const columns = [
      {
        dataIndex: 'id',
        key: 'title',
        render: (id) => this.renderSeasonTitle(id)
      },
      {
        key: 'date',
        dataIndex: 'id',
        render: (id) => this.renderSeasonDate(id)
      },
      {
        key: 'id',
        dataIndex: 'id',
        render: (id) => this.renderData(id, 'season')
      }
    ]

    const seasons = _.cloneDeep(data.tournamentCalendar)
    return (
      <Table
        rowKey={record => record.id}
        columns={columns} dataSource={seasons}
        pagination={false}
        expandedRowRender={this.getRenderedSecondColumn}
        showHeader={false}
      />
    )
  }

  getRenderedSecondColumn =(Season) => {
    const { editTeam, selectedTeamRequest, selectedPlayerRequest, editSquad, teamIsLoading, playerIsLoading } = this.state
    const { competitions, onHistoryUpdate } = this.props
    const teamFilter = this.getTeamData(competitions, Season)
    return (
      <OptaTeamData teamFilter={teamFilter} renderData={this.renderData} editTeam={editTeam} selectedTeamRequest={selectedTeamRequest}
        hideEditTeamModal={this.hideEditTeamModal} selectedPlayerRequest={selectedPlayerRequest} hideEditSquadModal={this.hideEditSquadModal} editSquad={editSquad}
        getPlayerFilter={this.getPlayerFilter} teamIsLoading={teamIsLoading} playerIsLoading={playerIsLoading} onHistoryUpdate={onHistoryUpdate} getSelectedOptaIds={this.getSelectedOptaIds} />
    )
  }

  render () {
    const columnData = this.getRenderedColumn
    const { editCompetition, editSeason, addTeam, addSeason, addSquad, selectedCompetition, selectedSeason, seasonIsLoading, competitionIsLoading, height } = this.state
    const { competitions, loading, onHistoryUpdate, project } = this.props
    return (
      <div className='data-expand-table' id='data-expand-table' ref={(ref) => { this.tableSize = ref }}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
          loading={loading}>
          <Table
            rowKey={record => record.id}
            columns={this.columns}
            dataSource={competitions}
            pagination={false}
            expandedRowRender={columnData}
            scroll={{ x: true, y: (height - 37) }}
            title={() => 'Competition'}
            showHeader={false}
          />
        </Skeleton>
        { editCompetition && selectedCompetition ? <EditCompetitionModal visible={editCompetition && selectedCompetition} handleSubmit={() => { }}
          handleCancel={this.hideEditCompetitionModal}
          onHistoryUpdate={onHistoryUpdate}
          title={this.getSelectedCompetitionTitle(competitionIsLoading)}
          selectedCompetition={selectedCompetition} competitionIsLoading={competitionIsLoading} /> : null }
        { editSeason ? <EditSeasonModal visible={editSeason} handleSubmit={() => { }} handleCancel={this.hideEditSeasonModal} onHistoryUpdate={onHistoryUpdate}
          title={this.getSeasonTitle(seasonIsLoading)} selectedSeason={selectedSeason} seasonIsLoading={seasonIsLoading} /> : null }
        { addSeason ? <AddSeasonModal visible={addSeason} handleSubmit={() => { }} handleCancel={this.hideAddSeasonModal} loading={false} project={project}
          title={'SeasonName'} okText={'Save'} /> : null }
        { addTeam ? <AddTeamModal visible={addTeam} handleSubmit={() => { }} handleCancel={this.hideAddTeamModal} loading={false} project={project}
          title={'TeamName'} okText={'Save'} /> : null }
        { addSquad ? <AddSquadModal visible={addSquad} handleSubmit={() => { }} handleCancel={this.hideAddSquadModal} loading={false} project={project}
          title={'squadName'} okText={'Save'} /> : null }
      </div>
    )
  }
}

DataExpandTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryGetCompetitionDetails,
    {
      options: () => {
        return {
          variables: { id: null },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          selectedCompetition: props.data.getSeason,
          competitionIsLoading: props.data.loading,
          getCompetitionDetails: (variables) => {
            return props.data.refetch(variables)
          }
        }
      }
    }
  ),
  graphql(
    QueryGetSeasonDetails,
    {
      options: () => {
        return {
          variables: { id: null },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        return {
          selectedSeason: props.data.getSeason,
          seasonIsLoading: props.data.loading,
          getSeasonDetails: (variables) => {
            return props.data.refetch(variables)
          }
        }
      }
    }
  )
)(DataExpandTable))
