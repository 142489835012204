import React from 'react'
import PropTypes from 'prop-types'

const SportFilterIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <defs>
      <circle id='ge4gn7t32a' cx='4.727' cy='4.727' r='4.727' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='x8lqwjedlb' fill='#fff'>
        <use xlinkHref='#ge4gn7t32a' />
      </mask>
      <circle cx='4.727' cy='4.727' r='4.377' stroke={color} strokeWidth='.7' />
      <g stroke={color} strokeWidth='.5' mask='url(#x8lqwjedlb)'>
        <g>
          <path d='M6.064 4.808l-.825 1.65H3.65l-.824-1.65.824-1.649h1.59l.825 1.65z' transform='translate(-.646 -1.535)' />
          <path d='M8.569 6.263l-.825 1.649h-1.59l-.824-1.65.825-1.648h1.59l.824 1.649z' transform='translate(-.646 -1.535)' />
          <path d='M8.69 3.232l-.824 1.65h-1.59l-.825-1.65.825-1.649h1.59l.824 1.65z' transform='translate(-.646 -1.535)' />
          <path d='M11.114 4.808l-.824 1.65H8.7l-.825-1.65L8.7 3.16h1.59l.824 1.65z' transform='translate(-.646 -1.535)' />
          <path d='M11.114 7.758l-.824 1.649H8.7l-.825-1.65L8.7 6.11h1.59l.824 1.649z' transform='translate(-.646 -1.535)' />
          <path d='M8.69 9.253l-.824 1.649h-1.59l-.825-1.65.825-1.648h1.59l.824 1.649z' transform='translate(-.646 -1.535)' />
          <path d='M6.145 10.626l-.825 1.65H3.73l-.824-1.65.824-1.649h1.59l.825 1.65zM3.518 9.172l-.824 1.649h-1.59L.28 9.17l.824-1.648h1.59l.824 1.649z' transform='translate(-.646 -1.535)' />
          <path d='M3.6 6.263l-.825 1.649h-1.59L.36 6.262l.825-1.648h1.59l.824 1.649z' transform='translate(-.646 -1.535)' />
          <path d='M3.6 3.232l-.825 1.65h-1.59L.36 3.231l.825-1.649h1.59l.824 1.65z' transform='translate(-.646 -1.535)' />
          <path d='M6.145 1.899L5.32 3.548H3.73l-.824-1.649L3.73.25h1.59l.825 1.649zM6.064 7.758l-.825 1.649H3.65l-.824-1.65.824-1.648h1.59l.825 1.649z' transform='translate(-.646 -1.535)' />
        </g>
      </g>
      <g stroke={color} transform='translate(3 3)'>
        <circle cx='8.122' cy='8.122' r='7.591' fill='#EEE' strokeWidth='1.063' />
        <path strokeWidth='1.003' d='M6.01.892c.688 3.318 2.284 5.133 4.788 5.446 2.066.423 4.414.986 4.93 1.784M.939 5.963c2.973.626 4.789 2.144 5.446 4.554.376 1.596.798 4.272 1.737 5.164M2.77 2.911L13.615 13.427' />
      </g>
    </g>
  </svg>
)

SportFilterIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

SportFilterIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#343A40'
}

export default SportFilterIcon
