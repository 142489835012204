import React from 'react'
import PropTypes from 'prop-types'
import Embedo from 'embedo'
import './../../ui.style.scss'

const isNotProd = (window.location.href.includes('local') || window.location.href.includes('dev') || location.href.includes('qa') || location.href.includes('uat'))

const accessToken = isNotProd ? 'fb4b1e9203a607f5e9d9d49c666ee200' : 'c412adc0d14322981dc9a3c04c0a53b8'
const appId = isNotProd ? '180015362652616' : '1581556128573768'

const embedo = new Embedo({
  twitter: true,
  instagram: {
    access_token: `${appId}|${accessToken}`
  },
  pinterest: true,
  facebook: {
    appId,
    version: 'v3.2',
    xfbml: true,
    access_token: `${appId}|${accessToken}`
  },
  youtube: true
})

class SocialMedia extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount = () => {
    const options = this.props.dataOptions || {}
    const width = this.props.dataWidth
    const height = this.props.dataHeight

    if (width) {
      options.width = width
    }

    if (height) {
      options.height = '400'
    }
    // options.width = '310'

    this.getSocialFeedData(options, this.props.href)
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.href === this.props.href) {
      return
    }
    this.container.innerHTML = ''
    this.setState({
      loaded: false
    })
    const options = newProps.dataOptions || {}
    const width = newProps.dataWidth
    const height = newProps.dataHeight

    if (width) {
      options.width = width
    }

    if (height) {
      options.height = '400'
    }
    // options.width = '310'

    this.getSocialFeedData(options, newProps.href)
  }

  getSocialFeedData = (options, url) => {
    embedo
      .load(this.container, url, options)
      .done((data) => {
        this.props.onUrlValidation(true)
        this.setState({
          loaded: true
        })
      })
      .fail(() => {
        this.props.onUrlValidation(false)
      })
  }

  render () {
    return (
      <a
        className='social-media-item'
        href={this.props.href}
        target='_blank'
        rel='noopener noreferrer'
        ref={c => (this.container = c)}
      />
    )
  }
}

SocialMedia.propTypes = {
  /** optional settings of SocialMedia. */
  dataOptions: PropTypes.object,
  /** social media post url of SocialMedia. */
  href: PropTypes.string,
  /** width of SocialMedia. */
  dataWidth: PropTypes.string.isRequired,
  /** width of SocialMedia. */
  dataHeight: PropTypes.string,
  /** change validity status of SocialMedia. */
  onUrlValidation: PropTypes.func
}

SocialMedia.defaultProps = {
}

export default SocialMedia
