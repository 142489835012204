import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $project: String) {
        deleteAssetCategory(
            input: {
                id: $id
            }
            project: $project
        ) {
            id
            name
            slug
            assetCount
        }
    }`
)
