import * as React from 'react'
import FilterInput from './../../components/ui/dataEntry/inputs/FilterInput'
import SettingsIcon from './../../components/ui/general/icons/SettingsIcon'
import { ChannelListTable } from './ChannelListTable'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import { PropTypes } from 'prop-types'
import { Empty, Button } from 'antd'
import AppContext from '../../AppContext'

export default class ChannelList extends React.Component {
  constructor (props) {
    super(props)
    this.channelListRef = React.createRef()
    this.state = {
      tableScrollHeight: 832,
      shouldShowWarning: false,
      isLoading: false,
      searchFilter: ''
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (this.state.isLoading) {
      this.setState({
        isLoading: nextProps.isMasterEnabled,
        shouldShowWarning: false
      })
    }
  }

  addChannel = () => {
    if (this.props.addChannel) { this.props.addChannel() }
  }

  selectChannel = (selectData, selectedRowCount) => {
    if (this.props.selectedChannelData) { this.props.selectedChannelData(selectData, selectedRowCount) }
  }

  updateDimensions = () => {
    const tableScrollHeight = this.channelListRef.current.clientHeight - 120
    this.setState({
      tableScrollHeight
    })
  }

  onEnableMasterConfig = () => {
    if (!this.props.isMasterEnabled && this.props.onEnableMasterConfig) { this.props.onEnableMasterConfig() } else {
      this.setState({ shouldShowWarning: true })
    }
  }

  onCancelMasterConfig = () => {
    this.setState({ shouldShowWarning: false })
  }

  onSubmitMasterConfig = () => {
    this.setState({ isLoading: true },
      this.props.onDisableMasterConfig()
    )
  }

  onSelectChannel = (id) => {
    this.props.onSelectChannel(id)
  }

  onChangeFilter = (searchVal) => {
    this.props.onSearchChannel(searchVal)
  }

  changeFilter = (filter, id) => {
    if (this.props.onChangeFilter) { this.props.onChangeFilter(filter, id) }
  }

  onSearchFilter = (searchFilter) => {
    this.setState({ searchFilter })

    this.onChangeFilter(searchFilter)
  }
  render () {
    return <AppContext.Consumer>
      {({ permissions }) => {
        const userPermissions = permissions['CHANNEL_MANAGER']
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
        return <React.Fragment>
          <div className='channel-list' ref={this.channelListRef}>
            <div className='channel-list-header'>
              <div className='filter'>
                <FilterInput
                  onChangeFilter={this.onChangeFilter}
                  onChangeSearchInput={this.onSearchFilter}
                  searchString={this.props.searchString}
                  filterType={'ChannelManager'}
                  filterSearch={this.state.searchFilter}
                  isClearFilter={this.props.isClearFilter}
                  changeFilter={this.changeFilter}
                  parentId='channel-manager'
                /></div>
              <div className={`enable-button${this.props.isMasterEnabled ? ' enable' : ''}${isUpdateDisabled ? ' disabled' : ''}`} onClick={this.onEnableMasterConfig}>
                <span>{` ${this.props.isMasterEnabled ? 'Disable' : 'Enable'} Master Config`}</span>
                <div className='settings-icon'><SettingsIcon /></div>
              </div>
            </div>
            <div className='channel-table' style={{ height: this.state.tableScrollHeight + 40 }}>
              {this.props.isLoading ? '' : <div className={`shadow ${this.props.channelList && this.props.channelList.length === 0 && !this.props.isLoading ? 'empty' : 'not-empty'}`} >
                {this.props.channelList && this.props.channelList.length === 0 && !this.props.isLoading ? <Empty /> : ''}
              </div>}
              <ChannelListTable data={this.props.channelList} tableHeight={this.state.tableScrollHeight} selectedChannel={this.selectChannel}
                selectedId={this.props.selectedId} onSelectChannel={this.onSelectChannel} isLoading={this.props.isLoading} />
            </div>
          </div>
          <div className={`channel-list-footer`}>
            <Button className={`create-channel-button${isCreateDisabled ? ' disabled' : ''}`} onClick={this.addChannel} disabled={this.props.isLoading}>Create Channel</Button>
          </div>
          <ConfirmModal
            isVisible={this.state.shouldShowWarning}
            title={'Disable MasterConfig'}
            message={'Do you want to disable master config ?'}
            isLeftPrior={false}
            leftButtonText={'Cancel'}
            rightButtonText={'Confirm'}
            handleSubmit={this.onSubmitMasterConfig}
            handleCancel={this.onCancelMasterConfig}
            isSubmitButtonDisabled={false}
            isCancelButtonDisabled={false}
            isLoading={this.state.isLoading}
          />
        </React.Fragment>
      }}
    </AppContext.Consumer>
  }
}

ChannelList.propTypes = {
  selectedChannelData: PropTypes.func,
  addChannel: PropTypes.func,
  onEnableMasterConfig: PropTypes.func,
  onDisableMasterConfig: PropTypes.func,
  channelList: PropTypes.any,
  selectedId: PropTypes.string,
  onSelectChannel: PropTypes.func,
  onSearchChannel: PropTypes.func,
  isLoading: PropTypes.bool,
  isMasterEnabled: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  isClearFilter: PropTypes.bool
}
