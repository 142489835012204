import gql from 'graphql-tag'

export default gql(`
query listUserDevices( $userId: ID! $project: String) {
    listUserDevices( 
        userId: $userId
        project: $project
    ) {
        id
        appVersion
        browserName
        browserVersion
        lastIPAddress
        lastIPLocation
        model
        name
        osVersion
        platformType
        updatedAt
        createdAt
    }
}`)
