import React, { Component } from 'react'
import { Empty, Skeleton, message } from 'antd'
import moment from 'moment'

import Accordion from './../../components/ui/dataDisplay/Accordion'
import EditCustomerProfileModal from './../../components/ui/dataEntry/other/EditCustomerProfileModal'
import EditCustomerProfileModalHyperion from './../../components/ui/dataEntry/other/EditCustomerProfileModalHyperion'
import ChangeCustomerPasswordModal from './../../components/ui/dataEntry/other/ChangeCustomerPasswordModal'
import CreateSubscriptionModal from './../../components/ui/dataEntry/other/CreateSubscriptionModal'
import ExpandMenuIcon from './../../components/ui//general/icons/ExpandMenuIcon'
import PopoverButton from './../../components/ui//general/buttons/PopoverButton'
import CustomerAccountIcon from '../../components/ui/general/icons/CustomerAccountIcon'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import SidebarOpenButton from '../../components/ui/general/buttons/SidebarOpenButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import ChangeCustomerEmailModal from '../../components/ui/dataEntry/other/ChangCustomerEmailModal'
import CustomerAntiPiracyActionModal from '../../components/ui/dataEntry/other/CustomerAntiPiracyActionModal'

import { utilityService } from '../../services/UtilityService'
import './CustomerServiceManager.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCustomerProfile from './../../graphQL/customers/getCustomerProfile'
import MutationUpdateCustomer from './../../graphQL/customers/updateCustomerProfile'
import MutationCreateSubscription from './../../graphQL/customers/createSubscription'
import MutationUpdateCustomerPassword from './../../graphQL/customers/changeCusomerPassword'
import MutationResendEmail from './../../graphQL/customers/resendEmail'
import MutationLogoutAllDevices from './../../graphQL/customers/logoutAllDevices'
import MutationDisableUser from './../../graphQL/customers/disableUser'
import MutationEnableUser from './../../graphQL/customers/enableUser'
import QueryGetUserAccountStatus from '../../graphQL/customers/getUserAccountStatus'
import MutationAdminUpdateEmail from '../../graphQL/customers/adminUpdateEmail '
import MutationAdminSyncUser from '../../graphQL/customers/adminSyncUser'
import QueryyListAllAntiPiracyActions from './../../graphQL/customers/listAllAntiPiracyActions'
import MutationSendAntiPiracyAction from '../../graphQL/customers/sendAntiPiracyAction'

const middlelayerError = 'Received null response, please check with backend team'

const options = [
  {
    type: 'editCustomerProfile',
    name: 'Edit Customer Profile'
  },
  {
    type: 'changeUserPassword',
    name: 'Change User Password'
  },
  {
    type: 'createSubscription',
    name: 'Create Subscription'
  },
  {
    type: 'sendResetPasswordEmail',
    name: 'Send Reset Password Email'
  },
  {
    type: 'logoutAllDevices',
    name: 'Logout All Devices'
  },
  {
    type: 'disableUser',
    name: 'Disable User'
  },
  {
    type: 'antiPiracy',
    name: 'Anti-Piracy'
  }
]

const enableOptions = [
  {
    type: 'editCustomerProfile',
    name: 'Edit Customer Profile'
  },
  {
    type: 'changeUserPassword',
    name: 'Change User Password'
  },
  {
    type: 'createSubscription',
    name: 'Create Subscription'
  },
  {
    type: 'sendResetPasswordEmail',
    name: 'Send Reset Password Email'
  },
  {
    type: 'logoutAllDevices',
    name: 'Logout All Devices'
  },
  {
    type: 'enableUser',
    name: 'Enable User'
  },
  {
    type: 'antiPiracy',
    name: 'Anti-Piracy'
  }
]

const optionsHyperion = [
  {
    type: 'editCustomer',
    name: 'Edit Customer'
  },
  {
    type: 'enableCustomer',
    name: 'Enable Customer'
  },
  {
    type: 'disableCustomer',
    name: 'Disable Customer'
  },
  {
    type: 'logoutAllDevices',
    name: 'Log Out All Devices'
  },
  {
    type: 'syncUser',
    name: 'Sync User'
  }
]

const optionsProjectx = [
  {
    type: 'editCustomer',
    name: 'Edit Customer'
  },
  {
    type: 'enableCustomer',
    name: 'Enable Customer'
  },
  {
    type: 'disableCustomer',
    name: 'Disable Customer'
  },
  {
    type: 'logoutAllDevices',
    name: 'Log Out All Devices'
  }
]
const width = ['50%', '100%', '100%', '100%', '100%', '100%']

class CustomerProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shouldShowPopup: false,
      isEditCustomerVisible: false,
      isChangePasswordVisible: false,
      isCreateSubscriptionVisible: false,
      isSendEmailVisible: false,
      islogoutAlllVisible: false,
      isDisableUserVisible: false,
      isEnableUserVisible: false,
      isModalLoading: false,
      isChangeEmailVisible: false,
      isEmailModalLoading: false,
      isSyncUserVisible: false,
      isAntiPiracyVisible: false
    }
    this.editModalContainer = null
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
    if (!newProps.refetch && this.props.refetch) {
      if (newProps.getCustomerData) {
        newProps.getCustomerData()
        setTimeout(newProps.getCustomerData, 1000)
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (!(this.editModalContainer && this.editModalContainer.contains(event.target))) {
      this.setState({ shouldShowPopup: false })
      this.props.changeProfileOptionStatus(false)
    }
  }

  toggleShowOptions = () => {
    this.setState({ shouldShowPopup: !this.state.shouldShowPopup }, () => this.props.changeProfileOptionStatus(this.state.shouldShowPopup))
  }

  selectOption = ({ type }) => {
    const { project } = this.props
    if (project === 'hyperion' || project === 'projectx') {
      if (type === 'editCustomer') {
        this.setState({ isEditCustomerVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'enableCustomer') {
        this.setState({ isEnableUserVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'disableCustomer') {
        this.setState({ isDisableUserVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'logoutAllDevices') {
        this.setState({ islogoutAlllVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (project === 'hyperion' && type === 'syncUser') {
        this.setState({ isSyncUserVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      }
    } else {
      if (type === 'editCustomerProfile') {
        this.setState({ isEditCustomerVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'changeUserPassword') {
        this.setState({ isChangePasswordVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'createSubscription') {
        this.setState({ isCreateSubscriptionVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'sendResetPasswordEmail') {
        this.setState({ isSendEmailVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'logoutAllDevices') {
        this.setState({ islogoutAlllVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'disableUser') {
        this.setState({ isDisableUserVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'updateEmail') {
        this.setState({ isChangeEmailVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'enableUser') {
        this.setState({ isEnableUserVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      } else if (type === 'antiPiracy') {
        this.setState({ isAntiPiracyVisible: true, shouldShowPopup: false }, () => this.props.changeProfileOptionStatus(false))
      }
    }
  }

  onEditCustomer = (data) => {
    const { project } = this.props
    data.project = project
    this.setState({ isModalLoading: true })
    this.props.updateCustomerProfile(data).then((response) => {
      if (!response.data.adminUpdateProfile) {
        this.setState({ isModalLoading: false })
        utilityService.handleError(middlelayerError)
        return
      }
      this.setState({ isEditCustomerVisible: false, isModalLoading: false })
      this.props.profileUpdated()
      message.success('Customer profile updated successfully')
    }, error => {
      this.setState({ isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onChangePassword = (password) => {
    const { customerProfile } = this.props
    this.setState({ isModalLoading: true })
    const variables = {
      password,
      email: customerProfile.email
    }
    this.props.updateCustomerPassword(variables).then(({ data }) => {
      this.setState({ isChangePasswordVisible: false, isModalLoading: false })
      if (!data.adminChangePassword || (data.adminChangePassword && data.adminChangePassword.error && data.adminChangePassword.error.message)) {
        this.props.profileUpdated()
        const errorMessage = !data.adminChangePassword ? middlelayerError : data.adminChangePassword.error.message
        message.error(errorMessage)
      } else {
        this.props.profileUpdated()
        const successMessage = data.adminChangePassword && data.adminChangePassword.success && data.adminChangePassword.success.message ? data.adminChangePassword.success.message : 'Password changed successfully'
        message.success(successMessage)
      }
    }, error => {
      this.setState({ isChangePasswordVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onSendAntiPiracyAction = (input) => {
    const { customerProfile, onRefetchData } = this.props
    this.setState({ isModalLoading: true })
    const variables = {
      actionKey: input.actionKey,
      operatorNote: input.operatorNote,
      userId: customerProfile.email
    }
    this.setState({ isModalLoading: true })
    this.props.sendAntiPiracyAction(variables).then((response) => {
      if (!response.data.sendAntiPiracyAction) {
        this.setState({ isModalLoading: false })
        utilityService.handleError(middlelayerError)
        return
      }
      this.setState({ isAntiPiracyVisible: false, isModalLoading: false })
      this.props.profileUpdated()
      message.success('Anti-Piracy Action Added successfully')
      onRefetchData()
    }, error => {
      this.setState({ isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onCreateSubscription= (data) => {
    this.setState({ isModalLoading: true })
    this.props.createSubscription(data).then(() => {
      this.setState({ isCreateSubscriptionVisible: false, isModalLoading: false })
      this.props.profileUpdated()
      message.success('Created Subscription Successfully')
    }, error => {
      this.setState({ isCreateSubscriptionVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onConfirmSendEmail = () => {
    const { customerProfile } = this.props
    this.setState({ isModalLoading: true })
    const variables = {
      email: customerProfile.email
    }
    this.props.resendEmail(variables).then(({ data }) => {
      this.setState({ isSendEmailVisible: false, isModalLoading: false })
      this.props.profileUpdated()
      if (data && data.adminForgotPassword && data.adminForgotPassword.error && data.adminForgotPassword.error.message) {
        message.error(data.adminForgotPassword.error.message)
      } else {
        message.success('Email send successfully')
      }
    }, error => {
      this.setState({ isSendEmailVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onConfirmLogoutAll = () => {
    const { customerProfile, project } = this.props
    this.setState({ isModalLoading: true })
    this.setState({ isModalLoading: true })
    const variables = {
      email: customerProfile.email,
      project
    }
    if (project === 'hyperion' || project === 'projectx') {
      variables.userId = customerProfile.id
    }
    this.props.onLogoutAllDevice(variables).then(({ data }) => {
      this.setState({ islogoutAlllVisible: false, isModalLoading: false })
      if (data.adminLogout && data.adminLogout.error && data.adminLogout.error.message) {
        const errorMessage = data.adminLogout.error.message
        this.props.profileUpdated()
        message.error(errorMessage)
      } else if (data.adminLogout && data.adminLogout.success && data.adminLogout.success.message) {
        const successMessage = data.adminLogout && data.adminLogout.success && data.adminLogout.success.message ? data.adminLogout.success.message : 'Successfully logout'
        this.props.profileUpdated()
        message.success(successMessage)
      } else {
        this.setState({ islogoutAlllVisible: false, isModalLoading: false })
        utilityService.handleError(middlelayerError)
      }
    }, error => {
      this.setState({ islogoutAlllVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onConfirmDisableUser = () => {
    const { customerProfile, project } = this.props
    this.setState({ isModalLoading: true })
    const variables = {
      email: customerProfile.email,
      project
    }
    if (project === 'hyperion' || project === 'projectx') {
      variables.userId = customerProfile.id
    }
    this.props.onDisableUser(variables).then(({ data }) => {
      this.setState({ isDisableUserVisible: false, isModalLoading: false })
      if (this.props.fetchCustomerStatus) {
        this.props.fetchCustomerStatus()
      }
      if (data && data.adminDisableUser && data.adminDisableUser.error && data.adminDisableUser.error.message) {
        const errorMessage = data.adminDisableUser.error.message
        this.props.profileUpdated()
        utilityService.handleError(errorMessage)
      } else if (data.adminDisableUser && data.adminDisableUser.success && data.adminDisableUser.success.message) {
        const successMessage = data.adminDisableUser && data.adminDisableUser.success && data.adminDisableUser.success.message ? data.adminDisableUser.success.message : 'User Disabled Successfully'
        this.props.profileUpdated()
        message.success(successMessage)
      } else {
        this.setState({ isDisableUserVisible: false, isModalLoading: false })
        utilityService.handleError(middlelayerError)
      }
    }, error => {
      this.setState({ isDisableUserVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onConfirmEnableUser = () => {
    this.setState({ isModalLoading: true })
    const { customerProfile, project } = this.props
    const variables = {
      email: customerProfile.email,
      project: project
    }

    if (project === 'hyperion' || project === 'projectx') {
      variables.userId = customerProfile.id
    }
    this.props.onEnableUser(variables).then(({ data }) => {
      this.setState({ isEnableUserVisible: false, isModalLoading: false })
      if (this.props.fetchCustomerStatus) {
        this.props.fetchCustomerStatus()
      }
      if (data && !data.adminEnableUser) {
        this.setState({ isEnableUserVisible: false, isModalLoading: false })
        utilityService.handleError(middlelayerError)
        return
      }
      if (data && data.adminEnableUser && data.adminEnableUser.error && data.adminEnableUser.error.message) {
        const errorMessage = data.adminEnableUser.error.message
        this.props.profileUpdated()
        utilityService.handleError(errorMessage)
      } else if (data.adminEnableUser && data.adminEnableUser.success && data.adminEnableUser.success.message) {
        const successMessage = data.adminEnableUser.success.message ? data.adminEnableUser.success.message : 'User Enabled Successfully'
        this.props.profileUpdated()
        message.success(successMessage)
      } else {
        this.setState({ isEnableUserVisible: false, isModalLoading: false })
        utilityService.handleError(middlelayerError)
      }
    }, error => {
      this.setState({ isEnableUserVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onConfirmSyncUser = () => {
    this.setState({ isModalLoading: true })
    const { customerProfile, project } = this.props
    const variables = {
      project: project
    }

    if (project === 'hyperion') {
      variables.userId = customerProfile.id || null
    }
    if (project === 'projectx') {
      message.error('This option is not available for this project')
      return
    }
    this.props.onSyncUser(variables).then(({ data }) => {
      this.setState({ isSyncUserVisible: false, isModalLoading: false })
      if (this.props.fetchCustomerStatus) {
        this.props.fetchCustomerStatus()
      }
      if (data && data.adminSyncUser) {
        this.setState({ isEnableUserVisible: false, isModalLoading: false })
        // utilityService.handleError(middlelayerError)
        if (data.adminSyncUser.statusCode === 200 && data.adminSyncUser.success) {
          message.success(data.adminSyncUser.success.message)
        } else if (data.adminSyncUser.statusCode !== 200 && data.adminSyncUser.error) {
          message.error(data.adminSyncUser.error.message)
        }
      }
    }, error => {
      this.setState({ isSyncUserVisible: false, isModalLoading: false })
      utilityService.handleError(error)
    })
  }

  onCloseCustomerEdit = () => {
    this.setState({ isEditCustomerVisible: false })
  }

  onCloseCustomerPasswordChange = () => {
    this.setState({ isChangePasswordVisible: false })
  }

  onCloseCreateSubscription = () => {
    this.setState({ isCreateSubscriptionVisible: false })
  }

  onCloseEmailConfirmation = () => {
    this.setState({ isSendEmailVisible: false })
  }

  onCloseLogoutAllConfirmation = () => {
    this.setState({ islogoutAlllVisible: false })
  }

  onDisableUserConfirmation = () => {
    this.setState({ isDisableUserVisible: false })
  }

  onEnableUserConfirmation = () => {
    this.setState({ isEnableUserVisible: false })
  }

  onSyncUserCancel=() => {
    this.setState({ isSyncUserVisible: false })
  }

  onCloseCustomerEmailChange = () => {
    this.setState({ isChangeEmailVisible: false })
  }

  onChangeEmail = (email) => {
    const { customerProfile } = this.props
    this.setState({ isEmailModalLoading: true })
    const variables = {
      oldEmail: customerProfile.email,
      newEmail: email
    }
    this.props.updateCustomerEmail(variables).then(({ data }) => {
      this.setState({ isChangeEmailVisible: false, isEmailModalLoading: false })
      if (!data.adminUpdateEmail || (data.adminUpdateEmail && data.adminUpdateEmail.error && data.adminUpdateEmail.error.message)) {
        this.props.profileUpdated()
        const errorMessage = !data.adminUpdateEmail ? middlelayerError : data.adminUpdateEmail.error.message
        message.error(errorMessage)
      } else {
        this.props.profileUpdated()
        setTimeout(() => {
          this.props.onChangeEmailChange(email)
        }, 800)
        const successMessage = data.adminUpdateEmail && data.adminUpdateEmail.success && data.adminUpdateEmail.success.message ? data.adminUpdateEmail.success.message : 'Email updated successfully'
        message.success(successMessage)
      }
    }, error => {
      this.setState({ isChangeEmailVisible: false, isEmailModalLoading: false })
      utilityService.handleError(error)
    })
  }

  getRenderedDetails = () => {
    const { customerProfile, isUpdateDisabled, toggleSidebar, isSideBarVisible, customerStatus } = this.props
    let enable = false
    if (customerStatus) {
      const { enabled } = customerStatus
      enable = enabled
    }

    const { shouldShowPopup } = this.state
    const { activeSubscriptions } = customerProfile
    const isPremium = (activeSubscriptions && !_.isEmpty(activeSubscriptions))
    // const isActive = (streamSessions || []).findIndex(item => item.isActive) > -1
    const address = customerProfile.address && customerProfile.city ? `${customerProfile.address}, ${customerProfile.city}` : (customerProfile.address || customerProfile.city ? (customerProfile.address || customerProfile.city) : '-')
    return (<div className='customer-profile-details'>
      <div className='customer-short-details'>
        <div className='short-details'>
          <span> Registered Date </span>
          <span>{customerProfile.registrationDate ? moment(customerProfile.registrationDate).format('DD MMM YYYY') : '-'}</span>
        </div>
        <div className='short-details'>
          <span> Package </span>
          <span>{ isPremium ? 'Premium' : 'Basic Package'}</span>
          <CustomerAccountIcon isPremium={isPremium} />
        </div>
        <div className='customer-status'>
          <span> User Account Status </span>
          <span className={`${enable ? 'enable' : 'disable'}`}>{enable ? 'Enabled' : 'Disabled'}</span>
        </div>
        <div className='customer-status'>
          <span> Anti-Piracy Status </span>
          <span className={`${customerProfile.lastPiracyStatus ? 'enable' : 'disable'}`}>{customerProfile.lastPiracyStatus}</span>
        </div>
        {isSideBarVisible && <ButtonContainer displayTitle='Show Audit History' childComponent={<SidebarOpenButton onClick={toggleSidebar} />} />}
        { !isUpdateDisabled ? <div className='option-list' id='option-list' ref={(ref) => { this.editModalContainer = ref }}>
          <PopoverButton
            button={<div onClick={this.toggleShowOptions} > <ExpandMenuIcon /></div>}
            placement='bottomLeft'
            displayParam='name'
            contents={enable ? options : enableOptions}
            onContentClick={this.selectOption}
            parentCompoent={'option-list'}
            isVisible={shouldShowPopup}
          />
        </div> : null }
      </div>
      <div className='customer-details'>

        <div className='details large'>
          <div className='data'>
            <span> ID/Email </span>
            <span> {customerProfile.id}</span>
          </div>
          <div className='data'>
            <span> Cognito User ID </span>
            <span>{customerProfile.cognitoUserId || '-'}</span>
          </div>
          <div className='data'>
            <span> Vimond ID: </span>
            <span>{customerProfile.externalId}</span>
          </div>
          <div className='data'>
            <span> First Name </span>
            <span>{customerProfile.firstName || '-'}</span>
          </div>
          <div className='data'>
            <span> Last Name </span>
            <span>{customerProfile.lastName || '-'}</span>
          </div>
          <div className='data'>
            <span> Date of Birth </span>
            <span>{customerProfile.dateOfBirth ? customerProfile.dateOfBirth : '-'}</span>
          </div>
        </div>

        <div className='details'>
          <div className='data'>
            <span> Address/ City </span>
            <span>{address}</span>
          </div>
          <div className='data'>
            <span> State </span>
            <span>{customerProfile.state || '-'}</span>
          </div>
          <div className='data'>
            <span> Country </span>
            <span>{customerProfile.country || '-'}</span>
          </div>
          <div className='data'>
            <span> Zip </span>
            <span>{customerProfile.postcode || '-'}</span>
          </div>
          <div className='data'>
            <span> Mobile No. </span>
            <span>{customerProfile.mobile || '-'}</span>
          </div>
          <div className='data'>
            <span> Mobile Validated </span>
            <span>{customerProfile.mobileVerified ? 'Yes' : 'No'}</span>
          </div>
        </div>
        <div className='details short'>
          <div className='data'>
            <span> Email Validated </span>
            <span>{customerProfile.emailVerified ? 'Yes' : 'No' }</span>
          </div>
          <div className='data'>
            <span> Receive Email </span>
            <span>{customerProfile.receiveEmail ? 'Yes' : 'No'}</span>
          </div>
          <div className='data'>
            <span> Receive SMS </span>
            <span>{customerProfile.receiveSms ? 'Yes' : 'No'}</span>
          </div>
          <div className='data'>
            <span> Use Cleeng </span>
            <span>{customerProfile.useCleeng ? 'Yes' : 'No'}</span>
          </div>
          <div className='data'>
            <span> Test User </span>
            <span>{customerProfile.isTestUser ? 'Yes' : 'No'}</span>
          </div>
          <div className='data'>
            <span> Cleeng Ghost User </span>
            <span>{customerProfile.cleengGhostUser ? 'Yes' : 'No'}</span>
          </div>
        </div>
      </div>
    </div>
    )
  }

  getRenderedDetailsHyperion = () => {
    const { customerProfile, isUpdateDisabled, toggleSidebar, isSideBarVisible, project } = this.props
    const { shouldShowPopup } = this.state
    return (<div className='customer-profile-details'>
      <div className='customer-short-details'>
        <div className='short-details'>
          <span> Registered Date </span>
          <span>{customerProfile.registrationDate ? moment(customerProfile.registrationDate).format('DD MMM YYYY') : '-'}</span>
        </div>
        <div className='short-details'>
          <span> Eligible for Hyperion </span>
          <span>{ customerProfile.eligibleForHyperion ? 'Yes' : 'No'}</span>
          <CustomerAccountIcon isPremium={customerProfile.eligibleForHyperion} />
        </div>
        {isSideBarVisible && <ButtonContainer displayTitle='Show Audit History' childComponent={<SidebarOpenButton onClick={toggleSidebar} />} />}
        { !isUpdateDisabled ? <div className='option-list' id='option-list' ref={(ref) => { this.editModalContainer = ref }}>
          <PopoverButton
            button={<div onClick={this.toggleShowOptions} > <ExpandMenuIcon /></div>}
            placement='bottomLeft'
            displayParam='name'
            contents={project === 'hyperion' ? optionsHyperion : optionsProjectx}
            onContentClick={this.selectOption}
            parentCompoent={'option-list'}
            isVisible={shouldShowPopup}
          />
        </div> : null }
      </div>
      <div className='customer-details'>

        <div className='details-hyperion large'>
          <div className='data'>
            <span> ID </span>
            <span> {customerProfile.id}</span>
          </div>
          <div className='data'>
            <span> Cognito ID </span>
            <span>{customerProfile.cognitoUserId || '-'}</span>
          </div>
          <div className='data'>
            <span> Contact ID </span>
            <span>{customerProfile.contactId || '-'}</span>
          </div>
          <div className='data'>
            <span> Billing Account ID</span>
            <span>{customerProfile.selectedBillingAccountId}</span>
          </div>
          <div className='data'>
            <span> Email </span>
            <span>{customerProfile.email || '-'}</span>
          </div>
        </div>

        <div className='details-hyperion'>
          <div className='data'>
            <span> First Name </span>
            <span>{customerProfile.firstName || '-'}</span>
          </div>
          <div className='data'>
            <span> Last Name </span>
            <span>{customerProfile.lastName || '-'}</span>
          </div>
          {/* <div className='data'>
            <span> Date of Birth </span>
            <span>{customerProfile.dateOfBirth ? moment(customerProfile.dateOfBirth).format('DD MMM YYYY') : '-'}</span>
          </div> */}
          <div className='data'>
            <span> Mobile No. </span>
            <span>{customerProfile.mobile || '-'}</span>
          </div>
          <div className='data'>
            <span> Terms Accepted </span>
            <span>{customerProfile.tncAcceptedOn ? moment(customerProfile.tncAcceptedOn).format('DD MMM YYYY HH:mm') : null}</span>
          </div>
        </div>
        <div className='details-hyperion short'>
          <div className='data'>
            <span> Service SMS </span>
            <span>{customerProfile.receiveServiceUpdateSms ? 'Yes' : 'No' }</span>
          </div>
          <div className='data'>
            <span> Service Email </span>
            <span>{customerProfile.receiveServiceUpdateEmail ? 'Yes' : 'No'}</span>
          </div>
          <div className='data'>
            <span> Billing Email </span>
            <span>{customerProfile.receiveBillReminderEmail ? 'Yes' : 'No'}</span>
          </div>
          <div className='data'>
            <span> Billing SMS </span>
            <span>{customerProfile.receiveBillReminderSms ? 'Yes' : 'No'}</span>
          </div>
        </div>
      </div>
    </div>
    )
  }

  render () {
    const { customerProfile, isLoading, project, antiPiracyActions } = this.props
    const { isEditCustomerVisible, isModalLoading, isChangePasswordVisible, isSendEmailVisible, islogoutAlllVisible,
      isDisableUserVisible, isCreateSubscriptionVisible, isEnableUserVisible, isChangeEmailVisible, isEmailModalLoading, isSyncUserVisible, isAntiPiracyVisible } = this.state

    let details
    if (customerProfile && !isLoading) {
      details = project === 'hyperion' || project === 'projectx' ? this.getRenderedDetailsHyperion() : this.getRenderedDetails()
    } else if (!customerProfile && !isLoading) {
      details = <Empty />
    } else {
      details = <Skeleton active title={false} paragraph={{ rows: 5, width: width }} loading />
    }

    return (
      <div className='customer-profile'>
        {project === 'hyperion' || project === 'projectx'
          ? <EditCustomerProfileModalHyperion
            isVisible={isEditCustomerVisible}
            handleSubmit={this.onEditCustomer}
            handleCancel={this.onCloseCustomerEdit}
            isSubmitDisabled={isModalLoading}
            data={customerProfile}
            isLoading={isModalLoading} />
          : <EditCustomerProfileModal
            isVisible={isEditCustomerVisible}
            handleSubmit={this.onEditCustomer}
            handleCancel={this.onCloseCustomerEdit}
            isSubmitDisabled={isModalLoading}
            data={customerProfile}
            isLoading={isModalLoading} />
        }
        <ChangeCustomerPasswordModal
          isVisible={isChangePasswordVisible}
          handleSubmit={this.onChangePassword}
          isSubmitDisabled={isModalLoading}
          handleCancel={this.onCloseCustomerPasswordChange}
          isLoading={isModalLoading} />
        <CustomerAntiPiracyActionModal
          isVisible={isAntiPiracyVisible}
          handleSubmit={this.onSendAntiPiracyAction}
          isSubmitDisabled={isModalLoading}
          handleCancel={(() => this.setState({ isAntiPiracyVisible: false }))}
          options={antiPiracyActions}
          isLoading={isModalLoading} />
        <CreateSubscriptionModal
          isVisible={isCreateSubscriptionVisible}
          handleSubmit={this.onCreateSubscription}
          isSubmitDisabled={isModalLoading}
          handleCancel={this.onCloseCreateSubscription}
          data={customerProfile}
          isLoading={isModalLoading} />
        <ConfirmModal
          isVisible={isSendEmailVisible}
          title={'Send Reset Password Email'}
          message={'Do you want to send reset password email?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onCloseEmailConfirmation}
          handleSubmit={this.onConfirmSendEmail} />
        <ConfirmModal
          isVisible={islogoutAlllVisible}
          title={'Logout from all devices'}
          message={'Do you want to Logout from all the devices?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onCloseLogoutAllConfirmation}
          handleSubmit={this.onConfirmLogoutAll} />
        <ConfirmModal
          isVisible={isDisableUserVisible}
          title={'Disable user'}
          message={'Do you want to disable the user?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onDisableUserConfirmation}
          handleSubmit={this.onConfirmDisableUser} />
        <ConfirmModal
          isVisible={isEnableUserVisible}
          title={'Enable user'}
          message={'Do you want to enable the user?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onEnableUserConfirmation}
          handleSubmit={this.onConfirmEnableUser} />
        <ChangeCustomerEmailModal
          isVisible={isChangeEmailVisible}
          handleSubmit={this.onChangeEmail}
          isSubmitDisabled={isEmailModalLoading}
          handleCancel={this.onCloseCustomerEmailChange}
          isLoading={isEmailModalLoading}
        />
        <ConfirmModal
          isVisible={isSyncUserVisible}
          title={'Sync user'}
          message={'Do you want to sync the user?'}
          isLoading={isModalLoading}
          isSubmitButtonDisabled={isModalLoading}
          rightButtonText={'Confirm'}
          handleCancel={this.onSyncUserCancel}
          handleSubmit={this.onConfirmSyncUser} />
        <Accordion title='CUSTOMER PROFILE' childComponent={details} onExpandButtonClick={this.props.onExpandButtonClick} />
      </div>
    )
  }
}

CustomerProfile.propTypes = {
}

export default withApollo(compose(
  graphql(
    QueryCustomerProfile,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, selectedCustomerCogId, project } = props
        const variables = { id: selectedCustomer, cognitoUserId: selectedCustomerCogId, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const customerProfile = data && data.getCustomer ? data.getCustomer : null
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return {
          customerProfile,
          isLoading: data && data.loading,
          error,
          getCustomerData: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateCustomer,
    {
      options: (props) => {
        const { selectedCustomer, selectedCustomerCogId, project } = props
        const variables = { id: selectedCustomer, cognitoUserId: selectedCustomerCogId, project }
        return {
          update: (cache, { data: { adminUpdateProfile } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCustomerProfile, variables }))
            if (cacheData && cacheData.getCustomer && adminUpdateProfile) {
              cacheData.getCustomer = adminUpdateProfile
            }
            cache.writeQuery({
              query: QueryCustomerProfile,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        updateCustomerProfile: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateCustomerPassword,
    {
      props: (props) => ({
        updateCustomerPassword: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateSubscription,
    {
      props: (props) => ({
        createSubscription: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationResendEmail,
    {
      props: (props) => ({
        resendEmail: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationLogoutAllDevices,
    {
      props: (props) => ({
        onLogoutAllDevice: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDisableUser,
    {
      props: (props) => ({
        onDisableUser: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationEnableUser,
    {
      props: (props) => ({
        onEnableUser: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryGetUserAccountStatus,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const customerStatus = data && data.getUserAccountStatus ? data.getUserAccountStatus.accountStatus : null
        return {
          customerStatus,
          fetchCustomerStatus: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    MutationAdminUpdateEmail,
    {
      props: (props) => ({
        updateCustomerEmail: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationAdminSyncUser,
    {
      props: (props) => ({
        onSyncUser: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryyListAllAntiPiracyActions,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { project } = props
        const variables = { client: 'vcms', project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const antiPiracyActions = data && data.listAllAntiPiracyActions && data.listAllAntiPiracyActions.data ? data.listAllAntiPiracyActions.data : null
        const error = data.error ? data.error.message : null
        return {
          antiPiracyActions,
          error
        }
      }
    }
  ),
  graphql(
    MutationSendAntiPiracyAction,
    {
      props: (props) => ({
        sendAntiPiracyAction: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(CustomerProfile))
