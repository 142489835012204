import React from 'react'
import { SignIn } from 'aws-amplify-react'
import { Auth, Hub } from 'aws-amplify'
import { message } from 'antd'

import LoginForm from './../../components/ui/dataEntry/forms/LoginForm'
import EnableMfa from './EnableMfa'
import ConfirmOtp from './ConfirmOtp'
import AddMfaDevices from '../../components/ui/feedback/AddMfaDevicesModal'
import AuthService from '../../services/AuthService'
import userMessages from '../../constants/messages'
import { utilityService } from '../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryUserSettings from '../../graphQL/mfaSettings/checkUser2faStatus'

import './Auth.scss'

const signInState = 'signIn'
const signOutState = 'signedOut'
const signedUpState = 'signedIn'
const requirePasswordState = 'requireNewPassword'
const forgotPasswordState = 'forgotPassword'

// let forceMFA = null

class Login extends SignIn {
  constructor (props) {
    super(props)
    this.setFavIcon()
    this.state = {
      isSuccess: false,
      isMfaEnabled: false,
      isMfaDeviceModalVisible: false,
      isMfaModalVisible: false,
      isEnableMfaLoading: false,
      isMfaConfirmModalLoading: false,
      isAddDeviceModalLoading: false,
      qrCode: '',
      secretKey: ''
    }
    // forceMFA = window.config.forceMFA
    this._validAuthStates = [
      signInState,
      signOutState
    ]
  }

  setFavIcon () {
    const favicon = document.getElementById('favicon')
    const url = window.location.href
    if (url.includes('hyperion')) {
      favicon.href = '/hyperion_favicon.ico'
    } else if (url.includes('subhub')) {
      favicon.href = '/subhub_favicon.ico'
    } else if (url.includes('uat') || url.includes('qa')) {
      favicon.href = '/favicon_gray.ico'
    }
  }

    onSubmit = async (username, password, shouldRember) => {
      const project = utilityService.getCurrentProject()
      const browserAndOsDetails = utilityService.getBrowserDetails()
      // const { isEnabled } = this.props
      // const awsconfig = {
      //   Auth: {
      //     storage: shouldRember ? localStorage : sessionStorage
      //   }
      // }
      // Amplify.configure(awsconfig)
      try {
        const attributes = {
          meta: window.config.meta,
          sessionId: new Date().getTime().toString(),
          project: project,
          userAgent: browserAndOsDetails
        }
        const user = await Auth.signIn(username, password, attributes)
        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.setState({ isMfaModalVisible: true, user })
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          message.success(userMessages.LOGIN_FORCE_RESET)
          this.changeState(requirePasswordState, user)
        // } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        //   this.setState({isMfaModalVisible: true})
        } else {
          this.props.getUserMfaStatus({ user: username, project, isFirstSignIn: true, userAgent: browserAndOsDetails }).then(({ data }) => {
            if (!data.checkUserAccess.projectAccess) {
              message.error(`You don't have access to this to project`)
              // this.onLogout()
              this.setState({ isSuccess: true })
              return
            }
            if ((data.checkUserAccess.mfaStatus) && (!user.challengeName || user.challengeName === 'NOMFA')) {
              this.changeState(signedUpState, user)
            } else {
              // this.changeState(signedUpState, user)
              this.setState({ isMfaEnabled: true, user })
            }
            this.setState({ isSuccess: true })
          })
        }
      } catch (errorMsg) {
        message.error(userMessages.LOGIN_FAILURE)
        this.setState({ isSuccess: true })
      }
    }

    forgotClicked = () => {
      this.changeState(forgotPasswordState)
    }

    onLogout = () => {
      this.setState({ isMfaEnabled: false })
      Auth.signOut()
        .then(data => {
          AuthService.clearUserDetails()
          Hub.listen('auth', this)
          this.setState({ isMfaEnabled: false })
        })
        .catch(err => console.log(err))
    }

    onContinueMfa = async (isMfaDeviceModalVisible) => {
      const { user } = this.state
      if (isMfaDeviceModalVisible && user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.setState({ isMfaModalVisible: true, isMfaEnabled: false })
      } else if (isMfaDeviceModalVisible) {
        this.setState({ isEnableMfaLoading: true })
        Auth.setupTOTP(user).then(code => {
          const domainName = utilityService.getEnvironment()
          const accountName = domainName ? `Optus CMS - ${domainName}` : 'Optus CMS'
          const authCode = `otpauth://totp/${accountName}:${this.state.user.username}?secret=${code}&issuer=${accountName}`
          // const authCode = 'otpauth://totp/AWSCognito:' + this.state.user.username + '?secret=' + code + '&issuer=AWSCognito'
          this.setState({ qrCode: authCode, isMfaDeviceModalVisible, isEnableMfaLoading: false, secretKey: code })
        }, error => {
          const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
          message.error(errorMessage)
          this.setState({ qrCode: '', isMfaDeviceModalVisible: false, isEnableMfaLoading: false, secretKey: '' })
        })
      } else { this.setState({ isMfaDeviceModalVisible }) }
    }

    confirmLogin = async (code, code2, isDeviceAdded) => {
      const { user } = this.state
      const browserAndOsDetails = utilityService.getBrowserDetails()
      const project = utilityService.getCurrentProject()
      try {
        const attributes = {
          meta: window.config.meta,
          sessionId: new Date().getTime().toString(),
          project: project,
          userAgent: browserAndOsDetails
        }
        if (isDeviceAdded) {
          this.setState({ isMfaConfirmModalLoading: true })
          Auth.confirmSignIn(
            user, // Return object from Auth.signIn()
            code, // Confirmation code
            user.challengeName,
            attributes // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          ).then(() => {
            this.changeState(signedUpState, user)
            this.setState({ isMfaModalVisible: false, isMfaConfirmModalLoading: false })
            this.props.getUserMfaStatus({ user: user.username, project, isFirstSignIn: true, userAgent: browserAndOsDetails }).then(({ data }) => {

            })
          }, error => {
            const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
            message.error(errorMessage)
            this.setState({ isMfaConfirmModalLoading: false })
          })
        } else {
          this.setState({ isAddDeviceModalLoading: true })
          Auth.verifyTotpToken(user, code).then(() => {
            Auth.setPreferredMFA(user, 'TOTP').then(() => {
              this.setState({ qrCode: '', isMfaDeviceModalVisible: false, isAddDeviceModalLoading: false, secretKey: '' })
              this.changeState(signedUpState, user)
            })
          }, error => {
            const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
            message.error(errorMessage)
            this.setState({ isAddDeviceModalLoading: false })
          })
        }
      } catch (error) {
        const errorMessage = error && error.message ? error.message : 'Something went wrong please try again later'
        message.error(errorMessage)
      }
    }

    showComponent () {
      const { isMfaEnabled, isSuccess, isMfaDeviceModalVisible, qrCode, secretKey, isMfaModalVisible, isEnableMfaLoading, isMfaConfirmModalLoading, isAddDeviceModalLoading } = this.state
      return (
        <div className='login'>
          {isMfaEnabled ? <EnableMfa
            onLogout={this.onLogout}
            onSuccess={() => this.onContinueMfa(true)}
            isLoading={isEnableMfaLoading} />
            : isMfaModalVisible ? <ConfirmOtp
              handleCancel={() => this.setState({ isMfaModalVisible: false })}
              handleSubmit={(a, b) => this.confirmLogin(a, b, true)}
              isLoading={isMfaConfirmModalLoading} />
              : <div className='login-form-align'>
                <LoginForm onForgotClick={this.forgotClicked} onSelect={this.onSubmit} formSuccess={isSuccess} />
              </div>}
          <AddMfaDevices
            isVisible={isMfaDeviceModalVisible}
            handleCancel={() => this.onContinueMfa(false)}
            qrCode={qrCode}
            secretKey={secretKey}
            isLoading={isAddDeviceModalLoading}
            handleSubmit={this.confirmLogin} />
        </div>
      )
    }
}

// export default Login
export default withApollo(compose(
  graphql(
    QueryUserSettings,
    {
      options: () => {
        const project = utilityService.getCurrentProject()
        return {
          fetchPolicy: 'network-only',
          variables: { user: '', project }
        }
      },
      props: (props) => {
        return {
          getUserMfaStatus: (user) => {
            return props.data.refetch(user)
          }
        }
      }
    }
  )

)(Login))
