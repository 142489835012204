import React from 'react'
import PropTypes from 'prop-types'

const Range6Icon = ({ width, height, color, onClick, className, ...props }) => (
  <svg className='range-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h18v18H0z' />
      <circle cx='9' cy='9' r='8.5' stroke={color} />
      <path fill={color} d='M9 15.3A6.3 6.3 0 0 0 9 2.7V15.3z' />
    </g>
  </svg>
)

Range6Icon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Range6Icon.defaultProps = {
  width: 18,
  height: 18,
  color: '#343A40'
}

export default Range6Icon
