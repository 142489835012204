import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, Tabs } from 'antd'

import _, { cloneDeep } from 'lodash'
import Input from '../inputs/Input'
import GeneralFieldComponent from '../../dataDisplay/GeneralFieldComponent'
import LoadingButton from '../../general/buttons/LoadingButton'
import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'
import getUuid from 'uuid-by-string'
const { TabPane } = Tabs

class CreateSportsModal extends Component {
    state = {
      sportsName: undefined,
      enable: false,
      activeTab: 'competitionFields',
      currentSelectedSports: {
        competitionFields: [],
        tournamentFields: [],
        teamFields: [],
        matchFields: [],
        playerFields: [],
        stageFields: []
      }
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedSport && !nextProps.isLoading && !_.isEqual(nextProps.selectedSport, this.props.selectedSport)) {
        this.setState({
          sportsName: nextProps.selectedSport.title,
          currentSelectedSports: nextProps.selectedSport })
      }
    }

    handleChange =(e) => {
      const { value, name } = e.target
      if (!value.startsWith(' ')) {
        if (name === 'name') {
          this.setState({ sportsName: value }, this.isEnableSave)
        }
      }
    }

    onOptionSelect = (value, index) => {
      const { currentSelectedSports, activeTab } = this.state
      currentSelectedSports[activeTab][index].type = value
      this.setState({ currentSelectedSports }, this.isEnableSave)
    }

    handleFieldChange =(e, index) => {
      const { currentSelectedSports, activeTab } = this.state
      const { value } = e.target
      if (!_.includes(value, ' ')) {
        currentSelectedSports[activeTab][index].displayName = value
        currentSelectedSports[activeTab][index].name = value
        currentSelectedSports[activeTab][index].default = null
        currentSelectedSports[activeTab][index].position = null
        currentSelectedSports[activeTab][index].isPosChange = null
        this.setState({ currentSelectedSports }, this.isEnableSave)
      } else {
        message.warn('Unnecessary space not allowed')
      }
    }

    onChangeStatus = (e, index) => {
      const { currentSelectedSports, activeTab } = this.state
      const { checked } = e.target
      currentSelectedSports[activeTab][index].isRequired = checked
      this.setState({ currentSelectedSports }, this.isEnableSave)
    }

    handleAddSports = () => {
      const { sportsName, currentSelectedSports } = this.state
      const { sportsDetails, selectedSport, listMasterFields } = this.props
      let isDuplicate = false
      let invalidMetaFields = sportsDetails.filter(item => {
        let curItem = _.cloneDeep(currentSelectedSports[item.id]) || []
        const tempItem = (curItem).filter(item => !item.displayName || !item.type)
        curItem = curItem.map(innerItem => {
          return {
            displayName: innerItem.displayName,
            type: innerItem.type
          }
        })
        const uniqArr = _.uniqWith(curItem, (a, b) => a.displayName.toLowerCase() === b.displayName.toLowerCase())
        if (uniqArr.length !== curItem.length) { isDuplicate = true }
        let curField = listMasterFields.filter(fields => fields.fieldName === item.id)
        let allFieldNames = []
        curField[0].fields.map(field => allFieldNames.push(field.displayName))
        uniqArr.map(item => {
          if (_.some(allFieldNames, (fieldName) => { return fieldName.toLowerCase() === item.displayName.toLowerCase() })) {
            isDuplicate = true
          }
        })
        return tempItem.length
      })
      if (invalidMetaFields && invalidMetaFields.length) {
        message.warn('Meta fields should be filled with valid values')
        return
      }
      if (isDuplicate) {
        message.warn('Please remove duplicate meta fields')
        return
      }
      const meta = (currentSelectedSports.meta || []).map(item => {
        delete item.__typename
        return item
      })
      const competitionFields = (currentSelectedSports.competitionFields || []).map(item => {
        delete item.__typename
        return item
      })
      const tournamentFields = (currentSelectedSports.tournamentFields || []).map(item => {
        delete item.__typename
        return item
      })
      const teamFields = (currentSelectedSports.teamFields || []).map(item => {
        delete item.__typename
        return item
      })
      const matchFields = (currentSelectedSports.matchFields || []).map(item => {
        delete item.__typename
        return item
      })
      const playerFields = (currentSelectedSports.playerFields || []).map(item => {
        delete item.__typename
        return item
      })
      const stageFields = (currentSelectedSports.stageFields || []).map(item => {
        delete item.__typename
        return item
      })
      const updatedSports = {
        title: sportsName,
        meta,
        competitionFields,
        tournamentFields,
        teamFields,
        matchFields,
        playerFields,
        stageFields
      }
      if (selectedSport) { updatedSports.id = selectedSport.id }
      this.props.handleSubmit(updatedSports)
    }

    reorder = (startIndex, endIndex) => {
      const { currentSelectedSports, activeTab } = this.state
      const result = Array.from(currentSelectedSports[activeTab])
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      currentSelectedSports[activeTab] = result
      this.setState({ currentSelectedSports }, this.isEnableSave)
    }

    onCloseField = (index) => {
      const { currentSelectedSports, activeTab } = this.state
      // const index = metaDataField.findIndex(item => item.id === id)
      currentSelectedSports[activeTab].splice(index, 1)
      this.setState({ currentSelectedSports }, this.isEnableSave)
    }

    addFieldObjects = () => {
      const { currentSelectedSports, activeTab } = this.state
      const randomId = getUuid(utilityService.makeRandomString(5))
      const newFieldObject = {
        id: randomId,
        displayName: '',
        type: '',
        isRequired: false
      }
      if (currentSelectedSports[activeTab] === null) {
        currentSelectedSports[activeTab] = []
      }
      currentSelectedSports[activeTab].push(newFieldObject)
      this.setState({ currentSelectedSports })
    }

    isEnableSave = () => {
      this.setState({ enable: true })
    }

    onTabChange = (activeTab) => {
      this.setState({ activeTab })
    }

    resetState = () => {
      const state = {
        sportsName: '',
        enable: false,
        activeTab: 'competitionFields',
        currentSelectedSports: {
          competitionFields: [],
          tournamentFields: [],
          teamFields: [],
          matchFields: [],
          playerFields: [],
          stageFields: []
        }
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, selectedStream, sportsDetails, isLoading, listMasterFields } = this.props
      const { sportsName, enable, activeTab, currentSelectedSports } = this.state
      const disabled = enable && sportsName
      let currentMetaFields = _.cloneDeep(currentSelectedSports[activeTab])
      let defaultMasterItem = (cloneDeep(listMasterFields) || []).find(item => item.fieldName === activeTab)
      if (defaultMasterItem && defaultMasterItem.fields && defaultMasterItem.fields.length) {
        defaultMasterItem.fields = defaultMasterItem.fields.map(item => {
          item.isDisabled = true
          return item
        })
        // currentMetaFields = [...disabled, ...currentMetaFields]
      }
      return (
        <Modal
          className='general-modal create-sports-modal tab-details'
          title={selectedStream ? 'EDIT SPORTS' : 'NEW SPORTS'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.handleAddSports}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !disabled, loading: isLoading }}
          // okButtonProps={{ loading: isLoading }}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='890px'
          confirmLoading={isLoading}
        >
          <div className='create-sports'>
            <div className='create-input'>
              <Input title='Sports Name'
                isRequired
                inputName='name'
                handleChange={this.handleChange}
                value={sportsName}
                placeholder='Enter Sports Name' />
            </div>
            <Tabs onChange={this.onTabChange} activeKey={activeTab}>
              {sportsDetails.map(details =>
                <TabPane tab={details.label} key={details.id}>
                  <GeneralFieldComponent
                    reorder={this.reorder}
                    onCloseField={this.onCloseField}
                    onOptionSelect={this.onOptionSelect}
                    onChangeStatus={this.onChangeStatus}
                    handleChange={this.handleFieldChange}
                    streamField={currentMetaFields}
                    disabledStreams={defaultMasterItem ? defaultMasterItem.fields : []}
                    isTitleInvisible
                    isSports
                    nameParam='displayName'
                    isEnableSave={this.isEnableSave}
                    columnTitle1={'Field Name'}
                    columnTitle2={'Field Type'}
                    columnTitle3={'Required'} />
                </TabPane>)}
            </Tabs>
            <LoadingButton
              type='primary'
              onClick={this.addFieldObjects}
              htmlType='submit'
              buttonText={'Add Meta Data'}
              buttonClass='save-btn'
              isLoading={false}
              isDisabled={!sportsName}
            />
          </div>
        </Modal>
      )
    }
}

CreateSportsModal.propTypes = {
  /** visible file status of CreateSportsModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateSportsModal. */
  handleCancel: PropTypes.func
}

CreateSportsModal.defaultProps = {
}

export default (CreateSportsModal)
