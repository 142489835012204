import React from 'react'
import PropTypes from 'prop-types'
import ClearFilterIcon from './../icons/ClearFilterIcon'
import './../../ui.style.scss'

const ClearFilterButton = ({ isDisabled, onClick, className, ...props }) => (
  <div className={`black-button clear-filter${isDisabled ? ' disabled' : ''} ${className || ''}`} onClick={onClick} >
    <ClearFilterIcon />
  </div>
)

ClearFilterButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired,
  /** Class name for seperate style */
  className: PropTypes.string
}

ClearFilterButton.defaultProps = {
  isDisabled: false
}

export default ClearFilterButton
