import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
// import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
// import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
// import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreatePublishGroupModal from '../../../components/ui/dataEntry/other/CreatePublishGroupModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import userMessages from '../../../constants/messages'
// import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryPublishGroupList from '../../../graphQL/admin/getPublishingGroups'
import MutationDeletePublishGroup from '../../../graphQL/admin/deletePublishingGroup'
import { utilityService } from '../../../services/UtilityService'
const deletePublishGroupMessage = {
  title: 'DELETE PUBLISHING GROUP',
  firstMessage: 'Are you sure you want to delete this Publising Group?',
  secondMessage: 'This action cannot be undone.'
}

let timeOutVar = null

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class PublishingGroups extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedPublishGroup: null,
      isLoading: !props.publishGroupList,
      showAddPublishGroupModal: false,
      showTransferPublishGroupModal: false,
      showDeletePublishGroupModal: false,
      selectedPublishGroup: undefined,
      height: null
    }
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '40%'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: this.renderStatus
      },
      {
        dataIndex: 'id',
        width: '3%',
        render: this.renderData
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
      window.addEventListener('resize', this.setHeight)
      this.setHeight()
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.publishGroupList, this.props.publishGroupList)) {
        this.setState({ isSearching: false, isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
      window.removeEventListener('resize', this.setHeight)
    }

    setHeight = () => {
      if (this.tableSize) {
        clearTimeout(timeOutVar)
        timeOutVar = null
        this.setState({ height: this.tableSize.clientHeight })
      } else {
        clearTimeout(timeOutVar)
        this.timeOutVar = setTimeout(() => {
          this.setHeight()
        }, 2000)
      }
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedPublishGroup) {
        this.setState({ openedPublishGroup: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedPublishGroup === id) {
        this.setState({ openedPublishGroup: null })
      } else {
        this.setState({ openedPublishGroup: id })
      }
    }

    onChangeFilter = value => {
      this.setState({ isSearching: true })
      this.props.onChangePublishingGroupsFilter({ searchString: value })
    }

    onSelectOption = (id, selectedOption) => {
      const { publishGroupList } = this.props
      const selectedPublishGroup = publishGroupList.find(item => item.id === id)
      if (!selectedPublishGroup) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddPublishGroupModal: true, selectedPublishGroup })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeletePublishGroupModal: true, selectedPublishGroup })
      }
      this.setState({ openedPublishGroup: null })
    }

    showAddPublishGroup = () => {
      this.setState({ showAddPublishGroupModal: true, selectedPublishGroup: null })
    }

    hideAddPublishGroupModal = () => {
      this.setState({ showAddPublishGroupModal: false, selectedPublishGroup: null })
    }

    handleAddPublishGroup = () => {
      this.setState({ showAddPublishGroupModal: false, selectedPublishGroup: null })
    }

    hideDeletePublishGroupModal = () => {
      this.setState({ showDeletePublishGroupModal: false })
    }

    handleDeletePublishGroup = () => {
      const { selectedPublishGroup } = this.state
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleting: true })
      this.props.deletePublishGroup(selectedPublishGroup.id).then(({ data }) => {
        onHistoryUpdate()
        this.setState({ showDeletePublishGroupModal: false, selectedPublishGroup: null, isDeleting: false })
        if (data && data.deletePublishingGroup && data.deletePublishingGroup.status === 'DONE') {
          message.success('Deleted Publish Group Successfully')
        } else {
          message.success('Triggered Delete Publish Group Successfully')
        }
      }, error => {
        this.setState({ showDeletePublishGroupModal: false, selectedPublishGroup: null, isDeleting: false })
        utilityService.handleError(error)
      })
    }

    handleMultipleDeletePublishGroups = () => {
      this.setState({ isMultipleDelete: false })
    }

    hideMultipleDeletePublishGroups = () => {
      this.setState({ isMultipleDelete: false })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    renderStatus = status => {
      const statusMap = { INPROGRESS: 'Delete in Progress', ERROR: 'Deletion Error' }
      return !status ? 'Active' : statusMap[status] ? statusMap[status] : status
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedPublishGroup, isSelected } = this.state
      const { publishGroupList } = this.props
      const selectedType = publishGroupList.find(item => item.id === id)
      if (selectedType && selectedType.status !== 'INPROGRESS' && !selectedType.isDefault && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (selectedType && selectedType.status === 'INPROGRESS') {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            {!isSelected ? <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedPublishGroup === id}
              placement={'leftBottom'}
            /> : '' }
          </div>
        )
      } else {
        return <div />
      }
    }

    render () {
      const { publishGroupList, onHistoryUpdate, project } = this.props
      const { showAddPublishGroupModal, showDeletePublishGroupModal, selectedPublishGroup, isMultipleDelete, selectedRowKeys, isSelected, isSearching,
        isLoading, isDeleting, height } = this.state
      let adminItem
      if (selectedPublishGroup && showDeletePublishGroupModal) {
        adminItem = <div >
          <p>{ selectedPublishGroup.name }</p>
        </div>
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
      }
      return (
        <AppContext.Consumer>
          {({ permissions }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal publishing-group-list'>
              {/* <FilterInput
                searchString={publishingGroupsFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
              /> */}
              <div className='admin-list publishing-group' ref={(ref) => { this.tableSize = ref }}>
                <InfiniteScroll
                  pageStart={0}
                  hasMore={false}
                  loadMore={() => {}}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading || isSearching}>
                    <Table
                      className={`general-table`}
                      rowKey={record => record.id}
                      columns={this.columns}
                      dataSource={publishGroupList}
                      pagination={false}
                      scroll={{ x: true, y: (height - 37) }}
                      rowSelection={isSelected ? rowSelection : undefined} />
                  </Skeleton>
                  {/* <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} /> */}
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {/* {!isSelected
                ? <MultipleSelectButton onClick={this.onMultipleSelect} />
                : <React.Fragment>
                  <BackArrowButton onClick={this.onMultipleDSelect} />
                  <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                </React.Fragment>
              } */}
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddPublishGroup}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                /> : null}
              </div>
              <CreatePublishGroupModal
                isVisible={showAddPublishGroupModal}
                handleCancel={this.hideAddPublishGroupModal}
                handleSubmit={this.handleAddPublishGroup}
                selectedPublishGroup={selectedPublishGroup}
                isSubmitDisabled={_.isEmpty(selectedPublishGroup) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                project={project} />
              <AdminItemConfirmModal
                isVisible={showDeletePublishGroupModal}
                title={deletePublishGroupMessage.title}
                firstMessage={deletePublishGroupMessage.firstMessage}
                secondMessage={deletePublishGroupMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeletePublishGroupModal}
                handleSubmit={this.handleDeletePublishGroup}
                isSubmitButtonDisabled={false}
                isLoading={isDeleting}
                isCancelButtonDisabled={this.isDeleteDisabled} />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'DELETE MULTIPLE DISPLAY TYPE'}
                message={userMessages.DELETE_MULTIPLE_DISPLAY_TYPE}
                isLoading={false}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeletePublishGroups}
                handleCancel={this.hideMultipleDeletePublishGroups}
              />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

PublishingGroups.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryPublishGroupList,
    {
      options: ({ publishingGroupsFilter, project }) => {
        const { searchString } = publishingGroupsFilter
        let filter = { }
        if (searchString) {
          filter.name = { match: searchString }
        }
        const variables = { filter, limit: 50, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: ({ data }) => {
        return {
          publishGroupList: data && data.listPublishingGroups && data.listPublishingGroups.items ? _.sortBy(_.cloneDeep(data.listPublishingGroups.items), 'id') : undefined,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationDeletePublishGroup, {
      options: ({ project }) => {
        return {
          update: (cache, { data: { deletePublishingGroup } }) => {
            const variables = { filter: null, limit: 50, offset: 0, project }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryPublishGroupList, variables }))
            if (cacheData && cacheData.listPublishingGroups && cacheData.listPublishingGroups.items) {
              const selectedAuthorIndex = cacheData.listPublishingGroups.items.findIndex(item => item.id === deletePublishingGroup.id)
              if (selectedAuthorIndex > -1) {
                if (deletePublishingGroup.status === 'DONE') {
                  cacheData.listPublishingGroups.items.splice(selectedAuthorIndex, 1)
                } else {
                  cacheData.listPublishingGroups.items[selectedAuthorIndex].status = deletePublishingGroup.status
                }
              }
            }
            cache.writeQuery({
              query: QueryPublishGroupList,
              data: cacheData,
              variables
            })
          }
        }
      },
      props: (props) => ({
        deletePublishGroup: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  )

)(PublishingGroups))
