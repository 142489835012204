import React from 'react'
import PropTypes from 'prop-types'
// import { Select } from 'antd'
import { Icon, Divider, Select, Tooltip } from 'antd'

import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'
import './../../ui.style.scss'
import CreateRoleModal from '../other/CreateRoleModal'
import { utilityService } from '../../../../services/UtilityService'

const Option = Select.Option

const filter = (input, option, options) => {
  const currentOption = (options || []).find(item => item.id === option.props.value)
  const returnValue = currentOption.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return returnValue
}

class RoleDropDown extends React.Component {
    state = {
      value: undefined,
      showAddRoleModal: false,
      isSearchPresent: false
    }

    onOptionSelect = (id) => {
      const selectedRole = (this.props.roles || []).find(item => item.id === id)
      this.props.onRoleSelect(selectedRole, this.props.index)
    }

    showAddRoleModal = () => {
      this.setState({ showAddRoleModal: true })
    }

    hideAddRoleModal = () => {
      this.setState({ showAddRoleModal: false })
    }

    handleAddRole = (newRole) => {
      this.setState({ showAddRoleModal: false })
      this.props.onRoleSelect(newRole, this.props.index)
    }

    onRoleSearch = (value) => {
      this.setState({ isSearchPresent: true })
      if (this.props.onSearch) { this.props.onSearch(value) }
    }

    onDropdownVisibleChange = (isOpen) => {
      if (!isOpen) { this.setState({ isSearchPresent: false }) }
    }

    render () {
      const { showAddRoleModal } = this.state
      const { roles, selectedRole, parentCompoent, index, project } = this.props
      let optionComponents = (roles || []).map((option, index) => (
        <Option key={option.id} value={option.id}>
          <Tooltip overlayClassName='drop-down-tooltip' placement='right' title={<p>{ option.name } ({utilityService.getSourceDetails(option['source'])} )</p>}>
            <p> { option.name }</p>
          </Tooltip>
        </Option>
      ))

      const dropdownRender = menu => (
        <div>
          <div onMouseDown={(e) => {
            e.stopPropagation()
            this.showAddRoleModal()
          }} className='custom-dropdown-container'>
            <div style={{ position: 'absolute', left: 0, marginLeft: '10px' }}>Add Role</div>
            <div style={{ position: 'absolute', right: 0, marginRight: '10px' }} > <Icon type='plus' /> </div>
          </div>
          <Divider dashed style={{ margin: 0 }} />
          { menu }
        </div>
      )

      return (

        <div className='role-drop-down'>

          <Select
            dropdownRender={dropdownRender}
            allowClear
            dropdownClassName='role-dropdown-popup'
            suffixIcon={<ExpandedIcon />}
            clearIcon={<CrossIcon />}
            value={selectedRole ? selectedRole.name : undefined}
            placeholder='Select Role'
            showSearch
            getPopupContainer={() => document.getElementById(parentCompoent)}
            onChange={this.onOptionSelect}
            onSearch={this.onRoleSearch}
            optionFilterProp={'name'} filterOption={(input, option) => filter(input, option, roles)}
            onDropdownVisibleChange={this.onDropdownVisibleChange}
          >
            { optionComponents }
          </Select>

          <CreateRoleModal
            isVisible={showAddRoleModal}
            handleCancel={this.hideAddRoleModal}
            handleSubmit={this.handleAddRole}
            roles={roles}
            index={index}
            project={project}
            isFromAsset
            title='NEW Role'
            module='ASSET_MANAGER' />
        </div>
      )
    }
}
RoleDropDown.propTypes = {
  /** Option selection action of Dropdown. */
  onRoleSelect: PropTypes.func,
  /** Function onSearch */
  onSearch: PropTypes.func,
  /** Selected value of Dropdown. */
  selectedRole: PropTypes.object,
  /** detail list of RoleDropDown. */
  role: PropTypes.array,
  /** detail of parentComponet for the position */
  parentCompoent: PropTypes.string,
  /** role search string */
  roleSearch: PropTypes.string
}

export default RoleDropDown
