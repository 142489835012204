import gql from 'graphql-tag'

export default gql(`
    mutation adminEnableUser( $email: String! $userId: String $project: String) {
        adminEnableUser(
            userId: $userId
            project: $project
            email: $email
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
