import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import UploadQueueCell from './UploadQueueCell'
import './../ui.style.scss'

class UploadQueue extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTag: ''
    }
  }

    onDragEnd = (result) => {
      if (result.destination) {
        this.props.onReorder(result.source.index, result.destination.index)
      }
    }

    onTagSearch = (searchTag) => {
      this.setState({ searchTag })
    }

    render () {
      const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: '10px',
        background: isDragging ? null : 'white',
        ...draggableStyle
      })

      const getListStyle = isDraggingOver => ({
        width: '100%',
        background: isDraggingOver ? null : 'white'
      })

      const { listMediaCategories, onCategorySelect } = this.props

      return (
        <div className='upload'>
          <div className='upload-queue'>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                  >
                    {this.props.uploadingFiles.map((file, index) => (
                      <Draggable key={index} draggableId={`id${index}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <UploadQueueCell
                              name={file.name}
                              onClose={this.props.handleDelete}
                              index={index}
                              listMediaCategories={listMediaCategories}
                              selectedCategory={file.category}
                              onCategorySelect={(category) => onCategorySelect(index, category)} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )
    }
}
UploadQueue.propTypes = {
  /** uploadingFiles of UploadQueue. */
  uploadingFiles: PropTypes.array,
  /** Reorder action of UploadQueue. */
  onReorder: PropTypes.func,
  /** delete action of UploadQueue. */
  handleDelete: PropTypes.func,
  /** delete action of UploadQueue. */
  handleTagChange: PropTypes.func,
  /**  */
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array
}

UploadQueue.defaultProps = {
}

export default UploadQueue
