import React from 'react'
import PropTypes from 'prop-types'

const DropDownIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 10 10`}>
    <g fill='none' fillRule='evenodd'>
      <path fill={color} d='M4.707 7.366l-3.614-4.23a.386.386 0 0 1 .293-.636h7.228a.386.386 0 0 1 .293.637L5.293 7.366a.386.386 0 0 1-.586 0z' />
      <path d='M0 0h10v10H0z' />
    </g>
  </svg>
)

DropDownIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string
}

DropDownIcon.defaultProps = {
  width: 10,
  height: 10,
  color: '#343A40'
}

export default DropDownIcon
