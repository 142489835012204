import React from 'react'
import PropTypes from 'prop-types'

const MasterSettings = ({ width, height, color, bgColor, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <rect width={width} height={height} fill={bgColor} rx='1.5' />
    <path fill={color} fillRule='nonzero' d='M11.595 9l-.481-.292c-.522-.333-.522-1.125 0-1.458l.481-.292c.402-.25.522-.75.281-1.125l-.401-.708c-.241-.417-.723-.542-1.084-.292l-.482.292c-.522.333-1.204-.083-1.204-.708v-.584c0-.458-.362-.833-.803-.833h-.804c-.441 0-.803.375-.803.833v.542c0 .625-.682 1.042-1.204.708l-.482-.25a.763.763 0 0 0-1.084.292l-.401.708c-.201.417-.08.917.28 1.167l.482.292c.522.291.522 1.125 0 1.416L3.405 9c-.402.25-.522.75-.281 1.125l.401.708c.241.417.723.542 1.084.292l.482-.25c.522-.333 1.204.083 1.204.708v.584c0 .458.362.833.803.833h.804c.441 0 .803-.375.803-.833v-.542c0-.625.682-1.042 1.204-.708l.482.291a.763.763 0 0 0 1.084-.291l.401-.709c.201-.458.08-.958-.28-1.208zM7.5 9.667c-.883 0-1.606-.75-1.606-1.667S6.617 6.333 7.5 6.333c.883 0 1.606.75 1.606 1.667S8.383 9.667 7.5 9.667z' />
  </svg>
)

MasterSettings.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

MasterSettings.defaultProps = {
  width: 15,
  height: 15,
  color: '#FFF',
  bgColor: '#F5212D'
}

export default MasterSettings
