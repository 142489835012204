import React, { Component } from 'react'
import { Collapse } from 'antd'
import MatchAssetAccordionHeader from './MatchAssetAccordionHeader'

import PropTypes from 'prop-types'
const Panel = Collapse.Panel

class MatchAssetAccordion extends Component {
  render () {
    const { title, icon, childComponent } = this.props
    return (
      <Collapse defaultActiveKey={[ '1' ]} showArrow={false} className='match-asset-accordion'>
        <Panel header={<MatchAssetAccordionHeader title={title} icon={icon} hasAsset={!!(childComponent && childComponent.length > 0)} />}>
          { childComponent && childComponent.length ? childComponent : null }
        </Panel>
      </Collapse>
    )
  }
}

MatchAssetAccordion.propTypes = {
  /** icon of MatchAssetAccordionHeader. */
  icon: PropTypes.element,
  /** is Done  status of MatchAssetAccordionHeader. */
  isDone: PropTypes.bool,
  /** title of MatchAssetAccordionHeader. */
  title: PropTypes.string
}
export default MatchAssetAccordion
