import React, { Component } from 'react'
import { Table, Empty, Skeleton } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListFavouriteTeams from '../../../graphQL/customers/listFavouriteTeams'
import { utilityService } from '../../../services/UtilityService'

class FavouriteTeamsTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [

      {
        dataIndex: 'logoUrl',
        width: 30,
        key: 'logoUrl',
        render: (logoUrl) => logoUrl ? <img src={logoUrl} /> : '',
        className: 'image'
      },
      {
        title: 'Official Name',
        dataIndex: 'officialName',
        key: 'officialName',
        width: 100,
        className: 'name'
      },
      {
        title: 'Short Name',
        dataIndex: 'shortName',
        key: 'shortName',
        width: 100,
        className: 'short-name'
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        width: 100,
        className: 'country'
      }
    ]
    this.state = {
      height: null
    }
    this.tableSize = React.createRef()
  }

  componentDidMount = () => {
    this.setState({ height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
  }

  render () {
    const { favouriteTeams, isLoading } = this.props
    const { height } = this.state

    return (
      <div className='customer-details-table favourite-teams-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(favouriteTeams) ? <Table className={``} rowKey={record => record.id} columns={this.columns} dataSource={favouriteTeams} pagination={false} scroll={{ y: (height - 45) }} />
            : <Empty /> }
        </Skeleton>
      </div>
    )
  }
}

FavouriteTeamsTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListFavouriteTeams,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer } = props
        const variables = { id: selectedCustomer }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const favouriteTeams = data && data.listFavouriteTeams && data.listFavouriteTeams.teams ? data.listFavouriteTeams.teams : []
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { favouriteTeams, isLoading: data && data.loading }
      }
    }
  )
)(FavouriteTeamsTable))
