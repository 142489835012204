import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, message, Switch } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import CreateAppConfigContainer from './CreateAppConfigContainer'
import Input from './../inputs/Input'
import InputNumber from '../inputs/InputNumber'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAllTags from '../../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../../graphQL/asset/createTag'
import QueryTagType from './../../../../graphQL/admin/tagType/listTagType'
import MutationCreateAudit from './../../../../graphQL/asset/createAudit'
import MutationCreateConfig from './../../../../graphQL/appManager/createAppConfig'
import MutationUpdateConfig from './../../../../graphQL/appManager/editAppConfig'
import QueryGetAppConfig from './../../../../graphQL/appManager/getAppConfig'
import MutationDuplicateAppConfig from '../../../../graphQL/appManager/duplicateAppConfig'
import QueryUserList from './../../../../graphQL/role/listUsers'
import QueryCriticalUserList from './../../../../graphQL/appManager/listCriticalUsers'

import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'

const fieldTypeList = [
  {
    id: 'STRING',
    name: 'String',
    value: 'STRING'
  },
  {
    id: 'BUCKET',
    name: 'Bucket',
    value: 'BUCKET'
  },
  {
    id: 'JSON',
    name: 'JSON',
    value: 'JSON'
  },
  {
    id: 'NUMBER',
    name: 'Number',
    value: 'NUMBER'
  },
  {
    id: 'IMAGE',
    name: 'Image',
    value: 'IMAGE'
  },
  {
    id: 'BOOLEAN',
    name: 'Boolean',
    value: 'BOOLEAN'
  },
  {
    id: 'RICHTEXT',
    name: 'Rich Text',
    value: 'RICHTEXT'
  },
  {
    id: 'JSONARRAY',
    name: 'JSON Array',
    value: 'JSONARRAY'
  }
]

class CreateConfigModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSaving: false,
      // settings: [],
      isEditVisible: false,
      isCropVisible: false,
      selectedTags: [],
      filteredConfigName: '',
      selectedType: '',
      app: '',
      group: '',
      key: '',
      itemSelected: {},
      isEnable: false,
      isTagCreating: false,
      systemTags: [],
      showWarning: false,
      shouldSave: false,
      streamField: [],
      tempPropertyName: '',
      tempFieldType: null,
      customMeta: [],
      isCritical: false,
      isCriticalChanged: false,
      isAssigneeChanged: false,
      assignedUsers: [],
      appData: null,
      groupData: null,
      userSearch: ''
    }
  }

  resetState = () => {
    this.setState({
      settings: [],
      isSaving: false,
      isEditVisible: false,
      isCropVisible: false,
      selectedTags: [],
      streamField: [],
      filteredConfigName: '',
      selectedType: '',
      app: '',
      group: '',
      key: '',
      isEnable: false,
      isTagCreating: false,
      showWarning: false,
      shouldSave: false,
      ignoreWarning: false,
      tempFieldType: null,
      tempPropertyName: '',
      customMeta: [],
      isCritical: false,
      isCriticalChanged: false,
      isAssigneeChanged: false,
      assignedUsers: [],
      appData: null,
      groupData: null,
      userSearch: ''
    })
  }

  componentDidMount = () => {
    if (this.props.details) {
      const selectedTags = this.props.details.tags ? this.props.details.tags.filter(item => item) : []
      this.setState({
        isEditVisible: this.props.isVisible,
        filteredConfigName: this.props.isDuplicating ? 'Copy Of ' + _.get(this.props.details, 'title') : _.get(this.props.details, 'title'),
        key: this.props.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(this.props.details, 'title')).slice(0, 125).toLocaleUpperCase() : this.props.details ? this.props.details.key : '',
        app: this.props.details && this.props.details.app ? this.props.details.app.id : '',
        group: this.props.isDuplicating ? '' : this.props.details && this.props.details.group ? this.props.details.group.key : '',
        selectedTags: selectedTags,
        streamField: this.props.isDuplicating ? this.props.details.fields : this.props.details && this.props.details.fields && this.props.details.fields.length > 0 ? _.sortBy(this.props.details.fields, 'position') : [],
        customMeta: this.props.details && this.props.details.customMeta && this.props.details.customMeta.length > 0 ? this.props.details.customMeta : [],
        isCritical: (this.props.details && this.props.details.isCritical) || false,
        assignedUsers: (this.props.details && this.props.details.assignedUsers) || []
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (!_.isEmpty(nextProps.details) && !this.state.isSaving && !_.isEqual(nextProps.details, this.props.details)) {
      const selectedTags = nextProps.details.tags ? nextProps.details.tags.filter(item => item) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        filteredConfigName: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'title') : _.get(nextProps.details, 'title'),
        key: nextProps.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'title')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && nextProps.details.app ? nextProps.details.app.id : '',
        group: nextProps.isDuplicating ? '' : (nextProps.details && nextProps.details.group ? nextProps.details.group.key : ''),
        selectedTags: selectedTags,
        selectedType: nextProps.details.type ? nextProps.details.type : '',
        streamField: nextProps.isDuplicating ? nextProps.details.fields : nextProps.details && nextProps.details.fields && nextProps.details.fields.length > 0 ? _.sortBy(nextProps.details.fields, 'position') : [],
        isCritical: (nextProps.details && nextProps.details.isCritical) || false,
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || [],
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : []
      }, () => {
        if (nextProps.configMetaFieldList && nextProps.configMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.configMetaFieldList, this.state.customMeta)
        }
      })
    }

    if (!_.isEmpty(nextProps.details) && !this.state.isSaving && nextProps.isDuplicating) {
      const selectedTags = nextProps.details.tags ? nextProps.details.tags.filter(item => item) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        filteredConfigName: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'title') : _.get(nextProps.details, 'title'),
        key: nextProps.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'title')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && nextProps.details.app ? nextProps.details.app.id : '',
        group: nextProps.isDuplicating ? '' : (nextProps.details && nextProps.details.group ? nextProps.details.group.key : ''),
        selectedTags: selectedTags,
        selectedType: nextProps.details.type ? nextProps.details.type : '',
        streamField: nextProps.isDuplicating ? nextProps.details.fields : nextProps.details && nextProps.details.fields && nextProps.details.fields.length > 0 ? _.sortBy(nextProps.details.fields, 'position') : [],
        isCritical: (nextProps.details && nextProps.details.isCritical) || false,
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || [],
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : []
      }, () => {
        if (nextProps.configMetaFieldList && nextProps.configMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.configMetaFieldList, this.state.customMeta)
        }
      })
    }

    if (!_.isEmpty(nextProps.details) && !this.state.isSaving && !this.state.filteredConfigName) {
      const selectedTags = nextProps.details.tags ? nextProps.details.tags.filter(item => item) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        filteredConfigName: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'title') : _.get(nextProps.details, 'title'),
        key: nextProps.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'title')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && nextProps.details.app ? nextProps.details.app.id : '',
        group: nextProps.isDuplicating ? '' : nextProps.details && nextProps.details.group ? nextProps.details.group.key : '',
        selectedTags: selectedTags,
        selectedType: nextProps.details.type ? nextProps.details.type : '',
        streamField: nextProps.isDuplicating ? nextProps.details.fields : nextProps.details && nextProps.details.fields && nextProps.details.fields.length > 0 ? _.sortBy(nextProps.details.fields, 'position') : [],
        isCritical: (nextProps.details && nextProps.details.isCritical) || false,
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || [],
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : []
      }, () => {
        if (nextProps.configMetaFieldList && nextProps.configMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.configMetaFieldList, this.state.customMeta)
        }
      })
    }
    if (nextProps.configMetaFieldList && nextProps.configMetaFieldList.length > 0 && _.isEmpty(nextProps.details) && nextProps.isFromEdit === undefined && !(this.state.customMeta && this.state.customMeta.length)) {
      this.checkForNewMeta(this.props.configMetaFieldList, this.state.customMeta)
    }
    if (nextProps.systemTags) {
      // const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: nextProps.systemTags })
    }
    if (nextProps.details && this.props.details && !_.isEqual(nextProps.details.customMeta, this.props.details.customMeta) && !nextProps.isVisible) {
      this.setState({ customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : [] }, () => {
      })
    }
    this.setState({ isEditVisible: nextProps.isVisible })
  };

  // getDuplicateStreamFields=(fields) => {
  //   const newFields = (fields || []).filter(item => {
  //     if (item) {
  //       item.id = utilityService.makeRandomString(6)
  //       return item
  //     }
  //   })
  //   return newFields
  // }

  checkForNewMeta=(metaFieldList, customMeta) => {
    let changeExists = false;
    (metaFieldList || []).map(item => {
      const foundMeta = (customMeta || []).find(existingItem => {
        if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
          return existingItem
        }
      })

      if (_.isEmpty(foundMeta)) {
        changeExists = true
      }
    })
    if (changeExists) {
      this.handleConfigMetaDetailsChange(metaFieldList, null)
    }
  }

  handleConfigMetaDetailsChange = (metaData, value) => {
    const listCustomMetaFields = _.cloneDeep(this.props.configMetaFieldList)
    // const { details } = this.state
    const { customMeta } = this.state
    // const newDetails = _.cloneDeep(details)
    let finalMetaModified = []
    if (value || value === '' || value === false || value === 'false') {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        let newMetaField = {
          config: item,
          value: item && item.default
        }
        if (item.id === metaData.id && item.type === metaData.type) {
          newMetaField.value = item.type === 'STRING' ? (value || '') : value
          delete item.isError
          newMetaField.config = item
        } else {
          const tempItem = (customMeta || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
          if (_.isEmpty(tempItem)) {
            newMetaField.config = item
            newMetaField.value = item.default
          } else {
            newMetaField = tempItem
            newMetaField.value = tempItem.value
          }
        }

        return newMetaField
      })
      finalMetaModified = customMetaModified
    } else if (!value || value === null || value === undefined || value !== false) {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        const foundMeta = (customMeta || []).find(existingMeta => {
          if (existingMeta && existingMeta.config) {
            if (existingMeta.config.id === item.id) { return existingMeta }
          }
        })
        if (_.isEmpty(foundMeta)) {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          return newMetaField
        } else {
          return foundMeta
        }
      })
      finalMetaModified = customMetaModified
    }
    const newCustomMeta = finalMetaModified
    this.setState({ customMeta: newCustomMeta }, () => {
      this.setState({ isEnable: true })
    })
  }

  getConfigMetaFields = (metaData, selectedMeta) => {
    const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
    return (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if (customMeta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{customMeta.name}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => this.handleConfigMetaDetailsChange(customMeta, value)} />
          </div>
        </div>
      } else if (customMeta.type === 'STRING') {
        return <Input
          // limit={50}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
          handleChange={({ target }) => this.handleConfigMetaDetailsChange(customMeta, target.value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      } else if (customMeta.type === 'NUMBER') {
        return <InputNumber
          limit={16}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
          handleChange={(value) => this.handleConfigMetaDetailsChange(customMeta, value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      }
    })
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { selectedTags } = this.state
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'APP_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags, isTagCreating: true })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            this.setState({ selectedTags, isEnable: true, isTagCreating: false })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}content_bank`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags, isEnable: true, isTagCreating: false })
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
        }
      }
    }
    this.setState({ selectedTags, isEnable: true })
  }

  handleTextChange = (event) => {
    const { value } = event.target
    const { isFromEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.filteredConfigName || '')
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isFromEdit) {
      const key = utilityService.slugifyString(value).slice(0, 125).toLocaleUpperCase()
      this.setState({ filteredConfigName: value, key })
    } else {
      this.setState({ filteredConfigName: value, isEnable: true })
    }
    // this.setState({ filteredConfigName: event.target.value, isEnable: true})
  }

  handleIdChange =(e) => {
    const { isDuplicating } = this.props
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(e.target.value) ? e.target.value.toLocaleUpperCase() : this.state.key
    if (isDuplicating) {
      this.setState({ key, isEnable: true })
    }
    this.setState({ key })
  }

  handleAppChange = (option) => {
    const appData = (this.props.appList || []).find(appItem => appItem.id === option)
    const isCritical = (appData && appData.isCritical) || false
    // let users = this.state.assignedUsers || [];
    // //let isUserExist = false
    // ((appData && appData.assignedUsers) || []).map(user=>{
    //   if(!users.includes(user)){
    //     users.push(user)
    //   }
    // });
    if (this.props.isFromEdit === true) {
      this.setState({ app: option, isEnable: true, group: '', appData })
    } else {
      this.setState({ app: option, isEnable: true, group: '', appData, isCritical: isCritical })
    }
  }

  handleTypeChange = (option) => {
    this.setState({ selectedType: option, isEnable: true })
  }

  handleGroupChange = (option) => {
    const groupData = (this.props.groupList || []).find(groupItem => groupItem.key === option)
    const isCritical = (groupData && groupData.isCritical) || false

    let users = this.state.assignedUsers || []
    // let isUserExist = false
    // ((groupData && groupData.assignedUsers) || []).map(user => {
    //   if (!users.includes(user)) {
    //     users.push(user)
    //   }
    // })
    if (this.props.isFromEdit === true) {
      this.setState({ group: option, isEnable: true, groupData, assignedUsers: users })
    } else {
      this.setState({ group: option, isEnable: true, groupData, isCritical: isCritical, assignedUsers: users })
    }
  }

  onItemSelected = (itemSelected) => {
    this.setState({ itemSelected })
  }

  handleUserChange = (option) => {
    let finalOptions = (option || []).map(item => item && item.id)
    let assignedUsers = (this.props.userList || []).filter(item => finalOptions.includes(item.id)).map(item => item.id)
    // apps.sort(function (a, b) {
    //   return option.indexOf(a.name) - option.indexOf(b.name)
    // })
    // details.countries = countries

    this.setState({ assignedUsers, isEnable: true, isAssigneeChanged: true, userSearch: '' })
  }

  handleSave = async () => {
    const { isFromEdit, isDuplicating, isCriticalDisabled, details, isAppAdminDisabled, userId } = this.props
    const { filteredConfigName, key, app, group, selectedType, streamField, selectedTags, isArchived, customMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged, appData } = this.state
    const notValidField = ((streamField || []).find(item => !item.name || item.name === '' || item.name === null))
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isGroupCritical = (details && details.group && details.group.isCritical) || false
    const isConfigCritical = isAppCritical || isGroupCritical || isCritical
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)

    if (!isFromEdit && appData && appData.assignedUsers && appData.assignedUsers.length > 0 && isAppAdminDisabled) {
      const isUserAssignedToApp = ((appData && appData.assignedUsers) || []).find(user => user === userId)
      if (!isUserAssignedToApp) {
        message.error('You do not have permission to create this Config, since user is not assigned to App')
        return
      }
    }

    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Config')
      return
    }
    if (isFromEdit && !isDuplicating && isCriticalDisabled && isAppAdminDisabled && (isConfigCritical) && !isAssignedUser) {
      message.error('You do not have access to do this operation on this Config')
      return
    }
    if (!_.isEmpty(notValidField)) {
      message.warn('Please enter valid propertyName for all properties before saving')
      return
    }
    if (selectedType && selectedType === 'CONFIG_LIST') {
      const stringField = (streamField || []).find(item => item.type === 'STRING')
      if (_.isEmpty(stringField)) {
        message.warn('Please add atleast one string type property')
        return
      } else {
        const useAsTitleSet = (streamField || []).find(item => {
          if (item && item.type === 'STRING' && item.useAsTitle) {
            return item
          }
        })
        if (_.isEmpty(useAsTitleSet)) {
          message.warn('Please set a useAsTitle for String field')
          return
        } else {
          if (!useAsTitleSet.isRequired) {
            message.warn('Please set a useAsTitle for String field as mandatory')
            return
          }
        }
      }
    }

    if (assignedUsers && assignedUsers.length) {
      const finalUserList = isCritical ? this.props.criticalUserList : this.props.userList
      let inValidUsers = []
      for (let i = 0; i < assignedUsers.length; i++) {
        const isValidUser = (finalUserList || []).find(user => user.id === assignedUsers[i])
        if (_.isEmpty(isValidUser)) {
          inValidUsers.push(assignedUsers[i])
        }
      }
      if (isCritical && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Please Remove Users that are not Critical Users before saving config. Please find list of invalid users: ' + inValidUsersString)
        return
      } else if (!isCritical && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Please Remove Users that do not have required permissions before saving config. Please find list of invalid users: ' + inValidUsersString)
        return
      }
    }

    const tagsArray = (selectedTags || []).map(tag => {
      if (tag) {
        return tag.key
      }
    })
    // this.props.handleSubmit(newTitle, key, selectedType)
    const newStreamFields = (streamField || []).map(field => {
      delete field['__typename']
      field.name = field.name ? (field.name).trim() : ''
      if (field.hasOwnProperty('value')) {
        delete field['value']
      }
      return field
    })
    let duplicateExists = false
    for (let i = 0; i < newStreamFields.length; i++) {
      const duplicateField = (newStreamFields || []).find((item, index) => {
        if (item.name === newStreamFields[i].name && index !== i && item.type === newStreamFields[i].type) {
          return item
        }
      })
      if (!_.isEmpty(duplicateField)) {
        duplicateExists = true
      }
      if (duplicateExists) {
        message.error('Please remove duplicate properties before saving')
        return
      }
    }
    this.setState({ isLoading: true })
    if (this.props.checkForConfigModification) {
      if (await this.props.checkForConfigModification()) {
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
        return
      }
    }
    const newCustomMeta = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item.config && item.config.id) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      }
      return newItem
    })
    if (isFromEdit && !isDuplicating) {
      this.props.updateAppConfig(filteredConfigName, key, app, group, selectedType, newStreamFields, this.props.selectedConfig, tagsArray, isArchived, newCustomMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged).then(() => {
        message.success('Config updated successfully')
        this.setState({ isLoading: false, isEnable: false })
        // this.props.reCreatePageTree()
        this.props.refetchHistory()
        this.handleCancel()
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    } else if (isDuplicating) {
      let input = {
        id: this.props.selectedConfig,
        title: filteredConfigName,
        key: key,
        app: app,
        group: group,
        type: selectedType,
        fields: newStreamFields,
        tags: tagsArray,
        isArchived: isArchived,
        customMeta: newCustomMeta,
        isCritical: isCritical || false,
        assignedUsers: assignedUsers || []
      }
      this.props.duplicateAppConfig(input).then((res) => {
        if (res && res.data && res.data.duplicateAppConfig) {
          this.props.onSelectConfigGroup(res.data.duplicateAppConfig.id)
        }
        message.success('Config created successfully')
        this.setState({ isLoading: false, isEnable: false })
        this.props.handleRecreateConfigTree(true)
        this.props.refetchHistory()
        this.handleCancel()
      }, error => {
        utilityService.handleError(error)
        if (error.message.includes('Config with the KEY already exists')) {
          this.setState({ isLoading: false, isEnable: false, app, filteredConfigName, key, group, streamField, selectedTags, customMeta })
          return
        }
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    } else {
      this.props.createAppConfig(filteredConfigName, key, app, group, selectedType, streamField, tagsArray, newCustomMeta, isCritical, assignedUsers, isAssigneeChanged).then((res) => {
        if (res && res.data && res.data.createAppConfig) {
          message.success('Config created successfully')
          this.setState({ isLoading: false, isEnable: false })
          if (!isDuplicating) {
            this.props.handleRecreateConfigTree(true)
            if (this.props.refetchConfigList) {
              setTimeout(() => {
                this.props.refetchConfigList()
              }, 1500)
            }
            this.props.onSelectConfigGroup(res.data.createAppConfig.id)
          } else {
            this.props.handleRecreateConfigTree(true)
          }

          this.handleCancel()
        }
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    }
  }

  handleCancel = () => {
    this.resetState()
    this.props.hideConfigModal()
  }

  forceSave=() => {
    this.setState({ shouldSave: true, ignoreWarning: true, showWarning: false }, () => this.handleSave())
  }

  discardChanges=() => {
    this.setState({ showWarning: false })
  }

  reorder = (startIndex, endIndex) => {
    const { streamField } = this.state
    const result = Array.from(streamField)
    result[startIndex].position = endIndex
    result[endIndex].position = startIndex
    const [removed] = result.splice(startIndex, 1)
    removed.trackPosChange = true
    result.splice(endIndex, 0, removed)
    result.map((item, index) => {
      item.position = index + 1
      return item
    })
    // this.props.handleRatingChange(result)
    this.setState({ streamField: result, isEnable: true })
  }

  onCloseField = (index) => {
    const { streamField } = this.state
    // const index = streamField.findIndex(item => item.id === id)
    if (streamField && streamField[index] && streamField[index].useAsTitle) {
      message.warn('Cannot Remove Property that is used as Title')
      return
    }
    streamField.splice(index, 1)
    const duplicateItem = streamField.filter(item => {
      const tempIndex = streamField.findIndex(innerItem => innerItem.id !== item.id && innerItem.displayName === item.displayName && innerItem.type === item.type)
      return tempIndex !== -1
    })
    const isNoDuplicate = !!duplicateItem.length
    this.setState({ streamField, isEnable: true, isNoDuplicate })
  }

  addFieldObjects = () => {
    const { streamField, tempFieldType, tempPropertyName } = this.state
    const id = uuidv4()
    if (!tempFieldType || !tempPropertyName) {
      message.error('Please enter valid property name and type')
      return
    }
    const duplicateItem = streamField.find(item => item.name === tempPropertyName && item.type === tempFieldType)
    const newFieldObject = {
      name: tempPropertyName,
      type: tempFieldType,
      isRequired: false,
      id,
      useAsTitle: false,
      tooltipText: null,
      position: streamField && streamField.length ? streamField[streamField.length - 1].position + 1 : 1
    }
    if (!_.isEmpty(duplicateItem)) {
      message.error('Duplicate Property')
      return
    }
    streamField.push(newFieldObject)
    this.setState({ streamField, isEnable: true, tempFieldType: null, tempPropertyName: '' })
  }

  onChangePropertyName = (event, index) => {
    const { streamField } = this.state
    const { value } = event.target
    let newStreamField = _.cloneDeep(streamField)
    const duplicateItem = newStreamField.find((item, innerIndex) => ((item.name === value || item.name.trim() === value.trim()) && item.type === newStreamField[index].type && index !== innerIndex))
    newStreamField[index].name = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.type === newStreamField[index].type) {
      message.error('Duplicate Property')
      this.setState({ streamField: newStreamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField: newStreamField, isEnable: true, isNoDuplicate: false })
    }
  }

  onOptionSelect = (value, index) => {
    const { streamField } = this.state
    const duplicateItem = streamField.find(item => item.name === streamField[index].name && item.type === value)
    streamField[index].type = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.type === value) {
      message.error('Duplicate Property')
      this.setState({ streamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField, isEnable: true, isNoDuplicate: false })
    }
  }

  onChangePropertyTooltipText = (event, index) => {
    const { streamField } = this.state
    const { value } = event.target
    let newStreamField = _.cloneDeep(streamField)
    const duplicateItem = newStreamField.find((item, innerIndex) => ((item.name === value || item.name.trim() === value.trim()) && item.type === newStreamField[index].type && index !== innerIndex))
    newStreamField[index].tooltipText = value
    if (!_.isEmpty(duplicateItem) && duplicateItem.type === newStreamField[index].type) {
      message.error('Duplicate Property')
      this.setState({ streamField: newStreamField, isEnable: false, isNoDuplicate: true })
    } else {
      this.setState({ streamField: newStreamField, isEnable: true, isNoDuplicate: false })
    }
  }

  handleTempPropertyNameChange=(event) => {
    this.setState({ tempPropertyName: event.target.value })
  }

  handleTempFieldTypeChange=(option) => {
    this.setState({ tempFieldType: option })
  }

onChangeStatus = (event, index) => {
  const { streamField } = this.state
  const { checked } = event.target
  let newStreamField = _.cloneDeep(streamField)
  newStreamField[index].isRequired = checked
  this.setState({ streamField: newStreamField, isEnable: true })
}
// dummy commit for deployment
onChangeUseAsTitle=(event, index) => {
  const { streamField } = this.state
  const { value } = event.target
  let newStreamField = _.cloneDeep(streamField)
  for (let i = 0; i < newStreamField.length; i++) {
    // streamField[i].useAsTitle = false
    if (newStreamField[i].id === value) {
      newStreamField[i].useAsTitle = true
    } else {
      newStreamField[i].useAsTitle = false
    }
  }
  this.setState({ streamField: newStreamField, isEnable: true })
}

onChangeConfigStatus = (value) => {
  const { assignedUsers } = this.state
  const tempAssignedUsers = []
  if (this.props.isAppAdminDisabled) {
    message.warn('You do not have permission to update critical flag')
    return
  }

  if (assignedUsers && assignedUsers.length) {
    const finalUserList = value ? this.props.criticalUserList : this.props.userList
    let inValidUsers = []
    for (let i = 0; i < assignedUsers.length; i++) {
      const foundIndex = (finalUserList || []).findIndex(user => user.id === assignedUsers[i])
      if (foundIndex > -1) {
        tempAssignedUsers.push(assignedUsers[i])
      } else {
        inValidUsers.push(assignedUsers[i])
      }
    }
    this.setState({ assignedUsers: tempAssignedUsers })
    if (value && !_.isEmpty(inValidUsers)) {
      let inValidUsersString = inValidUsers.toString()
      message.error('Removing Users that are not Critical Users. Please find list of invalid users removed: ' + inValidUsersString)
    } else if (!value && !_.isEmpty(inValidUsers)) {
      let inValidUsersString = inValidUsers.toString()
      message.error('Removing Users that do not have required permissions. Please find list of invalid users removed: ' + inValidUsersString)
    }
  }
  this.setState({ isCritical: value, isEnable: true, isCriticalChanged: true })
}

handleUserSearch=(value) => {
  this.setState({ userSearch: value })
}

render () {
  const { details, typeList, appList, groupList, isUploadBlocked, isCreateDisabled, isUpdateBlocked, project, isFromEdit, appClient, configMetaFieldList, isDuplicating, userList, criticalUserList, isAppAdminDisabled } = this.props
  const { selectedTags, filteredConfigName, key, isSaving, app, selectedType, isTagCreating, systemTags, group, streamField, tempFieldType, tempPropertyName, isEnable, isLoading, isCritical, assignedUsers, appData, groupData, userSearch } = this.state
  const finalIsEnable = !isFromEdit ? !!(filteredConfigName && key && app && group && selectedType && !_.isEmpty(streamField) && isEnable) : !!(isEnable && group && filteredConfigName && app && selectedType && !_.isEmpty(streamField))
  const isConfigCritical = !!((isCritical || (this.props.details && this.props.details.app && this.props.details.app.isCritical) || (this.props.details && this.props.details.group && this.props.details.group.isCritical)))
  const container = this.props.isVisible ? (
    <CreateAppConfigContainer
      details={_.cloneDeep(details)}
      streamField={streamField}
      selectedTags={selectedTags || []}
      name={filteredConfigName}
      selectedKey={key}
      selectedType={selectedType}
      handleTagChange={this.handleTagChange}
      handleTypeChange={this.handleTypeChange}
      handleAppChange={this.handleAppChange}
      handleTextChange={this.handleTextChange}
      systemTags={systemTags}
      typeList={typeList}
      appList={appList}
      groupList={groupList}
      selectedApp={app}
      isUploadBlocked={isUploadBlocked}
      selectedGroup={group}
      handleGroupChange={this.handleGroupChange}
      addFieldObjects={this.addFieldObjects}
      handleTempPropertyNameChange={this.handleTempPropertyNameChange}
      handleTempFieldTypeChange={this.handleTempFieldTypeChange}
      tempFieldType={tempFieldType}
      tempPropertyName={tempPropertyName}
      fieldTypeList={fieldTypeList}
      onChangePropertyName={this.onChangePropertyName}
      onChangePropertyTooltipText={this.onChangePropertyTooltipText}
      onOptionSelect={this.onOptionSelect}
      onChangeStatus={this.onChangeStatus}
      handleIdChange={this.handleIdChange}
      onCloseField={this.onCloseField}
      onChangeUseAsTitle={this.onChangeUseAsTitle}
      onChangeConfigStatus={this.onChangeConfigStatus}
      isCritical={isCritical}
      reorder={this.reorder}
      isFromEdit={isFromEdit}
      configMetaFields={this.getConfigMetaFields(this.state.customMeta, configMetaFieldList)}
      isDuplicating={isDuplicating}
      appData={appData}
      groupData={groupData}
      appClient={appClient}
      project={project}
      assignedUsers={assignedUsers}
      handleUserChange={this.handleUserChange}
      userList={isConfigCritical ? criticalUserList : userList}
      isAppAdminDisabled={isAppAdminDisabled}
      handleUserSearch={this.handleUserSearch}
      userSearch={userSearch}
    />
  ) : <div />
  return (
    <div>
      <Modal
        className='confirm-modal edit-image'
        title={details ? isDuplicating ? 'DUPLICATE CONFIG' : 'EDIT CONFIG' : 'CREATE CONFIG'}
        visible={this.props.isVisible}
        okText='Save'
        okButtonProps={{ disabled: (!finalIsEnable || (isUpdateBlocked && isCreateDisabled) || isTagCreating), loading: isLoading }}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        closable={false}
        confirmLoading={isSaving}
        destroyOnClose
        maskClosable={false}
        centered
        width='890px'
      >
        {container}
      </Modal>
    </div>
  )
}
}

CreateConfigModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** id of the selected image */
  imageId: PropTypes.string,
  /**  */
  createTag: PropTypes.func,
  /**  */
  tagsList: PropTypes.array,
  /** Callback for save content */
  updateImage: PropTypes.func,
  /**  */
  handleSuccess: PropTypes.func,
  /**  */
  mediaDetails: PropTypes.object,
  /** Callback to hide image editor */
  hideImageEditor: PropTypes.func
}

CreateConfigModal.defaultProps = {}

export default withApollo(
  compose(
    graphql(MutationCreateTag, {
      // TODO: The code for MutationCreateTag could be consolidated to keep the code DRY
      options: ({ project, appClient }) => {
        return {
          update: (cache, { data: { createTag } }) => {
            const filter = { type: { match: 'MANUAL' }, module: { exact: 'APP_MANAGER' } }
            try {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
              if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
              cache.writeQuery({
                query: QueryAllTags,
                data: cacheData
              })
            } catch (e) {
              console.log('error', encodeURI)
            }
          }
          // client: appClient
        }
      },
      props: props => ({
        createTag: contentObj => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),

    graphql(
      QueryTagType,
      {
        options: ({ appClient, project }) => {
          return {
            fetchPolicy: 'network-only',
            variables: { filter: null, limit: 500, project }
            // client: appClient
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    ),
    graphql(
      MutationCreateAudit,
      {
        props: (props) => ({
          createAudit: (variables) => {
            return props.mutate({
              variables
            })
          }
        })
      }
    ),
    graphql(MutationCreateConfig, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { createAppConfig } }) => {
            // const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppConfigList, variables: { limit: 499, project } }))
            // if (cacheData && cacheData.listAppConfig && cacheData.listAppConfig.items) { cacheData.listAppConfig.items.push(createAppConfig) }
            // cache.writeQuery({
            //   query: QueryGetAppConfigList,
            //   data: cacheData
            // })
          },
          client: appClient
          // refetchQueries: () => [{ query: QueryGetAppConfigList,
          //   variables: { limit: 499, project } }]
        }
      },
      props: props => ({
        createAppConfig: (title, key, app, group, type, fields, tags, customMeta, isCritical, assignedUsers) => {
          return props.mutate({
            variables: { title, key, app, group, type, fields, tags, project: props.ownProps.project, customMeta, isCritical, assignedUsers }
          })
        }
      })
    }),
    graphql(MutationUpdateConfig, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { editAppConfig } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppConfig, variables: { id: editAppConfig.id, project } }))
            if (cacheData && cacheData.editAppConfig) {
              cacheData.editAppConfig.data = editAppConfig
            }
            cache.writeQuery({
              query: QueryGetAppConfig,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        updateAppConfig: (title, key, app, group, type, fields, id, tags, isArchived, customMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged) => {
          return props.mutate({
            variables: { title, key, app, group, type, fields, project: props.ownProps.project, id, tags, isArchived, customMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged }
          })
        }
      })
    }),
    graphql(
      MutationDuplicateAppConfig, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          duplicateAppConfig: (input) => {
            return props.mutate({
              variables: { input, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      QueryUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            userList: data.listUsers && data.listUsers.items && data.listUsers.items.length ? data.listUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listUsers ? data.listUsers.totalCount : 0,
            getMoreUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listUsers.items, ...fetchMoreResult.listUsers.items]
                  prev.listUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    ),
    graphql(
      QueryCriticalUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            criticalUserList: data.listCriticalUsers && data.listCriticalUsers.items && data.listCriticalUsers.items.length ? data.listCriticalUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listCriticalUsers ? data.listCriticalUsers.totalCount : 0,
            getMoreCriticalUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listCriticalUsers.items, ...fetchMoreResult.listCriticalUsers.items]
                  prev.listCriticalUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    )
  )(CreateConfigModal)
)
