import React, { Component } from 'react'
import { Table, Empty, Skeleton } from 'antd'
import { Resizable } from 'react-resizable'
import moment from 'moment'

import Tooltip from './ToolTip'
import OfferDetailsModal from '../dataEntry/other/OfferDetailsModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCustomerProfile from '../../../graphQL/customers/getCustomerProfile'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class TransactionTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (id) => this.renderId(id)
      },
      {
        title: 'Sub ID',
        dataIndex: 'subscriptionId',
        width: 100,
        key: 'subscriptionId'
      },
      {
        title: 'Transaction Date',
        dataIndex: 'transactionDate',
        key: 'transactionDate',
        width: 100,
        render: (transactionDate) => transactionDate ? moment(new Date(transactionDate)).format('DD MMM YYYY, HH:mm:ss') : ''
      },
      {
        title: 'Transaction Amount',
        dataIndex: 'transactionAmount',
        key: 'transactionAmount',
        width: 100
      },
      {
        title: 'Coverage period',
        dataIndex: 'coveragePeriod',
        width: 100,
        key: 'coveragePeriod'
      },
      {
        title: 'Payment Method',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        width: 100
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100
      },
      {
        title: 'Offer Snapshot',
        dataIndex: 'id',
        key: 'offer',
        width: 100,
        render: (id, data) => this.renderOffer(id, data)
      },
      {
        title: 'Sub Type  Snapshot',
        dataIndex: 'id',
        width: 100,
        key: 'subscriptionType',
        render: (id, data) => this.renderSubType(id, data)
      },
      {
        title: 'System Log',
        dataIndex: 'id',
        width: 100,
        key: 'log',
        render: (id, data) => this.renderSystemLog(id, data)
      }
    ]
    this.state = {
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
    if (newProps.profileOptionShown === true) {
      this.setState({ shouldShowPopup: false })
    }
  }

  showViewOfferModal = (child, type) => {
    this.setState({ showOfferModal: true, offerChild: child, type })
  }

  hideViewOfferModal = () => {
    this.setState({ showOfferModal: false, offerChild: null })
  }

  renderOffer = (id, { offerSnapshot }) => {
    let description
    if (offerSnapshot) {
      description = <div className='details'>
        <span className='heading'>{ offerSnapshot && offerSnapshot.title ? offerSnapshot.title : '' }</span>
        <p><span className='key'>ID:</span><span>{ offerSnapshot && offerSnapshot.id ? offerSnapshot.id : '-'}</span></p>
        <p><span className='key'>Offer Code:</span><span>{offerSnapshot && offerSnapshot.offerCode ? offerSnapshot.offerCode : '-'}</span></p>
        <p><span className='key'>Description:</span><span>{ offerSnapshot && offerSnapshot.description ? offerSnapshot.description : '-'}</span></p>
        <p><span className='key'>Type:</span><span>{offerSnapshot && offerSnapshot.type ? offerSnapshot.type : '-'}</span></p>
        <p><span className='key'>Auto Renew Period:</span><span>{ offerSnapshot && offerSnapshot.autoRenewPeriod ? offerSnapshot.autoRenewPeriod : '-'}</span></p>
        <p><span className='key'>Price:</span><span>{offerSnapshot && offerSnapshot.price ? offerSnapshot.price : '-'}</span></p>
      </div>
    }
    return offerSnapshot ? <div className='comments' onClick={() => this.showViewOfferModal(description, 'offer')}>View Details</div> : ''
  }

  renderSubType = (id, { subscriptionTypeSnapshot }) => {
    let description
    if (subscriptionTypeSnapshot) {
      description = <div className='details'>
        <span className='heading'>{ subscriptionTypeSnapshot && subscriptionTypeSnapshot.title ? subscriptionTypeSnapshot.title : '' }</span>
        <p><span className='key'>ID:</span><span>{subscriptionTypeSnapshot && subscriptionTypeSnapshot.id ? subscriptionTypeSnapshot.id : '-'}</span></p>
        <p><span className='key'>Description:</span><span>{subscriptionTypeSnapshot && subscriptionTypeSnapshot.description ? subscriptionTypeSnapshot.description : '-'}</span></p>
        <p><span className='key'>Access Groups:</span><span>{ (subscriptionTypeSnapshot && subscriptionTypeSnapshot.accessGroups && subscriptionTypeSnapshot.accessGroups.length ? (subscriptionTypeSnapshot.accessGroups || []).map(item => <label >{`${item.id} - ${item.name}`} </label>) : '') }</span></p>
        <p><span className='key'>Active Session:</span><span>{subscriptionTypeSnapshot && subscriptionTypeSnapshot.activeSession ? subscriptionTypeSnapshot.activeSession : '-'}</span></p>
        <p><span className='key'>Quality of Streams:</span><span>{subscriptionTypeSnapshot && subscriptionTypeSnapshot.quality ? subscriptionTypeSnapshot.quality : '-'}</span></p>
      </div>
    }
    return subscriptionTypeSnapshot ? <div className='comments' onClick={() => this.showViewOfferModal(description, 'sub')}>View Details</div> : ''
  }

  renderSystemLog = (id, { systemLog }) => {
    const description = <div>
      <span className='heading'>{ 'System Logs' }</span>
      <p><span className='key'>Request PayLoad:</span>{systemLog && systemLog.requestPayload ? systemLog.requestPayload : '-'}</p>
      <p><span className='key'>Error:</span>{systemLog && systemLog.error ? systemLog.error : '-'}</p>
    </div>
    const childComponent = (<span style={{ color: '#FF015A' }}>{ 'Logs' }</span>)
    return (
      !_.isEmpty(systemLog) ? <Tooltip placement='rightTop' key={id} heading={'System Logs'} childComponent={childComponent} description={description} parentCompoent={'root'} /> : '')
  }

  renderId = (id) => {
    return (<Tooltip placement='top' key={id} childComponent={id} description={id} parentCompoent={'root'} />)
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  render () {
    const { purchaseHistory, isLoading } = this.props
    const { width, height, columns, showOfferModal, offerChild, type } = this.state

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))

    return (
      <div className='general-customer-details-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(purchaseHistory) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={purchaseHistory} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
        <OfferDetailsModal isVisible={showOfferModal} title={type === 'offer' ? 'OFFER SNAPSHOT DETAILS' : 'SUB TYPE SNAPSHOT DETAILS'} child={offerChild} handleCancel={this.hideViewOfferModal} />
      </div>
    )
  }
}

TransactionTable.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCustomerProfile,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer } = props
        const variables = { id: selectedCustomer }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const purchaseHistory = data && data.getCustomer && data.getCustomer.purchaseHistory ? data.getCustomer.purchaseHistory : []
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return { purchaseHistory, isLoading: data && data.loading }
      }
    }
  )
)(TransactionTable))
