import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import AppContext from '../../../AppContext'
import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateCountryModal from '../../../components/ui/dataEntry/other/CreateCountryModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryCountry from './../../../graphQL/admin/entertainmentData/listCountry'
import MutationDeleteCountry from '../../../graphQL/admin/entertainmentData/deleteCountry'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

const deleteCountryMessage = {
  title: 'DELETE COUNTRY',
  firstMessage: 'Are you sure you want to delete this Country?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  }
]

class CountryList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedCountry: null,
      isLoading: !(props.countryList && props.countryList.length),
      showAddCountryModal: false,
      showDeleteCountryModal: false,
      selectedCountry: undefined,
      deleteLoading: false,
      isDeleted: false,
      countrySearch: { searchString1: '', searchString2: '' }
    }
    this.columns = [
      {
        title: 'Countries',
        dataIndex: 'name',
        key: 'name',
        width: '37%',
        className: 'entertainment-data'
      },
      {
        title: 'Usage Count',
        dataIndex: 'assetCount',
        width: '50%',
        key: 'assetCount',
        render: (assetCount, { isError }) => this.renderAssetCount(assetCount, isError)
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id) => this.renderData(id)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.countryList, this.props.countryList)) {
        this.setState({ isSearching: false, isPaginating: false, isLoading: false })
      } else if (!newProps.isLoading && this.props.isLoading && this.state.isSearching && _.isEqual(newProps.countryList, this.props.countryList)) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEqual(newProps.countryList, this.props.countryList) && this.props.countryFilter && this.props.countryFilter.searchString) {
        this.setState({ isSearching: false, isLoading: false })
      } else if (!newProps.isLoading && _.isEmpty(newProps.countryList)) {
        this.setState({ isLoading: false })
      }
    }

    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedCountry) {
        this.setState({ openedCountry: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedCountry === id) {
        this.setState({ openedCountry: null })
      } else {
        this.setState({ openedCountry: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { countryList } = this.props
      const selectedCountry = countryList.find(item => item.id === id)
      if (!selectedCountry) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddCountryModal: true, selectedCountry })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteCountryModal: true, selectedCountry })
      }
      this.setState({ openedCountry: null })
    }

    onCountrySelect = (selectedValue, field) => {
      if (field === 'to') {
        this.setState({ toSelectedCountry: selectedValue })
      } else {
        this.setState({ fromSelectedCountry: selectedValue })
      }
    }

    showAddCountry = () => {
      this.setState({ showAddCountryModal: true })
    }

    hideAddCountryModal = () => {
      this.setState({ showAddCountryModal: false, selectedCountry: null })
    }

    handleAddCountry = () => {
      this.setState({ showAddCountryModal: false, selectedCountry: null })
    }

    hideDeleteCountryModal = () => {
      this.setState({ isDeleted: true, selectedCountry: null }, () => {
        this.setState({ showDeleteCountryModal: false, isDeleted: false })
      })
    }

    handleDeleteCountry = () => {
      const { selectedCountry } = this.state
      const { onHistoryUpdate } = this.props
      if (!selectedCountry) {
        return
      }
      this.setState({ deleteLoading: true })
      this.props.deleteCountry(selectedCountry.id, selectedCountry.name).then(() => {
        message.success('Country deleted successfully')
        this.setState({ isDeleted: true, selectedCountry: null }, () => {
          this.setState({ showDeleteCountryModal: false, deleteLoading: false, isDeleted: false })
        })
        onHistoryUpdate()
      }, error => {
        this.setState({ isDeleted: true, selectedCountry: null }, () => {
          this.setState({ showDeleteCountryModal: false, deleteLoading: false, isDeleted: false })
        })
        utilityService.handleError(error)
      })
    }

    onSearchOptions = (value, isFirst) => {
      let { countrySearch } = this.state
      if (isFirst) {
        countrySearch.searchString1 = value
      } else {
        countrySearch.searchString2 = value
      }
      this.setState({ countrySearch })
    }

    onChangeFilter = value => {
      this.setState({ isLoading: true, isSearching: true })
      this.props.onChangeCountryFilter({ searchString: value })
    }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.countryList.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreCountries(this.props.countryList.length))
    }

    renderAssetCount = (count, isError) => {
      if (isError) {
        return <div>{'Error in Transfer. Retry'}</div>
      } else if (!count) {
        return '0'
      } else {
        return count
      }
    }

    renderData = (id) => {
      let availableOptions = _.cloneDeep(options)
      const { openedCountry } = this.state
      const { countryList } = this.props
      const selectedCountry = countryList.find(item => item.id === id)
      if (selectedCountry && (!selectedCountry.assetCount || !parseInt(selectedCountry.assetCount)) && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (!selectedCountry.assetCount || !parseInt(selectedCountry.assetCount)) {
        // availableOptions.splice(1, 1)
        if (this.isUpdateDisabled) {
          availableOptions.splice(0, 1)
        }
      } else if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id='option-list'>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={'option-list'}
              isVisible={openedCountry === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { countryList, countryFilter, onHistoryUpdate } = this.props
      const { isLoading, isPaginating, showAddCountryModal, showDeleteCountryModal, selectedCountry, deleteLoading, isDeleted } = this.state
      let adminItem
      if (selectedCountry && showDeleteCountryModal) {
        adminItem = <div >
          <p>{ selectedCountry.name }</p>
        </div>
      }
      return (
        <AppContext.Consumer>
          {({ permissions, project }) => {
            const userPermissions = permissions['DATA_MANAGER']
            const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
            this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
            this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
            return <div className='admin-portal entertainment-list'>
              <FilterInput
                searchString={countryFilter.searchString}
                onChangeSearchInput={this.onChangeFilter}
                placement='rightTop'
                project={project}
              />
              <div className='admin-list country-list'>
                <FixedTableHeader columnCellList={this.columns} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreAsset}
                  hasMore={this.props.totalCount > countryList.length}
                  initialLoad={false}
                  useWindow={false}
                >
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <Table className={`general-table ${isPaginating ? 'paginating' : ''}`} rowKey={record => record.id} columns={this.columns} dataSource={countryList} pagination={false} />
                  </Skeleton>
                  <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                </InfiniteScroll>
              </div>
              <div className='admin-footer' >
                <LoadingButton
                  type='primary'
                  onClick={this.showAddCountry}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={isCreateDisabled}
                />
              </div>
              <CreateCountryModal
                isVisible={showAddCountryModal}
                handleCancel={this.hideAddCountryModal}
                handleSubmit={this.handleAddCountry}
                selectedCountry={selectedCountry}
                listApi module='DATA_MANAGER'
                isSubmitDisabled={_.isEmpty(selectedCountry) ? isCreateDisabled : this.isUpdateDisabled}
                onHistoryUpdate={onHistoryUpdate}
                isDeleted={isDeleted}
                searchString={countryFilter.searchString}
                project={project}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteCountryModal}
                title={deleteCountryMessage.title}
                firstMessage={deleteCountryMessage.firstMessage}
                secondMessage={deleteCountryMessage.secondMessage}
                adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteCountryModal}
                handleSubmit={this.handleDeleteCountry}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={deleteLoading} />
            </div>
          }}
        </AppContext.Consumer>
      )
    }
}

CountryList.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryCountry,
    {
      options: ({ countryFilter, project }) => {
        const { searchString } = countryFilter
        const search = searchString ? { keyword: searchString, fields: ['name'] } : null
        const variables = { search, offset: 0, limit: 20, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        let countryList = data.listCountry ? data.listCountry.items : []
        countryList = countryList.map(item => {
          if (item.isTransferring) {
            item.assetCount = 'Transferring'
          }
          return item
        })
        return {
          countryList,
          isLoading: data.loading || !data.listCountry,
          totalCount: data.listCountry ? data.listCountry.totalCount : 0,
          getMoreCountries: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listCountry.items, ...fetchMoreResult.listCountry.items]
                prev.listCountry.items = newList
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationDeleteCountry, {
      options: ({ countryFilter, project }) => ({
        update: (cache, { data: { deleteCountry } }) => {
          const { searchString } = countryFilter
          const search = searchString ? { keyword: searchString, fields: ['name'] } : null
          const variables = { search, offset: 0, limit: 20, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryCountry, variables }))
          if (cacheData && cacheData.listCountry && cacheData.listCountry.items) {
            const selectedIndex = cacheData.listCountry.items.findIndex(item => item.id === deleteCountry.id)
            if (selectedIndex > -1) {
              cacheData.listCountry.items.splice(selectedIndex, 1)
              cacheData.listCountry.totalCount = cacheData.listCountry.totalCount - 1
            }
          }
          cache.writeQuery({
            query: QueryCountry,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteCountry: (id, name) => {
          return props.mutate({
            variables: { id, name, project: props.ownProps.project }
          })
        }
      })
    }
  )
)(CountryList))
