import React from 'react'
import PropTypes from 'prop-types'

const CollapsedIcon = ({ width, height, color, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h15v15H0z' />
      <path fill={color} d='M12 14.09l-.97.91L3 7.5 11.03 0l.97.905L4.945 7.5z' />
    </g>
  </svg>
)

CollapsedIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

CollapsedIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#343A40'
}

export default CollapsedIcon
