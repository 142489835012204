import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, DatePicker, message } from 'antd'
import Input from '../inputs/Input'
import './../../ui.style.scss'
import moment from 'moment'
import { utilityService } from './../../../../services/UtilityService'
import userMessages from '../../../../constants/messages'

class CreateCustomerModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      address: '',
      mobile: '',
      postcode: '',
      city: '',
      state: '',
      country: '',
      dateOfBirth: null,
      isNameError: false,
      isEdited: false
    }
  }

  handleChangeEmail = (event) => {
    const email = event.target.value
    this.setState({ email, isEdited: true })
  }

  handleChangePassword = (event) => {
    const password = event.target.value
    this.setState({ password, isEdited: true })
  }

  handleChangeFname = (event) => {
    const firstName = event.target.value
    this.setState({ firstName, isEdited: true, isNameError: !firstName })
  }

  handleChangeLname = (event) => {
    const lastName = event.target.value
    this.setState({ lastName, isEdited: true })
  }

  handleChangeMobileNumber = (event) => {
    const mobile = event.target.value
    this.setState({ mobile, isEdited: true })
  }

  handleChangeAddress = (event) => {
    const address = event.target.value
    this.setState({ address, isEdited: true })
  }

  handleChangePostCode = (event) => {
    const postcode = event.target.value
    const regExp = /^\d*$/g
    if (regExp.test(postcode) || postcode === '') { this.setState({ postcode, isEdited: true }) }
  }

  // handleGeneralChange = (e) => {
  //   const { details } = this.state
  //   const { name, value } = e.target
  //   if (!value.startsWith(' ')) {
  //     if (name === 'opId') {
  //       const regexExp = /^[0-9]*$/
  //       const isNum = regexExp.test(value)
  //       if (isNum) {
  //         details[name] = value
  //       }
  //     } else {
  //       details[name] = value
  //     }
  //     this.setState({ details }, () => this.props.onEditSyncState(details))
  //   }
  // }

  handleChangeCity = (event) => {
    const city = event.target.value
    this.setState({ city, isEdited: true })
  }

  handleChangeState = (event) => {
    const state = event.target.value
    this.setState({ state, isEdited: true })
  }

  handleChangeCountry = (event) => {
    const country = event.target.value
    this.setState({ country, isEdited: true })
  }

  onChangeDOB = (dateOfBirth) => {
    this.setState({ dateOfBirth, isEdited: true })
  }

  handleSubmit = () => {
    const { email, password, firstName, lastName, mobile, address, postcode, city, state, country, dateOfBirth } = this.state
    const isValidEmail = utilityService.checkEmailValidation(email)
    const isValidPassword = utilityService.checkPasswordValidation(password)
    if (!isValidEmail) {
      message.warn(userMessages.LOGIN_EMAIL_VALIDATION_FAILURE)
      return
    }
    if (!isValidPassword) {
      message.warn(userMessages.LOGIN_PASSWORD_VALIDATION_FAILURE)
      return
    }
    const regExp = /^[+]*[0-9]*$/g
    if (mobile && !regExp.test(mobile)) {
      message.warn('Invalid Mobile Number')
      return
    }
    const details = {
      firstName: firstName || '',
      lastName: lastName || '',
      mobile: mobile || '',
      address: address || '',
      postcode: postcode || '',
      city: city || '',
      state: state || '',
      country: country || '',
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth).toISOString() : null
    }
    // if (!update.postcode) { delete (update.postcode) }
    const data = {
      email,
      password,
      details
    }
    this.props.handleSubmit(data)
  }
  clearState = () => {
    this.setState({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      mobile: '',
      address: '',
      postcode: '',
      city: '',
      state: '',
      country: '',
      dateOfBirth: null,
      isNameError: false,
      isEdited: false
    })
  }
  render () {
    const { isVisible, isSubmitDisabled, handleCancel, isLoading } = this.props
    const { email, password, firstName, isNameError, lastName, mobile, address, postcode, city, state, country, isEdited, dateOfBirth } = this.state
    const isEnable = isEdited
    return (
      <Modal
        className='general-modal create-customer'
        title={'CREATE CUSTOMER '}
        maskClosable={false}
        visible={isVisible}
        okText='Save'
        cancelText='Back'
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ disabled: (isSubmitDisabled || !isEnable) }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoading}
        afterClose={this.clearState}
      >
        { isVisible
          ? <div className='edit-cutomer-container'>
            <Input
              title='Email'
              inputName='email'
              type='email'
              handleChange={this.handleChangeEmail}
              value={email}
              placeholder='Enter email id'
              isRequired />
            <Input
              title='Password'
              inputName='password'
              type='password'
              handleChange={this.handleChangePassword}
              value={password}
              placeholder='Enter password'
              isRequired />
            <Input
              title='First Name'
              inputName='fName'
              handleChange={this.handleChangeFname}
              value={firstName}
              placeholder='Enter first name'
              isError={isNameError}
              errorMessage='Please enter first name' />
            <Input
              title='Last Name'
              inputName='lName'
              handleChange={this.handleChangeLname}
              value={lastName}
              placeholder='Enter last name' />
            <div className='date-picker-container'>
              <label>Date of Birth</label>
              <DatePicker
                value={dateOfBirth ? moment(dateOfBirth) : null}
                format='DD MMM YYYY'
                onChange={this.onChangeDOB}
                placeholder='Please select date of birth'
                allowClear
                timeDisabled
              />
            </div>
            <Input
              title='Mobile Number'
              inputName='mobile'
              handleChange={this.handleChangeMobileNumber}
              value={mobile}
              placeholder='Enter mobile number' />
            <Input
              title='Address'
              inputName='address'
              handleChange={this.handleChangeAddress}
              value={address}
              placeholder='Enter Address' />
            <Input
              title='ZIP'
              inputName='postcode'
              handleChange={this.handleChangePostCode}
              value={postcode}
              placeholder='Enter Post Code' />
            <Input
              title='City'
              inputName='city'
              handleChange={this.handleChangeCity}
              value={city}
              placeholder='Enter city name' />
            <Input
              title='State'
              inputName='state'
              handleChange={this.handleChangeState}
              value={state}
              placeholder='Enter state name' />
            <Input
              title='Country'
              inputName='country'
              handleChange={this.handleChangeCountry}
              value={country}
              placeholder='Enter country name' />
          </div> : '' }
      </Modal>
    )
  }
}

CreateCustomerModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

CreateCustomerModal.defaultProps = {
}

export default CreateCustomerModal
