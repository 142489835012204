import gql from 'graphql-tag'

export default gql(`
mutation createFilter($type:String! $name: String! $fields: [CreateFieldInput] $project: String){
    createFilter(input:{
        type:$type
        name:$name
        fields: $fields
        project: $project
      }
    ){
        id
        name
        fields{
            displayName
            name
            type
            value
            fieldName
            displayValue
        }
    }
  }
`)
