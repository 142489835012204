import * as React from 'react'
import PropTypes from 'prop-types'

class FixedTableHeader extends React.Component {
  render () {
    const { columnCellList, isExpandable, className } = this.props
    return <div className={className || 'fixed-header-component'}>
      {isExpandable && <div className='expandable' />}
      {(columnCellList || []).map((column, index) => <div style={{ flex: parseInt(column.width) }} key={index}>{column.title}</div>)}
    </div>
  }
}

FixedTableHeader.propTypes = {
  /** list of column cells contains width and title */
  columnCellList: PropTypes.arrayOf(PropTypes.object),
  /** Boolean for indicating expanted rows */
  isExpandable: PropTypes.bool
}

export default FixedTableHeader
