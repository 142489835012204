import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import PropTypes from 'prop-types'

import CreditCrewFieldCell from './CreditCrewFieldCell'

class CreditCrewFieldComponent extends Component {
    onDragEnd = result => {
      if (!result.destination) {
        return
      }
      this.props.reorder(result.source.index, result.destination.index)
    }

    render () {
      const { credits, onCloseField, onRoleSelect, onPersonChange, showCreatePersonModal, onPersonSearch, onRoleSearch,
        isCreateDisabled, roles, persons, roleSearch, isUpdateDisabled, project } = this.props
      const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle
      })

      const getListStyle = isDraggingOver => ({
        borderTop: isDraggingOver ? '1px solid #cfd0d1' : 'none'
      })

      return (
        <div className='credit-field-objects'>
          <div className='credit-data'>
            {credits && credits.length
              ? <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(droppableProvided, droppableSnapshot) => (
                    <div className='credit-content'
                      ref={droppableProvided.innerRef}
                      style={getListStyle(droppableSnapshot.isDraggingOver)}
                    >
                      {credits.map((field, index) => (
                        <Draggable key={field.id} draggableId={`id${index}`} index={index} isDragDisabled={isUpdateDisabled}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <CreditCrewFieldCell
                                field={field}
                                onCloseField={onCloseField}
                                onRoleSelect={onRoleSelect}
                                onPersonChange={onPersonChange}
                                showCreatePersonModal={showCreatePersonModal}
                                onPersonSearch={onPersonSearch}
                                onRoleSearch={onRoleSearch}
                                roleSearch={roleSearch}
                                isCreateDisabled={isCreateDisabled}
                                roles={roles}
                                persons={persons}
                                isUpdateDisabled={isUpdateDisabled}
                                project={project}
                                index={index} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              : null }
          </div>
        </div>
      )
    }
}

CreditCrewFieldComponent.propTypes = {
  /** function to reorder creditCrewFieldComponent */
  reorder: PropTypes.func,
  /** function to close creditCrewFieldComponent */
  onCloseField: PropTypes.func,
  /** function to handle personChange */
  onPersonChange: PropTypes.func,
  /** function to handle person search */
  onPersonSearch: PropTypes.func,
  /** function to handle role change */
  onRoleSelect: PropTypes.func,
  /** all credits */
  credits: PropTypes.array,
  /** roles details */
  roles: PropTypes.array,
  /** persons details */
  persons: PropTypes.array,
  /** Boolean for the creation of new person modal */
  isCreateDisabled: PropTypes.bool
}

export default CreditCrewFieldComponent
