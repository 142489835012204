import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, DatePicker, message, Switch } from 'antd'
import Input from '../inputs/Input'
import moment from 'moment'
import _ from 'lodash'
import './../../ui.style.scss'
import CurrencyDropDown from './../inputs/CurrencyDropDown'
import InputNumber from './../inputs/InputNumber'

const parentCompoent = 'edit-price-container'

const { RangePicker } = DatePicker

class CreatePriceModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      id: '',
      name: '',
      currency: '',
      displayPrice: '',
      startDate: '',
      endDate: '',
      wholeSalePrice: '',
      rrpIncGst: '',
      rrpExcGst: '',
      gstAmount: '',
      promoId: '',
      customMeta: [],
      isNameError: false,
      isEdited: false,
      isLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    if (nextProps.editPrice) {
      const { id, name, currency, displayPrice, wholeSalePrice, gstAmount, rrpIncGst, rrpExcGst, startDate, endDate, promoId, customMeta } = nextProps.editPrice
      this.setState({
        id,
        name,
        currency,
        displayPrice,
        wholeSalePrice,
        gstAmount,
        rrpIncGst,
        rrpExcGst,
        startDate,
        endDate,
        promoId,
        customMeta,
        isEdited: false
      }, () => {
        if (nextProps.priceMetaFieldList && nextProps.priceMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.priceMetaFieldList, this.state.customMeta)
        }
      })
    }
    if (nextProps.priceMetaFieldList && nextProps.priceMetaFieldList.length > 0 && !nextProps.editPrice && !(this.state.customMeta && this.state.customMeta.length)) {
      this.checkForNewMeta(this.props.priceMetaFieldList, this.state.customMeta)
    }
  }

  checkForNewMeta=(metaFieldList, customMeta) => {
    let changeExists = false;
    (metaFieldList || []).map(item => {
      const foundMeta = (customMeta || []).find(existingItem => {
        if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
          return existingItem
        }
      })

      if (_.isEmpty(foundMeta)) {
        changeExists = true
      }
    })
    if (changeExists) {
      this.handlePriceMetaDetailsChange(metaFieldList, null)
    }
  }

  clearState = () => {
    this.setState({
      name: '',
      currency: '',
      displayPrice: '',
      startDate: '',
      endDate: '',
      wholeSalePrice: '',
      rrpIncGst: '',
      rrpExcGst: '',
      gstAmount: '',
      promoId: '',
      customMeta: [],
      isNameError: false,
      isEdited: false,
      isLoading: false
    })
  }

  checkForDuplicateDate=(currentActivePrice) => {
    const { id, startDate, endDate } = this.state
    let warning = false
    if (currentActivePrice && currentActivePrice.startDate && currentActivePrice.endDate && startDate && endDate && id && id === currentActivePrice.id) {
      warning = false
    } else if (currentActivePrice && currentActivePrice.startDate && currentActivePrice.endDate && startDate && endDate && ((id && id !== currentActivePrice.id) || !id)) {
      warning = moment(startDate).isBetween(moment(currentActivePrice && currentActivePrice.startDate), moment(currentActivePrice && currentActivePrice.endDate)) || moment(endDate).isBetween(moment(currentActivePrice && currentActivePrice.startDate), moment(currentActivePrice && currentActivePrice.endDate)) || moment(currentActivePrice.startDate).isBetween(moment(startDate), moment(endDate)) || moment(currentActivePrice.endDate).isBetween(moment(startDate), moment(endDate))
    }
    return warning
  }

  handleSubmit = () => {
    const { name, currency, displayPrice, wholeSalePrice, gstAmount, rrpIncGst, rrpExcGst, startDate, endDate, promoId, customMeta } = this.state
    if (!name || !startDate || !endDate) {
      message.error('Enter name and Date')
      return
    }
    const { prices, activePricesWithPromoId } = this.props
    let warning = false
    if (promoId && _.has(activePricesWithPromoId, promoId)) {
      const relatedPrices = (prices || []).filter(price => {
        if (price) {
          return price.promoId === promoId
        }
      })
      relatedPrices.forEach(relatedPrice => {
        if (this.checkForDuplicateDate(relatedPrice)) {
          warning = true
        }
      })
    } else if (!promoId || promoId === '') {
      const relatedPrices = (prices || []).filter(price => {
        if (price) {
          return price.promoId === promoId
        }
      })
      relatedPrices.forEach(relatedPrice => {
        if (this.checkForDuplicateDate(relatedPrice)) {
          warning = true
        }
      })
    }

    if (warning) {
      message.error('The selected Date range already exsits for the promoId. Please choose a different Date')
    } else {
      this.setState({ isLoading: true })
      const data = {
        // id: newId,
        name: name || '',
        currency: currency || '',
        displayPrice: displayPrice || '',
        startDate: startDate || null,
        endDate: endDate || null,
        wholeSalePrice: wholeSalePrice || '',
        rrpIncGst: rrpIncGst || '',
        rrpExcGst: rrpExcGst || '',
        gstAmount: gstAmount || '',
        promoId: promoId || '',
        active: false,
        customMeta: (customMeta || []).map(item => {
          const newItem = {
            config: '',
            value: ''
          }
          if (item.config && item.config.id) {
            newItem.config = item.config.id
            newItem.value = item.value
            newItem.name = item.config.name || ''
          }
          return newItem
        })
      }

      this.props.handlePriceSubmit(data, () => {
        this.setState({
          id: '',
          name: '',
          currency: '',
          displayPrice: '',
          startDate: '',
          endDate: '',
          wholeSalePrice: '',
          rrpIncGst: '',
          rrpExcGst: '',
          gstAmount: '',
          promoId: '',
          customMeta: [],
          isNameError: false,
          isEdited: false,
          isLoading: false
        })
      })
    }
  }

  handleChangePname = (event) => {
    const { startDate, endDate } = this.state
    const name = event.target.value
    this.setState({ name }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  handleChangePromoId=(event) => {
    const { startDate, endDate, name } = this.state
    const promoId = event.target.value
    this.setState({ promoId }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  onCurrencyChange = (id) => {
    const { startDate, endDate, name } = this.state
    const { listCurrency } = this.props
    const selectedCurrency = ((listCurrency && listCurrency.value) || []).find(item => item === id)
    if (selectedCurrency) {
      this.setState({ currency: selectedCurrency }, () => {
        if (startDate && endDate && name) {
          this.setState({ isEdited: true })
        }
      })
    } else {
      this.setState({
        currency: null
      })
    }
  }

  handleChangeDprice = (event) => {
    const { startDate, endDate, name } = this.state
    const displayPrice = event.target.value
    this.setState({ displayPrice }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  handleChangeWprice = (event) => {
    const { startDate, endDate, name } = this.state
    const wholeSalePrice = event.target.value
    this.setState({ wholeSalePrice }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  handleChangeGSTAmount = (event) => {
    const { startDate, endDate, name } = this.state
    const gstAmount = event.target.value
    this.setState({ gstAmount }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  handleChangeRRPIncAmount = (event) => {
    const { startDate, endDate, name } = this.state
    const rrpIncGst = event.target.value
    this.setState({ rrpIncGst }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  handleChangeRRPExcAmount = (event) => {
    const { startDate, endDate, name } = this.state
    const rrpExcGst = event.target.value
    this.setState({ rrpExcGst }, () => {
      if (startDate && endDate && name) {
        this.setState({ isEdited: true })
      }
    })
  }

  handleDateChange = (selectedDates) => {
    const { name } = this.state
    let startDate = ''
    let endDate = ''
    startDate = selectedDates[0] ? moment(selectedDates[0]).utc().format() : null
    endDate = selectedDates[1] ? moment(selectedDates[1]).utc().format() : null
    this.setState({ startDate, endDate }, () => {
      if (name) {
        this.setState({ isEdited: true })
      }
    })
  };

  handlePriceMetaDetailsChange = (metaData, value) => {
    const listCustomMetaFields = _.cloneDeep(this.props.priceMetaFieldList)
    // const { details } = this.state
    const { customMeta } = this.state
    // const newDetails = _.cloneDeep(details)
    let finalMetaModified = []
    if (value || value === '' || value === false || value === 'false') {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        let newMetaField = {
          config: item,
          value: item && item.default
        }
        if (item.id === metaData.id && item.type === metaData.type) {
          newMetaField.value = item.type === 'STRING' ? (value || '') : value
          delete item.isError
          newMetaField.config = item
        } else {
          const tempItem = (customMeta || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
          if (_.isEmpty(tempItem)) {
            newMetaField.config = item
            newMetaField.value = item.default
          } else {
            newMetaField = tempItem
            newMetaField.value = tempItem.value
          }
        }

        return newMetaField
      })
      finalMetaModified = customMetaModified
    } else if (!value || value === null || value === undefined || value !== false) {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        const foundMeta = (customMeta || []).find(existingMeta => {
          if (existingMeta && existingMeta.config) {
            if (existingMeta.config.id === item.id) { return existingMeta }
          }
        })
        if (_.isEmpty(foundMeta)) {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          return newMetaField
        } else {
          return foundMeta
        }
      })
      finalMetaModified = customMetaModified
    }
    const newCustomMeta = finalMetaModified
    this.setState({ customMeta: newCustomMeta }, () => {
      this.setState({ isEdited: true })
    })
  }

  getPriceMetaFields = (metaData, selectedMeta) => {
    const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
    return (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if (customMeta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{customMeta.name}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => this.handlePriceMetaDetailsChange(customMeta, value)} />
          </div>
        </div>
      } else if (customMeta.type === 'STRING') {
        return <Input
          // limit={50}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
          handleChange={({ target }) => this.handlePriceMetaDetailsChange(customMeta, target.value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      } else if (customMeta.type === 'NUMBER') {
        return <InputNumber
          limit={16}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
          handleChange={(value) => this.handlePriceMetaDetailsChange(customMeta, value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      }
    })
  }

  render () {
    const { name, currency, displayPrice, wholeSalePrice, gstAmount, rrpIncGst, rrpExcGst, startDate, endDate, isNameError, isEdited, promoId } = this.state
    const { isVisible, handlePriceModalCancel, editPrice, listCurrency, priceMetaFieldList } = this.props
    const isEnable = isEdited
    return (
      <Modal
        className='general-modal edit-price'
        title={editPrice && editPrice.id ? 'EDIT PRICE ' : 'CREATE PRICE'}
        maskClosable={false}
        visible={isVisible}
        okText='Save'
        cancelText='Back'
        onOk={this.handleSubmit}
        onCancel={handlePriceModalCancel}
        okButtonProps={{ disabled: (!isEnable) }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={this.state.isLoading}
        afterClose={this.clearState}
      >
        { isVisible
          ? <div className='edit-price-container' id='edit-price-container'>
            <Input
              title='Price Name'
              inputName='name'
              handleChange={this.handleChangePname}
              value={name}
              placeholder='Enter price name'
              isError={isNameError}
              errorMessage='Insert Price Name'
              isRequired
            />

            <div className='input-field'>
              <label className='input-title'>Currency</label>
              <CurrencyDropDown
                selectedValue={currency}
                className='recurrence-drop-down'
                options={listCurrency && listCurrency.value ? listCurrency.value : []}
                valueParam='id'
                displayParam='name'
                onOptionSelect={this.onCurrencyChange}
                placeHolder='Select Currency'
                parent={parentCompoent}
                allowClear
              />
            </div>
            <Input
              title='Display Price'
              inputName='displayPrice'
              handleChange={this.handleChangeDprice}
              value={displayPrice}
              placeholder='Insert Display Price' />
            <div className='date-picker-container'>
              <label>Date<span>*</span></label>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                onChange={this.handleDateChange}
                format='DD-MMM-YYYY HH:mm'
                value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                defaultValue={null}
              />
            </div>
            <Input
              title='Wholesale Price'
              inputName='wholeSalePrice'
              handleChange={this.handleChangeWprice}
              value={wholeSalePrice}
              placeholder='Insert Wholesale Price' />
            <Input
              title='GST Amount'
              inputName='gstAmount'
              handleChange={this.handleChangeGSTAmount}
              value={gstAmount}
              placeholder='Insert gst amount' />
            <Input
              title='RRP inc GST'
              inputName='rrpIncGst'
              handleChange={this.handleChangeRRPIncAmount}
              value={rrpIncGst}
              placeholder='Insert RRP inc GST' />
            <Input
              title='RRP excl GST'
              inputName='rrpExcGst'
              handleChange={this.handleChangeRRPExcAmount}
              value={rrpExcGst}
              placeholder='Insert RRP excl GST' />
            <Input
              title='Promo ID'
              inputName='promoId'
              handleChange={this.handleChangePromoId}
              value={promoId}
              placeholder='Enter promo id'
              isError={false}
              errorMessage='' />

            {this.getPriceMetaFields(this.state.customMeta, priceMetaFieldList)}
          </div> : '' }
      </Modal>
    )
  }
}

CreatePriceModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handlePriceSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handlePriceModalCancel: PropTypes.func,
  /** object for the edit price */
  editPrice: PropTypes.object,
  /** array of the prices */
  prices: PropTypes.array,
  /** current active price id */
  activePrice: PropTypes.string

}

CreatePriceModal.defaultProps = {
}

export default CreatePriceModal
