import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import TabPanel from '../../components/ui/dataDisplay/TabPanel'
import HistoryList from '../auditManager/HistoryList'
import SidebarCloseButton from '../../components/ui/general/buttons/SidebarCloseButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import AppContext from '../../AppContext'

const detailsTabs = [
  {
    displayName: 'History',
    mode: 'history'
  }
]

class DataHistory extends Component {
  state = {
    mode: 'history'
  }

  handleModeChange = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  render () {
    const { mode } = this.state
    const { selectedDataId, isChanging, optaIds, templateId, toggleSidebar, contentId } = this.props
    return <AppContext.Consumer>
      {({ project }) => {
        return (
          <div className='data-history' >
            <div className='side-panel-header'>
              <ButtonContainer displayTitle='Hide Audit History' childComponent={<SidebarCloseButton onClick={toggleSidebar} />} />
              <TabPanel tabs={detailsTabs} selectedMode={mode} handleModeChange={this.handleModeChange} />
            </div>
            {mode === 'history' ? <HistoryList filter={selectedDataId} selectedDataId={selectedDataId} content='DATA_MANAGER' showFilter={false} isChanging={isChanging} selectedBucketGroup={selectedDataId[0] === 'COMPETITION' ? optaIds : templateId} moduleContent={selectedDataId[0] === 'COMPETITION' ? 'OPTA_SECTION' : ''} project={project} contentId={contentId} /> : null }
          </div>
        )
      }}
    </AppContext.Consumer>
  }
}

DataHistory.propTypes = {

}

export default DataHistory
