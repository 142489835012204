import gql from 'graphql-tag'

export default gql(`
    mutation adminCreateSubscription($email: String!, $offerCode: String, $endDate: AWSDateTime!) {
        adminCreateSubscription(
            email: $email
            offerCode: $offerCode
            endDate: $endDate
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
