import gql from 'graphql-tag'

export default gql(`
    mutation adminUpdateSubscription( $id: ID! $email: String! $update: SubscriptionUpdate) {
        adminUpdateSubscription(
            email: $email
            id: $id
            update: $update
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
