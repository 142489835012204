import React, { Component } from 'react'

import AppContext from '../../AppContext'
import TabPanel from '../../components/ui/dataDisplay/TabPanel'
import AppPublishDetails from './AppPublishDetails'
import AppPagePublishDetails from './AppPagePublishDetails'
import HistoryList from './../auditManager/HistoryList'
import AppWidgetList from './AppWidgetList'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import AppPageScheduleDetails from './AppPageScheduleDetails'

const detailsTabsPage = (mode) => [
  {
    displayName: 'Widgets',
    mode: 'widgets'
  },
  {
    displayName: 'Publish',
    mode: 'publish'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

const detailsTabsConfig = (mode) => [
  {
    displayName: 'Publish',
    mode: 'publish'
  }, {
    displayName: 'History',
    mode: 'history'
  }
]

const detailsTabsSetting = (mode) => [
  {
    displayName: 'History',
    mode: 'history'
  }
]

class AppRightSideBar extends Component {
  state ={
    widgetSearchString: undefined
  }

  onChangeFilter = (value) => {
    this.setState({ widgetSearchString: value }, this.onChangeWidgetFilter)
  }

  onChangeWidgetFilter = () => {
    this.props.onChangeWidgetFilter(this.state.widgetSearchString)
  }

  changeFilterValue = () => {
    this.setState({ isSearching: true }, this.onChangeWidgetFilter)
  }

  render () {
    const { widgetSearchString } = this.state
    const { selectedConfig, appDetails, appClient, shouldRefreshHistory, appMode, selectedPage, pageDetails, hidePagePublishModal,
      onPublishAllStartDateChange, handlePagePublish, onPublishAllButtonActiveChange, onPublishAllEndDateChange, isPagePublishEdited, isPublishLoading,
      togglePagePublishModal, showPagePublishModal, handleModeChange, mode, handlePageUnPublish, isUnPublishLoading, widgetSearchValue, togglePageScheduleModal,
      onPageDeleteVersion, selectedAppSetting, currentPageVersionId, onPageVersionSelection, appList, checkForConfigModification, isConfigSingleInstanceEdited, userId, pageReorderLoading } = this.props

    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['APP_MANAGER']
          const isUpdatePageDisabled = userPermissions.indexOf('UPDATE_PAGE') === -1
          const isPublishDisabled = userPermissions.indexOf('PUBLISH_CONFIG') === -1
          const isPublishPageDisabled = userPermissions.indexOf('PUBLISH_PAGE') === -1
          const isCriticalWriteDisabled = userPermissions.indexOf('CMS_WRITE_CRITICAL') === -1
          const isAppAdminDisabled = userPermissions.indexOf('APP_ADMIN') === -1
          return <div className='app-sidebar' id='app-sidebar'>
            <div className={`app-sidebar-top ${mode}`}>
              <TabPanel tabs={appMode === 'configs' ? detailsTabsConfig(mode) : appMode === 'pages' ? detailsTabsPage(mode) : detailsTabsSetting(mode)} selectedMode={mode} handleModeChange={handleModeChange} />
              {mode === 'widgets' && appMode === 'pages'
                ? <div className='filter'>
                  <FilterInput
                    searchString={widgetSearchString}
                    onChangeSearchInput={this.onChangeFilter}
                    // isClearFilter={isClearWidgetFilter}
                    filterType={'AppManager'}
                    changeFilter={this.changeFilterValue}
                    placement='leftTop'
                    // bucketAssetFilter={this.widgetFilterObj}
                    project={project}
                    isDisabled
                  />
                </div> : null }
            </div>
            {mode === 'publish' && appMode === 'configs' && selectedConfig
              ? <AppPublishDetails
                appDetails={appDetails}
                appClient={appClient}
                project={project}
                isPublishDisabled={isPublishDisabled}
                checkForConfigModification={checkForConfigModification}
                selectedConfig={selectedConfig}
                isConfigSingleInstanceEdited={isConfigSingleInstanceEdited}
                isCriticalWriteDisabled={isCriticalWriteDisabled}
                isAppAdminDisabled={isAppAdminDisabled}
                userId={userId}
              /> : null }
            {mode === 'publish' && appMode === 'pages' && selectedPage
              ? <AppPagePublishDetails
                appDetails={pageDetails}
                appClient={appClient}
                project={project}
                isPublishDisabled={isPublishPageDisabled}
                hidePagePublishModal={hidePagePublishModal}
                onPublishAllStartDateChange={onPublishAllStartDateChange}
                onPublishAllEndDateChange={onPublishAllEndDateChange}
                onPublishAllButtonActiveChange={onPublishAllButtonActiveChange}
                handlePagePublish={handlePagePublish}
                isPagePublishEdited={isPagePublishEdited}
                isPublishLoading={isPublishLoading}
                togglePagePublishModal={togglePagePublishModal}
                showPagePublishModal={showPagePublishModal}
                handlePageUnPublish={handlePageUnPublish}
                isUnPublishLoading={isUnPublishLoading}
                onPageVersionSelection={onPageVersionSelection}
                currentPageVersionId={currentPageVersionId}
              />
              : null }
            {mode === 'publish' && appMode === 'pages' && selectedPage
              ? <AppPageScheduleDetails
                appDetails={pageDetails}
                appClient={appClient}
                project={project}
                isPublishDisabled={isPublishPageDisabled}
                handlePagePublish={handlePagePublish}
                isPagePublishEdited={isPagePublishEdited}
                isPublishLoading={isPublishLoading}
                togglePageScheduleModal={togglePageScheduleModal}
                handlePageUnPublish={handlePageUnPublish}
                isUnPublishLoading={isUnPublishLoading}
                onPageDeleteVersion={onPageDeleteVersion}
                currentPageVersionId={currentPageVersionId}
                onPageVersionSelection={onPageVersionSelection}
              />
              : null }
            {mode === 'history' && (!selectedAppSetting || selectedAppSetting === 'Apps' || selectedAppSetting === 'Widgets' || selectedAppSetting === 'Api Keys')
              ? <HistoryList isChanging={shouldRefreshHistory} showFilter={false} content='APP_MANAGER' contentId={appMode === 'configs' ? selectedConfig : (appMode === 'pages' ? selectedPage : null)} project={project} type={selectedAppSetting === 'Widgets' ? 'Widgets' : selectedAppSetting === 'Apps' ? 'Apps' : 'Api Keys'} appList={appList} /> : null }
            {mode === 'widgets' && appMode === 'pages' && selectedPage
              ? <AppWidgetList project={project} appClient={appClient} pageDetails={pageDetails} isDragDisabled={isUpdatePageDisabled || pageReorderLoading} widgetSearchValue={widgetSearchValue} /> : null }
          </div>
        }}
      </AppContext.Consumer>

    )
  }
}

AppRightSideBar.propTypes = {

}

export default AppRightSideBar
