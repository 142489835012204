import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    getAsset(
        id: $id
        project: $project
    ) {
        id
        title
        type
        shortId
        externalId
        updatedAt
        status
        duration
        defaultMedia {
            id
            url
            fileName
            aspectRatio {
                title
                aspectRatio
                resolutions {
                    url
                }
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
        }
        media{
            id
            fileName
            createdAt
            name
            type
            fileSize
            duration
            thumbnails
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions{
                x1
                x2
                y1
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
            drmContent
            streams{
                id
                drm
                codec
                Url
                iv
                transport
                contentKey
            }
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
        }
        images{
            id
            assetId
            mediaId
            isDefault
            fileName
            createdAt
            name
            type{
                name
                id
            }
            fileSize
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2
                y2
            }
            dimensions{
                x1
                x2
                y1
                y2
            }
            aspectRatio {
                aspectRatio
                ratio
                title
                resolutions {
                key
                width
                height
                url
                }
            }
            
        }
        videos{
            id
            assetId
            mediaId
            fileName
            createdAt
            name
            fileSize
            cuePoints
            adPositions
            breakDuration
            cuePointsError
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            type
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
            drmContent
            streams{
                id
                drm
                codec
                Url
                iv
                transport
                contentKey
            }
            duration
            thumbnails
            isDefault
        }
        contents {
            id
            position
            value
            type
            caption
            metaFields{
                id
                fieldID
                tooltip
                displayName
                type
                isRequired
                values
                value
                fieldValues
            }
            media{
                id
            }
        }
    }
}`)
