import React from 'react'
import PropTypes from 'prop-types'

const BucketIcon = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h15v15H0z' />
      <path fill={color} d='M.6 0h13.8a.6.6 0 0 1 .6.6V2a.6.6 0 0 1-.6.6H.6A.6.6 0 0 1 0 2V.6A.6.6 0 0 1 .6 0zm0 4.2h13.8l-1.081 9.733A1.2 1.2 0 0 1 12.126 15H2.874a1.2 1.2 0 0 1-1.193-1.067L.6 4.2z' />
    </g>
  </svg>
)

BucketIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

BucketIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#343A40'
}

export default BucketIcon
