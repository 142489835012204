import gql from 'graphql-tag'

export default gql(`
    mutation ($name: String! $schemaId: ID! $streams: [ID!] $channelId: ID! $position: Int! $fields: [CreateFieldInput] $version: String) {
        createChannelConfigGroup(input: {
            name: $name
            schemaId: $schemaId
            streams: $streams
            channelId: $channelId
            position: $position
            fields: $fields
            version: $version
            },
            channelId: $channelId
            ) {
            id
            schemaId{
                id
              }
            position
            name
            fields{
                name
                value
                isRequired
                type
            }
            streams{
                id
                levelVersions{
                    streamId
                    version
                }
                fields{
                    name
                    value
                    isRequired
                    type
                }
                url
                streamSchema{
                    id
                    levels{
                        id
                        name
                        fields{
                            name
                            value
                            isRequired
                            type
                        }
                    }
                }
            }
        }
   }`
)
