import gql from 'graphql-tag'

export default gql(`
    mutation ($id: ID!, $name: String $icon: ID $isArchived: Boolean $channelCode: String $site: String $defaultTrimStart: Int $defaultTrimEnd: Int) {
        updateChannel(input: {
            id: $id
            name: $name
            icon: $icon
            isArchived: $isArchived
            channelCode: $channelCode
            site: $site
            defaultTrimStart: $defaultTrimStart
            defaultTrimEnd: $defaultTrimEnd
        }) {
            id
            name
            channelCode            
            isArchived
            site
            icon{
                id
                url
                tags{
                    id : key
                    name
                }
                name
                fileName
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
            }
            
            activeConfigGroup{
                id
                name
            }
            programs{
                previous{
                    id
                    shortId
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                    program{
                        id
                        broadCastStartTime
                        broadCastEndTime
                    }
                        match{
                            id
                            kickOffTime
                            finishTime
                            externalId
                            homeTeam {
                                id
                                officialName
                            }
                            awayTeam {
                                id
                                officialName     
                            }
                        }
                }
                current{
                    id
                    shortId
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                    program{
                        id
                        broadCastStartTime
                        broadCastEndTime
                    }
                        match{
                            id
                            kickOffTime
                            finishTime
                            externalId
                            homeTeam {
                                id
                                officialName
                            }
                            awayTeam {
                                id
                                officialName     
                            }
                        }
                }
                next{
                    id
                    shortId
                    defaultMedia {
                        id
                        url
                        fileName
                        aspectRatio {
                          title
                          aspectRatio
                          resolutions {
                            url
                          }
                        }
                        settings {
                          aspectRatio
                          outputFormat
                          fileName
                          x1
                          y1
                          x2 
                          y2
                        }
                    }
                    program{
                        id
                        broadCastStartTime
                        broadCastEndTime
                    }
                        match{
                            id
                            kickOffTime
                            finishTime
                            externalId
                            homeTeam {
                                id
                                officialName
                            }
                            awayTeam {
                                id
                                officialName     
                            }
                        }
                }
            }
            defaultTrimStart
            defaultTrimEnd
        }
   }`
)
