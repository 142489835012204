import React from 'react'
import PropTypes from 'prop-types'

const DuplicateIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h20v20H0z' />
      <path fill='#FFF' d='M15.818 3.571V16.43h.727c.804 0 1.455-.64 1.455-1.429V1.429C18 .639 17.35 0 16.545 0H6.364C5.56 0 4.909.64 4.909 1.429v.714h9.455c.803 0 1.454.64 1.454 1.428zM14.364 5c0-.79-.651-1.429-1.455-1.429H3.455C2.65 3.571 2 4.211 2 5v13.571C2 19.361 2.65 20 3.455 20h9.454c.804 0 1.455-.64 1.455-1.429V5zM7.09 9.286c0-.395.326-.715.727-.715.402 0 .727.32.727.715v2.143h2.182c.402 0 .728.32.728.714a.721.721 0 0 1-.728.714H8.545V15a.721.721 0 0 1-.727.714.721.721 0 0 1-.727-.714v-2.143H4.909a.721.721 0 0 1-.727-.714c0-.394.326-.714.727-.714h2.182V9.286z' />
    </g>
  </svg>
)

DuplicateIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

DuplicateIcon.defaultProps = {
  width: 20,
  height: 20
}

export default DuplicateIcon
