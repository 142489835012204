import React, { Component } from 'react'
import { Table, Skeleton, Tooltip, Input } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'

import FixedTableHeader from '../../components/ui/dataDisplay/FixedTableHeader'
import DropDown from './../../components/ui/dataEntry/inputs/DropDown'

const ingestJobTypeList = [
  {
    name: 'CSV Ingest',
    id: 'CSV_ASSET_INGEST'
  },
  {
    name: 'Gracenote Asset Ingest',
    id: 'GN_ASSET_INGEST'
  },
  {
    name: 'Gracenote Update Asset Ingest',
    id: 'GN_UPDATE_ASSET_INGEST'
  },
  {
    name: 'Gracenote Partner Update',
    id: 'GN_PARTNER_UPDATE'
  },
  {
    name: 'Gracenote Season Episode Link',
    id: 'GN_SEASON_EPISODE_LINK'
  },
  {
    name: 'Gracenote Season Asset Ingest',
    id: 'GN_SEASON_ASSET_INGEST'
  },
  {
    name: 'Gracenote Partner Expiry',
    id: 'GN_PARTNER_EXPIRY'
  },
  {
    name: 'Gracenote Series Partner Update',
    id: 'GN_SERIES_PARTNER_UPDATE'
  }
]
class IngestJobList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: !(props.listIngestJobs && props.listIngestJobs.length),
      isPaginating: false,
      jobId: props.selectedJobId ? props.selectedJobId : '',
      searchBatchIdString: props.searchBatchId
    }
    this.columns = [
      {
        title: 'Batch ID',
        dataIndex: 'batchID',
        width: '20%',
        key: 'batchID'
      },
      {
        title: 'Source',
        dataIndex: 'source',
        width: '5%',
        key: 'source'
      },
      {
        title: 'Start Time',
        dataIndex: 'createdAt',
        width: '15%',
        key: 'createdAt',
        render: this.renderCreatedAt
      },
      {
        title: 'Total Items',
        dataIndex: 'totalItems',
        width: '10%',
        key: 'totalItems',
        render: this.renderTotalItem
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '10%',
        key: 'status',
        render: this.renderStatus
      },
      {
        title: 'Job Type',
        dataIndex: 'jobType',
        width: '15%',
        key: 'jobType',
        className: 'job-type',
        render: this.renderJobType
      },
      {
        title: 'Triggered By',
        dataIndex: 'createdBy',
        width: '10%',
        key: 'createdBy'
      },
      {
        title: 'Retry Job ID',
        dataIndex: 'retryJobId',
        width: '10%',
        key: 'retryJobId',
        render: this.renderRetryJob
      }
    ]
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isLoading) {
      this.setState({ isPaginating: false, isLoading: true })
    } else if (!newProps.isLoading && this.props.isLoading && _.isEqual(newProps.listIngestJobs, this.props.listIngestJobs)) {
      this.setState({ isLoading: false })
    } else if (!newProps.isLoading && !_.isEqual(newProps.listIngestJobs, this.props.listIngestJobs)) {
      this.setState({ isPaginating: false, isLoading: false })
    }
    if (newProps.selectedJobId) {
      this.setState({
        jobId: newProps.selectedJobId
      })
    }
    if (newProps.listIngestJobs && newProps.listIngestJobs.length && !newProps.selectedJobId) {
      this.props.changeSelectedJob(newProps.listIngestJobs[0])
    }
  }

  componentWillUnmount () {
    clearTimeout(this.delayTime)
  }

    loadMoreAsset = () => {
      if (this.props.totalCount === this.props.listIngestJobs.length || this.state.isPaginating) { return }
      this.setState({ isPaginating: true }, () => this.props.getMoreIngestJob(this.props.listIngestJobs.length))
    }

    onIngestJobChange = (record, rowIndex) => {
      return {
        onClick: () => {
          this.props.changeSelectedJob(record)
          this.setState({
            jobId: record.batchID
          })
        }
      }
    }

    renderStatus = (status) => {
      return (
        <div className={'ingest-status'}>
          <p className={status === 'COMPLETE' ? 'complete' : status === 'PARTIAL' ? 'partial' : status === 'PROCESSING' ? 'processing' : status === 'FAILED' ? 'failed' : 'queued'}>{status}</p>
        </div>
      )
    }

    renderTotalItem = (totalItem) => {
      return (
        <div className={'ingest-total'}>
          <p>{totalItem}</p>
        </div>
      )
    }

    renderCreatedAt = (createdAt) => {
      return (
        <div className={'ingest-create-at'}>
          <p>{moment(createdAt).format('DD MMM YYYY, HH:mm')}</p>
        </div>
      )
    }

    setJobClassName = (record) => {
      return record.batchID === this.state.jobId ? 'selectJob' : ''
    }

    renderRetryJob = (sourceJob) => {
      return sourceJob === null ? '-' : <a className={'retry-job-id'} href={location.origin + '/ingest-jobs/' + sourceJob} target='_blank'>{sourceJob}</a>
    }

    renderJobType = (jobType) => {
      let customJobTypeName = this.props.handleCustomJobTypeName(jobType)
      return <Tooltip placement='topLeft' title={customJobTypeName}>
        {customJobTypeName}
      </Tooltip>
    }

    clickInput = (e) => {
      e.stopPropagation()
      e.preventDefault()
    }

    onSearchBatchIdChange = e => {
      let { value } = e.target
      if (value.startsWith(' ')) { value = value.trim() }
      this.setState({ searchBatchIdString: value })
      if (this.delayTime) { clearTimeout(this.delayTime) }
      this.delayTime = setTimeout(() => {
        clearTimeout(this.delayTime)
        this.props.onChangeBatchIdSearchInput(value)
      }, 750)
    }

    clearBatchIdSearchField =() => {
      this.setState({
        searchBatchIdString: null
      })
      this.props.onChangeBatchIdSearchInput('')
    }

    render () {
      const { listIngestJobs, onIngestJobTypeSelect, selectedIngestJobType } = this.props
      const { isLoading, isPaginating, searchBatchIdString } = this.state
      return (
        <React.Fragment>
          <div className='ingest-table-filter'>
            <DropDown
              options={ingestJobTypeList}
              selectedValue={selectedIngestJobType}
              placeHolder='Select Job Type'
              valueParam='id'
              displayParam='name'
              onOptionSelect={onIngestJobTypeSelect}
              allowClear
              className={(selectedIngestJobType) ? 'selected' : ''}
            />
            <Input
              onClick={this.clickInput}
              onChange={this.onSearchBatchIdChange}
              value={searchBatchIdString}
              placeholder={'Search BatchID'}
              allowClear
            />
          </div>

          <div className='ingest-job-table'>
            <FixedTableHeader columnCellList={this.columns} />
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMoreAsset}
              hasMore={this.props.totalCount > listIngestJobs.length}
              initialLoad={false}
              useWindow={false}
            >
              <Skeleton active avatar={false} title paragraph={{ rows: 10 }} loading={isLoading}>
                <Table rowKey={record => record.batchID} columns={this.columns} dataSource={listIngestJobs} pagination={false} onRow={this.onIngestJobChange} rowClassName={this.setJobClassName} showHeader={false} />
              </Skeleton>
              <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
            </InfiniteScroll>
          </div>
        </React.Fragment>
      )
    }
}

IngestJobList.propTypes = {
  /** array of ingested jobs */
  listIngestJobs: PropTypes.array,
  /** total number of ingested Jobs */
  totalCount: PropTypes.number,
  /** function to handle job pagination */
  getMoreIngestJob: PropTypes.func,
  /** boolean to get the job loading status */
  isLoading: PropTypes.bool,
  /** function to handle job change */
  changeSelectedJob: PropTypes.func,
  /** ingest Client configuration */
  IngestClient: PropTypes.object,
  /** selected JobID */
  selectedJobId: PropTypes.string,
  /** current project */
  project: PropTypes.string,
  /** function to handle batchId search change */
  onChangeBatchIdSearchInput: PropTypes.func
}

export default IngestJobList
