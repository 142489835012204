import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Table } from 'antd'
import _ from 'lodash'
import Tooltip from './../dataDisplay/ToolTip'

class JsonArrayTableViewModal extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  handleArrayKeyConversion = () => {
    const { selectedJsonArrayTableValue } = this.props
    let keys = [];
    (selectedJsonArrayTableValue || []).map(item => {
      keys = [...keys, ...Object.keys(item)]
    })
    return [...new Set(keys)]
  }

  handleArrayColumTable = () => {
    let keys = this.handleArrayKeyConversion()
    let columns = []
    keys.map(key => {
      columns.push(
        {
          title: key.toUpperCase(),
          dataIndex: key,
          key: key,
          width: '10%',
          render: (data) => this.renderData(data)
        }
      )
    })
    return columns
  }

  renderData = (data) => {
    if (Array.isArray(data)) {
      return null
    } else {
      return _.size(data && data.toString()) > 30
        ? <Tooltip placement='topLeft' description={data && data.toString()} childComponent={data && data.toString()}>
          {data && data.toString()}
        </Tooltip>
        : data && data.toString()
    }
  }
  render () {
    const { isVisible, handleCancel, selectedJsonArrayTableValue } = this.props
    let columns = this.handleArrayColumTable()
    return (
      <Modal
        className='general-modal'
        title={'JSON ARRAY'}
        maskClosable={false}
        visible={isVisible}
        closable={false}
        centered
        destroyOnClose
        width={'600px'}
        footer={[
          <Button key='back' onClick={handleCancel}>
              Close
          </Button>]}
      >
        <Table className='json-array-table' rowKey={record => record.id} columns={columns} dataSource={selectedJsonArrayTableValue} pagination={false} scroll={{ x: 1600, y: 240 }} />

      </Modal>
    )
  }
}

JsonArrayTableViewModal.propTypes = {
  /** function to handle Back in Modal */
  handleCancel: PropTypes.func,
  /** boolean toggle to show Modal */
  isVisible: PropTypes.bool
}

export default JsonArrayTableViewModal
