import gql from 'graphql-tag'

export default gql(`
subscription jobCreated{
    jobCreated{
        batchID
        source
        jobType
        status
        totalItems
        createdAt
        createdBy     
        retryJobId
        sourceJob
        isArchived
        successful {
            id
            isArchived
            shortId
            externalId
            title
            type
            updatedAt
            publishStartDate
            publishEndDate
            status
            isFree
            updatedAt
            seasonNumber
            episodeNumber
            series{
              id
              assetId
              title
            }
            season{
                id
                assetId
                title
            }
            defaultMedia {
              id
              fileName
              aspectRatio {
                title
                aspectRatio
                resolutions {
                  url
                }
              }
              settings {
                aspectRatio
                outputFormat
                fileName
                x1
                y1
                x2 
                y2
              }
            }
          }
        csvPayload
        errors {
            errorMessage
            rowId
        }
    }
  }`)
