import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import CreateBucketGroupContainer from './CreateBucketGroupTypeContainer'
// import userMessages from './../../../../constants/messages'
// import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryBucketGroupType from '../../../../graphQL/admin/bucket/listBucketGroupType'
import MutationCreateBucketGroupType from '../../../../graphQL/admin/bucket/createBucketGroupType'
import MutationUpdateBucketGroupType from '../../../../graphQL/admin/bucket/updateBucketGroupType'
import MutationUpdateMedia from '../../../../graphQL/content/updateMedia'
import MutationUpdateMediaSettings from '../../../../graphQL/content/updateMediaSettings'
import { utilityService } from '../../../../services/UtilityService'

class CreateBucketGroupTypeModal extends Component {
  constructor (props) {
    super(props)
    let groupTypeName = ''
    let image = ''
    let position = 1
    if (props.selectedGroupType) {
      groupTypeName = props.selectedGroupType.name
      position = props.selectedGroupType.position
      image = props.selectedGroupType.media && props.selectedGroupType.media.length && props.selectedGroupType.media[0] ? props.selectedGroupType.media[0] : ''
    }
    this.state = {
      groupTypeName,
      position,
      image,
      isEdited: false
    }
  }

    UNSAFE_componentWillReceiveProps= (nextProps) => { // eslint-disable-line camelcase
      if (nextProps.selectedGroupType && (!_.isEqual(this.props.selectedGroupType, nextProps.selectedGroupType) || (!this.state.groupTypeName && !this.state.image))) {
        this.setState({
          groupTypeName: nextProps.selectedGroupType.name,
          position: nextProps.selectedGroupType.position,
          image: nextProps.selectedGroupType.media && nextProps.selectedGroupType.media.length ? nextProps.selectedGroupType.media : ''
        })
      } else if (!_.isEqual(this.props.selectedImage, nextProps.selectedImage)) {
        this.setState({ image: nextProps.selectedImage })
      } else if (!nextProps.selectedGroupType && this.props.selectedGroupType && this.state.groupTypeName) {
        this.setState({ groupTypeName: '' })
      }
    }

    handleTextChange = (event) => {
      this.setState({ groupTypeName: event.target.value, isEdited: true })
    }

    handlePositionChange = (event) => {
      this.setState({ position: event.target.value, isEdited: true })
    }

    submitBucketGroupType = () => {
      const { groupTypeName, position } = this.state
      const { selectedGroupType, handleSubmit, groupTypeList, onHistoryUpdate } = this.props
      this.setState({ isLoading: true })
      const isSameKeyPresent = (groupTypeList || []).filter(item => item.name === groupTypeName)
      if (selectedGroupType) {
        if (isSameKeyPresent && isSameKeyPresent.length && isSameKeyPresent[0].id !== selectedGroupType.id) {
          message.error('Duplicate bucket group type found. Please try another name')
          this.setState({ isLoading: false })
          return
        }
        this.props.updateBucketGroupType(selectedGroupType.id, groupTypeName, position).then(() => {
          handleSubmit()
          onHistoryUpdate()
          this.setState({ isLoading: false })
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      } else {
        if (isSameKeyPresent && isSameKeyPresent.length) {
          message.error('Duplicate bucket group type found. Please try another name')
          this.setState({ isLoading: false })
          return
        }
        this.props.createBucketGroupType(groupTypeName, (groupTypeList || []).length ? groupTypeList[(groupTypeList || []).length - 1].position + 1 : 1).then(() => {
          handleSubmit()
          onHistoryUpdate()
          this.setState({ isLoading: false })
        }, error => {
          this.setState({ isLoading: false })
          utilityService.handleError(error)
        })
      }
    }

    handleCancel = () => {
      this.setState({
        groupTypeName: '',
        image: '',
        position: 1,
        isEdited: false
      })
      this.props.handleCancel()
    }

    resetState = () => {
      const state = {
        groupTypeName: '',
        image: '',
        position: 1,
        isEdited: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, showImageModal, showCropModal, croppedUrl, clearSelection, selectedGroupType, isSubmitDisabled } = this.props
      const { groupTypeName, isLoading, image, position, isEdited } = this.state
      return (
        <Modal
          className='confirm-modal edit-image'
          title={selectedGroupType ? 'EDIT BUCKET GROUP TYPE' : 'NEW BUCKET GROUP TYPE'}
          maskClosable={false}
          visible={isVisible}
          okText='Save'
          cancelText='Back'
          onOk={this.submitBucketGroupType}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: !groupTypeName || position === undefined || position === '' || position === null || !isEdited || isSubmitDisabled, loading: isLoading }}
          closable={false}
          centered
          width='600px'
          afterClose={this.resetState}
          confirmLoading={isLoading}
        >
          { isVisible ? <CreateBucketGroupContainer
            showCropModal={showCropModal}
            showImageModal={showImageModal}
            selectedImage={image}
            croppedUrl={croppedUrl}
            onHandlePosition={this.handlePositionChange}
            handleTextChange={this.handleTextChange}
            position={position}
            groupTypeName={groupTypeName}
            clearSelection={clearSelection}
          /> : null }
        </Modal>
      )
    }
}

CreateBucketGroupTypeModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Details of selected image */
  selectedImage: PropTypes.array,
  /** Function to save the author */
  createAuthor: PropTypes.func,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Callback to show the AddImageModal */
  showImageModal: PropTypes.func
}

CreateBucketGroupTypeModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationCreateBucketGroupType, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { createBucketGroupType } }) => {
              const variables = { filter: null, limit: 50, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketGroupType, variables }))
              if (cacheData && cacheData.listBucketGroupType && cacheData.listBucketGroupType.items) { cacheData.listBucketGroupType.items.push(createBucketGroupType) }
              cache.writeQuery({
                query: QueryBucketGroupType,
                data: cacheData,
                variables
              })
            }
          }
        },
        skip: ({ name, id }) => {
          return !!(!id || !name)
        },
        props: (props) => ({
          createBucketGroupType: (name, position) => {
            return props.mutate({
              variables: { name, position, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateBucketGroupType, {
        options: ({ project }) => {
          return {
            update: (cache, { data: { updateBucketGroupType } }) => {
              const variables = { filter: null, limit: 50, project }
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketGroupType, variables }))
              if (cacheData && cacheData.listBucketGroupType && cacheData.listBucketGroupType.items) {
                const selectedAuthorIndex = cacheData.listBucketGroupType.items.findIndex(item => item.id === updateBucketGroupType.id)
                if (selectedAuthorIndex > -1) {
                  cacheData.listBucketGroupType.items[selectedAuthorIndex] = updateBucketGroupType
                }
              }
              cache.writeQuery({
                query: QueryBucketGroupType,
                data: cacheData,
                variables
              })
            }
          }
        },
        skip: ({ name, id }) => {
          return !!(!id || !name)
        },
        props: (props) => ({
          updateBucketGroupType: (id, name, position) => {
            return props.mutate({
              variables: { id, name, position, project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMedia, {
        props: (props) => ({
          updateImage: (id, name, tags, settings) => {
            return props.mutate({
              variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
            })
          }
        })
      }
    ),
    graphql(
      MutationUpdateMediaSettings, {
        props: (props) => ({
          updateImageSettings: (id, assetId, settings) => {
            return props.mutate({
              variables: { id, assetId, settings }
            })
          }
        })
      }
    )
  )(CreateBucketGroupTypeModal)
)
