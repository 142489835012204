import React, { useRef, useState } from 'react'
import usePlayerTime from '../bitmovin/hooks/usePlayerTime'
import { formatDuration } from '../Utils/time'

const ScrubBar = props => {
  const { showPlayer } = props
  const { pRate, bRate, seek, getRelativePlayackTime } = usePlayerTime()
  const placardTimer = useRef()
  const [placardActive, setPlacardActive] = useState(false)
  const [placardPosition, setPlacardPosition] = useState(0)
  const [placardTime, setPlacardTime] = useState('')
  const getRelativeScrubBarPosition = e => {
    const progressBar = document.getElementById('player-progress')
    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left // x position within the element.
    const width = progressBar.clientWidth
    return x / width
  }

  const onClickProgressBar = e => {
    const seekPercentage = getRelativeScrubBarPosition(e)
    seek(seekPercentage)
  }

  const showTimePlacard = arg => {
    setPlacardActive(true)
    if (placardTimer.current) clearTimeout(placardTimer.current)
    placardTimer.current = setTimeout(() => setPlacardActive(false), 1000)
    const relativePosition = getRelativeScrubBarPosition(arg)
    setPlacardPosition(relativePosition * 100)
    setPlacardTime(getRelativePlayackTime(relativePosition))
  }

  return (
    <>
      <div
        id='player-progress'
        className='player-progress'
        onClick={arg => {
          onClickProgressBar(arg)
        }}
        onMouseMove={arg => {
          showTimePlacard(arg)
        }}>
        <div className='played' style={{ width: `${pRate}%` }} />
        <div className='playable' style={{ width: `${bRate > 100 ? 100 : bRate}%` }} />
      </div>
      {placardActive && showPlayer ? (
        <>
          <div className='placard' style={{ left: `calc(${placardPosition - 4}% ) ` }}>
            {placardTime < 0 ? `-${formatDuration(-placardTime)}` : formatDuration(placardTime)}
            <div className='placardPointer' />
          </div>
        </>
      ) : null}
    </>
  )
}
export default ScrubBar
