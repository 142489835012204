import gql from 'graphql-tag'

export default gql(`
  query($slug: String! $project: String) {
    listAssets(
      filter: {
        slug: {
          exact: $slug
        }
      }
      project: $project
      
    ) {
      items {
        id
        slug
      }
    }
  }`
)
