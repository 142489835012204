import React from 'react'
import { Menu, Item, contextMenu } from 'react-contexify'
import moment from 'moment'
import { Spin, Icon } from 'antd'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import 'react-contexify/dist/ReactContexify.css'
import _ from 'lodash'
import AssetListCell from './AssetListCell'
import DragAndDropIcon from '../general/icons/DragAndDropIcon'
import { generateCroppedThumbnail } from './../../../util/util'

import './../ui.style.scss'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  borderTop: isDragging ? '1px solid #cfd0d1' : 'none',
  borderLeft: isDragging ? '1px solid #cfd0d1' : 'none',
  borderRight: isDragging ? '1px solid #cfd0d1' : 'none',
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white'
})

class BucketDetailListForRelatedSeason extends React.Component {
  state = {
    showResetTitle: false,
    showEditTitle: false,
    showResetDisplayImage: false,
    showEditDisplayImage: false,
    selectedId: '',
    isFilterOpen: false
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.isScroll) {
      setTimeout(() => {
        this.bucketScrollContainer.scrollTop = (this.bucketScrollContainer.scrollHeight + 100)
        this.props.onBucketScroll()
      }, 200)
    }
  }

  removeBucketItem = (item) => {
    if (this.props.removeBucketItem) { this.props.removeBucketItem(item) }
  }

  isFilterOpen = (isFilterOpen) => {
    this.setState({ isFilterOpen })
  }

  AfterEdittedMenu = (type, itemId, isLoading, menuId) => {
    if (isLoading) {
      return <Menu className='loading-menu' id={menuId} >
        <Item>
          <Spin indicator={<Icon type='loading' style={{ fontSize: 24, color: '#FF015A' }} spin />} />
        </Item>
      </Menu>
    }
    return (
      <Menu id={menuId} >
        <Item onClick={() => this.removeBucketItem(itemId)} >{ type === 'ASSET' || 'SEASON' ? 'Remove Item' : 'Remove Filter' }</Item>
      </Menu>
    )
  }

  render () {
    const { bucketId, saveLoading, isUpdateBlocked, project, isSeason } = this.props
    const { isFilterOpen } = this.state
    const listData = _.sortBy(this.props.listData || [], isSeason ? 'episodeNumber' : 'seasonNumber')
    return (
      <div className='bucket-detail-list' ref={node => { this.bucketScrollContainer = node }}>
        <div className='bucket-detail'>
          <Droppable droppableId={'#Bucket_' + bucketId} isDropDisabled={saveLoading}>
            { (droppableProvided, droppableSnapshot) => (

              <div className='bucket-list-container'
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                { (listData || []).map((item, index) =>
                  <Draggable key={item.id} draggableId={item.id + `bucket-item` + index + 1} index={index} isDragDisabled>
                    { (provided, snapshot) => {
                      const menuId = `id-${item.id}-${bucketId}`
                      const projectTerm = project === 'hyperion' ? 'h' : project === 'projectx' ? 's' : 'os'
                      const assetId = item.id ? `${projectTerm}${item.id.split(projectTerm)[2]}` : item.assetId
                      return <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          onContextMenu={(e) => contextMenu.show({
                            id: menuId,
                            event: e
                          })}
                        >

                          <AssetListCell
                            assetId={assetId || item.id}
                            assetName={item.title}
                            displayId={assetId || item.id}
                            assetType={item.type}
                            status={item.status}
                            date={moment(new Date(item.updatedAt)).format('DD MMM YYYY, hh:mm A')}
                            imageUrl={item.defaultMedia ? generateCroppedThumbnail(item.defaultMedia, 87, 48, '16:9') : ''}
                            onSelect={() => { }}
                            project={project}
                            asset={item}
                          />
                        </div>
                        {(isFilterOpen || isUpdateBlocked) ? '' : this.AfterEdittedMenu(item.type, item.id, item.isLoading, menuId)}
                      </div>
                    } }
                  </Draggable>
                )}
                {!this.props.listData.length ? <div className='drag-asset-message'>
                  <DragAndDropIcon />
                  <p>Drag and drop Assets here </p>
                </div> : null}
              </div>
            )}
          </Droppable>
        </div>

      </div>
    )
  }
}

BucketDetailListForRelatedSeason.propTypes = {
  /** isSearch of BucketDetailListForRelatedAsset. */
  isSearch: PropTypes.bool,
  /** isEditted of BucketDetailListForRelatedAsset. */
  isEditted: PropTypes.bool,
  /** listData of BucketDetailListForRelatedAsset. */
  listData: PropTypes.array,
  /** Function to be called on rearrange of buckets */
  onDragEnd: PropTypes.func,
  /** Item Id */
  bucketId: PropTypes.string,
  /** Function to be called on bucket item remove */
  removeBucketItem: PropTypes.func,
  /** display image change action of BucketDetailListForRelatedAsset. */
  onAssetDisplayImageChange: PropTypes.func
}

export default BucketDetailListForRelatedSeason
