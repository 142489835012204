import * as React from 'react'

import JobTable from './JobTable'
import JobManagerHeader from './JobManagerHeader'
import JobDetails from './JobDetails'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import './JobManager.scss'

const groupByList = [
  {
    name: 'Job Status',
    id: 'jobStatus'
  },
  {
    name: 'Created By User',
    id: 'triggeredBy'
  },
  {
    name: 'Asset',
    id: 'assetId'
  },
  {
    name: 'Channel',
    id: 'channelCode'
  },
  {
    name: 'Match OPID',
    id: 'matchOpid'
  },
  {
    name: 'Job Type',
    id: 'jobType'
  }
]

const timeList = [
  {
    name: 'Last 24 Hours',
    id: '1'
  },
  {
    name: 'Last 2 Days',
    id: '2'
  },
  {
    name: 'Last 3 days',
    id: '3'
  },
  {
    name: 'Last 1 Week',
    id: '7'
  },
  {
    name: 'Last 1 Month',
    id: '30'
  }
]

class JobManager extends React.Component {
  constructor (props) {
    super(props)
    const isInitalSearchEnable = props.match && props.match.params && props.match.params.id
    this.state = {
      selectedGroup: '',
      selectedTime: '1',
      sort: isInitalSearchEnable ? {
        order: 'desc',
        value: 'relevance'
      } : {
        order: 'desc',
        value: 'createdAt'
      },
      isSidebarVisible: false,
      selectedErrors: [],
      jobTableFilter: [{
        displayName: 'Created At',
        fieldName: null,
        isRequired: false,
        name: 'createdAt',
        type: 'DATE',
        value: new Date(moment().subtract(24, 'hour')).toISOString() + ',' + new Date(moment()).toISOString()
      }],
      selectedJob: '',
      searchString: isInitalSearchEnable ? props.match.params.id : null,
      jobDetails: {},
      isAllErrorSelect: false,
      isRecreateLoading: false,
      isRecreateModalVisible: false,
      jobList: []
    }
    this.firstTimeFlag = true
  }

  componentDidMount = () => {
    document.title = 'Job Manager - Optus CMS'
  }

  onGroupSelect = (selectedGroup) => {
    this.setState({ selectedGroup })
  }

  onTimeSelect = (selectedTime) => {
    let { jobTableFilter } = this.state
    if (selectedTime) {
      const startTime = moment()
      const endTime = moment().subtract((parseInt(selectedTime) * 24), 'hour')
      const timeFilter = {
        displayName: 'Created At',
        fieldName: null,
        isRequired: false,
        name: 'createdAt',
        type: 'DATE',
        value: new Date(endTime).toISOString() + ',' + new Date(startTime).toISOString()
      }
      const index = jobTableFilter.findIndex((item) => item.name === 'createdAt')
      if (index > -1) { jobTableFilter[index] = timeFilter } else { jobTableFilter.push(timeFilter) }
    }
    this.setState({ selectedTime, jobTableFilter })
  }

  onTypeSelect = (type) => {
    let { jobTableFilter } = this.state
    const timeFilter = {
      displayName: 'Job Type',
      fieldName: null,
      isRequired: false,
      name: 'type',
      type: 'KEYWORD',
      value: type
    }
    const index = jobTableFilter.findIndex((item) => item.name === 'type')
    if (index > -1) { jobTableFilter[index] = timeFilter } else { jobTableFilter.push(timeFilter) }
    this.setState({ jobTableFilter })
  }

  onStatusSelect = (status) => {
    let { jobTableFilter } = this.state
    const timeFilter = {
      displayName: 'Job Status',
      fieldName: null,
      isRequired: false,
      name: 'status',
      type: 'KEYWORD',
      value: status
    }
    const index = jobTableFilter.findIndex((item) => item.name === 'status')
    if (index > -1) { jobTableFilter[index] = timeFilter } else { jobTableFilter.push(timeFilter) }
    this.setState({ jobTableFilter })
  }

  changeFilterValue = (filterVal) => {
    const { selectedTime } = this.state
    let jobTableFilter = filterVal.filter((item) => item.value)
    const statusFilter = this.state.jobTableFilter.filter(item => item.name === 'type' || item.name === 'status')
    let timeFilter
    if (selectedTime) {
      const startTime = moment()
      const endTime = moment().subtract((parseInt(selectedTime) * 24), 'hour')
      timeFilter = {
        displayName: 'Created At',
        fieldName: null,
        isRequired: false,
        name: 'createdAt',
        type: 'DATE',
        value: new Date(endTime).toISOString() + ',' + new Date(startTime).toISOString()
      }
      jobTableFilter.push(timeFilter)
    }
    if (statusFilter.length) {
      jobTableFilter = [...jobTableFilter, ...statusFilter]
    }
    this.setState({ jobTableFilter })
  }

  onSortChange = (sort) => {
    this.setState({ sort })
  }

  toggleSidebar = () => {
    const { jobList } = this.state
    this.setState({ isSidebarVisible: jobList && jobList.length ? !this.state.isSidebarVisible : false })
  }

  onSelectErrors = (selectedErrors) => {
    this.setState({ selectedErrors, isRecreateLoading: false })
  }

  onRetry = () => {
    this.onAuditChange()
    this.setState({ isAllErrorSelect: true, isRecreateLoading: true }, () => {
      this.setState({ isAllErrorSelect: false })
    })
  }

  onRetryFromDetails = () => {
    this.setState({ isRecreateModalVisible: true }, () => {
      this.setState({ isRecreateModalVisible: false })
    })
  }

  onSearchFilter = (searchString) => {
    let { jobTableFilter, sort } = this.state
    const index = jobTableFilter.findIndex((item) => item.name === 'createdAt')
    if (searchString) {
      if (index > -1) { jobTableFilter.splice(index, 1) }
      sort = {
        order: 'desc',
        value: 'relevance'
      }
    } else {
      sort = {
        order: 'desc',
        value: 'createdAt'
      }
      const timeFilter = index > -1 ? jobTableFilter[index] : {
        displayName: 'Created At',
        fieldName: null,
        isRequired: false,
        name: 'createdAt',
        type: 'DATE',
        value: new Date(moment().subtract(24, 'hour')).toISOString() + ',' + new Date(moment()).toISOString()
      }
      if (index > -1) { jobTableFilter[index] = timeFilter } else { jobTableFilter.push(timeFilter) }
    }
    this.setState({ searchString, sort, jobTableFilter })
  }

  changeSelectedJob = (jobDetails) => {
    this.props.history.push(`/jobs/${jobDetails.id}`)
    if (!this.firstTimeFlag) {
      this.setState({ isSidebarVisible: true, selectedJob: jobDetails.id, jobDetails })
    } else {
      this.setState({ selectedJob: jobDetails.id, jobDetails })
    }
    this.firstTimeFlag = false
  }

  onChangeJobDetails = (jobDetails) => {
    this.setState({ jobDetails })
  }

  onAuditChange = () => {
    this.setState({ isAuditChange: true }, () => {
      this.setState({ isAuditChange: false })
    })
  }

  downloadAll = () => {
    const { jobList } = this.state
    let csv = 'Job Type,Job ID,Job Name,Triggered By,Triggered Date,Asset Title,Asset ID,Channel Name,Channel Code,Start/End Time,Site,Match,Match Date,Match OPID,Last Updated Status,Job Status\n'
    jobList.map((job) => {
      csv += `${job.jobType && job.jobType.jobType ? job.jobType.jobType : ''},`
      csv += `${job.jobId ? job.jobId : ''},`
      csv += `${job.jobName ? (job.jobName).replace(/\n/g, '') : ''},`
      csv += `${job.triggeredBy ? job.triggeredBy : ''},`
      csv += `${job.triggeredDate ? job.triggeredDate : ''},`
      csv += `${job.assetTitle ? (job.assetTitle).replace(/\n/g, '') : ''},`
      csv += `${job.assetId ? job.assetId : ''},`
      csv += `${job.channelName ? job.channelName : ''},`
      csv += `${job.channelCode ? job.channelCode : ''},`
      csv += `${job.startEndTime ? job.startEndTime : ''},`
      csv += `${job.site ? job.site : ''},`
      csv += `${job.matchName ? job.matchName : ''},`
      csv += `${job.matchDate ? moment(job.matchDate, 'DD MMM YYYY, HH:mm').format('DD MMM YYYY HH:mm') : ''},`
      csv += `${job.matchOpid ? job.matchOpid : ''},`
      csv += `${job.lastUdated ? job.lastUdated : ''},`
      csv += `${job.jobStatus && job.jobStatus.status ? job.jobStatus.status : ''}\n`
    })
    let hiddenElement = document.createElement('a')
    document.body.appendChild(hiddenElement)
    hiddenElement.href = 'data:text/csvcharset=utf-8,' + encodeURIComponent(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'jobList.csv'
    hiddenElement.click()
    document.body.removeChild(hiddenElement)
  }

  sendJobList = (jobList) => {
    this.setState({ jobList, isSidebarVisible: jobList && jobList.length ? this.state.isSidebarVisible : false })
  }

  render () {
    const { sort, isSidebarVisible, selectedErrors, selectedGroup, jobDetails, jobTableFilter, isAllErrorSelect, isRecreateLoading, isRecreateModalVisible, searchString, isAuditChange } = this.state
    const isRetryEnabled = !!(selectedErrors && selectedErrors.length)
    return <div className='job-manager-container' id='job-manager-container'>
      <div className={`job-header-and-table${isSidebarVisible ? ' sidebar' : ''}`}>
        <JobManagerHeader
          groupByList={groupByList}
          onGroupSelect={this.onGroupSelect}
          onTypeSelect={this.onTypeSelect}
          onStatusSelect={this.onStatusSelect}
          onTimeSelect={this.onTimeSelect}
          timeList={timeList}
          parent={'job-manager-container'}
          sort={sort}
          searchString={searchString}
          onSortChange={this.onSortChange}
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={this.toggleSidebar}
          isRetryEnabled={isRetryEnabled}
          onRetry={this.onRetry}
          onSearchFilter={this.onSearchFilter}
          isRecreateLoading={isRecreateLoading}
          changeFilterValue={this.changeFilterValue}
          downloadAll={this.downloadAll} />
        <JobTable
          onSelectErrors={this.onSelectErrors}
          selectedErrors={selectedErrors}
          changeSelectedJob={this.changeSelectedJob}
          selectedGroup={selectedGroup}
          filterData={jobTableFilter}
          sort={sort}
          searchString={searchString}
          isAllErrorSelect={isAllErrorSelect}
          isRecreateModalVisible={isRecreateModalVisible}
          onChangeJobDetails={this.onChangeJobDetails}
          sendJobList={this.sendJobList}
          onAuditChange={this.onAuditChange} />
      </div>
      {isSidebarVisible && !_.isEmpty(jobDetails) ? <JobDetails filterData={jobTableFilter} sort={sort} searchString={searchString} jobDetails={jobDetails} onRetry={this.onRetryFromDetails} isAuditChange={isAuditChange} /> : ''}
    </div>
  }
}

export default withRouter(JobManager)
