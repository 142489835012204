import React, { Component } from 'react'
import { Row, Col, message } from 'antd'
import { withRouter } from 'react-router-dom'

import './AppManager.scss'
import AppLeftSideBar from './AppLeftSideBar'
import AppEditor from './AppEditor'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import { utilityService } from '../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetAppConfigList from '../../graphQL/appManager/listAppConfigs'
import QueryGetAppPageList from '../../graphQL/appManager/listAppPages'
import MutationDiscardPageLayoutItem from '../../graphQL/appManager/discardPageLayoutItem'

class AppManager extends Component {
  state= {
    appMode: 'pages',
    selectedGroupId: '',
    selectedConfigId: '',
    showSwitchWarning: false,
    showPageSwitchWarning: false,
    prevConfigId: '',
    tempConfigId: '',
    tempPageId: '',
    forceSave: false,
    forcePageSave: false,
    isConfigSaving: false,
    configEditted: false,
    isPageSaving: false,
    pageEditted: false,
    selectedPageId: '',
    selectedAppSetting: 'Apps',
    refetchPageList: false,
    discardPageLayoutItem: false,
    pageAppDetails: [], // for discard api,
    shouldRecreateConfigTree: false, // for recreating configlist tree anfter duplication,
    shouldRefetchConfigDetails: false, // for refetching data after group critical flag is changed for configs
    shouldRefetchPageDetails: false // for refetching data after group critical flag is changed for pages
  }

  // dummy commit

  componentDidMount = () => {
    const { project, permissions } = this.props
    const userPermissions = permissions['APP_MANAGER']
    const isViewConfigDisabled = userPermissions.indexOf('VIEW_CONFIG') === -1
    const isViewPageDisabled = userPermissions.indexOf('VIEW_PAGE') === -1
    const isViewSettingDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    document.title = `App Manager - ${project === 'hyperion' ? 'Hyperion' : 'Optus'} CMS`
    if (this.props.history && this.props.history.location.pathname) {
      const pathArray = (this.props.history.location.pathname).split('/')
      if (pathArray[2] && pathArray[3]) {
        if (pathArray[2] === 'configs' && !isViewConfigDisabled) {
          this.setState({ selectedConfigId: pathArray[3], appMode: pathArray[2] })
        } else if (pathArray[2] === 'pages' && !isViewPageDisabled) {
          this.setState({ selectedPageId: pathArray[3], appMode: pathArray[2] })
        }
        // this.setState({ selectedConfigId: pathArray[3] })
      } else if (pathArray[2] && pathArray[2] === 'settings' && !isViewSettingDisabled) {
        this.setState({ appMode: pathArray[2] })
      } else if (pathArray[2] && pathArray[2] === 'configs' && !isViewConfigDisabled) {
        this.setState({ appMode: pathArray[2] })
      }
    }
  }

  onAppConfigChange = (configId) => {
    if (this.state.configEditted) {
      this.setState({ tempConfigId: configId, showSwitchWarning: true })
    } else {
      this.setState({ selectedConfigId: configId, tempConfigId: '' })
      this.props.history.push(`/apps/${window.location.search ? 'configs/' + configId + window.location.search : 'configs/' + configId}`)
    }
  }

  onAppPageChange = (pageId) => {
    if (this.state.pageEditted) {
      this.setState({ tempPageId: pageId, showPageSwitchWarning: true })
    } else {
      this.setState({ selectedPageId: pageId, tempPageId: '' })
      this.props.history.push(`/apps/${window.location.search ? 'pages/' + pageId + window.location.search : 'pages/' + pageId}`)
    }
  }

  forceSave=() => {
    this.setState({ forceSave: true, isConfigSaving: true })
  }

  forcePageSave=() => {
    this.setState({ forcePageSave: true, isPageSaving: true })
  }

  updateForceSaveState=(value) => {
    if (value) {
      this.setState({ forceSave: false, isConfigSaving: false, configEditted: false, showSwitchWarning: false }, () => {
        if (this.state.tempConfigId) {
          this.onAppConfigChange(this.state.tempConfigId)
        } else if (this.state && this.state.tempEvent) {
          this.handleAppModeChange(this.state.tempEvent)
        }
      })
    } else {
      this.setState({ forceSave: false, isConfigSaving: false, showSwitchWarning: false, tempEvent: null })
    }
  }

  updatePageForceSaveState=(value) => {
    if (value) {
      this.setState({ forcePageSave: false, isPageSaving: false, pageEditted: false, showPageSwitchWarning: false }, () => {
        if (this.state.tempPageId) {
          this.onAppPageChange(this.state.tempPageId)
        } else if (this.state && this.state.tempEvent) {
          this.handleAppModeChange(this.state.tempEvent)
        }
      })
    } else {
      this.setState({ forcePageSave: false, isPageSaving: false, showPageSwitchWarning: false, tempEvent: null })
    }
  }

  changeConfigEditStatus=(value) => {
    this.setState({ configEditted: value })
  }

  changePageEditStatus=(value) => {
    this.setState({ pageEditted: value })
  }

  onCloseConfirmModal=() => {
    this.setState({ showSwitchWarning: false, forceSave: false, isConfigSaving: false, tempConfigId: '', tempEvent: null })
  }

  onCloseConfirmPageModal=() => {
    this.setState({ showPageSwitchWarning: false, forcePageSave: false, isPageSaving: false, tempPageId: '', tempEvent: null })
  }

  discardChanges=() => {
    this.setState({ showSwitchWarning: false, forceSave: false, isConfigSaving: false, configEditted: false }, () => {
      if (this.state && this.state.tempConfigId) {
        this.onAppConfigChange(this.state.tempConfigId)
      } else if (this.state && this.state.tempEvent) {
        this.handleAppModeChange(this.state.tempEvent)
      }
    })
  }

  discardPageChanges=() => {
    const { pageAppDetails } = this.state
    let layoutIds = (pageAppDetails.layoutItems || []).map(item => item.id)
    let app = (pageAppDetails && pageAppDetails.app && pageAppDetails.app.id) || null
    if (!_.isEmpty(layoutIds) && app) {
      this.props.discardPageLayoutItem(layoutIds, pageAppDetails.id, app).then(() => {

      }, (error) => {
        utilityService.handleError(error)
      })
    }

    this.setState({ showPageSwitchWarning: false, forcePageSave: false, isPageSaving: false, pageEditted: false, discardPageLayoutItem: true }, () => {
      if (this.state && this.state.tempPageId) {
        this.onAppPageChange(this.state.tempPageId)
      } else if (this.state && this.state.tempEvent) {
        this.handleAppModeChange(this.state.tempEvent)
      }
    })
  }

  handleAppModeChange = e => {
    const { permissions } = this.props
    const { selectedConfigId, selectedPageId } = this.state
    const userPermissions = permissions['APP_MANAGER']
    const isViewConfigDisabled = userPermissions.indexOf('VIEW_CONFIG') === -1
    const isViewPageDisabled = userPermissions.indexOf('VIEW_PAGE') === -1
    const isViewSettingDisabled = userPermissions.indexOf('APP_ADMIN') === -1
    if (e.target.value === 'configs' && isViewConfigDisabled) {
      message.error('You do not have permission to view configs')
      return
    } else if (e.target.value === 'pages' && isViewPageDisabled) {
      message.error('You do not have permission to view pages')
      return
    } else if (e.target.value === 'settings' && isViewSettingDisabled) {
      message.error('You do not have permission to view settings')
      return
    } else if (this.state.pageEditted && e.target.value === 'configs') {
      this.setState({ showPageSwitchWarning: true, tempEvent: e })
      return
    } else if (this.state.configEditted && e.target.value === 'pages') {
      this.setState({ showSwitchWarning: true, tempEvent: e })
      return
    } else if (this.state.configEditted && e.target.value === 'settings') {
      this.setState({ showSwitchWarning: true, tempEvent: e })
      return
    } else if (this.state.pageEditted && e.target.value === 'settings') {
      this.setState({ showPageSwitchWarning: true, tempEvent: e })
      return
    }
    const appMode = e.target.value
    if (e.target.value === 'settings') {
      this.props.history.push(`/apps/settings`)
    } else if (e.target.value === 'configs' && !selectedConfigId) {
      this.props.history.push(`/apps/configs`)
    } else if (e.target.value === 'configs' && selectedConfigId) {
      this.props.history.push(`/apps/configs/${selectedConfigId}`)
    } else if (e.target.value === 'pages' && !selectedPageId) {
      this.props.history.push(`/apps/pages`)
    } else if (e.target.value === 'pages' && selectedPageId) {
      this.props.history.push(`/apps/pages/${selectedPageId}`)
    } else {
      this.setState({ selectedAppSetting: 'Apps' })
    }
    this.setState({ appMode, tempEvent: null })
  }

  handleAppListSelect = (setting) => {
    this.setState({
      selectedAppSetting: setting
    })
  }

  handleRefetchPageList=(value) => {
    this.setState({ refetchPageList: value })
  }

  handlePageDetails = (pageAppDetails) => {
    this.setState({
      pageAppDetails
    })
  }

  handleRecreateConfigTree=(value) => {
    this.setState({ shouldRecreateConfigTree: value })
  }

  handleRefetchConfigDetails=(value) => {
    this.setState({ shouldRefetchConfigDetails: value })
  }

  handleRefetchPageDetails=(value) => {
    this.setState({ shouldRefetchPageDetails: value })
  }

  render () {
    const { selectedConfigId, showSwitchWarning, forceSave, isConfigSaving, configEditted, selectedPageId, appMode, isPageSaving, showPageSwitchWarning, forcePageSave,
      pageEditted, selectedAppSetting, refetchPageList, shouldRecreateConfigTree, shouldRefetchConfigDetails, shouldRefetchPageDetails } = this.state
    const config = (this.props.configList || []).find(item => item && selectedConfigId && item.id === selectedConfigId)
    const page = (this.props.pageList || []).find(item => item && selectedPageId && item.id === selectedPageId)
    return (
      <Row className='app-manager'>
        <Col xs={{ span: 6 }} >
          <AppLeftSideBar
            selectedConfig={selectedConfigId}
            selectedPage={selectedPageId}
            onSelectConfigGroup={this.onAppConfigChange}
            onSelectPageGroup={this.onAppPageChange}
            appClient={this.props.appClient}
            appMode={appMode}
            handleAppModeChange={this.handleAppModeChange}
            selectedAppSetting={selectedAppSetting}
            handleAppListSelect={this.handleAppListSelect}
            refetchPageList={refetchPageList}
            handleRefetchPageList={this.handleRefetchPageList}
            handleRecreateConfigTree={this.handleRecreateConfigTree}
            shouldRecreateConfigTree={shouldRecreateConfigTree}
            handleRefetchConfigDetails={this.handleRefetchConfigDetails}
            handleRefetchPageDetails={this.handleRefetchPageDetails}
            userId={this.props.userId}
            // searchedConfig={this.props.history && this.props.history.location.pathname && this.props.history.location.pathname.split('/').length === 3}
          />
        </Col>
        <Col xs={{ span: 18 }} className='app-editor-container' id='app-details-container'>
          <AppEditor
            selectedConfig={selectedConfigId}
            selectedPage={selectedPageId}
            appClient={this.props.appClient}
            project={this.props.project}
            onSelectConfigGroup={this.onAppConfigChange}
            onSelectPageGroup={this.onAppPageChange}
            forceSave={forceSave}
            forcePageSave={forcePageSave}
            updateForceSaveState={this.updateForceSaveState}
            updatePageForceSaveState={this.updatePageForceSaveState}
            changeConfigEditStatus={this.changeConfigEditStatus}
            changePageEditStatus={this.changePageEditStatus}
            configEditted={configEditted}
            appMode={appMode}
            pageEditted={pageEditted}
            selectedAppSetting={selectedAppSetting}
            refetchPageList={this.handleRefetchPageList}
            handlePageDetails={this.handlePageDetails}
            handleRecreateConfigTree={this.handleRecreateConfigTree}
            shouldRefetchConfigDetails={shouldRefetchConfigDetails}
            shouldRefetchPageDetails={shouldRefetchPageDetails}
            handleRefetchConfigDetails={this.handleRefetchConfigDetails}
            handleRefetchPageDetails={this.handleRefetchPageDetails}
            permissions={this.props.permissions}
            userId={this.props.userId}
          />
        </Col>
        <ConfirmModal
          isVisible={showSwitchWarning}
          title={`Config: ${config ? config.title : selectedConfigId}`}
          message={'Changes made by you will be discarded. Do you wish to continue ?'}
          isLeftPrior={false}
          leftButtonText={'Discard'}
          rightButtonText={'Save'}
          handleSubmit={this.forceSave}
          handleCancel={this.discardChanges}
          handleClose={this.onCloseConfirmModal}
          isSubmitButtonDisabled={false}
          isCancelButtonDisabled={false}
          isLoading={isConfigSaving}
        />

        <ConfirmModal
          isVisible={showPageSwitchWarning}
          title={`Page : ${page ? page.title : selectedPageId}`}
          message={'Changes made by you will be discarded. Do you wish to continue ?'}
          isLeftPrior={false}
          leftButtonText={'Discard'}
          rightButtonText={'Save'}
          handleSubmit={this.forcePageSave}
          handleCancel={this.discardPageChanges}
          handleClose={this.onCloseConfirmPageModal}
          isSubmitButtonDisabled={false}
          isCancelButtonDisabled={false}
          isLoading={isPageSaving}
        />
      </Row>
    )
  }
}

AppManager.propTypes = {

}

export default withRouter(withApollo(
  compose(
    graphql(
      QueryGetAppConfigList,
      {
        options: ({ project, appClient }) => {
          return {
            variables: { limit: 499, project },
            fetchPolicy: 'network-only',
            client: appClient
          }
        },
        props: (props) => {
          const configList = props.data && props.data.listAppConfig ? props.data.listAppConfig.items : []
          return {
            configList
          }
        }
      }
    ),
    graphql(
      QueryGetAppPageList,
      {
        options: ({ project, appClient }) => {
          return {
            variables: { limit: 499, project },
            fetchPolicy: 'network-only',
            client: appClient
          }
        },
        props: (props) => {
          const pageList = props.data && props.data.listAppPages ? props.data.listAppPages.items : []
          return {
            pageList
          }
        }
      }
    ),
    graphql(
      MutationDiscardPageLayoutItem, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          discardPageLayoutItem: (layoutItems, page, app) => {
            return props.mutate({
              variables: { layoutItems, page, project: props.ownProps.project, app }
            })
          }
        })
      }
    )

  )(AppManager)))
