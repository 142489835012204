import gql from 'graphql-tag'

export default gql(`
query checkUserAccess( $user: String! $project: String $isFirstSignIn: Boolean $userAgent: String) {
        checkUserAccess(
            user: $user
            project: $project
            isFirstSignIn: $isFirstSignIn
            userAgent: $userAgent
        ) {
            mfaStatus
            projectAccess
        }
    }`
)
