import React from 'react'
import PropTypes from 'prop-types'
import { Modal, DatePicker } from 'antd'
import moment from 'moment'

import './../../ui.style.scss'

const { RangePicker } = DatePicker

class RangeCalenderModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    const { isVisible, handleCancel, title, handleDateChange, resetState, handleDateRangeSubmit, startDate, endDate, disabled } = this.props
    return (

      <Modal
        className='general-modal'
        visible={isVisible}
        closable={false}
        title={title}
        okButtonProps={{ disabled: disabled, loading: false }}
        onOk={handleDateRangeSubmit}
        onCancel={handleCancel}
        okText={'Confirm'}
        cancelText='Cancel'
        afterClose={resetState}
        width='500px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <div className='date-picker-container'>
          {/* <label>Date<span>*</span></label> */}
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleDateChange}
            format='DD-MMM-YYYY HH:mm'
            value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
            defaultValue={null}
          />
        </div>

      </Modal>
    )
  }
}

RangeCalenderModal.propTypes = {
  /** visible action of RangeCalenderModal. */
  visible: PropTypes.bool,
  /** title  of RangeCalenderModal. */
  title: PropTypes.string,
  /** handleCancel action of RangeCalenderModal. */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

RangeCalenderModal.defaultProps = {
}

export default RangeCalenderModal
