import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
// import SeasonFilter from './SeasonFilter'
import CrossIcon from '../../general/icons/CrossIcon'
import ExpandedIcon from '../../general/icons/ExpandedIcon'

import MultilineEllipse from '../../../MultilineEllipse'
import placeholderImage from './../../../../assets/images/default-image.jpg'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryFilterAssets from '../../../../graphQL/asset/searchAssets'
// import SubscriptionAssetList from '../../../../graphQL/asset/createAssetSubscription'
import { utilityService } from '../../../../services/UtilityService'
import { generateCroppedThumbnail } from '../../../../util/util'

import './../../ui.style.scss'

const Option = Select.Option

class SeasonDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isSearchPresent: false,
      selectedSeason: undefined,
      seasonSearch: '',
      selectedSeries: undefined,
      isSelected: false,
      seriesSearch: undefined
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    const selectedSeason = newProps.selectedSeason && newProps.selectedSeason.id ? (newProps.assetList || []).find(item => item.id === newProps.selectedSeason.assetId) : null
    if (!_.isEmpty(selectedSeason)) {
      this.setState({
        selectedSeason,
        isSelected: true
      })
    } else if (_.isEmpty(newProps.selectedSeason)) {
      this.setState({
        selectedSeason: undefined
      })
    }
  }

    onSeasonSelect = (id) => {
      let selectedSeason = null
      if (id) {
        selectedSeason = (this.props.assetList || []).find(item => item.id === id)
        this.setState({
          selectedSeason,
          isSelected: true
        })
      }
      this.props.onSeasonSearch('')
      this.props.handleSeasonSelect(selectedSeason)
    }

    onSeasonSearch = (value) => {
      this.props.onSeasonSearch(value)
    }

    onSeriesSearch = (value) => {
      this.setState({
        seariesSearch: value
      })
    }

    clearFilter = (e) => {
      e.stopPropagation()
      this.setState({ selectedSeries: undefined, isSelected: false })
    }

    onChangeSeason = (id) => {
      this.onSeasonSelect(id, true)
    }

    render () {
      const { selectedSeason } = this.props
      // const { selectedSeason, seriesSearch, isSelected, selectedSeries } = this.state
      const { parentComponent, assetList } = this.props
      const image = placeholderImage
      const seasonComponents = assetList ? (assetList || []).map((season, index) => {
        const imageUrl = season.defaultMedia ? generateCroppedThumbnail(season.defaultMedia, 87, 48, '16:9') : null
        return <Option className='season-drop-down-option' key={`option${index}`} value={season.id}>
          <div className='season-list-cell'>
            <div className='details'>
              <MultilineEllipse maxLines={'2'} text={season.title || 'Untitled'} />
            </div>
            <div className='season-thumb' style={{ backgroundImage: `url(${imageUrl || image})` }} />
            <a className='season-id' target='#' href={`${location.origin}/assets/${season.id}`} >{season.id}</a>
          </div>
        </Option>
      }) : null

      // const seasonFilters = <SeasonFilter
      //   parent={parentComponent}
      //   selectedSeason={selectedSeason || undefined}
      //   onSeriesSelect={this.onSeriesSelect}
      //   onSeriesSearch={this.onSeriesSearch}
      //   seriesSearch={seriesSearch}
      //   isSelected={isSelected}
      //   clearFilter={this.clearFilter}
      //   clearSearchField={this.onChangeSeason}
      //   selectedSeries={selectedSeries}
      //   series={series}
      // />

      const selectedValue = selectedSeason ? (

        <React.Fragment>
          <div className='selected-season-details'>
            <div className='season-data'>
              <div className='selected-season'>
                <p>{selectedSeason.title}</p>
              </div>
            </div>
            {/* <div className='left-icon'>{seasonFilters}</div> */}
          </div>
        </React.Fragment>
      ) : <div className='left-icon' style={{ float: 'right', marginTop: '2px' }} />
      // ) : <div className='left-icon' style={{ float: 'right', marginTop: '2px' }}>{ seasonFilters }</div>
      return (
        <Select key={JSON.stringify(selectedSeason)}
          className='season-drop-down'
          placeholder='Select Season'
          showSearch
          allowClear
          suffixIcon={<ExpandedIcon />}
          clearIcon={<CrossIcon />}
          value={selectedValue}
          onChange={this.onSeasonSelect}
          mode='default'
          getPopupContainer={() => document.getElementById(parentComponent)}
          filterOption={false}
          onSearch={this.onSeasonSearch}
        >
          { seasonComponents }
        </Select>

      )
    }
}

SeasonDropDown.propTypes = {
  /** object to filter the service call */
  filterVal: PropTypes.object,
  /** function for season search */
  onSeasonSearch: PropTypes.func,
  /** season search value */
  searchString: PropTypes.string,
  /** function for handle season select option */
  handleSeasonSelect: PropTypes.func
}

SeasonDropDown.defaultProps = {
  parentComponent: 'asset-container'
}

export default withApollo(compose(
  graphql(
    QueryFilterAssets,
    {
      options: (props) => {
        const { searchString, filterVal, project } = props
        const sort = {
          value: 'updatedAt',
          order: 'desc'
        }
        const variables = utilityService.getFormattedAssetFilter(searchString, filterVal, sort, project)
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const assetList = data.listAssets ? data.listAssets.items : []
        return {
          assetList,
          isLoading: data.loading || !data.listAssets,
          totalCount: data.listAssets ? data.listAssets.totalCount : 0,
          getAssetList: (page) => {
            return data.fetchMore({
              fetchPolicy: 'network-only',
              variables: {
                offset: page,
                project: props.ownProps.project
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const newList = [...prev.listAssets.items, ...fetchMoreResult.listAssets.items]
                prev.listAssets.items = newList
                return prev
              }
            })
          }
          // subscribeToNewAsset: () => {
          //   return props.data.subscribeToMore({
          //     document: SubscriptionAssetList,
          //     updateQuery: (prev, { subscriptionData: { data: { assetCreated } } }) => {
          //       if (!assetCreated) return prev
          //       const newList = [ assetCreated, ...prev.listAssets.items ]
          //       prev.listAssets.items = newList
          //       prev.listAssets.totalCount = prev.listAssets.totalCount + 1
          //       return prev
          //     }
          //   })
          // }
        }
      }

    }
  )
)(SeasonDropDown))
