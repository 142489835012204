import gql from 'graphql-tag'

export default gql(`
    mutation(
        $name: String!
        $partner: ID!
        $terms: String    
        $project: String 
   ) {
        createOffer(
            input: {
            name: $name
            partner: $partner    
            terms: $terms    
            }
            project: $project
        ) {
            id
            externalId
            name    
            description
            mediumDescription
            shortDescription   
            terms
            isActive
            startDate
            endDate
            recurrence
            isArchived
            partner{
                id
                name
                logo{
                    id
                    mediaId
                    project
                    name
                    url
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                          url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2          
                    }
                }
            }
        }
    }`
)
