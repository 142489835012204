import React from 'react'
import PropTypes from 'prop-types'

import { Switch } from 'antd'
import _ from 'lodash'
import Input from './../inputs/Input'
import { generateCroppedThumbnail } from '../../../../util/util'
// import MultipleDropDown from './../inputs/AppMutiDorpDown'
import AddIcon from '../../general/icons/AddIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import CropWhiteIcon from '../../general/icons/CropWhiteIcon'
import TextAreaInput from '../inputs/TextArea'
import MultipleDropDown from '../inputs/AppMutiDorpDown'
import './../../ui.style.scss'

// const skippedTagsName = []

class EditAppDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showCrop: false,
      selectedIndex: undefined,
      icon: props.icon || null
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (!_.isEqual(this.props.icon, nextProps.icon) && nextProps.icon) {
      let icon = nextProps.icon
      this.setState({ icon })
    }
    if (this.props.croppedUrl !== nextProps.croppedUrl) {
      this.setState({ isBoolEnabled: true })
    }

    if (nextProps.isDeleteSuccess) {
      this.resetState()
    }
  }

    onTagSearch = (searchTag) => {
      this.setState({
        searchTag
        // selectedIndex: searchTag ? index : undefined
      })
    }

    hideCrop = () => {
      this.setState({ showCrop: false })
    }

    showCropModal = (e) => {
      const { icon } = this.state
      e.stopPropagation()
      if (!_.isEmpty(icon)) {
        this.props.showCropModal(icon, false)
      } else {
        this.props.showImageModal(false)
      }
    }

    removeImage = (e) => {
      e.stopPropagation()
      this.setState({ icon: null, isBoolEnabled: true })
      this.props.clearSelection()
    }

    showCrop = (e) => {
      e.stopPropagation()
      this.setState({ showCrop: true })
    }

    render () {
      const { name, selectedKey, handleTextChange, croppedUrl, url, handleURLChange, showImageModal, handleIdChange, handleShortDescriptionChange, onInputFieldBlur, onInputFieldFocus, description, appMetaFields, isFromEdit, handleKeyChange, appKey, onChangeAppStatus, isCritical, userList, handleUserChange, handleUserSearch, assignedUsers, isAppAdminDisabled, userSearch } = this.props
      const { icon, showCrop } = this.state
      const image = !_.isEmpty(icon) ? generateCroppedThumbnail(icon, 67, 67, '1:1') : undefined
      const defaultImage = require('../../../../assets/images/default-image-square.jpg')
      const currentImage = image || defaultImage
      return (
        <div className='edit-app-details'>
          <div className='edit-input-container'>
            <div className='edit-input-inner-container'>
              <div className='asset-icon'>
                {/* <span><span className='mandatory'>*</span></span> */}
                <div className={!_.isEmpty(image) ? 'avatar' : 'avatar-noimage'} onClick={showImageModal} onMouseLeave={() => this.hideCrop()}>
                  { showCrop

                    ? <div className={!_.isEmpty(image) ? 'avatar-hover' : 'avatar-noimage-hover'} onClick={(e) => this.showCropModal(e)}>
                      <span>{ image ? <CropWhiteIcon /> : <AddIcon /> }</span>
                    </div>
                    : <React.Fragment>
                      <div onMouseEnter={(e) => this.showCrop(e)} onMouseLeave={() => this.hideCrop()}>
                        <img size={180} className='user' src={croppedUrl || currentImage} />
                      </div>
                      { image ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e)} className='oval'>
                        <CrossIcon color='#FFFFFF' />
                      </div> : null}
                    </React.Fragment>
                  }
                </div>
              </div>
              <div className='edit-input-item-name'>
                <Input title='Name' placeholder='App Name' inputName='title' value={name} handleChange={handleTextChange} isRequired />
              </div>
            </div>
            <div className='edit-input-inner-container-noflex'>
              {/* <div className='edit-input-item'> */}
              <TextAreaInput
                title='Description'
                placeholder='Write a description...'
                inputName='shortDescription'
                onFocus={onInputFieldFocus}
                onBlur={onInputFieldBlur}
                value={description}
                handleChange={handleShortDescriptionChange}
              />
              {/* </div> */}
            </div>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <Input title='Key' placeholder='App Key' inputName='key' value={appKey} handleChange={handleKeyChange} isRequired readOnly={isFromEdit} />
              </div>
              <div className='switch-inner-container'>
                <span>{'Critical'}</span>
                <Switch checked={isCritical || false} onChange={onChangeAppStatus} />
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <Input title='Bundle ID' placeholder='Bundle ID' inputName='title' value={selectedKey} handleChange={handleIdChange} />
              </div>
              <div className='edit-input-item'>
                <Input title='URL' placeholder='URL' inputName='title' value={url} handleChange={handleURLChange} disabled={false} />
              </div>
            </div>
            <div className='edit-input-inner-container-noflex'>
              <div className='edit-input-item-noflex'>
                <div className='output-format' id='dropdown-app'>
                  <label className='title-text'>Users</label>
                  <MultipleDropDown options={userList} onOptionSelect={handleUserChange} selectedValue={assignedUsers} valueParam='id' displayParam='id' placeHolder='Select Users' parent={'dropdown-app'} disabled={isAppAdminDisabled} onSearch={handleUserSearch} searchText={userSearch} />
                </div>
              </div>
            </div>

          </div>
          {appMetaFields}
        </div>
      )
    }
}

EditAppDetails.propTypes = {
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /**  */
  handleFormatChange: PropTypes.func,
  /**  */
  handleTextChange: PropTypes.func,
  /**  */
  formats: PropTypes.array,
  /**  */
  selectedFormat: PropTypes.string,
  /** List of category */
  categoryList: PropTypes.array,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** Selected category valu */
  selectedCategory: PropTypes.string
}

export default EditAppDetails
