import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $name: String $slug: String $project: String) {
        updateAssetCategory(
            input: {
                id: $id
                name: $name
                slug: $slug
            }
            project: $project
        ) {
            id
            name
            slug
            isTransferring
            isError
        }
    }`
)
