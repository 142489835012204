import React from 'react'
import PropTypes from 'prop-types'

const FolderIcon = ({ width, height, selected, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <g fill='none' fillRule='evenodd'>
      <path fill={selected ? '#FFF' : '#343A40'} fillRule='nonzero' d='M19.017 7.086c.92 0 .966.37.92.964l-.63 9.813c-.051.598-.16 1.086-1.095 1.086H1.786c-.915 0-1.05-.493-1.096-1.086L.014 8.171C-.038 7.573.01 7.086.928 7.086zM1.344 2.88c.083-.959.405-.88 1.31-.88H6.91c1.084.005.906.058 1.556.774.857.937.992.921 2.125.921h7.439c.55 0 .758.154.784.81l.083 1.732H1.058c.026-.328.208-2.473.286-3.357z' />
      <path d='M0 0h20v20H0z' />
    </g>
  </svg>
)

FolderIcon.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

FolderIcon.defaultProps = {
  selected: false,
  width: 20,
  height: 20
}

export default FolderIcon
