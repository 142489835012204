import React, { Component } from 'react'
import { Table, Skeleton, message } from 'antd'
// import InfiniteScroll from 'react-infinite-scroller'
import AppContext from '../../../AppContext'

import ExpandMenuIcon from '../../../components/ui/general/icons/ExpandMenuIcon'
import PopoverButton from '../../../components/ui/general/buttons/PopoverButton'
// import MultipleSelectButton from '../../../components/ui/general/buttons/MultipleSelectButton'
// import DeleteButton from '../../../components/ui/general/buttons/DeleteButton'
// import BackArrowButton from '../../../components/ui/general/buttons/BackArrowButton'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import CreateConfigGroupModal from '../../../components/ui/dataEntry/other/CreateConfigGroupModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import DoneIcon from '../../../components/ui/general/icons/DoneIcon'
import CrossIcon from '../../../components/ui/general/icons/CrossIcon'
import ConfirmModal from '../../../components/ui/feedback/ConfirmModal'
import userMessages from '../../../constants/messages'
import { utilityService } from '../../../services/UtilityService'
// import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryListConfigGroups from '../../../graphQL/admin/channel/listConfigGroup'
import QueryListChannelStreams from '../../../graphQL/admin/channel/listStreamLevels'
import MutationDeleteChannelConfig from '../../../graphQL/admin/channel/deleteConfigGroupSchema'
import MutationCreateChannelConfig from '../../../graphQL/admin/channel/createConfigGroupSchema'
import MutationUpdateChannelConfig from '../../../graphQL/admin/channel/updateConfigGroupSchema'

const deleteConfigGroupMessage = {
  title: 'DELETE CONFIG GROUP',
  firstMessage: 'Are you sure you want to delete this Config Group?',
  secondMessage: 'This action cannot be undone.'
}

const options = [
  {
    type: 'edit',
    name: 'Edit'
  },
  {
    type: 'duplicate',
    name: 'Duplicate'
  }
]

class ChannelConfigGroups extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedConfigGroup: null,
      isLoading: !(props.configGroupList && props.configGroupList.length),
      isDuplicate: false,
      isEdit: false,
      showAddConfigGroupModal: false,
      showTransferContentPlaceholderModal: false,
      showDeleteConfigGroupModal: false,
      selectedConfigGroup: undefined,
      selectedRowKeys: [],
      isSelected: false,
      isMultipleDelete: false,
      isDeleteLoading: false,
      isCreateLoading: false
    }
    this.columns = [
      {
        title: 'Channel Streams',
        dataIndex: 'name',
        key: 'name',
        width: '37%',
        render: (name) => name ? name.toUpperCase() : ''
      },
      {
        title: 'Used',
        dataIndex: 'usedCount',
        width: '70%',
        key: 'usedCount'
      },
      {
        key: 'id',
        dataIndex: 'id',
        width: '3%',
        render: (id, data) => this.renderData(id, data.usedCount)
      }
    ]
  }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
      if (!newProps.isLoading && !_.isEqual(newProps.configGroupList, this.props.configGroupList)) {
        this.setState({ isLoading: false })
      }
    }
    componentWillUnmount () {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
      const { target } = event
      const { className } = target
      const availableOptions = ['edit', 'duplicate', 'delete']
      const isOptionClicked = availableOptions.findIndex(item => className === item)
      if (isOptionClicked > -1) {
        return
      }
      if (this.state.openedConfigGroup) {
        this.setState({ openedConfigGroup: null })
      }
    }

    toggleShowOptions = id => {
      if (this.state.openedConfigGroup === id) {
        this.setState({ openedConfigGroup: null })
      } else {
        this.setState({ openedConfigGroup: id })
      }
    }

    onSelectOption = (id, selectedOption) => {
      const { configGroupList } = this.props
      const selectedConfigGroup = configGroupList.find(item => item.id === id)
      if (!selectedConfigGroup) {
        return
      }
      if (selectedOption.type === 'edit') {
        this.setState({ showAddConfigGroupModal: true, selectedConfigGroup, isEdit: true })
      } else if (selectedOption.type === 'duplicate') {
        this.setState({ showAddConfigGroupModal: true, selectedConfigGroup, isDuplicate: true })
      } else if (selectedOption.type === 'delete') {
        this.setState({ showDeleteConfigGroupModal: true, selectedConfigGroup })
      }
      this.setState({ openedConfigGroup: null })
    }

    showAddConfigGroup = () => {
      this.setState({ showAddConfigGroupModal: true })
    }

    hideAddConfigGroupModal = () => {
      this.setState({ showAddConfigGroupModal: false, selectedConfigGroup: null, isDuplicate: false, isEdit: false })
    }

    handleAddConfigGroup = (configDetails) => {
      const { onHistoryUpdate } = this.props
      this.setState({ isCreateLoading: true })
      const { isEdit } = this.state
      const fields = (configDetails.fields || []).filter(field => field.name && field.type).map(field => {
        return {
          name: field.name,
          isRequired: field.isRequired,
          type: field.type
        }
      })
      const streams = (configDetails.streams || []).filter(stream => stream.levels && stream.levels.length).map(stream => {
        return { levels: (stream.levels).map(item => item.id) }
      })
      const variables = {
        name: configDetails.name,
        fields,
        streams
      }
      if (isEdit) {
        const { configGroupList } = this.props
        variables.id = configDetails.id
        const selectedConfig = (configGroupList || []).find(item => item.id === configDetails.id)
        variables.version = selectedConfig && selectedConfig.version ? parseInt(selectedConfig.version) + 1 : 1
        this.props.updateConfigGroupSchema(variables).then(() => {
          this.setState({ showAddConfigGroupModal: false, selectedConfigGroup: null, isDuplicate: false, isCreateLoading: false, isEdit: false })
          onHistoryUpdate()
          message.success('Config group updated successfully')
        }, error => {
          this.setState({ showAddConfigGroupModal: false, selectedConfigGroup: null, isDuplicate: false, isCreateLoading: false, isEdit: false })
          utilityService.handleError(error)
        })
      } else {
        variables.version = 1
        this.props.createConfigGroupSchema(variables).then(() => {
          this.setState({ showAddConfigGroupModal: false, selectedConfigGroup: null, isDuplicate: false, isCreateLoading: false, isEdit: false })
          onHistoryUpdate()
          message.success('Config group created successfully')
        }, error => {
          this.setState({ showAddConfigGroupModal: false, selectedConfigGroup: null, isDuplicate: false, isCreateLoading: false, isEdit: false })
          utilityService.handleError(error)
        })
      }
    }

    hideDeleteConfigGroupModal = () => {
      this.setState({ showDeleteConfigGroupModal: false })
    }

    handleDeleteConfigGroup = () => {
      const { selectedConfigGroup } = this.state
      const { onHistoryUpdate } = this.props
      this.setState({ isDeleteLoading: true })
      const variables = { id: selectedConfigGroup.id }
      this.props.deleteConfigGroupSchema(variables).then(() => {
        onHistoryUpdate()
        this.setState({ showDeleteConfigGroupModal: false, isDeleteLoading: false, selectedConfigGroup: null })
        message.success('Config group deleted successfully')
      }, error => {
        this.setState({ showDeleteConfigGroupModal: false, isDeleteLoading: false, selectedConfigGroup: null })
        utilityService.handleError(error)
      })
    }

    onSelectChange = selectedRowKeys => {
      this.setState({ selectedRowKeys })
    }

    onMultipleSelect = () => {
      this.setState({ isSelected: true })
    }

    onMultipleDSelect = () => {
      this.setState({ isSelected: false, selectedRowKeys: [] })
    }

    onMultipleDelete = () => {
      this.setState({ isMultipleDelete: true })
    }

    handleMultipleDeleteConfigGroup = () => {
      this.setState({ isMultipleDelete: false })
    }

    hideMultipleDeleteConfigGroup = () => {
      this.setState({ isMultipleDelete: false })
    }

    // loadMoreAsset = () => {
    //   if (this.props.totalCount === this.props.placeholderList.length || this.state.isPaginating) { return }
    //   this.setState({ isPaginating: true }, () => this.props.getMoreCategories(this.props.placeholderList.length))
    // }

    getRenderedSecondColumn = (data, isStream) => {
      if (isStream) {
        const columns = [
          {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '90%',
            render: (id) => <div className='config-stream-name'>{this.renderStream(id, data)}</div>
          }
        ]
        return (
          <Table
            className={`inner-table`}
            rowKey={record => record.id}
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}

          />
        )
      } else {
        const columns = [
          {
            title: 'Field Object',
            dataIndex: 'name',
            key: 'name',
            width: '20%'
          },

          {
            title: 'Field Type',
            dataIndex: 'type',
            key: 'type',
            width: '20%'
          },
          {
            title: 'Required',
            key: 'isRequired',
            dataIndex: 'isRequired',
            render: isRequired => (
              <span>
                {isRequired === true ? <DoneIcon /> : <CrossIcon color={'#f5212d'} />}
              </span>
            )
          }
        ]
        return (
          <Table
            className={`inner-table`}
            rowKey={record => record.id}
            columns={columns}
            dataSource={data}
            pagination={false}

          />
        )
      }
    }

    getRenderedColumn = data => {
      const columns = [
        {
          title: '',
          dataIndex: 'title',
          key: 'title',
          width: '90%'
        }
      ]
      const field = _.cloneDeep(data.fields)
      const stream = _.cloneDeep(data.streams)

      return <React.Fragment>
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={columns}
          dataSource={[{ title: 'Streams' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.getRenderedSecondColumn(stream, true)}
        />
        <Table
          className={`inner-table-container`}
          rowKey={record => record.title}
          columns={columns}
          dataSource={[{ title: 'Field Object' }]}
          pagination={false}
          showHeader={false}
          expandedRowRender={() => this.getRenderedSecondColumn(field, false)}
        />
      </React.Fragment>
    }

    renderStream = (id, data) => {
      let name = []
      let streams = data.find(item => item.id === id)
      if (streams && streams.levels && streams.levels.length) {
        for (let j = 0; j < streams.levels.length; j++) {
          name.push(streams.levels[j].name)
        }
      }
      const streamName = (name || []).map((dataItem, index) => {
        return <div key={index} className={`${index === 0 ? 'encoder-item-code' : (index === 1 ? 'encoder-item-status' : 'encoder-item-protocol')}`} > <span>{ dataItem }</span></div>
      })
      return streamName
    }

    renderData = (id, usedCount) => {
      let availableOptions = _.cloneDeep(options)
      const { openedConfigGroup } = this.state
      const randomId = utilityService.makeRandomString(6)
      if (!usedCount && !this.isDeleteDisabled) {
        const deleteOption = {
          type: 'delete',
          name: 'Delete'
        }
        availableOptions.push(deleteOption)
      }
      if (this.isCreateDisabled) {
        availableOptions.splice(1, 1)
      }
      if (this.isUpdateDisabled) {
        availableOptions.splice(0, 1)
      }
      if (availableOptions && availableOptions.length) {
        return (
          <div className='option-list' id={randomId}>
            <PopoverButton
              button={<div onClick={(e) => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
              displayParam='name'
              contents={availableOptions}
              onContentClick={(selectedOption) => this.onSelectOption(id, selectedOption)}
              parentCompoent={randomId}
              isVisible={openedConfigGroup === id}
              placement={'leftBottom'}
            />
          </div>
        )
      } else {
        return null
      }
    }

    render () {
      const { isPaginating, showAddConfigGroupModal, showDeleteConfigGroupModal, selectedConfigGroup, isMultipleDelete, selectedRowKeys,
        isSelected, isDuplicate, isLoading, isDeleteLoading, isCreateLoading, isEdit } = this.state
      const { configGroupList, channelStreamList } = this.props
      const columnData = this.getRenderedColumn
      // let adminItem
      // if (selectedConfigGroup && showDeleteConfigGroupModal) {
      //   adminItem = <div >
      //     <p>{ selectedConfigGroup.name } is being used {selectedConfigGroup.usedCount || '0'} times.</p>
      //   </div>
      // }

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: false
      }
      return <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['DATA_MANAGER']
          this.isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          this.isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          this.isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return (
            <div className='admin-portal channel-config-group'>
              {/* <FilterInput
            searchString={''}
            onChangeSearchInput={() => {}}
            // filterType={'AssetManager'}
            // changeFilter={(value) => {
            //   this.setState({ isSearching: true, filterData: value }, () => {
            //     this.changeFilterValue(value)
            //   })
            // }}
            placement='rightTop'
            // isClearFilter={isClearFilter}
            // initialSearch={initialSearch}
            // username={this.username}
            // bucketAssetFilter={filterData}
          /> */}
              <div className='admin-list'>
                {/* <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMoreAsset}
              hasMore={this.props.totalCount > placeholderList.length}
              initialLoad={false}
              useWindow={false}
            > */}
                <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                  <Table className={`general-table ${isPaginating ? 'paginating' : ''}`}
                    rowKey={record => record.id}
                    columns={this.columns}
                    dataSource={configGroupList}
                    pagination={false}
                    expandedRowRender={columnData}
                    rowSelection={isSelected ? rowSelection : undefined}
                  />
                </Skeleton>
                <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} />
                {/* </InfiniteScroll> */}
              </div>
              <div className='admin-footer' >
                <div className='left-button'>
                  {/* {!isSelected
                ? <MultipleSelectButton onClick={this.onMultipleSelect} />
                : <React.Fragment>
                  <BackArrowButton onClick={this.onMultipleDSelect} />
                  <DeleteButton onClick={this.onMultipleDelete} isDisabled={_.isEmpty(selectedRowKeys)} />
                </React.Fragment>
              } */}
                </div>
                {!isSelected ? <LoadingButton
                  type='primary'
                  onClick={this.showAddConfigGroup}
                  htmlType='submit'
                  buttonText={'New'}
                  buttonClass='save-btn'
                  isLoading={false}
                  isDisabled={this.isCreateDisabled}
                /> : null}
              </div>
              <CreateConfigGroupModal
                isVisible={showAddConfigGroupModal}
                handleCancel={this.hideAddConfigGroupModal}
                handleSubmit={this.handleAddConfigGroup}
                selectedConfigGroup={selectedConfigGroup}
                reorder={this.reorder}
                isDuplicate={isDuplicate}
                isLoading={isCreateLoading}
                channelStreamList={channelStreamList}
                isSubmitDisabled={isEdit ? this.isUpdateDisabled : this.isCreateDisabled}
              />
              <AdminItemConfirmModal
                isVisible={showDeleteConfigGroupModal}
                title={deleteConfigGroupMessage.title}
                firstMessage={deleteConfigGroupMessage.firstMessage}
                secondMessage={deleteConfigGroupMessage.secondMessage}
                // adminItem={adminItem}
                rightButtonText={'Delete'}
                handleCancel={this.hideDeleteConfigGroupModal}
                handleSubmit={this.handleDeleteConfigGroup}
                isSubmitButtonDisabled={this.isDeleteDisabled}
                isCancelButtonDisabled={false}
                isLoading={isDeleteLoading}
              />
              <ConfirmModal
                isVisible={isMultipleDelete}
                title={'DELETE MULTIPLE CONFIG GROUP'}
                message={userMessages.DELETE_MULTIPLE_CONFIG_GROUP}
                isLoading={false}
                rightButtonText={'Delete'}
                handleSubmit={this.handleMultipleDeleteConfigGroup}
                handleCancel={this.hideMultipleDeleteConfigGroup}
              />
            </div>
          )
        }}
      </AppContext.Consumer>
    }
}

ChannelConfigGroups.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryListConfigGroups,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let configGroupList = data.listConfigGroupSchemas ? [ ...data.listConfigGroupSchemas.items ] : []
        return {
          configGroupList,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    QueryListChannelStreams,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let channelStreamList = data.listStreamLevels ? [ ...data.listStreamLevels.items ] : []
        return {
          channelStreamList
        }
      }
    }
  ),
  graphql(
    MutationDeleteChannelConfig,
    {
      options: (props) => ({
        update: (cache, { data: { deleteConfigGroupSchema } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListConfigGroups, variables: { offset: 0 } }))
          if (cacheData && cacheData.listConfigGroupSchemas && cacheData.listConfigGroupSchemas.items) {
            const index = cacheData.listConfigGroupSchemas.items.findIndex(item => item.id === deleteConfigGroupSchema.id)
            cacheData.listConfigGroupSchemas.items.splice(index, 1)
          }
          cache.writeQuery({
            query: QueryListConfigGroups,
            data: cacheData,
            variables: { offset: 0 }
          })
        }
      }),
      props: (props) => ({
        deleteConfigGroupSchema: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateChannelConfig,
    {
      options: (props) => ({
        update: (cache, { data: { createConfigGroupSchema } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListConfigGroups, variables: { offset: 0 } }))
          if (cacheData && cacheData.listConfigGroupSchemas && cacheData.listConfigGroupSchemas.items) {
            cacheData.listConfigGroupSchemas.items.splice(0, 0, createConfigGroupSchema)
          }
          cache.writeQuery({
            query: QueryListConfigGroups,
            data: cacheData,
            variables: { offset: 0 }
          })
        }
      }),
      props: (props) => ({
        createConfigGroupSchema: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateChannelConfig,
    {
      options: (props) => ({
        update: (cache, { data: { updateConfigGroupSchema } }) => {
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListConfigGroups, variables: { offset: 0 } }))
          if (cacheData && cacheData.listConfigGroupSchemas && cacheData.listConfigGroupSchemas.items) {
            const index = cacheData.listConfigGroupSchemas.items.findIndex(item => item.id === updateConfigGroupSchema.id)
            cacheData.listConfigGroupSchemas.items[index] = updateConfigGroupSchema
          }
          cache.writeQuery({
            query: QueryListConfigGroups,
            data: cacheData,
            variables: { offset: 0 }
          })
        }
      }),
      props: (props) => ({
        updateConfigGroupSchema: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(ChannelConfigGroups))
