import gql from 'graphql-tag'

export default gql(`
  query getConfig ($id: ID!){
    getConfig(id: $id){
        id
        value
        key
    }
  }
`)
