import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, Table } from 'antd'

import _ from 'lodash'
import PopoverButton from '../general/buttons/PopoverButton'
import ExpandMenuIcon from '../general/icons/ExpandMenuIcon'
import CreateUserGroupModal from '../dataEntry/other/CreateUserGroupModal'
import GroupInput from '../dataEntry/inputs/GroupInput'
import ButtonContainer from '../general/buttons/ButtonContainer'
import { utilityService } from '../../../services/UtilityService'
import CustomerAccountIcon from '../general/icons/CustomerAccountIcon'

const tagsList = ['GET', 'CREATE', 'UPDATE', 'DELETE']
const matchTagsList = ['GET', 'CREATE', 'UPDATE', 'SYNC']
const JobTagsList = ['GET', 'CREATE']
const assetTagsList = ['GET', 'CREATE', 'UPDATE', 'DELETE', 'PUBLISH']
const ingestList = ['GET', 'CREATE', 'UPDATE', 'PUBLISH']
const appManagerList = ['CREATE_GROUP', 'UPDATE_GROUP', 'VIEW_CONFIG', 'CREATE_CONFIG', 'UPDATE_CONFIG', 'DELETE_GROUP', 'PUBLISH_CONFIG', 'ARCHIVE_CONFIG', 'VIEW_PAGE', 'CREATE_PAGE', 'UPDATE_PAGE', 'PUBLISH_PAGE', 'ARCHIVE_PAGE', 'APP_ADMIN', 'UPDATE_CONFIG_VALUES', 'CREATE_TAG', 'CMS_WRITE_CRITICAL']
const monitorManagerList = ['GET']
const drmManagerList = ['GET', 'UPDATE']

const options = [
  {
    type: 'editDetails',
    name: 'Edit Details'
  },
  {
    type: 'duplicate',
    name: 'Duplicate'
  }
]

class RoleUserGroupTable extends Component {
  constructor (props) {
    super(props)
    this.columns = [
      {
        title: 'User Groups',
        dataIndex: 'name',
        key: 'name',
        render: this.renderData
      },
      {
        title: 'Asset Manager',
        dataIndex: 'id',
        key: 'asset',
        render: (a, b) => this.renderPermissions(a, b, 'ASSET_MANAGER'),
        width: '10%'
      },
      {
        title: 'Content Bank',
        key: 'content',
        dataIndex: 'id',
        render: (a, b) => this.renderPermissions(a, b, 'CONTENT_BANK'),
        width: '10%'
      },
      {
        title: 'Channel Manager',
        dataIndex: 'id',
        key: 'channel',
        render: (a, b) => this.renderPermissions(a, b, 'CHANNEL_MANAGER'),
        width: '10%'
      },
      {
        title: 'Bucket Manager',
        dataIndex: 'id',
        key: 'bucket',
        render: (a, b) => this.renderPermissions(a, b, 'BUCKET_MANAGER'),
        width: '10%'
      },
      {
        title: 'Match Manager',
        key: 'match',
        dataIndex: 'id',
        render: (a, b) => this.renderPermissions(a, b, 'MATCH_MANAGER'),
        width: '10%'
      },
      {
        title: 'Role Manager',
        dataIndex: 'id',
        key: 'role',
        render: (a, b) => this.renderPermissions(a, b, 'ROLE_MANAGER'),
        width: '10%'
      },
      {
        title: 'Job Manager',
        key: 'job',
        dataIndex: 'id',
        render: (a, b) => this.renderPermissions(a, b, 'JOB_MANAGER'),
        width: '10%'
      },
      {
        title: 'Admin Manager',
        dataIndex: 'id',
        key: 'admin',
        render: (a, b) => this.renderPermissions(a, b, 'DATA_MANAGER'),
        width: '10%'
      },
      {
        title: 'Customer Manager',
        dataIndex: 'id',
        key: 'customer',
        render: (a, b) => this.renderPermissions(a, b, 'CUSTOMER_MANAGER'),
        width: '10%'
      },
      {
        title: 'App Manager',
        dataIndex: 'id',
        key: 'app',
        render: (a, b) => this.renderPermissions(a, b, 'APP_MANAGER'),
        width: '10%'
      },
      {
        title: 'Monitor Manager',
        dataIndex: 'id',
        key: 'monitor',
        render: (a, b) => this.renderPermissions(a, b, 'MONITOR_MANAGER'),
        width: '10%'
      },
      {
        title: 'DRM Manager',
        dataIndex: 'id',
        key: 'drm',
        render: (a, b) => this.renderPermissions(a, b, 'DRM_MANAGER'),
        width: '10%'
      }
    ]
    this.hypeColumns = [
      {
        title: 'User Groups',
        dataIndex: 'name',
        key: 'name',
        render: this.renderData
      },
      {
        title: 'Asset Manager',
        dataIndex: 'id',
        key: 'asset',
        render: (a, b) => this.renderPermissions(a, b, 'ASSET_MANAGER'),
        width: '10%'
      },
      {
        title: 'Content Bank',
        key: 'content',
        dataIndex: 'id',
        render: (a, b) => this.renderPermissions(a, b, 'CONTENT_BANK'),
        width: '10%'
      },
      {
        title: 'Bucket Manager',
        dataIndex: 'id',
        key: 'bucket',
        render: (a, b) => this.renderPermissions(a, b, 'BUCKET_MANAGER'),
        width: '10%'
      },
      {
        title: 'Role Manager',
        dataIndex: 'id',
        key: 'role',
        render: (a, b) => this.renderPermissions(a, b, 'ROLE_MANAGER'),
        width: '10%'
      },
      {
        title: 'Admin Manager',
        dataIndex: 'id',
        key: 'admin',
        render: (a, b) => this.renderPermissions(a, b, 'DATA_MANAGER'),
        width: '10%'
      },
      {
        title: 'Customer Manager',
        dataIndex: 'id',
        key: 'customer',
        render: (a, b) => this.renderPermissions(a, b, 'CUSTOMER_MANAGER'),
        width: '10%'
      },
      {
        title: 'Partner Manager',
        dataIndex: 'id',
        key: 'partner',
        render: (a, b) => this.renderPermissions(a, b, 'PARTNER_MANAGER'),
        width: '10%'
      },
      {
        title: 'Offer Manager',
        dataIndex: 'id',
        key: 'offer',
        render: (a, b) => this.renderPermissions(a, b, 'OFFER_MANAGER'),
        width: '10%'
      },
      {
        title: 'Ingest Manager',
        dataIndex: 'id',
        key: 'ingest',
        render: (a, b) => this.renderPermissions(a, b, 'INGEST_MANAGER'),
        width: '10%'
      },
      {
        title: 'App Manager',
        dataIndex: 'id',
        key: 'app',
        render: (a, b) => this.renderPermissions(a, b, 'APP_MANAGER'),
        width: '10%'
      },
      {
        title: 'Monitor Manager',
        dataIndex: 'id',
        key: 'monitor',
        render: (a, b) => this.renderPermissions(a, b, 'MONITOR_MANAGER'),
        width: '10%'
      }
    ]

    this.subhubColumns = [
      {
        title: 'User Groups',
        dataIndex: 'name',
        key: 'name',
        render: this.renderData
      },
      {
        title: 'Asset Manager',
        dataIndex: 'id',
        key: 'asset',
        render: (a, b) => this.renderPermissions(a, b, 'ASSET_MANAGER'),
        width: '10%'
      },
      {
        title: 'Content Bank',
        key: 'content',
        dataIndex: 'id',
        render: (a, b) => this.renderPermissions(a, b, 'CONTENT_BANK'),
        width: '10%'
      },
      {
        title: 'Bucket Manager',
        dataIndex: 'id',
        key: 'bucket',
        render: (a, b) => this.renderPermissions(a, b, 'BUCKET_MANAGER'),
        width: '10%'
      },
      {
        title: 'Role Manager',
        dataIndex: 'id',
        key: 'role',
        render: (a, b) => this.renderPermissions(a, b, 'ROLE_MANAGER'),
        width: '10%'
      },
      {
        title: 'Admin Manager',
        dataIndex: 'id',
        key: 'admin',
        render: (a, b) => this.renderPermissions(a, b, 'DATA_MANAGER'),
        width: '10%'
      },
      {
        title: 'Customer Manager',
        dataIndex: 'id',
        key: 'customer',
        render: (a, b) => this.renderPermissions(a, b, 'CUSTOMER_MANAGER'),
        width: '10%'
      },
      {
        title: 'Partner Manager',
        dataIndex: 'id',
        key: 'partner',
        render: (a, b) => this.renderPermissions(a, b, 'PARTNER_MANAGER'),
        width: '10%'
      },
      {
        title: 'Offer Manager',
        dataIndex: 'id',
        key: 'offer',
        render: (a, b) => this.renderPermissions(a, b, 'OFFER_MANAGER'),
        width: '10%'
      },
      {
        title: 'App Manager',
        dataIndex: 'id',
        key: 'app',
        render: (a, b) => this.renderPermissions(a, b, 'APP_MANAGER'),
        width: '10%'
      }
    ]

    this.state = {
      showOptions: false,
      toEditUserGroup: false,
      toDuplicateUserGroup: false,
      groupList: _.cloneDeep(props.groupList),
      userId: '',
      isModalLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.groupList && (!_.isEqual(newProps.groupList, this.props.groupList) || newProps.isCancelClicked)) {
      this.setState({ groupList: _.cloneDeep(newProps.groupList) })
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (!(this.tableContainer && this.tableContainer.contains(event.target))) {
      this.setState({ openedOption: '' })
    }
  }

  toggleShowOptions = (group) => {
    this.setState({ openedOption: group, userId: group })
  }

  onSelectOption = (selectedOption) => {
    if (selectedOption.type === 'editDetails') {
      this.setState({ toEditUserGroup: true })
      this.props.onUserGroupDelete(this.state.userId, true)
    } else if (selectedOption.type === 'duplicate') {
      this.setState({ toDuplicateUserGroup: true })
    } else if (selectedOption.type === 'delete') {
      this.props.onUserGroupDelete(this.state.userId)
    }
    this.setState({ openedOption: undefined })
  }

  hideEditUserModal = () => {
    this.setState({ toEditUserGroup: false, toDuplicateUserGroup: false })
  }

  onChangePermissions = (groupId, value, isDelete, moduleName) => {
    const { groupList } = this.state
    const selectedRowIndex = groupList.findIndex(item => item.id === groupId)
    if (selectedRowIndex > -1) {
      const selectedRow = groupList[selectedRowIndex]
      const assetIndex = (selectedRow.modules || []).findIndex(item => item.name === moduleName)
      if (isDelete && assetIndex > -1) {
        const index = selectedRow.modules[assetIndex].permissions.findIndex(item => item === value)
        if (index > -1) {
          groupList[selectedRowIndex].modules[assetIndex].permissions.splice(index, 1)
        }
      } else {
        if (assetIndex > -1) {
          if (groupList[selectedRowIndex].modules[assetIndex].permissions) { groupList[selectedRowIndex].modules[assetIndex].permissions.push(value) } else { groupList[selectedRowIndex].modules[assetIndex].permissions = [value] }
        } else {
          const newPermission = {
            name: moduleName,
            permissions: [value]
          }
          groupList[selectedRowIndex].modules.push(newPermission)
        }
      }
      this.props.onChangePermissions(groupList)
      this.setState({ groupList })
    }
  }

  onChangeUserGroups = (userGroupTitle, description, selectedUsers, toEditUserGroup, modules) => {
    this.setState({ isModalLoading: true }, () => {
      this.props.onChangeUserGroups(userGroupTitle, description, selectedUsers, toEditUserGroup, modules)
      setTimeout(() => {
        this.setState({ isModalLoading: false, toEditUserGroup: false, toDuplicateUserGroup: false })
      }, 2000)
    })
  }

  renderPermissions = (id, rowData, moduleName) => {
    const { isEditGroup } = this.props
    const parentId = utilityService.makeRandomString(5)
    const modulePermissions = (rowData.modules || []).find(item => item.name === moduleName)
    const tagOptions = moduleName === 'ASSET_MANAGER' ? [...assetTagsList] : moduleName === 'JOB_MANAGER' ? [...JobTagsList] : moduleName === 'MATCH_MANAGER'
      ? [...matchTagsList] : moduleName === 'INGEST_MANAGER' ? [...ingestList] : moduleName === 'APP_MANAGER' ? [...appManagerList] : moduleName === 'MONITOR_MANAGER' ? [...monitorManagerList] : moduleName === 'DRM_MANAGER' ? [...drmManagerList] : [...tagsList]
    return (
      <div id={parentId}>
        <GroupInput
          selectedItems={modulePermissions ? (modulePermissions.permissions || []).filter(item => item) : undefined}
          isEditGroup={isEditGroup}
          onChange={(value, isDelete) => this.onChangePermissions(id, value, isDelete, moduleName)}
          placeholder=''
          parentCompoent={parentId}
          displayParameter='value'
          keyParameter='tag'
          onSearch={() => { }}
          tagList={tagOptions.map(item => ({ tag: item === 'CREATE_TAG' ? 'CREATE' : item, value: item === 'GET' ? 'VIEW' : item }))}
        />
      </div>
    )
  }

  renderData = (group, { id, users }) => {
    const { openedOption } = this.state
    const { onUserGroupClick } = this.props
    const refId = utilityService.makeRandomString(5)
    const deleteOption = { type: 'delete', name: 'Delete' }
    const userGroupOptions = users && users.length ? options : [...options, deleteOption]
    return (
      <div className='group-name' id={refId}>
        <ButtonContainer childComponent={<span >{group}</span>} parentCompoent={refId} displayTitle={group} placement='topLeft' />
        <div onClick={() => onUserGroupClick(group, id)} className='link-button' ><ButtonContainer childComponent={
          <CustomerAccountIcon />} parentCompoent={refId} displayTitle={'Show users'} placement='top' />
        </div>
        <PopoverButton
          button={<div onClick={() => this.toggleShowOptions(id)} > <ExpandMenuIcon /> </div>}
          displayParam='name'
          contents={userGroupOptions}
          onContentClick={this.onSelectOption}
          parentCompoent={refId}
          isVisible={openedOption === id}
        />
      </div>
    )
  }

  renderUser = (users) => {
    // const usersArray
    const usersId = users ? users.map(item => item.id) : []
    return (
      <div>
        <Select
          defaultValue={usersId}
          style={{ width: 120 }}
          className={`group-input-tags disabled`}
          dropdownClassName={'group-drop-down'}
          onChange={this.handleChange}
          mode='multiple'
          disabled
        />
      </div>
    )
  }

  render () {
    const { toEditUserGroup, toDuplicateUserGroup, groupList, userId, isModalLoading } = this.state
    const { userList, onSearchUser, isCreateDisabled, isUpdateDisabled, project } = this.props
    const selectedGroup = groupList.find(item => item.id === userId)
    return (
      <div className='role-user-table role-user-group-table' ref={node => { this.tableContainer = node }}>
        <Table columns={project === 'hyperion' ? this.hypeColumns
          : project === 'projectx' ? this.subhubColumns : this.columns} dataSource={groupList} pagination={false} bordered rowKey={content => content.id} />
        <CreateUserGroupModal
          visible={toEditUserGroup || toDuplicateUserGroup}
          handleSubmit={(userGroupTitle, description, selectedUsers) => this.onChangeUserGroups(userGroupTitle, description, selectedUsers, toEditUserGroup, selectedGroup.modules)}
          handleCancel={this.hideEditUserModal}
          title={toEditUserGroup ? 'EDIT USER GROUP DETAILS' : 'DUPLICATE USER GROUP'}
          okText={toEditUserGroup ? 'Save' : 'Duplicate'}
          userList={userList}
          userGroupTitle={_.isEmpty(selectedGroup) ? '' : selectedGroup.name}
          description={_.isEmpty(selectedGroup) ? '' : selectedGroup.description}
          selectedUsers={_.isEmpty(selectedGroup) ? '' : (selectedGroup.users || []).map(item => item.id)}
          loading={isModalLoading}
          onSearchUser={onSearchUser}
          isSubmitDisabled={toEditUserGroup ? isUpdateDisabled : isCreateDisabled} />
      </div>
    )
  }
}

RoleUserGroupTable.propTypes = {
  /** List of users */
  userList: PropTypes.array,
  /** Function to be called on change of permissions */
  onChangePermissions: PropTypes.func,
  /** Boolean for checking cancel clicked or not */
  isCancelClicked: PropTypes.bool,
  /** Function to be called on delete user group */
  onUserGroupDelete: PropTypes.func,
  /** Function to be called on submit modal */
  onChangeUserGroups: PropTypes.func,
  /** Boolean to disable update */
  isUpdateDisabled: PropTypes.bool,
  /** Boolean to disable update */
  isCreateDisabled: PropTypes.bool
}

export default RoleUserGroupTable
