import gql from 'graphql-tag'

export default gql(`
query($id: ID!) {
    getSeason(id: $id) {
        id
        externalId
        ocId
        title
        startDate
        endDate
        active
        lastUpdated
        includesStandings
        includesVenues
        customTitle
        customStartDate
        customEndDate
        customActive
        tags{
            name
            key
            type
        } 
        competition{
            id
            title
            opId
        } 
    }    
}`)
