import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID!, $channelId: ID) {
        deleteChannelConfigGroup(input: { id: $id }, channelId: $channelId )
        {
            id
        }
    }`
)
