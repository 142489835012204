import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import { Radio } from 'antd'

class TabPanel extends React.Component {
  render () {
    const { selectedMode, onModeChange } = this.props
    return (
      <Radio.Group className='tab-panel' onChange={this.props.handleModeChange} value={selectedMode} >
        {this.props.tabs.map((item, index) => (
          <Radio.Button key={index} className='tab-panel-data' value={item.mode} style={{ width: `${100 / this.props.tabs.length}%` }} >
            <div className='tab-data' onClick={onModeChange ? () => onModeChange(item.mode) : () => {}}>
              <p>{item.displayName}</p>
              {item.icon}
            </div>
          </Radio.Button>
        ))}
      </Radio.Group>
    )
  }
}
TabPanel.propTypes = {
  /** mode change of TabPanel. */
  handleModeChange: PropTypes.func,
  /** selected mode of TabPanel. */
  selectedMode: PropTypes.string,
  /** tab pane items  of TabPanel. */
  item: PropTypes.string
}

TabPanel.defaultProps = {
}

export default TabPanel
