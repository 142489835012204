import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'

import AppContext from '../../AppContext'

import BucketDetailCell from '../../components/ui/dataDisplay/BucketDetailCell'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import LoadingButton from '../../components/ui/general/buttons/LoadingButton'
// import DuplicateButton from '../../components/ui/general/buttons/DuplicateButton'
import ArchiveButton from '../../components/ui/general/buttons/ArchiveButton'
import EditButton from '../../components/ui/general/buttons/EditButton'
import DragAndDropIcon from '../../components/ui/general/icons/DragAndDropIcon'
import SwapIcon from '../../components/ui/general/icons/SwapIcon'
import AddButton from '../../components/ui/general/buttons/AddButton'
import DropDown from '../../components/ui/dataEntry/inputs/DropDown'
import userMessages from './../../constants/messages'
import AddBucketGroupModal from '../../components/ui/dataEntry/other/AddBucketGroupModal'
import ConfirmModal from '../../components/ui/feedback/ConfirmModal'
import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetBucketGroupDetails from '../../graphQL/bucket/getBucketGroupDetails'
import SubscriptionUpdateBucketGroupList from '../../graphQL/bucket/updateBucketGroupSubscription'
import QueryGetBucketGroupList from './../../graphQL/bucket/getBucketGroupList'
import QueryGetBucketGroupType from './../../graphQL/admin/bucket/listBucketGroupType'
import MutationUpdateBucketGroup from './../../graphQL/bucket/updateBucketGroup'
import MutationCreateBucketGroupItem from './../../graphQL/bucket/createBucketGroupItem'
// import SubscriptionSaveAllBucket from './../../graphQL/bucket/saveAllBucketsSubscription'
import SubscriptionSaveAllBucket from './../../graphQL/bucket/updateBucketIdSubscription'
import MutationCreateBucketGroup from './../../graphQL/bucket/createBucketGroup'
import QueryFilterAssets from './../../graphQL/asset/searchAssets'

const deleteWarningMessage = {
  message: userMessages.BUCKET_GROUP_DELETE_CONFIRMATION,
  title: 'ARCHIVE ?'
}

let updatedBucketList = []

const subscribeChangeInBucket = (subscribeToMore, retrying = 50) => {
  return subscribeToMore({
    document: SubscriptionSaveAllBucket,
    updateQuery: (previous, { subscriptionData: { data: { bucketUpdatedNotifier } } }) => {
      const prev = cloneDeep(previous)
      // if (!bucketSaved || prev.getBucket.id !== bucketSaved.id) return prev
      if (prev.getBucketGroup && prev.getBucketGroup.bucketGroupItems && prev.getBucketGroup.bucketGroupItems.length) {
        const index = prev.getBucketGroup.bucketGroupItems.findIndex(item => item.bucketId === bucketUpdatedNotifier.id)
        if (index > -1) {
          prev.getBucketGroup.bucketGroupItems[index].update = !prev.getBucketGroup.bucketGroupItems[index].update
        }
      }
      updatedBucketList.push(bucketUpdatedNotifier.id)
      return prev
    },
    onError: (error) => {
      if ((error.errorMessage || '').includes('Socket')) {
        setTimeout(() => { subscribeChangeInBucket(subscribeToMore, retrying * 2) }, retrying)
      }
    }
  })
}

class BucketGroupDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bucketGroupDetails: props.bucketGroupDetails,
      toAddBucketGroup: false,
      toEditBucketGroup: false,
      bucketGroup: [],
      loading: false,
      shouldShowWarning: false,
      isDeleteLoading: false,
      showDiscardWarning: false,
      isSavingBucket: false,
      selectedId: '',
      isConfirmClicked: false,
      bucketDetails: null,
      offset: 5,
      saveAllArray: [], // saveALL: to add bucketid on change and remove once all save is completed
      toBeSaved: [], // saveALL: to add bucketid on change and remove once save is started
      addSearchStatus: true // saveAll: To Fix fast finger save on onAddSearch in bucket
    }
    if (props.defaultBucketGroup) {
      props.onBucketGroupChange(props.defaultBucketGroup)
    } else if (!props.defaultBucketGroup && props.bucketGroupList && props.bucketGroupList.length) {
      props.onBucketGroupChange(props.bucketGroupList[0].id)
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.showWarning) { this.setState({ showDiscardWarning: true }) }
    if (newProps.bucketGroupDetails && !_.isEqual(newProps.bucketGroupDetails, this.props.bucketGroupDetails)) {
      this.props.appliedBuckets((newProps.bucketGroupDetails && newProps.bucketGroupDetails.bucketGroupItems && newProps.bucketGroupDetails.bucketGroupItems.length
        ? newProps.bucketGroupDetails.bucketGroupItems : []).map(item => item.bucketId))
      let bucketGroupDetails = _.cloneDeep(newProps.bucketGroupDetails)
      let bucketGroupItems = (bucketGroupDetails.bucketGroupItems || []).filter((item) => item.bucket && !item.bucket.isArchived)
      bucketGroupDetails.bucketGroupItems = bucketGroupItems
      const bucketDetails = bucketGroupDetails.bucketDetails ? bucketGroupDetails.bucketDetails : null
      this.setState({ bucketGroupDetails, bucketDetails })
    }
    if (newProps.bucketId && newProps.bucketId !== this.props.bucketId) {
      if (newProps.bucketGroupId !== this.props.bucketGroupId) {
        this.setState({ offset: 5 })
        setTimeout(() => this.scrollToBucket(newProps.bucketId), 2000)
      } else {
        this.scrollToBucket(newProps.bucketId)
      }
    } else if (newProps.bucketId && newProps.bucketId === this.props.bucketId) {
      if (newProps.bucketGroupId !== this.props.bucketGroupId) {
        setTimeout(() => this.scrollToBucket(newProps.bucketId), 1000)
      }
    }
    if (newProps.bucketGroupList && newProps.bucketGroupList.length && !this.props.bucketGroupId) {
      if (newProps.defaultBucketGroup) {
        this.onBuckeGroupSelect(newProps.defaultBucketGroup)
      } else {
        this.onBuckeGroupSelect(newProps.bucketGroupList[0].id)
      }
    }
    if (newProps.newBucket && newProps.newBucket !== this.props.newBucket) {
      this.addNewBucket(newProps.newBucket)
    }
  }

  componentDidMount () {
    this.initiateSubscription()
  }

  initiateSubscription = () => {
    if (this.props.subscribeToChangeBucketGroup) {
      this.props.subscribeToChangeBucketGroup()
      this.props.subscribeToChangeBucket()
    } else {
      setTimeout(() => {
        this.initiateSubscription()
      }, 1500)
    }
  }

  addNewBucket = id => {
    const { bucketGroupDetails } = this.state
    let position = 0
    const lastBucket = bucketGroupDetails.bucketGroupItems && bucketGroupDetails.bucketGroupItems.length ? bucketGroupDetails.bucketGroupItems.reduce((prev, current) => (prev.position > current.position) ? prev : current) : undefined
    if (lastBucket) {
      position = lastBucket.position + 1
    }
    const request = {
      bucketId: id,
      groupId: this.props.bucketGroupId,
      position
    }
    const tempId = parseInt(Math.random() * 1000)
    const newBucket = {
      ...request,
      id: tempId
    }
    bucketGroupDetails.bucketGroupItems && bucketGroupDetails.bucketGroupItems.length ? bucketGroupDetails.bucketGroupItems.push(newBucket) : bucketGroupDetails.bucketGroupItems = [newBucket]

    this.props.createNewBucketItem(request).then(({ data }) => {
      this.props.updateHistory()
      const replacingBucketIndex = bucketGroupDetails.bucketGroupItems.findIndex(item => item.id === tempId)
      if (replacingBucketIndex < 0) {
        return
      }
      bucketGroupDetails.bucketGroupItems.splice(replacingBucketIndex, 1, data.createBucketGroupItem)
      const offsetLength = (bucketGroupDetails.bucketGroupItems || []).length
      this.setState({ bucketGroupDetails, offset: offsetLength + 1 }, () => {
        const filteredArray = bucketGroupDetails.bucketGroupItems.filter(item => item.id && item.id < 1000)
        if (!filteredArray.length) {
          this.updateBucketGroup()
        }
        this.dragElement.scrollIntoView({ behavior: 'smooth' })
        message.success(userMessages.SUCCESS_ADD_BUCKET_IN_BUCKET_GROUP)
      })
    }, error => {
      utilityService.handleError(error)
    })
  }

  scrollToBucket = bucketId => {
    const { bucketGroupDetails, offset } = this.state
    let bucketList = cloneDeep(bucketGroupDetails && bucketGroupDetails.bucketGroupItems ? bucketGroupDetails.bucketGroupItems : []).sort((a, b) => a.position - b.position)
    const index = (bucketList || []).findIndex(item => item.bucketId === bucketId)
    this.setState({ offset: offset >= index + 1 ? offset : index + 1 }, () => {
      const bucketItemElement = document.getElementById(`bucket-detail-cell-${bucketId}`)
      const container = document.getElementById('bucket-group-details-container')
      if (bucketItemElement) {
        const scrollWidth = bucketItemElement.offsetLeft - 20
        container.scrollLeft = scrollWidth
        // bucketItemElement.scrollIntoView({ 'block': 'center', behavior: 'smooth' })
        // bucketItemElement.classList.add("blink")
        // setTimeout( () => bucketItemElement.classList.remove("blink"), 6000 )
      }
    })
  }

  handleAddGroup = (title, key, type) => {
    if (this.state.loading) {
      return
    }
    this.setState({ loading: true })
    const { toAddBucketGroup, toEditBucketGroup, bucketGroupDetails } = this.state
    const group = { name: title, key: key, type: type }
    if (toAddBucketGroup) {
      this.props.createNewBucketGroup(group).then(response => {
        const newGroup = response.data.createBucketGroup
        this.props.onBucketGroupChange(newGroup.id)
        message.success(userMessages.SUCCESS_ADD_BUCKET_GROUP)
        this.setState({ bucketGroup: newGroup, toAddBucketGroup: false, loading: false })
        this.props.updateHistory()
      }, (error) => {
        const { graphQLErrors } = error
        if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized') {
          message.warning('User does not have permission to edit')
        } else if (!!graphQLErrors.length && graphQLErrors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
          message.error('Group ID already in use')
        } else {
          message.warning(error.message.replace('Error: ', '').replace('GraphQL error: ', ''))
        }
        this.setState({ toAddBucketGroup: false, loading: false })
      })
    } else if (toEditBucketGroup) {
      const id = bucketGroupDetails.id
      group.id = id
      this.props.updateBucketGroupDetails(group).then(response => {
        this.props.updateHistory()
        this.setState({ loading: true })
        const newGroup = response.data.updateBucketGroup
        this.setState({ bucketGroup: newGroup, loading: false, toEditBucketGroup: false })
      }, error => {
        this.setState({ toAddBucketGroup: false, loading: false })
        utilityService.handleError(error)
      })
    }
  }

  showAddBucketGroupModal = () => {
    this.setState({ toAddBucketGroup: true })
  }

  hideAddBucketGroupModal = () => {
    this.setState({ toAddBucketGroup: false, toEditBucketGroup: false })
  }

  showEditBucketGroupModal = () => {
    this.setState({ toEditBucketGroup: true })
  }

  onBuckeGroupSelect = value => {
    const isUnsaved = this.checkForUnsavedBucket()
    if (isUnsaved) {
      this.setState({ showDiscardWarning: true, selectedId: value })
    } else {
      this.props.onBucketGroupChange(value, true)
    }
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  archiveBucketGroup = () => {
    if (this.state.isDeleteLoading) {
      return
    }
    this.setState({ isDeleteLoading: true })
    this.props.archiveBucketGroup(this.props.bucketGroupDetails.id).then(response => {
      this.props.onBucketGroupChange(this.props.bucketGroupList[0].id)
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
      this.props.updateHistory()
    }, error => {
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  deleteBucketGroupItem = id => {
    const { bucketGroupDetails } = _.cloneDeep(this.state)
    const { bucketGroupItems } = bucketGroupDetails
    const deletedIndex = bucketGroupItems.findIndex(item => item.id === id)
    if (deletedIndex < 0) {
      return
    }
    bucketGroupItems.splice(deletedIndex, 1)
    const request = bucketGroupItems.map((item, index) => {
      return { id: item.id, position: index }
    })
    this.props.updateBucketGroup(request, this.props.bucketGroupId).then(data => {
      this.props.updateHistory()
      bucketGroupDetails.bucketGroupItems = bucketGroupItems
      this.setState({ bucketGroupDetails })
      this.props.appliedBuckets((this.state.bucketGroupDetails.bucketGroupItems || []).map(item => item.bucketId))
      message.success(userMessages.SUCCESS_DELETE_BUCKET_FROM_BUCKET_GROUP)
    }, error => {
      utilityService.handleError(error)
    })
  }

  reArrangeBucket = (index) => {
    const { bucketGroupDetails } = this.state
    let { bucketGroupItems } = bucketGroupDetails
    const temp = bucketGroupItems[index].position
    bucketGroupItems[index].position = bucketGroupItems[index + 1].position
    bucketGroupItems[index + 1].position = temp
    bucketGroupDetails.bucketGroupItems = bucketGroupItems
    this.setState({ bucketGroupDetails }, () => this.updateBucketGroup())
  }

  updateBucketGroup = (shouldShowMessage) => {
    const { bucketGroupDetails } = this.state
    const request = bucketGroupDetails.bucketGroupItems.map((item, index) => {
      return { id: item.id, position: index }
    })
    this.props.updateBucketGroup(request, this.props.bucketGroupId).then(data => {
      this.props.updateHistory()
      if (shouldShowMessage) {
        message.success(userMessages.SUCCESS_UPDATE_BUCKET_GROUP)
      }
    }, error => {
      utilityService.handleError(error)
    })
  }

  checkForUnsavedBucket = () => {
    let { bucketGroupDetails } = this.state
    let bucketList = bucketGroupDetails && bucketGroupDetails.bucketGroupItems ? bucketGroupDetails.bucketGroupItems : []
    let unsavedBuckets = bucketList.filter((item) => item.isChanged)
    return !!(unsavedBuckets && unsavedBuckets.length)
  }

  forceSave = () => {
    this.setState({ isSavingBucket: true })
  }

  discardChanges = () => {
    const { selectedId } = this.state
    this.setState({ showDiscardWarning: false, saveAllArray: [], toBeSaved: [] })
    this.props.onBucketGroupChange(selectedId, undefined, undefined, true)
  }

  onCloseConfirmModal = () => {
    this.setState({ showDiscardWarning: false })
    this.props.notifyParent()
  }

  changeBucket = (id, isChanged) => {
    let { bucketGroupDetails, isConfirmClicked, showDiscardWarning } = this.state
    let bucketList = bucketGroupDetails && bucketGroupDetails.bucketGroupItems ? bucketGroupDetails.bucketGroupItems : []
    let index = bucketList.findIndex((item) => {
      if (item.bucketId === id) {
        if (isChanged && !this.state.saveAllArray.includes(id)) {
          this.setState({
            saveAllArray: [...this.state.saveAllArray, id],
            toBeSaved: [...this.state.saveAllArray, id]
          })
        } else if (!isChanged && this.state.saveAllArray.includes(id)) {
          const index = this.state.saveAllArray.indexOf(id)
          this.state.saveAllArray.splice(index, 1)
          isConfirmClicked = _.isEmpty(this.state.saveAllArray) ? false : isConfirmClicked
          showDiscardWarning = _.isEmpty(this.state.saveAllArray) ? false : showDiscardWarning
          this.setState({
            saveAllArray: this.state.saveAllArray,
            isConfirmClicked: isConfirmClicked, // remove confirm loading
            showDiscardWarning
          })
        }
      }
      return item.bucketId === id
    })
    if (index !== -1) {
      bucketList[index].isChanged = isChanged
      bucketGroupDetails.bucketGroupItems = bucketList
      this.setState({ bucketGroupDetails })
      this.props.changeBucket(id, isChanged)
    }
  }

  forceSaveCompleted = (id) => {
    const { toBeSaved, saveAllArray } = this.state
    if (toBeSaved.includes(id)) {
      const index = toBeSaved.indexOf(id)
      toBeSaved.splice(index, 1)
    }
    if (saveAllArray.includes(id)) {
      const index = saveAllArray.indexOf(id)
      saveAllArray.splice(index, 1)
    }
    let { bucketGroupDetails } = this.state
    let bucketList = bucketGroupDetails && bucketGroupDetails.bucketGroupItems ? bucketGroupDetails.bucketGroupItems : []
    let index = bucketList.findIndex((item) => item.bucketId === id)
    bucketList[index].isChanged = false
    let unsavedBuckets = bucketList.filter((item) => item.isChanged)
    bucketGroupDetails.bucketGroupItems = bucketList
    if (unsavedBuckets && unsavedBuckets.length) {
      this.setState({ bucketGroupDetails, toBeSaved, saveAllArray })
    } else {
      const { selectedId } = this.state
      this.setState({ showDiscardWarning: false, isSavingBucket: false, bucketGroupDetails, toBeSaved, saveAllArray })
      this.props.onBucketGroupChange(selectedId, undefined, undefined, true)
    }
  }

  toggleSaveAllPopup = (bool) => {
    if (bool && !_.isEmpty(this.state.saveAllArray)) {
      this.setState({ isConfirmClicked: true })
    }
  }

  onSave = (id) => {
    if (this.state.toBeSaved.includes(id)) {
      const index = this.state.toBeSaved.indexOf(id)
      this.state.toBeSaved.splice(index, 1)
      this.setState({
        toBeSaved: this.state.toBeSaved
      })
    }
  }

  completedSubscription = (id) => {
    const index = updatedBucketList.findIndex(item => item === id)
    updatedBucketList.splice(index, 1)
    this.forceUpdate()
  }

  loadMoreBucket = (event) => {
    const { offset, bucketGroupDetails } = this.state
    const totalCount = bucketGroupDetails && bucketGroupDetails.bucketGroupItems ? bucketGroupDetails.bucketGroupItems.length : 0
    const container = document.getElementById('bucket-group-details-container')
    const width = container.scrollWidth
    const scrollLeft = container.scrollLeft
    if (offset >= totalCount || (width) >= (scrollLeft + container.offsetWidth + 300)) { return }

    const newOffset = totalCount > offset + 5 ? offset + 5 : totalCount
    this.setState({ offset: newOffset })
  }

  // To Fix fast finger save on onAddSearch in bucket
  changeAddSearchStatusStatus = (status) => {
    this.setState({
      addSearchStatus: status
    })
  }

  render () {
    const { bucketGroupDetails, toAddBucketGroup, toEditBucketGroup, loading, shouldShowWarning, isDeleteLoading, showDiscardWarning, isSavingBucket, toBeSaved, isConfirmClicked, saveAllArray, bucketDetails, offset, addSearchStatus } = this.state
    const { bucketGroupList, bucketGroupId, sourceIndex, destinationIndex, droppableId, draggableId, clearDragDropId, bucketId, updateHistory, displayTypes, bucketGroupTypes, draggingTaskId, defaultBucketGroup, changeLoadingBucket } = this.props
    let bucketList = (bucketGroupDetails && bucketGroupDetails.bucketGroupItems ? bucketGroupDetails.bucketGroupItems : []).sort((a, b) => a.position - b.position)
    bucketList = bucketList.slice(0, offset)
    if (draggableId !== this.lastDraggable) {
      this.lastDraggable = draggableId
      this.randomKey = utilityService.makeRandomString(6)
    }
    let isDefaultGroup = null
    if (defaultBucketGroup && (bucketGroupId === defaultBucketGroup)) {
      isDefaultGroup = true
    } else if (defaultBucketGroup && (bucketGroupId !== defaultBucketGroup)) {
      isDefaultGroup = false
    }

    // To Fix fast finger save on onAddSearch in bucket
    let saveAllButtonStatus = true
    if (!_.isEmpty(saveAllArray) && addSearchStatus) {
      saveAllButtonStatus = false
    }

    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['BUCKET_MANAGER']
          const isCreateBlocked = userPermissions.indexOf('CREATE') === -1
          const isUpdateBlocked = userPermissions.indexOf('UPDATE') === -1
          const isDeleteBlocked = userPermissions.indexOf('DELETE') === -1
          const userPermissionsContent = permissions['CONTENT_BANK']
          const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
          const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
          return <React.Fragment>
            <div className='bucket-details'>
              <div className='detail-top'>
                <p>Groups: </p>
                <DropDown options={bucketGroupList} selectedValue={bucketGroupId} valueParam='id' displayParam='name' onOptionSelect={this.onBuckeGroupSelect} />
                <ButtonContainer displayTitle='Create Bucket Group' childComponent={<AddButton onClick={this.showAddBucketGroupModal} isDisabled={isCreateBlocked} />} />
                <AddBucketGroupModal visible={toAddBucketGroup || toEditBucketGroup} handleSubmit={this.handleAddGroup} handleCancel={this.hideAddBucketGroupModal} loading={loading} title={toAddBucketGroup ? 'ADD BUCKET GROUP' : 'EDIT BUCKET GROUP'}
                  bucketGroupDetails={toEditBucketGroup ? bucketGroupDetails : null} isFromEdit={toEditBucketGroup} bucketGroupTypes={bucketGroupTypes} />

              </div>
              <div className='group-details' id='bucket-group-details-container' onScroll={this.loadMoreBucket}>
                {bucketList.map((bucket, index) => {
                  let draggableAsset
                  if (bucket.bucketId === droppableId) {
                    draggableAsset = this.props.assetList.find(item => item.id === draggableId.split('-random')[0])
                  }
                  if (draggableAsset) {
                    draggableAsset = { ...draggableAsset, randomKey: this.randomKey }
                  }
                  return <React.Fragment key={bucket.id}>
                    <BucketDetailCell
                      bucketId={bucket.bucketId}
                      sourceIndex={sourceIndex}
                      destinationIndex={destinationIndex}
                      droppableId={bucket.bucketId === droppableId ? droppableId : undefined}
                      draggable={bucket.bucketId === droppableId ? draggableAsset : null}
                      deleteBucketGroupItem={() => this.deleteBucketGroupItem(bucket.id)}
                      clearDragDropId={clearDragDropId}
                      selected={bucketId === bucket.bucketId}
                      changeBucket={this.changeBucket}
                      isForceSave={isSavingBucket ? bucket.isChanged : undefined}
                      forceSaveCompleted={this.forceSaveCompleted}
                      updateHistory={updateHistory}
                      displayTypes={displayTypes}
                      isCreateBlocked={isCreateBlocked}
                      isUpdateBlocked={isUpdateBlocked}
                      isDeleteBlocked={isDeleteBlocked}
                      subscriptionBucketDetail={bucketDetails}
                      onSave={this.onSave}
                      isSaveAllClicked={isConfirmClicked && toBeSaved.includes(bucket.bucketId)}
                      draggingTaskId={draggingTaskId}
                      forceFetchSubscription={updatedBucketList.includes(bucket.bucketId)}
                      completedSubscription={this.completedSubscription}
                      project={project}
                      isUpdateDisabledContent={isUpdateDisabledContent}
                      isCreateDisabledContent={isCreateDisabledContent}
                      changeAddSearchStatusStatus={this.changeAddSearchStatusStatus}
                      changeLoadingBucket={changeLoadingBucket}
                      bucketGroupId={bucketGroupId}
                    />
                    <div className='swap-icon'>
                      <SwapIcon disabled={index === bucketList.length - 1 || isUpdateBlocked} onClick={() => this.reArrangeBucket(index)} />
                    </div>
                  </React.Fragment>
                })}
                <div ref={(node) => { this.dragElement = node }} className={`empty-cell buckets-${bucketList.length}`} >
                  <DragAndDropIcon />
                  <p>Add Buckets here </p>
                </div>
              </div>
            </div>
            <div className='bucket-details-footer'>
              <ButtonContainer displayTitle='Archive Bucket Group' childComponent={<ArchiveButton type='primary' onClick={() => this.toggleWarningMessage(true)} isDisabled={isUpdateBlocked || isDefaultGroup} />} />
              <div className='right'>
                {/* <DuplicateButton onClick={() => {}} isDisabled /> */}
                <ButtonContainer displayTitle='Edit Bucket Group' childComponent={<EditButton onClick={this.showEditBucketGroupModal} isDisabled={isUpdateBlocked} />} />
                <LoadingButton type='primary' onClick={() => this.toggleSaveAllPopup(true)} isDisabled={saveAllButtonStatus} isLoading={!_.isEmpty(saveAllArray) && isConfirmClicked} htmlType='submit' buttonText='Save All' buttonClass='save-btn' />
                <ConfirmModal
                  isVisible={shouldShowWarning}
                  title={deleteWarningMessage.title}
                  message={deleteWarningMessage.message}
                  isLoading={isDeleteLoading}
                  isLeftPrior
                  rightButtonText={'Confirm'}
                  handleCancel={() => this.toggleWarningMessage(false)}
                  handleSubmit={this.archiveBucketGroup}
                  isSubmitButtonDisabled={false}
                  isCancelButtonDisabled={false}
                />
              </div>
            </div>
            <ConfirmModal
              isVisible={showDiscardWarning && !_.isEmpty(saveAllArray)}
              title={'UNSAVED CHANGES'}
              message={'Current bucket group contains unsaved changes. Do you want to discard the changes ?'}
              isLeftPrior={false}
              leftButtonText={'Discard'}
              rightButtonText={'Save'}
              handleSubmit={this.forceSave}
              handleCancel={this.discardChanges}
              handleClose={this.onCloseConfirmModal}
              isSubmitButtonDisabled={isUpdateBlocked || isConfirmClicked}
              isCancelButtonDisabled={false}
              isLoading={isSavingBucket || isConfirmClicked}
            />
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

BucketGroupDetails.propTypes = {
  /** Data for bucket list */
  bucketList: PropTypes.arrayOf(PropTypes.object),
  /** Source index for bucket item drag and rearrange */
  sourceIndex: PropTypes.number,
  /** Destination index for bucket item drag and rearrange */
  destinationIndex: PropTypes.number,
  /** ID of newly added asset in buckets */
  droppableId: PropTypes.string,
  /** Id of buckets inwhich new asset added */
  draggableId: PropTypes.string,
  /** Function to be called to clear drag and drop id */
  clearDragDropId: PropTypes.func,
  /** Function to be called on change of bucket details */
  changeBucket: PropTypes.func,
  /** Boolean for showing warning for saving un saved buckets */
  showWarning: PropTypes.bool
}

export default withApollo(compose(
  graphql(
    QueryGetBucketGroupDetails,
    {
      skip: ({ bucketGroupId }) => {
        return !bucketGroupId
      },
      options: ({ bucketGroupId }) => {
        return {
          variables: { id: bucketGroupId }
        }
      },
      props: (props) => {
        return {
          bucketGroupDetails: props.data.getBucketGroup,
          isLoading: props.data.loading,
          subscribeToChangeBucketGroup: () => {
            return props.data.subscribeToMore({
              document: SubscriptionUpdateBucketGroupList,
              updateQuery: (previous, { subscriptionData: { data: { bucketGroupUpdated } } }) => {
                if (bucketGroupUpdated && bucketGroupUpdated.isArchived) {
                  const variables = { limit: 300, project: props.project }
                  const listCacheData = props.ownProps.client.readQuery({ query: QueryGetBucketGroupList, variables })
                  const index = listCacheData.listBucketGroups.items.findIndex(group => group.id === bucketGroupUpdated.id)
                  if (index !== -1) {
                    props.ownProps.client.writeQuery({
                      query: QueryGetBucketGroupList,
                      data: listCacheData,
                      variables
                    })
                  }
                }
                if (!bucketGroupUpdated || previous.getBucketGroup.id !== bucketGroupUpdated.id || _.isEqual(bucketGroupUpdated, previous.getBucketGroup)) return previous
                const prev = cloneDeep(previous)
                prev.getBucketGroup = bucketGroupUpdated
                return prev
              }
            })
          },
          subscribeToChangeBucket: () => subscribeChangeInBucket(props.data.subscribeToMore)
        }
      }
    }
  ),
  graphql(
    MutationUpdateBucketGroup,
    {
      props: (props) => ({
        updateBucketGroup: (updateData, id) => {
          return props.mutate({
            variables: { updateData, id, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationUpdateBucketGroup,
    {
      props: (props) => ({
        updateBucketGroupDetails: (bucketObj) => {
          return props.mutate({
            variables: { ...bucketObj, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationCreateBucketGroupItem,
    {
      props: (props) => ({
        createNewBucketItem: (bucketObj) => {
          return props.mutate({
            variables: { ...bucketObj, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationCreateBucketGroup,
    {
      options: (props) => ({
        update: (cache, { data: { createBucketGroup } }) => {
          const variables = { limit: 300, project: props.project }
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryGetBucketGroupList, variables }))
          if (listCacheData && listCacheData.listBucketGroups && listCacheData.listBucketGroups.items) {
            listCacheData.listBucketGroups.items.splice(0, 0, createBucketGroup)
          }
          cache.writeQuery({
            query: QueryGetBucketGroupList,
            data: listCacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        createNewBucketGroup: (bucketObj) => {
          return props.mutate({
            variables: { ...bucketObj, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationUpdateBucketGroup,
    {
      props: (props) => ({
        archiveBucketGroup: (id) => {
          return props.mutate({
            variables: { id, isArchived: true, project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updateBucketGroup } }) => {
          const variables = { limit: 300, project: props.project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetBucketGroupList, variables }))

          if (cacheData && cacheData.listBucketGroups && cacheData.listBucketGroups.items) {
            const index = cacheData.listBucketGroups.items.findIndex(item => item.id === updateBucketGroup.id)
            if (index > -1) {
              cacheData.listBucketGroups.items.splice(index, 1)
            }
          }

          cache.writeQuery({
            query: QueryGetBucketGroupList,
            data: cacheData,
            variables
          })
        }
      })
    }),
  graphql(
    QueryGetBucketGroupList,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 300, project }
        }
      },
      props: (props) => {
        return {
          bucketGroupList: props.data && props.data.listBucketGroups ? props.data.listBucketGroups.items : []
        }
      }
    }
  ),
  graphql(
    QueryFilterAssets,
    {
      options: (props) => {
        const { filterData, project } = props
        const { searchString, filter, sort } = filterData
        const variables = utilityService.getFormattedAssetFilter(searchString, filter, sort, project)
        return {
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const assetList = data.listAssets ? data.listAssets.items : []
        return {
          assetList
        }
      }

    }
  ),
  graphql(
    QueryGetBucketGroupType,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 50, project }
        }
      },
      props: (props) => {
        const bucketGroupTypes = props.data && props.data.listBucketGroupType ? props.data.listBucketGroupType.items : []
        return {
          bucketGroupTypes
        }
      }
    }
  )
)(BucketGroupDetails))
