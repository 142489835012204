import React from 'react'
import PropTypes from 'prop-types'
import BackArrowIcon from './../icons/BackArrowIcon'
import './../../ui.style.scss'

const BackArrowButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button back-arrow ${isDisabled ? 'disabled' : ''}`} onClick={onClick} >
    <BackArrowIcon />
  </div>
)

BackArrowButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

BackArrowButton.defaultProps = {
  isDisabled: false
}

export default BackArrowButton
