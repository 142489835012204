import gql from 'graphql-tag'

export default gql(`
    mutation updateTeamMedia( $team: String $tournamentCalendar: String  $logo: ID $awayJersey: ID $homeJersey: ID $customHomeVenue : String) {
        updateTeamTournament(
            input: {
                team: $team
                tournamentCalendar: $tournamentCalendar
                logo: $logo
                awayJersey: $awayJersey
                homeJersey: $homeJersey
                customHomeVenue: $customHomeVenue
            }
        ) {
            id
            customHomeVenue
            awayJersey{
                id
                type
                fileName
                createdAt
                updatedAt
                type
                name
                fileSize
                isArchived
                duration
                thumbnails
                tags{
                    name
                    key
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
                aspectRatio {
                    title
                    aspectRatio
                    ratio
                    resolutions {
                        key
                        width
                        height
                        url
                    }
                }
                streams{
                    id
                }
                vodStatus{
                    id
                }
            }
            homeJersey{
                id
                type
                fileName
                createdAt
                updatedAt
                type
                name
                fileSize
                isArchived
                duration
                thumbnails
                tags{
                    name
                    key
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
                aspectRatio {
                    title
                    aspectRatio
                    ratio
                    resolutions {
                        key
                        width
                        height
                        url
                    }
                }
                streams{
                    id
                }
                vodStatus{
                    id
                }
            }
            logo{
                id
                type
                fileName
                createdAt
                updatedAt
                type
                name
                fileSize
                isArchived
                duration
                thumbnails
                tags{
                    name
                    key
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
                aspectRatio {
                    title
                    aspectRatio
                    ratio
                    resolutions {
                        key
                        width
                        height
                        url
                    }
                }
                streams{
                    id
                }
                vodStatus{
                    id
                }
            }
        }
    }`
)
