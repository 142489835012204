import gql from 'graphql-tag'

export default gql(`
    mutation updateCompetition( $id: ID! $customTitle: String  $customCompetitionCode: String $customCountry: String 
        $customCompetitionFormat: String $customType: String $customCompetitionType: String $customSource: String $tags: [ID] $logo: ID $defaultTeamImage: ID) {
        updateCompetition(
            input: {
                id: $id
                tags: $tags
                customTitle: $customTitle
                customCompetitionCode: $customCompetitionCode
                customCountry: $customCountry
                customCompetitionFormat: $customCompetitionFormat
                customType: $customType
                customCompetitionType: $customCompetitionType
                customSource: $customSource
                logo: $logo
                defaultTeamImage: $defaultTeamImage
            }
        ) {
            id
            title
            customType
            customTitle
            customSource
            customCountry
            customCompetitionCode
            customCompetitionType
            customCompetitionFormat
            tags{
                key
                name
                type
            }
            logo {
                id
                url
                fileName
                aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                    id
                    key
                  }
                  name
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
                }
                updatedAt
                type
                thumbnails
                status
                source
                module
                name
                mediaId
                isArchived
                fileSize
                drmContent
                duration
                createdAt
                createdBy
                adPositions
            }
    
              defaultTeamImage {
                id
                url
                fileName
                aspectRatio {
                  title
                  aspectRatio
                  resolutions {
                    url
                    id
                    key
                  }
                  name
                }
                settings {
                  aspectRatio
                  outputFormat
                  fileName
                  x1
                  y1
                  x2
                  y2
                }
                updatedAt
                type
                thumbnails
                status
                source
                module
                name
                mediaId
                isArchived
                fileSize
                drmContent
                duration
                createdAt
                createdBy
                adPositions
            } 
        }
    }`
)
