import React from 'react'
import PropTypes from 'prop-types'

const PinterestIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='none' fillRule='evenodd'>
      <rect width='30' height='30' fill='#AFAFAF' rx='3.75' />
      <path fill='#FFF' d='M15.55 3.281c-6.396 0-9.622 4.586-9.622 8.41 0 2.316.877 4.376 2.757 5.142.308.128.584.005.674-.336.062-.236.21-.831.275-1.08.09-.338.055-.456-.194-.75-.542-.64-.89-1.467-.89-2.64 0-3.402 2.546-6.448 6.629-6.448 3.615 0 5.602 2.209 5.602 5.158 0 3.882-1.718 7.158-4.268 7.158-1.409 0-2.462-1.164-2.125-2.593.404-1.705 1.188-3.545 1.188-4.777 0-1.102-.592-2.02-1.815-2.02-1.44 0-2.596 1.489-2.596 3.483 0 1.271.429 2.131.429 2.131l-1.732 7.336c-.514 2.177-.077 4.846-.04 5.114.022.161.227.2.32.079.132-.173 1.848-2.29 2.43-4.406.166-.599.947-3.7.947-3.7.468.893 1.835 1.677 3.289 1.677 4.327 0 7.264-3.945 7.264-9.225C24.072 7 20.69 3.28 15.55 3.28z' />
    </g>
  </svg>
)

PinterestIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

PinterestIcon.defaultProps = {
  width: 30,
  height: 30
}

export default PinterestIcon
