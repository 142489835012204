import _, { isEmpty } from 'lodash'
import moment from 'moment'
import NProgress from 'nprogress'
import { message } from 'antd'
import { Auth, Hub } from 'aws-amplify'
import { createBrowserHistory } from 'history'

import AuthService from './AuthService'
import config from './../constants/config';

const checkPasswordValidation = password => {
  // Password validations: atleast one number one uppercase one special character length between 6 and 16
  const passwordRegularExpression = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])[a-zA-Z0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]{6,16}$/
  return passwordRegularExpression.test(password)
}

const checkUrlValidation = url => {
  // Password validations: atleast one number one uppercase one special character length between 6 and 16
  const urlRegx = /^(?:http(s)?:\/\/)+[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
  return urlRegx.test(url)
}

const checkEmailValidation = email => {
  const emailRegularExpression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegularExpression.test(email)
}

const replaceAll = (parentString, str1, str2, ignore) => {
  return parentString.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), (ignore ? "gi" : "g")), (typeof (str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
}

const getAspectRatioImage = (aspectRatioData, aspectRatio) => {
  const perfectImage = aspectRatioData && aspectRatioData.length ? aspectRatioData.find(ratio => ratio.title === aspectRatio) : null
  const image = perfectImage && perfectImage.resolutions && perfectImage.resolutions.length ? perfectImage.resolutions[0].url : aspectRatioData[0].resolutions[0].url
  return image;
}

const slugifyString = string => {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;'
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------'
  const p = new RegExp(a.split('').join('|'), 'g')
  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    .substring(0, 250)
}

const getFormattedAssetFilter = (searchString, filterVal, sort, project) => {
  const filter = {
    isArchived: {
      match: false
    },
  }
  let assetFilter = _.isEmpty(filterVal) ? filter : filterVal
  if(!assetFilter.isArchived)
    assetFilter.isArchived = {
      match: false
    }

  let search
  if (searchString) {
    const regExp = /^\d*$/g
    const newSearch = regExp.test(searchString) ? `${project === 'hyperion' ? 'h' : project === 'projectx' ? 's' :  'os'}${searchString}` : searchString
    search = {
      keyword: newSearch,
      fields: ["title", "tags", "description", "externalId", "id"]
    }
  }

  return { filter: assetFilter, offset: 0, limit: 20, orderBy: sort.value, order: sort.order, search: search, project }
}

const getFormattedMediaFilter = (searchString, filterVal, mediaType, project) => {
  let search
  let newFilter = filterVal || {
    isArchived: {
      match: false
    }
  }
  if (searchString) {
    search = {
      keyword: searchString,
      fields: ['name', 'tags']
    }
  }
  if(mediaType)
    newFilter.type = { match: mediaType}
  
  return { filter: newFilter, offset: 0, limit: 15, search: search, project }
}

const getFormattedChannelFilter = (searchString, filterVal) => {
  let search = null
  let newFilter = _.isEmpty(filterVal) ? {
    isArchived: {
      match: false
    }
  } : filterVal
  if (searchString) {
    search = {
      keyword: searchString,
      fields: ['name', 'channelCode']
    }
  }
  return { filter: newFilter, search: search }
}

const getFormattedBucketFilter = (searchString, filterVal, sort, project) => {
  let search
  let newFilter = _.isEmpty(filterVal) ? {
    isArchived: {
      match: false
    }
  } : filterVal
  if (searchString) {
    search = {
      keyword: searchString,
      fields: ['name', 'displayName', 'key', 'tags']
    }
  }
  return { filter: newFilter, offset: 0, limit: 20, search: search, orderBy: sort.value, order: sort.order, project}
}

const changeFilterData = (filterVal) => {
  const filterData = typeof (filterVal) === 'string' ? filterVal.split(',') : filterVal
  let value = (filterData || []).map((innerItem) => typeof (innerItem) === 'string' ? innerItem : innerItem.key)
  value = value && value.length ? value : null
  return value
}

const changeFilterDataForOffer = (filterVal) => {
  const filterData = typeof (filterVal) === 'string' ? filterVal.split(',') : filterVal
  let value = (filterData || []).map((innerItem) => typeof (innerItem) === 'string' ? innerItem : innerItem.value)
  value = value && value.length ? value : null
  return value
}


const getFormattedFilter = (filter) => {
  let newFilter = {}
  filter.map((filterItem) => {
    if (filterItem.value !== null) {
      if (filterItem.type === 'KEYWORD') {
        newFilter[filterItem.name] = {
          multiple_exact: changeFilterData(filterItem.value)
        }
      } else if (filterItem.type === 'DATE') {
        newFilter[filterItem.name] = {
          multiple_exact: filterItem.value.split(',')
        }
      } else if (filterItem.type === 'INPUT') {
        newFilter[filterItem.name] = {
          multiple_exact: [filterItem.value]
        }
      } else if (filterItem.type === 'MULTIPLE') {
        if (!isEmpty(filterItem.value)){
          newFilter[filterItem.name] = {
            multiple_exact: filterItem.value
          }
        }
      } else {
        newFilter[filterItem.name] = {
          match: filterItem.value
        }
      }
    }
    if (filterItem.value === 'both' && filterItem.type === 'BOOLEAN') {
      newFilter[filterItem.name] = {
        match: null
      }
    }
  })
  if (newFilter.hasOwnProperty('isArchived') && newFilter.isArchived === undefined) { newFilter.isArchived = { match: false } }
  return newFilter
}

const getFormattedFilterForOffer = (filter) => {
  let newFilter = { }
  filter.map((filterItem) => {
    if (filterItem.value !== null) {
      if (filterItem.type === 'KEYWORD' && filterItem.name !== 'partner') {
        newFilter[filterItem.name] = {
          multiple_or: changeFilterDataForOffer(filterItem.value)
        }
      }
      else if (filterItem.type === 'KEYWORD' && filterItem.name === 'partner') {
        newFilter[filterItem.name] = {
          exact: filterItem.value
        }
      }
       else if(filterItem.type === 'BOOLEAN'&& filterItem.name === 'isActive'){
         if(filterItem.value === 1 || filterItem.value === 2){
           let name='isActive'
           let value= filterItem.value === 1 ? true : false
        newFilter[name] = {
          match: value
        }
      }else{
        let name='isArchived'
        let value=filterItem.value === 3 ? true : false
        newFilter[name] = {
          match: value
        }
      }
      }
    }
    
  })
  if (newFilter.hasOwnProperty('isArchived') && newFilter.isArchived === undefined) { newFilter.isArchived = { match: false } }
  return newFilter
}

const getFormattedFilterForPartner = (filter) => {
  let newFilter = {};
  (filter || []).map((filterItem) => {
    if (filterItem.value !== null) {
      if (filterItem.type === 'KEYWORD' && filterItem.name === 'partner') {
        [filterItem.name] = {
          exact: filterItem.value
        }
      }
      else if(filterItem.type === 'BOOLEAN'&& filterItem.name === 'isActive'){
        if(filterItem.value === 1 || filterItem.value === 2){
          let name='isActive'
          let value= filterItem.value === 1 ? true : false
       newFilter[name] = {
         match: value
       }
     }else{
       let name='isArchived'
       let value=filterItem.value === 3 ? true : false
       newFilter[name] = {
         match: value
       }
     }
     }
      }
  })
  if (newFilter.hasOwnProperty('isArchived') && newFilter.isArchived === undefined) { newFilter.isArchived = { match: false } }
  return newFilter
}

const getFormattedTagFilter = (tagFilter) => {
  let newFilter = {}
  if(tagFilter.filter && tagFilter.filter.length) {
    (tagFilter.filter).map( filterItem => {
      newFilter[filterItem.name] = filterItem.value ? {
        multiple_exact: (filterItem.value||[]).map(innerItem => {
          if(filterItem.fieldName === 'MODULE')
          return innerItem.key
          else
          return innerItem.name})
      } : null
    })
  }
  if(tagFilter.searchString){
    newFilter.name = { match: tagFilter.searchString }
  } else if((tagFilter.searchString === '' || tagFilter.searchString === null || tagFilter.searchString === undefined) && newFilter.name)
    delete(newFilter.name)
  return newFilter
}

const makeRandomString = (length) => {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const getPublishStatus = ( isPublished, publishStartDate, publishEndDate) => {
  if (isPublished && publishStartDate && moment.utc(publishStartDate)) {
    const now = moment.utc(new Date())
    const isAfter = now.isAfter(moment.utc(publishStartDate))
    if (publishEndDate) {
      const isBefore = now.isBefore(moment.utc(publishEndDate))
      const timeDiff = moment.utc(publishEndDate).diff(moment.utc(publishStartDate))
      if (timeDiff > 0 && isBefore && isAfter) {
        return 'PUBLISHED'
      } else if(timeDiff > 0 && !isBefore) {
        return 'EXPIRED'
      } else {
        return 'DRAFT'
      }
    } else if (isAfter) {
      return 'PUBLISHED'
    }
  }
  return 'DRAFT'
}

const getMatchManagerFilter = (matchFilter, matchSearch, count = 15) => {
  let filter = null
  if (matchFilter) {
    filter = {}
    if (matchFilter.selectedCompetitions && matchFilter.selectedCompetitions.length > 0) {
      filter.competition = { multiple_exact: matchFilter.selectedCompetitions.map(selectedItem => selectedItem.id) }
    }
    if (matchFilter.filterDate) {
      filter.matchDate = { multiple_exact: matchFilter.filterDate.split(',') }
    }
    if(matchFilter.stages && matchFilter.stages.length > 0){
      filter.stageName = { multiple_exact: matchFilter.stages.map(selectedItem => selectedItem.id) }
    }
    if(matchFilter.seasons && matchFilter.seasons.length > 0){
      filter.seasonName = { multiple_exact: matchFilter.seasons.map(selectedItem => selectedItem.id) }
    }
    if(matchFilter.sport && matchFilter.sport.length > 0){
      // filter.sport = { multiple_exact: matchFilter.sport } // for multiSelect={false}
      filter.sport = { multiple_exact: matchFilter.sport.map(selectedItem => selectedItem.id) } // for multiSelect
    }
    if(matchFilter.selectedSport && matchFilter.selectedSport.hasOwnProperty('id')){ // if id is provided instead of object
      filter.sport = { multiple_exact: [matchFilter.selectedSport.id] }
    }
  }
  const search = matchSearch ? { keyword: matchSearch, fields: [ 'title', 'description', 'opId' ] } : undefined
  const variables = { filter: !_.isEmpty(filter) ? filter : null, search, limit: count, offset: 0, orderBy: 'matchDate' } 
  return variables
}

const getAuditFilter = (search, filter, selectedBucketGroup, content, moduleContent, count = 20 ) => {
  if(content === 'BUCKET_MANAGER') {
    let modifiedFilter = getFormattedFilter(filter)
    if(filter){
      const contentIds = filter.filter(item => item.value && item.value.length && (item.fieldName === 'BUCKET' || item.fieldName === 'BUCKET_GROUP')).map(item => item.value.map(value=> value.key))
      const contentItemIds = contentIds.reduce(( total, item) => [...total, ...item], [])
      if(contentItemIds && contentItemIds.length) {
        modifiedFilter.contentId = { multiple_exact: contentItemIds }
      } else {
        modifiedFilter.contentId = { multiple_exact: [ selectedBucketGroup] }
      }
    }
    const variables = { filter: !_.isEmpty(modifiedFilter) ? modifiedFilter : null, search, limit: count, offset: 0 } 
    return variables
  } else if(content === 'CHANNEL_MANAGER') {
    let modifiedFilter = {}
    modifiedFilter.module = { multiple_exact: [content] }
    modifiedFilter.contentId = { multiple_exact: [filter, selectedBucketGroup] }
    const variables = { filter: !_.isEmpty(modifiedFilter) ? modifiedFilter : null, search, limit: count, offset: 0 } 
    return variables
  } else if(content === 'DATA_MANAGER') {
    let modifiedFilter = {}
    modifiedFilter.contentType =  filter  && filter.length? { multiple_exact: filter}  : {multiple_exact: [filter] }
    // if(moduleContent === 'OPTA_SECTION'){
      modifiedFilter.contentId = selectedBucketGroup && selectedBucketGroup.length? { multiple_exact: selectedBucketGroup } : {}
    // }else {
    //   modifiedFilter.contentId = {multiple_exact: [selectedBucketGroup]}
    // }
    modifiedFilter.module = { multiple_exact: [content] }
    const variables = { filter: !_.isEmpty(modifiedFilter) ? modifiedFilter : null, search, limit: count, offset: 0 } 
    return variables
  } else if(content === 'DRM_MANAGER') {
    let modifiedFilter = {}
    modifiedFilter.module = { exact: content }
    const variables = { filter: !_.isEmpty(modifiedFilter) ? modifiedFilter : null, search, limit: count, offset: 0 } 
    return variables
  }
}

const getRoleAuditFilter = (search, filter, selectedModule, selectedUser, selectedUserGroup, count = 20 ) => {
    let modifiedFilter = {}
    modifiedFilter.module = { multiple_exact: [selectedModule] }
    if(selectedUser && selectedUserGroup){
      modifiedFilter.contentId = { multiple_exact: [selectedUser, selectedUserGroup] }
    }else if (selectedUser) {
      modifiedFilter.updatedBy = { multiple_exact: [selectedUser] }
    }else if (selectedUserGroup) {
      modifiedFilter.contentId = { multiple_exact: [ selectedUserGroup] }
    }
    
    const variables = { filter: !_.isEmpty(modifiedFilter) ? modifiedFilter : null, search, limit: count, offset: 0 } 
    return variables
}

const getFormattedJobFilter = (filterData, searchString, sort) => {
  let search= null
  const filter = _.isEmpty(filterData) ? null : filterData
    if (searchString) {
      search = {
        keyword: searchString,
        fields: ['name', 'id', 'vwmJobId', 'createdBy']
      }
    }
  return { filter, offset: 0, limit: 20, orderBy: sort.value, order: sort.order, search: search }
}


const handleError = error => {
    NProgress.done()
    const history = createBrowserHistory()
    const errorType = typeof(error)
    if(errorType === 'string'){
      const errMessage = error.replace('Error: ', '').replace('GraphQL error: ', '')
      message.warning(errMessage)
      if(errMessage === "User suspended" || errMessage === "Invalid User" || errMessage.includes("Invalid token")){
        setTimeout(() => {
          Auth.signOut()
          .then(data => {
            AuthService.clearUserDetails()
            window.location.reload()
            history.push('/')
            Hub.listen('auth', this)
          })
          .catch(err => console.log(err))
        }, 1000)
        return
      }
    } else if(errorType === 'object'){
      const { graphQLErrors } = error
      if(graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized'){
        message.warning('User does not have permission to edit')
      } else if (graphQLErrors && graphQLErrors.length && graphQLErrors[0] && graphQLErrors[0].errorType.includes('Invalid token')){
        message.warning(graphQLErrors[0].errorType)
        setTimeout(() => {
          Auth.signOut()
          .then(data => {
            AuthService.clearUserDetails()
            window.location.reload()
            history.push('/')
            Hub.listen('auth', this)
          })
          .catch(err => console.log(err))
        }, 1000)
      }  else {
        const errorMessage = error.message.replace('Error: ', '').replace('GraphQL error: ', '')
        message.warning(errorMessage)
        if(errorMessage === "User suspended" || errorMessage === "Invalid User" || errorMessage.includes("Invalid token")){
          setTimeout(() => {
            Auth.signOut()
            .then(data => {
              AuthService.clearUserDetails()
              window.location.reload()
              history.push('/')
              Hub.listen('auth', this)
            })
            .catch(err => console.log(err))
          }, 1000)
          return
        }
      }
    }
}

const handleCustomerManagerError = error => {
    NProgress.done()
    const history = createBrowserHistory()
    const errorType = typeof(error)
    if(errorType === 'string'){
      const errMessage = error.replace('Error: ', '').replace('GraphQL error: ', '')
      message.warning(errMessage)
      if(errMessage.includes( "Invalid token")){
        setTimeout(() => {
          Auth.signOut()
          .then(data => {
            AuthService.clearUserDetails()
            window.location.reload()
            history.push('/')
            Hub.listen('auth', this)
          })
          .catch(err => console.log(err))
        }, 1000)
        return
      }
    } else if(errorType === 'object'){
      const errorMessage = error.message.replace('Error: ', '').replace('GraphQL error: ', '')
      message.warning(errorMessage)
      if(errorMessage.includes( "Invalid token")){
        setTimeout(() => {
          Auth.signOut()
          .then(data => {
            AuthService.clearUserDetails()
            window.location.reload()
            history.push('/')
            Hub.listen('auth', this)
          })
          .catch(err => console.log(err))
        }, 1000)
        return
      }
    }
}

const getFormattedOfferFilter=(searchString, filterVal, sort, project)=>{
  const filter = {
    isArchived: {
      match: false
    },
  }
  let offerFilter = _.isEmpty(filterVal) ? filter : filterVal
  if(!offerFilter.isArchived)
    offerFilter.isArchived = {
      match: false
    }


	let search
  if (searchString) {
    search = {
      
      keyword: searchString,
      fields: ["name","id","externalId"]
    }
  }
  return { filter: offerFilter, offset: 0, limit: 20, orderBy: sort.value, order: sort.order, search: search, project }
}

const getFormattedPartnerFilter=(filterVal, searchString, sort, project)=>{
    const filter = {
      isArchived: {
        match: false
      },
    }
    let partnerFilter = _.isEmpty(filterVal) ? filter : filterVal
    if(!partnerFilter.isArchived)
      partnerFilter.isArchived = {
        match: false
      }
    let search
    if (searchString) {
      search = {
        keyword: searchString,
        fields: ["name","id","externalId"]
      }
    }
    return {filter: partnerFilter, offset: 0, limit: 20, order: sort.order, orderBy: sort.value, search: search, project }
  }

  const getMonthasString=(month) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const monthString = months[month]
    return monthString
  }

  const getFormattedDateString=(date) => {
    const day = date.getDate()
    const month = getMonthasString(date.getMonth())
    const year = date.getFullYear()
    const hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
    const mins = (date.getMinutes() + '').length > 1 ? date.getMinutes() : '0' + date.getMinutes()
    const timeNotation = (date.getHours() > 11 ? 'PM' : 'AM')
    const dateString = day + ' ' + month + ' ' + year + ', ' + hour + ':' + mins + ' ' + timeNotation
    return dateString
  }

const delay = ms => new Promise(res => setTimeout(res, ms))

const getUniqueArr = (array) => {
  let uniqueArray = [];
  let lowecaseArr = [];
  
  // Loop through array values
  for(let value of array){
      if(lowecaseArr.indexOf((value||'').toLowerCase()) === -1){
          uniqueArray.push(value);
          lowecaseArr.push(value.toLowerCase());
      }
  }
  return uniqueArray;
}

const getEnvironment = () => {
  const currentUrl = window.location.href
  if(currentUrl.includes('dev'))
    return 'DEV'
  else if(currentUrl.includes('qa'))
    return 'QA'
  else if(currentUrl.includes('uat'))
    return 'UAT'
  else if(currentUrl.includes('local'))
    return 'LOCAL'
  else return ''
}

const isProdEnv = () => getEnvironment() === 'UAT' || getEnvironment() === ''

const getCurrentProject = () => {
  return (window.location.href.includes('hyp')) ? 'hyperion' : window.location.href.includes('subhub') ? 'projectx' :  'vcms'
  // return 'projectx'
}

const getRoleAuthLogFilter = (search, selectedUser, count = 20 ) => {
  let modifiedFilter = {}
   if (selectedUser) {
    modifiedFilter = {user: {exact: selectedUser }}
  }
  const variables = { filter: !_.isEmpty(modifiedFilter) ? modifiedFilter : null, search, limit: count, offset: 0 } 
  return variables
}

const getBrowserDetails = () =>  {
  'use strict';
  
  var module = {
      options: [],
      header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
      dataos: [
          { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
          { name: 'Windows', value: 'Win', version: 'NT' },
          { name: 'iPhone', value: 'iPhone', version: 'OS' },
          { name: 'iPad', value: 'iPad', version: 'OS' },
          { name: 'Kindle', value: 'Silk', version: 'Silk' },
          { name: 'Android', value: 'Android', version: 'Android' },
          { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
          { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
          { name: 'Macintosh', value: 'Mac', version: 'OS X' },
          { name: 'Linux', value: 'Linux', version: 'rv' },
          { name: 'Palm', value: 'Palm', version: 'PalmOS' }
      ],
      databrowser: [
          { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
          { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
          { name: 'Safari', value: 'Safari', version: 'Version' },
          { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
          { name: 'Opera', value: 'Opera', version: 'Opera' },
          { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
          { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
      ],
      init: function () {
          var agent = this.header.join(' '),
              os = this.matchItem(agent, this.dataos),
              browser = this.matchItem(agent, this.databrowser);
          
          return { os: os, browser: browser };
      },
      matchItem: function (string, data) {
          var i = 0,
              j = 0,
              html = '',
              regex,
              regexv,
              match,
              matches,
              version;
          
          for (i = 0; i < data.length; i += 1) {
              regex = new RegExp(data[i].value, 'i');
              match = regex.test(string);
              if (match) {
                  regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                  matches = string.match(regexv);
                  version = '';
                  if (matches) { if (matches[1]) { matches = matches[1]; } }
                  if (matches) {
                      matches = matches.split(/[._]+/);
                      for (j = 0; j < matches.length; j += 1) {
                          if (j === 0) {
                              version += matches[j] + '.';
                          } else {
                              version += matches[j];
                          }
                      }
                  } else {
                      version = '0';
                  }
                  return {
                      name: data[i].name,
                      version: parseFloat(version)
                  };
              }
          }
          return { name: 'unknown', version: 0 };
      }
  };
  
  var e = module.init(),
      browserAndDevicedetails = '';
  
      browserAndDevicedetails += `${e.browser.name} / ${e.os.name} ` 
      return browserAndDevicedetails
};

const getFormattedWidgetFilter = (app, searchString,project) => {
  let filter = {
    app: {
      match: app
    }
  }
  let search
  if (searchString) {
    const regExp = /^\d*$/g
    const newSearch = searchString
    search = {
      keyword: newSearch,
      fields: ["title", "tags", "description", "externalId", "id"]
    }
  }

  return { filter: filter,limit: 999, search: search, project }
}


const getPagePublishStatus = ( isPublished, publishStartDate, publishEndDate) => {
  if (isPublished && publishStartDate && moment.utc(publishStartDate)) {
    const now = moment.utc(new Date())
    const isAfter = now.isAfter(moment.utc(publishStartDate))
    if (publishEndDate) {
      const isBefore = now.isBefore(moment.utc(publishEndDate))
      const timeDiff = moment.utc(publishEndDate).diff(moment.utc(publishStartDate))
      if (timeDiff > 0 && isBefore && isAfter) {
        return 'Published'
      } else if(timeDiff > 0 && !isBefore) {
        return 'Expired'
      } else if(timeDiff > 0 && !isAfter) {
        return 'Scheduled'
      }
    } else if (isAfter) {
      return 'Published'
    } else if (!isAfter) {
      return 'Scheduled'
    }
  }
  // return 'UNPUBLISHED'
}

const getPageSheduleStatus = (publishStartDate, publishEndDate) => {
  if (publishStartDate && moment.utc(publishStartDate)) {
    const now = moment.utc(new Date())
    const isAfter = now.isAfter(moment.utc(publishStartDate))
    if (publishEndDate) {
      const isBefore = now.isBefore(moment.utc(publishEndDate))
      const timeDiff = moment.utc(publishEndDate).diff(moment.utc(publishStartDate))
      if (timeDiff > 0 && isBefore && isAfter) {
        return 'Published'
      } else if(timeDiff > 0 && !isBefore) {
        return 'Expired'
      } else if(timeDiff > 0 && !isAfter) {
        return 'Scheduled'
      }
    } else if (isAfter) {
      return 'Published'
    } else if (!isAfter) {
      return 'Scheduled'
    }
  }
  // return 'UNPUBLISHED'
}

const getFormattedWidgetSettingFilter = (app, searchString, project, active) => {
  let filter = {
    // app: {
    //   match: app
    // }
  }
  if(!_.isEmpty(app)){
  const appIds=(app || []).map(item=>item.key || item.id)
    filter.app={multiple_exact: appIds} 
  } 
  if(!_.isEmpty(active)){
    if(active !== 'both'){
      filter.isActive={match: active } 
    } else {
      delete filter.isActive
    }
  } 
  let search
  if (searchString) {
    //const regExp = /^\d*$/g
    const newSearch = searchString
    search = {
      keyword: newSearch,
      fields: ["title", "tags", "description", "externalId", "id"]
    }
  }

  return { filter: filter,limit: 999, search: search, project }
}


const getFormattedAppSettingFilter = (searchString, limit,project) => {
  // let filter = {
  //   // app: {
  //   //   match: app
  //   // }
  // }
  // if(!_.isEmpty(app)){
  // const appIds=(app || []).map(item=>item.key || item.id)
  //   filter.app={multiple_exact: appIds} 
  // } 
  let search
  if (searchString) {
    const regExp = /^\d*$/g
    const newSearch = regExp.test(searchString) ? `${project === 'hyperion' ? 'h' : project === 'projectx' ? 's' : 'os'}${searchString}` : searchString
    search = {
      keyword: newSearch,
      fields: ["title", "name", "description", "id"]
    }
  }

  return { limit: limit, search: search, project }
}

const getSourceDetails = (source) => {
  if (source) {
    return source[0].toUpperCase() + source.slice(1)
  } else {
    return 'Manual'
  }
}

const getPrimaryImageType = () => {
  let project = getCurrentProject();
  let primaryImageType = project === 'hyperion' ||  project === 'projectx' ? `${project}-primary` : 'primary'
  return primaryImageType
}

const copyToClipBoard = (data, type) => {
  navigator.clipboard.writeText(data, type)
  message.success(`${type} Copied Successfully`)
}

const getFormattedIngestFilter = (filter) => {
  let newFilter = { }
  filter.map((filterItem) => {
    if (filterItem.value !== null) {
      if (filterItem.type === 'KEYWORD' || filterItem.type === 'SELECT' ) {
        newFilter[filterItem.name] = changeFilterData(filterItem.value)
      } else if (filterItem.type === 'DATE') {
        newFilter[filterItem.name] = {
          multiple_exact: filterItem.value.split(',')
        }
      } else {
        newFilter[filterItem.name] = {
          match: filterItem.value
        }
      }
    }
    if (filterItem.value === 'both' && filterItem.type === 'BOOLEAN') {
      newFilter[filterItem.name] = {
        match: null
      }
    }
  })
  if (newFilter.hasOwnProperty('isArchived') && newFilter.isArchived === undefined) { newFilter.isArchived = { match: false } }
  return newFilter
}

const deepOmit = (obj, keys) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => deepOmit(item, keys));
  }

  const result = {};
  for (let key in obj) {
    if (!keys.includes(key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        result[key] = deepOmit(value, keys);
      } else {
        result[key] = value;
      }
    }
  }
  return result;
}


export const utilityService = {
  getFormattedIngestFilter,
  checkPasswordValidation,
  checkEmailValidation,
  getAspectRatioImage,
  replaceAll,
  slugifyString,
  getFormattedAssetFilter,
  getFormattedMediaFilter,
  getFormattedBucketFilter,
  getFormattedFilter,
  getFormattedTagFilter,
  makeRandomString,
  getPublishStatus,
  getFormattedChannelFilter,
  getMatchManagerFilter,
  getAuditFilter,
  handleError,
  getFormattedJobFilter,
  getRoleAuditFilter,
  checkUrlValidation,
  getFormattedOfferFilter,
  getFormattedFilterForOffer,
  getFormattedPartnerFilter,
  getFormattedFilterForPartner,
  getFormattedDateString,
  delay,
  getCurrentProject,
  getUniqueArr,
  getEnvironment,
  getRoleAuthLogFilter,
  getBrowserDetails,
  getFormattedWidgetFilter,
  getPagePublishStatus,
  getFormattedWidgetSettingFilter,
  getSourceDetails,
  getPageSheduleStatus,
  getFormattedAppSettingFilter,
  getPrimaryImageType,
  copyToClipBoard,
  isProdEnv,
  deepOmit,
  handleCustomerManagerError
}
