import React from 'react'
import PropTypes from 'prop-types'

const FilterIcon = ({ width, height, selected, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
    <path fill={selected ? '#FF015A' : '#343A40'} fillRule='evenodd' d='M16.231 8H19a1 1 0 1 1 0 2h-2.808A2.75 2.75 0 0 1 11 9.687V10H1a1 1 0 1 1 0-2h10v.213A2.751 2.751 0 0 1 16.231 8zm-5.539 6.2H19a1 1 0 1 1 0 2h-8.269a2.751 2.751 0 0 1-5.162 0H1a1 1 0 1 1 0-2h4.608a2.75 2.75 0 0 1 5.084 0zM3.734 1.9a2.751 2.751 0 0 1 5.232 0H19a1 1 0 1 1 0 2H8.849a2.75 2.75 0 0 1-4.998 0H1a1 1 0 1 1 0-2h2.734zM13.6 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM6.35 4.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zM8.15 17a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm5.5-6.3a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5z' />
  </svg>
)

FilterIcon.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

FilterIcon.defaultProps = {
  selected: false,
  width: 20,
  height: 18
}

export default FilterIcon
