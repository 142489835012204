import React from 'react'
import PropTypes from 'prop-types'

const DragDropUploadIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill='#343A40' fillRule='evenodd' d='M28.602 9.68c0-.097.015-.192.015-.288C28.617 4.2 24.57 0 19.578 0c-3.601 0-6.695 2.188-8.148 5.35a4.547 4.547 0 0 0-2.094-.518c-2.305 0-4.227 1.749-4.594 4.033C1.977 9.839 0 12.546 0 15.733 0 19.742 3.133 23 6.992 23H15v-6.389h-3.766L17.5 9.927l6.266 6.676H20v6.389h8.617c3.531 0 6.383-2.995 6.383-6.66 0-3.666-2.867-6.645-6.398-6.653z' />
  </svg>
)

DragDropUploadIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

DragDropUploadIcon.defaultProps = {
  width: 35,
  height: 23
}

export default DragDropUploadIcon
