import gql from 'graphql-tag'

export default gql(`
    mutation deleteTagType($id: ID!, $project: String) {
        deleteTagType(
                input: {
                    id: $id
                }
                project: $project
        ) {
            id
            name
            enabledAssetTypes{
                assetType
            }
        }
    }`
)
