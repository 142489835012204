import gql from 'graphql-tag'

export default gql(`
    mutation adminRefund($userEmail: String!,$cmsUserEmail: String!,$offerId: String!,$comment: String!,$transactionId: String,$transactionCoverTimeStamp: String) {
        adminRefund(
            userEmail: $userEmail
            cmsUserEmail: $cmsUserEmail
            offerId: $offerId
            comment: $comment
            transactionId: $transactionId
            transactionCoverTimeStamp: $transactionCoverTimeStamp
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
