import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '../../components/ui/dataEntry/inputs/Input'
import TagsInput from '../../components/ui/dataEntry/inputs/TagsInput'
import TextAreaInput from '../../components/ui/dataEntry/inputs/TextArea'
import { utilityService } from './../../services/UtilityService'

const parentCompoent = 'app-details-container'
const seoTitleMaxLength = 150
const slugMaxLength = 250
const seoDescMaxLength = 155

class PageSeoSettings extends Component {
  render () {
    const seoDetails = this.props.details
    const { onSeoKeywordChange, onInputFieldBlur, onInputFieldFocus, isInvalidSlug, handleSeoDetailsChange, mode } = this.props
    return (
      <div className='seo-container'>
        <div className='edit-input-container'>
          <div className='edit-input-inner-container'>
            <div className='output-field-left'>
              <Input
                title='SEO Title'
                placeholder='Insert SEO Title'
                inputName='seoTitle'
                onFocus={onInputFieldFocus}
                onBlur={onInputFieldBlur}
                value={seoDetails.title !== null && seoDetails.title !== undefined ? seoDetails.title : null}
                handleChange={handleSeoDetailsChange}
                isLimited
                limit={seoTitleMaxLength}
                isRequired
                mode={mode}
              />
            </div>
            <div className='output-field-right'>
              <Input
                title='URL Slug'
                placeholder='Insert URL Slug'
                inputName='slug'
                isRequired
                isError={isInvalidSlug}
                value={seoDetails.slug !== null && seoDetails.slug !== undefined ? seoDetails.slug : utilityService.slugifyString(this.props.defaultTitle.toLowerCase())}
                handleChange={handleSeoDetailsChange}
                onFocus={onInputFieldFocus}
                onBlur={onInputFieldBlur}
                errorMessage={'Invalid Slug'}
                isLimited
                limit={slugMaxLength}
                mode={mode}
              />
            </div>
          </div>
          <div className='edit-input-inner-container'>
            <TextAreaInput
              title='Short Description'
              placeholder='Write a short description...'
              inputName='seoMetaDescription'
              onFocus={onInputFieldFocus}
              onBlur={onInputFieldBlur}
              value={seoDetails.metaDescription}
              handleChange={handleSeoDetailsChange}
              isLimited
              limit={seoDescMaxLength}
              mode={mode}
            />
          </div>
          <div className='edit-input-inner-container'>
            <div className='input-field'>
              <label className='input-title'>Keywords</label>
              <TagsInput
                selectedItems={seoDetails.keywords || []}
                tagList={[]}
                className={'key-word-dropdown'}
                mode={'tags'}
                onChange={onSeoKeywordChange}
                placeholder='Insert Keywords'
                displayParameter='name'
                parentCompoent={parentCompoent}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PageSeoSettings.propTypes = {
  /** details of SeoSettings. */
  details: PropTypes.object.isRequired,
  /** seo data change action of SeoSettings. */
  handleSeoDetailsChange: PropTypes.func,
  /** keyword change action of SeoSettings. */
  onSeoKeywordChange: PropTypes.func,
  /** default title of SeoSettings. */
  defaultTitle: PropTypes.string
}

PageSeoSettings.defaultProps = {}

export default PageSeoSettings
