import gql from 'graphql-tag'

export default gql(`
    mutation ($name: String! $channelLogo: ID $isArchived: Boolean $channelCode: String $site: String $defaultTrimStart: Int $defaultTrimEnd: Int) {
        createChannel(
            input: {
                name: $name
                icon: $channelLogo
                channelCode: $channelCode
                isArchived: $isArchived
                site: $site
                defaultTrimStart: $defaultTrimStart
                defaultTrimEnd: $defaultTrimEnd
            }) {
                id
                name                
                channelCode
                isArchived
                site
                masterConfig {
                    id
                    name
                }
                icon{
                    id
                    url
                    fileName
                    tags{
                        id : key
                        name
                    }
                    name
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2
                    }
                    aspectRatio {
                        aspectRatio
                        ratio
                        title
                        resolutions {
                        key
                        width
                        height
                        url
                        }
                    }
                }
                activeConfigGroup{
                    id
                    name
                }
                configGroups {
                    id
                    schemaId{
                        id
                      }
                    position
                    name
                    fields{
                        name
                        value
                        isRequired
                        type
                    }
                    streams{
                        id
                        fields{
                            name
                            value
                            isRequired
                            type
                        }
                        url
                        streamSchema{
                            id
                            levels{
                                id
                                name
                                fields{
                                    name
                                    value
                                    isRequired
                                    type
                                }
                            }
                        }
                    }
                }
                activeConfigGroup{
                    id
                    name
                    streams{
                        id
                        fields{
                            name
                            isRequired
                            type
                            value
                        }
                    }
                    schemaId{
                        id
                        streams{
                            id
                            levels{
                                id
                                fields{
                                    name
                                    isRequired
                                    type
                                }
                            }
                        }
                    }
                }
                programs{
                    previous{
                        id
                        shortId
                        defaultMedia {
                            id
                            url
                            fileName
                            aspectRatio {
                              title
                              aspectRatio
                              resolutions {
                                url
                              }
                            }
                            settings {
                              aspectRatio
                              outputFormat
                              fileName
                              x1
                              y1
                              x2 
                              y2
                            }
                        }
                        program{
                            broadCastStartTime
                            broadCastEndTime
                        }
                            match{
                                id
                                kickOffTime
                                finishTime
                                externalId
                                homeTeam {
                                    id
                                    officialName
                                }
                                awayTeam {
                                    id
                                    officialName     
                                }
                            }
                    }
                    current{
                        id
                        shortId
                        defaultMedia {
                            id
                            url
                            fileName
                            aspectRatio {
                              title
                              aspectRatio
                              resolutions {
                                url
                              }
                            }
                            settings {
                              aspectRatio
                              outputFormat
                              fileName
                              x1
                              y1
                              x2 
                              y2
                            }
                        }
                        program{
                            broadCastStartTime
                            broadCastEndTime
                        }
                            match{
                                id
                                kickOffTime
                                finishTime
                                externalId
                                homeTeam {
                                    id
                                    officialName
                                }
                                awayTeam {
                                    id
                                    officialName     
                                }
                            }
                    }
                    next{
                        id
                        shortId
                        defaultMedia {
                            id
                            url
                            fileName
                            aspectRatio {
                              title
                              aspectRatio
                              resolutions {
                                url
                              }
                            }
                            settings {
                              aspectRatio
                              outputFormat
                              fileName
                              x1
                              y1
                              x2 
                              y2
                            }
                        }
                        program{
                            broadCastStartTime
                            broadCastEndTime
                        }
                            match{
                                id
                                kickOffTime
                                finishTime
                                externalId
                                homeTeam {
                                    id
                                    officialName
                                }
                                awayTeam {
                                    id
                                    officialName     
                                }
                            }
                    }
                }
                defaultTrimStart
                defaultTrimEnd
            }
   }`
)
