import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Icon } from 'antd'
import moment from 'moment'

import PopoverConfirm from './../../feedback/PopoverConfirm'
import Tooltip from '../../dataDisplay/ToolTip'
import EditIcon from '../../general/icons/EditIcon'
import PlayButton from './../../general/buttons/PlayButton'
import { generateCroppedThumbnail, getFormattedDuration } from '../../../../util/util'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import QueryGetVodJobs from '../../../../graphQL/asset/getAssetVodJobs'
// import QueryGetUpdatedVodStatus from '../../../../graphQL/asset/getVodStatus'
const defaultImage = require('../../../../assets/images/default-image-square.jpg')

const vodDetails = {
  'QUEUED': 'Waiting in the queue of pending jobs, starting in under 1 minute.',
  'PROCESSING': 'The asset is being downloaded for processing. which is the longest activity during Live2VOD conversion.',
  'REGISTERED': 'The asset has been ingested successfully in MP VOD, the DRM information needs still to be validated by MP.',
  'MP-PROCESSING': 'The asset has been ingested successfully in MP VOD, the DRM information is being validated by MP.',
  'COMPLETE': 'The  asset has been successfully processed , the DRM information has been validated by MP and extracted by VWM and inserted in the database.',
  'PUBLISHED': 'The  asset has been successfully processed and VCMS has acknowledged the complete status information.',
  'AWAITING AUTO TRIGGER': 'Broadcast end time is over and auto trigger of VOD job will happen within 5 minutes.',
  'AUTO TRIGGER DISABLED': 'Auto trigger is disabled, only manual trigger is possible.'
}
const expiryDay = 1

class ChannelContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showEditIcon: false,
      showModal: false,
      isModalLoading: false
    }
    this.internalTimer = undefined
  }

  componentDidMount = () => {
    if (this.props.startTime) {
      const liveDiff = moment(this.props.startTime).diff(moment(), 'seconds')
      if (liveDiff > 0) {
        this.liveTimeout = setTimeout(this.getStatus, (liveDiff + 1) * 1000)
      }
    }
    if (this.props.endTime) {
      const liveDiff = moment(this.props.endTime).diff(moment(), 'seconds')
      if (liveDiff > 0) {
        this.liveTimeout = setTimeout(this.handleEndOfStream, (liveDiff + 1) * 1000)
      // } else if (!this.props.vodStatus && this.props.isLiveAsset) {
      } else if (liveDiff > -24 * 60 * 60 && this.props.isLiveAsset) {
        this.internalTimer = setInterval(this.fetchVodStatus, 30 * 1000)
      }
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.startTime !== this.props.startTime) {
      const liveDiff = moment(newProps.startTime).diff(moment(), 'seconds')
      if (liveDiff > 0) {
        this.liveTimeout = setTimeout(this.getStatus, (liveDiff + 1) * 1000)
      }
    }
    if (newProps.endTime !== this.props.endTime || newProps.isLiveAsset !== this.props.isLiveAsset) {
      const liveDiff = moment(newProps.endTime).diff(moment(), 'seconds')
      if (liveDiff > 0) {
        this.liveTimeout = setTimeout(this.handleEndOfStream, (liveDiff + 1) * 1000)
      // } else if (!newProps.vodStatus && newProps.isLiveAsset) {
      } else if (liveDiff > -24 * 60 * 60 && newProps.isLiveAsset) {
        this.internalTimer = setInterval(this.fetchVodStatus, 30 * 1000)
      }
    }
    this.getStatus()
  }

  componentWillUnmount = () => {
    clearInterval(this.internalTimer)
    if (this.liveTimeout) {
      clearTimeout(this.liveTimeout)
    }
  }

  handleEndOfStream = () => {
    const { endTime, isLiveAsset } = this.props
    this.getStatus()
    if (moment().isAfter(endTime) && isLiveAsset) {
      this.fetchVodStatus()
      this.internalTimer = setInterval(this.fetchVodStatus, 30 * 1000)
    }
  }

  fetchVodStatus = () => {
    this.setState({ isLoading: true }, () => {
      if (this.props.fetchVodStatus) {
        this.props.fetchVodStatus(this.props.assetId).then(({ data }) => {
          this.setState({ isLoading: false })
          const { vodJobs } = data.getAsset
          if (vodJobs) {
            const isInTransition = (vodJobs || []).findIndex(item => item.type === 'AUTO' && item.status !== 'COMPLETE' && item.status !== 'PUBLISHED' && item.status !== 'ERROR') > -1
            if (isInTransition) {
              clearInterval(this.internalTimer)
            }
          }
        })
      }
    })
  }

  // fetchDirectVodStatus = () => {
  //   if (this.state.isLoading) {
  //     return
  //   }
  //   this.setState({ isLoading: true }, () => {
  //     if (this.props.fetchDirectVodStatus) {
  //       this.props.fetchDirectVodStatus(this.props.vodId).then(({ data }) => {
  //         this.setState({ isLoading: false })
  //         // if (data.getAsset && data.getAsset.vodStatus) {
  //         //   clearInterval(this.internalTimer)
  //         // }
  //       })
  //     } else {
  //       this.fetchVodStatus()
  //     }
  //   })
  // }

  onCloseContent = () => {
    if (this.props.onCloseContent) { this.props.onCloseContent(this.props.id) }
  }

  showImgEditor = () => this.setState({ showEditIcon: true })

  hideImgEditor = () => this.setState({ showEditIcon: false })

  getActiveConfigData = (data) => {
    return (data || []).filter((item) => item.url).map((item) => {
      const { streamSchema } = item
      return streamSchema.levels.map((levelItem) => levelItem.name)
    })
  }

  onPlayClick = () => {
    if (this.props.onPlayClick) { this.props.onPlayClick() }
  }

  getStatus = () => {
    const { startTime, endTime } = this.props
    const currentDate = moment()
    const startDate = moment(startTime)
    const endDate = moment(endTime)
    let isLive = endTime ? (currentDate.isBetween(startDate, endDate) || startDate.format('DD MM YYYY HH:mm') === currentDate.format('DD MM YYYY HH:mm'))
      : moment(currentDate).isSameOrAfter(startDate, 'minute')
    if (isLive !== this.state.isLive) { this.setState({ isLive }) }
  }

  getVodDetails = (vodStatus) => {
    if (vodStatus === 'ERROR') { return this.props.errorStatus ? this.props.errorStatus : 'Unable to fetch error details' } else return vodDetails[vodStatus]
  }

  render () {
    const { parentCompoent, icon, name, startTime, endTime, activeConfig, editProgram, id, source, channelId, isLoadingPlayer, isLiveAsset, assetType, isPlaying, jobList, disableAutoTrigger, isUpdateDisabled } = this.props
    const image = icon ? generateCroppedThumbnail(icon, 100, 100, '1:1') : defaultImage
    const { isLive } = this.state
    const isScheduled = moment().isBefore(moment(startTime))
    const isBroadcastFinished = moment().isAfter(moment(endTime))
    const durationInSec = moment(endTime).diff(moment(startTime), 'seconds')
    const duration = startTime && endTime ? getFormattedDuration(durationInSec) : null
    const configData = activeConfig ? this.getActiveConfigData(activeConfig.streams) : []
    const configDataStringArray = configData.map(item => JSON.stringify(item))
    const isDashPresent = configDataStringArray.indexOf('["DASH"]') > -1
    const isExpired = moment().diff(moment(startTime), 'days') >= expiryDay
    const isAlreadyAutoTriggered = (jobList || []).findIndex(item => item.type === 'AUTO') > -1
    const disableClose = moment().isAfter(startTime)
    // const refreshButton = <div className={`refresh-button ${isLoading ? 'disable' : ''}`} onClick={this.fetchDirectVodStatus}><Icon type={isLoading ? 'loading' : 'reload'} /></div>
    const startAbbr = (moment(startTime).isDST() && !moment(startTime).add(1, 'hours').isDST()) ? '(AEDT)' : (!moment(startTime).isDST() && moment(startTime).subtract(1, 'hours').isDST()) ? '(AEST)' : ''
    const endAbbr = (moment(endTime).isDST() && !moment(endTime).add(1, 'hours').isDST()) ? '(AEDT)' : (!moment(endTime).isDST() && moment(endTime).subtract(1, 'hours').isDST()) ? '(AEST)' : ''

    return <div className='channel-content-container'>
      <div className='img-container' onMouseEnter={this.showImgEditor} onMouseLeave={this.hideImgEditor} >
        <img src={image} />
        {(this.state.showEditIcon && !isUpdateDisabled) ? <div onClick={() => editProgram(id)} className='img-editor-icon'> <EditIcon /> </div> : null}
      </div>
      <div className='channel-content-wrapper'>
        <div className='channel-content-header'>
          {name ? <a className='pink-color' href={location.origin + '/channels/' + channelId} target='_blank'>{name}</a>
            : <span className='not-assigned'>Channel not assigned yet</span>}
          <div className='channel-content-btns'>
            {/* {assetType === 'LINEAR-CHANNEL' ? null : vodStatus ? <div>
                {vodStatus !== 'PUBLISHED' ? refreshButton : null}
              </div> : isBroadcastFinished ? <div>
                {isLiveAsset ? refreshButton : null}
              </div>
                : null } */}

            {/* {(vodStatus && vodStatus !== 'PUBLISHED' && assetType !== 'LINEAR-CHANNEL') || (isBroadcastFinished && isLiveAsset && assetType !== 'LINEAR-CHANNEL') ? refreshButton : null} */}

            {isDashPresent && ((!isExpired && (isBroadcastFinished || isLive)) || assetType === 'LINEAR-CHANNEL') ? <PlayButton isPlaying={isPlaying} isLoading={isLoadingPlayer} onClick={this.onPlayClick}>Play</PlayButton> : null}
            {!disableClose
              ? <div className='cross-icon'>
                <PopoverConfirm confirm={this.onCloseContent} parentCompoent={parentCompoent} warningMessage={'Are you sure you want to delete ?'} />
              </div>
              : null}
          </div>
        </div>
        <div>
          <div className='channel-content-details'>
            {activeConfig ? <div className={`image-info ${!isLiveAsset && isBroadcastFinished ? 'border-less' : ''}`}>
              <p>Active Config Group:</p>
              <Tooltip parentCompoent={parentCompoent} placement={'rightBottom'} details={configData} isList arrowPointAtCenter
                heading={activeConfig.name} childComponent={<p className='pink-color'>{activeConfig.name}</p>} />
            </div> : null}
            {assetType !== 'LINEAR-CHANNEL' && startTime && endTime ? <div className='image-info'>
              <p>Duration:</p>
              <p>{duration}</p>
            </div> : null}
            {source ? <div className={'image-info'}>
              <p>Source:</p>
              <p>{source}</p>
            </div> : null}

            { assetType !== 'LINEAR-CHANNEL' ? <div className='image-info' >
              <p className='broadcast-time'>{`Broadcast Start${startAbbr}:`}</p>
              <p className='broadcast-time-small'>{`BS${startAbbr}:`}</p>
              <p className='date'>{moment(startTime).format('DD MMM YYYY HH:mm')}</p>
            </div> : null }

            {/* {createdAt && assetType !== 'LINEAR-CHANNEL' ? <div className='image-info' >
              <p>VOD Job Start:</p>
              <p className='date'>{moment(createdAt).format('DD MMM YYYY HH:mm')}</p>
            </div> : ''} */}
            { assetType !== 'LINEAR-CHANNEL' && endTime ? <div className={'image-info'}>
              <p className='broadcast-time'>{`Broadcast End${endAbbr}:`}</p>
              <p className='broadcast-time-small'>{`BE${endAbbr}:`}</p>
              <p className='date'>{moment(endTime).format('DD MMM YYYY HH:mm')}</p>
            </div> : null}

            {assetType === 'LINEAR-CHANNEL' ? null
              : isBroadcastFinished && !isAlreadyAutoTriggered
                ? <div className={`image-info`} >
                  {isLiveAsset && !isExpired ? <p>Status:</p> : null}
                  {isLiveAsset && !isExpired ? <div className='status'>
                    <Tooltip parentCompoent={parentCompoent} placement={'rightBottom'} description={this.getVodDetails(disableAutoTrigger ? 'AUTO TRIGGER DISABLED' : 'AWAITING AUTO TRIGGER')} isList arrowPointAtCenter
                      childComponent={<p className={'not-live'}>{ disableAutoTrigger ? 'AUTO TRIGGER DISABLED' : 'AWAITING AUTO TRIGGER'}</p>} />
                  </div> : null}
                </div>
                : isBroadcastFinished && isAlreadyAutoTriggered
                  ? <div className={`image-info`} >
                    <p>Status:</p>
                    <div className='status'>
                      <p className={'not-live'}>{ 'PROGRAM COMPLETE'}</p>
                      {/* <Tooltip parentCompoent={parentCompoent} placement={'rightBottom'} description={this.getVodDetails('COMPLETE')} isList arrowPointAtCenter
                        childComponent={<p className={'not-live'}>{ 'PROGRAM COMPLETE'}</p>} /> */}
                    </div>
                  </div>
                  : <div className={`image-info`}>
                    { isLive || isScheduled ? <p>Status:</p> : null}
                    <div className='status'>
                      <p className={`${isLive === true ? 'live' : 'not-live'}`}>{isLive === true ? 'On Now' : isScheduled ? 'SCHEDULED' : ''}</p>
                      {isLive === true ? <div><span className=''>LIVE</span></div> : null}
                    </div>
                  </div>}
            {/* {updatedAt && assetType !== 'LINEAR-CHANNEL' ? <div className='image-info' >
              <p>VOD Status Last Update:</p>
              <p className='date'>{moment(updatedAt).format('DD MMM YYYY HH:mm')}</p>
            </div> : ''} */}
            {/* : vodStatus ? <div className={`image-info`}>
              <p>Status:</p>
              <div className='status'>
                <Tooltip parentCompoent={parentCompoent} placement={'rightBottom'} description={this.getVodDetails(vodStatus !== 'UNDEFINED' ? vodStatus : 'QUEUED')} isList arrowPointAtCenter
                  childComponent={<p className={'not-live'}>{vodStatus !== 'UNDEFINED' ? vodStatus : 'QUEUED'}</p>} />
              </div>
            </div>  */}
          </div>
        </div>

      </div>
    </div>
  }
}

ChannelContent.propTypes = {
  /** Channel Icon */
  icon: PropTypes.object,
  /** Channel Id */
  id: PropTypes.string,
  /** Channel Name */
  name: PropTypes.string,
  /** Function to be called on delete a channel */
  onCloseContent: PropTypes.func,
  /** Parent id */
  parentCompoent: PropTypes.string,
  /** Program start time  */
  startTime: PropTypes.string,
  /** Program end time */
  endTime: PropTypes.string,
  /** Time duration of the program */
  duration: PropTypes.string,
  /** Active config of the channel */
  activeConfig: PropTypes.object,
  /** Function to be called on edit channel date */
  editProgram: PropTypes.func,
  /** Source of the program */
  source: PropTypes.string,
  /** Channel ID */
  channelId: PropTypes.string,
  /** Asset ID */
  assetId: PropTypes.string,
  /** VOD status */
  vodStatus: PropTypes.string,
  /** VOD job created  time */
  createdAt: PropTypes.string,
  /** VOD job last updated time */
  updatedAt: PropTypes.string
}

export default withApollo(compose(
  graphql(
    QueryGetVodJobs,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project }
        }
      },
      props: (props) => {
        return {
          fetchedVodStatus: props.data.getAsset,
          fetchVodStatus: (id) => {
            return props.data.refetch({ id })
          }
        }
      }
    }
  )
  // graphql(
  //   QueryGetUpdatedVodStatus,
  //   {
  //     skip: ({ vodId }) => {
  //       return !vodId
  //     },
  //     options: ({ vodId }) => {
  //       return {
  //         variables: { id: vodId }
  //       }
  //     },
  //     props: (props) => {
  //       return {
  //         fetchDirectVodStatus: (id) => {
  //           return props.data.refetch({ id })
  //         }
  //       }
  //     }
  //   }
  // )

)(ChannelContent))
