import gql from 'graphql-tag'

export default gql(`
    mutation deleteRating($id: ID!, $scheme: String, $project: String) {
        deleteRating(
                id: $id,
                scheme: $scheme,
                project: $project
        ) {
            id
            scheme
            rating
            subRatings
        }
    }`
)
