import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import { Form,
  Input, Checkbox, message } from 'antd'
import { utilityService } from './../../../../services/UtilityService'
import userMessages from '../../../../constants/messages'
import LoadingButton from './../../general/buttons/LoadingButton'

class LoginForm extends React.Component {
    state = {
      username: '',
      password: '',
      isValidUserName: true,
      isValidPassword: true,
      errorMessage: '',
      isLoading: false,
      disabled: true,
      shouldRember: true
    }

    UNSAFE_componentWillReceiveProps (newProps) { // eslint-disable-line camelcase
      if (newProps.formSuccess) {
        this.setState({ isLoading: false })
      }
    }

    handleSubmit = (e) => {
      e.preventDefault()
      if (this.state.isLoading) {
        return
      }
      this.setState({ isLoading: true })
      let isValidPassword = true; let isValidUserName = true; let errorMessage = ''
      const { username, password, shouldRember } = this.state
      const email = username.toLowerCase()
      if (email && password) {
        isValidUserName = utilityService.checkEmailValidation(email)
        isValidPassword = utilityService.checkPasswordValidation(password)
        if (!isValidUserName && !isValidPassword) {
          errorMessage = userMessages.LOGIN_EMAIL_PASSWORD_VALIDATION_FAILURE
        } else if (isValidUserName && isValidPassword) {
          this.props.onSelect(email, password, shouldRember)
        } else {
          if (isValidUserName) {
            errorMessage = userMessages.LOGIN_PASSWORD_VALIDATION_FAILURE
          } else {
            errorMessage = userMessages.LOGIN_EMAIL_VALIDATION_FAILURE
          }
        }
      } else {
        if (!email) {
          isValidUserName = false
        }
        if (!password) {
          isValidPassword = false
        }
        errorMessage = userMessages.AUTH_EMPTY_FIELD
      }
      if (errorMessage) {
        message.warning(errorMessage)
        this.setState({ isLoading: false })
      }
      this.setState({ isValidPassword, isValidUserName, errorMessage })
    }

    handleLoginformChange = e => {
      const { name, value } = e.target
      this.setState({ [ name ]: value })
    }

    onRemembeMeChange = () => {
      this.setState({ shouldRember: !this.state.shouldRember })
    }

    checkFormStatus = () => {
      if (this.state.disabled) {
        if (this.state.username && this.state.password) {
          this.setState({ disabled: false })
        }
      } else {
        if (!this.state.username || !this.state.password) {
          this.setState({ disabled: true })
        }
      }
    }

    render () {
      const { isValidPassword, isValidUserName, isLoading, disabled, shouldRember } = this.state
      this.checkFormStatus()
      const isHyperion = window.location.href.includes('hyperion')
      const isSubhub = window.location.href.includes('subhub')
      return (
        <div className={`login-form ${isLoading ? 'loading' : ''}`} >
          {isHyperion ? <div className='optus-logo hyperion-logo'><span>HYPERION</span></div>
            : isSubhub ? <div className='optus-logo hyperion-logo'><span>SUBHUB</span></div>
              : <img className='optus-logo' src={require('./../../../../assets/images/optus-logo.png')} />}
          <span>SIGN IN</span>
          <div className='border-line' />
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label='Email' >
              <Input className={`email ${!isValidUserName ? 'error' : ''}`} placeholder='Enter your email' name='username' onChange={this.handleLoginformChange} value={this.state.username} />
            </Form.Item>
            <Form.Item label='Password'>
              <Input className={`password ${!isValidPassword ? 'error' : ''}`} type='password' name='password' placeholder='Enter your password' value={this.state.password} onChange={this.handleLoginformChange} />
            </Form.Item>
            <Form.Item>
              <LoadingButton type='primary' htmlType='submit' buttonText='Sign In' buttonClass='login-form-button' isLoading={isLoading} isDisabled={disabled} />
              <div className='login-form-down'>
                <Checkbox checked={shouldRember} onChange={this.onRemembeMeChange}>Stay signed in</Checkbox>
                <a className='login-form-forgot' onClick={this.props.onForgotClick}>Forgot password?</a>
              </div>
            </Form.Item>
          </Form>
        </div>
      )
    }
}

LoginForm.propTypes = {
  /** Submit action of LoginForm. */
  onSelect: PropTypes.func.isRequired,
  /** forgot email link click action of LoginForm. */
  onForgotClick: PropTypes.func.isRequired
}

LoginForm.defaultProps = {
}

export default LoginForm
