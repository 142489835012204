import React from 'react'
import PropTypes from 'prop-types'

const SwapIcon = ({ width, height, disabled, onClick, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={disabled ? () => {} : onClick}>
    <g fill='none' fillRule='evenodd'>
      <path d='M2 2h15v15H2z' />
      <g fill={disabled ? '#cfd0d1' : '#343A40'} fillRule='nonzero'>
        <path d='M19.712 5.02c.384-.288.384-.935 0-1.224L13.528-.05A1 1 0 0 0 12 .8V2H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h11v1.164a1 1 0 0 0 1.542.84l6.17-3.984zM.288 16.02c-.384-.288-.384-.935 0-1.224l6.184-3.846A1 1 0 0 1 8 11.8V13h11a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H8v1.164a1 1 0 0 1-1.542.84L.288 16.02z' />
      </g>
    </g>
  </svg>
)

SwapIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

SwapIcon.defaultProps = {
  width: 20,
  height: 20
}

export default SwapIcon
