import gql from 'graphql-tag'

export default gql(`
    mutation batchUpdateUserGroups($userInput: [CreateUserInput] $project: String) {
        batchUpdateUserGroups(
            input: $userInput
            project: $project
        ){
            id
            accessRoleGroups {
              id
              name
              modules{
                name
                permissions
              }
            }
        }
    }
`)
