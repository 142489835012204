import React from 'react'
import { Tree, message } from 'antd'

import GroupCompetitionIcon from '../../components/ui/general/icons/GroupCompetitionIcon'
import GroupDragAndDropIcon from '../../components/ui/general/icons/GroupDragAndDropIcon'
// import bucketTypes from './../../constants/bucket-group-types'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryGetBucketGroupList from './../../graphQL/bucket/getBucketGroupList'
import QueryGetBucketGroupType from './../../graphQL/admin/bucket/listBucketGroupType'
import MutationUpdateBucketGroup from './../../graphQL/bucket/updateBucketGroup'
import SubscriptionBucketGroupList from '../../graphQL/bucket/createBucketGroupSubscription'
// import SubscriptionUpdateBucketGroupList from '../../graphQL/bucket/updateBucketGroupSubscription'
import userMessages from './../../constants/messages'
import { utilityService } from '../../services/UtilityService'

const { TreeNode } = Tree

class BucketGroupList extends React.Component {
  constructor (props) {
    super(props)
    this.defaultPages = _.cloneDeep(props.bucketGroupTypes)
    let pages = []
    if (props.bucketGroupList && props.bucketGroupList.length) {
      pages = this.setBucketGroup(props.bucketGroupList)
    }
    this.state = {
      defaultExpandAll: true,
      pages
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.bucketGroupList && newProps.bucketGroupList.length && !_.isEqual(newProps.bucketGroupList, this.props.bucketGroupList)) {
      const pages = this.setBucketGroup(newProps.bucketGroupList)
      this.setState({ pages })
    }
    if (newProps.bucketGroupTypes && newProps.bucketGroupTypes.length && !_.isEqual(newProps.bucketGroupTypes, this.props.bucketGroupTypes)) {
      this.defaultPages = _.cloneDeep(newProps.bucketGroupTypes)
      const pages = this.setBucketGroup(newProps.bucketGroupList)
      this.setState({ pages })
    }
  }

  componentDidMount () {
    this.props.subscribeToNewBucketGroup()
    // this.props.subscribeToChangeBucketGroup()
  }

  setBucketGroup = bucketGroups => {
    let pages = (this.defaultPages || []).sort((a, b) => a.position - b.position)
    for (let i = 0; i < this.defaultPages.length; i++) {
      const type = this.defaultPages[i].id
      let pageBucketGroups = cloneDeep(bucketGroups).filter(item => item.type === type)
      pageBucketGroups = pageBucketGroups.map(item => {
        item.bucketGroupItems = (item.bucketGroupItems || []).filter(item => !item.bucket.isArchived)
        return item
      })
      pages[i].bucketGroups = pageBucketGroups
    }
    return pages
  }

  onDragEnter = () => {
  }

  onDrop = (info) => {
    const dropPos = info.node.props.pos.split('-')
    const dragPos = info.dragNode.props.pos.split('-')
    if (dropPos.length === dragPos.length) {
      const isSameBucket = _.isEqual([...dropPos].splice(0, 3).join(''), [...dragPos].splice(0, 3).join(''))

      if (!isSameBucket) {
        return
      }
      const { pages } = this.state
      const contents = pages[dropPos[1]].bucketGroups[dropPos[2]].bucketGroupItems
      const endIndex = parseInt([...dropPos].splice(3, 1))
      const startIndex = parseInt([...dragPos].splice(3, 1))

      const result = Array.from(contents)
      const [removed] = result.splice(startIndex, 1)
      const isDecreasing = endIndex < startIndex
      const largerIndex = isDecreasing ? startIndex : endIndex
      const smallerIndex = isDecreasing ? endIndex : startIndex
      const modifiedResult = cloneDeep(result).map((item, index) => {
        if (index >= smallerIndex && index < largerIndex) {
          item.position = item.position + (isDecreasing ? 1 : -1)
        }
        return item
      })
      removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : (result[endIndex].position - 1)
      modifiedResult.splice(endIndex, 0, removed)
      pages[dropPos[1]].bucketGroups[dropPos[2]].bucketGroupItems = modifiedResult
      this.setState({ pages }, () => this.updateBucketGroups(pages[dropPos[1]].bucketGroups[dropPos[2]].id, modifiedResult, true))
    } else {

    }
  }

  bucketGroupSelected = ([value]) => {
    const { pages } = this.state
    const { selectedBucketGroup } = this.props
    let currentBucketGroup
    for (let i = 0; i < pages.length; i++) {
      const bucketGroups = pages[i].bucketGroups
      const currentBucketGroupIndex = bucketGroups.findIndex(item => item.id === selectedBucketGroup)
      if (currentBucketGroupIndex > -1) {
        currentBucketGroup = bucketGroups[currentBucketGroupIndex]
        break
      }
    }
    for (let i = 0; i < pages.length; i++) {
      const bucketGroups = pages[i].bucketGroups
      const selectedIdIndex = bucketGroups.findIndex(item => item.id === value)
      if (selectedIdIndex > -1) {
        const selectedId = bucketGroups[selectedIdIndex].id
        this.setState({ selectedBucketId: '' })
        this.props.onSelectBucketGroup(selectedId)
        break
      } else {
        let bucketId
        const selectedIdIndex = bucketGroups.findIndex(item => {
          if (item.bucketGroupItems) {
            const index = item.bucketGroupItems.findIndex(bucketItem => bucketItem.id === value)
            if (index > -1) {
              bucketId = item.bucketGroupItems[index].bucket.id
              return true
            }
          }
          return false
        })
        if (selectedIdIndex > -1) {
          const isSameBucketGroup = (currentBucketGroup.bucketGroupItems || []).findIndex(bucket => bucket.bucketId === bucketId) > -1
          this.props.onSelectBucketGroup(bucketGroups[selectedIdIndex].id, isSameBucketGroup, bucketId)
          this.setState({ selectedBucketId: value })
        }
      }
    }
  }

  updateBucketGroups = (id, bucketGroupItems, shouldShowMessage) => {
    const request = bucketGroupItems.map((item, index) => {
      return { id: item.id, position: index }
    })
    this.props.updateBucketGroup(request, id).then(data => {
      if (shouldShowMessage) {
        message.success(userMessages.SUCCESS_UPDATE_BUCKET_GROUP)
      }
    }, error => {
      utilityService.handleError(error)
    })
  }

  render () {
    const { pages, defaultExpandAll, selectedBucketId } = this.state
    const { selectedBucketGroup } = this.props
    const tree = (
      pages.map(page => {
        return <TreeNode icon={<GroupCompetitionIcon />} key={page.id} title={page.name} selectable={false} >
          { page.bucketGroups.map(bucketGroup => {
            return <TreeNode className='bucket-group' key={bucketGroup.id} title={bucketGroup.name} >
              {(bucketGroup.bucketGroupItems || []).sort((a, b) => a.position > b.position).map(bucket => {
                return <TreeNode className={`bucket ${selectedBucketId === bucket.id ? 'selected' : ''}`} icon={<GroupDragAndDropIcon />} key={bucket.id} title={bucket.bucket.name} />
              })}
            </TreeNode>
          })}
        </TreeNode>
      })
    )

    return (
      <div className='bucket-group-list'>
        <Tree
          defaultExpandAll={defaultExpandAll}
          className='draggable-tree'
          selectedKeys={[selectedBucketGroup]}
          draggable
          blockNode
          onDragEnter={this.onDragEnter}
          onDrop={this.onDrop}
          onSelect={this.bucketGroupSelected}
          showIcon
        >
          {tree}
        </Tree>
      </div>
    )
  }
}

export default withApollo(compose(
  graphql(
    QueryGetBucketGroupList,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 300, project }
        }
      },
      props: (props) => {
        const bucketGroupList = props.data && props.data.listBucketGroups ? props.data.listBucketGroups.items : []
        return {
          bucketGroupList,
          subscribeToNewBucketGroup: () => {
            return props.data.subscribeToMore({
              document: SubscriptionBucketGroupList,
              updateQuery: (previous, { subscriptionData: { data: { bucketGroupCreated } } }) => {
                const { project } = props.ownProps
                if (!bucketGroupCreated || (project !== bucketGroupCreated.project)) return previous
                const prev = cloneDeep(previous)
                const index = prev.listBucketGroups.items.findIndex(group => group.id === bucketGroupCreated.id)
                if (index !== -1) {
                  prev.listBucketGroups.items.splice(index, 1)
                }
                const newList = [ bucketGroupCreated, ...prev.listBucketGroups.items ]
                prev.listBucketGroups.items = newList
                // prev.listBucketGroups.totalCount = prev.listBucketGroups.totalCount + 1
                return prev
              }
            })
          }
          // subscribeToChangeBucketGroup: () => {
          //   return props.data.subscribeToMore({
          //     document: SubscriptionUpdateBucketGroupList,
          //     updateQuery: (prev, { subscriptionData: { data: { bucketGroupUpdated } } }) => {
          //       if (!bucketGroupUpdated || !bucketGroupUpdated.isArchived) return prev
          //         const index = prev.listBucketGroups.items.findIndex(group => group.id === bucketGroupUpdated.id)
          //         debugger
          //         if (index !== -1) {
          //           prev.listBucketGroups.items.splice(index, 1)
          //         }
          //       return prev
          //     }
          //   })
          // }
        }
      }
    }
  ),
  graphql(
    QueryGetBucketGroupType,
    {
      options: ({ project }) => {
        return {
          variables: { limit: 50, project },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const bucketGroupTypes = props.data && props.data.listBucketGroupType ? props.data.listBucketGroupType.items : []
        return {
          bucketGroupTypes
        }
      }
    }
  ),
  graphql(
    MutationUpdateBucketGroup,
    {
      props: (props) => ({
        updateBucketGroup: (updateData, id) => {
          return props.mutate({
            variables: { updateData, id, project: props.ownProps.project }
          })
        }
      })
    })
)(BucketGroupList))
