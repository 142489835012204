import gql from 'graphql-tag'

export default gql(`
    mutation updateContentPlaceHolder($name: String! $key: String! $id: ID! $project: String $metaFields: [CreateFieldInput] ) {
        updateContentPlaceHolder(
            input: {
                id: $id
                name: $name
                key: $key
                metaFields: $metaFields
            }
            project: $project
        ) {
            id
            name
            key
            usedCount
            metaFields{
                id
                tooltip
                displayName
                displayTitle
                type
                isRequired
                values
            }
        }
    }`
)
