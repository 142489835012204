import React, { Component } from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'

import AppContext from './../../../AppContext'

import OptaSettingTable from '../../../components/ui/dataDisplay/OptaSettingTable'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import EditOptaSettingModal from '../../../components/ui/dataEntry/other/EditOptaSettingModal'

import QueryOptaSyncStates from '../../../graphQL/admin/listOptaSyncStates'
import { utilityService } from '../../../services/UtilityService'
class OptaSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      optaSyncStates: props.optaSyncState && props.optaSyncState.length ? props.optaSyncState : [],
      loading: true,
      isAddOptaSync: false
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.optaSyncState) {
      this.setState({ loading: newProps.loading })
    }
    if (newProps.optaSyncState && !_.isEqual(newProps.optaSyncState, this.props.optaSyncState)) {
      this.setState({ optaSyncStates: newProps.optaSyncState, loading: newProps.loading })
      const isRunning = (newProps.optaSyncState || []).findIndex(item => item.isRunning || item.isError === null) > -1
      if (isRunning && !this.setInterval) {
        this.refetchData()
      }
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.setInterval)
  }

  refetchData = () => {
    if (this.setInterval) {
      clearInterval(this.setInterval)
    }
    this.setInterval = setInterval(() => {
      this.refetchOptaSettings()
    }, 15000)
  }

  refetchOptaSettings = () => {
    this.props.refetchOptaSettings().then(({ data }) => {
      const optaList = data.listOptaSyncStates.items
      const isRunning = (optaList || []).findIndex(item => item.isRunning || item.isError === null) > -1
      if (!isRunning) {
        clearInterval(this.setInterval)
      }
    }, error => {
      utilityService.handleError(error)
    })
  }

  onAddOptaSync = () => {
    this.setState({ isAddOptaSync: true })
  }

  hideEditSettingModal = () => {
    this.setState({ isAddOptaSync: false })
  }

  render () {
    const { optaSyncStates, loading, isAddOptaSync } = this.state
    const { onHistoryUpdate } = this.props
    return (
      <AppContext.Consumer>
        {({ permissions }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isEditDisabled = userPermissions.indexOf('UPDATE') === -1
          return <React.Fragment>
            <div className='opta-setting'>
              <OptaSettingTable optaSyncStates={optaSyncStates} loading={loading} isCreateDisabled={isCreateDisabled} isEditDisabled={isEditDisabled} onHistoryUpdate={onHistoryUpdate} refetchData={this.refetchData} />
            </div>
            <div className='data-bottom'>
              <LoadingButton
                type='primary'
                onClick={this.onAddOptaSync}
                htmlType='submit'
                buttonText={'New'}
                buttonClass='save-btn'
                isLoading={false}
                isDisabled={isCreateDisabled}
              />
              <EditOptaSettingModal visible={isAddOptaSync} handleCancel={this.hideEditSettingModal} title={'ADD OPTA SYNC SETTINGS'} okText={'Save'} isAdd isSubmitDisabled={isCreateDisabled} onHistoryUpdate={onHistoryUpdate} />
            </div>
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

OptaSetting.propTypes = {}

export default withApollo(compose(
  graphql(
    QueryOptaSyncStates,
    {
      options: () => {
        return {
          variables: { offset: 0 },
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        let optaSyncState = data.listOptaSyncStates ? [ ...data.listOptaSyncStates.items ] : []
        return {
          optaSyncState,
          loading: data.loading,
          refetchOptaSettings: () => {
            return props.data.refetch()
          }
        }
      }
    }
  )

)(OptaSetting))
