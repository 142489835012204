import React, { Component } from 'react'
import { Table, Modal, Empty, Button } from 'antd'
import { Resizable } from 'react-resizable'

import PropTypes from 'prop-types'
import moment from 'moment'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import Tooltip from './ToolTip'

import QueryListPaymentTransaction from '../../../graphQL/customers/listPaymentTransaction'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

class TransactionModal extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Billing Item ID',
        dataIndex: 'billingItemId',
        key: 'billingItemId',
        width: 100
      },
      {
        title: 'IT Charge ID',
        dataIndex: 'itChargeId',
        width: 100,
        key: 'itChargeId'
      },
      {
        title: 'Subscription Start Date',
        dataIndex: 'subscriptionStartDate',
        key: 'subscriptionStartDate',
        width: 100,
        render: (subscriptionStartDate) => <div>{subscriptionStartDate ? moment(subscriptionStartDate).format('DD MMM YYYY') : '-'}</div>
      },
      {
        title: 'Subscription End Date',
        dataIndex: 'subscriptionEndDate',
        key: 'subscriptionEndDate',
        width: 100,
        render: (subscriptionEndDate) => <div>{subscriptionEndDate ? moment(subscriptionEndDate).format('DD MMM YYYY') : '-'}</div>
      },
      {
        title: 'Actual Charge Date',
        dataIndex: 'actualChargeDate',
        key: 'actualChargeDate',
        width: 100,
        render: (actualChargeDate) => <div>{actualChargeDate ? moment(actualChargeDate).format('DD MMM YYYY') : '-'}</div>
      },
      {
        title: 'Expected Charge Date',
        dataIndex: 'expectedChargeDate',
        key: 'expectedChargeDate',
        width: 100,
        render: (expectedChargeDate) => <div>{expectedChargeDate ? moment(expectedChargeDate).format('DD MMM YYYY') : '-'}</div>
      },
      {
        title: 'Payment Status',
        dataIndex: 'paymentStatus',
        width: 100,
        key: 'paymentStatus'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 100
      },
      {
        title: 'IT Error Payload',
        dataIndex: 'latestITErrorPayload',
        key: 'latestITErrorPayload',
        width: 100,
        render: this.errorPayload
      }
    ]

    this.state = {
      width: null,
      height: null,
      columns: this.columns,
      transactions: null
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

      componentDidMount = () => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }

      UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
        if (newProps.isActive !== this.props.isActive) {
          setTimeout(() => {
            this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
          }, 100)
        }
        if (newProps.transactions && !_.isEqual(newProps.transactions, this.state.transactions)) {
          this.setState({ transactions: _.cloneDeep(newProps.transactions) })
        }
      }

      errorPayload = (latestITErrorPayload) => {
        if (latestITErrorPayload) {
          const details = latestITErrorPayload || 'None'
          return <Tooltip
            childComponent={<div className='general-text'>View</div>}
            description={details}
            placement='leftTop'
            isList
          />
        } else {
          return <span className='no-data-text' >None</span>
        }
      }

      renderShared=(shared) => {
        return <span>{shared ? 'True' : 'False'}</span>
      }

      handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
          const nextColumns = [...columns]
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width
          }
          return { columns: nextColumns }
        })
      }

      renderTransactionsData=() => {
        const { width, height, transactions } = this.state
        const { columns } = this.state

        const column = columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: this.handleResize(index)
          })
        }))
        return <div className='general-customer-details-table' ref={this.tableSize}>
          { !_.isEmpty(transactions) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={transactions} pagination={false} scroll={{ x: width, y: (height) }} />
            : <div className='empty-container'> <Empty /> </div>}
        </div>
      }

      renderLoading = () => {
        return <div className='loading-container'>
          <Button loading className='loading-button' />
        </div>
      }

      handleCancel=() => {
        this.setState({ transactions: null })
        this.props.handleCancel()
      }

      render () {
        const { isVisible, loading } = this.props
        const { transactions } = this.state
        // const { columns } = this.state

        // const column = columns.map((col, index) => ({
        //   ...col,
        //   onHeaderCell: column => ({
        //     width: column.width,
        //     onResize: this.handleResize(index)
        //   })
        // }))
        return (
          <div ref={this.tableSize}>
            <Modal
              className='general-modal services-details transaction-modal'
              title={'Payment Transaction'}
              maskClosable={false}
              visible={isVisible}
              closable={false}
              centered
              destroyOnClose
              width={'1000px'}
              footer={[
                <Button key='back' onClick={this.handleCancel}>
                  Close
                </Button>]}
            >

              {loading && transactions === null ? this.renderLoading() : this.renderTransactionsData()}

            </Modal>
          </div>
        )
      }
}

TransactionModal.propTypes = {
  /** visibility toggle for Modal */
  isVisible: PropTypes.bool,
  /** function to handle Back in Modal */
  onCancel: PropTypes.func

}

export default withApollo(compose(
  graphql(
    QueryListPaymentTransaction,
    {
      skip: ({ isVisible, subscriptionId }) => !isVisible || !subscriptionId,
      options: (props) => {
        const { subscriptionId } = props
        const variables = { subscriptionId }
        return {
          fetchPolicy: 'no-cache',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const transactions = data && data.adminListPaymentTransactions ? data.adminListPaymentTransactions : []
        const loader = data && data.loading
        return { transactions, loading: loader }
      }
    }
  )
)(TransactionModal))
