import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $markInTime: AWSDateTime $markOutTime: AWSDateTime $site: String $drmRequired: Boolean $project: String) {
        updateAsset(
            id: $id
            project: $project
            input: {
                id: $id
                site: $site
                markInTime: $markInTime
                drmRequired: $drmRequired
                markOutTime: $markOutTime
            }
        ) {
            id
            shortId
            externalId
            type
            title
            shortTitle
            description
            shortDescription
            updatedAt
            updatedBy
            createdBy
            createdAt
            publishStartDate
            publishEndDate
            isArchived
            status
            seoTitle
            slug
            seoKeywords
            seoMetaDescription
            isLive
            isFree
            duration
            isInTransition
            markInTime
            markOutTime
            drmRequired
            site
            source
            relatedAssets{
                id
            }
            vodJobs{
                id
                status
                site
                name
                type
                jobType
                vwmJobId
                updatedAt
                createdAt
                createdBy
                progress
                errorDesc
                startTime
                endTime 
                channel{
                    id
                }
                mediaId{
                    id
                    duration
                }  
            }
            category{
                id
                name
                slug
            }
            defaultVideo{
                id
            }
            match{
                id
                kickOffTime
                matchDate
                opId
                finishTime
                externalId
                isLiveOnly
                competition{
                    id
                    title
                }
                homeTeam {
                    id
                    opId
                    code
                    officialName 
                    media {
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2          
                        }
                        vodStatus{
                            id
                        }
                    }
                }
                awayTeam {
                    id
                    opId
                    code
                    officialName   
                    media {
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2          
                        }
                        vodStatus{
                            id
                        }
                    }     
                }
            }
            program{
                id
                source
                isLiveTelecast
                channel{
                    id
                    name
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                    masterConfig{
                        id
                    }
                    configGroups{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    activeConfigGroup{
                        id
                        name
                        streams{
                            url
                            fields{
                                name
                                value
                                isRequired
                                type
                            }
                            streamSchema{
                                id
                                levels{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    defaultTrimStart
                    defaultTrimEnd
                }
                broadCastStartTime
                broadCastEndTime
            }
            author {
                id
                name
                media {
                    id
                    
                    fileName
                    aspectRatio {
                        aspectRatio
                        ratio
                        title          
                        resolutions {
                        url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2
                        y2          
                    }
                }
            }
            media{
                id
                fileName
                createdAt
                name
                type
                fileSize
                duration
                thumbnails
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                drmContent
                streams{
                    id
                    contentKey
                    Url
                    drm
                    iv 
                    codec
                    transport
                    drmEntitlementMessage
                    channel {
                        id
                    }
                    PackagingConfigurationId
                }
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
                _originalResponseBody
            }
            images{
                id
                assetId
                mediaId
                isDefault
                fileName
                createdAt
                name
                type{
                    name
                    id
                }
                fileSize
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2
                }
                dimensions{
                    x1
                    x2
                    y1
                    y2
                }
                aspectRatio {
                    aspectRatio
                    ratio
                    title
                    resolutions {
                    key
                    width
                    height
                    url
                    }
                }
                
            }
            videos{
                id
                assetId
                mediaId
                fileName
                createdAt
                name
                fileSize
                cuePoints
                adPositions
                breakDuration
                cuePointsError
                tags{
                    key
                    name
                    type
                }
                category{
                    id
                    label
                    type
                }
                type
                vodStatus{
                    id
                    startTime
                    endTime
                    channel{
                        id
                        icon{
                            id
                            fileName
                            aspectRatio {
                                aspectRatio
                                ratio
                                title          
                                resolutions {
                                url
                                }
                            }
                            settings {
                                aspectRatio
                                outputFormat
                                fileName
                                x1
                                y1
                                x2
                                y2
                            }
                        }
                    }
                }
                drmContent
                streams{
                    id
                    contentKey
                    Url
                    drm
                    iv 
                    codec
                    transport
                    drmEntitlementMessage
                    channel {
                        id
                    }
                    PackagingConfigurationId
                }
                duration
                thumbnails
                isDefault
                _originalResponseBody
            }
            tags {
                key
                type
                name
            }
            defaultMedia {
                id
                fileName
            }
            contents {
                id
                position
                value
                type
                caption
                metaFields{
                    id
                    tooltip
                    fieldID
                    displayName
                    type
                    isRequired
                    values
                    value
                    fieldValues
                }
                media{
                    id
                    fileName
                    name
                    type
                    fileSize
                    tags{
                        key
                        name
                        type
                    }
                    settings {
                        fileName
                        aspectRatio
                        fileName
                        x1
                        y1
                        x2
                        y2
                    }
                    dimensions{
                        x1
                        x2
                        y1
                        y2
                    }
                    aspectRatio {
                        aspectRatio
                        title
                        resolutions {
                            key
                            width
                            height
                            url
                        }
                    }
                    vodStatus{
                        id
                    }
                }
            }
            meta{
                displayName
                type
                value
                isRequired
            }
            ratings {
                id
                rating
                scheme
                subRatings
                position
                source
            }
            originalAudio
            audioLanguages
            originalTitle
            subtitleLanguages
            closedCaption
            releaseDateTime
            productionDateTime
            averageRating
            expectedNumberOfSeasons
            productionStudio {
                id
                name
            }
            genres {
                id
                name
            }
            countryOfOrigin
            seasonNumber
            episodeNumber
            episodes{
                assetId
                isArchived
                shortId
                externalId
                title
                type
                updatedAt
                publishStartDate
                publishEndDate
                status
                isFree
                seasonNumber
                episodeNumber
                series{
                    id
                    assetId
                    title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                    id
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                        url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
            }
            seasons{
                assetId
                isArchived
                shortId
                externalId
                title
                type
                updatedAt
                publishStartDate
                publishEndDate
                status
                isFree
                seasonNumber
                episodeNumber
                series{
                    id
                    assetId
                    title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                    id
                    fileName
                    aspectRatio {
                        title
                        aspectRatio
                        resolutions {
                        url
                        }
                    }
                    settings {
                        aspectRatio
                        outputFormat
                        fileName
                        x1
                        y1
                        x2 
                        y2
                    }
                }
            }
            mediumDescription
            videoDuration
            credits{
                id
                position
                source
                person{
                    id
                    name
                    image {
                        id
                        name
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                              url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2          
                        }
                    }
                }
                role{
                id
                name
                }
                type
                characterName
            }
            series{
                id
                assetId
                title
            }
            season{
                id
                assetId
                title
            }
            productionStudios{
                id
                name
            }
            yearOfRelease
            episodeNumberInSeries
            colorCode
            countries {
                id 
                name
            }
            seoCanonicalUrl 
        }
    }`
)
