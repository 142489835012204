import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PopoverConfirm from './../../feedback/PopoverConfirm'
import EditIcon from '../../general/icons/EditIcon'
import PlayButton from './../../general/buttons/PlayButton'
import './../../ui.style.scss'

import { generateCroppedThumbnail, getFileSize, getFormattedDuration } from './../../../../util/util'
import defaultImage from './../../../../assets/images/default-image-square.jpg'

class VideoContent extends React.Component {
    state = { showEditIcon: false, showEditModal: false }

    showVideoEditor = () => {
      this.setState({ showEditIcon: true })
    }

    hideVideoEditor = () => this.setState({ showEditIcon: false })

    onPlayClick = () => {
      this.props.onPlayClick('video', this.props.videoDetails.id)
    }

    makeDisplayVideo = () => {
      const { videoDetails } = this.props
      this.props.makeDisplayVideo(videoDetails.id)
    }

    render () {
      // const isWatching = false
      // const { videoDetails, parentCompoent, onCloseContent, isLoadingPlayer, isDefaultVideo } = this.props
      const { videoDetails, isLoadingPlayer, isDefaultVideo, isPlaying, onCloseContent, parentCompoent, showEditVideo, isDeleteDisabled, cuePoints, adPositions, breakDuration } = this.props
      let markIn; let markOut; let duration = videoDetails.duration

      let displayImage = defaultImage
      if (videoDetails && videoDetails.thumbnails && videoDetails.thumbnails.length) {
        displayImage = videoDetails.thumbnails[0]
      }
      if (videoDetails && videoDetails.vodStatus) {
        if (videoDetails.vodStatus.channel && videoDetails.vodStatus.channel.icon) {
          displayImage = generateCroppedThumbnail(videoDetails.vodStatus.channel.icon, 120, 120, '1:1')
        }
        markIn = videoDetails.vodStatus.startTime
        markOut = videoDetails.vodStatus.endTime
        if (markOut && markIn) {
          duration = moment(markOut).diff(markIn, 'seconds')
        }
      }
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const streamType = isSafari ? 'FAIRPLAY' : 'CENC'
      const isPlayable = videoDetails.streams && videoDetails.streams.length && videoDetails.streams.filter(item => item).find(stream => (stream.drm === (!isSafari ? 'CLEAR' : streamType) || stream.drm === (!isSafari ? 'CENC' : streamType)))
      const formattedDuration = duration ? getFormattedDuration(duration) : '-'
      const fileSize = videoDetails.fileSize ? getFileSize(videoDetails.fileSize) : ''
      const formattedCuePoints = (cuePoints || '').replace(/[, ]+/g, ',').replace(/[,]+/g, ', ')
      const formattedBreakDuration = (breakDuration || '').replace(/[, ]+/g, ',').replace(/[,]+/g, ', ')
      const formattedAdPositions = (adPositions || '').replace(/[, ]+/g, ',').replace(/[,]+/g, ', ')
      return (
        <div className='video-content' >
          <div className='video-container' onMouseEnter={this.showVideoEditor} onMouseLeave={this.hideVideoEditor}>
            <img src={displayImage} />
            { (this.state.showEditIcon && !isDeleteDisabled) ? <div onClick={() => showEditVideo(videoDetails.id, videoDetails)} className='video-editor-icon'> <EditIcon /> </div> : null }
          </div>
          <div className='video-content-wrapper'>
            <div className='video-content-header'>
              <p>{ videoDetails.name }</p>
              <div className='video-content-btns'>
                { isPlayable ? <PlayButton isPlaying={isPlaying} isLoading={isLoadingPlayer} onClick={this.onPlayClick}>Play</PlayButton> : null }
                { isPlayable && !isDeleteDisabled ? <span className='line-separator' /> : null}
                {isDeleteDisabled ? null : <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />}
              </div>
            </div>
            <div className='video-content-details'>

              {/* <div className='video-info' >
              <p>Original File Size:</p>
              <p>{videoDetails.size}</p>
            </div>

            <div className='video-info'>
              <p>Category:</p>
              <p>{videoDetails.category }</p>
            </div>

            <div className='video-info'>
              <p>Original Duration:</p>
              <p>{ videoDetails.duration }</p>
            </div>

            <div className='video-info'>
              <p>Trimmed Duration:</p>
              <p>{ videoDetails.trimmedDuration }</p>
            </div>

            <div className='video-info'>
              <p>Mark In:</p>
              <p>{ videoDetails.markIn }</p>
            </div>

            <div className='video-info'>
              <p>Mark Out:</p>
              <p>{ videoDetails.markOut }</p>
            </div>

            <div className='video-resolution-detail' >
              <p>Configurations:
                <span>{ videoDetails.channels}</span>
              </p>
            </div> */}
              {fileSize ? <div className='video-info' >
                <p>Original File Size</p>
                <p>{fileSize}</p>
              </div> : ''}
              {markIn ? <div className='video-info' >
                <p>Mark In</p>
                <p>{moment(markIn).format('DD MMM YYYY HH:mm:ss') }</p>
              </div> : ''}
              {markOut ? <div className='video-info' >
                <p>Mark Out</p>
                <p>{moment(markOut).format('DD MMM YYYY HH:mm:ss') }</p>
              </div> : ''}
              {videoDetails.category ? <div className='video-info'>
                <p>Category</p>
                <p>{videoDetails.category.label }</p>
              </div> : ''}
              <div className='video-info'>
                <p>Original Duration</p>
                <p>{formattedDuration }</p>
              </div>
              {(videoDetails.drmContent !== null) ? <div className='video-info' >
                <p>DRM Enabled</p>
                <p>{ (videoDetails.drmContent + '').toUpperCase() }</p>
              </div> : ''}
              {videoDetails.createdAt ? <div className='video-info' >
                <p>Created At</p>
                <p>{ moment(videoDetails.createdAt).format('DD MMM YYYY HH:mm:ss') }</p>
              </div> : ''}
            </div>
            <div className='ad-position-container'>
              <div className='video-info'>
                <p>AD Positions</p>
                <p>{formattedAdPositions || 'No AD Markers' }</p>
              </div>
              <div className='video-info'>
                <p>Cue Points</p>
                <p>{formattedCuePoints || 'No Cue Points' }</p>
              </div>
              <div className='video-info'>
                <p>Break Duration</p>
                <p>{formattedBreakDuration || 'No Break Duration' }</p>
              </div>
            </div>
            <div className='video-content-footer'>
              {/* <p className='default-video-configurations'>Configurations: </p> */}
              <p className={`default-video-status ${isDefaultVideo ? 'default' : ''}`} onClick={this.makeDisplayVideo}>{ isDefaultVideo ? 'Default Video' : 'Make Default Video' } </p>
            </div>
          </div>

          {/* <div className='play-button' >
            <Button
              buttonText={isWatching ? 'Watch' : 'Watching'}
              color={isWatching ? 'red' : 'brown-gray'}
              onClick={() => { }}
              style={{ width: '100px' }}
            />

          </div> */}
        </div >
      )
    }
}

VideoContent.propTypes = {
  /** parent component of VideoContent. */
  parentCompoent: PropTypes.string,
  /** videoDetails of VideoContent. */
  videoDetails: PropTypes.object,
  /** close action of VideoContent. */
  onCloseContent: PropTypes.func,
  /** edit video action callback of VideoContent. */
  showEditVideo: PropTypes.func,
  /** Boolean for deisable delete functionality */
  isDeleteDisabled: PropTypes.bool
}

VideoContent.defaultProps = {
  imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Football_iu_1996.jpg',
  parentCompoent: 'root'
}

export default VideoContent
