import gql from 'graphql-tag'

export default gql(`
    mutation deleteAssetImages($deletedId: [ID]! $assetId: ID! $project: String) {
        deleteAssetImages(
            input:  $deletedId,
            assetId: $assetId,
            project: $project
        )
    }`
)
