import React from 'react'
import PropTypes from 'prop-types'
import DefaultImageIcon from '../icons/DefaultImageIcon'
import './../../ui.style.scss'

const DefaultImageButton = ({ onClick, ...props }) => (
  <div className='black-button default-image' onClick={onClick} >
    <DefaultImageIcon />
  </div>
)

DefaultImageButton.propTypes = {

  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

DefaultImageButton.defaultProps = {

}

export default DefaultImageButton
