import React from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'

const AppHeading = ({ title, ...props }) => {
  return (
    <div className='app-heading'>
      <p> { title }</p>
    </div>
  )
}

AppHeading.propTypes = {
  /** Title of AppHeading. */
  title: PropTypes.string.isRequired
}

AppHeading.defaultProps = {
  title: 'Article'
}

export default AppHeading
