import gql from 'graphql-tag'

export default gql(`
query($limit: Int $project: String) {
    listLanguage( 
    project: $project
    limit: $limit
    ){
        totalCount
        items {
          id
          name
          source
        }
      }
}`)
