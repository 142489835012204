import React, { Component } from 'react'
import { Skeleton, message } from 'antd'

import Input from '../../../components/ui/dataEntry/inputs/Input'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetDrmPlaybackSettings from '../../../graphQL/admin/media/getDrmPlaybackSettings'
import MutationUpdateDrmPlaybackSettings from '../../../graphQL/admin/media/updateDrmPlaybackSettings'

const defaultPlayBackDetails = {
  url: '',
  apiKey: ''
}

class DrmPlaybackConfig extends Component {
  constructor (props) {
    super(props)
    const { apiKey, licenseUrl } = props.playBackDetails ? props.playBackDetails : defaultPlayBackDetails
    this.state = {
      apiKey,
      licenseUrl,
      isEdit: false,
      isConnectionLoading: false,
      isSaveLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(this.props.playBackDetails, nextProps.playBackDetails)) {
      const { licenseUrl, apiKey } = nextProps.playBackDetails
      this.setState({ licenseUrl, apiKey })
    }
  }

    onSave = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isSaveLoading: true })
      const { licenseUrl, apiKey } = this.state
      const variables = { licenseUrl, apiKey }
      this.props.updateDrmPlaybackSettings(variables).then(() => {
        onHistoryUpdate()
        message.success('DRM Playback settings saved successfully')
        this.setState({ isSaveLoading: false, isEdit: false })
      }, error => {
        this.setState({ isSaveLoading: false })
        utilityService.handleError(error)
      })
    }

  handleGeneralChange = (e) => {
    this.setState({ isEdit: true })
    const { name, value } = e.target
    this.setState({ [name]: value, isEdit: true })
  }

  render () {
    const { licenseUrl, apiKey, isEdit, isSaveLoading } = this.state
    const { isLoading } = this.props
    const isEnable = licenseUrl && apiKey
    return (
      <div className='drm-playback'>
        <div className='drm-playback-list'>
          <div className='drm-playback-data'>
            <Skeleton active avatar={false} title={false} paragraph={{ rows: 2 }} loading={isLoading}>
              <Input title='License Request URL' placeholder='License Request URL' inputName='licenseUrl' value={licenseUrl} handleChange={this.handleGeneralChange} isRequired />
              <Input type='password' title='API Key' placeholder='API Key ID' inputName='apiKey' value={apiKey} handleChange={this.handleGeneralChange} isRequired />
            </Skeleton>
          </div>
        </div>
        <div className='drm-playback-footer' >
          <LoadingButton
            type='primary'
            onClick={this.onSave}
            htmlType='submit'
            buttonText={'Save'}
            buttonClass='save-btn'
            isLoading={isSaveLoading}
            isDisabled={!(isEnable && isEdit)}
          />
        </div>
      </div>
    )
  }
}

export default withApollo(compose(
  graphql(
    QueryGetDrmPlaybackSettings,
    {
      options: () => {
        return {
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        return {
          playBackDetails: data.getDRMLicenseSettings,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationUpdateDrmPlaybackSettings,
    {
      options: () => {
        return {
          update: (cache, { data: { updateDRMLicenseSettings } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetDrmPlaybackSettings }))
            if (cacheData && cacheData.getContentBankSettings && updateDRMLicenseSettings) {
              cacheData.getContentBankSettings = updateDRMLicenseSettings
            }
            cache.writeQuery({
              query: QueryGetDrmPlaybackSettings,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        updateDrmPlaybackSettings: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )
)(DrmPlaybackConfig))
