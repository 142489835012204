import React, { Component } from 'react'
import { Skeleton, message } from 'antd'

import Input from '../../../components/ui/dataEntry/inputs/Input'
import InformationIcon from '../../../components/ui/general/icons/InformationIcon'
import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import { utilityService } from '../../../services/UtilityService'
import AppContext from '../../../AppContext'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetContentBankSettings from '../../../graphQL/admin/media/getContentBankSettings'
import MutationUpdateContentBankSettings from '../../../graphQL/admin/media/updateContentBankSettings'
import QueryS3FolderList from '../../../graphQL/content/listS3Folder'

const defaultContentbankDetails = {
  s3BucketName: '',
  region: '',
  secretAccessKey: '',
  accessKey: ''
}

class RemoteFolder extends Component {
  constructor (props) {
    super(props)
    const { s3BucketName, region, secretAccessKey, accessKey } = props.contentBankDetails ? props.contentBankDetails : defaultContentbankDetails
    this.state = {
      s3BucketName,
      region,
      secretAccessKey,
      accessKey,
      isEdit: false,
      isConnectionLoading: false,
      isSaveLoading: false,
      isDataSaved: true
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (!_.isEqual(this.props.contentBankDetails, nextProps.contentBankDetails)) {
      const { s3BucketName, region, secretAccessKey, accessKey } = nextProps.contentBankDetails
      this.setState({ s3BucketName, region, secretAccessKey, accessKey })
    }
  }

    onSaveBucketFolder = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isSaveLoading: true })
      const { s3BucketName, region, secretAccessKey, accessKey } = this.state
      const variables = { s3BucketName, region, secretAccessKey, accessKey }
      this.props.updateContentBankSettings(variables).then(() => {
        message.success('S3 bucket settings saved successfully')
        this.setState({ isSaveLoading: false, isEdit: false })
        onHistoryUpdate()
      }, error => {
        this.setState({ isSaveLoading: false })
        utilityService.handleError(error)
      })
    }

    onConnectionCheck = () => {
      const { onHistoryUpdate } = this.props
      this.setState({ isConnectionLoading: true })
      this.props.getFolderList().then(() => {
        this.setState({ isConnectionLoading: false })
        onHistoryUpdate()
        message.success('Test Successful')
      }, error => {
        this.setState({ isConnectionLoading: false })
        utilityService.handleError(error)
      })
    }

  handleGeneralChange = (e) => {
    this.setState({ isEdit: true })
    const { name, value } = e.target
    this.setState({ [name]: value, isEdit: true })
  }

  render () {
    const { s3BucketName, region, secretAccessKey, accessKey, isEdit, isSaveLoading, isConnectionLoading } = this.state
    const { isLoading } = this.props
    const isEnable = s3BucketName && region && secretAccessKey && accessKey
    const idTitle = <div className='type'>
      <span>Access Key ID</span>
      <div className='information-icon'>
        <InformationIcon />
      </div>
    </div>
    const secretKeyTitle = <div className='type'>
      <span>Secret Access Key</span>
      <div className='information-icon'>
        <InformationIcon />
      </div>
    </div>

    return <AppContext.Consumer>
      {({ permissions }) => {
        const userPermissions = permissions['DATA_MANAGER']
        //   const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
        const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
        return <div className='remote-folder'>
          <div className='remote-folder-list'>
            <div className='remote-folder-data'>
              <Skeleton active avatar={false} title={false} paragraph={{ rows: 2 }} loading={isLoading}>
                <Input title='S3 Bucket Name' placeholder='Insert S3 Bucket Name' inputName='s3BucketName' value={s3BucketName} handleChange={this.handleGeneralChange} isRequired />
                <Input title={'Region'} placeholder='Insert Region' inputName='region' value={region} handleChange={this.handleGeneralChange} isRequired />
                <Input type='password' title={idTitle} placeholder='Insert Access Key ID' inputName='accessKey' value={accessKey} handleChange={this.handleGeneralChange} />
                <Input type='password' title={secretKeyTitle} placeholder='Insert Secret Access Key' inputName='secretAccessKey' value={secretAccessKey} handleChange={this.handleGeneralChange} />
                <div className='button-container'>
                  <LoadingButton
                    type='primary'
                    onClick={this.onConnectionCheck}
                    htmlType='submit'
                    buttonText={'Check Connection'}
                    buttonClass='save-btn'
                    isLoading={isConnectionLoading}
                    isDisabled={!isEnable || isEdit}
                  />
                </div>
              </Skeleton>
            </div>
          </div>
          <div className='remote-folder-footer' >
            <LoadingButton
              type='primary'
              onClick={this.onSaveBucketFolder}
              htmlType='submit'
              buttonText={'Save'}
              buttonClass='save-btn'
              isLoading={isSaveLoading}
              isDisabled={!(isEnable && isEdit) || isUpdateDisabled}
            />
          </div>
        </div>
      }}
    </AppContext.Consumer>
  }
}

export default withApollo(compose(
  graphql(
    QueryGetContentBankSettings,
    {
      options: () => {
        return {
          fetchPolicy: 'network-only'
        }
      },
      props: (props) => {
        const { data } = props
        return {
          contentBankDetails: data.getContentBankSettings,
          isLoading: data.loading
        }
      }
    }
  ),
  graphql(
    MutationUpdateContentBankSettings,
    {
      options: () => {
        return {
          update: (cache, { data: { updateContentBankSettings } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetContentBankSettings }))
            if (cacheData && cacheData.getContentBankSettings) {
              cacheData.getContentBankSettings = updateContentBankSettings
            }
            cache.writeQuery({
              query: QueryGetContentBankSettings,
              data: cacheData
            })
          }
        }
      },
      props: (props) => ({
        updateContentBankSettings: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryS3FolderList,
    {
      options: ({ project }) => {
        let variables = { delimiter: '/', project }
        // if (prefix) { variables.prefix = prefix } else { variables.delimiter = '/' }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: ({ data }) => {
        return {
          folderList: data.listS3Objects ? data.listS3Objects.objects : [],
          folderListLoading: data.loading,
          getFolderList: () => {
            return data.refetch()
          }
        }
      }

    }
  )
)(RemoteFolder))
