import gql from 'graphql-tag'

export default gql(`
query hyperionListSubscriptionActivities( $subscriptionId: ID!) {
    hyperionListSubscriptionActivities( 
        subscriptionId: $subscriptionId
    ) {
        id
        subscriptionId
        eventDate
        eventName
        customerId
        planId
        providerId
    }
}`)
