import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import PublishIcon from '../icons/PublishIcon'

const PublishButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`black-button publish ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick} >
    <PublishIcon />
  </div>
)

PublishButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

PublishButton.defaultProps = {
  isDisabled: false
}

export default PublishButton
