import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Button, Skeleton } from 'antd'
// import moment from 'moment'

import AppContext from '../../../../AppContext'

import CompetitionOriginalData from '../../dataDisplay/CompetitionOriginalData'
import CompetitionCustomData from '../../dataDisplay/CompetitionCustomData'
import LoadingButton from '../../general/buttons/LoadingButton'
import CompetitionImage from './../../dataDisplay/CompetitionImages'
import { utilityService } from '../../../../services/UtilityService'

import MutationUpdateCompetition from '../../../../graphQL/admin/updateCompetition'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryGetCompetition from '../../../../graphQL/admin/getCompetition'

import './../../ui.style.scss'

const { TabPane } = Tabs

class EditCompetitionModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      customDetails: {}
    }
  }

  resetState = () => {
    this.setState({
      customDetails: {}
    })
  }

  onEditCompetition = (details) => {
    this.setState({ customDetails: details })
  }

  onCompetitionChange = () => {
    const { customDetails, mediaDetails } = this.state
    const { onHistoryUpdate } = this.props
    this.setState({ isLoading: true })
    if (!_.isEmpty(customDetails)) {
      const newDetails = {
        id: customDetails.id,
        customTitle: customDetails.customTitle,
        customCompetitionCode: customDetails.customCompetitionCode,
        customCountry: customDetails.customCountry,
        customCompetitionFormat: customDetails.customCompetitionFormat,
        customType: customDetails.customType,
        customCompetitionType: customDetails.customCompetitionType,
        customSource: customDetails.customSource,
        tags: customDetails.tags && customDetails.tags.length ? customDetails.tags.map(item => item.key) : [],
        logo: customDetails && customDetails.logo ? customDetails.logo.id : null,
        defaultTeamImage: customDetails && customDetails.defaultTeamImage ? customDetails.defaultTeamImage.id : null
      }
      this.props.updateCompetition(newDetails).then(response => {
        this.props.handleCancel()
        onHistoryUpdate()
        this.setState({ isLoading: false })
      }, error => {
        this.props.handleCancel()
        utilityService.handleError(error)
        this.setState({ isLoading: false })
      })
    }

    if (mediaDetails) {
      const { selectedCompetition } = this.props
      const newDetails = {
        id: selectedCompetition.id,
        logo: mediaDetails && mediaDetails.logo ? mediaDetails.logo.id : null,
        defaultTeamImage: mediaDetails && mediaDetails.defaultTeamImage ? mediaDetails.defaultTeamImage.id : null,
        customTitle: selectedCompetition.customTitle,
        customCompetitionCode: selectedCompetition.customCompetitionCode,
        customCountry: selectedCompetition.customCountry,
        customCompetitionFormat: selectedCompetition.customCompetitionFormat,
        customType: selectedCompetition.customType,
        customCompetitionType: selectedCompetition.customCompetitionType,
        customSource: selectedCompetition.customSource,
        tags: selectedCompetition.tags && selectedCompetition.tags.length ? selectedCompetition.tags.map(item => item.key) : []
      }
      this.props.updateCompetition(newDetails).then(response => {
        this.props.handleCancel()
        onHistoryUpdate()
        this.setState({ isLoading: false })
      }, error => {
        this.props.handleCancel()
        utilityService.handleError(error)
        this.setState({ isLoading: false })
      })
    }
  }

  onEditCompetitionMedia = (mediaDetails) => {
    this.setState({ mediaDetails })
  }

  render () {
    const { visible, handleCancel, selectedCompetition, competitionIsLoading, title } = this.props
    const { customDetails, isLoading, mediaDetails } = this.state

    let variables
    if (selectedCompetition) {
      variables = {
        id: selectedCompetition.id
      }
    }
    const query = {
      readQuery: { query: QueryGetCompetition, variables },
      queryName: 'getCompetition'
    }

    return (

      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <Modal
            className='general-modal edit-competition-modal'
            visible={visible}
            closable={false}
            title={title}
            cancelText='Back'
            afterClose={this.resetState}
            width='1024px'
            centered
            destroyOnClose
            maskClosable={false}
            footer={<div className='modal-footer'>
              <p>
                {/* Last Updated: {moment(new Date(selectedCompetition.lastUpdated)).format('DD MMM, YYYY')} */}
                {!_.isEmpty(customDetails) ? 'The changes made here will reflect in APIs and other modules of VCMS only after a re-sync of the respective competition is triggered from Opta Sync Settings page.'
                  : ''} </p>
              <div className='right-btn'>
                <Button key='back' onClick={handleCancel}> Back </Button>
                <LoadingButton type='primary' onClick={this.onCompetitionChange} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isLoading} isDisabled={(_.isEmpty(customDetails) && _.isEmpty(mediaDetails)) || isUpdateDisabled} />
              </div>
            </div>}
          >

            <div className='tab-details'>
              <Tabs>
                <TabPane tab='Original Data' key='1'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={competitionIsLoading}>
                    <CompetitionOriginalData selectedCompetition={selectedCompetition} />
                  </Skeleton>
                </TabPane>

                <TabPane tab='Custom Data' key='2'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={competitionIsLoading}>
                    <CompetitionCustomData selectedCompetition={selectedCompetition} onEditCompetition={this.onEditCompetition} project={project} />
                  </Skeleton>
                </TabPane>
                <TabPane tab='Images' key='3'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={competitionIsLoading}>
                    <CompetitionImage
                      selectedCompetition={selectedCompetition}
                      project={project}
                      logo={selectedCompetition.logo}
                      defaultTeamImage={selectedCompetition.defaultTeamImage}
                      onEditCompetitionMedia={this.onEditCompetitionMedia}
                      query={query}
                    />
                  </Skeleton>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        }}
      </AppContext.Consumer>
    )
  }
}

EditCompetitionModal.propTypes = {
  /** visible action of EditCompetitionModal. */
  visible: PropTypes.bool,
  /** handleCancel action of EditCompetitionModal. */
  handleCancel: PropTypes.func
}

EditCompetitionModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationUpdateCompetition,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateCompetition: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(EditCompetitionModal))
