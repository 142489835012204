import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Switch } from 'antd'

import Input from './../inputs/Input'
// import { generateCroppedThumbnail } from '../../../../util/util'
import MultipleDropDown from './../inputs/AppMultipleSelectDropDown'
import AddIcon from '../../general/icons/AddIcon'
import CrossIcon from '../../general/icons/CrossIcon'
import ViewPageIcon from '../../general/icons/ViewPageIcon'
import TextAreaInput from '../inputs/TextArea'
import './../../ui.style.scss'

// const skippedTagsName = []

class EditAppWidgetDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showCrop: false,
      selectedIndex: undefined,
      icon: props.icon || null
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (!_.isEqual(this.props.icon, nextProps.icon) && nextProps.icon) {
      let icon = nextProps.icon
      this.setState({ icon })
    }
    if (this.props.croppedUrl !== nextProps.croppedUrl) {
      this.setState({ isBoolEnabled: true })
    }

    if (nextProps.isDeleteSuccess) {
      this.resetState()
    }
  }

    onTagSearch = (searchTag) => {
      this.setState({
        searchTag
        // selectedIndex: searchTag ? index : undefined
      })
    }

    hideCrop = () => {
      this.setState({ showCrop: false })
    }

    showCropModal = (e) => {
      const { icon } = this.state
      e.stopPropagation()
      if (!_.isEmpty(icon)) {
        this.props.showCropModal(icon, false)
      } else {
        this.props.showImageModal(false)
      }
    }

    removeImage = (e) => {
      e.stopPropagation()
      this.setState({ icon: null, isBoolEnabled: true })
      this.props.clearSelection()
    }

    showCrop = (e) => {
      e.stopPropagation()
      this.setState({ showCrop: true })
    }

    render () {
      const { name, selectedKey, handleTextChange, handleIsEnabledChange, croppedUrl, appList, selectedApp, handleAppChange, showImageModal, handleIdChange, isFromEdit, handleShortDescriptionChange,
        onInputFieldBlur, onInputFieldFocus, description, appSearchText, handleAppSearchTextChange, isDuplicate, isActive } = this.props
      const { icon, showCrop } = this.state
      const isKeyDisable = isDuplicate ? false : isFromEdit
      const image = !_.isEmpty(icon) ? icon.fileName : undefined
      const defaultImage = require('../../../../assets/images/default-image-square.jpg')
      const currentImage = image || defaultImage
      return (
        <div className='edit-widget-details'>
          <div className='edit-input-container'>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <Input title='Title' placeholder='Widget Title' inputName='title' value={name} handleChange={handleTextChange} isRequired />
              </div>
              <div className='edit-input-item'>
                <Input title='Key' placeholder='Key' inputName='title' value={selectedKey} handleChange={handleIdChange} disabled={isKeyDisable} isRequired />
              </div>
            </div>
            <div className='edit-input-inner-container-noflex'>
              <div className='edit-input-item-noflex'>
                <div className='output-format' id='dropdown-app'>
                  <label className='title-text'>Apps<span className='mandatory'>*</span></label>
                  <MultipleDropDown options={appList} onOptionSelect={handleAppChange} selectedValue={selectedApp} valueParam='id' displayParam='name' placeHolder='Select Apps' parent={'dropdown-app'} disabled={false} onSearch={handleAppSearchTextChange} searchText={appSearchText} />
                </div>
              </div>
            </div>
            <div className='edit-input-inner-container-noflex'>
              <div className='edit-input-inner-container'>
                <div className='edit-input-item'>
                  <TextAreaInput
                    title='Description'
                    placeholder='Write a description...'
                    inputName='shortDescription'
                    onFocus={onInputFieldFocus}
                    onBlur={onInputFieldBlur}
                    value={description}
                    handleChange={handleShortDescriptionChange}
                  />
                </div>
                <div className='edit-input-item'>
                  <div className='widget-switch-inner-container'>
                    <span>Active</span>
                    <Switch checked={isActive} onChange={(value) => handleIsEnabledChange(value)} />
                  </div>
                </div>
              </div>
              {/* <div className='edit-input-item'> */}

              {/* </div> */}
            </div>
            <div className='edit-input-inner-container'>
              <div className='asset-icon'>
                <span>Preview Image<span className='mandatory'>*</span></span>
                <div className={!_.isEmpty(image) ? 'avatar' : 'avatar-noimage'} onClick={showImageModal} onMouseLeave={() => this.hideCrop()}>
                  { showCrop

                    ? <div className={!_.isEmpty(image) ? 'avatar-hover' : 'avatar-noimage-hover'} onClick={(e) => this.showCropModal(e)}>
                      <span>{ image ? <ViewPageIcon /> : <AddIcon /> }</span>
                    </div>
                    : <React.Fragment>
                      <div onMouseEnter={(e) => this.showCrop(e)} onMouseLeave={() => this.hideCrop()}>
                        <img size={180} className='user' src={croppedUrl || currentImage} />
                      </div>
                      { image ? <div onMouseEnter={(e) => e.stopPropagation()} onClick={(e) => this.removeImage(e)} className='oval'>
                        <CrossIcon color='#FFFFFF' />
                      </div> : null}
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

EditAppWidgetDetails.propTypes = {
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /**  */
  handleFormatChange: PropTypes.func,
  /**  */
  handleTextChange: PropTypes.func,
  /**  */
  formats: PropTypes.array,
  /**  */
  selectedFormat: PropTypes.string,
  /** List of category */
  categoryList: PropTypes.array,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** Selected category valu */
  selectedCategory: PropTypes.string
}

EditAppWidgetDetails.defaultProps = {
}

export default EditAppWidgetDetails
