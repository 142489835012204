import React, { Component } from 'react'
import { Table, Empty, Skeleton, message } from 'antd'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import getUserAdminAttributes from '../../../graphQL/customers/getUserAdminAttributes'
import Switch from '../dataEntry/inputs/Switch'
import adminUpdateAttributes from '../../../graphQL/customers/adminUpdateAttributes'
import userMessages from '../../../constants/messages'
import { utilityService } from '../../../services/UtilityService'

class AdminAttributeTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        width: '30%',
        key: 'title'
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: '30%'
      },
      {
        title: 'Status',
        dataIndex: 'value',
        key: 'value',
        width: '20%',
        render: (id, data) => this.renderStatus(id, data)
      },
      {
        title: '',
        dataIndex: 'value',
        key: 'value',
        width: '20%',
        render: (id, data) => this.renderStatusButton(id, data)
      }
    ]
    this.columnsWithOutEditPermission = [
      {
        title: 'Title',
        dataIndex: 'title',
        width: '30%',
        key: 'title'
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: '30%'
      },
      {
        title: 'Status',
        dataIndex: 'value',
        key: 'value',
        width: '40%',
        render: (id, data) => this.renderStatus(id, data)
      }
    ]
    this.state = {
      toggleLoader: false,
      currentSelection: null
    }
  }

  renderStatus = (_id, data) => {
    return data.value ? <div className='comments'>  Active</div> : <div className='comments'> Disabled</div>
  }

  renderStatusButton = (_id, data) => {
    return (
      <div className='comments'>
        <Switch
          isChecked={data.value} loading={this.state.toggleLoader && data.key === this.state.currentSelection} disabled={this.state.toggleLoader}
          onChange={(value) => this.onChangeUserStatus(value, data)}
        />
      </div>
    )
  }

  onChangeUserStatus = (value, data) => {
    this.setState({ toggleLoader: true, currentSelection: data.key })
    const selectedItem = this.props.attributes.find((item) => item.key === data.key)
    const attributesList = this.props.attributes.filter((item) => item.key !== data.key)
    const newItem = {
      key: selectedItem.key,
      title: selectedItem.title,
      value
    }

    const newList = [...attributesList, newItem]

    const newArray = newList.map(obj => {
      const { title, __typename, ...rest } = obj // Destructure to remove 'age'
      return rest
    })

    const request = {
      email: this.props.selectedCustomer,
      adminAttributes: newArray,
      project: 'vcms'
    }

    this.props.updateAttribute(request).then(() => {
      this.props.getUserAttributes().then(() => {
        message.success(value ? userMessages.ADMIN_ATRIBUTE_ACTIVATE : userMessages.ADMIN_ATRIBUTE_DISABLED)
        this.props.fetchHistory()
        this.setState({ toggleLoader: false, currentSelection: null })
      })
    }, error => {
      console.log('error', error)
      utilityService.handleCustomerManagerError(error)
    }
    )
  }

  render () {
    const { attributes, isLoading, userPermissions } = this.props
    const isViewOnly = userPermissions.indexOf('UPDATE') === -1

    return (
      <div className='general-customer-attribute-table' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          {!_.isEmpty(attributes)
            ? <Table className=''
              bordered
              rowKey={record => record.id}
              columns={isViewOnly ? this.columnsWithOutEditPermission : this.columns} dataSource={attributes} pagination={false}
            />
            : <Empty />}
        </Skeleton>
      </div>
    )
  }
}

export default withApollo(compose(
  graphql(
    getUserAdminAttributes,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer } = props
        const variables = { id: selectedCustomer }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const attributes = data && data.getUserAdminAttributes && data.getUserAdminAttributes.adminAttributes ? data.getUserAdminAttributes.adminAttributes : []
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return {
          attributes,
          isLoading: data && data.loading,
          getUserAttributes: () => {
            return props.data.refetch()
          }
        }
      }
    }
  ),
  graphql(
    adminUpdateAttributes,
    {
      props: (props) => ({
        updateAttribute: (request) => {
          return props.mutate({
            variables: request
          })
        }
      })
    }
  )
)(AdminAttributeTable))
