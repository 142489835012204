import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Skeleton, message } from 'antd'
import AppContext from '../../AppContext'

import AssetGeneralDetails from './AssetGeneralDetails'
import ButtonContainer from './../../components/ui/general/buttons/ButtonContainer'
import LoadingButton from './../../components/ui/general/buttons/LoadingButton'
import DuplicateButton from './../../components/ui/general/buttons/DuplicateButton'
import SidebarButton from './../../components/ui/general/buttons/SidebarButton'
import ArchiveButton from './../../components/ui/general/buttons/ArchiveButton'
import RestoreButton from './../../components/ui/general/buttons/RestoreButton'
import Accordion from './../../components/ui/dataDisplay/Accordion'
import AssetHeading from './../../components/ui/dataDisplay/AssetHeading'
import AssetTags from './AssetTags'
import AssetImages from './AssetImages'
import AssetChannel from './AssetChannel'
import AssetVideoJobStatus from './AssetVideoJobStatus'
import AssetVideos from './AssetVideos'
import ArticleContents from './content/ArticleContents'
import SeoSettings from './SeoSettings'
import Player from './../../components/player/Player'
import RelatedAssets from './RelatedAssets'
import RelatedSeasons from './RelatedSeasons'
import ConfirmModal from './../../components/ui/feedback/ConfirmModal'
import CreateAuthorModal from './../../components/ui/dataEntry/other/CreateAuthorModal'
import AddImageModal from './../../components/ui/dataEntry/other/AddImageModal'
import AddVideoModal from './../../components/ui/dataEntry/other/AddVideoModal'
import AddImageFromLocalFolderModal from './../../components/ui/dataEntry/other/AddImageFromLocalFolderModal'
import EditImageModal from './../../components/ui/dataEntry/other/EditImageModal'
import CropImageModal from './../../components/ui/dataEntry/other/CropImageModal'
import AddAssetChannelModal from './../../components/ui/dataEntry/other/AddAssetChannelModal'
import Button from './../../components/ui/general/buttons/Button'
import userMessages from './../../constants/messages'
import assetDefaultValues from '../../constants/asset-default'
import { utilityService } from './../../services/UtilityService'
import { getCropImageUrl, wordCapitalize } from '../../util/util'
import CreateRatingComponent from './../../components/ui/dataEntry/other/CreateRatingComponent'
import CreateMetaComponent from './../../components/ui/dataEntry/other/CreateMetaComponent'
import AssetCredits from './AssetCredits'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose, cloneDeep } from 'lodash'
import MutationArchiveAsset from './../../graphQL/asset/updateAssetDetails'
import MutationCreateContent from './../../graphQL/asset/createContentItem'
import QueryAllAssets from './../../graphQL/asset/searchAssets'
import QueryMediaCategory from './../../graphQL/content/listMediaCategories'
import MutationUpdateMedia from './../../graphQL/content/updateMedia'
import MutationDuplicateArticle from './../../graphQL/asset/duplicateAssetDetails'
import MutationCreateMediaLinks from './../../graphQL/content/batchLinkMedia'
import MutationCreateChannel from './../../graphQL/asset/createProgram'
import MutationUpdateChannel from './../../graphQL/asset/updateProgram'
import MutationLinkAssetProgram from './../../graphQL/asset/linkAssetProgram'
import QueryListChannel from './../../graphQL/asset/listChannel'
import QueryContentPlaceholder from './../../graphQL/asset/listContetPlaceHolder'
import autoAssociateImages from '../../graphQL/asset/autoAssociateImages'

import { GlobalHotKeys } from 'react-hotkeys'

const keyMap = {
  shortcutImage: 'i',
  shortcutVideo: 'v',
  shortcutDuplicate: 'd',
  shortcutSave: 's'
}

const deleteWarningMessage = {
  message: userMessages.ASSET_DELETE_CONFIRMATION,
  title: 'ARCHIVE ?'
}
const restoreWarningMessage = {
  message: userMessages.ASSET_RESTORE_CONFIRMATION,
  title: 'RESTORE ?'
}

const duplicateWarningMessage = {
  message: userMessages.ASSET_DUPLICATE_CONFIRMATION,
  title: 'DUPLICATE ?'
}
const width = ['100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%']
const expiryDay = 10
// const bufferTime = 15

const getSortedList = (details) => {
  const data = cloneDeep(details)
  return (data || []).sort((a, b) => {
    const regxAlpha = /[^a-zA-Z]/g
    const regxNumber = /[^0-9]/g
    const firstString = a.id.replace(regxAlpha, '')
    const secondString = b.id.replace(regxAlpha, '')
    if (firstString === secondString) {
      const firstNumber = parseInt(a.id.replace(regxNumber, ''), 10)
      const secondNumber = parseInt(b.id.replace(regxNumber, ''), 10)
      return firstNumber === secondNumber ? 0 : firstNumber > secondNumber ? 1 : -1
    } else {
      return firstString > secondString ? 1 : -1
    }
  })
}

let primaryImageType
class AssetDetails extends Component {
  constructor (props) {
    super(props)
    primaryImageType = utilityService.getPrimaryImageType()
    const videoList = props.details ? this.getPlayableVideos(props) : []
    this.state = {
      shouldShowWarning: false,
      showAddImage: false,
      showAddChannel: false,
      currentSelectedMediaId: '',
      seoSlugFocusOut: false,
      selectedImage: null,
      showAddImageModal: false,
      showCropModal: false,
      showAddVideo: false,
      showEditMedia: false,
      isFromContent: false,
      aspectRatio: {},
      settings: [],
      croppedUrl: '',
      shouldShowDuplicateWarning: false,
      shouldShowRestoreWarning: false,
      authorSearch: undefined,
      categorySearch: undefined,
      isFromEditChannel: false,
      channelSaveLoading: false,
      channelDetails: {},
      programId: '',
      videoId: undefined,
      showPlayer: false,
      selectedChannel: null,
      matchFilter: undefined,
      videoList,
      showVideoLoading: false,
      isLocalFolderOpen: false,
      showAddImageFromLocal: false,
      activeImageType: primaryImageType,
      isSaveDisabled: false,
      triggerAutoAssociateLoader: false

    }
    if (videoList && videoList.length) {
      setTimeout(() => this.playInitialVideo(videoList), 100)
    }
  }

  componentDidMount () {
    const { listImageTypes, details } = this.props
    const selectedImageType = (listImageTypes || []).find(imageType => {
      const isEnabled = (imageType.enabledAssetTypes || []).find(item => details && item.assetType === details.type)
      return imageType.id === this.state.activeImageType && !_.isEmpty(isEnabled)
    })
    if (listImageTypes && listImageTypes.length && _.isEmpty(selectedImageType)) {
      const selectedImageType = (listImageTypes || []).find(imageType => {
        const isEnabled = (imageType.enabledAssetTypes || []).find(item => details && item.assetType === details.type)
        return !_.isEmpty(isEnabled)
      })
      if (!_.isEmpty(selectedImageType)) { this.onImageTypeChange(selectedImageType.id) }
    }
    this.addingNewCredits = []
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line camelcase
    if (nextProps.details && this.props.details && nextProps.details.id === this.props.details.id && nextProps.newDefaultImage !== this.props.newDefaultImage && nextProps.details.type === 'ARTICLE') {
      this.onAddContent({ type: 'DEFAULT_IMAGE' }, (nextProps.details.images || []).filter(image => image.type.id === primaryImageType && image.isDefault === 'true')[0])
    }
    if (nextProps.assetId && nextProps.details && (!this.props.details || nextProps.details.id !== this.props.details.id || (this.props.details &&
      (this.getChannelDiff(nextProps.details.program, this.props.details.program))))) {
      const videoList = this.getPlayableVideos(nextProps)
      this.setState({ videoList, showPlayer: false }, () => this.playInitialVideo(videoList))
    }
    if (nextProps.listAssetTypes && nextProps.listAssetTypes.length && nextProps.details && !_.isEqual(this.props.listAssetTypes, nextProps.listAssetTypes)) {
      const videoList = this.getPlayableVideos(nextProps)
      const videoLength = this.state.videoList.length
      this.setState({ videoList, showPlayer: false }, () => {
        if (!videoLength) {
          this.playInitialVideo(videoList)
        }
      })
    }
    if (nextProps.details && this.props.details && !_.isEqual(this.props.details.videos, nextProps.details.videos)) {
      const oldVideos = (this.props.details.videos || [])
      const newVideos = _.cloneDeep(nextProps.details.videos || [])
      if (newVideos.length !== oldVideos.length) {
        const videoList = this.getPlayableVideos(nextProps)
        this.setState({ videoList, showPlayer: false })
        const { videoId } = this.state
        const defaultVid = newVideos.find(item => item.isDefault === true || item.isDefault === 'true')
        if (!_.isEmpty(defaultVid)) {
          if (videoId) {
            const isPresent = newVideos.find(item => item.id === videoId)
            if (_.isEmpty(isPresent)) {
              this.onPlayClick(defaultVid.type, defaultVid.id, undefined, newVideos)
            }
            // this.setState({videoId: defaultVid.id})
          } else {
            this.onPlayClick(defaultVid.type, defaultVid.id, undefined, newVideos)
          }
        } else if (newVideos.length) {
          const firstVid = newVideos[0]
          this.onPlayClick(firstVid.type, firstVid.id, undefined, newVideos)
        }
      }
    }
    if (nextProps.isVodTriggerSuccess) {
      this.setState({ showVideoLoading: false, showAddVideo: false })
    }
    if (nextProps.assetId !== this.props.assetId) {
      this.onImageTypeChange(primaryImageType)
    }
    if ((nextProps.listImageTypes !== this.props.listImageTypes || (nextProps.details && this.props.details && nextProps.details.id !== this.props.details.id)) && nextProps.listImageTypes.length && nextProps.details) {
      const activeImageTypesForAsset = (nextProps.listImageTypes || []).filter(imageType => {
        const imageTempIndex = (imageType.enabledAssetTypes || []).findIndex(innerImage => innerImage.assetType === nextProps.details.type)
        return imageTempIndex > -1
      })
      const primaryPresent = activeImageTypesForAsset.find(item => item.id === primaryImageType)
      if (_.isEmpty(primaryPresent) && activeImageTypesForAsset && activeImageTypesForAsset.length) { this.onImageTypeChange(activeImageTypesForAsset[0].id) }
    }
  }

  getChannelDiff = (nextChannel, currentChannel) => {
    const newChannel = _.cloneDeep(nextChannel)
    const oldChannel = _.cloneDeep(currentChannel)
    if (newChannel) { delete newChannel.channel.icon }
    if (oldChannel) { delete oldChannel.channel.icon }
    return !_.isEqual(newChannel, oldChannel)
  }

  getPlayableVideos = props => {
    const { listAssetTypes } = props
    let videos = []
    const bufferTime = (props.details && props.details.program && props.details.program.channel && props.details.program.channel.defaultTrimStart) / 60
    const selectedAssetConfig = listAssetTypes.find(item => item.title === props.details.type)
    if (props.details && props.details.program && selectedAssetConfig && selectedAssetConfig.programSection) {
      const isExpired = moment().diff(moment(props.details.program.broadCastStartTime), 'days') >= expiryDay
      const startTime = moment(props.details.program.broadCastStartTime).add(-bufferTime, 'minutes')
      const isSheduled = moment().isBefore(startTime)
      let activeConfig = props.details.program.channel ? props.details.program.channel.activeConfigGroup : undefined
      if (props.details.program.channel && props.details.program.channel.masterConfig) {
        const index = (props.details.program.channel.configGroups || []).findIndex(item => item.id === props.details.program.channel.masterConfig.id)
        if (index > -1) {
          activeConfig = props.details.program.channel.configGroups[index]
        }
      }
      const configData = activeConfig ? this.getActiveConfigData(activeConfig.streams) : []
      const configDataStringArray = configData.map(item => JSON.stringify(item))
      const isDashPresent = configDataStringArray.indexOf('["DASH"]') > -1
      if (!isSheduled && !isExpired && isDashPresent && props.details.type !== 'LINEAR-CHANNEL') {
        const video = { ...props.details.program, isProgram: true }
        videos.push(video)
      }
      if (isDashPresent) {
        const video = { ...props.details.program, isProgram: true, isLinearChannel: true }
        videos.push(video)
      }
    }
    if (props.details && props.details.videos && props.details.videos.length && selectedAssetConfig && selectedAssetConfig.videoSection) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const streamType = isSafari ? 'FAIRPLAY' : 'CENC'
      const media = props.details.videos.filter(item => item.streams && item.streams.length && item.streams.filter(item => item).find(stream => (stream.drm === (!isSafari ? 'CLEAR' : streamType) || stream.drm === (!isSafari ? 'CENC' : streamType))))
      videos = [...videos, ...media]
    }
    const defaultVideoIndex = (videos || []).findIndex(item => item.isDefault === 'true' || item.isDefault === true)
    if (defaultVideoIndex > -1) {
      const defaultVideo = videos.splice(defaultVideoIndex, 1)
      videos = [...defaultVideo, ...videos]
    }

    const videoList = videos.map(item => {
      if (item.isProgram && item.isLinearChannel) {
        return { id: item.id, name: item.channel ? item.channel.name : undefined, isProgram: item.isProgram, isLinearChannel: item.isLinearChannel }
      } else if (item.isProgram && !item.isLinearChannel) {
        return { id: item.id, name: 'Live Program', isProgram: item.isProgram, isLinearChannel: item.isLinearChannel }
      } else {
        const name = item.category ? `${item.category.label}-${item.name}` : item.name
        return { id: item.id, name, isProgram: item.isProgram, isLinearChannel: item.isLinearChannel }
      }
    })
    return videoList
  }

  playInitialVideo = videoList => {
    const { details } = this.props
    // const assetType = details.isLive
    if (videoList && videoList.length) {
      const type = videoList[0].isProgram ? 'channel' : 'vod'
      if (details.isLive) {
        this.onPlayClick(type, videoList[0].id, videoList[0].isLinearChannel)
      } else {
        const vods = videoList.filter(item => !item.isProgram)
        if (vods.length) {
          this.onPlayClick('vod', vods[0].id)
        } else {
          this.onPlayClick(type, videoList[0].id, videoList[0].isLinearChannel)
        }
      }
    } else {
      // remove player
      this.setState({ videoId: undefined })
    }
  }

  getActiveConfigData = (data) => {
    return (data || []).filter((item) => item.url).map((item) => {
      const { streamSchema } = item
      return streamSchema.levels.map((levelItem) => levelItem.name)
    })
  }

  toggleWarningMessage = status => {
    this.setState({ shouldShowWarning: status })
  }

  toggleRestoreWarningMessage = status => {
    this.setState({ shouldShowRestoreWarning: status })
  }

  toggleDuplicateWarningMessage = status => {
    this.setState({ shouldShowDuplicateWarning: status })
  }

  deleteCurrentAsset = () => {
    if (this.state.isDeleteLoading) {
      return
    }
    this.setState({ isDeleteLoading: true })
    this.props.archiveAsset(this.props.details.id).then(response => {
      this.props.onDeleteAsset([this.props.details.id])
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
    }, error => {
      this.setState({ isDeleteLoading: false })
      this.toggleWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  restoreCurrentAsset = () => {
    if (this.state.isRestoreLoading) {
      return
    }
    this.setState({ isRestoreLoading: true })
    this.props.restoreAsset(this.props.details.id).then(response => {
      this.props.onDeleteAsset([this.props.details.id])
      this.setState({ isRestoreLoading: false })
      this.toggleRestoreWarningMessage(false)
    }, error => {
      this.setState({ isRestoreLoading: false })
      this.toggleRestoreWarningMessage(false)
      utilityService.handleError(error)
    })
  }

  onAddMedia = (media, mediaCategory) => {
    const { listImageTypes, details } = this.props
    const { activeImageType } = this.state
    let newMedia = (media || []).filter(item => {
      const tempIndex = (details.media || []).findIndex(innerItem => innerItem.type === activeImageType && innerItem.id === item.id)
      return tempIndex === -1
    })
    newMedia = newMedia.filter(item => {
      const tempIndex = (details.images || []).findIndex(innerItem => innerItem.type.id === activeImageType && innerItem.mediaId === item.id)
      return tempIndex === -1
    })
    if (this.state.showAddVideoList) {
      this.setState({ showAddVideoList: false })
      // const videoLinkArr = [...newMedia].map(item => {
      //   return {
      //     mediaId: item.id,
      //     assetId: this.props.details.id,
      //     mediaCategory: mediaCategory || null
      //   }
      // })
      const newAddedVideo = [...newMedia].map(video => {
        video.category = mediaCategory ? { id: mediaCategory } : null
        return video
      })
      this.props.onAddMedia(newAddedVideo, true, mediaCategory)
      // this.props.addMediaLinks(videoLinkArr).then(({ data }) => {
      //   const newAddedVideo = [...newMedia].map(video => {
      //     video.category = data.batchCreateAssetMediaLink.find(item => item.id === video.id).category
      //     return video
      //   })
      //   debugger
      //   this.props.onAddMedia(newAddedVideo, true, mediaCategory)
      // })
    } else {
      this.setState({ showAddImage: false })
      if (this.addImageType === 'contents' && newMedia && newMedia.length) {
        this.createImageContents(newMedia)
      } else if (this.addImageType === 'images') {
        // const imageLinkArray = [...newMedia].map(item => {
        //   return {
        //     mediaId: item.id,
        //     assetId: this.props.details.id
        //   }
        // })
        const selectedImageType = (listImageTypes || []).find(imageType => imageType.id === activeImageType)
        const cropPos = (selectedImageType.enabledAssetTypes || []).find(innerImage => innerImage.assetType === details.type).cropPosition
        const newAddedImages = [...newMedia].map(image => {
          image.type = 'IMAGE'
          image.imageType = activeImageType
          image.cropPos = cropPos
          return image
        })
        this.props.onAddMedia(newAddedImages, false)
        // this.props.addMediaLinks(imageLinkArray).then(({ data }) => {
        //   const selectedImageType = (listImageTypes || []).find(imageType => imageType.id === activeImageType)
        //   const cropPos = (selectedImageType.enabledAssetTypes || []).find(innerImage => innerImage.assetType === details.type).cropPosition
        //   const newAddedImages = [...newMedia].map(image => {
        //     image.id = data.batchCreateAssetMediaLink.find(item => item.id === image.id).id
        //     image.type = 'IMAGE'
        //     image.imageType = activeImageType
        //     image.cropPos = cropPos
        //     return image
        //   })
        //   debugger
        //   this.props.onAddMedia(newAddedImages, false)
        // })
      }
    }
  }

  onSeoSlugFocusOut = (event, status) => {
    // this.setState({ seoSlugFocusOut: status })
  }

  onAddContent = (contentItem, defaultMedia) => {
    const { details } = this.props
    const currentDefaultMedia = defaultMedia || (details.images || []).filter(image => image.type.id === primaryImageType && image.isDefault === 'true')[0]
    const { contents } = details
    const type = contentItem.type
    const defaultContentType = ['TITLE', 'SHARE', 'DESCRIPTION', 'AUTHOR', 'SOCIAL', 'EMBED', 'IMAGE', 'DEFAULT_IMAGE', 'RICHTEXT']
    if (type === 'IMAGE') {
      this.showAddImages('contents')
    } else {
      const newContent = { ...assetDefaultValues.defaultContentData[type] }
      switch (type) {
        case 'TITLE':
          newContent.value = details.title
          break
        case 'DESCRIPTION':
          newContent.value = details.description
          break
        case 'AUTHOR':
          newContent.name = details.author
            ? details.author.name
            : 'Author Name'
          newContent.placeholderImage = details.author
            ? details.author.placeholderImage
            : ''
          break
        case 'DEFAULT_IMAGE':
          newContent.media = currentDefaultMedia ? currentDefaultMedia.mediaId : null
          break
        default:
          break
      }
      newContent.id = `${type}-${parseInt(Math.random() * 1000, 10)}`
      newContent.position = contents.length ? contents[contents.length - 1].position + 1 : 1
      const newContentRequest = {
        position: newContent.position,
        media: newContent.media || null,
        value: newContent.value ? newContent.value : !defaultContentType.includes(type) ? (contentItem.name ? contentItem.name : null) : null,
        type: type,
        assetId: details.id,
        metaFields: (contentItem.metaFields || []).map(metaField => ({ fieldID: metaField.id, displayName: metaField.displayName, displayTitle: metaField.displayTitle, type: metaField.type, isRequired: metaField.isRequired, id: utilityService.makeRandomString(6), values: metaField.values, tooltip: metaField.tooltip }))
      }
      this.props.createContentItem(newContentRequest).then(success => {
        this.props.onAddContent(success.data.createContentItem)
        if (type === 'DEFAULT_IMAGE') {
          if (contents.length < this.props.details.contents.length) {
            this.props.onOrderChange(contents.length, 0)
          } else {
            if (contents.length) {
              setTimeout(() => this.props.onOrderChange(contents.length - 1, 0), 300)
            }
          }
        }
      }, error => {
        utilityService.handleError(error)
      })
    }
  }

  createImageContents = (images) => {
    const { details } = this.props
    const { contents } = details
    images.map(image => {
      const position = contents.length ? contents[contents.length - 1].position + 1 : 1
      const newContentRequest = {
        position: position,
        media: image.id,
        type: 'IMAGE',
        assetId: details.id
      }
      this.props.createContentItem(newContentRequest).then(success => {
        this.props.onAddContent(success.data.createContentItem)
      }, error => {
        utilityService.handleError(error)
      })
    })
  }

  showAddImages = popupId => {
    this.addImageType = popupId
    this.setState({ showAddImage: true })
  }

  onImageTypeChange = activeImageType => {
    this.setState({ activeImageType })
    this.props.onImageTypeChange(activeImageType)
  }

  showAddImageLocal = (showAddImageFromLocal) => {
    if (showAddImageFromLocal) {
      this.setState({ isLocalFolderOpen: false, showAddImageFromLocal }, () => {
        setTimeout(() => {
          this.setState({ isLocalFolderOpen: true }, () => {
            this.setState({ isLocalFolderOpen: false })
          })
        }, 100)
      })
    } else {
      this.setState({ showAddImageFromLocal })
    }
  }

  showAddChannel = () => {
    this.setState({ showAddChannel: true })
  }

  hideAddChannel = () => {
    this.setState({ showAddChannel: false, isFromEditChannel: false })
  }

  onAddChannel = (selectedChannel, startDate, endDate, curProgramId) => {
    if (this.state.channelSaveLoading) {
      return
    }
    this.setState({ channelSaveLoading: true })
    const startTime = startDate ? startDate.toISOString() : null
    const endTime = endDate ? endDate.toISOString() : null
    const { details } = this.props
    let currentProgram = curProgramId && details && !_.isEmpty(details.program) ? details.program : {}
    if (this.state.isFromEditChannel && !_.isEmpty(currentProgram) && currentProgram.source !== 'EPG') {
      const { programId } = this.state
      this.props.updateProgram(programId, selectedChannel, startTime, endTime).then((response) => {
        const updatedProgram = response.data.updateProgram
        let { program } = details
        const programList = [program.id]
        this.props.linkAssetProgram(details.id, programList).then(() => {
          program = updatedProgram
          this.props.updateChannelProgram(program)
          this.setState({ showAddChannel: false, isFromEditChannel: false, channelSaveLoading: false, programId: '' })
          setTimeout(() => {
            const videoList = this.getPlayableVideos(this.props)
            this.setState({ videoList, showPlayer: false })
          }, 1000)
          message.success(userMessages.PROGRAM_UPDATE_SUCCESS)
        }, error => {
          this.setState({ showAddChannel: false, isFromEditChannel: false, channelSaveLoading: false, programId: '' })
          utilityService.handleError(error)
        })
        // const index = details.program.findIndex((item) => item.id === updatedProgram.id)
      }, error => {
        this.setState({ showAddChannel: false, isFromEditChannel: false, channelSaveLoading: false, programId: '' })
        utilityService.handleError(error)
      })
    } else {
      this.props.createProgram(selectedChannel, startTime, endTime).then((response) => {
        // let programId = details && details.program && details.program.length ? details.program.map((item) => item.id) : []
        // programId.push(response.data.createProgram.id)
        // let { program } = details
        // program = program && program.length ? program : []
        let program = response.data.createProgram
        // if (!_.isEmpty(currentProgram) && currentProgram.source === 'EPG') {
        //   programId = programId.filter((item) => item.id !== currentProgram.id)
        //   program = program.filter((item) => item.id !== currentProgram.id)
        // }
        this.props.linkAssetProgram(details.id, program.id).then(() => {
          this.props.updateChannelProgram(program)
          setTimeout(() => {
            const videoList = this.getPlayableVideos(this.props)
            this.setState({ videoList, showPlayer: false }, () => this.playInitialVideo(videoList))
          }, 1000)
          this.setState({ showAddChannel: false, isFromEditChannel: false, channelSaveLoading: false })
          if (!_.isEmpty(currentProgram) && currentProgram.source === 'EPG') { message.success(userMessages.PROGRAM_UPDATE_SUCCESS) } else message.success(userMessages.PROGRAM_ADDED_SUCCESS)
        }, error => {
          this.setState({ showAddChannel: false, isFromEditChannel: false, channelSaveLoading: false })
          utilityService.handleError(error)
        })
      }, error => {
        this.setState({ showAddChannel: false, isFromEditChannel: false, channelSaveLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  hideAddImage = () => {
    this.setState({ showAddImage: false, showAddVideoList: false })
  }

  showImageEditor = (imageUrl, imageId, details, currentId, isFromContent) => {
    this.currentId = currentId
    this.setState({
      showEditMedia: true,
      currentSelectedMediaId: imageId,
      currentSelectedMediaDetails: details,
      currentSelectedMediaType: 'IMAGE',
      isFromContent
    })
  }

  showAddVideo = uploadType => {
    if (uploadType === 'uploadQueue') { this.setState({ showAddVideoList: true, showAddVideo: false }) } else { this.setState({ showAddVideo: true, showAddVideoList: false }) }
  }

  showAddImageFromLocal = () => {

  }

  hideAddVideo = () => {
    this.setState({ showAddVideo: false })
  }

  onTriggerVod = (video, tags, mediaCategory, drmRequired) => {
    this.props.onTriggerVod(video, tags, mediaCategory, drmRequired)
    this.setState({ showVideoLoading: true })
    // this.setState({ showVideoLoading: true }, () => {
    //   setTimeout(() => {
    //     this.setState({ showAddVideo: false, showVideoLoading: false })
    //   }, 2000)
    // })
  }

  showVideoEditor = (videoId, videoDetails) => {
    this.setState({
      showEditMedia: true,
      currentSelectedMediaId: videoId,
      currentSelectedMediaDetails: videoDetails,
      currentSelectedMediaType: 'VIDEO',
      isFromContent: false
    })
  }

  hideImageEditor = (responseData, isVideo) => {
    this.currentId = undefined
    const oldMediaType = _.cloneDeep(this.state.currentSelectedMediaType)
    this.setState({
      showEditMedia: false,
      currentSelectedMediaId: '',
      currentSelectedMediaDetails: undefined,
      currentSelectedMediaType: null,
      isFromContent: false
    }, () => {
      if (oldMediaType === 'VIDEO') {
        const videoList = this.getPlayableVideos(this.props)
        this.setState({ videoList })
      }
    })
    if (responseData) {
      this.props.updateMediaDetails(responseData, isVideo)
    }
  }

  showCreateAuthorModal = () => {
    this.setState({ showCreateAuthorModal: true })
  }

  onCreateAuthor = author => {
    this.props.onAuthorChange(author)
    this.setState({ showCreateAuthorModal: false, selectedImage: null, croppedUrl: '' })
  }

  hideCreateAuthorModal = () => {
    this.setState({ showCreateAuthorModal: false, selectedImage: null, croppedUrl: '' })
  }

  showAddImageModal = () => {
    this.setState({ showAddImageModal: true, showCreateAuthorModal: false })
  }

  hideAddImageModal = () => {
    this.setState({ showAddImageModal: false, showCreateAuthorModal: true })
  }

  saveCurrentSelection = (selectedImage) => {
    this.setState({ selectedImage, showAddImageModal: false, showCreateAuthorModal: true })
  }

  onPlayerClose = () => {
    this.setState({ showPlayer: false }, () => {
      // FIX-ME: Remove the button and find the exact issue of player unmount
      setTimeout(() => {
        const titleInput = '#channel-hidden-button'
        const input = document.querySelector(titleInput)
        if (input) {
          input.focus()
        }
      }, 10)
    })
  }

  onChangeShowPlayer = showPlayer => {
    if (this.state.showPlayer !== showPlayer) {
      this.setState({ showPlayer }, () => {
        if (!showPlayer) {
          setTimeout(() => {
            const titleInput = '#channel-hidden-button'
            const input = document.querySelector(titleInput)
            if (input) {
              input.focus()
            }
          }, 10)
        }
      })
    }
  }

  showCropModal = () => {
    const { selectedImage } = this.state
    const aspectRatio = selectedImage[0].aspectRatio.find(item => item.title === '1:1')
    const settings = selectedImage[0].settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
    this.setState({ showCreateAuthorModal: false, settings, aspectRatio }, () => this.setState({ showCropModal: true }))
  }

  hideCropModal = () => {
    this.setState({ showCropModal: false, showCreateAuthorModal: true })
  }

  clearSelectedImage = () => {
    this.setState({ selectedImage: null, croppedUrl: null })
  }

  saveCurrentCrop = (settings) => {
    const image = { ...this.state.selectedImage[0] }
    const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
    const currentaspectId = currentAspectRatio.aspectRatio
    const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
    // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
    image.settings.map(setting => {
      setting.outputFormat = 'JPG'
      delete setting['__typename']
    })
    image.settings[changedIndex].x1 = settings.x1
    image.settings[changedIndex].x2 = settings.x2
    image.settings[changedIndex].y1 = settings.y1
    image.settings[changedIndex].y2 = settings.y2
    // this.props.updateImage(image.id, image.name, tags, image.settings)
    const croppedUrl = getCropImageUrl(image, settings)
    this.setState({ showCropModal: false, showCreateAuthorModal: true, croppedUrl })
  }

  duplicateAssets = () => {
    // NProgress.start()
    if (this.state.isDuplicateLoading) {
      return
    }
    this.setState({ isDuplicateLoading: true }, () => {
      this.props.duplicateAsset(this.props.details.id).then(({ data }) => {
        this.setState({ isDuplicateLoading: false, shouldShowDuplicateWarning: false })
        // this.props.onDuplicateAsset(data.duplicateAsset.id)
        window.open(location.origin + '/assets/' + data.duplicateAsset.id, '_blank')
        message.success(userMessages.ASSET_DUPLICATE_SUCCESS)

        // this.props.clearFilterDetails()
      }, error => {
        this.setState({ isDuplicateLoading: false })
        this.toggleDuplicateWarningMessage(false)
        utilityService.handleError(error)
      })
    })
  }

  isPlayerReady = () => {
    this.setState({ isVodApiCalling: false })
  }

  getProgramDRM = (program, type, streamType) => {
    if (type === 'channel') {
      if (!program || !program.channel || !program.channel.activeConfigGroup) {
        return
      }
      let activeConfig = program.channel.activeConfigGroup
      if (program.channel && program.channel.masterConfig) {
        const index = (program.channel.configGroups || []).findIndex(item => item.id === program.channel.masterConfig.id)
        if (index > -1) {
          activeConfig = program.channel.configGroups[index]
        }
      }
      const activeConfigStream = activeConfig.streams.filter(item => item && item.url).find(item => item.streamSchema.levels[0].name === streamType)
      let url, contentKey, drmEntitlementMessage
      if (activeConfigStream && activeConfigStream.fields && activeConfigStream.streamSchema) {
        url = activeConfigStream.url
        contentKey = activeConfigStream.fields.find(item => item.name === 'Content Key').value
        drmEntitlementMessage = activeConfigStream.fields.find(item => item.name === 'DRM Entitlement Message') && activeConfigStream.fields.find(item => item.name === 'DRM Entitlement Message').value
      } else {
        message.error(userMessages.INVALID_CHANNEL_CONFIG)
        return
      }
      const request = {
        url,
        contentKey,
        streamType,
        drmEntitlementMessage
      }
      this.setState({ streamRequest: request })
    } else {
      const request = {
        url: program.Url,
        contentKey: program.contentKey,
        streamType,
        drmEntitlementMessage: program.drmEntitlementMessage
      }
      if (streamType === 'HLS' && program.iv) {
        request.iv = program.iv
      }
      if (program.drm === 'CLEAR') {
        request.drm = 'CLEAR'
      }
      this.setState({ streamRequest: request })
    }
  }

  onAuthorSearch = (value) => {
    this.setState({
      authorSearch: value
    })
  }

  onMatchSearch = (value) => {
    this.setState({
      matchSearch: value
    })
  }

  onChangeMatchFilter = (matchFilterObj) => {
    this.setState({ matchFilter: matchFilterObj })
  }

  onCategorySearch = (value) => {
    this.setState({
      categorySearch: value
    })
  }

  onCloseChannel = () => {
    const { details } = this.props
    const currentChannel = details.program ? details.program.id : undefined
    this.props.linkAssetProgram(details.id, []).then(() => {
      if (currentChannel === this.state.videoId) {
        const videos = this.state.videoList.filter(item => !item.isProgram)
        if (videos && videos.length) {
          this.onPlayClick('vod', videos[0].id)
        } else {
          this.setState({ videoList: [], videoId: undefined })
        }
      }
      this.props.updateChannelProgram(null)
      message.success(userMessages.PROGRAM_DELETE_SUCCESS)
    }, error => {
      utilityService.handleError(error)
    })
  }

  onPlayClick = (selectedVideoType, videoId, isLinearChannel, newVideos) => {
    if (videoId === this.state.videoId && isLinearChannel === this.state.isLinearChannel) {
      return
    }
    if (videoId === this.state.videoId) {
      this.setState({ isLinearChannel })
    }
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const decodeType = isSafari ? 'HLS' : 'DASH'
    const streamType = isSafari ? 'FAIRPLAY' : 'CENC'
    if (selectedVideoType === 'channel') {
      // this.setState({ selectedVideoType, videoId, showPlayer: false, isVodApiCalling: true })
      this.setState({ selectedVideoType, videoId, isVodApiCalling: true, isLinearChannel })
      this.getProgramDRM(this.props.details.program, selectedVideoType, decodeType)
    } else {
      let selectedVideo = (this.props.details.videos || []).find(media => media.id === videoId)
      if (!selectedVideo && newVideos && newVideos.length) {
        selectedVideo = (newVideos || []).find(media => media.id === videoId)
      }
      if (this.state.showPlayer) {
        // this.setState({ showPlayer: false })
      }
      const selectedStream = (selectedVideo.streams || []).filter(item => item).find(stream => (stream.drm === (!isSafari ? 'CENC' : streamType) || stream.drm === (!isSafari ? 'CLEAR' : streamType)) && stream.transport === decodeType)
      if (selectedStream) {
        this.setState({ selectedVideoType, videoId, isVodApiCalling: true, isLinearChannel })
        this.getProgramDRM(selectedStream, selectedVideoType, decodeType)
      }
    }
  }

  editProgram = (id) => {
    const { program } = this.props.details
    this.setState({ showAddChannel: true, isFromEditChannel: true, channelDetails: program, programId: id })
  }

  handleFileUpload = (fileData) => {
    const { details } = this.props
    this.setState({ showAddVideo: false })
    fileData.assetId = details.id
    fileData.assetTitle = details.title
    fileData.assetType = details.type
    this.props.handleFileUpload(fileData)
  }

  handleImageUpload = (fileData) => {
    const { activeImageType } = this.state
    const { listImageTypes, details } = this.props
    const selectedImageType = (listImageTypes || []).find(imageType => imageType.id === activeImageType)
    const cropPos = (selectedImageType.enabledAssetTypes || []).find(innerImage => innerImage.assetType === details.type).cropPosition
    this.setState({ showAddImageFromLocal: false })
    this.props.handleFileUpload(fileData, true, activeImageType, cropPos)
  }

  keyHandlers = {
    shortcutImage: () => { this.showAddImages('images') },
    shortcutVideo: () => {
      const { details, listAssetTypes } = this.props
      const assetConfig = details ? listAssetTypes.find(item => item.title === details.type) : ''
      if (details && assetConfig && assetConfig.videoSection) {
        this.showAddVideo('videos')
      }
    },
    shortcutDuplicate: () => {
      this.setState({
        shouldShowDuplicateWarning: !this.state.shouldShowDuplicateWarning
      })
    },
    shortcutSave: () => {
      if (this.props.isEdited) {
        this.props.submitAssetDetails()
      }
    }
  }

  addingSubAsset = () => {
    this.setState({ isSaveDisabled: true })
  }

  onEditRelatedSeaonOrEpisode = () => {
    this.setState({ isSaveDisabled: false }, () => {
      this.props.refetchHistory(true)
    })
  }

  onChangeAddingNewCredits = (id) => {
    if (id) {
      const index = (this.addingNewCredits || []).findIndex(item => item === id)
      if (index > -1) {
        this.addingNewCredits.splice(index, 1)
      } else {
        this.addingNewCredits.push(id)
      }
    }
  }

  autoImageAssociate = () => {
    this.setState({ triggerAutoAssociateLoader: true })

    this.props.autoAssociateImages(this.props.details.id).then((res) => {
      const { data: {
        autoAssociateImages: { status }
      } } = res

      message.success(`${wordCapitalize(status)}`)
      this.setState({ triggerAutoAssociateLoader: false })
    }).catch((err) => {
      utilityService.handleError(err)
      this.setState({ triggerAutoAssociateLoader: false })
    })
  }

  render () {
    const {
      details,
      isSaving,
      isEdited,
      onChangeDefaultImage,
      handleGeneralDetailsChange,
      handleMetaDetailsChange,
      handleSeoDetailsChange,
      onGeneralTagChange,
      onSeoKeywordChange,
      onEmbedScriptChange,
      onEmbedScriptCaptionChange,
      onAuthorChange,
      onSocialShareChange,
      onCloseImage,
      onOrderChange,
      onRichTextChange,
      onCloseContent,
      submitAssetDetails,
      onImageCaptionChange,
      onInputFieldFocus,
      onInputFieldBlur,
      isArchive,
      isInvalidSlug,
      isInvalidTitle,
      channelList,
      onMatchSelect,
      updateStatus,
      updateSubscriptionStatus,
      createdBucket,
      onEditRelatedAssets,
      onCategorySelect,
      onCloseVideo,
      onVodTrigger,
      onMakeDisplayVideo,
      savedLiveStatus,
      mediaCategoryList,
      listAssetTypes,
      uploadingFiles,
      listMediaCategories,
      listImageTypes,
      isAssetImageLoading,
      isAssetVideoLoading,
      listGenreDetails,
      listProductionStudio,
      listRolesDetails,
      listPersonsDetails,
      listCountryDetails,
      listLanguageDetails,
      listRatingDetails,
      handleHyperionGeneralChange,
      handleChangeProductionDateTime,
      handleChangeReleaseDateTime,
      handleDurationChange,
      handleSeasonSelect,
      handleSeriesSelect,
      handleGenreChange,
      handleSubtitleChange,
      handleAudioLanguageChange,
      handleCaptionChanges,
      handleRatingChange,
      onChangeCredits,
      handleProductionStudiosChange,
      handleCountriesChange,
      onPersonSearch,
      onRoleSearch,
      onProductionStudioSearch,
      productionSearch,
      handleAssetImageCategoryChange,
      handleAutoPublishChanges,
      onContentPlaceholderChange
    } = this.props

    const {
      shouldShowWarning,
      shouldShowRestoreWarning,
      isDuplicateLoading,
      isDeleteLoading,
      isRestoreLoading,
      croppedUrl,
      showAddImage,
      currentSelectedMediaId,
      currentSelectedMediaType,
      showCropModal,
      aspectRatio,
      matchSearch,
      showEditMedia,
      isFromContent,
      settings,
      shouldShowDuplicateWarning,
      authorSearch,
      currentSelectedMediaDetails,
      showAddChannel,
      isFromEditChannel,
      channelDetails,
      channelSaveLoading,
      showPlayer,
      categorySearch,
      matchFilter,
      videoId,
      selectedVideoType,
      isVodApiCalling,
      streamRequest,
      videoList,
      isLinearChannel,
      showAddVideo,
      showAddImageFromLocal,
      isLocalFolderOpen,
      showAddVideoList,
      showVideoLoading,
      activeImageType,
      isSaveDisabled
    } = this.state
    let videos = []
    if (details && details.videos && details.videos.length) {
      videos = [...details.videos]
    }
    let selectedVideoName
    let selectedVideoDetails
    if (videoList && videoList.length) {
      const selectedVideo = videoList.find(item => item.isLinearChannel === isLinearChannel && item.id === videoId)
      if (selectedVideo) {
        selectedVideoName = selectedVideo.name
        selectedVideoDetails = selectedVideo
      }
    }
    if (videos && videos.length) {
      const selectedVideo = videos.find(item => item.isLinearChannel === isLinearChannel && item.id === videoId)
      if (selectedVideo) {
        selectedVideoDetails = selectedVideo
      }
    }
    const assetConfig = details ? listAssetTypes.find(item => item.title === details.type) : ''
    const selectedImageType = (listImageTypes || []).find(imageType => imageType.id === activeImageType)
    const selectedImageAssetType = selectedImageType && details ? (selectedImageType.enabledAssetTypes || []).find(item => item.assetType === details.type) : null
    const minImageCount = selectedImageAssetType ? selectedImageAssetType.minCount : null
    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['ASSET_MANAGER']
          const userPermissionsContent = permissions['CONTENT_BANK']
          // const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
          const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
          const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
          return <React.Fragment>
            <GlobalHotKeys keyMap={keyMap} handlers={this.keyHandlers} />
            {details && videoList && videoList.length && streamRequest && assetConfig && (assetConfig.videoSection || assetConfig.programSection) ? <Player
              type={selectedVideoType}
              key={details.id}
              assetTitle={details.title}
              selectedVideo={selectedVideoName}
              selectedVideoDetails={selectedVideoDetails}
              isLinearChannel={isLinearChannel}
              program={details.program}
              videoList={videoList}
              assetId={details.id}
              showPlayer={showPlayer}
              onPlayerClose={this.onPlayerClose}
              videoId={videoId}
              vodJobs={details.vodJobs}
              streamRequest={_.cloneDeep(streamRequest)}
              onVodTrigger={onVodTrigger}
              vodStatus={details.vodStatus && details.vodStatus.jobType === 'LIVE_TO_VOD' ? details.vodStatus : null}
              markIn={details.vodStatus && details.vodStatus.jobType === 'LIVE_TO_VOD' ? details.vodStatus.startTime : null}
              markOut={details.vodStatus && details.vodStatus.jobType === 'LIVE_TO_VOD' ? details.vodStatus.endTime : null}
              site={details.vodStatus && details.vodStatus.jobType === 'LIVE_TO_VOD' ? details.vodStatus.site : undefined}
              isPlayerReady={this.isPlayerReady}
              assetType={details.type}
              onChangeVideo={this.onPlayClick}
              onChangeShowPlayer={this.onChangeShowPlayer}
              isCollapsable
              drmRequired={details.drmRequired}
              project={project}
            /> : null}
            <div className='container' id='asset-container' ref={(node) => { this.containerRef = node }}>
              <AssetHeading title={details ? details.type : 'ASSET'}
                status={details ? details.status : null}
                type={details ? details.type : 'ARTICLE'}
                listAssetTypes={listAssetTypes}
                project={project}
                assetId={details && details.id} />
              <Accordion
                title='General'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <AssetGeneralDetails
                      details={details}
                      isInvalidTitle={isInvalidTitle}
                      onInputFieldFocus={onInputFieldFocus}
                      onInputFieldBlur={onInputFieldBlur}
                      handleGeneralDetailsChange={handleGeneralDetailsChange}
                      handleMetaDetailsChange={handleMetaDetailsChange}
                      onAuthorChange={onAuthorChange}
                      showCreateAuthorModal={this.showCreateAuthorModal}
                      onSearch={this.onAuthorSearch}
                      onMatchSearch={this.onMatchSearch}
                      matchSearch={matchSearch}
                      authorSearch={authorSearch}
                      onMatchSelect={onMatchSelect}
                      updateStatus={updateStatus}
                      updateSubscriptionStatus={updateSubscriptionStatus}
                      onCategorySelect={onCategorySelect}
                      onCategorySearch={this.onCategorySearch}
                      categorySearch={categorySearch}
                      onChangeMatchFilter={this.onChangeMatchFilter}
                      matchFilter={matchFilter}
                      isCreateDisabled={isCreateDisabled}
                      listAssetTypes={listAssetTypes}
                      project={project}
                      listGenreDetails={listGenreDetails}
                      listProductionStudio={listProductionStudio}
                      listCountryDetails={listCountryDetails}
                      listLanguageDetails={listLanguageDetails}
                      handleHyperionGeneralChange={handleHyperionGeneralChange}
                      handleChangeProductionDateTime={handleChangeProductionDateTime}
                      handleChangeReleaseDateTime={handleChangeReleaseDateTime}
                      handleDurationChange={handleDurationChange}
                      handleSeasonSelect={handleSeasonSelect}
                      handleSeriesSelect={handleSeriesSelect}
                      handleGenreChange={handleGenreChange}
                      handleSubtitleChange={handleSubtitleChange}
                      handleAudioLanguageChange={handleAudioLanguageChange}
                      handleCaptionChanges={handleCaptionChanges}
                      handleProductionStudiosChange={handleProductionStudiosChange}
                      handleCountriesChange={handleCountriesChange}
                      onProductionStudioSearch={onProductionStudioSearch}
                      productionSearch={productionSearch}
                      handleAutoPublishChanges={handleAutoPublishChanges}
                    /> : null}
                  </Skeleton>
                }
              />

              {details && assetConfig && assetConfig.metaFields
                ? <CreateMetaComponent
                  details={details}
                  assetConfig={assetConfig}
                  width={width}
                  handleMetaDetailsChange={handleMetaDetailsChange}
                />
                : null
              }

              {details && assetConfig && assetConfig.creditSection ? <Accordion
                title='Credit'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <AssetCredits
                      isCreateDisabled={isCreateDisabled}
                      isUpdateDisabled={isUpdateDisabled}
                      isCreateDisabledContent={isCreateDisabledContent}
                      isUpdateDisabledContent={isUpdateDisabledContent}
                      listRolesDetails={listRolesDetails}
                      listPersonsDetails={listPersonsDetails}
                      details={_.cloneDeep(details)}
                      onChangeCredits={onChangeCredits}
                      project={project}
                      onPersonSearch={onPersonSearch}
                      onRoleSearch={onRoleSearch}
                      onChangeAddingNewCredits={this.onChangeAddingNewCredits}
                    /> : null}
                  </Skeleton>
                }
              /> : ''}
              {details && assetConfig && (assetConfig.programSection || assetConfig.videoSection) ? <Accordion
                title='VIDEO JOB PROGRESS'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <AssetVideoJobStatus
                      jobStatus={(details.vodJobs || []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))}
                    /> : null}
                  </Skeleton>
                }
              /> : null}
              {details && assetConfig && assetConfig.programSection ? <Accordion
                title='Programs'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <AssetChannel
                      isLiveAsset={savedLiveStatus !== undefined ? savedLiveStatus : details.isLive}
                      assetType={details.type}
                      showAddChannel={this.showAddChannel}
                      onCloseChannel={this.onCloseChannel}
                      editProgram={this.editProgram}
                      channel={_.cloneDeep(details.program)}
                      assetId={details.id}
                      disableAutoTrigger={(details.match && details.match.isLiveOnly) || (assetConfig && !assetConfig.isLveToVodSupported)}
                      isPlaying={!isLinearChannel && details.program && videoId === details.program.id}
                      isLoadingPlayer={isVodApiCalling && details.program && videoId === details.program.id}
                      onPlayClick={(id) => this.onPlayClick('channel', details.program.id)}
                      vodStatus={details.vodStatus}
                      jobList={details.vodJobs}
                      // isButtonDisabled={userPermissions.indexOf('CREATE') === -1}
                      isUpdateDisabled={isUpdateDisabled}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              /> : null}
              {details && assetConfig && assetConfig.videoSection ? <Accordion
                title='Video'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    <AssetVideos
                      videos={videos}
                      selectedVideo={videoId}
                      isLoadingPlayer={isVodApiCalling}
                      loadingVideo={videoId}
                      image={details.vodStatus && details.vodStatus.channel ? details.program.channel.icon : details.program && details.program.channel ? details.program.channel.icon : null}
                      onPlayClick={this.onPlayClick}
                      showAddVideo={this.showAddVideo}
                      onCloseVideo={onCloseVideo}
                      showEditVideo={this.showVideoEditor}
                      defaultVideo={details.defaultVideo ? details.defaultVideo.id : undefined}
                      makeDisplayVideo={onMakeDisplayVideo}
                      isDeleteDisabled={isUpdateDisabled}
                      isAssetVideoLoading={isAssetVideoLoading}
                    />
                  </Skeleton>
                }
              /> : null}

              {details && assetConfig && assetConfig.parentalRatingSection ? <Accordion
                title='Rating'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    <CreateRatingComponent
                      listRatingDetails={listRatingDetails}
                      ratings={_.cloneDeep(details.ratings || [])}
                      handleRatingChange={handleRatingChange}
                      isUpdateDisabled={isUpdateDisabled}
                    />
                  </Skeleton>
                }
              /> : ''}

              <Accordion
                title='Tags'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <div className='tags-dummy-container' id='tags-dummy-container'>
                      <AssetTags
                        onGeneralTagChange={onGeneralTagChange}
                        selectedItems={details.tags ? details.tags.filter(item => item) : []}
                        assetType={details.type || 'ASSET'}
                        project={project}
                      />
                    </div> : null}
                  </Skeleton>
                }
              />

              <Accordion
                title='Images'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <div className='image-type-container'>
                      <AssetImages
                        images={(details.images || [])}
                        defaultMedia={details.defaultMedia}
                        onAddImage={this.onAddMedia}
                        onChangeDefaultImage={onChangeDefaultImage}
                        onCloseImage={onCloseImage}
                        showEditImage={this.showImageEditor}
                        onImageTypeChange={this.onImageTypeChange}
                        isButtonDisabled={isUpdateDisabled}
                        isUpdateImageDisabled={isUpdateDisabledContent}
                        uploadingFiles={_.cloneDeep(uploadingFiles)}
                        isLoading={isAssetImageLoading}
                        activeImageType={activeImageType}
                        project={project}
                        listImageTypes={(listImageTypes || []).filter(imageType => {
                          const imageTempIndex = (imageType.enabledAssetTypes || []).findIndex(innerImage => innerImage.assetType === details.type)
                          const tempImgIndex = (details.images || []).findIndex(innerImage => innerImage.type.name === imageType.name)
                          return imageTempIndex > -1 || tempImgIndex > -1
                        })}
                        mediaCategoryList={mediaCategoryList}
                        handleAssetImageCategoryChange={handleAssetImageCategoryChange}
                      />
                      <div className='button-container'>
                        <span>Add Images from:</span>
                        <div style={{ width: '150px' }}>
                          <Button
                            buttonText={'Content Bank'}
                            color='red'
                            onClick={() => this.showAddImages('images')}
                            style={{ width: '150px' }}
                            isDisabled={isUpdateDisabled || _.isEmpty(selectedImageAssetType)}
                          />
                        </div>
                        <div style={{ width: '150px' }}>
                          <Button
                            buttonText={'Local Drive'}
                            color='red'
                            onClick={() => this.showAddImageLocal(true)}
                            style={{ width: '150px' }}
                            isDisabled={isUpdateDisabled || isCreateDisabledContent || _.isEmpty(selectedImageAssetType)}
                          />
                        </div>
                        {selectedImageType && !(minImageCount === null || minImageCount === undefined) ? <div className='mandatory-count'>
                          {
                            project === 'vcms' &&
                            <div style={{ width: '150px', marginRight: '5px' }}>
                              <Button
                                buttonText={'Auto Associate'}
                                color='red'
                                onClick={() => this.autoImageAssociate()}
                                style={{ width: '150px' }}
                                isLoading={this.state.triggerAutoAssociateLoader}
                              />
                            </div>
                          }
                          <span>Req No of Images: </span>
                          <span>{minImageCount}</span>
                        </div> : null}
                      </div>
                    </div> : null}
                  </Skeleton>
                }
              />
              {details && assetConfig && assetConfig.contentSection ? <Accordion
                title='Content'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 10, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <ArticleContents
                      defaultMedia={details.defaultMedia}
                      placeholderList={this.props.placeholderList}
                      author={details.author}
                      content={details.contents || []}
                      onInputFieldFocus={onInputFieldFocus}
                      onInputFieldBlur={onInputFieldBlur}
                      onCloseContent={onCloseContent}
                      onRichTextChange={onRichTextChange}
                      onOrderChange={onOrderChange}
                      onAddContent={this.onAddContent}
                      onEmbedScriptChange={onEmbedScriptChange}
                      onEmbedScriptCaptionChange={onEmbedScriptCaptionChange}
                      onSocialShareChange={onSocialShareChange}
                      onContentPlaceholderChange={onContentPlaceholderChange}
                      showEditImage={this.showImageEditor}
                      onImageCaptionChange={onImageCaptionChange}
                      isUpdateDisable={isUpdateDisabled}
                      assetId={this.props.assetId}
                      isUpdateImageDisabled={isUpdateDisabledContent}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              /> : null}

              {details && assetConfig && assetConfig.assetTypeCategory && (assetConfig.assetTypeCategory === 'SERIES' || assetConfig.assetTypeCategory === 'SEASON') ? <Accordion
                title={assetConfig.assetTypeCategory === 'SERIES' ? `Seasons` : 'Episodes'}
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 6, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <RelatedSeasons
                      bucketId={details && details.relatedAssets ? details.relatedAssets.id : null}
                      parentAssetId={details.id}
                      createdBucket={createdBucket}
                      onEditRelatedSeaonOrEpisode={this.onEditRelatedSeaonOrEpisode}
                      isSaving={isSaving}
                      isUpdateDisable={userPermissions.indexOf('UPDATE') === -1}
                      project={project}
                      isSeason={assetConfig.assetTypeCategory === 'SEASON'}
                      assetList={assetConfig.assetTypeCategory === 'SEASON'
                        ? (details.episodes || []).map(item => ({ ...item, id: details.id + item.assetId })) : (details.seasons || []).map(item => ({ ...item, id: details.id + item.assetId }))}
                      addingSubAsset={this.addingSubAsset}
                    /> : null}
                  </Skeleton>
                }
              /> : ''}

              <Accordion
                title='RELATED ASSETS'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 6, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <RelatedAssets
                      bucketId={details && details.relatedAssets ? details.relatedAssets.id : null}
                      assetId={details.id}
                      createdBucket={createdBucket}
                      onEditRelatedAssets={onEditRelatedAssets}
                      isSaving={isSaving}
                      isUpdateDisable={userPermissions.indexOf('UPDATE') === -1}
                      project={project}
                    /> : null}
                  </Skeleton>
                }
              />

              <Accordion
                title='SEO'
                childComponent={
                  <Skeleton active avatar={false} title={false} paragraph={{ rows: 6, width: width }}
                    loading={this.props.isLoading}>
                    {details ? <SeoSettings
                      assetId={details.id}
                      isInvalidSlug={isInvalidSlug}
                      details={{ slug: details.slug, title: details.seoTitle, metaDescription: details.seoMetaDescription, keywords: details.seoKeywords, seoCanonicalUrl: details.seoCanonicalUrl }}
                      onInputFieldFocus={onInputFieldFocus}
                      onInputFieldBlur={onInputFieldBlur}
                      handleSeoDetailsChange={handleSeoDetailsChange}
                      onSeoKeywordChange={onSeoKeywordChange}
                      defaultTitle={details.title}
                    /> : null}
                  </Skeleton>
                }
              />
            </div>
            {details ? <div className='asset-details-footer'>
              {!isArchive && !details.isArchived ? <ButtonContainer displayTitle='Archive' childComponent={<ArchiveButton onClick={() => this.toggleWarningMessage(true)} isDisabled={isSaving || isUpdateDisabled} />} /> : null}
              {!isArchive && details.isArchived ? <ButtonContainer displayTitle='Restore' childComponent={<RestoreButton onClick={() => this.toggleRestoreWarningMessage(true)} isDisabled={isSaving || isUpdateDisabled} />} /> : null}
              <ConfirmModal isVisible={shouldShowWarning} title={deleteWarningMessage.title} message={deleteWarningMessage.message} isLoading={isDeleteLoading} isLeftPrior rightButtonText={'Confirm'} handleCancel={() => this.toggleWarningMessage(false)} handleSubmit={this.deleteCurrentAsset} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <ConfirmModal isVisible={shouldShowRestoreWarning} title={restoreWarningMessage.title} message={restoreWarningMessage.message} isLoading={isRestoreLoading} rightButtonText={'Confirm'} handleCancel={() => this.toggleRestoreWarningMessage(false)} handleSubmit={this.restoreCurrentAsset} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
              <div className='right'>
                <div className='side-button'>
                  <ButtonContainer displayTitle='Duplicate' childComponent={<DuplicateButton onClick={() => this.toggleDuplicateWarningMessage(true)} isDisabled={isSaving || isEdited || isArchive || isCreateDisabled} />} />
                  <ConfirmModal isVisible={shouldShowDuplicateWarning} title={duplicateWarningMessage.title} message={duplicateWarningMessage.message} isLoading={isDuplicateLoading} rightButtonText={'Confirm'} handleCancel={() => this.toggleDuplicateWarningMessage(false)} handleSubmit={this.duplicateAssets} isSubmitButtonDisabled={false} isCancelButtonDisabled={false} />
                  <ButtonContainer displayTitle='Toggle Sidebar' childComponent={<SidebarButton onClick={this.props.toggleSidebar} />} />
                </div>
                <div style={{ width: '150px' }}>
                  <LoadingButton type='primary' onClick={() => submitAssetDetails()} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isSaving} isDisabled={!isEdited || isUpdateDisabled || isSaveDisabled || !_.isEmpty(this.addingNewCredits)} />
                </div>
                <p>{isSaving ? 'Saving...' : `Last saved at ${moment(new Date(details.updatedAt)).format('DD MMM YYYY, hh:mm A')}`}</p>
              </div>
            </div> : null}
            <CropImageModal
              isVisible={showCropModal}
              aspectRatio={aspectRatio}
              imgUrl={settings && settings.length ? settings[0].fileName : null}
              settings={settings}
              updateSettings={this.saveCurrentCrop}
              handleCancel={this.hideCropModal}
              isUpdateBlocked={isUpdateDisabledContent} />
            <AddImageModal
              isMultiSelect={false}
              isVisible={this.state.showAddImageModal}
              handleSubmit={this.saveCurrentSelection}
              handleCancel={this.hideAddImageModal}
              mediaType='IMAGE'
              isUpdateDisabled={isUpdateDisabled}
              isSubmitButtonDisabled={isCreateDisabledContent}
              project={project} />
            <CreateAuthorModal
              clearSelection={this.clearSelectedImage}
              isVisible={this.state.showCreateAuthorModal}
              croppedUrl={croppedUrl}
              showCropModal={this.showCropModal}
              selectedImage={this.state.selectedImage}
              showImageModal={this.showAddImageModal}
              handleCancel={this.hideCreateAuthorModal}
              handleSubmit={this.onCreateAuthor}
              isUpdateBlocked={isUpdateDisabledContent}
              module='ASSET_MANAGER'
              project={project} />
            {details ? <EditImageModal
              assetId={this.currentId || details.id}
              type={currentSelectedMediaType}
              isVisible={showEditMedia}
              hideImageEditor={this.hideImageEditor}
              imageId={currentSelectedMediaId}
              mediaDetails={currentSelectedMediaDetails}
              categoryList={mediaCategoryList}
              isUpdateBlocked={isUpdateDisabledContent}
              isUploadBlocked={isCreateDisabledContent}
              selectedImageType={(listImageTypes || []).find(item => item.id === activeImageType)}
              isFromAsset={!isFromContent}
              refetchHistory={this.props.refetchHistory}
              project={project} /> : null}
            <AddImageModal
              onChangeModal={this.showAddVideo}
              isMultiSelect isVisible={showAddImage || showAddVideoList}
              type={this.addImageType} handleCancel={this.hideAddImage}
              isUpdateDisabled={isUpdateDisabled}
              handleSubmit={this.onAddMedia}
              isSubmitButtonDisabled={isCreateDisabledContent || (showAddImage && this.addImageType !== 'contents')}
              isCancelButtonDisabled={false}
              currentSelection={this.props.details && this.addImageType !== 'contents'
                ? ([...(this.props.details.images || [])]).filter(item => item.type.id === activeImageType) : []}
              mediaType={showAddVideoList ? 'VIDEO' : 'IMAGE'}
              project={project} />
            <AddVideoModal
              isMultiSelect
              isVisible={showAddVideo}
              videoCategoryList={mediaCategoryList.filter((item) => item.type === 'VIDEO' && item.isActive)}
              handleCancel={this.hideAddVideo}
              handleSubmit={this.onTriggerVod}
              isSubmitButtonDisabled={isCreateDisabled}
              isCancelButtonDisabled={false}
              isLoading={showVideoLoading}
              handleFileUpload={this.handleFileUpload}
              onChangeModal={this.showAddVideo}
              project={project} />
            <AddImageFromLocalFolderModal
              isVisible={showAddImageFromLocal}
              handleCancel={() => this.showAddImageLocal(false)}
              handleSubmit={this.handleImageUpload}
              isSubmitButtonDisabled={isCreateDisabled}
              isCancelButtonDisabled={false}
              isLoading={false}
              isLocalFolderOpen={isLocalFolderOpen}
              listMediaCategories={listMediaCategories}
              onChangeModal={this.showAddImageFromLocal}
              project={project} />
            <AddAssetChannelModal
              isVisible={showAddChannel}
              handleCancel={this.hideAddChannel}
              handleSubmit={this.onAddChannel}
              channelList={channelList}
              isFromEdit={isFromEditChannel}
              details={channelDetails}
              type={details ? details.type : null}
              isLoading={channelSaveLoading} />
          </React.Fragment>
        }}
      </AppContext.Consumer>
    )
  }
}

AssetDetails.propTypes = {
  /** is edited details status of AssetDetails. */
  isEdited: PropTypes.bool.isRequired,
  /** is Saving details status of AssetDetails. */
  isSaving: PropTypes.bool.isRequired,
  /** toggle side bar value of AssetDetails. */
  showSidebar: PropTypes.bool,
  /** toggle side bar action of AssetDetails. */
  toggleSidebar: PropTypes.func,
  /** details of AssetDetails. */
  details: PropTypes.object,
  /** default image change action of AssetDetails. */
  onChangeDefaultImage: PropTypes.func.isRequired,
  /** add image action of AssetDetails. */
  onAddMedia: PropTypes.func.isRequired,
  /** general data change action of AssetDetails. */
  handleGeneralDetailsChange: PropTypes.func.isRequired,
  /** seo data change action of AssetDetails. */
  handleSeoDetailsChange: PropTypes.func.isRequired,
  /** tag change action of AssetDetails. */
  onGeneralTagChange: PropTypes.func.isRequired,
  /** keyword change action of AssetDetails. */
  onSeoKeywordChange: PropTypes.func.isRequired,
  /** add content action of AssetManager. */
  onAddContent: PropTypes.func.isRequired,
  /** author change action of AssetManager. */
  onAuthorChange: PropTypes.func.isRequired,
  /** social feed change action of AssetManager. */
  onSocialShareChange: PropTypes.func.isRequired,
  /** embed script change action of AssetManager. */
  onEmbedScriptChange: PropTypes.func.isRequired,
  /** embed script caption change action of AssetManager. */
  onEmbedScriptCaptionChange: PropTypes.func.isRequired,
  /** Rich text value change action of AssetManager. */
  onRichTextChange: PropTypes.func.isRequired,
  /** image close action of AssetManager. */
  onCloseImage: PropTypes.func.isRequired,
  /** content order change action of AssetManager. */
  onOrderChange: PropTypes.func,
  /** on content close action of ArticleContents. */
  onCloseContent: PropTypes.func,
  /** submit asset details action of ArticleContents. */
  submitAssetDetails: PropTypes.func,
  /** image caption change of ArticleContents. */
  onImageCaptionChange: PropTypes.func,
  /** create asset content action of AssetManager. */
  createContentItem: PropTypes.func.isRequired,
  /** delete asset  ArticleManager. */
  archiveAsset: PropTypes.func,
  /** delete asset  ArticleManager. */
  onDeleteAsset: PropTypes.func,
  /** For loading. */
  isLoading: PropTypes.bool,
  /* History parameter of dom */
  history: PropTypes.object,
  /** Function to be called on channel details update */
  updateChannelProgram: PropTypes.func,
  /** Function to be called on match select */
  onMatchSelect: PropTypes.func,
  /** Function to be called on live status change */
  updateStatus: PropTypes.func,
  /** Function to be called on subscription status change */
  updateSubscriptionStatus: PropTypes.func,
  /** Function to be called to create bucket in related assets */
  createdBucket: PropTypes.func,
  /** Function to be called on related assets update */
  onEditRelatedAssets: PropTypes.func,
  /** Function to be called on category select */
  onCategorySelect: PropTypes.func,
  /** Details of GenreList */
  listGenreDetails: PropTypes.array,
  /** Details of ProductionStudioList */
  listProductionStudio: PropTypes.array,
  /** Details of RolesList */
  listRolesDetails: PropTypes.array,
  /** Details of PersonsList */
  listPersonsDetails: PropTypes.array,
  /** Details of CountriesList */
  listCountryDetails: PropTypes.array,
  /** Details of LanguagesList */
  listLanguageDetails: PropTypes.array,
  /** array data of ratingField */
  listRatingDetails: PropTypes.array,
  /** hyperion generalChanges func */
  handleHyperionGeneralChange: PropTypes.func.isRequired,
  /** function to handleProductionDateTime */
  handleChangeProductionDateTime: PropTypes.func.isRequired,
  /** function to handleDurationTime */
  handleDurationChange: PropTypes.func.isRequired,
  /** function to handleSeasonOption select */
  handleSeasonSelect: PropTypes.func.isRequired,
  /** function to handleSeriesOption select */
  handleSeriesSelect: PropTypes.func.isRequired,
  /** function to handleCountriesChange select */
  handleCountriesChange: PropTypes.func.isRequired,
  /** function to handleGenreOption select */
  handleGenreChange: PropTypes.func.isRequired,
  /** function to handleSubtitleOption select */
  handleSubtitleChange: PropTypes.func.isRequired,
  /** function to handleAudioLanguageOption select */
  handleAudioLanguageChange: PropTypes.func.isRequired,
  /** function to handleCaptionChangesOption select */
  handleCaptionChanges: PropTypes.func.isRequired,
  /** function to handle rating change */
  handleRatingChange: PropTypes.func.isRequired,
  /** function to handleProductionStudiosChange select */
  handleProductionStudiosChange: PropTypes.func.isRequired
}

// export default AssetDetails
export default withApollo(compose(
  graphql(
    MutationArchiveAsset,
    {
      props: (props) => ({
        archiveAsset: (id) => {
          return props.mutate({
            variables: { id, isArchived: true, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updateAsset } }) => {
          const { searchString, filter, sort } = props.appliedFilter
          const variables = utilityService.getFormattedAssetFilter(searchString, filter, sort, props.project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllAssets, variables }))
          if (variables.filter.isArchived && (variables.filter.isArchived.match === true || variables.filter.isArchived.match === 'true')) {
            if (cacheData && cacheData.listAssets && cacheData.listAssets.items) {
              const index = cacheData.listAssets.items.findIndex(item => item.id === updateAsset.id)
              if (index === -1) {
                cacheData.listAssets.items.splice(0, 0, updateAsset)
                cacheData.listAssets.totalCount = cacheData.listAssets.totalCount + 1
              }
            }
          } else if (variables.filter.isArchived && (variables.filter.isArchived.match === false || variables.filter.isArchived.match === 'false')) {
            if (cacheData && cacheData.listAssets && cacheData.listAssets.items) {
              const index = cacheData.listAssets.items.findIndex(item => item.id === updateAsset.id)
              if (index > -1) {
                cacheData.listAssets.items.splice(index, 1)
                cacheData.listAssets.totalCount = cacheData.listAssets.totalCount - 1
              }
            }
          }

          cache.writeQuery({
            query: QueryAllAssets,
            data: cacheData,
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationArchiveAsset,
    {
      props: (props) => ({
        restoreAsset: (id) => {
          return props.mutate({
            variables: { id, isArchived: false, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { updateAsset } }) => {
          const { searchString, filter, sort } = props.appliedFilter
          const variables = utilityService.getFormattedAssetFilter(searchString, filter, sort, props.project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllAssets, variables }))
          if (!filter.isArchived || (filter.isArchived && (filter.isArchived.match === true || filter.isArchived.match === 'true'))) {
            if (cacheData && cacheData.listAssets && cacheData.listAssets.items) {
              const index = cacheData.listAssets.items.findIndex(item => item.id === updateAsset.id)
              if (index > -1) {
                cacheData.listAssets.items.splice(index, 1)
                cacheData.listAssets.totalCount = cacheData.listAssets.totalCount - 1
              }
            }
          } else if (filter.isArchived && (filter.isArchived.match === false || filter.isArchived.match === 'false')) {
            if (cacheData && cacheData.listAssets && cacheData.listAssets.items) {
              const index = cacheData.listAssets.items.findIndex(item => item.id === updateAsset.id)
              if (index === -1) {
                cacheData.listAssets.items.splice(0, 0, updateAsset)
                cacheData.listAssets.totalCount = cacheData.listAssets.totalCount + 1
              }
            }
          }
          cache.writeQuery({
            query: QueryAllAssets,
            data: cacheData,
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateContent,
    {
      props: (props) => ({
        createContentItem: (contentObj) => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
  graphql(
    MutationCreateMediaLinks,
    {
      props: (props) => ({
        addMediaLinks: (imageObjArray) => {
          return props.mutate({
            variables: { imageObjArray }
          })
        }
      })
    }),
  graphql(
    MutationUpdateMedia, {
      options: {
      },
      skip: ({ id, name, tags, settings }) => {
        return !!(!id || !name || !tags || !settings)
      },
      props: (props) => ({
        updateImage: (id, name, tags, settings) => {
          return props.mutate({
            variables: { id, name, tags, settings, updatedAt: new Date().toISOString(), project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationDuplicateArticle,
    {
      props: (props) => ({
        duplicateAsset: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      }),
      options: (props) => ({
        update: (cache, { data: { duplicateAsset } }) => {
          // const sortData = {
          //   value: 'createdAt',
          //   order: 'desc'
          // }
          const { searchString, filter, sort } = props.appliedFilter
          const variables = utilityService.getFormattedAssetFilter(searchString, filter, sort, props.project)

          // const variables = utilityService.getFormattedAssetFilter('', undefined, sortData, props.project)
          const listCacheData = _.cloneDeep(cache.readQuery({ query: QueryAllAssets, variables }))
          if (listCacheData && listCacheData.listAssets && listCacheData.listAssets.items) {
            listCacheData.listAssets.items.splice(0, 0, duplicateAsset)
            listCacheData.listAssets.totalCount = listCacheData.listAssets.totalCount + 1
          }
          cache.writeQuery({
            query: QueryAllAssets,
            data: listCacheData,
            variables
          })
        }
      })
    }),
  graphql(
    MutationCreateChannel,
    {
      props: (props) => ({
        createProgram: (channelId, startTime, endTime) => {
          return props.mutate({
            variables: { channelId, startTime, endTime, project: props.ownProps.project }
          })
        }
      })
    }),
  graphql(
    MutationUpdateChannel,
    {
      props: (props) => ({
        updateProgram: (id, channelId, startTime, endTime) => {
          return props.mutate({
            variables: { id, channelId, startTime, endTime }
          })
        }
      })
    }),
  graphql(
    QueryListChannel,
    {
      options: ({ project }) => {
        const filter = {
          isArchived: {
            match: false
          }
        }
        return {
          variables: { filter, project }
        }
      },
      props: (props) => {
        return {
          channelList: (props.data && props.data.listChannels) ? getSortedList(props.data.listChannels.items) : []
        }
      }
    }
  ),
  graphql(
    MutationLinkAssetProgram,
    {
      props: (props) => ({
        linkAssetProgram: (assetId, programs) => {
          return props.mutate({
            variables: { assetId, programs }
          })
        }
      })
    }
  ),
  graphql(
    QueryMediaCategory,
    {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { project }
        }
      },
      props: (props) => {
        return {
          listMediaCategories: props.data.listMediaCategories && props.data.listMediaCategories && props.data.listMediaCategories.length ? props.data.listMediaCategories : []
        }
      }
    }
  ),
  graphql(
    QueryContentPlaceholder,
    {
      options: ({ project }) => {
        const variables = { limit: 1000, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const placeholderList = data.listContentPlaceHolder ? data.listContentPlaceHolder.items : []
        return {
          placeholderList
        }
      }
    }
  ),
  graphql(
    autoAssociateImages,
    {
      props: (props) => ({
        autoAssociateImages: (assetId) => {
          return props.mutate({
            variables: { project: props.ownProps.project, assetId }
          })
        }
      })
    })
)(withRouter(withApollo(AssetDetails))))
