import React, { Component } from 'react'
import { Table, Empty, Skeleton } from 'antd'
import { Resizable } from 'react-resizable'

import Tooltip from './../dataDisplay/ToolTip'
import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import listUserAntiPiracyActions from '../../../graphQL/customers/listUserAntiPiracyActions'
import { utilityService } from '../../../services/UtilityService'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}
class TransactionTable extends Component {
  constructor (props) {
    super(props)

    this.columns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        width: '20%',
        key: 'createdAt',
        render: (createdAt) => this.renderCreatedDate(createdAt)
      },
      {
        title: 'Action',
        dataIndex: 'actionDetails',
        key: 'actionDetails',
        width: '20%',
        render: (actionDetails) => this.renderAction(actionDetails)
      },
      {
        title: 'Internal Notes',
        dataIndex: 'operatorNote',
        key: 'operatorNote',
        width: '60%'
        // render: (operatorNote) => this.renderNote(operatorNote)
      }
    ]
    this.state = {
      width: null,
      height: null,
      columns: this.columns
    }
    this.tableSize = React.createRef()

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
  }

  componentDidMount = () => {
    this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.isActive !== this.props.isActive) {
      setTimeout(() => {
        this.setState({ width: this.tableSize.current.clientWidth, height: this.tableSize.current.clientHeight })
      }, 100)
    }
    if (newProps.refetch === true) {
      this.props.getUserAttributes()
    }
  }

  showViewOfferModal = (child, type) => {
    this.setState({ showOfferModal: true, offerChild: child, type })
  }

  hideViewOfferModal = () => {
    this.setState({ showOfferModal: false, offerChild: null })
  }

  renderAction = (actionDetails) => {
    let subActions = []
    let content = <div />
    if (actionDetails.subActions) {
      subActions = actionDetails.subActions.map(subAction => {
        return subAction.subAction
      })
      content = <ul>
        {subActions && subActions.map(subAction => {
          return <li> {subAction} </li>
        })}</ul>
    }
    return (
      <div>
        <div style={{ width: 'fit-content' }}>
          <Tooltip className='anti-piracy-tooltip' placement='right'
            childComponent={<div id='sub-action-popover-content' className='comments' style={{ width: 'fit-content' }}>{actionDetails && actionDetails.action ? actionDetails.action : '' } </div>}
            description={content}
            parentCompoent='sub-action-popover' />
        </div>
      </div>
    )
  }

  renderNote = (operatorNote) => {
    let operatorNoteShort = operatorNote.substring(0, 100)
    return (
      <div>
        <div style={{ width: 'fit-content' }}>
          <Tooltip className='anti-piracy-tooltip' placement='top'
            childComponent={<div id='sub-action-popover-content' style={{ width: 'fit-content' }}>{operatorNoteShort} </div>}
            description={operatorNote}
            parentCompoent='sub-action-popover' />
        </div>
      </div>
    )
  }

  renderCreatedDate = (createdAt) => {
    return createdAt && createdAt
      ? moment(new Date(createdAt)).format('DD MMM YYYY, HH:mm:ss') : ''
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }

  render () {
    const { attributes, isLoading } = this.props
    const { width, height, columns } = this.state

    const column = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }))

    return (
      <div className='anti-piracy-table' id='sub-action-popover' ref={this.tableSize}>
        <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
          { !_.isEmpty(attributes) ? <Table bordered components={this.components} className={``} rowKey={record => record.id} columns={column} dataSource={attributes} pagination={false} scroll={{ x: width, y: (height) }} />
            : <Empty /> }
        </Skeleton>
      </div>
    )
  }
}

TransactionTable.propTypes = {}

export default withApollo(compose(
  graphql(
    listUserAntiPiracyActions,
    {
      skip: ({ selectedCustomer }) => !selectedCustomer,
      options: (props) => {
        const { selectedCustomer, project } = props
        const variables = { userId: selectedCustomer, project: project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const attributes = data && data.listUserAntiPiracyActions && data.listUserAntiPiracyActions.data ? data.listUserAntiPiracyActions.data : []
        const error = data.error ? data.error.message : null
        if (error) {
          utilityService.handleCustomerManagerError(error)
        }
        return {
          attributes,
          isLoading: data && data.loading,
          getUserAttributes: () => {
            return props.data.refetch()
          }
        }
      }
    }
  )
)(TransactionTable))
