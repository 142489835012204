import React from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import './../ui.style.scss'
import _ from 'lodash'
import Input from './../dataEntry/inputs/Input'
import DropDown from './../dataEntry/inputs/DropDown'
import { utilityService } from './../../../services/UtilityService'
import userMessage from './../../../constants/messages'
// import TagsInput from './../dataEntry/inputs/TagsInput'

class AddBucketModal extends React.Component {
  state = {
    name: this.props.bucketDetails ? this.props.bucketDetails.name : '',
    key: this.props.bucketDetails ? this.props.bucketDetails.key : '',
    displayName: this.props.bucketDetails ? this.props.bucketDetails.displayName : '',
    displayType: this.props.bucketDetails && this.props.bucketDetails.displayType ? this.props.bucketDetails.displayType.id : '',
    tags: []
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (!_.isEqual(newProps.bucketDetails, this.props.bucketDetails) || !this.state.name) {
      this.setState({
        name: newProps.bucketDetails ? newProps.bucketDetails.name : '',
        key: newProps.bucketDetails ? newProps.bucketDetails.key : '',
        displayName: newProps.bucketDetails ? newProps.bucketDetails.displayName : '',
        displayType: newProps.bucketDetails && newProps.bucketDetails.displayType ? newProps.bucketDetails.displayType.id : '',
        tags: []
      })
    }
  }

  handleTitleChange =(e) => {
    const { value } = e.target
    const { isEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.name || '')
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isEdit) {
      const key = utilityService.slugifyString(value).slice(0, 100).toLocaleUpperCase()
      this.setState({ name: value, key })
    } else {
      this.setState({ name: value })
    }
  }

  handleIdChange =(e) => {
    const { value } = e.target
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(value) ? value.toLocaleUpperCase() : this.state.key
    this.setState({ key })
  }

  handleDisplayTitleChange =(e) => {
    const { value } = e.target
    this.setState({ displayName: value })
  }

  onDisplayTypeChange = value => {
    this.setState({ displayType: value })
  }

  resetState = () => {
    const state = {
      name: '',
      key: '',
      displayName: '',
      displayType: '',
      tags: []
    }
    this.setState({ ...state })
  }

  handleCreateBucket = () => {
    const { displayName, name, displayType, key } = this.state
    // let isNameEmpty = false, isdisplayNameEmpty = false, isDisplayTypeEmpty = false
    if (!name) {
      // isNameEmpty = true
      message.error(userMessage.EMPTY_BUCKET_NAME)
    }
    if (!displayName) {
      // isdisplayNameEmpty = true
      message.error(userMessage.EMPTY_BUCKET_DISPLAY_NAME)
    }
    if (!displayType) {
      // isDisplayTypeEmpty = true
      message.error(userMessage.EMPTY_BUCKET_DISPLAY_TYPE)
    }
    if (name && displayName && displayType) {
      const request = {
        name,
        displayType,
        displayName: displayName,
        key
      }
      this.props.createBucket(request)
    }
  }

  render () {
    const { visible, handleCancel, displayTypes, isEdit, loading } = this.props
    const { displayName, name, displayType, key } = this.state
    const tempTitle = <div className='title'>
      <p>{isEdit ? 'EDIT BUCKET' : 'ADD BUCKET'}</p>
      <p>{isEdit && key ? key : ''}</p>
    </div>
    return (

      <Modal
        className='general-modal add-bucket-modal'
        visible={visible}
        closable={false}
        title={tempTitle}
        maskClosable={false}
        onOk={this.handleCreateBucket}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !(displayName && name && displayType && key), loading: loading }}
        okText={isEdit ? 'Save' : 'Add Bucket'}
        cancelText='Back'
        afterClose={this.resetState}
        centered
        destroyOnClose
      >
        <Input title='Name' handleChange={this.handleTitleChange} value={name} isRequired limit={100} />
        <Input title='Key' handleChange={this.handleIdChange} value={key} isRequired limit={100} readOnly={isEdit} />
        {/* <div className='input-field'>
          <label className='input-title'>Competition</label>
          <DropDown options={options} displayParam='name' />
        </div> */}
        <Input title='Display Name' handleChange={this.handleDisplayTitleChange} value={displayName} isRequired limit={100} />
        <div className='input-field' id={'display-type-id'}>
          <label className='input-title'>Display Type</label>
          <span className='mandatory'>*</span>
          <DropDown options={displayTypes || []} selectedValue={displayType} displayParam='name' valueParam='id' onOptionSelect={this.onDisplayTypeChange} parent={'display-type-id'} />
        </div>
        {/* <label>Tags</label> */}
        {/* <TagsInput selectedItems={selectedItems} tagList={tagsList} displayParameter='tag' /> */}
      </Modal>

    )
  }
}

AddBucketModal.propTypes = {
  /** visible action of AddBucketModal. */
  visible: PropTypes.bool,
  /** title  of AddBucketModal. */
  title: PropTypes.string,
  /** handleCancel action of AddBucketModal. */
  handleCancel: PropTypes.func,
  /** Boolean to confirm modal is edit or not */
  isEdit: PropTypes.bool,
  /** Boolean for showing loading */
  loading: PropTypes.bool
}

AddBucketModal.defaultProps = {
}

export default AddBucketModal
