import gql from 'graphql-tag'

export default gql(`
query($id: ID! $project: String) {
    getAsset(
            id: $id
            project: $project
        ) {
        id
        isInTransition
        isLive
        duration
        defaultVideo{
            id
        }
        vodJobs{
            id
            status
            site
            name
            type
            jobType
            vwmJobId
            updatedAt
            createdAt
            createdBy
            progress
            errorDesc
            startTime
            endTime 
            jobStates{
                status
                errorCode
                updatedAt
            }
            channel{
                id
            } 
            mediaId{
                id
                duration
            }  
        }
    }
}`)
