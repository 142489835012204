import gql from 'graphql-tag'

export default gql(`
query getCustomer( $id: ID!, $cognitoUserId: String $project: String ) {
    getCustomer( 
      id: $id,
      cognitoUserId: $cognitoUserId
      project: $project
    ) {
        id
        firstName
        lastName
        email
        mobile
        dateOfBirth
        emailVerified
        mobileVerified
        address
        postcode
        city
        state
        country
        externalId
        analyticUserId
        cognitoUserId
        registrationDate
        receiveEmail
        receiveSms
        isTestUser
        useCleeng
        cleengGhostUser
        activeSubscriptions {
            id
        }
        purchaseHistory{
            id
            status
            subscriptionId
            transactionDate
            transactionAmount
            coveragePeriod
            paymentMethod
            offerSnapshot{
              id
              offerCode
              title
              description
              type
              segmentTag
              period
              autoRenewPeriod
              subscriptionType
              createdDate
              updatedDate
              price
              freePeriod
              validUntil
              maxUsagePerUser
              additionalAccessGroups{
                id
                name
              }
            }
            subscriptionTypeSnapshot{
              id
              title
              description
              accessGroups{
                id
                name
              }
              activeSession
              qualityOfStreams
            }
            systemLog{
              requestPayload
              error
            }
        }
        contactId
        eligibleForHyperion
        status
        selectedBillingAccountId
        receiveServiceUpdateEmail
        receiveServiceUpdateSms
        receiveBillReminderEmail
        receiveBillReminderSms
        tncAcceptedOn
        billingAccounts{
          billCycleCloseDay
          billingAccountId
          billingAccountNumber
          billingAccountStatus
          collectionStatus
          contactRole
          customerId
          eligibleForHyperion
          services{
              activationDate
              lineOfBusiness
              planDescription
              planId
              planName
              productId
              recontractionDate
              serviceId
              serviceStatus
              serviceType
              shared
              statusChangeDate
              subscriberId
          }
        }
        lastPiracyStatus
        lastPiracyStatusUpdatedAt
    }
}`)
