import gql from 'graphql-tag'

export default gql(`
  query getAccessRoleGroups($project: String){
    getAccessRoleGroups(project: $project){
      id
      name
      description
      createdAt
      modules{
        name
        permissions
      }
      users{
        id
        accessRoleGroups {
          id
        }
      }
    }
  }`
)
