import gql from 'graphql-tag'

export default gql(`
subscription ingestJobUpdated($batchID: ID!){
    ingestJobUpdated(batchID: $batchID){
        batchID
        source
        jobType
        status
        totalItems
        createdAt
        createdBy   
        retryJobId
        sourceJob  
        successful {
            id
            isArchived
            shortId
            externalId
            title
            type
            updatedAt
            publishStartDate
            publishEndDate
            status
            isFree
        }
        csvPayload
        errors {
            errorMessage
            rowId
        }
    }
  }`)
