import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import './font.scss'
import config from './constants/config'
import { utilityService } from './services/UtilityService'
import App from './Provider'
import * as serviceWorker from './serviceWorker'

const domain = config.LOCAL_URL

const initializeApp = async () => {
  const project = utilityService.getCurrentProject()
  let siteUrl = location.origin.toString()
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
    siteUrl = `${domain}`
  }
  let timestamp = new Date().getTime()
  let configUrl = project === 'hyperion' ? `/config?project=hyperion&stack=${siteUrl}&timestamp=${timestamp}`
    : project === 'projectx' ? `/config?project=projectx&stack=${siteUrl}&timestamp=${timestamp}`
      : `/config?stack=${siteUrl}&timestamp=${timestamp}`
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
    configUrl = `${domain}${configUrl}`
  }
  const dataDev = await fetch(configUrl)
  const config = await dataDev.json()
  window.config = config
  const favicon = document.getElementById('favicon')
  const url = window.location.href

  if (url.includes('hyperion')) {
    favicon.href = '/hyperion_favicon.ico'
  } else if (url.includes('subhub')) {
    favicon.href = '/subhub_favicon.ico'
  } else if (url.includes('uat') || url.includes('qa')) {
    favicon.href = '/favicon_gray.ico'
  }

  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(<App />)

  // ReactDOM.render(
  //   <App />
  //   , document.getElementById('root')
  // )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

initializeApp()
