import gql from 'graphql-tag'

export default gql(`
    mutation ($id: ID! $masterConfigEnabled: Boolean! $masterConfigSchemaId: ID!) {
        updateModuleSettings(input: {
            id: $id
            channelManagerSetting:{
                masterConfigEnabled: $masterConfigEnabled
                masterConfigSchemaId: $masterConfigSchemaId
            }
        })
        {
            id
            channelManagerSetting {
                masterConfigEnabled
                masterConfigSchemaId
            }  
        }
   }`
)
