import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import moment from 'moment'
import './../ui.style.scss'

import AppScheduleDetailsCell from './../dataEntry/inputs/AppScheduleDetailsCell'

class SchedulePageModal extends React.Component {
  submit = () => {
    this.props.handlePageSchedule()
  }

  clearState = () => {
    this.setState()
  }

  render () {
    const { handleCancel, visible, onScheduleEndDateChange, onScheduleStartDateChange,
      isPageScheduleEdited, pageVersionDetails, editPageSchedule, isScheduleLoading, currentPageVersionId } = this.props
    const isValidDate = pageVersionDetails && pageVersionDetails.publishStartDate && pageVersionDetails.publishEndDate
      ? moment(pageVersionDetails.publishStartDate).isBefore(moment(pageVersionDetails.publishEndDate)) : true
    return (
      <Modal
        className='general-modal schedule-page-modal'
        visible={visible}
        closable={false}
        title={'SCHEDULE PAGE'}
        onOk={this.submit}
        onCancel={handleCancel}
        okText={editPageSchedule ? 'Save' : 'Schedule'}
        cancelText='Back'
        okButtonProps={{ disabled: !(isPageScheduleEdited && isValidDate), loading: isScheduleLoading }}
        destroyOnClose
        afterClose={this.clearState}
      >
        <div className='schedule-heading'>
          <div className='dates'>
            <label className='dates-selector'>Start Date</label>
            <label className='dates-selector'>End Date</label>
          </div>
        </div>
        <AppScheduleDetailsCell
          type='All'
          startDate={pageVersionDetails ? pageVersionDetails.publishStartDate : null}
          endDate={pageVersionDetails ? pageVersionDetails.publishEndDate : null}
          //   isActive={pageVersionDetails.isPublished}
          onStartDateChange={onScheduleStartDateChange}
          onEndDateChange={onScheduleEndDateChange}
          dateFormat='DD MMM YYYY, HH:mm'
          module='appPage'
          pageVersionDetails={pageVersionDetails}
          currentPageVersionId={currentPageVersionId}
        />
      </Modal>

    )
  }
}

SchedulePageModal.propTypes = {
  /** visible action of PublishBucketModal. */
  visible: PropTypes.bool,
  /** title  of PublishBucketModal. */
  title: PropTypes.string,
  /** handleCancel action of PublishBucketModal. */
  handleCancel: PropTypes.func
}

SchedulePageModal.defaultProps = {
}

export default SchedulePageModal
