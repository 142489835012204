import gql from 'graphql-tag'

export default gql(`
    query($files: [UploadMediaInput] $project: String) {
        getUploadUrl(
            input: $files
            project: $project
            )
        {
            key
            url
            uploadUrl
        }
    }
    `
)
