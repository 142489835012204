import gql from 'graphql-tag'

export default gql(`
    mutation updateConfig( $id: ID! $isEnabled: Boolean $project: String) {
        updateConfig(
            input: {
                id: $id
                isEnabled: $isEnabled
            }
            project: $project
        ) {
            id
            name
            isEnabled
        }
    }`
)
