import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import SupportTicketComments from '../../dataDisplay/SupportTicketComments'
import './../../ui.style.scss'

class SupportTicketCommentsModal extends Component {
    state = {
      isLoading: false
    }

    resetState = () => {
      const state = {
        isLoading: false
      }
      this.setState({ ...state })
    }

    render () {
      const { isVisible, handleCancel, ticketData } = this.props
      const title = <div className='title'>
        <p>COMMENTS</p>
        <p>{ ticketData && ticketData.id ? ticketData.id : ''}</p>
      </div>
      return (
        <Modal
          className='general-modal ticket-comments-modal'
          title={title}
          maskClosable={false}
          visible={isVisible}
          cancelText='Close'
          onOk={() => {}}
          onCancel={handleCancel}
          closable={false}
          centered
          destroyOnClose
          afterClose={this.resetState}
          width='650px'
        >

          {(ticketData.comments || []).map(item => <SupportTicketComments comments={item} />)}

        </Modal>
      )
    }
}

SupportTicketCommentsModal.propTypes = {
  /** visible file status of CreateCategoryModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateCategoryModal. */
  handleCancel: PropTypes.func
}

SupportTicketCommentsModal.defaultProps = {
}

export default SupportTicketCommentsModal
