import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import TextAreaInput from '../inputs/TextArea'
import AntiPiracyDropDown from '../inputs/AnitPiracyDropDown'
import './../../ui.style.scss'

class CustomerAntiPiracyActionModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      actionKey: '',
      operatorNote: '',
      isEdited: false,
      selectedNote: null
    }
  }

  onOptionSelect = (value) => {
    let selectedNote = this.props.options.filter(option => option.key === value)[0].note
    this.setState({ actionKey: value, isEdited: true, selectedNote })
  }

  handleNotesChange = (event) => {
    const operatorNote = event.target.value
    this.setState({ operatorNote, isEdited: true })
  }

  handleSubmit = () => {
    const { actionKey, operatorNote } = this.state
    let options = {
      actionKey, operatorNote
    }
    this.props.handleSubmit(options)
  }

  clearState = () => {
    this.setState({
      actionKey: '',
      operatorNote: '',
      isEdited: false,
      selectedNote: null
    })
  }

  render () {
    const { isVisible, isSubmitDisabled, handleCancel, isLoading, options } = this.props
    const { isEdited, operatorNote, actionKey, selectedNote } = this.state
    return (
      <Modal
        className='general-modal change-password'
        title={'Anti-Piracy'}
        maskClosable={false}
        visible={isVisible}
        okText='Save'
        cancelText='Cancel'
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ disabled: (isSubmitDisabled || !isEdited || !operatorNote || !actionKey) }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoading}
        afterClose={this.clearState}
        width='350'
      >
        {isVisible
          ? <div className='change-password-container'>
            <div className='input-field anti-piracy-dropdown'>
              <label className='input-title' style={{ marginBottom: '10px' }}>Anti-Piracy</label>
              <AntiPiracyDropDown
                options={options}
                selectedValue={actionKey}
                placeHolder='Select Anti-Piracy Action'
                valueParam='key'
                displayParam='title'
                onOptionSelect={this.onOptionSelect}
              />
              {selectedNote && <p className='input-note-title'>{selectedNote}</p>}
            </div>
            <TextAreaInput
              title='Internal Notes'
              placeholder='Internal Notes'
              inputName='operatorNote'
              // onFocus={onInputFieldFocus}
              // onBlur={onInputFieldBlur}
              value={operatorNote}
              handleChange={this.handleNotesChange}
            />
          </div> : null}
      </Modal>
    )
  }
}

CustomerAntiPiracyActionModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** Callback when content is saved */
  handleSubmit: PropTypes.func,
  /** Callback when cancel is clicked */
  handleCancel: PropTypes.func,
  /** Boolean to disable submit button */
  isSubmitDisabled: PropTypes.bool
}

CustomerAntiPiracyActionModal.defaultProps = {
}

export default CustomerAntiPiracyActionModal
