import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from '@hello-pangea/dnd'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import { message } from 'antd'

// import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import SearchButton from '../../components/ui/general/buttons/SearchButton'
import ButtonContainer from '../../components/ui/general/buttons/ButtonContainer'
import BucketDetailListForRelatedAsset from '../../components/ui/dataDisplay/BucketDetailListForRelatedAsset'
import BucketAssetList from '../bucketManager/BucketAssetList'
import userMessages from '../../constants/messages'
import { utilityService } from '../../services/UtilityService'

import MutationCreateBucket from '../../graphQL/bucket/createBucket'
import MutationCreateBucketItem from '../../graphQL/bucket/createBucketItem'
import MutationUpdateBucket from '../../graphQL/bucket/updateBucket'
import SubscriptionUpdateBucket from '../../graphQL/bucket/updateBucketSubscription'
import MutationUpdateBucketItem from '../../graphQL/bucket/batchUpdateBucketItem'
import QueryGetBucketDetails from '../../graphQL/bucket/getBucketDetails'
import QueryFilterAssets from '../../graphQL/asset/searchAssets'
import moment from 'moment'
import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'

const parent = 'related-assets'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

let isUpdatedFlag = false

class RelatedAssets extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bucketDetails: _.cloneDeep(props.bucketDetails),
      assetSearchString: '',
      assetSort: {
        value: 'updatedAt',
        order: 'desc'
      },
      assetFilter: [],
      assetFilterSearch: '',
      isClearFilter: false,
      updatedBucketItem: [],
      saveLoading: false,
      isScroll: false,
      newData: [],
      isEditted: false,
      isDragDisabledFlag: false
    }
    this.updateCount = 0
    this.changedBucket = []
  }
  UNSAFE_componentWillReceiveProps = (newProps) => { // eslint-disable-line camelcase
    if (newProps.assetId !== this.props.assetId || (newProps.bucketDetails && !this.props.bucketDetails && !this.state.bucketDetails)) {
      this.setState({ bucketDetails: _.cloneDeep(newProps.bucketDetails) })
    }
    if (newProps.isSaving && !this.props.isSaving && newProps.bucketId && this.state.isEditted) {
      this.updateBucket(newProps.bucketId)
    }
    if (isUpdatedFlag && !_.isEqual(newProps.bucketDetails, this.state.bucketDetails)) {
      isUpdatedFlag = false
      this.setState({ bucketDetails: _.cloneDeep(newProps.bucketDetails) })
    }
  }

  componentDidMount () {
    this.initiateSubscription()
  }

  initiateSubscription = () => {
    if (this.props.subscribeToChangeBucket) {
      this.props.subscribeToChangeBucket()
    } else {
      setTimeout(() => {
        this.initiateSubscription()
      }, 1500)
    }
  }

  onAddSearch = async () => {
    this.setState({ addFilterLoading: true, isScroll: true })
    const { bucketId } = this.props
    const { bucketDetails } = this.state
    if (!bucketId) {
      const key = Math.random().toString(36).substr(2, 9).toUpperCase()
      const request = {
        name: key,
        type: 'ASSET',
        key: key,
        isPublished: true,
        publishStartDate: moment().utc(),
        module: 'ASSET_MANAGER'
      }
      this.props.createBucket(request).then(async ({ data }) => {
        this.setState({ bucketDetails: data.createBucket }, async () => {
          await this.createcreateSearchBucketItemCallBack(data.createBucket.id, 0, 'FILTER')
          this.props.createdBucket(data.createBucket.id)
        })
      }, error => {
        utilityService.handleError(error)
      })
    } else {
      this.createcreateSearchBucketItemCallBack(bucketId, (bucketDetails.bucketItems || []).length, 'FILTER')
    }
  }

  getAddFilterStatus = () => {
    let { bucketDetails } = this.state
    let bucketItems = bucketDetails ? _.cloneDeep(bucketDetails.bucketItems) : []
    const filterCount = (bucketItems || []).filter((item) => item.type === 'FILTER').length
    return filterCount >= 3
  }

  createcreateSearchBucketItemCallBack = async (bucketId, position, type, assetData) => {
    return new Promise(resolve => { this.createSearchBucketItem(bucketId, position, type, assetData, resolve) })
  }

  createSearchBucketItem = async (bucketId, position, type, assetData, callBack) => {
    let newData
    let { bucketDetails, updatedBucketItem } = this.state
    let bucketItems = bucketDetails && bucketDetails.bucketItems ? bucketDetails.bucketItems : []

    if (type === 'FILTER') {
      newData = {
        id: 'temp-id-123',
        type: 'FILTER',
        bucket: bucketId,
        position: position,
        filterRule: {
          searchKey: null,
          filters: [
            {
              value: false,
              fieldName: 'isArchived',
              name: 'isArchived',
              type: 'BOOLEAN',
              displayValue: 'isArchived'
            }, {
              value: 'asc',
              fieldName: 'updatedAt',
              name: 'Sort',
              type: 'SORT',
              displayValue: null
            }, {
              displayName: 'Limit',
              fieldName: null,
              isRequired: true,
              name: 'limit',
              type: 'LIMIT',
              value: 10
            }
          ]
        }
      }
    } else if (type === 'ASSET') {
      const isAssetPresent = assetData ? (bucketItems || []).find((item) => item.asset && item.asset.id === assetData.id) : null
      if (_.isEmpty(isAssetPresent) && assetData.id !== this.props.assetId) {
        newData = {
          id: 'temp-id-123',
          type: 'ASSET',
          assetId: assetData.id,
          bucket: bucketId,
          position: position,
          asset: assetData
        }
      } else if (assetData.id === this.props.assetId) {
        message.warning(userMessages.CURRENT_ASSET_ADDED)
        callBack(true)
        return
      } else {
        message.warning(userMessages.ASSET_ADDED_ALREADY)
        callBack(true)
        return
      }
    }
    bucketItems.splice(position, 0, newData)
    bucketDetails.bucketItems = bucketItems
    this.setState({ bucketDetails })
    const request = _.cloneDeep(newData)
    delete request.id
    delete request.asset
    if (this.props.addingSubAsset) { this.props.addingSubAsset() }
    this.props.createBucketItem({ bucketItems: [request], module: 'ASSET_MANAGER' }).then(async (response) => {
      const index = bucketItems.findIndex((item) => item.id === newData.id)
      bucketItems[index] = response.data.batchCreateBucketItem[0]
      bucketItems = bucketItems.map((item, index) => {
        if (index >= position || updatedBucketItem.indexOf(item.id) === -1) {
          updatedBucketItem.push(item.id)
        }
        item.position = index >= position ? index : item.position
        delete (item.__typename)
        return item
      })
      bucketDetails.bucketItems = bucketItems
      this.setState({ bucketDetails, addFilterLoading: false, updatedBucketItem, isEditted: true, isDragDisabledFlag: false }, () => {
        this.props.onEditRelatedAssets(true)
        callBack(true)
      })
    }, async error => {
      this.setState({ addFilterLoading: false, isDragDisabledFlag: false })
      utilityService.handleError(error)
      callBack(true)
    })
  }

  onDragEnd = async (result) => {
    const { bucketId, project } = this.props
    const { assetSearchString, assetSort, assetFilter } = this.state
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (source.droppableId === 'asset-list' && destination.droppableId.startsWith('#Bucket_')) {
      const id = result.draggableId.split('#Asset_list_')[0]
      // const dropableId = destination.droppableId.split('#Bucket_')[1]
      this.setState({ isDragDisabledFlag: true })
      let assetData = { id }
      try {
        const variables = utilityService.getFormattedAssetFilter(assetSearchString, assetFilter, assetSort, project)
        const getCacheData = this.props.client.readQuery({ query: QueryFilterAssets, variables })
        if (getCacheData && getCacheData.listAssets) {
          assetData = getCacheData.listAssets.items.find(item => item.id === id)
        }
      } catch (error) {
        console.error('cache update did not work', error)
      }

      if (!bucketId) {
        const key = Math.random().toString(36).substr(2, 9).toUpperCase()
        const request = {
          name: key,
          type: 'ASSET',
          key: key,
          isPublished: true,
          publishStartDate: moment().utc(),
          module: 'ASSET_MANAGER'
        }
        this.props.createBucket(request).then(async ({ data }) => {
          this.setState({ bucketDetails: data.createBucket }, async () => {
            await this.createcreateSearchBucketItemCallBack(data.createBucket.id, destination.index, 'ASSET', assetData)
            this.props.createdBucket(data.createBucket.id)
          })
        }, error => {
          utilityService.handleError(error)
        })
      } else {
        this.createcreateSearchBucketItemCallBack(bucketId, destination.index, 'ASSET', assetData)
      }
    } else if (source.droppableId.startsWith('#Bucket_') && destination.droppableId.startsWith('#Bucket_')) {
      if (source.index !== destination.index) {
        this.reorderBucketItems(source.index, destination.index)
      }
    }
  }

  reorderBucketItems =(sourceIndex, destinationIndex) => {
    let { bucketDetails, updatedBucketItem } = this.state
    let buckets = bucketDetails.bucketItems && bucketDetails.bucketItems.length ? [...bucketDetails.bucketItems] : []
    const smallIndex = sourceIndex <= destinationIndex ? sourceIndex : destinationIndex
    const largeIndex = sourceIndex === smallIndex ? destinationIndex : sourceIndex
    buckets = this.getSortedList(buckets)
    this.changedBucket.push(buckets[sourceIndex].id)
    buckets = reorder(buckets, sourceIndex, destinationIndex)
    buckets = buckets.map((item, index) => {
      if (index >= smallIndex && index <= largeIndex && updatedBucketItem.indexOf(item.id) === -1) {
        updatedBucketItem.push(item.id)
      }
      item.position = (index >= smallIndex && index <= largeIndex) ? index : item.position
      return item
    })
    bucketDetails.bucketItems = buckets
    this.props.onEditRelatedAssets(true)
    this.setState({ bucketDetails, updatedBucketItem, isEditted: true })
  }

  updateBucket = (bucketId) => {
    this.setState({ saveLoading: true })
    const { bucketDetails } = this.state
    const bucketItems = bucketDetails.bucketItems ? bucketDetails.bucketItems : []
    let bucketIdList = bucketItems && bucketItems.length ? bucketItems.map((item) => item.id) : null
    const tempIdIndex = (bucketIdList || []).indexOf('temp-id-123')
    if (tempIdIndex > -1) {
      this.updateCount++
      if (this.updateCount < 3) {
        setTimeout(() => this.updateBucket(bucketId), 500)
        return
      }
      bucketIdList.splice(tempIdIndex, 1)
    }
    this.updateCount = 0
    const variables = {
      id: bucketId,
      buckets: bucketIdList,
      parentAssetId: this.props.assetId,
      module: this.props.manager === 'offer' ? 'OFFER_MANAGER' : 'ASSET_MANAGER'
    }
    const { updatedBucketItem } = this.state
    const assetList = (bucketItems || []).filter((item) => item.type === 'ASSET').map((item) => {
      return {
        id: item.id,
        position: item.position,
        assetId: item.asset ? item.asset.id : null,
        type: item.type,
        customMedia: item.customMedia ? item.customMedia.id : null,
        customTitle: item.customTitle
      }
    })
    const filterList = (bucketItems || []).filter((item) => item.type === 'FILTER').map((item) => {
      return {
        bucket: bucketId,
        position: item.position,
        type: item.type,
        id: item.id,
        filterRule: {
          searchKey: item.filterRule.searchKey,
          filters: (item.filterRule && item.filterRule.filters && item.filterRule.filters.length ? item.filterRule.filters : []).map((filterItem) => {
            if (filterItem.type === 'KEYWORD') {
              if (typeof (filterItem.value) !== 'string') {
                let value = (filterItem.value || []).map((filterInner) => filterInner.key)
                let key = (filterItem.value || []).map((filterInner) => filterInner.name)
                filterItem.value = value && value.length ? value.join() : null
                filterItem.displayValue = key && key.length ? key.join() : null
              }
            }
            delete (filterItem.__typename)
            return filterItem
          })
        }
      }
    })
    let bucketListItems = [...assetList, ...filterList]
    bucketListItems = bucketListItems.filter((item) => updatedBucketItem.indexOf(item.id) !== -1)
    bucketListItems = bucketListItems && bucketListItems.length ? bucketListItems : null
    if (bucketListItems) {
      bucketListItems = bucketListItems.map(item => {
        const isAuditChange = this.changedBucket.includes(item.id)
        item.trackPosChange = isAuditChange
        return item
      })
      this.props.updateBucketItem(bucketListItems, this.props.assetId).then(() => {
        this.props.updateBucket(variables).then(() => {
          this.changedBucket = []
          this.setState({ saveLoading: false })
        })
      }, error => {
        this.setState({ saveLoading: false })
        utilityService.handleError(error)
      })
    } else {
      this.props.updateBucket(variables).then(() => {
        this.setState({ saveLoading: false, updatedBucketItem: [], isEditted: false })
      }, error => {
        this.setState({ saveLoading: false })
        utilityService.handleError(error)
      })
    }
  }

  removeBucketItem = (itemId) => {
    let { bucketDetails, updatedBucketItem } = this.state
    let { bucketItems } = bucketDetails
    const deletedIndex = bucketItems.findIndex((item) => item.id === itemId)
    bucketItems = bucketItems.filter((item) => item.id !== itemId).map((item, index) => {
      if (deletedIndex <= index && updatedBucketItem.indexOf(item.id) === -1) {
        updatedBucketItem.push(item.id)
      }
      item.position = index
      return item
    })
    bucketDetails.bucketItems = bucketItems && bucketItems.length ? bucketItems : null
    this.setState({ bucketItems, updatedBucketItem, isEditted: true })
    this.props.onEditRelatedAssets(true)
  }

  onChangeAssetFilter = (id, value) => {
    let { bucketDetails, updatedBucketItem } = this.state
    let { bucketItems } = this.state.bucketDetails
    const modifiedIndex = bucketItems.findIndex(item => item.id === id)
    bucketItems[modifiedIndex].filterRule = value
    bucketDetails.bucketItems = bucketItems
    if (updatedBucketItem.indexOf(id) === -1) { updatedBucketItem.push(id) }
    this.setState({ bucketDetails, updatedBucketItem, isEditted: true })
    this.props.onEditRelatedAssets(true)
  }

  onChangeFilter = value => {
    this.setState({ assetSearchString: value })
  }

  onSearchFilter = (filterSearch) => {
    this.setState({ assetFilterSearch: filterSearch })
  }

  clearFilterDetails = () => {
    this.setState({ isClearFilter: true }, () => {
      setTimeout(() => this.setState({ isClearFilter: false }), 200)
    })
  }

  changeFilterValue = (filter) => {
    let newFilter = utilityService.getFormattedFilter(filter)
    this.assetFilterObj = filter
    this.setState({ isSearching: true, assetFilter: newFilter })
  }

  getSortedList = (data) => {
    return (data || []).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
  }

  onBucketScroll= () => {
    this.setState({ isScroll: false })
  }

  render () {
    const { isUpdateDisable, project } = this.props
    const { assetFilter, assetSearchString, assetSort, isClearFilter, bucketDetails, saveLoading, addFilterLoading, isScroll, isDragDisabledFlag } = this.state
    let selectedAssets = bucketDetails && bucketDetails.bucketItems ? (bucketDetails.bucketItems || []).filter((item) => item.type === 'ASSET').map((item) => item.asset.id) : []
    selectedAssets.push(this.props.assetId)
    return (
      <div className='related-assets' id='related-assets'>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className='inner-assets'>
            <p>Available Assets</p>
            <div className='related-asset-container'>
              <div className='filter'>
                <FilterInput
                  searchString={assetSearchString}
                  onChangeSearchInput={this.onChangeFilter}
                  bucketAssetFilter={this.assetFilterObj}
                  onSearchFilter={this.onSearchFilter}
                  filterType={'AssetManager'}
                  parentId={parent}
                  clearFilterDetails={this.clearFilterDetails}
                  isClearFilter={isClearFilter}
                  project={project}
                  changeFilter={this.changeFilterValue}
                />
              </div>
              <BucketAssetList
                searchString={assetSearchString}
                sort={assetSort}
                filterVal={assetFilter}
                selectedAssets={selectedAssets}
                isDragDisabled={isUpdateDisable || isDragDisabledFlag}
                project={project} />
            </div>
          </div>

          <div className='inner-assets'>
            <p>Selected Assets</p>
            <div className='related-asset-container'>
              <div className='filter'>
                <ButtonContainer displayTitle='Add Search Item'
                  childComponent={<SearchButton onClick={this.onAddSearch} isDisabled={this.getAddFilterStatus() || saveLoading || addFilterLoading || isUpdateDisable} />} />
              </div>
              <BucketDetailListForRelatedAsset
                listData={bucketDetails && bucketDetails.bucketItems ? this.getSortedList(bucketDetails.bucketItems) : []}
                onChangeFilter={this.onChangeAssetFilter}
                saveLoading={saveLoading}
                removeBucketItem={this.removeBucketItem}
                parentManager='Asset'
                parent={parent}
                isScroll={isScroll}
                onBucketScroll={this.onBucketScroll}
                project={project}
              />
            </div>
          </div>
        </DragDropContext>
      </div>
    )
  }
}

RelatedAssets.propTypes = {
  /** asset id of selected asset. */
  assetId: PropTypes.string,
  /** bucket id of related asset. */
  bucketId: PropTypes.string,
  /** is Saving details status of RelatedAssets. */
  isSaving: PropTypes.bool.isRequired,
  /** Function to be called to create bucket in related assets */
  createdBucket: PropTypes.func,
  /** Function to be called on related assets update */
  onEditRelatedAssets: PropTypes.func
}

RelatedAssets.defaultProps = {}

export default withApollo(compose(

  graphql(
    MutationCreateBucket,
    {
      props: (props) => ({
        createBucket: (event) => {
          let variables = event
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationCreateBucketItem,
    {
      props: (props) => ({
        createBucketItem: (items) => {
          let variables = items
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    QueryGetBucketDetails,
    {
      skip: ({ bucketId }) => {
        return !bucketId
      },
      options: ({ bucketId }) => {
        return {
          variables: { id: bucketId }
        }
      },
      props: (props) => {
        return {
          bucketDetails: props.data.getBucket,
          isLoading: props.data.loading,
          fetchAssetDetails: () => {
            return props.data.refetch()
          },
          subscribeToChangeBucket: () => {
            return props.data.subscribeToMore({
              document: SubscriptionUpdateBucket,
              updateQuery: (previous, { subscriptionData: { data: { bucketUpdated } } }) => {
                const prev = cloneDeep(previous)
                // if (bucketUpdated && bucketUpdated.isArchived) {
                //   const variables = { id: data.ownProps.bucketId }
                //   const listCacheData = props.ownProps.client.readQuery({ query: QueryGetBucketDetails, variables })
                //   const index = listCacheData.listBucketGroups.items.findIndex(group => group.id === bucketUpdated.id)
                //   if (index !== -1) {
                //     props.ownProps.client.writeQuery({
                //       query: QueryGetBucketGroupList,
                //       data: listCacheData,
                //       variables
                //     })
                //   }
                // }
                if (!bucketUpdated || prev.getBucket.id !== bucketUpdated.id) return prev
                isUpdatedFlag = true
                prev.getBucketGroup = bucketUpdated
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationUpdateBucket,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateBucket: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateBucketItem,
    {
      options: (props) => ({
      }),
      props: (props) => ({
        updateBucketItem: (bucketItems, assetId) => {
          return props.mutate({
            variables: {
              bucketItems,
              parentAssetId: assetId,
              module: 'ASSET_MANAGER',
              project: props.ownProps.project
            }
          })
        }
      })
    }
  )

)(RelatedAssets))
