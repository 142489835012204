import React from 'react'
import PropTypes from 'prop-types'
import Input from '../inputs/Input'
import PopoverConfirm from '../../feedback/PopoverConfirm'
import TextAreaInput from '../inputs/TextArea'
import './../../ui.style.scss'

const style =
(<style type='text/css'>
  { `
        ::-webkit-scrollbar: {
            width: 0
        }

        ::-webkit-scrollbar-track: {
            background: #f1f1f1
        }

        /* Handle */
        ::-webkit-scrollbar-thumb: {
            background: #888
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover: {
            background: #555
        }
        #embed{
            margin: 0px 4px 0 4px;
            border: 1px ;
            height:100%;
            overflow:scroll;
            scrollbarWidth: thin;
        
        }`
  }
</style>)

const parentCompoent = 'asset-container'

class EmbedContent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      embedScriptCaption: ''
    }
  }

    onEmbedScriptChange = (e) => {
      const { value } = e.target
      const { onEmbedScriptChange, id } = this.props
      onEmbedScriptChange(id, value)
    }

    onEmbedScriptCaptionChange = (e) => {
      this.props.onEmbedScriptCaptionChange(this.props.id, e.target.value)
    }

    render () {
      const { content, onCloseContent, caption, onInputFieldFocus, onInputFieldBlur } = this.props

      return (
        <div className={`embed-content-item ${!content.value ? 'empty' : ''}`} >
          <PopoverConfirm confirm={onCloseContent} parentCompoent={parentCompoent} />
          <div className={`embed-content-display ${!content.value ? '' : 'not-empty'}`} >
            { content.value
              ? <iframe id='embed' title='embed-test' srcDoc={`${content.value}`} scrolling='auto'>{ style }</iframe> : <p> Preview </p>
            }

          </div>
          <div className='embed-content-data'>
            <TextAreaInput title='Embed Script' placeholder='Enter block code' inputName='EmbedScript' value={content.value} onFocus={onInputFieldFocus} onBlur={onInputFieldBlur} handleChange={this.onEmbedScriptChange} />
            <Input title='Caption' placeholder='Enter Caption' inputName='caption' onFocus={onInputFieldFocus} onBlur={onInputFieldBlur} value={caption} handleChange={this.onEmbedScriptCaptionChange} />
          </div>

        </div>
      )
    }
}
EmbedContent.propTypes = {

  /** identifier of EmbedContent. */
  id: PropTypes.string,
  /** content details of EmbedContent. */
  content: PropTypes.object,
  /** Embed script change action of EmbedContent. */
  onEmbedScriptChange: PropTypes.func,
  /** Embed script caption change action of EmbedContent. */
  onEmbedScriptCaptionChange: PropTypes.func,
  /** close action of EmbedContent. */
  onCloseContent: PropTypes.func.isRequired
}

EmbedContent.defaultProps = {
}

export default EmbedContent
