import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './../ui.style.scss'
import moment from 'moment'
import { getFormattedDuration } from '../../../util/util'
import CollapsedIcon from './../general/icons/CollapsedIcon'

const detailsMeta = {
  shortId: 'Asset ID:',
  createdBy: 'Created by:',
  updatedAt: 'Last Modified:',
  createdAt: 'Created on:',
  updatedBy: 'Last Modified by:',
  competition: 'Competition:',
  match: 'Match:',
  matchDate: 'Match Date:',
  scheduledTime: 'Broadcast Time:',
  kickOffTime: 'Kickoff Time:',
  finishTime: 'Finish Time:',
  matchId: 'Match OPID:',
  homeTeam: 'Home Team:',
  homeTeamId: 'Home Team OPID:',
  awayTeam: 'Away Team:',
  awayTeamId: 'Away Team OPID:',
  duration: 'Duration:',
  source: 'Source:'
}
const formatedDate = date => moment(new Date(date)).format('DD MMM YYYY, hh:mm A')

class GeneralDisplayDetails extends Component {
  state = {
    isAccordionActive: false
  }

  makeAccordionVisible = () => {
    this.setState({ isAccordionActive: !this.state.isAccordionActive })
  }

  render () {
    const { details, isCompetition } = this.props
    const { match } = details
    const { isAccordionActive } = this.state
    return (
      <div className='details-list'>
        <div key={1}>
          <p className='key' >{ detailsMeta[ 'shortId' ] }</p>
          <p className='value' >{ `${details.shortId || details.id}` }</p>
        </div>
        <div key={2}>
          <p className='key' >{ detailsMeta[ 'updatedBy' ] }</p>
          <p className='value' >{ details[ 'updatedBy' ] || details[ 'createdBy' ] }</p>
        </div>
        <div key={3}>
          <p className='key' >{ detailsMeta[ 'createdBy' ] }</p>
          <p className='value' >{ details[ 'createdBy' ] }</p>
        </div>

        <div key={4}>
          <p className='key' >{ detailsMeta[ 'updatedAt' ] }</p>
          <p className='value' >{ formatedDate(details[ 'updatedAt' ]) }</p>
        </div>

        <div key={5}>
          <p className='key' >{ detailsMeta[ 'createdAt' ] }</p>
          <p className='value' >{ formatedDate(details[ 'createdAt' ]) }</p>
        </div>
        <div key={6}>
          <p className='key' >{ detailsMeta[ 'source' ] }</p>
          <p className='value' >{ details.source ? details.source : '-' }</p>
        </div>
        {details && details.duration && details.duration !== '0' ? <div key={20}>
          <p className='key' >{ detailsMeta[ 'duration' ] }</p>
          <p className='value' >{details && details.duration && details.duration !== '0' ? getFormattedDuration(details.duration) : '-'}</p>
        </div> : null}

        { isCompetition ? <div>
          <div className={`accordion-arrow ${isAccordionActive ? 'active' : ''}`} onClick={this.makeAccordionVisible}>
            <div>{isAccordionActive ? 'Less Info' : 'More Info'}</div>
            <CollapsedIcon />
          </div>
        </div> : null }

        {isAccordionActive && isCompetition ? <React.Fragment>
          <div key={6}>
            <p className='key' >{ detailsMeta[ 'competition' ] }</p>
            <p className='value' >{match && match.competition ? match.competition.title : '-'}</p>
          </div>
          <div key={7}>
            <p className='key' >{ detailsMeta[ 'match' ] }</p>
            <p className='value' >{match && match.homeTeam && match.homeTeam.officialName && match && match.awayTeam && match.awayTeam.officialName ? match.homeTeam.officialName + ' v ' + match.awayTeam.officialName : '-'}</p>
          </div>
          <div key={8}>
            <p className='key' >{ detailsMeta[ 'matchId' ] }</p>
            <p className='value' >{match && match.opId ? match.opId : '-'}</p>
          </div>
          <div key={9}>
            <p className='key' >{ detailsMeta[ 'matchDate' ] }</p>
            <p className='value' >{match && match.matchDate ? moment(match.matchDate).format('DD MMM YYYY') : '-'}</p>
          </div>
          <div key={10}>
            <p className='key' >{ detailsMeta[ 'homeTeam' ] }</p>
            <p className='value' >{match && match.homeTeam && match.homeTeam.officialName ? `${match.homeTeam.officialName} (  ${match.homeTeam.code} ) ` : '-'}</p>
          </div>
          <div key={11}>
            <p className='key' >{ detailsMeta[ 'homeTeamId' ] }</p>
            <p className='value' >{match && match.homeTeam ? match.homeTeam.opId : '-'}</p>
          </div>
          <div key={12}>
            <p className='key' >{ detailsMeta[ 'awayTeam' ] }</p>
            <p className='value' >{match && match.awayTeam && match.awayTeam.officialName ? `${match.awayTeam.officialName} (  ${match.awayTeam.code} ) ` : '-'}</p>
          </div>
          <div key={13}>
            <p className='key' >{ detailsMeta[ 'awayTeamId' ] }</p>
            <p className='value' >{match && match.awayTeam ? match.awayTeam.opId : '-'}</p>
          </div>
          <div key={14}>
            <p className='key' >{ detailsMeta[ 'kickOffTime' ] }</p>
            <p className='value' >{match && (match.kickOffTime || match.matchDate) ? moment(match.kickOffTime || match.matchDate).format('HH:mm') : '-'}</p>
          </div>
          <div key={15}>
            <p className='key' >{ detailsMeta[ 'finishTime' ] }</p>
            <p className='value' >{match && match.finishTime ? moment(match.finishTime).format('HH:mm') : '-'}</p>
          </div>
          <div key={16}>
            <p className='key' >Markin Time</p>
            <p className='value' >{details && details.markInTime ? moment(details.markInTime).format('DD MMM YYYY HH:mm:ss') : '-'}</p>
          </div>
          <div key={17}>
            <p className='key' >Markout Time</p>
            <p className='value' >{details && details.markOutTime ? moment(details.markOutTime).format('DD MMM YYYY HH:mm:ss') : '-'}</p>
          </div>
        </React.Fragment> : null }

      </div>
    )
  }
}

GeneralDisplayDetails.propTypes = {
  /** Details array of GeneralDisplayDetails. */
  details: PropTypes.object.isRequired
}

GeneralDisplayDetails.defaultProps = {
  details: []
}

export default GeneralDisplayDetails
