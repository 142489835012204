import gql from 'graphql-tag'

export default gql(`
    mutation retryGracenoteIngestJob($batchJobId: ID! $project: String) {
        retryGracenoteIngestJob(
            batchJobId: $batchJobId
            project: $project
        ) {
            batchID
        }
    }`
)
