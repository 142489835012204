import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import JSPlayer from './jsPlayers/jsPlayer'
import { message } from 'antd'
import moment from 'moment'

import './Player.scss'

import PlayerMenu from './PlayerMenu'
import PlayerCloseIcon from './icons/CollapseIcon'
import userMessages from './../../constants/messages'
import DropDown from '../ui/dataEntry/inputs/DropDown'
import { utilityService } from '../../services/UtilityService'

// import { utilityService } from './../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationLiveToVod from '../../graphQL/asset/triggerLiveToVod'
import QueryPlayerDrm from './../../graphQL/asset/getPlayerDrm'
import QueryGetSavedTrim from './../../graphQL/asset/getSavedTrim'
import MutationUpdateSavedTrim from './../../graphQL/asset/updateSavedTrim'
import QueryGetAssetDetails from './../../graphQL/asset/getAssetDetails'
import { playerId } from './config/playerConfig'
import { getActivePlayer } from './jsPlayers/Utils/playerUtils'

const expiryDay = 10
// const bufferTime = 15
const availablePlayTimes = [
  0,
  (2 * 60 + 55) * 60,
  (3 * 60 + 55) * 60,
  (4 * 60 + 55) * 60,
  (5 * 60 + 55) * 60
]
let playTime = availablePlayTimes[2]

// const dragSelector = '#player .jw-wrapper .jw-controls .jw-controlbar .jw-button-container'
const dragSelector = '#player-container .overlay .border'

class Player extends Component {
  constructor (props) {
    super(props)
    let playlist

    if (props.streamRequest && props.streamRequest.drm === 'CLEAR') {
      playlist = this.getClearPlayList(props.streamRequest)
      props.isPlayerReady()
    } else if (props.streamData) {
      playlist = this.getPlayList(
        props.streamData,
        props.program,
        props.type,
        props.assetType,
        props.isLinearChannel
      )
    }
    const { program } = props
    if (program) {
      let startTime = program.broadCastStartTime
      startTime = moment(new Date(startTime))
      let endTime = program.broadCastEndTime
      endTime = moment(new Date(endTime))
      const range = endTime.diff(startTime, 'seconds')
      let endBufferTime =
        program.channel.defaultTrimEnd !== null ? program.channel.defaultTrimEnd / 60 : 15
      let startBufferTime =
        program.channel.defaultTrimStart !== null ? program.channel.defaultTrimStart / 60 : 15
      playTime = range + (startBufferTime + endBufferTime) * 60
    }
    this.state = {
      isSaved: true,
      playlist,
      playerRange: 0,
      playerStartTime: 0
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (newProps.streamRequest && newProps.streamRequest.drm === 'CLEAR') {
      const playlist = this.getClearPlayList(newProps.streamRequest)
      if (!_.isEqual(playlist, this.state.playlist)) {
        this.setState({ playlist, isEditing: false })
        this.props.isPlayerReady()
      }
    }
    if (newProps.streamData) {
      if (!_.isEqual(newProps.streamData, this.props.streamData)) {
        this.props.isPlayerReady()
        const playlist = this.getPlayList(
          newProps.streamData,
          newProps.program,
          newProps.type,
          newProps.assetType,
          newProps.isLinearChannel
        )
        this.setState({ playerStartTime: Date.now() })
        this.setState({ playlist, isEditing: false })
      } else if (
        !_.isEqual(newProps.isLinearChannel, this.props.isLinearChannel) ||
        (newProps.assetId !== this.props.assetId &&
          _.isEqual(newProps.isLinearChannel, this.props.isLinearChannel))
      ) {
        this.props.fetchDRMDetails()
      }
    } else if (newProps.drmError && !this.props.drmError) {
      this.props.isPlayerReady()
      message.error(userMessages.INVALID_DRM_CONFIG)
    }
    if (newProps.showPlayer !== this.props.showPlayer) {
      // TO DO handle play and pause of player
      const player = getActivePlayer()
      if (player) {
        player.togglePlayPause(newProps.showPlayer)
      }
    }
  }

  componentWillUnmount = () => {
    const menuBar = document.querySelector(
      '#player-container .jw-wrapper .jw-controls .jw-controlbar .jw-button-container'
    )
    if (menuBar) {
      menuBar.removeEventListener('mouseenter', this.showResizeOption)
      menuBar.removeEventListener('mouseout', this.removeResizeOption)
    }
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
    clearInterval(this.playerInterval)
    if (getActivePlayer()) {
      const player = getActivePlayer()
      if (player && player.remove) {
        player.remove()
      }
    }
  }

  getClearPlayList = streamData => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    // CLEAR eg https://cms-uat.optussport.tv/jobs/a2d8643e-8b76-333e-9f3d-b21683725a2c
    let playlist = [
      {
        file: streamData.url,
        type: isSafari ? 'hls' : 'dash'
      }
    ]
    return playlist
  }

  getPlayList = (streamData, program, type, assetType, isLinearChannel) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    let bufferTime =
      program && program.channel.defaultTrimEnd !== null
        ? (program && program.channel.defaultTrimEnd) / 60
        : 15
    let widevineLicence, playreadyLicence, fairplayLicence, modifiedUrl
    if (streamData.license && streamData.license.length) {
      widevineLicence = streamData.license.find(licence => licence['name'] === 'widevine_modular')
      playreadyLicence = streamData.license.find(licence => licence['name'] === 'playready')
      fairplayLicence = streamData.license.find(licence => licence['name'] === 'fairplay')
    }
    const isExpired = program
      ? moment().diff(moment(program.broadCastStartTime), 'days') >= expiryDay
      : undefined
    const startTimeObj = program
      ? moment(program.broadCastStartTime).add(-bufferTime, 'minutes')
      : undefined
    const isSheduled = moment().isBefore(startTimeObj)
    modifiedUrl = streamData.url
    if (
      type === 'channel' &&
      assetType !== 'LINEAR-CHANNEL' &&
      !isExpired &&
      !isSheduled &&
      !isLinearChannel &&
      program
    ) {
      const leftUrl = streamData.url.split('/')
      const rightUrl = leftUrl.splice(leftUrl.length - 1, 1)[0]
      let startTime = program.broadCastStartTime
      startTime = parseInt(new Date(startTime).getTime() / 1000)
      let middleUrl = '/'
      let endUrl = ''
      const buffer = bufferTime * 60
      if (startTime) {
        middleUrl += 'start/' + (startTime - buffer) + '/'
        middleUrl += 'end/' + (startTime - buffer + playTime) + '/'
        endUrl += `?start=${startTime - buffer}&end=${startTime - buffer + playTime}`
      }

      if (window.config.startOverQSPUrlFormat) {
        modifiedUrl = leftUrl.join('/') + '/' + rightUrl + endUrl
      } else {
        modifiedUrl = leftUrl.join('/') + middleUrl + rightUrl
      }
    }

    let playlist = [
      {
        sources: [
          {
            file: modifiedUrl,
            drm: {},
            type: isSafari ? 'hls' : 'dash'
          }
        ]
      }
    ]
    if (widevineLicence) {
      playlist[0].sources[0].drm.widevine = {
        url: widevineLicence['uri'],
        serverCertificateUrl: 'https://drm-widevine-licensing.axprod.net/ServiceCertificate',
        licenseRequestHeaders: [
          {
            name: 'X-AxDRM-Message',
            value: widevineLicence['drmData']
          }
        ]
      }
    }
    if (playreadyLicence) {
      playlist[0].sources[0].drm.playready = {
        url: playreadyLicence['uri'],
        serverCertificateUrl: 'https://drm-playready-licensing.axprod.net/ServiceCertificate',
        licenseRequestHeaders: [
          {
            name: 'X-AxDRM-Message',
            value: playreadyLicence['drmData']
          }
        ]
      }
    }
    if (fairplayLicence) {
      playlist[0].sources[0].drm.fairplay = {
        processSpcUrl: fairplayLicence['uri'],
        certificateUrl: 'https://infinit.blob.core.windows.net/fairplay/fairplayDER.cer',
        licenseRequestHeaders: [
          {
            name: 'X-AxDRM-Message',
            value: fairplayLicence['drmData']
          }
        ]
      }
    }
    if (_.isEmpty(playlist[0].sources[0].drm)) {
      playlist[0].sources[0].drm = null
    }
    return playlist
  }

  onEditChange = isEditing => {
    this.setState({ isEditing })
  }

  showResizeOption = e => {
    if (this.props.showPlayer && !this.state.showResize) {
      const rect = e.target.getBoundingClientRect()
      this.position = e.clientX - rect.left - 10
      this.setState({ showResize: true })
    }
  }

  removeResizeOption = () => {
    setTimeout(() => {
      if (!this.state.isDragging && this.state.showResize) {
        this.setState({ showResize: false })
      }
    }, 1000)
  }

  getDuration = () => {
    const { program } = this.props
    let startTime = program.broadCastStartTime
    startTime = moment(new Date(startTime))
    let endTime = program.broadCastEndTime
    endTime = moment(new Date(endTime))
    const duration = moment.duration(endTime.diff(startTime))
    const totalMinutes = Math.floor(duration.asMinutes())
    const hours = Math.floor(totalMinutes / 60)
    const minutes = Math.round(totalMinutes % 60)
    return (
      (hours < 10 ? '0' + hours : hours) + 'h ' + (minutes < 10 ? '0' + minutes : minutes) + 'm '
    )
  }

  onChangeRange = range => {
    if (range) {
      playTime = availablePlayTimes[range]
    } else {
      const { program } = this.props
      if (!program) {
        return
      }
      let startTime = program.broadCastStartTime
      startTime = moment(new Date(startTime))
      let endTime = program.broadCastEndTime
      endTime = moment(new Date(endTime))
      const range = endTime.diff(startTime, 'seconds')
      let endBufferTime =
        program.channel.defaultTrimEnd !== null ? program.channel.defaultTrimEnd / 60 : 15
      let startBufferTime =
        program.channel.defaultTrimStart !== null ? program.channel.defaultTrimStart / 60 : 15
      playTime = range + (startBufferTime + endBufferTime) * 60
    }
    this.props.fetchDRMDetails()
    this.setState({ playerRange: range })
  }

  onAutoStart = () => {
    const player = getActivePlayer()
    if (!this.props.showPlayer && player) {
      player.pause()
    }
  }

  onReady = () => {
    if (this.playerInterval) {
      clearInterval(this.playerInterval)
    }
    this.playerInterval = setInterval(this.updatePlayerProgress, 1000)
    this.setState({ isPlayerReady: true })
    if (!this.alreadyAddedEvent) {
      const menuBar = document.querySelector(dragSelector)
      if (menuBar) {
        menuBar.addEventListener('mouseenter', this.showResizeOption)
        menuBar.addEventListener('mouseout', this.removeResizeOption)
      }
      this.alreadyAddedEvent = true
    }
  }

  onResume = event => {
    if (this.props.onChangeShowPlayer) {
      this.props.onChangeShowPlayer(true)
    }
  }

  onPlay = event => {}

  onOneHundredPercent = () => {
    this.props.fetchDRMDetails()
  }

  onError = () => {
    this.alreadyAddedEvent = false
    const menuBar = document.querySelector(dragSelector)
    if (menuBar) {
      menuBar.removeEventListener('mouseenter', this.showResizeOption)
      menuBar.removeEventListener('mouseout', this.removeResizeOption)
    }
    this.setState({ isPlayerError: true })
  }

  onVideoLoad = () => {
    this.isVideoChanged = true
  }

  onError = () => {
    this.setState({ isPlayerError: true })
  }

  onTogglePlayer = () => {
    if (this.props.onChangeShowPlayer) {
      this.props.onChangeShowPlayer(!this.props.showPlayer)
    }
  }

  onMouseDown = e => {
    if (this.props.showPlayer) {
      window.addEventListener('mousemove', this.handleMouseMove)
      window.addEventListener('mouseup', this.handleMouseUp)
      this.setState({ isDragging: true })
    }
  }

  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
    this.setState({ isDragging: false })
  }

  handleMouseMove = e => {
    const { isDragging, isEditing } = this.state
    if (!isDragging || !this.props.showPlayer) {
      return
    }
    const rect = e.target.getBoundingClientRect()
    this.position = e.clientX - rect.left - 10
    const playerOffset = isEditing ? 168 : 120
    const player = getActivePlayer()
    const draggedDistance = e.pageY - playerOffset
    if (draggedDistance > 120) {
      player.resize('100%', draggedDistance)
    } else {
      // this.onTogglePlayer()
    }
  }

  updatePlayerProgress = () => {
    const player = getActivePlayer()
    const { program } = this.props
    if (!player || !player.getPosition || !program) {
      return
    }
    let position = player.getPosition()
    let bufferTime =
      program.channel.defaultTrimStart !== null ? program.channel.defaultTrimStart / 60 : 15
    const startTime = moment(program.broadCastStartTime).add(-bufferTime, 'minute')
    const endTimeObj = _.cloneDeep(startTime).add(playTime, 'second')
    const now = moment()
    const isLive = now.isBefore(endTimeObj)
    if (isLive) {
      if (position > 0) {
        position = 0
      }
      let buffer = now.diff(startTime, 'seconds')
      position = buffer + position
      buffer = (buffer * 100) / playTime
      position = (position * 100) / playTime
      this.setState({ playing: position.toFixed(2), buffer: buffer.toFixed(2) })
    } else {
      const buffer = (player.getBuffer() * 100) / playTime
      const playingTime = (position * 100) / playTime
      this.setState({ playing: playingTime.toFixed(2), buffer: buffer.toFixed(2) })
    }
  }

  onClickProgressBar = e => {
    const progressBar = document.getElementById('player-progress')
    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left // x position within the element.
    const width = progressBar.clientWidth
    this.seekPlayer(width, x)
  }

  updateVideoEncoding = (start, end, site, isLive, drmRequired) => {
    // const { name, channelId, assetId, id, startTime } = this.props
    const { program, assetId, assetTitle, assetType } = this.props
    const name = assetTitle
    const channelId = program.channel ? program.channel.id : undefined
    const id = program.id
    const variable = {
      name,
      channel: channelId,
      assetId,
      programId: id,
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      site,
      isLive,
      jobType: 'LIVE_TO_VOD',
      assetType,
      drmRequired,
      timestamp: new Date().getTime()
    }
    this.setState({ isSaved: false, isError: false }, () => {
      this.props.triggerLiveToVod(variable).then(
        ({ data }) => {
          this.props.onVodTrigger(data.triggerLiveToVod)
          message.success(userMessages.VOD_TRIGGER_SUCCESS)
          this.setState({ isSaved: true })
        },
        error => {
          utilityService.handleError(error)
          this.setState({ isSaved: true, isError: true })
        }
      )
    })
  }

  onSaveTrim = (start, end, site, drmRequired) => {
    const request = {
      id: this.props.assetId,
      markInTime: start.toISOString(),
      markOutTime: end.toISOString(),
      site: site,
      drmRequired
    }
    this.setState({ isSavingTrim: true }, () => {
      this.props.updateSavedTrim(request).then(
        ({ data }) => {
          // message.success(userMessages.VOD_TRIGGER_SUCCESS)
          this.setState({ isSavingTrim: false })
        },
        error => {
          utilityService.handleError(error)
          this.setState({ isSavingTrim: false })
        }
      )
    })
  }

  seekPlayer = (width, x) => {
    const player = getActivePlayer()
    const { program } = this.props
    if (player && program) {
      let bufferTime =
        program.channel.defaultTrimStart !== null ? program.channel.defaultTrimStart / 60 : 15
      const startTime = moment(program.broadCastStartTime).add(-bufferTime, 'minute')
      const endTimeObj = _.cloneDeep(startTime).add(playTime, 'second')
      const now = moment()
      const isLive = now.isBefore(endTimeObj)
      if (isLive) {
        const bufferBar = document.querySelector('#player-progress .playable')
        const playableWidth = bufferBar.clientWidth
        if (playableWidth > x) {
          const seekPercentage = x / width
          let seekTime = seekPercentage * playTime
          const totalPlayableTime = _.cloneDeep(now).diff(_.cloneDeep(startTime), 'seconds')
          seekTime = seekTime - totalPlayableTime
          player.seek(Math.round(seekTime))
        }
      } else {
        const seekPercentage = x / width
        const seekTime = seekPercentage * playTime
        player.seek(Math.round(seekTime))
      }
    }
  }

  onChangeVideo = value => {
    const { videoList, onChangeVideo } = this.props
    const selectedVideo = videoList.find(item => item.name === value)
    if (selectedVideo && onChangeVideo) {
      const videoType = selectedVideo.isProgram ? 'channel' : 'vod'
      onChangeVideo(videoType, selectedVideo.id, selectedVideo.isLinearChannel)
    }
  }

  render () {
    const {
      onPlayerClose,
      program,
      videoId,
      markIn,
      markOut,
      site,
      assetType,
      showPlayer,
      videoList,
      isLinearChannel,
      selectedVideo,
      savedTrim,
      savedSite,
      isCollapsable,
      vodJobs,
      drmRequired,
      selectedVideoDetails
    } = this.props
    const {
      isSaved,
      playlist,
      isPlayerReady,
      isEditing,
      isPlayerError,
      playing,
      buffer,
      playerRange,
      isSavingTrim,
      isError,
      showResize,
      isDragging,
      playerStartTime
    } = this.state
    let duration
    let defaultTrimStart = program && program.channel && program.channel.defaultTrimStart
    if (program) {
      duration = this.getDuration()
    }
    let isLive = false
    let isValid = false
    if (program) {
      let bufferTime =
        program.channel.defaultTrimStart !== null ? program.channel.defaultTrimStart / 60 : 15
      const startTime = moment(program.broadCastStartTime).add(-bufferTime, 'minute')
      const endTimeObj = _.cloneDeep(startTime).add(playTime, 'second')
      isLive = moment().isBefore(endTimeObj) && moment().isAfter(startTime)
      isValid = moment().isAfter(startTime) && moment().diff(startTime, 'days') < expiryDay
    }
    const isEditable =
      !!duration &&
      videoId === program.id &&
      assetType !== 'LINEAR-CHANNEL' &&
      isValid &&
      !isLinearChannel

    return (
      <div
        id='player-container'
        className={`player-container ${isPlayerReady ? 'ready' : ''} ${
          isEditing ? 'edit-mode' : ''
        } ${isEditable ? 'custom' : ''} ${!showPlayer ? 'hide' : ''}  ${
          !isCollapsable ? 'sidebar-player' : ''
        }`}>
        {playlist ? (
          <JSPlayer
            playerStartTime={playerStartTime}
            playerId={playerId}
            playlist={playlist}
            onError={this.onError}
            onSetupError={this.onError}
            isAutoPlay
            onResume={this.onResume}
            onPlay={this.onPlay}
            onTogglePlayer={this.onTogglePlayer}
            onPause={this.onPause}
            onAutoStart={this.onAutoStart}
            onReady={this.onReady}
            onVideoLoad={this.onVideoLoad}
            onOneHundredPercent={this.onOneHundredPercent}
            onClickProgressBar={this.onClickProgressBar}
            customProps={{ playbackRateControls: [0.5, 1, 2, 3, 4] }}
            isEditable={isEditable}
            showPlayer={showPlayer}
            isLive={isLive}
            defaultTrimStart={
              program && program.channel && program.channel.defaultTrimStart !== null
                ? program.channel.defaultTrimStart / 60
                : 15
            }
            broadCastStartTime={
              program && program.broadCastStartTime ? program.broadCastStartTime : null
            }
            playTime={playTime}
          />
        ) : null}
        {/* {isPlayerReady && isEditable && vodStatus ? <p className='vod-status'>Converting to VOD: <span>{vodStatus.status !== 'UNDEFINED' ? vodStatus.status : 'QUEUED'}</span> </p> : null} */}
        {isPlayerReady && isEditable ? (
          <div id='player-progress' className='player-progress' onClick={this.onClickProgressBar}>
            <div className='played' style={{ width: `${playing > 100 ? 100 : playing}%` }} />
            <div className='playable' style={{ width: `${buffer > 100 ? 100 : buffer}%` }} />
            {isLive ? (
              <div className='live-circle' style={{ left: `calc( ${buffer}% - 8px)` }} />
            ) : null}
          </div>
        ) : null}
        {isPlayerReady && videoList && videoList.length > 1 ? (
          <div className='play-list'>
            <DropDown
              selectedValue={selectedVideo}
              options={videoList || []}
              valueParam='name'
              displayParam='name'
              onOptionSelect={this.onChangeVideo}
            />
          </div>
        ) : null}
        {isPlayerReady ? (
          <div className='overlay'>
            <div className='border' />
            <div
              className='drag'
              onMouseDown={this.onMouseDown}
              style={{
                left: `${this.position}px`,
                cursor: isDragging ? 'ns-resize' : 'ns-resize',
                display: showResize || isDragging ? 'block' : 'none'
              }}
            />
          </div>
        ) : null}
        {isPlayerReady ? (
          <PlayerMenu
            playTime={playTime}
            playerRange={playerRange}
            onChangeRange={this.onChangeRange}
            showPlayer={showPlayer}
            site={site}
            vodJobs={vodJobs}
            savedSite={savedSite}
            allowTrim={isEditing}
            savedTrim={savedTrim}
            isError={isError}
            isSavingTrim={isSavingTrim}
            markIn={markIn ? moment(new Date(markIn)) : null}
            markOut={markOut ? moment(new Date(markOut)) : null}
            startTime={program ? program.broadCastStartTime : null}
            endTime={program ? program.broadCastEndTime : null}
            onEditChange={this.onEditChange}
            isSaved={isSaved}
            updateVideoEncoding={this.updateVideoEncoding}
            duration={duration}
            onPlayerClose={this.onTogglePlayer}
            isEditable={isEditable}
            seekPlayer={this.seekPlayer}
            onSaveTrim={this.onSaveTrim}
            drm={drmRequired}
            selectedVideoDetails={selectedVideoDetails}
            defaultTrimStart={defaultTrimStart || defaultTrimStart === 0 ? defaultTrimStart : null}
          />
        ) : isPlayerError ? (
          <PlayerCloseIcon className='invalid-player-close' onClick={onPlayerClose} />
        ) : null}
      </div>
    )
  }
}

Player.propTypes = {}
export default withApollo(
  compose(
    graphql(QueryGetSavedTrim, {
      skip: ({ assetId }) => {
        return !assetId
      },
      options: ({ assetId, project }) => {
        return {
          variables: { id: assetId, project }
        }
      },
      props: props => {
        let savedTrim, savedSite
        if (props.data && props.data.getAsset) {
          if (props.data.getAsset.markInTime) {
            savedTrim = {
              markIn: props.data.getAsset.markInTime,
              markOut: props.data.getAsset.markOutTime
            }
          }
          savedSite = props.data.getAsset.site
        }
        return { savedTrim, savedSite }
      }
    }),
    graphql(MutationUpdateSavedTrim, {
      props: props => ({
        updateSavedTrim: event => {
          return props.mutate({
            variables: event
          })
        }
      })
    }),
    graphql(MutationLiveToVod, {
      options: ({ assetId, project }) => {
        return {
          update: (cache, { data: { triggerLiveToVod } }) => {
            const cacheData = cache.readQuery({
              query: QueryGetAssetDetails,
              variables: { id: assetId, project }
            })
            if (cacheData.getAsset && cacheData.getAsset) {
              const index = (cacheData.getAsset.vodJobs || []).findIndex(
                item => item.id === triggerLiveToVod.id
              )
              if (index === -1) {
                cacheData.getAsset.vodJobs.push(triggerLiveToVod)
              } else {
                cacheData.getAsset.vodJobs[index] = triggerLiveToVod
              }
              // cacheData.getAsset.vodStatus = triggerLiveToVod
            }
            cache.writeQuery({
              query: QueryGetAssetDetails,
              data: cacheData,
              variables: { id: assetId }
            })
          }
        }
      },
      props: props => ({
        triggerLiveToVod: variables => {
          return props.mutate({
            variables
          })
        }
      })
    }),
    graphql(QueryPlayerDrm, {
      skip: ({ streamRequest }) => {
        return streamRequest.drm === 'CLEAR'
      },
      options: ({ streamRequest }) => {
        return {
          variables: streamRequest,
          fetchPolicy: 'network-only'
        }
      },
      props: props => {
        return {
          streamData:
            props.data && props.data.getDrmContentLicense ? props.data.getDrmContentLicense : null,
          drmError: props.data.error,
          fetchDRMDetails: () => {
            return props.data.refetch()
          }
        }
      }
    })
  )(Player)
)
