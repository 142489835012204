import React from 'react'
import PropTypes from 'prop-types'
import ViewAppleNewsIcon from '../icons/ViewAppleNewsIcon'
import './../../ui.style.scss'
import LoadingButton from './LoadingButton'

const ViewAppleNewsButton = ({ isDisabled, isLoading, onClick, ...props }) => (
  <div className={`black-button page-view ${isDisabled ? 'disabled' : ''}`} onClick={onClick} >
    {isLoading ? <LoadingButton buttonText={''} buttonClass={'apple-news'} isLoading={isLoading} /> : <ViewAppleNewsIcon color='#fff' />}
  </div>
)

ViewAppleNewsButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired,
  /** Button loading Apple URL */
  isLoading: PropTypes.bool
}

ViewAppleNewsButton.defaultProps = {
  isDisabled: false,
  isLoading: false
}

export default ViewAppleNewsButton
