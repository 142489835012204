import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'

const SidebarOpenButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`sidebar-close ${isDisabled ? 'disabled' : ''}`} onClick={onClick} >
    <svg width='37' height='37' viewBox='0 0 37 37'>
      <g fill='none' fillRule='evenodd'>
        <rect width='37' height='37' fill='#343A40' rx='2' />
        <path d='M9 9h20v20H9z' />
        <path fill='#FFF' fillRule='nonzero' d='M28 26.778V11.222C28 9.994 26.993 9 25.75 9h-13.5C11.007 9 10 9.994 10 11.222v13.445h1.25V10.222H24.5v17.556H11.25v-5.445H10v4.445C10 28.006 11.007 29 12.25 29h13.5c1.243 0 2.25-.994 2.25-2.222z' />
        <path fill='#FFF' fillRule='nonzero' d='M13.39 18.333l3.36-2.973c.19-.169.337-.36.625-.36.39 0 .625.344.625.667v2h4.375c.344 0 .625.3.625.666v1.334c0 .366-.281.666-.625.666H18v2c0 .323-.234.667-.625.667-.288 0-.434-.191-.625-.36l-3.36-2.973c-.239-.208-.39-.37-.39-.667 0-.297.151-.458.39-.667z' />
      </g>
    </svg>

  </div>
)

SidebarOpenButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

SidebarOpenButton.defaultProps = {
  isDisabled: false
}

export default SidebarOpenButton
