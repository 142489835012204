import React, { Component } from 'react'
import { Skeleton, message } from 'antd'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

import AppContext from '../../../AppContext'

import LoadingButton from '../../../components/ui/general/buttons/LoadingButton'
import BucketGroupTypeCell from '../../../components/ui/dataDisplay/BucketGroupTypeCell'
import CreateBucketGroupTypeModal from '../../../components/ui/dataEntry/other/CreateBucketGroupTypeModal'
import CropImageModal from '../../../components/ui/dataEntry/other/CropImageModal'
import AddImageModal from '../../../components/ui/dataEntry/other/AddImageModal'
import AdminItemConfirmModal from '../../../components/ui/feedback/AdminItemConfirmModal'
import FixedTableHeader from '../../../components/ui/dataDisplay/FixedTableHeader'
import { getCropImageUrl } from '../../../util/util'
import userMessages from '../../../constants/messages'
import { utilityService } from '../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryBucketGroupType from '../../../graphQL/admin/bucket/listBucketGroupType'
import MutationDeleteBucketGroupType from '../../../graphQL/admin/bucket/deleteBucketGroupType'
import MutationRearrangeBucketGroupType from '../../../graphQL/admin/bucket/batchUpdateBucketGroupType'
import FilterInput from '../../../components/ui/dataEntry/inputs/FilterInput'
import GroupCompetitionIcon from '../../../components/ui/general/icons/GroupCompetitionIcon'

const deleteBucketTypeMessage = {
  title: 'DELETE BUCKET GROUP TYPE',
  firstMessage: 'Are you sure you want to delete this bucket group type?',
  secondMessage: 'This action cannot be undone.'
}

// const defaultImage = require('../../../assets/images/avatar.jpg')

class BucketGroupType extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedGroupType: null,
      isLoading: !(props.groupTypeList && props.groupTypeList.length),
      selectedGroupType: undefined,
      showCreateBucketGroupModal: false,
      showDeleteGroupTypeModal: false,
      groupTypeList: cloneDeep(props.groupTypeList).sort((a, b) => a.position - b.position)
    }
    this.updatedContents = []
    this.bucketGroupPos = []
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps = newProps => { // eslint-disable-line camelcase
    if (
      !newProps.isLoading &&
      (!_.isEqual(newProps.groupTypeList, this.props.groupTypeList) ||
      !(newProps.groupTypeList && newProps.groupTypeList.items && newProps.groupTypeList.items.length))
    ) {
      const orderedList = this.reorderList(newProps.groupTypeList)
      this.setState({ isSearching: false, isPaginating: false, isLoading: false, groupTypeList: orderedList })
    }
  }

  reorderList = (listArray) => {
    const listToSort = cloneDeep(listArray)
    let sortedData = listToSort.sort((a, b) => a.position - b.position)
    return sortedData
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { target } = event
    const { className } = target
    const availableOptions = [ 'edit', 'delete' ]
    const isOptionClicked = availableOptions.findIndex(item => className === item)
    if (isOptionClicked > -1) {
      return
    }
    if (this.state.openedGroupType) {
      this.setState({ openedGroupType: null })
    }
  }

    toggleShowOptions = id => {
      if (this.state.openedGroupType === id) {
        this.setState({ openedGroupType: null })
      } else {
        this.setState({ openedGroupType: id })
      }
    }

    showAddAuthor = () => {
      this.setState({ showCreateBucketGroupModal: true, selectedGroupType: null })
    }

    hideCreateAuthorModal = () => {
      this.setState({ showCreateBucketGroupModal: false })
    }

    showCropModal = (images) => {
      let { selectedImage } = this.state
      if (!selectedImage) {
        selectedImage = images
      }
      const aspectRatio = selectedImage[0].aspectRatio.find(item => item.title === '1:1')
      const settings = selectedImage[0].settings.filter(item => item.aspectRatio === aspectRatio.aspectRatio)
      this.setState({ selectedImage, showCreateBucketGroupModal: false, settings, aspectRatio }, () => this.setState({ showCropModal: true }))
    }

    hideCropModal = () => {
      this.setState({ showCropModal: false, showCreateBucketGroupModal: true })
    }

    onCreateBucketGroupModal = () => {
      this.setState({ showCreateBucketGroupModal: false })
    }

    clearSelectedImage = () => {
      this.setState({ selectedImage: null, croppedUrl: null })
    }

    showAddImageModal = () => {
      this.setState({ showAddImageModal: true, showCreateBucketGroupModal: false })
    }

    hideAddImageModal = () => {
      this.setState({ showAddImageModal: false, showCreateBucketGroupModal: true })
    }

    saveCurrentSelection = (selectedImage) => {
      this.setState({ selectedImage, showAddImageModal: false, showCreateBucketGroupModal: true })
    }

    saveCurrentCrop = (settings) => {
      const image = { ...this.state.selectedImage[0] }
      const currentAspectRatio = image.aspectRatio.find(item => item.title === '1:1')
      const currentaspectId = currentAspectRatio.aspectRatio
      const changedIndex = image.settings.findIndex(item => item.aspectRatio === currentaspectId)
      // const tags = image.tags && image.tags.length ? image.tags.map(item => item.id) : null
      image.settings.map(setting => {
        setting.outputFormat = 'JPG'
        delete setting[ '__typename' ]
      })
      image.settings[changedIndex].x1 = settings.x1
      image.settings[changedIndex].x2 = settings.x2
      image.settings[changedIndex].y1 = settings.y1
      image.settings[changedIndex].y2 = settings.y2
      // this.props.updateImage(image.id, image.name, tags, image.settings)
      const croppedUrl = getCropImageUrl(image, settings)
      this.setState({ showCropModal: false, showCreateBucketGroupModal: true, croppedUrl })
    }

    handleBucketGroupType = (selectedGroupType, type) => {
      if (type === 'delete') {
        this.setState({ showDeleteGroupTypeModal: true, selectedGroupType })
      }
      if (type === 'edit') {
        this.setState({ showCreateBucketGroupModal: true, selectedGroupType })
      }
    }

    handleCancelDeleteGroupTypeModal = () => {
      this.setState({ showDeleteGroupTypeModal: false, selectedGroupType: null })
    }

    handleDeleteGroupTypeModal = () => {
      const { selectedGroupType } = this.state
      const { onHistoryUpdate } = this.props
      this.props.deleteBucketGroupType(selectedGroupType.id).then(() => {
        onHistoryUpdate()
        const isDeletedGroupPresent = this.updatedContents.findIndex(item => item === selectedGroupType.id)
        if (isDeletedGroupPresent > -1) {
          this.updatedContents.splice(isDeletedGroupPresent, 1)
        }
        this.setState({ showDeleteGroupTypeModal: false, selectedGroupType: null })
      }, error => {
        this.setState({ showDeleteGroupTypeModal: false, selectedGroupType: null })
        utilityService.handleError(error)
      })
    }

    reorder = (startIndex, endIndex) => {
      const { groupTypeList } = this.state
      console.log('groupTypeList', groupTypeList)
      // const result = Array.from(groupTypeList)
      // const [removed] = result.splice(startIndex, 1)
      // result.splice(endIndex, 0, removed)
      // this.setState({ groupTypeList: result })

      const result = Array.from(cloneDeep(groupTypeList))
      const [removed] = result.splice(startIndex, 1)
      const isDecreasing = endIndex < startIndex
      const largerIndex = isDecreasing ? startIndex : endIndex
      const smallerIndex = isDecreasing ? endIndex : startIndex
      const modifiedResult = result.map((item, index) => {
        if (index >= smallerIndex && index < largerIndex) {
          item.position = item.position + (isDecreasing ? 1 : -1)
        }
        return item
      })
      removed.position = endIndex === result.length ? (result[endIndex - 1].position + 1) : (result[endIndex].position - 1)
      modifiedResult.splice(endIndex, 0, removed);
      [...modifiedResult].splice(smallerIndex, largerIndex - smallerIndex + 1).map(item => {
        this.updatedContents.push(item.id)
      })
      this.bucketGroupPos.push(groupTypeList[startIndex].id)

      console.log('modifiedResult', modifiedResult)

      // console.log('isequal', isEqual(modifiedResult, groupTypeList))
      this.setState({ groupTypeList: modifiedResult }, this.updateBucketGroupPosition)
    }

    onChangeFilter = value => {
      this.setState({ searchString: value })
      this.props.onChangeBucketGroupFilter({ searchString: value })
    }

      onDragEnd = result => {
        if (!result.destination || result.source.index === result.destination.index) {
          return
        }
        this.reorder(result.source.index, result.destination.index)
      }

      updateBucketGroupPosition = () => {
        const { groupTypeList } = this.state
        // const { onHistoryUpdate } = this.props
        this.updatedContents = [...new Set(this.updatedContents)]
        const updatedBuckets = this.updatedContents.map(item => {
          const selectedBucketGroup = groupTypeList.find(group => group.id === item)
          const isAuditChange = this.bucketGroupPos.includes(selectedBucketGroup.id)
          let trackPosChange = isAuditChange
          return { id: selectedBucketGroup.id, position: selectedBucketGroup.position, name: selectedBucketGroup.name, trackPosChange: trackPosChange }
        })
        this.props.batchUpdateBucketGroupPosition(updatedBuckets).then(() => {
          // onHistoryUpdate()
          this.bucketGroupPos = []
          message.success(userMessages.BUCKET_GROUP_POSITION_SUCCESS)
        }, error => {
          this.setState({ isSaving: false, isLoading: false })
          utilityService.handleError(error)
        })
      }

      render () {
        const { isLoading, openedGroupType, selectedGroupType, showDeleteGroupTypeModal, showCreateBucketGroupModal, croppedUrl, selectedImage, groupTypeList, showCropModal,
          aspectRatio, settings, showAddImageModal, searchString } = this.state
        const { onHistoryUpdate } = this.props
        let adminItem
        if (selectedGroupType && showDeleteGroupTypeModal) {
          adminItem = <div className='author-list-name'>
            <GroupCompetitionIcon />
            {/* <img src={selectedGroupType.media && selectedGroupType.media.length && selectedGroupType.media[ 0 ] ? generateCroppedThumbnail(selectedGroupType.media[0], 25, 25, '1:1') : defaultImage} /> */}
            <p>{ selectedGroupType.name }</p>
          </div>
        }
        const columnCellList = [
          {
            title: 'Bucket Group Type',
            width: '10'
          },
          {
            title: 'Bucket Groups Created',
            width: '10'
          },
          {
            width: '1'
          }
        ]
        return (
          <AppContext.Consumer>
            {({ permissions, project }) => {
              const userPermissions = permissions['DATA_MANAGER']
              const isCreateDisabled = userPermissions.indexOf('CREATE') === -1
              const isDeleteDisabled = userPermissions.indexOf('DELETE') === -1
              const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
              const userPermissionsContent = permissions['CONTENT_BANK']
              const isUpdateDisabledContent = userPermissionsContent.indexOf('UPDATE') === -1
              const isCreateDisabledContent = userPermissionsContent.indexOf('CREATE') === -1
              return <div className='admin-portal bucket-group-type'>
                <FilterInput
                  searchString={searchString}
                  onChangeSearchInput={this.onChangeFilter}
                  project={project}
                  // filterType={'AssetManager'}
                  // changeFilter={(value) => {
                  //   this.setState({ isSearching: true, filterData: value }, () => {
                  //     this.changeFilterValue(value)
                  //   })
                  // }}
                  placement='rightTop'
                  // isClearFilter={isClearFilter}
                  // initialSearch={initialSearch}
                  // username={this.username}
                  // bucketAssetFilter={filterData}
                />
                <div className='admin-list'>
                  <FixedTableHeader columnCellList={columnCellList} />
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }} loading={isLoading}>
                    <div className='bucket-group-heading bucket-group-general'>
                      <div className='type'> Bucket Group Type </div>
                      <div className='name'> Bucket Groups Created </div>
                      <div className='expand' />
                    </div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId='droppable'>
                        {(droppableProvided, droppableSnapshot) => (
                          <div className='bucket-group-content'
                            ref={droppableProvided.innerRef}
                            // style={getListStyle(droppableSnapshot.isDraggingOver)}
                          >
                            {groupTypeList.map((group, index) => (
                              <Draggable key={index} draggableId={`id${index}`} index={index} isDragDisabled={isUpdateDisabled}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    // style={getItemStyle(
                                    //   snapshot.isDragging,
                                    //   provided.draggableProps.style
                                    // )}
                                  >
                                    <BucketGroupTypeCell bucketGroup={group} isOpened={openedGroupType === group.id} toggleShowOptions={this.toggleShowOptions} handleBucketGroupType={this.handleBucketGroupType} isUpdateDisabled={isUpdateDisabled} isDeleteDisabled={isDeleteDisabled} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Skeleton>
                  {/* <Skeleton active avatar={false} title paragraph={{ rows: 1 }} loading={isPaginating} /> */}
                </div>
                <div className='admin-footer' >
                  <LoadingButton
                    type='primary'
                    onClick={this.showAddAuthor}
                    htmlType='submit'
                    buttonText={'New'}
                    buttonClass='save-btn'
                    isLoading={false}
                    isDisabled={isCreateDisabled}
                  />
                </div>
                <CropImageModal
                  isVisible={showCropModal}
                  aspectRatio={aspectRatio}
                  imgUrl={settings && settings.length ? settings[0].fileName : null}
                  settings={settings}
                  updateSettings={this.saveCurrentCrop}
                  handleCancel={this.hideCropModal}
                  isUpdateBlocked={isUpdateDisabledContent} />
                <AddImageModal
                  isMultiSelect={false}
                  isVisible={showAddImageModal}
                  handleSubmit={this.saveCurrentSelection}
                  handleCancel={this.hideAddImageModal}
                  mediaType='IMAGE'
                  isUpdateBlocked={isUpdateDisabledContent}
                  isSubmitButtonDisabled={isCreateDisabledContent}
                  project={project} />
                <CreateBucketGroupTypeModal
                  groupTypeList={groupTypeList}
                  selectedGroupType={selectedGroupType}
                  clearSelection={this.clearSelectedImage}
                  isVisible={showCreateBucketGroupModal}
                  croppedUrl={croppedUrl}
                  showCropModal={this.showCropModal}
                  selectedImage={selectedImage}
                  showImageModal={this.showAddImageModal}
                  handleCancel={this.hideCreateAuthorModal}
                  handleSubmit={this.onCreateBucketGroupModal}
                  isSubmitDisabled={_.isEmpty(selectedGroupType) ? isCreateDisabled : isUpdateDisabled}
                  onHistoryUpdate={onHistoryUpdate}
                  project={project} />
                <AdminItemConfirmModal
                  isVisible={showDeleteGroupTypeModal}
                  title={deleteBucketTypeMessage.title}
                  firstMessage={deleteBucketTypeMessage.firstMessage}
                  secondMessage={deleteBucketTypeMessage.secondMessage}
                  adminItem={adminItem} rightButtonText={'Delete'}
                  handleCancel={this.handleCancelDeleteGroupTypeModal}
                  handleSubmit={this.handleDeleteGroupTypeModal}
                  isSubmitButtonDisabled={isDeleteDisabled}
                  isCancelButtonDisabled={false} />
              </div>
            }}
          </AppContext.Consumer>
        )
      }
}

export default withApollo(compose(
  graphql(
    QueryBucketGroupType,
    {
      options: ({ bucketGroupFilter, project }) => {
        const { searchString } = bucketGroupFilter
        const filter = searchString ? { name: { match: searchString } } : null
        const variables = { filter, limit: 50, project }
        return {
          fetchPolicy: 'network-only',
          variables
        }
      },
      props: (props) => {
        const { data } = props
        const groupTypeList = data.listBucketGroupType ? data.listBucketGroupType.items : []
        return {
          groupTypeList,
          isLoading: data.loading || !data.listBucketGroupType
        }
      }
    }
  ),
  graphql(
    MutationDeleteBucketGroupType, {
      options: ({ bucketGroupFilter, project }) => ({
        update: (cache, { data: { deleteBucketGroupType } }) => {
          const { searchString } = bucketGroupFilter
          const filter = searchString ? { name: { match: searchString } } : null
          const variables = { filter, limit: 50, project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryBucketGroupType, variables }))
          if (cacheData && cacheData.listBucketGroupType && cacheData.listBucketGroupType.items) {
            const selectedAuthorIndex = cacheData.listBucketGroupType.items.findIndex(item => item.id === deleteBucketGroupType.id)
            if (selectedAuthorIndex > -1) {
              cacheData.listBucketGroupType.items.splice(selectedAuthorIndex, 1)
            }
          }
          cache.writeQuery({
            query: QueryBucketGroupType,
            data: cacheData,
            variables
          })
        }
      }),
      props: (props) => ({
        deleteBucketGroupType: (id) => {
          return props.mutate({
            variables: { id, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    MutationRearrangeBucketGroupType,
    {
      props: (props) => ({
        batchUpdateBucketGroupPosition: (contentsArray) => {
          return props.mutate({
            variables: { contents: contentsArray, project: props.ownProps.project }
          })
        }
      })
    })

)(BucketGroupType))
