import React from 'react'
// import PropTypes from 'prop-types'
import { Checkbox, DatePicker } from 'antd'
import InformationIcon from '../general/icons/InformationIcon'
import Input from '../dataEntry/inputs/Input'
import Switch from '../dataEntry/inputs/Switch'
import TagsInputContainer from '../dataEntry/inputs/TagsInputContainer'
import './../ui.style.scss'

const { RangePicker } = DatePicker
const parentCompoent = 'root'

class AddSeason extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disable: true
    }
  }

  render () {
    const { project } = this.props
    const seasonCode = <div className='type'>
      <span>Season Opta ID</span>
      <div className='information-icon'>
        <InformationIcon />
      </div>
    </div>
    return (
      <div className='add-season'>
        <div className='season-data'>
          <Input title='Title' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
        </div>
        <div className='new-data'>
          <Input title='Title' placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
        </div>
        <div className='new-data'>
          <Input title={seasonCode} placeholder='Write a title...' inputName='title' value={''} handleChange={() => { }} />
        </div>
        <div className='season-data'>
          <label> Start/End Date </label>
          <RangePicker onChange={() => { }} format='DD MMM YYYY' getCalendarContainer={() => document.getElementById(parentCompoent)}
            value={''} />
        </div>
        <div className='season-data'>
          <label> Tags </label>
          <TagsInputContainer
            selectedItems={[]}
            onChange={() => {}}
            placeholder='Insert Tags'
            parentCompoent={'custom-tags'}
            displayParameter='name'
            onSearch={() => {}}
            project={project}
          />
        </div>
        <div className='season-data'>
          <div className='bottom'>
            <div className='type'>
              <span>Active</span>
              <div className='information-icon'>
                <InformationIcon />
              </div>
              <Switch isChecked onChange={() => { }} />
            </div>
            <div className='type'>
              <span>Including Venues</span>
              <div className='information-icon'>
                <InformationIcon />
              </div>
              <Checkbox checked={false} onClick={() => { }} />
            </div>
            <div className='type'>
              <span>Including Standings</span>
              <div className='information-icon'>
                <InformationIcon />
              </div>
              <Checkbox checked onClick={() => { }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AddSeason.propTypes = {

}

AddSeason.defaultProps = {
}

export default AddSeason
