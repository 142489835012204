import gql from 'graphql-tag'

export default gql(`
    mutation($fileKey: String! $parts: [MultipartUploadPartsInput] $uploadId: String! $assetId: ID) {
        completedMultipartUpload(input: {
            uploadId: $uploadId
            fileKey: $fileKey
            parts: $parts
            assetId: $assetId
        } )
        {
            fileKey
            url
            bucket
        }
    }
    `
)
