import React from 'react'
import PropTypes from 'prop-types'
import AddIcon from './../icons/AddIcon'
import './../../ui.style.scss'

const AddButton = ({ isDisabled, onClick, ...props }) => (
  <div className={`add-button ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => {} : onClick}>
    <AddIcon />
  </div>
)

AddButton.propTypes = {
  /** disabled status of Button. */
  isDisabled: PropTypes.bool,
  /** Button click action. */
  onClick: PropTypes.func.isRequired
}

AddButton.defaultProps = {
  isDisabled: false
}

export default AddButton
