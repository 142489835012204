import React, { Component } from 'react'
import { message } from 'antd'
// import PropTypes from 'prop-types'
import Accordion from './Accordion'
import TextAreaInput from '../dataEntry/inputs/TextArea'
import CategoryDropDown from '../dataEntry/inputs/CategoryDropDown'
import Switch from '../dataEntry/inputs/Switch'
import CopyLinkIcon from '../general/icons/CopyLinkIcon'
import TagsInput from '../dataEntry/inputs/TagsInput'

const predefinedVariables = [
  '{ASSET_TYPE}',
  '{HOME_TEAM_NAME}',
  '{AWAY_TEAM_NAME}',
  '{HOME_TEAM_CUSTOM_NAME}',
  '{AWAY_TEAM_CUSTOM_NAME}',
  '{HOME_TEAM_SHORT_NAME}',
  '{AWAY_TEAM_SHORT_NAME}',
  '{HOME_TEAM_CUSTOM_SHORT_NAME}',
  '{AWAY_TEAM_CUSTOM_SHORT_NAME}',
  '{MATCH_DATE}',
  '{MATCH_FINISH}',
  '{MATCH_KICKOFF}',
  '{SEASON_END}',
  '{STAGE}',
  '{WEEK}',
  '{MATCH_DESCRIPTION}',
  '{VENUE_NAME}',
  '{SEASON}',
  '{COMPETITION_CODE}',
  '{COMPETITION_NAME}',
  '{HOME_TEAM_CODE}',
  '{AWAY_TEAM_CODE}',
  '{HOME_TEAM_CUSTOM_CODE}',
  '{AWAY_TEAM_CUSTOM_CODE}',
  '{SPORT}'
]

class TemplateAssetDetails extends Component {
  state = {
    categorySearch: null
  }

  onCategorySearch = (value) => {
    this.setState({ categorySearch: value })
  }

  copyToClipboard = (text) => {
    // let urlCopyMessage = ''
    let textField = document.createElement('textarea')
    // const { selectedAspect, details } = this.props
    // const filterSettings = details.settings.find((item) => item.aspectRatio === selectedAspect)
    const url = text
    textField.innerText = url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    const urlCopyMessage = `Copied ${text} to clipboard`
    if (urlCopyMessage) {
      message.success(urlCopyMessage)
    }
    this.setState({ urlCopyMessage })
  }

  onTagChange = (value, isDelete) => {
    const { details } = this.props
    let { seoKeywords } = details
    if (isDelete) {
      const index = seoKeywords.indexOf(value)
      if (index > -1) {
        seoKeywords.splice(index, 1)
      }
    } else {
      if (!seoKeywords) {
        seoKeywords = [value]
      } else {
        const index = seoKeywords.indexOf(value)
        if (index === -1) {
          seoKeywords.push(value)
        }
      }
    }
    this.props.handleSeoKeywordChange(seoKeywords)
  }

  render () {
    const { details, handleGeneralChange, onLiveStatusChange, onPremiumStatusChange, onCategorySelect } = this.props
    const { categorySearch } = this.state

    const childComponent = <div className='template-asset'>
      <div className='right'>
        <div className='top'>
          <label> Predefined Variables </label>
        </div>
        <div className='bottom'>
          {predefinedVariables.map(item => {
            return <div key={item} className='variable-options'>
              <p> {item} </p>
              <div className='copy-field' onClick={() => this.copyToClipboard(item)}><CopyLinkIcon /></div>
            </div>
          })}
        </div>
      </div>
      <div className='left'>
        <div className='asset-data'>
          <div className='details'>
            <TextAreaInput
              title='Title'
              placeholder='{ASSET_TYPE}: {HOME_TEAM_NAME} Vs {AWAY_TEAM_NAME}'
              inputName='title'
              onFocus={() => {}}
              onBlur={() => {}}
              value={details.title}
              handleChange={handleGeneralChange}
            />
          </div>
          <div className='details'>
            <TextAreaInput
              title='Short Title'
              placeholder='{HOME_TEAM_CODE} Vs {AWAY_TEAM_CODE}'
              inputName='shortTitle'
              onFocus={() => {}}
              onBlur={() => {}}
              value={details.shortTitle}
              handleChange={handleGeneralChange}
            />
          </div>
          <div className='details'>
            <TextAreaInput
              title='Short Description'
              placeholder='{ASSET_TYPE}: Match between {HOME_TEAM_NAME} and {AWAY_TEAM_NAME}'
              inputName='description'
              onFocus={() => {}}
              onBlur={() => {}}
              value={details.description}
              handleChange={handleGeneralChange}
            />
          </div>
          <div className='details'>
            <TextAreaInput
              title='SEO Title'
              placeholder='{HOME_TEAM_NAME} Vs {AWAY_TEAM_NAME}'
              inputName='seoTitle'
              onFocus={() => {}}
              onBlur={() => {}}
              value={details.seoTitle}
              handleChange={handleGeneralChange}
            />
          </div>
          <div className='details seo-keyword-container' id={'seo-keyword-container-match'}>
            <div>SEO Keyword</div>
            <TagsInput
              selectedItems={details.seoKeywords || []}
              tagList={[]}
              className={'key-word-dropdown'}
              mode={'tags'}
              onChange={this.onTagChange}
              placeholder='Insert SEO Keyword'
              displayParameter='name'
              parentCompoent={'seo-keyword-container-match'}
            />
          </div>
          <div className='details'>
            <TextAreaInput
              title='SEO Description'
              placeholder='{ASSET_TYPE}: of {STAGE_NAME} Match between {HOME_TEAM_NAME} and {AWAY_TEAM_NAME}'
              inputName='seoMetaDescription'
              onFocus={() => {}}
              onBlur={() => {}}
              value={details.seoMetaDescription}
              handleChange={handleGeneralChange}
            />
          </div>
          <div className='details' id='details'>
            <label>Category</label>
            <CategoryDropDown
              parentCompoent={'details'}
              onCategorySelect={onCategorySelect}
              selectedCategory={details.category}
              onSearch={this.onCategorySearch}
              categorySearch={categorySearch}
            />
          </div>
          <div className='details'>
            <div className='switch-container'>
              {details.assetType === 'GAME' ? <div className='type'>
                <span>Live</span>
                <Switch isChecked={details.isLive} onChange={onLiveStatusChange} />
              </div> : null}
              <div className='type'>
                <span>Premium</span>
                <Switch isChecked={(!details.isFree || details.isFree === 'false')} onChange={onPremiumStatusChange} />
              </div>
            </div>
          </div>
          {/* <div className='details'>
            <div className='asset-no'>
              <div className='asset-require'>
                <label>Min Asset Requirement</label>
                <InputNumber min={1} max={10} value={details.minAssets} onChange={(value) => onRangeChange('minAssets', value)} />
              </div>
              <div className='asset-require'>
                <label>Max Asset Requirement</label>
                <InputNumber min={1} max={10} value={details.maxAssets} onChange={(value) => onRangeChange('maxAssets', value)} />
              </div>
            </div>
          </div> */}
        </div>

      </div>
    </div>
    return (
      <div className='template-asset-details'>
        <Accordion
          title={'DEFAULT ASSET DETAILS'}
          childComponent={childComponent}
        />
      </div>
    )
  }
}

TemplateAssetDetails.propTypes = {}
export default TemplateAssetDetails
