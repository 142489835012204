import gql from 'graphql-tag'

export default gql(`
    query{
        getContentBankSettings{
            id
            accessKey
            region
            s3BucketName
            secretAccessKey
        }
    }`
)
