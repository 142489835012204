import gql from 'graphql-tag'

export default gql(`
    mutation createPerson($name: String!, $project: String, $image: ID) {
        createPerson(
            input: {
                name: $name
                image: $image
            },
            project: $project
        ) {
            id
            name
            image{
                id
                name
                fileName
                aspectRatio {
                    aspectRatio
                    ratio
                    title          
                    resolutions {
                      url
                    }
                }
                settings {
                    aspectRatio
                    outputFormat
                    fileName
                    x1
                    y1
                    x2
                    y2          
                }
            }
            usedCount
            source
        }
        
    }`
)
