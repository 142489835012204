import gql from 'graphql-tag'

export default gql(`
    mutation($id: ID! $assetId: ID! $isDefault: String $categoryId: ID $mediaId: ID! ) {
        updateAssetVideo(
            input:  {
                id: $id
                mediaId: $mediaId
                assetId: $assetId
                isDefault: $isDefault
                categoryId: $categoryId
              }
        )  {
            id
            assetId
            mediaId
            fileName
            createdAt
            name
            fileSize
            cuePoints
            adPositions
            breakDuration
            cuePointsError
            tags{
                key
                name
                type
            }
            category{
                id
                label
                type
            }
            type
            vodStatus{
                id
                startTime
                endTime
                channel{
                    id
                    icon{
                        id
                        fileName
                        aspectRatio {
                            aspectRatio
                            ratio
                            title          
                            resolutions {
                            url
                            }
                        }
                        settings {
                            aspectRatio
                            outputFormat
                            fileName
                            x1
                            y1
                            x2
                            y2
                        }
                    }
                }
            }
            drmContent
            streams{
                id
                contentKey
                Url
                drm
                iv 
                codec
                transport
                channel {
                    id
                }
                PackagingConfigurationId
            }
            duration
            thumbnails
            isDefault
            _originalResponseBody
        }
    }`
)
