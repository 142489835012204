import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'
import _ from 'lodash'
import Input from './../inputs/Input'
import TagsInputContainer from './../inputs/TagsInputContainer'
import DropDown from './../inputs/DropDown'
import MultipleDropDown from '../inputs/AppMutiDorpDown'
import './../../ui.style.scss'

// const skippedTagsName = []

class EditAppConfigDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTag: '',
      selectedIndex: undefined
    }
  }

    onTagSearch = (searchTag) => {
      this.setState({
        searchTag
        // selectedIndex: searchTag ? index : undefined
      })
    }

    render () {
      const { name, selectedKey, handleTypeChange, handleTextChange, selectedTags, typeList, appList, groupList, selectedType, selectedApp, selectedGroup, handleAppChange, handleGroupChange, handleIdChange, project, isFromEdit, appClient, configMetaFields, isDuplicating, onChangeConfigStatus, isCritical, assignedUsers, handleUserChange, userList, details, appData, groupData, isAppAdminDisabled, userSearch, handleUserSearch } = this.props
      const { searchTag } = this.state
      const isAppCritical = !_.isEmpty(appData) ? ((appData && appData.isCritical) || false) : ((details && details.app && details.app.isCritical) || false)
      const isGroupCritical = !_.isEmpty(groupData) ? ((groupData && groupData.isCritical) || false) : ((details && details.group && details.group.isCritical) || false)
      const isCreateAppCritical = (appData && appData.isCritical) || false
      const isCreateGroupCritical = (groupData && groupData.isCritical) || false

      return (
        <div className='edit-config-details'>
          <div className='edit-input-container'>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <Input title='Name' placeholder='Config Name' inputName='title' value={name} handleChange={handleTextChange} isRequired />
              </div>
              <div className='edit-input-item'>
                <Input title='Key' placeholder='Key' inputName='title' value={selectedKey} handleChange={handleIdChange} disabled={isFromEdit && !isDuplicating} isRequired />
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <div className='output-format' id='dropdown-app'>
                  <label className='title-text'>App<span className='mandatory'>*</span></label>
                  <DropDown options={appList} onOptionSelect={handleAppChange} selectedValue={selectedApp} valueParam='id' displayParam='name' placeHolder='Output format' parent={'dropdown-app'} disabled={isFromEdit && isDuplicating} />
                </div>
              </div>
              <div className='edit-input-item'>
                <div className='output-format' id='dropdown-type'>
                  <label className='title-text'>Type<span className='mandatory'>*</span></label>
                  <DropDown
                    options={typeList}
                    onOptionSelect={handleTypeChange}
                    selectedValue={selectedType}
                    valueParam='id'
                    displayParam='name'
                    placeHolder={`Select Type`}
                    parent={'dropdown-type'}
                    disabled={isFromEdit}
                    className={selectedType ? 'selected' : ''} />
                </div>
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='edit-input-item'>
                <div className='output-format' id='dropdown-group'>
                  <label className='title-text'>Group<span className='mandatory'>*</span></label>
                  <DropDown options={selectedApp ? groupList.filter(group => group.app.id === selectedApp) : []} onOptionSelect={handleGroupChange} selectedValue={selectedGroup} valueParam='key' displayParam='name' placeHolder='Output format' parent={'dropdown-group'} disabled={!selectedApp} />
                </div>
              </div>
              <div className='system-tags'>
                <div className='input-field' id='tag-dropdown'>
                  <label className='input-title'>{'Tags'}</label>
                  <TagsInputContainer
                    selectedItems={selectedTags.map(item => item.name)}
                    onChange={this.props.handleTagChange}
                    displayParameter='name'
                    placeholder='Insert Tags'
                    parentCompoent='tag-dropdown'
                    onSearch={this.onTagSearch}
                    searchTag={searchTag}
                    type={'MANUAL'}
                    isCustom
                    module={'APP_MANAGER'}
                    isDropdownInvisible
                    appClient={appClient}
                    project={project}
                  />
                </div>
              </div>
            </div>
            <div className='edit-input-inner-container-noflex'>
              <div className='edit-input-item-noflex'>
                <div className='output-format' id='dropdown-app'>
                  <label className='title-text'>Users</label>
                  <MultipleDropDown options={userList} onOptionSelect={handleUserChange} selectedValue={assignedUsers} valueParam='id' displayParam='id' placeHolder='Select Users' parent={'dropdown-app'} disabled={isAppAdminDisabled} onSearch={handleUserSearch} searchText={userSearch} allowClear />
                </div>
              </div>
            </div>
            <div className='edit-input-inner-container'>
              <div className='switch-inner-container'>
                <span>{'Critical'}</span>
                <Switch checked={((isAppCritical || isGroupCritical) ? true : isCritical)} onChange={onChangeConfigStatus} disabled={isAppCritical || isGroupCritical || isCreateAppCritical || isCreateGroupCritical} />
              </div>
            </div>
          </div>
          {configMetaFields}
        </div>
      )
    }
}

EditAppConfigDetails.propTypes = {
  tagsList: PropTypes.array,
  /**  */
  selectedTags: PropTypes.array,
  /**  */
  handleTagChange: PropTypes.func,
  /**  */
  handleFormatChange: PropTypes.func,
  /**  */
  handleTextChange: PropTypes.func,
  /**  */
  formats: PropTypes.array,
  /**  */
  selectedFormat: PropTypes.string,
  /** List of category */
  categoryList: PropTypes.array,
  /** Function to be called on category change */
  handleCategoryChange: PropTypes.func,
  /** Selected category valu */
  selectedCategory: PropTypes.string
}

export default EditAppConfigDetails
