import React from 'react'
import PropTypes from 'prop-types'
import './../../ui.style.scss'
import Tooltip from './../../dataDisplay/ToolTip'
import InformationIcon from '../../general/icons/InformationIcon'

const onChange = (handleChange, event) => {
  const value = event.target.value
  if (!value.startsWith(' ')) { handleChange(event) }
}

const InputApp = ({ handleChange, value, placeholder, title, inputName, isEditable, isLimited, limit, isError, onBlur, onFocus, errorMessage, isRequired, readOnly, type, isOptional, noLabel, tooltipText, ...props }) => (
  <div className={`input-field ${isError ? 'error' : ''}${readOnly ? ' readOnly' : ''}`}>
    <div><label>{title}</label>{isRequired ? <span className='mandatory'>*</span> : ''} {tooltipText ? <span id='info-icon'><Tooltip placement='right' childComponent={<div className='info-icon'><InformationIcon /></div>} description={tooltipText} parentCompoent='info-icon' /></span> : null }</div>    { isLimited ? <label className='limit'><span>Remaining:</span>{ ` ${limit - (value ? value.length : 0)}` }</label> : null }
    <input name={inputName} value={value || ''} onChange={onChange.bind(this, handleChange)} placeholder={placeholder} maxLength={limit} onBlur={onBlur} onFocus={onFocus} autoComplete='off'
      readOnly={readOnly} type={type} {...props} />
    {isError ? <p>{ errorMessage } </p> : null }

  </div>
)

InputApp.propTypes = {
  /** Change action of Input. */
  handleChange: PropTypes.func,
  /** on focus action of Input. */
  onFocus: PropTypes.func,
  /** out of focus action of Input. */
  onBlur: PropTypes.func,
  /** display value of Input. */
  value: PropTypes.string,
  /** placeholder of Input. */
  placeholder: PropTypes.string,
  /** title of Input. */
  title: PropTypes.any.isRequired,
  /** inut field name of Input. */
  inputName: PropTypes.string,
  /** editable status of Input. */
  isEditable: PropTypes.bool,
  /** error field status of Input. */
  isError: PropTypes.bool,
  /** limited input field status of Input. */
  isLimited: PropTypes.bool,
  /** string limit of Input. */
  limit: PropTypes.number,
  /** requierd field or not */
  isRequired: PropTypes.bool,
  /** Boolean value for readonly props */
  readOnly: PropTypes.bool,
  /** Type of input  */
  type: PropTypes.string
}

InputApp.defaultProps = {
  isEditable: true,
  readOnly: false,
  type: 'text'
}

export default InputApp
