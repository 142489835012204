export default () => {
  self.addEventListener('message', event => { // eslint-disable-line no-restricted-globals
    if (!event) return
    let apiUrl = `${location.origin.toString()}/log`
    if (location.origin === 'http://localhost:3000' || location.origin === 'http://127.0.0.1:3000') {
      apiUrl = 'https://cms-dev.create.diagnal.com/log'
      // apiUrl = 'https://cms-qa.optussport.tv/log'
      // apiUrl = 'https://cms-uat.optussport.tv/log'
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ message: event.data })
    }

    fetch(apiUrl, requestOptions).then(response => {
      postMessage('success')
    }).catch(error => {
      postMessage(error)
    })
  })
}
