import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Button, Skeleton } from 'antd'
// import moment from 'moment'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import AppContext from '../../../../AppContext'

import SquadOriginalData from '../../dataDisplay/SquadOriginalData'
import SquadCustomData from '../../dataDisplay/SquadCustomData'
import SquadSeasonData from '../../dataDisplay/SquadSeasonData'
import LoadingButton from '../../general/buttons/LoadingButton'
import './../../ui.style.scss'

import { utilityService } from '../../../../services/UtilityService'
import MutationUpdatePlayer from '../../../../graphQL/admin/updatePlayer'
// import QueryTeam from '../../../../graphQL/admin/listTeam'
import MutationUpdatePlayerMedia from '../../../../graphQL/admin/updatePlayerMedia'
import QueryGetPlayerDetails from '../../../../graphQL/admin/getPlayer'

const { TabPane } = Tabs

class EditSquadModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      customDetails: {}
    }
  }

  resetState = () => {
    this.setState({
      customDetails: {}
    })
  }

  onEditPlayer = (details) => {
    this.setState({ customDetails: details })
  }

  onEditPlayerMedia = (playerMedia) => {
    this.setState({ playerMedia })
  }

  updatePlayerMediaCallBack = (details) => {
    return new Promise(resolve => {
      this.updatePlayerMedia(details, resolve)
    })
  }

  updatePlayerMedia = async (details, resolve) => {
    this.props.updatePlayerMedia(details).then(async () => {
      this.props.onHistoryUpdate()
      resolve(true)
    })
  }

  onCompetitionChange = async () => {
    const { customDetails, playerMedia } = this.state
    const { playerFilter, selectedSquad, onHistoryUpdate } = this.props
    this.setState({ isLoading: true })
    if (playerMedia) {
      const newDetails = {
        player: selectedSquad.player.externalId,
        team: playerFilter.teamExternalId,
        shirtNumber: playerMedia.shirtNumber,
        tournamentCalendar: playerFilter.tournamentCalendarExternalId,
        awayImage: playerMedia.awayImage ? playerMedia.awayImage.id : null,
        homeImage: playerMedia.homeImage ? playerMedia.homeImage.id : null
      }
      await this.updatePlayerMediaCallBack(newDetails)
    }
    if (!_.isEmpty(customDetails)) {
      const newDetails = {
        id: customDetails.id,
        customFirstName: customDetails.customFirstName,
        customLastName: customDetails.customLastName,
        customMatchName: customDetails.customMatchName,
        customPosition: customDetails.customPosition,
        customType: customDetails.customType,
        customNationality: customDetails.customNationality,
        customActive: customDetails.customActive,
        tags: customDetails.tags && customDetails.tags.length ? customDetails.tags.map(item => item.key) : []
      }
      this.props.updatePlayer(newDetails).then(response => {
        this.props.handleCancel()
        onHistoryUpdate()
        this.setState({ isLoading: false })
      }, error => {
        this.props.handleCancel()
        utilityService.handleError(error)
        this.setState({ isLoading: false })
      })
    } else {
      this.props.handleCancel()
      this.setState({ isLoading: false })
    }
  }

  render () {
    const { visible, handleCancel, title, okText, selectedSquad, playerIsLoading, playerFilter } = this.props
    const { customDetails, isLoading, playerMedia } = this.state
    let variables
    if (playerFilter && selectedSquad && selectedSquad.player) {
      variables = {
        id: selectedSquad.player.id,
        tournamentCalendar: playerFilter.tournamentCalendar,
        team: playerFilter.team
      }
    }
    const query = {
      readQuery: { query: QueryGetPlayerDetails, variables },
      queryName: 'getPlayer'
    }

    return (
      <AppContext.Consumer>
        {({ permissions, project }) => {
          const userPermissions = permissions['DATA_MANAGER']
          const isUpdateDisabled = userPermissions.indexOf('UPDATE') === -1
          return <Modal
            className='general-modal edit-squad-modal'
            visible={visible}
            closable={false}
            title={title}
            onCancel={handleCancel}
            okText={okText}
            cancelText='Back'
            afterClose={this.resetState}
            width='934px'
            centered
            destroyOnClose
            maskClosable={false}
            footer={<div className='modal-footer'>
              <p>
                {/* Last Updated: {moment(new Date(selectedSquad.lastUpdated)).format('DD MMM, YYYY')} */}
                {!_.isEmpty(customDetails) || !_.isEmpty(playerMedia) ? 'The changes made here will reflect in APIs and other modules of VCMS only after a re-sync of the respective competition is triggered from Opta Sync Settings page.'
                  : ''}</p>
              <div className='right-btn'>
                <Button key='back' onClick={handleCancel}> Back </Button>
                <LoadingButton type='primary' onClick={this.onCompetitionChange} htmlType='submit' buttonText='Save' buttonClass='save-btn' isLoading={isLoading} isDisabled={(_.isEmpty(customDetails) && _.isEmpty(playerMedia)) || isUpdateDisabled} />
              </div>
            </div>}
          >
            <div className='tab-details'>
              <Tabs>
                <TabPane tab='Original Data' key='1'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={playerIsLoading}>
                    {!playerIsLoading ? <SquadOriginalData selectedSquad={selectedSquad.player} /> : null}
                  </Skeleton>
                </TabPane>

                <TabPane tab='Custom Data' key='2'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={playerIsLoading}>
                    {!playerIsLoading ? <SquadCustomData selectedSquad={selectedSquad.player} onEditPlayer={this.onEditPlayer} project={project} /> : null}
                  </Skeleton>
                </TabPane>

                <TabPane tab='Season Data' key='3'>
                  <Skeleton active avatar={false} title paragraph={{ rows: 6 }}
                    loading={playerIsLoading}>
                    {!playerIsLoading ? <SquadSeasonData selectedSquad={selectedSquad.player} shirtNumber={selectedSquad.shirtNumber} awayImage={selectedSquad.awayImage} homeImage={selectedSquad.homeImage} onEditPlayer={this.onEditPlayer} onEditSquadMedia={this.onEditPlayerMedia} query={query} /> : null}
                  </Skeleton>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        }}
      </AppContext.Consumer>
    )
  }
}

EditSquadModal.propTypes = {
  /** visible action of EditSquadModal. */
  visible: PropTypes.bool,
  /** handleCancel action of EditSquadModal. */
  handleCancel: PropTypes.func
}

EditSquadModal.defaultProps = {
}

export default withApollo(compose(
  graphql(
    MutationUpdatePlayer,
    {
      options: (props) => ({
        update: (cache, { data: { updatePlayerTeamTournament } }) => {
          const { selectedSquad, playerFilter } = props
          if (playerFilter) {
            let variables
            if (selectedSquad && selectedSquad.player) {
              variables = {
                id: selectedSquad.player.id,
                tournamentCalendar: playerFilter.tournamentCalendar,
                team: playerFilter.team
              }
            }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetPlayerDetails, variables }))
            if (cacheData && cacheData.getPlayer) {
              cacheData.getPlayer.tags = updatePlayerTeamTournament.tags
              cacheData.getPlayer.customFirstName = updatePlayerTeamTournament.customFirstName
              cacheData.getPlayer.customLastName = updatePlayerTeamTournament.customLastName
              cacheData.getPlayer.customMatchName = updatePlayerTeamTournament.customMatchName
              cacheData.getPlayer.customNationality = updatePlayerTeamTournament.customNationality
              cacheData.getPlayer.customPosition = updatePlayerTeamTournament.customPosition
              cacheData.getPlayer.customType = updatePlayerTeamTournament.customType
              cacheData.getPlayer.customActive = updatePlayerTeamTournament.customActive
            }
            cache.writeQuery({
              query: QueryGetPlayerDetails,
              data: cacheData,
              variables
            })
          }
        }
      }),
      props: (props) => ({
        updatePlayer: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdatePlayerMedia,
    {
      options: (props) => ({
        update: (cache, { data: { updatePlayerTeamTournament } }) => {
          const { selectedSquad, playerFilter } = props
          if (playerFilter) {
            let variables
            if (selectedSquad && selectedSquad.player) {
              variables = {
                id: selectedSquad.player.id,
                tournamentCalendar: playerFilter.tournamentCalendar,
                team: playerFilter.team
              }
            }
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetPlayerDetails, variables }))
            if (cacheData && cacheData.getPlayer) {
              cacheData.getPlayer.shirtNumber = updatePlayerTeamTournament.shirtNumber
              cacheData.getPlayer.awayImage = updatePlayerTeamTournament.awayImage
              cacheData.getPlayer.homeImage = updatePlayerTeamTournament.homeImage
            }
            cache.writeQuery({
              query: QueryGetPlayerDetails,
              data: cacheData,
              variables
            })
          }
        }
      }),
      props: (props) => ({
        updatePlayerMedia: (variables) => {
          return props.mutate({
            variables
          })
        }
      })
    }
  )

)(EditSquadModal))
