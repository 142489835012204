import gql from 'graphql-tag'

export default gql(`
    mutation($imageObjArray: [updateAssetMediaLinkInput]) {
        batchCreateAssetMediaLink(input: $imageObjArray ) {
                id
                type
                category{
                    id
                    label
                    type
                }
        }
    }`
)
