import gql from 'graphql-tag'

export default gql(`
    mutation createGroup($name: String $key: String! $app: ID! $project: String $type: AppGroupType $isCritical: Boolean $assignedUsers: [String]) {
        createGroup(
            input: {
                name: $name
                key: $key
                app: $app
                type: $type
                isCritical: $isCritical
                assignedUsers: $assignedUsers
            },
            project: $project
        ) {
            id
            key
            name
            type
            isCritical
            assignedUsers
            app{
                id
                name
                url
                isCritical
            }
        }
    }`
)
