import React from 'react'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import CollapsedIcon from '../general/icons/CollapsedIcon'

import './../ui.style.scss'

const Panel = Collapse.Panel

class BucketFilterAccordion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeKey: ['0']
    }
  }

    click = () => {
      const { onExpandButtonClick } = this.props
      if (onExpandButtonClick) {
        onExpandButtonClick()
        this.setState({ activeKey: this.state.activeKey[0] === '0' ? ['1'] : ['0'] })
      }
    }

    render () {
      const { title, childComponent, hideCollapse } = this.props
      const { activeKey } = this.state
      const header = (
        <div className='accordion-arrow'>
          <div className='title'>{ title }</div>
          {!hideCollapse ? <CollapsedIcon onClick={this.click} /> : null }
        </div>
      )

      return (
        <Collapse className='full-accordion' activeKey={activeKey}>
          <Panel header={header} key={'1'}>
            { childComponent }
          </Panel>
        </Collapse>
      )
    }
}

BucketFilterAccordion.propTypes = {
  /** Title of BucketFilterAccordion. */
  title: PropTypes.any.isRequired,
  /** Child Component  of BucketFilterAccordion. */
  childComponent: PropTypes.any
}

BucketFilterAccordion.defaultProps = {
  isFirstClose: false
}

export default BucketFilterAccordion
