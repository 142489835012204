import gql from 'graphql-tag'

export default gql(`
query getAppPage($id: ID!, $project: String!) {
    getAppPage(id: $id, project: $project) {
        id
        key
        title
        shortTitle
        shortDescription
        description
        isCritical
        app {
          id
          name
          url
          isCritical
        }
        group {
          key
          name
          isCritical 
        }
        
        project
        createdAt
        updatedAt  
      }
}`)
