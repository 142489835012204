import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { Skeleton, message, Button } from 'antd'

import FilterInput from '../../components/ui/dataEntry/inputs/FilterInput'
import AddUploadButton from '../../components/ui/general/buttons/AddUploadButton'
import ImageFileDisplay from '../../components/ui/dataDisplay/ImageFileDisplay'
import FolderDisplay from '../../components/ui/dataDisplay/FolderDisplay'
import ImageLoadingDisplay from '../../components/ui/dataDisplay/ImageLoadingDisplay'
import UploadQueueModal from './../../components/ui/feedback/UploadQueueModal'
import ApiService from './../../services/ApiService'
import { Menu, Item, contextMenu } from 'react-contexify'
import EditFolderModal from '../../components/ui/dataEntry/other/EditFolderModal'
import DeleteFolderModal from '../../components/ui/dataEntry/other/DeleteFolderModal'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { cloneDeep, flowRight as compose } from 'lodash'
import QueryUploadImage from '../../graphQL/asset/getUploadUrl'
import QueryFilterMedia from '../../graphQL/content/filterMedia'
import QueryListFolders from '../../graphQL/content/listFolders'
import MutationCreateMedia from '../../graphQL/content/createMedia'
import MutationAddFolder from '../../graphQL/content/addFolder'
import MutationUpdateFolder from '../../graphQL/content/updateFolder'
import MutationDeleteFolders from '../../graphQL/content/deleteFolders'
import { generateCroppedThumbnail } from './../../util/util'
import { utilityService } from '../../services/UtilityService'
const defaultImage = require('../../assets/images/default-image.jpg')

class MediaList extends React.Component {
  constructor (props) {
    super(props)
    let imageList = []
    if (props.imageList && props.imageList.length) {
      imageList = this.filterImages(props.imageList, props.currentSelection).sort((a, b) => {
        return moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
      })
      this.selectedIndex = 0
      if (!props.isModal) {
        this.onImageSelect(0)
      }
    }
    let folderList = []
    if (props.folderList && props.folderList.length) {
      folderList = this.filterImages(props.folderList, props.currentSelection).sort((a, b) => {
        return moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
      })
      this.selectedIndex = 0
      if (!props.isModal) {
        this.onImageSelect(0)
      }
    }
    this.state = {
      imageList: [],
      folderList,
      uploadingFiles: [],
      loading: false,
      visible: false,
      isLocalFolderOpen: false,
      isCreateDisabled: false,
      addFolderModal: false,
      editFolderModal: false,
      editFolderInfo: [],
      deleteFolderModal: false,
      deleteItems: [],
      isaddFolderLoading: false,
      isDeleteFolderLoading: false,
      imageListHold: imageList,
      selectedFolder: undefined,
      searchTag: ''
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    const { uploadData, isUploadError } = nextProps
    if (isUploadError && !this.props.isUploadError) {
      this.setState({ uploadingFiles: [] })
    }
    if (uploadData && !_.isEqual(uploadData, this.props.uploadData)) {
      if (uploadData.length > -1) {
        uploadData.map((item) => {
          this.uploadImageApi(_.cloneDeep(item))
        })
      }
    }
    if (nextProps.imageList && (!_.isEqual(nextProps.imageList, this.props.imageList) || !_.isEqual(nextProps.currentSelection, this.state.currentSelection))) { // removed: && !nextProps.isLoading
      // if (this.props.imageList && nextProps.imageList.length === this.props.imageList.length) return
      const imageList = this.filterImages(nextProps.imageList, nextProps.currentSelection)
      this.lastIndex = 0
      // if ((this.props.filter && (!_.isEmpty(this.props.filter) && !this.props.filter.isArchived)) || this.props.searchString) {
      //   this.setState({ imageList, currentSelection: nextProps.currentSelection, isPaginating: false }, () => {
      //     if ((!nextProps.isModal && nextProps.selectedMedias && !nextProps.selectedMedias.length)) {
      //       const index = _.findIndex(nextProps.imageList, (item) => item.id === this.props.match.params.id)
      //       if (nextProps.imageList.length && index === -1) { this.onImageSelect(0) }
      //     }
      //   })
      // } else if (!_.isEmpty(nextProps.folderList) && nextProps.totalFolderCount > this.state.folderList.length) {
      //   this.setState({ imageListHold: imageList, currentSelection: nextProps.currentSelection, isPaginating: false }, () => {
      //     if ((!nextProps.isModal && nextProps.selectedMedias && !nextProps.selectedMedias.length)) {
      //       const index = _.findIndex(nextProps.imageList, (item) => item.id === this.props.match.params.id)
      //       if (nextProps.imageList.length && index === -1) { this.onImageSelect(0) }
      //     }
      //   })
      // } else {
      this.setState({ imageList: imageList, currentSelection: nextProps.currentSelection, isPaginating: false }, () => {
        if ((!nextProps.isModal && nextProps.selectedMedias && !nextProps.selectedMedias.length)) {
          const index = _.findIndex(nextProps.imageList, (item) => item.id === this.props.match.params.id)
          if (nextProps.imageList.length && index === -1) { this.onImageSelect(0) }
        }
      })
      // }
    }
    if (nextProps.folderList && !nextProps.isFolderLoading && !_.isEqual(nextProps.folderList, this.props.folderList)) {
      const folderList = this.filterImages(nextProps.folderList, nextProps.currentSelection)
      this.lastIndex = 0
      this.setState({ folderList, currentSelection: nextProps.currentSelection, isPaginating: false })
    }
    if (nextProps.deletedId !== this.props.deletedId && nextProps.deletedId) {
      const { imageList } = this.state
      const index = imageList.findIndex(selectedImage => selectedImage.id === nextProps.deletedId)
      if (index > -1) {
        if (imageList.length > 1) {
          const nextImageId = imageList[ index ? index - 1 : 1 ].id
          setTimeout(() => {
            this.props.onSelectImage(imageList, nextImageId)
          }, 500)
        } else {
          setTimeout(() => {
            this.props.onSelectImage(imageList, '')
          }, 3000)
        }
      }
    }
  }

    filterImages = (imageList, currentSelection) => {
      let filteredImages = [ ...imageList ]
      if (imageList && imageList.length && currentSelection) {
        filteredImages = filteredImages.map(image => {
          const shouldHide = currentSelection.findIndex(selectedImage => selectedImage.mediaId === image.id)
          if (shouldHide > -1) {
            image.shouldHide = true
          }
          return image
        })
      }
      return filteredImages
    }

    uploadImageApi = (uploadData) => {
      const { fileName, contentType, key, uploadUrl } = uploadData
      const successCallBack = () => {
        const { uploadingFiles } = this.state
        const index = uploadingFiles.findIndex(image => image.id === key)
        this.props.createMedia(key, fileName, 'IMAGE', uploadingFiles[index].tagList).then(response => {
          // message.success(`Created media ${fileName} successfully`)
          this.props.onSelectImage(this.state.imageList, response.data.createMedia.id)
          const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
          if (selectedImageIndex > -1) {
            uploadingFiles.splice(selectedImageIndex, 1)
          }
          this.setState({ uploadingFiles })
        }, error => {
          utilityService.handleError(error)
        })
      }

      const failureCallBack = () => {
        const { uploadingFiles } = this.state
        message.success(`Failed to create media ${fileName}`)
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        if (selectedImageIndex > -1) {
          uploadingFiles.splice(selectedImageIndex, 1)
        }
        this.setState({ uploadingFiles })
      }

      const progressCallBack = progress => {
        const { uploadingFiles } = this.state
        const selectedImageIndex = uploadingFiles.findIndex(image => image.id === key)
        if (selectedImageIndex > -1) {
          uploadingFiles[selectedImageIndex].percentage = progress
        }
        this.setState({ uploadingFiles })
      }
      const { uploadingFiles } = this.state
      const selectedImageIndex = uploadingFiles.findIndex(image => image.id === fileName)
      if (selectedImageIndex > -1) {
        uploadingFiles[selectedImageIndex].id = key
        uploadingFiles[selectedImageIndex].isUploading = true
        const notUploadedFile = uploadingFiles.find(item => !item.isUploading)
        if (!notUploadedFile) {
          this.props.clearFileName()
        }
        this.setState({ uploadingFiles })
        ApiService('PUT', uploadUrl, uploadingFiles[selectedImageIndex].file, { 'Content-Type': contentType }, successCallBack, failureCallBack, progressCallBack)
      }
    }

    onImageSelect = (index, e) => {
      if (this && this.state && this.state.imageList) {
        const { imageList } = this.state
        this.props.onSelectImage(imageList, !_.isEmpty(imageList) ? [...imageList].filter(image => !image.shouldHide)[ index ].id : null, e)
      }
    }

    onImageSingleClick = (index, e, id) => {
      const { imageList } = this.state
      this.props.onSelectImage(imageList, !_.isEmpty(imageList) && [...imageList].filter(image => !image.shouldHide).filter((img) => (img.id === id)) ? id : null, e, false)
    }

    onFolderSingleClick = (id, folderData) => {
      const { folderList } = this.state
      this.props.onSelectImage(folderList, !_.isEmpty(folderList) && [...folderList].filter((folder) => (folder.id === id)) ? id : null, null, true, folderData)
    }

    onFolderDoubleClick = (id) => {
      const filter = [{
        displayName: 'Tags',
        name: 'tags',
        type: 'KEYWORD',
        value: [],
        isRequired: true,
        fieldName: 'TAGS'
      }]
      this.state.folderList.filter((folder) => {
        if (folder.id === id) {
          filter[0].value = folder.tags
        }
      })
      this.setState({
        selectedFolder: id,
        imageList: []
      }, () => { this.props.onFilterChange(null, filter) })
    }

    onDoubleClick = (id) => {
      if (this.props.onDoubleClick) {
        this.props.onDoubleClick(this.state.imageList, id)
      }
    }

    uploadList = (dataList, tagList) => {
      if (this.state.selectedFolder) { // add file inside folder
        if (!tagList) {
          tagList = []
        }
        this.state.folderList.filter((folder) => {
          if (folder.id === this.state.selectedFolder) {
            folder.tags.map((tag) => {
              tagList.push(tag.key)
            })
          }
        })
      }
      const { uploadingFiles } = this.state
      this.props.updatedImageName(dataList)
      for (let i = 0; i < dataList.length; i++) {
        const contentType = dataList[i].type
        const fileName = dataList[i].name
        if (contentType && fileName) {
          const newFile = {
            fileName,
            contentType,
            percentage: 0,
            id: fileName,
            file: dataList[i],
            tagList
          }

          uploadingFiles.splice(0, 0, newFile)
        }
      }
      if (this.props.isModal) {
        this.props.displayModal(true)
      }
      this.setState({ uploadingFiles, visible: false })
    }

    showModal = () => {
      if (this.props.isModal) {
        this.props.displayModal(false)
      }
      // if (this.props.clearSearch) { this.props.clearSearch() }
      this.setState({
        visible: true
      }, () => {
        setTimeout(() => {
          this.setState({ isLocalFolderOpen: true }, () => {
            this.setState({ isLocalFolderOpen: false })
          })
        }, 100)
      })
    }

    onSearchFilter = (e) => {
      console.log(e)
    }

    handleOk = () => {
      if (this.props.isModal) {
        this.props.displayModal(true)
      }
      this.setState({ visible: false })
    }

    handleCancel = () => {
      if (this.props.isModal) {
        this.props.displayModal(true)
      }
      this.setState({ visible: false })
    }

    loadMoreMedia = () => {
      // if (this.state.imageList.length && (this.props.totalCount === this.state.imageList.length || this.state.isPaginating)) { }
      if (this.state.folderList && this.props.totalFolderCount && this.props.totalFolderCount > this.state.folderList.length) {
        if (!this.state.isPaginating && !this.props.isLoading && !this.props.isFolderLoading) {
          this.setState({ isPaginating: true }, () => {
            this.props.getMorefolders(this.state.folderList.length)
          })
        }
      } else {
        if (this.state.imageList.length && this.props.totalCount !== this.state.imageList.length && !this.state.isPaginating && !this.props.isLoading) {
          if (this.lastIndex === this.state.imageList.length) {
            return
          }
          this.lastIndex = this.state.imageList.length
          this.setState({ isPaginating: true }, () => this.props.getMoreMedia(this.state.imageList.length))
        }
      }
    }

    addFolder = () => {
      this.setState({
        addFolderModal: true
      })
    }

    onAddFolderSubmit = (input) => {
      this.setState({ isaddFolderLoading: true })
      this.props.createFolder(input).then(({ data }) => {
        this.setState({
          addFolderModal: false,
          isaddFolderLoading: false
        }, () => {
          const { folderList } = this.state
          this.props.onSelectImage(folderList, data.createFolder.id, null, true, data.createFolder)
        })
        message.success('Folder created Successfully')
      }, (error) => {
        this.setState({
          addFolderModal: false,
          isaddFolderLoading: false
        })
        utilityService.handleError(error)
      })
    }

    onAddFolderCancel = () => {
      this.setState({
        addFolderModal: false
      })
    }

    editFolderModal = (id) => {
      const folderData = this.state.folderList.filter((folder) => (folder.id === id))
      this.setState({
        editFolderModal: true,
        editFolderInfo: folderData
      })
    }

    onEditFolderSubmit = (input) => {
      input.id = this.state.editFolderInfo[0].id
      this.setState({ iseditFolderLoading: true })
      this.props.updateFolder(input).then(({ data }) => {
        this.setState({
          editFolderModal: false,
          iseditFolderLoading: false
        }, () => {
          const { folderList } = this.state
          this.props.onSelectImage(folderList, data.updateFolder.id, null, true, data.updateFolder)
          this.props.onUploadFolderUpdate()
        })
        message.success('Folder updated Successfully')
        this.props.onUploadFolderUpdate(true)
      }, (error) => {
        this.setState({
          editFolderModal: false,
          iseditFolderLoading: false,
          editFolderInfo: []
        })
        utilityService.handleError(error)
      })
    }

    onEditFolderCancel = () => {
      this.setState({
        editFolderModal: false,
        editFolderInfo: {}
      })
    }

    deleteFolderModal = (id, name) => {
      const deleteItems = [{
        id,
        name
      }]
      this.setState({
        deleteFolderModal: true,
        deleteItems
      })
    }

    ondeleteFolderCancel = () => {
      this.setState({
        deleteFolderModal: false,
        deleteItems: []
      })
    }

    ondeleteFolderSubmit = () => {
      this.setState({
        isDeleteFolderLoading: true
      })
      if (!_.isEmpty(this.state.deleteItems)) {
        let deleteIds = []
        this.state.deleteItems.map((item) => {
          deleteIds.push(item.id)
        })
        const { folderList, imageList } = this.state
        this.props.deleteFolders({ id: deleteIds }).then((success) => {
          message.success('Folder deleted Successfully')
          this.setState({
            isDeleteFolderLoading: false,
            deleteFolderModal: false,
            deleteItems: []
          }, () => {
            if (deleteIds[0] && (_.includes(this.props.selectedMedias, deleteIds[0])) && !_.isEmpty(folderList)) {
              const index = (folderList || []).findIndex(folder => folder.id === deleteIds[0])
              if (index > -1) {
                if (folderList.length > 1) {
                  const folderIndex = index ? index - 1 : 1
                  const nextFolderId = folderList[folderIndex].id
                  setTimeout(() => {
                    this.props.onSelectImage(folderList, nextFolderId, null, true, folderList[folderIndex])
                  }, 500)
                } else {
                  if (imageList.length > 1) {
                    setTimeout(() => {
                      this.props.onSelectImage(imageList, imageList[0].id)
                    }, 500)
                  }
                }
              }
            }
          })
        }, (error) => {
          this.setState({
            isDeleteFolderLoading: false,
            deleteFolderModal: false,
            deleteItems: []
          })
          utilityService.handleError(error)
        })
      } else {
        this.setState({
          isDeleteFolderLoading: false,
          deleteFolderModal: false,
          deleteItems: []
        })
      }
    }

    onSearchTags = (searchKey) => {
      this.setState({ searchTag: searchKey })
    }

    clearFilter = () => {
      this.setState({
        selectedFolder: undefined
      },
      () => {
        this.props.clearSearch()
      }
      )
    }

    updateFilter = (filter) => {
      if (this.state.selectedFolder) { // add filters inside folder
        let newFilter = []
        // this.state.folderList.filter((folder) => {
        //   if (folder.id === this.state.selectedFolder) {
        //     filter.map((item, index) => {
        //       if (item.name === 'tags') {
        //         if (filter[index].value) {
        //           const ids = new Set(filter[index].value.map(d => d.key))
        //           filter[index].value = [...filter[index].value, ...folder.tags.filter(d => !ids.has(d.key))]
        //           // filter[index].value.push(...folder.tags)
        //         } else {
        //           filter[index].value = folder.tags
        //         }
        //       }
        //     })
        //   }
        // })
        newFilter = [{
          displayName: 'Tags',
          name: 'tags',
          type: 'KEYWORD',
          value: [],
          isRequired: true,
          fieldName: 'TAGS'
        }]
        this.state.folderList.filter((folder) => {
          if (folder.id === this.state.selectedFolder) {
            newFilter[0].value = folder.tags
          }
        })
        this.props.onFilterChange(filter, newFilter)
      } else {
        this.props.onFilterChange(filter)
      }
    }

    getFolderData=(folderListRender) => {
      const { filter, module } = this.props
      if (filter) {
        const filterKeys = Object.keys(filter)
        if ((filterKeys.length && filterKeys.length === 1 && filterKeys[0] === 'isArchived' && filter['isArchived'].match === false) || (filterKeys.length && module === 'APP_MANAGER')) {
          return [...folderListRender]
        } else {
          return null
        }
      } else {
        return [...folderListRender]
      }
    }

    render () {
      const { imageList, uploadingFiles, visible, isPaginating, isCreateDisabled, isLocalFolderOpen,
        addFolderModal, deleteFolderModal, deleteItems, isDeleteFolderLoading, isaddFolderLoading,
        editFolderModal, editFolderInfo, iseditFolderLoading, selectedFolder, searchTag } = this.state
      const { folderList, isLoading, isFolderLoading, searchString, onSearchMedia, isClearFilter, mediaType, isUploadDisabled, isAddFolder, project } = this.props
      let imageListRender = null
      if ((!isLoading || isPaginating) && imageList && imageList.length) {
        imageListRender = [...imageList].filter(image => !image.shouldHide && (!mediaType || image.type === mediaType)).map((image, index) => {
          let imageUrl = defaultImage
          if (image.type === 'IMAGE') {
            imageUrl = generateCroppedThumbnail(image, 148, 84, '16:9')
          } else {
            if (image.thumbnails && image.thumbnails.length) {
              imageUrl = image.thumbnails[0]
            } else if (image.vodStatus && image.vodStatus.channel && image.vodStatus.channel.icon) {
              imageUrl = generateCroppedThumbnail(image.vodStatus.channel.icon, 148, 84, '16:9')
            }
          }
          const isSelected = this.props.selectedMedias.indexOf(image.id) > -1 // check for selected image
          // return <ImageFileDisplay key={image.id} imageIndex={index} imageSrc={imageUrl} selected={isSelected} fileName={image.name} onSelect={this.onImageSelect} onDoubleClick={() => this.onDoubleClick(image.id)} />
          return <ImageFileDisplay key={image.id} imageId={image.id} imageIndex={index} imageSrc={imageUrl} selected={isSelected} fileName={image.name} onSelect={this.onImageSingleClick} onDoubleClick={() => this.onDoubleClick(image.id)} />
        })
      }
      let folderListRender = null
      if ((!isFolderLoading) && folderList && folderList.length) {
        folderListRender = [...folderList].map((folder, index) => {
          const menuId = `id-${folder.id}-${index}`
          const key = `key-${folder.id}-${index}`
          const isSelected = this.props.selectedMedias.indexOf(folder.id) > -1
          return <div key={key}>
            <div
              onContextMenu={(e) => contextMenu.show({
                id: menuId,
                event: e
              })}
            >
              <FolderDisplay key={folder.id} folderIndex={index} selected={isSelected} fileName={folder.name} onSelect={() => this.onFolderSingleClick(folder.id, folder)} onDoubleClick={() => this.onFolderDoubleClick(folder.id)} />
            </div>
            <Menu id={menuId}>
              <Item onClick={() => { this.editFolderModal(folder.id) }} >{'Edit'}</Item>
              <Item onClick={() => { this.deleteFolderModal(folder.id, folder.name) }} >{'Delete'}</Item>
            </Menu>
          </div>
        })
      }
      const randomId = utilityService.makeRandomString(6)
      const isVideo = mediaType === 'VIDEO'
      return (
        <div className='media-list-container' onScroll={this.loadMoreMedia} id={'media-list-container_' + randomId}>
          <div className={`media-list-header`}>
            <div className='filter'>
              <FilterInput
                filterType={'ContentBank'}
                onChangeSearchInput={onSearchMedia}
                searchString={searchString}
                placement={'bottomRight'}
                changeFilter={this.updateFilter}
                isClearFilter={isClearFilter}
                parentId={'media-list-container_' + randomId}
                project={project}
              />
            </div>
            {_.isEmpty(selectedFolder) && isAddFolder ? <Button className={`add-folder-button ${isCreateDisabled ? ' disabled' : ''}`} onClick={this.addFolder} disabled={this.props.isFolderLoading}>New Folder</Button> : ''}
          </div>
          {(!_.isEmpty(this.state.selectedFolder)) ? <div className='content-bank-breadcrumbs'>
            <span className='breadcrumbs-parent' onClick={this.clearFilter}>Root Folder</span>
            <span className='breadcrumbs-division'> / </span>
            <span className='breadcrumbs-folder'>{this.state.folderList.filter(item => item.id === this.state.selectedFolder)[0].name}</span>
          </div> : ''}
          <div className={`media-list ${imageList && (imageList.length || uploadingFiles.length) && imageList.length <= 4 ? 'single-row' : ''}`} ref={(ref) => { this.scrollParentRef = ref }}>
            { folderList && imageList && <InfiniteScroll
              loadMore={this.loadMoreMedia}
              hasMore={this.props.totalCount > imageList.length || this.props.totalFolderCount > folderList.length}
              useWindow={false}
              initialLoad
            >
              {(isVideo || isUploadDisabled) ? '' : <AddUploadButton onClick={this.showModal} />}
              { (uploadingFiles || []).map((image, index) => <ImageLoadingDisplay key={index} name={image.fileName} percentage={image.percentage} />) }
              {/* checking if folder is selected or any filter applied or removed */}
              { (!isFolderLoading || isPaginating) && _.isEmpty(this.state.selectedFolder) && (_.isEmpty(this.props.filter) || this.props.filter.isArchived) && _.isEmpty(this.props.searchString) && folderList && folderList.length ? this.getFolderData(folderListRender) : null}
              { this.props.totalFolderCount <= folderList.length && (!isLoading || isPaginating) && imageList && imageList.length ? [...imageListRender] : null}
              { isLoading || isFolderLoading || isPaginating ? [...Array(7)].map((e, i) => <Skeleton key={i} active avatar={{ size: 'large', shape: 'default' }} title={false} paragraph={{ width: '100%', row: 1 }} />) : null }
              <UploadQueueModal
                visible={visible}
                isLocalFolderOpen={isLocalFolderOpen}
                handleCancel={this.handleCancel}
                uploadList={this.uploadList}
                project={project}
              />
            </InfiniteScroll>
            }
          </div>
          <EditFolderModal
            visible={addFolderModal}
            handleSubmit={this.onAddFolderSubmit}
            handleCancel={this.onAddFolderCancel}
            loading={isaddFolderLoading}
            title={'NEW FOLDER'}
            okText={'Save'}
            isSubmitDisabled={isCreateDisabled}
            searchTag={searchTag}
            onSearchTags={this.onSearchTags}
            filterType={'ContentBank'}
            contentName={'TAGS'}
            module={'CONTENT_BANK'}
            project={project}
          />
          <EditFolderModal
            visible={editFolderModal}
            editFolderInfo={editFolderInfo}
            handleSubmit={this.onEditFolderSubmit}
            handleCancel={this.onEditFolderCancel}
            loading={iseditFolderLoading}
            title={'EDIT FOLDER'}
            okText={'Save'}
            isSubmitDisabled={isCreateDisabled}
            searchTag={searchTag}
            onSearchTags={this.onSearchTags}
            filterType={'ContentBank'}
            contentName={'TAGS'}
            module={'CONTENT_BANK'}
            project={project}
          />
          <DeleteFolderModal
            isVisible={deleteFolderModal}
            deleteItems={deleteItems}
            handleSubmit={this.ondeleteFolderSubmit}
            handleCancel={this.ondeleteFolderCancel}
            isLoading={isDeleteFolderLoading}
            title={'DELETE FOLDER'}
            message1={'Are you sure you want to delete this folder?'}
            message2={'This action cannot be undone.'}
            okText={'Delete'}
          />
        </div >
      )
    }
}

MediaList.propTypes = {
  /** selected images list of AddImageContainer. */
  imageList: PropTypes.array,
  /** selected images list of AddImageContainer. */
  onSelectImage: PropTypes.func,
  /** Callback to be executed on image upload  */
  updatedImageName: PropTypes.func,
  /** Function to get the image upload URL  */
  getUploadUrl: PropTypes.func,
  /** Name of the selected file  */
  fileName: PropTypes.string,
  /** Content type of the selected file  */
  contentType: PropTypes.string,
  /** Callback to be called to create the file in the backed  */
  createImage: PropTypes.func,
  /** File object to be passed */
  file: PropTypes.object,
  /**  */
  currentSelection: PropTypes.array,
  /**  */
  disableCancelButton: PropTypes.func,
  /**  */
  clearFileName: PropTypes.func,
  /** deleted media id of medialist. */
  deletedId: PropTypes.string,
  /** Parent id for filter */
  parentId: PropTypes.string,
  /** Filter for list media */
  filter: PropTypes.object,
  /** Function to clear search value and filter while uploading a media */
  clearSearch: PropTypes.func,
  /** Boolean for clearing filter on adding of a new media */
  isClearFilter: PropTypes.bool,
  /** Media type */
  mediaType: PropTypes.string,
  /** Boolean to disable upload */
  isUploadDisabled: PropTypes.bool

}

MediaList.defaultProps = {
}

export default withApollo(compose(
  graphql(
    QueryUploadImage,
    {
      options: ({ files, project }) => {
        return {
          fetchPolicy: 'network-only',
          variables: { files, project }
        }
      },
      skip: ({ files }) => {
        return (!files || !files.length)
      },
      props: (props) => {
        const { data, ownProps } = props
        const uploadData = !data.loading && data.getUploadUrl ? data.getUploadUrl.map((item, index) => {
          return { ...item, ...ownProps.files[index] }
        }) : []
        const isUploadError = !data.loading && data.error
        if (isUploadError) {
          ownProps.clearFileName()
          utilityService.handleError(data.error)
        }
        return { uploadData: _.map(uploadData, _.clone), isUploadError }
      }
    }
  ),
  graphql(
    QueryFilterMedia,
    {
      options: ({ searchString, filter, shouldCacheLoad, mediaType, project }) => {
        const variables = utilityService.getFormattedMediaFilter(searchString, filter, mediaType, project)
        return {
          fetchPolicy: shouldCacheLoad ? 'cache-first' : 'network-only',
          variables
        }
      },
      props: ({ data, ownProps }) => {
        const project = ownProps.project
        return {
          imageList: data && data.listMedia && data.listMedia.items ? _.map(data.listMedia.items, _.clone) : [],
          isLoading: data.loading,
          totalCount: data && data.listMedia && data.listMedia.totalCount ? data.listMedia.totalCount : null,
          getMoreMedia: (offset) => {
            return data.fetchMore({
              variables: {
                offset: offset,
                limit: 15,
                project
              },
              updateQuery: (previous, current) => {
                let { fetchMoreResult } = current
                const prev = cloneDeep(previous)
                if ((!prev.listMedia && offset === 0) || (prev.listMedia.items.length === offset)) {
                  if (!fetchMoreResult) return prev
                  if (!prev.listMedia) return { listMedia: { items: [], totalCount: 0, __typename: 'MediaConnection' } }
                  let newList = [ ...fetchMoreResult.listMedia.items ]
                  if (prev.listMedia && prev.listMedia.items && prev.listMedia.items.length) {
                    newList = [...prev.listMedia.items, ...newList]
                  }
                  prev.listMedia.items = _.uniqBy(newList, 'id')
                  prev.listMedia.offset = offset // had a dependency on developed system. Remove if needed
                  return prev
                } else {
                  return prev
                }
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationCreateMedia,
    {
      options: ({ searchString, filter, mediaType, project }) => {
        return { update: (cache, { data: { createMedia } }) => {
          const variables = utilityService.getFormattedMediaFilter(searchString, filter, mediaType, project)
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryFilterMedia, variables }))
          if (cacheData && cacheData.listMedia && cacheData.listMedia.items) { cacheData.listMedia.items.splice(0, 0, createMedia) }
          cache.writeQuery({
            query: QueryFilterMedia,
            data: cacheData,
            variables
          })
        }
        }
      },
      props: (props) => ({
        createMedia: (key, name, type, tags) => {
          return props.mutate({
            variables: { key, name, type, tags, project: props.ownProps.project }
          })
        }
      })
    }
  ),
  graphql(
    QueryListFolders,
    {
      options: ({ shouldCacheLoad, project }) => {
        const variables = { limit: 20, offset: 0, orderBy: 'name', order: 'asc', project }
        return {
          fetchPolicy: shouldCacheLoad ? 'cache-first' : 'network-only',
          variables
        }
      },
      props: ({ data, ownProps }) => {
        const project = ownProps.project
        return {
          folderList: data && data.listFolders && data.listFolders.items ? _.map(data.listFolders.items, _.clone) : undefined,
          isFolderLoading: data.loading,
          totalFolderCount: data.listFolders ? data.listFolders.totalCount : 0,
          getMorefolders: (offset) => {
            return data.fetchMore({
              variables: {
                limit: 20,
                offset: offset,
                orderBy: 'name',
                order: 'asc',
                project
              },
              updateQuery: (previous, current) => {
                let { fetchMoreResult } = current
                if (!fetchMoreResult) return previous
                const prev = cloneDeep(previous)
                if (!prev.listFolders) return { listFolders: { items: [] } }
                let newList = [ ...fetchMoreResult.listFolders.items ]
                if (prev.listFolders && prev.listFolders.items && prev.listFolders.items.length) {
                  newList = [...prev.listFolders.items, ...newList]
                }
                prev.listFolders.items = _.uniqBy(newList, 'id')
                prev.listFolders.totalFolderCount = fetchMoreResult.listFolders.totalCount
                prev.listFolders.offset = offset // had a dependency on developed system. Remove if needed
                return prev
              }
            })
          }
        }
      }
    }
  ),
  graphql(
    MutationAddFolder,
    {
      options: ({ project }) => {
        return { update: (cache, { data: { createFolder } }) => {
          const variables = { limit: 20, offset: 0, orderBy: 'name', order: 'asc', project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListFolders, variables }))

          if (cacheData && cacheData.listFolders && cacheData.listFolders.items) {
            cacheData.listFolders.items.splice(0, 0, createFolder)
            cacheData.listFolders.totalCount += 1
            cacheData.listFolders.offset = 0 // had a dependency on developed system. Remove if needed
          }
          cache.writeQuery({
            query: QueryListFolders,
            data: cacheData,
            variables
          })
        }
        }
      },
      props: (props) => ({
        createFolder: (input) => {
          let variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationDeleteFolders,
    {
      options: ({ project }) => {
        return { update: (cache, { data: { deleteFolders } }) => {
          const variables = { limit: 20, offset: 0, orderBy: 'name', order: 'asc', project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListFolders, variables }))
          if (cacheData && cacheData.listFolders && cacheData.listFolders.items) {
            deleteFolders.forEach(id => {
              const index = cacheData.listFolders.items.findIndex((item) => item.id === id)
              cacheData.listFolders.items.splice(index, 1)
              cacheData.listFolders.totalCount -= 1
              cacheData.listFolders.offset = 0 // had a dependency on developed system. Remove if needed
            })
          }
          cache.writeQuery({
            query: QueryListFolders,
            data: cacheData,
            variables
          })
        }
        }
      },
      props: (props) => ({
        deleteFolders: (input) => {
          const variables = input
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  ),
  graphql(
    MutationUpdateFolder,
    {
      options: ({ project }) => {
        return { update: (cache, { data: { updateFolder } }) => {
          const variables = { limit: 20, offset: 0, orderBy: 'name', order: 'asc', project }
          const cacheData = _.cloneDeep(cache.readQuery({ query: QueryListFolders, variables }))
          if (cacheData && cacheData.listFolders && cacheData.listFolders.items) {
            const index = cacheData.listFolders.items.findIndex((item) => item.id === updateFolder.id)
            cacheData.listFolders.items.splice(index, 1, updateFolder)
            cacheData.listFolders.offset = 0 // had a dependency on developed system. Remove if needed
          }
          cache.writeQuery({
            query: QueryListFolders,
            data: cacheData,
            variables
          })
        }
        }
      },
      props: (props) => ({
        updateFolder: (variables) => {
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }
  // ),
  // graphql(
  //   QueryTags,
  //   {
  //     options: (props) => {
  //       return {
  //         fetchPolicy: 'network-only'
  //       }
  //     },
  //     props: ({ data }) => {
  //       let groupList = data.listTags ? data.listTags.items : []
  //       return {
  //         groupList,
  //         isLoading: data.loading || !data.listTags,
  //         totalCount: data.listTags ? data.listTags.totalCount : 0,
  //         getMoreTags: (page) => {
  //           return data.fetchMore({
  //             fetchPolicy: 'network-only',
  //             variables: {
  //               offset: page
  //             },
  //             updateQuery: (prev, { fetchMoreResult }) => {
  //               if (!fetchMoreResult) return prev
  //               const newList = [...prev.listTags.items, ...fetchMoreResult.listTags.items]
  //               prev.listTags.items = newList
  //               return prev
  //             }
  //           })
  //         }
  //       }
  //     }
  //   }
  )

)(withRouter(MediaList)))
