import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import AddObjectContainer from './AddObjectContainer'
import LoadingButton from '../../general/buttons/LoadingButton'

class AddObjectModal
  extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  resetState = () => {
    this.setState({

    })
  }

  getTitle =(isWidget, isWidgetEdit, isUpdateBlocked) => {
    if (isWidget) {
      if (isUpdateBlocked) {
        return 'View Widget'
      } else if (isWidgetEdit) {
        return 'Edit Widget'
      } else {
        return 'Add Widget'
      }
    } else {
      if (isUpdateBlocked) {
        return 'View Object'
      } else {
        return 'Add Object'
      }
    }
  }

  render () {
    const { handleCancel, isVisible, fieldTypeList, objectDetails, showEditIcon, showAddBucketModal, showImgEditModal, handleObjectDetailsChange,
      project, handleShowAddImageModal, hideAddImageModal, saveCurrentImageSelection, showAddImageModal,
      handleShowBucketModal, hideAddBucketModal, handleBucketSelection, selectedBucket, saveCurrentBucketSelection,
      hideImgEditor, showImgEditorIcon, hideImgEditorIcon, showImgEditor, details, handleConfigObjectSave, isConfigObjectSaveLoading,
      isConfigObjectSaveDisable, selectedBucketConfig, selectedImgConfig, selectedImgEditConfig, editImageDetails, isWidget,
      isWidgetEdit, isJsonArrayValid, jsonArrayInvalidEntries, jsonInvalidEntries, isJsonArrayVisible, jsonConfig, jsonSelectedMeta, handleJsonArrayVisibilty,
      handleJsonArrayHide, isUpdateBlocked } = this.props
    return (
      <div>
        <Modal
          className='confirm-modal edit-object-config'
          title={this.getTitle(isWidget, isWidgetEdit, isUpdateBlocked)}
          visible={isVisible}
          closable={false}
          confirmLoading={false}
          destroyOnClose
          maskClosable={false}
          centered
          width='890px'
          footer={<div className='modal-footer'>
            <p>
              {!isConfigObjectSaveDisable && isWidget ? `Please ensure you save the page layout after adding the widget. Unsaved changes in layouts would be lost if you navigate out of the page editor.`
                : ''} </p>
            <div className='right-btn'>
              <Button key='back' onClick={() => handleCancel(true, isWidgetEdit)}> Cancel </Button>
              <LoadingButton type='primary' onClick={handleConfigObjectSave} htmlType='submit' buttonText={isWidget ? isWidgetEdit ? 'SAVE' : 'ADD' : 'Save'} buttonClass='save-btn' isLoading={isConfigObjectSaveLoading} isDisabled={isConfigObjectSaveDisable} />
            </div>
          </div>}
        >
          <AddObjectContainer
            handleObjectDetailsChange={handleObjectDetailsChange}
            fieldTypeList={fieldTypeList}
            objectDetails={objectDetails}
            project={project}
            handleShowAddImageModal={handleShowAddImageModal}
            hideAddImageModal={hideAddImageModal}
            saveCurrentImageSelection={saveCurrentImageSelection}
            showImgEditorIcon={showImgEditorIcon}
            hideImgEditorIcon={hideImgEditorIcon}
            showAddImageModal={showAddImageModal}
            showEditIcon={showEditIcon}
            handleShowBucketModal={handleShowBucketModal}
            showAddBucketModal={showAddBucketModal}
            hideAddBucketModal={hideAddBucketModal}
            handleBucketSelection={handleBucketSelection}
            selectedBucket={selectedBucket}
            saveCurrentBucketSelection={saveCurrentBucketSelection}
            showImgEditor={showImgEditor}
            hideImgEditor={hideImgEditor}
            showImgEditModal={showImgEditModal}
            details={details}
            selectedBucketConfig={selectedBucketConfig}
            selectedImgConfig={selectedImgConfig}
            selectedImgEditConfig={selectedImgEditConfig}
            editImageDetails={editImageDetails}
            isWidget={isWidget}
            isWidgetEdit={isWidgetEdit}
            isJsonArrayValid={isJsonArrayValid}
            jsonInvalidEntries={jsonInvalidEntries}
            jsonArrayInvalidEntries={jsonArrayInvalidEntries}
            isJsonArrayVisible={isJsonArrayVisible}
            jsonConfig={jsonConfig}
            jsonSelectedMeta={jsonSelectedMeta}
            handleJsonArrayVisibilty={handleJsonArrayVisibilty}
            handleJsonArrayHide={handleJsonArrayHide}
            isUpdateBlocked={isUpdateBlocked}
          />
        </Modal>

      </div>
    )
  }
}

AddObjectModal.propTypes = {

}

AddObjectModal.defaultProps = {}

export default AddObjectModal
