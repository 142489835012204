import React from 'react'
import PropTypes from 'prop-types'

const MultipleSelectIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill='white' fillRule='evenodd'>
      <rect width='14' height='14' y='6' rx='.933' />
      <path d='M1.941 3H16a1 1 0 0 1 1 1v14.059a.941.941 0 0 1-1.882 0V5.882a1 1 0 0 0-1-1H1.94a.941.941 0 0 1 0-1.882z' />
      <path d='M4 0h15a1 1 0 0 1 1 1v15a1 1 0 0 1-2 0V3a1 1 0 0 0-1-1H4a1 1 0 1 1 0-2z' />
    </g>
  </svg>
)

MultipleSelectIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

MultipleSelectIcon.defaultProps = {
  width: 20,
  height: 20
}

export default MultipleSelectIcon
