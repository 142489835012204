import gql from 'graphql-tag'

export default gql(`
    mutation adminCreateUser( $email: String!, $password: String!, $details: UserDetailInput) {
        adminCreateUser(
            email: $email
            password: $password
            details: $details
        ) {
            success{
                message
            }
            error{
                message
            }
        }
    }`
)
