import gql from 'graphql-tag'

export default gql(`
    mutation($deletedId: [ID]! $assetId: ID!) {
        deleteAssetVideos(
            input:  $deletedId,
            assetId: $assetId
        )
    }`
)
