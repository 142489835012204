const userMessages = {
  // Auth Messages
  LOGIN_EMAIL_PASSWORD_VALIDATION_FAILURE: 'Please enter valid credentials',
  LOGIN_PASSWORD_VALIDATION_FAILURE: 'Please enter a valid password',
  LOGIN_EMAIL_VALIDATION_FAILURE: 'Please enter a valid email',
  LOGIN_FORCE_RESET: 'Please set your password ',
  AUTH_EMPTY_FIELD: 'Please enter the missing field',
  AUTH_EMPTY_FIELDS: 'Please enter the missing fields',
  AUTH_PASSWORD_MISMATCH: 'Passwords does not match',
  AUTH_PASSWORD_VALIDATION: 'Password need to include both upper and lowercase characters, atleast one number and a special character and be a minimum of 12 characters',
  FORGOT_PASSWORD_EMAIL_VALIDATAION: 'Please enter a valid email',
  FORGOT_PASSWORD_EMAIL_EMPTY: 'Please enter your email',
  FORGOT_VERIFICATION_CODE_SUCCESS: 'If your account exists on the application, a password reset link will be sent to the relevant registered email address',
  AUTH_PASSWORD_SET_SUCCESS: 'Password reset successfully',
  LOGIN_FAILURE: 'Login Failed',

  // Asset Messages
  INVALID_PUBLISH_DATE: 'Publishing dates are invalid',
  EMPTY_TITLE: 'Asset must have a title',
  EMPTY_SLUG: 'Slug must have minimum three character length',
  ASSET_PUBLISH_SUCCESS: 'Asset published successfully',
  ASSET_UNPUBLISH_SUCCESS: 'Asset unpublished successfully',
  ASSET_UPDATE_SUCCESS: 'Asset updated successfully',
  ASSET_DELETE_CONFIRMATION: 'Are you sure you want to archive ?', // Popup confirmation
  ASSET_RESTORE_CONFIRMATION: 'Are you sure you want to restore ?', // Popup confirmation
  ASSET_DUPLICATE_CONFIRMATION: 'Are you sure you want to duplicate the asset ?', // Popup confirmation
  UNSAVED_ASSET_WARNING: 'Changes made by you will be discarded. Do you wish to continue ?', // Popup confirmation
  ARCHIVE_ASSET_WARNING: 'Are you sure you want to archive the selected assets ?',
  ASSET_CREATE_SUCCESS: 'Asset created successfully',
  ASSET_CREATE_ERROR: 'Something went wrong please try again later',
  INVALID_ASSET: 'Asset not found',
  DUPLICATE_SLUG: 'URL slug is already in use. Please use a different slug',
  INVALID_SLUG: 'URL slug is not valid',
  ASSET_DUPLICATE_SUCCESS: 'Asset duplicated successfully',
  ASSET_LINK_COPIED: 'Asset Preview URL copied to clipboard',
  PROGRAM_ADDED_SUCCESS: 'Program added to asset successfully',
  PROGRAM_DELETE_SUCCESS: 'Program deleted from asset successfully',
  PROGRAM_UPDATE_SUCCESS: 'Program updated successfully',
  ASSET_CONTENT_DELETE_CONFIRMATION: 'Are you sure you want to delete?', // pop over confirmation
  ASSET_LINKED_MATCH_WARNING: 'Changing linked match will overwrite program data. Do you want to continue ?',
  ASSET_LINKED_SEAONS_WARNING: 'Ensure the episode metadata is as per the selected season. Proceed to update the season? ',
  ASSET_LINKED_SERIES_WARNING: 'Ensure the season metadata is as per the selected series. Proceed to update the series? ',
  CURRENT_ASSET_ADDED: 'You are working in current asset',
  ALREADY_ADDED_VIDEO: 'Video already added to this asset',
  EXPORT_CSV_ASSET_WARNING: 'Are you sure you want to export csv for the selected assets ?',
  UPDATE_CATEGORY_ASSET_IMAGE_WARNING: 'Are you sure you want to update category for the selected asset image?',
  APPLE_NEWS_URL_FAILED: 'Apple News Preview URL Generation Failed',
  APPLE_NEWS_URL_SUCCESS: 'Apple News Preview URL Successfully Generated',

  // VOD Messages
  VOD_TRIGGER_SUCCESS: 'Successfully Triggered VOD Asset',
  VOD_COMPLETE_SUCCESS: 'LIVE to VOD conversion completed',
  INVALID_MARK_TIME: 'Invalid MarkIn MarkOut',
  INCOMPLETE_MARKOUT: 'Please select the completed portion to create VOD',
  INVALID_CHANNEL_CONFIG: 'Channel config is invalid. Please check the config!',
  INVALID_DRM_CONFIG: 'Video cannot be played. Please check the DRM Playback config!',

  // Channel Messages
  LINK_COPIED: 'Stream URL copied to clipboard',
  INVALID_URL: 'Please enter a valid URL',

  // Filter messages
  FILTER_ADDED_SUCCESS: 'New Filter has been added',
  FILTER_SAVED: 'Filter saved successfully',

  // Content Messages
  IMAGE_LINK_COPIED: 'Image URL copied to clipboard',
  CONTENT_DELETE_CONFIRMATION: 'Are you sure you want to archive?', // pop over confirmation
  CONTENT_RESTORE_CONFIRMATION: 'Are you sure you want to restore?', // pop over confirmation
  FILTER_UPDATE_SUCCESS: 'Filter updated successfully',

  // Common Messages
  AUTHOR_EMPTY_IMAGE: 'Please select an image to save author',
  DUPLICATE_AUTHOR_NAME: 'Author with same name exists',

  // Bucket Messages
  BUCKET_UPDATED_SUCCESS: 'Bucket updated successfully',
  BUCKET_GROUP_DELETE_CONFIRMATION: 'Are you sure you want to archive this bucket group ?',
  ASSET_ADDED_ALREADY: 'Asset already added',
  EMPTY_BUCKET_NAME: 'Bucket name cannot be empty',
  EMPTY_BUCKET_DISPLAY_NAME: 'Bucket display name cannot be empty',
  EMPTY_BUCKET_DISPLAY_TYPE: 'Bucket display type cannot be empty',
  SUCCESS_ADD_BUCKET_IN_BUCKET_GROUP: 'Successfully added Bucket to bucket group',
  SUCCESS_ADD_BUCKET_GROUP: 'Created Bucket Group Successfully',
  SUCCESS_DELETE_BUCKET_FROM_BUCKET_GROUP: 'Deleted Bucket from bucket group',
  SUCCESS_UPDATE_BUCKET_GROUP: 'Successfully Updated Bucket Group',
  SUCCESS_BUCKET_CREATE: 'Created Bucket successfully',
  BUCKET_ALREADY_UPDATED: 'This bucket has been modified by another user. Please reload to get the latest updates',

  // Player Messages

  // Admin Messages
  RESYNC_COMPETITION: 'This action will re-sync all the matches of this competition from Last Sync Frequency. Do you want to proceed?',
  RESET_COMPETITION: 'This action will reset the re-sync of this competition. Do you want to proceed?',
  RESYNC_COMPETITION_ERROR: 'Sorry, resync is not possible during Sync. Try after Sync complete.',
  EDIT_COMPETITION_ERROR: 'Sorry, editing is not possible during Sync. Try after Sync complete.',
  DELETE_MULTIPLE_CONTENT_PLACEHOLDER: 'Are you sure you want to delete Multiple Content Placeholder? This action cannot be undone.',
  DELETE_MULTIPLE_TAGS: 'Are you sure you want to delete Multiple Tags? This action cannot be undone.',
  DELETE_MULTIPLE_DISPLAY_TYPE: 'Are you sure you want to delete Multiple Display Type? This action cannot be undone.',
  DELETE_MULTIPLE_CHANNEL_STREAMS: 'Are you sure you want to delete Multiple Channel Streams? This action cannot be undone.',
  DELETE_MULTIPLE_CONFIG_GROUP: 'Are you sure you want to delete Multiple Config Group? This action cannot be undone.',
  BUCKET_GROUP_POSITION_SUCCESS: 'Successfully updated bucket group position',

  // Offer Messages
  OFFER_CREATE_SUCCESS: 'Offer created successfully',
  OFFER_UPDATE_SUCCESS: 'Offer updated successfully',
  PRICE_CREATE_SUCCESS: 'Price created successfully',
  PRICE_UPDATE_SUCCESS: 'Price Updated successfully',
  PRICE_DELETE_SUCCESS: 'Price Deleted successfully',
  OFFER_DELETE_CONFIRMATION: 'Are you sure you want to archive ?', // Popup confirmation
  OFFER_RESTORE_CONFIRMATION: 'Are you sure you want to restore ?', // Popup confirmation

  // Partner Messages
  PARTNER_CREATE_SUCCESS: 'Partner created successfully',
  PARTNER_UPDATE_SUCCESS: 'Partner updated successfully',

  // App Manager
  WIDGET_ADDED_ALREADY: 'Widget already added',
  APPLE_NEWS_SECTION_MAPPING: 'Updated Apple News Section Mapping',
  APPLE_NEWS_SECTION_MAPPING_UNLINK: 'Unlinked Bucket From Apple News Section',

  // Customer manager
  ADMIN_ATRIBUTE_ACTIVATE: 'Admin Attribute Successfully Activated.',
  ADMIN_ATRIBUTE_DISABLED: 'Admin Attribute Successfully Disabled.'

}
export default userMessages
