import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

import './../../ui.style.scss'
import { utilityService } from '../../../../services/UtilityService'

const Option = Select.Option

const LanguageDropDown = ({ onOptionSelect, displayParam, keyParam, options, placeHolder, className, allowClear, defaultValue, parent, value, ...props }) => {
  return (
    <Select
      className={className ? 'multi-drop-down ' + className : 'multi-drop-down'}
      mode='multiple'
      allowClear={allowClear}
      value={value}
      style={{ width: '100%' }}
      placeholder={placeHolder}
      getPopupContainer={() => document.getElementById(parent)}
      filterOption={(input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={onOptionSelect}
      optionLabelProp='label'
      {...props}
    >
      {(options || []).filter(item => !(value && value.length && value.includes(item.name))).map((option, index) => {
        return <Option label={option[displayParam]} key={option.id} value={option[keyParam]}>{option[displayParam]} <span> {utilityService.getSourceDetails(option['source'])}</span> </Option>
      })}
    </Select>
  )
}

LanguageDropDown.propTypes = {
  /** Options of Dropdown. */
  options: PropTypes.array.isRequired,
  /** Option selection action of Dropdown. */
  onOptionSelect: PropTypes.func,
  /** Placeholder text of Dropdown. */
  placeHolder: PropTypes.string,
  /** Class name */
  className: PropTypes.string,
  defaultValue: PropTypes.array
}

LanguageDropDown.defaultProps = {
  placeHolder: 'Select'
}

export default LanguageDropDown
