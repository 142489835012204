import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { generateCroppedThumbnail } from '../../../util/util'
import ChannelDetailCell from '../dataDisplay/ChannelDetailCell'
// import DropDown from './../dataEntry/inputs/DropDown'
import './../ui.style.scss'

// const options = [{
//   name: 'Assignee 1',
//   address: 'Address1'
// }, {
//   name: 'Assignee 2',
//   address: 'Address2'
// }]

class MatchGeneralDetails extends React.Component {
  render () {
    const { match, program } = this.props
    return (
      match
        ? <div key={match.id} className={`match-general-details ${program.channel ? 'with-channel' : ''}`}>
          <div className='match-details-top'>
            <span>{ match.matchDate ? moment(new Date(match.matchDate)).format('ddd,DD MMM,YYYY') : ''}</span>
            <span> - </span>
            <span>{match.competition && match.competition.title ? match.competition.title.toUpperCase() : ''}</span>
          </div>
          <div className='match-details-middle'>
            <div className='match-icons'>
              <img src={match.homeTeam && match.homeTeam.media && match.homeTeam.media.length && match.homeTeam.media[ 0 ] ? generateCroppedThumbnail(match.homeTeam.media[0], 200, 200, '1:1') : require('./../../../assets/images/avatar-team-icon.png')} />
              <p>{ match.homeTeam && match.homeTeam.officialName ? match.homeTeam.officialName.toUpperCase() : '' }</p>
            </div>

            <div className='match-details-center'>
              <div className='match-data'>
                <p>Start Broadcast</p>
                <p>{program && program.broadCastStartTime ? moment(new Date(program.broadCastStartTime)).format('HH:mm') : '-'}</p>
              </div>
              <div className='match-data'>
                <p>Stop Broadcast</p>
                <p>{program && program.broadCastEndTime ? moment(new Date(program.broadCastEndTime)).format('HH:mm') : '-'}</p>
              </div>
              <div className='match-data'>
                <p>Match Kickoff</p>
                <p>{match.kickOffTime ? moment(new Date(match.kickOffTime)).format(' HH:mm') : match.matchDate ? moment(new Date(match.matchDate)).format(' HH:mm') : '-'}</p>
              </div>
              <div className='match-data'>
                <p>Match Finished</p>
                <p>{match.finishTime ? moment(new Date(match.finishTime)).format(' HH:mm') : '-'}</p>
              </div>
            </div>

            <div className='match-icons'>
              <img src={match.awayTeam && match.awayTeam.media && match.awayTeam.media.length && match.awayTeam.media[ 0 ] ? generateCroppedThumbnail(match.awayTeam.media[0], 200, 200, '1:1') : require('./../../../assets/images/avatar-team-icon.png')} />
              <p>{match.awayTeam && match.awayTeam.officialName ? match.awayTeam.officialName.toUpperCase() : ''}</p>
            </div>
          </div>

          {/* <div className='match-details-bottom'>
            <p>Assignee: </p>
            <DropDown options={options} displayParam='name' />
          </div> */}

          {program && program.channel ? <ChannelDetailCell
            startTime={program.broadCastStartTime}
            match={match}
            endTime={program.broadCastEndTime}
            channel={program.channel ? program.channel : null}
            imageUrl={program.channel && program.channel.icon ? program.channel.icon : null}
          /> : null }
        </div>
        : null
    )
  }
}

MatchGeneralDetails.propTypes = {
  /** MatchDetails of MatchGeneralDetails. */
  MatchDetails: PropTypes.array
}

MatchGeneralDetails.defaultProps = {
}

export default MatchGeneralDetails
