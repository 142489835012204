import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, message, Switch } from 'antd'
import CreateAppPageContainer from './CreateAppPageContainer'
import Input from './../inputs/Input'
import InputNumber from '../inputs/InputNumber'
import { utilityService } from '../../../../services/UtilityService'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import QueryAllTags from '../../../../graphQL/asset/getTags'
import MutationCreateTag from '../../../../graphQL/asset/createTag'
import QueryTagType from './../../../../graphQL/admin/tagType/listTagType'
import MutationCreatePage from './../../../../graphQL/appManager/createAppPage'
import MutationUpdatePage from './../../../../graphQL/appManager/editAppPage'
import QueryGetAppPage from './../../../../graphQL/appManager/getAppPage'
import QueryGetAppPageList from './../../../../graphQL/appManager/listAppPages'
import MutationDuplicateAppPage from '../../../../graphQL/appManager/duplicateAppPage'
import QueryUserList from './../../../../graphQL/role/listUsers'
import QueryCriticalUserList from './../../../../graphQL/appManager/listCriticalUsers'

import './../../ui.style.scss'

class CreatePageModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSaving: false,
      // settings: [],
      isEditVisible: false,
      isCropVisible: false,
      selectedTags: [],
      pageTitle: '',
      shortDescription: '',
      app: '',
      key: '',
      group: '',
      slug: '',
      seoTitle: '',
      seoMetaDescription: '',
      seoKeywords: [],
      itemSelected: {},
      isEnable: false,
      isTagCreating: false,
      systemTags: [],
      showWarning: false,
      shouldSave: false,
      // customMeta: props.details && !_.isEmpty(props.details.customMeta) ? props.details.customMeta : [],
      customMeta: [],
      isCritical: false,
      isCriticalChanged: false,
      appData: null,
      groupData: null,
      assignedUsers: [],
      isAssigneeChanged: false,
      userSearch: ''
    }
  }

  resetState = () => {
    this.setState({
      settings: [],
      customMeta: [],
      isSaving: false,
      isEditVisible: false,
      isCropVisible: false,
      selectedTags: [],
      pageTitle: '',
      shortDescription: '',
      app: '',
      group: '',
      slug: '',
      key: '',
      seoTitle: '',
      seoMetaDescription: '',
      seoKeywords: [],
      isEnable: false,
      isTagCreating: false,
      showWarning: false,
      shouldSave: false,
      ignoreWarning: false,
      isCritical: false,
      isCriticalChanged: false,
      appData: null,
      groupData: null,
      assignedUsers: [],
      isAssigneeChanged: false,
      userSearch: ''
    })
  }

  componentDidMount = () => {
    // console.log('mounted')
    if (this.props.details) {
      const selectedTags = this.props.details.tags ? this.props.details.tags.filter(item => item) : []
      this.setState({
        isEditVisible: this.props.isVisible,
        pageTitle: _.get(this.props.details, 'title'),
        app: this.props.details && this.props.details.app ? this.props.details.app.id : '',
        group: this.props.details && this.props.details.group ? this.props.details.group.key : '',
        key: this.props.details ? this.props.details.key : '',
        shortDescription: this.props.details && this.props.details.shortDescription ? this.props.details.shortDescription : '',
        selectedTags: selectedTags,
        slug: (this.props.details && this.props.details.slug) || '',
        seoTitle: this.props.details && this.props.details.seoTitle ? this.props.details.seoTitle : '',
        seoMetaDescription: this.props.details && this.props.details.seoMetaDescription ? this.props.details.seoMetaDescription : '',
        seoKeywords: this.props.details && this.props.details.seoKeywords ? this.props.details.seoKeywords : [],
        customMeta: this.props.details && this.props.details.customMeta && this.props.details.customMeta.length > 0 ? this.props.details.customMeta : [],
        assignedUsers: (this.props.details && this.props.details.assignedUsers) || []
      })
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => { // eslint-disable-line camelcase
    if (!_.isEmpty(nextProps.details) && !this.state.isSaving && !_.isEqual(nextProps.details, this.props.details)) {
      const selectedTags = nextProps.details.tags ? nextProps.details.tags.filter(item => item) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        pageTitle: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'title') : _.get(nextProps.details, 'title'),
        key: nextProps.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'title')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && nextProps.details.app ? nextProps.details.app.id : '',
        group: nextProps.isDuplicating ? '' : nextProps.details && nextProps.details.group ? nextProps.details.group.key : '',
        shortDescription: nextProps.details && nextProps.details.shortDescription ? nextProps.details.shortDescription : '',
        selectedTags: selectedTags,
        selectedType: nextProps.details.type ? nextProps.details.type : '',
        slug: (nextProps.details && nextProps.details.slug) || '',
        seoTitle: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'seoTitle') : nextProps.details && nextProps.details.seoTitle ? nextProps.details.seoTitle : '',
        seoMetaDescription: nextProps.details && nextProps.details.seoMetaDescription ? nextProps.details.seoMetaDescription : '',
        seoKeywords: nextProps.details && nextProps.details.seoKeywords ? nextProps.details.seoKeywords : [],
        isCritical: nextProps.details && nextProps.details.isCritical ? nextProps.details.isCritical : false,
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || [],
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : []
      }, () => {
        if (nextProps.pageMetaFieldList && nextProps.pageMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.pageMetaFieldList, this.state.customMeta)
        }
      })
    }
    if (!_.isEmpty(nextProps.details) && !this.state.isSaving && !this.state.pageTitle) {
      const selectedTags = nextProps.details.tags ? nextProps.details.tags.filter(item => item) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        pageTitle: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'title') : _.get(nextProps.details, 'title'),
        key: nextProps.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'title')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && nextProps.details.app ? nextProps.details.app.id : '',
        group: nextProps.isDuplicating ? '' : nextProps.details && nextProps.details.group ? nextProps.details.group.key : '',
        shortDescription: nextProps.details && nextProps.details.shortDescription ? nextProps.details.shortDescription : '',
        selectedTags: selectedTags,
        selectedType: nextProps.details.type ? nextProps.details.type : '',
        slug: (nextProps.details && nextProps.details.slug) || '',
        seoTitle: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'seoTitle') : nextProps.details && nextProps.details.seoTitle ? nextProps.details.seoTitle : '',
        seoMetaDescription: nextProps.details && nextProps.details.seoMetaDescription ? nextProps.details.seoMetaDescription : '',
        seoKeywords: nextProps.details && nextProps.details.seoKeywords ? nextProps.details.seoKeywords : [],
        isCritical: nextProps.details && nextProps.details.isCritical ? nextProps.details.isCritical : false,
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || [],
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : []
      }, () => {
        if (nextProps.pageMetaFieldList && nextProps.pageMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.pageMetaFieldList, this.state.customMeta)
        }
      })
    }
    // check for duplicating page
    if (!_.isEmpty(nextProps.details) && !this.state.isSaving && nextProps.isDuplicating) {
      const selectedTags = nextProps.details.tags ? nextProps.details.tags.filter(item => item) : []
      // const selectedAspectRatio = this.state.selectedAspectRatio ? this.state.selectedAspectRatio : _.get(nextProps.mediaDetails, 'aspectRatio', []).length ? _.get(nextProps.mediaDetails, 'aspectRatio', [])[0] : null
      this.setState({
        isEditVisible: nextProps.isVisible,
        pageTitle: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'title') : _.get(nextProps.details, 'title'),
        key: nextProps.isDuplicating ? utilityService.slugifyString('Copy Of ' + _.get(nextProps.details, 'title')).slice(0, 125).toLocaleUpperCase() : nextProps.details ? nextProps.details.key : '',
        app: nextProps.details && nextProps.details.app ? nextProps.details.app.id : '',
        group: nextProps.isDuplicating ? '' : nextProps.details && nextProps.details.group ? nextProps.details.group.key : '',
        shortDescription: nextProps.details && nextProps.details.shortDescription ? nextProps.details.shortDescription : '',
        selectedTags: selectedTags,
        selectedType: nextProps.details.type ? nextProps.details.type : '',
        slug: (nextProps.details && nextProps.details.slug) || '',
        seoTitle: nextProps.isDuplicating ? 'Copy Of ' + _.get(nextProps.details, 'seoTitle') : nextProps.details && nextProps.details.seoTitle ? nextProps.details.seoTitle : '',
        seoMetaDescription: nextProps.details && nextProps.details.seoMetaDescription ? nextProps.details.seoMetaDescription : '',
        seoKeywords: nextProps.details && nextProps.details.seoKeywords ? nextProps.details.seoKeywords : [],
        isCritical: nextProps.details && nextProps.details.isCritical ? nextProps.details.isCritical : false,
        assignedUsers: (nextProps.details && nextProps.details.assignedUsers) || [],
        customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : []
      }, () => {
        if (nextProps.pageMetaFieldList && nextProps.pageMetaFieldList.length > 0) {
          this.checkForNewMeta(this.props.pageMetaFieldList, this.state.customMeta)
        }
      })
    }
    if (nextProps.pageMetaFieldList && nextProps.pageMetaFieldList.length > 0 && _.isEmpty(nextProps.details) && nextProps.isFromEdit === undefined && !(this.state.customMeta && this.state.customMeta.length)) {
      this.checkForNewMeta(this.props.pageMetaFieldList, this.state.customMeta)
    }
    if (nextProps.systemTags) {
      // const enabledTags = (nextProps.systemTags || []).filter(tag => tag.isMediaEnabled === true)
      this.setState({ systemTags: nextProps.systemTags })
    }
    if (nextProps.details && this.props.details && !_.isEqual(nextProps.details.customMeta, this.props.details.customMeta) && !nextProps.isVisible) {
      this.setState({ customMeta: nextProps.details && nextProps.details.customMeta && nextProps.details.customMeta.length > 0 ? nextProps.details.customMeta : [] }, () => {
      })
    }
    this.setState({ isEditVisible: nextProps.isVisible })
  };

  checkForNewMeta=(metaFieldList, customMeta) => {
    let changeExists = false;
    (metaFieldList || []).map(item => {
      const foundMeta = (customMeta || []).find(existingItem => {
        if (existingItem && existingItem.config && item && existingItem.config.id === item.id) {
          return existingItem
        }
      })

      if (_.isEmpty(foundMeta)) {
        changeExists = true
      }
    })
    if (changeExists) {
      this.handlePageMetaDetailsChange(metaFieldList, null)
    }
  }

  handlePageMetaDetailsChange = (metaData, value) => {
    const listCustomMetaFields = _.cloneDeep(this.props.pageMetaFieldList)
    // const { details } = this.state
    const { customMeta } = this.state
    // const newDetails = _.cloneDeep(details)
    let finalMetaModified = []
    if (value || value === '' || value === false || value === 'false') {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        let newMetaField = {
          config: item,
          value: item && item.default
        }
        if (item.id === metaData.id && item.type === metaData.type) {
          newMetaField.value = item.type === 'STRING' ? (value || '') : value
          delete item.isError
          newMetaField.config = item
        } else {
          const tempItem = (customMeta || []).find(innerItem => innerItem && innerItem.config && innerItem.config.id === item.id && item.type === innerItem.config.type)
          if (_.isEmpty(tempItem)) {
            newMetaField.config = item
            newMetaField.value = item.default
          } else {
            newMetaField = tempItem
            newMetaField.value = tempItem.value
          }
        }

        return newMetaField
      })
      finalMetaModified = customMetaModified
    } else if (!value || value === null || value === undefined || value !== false) {
      const customMetaModified = (listCustomMetaFields || []).map(item => {
        const foundMeta = (customMeta || []).find(existingMeta => {
          if (existingMeta && existingMeta.config) {
            if (existingMeta.config.id === item.id) { return existingMeta }
          }
        })
        if (_.isEmpty(foundMeta)) {
          let newMetaField = {
            config: item,
            value: item && item.default
          }
          return newMetaField
        } else {
          return foundMeta
        }
      })
      finalMetaModified = customMetaModified
    }
    const newCustomMeta = finalMetaModified
    this.setState({ customMeta: newCustomMeta }, () => {
      this.setState({ isEnable: true })
    })
  }

  getPageMetaFields = (metaData, selectedMeta) => {
    const metaFields = selectedMeta ? _.cloneDeep(selectedMeta) : []
    return (metaFields || []).map((customMeta, index) => {
      const selectedMeta = (metaData || []).find(item => item && item.config && item.config.id === customMeta.id && item.config.type === customMeta.type)
      if (customMeta.type === 'BOOLEAN') {
        return <div className='switch-container input-field' key={index}>
          <div className='switch-inner-container'>
            <span>{customMeta.name}</span>
            <Switch checked={selectedMeta ? (selectedMeta.value === 'false' ? false : selectedMeta.value) : false} onChange={(value) => this.handlePageMetaDetailsChange(customMeta, value)} />
          </div>
        </div>
      } else if (customMeta.type === 'STRING') {
        return <Input
          // limit={50}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? selectedMeta.value : '') : ''}
          handleChange={({ target }) => this.handlePageMetaDetailsChange(customMeta, target.value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      } else if (customMeta.type === 'NUMBER') {
        return <InputNumber
          limit={16}
          key={index}
          title={customMeta.name}
          placeholder={`Enter ${customMeta.name}`}
          inputName='title'
          // isRequired={customMeta.isRequired}
          value={selectedMeta && selectedMeta.config ? (selectedMeta.value !== null ? parseInt(selectedMeta.value) : null) : null}
          handleChange={(value) => this.handlePageMetaDetailsChange(customMeta, value)}
          // onFocus={onInputFieldFocus}
          isError={selectedMeta ? selectedMeta.isError : false}
          errorMessage={''}
          // onBlur={onInputFieldBlur}
        />
      }
    })
  }

  handleTagChange = (value, tagsList, type, isDelete, isCustom) => {
    const { selectedTags } = this.state
    if (isDelete) {
      const index = selectedTags.findIndex(item => (item.name).toLowerCase() === value.toLowerCase() && item.type === type)
      selectedTags.splice(index, 1)
    } else {
      const index = tagsList.findIndex(item => (item.name).toLowerCase() === value.toLowerCase())
      if (index === -1) {
        if (isCustom) {
          const newTag = { name: value, module: 'APP_MANAGER', type: 'MANUAL' }
          const id = Math.random().toString(36).substr(2, 9)
          selectedTags.push({ ...newTag, id, type })
          this.setState({ selectedTags, isTagCreating: true })
          this.props.createTag(newTag).then(response => {
            const tag = response.data.createTag
            const index = selectedTags.findIndex(item => item.id === id)
            selectedTags.splice(index, 1, tag)
            this.setState({ selectedTags, isEnable: true, isTagCreating: false })
          }, error => {
            const index = (error.message || '').indexOf('ConditionalCheckFailedException')
            if (index > -1) {
              const tagIndex = selectedTags.findIndex(item => item.id === id)
              const newId = `${value}content_bank`
              selectedTags[tagIndex].key = newId
              this.setState({ selectedTags, isEnable: true, isTagCreating: false })
              return
            }
            utilityService.handleError(error)
          })
        }
      } else {
        const newTag = (tagsList[index])
        const isPresent = selectedTags.findIndex(item => item.key === newTag.key) > -1
        if (!isPresent) {
          selectedTags.push(newTag)
        }
      }
    }
    this.setState({ selectedTags, isEnable: true })
  }

  handleTextChange = (event) => {
    const { value } = event.target
    const { isFromEdit } = this.props
    const expectedKey = utilityService.slugifyString(this.state.pageTitle || '')
    const expectedSeoTitle = this.state.pageTitle || ''
    if (expectedKey.toLocaleUpperCase() === this.state.key && !isFromEdit && expectedSeoTitle === this.state.seoTitle) {
      const key = utilityService.slugifyString(value).slice(0, 125).toLocaleUpperCase()
      this.setState({ pageTitle: value, key, seoTitle: value })
    } else {
      this.setState({ pageTitle: value, isEnable: true })
    }
  }

  handleIdChange =(e) => {
    const { isDuplicating } = this.props
    const codeReg = /^[a-zA-Z0-9-]*$/
    const key = codeReg.test(e.target.value) ? e.target.value.toLocaleUpperCase() : this.state.key
    if (isDuplicating) {
      this.setState({ key, isEnable: true })
    }
    this.setState({ key })
  }

  handleAppChange = (option) => {
    const appData = (this.props.appList || []).find(appItem => appItem.id === option)
    const isCritical = (appData && appData.isCritical) || false
    if (this.props.isFromEdit === true) {
      this.setState({ app: option, isEnable: true, group: '', appData })
    } else {
      this.setState({ app: option, isEnable: true, group: '', appData, isCritical: isCritical })
    }
    // this.setState({ app: option, isEnable: true, group: '' })
  }

  handleGroupChange = (option) => {
    const groupData = (this.props.groupList || []).find(groupItem => groupItem.key === option)
    const isCritical = (groupData && groupData.isCritical) || false

    let users = this.state.assignedUsers || []
    // let isUserExist = false
    // ((groupData && groupData.assignedUsers) || []).map(user => {
    //   if (!users.includes(user)) {
    //     users.push(user)
    //   }
    // })
    if (this.props.isFromEdit === true) {
      this.setState({ group: option, isEnable: true, groupData, assignedUsers: users })
    } else {
      this.setState({ group: option, isEnable: true, groupData, isCritical: isCritical, assignedUsers: users })
    }
    // this.setState({ group: option, isEnable: true })
  }

  onItemSelected = (itemSelected) => {
    this.setState({ itemSelected })
  }

  handleSave = async () => {
    const { isFromEdit, selectedPage, isDuplicating, details, isCriticalWriteDisabled, isAppAdminDisabled, userId } = this.props
    const { pageTitle, app, key, group, shortDescription, selectedTags, slug, seoKeywords, seoMetaDescription, seoTitle, customMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged, appData } = this.state
    const isAppCritical = (details && details.app && details.app.isCritical) || false
    const isGroupCritical = (details && details.group && details.group.isCritical) || false
    const isPageCritical = isAppCritical || isGroupCritical || isCritical
    const isAssignedUser = (assignedUsers || []).find(user => user === userId)

    if (!isFromEdit && appData && appData.assignedUsers && appData.assignedUsers.length > 0 && isAppAdminDisabled) {
      const isUserAssignedToApp = ((appData && appData.assignedUsers) || []).find(user => user === userId)
      if (!isUserAssignedToApp) {
        message.error('You do not have permission to create this Page, since user is not assigned to App')
        return
      }
    }
    if (isAppAdminDisabled && assignedUsers && assignedUsers.length > 0 && !isAssignedUser) {
      message.error('You do not have permission to do this operation on this Page')
      return
    }
    if (isFromEdit && !isDuplicating && ((isCriticalWriteDisabled && isAppAdminDisabled && isPageCritical) || (!isAssignedUser && assignedUsers && assignedUsers.length > 0 && isAppAdminDisabled))) {
      message.error('You do not have access to do this operation on this Page')
      return
    }
    if (assignedUsers && assignedUsers.length) {
      const finalUserList = isCritical ? this.props.criticalUserList : this.props.userList
      let inValidUsers = []
      for (let i = 0; i < assignedUsers.length; i++) {
        const isValidUser = (finalUserList || []).find(user => user.id === assignedUsers[i])
        if (_.isEmpty(isValidUser)) {
          inValidUsers.push(assignedUsers[i])
        }
      }
      if (isCritical && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Please Remove Users that are not Critical Users before saving Page. Please find list of invalid users: ' + inValidUsersString)
        return
      } else if (!isCritical && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Please Remove Users that do not have required permissions before saving Page. Please find list of invalid users: ' + inValidUsersString)
        return
      }
    }
    this.setState({ isLoading: true })
    if (this.props.checkForPageModification) {
      if (await this.props.checkForPageModification()) {
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
        return
      }
    }
    const tagsArray = (selectedTags || []).map(tag => {
      if (tag) {
        return tag.key
      }
    })
    const newCustomMeta = (customMeta || []).map(item => {
      const newItem = {
        config: '',
        value: ''
      }
      if (item.config && item.config.id) {
        newItem.config = item.config.id
        newItem.value = item.value
        newItem.name = item.config.name || ''
      }
      return newItem
    })

    if (isFromEdit && !isDuplicating) {
      this.props.updateAppPage(pageTitle, app, key, group, shortDescription, tagsArray, slug, seoKeywords, seoMetaDescription, seoTitle, selectedPage, newCustomMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged).then(() => {
        message.success('Page updated successfully')
        this.setState({ isLoading: false, isEnable: false })
        // this.props.reCreatePageTree()
        this.props.refetchPageList(true)
        this.props.refetchHistory()
        this.handleCancel()
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    } else if (isDuplicating) {
      const { isArchived, publishStartDate, publishEndDate, layoutItems, scheduledVersions } = details
      const newLayoutItems = (layoutItems || []).map(layoutItem => {
        if (layoutItem) {
          return layoutItem.id
        }
      })

      const newScheduledVersions = (scheduledVersions || []).map(scheduledVersion => {
        if (scheduledVersion) {
          return scheduledVersion.id
        }
      })
      this.props.duplicateAppPage(selectedPage, pageTitle, key, app, group, tagsArray, shortDescription, newLayoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, false, isArchived, publishStartDate, publishEndDate, newCustomMeta, newScheduledVersions, (isCritical || false), assignedUsers).then((res) => {
        if (res && res.data && res.data.duplicateAppPage) {
          message.success('Page Duplicated successfully')
          this.setState({ isLoading: false, isEnable: false })
          this.props.refetchPageList(true)
          this.handleCancel()
          this.props.onSelectPageGroup(res.data.duplicateAppPage.id)
        }
      }, error => {
        utilityService.handleError(error)
        if (error.message.includes('App Page Already exists with the Key')) {
          this.setState({ isLoading: false, isEnable: false, app, group, shortDescription, selectedTags, customMeta, slug, seoKeywords, seoMetaDescription, seoTitle })
          return
        }
        this.setState({ isLoading: false, isEnable: false })
        this.handleCancel()
      })
    } else {
      this.props.createAppPage(pageTitle, app, key, group, shortDescription, tagsArray, slug, seoKeywords, seoMetaDescription, seoTitle, newCustomMeta, isCritical, assignedUsers).then((res) => {
        if (res && res.data && res.data.createAppPage) {
          message.success('Page created successfully')
          this.setState({ isLoading: false, isEnable: false })
          this.props.refetchPageList(true)
          this.props.onSelectPageGroup(res.data.createAppPage.id)
          this.handleCancel()
        }
      }, error => {
        utilityService.handleError(error)
        this.setState({ isLoading: false, isEnable: false })
        // this.handleCancel()
      })
    }
  }

  handleCancel = () => {
    this.resetState()
    this.props.hidePageModal()
  }

  forceSave=() => {
    this.setState({ shouldSave: true, ignoreWarning: true, showWarning: false }, () => this.handleSave())
  }

  discardChanges=() => {
    this.setState({ showWarning: false })
  }

  onInputFieldFocus = () => {
    this.isEditingInput = true
  }

  onInputFieldBlur = (e) => {
    if (!this.isEditingInput) {
      return
    }
    const { slug, pageTitle } = this.state
    const defaultSlug = utilityService.slugifyString(`untitled`)
    const isFirstSlug = slug && (slug.indexOf('untitled-') === 0 || slug.indexOf(defaultSlug) === 0)
    if (isFirstSlug && pageTitle !== '') {
      const newTitle = `${pageTitle.toLowerCase()}`
      const slug = utilityService.slugifyString(newTitle)
      // details.slug = slug
      this.setState({ slug: slug })
    }
    this.isEditingInput = false
  }

  handleSeoDetailsChange = e => {
    // const { details } = this.props
    let { isMetaDescEdited } = this.state
    const { name, value } = e.target
    // details[name] = value
    if (name === 'seoMetaDescription') {
      isMetaDescEdited = true
      this.setState({ seoMetaDescription: value, isMetaDescEdited, isEnable: true })
    }
    if (name === 'seoTitle') {
      this.setState({ seoTitle: value, isEnable: true })
    }
    if (name === 'slug') {
      this.slugEdited = true
      const modified = utilityService.slugifyString(value)
      if (value !== modified) {
        this.invalidSlug = true
      } else {
        this.setState({ isOldSlug: false })
        this.invalidSlug = false
      }
      this.setState({ slug: value, isMetaDescEdited, isEnable: true })
    }
  }

  handleShortDescriptionChange = (e) => {
    const { isFromEdit } = this.props
    const { value } = e.target
    const expectedSeoMetaDescription = this.state.shortDescription || ''
    if (!isFromEdit && expectedSeoMetaDescription === this.state.seoMetaDescription) {
      this.setState({ shortDescription: value, seoMetaDescription: value })
    } else {
      this.setState({ shortDescription: value, isEnable: true })
    }
    // this.setState({ shortDescription: value || '', isEnable: true })
  }

  onSeoKeywordChange = (value, isDelete) => {
    let { seoKeywords } = this.state
    if (isDelete) {
      const index = seoKeywords.indexOf(value)
      if (index > -1) {
        seoKeywords.splice(index, 1)
      }
    } else {
      if (!seoKeywords) {
        seoKeywords = [value]
      } else {
        const index = seoKeywords.findIndex(item => item === value)
        if (index === -1) {
          seoKeywords.push(value)
        }
      }
    }
    this.setState({ seoKeywords, isEnable: true })
  }

  onChangePageStatus = (value) => {
    const { assignedUsers } = this.state
    let tempAssignedUsers = []
    if (this.props.isAppAdminDisabled) {
      message.error('You do not have permission to update critical flag')
      return
    }

    if (assignedUsers && assignedUsers.length) {
      const finalUserList = value ? this.props.criticalUserList : this.props.userList
      let inValidUsers = []
      for (let i = 0; i < assignedUsers.length; i++) {
        const foundIndex = (finalUserList || []).findIndex(user => user.id === assignedUsers[i])
        if (foundIndex > -1) {
          tempAssignedUsers.push(assignedUsers[i])
        } else {
          inValidUsers.push(assignedUsers[i])
        }
      }
      this.setState({ assignedUsers: tempAssignedUsers })
      if (value && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Removing Users that are not Critical Users. Please find list of invalid users removed: ' + inValidUsersString)
      } else if (!value && !_.isEmpty(inValidUsers)) {
        let inValidUsersString = inValidUsers.toString()
        message.error('Removing Users that do not have required permissions. Please find list of invalid users removed: ' + inValidUsersString)
      }
    }
    this.setState({ isCritical: value, isEnable: true, isCriticalChanged: true })
  }

  handleUserChange = (option) => {
    // const { isFromEdit, details } = this.props
    // const { groupData } = this.state
    // if (!isFromEdit && groupData && groupData.assignedUsers && groupData.assignedUsers.length > 0) {
    //   for (let i = 0; i < groupData.assignedUsers.length; i++) {
    //     const foundUser = (option || []).find(user => user.id === groupData.assignedUsers[i])
    //     if (!foundUser) {
    //       message.error('You cannot remove user that is already assigned to App/Group')
    //       return
    //     }
    //   }
    // } else if (isFromEdit && !groupData && details && details.assignedUsers && details.assignedUsers.length > 0) {
    //   for (let i = 0; i < details.assignedUsers.length; i++) {
    //     const foundUser = (option || []).find(user => user.id === details.assignedUsers[i])
    //     if (!foundUser) {
    //       message.error('You cannot remove user that is already assigned to App/Group')
    //       return
    //     }
    //   }
    // } else if (isFromEdit && groupData && groupData.assignedUsers && groupData.assignedUsers.length > 0) {
    //   for (let i = 0; i < groupData.assignedUsers.length; i++) {
    //     const foundUser = (option || []).find(user => user.id === groupData.assignedUsers[i])
    //     if (!foundUser) {
    //       message.error('You cannot remove user that is already assigned to App/Group')
    //       return
    //     }
    //   }
    // }

    let finalOptions = (option || []).map(item => item && item.id)
    let assignedUsers = (this.props.userList || []).filter(item => finalOptions.includes(item.id)).map(item => item.id)
    // apps.sort(function (a, b) {
    //   return option.indexOf(a.name) - option.indexOf(b.name)
    // })
    // details.countries = countries

    this.setState({ assignedUsers, isEnable: true, isAssigneeChanged: true })
  }

  handleUserSearch=(value) => {
    this.setState({ userSearch: value })
  }

  render () {
    const { details, typeList, appList, groupList, isUploadBlocked, isCreateDisabled, isUpdateBlocked, project, isFromEdit, appClient, pageMetaFieldList, isDuplicating, userList, criticalUserList, isAppAdminDisabled } = this.props
    const { selectedTags, pageTitle, isSaving, app, isTagCreating, systemTags, group, isEnable, isLoading, shortDescription, slug, seoTitle, seoMetaDescription, seoKeywords, key, isCritical, appData, groupData, assignedUsers, userSearch } = this.state
    const finalIsEnable = !isFromEdit ? !!(pageTitle && app && group && seoTitle && slug) : !!(isEnable && group && pageTitle && app && seoTitle && slug)
    const isConfigCritical = !!((isCritical || (this.props.details && this.props.details.app && this.props.details.app.isCritical) || (groupData && groupData.isCritical)))
    const container = this.props.isVisible ? (
      <CreateAppPageContainer
        details={_.cloneDeep(details)}
        selectedTags={selectedTags || []}
        name={pageTitle}
        shortDescription={shortDescription}
        handleTagChange={this.handleTagChange}
        handleAppChange={this.handleAppChange}
        handleTextChange={this.handleTextChange}
        handleIdChange={this.handleIdChange}
        systemTags={systemTags}
        selectedKey={key}
        typeList={typeList}
        appList={appList}
        groupList={groupList}
        selectedApp={app}
        isUploadBlocked={isUploadBlocked}
        selectedGroup={group}
        handleGroupChange={this.handleGroupChange}
        isFromEdit={isFromEdit}
        onInputFieldFocus={this.onInputFieldFocus}
        onInputFieldBlur={this.onInputFieldBlur}
        handleSeoDetailsChange={this.handleSeoDetailsChange}
        handleShortDescriptionChange={this.handleShortDescriptionChange}
        onSeoKeywordChange={this.onSeoKeywordChange}
        slug={slug}
        seoTitle={seoTitle}
        isCritical={isCritical}
        onChangePageStatus={this.onChangePageStatus}
        seoMetaDescription={seoMetaDescription}
        seoKeywords={seoKeywords}
        pageMetaFields={this.getPageMetaFields(this.state.customMeta, pageMetaFieldList)}
        appClient={appClient}
        project={project}
        isDuplicating={isDuplicating}
        appData={appData}
        groupData={groupData}
        assignedUsers={assignedUsers}
        handleUserChange={this.handleUserChange}
        userList={isConfigCritical ? criticalUserList : userList}
        userSearch={userSearch}
        handleUserSearch={this.handleUserSearch}
        isAppAdminDisabled={isAppAdminDisabled}
      />
    ) : <div />
    return (
      <div>
        <Modal
          className='confirm-modal edit-image'
          title={details ? isDuplicating ? 'DUPLICATE PAGE' : 'EDIT PAGE' : 'CREATE PAGE'}
          visible={this.props.isVisible}
          okText='Save'
          okButtonProps={{ disabled: (!finalIsEnable || (isUpdateBlocked && isCreateDisabled) || isTagCreating), loading: isLoading }}
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          closable={false}
          confirmLoading={isSaving}
          destroyOnClose
          maskClosable={false}
          centered
          width='890px'
        >
          {container}
        </Modal>
      </div>
    )
  }
}

CreatePageModal.propTypes = {
  /** Visible status of Modal. */
  isVisible: PropTypes.bool,
  /** id of the selected image */
  imageId: PropTypes.string,
  /**  */
  createTag: PropTypes.func,
  /**  */
  tagsList: PropTypes.array,
  /** Callback for save content */
  updateImage: PropTypes.func,
  /**  */
  handleSuccess: PropTypes.func,
  /**  */
  mediaDetails: PropTypes.object,
  /** Callback to hide image editor */
  hideImageEditor: PropTypes.func
}

CreatePageModal.defaultProps = {}

export default withApollo(
  compose(
    graphql(MutationCreateTag, {
      // TODO: The code for MutationCreateTag could be consolidated to keep the code DRY
      options: ({ project, appClient }) => {
        return {
          update: (cache, { data: { createTag } }) => {
            const filter = { type: { match: 'MANUAL' }, module: { exact: 'APP_MANAGER' } }
            try {
              const cacheData = _.cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
              if (cacheData && cacheData.listTags && cacheData.listTags.items) { cacheData.listTags.items.push(createTag) }
              cache.writeQuery({
                query: QueryAllTags,
                data: cacheData
              })
            } catch (e) {
              console.log('error', encodeURI)
            }
          }
          // client: appClient
        }
      },
      props: props => ({
        createTag: contentObj => {
          let variables = contentObj
          variables.project = props.ownProps.project
          return props.mutate({
            variables
          })
        }
      })
    }),
    graphql(
      QueryTagType,
      {
        options: ({ appClient, project }) => {
          return {
            fetchPolicy: 'network-only',
            variables: { filter: null, limit: 500, project }
            // client: appClient
          }
        },
        props: (props) => {
          return {
            systemTags: props.data && props.data.listTagType && props.data.listTagType.items && props.data.listTagType.items.length ? props.data.listTagType.items : []
          }
        }
      }
    ),
    // graphql(
    //   MutationCreateAudit,
    //   {
    //     props: (props) => ({
    //       createAudit: (variables) => {
    //         return props.mutate({
    //           variables
    //         })
    //       }
    //     })
    //   }
    // ),
    graphql(MutationCreatePage, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { createAppPage } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppPageList, variables: { limit: 499, project } }))
            if (cacheData && cacheData.listAppPages && cacheData.listAppPages.items) { cacheData.listAppPages.items.push(createAppPage) }
            cache.writeQuery({
              query: QueryGetAppPageList,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        createAppPage: (title, app, key, group, shortDescription, tags, slug, seoKeywords, seoMetaDescription, seoTitle, customMeta, isCritical, assignedUsers) => {
          return props.mutate({
            variables: { title, app, key, group, shortDescription, tags, slug, seoKeywords, seoMetaDescription, seoTitle, project: props.ownProps.project, customMeta, isCritical, assignedUsers }
          })
        }
      })
    }),
    graphql(MutationUpdatePage, {
      options: ({ appClient, project }) => {
        return {
          update: (cache, { data: { editAppPage } }) => {
            const cacheData = _.cloneDeep(cache.readQuery({ query: QueryGetAppPage, variables: { id: editAppPage.id, project } }))
            if (cacheData && cacheData.editAppPage) {
              cacheData.editAppPage.data = editAppPage
            }
            cache.writeQuery({
              query: QueryGetAppPage,
              data: cacheData
            })
          },
          client: appClient
        }
      },
      props: props => ({
        updateAppPage: (title, app, key, group, shortDescription, tags, slug, seoKeywords, seoMetaDescription, seoTitle, id, customMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged) => {
          return props.mutate({
            variables: { title, app, key, group, shortDescription, tags, slug, seoKeywords, seoMetaDescription, seoTitle, id, project: props.ownProps.project, customMeta, isCritical, isCriticalChanged, assignedUsers, isAssigneeChanged }
          })
        }
      })
    }),
    graphql(
      MutationDuplicateAppPage, {
        options: ({ appClient }) => {
          return {
            client: appClient
          }
        },
        props: (props) => ({
          duplicateAppPage: (id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta, scheduledVersions, isCritical, assignedUsers) => {
            return props.mutate({
              variables: { id, title, key, app, group, tags, shortDescription, layoutItems, slug, seoKeywords, seoMetaDescription, seoTitle, isPublished, isArchived, publishStartDate, publishEndDate, customMeta, scheduledVersions, project: props.ownProps.project, isCritical, assignedUsers }
            })
          }
        })
      }
    ),
    graphql(
      QueryUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            userList: data.listUsers && data.listUsers.items && data.listUsers.items.length ? data.listUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listUsers ? data.listUsers.totalCount : 0,
            getMoreUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listUsers.items, ...fetchMoreResult.listUsers.items]
                  prev.listUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    ),
    graphql(
      QueryCriticalUserList,
      {
        options: (props) => {
          const variables = { search: props.userSearch, project: props.project }
          return {
            fetchPolicy: 'network-only',
            variables
          }
        },
        props: (props) => {
          const { data } = props
          return {
            criticalUserList: data.listCriticalUsers && data.listCriticalUsers.items && data.listCriticalUsers.items.length ? data.listCriticalUsers.items : [],
            isLoading: data.loading,
            totalCount: data.listCriticalUsers ? data.listCriticalUsers.totalCount : 0,
            getMoreCriticalUsers: (page) => {
              return data.fetchMore({
                fetchPolicy: 'network-only',
                variables: {
                  offset: page,
                  project: props.ownProps.project
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  const newList = [...prev.listCriticalUsers.items, ...fetchMoreResult.listCriticalUsers.items]
                  prev.listCriticalUsers.items = newList
                  return prev
                }
              })
            }
          }
        }
      }
    )
  )(CreatePageModal)
)
