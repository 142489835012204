import gql from 'graphql-tag'

export default gql(`
query getAppPage($id: ID!, $project: String!) {
    getAppPage(id: $id, project: $project) {
        id
        key
        project
        createdAt
        updatedAt
        updatedBy
        createdBy
      }
}`)
