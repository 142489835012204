import React from 'react'
import PropTypes from 'prop-types'

const CrossIcon = ({ width, height, onClick, color, module, ...props }) => (
  <>
    {module !== 'appConfig' ? <svg className='cross-icon' width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
      <path fill={color} fillRule='evenodd' d='M7.5 6.322L1.607.429.43 1.607 6.322 7.5.429 13.393l1.178 1.178L7.5 8.678l5.893 5.893 1.178-1.178L8.678 7.5l5.893-5.893L13.393.43z' />
    </svg>
      : <svg className='cross-icon' onClick={onClick} width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h15v15H0z' />
          <path d='m.529.09 6.957 6.971 6.96-6.97a.308.308 0 0 1 .436.436L7.923 7.499l6.96 6.973a.31.31 0 0 1-.217.527.307.307 0 0 1-.22-.09L7.488 7.934.529 14.908a.307.307 0 0 1-.22.091.31.31 0 0 1-.22-.527L7.05 7.498.093.527A.308.308 0 0 1 .529.09z' fill='#343A40' />
        </g>
      </svg>
    }
  </>
)

CrossIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onClick: PropTypes.func,
  color: PropTypes.string
}

CrossIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#343A40'
}

export default CrossIcon
