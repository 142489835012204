import gql from 'graphql-tag'

export default gql(`
    mutation createConfigGroupSchema( $name: String $streams: [CreateStreamSchemaInput]! $fields: [CreateFieldInput] $version: String!) {
        createConfigGroupSchema(
            input: {
                name: $name
                fields: $fields
                streams: $streams
                version: $version
            }
        ) {
            id
            name
            usedCount
            version
            streams{
                id
                levels {
                    id
                    name
                    fields{
                        name
                        value
                        isRequired
                        type
                    }
                }
            }
            fields{
                name
                value
                isRequired
                type
            }
        }
    }`
)
