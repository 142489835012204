import gql from 'graphql-tag'

export default gql(`
    mutation batchCreateBucketItem($bucketItems:[CreateBucketItemInput]! $module: MODULE_TYPE $project: String) {
        batchCreateBucketItem (
            input: $bucketItems
            module: $module
            project: $project
          ) {
            id
            position
            type
            filterRule{
                searchKey
                filters{
                    name
                    fieldName
                    type
                    value
                    displayValue
                    displayName
                    isRequired
                }
            }
            asset{
                id
                title
                shortId
                externalId
                status
                type
                updatedAt
                seasonNumber
                episodeNumber
                series{
                  id
                  assetId
                  title
                }
                season{
                    id
                    assetId
                    title
                }
                defaultMedia {
                    id
                    url
                    fileName
                    aspectRatio {
                      title
                      aspectRatio
                      resolutions {
                        url
                      }
                    }
                    settings {
                      aspectRatio
                      outputFormat
                      fileName
                      x1
                      y1
                      x2 
                      y2
                    }
                }
            }
        }
    }`
)
