import React from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import Input from '../inputs/Input'
import DropDown from '../inputs/DropDown'
import { utilityService } from '../../../../services/UtilityService'
import './../../ui.style.scss'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose, cloneDeep } from 'lodash'
import MutationCreateTag from '../../../../graphQL/asset/createTag'
import QueryAllTags from '../../../../graphQL/asset/getTags'

// const tagTypes = [
//   {
//     id: 'SPORT',
//     name: 'Sport'
//   }, {
//     id: 'TEAM',
//     name: 'Team'
//   // }, {
//   //   id: 'VIMOND',
//   //   name: 'Vimond'
//   }, {
//     id: 'COMPETITION',
//     name: 'Competition'
//   }, {
//     id: 'BUCKET',
//     name: 'Bucket'
//   }, {
//     id: 'PRODUCT',
//     name: 'Product'
//   }, {
//     name: 'Search Filter',
//     id: 'SEARCH_FILTER'
//   }
// ]

class CreateTagModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: undefined,
      systemType: undefined,
      isLoading: false
    }
  }

  resetState = () => {
    const state = {
      name: undefined,
      systemType: undefined
    }
    this.setState({ ...state })
  }

  onTagTypeChange = (value) => {
    this.setState({ systemType: value })
  }

  onChangeTagName = (e) => {
    const { value } = e.target
    this.setState({ name: value })
  }

  handleSubmit = () => {
    this.setState({
      isLoading: true
    })
    const { systemType, name } = this.state
    const { onHistoryUpdate } = this.props
    const request = {
      name, type: systemType, module: 'ALL'
    }

    this.props.createTag(request).then(() => {
      this.setState({
        isLoading: false
      })
      this.props.onTagCreation()
      onHistoryUpdate()
    }, error => {
      if ((error.message || '').indexOf('The conditional request failed') > -1) {
        message.error('Duplicate tag name found. Please change the tag name and try again')
      } else {
        utilityService.handleError(error)
      }
    })
  }

  render () {
    const { visible, handleCancelTagCreation, tagTypes } = this.props
    const { name, systemType, isLoading } = this.state
    const newTagTypes = (tagTypes || []).filter(item => item.name !== 'MANUAL')
    return (

      <Modal
        className='general-modal create-system-tag-modal'
        visible={visible}
        closable={false}
        title={'New System Tag'}
        okButtonProps={{ disabled: !systemType || !name || isLoading, loading: isLoading }}
        onOk={this.handleSubmit}
        onCancel={handleCancelTagCreation}
        okText={'Save'}
        cancelText='Cancel'
        afterClose={this.resetState}
        width='620px'
        centered
        destroyOnClose
        maskClosable={false}
      >
        <div className='custom-data'>
          <Input title='Tag Name' isRequired handleChange={this.onChangeTagName} value={name} placeholder='Please enter Tag name' />
          <div className='details' id='details'>
            <label>Tag Type<span className='mandatory'>*</span></label>
            <DropDown displayParam='name' valueParam='name' onOptionSelect={this.onTagTypeChange} selectedValue={systemType} options={newTagTypes} placeHolder='Select Tag Type' parent='details' />
          </div>
        </div>

      </Modal>
    )
  }
}

CreateTagModal.propTypes = {
  /** visible action of CreateUserGroupModal. */
  visible: PropTypes.bool,
  /** title  of CreateUserGroupModal. */
  title: PropTypes.string,
  /** handleCancel action of CreateUserGroupModal. */
  handleCancel: PropTypes.func
}

CreateTagModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationCreateTag,
      {
        options: ({ project, tagFilter }) => {
          return {
            update: (cache, { data: { createTag } }) => {
              let filter = utilityService.getFormattedTagFilter(tagFilter)
              if ((filter.module && filter.module.multiple_exact && (filter.module.multiple_exact || []).findIndex(item => item === createTag.module) === -1) || (filter.type && filter.type.multiple_exact && (filter.type.multiple_exact || []).findIndex(item => item === createTag.type) === -1)) {
                filter = {}
              }
              const cacheData = cloneDeep(cache.readQuery({ query: QueryAllTags, variables: { filter, limit: 30, offset: 0, project } }))
              if (cacheData && cacheData.listTags && cacheData.listTags.items && createTag) { cacheData.listTags.items.splice(0, 0, createTag) }
              cache.writeQuery({
                query: QueryAllTags,
                data: cacheData
              })
            }
          }
        },

        props: (props) => ({
          createTag: (contentObj) => {
            let variables = contentObj
            variables.project = props.ownProps.project
            return props.mutate({
              variables
            })
          }
        })
      }
    )
  )(CreateTagModal)
)
