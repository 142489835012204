import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import PropTypes from 'prop-types'

import AttributeFieldCell from './AttributeFieldCell'

class AttributeFieldComponent extends Component {
  onDragEnd = result => {
    if (!result.destination) {
      return
    }
    this.props.reorder(result.source.index, result.destination.index)
  }

  render () {
    const {
      attributeData,
      handleNameChange,
      handleLabelChange,
      handleAttributeSelect,
      handleAttributeSearch,
      onCloseField,
      attributes,
      attributeSearch,
      handleNameBlur,
      handleLabelBlur,
      onSaveAttribute,
      onCancelAttribute,
      isUpdateDisabled
    } = this.props
    const getItemStyle = (isDragging, draggableStyle) => ({
      ...draggableStyle
    })

    const getListStyle = isDraggingOver => ({
      // borderTop: isDraggingOver ? '1px solid #cfd0d1' : 'none'
    })

    return (
      <div className='attribute-field-objects'>
        <div className='attribute-data'>
          {attributeData && attributeData.length
            ? <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(droppableProvided, droppableSnapshot) => (
                  <div className='attribute-content'
                    ref={droppableProvided.innerRef}
                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                  >
                    {attributeData.map((field, index) => (
                      <Draggable key={field.id} draggableId={`id${index}`} index={index} isDragDisabled={isUpdateDisabled}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <AttributeFieldCell
                              field={field}
                              index={index}
                              onCloseField={onCloseField}
                              handleNameChange={handleNameChange}
                              handleLabelChange={handleLabelChange}
                              handleAttributeSelect={handleAttributeSelect}
                              handleAttributeSearch={handleAttributeSearch}
                              attributeSearch={attributeSearch}
                              attributes={attributes}
                              handleNameBlur={handleNameBlur}
                              handleLabelBlur={handleLabelBlur}
                              onSaveAttribute={onSaveAttribute}
                              onCancelAttribute={onCancelAttribute}
                              attributeData={attributeData}
                              isUpdateDisabled={isUpdateDisabled}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            : null }
        </div>
      </div>
    )
  }
}

AttributeFieldComponent.propTypes = {
  /** data of attributes */
  attributeData: PropTypes.array,
  /** function to handle attribute filed close */
  onCloseField: PropTypes.func,
  /** function to handle attribute filed reorder */
  reorder: PropTypes.func,
  /** function to handleNameChange in attribueFieldCell */
  handleNameChange: PropTypes.func,
  /** function to handleLabelChange in attribueFieldCell */
  handleLabelChange: PropTypes.func,
  /** function to handle attributeType change in attribueFieldCell */
  handleAttributeSelect: PropTypes.func,
  /** data for attributeType dropdown */
  attributes: PropTypes.array,
  /** string that represents attribute to be searched */
  attributeSearch: PropTypes.string,
  /** function to handle attributeName apicall in attribueFieldCell */
  handleNameBlur: PropTypes.func,
  /** function to handle attributeLabel apicall in attribueFieldCell */
  handleLabelBlur: PropTypes.func

}

export default AttributeFieldComponent
