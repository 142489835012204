import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, DatePicker } from 'antd'
import Input from '../inputs/Input'
import { Auth } from 'aws-amplify'

import moment from 'moment'

import './../../ui.style.scss'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import _, { flowRight as compose } from 'lodash'
import MutationRefund from '../../../../graphQL/customers/refund'
import { utilityService } from '../../../../services/UtilityService'

// import { utilityService } from '../../../../services/UtilityService'

class RefundModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      userEmail: this.props.email ? this.props.email : '',
      cmsUserEmail: '',
      offerId: '',
      comment: '',
      transactionId: '',
      transactionCoverTimeStamp: '',
      isEdited: false
    }
    this.getUserDetails()
  }

  getUserDetails = async () => {
    const user = await Auth.currentUserInfo()
    if (user && user.attributes) {
      const { email } = user.attributes
      this.setState({ cmsUserEmail: email })
    } else {
      message.warn('User details not found')
    }
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => { // eslint-disable-line camelcase
    const { email } = nextProps
    if (!(_.isEqual(email, this.props.email)) && !this.state.isEdited) {
      this.setState({
        userEmail: email
      })
    }
  }
  handleChangeOfferId = (event) => {
    const offerId = event.target.value
    if (!_.isEmpty(offerId) && !_.isEmpty(this.state.comment) && (!_.isEmpty(this.state.transactionId))) {
      this.setState({ offerId, isEdited: true })
    } else {
      this.setState({ offerId, isEdited: false })
    }
  }

  handleChangeComment = (event) => {
    const comment = event.target.value
    if (!_.isEmpty(comment) && !_.isEmpty(this.state.offerId) && (!_.isEmpty(this.state.transactionId))) {
      this.setState({ comment, isEdited: true })
    } else {
      this.setState({ comment, isEdited: false })
    }
  }

  handleChangeTransactionId = (event) => {
    const transactionId = event.target.value
    if (!_.isEmpty(this.state.comment) && !_.isEmpty(this.state.offerId) && (!_.isEmpty(transactionId))) {
      this.setState({ transactionId, isEdited: true })
    } else {
      this.setState({ transactionId, isEdited: false })
    }
  }

  handleChangeTransactionCoverTimeStamp = (selectedDate) => {
    const transactionCoverTimeStamp = selectedDate ? moment(selectedDate).utc().format() : null
    if (!_.isEmpty(this.state.comment) && !_.isEmpty(this.state.offerId) && (!_.isEmpty(this.state.transactionId) || !_.isEmpty(transactionCoverTimeStamp))) {
      this.setState({ transactionCoverTimeStamp, isEdited: true })
    } else {
      this.setState({ transactionCoverTimeStamp, isEdited: false })
    }
  }

  handleSubmit = () => {
    this.setState({ isLoading: true })
    const { userEmail, cmsUserEmail, offerId, comment, transactionId, transactionCoverTimeStamp } = this.state
    const { onRefund } = this.props
    if (_.isEmpty(offerId)) {
      message.warn('Please provide `Offer Id`')
      return
    } else if (_.isEmpty(comment)) {
      message.warn('Please provide `Comment`')
      return
    } else if (_.isEmpty(transactionId) && _.isEmpty(transactionCoverTimeStamp)) {
      message.warn('Please provide either `Transaction Id` or `Transaction Cover Time Stamp`')
      return
    }
    const refundRequest = {
      userEmail: userEmail || '',
      cmsUserEmail: cmsUserEmail || '',
      offerId: offerId || '',
      comment: comment || '',
      transactionId: transactionId || '',
      transactionCoverTimeStamp: transactionCoverTimeStamp || ''
    }
    this.props.refund(refundRequest).then(({ data }) => {
      if (!data.adminRefund) {
        this.setState({ isLoading: false })
        onRefund()
        message.error('Something went wrong, Please try again later')
        return
      }
      const { success, error } = data.adminRefund
      if (success && success.hasOwnProperty('message') && !_.isEmpty(success.message)) {
        onRefund()
        message.success(success.message)
        this.setState({ isLoading: false })
        this.props.fetchHistory()
      } if (error && error.hasOwnProperty('message') && !_.isEmpty(error.message)) {
        message.error(error.message)
        this.setState({ isLoading: false })
        this.props.fetchHistory()
      }
    }, error => {
      utilityService.handleError(error)
      this.setState({ isLoading: false })
    })
  }

  clearState = () => {
    const state = {
      isLoading: false,
      offerId: '',
      comment: '',
      transactionId: '',
      transactionCoverTimeStamp: '',
      isEdited: false
    }
    this.setState({ ...state })
  }

  render () {
    const { isVisible, isSubmitDisabled, handleCancel } = this.props
    const { userEmail, cmsUserEmail, offerId, comment, transactionId, isEdited, isLoading, transactionCoverTimeStamp } = this.state
    const isEnable = isEdited
    return (
      <Modal
        className='general-modal edit-customer'
        title={'REFUND'}
        maskClosable={false}
        visible={isVisible}
        okText='Send'
        cancelText='Cancel'
        onOk={this.handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ disabled: (isSubmitDisabled || !isEnable) }}
        closable={false}
        centered
        destroyOnClose
        confirmLoading={isLoading}
        afterClose={this.clearState}
      >
        { isVisible
          ? <React.Fragment>
            <div className='edit-cutomer-container'>
              <Input
                title='User Email'
                inputName='userEmail'
                handleChange={() => {}}
                value={userEmail}
                readOnly
                isRequired
                placeholder='Enter User Email' />
              <Input
                title='CMS User Email'
                inputName='cmsUserEmail'
                handleChange={() => {}}
                value={cmsUserEmail}
                readOnly
                isRequired
                placeholder='Enter CMS User Email' />
              <Input
                title='Offer Id'
                inputName='offerId'
                handleChange={this.handleChangeOfferId}
                value={offerId}
                placeholder='Enter Offer ID'
                isRequired />
              <Input
                title='Comment'
                inputName='comment'
                handleChange={this.handleChangeComment}
                value={comment}
                placeholder='Enter Comment'
                isRequired />
              <Input
                title='Transaction Id'
                inputName='transactionId'
                handleChange={this.handleChangeTransactionId}
                value={transactionId}
                placeholder='Enter Transaction Id'
                isOptional />

              <div className='date-picker-container'>
                <div>
                  <label>Transaction Cover Time Stamp</label>
                  <span className=' optional'>*</span>
                </div>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format='DD-MMM-YYYY HH:mm'
                  placeholder='Enter Transaction Cover Time Stamp'
                  showToday={false}
                  onChange={this.handleChangeTransactionCoverTimeStamp}
                  value={transactionCoverTimeStamp ? moment(transactionCoverTimeStamp) : null}
                />
              </div>
            </div>
            <div className='warning-container'>
              <span>* Mandatory fields</span>
              <span>* At least one of the fields is required</span>
            </div>
          </React.Fragment>
          : '' }
      </Modal>
    )
  }
}

RefundModal.propTypes = {
  /** visible file status of CreateBucketDisplayTypesModal. */
  isVisible: PropTypes.bool,
  /** cancel action of CreateBucketDisplayTypesModal. */
  handleCancel: PropTypes.func
}

RefundModal.defaultProps = {
}

export default withApollo(
  compose(
    graphql(
      MutationRefund, {
        props: (props) => ({
          refund: (request) => {
            return props.mutate({
              variables: request
            })
          }
        })
      }
    )
  )(RefundModal)
)
